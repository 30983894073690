import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get as _get, set as _set, isEmpty as _isEmpty, pullAt as _pullAt } from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, useModal } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './ManualPropertyLossesComponent.metadata.json5';
import styles from './ManualPropertyLossesComponent.module.scss';
import messages from './ManualPropertyLossesComponent.messages';

import { Button } from '@jutro/legacy/components';

function ManualPropertyLossesComponent(props) {
    const modalApi = useModal();
    const {
        submissionVM,
        updateWizardData,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
        onValidateParentPage,
        disregardFieldValidationParentPage,
        title,
        isInlineComponent,
        readOnly,
        showErrors,
        updateIsPageSubmitted,
        lineOfBusiness
    } = props;
    const [submissionVMForLosses, updateSubmissionVMForLosses] = useState(viewModelService.clone(submissionVM));
    const translator = useTranslator();
    const [hasManualPropertyLosses, setHasManualPropertyLosses] = useState(
        _get(submissionVM, `lobData.${lineOfBusiness}.manualPropertyLossRecords.value.length`, 0) > 0
    );
    const {
        isComponentValid,
        disregardFieldValidation,
        onValidate
    } = useValidation('ManualPropertyLosses');

    
    const addPropertyLoss = useCallback(() => {
        const propertyLoss = {
            lossType: '',
            lossTypeDesc: '',
            source: {
                sourceType: 'Self-Declared'
            },
            catastrophicLoss: false
        };
        const {
            _xCenter,
            _dtoName,
        } = submissionVMForLosses.lobData[lineOfBusiness].manualPropertyLossRecords;
        const propertyLossVM = viewModelService.create(propertyLoss, _xCenter, _dtoName);

        submissionVMForLosses.lobData[lineOfBusiness].manualPropertyLossRecords.value.push(
            propertyLossVM.value
        );

        const newsubmissionVMForLosses = viewModelService.clone(submissionVMForLosses);

        updateSubmissionVMForLosses(newsubmissionVMForLosses);
        // we cannot overrite all of the QDD - Other components are changing it at the same time
        submissionVM.lobData[lineOfBusiness].manualPropertyLossRecords.value.push(
            propertyLossVM.value
        );
        updateWizardData(submissionVM);
        updateIsPageSubmitted(false);
    }, [submissionVMForLosses, lineOfBusiness, viewModelService, updateWizardData, submissionVM, updateIsPageSubmitted]);

    const writeValue = useCallback(
        (value, path) => {
            _set(submissionVMForLosses, path, value);
            _set(submissionVM, path, value);

            const newsubmissionVMForLosses = viewModelService.clone(submissionVMForLosses);

            updateSubmissionVMForLosses(newsubmissionVMForLosses);
            // we cannot overrite all of the QDD - Other components are changing it at the same time
            updateWizardData(submissionVM);
        },
        [submissionVMForLosses, submissionVM, viewModelService, updateWizardData]
    );
    
    useEffect(() => {
        const currentPropLosses = _get(submissionVMForLosses, `lobData.${lineOfBusiness}.manualPropertyLossRecords.value`);

        if (currentPropLosses === undefined) {
            _set(submissionVMForLosses, `lobData.${lineOfBusiness}.manualPropertyLossRecords.value`, []);
            _set(submissionVM, `lobData.${lineOfBusiness}.manualPropertyLossRecords.value`, []);
        }

        // Be default adding one empty record in case we dont have any losses present
        // Handling only for popup here as we have this logic for toggle present already
        if(_isEmpty(currentPropLosses) && !isInlineComponent){
            addPropertyLoss();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onToggleLossInd = useCallback((newVal, path) => {
        const manualLossRecords = _get(submissionVM, `lobData.${lineOfBusiness}.manualPropertyLossRecords.value`);

        if (!newVal && !_isEmpty(manualLossRecords)) {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.removeAllLossesTitle,
                message: messages.removeAllLossesDescription,
                confirmButtonText: translator(commonMessages.removeItemButtonText, { itemToRemove: 'LOSSES' }),
                cancelButtonText: commonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const manualPropertyLossesListPath = `lobData.${lineOfBusiness}.manualPropertyLossRecords`;

                    manualLossRecords.forEach((_, index) => {
                        disregardFieldValidation(`ehPropertyLoss${index}`);
                        disregardFieldValidationParentPage(`ehPropertyLoss${index}`);

                    });
                    _set(submissionVMForLosses, manualPropertyLossesListPath, []);

                    const newsubmissionVMForLosses = viewModelService.clone(submissionVMForLosses);

                    updateSubmissionVMForLosses(newsubmissionVMForLosses);
                    // we cannot overrite all of the QDD - Other components are changing it at the same time
                    _set(submissionVM, manualPropertyLossesListPath, []);
                    updateWizardData(submissionVM);

                    // umbrella does not have an indicator for manual property loss
                    if (path) {
                        writeValue(newVal, path);
                        setHasManualPropertyLosses(newVal);
                    } else {
                        setHasManualPropertyLosses(newVal);
                    }
                }
            }, () => { });
        } else {
            if (newVal && manualLossRecords.length < 1) {
                addPropertyLoss();// Auto add a loss shell when user confirms loss existence.
            }

            // umbrella does not have an indicator for manual property loss
            if (path) {
                writeValue(newVal, path);
            } else {
                setHasManualPropertyLosses(newVal)
            }
        }
    }, [addPropertyLoss, modalApi, disregardFieldValidation, disregardFieldValidationParentPage, submissionVM, submissionVMForLosses, updateWizardData, viewModelService, writeValue, lineOfBusiness, translator]);

    const removePropertyLoss = useCallback((evt) => {
        const pathArray = evt.path.split('.');
        const pathIdentifier = pathArray.slice(-1);
        const [, lossIndex] = /\[(\d+)\][^[]*$/.exec(pathIdentifier) || [];

        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: messages.removeLossTitle,
            message: messages.removeLossDescription,
            confirmButtonText: translator(commonMessages.removeItemButtonText, { itemToRemove: 'LOSS' }),
            cancelButtonText: commonMessages.cancel
        }).then((result) => {
            if (result !== 'cancel') {
                const manualPropertyLossesListPath = `lobData.${lineOfBusiness}.manualPropertyLossRecords.value`;
                const manualPropertyLossList = _get(submissionVMForLosses, manualPropertyLossesListPath);

                const propertyLossLastIndex = manualPropertyLossList.length - 1;

                disregardFieldValidation(`ehPropertyLoss${propertyLossLastIndex}`);
                disregardFieldValidationParentPage(`ehPropertyLoss${propertyLossLastIndex}`);
                _pullAt(manualPropertyLossList, lossIndex);
                _set(submissionVMForLosses, manualPropertyLossesListPath, manualPropertyLossList);
                _set(submissionVM, manualPropertyLossesListPath, manualPropertyLossList);

                if (manualPropertyLossList.length === 0) {
                    _set(submissionVMForLosses, `lobData.${lineOfBusiness}.manualPropertyLossRecords.value`, false);
                    _set(submissionVM, `lobData.${lineOfBusiness}.manualPropertyLossRecords.value`, false);
                }

                const newsubmissionVMForLosses = viewModelService.clone(submissionVMForLosses);

                updateSubmissionVMForLosses(newsubmissionVMForLosses);
                // we cannot overrite all of the QDD - Other components are changing it at the same time
                updateWizardData(submissionVM);
            }
        }, () => { });
    }, [lineOfBusiness, modalApi, submissionVMForLosses, disregardFieldValidation, disregardFieldValidationParentPage, submissionVM, viewModelService, updateWizardData, translator]);

    const generateOverrides = useCallback(() => {
        const overrideProps = {};
        const currentPropLosses = _get(submissionVMForLosses, `lobData.${lineOfBusiness}.manualPropertyLossRecords.value`);

        if (currentPropLosses !== undefined) {
            currentPropLosses.forEach((propLoss, index) => {
                overrideProps[`deletePropertyLoss${index}`] = {
                    visible: _get(currentPropLosses, 'length') > 1
                        && !readOnly // && (!isVerified || authUserData.permissions_Ext.includes('editoverrides'))
                };
                overrideProps[`ehPropertyLoss${index}`] = {
                    viewOnly: readOnly,
                    showErrors
                };
            });
        }

        // umbrella does not have an indicator for manual property loss
        if (lineOfBusiness === 'personalUmbrella_EU') {
            overrideProps.PropertyLossesResponse = {
                readOnly,
                visible: isInlineComponent,
                value: hasManualPropertyLosses
            }
        } else {
            overrideProps.PropertyLossesResponse = {
                readOnly,
                visible: isInlineComponent,
                path: `lobData.${lineOfBusiness}.hasManualPropertyLosses.value`
            }
        }

        return overrideProps;
    }, [submissionVMForLosses, lineOfBusiness, readOnly, showErrors, isInlineComponent, hasManualPropertyLosses]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly,
            showErrors,
            showRequired: true,
            autoComplete: false
        },
        ehManualPropertyLossesMainDiv: {
            className: isInlineComponent ? styles.ehManualPropertyLossesMainDivInline : styles.ehManualPropertyLossesMainDiv,
            visible: !!_get(submissionVM, `value.lobData.${lineOfBusiness}.hasManualPropertyLosses`)
                || hasManualPropertyLosses, // umbrella does not have an indicator for manual property loss,
            readOnly
        },
        addPropertyLosses: {
            visible: !readOnly
        },
        manualPropertyLossesContainer: {
            path: `lobData.${lineOfBusiness}.manualPropertyLossRecords.children`
        },
        ...generateOverrides()
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddPropertyLossClick: addPropertyLoss,
            onRemovePropertyLossClick: removePropertyLoss,
            onValidate,
            onValidateParentPage,
            onToggleLossInd
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVMForLosses,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVMForLosses]
    );

    const onOk = useCallback(
        () => {
            onResolve(submissionVMForLosses);
        }, [onResolve, submissionVMForLosses]
    );

    const onCancel = useCallback(
        () => {
            const manualPropertyLossList = _get(submissionVMForLosses, `lobData.${lineOfBusiness}.manualPropertyLossRecords.value`);

            manualPropertyLossList.forEach((loss, index) => {
                if (!loss.lossType || !loss.claimDate || !loss.claimAmount) {
                    if (disregardFieldValidationParentPage) {
                        disregardFieldValidationParentPage(`ehPropertyLoss${index}`);
                    }
                }
            });
            onReject();
        }, [disregardFieldValidationParentPage, onReject, submissionVMForLosses, lineOfBusiness]
    );

    const inLineComponent = (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVMForLosses}
            callbackMap={resolvers.resolveCallbackMap}
            onValueChange={writeValue}
            overrideProps={overrideProps}
            resolveValue={readValue}
            onValidationChange={onValidate}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );

    if (isInlineComponent) {
        return inLineComponent;
    }

    return (
        <ModalNext isOpen={isOpen} className={styles.modalDialog}>
            <ModalHeader title={title} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVMForLosses}
                    callbackMap={resolvers.resolveCallbackMap}
                    onValueChange={writeValue}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    Cancel
                </Button>
                <Button onClick={onOk} disabled={!isComponentValid}>
                    Save
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

ManualPropertyLossesComponent.propTypes = {
    submissionVM: PropTypes.shape({}),
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
        clone: PropTypes.func
    }).isRequired,
    onReject: PropTypes.func,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    onValidateParentPage: PropTypes.func.isRequired,
    disregardFieldValidationParentPage: PropTypes.func.isRequired,
    title: PropTypes.string,
    isInlineComponent: PropTypes.bool,
    updateWizardData: PropTypes.func,
    readOnly: PropTypes.bool,
    showErrors: PropTypes.bool,
    updateIsPageSubmitted: PropTypes.func
};
ManualPropertyLossesComponent.defaultProps = {
    submissionVM: {},
    updateWizardData: () => { },
    title: '',
    isInlineComponent: false,
    readOnly: false,
    showErrors: false,
    updateIsPageSubmitted: () => { }
};
export default ManualPropertyLossesComponent;
