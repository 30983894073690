import React, { useMemo, useContext } from 'react';
import _ from 'lodash';
import { wizardProps, WizardPageHeader } from 'e1p-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { useTranslator } from '@jutro/locale';
import { commonMessages } from 'e1p-platform-translations';
import styles from './ThankYouPage.module.scss';
import metadata from './ThankYouPage.metadata.json5';

const LOB = 'personalAuto_EA';

function ThankYouPage(props) {
    const { wizardData: submissionVM } = props;

    const viewModelService = useContext(ViewModelServiceContext);

    const policyNumber = useMemo(() => _.get(submissionVM, 'bindData.policyNumber.value'), [submissionVM]);

    const onPrintHandler = () => {
        window.print();
    };

    const translator = useTranslator();
    const { opCo } = useContext(AmfamOktaTokenContext);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        exitToDashBoardButtonId: {
            to: `/policies/${policyNumber}/summary`,
        },
        messagesContainer: {
            submissionVM,
            viewModelService,
            LOB
        },
        policyDocumentContainer: {
            submissionVM
        },
        addANewLineComponent: {
            submissionVM
        },
        requiredDocumentContainer: {
            submissionVM,
            viewModelService
        },
        rvpSection: {
            submissionVM
        },
        wizardHeaderContainer: {
            wizardSubmission: submissionVM,
            isSideHeader: false,
            shouldLink: false,
            isPolicyView: false
        },
        thankyouMessageLabelId: {
            content: opCo === 'MSA' ? translator(commonMessages.thankYouMessage, { partner: translator(commonMessages.msa) })
                : translator(commonMessages.thankYouMessage, { partner: translator(commonMessages.connect) })
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            wizardpageheader: WizardPageHeader
        },
        resolveCallbackMap: {
            onPrintHandler
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

ThankYouPage.propTypes = wizardProps;
export default ThankYouPage; 
