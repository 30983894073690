import React, {
    useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, withModalContext, useModal } from '@jutro/components';
import {
    FilterBar
} from '@jutro/legacy/datatable';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { PartnerService } from 'e1p-capability-gateway';
import PropTypes from 'prop-types';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import styles from './E1PAdvisorLookupComponent.module.scss';
import metadata from './E1PAdvisorLookupComponent.metadata.json5';
import messages from './E1PAdvisorLookupComponent.messages';


import { Button } from '@jutro/legacy/components';


function E1PAdvisorLookupComponent(props) {
    const {
        advisorLastName,
        isOpen,
        onResolve,
        onReject
    } = props;
    const modalApi = useModal();

    const { onValidate } = useValidation(
        'E1pAdvisorLookupComponent'
    );
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [isFetcingAdvisors, setIsFetchingAdvisors] = useState(false);
    const [chosenAdvisor, setChosenAdvisor] = useState(undefined);
    const [advisorsForDisplay, setAdvisorsForDisplay] = useState([])
    const [advisorDataWithoutFilter, setAdvisorDataWithoutfilter] = useState([]);

    useEffect(() => {
        if (_.isEmpty(advisorLastName)) {
            return false;
        }

        setIsFetchingAdvisors(true);
        // Calling partner service to get advisors matching entered last name
        PartnerService.getAmeripriseAdvisors(advisorLastName, authHeader)
            .then((advisorsResponse) => {
                const advisorDataToDisplay = advisorsResponse.map((foundAdvisor) => ({
                    id: foundAdvisor.AdvisorNumber,
                    displayName: `${foundAdvisor.FirstName} ${foundAdvisor.LastName}`,
                    secondaryLabel: `Location: ${foundAdvisor.StateCode},  Advisor ID: ${foundAdvisor.AdvisorNumber}`,
                    firstName: foundAdvisor.FirstName,
                    stateCode: foundAdvisor.StateCode
                }));

                setAdvisorsForDisplay(advisorDataToDisplay);
                setAdvisorDataWithoutfilter(advisorDataToDisplay);
            })
            .catch(() => {
                modalApi.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: messages.advisorLookupError
                }).then(() => 
                    // close the popup
                     onReject()
                )
            }).finally(() => {
                setIsFetchingAdvisors(false);
            })
        // want to execute it only once on opening of this popup
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * @param filters {Object} Contains key and value for the search and any filters applied
     * @returns None
     * 
     * This function is triggered when text is entered into the search bar AND when "APPLY" button
     *   under filters is clicked. It will first apply the search criteria and then apply each filter.
     *   Filters run as AND (all filter conditions must match for element to remain in list).
     */
    const handleFilteringChange = useCallback(
        (filters) => {
            const clonedData = _.cloneDeep(advisorDataWithoutFilter);
            const filterredValues = clonedData.filter((advisor) => {
                let keepValue = true;
                let includedInFirstName; let includedInStateCode; let includedInId;

                for (const filter in filters) {
                    // search is the global search input box beside the filter toggle
                    // first apply search and then apply filters
                    if (filter === 'search' && !_.isEmpty(filters[filter])) {
                        includedInFirstName = advisor.firstName?.toLowerCase().trim().includes(
                            filters[filter]?.toLowerCase().trim()
                        );

                        if (!includedInFirstName) {
                            includedInStateCode = advisor.stateCode?.toLowerCase().trim().includes(
                                filters[filter]?.toLowerCase().trim()
                            );
                        }
 
                        if (!includedInFirstName && !includedInStateCode) {
                            includedInId = advisor.id?.toLowerCase().trim().includes(
                                filters[filter]?.toLowerCase().trim()
                            );
                        }

                        keepValue = includedInFirstName || includedInStateCode || includedInId;

                        // search box takes precedence so no need to check filters
                        if (!keepValue) {
                            break;
                        }
                    }
                    else {
                        // all filters have to apply
                        const hasMatch = advisor[filter].toLowerCase().trim().includes(
                            filters[filter]?.toLowerCase().trim()
                        );

                        keepValue = hasMatch;

                        // Filters are AND not OR - all filters given must have match
                        if (!keepValue) {
                            break;
                        }
                    }
                }

                return keepValue;
            }
            );

            setAdvisorsForDisplay(filterredValues);
        },
        [advisorDataWithoutFilter],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        advisorLookupLoader: {
            loaded: !isFetcingAdvisors,
            text: translator(messages.retrievingAdvisors)
        },
        existingAdvisorsFoundMessage: {
            visible: !isFetcingAdvisors && !_.isEmpty(advisorDataWithoutFilter)
        },
        noAdvisorsFoundMessage: {
            visible: !isFetcingAdvisors && _.isEmpty(advisorDataWithoutFilter)
        },
        advisorsRadioOptions: {
            key: advisorsForDisplay.length,
            availableValues: advisorsForDisplay,
            value: chosenAdvisor?.id,
            onValueChange: (value) => {
                // When user selects any radio button storing selection to state variable
                setChosenAdvisor(advisorsForDisplay.find((advisor) => advisor.id === value))
            }
        },
        filterBarContainer: {
            visible: !isFetcingAdvisors && !_.isEmpty(advisorDataWithoutFilter),
            content: [
                <FilterBar
                    onFiltersChange={handleFilteringChange}
                    uiProps={{
                        component: 'fragment',
                        id: 'filters',
                        type: 'container',
                        contentLayout: {
                            component: "Grid",
                            componentProps: {
                                columns: [
                                    "1fr",
                                    "1fr"
                                ],
                                gap: "small"
                            }
                        },
                        content: [
                            {
                                componentProps: {
                                    label: translator(messages.advisorFirstName),
                                    path: 'firstName',
                                    autoTrim: true
                                },
                                datatype: 'text',
                                id: 'nameFilter',
                                type: 'field'
                            },
                            {
                                componentProps: {
                                    label: translator(messages.advisorStateCode),
                                    path: 'stateCode',
                                    autoTrim: true,
                                },
                                datatype: 'text',
                                id: 'stateFilter',
                                type: 'field'
                            },
                            {
                                componentProps: {
                                    label: translator(messages.advisorId),
                                    path: 'id',
                                    autoTrim: true,
                                },
                                datatype: 'text',
                                id: 'idFilter',
                                type: 'field'
                            },
                        ]
                    }}
                />
            ]
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                advisorsForDisplay,
                id,
                path,
                overrideProps
            ),
        [overrideProps, advisorsForDisplay]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        },
    };

    const onContinue = useCallback(
        () => {
            if (!chosenAdvisor) {
                return onReject();
            }

            // Creating object which will be returned on click on continue
            const advisor = {
                advisorID: chosenAdvisor.id,
                // display name is concatination of first name and last name
                advisorName: chosenAdvisor.displayName,
                advisorLocation: chosenAdvisor.stateCode
            }

            return onResolve(advisor);
        }, [chosenAdvisor, onReject, onResolve]
    );

    useEffect(() => {
        // Search bar is built into the Jutro filter bar component and they are not providing a way to configure it,
        //   so grabbing by element name and setting placeholder to empty string
        document.getElementsByName('searchFilterBar').forEach((htmlElement) => {
            htmlElement.placeholder = '';
        });
    }, [isFetcingAdvisors]);

    return (
        <ModalNext isOpen={isOpen} className={styles.smallPopUp}>
            <ModalHeader title={translator(messages.existingAdvisors)} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={advisorsForDisplay}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onContinue}>
                    {translator(e1pCommonMessages.continue)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );

}

E1PAdvisorLookupComponent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    advisorLastName: PropTypes.string
};

E1PAdvisorLookupComponent.defaultProps = {
    advisorLastName: undefined
};
export default withModalContext(E1PAdvisorLookupComponent);
