import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty
} from 'lodash';
import {
    useModal,
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { AutoLossService } from 'e1p-capability-gateway';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { TimelineService } from 'e1p-capability-journey';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import metadata from '../RiskAnalysisPage.metadata.json5';
import messages from '../RiskAnalysisPage.messages';
import requiredMetadata from '../RiskAnalysisPage.requiredness';

function ViewEARiskAnalysisPage(props) {
    const modalApi = useModal();
    const { wizardData: submissionVM, authUserData } = props;
    const viewModelService = useContext(ViewModelServiceContext);

    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [dataForComponent, updateDataForComponent] = useState({});
    const [isRetrievingReports, setIsRetrievingReports] = useState(false);
    const [visibleFields, updateVisibleFields] = useState([]);
    const [manualLossList, setManualLossList] = useState([]);
    const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');
    const {opCo} = useContext(AmfamOktaTokenContext);

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(submissionVM, 'lobData.personalAuto_EA.premiumStability.value') !== undefined;

    const createAutoLossVM = useCallback((vmObject, dtoName) => {
        const vmNode = viewModelService.create(
            vmObject,
            'pc',
            dtoName
        );

        return vmNode;
    }, [viewModelService]);

    useEffect(() => {
        const initialVisibleFields = ['declareLossStatementThreeYears', 'declareLossStatementFiveYears']; // Fields to look up by partner/state

        updateVisibleFields(
            isRequired(initialVisibleFields, requiredMetadata, policyState, opCo)
        );
        // User can't able to change policy state on Risk Analysis Page,
        // hence we need to run this useEffect only single time.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opCo]);

    useEffect(() => {
        // calling loadAutoLosses only in full app mode
        if (_get(submissionVM, 'value.quoteType') === 'Full') {
            setIsRetrievingReports(true);

            const historyPromise = TimelineService.getTimeline(
                {
                    policyNumber: _get(submissionVM, 'value.policyNumber'),
                    relatedItem: 'Policy'
                },
                authHeader
            );

            historyPromise.then((response) => {
                const lossHistoryEvent = response.events.find((event) => event.description?.includes('Self-Declared'))?.description;

                if (lossHistoryEvent) {
                    setManualLossList(lossHistoryEvent.split("\n"));
                }
            });

            const AutoLossRecordsPromise = AutoLossService.loadAutoLosses(
                _get(submissionVM, 'quoteID.value'),
                authHeader
            );

            AutoLossRecordsPromise
                .then((response) => {
                    const autoLossRecords = [];
                    const filteredAutoLossRecords = response.autoLossRecords
                        .filter((loss) => loss.source?.sourceType !== 'Self-Declared');

                    filteredAutoLossRecords.forEach((result) => {
                        const recordVM = createAutoLossVM(
                            result,
                            'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoLossRecordDTO'
                        );

                        autoLossRecords.push(recordVM.value);
                    });

                    const autoViolationRecords = [];
                    const filteredAutoViolationRecords = response.autoViolationRecords
                        .filter((violation) => violation.source?.sourceType !== 'Self-Declared');

                    filteredAutoViolationRecords.forEach((result) => {
                        const violationVM = createAutoLossVM(
                            result,
                            'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoViolationRecordDTO'
                        );

                        _set(
                            violationVM,
                            'value.operatorDisplayName',
                            `${violationVM.value.assignment.firstName} ${violationVM.value.assignment.lastName}`
                        );
                        autoViolationRecords.push(violationVM.value);
                    });

                    _set(dataForComponent, 'lobData.personalAuto_EA.autoLossRecords.value', autoLossRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.autoViolationRecords.value', autoViolationRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.mvrLicenseStatusRecords.value', response.mvrlicenseStatus);
                    _set(dataForComponent, 'lobData.personalAuto_EA.orderingInfo.value', response.orderingInfo);
                    updateDataForComponent(dataForComponent);
                })
                .catch(() => {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: messages.reportsErrorTitle,
                        message: messages.reportsErrorMessage
                    });
                })
                .finally(() => {
                    setIsRetrievingReports(false);
                });
        }
        // No array dependencies needed in this hook.
        // The logic of initializing losses data needs to be executed only once
        // when landing into losses page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(() => submissionVM, [submissionVM]);


    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        RiskAnalysisPageLoadingIndicator: {
            loaded: !isRetrievingReports,
            text: translator(messages.loading)
        },
        RiskAnalysisPageContainer: {
            visible: !isRetrievingReports
        },
        RiskAnalysisPageLoader: {
            loaded: true
        },
        EAPriorCarrierComponentId: {
            submissionVM,
            disregardFieldValidation: () => {},
            viewOnlyMode: true
        },
        EALossAndViolationComponentId: {
            lobDataModel: submissionVM.lobData.personalAuto_EA,
            onModelChange: () => {},
            viewModelService,
            disregardFieldValidation:  () => {},
            operators: submissionVM.lobData.personalAuto_EA.coverables.drivers?.value?.map((driver) => ({
                    publicId: driver.publicID,
                    displayName: driver.person.displayName
                })),
            viewOnlyMode: true,
            policyState,
            authUserData,
            isVerified: true
        },
        EARiskAnalysisMiscComponentId: {
            submissionVM,
            viewOnlyMode: true,
            shouldShowCedingField: policyState === 'NC'
        },
        billingMiscellaneousReportComponent: {
            visible: authUserData?.permissions_Ext.includes('canviewbillingreport'),
            data: _get(submissionVM,'value.baseData.cancelActivities_Ext',[])
        },
        diminishingAvailableAmount: {
            labelPosition: 'left'
        },
        diminishingAvailableAmountLatest: {
            labelPosition: 'left'
        },
        EACreditReportsComponentId: {
            submissionVM,
            updateWizardData: () => {},
            viewOnlyMode: true,
            lineOfBusiness: submissionVM.lobData.personalAuto_EA,
            lobName: 'personalAuto_EA',
            authUserData,
            id: 'EACreditReportsComponentId',
            onValidate:  () => {}
        },
        vehicleReportTabID: {
            visible: submissionVM.baseData.periodStatus.value.code === 'Quoted',
            viewOnlyMode: true
        },
        lossesAndViolationID: {
            visible: submissionVM.value.quoteType === 'Full' && !_isEmpty(dataForComponent),
            lossesAndViolations: dataForComponent,
            viewOnlyMode: true,
            drivers: submissionVM.lobData.personalAuto_EA.coverables.drivers,
            policyState
        },
        EAUWIssuesComponentId: {
            submissionVM,
            updateWizardData: () => {},
            authHeader,
            viewOnlyMode: true
        },
        declareStatementThreeYears: {
            visible: visibleFields.includes('declareLossStatementThreeYears')
        },
        declareStatementFiveYears: {
            visible: visibleFields.includes('declareLossStatementFiveYears')
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(submissionVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: submissionVM,
            updateWizardData: () => {},
            onValidate: () => {},
            showErrors: false,
            viewOnlyMode: true
        },
        manualLossesHistory:{
            manualLossList,
            visible: _get(submissionVM, 'value.quoteType') === 'Full' && manualLossList.length > 0
        },
        noMiscReportResultsMessageId: {
            visible: (() => 
                // If misc report content is empty 
                 !_get(document.getElementById('miscellaneousReportsBodyId'), 'innerText') 
                    && !_get(document.getElementById('billingMiscellaneousReportComponent'), 'innerText')
            )()
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate: () => {}
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewEARiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewEARiskAnalysisPage);
