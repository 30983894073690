import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { RewriteService } from 'e1p-capability-rewrite';
import { useTranslator } from '@jutro/locale';
import {
    get as _get,
    set as _set
} from 'lodash';
import PropTypes from 'prop-types';
import metadata from './PolicyTermComponent.metadata.json5';
import messages from './PolicyTermComponent.messages';

function PolicyTermComponent(props) {
    const {
        transactionVM,
        authHeader,
        updateWizardData,
        setIsCallingService,
        viewOnly
    } = props;
    const translator = useTranslator();
    const TERM_HALFYEAR = 'HalfYear';
    const TERM_ANNUAL = 'Annual';
    const JOBTYPE_POLICYCHANGE = 'PolicyChange';
    const JOBTYPE_REWRITE = 'Rewrite';
    const JOBTYPE_RENEWAL = 'Renewal';
    const isJobTypeRenewal = _get(transactionVM, 'baseData.jobType.value.code') === JOBTYPE_RENEWAL;
    const isJobTypePolicyChange = _get(transactionVM, 'baseData.jobType.value.code') === JOBTYPE_POLICYCHANGE;
    const isJobTypeRewrite = _get(transactionVM, 'baseData.jobType.value.code') === JOBTYPE_REWRITE;
    const twelveMonthTermEligible = _get(transactionVM, 'lobData.personalAuto_EA.twelveMonthTermEligible.value', false);
    const [termAvailableValues, setTermAvailableValues] = useState([]);
    const [updatingPolicyTerm, setUpdatingPoicyTerm] = useState(false);

    // since we are changing the policy Term and calling save and quote,
    // so status should be Quoted, if the PNI is eligible for 12 month term,
    // other wise we will get validation issue
    const validateAPIResponse = useCallback((response) => {
        const quoteData = _get(transactionVM, 'quoteData.value');

        if (response.baseData.periodStatus === 'Quoted') {
            _set(transactionVM, 'value', response);

            const quoteType = _get(transactionVM, 'quoteType_Ext.value.code', null);

            // dummy prop, tells the quote page to get a new quote proposal url
            _set(transactionVM, 'policyTermChanged', true);

            if (quoteType === null) {
                _set(transactionVM, 'quoteType_Ext.value', response?.quoteType);
            }
        } else {
            // show error and set term type to default six months
            const respQuoteData = _get(response, 'quoteData');

            if (respQuoteData === undefined) {
                _set(response, 'quoteData', quoteData);
            }

            _set(transactionVM, 'value', response);
            _set(transactionVM, 'baseData.termType.value', TERM_HALFYEAR);
        }

        updateWizardData(transactionVM);
    }, [transactionVM, updateWizardData]);

    const callSaveAndQuote = useCallback((termType) => {
        setUpdatingPoicyTerm(true);
        setIsCallingService(true);
        _set(transactionVM, 'baseData.termType.value', termType);

        let serverCall = LoadSaveService.saveAndQuoteSubmission;
        let data = transactionVM.value;

        if (isJobTypeRewrite) {
            serverCall = RewriteService.saveAndQuote;
            data = [transactionVM.value];
        }

        serverCall(data, authHeader)
            .then((response) => {
                validateAPIResponse(response);
            })
            .catch(() => { })
            .finally(() => {
                setUpdatingPoicyTerm(false);
                setIsCallingService(false);
            });
    }, [authHeader, isJobTypeRewrite, setIsCallingService, transactionVM, validateAPIResponse]);

    const termChangeHandler = useCallback((value) => {
        callSaveAndQuote(value);
    }, [callSaveAndQuote]);

    const getTermTypeValue = useMemo(() => {
        if (isJobTypePolicyChange) {
            return _get(transactionVM, 'baseData.termType_Ext.value.code');
        }

        return _get(transactionVM, 'baseData.termType.value.code', _get(transactionVM, 'termType.value.code'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isJobTypePolicyChange, transactionVM.value]);

    useEffect(() => {
        const availableValues = [];

        if (!isJobTypePolicyChange || getTermTypeValue === TERM_HALFYEAR) {
            availableValues.push(
                {
                    code: TERM_HALFYEAR,
                    name: messages.sixMonths
                }
            );
        }

        if ((!isJobTypePolicyChange && twelveMonthTermEligible)
            || getTermTypeValue === TERM_ANNUAL) {
            availableValues.push(
                {
                    code: TERM_ANNUAL,
                    name: messages.twelveMonths
                }
            );
        }

        setTermAvailableValues(availableValues);
    }, [getTermTypeValue, isJobTypePolicyChange, twelveMonthTermEligible]);

    const overrideProps = {
        '@field': {
            readOnly: viewOnly || isJobTypePolicyChange,
            autoComplete: false
        },
        termRadio: {
            onValueChange: (value) => termChangeHandler(value),
            value: getTermTypeValue,
            readOnly: viewOnly  || isJobTypeRenewal || termAvailableValues.length === 1,
            availableValues: termAvailableValues
        },
        componentLoader: {
            loaded: !updatingPolicyTerm,
            text: translator(messages.changingPolicyTerm)
        },
        policyTermContainerDiv: {
            visible: !updatingPolicyTerm
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={transactionVM}
        />
    );
}

PolicyTermComponent.propTypes = {
    transactionVM: PropTypes.shape({
        value: PropTypes.shape({})
    }).isRequired,
    updateWizardData: PropTypes.func,
    setIsCallingService: PropTypes.func,
    authHeader: PropTypes.shape({}),
    viewOnly: PropTypes.bool
};

PolicyTermComponent.defaultProps = {
    setIsCallingService: () => { },
    viewOnly: false
};
export default PolicyTermComponent;
