import React, {
    useCallback, useEffect, useState, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue, appendMetadataWithIndex } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    get, set, isUndefined, isEqual, includes, isEmpty
} from 'lodash';
import { E1PPageHeaderCompletionStatusComponent } from 'e1p-capability-policyjob-react';
import { UnderlyingPolicyUtil } from 'e1p-portals-util-js';
import appConfig from 'app-config';
import metadata from './UnderlyingPolicyDetails.metadata.json5';
import UnderlyingPolicyNamedInsured from '../EUNamedInsured/UnderlyingPolicyNamedInsured';
import styles from '../../E1PEUUnderlyingPolicyComponent.module.scss';
import EUPersonalPropertyExposure from '../EUPersonalPropertyExposure/EUPersonalPropertyExposure';
import EUAdditionalPremises from '../EUAdditionalPremises/EUAdditionalPremises';
import EURentalDwellings from '../EURentalDwellings/EURentalDwellings';
import messages from './UnderlyingPolicyDetails.messages';

function UnderlyingPolicyDetails(props) {
    const {
        model: underlyingPolicyVM,
        onModelChange,
        onValidate,
        labelPosition,
        visible,
        viewOnlyMode,
        id,
        setUnderlyingPolicyIsValid,
        showErrors,
        isPniOrSniMatch,
        index
    } = props;

    const {
        isComponentValid,
        onValidate: setComponentValidation,
        disregardFieldValidation: setDisregardULPolicyDetailsFieldValidation
    } = useValidation('ulPolicyDetails');

    const {
        isComponentValid: isExpValid,
        registerComponentValidation,
        disregardFieldValidation,
        onValidate: setComponentValidationForExp
    } = useValidation('exposure');

    // CONFIG for limit dropdowns
    const {
        maxCombinedSingleLimit,
        maxSplitLimit,
        minCombinedSingleLimit,
        minSplitLimit
    } = get(appConfig, 'limitConfig');
    const LIMIT_INCREMENT = 1000;

    useEffect(() => {
        if (onValidate) {
            onValidate((isComponentValid && isExpValid), id);
        }
    }, [underlyingPolicyVM, id, onValidate, isComponentValid, isExpValid]);

    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const ulPolExposureElement = document.getElementById(`policyType${index}`);

        if (ulPolExposureElement
            && ulPolExposureElement.focus !== undefined
            && isEmpty(get(underlyingPolicyVM, 'value.policyType'))) {
            ulPolExposureElement.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [watercraftLiabilityCoverageInd, setWatercraftLiabilityCoverageInd] = useState('');
    const [isSplitLimit, setIsSplitLimit] = useState(true);
    const [policyLevelCoverageBundle, setPolicyLevelCoverageBundle] = useState('splitLimit');

    useEffect(() => {
        const isValid = isExpValid
            && isComponentValid;

        setUnderlyingPolicyIsValid(!!isValid);
    }, [isComponentValid, setUnderlyingPolicyIsValid, isExpValid]);

    const onPolicyLevelCoverageBundleChange = useCallback((value) => {
        setIsSplitLimit(value === 'splitLimit');
        setPolicyLevelCoverageBundle(value);

        // Should wipe the fields on value change
        if (value === 'combinedSingleLimit') {
            set(underlyingPolicyVM, 'bodilyInjuryCovPerPersonLimit', undefined);
            set(underlyingPolicyVM, 'bodilyInjuryCovPerOccLimit', undefined);
            set(underlyingPolicyVM, 'propertyDamageCovPerOccLimit', undefined);
            set(underlyingPolicyVM, 'uninsuredMotoristCovPerPersonLimit', undefined);
            set(underlyingPolicyVM, 'uninsuredMotoristCovPerOccLimit', undefined);
            set(underlyingPolicyVM, 'underinsuredMotoristCovPerPersonLimit', undefined);
            set(underlyingPolicyVM, 'underinsuredMotoristCovPerOccLimit', undefined);
            set(underlyingPolicyVM, 'umuimcovPerPersonLimit', undefined);
            set(underlyingPolicyVM, 'umuimcovPerOccLimit', undefined);
            set(underlyingPolicyVM, 'hasCombinedSingleLimit.value', true);
        } else if (value === 'splitLimit') {
            set(underlyingPolicyVM, 'combinedSingleLimitCovPerOccLimit', undefined);
            set(underlyingPolicyVM, 'uninsuredMotoristCovPerOccCSL', undefined);
            set(underlyingPolicyVM, 'underinsuredMotoristCovPerOccCSL', undefined);
            set(underlyingPolicyVM, 'umuimcovPerOccCSL', undefined);
            set(underlyingPolicyVM, 'hasCombinedSingleLimit.value', false);
        }
    }, [underlyingPolicyVM]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const watercraftLiabilityCoverageAvailableValues = [
        {
            code: 'homeowners',
            name: 'Homeowners'
        },
        {
            code: 'personalwatercraft',
            name: 'Watercraft'
        }
    ];

    useEffect(() => {
        if (isUndefined(underlyingPolicyVM.value)) {
            underlyingPolicyVM.value = {};
        }

        UnderlyingPolicyUtil.registerValidationForExposures(
            registerComponentValidation,
            underlyingPolicyVM
        );

        if (get(underlyingPolicyVM, 'policyType.value.code', null) === 'personalwatercraft') {
            if (underlyingPolicyVM.hasCoverageELiabilityCov.value) {
                setWatercraftLiabilityCoverageInd('homeowners');
            } else if (underlyingPolicyVM.hasCombinedSingleLimit.value !== undefined
                || underlyingPolicyVM.hasBodilyInjuryCov.value !== undefined) {
                setWatercraftLiabilityCoverageInd('personalwatercraft');
            }
        }

        // To check if combined single limit bundle is selected previously
        // we will check if any one of them is present rather than all of them as
        // uninsuredMotoristCovPerOccCSL and umuimcovPerOccCSL are mutually exclusive
        if (underlyingPolicyVM.combinedSingleLimitCovPerOccLimit?.value
            || underlyingPolicyVM.uninsuredMotoristCovPerOccCSL?.value
            || underlyingPolicyVM.underinsuredMotoristCovPerOccCSL?.value
            || underlyingPolicyVM.umuimcovPerOccCSL?.value) {
            setPolicyLevelCoverageBundle('combinedSingleLimit');
            setIsSplitLimit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setPrimaryHomeIndicator = useCallback((newVal) => {
        set(underlyingPolicyVM, 'isPrimaryInd.value', newVal);
    }, [underlyingPolicyVM]);

    const writeValue = useCallback(
        (newVal, path) => {
            set(underlyingPolicyVM, `${path}.value`, newVal);
            onModelChange();
        },
        [onModelChange, underlyingPolicyVM]
    );

    useEffect(() => {
        if (underlyingPolicyVM.coverageELiabilityCovLimit.value) {
            set(underlyingPolicyVM, 'hasCoverageELiabilityCov.value', true);
        } else {
            set(underlyingPolicyVM, 'hasCoverageELiabilityCov.value', false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [underlyingPolicyVM.coverageELiabilityCovLimit.value]);

    // IAP-593 : set coverage indicators if coverage limits are present
    useEffect(
        () => {
            if (underlyingPolicyVM.bodilyInjuryCovPerPersonLimit.value
                || underlyingPolicyVM.bodilyInjuryCovPerOccLimit.value) {
                set(underlyingPolicyVM, 'hasBodilyInjuryCov.value', true);
            } else {
                set(underlyingPolicyVM, 'hasBodilyInjuryCov.value', false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            underlyingPolicyVM.bodilyInjuryCovPerPersonLimit.value,
            underlyingPolicyVM.bodilyInjuryCovPerOccLimit.value
        ]
    );

    useEffect(
        () => {
            if (underlyingPolicyVM.propertyDamageCovPerOccLimit.value) {
                set(underlyingPolicyVM, 'hasPropertyDamageCov.value', true);
            } else {
                set(underlyingPolicyVM, 'hasPropertyDamageCov.value', false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            underlyingPolicyVM.propertyDamageCovPerOccLimit.value
        ]
    );

    useEffect(
        () => {
            if (
                underlyingPolicyVM.uninsuredMotoristCovPerPersonLimit.value
                || underlyingPolicyVM.uninsuredMotoristCovPerOccLimit.value
                || underlyingPolicyVM.uninsuredMotoristCovPerOccCSL.value
            ) {
                set(underlyingPolicyVM, 'hasUninsuredMotoristCov.value', true);
            } else {
                set(underlyingPolicyVM, 'hasUninsuredMotoristCov.value', false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            underlyingPolicyVM.uninsuredMotoristCovPerPersonLimit.value,
            underlyingPolicyVM.uninsuredMotoristCovPerOccLimit.value,
            underlyingPolicyVM.uninsuredMotoristCovPerOccCSL.value
        ]
    );

    useEffect(
        () => {
            if (
                underlyingPolicyVM.underinsuredMotoristCovPerPersonLimit.value
                || underlyingPolicyVM.underinsuredMotoristCovPerOccLimit.value
                || underlyingPolicyVM.underinsuredMotoristCovPerOccCSL.value
            ) {
                set(underlyingPolicyVM, 'hasUnderinsuredMotoristCov.value', true);
            } else {
                set(underlyingPolicyVM, 'hasUnderinsuredMotoristCov.value', false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            underlyingPolicyVM.underinsuredMotoristCovPerPersonLimit.value,
            underlyingPolicyVM.underinsuredMotoristCovPerOccLimit.value,
            underlyingPolicyVM.underinsuredMotoristCovPerOccCSL.value
        ]
    );

    useEffect(
        () => {
            if (
                underlyingPolicyVM.umuimcovPerPersonLimit.value
                || underlyingPolicyVM.umuimcovPerOccLimit.value
                || underlyingPolicyVM.umuimcovPerOccCSL.value
            ) {
                set(underlyingPolicyVM, 'hasUMUIMCov.value', true);
            } else {
                set(underlyingPolicyVM, 'hasUMUIMCov.value', false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            underlyingPolicyVM.umuimcovPerPersonLimit.value,
            underlyingPolicyVM.umuimcovPerOccLimit.value,
            underlyingPolicyVM.umuimcovPerOccCSL.value
        ]
    );


    const renderCompletionHeader = (sectionHeader, isSectionValid) => (
            <E1PPageHeaderCompletionStatusComponent
                sectionHeader={sectionHeader?.defaultMessage}
                isSectionValid={isSectionValid}
            />
        );

    const onChangeOfPolicyType = useCallback((value) => {
        UnderlyingPolicyUtil.onChangeOfPolicyTypeExposure(
            value,
            underlyingPolicyVM,
            disregardFieldValidation,
            writeValue
        );
    }, [disregardFieldValidation, underlyingPolicyVM, writeValue]);

    const onAddSni = useCallback(() => {
        if (isUndefined(get(underlyingPolicyVM, 'secondaryNamedIsnured.value', undefined))) {
            writeValue({}, 'secondaryNamedInsured');
        }
    }, [underlyingPolicyVM, writeValue]);

    const formattedMetadata = useMemo(() => appendMetadataWithIndex(metadata.pageContent, index), [index]);

    const onRemoveSni = useCallback(() => {
        writeValue(undefined, 'secondaryNamedInsured');
        setDisregardULPolicyDetailsFieldValidation('secondaryNamedInsured');
    }, [writeValue, setDisregardULPolicyDetailsFieldValidation]);

    const generateOverrides = useCallback(() => {
        const selectedPolicyType = get(underlyingPolicyVM, 'policyType.value.code', null);
        // Only imported underlying policies have effective dates
        const underlyingPolicyIsImportedInd = !isEmpty(get(underlyingPolicyVM, 'effectiveDate.value', null));

        const onWatercraftLiabilityIndChange = (value) => {
            setWatercraftLiabilityCoverageInd(value);

            if (value === 'homeowners') {
                set(underlyingPolicyVM, 'bodilyInjuryCovPerPersonLimit', undefined);
                set(underlyingPolicyVM, 'bodilyInjuryCovPerOccLimit', undefined);
                set(underlyingPolicyVM, 'propertyDamageCovPerOccLimit', undefined);
                set(underlyingPolicyVM, 'combinedSingleLimitCovPerOccLimit', undefined);
            } else {
                set(underlyingPolicyVM, 'coverageELiabilityCovLimit', undefined);
            }
        };

        return {
            '@field': {
                showRequired: true,
                visible,
                labelPosition,
                readOnly: viewOnlyMode || underlyingPolicyIsImportedInd,
                showErrors,
                autoComplete: false
            },
            [`underlyingPolicyTabContainer${index}`]: {
                defaultActiveTab: `underlyingPolicyTab${index}`
            },
            [`policyType${index}`]: {
                readOnly: !underlyingPolicyVM.aspects.subtreeValid || viewOnlyMode || underlyingPolicyIsImportedInd,
                onValueChange: onChangeOfPolicyType
            },
            [`effectiveDate${index}`]: {
                updateDateDto: () => writeValue(underlyingPolicyVM?.effectiveDate, 'effectiveDate'),
                dateDTO: underlyingPolicyVM?.effectiveDate,
                visible: underlyingPolicyIsImportedInd,
                onValidate: setComponentValidation,
                readOnly: true
            },
            [`expirationDate${index}`]: {
                updateDateDto: () => writeValue(underlyingPolicyVM?.expirationDate, 'expirationDate'),
                dateDTO: underlyingPolicyVM?.expirationDate,
                visible: underlyingPolicyIsImportedInd,
                onValidate: setComponentValidation,
                readOnly: true
            },
            [`namedInsuredRelResOfYourHouseholdInd${index}`]: {
                readOnly: underlyingPolicyIsImportedInd || viewOnlyMode,
                visible: !isPniOrSniMatch
            },
            [`isPrimary${index}`]: {
                defaultValue: selectedPolicyType === 'commercialproperty' ? false : null,
                readOnly: viewOnlyMode
            },
            [`primaryNamedInsured${index}`]: {
                model: underlyingPolicyVM.primaryNamedInsured,
                onModelChange,
                labelPosition,
                viewOnlyMode: viewOnlyMode || underlyingPolicyIsImportedInd,
                onValidate: setComponentValidation,
                hideDOB: true,
                showErrors,
                index
            },
            [`secondaryNamedInsured${index}`]: {
                model: underlyingPolicyVM.secondaryNamedInsured,
                onModelChange,
                labelPosition,
                viewOnlyMode: viewOnlyMode || underlyingPolicyIsImportedInd,
                isPNI: false,
                hideDOB: true,
                onValidate: setComponentValidation,
                visible: get(underlyingPolicyVM, 'secondaryNamedInsured.value', false),
                showErrors,
                index
            },
            [`addSni${index}`]: {
                visible: !get(underlyingPolicyVM, 'secondaryNamedInsured.value', false) && !viewOnlyMode && !underlyingPolicyIsImportedInd,
                disabled: viewOnlyMode
            },
            [`removeSni${index}`]: {
                visible: get(underlyingPolicyVM, 'secondaryNamedInsured.value', false) && !viewOnlyMode && !underlyingPolicyIsImportedInd,
                disabled: viewOnlyMode
            },
            [`personalVehicleExposures${index}`]: {
                model: underlyingPolicyVM.personalVehicleExposures,
                onModelChange,
                labelPosition,
                viewOnlyMode,
                onValidate: setComponentValidationForExp,
                showErrors,
                parentIndex: index
            },
            [`personalVehicleExposureTab${index}`]: {
                className: isEqual(selectedPolicyType, 'personalauto') ? 'tabContainer' : 'hiddenTab',
                heading: renderCompletionHeader(messages.vehicleExp, isExpValid)
            },
            [`recreationalVehicleExposures${index}`]: {
                model: underlyingPolicyVM.recreationalVehicleExposures,
                onModelChange,
                labelPosition,
                viewOnlyMode,
                onValidate: setComponentValidationForExp,
                showErrors,
                parentIndex: index
            },
            [`recreationalVehicleExposureTab${index}`]: {
                className: isEqual(selectedPolicyType, 'otherspecialityvehicle') ? 'tabContainer' : 'hiddenTab',
                heading: renderCompletionHeader(messages.recreationVehicleExp, isExpValid)
            },
            [`watercraftExposures${index}`]: {
                model: underlyingPolicyVM.watercraftExposures,
                onModelChange,
                labelPosition,
                viewOnlyMode,
                onValidate: setComponentValidationForExp,
                showErrors,
                parentIndex: index
            },
            [`watercraftExposureTab${index}`]: {
                className: isEqual(selectedPolicyType, 'personalwatercraft') ? 'tabContainer' : 'hiddenTab',
                heading: renderCompletionHeader(messages.watercraftExp, isExpValid)
            },
            [`cycleExposures${index}`]: {
                model: underlyingPolicyVM.cycleExposures,
                onModelChange,
                labelPosition,
                viewOnlyMode,
                onValidate: setComponentValidationForExp,
                showErrors,
                parentIndex: index
            },
            [`cycleExposureTab${index}`]: {
                className: isEqual(selectedPolicyType, 'cycle') ? 'tabContainer' : 'hiddenTab',
                heading: renderCompletionHeader(messages.cycleExp, isExpValid)
            },
            [`propertyExposure${index}`]: {
                model: underlyingPolicyVM.personalPropertyExposure,
                onModelChange,
                setPrimaryHomeIndicator,
                carrierName: underlyingPolicyVM.carrierName,
                viewOnlyMode,
                onValidate: setComponentValidationForExp,
                showErrors,
                parentIndex: index,
                underlyingPolicyIsImportedInd
            },
            [`propertyExposureTab${index}`]: {
                className: isEqual(selectedPolicyType, 'personalproperty') ? 'tabContainer' : 'hiddenTab',
                heading: renderCompletionHeader(messages.personalPropertyExp, isExpValid)
            },
            [`additionalPremises${index}`]: {
                model: underlyingPolicyVM.personalPropertyExposure.additionalPremises,
                onModelChange,
                labelPosition,
                viewOnlyMode,
                showErrors,
                parentIndex: index
            },
            [`additionalPremiseTab${index}`]: {
                className: 'hiddenTab'
                // isEqual(selectedPolicyType, 'personalproperty')
                // && get(underlyingPolicyVM, 'personalPropertyExposure.value', false)
                //     ? 'tabContainer'
                //     : 'hiddenTab',
            },
            [`rentalDwellingExposureTab${index}`]: {
                className: isEqual(selectedPolicyType, 'commercialproperty') ? 'tabContainer' : 'hiddenTab',
                heading: renderCompletionHeader(messages.renterDwellingExp, isExpValid)
            },
            [`rentalDwellingExposures${index}`]: {
                model: underlyingPolicyVM.rentalDwellingExposures,
                onModelChange,
                labelPosition,
                viewOnlyMode,
                onValidate: setComponentValidationForExp,
                showErrors,
                parentIndex: index
            },
            [`otherCarrierName${index}`]: {
                visible: isEqual(get(underlyingPolicyVM, 'carrierName.value.code', null), 'other'),
            },
            [`pniSniQuestionDivider${index}`]: {
                visible: (!isEqual(selectedPolicyType, 'personalproperty') && !isPniOrSniMatch) || isEqual(selectedPolicyType, 'personalproperty')
            },
            [`propertyDetailsContainer${index}`]: {
                visible: isEqual(selectedPolicyType, 'personalproperty'),
            },
            [`coverageHeader${index}`]: {
                visible: !isEqual(selectedPolicyType, null),
            },
            [`coverageEContainer${index}`]: {
                visible: selectedPolicyType === 'personalwatercraft'
                    ? watercraftLiabilityCoverageInd === 'homeowners'
                    : includes(
                        ['personalproperty', 'commercialproperty', 'personalwatercraft'],
                        selectedPolicyType
                    )
            },
            [`vehicleCoveragesContainer${index}`]: {
                visible: selectedPolicyType === 'personalwatercraft'
                    ? watercraftLiabilityCoverageInd === 'personalwatercraft'
                    : includes(
                        [
                            'personalaononowner',
                            'personalauto',
                            'commercialvehicle',
                            'otherspecialityvehicle',
                            'cycle',
                            'personalwatercraft'
                        ],
                        selectedPolicyType
                    )
            },
            [`UMUIMCoveragesContainer${index}`]: {
                visible: !includes(['personalwatercraft'], selectedPolicyType)
            },
            [`watercraftLiabilityDropdown${index}`]: {
                visible: includes(['personalwatercraft'], selectedPolicyType),
                availableValues: watercraftLiabilityCoverageAvailableValues,
                value: watercraftLiabilityCoverageInd,
                onValueChange: onWatercraftLiabilityIndChange
            },
            // PERSONAL PROPERTY EXPOSURE LIMIT
            [`coverageELiabilityLimit${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'coverageELiabilityCovLimit.value'),
                label: includes(
                    ['personalproperty', 'commercialproperty'],
                    selectedPolicyType
                ) ? messages.propertyCoverageELabel : messages.otherCoverageELabel
            },
            // COMBINED SINGLE LIMITS BELOW
            [`CSLPerOccurrenceLimit${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'combinedSingleLimitCovPerOccLimit.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxCombinedSingleLimit,
                minLimit: minCombinedSingleLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            [`uninsuredMotoristCovPerOccCSL${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'uninsuredMotoristCovPerOccCSL.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxCombinedSingleLimit,
                minLimit: minCombinedSingleLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            [`underinsuredMotoristCovPerOccCSL${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'underinsuredMotoristCovPerOccCSL.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxCombinedSingleLimit,
                minLimit: minCombinedSingleLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            [`UMUIMCovPerOccCSL${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'umuimcovPerOccCSL.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxCombinedSingleLimit,
                minLimit: minCombinedSingleLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            // END CSL Fields
            // SPLIT LIMITS BELOW
            [`BIPerPersonLimit${index}`]: {
                // multiSelectClasses: [styles.euCoverageLimit, styles.subField],
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'bodilyInjuryCovPerPersonLimit.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxSplitLimit,
                minLimit: minSplitLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            [`BIPerOccurrenceLimit${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'bodilyInjuryCovPerOccLimit.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxSplitLimit,
                minLimit: minSplitLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            [`propertyDamagePerOccLimit${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'propertyDamageCovPerOccLimit.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxSplitLimit,
                minLimit: minSplitLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            [`uninsuredMotoristPerPersonLimit${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'uninsuredMotoristCovPerPersonLimit.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxSplitLimit,
                minLimit: minSplitLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            [`uninsuredMotoristCovPerOccLimit${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'uninsuredMotoristCovPerOccLimit.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxSplitLimit,
                minLimit: minSplitLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            [`underinsuredMotoristPerPersonLimit${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'underinsuredMotoristCovPerPersonLimit.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxSplitLimit,
                minLimit: minSplitLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            [`underinsuredMotoristCovPerOccLimit${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'underinsuredMotoristCovPerOccLimit.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxSplitLimit,
                minLimit: minSplitLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            [`UMUIMPerPersonLimit${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'umuimcovPerPersonLimit.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxSplitLimit,
                minLimit: minSplitLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            [`UMUIMCovPerOccLimit${index}`]: {
                onLimitChange: writeValue,
                limit: get(underlyingPolicyVM, 'umuimcovPerOccLimit.value'),
                gridClasses: [styles.euCoverageLimit, styles.subField],
                maxLimit: maxSplitLimit,
                minLimit: minSplitLimit,
                limitIncrement: LIMIT_INCREMENT
            },
            // END Auto split limit fields
            [`jurisdiction${index}`]: {
                visible: !underlyingPolicyVM.isManuallyAddedInd.value
            },
            [`underlyingPolicyTab${index}`]: {
                heading: renderCompletionHeader(messages.ulPolicyDetails, isComponentValid)
            },
            [`combinedSingleLimitRadioButton${index}`]: {
                value: policyLevelCoverageBundle,
                onValueChange: onPolicyLevelCoverageBundleChange,
                readOnly: viewOnlyMode || underlyingPolicyIsImportedInd
            },
            [`combinedSingleLimitContainer${index}`]: {
                visible: !isSplitLimit
            },
            [`splitLimitContainer${index}`]: {
                visible: isSplitLimit
            },
            [`policyNumber${index}`]: {
                required: get(underlyingPolicyVM, 'value.isManuallyAddedInd', false)
            }
        };
    }, [
        underlyingPolicyVM,
        visible,
        showErrors,
        labelPosition,
        viewOnlyMode,
        onChangeOfPolicyType,
        setComponentValidation,
        onModelChange,
        setComponentValidationForExp,
        isExpValid, setPrimaryHomeIndicator,
        watercraftLiabilityCoverageInd,
        watercraftLiabilityCoverageAvailableValues,
        isComponentValid,
        writeValue,
        onPolicyLevelCoverageBundleChange,
        policyLevelCoverageBundle,
        isSplitLimit,
        index,
        isPniOrSniMatch,
        maxCombinedSingleLimit,
        minCombinedSingleLimit,
        maxSplitLimit,
        minSplitLimit
    ]);

    const overrideProps = {
        ...generateOverrides(),
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddSni,
            onRemoveSni,
            onPolicyLevelCoverageBundleChange

        },
        resolveComponentMap: {
            additionalpremise: EUAdditionalPremises,
            namedinsured: UnderlyingPolicyNamedInsured,
            propertyexposure: EUPersonalPropertyExposure,
            rentaldwellings: EURentalDwellings
        },
    };

    const readValue = (fieldId, path) => {
        return readViewModelValue(
            metadata.pageContent,
            underlyingPolicyVM,
            fieldId,
            path,
            overrideProps
        );
    };

    return (
        <ViewModelForm
            uiProps={formattedMetadata}
            model={underlyingPolicyVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={writeValue}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

UnderlyingPolicyDetails.propTypes = {
    model: PropTypes.shape({}).isRequired,
    onModelChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    labelPosition: PropTypes.string,
    visible: PropTypes.bool,
    viewOnlyMode: PropTypes.bool,
    setUnderlyingPolicyIsValid: PropTypes.func,
    id: PropTypes.string,
    showErrors: PropTypes.bool,
    index: PropTypes.number.isRequired
};

UnderlyingPolicyDetails.defaultProps = {
    labelPosition: 'top',
    visible: true,
    viewOnlyMode: false,
    setUnderlyingPolicyIsValid: () => { },
    id: '',
    showErrors: false
};

export default UnderlyingPolicyDetails;
