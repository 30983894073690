import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class PaymentService {
    static retrievePaymentDetailsForTransactionId(transactionID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('payments'),
            'retrievePaymentDetailsForTransactionId',
            [transactionID], additionalHeaders);
    }
}
