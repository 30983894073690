import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invocation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSideBySideRequest(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('sidebyside'), method, data, additionalHeaders);
}

/**
 * This is the service responsible for dealing with the Side By Side Quoting operations
 *
 * @export
 * @class SideBySideService
 */
export default class SideBySideService {
    /**
     * Assign version as the Selected Version
     *
     * @param {Object} qdd QuoteDataDTO
     * @param {String} versionID ID of the quote version that user selects
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof SideBySideService
     */
    static selectVersion(qdd, versionID, additionalHeaders = {}) {
        return processSideBySideRequest('selectVersion', [qdd, versionID], additionalHeaders);
    }

    /**
     * Entering SideBySide Mode. Creates side by side versions from default version.
     * If already in side by side, the data from the selected period wil be copied to
     * the other versions.
     *
     * @param {Object} qdd QuoteDataDTO
     * @returns {Promise} the promise from the backend call - QuoteDataDTO
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof SideBySideService
     */
    static enterSideBySideMode(qdd, additionalHeaders = {}) {
        return processSideBySideRequest('enterSideBySideMode', [qdd], additionalHeaders);
    }

    /**
     * Add a new SideBySide Version.
     * @param {Object} QuoteDataDTO String
     * @param {*} additionalHeaders
     * @returns {Promise} the promise from the backend call - QuoteDataDTO
     * @memberof SideBySideService
     */
    static addNewSideBySideVersion(QuoteDataDTO, additionalHeaders = {}) {
        return processSideBySideRequest('addNewSideBySideVersion', [QuoteDataDTO], additionalHeaders);
    }

    /**
     * Update the name of a SideBySide version.
     *
     * @param {Object} qdd QuoteDataDTO
     * @param {String} versionID ID of the quote version that user selects
     * @param {String} versionName New name
     * @returns {Promise} the promise from the backend call - QuoteDataDTO
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof SideBySideService
     */
    static updateSideBySideQuoteName(qdd, versionID, versionName, additionalHeaders = {}) {
        return processSideBySideRequest('updateSideBySideQuoteName', [qdd, versionID, versionName], additionalHeaders);
    }

    /**
     * Withdraw the specified SideBySide version.
     *
     * @param {Object} qdd QuoteDataDTO
     * @param {String} periodToWithdrawID ID of the policy period to withdraw
     * @returns {Promise} the promise from the backend call - QuoteDataDTO
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof SideBySideService
     */
    static withdrawSideBySideVersion(qdd, periodToWithdrawID, additionalHeaders = {}) {
        return processSideBySideRequest('withdrawSideBySideVersion', [qdd, periodToWithdrawID], additionalHeaders);
    }

    /**
     * Performing save & quote operations on all the given SXS versions
     *
     * @param {String} sideBySideVersions An array of custom quote dtos
     * @returns {Promise} the promise from the backend call - CustomQuoteDTO
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof SideBySideService
     */
    static saveAndQuoteSideBySideVersions(sideBySideVersions, additionalHeaders = {}) {
        return processSideBySideRequest('saveAndQuoteSideBySideVersions', [sideBySideVersions], additionalHeaders);
    }
}
