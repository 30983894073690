import React, { useEffect, useState, useCallback } from 'react';
import {
    get, set, noop
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useModal } from '@jutro/components';
import { CancellationService } from 'e1p-capability-gateway';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useUWIssueUtil } from 'e1p-capability-hooks';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { commonMessages } from 'e1p-platform-translations';
import htmlParser from 'html-react-parser';
import metadata from './BindCancellationPage.metadata.json5';
import styles from './BindCancellationPage.module.scss';
import messages from './BindCancellationPage.messages';

function BindCancellationPage(props) {
    const modalApi = useModal();
    const {
        wizardData,
        updateWizardData,
        steps,
        jumpTo
    } = props;
    const { submissionVM } = wizardData;
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const [showCancel, setShowCancel] = useState(true);
    const [isProcessingCancellation, setIsProcessingCancellation] = useState(false);
    const [isAbinito, setIsAbinito] = useState(false);
    const [isUWTermination, setIsUWTermination] = useState(false);
    const [isInteractiveStarted, setIsInteractiveStarted] = useState(false);
    const [interactiveErrorMessage, setInteractiveErrorMessage] = useState(false);
    const [deliverDocInd, setDeliverDocInd] = useState(true);
    // in SubmissionVM we are getting JobDTO not CancellationDTO, from the backend
    const isQuoted = get(submissionVM, 'latestPeriod.status.value') === 'Quoted';
    const jobID = get(submissionVM, 'value.jobNumber');
    const redirectURL = `/cancellation/${jobID}/risk-analysis`;
    const {
        hasUWIssues,
        showUnderwritingIssuesPopup
    } = useUWIssueUtil(
        submissionVM,
        updateWizardData,
        jumpTo,
        steps,
        redirectURL
    );
    const isSourceInsured = get(submissionVM, ['value', 'cancellationSource']) === 'insured';
    
    const getCancellationSummary = () => {
        const { history } = props;

        return history.push({
            pathname: `/cancellation/${submissionVM.value.jobNumber}/summary`,
            isRouteChangedDuringFlow: true
        });
    };

    const checkAndShowUWIssues = useCallback(() => {
        if (hasUWIssues()) {
            showUnderwritingIssuesPopup();
        }
    }, [hasUWIssues, showUnderwritingIssuesPopup]);

    useEffect(() => {
        const vm = get(submissionVM, 'value');

        if (vm.deferredLetterStatus_Ext === 'Pending') {
            setIsInteractiveStarted(true);
        }

        // If policy is in canceling status or reason is under writer for company cancelled policy hide Cancel Now button
        if (vm.status === 'Canceling' || (!isSourceInsured && vm.cancelReasonCode === 'uwreasons')) {
            setShowCancel(false);
        }

        if (vm.cancelReasonCode === 'abInitio_ext') {
            setIsAbinito(true);
        }

        if (vm.cancelReasonCode === 'uwreasons') {
            setIsUWTermination(true);
        }

        checkAndShowUWIssues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Returns title and message for cancel confirmation modal
    const getCancelModalTitleAndMessage = () => {
        // If there are future bound renewals on policy
        if (get(submissionVM, ['value', 'hasFutureBoundRenewal_Ext'])) {
            return {
                title: translator(messages.cancelCurrentAndRenewalTermsTitle),
                message: htmlParser(translator(messages.boundRenewalCancellationMessage))
            };
        }

        // If there are future unbound renewals on policy
        if (get(submissionVM, ['value', 'hasFutureUnBoundRenewal_Ext'])) {
            return {
                title: translator(messages.cancelCurrentAndRenewalTermsTitle),
                message: htmlParser(translator(messages.unBoundRenewalCancellationMessage))
            };
        }

        // If there are no future renewal for the policy
        return {
            title: translator(messages.bindCancellation),
            message: translator(messages.cancellationMessage)
        };
    };

    const onCancelNowClickHandler = () => {
        modalApi.showConfirm({
            ...getCancelModalTitleAndMessage(),
            confirmButtonText: commonMessages.confirm,
            cancelButtonText: commonMessages.cancel
        }).then((results) => {
            if (results === 'cancel') {
                return noop();
            }

            setIsProcessingCancellation(true);
            CancellationService
                .bind(get(submissionVM.value, 'jobNumber'), deliverDocInd, authHeader)
                .then(
                    (res) => {
                        if (get(res, ['errorsAndWarnings', 'validationIssues', 'issues', 0, 'type']) === 'error') {
                            setIsProcessingCancellation(false);
                            setIsInteractiveStarted(false);
                            setInteractiveErrorMessage(true);
                        } else if (res && res.status !== 'Bound') {
                            // not able to bind the cancellation
                            set(submissionVM, 'value', res);

                            const uwIssuesInRespone = get(res, 'errorsAndWarnings');

                            if (uwIssuesInRespone) {
                                set(submissionVM, 'value.errorsAndWarnings_Ext', uwIssuesInRespone);
                            }

                            updateWizardData(submissionVM);
                            checkAndShowUWIssues();
                        } else {
                            setIsProcessingCancellation(false);
                            getCancellationSummary();
                        }
                    },
                    () => {
                        setIsProcessingCancellation(false);
                        modalApi.showAlert({
                            status: 'error',
                            icon: 'mi-error-outline',
                            title: messages.cancelNowButton,
                            message: messages.failedToBindCancellation
                        });
                    }
                );
        }, () => { });
    };

    const createInteractiveHandler = () => {
        const interactiveLetterURL = get(submissionVM.value, 'interactiveLetterRedirectURL_Ext');

        setIsInteractiveStarted(true);
        window.open(`${interactiveLetterURL}`);
    };

    const onScheduleCancelClickHandler = () => {
        modalApi.showConfirm({
            ...getCancelModalTitleAndMessage(),
            confirmButtonText: commonMessages.confirm,
            cancelButtonText: commonMessages.cancel
        }).then((results) => {
            if (results === 'cancel') {
                return noop();
            }

            setIsProcessingCancellation(true);

            const schedulDate = get(submissionVM.value, 'effectiveDate.isodate_Ext') ? get(submissionVM.value, 'effectiveDate.isodate_Ext') : get(submissionVM.value, 'latestPeriod.cancellationDate');

            CancellationService
                .scheduleCancellation(get(submissionVM.value, 'jobNumber'), schedulDate, authHeader)
                .then(
                    (response) => {
                        if (get(response, ['errorsAndWarnings', 'validationIssues', 'issues', 0, 'type']) === 'error') {
                            setIsProcessingCancellation(false);
                            setIsInteractiveStarted(false);
                            setInteractiveErrorMessage(true);
                        } else if (response && response.status !== 'Canceling') {
                            // not able to bind the cancellation
                            set(submissionVM, 'value', response);

                            const uwIssuesInRespone = get(response, 'errorsAndWarnings');

                            if (uwIssuesInRespone) {
                                set(submissionVM, 'value.errorsAndWarnings_Ext', uwIssuesInRespone);
                            }

                            updateWizardData(submissionVM);
                            checkAndShowUWIssues();
                        } else {
                            setIsProcessingCancellation(false);
                            getCancellationSummary();
                        }
                    },
                    () => {
                        setIsProcessingCancellation(false);
                        modalApi.showAlert({
                            status: 'error',
                            icon: 'mi-error-outline',
                            title: messages.cancelNowButton,
                            message: messages.failedToBindCancellation
                        });
                    }
                );
        }, () => { });
    };

    const onWithdrawClickHandler = () => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: translator(messages.withdrawCancellation),
            message: translator(messages.wantWithdrawCancellation),
            iconClassType: false,
            confirmButtonText: translator(commonMessages.withdrawTransaction, { transaction: 'CANCELLATION' }),
            cancelButtonText: translator(commonMessages.cancel)
        }).then((results) => {
            if (results === 'cancel') {
                return noop();
            }

            setIsProcessingCancellation(true);
            CancellationService
                .withdrawCancellationByCancellationNumber(get(submissionVM.value, 'jobNumber'), authHeader)
                .then(
                    () => {
                        setIsProcessingCancellation(false);
                        getCancellationSummary();
                    },
                    () => {
                        setIsProcessingCancellation(false);
                        modalApi.showAlert({
                            status: 'error',
                            icon: 'mi-error-outline',
                            title: messages.modalError,
                            message: messages.failedWithDrawCancellation
                        });
                    }
                );
        }, () => { });
    };

    const onRescindClickHandler = () => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: translator(messages.rescindCancellation),
            message: translator(messages.wantRescindCancellation),
            iconClassType: false,
            confirmButtonText: translator(commonMessages.confirm),
            cancelButtonText: translator(commonMessages.cancel)
            
        }).then(() => {
            setIsProcessingCancellation(true);
            CancellationService
                .rescindCancellation(get(submissionVM.value, 'jobNumber'), authHeader)
                .then(
                    () => {
                        setIsProcessingCancellation(false);
                        getCancellationSummary();
                    },
                    () => {
                        setIsProcessingCancellation(false);
                        modalApi.showAlert({
                            status: 'error',
                            icon: 'mi-error-outline',
                            title: messages.modalError,
                            message: messages.failedRescindCancellation
                        });
                    }
                );
        }, () => { });
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onScheduleCancelClickHandler,
            onWithdrawClickHandler,
            onCancelNowClickHandler,
            createInteractiveHandler,
            onRescindClickHandler
        }
    };

    /**
     * Schedule Cancellation Button will be visible if 
     * source is not insured and policy is not in cancelling status
     * and cancel reason code is not in [abInitio_ext, rewritencspecific_ext]
     */
    const isScheduleCancellationButtonVisible = useCallback(
        () =>
            !isSourceInsured &&
            get(submissionVM.value, 'status') !== 'Canceling' &&
            !isAbinito &&
            get(submissionVM, ['cancelReasonCode', 'value', 'code']) !==
                'rewritencspecific_ext',
        [isAbinito, isSourceInsured, submissionVM]
    );

    /**
     * Create Interactive Button will be visible if 
     * cancellation source is not insured and
     * cancel reason code is not rewritencspecific_ext
     */
    const isCreateInteractiveLetterButtonVisible = useCallback(
        () =>
            !isSourceInsured &&
            get(submissionVM, ['cancelReasonCode', 'value', 'code']) !==
                'rewritencspecific_ext',
        [isSourceInsured, submissionVM]
    );

    const overrides = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        bindCancellationPageLoadingIndicator: {
            loaded: !isProcessingCancellation,
            text: translator(messages.completingYourCancellationMessage)
        },
        bindCancellationPageContainer: {
            visible: !isProcessingCancellation
        },
        scheduleButtonId: {
            visible: isScheduleCancellationButtonVisible(),
            disabled: !isQuoted
        },
        createButtonId: {
            onClick: createInteractiveHandler,
            disabled: isInteractiveStarted || get(submissionVM.value, 'status') === 'Canceling',
            visible: isCreateInteractiveLetterButtonVisible()
        },
        interactivePendingMessageDiv: {
            visible: interactiveErrorMessage
        },
        cancelNowButtonId: {
            visible: showCancel,
            onClick: onCancelNowClickHandler,
            disabled: !isQuoted,
            // setting style of the button
            type: (isAbinito && !isInteractiveStarted) ? 'outlined': 'filled'
        },
        withdrawButtonId: {
            visible: get(submissionVM.value, 'status') !== 'Canceling',
        },
        rescindButtonId: {
            visible: get(submissionVM.value, 'status') === 'Canceling'
                // if reason is uwreason, then user has to have rescinduwtermination_ext permission
                && (authUserData.permissions_Ext.includes('rescinduwtermination_ext') || !isUWTermination)
                // Agent cannot rescind carrier cancellations + NSF(shows as insured category and they wont change it)
                && !(authUserData.roles_Ext.includes('ext_sales_service') && submissionVM.cancelReasonCode.aspects.availableValues.find((code) => code.code === submissionVM.value.cancelReasonCode)?.belongsToCategory({
                    code: 'carrier',
                    typelist: { name: 'CancellationSource' }
                }))
                // Agent cannot rescind NSF(shows as insured category and they wont change it)
                && !(authUserData.roles_Ext.includes('ext_sales_service') && submissionVM.value.cancelReasonCode === 'nonsufficientfunds_ext')
        },
        paidToDate: {
            visible: get(submissionVM, ['cancelReasonCode', 'value', 'code']) === 'nonsufficientfunds_ext',
            dateDTO: submissionVM.billingPaidThroughDate_Ext,
            readOnly: true
        },
        deliverDocumentsIndComponent: {
            visible: authUserData.permissions_Ext.includes('docdeliveryind_ext'),
            deliverDocInd,
            setDeliverDocInd
        },
        // IAP-5583: Backend has two different fields for description based on cancellation Source i.e Company or Insured
        transactionDescriptionId: {
            value: get(submissionVM, 'value.description', get(submissionVM, 'value.uwactionDTO_Ext.reasonDescription'))
        }
    };

    return (
        <WizardPage
            isLoadingWholePage={isProcessingCancellation}
            showPrevious={false}
            showNext={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

BindCancellationPage.propTypes = wizardProps;
export default withRouter(BindCancellationPage);
