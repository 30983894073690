import { defineMessages } from 'react-intl';

export default defineMessages({
    addDriver: {
        id: 'AddDriverComponent.Add Driver',
        defaultMessage: 'Add Driver'
    },
    new: {
        id: 'AddDriverComponent.New',
        defaultMessage: 'New'
    },
    newPerson: {
        id: 'AddDriverComponent.New Person',
        defaultMessage: 'New Person'
    },
    existingPerson: {
        id: 'AddDriverComponent.Existing Person',
        defaultMessage: 'Existing Person'
    }
});
