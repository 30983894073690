import { defineMessages } from 'react-intl';

export default defineMessages({
    account: {
        id: 'e1p.gateway.views.quote-detail.Account',
        defaultMessage: 'Account'
    },
    activities: {
        id: 'e1p.gateway.views.quote-detail.Activities',
        defaultMessage: 'Activities'
    },
    continueQuote: {
        id: 'e1p.gateway.views.quote-detail.Continue Quote',
        defaultMessage: 'Continue Quote'
    },
    createdDate: {
        id: 'e1p.gateway.views.quote-detail.Created Date',
        defaultMessage: 'Date Created'
    },
    documents: {
        id: 'e1p.gateway.views.quote-detail.Documents',
        defaultMessage: 'Documents'
    },
    effectiveDate: {
        id: 'e1p.gateway.views.quote-detail.Effective Date',
        defaultMessage: 'Effective Date'
    },
    note: {
        id: 'e1p.gateway.views.quote-detail.Note',
        defaultMessage: 'Note'
    },
    notes: {
        id: 'e1p.gateway.views.quote-detail.Notes',
        defaultMessage: 'Notes'
    },
    openActivities: {
        id: 'e1p.gateway.views.quote-detail.Open Activities',
        defaultMessage: 'Open Activities'
    },
    openIssues: {
        id: 'e1p.gateway.views.quote-detail.Open Issues',
        defaultMessage: 'Open UW Issues'
    },
    policy: {
        id: 'e1p.gateway.views.quote-detail.Policy',
        defaultMessage: 'Policy'
    },
    premium: {
        id: 'e1p.gateway.views.quote-detail.Premium',
        defaultMessage: 'Premium'
    },
    status: {
        id: 'e1p.gateway.views.quote-detail.Status',
        defaultMessage: 'Status'
    },
    summary: {
        id: 'e1p.gateway.views.quote-detail.Summary',
        defaultMessage: 'Summary'
    },
    premiumQuoteCalculated: {
        id: 'e1p.gateway.views.quote-detail.The premium for this quote has been calculated',
        defaultMessage: 'The premium for this quote has been calculated'
    },
    quoteHasBeenBound: {
        id: 'e1p.gateway.views.quote-detail.This quote has been bound',
        defaultMessage: 'This quote has been bound'
    },
    quoteSuccessfullyStarted: {
        id: 'e1p.gateway.views.quote-detail.This quote has been successfully started',
        defaultMessage: 'This quote has been successfully started.'
    },
    thisQuoteHasBeenWithDrawn: {
        id: 'e1p.gateway.views.quote-detail.This quote has been withdrawn',
        defaultMessage: 'This quote has been withdrawn.'
    },
    withdrawQuote: {
        id: 'e1p.gateway.views.quote-detail.WithdrawQuote',
        defaultMessage: 'Withdraw Quote'
    },
    continiueQuoteQizard: {
        id: 'e1p.gateway.views.quote-detail.You may either withdraw or continue to the quote',
        defaultMessage: 'You may either withdraw or continue to the quote.'
    },
    changeQuoteWizardToBind: {
        id: 'e1p.gateway.views.quote-detail.You may either withdraw or continue to the quote where you can bind the quote.',
        defaultMessage: 'You may either withdraw or continue to the quote where you can bind the quote.'
    },
    mayWithdrawQuote: {
        id: 'e1p.gateway.views.quote-detail.You may withdraw the quote',
        defaultMessage: 'You may withdraw the quote'
    },
    sureWithDrawQuote: {
        id: 'e1p.gateway.directives.JobSummary.Are you sure you want to withdraw quote:',
        defaultMessage: 'Are you sure you want to withdraw quote:'
    },
    failedWithdrawSubmission: {
        id: 'e1p.gateway.directives.JobSummary.Failed to withdraw submission',
        defaultMessage: 'Failed to withdraw submission'
    },
    withdraw: {
        id: 'e1p.gateway.directives.JobSummary.Withdraw',
        defaultMessage: 'Withdraw'
    },
    withdrawJob: {
        id: 'e1p.gateway.directives.JobSummary.WithdrawJob',
        defaultMessage: 'Withdraw Quote?'
    },
    uwIssuesHaveBeenRaised: {
        id: 'e1p.gateway.directives.quote-detail.Underwriting issues have been raised for this quote.',
        defaultMessage: 'Underwriting issues have been raised for this quote.'
    },
    uwIssuesHaveBeenApproved: {
        id: 'e1p.gateway.directives.quote-detail.All underwriting issues have been approved for this quote',
        defaultMessage: 'All underwriting issues have been approved for this quote'
    },
    preferredUnderwriter: {
        id: 'e1p.gateway.views.quote-detail.Preferred Underwriter',
        defaultMessage: 'Preferred Underwriter'
    },
    termEffectiveDate: {
        id: 'e1p.gateway.views.policy-detail-summary.Term Effective Date',
        defaultMessage: 'Term Effective Date'
    },
    viewQuote: {
        id: 'e1p.gateway.views.quote-detail.View Quote',
        defaultMessage: 'View Quote'
    },
});
