import React, { useCallback, useState, useMemo } from 'react';
import {
    get as _get
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useUWIssueUtil, useLandingPageUtil } from 'e1p-capability-hooks';
import metadata from '../RiskAnalysisPage.metadata.json5';
import styles from '../RiskAnalysisPage.module.scss';
import messages from '../RiskAnalysisPage.messages';

const LOB = 'homeowners_EH';

function ViewRiskAnalysisPage(props) {
    // eslint-disable-max-len
    const { authHeader } = useAuthentication();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        authUserData
    } = props;

    const jobNumber = _get(policyChangeVM, 'jobID.value');
    const policyChangeSource = _get(policyChangeVM, 'value.policyChangeSource', '');
    const policyChangeReason = _get(policyChangeVM, 'value.policyChangeReason', '');
    const [creditReportResponse, setCreditReportResponse] = useState({});
    const [geoDataResponse, setGeoDataResponse] = useState({});
    const [responseAuto, setResponseAuto] = useState([]);
    const [responseProperty, setResponseProperty] = useState({});
    const translator = useTranslator();
    const {
        onValidate
    } = useValidation('ViewRiskAnalysisPage');

    const {
        hasUWIssues
    } = useUWIssueUtil(
        policyChangeVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const {
        getDefaultTab
    } = useLandingPageUtil();

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');

    const defaultTabBasedOnReason = useMemo(() => getDefaultTab(LOB, policyChangeSource, policyChangeReason), [getDefaultTab, policyChangeSource, policyChangeReason]);

    const saveAndQuote = useCallback(() => policyChangeVM, [policyChangeVM]);

    const uwIssuesPresent = useMemo(() => 
         hasUWIssues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [policyChangeVM?.errorsAndWarnings_Ext?.underwritingIssues?.value]);

    const getDefaultActiveTab = useMemo(() => {
        if (uwIssuesPresent) {
            return 'uwIssuesTab';
        }

        return defaultTabBasedOnReason === false ? 'lossesAndViolationTab' : defaultTabBasedOnReason;
    }, [uwIssuesPresent, defaultTabBasedOnReason]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
        },
    };

    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(policyChangeVM, 'lobData.homeowners_EH.premiumStability.value') !== undefined;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: true,
            defaultActiveTab: getDefaultActiveTab
        },
        ehPremiumComponent: {
            lobDataModel: policyChangeVM.lobData.homeowners_EH,
            productCode: policyChangeVM.baseData.productCode.value
        },
        lossesAndViolationComponent: {
            responseAuto,
            setResponseAuto,
            responseProperty,
            setResponseProperty,
            submissionVM: policyChangeVM,
            updateWizardData,
            setLossesPageValid: () => {},
            isSkipping,
            authUserData,
            viewOnlyMode: true
        },
        creditReportsComponent: {
            submissionVM: policyChangeVM,
            creditReportResponse,
            setCreditReportResponse,
            updateWizardData,
            lineOfBusiness: policyChangeVM.lobData.homeowners_EH,
            lobName: 'homeowners_EH',
            authUserData,
            id: 'creditReportsComponent',
            onValidate,
            setOverlappingException: () => {},
            viewOnlyMode: true
        },
        geoDataComponent: {
            quoteID: jobNumber,
            geoDataResponse,
            setGeoDataResponse,
            policyState: _get(policyChangeVM, 'baseData.policyAddress.state.value.code')
        },
        uwIssueComponent: {
            submissionVM: policyChangeVM,
            updateWizardData,
            authHeader,
            viewOnlyMode: true
        },
        EHRiskAnalysisMiscComponentId: {
            submissionVM: policyChangeVM,
            visible: true
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(policyChangeVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            showErrors: false,
            viewOnlyMode: true
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    return (
        <WizardPage
            onNext={saveAndQuote}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewRiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewRiskAnalysisPage);
