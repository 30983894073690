import { defineMessages } from 'react-intl';

export default defineMessages({
    heatingUnitType: {
        id: 'quoteandbind.eh.views.component.firePlace.Choose Heating Unit Type',
        defaultMessage: 'Choose Heating Unit Type',
    },
    description: {
        id: 'quoteandbind.eh.views.component.firePlace.Description',
        defaultMessage: 'Description',
    },
    quantity: {
        id: 'quoteandbind.eh.views.component.firePlace.Quantity',
        defaultMessage: 'Quantity',
    }
});
