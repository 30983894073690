import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import {
    get, set, some, isEmpty
} from 'lodash';
import { E1PEHHO3PropertyDetailsComponent } from 'e1p-capability-policyjob-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useLandingPageUtil } from 'e1p-capability-hooks';
import { PrefillService } from 'e1p-capability-quoteandbind';
import { EndorsementService } from 'e1p-capability-policychange';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';

const LOB = 'homeowners_EH';

function PropertyPage(props) {
    const {
        wizardData: policyChangeVM, updateWizardData, isSkipping, steps, jumpTo, updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [prefillData, setPrefillData] = useState('');
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [floorMaterialErrorState, updateFloorMaterialErrorState] = useState(false);
    const [insideWallMaterialPercentageSumErrorState, updateInsideWallMaterialPercentageSumErrorState] = useState(false);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isReplacementCostStale, setIsReplacementCostStale] = useState(false);
    const [coolingSystemType, updateCoolingSystemType] = useState(undefined);
    const [heatingSystemType, updateHeatingSystemType] = useState(undefined);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isOutstandingChangesPresent, setIsOutstandingChangesPresent] = useState(true);
    const [isReplacementRecalculateCompleted, setIsReplacementRecalculateCompleted] = useState(true);
    const [isSubmittedWithoutRc, updateIsSubmittedWithoutRc] = useState(false);
    const hasReplacementCost = get(policyChangeVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value', undefined);
    
    const {
        isComponentValid,
        initialValidation,
        registerComponentValidation,
        disregardFieldValidation,
        onValidate
    } = useValidation('PropertyPage');

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    const onNext = useCallback(
        async (_, calledFromOnSave) => {
            // missing fields
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                setIsOutstandingChangesPresent(true);
                updateIsSubmittedWithoutRc(false);
                window.scrollTo(0, 0);

                return false;
            }

            // Set a different message for replacement cost
            if (isReplacementCostStale && !calledFromOnSave) {
                setIsOutstandingChangesPresent(true);
                updateIsSubmittedWithoutRc(true);
                // don't need to show this message
                updateIsPageSubmitted(false);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            let serverCall = EndorsementService.saveAndQuoteEndorsement;

            if(calledFromOnSave && isReplacementCostStale){
                serverCall = EndorsementService.saveEndorsement;
                updateIsPageSubmitted(false);
            }

            const saveResponse = await serverCall(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', saveResponse);
            updateWizardData(policyChangeVM);
            setIsSavingEndorsement(false);

            return policyChangeVM;
        },
        [isComponentValid, isReplacementCostStale, policyChangeVM, authHeader, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, policyChangeVM, updateWizardSnapshot]
    );

    useEffect(() => {
        setIsPageInitialized(true);

        const heatingSystems = get(policyChangeVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            updateHeatingSystemType(heatingSystems[0].heatingType);
        }

        const coolingSystems = get(policyChangeVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');

        if (coolingSystems !== undefined && coolingSystems.length > 0) {
            updateCoolingSystemType(coolingSystems[0].coolingType);
        }
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isPageValid = useCallback(() => !(insideWallMaterialPercentageSumErrorState || floorMaterialErrorState
            || !coolingSystemType || !heatingSystemType), [
        insideWallMaterialPercentageSumErrorState,
        floorMaterialErrorState,
        heatingSystemType,
        coolingSystemType
    ]);


    useEffect(() => {
        // register validation for disabling next button
        registerComponentValidation(isPageValid);
    }, [
        isPageValid,
        registerComponentValidation
    ]);

    useEffect(() => {
        if (prefillData !== '') {
            set(policyChangeVM, 'value.lobData.homeowners_EH.coverables', prefillData.homeowners_EH.coverables);

            const heatingSystems = get(policyChangeVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

            if (heatingSystems !== undefined && heatingSystems.length > 0) {
                updateHeatingSystemType(heatingSystems[0].heatingType);
            }

            const coolingSystems = get(policyChangeVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');

            if (coolingSystems !== undefined && coolingSystems.length > 0) {
                updateCoolingSystemType(coolingSystems[0].coolingType);
            }

            updateWizardData(policyChangeVM);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefillData]);

    // Required fields for recalculate the replacement cost
    const requiredFieldsForReplacementCost = [
        'yearBuilt',
        'numberOfStories',
        'totalSquareFeet',
        'exteriorWallFinish',
        'roofType',
        'roofShape',
        'garages', // has children
        'slopeType',
        'numberOfRoomsWithCathedralVaultedCeilings',
        'numberOfRoomsWithCrownMolding',
        'ceilingHeightType',
        'numberOfFullBaths',
        'numberOfHalfBaths',
        'kitchenCounterTopType',
        'foundationType',
        'basementFinishedArea',
        'heatingSystems',
        'coolingSystems',
        'insideWallMaterials', // has children
        'floors', // has children
    ];
    /**
     * isRecalculateReplacementCostDisable, will save Boolean value, which will be used to enable or disable
     * the recalculate ReplacementCost button on UI, if any of the value in requiredFieldsForReplacementCost array
     * is undefined or empty, then it will returns true, else false.
     * @param {Object} subVM quote data dto
     * @returns {Boolean} true if replacement cost can't be recalculated
     */
    const isRecalculateReplacementCostDisable = (subVM) => {
        const isDisabled = some(requiredFieldsForReplacementCost, (requiredField) => {
            const requiredFieldValue = get(subVM.lobData.homeowners_EH.coverables.construction, `${requiredField}.value`);

            // requiredFieldValue could be string, integer, or array.
            if (Array.isArray(requiredFieldValue)) {
                return requiredFieldValue.length === 0;
            }

            return requiredFieldValue === undefined || requiredFieldValue === '';
        });

        return isDisabled || !isComponentValid;
    };

    const isRecalculateRequired = !hasReplacementCost && !isRecalculateReplacementCostDisable(policyChangeVM);

    const generateOverrides = useCallback(() => {
        const overrideProps = {
            roofAgeMessage: {
                visible: false
            },
            partialPrefillNotification: {
                visible: false
            },
            propertyPrefillUnsuccessfulInfoMessage: {
                visible: false
            },
            covAWillBeUpdatedBasedOnReplacementCostInfoMessage: {
                visible: false
            }
        }

        return overrideProps;
    }, []);

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => isPageInitialized
        && !isSavingEndorsement
        && !isSkipping
        && !isSavingCurrentPageChanges
        && isReplacementRecalculateCompleted,
        [isReplacementRecalculateCompleted, isPageInitialized, isSavingCurrentPageChanges,
        isSavingEndorsement, isSkipping]);

    const saveAndQuote = useCallback(
        async () => {
            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteResponse);
            updateWizardData(policyChangeVM);

            return policyChangeVM.value;
        },
        [authHeader, policyChangeVM, updateWizardData]
    );

    const recalculateReplacementCost = useCallback(
        () => {
            if (isRecalculateRequired) {
                setIsReplacementRecalculateCompleted(false);
                PrefillService.orderReplacementCost(
                    policyChangeVM.jobID.value,
                    policyChangeVM.lobData.value,
                    authHeader
                ).then((prefillServiceResponse) => {
                    if (prefillServiceResponse?.homeowners_EH?.exceptions_Ext) {
                         
                        policyChangeVM.baseData.exceptions_Ext = prefillServiceResponse?.homeowners_EH?.exceptions_Ext;
                        setIsReplacementRecalculateCompleted(true);
                        updateWizardData(policyChangeVM);
                    } else {
                        setIsOutstandingChangesPresent(true);
                        setIsReplacementCostStale(false);
                        setPrefillData(prefillServiceResponse);
                        // E1PAP1PC-15700 : save all data till this point
                        set(
                            policyChangeVM.lobData.homeowners_EH.coverables,
                            'value',
                            prefillServiceResponse.homeowners_EH.coverables
                        );
                        set(policyChangeVM, 'value.flowStepIDs_Ext', ['property']);
                        EndorsementService.saveEndorsement(
                            [policyChangeVM.value],
                            authHeader
                        ).then((updateDraftResp) => {
                            set(policyChangeVM, 'value', updateDraftResp);
                            updateWizardData(policyChangeVM);
                            updateWizardSnapshot(policyChangeVM);
                        }).finally(() => {
                            setIsReplacementRecalculateCompleted(true);
                        });
                    }
                }).catch(() => {
                    setIsReplacementRecalculateCompleted(true);

                    if (!isEmpty(get(policyChangeVM, 'baseData.value.exceptions_Ext', []))) {
                        policyChangeVM.baseData.value.exceptions_Ext.push(
                            { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                        );
                    } else {
                        set(
                            policyChangeVM.value,
                            `baseData.exceptions_Ext[${0}]`,
                            { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                        );
                    }

                    updateWizardData(policyChangeVM);
                });
            } else {
                setIsOutstandingChangesPresent(false);
            }
        }, [isRecalculateRequired, policyChangeVM, authHeader, updateWizardData, updateWizardSnapshot, translator]
    );


    const onCustom = useCallback(
        async () => {
            // missing fields
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);
                updateIsSubmittedWithoutRc(false);

                return false;
            }

            // Set a different message for replacement cost
            if (isReplacementCostStale) {
                updateIsSubmittedWithoutRc(true);
                // don't need to show this message
                updateIsPageSubmitted(false);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);
            policyChangeVM.value = await saveAndQuote();
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [isComponentValid, isReplacementCostStale, policyChangeVM, saveAndQuote, updateWizardData, updateWizardSnapshot, getLandingPageIndexForQuotedJob, steps, jumpTo]
    );

    if (!isPageInitialized) {
        return null;
    }


    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            showCustom
            onCustom={onCustom}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
        >
            <E1PEHHO3PropertyDetailsComponent
                onValidate={onValidate}
                transactionVM={policyChangeVM}
                updateWizardData={updateWizardData}
                viewOnlyMode={false}
                parentOverrideProps={generateOverrides}
                isOutstandingChangesPresent={isOutstandingChangesPresent}
                disregardFieldValidation={disregardFieldValidation}
                recalculateReplacementCost={recalculateReplacementCost}
                updateFloorMaterialErrorState={updateFloorMaterialErrorState}
                updateInsideWallMaterialPercentageSumErrorState={updateInsideWallMaterialPercentageSumErrorState}
                isSavingTransaction={isSavingEndorsement}
                isReplacementRecalculateCompleted={isReplacementRecalculateCompleted}
                isSavingCurrentPageChanges={isSavingCurrentPageChanges}
                isPageSubmitted={isPageSubmitted}
                isPageInitialized={isPageInitialized}
                isSkipping={isSkipping}
                isRecalculateReplacementCostDisable={isRecalculateReplacementCostDisable}
                insideWallMaterialPercentageSumErrorState={insideWallMaterialPercentageSumErrorState}
                floorMaterialErrorState={floorMaterialErrorState}
                heatingSystemType={heatingSystemType}
                updateHeatingSystemType={updateHeatingSystemType}
                coolingSystemType={coolingSystemType}
                updateCoolingSystemType={updateCoolingSystemType}
                setIsReplacementCostStale={setIsReplacementCostStale}
                isSubmittedWithoutRc={isSubmittedWithoutRc}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
