import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class UserService {
    static getAvailableProducerCodesBasedOnProducerIdAndState(baseState, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayUser'),
            'getAvailableProducerCodesBasedOnProducerIdAndState', [baseState], additionalHeaders);
    }

    static getAvailableProducerCodesForCurrentUser(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayUser'),
            'getAvailableProducerCodesForCurrentUser', [], additionalHeaders);
    }
}
