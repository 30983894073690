import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { Grid, GridItem } from '@jutro/layout';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { ServiceManager } from '@jutro/legacy/services';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';
import { e1pUserAccessUtil } from 'e1p-capability-hooks';
import cx from 'classnames';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { E1PProductImageComponent } from 'e1p-capability-policyjob-react';
import { AccountService } from 'e1p-capability-gateway';
import AccountOpenTransactions from '../OpenTransactions/OpenTransactions';
import AccountCompletedTransactions from '../CompletedTransactions/CompletedTransactions';
import { withAmfamOktaTokenContext } from '../../Components/E1PStateManagement/AmfamOktaTokenContext';
import metadata from './Summary.metadata.json5';
import EditContacts from '../EditContacts/EditContacts';
import summaryStyles from './Summary.module.scss';
import messages from '../Accounts.messages';
import gatewayMessages from '../../gateway.messages';

const getFormattedCurrency = (premiumAmount, index) => (
    <CurrencyField
        id={`currency${index}`}
        value={premiumAmount}
        readOnly
        hideLabel
        showOptional={false}
    // className={styles.currencyStyle}
    />
);

class Summary extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({}).isRequired
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        intl: PropTypes.shape({}).isRequired,
        authUserData: PropTypes.shape({ publicID: PropTypes.string }).isRequired
    };

    static contextType = TranslatorContext;

    state = {
        accountData: '',
        showNewQuoteButton: false,
        completedTransactionArray: [],
        copyQuoteMessage: { type: 'success', message: undefined },
        openTransactionCounts: {}
    };

    componentDidMount() {
        const { fromAccountLanding } = this.props;

        this.getShowNewQuoteButton();
        this.setState({
            accountData: fromAccountLanding.accountDetailsData
        });
        this.setTransactionsData();
        this.refreshOpenTransactionsRef = React.createRef();
    };

    updateSummaryOpenTransactionCounts = (updatedCounts) => {
        this.setState({
            openTransactionCounts: updatedCounts
        });
    }

    refreshOpenTransactions = () => {
        if (this.refreshOpenTransactionsRef.current) {
            this.refreshOpenTransactionsRef.current();
        }
    }

    getShowNewQuoteButton = async () => {
        const { authUserData, opCo } = this.props;
        const showNewQuoteButtonPerm = authUserData?.permissions_Ext.includes('createsubmission');
        const { isNewQuoteFunctionalityVisible } = e1pUserAccessUtil(authUserData, opCo);
        const shouldShowNewQuoteButtonMSA = showNewQuoteButtonPerm && isNewQuoteFunctionalityVisible();
        this.setState({
            showNewQuoteButton: shouldShowNewQuoteButtonMSA
        });
    };

    // fetch all completed transactions for an account
    setTransactionsData = async () => {
        const { authHeader, intl, fromAccountLanding } = this.props;
        let transactions = [];

        try {
            const accountHandlerResponse = await AccountService.getCompletedPolicyTransactionsForAccount(
                fromAccountLanding.accountDetailsData.accountNumber,
                authHeader
            );

            transactions = accountHandlerResponse?.jobSummaries;
        } catch {
            transactions = [];
        }

        const mappedData = transactions.map(
            (transDetails, index) => ({
                // first mapping from the api will have the code nested
                productCode: transDetails.productCode || transDetails.product.productCode,
                effectiveDate: transDetails.effectiveDate || intl.formatDate(
                    new Date(transDetails.jobEffectiveDate_Ext),
                    {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        timeZone: 'UTC'
                    }
                ),
                expirationDate: transDetails.expirationDate || intl.formatDate(
                    new Date(transDetails.jobExpirationDate_Ext),
                    {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        timeZone: 'UTC'
                    }
                ),
                type: transDetails.type,
                status: transDetails.status,
                jobNumber: transDetails.jobNumber,
                // changed key from response, so different after mount
                insuredName: transDetails.insuredName || transDetails.accountHolderName,
                // already formatted post mount
                premium: transDetails.premium || getFormattedCurrency(
                    transDetails.totalPremium,
                    index
                )
            })
        );

        this.setState({
            completedTransactionArray: mappedData
        });
    };

    getPolicyTable = () => {
        const { accountData } = this.state;
        const { intl } = this.props;
        const policyArray = accountData.policySummaries;

        return policyArray.map((policyDetails, index) => ({
            productCode: policyDetails.product.productCode,
            productName: policyDetails.product.productName,
            insuredName: policyDetails.primaryInsuredName,
            displayStatus: policyDetails.displayStatus,
            policyNumber: policyDetails.policyNumber,
            premium: getFormattedCurrency(policyDetails.premium, index),
            effectiveDate: intl.formatDate(
                new Date(policyDetails.effective),
                {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    timeZone: 'UTC'
                }
            ),
            expirationDate: intl.formatDate(
                new Date(policyDetails.expiration),
                {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    timeZone: 'UTC'
                }
            )
        }));
    };

    readValue = (id, path) => {
        const { accountData } = this.state;

        return _.get(accountData, path);
    };

    getCell = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = {
            displayStatus: translator(messages.displayStatus),
            insuredName: translator(messages.insuredName),
            effectiveDate: translator(messages.effectiveDate),
            expirationDate: translator(messages.expirationDate)
        };

        return <span title={toolTipMessage[property]}>{item[property]}</span>;
    };

    getProductImage = (item) => {
        const productDisplayName = {
            Homeowners_EH: 'Homeowners',
            PersonalAuto_EA: 'Auto',
            PersonalUmbrella_EU: 'Umbrella'
        }[item.productCode];

        return (
            <Grid columns={['1fr']} gap="small">
                <GridItem tag="div">
                    <div className={summaryStyles.iconClass}>
                        <E1PProductImageComponent
                            productCode={item.productCode}
                        />
                    </div>
                    <div className={summaryStyles.productLabel}>{productDisplayName}</div>
                </GridItem>
            </Grid>
        );
    };

    getPolicyNoLink = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = translator(messages.policyNumber);

        return (
            <Link
                to={`/policies/${item[property]}/summary`}
                className={cx(summaryStyles.remove_link_style)}
                title={toolTipMessage}
            >
                {item[property]}
            </Link>
        );
    };

    // Custom dropdown for new quote for this account
    handleNewQuoteForAccount = (evt) => {
        const {
            accountData: { accountNumber }
        } = this.state;
        const prodDetails = evt.productDetails;
        const { history } = this.props;
        const { lobQuoteURL } = appConfig;

        return history.push({
            pathname: lobQuoteURL[prodDetails.lob],
            search: `?productCode=${prodDetails.lob}&policyType=${prodDetails.policyType}&accountNumber=${accountNumber}`
        });
    };

    getPolicyTransactionStatusForCompletedTransactions = () => [
        {
            code: 'all',
            name: gatewayMessages.allCompletedTransactions
        },
        {
            // Displays as bound but code is In Force
            code: 'In Force',
            name: gatewayMessages.issued
        },
        {
            code: 'Declined',
            name: {
                id: 'typekey.PolicyPeriodStatus.Declined',
                defaultMessage: 'Declined'
            }
        },
        {
            code: 'Not-taken',
            name: {
                id: 'typekey.PolicyPeriodStatus.NotTaken',
                defaultMessage: 'Not-taken'
            }
        },
        {
            code: 'Non-renewed',
            name: {
                id: 'typekey.PolicyPeriodStatus.NonRenewed',
                defaultMessage: 'Non-renewed'
            }
        }
    ];

    getPolicyTransactionStatusForExpiredAndWithdrawnTransactions = () => [
        {
            code: 'all',
            name: gatewayMessages.all
        },
        {
            code: 'Expired',
            name: {
                id: 'typekey.PolicyPeriodStatus.Expired',
                defaultMessage: 'Expired'
            }
        },
        {
            code: 'Withdrawn',
            name: {
                id: 'typekey.PolicyPeriodStatus.Withdrawn',
                defaultMessage: 'Withdrawn'
            }
        }
    ];

    getAccountHolderStateandPostalCode = (accountData) => `${accountData.accountHolder.person.primaryAddress.state} ${accountData.accountHolder.person.primaryAddress.postalCode}`;

    getAccountDisplayStatus = (accountData) => `${accountData.statusDisplayName}`;

    getPhoneNumber = (accountData) => {
        let primaryPhoneNumber = '';
        const localeService = ServiceManager.getService('locale-service');
        const phoneUtil = PhoneUtil();

        switch (accountData.accountHolder.person.primaryPhoneType) {
            case 'work':
                primaryPhoneNumber =
                    accountData.accountHolder.person.workNumber;
                break;
            case 'home':
                primaryPhoneNumber =
                    accountData.accountHolder.person.homeNumber;
                break;
            case 'mobile':
                primaryPhoneNumber =
                    accountData.accountHolder.person.cellNumber;
                break;
            default:
                primaryPhoneNumber = '';
        }

        return phoneUtil.prettyPrint(
            primaryPhoneNumber,
            localeService.getDefaultCountryCode()
        );
    };

    getPhoneNumberVisibility = (accountData) => {
        if (accountData.accountHolder.person.primaryPhoneType) {
            return true;
        }

        return false;
    };

    setCopyQuoteMessage = (messageObj) => {
        this.setState({
            copyQuoteMessage: {
                type: messageObj.type,
                message: messageObj.message
            }
        }, () => {
            window.scrollTo({
                top: 150,
                left: 0,
                behavior: 'smooth'
            });
        });
    }

    render() {
        const {
            accountData,
            showNewQuoteButton,
            completedTransactionArray,
            copyQuoteMessage,
            openTransactionCounts
        } = this.state;
        const { fromAccountLanding, intl, history, authHeader, opCoConfig } = this.props;

        if (_.isEmpty(accountData)) {
            return null;
        }

        const policySummaryArray = this.getPolicyTable();
        const allowedPolicyTypesForOpCo = _.get(opCoConfig, 'allowedPolicyTypes', []);
        const translator = this.context;

        const getPartnerName = () => {
            const { operatingCompanyConfig } = appConfig;
            const connectConfigObject = _.get(operatingCompanyConfig, 'CONNECT.experiences', {});
            const partnerCode = _.get(accountData, 'partnerCode',);
            const partnerName = connectConfigObject[partnerCode]?.partnerDisplayName;

            return partnerName || partnerCode
        };

        const overrides = {
            PolicyTable: {
                data: policySummaryArray
            },
            name: {
                content: _.get(accountData, 'accountHolder.person.displayName')
            },
            addressline1: {
                content: _.get(
                    accountData,
                    'accountHolder.person.primaryAddress.addressLine1',
                    '-'
                )
            },
            addressline2: {
                content: _.get(
                    accountData,
                    'accountHolder.person.primaryAddress.addressLine2',
                    '-'
                )
            },
            addressline3: {
                content: _.get(
                    accountData,
                    'accountHolder.person.primaryAddress.addressLine3',
                    '-'
                )
            },
            city: {
                content: _.get(
                    accountData,
                    'accountHolder.person.primaryAddress.city'
                )
            },
            noDataMessage: {
                visible: _.isEmpty(policySummaryArray)
            },
            renewals: {
                content: _.toString(openTransactionCounts.renewals || 0)
            },
            cancellations: {
                content: _.toString(openTransactionCounts.cancellations || 0)
            },
            changes: {
                content: _.toString(openTransactionCounts.changes || 0)
            },
            submissions: {
                content: _.toString(openTransactionCounts.submissions || 0)
            },
            rewrites: {
                content: _.toString(openTransactionCounts.rewrites || 0)
            },
            stateAndPostalcode: {
                content: this.getAccountHolderStateandPostalCode(accountData)
            },
            accountStatus: {
                content: this.getAccountDisplayStatus(accountData)
            },
            phoneNumberLabel: {
                visible: this.getPhoneNumberVisibility(accountData)
            },
            phoneNumber: {
                content: this.getPhoneNumber(accountData),
                visible: this.getPhoneNumberVisibility(accountData)
            },
            newQuoteButton: {
                visible: showNewQuoteButton
            },
            producerCode: {
                content: this.props.opCo === 'MSA' ? _.get(accountData, 'producerCodes[0].displayValue') : getPartnerName()
            },
            producerCodeLabel: {
                content: this.props.opCo === 'MSA' ? translator(messages.producerCode) : translator(messages.Partner)
            },
            producerInformationHeader: {
                content: this.props.opCo === 'MSA' ? translator(messages.ProducerInformation) : translator(messages.PartnerInformation)
            },
            AccountOpenTransactions: {
                content: (
                    <AccountOpenTransactions
                        fromAccountLanding={fromAccountLanding}
                        history={history}
                        intl={intl}
                        authHeader={authHeader}
                        refreshAccountOpenTransactionRef={this.refreshOpenTransactionsRef}
                        setCopyQuoteMessage={this.setCopyQuoteMessage}
                        updateSummaryOpenTransactionCounts={this.updateSummaryOpenTransactionCounts}
                    />
                )
            },
            AccountCompletedTransactions: {
                // IAP-2618: we need to filter expired and withdrawn from other completed transactions
                transactionsResponse: completedTransactionArray.filter((transactionResp) => !['Expired', 'Withdrawn'].includes(transactionResp.status)),
                intl,
                policyTransactionStatusValues: this.getPolicyTransactionStatusForCompletedTransactions(),
                typeOfTransactions: 'COMPLETED', // ['COMPLETED', 'EXPIRED_AND_WITHDRAWN']
                setCopyQuoteMessage: this.setCopyQuoteMessage,
                refreshOpenTransactions: this.refreshOpenTransactions
            },
            AccountExpiredAndWithdrawnTransactions: {
                // IAP-2618: we need to filter expired and withdrawn from other completed transactions
                transactionsResponse: completedTransactionArray.filter((transactionResp) => ['Expired', 'Withdrawn'].includes(transactionResp.status)),
                intl,
                policyTransactionStatusValues: this.getPolicyTransactionStatusForExpiredAndWithdrawnTransactions(),
                typeOfTransactions: 'EXPIRED_AND_WITHDRAWN', // ['COMPLETED', 'EXPIRED_AND_WITHDRAWN']
                setCopyQuoteMessage: this.setCopyQuoteMessage,
                refreshOpenTransactions: this.refreshOpenTransactions
            },
            copyQuoteMessage: {
                type: copyQuoteMessage.type,
                message: copyQuoteMessage.message,
            },
            copyQuoteMessageDiv: {
                visible: !_.isEmpty(copyQuoteMessage.message)
            },
            paeaLink: {
                visible: allowedPolicyTypesForOpCo.includes('EAFamilyCar')
            },
            ho3ehLink: {
                visible: allowedPolicyTypesForOpCo.includes('HO3')
            },
            ho6ehLink: {
                visible: allowedPolicyTypesForOpCo.includes('HO6')
            },
            ho4ehLink: {
                visible: allowedPolicyTypesForOpCo.includes('HO4')
            },
            hf9ehLink: {
                visible: allowedPolicyTypesForOpCo.includes('HF9')
            },
            umbeuLink: {
                visible: allowedPolicyTypesForOpCo.includes('Umbrella')
            }
        };

        const resolvers = {
            resolveCallbackMap: {
                getCell: this.getCell,
                getProductImage: this.getProductImage,
                getPolicyNoLink: this.getPolicyNoLink,
                // handleNewQuoteClick: this.handleNewQuoteClick
                // Custom New quote for this account
                newQuoteForAccount: this.handleNewQuoteForAccount,
            },
            resolveValue: this.readValue,
            resolveClassNameMap: summaryStyles,
            resolveComponentMap: {
                editcontactscomponent: EditContacts,
                AccountCompletedTransactions
            }
        };

        return (
            <div className={cx(summaryStyles.summary)}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={accountData}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    onValidationChange={() => { }}
                    onValueChange={() => { }}
                />
            </div>
        );
    }
}
export const SummaryComponent = Summary;
export default withIntl(withAuthenticationContext(withAmfamOktaTokenContext(Summary)));
