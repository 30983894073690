import { defineMessages } from 'react-intl';

export default defineMessages({
    withdrawReinstatement: {
        id: 'e1p.gateway.views.policy-landing.Withdraw',
        defaultMessage: 'Withdrawing Reinstatement',
    },
    wouldYouLikeToWithdraw: {
        id: 'e1p.gateway.views.policy-landing.Would you like to Withdraw',
        defaultMessage: 'Would you like to withdraw the reinstatement?',
    },
    reinstatementEffectiveDate: {
        id: 'e1p.gateway.views.reinstatement-detail.Reinstatement Effective Date',
        defaultMessage: 'Reinstatement Effective Date',
    },
    policyEffectiveDate: {
        id: 'e1p.gateway.views.reinstatement-detail.Effective Date',
        defaultMessage: 'Effective Date',
    },
    reinstatementSource: {
        id: 'e1p.gateway.views.reinstatement-detail.Reinstatement Source',
        defaultMessage: 'Reinstatement Source',
    },
    reinstatementReason: {
        id: 'e1p.gateway.views.reinstatement-detail.Reinstatement Reason',
        defaultMessage: 'Reinstatement Reason',
    },
    description: {
        id: 'e1p.gateway.views.reinstatement-detail.Description',
        defaultMessage: 'Description',
    },
    changeInCost: {
        id: 'e1p.gateway.views.reinstatement-detail.Change in Cost',
        defaultMessage: 'Change in Cost',
    },
    policyPremium: {
        id: 'e1p.gateway.views.reinstatement-detail.Policy Premium',
        defaultMessage: 'Policy Premium',
    },
    taxes: {
        id: 'e1p.gateway.views.reinstatement-detail.Taxes',
        defaultMessage: 'Taxes',
    },
    premiumReinstatementCalculated: {
        id: 'e1p.gateway.views.reinstatement-detail.The premium for this reinstatement has been calculated',
        defaultMessage: 'The premium for this reinstatement has been calculated'
    },
    reinstatementHasBeenBound: {
        id: 'e1p.gateway.views.reinstatement-detail.This reinstatement has been bound',
        defaultMessage: 'This reinstatement has been bound'
    },
    reinstatementSuccessfullyStarted: {
        id: 'e1p.gateway.views.reinstatement-detail.This reinstatement has been successfully started',
        defaultMessage: 'This reinstatement has been successfully started.'
    },
    thisReinstatementHasBeenWithDrawn: {
        id: 'e1p.gateway.views.reinstatement-detail.This reinstatement has been withdrawn',
        defaultMessage: 'This reinstatement has been withdrawn.'
    },
    continiueReinstatementQizard: {
        id: 'e1p.gateway.views.reinstatement-detail.You may either withdraw or continue to the reinstatement.',
        defaultMessage: 'You may either withdraw or continue to the reinstatement.'
    },
    changeReinstatementWizardToBind: {
        id: 'e1p.gateway.views.reinstatement-detail.You may either withdraw or continue to the reinstatement where you can bind the reinstatement.',
        defaultMessage: 'You may either withdraw or continue to the reinstatement where you can bind the reinstatement.'
    },
    continueReinstatement: {
        id: 'e1p.gateway.views.reinstatement-detail.Continue Reinstatement',
        defaultMessage: 'Continue Reinstatement'
    },

});
