import { defineMessages } from 'react-intl';

export default defineMessages({
    renewals: {
        id: 'renewal.eh.directives.templates.ea-whats-next.Renewals',
        defaultMessage: 'Renewals'
    },
    insuredDetails: {
        id: 'renewal.eh.directives.templates.Insured',
        defaultMessage: 'Insured'
    },
    propertyPage: {
        id: 'renewal.eh.directives.templates.Property',
        defaultMessage: 'Property'
    },
    exitTransaction: {
        id: 'renewal.eh.wizard.step.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'renewal.eh.wizard.step.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'renewal.eh.wizard.step.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'renewal.eh.wizard.step.The information entered will be stored as a draft',
        defaultMessage: 'The information entered will be stored as a draft'
    },
    quotePage: {
        id: 'renewal.eh.directives.templates.Coverages',
        defaultMessage: 'Coverages'
    },
    recalculateButtonLabel: {
        id: 'renewal.eh.directives.templates.eh-edit.recalculateButtonLabel',
        defaultMessage: 'Recalculate'
    },
    reviewChangesLabel: {
        id: 'renewal.eh.directives.templates.eh-edit.reviewChangesLabel',
        defaultMessage: 'Review Changes'
    },
    confirmation: {
        id: 'renewal.eh.directives.templates.Confirmation',
        defaultMessage: 'Confirmation'
    },
    anErrorOccurred: {
        id: 'renewal.eh.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'renewal.eh.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'renewal.eh.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'renewal.eh.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    tpiPageTitle: {
        id: 'renewal.eh.wizard.step.Third-Party Interest',
        defaultMessage: 'Third-Party Interest'
    },
    riskAnalysisPage: {
        id: 'renewal.eh.directives.templates.Risk Analysis',
        defaultMessage: 'Risk Analysis'
    },
    exitTransactionMessage: {
        id: 'renewal.eh.directives.templates.Information entered on prior pages will be saved as a draft. Any changes on this page will be lost. Changes will be discarded if not applied before the renewal is automatically bound.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any changes on this page will be lost. Changes will be discarded if not applied before the renewal is automatically bound.'
    }
});
