import { defineMessages } from 'react-intl';

export default defineMessages({
    standardizingAddressMessage: {
        id: 'common.component.e1pEUTPISearchDetailComponent.Standardizing address...',
        defaultMessage: 'Standardizing address...'
    },
    tpiDetailsHeading: {
        id: 'common.component.e1pEUTPISearchDetailComponent.tpiDetailsHeading',
        defaultMessage: ' Add Third-Party Interest Details'
    },
    grantor: {
        id: 'common.component.e1pEUTPISearchDetailComponent.Are you a grantor of the trust?',
        defaultMessage: 'Are you a grantor of the trust?'
    },
    individualOrFamily: {
        id: 'common.component.e1pEUTPISearchDetailComponent.Is this an individual or family trust?',
        defaultMessage: 'Is this an individual or family trust?'
    },
    member: {
        id: 'common.component.e1pEUTPISearchDetailComponent.Are you a member of the LLC?',
        defaultMessage: 'Are you a member of the LLC?'
    },
    jointOwnership: {
        id: 'common.component.e1pEUTPISearchDetailComponent.Was the LLC formed by relatives due to the joint ownership of property?',
        defaultMessage: 'Was the LLC formed by relatives due to the joint ownership of property?'
    }
});
