import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useTranslator } from '@jutro/locale';
import { Badge } from '@jutro/components';

import { commonQuoteStyles } from 'gw-capability-quoteandbind-common-react';
import { MockUpUtil } from '@xengage/gw-portals-util-js';

import messages from './EaQuoteInfo.messages';

const PATH_TO_MOCK_DATA = 'quote.pa';

function renderVehicles(submissionVM, vehicles = []) {
    return vehicles
        .map(({ displayName, vin }) => {
            const isVinMocked = MockUpUtil.isValueMocked(submissionVM.value, PATH_TO_MOCK_DATA, 'lobData.personalAuto_EA.coverables.vehicles[0].vin');
            const textToDisplay = isVinMocked ? displayName : `${displayName} (${vin})`;

            return (
                <span className={commonQuoteStyles.coverableItem} key={vin}>{textToDisplay}</span>
            );
        });
}

function renderDrivers(submissionVM, drivers = []) {
    return drivers
        .map(({ person }, index) => {
            const { displayName } = person;
            const pathToDriver = `lobData.personalAuto_EA.coverables.drivers[${index}].person`;
            const isDisplayNameMocked = MockUpUtil.isDisplayNameMocked(
                submissionVM.value,
                PATH_TO_MOCK_DATA,
                `${pathToDriver}.displayName`,
                `${pathToDriver}.firstname`,
                `${pathToDriver}.lastname`
            );

            return (
                <span
                    key={displayName}
                    className={commonQuoteStyles.coverableItem}
                >
                    {isDisplayNameMocked ? undefined : displayName}
                </span>
            );
        });
}

/**
 * Renders a badge if the colection contains more than 1 item
 * @param {Array} collection an array of items
 * @returns {React.ReactNode}
 */
function renderBadge(collection) {
    const size = _.get(collection, 'length', 0);

    if (size > 1) {
        return (
            <Badge
                type="inverse"
                size="small"
                value={size}
                className={commonQuoteStyles.infoBlockBadge}
            />
        );
    }

    return null;
}

function EaQuoteInfo(props) {
    const { submissionVM } = props;
    const translator = useTranslator();
    const quoteId = _.get(submissionVM, 'quoteID.value');
    const vehicles = _.get(submissionVM, 'lobData.personalAuto_EA.coverables.vehicles.value', [])
        .filter(({ displayName }) => !_.isEmpty(displayName));
    const drivers = _.get(submissionVM, 'lobData.personalAuto_EA.coverables.drivers.value', [])
        .filter(({ person }) => !_.isEmpty(person.displayName));

    const address = _.get(submissionVM, 'baseData.policyAddress.value', {});
    const { displayName = '' } = address;

    const isAddressDisplayNameMocked = MockUpUtil.isDisplayNameMocked(
        _.get(submissionVM, 'value'),
        PATH_TO_MOCK_DATA,
        'baseData.policyAddress.displayName',
        'baseData.policyAddress.addressLine1',
        'baseData.policyAddress.city'
    );
    const policyAddressToShowInHeader = isAddressDisplayNameMocked ? `${address.state} ${address.postalCode}` : displayName;

    return (
        <div className={commonQuoteStyles.quoteInfoBar}>
            <div className={commonQuoteStyles.infoBlock}>
                <div className={commonQuoteStyles.infoBlockTitle}>
                    {translator(messages.personalAuto_EAQuote)}
                </div>
                <div id="contextSubmissionId" className={commonQuoteStyles.infoBlockContent}>{quoteId}</div>
            </div>
            <div className={commonQuoteStyles.infoBlock}>
                <div className={commonQuoteStyles.infoBlockTitle}>
                    {translator(messages.location)}
                </div>
                <div id="contextLocation" className={commonQuoteStyles.infoBlockContent}>{policyAddressToShowInHeader}</div>
            </div>
            <div className={commonQuoteStyles.infoBlock}>
                <div className={commonQuoteStyles.infoBlockTitle}>
                    {translator(messages.vehicles)}
                    {renderBadge(vehicles)}
                </div>
                <div id="vehicles" className={commonQuoteStyles.infoBlockContent}>{renderVehicles(submissionVM, vehicles)}</div>
            </div>
            <div className={commonQuoteStyles.infoBlock}>
                <div className={commonQuoteStyles.infoBlockTitle}>
                    {translator(messages.drivers)}
                    {renderBadge(drivers)}
                </div>
                <div id="drivers" className={commonQuoteStyles.infoBlockContent}>{renderDrivers(submissionVM, drivers)}</div>
            </div>
        </div>
    );
}

EaQuoteInfo.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
};

export default EaQuoteInfo;
