import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import { WizardPageTemplateWithTitle } from 'e1p-portals-wizard-react';
import 'e1p-capability-quoteandbind-eh-react';
import QNBEHInsuredPage from './common/pages/Insured/InsuredPage';
import QNBEHViewInsuredPage from './common/pages/Insured/ViewOnly/ViewInsuredPage';

setComponentMapOverrides(
    {
        QNBEHInsuredPage: { component: 'QNBEHInsuredPage' },
        WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
        QNBEHViewInsuredPage: { component: 'QNBEHViewInsuredPage' },
    },
    {
        QNBEHInsuredPage,
        WizardPageTemplateWithTitle,
        QNBEHViewInsuredPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PEEHWizard } from './PEEHWizard';
