import React from 'react';
import { withRouter } from 'react-router-dom';

import { wizardProps } from 'e1p-portals-wizard-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './ContactForAssistance.metadata.json5';
import styles from './ContactForAssistance.module.scss';
import './ContactForAssistance.messages';

function ContactForAssistance(props) {
    const { history, location } = props;

    const goToPolicy = () => {
        const { policyNumber } = location;
        const redirectLobPath = `/policies/${policyNumber}/summary`;

        history.push(redirectLobPath);
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            goToPolicy
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ContactForAssistance.propTypes = wizardProps;
export default withRouter(ContactForAssistance);
