import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InlineLoader, Tooltip } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { pageTemplateProps } from '../prop-types/wizardPropTypes';

import styles from './E1PDigitalWizardPageTemplate.module.scss';
import MobileWizardSteps from '../components/MobileWizardSteps/MobileWizardSteps';
import WizardSidebar from '../components/WizardSidebar';

import { Button } from '@jutro/legacy/components';

function renderLoader(isLoading) {
    return isLoading
        ? (
            <InlineLoader loading={isLoading} className="gw-inline-loader" />
        ) : null;
}

function DigitalWizardPageTemplate(props) {
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const isPhone = breakpoint === 'phone';
    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        nextButtonTooltip,
        onPrevious,
        isLoadingPrevious,
        disablePrevious,
        showPrevious,
        previousLabel,
        onCancel,
        isLoadingCancel,
        disableCancel,
        showCancel,
        onCustom,
        onCustom1,
        onSave,
        onWithdraw,
        withdrawLabel,
        showWithdraw,
        isLoadingCustom,
        isLoadingSave,
        isLoadingCustom1,
        isLoadingWithdraw,
        disableCustom,
        disableCustom1,
        disableOnSave,
        showCustom,
        showCustom1,
        showOnSave,
        customLabel,
        custom1Label,
        onSaveLabel,
        renderContextComponent,
        renderWidgets,
        wizardData,
        wizardSnapshot,
        showContextComponent,
        showWizardHeader,
        customMessageModal,
        exitLabel,
        custom1ButtonType,
        hideSidebar,
        isSkipping,
        isLoadingWholePage
    } = props;
    const [showSidebar, setShowSidebar] = useState(!isPhone && !hideSidebar);

    const toggleSideBar = useCallback(() => {
        setShowSidebar(!showSidebar);
    }, [showSidebar]);

    const shouldShowNavButtons = !(
        showNext === false
        && showPrevious === false
        && showCancel === false
        && showCustom === false
        && showCustom1 === false
        && showOnSave === false
        && showWithdraw === false
    );
    const isPerformingTransition = isLoadingCancel || isLoadingPrevious
        || isLoadingNext || isLoadingCustom || isLoadingCustom1 || isLoadingSave || isLoadingWithdraw;
    
    // IAP-1924, hide navigation buttons during page transation or while showing full page loader
    const shouldShowButtons = shouldShowNavButtons && !isPerformingTransition && !isSkipping && !isLoadingWholePage;

    const wizardClassName = classNames(styles.wizardMain, {
        [styles.wizardMainShowSidebar]: showSidebar && isPhone
    });
    // eslint-disable-next-line arrow-body-style
    const contextComponent = useCallback(() => {
        return (
            <div className={styles.wizardContext}>
                {renderContextComponent(wizardSnapshot)}
            </div>
        );
    }, [renderContextComponent, wizardSnapshot]);

    const nextButton = (
        <Button
            id="gw-wizard-Next"
            className={styles.nextbutton}
            disabled={disableNext || isPerformingTransition}
            onClick={onNext}
        >
            <div className={styles.buttonLabelAndLoaderStyle}>
                {translator(nextLabel)}
                {renderLoader(isLoadingNext)}
            </div>
        </Button >
    );

    const nextButtonWithTooltip = (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>
            <Tooltip id="nextButtonTooltip" placement="bottom" content={nextButtonTooltip}>
                <span>
                    {nextButton}
                </span>
            </Tooltip>
        </React.Fragment>
    );

    const renderNextButton = nextButtonTooltip ? nextButtonWithTooltip : nextButton;

    return (
        <div>
            <div>
                {showContextComponent && contextComponent()}
            </div>
            <div className={styles.digitalWizard}>
                <div className={wizardClassName}>
                    <div className={styles.wizardNavigation}>
                        {
                            showSidebar
                                ? (
                                    <WizardSidebar
                                        customMessageModal={customMessageModal}
                                    />
                                )
                                : null
                        }
                    </div>
                    <div className={styles.wizardContent}>
                        {isPhone ? <MobileWizardSteps toggleSideBar={toggleSideBar} /> : null}
                        {showWizardHeader && contextComponent()}
                        {content}
                    </div>
                    <div className={classNames({ [styles.navButtons] : shouldShowButtons, [styles.hideNavButtons] : !shouldShowButtons })}>
                        <div className={styles.navTransitionButtons}>
                            {(showPrevious) && (
                                <Button
                                    id="gw-wizard-previous"
                                    disabled={disablePrevious || isPerformingTransition}
                                    type="outlined"
                                    onClick={onPrevious}
                                >
                                    <div className={styles.buttonLabelAndLoaderStyle}>
                                        {translator(previousLabel)}
                                        {renderLoader(isLoadingPrevious)}
                                    </div>
                                </Button>
                            )}
                            {(showCancel) && (
                                <Button
                                    id="gw-wizard-cancel"
                                    disabled={disableCancel || isPerformingTransition}
                                    type="outlined"
                                    onClick={onCancel}
                                >
                                    <div className={styles.buttonLabelAndLoaderStyle}>
                                        {translator(exitLabel)}
                                        {renderLoader(isLoadingCancel)}
                                    </div>
                                </Button>
                            )}
                            {(showWithdraw) && (
                                <Button
                                    id="gw-wizard-cancel"
                                    type="outlined"
                                    onClick={onWithdraw}
                                >
                                    <div className={styles.buttonLabelAndLoaderStyle}>
                                        {translator(withdrawLabel)}
                                        {renderLoader(isLoadingWithdraw)}
                                    </div>
                                </Button>
                            )}
                        </div>
                        <div className={styles.navTransitionButtons}>
                            {(showCustom) && (
                                <Button
                                    id="gw-wizard-custom"
                                    disabled={disableCustom || isPerformingTransition}
                                    type="outlined"
                                    onClick={onCustom}
                                >
                                    <div className={styles.buttonLabelAndLoaderStyle}>
                                        {translator(customLabel)}
                                        {renderLoader(isLoadingCustom)}
                                    </div>
                                </Button>
                            )}
                            {(showCustom1) && (
                                <Button
                                    id="gw-wizard-custom1"
                                    disabled={disableCustom1 || isPerformingTransition}
                                    type={custom1ButtonType}
                                    onClick={onCustom1}
                                >
                                    <div className={styles.buttonLabelAndLoaderStyle}>
                                        {translator(custom1Label)}
                                        {renderLoader(isLoadingCustom1)}
                                    </div>
                                </Button>
                            )}
                            {(showOnSave) && (
                                <Button
                                    id="gw-wizard-custom1"
                                    disabled={disableOnSave || isPerformingTransition}
                                    type="outlined"
                                    onClick={onSave}
                                >
                                    <div className={styles.buttonLabelAndLoaderStyle}>
                                        {translator(onSaveLabel)}
                                        {renderLoader(isLoadingSave)}
                                    </div>
                                </Button>
                            )}
                            {(showNext) && renderNextButton}
                        </div>
                    </div>
                    <div className={styles.wizardWidgets}>
                        {showSidebar ? renderWidgets(wizardData) : null}
                    </div>
                </div>
            </div>
        </div>
    );
                            }

DigitalWizardPageTemplate.propTypes = {
    ...pageTemplateProps,
    /** a function that accepts wizardData to render a React component */
    renderContextComponent: PropTypes.func,
    /** a function that accepts wizardData to render a React component */
    renderWidgets: PropTypes.func,
    showContextComponent: PropTypes.bool,
    showWizardHeader: PropTypes.bool,
    nextButtonTooltip: PropTypes.string
};

DigitalWizardPageTemplate.defaultProps = {
    renderContextComponent: () => null,
    renderWidgets: () => null,
    showContextComponent: true,
    showWizardHeader: false,
    nextButtonTooltip: ''
};

export default DigitalWizardPageTemplate;
