import { defineMessages } from 'react-intl';

export default defineMessages({
    noPolicyDocuments: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.Preparing Documents...',
        defaultMessage: 'Preparing Documents...'
    },
    workInProgressTitle: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.In Progress',
        defaultMessage: 'In Progress'
    },
    workInProgress: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.Upload API is under development',
        defaultMessage: 'Upload API is under development'
    },
    fetchingDocumentsText: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.fetching Documents',
        defaultMessage: 'Fetching Documents...'
    },
    uploadingDocumentText: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.fetching Document',
        defaultMessage: 'Uploading Document...'
    },
    preparingDocuments: {
        id: 'e1p.gateway.directives.gateway-documents.Preparing documents…',
        defaultMessage: 'Preparing documents…'
    }
});
