import { defineMessages } from 'react-intl';

// It contains list of Auto validation messages. New EA validations should be added here.
export default defineMessages({
    oneCarMin: {
        id: 'e1p.platform.lob.ea.error.At least one vehicle is required.',
        defaultMessage: 'At least one vehicle is required.'
    },
    noVehicleDataFound: {
        id:
            'e1p.platform.lob.ea.error.The Vehicle Service could not return data for the specified combination. Try another combination.',
        defaultMessage:
            'The Vehicle Service could not return data for the specified combination. Try another combination.'
    },
    prayerForJudgementErrorMessage: {
        id:
            'e1p.platform.lob.ea.error.Prayer for Judgement can only be selected once per Operator.',
        defaultMessage:
            'Prayer for Judgement can only be selected once per Operator.'
    },
    FailedCallVHS: {
        id: 'e1p.platform.lob.ea.error.Vehicle History failed to load.',
        defaultMessage: 'Vehicle History failed to load.'
    },
    accidentalDeathAndDismembermentWarning: {
        id:
            'e1p.platform.lob.ea.info.Verify Accidental Death and Dismemberment coverage selection.',
        defaultMessage:
            'Verify Accidental Death and Dismemberment coverage selection.'
    },
    physicalDamageCovRemovalMsg: {
        id:
            'e1p.platform.lob.ea.info.This risk does not meet the underwriting guidelines. As a result we cannot provide physical damage coverages.',
        defaultMessage:
            'This risk does not meet the underwriting guidelines. As a result we cannot provide physical damage coverages.'
    },
    physicalDamageCovRemovalMsgForReports: {
        id: 'e1p.platform.lob.ea.info.This risk does not meet the underwriting guidelines. As a result we cannot provide physical damage coverages. The premium reflects the removal of physical damage coverage.',
        defaultMessage: 'This risk does not meet the underwriting guidelines. As a result we cannot provide physical damage coverages. The premium reflects the removal of physical damage coverage.'
    },
    physicalDamageCovAvailableMsg: {
        id: 'e1p.platform.lob.ea.info.Policy is eligible for physical damage coverage, based on acceptable activity.',
        defaultMessage: 'Policy is eligible for physical damage coverage, based on acceptable activity.'
    },
    accidentForgivenessMessage: {
        id: 'e1p.platform.lob.ea.info.Accident Forgiveness has been earned.',
        defaultMessage: 'Accident Forgiveness has been earned.'
    },
    vinYearInvalid: {
        id: 'e1p.platform.lob.ea.info.VIN entered does not match Year provided.',
        defaultMessage: 'VIN does not match Year provided.'
    },
    vehicleHasUpdatedMessage: {
        id: 'e1p.platform.lob.ea.info.Vehicle has been updated based on VIN entered. Please verify the vehicle information.',
        defaultMessage: 'Vehicle has been updated based on VIN entered. Please verify the vehicle information.'
    },
    LiabilityCovCededMsg: {
        id:
            'e1p.platform.lob.ea.info.Liability coverage has been Ceded. There are limits on the amount of coverage available. Maximum allowable BI limit is 100,000/300,000 when Insured does not have an Excess Liability policy. If Insured does have an Excess Liability policy please indicate this on the Insured screen to be eligible for BI limit of 250,000/500,000. Some discounts will not apply to Ceded policies.',
        defaultMessage:
            'Liability coverage has been Ceded. There are limits on the amount of coverage available. Maximum allowable BI limit is 100,000/300,000 when Insured does not have an Excess Liability policy. If Insured does have an Excess Liability policy please indicate this on the Insured screen to be eligible for BI limit of 250,000/500,000. Some discounts will not apply to Ceded policies.'
    },
    vehicleLevelPremiumPolCovText: {
        id: 'e1p.platform.lob.ea.info.For vehicle level premiums of policy coverages, refer to quote proposal or declaration page.',
        defaultMessage: 'For vehicle level premiums of policy coverages, refer to quote proposal or declaration page.'
    },
    defensiveDriverDiscountNotification: {
        id: 'e1p.platform.lob.ea.info.Both Primary and Secondary Named Insured must be at least 55 and have taken a Defensive Driver course within the last three years for discount to apply.',
        defaultMessage:
            'Both Primary and Secondary Named Insured must be at least 55 and have taken a Defensive Driver course within the last three years for discount to apply.'
    },
    sr22WithCompOnly: {
        id:
            'e1p.platform.lob.ea.info.At least one vehicle must have liability coverage selected for a requested SR22 filing',
        defaultMessage:
            'At least one vehicle must have liability coverage selected for a requested SR22 filing.'
    },
    sr22fr44WithCompOnly: {
        id:
            'e1p.platform.lob.ea.At least one vehicle must have liability coverage selected for a requested SR22/FR44 filing',
        defaultMessage:
            'At least one vehicle must have liability coverage selected for a requested SR22/FR44 filing.'
    },
    fr44RelatedViolation: {
        id:
            'e1p.platform.lob.ea.info.Financial Responsibility type violation present for driver without a request for a filing',
        defaultMessage:
            'Financial Responsibility type violation present for driver without a request for a filing.'
    },
    nationalGuardWithDefensiveDriverMessage: {
        id:
            'e1p.platform.lob.ea.info.To be eligible for the National Guard discount, the driver must be enrolled in the Defensive Driver discount',
        defaultMessage:
            'To be eligible for the National Guard discount, the driver must be enrolled in the Defensive Driver discount.'
    },
    insuredAddressInfoMessageText: {
        id: 'e1p.platform.lob.ea.info.If Residence Address has changed, please update Mailing, Billing and Garaging Address for each vehicle with new Residence Address. Changes only apply to this policy. Other policies associated to this insured also need to be updated.',
        defaultMessage: 'If Residence Address has changed, please update Mailing, Billing and Garaging Address for each vehicle with new Residence Address. Changes only apply to this policy. Other policies associated to this insured also need to be updated.'
    },
    bodilyInjuryReducedLiabLimitMsg: {
        id: 'e1p.platform.lob.ea.info.Liability coverage has been Ceded. Bodily Injury has been reset to the maximum allowable limit of 100,000/300,000 when Insured does not have an Excess Liability policy. If Insured does have an Excess Liability policy please indicate this on the Insured screen to be eligible for Bodily Injury limit of 250,000/500,000.',
        defaultMessage: 'Liability coverage has been Ceded. Bodily Injury has been reset to the maximum allowable limit of 100,000/300,000 when Insured does not have an Excess Liability policy. If Insured does have an Excess Liability policy please indicate this on the Insured screen to be eligible for Bodily Injury limit of 250,000/500,000.'
    },
    purchasedAccidentForgivenessMessage: {
        id: 'e1p.platform.lob.ea.info.Good news! Your client is eligible for Purchased Accident Forgiveness. Navigate to the Coverages screen to select this coverage.',
        defaultMessage: 'Good news! Your client is eligible for Purchased Accident Forgiveness. Navigate to the Coverages screen to select this coverage.'
    },
    propertyPolicyRequiredForAuto: {
        id: 'e1p.platform.lob.ea.error.Account Property Policy should be in force status as of the effective date of Auto Policy.',
        defaultMessage: 'Account Property Policy should be in force status as of the effective date of Auto Policy.'
    },
    tncGapCoverageMinimumPDLimitMessage: {
        id: 'e1p.platform.lob.ea.info.As of 7/1/2024, TNC Gap - Coverage requires a minimum Property Damage Limit of $50,000.',
        defaultMessage: 'As of 7/1/2024, TNC Gap - Coverage requires a minimum Property Damage Limit of $50,000.'
    },
    priorCarrierEffectiveDateMessage: {
        id: 'e1p.platform.lob.ea.warning.Changes to prior carrier information must be done effective new business date.',
        defaultMessage: 'Changes to prior carrier information must be done effective new business date.'
    }
});
