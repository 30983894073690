import { defineMessages } from 'react-intl';

export default defineMessages({
    rewriteStarted: {
        id: 'e1p.gateway.views.rewrite-detail.This rewrite has been successfully started.',
        defaultMessage: 'This rewrite has been successfully started.'
    },
    withdrawRewrite: {
        id: 'e1p.gateway.views.rewrite-detail.Withdraw Rewrite',
        defaultMessage: 'Withdraw Rewrite'
    },
    continueRewrite: {
        id: 'e1p.gateway.views.rewrite-detail.Continue Rewrite',
        defaultMessage: 'Continue Rewrite'
    },
    rewritePremiusCalculated: {
        id: 'e1p.gateway.views.rewrite-detail.The premium for this rewrite has been calculated',
        defaultMessage: 'The premium for this rewrite has been calculated'
    },
    mayWithDrawRewrite: {
        id: 'e1p.gateway.views.rewrite-detail.You may withdraw the rewrite',
        defaultMessage: 'You may withdraw the rewrite'
    },
    policyWithdrawn: {
        id: 'e1p.gateway.views.rewrite-detail.This rewrite has been withdrawn',
        defaultMessage: 'This rewrite has been withdrawn'
    },
    rewriteBound: {
        id: 'e1p.gateway.views.rewrite-detail.This rewrite has been bound',
        defaultMessage: 'This rewrite has been bound'
    },
    mayWithdrawOrContinueRewriteWizard: {
        id: 'e1p.gateway.views.rewrite-detail.You may either withdraw or continue to the rewrite.',
        defaultMessage: 'You may either withdraw or continue to the rewrite.'
    },
    mayWithdrawOrContinue: {
        id: 'e1p.gateway.views.rewrite-detail.You may either withdraw this rewrite or continue to the rewrite.',
        defaultMessage: 'You may either withdraw this rewrite or continue to the rewrite.'
    },
    mayWithdrawRewrite: {
        id: 'e1p.gateway.views.rewrite-detail.You withdraw the rewrite',
        defaultMessage: 'You withdraw the rewrite'
    },
    lastDays: {
        id: 'e1p.gateway.views.rewrite-detail.last30Days',
        defaultMessage: 'Issued in Last 30 Days'
    },
    openBound: {
        id: 'e1p.gateway.views.rewrite-detail.Open - Bound',
        defaultMessage: 'Open - Bound'
    },
    openNotBound: {
        id: 'e1p.gateway.views.rewrite-detail.Open - Not Bound',
        defaultMessage: 'Open - Not Bound'
    },
    errorLoadingDocuments: {
        id: 'e1p.gateway.views.rewrite-documents.Error loading documents.',
        defaultMessage: 'Error loading documents.'
    },
    status: {
        id: 'e1p.gateway.views.rewrite-documents.Status',
        defaultMessage: 'Status'
    },
    underwritingIssues: {
        id: 'e1p.gateway.views.rewrite-documents.Underwriting Issues',
        defaultMessage: 'Underwriting Issues'
    },
    errorLoadingNotes: {
        id: 'e1p.gateway.views.rewrite-notes.Error loading notes.',
        defaultMessage: 'Error loading notes.'
    },
    sureWithdrawRewrite: {
        id: 'e1p.gateway.views.rewrite.Are you sure you want to withdraw this rewrite?',
        defaultMessage: 'Are you sure you want to withdraw this rewrite?'
    },
    withDrawRewrite: {
        id: 'e1p.gateway.views.rewrite.Withdraw Rewrite??',
        defaultMessage: 'Withdraw Rewrite?'
    },
    cancel: {
        id: 'e1p.gateway.views.rewrite.Cancel',
        defaultMessage: 'Cancel'
    },
    failedtoSaveRewrite: {
        id: 'e1p.gateway.views.rewrite.Failed to Save Rewrite',
        defaultMessage: 'Failed to Save Rewrite'
    },
    saveAndExit: {
        id: 'e1p.gateway.views.rewrite.Save and Exit',
        defaultMessage: 'Save and Exit'
    },
    quote: {
        id: 'e1p.gateway.views.rewrite.Quote',
        defaultMessage: 'Quote'
    },
    savingRewrite: {
        id: 'e1p.gateway.views.rewrite.Saving Rewrite',
        defaultMessage: 'Saving Rewrite'
    },
    unableWithdrawRewrite: {
        id: 'e1p.gateway.views.rewrite.Unable to withdraw this rewrite',
        defaultMessage: 'Unable to withdraw this rewrite'
    },
    withdraw: {
        id: 'e1p.gateway.views.rewrite.Withdraw',
        defaultMessage: 'Withdraw'
    },
    withdrawingRewrite: {
        id: 'e1p.gateway.views.rewrite.Withdrawing Rewrite',
        defaultMessage: 'Withdrawing Rewrite'
    },
    failedToWithdrawRewrite: {
        id: 'e1p.gateway.views.rewrite-detail.Failed to withdraw rewrite',
        defaultMessage: 'Failed to withdraw rewrite'
    },
    error: {
        id: 'e1p.gateway.directives.Rewrite-JobSummary.Error',
        defaultMessage: 'Error'
    },
    details: {
        id: 'e1p.gateway.directives.Rewrite-JobSummary.Error.Details',
        defaultMessage: 'Error loading quote information.'
    },
    preferredUnderwriter: {
        id: 'e1p.gateway.views.rewrite-detail.Preferred Underwriter',
        defaultMessage: 'Preferred Underwriter'
    },
    uwIssuesHaveBeenRaised: {
        id: 'e1p.gateway.directives.rewrite-detail.Underwriting issues have been raised for this quote.',
        defaultMessage: 'Underwriting issues have been raised for this quote.'
    },
    uwIssuesHaveBeenApproved: {
        id: 'e1p.gateway.directives.rewrite-detail.All underwriting issues have been approved for this quote',
        defaultMessage: 'All underwriting issues have been approved for this quote'
    },
    rewriteTermEffectiveDate: {
        id: 'e1p.gateway.views.policy-detail-summary.Rewrite Term Effective Date',
        defaultMessage: 'Term Effective Date'
    },
    viewRewrite: {
        id: 'e1p.gateway.views.rewrite-detail.View Rewrite',
        defaultMessage: 'View Rewrite'
    },
});
