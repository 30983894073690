import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
    get as _get,
    set as _set,
    findIndex as _findIndex,
    isEmpty as _isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useUWIssueUtil } from 'e1p-capability-hooks';
import { RenewalService } from 'e1p-capability-renewal';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import wizardMessages from '../../EHRenewalWizard.messages';
import metadata from './RiskAnalysisPage.metadata.json5';
import styles from './RiskAnalysisPage.module.scss';
import messages from './RiskAnalysisPage.messages';

function RiskAnalysisPage(props) {
    const { authHeader } = useAuthentication();
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        authUserData,
        onCustom2: handleDiscardChanges,
        updateWizardSnapshot
    } = props;

    const jobNumber = _get(renewalVM, 'jobID.value');
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [lossesPageValid, setLossesPageValid] = useState(true);
    const [creditReportResponse, setCreditReportResponse] = useState({});
    const [geoDataResponse, setGeoDataResponse] = useState({});
    const [responseAuto, setResponseAuto] = useState([]);
    const [activeTab, updateActiveTab] = useState('');
    const [responseProperty, setResponseProperty] = useState({});
    const [overlappingException, setOverlappingException] = useState(false);
    const translator = useTranslator();
    const isDiscardButtonActive = _get(renewalVM, 'value.canDiscard_Ext', false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const changeSummaryIndex = _findIndex(steps, ({ path }) => path === '/change-summary');
    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation('RiskAnalysisPage');

    const {
        hasUWIssues
    } = useUWIssueUtil(
        renewalVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const isPageValid = useCallback(() => lossesPageValid && !overlappingException, [lossesPageValid, overlappingException]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation]);

    useEffect(() => {
        setIsPageInitialized(true);
    }, []);

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(renewalVM, 'lobData.homeowners_EH.premiumStability.value') !== undefined;

    const saveAndQuote = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingCurrentPageChanges(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            _set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            setIsSavingCurrentPageChanges(false);

            return renewalVM;
        },
        [authHeader, isComponentValid, renewalVM, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote();

                const fieldIssues = _get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = _get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, renewalVM, updateWizardSnapshot]
    );

    const uwIssuesPresent = useMemo(() => 
         hasUWIssues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [renewalVM?.errorsAndWarnings_Ext?.underwritingIssues?.value]);

    const getDefaultActiveTab = useMemo(() => {
        if (uwIssuesPresent) {
            return 'uwIssuesTab';
        }

        return 'lossesAndViolationTab';
    }, [uwIssuesPresent]);

    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            _set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);
            jumpTo(changeSummaryIndex, true, quoteResponse);

            return false;
        },
        [authHeader, changeSummaryIndex, isComponentValid, jumpTo, renewalVM, updateWizardData]
    );
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: !isSavingRenewal && isPageInitialized
                && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: !isSavingRenewal && isPageInitialized && !isSavingCurrentPageChanges,
            defaultActiveTab: getDefaultActiveTab,
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            activeTab: activeTab !== '' ? activeTab : getDefaultActiveTab
        },
        lossesAndViolationComponent: {
            responseAuto,
            setResponseAuto,
            responseProperty,
            setResponseProperty,
            submissionVM: renewalVM,
            updateWizardData,
            setLossesPageValid,
            isSkipping,
            authUserData,
            onValidateParentPage: onValidate,
            disregardFieldValidationParentPage: disregardFieldValidation,
            showErrors: isPageSubmitted,
            updateIsPageSubmitted
        },
        EHRiskAnalysisMiscComponentId: {
            submissionVM: renewalVM,
            visible: true
        },
        creditReportsComponent: {
            submissionVM: renewalVM,
            creditReportResponse,
            setCreditReportResponse,
            updateWizardData,
            lineOfBusiness: renewalVM.lobData.homeowners_EH,
            lobName: 'homeowners_EH',
            authUserData,
            id: 'creditReportsComponent',
            onValidate,
            setOverlappingException
        },
        geoDataComponent: {
            quoteID: jobNumber,
            geoDataResponse,
            setGeoDataResponse,
            policyState: _get(renewalVM, 'baseData.policyAddress.state.value.code')
        },
        uwIssueComponent: {
            submissionVM: renewalVM,
            updateWizardData,
            authHeader
        },
        diminishingAvailableAmount: {
            labelPosition: 'left'
        },
        diminishingAvailableAmountLatest: {
            labelPosition: 'left'
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(renewalVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    return (
        <WizardPage
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
            skipWhen={initialValidation}
            onNext={saveAndQuote}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            onSave={onSave}
            showOnSave
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
