import { defineMessages } from 'react-intl';

export default defineMessages({
    startSignature: {
        id: 'E1PSignatureComponent.Start Signature',
        defaultMessage: 'Start Signature',
    },
    cancel: {
        id: 'E1PSignatureComponent.Cancel',
        defaultMessage: 'Cancel',
    },
    confirm: {
        id: 'E1PSignatureComponent.Confirm',
        defaultMessage: 'Confirm',
    },
    signature: {
        id: 'E1PSignatureComponent.Signature',
        defaultMessage: 'Signature'
    },
    locationCode: {
        id: 'E1PSignatureComponent.Location Code',
        defaultMessage: 'Location Code'
    },
    signatureType: {
        id: 'E1PSignatureComponent.Signature Type',
        defaultMessage: 'Signature Type'
    },
    sign: {
        id: 'E1PSignatureComponent.Sign',
        defaultMessage: 'Sign'
    },
    chooseSignatureType: {
        id: 'E1PSignatureComponent..--Choose a Signature Type--',
        defaultMessage: '.--Choose a Signature Type--'
    },
    close: {
        id: 'E1PSignatureComponent.Close',
        defaultMessage: 'Close'
    },
    ESignErrorMessageTitle: {
        id: 'E1PSignatureComponent.Sign in Error,',
        defaultMessage: 'Sign in Error'
    },
    signatureTypeHelpText1: {
        id: 'E1PSignatureComponent.Signature Type Help Text1',
        defaultMessage: 'Agency eSignature Tool – Signatures captured using agency eSignature application.'
    },
    signatureTypeHelpText2: {
        id: 'E1PSignatureComponent.Signature Type Help Text2',
        defaultMessage: 'In Person Click2Sign - Signatures captured in agency office using embedded DocuSign eSignature application.'
    },
    signatureTypeHelpText3: {
        id: 'E1PSignatureComponent.Signature Type Help Text3',
        defaultMessage: 'Print and Sign – Wet signature.'
    },
    signatureTypeHelpText4: {
        id: 'E1PSignatureComponent.Signature Type Help Text4',
        defaultMessage: 'Remote Click2Sign - Signatures captured electronically using DocuSign eSignature application and secure email.'
    },

    pleaseWait: {
        id: 'E1PSignatureComponent.Please wait while we prepare your documents',
        defaultMessage: 'Please wait while we prepare your documents'
    },
    signingAgentHelpText: {
        id: 'E1PSignatureComponent.Licensed Agent(s) for this Location Code. Contact MSA at FOSCLicensing@MSAGROUP.COM to request updates.',
        defaultMessage: 'Licensed Agent(s) for this Location Code. Contact MSA at FOSCLicensing@MSAGROUP.COM to request updates.'
    },
    signingAgent: {
        id: 'E1PSignatureComponent.Signing Agent',
        defaultMessage: 'Signing Agent'
    },
    popupBlocker: {
        id: 'E1PSignatureComponent.Please disable your pop-up blocker and click the "Sign" button again, so we can display your documents.',
        defaultMessage: 'Please disable your pop-up blocker and click the "Sign" button again, so we can display your documents.'
    }
});
