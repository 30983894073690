import React, {
    useCallback
} from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import metadata from '../EUPreQualificationPage.metadata.json5';

function ViewEUPreQualificationPage(props) {
    const { wizardData: submissionVM } = props;
    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(async () => submissionVM, [submissionVM]);

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {

        '@field': {
            readOnly: true
        },
        preQualificationPageLoadingIndicator: {
            loaded: true
        },
        euPreQualificationComponent: {
            data: submissionVM,
            updateWizardData: () => {},
            showErrors: false,
            viewOnlyMode: true
        }
    };

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate: () => {}
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewEUPreQualificationPage.propTypes = wizardProps;
export default withRouter(ViewEUPreQualificationPage);
