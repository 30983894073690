
import React, { useContext } from 'react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './ThirdPartyInterestPage.metadata.json5';

function ThirdPartyInterestPage(props) {
    const { wizardData: policyViewVM, updateWizardData } = props;
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            showOptional: true,
            readOnly: true
        },
        e1ptpiDisplayTableContainer: {
            transactionVM: policyViewVM,
            viewModelService,
            authHeader,
            updateWizardData,
            setIsSavingTPI: () => { },
            showErrors: false,
            onValidate: () => { },
            disregardFieldValidationParentPage: () => { },
            viewOnlyMode: true
        }
    };

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            addTPIButtonOnClickHandler: undefined,
            handleTPITableOnCell: undefined,
            getTPINameHyperlink: undefined,
            removeTPIButtonOnClickHandler: undefined
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            showNext={false}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyViewVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ThirdPartyInterestPage.propTypes = wizardProps;
export default ThirdPartyInterestPage;
