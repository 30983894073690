import { defineMessages } from 'react-intl';

export default defineMessages({
    inspectionType: {
        id: 'quoteandbind.roofScoreDataComponent.Inspection Type',
        defaultMessage: 'Inspection Type'
    },
    priorityScore: {
        id: 'quoteandbind.roofScoreDataComponent.Priority Score',
        defaultMessage: 'Priority Score'
    },
    finalInspectionScore: {
        id: 'quoteandbind.roofScoreDataComponent.Final Inspection Score',
        defaultMessage: 'Final Inspection Score'
    }
});
