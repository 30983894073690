import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, set, startsWith } from 'lodash';
import { E1PIframeComponent } from 'e1p-capability-policyjob-react';
import { useTranslator } from '@jutro/locale';
import { usePaymentsUtil } from 'e1p-capability-hooks';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { error as loggerError } from '@jutro/logger';

function E1PPaymentusComponent(props) {
    const {
        submissionVM,
        amfamAccessToken,
        updateWizardData,
        setKeepShowingIframeAfterPayment,
        disableAllSteps,
        setValidationErrors,
        wizardSnapshot,
        LOB
    } = props;

    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const translator = useTranslator();

    const [showIframe, setShowIframe] = useState(false);

    const {
        paymentusIFrameToken,
        paymentusEndpoint
    } = usePaymentsUtil(
        amfamAccessToken,
        get(submissionVM, 'baseData.productCode.value'),
        submissionVM,
        setValidationErrors,
        translator
    );

    // Update payment reference number & call updateQuotedSubmission API
    const updateReferenceNumber = useCallback(async (referenceNumber) => {
        const licensedProducers = get(submissionVM, 'value.licensedProducers');

        set(submissionVM, 'bindData.value.paymentRefNumber', referenceNumber);
        set(wizardSnapshot, 'bindData.value.paymentRefNumber', referenceNumber);
        set(submissionVM, 'flowStepIDs_Ext.value', ['paymentdetails']);
        set(wizardSnapshot, 'flowStepIDs_Ext.value', ['paymentdetails']);
        set(submissionVM, 'entryCompletionStepIDs_Ext.value', ['paymentdetails']);
        set(wizardSnapshot, 'entryCompletionStepIDs_Ext.value', ['paymentdetails']);

        // IAP-4736: set autoPayDiscInd indicator to false if not set already
        if (get(submissionVM, `lobData.${LOB}.autoPayDiscInd.value`) === undefined) {
            set(submissionVM, `lobData.${LOB}.autoPayDiscInd.value`, false);
            set(wizardSnapshot, `lobData.${LOB}.autoPayDiscInd.value`, false);
        }

        // Based on job type call update Quote method for updating payment ref number
        const flowType = get(submissionVM, 'baseData.value.jobType');

        try {
            submissionVM.value = flowType === 'Submission'
                ? await LoadSaveService.updateQuotedSubmission(submissionVM.value, authHeader)
                : await RewriteService.updateQuotedRewrite([submissionVM.value], authHeader);
        } catch (error) {
            // in case PC blows up and does not save reference number, try last successful snapshot
            submissionVM.value = flowType === 'Submission'
                ? await LoadSaveService.updateQuotedSubmission(wizardSnapshot.value, authHeader)
                : await RewriteService.updateQuotedRewrite([wizardSnapshot.value], authHeader);
            loggerError(error);
        } finally {
            // must persist licensed producers for underwriters; update will loose this and
            //  then the signature component will think the uw is an authorized seller and all
            //  the dropdown options will be lost
            set(submissionVM, 'value.licensedProducers', licensedProducers);
            setKeepShowingIframeAfterPayment(true);
            disableAllSteps();
            updateWizardData(submissionVM);
        }
    }, [LOB, LoadSaveService, authHeader, disableAllSteps, setKeepShowingIframeAfterPayment, submissionVM, updateWizardData, wizardSnapshot]);

    const handleMessage = (event) => {
        // Check if event is generated from paymentus origin URL, to avoid security risk
        if (event && startsWith(paymentusEndpoint, event.origin) && event.data?.msg) {
            const paymentInfo = event.data.msg;
            const status = get(paymentInfo, 'payments[0].status');

            switch (paymentInfo.status) {
                // this happens when 
                case 'PROCESSED':
                    if (status === 'ACCEPTED') {
                        const referenceNumber = get(paymentInfo, ['payments', 0, 'reference-number']);

                        if (referenceNumber) {
                            updateReferenceNumber(referenceNumber);
                        }
                        else {
                            // putting this here because it should show up in dynatrace if it ever happens
                            throw new Error('PAYMENTUS ERROR: no reference number returned');
                        }
                    }

                    break;
                case 'COMPLETE':
                    // Don't close iframe when denied or canceled. User should see content from paymentus in the iframe
                    if (status === 'ACCEPTED') {
                        setShowIframe(false);
                        setKeepShowingIframeAfterPayment(false);
                    }

                    break;
                case 'CANCEL':
                    setShowIframe(false);
                    break;
                default:
                // do nothing
            }
        }
    };

    // Add/remove iframe & event listener for messages from iFrame
    useEffect(() => {
        if (paymentusIFrameToken) {
            setShowIframe(true);
            window.addEventListener('message', handleMessage);
        } else {
            setShowIframe(false);
            window.removeEventListener('message', handleMessage);
        }

        // remove event listener on unmount
        return function cleanUp() {
            window.removeEventListener('message', handleMessage);

            // user changed contact info after doing successful payment but did not closed iframe
            if (get(submissionVM, 'bindData.value.paymentRefNumber', undefined)) {
                setKeepShowingIframeAfterPayment(false);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentusIFrameToken]);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>
            {
                showIframe
                && (
                    <div style={{ margin: '16px 0' }}>
                        <E1PIframeComponent
                            height="50%"
                            id="paymentus"
                            showLoader
                            src={`${paymentusEndpoint}${paymentusIFrameToken}`}
                        />
                    </div>
                )
            }
        </React.Fragment>
    );
}

E1PPaymentusComponent.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    amfamAccessToken: PropTypes.string.isRequired,
    updateWizardData: PropTypes.func.isRequired,
    setKeepShowingIframeAfterPayment: PropTypes.func.isRequired,
    setValidationErrors: PropTypes.func.isRequired,
    disableAllSteps: PropTypes.func.isRequired,
    wizardSnapshot: PropTypes.shape({}).isRequired,
    LOB: PropTypes.string.isRequired
};

E1PPaymentusComponent.defaultProps = {};
export default E1PPaymentusComponent;
