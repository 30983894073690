const overrides = {
    field: {
        ownsHomeIndicator: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT']
                }
            }
        },
        applicantsResideInSameStateInd: {
            partner: {
                MSA: {
                    states: ['NH']
                }
            }
        },
        isUmbrellaAssociated: {
            partner: {
                MSA: {
                    states: ['NC']
                }
            }
        }
    }
};

export default overrides;
