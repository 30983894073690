import React, {
    useContext, useCallback, useEffect, useState
} from 'react';
import { get, set, isEmpty, find, map } from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { AutoLossService, PropertyLossService } from 'e1p-capability-gateway';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import PropTypes from 'prop-types';
import metadata from './EUVerifiedLossesAndViolationsComponent.metadata.json5';
import messages from './EUVerifiedLossesAndViolationsComponent.messages';

const POLICY_CHANGE = 'PolicyChange';
const REWRITE = 'Rewrite';
const RENEWAL = 'Renewal';

/** This is actually being used for both verified and unverified flows */
function EUVerifiedLossesAndViolationsComponent(props) {
    const modalApi = useModal();
    const { submissionVM,
        isSkipping,
        showErrors,
        viewOnlyMode,
        updateWizardData,
        disregardFieldValidation,
        authUserData,
        isVerified,
        onValidate,
        updateIsPageSubmitted
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [dataForComponent, updateDataForComponent] = useState({});
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [isRetrievingReports, setIsRetrievingReports] = useState(false);
    const [responseAuto, setResponseAuto] = useState([]);
    const [responseProperty, setResponseProperty] = useState({});
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [lossesPageValid, setLossesPageValid] = useState(true);
    const { authHeader } = useAuthentication();
    const jobTypeCode = get(submissionVM, 'baseData.jobType.value.code', '');
    const jobNumber = (jobTypeCode === POLICY_CHANGE || jobTypeCode === REWRITE || jobTypeCode === RENEWAL)
        ? get(submissionVM, 'jobID.value')
        : get(submissionVM, 'quoteID.value');
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(
        'EUVerifiedLossesAndViolationsComponent'
    );

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, 'EUVerifiedLossesAndViolationsComponent');
        }
    }, [onValidate, isComponentValid, submissionVM]);

    const policyState = get(submissionVM, 'baseData.policyAddress.state.value.code');

    const getAccountHolderName = () => {
        if (jobTypeCode === REWRITE) {
            return submissionVM.baseData.accountHolder.person.displayName.value;
        }

        return (
            submissionVM.baseData.accountHolder ?
                submissionVM.baseData.accountHolder.displayName.value : submissionVM.baseData.accountHolder_Ext.displayName.value
        )
    };

    /**
     * Helper callback for creating a new "VMNode" object using the given VM object and DTO name.
     */
    const createVMNode = useCallback(
        (vmObject, dtoName) => {
            const vmNode = viewModelService.create(vmObject, 'pc', dtoName);

            return vmNode;
        },
        [viewModelService]
    );

    /**
     * Helper effect for initializing the page. This hook will perform additional operations if the submission
     * is transitioning from quick-quote to full-application.
     */
    useEffect(() => {
        let isMounted = true;

        if (!isSkipping) {
            let autoLosses = {};

            setIsRetrievingReports(true);
            autoLosses = AutoLossService.loadAutoLosses(
                jobNumber,
                authHeader
            ).then((response) => {
                if (!isMounted) {
                    return
                }

                const autoLossRecords = [];

                response.autoLossRecords.filter((record) => record.source.sourceType !== 'Self-Declared').forEach((result) => {
                    const recordVM = createVMNode(
                        result,
                        'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoLossRecordDTO'
                    );

                    set(
                        recordVM,
                        'value.operatorDisplayName',
                        `${recordVM.value.assignment.firstName} ${recordVM.value.assignment.lastName}`
                    );
                    autoLossRecords.push(recordVM.value);
                });

                const autoViolationRecords = [];

                response.autoViolationRecords.filter((record) => record.source.sourceType !== 'Self-Declared').forEach((result) => {
                    const violationVM = createVMNode(
                        result,
                        'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoViolationRecordDTO'
                    );

                    set(
                        violationVM,
                        'value.operatorDisplayName',
                        `${violationVM.value.assignment.firstName} ${violationVM.value.assignment.lastName}`
                    );
                    autoViolationRecords.push(violationVM.value);
                });

                set(
                    dataForComponent,
                    'lobData.personalUmbrella_EU.autoLossRecords.value',
                    autoLossRecords
                );
                set(
                    dataForComponent,
                    'lobData.personalUmbrella_EU.autoViolationRecords.value',
                    autoViolationRecords
                );
                set(
                    dataForComponent,
                    'lobData.personalUmbrella_EU.orderingInfo.value',
                    response.orderingInfo
                );
            }).catch(() => {
                setIsRetrievingReports(false);
                modalApi.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: messages.reportsErrorTitle,
                    message: messages.reportsErrorMessage
                });
            });

            let propertyLosses = {};

            propertyLosses = PropertyLossService.loadPropertyLosses(
                jobNumber,
                authHeader
            ).then((response) => {
                if(!isMounted){
                    return;
                }

                response.propertyLossRecords = response.propertyLossRecords.filter((record) => record.source.sourceType !== 'manual');

                const orderingInfoList = get(response, 'orderingInfo', []);
                let propertyLossRecords = get(response, 'propertyLossRecords', []);

                propertyLossRecords = map(propertyLossRecords, (lossRecord) => {
                    // find report ordering info by sourceIdentifier
                    const orderingInfo = find(orderingInfoList, (info) => get(info, ['additionalInfo', 'reportId']) === get(lossRecord, ['source', 'sourceIdentifier']))

                    return {
                        reportOrderDate: get(orderingInfo, ['additionalInfo', 'reportOrderDate']),
                        ...lossRecord
                    };
                });
                set(
                    dataForComponent,
                    'propertyLosses.value',
                    propertyLossRecords
                );
            }).catch(() => {
                setIsRetrievingReports(false);
                modalApi.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: messages.reportsErrorTitle,
                    message: messages.reportsErrorMessage
                });
            });

            Promise.all([
                autoLosses,
                propertyLosses
            ]).finally(() => {
                if(isMounted){
                    updateDataForComponent(dataForComponent);
                    setIsRetrievingReports(false);
                }
            });
        }

        setIsPageInitialized(true);

        return () => {
            isMounted = false;
        }

        // No array dependencies needed in this hook.
        // The logic of initializing losses data needs to be executed only once
        // when landing into losses page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping]);

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            // showOptional: true,
            labelPosition: 'top', showErrors,
            autoComplete: false,
            readOnly: viewOnlyMode
        },
        underwritingPageLoadingIndicator: {
            loaded: !isRetrievingReports,
            text: translator(messages.loadingReportsMessage)
        },
        underwritingPageContainer: {
            visible: !isRetrievingReports,
        },
        lossesAndViolationID: {
            visible: isVerified && !isEmpty(dataForComponent),
            lossesAndViolations: dataForComponent,
            authHeader,
            quoteId: jobNumber,
            updateBaseDataComponent: updateDataForComponent,
            // in policy change only coming under accountHolder_Ext
            accountHolderName: getAccountHolderName(),
            drivers: submissionVM.lobData.personalUmbrella_EU.vehicleOperators,
            policyState,
            jobType: get(submissionVM, 'value.baseData.jobType'),
            lineOfBusiness: 'personalUmbrella_EU',
            viewOnlyMode,
            updateIsPageSubmitted
        },
        EALossAndViolationComponentId: {
            lobDataModel: submissionVM.lobData.personalUmbrella_EU,
            onModelChange: () => updateWizardData(submissionVM),
            viewModelService,
            disregardFieldValidation,
            policyState,
            operators: submissionVM.lobData.personalUmbrella_EU.vehicleOperators.value?.map((operator) => {
                const midInitial = ` ${operator.middleName?.charAt(0) ?? ''}`;

                return {
                    publicId: operator.publicID,
                    displayName: `${operator?.firstName}${midInitial} ${operator?.lastName}`
                };
            }),
            showErrors,
            periodStartDate: submissionVM.baseData.periodStartDate,
            onChangePrayerForJudgement: () => { },
            showPrayerForJudgementError: false,
            authUserData,
            isVerified,
            viewOnlyMode,
            updateIsPageSubmitted
        },
        propertyLossComponent: {
            responseAuto,
            setResponseAuto,
            responseProperty,
            setResponseProperty,
            submissionVM,
            updateWizardData,
            setLossesPageValid,
            isSkipping,
            authUserData,
            onValidateParentPage: onValidate,
            disregardFieldValidationParentPage: disregardFieldValidation,
            showErrors,
            lineOfBusiness: 'personalUmbrella_EU',
            isVerified,
            updateIsPageSubmitted,
            viewOnlyMode
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                dataForComponent,
                id,
                path,
                overrideProps
            ),
        [overrideProps, dataForComponent]
    );

    /**
     * Helper callback for updating the stored data state of this component.
     */
    const updateData = useCallback((newData) => {
        updateDataForComponent(newData);
    }, []);

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    if (!isPageInitialized === true) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={dataForComponent}
            overrideProps={overrideProps}
            onModelChange={updateData}
            onValidationChange={setComponentValidation}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

/**
 * Define expected types for properties to be passed into this Jutro component.
 */
EUVerifiedLossesAndViolationsComponent.propTypes = {
    submissionVM: PropTypes.shape({
    }).isRequired,
    isSkipping: PropTypes.bool,
    showErrors: PropTypes.bool,
    viewOnlyMode: PropTypes.bool
};
EUVerifiedLossesAndViolationsComponent.defaultProps = {
    isSkipping: false,
    showErrors: false,
    viewOnlyMode: false
};


export default EUVerifiedLossesAndViolationsComponent;
