import React, {
    useCallback, useContext, useEffect, useRef, useState
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    isUndefined as _isUndefined,
    pullAt as _pullAt,
    cloneDeep as _cloneDeep,
    find as _find,
    map as _map
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { PropertyLossService } from 'e1p-capability-gateway';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import PropTypes from 'prop-types';
import appConfig from 'app-config';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './VerifiedLossesAndViolationComponent.metadata.json5';
import messages from './VerifiedLossesAndViolationComponent.messages';

const statesNotIncludingAutoLosses = appConfig.statesNotIncludingAutoLosses ?? [];

function VerifiedLossesAndViolationComponent(props) {
    const modalApi = useModal();
    const {
        submissionVM,
        updateWizardData,
        setLossesPageValid,
        responseAuto,
        setResponseAuto,
        responseProperty,
        setResponseProperty,
        isSkipping,
        viewOnlyMode,
        authUserData,        
        disregardFieldValidationParentPage,
        onValidateParentPage,
        showErrors,
        lineOfBusiness,
        isVerified,
        updateIsPageSubmitted
    } = props;
    const jobTypeCode = _get(submissionVM, 'baseData.jobType.value.code', '');
    // For policy change type path is different then other transactions
    const policyType = jobTypeCode === 'PolicyChange' ? _get(submissionVM, 'policyType_Ext.value.code') : _get(submissionVM, `lobData.${lineOfBusiness}.policyType.value.code`);
    const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');
    const jobNumber = jobTypeCode !== 'Submission' ? _get(submissionVM, 'jobID.value') : _get(submissionVM, 'quoteID.value');
    const translator = useTranslator();
    const [isLoadingReports, setIsLoadingReports] = useState(true);
    const { onValidate } = useValidation(
        'VerifiedLossesAndViolationComponent'
    );
    const viewModelService = useContext(ViewModelServiceContext);
    const [lossTypeCodes, setLossTypeCodes] = useState([]);
    const propertyLossListRef = useRef(`lobData.${lineOfBusiness}.manualPropertyLossRecords.value`);
    const { authHeader } = useAuthentication();
    const [dataForComponent, updateDataForComponent] = useState({});

    const modifyForOverrideCallAutoLoss = (lossArray) => {
        const lossObject = [];

        lossArray.forEach((record) => {
            const recordObj = {
                incurDate: record.incurDate,
                lossType: record.lossType,
                recordId: record.recordId,
                isRemoved: record.isRemoved
            };

            lossObject.push(recordObj);
        });

        return lossObject;
    };

    const modifyForOverrideCallPropertyLoss = (lossArray) => {
        const lossObject = [];

        lossArray.forEach((record) => {
            const recordObj = {
                claimDate: record.claimDate,
                lossType: record.lossType,
                recordId: record.recordId,
                isRemoved: record.isRemoved,
                claimAmount: record.claimAmount
            };

            lossObject.push(recordObj);
        });

        return lossObject;
    };

    const savePropertyOverrides = useCallback((value, index) => {
        const path = `propertyReports[${index}].isRemoved`;
        const dataForComponentClone = _cloneDeep(dataForComponent);

        _set(dataForComponentClone, path, value);

        setIsLoadingReports(true);

        const losses = PropertyLossService.savePropertyOverrides(
            jobNumber,
            modifyForOverrideCallPropertyLoss(dataForComponentClone.propertyReports),
            authHeader
        );

        losses.then((response) => {
            response.propertyLossRecords = response.propertyLossRecords.filter((record)=>record.source.sourceType !== 'manual');
            _set(dataForComponentClone, 'propertyReports', response.propertyLossRecords);
            setResponseProperty(response);
        }).catch(() => {
            modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: messages.saveOverridesErrorTitle,
                message: messages.saveOverridesErrorMessage
            });
        }).finally(() => {
            updateDataForComponent(dataForComponentClone);
            setIsLoadingReports(false);
        });
    }, [authHeader, dataForComponent, jobNumber, modalApi, setResponseProperty]);

    const saveAutoOverrides = useCallback((value, index) => {
        const path = `autoReports${index}.isRemoved`;
        const dataForComponentClone = _cloneDeep(dataForComponent);

        _set(dataForComponentClone, path, value);

        setIsLoadingReports(true);

        const losses = PropertyLossService.saveAutoOverrides(
            jobNumber,
            modifyForOverrideCallAutoLoss(dataForComponentClone.autoReports),
            authHeader
        );

        losses.then((response) => {
            _set(dataForComponentClone, 'autoReports', response.autoLossRecords);
            setResponseAuto(response.autoLossRecords);
        }).catch(() => {
            modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: messages.saveOverridesErrorTitle,
                message: messages.saveOverridesErrorMessage
            });
        }).finally(() => {
            updateDataForComponent(dataForComponentClone);
            setIsLoadingReports(false);
        });
    }, [authHeader, dataForComponent, jobNumber, modalApi, setResponseAuto]);


    useEffect(() => {
        let propertyAutoLosses = {};

        if (isVerified && 
            (policyType === 'HO3' || policyType === 'HF9')
            && _isEmpty(responseAuto) && !isSkipping && !statesNotIncludingAutoLosses.includes(policyState)
        ) {
            propertyAutoLosses = PropertyLossService.loadAutoLosses(
                jobNumber,
                authHeader
            ).then((response) => {
                const orderingInfoList = _get(response, 'orderingInfo', []);

                response.autoLossRecords = _map(_get(response, 'autoLossRecords', []), (lossRecord) => {
                    // find report ordering info by sourceIdentifier
                    const orderingInfo = _find(orderingInfoList, (info) => _get(info, ['additionalInfo', 'reportId']) === _get(lossRecord, ['source', 'sourceIdentifier']))

                    return {
                        reportOrderDate: _get(orderingInfo, ['additionalInfo', 'reportOrderDate']),
                        ...lossRecord
                    };
                });
                setResponseAuto(response.autoLossRecords);
            }).catch(() => {
                setIsLoadingReports(false);
            });
        }

        let propertyLosses = {};

        if (isVerified && _isEmpty(responseProperty) && !isSkipping) {
            propertyLosses = PropertyLossService.loadPropertyLosses(
                jobNumber,
                authHeader
            ).then((response) => {
                response.propertyLossRecords = response.propertyLossRecords.filter((record)=>record.source.sourceType !== 'manual');

                const orderingInfoList = _get(response, 'orderingInfo', []);

                response.propertyLossRecords = _map(_get(response, 'propertyLossRecords', []), (lossRecord) => {
                    // find report ordering info by sourceIdentifier
                    const orderingInfo = _find(orderingInfoList, (info) => _get(info, ['additionalInfo', 'reportId']) === _get(lossRecord, ['source', 'sourceIdentifier']));

                    // IAP-4553, lossRecordDesc is not coming from backend, so gettting from typekey since we have lossType available
                    if(lossRecord.lossType && !lossRecord.lossTypeDesc) {
                        const lossDecription = translator({
                            id: `typekey.PropertyLossType_Ext.${lossRecord.lossType}`,
                            defaultMessage: lossRecord.lossType
                        });

                        lossRecord.lossTypeDesc = lossDecription;
                    }

                    return {
                        reportOrderDate: _get(orderingInfo, ['additionalInfo', 'reportOrderDate']),
                        ...lossRecord
                    };
                });
                setResponseProperty(response);
            }).catch(() => {
                setIsLoadingReports(false);
            });
        }

        Promise.all([
            propertyAutoLosses,
            propertyLosses
        ]).finally(() => {
            setIsLoadingReports(false);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping]);


    useEffect(() => {
        updateDataForComponent(
            {
                autoReports: responseAuto,
                propertyReports: responseProperty.propertyLossRecords,
                ...{ ...submissionVM }
            }
        );
    }, [responseAuto, responseProperty, submissionVM]);

    useEffect(() => {
        const currentPropLosses = _get(submissionVM, propertyLossListRef.current);

        if (_isUndefined(currentPropLosses)) {
            _set(submissionVM, propertyLossListRef.current, []);
        } else if (!_isEmpty(currentPropLosses)) {
            _set(submissionVM, `lobData.${lineOfBusiness}.hasManualPropertyLosses.value`, true);
        }

        updateWizardData(submissionVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeLossRow = useCallback((rowToRemove, index, path) => {
        const currentLostList = _get(submissionVM, path);

        if (_isEmpty(rowToRemove)) {
            _pullAt(currentLostList, index);
            updateWizardData(submissionVM);

            return;
        }

        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: messages.removeLossTitle,
            message: messages.removeLossDescription,
            confirmButtonText: translator(commonMessages.removeItemButtonText, { itemToRemove: 'LOSS' }),
            cancelButtonText: commonMessages.cancel
            
        }).then(() => {
            _pullAt(currentLostList, index);
            updateWizardData(submissionVM);
        }, () => { });
    }, [submissionVM, modalApi, translator, updateWizardData]);

    const onValueChange = useCallback((newValue, path) => {
        _set(submissionVM, path, newValue);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const onAddPropLoss = useCallback(() => {
        _get(submissionVM, propertyLossListRef.current).push({});
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const onRemovePropLossRow = useCallback((rowToRemove, index) => {
        removeLossRow(rowToRemove, index, propertyLossListRef.current);
    }, [removeLossRow]);

    const resolvers = {
        resolveCallbackMap: {
            onRemovePropLossRow,
            onAddPropLoss,
            onValueChange
        }
    };


    // requestData: {riskCity: "Connersville", riskState: "IN", riskZipCode: "47331", riskAddressLine1: "1186 Alisa Dr"}
    // riskAddressLine1: "1186 Alisa Dr"
    // riskCity: "Connersville"
    // riskState: "IN"
    // riskZipCode: "47331"


    const getRiskAddress = () => {
        const requestData = _get(responseProperty, 'requestData');

        if (requestData) {
            if (requestData.riskAddressLine2) {
                return (
                    `${requestData.riskAddressLine1} ${requestData.riskAddressLine2} ${requestData.riskCity}, ${requestData.riskState} ${requestData.riskZipCode}`
                );
            }

            return (
                `${requestData.riskAddressLine1} ${requestData.riskCity} ${requestData.riskState} ${requestData.riskZipCode}`
            );
        }

        return '';
    };

    const generateVisibilityProps = () => {
        const isPropertyLossesTableVisible = _get(submissionVM, `lobData.${lineOfBusiness}.hasManualPropertyLosses.value`) || false;

        if (_isEmpty(lossTypeCodes) && !_isEmpty(_get(submissionVM, propertyLossListRef.current))) {
            const manualPropertyLossRecords = _get(submissionVM, `lobData.${lineOfBusiness}.manualPropertyLossRecords`);
            const lossTypeAvailableValues = manualPropertyLossRecords
                .children[0].lossType.aspects.availableValues.map((key) => ({
                        name: {
                            id: key.name
                        },
                        code: key.code
                    }));

            setLossTypeCodes(lossTypeAvailableValues);
        }

        return {
            PropertyLossesTable: {
                visible: isPropertyLossesTableVisible,
                noDataText: messages.noLossAdded
            },
            PropLossTableHeader: {
                visible: isPropertyLossesTableVisible
            },
            '@field': {
                onValueChange,
                textAlign: 'center',
                autoComplete: false
            },
            PropLossDescription: {
                availableValues: lossTypeCodes
            },
            PropLossDate: {
                maxDate: new Date()
            }
        };
    };

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        underwritingPageLoadingIndicator: {
            loaded: !isLoadingReports,
            text: translator(messages.loadingLosses)
        },
        underwritingPageContainer: {
            visible: !isLoadingReports
        },
        atFaultAutoLosses: {
            readOnly: viewOnlyMode
        },
        autoLosses: {
            readOnly: viewOnlyMode
        },
        thirdPartyAutoReportsMainDiv: {
            visible: (policyType === 'HO3' || policyType === 'HF9') && !statesNotIncludingAutoLosses.includes(policyState)
        },
        selfDeclaredAutoLossesMainDiv: {
            visible: (policyType === 'HO3' || policyType === 'HF9') && !statesNotIncludingAutoLosses.includes(policyState)
        },
        selfDeclaredPropertyTable: {
            readOnly: viewOnlyMode || !authUserData.permissions_Ext.includes('editoverrides'),
            submissionVM,
            updateWizardData,
            viewModelService,
            onValidateParentPage,
            disregardFieldValidationParentPage,
            isInlineComponent: true,
            showErrors,
            updateIsPageSubmitted,
            lineOfBusiness
        },
        propertyAddressId: {
            content: getRiskAddress()
        },
        autoThirdPartyReports: {
            saveOverrides: saveAutoOverrides,
            viewOnlyMode
        },
        propertyThirdPartyReports: {
            saveOverrides: savePropertyOverrides,
            viewOnlyMode
        },
        // IAP-1536: Hide the whole manual loss section if they do not have viewoverrides permission
        manualLossesMainContainer: {
            visible: authUserData.permissions_Ext.includes('viewoverrides')
        },
        thirdPartyReportsMainContainer:{
            visible: isVerified
        },
        ...generateVisibilityProps()
    };

    useEffect(() => {
        // In cases where participant chooses to report loss(es), ensure that the loss related table is not empty and has valid values.
        let pageDoesNotCheck = true;

        // hasManualPropertyAutoLosses and hasManualPropertyAtFaultAutoLosses fields are available for HO3 and HF9
        // and policyState other that IN, UT & TN.
        if ((policyType === 'HO3' || policyType === 'HF9') && !statesNotIncludingAutoLosses.includes(policyState)) {
            pageDoesNotCheck = (_isUndefined(_get(submissionVM, `lobData.${lineOfBusiness}.hasManualPropertyAutoLosses.value`))
                || _isUndefined(_get(submissionVM, `lobData.${lineOfBusiness}.hasManualPropertyAtFaultAutoLosses.value`))
                || (_get(submissionVM, `lobData.${lineOfBusiness}.hasManualPropertyLosses.value`)
                    && (_isEmpty(_get(submissionVM, propertyLossListRef.current))
                        || !_get(submissionVM, `lobData.${lineOfBusiness}.manualPropertyLossRecords.aspects.subtreeValid`))));
        } else {
            pageDoesNotCheck = ((_get(submissionVM, `lobData.${lineOfBusiness}.hasManualPropertyLosses.value`)
                && (_isEmpty(_get(submissionVM, propertyLossListRef.current))
                    || !_get(submissionVM, `lobData.${lineOfBusiness}.manualPropertyLossRecords.aspects.subtreeValid`))));
        }

        setLossesPageValid(!pageDoesNotCheck);
    }, [submissionVM, updateWizardData, setLossesPageValid, policyType, policyState, lineOfBusiness]);

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                dataForComponent,
                id,
                path,
                overrideProps
            ),
        [overrideProps, dataForComponent]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={dataForComponent}
            resolveValue={readValue}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

VerifiedLossesAndViolationComponent.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    responseAuto: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    responseProperty: PropTypes.shape({
        propertyLossRecords: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    setResponseProperty: PropTypes.func.isRequired,
    setResponseAuto: PropTypes.func.isRequired,
    updateWizardData: PropTypes.func.isRequired,
    setLossesPageValid: PropTypes.func.isRequired,
    isSkipping: PropTypes.bool.isRequired,
    viewOnlyMode: PropTypes.bool,
    onValidateParentPage: PropTypes.func,
    disregardFieldValidationParentPage: PropTypes.func,
    showErrors: PropTypes.bool,
    lineOfBusiness: PropTypes.string,
    isVerified: PropTypes.bool
};

VerifiedLossesAndViolationComponent.defaultProps = {
    viewOnlyMode: false,    
    onValidateParentPage: () => { },
    disregardFieldValidationParentPage: () => { },
    showErrors: false,
    lineOfBusiness: 'homeowners_EH',
    isVerified: true
};

export default VerifiedLossesAndViolationComponent;
