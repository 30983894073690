import React, { useEffect, useState } from 'react';
import { get as _get } from 'lodash';
import { UnderWritingIssuesComponent } from 'e1p-capability-policyjob-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import metadata from './UWIssuesComponent.metadata.json5';
import styles from './UWIssuesComponent.module.scss';

function UWIssuesComponent(props) {
    const {
        onValidate,
    } = useValidation('UWIssuesComponent');
    const { submissionVM, updateWizardData } = props;
    const quoteID = _get(submissionVM, 'quoteID.value');
    const [underwritingIssues, setunderwritingIssues] = useState([]);

    useEffect(() => {
        const UWIssues = _get(submissionVM, 'value.errorsAndWarnings.underwritingIssues', {});

        setunderwritingIssues(UWIssues);

        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showUWIssues = true;
    // const findBlockingPoint = () => {
    //     const BLOCKS_QUOTES = ['BlocksQuote', 'BlocksQuoteRelease'];
    //     return underwritingIssues.some((offeringIssue) => {
    //         return includes(BLOCKS_QUOTES, offeringIssue.currentBlockingPoint);
    //     });
    // };

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        uwIssuesID: {
            underwritingIssues,
            setunderwritingIssues,
            blockQuote: false, // findBlockingPoint(),
            quoteID,
            visible: showUWIssues
        }
    };

    const resolvers = {
        resolveComponentMap: { underwritingIssuesComponent: UnderWritingIssuesComponent },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={onValidate}
            componentMap={resolvers.resolveComponentMap}
            resolveClassNameMap={resolvers.resolveClassNameMap}
        // callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

UWIssuesComponent.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired
};
export default UWIssuesComponent;
