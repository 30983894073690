import {
    findIndex as _findIndex
} from 'lodash';


function useLandingPageUtil() {
    const getLandingPageForEH = (changeSource, changeReason) => {
        let landingPage = '/insured-details';
        switch (changeSource) {
            case 'company':
                switch (changeReason) {
                    case 'coveragechange':
                    case 'other':
                    case 'policycorrection':
                    case 'propertyrepairletter':
                        landingPage = '/coverages';
                        break;
                    case 'claimriskreview':
                    case 'claimsreformation':
                    case 'credit':
                    case 'propertysurvey':
                    case 'underwriterreview':
                    case 'premiumadjustment':
                        landingPage = '/risk-analysis';
                        break;
                    case 'ratesetcorrection':
                        landingPage = '/insured-details';
                        break;
                    default:
                        return false;
                }
                break;
            case 'insured':
                switch (changeReason) {
                    case 'other':
                    case 'coveragechange':
                        landingPage = '/coverages';
                        break;
                    case 'addresschange':
                        landingPage = '/insured-details';
                        break;
                    case 'propertychange':
                        landingPage = '/property';
                        break;
                    case 'thirdpartyinterestchange':
                        landingPage = '/third-party-interest';
                        break;
                    default:
                        return false;
                }
                break;
            case 'thirdpartyinterest':
                landingPage = '/third-party-interest';
                break;
            default:
                return false;
        }
        return landingPage;
    };

    const getLandingPageForEA = (changeSource, changeReason) => {
        let landingPage = '/insured-details';
        switch (changeSource) {
            case 'company':
                switch (changeReason) {
                    case 'claimsreformation':
                    case 'credit':
                    case 'nonpayoccurrences':
                    case 'underwriterreview':
                    case 'premiumadjustment':
                        landingPage = '/risk-analysis'; // will go to risk analysis page
                        break;
                    case 'other':
                    case 'policycorrection':
                    case 'coveragechange':
                        landingPage = '/coverages';
                        break;
                    case 'movedtoanewrisklocation':
                        landingPage = '/vehicles';
                        break;
                    case 'ratesetcorrection':
                        landingPage = '/insured-details';
                        break;
                    default:
                        return false;
                }
                break;
            case 'insured':
                switch (changeReason) {
                    case 'other':
                    case 'coveragechange':
                        landingPage = '/coverages';
                        break;
                    case 'addresschange':
                        landingPage = '/insured-details';
                        break;
                    case 'driverchange':
                        landingPage = '/drivers';
                        break;
                    case 'vehiclechange':
                        landingPage = '/vehicles';
                        break;
                    case 'thirdpartyinterestchange':
                        landingPage = '/third-party-interest';
                        break;
                    default:
                        return false;
                }
                break;
            case 'thirdpartyinterest':
                landingPage = '/third-party-interest';
                break;
            default:
                return false;
        }
        return landingPage;
    };

    const getLandingPageForEU = (changeSource, changeReason) => {
        let landingPage = '/insured-details';
        switch (changeSource) {
            case 'company':
                switch (changeReason) {
                    case 'coveragechange':
                    case 'other':
                    case 'policycorrection':
                        landingPage = '/coverages';
                        break;
                    case 'credit':
                    case 'claimsreformation':
                    case 'underwriterreview':
                    case 'premiumadjustment':
                        landingPage = '/risk-analysis';
                        break;
                    case 'ratesetcorrection':
                        landingPage = '/insured-details';
                        break;
                    default:
                        return false;
                }
                break;
            case 'insured':
                switch (changeReason) {
                    case 'other':
                    case 'coveragechange':
                        landingPage = '/coverages';
                        break;
                    case 'addresschange':
                        landingPage = '/insured-details';
                        break;
                    case 'underlyingpolicychange':
                        landingPage = '/underlying-policy';
                        break;
                    case 'thirdpartyinterestchange':
                        landingPage = '/third-party-interest';
                        break;
                    default:
                        return false;
                }
                break;
            case 'thirdpartyinterest':
                landingPage = '/third-party-interest';
                break;
            default:
                return false;
        }
        return landingPage;
    };

    const getLandingPage = (LOB, changeSource, changeReason) => {
        let landingPage = '/insured-details';
        switch (LOB) {
            case 'homeowners_EH':
                landingPage = getLandingPageForEH(changeSource, changeReason);
                break;
            case 'personalAuto_EA':
                landingPage = getLandingPageForEA(changeSource, changeReason);
                break;
            case 'personalUmbrella_EU':
                landingPage = getLandingPageForEU(changeSource, changeReason);
                break;
            default:
                return false;
        }
        return landingPage;
    };

    const getLandingPageIndex = (LOB, changeSource, changeReason, steps) => {
        let landingPageIndex = -1;
        const landingPage = getLandingPage(LOB, changeSource, changeReason);
        if (landingPage) {
            landingPageIndex = _findIndex(
                steps, (step) => step.path === landingPage
            );
        }
        return landingPageIndex;
    };

    const getLandingPageIndexForQuotedJob = (LOB, steps) => {
        let landingPageIndex = -1;
        let landingPage = '/policyChangeDiff';
        switch (LOB) {
            case 'homeowners_EH':
                landingPage = '/policyChangeDiff';
                break;
            case 'personalAuto_EA':
                landingPage = '/change-summary';
                break;
            case 'personalUmbrella_EU':
                landingPage = '/change-review';
                break;
            default:
                return false;
        }
        if (landingPage) {
            landingPageIndex = _findIndex(
                steps, (step) => step.path === landingPage
            );
        }
        return landingPageIndex;
    };

    const getDefaultTabForEH = (changeSource, changeReason) => {
        let landingTab = 'uwIssuesTab'; // default Tab
        switch (changeSource) {
            case 'company':
                switch (changeReason) {
                    case 'claimriskreview':
                    case 'claimsreformation':
                        landingTab = 'lossesAndViolationTab'; // ID of losses and voilation Tab
                        break;
                    case 'propertysurvey':
                    case 'credit':
                        landingTab = 'creditTab'; // ID of credit Tab
                        break;
                    case 'underwriterreview':
                        landingTab = 'uwIssuesTab'; // ID of UwIssues Tab
                        break;
                    case 'premiumadjustment':
                        landingTab = 'premiumTab'; // ID for Premium Tab
                        break;
                    default:
                        return false;
                }
                break;
            default:
                return false;
        }
        return landingTab;
    };

    const getDefaultTabForEA = (changeSource, changeReason) => {
        let landingTab = 'uwIssuesTab'; // default Tab
        switch (changeSource) {
            case 'company':
                switch (changeReason) {
                    case 'claimsreformation':
                        landingTab = 'lossesAndViolationsTabID'; // ID of losses and voilation Tab
                        break;
                    case 'credit':
                        landingTab = 'creditTabID'; // ID of credit Tab
                        break;
                    case 'underwriterreview':
                        landingTab = 'uwIssuesTabID'; // ID of UwIssues Tab
                        break;
                    case 'nonpayoccurrences':
                        landingTab = 'miscellaneousReportsTabID'; // ID of Miscelleanous Tab
                        break;
                    case 'premiumadjustment':
                        landingTab = 'premiumTab'; // ID for Premium Tab
                        break;
                    default:
                        return false;
                }
                break;
            default:
                return false;
        }
        return landingTab;
    };

    const getDefaultTabForEU = (changeSource, changeReason) => {
        let landingTab = 'uwIssuesTab'; // default Tab
        switch (changeSource) {
            case 'company':
                switch (changeReason) {
                    case 'claimsreformation':
                        landingTab = 'lossesAndViolationTab'; // ID of losses and voilation Tab
                        break;
                    case 'credit':
                        landingTab = 'creditTabID'; // ID of credit Tab
                        break;
                    case 'underwriterreview':
                        landingTab = 'uwIssuesTab'; // ID of UwIssues Tab
                        break;
                    case 'premiumadjustment':
                        landingTab = 'premiumTab'; // ID for Premium Adjustment
                        break;
                    default:
                        return false;
                }
                break;
            default:
                return false;
        }
        return landingTab;
    };

    const getDefaultTab = (LOB, changeSource, changeReason) => {
        let landingTab = 'uwIssuesTab';
        switch (LOB) {
            case 'homeowners_EH':
                landingTab = getDefaultTabForEH(changeSource, changeReason);
                break;
            case 'personalAuto_EA':
                landingTab = getDefaultTabForEA(changeSource, changeReason);
                break;
            case 'personalUmbrella_EU':
                landingTab = getDefaultTabForEU(changeSource, changeReason);
                break;
            default:
                return false;
        }
        return landingTab;
    };

    return {
        getLandingPage,
        getLandingPageIndex,
        getLandingPageIndexForQuotedJob,
        getDefaultTab
    };
}

export default useLandingPageUtil;
