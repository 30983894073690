import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '@jutro/layout';
import _ from 'lodash';
import { CurrencyValue, useModal } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { ehCommonMessages, commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import {
    EHOptionalCoveragesReadOnlyComponent,
    EHOptionalCoveragesComponent
} from 'e1p-capability-policyjob-react';
import styles from './EHSideBySideOptionalCoveragesComponent.module.scss';

import { Button } from '@jutro/legacy/components';

const OPTIONAL_COVERAGEUIGROUPS = [
    'Popular Optional Coverages',
    'More Optional Coverages'
];
const BLOCKS_QUOTES = ['BlocksQuote', 'BlocksQuoteRelease'];

function EHSideBySideOptionalCoveragesComponent(props) {
    const modalApi = useModal();
    const {
        transactionVM,
        lobOfferings,
        onChangeSubmissionAndSync,
        onChangeSubmission,
        onSyncCoverages,
        onValidate,
        policyState,
        quoteDataOfferings,
        policyType,
        authUserData,
        underwritingIssues,
        onScheduleChange
    } = props;

    const hasGoldOrPlatinumBoxVisible = useCallback((offeringIndex) => !!(_.get(lobOfferings, `[${offeringIndex}].coverages.coverages`, [])
            .find(({ codeIdentifier }) => _.includes(['EH_GoldCoverageBundle', 'EH_PlatinumCoverageBundle'], codeIdentifier))), [lobOfferings])

    const translator = useTranslator();
    const id = 'EHSideBySideOptionalCoveragesComponent';
    const { isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, lobOfferings, onValidate, isComponentValid]);

    /** Callback from main quote page */
    const syncCoverages = useCallback((value, path, lobPath, quotePath) => {
        if (onSyncCoverages) {
            return Promise.resolve(onSyncCoverages(value, path, lobPath, quotePath).then((response) => response));
        }

        return false;
    }, [onSyncCoverages]);

    /** Callback from main quote page */
    const changeSubmissionAndSync = useCallback((value, path, lobPath, quotePath) => {
        if (onChangeSubmissionAndSync) {
            return Promise.resolve(onChangeSubmissionAndSync(value, path, lobPath, quotePath).then((response) => response));
        }

        return false;
    }, [onChangeSubmissionAndSync]);

    const scheduleChange = useCallback((value, path) => {
        if (onScheduleChange) {
            return Promise.resolve(onScheduleChange(value, path).then((response) => response));
        }

        return Promise.resolve();
    }, [onScheduleChange]);

    const openOptionalCoveragePopup = useCallback((currentOfferingIndex) => {
        const componentProps = {
            title: 'Optional Coverages',
            iconClassType: false,
            coverageUIGroups: OPTIONAL_COVERAGEUIGROUPS,
            viewOnlyMode: false,
            policyType,
            hasGoldOrPlatinumBoxVisible: hasGoldOrPlatinumBoxVisible(currentOfferingIndex),
            transactionVM,
            changeSubmissionAndSync: (value, termPath, clausePublicID) => Promise.resolve(changeSubmissionAndSync(
                    value, termPath, `lobData.homeowners_EH.offerings.children[${currentOfferingIndex}]`,
                    `quoteData.offeredQuotes.children[${currentOfferingIndex}]`, clausePublicID
                )),
            currentOfferingIndex,
            authUserData,
            underwritungIssues: underwritingIssues,
            BLOCKS_QUOTES,
            changeSubmission: onChangeSubmission,
            syncCoverages: (value, termPath, clausePublicID) => Promise.resolve(syncCoverages(
                    value, termPath, `lobData.homeowners_EH.offerings.children[${currentOfferingIndex}]`,
                    `quoteData.offeredQuotes.children[${currentOfferingIndex}]`, clausePublicID
                )),
            onScheduleChange: scheduleChange,
            currentOfferingBranchCode: lobOfferings[currentOfferingIndex].branchCode

        };
        const result = modalApi.showModal(<EHOptionalCoveragesComponent {...componentProps} />);

        return result;
    }, [policyType, hasGoldOrPlatinumBoxVisible, transactionVM, authUserData, underwritingIssues, onChangeSubmission, scheduleChange, lobOfferings, modalApi, changeSubmissionAndSync, syncCoverages]);

    const COLUMNS = lobOfferings.length + 1;
    const columnConfig = [];

    for (let column = 0; column < COLUMNS; column++) {
        let columnWidthToPush = '320px';

        if (column === 0) {
            columnWidthToPush = '210px';
        }

        columnConfig.push(columnWidthToPush);
    }

    const optionalCovRows = [''] // Need first cell to be empty
        .concat({ name: 'Applied Optional Coverages', codeIdentifier: 'appliedOptionalCoverages' })
        .concat({ name: 'Subtotal', codeIdentifier: 'subtotal' });
    const rowHeaders = [...optionalCovRows];

    optionalCovRows.shift();

    const ROWS = rowHeaders.length;
    const numberOfCells = COLUMNS * ROWS;
    const tableContent = [];

    for (let cell = 0; cell <= numberOfCells - 1; cell++) {
        // There will always be 3 rows based on mock,
        //   but cols differ based on # of versions
        const rowNumber = Math.floor(cell / COLUMNS) + 1;
        const columnNumber = cell % COLUMNS;
        const OFFSET = 1; // array starts at 0 and first element is a header
        const offeringIndex = columnNumber - OFFSET;

        // first column - render row headers
        if (cell === 0) {
            rowHeaders.shift();
            // first cell is empty
            tableContent.push(<GridItem
                className={rowNumber === ROWS ? styles.qDetailCellBottomLeftHeader : styles.qDetailCellLeftHeader}
                tag="div" key={`coverageGridItem${cell}`} />)
        }
        // first column - render row headers
        else if ((cell % COLUMNS === 0)) {
            const headerClause = rowHeaders.shift();

            if (headerClause.codeIdentifier === 'appliedOptionalCoverages') {
                tableContent.push(
                    <GridItem
                        className={styles.qDetailCellBottomLeftHeader}
                        tag="div" key={`appliedOptionalCovLabel${cell}`}>
                        <span key={`appliedOptionalCovSpan${cell}`}>
                            {translator(ehCommonMessages.appliedOptionalCoverages)}
                        </span>
                    </GridItem>
                )

            } else if (headerClause.codeIdentifier === 'subtotal') {
                tableContent.push(
                    <GridItem
                        className={styles.subTotalGridItem}
                        tag="div" key={`coverageSubtotalLabelDiv${cell}`}>
                        <span key={`coverageSubtotalCellLabel${cell}`}>
                            {headerClause.name}
                        </span>
                    </GridItem>
                );
            }
        } else if (rowNumber === 1) { // first row - quote version name
            tableContent.push(
                <GridItem key={`quotename${cell}`} className={styles.editOptionalCovButtonDiv} >
                    <span key={`quotename${cell}`}>
                        {lobOfferings[cell - 1].branchName}
                    </span>
                    <Button
                        key={`editOptionalCovButton${cell}`}
                        className={styles.editOptionalCovButton}
                        icon="mi-edit" iconPosition="left"
                        type="outlined"
                        onClick={() => openOptionalCoveragePopup(offeringIndex)}
                    >
                        {translator(ehCommonMessages.editOptionalCoverages)}
                    </Button>
                </GridItem >
            );
        } else {
            const currentQuoteOffering = quoteDataOfferings
                .find((quoteOffering) => quoteOffering.branchCode === lobOfferings[offeringIndex].branchCode);
            const isCurrentOfferingNotQuoted = currentQuoteOffering?.status !== 'Quoted';
            const scheduleList = _.get(lobOfferings, `[${offeringIndex}].coverages.schedules`, []);
            let optionalCoverageSubtotalAmount = _.get(_.get(lobOfferings, `[${offeringIndex}].premiumSummary`, [])
                .find((prem) => prem.premiumSummaryType === 'EHOptionalCvgSubtotal'), 'amount', 0);

            // Add schedule coverage total amount to optional coverage total
            optionalCoverageSubtotalAmount += _.get(
                scheduleList.find(
                    (schedule) => _.get(schedule, 'premiumSummary.premiumSummaryType') === 'TotalScheduleCoverage'
                ),
                'premiumSummary.amount', 0
            );

            const selectedOptionalCoverages = _.get(
                lobOfferings, `[${offeringIndex}].coverages.coverages`, []
            ).filter((coverage) => OPTIONAL_COVERAGEUIGROUPS
                .indexOf(coverage.uigroup) >= 0 && coverage.selected === true);

            const selectedExclusions = _.get(
                lobOfferings, `[${offeringIndex}].exclusions`, []
            ).filter((exclusion) => exclusion.selected === true);
            const selectedConditions = _.get(
                lobOfferings, `[${offeringIndex}].conditions`, []
            ).filter((condition) => condition.selected === true);

            let selectedOptionalCoveragesExclusionsAndConditions = [
                ...selectedOptionalCoverages,
                ...selectedExclusions,
                ...selectedConditions
            ];

            if (isCurrentOfferingNotQuoted) {
                selectedOptionalCoveragesExclusionsAndConditions = selectedOptionalCoveragesExclusionsAndConditions
                    .map((selectionOptionalCov) => ({
                            ...selectionOptionalCov,
                            amount: undefined
                        }));

            }

            // For state CT - check if special loss settlement is selected - add it to optional coverage
            if (policyState === 'CT') {
                const dwellingCovA = _.find(_.get(lobOfferings, [offeringIndex, 'coverages', 'coverages'], []), { codeIdentifier: 'EH_DwellingCovA' });
                const isSpecialLossSettlementSelected = !!_.find(_.get(dwellingCovA, ['terms'], []), (term) => term.chosenTerm && term.type === 'OptionEH_DwellingCovA_SpecialLossSettlementType');

                if (isSpecialLossSettlementSelected) {
                    selectedOptionalCoveragesExclusionsAndConditions.push({
                        amount: undefined,
                        name: translator(ehCommonMessages.specialLossSettlement)
                    });
                }
            }

            const covHeader = optionalCovRows[rowNumber - 2];

            if (covHeader.codeIdentifier === 'subtotal') {
                tableContent.push(
                    isCurrentOfferingNotQuoted ?
                        <GridItem key={`quotename${cell}`} className={styles.subTotalGridItemAmount} />
                        :
                        <GridItem key={`quotename${cell}`} className={styles.subTotalGridItemAmount}>
                            <CurrencyValue
                                id={`version${offeringIndex}subtotal`}
                                showFractions
                                value={{
                                    amount: optionalCoverageSubtotalAmount,
                                    currency: 'USD'
                                }}
                            />
                        </GridItem>
                );
            } else if (covHeader.codeIdentifier === 'appliedOptionalCoverages') {
                tableContent.push(
                    <GridItem
                        key={`EHOptionalCoveragesReadOnlyComponentGrid${offeringIndex}`}
                        className={styles.appliedOptionalCoveragesGrid}
                        gap='none'
                    >
                        {selectedOptionalCoveragesExclusionsAndConditions.map((item, key) => (
                            <div
                                className={(key % 2 === 0) ? styles.appliedOptionalCovEvenChildBackgroundColor : styles.appliedOptionalCovOddChildBackgroundColor}
                            >
                                <EHOptionalCoveragesReadOnlyComponent
                                    id={`EHOptionalCoveragesReadOnlyComponent${offeringIndex}${key}`}
                                    value={item}
                                />
                            </div>
                        ))}
                    </GridItem>
                );
            }
            else {
                // Ideally should not come here
                tableContent.push(
                    <GridItem
                        tag="div" key={`notApplicableQuotecell${cell}`}
                        className={rowNumber === ROWS ? styles.qDetailCellBottom : styles.qDetailCell}>
                        <span className={styles.noCoverageText}>{translator(e1pCommonMessages.notApplicable)}</span>
                    </GridItem>
                );
            }
        }
    }

    return (
        <div className={styles.tableSpacingAbove}>
            <h4>{translator(ehCommonMessages.optionalCoverages)}</h4>
            <Grid columns={columnConfig} gap="none">
                {tableContent}
            </Grid>
        </div>
    );
}

EHSideBySideOptionalCoveragesComponent.propTypes = {
    lobOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    quoteDataOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    updateQdd: PropTypes.func.isRequired,
    updateQuoteVersionName: PropTypes.func.isRequired,
    setSelectedVersion: PropTypes.func.isRequired,
    onWithdrawSideBySideVersion: PropTypes.func.isRequired,
    policyState: PropTypes.string.isRequired,
    hasExtendedAttributeEditability: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
    policyType: PropTypes.string.isRequired,
    authUserData: PropTypes.shape({}).isRequired,
    underwritingIssues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onScheduleChange: PropTypes.func.isRequired,
    transactionVM: PropTypes.shape({}).isRequired
};

export default EHSideBySideOptionalCoveragesComponent;
