import { defineMessages } from 'react-intl';

export default defineMessages({
    completingYourReinstatementMessage: {
        id: 'reinstatement.quote.directives.templates.policy-reinstatement.Completing your reinstatement...',
        defaultMessage: 'Completing your reinstatement...'
    },
    errorQuoting: {
        id: 'reinstatement.common.directives.templates.policy-reinstatement.Error Quoting Reinstatement',
        defaultMessage: 'Error Quoting Reinstatement'
    },
    returnQuoting: {
        id: 'reinstatement.common.directives.templates.policy-reinstatement.Error Quoting',
        defaultMessage: 'There has been an error quoting the reinstatement'
    },
    reinstatementPolicyNumber: {
        id: 'reinstatement.common.Reinstatement Policy Number',
        defaultMessage: 'Policy Number'
    },
    reinstatementStartDate: {
        id: 'reinstatement.common.Effective Date',
        defaultMessage: 'Effective Date'
    },
    reinstatementAddress: {
        id: 'reinstatement.common.Reinstatement Insured Residence Address',
        defaultMessage: 'Insured Residence Address'
    },
    reinstatementEffectiveDate: {
        id: 'reinstatement.common.Reinstatement.Effective Date',
        defaultMessage: 'Reinstatement Effective Date'
    },
    totalCost: {
        id: 'reinstatement.common.Reinstatement Policy Premium',
        defaultMessage: 'Policy Premium'
    },
    changeInCost: {
        id: 'reinstatement.common.Reinstatement Transaction Premium',
        defaultMessage: 'Transaction Premium'
    },
    taxes: {
        id: 'reinstatement.common.Reinstatement Taxes & Surcharges',
        defaultMessage: 'Taxes & Surcharges'
    },
    reinstatementPeriodStatus: {
        id: 'reinstatement.common.Reinstatement Period Status',
        defaultMessage: 'Period Status'
    },
    resinstateNow: {
        id: 'reinstatement.common.Reinstatement Reinstate',
        defaultMessage: 'Reinstate'
    }
});
