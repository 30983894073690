import React, {
    useCallback, useState, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import { ModalNext, ModalBody, ModalFooter, ModalHeader } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import PropTypes from 'prop-types';
import styles from './E1PContactsFoundComponent.module.scss';
import metadata from './E1PContactsFoundComponent.metadata.json5';
import messages from './E1PContactsFoundComponent.messages';


import { Button } from '@jutro/legacy/components';


function E1PContactsFoundComponent(props) {
    const {
        contactRecords,
        isOpen,
        onResolve,
        onReject
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        registerInitialComponentValidation
    } = useValidation(
        'E1PContactsFoundComponent'
    );

    const [contactsData, updateContactsData] = useState(contactRecords);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);

    // User shouldn't be able to click use submit button if they havent selected one
    useEffect(() => {
        registerInitialComponentValidation(() => !!contactsData.contactToUse);
        // Run on mount only (empty deps)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value) => {
            updateIsPageSubmitted(false);

            const nextFormData = _.cloneDeep(contactsData);

            _.set(nextFormData, 'contactToUse', contactsData.find((element) => element.tempID === value));
            updateContactsData(nextFormData);
        },
        [contactsData]
    );

    const possibleContactChoices = useMemo(() => {
        const availableValueArray = [];

        contactsData.forEach((contact, index) => {
            const tempObj = {};

            tempObj.displayName = `${contact.displayName} - ${contact.displayAddress}`;;
            tempObj.id = `${contact.displayName}${index}`;
            _.set(contact, 'tempID', tempObj.id);
            availableValueArray.push(tempObj);
        });

        // Create new contact needs to be an option
        const createNewContact = {
            displayName: 'Create New Contact',
            id: 'createNewLabel',
            tempID: 'createNewLabel'
        };

        contactsData.push(createNewContact);
        updateContactsData(contactsData);

        if (availableValueArray.length > 0) {
            availableValueArray.push(createNewContact);
        }

        return availableValueArray;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        // '@element': {
        //     className: 'messageStyle'
        // },
        inlineErrorContactNotChosenDiv: {
            visible: isPageSubmitted && !isComponentValid
        },
        contactOptionRadio: {
            availableValues: possibleContactChoices,
            className: 'messageStyle'
        },
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                contactsData,
                id,
                path,
                overrideProps
            ),
        [overrideProps, contactsData]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
    };

    const onOk = useCallback(
        () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);

                return false;
            }

            const wrapperObj = {
                chosenContact: contactsData.contactToUse
            };

            return onResolve(wrapperObj);
        }, [isComponentValid, contactsData.contactToUse, onResolve]
    );

    const onCancel = useCallback(() => onReject(), [onReject]);

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onOk}
            className={styles.smallPopUp}
        >
            <ModalHeader title={messages.existingContactFound} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={contactsData}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    onValidationChange={setComponentValidation}
                />
            </ModalBody>
            <ModalFooter>
                <Button type="outlined" onClick={onCancel}>
                    {e1pCommonMessages.cancel}
                </Button>
                <Button onClick={onOk}>
                    {e1pCommonMessages.submit}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

E1PContactsFoundComponent.propTypes = {
    contactRecords: PropTypes.shape({}).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired
};
export default E1PContactsFoundComponent;
