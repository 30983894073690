import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class SubmissionService {
    /**
     * Fetch Estimated Payment Review
     * @param {string[]} data
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getEstimatedPaymentPreview = (data, additionalHeaders) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'getEstimatedPaymentPreview',
            data,
            additionalHeaders
        );
    };

    static copySubmission = (jobNumber, additionalHeaders) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'copySubmission',
            [jobNumber],
            additionalHeaders
        );
    };

    /**
     * Creates a new submission
     * @param {Object} newSubmission data to create new submisison
     * @param {Object} additionalHeaders additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from backend
     */
    static createSubmission(newSubmission, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewaySubmission'),
            'createNewSubmission',
            [newSubmission],
            additionalHeaders);
    }
}
