import { defineMessages } from 'react-intl';

export default defineMessages({
    commonAddressLine1: {
        id: 'quoteandbind.wizard.step.insured.producer.address.line 1',
        defaultMessage: 'Address Line 1',
    },
    commonCity: {
        id: 'quoteandbind.wizard.step.insured.producer.address.City',
        defaultMessage: 'City',
    },
    commonState: {
        id: 'quoteandbind.wizard.step.insured.producer.address.State',
        defaultMessage: 'State',
    },
    commonZipCode: {
        id: 'quoteandbind.wizard.step.insured.producer.address.ZipCode',
        defaultMessage: 'Zip Code',
    },
    phone: {
        id: 'quoteandbind.wizard.step.insured.producer.address.Phone',
        defaultMessage: 'Phone'
    },
});
