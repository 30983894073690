import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue, appendMetadataWithIndex } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import metadata from './DriverComponent.metadata.json5';

import './DriverComponent.messages';

function DriverComponent(props) {
    const {
        data: driverVM,
        readOnlyFields,
        labelPosition,
        showOptional,
        phoneWide,
        path,
        id,
        index,
        onValidate,
        onValueChange
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [driverVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const writeStepperValue = useCallback(
        (value, changedPath) => {
            const valueToString = _.toString(value);
            handleValueChange(valueToString, changedPath);
        },
        [handleValueChange]
    );

    const driverYearFirstLicencedAvailableValues = useMemo(() => {
        const currentYear = new Date().getUTCFullYear();
        const { ageAllowedToDrive } = config.personalAutoConfig;
        const dateOfBirth = _.get(driverVM, 'dateOfBirth.value');

        const earliestYear = _.isNil(dateOfBirth)
            ? currentYear - ageAllowedToDrive
            : dateOfBirth.year + ageAllowedToDrive;

        if (earliestYear > currentYear) {
            // return an empty object instead of just an empty array so that
            // the dropdown menu will show "No options"
            return [{}];
        }

        // _.range is not inclusive. Need to add -1 to get correct years
        const yearRange = _.range(currentYear, earliestYear - 1);

        return yearRange.map(_.toString).map((year) => ({
            code: year,
            name: year
        }));
    }, [driverVM]);

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            phoneWide,
        },
        [`driverFirstName${index}`]: { readOnly: _.includes(readOnlyFields, 'driverFirstName') },
        [`driverLastName${index}`]: { readOnly: _.includes(readOnlyFields, 'driverLastName') },
        [`driverDateOfBirth${index}`]: { readOnly: _.includes(readOnlyFields, 'driverDateOfBirth') },
        [`driverGender${index}`]: { readOnly: _.includes(readOnlyFields, 'driverGender') },
        [`driverLicenseNumber${index}`]: { readOnly: _.includes(readOnlyFields, 'driverLicenseNumber') },
        [`driverLicenceState${index}`]: { readOnly: _.includes(readOnlyFields, 'driverLicenceState') },
        [`driverYearFirstLicenced${index}`]: {
            readOnly: _.includes(readOnlyFields, 'driverYearFirstLicenced'),
            availableValues: driverYearFirstLicencedAvailableValues
        },
        // As this value is a typelist in the VM which accepts a string we need
        // to convert the number from the Stepper component to a string
        [`driverNumberOfAccidents${index}`]: {
            readOnly: _.includes(readOnlyFields, 'driverNumberOfAccidents'),
            onValueChange: writeStepperValue,
            value: _.get(driverVM.value, 'accidents')
        },
        [`driverNumberOfViolations${index}`]: {
            readOnly: _.includes(readOnlyFields, 'driverNumberOfViolations'),
            onValueChange: writeStepperValue,
            value: _.get(driverVM.value, 'violations')
        }
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => {
            return readViewModelValue(
                formattedMetadata,
                driverVM,
                fieldId,
                fieldPath,
                overrideProps
            );
        },
        [driverVM, formattedMetadata, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={formattedMetadata}
            model={driverVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
        />
    );
}

DriverComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    readOnlyFields: PropTypes.arrayOf(PropTypes.string),
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.string,
    id: PropTypes.string
};
DriverComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    readOnlyFields: [],
    showOptional: true,
    id: undefined,
    index: 0
};
export default DriverComponent;
