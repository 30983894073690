import React, { useCallback, useEffect } from 'react';
import {
    get as _get,
    isEmpty as _isEmpty
} from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';

import metadata from './FloorComponent.metadata.json5';
import styles from './FloorComponent.scss';
import './FloorComponent.messages';

function FloorComponent(props) {
    const {
        data: floorVM,
        path,
        id,
        onValidate,
        index,
        onValueChange,
        viewOnly,
        showErrors
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [floorVM, id, onValidate, isComponentValid]);

    /**
     * E1PAP1PC-15716:E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const floorMaterialContainerElement = document.getElementById(`floorMaterialGrid${index}`);

        if (floorMaterialContainerElement
            && _isEmpty(_get(floorVM, 'value.floorType'))) {
            const floorTypeElement = floorMaterialContainerElement.querySelector('input[id="floorType"]');

            if (floorTypeElement && floorTypeElement.focus !== undefined) {
                floorTypeElement.focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnly,
            showErrors,
            autoComplete: false
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };
    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                floorVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [floorVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={floorVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

FloorComponent.propTypes = {
    data: PropTypes.shape({}),
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    viewOnly: PropTypes.bool,
    showErrors: PropTypes.bool,
    index: PropTypes.number.isRequired
};
FloorComponent.defaultProps = {
    data: {},
    path: undefined,
    viewOnly: false,
    showErrors: false
};

export default FloorComponent;
