import { defineMessages } from 'react-intl';

export default defineMessages({
    search: {
        id: 'DashboardAccountSearchComponent.Search',
        defaultMessage: 'Search'
    },
    startDate: {
        id: 'DashboardAccountSearchComponent.Last Name',
        defaultMessage: 'Last Name'
    },
    searchState: {
        id: 'DashboardAccountSearchComponent.State',
        defaultMessage: 'State'
    },
    find: {
        id: 'DashboardAccountSearchComponent.Search Accounts',
        defaultMessage: 'Search Accounts'
    },
    locationCode: {
        id: 'DashboardAccountSearchComponent.Location Code',
        defaultMessage: 'Location Code'
    },
    invalidSearchCriteria: {
        id: 'DashboardAccountSearchComponent.Account search was not successful. Please provide, at the minimum, Last Name, State, and Producer Code to perform an account search.',
        defaultMessage: 'Account search was not successful. Please provide, at the minimum, Last Name, State, and Producer Code to perform an account search.'
    }
});
