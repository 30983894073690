import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useUWIssueUtil, useLandingPageUtil } from 'e1p-capability-hooks';
import { EndorsementService } from 'e1p-capability-policychange';
import metadata from './RiskAnalysisPage.metadata.json5';
import styles from './RiskAnalysisPage.module.scss';
import messages from './RiskAnalysisPage.messages';

const LOB = 'homeowners_EH';

function RiskAnalysisPage(props) {
    // eslint-disable-max-len
    const { authHeader } = useAuthentication();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        updateWizardSnapshot,
        authUserData
    } = props;

    const jobNumber = _get(policyChangeVM, 'jobID.value');
    const policyChangeSource = _get(policyChangeVM, 'value.policyChangeSource', '');
    const policyChangeReason = _get(policyChangeVM, 'value.policyChangeReason', '');
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [lossesPageValid, setLossesPageValid] = useState(true);
    const [creditReportResponse, setCreditReportResponse] = useState({});
    const [geoDataResponse, setGeoDataResponse] = useState({});
    const [responseAuto, setResponseAuto] = useState([]);    
    const [activeTab, updateActiveTab] = useState('');
    const [responseProperty, setResponseProperty] = useState({});
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [overlappingException, setOverlappingException] = useState(false);
    const translator = useTranslator();
    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation('RiskAnalysisPage');

    const {
        hasUWIssues
    } = useUWIssueUtil(
        policyChangeVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const {
        getLandingPageIndexForQuotedJob,
        getDefaultTab
    } = useLandingPageUtil();

    const defaultTabBasedOnReason = useMemo(() => getDefaultTab(LOB, policyChangeSource, policyChangeReason),
        [getDefaultTab, policyChangeSource, policyChangeReason]);

    const isPageValid = useCallback(() => lossesPageValid && !overlappingException,
        [lossesPageValid, overlappingException]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation]);

    useEffect(() => {
        let isMounted = true;
        
        if (isMounted) {
            setIsPageInitialized(true);
        }

        return () => {
            isMounted = false;
        }
    }, []);

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(policyChangeVM, 'lobData.homeowners_EH.premiumStability.value') !== undefined;

    const saveAndQuote = useCallback(
        async (_, isQuote = false, calledFromOnSave = false) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            if (!isQuote) {
                const saveResponse = await EndorsementService.saveEndorsement(
                    [policyChangeVM.value],
                    authHeader
                );

                _set(policyChangeVM, 'value', saveResponse);
                updateWizardData(policyChangeVM);
            } else {
                const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                    [(policyChangeVM.value)],
                    authHeader
                );

                _set(policyChangeVM, 'value', quoteResponse);
                updateWizardData(policyChangeVM);
            }

            setIsSavingEndorsement(false);

            const validationErrors = _get(policyChangeVM, 'errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            if (!calledFromOnSave && ((isQuote && validationErrors.length === 0))) {
                let newLandingPageIndex = -1;

                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );

                if (newLandingPageIndex >= 0) {
                    jumpTo(newLandingPageIndex, true);
                }

                return false;
            }

            return policyChangeVM;
        },
        [authHeader, getLandingPageIndexForQuotedJob, isComponentValid, jumpTo, policyChangeVM, steps, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote(undefined, false, true);

                const fieldIssues = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [policyChangeVM, saveAndQuote, updateWizardSnapshot]
    );


    const uwIssuesPresent = useMemo(() => 
         hasUWIssues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [policyChangeVM?.errorsAndWarnings_Ext?.underwritingIssues?.value]);

    const getDefaultActiveTab = useMemo(() => {
        if (uwIssuesPresent) {
            return 'uwIssuesTab';
        }

        return defaultTabBasedOnReason === false ? 'lossesAndViolationTab' : defaultTabBasedOnReason;
    }, [uwIssuesPresent, defaultTabBasedOnReason]);

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            const resp = await saveAndQuote(undefined, true);

            if (!resp) { return false; }

            policyChangeVM.value = resp?.value;
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [
            isComponentValid, saveAndQuote, policyChangeVM, updateWizardData,
            updateWizardSnapshot, getLandingPageIndexForQuotedJob, steps, jumpTo
        ]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: !isSavingEndorsement && isPageInitialized && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: !isSavingEndorsement && isPageInitialized && !isSavingCurrentPageChanges,
            defaultActiveTab: getDefaultActiveTab,
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            activeTab: activeTab !== '' ? activeTab : getDefaultActiveTab
        },
        lossesAndViolationComponent: {
            responseAuto,
            setResponseAuto,
            responseProperty,
            setResponseProperty,
            submissionVM: policyChangeVM,
            updateWizardData,
            setLossesPageValid,
            isSkipping,
            authUserData,
            onValidateParentPage: onValidate,
            disregardFieldValidationParentPage: disregardFieldValidation,
            showErrors: isPageSubmitted,
            updateIsPageSubmitted
        },
        miscellaneaousReportsTab: {
            // visible: false
        },
        EHRiskAnalysisMiscComponentId: {
            submissionVM: policyChangeVM,
            visible: true
        },
        creditReportsComponent: {
            submissionVM: policyChangeVM,
            creditReportResponse,
            setCreditReportResponse,
            updateWizardData,
            lineOfBusiness: policyChangeVM.lobData.homeowners_EH,
            lobName: 'homeowners_EH',
            authUserData,
            id: 'creditReportsComponent',
            onValidate,
            setOverlappingException,
        },
        geoDataComponent: {
            quoteID: jobNumber,
            geoDataResponse,
            setGeoDataResponse,
            policyState: _get(policyChangeVM, 'baseData.policyAddress.state.value.code')
        },
        uwIssueComponent: {
            submissionVM: policyChangeVM,
            updateWizardData,
            authHeader
        },
        diminishingAvailableAmount: {
            labelPosition: 'left'
        },
        diminishingAvailableAmountLatest: {
            labelPosition: 'left'
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(policyChangeVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    return (
        <WizardPage
            skipWhen={initialValidation}
            onNext={saveAndQuote}
            showCustom
            onCustom={onCustom}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
