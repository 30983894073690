import { defineMessages } from 'react-intl';

export default defineMessages({
    handlingConflicts: {
        id: 'common.component.oosConflictRewriteComponent.Handling Conflicts And Quoting Period...',
        defaultMessage: 'Handling Conflicts And Quoting Period...'
    },
    overrideValuesErrorMessage: {
        id: 'common.component.oosConflictRewriteComponent.Please indicate which future conflicts are to be overridden',
        defaultMessage: 'Please indicate which future conflicts are to be overridden'
    },
    submitConfirmTitle: {
        id: 'common.component.oosConflictRewriteComponent.Submitted changes cannot be undone',
        defaultMessage: 'Submitted changes cannot be undone'
    },
    submitConfirmMessage: {
        id: 'common.component.oosConflictRewriteComponent.Submitted changes cannot be undone. Are you sure you want to continue?',
        defaultMessage: 'Submitted changes cannot be undone. Are you sure you want to continue?'
    },
});
