import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo,
    useRef
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    isEqual as _isEqual,
    remove as _remove,
    includes as _includes
} from 'lodash';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { e1pDateUtil } from 'e1p-capability-hooks';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { commonMessages as e1pCommonMessages, ehCommonMessages } from 'e1p-platform-translations';
import config from 'app-config';
import { E1PPropertyPageSectionHeaderComponent } from 'e1p-capability-policyjob-react';
import metadata from './E1PEHHF9PropertyDetailsComponent.metadata.json5';
import styles from './E1PEHHF9PropertyDetailsComponent.module.scss';
import requiredMetadata from './E1PEHHF9PropertyDetailsComponent.requiredness';

const SECTION_STATUS = {
    SUCCESS: 'success',
    FAILURE: 'failure',
    NONE: 'none'
};

function E1PEHHF9PropertyDetailsComponent(props) {
    const {
        id,
        onValidate,
        viewOnlyMode,
        transactionVM,
        updateWizardData,
        isPageSubmitted,
        heatingSystemType,
        updateHeatingSystemType,
        coolingSystemType,
        updateCoolingSystemType,
        setIsReplacementCostStale,
        creditScoreMoreThan597    
    } = props;

    const modalApi = useModal();
    const { authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [interiorStatus, updateInteriorStatus] = useState(SECTION_STATUS.NONE);
    const [exteriorStatus, updateExteriorStatus] = useState(SECTION_STATUS.NONE);
    const [basementStatus, updateBasementStatus] = useState(SECTION_STATUS.NONE);
    const [propertyInformationStatus, updatePropertyInformationStatus] = useState(SECTION_STATUS.NONE);
    const [hvacStatus, updateHvacStatus] = useState(SECTION_STATUS.NONE);
    const policyState = _get(transactionVM, 'baseData.policyAddress.state.value.code') || _get(transactionVM, 'policyAddress.state.value.code');
    const [requiredFields, updateRequiredFields] = useState([]);
    const [expandedSections, setExpandedSections] = useState([
        'interiorAccordionCard',
        'exteriorAccordionCard',
        'basementAccordionCard',
        'propertyInformationAccordionCard',
        'heatingAndCoolingAccordionCard'
    ]);
    const updateInspectionData = useRef(false);
    const jobType = _get(transactionVM.value, 'baseData.jobType') || _get(transactionVM, 'value.jobType');
    const heatingTypeValue = _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value.[0].heatingType',[])
    const coolingTypeValue = _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value.[0].coolingType',[]);
    const canChangeYearBuilt = authUserData?.permissions_Ext.includes('edityearbuilt_ext');
    const [floorMaterialErrorState, updateFloorMaterialErrorState] = useState(false);
    const [insideWallMaterialPercentageSumErrorState, updateInsideWallMaterialPercentageSumErrorState] = useState(false);

    const {
        isComponentValid,
        disregardFieldValidation,
        onValidate: setComponentValidation
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const isNumberOfFullBathsValid = useMemo(() => {
        const numberOfFullBaths = _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value');

        return numberOfFullBaths === undefined
            || (numberOfFullBaths >= 1
                && numberOfFullBaths <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionVM.lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value]);

    const isNumberOfHalfBathsValid = useMemo(() => {
        const numberOfHalfBaths = _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value');

        return numberOfHalfBaths === undefined
            || (numberOfHalfBaths >= 0
                && numberOfHalfBaths <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionVM.lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value]);

    const isNumberOfRoomsWithCathedralVaultedCeilingsValid = useMemo(() => {
        const numberOfRoomsWithCathedralVaultedCeilings = _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value');

        return numberOfRoomsWithCathedralVaultedCeilings === undefined
            || (numberOfRoomsWithCathedralVaultedCeilings >= 0
                && numberOfRoomsWithCathedralVaultedCeilings <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value]);

    useEffect(() => {
        if (!isNumberOfFullBathsValid || !isNumberOfHalfBathsValid || !isNumberOfRoomsWithCathedralVaultedCeilingsValid
            || floorMaterialErrorState || insideWallMaterialPercentageSumErrorState) {
            if (onValidate) {
                onValidate(false, id);
            }
        }
    }, [id, isNumberOfFullBathsValid, onValidate, isNumberOfHalfBathsValid, isNumberOfRoomsWithCathedralVaultedCeilingsValid,
        floorMaterialErrorState, insideWallMaterialPercentageSumErrorState]);

    const hasReplacementCost = _get(transactionVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value', undefined);

    const renderAccordionHeader = (title, status) => (isOpen) => (
            <E1PPropertyPageSectionHeaderComponent
                isOpen={isOpen}
                title={title}
                status={status}
            />
        );

    const currentDateYear = useMemo(() => e1pDateUtil.getCurrentDateYear(), []);
    const { minYearBuilt } = config.enterpriseHomeownersConfig;

    const addInsideWallMaterial = useCallback(() => {
        const wallMaterialNotProvided = _isEmpty(
            _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value', [])
        );
        const wallMaterial = {
            materialType: '',
            percentage: ''
        };

        if (wallMaterialNotProvided) {
            wallMaterial.percentage = 100;
        }

        const {
            _xCenter,
            _dtoName,
        } = transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials;
        const wallMaterialVM = viewModelService.create(wallMaterial, _xCenter, _dtoName);

        transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.value.push(
            wallMaterialVM.value
        );

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(transactionVM);
    }, [hasReplacementCost, transactionVM, updateWizardData, viewModelService, setIsReplacementCostStale]);

    const addFloorMaterial = useCallback(() => {
        const floorMaterialNotProvided = _isEmpty(
            _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.floors.value', [])
        );
        const floor = {
            floorType: '',
            percentage: ''
        };

        if (floorMaterialNotProvided) {
            floor.percentage = 100;
        }

        const {
            _xCenter,
            _dtoName,
        } = transactionVM.lobData.homeowners_EH.coverables.construction.floors;
        const floorMaterialVM = viewModelService.create(floor, _xCenter, _dtoName);

        transactionVM.lobData.homeowners_EH.coverables.construction.floors.value.push(
            floorMaterialVM.value
        );

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(transactionVM);
    }, [hasReplacementCost, transactionVM, updateWizardData, viewModelService, setIsReplacementCostStale]);

    const addGarage = useCallback(() => {
        const garage = {
            garageType: '',
            garageSize: ''
        };
        const {
            _xCenter,
            _dtoName,
        } = transactionVM.lobData.homeowners_EH.coverables.construction.garages;
        const garageVM = viewModelService.create(garage, _xCenter, _dtoName);

        transactionVM.lobData.homeowners_EH.coverables.construction.garages.value.push(
            garageVM.value
        );

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(transactionVM);
    }, [hasReplacementCost, transactionVM, updateWizardData, viewModelService, setIsReplacementCostStale]);

    useEffect(() => {
        if (transactionVM.lobData.homeowners_EH.coverables.construction.garages.value.length === 0) {
            addGarage();
        }

        if (transactionVM.lobData.homeowners_EH.coverables.construction.floors.value.length === 0) {
            addFloorMaterial();
        }

        if (transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.length === 0) {
            addInsideWallMaterial();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(
        () => {
            const initialRequiredFields = [
                'yearBuilt',
                'styleOfHome',
                'numberOfStories',
                'numberOfUnits',
                'totalSquareFeet',
                'propertySlope',
                'foundationType',
                'basementFinishedAreaType',
                'extereriorWallFinish',
                'roofMaterial',
                'yearRoofInstalled',
                'roofShape',
                'ceilingHeight',
                'roomsWithCathedralCeilings',
                'roomsWithCrownMoldings',
                'kitchenCountertop',
                'numberOfHalfBaths',
                'numberOfFullBaths',
                'totalNumberOfPeopleLiving',
                'hasSwimmingPool',
                'doesYourHomeHaveFence',
                'primaryHeatingSystem',
                'primaryCoolingSystem',
                'hasWoodOrCoalStove'
            ]; // Fields to look up by partner/state

            updateRequiredFields(
                isRequired(
                    initialRequiredFields,
                    requiredMetadata,
                    policyState,
                    'MSA'
                )
            );
            // When policystate changes update the required fields
        }, [policyState]
    );

    /**
     * Using the following booleans to make sure the status icons don't turn green until all
     *   added rows have completed fields
     */
    const areGarageFieldsIncomplete = transactionVM.lobData.homeowners_EH.coverables.construction.garages.value
        .some((garage) => {
            if (garage.garageType.toLocaleLowerCase() === 'none') {return false;}

            return !garage.garageSize || !garage.garageType;
        });

    const areFloorFieldsIncomplete = transactionVM.lobData.homeowners_EH.coverables.construction.floors.value
        .some((floor) => !floor.floorType || !floor.percentage);

    const areWallFieldsIncomplete = transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.value
        .some((wall) => !wall.materialType || !wall.percentage);

    const areFireplaceFieldsIncomplete = transactionVM.lobData.homeowners_EH.coverables.construction.firePlaces.value
        .some((firePlace) => !firePlace.heatingUnitType);

    useEffect(
        () => {
            const interiorWalls = _get(
                transactionVM, 'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value', []
            );
            const floors = _get(
                transactionVM, 'lobData.homeowners_EH.coverables.construction.floors.value', []
            );

            if (transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.ceilingHeightType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.ceilingHeightType.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCrownMolding.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCrownMolding.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.kitchenCounterTopType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.kitchenCounterTopType.value !== ''
                && (!insideWallMaterialPercentageSumErrorState)
                && (!floorMaterialErrorState)
                && (!floorMaterialErrorState)
                && interiorWalls.length > 0
                && floors.length > 0
                && !areWallFieldsIncomplete
                && !areFloorFieldsIncomplete) {
                updateInteriorStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || interiorStatus !== SECTION_STATUS.NONE) {
                updateInteriorStatus(SECTION_STATUS.FAILURE);
            }
        },
        [
            transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.ceilingHeightType.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCrownMolding.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.kitchenCounterTopType.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.value.length,
            transactionVM.lobData.homeowners_EH.coverables.construction.floors.value.length,
            insideWallMaterialPercentageSumErrorState,
            floorMaterialErrorState,
            isPageSubmitted,
            transactionVM,
            interiorStatus,
            areWallFieldsIncomplete,
            areFloorFieldsIncomplete
        ]
    );

    useEffect(
        () => {
            if (transactionVM.lobData.homeowners_EH.coverables.construction.foundationType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.foundationType.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.basementFinishedAreaType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.basementFinishedAreaType.value !== '') {
                updateBasementStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || interiorStatus !== SECTION_STATUS.NONE) {
                updateBasementStatus(SECTION_STATUS.FAILURE);
            }
        },
        [transactionVM.lobData.homeowners_EH.coverables.construction.foundationType.value, transactionVM.lobData.homeowners_EH.coverables.construction.basementFinishedAreaType.value, isPageSubmitted, interiorStatus]
    );

    useEffect(
        () => {
            const yearBuilt = parseInt(
                transactionVM.lobData.homeowners_EH.coverables.construction.yearBuilt.value,
                10
            );
            const hasValidYearBuilt = (currentDateYear >= yearBuilt) && (yearBuilt >= minYearBuilt);

            if (hasValidYearBuilt
                && transactionVM.lobData.homeowners_EH.coverables.construction.styleOfHome.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.styleOfHome.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.totalSquareFeet.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.totalSquareFeet.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfStories.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfStories.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.totalNumberOfPeopleLiving.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.totalNumberOfPeopleLiving.value !== '') {
                updatePropertyInformationStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || interiorStatus !== SECTION_STATUS.NONE) {
                updatePropertyInformationStatus(SECTION_STATUS.FAILURE);
            }
        },
        [
            transactionVM.lobData.homeowners_EH.coverables.construction.yearBuilt.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.styleOfHome.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.totalSquareFeet.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.numberOfStories.value,
            transactionVM.lobData.homeowners_EH.coverables.yourHome.totalNumberOfPeopleLiving.value,
            transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value,
            currentDateYear,
            minYearBuilt,
            interiorStatus,
            isPageSubmitted
        ]
    );

    useEffect(
        () => {
            if (transactionVM.lobData.homeowners_EH.coverables.construction.hasWoodOrCoalStove.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.hasWoodOrCoalStove.value !== ''
                && ((viewOnlyMode && heatingTypeValue !== undefined) || heatingSystemType !== undefined)
                && ((viewOnlyMode && coolingTypeValue !== undefined) || coolingSystemType !== undefined)
                && !areFireplaceFieldsIncomplete) {
                updateHvacStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || interiorStatus !== SECTION_STATUS.NONE) {
                updateHvacStatus(SECTION_STATUS.FAILURE);
            }
        },
        [
            transactionVM.lobData.homeowners_EH.coverables.construction.hasWoodOrCoalStove.value,
            heatingSystemType,
            coolingSystemType,
            isPageSubmitted,
            interiorStatus,
            areFireplaceFieldsIncomplete,
            viewOnlyMode,
            heatingTypeValue,
            coolingTypeValue
        ]
    );


    useEffect(
        () => {
            if (transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value !== ''

                && transactionVM.lobData.homeowners_EH.coverables.construction.roofShape.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.roofShape.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.roofType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.roofType.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.slopeType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.slopeType.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.doesYourHomeHaveFence.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.doesYourHomeHaveFence.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value !== ''
                && ((transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value === true
                    && transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence.value)
                    || transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value === false
                    || creditScoreMoreThan597)
                && transactionVM.lobData.homeowners_EH.coverables.construction.garages.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.garages.value.length > 0
                && !areGarageFieldsIncomplete
                && transactionVM.lobData.homeowners_EH.coverables.construction.garages.value.length > 0) {
                updateExteriorStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || exteriorStatus !== SECTION_STATUS.NONE) {
                updateExteriorStatus(SECTION_STATUS.FAILURE);
            }
        },
        [
            transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.roofShape.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.roofType.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.slopeType.value,
            transactionVM.lobData.homeowners_EH.coverables.yourHome.doesYourHomeHaveFence.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence.value,
            creditScoreMoreThan597,
            transactionVM.lobData.homeowners_EH.coverables.construction.garages.value.length,
            isPageSubmitted,
            exteriorStatus,
            transactionVM.lobData.homeowners_EH.coverables.construction.garages.value,
            areGarageFieldsIncomplete
        ]
    );

    const removeInsideWallMaterial = useCallback(
        (evt) => {
            const insideWallMaterialListPath = 'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value';
            const insideWallMaterialPath = evt.path;
            const insideWallMaterial = _get(transactionVM, insideWallMaterialPath).value;
            const insideWallMaterialList = _get(transactionVM, insideWallMaterialListPath);
            const insideWallMaterialIndex = insideWallMaterialList
                .findIndex((insideWallMaterialFromList) => _isEqual(insideWallMaterialFromList, insideWallMaterial));

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: ehCommonMessages.removeInsideWallMaterial,
                message: ehCommonMessages.removeInsideWallMaterialDescription,
                confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'INTERIOR WALL TYPE' }),
                cancelButtonText: e1pCommonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const newInsideWallMaterialList = _remove(insideWallMaterialList, (insideWallMaterialFromList) => (
                        _isEqual(insideWallMaterialFromList, insideWallMaterial)));

                    _set(transactionVM, newInsideWallMaterialList, insideWallMaterialListPath);
                    disregardFieldValidation(`wallMaterial${insideWallMaterialIndex}`);

                    if (hasReplacementCost) {
                        setIsReplacementCostStale(true);
                        _set(
                            transactionVM,
                            'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                            undefined
                        );
                    }

                    if (interiorStatus !== SECTION_STATUS.NONE && insideWallMaterialList.length < 1) {
                        updateInteriorStatus(SECTION_STATUS.FAILURE);
                    }

                    updateWizardData(transactionVM);
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => { });
        },
        [disregardFieldValidation, modalApi, hasReplacementCost, interiorStatus, transactionVM, updateWizardData, setIsReplacementCostStale, translator]
    );
    const removeFloorMaterial = useCallback(
        (evt) => {
            const floorMaterialListPath = 'lobData.homeowners_EH.coverables.construction.floors.value';
            const floorMaterialPath = evt.path;
            const floorMaterial = _get(transactionVM, floorMaterialPath).value;
            const floorMaterialList = _get(transactionVM, floorMaterialListPath);
            const floorMaterialIndex = floorMaterialList
                .findIndex((floorMaterialFromList) => _isEqual(floorMaterialFromList, floorMaterial));

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: ehCommonMessages.removeFloorMaterial,
                message: ehCommonMessages.removeFloorMaterialDescription,
                confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'FLOOR MATERIAL' }),
                cancelButtonText: e1pCommonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const newFloorMaterialList = _remove(floorMaterialList, (floorMaterialFromList) => (
                        _isEqual(floorMaterialFromList, floorMaterial)));

                    _set(transactionVM, newFloorMaterialList, floorMaterialListPath);
                    disregardFieldValidation(`floorMaterial${floorMaterialIndex}`);

                    if (hasReplacementCost) {
                        setIsReplacementCostStale(true);
                        _set(
                            transactionVM,
                            'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                            undefined
                        );
                    }

                    if (interiorStatus !== SECTION_STATUS.NONE && floorMaterialList.length < 1) {
                        updateInteriorStatus(SECTION_STATUS.FAILURE);
                    }

                    updateWizardData(transactionVM);
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => { });
        },
        [disregardFieldValidation, modalApi, hasReplacementCost, interiorStatus, transactionVM, updateWizardData, setIsReplacementCostStale, translator]
    );
    const removeGarage = useCallback(
        (evt) => {
            const garageListPath = 'lobData.homeowners_EH.coverables.construction.garages.value';
            const garagePath = evt.path;
            const garageMaterial = _get(transactionVM, garagePath).value;
            const garageList = _get(transactionVM, garageListPath);
            const garageIndex = garageList
                .findIndex((garageFromList) => _isEqual(garageFromList, garageMaterial));

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: ehCommonMessages.removeEHGarage,
                message: ehCommonMessages.removeEHGarageDescription,
                confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'GARAGE' }),
                cancelButtonText: e1pCommonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const newGarageList = _remove(garageList, (garageFromList) => (
                        _isEqual(garageFromList, garageMaterial)));

                    _set(transactionVM, newGarageList, garageListPath);
                    disregardFieldValidation(`ehGarage
                    ${garageIndex}`);

                    if (hasReplacementCost) {
                        setIsReplacementCostStale(true);
                        _set(
                            transactionVM,
                            'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                            undefined
                        );
                    }

                    if (exteriorStatus !== SECTION_STATUS.NONE && garageList.length < 1) {
                        updateExteriorStatus(SECTION_STATUS.FAILURE);
                    }

                    updateWizardData(transactionVM);
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => { });
        },
        [disregardFieldValidation, modalApi, exteriorStatus, hasReplacementCost, transactionVM, updateWizardData, setIsReplacementCostStale, translator]
    );

    const removeFirePlace = useCallback(
        (evt) => {
            const firePlaceListPath = 'lobData.homeowners_EH.coverables.construction.firePlaces.value';
            const firePlacePath = evt.path;
            const firePlaceMaterial = _get(transactionVM, firePlacePath).value;
            const firePlaceList = _get(transactionVM, firePlaceListPath);
            const firePlaceIndex = firePlaceList
                .findIndex((firePlaceFromList) => _isEqual(firePlaceFromList, firePlaceMaterial));

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: ehCommonMessages.removeFirePlace,
                message: ehCommonMessages.removeFirePlaceDescription,
                confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'FIRE PLACE' }),
                cancelButtonText: e1pCommonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const newFirePlaceList = _remove(firePlaceList, (firePlaceFromList) => (
                        _isEqual(firePlaceFromList, firePlaceMaterial)));

                    _set(transactionVM, newFirePlaceList, firePlaceListPath);
                    disregardFieldValidation(`firePlace${firePlaceIndex}`);

                    if (hasReplacementCost) {
                        setIsReplacementCostStale(true);
                        _set(
                            transactionVM,
                            'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                            undefined
                        );
                    }

                    updateWizardData(transactionVM);
                }
                // eslint-disable-next-line @typescript-eslint/no-empty-function
            }, () => { });
        },
        [disregardFieldValidation, modalApi, hasReplacementCost, transactionVM, updateWizardData, setIsReplacementCostStale, translator]
    );

    const addFirePlace = useCallback(() => {
        const firePlace = {
            heatingUnitType: '',
            heatingUnitTypeDescription: ''
        };
        const {
            _xCenter,
            _dtoName,
        } = transactionVM.lobData.homeowners_EH.coverables.construction.firePlaces;
        const firePlaceVM = viewModelService.create(firePlace, _xCenter, _dtoName);

        transactionVM.lobData.homeowners_EH.coverables.construction.firePlaces.value.push(
            firePlaceVM.value
        );

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(transactionVM);
    }, [hasReplacementCost, transactionVM, updateWizardData, viewModelService, setIsReplacementCostStale]);

    const floorMaterialErrorVisibility = () => {
        const floorMaterialPercentageSumInValid = (transactionVM.lobData.homeowners_EH.coverables
            .construction.floors.value.reduce((sum, el) => sum + +el.percentage, 0) !== 100 && transactionVM.lobData.homeowners_EH.coverables.construction.floors.length > 0);

        if (floorMaterialErrorState !== floorMaterialPercentageSumInValid) {
            updateFloorMaterialErrorState(floorMaterialPercentageSumInValid);
        }

        return floorMaterialPercentageSumInValid;
    };

    const insideWallMaterialErrorVisibility = () => {
        const insideWallMaterialPercentageSumInValid = (transactionVM.lobData.homeowners_EH.coverables
            .construction.insideWallMaterials.value.reduce((sum, el) => sum + +el.percentage, 0) !== 100 && transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.length > 0);

        if (insideWallMaterialPercentageSumErrorState !== insideWallMaterialPercentageSumInValid) {
            updateInsideWallMaterialPercentageSumErrorState(insideWallMaterialPercentageSumInValid);
        }

        return insideWallMaterialPercentageSumInValid;
    };

    const dogInHomeChange = useCallback(
        (value) => {
            _set(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome', value);

            if (!value) {
                _set(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd', undefined);
            }

            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData]
    );

    const swimmingPoolFenceChange = useCallback(
        (value) => {
            _set(transactionVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence', value);
            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData]
    );

    const swimmingPoolValueChange = useCallback(
        (value) => {
            _set(transactionVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool', value);

            if (!value) {
                _set(transactionVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence', undefined);
            }

            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData]
    );

    const heatingSystemTypes = useMemo(() => {
        const heatingSystemDTO = viewModelService.create({}, 'pc', 'amfam.edge.capabilities.policyjob.lob.eh.coverables.dto.EHHeatingSystemDTO');

        return heatingSystemDTO.heatingType.aspects.availableValues.map((item) => ({
                code: item.code,
                name: {
                    id: item.name,
                }
            }));
    }, [viewModelService]);

    const heatingSystemTypeChange = useCallback((value) => {
        updateHeatingSystemType(value);

        const heatingSystems = _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            _set(transactionVM.value, `lobData.homeowners_EH.coverables.construction.heatingSystems[${0}].heatingType`, value);
        } else {
            const heatingSystem = {
                heatingType: value,
                isPrimary: true
            };
            const {
                _xCenter,
                _dtoName,
            } = transactionVM.lobData.homeowners_EH.coverables.construction.heatingSystems;
            const heatingSystemVM = viewModelService.create(heatingSystem, _xCenter, _dtoName);

            transactionVM.lobData.homeowners_EH.coverables.construction.heatingSystems.value.push(
                heatingSystemVM.value
            );
        }

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(transactionVM);
    }, [transactionVM, hasReplacementCost, updateWizardData, viewModelService, setIsReplacementCostStale, updateHeatingSystemType]);

    const coolingSystemTypes = useMemo(() => {
        const coolingSystemDTO = viewModelService.create({}, 'pc', 'amfam.edge.capabilities.policyjob.lob.eh.coverables.dto.EHCoolingSystemDTO');

        return coolingSystemDTO.coolingType.aspects.availableValues.map((item) => ({
                code: item.code,
                name: {
                    id: item.name,
                }
            }));
    }, [viewModelService]);

    const coolingSystemTypeChange = useCallback((value) => {
        updateCoolingSystemType(value);

        const coolingSystems = _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');

        if (coolingSystems !== undefined && coolingSystems.length > 0) {
            _set(transactionVM.value, `lobData.homeowners_EH.coverables.construction.coolingSystems[${0}].heatingType`, value);
        } else {
            const coolingSystem = {
                coolingType: value,
                isPrimary: true
            };
            const {
                _xCenter,
                _dtoName,
            } = transactionVM.lobData.homeowners_EH.coverables.construction.coolingSystems;
            const coolingSystemVM = viewModelService.create(coolingSystem, _xCenter, _dtoName);

            transactionVM.lobData.homeowners_EH.coverables.construction.coolingSystems.value.push(
                coolingSystemVM.value
            );
        }

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(transactionVM);
    }, [transactionVM, hasReplacementCost, updateWizardData, viewModelService, setIsReplacementCostStale, updateCoolingSystemType]);


    const checkSwimmingPoolFenceVisibility = useMemo(() => 
         !!_get(transactionVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool') && !creditScoreMoreThan597
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [creditScoreMoreThan597, transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value]);

    const replacementCostFieldOnChange = useCallback(
        (value, path) => {
            _set(transactionVM, path, value);
            updateWizardData(transactionVM);

            if (['lobData.homeowners_EH.coverables.construction.totalSquareFeet',
                'lobData.homeowners_EH.coverables.construction.yearBuilt']
                .includes(path)) {
                updateInspectionData.current = true;
            }

            if (hasReplacementCost) {
                setIsReplacementCostStale(true);
                _set(
                    transactionVM,
                    'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                    undefined
                );
            }
        },
        [hasReplacementCost, transactionVM, updateWizardData, setIsReplacementCostStale],
    );


    const resolvers = {
        resolveCallbackMap: {
            onAddInsideWallMaterialClick: addInsideWallMaterial,
            onRemoveInsideWallMaterialClick: removeInsideWallMaterial,
            onAddFloorMaterialClick: addFloorMaterial,
            onRemoveFloorMaterialClick: removeFloorMaterial,
            onAddGarageClick: addGarage,
            onRemoveGarageClick: removeGarage,
            onAddFirePlaceClick: addFirePlace,
            onRemoveFirePlaceClick: removeFirePlace,
            onValidate: setComponentValidation,
        },
        resolveClassNameMap: styles
    };

    const generateOverrides = useCallback(() => {
        const floors = _get(
            transactionVM,
            'lobData.homeowners_EH.coverables.construction.floors.value',
            []
        );
        const wallMaterials = _get(
            transactionVM,
            'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value',
            []
        );
        const garages = _get(
            transactionVM,
            'lobData.homeowners_EH.coverables.construction.garages.value',
            []
        );
        const firePlaces = _get(
            transactionVM,
            'lobData.homeowners_EH.coverables.construction.firePlaces.value',
            []
        );
        const floorOverrides = floors.map((_, index) => ({
                [`floorMaterial${index}`]: {
                    showErrors: isPageSubmitted,
                    onValueChange: replacementCostFieldOnChange,
                    viewOnly: viewOnlyMode
                },
                [`deleteFloorMaterial${index}`]: {
                    visible: !viewOnlyMode
                }
            }));
        const wallMaterialsOverrides = wallMaterials.map((_, index) => ({
                [`wallMaterial${index}`]: {
                    showErrors: isPageSubmitted,
                    onValueChange: replacementCostFieldOnChange,
                    viewOnly: viewOnlyMode
                },
                [`ehDeleteInsideWallMaterial${index}`]: {
                    visible: !viewOnlyMode
                }
            }));
        const garagesOverrides = garages.map((_, index) => ({
                [`ehGarage${index}`]: {
                    showErrors: isPageSubmitted,
                    onValueChange: replacementCostFieldOnChange,
                    viewOnly: viewOnlyMode
                },
                [`deleteGarage${index}`]: {
                    visible: !viewOnlyMode
                }
            }));
        const firePlacesOverrides = firePlaces.map((_, index) => ({
                [`firePlace${index}`]: {
                    showErrors: isPageSubmitted,
                    onValueChange: replacementCostFieldOnChange,
                    viewOnly: viewOnlyMode
                },
                [`deleteFirePlace${index}`]: {
                    visible: !viewOnlyMode
                }
            }));

        return Object.assign(
            {},
            ...floorOverrides,
            ...wallMaterialsOverrides,
            ...garagesOverrides,
            ...firePlacesOverrides
        );
    }, [replacementCostFieldOnChange, isPageSubmitted, transactionVM, viewOnlyMode]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false,
            readOnly: viewOnlyMode
        },
        yearBuilt: {
            required: _includes(requiredFields, 'yearBuilt'),
            maxValue: currentDateYear,
            showErrors: isPageSubmitted,
            onValidate,
            onValueChange: replacementCostFieldOnChange,
            minValue: minYearBuilt,
            readOnly: viewOnlyMode || (jobType !== 'Submission' && !canChangeYearBuilt)
        },
        styleOfHome: {
            required: _includes(requiredFields, 'styleOfHome'),
            onValueChange: replacementCostFieldOnChange
        },
        numberOfStories: {
            required: _includes(requiredFields, 'numberOfStories'),
            onValueChange: replacementCostFieldOnChange
        },
        numberOfUnits: {
            required: _includes(requiredFields, 'numberOfUnits')
        },
        totalSquareFeet: {
            required: _includes(requiredFields, 'totalSquareFeet'),
            onValueChange: replacementCostFieldOnChange
        },
        propertySlope: {
            required: _includes(requiredFields, 'propertySlope'),
            onValueChange: replacementCostFieldOnChange
        },
        foundationType: {
            required: _includes(requiredFields, 'foundationType'),
            onValueChange: replacementCostFieldOnChange
        },
        basementFinishedAreaType: {
            required: _includes(requiredFields, 'basementFinishedAreaType'),
            onValueChange: replacementCostFieldOnChange
        },
        roofMaterial: {
            required: _includes(requiredFields, 'roofMaterial'),
            onValueChange: replacementCostFieldOnChange
        },
        roofShape: {
            required: _includes(requiredFields, 'roofShape'),
            onValueChange: replacementCostFieldOnChange
        },
        yearRoofInstalled: {
            required: _includes(requiredFields, 'yearRoofInstalled'),
            maxValue: currentDateYear,
            minValue: _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.yearBuilt.value'),
            showErrors: isPageSubmitted,
            onValidate
        },
        totalNumberOfPeopleLiving: {
            required: _includes(requiredFields, 'totalNumberOfPeopleLiving')
        },
        extereriorWallFinish: {
            required: _includes(requiredFields, 'extereriorWallFinish'),
            onValueChange: replacementCostFieldOnChange
        },
        ceilingHeight: {
            required: _includes(requiredFields, 'ceilingHeight'),
            onValueChange: replacementCostFieldOnChange
        },
        roomsWithCrownMoldings: {
            required: _includes(requiredFields, 'roomsWithCrownMoldings'),
            onValueChange: replacementCostFieldOnChange
        },
        roomsWithCathedralCeilings: {
            required: _includes(requiredFields, 'roomsWithCathedralCeilings'),
            onValueChange: replacementCostFieldOnChange
        },
        kitchenCountertop: {
            required: _includes(requiredFields, 'kitchenCountertop'),
            onValueChange: replacementCostFieldOnChange
        },
        numberOfHalfBaths: {
            required: _includes(requiredFields, 'numberOfHalfBaths'),
            onValueChange: replacementCostFieldOnChange
        },
        numberOfFullBaths: {
            required: _includes(requiredFields, 'numberOfFullBaths'),
            onValueChange: replacementCostFieldOnChange
        },
        floorMaterialError: {
            visible: floorMaterialErrorVisibility()
        },
        insideWallMaterialError: {
            visible: insideWallMaterialErrorVisibility()
        },
        dogInHome: {
            onValueChange: dogInHomeChange
        },
        highRiskDogBreedIndGridWithSeprator: {
            visible: !!_get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
        },
        highRiskDogBreedInd: {
            required: !!_get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
        },
        swimmingPool: {
            onValueChange: swimmingPoolValueChange,
            required: _includes(requiredFields, 'hasSwimmingPool'),
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        swimmingPoolFence: {
            required: checkSwimmingPoolFenceVisibility,
            value: _get(transactionVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence'),
            onValueChange: swimmingPoolFenceChange,
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        swimmingPoolFenceGridWithSeprator: {
            visible: checkSwimmingPoolFenceVisibility
        },
        sixtyftFence: {
            required: _includes(requiredFields, 'doesYourHomeHaveFence'),
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        addFloorMaterial: {
            visible: !viewOnlyMode && transactionVM.lobData.homeowners_EH.coverables.construction.floors.length < 5
        },
        addInsideWallMaterial: {
            visible: !viewOnlyMode && transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.length < 5
        },
        primaryHeatingSystem: {
            availableValues: heatingSystemTypes,
            onValueChange: heatingSystemTypeChange,
            value: viewOnlyMode ? heatingTypeValue : heatingSystemType,
            required: _includes(requiredFields, 'primaryHeatingSystem')
        },
        primaryCoolingSystem: {
            availableValues: coolingSystemTypes,
            onValueChange: coolingSystemTypeChange,
            value: viewOnlyMode ? coolingTypeValue : coolingSystemType,
            required: _includes(requiredFields, 'primaryCoolingSystem')
        },
        woodCoalStove: {
            required: _includes(requiredFields, 'hasWoodOrCoalStove'),
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        addFirePlace: {
            visible: !viewOnlyMode && transactionVM.lobData.homeowners_EH.coverables.construction.firePlaces.length < 3
        },
        interiorAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.interior, interiorStatus)
        },
        basementAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.basementTitle, basementStatus)
        },
        heatingAndCoolingAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.heatingAndCooling, hvacStatus)
        },
        exteriorAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.exterior, exteriorStatus)
        },
        propertyInformationAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.propertyInformation, propertyInformationStatus)
        },
        Accordion: {
            accordionStates: expandedSections,
            onUpdateAccordionStates: (openedSections) => {
                setExpandedSections(openedSections);
            }
        },
        addGarage: {
            visible: !viewOnlyMode
        },
        ...generateOverrides()
    };

    
    const readValue = useCallback((fieldId, fieldPath) => readViewModelValue(
            metadata.pageContent, transactionVM, fieldId, fieldPath, overrideProps
        ), [transactionVM, overrideProps]);


    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionVM}
            resolveValue={readValue}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={setComponentValidation}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

E1PEHHF9PropertyDetailsComponent.propTypes = {
    id: PropTypes.string,
    transactionVM: PropTypes.shape({}),
    onValidate:  PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    updateWizardData:  PropTypes.func.isRequired,
    isPageSubmitted: PropTypes.bool,
    heatingSystemType: PropTypes.string,
    updateHeatingSystemType: PropTypes.func,
    coolingSystemType: PropTypes.string,
    updateCoolingSystemType: PropTypes.func,
    setIsReplacementCostStale: PropTypes.func,
    creditScoreMoreThan597: PropTypes.bool
};

E1PEHHF9PropertyDetailsComponent.defaultProps = {
    transactionVM: {},
    id: undefined,
    isPageSubmitted: false,
    onValidate: undefined,
    viewOnlyMode: false
    
};
export default E1PEHHF9PropertyDetailsComponent;
