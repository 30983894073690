import React, { useCallback, useState, useEffect } from 'react';
import { ModalNext, ModalHeader, ModalFooter, ModalBody } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    e1pDateUtil
} from 'e1p-capability-hooks';
import { TypekeyUtil } from 'e1p-portals-util-js';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from './E1PWindstormMitigationDiscountModal.metadata.json5';
import styles from './E1PWindstormMitigationDiscountModal.module.scss';
import messages from './E1PWindstormMitigationDiscountModal.messages';

import { Button } from '@jutro/legacy/components';

function E1PWindstormMitigationDiscountModal(props) {
    const {
        title,
        isOpen,
        onResolve,
        onReject,
        transactionVM,
        viewModelService,
        viewOnly,
        setFieldsChangedOnCoveragePage

    } = props;
    const translator = useTranslator();
    const {
        isComponentValid, onValidate
    } = useValidation('E1PWindstormMitigationDiscountModal');
    const [transactionVMClone, setTransactionVMClone] = useState(viewModelService.clone(transactionVM));
    const [showCompleteMissingFieldMessage, updateShowCompleteMissingFieldMessage] = useState(false);
    const [openingProtectionValue, updateOpeningProtectionValue] = useState(undefined);

    /**
     * Helper function to get selected designation value
     */
    const getSelectedDesignationValue = useCallback(() => {
        const allDesignationCodes = TypekeyUtil
            .getAvailableValuesForTypekey(viewModelService,
                'EHWindstormMitigationFeatureType_Ext',
                'FortifiedFeatureTypes').map((fortifiedFeatureType) => fortifiedFeatureType.code);
        const features = _.get(
            transactionVMClone,
            'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
            []
        ).map((feature) => feature.featureType);
        const selectedDesignation = features.find((feature) => allDesignationCodes.includes(feature));

        return selectedDesignation;
    }, [transactionVMClone, viewModelService]);

    const checkGivenWindstormMitigationIsPresentOrNot = useCallback(
        (mitigationFeature, transactionVMCloneTemp = transactionVMClone) => {
            const features = _.get(
                transactionVMCloneTemp,
                'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
                []
            );
            const isGivenMitigationPresent = !_.isUndefined(
                features.find((feature) => feature.featureType === mitigationFeature)
            );

            // As we dont want to default value opening protection toggle
            if (mitigationFeature === 'openingprotection' && !isGivenMitigationPresent) {
                if (_.isUndefined(getSelectedDesignationValue())) {
                    return undefined;
                }
            }

            return isGivenMitigationPresent;
        }, [getSelectedDesignationValue, transactionVMClone]
    );

    useEffect(() => {
        updateOpeningProtectionValue(
            checkGivenWindstormMitigationIsPresentOrNot('openingprotection')
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for handling a mouse-click on the "Cancel" button.
     */
    const onCancel = useCallback(
        (evt) => {
            evt.stopPropagation();
            onReject();
        },
        [onReject]
    );


    /**
     * Helper callback for handling a mouse-click on the "Save" button.
     */
    const onSave = useCallback(
        (evt) => {
            if (!isComponentValid) {
                updateShowCompleteMissingFieldMessage(true);

                return false;
            }

            evt.stopPropagation();
            onResolve(transactionVMClone);
        },
        [isComponentValid, onResolve, transactionVMClone]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        }
    };

    /**
     * Helper function to add given windstorm mitigation feature
     */
    const addWindstormMitigationFeature = useCallback((mitigationFeature, tempTransactionVMClone = transactionVMClone) => {
        const {
            _xCenter,
            _dtoName
        } = tempTransactionVMClone.lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures;
        const newTransactionVMCloneData = viewModelService.clone(tempTransactionVMClone);
        const windstormMitigationFeatureVM = viewModelService.create(mitigationFeature, _xCenter, _dtoName);

        if (
            _.get(
                newTransactionVMCloneData,
                'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value'
            ) === undefined
        ) {
            _.set(newTransactionVMCloneData,
                'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
                []);
        }

        newTransactionVMCloneData.lobData.homeowners_EH.coverables.construction
            .windstormMitigationFeatures.value.push(
                windstormMitigationFeatureVM.value
            );
        setTransactionVMClone(newTransactionVMCloneData);
        setFieldsChangedOnCoveragePage(true);

        return newTransactionVMCloneData;
    }, [setFieldsChangedOnCoveragePage, transactionVMClone, viewModelService]);

    /**
        * Helper function to remove given windstorm mitigation feature
        */
    const removeMitigationFeature = useCallback((mitigationFeature) => {
        const features = _.get(
            transactionVMClone,
            'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
            []
        );
        const featuresWithoutGivenMitigationFeature = features
            .filter((feature) => feature.featureType !== mitigationFeature);
        const newTransactionVMCloneData = viewModelService.clone(transactionVMClone);

        _.set(
            newTransactionVMCloneData,
            'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
            featuresWithoutGivenMitigationFeature
        );
        setTransactionVMClone(newTransactionVMCloneData);
        setFieldsChangedOnCoveragePage(true);

        return newTransactionVMCloneData;
    }, [setFieldsChangedOnCoveragePage, transactionVMClone, viewModelService]);


    /**
     * Helper function which returns default value for totalHipRoof toggle
     * if roof shape type is 'hip', we are defaulting totalHipRoof to 'Yes'
     */
    const getDefaultValueForTotalHipRoofToggle = useCallback(() => {
        const roofShape = _.get(
            transactionVMClone,
            'lobData.homeowners_EH.coverables.construction.roofShape.value'
        );

        return roofShape === 'hip';
    }, [transactionVMClone]);

    /**
     * Helper function triggers when totalhiproof toggle value changes
     */
    const onTotalHipRoofToggleChange = useCallback((value) => {
        updateShowCompleteMissingFieldMessage(false);

        const totalHipRoofFeaturePresent = checkGivenWindstormMitigationIsPresentOrNot('totalhiproof');

        if (value) {
            if (!totalHipRoofFeaturePresent) {
                const totalHipRoofFeature = {
                    featureType: 'totalhiproof'
                }

                // if toggle value is yes and totalHipRoof is not present then we will add it
                addWindstormMitigationFeature(totalHipRoofFeature);
            }
        } else if (totalHipRoofFeaturePresent) {
            removeMitigationFeature('totalhiproof');
        }
    }, [addWindstormMitigationFeature, checkGivenWindstormMitigationIsPresentOrNot, removeMitigationFeature]);

    /**
    * Helper function triggers when opening protection toggle value changes
    */
    const onOpeningProtectionToggleChange = useCallback((value) => {
        updateShowCompleteMissingFieldMessage(false);
        updateOpeningProtectionValue(value);

        const openingProtectionFeaturePresent = checkGivenWindstormMitigationIsPresentOrNot('openingprotection');

        if (value) {
            if (!openingProtectionFeaturePresent) {
                const openingProtectionFeature = {
                    featureType: 'openingprotection'
                }

                addWindstormMitigationFeature(openingProtectionFeature);
            }
        } else if (openingProtectionFeaturePresent) {
            removeMitigationFeature('openingprotection');
        }
    }, [addWindstormMitigationFeature, checkGivenWindstormMitigationIsPresentOrNot, removeMitigationFeature]);

    const getDesignationAvailableValues = useCallback(() => {
        const availableValues = TypekeyUtil
            .getAvailableValuesForTypekey(
                viewModelService,
                'EHWindstormMitigationFeatureType_Ext',
                'FortifiedFeatureTypes');

        return availableValues;
    }, [viewModelService]);

    /**
   * Helper function triggers when any designation is selected
   */
    const onDesignationRadioButtonSelection = useCallback((value) => {
        const allDesignationCodes = TypekeyUtil
            .getAvailableValuesForTypekey(viewModelService,
                'EHWindstormMitigationFeatureType_Ext',
                'FortifiedFeatureTypes').map((fortifiedFeatureType) => fortifiedFeatureType.code);
        const selectedMitigationFeatures = _.get(
            transactionVMClone,
            'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
            []
        ).map((feature) => feature.featureType);
        // Removing previously selected designation feature type
        const designationCodesWithoutCurrentSelectedValue = allDesignationCodes
            .filter(
                (designationCode) => designationCode !== value
                    && selectedMitigationFeatures.includes(designationCode)
            );

        /**
         * In single function we are removing and adding new value, transactionVMClone does not get updated
         * after removal of mitigation feature so returning changed vm from removeMitigationFeature function
         * and passing it to the addWindstormMitigationFeature and checkGivenWindstormMitigationIsPresentOrNot funcs
         */

        let updatedTransactionVMClone = transactionVMClone;

        designationCodesWithoutCurrentSelectedValue.forEach((fortifiedFeature) => {
            updatedTransactionVMClone = removeMitigationFeature(fortifiedFeature);
        });

        const isSelectedFeaturePresent = checkGivenWindstormMitigationIsPresentOrNot(value, updatedTransactionVMClone);

        if (!isSelectedFeaturePresent) {
            const selectedDesignationFeature = {
                featureType: value
            }

            addWindstormMitigationFeature(selectedDesignationFeature, updatedTransactionVMClone);
        }
    }, [
        addWindstormMitigationFeature, checkGivenWindstormMitigationIsPresentOrNot,
        removeMitigationFeature, transactionVMClone, viewModelService
    ]);

    const designationExpirationDateChange = useCallback((value) => {
        const newTransactionVMCloneData = viewModelService.clone(transactionVMClone);

        _.set(
            newTransactionVMCloneData,
            'lobData.homeowners_EH.coverables.construction.certificationExpirationDate.value',
            e1pDateUtil.convertToUTC(value)
        );
        setTransactionVMClone(newTransactionVMCloneData);
        setFieldsChangedOnCoveragePage(true);
    }, [setFieldsChangedOnCoveragePage, transactionVMClone, viewModelService]);

    const getDesignationCertificationExpirationDate = useCallback(() => {
        const expirationDate = _.get(
            transactionVMClone,
            'lobData.homeowners_EH.coverables.construction.certificationExpirationDate.value'
        );

        if (_.isUndefined(expirationDate)) {
            return undefined;
        }

        return e1pDateUtil.convertToUTC(
            expirationDate
        );
    }, [transactionVMClone]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false,
            showRequired: true,
            showErrors: showCompleteMissingFieldMessage,
            readOnly: viewOnly
        },
        totalHipRoofToggle: {
            value: checkGivenWindstormMitigationIsPresentOrNot('totalhiproof'),
            onValueChange: onTotalHipRoofToggleChange,
            defaultValue: getDefaultValueForTotalHipRoofToggle()
        },
        openingProtectionToggle: {
            value: openingProtectionValue,
            onValueChange: onOpeningProtectionToggleChange,
        },
        completeMissingFieldMessageDiv: {
            visible: showCompleteMissingFieldMessage
        },
        designation: {
            availableValues: getDesignationAvailableValues(),
            onValueChange: onDesignationRadioButtonSelection,
            value: getSelectedDesignationValue(),
            tooltip: {
                text: messages.designationHelpText,
                placement: 'bottom-end'
            }
        },
        designationExpirationDate: {
            dateDTO: getDesignationCertificationExpirationDate(),
            updateDateDto: designationExpirationDateChange,
            isRequired: !_.isUndefined(getSelectedDesignationValue()),
            isDateFormatDDMMYYYY: true
        }
    };

    /**
    * Helper callback for reading values from the view model.
    */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.componentContent,
                transactionVMClone,
                id,
                path,
                overrideProps),
        [transactionVMClone, overrideProps]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ModalHeader title={translator(title)} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={transactionVMClone}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    {translator(e1pCommonMessages.cancel)}
                </Button>
                <Button onClick={onSave} hidden={viewOnly}>
                    {_.upperCase(translator(e1pCommonMessages.save))}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

E1PWindstormMitigationDiscountModal.propTypes = {
    viewOnly: PropTypes.bool,
    setFieldsChangedOnCoveragePage: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    transactionVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.func.isRequired,
};

E1PWindstormMitigationDiscountModal.defaultProps = {
    viewOnly: false
};

export default E1PWindstormMitigationDiscountModal;

