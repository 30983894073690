
/**
 * Returns a boolean indicating whether the given address objects represent the same address.
 *
 * @param {Object} addressValue1 The first of two address objects to be compared (not a VMNode).
 * @param {Object} addressValue2 The second of two address objects to be compared (not a VMNode).
 * @returns {boolean} True if the given address objects represent the same address, false otherwise.
 */
function isSameAddress(addressValue1, addressValue2) {
    if (addressValue1?.addressLine1?.toUpperCase() !== addressValue2?.addressLine1?.toUpperCase()) {
        return false;
    }
    if (addressValue1?.address1?.toUpperCase() !== addressValue2?.address1?.toUpperCase()) {
        return false;
    }
    if (addressValue1?.addressLine2?.toUpperCase() !== addressValue2?.addressLine2?.toUpperCase()) {
        return false;
    }
    if (addressValue1?.address2?.toUpperCase() !== addressValue2?.address2?.toUpperCase()) {
        return false;
    }
    if (addressValue1?.city?.toUpperCase() !== addressValue2?.city?.toUpperCase()) {
        return false;
    }
    if (addressValue1?.state?.toUpperCase() !== addressValue2?.state?.toUpperCase()) {
        return false;
    }
    if (addressValue1?.postalCode !== addressValue2?.postalCode) {
        return false;
    }
    if (addressValue1?.zipCode !== addressValue2?.zipCode) {
        return false;
    }
    return true;
}

function validateSpecialCharacters(addressString = '') {
    // below regex will accept all characters, numbers, space and also following special characters ". / # & -"
    const regex = new RegExp('^[ A-Za-z0-9./#&-]*$');
    const isValid =  regex.test(addressString);
    return isValid;
}

export default { isSameAddress, validateSpecialCharacters };
