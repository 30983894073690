import { useCallback, useContext } from 'react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import {
    e1pDateUtil
} from 'e1p-capability-hooks';
import {
    get as _get,
    isEmpty as _isEmpty,
    set as _set,
    isEqual as _isEqual,
    remove as _remove
} from 'lodash';

/**
 * @purpose The purpose of this util is to reuse code between
 *   Verified and unverified flows
 * @param {VMNode} priorPolicyUpdateVMList
 * @param {Function} onValueChange
 * @param {String} path
 * @returns {*} object
 */
function usePriorPolicyUpdateUtil(
    priorPolicyUpdateVMList = [],
    onValueChange = undefined,
    path = undefined
) {
    const viewModelService = useContext(ViewModelServiceContext);
    const createPriorPolicyUpdatesVM = useCallback(
        (integrationId, reason) => {
            if (_isEmpty(priorPolicyUpdateVMList)) {
                _set(priorPolicyUpdateVMList, 'value', []);
            }

            const priorPolicyUpdatesObj = {
                reason: undefined,
                startDate: undefined,
                endDate: undefined,
                integrationId,
                policyRole: 'PolicyPriNamedInsured',
                isUWConsideration: reason === 'UWConsideration' ? true : undefined,
                type: reason === 'UWConsideration' ? 'PriorPolicyUWConsideration_Ext' : undefined
            };

            const priorPolicyUpdatesVM = viewModelService.create(
                priorPolicyUpdatesObj,
                'pc',
                'amfam.edge.capabilities.policyjob.common.priorpolicy.dto.PriorPolicyUpdateDTO'
            );

            priorPolicyUpdateVMList.value.push(priorPolicyUpdatesVM.value);

            if (onValueChange) {
                onValueChange(priorPolicyUpdateVMList.value, `${path}.value`);
            }
        },
        [onValueChange, path, priorPolicyUpdateVMList, viewModelService]
    );

    const removePriorPolicyUpdate = useCallback(
        (updatePath) => {
            const policyUpdateListPath = 'lobData.personalAuto_EA.priorPolicyUpdates.value';
            const policyUpdatePath = `children[${updatePath}]`;
            const policyUpdate = _get(priorPolicyUpdateVMList, policyUpdatePath).value;
            const policyUpdateList = priorPolicyUpdateVMList.value;
            const newPrimaryInsuredList = _remove(
                policyUpdateList,
                (policyUpdateFromList) => _isEqual(policyUpdateFromList, policyUpdate)
            );

            _set(
                priorPolicyUpdateVMList,
                newPrimaryInsuredList,
                policyUpdateListPath
            );
        }, [priorPolicyUpdateVMList]
    );

    /**
   * Helper callback to modify dates to desired format by PolicyCenter
   */
    const modifyDates = useCallback((transactionVM) => {
        const dateFormat = (date) => e1pDateUtil.convertToUTCForDateWithoutTZ(date);
        const priorPolicies = _get(
            transactionVM,
            'lobData.personalAuto_EA.priorPolicies.value',
            []
        );

        priorPolicies.forEach((policy) => {
            const tempPolicy = policy;

            tempPolicy.policyInceptionDate = dateFormat(
                tempPolicy.policyInceptionDate
            );
            tempPolicy.policyHolderExpirationDate = dateFormat(
                tempPolicy.policyHolderExpirationDate
            );
        });

        const priorPolicyUpdates = _get(
            transactionVM,
            'lobData.personalAuto_EA.priorPolicyUpdates.value',
            []
        );

        priorPolicyUpdates.forEach((policyUpdate) => {
            const tempPolicyUpdate = policyUpdate;

            tempPolicyUpdate.startDate = dateFormat(tempPolicyUpdate.startDate);
            tempPolicyUpdate.endDate = dateFormat(tempPolicyUpdate.endDate);
        });
    }, []);

    return {
        createPriorPolicyUpdatesVM,
        modifyDates,
        removePriorPolicyUpdate
    };
}

export default usePriorPolicyUpdateUtil;
