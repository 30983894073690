import { defineMessages } from 'react-intl';

export default defineMessages({
    standardizingAddressMessage: {
        id: 'quoteandbind.views.ea-vehicle-details.Standardizing address...',
        defaultMessage: 'Standardizing address...'
    },
    addGaragingAddressSubHeader: {
        id: 'quoteandbind.views.ea-vehicle-details.Subtext explaining that the agent needs to add the address the vehicle is parked/garaged at.',
        defaultMessage: 'Subtext explaining that the agent needs to add the address the vehicle is parked/garaged at.'
    },
    addressLine1: {
        id: 'quoteandbind.views.ea-vehicle-details.Address Line 1',
        defaultMessage: 'Address Line 1'
    },
    addressLine2: {
        id: 'quoteandbind.views.ea-vehicle-details.Address Line 2',
        defaultMessage: 'Address Line 2'
    },
    addressLine2Placeholder: {
        id: 'quoteandbind.views.ea-vehicle-details.Apt, Suite, Building, Floor, etc.',
        defaultMessage: 'Apt, Suite, Building, Floor, etc.'
    },
    addressCity: {
        id: 'quoteandbind.views.ea-vehicle-details.City',
        defaultMessage: 'City'
    },
    addressState: {
        id: 'quoteandbind.views.ea-vehicle-details.State',
        defaultMessage: 'State'
    },
    addressZipCode: {
        id: 'quoteandbind.views.ea-vehicle-details.Zip Code',
        defaultMessage: 'Zip Code'
    }
});
