import React, { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, withModalContext } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './ProtectiveDevicesPage.metadata.json5';
import styles from './ProtectiveDevicesPage.module.scss';
import messages from './ProtectiveDevicesPage.messages';

import { Button } from '@jutro/legacy/components';

const fireCodes = [
    'automaticsprinklersystem',
    'connectedgasleakagedetector',
    'connectedgenerator',
    'connectedthermostat',
    'automaticsprinklersystemlimitedareas',
    'smokedetectors'
];

const fireAlarmCodes = [
    'connectedfirealarm',
    'professionallymonitoredfirealarm',
    'connectedandprofessionallymonitoredfirealarm',
    'localfirealarm'
];

const theftCodes = [
    'connectedvideodoorbell'
];

const burglarAlarmCodes = [
    'connectedburglaralarm',
    'professionallymonitoredburglaralarm',
    'connectedandprofessionallymonitoredburglaralarm',
    'localburglaralarm'
];

const waterCodes = [
    'connectedwatersensorwithautomaticshutoff',
    'connectedwatersensorwithoutautomaticshutoff'
];

const connectedCodes = [
    'connectedwatersensorwithautomaticshutoff',
    'connectedwatersensorwithoutautomaticshutoff',
    'connectedburglaralarm',
    'connectedandprofessionallymonitoredburglaralarm',
    'connectedvideodoorbell',
    'connectedfirealarm',
    'connectedandprofessionallymonitoredfirealarm',
    'connectedgasleakagedetector',
    'connectedgenerator',
    'connectedthermostat'
];

const inEligibleProtectiveDeviceCombinationForBurglary = [
    'centralburglaryalarm',
    'directlinetopolicestation'
];

const inEligibleProtectiveDeviceCombinationForFire = [
    'directlinetofirestation',
    'centralfirealarm'
];

const inEligibleProtectiveDeviceCombinationForSprinkler = [
    'automaticsprinklersystemlimitedareas',
    'automaticsprinklersystem'
];


function ProtectiveDevices(props) {
    const {
        submissionVM,
        viewModelService,
        isOpen,
        onResolve,
        viewOnlyMode
    } = props;
    const [pdFormData, updatePDFormData] = useState(viewModelService.clone(submissionVM));
    const [options, setOptions] = useState([]);
    const [selectedProtectiveDevices, setSelectedProtectiveDevices] = useState([]);
    const [fireProtectiveDevices, setFireProtectiveDevices] = useState([]);
    const [theftProtectiveDevices, setTheftProtectiveDevices] = useState([]);
    const [waterProtectiveDevices, setWaterProtectiveDevices] = useState([]);
    const [selectedFireProtectiveDevices, setSelectedFireProtectiveDevices] = useState([]);
    const [selectedTheftProtectiveDevices, setSelectedTheftProtectiveDevices] = useState([]);
    const [selectedWaterProtectiveDevice, setSelectedWaterProtectiveDevice] = useState('');
    const [fireAlarmTypes, setFireAlarmTypes] = useState([]);
    const [burglarAlarmTypes, setBurglarAlarmTypes] = useState([]);
    const [selectedFireAlarmType, setSelectedFireAlarmType] = useState('');
    const [selectedBurglarAlarmType, setSelectedBurglarAlarmType] = useState('');
    const [showFireDeviceFields, setShowFireDeviceFields] = useState(false);
    const [showTheftDeviceFields, setShowTheftDeviceFields] = useState(false);
    const [showWaterDeviceFields, setShowWaterDeviceFields] = useState(false);
    const [availableProtectiveDevicesMap, setAvailableProtectiveDevicesMap] = useState([]);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const translator = useTranslator();
    const [showTopError, setShowTopError] = useState(false);
    const [dropdownActive, setDropdownActive] = useState(false);
    const baseState = _.get(
        submissionVM,
        'baseData.policyAddress.state.value.code',
        _.get(submissionVM, 'policyAddress.state.value.code')
    );
    // We will use the available discounts to decide which protective device filter to use
    const discountsList = _.get(submissionVM, 'lobData.homeowners_EH.modifiers.value');
    const hasSecureHomeDisc = !!discountsList.find((discount) => discount.patternCode === 'EH_SecureHomeDisc');
    const hasProtectiveDevicesDisc = !!discountsList.find((discount) => discount.patternCode === 'EH_ProtectiveDevicesDisc');
    const { isComponentValid, onValidate } = useValidation('ProtectiveDevicesPage');

    const setProtectiveDevices = useCallback(() => {
        let protectiveDeviceValues = [];
        const protectiveDevicesFilters = _.get(viewModelService, 'productMetadata').get('pc').types.getTypelist('EHHomeProtectionDeviceType_Ext').filters;

        if (protectiveDevicesFilters) {
            protectiveDeviceValues = _.find(protectiveDevicesFilters, { name: 'MSASafetyFirstDeviceTypes' }).codes;

            // North Carolina Rate Buereau device types for NC
            if (baseState === 'NC') {
                const ncrbCodes = _.find(protectiveDevicesFilters, { name: 'NCRBDeviceTypes' }).codes;

                protectiveDeviceValues = protectiveDeviceValues.concat(ncrbCodes);
            }
        }

        const protectiveDevicesList = protectiveDeviceValues
            .map((item) => ({
                code: item.code,
                name: translator({
                    id: item.name,
                    defaultMessage: item.name,
                }),
            }));

        setOptions(protectiveDevicesList);
    }, [baseState, translator, viewModelService]);

    const setSecureHomeDevices = useCallback(() => {
        let protectiveDeviceValues = [];
        const protectiveDevicesFilters = _.get(viewModelService, 'productMetadata').get('pc').types.getTypelist('EHHomeProtectionDeviceType_Ext').filters;

        if (protectiveDevicesFilters) {
            protectiveDeviceValues = _.find(protectiveDevicesFilters, { name: 'MSASecureHomeDeviceTypes' }).codes;

            if (baseState === 'NC') {
                // North Carolina Rate Buereau device types for NC
                const ncrbCodes = _.find(protectiveDevicesFilters, { name: 'NCRBDeviceTypes' }).codes;

                protectiveDeviceValues = protectiveDeviceValues.concat(ncrbCodes);
            }
        }

        const protectiveDevicesList = protectiveDeviceValues
            .map((item) => ({
                code: item.code,
                name: translator({
                    id: item.name,
                    defaultMessage: item.name,
                }),
            }));
        const fireDevices = [{ code: 'fireAlarm', name: 'Fire Alarm' }];
        const fireTypes = [];
        const theftDevices = [{ code: 'burglarAlarm', name: 'Burglar Alarm' }];
        const burglarTypes = [];
        const waterDevices = [];

        protectiveDevicesList.forEach((device) => {
            if (fireCodes.includes(device.code)) {
                fireDevices.push(device);
            } else if (fireAlarmCodes.includes(device.code)) {
                fireTypes.push(device);
            } else if (theftCodes.includes(device.code)) {
                theftDevices.push(device);
            } else if (burglarAlarmCodes.includes(device.code)) {
                burglarTypes.push(device);
            } else if (waterCodes.includes(device.code)) {
                waterDevices.push(device);
            }
        });
        setFireProtectiveDevices(fireDevices);
        setTheftProtectiveDevices(theftDevices);
        setWaterProtectiveDevices(waterDevices);

        setFireAlarmTypes(fireTypes);
        setBurglarAlarmTypes(burglarTypes);

        const selectedFireDevices = [];
        const selectedTheftDevices = [];
        let selectedWaterDevice = '';
        let selectedFireType = '';
        let selectedBurglarType = '';

        _.get(pdFormData, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value').forEach((device) => {
            if (fireCodes.includes(device.deviceType)) {
                selectedFireDevices.push(device.deviceType);
            } else if (fireAlarmCodes.includes(device.deviceType)) {
                selectedFireType = device.deviceType;
                selectedFireDevices.push('fireAlarm');
            } else if (theftCodes.includes(device.deviceType)) {
                selectedTheftDevices.push(device.deviceType);
            } else if (burglarAlarmCodes.includes(device.deviceType)) {
                selectedBurglarType = device.deviceType;
                selectedTheftDevices.push('burglarAlarm');
            } else if (waterCodes.includes(device.deviceType)) {
                selectedWaterDevice = device.deviceType;
            }
        });
        setShowFireDeviceFields(selectedFireDevices.length > 0);
        setShowTheftDeviceFields(selectedTheftDevices.length > 0);
        setShowWaterDeviceFields(selectedWaterDevice !== '');
        setSelectedFireProtectiveDevices(selectedFireDevices);
        setSelectedTheftProtectiveDevices(selectedTheftDevices);
        setSelectedWaterProtectiveDevice(selectedWaterDevice);
        setSelectedFireAlarmType(selectedFireType);
        setSelectedBurglarAlarmType(selectedBurglarType);
    }, [baseState, pdFormData, translator, viewModelService]);

    useEffect(
        () => {
            const clonedSubmissionVM = viewModelService.clone(submissionVM);

            updatePDFormData(clonedSubmissionVM);

            // E1PAP1PC-15872 - we were wiping out fixed ids; need fixed ids as it is
            const selectedProtectiveDevicesMap = _.get(
                pdFormData,
                'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value',
                []
            ).reduce((homeProtectionDeviceMap, homeProtectionDevice) => ({
                ...homeProtectionDeviceMap,
                [homeProtectionDevice.deviceType]: homeProtectionDevice.fixedId
            }), {});

            setAvailableProtectiveDevicesMap(selectedProtectiveDevicesMap);

            if (
                _.get(pdFormData, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value').length
            ) {
                const protectiveDevicesValues = clonedSubmissionVM.value.lobData.homeowners_EH.coverables.construction
                    .homeProtectionDevices.map((item) => item.deviceType);

                setSelectedProtectiveDevices(protectiveDevicesValues);
            }

            if (hasSecureHomeDisc) {
                setSecureHomeDevices();
                setShowTopError(true);
            } else if (hasProtectiveDevicesDisc) {
                setProtectiveDevices();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const homeProtectionDeviceAdd = useCallback((homeProtectionDevice) => {
        const {
            _xCenter,
            _dtoName,
        } = pdFormData.lobData.homeowners_EH.coverables.construction.homeProtectionDevices;
        const newPDFormData = pdFormData;
        const protectiveDeviceVM = viewModelService.create(homeProtectionDevice, _xCenter, _dtoName);

        if (newPDFormData.lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value === undefined) {
            newPDFormData.lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value = [];
        }

        newPDFormData.lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value.push(
            protectiveDeviceVM.value
        );
        updatePDFormData(newPDFormData);
    }, [pdFormData, viewModelService]);

    const handleValueChange = useCallback((value) => {
        _.set(
            pdFormData,
            'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value',
            []
        );

        for (let i = 0; i < value.length; i++) {
            const obj = {
                deviceType: value[i],
                fixedId: availableProtectiveDevicesMap[value[i]]
            };

            homeProtectionDeviceAdd(obj);
        }

        // edge case: when checkboxes are selected and then all are deslected, value is
        //   a an array with 1 element that is an empty array. Messes with the save validation.
        if (value.length === 1 && value[0].length === 0) {
            setSelectedProtectiveDevices([]);
        } else { setSelectedProtectiveDevices(value); }

        setErrorList([]);
    }, [availableProtectiveDevicesMap, homeProtectionDeviceAdd, pdFormData]);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = viewModelService.clone(pdFormData);

            _.set(nextFormData, path, value);
            updatePDFormData(nextFormData);
        },
        [pdFormData, viewModelService]
    );

    const saveAndNextHandler = useCallback(() => {
        // IAP-373 : ineligible protectiveDevices selection check
        const inEligibleDeviceSelectionErrors = []

        if (inEligibleProtectiveDeviceCombinationForBurglary.every(
            (deviceFromCombination) => selectedProtectiveDevices.includes(deviceFromCombination))
        ) {
            inEligibleDeviceSelectionErrors.push(
                { description: messages.centralBurglaryAndDirectLineToPoliceStationNotAllowed }
            );
        }

        if (inEligibleProtectiveDeviceCombinationForFire.every(
            (deviceFromCombination) => selectedProtectiveDevices.includes(deviceFromCombination))
        ) {
            inEligibleDeviceSelectionErrors.push(
                { description: messages.centralFireAlarnAndDirectLineToFireStationNotAllowed }
            );
        }

        if (inEligibleProtectiveDeviceCombinationForSprinkler.every(
            (deviceFromCombination) => selectedProtectiveDevices.includes(deviceFromCombination))
        ) {
            inEligibleDeviceSelectionErrors.push(
                { description: messages.automaticSprinklerLimitedAreasAndAutomaticSprinklerSystemsNotAllowed }
            );
        }

        if (!_.isEmpty(inEligibleDeviceSelectionErrors)) {
            setErrorList(inEligibleDeviceSelectionErrors);
            updateIsPageSubmitted(true);

            return false;
        }

        if (selectedProtectiveDevices.length === 0) {
            if (showTopError) {
                setErrorList([{ description: messages.selectAProtectiveDevice }]);
            }

            updateIsPageSubmitted(true);

            return false;
        }

        if (!isComponentValid) {
            setErrorList([{ description: messages.missingFields }]);
            updateIsPageSubmitted(true);

            return false;
        }

        setErrorList([]);

        const wrapperObj = {
            vm: pdFormData
        };

        onResolve(wrapperObj);
    }, [isComponentValid, onResolve, pdFormData, selectedProtectiveDevices, showTopError]);

    const onCancel = useCallback(
        () => {
            // sending submissionVM as it is so that changed values wont be saved
            const wrapperObj = {
                vm: submissionVM
            };

            onResolve(wrapperObj);
        }, [onResolve, submissionVM]
    );

    const resolvers = {
        resolveClassNameMap: styles
    };

    useEffect(() => {
        const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));
        const tempSingleValues = [];

        if (hasSecureHomeDisc) {
            if (selectedWaterProtectiveDevice) { tempSingleValues.push(selectedWaterProtectiveDevice); }

            if (selectedFireAlarmType) { tempSingleValues.push(selectedFireAlarmType); }

            if (selectedBurglarAlarmType) { tempSingleValues.push(selectedBurglarAlarmType); }

            handleValueChange(
                concat(
                    selectedFireProtectiveDevices.filter((n) => n !== 'fireAlarm'),
                    selectedTheftProtectiveDevices.filter((n) => n !== 'burglarAlarm'),
                    tempSingleValues
                )
            );
        }
    }, [
        handleValueChange, selectedBurglarAlarmType, selectedFireAlarmType, baseState, hasSecureHomeDisc,
        selectedFireProtectiveDevices, selectedTheftProtectiveDevices, selectedWaterProtectiveDevice
    ]);

    const overrideProps = {
        '@field': {
            readOnly: viewOnlyMode,
            showRequired: true,
            // If no checkboxes checked, don't show the
            //    "this is a required field" for the invisible data sharing field
            showErrors: isPageSubmitted && selectedProtectiveDevices.length !== 0,
            autoComplete: false
        },
        protectiveDevices: {
            visible: !hasSecureHomeDisc,
            availableValues: options,
            value: selectedProtectiveDevices,
            onValueChange: (value) => handleValueChange(value),
            showRequired: true,
            showErrors: isPageSubmitted
        },
        secureHomeContainer: {
            visible: hasSecureHomeDisc,
            className: dropdownActive ? 'minHeightDropdownSelected' : undefined
        },
        fireTypeID: {
            viewOnlyMode,
            deviceLabel: messages.fireProtective,
            protectiveDevicesAvailableValues: fireProtectiveDevices,
            selectedDevices: selectedFireProtectiveDevices,
            showFields: showFireDeviceFields,
            setShowFields: setShowFireDeviceFields,
            setSelectedDevices: setSelectedFireProtectiveDevices,
            alarmAvailableValues: fireAlarmTypes,
            selectedAlarmType: selectedFireAlarmType,
            setSelectedAlarmType: setSelectedFireAlarmType,
            showErrors: isPageSubmitted,
            onValidate,
            alarmLabel: messages.fireAlarmLabel,
            index: 1,
            setDropdownActive
        },
        theftTypeID: {
            viewOnlyMode,
            deviceLabel: messages.theftProtective,
            protectiveDevicesAvailableValues: theftProtectiveDevices,
            selectedDevices: selectedTheftProtectiveDevices,
            showFields: showTheftDeviceFields,
            setShowFields: setShowTheftDeviceFields,
            setSelectedDevices: setSelectedTheftProtectiveDevices,
            alarmAvailableValues: burglarAlarmTypes,
            selectedAlarmType: selectedBurglarAlarmType,
            setSelectedAlarmType: setSelectedBurglarAlarmType,
            showErrors: isPageSubmitted,
            onValidate,
            alarmLabel: messages.burglarAlarmLabel,
            index: 2,
            setDropdownActive
        },
        waterTypeID: {
            viewOnlyMode,
            deviceLabel: messages.waterProtective,
            singleSelect: true,
            protectiveDevicesAvailableValues: waterProtectiveDevices,
            selectedDevices: selectedWaterProtectiveDevice,
            showFields: showWaterDeviceFields,
            setShowFields: setShowWaterDeviceFields,
            setSelectedDevices: setSelectedWaterProtectiveDevice,
            showErrors: isPageSubmitted,
            onValidate,
            index: 3
        },
        datashareind: {
            visible: (() => {
                let connectedDeviceFound = false;

                _.get(pdFormData, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value').forEach((device) => {
                    if (connectedCodes.includes(device.deviceType)) {
                        connectedDeviceFound = true;
                    }
                });

                if (!connectedDeviceFound) {
                    _.set(pdFormData, 'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd', undefined);
                }

                return connectedDeviceFound;
            })(),
            value: _.get(pdFormData, 'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd.value')
                ? 'true' : 'false',
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={styles.transactionComparisonModal}>
            <ModalHeader title={translator(messages.protectiveDeviceModalHeader)} />
            <ModalBody>
                <div className={styles.errorComponentMarginBelow}>
                    <WizardSingleErrorComponent issuesList={errorList} />
                </div>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={pdFormData}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    {translator(viewOnlyMode ? commonMessages.close : messages.cancel)}
                </Button>
                <Button onClick={saveAndNextHandler} hidden={viewOnlyMode}>
                    {translator(messages.save)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

ProtectiveDevices.propTypes = {
    title: PropTypes.string,
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
            quoteID: PropTypes.shape({}),
            lobData: PropTypes.shape({
                homeowners_EH: PropTypes.shape({
                    coverables: PropTypes.shape({
                        construction: PropTypes.shape({
                            homeProtectionDevices: PropTypes.arrayOf(PropTypes.shape({
                            }))
                        })
                    })
                })
            })
        })
    }).isRequired,
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
        clone: PropTypes.func
    }),
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    viewOnlyMode: PropTypes.bool
};

ProtectiveDevices.defaultProps = {
    viewOnlyMode: false
};

export default withModalContext(ProtectiveDevices);
