import { defineMessages } from 'react-intl';

export default defineMessages({
    supportingSubHeader: {
        id: 'SupportingModal.If a supporting policy is not displayed, please add it manually. ',
        defaultMessage: 'If a supporting policy is not displayed, please add it manually. A promise policy must be issued within 30 days.'
    },
    checkForNewPolicies: {
        id: 'SupportingModal.CHECK FOR NEW POLICIES',
        defaultMessage: 'CHECK FOR NEW POLICIES'
    },
    checkingNewPolicies: {
        id: 'SupportingModal.Checking New Policies...',
        defaultMessage: 'Checking New Policies...'
    }
});
