import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class TPIService {
    /**
     * Searches TPI
     * @param {Object} TPISearchCriteriaDTO TPISearchCriteriaDTO
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static searchTPIs = (TPISearchCriteriaDTO, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('tpi'),
            'searchTPIs',
            [TPISearchCriteriaDTO],
            additionalHeaders
        );
    }

    /**
     * Retrieve TPI Details
     * @param {Object} tpiBillingDetailsLookupDTO tpiBillingDetailsLookupDTO
     * @param {Object} additionalHeaders additional headers to pass to the backend (e.g. auth)
     */

    static retrieveTPIDetails = (tpiBillingDetailsLookupDTO, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('tpi'),
            'retrieveTPIDetails',
            [tpiBillingDetailsLookupDTO],
            additionalHeaders
        );
    }
}
