/* eslint-disable camelcase */
import {
    useCallback,
    useState,
    useEffect
} from 'react';
import { getConfigValue, ConsoleHelper } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { get as _get } from 'lodash';

const PARTNER_EXPERIENCE_ID = '7119';
const BILLING_SYSTEM = 'BillingCenter';
const TPI_FULL_PAY = 'af:tpiFullPayR1';

/**
 * PURPOSE: This hook will call out and get the paymentus iframe token, on mount,
 *   and when the payment plan is switched.
 *
 * @param {String} amfamAccessToken
 * @param {String} productCode
 * @param {Object} submissionVM
 * @param {function} setValidationErrors useState to update errors
 * @param {Function} translator translator
 * @returns {Object} paymentusIFrameToken - token for ifram url param
        selectedPlan - selected payment plan,
        requestIFrameToken - on demand call to external api for another token,
        paymentusEndpoint - url for the ifram
 */
function usePaymentsUtil(
    amfamAccessToken,
    productCode,
    submissionVM,
    setValidationErrors,
    translator
) {
    const externalPaymentsEndpoint = getConfigValue('endpoints.externalPaymentsEndpoint');
    const paymentusEndpoint = getConfigValue('endpoints.paymentusEndpoint');
    const [paymentusIFrameToken, setPaymentusIFrameToken] = useState(undefined);
    const [downPayment, updateDownPayment] = useState(undefined);
    const { authHeader } = useAuthentication();

    const lobMap = {
        PersonalAuto_EA: submissionVM.lobData.personalAuto_EA,
        Homeowners_EH: submissionVM.lobData.homeowners_EH,
        PersonalUmbrella_EU: submissionVM.lobData.personalUmbrella_EU
    };
    const lobDataObject = lobMap[productCode];
    const autopaySelected = _get(lobDataObject, 'autoPayDiscInd.value');
    const selectedOfferings = _get(
        lobDataObject,
        'offerings.value'
    ).find((lobOffering) => {
        return _get(submissionVM, 'quoteData.offeredQuotes.value').find(
            (quoteOffering) => quoteOffering.selected
        ).branchCode === lobOffering.branchCode
    });

    const selectedPlan = _get(selectedOfferings, 'paymentPlans', []).find((plan) => plan.isSelected);

    useEffect(() => {
        const periodStatus = _get(submissionVM, 'baseData.periodStatus.value');
        let paymentAmount;

        // if status is not quoted or selected plan is tpi full pay - do not calculate down payment
        if (periodStatus.code === 'Quoted' && selectedPlan.billingId !== TPI_FULL_PAY) {
            const jobType = _get(submissionVM, 'baseData.value.jobType', 'Submission');
            let retrievePaymentPlans = LoadSaveService.retrievePaymentPlans;
            let params = [_get(submissionVM, 'value.quoteID'), _get(submissionVM, 'value.sessionUUID')];

            if (jobType === 'Rewrite') {
                retrievePaymentPlans = RewriteService.retrievePaymentPlans;
                params = [_get(submissionVM, 'value.jobID')];
            }

            retrievePaymentPlans(...params, authHeader)
                .then((response) => {
                    const selectedPaymentPlan = response.find((paymentPlan) => paymentPlan.isSelected);

                    if (selectedPaymentPlan) {
                        paymentAmount = _get(selectedPaymentPlan, 'downPayment.amount');

                        if (!paymentAmount || paymentAmount === 0) {
                            paymentAmount = undefined;
                        }

                        updateDownPayment(paymentAmount);
                    }
                })
                .catch(() => {
                    const { modalApi } = _get(window, '__giamfam.modalApi');

                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: e1pCommonMessages.downPaymentCouldNotBeCalculatedTitle,
                        message: e1pCommonMessages.downPaymentCouldNotBeCalculatedMessage
                    });
                })
        }
        
    }, [authHeader, selectedPlan, submissionVM]);

    const getRequestBody = useCallback(() => {
        const transactionId = _get(submissionVM, 'quoteID.value', submissionVM.jobID?.value);
        const policyNumber = _get(submissionVM.value, 'policyNumber');
        let emailAddress = lobDataObject.primaryNamedInsured.person.emailAddress1.value;
        // IAP-5529, use paperless email if set
        
        if(lobDataObject.paperlessInd.value && lobDataObject.paperlessEmail.value) {
            emailAddress = lobDataObject.paperlessEmail.value;
        }

        return {
            transactionId: transactionId,
            payment: {
                partnerExperienceId: PARTNER_EXPERIENCE_ID,
                producerCode: _get(submissionVM.value, 'baseData.producerCode'),
                accounts: [
                    {
                        accountNumber: lobDataObject.billingAccountNumber.value,
                        specifiedPolicy: policyNumber,
                        expectedAmount: downPayment,
                        designation: 'Downpay',
                        billingSystem: BILLING_SYSTEM,
                        uwCompany: submissionVM.baseData.uwcompanyCode_Ext.value?.code
                    }
                ],
                payor: {
                    firstName: lobDataObject.primaryNamedInsured.person.firstName.value,
                    lastName: lobDataObject.primaryNamedInsured.person.lastName.value,
                    phoneNumber: lobDataObject.primaryNamedInsured.person.homeNumber.value,
                    emailAddress
                },
                totalPaymentAmount: downPayment
            }
        };
    }, [downPayment,
        lobDataObject.billingAccountNumber.value,
        lobDataObject.primaryNamedInsured.person.emailAddress1.value,
        lobDataObject.primaryNamedInsured.person.firstName.value,
        lobDataObject.primaryNamedInsured.person.homeNumber.value,
        lobDataObject.primaryNamedInsured.person.lastName.value,
        lobDataObject.paperlessInd.value,
        lobDataObject.paperlessEmail.value,
        submissionVM
    ]);

    const requestIFrameToken = useCallback(() => {

        // When no active enterprise session exists then display session Expiration message
        // to user and dont make call to paymentus
        if (!amfamAccessToken) {
            const errors = [{
                level: 'LEVEL_ERROR',
                description: translator(e1pCommonMessages.oktaSessionExpirationMessage)
            }];

            setValidationErrors(errors);
            ConsoleHelper('Paymentus INFO: No enterprise okta token found', 'ERROR');

            return;
        }

        const body = getRequestBody();

        if (_get(lobDataObject, 'autoPayDiscInd.value')) {
            body.payment.accounts[0].autoPayEnrollment = 'AUTHORIZED';
        }

        // eslint-disable-next-line consistent-return
        return fetch(externalPaymentsEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'AFI-AppName': 'ProducerPortal',
                Authorization: amfamAccessToken
            },
            body: JSON.stringify(body)
            // redirect: 'follow'
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                // To set errors for Bad requests that are caught in catch
                if (_get(data, 'status.code', undefined) !== 200) {
                    const errors = [{
                        level: 'LEVEL_ERROR',
                        description: translator(e1pCommonMessages.paymentusServiceError)
                    }];

                    setValidationErrors(errors);
                }

                setPaymentusIFrameToken(data.authToken);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                // When call to paymentus fail with an exception display generic error message to user
                const errors = [{
                    level: 'LEVEL_ERROR',
                    description: translator(e1pCommonMessages.paymentusServiceError)
                }];

                setValidationErrors(errors);
                console.error('Paymentus Error:', error);
                setPaymentusIFrameToken(undefined);
            });
    },
        [amfamAccessToken, externalPaymentsEndpoint, getRequestBody, lobDataObject, setValidationErrors, translator]);

    useEffect(() => {
        setPaymentusIFrameToken(undefined);

        if (downPayment && selectedOfferings?.paymentPlans) {
            requestIFrameToken();
        }
        // Only run in case of down payment update
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downPayment, autopaySelected]);

    return {
        paymentusIFrameToken,
        selectedPlan,
        requestIFrameToken,
        paymentusEndpoint
    };
}

export default usePaymentsUtil;