import { defineMessages } from 'react-intl';

export default defineMessages({
    accountTitle: {
        id: 'e1p.gateway.views.landing.Account:',
        defaultMessage: 'Account: '
    },
    policyNavigation: {
        id: 'e1p.gateway.navigation.landing.Policy:',
        defaultMessage: 'Policy: '
    },
    quote: {
        id: 'e1p.gateway.views.quote-detail.Quote',
        defaultMessage: 'Quote'
    },
    policyChange: {
        id: 'e1p.gateway.views.endorsement-detail.Policy Change',
        defaultMessage: 'Policy Change'
    },
    reinstatement: {
        id: 'e1p.gateway.views.endorsement-detail.Reinstatement',
        defaultMessage: 'Reinstatement'
    },
    renewal: {
        id: 'e1p.portal.components.transactionWizardTitle.transactionWizardTitle.Renewal',
        defaultMessage: 'Renewal'
    }
});
