import SignatureService from '../services/SignatureService';

const getSignatureRoles = (quoteID, signature, authHeader) => {
    return SignatureService.getSignatureRoles(quoteID, signature, authHeader);
};

const signPolicy = async (signature, quoteID, authHeader, recipients, licensedProducerID, pniOnlineSignatureName) => {
    if (['Wet', 'AgencySignature', 'MailAndSign', 'OnlineSign'].includes(signature)) {
        const signatureRoles = {};
        const signatureRequestDTO = {
            quoteID: quoteID,
            signatureType: signature,
            signatureRecipients: signatureRoles,
            licensedProducerID: licensedProducerID,
            pniOnlineSignatureName: pniOnlineSignatureName
        };
        const signatureResponseDTO = await SignatureService
            .signPolicy(signatureRequestDTO, authHeader);
        return signatureResponseDTO;
    }
    const hashSet = [...new Set(recipients)];
    const signatureRequestDTO = {
        quoteID: quoteID,
        signatureType: signature,
        signRecipients: hashSet,
        callBackURL: (() => {
            const BASE_PATH = '/producer-engage';
            const { origin } = window.location;
            return `${origin}${BASE_PATH}/esignconfirmation`;
        })(),
        licensedProducerID: licensedProducerID
    };
    const signatureResponseDTO = await SignatureService.signPolicy(signatureRequestDTO, authHeader);
    return signatureResponseDTO;
};

export default {
    signPolicy: signPolicy,
    getSignatureRoles: getSignatureRoles
};
