import React, {
    useContext, useCallback, useEffect, useRef, useState,
} from 'react';
import {
    get, set, isUndefined, isEmpty
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useOOSConflictPageLandingUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import messages from './EUVehicleOperatorPage.messages';
import metadata from './EUVehicleOperatorPage.metadata.json5';

function EUVehicleOperatorPage(props) {
    const {
        wizardData: rewriteVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        currentStepIndex,
        changeNextSteps,
        updateWizardSnapshot
    } = props;
    const stepsRef = useRef(steps);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        onValidate, initialValidation,
        isComponentValid, registerComponentValidation
    } = useValidation('EUVehicleOperatorPage');
    const [isSavingRewrite, setIsSavingRewrite] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);

    useEffect(() => {
        /**
         * Using useRef to access current updated steps.
         * as we are adding new conflicts step and landing user on this newly created step
         * "step" state variable from props does not give us updated value inside useOOSConflictPageLandingUtil
         * it refers initial rendered value only(as we are adding new step and want to land user
         * on new step in)
         */
        stepsRef.current = steps;
    }, [steps]);

    const {
        removeOrAddAndLandOnConflictsPage
    } = useOOSConflictPageLandingUtil(
        stepsRef,
        currentStepIndex,
        changeNextSteps,
        jumpTo
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        if (isUndefined(rewriteVM.lobData.personalUmbrella_EU.vehicleOperators.value)) {
            set(rewriteVM.lobData.personalUmbrella_EU.vehicleOperators, 'value', []);
        }

        updateWizardData(rewriteVM);
        registerComponentValidation(() => rewriteVM.lobData.personalUmbrella_EU.vehicleOperators.aspects.subtreeValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const vehicleOperatorElement = document.getElementById(`firstName${rewriteVM.lobData.personalUmbrella_EU.vehicleOperators.length - 1}`);

        if (vehicleOperatorElement
            && isEmpty(vehicleOperatorElement.value)
            && vehicleOperatorElement.focus !== undefined) {
            vehicleOperatorElement.focus();
        }
    }, [rewriteVM.lobData.personalUmbrella_EU.vehicleOperators.length]);


    // this grabs the latest element on the page --
    // this is to make sure this element has been loaded
    const latestVehicleOperatorElement = document.getElementById(
        `euVehicleOperatorComponentDiv${rewriteVM.lobData.personalUmbrella_EU.vehicleOperators.children.length - 1}`
    );

    useEffect(() => {
        // indexStale set in the add vehicle operator function
        // once latest element is loaded and a new vehicle operator is added
        // we check if the button should be visible
        if (latestVehicleOperatorElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestVehicleOperatorElement]);

    const addVehicleOperator = useCallback(
        () => {
            const {
                _xCenter,
                _dtoName
            } = rewriteVM.lobData.personalUmbrella_EU.vehicleOperators;
            const newVehicleOperatorVM = viewModelService.create({}, _xCenter, _dtoName);

            rewriteVM.lobData.personalUmbrella_EU.vehicleOperators.pushElement(
                newVehicleOperatorVM
            );
            updateWizardData(rewriteVM);
            setCheckScrolling();
            setIndexStale(true);
        },
        [viewModelService, updateWizardData, rewriteVM]
    );

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        vehicleOperatorPageLoadingIndicator: {
            loaded: !isSavingRewrite && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        vehicleOperatorPageContainer: {
            visible: !isSavingRewrite && !isSkipping && !isSavingCurrentPageChanges
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleOperatorsContainer')
        },
        EUVehicleOperatorGrid: {
            key: get(rewriteVM, 'lobData.personalUmbrella_EU.vehicleOperators', []).length,
            transactionVM: rewriteVM,
            drivers: get(rewriteVM, 'lobData.personalUmbrella_EU.vehicleOperators', []),
            path: 'lobData.personalUmbrella_EU.vehicleOperators.children',
            onValidate,
            showErrors: isPageSubmitted,
            setCheckScrolling,
            updateWizardData
        },
    };

    /**
     * Helper callback for writing values to the view model.
     */
    const writeValue = useCallback(
        (newVal, path) => {
            set(rewriteVM, `${path}.value`, newVal);
            updateWizardData(rewriteVM);
        },
        [rewriteVM, updateWizardData]
    );

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            addVehicleOperator,
            onValidate
        }
    };

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRewrite(true);

            const newRewriteVM = viewModelService.clone(rewriteVM);
            const quoteData = get(newRewriteVM, 'quoteData.value');
            const response = await RewriteService.saveAndQuote(
                [rewriteVM.value],
                authHeader
            );
            const respQuoteData = get(response, 'quoteData');

            if (respQuoteData === undefined) {
                set(response, 'quoteData', quoteData);
            }

            set(newRewriteVM, 'value', response);
            updateWizardData(newRewriteVM);

            if (!calledFromOnSave) {
                /**
                 E1PAP1PC-13853 :
                 If we get conflicts in saveAndQuote, we will add conflicts page
                 and user will land on conflicts page if its not present.
                 If we had conflicts and we came back and made changes such that after
                 saveAndQuote if there are no conflicts we will remove conflicts page if its present
                 */
                const hasConflicts = !isEmpty(get(rewriteVM, 'value.conflicts', []));

                removeOrAddAndLandOnConflictsPage(hasConflicts);

                if (hasConflicts) {
                    setIsSavingRewrite(false);

                    return false;
                }
            }

            setIsSavingRewrite(false);

            return newRewriteVM;
        },
        [
            authHeader, isComponentValid, rewriteVM, updateWizardData,
            viewModelService, removeOrAddAndLandOnConflictsPage
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                const onNextResponse = await onNext(undefined, true);

                if(onNextResponse){
                    const fieldIssues = get(onNextResponse, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                    const exceptions = get(onNextResponse, 'baseData.exceptions_Ext.value', []);

                    if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                        updateWizardSnapshot(onNextResponse);
                    }
                }
              
                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, updateWizardSnapshot]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

EUVehicleOperatorPage.propTypes = wizardProps;
export default EUVehicleOperatorPage;
