import React, {
    useCallback,
} from 'react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm,  } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ehCommonMessages } from 'e1p-platform-translations';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from '../PropertyPage.metadata.json5';
import styles from '../PropertyPage.module.scss';


function PropertyPage(props) {
    const translator = useTranslator();
    const {
        wizardData: renewalVM, updateWizardData
    } = props;


    const {
        onValidate
    } = useValidation('PropertyPage');


    const onNext = useCallback(
        async () => renewalVM,
        [renewalVM]
    );


    const resolvers = {
        resolveCallbackMap: {
            recalculateReplacementCostClick: () => { },
            onValidate,
        },
        resolveClassNameMap: styles
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        propertyPageLoader: {
            loaded: true,
            loadingMessage: translator(ehCommonMessages.loadingPrefill)
        },
        replacementCostRecalculate: {
            visible: false
        },
        replacementCostMissingNotificationDiv: {
            visible: false
        },
        noOutstandingChangesNotificationDiv: {
            visible: false
        },
        replacementCostWithDollorrid: {
            content: (() => [
                    {
                        id: 'replacementCost',
                        component: 'Currency',
                        type: 'field',
                        componentProps: {
                            readOnly: true,
                            className: 'replacementCost',
                            path: 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount',
                            label: {
                                id: '',
                                defaultMessage: ''
                            },
                            layout: 'reversed',
                            dataType: 'number'
                        }
                    }
                ])()
        },
        e1pEHHF9PropertyDetailsComponent: {
            transactionVM: renewalVM,
            updateWizardData: () => {},
            isPageSubmitted: false,
            heatingSystemType: () => {},
            updateHeatingSystemType: () => {},
            coolingSystemType: () => {},
            updateCoolingSystemType: () => {},
            setIsReplacementCostStale: false,
            creditScoreMoreThan597: false,
            viewOnlyMode: true,
        },
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
