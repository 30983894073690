import { useCallback, useEffect, useState } from 'react';

import { get as _get, set as _set } from 'lodash';

/**
 * @param {Object} transactionVM
 * @param {Object} principalDriverData
 * @param {function} setPrincipalDriverData
 *  @param {function} updateWizardData
 * @returns {*}
 */
function useDriverAssignmentPageUtil(transactionVM, principalDriverData, setPrincipalDriverData, updateWizardData) {
    const [showOccupationColumn, setShowOccupationColumn] = useState(false);

    const shouldOccupationCellShow = useCallback((rowOfData) => {
        // if  vehicle assigned is garaged out of state and more than 6 months 
        // set occupation show field true 
        const vehicles = _get(transactionVM, 'lobData.personalAuto_EA.coverables.vehicles.value');
        const selectedVehicle = vehicles?.find((vehicle) => {
            return vehicle.fixedId === rowOfData.vehicle.fixedId
        });
    
        const isVehicleGaragedOutOfState = selectedVehicle?.garagingLocation.state !== _get(transactionVM, 'baseData.policyAddress.state.value.code')
            && selectedVehicle?.garagedOutOfStateGreaterThan6Months;
        return isVehicleGaragedOutOfState;
    }, [transactionVM]); 

    useEffect(() => {
        const foundVehicleGaragedOutofState = principalDriverData?.find((dataRow) => shouldOccupationCellShow(dataRow));
        setShowOccupationColumn(foundVehicleGaragedOutofState !== undefined);
    }, [principalDriverData, shouldOccupationCellShow, setShowOccupationColumn]);

    const onOccupationChangeCallback = useCallback(
        (value, id, index) => {
            const newData = [...principalDriverData];
            _set(newData[index], 'occupation', value);
            const selectedDriver = transactionVM.lobData.personalAuto_EA.coverables.drivers.children.find((driver) => { return driver.fixedId.value === newData[index].driver.fixedId });
            _set(selectedDriver, 'isStudentInd', value === 'Student');
            _set(selectedDriver, 'isMilitaryInd', value === 'activeMilitary');
            setPrincipalDriverData(newData);
            updateWizardData(transactionVM);
        },
        [principalDriverData, transactionVM, updateWizardData, setPrincipalDriverData]
    );

    const checkOccupation = useCallback((assignmentData) => {
        const selectedVehicle = transactionVM.lobData.personalAuto_EA.coverables.vehicles.value.find((vehicle) => {
            return vehicle.fixedId === assignmentData.vehicle.fixedId
        });

        // policy view does not have base data
        const policyState = _get(transactionVM,'baseData.policyAddress.state.value.code', _get(transactionVM,'policyAddress.state.value.code'));
        const isVehicleGaragedOutOfState = selectedVehicle && selectedVehicle.garagingLocation.state !== policyState
            && selectedVehicle.garagedOutOfStateGreaterThan6Months;
        if (isVehicleGaragedOutOfState) {
            const selectedDriver = transactionVM.lobData.personalAuto_EA.coverables.drivers.children.find((driver) => {
                return driver.fixedId?.value === assignmentData.driver.fixedId
            });

            const isStudent = _get(selectedDriver, 'isStudentInd.value');
            const isMilitary = _get(selectedDriver, 'isMilitaryInd.value');
            if (isStudent) {
                _set(assignmentData, 'occupation', 'Student');
            } else if (isMilitary) {
                _set(assignmentData, 'occupation', 'activeMilitary');
            } else if(isStudent === false && isMilitary === false){
                _set(assignmentData, 'occupation', 'Other');
            }
        }
        return assignmentData;
    }, [transactionVM])

    return {
        shouldOccupationCellShow,
        showOccupationColumn,
        onOccupationChangeCallback,
        checkOccupation
    };
}

export default useDriverAssignmentPageUtil;
