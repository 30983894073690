import { useCallback } from 'react';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { AttributeConfigService } from 'e1p-capability-config';
import { useE1PAttributeConfigStateContext } from 'e1p-capability-gateway-react';
import appConfig from 'app-config';
import moment from 'moment';

const getConfigRequest = (transactionVM) => {
    const lobName = appConfig.lobNameMap[_.get(transactionVM, 'baseData.productCode.value', _.get(transactionVM, 'productCode.value'))];
    // for change transaction policy type field is under changeDataDTO
    const policyType = _.get(transactionVM, `lobData.${lobName}.value.policyType`, _.get(transactionVM, 'value.policyType'));
    const configRequest = {
        policyLine: appConfig.policyLineMap[policyType],
        policyType: policyType,
        experienceID: _.get(transactionVM, 'baseData.experienceID_Ext.value', _.get(transactionVM, 'experienceID_Ext.value')),
        partnerID: _.get(transactionVM, `baseData.value.partnerCode_Ext`),
        state: _.get(transactionVM, 'baseData.policyAddress.value.state', _.get(transactionVM, 'policyAddress.value.state')),
        ratingDate: _.get(transactionVM, 'baseData.ratingDate.value', _.get(transactionVM, 'ratingDate.value'))
    }
    // Once we have experienceIds setup, will get rid of this partnerID hardCoding
    // defaulting because in few scenarios we are not getting partnerID from PC
    if (!configRequest.partnerID) {
        configRequest.partnerID = '529';
    }
    return configRequest;
};

/**
 * IAP-3680 : risk role attribute changes
 * Util for getting config data for field
 * @param {Object} transactionVM 
 * @returns {*}
 */
function useAttributeConfigUtil(transactionVM) {
    const { authHeader } = useAuthentication();
    const { attributeConfig, setAtributeConfig } = useE1PAttributeConfigStateContext();
    const configRequest = getConfigRequest(transactionVM);

    /**
     * Helper function to check if new configRequest is similar to existing config metadata
     * @param {Object} attributeConfigMetadata 
     * @returns {Boolean} 
     */
    const canUseExistingConfigData = useCallback((attributeConfigMetadata) => {
        if (!_.isEqual(configRequest.state, attributeConfigMetadata.state)) {
            return false;
        }
        if (!_.isEqual(configRequest.policyLine, attributeConfigMetadata.policyLine)) {
            return false;
        }
        if (!_.isEqual(configRequest.policyType, attributeConfigMetadata.policyType)) {
            return false;
        }
        if (!_.isEqual(configRequest.partnerID, attributeConfigMetadata.partnerID)) {
            return false;
        }
        // can be used once we have experience id returning for each transaction for each opco
        // if (!_.isEqual(configRequest.experienceID, attributeConfigMetadata.experienceID)) {
        //     return false;
        // }
        // this what they are comparing in backend
        if (moment(attributeConfigMetadata.prodEffectiveDate).isAfter(configRequest.ratingDate)) {
            return false;
        }

        return true;

    }, [
        configRequest.partnerID,
        configRequest.policyLine, configRequest.policyType,
        configRequest.ratingDate, configRequest.state
    ])

    /**
     * Helper function to get config metadata
     * It check first if we need to call api to get config data again
     * if not we returns existing config data otherwise 
     * we call api to get config data and store it in context
     * 
     */
    const getAttributeconfig = useCallback(() => {

        if (attributeConfig && canUseExistingConfigData(attributeConfig.configMetadata)) {
            return Promise.resolve(attributeConfig);
        }
        return AttributeConfigService.retrieveConfig(configRequest, authHeader)
            .then((configResponse) => {
                const updatedResponse = configResponse;
                _.set(updatedResponse, 'configMetadata.partnerID', configRequest.partnerID);
                _.set(updatedResponse, 'configMetadata.experienceID', configRequest.experienceID);
                setAtributeConfig(configResponse);
                return configResponse;
            });

    }, [attributeConfig, authHeader, canUseExistingConfigData, configRequest, setAtributeConfig]);

    return {
        getAttributeconfig
    }

}

export default useAttributeConfigUtil;