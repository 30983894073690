import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import PCEASingleClauseComponentVM from './PCEASingleClauseComponentVM';
import messages from './PCEAClauseComponentVM.messages';

function getPath(path, changedValuePath) {
    // onBlur event returns an object instead of path as a String
    const pathToNormalise = _.isObject(changedValuePath)
        ? changedValuePath.model : changedValuePath;

    const normalisePath = pathToNormalise.replace(/\[/g, '.children[');
    const basePath = path.replace(/\.value/, '');
    return `${basePath}${normalisePath}`;
}

export default class PCEAClausesComponentVM extends Component {
    /**
     * @memberof gw-components-platform-react.PCEAClausesComponentVM
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {clausesArray} propTypes.value - is the clause is seleceted
     * @prop {bool} propTypes.splitByClauseCategory - if clauses should be split by coverageCategory
     * @prop {string} propTypes.categoryDisplayName - title if splitByClauseCategory is false
     * @prop {string} propTypes.isEditable - if the clauses should not be editable
     */

    static propTypes = {
        ...PCEASingleClauseComponentVM.propTypes,
        path: PropTypes.string.isRequired,
        value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        splitByClauseCategory: PropTypes.bool,
        labelPosition: PropTypes.string,
        isEditable: PropTypes.bool,
        loadingClauseMessage: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        })
    };

    static defaultProps = {
        splitByClauseCategory: false,
        labelPosition: 'top',
        isEditable: true,
        loadingClauseMessage: messages.updatingSelection
    };

    static contextType = TranslatorContext;

    toMetadata = (clausesCategory) => {
        const {
            value: clauses,
            path,
            labelPosition,
            loadingClauseMessage,
            mergedList,
            category,
            loadingClause,
            getMaxNumberOfTermsForACoverage,
            vehicleArray,
            travelPackage,
            isCompOnlyVehicle
        } = this.props;

        // Altering the height of the cell to display loader whenever updating the coverage
        const isLoading = _.isString(loadingClause);
        let singleClauseComponentClassName;
        if (!travelPackage && isLoading) {
            singleClauseComponentClassName = 'pceaClauseContainer';
        } else if (!travelPackage && !isLoading) { // to set height of last child
            singleClauseComponentClassName = 'pceaClauseContainer clauseLastChildHeight';
        } else if (travelPackage) {
            singleClauseComponentClassName = undefined;
        }
        const translator = this.context;
        const upgradePackages = ['EA_PlatinumCoverageBundle', 'EA_GoldCoverageBundle'];
        const policyCoverageBundles = ['EA_SplitLimitCoverageBundle', 'EA_CombinedSingleLimitCoverageBundle'];
        let clausesArray = clausesCategory;
        if (category === 'Vehicle') {
            clausesArray = mergedList;
        }
        return clausesArray.map((clause) => {
            if (!travelPackage && upgradePackages.includes(clause.publicID)) {
                // Don't include gold and platinum with set of clauses
                return undefined;
            } if (travelPackage && !upgradePackages.includes(clause.publicID)) {
                // Do include gold and platinum but nothing else
                return undefined;
            }
            if (policyCoverageBundles.includes(clause.publicID)) {
                // Do not include Split / CSL limit
                return undefined;
            }
            const index = clauses.findIndex((element) => element.publicID === clause.publicID);
            const clausePath = `[${index}]`;
            let realClause = { ...clause };
            let numberOfTerms;
            let threeLineLabel = false;
            let twoThreeLineLabel = false;
            if (category === 'Line') {
                const termDetails = getMaxNumberOfTermsForACoverage(category, vehicleArray, clause);
                numberOfTerms = termDetails.numberOfTerms;
                twoThreeLineLabel = termDetails.twoThreeLineLabel;
            }
            if (category === 'Vehicle') {
                realClause = clausesCategory
                    .find((element) => element.publicID === clause.publicID);
                const termDetails = getMaxNumberOfTermsForACoverage(category, vehicleArray, clause);
                numberOfTerms = termDetails.numberOfTerms;
                threeLineLabel = termDetails.threeLineLabel;
                twoThreeLineLabel = termDetails.twoThreeLineLabel;
            }
            let compOnly = isCompOnlyVehicle;
            if (clause.codeIdentifier === 'EA_Comprehensive') {
                // even if compOnly is selected for the vehicle
                // we need to show comprehensive cov on UI
                compOnly = false;
            }
            // Vehicle does not have this coverage so we will render "not applicable"
            //  Done through the noCoverage prop
            const vehicleHasCoverage = !!clausesCategory
                .find((elt) => elt.publicID === clause.publicID);
            if (!vehicleHasCoverage) {
                return {
                    id: `clause_${clause.publicID}_[${index}]`,
                    type: 'field',
                    component: 'PCEASingleClauseComponentVM',
                    componentProps: {
                        ...this.props,
                        labelPosition,
                        loadingClauseMessage: translator(loadingClauseMessage),
                        value: clause,
                        path: getPath(path, clausePath),
                        containerClassName: isLoading ? 'pceaClauseContainer' : 'pceaClauseContainer clauseLastChildHeight',
                        noCoverage: true,
                        numberOfTerms,
                        isCompOnlyVehicle: compOnly,
                        threeLineLabel: threeLineLabel,
                        twoThreeLineLabel: twoThreeLineLabel
                    }
                };
            }

            return {
                id: `clause_${clause.publicID}_[${index}]`,
                type: 'field',
                component: 'PCEASingleClauseComponentVM',
                componentProps: {
                    ...this.props,
                    labelPosition,
                    loadingClauseMessage: translator(loadingClauseMessage),
                    value: realClause,
                    path: getPath(path, clausePath),
                    containerClassName: singleClauseComponentClassName,
                    noCoverage: false,
                    loadingClause,
                    numberOfTerms,
                    isCompOnlyVehicle: compOnly,
                    threeLineLabel: threeLineLabel,
                    twoThreeLineLabel: twoThreeLineLabel
                }
            };
        }).filter((comp) => comp !== undefined);
    };

    generateClauseGroup = (clauseCategory, index) => {
        const clausesMetadata = this.toMetadata(clauseCategory);

        return {
            id: `clause_category_container_[${index}]`,
            type: 'container',
            component: 'div',
            componentProps: { className: 'clauseCategoryContainer' },
            content: clausesMetadata
        };
    };

    generateReadOnlyMetadata = () => {
        const { value: clauses } = this.props;
        const clausesToRender = clauses
            .filter((clause) => clause.selected);

        if (_.isEmpty(clausesToRender)) {
            return [{
                id: 'no_clause',
                type: 'element',
                component: 'h4',
                content: messages.noCoverage,
                componentProps: {
                    className: 'gw-no-coverages'
                }
            }];
        }
        return this.toMetadata(clausesToRender);
    }

    generateEditableMetadata() {
        const { value: clauses, splitByClauseCategory } = this.props;

        if (splitByClauseCategory) {
            // coverageCategoryCode is used in all types of clauses
            const clauseCategory = _.groupBy(clauses, 'coverageCategoryCode');
            const clauseCategoryMetadata = Object.values(clauseCategory)
                .map(this.generateClauseGroup);

            return {
                id: 'clause_container',
                type: 'container',
                component: 'div',
                content: clauseCategoryMetadata
            };
        }
        return this.generateClauseGroup(clauses);
    }

    generateMetadata() {
        const { isEditable } = this.props;

        if (!isEditable) {
            return {
                id: 'clause_container',
                type: 'container',
                component: 'div',
                content: this.generateReadOnlyMetadata()
            };
        }
        return this.generateEditableMetadata();
    }

    render() {
        return <MetadataContent uiProps={this.generateMetadata()} />;
    }
}
