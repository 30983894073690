import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextPageMessage: {
        id: 'renewal.eu.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    policyType: {
        id: 'renewal.eu.Underlying Policy.Policy Type',
        defaultMessage: 'Policy Type'
    },
    carrierName: {
        id: 'renewal.eu.Underlying Policy.Insurer',
        defaultMessage: 'Insurer'
    },
    ULPolicyDetails: {
        id: 'renewal.eu.Underlying Policy.UL Policy Details',
        defaultMessage: 'UL Policy Details'
    },
    policyNumber: {
        id: 'renewal.eu.Underlying Policy.Policy Number',
        defaultMessage: 'Policy Number'
    },
    delete: {
        id: 'renewal.eu.Underlying Policy.Delete',
        defaultMessage: 'Delete'
    },
    removeUnderlyingPolicy: {
        id: 'renewal.eu.Underlying Policy.Remove?',
        defaultMessage: 'Remove Underlying Policy?'
    },
    removeEAVehicleExposure: {
        id: 'renewal.eu.Underlying Policy.Remove EA Vehicle Exposure?',
        defaultMessage: 'Remove Vehicle Exposure?'
    },
    removeAdditionalPremise: {
        id: 'renewal.eu.Underlying Policy.Remove Additional Premise',
        defaultMessage: 'Remove Additional Premise?'
    },
    removeAddress: {
        id: 'renewal.eu.Underlying Policy.Remove Address',
        defaultMessage: 'Remove Address?'
    },
    confirmDelete: {
        id: 'renewal.eu.Underlying Policy.Confirm Delete',
        defaultMessage: 'Please Confirm Delete.'
    },
    cancel: {
        id: 'renewal.eu.Underlying Policy.Cancel',
        defaultMessage: 'Cancel'
    },
    compOnlyVehicle: {
        id: 'renewal.eu.Underlying Policy.Comp Only',
        defaultMessage: 'Comp Only'
    },
    vehicleModel: {
        id: 'renewal.eu.Underlying Policy.Model',
        defaultMessage: 'Model'
    },
    vehicleMake: {
        id: 'renewal.eu.Underlying Policy.Make',
        defaultMessage: 'Make'
    },
    vehicleYear: {
        id: 'renewal.eu.Underlying Policy.Year',
        defaultMessage: 'Year'
    },
    isAntique: {
        id: 'renewal.eu.Underlying Policy.Antique/Classic',
        defaultMessage: 'Antique/Classic'
    },
    grossWeightOverThreshold: {
        id: 'renewal.eu.Underlying Policy.GWVR Greater than 15,000 lbs',
        defaultMessage: 'GWVR Greater than 15,000 lbs'
    },
    isPassengerVehicle: {
        id: 'renewal.eu.Underlying Policy.Is Vehicle a 12+ Passenger Van',
        defaultMessage: 'Is Vehicle a 12+ Passenger Van'
    },
    companyFurnished: {
        id: 'renewal.eu.Underlying Policy.Company Furnished Vehicle',
        defaultMessage: 'Company Furnished Vehicle'
    },
    horsepower: {
        id: 'renewal.eu.Underlying Policy.Horsepower',
        defaultMessage: 'Horsepower'
    },
    licensedForRoadUse: {
        id: 'renewal.eu.Underlying Policy.Licensed for Road Use',
        defaultMessage: 'Licensed for Road Use'
    },
    HIN: {
        id: 'renewal.eu.Underlying Policy.HIN',
        defaultMessage: 'HIN/Serial Number'
    },
    watercraftType: {
        id: 'renewal.eu.Underlying Policy.Watercraft Type',
        defaultMessage: 'Watercraft Type'
    },
    boatLength: {
        id: 'renewal.eu.Underlying Policy.Length (ft)',
        defaultMessage: 'Length (ft)'
    },
    addWatercraft: {
        id: 'renewal.eu.Underlying Policy.addWatercraft',
        defaultMessage: 'Add Watercraft'
    },
    addRecreationVehicle: {
        id: 'renewal.eu.Underlying Policy.addRecreationVehicle',
        defaultMessage: 'Add Recreational Vehicle'
    },
    addVehicle: {
        id: 'renewal.eu.Underlying Policy.addVehicle',
        defaultMessage: 'Add Vehicle'
    },
    allUnderlyingPolicisAdded: {
        id: 'renewal.eu.Underlying Policy.All Underlying Policies Added',
        defaultMessage: 'All Underlying Policies Added'
    }
});
