import React, { useEffect, useState } from 'react';
import { get as _get } from 'lodash';
import { UnderWritingIssuesComponent } from 'e1p-capability-policyjob-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import { SubmissionService } from 'gw-capability-gateway';
import metadata from './EAUWIssuesComponent.metadata.json5';
import styles from './EAUWIssuesComponent.module.scss';
import messages from './EAUWIssuesComponent.messages';

function EAUWIssuesComponent(props) {
    const modalApi = useModal();
    const {
        onValidate,
    } = useValidation('EAUWIssuesComponent');
    const { submissionVM, updateWizardData, authHeader } = props;
    const quoteID = _get(submissionVM, 'quoteID.value');
    const [underwritingIssues, setunderwritingIssues] = useState([]);

    const getUWIssues = (async () => {
        try {
            const getALLUWIssueData = await SubmissionService
                .getUWIssuesForSubmission([submissionVM.quoteID.value], authHeader);

            setunderwritingIssues(getALLUWIssueData);

            // getQuoteSummaryCount();
        } catch (e) {
            return modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: messages.modalError,
                message: messages.errorLoadingUWIssuestitle
            });
        }
    });

    useEffect(() => {
        const UWIssues = _get(submissionVM, 'value.errorsAndWarnings.underwritingIssues', {});

        getUWIssues(UWIssues);

        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showUWIssues = true;

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        uwIssuesID: {
            underwritingIssues,
            setunderwritingIssues,
            blockQuote: false, // findBlockingPoint(),
            quoteID,
            visible: showUWIssues
        }
    };

    const resolvers = {
        resolveComponentMap: { underwritingIssuesComponent: UnderWritingIssuesComponent },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            // referToUnderwriter
        }
    };

    const modelToSend = {
        uwIssues: underwritingIssues
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={modelToSend}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={onValidate}
            componentMap={resolvers.resolveComponentMap}
            resolveClassNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

EAUWIssuesComponent.propTypes = {
    submissionVM: PropTypes.shape({
        quoteID: PropTypes.shape({ value: PropTypes.shape({}) })
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    authHeader: PropTypes.shape({}).isRequired,
};
export default EAUWIssuesComponent;
