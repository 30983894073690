import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
    filter as _filter,
    find as _find,
    get as _get,
    includes as _includes,
    remove as _remove,
    set as _set,
    isEmpty as _isEmpty,
    trim as _trim
} from 'lodash';
import { withModalContext, TooltipIcon } from '@jutro/components';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { TPIService } from 'e1p-capability-gateway';
import { TPIUtil } from 'e1p-portals-util-js';
import { e1pUSStatesUtil } from 'e1p-capability-hooks';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RadioButtonField } from '@jutro/legacy/components';
import messages from './E1PTPISearchComponent.messages';
import metadata from './E1PTPISearchComponent.metadata.json5';


/**
 * This component file models the popup which appears when the "Add Third-Party Interest" button is clicked.
 *
 * @param {Object} props An object containing the properties passed into this component.
 * @returns {Object} An object containing the data required for rendering this component.
 */
function E1PTPISearchComponent(props) {
    const {
        productCode,
        viewModelService,
        authHeader,
        transactionVM,
        tpiBasePath,
        existingContact,
        isPerson,
        isAddingExistingContact,
        updateIsSearchTPIVisible,
        saveThirdPartyInterestClickHandler,
        showErrors,
        onValidate,
        disregardFieldValidationParentPage,
        setIsAddingTPI
    } = props;
    const { authUserData } = useAuthentication();
    const translator = useTranslator();
    const [isPerformingSearch, setIsPerformingSearch] = useState(false);
    const [isPerformingRetrieve, setIsPerformingRetrieve] = useState(false);
    const [isClickedOnSearch, setIsClickedOnSearch] = useState(false);
    const [searchResultData, updateSearchResultData] = useState([]);
    const [errorWarningMessage, updateErrorWarningMessage] = useState('');
    const [showErrorWarning, updateShowErrorWarning] = useState(false);
    const [tpiDetailVM, setTPIDetailVM] = useState(undefined);
    const [selectedAddlInterestTypeCode, setSelectedAddlInterestTypeCode] = useState(undefined);
    const [selectedAddlInsuredTypeCode, setSelectedAddlInsuredTypeCode] = useState(undefined);
    const [isTPISearchResultControlled, setIsTPISearchResultControlled] = useState(false);
    const [isControlledTPI, setIsControlledTPI] = useState(false);
    const [isTPIContactTypeCompany, setIsTPIContactTypeCompany] = useState(false);
    const [isTPITypeMortgagee, setIsTPITypeMortgagee] = useState(false);
    const [isTPITypeTrust, setIsTPITypeTrust] = useState(false);
    const [USStates, setUSStates] = useState([]);
    const [toolTipDivFirstChild, setToolTipDivFirstChild] = useState(undefined);
    const [isTPIFromSearchResult, updateIsTPIFromSearchResult] = useState(false);
    const [tpiNumberSearchInput, updateTPINumberSearchInput] = useState(undefined);
    const [isATPIFromSearchResultSelected, setIsATPIFromSearchResultSelected] = useState(false);
    // const [preferredTPIContactVM, setPreferredTPIContactVM] = useState(undefined);
    // const preferredTPIContactRef = useRef(undefined);

    /**
     * The "isAddingNewTPI" state is true whenever a user clicks on the "Add New TPI" hyperlink.
     * This happens whenever the user needs to explicitly create a new TPI that doesn't exist in
     * the Pragma or PolicyCenter databases.
     */
    const [isAddingNewTPI, setIsAddingNewTPI] = useState(false);
    const ADDL_INTEREST_TYPE_TL = viewModelService.productMetadata
        .get('pc')
        .types.getTypelist('AdditionalInterestType');
    const ADDL_INSURED_TYPE_TL = viewModelService.productMetadata
        .get('pc')
        .types.getTypelist('EUAdditionalInsuredType_Ext');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const productToLineMap = new Map([
        ['PersonalAuto_EA', 'EAAutoLine_Ext'],
        ['Homeowners_EH', 'EHLine_Ext'],
        ['PersonalUmbrella_EU', 'EULine_Ext']
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const addlInterestTypesForPerson = ['AdditionalInsured_Ext', 'AdditionalInterest_Ext',
        'Titleholder_Ext', 'AdditionalDesignee_Ext'];

    /**
     * Helper callback for creating a new TPI Search Criteria VMNode object.
     */
    const createNewTPISearchCriteriaVM = useCallback(
        (latestAddlInsuredTypeCodeValue, latestAddlInterestTypeCodeValue) => {
            const tpiSearchCriteriaObj = {
                searchCriteria: 'startsWith', // Can only be "startsWith" or "includes"
                maxSearch: '100', // max number of records returned by TPI service
                line: productToLineMap.get(productCode),
                accountID: _get(transactionVM, 'baseData.accountNumber.value'),
                organizationName: '',
                firstName: '',
                lastName: '',
                city: '',
                state: '',
                addlInterestType: latestAddlInterestTypeCodeValue
            };
            const newTPISearchCriteriaVM = viewModelService.create(
                tpiSearchCriteriaObj,
                'pc',
                'amfam.edge.capabilities.gateway.tpi.dto.TPISearchCriteriaDTO'
            );

            // for EU, contactSubtype will always be Company, also we are not showing the type field on the UI and
            // for EA and EH, we are showing the field based on below condition, hence setting the field to undefined.
            // so that user would select the value on UI.
            if (['PersonalAuto_EA', 'Homeowners_EH'].includes(productCode)
                && ['AdditionalInsured_Ext', 'AdditionalInterest_Ext', 'Titleholder_Ext'].includes(latestAddlInterestTypeCodeValue)) {
                _set(newTPISearchCriteriaVM, 'value.contactSubtype', undefined);
            } else {
                _set(newTPISearchCriteriaVM, 'value.contactSubtype', 'Company');
            }

            return newTPISearchCriteriaVM;
        },
        [productToLineMap, productCode, transactionVM, viewModelService]
    );

    const [tpiSearchCriteriaVM, setTPISearchCriteriaVM] = useState(
        createNewTPISearchCriteriaVM(undefined)
    );

    useEffect(() => {
        const stateValues = e1pUSStatesUtil.getUSStates(viewModelService);

        setUSStates(stateValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for filtering the "AdditionalInterestType" typelist values based on whether
     * they're already in use on the policy.
     */
    const filterAddlInterestTypeCodes = useCallback(
        (initialAddlInterestTypeCodes) => {
            const allExistingTPIsOnPolicy = _get(transactionVM, `${tpiBasePath}.value`, []);
            const alreadyUsedAddlInterestTypeCodes = allExistingTPIsOnPolicy.map((item) => item.addlInterestType);
            const filteredAddlInterestTypeCodes = _filter(
                initialAddlInterestTypeCodes,
                (typeCode) => {
                    const isTypeCodeAvailable = alreadyUsedAddlInterestTypeCodes.indexOf(typeCode.code) === -1;
                    const canTypeCodeHaveMultipleOccurrences = !typeCode.code.includes('MORTGAGEE');

                    return isTypeCodeAvailable || canTypeCodeHaveMultipleOccurrences;
                }
            );

            return filteredAddlInterestTypeCodes;
        },
        [transactionVM, tpiBasePath]
    );

    /**
     * Helper callback for retrieving the "AdditionalInterestType" typelist values for EA.
     */
    const getAddlInterestTypeCodesForEA = useCallback(() => {
        const addlInterestTypeCodes = [];
        let filteredCodes = [];
        const statesWithAdditionalDesignee = ['CA', 'NJ', 'NY', 'CT']
        const statesWithLessor = ['CT']
        const policyStateCode = _get(transactionVM, 'baseData.policyAddress.state.value.code');
        const codes = _find(ADDL_INTEREST_TYPE_TL.filters, {
            name: `EATPIFilter_${policyStateCode}_Ext`
        });

        // IAP-3387: typefilter for CT does not include necessary typecodes 
        if (codes && policyStateCode !== 'CT') {
            filteredCodes = codes.codes;
            filteredCodes.forEach((typeCode) => {
                addlInterestTypeCodes.push(
                    _find(ADDL_INTEREST_TYPE_TL.codes, {
                        code: typeCode.code
                    })
                );
            });
        } else {
            filteredCodes = _find(ADDL_INTEREST_TYPE_TL.filters, {
                name: 'EAControlledTPIFilter_Ext'
            }).codes;
            filteredCodes.forEach((typeCode) => {
                if ((typeCode.code !== 'LESSOR' || statesWithLessor.includes(policyStateCode)) && typeCode.code !== 'LOSSP') {
                    addlInterestTypeCodes.push(
                        _find(ADDL_INTEREST_TYPE_TL.codes, {
                            code: typeCode.code
                        })
                    );
                }
            });
        }

        if (!statesWithAdditionalDesignee.includes(policyStateCode)) {
            _remove(addlInterestTypeCodes, (typeCode) => typeCode.code === 'AdditionalDesignee_Ext');
        }

        const availableValues = addlInterestTypeCodes.map((typeCode) => ({
            code: typeCode.code,
            name: translator({ id: typeCode.name })
        }));

        return availableValues;
    }, [ADDL_INTEREST_TYPE_TL.codes, ADDL_INTEREST_TYPE_TL.filters, transactionVM, translator]);

    /**
     * Helper callback for retrieving the "AdditionalInterestType" typelist values for EH.
     */
    const getAddlInterestTypeCodesForEH = useCallback(() => {
        const addlInterestTypeCodes = [];

        addlInterestTypeCodes.push(
            _find(ADDL_INTEREST_TYPE_TL.codes, {
                code: 'AdditionalInterest_Ext'
            })
        );

        const policyTypeCode = _get(transactionVM, 'lobData.homeowners_EH.policyType.value.code')
            ?? _get(transactionVM, 'value.policyType'); // For policy change transactions

        if (policyTypeCode === 'HO3' || policyTypeCode === 'HO6' || policyTypeCode === 'HF9') {
            addlInterestTypeCodes.push(
                _find(ADDL_INTEREST_TYPE_TL.codes, {
                    code: 'AdditionalInsured_Ext'
                })
            );

            const filteredCodes = _find(ADDL_INTEREST_TYPE_TL.filters, {
                name: 'EHControlledTPIFilter_Ext'
            }).codes;

            filteredCodes.forEach((typeCode) => {
                addlInterestTypeCodes.push(
                    _find(ADDL_INTEREST_TYPE_TL.codes, {
                        code: typeCode.code
                    })
                );
            });

            // Homeowner and secondary/seasonal can add trust
            if (['HO3', 'HF9'].includes(policyTypeCode)) {
                addlInterestTypeCodes.push(
                    _find(ADDL_INTEREST_TYPE_TL.codes, {
                        code: 'TRUST_Ext'
                    })
                );
            }
        }

        if (isAddingExistingContact) {
            const filterredCodes = TPIUtil.filterInterestTypesForGivenUser(
                addlInterestTypeCodes,
                transactionVM,
                tpiBasePath,
                existingContact.publicID
            );

            // update addlInterestTypeCodes with interestCodes which are present for given user
            addlInterestTypeCodes.splice(0, addlInterestTypeCodes.length);
            addlInterestTypeCodes.push(...filterredCodes);
        }

        const availableValues = addlInterestTypeCodes.map((typeCode) => ({
            code: typeCode.code,
            name: translator({ id: typeCode.name })
        }));

        return availableValues;
    }, [
        ADDL_INTEREST_TYPE_TL.codes,
        ADDL_INTEREST_TYPE_TL.filters,
        transactionVM,
        tpiBasePath,
        existingContact,
        translator,
        isAddingExistingContact
    ]);

    /**
     * Helper callback for retrieving the "AdditionalInterestType" typelist values for EU.
     */
    const getAddlInterestTypeCodesForEU = useCallback(() => {
        const addlInterestTypeCodes = [];

        addlInterestTypeCodes.push(
            _find(ADDL_INTEREST_TYPE_TL.codes, {
                code: 'AdditionalInsured_Ext'
            })
        );

        const policyStateCode = _get(transactionVM, 'baseData.policyAddress.state.value.code');

        if (policyStateCode === 'NJ') {
            addlInterestTypeCodes.push(
                _find(ADDL_INTEREST_TYPE_TL.codes, {
                    code: 'AdditionalDesignee_Ext'
                })
            );
        }

        if (isAddingExistingContact) {
            const filterredCodes = TPIUtil.filterInterestTypesForGivenUser(
                addlInterestTypeCodes,
                transactionVM,
                tpiBasePath,
                existingContact.publicID
            );

            // update addlInterestTypeCodes with interestCodes which are present for given user
            addlInterestTypeCodes.splice(0, addlInterestTypeCodes.length);
            addlInterestTypeCodes.push(...filterredCodes);
        }

        const availableValues = addlInterestTypeCodes.map((typeCode) => ({
            code: typeCode.code,
            name: translator({ id: typeCode.name })
        }));

        return availableValues;
    }, [
        ADDL_INTEREST_TYPE_TL.codes,
        existingContact,
        isAddingExistingContact,
        tpiBasePath,
        transactionVM,
        translator
    ]);

    /**
     * Helper memo for retrieving the most appropriate "AdditionalInterestType" typelist values.
     */
    const getAddlInterestTypeCodes = useMemo(() => {
        let addlInterestTypeCodes = [];

        if (productCode === 'PersonalAuto_EA') {
            addlInterestTypeCodes = getAddlInterestTypeCodesForEA();
        } else if (productCode === 'Homeowners_EH') {
            addlInterestTypeCodes = getAddlInterestTypeCodesForEH();
        } else if (productCode === 'PersonalUmbrella_EU') {
            addlInterestTypeCodes = getAddlInterestTypeCodesForEU();
        }

        addlInterestTypeCodes = filterAddlInterestTypeCodes(addlInterestTypeCodes);

        return addlInterestTypeCodes;
    }, [
        productCode,
        filterAddlInterestTypeCodes,
        getAddlInterestTypeCodesForEA,
        getAddlInterestTypeCodesForEH,
        getAddlInterestTypeCodesForEU
    ]);

    const getAddlInterestTypeCodesExistingContact = useMemo(() => {
        let addlInterestTypeCodes = [];

        if (productCode === 'PersonalAuto_EA') {
            addlInterestTypeCodes = getAddlInterestTypeCodesForEA();
        } else if (productCode === 'Homeowners_EH') {
            addlInterestTypeCodes = getAddlInterestTypeCodesForEH();
        } else if (productCode === 'PersonalUmbrella_EU') {
            addlInterestTypeCodes = getAddlInterestTypeCodesForEU();
        }

        addlInterestTypeCodes = filterAddlInterestTypeCodes(addlInterestTypeCodes);

        if (isPerson) {
            addlInterestTypeCodes = addlInterestTypeCodes
                .filter((addlInterestTypeCode) => addlInterestTypesForPerson.includes(addlInterestTypeCode.code));
        }

        return addlInterestTypeCodes;
    }, [
        isPerson,
        addlInterestTypesForPerson,
        productCode,
        filterAddlInterestTypeCodes,
        getAddlInterestTypeCodesForEA,
        getAddlInterestTypeCodesForEH,
        getAddlInterestTypeCodesForEU
    ]);

    /**
     * Helper memo for retrieving the most appropriate "AdditionalInterestType" typelist values.
     */
    const getAddlInsuredTypeCodes = useMemo(() => {
        let availableValues = [];

        if (productCode === 'PersonalUmbrella_EU') {
            const addlInsuredTypeCodes = [...ADDL_INSURED_TYPE_TL.codes];

            availableValues = addlInsuredTypeCodes.map((typeCode) => ({
                code: typeCode.code,
                name: translator({ id: typeCode.name })
            }));
        }

        return availableValues;
    }, [ADDL_INSURED_TYPE_TL.codes, productCode, translator]);

    /**
     * Helper callback for handling value changes on the Third Party Search modal form.
     */
    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = viewModelService.clone(tpiSearchCriteriaVM);

            _set(nextFormData, path, value);

            if (path === 'contactSubtype') {
                if (value === 'Company') {
                    _set(nextFormData, 'firstName.value', '');
                    _set(nextFormData, 'lastName.value', '');
                } else if (value === 'Person') {
                    _set(nextFormData, 'organizationName.value', '');
                }
            }

            setTPISearchCriteriaVM(nextFormData);
        },
        [tpiSearchCriteriaVM, viewModelService]
    );

    const updateAndShowWarning = useCallback((textToDisplay) => {
        // if text to display is empty then hide error
        updateShowErrorWarning(!_isEmpty(textToDisplay));
        updateErrorWarningMessage(textToDisplay);
    }, []);

    /**
     * Helper callback for handling changes to the "Contact Subtype" drop-down field.
     */
    const handleContactSubtypeValueChange = useCallback(
        (value) => {
            setIsAddingNewTPI(false);

            const nextFormData = viewModelService.clone(tpiSearchCriteriaVM);

            _set(nextFormData, 'contactSubtype.value', value);
            setTPISearchCriteriaVM(nextFormData);
            setTPIDetailVM(undefined);
        },
        [tpiSearchCriteriaVM, viewModelService]
    );


    const resetSearchCriteria = useCallback(
        (latestAddlInsuredTypeCodeValue, latestAddlInterestTypeCodeValue) => {
            const newTPISearchCriteriaVM = createNewTPISearchCriteriaVM(
                latestAddlInsuredTypeCodeValue, latestAddlInterestTypeCodeValue
            );

            setTPISearchCriteriaVM(newTPISearchCriteriaVM);
            updateSearchResultData([]);
            setTPIDetailVM(undefined);
            setIsClickedOnSearch(false);

            const policyStateCode = _get(transactionVM, 'baseData.policyAddress.state.value.code');

            if (selectedAddlInterestTypeCode === "AdditionalDesignee_Ext" && policyStateCode === 'CT') {
                handleContactSubtypeValueChange('Person');
            }
        },
        [createNewTPISearchCriteriaVM, handleContactSubtypeValueChange, selectedAddlInterestTypeCode, transactionVM]
    );

    const resetSearchButtonOnClickHandler = useCallback(() => {
        resetSearchCriteria(undefined);
        setIsClickedOnSearch(false);
        setIsAddingNewTPI(false);
        updateTPINumberSearchInput(undefined);
        setIsATPIFromSearchResultSelected(false);
    }, [resetSearchCriteria]);

    const searchButtonOnClickHandler = useCallback(async () => {
        setIsAddingNewTPI(false);
        setIsPerformingSearch(true);
        setIsClickedOnSearch(true);
        // call search api
        updateSearchResultData([]);
        setTPIDetailVM(undefined);
        updateShowErrorWarning(false);

        // IAP-1354 : TPI - Search by TPI Number
        if (!_isEmpty(_trim(tpiNumberSearchInput))) {
            const tpiBillingDetailsLookupDTO = {
                line: productToLineMap.get(productCode),
                tpiId: tpiNumberSearchInput
            };

            TPIService.retrieveTPIDetails(tpiBillingDetailsLookupDTO, authHeader)
                .then((response) => {
                    const searchedData = [{ ...response, addedAsTPI: false }];

                    updateSearchResultData(searchedData);
                    setIsTPISearchResultControlled(true)

                    return response;
                })
                .catch(() => {
                    updateAndShowWarning(translator(messages.tpiNumberSearchError));
                })
                .finally(() => {
                    setIsPerformingSearch(false);
                });

            return true;
        }

        TPIService.searchTPIs(tpiSearchCriteriaVM.value, authHeader)
            .then((response) => {
                if (response.errorMessage !== undefined) {
                    // E1PAP1PC-13530 : we are getting this error message from service; overwriting it with useful message
                    if (response.errorMessage === messages.tooManyResultsErrorOldMessage?.defaultMessage) {
                        _set(response, 'errorMessage', messages.tooManyResultsError);
                    }

                    updateAndShowWarning(response.errorMessage);
                }

                // E1PAP1PC-13530 : to show radio button to select tpi to add; using addedAsTPI dummy field
                const searchedData = response.matchingResults?.map((matchingResult) => ({ ...matchingResult, addedAsTPI: false }));

                // Commenting all preferred code as we don't have test data
                // if (!_isEmpty(response.matchingResults)) {
                //     const preferredTPI = response.matchingResults.find((result) => result.preferred);
                //     // we need this object structure in method createTPITemplateObjFromSearchResult
                //     _set(preferredTPI,'rawResponseForRecord', preferredTPI);
                //     preferredTPIContactRef.current = preferredTPI;
                // }
                updateSearchResultData(searchedData);
                setIsTPISearchResultControlled(false);

                if (
                    response.matchingResults[0] !== undefined
                    && response.matchingResults[0].tpiid !== undefined
                ) {
                    setIsTPISearchResultControlled(true);
                }

                return response;
            })
            .catch((error) => {
                // If calling the search service fails we will only have "error.baseError"
                const baseErrorMessage = _get(error, 'baseError', undefined);
                const specificErrorMessage = _get(baseErrorMessage, 'error.message', undefined);
                const errorMessageToDisplay = specificErrorMessage
                    ? `${specificErrorMessage}. Please refine the Search Criteria`
                    : baseErrorMessage;

                updateAndShowWarning(errorMessageToDisplay);
            })
            .finally(() => {
                setIsPerformingSearch(false);
            });
    }, [
        authHeader, productCode, productToLineMap, tpiNumberSearchInput,
        tpiSearchCriteriaVM.value, updateAndShowWarning, translator
    ]);

    const formatSearchResult = useCallback(() => {
        if (searchResultData.length > 0) {

            const formattedResults = searchResultData.sort((tpiA, tpiB) => {

                const tpiAPreferred = tpiA.preferred;
                const tpiBPreferred = tpiB.preferred;
                const tpiAEscrow = tpiA.escrow;
                const tpiBEscrow = tpiB.escrow;

                if (tpiAPreferred === tpiBPreferred) {
                    if (tpiAEscrow < tpiBEscrow) { return 1 }

                    if (tpiAEscrow > tpiBEscrow) { return -1 }

                    return 0
                }

                return tpiAPreferred < tpiBPreferred ? 1 : -1;

            }).map((tpi) => {
                if (tpi.tpiid === undefined) {
                    // uncontrolled tpi record
                    const formatObj = {
                        name: tpi.displayName,
                        address: tpi.displayAddress,
                        preferred: '',
                        escrow: '',
                        tpiid: '',
                        comments: '',
                        alias: '',
                        rawResponseForRecord: tpi,
                        addedAsTPI: tpi.addedAsTPI,
                        publicID: tpi.publicID
                    };

                    return formatObj;
                }

                return {
                    name: TPIUtil.getFormattedTPIBankName(tpi, true),
                    address: TPIUtil.getFormattedTPIAddress(tpi),
                    preferred: tpi.preferred ? 'true' : 'false',
                    escrow: tpi.escrow ? 'true' : 'false',
                    tpiid: tpi.tpiid,
                    comments: tpi.comments === 'null' ? '' : tpi.comments,
                    alias: tpi.alias === 'null' ? '' : tpi.alias,
                    rawResponseForRecord: tpi,
                    addedAsTPI: tpi.addedAsTPI
                };
            });

            return formattedResults;
        }

        return [];
    }, [searchResultData]);

    /**
     * Helper memo for determining whether the "Insured Type" drop-down field is visible.
     */
    const addlInsuredTypeDropdownVisible = useMemo(() => (
        productCode === 'PersonalUmbrella_EU'
        && selectedAddlInterestTypeCode === 'AdditionalInsured_Ext'
    ), [productCode, selectedAddlInterestTypeCode]);

    /**
     * Helper callback for creating a template TPI object using the selected TPI search result.
     */
    const createTPITemplateObjFromSearchResult = useCallback(
        (selectedRow) => {
            let addlInterestTemplateObj = {};

            setIsAddingNewTPI(false);

            if (selectedRow.rawResponseForRecord.tpiid !== undefined) {
                // This block is for controlled TPIs
                addlInterestTemplateObj = {
                    comments: selectedRow.rawResponseForRecord.comments,
                    escrowInd: selectedRow.rawResponseForRecord.escrow,
                    preferredInd: selectedRow.rawResponseForRecord.preferred,
                    tpiid: selectedRow.rawResponseForRecord.tpiid,
                    bankPrimaryName: selectedRow.rawResponseForRecord.bank.name1,
                    bankSecondaryName: selectedRow.rawResponseForRecord.bank.name2,
                    bankTertiaryName: selectedRow.rawResponseForRecord.bank.name3,
                    address: selectedRow.rawResponseForRecord.address,
                    addlInterestType: selectedAddlInterestTypeCode,
                    loanNumber: undefined,
                    sendBillToTPIInd: false
                };
                setIsControlledTPI(true);
                setIsTPIContactTypeCompany(true); // always true for controlled TPI
            } else {
                // This block is for uncontrolled TPIs
                setIsControlledTPI(false);

                const primaryAddress = {
                    displayName: selectedRow.rawResponseForRecord.displayAddress,
                    addressType: 'home',
                    addressLine1: selectedRow.rawResponseForRecord.addressLine1,
                    city: selectedRow.rawResponseForRecord.city,
                    state: selectedRow.rawResponseForRecord.state,
                    country: selectedRow.rawResponseForRecord.country,
                    postalCode: selectedRow.rawResponseForRecord.postalCode
                };

                addlInterestTemplateObj = {
                    addlInterestType: selectedAddlInterestTypeCode,
                    trustees: [],
                    address: {
                        ...primaryAddress,
                        address1: primaryAddress.addressLine1
                    }
                };

                // Setting Additional Insured Type, when creating tpi from existing contact
                if (addlInsuredTypeDropdownVisible) {
                    addlInterestTemplateObj.additionalInsuredType = selectedAddlInsuredTypeCode;
                }

                if (
                    selectedRow.rawResponseForRecord.tpiid === undefined
                    && selectedRow.rawResponseForRecord.contactSubtype === 'Person'
                ) {
                    const formattedDOB = moment(selectedRow.rawResponseForRecord.dateOfBirth);
                    const personObj = {
                        firstName: selectedRow.rawResponseForRecord.firstName,
                        lastName: selectedRow.rawResponseForRecord.lastName,
                        displayName: selectedRow.rawResponseForRecord.displayName,
                        dateOfBirth: {
                            year: formattedDOB.year(),
                            month: formattedDOB.month(),
                            day: formattedDOB.date(),
                            isodate_Ext: selectedRow.rawResponseForRecord.dateOfBirth
                        },
                        publicID: selectedRow.rawResponseForRecord.publicID
                    };

                    addlInterestTemplateObj.person = personObj;
                    addlInterestTemplateObj.person.primaryAddress = primaryAddress;
                    setIsTPIContactTypeCompany(false);
                }

                if (
                    selectedRow.rawResponseForRecord.tpiid === undefined
                    && selectedRow.rawResponseForRecord.contactSubtype === 'Company'
                ) {
                    const companyObj = {
                        name: selectedRow.rawResponseForRecord.displayName,
                        displayName: selectedRow.rawResponseForRecord.displayName,
                        publicID: selectedRow.rawResponseForRecord.publicID
                    };

                    addlInterestTemplateObj.company = companyObj;
                    addlInterestTemplateObj.company.primaryAddress = primaryAddress;
                    setIsTPIContactTypeCompany(true);
                }
            }

            return addlInterestTemplateObj;
        },
        [addlInsuredTypeDropdownVisible, selectedAddlInsuredTypeCode, selectedAddlInterestTypeCode]
    );

    /**
     * Helper callback for creating a new additional interest template object. This object will be
     * used to initialize the appropriate TPI detail component.
     */
    const createTPITemplateObjFromScratch = useCallback(() => {
        const addlInterestTemplateObj = {
            addlInterestType: selectedAddlInterestTypeCode,
            deceased: false,
            trustees: []
        };
        const defaultAddressData = {
            addressType: 'home',
            country: 'US',
            city: _get(tpiSearchCriteriaVM, 'city.value'),
            state: _get(tpiSearchCriteriaVM, 'state.value')
        };
        const contactSubtype = _get(tpiSearchCriteriaVM, 'contactSubtype.value.code');

        if (contactSubtype === 'Company') {
            addlInterestTemplateObj.company = {
                name: _get(tpiSearchCriteriaVM, 'organizationName.value')
            };
            addlInterestTemplateObj.company.primaryAddress = defaultAddressData;
            setIsTPIContactTypeCompany(true);
        } else if (contactSubtype === 'Person') {
            addlInterestTemplateObj.person = {
                firstName: _get(tpiSearchCriteriaVM, 'firstName.value'),
                lastName: _get(tpiSearchCriteriaVM, 'lastName.value')
            };
            addlInterestTemplateObj.person.primaryAddress = defaultAddressData;
            setIsTPIContactTypeCompany(false);
        }

        addlInterestTemplateObj.address = defaultAddressData;

        return addlInterestTemplateObj;
    }, [selectedAddlInterestTypeCode, tpiSearchCriteriaVM]);

    const createTPITemplateObjFromExistingContact = useCallback(() => {
        const addlInterestTemplateObj = {
            addlInterestType: selectedAddlInterestTypeCode,
            deceased: false,
            trustees: [],
            address: {
                ..._get(existingContact, 'primaryAddress'),
                address1: _get(existingContact, 'primaryAddress.addressLine1'),
                address2: _get(existingContact, 'primaryAddress.addressLine2', ''),
                publicID: undefined
            }
        };

        if (!isPerson) {
            addlInterestTemplateObj.company = existingContact;
            setIsTPIContactTypeCompany(true);
        } else {
            addlInterestTemplateObj.person = existingContact;
            setIsTPIContactTypeCompany(false);
        }

        return addlInterestTemplateObj;
    }, [existingContact, isPerson, selectedAddlInterestTypeCode]);

    /**
     * Helper callback for creating a new "AddlInterestDTO" that is appropriate for the given LOB.
     */
    const createNewAddlInterestDTO = useCallback(
        (addlInterestTemplateObj) => {
            let newTPIDetailVM = {};

            if (productCode === 'PersonalAuto_EA') {
                const policyStateCode = _get(transactionVM, 'baseData.policyAddress.state.value.code');

                if (addlInterestTemplateObj.addlInterestType === "AdditionalDesignee_Ext" && policyStateCode === 'CT') {
                    newTPIDetailVM = viewModelService.create(
                        addlInterestTemplateObj,
                        'pc',
                        'amfam.edge.capabilities.policyjob.lob.ea.dto.EAAutoLineAddlInterestDTO'
                    );
                } else {
                    newTPIDetailVM = viewModelService.create(
                        addlInterestTemplateObj,
                        'pc',
                        'amfam.edge.capabilities.policyjob.lob.ea.coverables.dto.EAVehicleAddlInterestDTO'
                    );
                }
            } else if (productCode === 'Homeowners_EH') {
                newTPIDetailVM = viewModelService.create(
                    addlInterestTemplateObj,
                    'pc',
                    'amfam.edge.capabilities.policyjob.lob.eh.coverables.dto.EHDwellingAddlInterestDTO'
                );
            } else if (productCode === 'PersonalUmbrella_EU') {
                newTPIDetailVM = viewModelService.create(
                    addlInterestTemplateObj,
                    'pc',
                    'amfam.edge.capabilities.policyjob.lob.eu.dto.EULineAddlInterestDTO'
                );
            }

            return newTPIDetailVM;
        },
        [productCode, transactionVM, viewModelService]
    );

    const retrieveTPIDetails = useCallback(async (tpiDetails) => {
        updateAndShowWarning();
        setIsPerformingRetrieve(true);

        // call  retrieve tpi details vm
        const tpiBillingDetailsLookupDTO = {
            line: productToLineMap.get(productCode),
            tpiId: tpiDetails.value.tpiid
        };

        TPIService.retrieveTPIDetails(tpiBillingDetailsLookupDTO, authHeader)
            .then((response) => {
                _set(tpiDetails, 'value.billingAccountNumber', response?.billingAccountNumber);
            })
            .catch((error) => {
                // If calling the retrieve service fails we will only have "error.baseError"
                const baseErrorMessage = _get(error, 'baseError', undefined);
                const specificErrorMessage = _get(baseErrorMessage, 'error.message', undefined);
                const errorMessageToDisplay = specificErrorMessage
                    ? `${specificErrorMessage}. Error Occurred in retrieveTPIDetails Call`
                    : baseErrorMessage;

                updateAndShowWarning(errorMessageToDisplay);
            })
            .finally(() => {
                setIsPerformingRetrieve(false);
            });
    }, [authHeader, productCode, productToLineMap, updateAndShowWarning]);

    /**
     * Helper callback for handling when the "Add" button is clicked.
     */
    const handleAddTPIButtonClick = useCallback(
        async (selectedRow) => {
            searchResultData.forEach((searchRecord) => {
                _set(searchRecord, 'addedAsTPI', false);

                if (selectedRow.tpiid && selectedRow.tpiid === searchRecord.tpiid) {
                    _set(searchRecord, 'addedAsTPI', true);
                } else if ((selectedRow.publicID && selectedRow.publicID === searchRecord.publicID)) {
                    _set(searchRecord, 'addedAsTPI', true);
                }
            });

            updateSearchResultData(searchResultData);
            setTPIDetailVM(undefined);

            const addlInterestTemplateObj = createTPITemplateObjFromSearchResult(selectedRow);
            const newTPIDetailVM = createNewAddlInterestDTO(addlInterestTemplateObj);

            if (newTPIDetailVM.value.tpiid
                && newTPIDetailVM.value.escrowInd
                && productCode === 'Homeowners_EH') {
                // retrieveTPIDetails and update billing account number for controlled tpi and for EH and EA
                await retrieveTPIDetails(newTPIDetailVM);
            }
            // // if preferred is present and not selected get bankAccountNumber for preferred tpi as well
            // if(!!preferredTPIContactRef.current && selectedRow.preferred !== "true"){
            //     const preferredAddlInterestTemplateObj = createTPITemplateObjFromSearchResult(preferredTPIContactRef.current);
            //     const preferredTPIDetailVM = createNewAddlInterestDTO(preferredAddlInterestTemplateObj);
            //     if (preferredTPIDetailVM.value.tpiid
            //         && preferredTPIDetailVM.value.escrowInd
            //         && productCode === 'Homeowners_EH') {
            //         // retrieveTPIDetails and update billing account number for controlled tpi and for EH and EA
            //         await retrieveTPIDetails(preferredTPIDetailVM);
            //     }
            //     setPreferredTPIContactVM(preferredTPIDetailVM);
            // }

            setTPIDetailVM(newTPIDetailVM);
            updateIsTPIFromSearchResult(true);
            setIsATPIFromSearchResultSelected(true);
        },
        [
            createNewAddlInterestDTO, createTPITemplateObjFromSearchResult,
            productCode, retrieveTPIDetails, searchResultData, updateIsTPIFromSearchResult
        ]
    );

    /**
     * Helper callback for handling when the "Add a New Third-Party Interest" link is clicked.
     */
    const handleAddNewTPIButtonClick = useCallback(
        (event) => {
            updateAndShowWarning();
            event.preventDefault();
            setTPIDetailVM(undefined);

            const addlInterestTemplateObj = createTPITemplateObjFromScratch();

            addlInterestTemplateObj.additionalInsuredType = selectedAddlInsuredTypeCode;
            setIsControlledTPI(false);
            setIsAddingNewTPI(true);

            const newTPIDetailVM = createNewAddlInterestDTO(addlInterestTemplateObj);

            setTPIDetailVM(newTPIDetailVM);
            updateIsTPIFromSearchResult(false);
        },
        [
            updateAndShowWarning, createTPITemplateObjFromScratch,
            selectedAddlInsuredTypeCode, createNewAddlInterestDTO,
            updateIsTPIFromSearchResult
        ]
    );

    const createNewTPIUsingExistingContact = useCallback((addlInterestType, addlInsuredTypeCode) => {
        setTPIDetailVM(undefined);

        const addlInterestTemplateObj = createTPITemplateObjFromExistingContact();

        addlInterestTemplateObj.addlInterestType = addlInterestType;

        if (addlInsuredTypeCode) {
            addlInterestTemplateObj.additionalInsuredType = addlInsuredTypeCode;
        }

        setIsControlledTPI(false);
        setIsAddingNewTPI(false);

        const newTPIDetailVM = createNewAddlInterestDTO(addlInterestTemplateObj);

        setTPIDetailVM(newTPIDetailVM);
    }, [createNewAddlInterestDTO, createTPITemplateObjFromExistingContact]);

    /**
     * Helper callback for handling changes to the "Interest Type" drop-down field.
     */
    const handleInterestTypeValueChange = useCallback(
        (value) => {
            if (value) {
                setIsAddingNewTPI(false);
                setSelectedAddlInterestTypeCode(value);
                setSelectedAddlInsuredTypeCode(undefined);
                resetSearchCriteria(selectedAddlInsuredTypeCode, value);
                setIsTPITypeMortgagee(_includes(value, 'MORTGAGEE'));
                setIsTPITypeTrust(value === 'TRUST_Ext');
            }
        },
        [resetSearchCriteria, selectedAddlInsuredTypeCode]
    );

    useEffect(() => {
        const policyStateCode = _get(transactionVM, 'baseData.policyAddress.state.value.code');

        if (selectedAddlInterestTypeCode === 'AdditionalDesignee_Ext' && policyStateCode === 'CT') {
            handleContactSubtypeValueChange('Person')
        }
    }, [handleContactSubtypeValueChange, selectedAddlInterestTypeCode, transactionVM])

    /**
     * Helper callback for handling changes to the "Insured Type" drop-down field.
     */
    const handleInsuredTypeValueChange = useCallback(
        (value) => {
            setIsAddingNewTPI(false);
            setSelectedAddlInsuredTypeCode(value);
            resetSearchCriteria(value, selectedAddlInterestTypeCode);
        },
        [resetSearchCriteria, selectedAddlInterestTypeCode]
    );

    const handleInterestTypeValueChangeExistingContact = useCallback(
        (value) => {
            if (value) {
                setSelectedAddlInterestTypeCode(value);
                setSelectedAddlInsuredTypeCode(undefined);
                resetSearchCriteria(selectedAddlInsuredTypeCode, value);
                setIsTPITypeMortgagee(_includes(value, 'MORTGAGEE'));
                setIsTPITypeTrust(value === 'TRUST_Ext');

                if (!(productCode === 'PersonalUmbrella_EU' && value === 'AdditionalInsured_Ext')) {
                    createNewTPIUsingExistingContact(value, selectedAddlInsuredTypeCode);
                }
            }
        },
        [createNewTPIUsingExistingContact, productCode, resetSearchCriteria, selectedAddlInsuredTypeCode]
    );

    const handleInsuredTypeValueChangeExistingContact = useCallback(
        (value) => {
            setSelectedAddlInsuredTypeCode(value);
            resetSearchCriteria(value, selectedAddlInterestTypeCode);
            createNewTPIUsingExistingContact(selectedAddlInterestTypeCode, value);
        },
        [createNewTPIUsingExistingContact, resetSearchCriteria, selectedAddlInterestTypeCode]
    );

    /**
     * Helper memo for determining whether the "Contact Subtype" drop-down field is visible.
     */
    const contactSubtypeDropdownVisible = useMemo(() => {
        const isGenerallyVisible = selectedAddlInterestTypeCode === 'AdditionalInsured_Ext'
            || selectedAddlInterestTypeCode === 'AdditionalInterest_Ext';
        const visibleForEA = productCode === 'PersonalAuto_EA'
            && (isGenerallyVisible
                || selectedAddlInterestTypeCode === 'AdditionalDesignee_Ext'
                || selectedAddlInterestTypeCode === 'Titleholder_Ext'
            );
        const visibleForEH = productCode === 'Homeowners_EH' && isGenerallyVisible;

        return visibleForEA || visibleForEH;
    }, [productCode, selectedAddlInterestTypeCode]);

    /**
     * Helper callback for determining whether a field should be visible based on contact subtype.
     */
    const isFieldVisibleFor = useCallback(
        (contactSubtype) => {
            if (_get(tpiSearchCriteriaVM, 'value.contactSubtype') !== undefined) {
                return (
                    selectedAddlInterestTypeCode !== undefined
                    && tpiSearchCriteriaVM.contactSubtype.value.code === contactSubtype
                );
            }

            return false;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tpiSearchCriteriaVM.contactSubtype, selectedAddlInterestTypeCode]
    );

    /**
     * Helper memo for determining whether the "Organization Name" input field is visible.
     */
    const organizationNameSearchInputVisible = useMemo(() => {
        const isGenerallyVisible = isTPITypeMortgagee
            || selectedAddlInterestTypeCode === 'TRUST_Ext'
            || isFieldVisibleFor('Company');
        const visibleForEA = productCode === 'PersonalAuto_EA' && isGenerallyVisible;
        const visibleForEH = productCode === 'Homeowners_EH' && isGenerallyVisible;
        const visibleForEU = productCode === 'PersonalUmbrella_EU'
            && isGenerallyVisible
            && (!!selectedAddlInsuredTypeCode
                || selectedAddlInterestTypeCode === 'AdditionalDesignee_Ext');

        return visibleForEA || visibleForEH || visibleForEU;
    }, [
        isFieldVisibleFor,
        isTPITypeMortgagee,
        productCode,
        selectedAddlInsuredTypeCode,
        selectedAddlInterestTypeCode
    ]);

    /**
     * Helper memo for determining whether the "First/Last Name" input fields are visible.
     */
    const firstLastNameSearchInputVisible = useMemo(() => {
        const isGenerallyVisible = isFieldVisibleFor('Person');
        const visibleForEA = productCode === 'PersonalAuto_EA' && isGenerallyVisible;
        const visibleForEH = productCode === 'Homeowners_EH' && isGenerallyVisible;
        const visibleForEU = productCode === 'PersonalUmbrella_EU'
            && isGenerallyVisible
            && (!!selectedAddlInsuredTypeCode
                || selectedAddlInterestTypeCode === 'AdditionalDesignee_Ext');

        return visibleForEA || visibleForEH || visibleForEU;
    }, [isFieldVisibleFor, productCode, selectedAddlInsuredTypeCode, selectedAddlInterestTypeCode]);

    const renderActionColumn = useCallback(
        (item, index) => (
            <RadioButtonField
                id={`${index}selectTPIRadioButton`}
                value={String(item.addedAsTPI)}
                availableValues={
                    [
                        {
                            name: '',
                            code: 'true'
                        }
                    ]
                }
                readOnly={false}
                showOptional={false}
                onValueChange={() => handleAddTPIButtonClick(item)
                }
            />
        ),
        [handleAddTPIButtonClick]
    );

    const loadingMessage = useCallback(() => isPerformingSearch
        ? translator(messages.searchingForMatchingTPIsMessage) : translator(messages.retrievingTPIDetails), [isPerformingSearch, translator]);

    useEffect(() => {
        try {
            const preferredHeader = document.getElementById('tpiSearchResultTable_preferred');
            const tooltipDiv = document.getElementById('preferredTooltipDiv');

            if (preferredHeader && ((tooltipDiv && tooltipDiv.firstChild) || toolTipDivFirstChild)) {
                const firstChild = toolTipDivFirstChild || tooltipDiv.firstChild;

                preferredHeader.appendChild(firstChild);
                setToolTipDivFirstChild(firstChild)
            }
        } catch {
            // try block should not throw any exception; its just for additional checking
        }

    }, [
        isPerformingSearch, isPerformingRetrieve,
        isAddingExistingContact, isClickedOnSearch,
        isAddingNewTPI, toolTipDivFirstChild
    ]);

    const getEscrowValue = (item) => (<span>
        {item.escrow === 'true' ? 'Yes' : 'No'}
    </span>);

    const getPreferredValue = (item) => (<span>
        {item.preferred === 'true' ? 'Yes' : 'No'}
    </span>);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showRequired: true,
            autoComplete: false
        },
        tpiSearchResultTable: {
            data: formatSearchResult()
        },
        errorWarningContainer: {
            visible: showErrorWarning
        },
        errorAndWarningText: {
            message: translator(errorWarningMessage)
        },
        useExistingContactRelatedFieldsContainer: {
            visible: isAddingExistingContact
        },
        addlInterestTypeDropdownExistingContact: {
            availableValues: getAddlInterestTypeCodesExistingContact,
            onValueChange: handleInterestTypeValueChangeExistingContact,
            value: selectedAddlInterestTypeCode
        },
        addlInsuredTypeDropdownExistingContact: {
            availableValues: getAddlInsuredTypeCodes,
            onValueChange: handleInsuredTypeValueChangeExistingContact,
            value: selectedAddlInsuredTypeCode,
            required: addlInsuredTypeDropdownVisible,
            visible: addlInsuredTypeDropdownVisible
        },
        tpiSearchContainer: {
            visible: !isAddingExistingContact
        },
        addlInterestTypeDropdown: {
            availableValues: getAddlInterestTypeCodes,
            onValueChange: handleInterestTypeValueChange,
            value: selectedAddlInterestTypeCode
        },
        addlInsuredTypeDropdown: {
            availableValues: getAddlInsuredTypeCodes,
            onValueChange: handleInsuredTypeValueChange,
            value: selectedAddlInsuredTypeCode,
            required: addlInsuredTypeDropdownVisible,
            visible: addlInsuredTypeDropdownVisible
        },
        contactSubtypeDropDown: {
            onValueChange: handleContactSubtypeValueChange,
            required: contactSubtypeDropdownVisible,
            visible: contactSubtypeDropdownVisible,
            readOnly: selectedAddlInterestTypeCode === 'AdditionalDesignee_Ext' && _get(transactionVM, 'baseData.policyAddress.state.value.code') === 'CT'
        },
        organizationNameFields: {
            visible: organizationNameSearchInputVisible
        },
        organizationNameSearchInput: {
            onEnter: () => searchButtonOnClickHandler(),
            visible: organizationNameSearchInputVisible
        },
        personNameFields: {
            visible: firstLastNameSearchInputVisible
        },
        firstNameSearchInput: {
            onEnter: () => searchButtonOnClickHandler(),
            visible: firstLastNameSearchInputVisible
        },
        middleNameSearchInput: {
            onEnter: () => searchButtonOnClickHandler(),
            visible: firstLastNameSearchInputVisible
        },
        lastNameSearchInput: {
            onEnter: () => searchButtonOnClickHandler(),
            visible: firstLastNameSearchInputVisible
        },
        tpiNumberSearchInputDiv: {
            visible: authUserData.permissions_Ext.includes('searchtpibynumber_ext')
        },
        tpiNumberSearchInput: {
            value: tpiNumberSearchInput,
            onValueChange: ((value) => {
                updateTPINumberSearchInput(value);
            }),
            onEnter: () => searchButtonOnClickHandler(),
        },
        citySearchInput: {
            onEnter: () => searchButtonOnClickHandler(),
            visible: firstLastNameSearchInputVisible || organizationNameSearchInputVisible
        },
        stateSearchInput: {
            availableValues: e1pUSStatesUtil.getStateValues(USStates, translator),
            visible: firstLastNameSearchInputVisible || organizationNameSearchInputVisible
        },
        tpiActionContainer: {
            visible: firstLastNameSearchInputVisible || organizationNameSearchInputVisible
        },
        tpiSearchLoadingIndicator: {
            loaded: !isPerformingSearch && !isPerformingRetrieve,
            text: loadingMessage()
        },
        tpiSearchResultContainer: {
            visible: !isPerformingSearch && !isPerformingRetrieve
                && !isAddingExistingContact && isClickedOnSearch && !isAddingNewTPI
        },
        searchResultsHeaderContainer: {
            visible: !isPerformingSearch && !isPerformingRetrieve
                && !isAddingExistingContact && isClickedOnSearch && !isAddingNewTPI
        },
        searchResultsSubheader: {
            content: (
                <span>
                    {translator(messages.selectAThirdPartyWherePart1)}<b>{translator(messages.selectAThirdPartyWherePart2)}</b>{translator(messages.selectAThirdPartyWherePart3)}
                </span>
            ),
            visible: isFieldVisibleFor('Company')
        },
        tpiNumberTitle: { visible: isTPISearchResultControlled },
        tpiPreferredTitle: {
            visible: isTPISearchResultControlled
        },
        tpiEscrowTitle: { visible: isTPISearchResultControlled },
        tpiCommentsTitle: { visible: isTPISearchResultControlled },
        tpiAliasTitle: { visible: isTPISearchResultControlled },
        addNewTPIContainer: {
            visible:
                selectedAddlInterestTypeCode !== undefined
                && _get(tpiSearchCriteriaVM, 'contactSubtype.value.code') !== undefined
                && !isAddingExistingContact
                && !isAddingNewTPI
                && isClickedOnSearch
                && !isATPIFromSearchResultSelected
        },
        newTPICanBeCreatedText: {
            visible: !isATPIFromSearchResultSelected
        },
        newTPINotSetupForBillingText: {
            visible: (productCode === 'Homeowners_EH' || productCode === 'PersonalAuto_EA') && organizationNameSearchInputVisible && !isATPIFromSearchResultSelected,
            style: { fontWeight: 'var(--font-weight-normal-bold)' }
        },
        ehTPISearchDetailComponentID: {
            tpiDetailVM,
            viewModelService,
            isControlledTPI,
            isTPIContactTypeCompany,
            isTPITypeMortgagee,
            isTPITypeTrust,
            isAddingNewTPI,
            transactionVM,
            authHeader,
            visible: productCode === 'Homeowners_EH',
            updateIsSearchTPIVisible,
            updateAndShowWarning,
            saveThirdPartyInterestClickHandler,
            showErrors,
            onValidate,
            disregardFieldValidationParentPage,
            setIsAddingTPI,
            isTPIFromSearchResult
            // preferredTPIContactVM: preferredTPIContactVM
        },
        eaTPISearchDetailComponentID: {
            tpiDetailVM,
            viewModelService,
            isControlledTPI,
            isTPIContactTypeCompany,
            isTPITypeMortgagee,
            isTPITypeTrust,
            isAddingNewTPI,
            transactionVM,
            authHeader,
            visible: productCode === 'PersonalAuto_EA',
            updateIsSearchTPIVisible,
            updateAndShowWarning,
            saveThirdPartyInterestClickHandler,
            showErrors,
            onValidate,
            disregardFieldValidationParentPage,
            setIsAddingTPI,
            isTPIFromSearchResult
        },
        euTPISearchDetailComponentID: {
            tpiDetailVM,
            viewModelService,
            isControlledTPI,
            isTPIContactTypeCompany,
            isAddingNewTPI,
            transactionVM,
            authHeader,
            visible: productCode === 'PersonalUmbrella_EU',
            isAddingExistingContact,
            updateIsSearchTPIVisible,
            updateAndShowWarning,
            saveThirdPartyInterestClickHandler,
            showErrors,
            onValidate,
            disregardFieldValidationParentPage,
            setIsAddingTPI,
            isTPIFromSearchResult
        },
        preferredTooltip: {
            text: messages.preferredTooltipText,
            placement: 'top'
        },
        thirdPartyInterestTitleSubheader: {
            visible: (productCode === 'Homeowners_EH' || productCode === 'PersonalAuto_EA') && organizationNameSearchInputVisible
        }
    };

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            searchButtonOnClickHandler,
            resetSearchButtonOnClickHandler,
            sortString: DatatableUtil.sortString,
            renderActionColumn,
            handleAddNewTPIButtonClick,
            getEscrowValue,
            getPreferredValue
        },
        resolveComponentMap: {
            TooltipIcon
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
            metadata.pageContent,
            tpiSearchCriteriaVM,
            fieldId,
            fieldPath,
            overrideProps
        ),
        [tpiSearchCriteriaVM, overrideProps]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={tpiSearchCriteriaVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

/**
 * Define expected types for properties to be passed into this Jutro component.
 */
E1PTPISearchComponent.propTypes = {
    productCode: PropTypes.string.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func,
        create: PropTypes.func,
        productMetadata: PropTypes.shape({
            get: PropTypes.func
        }).isRequired
    }).isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    transactionVM: PropTypes.shape({}).isRequired,
    tpiBasePath: PropTypes.string.isRequired,
    updateIsSearchTPIVisible: PropTypes.func.isRequired,
    saveThirdPartyInterestClickHandler: PropTypes.func.isRequired,
    isAddingExistingContact: PropTypes.bool,
    isPerson: PropTypes.bool,
    showErrors: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
    disregardFieldValidationParentPage: PropTypes.func.isRequired,
    existingContact: PropTypes.bool,
    setIsAddingTPI: PropTypes.func
};

/**
 * Define default values for properties to be passed into this Jutro component.
 */
E1PTPISearchComponent.defaultProps = {
    isAddingExistingContact: false,
    isPerson: false,
    existingContact: undefined,
    setIsAddingTPI: undefined
};

export default withModalContext(E1PTPISearchComponent);
