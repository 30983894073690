import React, {
    useCallback, useContext, useMemo, useState, useEffect
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    cloneDeep as _cloneDeep,
    findIndex as _findIndex
} from 'lodash';
import { RenewalService } from 'e1p-capability-renewal';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import {
    useSniUtil,
    useEffectiveDateUtil,
    useNewBusinessUtil,
    e1pDateUtil
} from 'e1p-capability-hooks';
import { useValidation } from '@xengage/gw-portals-validation-react';
import moment from 'moment';
import wizardMessages from '../../EHRenewalWizard.messages';
import messages from './InsuredDetailsPage.messages';
import metadata from './InsuredDetailsPage.metadata.json5';


const LOB = 'homeowners_EH';

function InsuredDetailsPage(props) {
    const modalApi = useModal();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const translator = useTranslator();
    const [lapseInCoverage, updateLapseInCoverage] = useState(undefined);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const [policyIsBackDated, setPolicyIsBackDated] = useState(false);
    const [effDateChanged, setEffDateChanged] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const {
        wizardData: renewalVM, updateWizardData, isSkipping, authUserData,
        steps, jumpTo, isPageJumpEnabled,
        updateIsPageJumpEnabled,
        updateWizardSnapshot
    } = props;
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [initialHouseholdMembers, setInitialHouseholdMembers] = useState([]);
    const [lastPolicyExpirationDate] = useState(
        _cloneDeep(renewalVM.value.lobData.homeowners_EH
        .priorPolicies[0]?.policyExpirationDate));
    const {
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        onValidate
    } = useValidation('InsuredDetailsPage');

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [renewalVM, isComponentValid, isPageSubmitted]);

    const { createSecondaryNamedInsuredVM, removeSni, addSniInCoverable } = useSniUtil(
        renewalVM,
        updateWizardData,
        viewModelService,
        LOB,
        disregardFieldValidation
    );

    const {
        getLocationAndProducerCodes,
        getProducerDetailsFromProducerCodes,
        getProducerDetailsFromExternalId
    } = useNewBusinessUtil(
        renewalVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        setLocationAndProducerCodes
    );

    const policyForm = _get(renewalVM, 'lobData.homeowners_EH.policyFormType.value');
    const policyType = _get(renewalVM, 'lobData.homeowners_EH.value.policyType');
    const changeSummaryIndex = _findIndex(steps, ({ path }) => path === '/change-summary');
    const coveragesIndex = _findIndex(steps, ({ path }) => path === '/coverages');
    const usageType = translator({ id: _get(renewalVM, 'lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value.name') });
    const {
        getEffectiveDateBounds
    } = useEffectiveDateUtil(renewalVM, updateWizardData, false);

    const { maxEffectiveDate, minEffectiveDate } = useMemo(
        () => getEffectiveDateBounds(
                renewalVM.baseData.periodStartDate.value,
                renewalVM.baseData.periodEndDate.value,
                30,
                60
            ),
        [
            getEffectiveDateBounds,
            renewalVM.baseData.periodEndDate.value,
            renewalVM.baseData.periodStartDate.value
        ]
    );

    const setBackdateInformation = () => {
        const policyEffectiveDate = moment(renewalVM.baseData.effectiveDate.value);
        const effectiveDateBeforeToday = moment().isAfter(policyEffectiveDate, 'd');

        setPolicyIsBackDated(effectiveDateBeforeToday);
    };

    useEffect(() => {
        setBackdateInformation();

        // we want to make each step as visited so that user
        // can navigate to any page by clicking on tabs on chevron
        if (isPageJumpEnabled) {
            updateIsPageJumpEnabled(false);
            jumpTo(coveragesIndex, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(renewalVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(renewalVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    useEffect(() => {
        // get available producer codes for given state
        const policyState = _get(renewalVM, 'baseData.policyAddress.state.value.code');

        if (policyState && isAgent) {
            // only for agents
            getAndSetLocationCodes(policyState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const isProducerCodeDisabled = useMemo(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(renewalVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(renewalVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);

            return true;
        }

        return false;
    }, [locationAndProducerCodes, renewalVM]);

    useEffect(() => {
        const {
            producerCode
        } = producerCodeDetails;
        const existingProducerCode = _get(
            renewalVM,
            'value.baseData.producerCode_Ext',
            renewalVM.value.baseData.producerCode
        );

        if (existingProducerCode && isAgent
            && existingProducerCode !== producerCode) {
            (async () => {
                // only for agents
                const details = await getProducerDetailsFromProducerCodes(
                    renewalVM.value.baseData.producerCode_Ext,
                    locationAndProducerCodes.producerCodes,
                    renewalVM.value.baseData.externalID_Ext
                );

                // in case we dont get any response from service
                if (details) {
                    setProducerCodeDetails(details);
                    setAgencyName(details.agencyName);
                    setBookTransferIndicator(details.bookTransferIndicator);
                    setServiceCenterIndicator(details.serviceCenterIndicatorValue);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renewalVM.baseData.producerCode_Ext.value]);

    const locationCodeChange = useCallback(
        (value) => {
            _set(renewalVM, 'baseData.externalID_Ext', value);

            const selectedProducerCode = locationAndProducerCodes?.producerCodes
                ?.find((producerCode) => producerCode?.externalId_Ext === value);

            _set(renewalVM, 'baseData.producerCode_Ext', selectedProducerCode?.code);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData, locationAndProducerCodes]
    );

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, address, phone, externalID, name, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            _set(renewalVM, 'baseData.value.producerCode_Ext', producerCode);
            _set(renewalVM, 'baseData.externalID_Ext.value', externalID);
            updateWizardData(renewalVM);
            setProducerCodeDetails({ producerCode, address, phone });
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [renewalVM, updateWizardData],
    );

    useEffect(() => {
        // keeping the IF condition here, but under no circumstances should it be undefined
        if (renewalVM.baseData.externalID_Ext?.value) {
            (async () => {
                const details = await getProducerDetailsFromExternalId(
                    renewalVM.baseData.externalID_Ext.value
                );

                setProducerCodeDetails(details);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const existingHouseholdOccupants = _cloneDeep(_get(renewalVM.value, 'lobData.homeowners_EH.coverables.householdOccupants'));

        setInitialHouseholdMembers(existingHouseholdOccupants);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            renewalVM.lobData.homeowners_EH.priorPolicies.value !== undefined
            && renewalVM.lobData.homeowners_EH.priorPolicies.length > 0
        ) {
            updateLapseInCoverage(
                !renewalVM.lobData.homeowners_EH.priorPolicies.value[0].continuousCoverageInd
            );
        }
    }, [
        renewalVM.lobData.homeowners_EH.priorPolicies.length,
        renewalVM.lobData.homeowners_EH.priorPolicies.value]);

    const addNewPrimaryNamedInsured = useCallback(() => {
        const primaryNamedInsuredObj = {
            person: {
                lastName: '',
                firstName: '',
                dateOfBirth: {
                    year: undefined,
                    month: undefined,
                    day: undefined
                },
                // save primary address
                primaryAddress: (() => {
                    // default new person address to prior pni
                    const priorPniAddress = _get(
                        renewalVM,
                        `lobData[${LOB}].primaryNamedInsured.person.primaryAddress.value`
                    );

                    // Needs new public ID to make it a new adress in the DB
                    _set(priorPniAddress, 'publicID', undefined);

                    return priorPniAddress;
                })()
            }
        };
        const { _dtoName, _xCenter } = renewalVM.lobData[LOB].primaryNamedInsured;
        const primaryNamedInsuredVM = viewModelService.create(
            primaryNamedInsuredObj,
            _xCenter,
            _dtoName
        );

        _set(
            renewalVM,
            `lobData[${LOB}].primaryNamedInsured.value`,
            primaryNamedInsuredVM.value
        );
        updateWizardData(renewalVM);
    }, [renewalVM, updateWizardData, viewModelService]);

    const syncNamedInsuredToHouseholdMembers = useCallback(() => {
        // sync NI changes to householdoccupants
        //   changes to NI will not override data in the occ so must be synced
        const householdOccupants = _get(renewalVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
        const pni = _get(renewalVM.value, 'lobData.homeowners_EH.primaryNamedInsured');
        const sni = _get(renewalVM.value, 'lobData.homeowners_EH.secondaryNamedInsured');
        const pniOccupant = householdOccupants
            .find((occupant) => occupant.person.publicID === pni.person.publicID);
        // SNI may be undefined so being null safe there
        const sniOccupant = householdOccupants
            .find((occupant) => occupant.person.publicID === sni?.person?.publicID);

        if (pniOccupant) {
            // updates to existing pni contact
            _set(pniOccupant, 'person', pni.person);
        }

        if (sni && sniOccupant) {
            // updates to existing sni contact
            _set(sniOccupant, 'person', sni.person);
        }

        if (pni && !pniOccupant) {
            // check first in the existing household occupants before creating new
            if (!_isEmpty(initialHouseholdMembers)) {
                const initialPniOccupant = initialHouseholdMembers
                    .find((occupant) => occupant.person.publicID === pni?.person?.publicID);

                if (initialPniOccupant) {
                    _set(initialPniOccupant, 'person', pni.person);
                    _set(initialPniOccupant, 'relationshipToNI', undefined);
                    householdOccupants.push(initialPniOccupant);
                } else {
                    householdOccupants.push({ person: pni.person });
                }
            } else {
                householdOccupants.push({ person: pni.person });
            }

            // only update this if pni/sni changes
            _set(renewalVM, `lobData[${LOB}].coverables.householdOccupants`, householdOccupants);
        }

        if (sni && !sniOccupant) {
            // check first in the existing household occupants before creating new
            if (!_isEmpty(initialHouseholdMembers)) {
                const initialSniOccupant = initialHouseholdMembers
                    .find((occupant) => occupant.person.publicID === sni?.person?.publicID);

                if (initialSniOccupant) {
                    _set(initialSniOccupant, 'person', sni.person);
                    _set(initialSniOccupant, 'relationshipToNI', sni.relationshipToNI);
                    householdOccupants.push(initialSniOccupant);
                } else {
                    householdOccupants.push(
                        { person: sni.person, relationshipToNI: sni.relationshipToNI }
                    );
                }
            } else {
                householdOccupants.push(
                    { person: sni.person, relationshipToNI: sni.relationshipToNI }
                );
            }

            // only update this if pni/sni changes
            _set(renewalVM, `lobData[${LOB}].coverables.householdOccupants`, householdOccupants);
        }
    }, [initialHouseholdMembers, renewalVM]);

    /**
     * Setting riskLocation, mailingAddress and billingAddressValues based on indicators
     */
    const setCorrectAddressValues = useCallback(
        () => {
            // if riskAddressSameInd is true then set policyAddress value to risk location
            if (
                _get(
                    renewalVM,
                    'lobData.homeowners_EH.coverables.yourHome.isRiskAddressSame_Ext.value'
                )
            ) {
                _set(
                    renewalVM,
                    'lobData.homeowners_EH.coverables.yourHome.ehlocation.value',
                    _get(renewalVM, 'baseData.policyAddress.value')
                );
            }

            // if mailingAddressSameInd is true then set policyAddress value to mailingAddress
            if (
                _get(
                    renewalVM,
                    'baseData.isMailingAddressSame_Ext.value'
                )
            ) {
                _set(
                    renewalVM,
                    'baseData.policyMailingAddress_Ext.value',
                    _get(renewalVM, 'baseData.policyAddress.value')
                );
            }

            //  if billingAddressSameInd is true then set mailingAddress value to billingAddress
            if (
                _get(
                    renewalVM,
                    'baseData.isBillingAddressSame_Ext.value'
                )
            ) {
                _set(
                    renewalVM,
                    'baseData.policyBillingAddress_Ext.value',
                    _get(renewalVM, 'baseData.policyMailingAddress_Ext.value')
                );
            }
        }, [renewalVM]
    );

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            const policyExpirationDate = _get(
                renewalVM,
                'lobData.homeowners_EH.priorPolicies.value[0].policyExpirationDate'
            );
            let correctDateFormat;

            // prior policy may not exist
            // without this check the path is getting created and value is set to today
            if (policyExpirationDate) {
                correctDateFormat = e1pDateUtil.convertToUTC(policyExpirationDate);
                _set(
                    renewalVM,
                    'lobData.homeowners_EH.priorPolicies.children[0].policyExpirationDate.value',
                    correctDateFormat
                );
            }

            setIsSavingRenewal(true);
            syncNamedInsuredToHouseholdMembers();
            setCorrectAddressValues();
            // Need this for validation rules
            _set(renewalVM, 'flowStepIDs_Ext.value', ['namedinsured']);
            _set(renewalVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);

            const saveResponse = await RenewalService.saveRenewal(
                [renewalVM.value],
                authHeader
            );

            setIsSavingRenewal(false);

            if (saveResponse.baseData.exceptions_Ext) {
                // This code is to handle the exception by showing it
                // using Inline Notification Component
                _set(renewalVM, 'baseData.exceptions_Ext.value', saveResponse.baseData.exceptions_Ext);
                updateWizardData(renewalVM);
            } else {
                _set(renewalVM, 'value', saveResponse);
                // sni exist or not is being checked in the method itself, so no need to check here
                addSniInCoverable();

                // if this page renders again before the page switch, the date will get screwed up again
                if (correctDateFormat) {
                    _set(
                        renewalVM,
                        'value.lobData.homeowners_EH.priorPolicies[0].policyExpirationDate',
                        correctDateFormat
                    );
                }

                updateWizardData(renewalVM);

                return renewalVM;
            }

            // if this page renders again before the page switch, the date will get screwed up again
            if (correctDateFormat) {
                _set(
                    renewalVM,
                    'value.lobData.homeowners_EH.priorPolicies[0].policyExpirationDate',
                    correctDateFormat
                );
            }

            setIsSavingRenewal(false);
            updateWizardData(renewalVM);

            return false;
        },
        [
            addSniInCoverable, authHeader, isComponentValid, renewalVM,
            setCorrectAddressValues, syncNamedInsuredToHouseholdMembers, updateWizardData
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = _get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    const generateOverrides = useCallback(() => {
        const overrides = {};

        overrides.primaryNamedInsured = {
            isPNI: true
        };

        return overrides;
    }, []);

    const swapPniSni = useCallback(() => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: e1pCommonMessages.swapPniSniTitle,
            message: e1pCommonMessages.swapPniSniMessage,
            confirmButtonText: e1pCommonMessages.confirm,
            cancelButtonText: e1pCommonMessages.cancel
        }).then(
            (result) => {
                if (result !== 'cancel') {
                    const pniPerson = {
                        ..._get(
                            renewalVM,
                            'lobData.homeowners_EH.primaryNamedInsured.person.value'
                        )
                    };
                    const sniPerson = {
                        ..._get(renewalVM, 'lobData.homeowners_EH.secondaryNamedInsured.person.value')
                    };

                    let householdOccupants = _get(renewalVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
                    let sniOccupant = householdOccupants
                        .find((occupant) => occupant.person.publicID === sniPerson?.publicID);

                    if (!sniOccupant) {
                        addSniInCoverable();
                        householdOccupants = _get(renewalVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
                        sniOccupant = householdOccupants
                            .find((occupant) => occupant.person.publicID === sniPerson.publicID);
                    }

                    const pniOccupant = householdOccupants
                        .find((occupant) => occupant.person.publicID === pniPerson.publicID);

                    const relationshipToNI = _get(renewalVM, 'value.lobData.homeowners_EH.secondaryNamedInsured.relationshipToNI', 'notrelated');

                    _set(pniOccupant, 'relationshipToNI', relationshipToNI);
                    _set(sniOccupant, 'relationshipToNI', undefined);
                    // swap PNI and SNI
                    _set(
                        renewalVM,
                        'lobData.homeowners_EH.primaryNamedInsured.person.value',
                        sniPerson
                    );
                    _set(
                        renewalVM,
                        'lobData.homeowners_EH.secondaryNamedInsured.person.value',
                        pniPerson
                    );
                    // since we are swapping PNI and SNI, we need remove publicID, FixedID and integrationID from both policyContactRoles
                    // i.e PNI and SNI
                    _set(renewalVM, 'lobData.homeowners_EH.primaryNamedInsured.fixedId.value', undefined);
                    _set(renewalVM, 'lobData.homeowners_EH.primaryNamedInsured.publicID.value', undefined);
                    _set(renewalVM, 'lobData.homeowners_EH.primaryNamedInsured.integrationId.value', undefined);
                    _set(renewalVM, 'lobData.homeowners_EH.secondaryNamedInsured.fixedId.value', undefined);
                    _set(renewalVM, 'lobData.homeowners_EH.secondaryNamedInsured.publicID.value', undefined);
                    _set(renewalVM, 'lobData.homeowners_EH.secondaryNamedInsured.integrationId.value', undefined);

                    // If paperless Email id exist and pni email id is undefiend
                    // then update pni email to paperless email
                    if (!_get(renewalVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value')
                        && !!_get(renewalVM, 'lobData.homeowners_EH.paperlessEmail.value')) {
                        _set(renewalVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value',
                            _get(renewalVM, 'lobData.homeowners_EH.paperlessEmail.value'));
                    }

                    updateWizardData(renewalVM);
                }
            },
            () => { }
        );
    }, [addSniInCoverable, renewalVM, modalApi, updateWizardData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        internalProducerDetailsComponent: {
            defaultValue: renewalVM.baseData.externalID_Ext?.value,
            visible: !isAgent
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            disabled: isProducerCodeDisabled,
            onValueChange: locationCodeChange,
            visible: isAgent
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        renewalTermEffectiveDate: {
            dateDTO: renewalVM.baseData.effectiveDate,
            updateDateDto: () => {
                setEffDateChanged(true);
                setBackdateInformation();
                updateWizardData(renewalVM);
            },
            minDate: minEffectiveDate,
            maxDate: maxEffectiveDate,
            showErrors: isPageSubmitted,
            onValidate
        },
        policyInformationInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr', '0.25fr']
        },
        insuredDetailsPageLoadingIndicator: {
            loaded: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        insuredDetailsPageContainer: {
            visible: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges
        },
        mailingAndBillingAddressComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            showErrors: isPageSubmitted
        },
        addSni: {
            visible: !_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        sniContainer: {
            visible: !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        removeSni: {
            visible: !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        changePniComponent: {
            accountNumber: _get(renewalVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(renewalVM, 'baseData.accountNumber.value'),
            allowNewContact: false,
            excludedContacts: [
                _get(renewalVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            householdOccupants: _get(
                renewalVM.value,
                `lobData[${LOB}].coverables.householdOccupants`,
                undefined
            )
        },
        changeSniComponent: {
            accountNumber: _get(renewalVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(renewalVM, 'baseData.accountNumber.value')
                && !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            excludedContacts: [
                _get(renewalVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            householdOccupants: _get(
                renewalVM.value,
                `lobData[${LOB}].coverables.householdOccupants`,
                undefined
            )
        },
        riskAddressComponent: {
            submissionVM: renewalVM,
            updateWizardData,
            onValidate,
            viewModelService,
            viewOnlyMode: _get(renewalVM, `lobData[${LOB}].policyFormType.value`) !== 'HO4',
            showErrors: isPageSubmitted
        },
        ehPriorAddressComponent: {
            submissionVM: renewalVM,
            updateWizardData,
            onValidate,
            viewModelService,
            viewOnlyMode: true,
            visible: policyType === 'HO3' || policyType === 'HF9',
            showErrors: isPageSubmitted,
            LOB
        },
        insuranceHistoryMainDiv: {
            visible: policyType !== 'HO4'
        },
        coverageLapse: {
            value: lapseInCoverage,
            readOnly: true
        },
        policyForm: {
            visible: usageType !== undefined || policyType === 'HO4',
            value: policyForm
        },
        usageType: {
            value: usageType,
            visible: policyType !== 'HO4'
        },
        lastPolicyExpirationDate: {
            // HO4 will not have prior policies, so implement check for undefined
            dateDTO: lastPolicyExpirationDate,
            readOnly: true
        },
        swapPniSni: {
            visible: !!_get(renewalVM, `value.lobData[${LOB}].secondaryNamedInsured.person.publicID`)
                && !!_get(renewalVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`)
        },
        insuredResidenceAddressWarningMessageDiv: {
            visible: _get(renewalVM, `lobData[${LOB}].policyFormType.value`) !== 'HO4'
        },
        hO4InsuredResidenceAddressWarningMessageDiv: {
            visible: _get(renewalVM, `lobData[${LOB}].policyFormType.value`) === 'HO4'
        },
        lossQuestionEmptyContainer: {
            visible: !policyIsBackDated || !effDateChanged,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 4 : 3
        },
        lossQuestionContainer: {
            visible: policyIsBackDated && effDateChanged,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 4 : 3
        },
        lossOccurredBetweenEffectiveDateAndCreateDateInd: {
            defaultValue: renewalVM.value.hasLossOnOrAfterEffDate
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate,
            onRemoveSni: removeSni,
            onAddNewPni: addNewPrimaryNamedInsured,
            onSwapPniSni: swapPniSni,
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    const reviewChanges = useCallback(
        async () => {
            setIsSavingRenewal(true);

            const policyExpirationDate = _get(
                renewalVM,
                'lobData.homeowners_EH.priorPolicies.value[0].policyExpirationDate'
            );

            // prior policy may not exist
            // without this check the path is getting created and value is set to today
            if (policyExpirationDate) {
                const correctDateFormat = e1pDateUtil.convertToUTC(policyExpirationDate);

                _set(
                    renewalVM,
                    'lobData.homeowners_EH.priorPolicies.children[0].policyExpirationDate.value',
                    correctDateFormat
                );
            }

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            _set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);
            jumpTo(changeSummaryIndex, true, quoteResponse);

            return false;
        },
        [authHeader, changeSummaryIndex, jumpTo, renewalVM, updateWizardData]
    );

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            disableCustom={!isComponentValid}
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            onSave={onSave}
            showOnSave
            showPrevious={false}
            showCustom
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

InsuredDetailsPage.propTypes = wizardProps;

export default withAuthenticationContext(InsuredDetailsPage);
