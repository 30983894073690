import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invocation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processConfigRequest(method, data, additionalHeaders = {}) {
    const configURL = getProxiedServiceUrl('config');
    return JsonRPCService.send(configURL, method, data, additionalHeaders);
}

/**
 * This is the service responsible for dealing with the Config operations
 *
 * @export
 * @class AttributeConfigService
 */
export default class AttributeConfigService {
    /**
     * Retrieve Config Metadata
     *
     * @param {Object} configRequest ConfigRequestDTO
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof AttributeConfigService
     */
    static retrieveConfig(configRequest, additionalHeaders = {}) {
        return processConfigRequest('retrieveConfig', [configRequest], additionalHeaders);
    }

    
}
