import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    get as _get,
    set as _set,
    find as _find,
    includes as _includes
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useModifierUtil } from 'e1p-capability-hooks';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { commonMessages, euCommonMessages } from 'e1p-platform-translations';
import stateSpecificSurchargesConfig from './StateSpecificSurchargeConfig';
import messages from './SurchargeListComponent.messages';
import metadata from './SurchargeListComponent.metadata.json5';
import styles from './SurchargeListComponent.module.scss';

/**
 * StateSpecificSurchargesConfig file includes only those state for which we have state specific logic(what surchanges to show for given state)
 * If above file does not contain state, we will show all the available surcharges for given state
 * @param {Object[]} surcharges 
 * @param {String} lob 
 * @param {String} policyState 
 * @returns {Object[]}
 */
const getStateSpecificSurcharges = (surcharges, lob, policyState) => {
    const surchargesForState = stateSpecificSurchargesConfig[lob][policyState];

    if (surchargesForState) {
        return surcharges.filter((surcharge) => surchargesForState.includes(_get(surcharge, 'modifier.patternCode')));
    }

    return surcharges;
}

function SurchargeListComponent(props) {
    const { value: surcharges, transactionVM, lob, updateWizardData, setFieldsChangedOnCoveragePage, alwaysShowSurchargeComponent, viewOnlyMode } = props;
    const { authUserData } = useAuthentication();
    const translator = useTranslator();
    const [appliedSurcharges, setAppliedSurcharges] = useState([]);
    const [chargeHighProfileSurcharge, setChargeHighProfileSurcharge] = useState(_get(transactionVM, 'lobData.personalUmbrella_EU.chargeHighProfileSurcharge.value', false));
    const [chargeHighProfileUWIssue, setChargeHighProfileUWIssue] = useState(undefined);
    const [surchargeTotal, setSurchargeTotal] = useState(undefined);
    const {
        getStateVehicleFees,
        getStateCommonFees,
        getStatePACSurcharge
    } = useModifierUtil(transactionVM);
    const underwritingIssuesPath = _get(transactionVM, 'baseData.jobType.value.code', '') === 'Renewal'
        ? 'value.errorsAndWarnings_Ext.underwritingIssues'
        : 'value.errorsAndWarnings.underwritingIssues';

    useEffect(() => {
        let surchargeSubtotal = 0;
        let surchargeValue = [];

        if (lob === 'PersonalAuto_EA') {
            const allCombindSurcharges = [...surcharges, ...getStateVehicleFees(), ...getStateCommonFees(), ...getStatePACSurcharge()];

            surchargeValue = getStateSpecificSurcharges(allCombindSurcharges, lob, _get(
                transactionVM,
                'baseData.policyAddress.state.value.code',
                _get(transactionVM, 'policyAddress.state.value.code')
            ))
        } else {
            surchargeValue = surcharges;
        }

        surchargeValue.forEach((surcharge) => {
            // eslint-disable-next-line no-unsafe-optional-chaining
            if (_get(surcharge, 'modifier.premium')?.amount) {surchargeSubtotal += _get(surcharge, 'modifier.premium')?.amount;}
        })
        setAppliedSurcharges(surchargeValue);
        setSurchargeTotal(surchargeSubtotal);

        const uwIssues = _get(transactionVM, underwritingIssuesPath);
        const tempChargeHighProfile = _find(uwIssues, (issue) => issue.uwissueCode_Ext === "HighProfileInsuredOther_EU");

        setChargeHighProfileUWIssue(tempChargeHighProfile);

        if (uwIssues) {
            if (tempChargeHighProfile !== undefined
                && _get(transactionVM, 'lobData.personalUmbrella_EU.chargeHighProfileSurcharge.value') === undefined) {
                setChargeHighProfileSurcharge(false);
                _set(transactionVM, 'lobData.personalUmbrella_EU.chargeHighProfileSurcharge', false);
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
    };

    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        appliedSurcharges.forEach((tempSurcharge, index) => {
            if (tempSurcharge.vehicle) {
                const surchargeTitle = translator(messages.surchargeName,
                    {
                        make: tempSurcharge.vehicle.make,
                        model: tempSurcharge.vehicle.model,
                        year: tempSurcharge.vehicle.year
                    });

                overrideProps[`productSpecificName${index}`] = {
                    value: surchargeTitle
                };
            } else {
                overrideProps[`productSpecificName${index}`] = {
                    value: tempSurcharge.productSpecificName
                };
            }

            overrideProps[`appliedSurchargeAmount${index}`] = {
                value: tempSurcharge.premium
            };

        })

        return overrideProps;
    }, [appliedSurcharges, translator]);

    const overrideProps = {
        surchargeTableRowsContainer: {
            visible: !!appliedSurcharges?.length,
        },
        surchargeTitle: {
            visible: !!appliedSurcharges?.length || alwaysShowSurchargeComponent,
        },
        chargeHighProfileSurchargeIndToggleField: {
            value: chargeHighProfileSurcharge,
            label: translator(euCommonMessages.applyHighProfileSurcharge),
            visible: chargeHighProfileUWIssue !== undefined
                && chargeHighProfileUWIssue.currentBlockingPoint === 'NonBlocking',
            availableValues: [
                {
                    displayName: translator(commonMessages.yes),
                    id: true
                },
                {
                    displayName: translator(commonMessages.no),
                    id: false
                }
            ],
            // this has to be permission based rather than role based
            readOnly: !_includes(authUserData?.roles_Ext, 'underwriter') || viewOnlyMode,
            onValueChange: (value) => {
                setChargeHighProfileSurcharge(value)
                _set(transactionVM, 'lobData.personalUmbrella_EU.chargeHighProfileSurcharge', value);

                if (setFieldsChangedOnCoveragePage) {setFieldsChangedOnCoveragePage(true);}

                if (updateWizardData) {updateWizardData(transactionVM);}
            }

        },

        surchargeValue: {
            value: { currency: 'usd', amount: surchargeTotal }
        },
        surchargeTotalRow: {
            visible: surchargeTotal !== 0
        },
        feeAndOtherCharges: {
            visible: alwaysShowSurchargeComponent
        },
        ...generateOverrides()
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={{ surcharge: appliedSurcharges, surchargesAmount: surchargeTotal }}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

SurchargeListComponent.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({})),
    netSurcharge: PropTypes.shape({}),
    setFieldsChangedOnCoveragePage: PropTypes.func,
    updateWizardData: PropTypes.func,
    alwaysShowSurchargeComponent: PropTypes.bool,
    viewOnlyMode: PropTypes.bool
};

SurchargeListComponent.defaultProps = {
    value: undefined,
    netSurcharge: undefined,
    setFieldsChangedOnCoveragePage: undefined,
    updateWizardData: undefined,
    alwaysShowSurchargeComponent: false,
    viewOnlyMode: false
};
export default SurchargeListComponent;
