import { useCallback, useContext } from 'react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import {
    get as _get,
    set as _set
} from 'lodash';
import e1pDateUtil from './e1pDateUtil';

function usePriorPolicyMilitaryDeploymentUpdateUtil() {
    const viewModelService = useContext(ViewModelServiceContext);

    const getMilitaryDeploymentRecordIndex = useCallback((integrationId, priorPolicyUpdateVMList) => {
        const priorPolicyVMListValues = _get(priorPolicyUpdateVMList, 'value', []);
        return priorPolicyVMListValues
            .findIndex((priorPolicyUpdate) => priorPolicyUpdate.type === 'MilitaryDeployment_Ext'
                && priorPolicyUpdate.integrationId === integrationId);
    }, []);

    const getMilitaryDeploymentRecords = useCallback((integrationId, priorPolicyUpdateVMList) => {
        const priorPolicyVMListValues = _get(priorPolicyUpdateVMList, 'value', []);
        return priorPolicyVMListValues
            .filter((priorPolicyUpdate) => priorPolicyUpdate.type === 'MilitaryDeployment_Ext'
                && priorPolicyUpdate.integrationId === integrationId);
    }, []);

    const createPriorPolicyMilitaryDeploymentUpdatesVM = useCallback(
        (contactID, role, integrationId, periodStartDate, priorPolicyUpdateVMList) => {
            if ((!priorPolicyUpdateVMList.value)) {
                _set(priorPolicyUpdateVMList, 'value', []);
            }

            const priorPolicyUpdatesObj = {
                reason: 'ReturningMilitary',
                endDate: e1pDateUtil.convertToUTC(periodStartDate),
                type: 'MilitaryDeployment_Ext',
                contactID: contactID,
                policyRole: role,
                integrationId: integrationId
            };
            const priorPolicyUpdatesVM = viewModelService.create(
                priorPolicyUpdatesObj,
                'pc',
                'amfam.edge.capabilities.policyjob.common.priorpolicy.dto.PriorPolicyUpdateDTO'
            );
            priorPolicyUpdateVMList.value.push(priorPolicyUpdatesVM.value);
        },
        [viewModelService]
    );

    const removeMilitaryDeployments = useCallback((integrationId, priorPolicyUpdateVMList) => {
        return _get(priorPolicyUpdateVMList, 'value', [])
            .filter((priorPolicyUpdate) => !(priorPolicyUpdate.type === 'MilitaryDeployment_Ext'
                && priorPolicyUpdate.integrationId === integrationId));
    }, []);


    return {
        getMilitaryDeploymentRecordIndex,
        removeMilitaryDeployments,
        getMilitaryDeploymentRecords,
        createPriorPolicyMilitaryDeploymentUpdatesVM
    };
}
export default usePriorPolicyMilitaryDeploymentUpdateUtil;
