import React, {
    useCallback, useState, useEffect, useRef
} from 'react';
import {
    e1pDateUtil
} from 'e1p-capability-hooks';
import _ from 'lodash';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import appConfig from 'app-config';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './E1PDateComponent.metadata.json5';

const MONTH_PADDING = 1; // dto month starts from 0
const TODAYS_DATE_OBJECT = (() => {
    const today = new Date();

    return { day: today.getDate(), month: today.getMonth(), year: today.getFullYear() };
})();

// Use min and max default dates from build config
const MAX_DEFAULT_DATE = appConfig.maxDate;

const MIN_DEFAULT_DATE = appConfig.minDate;

const getInvalidDateMessage = (minDate, maxDate) => `Value must be between ${e1pDateUtil.getFormatedDate(minDate)} and ${e1pDateUtil.getFormatedDate(maxDate)}`;

/**
 *
 * @param {*} formattedDate any date thats not an object
 * @returns {object} a date object
 */
const getObjectFromDate = (formattedDate) => {
    const momentDate = moment(formattedDate).toDate();

    return {
        day: momentDate.getDate(),
        month: momentDate.getMonth(),
        year: momentDate.getFullYear()
    };
};

/**
 * @param {*} dateObject localDateDTO object
 * @returns {boolean} 
 * 
 * check if the date is valid from localDateDTO Object
 */
const isDateObjectValid = (dateObject) => {
    let { day, month } = dateObject;
    const { year } = dateObject;

    // comes as int from local date dto so add leading '0' if needed
    // Ex: 1/7/2020 -> 01/07/2020
    day = day?.toString().length === 1
        ? `0${day}` : day;

    // Month on date dto starts at 0 so we need to add the padding (+1)
    month += MONTH_PADDING;
    // Need to add leading 0 to single digits
    month = month?.toString().length === 1
        ? `0${month}` : month;

    const dateStringFormatValue = `${month}/${day}/${year}`;

    // no need to check any other format, since we just need to check if date is vaild or not
    return moment(dateStringFormatValue, 'MM/DD/YYYY', true).isValid();
};

const E1PDateComponent = (props) => {
    const {
        updateDateDto,
        label,
        hideLabel,
        // Can't pass in as "required", it screws up useValidation
        //  So I prefixed with "is"
        isRequired,
        disabled,
        readOnly,
        defaultValue, // defaultValue takes precedence over defaultToToday
        defaultToToday,
        labelPosition,
        additionalValidationMessages,
        showErrors,
        onValidate,
        id,
        className,
        path,
        visible,
        onBlur,
        tooltip,
        showCalendarIcon,
        currentMinDate,
        isDateFormatDDMMYYYY
    } = props;
    let { dateDTO, maxDate, minDate } = props;
    const translator = useTranslator();
    const [isComponentInitialized, setIsComponentInitialized] = useState(false);
    const [validationMessages, updateValidationMessages] = useState([]);
    const [dateString, updateDateString] = useState('');
    // This component may be loaded in by the parent before
    //   the parent has initialized the data
    const dataInitialized = !!dateDTO;

    const {
        isComponentValid,
        onValidate: setComponentValidation,
        registerComponentValidation
    } = useValidation(id);

    // This ref is used to default the value on the initial/first load of the component
    const canUseDefaultValueRef = useRef(true);

    const isViewModel = ViewModelUtil.isViewModelNode(dateDTO);
    const updateDate = (newDateObject) => {
        if (isViewModel) {
            dateDTO.value = newDateObject;
        } else {
            dateDTO = newDateObject;
        }
    };

    useEffect(() => {
        // Extra layer of validation to make sure the final date is valid
        registerComponentValidation(() => {
            const dateObject = isViewModel ? { ...dateDTO.value } : { ...dateDTO };

            return moment(dateObject).isValid();
        });
    }, [dateDTO, dateString, isViewModel, registerComponentValidation]);

    // If coming from a date format that isn't local date dto
    if (
        dateDTO
        && !isViewModel
        && typeof (dateDTO.valueOf()) !== 'object'
    ) {
        dateDTO = getObjectFromDate(dateDTO);
    } else if (
        dateDTO?.value
        && isViewModel
        && typeof (dateDTO?.value.valueOf()) !== 'object'
    ) {
        dateDTO.value = getObjectFromDate(dateDTO.value);
    }

    // Allows devs to pass in new Date()
    if (typeof (maxDate.valueOf()) !== 'object') {
        maxDate = getObjectFromDate(maxDate);
    }

    // Allows devs to pass in new Date()
    if (typeof (minDate.valueOf()) !== 'object') {
        minDate = getObjectFromDate(minDate);
    }

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [dateString, id, onValidate, isComponentValid]);

    useEffect(() => {
        if (_.isUndefined(dateDTO)) {
            updateDateString('');
        }
    }, [dateDTO]);

    useEffect(() => {
        // Set a default value if the value passed in is undefined
        let initialValue = dateDTO?.value;

        if (!isViewModel) {
            initialValue = dateDTO;
        }

        // defaultValue takes precedence over defaultToToday
        if (!initialValue && defaultValue && canUseDefaultValueRef.current) {
            initialValue = defaultValue;
        }

        if (!initialValue && defaultToToday && canUseDefaultValueRef.current) {
            initialValue = TODAYS_DATE_OBJECT;
        }

        if (initialValue) {
            let { day, month } = initialValue;

            // comes as int from local date dto so add leading '0' if needed
            // Ex: 1/7/2020 -> 01/07/2020
            day = day?.toString().length === 1
                ? `0${day}` : day;

            // Month on date dto starts at 0 so we need to add the padding (+1)
            month += MONTH_PADDING;
            // Need to add leading 0 to single digits
            month = month?.toString().length === 1
                ? `0${month}` : month;

            const { year } = initialValue;
            let dateStringFormatValue = `${month}${day}${year}`;

            if (isDateFormatDDMMYYYY) {
                dateStringFormatValue = `${day}${month}${year}`;
            }

            updateDateString(dateStringFormatValue);

            // If date value undefined update date dto on the parent with the default
            if (dataInitialized
                && (!dateDTO?.value && isViewModel)
                && !readOnly
                && canUseDefaultValueRef.current) {
                updateDate(initialValue);
                updateDateDto();
            }
        }

        // this should stop from using the default value on re-render
        canUseDefaultValueRef.current = false;
        setIsComponentInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataInitialized, dateDTO?.value]);

    useEffect(() => {
        if (!readOnly && !disabled && dateDTO?.value) {
            // check dateInRange only if date is Valid
            const isValidDate = isDateObjectValid(dateDTO?.value);

            if (isValidDate) {
                // Check to see if date is in valid range
                const isDateInRange = e1pDateUtil.checkIfDateIsInRange(dateDTO.value, minDate, maxDate);

                if (!isDateInRange) {
                    // Error message for date out of range
                    updateValidationMessages([getInvalidDateMessage(minDate, maxDate)]);
                } else {
                    // IAP-2456, Book Roll - Quote Effective Date,
                    // clear date in range validation message, when we change from Non Bookroll to Bookroll for future dated transactions.
                    // maxDate varies upon Bookroll to Non Bookroll.
                    // eslint-disable-next-line no-lonely-if
                    if (validationMessages.length > 0) {
                        updateValidationMessages([]);
                    }
                }
            } else {
                // date is invalid
                updateValidationMessages([translator(commonMessages.invalidDateEntered)]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxDate]);

    const writeValue = useCallback((value) => {
        updateDateString(value);

        // MMDDYYYY -> 8 chars
        const VALID_DATE_LENGTH = 8;
        const shouldUpdateDateOnTransaction = value.replaceAll('/', '')
            .replaceAll(' ', '').length === VALID_DATE_LENGTH;

        // Wait until we have a full date to update localdatedto on the parent
        if (shouldUpdateDateOnTransaction && !readOnly) {
            const PADDING_CHARACTER = '0';
            // Split into an array
            // ["MM", "DD", "YYYY"]
            const unformattedDate = value.split('/');
            // If leading 0 (ex: "01"), drop the 0 as we can't save this way to the dto
            let month = unformattedDate[0][0] === PADDING_CHARACTER
                ? unformattedDate[0][1] : unformattedDate[0];
            let day = unformattedDate[1][0] === PADDING_CHARACTER
                ? unformattedDate[1][1] : unformattedDate[1];
            const year = unformattedDate[2];

            if (isDateFormatDDMMYYYY) {
                month = unformattedDate[1][0] === PADDING_CHARACTER
                    ? unformattedDate[1][1] : unformattedDate[1];
                day = unformattedDate[0][0] === PADDING_CHARACTER
                    ? unformattedDate[0][1] : unformattedDate[0];
            }

            // Format for local date dto. Subtract 1 from month since starts at 0
            // Change from string to int, radix 10 = decimal
            const newDateObject = {
                day: parseInt(day, 10), month: parseInt(month, 10) - MONTH_PADDING, year: parseInt(year, 10)
            };
            let isDateInRange = e1pDateUtil.checkIfDateIsInRange(newDateObject, minDate, maxDate);

            // Check to see if date is in valid range
            // Added this as part of effective date validation to check if we have current minimum date
            if (currentMinDate) {
                isDateInRange = e1pDateUtil.checkIfDateIsInRange(newDateObject, currentMinDate, maxDate);
            }

            // Check to see if the date is valid
            //   third param is enforcing strict parsing
            let momentDate = moment(value, 'MM/DD/YYYY', true);

            if (isDateFormatDDMMYYYY) {
                momentDate = moment(value, 'DD/MM/YYYY', true);
            }

            // Update the state of the parent viewmodel
            updateDate(newDateObject);
            // Either of these may be undefined
            //   Added here for onValueChange functions that may want to
            //     utilize the new value and path
            updateDateDto(newDateObject, path);

            // Set error messages
            if (!momentDate.isValid()) {
                updateValidationMessages([translator(commonMessages.invalidDateEntered)]);
            } else if (!isDateInRange) {
                // Error message for date out of range
                updateValidationMessages([getInvalidDateMessage(minDate, maxDate)]);

                if (currentMinDate) {
                    updateValidationMessages([getInvalidDateMessage(currentMinDate, maxDate)]);
                }
            } else {
                // Set errors back to empty
                setComponentValidation(true, id);
                updateValidationMessages([]);
            }
        } else if (value.replaceAll('/', '')
            .replaceAll(' ', '').length > 0) {
            // Not complete so force invalid
            setComponentValidation(false, id);
        } else if (value === "") {
            // clear date when blank 
            updateDate(undefined);
            updateDateDto(undefined, path);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateDTO?.value, maxDate, minDate, updateDateDto, readOnly, currentMinDate]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        maskeDateID: {
            disabled,
            label: translator(label),
            readOnly,
            required: isRequired,
            validationMessages: (() => {
                if (additionalValidationMessages) {
                    return validationMessages.concat(additionalValidationMessages);
                }

                return validationMessages;
            })(),
            value: dateString,
            icon: showCalendarIcon ? 'mi-event' : '',
            placeholder: isDateFormatDDMMYYYY ? 'DD/MM/YYYY' : 'MM/DD/YYYY',
            showRequired: true,
            labelPosition,
            showErrors,
            className,
            visible,
            hideLabel,
            onBlur,
            tooltip
        }
    };

    const readValue = useCallback(
        (fieldID, fieldPath) => readViewModelValue(
                metadata.pageContent,
                dateString,
                fieldID,
                fieldPath,
                overrideProps
            ),
        [overrideProps, dateString]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        },
    };

    if (!isComponentInitialized) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={{ dateString }}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
        />
    );
};

E1PDateComponent.propTypes = {
    dateDTO: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
    ]),
    label: PropTypes.shape({}), // Has to be a message obj.
    updateDateDto: PropTypes.func, // updates the date on the job
    onValidate: PropTypes.func,
    id: PropTypes.string.isRequired,
    defaultValue: PropTypes.shape({}),
    minDate: PropTypes.shape({}),
    maxDate: PropTypes.shape({}),
    isRequired: PropTypes.bool,
    hideLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    additionalValidationMessages: PropTypes.arrayOf(
        PropTypes.shape({})
    ),
    showErrors: PropTypes.bool,
    defaultToToday: PropTypes.bool,
    labelPosition: PropTypes.string,
    className: PropTypes.string,
    path: PropTypes.string,
    visible: PropTypes.bool,
    showCalendarIcon: PropTypes.bool,
    onBlur: PropTypes.func,
    currentMinDate: PropTypes.shape({}),
    tooltip: PropTypes.shape({}),
    isDateFormatDDMMYYYY: PropTypes.bool
};

E1PDateComponent.defaultProps = {
    defaultValue: undefined, // TODAYS_DATE_OBJECT,
    minDate: MIN_DEFAULT_DATE,
    maxDate: MAX_DEFAULT_DATE,
    isRequired: false,
    disabled: false,
    readOnly: false,
    hideLabel: false,
    showErrors: false,
    additionalValidationMessages: [],
    defaultToToday: false,
    labelPosition: 'left',
    className: '',
    path: undefined,
    visible: true,
    onValidate: () => { },
    onBlur: undefined,
    tooltip: undefined,
    showCalendarIcon: false,
    currentMinDate: undefined,
    isDateFormatDDMMYYYY: false,
    dateDTO: undefined,
    label: undefined,
    updateDateDto: undefined
};

export default E1PDateComponent;
