import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './ThirdPartyReportComponent.metadata.json5';
import './ThirdPartyReportComponent.messages';

function ThirdPartyReportComponent(props) {
    const {
        data: reportVM,
        path,
        id,
        onValueChange,
        isPropertyLoss,
        saveOverrides,
        viewOnlyMode,
        canViewDisputeCheckbox
    } = props;
    const { onValidate: setComponentValidation } = useValidation(id);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;

        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, path]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            // All fields of Third Party Report should be read only
            readOnly: true,
            autoComplete: false
        },
        ehPropertyLoss: {
            columns: isPropertyLoss ? ['1fr', '1fr', '1fr', '1fr','1fr', '0.25fr'] : ['1fr', '1fr', '1fr','1fr', '0.25fr']
        },
        claimDate: {
            updateDateDto: reportVM.incurDate
                ? () => handleValueChange(reportVM?.incurDate, 'incurDate')
                : () => handleValueChange(reportVM?.claimDate, 'claimDate'),
            dateDTO: reportVM.incurDate ? reportVM.incurDate : reportVM.claimDate
        },
        reportOrderDate: {
            dateDTO: reportVM.reportOrderDate
        },
        usedInRating: {
            value: 'Yes'
        },
        catLoss: {
            visible: isPropertyLoss
        },
        disputeRecordId: {
            onValueChange: (value) => {
                saveOverrides(value, path.replace('reportsVM', ''));
            },
            value: reportVM.isRemoved,
            visible: canViewDisputeCheckbox,
            readOnly: viewOnlyMode
        }
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                reportVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [reportVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={reportVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            resolveValue={readValue}
        />
    );
}

ThirdPartyReportComponent.propTypes = {
    value: PropTypes.shape({}),
    data: PropTypes.shape({}),
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    isPropertyLoss: PropTypes.bool,
    saveOverrides: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    canViewDisputeCheckbox: PropTypes.bool
};
ThirdPartyReportComponent.defaultProps = {
    value: {},
    data: {},
    path: undefined,
    isPropertyLoss: false,
    viewOnlyMode: false,
    canViewDisputeCheckbox: false
};

export default ThirdPartyReportComponent;
