import { defineMessages } from 'react-intl';

export default defineMessages({
    policyLevelCoverages: {
        id: 'EaSideBySidePolicyLevelComponent.component.Policy Level Coverages',
        defaultMessage: 'Policy Level Coverages'
    },
    notApplicable: {
        id: 'EaSideBySidePolicyLevelComponent.component.Not applicable',
        defaultMessage: 'Not applicable'
    }
});
