import React, {
    useContext, useCallback, useRef } from 'react';
import { get, set } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import messages from '../UnderlyingPolicyPage.messages';
import metadata from '../UnderlyingPolicyPage.metadata.json5';

function ViewUnderlyingPolicyPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const {
        onValidate
    } = useValidation('ViewUnderlyingPolicyPage');
    const jobType = get(policyChangeVM.value, 'baseData.jobType');

    const onNext = useCallback(() => policyChangeVM, [policyChangeVM]);

    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            readOnly: true
        },
        underlyingPolicyPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.loadingNextPageMessage)
        },
        underlyingPolicyPageContainer: {
            visible: true
        },
        euUnderlyingPolicyComponent: {
            data: policyChangeVM,
            updateWizardData: () => {},
            isPageSubmitted: false,
            isPrivatePassengerQuestionVisible: false,
            viewOnlyMode: true,
            deletedItemsRef: useRef([]),
            onValidate: undefined,
            jobType
        }
    };

    const writeValue = useCallback(
        (newVal, path) => {
            set(policyChangeVM, `${path}.value`, newVal);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

ViewUnderlyingPolicyPage.propTypes = wizardProps;
export default ViewUnderlyingPolicyPage;
