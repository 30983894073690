/**
 * Using this to create a view model for the dashboard account search component
 * Needed to initialize the page
 * @param {*} viewModelService
 * @returns {ViewModel} Viewmodel to use for search criteria fields
 */
const createPolicySearchVM = (viewModelService) => {
    const policySearchVM = viewModelService.create({ contactType: 'person', country: 'US' },
        'pc', 'amfam.edge.capabilities.gateway.policy.search.dto.PolicyPeriodSearchCriteriaDTO');
    return policySearchVM;
};

export default { createPolicySearchVM };