/**
 * documentCategoryCodeToNameMap is the mapping for document Category to actual name,
 * this mapping is not defined in PC, backend only sends the document Category as string for every document
 */
const documentCategoryCodeToNameMap = new Map([
    ["billingpayments", "Billing/Payments"],
    ["communication", "Communication"],
    ["photo", "Photo"],
    ["policyinformation", "Policy Information"],
    ["policyinformationincoming", "Policy Information - Incoming"],
    ["policyinformationoutgoing", "Policy Information - Outgoing"],
    ["policypacket", "Policy Packet"],
    ["policypacketdocument", "Policy Packet Document"],
    ["additionallivingexpenseale", "Additional Living Expense - ALE"],
    ["arbitration", "Arbitration"],
    ["authorization", "Authorization"],
    ["billsandpayments", "Bills And Payments"],
    ["correspondenceincoming", "Correspondence Incoming"],
    ["correspondenceoutgoing", "Correspondence Outgoing"],
    ["coverage", "Coverage"],
    ["estimatesandrelatedphotos", "Estimates And Related Photos"],
    ["injuryreport", "Injury Report"],
    ["investigation", "Investigation"],
    ["investigationreport", "Investigation Report"],
    ["isoreport", "ISO Report"],
    ["legal", "Legal"],
    ["medicaldocumentation", "Medical Documentation"],
    ["other", "Other"],
    ["paymenttracker", "Payment Tracker"],
    ["reports", "Reports"],
    ["salvageandtotalloss", "Salvage And Total Loss"],
    ["settlement", "Settlement"],
    ["subrogation", "Subrogation"],
    ["autopaynotification", "Autopay Notification"],
    ["paymentnotification", "Payment Notification"],
    ["paymentreceipt", "Payment Receipt"],
    ["application", "Application"],
    ["quote", "Quote"],
    ["duediligence", "Due Diligence"],
    ["underwriting", "Underwriting"]
]);



/**
 * documentTypeCodeToNameMap is the mapping for typeCode to actual name,
 * this mapping is not defined in PC, backend only sends the document type as string for every document
 */
const documentTypeCodeToNameMap = new Map([
    ["addresschange", "Address Change"],
    ["affidavit", "Affidavit"],
    ["agentacknowledgement", "Agent Acknowledgement"],
    ["aledocuments", "ALE Documents"],
    ["aleinvoice", "ALE Invoice"],
    ["alereceipt", "ALE Receipt"],
    ["amfamfinaldraft", "Amfam Final Draft"],
    ["application", "Application"],
    ["applicationnewbusiness", "Application / New Business"],
    ["appraisalacv", "Appraisal/ACV"],
    ["arbitration", "Arbitration"],
    ["archive", "Archive"],
    ["assignment", "Assignment"],
    ["assignmentofbenefits", "Assignment of Benefits"],
    ["audit", "Audit"],
    ["authorizationmedical", "Authorization - Medical"],
    ["authorizationnonmedical", "Authorization - Non-Medical"],
    ["autodebit", "Auto Debit"],
    ["autopaypayment", "Autopay Payment"],
    ["autopaysetup", "Autopay Setup"],
    ["bankruptcynotice", "Bankruptcy Notice"],
    ["billcancelnotice", "Bill Cancel Notice"],
    ["billinginteractiveletter", "Billing Interactive Letter"],
    ["billingletter", "Billing Letter"],
    ["billingstatementandnotification", "Billing Statement and Notification"],
    ["bulkpayment", "Bulk Payment"],
    ["businessincome", "Business Income"],
    ["californiaearthquake", "California Earthquake"],
    ["cancel", "Cancel"],
    ["cancellationrequest", "Cancellation Request"],
    ["certifiedmailreceipt", "Certified Mail Receipt"],
    ["certifiedpolicy", "Certified Policy"],
    ["check", "Check"],
    ["checkdraft", "Check/Draft"],
    ["claimiqliability", "ClaimIQ - Liability"],
    ["claimnotes", "Claim Notes"],
    ["claims", "Claims"],
    ["complaint", "Complaint"],
    ["constructedbill", "Constructed Bill"],
    ["consumerreport", "Consumer Report"],
    ["consumerreportnotice", "Consumer Report Notice"],
    ["contentslist", "Contents List"],
    ["correspondenceoutgoing", "Correspondence - Outgoing"],
    ["correspondencereceived", "Correspondence Received"],
    ["countersignature", "Counter Signature"],
    ["coverage", "Coverage"],
    ["coverageselectionrejection", "Coverage Selection/Rejection"],
    ["coveragesummary", "Coverage Summary"],
    ["creditcardexpiration", "Credit Card Expiration"],
    ["customerauthorization", "Customer Authorization"],
    ["deathcertificate", "Death Certificate"],
    ["declaration", "Declaration"],
    ["decpage", "Dec Page"],
    ["demandpacket", "Demand Packet"],
    ["demandpacketlessmedicalbills", "Demand Packet Less Medical Bills"],
    ["demandpacketoutgoing", "Demand Packet - Outgoing"],
    ["denial", "Denial"],
    ["diagram", "Diagram"],
    ["diminishedvalue", "Diminished Value"],
    ["directiontopay", "Direction To Pay"],
    ["duediligence", "Due Diligence"],
    ["email", "Email"],
    ["emailincoming", "Email - Incoming"],
    ["emailoutgoing", "Email - Outgoing"],
    ["endorsement", "Endorsement"],
    ["endorsementcerttrans", "Endorsement Cert Trans"],
    ["eob", "EOB"],
    ["esigneddocument", "E-signed Document"],
    ["estimate", "Estimate"],
    ["estimatesandrelatedphotos", "Estimates And Related Photos"],
    ["evidenceofinsurance", "Evidence of Insurance"],
    ["examinationunderoath", "Examination Under Oath"],
    ["expertreports", "Expert Reports"],
    ["financialresponsibility", "Financial Responsibility"],
    ["fnol", "FNOL"],
    ["generalcorrespondence", "General Correspondence"],
    ["generalinformation", "General Information"],
    ["generalletter", "General Letter"],
    ["generallettersensitive", "General Letter - Sensitive"],
    ["householdcorrespondence", "Household Correspondence"],
    ["idcard", "ID Card"],
    ["imereport", "IME Report"],
    ["inspection", "Inspection"],
    ["inspectionreport", "Inspection Report"],
    ["installmentagreement", "Installment Agreement"],
    ["insuredscopies", "Insured's Copies"],
    ["integration", "Integration"],
    ["internetendorsement", "Internet Endorsement"],
    ["isoreports", "ISO Reports"],
    ["itelreport", "ITEL Report"],
    ["largelossreport", "Large loss report"],
    ["leaserentalagreement", "Lease/Rental Agreement"],
    ["legal", "Legal"],
    ["legalarbitration", "Legal - Arbitration"],
    ["legalcorrespondence", "Legal - Correspondence"],
    ["legaldepositions", "Legal - Depositions"],
    ["legaldiscovery", "Legal - Discovery"],
    ["legallitigation", "Legal - Litigation"],
    ["legalmedicals", "Legal - Medicals"],
    ["legalopinion", "Legal - Opinion"],
    ["legalpleadings", "Legal - Pleadings"],
    ["legalstatusreports", "Legal - Status Reports"],
    ["legalsuitsandcourtdocuments", "Legal - Suits And Court Documents"],
    ["legalsummonsandcomplaints", "Legal - Summons and Complaints"],
    ["letter", "Letter"],
    ["letterofguarantee", "Letter of Guarantee"],
    ["lien", "Lien"],
    ["lienholderchange", "Lienholder Change"],
    ["lienrelease", "Lien Release"],
    ["lossofrent", "Loss Of Rent"],
    ["medicalbillambulance", "Medical Bill - Ambulance"],
    ["medicalbillhcfa1500", "Medical Bill - HCFA 1500"],
    ["medicalbillother", "Medical Bill - Other"],
    ["medicalbillprescription", "Medical Bill - Prescription"],
    ["medicalbillreview", "Medical Bill Review"],
    ["medicalbillub04", "Medical Bill - UB 04"],
    ["medicalletter", "Medical Letter"],
    ["medicalrecord", "Medical Record"],
    ["medicalreport", "Medical Report"],
    ["medicalservices", "Medical Services"],
    ["medicarenotice", "Medicare Notice"],
    ["medpaylog", "Med Pay Log"],
    ["miscellaneous", "Miscellaneous"],
    ["miscother", "Misc/Other"],
    ["mold", "Mold"],
    ["nonpay", "Non-Pay"],
    ["nonwaiver", "Non-Waiver"],
    ["notice", "Notice"],
    ["noticeofcerttrans", "Notice of Cert Trans"],
    ["noticeofinsured", "Notice of Insured"],
    ["noticestatisticalcoding", "Notice Statistical Coding"],
    ["oralauthorizationletter", "Oral Authorization Letter"],
    ["orangecard", "Orange Card"],
    ["paymentletter", "Payment Letter"],
    ["paymentreceipts", "Payment Receipts"],
    ["paymentschedule", "Payment Schedule"],
    ["photos", "Photos"],
    ["pipapplication", "PIP Application"],
    ["pipdemand", "PIP Demand"],
    ["pipdemanddocs", "PIP Demand Docs"],
    ["pipdemandresponse", "PIP Demand Response"],
    ["pipfilenotes", "PIP File Notes"],
    ["piplog", "PIP Log"],
    ["policefirereport", "Police/Fire Report"],
    ["policy", "Policy"],
    ["policydocuments", "Policy Documents"],
    ["policyinteractiveletter", "Policy Interactive Letter"],
    ["policyletter", "Policy Letter"],
    ["policynotes", "Policy Notes"],
    ["policysummary", "Policy Summary"],
    ["powerofattorney", "Power Of Attorney"],
    ["proofofinsurance", "Proof of Insurance"],
    ["proofofloss", "Proof Of Loss"],
    ["publicadjusterrepresentationletter", "Public Adjuster Representation Letter"],
    ["questionnaire", "Questionnaire"],
    ["quote", "Quote"],
    ["qwikpayforagentuseonly", "Qwik Pay (For Agent Use Only)"],
    ["rbiletter", "RBI Letter"],
    ["rcv", "RCV"],
    ["receipt", "Receipt"],
    ["receiptinvoice", "Receipt/Invoice"],
    ["recordedstatement", "Recorded Statement"],
    ["recovery", "Recovery"],
    ["referral", "Referral"],
    ["rehabilitation", "Rehabilitation"],
    ["reinspection", "Reinspection"],
    ["reinstatement", "Reinstatement"],
    ["rejectionreport", "Rejection Report"],
    ["release", "Release"],
    ["renewal", "Renewal"],
    ["repairestimateletter", "Repair Estimate Letter"],
    ["report", "Report"],
    ["reportletter", "Report Letter"],
    ["reservationofrights", "Reservation Of Rights"],
    ["residencyverification", "Residency Verification"],
    ["returnedmail", "Returned Mail"],
    ["rewrites", "Rewrites"],
    ["riskevaluation", "Risk Evaluation"],
    ["roofevaluationreport", "Roof Evaluation Report"],
    ["salvagelog", "Salvage Log"],
    ["schedule", "Schedule"],
    ["signedform", "Signed Form"],
    ["siudocuments", "SIU-Documents"],
    ["statechangeendorsement", "State Change Endorsement"],
    ["statecompliance", "State Compliance"],
    ["statementletter", "Statement Letter"],
    ["statementother", "Statement-Other"],
    ["statementtranscribed", "Statement-Transcribed"],
    ["subrogationdemand", "Subrogation Demand"],
    ["subrogationdocuments", "Subrogation Documents"],
    ["subrogationrecovery", "Subrogation Recovery"],
    ["supportingdocumentation", "Supporting Documentation"],
    ["theftpacket", "Theft Packet"],
    ["thirdpartyinteresttpi", "Third-Party Interest(TPI)"],
    ["thirdpartysettlement", "Third Party Settlement"],
    ["title", "Title"],
    ["totallosssettlement", "Total Loss Settlement"],
    ["underwriting", "Underwriting"],
    ["uw", "UW"],
    ["uwdocument", "UW Document"],
    ["vehicleinspection", "Vehicle Inspection"],
    ["vehiclerelated", "Vehicle Related"],
    ["vendorinvoice", "Vendor Invoice"],
    ["vendorreport", "Vendor Report"],
    ["video", "Video"],
    ["wageauthorization", "Wage Authorization"],
    ["wagestatement", "Wage Statement"],
    ["wageverification", "Wage Verification"],
    ["watermitigation", "Water Mitigation"],
    ["withdrawalofclaimdocumenttype", "Withdrawal Of Claim Document Type"],
    ["worksheet", "Worksheet"]
]);

export { 
    documentTypeCodeToNameMap, 
    documentCategoryCodeToNameMap 
};