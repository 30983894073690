import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withModalContext } from '@jutro/components';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { CancellationService } from 'gw-capability-gateway';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import gatewayMessages from '../../gateway.messages';
import metadata from './Summary.metadata.json5';
import messages from '../Cancellation.messages';
import styles from './Summary.module.scss';

const productMap = {
    PersonalAuto_EA: 'personalAuto_EA',
    Homeowners_EH: 'homeowners_EH',
    PersonalUmbrella_EU: 'personalUmbrella_EU'
};

class CancellationSummaryWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }),
        authUserData: PropTypes.shape({
            userType: PropTypes.string.isRequired,
            permissions_Ext: PropTypes.arrayOf(PropTypes.string)
        }),
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                loadCancellationSummary: PropTypes.shape({}).isRequired,
                updateJobSummary: PropTypes.func.isRequired
            })
        }).isRequired,
        intl: PropTypes.shape({}),
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired
    };

    static defaultProps = {
        authHeader: undefined,
        authUserData: undefined,
        intl: undefined
    }

    state = {
        isLoading: true
    };

    getCancellationStatus = (status) => {
        const translator = this.context;

        return translator({ id: `typekey.PolicyPeriodStatus.${status}`, defaultMessage: status });
    };

    getCancellationSummary = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                quoteDetailsData: { jobNumber, updateJobSummary }
            }
        } = this.props;
        const cancellation = await CancellationService.findJobByJobNumber(jobNumber, authHeader);

        updateJobSummary(cancellation);
    };

    /**
    * E1P custom change - Plans to migrate to customer folder in future
    * This function takes us to the custom e1p cancellation wizard
    */

    goToCancellationWizard = () => {
        const { history } = this.props;
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadCancellationSummary: cancellationVM
                }
            }
        } = this.props;
        const { policyNumber } = cancellationVM.policy.latestPeriod;
        const { jobNumber } = cancellationVM;
        const routeParam = { policyNumber, jobNumber };

        return history.push('/cancel',
            { routeParam });
    }

    onWithdrawCancellation = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadCancellationSummary: cancellationVM }
            },
            authHeader
        } = this.props;

        this.props.modalContext.showConfirm({
            title: messages.withdrawCancellation,
            message: messages.wantWithdrawCancellation,
            confirmButtonText: messages.withdrawCancellation,
            cancelButtonText: messages.doNotWithdraw
        }).then((results) => {
            if (results === 'confirm') {
                CancellationService.withdrawCancellationByCancellationNumber(
                    cancellationVM.jobNumber,
                    authHeader
                ).then(
                    () => {
                        this.getCancellationSummary();
                    },
                    () => {
                        this.props.modalContext.showAlert({
                            title: gatewayMessages.modalError,
                            message: messages.failedWithDrawCancellation,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.close
                        }).catch(_.noop);
                    }
                );
            }
        }, _.noop);
    };

    getNotificationContent = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadCancellationSummary: cancellationVM }
            },
            authUserData
        } = this.props;
        const content = {};

        const isAgent = authUserData.userType === 'producer';

        if (
            cancellationVM
        ) {
            if (cancellationVM.status === this.getCancellationStatus('Withdrawn')) {
                content.infoMessageTitle = translator(messages.cancellationHasBeenWithDrawn);
                content.infoMessageDescription = translator(
                    messages.needToCancelStartNewCancellation
                );
            } else if (cancellationVM.status === this.getCancellationStatus('Bound')) {
                content.infoMessageTitle = translator(messages.cancellationHasBeenWithBound);
            } else if (cancellationVM.status === this.getCancellationStatus('Canceling')) {
                content.infoMessageTitle = translator(messages.policyScheduledCancellation);
                content.infoMessageDescription = translator(messages.needRescindCancellation);
                
                if (isAgent && cancellationVM.cancelReasonCode === 'nonpayment') {
                    content.infoMessageDescription = '';
                }
            } else if (cancellationVM.isInsuredSource) {
                content.infoMessageTitle = cancellationVM.isRefund
                    ? translator(messages.cancellationAmountToBeCalculated)
                    : translator(messages.stillOwedHasBeenCalculated);
                content.infoMessageDescription = cancellationVM.canWithdraw
                    && cancellationVM.canBind
                    ? translator(messages.bindActivitiesNotesDocument)
                    : translator(messages.withDrawActivitiesNotesDocument);
            } else {
                // This else condition is for quoted status and draft status
                content.infoMessageTitle = translator(messages.cancellationInitiatedByInsurer);

                if (isAgent && cancellationVM.cancelReasonCode === 'nonpayment') {
                    content.infoMessageDescription = '';
                } else {
                    content.infoMessageDescription = isAgent
                        ? translator(messages.canNotBindOrWithDrawButCanAddInfo)
                        : (content.infoMessageDescription = translator(
                            messages.canBindCancelCannotWithdraw
                        ));
                }
            }

            if (!cancellationVM.canBind && !cancellationVM.canWithdraw) {
                content.infoMessageDescription = '';
            }
        }

        return {
            infoMessageTitle: content.infoMessageTitle,
            infoMessageDescription: content.infoMessageDescription,
            withDrawContent: translator(messages.withdrawCancellation),
            continueContent: translator(messages.continueCancellation)
        };
    };

    getCancellationToProceed = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadCancellationSummary: cancellationVM }
            },
            authUserData
        } = this.props;

        const isAgent = authUserData.userType === 'producer';

        return {
            isContinueTransaction: cancellationVM.canBind && (!isAgent || cancellationVM.cancelReasonCode !== 'nonpayment'),
            isWithdrawTransaction: cancellationVM.canWithdraw
        };
    };

    getPolicyLinkVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadCancellationSummary: cancellationVM }
            }
        } = this.props;

        return (
            !_.isEmpty(cancellationVM.latestPeriod)
            && cancellationVM.latestPeriod.policyNumber
            && cancellationVM.latestPeriod.policyNumber !== 'Unassigned'
        );
    };

    getRefundInfo = (cancellation) => {
        const translator = this.context;
        const labelInfo = cancellation.isRefund ? messages.refundAmount : messages.stillOwed;
        const valueInCost = cancellation.changeInCost;

        if (cancellation.isRefund && valueInCost.amount < 0) {
            valueInCost.amount *= -1;
        }

        return {
            label: translator(labelInfo),
            value: valueInCost
        };
    };

    getRefundMethod = (cancellation) => {
        const translator = this.context;
        const { intl } = this.props;
        const policyEffectiveDate = intl.formatDate(new Date(_.get(cancellation, 'policy.latestPeriod.effectiveDate')),
            {
                year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC'
            });
        const policyCancellationDate = intl.formatDate(new Date(_.get(cancellation, 'latestPeriod.cancellationDate')),
            {
                year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC'
            });

        // refundMethod should be Flat when Cancellation
        // effective is the same as the Submission effective date
        const refundMethod = policyEffectiveDate === policyCancellationDate
            ? translator({
                id: 'typekey.CalculationMethod.flat',
                defaultMessage: 'Flat'
            })
            : translator({
                id: `typekey.CalculationMethod.${cancellation.cancellationRefundMethod}`,
                defaultMessage: cancellation.cancellationRefundMethod
            });

        return refundMethod;
    };

    render() {
        const translator = this.context;
        const { isLoading } = this.state;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadCancellationSummary: cancellationVM }
            },
            intl,
            authUserData
        } = this.props;

        if (_.isEmpty(cancellationVM)) {
            return <E1PLoader loaded={!isLoading} />;
        }

        const overrides = {
            quoteNotification: {
                notificationContent: this.getNotificationContent(),
                transactionVisibleActions: this.getCancellationToProceed()
            },
            underwritingTable: {
                data: cancellationVM.underwritingIssues
            },
            policyInfoLink: {
                visible: this.getPolicyLinkVisible()
            },
            detailGridTotalColumn: {
                visible: cancellationVM.statusCode === 'Quoted'
            },
            cancellationReason: {
                value: translator({
                    id: `typekey.ReasonCode.${cancellationVM.cancelReasonCode}`,
                    defaultMessage: cancellationVM.cancelReasonCode
                })
            },
            cancellationSource: {
                value: translator({
                    id: `typekey.CancellationSource.${cancellationVM.cancellationSource}`,
                    defaultMessage: cancellationVM.cancellationSource
                })
            },
            cancellationRefund: {
                value: this.getRefundMethod(cancellationVM)
            },
            policyExpirationId: {
                value: intl.formatDate(new Date(_.get(cancellationVM, `policy.lobs[${productMap[cancellationVM.productCode]}].expirationDate`)),
                    {
                        year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC'
                    })
            },
            policyEffeciveDateId: {
                value: intl.formatDate(new Date(_.get(cancellationVM, 'policy.latestPeriod.effectiveDate')),
                    {
                        year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC'
                    })
            },
            refundAmountDetails: this.getRefundInfo(cancellationVM),
            deliverDocumentsIndComponent: {
                visible: authUserData.permissions_Ext.includes('docdeliveryind_ext'),
                // eslint-disable-next-line no-secrets/no-secrets
                deliverDocInd: _.get(cancellationVM, 'shouldDeliverDocuments_Ext', true),
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                setDeliverDocInd: () => { },
                viewOnlyMode: true
            },
            preemptionInfoMessageDiv: {
                visible: _.get(
                    cancellationVM,
                    'hasPreemptions_Ext',
                    false
                )
            },
            // IAP-5583: Backend has two different fields for description based on cancellation Source i.e Company or Insured
            cancellationDescriptionId: {
                value: _.get(cancellationVM, 'description', _.get(cancellationVM, 'uwactionDTO_Ext.reasonDescription'))
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onContinueQuote: this.goToCancellationWizard,
                onWithdrawTransaction: this.onWithdrawCancellation
            }
        };

        const readValue = (id, path) => readViewModelValue(metadata.pageContent, cancellationVM, id, path, overrides);

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={cancellationVM}
                overrideProps={overrides}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

const CancellationSummary = withModalContext(CancellationSummaryWithoutModalContext);

export default withIntl(withAuthenticationContext(CancellationSummary));
