import { defineMessages } from 'react-intl';

export default defineMessages({
    thirdPartyReportClaimDate: {
        id: 'components.thirdPartyReports.Date',
        defaultMessage: 'Date'

    },
    thirdPartyReportCode: {
        id: 'components.thirdPartyReports.Code',
        defaultMessage: 'Code'
    },
    thirdPartyReportDescription: {
        id: 'components.thirdPartyReports.Description',
        defaultMessage: 'Description'
    },
    thirdPartyReportAmount: {
        id: 'components.thirdPartyReports.Amount',
        defaultMessage: 'Amount'
    },
    thirdPartyReportSource: {
        id: 'components.thirdPartyReports.Source',
        defaultMessage: 'Source'
    },
    thirdPartyReportUsedInRating: {
        id: 'components.thirdPartyReports.Used in Rating',
        defaultMessage: 'Used in Rating'
    },
    cat: {
        id: 'components.thirdPartyReports.CAT',
        defaultMessage: 'CAT'
    },
    disputeLabel: {
        id: 'components.thirdPartyReports.Dispute',
        defaultMessage: 'Dispute'
    }
});
