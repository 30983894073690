import React, {
    useCallback,
    useEffect,
    useState
} from 'react';
import PropTypes from 'prop-types';
import {
    get, set, includes, isEmpty
} from 'lodash';
import htmlParser from 'html-react-parser';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { E1PPropertyPageSectionHeaderComponent } from 'e1p-capability-policyjob-react';
import { PropertyFlowUtil } from 'e1p-portals-util-js';
import { commonMessages as  e1pCommonMessages, ehCommonMessages } from 'e1p-platform-translations';
import metadata from './EHHO4PropertyDetailsComponent.metadata.json5';
import styles from './EHHO4PropertyDetailsComponent.module.scss';
import requiredMetadata from './EHHO4PropertyDetailsComponent.requiredness';

const SECTION_STATUS = {
    SUCCESS: 'success',
    FAILURE: 'failure',
    NONE: 'none'
};

function EHHO4PropertyDetailsComponent(props) {
    const {
        onValidate,
        transactionVM,
        updateWizardData,
        isPageSubmitted,
        setShouldSkipAdditionalInfo,
        isSavingCurrentPageChanges,
        isSkipping,
        isSavingEndorsement,
        viewOnly
    } = props;
    const translator = useTranslator();
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [animalStatus, updateAnimalStatus] = useState(SECTION_STATUS.NONE);
    const [exteriorStatus, updateExteriorStatus] = useState(SECTION_STATUS.NONE);
    const [propertyInformationStatus, updatePropertyInformationStatus] = useState(SECTION_STATUS.NONE);
    // Get Policy State
    const policyState = get(transactionVM, 'baseData.policyAddress.state.value.code') || get(transactionVM, 'policyAddress.state.value.code');
    const [requiredFields, updateRequiredFields] = useState([]);
    const [areAllRequiredFieldsPresent, setAreAllRequiredFieldsPresent] = useState(false);
    const [expandedSections, setExpandedSections] = useState([
        'propertyInformationAccordionCard',
        'animalsAccordionCard',
        'exteriorAccordionCard'
    ]);

    const renderAccordionHeader = (title, status) => (isOpen) => (
            <E1PPropertyPageSectionHeaderComponent
                isOpen={isOpen}
                title={title}
                status={status}
            />
        );

    useEffect(() => {
        /**
         * IAP-3362 : HO4 & HO6-Unable to Navigate from Coverages Screen
         * As we are deciding to finish wizard flow on coverage page based on shouldSkipAdditionalInfo
         * We need to set this value to true for HO4
         */
        setShouldSkipAdditionalInfo(true);

        if (isEmpty(transactionVM.lobData.homeowners_EH.coverables.yourHome.value)) {
            transactionVM.lobData.homeowners_EH.coverables.yourHome = {};
            updateWizardData(transactionVM);
        }

        if (isEmpty(transactionVM.lobData.homeowners_EH.coverables.construction.value)) {
            transactionVM.lobData.homeowners_EH.coverables.construction = {};
            updateWizardData(transactionVM);
        }

        setIsPageInitialized(true);
        updateWizardData(transactionVM);

        const allRequiredFieldsPresent = PropertyFlowUtil.checkAllRequiredFieldsArePresentHO4(
            transactionVM
        );

        setAreAllRequiredFieldsPresent(allRequiredFieldsPresent);

        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const usageType = get(transactionVM, 'lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value');
        const householdOccupants = get(transactionVM, 'lobData.homeowners_EH.coverables.householdOccupants.value');

        set(transactionVM, 'lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value', usageType);
        set(transactionVM, 'lobData.homeowners_EH.coverables.householdOccupants.value', householdOccupants);
        updateWizardData(transactionVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPageInitialized]);

    useEffect(
        () => {
            const initialRequiredFields = [
                'numberOfUnits',
                'hasDogInHome',
                'highRiskDogBreedInd',
                'extereriorWallFinish',

            ]; // Fields to look up by partner/state

            updateRequiredFields(
                isRequired(
                    initialRequiredFields,
                    requiredMetadata,
                    policyState,
                    'MSA'
                )
            );
            // When policystate changes update the required fields
        }, [policyState]
    );

    useEffect(
        () => {
            if (transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== '') {
                updatePropertyInformationStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || propertyInformationStatus !== SECTION_STATUS.NONE) {
                updatePropertyInformationStatus(SECTION_STATUS.FAILURE);
            }
        },
        [isPageSubmitted, propertyInformationStatus, transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value]
    );

    useEffect(
        () => {
            if (transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value !== '') {
                updateExteriorStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || exteriorStatus !== SECTION_STATUS.NONE) {
                updateExteriorStatus(SECTION_STATUS.FAILURE);
            }
        },
        [isPageSubmitted, exteriorStatus, transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value]
    );

    useEffect(
        () => {
            const isHighRiskDogBreedRequired = includes(requiredFields, 'highRiskDogBreedInd');
            const isHightRiskDogBreedAnswered = transactionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value !== undefined
            && transactionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value !== '';
            const isAnimalBittenAnswered = transactionVM.lobData.homeowners_EH.coverables.yourHome.hasAnimalBitten.value !== undefined
            && transactionVM.lobData.homeowners_EH.coverables.yourHome.hasAnimalBitten.value !== '';

            // dogInHome is false
            if (transactionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === false) {
                updateAnimalStatus(SECTION_STATUS.SUCCESS);
            }
            // dogInHome is true
            else if (transactionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === true) {
                // highRiskdog and animalBitten is visible
                if (isHighRiskDogBreedRequired && isHightRiskDogBreedAnswered && isAnimalBittenAnswered) {
                    updateAnimalStatus(SECTION_STATUS.SUCCESS);
                }
                // highRiskdog is not visible and animalBitten is visible
                else if (!isHighRiskDogBreedRequired && isAnimalBittenAnswered) {
                    updateAnimalStatus(SECTION_STATUS.SUCCESS);
                }
                else if (isPageSubmitted || animalStatus !== SECTION_STATUS.NONE) {
                    updateAnimalStatus(SECTION_STATUS.FAILURE);
                }
            }
        }, [animalStatus, isPageSubmitted, requiredFields, transactionVM.lobData.homeowners_EH.coverables.yourHome.hasAnimalBitten.value, transactionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value, transactionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value]
    );

    const dogInHomeChange = useCallback(
        (value) => {
            set(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome', value);

            if (!value) {
                set(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd', undefined);
                set(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasAnimalBitten', undefined);
            }

            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData]
    );
    
    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
        resolveClassNameMap: styles
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false,
            readOnly: viewOnly
        },
        propertyPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(ehCommonMessages.loadingNextPageMessage)
        },
        propertyPageContainer: {
            visible: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges
        },
        numberOfUnits: {
            required: includes(requiredFields, 'numberOfUnits')
        },
        dogInHome: {
            required: includes(requiredFields, 'hasDogInHome'),
            onValueChange: dogInHomeChange,
            className: viewOnly ? 'toggleFieldReadOnly' : 'toggleField'
        },
        exteriorWallFinish: {
            required: includes(requiredFields, 'extereriorWallFinish')
        },
        dogBreedSet1: {
            content: htmlParser(translator(ehCommonMessages.akitaAndPitbull))
        },
        dogBreedSet2: {
            content: htmlParser(translator(ehCommonMessages.alaskanAndRottweiler))
        },
        dogBreedSet3: {
            content: htmlParser(translator(ehCommonMessages.chowChowAndStaffordshire))
        },
        dogBreedSet4: {
            content: htmlParser(translator(ehCommonMessages.doberman))
        },
        highRiskDogBreedIndGridWithSeprator: {
            visible: includes(requiredFields, 'highRiskDogBreedInd')
            && !!get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
        },
        highRiskDogBreedInd: {
            required: includes(requiredFields, 'highRiskDogBreedInd')
            && !!get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
            className: viewOnly ? 'toggleFieldReadOnly' : 'toggleField'
        },
        doesAnyDogHaveAnyBiteHistorydGridWithSeprator: {
            visible: !!get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
        },
        doesAnyDogHaveAnyBiteHistory: {
            required: !!get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
            className: viewOnly ? 'toggleFieldReadOnly' : 'toggleField'
        },
        animalsAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.animals, animalStatus)
        },
        exteriorAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.exterior, exteriorStatus),
            visible:  includes(requiredFields, 'extereriorWallFinish')
        },
        propertyInformationAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.propertyInformation, propertyInformationStatus)
        },
        Accordion: {
            accordionStates: expandedSections,
            onUpdateAccordionStates: (openedSections) => {
                setExpandedSections(openedSections);
            }
        },
        pleaseProvideInfoOnPropertyDiv: {
            visible: !areAllRequiredFieldsPresent && !viewOnly
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                transactionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, transactionVM]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionVM}
            resolveValue={readValue}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={onValidate}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

EHHO4PropertyDetailsComponent.propTypes = {
    onValidate:  PropTypes.func.isRequired,
    transactionVM:PropTypes.shape({}).isRequired,
    updateWizardData:  PropTypes.func.isRequired,
    isPageSubmitted: PropTypes.bool,
    setShouldSkipAdditionalInfo: PropTypes.func.isRequired,
    isSavingCurrentPageChanges: PropTypes.bool,
    isSkipping: PropTypes.bool,
    isSavingEndorsement: PropTypes.bool,
    viewOnly: PropTypes.bool
};

export default EHHO4PropertyDetailsComponent;
