import React, {
    useCallback, useState, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import { ModalNext, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import styles from './AccountsFoundComponent.module.scss';
import metadata from './AccountsFoundComponent.metadata.json5';
import './AccountsFoundComponent.messages';


import { Button } from '@jutro/legacy/components';


function AccountsFoundComponent(props) {
    const {
        accountsVM,
        isOpen,
        onResolve,
        onReject
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        registerInitialComponentValidation
    } = useValidation(
        'AccountsFoundComponent'
    );

    const [accountData, updateAccountData] = useState(accountsVM);

    // User shouldn't be able to click use account button if they havent selected one
    useEffect(() => {
        registerInitialComponentValidation(() => !!accountData.accountToUse);
        // Run on mount only (empty deps)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value) => {
            const nextFormData = _.cloneDeep(accountData);

            _.set(nextFormData, 'accountToUse', accountData.find((element) => element.tempID === value));
            updateAccountData(nextFormData);
        },
        [accountData]
    );

    const possibleAccountChoices = useMemo(() => {
        const availableValueArray = [];
        let index = 0;

        accountData.forEach((accountVM) => {
            const middleName = accountVM.accountHolder.person.middleName ? accountVM.accountHolder.person.middleName : '';
            const displayName = `${accountVM.accountHolder.person.firstName} ${middleName} ${accountVM.accountHolder.person.lastName}`;
            const tempObj = {};

            tempObj.displayName = `${displayName} ${accountVM.accountHolder.person.dateOfBirth.month + 1}/${accountVM.accountHolder.person.dateOfBirth.day}/${accountVM.accountHolder.person.dateOfBirth.year}`;
            tempObj.id = `${accountVM.accountHolder.person.firstName}${accountVM.accountHolder.person.lastName}${index}`;

            const addressLine2 = accountVM.accountHolder.person.primaryAddress.addressLine2 ? accountVM.accountHolder.person.primaryAddress.addressLine2 : '';

            tempObj.secondaryLabel = `${accountVM.accountHolder.person.primaryAddress.addressLine1} ${addressLine2} ${accountVM.accountHolder.person.primaryAddress.city}, ${accountVM.accountHolder.person.primaryAddress.state} ${accountVM.accountHolder.person.primaryAddress.postalCode}`;
            index += 1;
            _.set(accountVM, 'tempID', tempObj.id);
            availableValueArray.push(tempObj);
        });

        // Create new account needs to be an option
        const createNewAccount = {
            displayName: 'Create New Account',
            id: 'createNewLabel',
            tempID: 'createNewLabel'
        };

        accountData.push(createNewAccount);
        updateAccountData(accountData);

        if (availableValueArray.length > 0) {
            availableValueArray.push(createNewAccount);
        }

        return availableValueArray;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isNoPossibleAccountFound = possibleAccountChoices.length === 0;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        '@element': {
            className: 'messageStyle'
        },
        accountOptionRadio: {
            availableValues: possibleAccountChoices,
            className: 'messageStyle'
        },
        noAccountFoundSubtitle: {
            visible: isNoPossibleAccountFound,
        },
        accountsFoundSubtitle: {
            visible: !isNoPossibleAccountFound,
        }
    };

    useEffect(() => {
        if (isNoPossibleAccountFound) {
            writeValue('createNewLabel');
        }
        // When possibleAccountChoices is generated, create new account
        // will be implicitly selected if it is the only choice
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [possibleAccountChoices]);

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                accountData,
                id,
                path,
                overrideProps
            ),
        [overrideProps, accountData]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
    };

    const onOk = useCallback(
        () => {
            if (!isComponentValid) {
                return false;
            }

            const wrapperObj = {
                chosenAccount: accountData.accountToUse
            };

            return onResolve(wrapperObj);
        }, [isComponentValid, accountData.accountToUse, onResolve]
    );

    const onCancel = useCallback(() => onReject(), [onReject]);

    if (!possibleAccountChoices) {
        // account choices not generated yet
        return undefined;
    }

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onOk}
            className={styles.smallPopUp}
        >
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={accountData}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    onValidationChange={setComponentValidation}
                />
            </ModalBody>
            <ModalFooter>
                <Button type="outlined" onClick={onCancel}>
                    Cancel
                </Button>
                {
                    isNoPossibleAccountFound
                    && (
                        <Button disabled={!isComponentValid} onClick={onOk}>
                            Create New Account
                        </Button>
                    )
                }
                {
                    !isNoPossibleAccountFound
                    && (
                        <Button disabled={!isComponentValid} onClick={onOk}>
                            Submit
                        </Button>
                    )
                }
            </ModalFooter>
        </ModalNext>
    );
}

AccountsFoundComponent.propTypes = {
    accountsVM: PropTypes.arrayOf(
        PropTypes.shape({})
    ).isRequired,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    onReject: PropTypes.func
};
export default AccountsFoundComponent;
