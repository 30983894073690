import React, {
    useCallback,
    useEffect, useState,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import {
    get, set, isEqual, isEmpty, differenceWith
} from 'lodash';
import metadata from './SupportingPolicyComponent.metadata.json5';
import SupportingPolicyModal from './SupportingPolicyModal/SupportingPolicyModal';

function SupportingPolicyComponent(props) {
    const modalApi = useModal();
    const {
        transactionVM,
        LOB,
        updateWizardData,
        viewOnly,
        setFieldsChangedOnCoveragePage,
        tooltip,
        authHeader
    } = props;
    const [isSupportingPolicy, setIsSupportingPolicy] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);

    useEffect(() => {
        const policies = get(transactionVM, `lobData.${LOB}.associatedPolicies`, []);
        const movingInPolicies = get(transactionVM, `lobData.${LOB}.movingInPolicies`, []);

        if (policies.length > 0 || movingInPolicies.length > 0) {
            setIsSupportingPolicy(true);
        }
    }, [LOB, transactionVM]);

    const hasSupportingPolicy = useCallback(() => {
        const policies = get(transactionVM, `lobData.${LOB}.associatedPolicies`, []);
        const movingInPolicies = get(transactionVM, `lobData.${LOB}.movingInPolicies`, []);

        if (policies.length > 0 || movingInPolicies.length > 0) {
            return true;
        }

        return false;
    }, [LOB, transactionVM]);

    // there's no reason for this to use memoization
    const hasSystematicPolicy = (() => {
        const associatedPolicies = get(transactionVM, `lobData.${LOB}.associatedPolicies`, []);
        const movingInPolicies = get(transactionVM, `lobData.${LOB}.movingInPolicies`, []);
        const systematicAssociatedPolicies = associatedPolicies.filter((pol) => get(pol, 'value.policySource') === 'Systematic');
        const systematicMovingInPolicies = movingInPolicies.filter((pol) => get(pol, 'value.source') === 'Systematic');

        if (systematicAssociatedPolicies.length > 0 || systematicMovingInPolicies.length > 0) {
            return true;
        }

        return false;
    })();

    const showSupportingPolicyModal = useCallback(
        () => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: true,
                showCancelBtn: true,
                transactionVM,
                path: `lobData.${LOB}.associatedPolicies`,
                LOB,
                viewModelService,
                viewOnly,
                updateWizardData,
                authHeader
            };

            return modalApi.showModal(
                <SupportingPolicyModal {...componentProps} />
            );
        },
        [LOB, transactionVM, viewModelService, viewOnly, updateWizardData, authHeader, modalApi]
    );

    const formatMovingInPolicies = useCallback(
        (filteredMovingInPolicies) =>
            filteredMovingInPolicies.map(movingInPolicy => ({
                policyType: get(movingInPolicy, 'policyType'),
                policyNumber: get(movingInPolicy, 'policyNumber'),
                source: get(movingInPolicy, 'policySource'),
                fixedId: get(movingInPolicy, 'fixedId'),
                publicId: get(movingInPolicy, 'publicId'),
            })),
        []
    );

    /**
     * Moving In Discount is only applicable for HO3, so we need to filter out
     * movingInPolicies from associated policies only when policyType is HO3
     */
    const filterMoveInPoliciesFromAssociatedPolices = useCallback((associatedPoliciesObj) => {
        if (LOB === 'homeowners_EH'
        && get(transactionVM, 'lobData.homeowners_EH.policyType.value.code',
            get(transactionVM, 'value.policyType')) === 'HO3') {
            const filteredMovingInPolicies = associatedPoliciesObj.filter(
                (associatedPolicy) =>
                    associatedPolicy.policyLineType === 'EHLine_Ext' &&
                    ['HO3', 'HO4', 'HO6'].includes(
                        associatedPolicy.policyType
                    ) &&
                    isEmpty(associatedPolicy.policyLineName)
            );

            const filteredAssociatedPolicies = differenceWith(
                associatedPoliciesObj, filteredMovingInPolicies, isEqual
            );
            const formattedMovingInPolicies = formatMovingInPolicies(filteredMovingInPolicies);
            // we need to combine filtered movingInPolices with the previous systematic policies,
            // because we were not showing them on UI, since those were already present in associated policies array
            const previousMovingInPolicies = get(transactionVM, 'lobData.homeowners_EH.movingInPolicies.value', []);

            previousMovingInPolicies.forEach((previousmovingInPolicy) => {
                const isSystematic = get(previousmovingInPolicy, 'source') === 'Systematic';
                const isPresentAlready =
                    formattedMovingInPolicies.findIndex(
                        (formattedMovingPolicy) =>
                            formattedMovingPolicy.policyNumber ===
                            previousmovingInPolicy.policyNumber
                    ) > -1;

                // we need to only push systematic policies that we are not showing on the UI,
                // all the manual polices will be available in the formattedMovingInPolicies array
                if (isSystematic && !isPresentAlready) {
                    formattedMovingInPolicies.push(previousmovingInPolicy);
                }
            });

            return {
                associatedPolicies: filteredAssociatedPolicies,
                movingInPolicies: formattedMovingInPolicies
            };
        }

        // no Need to format for other LOB's, because they will not have movingInPolicies
        return {
            associatedPolicies: associatedPoliciesObj,
            movingInPolicies: []
        };
    }, [LOB, formatMovingInPolicies, transactionVM]);

    const onSupportingPolicyToggleValueChange = useCallback((value) => {
        if (value) {
            showSupportingPolicyModal().then((res) => {
                const filteredValues = filterMoveInPoliciesFromAssociatedPolices(res.value);

                set(transactionVM, ['lobData', LOB, 'associatedPolicies', 'value'], filteredValues.associatedPolicies);

                if (LOB === 'homeowners_EH') {
                    set(transactionVM, ['lobData', LOB, 'movingInPolicies', 'value'], filteredValues.movingInPolicies);
                }

                updateWizardData(transactionVM);
                setIsSupportingPolicy(hasSupportingPolicy());
            }).catch((res) => {
                if (!isEmpty(res)) {
                    /**
                     * E1PAP1PC-15333 :
                     * if user clicks on Check New Policies button we want to save those policies
                     * even if user clicks on cancel
                     */
                    const associatedPolicies = get(transactionVM, ['lobData', LOB, 'associatedPolicies', 'value'], []);

                    set(transactionVM, ['lobData', LOB, 'associatedPolicies', 'value'], associatedPolicies.concat(res));
                    updateWizardData(transactionVM);
                    setIsSupportingPolicy(hasSupportingPolicy());
                }
            });
        } else {
            set(transactionVM, ['lobData', LOB, 'associatedPolicies', 'value'], []);

            if (LOB === 'homeowners_EH') {
                set(transactionVM, ['lobData', LOB, 'MovingInPolicies', 'value'], []);
            }

            updateWizardData(transactionVM);
            setIsSupportingPolicy(value);
        }

        // force recalculate on coverage page
        setFieldsChangedOnCoveragePage(true);
    }, [LOB, filterMoveInPoliciesFromAssociatedPolices, hasSupportingPolicy,
        setFieldsChangedOnCoveragePage, showSupportingPolicyModal, transactionVM, updateWizardData]);

    const onSupportingPolicyEditIconClick = () => {
        showSupportingPolicyModal().then((res) => {
            // since we are combining associated policies with moving in policies for homeowners
            // we need to seprate them on click of save and store in respective locations
            const filteredValues = filterMoveInPoliciesFromAssociatedPolices(res.value);
            const isAssociatedPoliciesChanged = !isEqual(get(transactionVM, ['lobData', LOB, 'associatedPolicies', 'value'], []), filteredValues.associatedPolicies);
            let isMovingInPoliciesChanged = false;

            if (LOB === 'homeowners_EH') {
                isMovingInPoliciesChanged = !isEqual(get(transactionVM, ['lobData', LOB, 'movingInPolicies', 'value'], []), filteredValues.movingInPolicies);
                set(transactionVM, ['lobData', LOB, 'movingInPolicies', 'value'], filteredValues.movingInPolicies);
            }

            if (isAssociatedPoliciesChanged || isMovingInPoliciesChanged) {
                // force recalculate on coverage page
                setFieldsChangedOnCoveragePage(true);
            }

            set(transactionVM, ['lobData', LOB, 'associatedPolicies', 'value'], filteredValues.associatedPolicies);
            updateWizardData(transactionVM);
            setIsSupportingPolicy(hasSupportingPolicy());
        }).catch((res) => {
            if (!isEmpty(res)) {
                /**
                 * E1PAP1PC-15333 :
                 * if user clicks on Check New Policies button we want to save those policies
                 * even if user clicks on cancel
                 */
                const associatedPolicies = get(transactionVM, ['lobData', LOB, 'associatedPolicies', 'value'], []);

                set(transactionVM, ['lobData', LOB, 'associatedPolicies', 'value'], associatedPolicies.concat(res));
                updateWizardData(transactionVM);
                setIsSupportingPolicy(hasSupportingPolicy());
            }
        });
    };

    const overrideProps = {
        '@field': {
            showRequired: true,
            autoComplete: false
        },
        SupportingPolicyToggle: {
            value: isSupportingPolicy,
            onValueChange: onSupportingPolicyToggleValueChange,
            readOnly: viewOnly || hasSystematicPolicy,
            tooltip
        },
        SupportingPoliciesActionIcon: {
            onClick: onSupportingPolicyEditIconClick,
            visible: isSupportingPolicy,
            icon: viewOnly ? 'mi-visibility' : 'mi-edit'
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={transactionVM}
        />
    );
}

SupportingPolicyComponent.propTypes = {
    transactionVM: PropTypes.shape({}).isRequired,
    tooltip: PropTypes.shape({}),
    LOB: PropTypes.string.isRequired,
    updateWizardData: PropTypes.func.isRequired,
    viewOnly: PropTypes.bool.isRequired,
    setFieldsChangedOnCoveragePage: PropTypes.func,
    authHeader: PropTypes.shape({})
};

SupportingPolicyComponent.defaultProps = {
    setFieldsChangedOnCoveragePage: () => { },
    tooltip: undefined,
    authHeader: undefined
};
export default SupportingPolicyComponent;
