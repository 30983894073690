import React, {
    useCallback,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAdditionalInfoUtil } from 'e1p-capability-hooks';
import metadata from './AdditionalInfoDriver.metadata.json5';
import styles from './AdditionalInfoDriver.module.scss';

function AdditionalInfoDriver(props) {
    const {
        submissionVM,
        labelPosition,
        id,
        onValueChange,
        authHeader,
        onValidate,
        viewModelService,
        showErrors
    } = props;

    const {
        allDrivers,
        driverHasInvalidLicInfo,
    } = useAdditionalInfoUtil(submissionVM);

    const { driversWithMissingInfo, driversWithMissingInfoPresent } = useMemo(() => {
        const drivers = _.get(viewModelService.clone(submissionVM), 'lobData.personalAuto_EA.coverables.drivers.children', []);
        const allDriversWithMissingInfo = drivers.filter((driver) => driverHasInvalidLicInfo(driver));

        return {
            driversWithMissingInfo: allDriversWithMissingInfo,
            driversWithMissingInfoPresent: allDriversWithMissingInfo.length > 0
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const driverHadMissingInfo = useCallback((driver) => driversWithMissingInfo.some((driverWithMissingInfo) => driverWithMissingInfo.value.publicID === driver.value.publicID), [driversWithMissingInfo]);

    const generateVisibilityOverrides = useCallback(() => {
        const driverOverrides = [];

        if (!_.isEmpty(allDrivers)) {
            allDrivers.forEach((driver, index) => {
                driverOverrides.push({
                    [`driverContainerFragment${index}`]: {
                        visible: driverHadMissingInfo(driver)
                    }
                });
                driverOverrides.push({
                    [`driverLicenseNumber${index}`]: {
                        id,
                        viewModelService,
                        authHeader,
                        drivers: _.get(submissionVM, 'lobData.personalAuto_EA.coverables.drivers'),
                        driverVM: driver,
                        label: 'License Number',
                        licenseNumberRequired: true
                    },
                    [`birthdate${index}`]: {
                        updateDateDto: () => onValueChange(
                            driver.person.dateOfBirth.value,
                            `lobData.personalAuto_EA.prefillDrivers.children[${index}].person.dateOfBirth`
                        ),
                        dateDTO: driver.person.dateOfBirth
                    }
                });
            });
            driverOverrides.push({
                driverContainer: {
                    visible: driversWithMissingInfoPresent
                }
            });
        }

        return Object.assign({}, ...(driverOverrides));
    }, [allDrivers, driversWithMissingInfoPresent, driverHadMissingInfo, id, viewModelService, authHeader, submissionVM, onValueChange]);

    const handleValueChange = useCallback((value, changedPath) => {
        // Model used is same as wizard model. Hence changed path is same as full path
        if (onValueChange) {
            onValueChange(value, changedPath);
        }
    }, [onValueChange]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            onValueChange: handleValueChange
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition,
            showErrors,
            autoComplete: false,
            showRequired: true
        },
        ...generateVisibilityOverrides()
    };

    const readValue = useCallback((fieldId, fieldPath) => readViewModelValue(
            metadata.pageContent, submissionVM, fieldId, fieldPath, overrideProps
        ), [submissionVM, overrideProps]);

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            onValueChange={handleValueChange}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

AdditionalInfoDriver.propTypes = {
    value: PropTypes.shape({}),
    submissionVM: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string,
    showErrors: PropTypes.bool
};
AdditionalInfoDriver.defaultProps = {
    value: {},
    submissionVM: {},
    labelPosition: 'left', // I want labels on top by default
    id: undefined,
    showErrors: false
};
export default AdditionalInfoDriver;
