import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class NoteService {
    static createNoteForJob(jobNumber, dto, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayNote'),
            'createNoteForJob', [jobNumber, dto], additionalHeaders);
    }

    static createNoteForPolicy(policyNumber, dto, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayNote'),
            'createNoteForPolicy', [policyNumber, dto], additionalHeaders);
    }

    static createNoteForActivity(activityPublicID, dto, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayNote'),
            'createNoteForActivity', [activityPublicID, dto], additionalHeaders);
    }

    static getNotesForPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayNote'),
            'getNotesForPolicy', [policyNumber], additionalHeaders);
    }

    static getNotesForJob(jobNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayNote'),
            'getNotesForJob', [jobNumber], additionalHeaders);
    }

    static getNotesForActivity(activityPublicID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayNote'),
            'getNotesForActivity', [activityPublicID], additionalHeaders);
    }

    static editNote(dto, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayNote'),
            'editNote', [dto], additionalHeaders);
    }

    static deleteNote(dto, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayNote'),
            'deleteNote', [dto], additionalHeaders);
    }

    static loadSearchCriteriaForPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayNote'),
            'loadSearchCriteriaForPolicy', [policyNumber], additionalHeaders);
    }

    static loadSearchCriteriaForJob(jobNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayNote'),
            'loadSearchCriteriaForJob', [jobNumber], additionalHeaders);
    }

    static searchNotes(searchCriteriaDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayNote'),
            'searchNotes', [searchCriteriaDTO], additionalHeaders);
    }
}
