import { defineMessages } from 'react-intl';

export default defineMessages({
    exitTransaction: {
        id: 'quoteandbind.eu.wizard.steps.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'quoteandbind.eu.wizard.steps.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'quoteandbind.eu.wizard.steps.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'quoteandbind.eu.wizard.steps.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    }
});
