import { defineMessages } from 'react-intl';

export default defineMessages({
    applyingChangesMessage: {
        id: 'rewrite.eu.views.payment-details.Applying changes...',
        defaultMessage: 'Applying changes...'
    },
    completingYourPurchaseMessage: {
        id: 'rewrite.eu.views.payment-details.Completing your purchase...',
        defaultMessage: 'Completing your purchase...'
    },
    loadingPayments: {
        id: 'rewrite.eu.views.payment-details.Please wait while we communicate with the payments system...',
        defaultMessage: 'Please wait while we communicate with the payments system...'
    },
    paymentBuyNow: {
        id: 'rewrite.eu.views.payment-details.Buy Now',
        defaultMessage: 'Buy Now'
    },
    backToPolicy: {
        id: 'rewrite.eu.views.payment-details.Back To Policy',
        defaultMessage: 'Back To Policy'
    },
    issueLabel: {
        id: 'rewrite.e1p.eu.views.payment-details.issueLabel',
        defaultMessage: 'Issue'
    },
    unableToBindTitle: {
        id: 'rewrite.e1p.eu.views.payment-details.Unable to bind',
        defaultMessage: 'Unable to bind'
    },
    verifiedPremium: {
        id: 'rewrite.eu.views.payment-details.verifiedPremium',
        defaultMessage: 'Verified Premium'
    },
    policyTerm: {
        id: 'rewrite.eu.views.payment-details.Policy Term',
        defaultMessage: 'Policy Term'
    },
    twelveMonths: {
        id: 'rewrite.eu.views.payment-details.12 Months',
        defaultMessage: '12 Months'
    },
    tpiTitle: {
        id: 'rewrite.eu.views.payment-details.thirdPartyTitle',
        defaultMessage: 'Third-Party Interest'
    },
    insuredTitle: {
        id: 'rewrite.eu.views.payment-details.insuredContactTitle',
        defaultMessage: 'Insured Contact'
    },
    phoneHome: {
        id: 'rewrite.eu.views.payment-details.Home Phone',
        defaultMessage: 'Home Phone'
    },
    tooltipHomePhone: {
        id: 'rewrite.eu.views.payment-details.Home Phone.tooltip',
        defaultMessage: 'Home or Mobile Phone Required for Interior Inspection'
    },
    phoneMobile: {
        id: 'rewrite.eu.views.payment-details.Mobile Phone',
        defaultMessage: 'Mobile Phone'
    },
    tooltipMobilePhone: {
        id: 'rewrite.eu.views.payment-details.Mobile Phone.tooltip',
        defaultMessage: 'Home or Mobile Phone Required for Interior Inspection'
    },
    insuredResAddressTitle: {
        id: 'rewrite.eu.views.payment-details.insuredResAddressTitle',
        defaultMessage: 'Insured Residence Address'
    },
    signature: {
        id: 'rewrite.eu.views.payment-details.signature',
        defaultMessage: 'Signature'
    },
    locationCode: {
        id: 'rewrite.eu.views.payment-details.Location Code',
        defaultMessage: 'Location Code'
    },
    signaturePlaceholder: {
        id: 'rewrite.eu.views.payment-details.Choose-Signature',
        defaultMessage: '--Choose a Signature Type--'
    }
});
