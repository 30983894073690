import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './InteractiveLetterTableComponent.metadata.json5';
import messages from './InteractiveLetterTableComponent.messages';
import { Button } from '@jutro/legacy/components';

function InteractiveLetterTableComponent(props) {
    const {
        createTemplate,
        interactiveLetters
    } = props;

    const createInteractive = useCallback((data) => {
        createTemplate(data);
    }, [createTemplate]);

    const createInteractiveTemplate = useCallback((item) => (
            <Button
                onClick={() => createInteractive(item)}
            >
                {messages.createButton}
            </Button>
        ), [createInteractive]);

    const resolvers = {
        resolveCallbackMap: {
            createInteractiveTemplate
        },
    };

    const overrideProps = {
        interactiveTable: {
            data: interactiveLetters
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

InteractiveLetterTableComponent.propTypes = {
    interactiveLetters: PropTypes.arrayOf(PropTypes.shape({})),
    createTemplate: PropTypes.func.isRequired
};
InteractiveLetterTableComponent.defaultProps = {
    interactiveLetters: []
};
export default InteractiveLetterTableComponent;
