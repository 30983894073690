import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, set, isUndefined, isEqual, isEmpty
} from 'lodash';
import {
    useModal,
} from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { euCommonMessages, commonMessages, eaCommonMessages } from 'e1p-platform-translations';
import * as PropTypes from 'prop-types';
import metadata from './EUVehicleExposureTableComponent.metadata.json5';

function EUVehicleExposureTableComponent(props) {
    const modalApi = useModal();
    const {
        model: vehicleExposuresVM,
        onModelChange,
        onValidate,
        visible,
        viewOnlyMode,
        id,
        showErrors,
        parentIndex
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isClickedOnAddVehicle, updateIsClickedOnAddVehicle] = useState(false);
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);

    const [addButtonContent, setAddButtonContent] = useState(
        translator(commonMessages.addVehicle)
    );
    const [newVehicleExposureTitle, setNewVehicleExposureTitle] = useState(
        translator(eaCommonMessages.newVehicle)
    );
    const isWatercraftExposure = get(vehicleExposuresVM, '_propertyName') === 'watercraftExposures';
    const isRecreationVehicleExposure = get(vehicleExposuresVM, '_propertyName') === 'recreationalVehicleExposures';
    const isMotorCycleExposure = get(vehicleExposuresVM, '_propertyName') === 'cycleExposures';
    const isPersonalVehicleExposure = get(vehicleExposuresVM, '_propertyName') === 'personalVehicleExposures';
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        disregardFieldValidation
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [vehicleExposuresVM, id, onValidate, isComponentValid]);

    const addVehicle = useCallback(() => {
        const { _xCenter, _dtoName } = vehicleExposuresVM;
        const newVehicleExposureVM = viewModelService.create(
            { isManuallyAddedInd: true }, _xCenter, _dtoName
        );

        vehicleExposuresVM.pushElement(newVehicleExposureVM);
        setCheckScrolling(true);
        setIndexStale(true);
        onModelChange();
    }, [vehicleExposuresVM, viewModelService, onModelChange]);


    useEffect(() => {
        if (isUndefined(vehicleExposuresVM.value)) {
            vehicleExposuresVM.value = [];
            addVehicle();
        }

        if (!viewOnlyMode) {
            onModelChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // this grabs the latest element on the page --
    // this is to make sure this element has been loaded
    const latestVehicleExposureElement = document.getElementById(
        `euVehicleExposureComponentDiv${vehicleExposuresVM.children.length - 1}`
    );

    useEffect(() => {
        // indexStale set in the add vehicle exposure function
        // once latest element is loaded and a new vehicle exposure is added
        // we check if the button should be visible
        if (latestVehicleExposureElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestVehicleExposureElement]);


    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        let vehicleExposureElement;

        switch (true) {
            case isWatercraftExposure:
                vehicleExposureElement = document.getElementById(`watercraftExposureTab${parentIndex}`);
                break;
            case isRecreationVehicleExposure:
                vehicleExposureElement = document.getElementById(`recreationalVehicleExposureTab${parentIndex}`);
                break;
            case isMotorCycleExposure:
                vehicleExposureElement = document.getElementById(`cycleExposureTab${parentIndex}`);
                break;
            default:
                vehicleExposureElement = document.getElementById(`personalVehicleExposureTab${parentIndex}`);
                break;
        }

        if (vehicleExposureElement) {
            if (isWatercraftExposure) {
                const vehicleYearElement = vehicleExposureElement.querySelector(`input[id="year${vehicleExposuresVM.length - 1}"]`);

                if (vehicleYearElement
                    && isEmpty(vehicleYearElement.value)
                    && vehicleYearElement.focus !== undefined) {
                    vehicleYearElement.focus();
                }
            } else {
                const vehicleVinElement = vehicleExposureElement.querySelector(`input[id="vin${vehicleExposuresVM.length - 1}"]`);

                if (vehicleVinElement
                    && isEmpty(vehicleVinElement.value)
                    && vehicleVinElement.focus !== undefined) {
                    vehicleVinElement.focus();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleExposuresVM.length]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isClickedOnAddVehicle) {
            updateIsClickedOnAddVehicle(false);
        }
    }, [vehicleExposuresVM, isComponentValid, isClickedOnAddVehicle]);

    const removeVehicleExposure = useCallback(
        (evt) => {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: euCommonMessages.removeVehicleExposureTitle,
                message: translator(commonMessages.removeItemMessage, { itemToRemove: 'Vehicle Exposure' }),
                confirmButtonText: translator(commonMessages.removeItemButtonText, { itemToRemove: 'VEHICLE EXPOSURE' }),
                cancelButtonText: commonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const totalVehiclesCount = vehicleExposuresVM.value.length - 1;
                    const vehicleExposurePath = evt.path;
                    const vehicleExposure = get(vehicleExposuresVM, vehicleExposurePath).value;
                    const vehicleIndex = vehicleExposuresVM.value.findIndex(
                        (exposure) => isEqual(exposure, vehicleExposure)
                    );

                    vehicleExposuresVM.value.splice(vehicleIndex, 1);
                    disregardFieldValidation(`euVehicleExposureComponent${totalVehiclesCount}`);
                    setCheckScrolling(true);

                    if(totalVehiclesCount === 0){
                        addVehicle();
                    }

                    onModelChange();
                }
            }, () => { });
        },
        [vehicleExposuresVM, disregardFieldValidation, onModelChange, addVehicle, translator, modalApi]
    );

    const writeValue = useCallback(
        (newVal, localPath) => {
            set(vehicleExposuresVM, `${localPath}.value`, newVal);
            onModelChange();
        },
        [onModelChange, vehicleExposuresVM]
    );

    useEffect(() => {
        switch (true) {
            case isWatercraftExposure:
                setAddButtonContent(translator(euCommonMessages.addWatercraft));
                setNewVehicleExposureTitle(translator(euCommonMessages.newWatercraft));
                break;
            case isRecreationVehicleExposure:
                setAddButtonContent(translator(euCommonMessages.addRecreationalVehicle));
                setNewVehicleExposureTitle(translator(euCommonMessages.newRecreationalVehicle));
                break;
            case isPersonalVehicleExposure:
                setAddButtonContent(translator(commonMessages.addVehicle));
                setNewVehicleExposureTitle(translator(eaCommonMessages.newVehicle));
                break;
            case isMotorCycleExposure:
                setAddButtonContent(translator(euCommonMessages.addMotorcycle));
                setNewVehicleExposureTitle(translator(euCommonMessages.newMotorcycle));
                break;
            default:
                setAddButtonContent(translator(commonMessages.addVehicle));
                setNewVehicleExposureTitle(translator(eaCommonMessages.newVehicle));
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrideProps = {
        '@field': {
            className: 'allFields',
            visible,
            showRequired: true,
            disabled: viewOnlyMode,
            showErrors: showErrors || isClickedOnAddVehicle,
            autoComplete: false
        },
        addVehicleExposureButton: {
            visible: !viewOnlyMode,
            content: addButtonContent
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleExposureGridContainer')
        },
        EUVehicleExposureGrid: {
            vehicleExposures: vehicleExposuresVM,
            showErrors: showErrors || isClickedOnAddVehicle,
            viewOnlyMode,
            isWatercraftExposure,
            newVehicleExposureTitle,
            isRecreationVehicleExposure,
            isPersonalVehicleExposure,
            onValueChange: writeValue,
            removeVehicleExposure,
            key: get(vehicleExposuresVM, 'children', []).length,
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            addVehicleExposure: addVehicle,
            onValidate: setComponentValidation
        },
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={vehicleExposuresVM}
                overrideProps={overrideProps}
                onValidationChange={setComponentValidation}
                onValueChange={writeValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

EUVehicleExposureTableComponent.propTypes = {
    model: PropTypes.shape({}).isRequired,
    onModelChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    viewOnlyMode: PropTypes.bool,
    id: PropTypes.string,
    showErrors: PropTypes.bool,
    parentIndex: PropTypes.number.isRequired
};
EUVehicleExposureTableComponent.defaultProps = {
    visible: true,
    viewOnlyMode: false,
    id: '',
    showErrors: false
};
export default EUVehicleExposureTableComponent;
