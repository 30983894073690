import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextPageMessage: {
        id: 'endorsement.eu.insuredDetailsPage.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    lossOccurredAfterChangeEffectiveDate: {
        id: 'endorsement.eu.insuredDetailsPage.Loss Occurred After Change Effective Date',
        defaultMessage: 'A loss for this risk occurred on or after the effective date of the change?'
    },
    lossOccurredAfterEffectiveDate: {
        id: "endorsement.eu.insuredDetailsPage.Has any Liability Loss occurred in the household between the effective date and today's date?",
        defaultMessage: "Has any Liability Loss occurred in the household between the effective date and today's date?"
    },
    lossOccurredAfterChangeEffectiveDateYes: {
        id: 'endorsement.eu.insuredDetailsPage.Loss Occurred After Change Effective Date.Yes',
        defaultMessage: 'Yes'
    },
    lossOccurredAfterChangeEffectiveDateNo: {
        id: 'endorsement.eu.insuredDetailsPage.Loss Occurred After Change Effective Date.No',
        defaultMessage: 'No'
    },
    policyInformation: {
        id: 'endorsement.eu.insuredDetailsPage.Policy Information',
        defaultMessage: 'Policy Information'
    },
    lineOfBusiness: {
        id: 'endorsement.eu.insuredDetailsPage.Line Of Business',
        defaultMessage: 'Line Of Business'
    },
    periodStartDate: {
        id: 'endorsement.eu.insuredDetailsPage.Effective Date',
        defaultMessage: 'Effective Date'
    },
    periodEndDate: {
        id: 'endorsement.eu.insuredDetailsPage.Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    rateAsOfDate: {
        id: 'endorsement.eu.insuredDetailsPage.Rate as of Date',
        defaultMessage: 'Rate as of Date'
    },
    riskState: {
        id: 'endorsement.eu.insuredDetailsPage.Risk State',
        defaultMessage: 'Risk State'
    },
    policyType: {
        id: 'endorsement.eu.insuredDetailsPage.Policy Type',
        defaultMessage: 'Policy type'
    },
    insuredInfo: {
        id: 'endorsement.eu.insuredDetailsPage.Insured Information',
        defaultMessage: 'Insured Information'
    },
    insuredFirstName: {
        id: 'endorsement.eu.insuredDetailsPage.insuredInformation.FirstName',
        defaultMessage: 'First Name'
    },
    insuredMiddleName: {
        id: 'endorsement.eu.insuredDetailsPage.insuredInformation.M.I.',
        defaultMessage: 'M.I.'
    },
    insuredLastName: {
        id: 'endorsement.eu.insuredDetailsPage.insuredInformation.LastName',
        defaultMessage: 'LastName'
    },
    insuredSuffix: {
        id: 'endorsement.eu.insuredDetailsPage.insuredInformation.Suffix',
        defaultMessage: 'Suffix'
    },
    insuredAddress: {
        id: 'endorsement.eu.insuredDetailsPage.Insured Residence Information',
        defaultMessage: 'Insured Residence Address'
    },
    insuredAddressAddressLine1: {
        id: 'endorsement.eu.insuredDetailsPage.insuredAddress.Address Line 1',
        defaultMessage: 'Address Line 1'
    },
    insuredAddressAddressLine2: {
        id: 'endorsement.eu.insuredDetailsPage.insuredAddress.Address Line 2',
        defaultMessage: 'Address Line 2'
    },
    insuredAddressAddressCity: {
        id: 'endorsement.eu.insuredDetailsPage.insuredAddress.City',
        defaultMessage: 'City'
    },
    insuredAddressAddressZipCode: {
        id: 'endorsement.eu.insuredDetailsPage.insuredAddress.ZipCode',
        defaultMessage: 'Zip Code'
    },
    insuredAddressAddressState: {
        id: 'endorsement.eu.insuredDetailsPage.insuredAddress.State',
        defaultMessage: 'State'
    },
    addNewPni: {
        id: 'endorsement.eu.insuredDetailsPage.pni.Add New Pni',
        defaultMessage: 'Add New Pni'
    }
});
