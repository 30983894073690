import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';

import './CoolingSystemComponent.messages';
import metadata from './CoolingSystemComponent.metadata.json5';
import styles from './CoolingSystemComponent.scss';

function CoolingSystemComponent(props) {
    const {
        data: coolingSystemVM,
        labelPosition,
        path,
        id,
        onValidate,
        onValueChange,
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [coolingSystemVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        }
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                coolingSystemVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [coolingSystemVM, overrideProps]
    );
    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={coolingSystemVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

CoolingSystemComponent.propTypes = {
    value: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};
CoolingSystemComponent.defaultProps = {
    value: {},
    labelPosition: 'top',
    path: undefined
};

export default CoolingSystemComponent;
