import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextPageMessage: {
        id: 'endorsement.eh.views.coverages.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    coverages: {
        id: 'endorsement.eh.views.coverages.Coverages',
        defaultMessage: 'Coverages'
    },
    mainCoverages: {
        id: 'endorsement.eh.views.coverages.Main Coverages Premium',
        defaultMessage: 'Main Coverages Premium'
    },
    additionalCoverages: {
        id: 'endorsement.eh.selectAction.cart.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    },
    nextLabel: {
        id: 'endorsement.eh.quotePage.Next',
        defaultMessage: 'Next'
    },
    recalculateLabel: {
        id: 'endorsement.eh.quotePage.Recalculate',
        defaultMessage: 'Recalculate'
    },
    schedules: {
        id: 'endorsement.eh.selectAction.cart.Schedules',
        defaultMessage: 'Schedules'
    },
    optionalCoverageLabel: {
        id: 'endorsement.eh.coveragePage.Optional Coverage.Optional Coverages',
        defaultMessage: 'Optional Coverages'
    },
    sectionICoverages: {
        id: 'endorsement.eh.coveragesPage.Section I Coverages',
        defaultMessage: 'Section I Coverages'
    },
    sectionIICoverages: {
        id: 'endorsement.eh.coveragesPage.Section II Coverages',
        defaultMessage: 'Section II Coverages'
    },
    protectiveDevices: {
        id: 'endorsement.eh.coveragesPage.Protective Devices',
        defaultMessage: 'Protective Devices'
    },
    deductibles: {
        id: 'endorsement.eh.coveragesPage.Deductibles',
        defaultMessage: 'Deductibles'
    },
    optionalCoveragePackage: {
        id: 'endorsement.eh.coveragesPage.optionalCoveragePackage',
        defaultMessage: 'Optional Coverage Package'
    },
    goldPlatinumPrimaryLabel: {
        id: 'endorsement.eh.coveragesPage.goldPlatinumPrimaryLabel',
        defaultMessage: 'Gold & Platinum Packages'
    },
    discountMessageSelect: {
        id: 'endorsement.eh.coveragesPage.selectCoverage',
        defaultMessage: 'Select any coverages marked with'
    },
    discountMessageLabel: {
        id: 'endorsement.eh.coveragesPage.discountMessage',
        defaultMessage: 'to receive a Package Discount. The more coverages selected, the greater the discount.'
    }
});
