import { defineMessages } from 'react-intl';

export default defineMessages({
    standardizingAddressMessage: {
        id: 'common.E1PEATPISearchDetailsComponent.Standardizing address...',
        defaultMessage: 'Standardizing address...'
    },
    ok: {
        id: 'common.E1PEATPISearchDetailsComponent.ok',
        defaultMessage: 'Save'
    },
    cancel: {
        id: 'common.E1PEATPISearchDetailsComponent.cancel',
        defaultMessage: 'Cancel'
    },
    tpiID: {
        id: 'common.E1PEATPISearchDetailsComponent.tpiID',
        defaultMessage: 'TPI ID'
    },
    tpiDetailsHeading: {
        id: 'common.E1PEATPISearchDetailsComponent.tpiDetailsHeading',
        defaultMessage: 'Third-Party Interest'
    },
    BankName: {
        id: 'common.E1PEATPISearchDetailsComponent.BankName',
        defaultMessage: 'Name'
    },
    address: {
        id: 'common.E1PEATPISearchDetailsComponent.address',
        defaultMessage: 'Address'
    },
    firstName: {
        id: 'common.E1PEATPISearchDetailsComponent.person.firstName',
        defaultMessage: 'First Name'
    },
    middleName: {
        id: 'common.E1PEATPISearchDetailsComponent.person.middleName',
        defaultMessage: 'Middle Name'
    },
    lastName: {
        id: 'common.E1PEATPISearchDetailsComponent.person.lastName',
        defaultMessage: 'Last Name'
    },
    interestTypePlaceHolder: {
        id: 'common.E1PEATPISearchDetailsComponent.interestTypePlaceHolder',
        defaultMessage: 'Please Select'
    },
    suffix: {
        id: 'common.E1PEATPISearchDetailsComponent.person.suffix',
        defaultMessage: 'Suffix'
    },
    addressline1: {
        id: 'common.E1PEATPISearchDetailsComponent.address.line1',
        defaultMessage: 'Address Line 1'
    },
    addressLine2: {
        id: 'common.E1PEATPISearchDetailsComponent.address.Line2',
        defaultMessage: 'Address Line 2'
    },
    addressline2optional: {
        id: 'common.E1PEATPISearchDetailsComponent.address.addressline2.optional',
        defaultMessage: 'Optional'
    },
    City: {
        id: 'common.E1PEATPISearchDetailsComponent.address.City',
        defaultMessage: 'City'
    },
    ZipCode: {
        id: 'common.E1PEATPISearchDetailsComponent.address.ZipCode',
        defaultMessage: 'Zip Code'
    },
    State: {
        id: 'common.E1PEATPISearchDetailsComponent.address.State',
        defaultMessage: 'State'
    },
    ChooseStatePlaceholder: {
        id: 'common.E1PEATPISearchDetailsComponent.address-details.Choose State',
        defaultMessage: '--Choose State--'
    },
    companyName: {
        id: 'common.E1PEATPISearchDetailsComponent.company.companyName',
        defaultMessage: 'Name'
    },
    sendBillToTPIYes: {
        id: 'common.E1PEATPISearchDetailsComponent.sendBillToTPI.Yes',
        defaultMessage: 'Yes'
    },
    sendBillToTPINo: {
        id: 'common.E1PEATPISearchDetailsComponent.sendBillToTPI.No',
        defaultMessage: 'No'
    },
    sendBillToTPI: {
        id: 'common.E1PEATPISearchDetailsComponent.sendBillToTPI',
        defaultMessage: 'Send Bill to TPI?'
    },
    assignAtRenewal: {
        id: 'common.E1PEATPISearchDetailsComponent.sendBillToTPI.assignAtRenewal',
        defaultMessage: 'Assign At Renewal'
    },
    assignNow: {
        id: 'common.E1PEATPISearchDetailsComponent.sendBillToTPI.assignNow',
        defaultMessage: 'Assign Now'
    },
    assignNowOrAtRenewal: {
        id: 'common.E1PEATPISearchDetailsComponent.assignNowOrAtRenewal',
        defaultMessage: ' '
    },
    LoanNumber: {
        id: 'common.E1PEATPISearchDetailsComponent.LoanNumber',
        defaultMessage: 'Loan Number'
    },
    vehicleDropdownMessage: {
        id: 'common.E1PEATPISearchDetailsComponent.selectVehicle',
        defaultMessage: 'Select Vehicle'
    },
    AdditionalInterestType: {
        id: 'common.E1PEATPISearchDetailsComponent.company.additionalInterestType',
        defaultMessage: 'Additional Interest Type'
    }
});
