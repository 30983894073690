import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class TimelineService {
    static getTimelineForAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('journeyTimeline'), 'getTimelineForAccount', [accountNumber], additionalHeaders);
    }

    static getTimeline(historySearchCriteriaDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('journeyTimeline'), 'getTimeline', [historySearchCriteriaDTO], additionalHeaders);
    }

    static getDocumentURL(historyDTO, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('journeyTimeline'), 'retrieveDocURLByHistoryLog', [historyDTO], additionalHeaders);
    }
}
