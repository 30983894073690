import { defineMessages } from 'react-intl';

export default defineMessages({
    externalLocationCode: {
        id: 'externalLocationCode.Location Code',
        defaultMessage: 'Location Code'
    },
    noAgencyFound: {
        id: 'externalLocationCode.No agency found. Correct your location code and try again.',
        defaultMessage: 'No agency found. Correct your location code and try again.'
    },
    fetchingDetails: {
        id: 'externalLocationCode.Fetching agency details...',
        defaultMessage: 'Fetching agency details...'
    },
    agencyFound: {
        id: 'externalLocationCode.Successfully found agency',
        defaultMessage: 'Successfully found agency'
    }
});
