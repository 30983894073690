import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import metadata from './ThirdPartyReportsComponent.metadata.json5';
import styles from './ThirdPartyReportsComponent.module.scss';
import './ThirdPartyReportsComponent.messages';

function ThirdPartyReportsComponent(props) {
    const {
        value: reportsVM,
        path,
        saveOverrides,
        authUserData, 
        viewOnlyMode
    } = props;

    const canViewDisputeCheckbox = authUserData?.permissions_Ext.includes('editoverrides');

    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        if (reportsVM !== undefined) {
            reportsVM.forEach((report, index) => {
                overrideProps[`ehthirdPartyReport${index}`] = {
                    isPropertyLoss: path === 'propertyReports',
                    saveOverrides,
                    viewOnlyMode,
                    canViewDisputeCheckbox
                };
            });
        }

        return overrideProps;
    }, [reportsVM, path, saveOverrides, viewOnlyMode, canViewDisputeCheckbox]);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        thirdPartyReportCAT: {
            visible: path === 'propertyReports'
        },
        thirdPartyReportLabels: {
            columns: path === 'propertyReports' ? ['1fr', '1fr', '1fr', '1fr','1fr', '0.25fr'] : ['1fr', '1fr', '1fr','1fr', '0.25fr']
        },
        disputeRecordLabel: {
            visible: canViewDisputeCheckbox
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const modelValue = {
        reportsVM
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={modelValue}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ThirdPartyReportsComponent.propTypes = {
    reportsVM: PropTypes.arrayOf(PropTypes.shape({})),
    value:  PropTypes.arrayOf(PropTypes.shape({})),
    path: PropTypes.string,
    saveOverrides: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    authUserData: PropTypes.shape({})
};
ThirdPartyReportsComponent.defaultProps = {
    reportsVM: [],
    value: [],
    path: undefined,
    viewOnlyMode: false
};
export default withAuthenticationContext(ThirdPartyReportsComponent);
