import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { isEmpty as _isEmpty } from 'lodash';
import { Chevron } from '@jutro/components';
import metadata from './ProducerAddressComponent.metadata.json5';
import './ProducerAddressComponent.messages';

/**
 *
 * @param {*} props     address {
 *                          addressLine1: "53 W JACKSON BLVD "
 *                          addressUsage: "WORK"
 *                          city: "CHICAGO"
 *                          country: "US"
 *                          effectiveDate: "1900-01-01T00:00:00Z"
 *                          endEffectiveDate: "2300-01-01T00:00:00Z"
 *                          locationId: "35800"
 *                          primaryAddressIndicator: true
 *                          state: "IL"
 *                          zip5Code: "60604"
 *                      },
 *                      phone: "6085551234"
 *                  }
 * @returns {JSX} returns a component that contains diabled address fields and phone
 */
function ProducerAddressComponent(props) {
    const {
        address: producerAddress,
        phone,
        labelPosition
    } = props;


    const renderProducerAddressHeader = useCallback(() => (isOpen) => (
            <React.Fragment>
                <Chevron isOpen={isOpen} align="left" />
                {isOpen ? 'Hide Agency Address' : 'Show Agency Address'}
            </React.Fragment>
        ), []);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition,
            autoComplete: false
        },
        addressInfoContainerAccordion: {
            disabled: _isEmpty(producerAddress)
        },
        commonState: {
            availableValues: [
                {
                    id: producerAddress.state,
                    displayName: producerAddress.state
                }
            ],
            defaultValue: producerAddress.state
        },
        addressInfoContainerAccordionCard: {
            renderHeader: renderProducerAddressHeader()
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={{ ...producerAddress, phone }}
            overrideProps={overrideProps}
        />
    );
}

ProducerAddressComponent.propTypes = {
    id: PropTypes.string,
    address: PropTypes.shape({}),
    phone: PropTypes.string,
    labelPosition: PropTypes.string
};
ProducerAddressComponent.defaultProps = {
    address: {},
    phone: '',
    labelPosition: 'top',
    id: undefined
};
export default ProducerAddressComponent;
