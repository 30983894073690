import { defineMessages } from 'react-intl';

export default defineMessages({
    standardizingAddressMessage: {
        id: 'rewrite.eu.views.eu-policydetails-details.Standardizing address...',
        defaultMessage: 'Standardizing address...'
    },
    loadingNextPageMessage: {
        id: 'rewrite.eu.views.eu-policydetails-details.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    creatingYourSubmissionMessage: {
        id: 'rewrite.eu.views.eu-policydetails-details.Creating your submission...',
        defaultMessage: 'Creating your submission...'
    },
    startDate: {
        id: 'rewrite.eu.views.eu-policy-info.Coverage Start Date',
        defaultMessage: 'Coverage Start Date'
    },
    rateAsOfDate: {
        id: 'rewrite.eu.views.eu-policy-info.Rate as of Date',
        defaultMessage: 'Rate as of Date'
    },
    middleName: {
        id: 'gateway.eu.views.rewrite.M.I.',
        defaultMessage: 'M.I.'
    },
    suffix: {
        id: 'gateway.eu.views.rewrite.Suffix',
        defaultMessage: 'Suffix'
    },
    chooseSuffix: {
        id: 'rewrite.eu.inputs.address-details.Choose Suffix',
        defaultMessage: '--Choose Suffix--'
    },
    insuredResidenceInformation: {
        id: 'rewrite.eu.wizard.step.Policy Details.Insured Residence Information',
        defaultMessage: 'Insured Residence Information'
    },
    Optional: {
        id: 'rewrite.eu.views.eu-policydetails-details.optional',
        defaultMessage: 'Optional'
    },
    insuredInformation: {
        id: 'rewrite.eu.views.eu-policydetails-details.Insured Information',
        defaultMessage: 'Insured Information'
    },
    accountTypeErrorMessage: {
        id: 'rewrite.eu.views.eu-policydetails-details.Account type must be a Company or a Person',
        defaultMessage: 'Account type must be a Company or a Person'
    },
    newAccountCreateErrorMessage: {
        id: 'rewrite.eu.views.eu-policydetails-details.Could not create the Account',
        defaultMessage: 'Could not create the Account'
    },
    sourceType: {
        id: 'gateway.eu.views.rewrite.Source Type',
        defaultMessage: 'Source of Entry'
    },
    agencyName: {
        id: 'gateway.eu.views.rewrite.AgencyName',
        defaultMessage: 'Agency Name'
    },
    agencyInfo: {
        id: 'rewrite.eu.wizard.step.Agency Information',
        defaultMessage: 'Agency Information'
    },
    effDate: {
        id: 'rewrite.eu.views.eu-policy-info.Effective Date',
        defaultMessage: 'Effective Date'
    },
    expDate: {
        id: 'rewrite.eu.views.eu-policy-info.Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    riskState: {
        id: 'rewrite.eu.views.eu-policy-info.Risk State',
        defaultMessage: 'Risk State'
    },
    Yes: {
        id: 'rewrite.eu.wizard.step.Policy Details.Yes',
        defaultMessage: 'Yes'
    },
    No: {
        id: 'rewrite.eu.wizard.step.Policy Details.No',
        defaultMessage: 'No'
    },
    removePriorAddress: {
        id: 'rewrite.eu.controllers.underwriting-details.Remove Prior Address?',
        defaultMessage: 'Remove Prior Loss Address?'
    },
    removePriorAddressDescription: {
        id: 'rewrite.eu.underwriting-details.remove-loss.Please note that this action will remove provided prior address.',
        defaultMessage: 'Please note that this action will remove provided prior address.'
    },
    effectiveDateValidation: {
        id: 'rewrite.eu.wizard.step..Policy must be bound within 5 days of the Effective Date.',
        defaultMessage: 'Policy must be bound within 5 days of the Effective Date.'
    },
    producerCodePlaceHolder: {
        id: 'gateway.eu.views.rewrite.Choose Producer Code',
        defaultMessage: 'Please Select'
    },
    locationCode: {
        id: 'gateway.eu.views.rewrite.Location Code',
        defaultMessage: 'Location Code'
    },
    policyInformation: {
        id: 'rewrite.eu.wizard.step.Policy Information',
        defaultMessage: 'Policy Information'
    },
    lineOfbusiness: {
        id: 'gateway.eu.views.rewrite.Line of Business',
        defaultMessage: 'Line of Business'
    },
    policyType: {
        id: 'gateway.eu.views.rewrite.Policy Type',
        defaultMessage: 'Policy Type'
    }
});
