import React, { useContext } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { Icon } from '@jutro/components';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WizardContext } from '../WizardContext';
import styles from './WizardSidebar.module.scss';

/**
 * @typedef {import('gw-portals-edge-validation-js/errors').GenericUIIssue} GenericUIIssue
*/

/**
 * Renders an icon for steps with errors
 * @param {Array<GenericUIIssue>} errorsAndWarnings array
 * @returns {Object}
 */
function renderErrors(errorsAndWarnings = []) {
    const highestLevel = errorsAndWarnings
        .map((err) => err.level)
        .reduce((currentHighest, currentError) => {
            if (!currentHighest) {
                return currentError;
            }
            if (currentHighest === ErrorLevel.LEVEL_WARN) {
                // currentError can only Greater or Equal than currentHighest
                return currentError;
            }
            return currentHighest;
        }, null);
    switch (highestLevel) {
        case ErrorLevel.LEVEL_ERROR:
            return <Icon icon="minus-circle" className={styles.errorIssue} />;
        case ErrorLevel.LEVEL_WARN:
            return <Icon icon="exclamation-triangle" className={styles.warningIssue} />;
        default:
            return null;
    }
}

function jumpRenderModal(index, jumpTo, steps, step, wizardContext, customMessageModal) {
    const modalMessages = {
        title: !_.isEmpty(customMessageModal)
            ? customMessageModal.title : commonMessages.wantToJump,
        message: !_.isEmpty(customMessageModal)
            ? customMessageModal.message : commonMessages.wantToJumpMessage,
        messageProps: {
            ok: commonMessages.yes,
            close: commonMessages.close
        }
    };
    const {
        wizardData,
        wizardSnapshot,
        onPageJump,
    } = wizardContext;

    const submissionVM = _.get(wizardData, 'submissionVM') || wizardData;

    onPageJump({
        wizardData: submissionVM,
        wizardSnapshot,
        modalMessages,
        index
    });
}

function renderSteps(translator, steps, currentStepIndex,
    stepsWithErrors, jumpTo, wizardContext, customMessageModal) {
    return steps.map((step, index) => {
        const liClassName = classNames(styles.liStep, {
            [styles.active]: index === currentStepIndex,
            [styles.visited]: step.visited && index !== currentStepIndex
        });
        if(step.stepProps.hidePageFromSidebar) return;
        return (
            <li className={liClassName} key={step.id}>
                <button
                    onClick={() => jumpRenderModal(index,
                        jumpTo, steps, step, wizardContext, customMessageModal)}
                    disabled={!step.visited || step.disabled}
                    type="button"
                >
                    { translator(step.title) }
                    { renderErrors(stepsWithErrors[step.id])}
                </button>
            </li>
        );
    });
}

function WizardSidebar(props) {
    const wizardContext = useContext(WizardContext);
    const translator = useTranslator();
    const {
        steps,
        currentStepIndex,
        jumpTo,
        stepsWithErrors,
        wizardTitle,
        currentStep
    } = wizardContext;
    const { customMessageModal } = props;
    const wizardSteps = steps.filter((step) => !step.id.endsWith('EANestedPage'));

    return (
        <div className={currentStep.id.endsWith('EANestedPage') ? styles.hideNavBar : styles.wizardSidebarWrapper}>
            {/* We don't want a wizard title and this underline */}
            {/* <div className={styles.sideBarTitle}>
                {translator(wizardTitle)}
            </div> */}
            <div role="navigation" aria-label={translator(wizardTitle)}>
                <ul className={styles.stepsNav}>
                    {renderSteps(translator, wizardSteps,
                        currentStepIndex, stepsWithErrors,
                        jumpTo, wizardContext, customMessageModal)}
                </ul>
            </div>
        </div>
    );
}

WizardSidebar.propTypes = {
    customMessageModal: PropTypes.shape({})
};
WizardSidebar.defaultProps = {
    customMessageModal: {}
};
export default WizardSidebar;
