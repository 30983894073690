import { defineMessages } from 'react-intl';

export default defineMessages({
    RelatedTo: {
        id: 'e1p.gateway.directives.gateway-notes.RelatedTo',
        defaultMessage: 'Related To',
    },
    SecurityLevel: {
        id: 'e1p.gateway.directives.gateway-notes.SecurityLevel',
        defaultMessage: 'Security Level',
    },
    Note: {
        id: 'e1p.gateway.directives.gateway-note.Note',
        defaultMessage: 'Note',
    },
    noPolicyNotes: {
        id: 'e1p.gateway.directives.gateway-note.No notes associated with this policy',
        defaultMessage: 'No notes available.'
    },

});
