import React, { useCallback } from 'react';
import {
    get as _get,
    includes as _includes
} from 'lodash';
import { RiskAnalysisUWIssuesComponent } from 'e1p-capability-policyjob-react';

const allBlockingPoints = ['BlocksQuote',
    'BlocksRateRelease',
    'BlocksQuoteRelease',
    'BlocksBind',
    'BlocksIssuance',
];
// NonBlocking

function useUWIssueUtil(
    submissionVM,
    updateWizardData,
    jumpTo,
    steps,
    redirectURL = undefined
) {
    // UWBlockingPoints should be an array of blocking points
    // If calling right after a state update, we must pass VM
    // as setState is async and VM will not have correct values
    // E1PAP1PC-14149
    const hasUWIssuesOfType = useCallback((UWBlockingPoints, newSubmissionVM = submissionVM, ignoreRvpUW = false) => {
        if (!UWBlockingPoints) {
            return false;
        }

        const uwIssues = _get(newSubmissionVM, 'value.errorsAndWarnings.underwritingIssues',
            _get(newSubmissionVM, 'value.errorsAndWarnings_Ext.underwritingIssues'));

        if (uwIssues !== undefined && uwIssues.length > 0) {
            // added one more condition to by pass the rvp UW Issue if we don't have
            // to show popup if there is only one UW Issue i.e RVP.
            if (ignoreRvpUW && uwIssues.length === 1) {
                const isRvpUWIssuePresent = uwIssues.some(
                    (issue) => (_includes(UWBlockingPoints, issue.currentBlockingPoint))
                        && (issue.longDescription === 'RVP Target Group is Medium')
                );

                if (isRvpUWIssuePresent) {
                    return false;
                }
            }

            const isUWIssuePresent = uwIssues.some(
                (issue) => _includes(UWBlockingPoints, issue.currentBlockingPoint)
            );

            return isUWIssuePresent;
        }

        return false;
    }, [submissionVM]);

    const hasUWIssues = useCallback((ignoreRvpUW = false) => hasUWIssuesOfType(allBlockingPoints, submissionVM, ignoreRvpUW), [hasUWIssuesOfType, submissionVM]);

    // use this function only if submissionVM has UWissues, other wise it would open blank popup
    // If calling right after a state update, we must pass VM
    // as setState is async and VM will not have correct values
    // E1PAP1PC-14149
    const showUnderwritingIssuesPopup = useCallback(async (newSubmissionVM = submissionVM) => {
        const componentProps = {
            submissionVM: newSubmissionVM,
            updateWizardData,
            isPopup: true,
            jumpTo,
            steps,
            redirectURL
        };
        const { modalApi } = _get(window, '__giamfam.modalApi');
        const result = await modalApi.showModal(<RiskAnalysisUWIssuesComponent {...componentProps} />);
        
        return result;
    }, [jumpTo, redirectURL, steps, submissionVM, updateWizardData]);

    return {
        hasUWIssuesOfType,
        hasUWIssues,
        showUnderwritingIssuesPopup
    };
}

export default useUWIssueUtil;
