import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    isUndefined as _isUndefined
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './DiscountsListComponent.metadata.json5';
import styles from './DiscountsListComponent.module.scss';

function DiscountsListComponent(props) {
    /**
    * `discountsTotal` is being passed as prop, since this is the total of different coverables
    * like lineLevel Discounts, Vehicle level discounts, etc.
    * To calculate discounts in component itself, we need the whole transactionVM, not just the discounts array
    * 
    * This component and its heading is only visible when we have any discounts to show.
    */

    const { value: discounts, discountsTotal } = props;
    const [appliedDiscounts, setAppliedDiscounts] = useState([]);

    useEffect(() => {
        setAppliedDiscounts(discounts.filter(
            (item) => item.applied && item.modifierType === 'discount'
        ));
    }, [discounts]);

    const resolvers = {
        resolveClassNameMap: styles,
    };

    const overrideProps = {
        discountTableComponent: {
            visible: !!appliedDiscounts.length,
        },
        discountsTitle: {
            visible: !!appliedDiscounts.length,
        },
        discountValue: {
            value: { currency: 'usd', amount: discountsTotal }
        },
        discountTotalRow: {
            visible: !_isUndefined(discountsTotal)
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={{ discounts: appliedDiscounts }}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

DiscountsListComponent.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({})),
    discountsTotal: PropTypes.number
};

DiscountsListComponent.defaultProps = {
    value: undefined,
    discountsTotal: undefined
};
export default DiscountsListComponent;
