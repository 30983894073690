import React, {
    useCallback,
    useState,
    useEffect
} from 'react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { UnderwritingService } from 'e1p-capability-gateway';

import { ModalNext, ModalHeader, ModalBody, ModalFooter, withModalContext } from '@jutro/components';
import _ from 'lodash';
import metadata from './UWIssueDetailPopup.metadata.json5';
import styles from './UWIssueDetailPopup.module.scss';


import { Button } from '@jutro/legacy/components';


const UWIssueDetailPopup = (props) => {
    const {
        uwIssueVM,
        title,
        viewModelService,
        isOpen,
        onReject
    } = props;
    const [uwFormData, updateUWFormData] = useState(uwIssueVM);
    const [uwHistory, updateUWHistory] = useState(undefined);
    const {
        isComponentValid,
        onValidate
    } = useValidation('uwIssueDetailsPopup');

    const { authHeader } = useAuthentication();

    useEffect(() => {
        const publicId = _.get(uwFormData, 'publicID');

        UnderwritingService
            .getUWHistory(publicId.value, authHeader)
            .then((uwResponse) => {
                const uwHistories = uwResponse.map((uwHistroyInfo) => ({
                        user: uwHistroyInfo.user,
                        issueType: uwHistroyInfo.issueType,
                        blockingPoint: uwHistroyInfo.blockingPoint,
                        creationDate: uwHistroyInfo.creationDate,
                        effectiveDate: uwHistroyInfo.effectiveDate,
                        policyTransaction: uwHistroyInfo.policyTransaction,
                        validUntil: uwHistroyInfo.validUntil,
                        editable: uwHistroyInfo.editable === true ? 'Yes' : 'No',
                        approvalType: uwHistroyInfo.approvalType,
                        status: uwHistroyInfo.status
                    }));

                updateUWHistory(uwHistories);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = viewModelService.clone(uwFormData);

            _.set(nextFormData, path, value);
            updateUWFormData(nextFormData);
        },
        [uwFormData, viewModelService]
    );

    const cancelHandler = useCallback((evt) => {
        evt.stopPropagation();
        onReject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            cancelHandler
        }
    };
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            autoComplete: false
        },
        uwHistoryTable: {
            data: uwHistory
        }
    };

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={title} className={styles.modalTitle} />
            <ModalBody className={styles.modalWidth}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={uwFormData}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    onValidationChange={onValidate}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={cancelHandler} disabled={!isComponentValid}>
                    {'Ok'}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
};

UWIssueDetailPopup.propTypes = {
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    uwIssueVM: PropTypes.shape({}).isRequired,
    onReject: PropTypes.func,
    isOpen: PropTypes.bool,
    authHeader: PropTypes.shape({}),
    title: PropTypes.string
};
UWIssueDetailPopup.defaultProps = {
    title: '',
    isOpen: undefined,
    onReject: undefined,
    authHeader: undefined
};
export default withModalContext(UWIssueDetailPopup);
