/* eslint-disable security/detect-object-injection */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { CurrencyValue } from '@jutro/components';
import { Grid, GridItem } from '@jutro/layout';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import listOfCoveragesReferenceCode from 'e1p-portals-util-js/EHWhatsIncluded-config.json';
import styles from './EHClauseComponentForOptionalCov.module.scss';
import './ClauseComponentVM.messages';

import { CheckboxField } from '@jutro/legacy/components';

class ClauseComponentForOptionalCov extends Component {
    /**
     * @memberof gw-components-platform-react.ClauseComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.displayName - name of clause to display
     * @prop {bool} propTypes.readOnly - if the clause selection is read only
     * @prop {bool} propTypes.value - is the clause is seleceted
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {bool} propTypes.isLoading - should the clause be loading
     * @prop {string} propTypes.loadingMessage - message to be shown while loading
     * @prop {string} propTypes.containerClassName - clause container class
     */

    static propTypes = {
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        readOnly: PropTypes.bool,
        onValueChange: PropTypes.func.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape({})),
        value: PropTypes.bool,
        path: PropTypes.string,
        isLoading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        checked: PropTypes.bool,
        isEditable: PropTypes.bool,
        labelPosition: PropTypes.string,
        amount: PropTypes.shape({}),
        referenceCode: PropTypes.string,
        schedulesMap: PropTypes.shape({}),
        policyType: PropTypes.string,
        hasGoldOrPlatinumBoxVisible: PropTypes.bool.isRequired,
        policyState: PropTypes.string.isRequired
    }

    static defaultProps = {
        readOnly: false,
        path: undefined,
        value: undefined,
        isLoading: false,
        loadingMessage: '',
        checked: false,
        children: undefined,
        isEditable: true,
        labelPosition: 'left',
        amount: undefined,
        referenceCode: '',
        schedulesMap: new Map(),
        policyType: undefined
    }

    handleChange = (value) => {
        const { onValueChange, path } = this.props;

        if (onValueChange) {
            onValueChange(value, path);
        }
    }

    getLabelName = (labelName) => labelName

    renderEditableValue() {
        const {
            displayName,
            readOnly,
            value,
            id,
            isLoading,
            loadingMessage,
            children,
            path,
            checked,
            labelPosition,
            schedulesMap,
            referenceCode,
            policyType,
            hasGoldOrPlatinumBoxVisible,
            policyState
        } = this.props;

        const hasChildren = !_.isNil(children);
        const hasChildrenWithForSelectedCov = value && hasChildren;
        const hasSchedulesWithForSelectedCov = value && schedulesMap.get(referenceCode);
        // showing discount icon with each coverage if condition gets satisfied.
        const checkboxLabelStyles = () => {
            if (!hasGoldOrPlatinumBoxVisible && (policyType === 'HO3' || policyType === 'HF9')) {
                const showDiscountIcon = listOfCoveragesReferenceCode[policyState]?.includes(referenceCode);
                const styleClass = classNames({
                    [styles.appendIcon]: showDiscountIcon
                });

                return styleClass;
            }

            return '';
        };

        return (
            <div>
                {isLoading
                    ? (
                        <E1PLoader
                            loaded={!isLoading}
                            text={loadingMessage}
                        />
                    ) : (
                        <Grid
                                columns={[
                                    '2.25fr',
                                    '1fr'
                                ]}
                                gap="small"
                                id="Grid"
                                rows={[
                                    '1fr'
                                ]}
                                tag="div"
                            >
                                <GridItem
                                    className="jut__Containers__highlightHover"
                                    tag="div"
                                    colSpan="1"
                                >
                                    <CheckboxField
                                        id={id}
                                        label={this.getLabelName(displayName)}
                                        value={value}
                                        readOnly={readOnly}
                                        onValueChange={this.handleChange}
                                        labelClassName={checkboxLabelStyles()}
                                        path={path}
                                        checked={checked}
                                        labelPosition={labelPosition}
                                        showInlineLabel
                                        layout="full-width"
                                    />
                                </GridItem>
                                {(hasChildrenWithForSelectedCov) ? (
                                    <GridItem
                                            className="jut__Containers__highlightHover"
                                            tag="div"
                                            colSpan="1"
                                        >
                                            <div>
                                                {value ? children : null}
                                            </div>
                                        </GridItem>
                                ) : null}
                                {(hasSchedulesWithForSelectedCov) ? (
                                    <GridItem
                                            className={styles.schedule}
                                            tag="div"
                                            colSpan="2"
                                        >
                                            <div>
                                                {schedulesMap.get(referenceCode)}
                                            </div>
                                        </GridItem>
                                ) : null}
                            </Grid>
                    )
                }
            </div>
        );
    }

    renderReadOnlyValue() {
        const {
            displayName, children, amount
        } = this.props;

        return (
            <div className={styles.readOnlyGrid}>
                <span className={styles.readOnlyGridSlotLeft}>
                    {displayName}
                    <span className={styles.readOnlyGridAmount}>
                        {!_.isUndefined(amount) ? (
                            <CurrencyValue
                                amount={amount.amount}
                                currency={amount.currency}
                                showFractions
                            />
                        ) : undefined
                        }
                    </span>
                </span>
                <div className={styles.readOnlyGridSlotRight}>
                    {children}
                </div>
            </div>
        );
    }

    render() {
        const { isEditable } = this.props;

        return isEditable ? this.renderEditableValue() : this.renderReadOnlyValue();
    }
}

export default ClauseComponentForOptionalCov;
