import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    set as _set, get as _get, includes as _includes
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import metadata from './PrivatePassengerQuestionComponent.metadata.json5';
import './PrivatePassengerQuestionComponent.messages';

function PrivatePassengerQuestionComponent(props) {
    const {
        lobDataModel,
        onModelChange,
        viewModelService,
        onValidate,
        id,
        viewOnlyMode,
        isPrivatePassengerQuestionVisible,
        isPageSubmitted,
        underlyingPolicyPageOnValidate
    } = props;
    const translator = useTranslator();
    const [textFieldView, setTextFieldView] = useState(false);
    const [isPassengerAutoDriverInd, setIsPassengerAutoDriverInd] = useState(undefined);
    const [selectedReasonForNotDriving, setSelectedReasonForNotDriving] = useState([]);
    const [options, setOptions] = useState([]);
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        registerComponentValidation
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [lobDataModel, id, onValidate, isComponentValid]);

    useEffect(() => {
        if (underlyingPolicyPageOnValidate) {
            underlyingPolicyPageOnValidate(isComponentValid, id);
        }
    }, [lobDataModel, id, onValidate, isComponentValid, underlyingPolicyPageOnValidate]);

    const availableValues = viewModelService.productMetadata
        .get('pc')
        .types.getTypelist('EUNoOperatorReason_Ext');

    useEffect(
        () => {
            const reasonsForNotDrivingValue = availableValues.codes.map((item) => ({
                    code: item.code,
                    name: translator({
                        id: item.name,
                        defaultMessage: item.name
                    })
                }));

            setOptions(reasonsForNotDrivingValue);

            if (_get(lobDataModel, 'reasonForNotDrivingPrivateAuto.value', []).length) {
                const notDrivingValues = lobDataModel.reasonForNotDrivingPrivateAuto.value.map((item) => item.reasonForNotDriving);
                const descriptionTextField = _includes(notDrivingValues, 'other');

                setSelectedReasonForNotDriving(notDrivingValues);
                setTextFieldView(descriptionTextField);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    useEffect(() => {
        const passengerAutoIndVal = _get(lobDataModel, 'resRelPrivatePassengerAutoInd.value');

        setIsPassengerAutoDriverInd(passengerAutoIndVal);
    }, [lobDataModel, lobDataModel.resRelPrivatePassengerAutoInd.value]);

    const isPageValid = useCallback(() => {
        const isQuoteBlockedDueToDriverQuestion = lobDataModel.resRelPrivatePassengerAutoInd?.value;

        if (!isQuoteBlockedDueToDriverQuestion) {
            const reasonLength = !!lobDataModel.reasonForNotDrivingPrivateAuto?.value?.length;

            return reasonLength;
        }

        return isQuoteBlockedDueToDriverQuestion;
    }, [lobDataModel.reasonForNotDrivingPrivateAuto, lobDataModel.resRelPrivatePassengerAutoInd]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation]);

    const updateReasonForNotDriving = (values) => {
        const reasonForNotDrivingArray = [];

        _set(lobDataModel, 'reasonForNotDrivingPrivateAuto.value', []);

        for (let i = 0; i < values.length; i++) {
            const obj = {
                reasonForNotDriving: values[i],
            };

            reasonForNotDrivingArray.push(obj);
        }

        _set(lobDataModel, 'reasonForNotDrivingPrivateAuto.value', reasonForNotDrivingArray);
        setSelectedReasonForNotDriving(values);

        const otherOptionValue = _includes(values, 'other');

        setTextFieldView(otherOptionValue);

        if (!otherOptionValue) {
            _set(lobDataModel, 'noOperatorReasonOther.value', '');
        }

        onModelChange();
    };

    const updateDrivingQuestion = (value) => {
        if (value) {
            setSelectedReasonForNotDriving([]);
            setTextFieldView(!value);
            _set(lobDataModel, 'reasonForNotDrivingPrivateAuto.value', undefined);
            _set(lobDataModel, 'noOperatorReasonOther.value', undefined);
        }

        _set(lobDataModel, 'resRelPrivatePassengerAutoInd.value', value);
        onModelChange();
    };

    const descriptionBoxValueChange = (val) => {
        _set(lobDataModel, 'noOperatorReasonOther.value', val);
        onModelChange();
    };

    const overrideProps = {
        '@field': {
            showRequired: true,
            readOnly: viewOnlyMode,
            autoComplete: false,
            showErrors: isPageSubmitted
        },
        reasonForNotDriving: {
            visible: isPassengerAutoDriverInd === false,
            availableValues: options,
            onValueChange: (value) => updateReasonForNotDriving(value),
            value: selectedReasonForNotDriving
        },
        AdditionalInfoTitleContainer: {
            visible: isPrivatePassengerQuestionVisible
        },
        privatePassengerDriverQuestionContainer: {
            visible: isPrivatePassengerQuestionVisible
        },
        privatePassengerDriverQuestion: {
            onValueChange: updateDrivingQuestion,
            value: isPassengerAutoDriverInd
        },
        descriptionBox: {
            visible: textFieldView && !isPassengerAutoDriverInd,
            onValueChange: descriptionBoxValueChange
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={lobDataModel}
            onValidationChange={setComponentValidation}
        />
    );
}

PrivatePassengerQuestionComponent.propTypes = {
    viewModelService: PropTypes.shape({
        productMetadata: PropTypes.shape({
            get: PropTypes.func
        }).isRequired
    }).isRequired,
    lobDataModel: PropTypes.shape({
        resRelPrivatePassengerAutoInd: PropTypes.shape({
            value: PropTypes.bool
        }),
        coverables: PropTypes.shape({
            underlyingPolicies: PropTypes.shape({
                value: PropTypes.arrayOf(PropTypes.shape({}))
            })
        }),
        reasonForNotDrivingPrivateAuto: PropTypes.shape({
            value: PropTypes.arrayOf(PropTypes.shape({}))
        })
    }).isRequired,
    onModelChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    isPrivatePassengerQuestionVisible: PropTypes.bool,
    isPageSubmitted: PropTypes.bool,
    underlyingPolicyPageOnValidate: PropTypes.func
};

PrivatePassengerQuestionComponent.defaultProps = {
    id: undefined,
    viewOnlyMode: false,
    isPrivatePassengerQuestionVisible: false,
    isPageSubmitted: false
};
export default PrivatePassengerQuestionComponent;
