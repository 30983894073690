import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    cloneDeep as _cloneDeep,
    set as _set,
    get as _get
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { appendMetadataWithIndex } from '@xengage/gw-jutro-adapters-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from './EAVehiclePrefillComponent.metadata.json5';
import './EAVehiclePrefillComponent.messages';
import styles from './EAVehiclePrefillComponent.module.scss';

function EAVehiclePrefillComponent(props) {
    const {
        value: vehicleVM,
        handleStatusChange,
        index
    } = props;
    const [displayName, setDisplayName] = useState(null);
    const [prefillFormData, setPrefillFormData] = useState(vehicleVM);

    useEffect(() => {
        if (!prefillFormData.vehicleDisplayName) {
            // in rare cases, one or more of these values could be null
            prefillFormData.vehicleDisplayName = `
                ${prefillFormData.year || ''}${prefillFormData.year ? ' ' : ''}
                ${prefillFormData.make || ''}${prefillFormData.make ? ' ' : ''}
                ${prefillFormData.model || ''}${prefillFormData.model ? ' ' : ''}
            `;
            setDisplayName(prefillFormData.vehicleDisplayName);
        }
    }, [prefillFormData, prefillFormData.make, prefillFormData.model, prefillFormData.vehicleDisplayName, prefillFormData.year]);

    const onPrefillVehicleRadioButtonValueChange = useCallback(
        (radioButtonCode) => {
            const updatedFormData = _cloneDeep(prefillFormData);

            _set(updatedFormData, 'addToQuote', radioButtonCode);
            setPrefillFormData(updatedFormData);
            handleStatusChange(radioButtonCode, index);
        },
        [handleStatusChange, index, prefillFormData]
    );

    const formattedMetadata = useMemo(() => appendMetadataWithIndex(metadata.pageContent, index), [index]);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        [`eaPrefillVehicle${index}`]: {
            readOnly: true,
            value: displayName,
        },
        [`vehicleStatus${index}`]: {
            value: String(_get(prefillFormData, 'addToQuote')),
            defaultValue: 'true',
            availableValues: [
                {
                    code: 'true',
                    name: e1pCommonMessages.yes
                },
                {
                    code: 'false',
                    name: e1pCommonMessages.no
                }],
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            onPrefillVehicleRadioButtonValueChange
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={formattedMetadata}
            model={prefillFormData}
            onValueChange={handleStatusChange}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

EAVehiclePrefillComponent.propTypes = {
    value: PropTypes.shape({}),
    index: PropTypes.number.isRequired,
    handleStatusChange: PropTypes.func.isRequired
};
EAVehiclePrefillComponent.defaultProps = {
    value: {}
};
export default EAVehiclePrefillComponent;
