import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class ContingencyService {
    /**
     * Selects a quote
     * @param {Object} dto ContentRequestDTO
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static retrieveContingenciesForPolicy = (dto, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('contingency'),
            'retrieveContingenciesForPolicy',
            [dto],
            additionalHeaders
        );
    }

    static updateContingencyStatus = (dto, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('contingency'),
            'updateContingencyStatus',
            [dto],
            additionalHeaders
        );
    }
}
