
import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, find, set
} from 'lodash';

import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDriverPageUtil } from 'e1p-capability-hooks';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import metadata from '../DriversPage.metadata.json5';

function EAViewDriversPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        onValidate
        // registerInitialComponentValidation
    } = useValidation('EAViewDriversPage');

    const { authUserData } = useAuthentication();
    const [checkScrolling, setCheckScrolling] = useState(false);
   
    const {
        isDefensiveDriverDiscountAvailableForDriver,
        getDriverAge,
        isFinancialResponsibilityFormAvailableForDriver
    } = useDriverPageUtil();

    policyChangeVM.flowStepIDs_Ext.value = ['driver'];
    policyChangeVM.entryCompletionStepIDs_Ext.value = ['driver'];

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';
        const drivers = get(policyChangeVM, `${driversPath}`);
        // eslint-disable-next-line max-len
        const pniPublicID = policyChangeVM.lobData.personalAuto_EA.primaryNamedInsured.person.publicID.value;
        const sniPublicID = policyChangeVM.lobData.personalAuto_EA.secondaryNamedInsured.person
            ? policyChangeVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value
            : undefined;
        const pni = find(drivers.children, (driver) => {
            return driver.person.publicID.value === pniPublicID;
        });
        let sni;
        if (sniPublicID) {
            sni = find(drivers.children, (driver) => {
                return driver.person.publicID.value === sniPublicID;
            });
            if (sni) {
                set(sni, 'value.role', 'sni');
            }
        }
        if (pni) {
            set(pni, 'value.role', 'pni');
        }
    }, [policyChangeVM, policyChangeVM.lobData.personalAuto_EA.coverables.drivers]);

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(() => {
        return policyChangeVM;
    }, [policyChangeVM]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'top',
            readOnly: true
        },
        driversPageLoadingIndicator: {
            loaded: true
        },
        driversPageContainer: {
            visible: true
        },
        addDriver: {
            visible: false
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('eadrivercontainer')
        },
        inlineNotificationContainer: {
            visible: false
        },
        EADriverGrid: {
            drivers: get(policyChangeVM, 'lobData.personalAuto_EA.coverables.drivers', []),
            path: 'lobData.personalAuto_EA.coverables.drivers.children',
            onValidate: ()=>{},
            viewModelService: viewModelService,
            showErrors: false,
            policyState: get(policyChangeVM, 'baseData.policyAddress.state.value.code'),
            driverPageDisregardFieldValidation: ()=>{},
            checkLicenseRequired: ()=>{},
            driversWithLicenseValidationError: [],
            setDriversWithLicenseValidationErrors: ()=>{},
            getDriverAge: getDriverAge,
            periodStartDate: get(policyChangeVM, 'baseData.periodStartDate.value'),
            priorPolicyList: get(policyChangeVM, 'lobData.personalAuto_EA.priorPolicyUpdates'),
            onRemoveDriver: ()=>{},
            isFinancialResponsibilityFormAvailableForDriver,
            isNewSubmission: false,
            viewOnlyMode: true,
            onValidationChange: ()=>{},
            setSNIHasDefensiveCourse: ()=>{},
            setPNIHasDefensiveCourse: ()=>{},
            authUserData,
            transactionVM: policyChangeVM,
            isDefensiveDriverDiscountAvailableForDriver,
            updateWizardData: ()=>{},
            setCheckScrolling
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps, policyChangeVM]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onAddDriverClick: () => { },
            onRemoveDriver: () => { },
            onValidate: onValidate,
            onPrefillData: undefined,
            onViewModelService: viewModelService,
            onCheckLicense: () => { }
        },
        resolveComponentMap: {
            WizardSingleErrorComponent: WizardSingleErrorComponent
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

EAViewDriversPage.propTypes = wizardProps;
export default EAViewDriversPage;
