import { defineMessages } from 'react-intl';

export default defineMessages({
    chooseFloorType: {
        id: 'quoteandbind.eh.views.component.floor.Choose Floor Type',
        defaultMessage: 'Choose Floor Type',
    },
    percentage: {
        id: 'quoteandbind.eh.views.component.floor.Percentage',
        defaultMessage: 'Percentage',
    }
});
