import { defineMessages } from 'react-intl';

export default defineMessages({
    activities: {
        id: 'e1p.gateway.views.renewal.renewal-activities.Activities',
        defaultMessage: 'Activities'
    },
    activity: {
        id: 'e1p.gateway.views.renewal.renewal-activities.Activity',
        defaultMessage: 'Activity'
    },
    searchactivities: {
        id: 'e1p.gateway.views.renewal.renewal-activities.Search activities',
        defaultMessage: 'Search activities'
    },
    note: {
        id: 'e1p.gateway.views.renewal.renewal-notes.Note',
        defaultMessage: 'Note'
    },
    billingPageLink: {
        id: 'e1p.gateway.views.renewal.renewal-summary.If you need to make a payment, please goto...',
        defaultMessage: 'If you need to make a payment, please go to the {billingPageLink}. If you would like to cancel the renewal please go to the %%policyLink%%.'
    },
    mayWithdrawRenewal: {
        id: 'e1p.gateway.views.renewal.renewal-summary.You may withdraw the renewal',
        defaultMessage: 'You may withdraw the renewal'
    },
    cancelRenewal: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Cancel Renewal',
        defaultMessage: 'Cancel Renewal'
    },
    continueRenewal: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Continue Renewal',
        defaultMessage: 'Continue Renewal'
    },
    premiumRenewalCalculated: {
        id: 'e1p.gateway.views.renewal.renewal-summary.The premium for this renewal has been calculated',
        defaultMessage: 'The premium for this renewal has been calculated'
    },
    thisRenewalHasBeenBound: {
        id: 'e1p.gateway.views.renewal.renewal-summary.This renewal has been bound',
        defaultMessage: 'This renewal has been bound'
    },
    newPremium: {
        id: 'e1p.gateway.views.renewal.renewal-summary.New Premium',
        defaultMessage: 'New Premium'
    },
    transactionSummary: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Transaction Summary',
        defaultMessage: 'Transaction Summary'
    },
    increaseOf: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Increase of',
        defaultMessage: 'Increase of {value}%'
    },
    totalPremium: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Total Premium',
        defaultMessage: 'Total Premium'
    },
    currentPremium: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Current Premium',
        defaultMessage: 'Current Premium'
    },
    changeinCost: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Change in Cost',
        defaultMessage: 'Change in Cost'
    },
    tax: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Tax',
        defaultMessage: 'Tax'
    },
    totalPremiumTaxesandFeesTax: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Total Premium + Taxes and Fees(Tax)',
        defaultMessage: '%%totalPremium%% + %%taxesAndFees%% (Tax)'
    },
    createdDate: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Created Date',
        defaultMessage: 'Date Created'
    },
    policyInception: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Policy Inception',
        defaultMessage: 'Policy Inception'
    },
    nonRenewReason: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Non-Renew Reason',
        defaultMessage: 'Non-Renew Reason'
    },
    status: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Status',
        defaultMessage: 'Status'
    },
    policy: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Policy',
        defaultMessage: 'Policy'
    },
    renewalEffectiveDate: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Renewal Effective Date',
        defaultMessage: 'Renewal Effective Date'
    },
    currentPolicyExpiration: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Current Policy Expiration',
        defaultMessage: 'Current Policy Expiration'
    },
    currentPolicy: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Current Policy',
        defaultMessage: 'Current Policy'
    },
    policybillingpage: {
        id: 'e1p.gateway.views.renewal.renewal-summary.policy billing page',
        defaultMessage: 'policy billing page'
    },
    policydetailspage: {
        id: 'e1p.gateway.views.renewal.renewal-summary.policy details page',
        defaultMessage: 'policy details page'
    },
    withdrawRenewal: {
        id: 'gateway.views.job-detail-summary.Withdraw Renewal',
        defaultMessage: 'Withdraw Renewal'
    },
    doNotCancel: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Do Not Cancel',
        defaultMessage: 'Do Not Cancel'
    },
    decidedToAllowRenewPolicy: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.It has been decided not to allow renewal of this policy.',
        defaultMessage: 'It has been decided not to allow renewal of this policy. The policy will expire on the current expiration date.'
    },
    insurerRequest: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Insurer Request',
        defaultMessage: 'Insurer Request'
    },
    decidedToDeclineRenewPolicy: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.The customer has decided to decline the renewal of the policy.',
        defaultMessage: 'The customer has decided to decline the renewal of the policy.'
    },
    customerRequest: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Customer Request',
        defaultMessage: 'Customer Request'
    },
    sureWithdrawRenew: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Are you sure you want to withdraw the Renewal?',
        defaultMessage: 'Are you sure you want to withdraw the Renewal?'
    },
    withdrawTheRenewal: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Withdraw the Renewal?',
        defaultMessage: 'Withdraw the Renewal?'
    },
    reasonForCancellingRenew: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Reason for Cancelling the Renewal',
        defaultMessage: 'Reason for Cancelling the Renewal'
    },
    cancelCtrlRenewal: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Cancel Renewal',
        defaultMessage: 'Cancel Renewal'
    },
    doNotWithdraw: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Do Not Withdraw',
        defaultMessage: 'Do Not Withdraw'
    },
    withdrawCtrlRenewal: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Withdraw Renewal',
        defaultMessage: 'Withdraw Renewal'
    },
    startedByMistakeNoProblem: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Was this renewal started by mistake? No problem...',
        defaultMessage: 'Was this renewal started by mistake? No problem, you can always withdraw the renewal transaction.'
    },
    totalPremiumAndTaxes: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Total Premium + Taxes and Fees (Tax)',
        defaultMessage: '{totalPremium} + {taxesAndFees} (Tax)'
    },
    failedToRenewal: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Failed to cancel renewal.',
        defaultMessage: 'Failed to cancel renewal.'
    },
    errorNotification: {
        id: 'e1p.policyview.ea.renewal.inlineNotification.There are outstanding changes for this renewal. Apply changes to continue processing.',
        defaultMessage: 'There are outstanding changes for this renewal. Apply changes to continue processing.'
    },
    viewRenewalButton: {
        id: 'gateway.views.job-detail-summary.View Renewal',
        defaultMessage: 'View Renewal'
    },
    continueEditRenewalButton: {
        id: 'gateway.views.job-detail-summary.Continue Editing Renewal',
        defaultMessage: 'Continue Editing Renewal'
    },
    editRenewalButton: {
        id: 'gateway.views.job-detail-summary.Edit Renewal',
        defaultMessage: 'Edit Renewal'
    },
    renewalDirectionNotTaken: {
        id: 'e1p.renewal.summary.editRenewalDirection.Not Taken',
        defaultMessage: 'Not Taken'
    },
    renewalDirectionNonRenew: {
        id: 'e1p.renewal.summary.editRenewalDirection.Non-Renew',
        defaultMessage: 'Non-Renew'
    },
    renewalDirectionRenew: {
        id: 'e1p.renewal.summary.editRenewalDirection.Renew',
        defaultMessage: 'Renew'
    },
    withdrawTransaction: {
        id: 'e1p.renewal.summary.withdrawRenewal.Withdraw Renewal',
        defaultMessage: 'Withdraw Renewal'
    },
    withdrawRenewalLabel: {
        id: 'e1p.renewal.summary.withdrawRenewal.Withdraw Renewal',
        defaultMessage: 'Withdraw Renewal'
    },
    withdrawRenewalInfoMessage: {
        id: 'e1p.renewal.summary.withdrawRenewal.This will withdraw the entire policy period, and all changes will be lost. A new work order will be created in the next batch cycle.',
        defaultMessage: 'This will withdraw the entire policy period, and all changes will be lost. A new work order will be created in the next batch cycle.'
    },
    renewalTermEffectiveDate: {
        id: 'e1p.gateway.views.policy-detail-summary.Renewal Term Effective Date',
        defaultMessage: 'Term Effective Date'
    },
    withdrawRenewalCreditReconsiderationMessage: {
        id: 'e1p.renewal.summary.withdrawRenewal.This will withdraw the entire policy period, and all changes will be lost. A new workorder will be created in the next batch cycle.<br/><br/>The reordered credit will not be withdrawn.',
        defaultMessage: 'This will withdraw the entire policy period, and all changes will be lost. A new workorder will be created in the next batch cycle.<br/><br/>The reordered credit will not be withdrawn.'
    },
    renewalDirectionEditStatus: {
        id: 'gateway.views.job-detail-summary.Edit Status',
        defaultMessage: 'Edit Status'
    }
});
