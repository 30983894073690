import _ from 'lodash';
import messages from './ActivityAdvancedFilter.messages';

export default class {
    constructor() {
        this.activityStatusFilter = {
            displayKey: messages.activitieslandingStatus,
            displayValue: '',
            options: [{
                code: 'open',
                displayKey: messages.activitieslandingOpen,
                displayValue: '',
                selected: false
            }, {
                code: 'complete',
                displayKey: messages.activitieslandingComplete,
                displayValue: '',
                selected: false
            }, {
                code: 'canceled',
                displayKey: messages.activitieslandingCanceled,
                displayValue: '',
                selected: false
            }, {
                code: 'skipped',
                displayKey: messages.activitieslandingSkipped,
                displayValue: '',
                selected: false
            }]
        };

        this.activityCategoryFilter = {
            displayKey: messages.activitieslandingCategory,
            displayValue: '',
            options: [{
                code: 'ICS',
                displayKey: messages.activitieslandingIcs,
                displayValue: '',
                selected: false
            }, {
                code: 'ICSUW',
                displayKey: messages.activitieslandingIcsUw,
                displayValue: '',
                selected: false
            }]
        };

        this.activityPriorityFilter = {
            displayKey: messages.activitieslandingPriority,
            displayValue: '',
            options: []
        };

        this.activityAssignmentFilter = {
            displayKey: messages.activitieslandingAssignment,
            displayValue: '',
            options: [{
                code: 'assignedToMe',
                displayKey: messages.activitieslandingToMe,
                displayValue: '',
                selected: false
            }, {
                code: 'assignedToOthers',
                displayKey: messages.activitieslandingToOthers,
                displayValue: '',
                selected: false
            }]
        };

        this.activityCreatedByFilter = {
            displayKey: messages.activitieslandingCreatedBy,
            displayValue: '',
            options: [{
                code: 'createdByMe',
                displayKey: messages.activitieslandingMe,
                displayValue: '',
                selected: false
            }, {
                code: 'createdByOthers',
                displayKey: messages.activitieslandingOthers,
                displayValue: '',
                selected: false
            }]
        };

        this.customFilter = {
            filters: [
                this.activityCategoryFilter,
                this.activityStatusFilter,
                this.activityAssignmentFilter,
                this.activityPriorityFilter,
                this.activityCreatedByFilter
            ]
        };
    }

    createActivityStatusFilterOptions = (activity) => {
        if (!this.activityStatusFilter.options.some(
            (option) => option.code === activity.status
        )) {
            const newFilter = {
                code: activity.status,
                displayKey: activity.status,
                displayValue: activity.status,
                selected: false
            };

            this.activityStatusFilter.options.push(newFilter);
        }
    };

    createActivityPriorityFilterOptions = (priorityTypeList, translator) => {
        const priorityOptions = [];

        Object.keys(priorityTypeList).forEach((code) => {
            if (_.has(priorityTypeList[code], 'code')) {
                const displayName = translator({
                    id: priorityTypeList[code].name,
                    defaultMessage: priorityTypeList[code].name
                });
                const newFilter = {
                    code: priorityTypeList[code].code,
                    displayKey: displayName,
                    displayValue: displayName,
                    selected: false,
                    priority: priorityTypeList[code].priority
                };

                priorityOptions.push(newFilter);
            }
        });
        this.activityPriorityFilter.options = _.sortBy(priorityOptions, 'priority');
    };

    createCustomFilterOptions = (activities, activityVM, translator) => {
        const priorityList = _.get(activityVM, 'priority.aspects.availableValues');

        this.createActivityPriorityFilterOptions(priorityList, translator);
        activities.forEach((activity) => {
            this.createActivityStatusFilterOptions(activity);
        });

        return this.customFilter.filters;
    };

    setAllFilterOptionsSelected = (Filter, value) => Filter.options.forEach((option) => {
            const optionItem = option;

            optionItem.selected = value;
        });

    selectedOption = (customFilter, filters) => {
        const filterIndex = _.findIndex(customFilter,
            (o) => o.displayKey === filters.displayKey);

        _.set(customFilter, filterIndex, filters);

        return customFilter;
    };

    setCustomFiltersSelected = (customFilter, value) => customFilter.forEach((filter) => {
            this.setAllFilterOptionsSelected(filter, value);
        });

    setCustomFilterFieldsForQuickFilter = (selectedTile) => {
        if (selectedTile) {
            switch (selectedTile) {
                case 'yourOpenActivityTile': {
                    const openOptionMyOpenFilter = _.find(this.activityStatusFilter.options,
                        (option) => option.code === 'open');

                    openOptionMyOpenFilter.selected = true;

                    const assignedToMeOptionMyOpenFilter = _.find(
                        this.activityAssignmentFilter.options,
                        (option) => option.code === 'assignedToMe'
                    );

                    assignedToMeOptionMyOpenFilter.selected = true;
                    break;
                }
                case 'yourCompletedActivityTile': {
                    const completeOptionMyClosedFilter = _.find(
                        this.activityStatusFilter.options,
                        (option) => option.code === 'complete'
                    );

                    completeOptionMyClosedFilter.selected = true;

                    const assignedToMeOption = _.find(this.activityAssignmentFilter.options, (option) => option.code === 'assignedToMe');

                    assignedToMeOption.selected = true;
                    break;
                }
                case 'createdByYouActivityTile': {
                    const createdByMeOption = _.find(this.activityCreatedByFilter.options, (option) => option.code === 'createdByMe');

                    createdByMeOption.selected = true;

                    this.setAllFilterOptionsSelected(this.activityStatusFilter, true);
                    // setCustomFilters(customFilter.filters);
                    break;
                }
                case 'allOpenActivityTile': {
                    const openOption = _.find(this.activityStatusFilter.options, (option) => option.code === 'open');

                    openOption.selected = true;

                    this.setAllFilterOptionsSelected(this.activityAssignmentFilter, true);
                    break;
                }
                case 'allCompletedActivityTile': {
                    const completeOption = _.find(this.activityStatusFilter.options, (option) => option.code === 'complete');

                    completeOption.selected = true;

                    this.setAllFilterOptionsSelected(this.activityAssignmentFilter, true);
                    break;
                }
                case 'allCancelledActivityTile': {
                    const canceledOption = _.find(this.activityStatusFilter.options, (option) => option.code === 'canceled');

                    canceledOption.selected = true;

                    this.setAllFilterOptionsSelected(this.activityAssignmentFilter, true);
                    break;
                }
                default:
                    break;
            }
        }

        return this.customFilter.filters;
    };

    filterByDisplayValue = (filter, value) => filter.options
            ? filter.options.some(
                (option) => option.selected && option.displayValue === value
            ) : true;

    filterByCode = (filter, value) => filter.options
            ? filter.options.some(
                (option) => option.selected && option.code === value
            ) : true;

    filterActivityByAssignment = (activity, activityAssignmentFilter) => {
        const selectedOptions = activityAssignmentFilter.options.filter(
            (option) => option.selected
        );

        return selectedOptions.some((option) => ((option.code === 'assignedToMe') ? activity.isAssignedToCurrentUser : !activity.isAssignedToCurrentUser));
    };

    filterActivityByCreatedBy = (activity, activityCreatedByFilter) => {
        const selectedOptions = activityCreatedByFilter.options.filter(
            (option) => option.selected
        );

        return selectedOptions.some((option) => ((option.code === 'createdByMe') ? activity.isCreatedByCurrentUser : !activity.isCreatedByCurrentUser));
    };

    filterActivity = (customFilters, activity) => {
        let showActivity = true;

        if (customFilters) {
            const [activityStatusFilter,
                activityAssignmentFilter,
                activityPriorityFilter,
                activityCreatedByFilter,
                activityCategoryFilter
            ] = customFilters;
            const selectedCustomFilters = _.filter(customFilters, (filter) => filter.options.some((option) => option.selected));

            for (let i = 0; i < selectedCustomFilters.length; i += 1) {
                const customFilter = selectedCustomFilters[i];

                switch (customFilter) {
                    case activityStatusFilter:
                        showActivity = this.filterByCode(
                            activityStatusFilter, activity.status
                        );
                        break;
                    case activityPriorityFilter:
                        showActivity = this.filterByCode(
                            activityPriorityFilter, activity.category
                        );
                        break;
                    case this.activityCategoryFilter:
                        showActivity = this.filterByCode(
                            activityCategoryFilter, activity.priority
                        );
                        break;
                    case activityAssignmentFilter:
                        showActivity = this.filterActivityByAssignment(activity,
                            activityAssignmentFilter);
                        break;
                    case activityCreatedByFilter:
                        showActivity = this.filterActivityByCreatedBy(activity,
                            activityCreatedByFilter);
                        break;
                    default:
                        showActivity = false;
                        break;
                }

                if (!showActivity) {
                    break;
                }
            }
        }

        return showActivity;
    };

    filterActivities = (customFilters, allActivities) => {
        const activities = allActivities.filter(
            (activity) => this.filterActivity(customFilters, activity) && activity
        );

        return activities;
    };
}