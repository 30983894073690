import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { withModalContext } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { RenewalService } from 'gw-capability-gateway';
import { UnderWritingIssuesComponent } from 'e1p-capability-policyjob-react';
import { UWIssueFormatUtil } from 'e1p-portals-util-js'
import gatewayMessages from '../../gateway.messages';
import metadata from './UWWorkBench.metadata.json5';

class RenewalDetailsUWIssuesWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}),
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string,
                getRenewalSummaryCount: PropTypes.func
            }),
            basePath: PropTypes.string
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired
    }

    static defaultProps = {
        authHeader: undefined
    }

    state = {
        underwritingData: []
    }

    componentDidMount() {
        this.getUWIssues();
    }

    handleError = (title, message) => this.props.modalContext.showAlert({
            status: 'error',
            icon: 'mi-error-outline',
            title,
            message
        })

    getUWIssues = (async () => {
        const {
            authHeader, fromAccountLanding:
            { quoteDetailsData: { jobNumber, getRenewalSummaryCount } }
        } = this.props;

        try {
            const getALLUWIssueData = await RenewalService
                .getUWIssuesForRenewal([jobNumber], authHeader);

            const sortedUWIssueData = UWIssueFormatUtil.formatAndSortUWIssues(getALLUWIssueData);

            this.setState({
                underwritingData: sortedUWIssueData
            });
            getRenewalSummaryCount();
        } catch (e) {
            this.handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorLoadingUWIssues
            );
        }
    })

    setUnderwritingIssues = (underwritingIssues) => {
        const sortedUWIssueData = UWIssueFormatUtil.formatAndSortUWIssues(underwritingIssues);

        this.setState({
            underwritingData: sortedUWIssueData
        });
    };

    render() {
        const { underwritingData } = this.state;
        const {
            fromAccountLanding: { quoteDetailsData: { jobNumber } }
        } = this.props;
        const overrides = {
            renewalDetailsUWIssues: {
                underwritingIssues: underwritingData,
                quoteID: jobNumber,
                setunderwritingIssues: this.setUnderwritingIssues,
                hideHighProfileSurcharge: true
            }
        };
        const resolvers = {
            resolveComponentMap: {
                underwritingIssuesComponent: UnderWritingIssuesComponent
            }
        };

        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

const RenewalDetailsUWIssues = withModalContext(RenewalDetailsUWIssuesWithoutModalContext);

export default withAuthenticationContext(RenewalDetailsUWIssues);
