import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
    set, isEmpty, get, uniqBy
} from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { PolicyDiffService, ChangePhraseService } from 'e1p-capability-policyjob';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { RenewalService } from 'e1p-capability-renewal';
import { PaymentPageUtil } from 'e1p-portals-util-js';
import { useUWIssueUtil, useRenewalPageUtil } from 'e1p-capability-hooks';
import metadata from './RenewalChangeDiffPage.metadata.json5';
import messages from './RenewalChangeDiffPage.messages';

function RenewalChangeDiff(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        jumpTo,
        steps,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const [policyDiffData, setPolicyDiffData] = useState(null);
    const [renewalPhraseData, setRenewalPhraseData] = useState([]);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [signatureSuccess, setSignatureSuccess] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isOOSComoponentValid, updateIsOOSComoponentValid] = useState(true);
    // It is used to show loader message 'Preparing Required Documents' when gettingSignatureDocument is true
    const [gettingSignatureDocument, setGettingSignatureDocument] = useState(false);
    const {
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('RenewalDiff');

    const {
        hasUWIssuesOfType,
        showUnderwritingIssuesPopup,
        hasUWIssues
    } = useUWIssueUtil(
        renewalVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const {
        applyChangesToRenewal,
        setDefaultValuesForSignature
    } = useRenewalPageUtil(
        renewalVM,
        updateWizardData,
        setIsSavingRenewal,
        showUnderwritingIssuesPopup
    );

    // Check if any form requires signature
    const isSignatureRequired = get(
        renewalVM, 'lobData.personalAuto_EA.hasSignatureRequiredFormsInCurrentJob.value'
    );

    const conflictsPresent = !isEmpty(get(renewalVM, 'value.conflicts', []));
    const [activeTab, setActiveTab] = useState(conflictsPresent ? 'changeConflictsTab' : 'changePhrases');
    
    // when confilcts are resolved, conflicts tab is getting removed,
    // then we need to set change phrases as active tab
    useEffect(() => {
        setActiveTab(conflictsPresent ? 'changeConflictsTab' : 'changePhrases');
    },[conflictsPresent]);
    
    useEffect(() => {
        registerComponentValidation(
            () => !hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease'])
                    && ((isSignatureRequired && signatureSuccess) || !isSignatureRequired)
        );
    }, [
        hasUWIssuesOfType, isSignatureRequired,
        registerComponentValidation, signatureSuccess
    ]);

    const checkuwIssues = useCallback(() => {
        if (hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease'])) {
            showUnderwritingIssuesPopup();
        }
    }, [hasUWIssuesOfType, showUnderwritingIssuesPopup]);

    useEffect(() => {
        /**
         * E1PAP1PC-14713 :
         * defaulting signature fields
         */
        if (isSignatureRequired) {
            setDefaultValuesForSignature(authUserData, setSignatureSuccess);
        }

        // Show UW issues if quote is blocked
        checkuwIssues();

        // Call the policy diff
        PolicyDiffService.getPolicyDiffWithPrevious(
            [renewalVM.jobID.value], authHeader
        ).then(setPolicyDiffData);

        ChangePhraseService.getChangePhrase(
            renewalVM.jobID.value, authHeader
        ).then((response) => {
            const mappedPhrases = response.map((phrase) => ({
                    name: phrase
                }));

            setRenewalPhraseData(mappedPhrases);
        });
        // It should call when page is render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
    *Checking if valid signature or not
     */
    const isPageValid = useCallback(() => {
        let isSignatureDone = get(renewalVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;

        if (!isSignatureRequired) {
            // no signature needed
            isSignatureDone = true;
        }

        return isSignatureDone && !checkuwIssues();
    }, [renewalVM, signatureSuccess, isSignatureRequired, checkuwIssues]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, signatureSuccess]);

    const writeValue = useCallback(
        (value, path) => {
            set(renewalVM, path, value);
            updateWizardData(renewalVM);
        }, [renewalVM, updateWizardData]
    );

    const setErrors = useCallback((removeUnnecessaryErrors = false) => {
        const isSignatureDone = get(renewalVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;
        const isSignatureMissing = isSignatureRequired && !isSignatureDone;
        
        const isUwIssuePresent = hasUWIssues();
        
        // no payments in renewal
        const shouldShowMissingPaymentError = false;

        const errors = PaymentPageUtil.getValidationErrorsForPaymentPage(
            validationErrors, shouldShowMissingPaymentError, isSignatureMissing, isUwIssuePresent, translator, removeUnnecessaryErrors);

        const uniqueErrors= uniqBy(errors, 'description');

        setValidationErrors(uniqueErrors);

        return uniqueErrors;

    }, [renewalVM, signatureSuccess, isSignatureRequired, hasUWIssues, validationErrors, translator]);

    useEffect(() => {
        if (signatureSuccess) {
            setErrors(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureSuccess])

    /**
     * set errors and move to signature tab if signature is required and only signature is pending.
     */
    const setErrorsAndMovetoSignatureTabIfRequired = useCallback(() => {
        const activeErrors = setErrors();

        const isOnlySignatureErrorLeft =
            activeErrors.length === 1 &&
            activeErrors.find(
                (error) =>
                    error.description ===
                    translator(e1pCommonMessages.signatureRequiredInfoMessage)
            );
        const isSignatureDone = get(renewalVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;

        if (isSignatureRequired && !isSignatureDone && isOnlySignatureErrorLeft) {
            setActiveTab('signatureTab');
        }
    },[isSignatureRequired, renewalVM, setErrors, signatureSuccess, translator]);

    const resolvers = {
        resolveComponentMap: {
            WizardSingleErrorComponent
        },
        resolveCallbackMap: {
            sortString: DatatableUtil.sortString
        }
    };

    const onSave = useCallback(
        async (_, savingLocationCodeChanges = false) => {
            if (!savingLocationCodeChanges) {
                if (!isComponentValid) {
                    updateIsPageSubmitted(true);
                    setErrorsAndMovetoSignatureTabIfRequired();
                    window.scrollTo(0, 0);

                    return false;
                }
            }

            setIsSavingCurrentPageChanges(true);

            try {
                const signatureType = get(renewalVM, 'value.baseData.signatureType_ext');

                const quoteRenewal = await RenewalService.saveAndQuoteRenewal(
                    [(renewalVM.value)],
                    authHeader
                );

                set(renewalVM, 'value', quoteRenewal);
                updateWizardData(renewalVM);

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    set(renewalVM, 'value.baseData.signatureType_ext', signatureType);
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }

            return true;
        },
        [
            authHeader, isComponentValid, renewalVM,
            setErrorsAndMovetoSignatureTabIfRequired, updateWizardData, updateWizardSnapshot
        ]
    );

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (gettingSignatureDocument) {
            loadingMessage = translator(e1pCommonMessages.prepareReqDocuments);
        } else {
            loadingMessage = translator(messages.completingYourPurchaseMessage);
        }

        return loadingMessage;
    }, [gettingSignatureDocument, isSavingCurrentPageChanges, translator]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        renewalChangeDiffPageLoadingIndicator: {
            loaded: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges && !gettingSignatureDocument,
            text: getLoadingIndicatorMessage
        },
        premiumMightChangeMessageDiv: {
            visible: renewalVM.baseData.periodStatus.value.code !== 'Bound'
        },
        renewalChangeDiffPageContainer: {
            visible: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges,
            // Facing some issues, if i try to hide the container using visibility. 
            // Initially, visibility is false so all the components will be unmount 
            // when the visibility become true then all components will be mount again 
            // it will re-trigger all the react hook components so handled hiding using CSS
            className: gettingSignatureDocument ? 'display-none' : ''
        },
        ehPolicyDiff: {
            quote: policyDiffData,
            visible: !isEmpty(policyDiffData)
        },
        changePhraseDataTable: {
            data: renewalPhraseData
        },
        policychangeSummaryTabset: {
            defaultActiveTab: 'changePhrases',
            activeTab,
            onTabChange: (tabId) => {
                setActiveTab(tabId);
            }
        },
        changeConflictsTab: {
            visible: conflictsPresent
        },
        e1pOOSConflictsInlineErrorMessageComponent: {
            visible: conflictsPresent
        },
        PremiumBox: {
            // wasn't given an agnostic prop name
            renewalVM,
            viewOnlyMode: true,
            authHeader
        },
        changeConflicts: {
            conflicts: get(renewalVM, 'value.conflicts', []),
            jobNumber: get(renewalVM, 'value.jobID', ''),
            authHeader,
            changeEffectiveDate: get(renewalVM.value, 'baseData.effectiveDate'),
            writeValue,
            isPageSubmitted,
            updateIsPageSubmitted,
            updateIsOOSComoponentValid
        },
        signatureTab: {
            visible: isSignatureRequired
        },
        // Should be visible whenever custom validation errors are triggered
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        signatureComponent: {
            submissionVM: renewalVM,
            updateWizardData,
            authHeader,
            LOB: 'personalAuto_EA',
            onSignatureSuccess: setSignatureSuccess,
            producerCodeOptions,
            setProducerCodeOptions,
            setGettingSignatureDocument,
            showErrors: isPageSubmitted,
            saveLocationCodeChanges: (() => {
                onSave(undefined, true);
            }),
            locationCodeEditable: true
        }
    };

    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, renewalVM, id, path, overrideProps), [overrideProps, renewalVM]);

    return (
        <WizardPage
            nextLabel={translator(messages.issue)}
            disablePrevious={gettingSignatureDocument}
            disableCustom1 ={gettingSignatureDocument}
            showNext={false}
            onCustom1={() => {
                if (!isComponentValid) {
                    updateIsPageSubmitted(true);
                    setErrorsAndMovetoSignatureTabIfRequired();
                    window.scrollTo(0, 0);

                    return false;
                }

                return applyChangesToRenewal();
            }}
            showCustom1
            custom1ButtonType="filled"
            onCustom={onSave}
            showCustom
            customLabel={translator(e1pCommonMessages.save)}
            isPageSubmittedWithErrors={isPageSubmitted && !isOOSComoponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                resolveValue={readValue}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}


RenewalChangeDiff.propTypes = wizardProps;
export default RenewalChangeDiff;
