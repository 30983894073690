import React, { useCallback, useMemo } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { get, set } from 'lodash';
import moment from 'moment';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

/**
 * @param {*} model optional because "getDefaultPeriodStartDate" doesn't need it.
 * @param {*} updateWizardData optional because "handlePeriodStartDateChange" is the
 *              only exposed function that is updating state.
 * @param {*} use12MonthPolicyTerm -- A boolean indicating whether the policy is using a 12-month
 *              term. A 6-month term will be used if this value is "false".
 * @returns {*} object
 */
function useEffectiveDateUtil(
    model = {},
    updateWizardData = undefined,
    use12MonthPolicyTerm = true
) {
    const { authUserData } = useAuthentication();
    const hasUnlimitedBackDatePermission = authUserData.permissions_Ext.includes('unlimitedbackdate');

    function addMonths(effectiveDate) {
        const dateMoment = moment(effectiveDate);
        dateMoment.add(6, 'months');
        const expirationDate = dateMoment.toDate();

        // logic copied from PolicyCenter to set expiration dates where February is concerned
        if ((expirationDate.getDate() !== effectiveDate.getDate())
            || (expirationDate.getMonth() === 1 && expirationDate.getDate() === 29)) {
            // if the expiration date falls on leap year and 02/29
            // - add an extra to the expiration date
            expirationDate.setDate(expirationDate.getDate() + 1); // add an extra day
        } else if (effectiveDate.getMonth() === 1 && effectiveDate.getDate() === 29) {
            /*
            This is to account for a policy written with effective 2/29 (leap year),
            it should create the next term exp date to be 3/1
            so, set the current term expiration date to 1st Sept
        */
            expirationDate.setMonth(8);
            expirationDate.setDate(1);
        }

        return expirationDate;
    }

    /**
     * Helper callback for determining the most appropriate period end date (AKA "coverage end
     * date") for the given period start date (AKA "coverage start date").
     */
    const getPeriodEndDateForGivenStartDate = useCallback(
        (periodStartDate) => {
            // IAP-4075 : if user removes whole effective date periodStartDate becomes undefined
            // and it throws undefined year error while creating new Date object
            // adding condition here to return it undefined in case user deletes effective date
            if (!periodStartDate) {
                return undefined;
            }
            let tempDate = new Date(
                periodStartDate.year,
                periodStartDate.month,
                periodStartDate.day
            );
            if (use12MonthPolicyTerm) {
                tempDate.setFullYear(periodStartDate.year + 1);
            } else {
                tempDate = addMonths(tempDate);
            }
            const periodEndDate = {
                year: tempDate.getFullYear(),
                month: tempDate.getMonth(),
                day: tempDate.getDate()
            };
            return periodEndDate;
        },
        [use12MonthPolicyTerm]
    );

    /**
     * Helper callback for handling updates to the period start date (AKA "coverage start date").
     */
    const handlePeriodStartDateChange = useCallback(
        (periodStartDate) => {
            const periodEndDate = getPeriodEndDateForGivenStartDate(periodStartDate);
            set(model, 'baseData.periodStartDate.value', periodStartDate);
            set(model.value, 'baseData.originalEffectiveDate', periodStartDate);
            set(model, 'baseData.periodEndDate.value', periodEndDate);
            updateWizardData(model);
        },
        [getPeriodEndDateForGivenStartDate, model, updateWizardData]
    );

    /**
     * Helper memo for calculating the default period start date (AKA "coverage start date").
     */
    const getDefaultPeriodStartDate = useMemo(() => {
        const existingPeriodStartDate = get(model, 'baseData.periodStartDate.value');
        let tempDate = new Date();
        if (existingPeriodStartDate) {
            tempDate = new Date(
                existingPeriodStartDate.year,
                existingPeriodStartDate.month,
                existingPeriodStartDate.day
            );
        }
        const defaultPeriodStartDate = {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth(),
            day: tempDate.getDate()
        };
        return defaultPeriodStartDate;
    }, [model]);

    /**
     * Helper memo for calculating the default period end date (AKA "coverage end date").
     */
    const getDefaultPeriodEndDate = useMemo(() => {
        const defaultPeriodEndDate = getPeriodEndDateForGivenStartDate(getDefaultPeriodStartDate);
        return defaultPeriodEndDate;
    }, [getDefaultPeriodStartDate, getPeriodEndDateForGivenStartDate]);

    /**
     * @param {Date} periodStartDate policy start date
     * @param {Date} periodEndDate policy end date
     * @param {Number} daysInPast days in the past relative to system date
     * @param {Number} daysInFuture days in the future relative to system date
     * @returns {Object} Minimum and maximum effective dates for a transaction
     *  used in all policy changes. Might be useful for other transactions.
     */
    const getEffectiveDateBounds = useCallback(
        (periodStartDate, periodEndDate, daysInPast, daysInFuture) => {
            let maxEffectiveDate = moment().add(daysInFuture, 'days');
            if (maxEffectiveDate.isAfter(periodEndDate, 'd')) {
                maxEffectiveDate = moment(periodEndDate);
            }
            if (model.cancellationDate_Ext !== undefined && maxEffectiveDate.isAfter(moment(model.cancellationDate_Ext), 'd')) {
                maxEffectiveDate = moment(model.cancellationDate_Ext);
            }
            let minEffectiveDate = moment().subtract(daysInPast, 'days');
            if (minEffectiveDate.isBefore(periodStartDate, 'd')) {
                minEffectiveDate = moment(periodStartDate);
            }
            if (hasUnlimitedBackDatePermission) {
                return {
                    maxEffectiveDate: {
                        year: maxEffectiveDate.year(),
                        month: maxEffectiveDate.month(),
                        day: maxEffectiveDate.date()
                    },
                    minEffectiveDate: {
                        year: moment(periodStartDate).year(),
                        month: moment(periodStartDate).month(),
                        day: moment(periodStartDate).date()
                    }
                };
            }
            return {
                maxEffectiveDate: {
                    year: maxEffectiveDate.year(),
                    month: maxEffectiveDate.month(),
                    day: maxEffectiveDate.date()
                },
                minEffectiveDate: {
                    year: minEffectiveDate.year(),
                    month: minEffectiveDate.month(),
                    day: minEffectiveDate.date()
                }
            };
        },
        [hasUnlimitedBackDatePermission, model.cancellationDate_Ext]
    );

    /**
     * @param {Number} daysInFuture days in the future relative to system date
     * @returns {Object} Minimum and maximum effective dates for a transaction
     *  used in all New Business Transaction.
     * maxDate will always be systemDate + DaysInFuture
     */
    const getEffectiveDateBoundsForSubmission = useCallback(
        (daysInFuture) => {
            const existingPeriodStartDate = get(model, 'baseData.periodStartDate.value');
            const maxEffectiveDate = moment(new Date()).add(daysInFuture, 'days');
            const currentDate = moment(new Date());
            let effectiveDate = moment(new Date());
            if (existingPeriodStartDate) {
                const existingStartDate = moment(new Date(
                    existingPeriodStartDate.year,
                    existingPeriodStartDate.month,
                    existingPeriodStartDate.day
                ));
                // Check if the effective date is within 5 days from current date
                // if not min possible effective date would be current date
                if (existingStartDate.isSameOrBefore(currentDate, 'd')) {
                    if (existingStartDate?.isBefore(moment(new Date()).subtract(5, 'days'), 'd')) {
                        effectiveDate = currentDate;
                    } else {
                        effectiveDate = existingStartDate;
                    }
                }
                if (existingStartDate.isAfter(currentDate, 'd')) {
                    effectiveDate = currentDate;
                }
            }
            const minEffectiveDateForSixMonthPolicy = moment(new Date()).subtract(6, 'months');
            const minEffectiveDateForAnnualPolicy = moment(new Date()).subtract(12, 'months');
            const minEffectiveDateBasedOnPolicyTerm = use12MonthPolicyTerm
                ? minEffectiveDateForAnnualPolicy : minEffectiveDateForSixMonthPolicy;

            if (hasUnlimitedBackDatePermission) {
                return {
                    maxEffectiveDate: {
                        year: maxEffectiveDate.year(),
                        month: maxEffectiveDate.month(),
                        day: maxEffectiveDate.date()
                    },
                    // Initial min Effective date
                    minEffectiveDate: {
                        year: minEffectiveDateBasedOnPolicyTerm.year(),
                        month: minEffectiveDateBasedOnPolicyTerm.month(),
                        day: minEffectiveDateBasedOnPolicyTerm.date()
                    },
                    // For users with UnlimitedBackDatePermission minimum possible effective date is
                    // always 6 month or 12 months from current date based on policy term irespective of
                    // whether user updates the effective date field or not
                    currentMinEffectiveDate: {
                        year: minEffectiveDateBasedOnPolicyTerm.year(),
                        month: minEffectiveDateBasedOnPolicyTerm.month(),
                        day: minEffectiveDateBasedOnPolicyTerm.date()
                    },
                };
            }
            return {
                maxEffectiveDate: {
                    year: maxEffectiveDate.year(),
                    month: maxEffectiveDate.month(),
                    day: maxEffectiveDate.date()
                },
                // On load of insured page and when user does not edit effective date
                // the minimum possible effective date can be either within the
                // 5 days range from current date or current date itself
                minEffectiveDate: {
                    year: effectiveDate.year(),
                    month: effectiveDate.month(),
                    day: effectiveDate.date()
                },
                // When user edits effective date field, the minimum possible effective date is
                // always current date, he cannot generate quote with back date
                currentMinEffectiveDate: {
                    year: moment(new Date()).year(),
                    month: moment(new Date()).month(),
                    day: moment(new Date()).date()
                },
            };
        },
        [hasUnlimitedBackDatePermission, model, use12MonthPolicyTerm]
    );

    /**
     * @param {Date} policyStartDate policy start date
     * @param {Date} policyEndDate policy end date
     * @param {Number} daysInPast days in the past relative to system date
     * @returns {Object} Minimum and maximum effective dates for a transaction
     *  used in Cancellation transactions
     */
    const getEffectiveDateBoundsForCancellation = useCallback((policyStartDate, policyEndDate, daysInPast) => {
        const maxDate = moment(policyEndDate);
        let minDate = moment().subtract(daysInPast, 'days');
        if (minDate.isBefore(policyStartDate, 'd') || hasUnlimitedBackDatePermission) {
            minDate = moment(policyStartDate);
        }
        return {
            maxEffectiveDate: {
                year: maxDate.year(),
                month: maxDate.month(),
                day: maxDate.date()
            },
            minEffectiveDate: {
                year: minDate.year(),
                month: minDate.month(),
                day: minDate.date()
            }
        };
    }, [hasUnlimitedBackDatePermission]);

    return {
        getPeriodEndDateForGivenStartDate,
        handlePeriodStartDateChange,
        getDefaultPeriodStartDate,
        getDefaultPeriodEndDate,
        getEffectiveDateBounds,
        getEffectiveDateBoundsForSubmission,
        getEffectiveDateBoundsForCancellation
    };
}

// used to wrap around class components, used for Cancellation transaction
export function withEffectiveDateUtil(WrappedComponent) {
    function WithEffectiveDateUtil(props) {
        const { getEffectiveDateBoundsForCancellation } = useEffectiveDateUtil();
        return <WrappedComponent getEffectiveDateBoundsForCancellation = {getEffectiveDateBoundsForCancellation} {...props} />;
    }
    hoistNonReactStatic(WithEffectiveDateUtil, WrappedComponent);
    return WithEffectiveDateUtil;
}

export default useEffectiveDateUtil;
