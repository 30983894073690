import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo,
    useRef
} from 'react';
import {
    get as _get,
    isEmpty as _isEmpty,
    includes as _includes,
    set as _set,
    cloneDeep as _cloneDeep
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { AccountService } from 'e1p-capability-gateway';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { isRequired } from 'e1p-portals-required-validator-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import {
    useEffectiveDateUtil,
    useNewBusinessUtil,
    useSniUtil,
    e1pUSStatesUtil
} from 'e1p-capability-hooks';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import appConfig from 'app-config';
import messages from './RewriteEAPolicyDetailsPage.messages';
import metadata from './RewriteEAPolicyDetailsPage.metadata.json5';
import requiredMetadata from './RewriteEAPolicyDetailsPage.requiredness';

const LOB = 'personalAuto_EA';

function RewritePolicyDetailsPage(props) {
    const modalApi = useModal();
    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerInitialComponentValidation,
        disregardFieldValidation,
        registerComponentValidation
    } = useValidation('PolicyDetailsPage');
    const {
        wizardData: rewriteVM,
        updateWizardData,
        updateWizardSnapshot,
        wizardSnapshot
    } = props;
    const translator = useTranslator();
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [requiredFields, updateRequiredFields] = useState([]);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const [isAnExistingAccount, setIsAnExistingAccount] = useState(false);
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [submissionCreationInProgress, setSubmissionCreationInProgress] = useState(false);
    const [isComponentInitialized, setIsComponentInitialized] = useState(false);
    const policyState = _get(rewriteVM, 'baseData.policyAddress.state.value.code');
    const { opCo } = useContext(AmfamOktaTokenContext);
    const businessType = rewriteVM.baseData?.businessTransactionType_Ext?.value?.code;
    const hasPerformedAccountSearch = useRef(false);
    const [USStates, setUSStates] = useState([]);
    const [initialDrivers, setInitialDrivers] = useState([]);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [showSearchButtonErrors, setShowSearchButtonErrors] = useState(false);
    const { daysInFutureForBookroll, daysInFuture } = appConfig.dateInputConfig;
    const {
        handlePeriodStartDateChange,
        getDefaultPeriodEndDate,
        getEffectiveDateBoundsForSubmission
    } = useEffectiveDateUtil(rewriteVM, updateWizardData, false);

    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');

    const {
        createAccountHolderVM,
        getLocationAndProducerCodes,
        createPrimaryNamedInsuredVM,
        getProducerDetailsFromProducerCodes
    } = useNewBusinessUtil(
        rewriteVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        setLocationAndProducerCodes
    );

    const { createSecondaryNamedInsuredVM, removeSni, addSniInCoverable } = useSniUtil(
        rewriteVM,
        updateWizardData,
        viewModelService,
        LOB,
        disregardFieldValidation
    );

    /**
     * Take the show errors off once page is fixed.
     * EX. show errors is true for all fields so when
     *   you add a new driver/sni or so, the fields
     *   would be red without giving a chance to fill in first
     */
    useEffect(() => {
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        const stateValues = e1pUSStatesUtil.getUSStates(viewModelService);

        setUSStates(stateValues);

        const existingDrivers = _cloneDeep(_get(rewriteVM.value, 'lobData.personalAuto_EA.coverables.drivers'));

        setInitialDrivers(existingDrivers);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const markAccountSearchCompleted = () => {
        hasPerformedAccountSearch.current = true;
    };
    const { maxEffectiveDate, minEffectiveDate } = useMemo(
        () => 
            // If Book Transfer is Yes, allow quoting up to 120 days in the future, otherwise 60 days  
             bookTransferIndicator ? getEffectiveDateBoundsForSubmission(daysInFutureForBookroll)
                : getEffectiveDateBoundsForSubmission(daysInFuture)
        ,
        [getEffectiveDateBoundsForSubmission, bookTransferIndicator, daysInFutureForBookroll, daysInFuture]
    );

    useEffect(() => {
        const initialRequiredFields = ['ownsHomeIndicator', 'applicantsResideInSameStateInd', 'isUmbrellaAssociated']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
    }, [policyState]);

    useEffect(() => {
        if (rewriteVM.baseData.quoteSource_Ext?.sourceType === undefined) {
            const model = {
                sourceType: 'directentry',
                sourceName: 'msaagentsonly'
            };

            if (rewriteVM.baseData.quoteSource_Ext) {
                rewriteVM.baseData.quoteSource_Ext.value = model;
            }
        }

        setIsComponentInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const accountHolderPath = 'baseData.accountHolder';
        const accountHolder = _get(rewriteVM, `${accountHolderPath}.value`);

        // Create accountholder VM if not existing
        if (_isEmpty(accountHolder)) {
            createAccountHolderVM();
        } else {
            setIsAnExistingAccount(true);
        }
        // executed only once on initial render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AccountService]);

    const areAcknowledgementsAccepted = useCallback(() => {
        const isSourceTypeCompRater = _get(
            rewriteVM,
            'baseData.quoteSource_Ext.sourceType.value.code'
        ) === 'comprater';

        if (isSourceTypeCompRater) {
            return true;
        }

        if (rewriteVM.value.lobData.personalAuto_EA.acknowledgements === undefined) {
            return false;
        }

        return rewriteVM.value.lobData.personalAuto_EA.acknowledgements.every(
            (acknowledgement) => acknowledgement.acknowledgeAnswerType === 'accept'
        );
    }, [rewriteVM]);

    const handleAcknowledgementsValueChange = useCallback(
        (value) => {
            _set(rewriteVM, `lobData[${LOB}].acknowledgements`, value);
            updateWizardData(rewriteVM);
        },
        [updateWizardData, rewriteVM]
    );

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isStandardizingAddress) {
            loadingMessage = translator(messages.standardizingAddressMessage);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else {
            loadingMessage = translator(messages.loadingNextPageMessage);
        }

        return loadingMessage;
    }, [translator, isStandardizingAddress, isSavingCurrentPageChanges]);

    const isValid = useCallback(() => {
        if (businessType === 'FullTerm') {
            return true;
        }

        return areAcknowledgementsAccepted();
    }, [areAcknowledgementsAccepted, businessType]);

    useEffect(() => {
        registerComponentValidation(isValid);
    }, [isValid, registerComponentValidation]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setSubmissionCreationInProgress(true);
        // Need this for validation rules
        _set(rewriteVM, 'flowStepIDs_Ext.value', ['namedinsured']);
        _set(rewriteVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);

        // sync NI changes to Drivers
        const drivers = _get(rewriteVM.value, 'lobData.personalAuto_EA.coverables.drivers');
        const pni = _get(rewriteVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
        const sni = _get(rewriteVM.value, 'lobData.personalAuto_EA.secondaryNamedInsured');
        const pniDriver = drivers.find((driver) => driver.person.publicID === pni.person.publicID);
        // SNI may be undefined so being null safe there
        const sniDriver = drivers.find((driver) => driver.person.publicID === sni?.person?.publicID);

        if (pniDriver) {
            // updates to existing pni contact
            _set(pniDriver, 'person', pni.person);
        }

        if (pni && !pniDriver) {
            // check if PNI is already present in existing drivers
            // if present then add in the drivers, but do not create new here, that is handled in driver page
            if (!_isEmpty(initialDrivers)) {
                const initialPniDriver = initialDrivers
                    .find((driver) => driver.person.publicID === pni?.person?.publicID);

                if (initialPniDriver) {
                    _set(initialPniDriver, 'person', pni.person);
                    _set(initialPniDriver, 'relationshipToNI', undefined);
                    drivers.push(initialPniDriver);

                    _set(rewriteVM.value, 'lobData.personalAuto_EA.coverables.drivers', drivers);
                }
            }
        }

        if (sni && sniDriver) {
            // updates to existing sni contact
            _set(sniDriver, 'person', sni?.person);
        }

        if (sni && !sniDriver) {
            // check if SNI is already present in existing drivers
            // if present then add in the drivers, but do not create new here, that is handled in driver page
            if (!_isEmpty(initialDrivers)) {
                const initialSniDriver = initialDrivers
                    .find((driver) => driver.person.publicID === sni?.person?.publicID);

                if (initialSniDriver) {
                    _set(initialSniDriver, 'person', sni.person);
                    _set(initialSniDriver, 'relationshipToNI', sni?.relationshipToNI);
                    drivers.push(initialSniDriver);

                    _set(rewriteVM.value, 'lobData.personalAuto_EA.coverables.drivers', drivers);
                }
            }
        }

         
        rewriteVM?.value?.lobData?.personalAuto_EA?.coverables?.drivers.forEach((_, index) => {
            const licenseNumber = _get(rewriteVM.value, `lobData.personalAuto_EA.coverables.drivers[${index}].licenseNumber`);
            const licenseState = _get(rewriteVM.value, `lobData.personalAuto_EA.coverables.drivers[${index}].licenseState`);

            _set(rewriteVM.value, `lobData.personalAuto_EA.coverables.drivers[${index}].person.licenseNumber`, licenseNumber);
            _set(rewriteVM.value, `lobData.personalAuto_EA.coverables.drivers[${index}].person.licenseState`, licenseState);
        });
        _set(rewriteVM, 'baseData.accountHolder.person.value', pni.person);

        const QuoteDataResponse = await RewriteService.updateDraftRewrite(
            [rewriteVM.value],
            authHeader
        );

        _set(rewriteVM, 'value', QuoteDataResponse);
        updateWizardData(rewriteVM);
        //   sni exist or not is being checked in the method itself, so no need to check here
        addSniInCoverable();
        setSubmissionCreationInProgress(false);

        return rewriteVM;
    }, [isComponentValid, rewriteVM, authHeader, initialDrivers, updateWizardData, addSniInCoverable]);

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    /**
                     * E1PAP1PC-14986 :
                     * wizardData and wizardSnapshot not being equal due to
                     * some defaulting on each page so doing this temp fix
                     */
                    if (rewriteVM.baseData.value.producerCode) {
                        _set(rewriteVM,
                            'baseData.value.producerCode_Ext',
                            rewriteVM.baseData.value.producerCode);
                    }

                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, rewriteVM, updateWizardSnapshot]
    );

    const doesJobExist = useCallback(() => (
            _get(rewriteVM, 'jobID.value')
        ), [rewriteVM]);

    useEffect(() => {
        registerInitialComponentValidation(doesJobExist);
    }, [doesJobExist, registerInitialComponentValidation]);

    const handleAddressValueChange = useCallback(
        (newVal, localPath) => {
            _set(rewriteVM, localPath, newVal);
            updateWizardData(rewriteVM);
        },
        [rewriteVM, updateWizardData]
    );

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(rewriteVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(rewriteVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    useEffect(() => {
        // get available producer codes for given state
        if (policyState && isAgent) {
            // only for agents
            getAndSetLocationCodes(policyState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isProducerCodeDisabled = useMemo(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(rewriteVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(rewriteVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);

            return true;
        }

        return false;
    }, [locationAndProducerCodes, rewriteVM]);

    useEffect(() => {
        const {
            producerCode
        } = producerCodeDetails;
        const existingProducerCode = _get(
            rewriteVM,
            'value.baseData.producerCode_Ext',
            rewriteVM.value.baseData.producerCode
        );

        if (existingProducerCode && isAgent
            && existingProducerCode !== producerCode) {
            (async () => {
                // only for agents
                const details = await getProducerDetailsFromProducerCodes(
                    rewriteVM.value.baseData.producerCode_Ext,
                    locationAndProducerCodes.producerCodes,
                    rewriteVM.value.baseData.externalID_Ext
                );

                // in case we dont get any response from service
                if (details) {
                    setProducerCodeDetails(details);
                    setAgencyName(details.agencyName);
                    setBookTransferIndicator(details.bookTransferIndicator);
                    setServiceCenterIndicator(details.serviceCenterIndicatorValue);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewriteVM.baseData.producerCode_Ext.value]);

    const locationCodeChange = useCallback(
        (value) => {
            _set(rewriteVM, 'baseData.externalID_Ext', value);

            const selectedProducerCode = locationAndProducerCodes?.producerCodes
                ?.find((producerCode) => producerCode?.externalId_Ext === value);

            _set(rewriteVM, 'baseData.producerCode_Ext', selectedProducerCode?.code);
            updateWizardData(rewriteVM);
        },
        [rewriteVM, updateWizardData, locationAndProducerCodes]
    );

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, address, phone, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            _set(rewriteVM, 'baseData.producerCode_Ext', producerCode);
            _set(rewriteVM, 'baseData.externalID_Ext.value', externalID);
            setProducerCodeDetails({ address, phone });
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [rewriteVM],
    );

    const swapPniSni = useCallback(() => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: e1pCommonMessages.swapPniSniTitle,
            message: e1pCommonMessages.swapPniSniMessage,
            confirmButtonText: e1pCommonMessages.confirm,
            cancelButtonText: e1pCommonMessages.cancel
        }).then(
            (result) => {
                if (result !== 'cancel') {
                    const pniPerson = _cloneDeep(
                        rewriteVM.lobData[LOB].primaryNamedInsured.person.value
                    );
                    const sniPerson = _cloneDeep(
                        rewriteVM.lobData[LOB].secondaryNamedInsured.person.value
                    );
                    let drivers = _get(rewriteVM.value, `lobData[${LOB}].coverables.drivers`);
                    let sniDriver = drivers
                        .find((driver) => driver.person.publicID === sniPerson?.publicID);

                    if (!sniDriver) {
                        addSniInCoverable();
                        drivers = _get(rewriteVM.value, `lobData[${LOB}].coverables.drivers`);
                        sniDriver = drivers
                            .find((occupant) => occupant.person.publicID === sniPerson.publicID);
                        // setting driver role for SNI to operator, since sni is newly added.
                        _set(sniDriver, 'policyDriverRoleType', 'operator');
                    }

                    const pniDriver = drivers
                        .find((occupant) => occupant.person.publicID === pniPerson.publicID);

                    const relationshipToNI = _get(rewriteVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`, 'notrelated');

                    _set(pniDriver, 'relationshipToNI', relationshipToNI);
                    _set(sniDriver, 'relationshipToNI', undefined);
                    // now swap PNI and SNI
                    rewriteVM.lobData[LOB].primaryNamedInsured.person.value = sniPerson;
                    rewriteVM.lobData[LOB].secondaryNamedInsured.person.value = pniPerson;
                    // since we are swapping PNI and SNI, we need remove publicID, FixedID and integrationID from both policyContactRoles
                    // i.e PNI and SNI
                    _set(rewriteVM, `lobData[${LOB}].primaryNamedInsured.fixedId.value`, undefined);
                    _set(rewriteVM, `lobData[${LOB}].primaryNamedInsured.publicID.value`, undefined);
                    _set(rewriteVM, `lobData[${LOB}].primaryNamedInsured.integrationId.value`, undefined);
                    _set(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.fixedId.value`, undefined);
                    _set(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.publicID.value`, undefined);
                    _set(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.integrationId.value`, undefined);

                    // If paperless Email id exist and pni email id is undefiend
                    // then update pni email to paperless email
                    if (!_get(rewriteVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value')
                        && !!_get(rewriteVM, 'lobData.personalAuto_EA.paperlessEmail.value')) {
                        _set(rewriteVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value',
                            _get(rewriteVM, 'lobData.personalAuto_EA.paperlessEmail.value'));
                    }

                    updateWizardData(rewriteVM);
                }
            },
            () => { }
        );
    }, [addSniInCoverable, modalApi, rewriteVM, updateWizardData]);

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate,
            onRemoveSni: removeSni,
            onMarkAccountSearchCompleted: markAccountSearchCompleted,
            onHandleProducerApiResponse: handleProducerApiRespone,
            onSwapPniSni: swapPniSni
        }
    };

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            showRequired: true,
            autoComplete: false
        },
        policyState: {
            availableValues: e1pUSStatesUtil.getStateValues(USStates, translator)
        },
        policyDetailsPageLoadingIndicator: {
            loaded:
                !isStandardizingAddress
                && !submissionCreationInProgress
                && !isSavingCurrentPageChanges,
            text: getLoadingIndicatorMessage
        },
        policyDetailsPageContainer: {
            visible:
                !isStandardizingAddress
                && !submissionCreationInProgress
                && !isSavingCurrentPageChanges
        },
        coverageStartDate: {
            updateDateDto: handlePeriodStartDateChange,
            dateDTO: rewriteVM.baseData.periodStartDate,
            defaultToToday: true,
            minDate: minEffectiveDate,
            maxDate: maxEffectiveDate,
            showErrors: isPageSubmitted
        },
        coverageEndDate: {
            dateDTO: rewriteVM.baseData.periodEndDate,
            updateDateDto: () => updateWizardData(rewriteVM),
            readOnly: true,
            defaultValue: getDefaultPeriodEndDate
        },
        personalInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        originalEffectiveDate: {
            dateDTO: rewriteVM.baseData.periodStartDate,
            updateDateDto: () => updateWizardData(rewriteVM),
            readOnly: true,
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        excessLiabilityInd: {
            required: true,
            visible: _includes(requiredFields, 'isUmbrellaAssociated'),
        },
        sniContainer: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        addSni: {
            visible: !_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        secondaryNamedInsured: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            showErrors: isPageSubmitted

        },
        changePniComponent: {
            accountNumber: _get(rewriteVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(rewriteVM, 'baseData.accountNumber.value'),
            excludedContacts: [
                _get(rewriteVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            drivers: _get(
                rewriteVM.value,
                `lobData[${LOB}].coverables.drivers`,
                undefined
            )
        },
        changeSniComponent: {
            accountNumber: _get(rewriteVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(rewriteVM, 'baseData.accountNumber.value')
                && !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            excludedContacts: [
                _get(rewriteVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            drivers: _get(
                rewriteVM.value,
                `lobData[${LOB}].coverables.drivers`,
                undefined
            )
        },
        policyDetailsContainer: {
            visible:
                !submissionCreationInProgress
                && !isStandardizingAddress
        },
        mailingAndBillingAddressComponent: {
            transactionVM: rewriteVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            showErrors: isPageSubmitted,
            wizardSnapshot
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        rentOrOwn: {
            required: _includes(requiredFields, 'ownsHomeIndicator')
        },
        e1pAcknowledgementComponent: {
            acknowledgements: _get(rewriteVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: policyState,
                name: translator({ id: _get(rewriteVM, 'baseData.policyAddress.state.value.name') })
            },
            visible: businessType !== 'FullTerm',
            onValueChange: handleAcknowledgementsValueChange,
            lob: LOB
        },
        sourceType: {
            readOnly: true
        },
        accountSearchComponent: {
            viewModelService,
            setIsAnExistingAccount,
            authHeader,
            visible: !_get(rewriteVM, 'baseData.accountNumber.value'),
            showSearchButtonErrors,
            setShowSearchButtonErrors,
            searchButtonErrorFunc: () => {
                setShowSearchButtonErrors(true);
                window.scrollTo(0, 0);

                return false;
            }
        },
        insuredResidenceAddress: {
            addressVM: _get(rewriteVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate,
            onAddressChange: (value, path) => handleAddressValueChange(value, `baseData.policyAddress.${path}`),
            viewOnlyMode: true,
            showParentLoader: setIsStandardizingAddress,
            showErrors: isPageSubmitted
        },
        primaryNamedInsured: {
            showErrors: isPageSubmitted,
            isPNI: true
        },
        ExternalProducerDetailsComponent: {
            defaultValue: rewriteVM.baseData.externalID_Ext?.value,
            visible: !isAgent,
            showErrors: isPageSubmitted || showSearchButtonErrors
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            disabled: isProducerCodeDisabled,
            onValueChange: locationCodeChange,
            visible: isAgent,
            showErrors: isPageSubmitted || showSearchButtonErrors
        },
        applicantsResideInSameStateInd: {
            required: true,
            visible: _includes(requiredFields, 'applicantsResideInSameStateInd'),
        },
        swapPniSni: {
            visible: !!_get(rewriteVM, `value.lobData[${LOB}].secondaryNamedInsured.person.publicID`)
                && !!_get(rewriteVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`)
        },
        removeSni: {
            visible: !!_get(rewriteVM, `value.lobData[${LOB}].secondaryNamedInsured`)
        },
        agencyDetailsContainer: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: rewriteVM,
            authHeader,
            LOB: 'personalAuto_EA'
        }
    };

    const readValue = (id, path) => readViewModelValue(metadata.pageContent, rewriteVM, id, path, overrideProps);

    if (!isComponentInitialized) {
        return null;
    }

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={(isPageSubmitted && !isComponentValid) || showSearchButtonErrors}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

RewritePolicyDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(RewritePolicyDetailsPage);
