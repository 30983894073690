import React, {
    useContext, useCallback, useEffect, useRef, useMemo, useState,
} from 'react';
import {
    get, set, every, includes, isUndefined,
    pullAt, some, isEmpty, find, findIndex
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useModal } from '@jutro/components';
import { WizardPage, wizardProps, WizardPageTemplateWithTitle } from 'e1p-portals-wizard-react';
import { EndorsementService } from 'e1p-capability-policychange';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useLandingPageUtil } from 'e1p-capability-hooks';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import messages from './UnderlyingPolicyPage.messages';
import metadata from './UnderlyingPolicyPage.metadata.json5';
import EUVehicleOperatorPage from '../VehicleOperator/EUVehicleOperatorPage';


const LOB = 'personalUmbrella_EU';
const AUTO_EXPOSURES = ['personalauto', 'personalaononowner', 'otherspecialityvehicle', 'cycle'];

function UnderlyingPolicyPage(props) {
    const modalApi = useModal();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        currentStepIndex,
        changeNextSteps,
        isSkipping,
        steps,
        jumpTo,
        updateWizardSnapshot,
        updateFurthestVisitedIndexOnStart
    } = props;
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation('UnderlyingPolicyPage');
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const deletedItemsRef = useRef([]);
    const underlyingPoliciesVM = useMemo(() => get(policyChangeVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []), [policyChangeVM]);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const isMounted = useRef(false);
    const { getLandingPageIndexForQuotedJob } = useLandingPageUtil();
    const jobType = get(policyChangeVM.value, 'baseData.jobType');

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    // DO NOT change the ID of this page without changing step to field mappings config
    const vehicleOperatorPage = useRef({
        id: 'PCEUVehicleOperatorsPage',
        path: '/vehicle-operator',
        component: EUVehicleOperatorPage,
        title: {
            id: 'quoteandbind.eu.wizard.step.Vehicle Operator',
            defaultMessage: 'Vehicle Operator',
        },
        stepProps: {
            template: WizardPageTemplateWithTitle
        },
    }).current;

    // As we change number of steps need to update furthest visited index
    useEffect(() => {
        const indexOfReviewPage = findIndex(steps, ({ path }) => path === '/change-review');

        updateFurthestVisitedIndexOnStart(indexOfReviewPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps]);

    const handleVehicleOperatorPage = useCallback(() => {
        const remainingSteps = steps.slice(currentStepIndex + 1, steps.length);
        const vehicleUnderlyingPolicyExists = some(underlyingPoliciesVM.value,
            (policy) => AUTO_EXPOSURES.includes(policy.policyType));

        if (vehicleUnderlyingPolicyExists && !find(steps, ['id', vehicleOperatorPage.id])) {
            remainingSteps.unshift(vehicleOperatorPage);
        }

        if (!vehicleUnderlyingPolicyExists && find(steps, ['id', vehicleOperatorPage.id])) {
            remainingSteps.shift(vehicleOperatorPage);
        }

        changeNextSteps(remainingSteps);

        return vehicleUnderlyingPolicyExists;
    }, [steps, currentStepIndex, underlyingPoliciesVM.value, changeNextSteps, vehicleOperatorPage]);

    useEffect(() => {
        if (isUndefined(underlyingPoliciesVM.value)) {
            set(underlyingPoliciesVM, 'value', []);
        }

        updateWizardData(policyChangeVM);
        handleVehicleOperatorPage();
        registerComponentValidation(() => every(underlyingPoliciesVM.children,
                (el, index) => (el.aspects.subtreeValid
                    || some(deletedItemsRef.current, (i) => i === index)))
                && !!(underlyingPoliciesVM.value
                    .filter((_, index) => !deletedItemsRef?.current?.includes(index))
                    .find((policy) => policy.policyType === 'personalproperty' && policy.isPrimaryInd)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const doSave = useCallback(
        async () => {
            setIsSavingEndorsement(true);

            try {
                pullAt(underlyingPoliciesVM.value, deletedItemsRef.current);

                const propertyUnderlyingPolicyExists = some(underlyingPoliciesVM.value,
                    (policy) => includes('personalproperty',
                        policy.policyType));

                if (propertyUnderlyingPolicyExists) {
                    set(policyChangeVM, 'flowStepIDs_Ext.value', ['underlyingproperty']);
                    set(policyChangeVM, 'entryCompletionStepIDs_Ext.value', ['underlyingproperty']);
                }

                policyChangeVM.value = await EndorsementService.saveEndorsement(
                    [(policyChangeVM.value)],
                    authHeader
                );
                updateWizardData(policyChangeVM);
            } catch {
                setIsSavingEndorsement(false);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: messages.saveCoverageError,
                    message: messages.saveCoverageErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });
            }

            // updating deletedItemsRef to empty, once we delete underlying policy. 
            deletedItemsRef.current = [];
            setIsSavingEndorsement(false);

            return policyChangeVM.value;
        },
        [authHeader, modalApi, policyChangeVM, underlyingPoliciesVM.value, updateWizardData]
    );

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            policyChangeVM.value = await doSave();

            const vehicleUnderlyingPolicyExists = await handleVehicleOperatorPage();

            if (!vehicleUnderlyingPolicyExists) {
                policyChangeVM.value = await EndorsementService.saveAndQuoteEndorsement(
                    [(policyChangeVM.value)],
                    authHeader
                );
            }

            setIsSavingEndorsement(false);
            // Added Two underlying policies and deleted auto , backend is sending validations for auto
            // so updated wizarddata to show them on UI.
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            return policyChangeVM;
        },
        [authHeader, doSave, handleVehicleOperatorPage, isComponentValid, policyChangeVM, updateWizardData, updateWizardSnapshot]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    // Updating noOperatorReasonOther value , which makes wizard and snapshot data equal.
                    // It will avoid unnecessary modal popup for saved changes.
                    if(get(policyChangeVM.lobData.personalUmbrella_EU, 'noOperatorReasonOther.value', undefined) === undefined) {
                        set(policyChangeVM.lobData.personalUmbrella_EU, 'noOperatorReasonOther.value', undefined);
                    }

                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, policyChangeVM, updateWizardSnapshot]
    );

    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        underlyingPolicyPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        underlyingPolicyPageContainer: {
            visible: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges
        },
        euUnderlyingPolicyComponent: {
            data: policyChangeVM,
            updateWizardData,
            isPageSubmitted,
            isPrivatePassengerQuestionVisible: false,
            deletedItemsRef,
            jobType
        }
    };

    const writeValue = useCallback(
        (newVal, path) => {
            set(policyChangeVM, `${path}.value`, newVal);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    const saveAndQuote = useCallback(
        async () => {
            const saveResponse = await doSave();

            set(policyChangeVM, 'value', saveResponse);
            updateWizardData(policyChangeVM);
            await handleVehicleOperatorPage();

            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteResponse);
            updateWizardData(policyChangeVM);

            return policyChangeVM.value;
        },
        [authHeader, doSave, handleVehicleOperatorPage, policyChangeVM, updateWizardData]
    );

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);
            policyChangeVM.value = await saveAndQuote();
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            // Update the component's state only if it is currently mounted.
            if(isMounted.current){
                setIsSavingEndorsement(false);
                updateWizardData(policyChangeVM);
            }

            return false;
        },
        [
            getLandingPageIndexForQuotedJob,
            isComponentValid,
            jumpTo,
            policyChangeVM,
            saveAndQuote,
            steps,
            updateWizardData,
            updateWizardSnapshot
        ]
    );

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            nextLabel={translator(messages.allUnderlyingPolicisAdded)}
            showCustom
            onCustom={onCustom}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={writeValue}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

UnderlyingPolicyPage.propTypes = wizardProps;
export default UnderlyingPolicyPage;
