import React, {
    useCallback, useContext, useEffect,
    useState
} from 'react';
import {
    get, set, find, findIndex, unset, pullAt
} from 'lodash';

import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDriverPageUtil } from 'e1p-capability-hooks';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import metadata from '../DriversPage.metadata.json5';

function ViewEADriversPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        setIsPageValid,
        viewOnly
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        onValidate,
        isComponentValid
    } = useValidation('EADriversPage');
    const [checkScrolling, setCheckScrolling] = useState(true);
   
    const {
        isDefensiveDriverDiscountAvailableForDriver,
        getDriverAge,
        isFinancialResponsibilityFormAvailableForDriver
    } = useDriverPageUtil();

    /**
    * Setting current wizard page is valid or not,using this info
    * to show popup(The changes you have made on this page may be lost.)
    * on click of previous or page jump
    */
    useEffect(() => {
        setIsPageValid(isComponentValid);
    }, [isComponentValid, setIsPageValid]);

    const { authUserData } = useAuthentication();

    renewalVM.flowStepIDs_Ext.value = ['driver'];
    renewalVM.entryCompletionStepIDs_Ext.value = ['driver'];

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';
        const drivers = get(renewalVM, `${driversPath}`);
        const pniPublicID = renewalVM
            .lobData.personalAuto_EA.primaryNamedInsured.person.publicID.value;
        const sniPublicID = renewalVM
            .lobData.personalAuto_EA.secondaryNamedInsured.person
            ? renewalVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value
            : undefined;
        let pni = find(
            drivers.children,
            driver => driver.person.publicID.value === pniPublicID
        );
        const pniIndex = findIndex(
            drivers.children,
            driver => driver.person.publicID.value === pniPublicID
        );

        // Unset role.Fixes bug where you come back to drivers from a further page
        // and ArrayUpdater has changed the ordering of your drivers and role has persisted
        // so you end up seeing both  drivers as PNI
        drivers.children.forEach((driverNode) => {
            unset(driverNode, 'role');
        });

        // Put PNI back as first driver
        if (pniIndex > 0) {
            pni = viewModelService.clone(pni);
            pullAt(drivers.value, pniIndex);
            drivers.value.unshift(pni.value);
        }


        if (sniPublicID) {
            const sni = find(drivers.children, (driver) => driver.person.publicID.value === sniPublicID);

            if (sni) {
                set(sni, 'value.role', 'sni');
            }
        }

        if (pni) {
            set(pni, 'value.role', 'pni');
        }
    }, [renewalVM, renewalVM.lobData.personalAuto_EA.coverables.drivers.value, viewModelService]);

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(() => renewalVM, [renewalVM]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'top',
            disabled: viewOnly
        },
        driversPageLoadingIndicator: {
            loaded: true
        },
        driversPageContainer: {
            visible: true
        },
        driverHeaderInfoID: {
            visible: true
        },
        DefensiveDriverInd: {
            visible: get(renewalVM, 'baseData.policyAddress.state.value.code') !== 'IN'
        },
        addDriver: {
            visible: false
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('eadrivercontainer')
        },
        inlineNotificationContainer: {
            visible: false
        },
        EADriverGrid: {
            drivers: get(renewalVM, 'lobData.personalAuto_EA.coverables.drivers', []),
            path: 'lobData.personalAuto_EA.coverables.drivers.children',
            onValidate: ()=>{},
            viewModelService,
            showErrors: false,
            policyState: get(renewalVM, 'baseData.policyAddress.state.value.code'),
            driverPageDisregardFieldValidation: ()=>{},
            checkLicenseRequired: ()=>{},
            driversWithLicenseValidationError: [],
            setDriversWithLicenseValidationErrors: ()=>{},
            getDriverAge,
            periodStartDate: get(renewalVM, 'baseData.periodStartDate.value'),
            priorPolicyList: get(renewalVM, 'lobData.personalAuto_EA.priorPolicyUpdates'),
            onRemoveDriver: ()=>{},
            isFinancialResponsibilityFormAvailableForDriver,
            isNewSubmission: false,
            viewOnlyMode: true,
            onValidationChange: ()=>{},
            setSNIHasDefensiveCourse: ()=>{},
            setPNIHasDefensiveCourse: ()=>{},
            authUserData,
            transactionVM: renewalVM,
            isDefensiveDriverDiscountAvailableForDriver,
            updateWizardData: ()=>{},
            setCheckScrolling
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onAddDriverClick: () => { },
            onRemoveDriver: () => { },
            onValidate,
            onPrefillData: undefined,
            onViewModelService: viewModelService,
            onCheckLicense: () => { }
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

ViewEADriversPage.propTypes = wizardProps;
export default ViewEADriversPage;
