import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextPageMessage: {
        id: 'policychange.eu.Vehicle Operator.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    ratingPolicy: {
        id: 'policychange.eu.Vehicle Operator.Policy is being rated...',
        defaultMessage: 'Policy is being rated...'
    }
});
