import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withModalContext } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { NoteService } from 'e1p-capability-gateway';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import NotesComponent from '../../Components/NotesComponent/NotesComponent';
import gatewayMessages from '../../gateway.messages';
import metadata from './Notes.metadata.json5';
import messages from './Notes.messages';

class PolicyNotesWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}),
        authUserData: PropTypes.shape({}),
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    policyNumber: PropTypes.string
                }),
                getUpdateCountInfo: PropTypes.func.isRequired
            }),
            basePath: PropTypes.string
        }).isRequired
    };

    static defaultProps = {
        authHeader: undefined,
        authUserData: undefined
    }

    state = {
        notesData: [],
        loaded: false,
        policyNumber: ''
    };

    componentDidMount() {
        this.getNotes();
    }

    getNotes = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                policyDetailsData: { policyResponse }
            }
        } = this.props;
        const translator = this.context;
        const { policyNumber } = policyResponse;

        this.setState({ loaded: false });

        try {
            const notesData = await NoteService.getNotesForPolicy(policyNumber, authHeader);

            this.setState({
                notesData,
                loaded: true,
                policyNumber
            });
        } catch (e) {
            this.setState({
                loaded: true
            });
            this.props.modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: translator(messages.errorLoadingNotes, {
                    policyNumber
                }),
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.close
            }).catch(_.noop);
        }
    };

    getNotesTileUpdatedCount = (substract = false) => {
        const {
            fromAccountLanding: {
                policyDetailsData: { getUpdateCountInfo }
            }
        } = this.props;

        getUpdateCountInfo('Notes', substract);
    };

    createNote = async (data) => {
        const {
            authHeader,
            fromAccountLanding: {
                policyDetailsData: { policyResponse }
            }
        } = this.props;
        const { policyNumber } = policyResponse;

        this.setState({ loaded: false });

        try {
            const policyNoteData = await NoteService.createNoteForPolicy(
                policyNumber, data,
                authHeader
            );

            if (!_.isEmpty(policyNoteData)) {
                this.getNotes();
                this.getNotesTileUpdatedCount();
            }
        } catch (e) {
            this.setState({
                loaded: true
            });
            this.props.modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: messages.errorCreatingNotes,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.close
            }).catch(_.noop);
        }
    };

    editNote = async (data) => {
        const {
            authHeader
        } = this.props;

        // const { policyNumber } = policyResponse;
        this.setState({ loaded: false });

        try {
            const policyNoteData = await NoteService.editNote(
                data,
                authHeader
            );

            if (!_.isEmpty(policyNoteData)) {
                this.getNotes();
                // this.getNotesTileUpdatedCount();
            }
        } catch (e) {
            this.setState({
                loaded: true
            });
            this.props.modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: messages.errorUpdatingNotes,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.close
            }).catch(_.noop);
        }
    };

    deleteNote = async (data) => {
        const {
            authHeader
        } = this.props;

        // const { policyNumber } = policyResponse;
        this.setState({ loaded: false });

        try {
            const policyNoteData = await NoteService.deleteNote(
                data,
                authHeader
            );

            // delete note api will return empty response if successfull
            if (_.isEmpty(policyNoteData)) {
                this.getNotes();
                this.getNotesTileUpdatedCount(true);
            }
        } catch (e) {
            this.setState({
                loaded: true
            });
            this.props.modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: messages.errorDeletingNotes,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.close
            }).catch(_.noop);
        }
    };

    render() {
        const { authUserData } = this.props;
        const { notesData, loaded, policyNumber } = this.state;
        const overrides = {
            policyNotes: {
                initialNotesData: notesData,
                createNote: this.createNote,
                editNote: this.editNote,
                deleteNote: this.deleteNote,
                visible: loaded,
                authUserData,
                policyNumber
            },
            notesLoader: {
                loaded
            }
        };
        const resolvers = {
            resolveComponentMap: {
                notescomponent: NotesComponent
            }
        };

        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

const PolicyNotes = withModalContext(PolicyNotesWithoutModalContext);

export default withAuthenticationContext(PolicyNotes);
