/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import messages from './DriverAssignmentTable.messages';
import styles from './DriverAssignmentTable.module.scss';


import { IconButton, DropdownSelectField } from '@jutro/legacy/components';


function DriverAssignmentTable(props) {
    const {
        id,
        principalDriverData,
        occasionalDriverData,
        header,
        getAvailableDrivers,
        handleDriverChange,
        numberOfDrivers,
        numberOfVehicles,
        getAvailableVehicles,
        handleVehicleChange,
        onValidate,
        showErrors,
        viewOnlyMode,
        handleOccupationChange,
        showOccupationColumn,
        shouldOccupationCellShow
    } = props;
    const translator = useTranslator();
    const {
        isComponentValid,
        onValidate: setComponentValidation,
    } = useValidation(id);

    const getAvailableOccupations = () => 
        // “Student”, “Active Military” and “Other”.
         [{
            code: messages.student.defaultMessage,
            name: messages.student
        }, {
            code: messages.activeMilitaryCode.defaultMessage,
            name: messages.activeMilitary
        }, {
            code: messages.other.defaultMessage,
            name: messages.other
        }]
    ;

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [principalDriverData, occasionalDriverData, id, onValidate, isComponentValid]);

    const tableContent = [
        <React.Fragment key={`tableContentHeader${id}`}>
            <GridItem tag="div" key="VehicleHeader" className={styles.headers}>
                <span className={styles.required}>{translator(messages.vehicle)}</span>
            </GridItem>
            <GridItem tag="div" key="VinHeader" className={styles.headers}>
                <span className={styles.required}>{translator(messages.vin)}</span>
            </GridItem>
            <GridItem tag="div" key="DriverHeader" className={styles.headers}>
                <span className={styles.required}>{translator(messages.driver)}</span>
            </GridItem>
            {showOccupationColumn && (
                <React.Fragment>
                    {principalDriverData ? (<GridItem tag="div" key="OccupationHeader" className={styles.headers}>
                        <span className={styles.required}>{translator(messages.occupation)}</span>
                        <IconButton
                            id="tooltipOccupationHeader"
                            icon="mi-help-outline"
                            size="medium"
                            tooltip={{ text: messages.occupationHelpText }}
                            className={styles.coveragesHelpTextIconButton}
                            iconClassName={styles.coveragesHelpTextIcon}
                        />
                    </GridItem>) : (<GridItem tag="div" key="emptyOccupationHeader" className={styles.headers} />)}
                </React.Fragment>
            )}
        </React.Fragment>
    ];

    // When there is only one driver and 1 vehicle, default. No user Action.
    const isThereNoUserAction = numberOfDrivers === 1 && numberOfVehicles === 1
    
    useEffect(() => {
        if (isThereNoUserAction && principalDriverData.length === 1) {
            const onlyRecord = principalDriverData[0];

            // IAP-3723: The value passed in by the event is string by default and we need to keep it as a number
            if (handleDriverChange) {handleDriverChange(parseInt(getAvailableDrivers()[0].code, 10), onlyRecord, id);}
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (principalDriverData) {
        principalDriverData.forEach((rowOfData, index) => {
            tableContent.push(
                <React.Fragment key={`tableContentPD${index}${id}`}>
                    <GridItem className={styles.gridItemPadding} tag="div" key={`vehiclecellGridContainerPDD${index}`}>
                        {
                            numberOfDrivers >= numberOfVehicles || viewOnlyMode ?
                                (
                                    <GridItem tag="div" key={`vehiclecellPDD${index}`} className={styles.staticValue}>
                                        {rowOfData.vehicle.vehicleName}
                                    </GridItem>
                                ) :
                                (
                                    <DropdownSelectField
                                        availableValues={getAvailableVehicles()}
                                        id={`vehicleDropdownPDD${index}`}
                                        value={rowOfData.vehicle.fixedId}
                                        onValueChange={(value, _, { beforeValue }) => {
                                            if (!beforeValue || value !== beforeValue.toString()) {
                                                // IAP-3723: The value passed in by the event is string by default and we need to keep it as a number
                                                handleVehicleChange(parseInt(value, 10), id, index)
                                            }
                                        }}
                                        placeholder=''
                                        readOnly={isThereNoUserAction}
                                        onValidationChange={setComponentValidation}
                                        required
                                        showErrors={showErrors}
                                    />
                                )
                        }
                    </GridItem>
                    <GridItem className={styles.gridItemPadding} tag="div" key={`vincellGridContainerPDD${index}`}>
                        <GridItem tag="div" key={`vincellPDD${index}`} className={styles.staticValue}>
                            {rowOfData.vehicle.vin}
                        </GridItem>
                    </GridItem>
                    <GridItem tag="div" className={styles.gridItemPadding} key={`drivercellPDD${index}`}>
                        {
                            numberOfDrivers >= numberOfVehicles && (!viewOnlyMode || isThereNoUserAction) ?
                                (<DropdownSelectField
                                    availableValues={getAvailableDrivers()}
                                    id={`driverDropdown${index}`}
                                    value={rowOfData.driver.fixedId}
                                    // IAP-3723: The value passed in by the event is string by default and we need to keep it as a number
                                    onValueChange={(value) => handleDriverChange(parseInt(value, 10), rowOfData, id, index)}
                                    placeholder=''
                                    readOnly={isThereNoUserAction}
                                    onValidationChange={setComponentValidation}
                                    required
                                    showErrors={showErrors}
                                />) :
                                (
                                    <GridItem tag="div" key={`drivercell${index}`} className={styles.staticValue}>
                                        {rowOfData.driver.driverName}
                                    </GridItem>
                                )
                        }
                    </GridItem>
                    {/* IAP-3723 Optional Occupation Column */}
                    {showOccupationColumn && (<React.Fragment>
                        <GridItem className={styles.gridItemPadding} tag="div" key={`occupationcell${index}`}>
                            {
                                shouldOccupationCellShow(rowOfData, index) ?
                                    (<DropdownSelectField
                                        availableValues={getAvailableOccupations()}
                                        id={`OccupationDropdown${index}`}
                                        value={rowOfData.occupation}
                                        onValueChange={(value, _, { beforeValue }) => {
                                            if (!beforeValue || value !== beforeValue.toString()) {
                                                handleOccupationChange(value, id, index)
                                            }
                                        }}
                                        placeholder=''
                                        onValidationChange={setComponentValidation}
                                        required
                                        showErrors={showErrors}
                                        readOnly={viewOnlyMode}
                                    />
                                    ) :
                                    (<GridItem tag="div" key={`emptyOccupation${index}`} />)
                            }
                        </GridItem>
                    </React.Fragment>)}
                </React.Fragment>
            );
        })
    }

    if (occasionalDriverData) {
        occasionalDriverData.forEach((rowOfData, index) => {
            tableContent.push(
                <React.Fragment key={`tableContentOD${index}${id}`}>
                    <GridItem className={styles.gridItemPadding} tag="div" key={`vehiclecellGridItemContainerOCC${index}`}>
                        {viewOnlyMode ?
                            (
                                <GridItem tag="div" key={`vehiclecellReadOnlyOCC${index}`} className={styles.staticValue}>
                                    {rowOfData.vehicle.vehicleName}
                                </GridItem>

                            ) :
                            (<GridItem tag="div" className={styles.gridItemPadding} key={`vehiclecellOCC${index}`}>
                                <DropdownSelectField
                                    availableValues={getAvailableVehicles()}
                                    id={`vehicleDropdown${index}`}
                                    value={rowOfData.vehicle.fixedId}
                                    onValueChange={(value, _, { beforeValue }) => {
                                        if (!beforeValue || value !== beforeValue.toString()) {
                                            // IAP-3723: The value passed in by the event is string by default and we need to keep it as a number
                                            handleVehicleChange(parseInt(value, 10), id, index)
                                        }
                                    }}
                                    placeholder=''
                                    readOnly={isThereNoUserAction}
                                    onValidationChange={setComponentValidation}
                                    required
                                    showErrors={showErrors}
                                />
                            </GridItem>)}
                    </GridItem>
                    <GridItem className={styles.gridItemPadding} tag="div" key={`vincellGridItemContainerOCC${index}`}>
                        <GridItem tag="div" key={`vincellOCC${index}`} className={styles.staticValue}>
                            {rowOfData.vehicle.vin}
                        </GridItem>
                    </GridItem>
                    <GridItem tag="div" className={styles.gridItemPadding} key={`drivercellGridContainerOCC${index}`}>
                        {viewOnlyMode ?
                            (
                                <GridItem tag="div" key={`drivercellOCC${index}`} className={styles.staticValue}>
                                    {rowOfData.driver.driverName}
                                </GridItem>
                            ) :
                            (
                                <DropdownSelectField
                                    availableValues={getAvailableDrivers()}
                                    id={`driverDropdown${index}`}
                                    value={rowOfData.driver.fixedId}
                                    // IAP-3723: The value passed in by the event is string by default and we need to keep it as a number
                                    onValueChange={(value) => handleDriverChange(parseInt(value, 10), rowOfData, id, index)}
                                    placeholder=''
                                    readOnly={isThereNoUserAction}
                                    onValidationChange={setComponentValidation}
                                    required
                                    showErrors={showErrors}
                                />
                            )}
                    </GridItem>
                    {/* IAP-3723 Optional Occupation Column */}
                    {showOccupationColumn &&
                        (<React.Fragment>
                            <GridItem className={styles.gridItemPadding} tag="div" key={`occupationcell${index}`}>
                                <GridItem tag="div" key={`emptyOccupation${index}`} />
                            </GridItem>
                        </React.Fragment>)
                    }
                </React.Fragment>
            );
        })
    }

    const columnVariable = showOccupationColumn ? ['1fr', '1fr', '1fr', '1fr'] : ['1fr', '1fr', '1fr'];

    return (
        <div key={id}>
            <h4 key={`header${id}`}>{header}</h4>
            <Grid key={`grid${id}`}columns={columnVariable} gap="none" className={styles.assignmentTableBorder}>
                {tableContent}
            </Grid>
        </div>
    );
}

DriverAssignmentTable.propTypes = {
    id: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    principalDriverData: PropTypes.arrayOf(PropTypes.shape({})),
    occasionalDriverData: PropTypes.arrayOf(PropTypes.shape({})),
    getAvailableDrivers: PropTypes.func,
    getAvailableVehicles: PropTypes.func,
    handleDriverChange: PropTypes.func,
    handleVehicleChange: PropTypes.func,
    numberOfDrivers: PropTypes.number.isRequired,
    numberOfVehicles: PropTypes.number.isRequired,
    viewOnlyMode: PropTypes.bool,
    handleOccupationChange: PropTypes.func
};

DriverAssignmentTable.defaultProps = {
    principalDriverData: undefined,
    occasionalDriverData: undefined,
    handleDriverChange: undefined,
    handleVehicleChange: undefined,
    viewOnlyMode: false,
    getAvailableDrivers: undefined,
    getAvailableVehicles: undefined,
    handleOccupationChange: () => { }
}
export default DriverAssignmentTable;
