import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';

import metadata from './FirePlaceComponent.metadata.json5';
import styles from './FirePlaceComponent.scss';
import './FirePlaceComponent.messages';

function FirePlaceComponent(props) {
    const {
        data: firePlaceVM,
        path,
        id,
        onValidate,
        onValueChange,
        viewOnly,
        showErrors,
        index
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [firePlaceVM, id, onValidate, isComponentValid]);

    /**
     * E1PAP1PC-15716:E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const firePlaceContainerElement = document.getElementById(`firePlacesGrid${index}`);

        if (firePlaceContainerElement
            && _.isEmpty(_.get(firePlaceVM, 'value.heatingUnitType'))) {
            const firePlaceElement = firePlaceContainerElement.querySelector('input[id="heatingUnitType"]');

            if (firePlaceElement && firePlaceElement.focus !== undefined) {
                firePlaceElement.focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnly,
            showErrors,
            autoComplete: false
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };
    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                firePlaceVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [firePlaceVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={firePlaceVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

FirePlaceComponent.propTypes = {
    value: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    viewOnly: PropTypes.bool,
    showErrors: PropTypes.bool,
    index: PropTypes.number.isRequired
};
FirePlaceComponent.defaultProps = {
    value: {},
    labelPosition: 'top',
    path: undefined,
    viewOnly: false,
    showErrors: false
};

export default FirePlaceComponent;
