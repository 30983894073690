import React, { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, withModalContext } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './LossMitigationComponent.metadata.json5';
import styles from './LossMitigationComponent.module.scss';

import { Button } from '@jutro/legacy/components';

function LossMitigationComponent(props) {
    const {
        transactionVM,
        viewModelService,
        isOpen,
        onResolve,
        viewOnly,
        title,
        setFieldsChangedOnCoveragePage
    } = props;
    const [transactionVMClone, setTransactionVMClone] = useState(viewModelService.clone(transactionVM));
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSecondaryWaterResistance, setIsSecondaryWaterResistance] = useState(false);
    const [isWallToFoundationRestraint, setIsWallToFoundationRestraint] = useState(false);
    const [roofAttachment, setRoofAttachment] = useState();
    const [roofCovering, setRoofCovering] = useState();
    const [roofShape, setRoofShape] = useState();
    const [roofToWallConnection, setRoofToWallConnection] = useState();
    const [shutters, setShutters] = useState();
    const translator = useTranslator();
    const { isComponentValid, onValidate } = useValidation('LossMitigationComponent');

    useEffect(() => {
        const windstormMitigationList = _.get(transactionVMClone,
            'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
            []);

        if (_.find(windstormMitigationList, {featureType: 'secondarywaterresistance'})) {
            setIsSecondaryWaterResistance(true);
        }

        if (_.find(windstormMitigationList, {featureType: 'walltofoundationrestraint'})) {
            setIsWallToFoundationRestraint(true);
        }

        const roofAttachmentItem = _.find(windstormMitigationList, (windstormMitigation) => _.includes(['roofattachment2.5nail12plywood', 'roofattachment2.5nail6plywood'], windstormMitigation.featureType));

        if (roofAttachmentItem) {
            setRoofAttachment(roofAttachmentItem.featureType);
        }

        const roofCoveringItem = _.find(windstormMitigationList, (windstormMitigation) => _.includes(['roofcoveringreinforcedconcreteroofdeck'], windstormMitigation.featureType));

        if (roofCoveringItem) {
            setRoofCovering(roofCoveringItem.featureType);
        }

        const roofShapeItem = _.find(windstormMitigationList, (windstormMitigation) => _.includes(['roofshapehip'], windstormMitigation.featureType));

        if (roofShapeItem) {
            setRoofShape(roofShapeItem.featureType);
        }

        const roofToWallConnectionItem = _.find(windstormMitigationList, (windstormMitigation) => _.includes(['rooftowallconnectionclips', 'rooftowallconnectionsinglewraps', 'rooftowallconnectiondoublewraps'], windstormMitigation.featureType));

        if (roofToWallConnectionItem) {
            setRoofToWallConnection(roofToWallConnectionItem.featureType);
        }

        const windstormShutters = _.find(windstormMitigationList, (windstormMitigation) => _.includes(['shuttershurricaneprotection', 'shuttersintermediate'], windstormMitigation.featureType));

        if (windstormShutters) {
            setShutters(windstormShutters.featureType);
        }
        // It needs to execute on mount only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveAndNextHandler = useCallback(() => {
        if (isComponentValid) {
            const {
                _xCenter,
                _dtoName
            } = transactionVMClone.lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures;
            const newTransactionVMCloneData = viewModelService.clone(transactionVMClone);
            const windstormMitigationFeatureValues = [];

            if (isSecondaryWaterResistance) {
                windstormMitigationFeatureValues.push(viewModelService.create({ featureType: 'secondarywaterresistance' }, _xCenter, _dtoName).value); 
            }

            if (isWallToFoundationRestraint) {
                windstormMitigationFeatureValues.push(viewModelService.create({ featureType: 'walltofoundationrestraint' }, _xCenter, _dtoName).value); 
            }

            if (roofAttachment) {
                windstormMitigationFeatureValues.push(viewModelService.create({ featureType: roofAttachment }, _xCenter, _dtoName).value); 
            }

            if (roofCovering) {
                windstormMitigationFeatureValues.push(viewModelService.create({ featureType: roofCovering }, _xCenter, _dtoName).value); 
            }

            if (roofToWallConnection) {
                windstormMitigationFeatureValues.push(viewModelService.create({ featureType: roofToWallConnection }, _xCenter, _dtoName).value); 
            }

            if (roofShape) {
                windstormMitigationFeatureValues.push(viewModelService.create({ featureType: roofShape }, _xCenter, _dtoName).value); 
            }

            if (shutters) {
                windstormMitigationFeatureValues.push(viewModelService.create({ featureType: shutters }, _xCenter, _dtoName).value); 
            }

            _.set(newTransactionVMCloneData,
                'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
                windstormMitigationFeatureValues);
            setTransactionVMClone(newTransactionVMCloneData);
            setFieldsChangedOnCoveragePage(true);
            onResolve(newTransactionVMCloneData);
        } else {
            updateIsPageSubmitted(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isComponentValid, isSecondaryWaterResistance, isWallToFoundationRestraint,
        roofAttachment, roofCovering, roofShape, roofToWallConnection, shutters,
        setTransactionVMClone, setFieldsChangedOnCoveragePage, transactionVMClone,
        onResolve
    ]);

    const onCancel = useCallback(
        () => {
            onResolve(transactionVMClone);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const onIsSecondaryWaterResistanceChange = useCallback(
        (value) => {
            setIsSecondaryWaterResistance(value);
        }, [setIsSecondaryWaterResistance]
    );

    const onIsWallToFoundationRestraintChange = useCallback(
        (value) => {
            setIsWallToFoundationRestraint(value);
        }, [setIsWallToFoundationRestraint]
    );
    
    const onRoofAttachmentChange = useCallback(
        (value) => {
            setRoofAttachment(value);
        }, [setRoofAttachment]
    );

    const onRoofCoveringChange = useCallback(
        (value) => {
            setRoofCovering(value);
        }, [setRoofCovering]
    );
    
    const onRoofShapeChange = useCallback(
        (value) => {
            setRoofShape(value);
        }, [setRoofShape]
    );
    
    const onRoofToWallConnectionChange = useCallback(
        (value) => {
            setRoofToWallConnection(value);
        }, [setRoofToWallConnection]
    );
    
    const onShuttersChange = useCallback(
        (value) => {
            setShutters(value);
        }, [setShutters]
    );

    const resolvers = {
        resolveClassNameMap: styles
    };

    const overrideProps = {
        '@field': {
            readOnly: viewOnly,
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false,
            required: true
        },
        isSecondaryWaterResistance: {
            value: isSecondaryWaterResistance,
            onValueChange: onIsSecondaryWaterResistanceChange,
        },
        isWallToFoundationRestraint: {
            value: isWallToFoundationRestraint,
            onValueChange: onIsWallToFoundationRestraintChange,
        },
        roofAttachmentSelect: {
           value: roofAttachment,
           onValueChange: onRoofAttachmentChange
        },
        roofCoveringSelect: {
           value: roofCovering,
           onValueChange: onRoofCoveringChange
        },
        roofShapeSelect: {
           value: roofShape,
           onValueChange: onRoofShapeChange
        },
        roofToWallConnectionSelect: {
           value: roofToWallConnection,
           onValueChange: onRoofToWallConnectionChange
        },
        shuttersSelect: {
           value: shutters,
           onValueChange: onShuttersChange
        },
        completeMissingFieldMessageDiv: {
            visible: isPageSubmitted && !isComponentValid
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={styles.transactionComparisonModal}>
            <ModalHeader title={translator(title)} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={transactionVMClone}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    {translator(commonMessages.cancel)}
                </Button>
                <Button onClick={saveAndNextHandler} hidden={viewOnly}>
                    {translator(commonMessages.save)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

LossMitigationComponent.propTypes = {
    viewOnly: PropTypes.bool,
    setFieldsChangedOnCoveragePage: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    transactionVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.func.isRequired,
};

LossMitigationComponent.defaultProps = {
    viewOnly: false
};

export default withModalContext(LossMitigationComponent);
