import { defineMessages } from 'react-intl';


export default defineMessages({
    existingAdvisors: {
        id: 'components.E1PAdvisorLookupComponent.Existing Advisors',
        defaultMessage: 'Existing Advisors'
    },
    retrievingAdvisors: {
        id: 'components.E1PAdvisorLookupComponent.Retrieving Advisors...',
        defaultMessage: 'Retrieving Advisors...'
    },
    existingAdvisorsFoundMessage: {
        id: 'components.E1PAdvisorLookupComponent.Existing advisor(s) found. Select an existing advisor.',
        defaultMessage: 'Existing advisor(s) found. Select an existing advisor.'
    },
    noAdvisorFoundMessage: {
        id: 'components.E1PAdvisorLookupComponent.No existing advisor(s) found. Please review search criteria.',
        defaultMessage: 'No existing advisor(s) found. Please review search criteria.'
    },
    advisorLookupError: {
        id: 'components.E1PAdvisorLookupComponent.Unable to connect to the Advisor service at this time.',
        defaultMessage: 'Unable to connect to the Advisor service at this time.'
    },
    advisorFirstName: {
        id: 'components.E1PAdvisorLookupComponent.First Name',
        defaultMessage: 'First Name'
    },
    advisorStateCode: {
        id: 'components.E1PAdvisorLookupComponent.Location',
        defaultMessage: 'Location'
    },
    advisorId: {
        id: 'components.E1PAdvisorLookupComponent.Advisor ID',
        defaultMessage: 'Advisor ID'
    }
});
