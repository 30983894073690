import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    cloneDeep as _cloneDeep,
    set as _set
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { e1pContactUtil } from 'e1p-capability-hooks';
import { appendMetadataWithIndex } from '@xengage/gw-jutro-adapters-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from './EADriverPrefillComponent.metadata.json5';
import './EADriverPrefillComponent.messages';
import styles from './EADriverPrefillComponent.module.scss';

function EADriverPrefillComponent(props) {
    const {
        value: driverVM,
        onPNI,
        onSNI,
        index,
        handleStatusChange
    } = props;
    const [prefillFormData, setPrefillFormData] = useState(driverVM);

    useEffect(() => {
        if (!prefillFormData.displayName) {
            const middleName = prefillFormData.middleName ? prefillFormData.middleName : '';

            prefillFormData.displayName = `${prefillFormData.firstName} ${middleName} ${prefillFormData.lastName}`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPrefillDriverRadioButtonValueChange = useCallback(
        (radioButtonCode) => {
            const updatedFormData = _cloneDeep(prefillFormData);

            _set(updatedFormData, 'driverStatus', radioButtonCode);
            setPrefillFormData(updatedFormData);
            handleStatusChange(radioButtonCode, index);
        },
        [handleStatusChange, index, prefillFormData]
    );

    const formattedMetadata = useMemo(() => appendMetadataWithIndex(metadata.pageContent, index), [index]);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        [`driverStatus${index}`]: {
            readOnly: (onPNI && e1pContactUtil.samePerson(prefillFormData, onPNI))
                || (onSNI && e1pContactUtil.samePerson(prefillFormData, onSNI)),
            availableValues: [
                {
                    code: 'AddedToQuote',
                    name: e1pCommonMessages.yes
                },
                {
                    code: 'NotInHousehold',
                    name: e1pCommonMessages.no
                }]
        },
        [`eaPrefillDriverdateOfBirth${index}`]: {
            value: `##/##/${prefillFormData.dateOfBirth.year}`
        }
    };
    
    const resolvers = {
        resolveCallbackMap: {
            onPrefillDriverRadioButtonValueChange
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={formattedMetadata}
            model={prefillFormData}
            onValueChange={handleStatusChange}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

EADriverPrefillComponent.propTypes = {
    value: PropTypes.shape({}),
    onPNI: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        dateOfBirth: PropTypes.shape({
            day: PropTypes.number,
            year: PropTypes.number,
            month: PropTypes.number,
        }).isRequired
    }).isRequired,
    onSNI: PropTypes.shape({}),
    index: PropTypes.number.isRequired,
    handleStatusChange: PropTypes.func.isRequired
};
EADriverPrefillComponent.defaultProps = {
    value: {},
    onSNI: undefined
};
export default EADriverPrefillComponent;
