import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
    useRef,
    useMemo
} from 'react';
import {
    get, set, isEmpty, isUndefined, filter,
    sortBy, cloneDeep, isNil, findIndex, noop, find, map
} from 'lodash';
import config from 'app-config';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { withRouter } from 'react-router-dom';
import {
    ViewModelForm,
    ViewModelServiceContext
} from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import EATravelPackagesConfig from 'e1p-portals-util-js/EATravelPackages-config.json';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { CoverageUtil, VehicleUtil, FeatureUtil, ClausesUtil as e1pClausesUtil } from 'e1p-portals-util-js';
import { useModal } from '@jutro/components';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useLandingPageUtil, useModifierUtil } from 'e1p-capability-hooks';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import {
    PackageDifferenceComponent,
    ComprehensiveOnlyDescriptionComponent
} from 'e1p-capability-policyjob-react';
import { RenewalService } from 'e1p-capability-renewal';
import { commonMessages as e1pCommonMessages, eaCommonMessages } from 'e1p-platform-translations';
import { QuoteProposalService } from 'e1p-capability-gateway';
import metadata from './CoveragePage.metadata.json5';
import messages from './CoveragePage.messages';
import wizardMessages from '../../EARenewalWizard.messages';

const structureCustomQuote = (renewalVM, clauses) => 
    //   convert OfferingDTO to CustomQuotedDTO structure
     ({
        coverages: clauses.personalAuto_EA
    })
;

const getCustomQuote = (vm, lobPath, lobName, filterChangedClauses = false) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages, lobName, null
        );
    }

    return structureCustomQuote(vm, clausesToUpdate);
};

const LOB = 'personalAuto_EA';

function CoveragePage(props) {
    const modalApi = useModal();
    const translator = useTranslator();
    const [loadingClause, updateLoadingClause] = useState();
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        authHeader,
        steps,
        jumpTo,
        updateWizardSnapshot,
        authUserData,
        e1pGoNext,
        markFurthestStep,
        currentStepIndex
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [columnData, setColumnData] = useState({});
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [platinumSelectedState, setPlatinumSelected] = useState(false);
    const [platStateChanged, setPlatStateChanged] = useState(false);
    const [isQuoteStale, setIsQuoteStale] = useState(false);
    const [ADDSelectedBeforePlatinum, setADDSelectedBeforePremium] = useState(false);
    const [ADDClauseInCoverages, setADDClauseInCoverages] = useState(false);
    const [ADDSelectedState, setADDSelected] = useState(undefined);
    const [isFetchingQuoteProposal, setIsFetchingQuoteProposal] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const paperLessIndValue = get(renewalVM, 'lobData.personalAuto_EA.paperlessInd.value') === undefined
        ? false : get(renewalVM, 'lobData.personalAuto_EA.paperlessInd.value');
    // canStartEdit_Ext will be true if the renewal is in workflow, else it will be false
    const isRenewalInWorkflow = get(renewalVM, 'value.canStartEdit_Ext', false);
    const changeSummaryIndex = findIndex(steps, ({ path }) => path === '/change-summary');
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [selectedVehicleCoverages, setSelectedVehicleCoverages] = useState({});
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const [updatingLineLevelCoverages, setUpdatingLineLevelCoverages] = useState(false);
    const [updatingVehicleLevelCoverages, setUpdatingVehicleLevelCoverages] = useState(false);

    const hasExtendedAttributeEditability = authUserData.permissions_Ext.includes('extendedattributeeditability_ext');
    const policyState = get(renewalVM, 'baseData.policyAddress.state.value.code');

    const isCompOnlyFeatureAvailable = FeatureUtil.isCompOnlyFeatureAvailableForState(policyState);
    const isSurchargeSectionVisible = FeatureUtil.isSurchargeSectionAvailableForState(policyState);
    const isTravelPackageVisible = FeatureUtil.isTravelPackageVisibleForState(policyState);

    const {
        isComponentValid,
        onValidate,
        initialValidation,
        registerComponentValidation,
        disregardFieldValidation,
        registerInitialComponentValidation
    } = useValidation('EACoveragePage');

    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    const {
        structuredModifiers
    } = useModifierUtil(renewalVM);
    const modifiers = structuredModifiers;

    const navRef = useRef();

    // In Renewal flow user will directly land on coverage page so making each step as visited
    // so that user can navigate to visited pages by clicking on tabs on chevron
    useEffect(() => {
        markFurthestStep(currentStepIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps]);

    const writeValue = useCallback(
        (value, path) => {
            set(renewalVM, path, value);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData]
    );

    const whatsIncludedHandler = useCallback(async () => {
        const componentProps = {
            lob: 'PersonalAuto_EA',
            policyState
        };
        const result = await modalApi.showModal(
            <PackageDifferenceComponent {...componentProps} />
        );

        return result;
    }, [policyState, modalApi]);

    const compOnlyDescriptionHandler = async () => {
        const result = await modalApi.showModal(
            <ComprehensiveOnlyDescriptionComponent />
        );

        return result;
    };

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = get(renewalVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(renewalVM, 'lobData.personalAuto_EA.paperlessInd.value');
        const paperlessEmail = get(renewalVM, 'lobData.personalAuto_EA.paperlessEmail.value');

        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [renewalVM, isPaperlessEmailUpdated]);

    const updatePlatinumADD = useCallback(() => {
        let tableContent = renewalVM.lobData.personalAuto_EA
            .offerings?.children[0].coverages?.vehicleCoverages?.value[0]?.coverages;
        const platinumClause = tableContent.filter((cov) => cov.codeIdentifier === 'EA_PlatinumCoverageBundle');
        const platinumSelected = platinumClause[0]?.selected;

        if (!platinumSelected && platinumSelectedState) {
            setPlatinumSelected(platinumSelected);
            setPlatStateChanged(true);
        } else if (platinumSelected && !platinumSelectedState) {
            setPlatinumSelected(platinumSelected);
            setPlatStateChanged(false);
        }

        tableContent = renewalVM.lobData.personalAuto_EA.offerings?.children[0]?.coverages?.lineCoverages.value;

        const ADDClause = tableContent.filter((cov) => cov.codeIdentifier === 'EA_AccidentalDeathAndDismemberment_Line');

        // ADD is not in every product version; running below code will break things
        if (ADDClause.length === 0) {
            setADDClauseInCoverages(false);

            return undefined;
        }

        setADDClauseInCoverages(true);

        const ADDSelected = ADDClause[0]?.selected;

        if (ADDSelected && !platinumSelectedState && ADDSelectedState === false) {
            setADDSelected(ADDSelected);
            setADDSelectedBeforePremium(true);
        } else if (!ADDSelected && ADDSelectedState) {
            setADDSelected(ADDSelected);
        }

        if (ADDSelectedState === undefined) {
            setADDSelected(ADDSelected);
        }
    }, [ADDSelectedState, platinumSelectedState, renewalVM.lobData.personalAuto_EA.offerings]);


    const generateColumnData = () => {
        const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
        const lobOfferings = get(renewalVM, `${lobOfferingPath}.value`);
        let filteredOfferings = cloneDeep(lobOfferings);

        filteredOfferings = filteredOfferings.filter((offering) => offering.branchCode !== 'CUSTOM');

        const colData = filteredOfferings
            .map((lobOffering, lobIndex) => {
                // Added this condition to hide the vehicleCoverages from UI for which hidden
                // indicator is coming as true.
                const vehicleData = get(lobOffering, 'coverages.vehicleCoverages', []);

                vehicleData.forEach((coverage, index) => {
                    const filteredCoverages = coverage.coverages
                        .filter((cov) => !(cov.isHiddenInVersion));

                    set(lobOffering, `coverages.vehicleCoverages[${index}].coverages`, filteredCoverages);
                });

                return {
                    name: lobOffering.branchName,
                    code: lobOffering.branchCode,
                    quote: {
                        path: '',
                        data: {}
                    },
                    lob: {
                        path: `${lobOfferingPath}.children[${lobIndex}]`,
                        data: lobOffering
                    },
                    policyViewVM: renewalVM
                };
            });

        return sortBy(colData, ['code']);
    };


    useEffect(() => {
        const colData = generateColumnData();

        setColumnData(colData);
        updatePlatinumADD();

        // set isQuoteStale, when status is draft
        if (get(renewalVM, 'value.baseData.periodStatus') === 'Draft') {
            setIsQuoteStale(true);
        }
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
      *
      * @param {Obj} newRenewalDTO renewal dto after clause update
      * @param {Obj} oldDTO renewal prior to server call
      */
    const setFieldsFromForm = (newRenewalDTO, oldDTO) => {
        const isPaperLess = get(oldDTO, 'lobData.personalAuto_EA.paperlessInd', false);

        set(newRenewalDTO, 'lobData.personalAuto_EA.paperlessInd', isPaperLess);

        const email = get(oldDTO, 'lobData.personalAuto_EA.paperlessEmail', undefined);

        set(newRenewalDTO, 'lobData.personalAuto_EA.paperlessEmail', email);
    };


    const onForceUpdateCoverages = useCallback(
        async () => {
            const updateOptions = {
                fullCoverageUpdate: true,
                useGenericValueAsString: true,
                shouldSyncCoveragesAfterUpdate: true,
                forceUpdate: true,
            }

            return Promise.resolve(RenewalService.updateDraftRenewalWithOptions(
                renewalVM.value,
                updateOptions,
                authHeader
            ).then((saveWithOptionsResponse) => {
                set(renewalVM, 'value', saveWithOptionsResponse);
                updateWizardData(renewalVM);
                setIsQuoteStale(true);
                updateLoadingClause(undefined);
            }));

        },
        [authHeader, renewalVM, updateWizardData]
    );

    const onUpdateCustomQuote = useCallback(
        (_basePath, lobPath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(renewalVM, lobPath, lobName, false);
            const oldRenewalVM = viewModelService.clone(renewalVM);
            const getRemovedClausesID = (newRenewalVM, path) => ClausesUtil.getRemovedClausesID(
                oldRenewalVM, newRenewalVM, path
            );

            return RenewalService.updateCoverages(
                renewalVM.value.jobID,
                { personalAuto_EA: customQuote.coverages },
                renewalVM.sessionUUID.value,
                authHeader
            ).then((updatedSubmission) => {
                const changedPath = lobPath.replace(/.children/, '');

                //   Need to persist entered data
                setFieldsFromForm(updatedSubmission, renewalVM.value);
                //   Set model to server respone
                renewalVM.value = updatedSubmission;

                const updatedClauses = get(updatedSubmission, `${changedPath}.coverages`);

                set(renewalVM, `${lobPath}.coverages`, updatedClauses);

                const removedFieldsFromLineCoverages = getRemovedClausesID(renewalVM, `${lobPath}.coverages.lineCoverages`);
                const vehicleCoveragePath = `${lobPath}.coverages.vehicleCoverages`;
                const removedFieldsFromVehicleCoverages = get(renewalVM, `${vehicleCoveragePath}.value`)
                    .flatMap((coverage, index) => getRemovedClausesID(renewalVM, `${vehicleCoveragePath}.children[${index}].coverages`));
                const allRemovedFields = [
                    ...removedFieldsFromLineCoverages,
                    ...removedFieldsFromVehicleCoverages];

                disregardFieldValidation(allRemovedFields);
                setIsQuoteStale(true);
                updateWizardData(renewalVM);
                updateLoadingClause(undefined);
            });
        },
        [authHeader, disregardFieldValidation, renewalVM, updateWizardData,
            viewModelService]
    );
    const onClauseChange = useCallback((schedule, path) => {
        const lobOfferingPath = 'lobData.personalAuto_EA.offerings.children[0]';

        writeValue(schedule, path);

        return onUpdateCustomQuote({}, lobOfferingPath);
    }, [onUpdateCustomQuote, writeValue]);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            // IAP-2626 : SC Auto - When UM and/or UIMBI Rejected, PE needs to send an stacking indicator to PC
            CoverageUtil.changeStackingTermValueorUMBIAndUIMBICoverages(renewalVM, changedPath, value, updateWizardData);
            set(renewalVM, changedPath, value);
            e1pClausesUtil.setClauseValue(renewalVM, value, changedPath);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData]
    );

    const syncClauses = useCallback(async (value, changedPath) => {
        const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
        const baseObject = get(renewalVM, basePath);

        updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
        await onClauseChange();
        updateLoadingClause(undefined);
        updateWizardData(renewalVM);
    }, [onClauseChange, renewalVM, updateWizardData]);

    const performChangeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);

            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const coverageListPath = ClausesUtil.getObjectPathFromChangedPath(basePath);
            const selectedVehicleCoverage = get(
                renewalVM, ClausesUtil.getObjectPathFromChangedPath(coverageListPath), {}
            );
            const vehicleIndex = findIndex(
                get(renewalVM, 'lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.value', {}),
                (vehicle) => vehicle.fixedId === selectedVehicleCoverage.fixedId
            );
            const selectedCoverage = get(renewalVM, basePath, {});

            const keys = Object.keys(EATravelPackagesConfig);

            // If selected coverage change is for travel package
            if (keys.includes(selectedCoverage.codeIdentifier)) {
                const coverages = get(renewalVM, coverageListPath, {});

                // Save corresponding selected values related to package
                if (value) {
                    const newSelectedVehicleCoverages = {};

                    EATravelPackagesConfig[selectedCoverage.codeIdentifier].forEach((key) => {
                        const coverage = find(coverages, (cov) => cov.codeIdentifier === key);

                        if (coverage) {
                            set(newSelectedVehicleCoverages, [vehicleIndex, key, 'selected'], coverage.selected);
                            set(newSelectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm'], get(coverage, 'terms[0].chosenTerm'));
                        }
                    });
                    setSelectedVehicleCoverages(
                        { selectedVehicleCoverages, ...newSelectedVehicleCoverages }
                    );

                    return syncClauses(value, changedPath);
                }

                // Sync new change and check for previously selected values
                return syncClauses(value, changedPath).then(() => {
                    let isChosenTerm = false;

                    Object.keys(get(selectedVehicleCoverages, vehicleIndex, {})).forEach((key) => {
                        const index = findIndex(coverages, (cov) => cov.codeIdentifier === key);
                        const selected = get(selectedVehicleCoverages, [vehicleIndex, key, 'selected'], false);
                        const chosenTerm = get(selectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm']);

                        // Make selection of previously selected items
                        if (index >= 0 && selected) {
                            changeSubmission(true, `lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[${vehicleIndex}].coverages.children[${index}].selected`);

                            // If there is any updated term (dropdown value) in previous value
                            if (chosenTerm) {
                                isChosenTerm = true;
                            }
                        }
                    });

                    // Update chosen Terms if exists
                    if (isChosenTerm) {
                        return syncClauses(value, changedPath).then(() => {
                            Object.keys(
                                get(selectedVehicleCoverages, vehicleIndex, {})
                            ).forEach((key) => {
                                const index = findIndex(coverages,
                                    (cov) => cov.codeIdentifier === key);
                                const selected = get(selectedVehicleCoverages, [vehicleIndex, key, 'selected'], false);
                                const chosenTerm = get(selectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm']);

                                if (index >= 0 && selected) {
                                    if (chosenTerm) {
                                        changeSubmission(chosenTerm, `lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[${vehicleIndex}].coverages.children[${index}].terms.children[0].chosenTerm`);
                                    }
                                }
                            });
                        });
                    }

                    // Remove previously selected values
                    const newSelectedVehicleCoverages = {};

                    set(newSelectedVehicleCoverages, [vehicleIndex], {});
                    setSelectedVehicleCoverages(
                        { selectedVehicleCoverages, ...newSelectedVehicleCoverages }
                    );

                    return syncClauses(value, changedPath);
                });
            }

            return syncClauses(value, changedPath);
        },
        [changeSubmission, renewalVM, selectedVehicleCoverages, syncClauses]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            // vehicleName for the selected Index
            let selectedVehicleName = get(
                renewalVM, `${changedPath}._parent._parent._parent._parent._parent.vehicleName.value`
            );

            // If vehicleName not found
            if (!selectedVehicleName) {
                selectedVehicleName = get(
                    renewalVM, `${changedPath}._parent._parent._parent.vehicleName.value`
                );
            }

            const diminishingDeductibleArray = get(renewalVM, 'lobData.personalAuto_EA.diminishingDeductible.value');

            // find if there is a diminishingDeductible for secletedVehicleName
            const diminishingDeductibleFound = diminishingDeductibleArray.find(
                (dimValue) => dimValue.vehicleDisplayName === selectedVehicleName
            );
            // Check if there is a credit Accrued for the the corresponding vehicle
            const isCreditAccrued = diminishingDeductibleFound?.amount > 0;

            // When dimishing deductable is deselected
            const diminishingDeductibleRemoved = get(
                renewalVM, `${changedPath}._parent.codeIdentifier.value`
            ) === 'EA_DiminishingDeductibleVeh' && value === false;

            const isComprehensiveDeleted = get(
                renewalVM, `${changedPath}._parent.codeIdentifier.value`
            ) === 'EA_Comprehensive' && !value;

            const isCollisionDeleted = get(
                renewalVM, `${changedPath}._parent.codeIdentifier.value`
            ) === 'EA_Collision' && !value;

            // When Comprehensive or Collision is reduced below 500
            const isComprehensiveChanged = get(
                renewalVM, `${changedPath}._parent.patternCodeIdentifier.value`
            ) === 'EA_Comprehensive_Deductible' && value < 500;

            const isCollisionChanged = get(
                renewalVM, `${changedPath}._parent.patternCodeIdentifier.value`
            ) === 'EA_Collision_Deductible' && value < 500;

            if (isCreditAccrued && (diminishingDeductibleRemoved
                || isComprehensiveChanged || isCollisionChanged
                || isCollisionDeleted || isComprehensiveDeleted)) {
                return Promise.resolve(modalApi.showConfirm({
                    title: eaCommonMessages.diminishingDeductibleCreditTitle,
                    message: eaCommonMessages.diminishingDeductibleCreditInfoMessage,
                    confirmButtonText: eaCommonMessages.removeCredit,
                    cancelButtonText: e1pCommonMessages.cancel
                }).then((result) => {
                    if (result === 'cancel') {
                        return noop();
                    }

                    performChangeSubmissionAndSync(value, changedPath).then(() => {
                        updatePlatinumADD();
                    });

                    return true;
                }, noop));
            }

            return Promise.resolve(performChangeSubmissionAndSync(value, changedPath).then(() => {
                updatePlatinumADD();
            }));
        }, [performChangeSubmissionAndSync, renewalVM, updatePlatinumADD, modalApi]
    );

    const updatePaperlessIndAndEmail = (value, path) => {
        set(renewalVM, path, value);

        switch (path) {
            case 'lobData.personalAuto_EA.paperlessInd':
                if (value) {
                    const pniEmail = get(renewalVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value');

                    if (get(renewalVM, 'lobData.personalAuto_EA.paperlessEmail.value') === undefined) {
                        set(renewalVM, 'lobData.personalAuto_EA.paperlessEmail.value', pniEmail);
                    }
                } else {
                    set(renewalVM, 'lobData.personalAuto_EA.paperlessEmail.value', undefined);
                }

                break;
            default:
                break;
        }

        setIsPaperlessEmailUpdated(true);
        updateWizardData(renewalVM);
    };

    const getTotalPremium = useCallback(() => {
        const payPlans = get(columnData[0], 'lob.data.paymentPlans');
        const selectedPlan = filter(payPlans, (plan) => plan.isSelected);
        const totalPremium = selectedPlan[0]?.total?.amount;

        if ((!totalPremium && totalPremium !== 0) || isQuoteStale) {
            return undefined;
        }

        return { currency: 'usd', amount: totalPremium };
    }, [columnData, isQuoteStale]);

    const saveAndQuote = useCallback(
        async (calledFromOnSave = false) => {
            let quoteRenewal;

            try {
                setIsSavingRenewal(true);

                // If paperless Email id exist and pni email id is undefiend
                // then update pni email to paperless email
                if (isUndefined(get(renewalVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value'))
                    && !!get(renewalVM, 'lobData.personalAuto_EA.paperlessEmail.value')) {
                    set(renewalVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value',
                        get(renewalVM, 'lobData.personalAuto_EA.paperlessEmail.value'));
                }

                quoteRenewal = await RenewalService.saveAndQuoteRenewal(
                    [(renewalVM.value)],
                    authHeader
                );
            } catch {
                setIsSavingRenewal(false);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: messages.saveCoverageError,
                    message: messages.saveCoverageErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });
            }

            renewalVM.value = quoteRenewal;
            updateWizardData(renewalVM);

            if (!calledFromOnSave) {
                const hasConflicts = !isEmpty(get(renewalVM, 'value.conflicts', []));

                if (hasConflicts) {
                    let newLandingPageIndex = -1;

                    newLandingPageIndex = getLandingPageIndexForQuotedJob(
                        LOB,
                        steps
                    );

                    if (newLandingPageIndex >= 0) {
                        jumpTo(newLandingPageIndex, true);
                    }

                    return false;
                }
            }

            setIsSavingRenewal(false);

            return renewalVM;
        },
        [authHeader, getLandingPageIndexForQuotedJob, jumpTo, modalApi, renewalVM, steps, updateWizardData]
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [renewalVM, isComponentValid, isPageSubmitted]);

    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            await saveAndQuote(calledFromOnSave);

            return renewalVM;
        },
        [isComponentValid, renewalVM, saveAndQuote]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    const handleCompOnlyChangeInCopyCoverages = useCallback(async (vehiclePublicID) => {

        updateLoadingClause(vehiclePublicID);

        // call save and quote
        let quoteRenewal;

        try {
            quoteRenewal = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );
        } catch {
            updateLoadingClause(undefined);
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.saveCoverageError,
                message: messages.saveCoverageErrorMessage,
                messageProps: {
                    confirmButtonText: commonMessages.cancelModel
                },
                showCancelBtn: false
            });
        }

        renewalVM.value = quoteRenewal;
        updateWizardData(renewalVM);

        const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
        const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

        if (!isEmpty(fieldIssues) || !isEmpty(exceptions)) {
            const saveResponse = await RenewalService.saveRenewal(
                [renewalVM.value],
                authHeader
            );
            const errorsAndWarnings = get(renewalVM, 'value.errorsAndWarnings_Ext');
            const updatedfieldIssues = fieldIssues.concat(get(saveResponse, 'errorsAndWarnings_Ext.validationIssues.fieldIssues', []));

            set(errorsAndWarnings, 'validationIssues.fieldIssues', updatedfieldIssues);
            // const updatedExceptions = exceptions.concat(get(saveResponse, 'baseData.exceptions_Ext.value', []));
            set(saveResponse, 'errorsAndWarnings_Ext', errorsAndWarnings);
            set(renewalVM, 'value', saveResponse);
            updateWizardData(renewalVM);
            window.scrollTo(0, 0);
        }

        updateLoadingClause(undefined);
    }, [authHeader, modalApi, renewalVM, updateWizardData])

    const handleCompOnlyChange = useCallback(
        async (value, vehiclePublicID) => {
            /**
             * get index of vehicle and set compOnlyInd
             */
            updateLoadingClause(vehiclePublicID);

            const vehicleIndex = VehicleUtil.getVehicleIndexBasedonPublicID(renewalVM, vehiclePublicID);

            set(renewalVM, `value.lobData.personalAuto_EA.coverables.vehicles.${vehicleIndex}.compOnlyInd`, value);
            updateWizardData(renewalVM);

            if (value) {
                // set all the vehicle coverage with selected as false
                const vehicleCoverages = VehicleUtil.getVehicleCoveragesBasedonPublicID(renewalVM, vehiclePublicID);
                const updatedCoverages = vehicleCoverages.map((cov) => {
                    if (cov.codeIdentifier !== 'EA_Comprehensive') {
                        set(cov, 'selected', false);
                    } else {
                        set(cov, 'selected', true);
                    }

                    return cov;
                });
                const vehicleCoverageIndex = VehicleUtil.getVehicleCoverageIndexBasedonPublicID(renewalVM, vehiclePublicID);

                set(renewalVM, `value.lobData.personalAuto_EA.offerings.0.coverages.vehicleCoverages.${vehicleCoverageIndex}.coverages`, updatedCoverages);
                updateWizardData(renewalVM);
            }

            // check if every vehicle is componly then we need to update all line coverages to selected as false
            if (VehicleUtil.isEveryVehicleCompOnly(renewalVM)) {
                const lineCoverages = VehicleUtil.getLineLevelCoverages(renewalVM);
                const updatedLineCoverages = lineCoverages.map((cov) => {
                    set(cov, 'selected', false);

                    return cov;
                });

                set(renewalVM, 'value.lobData.personalAuto_EA.offerings.0.coverages.lineCoverages', updatedLineCoverages);
                updateWizardData(renewalVM);
            }

            // call save and quote
            let quoteRenewal;

            try {
                quoteRenewal = await RenewalService.saveAndQuoteRenewal(
                    [(renewalVM.value)],
                    authHeader
                );
            } catch {
                updateLoadingClause(undefined);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: messages.saveCoverageError,
                    message: messages.saveCoverageErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });
            }

            renewalVM.value = quoteRenewal;
            updateWizardData(renewalVM);

            const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
            const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

            if (!isEmpty(fieldIssues) || !isEmpty(exceptions)) {
                const saveResponse = await RenewalService.saveRenewal(
                    [renewalVM.value],
                    authHeader
                );
                const errorsAndWarnings = get(renewalVM, 'value.errorsAndWarnings_Ext');
                const updatedfieldIssues = fieldIssues.concat(get(saveResponse, 'errorsAndWarnings_Ext.validationIssues.fieldIssues', []));

                set(errorsAndWarnings, 'validationIssues.fieldIssues', updatedfieldIssues);
                // const updatedExceptions = exceptions.concat(get(saveResponse, 'baseData.exceptions_Ext.value', []));
                set(saveResponse, 'errorsAndWarnings_Ext', errorsAndWarnings);
                set(renewalVM, 'value', saveResponse);
                updateWizardData(renewalVM);
                window.scrollTo(0, 0);
            }

            updateLoadingClause(undefined);
        }, [authHeader, modalApi, renewalVM, updateWizardData]);

    const generateOverrides = useCallback(
        () => {
            const vehicles = get(renewalVM, 'lobData.personalAuto_EA.coverables.vehicles.value');
            const premiumTypeLabel = get(renewalVM, 'quoteType_Ext.value.code') === 'Quick'
                ? 'Unverified Premium' : 'Verified Premium';
            const overrides = {};

            if (!isEmpty(vehicles)) {
                vehicles.forEach((_, i) => {
                    overrides[`vehicleCoverage${i}`] = {
                        loadingClause
                    };
                });
            }

            overrides[premiumTypeLabel] = {
                content: premiumTypeLabel
            };

            const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
            const lobOfferings = get(renewalVM, `${lobOfferingPath}.value`);

            overrides.gridContainerLineCoverage = {
                /**
                 * invalid fields remains even though respective coverage is not present, so to avoid this
                 * want to remount whole policyLevelCoverages component in case PC does not return coverages which were present previously 
                 */
                key: `policyLevelCoverages${VehicleUtil.getLineLevelCoverages(renewalVM).length}`,
                lobOfferings,
                onChangeSubmissionAndSync: changeSubmissionAndSync,
                onChangeSubmission: changeSubmission,
                onSyncCoverages: syncClauses,
                onValidate,
                hasExtendedAttributeEditability,
                policyState,
                lobIndex: 0,
                quoteIndex: 0,
                showErrors: isPageSubmitted,
                isQuoteStale,
                setUpdatingLineLevelCoverages,
                updatingVehicleLevelCoverages
            };
            overrides.gridContainerVehicleCoverage = {
                lobOfferings,
                onChangeSubmissionAndSync: changeSubmissionAndSync,
                onChangeSubmission: changeSubmission,
                onSyncCoverages: syncClauses,
                onValidate,
                hasExtendedAttributeEditability,
                policyState,
                lobIndex: 0,
                quoteIndex: 0,
                vehicles: get(
                    renewalVM,
                    'lobData.personalAuto_EA.coverables.vehicles.value'
                ),
                quoteDataOfferings: get(renewalVM, 'quoteData.offeredQuotes.value'),
                isCompOnlyVisible: isCompOnlyFeatureAvailable,
                onCompOnlyChange: handleCompOnlyChange,
                policyTransactionVM: renewalVM,
                updateWizardData,
                onCompOnlyChangeForCopyCoverages: handleCompOnlyChangeInCopyCoverages,
                columnData,
                onForceUpdateCoverages,
                updatePlatinumADD,
                showErrors: isPageSubmitted,
                isQuoteStale,
                setUpdatingVehicleLevelCoverages,
                updatingLineLevelCoverages
            };

            return overrides;
        },
        [
            renewalVM, changeSubmissionAndSync, changeSubmission, syncClauses, onValidate, 
            hasExtendedAttributeEditability, policyState, isPageSubmitted, isQuoteStale, 
            updatingVehicleLevelCoverages, isCompOnlyFeatureAvailable, handleCompOnlyChange, 
            updateWizardData, handleCompOnlyChangeInCopyCoverages, columnData, onForceUpdateCoverages,
             updatePlatinumADD, updatingLineLevelCoverages, loadingClause
            ]
    );

    const getCovPath = (ID, index) => {
        let covPath = '';

        if (ID === 'Vehicle') {
            covPath = `lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[${index}].coverages`;
        } else if (ID === 'Line') {
            covPath = 'lobData.personalAuto_EA.offerings.children[0].coverages.lineCoverages';
        } else {
            throw new Error('You did not pass in the required ID parameter');
        }

        return covPath;
    };

    const discountChange = (value, path) => {
        set(renewalVM, path, value);
        updateWizardData(renewalVM, false);
    };

    const recalculate = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (isUndefined(get(renewalVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value'))
                && !!get(renewalVM, 'lobData.personalAuto_EA.paperlessEmail.value')) {
                set(renewalVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value',
                    get(renewalVM, 'lobData.personalAuto_EA.paperlessEmail.value'));
            }

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            setIsQuoteStale(false);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);

            return false;
        },
        [authHeader, isComponentValid, renewalVM, updateWizardData]
    );

    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setIsSavingRenewal(false);

            return false;
        },
        [
            authHeader, changeSummaryIndex, isComponentValid,
            jumpTo, renewalVM, updateWizardData, updateWizardSnapshot
        ]
    );

    // Backend has Split / Combined Single Limit as Checkbox options, we need radio box behavior
    // Method handles switching between Split / Combined Single Limit
    const onPolicyLevelCoverageBundleChange = (value) => {
        const lineCoverages = get(renewalVM.value, 'lobData.personalAuto_EA.offerings.0.coverages.lineCoverages');
        const splitLimitIndex = findIndex(lineCoverages, (cov) => cov.codeIdentifier === 'EA_SplitLimitCoverageBundle');
        const combinedLimitIndex = findIndex(lineCoverages, (cov) => cov.codeIdentifier === 'EA_CombinedSingleLimitCoverageBundle');
        const isSplitLimitSelected = value === 'EA_SplitLimitCoverageBundle';

        // We need to update both before calling custom quote api to update coverage,
        // Updating combined limit first & split limit with sync later to update coverage selection
        changeSubmission(!isSplitLimitSelected, `${getCovPath('Line')}.children[${combinedLimitIndex}].selected`);
        changeSubmissionAndSync(isSplitLimitSelected, `${getCovPath('Line')}.children[${splitLimitIndex}].selected`);
    };

    // Returns policy line coverage endorsement bundle radio options (Split / Combined Single Limit)
    const getAvailablePolicyLineCoverageBundles = () => {
        const lineCoverages = get(renewalVM, getCovPath('Line'), {});

        return map(filter(lineCoverages.value, (cov) => cov.coverageCategoryCode === 'EA_EndorsementPackage', []), (cov) => ({
                id: cov.codeIdentifier,
                displayName: cov.name
            }));
    };

    // Returns selected policy line coverage endorsement bundle
    const getSelectedPolicyLineCoverageBundle = () => {
        const lineCoverages = get(renewalVM, getCovPath('Line'), {});

        return get(find(lineCoverages.value, (cov) => cov.coverageCategoryCode === 'EA_EndorsementPackage' && cov.selected), 'codeIdentifier');
    };

    let vehicleDiscountSubtotal = 0;
    let discountSubtotal = 0;
    const lineDiscountSubtotal = get(renewalVM, 'lobData.personalAuto_EA.offerings.value[0].premiumSummary', [])
        .find((prem) => prem.premiumSummaryType === 'TotalDiscounts')?.amount || 0;

    get(renewalVM, 'lobData.personalAuto_EA.offerings.value[0].coverages.vehicleCoverages').forEach((vehicleCoverage) => {
        vehicleDiscountSubtotal += get(vehicleCoverage, 'premiumSummary', [])
            .find((prem) => prem.premiumSummaryType === 'TotalVehicleDiscounts')?.amount || 0;
    })
    discountSubtotal = vehicleDiscountSubtotal + lineDiscountSubtotal;

    const isLiabilityCovCeded = get(renewalVM, 'lobData.personalAuto_EA.cededIndicator.value', false) || get(renewalVM, 'lobData.personalAuto_EA.uwcededIndicator.value', false)

    const isTNCCoverageSelected = useMemo(
        () => CoverageUtil.isCoverageSelected(renewalVM, 'EA_TNCCov'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [renewalVM.value]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges, [isSavingCurrentPageChanges, isSavingRenewal, isSkipping]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            autoComplete: false
        },
        premiumMightChangeMessageDiv: {
            visible: renewalVM.baseData.periodStatus.value.code !== 'Bound'
        },
        quoteProposalLinkContainer: {
            onClick: (e) => {
                e.preventDefault();
                setIsFetchingQuoteProposal(true);
                QuoteProposalService.retriveQuoteProposalURL(
                    get(renewalVM, 'jobID.value'),
                    authHeader
                ).then((response) => {
                    window.open(response.quoteProposalURL, '_blank');
                    setIsFetchingQuoteProposal(false);
                }).catch(() => {
                    setIsFetchingQuoteProposal(false);
                });
            },
            disabled: renewalVM.baseData.periodStatus === 'Quoted'
                || isFetchingQuoteProposal
        },
        LiabilityCovCededMsgDiv: {
            visible: policyState === 'NC' && isLiabilityCovCeded
        },
        ADDWarningDiv: {
            visible: ADDClauseInCoverages && platStateChanged && !ADDSelectedBeforePlatinum
        },
        physicalDamageCovRemovalMsgDiv: {
            visible: policyState === 'NC' && get(renewalVM, 'lobData.personalAuto_EA.uwcededIndicator.value', false)
        },
        coveragesPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        paperlessDiscountContainer: {
            visible: get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled'])
        },
        PaperlessDiscountToggle: {
            required: true,
            value: get(renewalVM, 'lobData.personalAuto_EA.paperlessInd.value'),
            onValueChange: updatePaperlessIndAndEmail
        },
        paperlessEmailId: {
            visible: paperLessIndValue,
            required: paperLessIndValue,
            onValueChange: updatePaperlessIndAndEmail,
            value: renewalVM.lobData.personalAuto_EA.paperlessEmail.value
        },
        coveragesPageContainer: {
            visible: isPageLoaded
        },
        AutoPayDiscountToggle: {
            onValueChange: discountChange,
            value: get(renewalVM, 'lobData.personalAuto_EA.autoPayDiscInd.value')
        },
        totalPremiumID: {
            value: (() => {
                const totalPremium = getTotalPremium();

                if (!isNil(totalPremium)) {
                    return totalPremium;
                }

                return undefined;
            })()
        },
        policyTermComponent: {
            transactionVM: renewalVM,
            authHeader,
            updateWizardData
        },
        paymentOptionsID: {
            submissionVM: renewalVM,
            authHeader,
            updateWizardData,
            LOB: 'personalAuto_EA',
            isQuoteStale
        },
        buyNowButton: {
            visible: !isQuoteStale,
            onClick: async () => {
                await onNext();

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    e1pGoNext();
                }
            }
        },
        recalculateButton: {
            visible: isQuoteStale,
            onClick: () => recalculate()
        },
        monthlyPaymentScheduleComponent: {
            quoteID: get(renewalVM, 'jobID.value'),
            authHeader,
            transactionTotalAmount: getTotalPremium(),
            changeInCost: renewalVM.transactionCost?.value,
            startDate: renewalVM.baseData.periodStartDate.value,
            endDate: renewalVM.baseData.periodEndDate.value,
            jobTypeCode: renewalVM.baseData.jobType.value.code,
            offerings: get(renewalVM, 'lobData.personalAuto_EA.offerings.value')
        },
        eaSurchargeListComponentId: {
            value: modifiers.filter(
                (item) => item.applied && item.modifierType === "surcharge"
            ),
            visible: (!!filter(modifiers, { applied: true, modifierType: 'fee' }).length
                || !!filter(modifiers, { applied: true, modifierType: 'surcharge' }).length
            ) && isSurchargeSectionVisible,
            transactionVM: renewalVM,
            lob: 'PersonalAuto_EA'
        },
        eaDiscountsListComponentId: {
            visible: !!filter(modifiers, { applied: true, modifierType: 'discount' }).length,
            value: modifiers,
            discountsTotal: discountSubtotal !== 0 ? discountSubtotal : undefined
        },
        supportingPolicyComponentContainer: {
            transactionVM: renewalVM,
            LOB: 'personalAuto_EA',
            updateWizardData,
            viewOnly: false
        },
        scrollingComponentId: {
            navRef,
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('gridContainerVehicleCoverageContainer'),
            scrollWidth: 320
        },
        policyLevelCoverageBundle: {
            availableValues: getAvailablePolicyLineCoverageBundles(),
            value: getSelectedPolicyLineCoverageBundle(),
            onValueChange: onPolicyLevelCoverageBundleChange,
            // Only make it visible if options are available for policy level endorsement packages
            visible: getAvailablePolicyLineCoverageBundles().length > 0
        },
        travelPackageGrid: {
            visible: isTravelPackageVisible
        },
        tncGapMinimumPDLimitMsgDiv: {
            visible: policyState === 'IN' && isTNCCoverageSelected
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onValidate,
            whatsIncludedHandler,
            compOnlyDescriptionHandler
        },
    };

    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, renewalVM, id, path, overrideProps), [overrideProps, renewalVM]);

    const isPageLoading = useCallback(() => isUndefined(loadingClause), [loadingClause]);

    const isQuoted = useCallback(() => get(renewalVM, 'status') === 'quoted', [renewalVM]);

    useEffect(() => {
        registerComponentValidation(isPageLoading);
        registerInitialComponentValidation(isQuoted);
    }, [isPageLoading, isQuoted, registerComponentValidation, registerInitialComponentValidation]);

    const dataForComponent = {
        columnData,
        modifiers,
        renewalVM
    };

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            showNext={!isQuoteStale}
            showCustom={!isRenewalInWorkflow && isQuoteStale}
            customLabel={wizardMessages.recalculateButtonLabel}
            onCustom={recalculate}
            showCustom1
            onCustom1={reviewChanges}
            custom1Label={wizardMessages.reviewChangesLabel}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            onSave={onSave}
            showOnSave
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={dataForComponent}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

CoveragePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.shape({})
    }).isRequired,
    ...wizardProps
};
export default withRouter(withAuthenticationContext(CoveragePage));
