import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayCancel'), method, data, additionalHeaders);
}

export default class CancellationService {
    static startPolicyCancellation(policyNumber, cancelDto, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayCancel'),
            'startPolicyCancellation', [policyNumber, cancelDto], additionalHeaders);
    }

    static getEffectiveLocalDateForCancellation(policyNumber, cancelDto, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayCancel'),
            'getEffectiveLocalDateForCancellation', [policyNumber, cancelDto], additionalHeaders);
    }

    static scheduleCancellation(cancellationNumber, processDate, additionalHeaders = {}) {
        return processSubmission('scheduleCancellation', [cancellationNumber, processDate], additionalHeaders);
    }

    static rescindCancellation(cancellationNumber, additionalHeaders = {}) {
        return processSubmission('rescindCancellation', [cancellationNumber], additionalHeaders);
    }

    static findJobByJobNumber(cancellationNumber, additionalHeaders = {}) {
        return processSubmission('findJobByJobNumber', [cancellationNumber], additionalHeaders);
    }

    static withdrawCancellationByCancellationNumber(cancellationNumber, additionalHeaders = {}) {
        return processSubmission('withdrawCancellationByJobNumber', [cancellationNumber], additionalHeaders);
    }

    static bindCancellation(cancellationNumber, additionalHeaders = {}) {
        return processSubmission('bindCancellation', [cancellationNumber], additionalHeaders);
    }

    static loadCancellationJobInfo(policyNumber, additionalHeaders = {}) {
        return processSubmission('loadCancellationJobInfo', [policyNumber], additionalHeaders);
    }

    static onChangeOfLetterMailingDate(cancelDTO, additionalHeaders = {}) {
        return processSubmission('onChangeOfLetterMailingDate', [cancelDTO], additionalHeaders);
    }

    static bind(cancellationNumber, deliverDocInd, additionalHeaders = {}) {
        return processSubmission('bind', [cancellationNumber, deliverDocInd], additionalHeaders);
    }
}
