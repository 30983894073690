import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
    get as _get,
    isEmpty as _isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useOOSConflictPageLandingUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { RewriteService } from 'e1p-capability-rewrite';
import metadata from './RiskAnalysisPage.metadata.json5';
import messages from './RiskAnalysisPage.messages';

function RiskAnalysisPage(props) {
    const { authHeader } = useAuthentication();
    const {
        wizardData: rewriteVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        currentStepIndex,
        changeNextSteps,
        updateWizardSnapshot,
        authUserData
    } = props;
    const stepsRef = useRef(steps);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [saveAndQuoteInProcess, setSaveAndQuoteInProcess] = useState(false);    
    const [activeTab, updateActiveTab] = useState('');
    const translator = useTranslator();
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        registerInitialComponentValidation,
        registerComponentValidation
    } = useValidation('RiskAnalysisPage');

    useEffect(() => {
        /**
         * Using useRef to access current updated steps.
         * as we are adding new conflicts step and landing user on this newly created step
         * "step" state variable from props does not give us updated value inside useOOSConflictPageLandingUtil
         * it refers initial rendered value only(as we are adding new step and want to land user
         * on new step in)
         */
        stepsRef.current = steps;
    }, [steps]);

    const {
        removeOrAddAndLandOnConflictsPage
    } = useOOSConflictPageLandingUtil(
        stepsRef,
        currentStepIndex,
        changeNextSteps,
        jumpTo
    );

    const isQuoted = useCallback(() => (
            _get(rewriteVM, 'baseData.periodStatus.value.code') === 'Quoted'
        ), [rewriteVM]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        registerInitialComponentValidation(isQuoted);
    }, [isQuoted, registerInitialComponentValidation]);

    const saveAndQuote = useCallback(
        async (_, calledFromOnSave = false) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setSaveAndQuoteInProcess(true);
            rewriteVM.value = await RewriteService.saveAndQuote(
                [rewriteVM.value],
                authHeader
            );

            if (!calledFromOnSave) {
                /**
                  E1PAP1PC-13853 :
                  If we get conflicts in saveAndQuote, we will add conflicts page if its not present
                  and user will land on conflicts page
                  If we had conflicts and we came back and made changes such that after saveAndQuote
                  if there are no conflicts we will remove conflicts page if its present
                 */
                const hasConflicts = !_isEmpty(_get(rewriteVM, 'value.conflicts', []));

                removeOrAddAndLandOnConflictsPage(hasConflicts);

                if (hasConflicts) {
                    setSaveAndQuoteInProcess(false);

                    return false;
                }
            }

            setSaveAndQuoteInProcess(false);

            return rewriteVM;
        },
        [authHeader, isComponentValid, rewriteVM, removeOrAddAndLandOnConflictsPage]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote(undefined, true);

                const fieldIssues = _get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, rewriteVM, updateWizardSnapshot]
    );

    const getDefaultActiveTab = () => {
        if (_get(rewriteVM.value, 'errorsAndWarnings.underwritingIssues.length') > 0) {
            return 'uwIssuesTab';
        }

        return 'lossesAndViolationTab';
    };

    const isPageValid = useCallback(() => {
        const isQuoteBlocked = rewriteVM.errorsAndWarnings
            .underwritingIssues?.value?.some(
                (issue) => issue.currentBlockingPoint === 'BlocksQuote' || issue.currentBlockingPoint === 'BlocksQuoteRelease'
            );

        return !isQuoteBlocked;
    }, [rewriteVM.errorsAndWarnings.underwritingIssues]);

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(rewriteVM, 'lobData.personalUmbrella_EU.premiumStability.value') !== undefined;

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation]);

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: !saveAndQuoteInProcess && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: !saveAndQuoteInProcess && !isSavingCurrentPageChanges,
            defaultActiveTab: getDefaultActiveTab(),
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            activeTab: activeTab !== '' ? activeTab : getDefaultActiveTab(),
            showErrors: isPageSubmitted
        },
        lossesAndViolationComponent: {
            submissionVM: rewriteVM,
            isSkipping,
            showErrors: isPageSubmitted,
            authUserData,
            isVerified: true,
            updateWizardData,
            disregardFieldValidation,
            updateIsPageSubmitted
        },
        UWIssuesComponentId: {
            submissionVM: rewriteVM,
            updateWizardData,
            authHeader,
            showErrors: isPageSubmitted
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(rewriteVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: rewriteVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        },
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                rewriteVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, rewriteVM]
    );

    return (
        <WizardPage
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            onNext={saveAndQuote}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
