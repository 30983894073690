import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
 
import {
    get as _get,
    isEmpty as _isEmpty
} from 'lodash';
import styles from './EHGarageComponent.scss';
import metadata from './EHGarageComponent.metadata.json5';
import './EHGarageComponent.messages';

function EHGarageComponent(props) {
    const {
        data: ehGarageVM,
        path,
        id,
        onValidate,
        onValueChange,
        viewOnly,
        showErrors,
        index
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [ehGarageVM, id, onValidate, isComponentValid]);

    /**
     * E1PAP1PC-15716:E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const gargeContainerElement = document.getElementById(`ehGarageGrid${index}`);

        if (gargeContainerElement
            && _isEmpty(_get(ehGarageVM, 'value.garageType'))) {
            const garageTypeElement = gargeContainerElement.querySelector('input[id="garageType"]');

            if (garageTypeElement && garageTypeElement.focus !== undefined) {
                garageTypeElement.focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const onGarageTypeChange = useCallback((value) => {
        handleValueChange(value, 'garageType');

        if (value === 'none') {
            handleValueChange(undefined, 'garageSize');
        }
    }, [handleValueChange]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnly,
            showRequired: true,
            showErrors,
            autoComplete: false
        },
        garageType: {
            onValueChange: onGarageTypeChange
        },
        garageSize: {
            required: _get(ehGarageVM, 'value.garageType') !== 'none',
            visible: _get(ehGarageVM, 'value.garageType') !== 'none'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                ehGarageVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [ehGarageVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={ehGarageVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

EHGarageComponent.propTypes = {
    data: PropTypes.shape({}),
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    viewOnly: PropTypes.bool,
    showErrors: PropTypes.bool,
    index: PropTypes.number.isRequired
};
EHGarageComponent.defaultProps = {
    data: {},
    path: undefined,
    viewOnly: false,
    showErrors: false
};

export default EHGarageComponent;
