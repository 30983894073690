import { defineMessages } from 'react-intl';

export default defineMessages({
    returntoSummary: {
        id: 'reinstatement.common.directives.templates.policy-cancel.Return to Summary Page',
        defaultMessage: 'Return to Summary'
    },
    returnToSummaryPage: {
        id: 'reinstatement.common.directives.templates.policy-cancel.Summary Page',
        defaultMessage: 'You will be returned to the summary page. Is this alright?'
    },
    exitTransaction: {
        id: 'reinstatement.common.directives.templates.policy-cancel.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'reinstatement.common.directives.templates.policy-cancel.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'reinstatement.common.directives.templates.policy-cancel.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'reinstatement.common.directives.templates.policy-cancel.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    }
});
