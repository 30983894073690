import React, {
    useCallback, useContext, useEffect, useMemo, useState, useRef
} from 'react';
import {
    filter as _filter,
    get as _get,
    includes as _includes,
    isEmpty as _isEmpty,
    isUndefined as _isUndefined,
    set as _set,
    cloneDeep as _cloneDeep,
    remove as _remove
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { AccountService, AccountSearchUtil } from 'e1p-capability-gateway';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import {
    SubmissionVMSelectors,
    RandomNumberUtil,
    AddressUtil
} from 'e1p-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { ManualPropertyLossesComponent, AccountsFoundComponent } from 'e1p-capability-policyjob-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import appConfig from 'app-config';
import {
    useEffectiveDateUtil,
    useNewBusinessUtil,
    useSniUtil,
    e1pUSStatesUtil,
    e1pDateUtil,
    useCreditReportUtil,
    useDataLayer
} from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import moment from 'moment';
import ho3UnverifiedWizardConfig from '../../../config/unverified/HO3/eh-wizard-config.json5';
import ho3VerifiedWizardConfig from '../../../config/verified/HO3/eh-wizard-config.json5';
import ho4UnverifiedWizardConfig from '../../../config/unverified/HO4/eh-wizard-config.json5';
import ho4VerifiedWizardConfig from '../../../config/verified/HO4/eh-wizard-config.json5';
import ho6UnverifiedWizardConfig from '../../../config/unverified/HO6/eh-wizard-config.json5';
import ho6VerifiedWizardConfig from '../../../config/verified/HO6/eh-wizard-config.json5';
import hf9UnverifiedWizardConfig from '../../../config/unverified/HF9/eh-wizard-config.json5';
import hf9VerifiedWizardConfig from '../../../config/verified/HF9/eh-wizard-config.json5';
import messages from './InsuredPage.messages';
import metadata from './InsuredPage.metadata.json5';
import requiredMetadata from './InsuredPage.requiredness';

const LOB = 'homeowners_EH';
const submissionVMSelector = new SubmissionVMSelectors(LOB);
const pniPath = 'lobData.homeowners_EH.primaryNamedInsured';

const policyFormsMap = {
    HO3propertyowneroccupied: 'HO3',
    HO3seasonalsecondary: 'HO3',
    HO3vacant: 'HO3',
    HO4undefined: 'HO4',
    HO6propertyowneroccupied: 'HO6',
    HO6rentedtoothers: 'HO6',
    HO6seasonalsecondary: 'HO6',
    HO6vacant: 'HO6',
    HF9seasonalsecondarynotrented: 'HF9-A',
    HF9seasonalsecondaryoccasionallyrented: 'HF9-B',
    HF9rentedornonowneroccupied: 'HF9-C',
    HF9vacant: 'DNQ - no form applies'
};
const { operatingCompanyConfig } = appConfig;
const statesNotIncludingAutoLosses = appConfig.statesNotIncludingAutoLosses ?? [];
const experienceIdMap = {
    MSA: '7119', // always the same for MSA
}

function InsuredPage(props) {
    const modalApi = useModal();
    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerInitialComponentValidation,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation('InsuredPage');
    const {
        wizardData: submissionVM,
        updateWizardData,
        changeNextSteps,
        authUserData,
        isSkipping,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const [requiredFields, updateRequiredFields] = useState([]);
    const [lapseInCoverage, updateLapseInCoverage] = useState(undefined);
    const [lastPolicyExpirationDate, updateLastPolicyExpirationDate] = useState(undefined);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const [isAnExistingAccount, setIsAnExistingAccount] = useState(false);
    const [submissionExists, setSubmissionExists] = useState(false);
    const [usageType, updateUsageType] = useState(undefined);
    const [usageTypes, updateUsageTypes] = useState([]);
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [submissionCreationInProgress, setSubmissionCreationInProgress] = useState(false);
    const [isComponentInitialized, setIsComponentInitialized] = useState(false);
    const hasPerformedAccountSearch = useRef(false);
    const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');
    const [USStates, setUSStates] = useState([]);
    const policyType = _get(submissionVM, 'lobData.homeowners_EH.policyType.value.code');
    const [initialPniPublicId, setInitialPniPublicId] = useState(false);
    const [isNewQuote, setIsNewQuote] = useState(false);
    const [initialHouseholdMembers, setInitialHouseholdMembers] = useState([]);
    const [initialRiskAddress, setInitialRiskAddress] = useState(undefined);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [showSearchButtonErrors, setShowSearchButtonErrors] = useState(false);
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const canChangeRiskState = authUserData?.permissions_Ext.includes('editriskaddress_ext');
    const sessionIdPrefixEdit = 'EH - NB - ';
    const sessionHeader = sessionIdPrefixEdit + RandomNumberUtil.randomFixedInteger(10);
    const {
        handlePeriodStartDateChange,
        getDefaultPeriodEndDate,
        getEffectiveDateBoundsForSubmission
    } = useEffectiveDateUtil(submissionVM, updateWizardData);

    // Rating date and quote creation date need to be editable for rate testing
    const [testRatingDate, setTestRatingDate] = useState(undefined);
    const [testQuoteCreationDate, setTestQuoteCreationDate] = useState(undefined);
    const environment = _get(appConfig, 'env.AMFAM_ENV', 'local');
    const { daysInFutureForBookroll, daysInFuture } = appConfig.dateInputConfig;
    const { opCo } = useContext(AmfamOktaTokenContext);

    if (!_get(submissionVM, 'baseData.experienceID_Ext.value')) {
        _set(submissionVM, 'baseData.experienceID_Ext.value', experienceIdMap[opCo]);
    }


    const {
        createAccountHolderVM,
        getLocationAndProducerCodes,
        startSubmission,
        createPrimaryNamedInsuredVM,
        getProducerDetailsFromProducerCodes
    } = useNewBusinessUtil(
        submissionVM,
        updateWizardData,
        viewModelService,
        LOB,
        isAnExistingAccount,
        authHeader,
        setLocationAndProducerCodes
    );

    const {
        showReportPopup
    } = useCreditReportUtil(
        translator,
        LOB
    );

    const { addNewQuoteTagIfApplicable } = useDataLayer();

    useEffect(()=>{
        addNewQuoteTagIfApplicable(submissionVM);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    const steps = useMemo(() => {
        const isTransitioning = submissionVM.transitioning;
        // convert to full app won't happen until we get to payment details
        //   and enter additional info or skips additional info
        const isFullQuote = _get(submissionVM.value, 'quoteType') === 'Full';

        if (policyType === 'HO4') {
            if (isFullQuote || isTransitioning) {
                return ho4VerifiedWizardConfig.steps;
            }

            return ho4UnverifiedWizardConfig.steps;
        }

        if (policyType === 'HO3') {
            if (isFullQuote || isTransitioning) {
                return ho3VerifiedWizardConfig.steps;
            }

            return ho3UnverifiedWizardConfig.steps;
        }

        if (policyType === 'HO6') {
            if (isFullQuote || isTransitioning) {
                return ho6VerifiedWizardConfig.steps;
            }

            return ho6UnverifiedWizardConfig.steps;
        }

        if (policyType === 'HF9') {
            if (isFullQuote || isTransitioning) {
                return hf9VerifiedWizardConfig.steps;
            }

            return hf9UnverifiedWizardConfig.steps;
        }

        return ho3UnverifiedWizardConfig.steps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyType]);

    useEffect(() => {
        // IAP-370: if full quote additional information page is removed
        // and flow proceeds to next page
        const nextSteps = [...steps];
        const additionalInfoStepIndex = nextSteps.findIndex((step) => step.path === '/additionalInformation');

        if (submissionVM.value.quoteType === 'Full' && additionalInfoStepIndex > -1) {
            nextSteps.splice(additionalInfoStepIndex, 1); // remove
            nextSteps.shift(); // remove current page (always first elt)
            changeNextSteps(nextSteps);
        }
    }, [changeNextSteps, steps, submissionVM.value.quoteType])

    const { createSecondaryNamedInsuredVM, removeSni } = useSniUtil(
        submissionVM,
        updateWizardData,
        viewModelService,
        LOB,
        disregardFieldValidation
    );

    const markAccountSearchCompleted = () => {
        hasPerformedAccountSearch.current = true;
    };

    const { maxEffectiveDate, minEffectiveDate, currentMinEffectiveDate } = useMemo(
        () => 
            // If Book Transfer is Yes, allow quoting up to 120 days in the future, otherwise 60 days  
             bookTransferIndicator ? getEffectiveDateBoundsForSubmission(daysInFutureForBookroll)
                : getEffectiveDateBoundsForSubmission(daysInFuture)
        ,
        [getEffectiveDateBoundsForSubmission, bookTransferIndicator, daysInFutureForBookroll, daysInFuture]
    );

    const getCreditReportStatus = useCallback(
        (quoteID) => showReportPopup(
                quoteID,
                authHeader,
                submissionVM
            ),
        [authHeader, showReportPopup, submissionVM]
    );

    const resetPolicyTypeSpecificFields = useCallback(() => {
        if (policyType === 'HO6') {
            _set(
                submissionVM,
                'lobData.homeowners_EH.hasManualPropertyAutoLosses.value',
                undefined
            );
            _set(
                submissionVM,
                'lobData.homeowners_EH.hasManualPropertyAtFaultAutoLosses.value',
                undefined
            );
        }
    }, [policyType, submissionVM]);

    const policyForm = useMemo(() => {
        let policyFormKey = policyType + usageType;

        // IAP-639 : handling corner case when
        // comp rator sends usage type for renters
        if (policyType === 'HO4') {
            policyFormKey = 'HO4undefined';
        }

        return policyFormsMap[policyFormKey];
    }, [policyType, usageType]);

    useEffect(() => {
        resetPolicyTypeSpecificFields();

        if (policyType !== 'HO4') {
            const yourHomeDTO = viewModelService.create(
                {},
                'pc',
                'amfam.edge.capabilities.policyjob.lob.eh.coverables.dto.EHYourHomeDTO'
            );
             

            const typelistMetadata = yourHomeDTO.dwellingUsageType.aspects.availableValues[0].typelist;
            let dwellingUsageTypes = typelistMetadata.getFilter(`MSA${policyType}`).codes;
            const stateSpecificFilterName = `MSA${policyType}-${policyState}`;

            if (typelistMetadata.hasFilter(stateSpecificFilterName)) {
                dwellingUsageTypes = typelistMetadata.getFilter(stateSpecificFilterName).codes;
            }

;

            const mappedUsageTypes = dwellingUsageTypes.map((item) => ({
                    code: item.code,
                    name: {
                        id: item.name
                    }
                }));

            updateUsageTypes(mappedUsageTypes);
        }

        if (policyType === 'HO3' || policyType === 'HO6') {
            updateUsageType('propertyowneroccupied');
        } else {
            updateUsageType(undefined);
        }

        const nextSteps = steps.slice(1, steps.length);

        changeNextSteps(nextSteps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyType, policyState]);

    const availablePolicyTypes = useMemo(() => {
        const { availableValues } = submissionVM.lobData.homeowners_EH.policyType.aspects;
        const ehLinePolicyTypes = _filter(availableValues, (availableValue) => {
            if (_includes(requiredFields, 'policyTypeHF9')) {
                return availableValue.belongsToCategory({
                    code: 'EHLine_Ext',
                    typelist: { name: 'PolicyLine' }
                }) && availableValue.code !== "HF9"
            }

            return availableValue.belongsToCategory({
                code: 'EHLine_Ext',
                typelist: { name: 'PolicyLine' }
            });
        });

        return ehLinePolicyTypes.map((ehLinePolicyType) => ({ code: ehLinePolicyType.code, name: translator({ id: ehLinePolicyType.name }) }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredFields]);

    /**
     * Helper function to handle below scenario:
     * if insured residence address is entered and user clicks on search account
     * and choses existing account; to keep old addresses in place for riskAddress,
     * mailing and billing address
     */
    const setCorrectAddressAfterChosingExistingAccount = useCallback(
        (policyAddress) => {
            const riskAddressSameAsEnterred = AddressUtil.isSameAddress(
                policyAddress,
                _get(
                    submissionVM,
                    'lobData.homeowners_EH.coverables.yourHome.ehlocation.value'
                )
            );
            const mailingSameAsEnterred = AddressUtil.isSameAddress(
                policyAddress,
                _get(
                    submissionVM,
                    'baseData.policyMailingAddress_Ext.value'
                )
            );

            _set(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.isRiskAddressSame_Ext.value',
                riskAddressSameAsEnterred
            );
            _set(
                submissionVM,
                'baseData.isMailingAddressSame_Ext.value',
                mailingSameAsEnterred
            );
        }, [submissionVM]
    );

    const setPNI = useCallback(
        (accountHolder) => {
            _set(submissionVM, `${pniPath}.person.value`, accountHolder);

            /**
                if insured residence address is entered and user clicks on search account
                and choses existing account; to keep old address in place for riskAddress,
                mailing and billing address calling fn setCorrectAddressAfterChosingExistingAccount
             */
            if (
                !!_get(submissionVM, 'baseData.policyAddress.addressLine1.value')
                && !!_get(submissionVM, 'baseData.policyAddress.city.value')
                && !!_get(submissionVM, 'baseData.policyAddress.state.value')
                && !!_get(submissionVM, 'baseData.policyAddress.postalCode.value')
            ) {
                setCorrectAddressAfterChosingExistingAccount(accountHolder.primaryAddress);
            }

            _set(submissionVM, 'baseData.policyAddress.value', accountHolder.primaryAddress);
            updateWizardData(submissionVM);
        },
        [setCorrectAddressAfterChosingExistingAccount, submissionVM, updateWizardData]
    );

    useEffect(() => {
        const accountHolderPath = 'baseData.accountHolder';
        const accountHolder = _get(submissionVM, `${accountHolderPath}.value`);
        const accountExists = !_isEmpty(accountHolder);

        if (!accountExists) {
            // Needed for account search
            createAccountHolderVM();
        }

        const pni = _get(submissionVM, `${pniPath}.value`);

        // Create PrimaryNamedInsured if necessary and populate it with accountHolder data
        if (_isEmpty(pni)) {
            createPrimaryNamedInsuredVM();

            if (accountExists) {
                _set(submissionVM, `${pniPath}.person.value`, accountHolder);

                // when transaction is not started, wizardPageHeader needs updated value
                // from PNI object to show the PNI Name in breadcrum
                // and renderContextComponent is using wizardsnapshot to read the values,
                // updateWizardData wont work here
                if (!_get(submissionVM, 'quoteID.value')) {
                    updateWizardSnapshot(submissionVM);
                }
            }
        }

        setIsAnExistingAccount(accountExists);

        // Set submission as existing (new quote for this account)
        if (_get(submissionVM, 'quoteID.value')) {
            setSubmissionExists(true);
        }
        // executed only once on initial render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AccountService]);

    /**
         * Helper for getting producer code on initial render and
         *   then when state changes. Will only run when a state changes
         *   and on initial render so no need to make a callback as the param
         *   will always be a new value.
         * @param {String} location State code
         * @param {Boolean} initialCallForExistingAccount
         */
    const getAndSetLocationCodes = async (location, initialCallForExistingAccount = false) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(submissionVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(submissionVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        } else if (!initialCallForExistingAccount) {
            // dont want to reset below data on continue quote
            // or when user is creating new quote for existing account
            _set(submissionVM, 'baseData.producerCode_Ext', undefined);
            _set(submissionVM, 'baseData.externalID_Ext.value', undefined);
            setProducerCodeDetails({});
            setAgencyName(undefined);
            setBookTransferIndicator(undefined);
            setServiceCenterIndicator(undefined);
        }
    };

    useEffect(() => {
        // Policy state will not exist for new account so nothing will happen
        // If New quote for an account we will use accountholders state to set
        // policy state and that state here will set the initial producer code
        if (policyState && isAgent) {
            // only for agents
            getAndSetLocationCodes(policyState, true).then(() => {
                setIsComponentInitialized(true);
            }).catch(() => setIsComponentInitialized(true));
        } else {(setIsComponentInitialized(true));}

        // Get PNI and SNI person information on load of page
        // used for determining if we want to make credit report call later
        setInitialPniPublicId(_get(submissionVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`));
        setIsNewQuote(!_get(submissionVM, 'value.quoteID'));

        const existingHouseholdOccupants = _cloneDeep(_get(submissionVM.value, `lobData[${LOB}].coverables.householdOccupants`));

        setInitialHouseholdMembers(existingHouseholdOccupants);
        setInitialRiskAddress(
            _get(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.ehlocation.value'
            )
        );
        // Only run on initial render. Helper will be called again from
        //   the state fields onChange handler
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Since risk State field is editable, so we need to reset the policy address with selectedState
     */
    const resetPolicyAddress = useCallback((selectedState) => {
        const { _xCenter, _dtoName } = _get(submissionVM, 'baseData.policyAddress');
        const policyAddressVM = viewModelService.create(
            { state: selectedState }, _xCenter, _dtoName
        );

        return policyAddressVM.value;
    }, [submissionVM, viewModelService]);

    const onPolicyStateChange = useCallback(
        async (newValue, path) => {
            _set(submissionVM, path, newValue);
            // Reset policy address
            _set(submissionVM, 'baseData.policyAddress.value', resetPolicyAddress(newValue));
            updateWizardData(submissionVM);

            // Get Producer codes for the state
            if (newValue !== undefined && isAgent) {
                // only for agents
                await getAndSetLocationCodes(newValue);
                // Because above function needs a refresh
                updateWizardData(submissionVM);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getLocationAndProducerCodes, submissionVM, updateWizardData]
    );

    useEffect(() => {
        if (submissionVM.baseData.quoteSource_Ext.sourceType === undefined) {
            const model = {
                sourceType: 'directentry',
                sourceName: 'msaagentsonly'
            };

            submissionVM.baseData.quoteSource_Ext.value = model;
        }

        authHeader['afe-session-id'] = sessionHeader;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            submissionVM.lobData.homeowners_EH.priorPolicies.value !== undefined
            && submissionVM.lobData.homeowners_EH.priorPolicies.length > 0
        ) {
            if (
                submissionVM.lobData.homeowners_EH.priorPolicies.value[0].policyExpirationDate
                !== undefined
            ) {
                // Storing date in state variable in UTC format to show correct date,
                // as we are storing date in UTC format in backend
                // Note - if date field in dto is of type localDateDTO then we don't have to do
                // these conversions, policyExpirationDate field is of type Date in dto
                //  thats why we are doing this conversion
                updateLastPolicyExpirationDate(
                    e1pDateUtil.convertToUTC(
                        _get(
                            submissionVM,
                            'lobData.homeowners_EH.priorPolicies.value[0].policyExpirationDate'
                        )
                    )
                );
            }

            updateLapseInCoverage(
                !submissionVM.lobData.homeowners_EH.priorPolicies.value[0].continuousCoverageInd
            );
        }

        if (submissionVM.lobData.homeowners_EH.coverables.yourHome !== undefined) {
            if (submissionVM.lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value) {
                updateUsageType(
                    submissionVM.lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value
                        .code
                );
            }
        }

        if (
            _isUndefined(
                _get(submissionVM, 'lobData.homeowners_EH.hasManualPropertyAutoLosses.value')
            )
        ) {
            _set(submissionVM, 'lobData.homeowners_EH.hasManualPropertyAutoLosses.value', false);
        }

        if (
            _isUndefined(
                _get(submissionVM, 'lobData.homeowners_EH.hasManualPropertyAtFaultAutoLosses.value')
            )
        ) {
            _set(
                submissionVM,
                'lobData.homeowners_EH.hasManualPropertyAtFaultAutoLosses.value',
                false
            );
        }

        if (
            _isUndefined(_get(submissionVM, 'lobData.homeowners_EH.hasManualPropertyLosses.value'))
        ) {
            _set(submissionVM, 'lobData.homeowners_EH.hasManualPropertyLosses.value', false);
        }

        updateWizardData(submissionVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initialRequiredFields = [
            'atFaultAutoLosses',
            'autoLosses',
            'propertyLosses',
            'policyTypeHF9'
        ]; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );

        if (policyState === 'IN') {
            _set(
                submissionVM,
                'lobData.homeowners_EH.hasManualPropertyAutoLosses.value',
                undefined
            );
            _set(
                submissionVM,
                'lobData.homeowners_EH.hasManualPropertyAtFaultAutoLosses.value',
                undefined
            );
        }
        // When policystate changes update the required fields
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyState]);

    const isProducerCodeDisabled = useMemo(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(submissionVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(submissionVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);

            return true;
        }

        return false;
    }, [locationAndProducerCodes, submissionVM]);

    const lastPolicyExpirationDateChange = useCallback(
        (value) => {
            if (value !== undefined) {
                updateLastPolicyExpirationDate(value);

                if (submissionVM.lobData.homeowners_EH.priorPolicies.value === undefined) {
                    _set(submissionVM, 'value.lobData.homeowners_EH.priorPolicies', []);
                }

                if (submissionVM.lobData.homeowners_EH.priorPolicies.length === 0) {
                    const priorPolicy = {
                        policySource: 'ManuallyAdded',
                        contactID: '',
                        policyRole: 'PolicyPriNamedInsured'
                    };
                    const priorPolicyVM = viewModelService.create(
                        priorPolicy,
                        'pc',
                        'amfam.edge.capabilities.policyjob.common.priorpolicy.dto.PriorPolicyDTO'
                    );

                    submissionVM.lobData.homeowners_EH
                        .priorPolicies.value.push(priorPolicyVM.value);
                }

                _set(
                    submissionVM.value,
                    `lobData.homeowners_EH.priorPolicies[${0}].policyExpirationDate`,
                    moment(new Date(value.year, value.month, value.day))
                        .format('YYYY-MM-DDTHH:mm:ss')
                        .concat('Z')
                );
                updateWizardData(submissionVM);
            }
        },
        [updateWizardData, submissionVM, viewModelService]
    );
    const lapseInCoverageChange = useCallback(
        (value) => {
            updateLapseInCoverage(value);

            if (submissionVM.lobData.homeowners_EH.priorPolicies.value === undefined) {
                _set(submissionVM, 'value.lobData.homeowners_EH.priorPolicies', []);
            }

            if (submissionVM.lobData.homeowners_EH.priorPolicies.length === 0) {
                const priorPolicy = {
                    policySource: 'ManuallyAdded',
                    contactID: '',
                    policyRole: 'PolicyPriNamedInsured'
                };
                const priorPolicyVM = viewModelService.create(
                    priorPolicy,
                    'pc',
                    'amfam.edge.capabilities.policyjob.common.priorpolicy.dto.PriorPolicyDTO'
                );

                submissionVM.lobData.homeowners_EH.priorPolicies.value.push(priorPolicyVM.value);
            }

            _set(
                submissionVM.value,
                `lobData.homeowners_EH.priorPolicies[${0}].continuousCoverageInd`,
                !value
            );
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const locationCodeChange = useCallback(
        (value) => {
            _set(submissionVM, 'baseData.externalID_Ext', value);

            const selectedProducerCode = locationAndProducerCodes?.producerCodes
                ?.find((producerCode) => producerCode?.externalId_Ext === value);

            _set(submissionVM, 'baseData.producerCode_Ext', selectedProducerCode?.code);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData, locationAndProducerCodes]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const showPropertyLossesPopOver = async () => {
        const componentProps = {
            title: translator(messages.selfDeclaredPropertyLossesTitle),
            iconClassType: false,
            showCancelBtn: false,
            showSaveBtn: true,
            submissionVM,
            viewModelService,
            onValidateParentPage: onValidate,
            disregardFieldValidationParentPage: disregardFieldValidation,
            lineOfBusiness: 'homeowners_EH'
        };
        const result = await modalApi.showModal(
            <ManualPropertyLossesComponent {...componentProps} />
        );

        return result;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setPriorPolicyAndLossesFieldsToRetrieveResp = (submission) => {
        if (policyType !== 'HO4') {
            _set(
                submission,
                `lobData[${LOB}].priorPolicies`,
                submissionVM.lobData[LOB].priorPolicies.value
            );
            _set(
                submission,
                `lobData[${LOB}].priorPolicies[${0}].contactID`,
                submissionVM.lobData[LOB].primaryNamedInsured.person.contactID.value
            );
        }

        _set(
            submission,
            `lobData[${LOB}].hasManualPropertyAtFaultAutoLosses`,
            submissionVM.lobData[LOB].hasManualPropertyAtFaultAutoLosses.value
        );
        _set(
            submission,
            `lobData[${LOB}].hasManualPropertyAutoLosses`,
            submissionVM.lobData[LOB].hasManualPropertyAutoLosses.value
        );
        _set(
            submission,
            `lobData[${LOB}].hasManualPropertyLosses`,
            submissionVM.lobData[LOB].hasManualPropertyLosses.value
        );
        _set(
            submission,
            `lobData[${LOB}].manualPropertyLossRecords`,
            submissionVM.lobData[LOB].manualPropertyLossRecords.value
        );
    };

    const addHouseholdOccupants = useCallback((submission) => {
        const sni = submissionVMSelector.getSni(submission);
        const pni = submissionVMSelector.getPni(submission);
        const householdOccupants = _get(
            submission,
            `lobData[${LOB}].coverables.householdOccupants`,
            []
        );

        // IAP-4716, cleanup household occupants, getting duplicated if any exception occurs
        _remove(householdOccupants, (occupant) => occupant.person.publicID === undefined);

        const sniOccupant = householdOccupants
            .find((occupant) => occupant.person.publicID === sni?.person?.publicID);
        const pniOccupant = householdOccupants
            .find((occupant) => occupant.person.publicID === pni?.person?.publicID);

        if (pni && !pniOccupant) {
            // check first in the existing household occupants before creating new
            if (!_isEmpty(initialHouseholdMembers)) {
                const initialPniOccupant = initialHouseholdMembers
                    .find((occupant) => occupant.person.publicID === pni?.person?.publicID);

                if (initialPniOccupant) {
                    _set(initialPniOccupant, 'person', pni.person);
                    _set(initialPniOccupant, 'relationshipToNI', undefined);
                    householdOccupants.push(initialPniOccupant);
                } else {
                    householdOccupants.push({ person: pni.person });
                }
            } else {
                householdOccupants.push({ person: pni.person });
            }

            // only update this if pni/sni changes
            _set(submission, `lobData[${LOB}].coverables.householdOccupants`, householdOccupants);
        }

        if (sni && !sniOccupant) {
            // check first in the existing household occupants before creating new
            if (!_isEmpty(initialHouseholdMembers)) {
                const initialSniOccupant = initialHouseholdMembers
                    .find((occupant) => occupant.person.publicID === sni?.person?.publicID);

                if (initialSniOccupant) {
                    _set(initialSniOccupant, 'person', sni.person);
                    _set(initialSniOccupant, 'relationshipToNI', sni.relationshipToNI);
                    householdOccupants.push(initialSniOccupant);
                } else {
                    householdOccupants.push(
                        { person: sni.person, relationshipToNI: sni.relationshipToNI }
                    );
                }
            } else {
                householdOccupants.push(
                    { person: sni.person, relationshipToNI: sni.relationshipToNI }
                );
            }

            // only update this if pni/sni changes
            _set(submission, `lobData[${LOB}].coverables.householdOccupants`, householdOccupants);
        }

        _set(submissionVM, 'value', submission);
        updateWizardData(submissionVM);
    }, [initialHouseholdMembers, submissionVM, updateWizardData]);

    /**
     * Setting riskLocation, mailingAddress and billingAddressValues based on indicators
     */
    const setCorrectAddressValues = useCallback(
        () => {
            // if riskAddressSameInd is true then set policyAddress value to risk location
            if (
                _get(
                    submissionVM,
                    'lobData.homeowners_EH.coverables.yourHome.isRiskAddressSame_Ext.value'
                )
            ) {
                _set(
                    submissionVM,
                    'lobData.homeowners_EH.coverables.yourHome.ehlocation.value',
                    _get(submissionVM, 'baseData.policyAddress.value')
                );
            }

            // if mailingAddressSameInd is true then set policyAddress value to mailingAddress
            if (
                _get(
                    submissionVM,
                    'baseData.isMailingAddressSame_Ext.value'
                )
            ) {
                _set(
                    submissionVM,
                    'baseData.policyMailingAddress_Ext.value',
                    _get(submissionVM, 'baseData.policyAddress.value')
                );
            }

            //  if billingAddressSameInd is true then set mailingAddress value to billingAddress
            if (
                _get(
                    submissionVM,
                    'baseData.isBillingAddressSame_Ext.value'
                )
            ) {
                _set(
                    submissionVM,
                    'baseData.policyBillingAddress_Ext.value',
                    _get(submissionVM, 'baseData.policyMailingAddress_Ext.value')
                );
            }
        }, [submissionVM]
    );

    /**
     * Only set Mailing and Billing to Address if Indicators are set to true
     */
    const setCorrectAddressFromAccountValues = useCallback(
        () => {
            // if mailingAddressSameInd is true then set policyAddress value to mailingAddress
            if (
                _get(
                    submissionVM,
                    'baseData.isMailingAddressSame_Ext.value'
                )
            ) {
                _set(
                    submissionVM,
                    'baseData.policyMailingAddress_Ext.value',
                    _get(submissionVM, 'baseData.policyAddress.value')
                );
            }

            //  if billingAddressSameInd is true then set mailingAddress value to billingAddress
            if (
                _get(
                    submissionVM,
                    'baseData.isBillingAddressSame_Ext.value'
                )
            ) {
                _set(
                    submissionVM,
                    'baseData.policyBillingAddress_Ext.value',
                    _get(submissionVM, 'baseData.policyMailingAddress_Ext.value')
                );
            }
        }, [submissionVM]
    );

    const doUpdateDraftSubmission = useCallback(
        async (shouldUpdateWizardDate = true) => {
            if (_get(submissionVM, 'lobData.homeowners_EH.policyType.value.code') !== 'HO4') {
                _set(
                    submissionVM,
                    `lobData[${LOB}].coverables.yourHome.dwellingUsageType.value`,
                    usageType
                );
            }

            // Setting riskLocation, mailingAddess and billingAddress values
            setCorrectAddressValues();

            const currentAddress = _get(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.ehlocation.value'
            );
            // Comparing Updated RiskAddress with Initial RiskAddress
            const riskAddressSameAsInitiallyEntered = AddressUtil.isSameAddress(
                initialRiskAddress,
                currentAddress
            );
            const yearBuilt = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.yearBuilt.value');
             
            // For HO3 and HF9, if RiskAddress is updated then we can set yearBuilt to undefined.So that we can call prefill service.
            if ((policyType === 'HO3' || policyType === 'HF9') && yearBuilt !== undefined && !riskAddressSameAsInitiallyEntered && submissionVM.value.quoteType !== 'Full') {
                _set(
                    submissionVM,
                    'lobData.homeowners_EH.coverables.construction.yearBuilt.value',
                    undefined
                );
            }

            addHouseholdOccupants(submissionVM.value);

            if (!_isEmpty(_get(submissionVM, 'value.baseData.producerCode_Ext'))) {
                _set(
                    submissionVM,
                    'baseData.value.producerCode',
                    _get(submissionVM, 'value.baseData.producerCode_Ext')
                );
            }
             
            authHeader['afe-session-id'] = sessionIdPrefixEdit + _get(submissionVM, 'quoteID.value');

            const QuoteDataResponse = await LoadSaveService.updateDraftSubmission(
                submissionVM.value,
                authHeader
            );

            _set(
                QuoteDataResponse,
                'baseData.producerCode_Ext',
                _get(submissionVM, 'baseData.value.producerCode')
            );
            _set(submissionVM, 'value', QuoteDataResponse);
            updateWizardData(submissionVM);

            if (shouldUpdateWizardDate) {updateWizardData(submissionVM);}
        },
         
        [submissionVM, setCorrectAddressValues, initialRiskAddress, policyType, addHouseholdOccupants, authHeader, updateWizardData, usageType]
    );

    const onPropertyLossesChange = (value) => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!value) {
            if (
                submissionVM.lobData[LOB].hasManualPropertyLosses.value === undefined
                || submissionVM.lobData[LOB].hasManualPropertyLosses.value === false
            ) {
                _set(submissionVM, `lobData[${LOB}].hasManualPropertyLosses.value`, value);
                showPropertyLossesPopOver()
                    .then((result) => {
                        updateWizardData(result);
                    })
                    .catch(() => {
                        _set(submissionVM, `lobData[${LOB}].manualPropertyLossRecords.value`, []);
                        _set(submissionVM, `lobData[${LOB}].hasManualPropertyLosses.value`, false);
                        updateWizardData(submissionVM);
                    });
            }
        } else if (!value) {
            _set(submissionVM, `lobData[${LOB}].manualPropertyLossRecords.value`, []);
            _set(submissionVM, `lobData[${LOB}].hasManualPropertyLosses.value`, value);
            updateWizardData(submissionVM);
        }
    };

    const onEditPropertyLosses = useCallback(() => {
        showPropertyLossesPopOver()
            .then((result) => {
                updateWizardData(result);
            })
            .catch(() => {
                updateWizardData(submissionVM);
            });
    }, [updateWizardData, submissionVM, showPropertyLossesPopOver]);

    const showAccountsModal = useCallback(async (vm) => {
        const componentProps = {
            title: 'Existing Accounts',
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            accountsVM: vm
        };

        return modalApi.showModal(<AccountsFoundComponent {...componentProps} />);
    }, [modalApi]);

    const createNewAccountForSubmission = useCallback(async () => {
        // Create accountHolder from pni data for account creation
        const accountHolder = {
            person: _get(submissionVM, `${pniPath}.person.value`)
        };

        // Setting policy address as account holders primary address
        _set(
            accountHolder,
            'person.primaryAddress',
            submissionVM.baseData.policyAddress.value
        );

        const accountHolderViewVM = viewModelService.create(
            accountHolder,
            'pc',
            'amfam.edge.capabilities.policycommon.accountcontact.dto.AccountHolderDTO',
            {
                ProducerCodeRequired: true
            }
        );
        const newAccount = {};

        newAccount.accountHolder = _get(accountHolderViewVM, 'value');

        // E1PAP1PC-12445
        // Setting selected producer code to account.producerCodes
        // to assign correct producer code to account
        const selectedProducerCode = locationAndProducerCodes?.producerCodes?.filter(
            (producerCode) => producerCode.name
                === submissionVM.value.baseData.producerCode_Ext
        );

        newAccount.producerCodes = selectedProducerCode;

        // If user is underwriter; structure for locationAndProducerCodes is different
        if (!newAccount.producerCodes) {
            const { producerCodes } = locationAndProducerCodes.find(
                (codeObject) => codeObject.producerCodes.name
                    === submissionVM.value.baseData.producerCode_Ext
            );

            newAccount.producerCodes = [producerCodes];
        }

        const expID = _get(submissionVM, 'baseData.experienceID_Ext.value');

        // expID is if it already exists on base data; came in from a url param bridge
        // experienceIdMap is for direct entry; new quote+new account
        newAccount.experienceID = expID || experienceIdMap[opCo];

        const newAccountResponse = await AccountService.getNewAccountForContact(
            newAccount,
            authHeader
        );

        if (newAccountResponse.accountNumber) {
            _set(
                submissionVM,
                'baseData.accountNumber.value',
                newAccountResponse.accountNumber
            );
            setIsAnExistingAccount(true);
        } else {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.accountTypeErrorMessage,
                message: messages.newAccountCreateErrorMessage,
                confirmButtonText: e1pCommonMessages.confirm,
                cancelButtonText: e1pCommonMessages.cancel
            }).then(() => {
                setSubmissionCreationInProgress(false);
                window.history.back();
            });
        }
    }, [modalApi, authHeader, locationAndProducerCodes, opCo, submissionVM, viewModelService]);

    const checkAcknowledgementsPresents = useCallback(() => {
        if (submissionVM.value.lobData.homeowners_EH.acknowledgements === undefined) {
            return false;
        }

        return submissionVM.value.lobData.homeowners_EH.acknowledgements.every(
            (acknowledgement) => acknowledgement.acknowledgeAnswerType === 'accept'
        );
    }, [submissionVM.value.lobData.homeowners_EH.acknowledgements]);

    /**
     * Helper function to check if policy address is different from risk/mailing address
     * and set address indicators correctly; copy risk, mailing and billing address from
     * VM node(user entered values) and paste it into submission response
     *
     *  since user can now edit the policy Address(insured residence information),
     * so after creation of job, we need to use the address entered by user or which is
     * available in submissionVM for policy address, and need to sync it to other addresses as well
     */
    const setPolicyRiskMailingBillingAdressValuesAfterSubmissionCreation = useCallback(
        (submission) => {
            const policyAddressOnVM = _get(submissionVM, 'baseData.policyAddress.value');

            _set(submission, 'baseData.policyAddress', policyAddressOnVM);

            const riskAddressSameAsEnterred = AddressUtil.isSameAddress(
                _get(
                    submission,
                    'baseData.policyAddress'
                ),
                _get(
                    submissionVM,
                    'lobData.homeowners_EH.coverables.yourHome.ehlocation.value'
                )
            );
            const mailingSameAsEnterred = AddressUtil.isSameAddress(
                _get(
                    submission,
                    'baseData.policyAddress'
                ),
                _get(
                    submissionVM,
                    'baseData.policyMailingAddress_Ext.value'
                )
            );
            const billingSameAsEnterred = AddressUtil.isSameAddress(
                _get(
                    submissionVM,
                    'baseData.policyMailingAddress_Ext.value'
                ),
                _get(
                    submissionVM,
                    'baseData.policyBillingAddress_Ext.value'
                )
            );

            _set(
                submission,
                'lobData.homeowners_EH.coverables.yourHome.isRiskAddressSame_Ext',
                riskAddressSameAsEnterred
            );
            _set(
                submission,
                'baseData.isBillingAddressSame_Ext',
                billingSameAsEnterred
            );
            _set(
                submission,
                'baseData.isMailingAddressSame_Ext',
                mailingSameAsEnterred
            );
            _set(
                submission,
                'lobData.homeowners_EH.coverables.yourHome.ehlocation',
                submissionVM.lobData.homeowners_EH.coverables.yourHome.ehlocation.value
            );
            _set(
                submission,
                'baseData.policyBillingAddress_Ext',
                submissionVM.baseData.policyBillingAddress_Ext.value
            );
            _set(
                submission,
                'baseData.policyMailingAddress_Ext',
                submissionVM.baseData.policyMailingAddress_Ext.value
            );
        }, [submissionVM]
    );

    const shouldOrderCreditReport = useCallback(() => (initialPniPublicId
            !== _get(submissionVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`))
            || isNewQuote, [initialPniPublicId, isNewQuote, submissionVM]);

    /**
     * Sets form data to retrieve retrieve submission response
     * once form data is set to response then response data gets stored in submissionVM
     */
    const setSubmissionFieldsFromForm = useCallback((submission) => {
        setSubmissionExists(_get(submission, 'quoteID'));

        _set(submission, 'baseData.quoteSource_Ext', {
            sourceType: 'directentry',
            sourceName: 'msaagentsonly'
        });
        _set(
            submission,
            `lobData[${LOB}].secondaryNamedInsured`,
            submissionVM.lobData[LOB].secondaryNamedInsured.value
        );
        setPolicyRiskMailingBillingAdressValuesAfterSubmissionCreation(submission);
        _set(
            submission,
            `lobData[${LOB}].acknowledgements`,
            submissionVM.lobData[LOB].acknowledgements.value
        );
        _set(
            submission,
            `lobData[${LOB}].primaryNamedInsured`,
            submissionVM.lobData[LOB].primaryNamedInsured.value
        );

        if (_get(submissionVM, 'baseData.producerCode_Ext.value')) {
            _set(
                submission,
                'baseData.producerCode_Ext',
                _get(submissionVM, 'baseData.producerCode_Ext.value')
            );
        }

        setPriorPolicyAndLossesFieldsToRetrieveResp(submission);

        if (policyType !== 'HO4') {
            _set(
                submission,
                `lobData[${LOB}].priorPolicies[${0}].contactID`,
                submissionVM.lobData[LOB].primaryNamedInsured.person.contactID.value
            );

            _set(
                submission,
                `lobData[${LOB}].priorPolicies[${0}].policyRole`,
                'PolicyPriNamedInsured'
            );
        }

        _set(submissionVM, 'value', submission);
        _set(submissionVM, 'value.flowStepIDs_Ext', ['geoandpropertyloss', 'namedinsured']);
        _set(submissionVM, 'value.entryCompletionStepIDs_Ext', ['geoandpropertyloss', 'namedinsured']);
    }, [policyType, setPolicyRiskMailingBillingAdressValuesAfterSubmissionCreation, setPriorPolicyAndLossesFieldsToRetrieveResp, submissionVM]);

    const onNext = useCallback(async () => {
        const shouldGoNext = isComponentValid && checkAcknowledgementsPresents();

        // Setting riskLocation, mailingAddess and billingAddress values based on indicators
        setCorrectAddressValues();

        if (!shouldGoNext) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setSubmissionCreationInProgress(true);

        if (!isAnExistingAccount || !submissionExists) {
            if (!isAnExistingAccount) {
                // If user hasn't searched for the account, perform search
                if (!hasPerformedAccountSearch.current) {
                    const accountHolder = {
                        person: _get(submissionVM, `${pniPath}.person.value`)
                    };

                    _set(
                        accountHolder,
                        'person.primaryAddress',
                        submissionVM.baseData.policyAddress.value
                    );

                    const connectConfigObject = operatingCompanyConfig[opCo].experiences;
                    let partnerCode;

                    for (const key in connectConfigObject) {
                        // eslint-disable-next-line no-prototype-builtins
                        if (connectConfigObject.hasOwnProperty(key)) {
                            if (connectConfigObject[key].experienceId === _get(submissionVM, 'baseData.experienceID_Ext.value')) {
                                partnerCode = key;

                                if (connectConfigObject[key].retired && connectConfigObject[key].mergedPartnerCode) {
                                    partnerCode = connectConfigObject[key].mergedPartnerCode;
                                }
                            }
                        }
                    }

                    const response = await AccountSearchUtil.lookupAccount(
                        accountHolder.person,
                        submissionVM.baseData.value.producerCode_Ext,
                        partnerCode,
                        authHeader, viewModelService
                    );

                    try {
                        // user selected an account
                        const { chosenAccount } = await showAccountsModal(response);

                        if (chosenAccount.accountHolder) {
                            submissionVM.baseData.accountHolder.value = chosenAccount
                                .accountHolder.person;
                            submissionVM.baseData.accountNumber.value = chosenAccount.accountNumber;
                            submissionVM.baseData.policyAddress.value = chosenAccount
                                .accountHolder.person.primaryAddress;
                            setIsAnExistingAccount(true);
                            setCorrectAddressFromAccountValues();
                        } else {
                            // Create new account when user selects Create New Account
                            // Button/Radio Button
                            await createNewAccountForSubmission();
                        }
                    } catch {
                        // User clicks on Cancel button to stop the creation of Account
                        setSubmissionCreationInProgress(false);

                        return false;
                    }
                } else {
                    await createNewAccountForSubmission();
                }

                // New quote for new account ?
                setSubmissionCreationInProgress(true);

                const submission = await startSubmission({ testQuoteCreationDate, testRatingDate });

                setSubmissionFieldsFromForm(submission);

                await doUpdateDraftSubmission();

                if (shouldOrderCreditReport()) {
                    if (!(await getCreditReportStatus(_get(submissionVM.value, 'quoteID')))) {
                        setSubmissionCreationInProgress(false);

                        return false;
                    }
                }

                setSubmissionCreationInProgress(false);

                return submissionVM;
            }

            // New quote for this account - existing account?
            const submission = await startSubmission({ testQuoteCreationDate, testRatingDate });

            setSubmissionFieldsFromForm(submission);

            await doUpdateDraftSubmission();

            if (shouldOrderCreditReport()) {
                if (!(await getCreditReportStatus(_get(submissionVM.value, 'quoteID')))) {
                    setSubmissionCreationInProgress(false);

                    return false;
                }
            }

            setSubmissionCreationInProgress(false);

            return submissionVM;
        }

        _set(submissionVM, 'baseData.quoteSource_Ext.value', {
            sourceType: 'directentry',
            sourceName: 'msaagentsonly'
        });

        if (policyType !== 'HO4') {
            _set(
                submissionVM,
                `lobData[${LOB}].priorPolicies.value`,
                submissionVM.lobData[LOB].priorPolicies.value
            );
            _set(
                submissionVM,
                `lobData[${LOB}].priorPolicies.value[0].contactID`,
                submissionVM.lobData[LOB].primaryNamedInsured.person.contactID.value
            );
        }

        // Need this for validation rules
        _set(submissionVM, 'value.flowStepIDs_Ext', ['geoandpropertyloss', 'namedinsured']);
        _set(submissionVM, 'value.entryCompletionStepIDs_Ext', ['geoandpropertyloss', 'namedinsured']);

        // sync NI changes to householdoccupants
        //   changes to NI will not override data in the occ so must be synced
        const householdOccupants = _get(submissionVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
        const pni = _get(submissionVM.value, 'lobData.homeowners_EH.primaryNamedInsured');
        const sni = _get(submissionVM.value, 'lobData.homeowners_EH.secondaryNamedInsured');
        const pniOccupant = householdOccupants
            .find((occupant) => occupant.person.publicID === pni.person.publicID);
        // SNI may be undefined so being null safe there
        const sniOccupant = householdOccupants
            .find((occupant) => occupant.person.publicID === sni?.person?.publicID);

        if (pniOccupant) {
            // updates to existing pni contact
            _set(pniOccupant, 'person', pni.person);
        }

        if (sni && sniOccupant) {
            _set(sniOccupant, 'person', sni?.person);
        }

        // Update draft with changes to NI
        await doUpdateDraftSubmission();

        if (shouldOrderCreditReport()) {
            if (!(await getCreditReportStatus(_get(submissionVM.value, 'quoteID')))) {
                setSubmissionCreationInProgress(false);

                return false;
            }
        }

        setSubmissionCreationInProgress(false);

        return submissionVM;
    }, [
        isAnExistingAccount, submissionExists, submissionVM, doUpdateDraftSubmission,
        authHeader, viewModelService, showAccountsModal, createNewAccountForSubmission,
        startSubmission, policyType, getCreditReportStatus, checkAcknowledgementsPresents,
        isComponentValid, setCorrectAddressValues, shouldOrderCreditReport,
        setCorrectAddressFromAccountValues, testQuoteCreationDate, testRatingDate,
        opCo, setSubmissionFieldsFromForm
    ]);

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, submissionVM, updateWizardSnapshot]
    );

    const doesSubmissionExist = useCallback(() => !!_get(submissionVM.value, 'quoteID'), [submissionVM]);

    useEffect(() => {
        const stateValues = e1pUSStatesUtil.getUSStates(viewModelService);

        setUSStates(stateValues);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkIsEffectiveDateValid = useCallback(
        () => {
            // If user does not have back date permissions and the policy effective date is 5 days past the current date then return false
            const policyEffectiveDate = moment(submissionVM.baseData?.periodStartDate?.value);

            if (policyEffectiveDate?.isBefore(moment().subtract(5, 'days'), 'd')
                && !(authUserData?.permissions_Ext.includes('increasedeffectivedaterange_ext') || authUserData.permissions_Ext.includes('unlimitedbackdate'))) {
                _set(submissionVM, 'transitioning', false);

                return false;
            }

            return true;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissionVM.baseData.periodStartDate?.value]
    );

    // IAP-808, user should land on insuredPage for copied quote
    // backend is resetting acknowledgements and sourceType to null/undefined
    const isUserAllowedToProceedForCopiedQuotes = useCallback(() => {
        const sourceTypeCondition = (_get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value') === undefined
            || _get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'directentry');

        if (sourceTypeCondition) {
            return _get(submissionVM, 'value.lobData.homeowners_EH.acknowledgements.length') > 0
                && checkAcknowledgementsPresents();
        }

        return true;
    }, [checkAcknowledgementsPresents, submissionVM]);

    const isPageValid = useCallback(() => doesSubmissionExist() && checkIsEffectiveDateValid() && isUserAllowedToProceedForCopiedQuotes(), [checkIsEffectiveDateValid, doesSubmissionExist, isUserAllowedToProceedForCopiedQuotes]);

    useEffect(
        () => {
            registerInitialComponentValidation(isPageValid);
            registerComponentValidation(() => checkAcknowledgementsPresents() && checkIsEffectiveDateValid());
        },
        [
            checkIsEffectiveDateValid,
            checkAcknowledgementsPresents,
            registerInitialComponentValidation,
            registerComponentValidation,
            isPageValid
        ]
    );

    const usageTypeChange = useCallback(
        (value) => {
            updateUsageType(value);
        },
        [updateUsageType]
    );

    const handleAddressValueChange = useCallback(
        (newVal, localPath) => {
            _set(submissionVM, localPath, newVal);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const updatePolicyType = useCallback((value) => {
        const selectedPolicyType = availablePolicyTypes
            .find((policyVar) => policyVar.code === value);

        _set(submissionVM, 'lobData.homeowners_EH.policyType.value', selectedPolicyType);
        disregardFieldValidation('InsuredPage');
        disregardFieldValidation('lastPolicyExpirationDate');
        updateWizardData(submissionVM);
    }, [availablePolicyTypes, disregardFieldValidation, submissionVM, updateWizardData]);

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, address, phone, externalID, name, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            setLocationAndProducerCodes(
                [
                    {
                        producerCodes: { code: producerCode, name: producerCode },
                        locationCodes: { code: externalID, name: externalID }
                    }
                ]
            );
            _set(submissionVM, 'baseData.producerCode_Ext', producerCode);
            _set(submissionVM, 'baseData.externalID_Ext.value', externalID);
            setProducerCodeDetails({ producerCode, address, phone });
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [submissionVM],
    );

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate,
            onRemoveSni: removeSni,
            onMarkAccountSearchCompleted: markAccountSearchCompleted,
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    const handleAcknowledgementsValueChange = useCallback(
        (value) => {
            _set(submissionVM, `lobData[${LOB}].acknowledgements`, value);
            updateWizardData(submissionVM);
        },
        [updateWizardData, submissionVM]
    );
    /**
     * Helper memo for dynamically generat
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isStandardizingAddress) {
            loadingMessage = translator(messages.standardizingAddressMessage);
        } else if (isSkipping) {
            loadingMessage = translator(messages.loadingNextPageMessage);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else {
            loadingMessage = translator(messages.creatingYourSubmissionMessage);
        }

        return loadingMessage;
    }, [isStandardizingAddress, isSkipping, isSavingCurrentPageChanges, translator]);

    useEffect(() => {
        const {
            producerCode
        } = producerCodeDetails;

        const existingProducerCode = _get(
            submissionVM, 'value.baseData.producerCode_Ext',
            _get(submissionVM, 'value.baseData.producerCode')
        );

        if (existingProducerCode && isAgent
            && existingProducerCode !== producerCode) {
            (async () => {
                // only for agents
                const details = await getProducerDetailsFromProducerCodes(
                    existingProducerCode,
                    locationAndProducerCodes.producerCodes,
                    submissionVM.value.baseData.externalID_Ext
                );

                // in case we dont get any response from service
                if (details) {
                    setProducerCodeDetails(details);
                    setAgencyName(details.agencyName);
                    setBookTransferIndicator(details.bookTransferIndicator);
                    setServiceCenterIndicator(details.serviceCenterIndicatorValue);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM.baseData.producerCode_Ext.value]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        policyState: {
            onValueChange: onPolicyStateChange,
            availableValues: e1pUSStatesUtil.getStateValues(USStates, translator),
            disabled: !canChangeRiskState
        },
        policyDetailsPageLoadingIndicator: {
            loaded:
                !isStandardizingAddress
                && !submissionCreationInProgress
                && !isSkipping
                && !isSavingCurrentPageChanges,
            text: getLoadingIndicatorMessage
        },
        policyDetailsPageContainer: {
            visible:
                !submissionCreationInProgress
                && !isSkipping
                && !isSavingCurrentPageChanges
        },
        submissionPolicyType: {
            availableValues: availablePolicyTypes,
            readOnly: !!_get(submissionVM, 'quoteID.value'),
            onValueChange: updatePolicyType,
            required: true
        },
        coverageStartDate: {
            updateDateDto: handlePeriodStartDateChange,
            dateDTO: submissionVM.baseData.periodStartDate,
            defaultToToday: true,
            minDate: minEffectiveDate,
            maxDate: maxEffectiveDate,
            showErrors: isPageSubmitted || !_isUndefined(_get(submissionVM.value, 'quoteID')),
            isRequired: true,
            currentMinDate: currentMinEffectiveDate
        },
        coverageEndDate: {
            dateDTO: submissionVM.baseData.periodEndDate,
            updateDateDto: () => updateWizardData(submissionVM),
            readOnly: true,
            defaultValue: getDefaultPeriodEndDate
        },
        personalInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        originalEffectiveDate: {
            dateDTO: submissionVM.baseData.periodStartDate,
            updateDateDto: () => updateWizardData(submissionVM),
            readOnly: true,
        },
        lastPolicyExpirationDate: {
            dateDTO: lastPolicyExpirationDate,
            updateDateDto: lastPolicyExpirationDateChange,
            isRequired: policyType !== 'HO4'
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            disabled: isProducerCodeDisabled,
            onValueChange: locationCodeChange,
            visible: isAgent,
            showErrors: isPageSubmitted || showSearchButtonErrors
        },
        ExternalProducerDetailsComponent: {
            visible: !isAgent,
            defaultValue: submissionVM.baseData.externalID_Ext?.value,
            showErrors: isPageSubmitted || showSearchButtonErrors
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        sniContainer: {
            visible: !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        removeSni: {
            visible: !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        changePniComponent: {
            accountNumber: _get(submissionVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(submissionVM, 'baseData.accountNumber.value'),
            excludedContacts: [
                _get(submissionVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            householdOccupants: _get(
                submissionVM.value,
                `lobData[${LOB}].coverables.householdOccupants`,
                undefined
            )
        },
        changeSniComponent: {
            accountNumber: _get(submissionVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(submissionVM, 'baseData.accountNumber.value')
                && !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            excludedContacts: [
                _get(submissionVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            householdOccupants: _get(
                submissionVM.value,
                `lobData[${LOB}].coverables.householdOccupants`,
                undefined
            )
        },
        newHomePurchaseMainDiv: {
            visible: policyType === 'HO3' || policyType === 'HF9'
        },
        insuranceHistoryMainDiv: {
            visible: policyType !== 'HO4'
        },
        addSni: {
            visible: !_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        secondaryNamedInsured: {
            visible: !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            showErrors: isPageSubmitted
        },
        mailingAndBillingAddressComponent: {
            transactionVM: submissionVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            showErrors: isPageSubmitted
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        coverageLapseAndExpirationDate: {
            visible: policyType !== 'HO4'
        },
        coverageLapse: {
            value: lapseInCoverage,
            onValueChange: lapseInCoverageChange,
            required: policyType !== 'HO4'
        },
        usageType: {
            availableValues: usageTypes,
            value: usageType,
            onValueChange: usageTypeChange,
            visible: policyType !== 'HO4',
            required: policyType !== 'HO4',
            readOnly: usageTypes.length === 1
        },
        atFaultAutoLossesContainer: {
            visible: policyType !== 'HO6' && policyType !== 'HO4' && !statesNotIncludingAutoLosses.includes(policyState)
        },
        autoLossesContainer: {
            visible: policyType !== 'HO6' && policyType !== 'HO4' && !statesNotIncludingAutoLosses.includes(policyState)
        },
        propertyLosses: {
            onValueChange: onPropertyLossesChange,
            required: _includes(requiredFields, 'propertyLosses')
        },
        editPropertyLosses: {
            visible: !!_get(submissionVM, `lobData[${LOB}].hasManualPropertyLosses.value`),
            onClick: () => {
                onEditPropertyLosses();
            }
        },
        e1pAcknowledgementComponent: {
            acknowledgements: _get(submissionVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: _get(submissionVM, 'baseData.policyAddress.state.value.code'),
                name: translator({ id: _get(submissionVM, 'baseData.policyAddress.state.value.name') })
            },
            onValueChange: handleAcknowledgementsValueChange,
            policyType,
            showErrors: isPageSubmitted && !checkAcknowledgementsPresents(),
            lob: LOB
        },
        policyForm: {
            visible: usageType !== undefined || policyType === 'HO4',
            value: policyForm,
            readOnly: true
        },
        accountSearchComponent: {
            viewModelService,
            setIsAnExistingAccount,
            setPNI,
            authHeader,
            visible: !isAnExistingAccount,
            showSearchButtonErrors,
            setShowSearchButtonErrors,
            searchButtonErrorFunc: () => {
                setShowSearchButtonErrors(true);
                window.scrollTo(0, 0);

                return false;
            }
        },
        primaryNamedInsured: {
            showErrors: isPageSubmitted,
            isPNI: true
        },
        insuredResidenceAddress: {
            addressVM: _get(submissionVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate,
            viewOnlyMode: false,
            showParentLoader: setIsStandardizingAddress,
            showErrors: isPageSubmitted,
            onAddressChange: (value, path) => handleAddressValueChange(value, `baseData.policyAddress.${path}`)
        },
        riskAddressComponent: {
            submissionVM,
            updateWizardData,
            onValidate,
            viewModelService,
            showErrors: isPageSubmitted
        },
        ehPriorAddressComponent: {
            submissionVM,
            updateWizardData,
            onValidate,
            viewModelService,
            createPrimaryNamedInsuredVM,
            visible: policyType === 'HO3' || policyType === 'HF9',
            disregardFieldValidation,
            LOB,
            showErrors: isPageSubmitted
        },
        EhRatingDateTestComponent: {
            testRatingDate,
            setTestRatingDate,
            testQuoteCreationDate,
            setTestQuoteCreationDate,
            visible: !_get(submissionVM, 'quoteID.value', false)
                && environment !== 'prod'
        },
        quoteCreationDate: {
            dateDTO: submissionVM.baseData.quoteCreationDate_Ext,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        rateAsOfDate: {
            dateDTO: submissionVM.baseData.periodRateAsOfDate,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        ratingDate: {
            dateDTO: submissionVM.baseData.ratingDate,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps),
        [overrideProps, submissionVM]
    );

    if (!isComponentInitialized) {
        return null;
    }

    const isPageSubmittedWithErrors = isPageSubmitted
        && (!isComponentValid || !checkAcknowledgementsPresents());

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            onSave={onSave}
            showOnSave={!!_get(submissionVM, 'quoteID.value', false)}
            skipWhen={initialValidation}
            isPageSubmittedWithErrors={isPageSubmittedWithErrors || showSearchButtonErrors}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

InsuredPage.propTypes = wizardProps;

export default withAuthenticationContext(InsuredPage);
