import { defineMessages } from 'react-intl';

export default defineMessages({
    eaViolationsOperator: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-violation.Choose Operator',
        defaultMessage: 'Choose Operator'
    },
    eaViolationsDescription: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-violation.Choose Description',
        defaultMessage: 'Choose Description'
    },
    violationYes: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-violation.Yes',
        defaultMessage: 'Yes'

    },
    violationNo: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-violation.No',
        defaultMessage: 'No'
    }
});
