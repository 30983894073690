import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class ContentService {
    /**
     * Selects a quote
     * @param {Object} ContentRequestDTO ContentRequestDTO
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getContent = (ContentRequestDTO, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('content'),
            'getContent',
            [ContentRequestDTO],
            additionalHeaders
        );
    }
}
