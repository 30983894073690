
import _ from 'lodash';

function isEveryVehicleCompOnly(transactionVM) {
    const vehicles = _.get(transactionVM, 'value.lobData.personalAuto_EA.coverables.vehicles');

    return !_.isEmpty(vehicles) && _.every(vehicles, (vehicle) => vehicle.compOnlyInd);
}

function getVehicleBasedonPublicID(transactionVM, vehiclePublicID) {
    const vehicles = _.get(transactionVM, 'value.lobData.personalAuto_EA.coverables.vehicles');

    return _.find(vehicles, (veh) => veh.publicID === vehiclePublicID);
}

function getVehicleBasedonFixedID(transactionVM, vehicleFixedID) {
    const vehicles = _.get(transactionVM, 'value.lobData.personalAuto_EA.coverables.vehicles');

    return _.find(vehicles, (veh) => veh.fixedId === vehicleFixedID);
}

function getVehicleIndexBasedonPublicID(transactionVM, vehiclePublicID) {
    const vehicles = _.get(transactionVM, 'value.lobData.personalAuto_EA.coverables.vehicles');

    return _.findIndex(vehicles, (veh) => veh.publicID === vehiclePublicID);
}

function getVehicleCoverageIndexBasedonPublicID(transactionVM, vehiclePublicID) {
    const vehiclesCoverages = _.get(transactionVM, 'value.lobData.personalAuto_EA.offerings.0.coverages.vehicleCoverages');

    return _.findIndex(vehiclesCoverages, (vehicleCoverage) => vehicleCoverage.publicID === vehiclePublicID);
}

function getVehicleCoveragesBasedonPublicID(transactionVM, vehiclePublicID) {
    const allVehiclesCoverages = _.get(transactionVM, 'value.lobData.personalAuto_EA.offerings.0.coverages.vehicleCoverages');

    return _.find(allVehiclesCoverages, (vehicleCoverage) => vehicleCoverage.publicID === vehiclePublicID)?.coverages;
}

function getLineLevelCoverages(transactionVM) {
    return _.get(transactionVM, 'value.lobData.personalAuto_EA.offerings.0.coverages.lineCoverages');
}

/**
 * 
 * @param {Object} vehicle vehicle before lookup on vin and year
 * @param {Object} lookupResponse response from service
 * @param {Boolean} isSameVehicle If on vehicles page we can count on the return being a different vehicle.
 *   For different vehicles the user should key in properties again (EX: Was vin and year for a mustang. Changed vehicle to a truck.). 
 *   For additional info, it will be the same vehicle but will have VIN added.
 * @returns {Object} vehicle to persis
 */
function mapVinAndYearLookupToVehicle(vehicle, lookupResponse, isSameVehicle = false) {
    // Vehicle dto and lookup dto have different property names for same values
    lookupResponse.engine = lookupResponse.engineDescription;
    lookupResponse.grossVehicleWeight = lookupResponse.weight;
    lookupResponse.safetyFeatures = lookupResponse.features === undefined
        ? [] : lookupResponse.features;
    // Will throw an error with undefined location. User will have to realize if they change an existing vehicle to a completely different vehicle instead of deleting and re-adding 
    lookupResponse.garagingLocation = vehicle.garagingLocation;
    // eslint-disable-next-line camelcase
    lookupResponse.isGaragingAddressSame_Ext = vehicle.isGaragingAddressSame_Ext;

    // Fields we do not want to override with service values from additional info
    if (isSameVehicle) {
        lookupResponse.primaryUse = vehicle.primaryUse;
        lookupResponse.otherThanNamedInsuredOwnsInd = vehicle.otherThanNamedInsuredOwnsInd;
        lookupResponse.additionalInterests = vehicle.additionalInterests;
        lookupResponse.compOnlyInd = vehicle.compOnlyInd;
        lookupResponse.garagedOutOfStateGreaterThan6Months = vehicle.garagedOutOfStateGreaterThan6Months;
        lookupResponse.vehicleDrivers = vehicle.vehicleDrivers;
        lookupResponse.currentOdometerReading = vehicle.currentOdometerReading;
        lookupResponse.integrationId = vehicle.integrationId;
    }

    // Remove the properties that are not on the vehicle dto
    delete lookupResponse.engineDescription;
    delete lookupResponse.features;
    delete lookupResponse.weight;

    return lookupResponse;
}


/**
     * When user updates vin we are comparing existing vehicle year, make, model and series
     * with the new response year, make, model and series
     * If response has any change in above mentioned values we are updating dummy variable
     * 'vehicleInfoChanged' on vehicle to show info message to the user
     * @param {Object} response 
     * @param {Object} existingVehicle 
     */
function checkIfVehicleInfoChanged(response, existingVehicle) {
    const responseYear = _.truncate(response.year);
    const responseMake = _.upperCase(_.truncate(response.make));
    const responseModel = _.upperCase(_.truncate(response.model));
    const responseSeries = _.upperCase(_.truncate(response.series));
    const existingVehicleYear = _.truncate(existingVehicle.year);
    const existingVehicleMake = _.upperCase(_.truncate(existingVehicle.make));
    const existingVehicleModel = _.upperCase(_.truncate(existingVehicle.model));
    const existingVehicleSeries = _.upperCase(_.truncate(existingVehicle.series));

    if (
        existingVehicle.vin !== undefined
        && existingVehicle.year !== undefined
        && existingVehicle.make !== undefined
        && existingVehicle.model !== undefined
        && (responseYear + responseMake + responseModel + responseSeries)
        !== (existingVehicleYear + existingVehicleMake + existingVehicleModel + existingVehicleSeries)
    ) {
        _.set(response, 'vehicleInfoChanged', true);
    }
}

/**
 * if transaction has only one driver and only one vehicle and  given vehicle is not garaged out of state more than 6 months
 * then returns true otherwise false
 * @param {Object} transactionVM 
 * @returns {Boolean}
 */
const shouldSkipDriverAssignmentPage = (transactionVM) => {
    const drivers = _.get(transactionVM, 'value.lobData.personalAuto_EA.coverables.drivers', []);
    const vehicles = _.get(transactionVM, 'value.lobData.personalAuto_EA.coverables.vehicles', []);

    if (drivers.length === 1 && vehicles.length === 1) {
        const garagedOutOfStateGreaterThan6Months = _.get(vehicles, `[${0}].garagedOutOfStateGreaterThan6Months`, false);

        return !garagedOutOfStateGreaterThan6Months
    }

    return false;
}

/**
 * Set vehicle drivers to the vehicle
 * As only one vehicle and one driver is present we will set given driver as primary driver to the given vehicle
 * @param {Object} transactionVM 
 * @param {Function} updateWizardData 
 */
const setDriverAssignmentWhenOneDriverOneVehiclePresent = (transactionVM, updateWizardData) => {
    const drivers = _.get(transactionVM, 'value.lobData.personalAuto_EA.coverables.drivers', []);
    const vehicles = _.get(transactionVM, 'value.lobData.personalAuto_EA.coverables.vehicles', []);
    const existingVehicleDrivers = _.get(transactionVM, `value.lobData.personalAuto_EA.coverables.vehicles.[${0}].vehicleDrivers`, []);
    const vehicleDrivers = [{
        driverID: drivers[0].fixedId,
        primaryDriver: true
    }];

    if(_.isEmpty(existingVehicleDrivers)){
        _.set(vehicles, `[${0}.vehicleDrivers]`, vehicleDrivers);
    }

    updateWizardData(transactionVM);
}

export default {
    isEveryVehicleCompOnly,
    getVehicleBasedonPublicID,
    getVehicleBasedonFixedID,
    getVehicleIndexBasedonPublicID,
    getVehicleCoverageIndexBasedonPublicID,
    getVehicleCoveragesBasedonPublicID,
    getLineLevelCoverages,
    checkIfVehicleInfoChanged,
    mapVinAndYearLookupToVehicle,
    shouldSkipDriverAssignmentPage,
    setDriverAssignmentWhenOneDriverOneVehiclePresent
};
