import { defineMessages } from 'react-intl';

export default defineMessages({
    addTPIButton: {
        id: 'E1PAddTPIComponent.Add Third-Party Interest',
        defaultMessage: 'Add Third-Party Interest'
    },
    searchTPI: {
        id: 'E1PAddTPIComponent.Search Third-Party Interest',
        defaultMessage: 'Search Third-Party Interest'
    },
    searchOrAddTPI: {
        id: 'E1PAddTPIComponent.Search or Add Third-Party Interest',
        defaultMessage: 'Search or Add Third-Party Interest'
    },
    existingPerson: {
        id: 'E1PAddTPIComponent.Existing Person',
        defaultMessage: 'Existing Person'
    },
    existingOrganization: {
        id: 'E1PAddTPIComponent.Existing Organization',
        defaultMessage: 'Existing Organization'
    }
});
