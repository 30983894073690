import React, { useEffect, useState, useCallback } from 'react';
import { isEmpty as _isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { GeoService } from 'e1p-capability-gateway';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './GeoDataComponent.metadata.json5';
import messages from './GeoDataComponent.messages';

function GeoDataComponent(props) {
    const { quoteID, geoDataResponse, setGeoDataResponse, vehicles } = props;
    const translator = useTranslator();
    const [geoDataLoaded, setGeoDataLoaded] = useState(false);
    const { onValidate } = useValidation('GeoDataComponent');
    const { authHeader } = useAuthentication();

    /* empty params means when the page loads for the first time */
    useEffect(() => {
        setGeoDataLoaded(!_isEmpty(geoDataResponse));

        if (_isEmpty(geoDataResponse)) {
            GeoService.getGeoData(quoteID, authHeader)
                .then(response => {
                    setGeoDataResponse(response);
                    setGeoDataLoaded(true);
                })
                .finally(() => {
                    setGeoDataLoaded(true);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateOverrides = useCallback(() => {
        const overrides = [];

        geoDataResponse?.geoRecords?.forEach((geoRecord, index) => {
            const { assignment } = geoRecord;

            overrides[`address${index}`] = {
                value: (() => {
                    if (assignment !== undefined) {
                        return `${assignment.addressLine1}, ${assignment.city}, ${assignment.zip}, ${assignment.state}`;
                    }

                    return '';
                })(),
            };
            overrides[`geoCodeLabel${index}`] = {
                content: (() => {
                    if (assignment !== undefined) {
                        // eslint-disable-next-line arrow-body-style
                        const vehicleGeoRecord = vehicles.find(vehicle => {
                            return (
                                vehicle.integrationId === assignment.integrationId
                            );
                        });

                        if (vehicleGeoRecord) {
                            return `${vehicleGeoRecord.year || ''}${vehicleGeoRecord.year ? ' ' : ''}
                                ${vehicleGeoRecord.make || ''}${vehicleGeoRecord.make ? ' ' : ''}
                                ${vehicleGeoRecord.model || ''}${vehicleGeoRecord.model ? ' ' : ''}
                                Geo Code`;
                        } 
                    }

 return '';
                })(),
            };
        });

        return overrides;
    }, [geoDataResponse, vehicles]);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            readOnly: true,
            autoComplete: false,
        },
        geoDataPageLoadingIndicator: {
            loaded: geoDataLoaded,
            text: translator(messages.orderingGeoDataMessage),
        },
        geoDataPageContainer: {
            visible: geoDataLoaded,
        },
        ...generateOverrides(),
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={geoDataResponse}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
        />
    );
}

GeoDataComponent.propTypes = {
    quoteID: PropTypes.string.isRequired,
    setGeoDataResponse: PropTypes.func.isRequired,
    geoDataResponse: PropTypes.shape({
        assignment: PropTypes.shape({
            addressLine1: PropTypes.string,
            city: PropTypes.string,
            zip: PropTypes.string,
            state: PropTypes.string,
        }),
    }).isRequired,
    vehicles: PropTypes.arrayOf(
        PropTypes.shape({})
    ).isRequired
};

export default GeoDataComponent;
