import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
    set as _set
} from 'lodash';
import { useModal } from '@jutro/components';
import { Grid } from '@jutro/layout';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ContingencyService } from 'e1p-capability-gateway';
import { useTranslator } from '@jutro/locale';
import moment from 'moment';
import messages from './E1PContingenciesComponent.messages';
import metadata from './E1PContingenciesComponent.metadata.json5';
import styles from './E1PContingenciesComponent.modules.scss';

import { Button } from '@jutro/legacy/components';

function E1PContingenciesComponent(props) {
    const modalApi = useModal();
    const {
        contingencies,
        authUserData,
        authHeader,
        policyNumber,
        setOutstandingContingenciesPresent,
        canEditContingency
    } = props;


    const [isLoading, setIsLoading] = useState(false);
    const [contingenciesData, updateContingencies] = useState(contingencies);
    const translator = useTranslator();

    const getDueDate = (data) => {
        const dueDate = moment(data.contingencyDueDate).format('MM/DD/YYYY');

        return dueDate;
    };

    const getCreateDate = useCallback((data) => {
        const createDate = moment(data.createTime).format('MM/DD/YYYY');

        return createDate;
    }, []);

    const getPotentialAction = useCallback((data) => (
            <div>
                {translator({
                    id: `typekey.ContingencyAction.${data.contingencyAction}`,
                    defaultMessage: `typekey.ContingencyAction.${data.contingencyAction}`
                })}
            </div>
        ), [translator]);

    const getContingencyStatus = useCallback((data) => (
            <div>
                {translator({
                    id: `typekey.ContingencyStatus.${data.contingencyStatus}`,
                    defaultMessage: `typekey.ContingencyStatus.${data.contingencyStatus}`
                })}
            </div>
        ), [translator]);

    const onClickApproveContingency = useCallback((publicID, contingencyStatus) => {
        setIsLoading(true);

        const contingencyStatusUpdateRequestDto = {
            policyNumber,
            contingencies: [
                {
                    publicID,
                    contingencyStatus
                }
            ]
        };

        ContingencyService.updateContingencyStatus(contingencyStatusUpdateRequestDto, authHeader)
            .then((updatedContingncy) => {
                const contingencyToBeUpdatedIndex = contingenciesData
                    .findIndex((contingency) => contingency.publicID === updatedContingncy.publicID);

                contingenciesData[contingencyToBeUpdatedIndex] = updatedContingncy;
                updateContingencies(contingenciesData);
                setOutstandingContingenciesPresent(contingenciesData.some((contingency) => contingency.contingencyStatus === 'Pending'));
            }).catch(() => {
                modalApi.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: messages.contingenciesUpateErrorTitle,
                    message: messages.contingenciesUpdateErrorMessage
                });
            }).finally(() => {
                setIsLoading(false);
            });
    }, [policyNumber, authHeader, contingenciesData, setOutstandingContingenciesPresent, modalApi]);

    const getContigencyActionButton = useCallback((publicID, contingencyStatus, buttonText) => {
        const updateContingencyButton = (
            <div key={publicID + contingencyStatus}>
                <Button
                    className="contigencyButtons"
                    onClick={() => onClickApproveContingency(publicID, contingencyStatus)}
                >
                    {buttonText}
                </Button>
            </div>
        );

        return updateContingencyButton;
    }, [onClickApproveContingency]);

    const approveContingency = useCallback(
        (item) => {
            if (canEditContingency && item?.contingencyStatus === 'Pending' && !item.isAutomatedContingency) {
                return getContigencyActionButton(item?.publicID, 'Accepted', translator(messages.approve));
            }

            return null;
        },
        [canEditContingency, getContigencyActionButton, translator]
    );

    const rejectContingency = useCallback(
        (item) => {
            if (canEditContingency && item?.contingencyStatus === 'Pending' && !item.isAutomatedContingency) {
                return getContigencyActionButton(item?.publicID, 'Rejected', translator(messages.reject));
            }

            return null;
        },
        [canEditContingency, getContigencyActionButton, translator]
    );

    const renderExpanderContent = useCallback((data) => (
            <div>
                <Grid
                    columns={['0.25fr', '1.25fr']}
                    rows={['1fr']}
                    gap="xlarge"
                >
                    <div className="field">{translator(messages.description)}</div>
                    <div className="font-body">{data.description}</div>
                </Grid>
            </div>
        ), [translator]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            autoComplete: false
        },
        contingenciesTable: {
            data: contingenciesData,
            renderExpanderContent
        },
        contingencyLoader: {
            loaded: !isLoading,
            text: translator(messages.updatingContingenciesMessage)
        },
        e1pContingencies: {
            visible: !isLoading
        }
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                contingenciesData,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [contingenciesData, overrideProps]
    );
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getDueDate,
            getCreateDate,
            approveContingency,
            getPotentialAction,
            rejectContingency,
            getContingencyStatus,
            onRowClick: (data) => {
                _set(data, 'expanded', !data.expanded);
            }
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

E1PContingenciesComponent.propTypes = {
    contingencies: PropTypes.arrayOf(PropTypes.shape({})),
    authHeader: PropTypes.shape({}),
    policyNumber: PropTypes.string.isRequired,
    setOutstandingContingenciesPresent: PropTypes.func.isRequired
};

E1PContingenciesComponent.defaultProps = {
    contingencies: [],
    authHeader: undefined
};

export default E1PContingenciesComponent;
