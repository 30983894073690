
import React, { useCallback } from 'react';
import { get as _get, set as _set, isEmpty as _isEmpty } from 'lodash';
import { StandardizedAddressService } from 'e1p-capability-gateway';
import { AddressUtil } from 'e1p-portals-util-js';
import { commonMessages } from 'e1p-platform-translations';
import { AddressStandardizationComponent } from 'e1p-capability-policyjob-react';

/**
 * @purpose This hook is used to standardize a given address. It generates the
 *   "AddressStandardizationResultDTO" and then shows a modal with possible choices. Upon closing
 *   the modal, the calling file will receive the value of the selected choice. If you pass in
 *   "showModal" as false, the calling file will receive the result DTO instead. This hook is
 *   intended for use with the "AddressComponent", but may be used for any address passed in.
 *
 * @param {Object} viewModelService A Guidewire service used for generating view model objects.
 * @param {Object} authHeader An authorization header used for authenticating service calls.
 *
 * @returns {*} If showing the modal window, it returns the selected address. Otherwise will return
 *   the "getStandardizedAddress" response DTO when the modal window is not being shown.
 */
function useAddressStandardization(viewModelService, authHeader) {
    const showAddressModal = useCallback((vm, address) => {
        const { modalApi } = _get(window, '__giamfam.modalApi');
        const enteredAddress = { ...address };
        _set(enteredAddress, 'tempID', address.state);
        _set(
            enteredAddress,
            'formattedDisplayName',
            `${address.addressLine1}, ${address.addressLine2}, ${address.city}, ${address.state}, ${address.postalCode}`
        );
        const componentProps = {
            title: 'Address Standardization',
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            addressStandardizationResultDTO: vm,
            enteredAddress: enteredAddress
        };
        return modalApi.showModal(<AddressStandardizationComponent {...componentProps} />);
    }, []);

    /**
     * @param {VMNode} unstandardizedAddressVM The user-inputted address to be standardized.
     * @param {Boolean} showModal A boolean indicating whether to show the modal display.
     *
     * @returns {Object} The standardized address if found, otherwise the address which the user
     *   selects from the modal window.
     */
    const getStandardizedAddress = useCallback(
        async (unstandardizedAddressVM, showModal = true) => {
            /**
             * Defaulting country if its undefined
             * If user does not choose anything on the address standardization popup country was not getting default value
             */
            if (!_get(unstandardizedAddressVM, 'value.country')) {
                _set(unstandardizedAddressVM, 'value.country', 'US')
            }
            /*
             * Helper function for creating a new address "VMNode" object.
             */
            const createNewAddressVM = (address) => {
                if (address) {
                    const templateAddressObj = {
                        addressLine1: address.addressLine1,
                        addressLine2: address.addressLine2 ? address.addressLine2 : '',
                        city: address.city,
                        postalCode: address.postalCode,
                        // For EU Address we need zipCode instead of postalCode
                        zipCode: address.postalCode,
                        state: address.state,
                        country: 'US',
                        county: address.county
                    };
                    const { _dtoName, _xCenter } = unstandardizedAddressVM;
                    const addressVM = viewModelService.create(
                        templateAddressObj,
                        _xCenter,
                        _dtoName
                    );
                    return addressVM;
                }
                return undefined;
            };

            /*
             * Standardize the input address given it contains all of the necessary fields.
             */
            // We have address1 instead of addressLine1 in AdditionalInterestDTO
            const addressLine1 = _get(unstandardizedAddressVM, 'addressLine1.value', _get(unstandardizedAddressVM, 'address1.value'))
            const isAddressLine1Valid = addressLine1
                && addressLine1 !== ''
                && addressLine1?.length > 0;
            const isCityValid = _get(unstandardizedAddressVM, 'city.value') !== ''
                && _get(unstandardizedAddressVM, 'city.value', []).length > 0;
            const isStateValid = _get(unstandardizedAddressVM, 'state.value');
            const isPostalCodeValid = _get(unstandardizedAddressVM, 'postalCode.value') !== ''
                && _get(unstandardizedAddressVM, 'postalCode.value', []).length > 0;
            // Check for zipCode, we need either postalCode or zipCode in unstandardized address
            const isZipCodeValid = _get(unstandardizedAddressVM, 'zipCode.value') !== ''
                && _get(unstandardizedAddressVM, 'zipCode.value', []).length > 0;
            if (isAddressLine1Valid && isCityValid && isStateValid && (isPostalCodeValid || isZipCodeValid)) {
                const addressDTO = {
                    addressLine1: addressLine1,
                    addressLine2: _get(unstandardizedAddressVM, 'addressLine2.value', _get(unstandardizedAddressVM, 'address2.value', '')),
                    city: _get(unstandardizedAddressVM, 'city.value'),
                    // StandardizedAddressService expects postalCode, so assigning zipCode if postalCode is not available
                    postalCode: _get(unstandardizedAddressVM, 'postalCode.value') || _get(unstandardizedAddressVM, 'zipCode.value'),
                    state: _get(unstandardizedAddressVM, 'value.state')
                };
                const { modalApi } = _get(window, '__giamfam.modalApi');

                try {
                    const response = await StandardizedAddressService.getStandardizedAddress(
                        addressDTO,
                        authHeader
                    );
                    if (!_isEmpty(response.exceptions_Ext)) {
                        const errorMessage = _get(response, 'exceptions_Ext[0].errorMessage', '');
                        const POSTAL_ERROR_TEXT = 'postalCode is out of State Range';
                        const errorMessageToShow = errorMessage.includes(POSTAL_ERROR_TEXT) ?
                            commonMessages.addressStandardizationPostalErrorMessage :
                            errorMessage
                            modalApi.showAlert({
                            status: 'error',
                            icon: 'mi-error-outline',
                            title: commonMessages.addressStandardizationErrorTitle,
                            message: errorMessageToShow
                        });
                        return;
                    }
                    if (!showModal) {
                        return response;
                    }

                    if (!response.standardizationChoices) { return; }
                    const allExactMatchAddresses = response.standardizationChoices.filter((choice) => choice.isExactMatch);
                    // if only one exact match then map it directly otherwise show the popup
                    if (allExactMatchAddresses?.length === 1) {
                        return createNewAddressVM(allExactMatchAddresses[0]);
                    }
                    const results = await showAddressModal(response, addressDTO);
                    if (results) {
                        const addressReturned = results.addressToUse;
                        if (addressReturned) {
                            return createNewAddressVM(addressReturned);
                        }
                    }
                    return unstandardizedAddressVM;
                } catch (error) {

                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: commonMessages.addressStandardizationErrorTitle,
                        message: error.baseError
                    });
                }
            }
            return undefined;
        },
        [authHeader, showAddressModal, viewModelService]
    );

    /**
     * Standardize the address if entered address is valid and if there is a change in the address
     * @param {Object} oldAddress old address object value
     * @param {Object} addressVM new addressVM object
     * @param {Function} onAddressChange callback function which will get called when address is standardized
     * @param {String} addressPath path of address object, which will be used in callback function to update standardized address
     *
     * @returns {Boolean} returns true/false based on address is standardized or not
     */
    const standardizeAddressIfApplicable = useCallback(async (oldAddress, addressVM, onAddressChange, addressPath) => {
        const isSameAddress = AddressUtil.isSameAddress(oldAddress, _get(addressVM, 'value'));
        // No need to standardize the address as there is no change in the address
        if (isSameAddress) {
            return true;
        }
        // need to check all the required fields, otherwise tab out will not work.
        const addressLine1 = _get(addressVM, 'addressLine1.value', _get(addressVM, 'address1.value'));
        const city = _get(addressVM, 'city.value');
        const state = _get(addressVM, 'state.value');
        const postalCode = _get(addressVM, 'postalCode.value');
        // For EU Address we have zipCode instead of postalCode
        const zipCode = _get(addressVM, 'zipCode.value');
        const zipCodeValidation = new RegExp('^[0-9]{5}(-[0-9]{4})?$');
        if (!(addressLine1 && city && state && (zipCodeValidation.test(postalCode) || zipCodeValidation.test(zipCode)))) {
            return false;
        }

        // check addressLine1 and addressLine2 for special characters
        const isAddressLine1Valid = AddressUtil.validateSpecialCharacters(addressLine1);
        const isAddressLine2Valid = AddressUtil.validateSpecialCharacters(_get(addressVM, 'addressLine2.value', _get(addressVM, 'address2.value')));
        if (!isAddressLine1Valid || !isAddressLine2Valid) {
            return false;
        }

        const standardizedAddress = await getStandardizedAddress(addressVM);
        if (standardizedAddress) {
            onAddressChange(standardizedAddress.value, addressPath);
            return true;
        }
        return false;
    }, [getStandardizedAddress]);


    return { getStandardizedAddress, standardizeAddressIfApplicable };
}

export default useAddressStandardization;
