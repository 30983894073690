import { defineMessages } from 'react-intl';

export default defineMessages({
    applyingChangesMessageRewrite: {
        id: 'rewrite.e1p.views.payment-details.Applying changes...',
        defaultMessage: 'Applying changes...'
    },
    completingYourPurchaseMessageRewrite: {
        id: 'rewrite.e1p.views.payment-details.Completing your purchase...',
        defaultMessage: 'Completing your purchase...'
    },
    loadingPaymentsRewrite: {
        id: 'rewrite.e1p.views.payment-details.Please wait while we communicate with the payments system...',
        defaultMessage: 'Please wait while we communicate with the payments system...'
    },
    issueLabelRewrite: {
        id: 'rewrite.e1p.ea.views.payment-details.issueLabel',
        defaultMessage: 'Issue'
    }
});
