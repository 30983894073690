import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import {
    set, get, some, isEmpty,
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useLandingPageUtil } from 'e1p-capability-hooks';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { PrefillService } from 'e1p-capability-quoteandbind';
import { CreditService } from 'e1p-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { commonMessages as e1pCommonMessages, ehCommonMessages } from 'e1p-platform-translations';
import { EndorsementService } from 'e1p-capability-policychange';
import metadata from './PropertyPage.metadata.json5';
import styles from './PropertyPage.module.scss';

const LOB = 'homeowners_EH';

function PropertyPage(props) {
    const translator = useTranslator();
    // eslint-disable-max-len
    const { authHeader } = useAuthentication();
    const {
        wizardData: policyChangeVM, updateWizardData, steps, jumpTo, updateWizardSnapshot
    } = props;

    const [isPageInitialized, setPageInitialized] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSubmittedWithoutRc, updateIsSubmittedWithoutRc] = useState(false);
    const [isReplacementCostCalculated, setIsReplacementCostCalculated] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [heatingSystemType, updateHeatingSystemType] = useState(undefined);
    const [coolingSystemType, updateCoolingSystemType] = useState(undefined);
    const [creditScoreMoreThan597, updateCreditScoreMoreThan597] = useState(false);
    const [creditReportLoaded, setCreditReportLoaded] = useState(true);
    const [isRecalculating, setIsRecalculating] = useState(false);
    const [isOutstandingChangesPresent, setIsOutstandingChangesPresent] = useState(true);


    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerComponentValidation
    } = useValidation('PropertyPage');

    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    useEffect(() => {
        registerComponentValidation(() => (heatingSystemType !== undefined
                && coolingSystemType !== undefined))
    }, [coolingSystemType, heatingSystemType, registerComponentValidation]);

    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                setIsOutstandingChangesPresent(true);
                window.scrollTo(0, 0);

                return false;
            }

            // Set a different message for replacement cost
            if (isReplacementCostCalculated && !calledFromOnSave) {
                setIsOutstandingChangesPresent(true);
                updateIsSubmittedWithoutRc(true);
                // don't need to show this message
                updateIsPageSubmitted(false);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            let serverCall = EndorsementService.saveAndQuoteEndorsement;

            if(calledFromOnSave && isReplacementCostCalculated){
                serverCall = EndorsementService.saveEndorsement
                updateIsPageSubmitted(false);
            }

            const saveResponse = await serverCall(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', saveResponse);
            updateWizardData(policyChangeVM);
            setIsSavingEndorsement(false);

            return policyChangeVM;
        },
        [authHeader, isComponentValid, isReplacementCostCalculated, policyChangeVM, updateWizardData]
    );


    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, policyChangeVM, updateWizardSnapshot]
    );

    const getCreditData = (async () => {
        setCreditReportLoaded(false);
        CreditService.getCreditReport(
            get(policyChangeVM, 'jobID.value'),
            authHeader
        ).then((response) => {
            const reportWithoutNoHit = response.creditRecords?.find((creditRecord) => creditRecord.reportStatus !== 'NOHIT');

            if (reportWithoutNoHit !== undefined) {
                updateCreditScoreMoreThan597(reportWithoutNoHit.creditScore >= 597);
            }

            setCreditReportLoaded(true);
        }).catch((exception) => {
            if (policyChangeVM.value.baseData.exceptions_Ext) {
                policyChangeVM.value.baseData.exceptions_Ext.push(
                    { errorMessage: exception.baseError ? exception.baseError : exception.message }
                );
            } else {
                set(
                    policyChangeVM.value.baseData,
                    `exceptions_Ext[${0}]`,
                    { errorMessage: exception.baseError ? exception.baseError : exception.message }
                );
            }

            updateWizardData(policyChangeVM);
            updateCreditScoreMoreThan597(false);
        }).finally(() => {
            setCreditReportLoaded(true);
        });
    });

    useEffect(() => {
        if (isEmpty(policyChangeVM.lobData.homeowners_EH.coverables.yourHome.value)) {
            policyChangeVM.lobData.homeowners_EH.coverables.yourHome = {};
        }

        if (isEmpty(policyChangeVM.lobData.homeowners_EH.coverables.construction.value)) {
            policyChangeVM.lobData.homeowners_EH.coverables.construction = {};
        }

        getCreditData();

        const heatingSystems = get(policyChangeVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            updateHeatingSystemType(heatingSystems[0].heatingType);
        }

        const coolingSystems = get(policyChangeVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');

        if (coolingSystems !== undefined && coolingSystems.length > 0) {
            updateCoolingSystemType(coolingSystems[0].coolingType);
        }

        updateWizardData(policyChangeVM);
        setPageInitialized(true);

        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    

    // Logic for enabling and disabling the recalculate button
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const requiredFieldsForReplacementCost = [
        'yearBuilt',
        'numberOfStories',
        'totalSquareFeet',
        'exteriorWallFinish',
        'roofType',
        'roofShape',
        'garages', // has children
        'slopeType',
        'numberOfRoomsWithCathedralVaultedCeilings',
        'numberOfRoomsWithCrownMolding',
        'ceilingHeightType',
        'numberOfFullBaths',
        'numberOfHalfBaths',
        'kitchenCounterTopType',
        'foundationType',
        'basementFinishedAreaType',
        'heatingSystems',
        'coolingSystems',
        'insideWallMaterials', // has children
        'floors', // has children
    ];
    // /**
    //  * isRecalculateReplacementCostDisable, will save Boolean value, which will be used to enable or disable
    //  * the recalculate ReplacementCost button on UI, if any of the value in requiredFieldsForReplacementCost array
    //  * is undefined or empty, then it will returns true, else false.
    //  */
    const isRecalculateReplacementCostDisable = (pcVM) => {
        const isDisabled = some(requiredFieldsForReplacementCost, (requiredField) => {
            const requiredFieldValue = get(pcVM.lobData.homeowners_EH.coverables.construction, `${requiredField}.value`);

            // requiredFieldValue could be string, integer, or array.
            if (Array.isArray(requiredFieldValue)) {
                return requiredFieldValue.length === 0;
            }

            return requiredFieldValue === undefined || requiredFieldValue === '';
        });

        return isDisabled || !isComponentValid;
         
    };

    const isRecalculateRequired = isReplacementCostCalculated && !isRecalculateReplacementCostDisable(policyChangeVM) && !isRecalculating;

    const recalculateReplacementCost = useCallback(
        async () => {
            if (isRecalculateRequired) {
                setIsRecalculating(true);

                const response = await PrefillService.orderReplacementCost(
                    policyChangeVM.jobID.value,
                    policyChangeVM.lobData.value,
                    authHeader
                );
                 
                if (response?.homeowners_EH?.exceptions_Ext) {
                     
                    policyChangeVM.baseData.exceptions_Ext = response?.homeowners_EH?.exceptions_Ext;
                    updateWizardData(policyChangeVM);
                    setIsRecalculating(false);
                } else {
                    setIsReplacementCostCalculated(false);
                    setIsOutstandingChangesPresent(true);
                    // E1PAP1PC-15700 : save all data till this point
                    set(
                        policyChangeVM.lobData.homeowners_EH.coverables,
                        'value',
                        response.homeowners_EH.coverables
                    );
                    set(policyChangeVM, 'value.flowStepIDs_Ext', ['property']);
                    EndorsementService.saveEndorsement(
                        [policyChangeVM.value],
                        authHeader
                    ).then((updateDraftResp) => {
                        set(policyChangeVM, 'value', updateDraftResp);
                        updateWizardData(policyChangeVM);
                        updateWizardSnapshot(policyChangeVM);
                    }).finally(() => {
                        setIsRecalculating(false);
                    });
                }
            } else {
                setIsOutstandingChangesPresent(false);
            }
        }, [isRecalculateRequired, policyChangeVM, authHeader, updateWizardData, updateWizardSnapshot]
    );

    const resolvers = {
        resolveCallbackMap: {
            recalculateReplacementCostClick: recalculateReplacementCost,
            onValidate,
        },
        resolveClassNameMap: styles
    };

    const onFormValueChange = useCallback(
        (newValue, path) => {
            const deconstructedPath = path.split('.');
            const isRcField = requiredFieldsForReplacementCost
                .concat(['firePlaces', 'styleOfHome']) // optional but does effect RC
                .some(
                    (field) => deconstructedPath.includes(field)
                );

            if (!isReplacementCostCalculated && isRcField) {
                set(
                    policyChangeVM,
                    'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                    undefined
                );
                setIsReplacementCostCalculated(true);
            }

            set(policyChangeVM, path, newValue);
            updateWizardData(policyChangeVM);
        },
        [isReplacementCostCalculated, policyChangeVM, requiredFieldsForReplacementCost, updateWizardData]
    );
    


    /**
    * Helper memo for dynamically generating the loading indicator message.
    */
    const getLoadingIndicatorMessage = useMemo(
        () => {
            let loadingMessage = '';

            if (isSavingCurrentPageChanges) {
                loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
            } else if (!isPageInitialized || isSavingEndorsement) {
                loadingMessage = translator(ehCommonMessages.loadingNextPageMessage);
            } else if (isRecalculating) {
                loadingMessage = translator(ehCommonMessages.recalculatingReplacementCostAndSavingAllInfo);
            }

            return loadingMessage;
        },
        [
            isSavingCurrentPageChanges, isPageInitialized,
            isSavingEndorsement, translator, isRecalculating
        ]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => creditReportLoaded && !isSavingEndorsement
        && !isSavingCurrentPageChanges && !isRecalculating,
        [creditReportLoaded, isRecalculating, isSavingCurrentPageChanges, isSavingEndorsement]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            disabled: isRecalculating,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        replacementCostMissingNotificationDiv: {
            visible: isSubmittedWithoutRc
        },
        propertyPageLoader: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        propertyPageMainDiv: {
            visible: isPageLoaded
        },
        noOutstandingChangesNotificationDiv: {
            visible: !isOutstandingChangesPresent
        },
        replacementCostRecalculate: {
            disabled: isRecalculateReplacementCostDisable(policyChangeVM)
                || isRecalculating
        },
        replacementCostWithDollarrid: {
            content: (() => {
                if (isRecalculating) {
                    return [
                        {
                            id: 'InlineLoader',
                            type: 'element',
                            component: 'InlineLoader',
                            componentProps: {
                                loading: true,
                                size: 'medium',
                                loadingMessage: '',
                                successMessage: ''
                            }
                        }
                    ];
                }

 return [
                    {
                        id: 'replacementCost',
                        component: 'Currency',
                        type: 'field',
                        componentProps: {
                            readOnly: true,
                            className: 'replacementCost',
                            path: 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount',
                            label: {
                                id: '',
                                defaultMessage: ''
                            },
                            layout: 'reversed',
                            dataType: 'number'
                        }
                    }
                ];
            })()
        },
        e1pEHHF9PropertyDetailsComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            isPageSubmitted,
            heatingSystemType,
            updateHeatingSystemType,
            coolingSystemType,
            updateCoolingSystemType,
            setIsReplacementCostStale:(value) => {setIsReplacementCostCalculated(value);},
            creditScoreMoreThan597
        },
    };


    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    const saveAndQuote = useCallback(
        async () => {
            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteResponse);
            updateWizardData(policyChangeVM);

            return policyChangeVM.value;
        },
        [authHeader, policyChangeVM, updateWizardData]
    );

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid
                || heatingSystemType === undefined
                || coolingSystemType === undefined
            ) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            // Set a different message for replacement cost
            if (isReplacementCostCalculated) {
                updateIsSubmittedWithoutRc(true);
                // don't need to show this message
                updateIsPageSubmitted(false);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);
            policyChangeVM.value = await saveAndQuote();
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [
            isComponentValid, heatingSystemType, coolingSystemType,
            isReplacementCostCalculated,policyChangeVM, saveAndQuote, updateWizardData, 
            updateWizardSnapshot, getLandingPageIndexForQuotedJob, steps, jumpTo
        ]
    );

    if (!isPageInitialized) {
        return null;
    }


    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            showCustom
            onCustom={onCustom}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && (!isComponentValid
                    || heatingSystemType === undefined
                    || coolingSystemType === undefined
                    || isReplacementCostCalculated)
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onValueChange={onFormValueChange}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
