/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { CurrencyValue } from '@jutro/components';
import { Grid, GridItem } from '@jutro/layout';
import { IntlContext } from '@jutro/locale';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import styles from './EAClauseComponent.module.scss';

import { CheckboxField } from '@jutro/legacy/components';

class ClauseComponent extends Component {
    /**
     * @memberof gw-components-platform-react.ClauseComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.displayName - name of clause to display
     * @prop {bool} propTypes.readOnly - if the clause selection is read only
     * @prop {bool} propTypes.value - is the clause is seleceted
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {bool} propTypes.isLoading - should the clause be loading
     * @prop {string} propTypes.loadingMessage - message to be shown while loading
     * @prop {string} propTypes.containerClassName - clause container class
     */

    static propTypes = {
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        readOnly: PropTypes.bool,
        isVisible: PropTypes.bool,
        onValueChange: PropTypes.func.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape({})),
        value: PropTypes.bool,
        path: PropTypes.string,
        isLoading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        checked: PropTypes.bool,
        isEditable: PropTypes.bool,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        containerClassName: PropTypes.string,
        labelPosition: PropTypes.string,
        amount: PropTypes.shape({}),
        isQuoteStale: PropTypes.bool,
        maxNumOfTermsForRow: PropTypes.number,
        coverageCategoryCode: PropTypes.string,
        isCompOnlyVehicle: PropTypes.bool
    }

    static defaultProps = {
        readOnly: false,
        isVisible: true,
        path: undefined,
        value: undefined,
        isLoading: false,
        loadingMessage: '',
        checked: false,
        children: undefined,
        isEditable: true,
        description: undefined,
        containerClassName: undefined,
        labelPosition: 'left',
        amount: undefined,
        isQuoteStale: false,
        maxNumOfTermsForRow: undefined,
        coverageCategoryCode: undefined,
        isCompOnlyVehicle: false
    }

    static contextType = IntlContext;

    handleChange = (value) => {
        const { onValueChange, path } = this.props;

        if (onValueChange) {
            onValueChange(value, path);
        }
    }

    getCoveragePremiumAmount = (premiumAmount) => {
        const { isQuoteStale } = this.props;

        if (!_.isUndefined(premiumAmount) && !isQuoteStale) {
            const intl = this.context;
            const formatterCurrency = intl.formatNumber(
                premiumAmount.amount,
                {
                    style: 'currency',
                    currency: premiumAmount.currency,
                    currencyDisplay: 'symbol'
                }
            );

            return `${formatterCurrency}`;
        }

        return '';
    }

    renderEditableValue() {
        const {
            displayName,
            readOnly,
            value,
            id,
            isLoading,
            loadingMessage,
            children,
            path,
            checked,
            description,
            containerClassName,
            labelPosition,
            amount,
            maxNumOfTermsForRow,
            coverageCategoryCode,
            isVisible,
            threeLineLabel,
            twoThreeLineLabel,
            oneLineLabel,
            isCompOnlyVehicle
        } = this.props;

        const hasChildren = !_.isNil(children);
        const cellHeightStyle = (() => {
            // Each vehicle could be checked or unchecked
            // if any vehicles have a cov checked, all boxes need to grow
            // for the weird/psuedo table ui
            switch (maxNumOfTermsForRow) {
                case undefined:
                    return undefined;
                case 1:
                    // cell becomes longer because word break
                    // decreasing the padding here
                    if (displayName.length > 45) {
                        return styles.oneTermThreeRows;
                    }

                    if (displayName.length > 25) {
                        return styles.oneTermTwoRows;
                    }

                    return styles.oneTermOneRow;
                case 2:
                    if (twoThreeLineLabel) {
                        return styles.twoTermSixRow;
                    }

                    // two term clause with two or three line label names
                    if (threeLineLabel) {
                        return styles.twoTermFourRow;
                    }

                    // two term clause with one line label names
                    if (oneLineLabel) {
                        return styles.twoTermOneLine;
                    }

                    if (displayName.length > 45) {
                        return styles.length.twoTermThreeRow;
                    }

 if (displayName.length > 25) {
                        return styles.twoTermTwoRow;
                    }

                    return styles.twoTermOneRow;
                default:
                    // assuming 0 terms (cov not selected)
                    if (displayName.length > 45) {
                        return styles.noTermThreeRow;
                    }

 if (displayName.length > 25) {
                        return styles.noTermTwoRow;
                    } // base css class is for 0 terms

                    // and cov label fits on 1 line
                    return undefined;
            }
        })();
        const clauseContainerStyles = classNames(
            styles.clause, containerClassName, cellHeightStyle
        );
        const checkboxControlStyles = classNames({
            [styles.clauseAndTermSpacing]: hasChildren
        });
        const checkboxStyles = classNames({
            [styles.clauseNoPadding]: !hasChildren
        });
        const gridColumns = ['0.30fr', '2fr', '0.85fr'];

        // if isCompOnlyVehicle is true, then we will not show coverages section on the UI
        if (isCompOnlyVehicle) {
            return (
                <div className={clauseContainerStyles}>
                    {isLoading
                        ? (
                            <E1PLoader
                                loaded={!isLoading}
                                text={loadingMessage}
                            />
                        ) : (<span />)
                    }
                </div>
            );
        }

        return (
            <div className={clauseContainerStyles}>
                {isLoading
                    ? (
                        <E1PLoader
                            loaded={!isLoading}
                            text={loadingMessage}
                        />
                    ) : (
                        <Grid
                                columns={gridColumns}
                                gap="none"
                                id="Grid"
                                rows={[
                                    '1fr',
                                ]}
                                tag="div"
                            >
                                <GridItem
                                    className="jut__Containers__highlightHover"
                                    tag="div"
                                >
                                    <CheckboxField
                                        id={id}
                                        label={null}
                                        value={value}
                                        readOnly={readOnly}
                                        visible={isVisible}
                                        onValueChange={this.handleChange}
                                        className={checkboxStyles}
                                        controlClassName={checkboxControlStyles}
                                        path={path}
                                        checked={checked}
                                        labelPosition={labelPosition}
                                        showInlineLabel={false}
                                        layout="full-width"
                                    />
                                </GridItem>
                                <GridItem
                                    className="jut__Containers__highlightHover"
                                    tag="div"
                                >
                                    {value && children ? children
                                        : <div className={styles.emptyDiv} />}
                                </GridItem>
                                <GridItem
                                    className={styles.eaPremiumJustifyContent}
                                    tag="div"
                                >
                                    {this.getCoveragePremiumAmount(amount)}
                                </GridItem>
                            </Grid>
                    )
                }
            </div>
        );
    }

    renderReadOnlyValue() {
        const {
            children, amount
        } = this.props;

        return (
            <div className={styles.readOnlyGrid}>
                <span className={styles.readOnlyGridSlotLeft}>
                    <span className={styles.readOnlyGridAmount}>
                        {!_.isUndefined(amount) ? (
                            <CurrencyValue
                                amount={amount.amount}
                                currency={amount.currency}
                                showFractions
                            />
                        ) : undefined
                        }
                    </span>
                </span>
                <div className={styles.readOnlyGridSlotRight}>
                    {children}
                </div>
            </div>
        );
    }

    render() {
        const { isEditable } = this.props;

        return isEditable ? this.renderEditableValue() : this.renderReadOnlyValue();
    }
}

export default ClauseComponent;
