import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import styles from './ActivityAdvancedFilter.module.scss';
import { CheckboxField } from '@jutro/legacy/components';

const FilterOptions = (props) => {
    const { filters, onValueChange, filterDisplayKey } = props;
    const writeValue = (value, path) => {
        filters.options[path].selected = value;

        if (onValueChange) {
            onValueChange(filters);
        }
    };
    const translator = useTranslator();

    return (
        <Fragment key= {filterDisplayKey}>
            {filters.options.map((option, index) => {
                const fieldId = option.displayKey.id || option.displayKey;

                return (
                    <div className={styles.gwFilterOption} key={fieldId}>
                        <CheckboxField
                            id={fieldId}
                            value={option.selected}
                            path={index.toString()}
                            showInlineLabel
                            label={translator(option.displayKey)}
                            showOptional={false}
                            onValueChange={writeValue}
                            labelClassName={styles.gwActivityFilterOptionsLabel}
                        />
                    </div>
                );
            })}
        </Fragment>
    );
};

FilterOptions.propTypes = {
    filters: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    onValueChange: PropTypes.func.isRequired
};
export default FilterOptions;
