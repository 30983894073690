import { defineMessages } from 'react-intl';

export default defineMessages({
    feesAndChargesTitle: {
        id: 'FeesAndOtherCharges.Fees and Other Charges',
        defaultMessage: 'Fees and Other Charges'
    },
    paymentDueDate: {
        id: 'FeesAndOtherCharges.If a payment is not received by the due date, a fee of $10.00 will be charged.',
        defaultMessage: 'If a payment is not received by the due date, a fee of $10.00 will be charged.'
    },
    returnPayment: {
        id: 'FeesAndOtherCharges.If a payment is returned unpaid by a financial institution, a returned bank item fee of $25.00 will be charged.',
        defaultMessage: 'If a payment is returned unpaid by a financial institution, a returned bank item fee of $25.00 will be charged.'
    },
    autoPayments: {
        id: 'FeesAndOtherCharges.If you do not select automatic payments using a checking or savings account, a $7.00 installment fee will apply.',
        defaultMessage: 'If you do not select automatic payments using a checking or savings account, a $7.00 installment fee will apply.'
    }
});
