
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    filter as _filter,
    get as _get,
    indexOf as _indexOf,
    isEmpty as _isEmpty,
    set as _set
} from 'lodash';
import moment from 'moment';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage } from 'e1p-portals-wizard-react';
import { TranslatorContext } from '@jutro/locale';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { RandomNumberUtil } from 'e1p-portals-util-js';
import { CancellationService } from 'e1p-capability-gateway';
import metadata from './StartCancellationPage.metadata.json5';
import styles from './StartCancellationPage.module.scss';
import messages from './StartCancellationPage.messages';

const productToLineMap = new Map([
    ['PersonalAuto_EA', 'EAAutoLine_Ext'],
    ['Homeowners_EH', 'EHLine_Ext'],
    ['PersonalUmbrella_EU', 'EULine_Ext']
]);

class StartCancellationPage extends Component {
    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        onDoNotCancel: PropTypes.func.isRequired,
        policyNumber: PropTypes.string.isRequired,
        policyData: PropTypes.shape({
            policyNumber: PropTypes.string.isRequired
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        authUserData: PropTypes.shape({
            userType: PropTypes.string.isRequired
        }).isRequired,
    }

    static contextType = TranslatorContext;

    state = {
        cancellationSubmissionVM: {},
        enableRefundMethod: true,
        refundMethodsData: [],
        startCancellationTag: true,
        validationErrorMessage: '',
        showError: false,
        reasonCodes: [],
        options: [],
        selectedCheckboxValues: [],
        showCheckboxes: false,
        showOtherCarrier: false,
        showOtherInsuranceContainer: false,
        coverageOption: [],
        disableReason: true,
        showUWSection: false,
        isProcessingCancellation: false,
        isAbinito: false,
        isRewriteSpecific: false,
        sourceValues: [],
        isPageSubmitted: false,
        loadingEffectivedate: false
    };

    componentDidMount() {
        let { cancellationSubmissionVM } = this.state;
        const { wizardData: { policyData }, authUserData } = this.props;
        const hasChangeSource1 = authUserData?.permissions_Ext.includes('chgsource1_ext');
        const hasCompanyCancellationPerm = authUserData.permissions_Ext.includes('companycancellation_ext');
        const translator = this.context;
        const model = {
            policy: {}
        };

        cancellationSubmissionVM = this.createVM(model);

        const sourceValue = cancellationSubmissionVM.cancellationSource.aspects.availableValues.map((item) => {
            if (item.code === 'carrier') {
                return {
                    code: item.code,
                    name: translator({
                        id: 'Company',
                        defaultMessage: 'Company'
                    })
                };
            }

            return {
                code: item.code,
                name: translator({
                    id: item.name,
                    defaultMessage: item.name
                })
            };
        });

        if (hasCompanyCancellationPerm) {
            this.setState({ sourceValues: sourceValue });
        } else if (hasChangeSource1) {
            const sourceOptions = sourceValue.filter((data) => data.code !== 'carrier');
            const value = sourceOptions[0]?.code;

            // Default the value of Source dropdown, incase if there is only 1 option available.
            if (sourceOptions?.length === 1) {
                _set(cancellationSubmissionVM, 'cancellationSource.value', value);
                this.setState({ disableReason: false });
            }

            this.setState({ sourceValues: sourceOptions });
        }

        const earliestCancellationDate = new Date(_get(policyData, 'latestPeriod.effectiveDate.value'));

        earliestCancellationDate.setDate(earliestCancellationDate.getDate() + 1);
        earliestCancellationDate.setHours(0, 0, 0, 0);
        _set(cancellationSubmissionVM, 'earliestCancellationDate', earliestCancellationDate);
        _set(cancellationSubmissionVM, 'actualExpirationDate', _get(policyData, 'latestPeriod.expirationDate'));

        if (_get(cancellationSubmissionVM, 'additionalCancelReasonDesc_Ext.value')) {
            this.setState({ selectedCheckboxValues: cancellationSubmissionVM.additionalCancelReasonDesc_Ext.value });
        }

        this.setState({
            cancellationSubmissionVM
        });

        this.setReasonDrpDwnValue(cancellationSubmissionVM);
    };


    checkCarrierField = () => {
        const { cancellationSubmissionVM, selectedCheckboxValues } = this.state;
        const { updateWizardData, wizardData } = this.props;
        const otherCarrierField = _get(cancellationSubmissionVM, 'otherCarrierName_Ext.value');

        updateWizardData(wizardData);

        if (!!otherCarrierField && !!selectedCheckboxValues[0]) {
            this.setState({ startCancellationTag: false});
        } else {
            this.setState({ startCancellationTag: true });
        }
    }

    setReasonDrpDwnValue = (cancellationSubmissionVM) => {
        const translator = this.context;
        const { wizardData, e1pGoNext } = this.props;
        const { policyData } = wizardData;
        const { submissionVM } = wizardData;
        const productLine = productToLineMap.get(policyData.product.productCode.value);
        const cancellationReasonsByLine = cancellationSubmissionVM.cancelReasonCode.aspects
            .availableValues[0].typelist.getFilter(productLine).codes;
        const cancellationReasons = cancellationReasonsByLine.filter((cancellationReason) => (
            cancellationReason.belongsToCategory({
                code: cancellationSubmissionVM.cancellationSource.value?.code,
                typelist: { name: 'CancellationSource' }
            })));

        this.setState({
            reasonCodes: cancellationReasons.map((cancellationReason) => ({
                    code: cancellationReason.code,
                    name: translator({
                        id: cancellationReason.name,
                        defaultMessage: cancellationReason.name
                    })
                }))
        });

        if (submissionVM.value.jobNumber) {
            const vm = this.createVM(wizardData.submissionVM.value);

            _set(vm, 'cancellationSource', 'insured');
            this.setState({
                cancellationSubmissionVM: vm,
                startCancellationTag: false
            });
            e1pGoNext();
        }
    }

    updateCancellationEffectiveDate = () => {
        const {
            cancellationSubmissionVM
        } = this.state;

        this.writeValue(
            cancellationSubmissionVM.effectiveDate, 'effectiveDate'
        );
    }

    otherInsuranceContainerHandler = (value) => {
        const { wizardData, updateWizardData } = this.props;
        const { cancellationSubmissionVM } = this.state;
        const { policyData } = wizardData;
        const translator = this.context;
        const sourceValue = _get(cancellationSubmissionVM, 'cancellationSource.value.code');

        if (value === 'abInitio_ext') {
            this.setState({ isAbinito: true, isRewriteSpecific: false });

            const selectedPolicy = policyData.periods.value.filter(
                (item) => item.jobType_Ext === 'Submission'
            );

            const effectiveDate = moment(selectedPolicy[0].effectiveDate);

            _set(
                cancellationSubmissionVM,
                'uwactionDTO_Ext.pendedCancelDate',
                effectiveDate
            );
        } else if (value === 'rewritencspecific_ext'){
            this.setState({ isAbinito: false, isRewriteSpecific: true });
            _set(
                cancellationSubmissionVM,
                'uwactionDTO_Ext.pendedCancelDate',
                undefined
            );
        }else {
            this.setState({ isAbinito: false, isRewriteSpecific: false });
            _set(
                cancellationSubmissionVM,
                'uwactionDTO_Ext.pendedCancelDate',
                undefined
            );
        }

        _set(cancellationSubmissionVM, 'cancelReasonCode.value', value);
        updateWizardData(wizardData);
        this.getEffectiveDate();

        if (value === 'otherinsurance_ext' && sourceValue === 'insured') {
            const additionalReasonCheckboxValue = cancellationSubmissionVM.primaryCancelReasonDesc_Ext.aspects.availableValues
                .map((item) => ({
                        code: item.code,
                        name: translator({
                            id: item.name,
                            defaultMessage: item.name,
                        }),
                    }));

            this.setState({ startCancellationTag: true });
            this.setState({ options: additionalReasonCheckboxValue });
            this.setState({ showOtherInsuranceContainer: true });
        } else {
            _set(cancellationSubmissionVM, 'primaryCancelReasonDesc_Ext.value', undefined);
            _set(cancellationSubmissionVM, 'additionalCancelReasonDesc_Ext.value', []);
            _set(cancellationSubmissionVM, 'policyCarrierName_Ext.value', undefined);
            _set(cancellationSubmissionVM, 'otherCarrierName_Ext.value', '');
            this.setState({
                showOtherInsuranceContainer: false, selectedCheckboxValues: [], showCheckboxes: false, showOtherCarrier: false
            });
            this.setState({ startCancellationTag: false });
        }
    };

    onChangeOfSourceDrpDwn = async (value) => {
        const translator = this.context;
        const { cancellationSubmissionVM } = this.state;
        const {
            wizardData,
            updateWizardData,
            authHeader,
            authUserData
        } = this.props;
        const { policyData } = wizardData;
        const { policyNumber } = policyData;
        const policyType = policyData.product.productCode.value.slice(-2);
        const sessionIdPrefixEdit = `${policyType} - CN - `;

        authHeader['afe-session-id'] = sessionIdPrefixEdit + RandomNumberUtil.randomFixedInteger(10);
        _set(cancellationSubmissionVM, 'cancellationSource.value', value);
        this.setState({ disableReason: false });

        if (value === 'carrier') {
            const reasonValue = cancellationSubmissionVM.cancelReasonCode.aspects.availableValues.filter(
                (data) => data.code === 'uwreasons' || data.code === 'abInitio_ext' || data.code === 'rewritencspecific_ext'
            ).map(
                (data) => ({
                        code: data.code,
                        name: translator({
                            id: data.name,
                            defaultMessage: data.name
                        }),
                    })
            );

            this.setState({ reasonCodes: reasonValue });

            if (!authUserData.permissions_Ext.includes('cancelabinitio_ext')) {
                this.setState({
                    reasonCodes: reasonValue.filter(
                        (data) => data.code !== 'abInitio_ext'
                    )
                });
            }

            if (!authUserData.permissions_Ext.includes('canceluwreasons_ext')) {
                this.setState({
                    reasonCodes: reasonValue.filter(
                        (data) => data.code !== 'uwreasons'
                    )
                });
            }

            if(policyData.product.productCode.value === 'PersonalUmbrella_EU' || !authUserData.permissions_Ext.includes('cancelrewritencspecific_ext') || policyData.latestPeriod.baseState_Ext.value.code !== 'NC'){
                this.setState({
                    reasonCodes: reasonValue.filter(
                        (data) => data.code !== 'rewritencspecific_ext'
                    )
                });
            }

            this.setState({ showUWSection: true });
            _set(cancellationSubmissionVM, 'cancelReasonCode.value', 'uwreasons');
            this.otherInsuranceContainerHandler('uwreasons');
            await CancellationService.loadCancellationJobInfo(policyNumber.value, authHeader)
                .then((response) => {
                    // IAP-4115, letterMailingDate_Ext was not getting sent during startCancellation call
                    if( _get(response, 'letterMailingDate_Ext')) {
                        _set(cancellationSubmissionVM, 'value.letterMailingDate_Ext', response.letterMailingDate_Ext);    
                    }

                    _set(cancellationSubmissionVM, 'uwactionDTO_Ext.value', response.uwactionDTO_Ext);
                    this.setState({
                        coverageOption: cancellationSubmissionVM.uwactionDTO_Ext.alternateCoverageReferral.aspects.availableValues.map((item) => ({
                                code: item.code,
                                name: translator({
                                    id: item.name,
                                    defaultMessage: item.name,
                                }),
                            }))
                    });
                });
        } else {
            this.setReasonDrpDwnValue(cancellationSubmissionVM);
            this.setState({ showUWSection: false });
        }

        updateWizardData(wizardData);
    }

    onChangeOfEffectiveDate = async () => {
        const { cancellationSubmissionVM } = this.state;
        const { authHeader, wizardData, updateWizardData } = this.props;
        const { policyData } = wizardData;

        _set(cancellationSubmissionVM, 'effectiveDate.value', cancellationSubmissionVM.uwactionDTO_Ext.pendedCancelDate.value);
        _set(cancellationSubmissionVM, 'isTempCancellation', false);
        _set(cancellationSubmissionVM, 'cancellationRefundMethod', 'prorata');
        _set(cancellationSubmissionVM, 'policy.policyNumber.value', policyData.value.policyNumber);
        _set(cancellationSubmissionVM, 'value.letterMailingDate_Ext', _get(cancellationSubmissionVM, 'value.uwactionDTO_Ext.letterMailingDate'));
 
        const policyType = policyData.product.productCode.value.slice(-2);
        const sessionIdPrefixEdit = `${policyType} - CN - `;

        authHeader['afe-session-id'] = sessionIdPrefixEdit + _get(cancellationSubmissionVM, 'jobNumber.value');
        await CancellationService.onChangeOfLetterMailingDate(cancellationSubmissionVM.value, authHeader)
            .then((response) => {
                // IAP-4115, letterMailingDate_Ext was not getting sent during startCancellation call
                if( _get(response, 'letterMailingDate_Ext')) {
                    _set(cancellationSubmissionVM, 'value.letterMailingDate_Ext', response.letterMailingDate_Ext);    
                }

                _set(cancellationSubmissionVM, 'uwactionDTO_Ext.letterMailingDate.value', response.uwactionDTO_Ext.letterMailingDate);
                _set(cancellationSubmissionVM, 'uwactionDTO_Ext.pendedCancelDate.value', response.uwactionDTO_Ext.pendedCancelDate);
                _set(cancellationSubmissionVM, 'uwactionDTO_Ext.freePeriodEndDate.value', response.uwactionDTO_Ext.freePeriodEndDate);
                updateWizardData(wizardData);
            });
    }

    otherInsuranceCheckHandler = (value) => {
        const { wizardData, updateWizardData } = this.props;
        const { cancellationSubmissionVM, selectedCheckboxValues } = this.state;

        _set(cancellationSubmissionVM, 'policyCarrierName_Ext.value', value);
        updateWizardData(wizardData);

        if (value === 'other') {
            this.setState({ showOtherCarrier: true });
            this.setState({ startCancellationTag: true });
        } else if (!!selectedCheckboxValues[0] && value !== 'other') {
            _set(cancellationSubmissionVM, 'otherCarrierName_Ext.value', '');
            this.setState({ showOtherCarrier: false, startCancellationTag: false });
        }
    };

    additionalReasonCheckboxHandler = (value) => {
        const { wizardData, updateWizardData } = this.props;
        const { cancellationSubmissionVM, selectedCheckboxValues } = this.state;
        const translator = this.context;

        _set(cancellationSubmissionVM, 'primaryCancelReasonDesc_Ext.value', value);
        updateWizardData(wizardData);

        if (selectedCheckboxValues[0]) {
            const selectedReasonCheckbox = selectedCheckboxValues.filter((item) => item !== value);

            this.setState({ selectedCheckboxValues: selectedReasonCheckbox });
        }

        const additionalReasonCheckboxValue = cancellationSubmissionVM.primaryCancelReasonDesc_Ext.aspects.availableValues
            .filter((item) => item.code !== value).map((item) => ({
                    code: item.code,
                    name: translator({
                        id: item.name,
                        defaultMessage: item.name,
                    }),
                }));

        this.setState({ options: additionalReasonCheckboxValue, showCheckboxes: !!value });
    };

    handleCheckboxValues = (values) => {
        const { wizardData, updateWizardData } = this.props;
        const { cancellationSubmissionVM } = this.state;
        const otherInsuranceCarrierParam = cancellationSubmissionVM.policyCarrierName_Ext.value;
        const otherCarrierFieldParam = cancellationSubmissionVM.otherCarrierName_Ext.value;

        _set(cancellationSubmissionVM, 'additionalCancelReasonDesc_Ext.value', values);
        updateWizardData(wizardData);
        this.setState({ selectedCheckboxValues: values });

        if (!!values.length && otherInsuranceCarrierParam !== undefined) {
            if (otherInsuranceCarrierParam.code !== 'other' || (otherInsuranceCarrierParam.code === 'other' && !!otherCarrierFieldParam)) {
                this.setState({ startCancellationTag: false });
            } else {
                this.setState({ startCancellationTag: true });
            }
        } else {
            this.setState({ startCancellationTag: true });
        }
    }

    writeValue = (value, path) => {
        const { cancellationSubmissionVM } = this.state;

        this.setState({ showError: false });
        _set(cancellationSubmissionVM, path, value);
        this.setState({ cancellationSubmissionVM });

        if (path === 'cancelReasonCode') {
            this.getEffectiveDate();
        }
    };

    createVM = (model) => {
        const { viewModelService } = this.props;

        return viewModelService.create(model,
            'pc', 'edge.capabilities.gateway.job.cancellation.dto.CancellationDTO');
    };

    onReasonDescription = (value) => {
        const { cancellationSubmissionVM } = this.state;

        _set(cancellationSubmissionVM, 'uwactionDTO_Ext.reasonDescription.value', value);
        _set(cancellationSubmissionVM, 'description.value', value);
        this.setState({ startCancellationTag: false });
    }

    onStartCancel = async () => {
        const { startCancellationTag } = this.state;

        if (startCancellationTag) {
            this.setState({ isPageSubmitted: true });
            window.scrollTo(0, 0);

            return false;
        }

        this.setState({ isProcessingCancellation: true });

        const { authHeader, wizardData, updateWizardData } = this.props;
        const { policyData } = wizardData;
        const { submissionVM } = wizardData;
        const { policyNumber } = policyData;
        const policyType = policyData.product.productCode.value.slice(-2);
        const sessionIdPrefixEdit = `${policyType} - CN - `;
        const fieldIssues = _get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
        const issues = _get(submissionVM, 'value.errorsAndWarnings.validationIssues.issues', []);
        const { cancellationSubmissionVM, isAbinito } = this.state;

        if (_get(cancellationSubmissionVM.value, 'cancellationSource') === 'carrier') {
            _set(cancellationSubmissionVM, 'effectiveDate.value', cancellationSubmissionVM.uwactionDTO_Ext.pendedCancelDate.value);
        }

        _set(cancellationSubmissionVM, 'isTempCancellation', false);

        if (isAbinito) {
            _set(cancellationSubmissionVM, 'cancellationRefundMethod', 'flat');
        } else {
            _set(cancellationSubmissionVM, 'cancellationRefundMethod', 'prorata');
        }

        // If validation issues triggered , We will make a API call again since Backed will Rollback existing transaction
        if (!submissionVM.value.jobNumber || !_isEmpty(fieldIssues) || !_isEmpty(issues)) {
            authHeader['afe-session-id'] = sessionIdPrefixEdit + RandomNumberUtil.randomFixedInteger(10);
            await CancellationService.startPolicyCancellation(policyNumber.value, cancellationSubmissionVM.value, authHeader)
                .then((response) => {
                    _set(submissionVM, 'value', response);

                    if (response.errorsAndWarnings || response.exceptions_Ext) {
                        wizardData.errorsAndWarnings = response.errorsAndWarnings;
                        wizardData.exceptions_Ext = response.exceptions_Ext;
                        updateWizardData(wizardData);
                    } else {
                        wizardData.errorsAndWarnings = undefined;
                        wizardData.exceptions_Ext = undefined;
                        updateWizardData(wizardData);
                    }
                });
        }

        authHeader['afe-session-id'] = sessionIdPrefixEdit + _get(submissionVM, 'jobNumber.value');
        this.setState({ isProcessingCancellation: false });

        return wizardData;
    };

    onDoNotCancel = () => {
        const { onDoNotCancel } = this.props;

        if (onDoNotCancel) {
            onDoNotCancel();
        }
    };

    getEffectiveDate = () => {
        const { authHeader, wizardData, updateWizardData } = this.props;

        this.setState({ loadingEffectivedate: true });

        const { policyData } = wizardData;
        const { policyNumber } = policyData;
        const { cancellationSubmissionVM } = this.state;
        const dataTo = {
            cancellationSource: _get(cancellationSubmissionVM.value, 'cancellationSource'),
            isTempCancellation: true,
            cancelReasonCode: _get(cancellationSubmissionVM.value, 'cancelReasonCode'),
        };

        CancellationService.getEffectiveLocalDateForCancellation(
            policyNumber.value,
            dataTo,
            authHeader
        )
            .then((effectiveDateResponse) => {
                const effectiveDateResponseMoment = moment(effectiveDateResponse);
                const dateToBeSetAsMinDate = {
                    year: effectiveDateResponseMoment.year(),
                    month: effectiveDateResponseMoment.month(),
                    day: effectiveDateResponseMoment.date()
                };

                this.writeValue(dateToBeSetAsMinDate, 'effectiveDate.value');
                _set(cancellationSubmissionVM, 'effectiveDate.value', dateToBeSetAsMinDate);
            }).finally(() => {
                this.setState({ loadingEffectivedate: false });
            });
        updateWizardData(wizardData);
    }

    displayRefundValues = (refundAvailableValues) => {
        const translator = this.context;

        return refundAvailableValues.map((values) => ({
                code: values.code,
                name: translator({
                    id: values.name,
                    defaultMessage: values.name
                })
            }));
    }

    render() {
        const {
            isPageSubmitted, cancellationSubmissionVM, enableRefundMethod,
            showError, refundMethodsData,
            startCancellationTag, validationErrorMessage, reasonCodes,
            selectedCheckboxValues, options, showOtherCarrier,
            showOtherInsuranceContainer, showCheckboxes, coverageOption, disableReason,
            showUWSection, isProcessingCancellation, isAbinito, sourceValues,
            loadingEffectivedate, isRewriteSpecific
        } = this.state;

        if (_isEmpty(cancellationSubmissionVM)) {
            return null;
        }

        const translator = this.context;
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onStartCancel: this.onStartCancel,
                onDoNotCancel: this.onDoNotCancel,
                getEffectiveDate: this.getEffectiveDate,
                getRefundMethod: this.getRefundMethod,
                validateEffectiveDate: this.validateEffectiveDate,
                onChangeOfEffectiveDate: this.onChangeOfEffectiveDate,
                onReasonDescription: this.onReasonDescription
            }
        };
        // used to show/hide wholepage loader and bottom navigation buttons as well
        const isPageLoaded = !isProcessingCancellation && !loadingEffectivedate;

        const overrideProps = {
            '@field': {
                showErrors: isPageSubmitted,
                showRequired: true,
                autoComplete: false
            },
            startCancellationPageLoadingIndicator: {
                loaded: isPageLoaded,
                text: isProcessingCancellation
                    ? translator(messages.ratingYourPolicyMessage) : translator(messages.gettingEffectiveDate)
            },
            startCancellationPageContainer: {
                visible: isPageLoaded
            },
            refundMethodId: {
                disabled: enableRefundMethod,
                availableValues: this.displayRefundValues(_filter(
                    cancellationSubmissionVM.cancellationRefundMethod.aspects.availableValues,
                    (value) => _indexOf(refundMethodsData, value.code) !== -1
                ))
            },
            sourceId: {
                onValueChange: this.onChangeOfSourceDrpDwn,
                availableValues: sourceValues,
                readOnly: sourceValues.length === 1
            },
            underWrittingReasonDiv: {
                visible: showUWSection
            },
            effectiveDateId: {
                updateDateDto: this.updateCancellationEffectiveDate,
                dateDTO: cancellationSubmissionVM.effectiveDate,
                className: showError && styles.cancellationMessage,
                visible: !showUWSection && !isAbinito && !isRewriteSpecific,
                additionalValidationMessages: validationErrorMessage,
                showErrors: isPageSubmitted
            },
            startCancel: {
                disabled: startCancellationTag
            },
            reasonId: {
                availableValues: reasonCodes,
                disabled: disableReason,
                onValueChange: this.otherInsuranceContainerHandler
            },
            altCoverageReferal: {
                availableValues: coverageOption,
                visible: !isAbinito && !isRewriteSpecific
            },
            priReasonId: {
                required: showOtherInsuranceContainer,
                onValueChange: this.additionalReasonCheckboxHandler
            },
            otherInsuranceCarrier: {
                onValueChange: this.otherInsuranceCheckHandler,
                required: showOtherInsuranceContainer
            },
            otherInsuranceContainer: {
                visible: showOtherInsuranceContainer
            },
            additionalReasons: {
                availableValues: options,
                value: selectedCheckboxValues,
                onValueChange: (value) => this.handleCheckboxValues(value),
                visible: showCheckboxes,
                required: showCheckboxes,
                // checkbox group is of type element so the @field override does not apply here
                showRequired: true,
                showErrors: isPageSubmitted
            },
            otherCarrier: {
                visible: showOtherCarrier,
                required: showOtherCarrier,
                onBlur: () => this.checkCarrierField()
            },
            cancellationTextArea: {
                visible: !showUWSection
            },
            underWrittingName: {
                visible: showUWSection
            },
            departmentName: {
                visible: showUWSection
            },
            freePeriodEndDateId: {
                visible: showUWSection
            },
            letterMailingDateId: {
                updateDateDto: this.onChangeOfEffectiveDate,
                dateDTO: cancellationSubmissionVM.uwactionDTO_Ext.letterMailingDate,
                disabled: isAbinito
            },
            cancellationEffectiveDateId: {
                updateDateDto: () => this.writeValue(
                    cancellationSubmissionVM.uwactionDTO_Ext.pendedCancelDate, 'uwactionDTO_Ext.pendedCancelDate'
                ),
                dateDTO: cancellationSubmissionVM.uwactionDTO_Ext.pendedCancelDate,
                disabled: isAbinito
            },
            forcefeildCheckID: {
                visible: !isAbinito && !isRewriteSpecific
            },
            detailGridPreRenewalDirectionColumn: {
                visible: !isAbinito && !isRewriteSpecific
            }
        };

        return (
            <WizardPage
                isLoadingWholePage={!isPageLoaded}
                onNext={this.onStartCancel}
                showPrevious={false}
                isPageSubmittedWithErrors={isPageSubmitted && startCancellationTag}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={cancellationSubmissionVM}
                    onValueChange={this.writeValue}
                    overrideProps={overrideProps}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </WizardPage>
        );
    }
}

export default withViewModelService(withAuthenticationContext(StartCancellationPage));
