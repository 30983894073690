import { defineMessages } from 'react-intl';

export default defineMessages({
    moreMenu: {
        id: 'e1p.component.MoreMenuComponent.MORE',
        defaultMessage: 'MORE'
    },
    makePayment: {
        id: 'e1p.component.MoreMenuComponent.Make Payment',
        defaultMessage: 'Make Payment'
    },
    billingInquiry: {
        id: 'e1p.component.MoreMenuComponent.Billing Inquiry',
        defaultMessage: 'Billing Inquiry'
    }
});
