import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class QuoteProposalService {
    static retriveQuoteProposalURL = (jobNumber, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('printedquote'),
            'retriveQuoteProposalURL',
            [jobNumber],
            additionalHeaders
        );
    }

    static retrieveSideBySideQuoteProposalURL = (jobNumber, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('printedquote'),
            'retrieveSideBySideQuoteProposalURL',
            [jobNumber],
            additionalHeaders
        );
    }
}
