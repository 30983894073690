import { defineMessages } from 'react-intl';

export default defineMessages({
    notes: {
        id: 'e1p.gateway.views.account-detail.Notes',
        defaultMessage: 'Notes',
    },
    notesInfoId: {
        id: 'e1p.gateway.directives.gateway-notes.Note',
        defaultMessage: 'Add Note',
    },
    notesTopic: {
        id: 'e1p.gateway.directives.gateway-notes.Topic',
        defaultMessage: 'Topic',
    },
    notesSubject: {
        id: 'e1p.gateway.directives.gateway-notes.Subject',
        defaultMessage: 'Subject',
    },
    editTopicInput: {
        id: 'e1p.gateway.directives.activity-summary.Topic',
        defaultMessage: 'Topic',
    },
    searchNotes: {
        id: 'e1p.gateway.directives.gateway-notes.Search notes',
        defaultMessage: 'Search notes'
    },
    moreNotes: {
        id: 'e1p.gateway.directives.activity-view-summary.x more note(s)',
        defaultMessage: '{value} more note(s)'
    },
    hideNotes: {
        id: 'e1p.gateway.directives.activity-view-summary.Hide notes',
        defaultMessage: 'Hide notes'
    },
    addNote: {
        id: 'e1p.gateway.views.edit-note-modal.addNote',
        defaultMessage: 'Note',
    },
    editNote: {
        id: 'e1p.gateway.views.edit-note-modal.editNote',
        defaultMessage: 'Edit Note',
    },
    removeNoteTitle: {
        id: 'e1p.gateway.views.edit-note-modal.removeNoteTitle',
        defaultMessage: 'Delete Note',
    },
    removeNoteDescription: {
        id: 'e1p.gateway.views.edit-note-modal.removeNoteDescription',
        defaultMessage: 'The selected note will be removed.',
    },
    deleteNote: {
        id: 'e1p.gateway.views.edit-note-modal.deleteNote',
        defaultMessage: 'Delete Note',
    },
    cancelNote: {
        id: 'e1p.gateway.views.edit-note-modal.cancelNote',
        defaultMessage: 'Cancel',
    },
    expandAll: {
        id: 'e1p.gateway.directives.gateway-notes.Expand All',
        defaultMessage: 'Expand All'
    }
});
