import { defineMessages } from 'react-intl';

export default defineMessages({
    unableToDraftPolicy: {
        id: 'renewal.common.config.Unable to save draft renewal',
        defaultMessage: 'Unable to save draft renewal'
    },
    anErrorOccurred: {
        id: 'renewal.common.config.An error occurred while attempting to start the renewal.',
        defaultMessage: 'Something went wrong during this process. Please try again.'
    },
    renewals: {
        id: 'renewal.ea.directives.templates.ea-whats-next.Renewals',
        defaultMessage: 'Renewals'
    },
    insuredDetails: {
        id: 'renewal.ea.directives.templates.ea-policy-renewals.Insured',
        defaultMessage: 'Insured'
    },
    vehicles: {
        id: 'renewal.ea.directives.templates.ea-edit-vehicle.Vehicles',
        defaultMessage: 'Vehicles'
    },
    drivers: {
        id: 'renewal.ea.directives.templates.ea-edit-driver.Drivers',
        defaultMessage: 'Drivers'
    },
    underwritingPage: {
        id: 'renewal.ea.directives.templates.ea-policy-renewals.Underwriting',
        defaultMessage: 'Underwriting'
    },
    anErrorOccurredTitle: {
        id: 'gw-portals-policytransaction-renewal-ea.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'gw-portals-policytransaction-renewal-ea.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'gw-portals-policytransaction-renewal-ea.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    tpiPageTitle: {
        id: 'renewal.ea.wizard.step.Third-Party Interest',
        defaultMessage: 'Third-Party Interest'
    },
    riskAnalysisPage: {
        id: 'renewal.ea.directives.templates.ea-edit-riskAnalysis.Risk Analysis',
        defaultMessage: 'Risk Analysis'
    },
    recalculateButtonLabel: {
        id: 'renewal.ea.directives.templates.ea-edit.recalculateButtonLabel',
        defaultMessage: 'Recalculate'
    },
    reviewChangesLabel: {
        id: 'renewal.ea.directives.templates.ea-edit.reviewChangesLabel',
        defaultMessage: 'Review Changes'
    },
    viewOnlyInsuredPage: {
        id: 'renewal.ea-viewOnly.directives.templates.wizard.step.Insured',
        defaultMessage: 'Insured'
    },
    viewOnlyDriverPage: {
        id: 'renewal.ea-viewOnly.directives.templates.wizard.step.Drivers',
        defaultMessage: 'Drivers'
    },
    viewOnlyVehiclePage: {
        id: 'renewal.ea-viewOnly.directives.templates.wizard.step.Vehicles',
        defaultMessage: 'Vehicles'
    },
    viewOnlyRiskAnalysis: {
        id: 'renewal.ea-viewOnly.directives.templates.wizard.step.Risk Analysis',
        defaultMessage: 'Risk Analysis'
    },
    viewOnlyCoveragePage: {
        id: 'renewal.ea-viewOnly.directives.templates.wizard.step.Coverages',
        defaultMessage: 'Coverages'
    },
    viewOnlyTPIPage: {
        id: 'renewal.ea-viewOnly.directives.templates.wizard.step.Third-Party Interest',
        defaultMessage: 'Third-Party Interest'
    },
    exitTransactionMessage: {
        id: 'renewal.ea.directives.templates.Information entered on prior pages will be saved as a draft. Any changes on this page will be lost. Changes will be discarded if not applied before the renewal is automatically bound.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any changes on this page will be lost. Changes will be discarded if not applied before the renewal is automatically bound.'
    }
});
