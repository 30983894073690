import { defineMessages } from 'react-intl';

export default defineMessages({
    nonPayOccurrence: {
        id: 'billing.cancelActivities.Non Pay Occurrence',
        defaultMessage: 'Non Pay Occurrence'
    },
    cancelTransactionNumber: {
        id: 'billing.cancelActivities.Cancel Transaction',
        defaultMessage: 'Cancel Transaction'
    },
    cancelStatus: {
        id: 'billing.cancelActivities.Cancel Status',
        defaultMessage: 'Cancel Status'
    },
    cancelLetterNoticeDate: {
        id: 'billing.cancelActivities.Cancel Letter Notice Date',
        defaultMessage: 'Cancel Letter Notice Date'
    },
    cancelEffectiveDate: {
        id: 'billing.cancelActivities.Cancel Effective Date',
        defaultMessage: 'Cancel Effective Date'
    },
    rescindDate: {
        id: 'billing.cancelActivities.Rescind Date',
        defaultMessage: 'Rescind Date'
    },
    rewriteReinstateTransaction: {
        id: 'billing.cancelActivities.Rewrite/Reinstate Transaction',
        defaultMessage: 'Rewrite/Reinstate Transaction'
    },
    rewriteReinstateEffectiveDate: {
        id: 'billing.cancelActivities.Rewrite/Reinstate Effective Date',
        defaultMessage: 'Rewrite/Reinstate Effective Date'
    },
    rewriteReinstateStatus: {
        id: 'billing.cancelActivities.Rewrite/Reinstate Status',
        defaultMessage: 'Rewrite/Reinstate Status'
    }
});
