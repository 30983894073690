import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue, appendMetadataWithIndex } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import moment from 'moment';
import metadata from './EAViolationReportComponent.metadata.json5';
import './EAViolationReportComponent.messages';
import styles from './EAViolationReportComponent.module.scss';

function EAViolationReportComponent(props) {
    const {
        data: violationVM,
        labelPosition,
        path,
        id,
        onValidate,
        onValueChange,
        operators,
        viewOnlyMode,
        index,
        showErrors,
        periodStartDate,
        policyState,
        onChangePrayerForJudgement

    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    // Make sure iterated components have unique IDs
    const formattedMetadata = useMemo(() => appendMetadataWithIndex(metadata.pageContent, index), [index]);

    /**
     * E1PAP1PC-13490, we need to set focus on the first element of the violation node
     * when ever the new violation is added.
     * this useEffect will execute only once the component is rendered and based on condition
     * it will focus the first element on the UI.
     */
    useEffect(() => {
        const violationOperatorElement = document.getElementById(`violationOperator${index}`);

        if (violationOperatorElement
            && violationOperatorElement.focus !== undefined
            && _.isEmpty(_.get(violationVM, 'value.assignment.publicId'))) {
            violationOperatorElement.focus();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [violationVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;

        if (onValueChange) {
            onValueChange(value, fullPath);
        }

        onChangePrayerForJudgement();
    }, [onValueChange, path, onChangePrayerForJudgement]);

    const startingDate = new Date();

    // Helper function to convert LocalDateDTO object into a Date object
    const convertLocalDateToDate = (localDate) => new Date(_.get(localDate, 'value.year'), _.get(localDate, 'value.month'), _.get(localDate, 'value.day'));

    // Min date for manual violations is 10 years prior
    // to period start for OWI incidents, and 5 years for others
    const minDateForIncident = () => {
        let minDate;
        const violationTypeCode = _.get(violationVM, 'violationType.value.code');

        if (violationTypeCode && periodStartDate) {
            // Convert LocalDateDTO into Date(), then use moment.js to subtract either 5 or 10 years
            const maxYearsInPast = violationTypeCode === 'OWI' ? 10 : 5;
            const periodStart = convertLocalDateToDate(periodStartDate);
            const momentDate = moment(periodStart).subtract(maxYearsInPast, 'years');

            minDate = {
                year: momentDate.year(),
                month: momentDate.month(),
                day: momentDate.date()
            };
        }

        return minDate;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition,
            readOnly: viewOnlyMode,
            showErrors,
            autoComplete: false
        },
        [`reportGrid${index}`]: {
            className: policyState === 'NC' ? 'violationGridWithPrayerForJudgement' : 'violationGridWithoutPrayerForJudgement'
        },
        [`violationType${index}`]: { required: !_.get(violationVM, 'violationType.value') },
        [`prayerForJudgementId${index}`]: { 
            onValueChange: handleValueChange,
            visible: policyState === 'NC'
        },
        [`violationIncidentDate${index}`]: {
            isRequired: true,
            maxDate: {
                year: startingDate.getFullYear(),
                month: startingDate.getMonth(),
                day: startingDate.getDate() - 1
            },
            minDate: minDateForIncident(),
            updateDateDto: (newDate) => { handleValueChange(newDate, 'incidentDate'); handleValueChange(newDate, 'convictionDate'); },
            dateDTO: violationVM.incidentDate?.value,
            onValidate
        },
        [`violationOperator${index}`]: {
            availableValues: operators.map((operator) => ({
                    code: `${operator.publicId}`,
                    name: `${operator.displayName}`
                })),
            required: !_.get(violationVM, 'assignment.publicId.value')
        },
        [`violationSource${index}`]: {
            readOnly: true,
            value: 'Self-Declared'
        },
    };

    const readValue = useCallback((fieldId, fieldPath) => readViewModelValue(
            metadata.pageContent, violationVM, fieldId, fieldPath, overrideProps
        ), [violationVM, overrideProps]);

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={formattedMetadata}
            model={violationVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

EAViolationReportComponent.propTypes = {
    data: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    operators: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    index: PropTypes.number.isRequired,
    showErrors: PropTypes.bool,
    periodStartDate: PropTypes.shape({}).isRequired,
    policyState: PropTypes.string,
    onChangePrayerForJudgement: PropTypes.func.isRequired,
};
EAViolationReportComponent.defaultProps = {
    data: {},
    labelPosition: 'top',
    path: undefined,
    showOptional: true,
    id: undefined,
    viewOnlyMode: false,
    showErrors: false
};
export default EAViolationReportComponent;
