import { defineMessages } from 'react-intl';

export default defineMessages({
    chooseGarageType: {
        id: 'quoteandbind.eh.views.component.ehGarage.Choose Garage Type',
        defaultMessage: 'Choose Garage Type',
    },
    size: {
        id: 'quoteandbind.eh.views.component.ehGarage.Choose Garage Size',
        defaultMessage: 'Size',
    }
});
