import React, {
    useCallback,
    useState,
    useEffect,
    useContext,
    useMemo,
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { error as loggerError } from '@jutro/logger';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { UserService } from 'e1p-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import appConfig from 'app-config';
import {
    gatewayMessages,
    AmfamOktaTokenContext,
} from 'e1p-capability-gateway-react';
import metadata from './JobSearchCriteriaFilterComponent.metadata.json5';
import styles from './JobSearchCriteriaFilterComponent.module.scss';
import './JobSearchCriteriaFilterComponent.messages';

function JobSearchCriteriaFilterComponent(props) {
    const {
        labelPosition,
        showOptional,
        isCallingApi,
        onRefineJobsData,
        isPolicy,
        createJobSearchVM,
        jobSearchVM,
        setJobSearchVM,
        selectedTileView,
    } = props;
    const [producerCodesForCurrentUser, setProducerCodesForCurrentUser] =
        useState([]);
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const [isFetchingProducerCodes, setIsFetchingProducerCodes] = useState(false);
    // this is used for Internal users, where they can enter code in text box
    const [externalLocationCode, setExternalLocationCode] = useState(undefined);
    const { opCo } = useContext(AmfamOktaTokenContext);
    const { operatingCompanyConfig } = appConfig;

    useEffect(() => {
        if (viewModelService && _.isUndefined(jobSearchVM)) {
            createJobSearchVM();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService]);

    const getProducerCodes = useCallback(async () => {
        setIsFetchingProducerCodes(true);

        let formattedProducerCodes = [];

        try {
            const producerCodeResponse =
                await UserService.getAvailableProducerCodesForCurrentUser(
                    authHeader
                );

            if (producerCodeResponse && producerCodeResponse.length > 0) {
                formattedProducerCodes = _.sortBy(
                    producerCodeResponse,
                    'code'
                ).map(value => ({
                        code: value.code,
                        name: value.displayValue,
                    }));
            }

            setProducerCodesForCurrentUser(formattedProducerCodes);
        } catch (e) {
            loggerError(e);
            setProducerCodesForCurrentUser(formattedProducerCodes);
        }

        setIsFetchingProducerCodes(false);
    }, [authHeader]);

    useEffect(() => {
        if (isAgent) {
            getProducerCodes();
        }

        // this will get executed when we are getting search criteria from context
        if (
            !_.isUndefined(_.get(jobSearchVM, 'value')) &&
            _.get(jobSearchVM, 'value.producerCode') &&
            !isAgent
        ) {
            setExternalLocationCode(_.get(jobSearchVM, 'value.producerCode'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The onValueChange for most of the fields
     */
    const handleValueChange = useCallback(
        (value, changedPath) => {
            switch (changedPath) {
                case 'workStartedByCurrentUser_Ext': {
                    const workstartedValue = value === 'true';

                    _.set(jobSearchVM.value, changedPath, workstartedValue);
                    break;
                }
                case 'createdInLastXDays_Ext': {
                    const createdinlastdaysValue = parseInt(value, 10);

                    _.set(
                        jobSearchVM.value,
                        changedPath,
                        createdinlastdaysValue
                    );
                    break;
                }
                case 'issuedInLastXDays_Ext': {
                    const issuedinlastdaysValue = parseInt(value, 10);

                    _.set(
                        jobSearchVM.value,
                        changedPath,
                        issuedinlastdaysValue
                    );
                    break;
                }
                case 'policyNumber': {
                    // backend in not doing case insensative search, hence sending all uppercase for policyNumber
                    const policyNumberValue = _.isEmpty(value)
                        ? undefined
                        : value.toUpperCase();

                    _.set(jobSearchVM.value, changedPath, policyNumberValue);
                    break;
                }
                default: {
                    // setting to undefined if the value is empty, backend api is not ignoring empty fields
                    const valueToSet = _.isEmpty(value) ? undefined : value;

                    _.set(jobSearchVM.value, changedPath, valueToSet);
                    break;
                }
            }

            const searchCriteriaVMClone = viewModelService.clone(jobSearchVM);

            setJobSearchVM(searchCriteriaVMClone);
        },
        [jobSearchVM, setJobSearchVM, viewModelService]
    );

    const getPolicyPeriodStatusTypeCodes = useMemo(() => {
        const commonStatuses = ['Draft', 'Quoted', 'Expired', 'Withdrawn'];
        const searchableStatuses =
            selectedTileView === 'renewal'
                ? [...commonStatuses, 'Renewing', 'NonRenewing', 'NotTaking']
                : commonStatuses;
        const statusTypeCodes = _.filter(
            jobSearchVM?.policyPeriodStatus.aspects.availableValues,
            typeCode => searchableStatuses.indexOf(typeCode.code) !== -1
        ).map(statusObj => ({
                code: statusObj.code,
                name: translator({
                    id: statusObj.name,
                    defaultMessage: statusObj.name,
                }),
            })).sort((typeCodeOne, typeCodeTwo) => 
            // sort by name
             typeCodeOne.name.localeCompare(typeCodeTwo.name)
        );

        return statusTypeCodes;
    }, [jobSearchVM, translator, selectedTileView]);

    const getConnectPartnerValues = () => {
        const formattedDropdownValues = [];
        const connectConfigObject = _.get(
            operatingCompanyConfig,
            'CONNECT.experiences',
            {}
        );

        for (const key in connectConfigObject) {
            // eslint-disable-next-line no-prototype-builtins
            if (connectConfigObject.hasOwnProperty(key) && !connectConfigObject[key].retired) {
                formattedDropdownValues.push({
                    id: key,
                    displayName: {
                        id: key,
                        defaultMessage: connectConfigObject[key].partnerDisplayName
                    }
                });
            }
        }

        return formattedDropdownValues;
    };
    const resolvers = {
        resolveClassNameMap: styles,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            disabled: isCallingApi,
            autoComplete: false,
        },
        searchJobButton: {
            onClick: () => {
                if (onRefineJobsData) {
                    const searchVmClone = viewModelService.clone(jobSearchVM);

                    if (!isAgent) {
                        // for UW login, we are setting location code in producerCode field,
                        // so that parent component can call producer api to fetch the producer code from location code
                        const locationValueToSet =
                            externalLocationCode || undefined;

                        _.set(
                            searchVmClone,
                            'producerCode.value',
                            locationValueToSet
                        );
                    }

                    onRefineJobsData(searchVmClone);
                }
            },
            disabled: isCallingApi,
        },
        clearFiltersButton: {
            onClick: () => {
                createJobSearchVM();

                if (!isAgent) {
                    setExternalLocationCode(undefined);
                }
            },
            disabled: isCallingApi,
        },
        LocationCodeDropdownID: {
            availableValues: [
                {
                    code: 'allLocations',
                    name: translator(gatewayMessages.allLocations),
                },
            ].concat(producerCodesForCurrentUser),
            visible: opCo === 'MSA' && isAgent && !isFetchingProducerCodes,
        },
        ExternalLocationCodeID: {
            visible: opCo === 'MSA' && !isAgent,
            isRequired: false,
            value: externalLocationCode,
            onValueChange: code => {
                setExternalLocationCode(code);
            },
        },
        PartnerCodeDropdownID: {
            availableValues: getConnectPartnerValues(),
            visible: opCo === 'CONNECT',
        },
        jobSearchStatus: {
            visible: isPolicy,
        },
        jobSearchPolicyPeriodStatus: {
            visible: !isPolicy,
            availableValues: getPolicyPeriodStatusTypeCodes,
        },
        jobSearchQuoteNumber: {
            visible: !isPolicy,
        },
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.componentContent,
                jobSearchVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [jobSearchVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={jobSearchVM}
            overrideProps={overrideProps}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

JobSearchCriteriaFilterComponent.propTypes = {
    labelPosition: PropTypes.string,
    showOptional: PropTypes.bool,
    isCallingApi: PropTypes.bool,
    onRefineJobsData: PropTypes.func.isRequired,
    isPolicy: PropTypes.bool.isRequired,
    createJobSearchVM: PropTypes.func.isRequired,
    selectedTileView: PropTypes.string.isRequired,
};
JobSearchCriteriaFilterComponent.defaultProps = {
    labelPosition: 'top', // I want labels on top by default
    showOptional: false,
    isCallingApi: undefined
};
export default JobSearchCriteriaFilterComponent;
