import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import {
    get as _get,
    set as _set,
    concat as _concat,
    isEmpty as _isEmpty,
    isEqual as _isEqual,
    cloneDeep as _cloneDeep,
    range as _range,
    toString as _toString,
    truncate as _truncate
} from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { wizardProps, WizardPage } from 'e1p-portals-wizard-react';
import config from 'app-config';
import { VehicleInfoLookupService, PartnerDataUtil } from 'e1p-capability-gateway';
import { parseErrors } from '@xengage/gw-portals-edge-validation-js';
import { useModal } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAdditionalInfoUtil, useDriverPageUtil } from 'e1p-capability-hooks';
import { VehicleUtil } from 'e1p-portals-util-js';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { connectMessages, eaValidationAndInfoMessages } from 'e1p-platform-translations';
import { prefillDriverToDriverVMAdapter, foundInDrivers } from './AdditionalInfotUtil';
import styles from './AdditionalInformationPage.module.scss';
import metadata from './AdditionalInformationPage.metadata.json5';
import messages from './AdditionalInformationPage.messages';

const VALID_MEMBERSHIP_ID_LENGTH = 12;

const licencedDriverInHouseHold = 'AddedToQuote';

function AdditionalInformationPage(props) {
    const modalApi = useModal();
    const {
        wizardData: submissionVM, updateWizardData,
        removeCurrentPageFromSteps
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isQuoting, setIsQuoting] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState(undefined);
    const { onValidate, isComponentValid, disregardFieldValidation } = useValidation('AdditionalInformationPage');
    const [additionalInfoVM, updateAdditionalInfoVM] = useState(submissionVM);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [vehiclesWithNoDataFoundError, setVehiclesWithNoDataFoundError] = useState([]);
    const [membershipIdValidationMessage, setMembershipIdValidationMessage] = useState([]);
    const [membershipVerificationMessage, setMembershipVerificationMessage] = useState('');
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { opCo } = useContext(AmfamOktaTokenContext);
    const { operatingCompanyConfig } = config;
    const {
        allDrivers,
        allVehicles,
        allPrefillDrivers,
        vehicleHasInvalidVin,
        prefillDriverHasNoStatus,
        nonOperatorInfoIsMissing,
        additionalInfoIsProvided,
        additionalInfoIsProvidedExceptValidatedVehicleVins
    } = useAdditionalInfoUtil(additionalInfoVM);
    const translator = useTranslator();
    const [drivers, setDrivers] = useState([]);
    // this is an array of indexes to vehicles
    const [vehiclesWithInvalidVins, setVehiclesWithInvalidVins] = useState([]);
    const [driversWithInvalidDetails, setDriversWithInvalidDetails] = useState([]);
    const [showMembershipBlock, setShowMembershipBlock] = useState(false);

    const {
        setSpouseAsSNI,
        setRelationShipStatusForSNI,
        isDefensiveDriverDiscountAvailableForDriver,
        isLicenseNumberRequired,
        getDriverAge,
        isFinancialResponsibilityFormAvailableForDriver,
    } = useDriverPageUtil(
        submissionVM,
        updateWizardData,
        viewModelService,
        setValidationErrors,
        translator
    );

    useEffect(() => {
        let isMounted = true;

        // IAP-370: if full quote additional information page is removed
        // and flow proceeds to next page
        additionalInfoIsProvided(authHeader, updateWizardData).then((isProvided) => {
            if (isMounted) {
                if (isProvided) {
                    removeCurrentPageFromSteps();
                } else {
                    _set(submissionVM, 'convertedFromQuickToFull', true);
                }
            }

        })

        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const validateMembershipId = async () => {
        const membershipNumber = _get(additionalInfoVM, 'lobData.personalAuto_EA.membership.membershipNumber.value');

        if (membershipNumber?.length === VALID_MEMBERSHIP_ID_LENGTH && _get(additionalInfoVM, 'lobData.personalAuto_EA.membership.membershipLevel.value') !== undefined) {
            // not quoting but calling membership api
            setIsQuoting(true);

            const response = await PartnerDataUtil.connectCostcoMembershipValidation(
                membershipNumber,
                // Quote ID for new business and quote exists, Job ID for other trans, empty string for no sub created yet
                _get(additionalInfoVM, 'quoteID.value', undefined) || _get(additionalInfoVM, 'jobID.value', ''),
                authHeader,
                translator
            );

            setMembershipVerificationMessage(response.message);
            _set(additionalInfoVM, `lobData.personalAuto_EA.membership.membershipTier.value`, response.membershipTier);
            _set(additionalInfoVM, `lobData.personalAuto_EA.membership.membershipStatus.value`, response.membershipStatus);
            // not quoting but calling membership api
            setIsQuoting(false);
        }
    }

    useEffect(() => {
        let costcoPartnerId;
        const connectConfigObject = _get(operatingCompanyConfig, "CONNECT.experiences");

        for (const key in connectConfigObject) {
            // eslint-disable-next-line no-prototype-builtins
            if (connectConfigObject.hasOwnProperty(key)) {
                if (`${connectConfigObject[key].experienceIdDisplayName}` === 'Costco') {
                    costcoPartnerId = key;
                }
            }
        }

        const showMembership = opCo === 'CONNECT' && _get(additionalInfoVM, 'baseData.partnerCode_Ext.value') === costcoPartnerId
            && _get(additionalInfoVM, 'lobData.personalAuto_EA.membership.membershipNumber.value')?.length !== VALID_MEMBERSHIP_ID_LENGTH;

        setShowMembershipBlock(showMembership);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!_isEmpty(allVehicles)) {
            const invalidVins = [];

            allVehicles.forEach((vehicle, index) => {
                if (vehicleHasInvalidVin(vehicle)) {
                    invalidVins.push(index);
                }
            });
            setVehiclesWithInvalidVins(invalidVins);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!_isEmpty(allDrivers)) {
            const invalidDriverDetails = [];

            allDrivers.forEach((driver, index) => {
                if (nonOperatorInfoIsMissing(driver)) {
                    invalidDriverDetails.push(index);
                }
            });
            setDriversWithInvalidDetails(invalidDriverDetails);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let isMounted = true;

        // Take the show errors off once page is fixed
        // pass in no parameters to skip the vin lookups
        additionalInfoIsProvided().then((isProvided) => {
            if (isProvided && isComponentValid && isMounted) {
                updateIsPageSubmitted(false);
            }
        });

        return () => {
            isMounted = false;
        }
    }, [
        submissionVM, isComponentValid, isPageSubmitted, isQuoting,
        additionalInfoIsProvided, authHeader, updateWizardData
    ]);

    const showError = useCallback((errorTitle, errorMessage) => {
        modalApi.showAlert({
            status: 'error',
            icon: 'mi-error-outline',
            title: errorTitle,
            message: errorMessage
        });
    },[modalApi]);
    
    const writeValue = useCallback(
        (newVal, path) => {
            _set(additionalInfoVM, `${path}.value`, newVal);

            const newAdditionalInfoVM = viewModelService.clone(additionalInfoVM);

            updateAdditionalInfoVM(newAdditionalInfoVM);
            updateIsPageSubmitted(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const onDriversValueChange = (value, path, index) => {
        // from EADriverGridComponent we will always get [0].path trimming it out 
        const trimmedPath = path.split('[0]')[1];

        setDrivers((prevState) => prevState.map((driver, i) => {
            if (i === index) {
                _set(driver, trimmedPath?.substring(1), value);

                return driver;
            }

            return driver;
        }));
    };

    const getVehicleYearMakeModelSeries = useCallback((index) => {
        const vehicles = _get(
            viewModelService.clone(submissionVM),
            'value.lobData.personalAuto_EA.coverables.vehicles',
            []);
        const vehicle = vehicles[index];
        const vehicleYearMake = `${_truncate(vehicle.year)} ${_truncate(vehicle.make)}`;
        const vehicleModelSeries = `${_truncate(vehicle.model)} ${_truncate(vehicle.series)}`;

        return `${vehicleYearMake} ${vehicleModelSeries}`;
    }, [submissionVM, viewModelService]);

    const triggerVinLookup = useCallback((index) => {
        const VALID_VIN_LENGTH = 17;
        const vehicles = _get(viewModelService.clone(additionalInfoVM), 'lobData.personalAuto_EA.coverables.vehicles.children', []);
        const vehicleLookupDTO = {
            year: vehicles[index].year.value,
            vin: vehicles[index].vin.value,
            partnerID: _get(additionalInfoVM, 'baseData.partnerCode_Ext.value')
        };

        if (vehicles[index].year.value
            && vehicles[index].vin.value
            && vehicles[index].vin?.value?.length === VALID_VIN_LENGTH) {
            // not quoting but calling vin api
            setIsQuoting(true);
            VehicleInfoLookupService.lookupVehicleInfoBasedOnVinAndYear(
                vehicleLookupDTO, authHeader
            ).then((response) => {
                // valid length for vin; remove message
                // Service does not return error if not found.
                //   Have to check this property and then exit function.
                if (response.vinstatus !== 'data_found') {
                    // set errors
                    setValidationErrors(
                        [{
                            level: 'LEVEL_ERROR',
                            description: translator(eaValidationAndInfoMessages.noVehicleDataFound)
                        }]
                    );

                    updateAdditionalInfoVM(additionalInfoVM);
                    setIsQuoting(false);

                    return false;
                }

                VehicleUtil.checkIfVehicleInfoChanged(
                    response,
                    additionalInfoVM.lobData.personalAuto_EA.coverables.vehicles.children[index].value
                );
                additionalInfoVM.lobData.personalAuto_EA.coverables.vehicles.children[index].value = VehicleUtil.mapVinAndYearLookupToVehicle(
                    additionalInfoVM.lobData.personalAuto_EA.coverables.vehicles.children[index].value,
                    response,
                    true
                );
                // Removing vehicle public id from vehiclesWithNoDataFoundError array once vin is validated
                setVehiclesWithNoDataFoundError(
                    vehiclesWithNoDataFoundError
                        .filter((vehPublicID) => vehPublicID !== vehicles[index].publicID.value)
                );
                updateAdditionalInfoVM(additionalInfoVM);
                setValidationErrors([]);
                setIsQuoting(false);
            });
        }
    }, [additionalInfoVM, authHeader, translator, vehiclesWithNoDataFoundError, viewModelService]);

    const onDriverStatusValueChange = useCallback((value, path, index) => {
        if (value !== licencedDriverInHouseHold) { disregardFieldValidation(`EADriverGrid${index}`); }

        writeValue(value, path);
    }, [disregardFieldValidation, writeValue]);

    const onNext = useCallback(
        async () => {
            if (!additionalInfoIsProvidedExceptValidatedVehicleVins() || !isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsQuoting(true);
            setLoadingMessage(messages.ratingPolicy);
            drivers.forEach((driver, index) => {
                if (
                    (prefillDriverHasNoStatus(allPrefillDrivers()[index])
                        || !foundInDrivers(allPrefillDrivers()[index], submissionVM))
                    && _get(allPrefillDrivers()[index], 'driverStatus.value.code') === licencedDriverInHouseHold
                ) {
                    const addingDriver = _cloneDeep(driver);

                    addingDriver.value.person.driverStatus = licencedDriverInHouseHold;
                    additionalInfoVM.lobData.personalAuto_EA.coverables.drivers.pushElement(
                        addingDriver
                    );
                }
            });

            const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
            const driversList = _get(submissionVM, driversPath);

            driversList.forEach((driver) => {
                // updating role as sni for spouse
                if (driver.relationshipToNI === 'spouse') {
                    _set(driver, 'role', 'sni');
                }
            });

            /**
             * IAP-3005 : removing prompt to set Spouse as SNI
             * When Spouse is added as driver we will add it as SNI on the policy
             */
            setSpouseAsSNI();

            // E1PAP1PC-12823
            // syncs relationship status for sni
            setRelationShipStatusForSNI();

            return LoadSaveService.convertToFullApp(
                additionalInfoVM.value, authHeader
            ).then((response) => {
                const errorsAndWarnings = _get(response, 'errorsAndWarnings', []);
                const blockingUWIssues = errorsAndWarnings?.underwritingIssues?.filter((item) => item?.approvalBlockingPoint !== 'NonBlocking');

                _set(errorsAndWarnings, 'underwritingIssues', blockingUWIssues);

                const errors = parseErrors(errorsAndWarnings);
                const exceptionsPath = 'baseData.exceptions_Ext';
                const exceptions = _get(response, exceptionsPath, []);

                setIsQuoting(false);
                setLoadingMessage(undefined);

                if (errors?.length > 0 || exceptions.length > 0) {
                    setValidationErrors(errors.concat(exceptions));
                    _set(submissionVM, 'value', response);
                    updateWizardData(submissionVM);
                } else {
                    _set(submissionVM, 'value', response);
                    updateWizardData(submissionVM);
                }

                return submissionVM;
            }).catch(() => {
                setIsQuoting(false);
                setLoadingMessage(undefined);
                showError(messages.transactionError, messages.ratingErrorMessage);
            });
        },
        [additionalInfoIsProvidedExceptValidatedVehicleVins, isComponentValid, drivers, submissionVM, setSpouseAsSNI, setRelationShipStatusForSNI, LoadSaveService, additionalInfoVM.value, additionalInfoVM.lobData.personalAuto_EA.coverables.drivers, authHeader, prefillDriverHasNoStatus, allPrefillDrivers, updateWizardData, showError]
    );

    const codeNeedsFurtherExplanation = (driver) => (
        ['NCL', 'SRL']
            .includes(driver.nonOperatorDescType?.value?.code)
    );

    const pageUpdateOverrides = useCallback(() => {
        const overrides = [];

        allDrivers.forEach((driver, index) => {
            overrides.push({
                [`nonOperatorStatusOtherReason${index}`]: {
                    visible: _isEqual(
                        _get(driver, 'nonOperatorDescType.value.code', null),
                        'OTH'
                    )
                },
                [`nonOperatorSuspRevokPleaseExplID${index}`]: {
                    visible: codeNeedsFurtherExplanation(driver),
                },
                [`additionalDriverBirthdate${index}`]: {
                    updateDateDto: () => updateWizardData(submissionVM),
                    dateDTO: driver.person.dateOfBirth
                },
                [`nonOperatorBirthdate${index}`]: {
                    updateDateDto: () => updateWizardData(submissionVM),
                    dateDTO: driver.person.dateOfBirth
                }
            });
        });
        allPrefillDrivers().forEach((prefillDriver, index) => {
            const driverMidInitial = ` ${_get(prefillDriver, 'middleName.value', '').charAt(0)}`;

            prefillDriver.displayName = `${prefillDriver.firstName.value}${driverMidInitial} ${prefillDriver.lastName.value}`;
            additionalInfoVM.lobData.personalAuto_EA.prefillDrivers
                .children[index].displayName = prefillDriver.displayName;
            overrides.push({
                [`driverStatus${index}`]: {
                    value: _get(prefillDriver, 'driverStatus.value.code', null)
                },
                [`additionalDriverBirthdate${index}`]: {
                    updateDateDto: () => updateWizardData(submissionVM),
                    dateDTO: prefillDriver.dateOfBirth
                }
            });
        });

        return Object.assign({}, ...overrides);
    }, [
        additionalInfoVM.lobData.personalAuto_EA.prefillDrivers.children,
        allDrivers,
        allPrefillDrivers,
        submissionVM,
        updateWizardData
    ]);

    const setCarYears = () => {
        const currentYear = new Date().getUTCFullYear();
        const { oldestCarYear } = config.personalAutoConfig;

        // range is not inclusive. Need to add -1 to _get correct years
        const yearRange = _range(currentYear + 2, oldestCarYear - 1);

        const vehicleYearsMap = yearRange.map(_toString).map((yyyy) => ({
            code: yyyy,
            name: yyyy
        }));

        return vehicleYearsMap;
    }

    const onYearChange = useCallback((value, yearPath, index) => {
        writeValue(value, yearPath);

        const vehicles = _get(
            additionalInfoVM,
            'lobData.personalAuto_EA.coverables.vehicles.children',
            []
        );

        if (_get(vehicles[index], 'vin.value')) {
            triggerVinLookup(index);
        }
    }, [additionalInfoVM, triggerVinLookup, writeValue]);


    const [driversWithLicenseValidationError, setDriversWithLicenseValidationErrors] = useState([]);
    const generateVisibilityOverrides = useCallback(() => {
        const driverOverrides = [];
        const vehicleOverrides = [];
        const prefillDriverOverrides = [];

        if (!_isEmpty(allVehicles)) {
            allVehicles.forEach((vehicle, index) => {
                const vehicleInvalidVIN = vehiclesWithInvalidVins.includes(index);

                vehicleOverrides.push({
                    [`vehicleContainerFragment${index}`]: {
                        visible: vehicleInvalidVIN
                    }
                });

                if (vehicleInvalidVIN) {
                    vehicleOverrides.push({
                        [`vin${index}`]: {
                            onBlur: () => triggerVinLookup(index),
                            showErrors: isPageSubmitted,
                            validationMessages: vehiclesWithNoDataFoundError
                                .includes(vehicle.value.publicID)
                                ? [translator(eaValidationAndInfoMessages.noVehicleDataFound)]
                                : null
                        }
                    });
                }

                if (vehicleInvalidVIN) {
                    vehicleOverrides.push({
                        [`year${index}`]: {
                            onValueChange: (value, yearPath) => onYearChange(value, yearPath, index),
                            availableValues: setCarYears(),
                            showErrors: isPageSubmitted
                        }
                    });
                }

                if (vehicleInvalidVIN) {
                    vehicleOverrides.push({
                        [`vehicleName${index}`]: {
                            value: getVehicleYearMakeModelSeries(index)
                        }
                    });
                }

                if (vehicleInvalidVIN) {
                    vehicleOverrides.push({
                        [`vehicleInfoMessage${index}`]: {
                            content: _get(vehicle, 'value.vehicleInfoChanged')
                                ? eaValidationAndInfoMessages.vehicleHasUpdatedMessage
                                : messages.eaVinRequired
                        }
                    });
                }
            });
            vehicleOverrides.push({
                vehicleContainer: {
                    visible: vehiclesWithInvalidVins.length > 0
                }
            });
        }

        if (!_isEmpty(allDrivers)) {
            allDrivers.forEach((driver, index) => {
                driverOverrides.push({
                    [`nonOperatorStatusFragment${index}`]: {
                        visible: driversWithInvalidDetails.includes(index)
                    }
                });
            });
            driverOverrides.push({
                nonOperatorStatusContainer: {
                    visible: driversWithInvalidDetails.length > 0
                }
            });
        }

        if (!_isEmpty(allPrefillDrivers()) && _get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value.code', '') === 'comprater') {
            let prefillDriversSectionVisible = false;

            allPrefillDrivers().forEach((prefillDriver, index) => {
                const visibleDriver = !foundInDrivers(prefillDriver, submissionVM);

                if (visibleDriver) {
                    const isLicencedDriverInHouseHold = _get(prefillDriver, 'driverStatus.value.code') === licencedDriverInHouseHold;

                    prefillDriversSectionVisible = true;
                    prefillDriverOverrides.push({
                        [`additionalDrivers${index}`]: {
                            visible: visibleDriver
                        },
                        [`driverStatus${index}`]: {
                            onValueChange:
                                (value, path) => onDriverStatusValueChange(value, path, index),
                            value: _get(prefillDriver, 'driverStatus.value.code')
                        },
                        [`EADriverGrid${index}`]: {
                            key: `eaDriverGrid${index}`,
                            drivers: (() => {
                                const driverVM = drivers[index] ?? prefillDriverToDriverVMAdapter(
                                    prefillDriver, index, submissionVM, viewModelService, setDrivers
                                );
                                const clonedSubmissionVM = viewModelService.clone(submissionVM);

                                _set(clonedSubmissionVM, 'lobData.personalAuto_EA.coverables.drivers.value', [driverVM.value])

                                return clonedSubmissionVM.lobData.personalAuto_EA.coverables.drivers;
                            })(),
                            path: 'lobData.personalAuto_EA.coverables.drivers.children',
                            onValidate,
                            viewModelService,
                            showErrors: isPageSubmitted,
                            policyState: _get(submissionVM, 'baseData.policyAddress.state.value.code'),
                            driverPageDisregardFieldValidation: disregardFieldValidation,
                            authHeader,
                            checkLicenseRequired: (driver) => isLicenseNumberRequired(submissionVM, driver),
                            driversWithLicenseValidationError,
                            setDriversWithLicenseValidationErrors,
                            getDriverAge,
                            periodStartDate: _get(submissionVM, 'baseData.periodStartDate.value'),
                            priorPolicyList: _get(submissionVM, 'lobData.personalAuto_EA.priorPolicyUpdates'),
                            isFinancialResponsibilityFormAvailableForDriver,
                            isNewSubmission: true,
                            setSNIHasDefensiveCourse: () => { },
                            setPNIHasDefensiveCourse: () => { },
                            authUserData,
                            transactionVM: submissionVM,
                            isDefensiveDriverDiscountAvailableForDriver,
                            updateWizardData,
                            setCheckScrolling: () => { },
                            setIsExistingDriverDeleted: () => { },
                            showValidationMessage: () => {
                                updateIsPageSubmitted(true);
                                window.scrollTo(0, 0);

                                return false;
                            },
                            isAdditionalInfo: true,
                            visible: visibleDriver && isLicencedDriverInHouseHold,
                            onValueChange: (...args) => onDriversValueChange(...args, index)
                        }
                    });
                } else {
                    prefillDriverOverrides.push({
                        [`additionalDrivers${index}`]: {
                            visible: false
                        }
                    });
                }
            });
            prefillDriverOverrides.push({
                prefillDriverContainer: {
                    visible: prefillDriversSectionVisible
                }
            });
        } else {
            prefillDriverOverrides.push({
                prefillDriverContainer: {
                    visible: false
                }
            });
        }

        return Object.assign({}, ...(_concat(driverOverrides,
            vehicleOverrides, prefillDriverOverrides)));
    }, [allDrivers, allPrefillDrivers, allVehicles, authHeader, authUserData, disregardFieldValidation, drivers, driversWithInvalidDetails, driversWithLicenseValidationError, getDriverAge, getVehicleYearMakeModelSeries, isDefensiveDriverDiscountAvailableForDriver, isFinancialResponsibilityFormAvailableForDriver, isLicenseNumberRequired, isPageSubmitted, onDriverStatusValueChange, onValidate, onYearChange, submissionVM, translator, triggerVinLookup, updateWizardData, vehiclesWithInvalidVins, vehiclesWithNoDataFoundError, viewModelService]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            showErrors: isPageSubmitted,
            autoComplete: false,
            showRequired: true
        },
        additionalInformationPageLoadingIndicator: {
            loaded: !isQuoting,
            text: translator(loadingMessage)
        },
        additionalInformationPageContainer: {
            visible: !isQuoting
        },
        eaAdditionalInfoDriver: {
            submissionVM: additionalInfoVM,
            viewModelService,
            authHeader,
            showErrors: isPageSubmitted
        },
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        membershipContainer: {
            visible: showMembershipBlock
        },
        membershipID: {
            onBlur: (_, { value, beforeValue }) => {
                if (value === beforeValue) { return; }

                const membershipNumber = _get(additionalInfoVM, 'lobData.personalAuto_EA.membership.membershipNumber.value');

                if (membershipNumber === undefined || membershipNumber?.length !== VALID_MEMBERSHIP_ID_LENGTH) {
                    setMembershipIdValidationMessage([translator(connectMessages.membershipIdValidation)]);
                    setMembershipVerificationMessage('');
                } else {
                    setMembershipIdValidationMessage([]);
                    validateMembershipId();
                }
            },
            validationMessages: membershipIdValidationMessage
        },
        membershipVerificationMessage: {
            message: membershipVerificationMessage
        },
        membershipVerificationMessageDiv: {
            visible: membershipVerificationMessage.length !== 0
        },
        membershipLevel: {
            path: 'lobData.personalAuto_EA.membership.membershipLevel',
            onBlur: (_, { value, beforeValue }) => {
                if (value && (!beforeValue || value.code !== beforeValue?.code)) {
                    validateMembershipId();
                }
            },
        },
        ...pageUpdateOverrides(),
        ...generateVisibilityOverrides()
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
            metadata.pageContent,
            additionalInfoVM,
            id,
            path,
            overrideProps
        ),
        [additionalInfoVM, overrideProps]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            onValueChange: writeValue
        }
    };

    return (
        <WizardPage
            isLoadingWholePage={isQuoting}
            onNext={onNext}
            showOnSave={false}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            hideSidebar
            finish
            showCancel={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={additionalInfoVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

AdditionalInformationPage.propTypes = wizardProps;

export default AdditionalInformationPage;
