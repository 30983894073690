import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import metadata from './PriorAddressComponent.metadata.json5';
import './PriorAddressComponent.messages';

function PriorAddressComponent(props) {
    const {
        data: priorAddressVM,
        labelPosition,
        submissionVM,
        showOptional,
        path,
        id,
        requiredFields,
        showMostRecentCheckbox,
        onValidate,
        onValueChange,
        updateWizardData,
        viewOnlyMode,
        showErrors
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [priorAddressVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );


    const handleMostRecent = useCallback(() => {
        const { lob } = props;
        let priorAddressListPath;

        if (lob) {
            priorAddressListPath = `lobData.${lob}.primaryNamedInsured.priorAddresses.value`;
        } else {
            priorAddressListPath = 'lobData.homeowners_EH.primaryNamedInsured.priorAddresses.value';
        }

        const currentPriorAddressPath = path;
        const selectedPriorAddress = _.get(submissionVM, `${currentPriorAddressPath}.value`);
        const priorAddressList = _.get(submissionVM, priorAddressListPath);
        let previousSelected;

        if (selectedPriorAddress.priorAddress.priorAddressIndex !== undefined) {
            previousSelected = priorAddressList.find(
                (address) => address.mostRecentPriorAddress
                    && address.priorAddress.priorAddressIndex !== selectedPriorAddress
                        .priorAddress.priorAddressIndex
            );
        } else {
            previousSelected = priorAddressList.find(
                (address) => address.mostRecentPriorAddress
                    && address.fixedId !== selectedPriorAddress.fixedId
            );
        }

        if (previousSelected) {
            _.set(previousSelected, 'mostRecentPriorAddress', false);
        }

        _.set(selectedPriorAddress, 'mostRecentPriorAddress', true);
        updateWizardData(submissionVM);
    }, [path, props, submissionVM, updateWizardData]);

    const requiredFieldsOverrides = useCallback(() => {
        const overrideProps = {};

        if (requiredFields) {
            requiredFields.forEach((element) => {
                overrideProps[`${element}`] = {
                    required: true
                };
            });
        }

        return overrideProps;
    }, [requiredFields]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            showRequired: true,
            readOnly: viewOnlyMode,
            showErrors,
            autoComplete: false
        },
        mostRecentCheckbox: {
            visible: showMostRecentCheckbox,
            onValueChange: handleMostRecent
        },
        ...requiredFieldsOverrides()
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                priorAddressVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [priorAddressVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={priorAddressVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
        />
    );
}

PriorAddressComponent.propTypes = {
    requiredFields: PropTypes.shape([]),
    data: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    showMostRecentCheckbox: PropTypes.bool,
    submissionVM: PropTypes.shape({}).isRequired,
    id: PropTypes.string,
    updateWizardData: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    showErrors: PropTypes.bool
};
PriorAddressComponent.defaultProps = {
    requiredFields: [],
    data: {},
    showMostRecentCheckbox: true,
    labelPosition: 'top', // I want labels on top by default
    path: undefined,
    id: undefined,
    showOptional: false,
    viewOnlyMode: false,
    showErrors: false
};
export default PriorAddressComponent;
