/* eslint-disable react/style-prop-object */
import React from 'react';
import _ from 'lodash';
import { FormattedNumber } from 'react-intl';
import PropTypes from 'prop-types';

function SymbolCurrencyComponent(props) {
    const {
        value: currencyValue // eg., {currency: 'usd', amount: 211.30}
    } = props;

    return (_.isUndefined(currencyValue) ? null : (
        <FormattedNumber
            value={currencyValue.amount}
            style="currency"
            currencyDisplay="symbol"
            currency={currencyValue.currency}
        />
    )
    );
}

SymbolCurrencyComponent.propTypes = {
    value: PropTypes.shape({}),
};
SymbolCurrencyComponent.defaultProps = {
    value: undefined
};
export default SymbolCurrencyComponent;
