import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('signature'), method, data, additionalHeaders);
}

export default class SignatureService {
    static signPolicy(signatureRequestDTO, additionalHeaders = {}) {
        return processSubmission('signPolicy', [signatureRequestDTO], additionalHeaders);
    }

    static getSignatureRoles(quoteID, signatureType, additionalHeaders = {}) {
        return processSubmission('getSignatureRoles', [quoteID, signatureType], additionalHeaders);
    }

    static cancelSignature(signatureRequestDTO, additionalHeaders = {}) {
        return processSubmission('cancelSignature', [signatureRequestDTO], additionalHeaders);
    }

    static completeInPersonSignature(signatureRequestDTO, additionalHeaders = {}) {
        return processSubmission('completeInPersonSignature', [signatureRequestDTO], additionalHeaders);
    }

    static getSigningURLForJob(jobNumber, additionalHeaders = {}) {
        return processSubmission('getSigningURLForJob', [jobNumber], additionalHeaders);
    }
}
