import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

const coverables = {
    prefill: {
        drivers: [],
        vehicles: [],
        construction: [],
        yourHome: []
    }
};

export default class PrefillService {
    /**
     * Selects a quote
     * @param {Object} submissionDTO QuoteDataDTO
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static loadPrefill = (submissionDTO, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('prefill'),
            'orderPrefill',
            [submissionDTO],
            additionalHeaders
        );
    }

    /**
       * Get Replacement cost
       *
       * @param {Object} submissionDTO the submission
       * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
       * @returns {Promise}
       */
    static getReplacementCost = (submissionDTO, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('prefill'),
            'orderValuation',
            [submissionDTO],
            additionalHeaders
        );
    }

    static orderReplacementCost = (jobID, lobDTO, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('prefill'),
            'orderReplacementCost',
            [jobID, lobDTO],
            additionalHeaders
        );
    }
}
