import React, { Component } from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { TranslatorContext, withIntl } from '@jutro/locale';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { AccountService } from 'e1p-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { CustomizedHeaderComponent, FieldLinkComponent } from 'gw-components-platform-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { SelectProducerCode, withProducerContext } from 'gw-gateway-common-react';
import DashboardAccountSearchComponent from '../Components/DashboardAccountSearchComponent/DashboardAccountSearchComponent';
import metadata from './Accounts.metadata.json5';
import styles from './Accounts.module.scss';
import '../Policies/Policies.messages';
import messages from './Accounts.messages';

const { gatewayParamConfig = {} } = appConfig;

class Accounts extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        match: PropTypes.shape({
            path: PropTypes.string
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        intl: PropTypes.func.isRequired,
        children: PropTypes.shape({}).isRequired,
        authUserData: PropTypes.shape({ publicID: PropTypes.string }).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            currentView: 'recentlyViewedTile',
            selectedProducerCode: 'all',
            selectedHeaderContent: messages.RecentlyViewed,
            accountSearchResults: undefined,
            isPerformingAccountSearch: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { producerCode } = props;
        const { selectedProducerCode } = state;

        if (producerCode !== selectedProducerCode) {
            return {
                selectedProducerCode: producerCode
            };
        }

        return null;
    }

    getQueryParam = (selectedProducerCode) => {
        const { authUserData } = this.props;

        if (selectedProducerCode === 'myWork') {
            return [
                {
                    propertyName: 'CreateUser',
                    entityName: 'User',
                    entityPublicId: authUserData.publicID,
                    isDbProperty: true,
                    orOperation: false
                }
            ];
        }

        if (selectedProducerCode !== 'all') {
            return selectedProducerCode;
        }

        return null;
    };

    processResponseData = (accountData) => {
        const { intl } = this.props;
        const accountArray = accountData.map((accountInfo) => {
            // Search result from our api mapped the dto differently
            const isPersonal = accountInfo.isPersonalAccount || accountInfo.accountHolder.person;
            const account = {
                type: isPersonal ? 'Personal' : 'Commercial',
                // Search result from our api mapped the dto differently
                insuredName: accountInfo.accountHolder?.person?.displayName
                    || accountInfo.accountHolder,
                policy: accountInfo.policySummaries.length,
                dateCreated: intl.formatDate(new Date(accountInfo.accountCreatedDate), { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }),
                // Search result from our api mapped the dto differently
                address: accountInfo?.accountHolderAddress?.displayName
                    || accountInfo.accountHolder.person.primaryAddress.displayName,
                openActivities: accountInfo.numberOfOpenActivities,
                accountNumber: accountInfo.accountNumber
            };

            return account;
        });

        return accountArray;
    };

    getCell = (items, index, { id: property }) => {
        const translator = this.context;
        let toolTipMessage = '';

        switch (property) {
            case 'dateCreated':
                toolTipMessage = translator(messages.DateCreated);
                break;
            case 'address':
                toolTipMessage = translator(messages.Address);
                break;
            default:
                toolTipMessage = '';
        }

        return <span title={toolTipMessage}>{items[property]}</span>;
    };

    getAccount = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = translator(messages.Account);
        const icon = item.type === 'Personal' ? 'user' : 'building';

        return (
            <FieldLinkComponent
                id={`account${item[property]}`}
                value={item[property]}
                icon={icon}
                to={`/accounts/${item.accountNumber}/summary`}
                title={toolTipMessage}
            />
        );
    };

    getPolicyLink = (item, index, { id: property }) => {
        const translator = this.context;
        const toolTipMessage = property === 'policy' ? messages.Policies : messages.OpenActivities;
        const redirectPath = property === 'policy' ? 'summary' : 'activities';

        return (
            <FieldLinkComponent
                id={`policy${item[property]}`}
                value={item[property]}
                to={{
                    pathname: `/accounts/${item.accountNumber}/${redirectPath}`,
                    state: redirectPath
                }}
                title={translator(toolTipMessage)}
            />
        );
    };

    handleValueChange = (producerCode) => {
        this.setState({
            selectedProducerCode: producerCode
        });
    };

    handleTilesOnClick = (id) => {
        let tableHeading = messages.RecentlyViewed;

        switch (id) {
            case 'recentlyCreatedTile':
                tableHeading = messages.RecentlyCreated;
                break;
            case 'accountSearchTile':
                tableHeading = messages.SearchResults;
                break;
            default:
                tableHeading = messages.RecentlyViewed;
                break;
        }

        this.setState({
            currentView: id,
            accountSearchResults: [],
            selectedHeaderContent: tableHeading
        });
    };

    recentView = (paginationParams) => {
        const { authHeader } = this.props;

        return AccountService.getRecentlyViewedAccountsWithPagination(paginationParams, authHeader)
            .then((response) => {
                this.setState({
                    selectedHeaderContent: messages.RecentlyViewed
                });

                const data = this.processResponseData(response.accounts);

                return {
                    rows: data,
                    numberOfRows: response.maxNumberOfResults
                };
            });
    };

    passingProducerCode = (paramProducerCode) => {
        const isProducerCodeNotSpecific = ['all', 'myWork'].indexOf(paramProducerCode) > -1;

        return isProducerCodeNotSpecific || _.isNull(paramProducerCode);
    };

    recentCreated = (paginationParams, paramProducerCode) => {
        const { authHeader } = this.props;

        const queryParam = this.getQueryParam(paramProducerCode);
        let accountsPromise = null;

        if (this.passingProducerCode(paramProducerCode)) {
            accountsPromise = AccountService.getAccountsForCurrentUser(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        } else {
            accountsPromise = AccountService.getAccountsForProducerCode(
                gatewayParamConfig.accountsCreatedInLastXDays,
                paginationParams,
                queryParam,
                authHeader
            );
        }

        return accountsPromise.then((response) => {
            this.setState({
                selectedHeaderContent: messages.RecentlyCreated
            });

            const data = this.processResponseData(response.accounts);

            return {
                rows: data,
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    loadData = ({ sorted, pageSize, page }) => {
        const { currentView, selectedProducerCode } = this.state;
        const paramProducerCode = selectedProducerCode === 'all' ? null : selectedProducerCode;
        let tile = 'recentlyViewedTile';

        if (currentView) {
            tile = currentView;
        }

        // user can only sort by either account name or by createdDate
        // for backend to sort it properly we need to send DTO field name
        let orderBy = 'accountHolder';

        if (_.get(sorted, '0.id') === 'dateCreated') {
            orderBy = 'accountCreatedDate';
        }

        const startIndex = pageSize * page;
        const offsetEndMinusOne = startIndex + pageSize - 1;
        const paginationParams = {
            offsetStart: startIndex,
            offsetEnd: offsetEndMinusOne,
            orderBy,
            orderByDescending: _.get(sorted, '0.desc', true)
        };

        if (tile === 'recentlyViewedTile') {
            return this.recentView(paginationParams);
        }

        if (tile === 'recentlyCreatedTile') {
            return this.recentCreated(paginationParams, paramProducerCode);
        }

        return { rows: [], numberOfRows: 0 };
    };

    render() {
        const translator = this.context;
        const {
            selectedHeaderContent, currentView, selectedProducerCode, accountSearchResults,
            isPerformingAccountSearch
        } = this.state;
        const tableKey = currentView + selectedProducerCode;
        const overrides = {
            AccountSearchID: {
                visible: currentView === 'accountSearchTile',
                // callback to load search results from child component
                renderSearchResults: (data) => {
                    const processedData = this.processResponseData(data);

                    this.setState({
                        accountSearchResults: processedData || [],
                        selectedHeaderContent: messages.SearchResults
                    });
                },
                // callback to set the loader from the component
                setIsPerformingAccountSearch: (isLoading) => {
                    this.setState({
                        isPerformingAccountSearch: isLoading
                    });
                },
                isPerformingAccountSearch
            },
            accountSearchLoadingIndicator: {
                loaded: !isPerformingAccountSearch
            },
            recentlyViewedTableGrid: {
                key: tableKey,
                defaultSorted: [
                    { id: 'dateCreated', desc: true }
                ],
            },
            RecentlyViewedId: {
                title: translator(selectedHeaderContent),
            },
            [currentView]: {
                active: true
            },
            openActivitiesColumnTitle: {
                visible: currentView !== 'accountSearchTile'
            }

        };

        /**
         * dynamically setting DataTable as async or simple
         * for account search it will be simple datatable
         * and for other it will be async
         * */
        if (currentView === 'accountSearchTile') {
            _.unset(overrides.recentlyViewedTableGrid, 'onFetchData');
            overrides.recentlyViewedTableGrid.data = accountSearchResults;
            overrides.recentlyViewedTableGrid.localSorting = true;
        } else {
            _.unset(overrides.recentlyViewedTableGrid, 'data');
            overrides.recentlyViewedTableGrid.onFetchData = this.loadData;
            overrides.recentlyViewedTableGrid.localSorting = false;
        }

        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: this.getCell,
                getAccount: this.getAccount,
                getPolicyLink: this.getPolicyLink,
                handleTilesOnClick: this.handleTilesOnClick,
                handleValueChange: this.handleValueChange,
                sortNumber: DatatableUtil.sortNumber,
                sortString: DatatableUtil.sortString,
                sortDate: DatatableUtil.sortDate
            },
            resolveComponentMap: {
                customizedheadercomponent: CustomizedHeaderComponent,
                selectproducercode: SelectProducerCode,
                dashboardaccountsearchcomponent: DashboardAccountSearchComponent
            }
        };

        const accountsLanding = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;

        return <div className={cx(styles.account)}>{accountsLanding}</div>;
    }
}

export const AccountsComponent = Accounts;
export default withIntl(withAuthenticationContext(withProducerContext(Accounts)));
