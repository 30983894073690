import { defineMessages } from 'react-intl';

export default defineMessages({
    policies: {
        id: 'e1p.policies.dashboard.Policies',
        defaultMessage: 'Policies',
    },
    quotesTile: {
        id: 'e1p.policies.dashboard.Quotes',
        defaultMessage: 'Quotes',
    },
    policyChangesTile: {
        id: 'e1p.policies.dashboard.Policy Changes',
        defaultMessage: 'Policy Changes',
    },
    cancellationsTitle: {
        id: 'e1p.policies.dashboard.Cancellations',
        defaultMessage: 'Cancellations',
    },
    renewalsTitle: {
        id: 'e1p.policies.dashboard.Renewals',
        defaultMessage: 'Renewals',
    },
    rewritesTitle: {
        id: 'e1p.policies.dashboard.Rewrites',
        defaultMessage: 'Rewrites',
    },
    allPolicies: {
        id: 'e1p.policies.dashboard.All Policies',
        defaultMessage: 'All Policies',
    },
    last100Viewed: {
        id: 'e1p.policies.dashboard.Last 100 Policies Viewed',
        defaultMessage: 'Last 100 Policies Viewed',
    }
});
