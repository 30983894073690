import {
    get as _get,
    find as _find,
    isEmpty as _isEmpty,
    isUndefined as _isUndefined,
    set as _set
} from 'lodash';

import messages from '../../modules-react/e1p-platform-translations/lob/eh/ehCommon.messages';

const SECTION_STATUS = {
    SUCCESS: 'success',
    FAILURE: 'failure',
    NONE: 'none'
};

/**
 * E1PAP1PC-13604:
 * If coverage A limit is greater than 750000 and NO central fire alarm
 * and NO central burglar alarm is selected as protective device,
 * then returns true so that we will show info message
 * @param {Object} transactionVM transactionVM data for particular transaction
 * @returns {Boolean} returns boolean
 */
const isProtectiveDeviceRequiredMessageVisible = (
    transactionVM
) => {
    const policyType = _get(transactionVM,
        'value.lobData.homeowners_EH.policyType',
        _get(transactionVM, 'value.policyType'));

    if (policyType === 'HO3' || policyType === 'HF9') {
        const coverageA = _find(_get(transactionVM, 'value.lobData.homeowners_EH.offerings[0].coverages.coverages'),
            (coverage) => { return coverage.codeIdentifier === 'EH_DwellingCovA'; });
        const covALimit = coverageA?.terms[0]?.chosenTerm;
        if (covALimit > 750000) {
            const protectiveDevices = _get(
                transactionVM,
                'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value',
                []
            );
            const discountsList = _get(transactionVM, 'lobData.homeowners_EH.modifiers.value');
            const hasSecureHomeDisc = discountsList.find((discount) => discount.patternCode === 'EH_SecureHomeDisc');
            if (!hasSecureHomeDisc) {
                const centralFireAlarm = _find(
                    protectiveDevices,
                    (protectiveDevice) => { return protectiveDevice.deviceType === 'centralfirealarm'; }
                );
                const burglarAlarm = _find(
                    protectiveDevices,
                    (protectiveDevice) => { return protectiveDevice.deviceType === 'centralburglaryalarm'; }
                );
                if (!centralFireAlarm || !burglarAlarm) {
                    return true;
                }
            } else {
                const connectedAndProfessionallyCentralFireAlarm = _find(
                    protectiveDevices,
                    (protectiveDevice) => { return protectiveDevice.deviceType === 'connectedandprofessionallymonitoredfirealarm'; }
                );
                const professionallyCentralFireAlarm = _find(
                    protectiveDevices,
                    (protectiveDevice) => { return protectiveDevice.deviceType === 'professionallymonitoredfirealarm'; }
                );
                const connectedAndProfessionallyBurglarAlarm = _find(
                    protectiveDevices,
                    (protectiveDevice) => { return protectiveDevice.deviceType === 'connectedandprofessionallymonitoredburglaralarm'; }
                );
                const professionallyCentralBurglarAlarm = _find(
                    protectiveDevices,
                    (protectiveDevice) => { return protectiveDevice.deviceType === 'professionallymonitoredburglaralarm'; }
                );
                if (
                    (!professionallyCentralFireAlarm
                        && !connectedAndProfessionallyCentralFireAlarm)
                    || (!professionallyCentralBurglarAlarm
                        && !connectedAndProfessionallyBurglarAlarm)
                ) {
                    return true;
                }
            }
        }
    }
    return false;
};

/**
 * @param {Object} transactionVM
 * @param {function} translator
 * @returns {String} returns info message based out of state.
 */
function contentBasedOnState(
    transactionVM, translator
) {
    // We will use the available discounts to decide which message makes sense
    const discountsList = _get(transactionVM, 'lobData.homeowners_EH.modifiers.value');
    const hasSecureHomeDisc = discountsList.find((discount) => discount.patternCode === 'EH_SecureHomeDisc');
    if (hasSecureHomeDisc) {
        return translator(messages.secureHomeDeviceTypesRequiredInfoMessageBasedOnCovA);
    }
    return translator(messages.safetyFirstDeviceTypesRequiredInfoMessageBasedOnCovA);
}

/**
 * @param {Object} submissionVM
 * @param {Number} currentDateYear
 * @param {Number} minYearBuilt
 * @returns {String} returns success if property section is complete
 */
const getPropertyInformationStatus = (submissionVM, currentDateYear, minYearBuilt) => {
    const yearBuilt = parseInt(
        submissionVM.lobData.homeowners_EH.coverables.construction.yearBuilt.value,
        10
    );
    const hasValidYearBuilt = (currentDateYear >= yearBuilt) && (yearBuilt >= minYearBuilt);

    let propertyInformationStatus = SECTION_STATUS.NONE;
    if (hasValidYearBuilt
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .styleOfHome.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .styleOfHome.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .totalSquareFeet.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .totalSquareFeet.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .numberOfStories.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .numberOfStories.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.yourHome
            .numberOfUnits.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.yourHome
            .numberOfUnits.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.yourHome
            .totalNumberOfPeopleLiving.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.yourHome
            .totalNumberOfPeopleLiving.value !== '') {
        propertyInformationStatus = SECTION_STATUS.SUCCESS;
    }
    return propertyInformationStatus;
};

/**
 * @param {Object} submissionVM
 * @param {String} heatingSystemType
 * @param {String} coolingSystemType
 * @returns {String} returns success if heating/cooling section info is complete
 */
const getHvacStatus = (submissionVM, heatingSystemType, coolingSystemType) => {
    const areFireplaceFieldsIncomplete = submissionVM.lobData.homeowners_EH.coverables
        .construction.firePlaces.value
        .some((firePlace) => {
            return !firePlace.heatingUnitType;
        });
    let hvacStatus = SECTION_STATUS.NONE;
    if (submissionVM.lobData.homeowners_EH.coverables.construction
        .hasWoodOrCoalStove.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .hasWoodOrCoalStove.value !== ''
        && heatingSystemType !== undefined
        && coolingSystemType !== undefined
        && !areFireplaceFieldsIncomplete) {
        hvacStatus = SECTION_STATUS.SUCCESS;
    }
    return hvacStatus;
};

/**
 * @param {Object} submissionVM
 * @returns {String} returns success if basement section info is complete
 */
const getBasementStatus = (submissionVM) => {
    let basementStatus = SECTION_STATUS.NONE;
    if (submissionVM.lobData.homeowners_EH.coverables.construction
        .foundationType.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .foundationType.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .basementFinishedAreaType.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .basementFinishedAreaType.value !== '') {
        basementStatus = SECTION_STATUS.SUCCESS;
    }
    return basementStatus;
};

/**
 *
 * @param {Object} submissionVM
 * @param {Boolean} creditScoreMoreThan597
 * @param {Boolean} showRoofMessage
 * @returns {String} returns success if exterior section info is complete
 */
const getExteriorStatus = (
    submissionVM, creditScoreMoreThan597, showRoofMessage
) => {
    const areGarageFieldsIncomplete = submissionVM.lobData.homeowners_EH
        .coverables.construction.garages.value
        .some((garage) => {
            if (garage.garageType.toLocaleLowerCase() === 'none') {
                return false;
            }
            return !garage.garageSize || !garage.garageType;
        });
    let exteriorStatus = SECTION_STATUS.NONE;
    if (submissionVM.lobData.homeowners_EH.coverables.construction
        .exteriorWallFinish.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .exteriorWallFinish.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .yearRoofInstalled.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .yearRoofInstalled.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .roofShape.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .roofShape.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .roofType.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .roofType.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .slopeType.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .slopeType.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.yourHome
            .doesYourHomeHaveFence.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.yourHome
            .doesYourHomeHaveFence.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .hasSwimmingPool.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .hasSwimmingPool.value !== ''
        && ((submissionVM.lobData.homeowners_EH.coverables.construction
            .hasSwimmingPool.value === true
            && submissionVM.lobData.homeowners_EH.coverables.construction
                .hasSwimmingPoolFence.value)
            || submissionVM.lobData.homeowners_EH.coverables.construction
                .hasSwimmingPool.value === false
            || creditScoreMoreThan597)
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .garages.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .garages.value.length > 0
        && !areGarageFieldsIncomplete
        && submissionVM.lobData.homeowners_EH.coverables.construction.garages.value.length > 0
        && !showRoofMessage) {
        exteriorStatus = SECTION_STATUS.SUCCESS;
    }
    return exteriorStatus;
};

/**
 * @param {Object} submissionVM
 * @returns {String} returns success if animal section info is complete
 */
const getAnimalStatus = (submissionVM) => {
    let animalsStatus = SECTION_STATUS.NONE;

    const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code') || _get(submissionVM, 'policyAddress.state.value.code');
    const isHighRiskDogBreedRequired = !(['IL'].includes(policyState));
    const isHightRiskDogBreedAnswered = submissionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value !== undefined
    && submissionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value !== '';
    const isAnimalBittenAnswered = submissionVM.lobData.homeowners_EH.coverables.yourHome.hasAnimalBitten.value !== undefined
    && submissionVM.lobData.homeowners_EH.coverables.yourHome.hasAnimalBitten.value !== '';
    // dogInHome is false
    if (submissionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === false) {
        animalsStatus = SECTION_STATUS.SUCCESS;
    }
    // dogInHome is true
    else if (submissionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === true) {
        // highRiskdog and animalBitten is visible
        if (isHighRiskDogBreedRequired && isHightRiskDogBreedAnswered && isAnimalBittenAnswered) {
            animalsStatus = SECTION_STATUS.SUCCESS;
        }
        // highRiskdog is not visible and animalBitten is visible
        else if (!isHighRiskDogBreedRequired && isAnimalBittenAnswered) {
            animalsStatus = SECTION_STATUS.SUCCESS;
        }
    }
    return animalsStatus;
};

const getInteriorStatus = (submissionVM) => {
    const interiorWalls = _get(
        submissionVM, 'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value', []
    );
    const floors = _get(
        submissionVM, 'lobData.homeowners_EH.coverables.construction.floors.value', []
    );

    const insideWallMaterialPercentageSumInValid = (submissionVM.lobData.homeowners_EH
        .coverables.construction.insideWallMaterials.value.reduce((sum, el) => {
            return sum + +el.percentage;
        }, 0) !== 100
        && submissionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.length > 0
    );

    const floorMaterialPercentageSumInValid = (submissionVM.lobData.homeowners_EH.coverables
        .construction.floors.value.reduce((sum, el) => {
            return sum + +el.percentage;
        }, 0) !== 100
        && submissionVM.lobData.homeowners_EH.coverables.construction.floors.length > 0
    );

    const areFloorFieldsIncomplete = submissionVM.lobData.homeowners_EH
        .coverables.construction.floors.value
        .some((floor) => {
            return !floor.floorType || !floor.percentage;
        });

    const areWallFieldsIncomplete = submissionVM.lobData.homeowners_EH.coverables
        .construction.insideWallMaterials.value
        .some((wall) => {
            return !wall.materialType || !wall.percentage;
        });

    let interiorStatus = SECTION_STATUS.NONE;
    if (submissionVM.lobData.homeowners_EH.coverables.construction
        .numberOfRoomsWithCathedralVaultedCeilings.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .numberOfRoomsWithCathedralVaultedCeilings.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .ceilingHeightType.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .ceilingHeightType.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .numberOfRoomsWithCrownMolding.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .numberOfRoomsWithCrownMolding.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .numberOfFullBaths.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .numberOfFullBaths.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .numberOfHalfBaths.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .numberOfHalfBaths.value !== ''
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .kitchenCounterTopType.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction
            .kitchenCounterTopType.value !== ''
        && (!insideWallMaterialPercentageSumInValid)
        && (!floorMaterialPercentageSumInValid)
        && interiorWalls.length > 0
        && floors.length > 0
        && !areWallFieldsIncomplete
        && !areFloorFieldsIncomplete) {
        interiorStatus = SECTION_STATUS.SUCCESS;
    }
    return interiorStatus;
};

const checkAllSectionsAreValidHO6 = (
    submissionVM, currentDateYear, minYearBuilt, heatingSystemType
) => {
    let propertyInformationStatus = SECTION_STATUS.NONE;
    const yearBuilt = parseInt(
        submissionVM.lobData.homeowners_EH.coverables.construction.yearBuilt.value,
        10
    );
    const hasValidYearBuilt = (currentDateYear >= yearBuilt) && (yearBuilt >= minYearBuilt);
    if (hasValidYearBuilt
        && submissionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== '') {
        propertyInformationStatus = SECTION_STATUS.SUCCESS;
    }

    let exteriorStatus = SECTION_STATUS.NONE;
    if (submissionVM.lobData.homeowners_EH.coverables
        .construction.exteriorWallFinish.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value !== '') {
        exteriorStatus = SECTION_STATUS.SUCCESS;
    }

    const animalStatus = getAnimalStatus(submissionVM);

    let heatingAndCoolingStatus = SECTION_STATUS.NONE;
    if (heatingSystemType !== undefined) {
        heatingAndCoolingStatus = SECTION_STATUS.SUCCESS;
    }
    return {
        propertyInformationStatus,
        exteriorStatus,
        animalStatus,
        heatingAndCoolingStatus
    };
};


/**
 * Below changes are for HO6
 * Purpose of this function is to check if all required fields are present on property page
 * @param {Object} submissionVM
 * @param {Number} currentDateYear
 * @param {Number} minYearBuilt
 * @param {String} heatingSystemType
 * @returns {Boolean}
 */
const checkAllRequiredFieldsArePresentHO6 = (
    submissionVM, currentDateYear, minYearBuilt,
    heatingSystemType
) => {
    const {
        propertyInformationStatus, exteriorStatus,
        animalStatus, heatingAndCoolingStatus
    } = checkAllSectionsAreValidHO6(submissionVM, currentDateYear, minYearBuilt, heatingSystemType);

    return (
        exteriorStatus === SECTION_STATUS.SUCCESS
        && animalStatus === SECTION_STATUS.SUCCESS
        && propertyInformationStatus === SECTION_STATUS.SUCCESS
        && heatingAndCoolingStatus === SECTION_STATUS.SUCCESS
    );
};

const checkAllSectionsAreValidHO3 = (
    submissionVM, creditScoreMoreThan597, showRoofMessage,
    currentDateYear, minYearBuilt, heatingSystemType, coolingSystemType
) => {
    const interiorStatus = getInteriorStatus(submissionVM);

    const exteriorStatus = getExteriorStatus(
        submissionVM,
        creditScoreMoreThan597,
        showRoofMessage
    );

    const basementStatus = getBasementStatus(submissionVM);

    let animalsStatus = SECTION_STATUS.NONE;
    const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code') || _get(submissionVM, 'policyAddress.state.value.code');
    const isHighRiskDogBreedRequired = !(['IL'].includes(policyState));
    const isHightRiskDogBreedAnswered = submissionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value !== undefined
    && submissionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value !== '';
    // dogInHome is false
    if (submissionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === false) {
        animalsStatus = SECTION_STATUS.SUCCESS;
    }
    // dogInHome is true
    else if (submissionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === true) {
        // highRiskdog is visible
        if (isHighRiskDogBreedRequired && isHightRiskDogBreedAnswered) {
            animalsStatus = SECTION_STATUS.SUCCESS;
        }
        // highRiskdog is not visible and hasDogInHome is true
        else if (!isHighRiskDogBreedRequired) {
            animalsStatus = SECTION_STATUS.SUCCESS;
        }
    }

    const propertyInformationStatus = getPropertyInformationStatus(
        submissionVM,
        currentDateYear,
        minYearBuilt
    );

    const hvacStatus = getHvacStatus(
        submissionVM,
        heatingSystemType,
        coolingSystemType
    );
    return {
        interiorStatus,
        exteriorStatus,
        basementStatus,
        animalsStatus,
        propertyInformationStatus,
        hvacStatus
    };
};

/**
 * Below changes are for HO3
 * Purpose of this function is to check if all required fields are present on property page
 * We are using this util function as useValidation does not trigger at this point
 * @param {Object} submissionVM
 * @param {Boolean} creditScoreMoreThan597
 * @param {Number} currentDateYear
 * @param {Number} minYearBuilt
 * @param {String} heatingSystemType
 * @param {String} coolingSystemType
 * @param {Boolean} showRoofMessage
 * @returns {Boolean}
 */
const checkAllRequiredFieldsArePresentHO3 = (
    submissionVM, creditScoreMoreThan597, currentDateYear, minYearBuilt,
    heatingSystemType, coolingSystemType, showRoofMessage
) => {
    const {
        interiorStatus, exteriorStatus, basementStatus,
        animalsStatus, propertyInformationStatus, hvacStatus
    } = checkAllSectionsAreValidHO3(
        submissionVM, creditScoreMoreThan597, showRoofMessage,
        currentDateYear, minYearBuilt, heatingSystemType, coolingSystemType
    );
    return (
        interiorStatus === SECTION_STATUS.SUCCESS
        && exteriorStatus === SECTION_STATUS.SUCCESS
        && basementStatus === SECTION_STATUS.SUCCESS
        && animalsStatus === SECTION_STATUS.SUCCESS
        && propertyInformationStatus === SECTION_STATUS.SUCCESS
        && hvacStatus === SECTION_STATUS.SUCCESS
    );
};

const checkAllSectionsAreValidHO4 = (submissionVM) => {
    let propertyInformationStatus = SECTION_STATUS.NONE;
    if (submissionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== undefined
        && submissionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== '') {
        propertyInformationStatus = SECTION_STATUS.SUCCESS;
    }

    const animalStatus = getAnimalStatus(submissionVM);
    return { propertyInformationStatus, animalStatus };
};

/**
 * Below changes are for HO4
 * Purpose of this function is to check if all required fields are present on property page
 * @param {Object} submissionVM
 * @param {Function} setExpandedSections
 * @returns {Boolean}
 */
const checkAllRequiredFieldsArePresentHO4 = (submissionVM) => {
    const {
        propertyInformationStatus,
        animalStatus
    } = checkAllSectionsAreValidHO4(submissionVM);

    return (
        propertyInformationStatus === SECTION_STATUS.SUCCESS
        && animalStatus === SECTION_STATUS.SUCCESS
    );
};

const checkAllSectionsAreValidHF9 = (
    submissionVM, creditScoreMoreThan597, showRoofMessage,
    currentDateYear, minYearBuilt, heatingSystemType, coolingSystemType
) => {
    const interiorStatus = getInteriorStatus(submissionVM);

    const exteriorStatus = getExteriorStatus(
        submissionVM,
        creditScoreMoreThan597,
        showRoofMessage
    );

    const basementStatus = getBasementStatus(submissionVM);

    const propertyInformationStatus = getPropertyInformationStatus(
        submissionVM,
        currentDateYear,
        minYearBuilt
    );

    const hvacStatus = getHvacStatus(
        submissionVM,
        heatingSystemType,
        coolingSystemType
    );
    return {
        interiorStatus,
        exteriorStatus,
        basementStatus,
        propertyInformationStatus,
        hvacStatus
    };
};

/**
 * Below changes are for HF9
 * Purpose of this function is to check if all required fields are present on property page
 * We are using this util function as useValidation does not trigger at this point
 * @param {Object} submissionVM
 * @param {Boolean} creditScoreMoreThan597
 * @param {Boolean} showRoofMessage
 * @param {Number} currentDateYear
 * @param {Number} minYearBuilt
 * @param {String} heatingSystemType
 * @param {String} coolingSystemType
 * @returns {Boolean}
 */
const checkAllRequiredFieldsArePresentHF9 = (
    submissionVM, creditScoreMoreThan597, showRoofMessage, currentDateYear,
    minYearBuilt, heatingSystemType, coolingSystemType
) => {
    const {
        interiorStatus, exteriorStatus,
        basementStatus, propertyInformationStatus, hvacStatus
    } = checkAllSectionsAreValidHF9(
        submissionVM, creditScoreMoreThan597, showRoofMessage,
        currentDateYear, minYearBuilt, heatingSystemType, coolingSystemType
    );
    return (
        interiorStatus === SECTION_STATUS.SUCCESS
        && exteriorStatus === SECTION_STATUS.SUCCESS
        && basementStatus === SECTION_STATUS.SUCCESS
        && propertyInformationStatus === SECTION_STATUS.SUCCESS
        && hvacStatus === SECTION_STATUS.SUCCESS
    );
};

const checkIfPrefillIsSuccessful = (prefillResponse) => {
    // Assuming that prefill will always return year built, foundation type,
    // or total square footage if its successful
    const yearBuilt = !!_get(prefillResponse, 'coverables.construction.yearBuilt');
    const totalSquareFootage = !!_get(prefillResponse, 'coverables.construction.totalSquareFeet');
    const foundationType = !!_get(prefillResponse, 'coverables.construction.foundationType');
    return (yearBuilt || totalSquareFootage || foundationType);
};

/**
 * Helper function to check if windstorm mitigation discount info message visible or not
 * If policy State is 'NC'
 * and policy type is 'HO3'
 * and windstorm mitigation feature is present
 * and 'EH_AbsoluteWindHailExclusion' exclusion is selected then we show info message
 * 
 * @param {Object} transactionVM 
 * @returns {Boolean} boolean variable to indicate if info message should be visible or not
 */
const isWindstormMitigationDiscountInfoMessagePresent = (transactionVM) => {
    const isPolicyStateNC = _get(
        transactionVM,
        'baseData.policyAddress.state.value.code',
        _get(
            transactionVM,
            'policyAddress.state.value.code',
        )
    ) === 'NC';
    const offerings = _get(transactionVM, 'lobData.homeowners_EH.offerings.value', []);
    let selectedOffering = offerings[0];
    if (offerings.length > 1) {
        const offeredQuotes = _get(transactionVM, 'quoteData.offeredQuotes.value', []);
        const selectedQuoteBranchCode = offeredQuotes?.find((quote) => quote.selected)?.branchCode;
        if (selectedQuoteBranchCode) {
            selectedOffering = offerings.find((offering) => offering.branchCode === selectedQuoteBranchCode)
        }
    }
    const isRatingPlanNCRB = _get(selectedOffering, 'ratePlans', []).find((ratePlan) => ratePlan.ratePlanType === 'NCRB' && ratePlan.isSelected);
    const windstormMitigationFeatures = _get(
        transactionVM,
        'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
        []
    );
    const isWindstormMitigationPresent = !_isEmpty(windstormMitigationFeatures);
    const isAbsoluteWindHailExclusionPresent = _get(selectedOffering, 'exclusions', [])
        .find((exclusion) => exclusion.codeIdentifier === 'EH_AbsoluteWindHailExclusion' && exclusion.selected);

    const isHO3PolicyType = _get(
        transactionVM,
        'lobData.homeowners_EH.policyType.value.code',
        _get(transactionVM, 'value.policyType')) === 'HO3';

    return isPolicyStateNC && isHO3PolicyType && !!isRatingPlanNCRB
        && isWindstormMitigationPresent && !!isAbsoluteWindHailExclusionPresent;
}

/**
 * Helper function to add 'totalHipRoof' as mitigation feature when user selects 'hip' as roof shape type
 * IF user mistakenly selects roof shape as 'hip' and again changes it to another value,
 * we will remove 'totalHipRoof' from mitigation feature
 * @param {Function} viewModelService 
 * @param {Object} transactionVM 
 * @param {String} mitigationFeatureType 
 * @param {String} roofShapeType 
 */
const addOrRemoveTotalHipRoofWindstormMitigationFeature = (
    viewModelService, transactionVM,
    mitigationFeatureType, roofShapeType) => {
    const isPolicyStateNC = _get(
        transactionVM,
        'baseData.policyAddress.state.value.code'
    ) === 'NC';

    const isHO3PolicyType = _get(
        transactionVM,
        'lobData.homeowners_EH.policyType.value.code',
        _get(transactionVM, 'value.policyType')) === 'HO3';

    if (!isHO3PolicyType || !isPolicyStateNC) {
        return;
    }
    const {
        _xCenter,
        _dtoName
    } = transactionVM.lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures;
    const mitigationFeature = {
        featureType: mitigationFeatureType
    }
    const windstormMitigationFeatureVM = viewModelService.create(mitigationFeature, _xCenter, _dtoName);
    const features = _get(
        transactionVM,
        'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
        []
    );

    const isGivenMitigationPresent = !_isUndefined(features
        .find((feature) => feature.featureType === mitigationFeatureType)
    );

    if (
        transactionVM.lobData.homeowners_EH.coverables.construction
            .windstormMitigationFeatures.value === undefined
    ) {
        _set(
            transactionVM,
            'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
            []
        );
    }
    /**
     * User mistakenly chooses 'hip' as roof shape type and if again changes to different value
     * then we will remove 'totalHipRoof' as mitigation feature type,
     * to validation above conditions we are checking that only 'totalHipRoof' mitigation feature
     * is present and its fixed id is undefined
     */

    if (roofShapeType !== 'hip') {
        if (features.length === 1 && isGivenMitigationPresent && _isUndefined(features[0].fixedId)) {
            transactionVM.lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value = [];
        }
    }
    if (!isGivenMitigationPresent && roofShapeType === 'hip') {
        transactionVM.lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value.push(
            windstormMitigationFeatureVM.value
        );
    }
}

/**
 * Helper function to check if policy has any additional interest with convertToTPI indicator as true
 * @param {Object} transactionVM 
 * @returns {Boolean}
 */
const shouldConvertTPIToPayer = (transactionVM) => {
    const additionalInterests = _get(
        transactionVM,
        'value.lobData.homeowners_EH.coverables.yourHome.additionalInterests',
        []
    );
    const shouldConvertTpi = additionalInterests
        .filter((tpi) => tpi.convertTPIToPayerInd === true)
        .length > 0;
    if (shouldConvertTpi) {
        return true;
    }
    return false;
}


export default {
    isProtectiveDeviceRequiredMessageVisible,
    contentBasedOnState,
    checkIfPrefillIsSuccessful,
    checkAllRequiredFieldsArePresentHO3,
    checkAllRequiredFieldsArePresentHF9,
    checkAllRequiredFieldsArePresentHO4,
    checkAllRequiredFieldsArePresentHO6,
    isWindstormMitigationDiscountInfoMessagePresent,
    addOrRemoveTotalHipRoofWindstormMitigationFeature,
    shouldConvertTPIToPayer
};
