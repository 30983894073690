import React, { useCallback, useState, useEffect } from 'react';
import {
    get as _get
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { TimelineService } from 'e1p-capability-journey';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from '../RiskAnalysisPage.metadata.json5';
import messages from '../RiskAnalysisPage.messages';

function RiskAnalysisPage(props) {
    const {
        wizardData: submissionVM, isSkipping, authUserData
    } = props;
    const fullQuoteType = _get(submissionVM, 'value.quoteType') === 'Full';
    const quoteID = _get(submissionVM, 'value.quoteID');
    const [geoDataResponse, setGeoDataResponse] = useState({});
    const [responseAuto, setResponseAuto] = useState([]);
    const [manualLossList, setManualLossList] = useState([]);
    const [responseProperty, setResponseProperty] = useState({});
    const translator = useTranslator();
    const { authHeader } = useAuthentication();

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(submissionVM, 'lobData.homeowners_EH.premiumStability.value') !== undefined;

    const onNext = useCallback(
        () => submissionVM,
        [submissionVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate: () => {},
        },
    };

    useEffect(()=>{
        if(fullQuoteType){
            const historyPromise = TimelineService.getTimeline(
                {
                    policyNumber: _get(submissionVM, 'value.policyNumber'),
                    relatedItem: 'Policy'
                },
                authHeader
            );

            historyPromise.then((response) => {
                const lossHistoryEvent = response.events.find((event) => event.description?.includes('Self-Declared'))?.description;

                if (lossHistoryEvent) {
                    setManualLossList(lossHistoryEvent.split("\n"));
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top'
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: true,
            defaultActiveTab: fullQuoteType ? 'verifiedLossesAndViolationTab' : 'lossesAndViolationTab'
        },
        lossesAndViolationTab: {
            visible: !fullQuoteType
        },
        verifiedLossesAndViolationTab: {
            visible: fullQuoteType
        },
        verifiedLossesAndViolationComponent: {
            responseAuto,
            setResponseAuto,
            responseProperty,
            setResponseProperty,
            submissionVM,
            updateWizardData: () => {},
            authUserData,
            setLossesPageValid: () => {},
            viewOnlyMode: true
        },
        lossesAndViolationComponent: {
            submissionVM,
            updateWizardData: () => {},
            isSkipping,
            setLossesPageValid: () => {},
            viewOnlyMode: true
        },
        uwIssueComponent: {
            submissionVM,
            updateWizardData: () => {},
            authHeader,
            viewOnlyMode: true
        },
        creditReportsComponent: {
            submissionVM,
            updateWizardData: () => {},
            lineOfBusiness: submissionVM.lobData.homeowners_EH,
            lobName: 'homeowners_EH',
            authUserData,
            id: 'creditReportsComponent',
            onValidate: () => {},
            viewOnlyMode: true
        },
        geoDataComponent: {
            quoteID,
            geoDataResponse,
            setGeoDataResponse,
            policyState: _get(submissionVM, 'baseData.policyAddress.state.value.code')
        },
        uwIssuesNotification: {
            visible: false
        },
        diminishingAvailableAmount: {
            labelPosition: 'left'
        },
        diminishingAvailableAmountLatest: {
            labelPosition: 'left'
        },
        EHRiskAnalysisMiscComponentId: {
            submissionVM
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(submissionVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: submissionVM,
            updateWizardData: () => {},
            onValidate: () => {},
            showErrors: false,
            viewOnlyMode: true
        },
        manualLossesHistory:{
            manualLossList,
            visible: fullQuoteType && manualLossList.length > 0
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={() => {}}
                onValidationChange={() => {}}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
