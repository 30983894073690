import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextPageMessage: {
        id: 'renewal.eh.views.coverages.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    coverages: {
        id: 'renewal.eh.views.coverages.Coverages',
        defaultMessage: 'Coverages'
    },
    mainCoverages: {
        id: 'renewal.eh.views.coverages.Main Coverages Premium',
        defaultMessage: 'Main Coverages Premium'
    },
    additionalCoverages: {
        id: 'renewal.eh.selectAction.cart.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    },
    schedules: {
        id: 'renewal.eh.selectAction.cart.Schedules',
        defaultMessage: 'Schedules'
    },
    optionalCoverageAddButton: {
        id: 'renewal.eh.coverages.Optional Coverage.Optional Coverages Add Edit',
        defaultMessage: 'OPTIONAL COVERAGES'
    },
    optionalCoverageLabel: {
        id: 'renewal.eh.coveragePage.Optional Coverage.Optional Coverages',
        defaultMessage: 'Optional Coverages'
    },
    sectionICoverages: {
        id: 'renewal.eh.coveragesPage.Section I Coverages',
        defaultMessage: 'Section I Coverages'
    },
    sectionIICoverages: {
        id: 'renewal.eh.coveragesPage.Section II Coverages',
        defaultMessage: 'Section II Coverages'
    },
    deductibles: {
        id: 'renewal.eh.coveragesPage.Deductibles',
        defaultMessage: 'Deductibles'
    },
    goldPlatimumPrimaryHelpText: {
        id: 'renewal.eh.quick.QuoteClauseTable.goldPlatimumPrimaryHelpText.Coverage Comparison pop-up',
        defaultMessage: 'Coverage Comparison pop-up'
    },
    newHomePurchaseToolTip: {
        id: 'renewal.eh.coveragesPage.New purchase within 180 days',
        defaultMessage: 'New purchase within 180 days'
    },
    newHomePurchase: {
        id: 'renewal.eh.coveragesPage.New Home Purchase',
        defaultMessage: 'New Home Purchase'
    },
    nextLabel: {
        id: 'renewal.eh.quotePage.Next',
        defaultMessage: 'Next'
    },
    recalculateLabel: {
        id: 'renewal.eh.quotePage.Recalculate',
        defaultMessage: 'Recalculate'
    },
    optionalCoveragePackage: {
        id: 'renewal.eh.coveragesPage.optionalCoveragePackage',
        defaultMessage: 'Optional Coverage Package'
    },
    goldPlatinumPrimaryLabel: {
        id: 'renewal.eh.coveragesPage.goldPlatinumPrimaryLabel',
        defaultMessage: 'Gold & Platinum Packages'
    },
    discountMessageSelect: {
        id: 'renewal.eh.coveragesPage.selectCoverage',
        defaultMessage: 'Select any coverages marked with'
    },
    discountMessageLabel: {
        id: 'renewal.eh.coveragesPage.discountMessage',
        defaultMessage: 'to receive a Package Discount. The more coverages selected, the greater the discount.'
    }
});
