import { defineMessages } from 'react-intl';

export default defineMessages({
    accountSearchTitle: {
        id: 'accountSearchResults.title',
        defaultMessage: 'Existing Accounts'
    },
    accountSearchSubTitle: {
        id: 'accountSearchResults.No existing account(s) found. A new account will be created.',
        defaultMessage: 'No existing account(s) found. A new account will be created.'
    },
    existingAccounts: {
        id: 'accountSearchResults.Existing Accounts',
        defaultMessage: 'Existing Accounts'
    },
    existingAccountsSubtitle: {
        id: 'accountSearchResults.Existing account(s) found. Select an existing account or select create new account.',
        defaultMessage: 'Existing account(s) found. Select an existing account or select create new account.'
    }
});
