const overrides = {
    field: {
        declareLossStatementThreeYears: {
            partner: {
                MSA: {
                    states: ['PA', 'NH', 'VA', 'NC', 'MT', 'RI']
                },
                CONNECT: {
                    states: []
                }
            }
        },
        declareLossStatementFiveYears: {
            partner: {
                MSA: {
                    states: ['UT', 'IN', 'IL', 'TN', 'SC', 'CT', 'ME', 'WI']
                },
                CONNECT: {
                    states: ['WI']
                }
            }
        }
    }
};
export default overrides;
