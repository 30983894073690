import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import EURenewalInsuredDetailsPage from './pages/InsuredDetails/InsuredDetailsPage';
import EURenewalQualificationPage from './pages/Qualification/QualificationPage';
import EURenewalUnderlyingPolicyPage from './pages/UnderlyingPolicy/UnderlyingPolicyPage';
import EURenewalVehicleOperatorPage from './pages/VehicleOperator/VehicleOperatorPage';
import EURenewalRiskAnalysisPage from './pages/RiskAnalysis/RiskAnalysisPage';
import EURenewalCoveragePage from './pages/Coverages/CoveragesPage';
import EURenewalThirdPartyInterestPage from './pages/ThirdPartyInterest/ThirdPartyInterestPage';
import EURenewalChangeDiff from './pages/RenewalChangeDiff/RenewalChangeDiffPage';

import EUViewRenewalInsuredDetailsPage from './pages/InsuredDetails/ViewOnly/ViewInsuredDetailsPage';
import EUViewRenewalPreQualificationPage from './pages/Qualification/ViewOnly/ViewEUPreQualificationPage';
import EUViewRenewalUnderlyingPolicyPage from './pages/UnderlyingPolicy/ViewOnly/ViewUnderlyingPolicyPage';
import EUViewRenewalVehicleOperatorPage from './pages/VehicleOperator/ViewOnly/ViewVehicleOperatorPage';
import EUViewRenewalRiskAnalysisPage from './pages/RiskAnalysis/ViewOnly/ViewRiskAnalysisPage';
import EUViewRenewalCoveragePage from './pages/Coverages/ViewOnly/ViewCoveragesPage';
import EUViewRenewalThirdPartyInterestPage from './pages/ThirdPartyInterest/ViewOnly/ViewThirdPartyInterestPage';
import EUViewRenewalChangeDiff from './pages/RenewalChangeDiff/viewOnly/ViewRenewalChangeDiffPage';
import EURenewalPremiumBoxComponent from './components/EURenewalPremiumBoxComponent/EURenewalPremiumBoxComponent';

setComponentMapOverrides(
    {
        EURenewalInsuredDetailsPage: { component: 'EURenewalInsuredDetailsPage' },
        EURenewalQualificationPage: { component: 'EURenewalQualificationPage' },
        EURenewalUnderlyingPolicyPage: { component: 'EURenewalUnderlyingPolicyPage' },
        EURenewalVehicleOperatorPage: { component: 'EURenewalVehicleOperatorPage' },
        EURenewalRiskAnalysisPage: { component: 'EURenewalRiskAnalysisPage' },
        EURenewalCoveragePage: { component: 'EURenewalCoveragePage' },
        EURenewalThirdPartyInterestPage: { component: 'EURenewalThirdPartyInterestPage' },
        EURenewalChangeDiff: { component: 'EURenewalChangeDiff' },

        EUViewRenewalChangeDiff: { component: 'EUViewRenewalChangeDiff' },
        EUViewRenewalInsuredDetailsPage: { component: 'EUViewRenewalInsuredDetailsPage' },
        EUViewRenewalPreQualificationPage: { component: 'EUViewRenewalPreQualificationPage' },
        EUViewRenewalUnderlyingPolicyPage: { component: 'EUViewRenewalUnderlyingPolicyPage' },
        EUViewRenewalVehicleOperatorPage: { component: 'EUViewRenewalVehicleOperatorPage' },
        EUViewRenewalRiskAnalysisPage: { component: 'EUViewRenewalRiskAnalysisPage' },
        EUViewRenewalCoveragePage: { component: 'EUViewRenewalCoveragePage' },
        EUViewRenewalThirdPartyInterestPage: { component: 'EUViewRenewalThirdPartyInterestPage' },
        EURenewalPremiumBoxComponent: { component: 'EURenewalPremiumBoxComponent' }
    },
    {
        EURenewalInsuredDetailsPage,
        EURenewalQualificationPage,
        EURenewalUnderlyingPolicyPage,
        EURenewalVehicleOperatorPage,
        EURenewalRiskAnalysisPage,
        EURenewalCoveragePage,
        EURenewalThirdPartyInterestPage,
        EURenewalChangeDiff,

        EUViewRenewalChangeDiff,
        EUViewRenewalInsuredDetailsPage,
        EUViewRenewalPreQualificationPage,
        EUViewRenewalUnderlyingPolicyPage,
        EUViewRenewalVehicleOperatorPage,
        EUViewRenewalRiskAnalysisPage,
        EUViewRenewalCoveragePage,
        EUViewRenewalThirdPartyInterestPage,
        EURenewalPremiumBoxComponent
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as EURenewalWizard } from './EURenewalWizard';
