import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import QNBEHCommonQuotePage from './pages/common/Quote/QuotePage';
import QNBEHHomeownersPropertyPage from './pages/HO3/Property/PropertyPage';
import QNBEHHomeownersViewPropertyPage from './pages/HO3/Property/ViewOnly/ViewPropertyPage';
import QNBEHHomeownersPaymentDetailsPage from './pages/common/EHPaymentDetails/PaymentDetailsPage';
import QNBEHThankYouPage from './pages/common/ThankYouPage/ThankYouPage';
import QNBEHThirdPartyInterestPage from './pages/common/ThirdPartyInterest/ThirdPartyInterestPage';
import QNBEHViewThirdPartyInterestPage from './pages/common/ThirdPartyInterest/ViewOnly/ViewThirdPartyInterestPage';
import QNBEHHomeownersViewPaymentDetailsPage from './pages/common/EHPaymentDetails/ViewOnly/ViewPaymentDetailsPage';
import QNBEHHomeownersViewQuotePage from './pages/common/Quote/ViewOnly/ViewQuotePage';
import QNBEHCondominiumPropertyPage from './pages/HO6/Property/PropertyPage';
import QNBEHRentersPropertyPage from './pages/HO4/Property/PropertyPage';
import QNBEHRentersViewPropertyPage from './pages/HO4/Property/ViewOnly/ViewPropertyPage';
import QNBEHCondominiumViewPropertyPage from './pages/HO6/Property/ViewOnly/ViewPropertyPage';
import QNBEHSeasonalPropertyPage from './pages/HF9/Property/PropertyPage';
import QNBEHRiskAnalysisPage from './pages/common/RiskAnalysisPage/RiskAnalysisPage';
import QNBEHUnVerifiedLossesAndViolationComponent from './components/UnVerifiedLossesAndViolationComponent/UnVerifiedLossesAndViolationComponent';
import QNBEHCommonUWIssueComponent from './components/UWIssuesComponent/UWIssuesComponent';
import QNBEHCommonGeoDataComponent from './components/GeoDataComponent/GeoDataComponent';
import QNBEHVerifiedLossesAndViolationComponent from './components/VerifiedLossesAndViolationComponent/VerifiedLossesAndViolationComponent';
import QNBEHViewOnlyRiskAnalysisPage from './pages/common/RiskAnalysisPage/ViewOnly/RiskAnalysisPage';
import QNBEHSeasonalViewPropertyPage from './pages/HF9/Property/ViewOnly/ViewPropertyPage';
import EhSideBySideContainer from './components/EhSideBySideContainer/EhSideBySideContainer';
import EHSideBySideMandatoryCoveragesComponent from './components/EHSideBySideMandatoryCoveragesComponent/EHSideBySideMandatoryCoveragesComponent';
import EhSideBySideQuoteDetailsComponent from './components/EhSideBySideQuoteDetailsComponent/EhSideBySideQuoteDetailsComponent'
import EHSideBySideOptionalCoveragesComponent from './components/EHSideBySideOptionalCoveragesComponent/EHSideBySideOptionalCoveragesComponent'
import EHAdditionalInformation from './pages/common/EHPaymentDetails/AdditionalInfo/AdditionalInfo';

setComponentMapOverrides(
    {
        QNBEHSeasonalPropertyPage: { component: 'QNBEHSeasonalPropertyPage' },
        QNBEHRentersPropertyPage: { component: 'QNBEHRentersPropertyPage' },
        QNBEHHomeownersPropertyPage: { component: 'QNBEHHomeownersPropertyPage' },
        QNBEHCommonQuotePage: { component: 'QNBEHCommonQuotePage' },
        QNBEHHomeownersPaymentDetailsPage: { component: 'QNBEHHomeownersPaymentDetailsPage' },
        QNBEHThankYouPage: { component: 'QNBEHThankYouPage' },
        QNBEHThirdPartyInterestPage: { component: 'QNBEHThirdPartyInterestPage' },
        QNBEHViewThirdPartyInterestPage: { component: 'QNBEHViewThirdPartyInterestPage' },
        QNBEHHomeownersViewPropertyPage: { component: 'QNBEHHomeownersViewPropertyPage' },
        QNBEHHomeownersViewPaymentDetailsPage: {
            component: 'QNBEHHomeownersViewPaymentDetailsPage'
        },
        QNBEHHomeownersViewQuotePage: { component: 'QNBEHHomeownersViewQuotePage' },
        QNBEHCondominiumPropertyPage: { component: 'QNBEHCondominiumPropertyPage' },
        QNBEHCondominiumViewPropertyPage: { component: 'QNBEHCondominiumViewPropertyPage' },
        QNBEHRiskAnalysisPage: { component: 'QNBEHRiskAnalysisPage' },
        QNBEHVerifiedLossesAndViolationComponent: { component: 'QNBEHVerifiedLossesAndViolationComponent' },
        QNBEHCommonUWIssueComponent: { component: 'QNBEHCommonUWIssueComponent' },
        QNBEHCommonGeoDataComponent: { component: 'QNBEHCommonGeoDataComponent' },
        QNBEHUnVerifiedLossesAndViolationComponent: {
            component: 'QNBEHUnVerifiedLossesAndViolationComponent'
        },
        QNBEHViewOnlyRiskAnalysisPage: { component: 'QNBEHViewOnlyRiskAnalysisPage' },
        QNBEHRentersViewPropertyPage: { component: 'QNBEHRentersViewPropertyPage' },
        QNBEHSeasonalViewPropertyPage: { component: 'QNBEHSeasonalViewPropertyPage' },
        EhSideBySideContainer: { component: 'EhSideBySideContainer'},
        EHSideBySideMandatoryCoveragesComponent: { component: 'EHSideBySideMandatoryCoveragesComponent'},
        EhSideBySideQuoteDetailsComponent: { component: 'EhSideBySideQuoteDetailsComponent'},
        EHSideBySideOptionalCoveragesComponent: { component: 'EHSideBySideOptionalCoveragesComponent'},
        EHAdditionalInformation: { component: 'EHAdditionalInformation'}
    },
    {
        QNBEHSeasonalPropertyPage,
        QNBEHRentersPropertyPage,
        QNBEHHomeownersPropertyPage,
        QNBEHCommonQuotePage,
        QNBEHHomeownersPaymentDetailsPage,
        QNBEHThankYouPage,
        QNBEHThirdPartyInterestPage,
        QNBEHViewThirdPartyInterestPage,
        QNBEHHomeownersViewPropertyPage,
        QNBEHHomeownersViewPaymentDetailsPage,
        QNBEHHomeownersViewQuotePage,
        QNBEHCondominiumPropertyPage,
        QNBEHCondominiumViewPropertyPage,
        QNBEHRiskAnalysisPage,
        QNBEHVerifiedLossesAndViolationComponent,
        QNBEHCommonUWIssueComponent,
        QNBEHCommonGeoDataComponent,
        QNBEHUnVerifiedLossesAndViolationComponent,
        QNBEHViewOnlyRiskAnalysisPage,
        QNBEHRentersViewPropertyPage,
        QNBEHSeasonalViewPropertyPage,
        EhSideBySideContainer,
        EHSideBySideMandatoryCoveragesComponent,
        EhSideBySideQuoteDetailsComponent,
        EHSideBySideOptionalCoveragesComponent,
        EHAdditionalInformation
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as EHWizard } from './EHWizard';
