
import React, { useCallback, useState, useEffect } from 'react';
import { get as _get, isEmpty as _isEmpty } from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { TimelineService } from 'e1p-capability-journey';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from './RiskAnalysisPage.metadata.json5';
import messages from './RiskAnalysisPage.messages';

function RiskAnalysisPage(props) {
    // eslint-disable-max-len
    const { authHeader, authUserData } = useAuthentication();
    const {
        wizardData: submissionVM,
        updateWizardData,
        isSkipping,
        updateWizardSnapshot
    } = props;
    const fullQuoteType = submissionVM.value.quoteType === 'Full';
    const [saveAndQuoteInProcess, setSaveAndQuoteInProcess] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const translator = useTranslator();
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        registerInitialComponentValidation,
        registerComponentValidation
    } = useValidation('RiskAnalysisPage');

    const [manualLossList, setManualLossList] = useState([]);
    const [activeTab, updateActiveTab] = useState('');

    useEffect(()=>{
        let isMounted = true;

        if(fullQuoteType){
            const historyPromise = TimelineService.getTimeline(
                {
                    policyNumber: _get(submissionVM, 'value.policyNumber'),
                    relatedItem: 'Policy'
                },
                authHeader
            );

            historyPromise.then((response) => {
                const lossHistoryEvent = response.events.find((event) => event.description?.includes('Self-Declared'))?.description;

                if (isMounted && lossHistoryEvent) {
                    setManualLossList(lossHistoryEvent.split("\n"));
                }
            })
        }

        return () => {
            isMounted = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(submissionVM, 'lobData.personalUmbrella_EU.premiumStability.value') !== undefined;

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    const saveAndQuote = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setSaveAndQuoteInProcess(true);
            submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
                submissionVM.value,
                authHeader
            );
            setSaveAndQuoteInProcess(false);

            return submissionVM;
        },
        [authHeader, isComponentValid, submissionVM]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote();

                const fieldIssues = _get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, submissionVM, updateWizardSnapshot]
    );

    const getDefaultActiveTab = () => {
        if (_get(submissionVM.value, 'errorsAndWarnings.underwritingIssues.length') > 0) {
            return 'uwIssuesTab';
        }

        return 'lossesAndViolationTab';
    };

    const isPageValid = useCallback(() => {
        const isQuoteBlocked = submissionVM.errorsAndWarnings
            .underwritingIssues?.value?.some(
                (issue) => issue.currentBlockingPoint === 'BlocksQuote' || issue.currentBlockingPoint === 'BlocksQuoteRelease'
            );

        return !isQuoteBlocked;
    }, [submissionVM.errorsAndWarnings.underwritingIssues]);

    useEffect(() => {
        // We can skip page (on continue) to covs page if there hasn't been a quote yet
        registerInitialComponentValidation(() => _get(submissionVM, 'baseData.periodStatus.value.code') === 'Quoted' || !!_get(submissionVM, 'value.quoteData'));
        // register validation for disabling next button
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, registerInitialComponentValidation, submissionVM]);
    
    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: !saveAndQuoteInProcess && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: !saveAndQuoteInProcess && !isSavingCurrentPageChanges,
            defaultActiveTab: getDefaultActiveTab(),
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            activeTab: activeTab !== '' ? activeTab : getDefaultActiveTab()
        },
        lossesAndViolationComponent: {
            submissionVM,
            isSkipping,
            showErrors: isPageSubmitted,
            authUserData,
            isVerified: submissionVM.value.quoteType === 'Full' ||  submissionVM.value.quoteType_Ext === 'Full',
            updateWizardData,
            disregardFieldValidation,
            updateIsPageSubmitted
        },
        UWIssuesComponentId: {
            submissionVM,
            updateWizardData,
            authHeader,
            showErrors: isPageSubmitted
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(submissionVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: submissionVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        },
        manualLossesHistory:{
            manualLossList,
            visible: fullQuoteType && manualLossList.length > 0
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVM]
    );

    return (
        <WizardPage
            skipWhen={initialValidation}
            onNext={saveAndQuote}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default RiskAnalysisPage;
