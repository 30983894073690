import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
    get as _get,
    isEmpty as _isEmpty
} from 'lodash';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Wizard } from 'e1p-portals-wizard-react';
import { withViewModelService, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { PolicyViewService } from 'e1p-capability-gateway';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { error as loggerError } from '@jutro/logger';
import moment from 'moment';
import messages from './PEPolicyViewCommonWizard.messages';
import wizardStepToFieldMapping from './config/common/policy-view-common-wizard-step-to-field-mapping.json5';
import ho3Wizard from './config/eh/HO3/policy-view-eh-wizard-config.json5';
import ho6Wizard from './config/eh/HO6/policy-view-eh-wizard-config.json5';
import hf9Wizard from './config/eh/HF9/policy-view-eh-wizard-config.json5';
import h04Wizard from './config/eh/HO4/policy-view-eh-wizard-config.json5';
import eaWizard from './config/ea/policy-view-ea-wizard-config.json5';
import euWizard from './config/eu/policy-view-eu-wizard-config.json5';


function PEPolicyViewCommonWizard(props) {
    const modalApi = useModal();
    const [wizardConfig, setWizardConfig] = useState(ho3Wizard);
    const { authHeader } = useAuthentication();
    const [initialPolicyView, setInitialPolicyView] = useState(null);
    const viewModelService = useContext(ViewModelServiceContext);
    const [isLoading, setIsLoading] = useState(true);
    const { history, location } = props;

    const policyTypeMapWizardFlow = {
        HO3: {
            wizardConfig: ho3Wizard
        },
        HO6: {
            wizardConfig: ho6Wizard
        },
        HO4: {
            wizardConfig: h04Wizard
        },
        HF9: {
            wizardConfig: hf9Wizard
        },
        EAFamilyCar: {
            wizardConfig: eaWizard
        },
        Umbrella: {
            wizardConfig: euWizard
        }
    };

    const { steps, title } = wizardConfig;

    useEffect(() => {
        const { policyNumber, selectedDate, policyType } = location;

        if (!policyNumber && !selectedDate) {
            history.push('/');

            return;
        }

        const errorModalBox = (errorMessage) => {
            modalApi.showConfirm(errorMessage).then(() => {
                setIsLoading(false);

                const path = `/policies/${policyNumber}/summary`;

                history.push(path);
            });
        };

        if (policyNumber && selectedDate) {
            const selectedDateFormatted = moment(selectedDate);
            const policySerach = {
                policyNumber,
                selectedDate: {
                    year: selectedDateFormatted.year(),
                    month: selectedDateFormatted.month(),
                    day: selectedDateFormatted.date()
                }
            };

            PolicyViewService.getPolicyView(policySerach, authHeader)
                .then((response) => {
                    const exceptions = _get(response, 'exceptions_Ext', []);

                    if (!_isEmpty(exceptions)) {
                        const exceptionMessage = exceptions[0].errorMessage;

                        return errorModalBox({
                            title: messages.anErrorOccurredTitle,
                            message: {
                                id: exceptionMessage,
                                defaultMessage: exceptionMessage
                            },
                            status: 'warning',
                            icon: 'mi-error-outline'
                        });
                    }

                    setWizardConfig(
                        policyTypeMapWizardFlow[policyType]
                            ? policyTypeMapWizardFlow[policyType].wizardConfig
                            : ho3Wizard
                    );

                    const policyViewVM = viewModelService.create(
                        response,
                        'pc',
                        'amfam.edge.capabilities.gateway.policyview.dto.PolicyViewDTO'
                    );

                    setInitialPolicyView(policyViewVM);
                    setIsLoading(false);
                }).catch(() => {
                    errorModalBox({
                        title: messages.anErrorOccurredTitle,
                        message: messages.anErrorOccurred,
                        status: 'warning',
                        icon: 'mi-error-outline'
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleError = useCallback(
        (error) => {
            const policyNumber = _get(initialPolicyView, 'policyNumber.value');
            const redirectPath = `/policies/${policyNumber}/summary`;
            const pathname = _get(location, 'pathname');
            const state = {
                pathname: redirectPath,
                state: error
            };
            
            loggerError(`MSA-ViewWizard-Error: ${error}
            Pathname: ${pathname}`);

            return modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: messages.anErrorOccurredTitle,
                message: messages.anErrorOccurred
            }).then(() => <Redirect to={state} />);
        },
        [initialPolicyView, location, modalApi]
    );

    if (isLoading) {
        return (
            <E1PLoader
                loaded={!isLoading}
            />
        );
    }

    if (!initialPolicyView) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                viewOnly
                initialData={initialPolicyView}
                skipCompletedSteps
                wizardStepToFieldMapping={wizardStepToFieldMapping}
                isPageJumpEnabled
                isPolicyView
                onPreviousModalProps={{
                    title: messages.wantToJump,
                    message: messages.wantToJumpMessage,
                    messageProps: {
                        ok: messages.yesModel,
                        close: messages.cancelModel
                    }
                }}
            />
        </ErrorBoundary>
    );
}

PEPolicyViewCommonWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            quoteentry: PropTypes.shape({
                postalCode: PropTypes.string,
                quoteID: PropTypes.string
            })
        }),
        search: PropTypes.string,
        viewOnly: PropTypes.bool,
        shouldSkipAdditionalInfo: PropTypes.bool
    }).isRequired
};

export default withViewModelService(PEPolicyViewCommonWizard);
