import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'e1p.gateway.views.edit-note-detail-modal.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'e1p.gateway.views.edit-note-detail-modal.save',
        defaultMessage: 'Save & Close'
    },
    relatedTo: {
        id: 'e1p.gateway.views.edit-note-modal.RelatedTo',
        defaultMessage: 'Related To'
    },
    topic: {
        id: 'e1p.gateway.directives.notes.Topic',
        defaultMessage: 'Topic'
    },
    subject: {
        id: 'e1p.gateway.views.edit-note-modal.Subject',
        defaultMessage: 'Subject'
    },
    securityLevel: {
        id: 'e1p.gateway.directives.notes.SecurityLevel',
        defaultMessage: 'Security Level'
    },
    note: {
        id: 'e1p.gateway.views.edit-note-modal.Note',
        defaultMessage: 'Note'
    }
});
