import { useTranslator } from '@jutro/locale';
import { useMemo } from 'react';

function useEndorsementUtil(
    policyChangeVM,
    authUserData
) {
    const hasChangeSource1 = authUserData?.permissions_Ext.includes('chgsource1_ext');
    const hasChangeSource2 = authUserData?.permissions_Ext.includes('chgsource2_ext');
    const translator = useTranslator();

    /**
     * Helps to build the policy change source drop down values based on current agents permission
     * @returns {array} change source values
     */
    const changeSourceValues = useMemo(
        () => {
            const sourceValues = policyChangeVM.policyChangeSource_Ext.aspects.availableValues
                // filter items based on current user permission
                .filter((item) => ((hasChangeSource1 && item.code === 'insured')
                        || (hasChangeSource2 && (item.code === 'company' || item.code === 'insured'))
                        // CRU is not a valid source for MSA
                    ))
                .map((item) => ({
                        code: item.code,
                        name: translator({
                            id: item.name,
                            defaultMessage: item.name
                        })
                    }));

            return sourceValues;
        },
        [policyChangeVM.policyChangeSource_Ext.aspects.availableValues,
            hasChangeSource1, hasChangeSource2, translator]
    );

    return {
        changeSourceValues
    };
}

export default useEndorsementUtil;
