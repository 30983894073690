import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import { Link } from 'react-router-dom';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import { AccountService } from 'e1p-capability-gateway';
import { PolicyService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { Icon } from '@jutro/components';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { LobIconUtil } from '@xengage/gw-portals-util-js';
import SearchResults from '../SearchResults/SearchResults';
import messages from '../Accounts/Accounts.messages';
import metadata from './SRELandingPage.metadata.json5';
import SRELandingPageMessages from './SRELandingPage.messages';
import styles from './SRELandingPage.module.scss';

function SRELandingPage(props) {
    const [accountData, setAccountData] = useState([]);
    const [policiesData, setPoliciesData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, updateSearchText] = useState(undefined);
    const [search, updateSearch] = useState(false);
    const translator = useTranslator();
    const { authHeader } = props;
    const intl = useContext(IntlContext);

    const writeValue = useCallback((value) => {
        updateSearchText(value);
        updateSearch(false);
    }, []);
    const getCell = useCallback(
        (items, index, { id: property }) => {
            const tooltipMessages = {
                dateCreated: translator(messages.DateCreated),
                address: translator(messages.Address),
                displayStatus: translator(messages.status),
                premium: translator(messages.premium),
                effectiveDate: translator(messages.effectiveDate),
                expirationDate: translator(messages.expirationDate)
            };

            const toolTipMessage = tooltipMessages[property] || '';

            return <span title={toolTipMessage}>{items[property]}</span>;
        },
        [translator]
    );

    const getAccount = useCallback(
        (item, index, { id: property }) => {
            const toolTipMessage = translator(messages.Account);
            const icon = item.type === 'Personal' ? 'mi-person' : 'mi-apartment';

            return (
                <div className={styles.linkAccount}>
                    <Icon icon={icon} />
                    <Link to={`/accounts/${item.accountNumber}/summary`} title={toolTipMessage}>
                        {item[property]}
                    </Link>
                </div>
            );
        },
        [translator]
    );

    const getPolicyLink = useCallback(
        (item, index, { id: property }) => {
            let toolTipMessage = messages.OpenActivities;
            let redirectPath = 'activities';

            if (property === 'policy') {
                toolTipMessage = messages.Policies;
                redirectPath = 'summary';
            }

            return (
                <Link
                    to={{
                        pathname: `/accounts/${item.accountNumber}/${redirectPath}`,
                        state: redirectPath
                    }}
                    title={translator(toolTipMessage)}
                >
                    {item[property]}
                </Link>
            );
        },
        [translator]
    );

    const processResponseData = useCallback((data) => {
        const accountArray = data.map((accountInfo) => {
            const isPersonal = accountInfo.isPersonalAccount;
            const account = {
                type: isPersonal ? 'Personal' : 'Commercial',
                insuredName: accountInfo.accountHolder,
                policy: accountInfo.policySummaries.length,
                dateCreated: intl.formatDate(new Date(accountInfo.accountCreatedDate), { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }),
                address: accountInfo.accountHolderAddress.displayName,
                openActivities: accountInfo.numberOfOpenActivities,
                accountNumber: accountInfo.accountNumber
            };

            return account;
        });

        return accountArray;
    }, [intl]);

    const setAccountResponse = useCallback(
        (response) => {
            setAccountData(processResponseData(response));
        },
        [processResponseData]
    );

    const getProductImage = useCallback((item) => {
        const icon = LobIconUtil.getFontIcon(item.productCode);

        return (
            <Icon
                icon={icon}
                title={item.productCode}
            />
        );
    }, []);

    const getPoliciesCell = useCallback(
        (item, index, { id: property }) => {
            const toolTipMessage = translator(messages.insuredName);

            return <span title={toolTipMessage}>{item[property]}</span>;
        },
        [translator]
    );

    const getLink = useCallback(
        (item, index, { id: property }) => (
                <Link
                    to={`/policies/${item[property]}/summary`}
                    className={styles.removeLinkStyle}
                    title={translator(messages[property])}
                >
                    {item[property]}
                </Link>
            ),
        [translator]
    );

    const getAccountNumberLink = useCallback(
        (item, index, { id: property }) => {
            const toolTipMessage = translator(messages.Account);
            const { accountHolderName } = item;

            return (
                <Link
                    to={`/accounts/${item[property]}/summary`}
                    className={styles.removeLinkStyle}
                    title={toolTipMessage}
                >
                    {accountHolderName}
                </Link>
            );
        },
        [translator]
    );

    const setPoliciesResponse = useCallback((data) => {
        const policyItems = [];

        data.forEach((policyDetails) => {
            const { amount, currency } = policyDetails.premium;
            const premiumAmount = intl.formatNumber(
                amount,
                {
                    style: 'currency',
                    currency,
                    currencyDisplay: 'code'
                }
            );
            const policy = {
                productCode: policyDetails.product.productCode,
                insuredName: policyDetails.primaryInsuredName,
                displayStatus: policyDetails.displayStatus,
                accountNumber: policyDetails.accountNumber,
                policyNumber: policyDetails.policyNumber,
                account: policyDetails.accountNumber,
                accountHolderName: policyDetails.accountHolderName,
                primaryInsuredName: policyDetails.primaryInsuredName,
                premium: premiumAmount,
                effectiveDate: intl.formatDate(new Date(policyDetails.effective), { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }),
                expirationDate: intl.formatDate(new Date(policyDetails.expiration), { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })
            };

            policyItems.push(policy);

            return true;
        });
        setPoliciesData(policyItems);
    }, [intl]);

    useEffect(() => {
        AccountService.getRecentlyViewedAccounts(authHeader).then((accountsResponse) => {
            setAccountResponse(accountsResponse, messages.RecentlyViewed);
            setIsLoading(false);
        });

        PolicyService.getRecentlyViewedPolicies(authHeader).then((policiesResponse) => {
            setPoliciesResponse(policiesResponse);
            setIsLoading(false);
        });
    }, [authHeader, setAccountResponse, setPoliciesResponse]);

    const handleClick = useCallback(() => {
        if (searchText) {
            updateSearch(true);
        }
    }, [searchText]);

    const overrides = {
        recentlyViewedTableGrid: {
            visible: accountData.length > 0,
            data: accountData
        },
        recentlyViewedPoliciesTableGrid: {
            visible: policiesData.length > 0,
            data: policiesData
        },
        searchResults: {
            param: search ? searchText : '',
            title: translator(SRELandingPageMessages.searchResults, {
                query: searchText
            }),
            visible: searchText !== undefined,
            noResultsMessage: translator(SRELandingPageMessages.noSearchresultsFound)
        },
        searchBox: {
            onValueChange: writeValue,
            value: searchText,
            onEnter: handleClick
        },
        searchBtn: {
            onClick: handleClick
        },
        searchResultsContainer: {
            visible: search && searchText !== undefined
        },
        noPoliciesFound: {
            visible: !policiesData.length
        },
        noAccountsFound: {
            visible: !accountData.length
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell,
            getProductImage,
            getPoliciesCell,
            getAccount,
            getPolicyLink,
            getAccountNumberLink,
            getLink
        },
        resolveComponentMap: {
            searchresults: SearchResults
        }
    };

    if (isLoading) {
        return <E1PLoader loaded={!isLoading} />;
    }

    const landingPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;

    return <div className={styles.landingStyles}>{landingPage}</div>;
}

SRELandingPage.propTypes = {
    authHeader: PropTypes.shape({}).isRequired
};

export default withAuthenticationContext(SRELandingPage);
