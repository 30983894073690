import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {
    findIndex as _findIndex,
    get as _get,
    set as _set,
    isNil as _isNil,
    isEmpty as _isEmpty,
    isUndefined as _isUndefined
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PaymentHelper } from 'e1p-capability-payment';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { error as loggerError } from '@jutro/logger';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useUWIssueUtil, e1pUserAccessUtil } from 'e1p-capability-hooks';
import { QuoteProposalUtil, PaymentPageUtil } from 'e1p-portals-util-js';
import {
    parseErrors
} from '@xengage/gw-portals-edge-validation-js';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import appConfig from 'app-config';
import messages from './PaymentDetailsPage.messages';
import metadata from './PaymentDetailsPage.metadata.json5';

const LOB = 'personalUmbrella_EU';

function initializeVM(rewriteVM) {
    rewriteVM.bindData.paymentDetails.value = rewriteVM.bindData.paymentDetails.value || {};
}

// need to make paymentus required for INT and above
const environment = _get(appConfig, 'env.AMFAM_ENV', 'local');
const envsWithoutPayment = ['dev', 'local', 'sbx', 'dev2', 'int2', 'qa2', 'dev3', 'gwupgrade1', 'int', 'perf2'];
const isEnvWithPaymentus = !envsWithoutPayment.includes(environment);

function PaymentDetailsPage(props) {
    const {
        wizardData: rewriteVM,
        updateWizardData,
        steps,
        jumpTo,
        disableAllSteps,
        updateWizardSnapshot,
        wizardSnapshot
    } = props;

    const [validationErrors, setValidationErrors] = useState([]);
    const [isVMInitialized, setIsVMInitialized] = useState(false);
    const [bindOperationsInProcess, setBindOperationsInProcess] = useState(false);
    const [signatureSuccess, setSignatureSuccess] = useState(false);
    const { authHeader, authUserData } = useAuthentication();
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [underwritingIssuesPopupAppeared, setUnderwritingIssuesPopupAppeared] = useState(false);
    const [keepShowingIframeAfterPayment, setKeepShowingIframeAfterPayment] = useState(false);
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const [isCallingCanPurchase, setIsCallingCanPurchase] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isMailingComponentValid, updateIsBillingComponentValid] = useState(true);
    const [isBillingComponentValid, updateIsMailingComponentValid] = useState(true);
    const [fieldIssue, setFieldIssue] = useState(false);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('PaymentDetailsPage');
    const translator = useTranslator();
    const amfamOktaToken = useContext(AmfamOktaTokenContext);
    const PAY_MONTHLY = 'af:payMthly3';
    const isNewBusiness = _get(rewriteVM, 'baseData.value.businessTransactionType_Ext') === 'NewBusiness';
    // paymentRequiredInd is coming from backend which tells us for which rewrite type we need to collect payment
    const isPaymentRequiredBackendInd = _get(rewriteVM, 'value.paymentRequiredInd_Ext');
    const requiresPaymentForEnvironment = isEnvWithPaymentus && isPaymentRequiredBackendInd;
    const isPaymentDone = !!_get(rewriteVM, 'bindData.value.paymentRefNumber');
    const isSignatureStatusAsCompleted = _get(rewriteVM, 'value.baseData.signature_Ext.status') === 'COMPLETED';
    // It is used to show loader message 'Preparing Required Documents' when gettingSignatureDocument is true
    const [gettingSignatureDocument, setGettingSignatureDocument] = useState(false);
    // It is used to show loader message 'Saving the Insureds contact information' when isInsuredContactUpdating is true
    const [isInsuredContactUpdating, setIsInsuredContactUpdating] = useState(false);
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);
    const { paymentRequiredForOpco } = appConfig;
    const { opCo } = amfamOktaToken;
    const [requiresPayment, setIsRequiresPayment] = useState(requiresPaymentForEnvironment && paymentRequiredForOpco.includes(opCo));
    const canUserIssueWithoutPayment = authUserData.permissions_Ext.includes('issuewithoutpayment_ext');

    const { isUserAuthorisedToPayAndBind } = e1pUserAccessUtil(authUserData, opCo);
    const canUserPayAndBindQuote = isUserAuthorisedToPayAndBind();

    const {
        hasUWIssues,
        showUnderwritingIssuesPopup
    } = useUWIssueUtil(
        rewriteVM,
        updateWizardData,
        jumpTo,
        steps
    );

    useEffect(() => {
        // It will make payment required if the environment, paymentRequiredInd and operating company condition matches (MSA)
        setIsRequiresPayment(requiresPaymentForEnvironment && paymentRequiredForOpco.includes(opCo))
    }, [opCo, paymentRequiredForOpco, requiresPaymentForEnvironment]);

    useEffect(() => {
        if (!isPaymentDone && requiresPayment) {
            PaymentHelper.getReceiptNumber(
                _get(rewriteVM, 'jobID.value', rewriteVM.jobID?.value), authHeader
            ).then((referenceNumber) => {
                // set value from receipt in case it did not get saved to PC
                if (referenceNumber) {
                    _set(rewriteVM, 'bindData.value.paymentRefNumber', referenceNumber);
                    updateWizardData(rewriteVM);
                }
            }).catch((err) => {
                loggerError(err);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isBillingComponentValid && isMailingComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isPageSubmitted, isBillingComponentValid, isMailingComponentValid]);

    const showPopupIfUWIssueExists = useCallback(() => {
        if (hasUWIssues() && !underwritingIssuesPopupAppeared) {
            showUnderwritingIssuesPopup();
            setUnderwritingIssuesPopupAppeared(true);
        }
    }, [hasUWIssues, showUnderwritingIssuesPopup, underwritingIssuesPopupAppeared]);

    const hasSignatureRequiredFormsInCurrentJob = rewriteVM.lobData.personalUmbrella_EU?.hasSignatureRequiredFormsInCurrentJob?.value;

    /**
     * Check quote data dto for validation errors and exceptions
     *   that come from in page api response (not onNext calls)
     */
    const checkForInPageErrors = useCallback(
        (qdd) => {
            const errorsAndWarnings = _get(qdd, 'errorsAndWarnings', []);

            // uw goes in pop up; not top banner
            delete errorsAndWarnings.underwritingIssues;

            const exceptionsPath = 'baseData.exceptions_Ext';
            const errors = parseErrors(errorsAndWarnings);
            const exceptions = _get(qdd, exceptionsPath, []);
            const errorConvertingToFullApp = errors?.length > 0 || exceptions.length > 0;

            if (errorConvertingToFullApp) {
                setValidationErrors(errors.concat(exceptions));
            }
        },
        [],
    );

    useEffect(() => {
        QuoteProposalUtil.fetchQuoteProposal(
            rewriteVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );
        // Above logic only needs to run once when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();

            if (quoteProposalLink) {
                window.open(quoteProposalLink, '_blank');

                return true;
            }
        }, [quoteProposalLink]
    );

    // When payment option changes, we need to get a new doc url
    useEffect(() => {
        if (rewriteVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                rewriteVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            rewriteVM.paymentOptionChanged = false;
        }
    }, [authHeader, rewriteVM, rewriteVM.paymentOptionChanged]);

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = _get(rewriteVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmail = _get(rewriteVM, 'lobData.personalUmbrella_EU.paperlessEmail.value');

        return isPaperlessEmailUpdated && pniEmail && pniEmail !== paperlessEmail;
    }, [rewriteVM, isPaperlessEmailUpdated]);

    const checkCanPurchase = useCallback(async () => {
        if (isPaymentDone && requiresPayment) {
            disableAllSteps();
        } else {
            setIsCallingCanPurchase(true);

            const response = await RewriteService.canPurchase(rewriteVM.value, authHeader);

            // have to set uw issues from the can purchase so the util can see them
            _set(
                rewriteVM.value,
                'errorsAndWarnings.underwritingIssues',
                response.errorsAndWarnings.underwritingIssues
            );

            // this is to hide paymentus component if there is any validation error
            const fieldError = _get(response, 'errorsAndWarnings.validationIssues.fieldIssues', []);

            setFieldIssue(!!(fieldError.length));

            const licensedProducers = _get(response, 'licensedProducers.licensedProducers', []);

            _set(rewriteVM.value, 'licensedProducers', response.licensedProducers);
            // check for validation errors
            checkForInPageErrors(response);
            // update wizard data with any uw issues and show if exists
            updateWizardData(rewriteVM);
            showPopupIfUWIssueExists();

            // set producer code options for signin agents
            if (licensedProducers.length > 0) {
                setProducerCodeOptions(
                    licensedProducers.map((value) => ({
                            code: value.producerID,
                            name: `${value.producerName.firstName} ${value.producerName.lastName}`
                        }))
                );
            }

            setIsCallingCanPurchase(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isVMInitialized) {
            initializeVM(rewriteVM);
            setIsVMInitialized(true);
        }
    }, [isVMInitialized, rewriteVM]);


    useEffect(() => {
        const initialPopUps = async () => {
            // if new forms are generated, we need to get licensed producers
            if (hasSignatureRequiredFormsInCurrentJob) {
                await checkCanPurchase();
            }
        };

        initialPopUps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSignatureRequiredFormsInCurrentJob]);

    const availableValuesForPaymentOptions = useMemo(() => {
        const correctOffering = rewriteVM.lobData.personalUmbrella_EU.offerings.value[0];

        if (correctOffering.paymentPlans !== undefined) {
            return correctOffering.paymentPlans
                .filter((paymentPlans) => paymentPlans.name)
                .reduce(
                    (paymentPlanMap, paymentPlan) => ({
                        ...paymentPlanMap,
                        [paymentPlan.billingId]: paymentPlan
                    }),
                    {}
                );
        }

        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rewriteVM.lobData.personalUmbrella_EU.offerings.value)]);

    const setErrors = useCallback((removeUnnecessaryErrors = false) => {
        const shouldShowMissingPaymentError = requiresPayment && !isPaymentDone && !canUserIssueWithoutPayment;
        const isSignatureMissing = _get(rewriteVM, 'baseData.signatureType_ext.value') === undefined || !signatureSuccess;
        const isUwIssuePresent = hasUWIssues();
        const uniqueErrors = PaymentPageUtil.getValidationErrorsForPaymentPage(
            validationErrors, shouldShowMissingPaymentError, isSignatureMissing,
            isUwIssuePresent, translator, removeUnnecessaryErrors);

        setValidationErrors(uniqueErrors);
    }, [validationErrors, requiresPayment, isPaymentDone, rewriteVM, signatureSuccess, hasUWIssues, translator, canUserIssueWithoutPayment]);

    useEffect(() => {
        if (signatureSuccess || isPaymentDone) {
            setErrors(true);
        }
        // want to trigger this only on signatureSuccess update and payment update
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureSuccess, isPaymentDone]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            setErrors();
            window.scrollTo(0, 0);

            return false;
        }

        setBindOperationsInProcess(true);

        const quoteData = _get(rewriteVM, 'quoteData');

        // If below int, we still need to set a payment ref number
        //   because we will still get a validation issue
        if (!requiresPayment) {
            const { paymentReferenceNumberForLocal } = appConfig.amfamRewritePaymentConfig;

            _set(rewriteVM, 'bindData.value.paymentRefNumber', paymentReferenceNumberForLocal);
        }

        const response = await RewriteService.bind([rewriteVM.value], authHeader);

        response.quoteData = quoteData.value;
        rewriteVM.value = response;
        setBindOperationsInProcess(false);
        updateWizardData(rewriteVM);

        return rewriteVM;
    }, [authHeader, isComponentValid, requiresPayment, rewriteVM, setErrors, updateWizardData]);

    /**
     * Helper callback which opens the "Third-Party Interest" wizard page.
     */
    const openTPIPageHandler = useCallback(() => {
        const indexForTPIPage = _findIndex(steps, ({ path }) => path === '/third-party-interest');

        jumpTo(indexForTPIPage);
    }, [steps, jumpTo]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isInsuredContactUpdating) {
            loadingMessage = translator(e1pCommonMessages.savingContactinfoMessage);
        } else if (gettingSignatureDocument) {
            loadingMessage = translator(e1pCommonMessages.prepareReqDocuments);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (bindOperationsInProcess) {
            loadingMessage = translator(messages.completingYourPurchaseMessage);
        }

        return loadingMessage;
    }, [isInsuredContactUpdating, gettingSignatureDocument, isSavingCurrentPageChanges, bindOperationsInProcess, translator]);

    const handleContactInfoUpdate = useCallback(async () => {
        // If paperless Email id exist and pni email id is undefiend, then update pni email to paperless email
        if (_isUndefined(_get(rewriteVM.value, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1'))
            && !!_get(rewriteVM.value, 'lobData.personalUmbrella_EU.paperlessEmail')) {
            _set(rewriteVM.value, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1',
                _get(rewriteVM.value, 'lobData.personalUmbrella_EU.paperlessEmail'));
        }

        const signatureType = _get(rewriteVM.value, 'baseData.signatureType_ext', undefined);

        rewriteVM.value = await RewriteService.saveAndQuote(
            [rewriteVM.value], authHeader
        );
        _set(rewriteVM.value, 'baseData.signatureType_ext', signatureType);
        updateWizardSnapshot(rewriteVM);
        updateWizardData(rewriteVM);
    }, [authHeader, rewriteVM, updateWizardData, updateWizardSnapshot]);

    const onSave = useCallback(
        async () => {
            try {
                if (!isMailingComponentValid || !isBillingComponentValid) {
                    updateIsPageSubmitted(true);
                    window.scrollTo(0, 0);

                    return false;
                }

                setIsSavingCurrentPageChanges(true);

                const signatureType = _get(rewriteVM.value, 'baseData.signatureType_ext', undefined);
                // We get licensedProducers in canPurchase call so storing it in variable before we do saveAndQuote
                const licensedProducers = _get(rewriteVM, 'value.licensedProducers');

                rewriteVM.value = await RewriteService.saveAndQuote(
                    [rewriteVM.value], authHeader
                );
                _set(rewriteVM.value, 'licensedProducers', licensedProducers);
                _set(rewriteVM.value, 'baseData.signatureType_ext', signatureType);
                QuoteProposalUtil.fetchQuoteProposal(
                    rewriteVM,
                    setQuoteProposalCompleted,
                    setQuoteProposalLink,
                    authHeader,
                    setIsQuoteProposalFailed
                );
                updateWizardData(rewriteVM);

                const fieldIssues = _get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        },
        [
            authHeader, isBillingComponentValid, isMailingComponentValid,
            rewriteVM, updateWizardData, updateWizardSnapshot
        ]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !bindOperationsInProcess
            && !isSavingCurrentPageChanges && !gettingSignatureDocument && !isInsuredContactUpdating,
    [bindOperationsInProcess, gettingSignatureDocument, isInsuredContactUpdating, isSavingCurrentPageChanges]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'top',
            readOnly: isPaymentDone && requiresPayment,
            autoComplete: false
        },
        paymentDetailsPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        paymentDetailsPageContainer: {
            visible: !bindOperationsInProcess
                && !isSavingCurrentPageChanges && !isInsuredContactUpdating,
            // Facing some issues, if i try to hide the container using visibility. 
            // Initially, visibility is false so all the components will be unmount 
            // when the visibility become true then all components will be mount again 
            // it will re-trigger all the react hook components so handled hiding using CSS
            className: gettingSignatureDocument ? "display-none" : "mt-8"
        },
        quoteProposalLinkContainer: {
            onClick: (e) => openDocument(e),
            disabled: !quoteProposalCompleted
        },
        monthlyPaymentScheduleComponent: {
            quoteID: _get(rewriteVM, 'quoteID.value', rewriteVM.jobID?.value),
            authHeader,
            transactionTotalAmount: !_isNil(
                availableValuesForPaymentOptions[
                _get(rewriteVM, 'bindData.selectedPaymentPlan.value')
                ]
            )
                ? availableValuesForPaymentOptions[
                    _get(rewriteVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined,
            changeInCost: rewriteVM.transactionCost?.value,
            startDate: rewriteVM.baseData.periodStartDate.value,
            endDate: rewriteVM.baseData.periodEndDate.value,
            jobTypeCode: rewriteVM.baseData.jobType.value.code,
            offerings: _get(rewriteVM, 'lobData.personalUmbrella_EU.offerings.value')
        },
        totalPremiumID: {
            value: !_isNil(
                availableValuesForPaymentOptions[
                _get(rewriteVM, 'bindData.selectedPaymentPlan.value')
                ]
            )
                ? availableValuesForPaymentOptions[
                    _get(rewriteVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined
        },
        totalPremiumWithFullPayID: {
            value: !_isNil(availableValuesForPaymentOptions[PAY_MONTHLY])
                ? availableValuesForPaymentOptions[PAY_MONTHLY].total
                : undefined
        },
        mailingAndBillingAddressComponent: {
            transactionVM: rewriteVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            viewOnlyMode: isPaymentDone && requiresPayment,
            updateIsMailingComponentValid,
            updateIsBillingComponentValid,
            showErrors: isPageSubmitted
        },
        paymentOptionsID: {
            submissionVM: rewriteVM,
            authHeader,
            updateWizardData,
            LOB,
            viewOnly: isPaymentDone
        },
        addThirdPartyInterestButton: {
            disabled: isPaymentDone && requiresPayment
        },
        // IAP-371 show TPI component in only read only Mode
        e1ptpiDisplayTableContainer: {
            transactionVM: rewriteVM,
            authHeader,
            updateWizardData,
            setIsSavingTPI: () => { },
            showErrors: false,
            onValidate: () => { },
            disregardFieldValidationParentPage: () => { },
            viewOnlyMode: true,
            showTitle: false
        },
        displayPNIAddress: {
            readOnly: true
        },
        paymentusComponent: {
            submissionVM: rewriteVM,
            wizardSnapshot,
            amfamAccessToken: amfamOktaToken.amfamAccessTokenHeader,
            updateWizardData,
            visible: canUserPayAndBindQuote
                && (!isPaymentDone || keepShowingIframeAfterPayment) && requiresPayment
                && !hasUWIssues()
                && !fieldIssue,
            setKeepShowingIframeAfterPayment,
            setValidationErrors,
            disableAllSteps,
            LOB
        },
        signatureComponent: {
            submissionVM: rewriteVM,
            updateWizardData,
            authHeader,
            LOB,
            onSignatureSuccess: setSignatureSuccess,
            disabled: signatureSuccess,
            readOnly: hasUWIssues(),
            producerCodeOptions,
            setProducerCodeOptions,
            setGettingSignatureDocument,
            // Only make it visible after payment ref number is available.
            //   If signarture is not required we don't want this visible and none
            //   of the logic should be executed in the component
            visible: hasSignatureRequiredFormsInCurrentJob && (isPaymentDone || !requiresPayment || canUserIssueWithoutPayment) && !isCallingCanPurchase,
            isSignatureRequired: hasSignatureRequiredFormsInCurrentJob,
            saveLocationCodeChanges: onSave,
            checkCanPurchase,
            locationCodeEditable: true
        },
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        insuredContactContainer: {
            visible: !isNewBusiness
        },
        deliverDocumentsIndComponent: {
            visible: authUserData.permissions_Ext.includes('docdeliveryind_ext'),
            deliverDocInd: _get(rewriteVM, 'value.shouldDeliverDocuments_Ext'),
            setDeliverDocInd: ((value) => {
                _set(rewriteVM, 'value.shouldDeliverDocuments_Ext', value);
                updateWizardData(rewriteVM);
            })
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        insuredContactInfo: {
            setIsPaperlessEmailUpdated,
            setIsInsuredContactUpdating
        },
        quoteProposalFailureErrorDiv: {
            visible: isQuoteProposalFailed
        },
        userNotAuthorisedErrorDiv: {
            visible: !canUserPayAndBindQuote
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            openTPIPageHandler,
            onValidate,
            onSaveClick: handleContactInfoUpdate
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, rewriteVM, id, path, overrideProps),
        [rewriteVM, overrideProps]
    );

    /**
     * Int and above need payment and signature from a licensed producer
     * Always stop if there are uw issue
     *
     */
    const isPageValid = useCallback(() => {
        if (requiresPayment && !isPaymentDone && !canUserIssueWithoutPayment) {
            return false;
        }

        const isQuoted = _get(rewriteVM, 'baseData.periodStatus.value.code') === 'Quoted';
        let isSignatureDone = _get(rewriteVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;

        let licensedProducerSelected = _get(rewriteVM, 'value.bindData.licensedProducerID', false);

        if (!hasSignatureRequiredFormsInCurrentJob) {
            // signature not required
            isSignatureDone = true;
            licensedProducerSelected = true;
        }

        return isQuoted && isSignatureDone && !hasUWIssues() && licensedProducerSelected;
    }, [requiresPayment, isPaymentDone, rewriteVM, signatureSuccess, hasSignatureRequiredFormsInCurrentJob, hasUWIssues, canUserIssueWithoutPayment]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, isPaymentDone, signatureSuccess, rewriteVM]);


    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            nextLabel={messages.issueLabel}
            onNext={onNext}
            disablePrevious={(isPaymentDone && requiresPayment) || gettingSignatureDocument || isInsuredContactUpdating}
            disableNext={gettingSignatureDocument || isInsuredContactUpdating}
            showNext={canUserPayAndBindQuote}
            showCancel={!isSignatureStatusAsCompleted}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && (!isMailingComponentValid || !isBillingComponentValid)
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PaymentDetailsPage.propTypes = wizardProps;
export default PaymentDetailsPage;
