import { defineMessages } from 'react-intl';

export default defineMessages({
    activities: {
        id: 'e1p.app.html.index.Activities',
        defaultMessage: 'Activities',
    },
    accounts: {
        id: 'e1p.app.html.index.Accounts',
        defaultMessage: 'Accounts',
    },
    policies: {
        id: 'e1p.app.html.index.Policies',
        defaultMessage: 'Policies',
    },
    claims: {
        id: 'e1p.app.html.index.Claims',
        defaultMessage: 'Claims',
    },
    commission: {
        id: 'e1p.app.html.index.Commission',
        defaultMessage: 'Commission',
    }
});
