import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import {
    get, set, isUndefined, isEmpty, filter, includes, find
} from 'lodash';
import { ConsoleHelper, PropertyFlowUtil, ClausesUtil as e1pClausesUtil } from 'e1p-portals-util-js';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useLandingPageUtil } from 'e1p-capability-hooks';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { EndorsementService } from 'e1p-capability-policychange';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useModal } from '@jutro/components';
import { PackageDifferenceComponent, ProtectiveDevices, PackageQualifyingEndorsementsComponent } from 'e1p-capability-policyjob-react';
import { commonMessages as e1pCommonMessages, ehCommonMessages } from 'e1p-platform-translations';
import PolicyChangeUtil from 'e1p-portals-util-js/PolicyChangeUtil';
import { QuoteProposalService } from 'e1p-capability-gateway';
import wizardMessages from '../../EHPolicyChangeWizard.messages';
import OptionalCoveragePopupComponent from '../../components/OptionalCoveragesPopup/OptionalCoveragesPopup';
import metadata from './CoveragesPage.metadata.json5';
import messages from './CoveragesPage.messages';
import styles from './CoveragesPage.module.scss';

const LOB = 'homeowners_EH';
const OPTIONAL_COVERAGE_CATEGORIES = [
    'EHLineAddGrp',
    'EH_OptionalProp',
    'EH_OptionalLiability',
    'EH_PersonalProtection',
    'EH_HomeBusiness',
    'EH_AdditionalProp',
    'EH_Home',
    'EH_PersonalBelongings',
    // 'EH_EndorsementPackage'
];

// convert OfferingDTO to CustomQuotedDTO structure
const structureCustomQuote = (policyChangeVM, clauses) => ({
        coverages: clauses.homeowners_EH
    });

const getCustomQuote = (vm, lobPath, lobName, filterChangedClauses = false) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages, lobName, null
        );
    }

    return structureCustomQuote(vm, clausesToUpdate);
};

function CoveragesPage(props) {
    const modalApi = useModal();
    const [loadingClause, updateLoadingClause] = useState();

    const translator = useTranslator();
    const {
        wizardData: policyChangeVM, updateWizardData, isSkipping, authHeader,
        steps,
        jumpTo,
        updateWizardSnapshot,
        authUserData,
        e1pGoNext
    } = props;
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const [selectedOptionalCoverages, updateSelectedOptionalCovs] = useState([]);
    const [coveragesModified, updateCoveragesModified] = useState(false);
    const [hasProtectiveDevices, setHasProtectiveDevices] = useState(false);
    const [isFetchingQuoteProposal, setIsFetchingQuoteProposal] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const [isQuoteStale, setIsQuoteStale] = useState(false);
    const policyState = get(policyChangeVM, 'baseData.policyAddress.state.value.code');
    const paperLessIndValue = get(policyChangeVM, 'lobData.homeowners_EH.paperlessInd.value') === undefined
        ? false : get(policyChangeVM, 'lobData.homeowners_EH.paperlessInd.value');
    const modifiers = get(policyChangeVM, 'lobData.homeowners_EH.modifiers.value', []);
    const hasGoldOrPlatinumBoxVisible = !!(get(policyChangeVM, 'lobData.homeowners_EH.offerings.value[0].coverages.coverages', [])
        .find(({ codeIdentifier }) => includes(['EH_GoldCoverageBundle', 'EH_PlatinumCoverageBundle'], codeIdentifier)));
    const {
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        registerComponentValidation,
        onValidate
    } = useValidation('CoveragesPage');

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);


    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    const hasExtendedAttributeEditability = authUserData.permissions_Ext.includes('extendedattributeeditability_ext');

    const changeSubmission = useCallback(
        (value, changedPath) => {
            set(policyChangeVM, changedPath, value);
            e1pClausesUtil.setClauseValue(policyChangeVM, value, changedPath);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = get(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(policyChangeVM, 'lobData.homeowners_EH.paperlessInd.value'); 
        const paperlessEmail = get(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value');
 
        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [policyChangeVM, isPaperlessEmailUpdated]);

    const checkHasProtectiveDevice = (vm) => {
        if (get(vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value').length > 0) {
            setHasProtectiveDevices(true);
        } else {
            setHasProtectiveDevices(false);
        }
    };

    const showProtectiveDevicePopup = useCallback(async () => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: true,
            showCancelBtn: true,
            submissionVM: policyChangeVM,
            viewModelService,
            translator,
            viewOnlyMode: false
        };
        const result = await modalApi.showModal(<ProtectiveDevices {...componentProps} />);

        return result;
    }, [policyChangeVM, translator, modalApi, viewModelService]);

    const onShowProtectiveDevicesPopup = useCallback(
        () => {
            try {
                showProtectiveDevicePopup().then((wrapperObj) => {
                    checkHasProtectiveDevice(wrapperObj.vm);

                    const responseFromPopup = get(
                        wrapperObj.vm,
                        'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value',
                        []
                    );

                    set(
                        policyChangeVM,
                        'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value',
                        responseFromPopup
                    );
                    set(
                        policyChangeVM,
                        'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd.value',
                        get(wrapperObj.vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd.value')
                    );
                    updateWizardData(policyChangeVM);
                }).catch(() => {
                    checkHasProtectiveDevice(policyChangeVM);
                });
            } catch {
                ConsoleHelper('user closed the modal', 'INFO');
            }
        }, [policyChangeVM, showProtectiveDevicePopup, updateWizardData]
    );

    const onProtectiveDevicesChange = useCallback((value) => {
        setHasProtectiveDevices(value);

        if (value) {
            onShowProtectiveDevicesPopup();
        } else {
            set(policyChangeVM, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value', []);
            set(
                policyChangeVM,
                'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd',
                undefined
            );
        }
    }, [onShowProtectiveDevicesPopup, policyChangeVM]);

    const writeValue = useCallback(
        (value, path) => {
            if (value !== undefined && path !== undefined) {
                set(policyChangeVM, path, value);
                updateWizardData(policyChangeVM);
            }
        },
        [policyChangeVM, updateWizardData]
    );

    const onUpdateCustomQuote = useCallback(
        async (_basePath, lobPath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(policyChangeVM, lobPath, lobName, false);
            const updatedCoverages = await EndorsementService.updateCoverages(
                policyChangeVM.value.jobID, [], { homeowners_EH: customQuote.coverages }, authHeader
            );
            const changedPath = lobPath.replace(/.children/, '');
            const updatedClauses = get(updatedCoverages, `${changedPath}.coverages`);
            const newpolicyChangeVM = viewModelService.clone(policyChangeVM);

            set(policyChangeVM, `${lobPath}.coverages`, updatedClauses);

            const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                policyChangeVM, newpolicyChangeVM, `${lobPath}.coverages.coverages`
            );
            const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                policyChangeVM, newpolicyChangeVM, `${lobPath}.coverages.schedules`
            );
            const allRemovedFields = [
                ...removedFieldsFromBaseCoverages, ...removedFieldsFromAdditionalCoverages
            ];

            disregardFieldValidation(allRemovedFields);
            updateWizardData(policyChangeVM);
            updateCoveragesModified(!coveragesModified);
            updateLoadingClause(undefined);
            setIsQuoteStale(true);

            return updatedCoverages;
        },
        [
            authHeader, disregardFieldValidation,
            policyChangeVM, updateWizardData,
            viewModelService, coveragesModified
        ]
    );

    const recalculate = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (!get(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value')
                && !!get(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value')) {
                set(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value',
                    get(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value'));
            }

            const quoteResponse = await  EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteResponse);
            setIsQuoteStale(false);
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);
            setIsSavingEndorsement(false);

            return false;
        },
        [authHeader, isComponentValid, policyChangeVM, updateWizardData, updateWizardSnapshot]
    );

    const onClauseChange = useCallback((schedule, path) => {
        const lobOfferingPath = 'lobData.homeowners_EH.offerings.children[0]';

        writeValue(schedule, path);

        return onUpdateCustomQuote({}, lobOfferingPath);
    }, [onUpdateCustomQuote, writeValue]);


    const syncClauses = useCallback(async () => {
        updateLoadingClause(true);
        await onClauseChange();
        updateWizardData(policyChangeVM);
    }, [onClauseChange, policyChangeVM, updateWizardData]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncClauses(value, changedPath);
        },
        [changeSubmission, syncClauses]
    );

    const onScheduleChange = useCallback(
        (schedule, path) => {
            const lobOfferingPath = 'lobData.homeowners_EH.offerings.children[0]';

            writeValue(schedule, `${path}.value`);

            return onUpdateCustomQuote({}, lobOfferingPath);
        },
        [onUpdateCustomQuote, writeValue]
    );

    const closePopupAction = useCallback(() => {
        updateCoveragesModified(!coveragesModified);
    }, [coveragesModified]);

    const updatePaperlessIndAndEmail = (value, path) => {
        set(policyChangeVM, path, value);

        switch (path) {
            case 'lobData.homeowners_EH.paperlessInd':
                if (value) {
                    const pniEmail = get(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value');

                    if (get(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value') === undefined) {
                        set(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value', pniEmail);
                    }
                } else {
                    set(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value', undefined);
                }

                break;
            default:
                break;
        }

        setIsPaperlessEmailUpdated(true);
        updateWizardData(policyChangeVM);
    };

    const updateIsThisNewHomePurchase = (value, path) => {
        set(policyChangeVM, path, value);
        updateWizardData(policyChangeVM);
    };

    const updateTPIOfTrust = (value, path) => {
        set(policyChangeVM, path, value);
        updateWizardData(policyChangeVM);
    }

    const saveAndQuote = useCallback(
        async (calledFromSaveOrCustom = false) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (!get(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value')
                && !!get(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value')) {
                set(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value',
                    get(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value'));
            }

            const quoteEndorsement = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteEndorsement);
            await PolicyChangeUtil.updatePolicyChangeVMCustomFields(policyChangeVM);
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);
            setIsQuoteStale(false)
            setIsSavingEndorsement(false);

            let validationErrors = get(policyChangeVM, 'errorsAndWarnings.validationIssues.fieldIssues', []);

            if (validationErrors.length === 0) {
                validationErrors = get(policyChangeVM, 'errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
            }

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            const hasConflicts = !isEmpty(get(policyChangeVM, 'value.conflicts', []));

            if (validationErrors.length === 0 && hasConflicts && !calledFromSaveOrCustom) {
                let newLandingPageIndex = -1;

                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );

                if (newLandingPageIndex >= 0) {
                    jumpTo(newLandingPageIndex, true);
                }

                return false;
            }

            return policyChangeVM;
        },
        [
            authHeader,
            getLandingPageIndexForQuotedJob,
            jumpTo,
            policyChangeVM,
            steps,
            updateWizardData,
            isComponentValid,
            updateWizardSnapshot

        ]
    );

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            const shouldContinue = await saveAndQuote();

            if (!shouldContinue) { return false; }

            return policyChangeVM;
        },
        [isComponentValid, saveAndQuote, policyChangeVM]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote(true);

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, policyChangeVM, updateWizardSnapshot]
    );


    const showWhatsIncludedPopup = async () => {
        const offerings = get(policyChangeVM, 'lobData.homeowners_EH.offerings.children[0]');

        const platinumOrGoldClauseIndex = offerings.value.coverages.coverages.findIndex(
            (clause) => clause.codeIdentifier === 'EH_PlatinumCoverageBundle' || clause.codeIdentifier === 'EH_GoldCoverageBundle'
        );

        if (platinumOrGoldClauseIndex === -1) {
            const componentProps = {
                coverages: offerings.coverages.coverages.value
            };
            const result = await modalApi.showModal(
                <PackageQualifyingEndorsementsComponent {...componentProps} />
            );

            return result;
        }

        const componentProps = {
            lob: 'Homeowners_EH'
        };
        // IAP-83: Gold and Platinum will continue to use existing component
        const result = await modalApi.showModal(
            <PackageDifferenceComponent {...componentProps} />
        );

        return result;
    };


    const showOptionalCoveragePopOver = useCallback(
        async () => {
            const offerings = get(policyChangeVM, 'lobData.homeowners_EH.offerings.children[0]');
            const goldBundledCov = offerings.value.coverages.coverages.find((clause) => clause.codeIdentifier === 'EH_GoldCoverageBundle');
            const platinumBundledCov = offerings.value.coverages.coverages.find((clause) => clause.codeIdentifier === 'EH_PlatinumCoverageBundle');
            const componentProps = {
                title: translator(messages.optionalCoverageLabel),
                iconClassType: false,
                showCancelBtn: false,
                showSaveBtn: true,
                policyChangeVM,
                updateWizardData,
                viewModelService,
                authHeader,
                closePopupAction,
                goldCovSelected: goldBundledCov ? goldBundledCov.selected : false,
                platinumCovSelected: platinumBundledCov ? platinumBundledCov.selected : false,
                translator,
                hasGoldOrPlatinumBoxVisible,
                policyState
            };
            const result = await modalApi.showModal(<OptionalCoveragePopupComponent {...componentProps} />);

            return result;
        },
        [
            authHeader, closePopupAction, hasGoldOrPlatinumBoxVisible,
            policyChangeVM, policyState, translator, updateWizardData,
            viewModelService, modalApi
        ]
    );

    const getTotalPremium = useCallback(() => {
        const payPlans = get(policyChangeVM.value, 'lobData.homeowners_EH.offerings[0].paymentPlans');
        const selectedPlan = payPlans.filter((plan) => plan.isSelected);
        const totalPremium = selectedPlan[0]?.total?.amount;

        if ((!totalPremium && totalPremium !== 0) || isQuoteStale) {
            return undefined;
        }

        return { currency: 'usd', amount: totalPremium };
    }, [isQuoteStale, policyChangeVM]);

    const policyType = get(policyChangeVM, 'value.policyType');
    const replacementCost = policyChangeVM.value.lobData.homeowners_EH
        .coverables.yourHome.valuation.estimatedReplacementCostAmount;
    // Optional coverage subtotal amount
    const scheduleList = get(policyChangeVM, 'lobData.homeowners_EH.offerings.value[0].coverages.schedules', []);
    let optionalCoverageSubtotalAmount = get(get(policyChangeVM, 'lobData.homeowners_EH.offerings.value[0].premiumSummary', [])
        .find((prem) => prem.premiumSummaryType === 'EHOptionalCvgSubtotal'), 'amount', 0);

    // Add schedule coverage total amount to optional coverage total
    optionalCoverageSubtotalAmount += get(scheduleList.find((schedule) => get(schedule, 'premiumSummary.premiumSummaryType') === 'TotalScheduleCoverage'), 'premiumSummary.amount', 0);

    const discountSubtotal = get(policyChangeVM, 'lobData.homeowners_EH.offerings.value[0].premiumSummary', [])
        .find((prem) => prem.premiumSummaryType === 'EHDiscountSavingsSubtotal')?.amount || 0;
    const primaryContentData = () => {
        if (!hasGoldOrPlatinumBoxVisible) {
            return translator(messages.optionalCoveragePackage);
        }

        return translator(messages.goldPlatinumPrimaryLabel);
    };
    const secondaryContentData = () => {
        if (!hasGoldOrPlatinumBoxVisible) {
            return (
                <React.Fragment>
                    {translator(messages.discountMessageSelect)}
                    <span className={styles.addIcon} />
                    {translator(messages.discountMessageLabel)}
                </React.Fragment>
            );
        }

        return translator(messages.goldPlatinumSecondaryLabel);
    };

    const getSelectedOptionalCov = () => {
        const newSubVM = viewModelService.clone(policyChangeVM);
        const tempSelectedOptionalCoverages = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).coverages.coverages.filter((coverage) => OPTIONAL_COVERAGE_CATEGORIES
                .indexOf(coverage.coverageCategoryCode.value) >= 0 && coverage.value.selected);
        const selectedExclusions = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).exclusions.filter((exclusion) => exclusion.selected.value === true);
        const selectedConditions = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).conditions.filter((condition) => condition.selected.value === true);

        // For state CT - check if special loss settlement is selected - add it to optional coverage
        if (policyState === 'CT') {
            const dwellingCovA = find(get(newSubVM.lobData.homeowners_EH.offerings, ['value', 0, 'coverages', 'coverages'], []), {codeIdentifier: 'EH_DwellingCovA'});
            const isSpecialLossSettlementSelected = !!find(get(dwellingCovA, ['terms'], []), (term) => term.chosenTerm && term.type === 'OptionEH_DwellingCovA_SpecialLossSettlementType');

            if (isSpecialLossSettlementSelected) {
                tempSelectedOptionalCoverages.push({
                    amount: undefined,
                    name: translator(ehCommonMessages.specialLossSettlement)
                });
            }
        }

        const selectedOptionalCoveragesExclusionsAndConditions = [
            ...tempSelectedOptionalCoverages,
            ...selectedExclusions,
            ...selectedConditions
        ];

        return selectedOptionalCoveragesExclusionsAndConditions;
    };

    useEffect(() => {
        updateCoveragesModified(false);

        const optionalSelectedCoverages = getSelectedOptionalCov();

        updateSelectedOptionalCovs({ selectedOptionalCoverages: optionalSelectedCoverages });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coveragesModified]);

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges;

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showErrors: isPageSubmitted,
            showRequired: true,
            autoComplete: false
        },
        coveragesPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: (isSavingCurrentPageChanges || isSavingEndorsement)
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        coveragesPageContainer: {
            visible: isPageLoaded
        },
        policyTermComponent: {
            transactionVM: policyChangeVM,
            authHeader,
            updateWizardData
        },
        quoteProposalLinkContainer: {
            onClick: (e) => {
                e.preventDefault();
                setIsFetchingQuoteProposal(true);
                QuoteProposalService.retriveQuoteProposalURL(
                    get(policyChangeVM, 'jobID.value'),
                    authHeader
                ).then((response) => {
                    window.open(response.quoteProposalURL, '_blank');
                    setIsFetchingQuoteProposal(false);
                }).catch(() => {
                    setIsFetchingQuoteProposal(false);
                });
            },
            disabled: policyChangeVM.baseData.periodStatus === 'Quoted'
                || isFetchingQuoteProposal
        },
        monthlyPaymentScheduleComponent: {
            quoteID: get(policyChangeVM, 'jobID.value'),
            authHeader,
            transactionTotalAmount: getTotalPremium(),
            changeInCost: policyChangeVM.transactionCost?.value,
            startDate: policyChangeVM.baseData.periodStartDate.value,
            endDate: policyChangeVM.baseData.periodEndDate.value,
            jobTypeCode: policyChangeVM.baseData.jobType.value.code,
            offerings: get(policyChangeVM, 'lobData.homeowners_EH.offerings.value')
        },
        paymentOptionsID: {
            submissionVM: policyChangeVM,
            authHeader,
            viewOnly: true,
            updateWizardData,
            LOB: 'homeowners_EH',
            isQuoteStale
        },
        OptionalCovTopGrid: {
            visible: policyType !== 'HO3' && policyType !== 'HF9',
        },
        paperlessInd: {
            required: true,
            value: get(policyChangeVM, 'lobData.homeowners_EH.paperlessInd.value'),
            onValueChange: updatePaperlessIndAndEmail,
            labelPosition: 'top',
        },
        isThisNewHomePurchase: {
            labelPosition: 'top',
            visible: (policyType === 'HO3' || policyType === 'HF9') && policyState !== 'MT',
            onValueChange: updateIsThisNewHomePurchase,
            required: true,
            disabled: true
        },
        isProtectiveDevicesMainGrid: {
            visible: policyType === 'HO3' || policyType === 'HF9' || policyType === 'HO6' || (policyType === 'HO4' && policyState === 'NC'),
        },
        isProtectiveDevicesIcon: {
            onClick: onShowProtectiveDevicesPopup,
            visible: hasProtectiveDevices,
            icon: 'mi-edit'
        },
        isProtectiveDevices: {
            value: hasProtectiveDevices,
            onValueChange: onProtectiveDevicesChange,
            // disabled: viewOnlyMode,
            required: true,
            labelPosition: 'top'
        },
        paperlessEmailId: {
            visible: paperLessIndValue,
            required: paperLessIndValue,
            onValueChange: updatePaperlessIndAndEmail,
            labelPosition: 'top',
        },
        tpiOfTrust: {
            onValueChange: updateTPIOfTrust,
            visible: policyType === 'HO3' && policyState === 'NC',
            labelPosition: 'top'
        },
        wizardPageHeader: { wizardSubmission: policyChangeVM },
        sectionICoverages: {
            loadingClause,
            uiGroup: 'Section I',
            hideLabel: true,
            labelPosition: 'top',
            policyType,
            replacementCost,
            steps,
            jumpTo,
            policyState,
            isQuoteStale
        },
        sectionIICoverages: {
            loadingClause,
            uiGroup: 'Section II',
            hideLabel: true,
            labelPosition: 'top',
            isQuoteStale
        },
        deductibles: {
            loadingClause,
            uiGroup: 'Deductibles',
            labelPosition: 'top',
            hasExtendedAttributeEditability,
            isQuoteStale
        },
        scheduleItemContainer: {
            loadingClause,
            categoryDisplayName: translator(messages.schedules)
        },
        addOrEditOptionalCovButton: {
            onClick: () => showOptionalCoveragePopOver()
        },
        seeOptionalCovButton: {
            onClick: () => showOptionalCoveragePopOver()
        },
        supportingPolicyComponentContainer: {
            transactionVM: policyChangeVM,
            LOB: 'homeowners_EH',
            updateWizardData,
            viewOnly: false,
            authHeader
        },
        GoldPlatinumGrid: {
            visible: policyType === 'HO3' || policyType === 'HF9'
        },
        goldPlatinumPrimaryHeading: {
            visible: policyType === 'HO3' || policyType === 'HF9',
            content: primaryContentData()
        },
        goldPlatinumSecondaryHeading: {
            visible: policyType === 'HO3' || policyType === 'HF9',
            content: secondaryContentData()
        },
        ehSurchargeListComponentId: {
            value: modifiers.filter(
                (item) => item.applied && item.modifierType !== "discount"
            )
        },
        ehDiscountsListComponentId: {
            visible: !!filter(modifiers, { applied: true, modifierType: 'discount' }).length,
            value: modifiers,
            discountsTotal: discountSubtotal
        },
        protectiveDeviceRequireInfoMessageBasedOnCovADiv: {
            visible: PropertyFlowUtil.isProtectiveDeviceRequiredMessageVisible(policyChangeVM)},
        protectiveDeviceRequireInfoMessageBasedOnCovA: {
            message: PropertyFlowUtil.contentBasedOnState(policyChangeVM, translator)
        },
        optionalCoverageSubtotalValue: {
            value: { currency: 'usd', amount: optionalCoverageSubtotalAmount }
        },
        windstormMitigationDiscountComponentContainer: {
            visible: (policyType === 'HO3' && includes(['NC', 'SC', 'CT'], policyState) || (policyType === 'HF9' && policyState === 'CT'))
        },
        windstormMitigationDiscountComponent: {
            transactionVM: policyChangeVM,
            viewOnly: false,
            updateWizardData,
            setFieldsChangedOnCoveragePage: () => { },
            policyState
        },
        windstormMitigationDiscountInfoMessageDiv: {
            visible: PropertyFlowUtil.isWindstormMitigationDiscountInfoMessagePresent(policyChangeVM)
        },
        ncrbDiscountAvailabilityMessageDiv: {
            visible: policyState === 'NC'
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        selectedOptionalCoverageDiv: {
            visible: getSelectedOptionalCov().length > 0
        },
        selectedOptionalCoverageLabel: {
            content: getSelectedOptionalCov().length > 0 ? translator(ehCommonMessages.selectedOptionalCovLabel) : translator(ehCommonMessages.emptyOptionalCovLabel)
        },
        buyNowButton: {
            visible: !isQuoteStale,
            disabled: !isUndefined(loadingClause),
            onClick: async () => {
                await onNext();

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    e1pGoNext();
                }
            }
        },
        recalculateButton: {
            visible: isQuoteStale,
            disabled: !isUndefined(loadingClause),
            onClick: () => {
                recalculate();
            }
        },
        totalPremiumID: {
            value: (() => getTotalPremium())()
        }
    };


    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange,
            onValidate,
            whatsIncludedHandler: showWhatsIncludedPopup

        },
    };
    const isPageLoading = useCallback(() => isUndefined(loadingClause), [loadingClause]);

    useEffect(() => {
        checkHasProtectiveDevice(policyChangeVM);
        // set isQuoteStale, when status is draft
        setIsQuoteStale(get(policyChangeVM, 'value.status') === 'Draft');
    }, [policyChangeVM]);


    useEffect(() => {
        registerComponentValidation(isPageLoading);
    }, [isPageLoading, registerComponentValidation]);

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            const shouldContinue = await saveAndQuote(true);

            if (!shouldContinue) { return false; }

            let newLandingPageIndex = -1;
            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            return false;
        },
        [
            isComponentValid,
            saveAndQuote,
            policyChangeVM,
            getLandingPageIndexForQuotedJob,
            steps,
            jumpTo
        ]
    );

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            showPrevious
            skipWhen={initialValidation}
            onNext={onNext}
            showNext={!isQuoteStale}
            showCustom1
            onCustom1={onCustom}
            disableNext={!isUndefined(loadingClause)}
            custom1Label={e1pCommonMessages.reviewChanges}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
            showCustom={isQuoteStale}
            customLabel={wizardMessages.recalculateButtonLabel}
            onCustom={recalculate}
            
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{ ...selectedOptionalCoverages, ...policyChangeVM }}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

CoveragesPage.propTypes = wizardProps;

export default withAuthenticationContext(CoveragesPage);
