import { defineMessages } from 'react-intl';

export default defineMessages({
    Notes: {
        id: 'e1p.gateway.views.activity-detail.Notes',
        defaultMessage: 'Notes'
    },
    Documents: {
        id: 'e1p.gateway.views.cancellation-detail.Documents',
        defaultMessage: 'Documents'
    },
    Claims: {
        id: 'e1p.gateway.views.policy-detail-summary.Claims',
        defaultMessage: 'Claims'
    },
    Billing: {
        id: 'e1p.gateway.views.policy-detail-summary.Billing',
        defaultMessage: 'Billing'
    },
    CancelPolicy: {
        id: 'e1p.gateway.views.policy-detail-summary.Cancel Policy',
        defaultMessage: 'Cancel Policy'
    },
    ChangePolicy: {
        id: 'e1p.gateway.views.policy-detail-summary.Change Policy',
        defaultMessage: 'Change Policy'
    },
    RenewPolicy: {
        id: 'e1p.gateway.views.policy-detail-summary.Start Renewal',
        defaultMessage: 'Start Renewal'
    },
    DeclarationPage: {
        id: 'e1p.gateway.views.policy-detail-summary.Declaration page',
        defaultMessage: 'Declaration page'
    },
    IdCard: {
        id: 'e1p.gateway.views.policy-detail-summary.ID Card',
        defaultMessage: 'ID Card'
    },
    Details: {
        id: 'e1p.gateway.views.policy-detail.Details',
        defaultMessage: 'Details'
    },
    SummaryDetails: {
        id: 'e1p.gateway.views.policy-detail.Summary Details',
        defaultMessage: 'Summary Details'
    },
    SummaryDetailsPreRenewalDirectionNone: {
        id: 'e1p.gateway.views.policy-detail.None',
        defaultMessage: 'None'
    },
    ProducerInformation: {
        id: 'e1p.gateway.views.policy-detail.Producer Information',
        defaultMessage: 'Producer Information'
    },
    PartnerInformation: {
        id: 'e1p.gateway.views.policy-detail.Partner Information',
        defaultMessage: 'Partner Information'
    },
    Partner: {
        id: 'e1p.gateway.views.policy-detail.Partner',
        defaultMessage: 'Partner'
    },
    PartnerMembershipLevel: {
        id: 'e1p.gateway.views.policy-detail.Membership Level',
        defaultMessage: 'Membership Level'
    },
    PartnerMembershipID: {
        id: 'e1p.gateway.views.policy-detail.Membership ID',
        defaultMessage: 'Membership ID'
    },
    AdvisorName: {
        id: 'e1p.gateway.views.policy-detail.Advisor Name',
        defaultMessage: 'Advisor Name'
    },
    Location: {
        id: 'e1p.gateway.views.policy-detail.Location',
        defaultMessage: 'Location'
    },
    AdvisorId: {
        id: 'e1p.gateway.views.policy-detail.Advisor ID',
        defaultMessage: 'Advisor ID'
    },
    Coverages: {
        id: 'e1p.gateway.views.policy-detail.Coverages',
        defaultMessage: 'Coverages'
    },
    PolicyInception: {
        id: 'e1p.gateway.views.policy-detail-summary.Policy Inception',
        defaultMessage: 'Policy Inception'
    },
    PolicyExpiration: {
        id: 'e1p.gateway.views.policy-detail-summary.Policy Expiration',
        defaultMessage: 'Policy Expiration'
    },
    ProducerOfRecord: {
        id: 'e1p.gateway.views.policy-detail.Producer of Record',
        defaultMessage: 'Producer of Record'
    },
    PreRenewalDirection: {
        id: 'e1p.gateway.views.policy-detail-summary.Pre-Renewal Direction',
        defaultMessage: 'Pre-Renewal Direction'
    },
    PolicyStatus: {
        id: 'e1p.gateway.views.policy-detail-summary.Policy Status',
        defaultMessage: 'Policy Status'
    },
    ProducerOfService: {
        id: 'e1p.gateway.views.policy-detail.Producer of Service',
        defaultMessage: 'Producer of Service'
    },
    PreferredUnderwriter: {
        id: 'e1p.gateway.views.policy-detail.Preferred Underwriter',
        defaultMessage: 'Preferred Underwriter'
    },
    TotalCost: {
        id: 'e1p.gateway.views.renewal.renewal-summary.Total Cost',
        defaultMessage: 'Total Cost'
    },
    NoneRenew: {
        id: 'e1p.gateway.views.policy-detail-summary.None',
        defaultMessage: 'None'
    },
    vehicles: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.vehicles',
        defaultMessage: 'Vehicles'
    },
    make: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.model',
        defaultMessage: 'Model'
    },
    year: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.year',
        defaultMessage: 'Year'
    },
    licensePlate: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.license plate',
        defaultMessage: 'License Plate'
    },
    hideVehicleCoverages: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.hide vehicle coverages',
        defaultMessage: 'Hide Vehicle Coverages'
    },
    showVehicleCoverages: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.show vehicle coverages',
        defaultMessage: 'Show Vehicle Coverages'
    },
    Coverage: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.coverage',
        defaultMessage: 'Coverage'
    },
    Deductible: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.deductible',
        defaultMessage: 'Deductible'
    },
    Limits: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.limits',
        defaultMessage: 'Limits'
    },
    PremiumBreakdown: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.premium breakdown',
        defaultMessage: 'Premium Breakdown'
    },
    vehicleInfoType: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.type',
        defaultMessage: 'Type'
    },
    vehicleInfoPolicy: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.Policy',
        defaultMessage: 'Policy'
    },
    coveredDrivers: {
        id: 'e1p.policy.pa.directives.policy-driver-info.Covered Drivers',
        defaultMessage: 'Covered Drivers'
    },
    coveredDriversName: {
        id: 'e1p.policy.pa.directives.policy-driver-info.Name',
        defaultMessage: 'Name'
    },
    coveredDriversLicense: {
        id: 'e1p.policy.pa.directives.policy-driver-info.License',
        defaultMessage: 'License'
    },
    licencePlate: {
        id: 'e1p.policy.pa.directives.policy-vehicle-info.license plate',
        defaultMessage: 'License Plate'
    },
    totalPremium: {
        id: 'e1p.integration.policy.directives.policy-coverages.Total Premium:',
        defaultMessage: 'Total Premium:'
    },
    riskanalysis: {
        id: 'e1p.gateway.views.riskanalysis-detail.Risk Analysis',
        defaultMessage: 'Risk Analysis'
    },
    loadingPolicy: {
        id: 'e1p.gateway.views.policies.summary.Loading Policy Transactions...',
        defaultMessage: 'Loading Policy Transactions...'
    },
    viewPolicyAsOfDate: {
        id: 'e1p.gateway.views.policy-detail-summary.View Policy As of Date',
        defaultMessage: 'View Policy As of Date'
    },
    loadingAgency: {
        id: 'e1p.gateway.views.policies.summary.Loading Agency Details...',
        defaultMessage: 'Loading Agency Details...'
    },
    addPreRenewalDirection: {
        id: 'e1p.gateway.views.policies.summary.Add Pre-Renewal Direction',
        defaultMessage: 'Add Pre-Renewal Direction'
    },
    deletePreRenewalDirection: {
        id: 'e1p.gateway.views.policies.summary.Delete Pre-Renewal Direction',
        defaultMessage: 'Delete Pre-Renewal Direction'
    },
    deletingPreRenewalDirection: {
        id: 'e1p.gateway.views.policies.summary.Deleting Pre-Renewal Direction...',
        defaultMessage: 'Deleting Pre-Renewal Direction...'
    },
    creatingRenewal: {
        id: 'e1p.gateway.views.policies.summary.Creating Renewal...',
        defaultMessage: 'Creating Renewal...'
    },
    deletePreRenewalDirectionFailedTitle: {
        id: 'e1p.gateway.views.policies.summary.Delete PreRenewal Direction Operation Failed',
        defaultMessage: 'Delete PreRenewal Direction Operation Failed'
    },
    deletePreRenewalDirectionFailedMessage: {
        id: 'e1p.gateway.views.policies.summary.Error occurred while deleting prerenewal direction for given policy',
        defaultMessage: 'Error occurred while deleting prerenewal direction for given policy'
    },
    createRenewalFailedTitle: {
        id: 'e1p.gateway.views.policies.summary.Create Renewal Failed',
        defaultMessage: 'Create Renewal Failed'
    },
    createRenewalFailedMessage: {
        id: 'e1p.gateway.views.policies.summary.Error occurred while performing Create Renewal for given policy',
        defaultMessage: 'Error occurred while performing Create Renewal for given policy'
    },
    renewTransactionTitle: {
        id: 'e1p.gateway.views.policies.summary.Start Renewal?',
        defaultMessage: 'Start Renewal?'
    },
    cancelLabel: {
        id: 'e1p.gateway.views.policies.summary.Cancel',
        defaultMessage: 'Cancel'
    },
    startRenewalLabel: {
        id: 'e1p.gateway.views.policies.summary.label.START RENEWAL',
        defaultMessage: 'START RENEWAL'
    },
    renewalConfirmationMessage: {
        id: 'e1p.gateway.views.policies.summary.The Renewal term for this policy will be created.',
        defaultMessage: 'The Renewal term for this policy will be created.'
    },
    reorderCreditButtonLabel: {
        id: 'e1p.gateway.views.policy-detail-summary.Reorder Credit',
        defaultMessage: 'Reorder Credit'
    }
});
