import React, {
    useContext, useCallback, useEffect
} from 'react';
import {
    get, set, sortBy, uniqBy
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import appConfig from 'app-config';
import messages from '../QuotePage.messages';

import metadata from '../QuotePage.metadata.json5';

function generateClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeCoveragePath = 'coverages.coverages';

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

function generateExclusionClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeExclusionPath = 'exclusions';

        return {
            code,
            path: `${lob.path}.${completeExclusionPath}`,
            clauses: get(lob.data, completeExclusionPath.replace(/\.children/, ''))
        };
    });
}

function generateConditionClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeConditionPath = 'conditions';

        return {
            code,
            path: `${lob.path}.${completeConditionPath}`,
            clauses: get(lob.data, completeConditionPath.replace(/\.children/, ''))
        };
    });
}

const getSelectedOffering = (submissionVM) => {
    const lobOfferingPath = 'lobData.homeowners_EH.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';
    const lobOfferings = get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = get(submissionVM, `${quoteOfferingPath}.value`);
    const selectedVersion = quoteOfferings.find((version) => version.selected);
    const lobIndex = lobOfferings.findIndex((offering) => offering.branchCode === selectedVersion.branchCode);

    return {
        lobOfferingPath,
        quoteOfferingPath,
        lobOfferings,
        quoteOfferings,
        selectedVersion,
        lobIndex
    };
};

const generateColumnData = (submissionVM) => {
    const selectedOffering = getSelectedOffering(submissionVM);

    let columnData = selectedOffering.lobOfferings.map((lobOffering, lobIndex) => {
        const quoteDataIndex = selectedOffering.quoteOfferings.findIndex(
            (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
        );
        const quoteData = selectedOffering.quoteOfferings[quoteDataIndex];

        return {
            name: lobOffering.branchName,
            code: lobOffering.branchCode,
            quote: {
                path: `${selectedOffering.quoteOfferingPath}.children[${quoteDataIndex}]`,
                data: quoteData
            },
            lob: {
                path: `${selectedOffering.lobOfferingPath}.children[${lobIndex}]`,
                data: lobOffering
            },
            submissionVM
        };
    });

    columnData = columnData.length > 1
        ? [columnData.find((offering) => offering.code === selectedOffering.selectedVersion.branchCode)]
        : columnData;

    return sortBy(columnData, ['code']);
};


const getExclusionsUniqueID = (submissionVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (exclusion) => ({
        publicID: exclusion.publicID,
        coverageCategoryCode: exclusion.exclusionCategoryCode,
        uigroup: exclusion.uigroup
    });

    const offerings = get(submissionVM, 'lobData.homeowners_EH.offerings.value');
    const exclusions = uniqBy(
        offerings.flatMap((offering) => offering.exclusions.map(structureClauseTableData)),
        'publicID'
    );

    return {
        exclusions
    };
};

const getConditionsUniqueID = (submissionVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (condition) => ({
        publicID: condition.publicID,
        coverageCategoryCode: condition.conditionCategoryCode,
        uigroup: condition.uigroup
    });

    const offerings = get(submissionVM, 'lobData.homeowners_EH.offerings.value');
    const conditions = uniqBy(
        offerings.flatMap((offering) => offering.conditions.map(structureClauseTableData)),
        'publicID'
    );

    return {
        conditions
    };
};

const getCoveragesUniqueID = (submissionVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (coverage) => ({
        publicID: coverage.publicID,
        coverageCategoryCode: coverage.coverageCategoryCode,
        uigroup: coverage.uigroup
    });
    const offerings = get(submissionVM, 'lobData.homeowners_EH.offerings.value');
    const offerringsWithCovsNotHidden = offerings.map((offerring) => {
        const offerringFormatted = { ...offerring};

        offerringFormatted.coverages.coverages = offerring.coverages.coverages
            .filter((coverage) => coverage.isHiddenInVersion !== true);

        return offerringFormatted;
    });
    const baseCoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages
            .coverages.map(structureClauseTableData)),
        'publicID'
    );
    const additionalCoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages
            .schedules.map(structureClauseTableData)),
        'publicID'
    );
    const sectionICoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Section I')
            .map(structureClauseTableData)),
        'publicID'
    );
    const sectionIICoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Section II')
            .map(structureClauseTableData)),
        'publicID'
    );
    const deductibles = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Deductibles')
            .map(structureClauseTableData)),
        'publicID'
    );

    return {
        baseCoverages,
        additionalCoverages,
        sectionICoverages,
        sectionIICoverages,
        deductibles
    };
};


const generateTableData = (submissionVM, columnData) => {
    const uniqueID = getCoveragesUniqueID(submissionVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateClauseData(columnData, coverageType)
    }));
};

const generateExclusionTableData = (submissionVM, columnData) => {
    const uniqueID = getExclusionsUniqueID(submissionVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateExclusionClauseData(columnData, coverageType)
    }));
};

const generateConditionTableData = (submissionVM, columnData) => {
    const uniqueID = getConditionsUniqueID(submissionVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateConditionClauseData(columnData, coverageType)
    }));
};

const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
const LOB = 'homeowners_EH';

function QuotePage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: submissionVM
    } = props;
    const translator = useTranslator();

    useEffect(() => {
        // displaying only one offering
        if (submissionVM.lobData.homeowners_EH.paperlessInd.value === undefined
            && get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'directentry') {
            set(submissionVM, 'lobData.homeowners_EH.paperlessInd.value', true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePrint = () => {
        window.print();
    };

    const checkComparatorFlow = useCallback(() => {
        if (get(submissionVM, 'baseData.quoteSource_Ext.sourceType')) {
            const isSourceTypeCompRater = get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'comprater';
            const quoteType = get(submissionVM, 'quoteType_Ext.value.code');

            return (isSourceTypeCompRater && quoteType === 'Quick');
        }

        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buyNow = useCallback(
        async () => submissionVM,
        [submissionVM]
    );

    const columnData = generateColumnData(submissionVM);

    const policyType = get(submissionVM, 'lobData.homeowners_EH.policyType.value.code');

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            readOnly: true
        },
        quotePageLoadingIndicator: {
            loaded: true
        },
        quoteProposalLinkId: {
            disabled: true
        },
        quoteTableHO3: {
            columnData,
            tableData: generateTableData(submissionVM, columnData),
            tableDataExclusion: generateExclusionTableData(submissionVM, columnData),
            tableDataCondition: generateConditionTableData(submissionVM, columnData),
            underwritingIssues: get(submissionVM, 'errorsAndWarnings.underwritingIssues.value') === undefined
                ? [] : get(submissionVM, 'errorsAndWarnings.underwritingIssues.value'),
            filterUWIssuesInCustomOffering: false,
            submissionVM,
            modifiers: get(submissionVM, 'lobData.homeowners_EH.modifiers.value'),
            isComprater: checkComparatorFlow(),
            visible: true,
            onDisableNextForTable: () => { },
            viewModelService,
            updateWizardData: () => { },
            authHeader,
            jumpTo: () => { },
            steps: [],
            setFieldsChangedOnCoveragePage: () => { },
            fieldsChangedOnCoveragePage: false,
            policyType,
            quoteIsStale: false,
            onScheduleChange: () => { },
            viewOnlyMode: true
        },
        startDate: {
            visible: showQuoteStartDate
        },
        printPage: {
            visible: !showQuoteStartDate
        },
        e1pAcknowledgementComponent: {
            acknowledgements: get(submissionVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: get(submissionVM, 'baseData.policyAddress.state.value.code'),
                name: translator({ id: get(submissionVM, 'baseData.policyAddress.state.value.name') })
            },
            visible: checkComparatorFlow(),
            viewOnly: true,
            lob: LOB
        },
        notificationContainer: {
            visible: false
        },
        enterSideBySide: {
            visible: false
        },
        sideBySideContent: {
            visible: false
        },
        quoteProposalValidationDiv: {
            visible: false
        },
        quoteProposalFailureErrorDiv: {
            visible: false
        },
        ncrbDiscountAvailabilityMessageDiv: {
            visible: false
        },
        paperlessEmailChangedMessageDiv: {
            visible: false
        },
        prefillReportsOrderedForRatingDiv: {
            visible: false
        },
        protectiveDeviceRequireInfoMessageBasedOnCovADiv: {
            visible: false
        },
        deleteVersionsMessageDiv: {
            visible: false
        },
        maxVersionsMessageDiv: {
            visible: false
        },
        minVersionsMessageDiv: {
            visible: false
        },
        ACVAppliedMessageDiv: {
            visible: false
        },
        windstormMitigationDiscountInfoMessageDiv: {
            visible: false
        }
    };

    return (
        <WizardPage
            shouldLink
            showNext={get(submissionVM, 'value.quoteType') === 'Full'}
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveCallbackMap: {
                        onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
                        onRecalculate: () => { },
                        onResetQuote: () => { },
                        onChangeSubmissionAndSync: () => { },
                        onChangeSubmission: () => { },
                        onSyncCoverages: () => { },
                        onScheduleChange: () => { },
                        onStaleQuoteBranchCode: () => { },
                        onPrint: handlePrint,
                        updateWizardData: () => { },
                        guidelinesCheckHandler: () => { },
                        legalDisclosureCheckHandler: () => { }
                    }
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onModelChange={() => { }}
                        onValidationChange={() => { }}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                );
            }}
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
