import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    SideBySideActionBarComponent
} from 'e1p-capability-policyjob-react';
import EaSideBySideQuoteDetailsComponent from '../EaSideBySideQuoteDetailsComponent/EaSideBySideQuoteDetailsComponent';
import EaSideBySidePolicyLevelComponent from '../EaSideBySidePolicyLevelComponent/EaSideBySidePolicyLevelComponent';
import EaSideBySideVehicleLevelComponent from '../EaSideBySideVehicleLevelComponent/EaSideBySideVehicleLevelComponent';

function EaSideBySideContainer(props) {
    const {
        lobOfferings,
        quoteDataOfferings,
        backButtonFunction,
        updateQdd,
        setNewQdd,
        updateQuoteVersionName,
        addVersionFunction,
        setSelectedVersion,
        onRecalculateSideBySide,
        onWithdrawSideBySideVersion,
        onViewQuoteProposal,
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        onValidate,
        hasExtendedAttributeEditability,
        policyState,
        isQuoteProposalReady,
        vehicles,
        showErrors
    } = props;

    
    const [quoteInDraftStatus, setQuoteInDraftStatus] = useState(false);

    useEffect(() => {
        if (quoteDataOfferings.findIndex((quote) => quote.status === 'Draft') !== -1) {
            setQuoteInDraftStatus(true);
        } else {
            setQuoteInDraftStatus(false);
        }
    }, [quoteDataOfferings])

    return (
        <React.Fragment>
            <SideBySideActionBarComponent id='ActionBar' 
                backButtonFunction={backButtonFunction}
                addVersionFunction={addVersionFunction}
                onRecalculateSideBySide={onRecalculateSideBySide}
                onViewQuoteProposal={onViewQuoteProposal}
                isQuoteProposalReady={isQuoteProposalReady}
                quoteInDraftStatus={quoteInDraftStatus}
            />
            <EaSideBySideQuoteDetailsComponent
                lobOfferings={lobOfferings}
                quoteDataOfferings={quoteDataOfferings}
                updateQdd={updateQdd}
                setNewQdd={setNewQdd}
                updateQuoteVersionName={updateQuoteVersionName}
                setSelectedVersion={setSelectedVersion}
                onWithdrawSideBySideVersion={onWithdrawSideBySideVersion}
            />
            <EaSideBySidePolicyLevelComponent
                lobOfferings={lobOfferings}
                onChangeSubmissionAndSync={changeSubmissionAndSync}
                onChangeSubmission={changeSubmission}
                onSyncCoverages={syncCoverages} 
                onValidate={onValidate}
                hasExtendedAttributeEditability={hasExtendedAttributeEditability}
                quoteDataOfferings={quoteDataOfferings}
                showErrors={showErrors}
                policyState={policyState}/>
            <EaSideBySideVehicleLevelComponent
                lobOfferings={lobOfferings}
                onChangeSubmissionAndSync={changeSubmissionAndSync}
                onChangeSubmission={changeSubmission}
                onSyncCoverages={syncCoverages} 
                onValidate={onValidate}
                hasExtendedAttributeEditability={hasExtendedAttributeEditability}
                policyState={policyState}
                quoteDataOfferings={quoteDataOfferings}
                showErrors={showErrors}
                vehicles={vehicles}/>
        </React.Fragment>
    );
}

EaSideBySideContainer.propTypes = {
    lobOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    quoteDataOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    backButtonFunction: PropTypes.func.isRequired,
    updateQdd: PropTypes.func.isRequired,
    setNewQdd: PropTypes.func.isRequired,
    updateQuoteVersionName: PropTypes.func.isRequired,
    addVersionFunction: PropTypes.func.isRequired,
    setSelectedVersion: PropTypes.func.isRequired,
    onRecalculateSideBySide: PropTypes.func.isRequired,
    onWithdrawSideBySideVersion: PropTypes.func.isRequired,
    onViewQuoteProposal: PropTypes.func.isRequired,
    policyState: PropTypes.string.isRequired,
    hasExtendedAttributeEditability: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
    isQuoteProposalReady: PropTypes.bool.isRequired,
    vehicles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    showErros: PropTypes.bool
};

EaSideBySideContainer.defaultProps = {
    showErrors: false
}

export default EaSideBySideContainer;
