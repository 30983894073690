import { defineMessages } from 'react-intl';

export default defineMessages({
    ratingYourPolicyMessage: {
        id: 'e1p.policyview.eh.directives.MultiQuoteView.Please wait while we rate your policy...',
        defaultMessage: 'Please wait while we rate your policy...'
    },
    creatingYourOfferingMessage: {
        id: 'e1p.policyview.eh.directives.MultiQuoteView.Please wait while we create your offering...',
        defaultMessage: 'Please wait while we create your offering...'
    },
    baseCoverages: {
        id: 'e1p.policyview.eh.directives.HoOfferingSelection.defaultBaseCoverages.General Coverages',
        defaultMessage: 'General Coverages'
    },
    additionalCoverages: {
        id: 'e1p.policyview.eh.directives.HoOfferingSelection.defaultAdditionalCoverages.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    },
    sectionICoverages: {
        id: 'e1p.policyview.eh.directives.HoOfferingSelection.defaultAdditionalCoverages.sectionICoverages',
        defaultMessage: 'Section I Coverages'
    },
    sectionIICoverages: {
        id: 'e1p.policyview.eh.directives.HoOfferingSelection.defaultAdditionalCoverages.sectionIICoverages',
        defaultMessage: 'Section II Coverages'
    },
    deductibles: {
        id: 'e1p.policyview.eh.directives.HoOfferingSelection.defaultAdditionalCoverages.Deductibles',
        defaultMessage: 'Deductibles'
    }
});
