import React, {
    useCallback, useContext, useState, useEffect
} from 'react';
import {
    get as _get, set as _set
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { useViewPolicyUtil } from 'e1p-capability-hooks';
import { RandomNumberUtil } from 'e1p-portals-util-js';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import moment from 'moment';
import messages from '../InsuredDetailsPage.messages';
import metadata from '../InsuredDetailsPage.metadata.json5';


const LOB = 'personalUmbrella_EU';

function ViewInsuredDetailsPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        authUserData
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const { authHeader } = useAuthentication();
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const translator = useTranslator();
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [policyIsBackDated, setPolicyIsBackDated] = useState(false);
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const {
        getLocationAndProducerCodes,
        getProducerDetails
    } = useViewPolicyUtil();
    const sessionIdPrefixEdit = 'EH - VW - ';
    const sessionHeader = sessionIdPrefixEdit + RandomNumberUtil.randomFixedInteger(10);
    const {
        onValidate
    } = useValidation('euInsuredDetailsPage');

    const onNext = useCallback(() => policyChangeVM, [policyChangeVM]);

    const generateOverrides = useCallback(() => {
        const overrides = {};

        overrides.secondaryNamedInsured = {
            viewOnlyMode: true,
            hideSSN: true
        };
        overrides.primaryNamedInsured = {
            viewOnlyMode: true,
            hideSSN: true,
            isPNI: true
        };

        return overrides;
    }, []);


    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location, authHeader, setLocationAndProducerCodes);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(policyChangeVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(policyChangeVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    useEffect(() => {
        if (policyChangeVM.baseData.externalID_Ext.value) {
            getProducerDetails(
                policyChangeVM.baseData.externalID_Ext.value, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
                setAgencyName(details.agencyName);
                setBookTransferIndicator(details.bookTransferIndicator);
                setServiceCenterIndicator(details.serviceCenterIndicatorValue);
            }).catch((error) => {
                /**
                * E1PAP1PC-15099 :
                * This is rare scenario; will never happen in production
                * When we bound policy using dummy producer code
                * and when other user tries to view this policy we get exception from service
                */
                if (policyChangeVM.baseData.value.exceptions_Ext) {
                    policyChangeVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        policyChangeVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(policyChangeVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyChangeVM.baseData.externalID_Ext.value, authHeader]);

    const setBackdateInformation = () => {
        const policyEffectiveDate = moment(policyChangeVM.baseData.effectiveDate.value);
        const effectiveDateBeforeToday = moment().isAfter(policyEffectiveDate, 'd');

        setPolicyIsBackDated(effectiveDateBeforeToday);
    };

    useEffect(() => {
        _set(policyChangeVM, 'baseData.producerCode_Ext.value', policyChangeVM.value.baseData.producerCode);

        if (isAgent) {
            // only look up for agents
            getAndSetLocationCodes(_get(policyChangeVM, 'baseData.policyAddress.state.value.code'));
        }

        setBackdateInformation();
        authHeader['afe-session-id'] = sessionHeader;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(policyChangeVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(policyChangeVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);
        }
    }, [locationAndProducerCodes, policyChangeVM]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        insuredDetailsPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.loadingNextPageMessage)
        },
        insuredDetailsPageContainer: {
            visible: true
        },
        mailingAndBillingAddressComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            viewOnlyMode: true
        },
        addNewPni: {
            visible: false
        },
        addSni: {
            visible: false
        },
        sniContainer: {
            visible: !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        sniInformation: {
            visible: !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        swapPniSni: {
            visible: false
        },
        coverageRateAsOfDate: {
            dateDTO: policyChangeVM.baseData.periodRateAsOfDate,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        policyInformationInfoContainer: {
            columns: authUserData.permissions_Ext.includes('editrateasofdate')
                ? ['0.25fr', '0.25fr', '0.25fr', '0.25fr'] : ['0.25fr', '0.25fr', '0.25fr']
        },
        changeEffectiveDate: {
            dateDTO: policyChangeVM.baseData.effectiveDate,
            updateDateDto: () => { },
            showErrors: true,
            onValidate
        },
        ExternalProducerDetailsComponent: {
            viewOnlyMode: true,
            defaultValue: policyChangeVM.externalID_Ext?.value
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        changePniComponent: {
            visible: false
        },
        changeSniComponent: {
            visible: false
        },
        insuredResidenceAddressWarningMessageDiv: {
            visible: false
        },
        lossQuestionEmptyContainer: {
            visible: !policyIsBackDated,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 3 : 2
        },
        lossQuestionContainer: {
            visible: policyIsBackDated,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 3 : 2
        },
        agencyInformationMainDiv: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: policyChangeVM,
            authHeader,
            LOB,
            viewOnlyMode: true
        },
        removeSni: {
            visible: false
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: () => { },
            onValidate,
            onRemoveSni: () => { },
            onAddNewPni: () => { },
            onSwapPniSni: () => { }
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

ViewInsuredDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewInsuredDetailsPage);
