import React, { useState } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import metadata from './RatingDateTestComponent.metadata.json5';
import messages from './RatingDateTestComponent.messages';

const RatingDateTestComponent = (props) => {
    const {
        ratingDateObject,
        quoteCreationDateObject,
        setTestRatingDate,
        setTestQuoteCreationDate,
        overriddenExperienceId,
        setOverriddenExperienceId,
        canChangeExperienceId
    } = props;
    const translator = useTranslator();
    const [showFields, setShowFields] = useState(false);

    const overrideProps = {
        '@field': {
            autoComplete: false,
            labelPosition: 'left'
        },
        quoteCreationDate: {
            updateDateDto: (newValue) => setTestQuoteCreationDate(newValue),
            dateDTO: quoteCreationDateObject
        },
        ratingDate: {
            updateDateDto: (newValue) => setTestRatingDate(newValue),
            dateDTO: ratingDateObject
        },
        showRatingFieldsLink: {
            onClick: () => { setShowFields(!showFields); },
            content: showFields ? translator(messages.hideFields) : translator(messages.changeVersion)
        },
        rateFieldCard: {
            visible: showFields
        },
        experienceId: {
            visible: canChangeExperienceId,
            availableValues: [
                {name: 'MSA', code: '7119'},
                {name: 'Connect Costco', code: '7192'},
                {name: 'Ameriprise Direct', code: '7168'},
                {name: 'Ameriprise Advisor Referral', code: '7165'},
            ],
            value: overriddenExperienceId,
            onValueChange: (value) => setOverriddenExperienceId(value)
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={{ ratingDate: ratingDateObject, quoteCreationDate: quoteCreationDateObject }}
            overrideProps={overrideProps}
        />
    );
};

RatingDateTestComponent.propTypes = {
    quoteCreationDateObject: PropTypes.shape({}),
    ratingDateObject: PropTypes.shape({}),
    setTestRatingDate: PropTypes.func.isRequired,
    setTestQuoteCreationDate: PropTypes.func.isRequired,
    overriddenExperienceId: PropTypes.string,
    setOverriddenExperienceId: PropTypes.func,
    canChangeExperienceId: PropTypes.bool
};

RatingDateTestComponent.defaultProps = {
    quoteCreationDateObject: undefined,
    ratingDateObject: undefined,
    overriddenExperienceId: undefined,
    setOverriddenExperienceId:undefined,
    canChangeExperienceId: false
};

export default RatingDateTestComponent;
