import React, { Component } from 'react';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { withModalContext } from '@jutro/components';
import { WizardPage } from 'e1p-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ReinstatementService } from 'e1p-capability-reinstatement';
import { RandomNumberUtil } from 'e1p-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import { get, set } from 'lodash';
import metadata from './StartReinstatementPage.metadata.json5';
import messages from './StartReinstatementPage.messages';
import styles from './StartReinstatementPage.module.scss';

class StartReinstatementPageWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    state = {
        reinstatementVM: {},
        reinstatementDataVM: {},
        isProcessingReinstatement: false,
        sourceValues: []
    }

    componentDidMount() {
        const { authHeader, wizardData } = this.props;
        const sessionIdPrefixEdit = 'AL - RI - ';
        let { policyVM } = wizardData;
        let { jobNumber } = wizardData;
        let effectiveDate = get(policyVM, 'latestPeriod.value.cancellationDate');

        // When user comes for first time or by clicking on continue button
        // wizardData contains { policyVM and jobNumber}
        // If user comes from bind page by clicking on previous
        // wizardData contains reinstatementDataVM
        if (!policyVM) {
            policyVM = wizardData;
            jobNumber = get(wizardData, 'value.jobID');
            effectiveDate = get(wizardData, 'effectiveDate_Ext.value');
        }

        const { policyNumber } = policyVM;
        const model = {
            policyNumber: policyNumber.value,
            effectiveDate_Ext: effectiveDate
        };

        const reinstatementDTO = this.createVM(model);

        this.setState(
            {
                reinstatementVM: reinstatementDTO
            }
        );
        this.handleSourceValues(reinstatementDTO);

        if (jobNumber) {
            authHeader['afe-session-id'] = sessionIdPrefixEdit + jobNumber;
            ReinstatementService.retrieve(jobNumber, authHeader).then((response) => {
                if (response) {
                    const reinstatementDataDTO = this.createDataVM(response);

                    reinstatementDataDTO.effectiveDate_Ext.value ??= response.effectiveDate;
                    this.setState(
                        {
                            reinstatementDataVM: reinstatementDataDTO
                        }
                    );
                    this.setState((prevState) => (
                        {
                            reinstatementVM: {
                                ...prevState.reinstatementVM,
                                effectiveDate_Ext: response.effectiveDate
                            }
                        }
                    ));
                }
            });
        }
    }

    handleSourceValues = (reinstatementDTO) => {
        const { authUserData } = this.props;
        const { reinstatementDataVM, reinstatementVM } = this.state;
        const translator = this.context;
        const hasChangeSource1 = authUserData?.permissions_Ext.includes('chgsource1_ext');
        const hasChangeSource2 = authUserData?.permissions_Ext.includes('chgsource2_ext');
        const sources = reinstatementDTO.source_Ext.aspects.availableValues
            .filter((item) => item.code !== 'tpi')
            .map((item) => ({
                    code: item.code,
                    name: translator({
                        id: item.name,
                        defaultMessage: item.name
                    })
                }));

        if (hasChangeSource1 && !hasChangeSource2) {
            const permittedSources = sources.filter((item) => item.code === 'insuredoragent');

            this.setState({ sourceValues: permittedSources });
            set(reinstatementVM, 'source_Ext', permittedSources);
            set(reinstatementDataVM, 'baseData.source_Ext', permittedSources);
        } else if (hasChangeSource2) {
            this.setState({ sourceValues: sources });
            set(reinstatementVM, 'source_Ext', sources);
            set(reinstatementDataVM, 'baseData.source_Ext', sources);
        }
    }

    createVM = (model) => {
        const { viewModelService } = this.props;

        return viewModelService.create(model,
            'pc', 'edge.capabilities.gateway.job.reinstatement.dto.ReinstatementDTO');
    };

    createDataVM = (model) => {
        const { viewModelService } = this.props;

        return viewModelService.create(model,
            'pc', 'edge.capabilities.reinstatement.dto.ReinstatementDataDTO');
    };

    onNext = async () => {
        this.setState({ isProcessingReinstatement: true });

        const { reinstatementVM } = this.state;
        const { authHeader } = this.props;
        const { wizardSnapshot, updateWizardData } = this.props;
        const { jobNumber } = wizardSnapshot;
        const sessionIdPrefixEdit = 'AL - RI - ';

        authHeader['afe-session-id'] = sessionIdPrefixEdit + RandomNumberUtil.randomFixedInteger(10);

        if (!jobNumber) {
            try {
                const response = await ReinstatementService
                    .createPolicyReinstatementTransaction(reinstatementVM.value, authHeader);
                // check for exception 
                if ( response?.exceptions_Ext ) {
                    reinstatementVM.value.exceptions_Ext = response.exceptions_Ext;
                    updateWizardData(reinstatementVM);
                    this.setState({ isProcessingReinstatement: false });
                
                    return false;
                }

                const newReinstatementVM = this.createDataVM(response);
                updateWizardData(newReinstatementVM);
                this.setState({ 
                    reinstatementDataVM: newReinstatementVM,
                    isProcessingReinstatement: false 
                });
                authHeader['afe-session-id'] = sessionIdPrefixEdit + get(newReinstatementVM, 'jobID.value');
                
                return newReinstatementVM;
            } catch {
                this.setState({ isProcessingReinstatement: false });
                this.props.modalContext.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: messages.errorStartingReinstatement,
                    message: messages.returnStarting,
                }).then(() => false);
            }
        } else {
            const { reinstatementDataVM } = this.state;

            this.setState({ isProcessingReinstatement: false });
            authHeader['afe-session-id'] = sessionIdPrefixEdit + get(reinstatementVM, 'jobID.value');

            return reinstatementDataVM;
        }

    }

    generateOverrides = () => {
        const overrideProps = {};
        const { reinstatementDataVM, sourceValues } = this.state;

        if (reinstatementDataVM.baseData && reinstatementDataVM.value?.baseData?.reason) {
            overrideProps.reinstatecode = {
                visible: false
            };
            overrideProps.reinstatecodeData = {
                visible: true,
                readOnly: true
            };
        } else {
            overrideProps.reinstatecodeData = {
                visible: false
            };
            overrideProps.reinstatecode = {
                visible: true
            };
        }

        if (reinstatementDataVM.baseData && reinstatementDataVM.value?.baseData?.source_Ext) {
            overrideProps.sourceType = {
                visible: false,
                availableValues: sourceValues
            };
            overrideProps.sourceTypeData = {
                visible: true,
                readOnly: true,
                availableValues: sourceValues
            };
        } else {
            overrideProps.sourceTypeData = {
                visible: false,
                availableValues: sourceValues
            };
            overrideProps.sourceType = {
                visible: true,
                availableValues: sourceValues
            };
        }

        return overrideProps;
    }

    render() {
        const { updateWizardData } = this.props;
        const { reinstatementVM, reinstatementDataVM, isProcessingReinstatement } = this.state;
        const translator = this.context;

        const resolvers = {
            resolveCallbackMap: {
                onStartReinstate: this.onStartReinstate
            },
            resolveClassNameMap: styles
        };

        const overrideProps = {
            startReinstatementPageLoadingIndicator: {
                loaded: !isProcessingReinstatement,
                text: translator(messages.ratingYourPolicyMessage)
            },
            startReinstatementPageContainer: {
                visible: !isProcessingReinstatement
            },
            ...this.generateOverrides()
        };

        return (
            <WizardPage
                onNext={this.onNext}
                showPrevious={false}
                disableNext={false}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={{ reinstatementVM, reinstatementDataVM }}
                    overrideProps={overrideProps}
                    onValueChange={updateWizardData}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </WizardPage>
        );
    }
}

const StartReinstatementPage = withModalContext(StartReinstatementPageWithoutModalContext);

export default withViewModelService(withAuthenticationContext(StartReinstatementPage));
