import { defineMessages } from 'react-intl';

export default defineMessages({
    thirdPartyValue: {
        id: 'components.thirdPartyReport.Value',
        defaultMessage: 'Value'
    },
    thirdPartyDescription: {
        id: 'components.thirdPartyReport.Description',
        defaultMessage: 'Description'
    },
    thirdPartySourceType: {
        id: 'components.thirdPartyReport.Source Type',
        defaultMessage: 'Source Type'
    },
    thirdPartyUsedInRating: {
        id: 'components.thirdPartyReport.Used in Rating',
        defaultMessage: 'Used in Rating'
    }
});
