import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    cloneDeep as _cloneDeep
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import {
    useEffectiveDateUtil,
    useSniUtil,
    e1pUSStatesUtil,
    useNewBusinessUtil
} from 'e1p-capability-hooks';
import appConfig from 'app-config';
import messages from './InsuredDetailPage.messages';
import metadata from './InsuredDetailPage.metadata.json5';

const LOB = 'personalUmbrella_EU';

function InsuredDetailPage(props) {
    const modalApi = useModal();
    const {
        initialValidation,
        isComponentValid,
        onValidate,
        disregardFieldValidation
    } = useValidation('InsuredDetailPage');
    const {
        wizardData: rewriteVM,
        updateWizardData,
        updateWizardSnapshot
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const translator = useTranslator();
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [isLoading, updateIsLoading] = useState(true);
    const [isComponentInitialized, setIsComponentInitialized] = useState(false);
    const [USStates, setUSStates] = useState([]);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const policyState = _get(rewriteVM, 'baseData.policyAddress.state.value.code');
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const { daysInFutureForBookroll, daysInFuture } = appConfig.dateInputConfig;
    const {
        handlePeriodStartDateChange,
        getDefaultPeriodEndDate,
        getEffectiveDateBoundsForSubmission
    } = useEffectiveDateUtil(rewriteVM, updateWizardData);

    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');

    const {
        getLocationAndProducerCodes,
        getProducerDetailsFromProducerCodes
    } = useNewBusinessUtil(
        rewriteVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        setLocationAndProducerCodes
    );

    const { createSecondaryNamedInsuredVM, removeSni } = useSniUtil(
        rewriteVM,
        updateWizardData,
        viewModelService,
        LOB,
        disregardFieldValidation
    );

    // If Book Transfer is Yes, allow quoting up to 120 days in the future, otherwise 60 days  
    const { maxEffectiveDate, minEffectiveDate } = useMemo(
        () => bookTransferIndicator ? getEffectiveDateBoundsForSubmission(daysInFutureForBookroll)
                : getEffectiveDateBoundsForSubmission(daysInFuture),
        [getEffectiveDateBoundsForSubmission, bookTransferIndicator, daysInFutureForBookroll, daysInFuture]
    );

    // This is part of the page validation
    const checkAcknowledgementsPresents = useCallback(() => {
        if (rewriteVM.value.lobData.personalUmbrella_EU.acknowledgements === undefined) {
            return false;
        }

        return rewriteVM.value.lobData.personalUmbrella_EU.acknowledgements.every(
            (acknowledgement) => acknowledgement.acknowledgeAnswerType === 'accept'
        );
    }, [rewriteVM.value.lobData.personalUmbrella_EU.acknowledgements]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted && checkAcknowledgementsPresents()) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted, checkAcknowledgementsPresents]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        const stateValues = e1pUSStatesUtil.getUSStates(viewModelService);

        setUSStates(stateValues);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        overrideProps.secondaryNamedInsured = {
            hideSSN: true
        };

        return overrideProps;
    }, []);

    useEffect(() => {
        if (rewriteVM.baseData.quoteSource_Ext.sourceType === undefined) {
            const model = {
                sourceType: 'directentry',
                sourceName: 'msaagentsonly'
            };

            rewriteVM.baseData.quoteSource_Ext.value = model;
        }

        updateIsLoading(false);
        setIsComponentInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(async () => {
        const shouldGoNext = isComponentValid && checkAcknowledgementsPresents();

        if (!shouldGoNext) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        updateIsLoading(true);

        if (!_get(rewriteVM, 'baseData.quoteSource_Ext.value')) {
            _set(rewriteVM, 'baseData.quoteSource_Ext.value', {
                sourceType: 'directentry',
                sourceName: 'msaagentsonly'
            });
        }

        // Need this for validation rules
        _set(rewriteVM, 'flowStepIDs_Ext.value', ['namedinsured']);
        _set(rewriteVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);

        const updateDraftResponse = await RewriteService.updateDraftRewrite(
            [rewriteVM.value],
            authHeader
        );

        _set(rewriteVM, 'value', updateDraftResponse);
        updateIsLoading(false);

        return rewriteVM;
    }, [authHeader, isComponentValid, rewriteVM, checkAcknowledgementsPresents]);

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, rewriteVM, updateWizardSnapshot]
    );


    const handleAddressValueChange = useCallback(
        (newVal, localPath) => {
            _set(rewriteVM, localPath, newVal);
            updateWizardData(rewriteVM);
        },
        [rewriteVM, updateWizardData]
    );

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(rewriteVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(rewriteVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    useEffect(() => {
        // get available producer codes for given state
        if (policyState && isAgent) {
            // only for agents
            getAndSetLocationCodes(policyState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const isProducerCodeDisabled = useMemo(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(rewriteVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(rewriteVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);

            return true;
        }

        return false;
    }, [locationAndProducerCodes, rewriteVM]);

    useEffect(() => {
        const {
            producerCode
        } = producerCodeDetails;
        const existingProducerCode = _get(
            rewriteVM,
            'value.baseData.producerCode_Ext',
            rewriteVM.value.baseData.producerCode
        );

        if (existingProducerCode && isAgent
            && existingProducerCode !== producerCode) {
            (async () => {
                // only for agents
                const details = await getProducerDetailsFromProducerCodes(
                    rewriteVM.value.baseData.producerCode_Ext,
                    locationAndProducerCodes.producerCodes,
                    rewriteVM.value.baseData.externalID_Ext
                );

                if (details) {
                    setProducerCodeDetails(details);
                    setAgencyName(details.agencyName);
                    setBookTransferIndicator(details.bookTransferIndicator);
                    setServiceCenterIndicator(details.serviceCenterIndicatorValue);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewriteVM.baseData.producerCode_Ext.value]);

    const locationCodeChange = useCallback(
        (value) => {
            _set(rewriteVM, 'baseData.externalID_Ext', value);

            const selectedProducerCode = locationAndProducerCodes?.producerCodes
                ?.find((producerCode) => producerCode?.externalId_Ext === value);

            _set(rewriteVM, 'baseData.producerCode_Ext', selectedProducerCode?.code);
            updateWizardData(rewriteVM);
        },
        [rewriteVM, updateWizardData, locationAndProducerCodes]
    );

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, address, phone, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            _set(rewriteVM, 'baseData.producerCode_Ext', producerCode);
            _set(rewriteVM, 'baseData.externalID_Ext.value', externalID);
            setProducerCodeDetails({ address, phone });
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [rewriteVM],
    );

    const swapPniSni = useCallback(() => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: e1pCommonMessages.swapPniSniTitle,
            message: e1pCommonMessages.swapPniSniMessage,
            confirmButtonText: e1pCommonMessages.confirm,
            cancelButtonText: e1pCommonMessages.cancel
        }).then(
            (result) => {
                if (result !== 'cancel') {
                    const pniPerson = _cloneDeep(
                        rewriteVM.lobData[LOB].primaryNamedInsured.person.value
                    );
                    const sniPerson = _cloneDeep(
                        rewriteVM.lobData[LOB].secondaryNamedInsured.person.value
                    );

                    rewriteVM.lobData[LOB].primaryNamedInsured.person.value = sniPerson;
                    rewriteVM.lobData[LOB].secondaryNamedInsured.person.value = pniPerson;
                    // since we are swapping PNI and SNI, we need remove publicID, FixedID and integrationID from both policyContactRoles
                    // i.e PNI and SNI
                    _set(rewriteVM, `lobData[${LOB}].primaryNamedInsured.fixedId.value`, undefined);
                    _set(rewriteVM, `lobData[${LOB}].primaryNamedInsured.publicID.value`, undefined);
                    _set(rewriteVM, `lobData[${LOB}].primaryNamedInsured.integrationId.value`, undefined);
                    _set(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.fixedId.value`, undefined);
                    _set(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.publicID.value`, undefined);
                    _set(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.integrationId.value`, undefined);

                    // If paperless Email id exist and pni email id is undefiend
                    // then update pni email to paperless email
                    if (!_get(rewriteVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value')
                        && !!_get(rewriteVM, 'lobData.personalUmbrella_EU.paperlessEmail.value')) {
                        _set(rewriteVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value',
                            _get(rewriteVM, 'lobData.personalUmbrella_EU.paperlessEmail.value'));
                    }

                    updateWizardData(rewriteVM);
                }
            },
            () => { }
        );
    }, [rewriteVM, updateWizardData, modalApi]);

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate,
            onRemoveSni: removeSni,
            onHandleProducerApiResponse: handleProducerApiRespone,
            onSwapPniSni: swapPniSni
        }
    };

    const handleAcknowledgementsValueChange = useCallback(
        (value) => {
            _set(rewriteVM, `lobData[${LOB}].acknowledgements`, value);
            updateWizardData(rewriteVM);
        },
        [updateWizardData, rewriteVM]
    );

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isStandardizingAddress) {
            loadingMessage = translator(messages.standardizingAddressMessage);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isLoading) {
            loadingMessage = translator(messages.loadingNextPageMessage);
        } else {
            loadingMessage = translator(messages.loadingNextPageMessage);
        }

        return loadingMessage;
    }, [isStandardizingAddress, isSavingCurrentPageChanges, isLoading, translator]);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        policyDetailsPageLoadingIndicator: {
            loaded:
                !isStandardizingAddress
                && !isLoading && isComponentInitialized && !isSavingCurrentPageChanges,
            text: getLoadingIndicatorMessage
        },
        policyDetailsPageContainer: {
            visible:
                !isStandardizingAddress
                && !isLoading && isComponentInitialized && !isSavingCurrentPageChanges
        },
        coverageStartDate: {
            updateDateDto: handlePeriodStartDateChange,
            dateDTO: rewriteVM.baseData.periodStartDate,
            defaultToToday: true,
            minDate: minEffectiveDate,
            maxDate: maxEffectiveDate,
            showErrors: true,
            readOnly: true
        },
        coverageEndDate: {
            dateDTO: rewriteVM.baseData.periodEndDate,
            updateDateDto: () => updateWizardData(rewriteVM),
            readOnly: true,
            defaultValue: getDefaultPeriodEndDate
        },
        personalInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        ExternalProducerDetailsComponent: {
            defaultValue: rewriteVM.baseData.externalID_Ext?.value,
            visible: !isAgent
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            disabled: isProducerCodeDisabled,
            onValueChange: locationCodeChange,
            visible: isAgent
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        sniContainer: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        removeSni: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        primaryNamedInsured: {
            hideSSN: true,
            showErrors: isPageSubmitted,
            isPNI: true
        },
        addSni: {
            visible: !_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        policyState: {
            availableValues: e1pUSStatesUtil.getStateValues(USStates, translator),
            readOnly: true
        },
        secondaryNamedInsured: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            hideSSN: true,
            showErrors: isPageSubmitted
        },
        mailingAndBillingAddressComponent: {
            transactionVM: rewriteVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            showErrors: isPageSubmitted
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        e1pAcknowledgementComponent: {
            acknowledgements: _get(rewriteVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: _get(rewriteVM, 'baseData.policyAddress.state.value.code'),
                name: translator({ id: _get(rewriteVM, 'baseData.policyAddress.state.value.name') })
            },
            onValueChange: handleAcknowledgementsValueChange,
            showErrors: isPageSubmitted && !checkAcknowledgementsPresents(),
            lob: LOB
        },
        insuredResidenceAddress: {
            addressVM: _get(rewriteVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate,
            onAddressChange: (value, path) => handleAddressValueChange(value, `baseData.policyAddress.${path}`),
            viewOnlyMode: true,
            showParentLoader: setIsStandardizingAddress
        },
        changePniComponent: {
            accountNumber: _get(rewriteVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(rewriteVM, 'baseData.accountNumber.value'),
            excludedContacts: [
                _get(rewriteVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ]
        },
        changeSniComponent: {
            accountNumber: _get(rewriteVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(rewriteVM, 'baseData.accountNumber.value')
                && !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            excludedContacts: [
                _get(rewriteVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ]
        },
        swapPniSni: {
            visible: !!_get(rewriteVM, `value.lobData[${LOB}].secondaryNamedInsured.person.publicID`)
                && !!_get(rewriteVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`)
        },
        agencyInformationContainer: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: rewriteVM,
            authHeader,
            LOB
        },
        ...generateOverrides()
    };

    const readValue = (id, path) => readViewModelValue(metadata.pageContent, rewriteVM, id, path, overrideProps);


    const isPageSubmittedWithErrors = isPageSubmitted
        && (!isComponentValid || !checkAcknowledgementsPresents());

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmittedWithErrors}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

InsuredDetailPage.propTypes = wizardProps;
export default withAuthenticationContext(InsuredDetailPage);
