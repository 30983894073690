import React, {
    useCallback, useContext, useState, useEffect
} from 'react';
import {
    set as _set,
    get as _get,
    findIndex as _findIndex,
    isEmpty as _isEmpty
} from 'lodash';
import { RenewalService } from 'e1p-capability-renewal';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTPIUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import wizardMessages from '../../EHRenewalWizard.messages';
import metadata from './ThirdPartyInterestPage.metadata.json5';

function ThirdPartyInterestPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        onCustom2: handleDiscardChanges,
        updateWizardSnapshot
    } = props;
    const { authHeader } = useAuthentication();
    const {
        isComponentValid,
        onValidate,
        disregardFieldValidation
    } = useValidation('ThirdPartyInterestPage');
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isSavingTPI, setIsSavingTPI] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const viewOnlyMode = false;
    const isDiscardButtonActive = _get(renewalVM, 'value.canDiscard_Ext', false);
    const changeSummaryIndex = _findIndex(steps, ({ path }) => path === '/change-summary');
    const [isAddingTPI, setIsAddingTPI] = useState(false);
    const { checkAndShowDialogForPresenceOfTrust } = useTPIUtil();

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [renewalVM, isComponentValid, isPageSubmitted]);

    /**
     * Helper callback for saving TPIs if changes were made while visiting this screen.
     */
    const saveTPIChangesIfNecessary = useCallback(async () => {
        // Do rateless quote to bring back to quoted status
        _set(renewalVM, 'baseData.ratingStyle_Ext.value', 'RatelessQuote_Ext');
        renewalVM.value = await RenewalService.saveAndQuoteRenewal(
            [(renewalVM.value)],
            authHeader
        );

        return renewalVM.value;
    }, [renewalVM, authHeader]);

    /**
     * Helper callback for handling wizard page navigation after the "Next" button is pressed.
     */
    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        const popResult = await checkAndShowDialogForPresenceOfTrust(
            renewalVM,
            updateWizardData);

        if (!popResult) {
            // user clicked cancel on presence of trust confirmation dialog
            return false;
        }

        setIsSavingRenewal(true);
        renewalVM.value = await saveTPIChangesIfNecessary();

        // Do not proceed if "saveTPIChangesIfNecessary" raised validation issues
        if (
            renewalVM.errorsAndWarnings_Ext.value !== undefined
            && renewalVM.errorsAndWarnings_Ext.validationIssues.value !== undefined
            && renewalVM.errorsAndWarnings_Ext.validationIssues.issues.value !== undefined
            && renewalVM.errorsAndWarnings_Ext.validationIssues.issues.value.length > 0
        ) {
            setIsSavingRenewal(false);
            window.scrollTo(0, 0);

            return false;
        }

        setIsSavingRenewal(false);

        return renewalVM;
    }, [
        checkAndShowDialogForPresenceOfTrust, isComponentValid,
        renewalVM, saveTPIChangesIfNecessary, updateWizardData
    ]);

    const onSave = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(renewalVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [isComponentValid, onNext, renewalVM, updateWizardSnapshot]
    );

    const getLoadingMessage = useCallback(() => {
        let loadingMessage = translator(e1pCommonMessages.loadingNextPage);

        if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isSavingTPI) {
            loadingMessage = translator(e1pCommonMessages.savingTPI);
        }

        return loadingMessage;
    }, [isSavingCurrentPageChanges, isSavingTPI, translator]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            readOnly: viewOnlyMode,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        thirdPartyInterestPageLoadingIndicator: {
            loaded: !isSavingRenewal && !isSkipping
                && !isSavingTPI && !isSavingCurrentPageChanges,
            text: getLoadingMessage()
        },
        thirdPartyInterestPageContainer: {
            visible: !isSavingRenewal && !isSkipping
                && !isSavingTPI && !isSavingCurrentPageChanges
        },
        e1ptpiDisplayTableContainer: {
            transactionVM: renewalVM,
            viewModelService,
            authHeader,
            updateWizardData,
            setIsSavingTPI,
            showErrors: isPageSubmitted,
            onValidate,
            disregardFieldValidationParentPage: disregardFieldValidation,
            setIsAddingTPI
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            const popResult = await checkAndShowDialogForPresenceOfTrust(
                renewalVM,
                updateWizardData);

            if (!popResult) {
                // user clicked cancel on presence of trust confirmation dialog
                return false;
            }

            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            _set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setIsSavingRenewal(false);

            return false;
        },
        [
            isComponentValid, checkAndShowDialogForPresenceOfTrust, renewalVM,
            updateWizardData, authHeader, jumpTo, changeSummaryIndex
        ]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            onSave={onSave}
            showPrevious={!isAddingTPI}
            showCancel={!isAddingTPI}
            showNext={!isAddingTPI}
            showOnSave={!isAddingTPI}
            showCustom={!isAddingTPI}
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                overrideProps={overrideProps}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

ThirdPartyInterestPage.propTypes = wizardProps;
export default ThirdPartyInterestPage;
