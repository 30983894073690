/* eslint-disable react/no-this-in-sfc */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { Chevron, withModalContext } from '@jutro/components';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { withValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import UnderwritingIssues from 'gw-components-platform-react/UnderwritingIssues/UnderwritingIssues';
import QuoteTableAccordionCardIterableComponent from 'gw-components-platform-react/QuoteTableAccordionCardIterableComponent/QuoteTableAccordionCardIterableComponent';
import { PackageDifferenceComponent, PackageQualifyingEndorsementsComponent } from 'e1p-capability-policyjob-react';
import { ConsoleHelper } from 'e1p-portals-util-js';
import { ehCommonMessages } from 'e1p-platform-translations';
import SingleClauseComponentVM from '../EHClauses/SingleClauseComponentVM';
import metadata from './QuoteClauseTable.metadata.json5';
import styles from './EHQuoteClauseTable.module.scss';
import OptionalCoverages from '../EHOptionalCoveragesAddEdit/OptionalCoverages';
import covAOverrides from './QuoteClauseTableCovAOverrides';
import messages from './QuoteClauseTable.messages';
import ProtectiveDevices from '../EHProtectiveDevices/ProtectiveDevicesPage';

const BLOCKS_QUOTES = ['BlocksQuote', 'BlocksQuoteRelease'];
const BLOCKS_BIND = 'BlocksBind';
const OPTIONAL_COVERAGEUIGROUPS = [
    'Popular Optional Coverages',
    'More Optional Coverages'
];

function getChangedDataPath(changedPath, fullChangedPath, pathToChangeTo) {
    const localisedVMPath = changedPath.replace(fullChangedPath, pathToChangeTo);

    return ViewModelUtil.getNonVMPath(localisedVMPath);
}

function getChangedColumnData(changedPathOfModel, columnData) {
    // onBlur is called from Jutro with a object instead of a path
    const path = _.isString(changedPathOfModel) ? changedPathOfModel : changedPathOfModel.model;

    return columnData.find(({ lob }) => _.includes(path, lob.path));
}

function getChangedClause(path, columnData) {
    const changedObjectPath = ClausesUtil.getObjectPathFromChangedPath(path);
    const changedColumn = getChangedColumnData(path, columnData);
    const changedPath = getChangedDataPath(changedObjectPath, changedColumn.lob.path, 'lob.data');

    return _.get(changedColumn, changedPath);
}

function selectMetadata(breakpoint) {
    const contentArray = metadata.componentContent.content;
    let metadataToRender = '';

    if (breakpoint === 'phone') {
        metadataToRender = contentArray.filter((item) => item.id !== 'desktopTable');
    } else {
        metadataToRender = contentArray.filter((item) => item.id !== 'phoneTable' && item.id !== 'mobileAccordionHeader');
    }

    const filteredMetdata = {
        ...metadata,
        componentContent: {
            content: metadataToRender
        }
    };

    return filteredMetdata;
}

function getSelectedCoverages(data) {
    // only return the selected base coverages names
    const coveragesObject = _.get(data, 'lob.data.coverages');
    const coverageNames = Object.keys(coveragesObject).filter((name) => _.includes(name.toLowerCase(), 'coverages'));
    const baseCoverageName = _.first(coverageNames);
    const coverages = _.get(coveragesObject, baseCoverageName);

    return coverages.filter((cov) => cov.selected);
}

const PAYMENT_TYPES = {
    monthly: 'monthly',
    annually: 'annually'
};

const moneyPropsTypes = PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.number
});

const getSelectedOffering = (submissionVM) => {
    const lobOfferingPath = 'lobData.homeowners_EH.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';
    const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
    let quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`);

    if (!quoteOfferings) {
        // Policy View Coverage page uses same component; we dont have offeredQuotes in policyView response
        quoteOfferings = [
            {
                branchCode: lobOfferings[0].branchCode,
                branchName: lobOfferings[0].branchName,
                selected: true
            }
        ]
    }

    const selectedVersion = quoteOfferings.find((version) => version.selected);
    const lobIndex = lobOfferings.findIndex((offering) => offering.branchCode === selectedVersion.branchCode);

    return {
        lobOfferingPath,
        quoteOfferingPath,
        lobOfferings,
        quoteOfferings,
        selectedVersion,
        lobIndex
    };
};


class QuoteClauseTableWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        columnData: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string,
            quote: PropTypes.shape({
                path: PropTypes.string,
                premium: PropTypes.shape({
                    monthlyPremium: moneyPropsTypes,
                    total: moneyPropsTypes,
                })
            })
        })).isRequired,
        tableData: PropTypes.arrayOf(PropTypes.shape({
            header: PropTypes.shape({}),
            data: PropTypes.arrayOf(PropTypes.shape({
                publicID: PropTypes.string
            })),
            tableContent: PropTypes.arrayOf(PropTypes.shape({
                path: PropTypes.string,
                clauses: PropTypes.arrayOf(PropTypes.shape({})),
                code: PropTypes.string
            }))
        })).isRequired,
        underwritingIssues: PropTypes.arrayOf(PropTypes.shape({})),
        filterUWIssuesInCustomOffering: PropTypes.bool,
        onBuyNow: PropTypes.func,
        onRecalculate: PropTypes.func,
        onChangeSubmissionAndSync: PropTypes.func,
        onChangeSubmission: PropTypes.func,
        onSyncCoverages: PropTypes.func,
        onSyncCoveragesAndRecalculate: PropTypes.func,
        onStaleQuoteBranchCode: PropTypes.func,
        viewOnlyMode: PropTypes.bool,
        quoteIsStale: PropTypes.bool,
        onCancel: PropTypes.func,
        isPageSubmitted: PropTypes.bool,
        updateIsPageSubmitted: PropTypes.func,
        isComponentValid: PropTypes.bool,
        registerComponentForValidation: PropTypes.func
    };

    static defaultProps = {
        onBuyNow: undefined,
        onRecalculate: undefined,
        onChangeSubmissionAndSync: undefined,
        onChangeSubmission: undefined,
        onSyncCoverages: undefined,
        onSyncCoveragesAndRecalculate: undefined,
        onStaleQuoteBranchCode: undefined,
        underwritingIssues: [],
        filterUWIssuesInCustomOffering: true,
        viewOnlyMode: false,
        quoteIsStale: false,
        onCancel: undefined,
        isPageSubmitted: false,
        updateIsPageSubmitted: () => { }
    }

    state = {
        formData: {},
        loadingClause: undefined,
        staleQuoteBranchCodes: this.getStaleCode(),
        resetBranchCodes: [],
        priceDifference: 0,
        hasProtectiveDevices: true,
        showOptionalCoverageSection: true,
        hasGoldOrPlatinumBoxVisible: true
    };

    componentDidMount() {
        const {
            columnData,
            submissionVM,
            updateWizardData,
            viewOnlyMode,
            authUserData
        } = this.props;
        const selectedOffering = getSelectedOffering(submissionVM);
        const hasGoldOrPlatinumBoxVisible = !!(_.get(submissionVM, `lobData.homeowners_EH.offerings.value[${selectedOffering.lobIndex}].coverages.coverages`, [])
            .find(({ codeIdentifier }) => _.includes(['EH_GoldCoverageBundle', 'EH_PlatinumCoverageBundle'], codeIdentifier)));
        const resetBranchCodes = columnData.map((item) => item.code);
         
        const hasExtendedAttributeEditability = authUserData?.permissions_Ext.includes('extendedattributeeditability_ext');

        this.setState({ hasExtendedAttributeEditability });
        this.setState({ resetBranchCodes });
        this.setState({ hasGoldOrPlatinumBoxVisible });
        this.checkHasProtectiveDevice(submissionVM);

        if (_.get(submissionVM, 'lobData.homeowners_EH.paperlessInd.value') === undefined && !viewOnlyMode) {
            _.set(submissionVM, 'lobData.homeowners_EH.paperlessInd.value', false);
            updateWizardData(submissionVM);
        }
    }


    getTableColumnContent(columnData, tableInfo) {
        return columnData.map((data, index) => ({
                id: `quoteTableColumn${index}`,
                type: 'element',
                component: 'TableColumn',
                componentProps: {
                    cellClassName: 'gwTableCell',
                    renderCell: this.renderCellContent,
                    data: tableInfo.tableContent.find((content) => content.code === data.code)
                }
            }));
    }

    getTotalPremium() {
        const { columnData, submissionVM } = this.props;

        if (columnData) {
            const payPlans = _.get(columnData[0], 'lob.data.paymentPlans');
            const selectedPlan = _.filter(payPlans, (plan) => plan.isSelected);
            const totalPremium = selectedPlan[0]?.total?.amount;

            if (!totalPremium && totalPremium !== 0) {
                return undefined;
            }

            _.set(submissionVM, 'unverfiedPremium', totalPremium);

            return { currency: 'usd', amount: totalPremium };
        }

        return undefined;
    }

    getStaleCode() {
        const { onStaleQuoteBranchCode } = this.props;
        let val = [];

        if (onStaleQuoteBranchCode) {
            if (onStaleQuoteBranchCode()) {
                const staleCodes = onStaleQuoteBranchCode();

                if (_.isArray(staleCodes)) {
                    val = staleCodes;
                } else {
                    val.push(staleCodes);
                }
            }
        }

        return val;
    }

    getChangedOfferingPathsAndSetClauseToLoading(path) {
        const { columnData } = this.props;
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;

        const changedColumn = getChangedColumnData(path, columnData);
        const changedClause = getChangedClause(path, columnData);
        const lobPath = changedColumn.lob.path;
        const quotePath = changedColumn.quote.path;
        const updatedBranchCodes = resetBranchCodes.filter((item) => item !== changedColumn.code);

        staleQuoteBranchCodes.push(changedColumn.code);
        this.setState({
            loadingClause: {
                clauseID: changedClause.coveragePublicID || changedClause.publicID,
                quoteCode: changedColumn.code
            },
            staleQuoteBranchCodes,
            resetBranchCodes: updatedBranchCodes
        });

        return [lobPath, quotePath];
    }

    checkHasProtectiveDevice = (vm) => {
        if (_.get(vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value', []).length > 0) {
            this.setState({ hasProtectiveDevices: true });
        } else {
            this.setState({ hasProtectiveDevices: false });
        }
    }

    removeStaleQuote = (path) => {
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;
        const selectedColumn = _.get(this.props, path);

        const newStateQuoteArray = staleQuoteBranchCodes.filter((item) => (
            item !== selectedColumn.code
        ));
        const newResetBranchArray = resetBranchCodes.filter((item) => (
            item !== selectedColumn.code
        ));

        this.setState({
            staleQuoteBranchCodes: newStateQuoteArray,
            resetBranchCodes: newResetBranchArray
        });
    }

    onShowProtectiveDevicesPopup = () => {
        const { setFieldsChangedOnCoveragePage, submissionVM, updateWizardData } = this.props;

        try {
            this.showProtectiveDevicesPopup().then((wrapperObj) => {
                _.set(
                    submissionVM,
                    'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value',
                    _.get(wrapperObj.vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value', [])
                );
                _.set(
                    submissionVM,
                    'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd.value',
                    _.get(wrapperObj.vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd.value')
                );
                updateWizardData(submissionVM);
                this.checkHasProtectiveDevice(wrapperObj.vm);
                setFieldsChangedOnCoveragePage(true);
            }).catch(() => {
                this.checkHasProtectiveDevice(submissionVM);
            });
        } catch {
            ConsoleHelper('user closed the modal');
        }
    }

    onProtectiveDevicesChange = (value) => {
         
        const { submissionVM, setFieldsChangedOnCoveragePage } = this.props;

        this.setState({ hasProtectiveDevices: value });

        if (value) {
            this.onShowProtectiveDevicesPopup();
        } else {
            _.set(
                submissionVM,
                'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value',
                []
            );
            _.set(
                submissionVM,
                'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd',
                undefined
            );
            setFieldsChangedOnCoveragePage(true);
        }
    };

     
    showProtectiveDevicesPopup = async () => {
        const {
            submissionVM,
            viewModelService,
            viewOnlyMode
        } = this.props;
        const componentProps = {
            iconClassType: false,
            showCloseBtn: true,
            showCancelBtn: true,
            submissionVM,
            viewModelService,
            translator: this.translator,
            viewOnlyMode
        };
        const result = await this.props.modalContext.showModal(<ProtectiveDevices {...componentProps} />);

        return result;
    }

    /**
     * This is never used because we did not design for mobile
     * Page willl break when shrunk to a mobile size
     *
     * @param {*} accordionData
     * @param {*} index
     * @param {*} isFormValid
     * @returns {JSX} the quote table
     */
    renderMobileQuoteHeader = (accordionData, index, isFormValid) => (
        (isOpen) => {
            const {
                formData,
                staleQuoteBranchCodes
            } = this.state;
            const scheduleStaleCode = this.getStaleCode();

            if (_.isEmpty(staleQuoteBranchCodes) && !_.isEmpty(scheduleStaleCode)) {
                this.setState({ staleQuoteBranchCodes: scheduleStaleCode });
            }

            const isAnnually = formData.paymentToggle === PAYMENT_TYPES.annually;
            const isQuoteStale = _.includes(staleQuoteBranchCodes, accordionData.code);
            const price = isAnnually
                ? accordionData.quote.data.premium.total
                : accordionData.quote.data.premium.monthlyPremium;

            const dataForComponent = {
                price,
                quoteName: accordionData.name,
                selectedCoverages: getSelectedCoverages(accordionData)
            };

            // Due to having a new render content, there may be duplicate ID's
            const overrides = {
                monthlyAmountText: {
                    visible: formData.paymentToggle === PAYMENT_TYPES.monthly
                },
                annuallyAmountText: {
                    visible: formData.paymentToggle === PAYMENT_TYPES.annually
                },
                buyNowButton: {
                    path: `columnData[${index}]`,
                    visible: !isQuoteStale,
                    onClick: () => this.buyNow(accordionData.quote.path, accordionData.lob.path)
                },
                recalculateButton: {
                    path: `columnData[${index}]`,
                    visible: isQuoteStale,
                    disabled: !isFormValid,
                    onClick: () => this.recalculate(`columnData[${index}]`, accordionData.quote.path, accordionData.lob.path)
                },
                saveButton: {
                    path: `columnData[${index}]`,
                    onClick: () => this.recalculate(`columnData[${index}]`, accordionData.quote.path, accordionData.lob.path)
                },
                selectedCoverages: {
                    visible: !isOpen
                },
                moreInfoContainer: {
                    visible: !isOpen
                },
                accordionChevron: {
                    isOpen
                },
                accordionStickyHeader: {
                    className: classNames(styles.accordionStickyHeader, {
                        [styles.mobileStickyHeader]: isOpen
                    })
                }
            };

            const resolvers = {
                resolveClassNameMap: styles
            };

            const metadataToRender = metadata.contentForMobileView.content;

            return (
                <ViewModelForm
                    uiProps={metadataToRender}
                    model={dataForComponent}
                    overrideProps={overrides}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            );
        }
    )


    renderCellContent = (clauseCellPublicID, index, props) => {
        const { loadingClause, hasExtendedAttributeEditability } = this.state;
        const {
            onChangeSubmission,
            onValidate,
            submissionVM,
            viewOnlyMode,
            jumpTo,
            steps,
            policyType,
            underwritingIssues,
            authUserData
        } = this.props;
        const policyState = _.get(
            submissionVM,
            'baseData.policyAddress.state.value.code',
            _.get(submissionVM, 'policyAddress.state.value.code')
        );
        const replacementCost = submissionVM.value.lobData.homeowners_EH
            .coverables.yourHome.valuation.estimatedReplacementCostAmount;
        const { path, clauses = [], code } = props.data;
        const { columnData } = this.props;
        const scheduleStaleCode = this.getStaleCode();
        const isQuoteStale = _.includes(scheduleStaleCode, code) || columnData[0].quote.data.status === 'Draft';
        const clauseIndex = clauses.findIndex(
            (clause) => clause.publicID === clauseCellPublicID.publicID
        );
        const clause = clauses[clauseIndex];
        const isQuoteLoading = _.get(loadingClause, 'quoteCode') === code && _.isUndefined(loadingClause.clauseID);
        const isClauseLoading = _.get(loadingClause, 'quoteCode') === code;
        const userRolePremiumViewRoles = authUserData?.permissions_Ext.includes('viewpremium_ext');
        const blocksQuoteUWIssues = underwritingIssues?.some((uwIssue) => BLOCKS_QUOTES.includes(uwIssue.currentBlockingPoint));
        const showPremium = !blocksQuoteUWIssues || userRolePremiumViewRoles;


        if (clauseIndex !== -1) {
            return (
                <SingleClauseComponentVM
                    value={clause}
                    path={`${path}.children[${clauseIndex}]`}
                    onChangeClause={onChangeSubmission}
                    onSyncCoverages={this.syncCoverages}
                    onChangeSubmissionAndSync={this.changeSubmissionAndSync}
                    onSyncCoveragesAndRecalculate={this.syncCoveragesAndRecalculate}
                    loadingClause={isClauseLoading || isQuoteLoading}
                    idPrefex={code}
                    labelTop
                    onValidate={onValidate}
                    replacementCost={replacementCost}
                    viewOnlyMode={viewOnlyMode}
                    isQuoteStale={isQuoteStale}
                    jumpTo={jumpTo}
                    steps={steps}
                    policyType={policyType}
                    showPremium={showPremium}
                    hasExtendedAttributeEditability={hasExtendedAttributeEditability}
                    policyState={policyState}
                    labelPosition='top'
                />
            );
        }

        return null;
    }

    writeValue = (value, path) => {
        const { formData } = this.state;

        _.set(formData, path, value);
        this.setState({ formData });
    };

    syncCoverages = (value, path) => {
        const { onSyncCoverages } = this.props;

        const [lobPath, quotePath] = this.getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onSyncCoverages) {
            return Promise.resolve(onSyncCoverages(value, path, lobPath, quotePath).then((response) => {
                this.setState({
                    loadingClause: undefined
                });

                return response;
            }));
        }
    }

    changeSubmissionAndSync = (value, path) => {
        const { onChangeSubmissionAndSync } = this.props;

        const [lobPath, quotePath] = this.getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onChangeSubmissionAndSync) {
           return Promise.resolve(onChangeSubmissionAndSync(value, path, lobPath, quotePath).then((resp) => {
                this.setState({
                    loadingClause: undefined
                });

                return resp;
            }));
        }
    }

    syncCoveragesAndRecalculate = (value, path) => {
        const { onSyncCoveragesAndRecalculate } = this.props;

        const [lobPath, quotePath] = this.getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onSyncCoveragesAndRecalculate) {
            onSyncCoveragesAndRecalculate(value, path, lobPath, quotePath).then(() => {
                this.setState({
                    loadingClause: undefined
                });
            });
        }
    }

    scheduleChange = (value, path) => {
        const { onScheduleChange } = this.props;

        if (onScheduleChange) {
            return Promise.resolve(onScheduleChange(value, path).then((response) => response));
        }

        return Promise.resolve();
    }

    changeSubmission = (value, path) => {
        const { onChangeSubmission } = this.props;

        if (onChangeSubmission) {
            return Promise.resolve(onChangeSubmission(value, path)).then((response) => response);
        }

        return Promise.resolve();
    }

    buyNow = (quotePath, lobPath) => {
        const {
            onBuyNow,
            onDisableNextForTable,
            updateIsPageSubmitted,
            submissionVM,
            isComprater,
            policyType,
            quoteIsStale,
            isComponentValid
        } = this.props;
        const isThisNewHomePurchase = submissionVM.lobData.homeowners_EH.coverables.yourHome.isThisNewHomePurchase.value !== undefined
            || (policyType === 'HO4' || policyType === 'HO6');
        let isQuoteBlocked = false;

        if (isComprater) {
            isQuoteBlocked = onDisableNextForTable();
        }

        isQuoteBlocked = isQuoteBlocked || !isThisNewHomePurchase || quoteIsStale;

        if (isQuoteBlocked || !isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        if (onBuyNow) {
            onBuyNow(lobPath, quotePath);
        }

        return false;
    }

    recalculate = (columnPath, quotePath, lobPath) => {
        const {
            onRecalculate,
            columnData,
            isComponentValid,
            updateIsPageSubmitted
        } = this.props;

        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        const changedColumnData = getChangedColumnData(lobPath, columnData);

        this.setState({
            loadingClause: {
                quoteCode: changedColumnData.code
            }
        });

        if (onRecalculate) {
            onRecalculate(lobPath, quotePath)
                .then(() => {
                    this.removeStaleQuote(columnPath);
                    this.setState({
                        loadingClause: undefined
                    });
                });
        }

        return true;
    }

    showOptionalCoveragePopOver = async () => {
        const {
            data,
            viewOnlyMode,
            policyType,
            submissionVM,
            setOptionalVisited,
            underwritingIssues,
            authUserData
        } = this.props;
        const {
            hasGoldOrPlatinumBoxVisible
        } = this.state;

        if (setOptionalVisited) {setOptionalVisited(true);}

        const selectedOffering = getSelectedOffering(submissionVM);

        // New functions added
        const componentProps = {
            title: 'Optional Coverages',
            iconClassType: false,
            data,
            coverageUIGroups: OPTIONAL_COVERAGEUIGROUPS,
            viewOnlyMode,
            policyType,
            hasGoldOrPlatinumBoxVisible,
            changeSubmissionAndSync: this.changeSubmissionAndSync,
            transactionVM: submissionVM,
            currentOfferingIndex: selectedOffering.lobIndex,
            authUserData,
            underwritungIssues: underwritingIssues,
            BLOCKS_QUOTES,
            changeSubmission: this.changeSubmission,
            syncCoverages: this.syncCoverages,
            onScheduleChange: this.scheduleChange,
            currentOfferingBranchCode: selectedOffering.selectedVersion.branchCode
        };
        const result = this.props.modalContext.showModal(<OptionalCoverages {...componentProps} />);

        return result;
    };

    getSelectedOptionalCov = (submissionVM, isQuoteStale) => {
        const { viewModelService } = this.props;
        const { showOptionalCoverageSection } = this.state;
        const newSubVM = viewModelService.clone(submissionVM);
        const { lobIndex: selectedOfferingIndex } = getSelectedOffering(newSubVM);
        const translator = this.context;
        const selectedOptionalCoverages = newSubVM.lobData.homeowners_EH.offerings
            .getElement(selectedOfferingIndex).coverages.coverages.filter((coverage) => OPTIONAL_COVERAGEUIGROUPS
                 
                .indexOf(coverage.uigroup.value) >= 0 && coverage.selected.value === true);
        const selectedExclusions = newSubVM.lobData.homeowners_EH.offerings
            .getElement(selectedOfferingIndex).exclusions.filter((exclusion) => exclusion.selected.value === true);
        const selectedConditions = newSubVM.lobData.homeowners_EH.offerings
            .getElement(selectedOfferingIndex).conditions.filter((condition) => condition.selected.value === true);

        if (isQuoteStale) {
            selectedOptionalCoverages.forEach((item) => { item.value.amount = { amount: 0 }; });
        }

        const policyState = _.get(
            submissionVM,
            'baseData.policyAddress.state.value.code',
            _.get(submissionVM, 'policyAddress.state.value.code')
        );

        // For state CT - check if special loss settlement is selected - add it to optional coverage
        if (policyState === 'CT') {
            const dwellingCovA = _.find(_.get(newSubVM.lobData.homeowners_EH.offerings, ['value', selectedOfferingIndex, 'coverages', 'coverages'], []), { codeIdentifier: 'EH_DwellingCovA' });
            const isSpecialLossSettlementSelected = !!_.find(_.get(dwellingCovA, ['terms'], []), (term) => term.chosenTerm && term.type === 'OptionEH_DwellingCovA_SpecialLossSettlementType');

            if (isSpecialLossSettlementSelected) {
                selectedOptionalCoverages.push({
                    amount: undefined,
                    name: translator(ehCommonMessages.specialLossSettlement)
                });
            }
        }

        const selectedOptionalCoveragesExclusionsAndConditions = [...selectedOptionalCoverages, ...selectedExclusions, ...selectedConditions];

        if (showOptionalCoverageSection !== (selectedOptionalCoveragesExclusionsAndConditions.length !== 0)) {
            this.setState({
                showOptionalCoverageSection: (!showOptionalCoverageSection)
            });
        }

        return { selectedOptionalCoveragesExclusionsAndConditions };
    }

    overrideCovALimit = (sectionITable, submissionVM) => {
        const covADwelling = sectionITable[0].tableContent[0].clauses.find((clause) => clause.codeIdentifier === 'EH_DwellingCovA');

        if (covADwelling !== undefined && covADwelling.terms.length > 0) {
            const covALimitVariations = _.get(covAOverrides, `partner[${'MSA'}]`);
            const policyState = _.get(submissionVM, 'baseData.policyAddress.state.value.code',
                _.get(submissionVM, 'policyAddress.state.value.code'));

            covALimitVariations.forEach((covALimitVariation) => {
                if (covALimitVariation.states.includes(policyState)) {
                    const replacementCost = submissionVM.value.lobData.homeowners_EH
                        .coverables.yourHome.valuation.estimatedReplacementCostAmount;

                    if (replacementCost !== undefined && replacementCost > 0) {
                        const minValueForCovA = covALimitVariation.minValue * replacementCost;
                        const maxValueForCovA = covALimitVariation.maxValue * replacementCost;

                        covADwelling.terms[0].directValueMax = maxValueForCovA;
                        covADwelling.terms[0].directValueMin = minValueForCovA;
                    }
                }
            });
        }
    };

    updatePaperlessIndAndEmail = (value, path) => {
        const { submissionVM, updateWizardData, setIsPaperlessEmailUpdated } = this.props;

        setIsPaperlessEmailUpdated(true);
        _.set(submissionVM, path, value);

        switch (path) {
            case 'lobData.homeowners_EH.paperlessInd':
                if (value) {
                    const pniEmail = _.get(submissionVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value');

                    if (_.get(submissionVM, 'lobData.homeowners_EH.paperlessEmail.value') === undefined) {
                        _.set(submissionVM, 'lobData.homeowners_EH.paperlessEmail.value', pniEmail);
                    }
                } else {
                    _.set(submissionVM, 'lobData.homeowners_EH.paperlessEmail.value', undefined);
                }

                break;
            default:
                break;
        }

        updateWizardData(submissionVM);
    }

    updateIsThisNewHomePurchase = (value, path) => {
        const { submissionVM, updateWizardData, setFieldsChangedOnCoveragePage } = this.props;

        _.set(submissionVM, path, value);
        updateWizardData(submissionVM);
        setFieldsChangedOnCoveragePage(true);
    }

    updateTPIOfTrust = (value, path) => {
        const { submissionVM, updateWizardData, setFieldsChangedOnCoveragePage } = this.props;

        // dont want to update setFieldsChangedOnCoveragePage when defaulting
        if (_.get(submissionVM, `value.${path}`) !== undefined) {
            setFieldsChangedOnCoveragePage(true);
        }

        _.set(submissionVM, path, value);
        updateWizardData(submissionVM);
    }

    showWhatsIncludedPopup = async () => {
        const {
            submissionVM
        } = this.props;
        const selectedOffering = getSelectedOffering(submissionVM);
        const offerings = _.get(submissionVM, `lobData.homeowners_EH.offerings.children[${selectedOffering.lobIndex}]`);

        const platinumOrGoldClauseIndex = offerings.value.coverages.coverages.findIndex(
            (clause) => clause.codeIdentifier === 'EH_PlatinumCoverageBundle' || clause.codeIdentifier === 'EH_GoldCoverageBundle'
        );

        if (platinumOrGoldClauseIndex === -1) {
            const componentProps = {
                coverages: offerings.coverages.coverages.value
            };
            const result = await this.props.modalContext.showModal(
                <PackageQualifyingEndorsementsComponent {...componentProps} />
            );

            return result;
        }

        const componentProps = {
            lob: 'Homeowners_EH'
        };
        // IAP-83: Gold and Platinum will continue to use existing component
        const result = await this.props.modalContext.showModal(
            <PackageDifferenceComponent {...componentProps} />
        );

        return result;
    }

    generateDesktopHeaderOverrides() {
        const {
            columnData,
            policyType,
            quoteIsStale
        } = this.props;
        const {
            formData,
            staleQuoteBranchCodes,
            priceDifference,
            loadingClause
        } = this.state;
        const {
            isComprater,
            onDisableNextForTable,
            submissionVM,
            fieldsChangedOnCoveragePage
        } = this.props;

        const isThisNewHomePurchase = submissionVM.lobData.homeowners_EH.coverables.yourHome.isThisNewHomePurchase.value !== undefined
            || (policyType === 'HO4' || policyType === 'HO6');

        const scheduleStaleCode = this.getStaleCode();

        if (_.isEmpty(staleQuoteBranchCodes) && !_.isEmpty(scheduleStaleCode)) {
            this.setState({ staleQuoteBranchCodes: scheduleStaleCode });
        }

        const overrides = columnData.map(({ code, quote, lob }, index) => {
            const columnPath = `columnData[${index}]`;
            const { viewOnlyMode, onCancel } = this.props;
            const isQuoteStale = _.includes(staleQuoteBranchCodes, code);
            const clearAmount = { amount: undefined };

            let monthlyValue = clearAmount;
            let annuallyValue = clearAmount;
            let isQuoteBlocked = false;

            if (!_.isEmpty(quote.data)) {
                const { data } = quote;
                const monthlyPremium = { ...clearAmount, ...data.premium.monthlyPremium};
                const total = { ...clearAmount, ...data.premium.total};

                monthlyValue = { ...clearAmount, ...monthlyPremium};
                annuallyValue = { ...clearAmount, ...total};
            }

            if (isComprater) {
                isQuoteBlocked = onDisableNextForTable();
            }

            isQuoteBlocked = isQuoteBlocked || !isThisNewHomePurchase || quoteIsStale;

            const monthlyHeader = isQuoteStale ? { [`monthlyAmount${index}`]: { value: monthlyValue } } : {};
            const annuallyHeader = isQuoteStale ? { [`annuallyAmount${index}`]: { value: annuallyValue } } : {};
            const shouldShowPriceDifference = (
                _.includes(priceDifference.columnPath, `[${index}]`)
                && !isQuoteStale
                && priceDifference.difference !== 0
            );

            return {
                [`quoteAnnuallyAmount${index}`]: { visible: formData.paymentToggle === PAYMENT_TYPES.annually },
                [`quoteMonthlyAmount${index}`]: { visible: formData.paymentToggle === PAYMENT_TYPES.monthly },
                [`priceDifference${index}`]: { visible: shouldShowPriceDifference, value: priceDifference.difference },
                [`exitButton${index}`]: {
                    onClick: () => onCancel()
                },
                [`buyNowButton${index}`]: {
                    visible: !isQuoteStale && quote.data.status !== 'Draft' && !fieldsChangedOnCoveragePage,
                    onClick: () => this.buyNow(quote.path, lob.path)
                },
                [`recalculateButton${index}`]: {
                    visible: (isQuoteStale || quote.data.status === 'Draft' || !!fieldsChangedOnCoveragePage) && !viewOnlyMode,
                    disabled: !!loadingClause,
                    onClick: () => this.recalculate(columnPath, quote.path, lob.path)
                },
                [`saveButton${index}`]: {
                    disabled: !!loadingClause,
                    onClick: () => this.recalculate(columnPath, quote.path, lob.path)
                },
                [`topRecalculateButton${index}`]: {
                    visible: (isQuoteStale || quote.data.status === 'Draft' || !!fieldsChangedOnCoveragePage) && !viewOnlyMode,
                    disabled: !!loadingClause,
                    onClick: () => this.recalculate(columnPath, quote.path, lob.path)
                },
                [`monthlyAmountText${index}`]: { visible: !isQuoteStale },
                [`annuallyAmountText${index}`]: { visible: !isQuoteStale },
                [`warningIcon${index}`]: {
                    visible: isQuoteBlocked
                },
                signatureSeperator: {
                    visible: !viewOnlyMode
                },
                ...monthlyHeader,
                ...annuallyHeader
            };
        });

        return Object.assign({}, ...overrides);
    }

    generatePhoneHeaderOverrides() {
        const { tableData, columnData, isComponentValid } = this.props;

        const overrides = columnData.map((data, index) => ({
                [`quoteTableAccordionCardIterableComponentContainer${index}`]: {
                    tableData,
                    columnData: data,
                    isFormValid: isComponentValid,
                    renderAccordionCardHeader: this.renderMobileQuoteHeader,
                    generatePhoneTableOverrides: this.generatePhoneTableOverrides.bind(this),
                    onValueChange: this.writeValue
                }
            }));

        return Object.assign({}, ...overrides);
    }

    generateDesktopTableOverrides() {
        const { tableData, columnData, submissionVM } = this.props;
         
        // Slicing Section I Data from the table Data and giving it's own metadata component to display it accordingly in the mandatory coverages tab
        const sectionITable = tableData.filter((element) => element.header.defaultMessage === 'Section I Coverages');

        this.overrideCovALimit(sectionITable, submissionVM);

        const overrides = sectionITable.map((tableInfo, index) => ({
            [`sectionICoverages${index}`]: {
                data: tableInfo.data,
                content: this.getTableColumnContent(columnData, tableInfo)
            }
        }));
         
        // Slicing Section II Data from the table Data and giving it's own metadata component to display it accordingly in the mandatory coverages tab
        const sectionIITable = tableData.filter((element) => element.header.defaultMessage === 'Section II Coverages');
        const overrides2 = sectionIITable.map((tableInfo, index) => ({
            [`sectionIICoverages${index}`]: {
                data: tableInfo.data,
                content: this.getTableColumnContent(columnData, tableInfo)
            }
        }));

         
        // Slicing Deductibles Data from the table Data and giving it's own metadata component
        const deductiblesTable = tableData.filter((element) => element.header.defaultMessage === 'Deductibles');
        let overrides3 = [];

        if (deductiblesTable.length === 0) {
            overrides3[0] = {
                propertyCoveragesDeductibleTab: {
                    visible: false
                }
            };
        } else {
            overrides3 = deductiblesTable.map((tableInfo, index) => ({
                [`deductibles${index}`]: {
                    data: tableInfo.data,
                    content: this.getTableColumnContent(columnData, tableInfo)
                }
            }));
        }

        return Object.assign({}, ...overrides, ...overrides2, ...overrides3);
    }

    generatePhoneTableOverrides() {
        const { columnData: quoteInformation, tableData } = this.props;

        const overrides = quoteInformation.flatMap((quoteInfo, quoteIndex) => tableData.map((tableInfo) => ({
                [`quoteTable${quoteIndex}`]: {
                    data: tableInfo.data,
                    title: tableInfo.header
                },
                [`quoteTableColumn${quoteIndex}`]: {
                    renderCell: this.renderCellContent,
                    data: tableInfo.tableContent.find((content) => content.code === quoteInfo.code)
                }
            })));

        return Object.assign({}, ...overrides);
    }

    generateDesktopOverrides() {
        return {
            ...this.generateDesktopHeaderOverrides(),
            ...this.generateDesktopTableOverrides()
        };
    }

    generatePhoneOverrides() {
        return {
            ...this.generatePhoneHeaderOverrides()
        };
    }

    generateOverrides(breakpoint) {
        const {
            hasProtectiveDevices, staleQuoteBranchCodes, showOptionalCoverageSection, hasGoldOrPlatinumBoxVisible
        } = this.state;
        const {
            columnData,
            submissionVM,
            viewOnlyMode,
            modifiers,
            authHeader,
            updateWizardData,
            policyType,
            setFieldsChangedOnCoveragePage,
            underwritingIssues,
            authUserData,
            isPageSubmitted,
            onViewQuoteProposal,
            isQuoteProposalReady,
            isSideBySideView
        } = this.props;
        const breakpointOverrides = breakpoint === 'phone' ? this.generatePhoneOverrides() : this.generateDesktopOverrides();
        const isQuoteStale = _.includes(staleQuoteBranchCodes, columnData[0].code) || columnData[0].quote.data.status === 'Draft';

;

        const premiumTypeLabel = (_.get(submissionVM, 'quoteType_Ext.value.code') === 'Quick' || _.get(submissionVM, 'value.quoteType') === 'Quick')
            ? 'Unverified Premium' : 'Verified Premium';
        const paperLessIndValue = _.get(submissionVM, 'lobData.homeowners_EH.paperlessInd.value') === undefined
            ? false : _.get(submissionVM, 'lobData.homeowners_EH.paperlessInd.value');
        const userRolePremiumViewRoles = authUserData?.permissions_Ext.includes('viewpremium_ext');
        const allUWIssuesApproval = underwritingIssues?.every((item) => item?.approvalBlockingPoint === 'NonBlocking');
        const blocksQuoteUWIssues = underwritingIssues?.some((uwIssue) => BLOCKS_QUOTES.includes(uwIssue.currentBlockingPoint));
        const blocksBindUWIssues = underwritingIssues?.some((uwIssue) => BLOCKS_BIND.includes(uwIssue.currentBlockingPoint));
        const hidePremiumForVerified = (_.get(submissionVM, 'quoteType_Ext.value.code') !== 'Quick' && _.get(submissionVM, 'value.quoteType') !== 'Quick') && blocksBindUWIssues;
        const showPremium = (!blocksQuoteUWIssues || userRolePremiumViewRoles || allUWIssuesApproval)
            && !hidePremiumForVerified && !isQuoteStale;
        const isNewBusiness = _.get(submissionVM, 'baseData.value.businessTransactionType_Ext') === 'NewBusiness';
        const selectedOffering = getSelectedOffering(submissionVM);
        // Optional coverage subtotal amount
        const scheduleList = _.get(submissionVM, `lobData.homeowners_EH.offerings.value[${selectedOffering.lobIndex}].coverages.schedules`, []);
        let optionalCoverageSubtotalAmount = _.get(_.get(submissionVM, `lobData.homeowners_EH.offerings.value[${selectedOffering.lobIndex}].premiumSummary`, [])
            .find((prem) => prem.premiumSummaryType === 'EHOptionalCvgSubtotal'), 'amount', 0);

        // Add schedule coverage total amount to optional coverage total
        optionalCoverageSubtotalAmount += _.get(scheduleList.find((schedule) => _.get(schedule, 'premiumSummary.premiumSummaryType') === 'TotalScheduleCoverage'), 'premiumSummary.amount', 0);

        const discountSubtotal = _.get(submissionVM, `lobData.homeowners_EH.offerings.value[${selectedOffering.lobIndex}].premiumSummary`, [])
            .find((prem) => prem.premiumSummaryType === 'EHDiscountSavingsSubtotal')?.amount || 0;
        const translator = this.context;

        const policyState = _.get(
            submissionVM,
            'baseData.policyAddress.state.value.code',
            _.get(submissionVM, 'policyAddress.state.value.code')
        );

        const primaryContentData = () => {
            if (!hasGoldOrPlatinumBoxVisible) {
                return translator(messages.optionalCoveragePackage);
            }

            return translator(messages.goldPlatinumPrimaryLabel);
        };
        const secondaryContentData = () => {
            if (!hasGoldOrPlatinumBoxVisible) {
                return (
                    <React.Fragment>
                        {translator(messages.discountMessageSelect)}
                        <span className={styles.addIcon} />
                        {translator(messages.discountMessageLabel)}
                    </React.Fragment>
                );
            }

            return translator(messages.goldPlatinumSecondaryLabel);
        };

        return {
            '@field': {
                // apply to all fields
                onValueChange: this.writeValue,
                readOnly: viewOnlyMode,
                showRequired: true,
                showErrors: isPageSubmitted,
                autoComplete: false
            },
            addOrEditOptionalCovButton: {
                onClick: () => this.showOptionalCoveragePopOver(),
                content: viewOnlyMode ? translator(ehCommonMessages.viewOptionalCoverages) : translator(ehCommonMessages.editOptionalCoverages),
                icon: viewOnlyMode ? 'mi-visibility' : 'mi-edit',
                visible: viewOnlyMode ? showOptionalCoverageSection === 'undefined' || showOptionalCoverageSection : true
            },
            isProtectiveDevicesMainGrid: {
                visible: policyType === 'HO3' || policyType === 'HF9' || policyType === 'HO6' || (policyType === 'HO4' && policyState === 'NC'),
            },
            isProtectiveDevices: {
                value: hasProtectiveDevices,
                onValueChange: this.onProtectiveDevicesChange,
                disabled: viewOnlyMode,
                required: true
            },
            isProtectiveDevicesIcon: {
                onClick: this.onShowProtectiveDevicesPopup,
                visible: hasProtectiveDevices,
                icon: viewOnlyMode ? 'mi-visibility' : 'mi-edit',
                disabled: false
            },
            paperlessInd: {
                required: true,
                value: _.get(submissionVM, 'lobData.homeowners_EH.paperlessInd.value'),
                onValueChange: this.updatePaperlessIndAndEmail
            },
            paperlessEmailId: {
                visible: paperLessIndValue,
                onValueChange: this.updatePaperlessIndAndEmail,
                className: viewOnlyMode ? '': 'paperlessEmail'
            },
            recalculateAndNextButtonMainDiv: {
                visible: !viewOnlyMode
            },
            buyNowtableHeader: {
                visible: !viewOnlyMode
            },
            isThisNewHomePurchase: {
                onValueChange: this.updateIsThisNewHomePurchase,
                visible: (policyType === 'HO3' || policyType === 'HF9') && policyState !== 'MT',
                required: true,
                disabled: viewOnlyMode || !isNewBusiness,
                className: 'nowrap toggleField'
            },
            tpiOfTrust: {
                onValueChange: this.updateTPIOfTrust,
                visible: policyType === 'HO3' && policyState === 'NC'
            },
            premiumTypeLabel: {
                content: premiumTypeLabel
            },
            seeOptionalCovButton: {
                onClick: () => this.showOptionalCoveragePopOver(),
                content: viewOnlyMode ? translator(ehCommonMessages.viewOptionalCoverages) : translator(ehCommonMessages.editOptionalCoverages),
                icon: viewOnlyMode ? 'mi-visibility' : 'mi-edit'
            },
            questionCircleIcon: {
                disabled: viewOnlyMode
            },
            ehSurchargeListComponentId: {
                value: modifiers.filter(
                    (item) => item.applied && item.modifierType !== "discount"
                )
            },
            ehDiscountsListComponentId: {
                visible: !!_.filter(modifiers, { applied: true, modifierType: 'discount' }).length,
                discountsTotal: discountSubtotal
            },
            paymentOptionsID: {
                submissionVM,
                authHeader,
                updateWizardData,
                LOB: 'homeowners_EH',
                isQuoteStale,
                viewOnly: viewOnlyMode
            },
            GoldPlatinumGrid: {
                visible: true,
                className: (policyType === 'HO3' || policyType === 'HF9') ? 'box-container-rounded px-6 pt-6' : 'optionalCovBox'
            },
            goldPlatinumPrimaryHeading: {
                visible: policyType === 'HO3' || policyType === 'HF9',
                content: primaryContentData()
            },
            goldPlatinumSecondaryHeading: {
                visible: policyType === 'HO3' || policyType === 'HF9',
                content: secondaryContentData()
            },
            goldPlatinumLinkGridId: {
                visible: policyType === 'HO3' || policyType === 'HF9',
            },
            mandatoryCoverageContainerID: {
                visible: false
            },
            totalPremiumID: {
                visible: showPremium
            },
            emptyPremium: {
                visible: !showPremium
            },
            quoteProposalLinkContainer: {
                visible: !isSideBySideView,
                disabled: viewOnlyMode || !isQuoteProposalReady,
                onClick: (e) => onViewQuoteProposal(e, 'MAIN'),
            },
            monthlyPaymentScheduleComponent: {
                quoteID: _.get(submissionVM, 'quoteID.value', submissionVM.jobID?.value),
                visible: !!(_.get(submissionVM, 'quoteID.value', submissionVM.jobID?.value)) && !(viewOnlyMode) && !isQuoteStale,
                authHeader,
                transactionTotalAmount: this.getTotalPremium(),
                changeInCost: submissionVM.transactionCost?.value,
                startDate: _.get(submissionVM, 'baseData.periodStartDate.value',
                    _.get(submissionVM, 'periodStartDate.value')),
                endDate: _.get(submissionVM, 'baseData.periodEndDate.value',
                    _.get(submissionVM, 'periodStartDate.value')),
                jobTypeCode: _.get(submissionVM, 'baseData.jobType.value.code', ''),
                offerings: _.get(submissionVM, 'lobData.homeowners_EH.offerings.value'),
                quoteOfferings: _.get(submissionVM, 'quoteData.offeredQuotes.value', [])
            },
            supportingPolicyComponentContainer: {
                transactionVM: submissionVM,
                LOB: 'homeowners_EH',
                viewOnly: viewOnlyMode,
                updateWizardData,
                setFieldsChangedOnCoveragePage
            },
            windstormMitigationDiscountComponentContainer: {
                visible: (policyType === 'HO3' && _.includes(['NC', 'SC', 'CT'], policyState) || (policyType === 'HF9' && policyState === 'CT'))
            },
            windstormMitigationDiscountComponent: {
                transactionVM: submissionVM,
                viewOnly: viewOnlyMode,
                updateWizardData,
                setFieldsChangedOnCoveragePage,
                policyState
            },
            optionalCoverageSubtotalValue: {
                value: { currency: 'usd', amount: optionalCoverageSubtotalAmount },
                visible: !isQuoteStale
            },
            selectedOptionalCoverageDiv: {
                visible: showOptionalCoverageSection
            },
            selectedOptionalCoverageLabel: {
                content: showOptionalCoverageSection ? translator(ehCommonMessages.selectedOptionalCovLabel) : translator(ehCommonMessages.emptyOptionalCovLabel)
            },
            ...breakpointOverrides
        };
    }

    renderClauseTable = (breakpoint) => {
        const {
            setComponentValidation, onChangeSubmission, submissionVM, columnData
        } = this.props;

        const { formData } = this.state;
        const scheduleStaleCode = this.getStaleCode();
        const isQuoteStale = _.includes(scheduleStaleCode, columnData[0].code)
            || columnData[0].quote.data.status === 'Draft';
        const selectedOptionalCoveragesExclusionsAndConditions = {
            ...this.getSelectedOptionalCov(submissionVM, isQuoteStale)
        };
        
        const dataForComponent = {
            ...formData,
            ..._.pick(this.props, ['columnData', 'tableData', 'modifiers']),
            ...submissionVM,
            ...selectedOptionalCoveragesExclusionsAndConditions,
            totalPremium: this.getTotalPremium(),
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                chevron: Chevron,
                quotetableaccordioncarditerablecomponent: QuoteTableAccordionCardIterableComponent,
                underwritingissues: UnderwritingIssues
            },
            resolveCallbackMap: {
                onChangeSubmissionAndSync: this.changeSubmissionAndSync,
                onSyncCoverages: this.syncCoverages,
                onChangeSubmission,
                whatsIncludedHandler: this.showWhatsIncludedPopup
            }
        };
        const generatedMetadata = selectMetadata(breakpoint);

        return (
            <ViewModelForm
                uiProps={generatedMetadata.componentContent}
                model={dataForComponent}
                overrideProps={this.generateOverrides(breakpoint)}
                onValueChange={this.writeValue}
                onValidationChange={setComponentValidation}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }

    render() {
        return (
            <BreakpointTrackerContext.Consumer>
                {this.renderClauseTable}
            </BreakpointTrackerContext.Consumer>
        );
    }
}

const QuoteClauseTable = withModalContext(QuoteClauseTableWithoutModalContext);

export default withValidation(QuoteClauseTable);
