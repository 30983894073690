import React from 'react';
import { Grid, GridItem } from '@jutro/layout';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import messages from './ManualLossHistoryTableComponent.messages';

function ManualLossHistoryTable(props) {
    const { manualLossList } = props;
    const translator = useTranslator();

    const tableContent = [];

    manualLossList.forEach((loss, index) => {
        tableContent.push(
            <React.Fragment key={`manualLossCellContainer${index}`}>
                <GridItem className="py-2" tag="div" key={`manualLossCell${index}`}>
                    {loss}
                </GridItem>
            </React.Fragment>
        );
    })

    /**
     * Pattern for Auto Violations - Self Declared Violation: Operator: operatorName, Description: violationType , Incident Date: incidentDate ;
     * Pattern for Auto Loss - Self Declared Loss: Operator: operatorName, Description: lossType, Incident Date: incurDate, Amount: lossAmount;
     * Pattern for Property Loss - Self Declared Loss: Date: claimDate, Description: lossType, Amount: claimAmount, CAT indicator: catastrophicLoss;
     */
    return (
        <div className="mt-8">
            <div className="font-b">{translator(messages.manualLossHistoryHeader)}</div>
            <Grid columns={['1fr']} gap="none" className="grid-border-container mt-2">
                {tableContent}
            </Grid>
        </div>
    );
}

ManualLossHistoryTable.propTypes = {
    manualLossList: PropTypes.arrayOf(PropTypes.string)
};

ManualLossHistoryTable.defaultProps = {
    manualLossList: []
};

export default ManualLossHistoryTable;
