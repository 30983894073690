import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    SideBySideActionBarComponent
} from 'e1p-capability-policyjob-react';
import EhSideBySideQuoteDetailsComponent from '../EhSideBySideQuoteDetailsComponent/EhSideBySideQuoteDetailsComponent';
import EHSideBySideMandatoryCoveragesComponent from '../EHSideBySideMandatoryCoveragesComponent/EHSideBySideMandatoryCoveragesComponent';
import EHSideBySideOptionalCoveragesComponent from '../EHSideBySideOptionalCoveragesComponent/EHSideBySideOptionalCoveragesComponent';

function EhSideBySideContainer(props) {
    const {
        transactionVM,
        lobOfferings,
        quoteDataOfferings,
        backButtonFunction,
        updateQdd,
        setNewQdd,
        updateQuoteVersionName,
        addVersionFunction,
        setSelectedVersion,
        onRecalculateSideBySide,
        onWithdrawSideBySideVersion,
        onViewQuoteProposal,
        changeSubmissionAndSync,
        changeSubmission,
        syncCoverages,
        onValidate,
        hasExtendedAttributeEditability,
        policyState,
        isQuoteProposalReady,
        policyType,
        authUserData,
        underwritingIssues,
        onScheduleChange,
        generateTableData,
        generateExclusionTableData,
        generateConditionTableData
    } = props;

    const [quoteInDraftStatus, setQuoteInDraftStatus] = useState(false);

    useEffect(() => {
        if (quoteDataOfferings.findIndex((quote) => quote.status === 'Draft') !== -1) {
            setQuoteInDraftStatus(true);
        } else {
            setQuoteInDraftStatus(false);
        }
    }, [quoteDataOfferings])

    return (
        <React.Fragment>
            <SideBySideActionBarComponent id='ActionBar'
                backButtonFunction={backButtonFunction}
                addVersionFunction={addVersionFunction}
                onRecalculateSideBySide={onRecalculateSideBySide}
                onViewQuoteProposal={onViewQuoteProposal}
                isQuoteProposalReady={isQuoteProposalReady}
                quoteInDraftStatus={quoteInDraftStatus}
            />
            <EhSideBySideQuoteDetailsComponent
                lobOfferings={lobOfferings}
                quoteDataOfferings={quoteDataOfferings}
                updateQdd={updateQdd}
                setNewQdd={setNewQdd}
                updateQuoteVersionName={updateQuoteVersionName}
                setSelectedVersion={setSelectedVersion}
                onWithdrawSideBySideVersion={onWithdrawSideBySideVersion}
            />
            <EHSideBySideMandatoryCoveragesComponent
                lobOfferings={lobOfferings}
                onChangeSubmissionAndSync={changeSubmissionAndSync}
                onChangeSubmission={changeSubmission}
                onSyncCoverages={syncCoverages}
                onValidate={onValidate}
                hasExtendedAttributeEditability={hasExtendedAttributeEditability}
                quoteDataOfferings={quoteDataOfferings}
                policyState={policyState} />
            <EHSideBySideOptionalCoveragesComponent
                lobOfferings={lobOfferings}
                onChangeSubmissionAndSync={changeSubmissionAndSync}
                onChangeSubmission={changeSubmission}
                onSyncCoverages={syncCoverages}
                onValidate={onValidate}
                hasExtendedAttributeEditability={hasExtendedAttributeEditability}
                quoteDataOfferings={quoteDataOfferings}
                policyType={policyType}
                authUserData={authUserData}
                underwritingIssues={underwritingIssues}
                onScheduleChange={onScheduleChange}
                generateTableData={generateTableData}
                generateExclusionTableData={generateExclusionTableData}
                generateConditionTableData={generateConditionTableData}
                transactionVM={transactionVM}
                policyState={policyState} />
        </React.Fragment>
    );
}

EhSideBySideContainer.propTypes = {
    lobOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    quoteDataOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    backButtonFunction: PropTypes.func.isRequired,
    updateQdd: PropTypes.func.isRequired,
    setNewQdd: PropTypes.func.isRequired,
    updateQuoteVersionName: PropTypes.func.isRequired,
    addVersionFunction: PropTypes.func.isRequired,
    setSelectedVersion: PropTypes.func.isRequired,
    onRecalculateSideBySide: PropTypes.func.isRequired,
    onWithdrawSideBySideVersion: PropTypes.func.isRequired,
    onViewQuoteProposal: PropTypes.func.isRequired,
    policyState: PropTypes.string.isRequired,
    hasExtendedAttributeEditability: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
    isQuoteProposalReady: PropTypes.bool.isRequired,
    vehicles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    policyType: PropTypes.string.isRequired,
    authUserData: PropTypes.shape({}).isRequired,
    underwritingIssues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onScheduleChange: PropTypes.func.isRequired,
    generateTableData: PropTypes.func.isRequired,
    generateExclusionTableData: PropTypes.func.isRequired,
    generateConditionTableData: PropTypes.func.isRequired,
    transactionVM: PropTypes.shape({}).isRequired
};

export default EhSideBySideContainer;
