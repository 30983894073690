import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class InspectionService {
    /**
     * Requests inspection
     * @param {String} jobNumber
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static runInspection = (jobNumber, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('inspection'),
            'runInspection',
            [jobNumber],
            additionalHeaders
        );
    }
}
