import { defineMessages } from 'react-intl';

export default defineMessages({
    invalidNoOfSNIs: {
        id:
            'e1p.platform.lob.ea.Invalid number of Secondary Named Insured on policy.',
        defaultMessage: 'Invalid number of Secondary Named Insured on policy.'
    },
    removeCredit: {
        id: 'e1p.platform.lob.ea.REMOVE CREDIT',
        defaultMessage: 'REMOVE CREDIT'
    },
    diminishingDeductibleCreditInfoMessage: {
        id:
            'e1p.platform.lob.ea.diminishingDeductible.Any accrued Diminishing Deductible credit will be lost.',
        defaultMessage:
            'Any accrued Diminishing Deductible credit will be lost.'
    },
    diminishingDeductibleCreditTitle: {
        id:
            'e1p.platform.lob.ea.diminishingDeductible.Remove Diminishing Deductible Credit?',
        defaultMessage: 'Remove Diminishing Deductible Credit?'
    },
    supportingPolicyEAToolTip: {
        id:
            'e1p.platform.lob.ea.Supporting Policies are used to determine eligibility for Multi-Product Discount. Multi-Product Discount applies to any Auto policy whose named insured is also a named insured on a Property or Umbrella policy with a qualifying company.',
        defaultMessage:
            'Supporting Policies are used to determine eligibility for Multi-Product Discount. Multi-Product Discount applies to any Auto policy whose named insured is also a named insured on a Property or Umbrella policy with a qualifying company.'
    },
    removeDriverTitle: {
        id: 'e1p.platform.lob.ea.Remove Driver?',
        defaultMessage: 'Remove Driver?'
    },
    removeDriverDescription: {
        id: 'e1p.platform.lob.ea.The selected driver will be removed.',
        defaultMessage: 'The selected driver will be removed.'
    },
    travelPackagesTitle: {
        id: 'e1p.platform.lob.ea.Travel Packages',
        defaultMessage: 'Travel Packages'
    },
    travelPackagesText: {
        id:
            'e1p.platform.lob.ea.The Travel Packages offer bundled coverages at a reduced premium compared to selecting the coverages individually. Please make package selections in the Vehicle Level Coverages section.',
        defaultMessage:
            'The Travel Packages offer bundled coverages at a reduced premium compared to selecting the coverages individually. Please make package selections in the Vehicle Level Coverages section.'
    },
    vehicle: {
        id: 'e1p.platform.lob.ea.Vehicle',
        defaultMessage: 'Vehicle'
    },
    defensiveTooltipHelpText: {
        id:
            'e1p.platform.lob.ea.For the Defensive Driver discount to apply, Primary Named Insured and, if applicable, Secondary Named Insured must both be at least 55 years old and have taken a Defensive Driver course within the last three years.',
        defaultMessage:
            'For the Defensive Driver discount to apply, Primary Named Insured and, if applicable, Secondary Named Insured must both be at least 55 years old and have taken a Defensive Driver course within the last three years.'
    },
    pipMessage: {
        id:
            'e1p.platform.lob.ea.Applies only to occupations of Unemployed and Retired',
        defaultMessage: 'Applies only to occupations of Unemployed and Retired'
    },
    declareStatementThreeYears: {
        id:
            'e1p.platform.lob.ea.Please declare all losses, accidents and violations that occurred within the last three years.',
        defaultMessage:
            'Please declare all losses, accidents and violations that occurred within the last three years.'
    },
    declareStatementFiveYears: {
        id:
            'e1p.platform.lob.ea.Please declare all losses, accidents and violations that occurred within the last five years.',
        defaultMessage:
            'Please declare all losses, accidents and violations that occurred within the last five years.'
    },
    mvrAndLicenseResults: {
        id: 'e1p.platform.lob.ea.MVR & License Status',
        defaultMessage: 'MVR & License Status'
    },
    mvrAndLicenseOperator: {
        id: 'e1p.platform.lob.ea.Vehicle Operator',
        defaultMessage: 'Vehicle Operator'
    },
    mvrStatusData: {
        id: 'e1p.platform.lob.ea.MVR Status',
        defaultMessage: 'MVR Status'
    },
    mvrLicenseStatus: {
        id: 'e1p.platform.lob.ea.License Status',
        defaultMessage: 'License Status'
    },
    mvrAndLicenseResultsColumn: {
        id: 'e1p.platform.lob.ea.Results',
        defaultMessage: 'Results'
    },
    lastNameMVRNoHitStatus: {
        id:
            "e1p.platform.lob.ea.lastNameMVRNoHitStatus.{name}. Please verify Driver's Name, Driver's License Number and Driver's License State.",
        defaultMessage:
            "{name}. Please verify Driver's Name, Driver's License Number and Driver's License State.",
    },
    firstSectionMVRNoHitStatus: {
        id:
            'e1p.platform.lob.ea.firstSectionMVRNoHitStatus.MVR(s) could not be found for ',
        defaultMessage: 'MVR(s) could not be found for '
    },
    middleNameMVRNoHitStatus: {
        id: 'e1p.platform.lob.ea.middleNameMVRNoHitStatus.{name}, ',
        defaultMessage: '{name}, '
    },
    removeVehicle: {
        id: 'e1p.platform.lob.ea.REMOVE VEHICLE',
        defaultMessage: 'REMOVE VEHICLE'
    },
    vehicleFieldsClearedMessage: {
        id:
            'e1p.platform.lob.ea.The previously selected {fieldsCleared} doesn’t exist for the selected {selectedValue}. Select a new {fieldsCleared}.',
        defaultMessage:
            'The previously selected {fieldsCleared} doesn’t exist for the selected {selectedValue}. Select a new {fieldsCleared}.'
    },
    removeVehicleDescription: {
        id:
            'e1p.platform.lob.ea.vehicles.remove-vehicle.The selected vehicle will be removed.',
        defaultMessage: 'The selected vehicle will be removed.'
    },
    removeVehicleTitle: {
        id: 'e1p.platform.lob.ea.vehicles.remove-vehicle.Remove Vehicle?',
        defaultMessage: 'Remove Vehicle?'
    },
    newVehicle: {
        id: 'e1p.platform.lob.ea.vehicles.New Vehicle',
        defaultMessage: 'New Vehicle'
    },
    applicantsResideInSameStateNH: {
        id:
            'e1p.platform.lob.ea.vehicles.Are all applicants a resident of New Hampshire?',
        defaultMessage: 'Are all applicants a resident of New Hampshire?'
    },
    financialResponsibilityFiling: {
        id: 'e1p.platform.lob.ea.drivers.Financial Responsibility Filing?',
        defaultMessage: 'Financial Responsibility Filing?'
    },
    selectVehicle: {
        id: 'e1p.platform.lob.ea.Select Vehicle',
        defaultMessage: 'Select Vehicle'
    },
    excessLiabilityIndLabel: {
        id:
            'e1p.platform.lob.ea.vehicles.Does any named insured have a personal excess liability or personal umbrella insurance policy with any company?',
        defaultMessage:
            'Does any named insured have a personal excess liability or personal umbrella insurance policy with any company?'
    },
    seventeenDigitsVinRequiredErrorMessage: {
        id:
            'e1p.platform.lob.ea.A valid 17-digit VIN or Year, Make, Model, and Series is required.',
        defaultMessage:
            'A valid 17-digit VIN or Year, Make, Model, and Series is required.'
    },
    vin: {
        id: 'e1p.platform.lob.ea.VIN',
        defaultMessage: 'VIN'
    },
    principalDrivers: {
        id: 'e1p.platform.lob.ea.DriverAssignment.Principal Drivers',
        defaultMessage: 'Principal Drivers'
    },
    occasionalDrivers: {
        id: 'e1p.platform.lob.ea.DriverAssignment.Occasional Drivers',
        defaultMessage: 'Occasional Drivers'
    },
    driverAssignmentPageTitle: {
        id: 'e1p.platform.lob.ea.DriverAssignment.Driver Assignment',
        defaultMessage: 'Driver Assignment'
    },
    driverAssignmentPageSubTitle: {
        id:
            'e1p.platform.lob.ea.DriverAssignment.Driver Assignment.All rated Drivers must be assigned to a vehicle as a Principal or Occasional Driver',
        defaultMessage:
            'All rated Drivers must be assigned to a vehicle as a Principal or Occasional Driver.'
    },
    unassignedDriverValidation: {
        id:
            'e1p.platform.lob.ea.DriverAssignment.Driver Assignment.Each driver must be assigned to a vehicle whether principal or occasional',
        defaultMessage:
            'Each driver must be assigned to a vehicle whether principal or occasional.'
    },
    unassignedVehicleValidation: {
        id:
            'e1p.platform.lob.ea.DriverAssignment.Driver Assignment.Each vehicle needs a primary driver',
        defaultMessage: 'Each vehicle needs a primary driver.'
    },
    overAssignedDriverValidation: {
        id:
            'e1p.platform.lob.ea.DriverAssignment.Driver Assignment.A driver can only be assigned once',
        defaultMessage: 'A driver can only be assigned once.'
    },
    updateDriverAssignment: {
        id:
            'e1p.platform.lob.ea.DriverAssignment.Driver Assignment.If you have added and/or removed a driver and/or vehicle, please review and update driver assignment.',
        defaultMessage:
            'If you have added and/or removed a driver and/or vehicle, please review and update driver assignment.'
    },
    prayerForJudgement: {
        id: 'e1p.platform.lob.ea.manualViolation.Prayer For Judgement',
        defaultMessage: 'Prayer For Judgement'
    },
    prayerForJudgementTooltip: {
        id:
            'e1p.platform.lob.ea.manualViolation.Only the oldest violation will be used as Prayer for Judgment',
        defaultMessage:
            'Only the oldest violation will be used as Prayer for Judgment.'
    },
    activeNationalGuardTooltipHelptext: {
        id:
            'e1p.platform.lob.ea.To be eligible for the National Guard discount, the driver must also qualify for the Defensive Driver discount.',
        defaultMessage:
            'To be eligible for the National Guard discount, the driver must also qualify for the Defensive Driver discount.'
    },
    activeNationalGuard: {
        id:
            'e1p.platform.lob.ea.Are you currently a member of the {state} National Guard?',
        defaultMessage:
            'Are you currently a member of the {state} National Guard?'
    },
    newDriver: {
        id: 'e1p.platform.lob.ea.vehicles.New Driver',
        defaultMessage: 'New Driver'
    },
    make: {
        id: 'e1p.platform.lob.ea.Make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'e1p.platform.lob.ea.Model',
        defaultMessage: 'Model'
    },
    antiTheftHelpText: {
        id: 'e1p.platform.lob.ea.antiTheftCategoryHelpText',
        defaultMessage: 'Category I Devices: Alarm Only Devices – a device which sounds an audible alarm that can be heard at a distance of at least 300 feet for a minimum of three (3) minutes. \nCategory II Devices: Active Disabling Devices – a device which disables the vehicle by making the fuel, ignition, or starting system inoperative and a separate manual step is required to engage the device. \nCategory III Devices: Passive Disabling Devices – a device which disables the vehicle by making the fuel, ignition, or starting system inoperative and a separate manual step is not required to engage the device. \nCategory IV Devices: Vehicle Recovery System Devices – an electronic unit installed in a vehicle that is activated after the vehicle is stolen. When activated, the device provides information to law enforcement officials or another public or private entity regarding the vehicle’s location. The system provides for the routine delivery of the information to the appropriate law enforcement entity to assist in the recovery of the vehicle.'
    },
    loadingPrefillDataMessage: {
        id: 'e1p.platform.lob.ea.ea-edit-driver.Loading prefill data...',
        defaultMessage: 'Loading prefill data...'
    },
    loadingNextPageMessage: {
        id: 'e1p.platform.lob.ea.ea-edit-driver.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    eaDriverTitle: {
        id: 'e1p.platform.lob.ea.ea-edit-driver.Driver EA',
        defaultMessage: 'Drivers'
    },
    driverInformation: {
        id: 'e1p.platform.lob.ea.ea-edit-driver.Driver Information',
        defaultMessage: 'Driver Information'
    },
    driverInformationSubHeading: {
        id: 'e1p.platform.lob.ea.ea-edit-driver.Complete the required information for each driver added from pre-fill. Add additional drivers by clicking the "ADD VEHICLE" button',
        defaultMessage: 'Complete the required information for each household resident added from pre-fill.  Add additional residents by clicking the "ADD DRIVER" button.'
    },
    noAdditionalPrefillSubheading: {
        id: 'e1p.platform.lob.ea.ea-edit-driver.Driver pre-fill report was returned without any additional driver information.',
        defaultMessage: 'Driver pre-fill report was returned without any additional driver information.'
    },
    garagedOutOfState: {
        id: 'e1p.platform.lob.ea.ea-edit-vehicle.Vehicle is garaged out of the State more than 6 months a year',
        defaultMessage: 'Vehicle is garaged out of the State more than 6 months a year'
    },
    recalculateButtonLabel: {
        id: 'e1p.platform.lob.ea.templates.ea-edit.recalculateButtonLabel',
        defaultMessage: 'Recalculate'
    },
    duplicateDriversFound: {
        id: 'e1p.platform.lob.ea.Duplicate drivers found on policy.',
        defaultMessage: 'Duplicate drivers found on policy.'
    },
    nonOperatorDescription: {
        id: 'e1p.platform.lob.ea.Non-Operator Description',
        defaultMessage: 'Non-Operator Description'
    },
    pleaseProvideOtherReason: {
        id: 'e1p.platform.lob.ea.Please provide an "Other" reason below:',
        defaultMessage: 'Please provide an "Other" reason below:'
    },
    driverLicenseState: {
        id: 'e1p.platform.lob.ea.Driver\'s License State',
        defaultMessage: 'Driver\'s License State'
    },
    driverLicenseNumber: {
        id: 'e1p.platform.lob.ea.Driver\'s License Number',
        defaultMessage: 'Driver\'s License Number'
    },
    ageFirstLicensed: {
        id: 'e1p.platform.lob.ea.Age First Licensed',
        defaultMessage: 'Age First Licensed'
    },
    driversLicenseCountry: {
        id: 'e1p.platform.lob.ea.Driver\'s License Country',
        defaultMessage: 'Driver\'s License Country'
    },
    licensedInCanadaOrPuertoRico: {
        id: 'e1p.platform.lob.ea.Have you ever been licensed in Canada and/or Puerto Rico?',
        defaultMessage: 'Have you ever been licensed in Canada and/or Puerto Rico?'
    },
    experienceDrivingInCanadaOrPuertoRico: {
        id: 'e1p.platform.lob.ea.Number of years of driving experience in Canada and/or Puerto Rico',
        defaultMessage: 'Number of years of driving experience in Canada and/or Puerto Rico'
    },
    defensiveDriverCourse: {
        id: 'e1p.platform.lob.ea.Person has taken a Defensive Driver Course',
        defaultMessage: 'Person has taken a Defensive Driver Course'
    },
    studentAwayMoreThan100Miles: {
        id: 'e1p.platform.lob.ea.Student more than 100 miles away without a vehicle',
        defaultMessage: 'Student more than 100 miles away without a vehicle'
    },
    schoolZipCode: {
        id: 'e1p.platform.lob.ea.School Zip Code',
        defaultMessage: 'School Zip Code'
    },
    schoolCountry: {
        id: 'e1p.platform.lob.ea.School Country',
        defaultMessage: 'School Country'
    },
    goodStudent: {
        id: 'e1p.platform.lob.ea.Good Student',
        defaultMessage: 'Good Student'
    },
    militaryPersonalActiveDuty: {
        id: 'e1p.platform.lob.ea.Military Personnel returning from active duty',
        defaultMessage: 'Military Personnel returning from active duty'
    },
    addMilitaryDeploymentInfo: {
        id: 'e1p.platform.lob.ea.Add Military Deployment Info',
        defaultMessage: 'Add Military Deployment Info'
    },
    deleteSNIDriverConfirmationTitle: {
        id: 'e1p.platform.lob.ea.Delete driver Secondary Named Insured?',
        defaultMessage: 'Delete driver Secondary Named Insured?'
    },
    deleteSNIDriverConfirmationMessage: {
        id: 'e1p.platform.lob.ea.The selected driver will also be deleted as the Secondary Named Insured.',
        defaultMessage: 'The selected driver will also be deleted as the Secondary Named Insured.'
    },
});
