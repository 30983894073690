import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class PartnerService {

    /**
     * Gets advisors list based on last name
     * @param {String} advisorLastName advisor last name
     * @param {Object} additionalHeaders additional headers to be passed to the backend e.g.(auth)
     * @returns {Promise} promise from backend api call, on success returns the list of advisors
     */
    static getAmeripriseAdvisors = (advisorLastName, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('partnerdata'),
            'getAmeripriseAdvisors',
            [advisorLastName],
            additionalHeaders
        );
    }

    static validateCostcoMembership = (membershipNumber, jobNumber, additionalHeaders = {}) =>{
        return JsonRPCService.send(
            getProxiedServiceUrl('partnerdata'),
            'validateCostcoMembership',
            [membershipNumber, jobNumber],
            additionalHeaders
        );
    }
}
