import { defineMessages } from 'react-intl';

export default defineMessages({
    standardizingAddressMessage: {
        id: 'quoteandbind.ea.views.ea-policydetails-details.Standardizing address...',
        defaultMessage: 'Standardizing address...'
    },
    loadingNextPageMessage: {
        id: 'quoteandbind.ea.views.ea-policydetails-details.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    creatingYourSubmissionMessage: {
        id: 'quoteandbind.ea.views.ea-policydetails-details.Creating your submission...',
        defaultMessage: 'Creating your submission...'
    },
    agencyInformation: {
        id: 'quoteandbind.ea.wizard.step.Agency Information',
        defaultMessage: 'Agency Information'
    },
    insuredInformation: {
        id: 'quoteandbind.ea.wizard.step.Policy Details.Insured Information',
        defaultMessage: 'Insured Information'
    },
    Optional: {
        id: 'quoteandbind.ea.views.ea-policydetails-details.optional',
        defaultMessage: 'Optional'
    },
    accountTypeErrorMessage: {
        id: 'quoteandbind.ea.views.ea-policydetails-details.Account type must be a Company or a Person',
        defaultMessage: 'Account type must be a Company or a Person'
    },
    newAccountCreateErrorMessage: {
        id: 'quoteandbind.ea.views.ea-policydetails-details.Could not create the Account',
        defaultMessage: 'Could not create the Account'
    },
    startDate: {
        id: 'quoteandbind.ea.wizard.step.ea-policy-info.Effective Date',
        defaultMessage: 'Effective Date'
    },
    endDate: {
        id: 'quoteandbind.ea.views.ea-policy-info.Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    rateAsOfDate: {
        id: 'quoteandbind.ea.views.ea-policy-info.Rate as of Date',
        defaultMessage: 'Rate as of Date'
    },
    loadingSubmissionMessage: {
        id: 'quoteandbind.ea.views.ea-policydetails-details.Submission in progress...',
        defaultMessage: 'Submission in progress...',
    },
    standardizingAddress: {
        id: 'quoteandbind.ea.views.ea-policydetails-details.Standardizing Address...',
        defaultMessage: 'Standardizing Address...',
    },
    riskState: {
        id: 'gateway.views.new-quote.Risk State',
        defaultMessage: 'Risk State'
    },
    originalEffectiveDate: {
        id: 'quoteandbind.ea.wizard.step.ea-policy-info.Original Effective Date',
        defaultMessage: 'Original Effective Date'
    },
    eaPriorAddressTitle: {
        id: 'quoteandbind.ea.views.ea-priorAddress-details.priorAddress',
        defaultMessage: 'Prior Address'
    },
    eaPriorAddressTitleNumber: {
        id: 'quoteandbind.ea.views.ea-priorAddress-details.priorAddress Number:',
        defaultMessage: 'Prior Address:'
    },
    eaPriorAddress: {
        id: 'quoteandbind.ea.quote.Homeowners priorAddress',
        defaultMessage: 'Prior Address'
    },
    removePriorAddressTitle: {
        id: 'quoteandbind.ea.controllers.EHpriorAddressDetailsCtrl.Remove priorAddress?',
        defaultMessage: 'Remove Prior Address?'
    },
    removePriorAddressDescription: {
        id: 'quoteandbind.ea.drivers.remove-priorAddress.message',
        defaultMessage: 'Are you sure you want to remove this Prior Address from the list of Prior Addresses?'
    },
    policyInformation: {
        id: 'quoteandbind.ea.wizard.step.ea-policy-info.Policy Information',
        defaultMessage: 'Policy Information'
    },
    middleName: {
        id: 'quoteandbind.ea.views.ea-policydetails-details.middleName',
        defaultMessage: 'M.I.'
    }
});
