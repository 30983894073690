import { defineMessages } from 'react-intl';

export default defineMessages({
    Return: {
        id: 'monthlyPaymentSchedule.return',
        defaultMessage: 'Return to Coverage'
    },
    monthlyPaymentSchedule: {
        id: 'monthlyPaymentSchedule.monthlyPaymentSchedule',
        defaultMessage: 'Monthly Payment Schedule'
    },
    Term: {
        id: 'monthlyPaymentSchedule.term',
        defaultMessage: 'Term'
    },
    ChangeInCost: {
        id: 'monthlyPaymentSchedule.changeInCost',
        defaultMessage: 'Change in Cost'
    },
    RemainingPlanInvoices: {
        id: 'monthlyPaymentSchedule.remainingPlanInvoices',
        defaultMessage: 'Remaining Plan Invoices'
    },
    UnappliedAmount: {
        id: 'monthlyPaymentSchedule.unappliedAmount',
        defaultMessage: 'Unapplied Amount'
    },
    OriginalAmountDue: {
        id: 'monthlyPaymentSchedule.OriginalAmountDue',
        defaultMessage: 'Original Amount Due'
    },
    DueDate: {
        id: 'monthlyPaymentSchedule.dueDate',
        defaultMessage: 'Due Date'
    },
    InvoiceDate: {
        id: 'monthlyPaymentSchedule.invoiceDate',
        defaultMessage: 'Invoice Date'
    },
    InvoiceDueDate: {
        id: 'monthlyPaymentSchedule.invoiceDueDate',
        defaultMessage: 'Invoice Due Date'
    },
    Total: {
        id: 'monthlyPaymentSchedule.total',
        defaultMessage: ' Total'
    },
    MonthlyPaymentSchedule: {
        id: 'monthlyPaymentScheduleComponent.VIEW MONTHLY PAYMENT SCHEDULE',
        defaultMessage: 'VIEW MONTHLY PAYMENT SCHEDULE'
    },
    anErrorOccurred: {
        id: 'monthlyPaymentScheduleComponent.error.message',
        defaultMessage: 'Billing Integration Error : Unable to retrieve Billing Estimated Payment info'
    },
    anErrorOccurredTitle: {
        id: 'monthlyPaymentScheduleComponent.error.title',
        defaultMessage: 'Error'
    },
    invoiceAmount: {
        id: 'monthlyPaymentSchedule.invoiceAmount',
        defaultMessage: 'Invoice Amount'
    },
    paymentScheduledetails: {
        id: 'monthlyPaymentSchedule.paymentScheduledetails',
        defaultMessage: 'Monthly Payment Schedule Details'
    },
    transactionPremium: {
        id: 'monthlyPaymentSchedule.transactionPremium',
        defaultMessage: 'Premium'
    }
});
