function toUppercaseFirstChar(stringInput) {
    if (!stringInput) {
        return '';
    }
    return stringInput.charAt(0).toUpperCase() + stringInput.slice(1);
}

export default {
    toUppercaseFirstChar
};
