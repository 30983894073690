import { defineMessages } from 'react-intl';

export default defineMessages({
    ratingYourPolicyMessage: {
        id: 'reinstatement.common.directives.templates.policy-reinstatement.Please wait while we rate your policy...',
        defaultMessage: 'Please wait while we rate your policy...'
    },
    errorStartingReinstatement: {
        id: 'reinstatement.common.directives.templates.policy-reinstatement.Error Starting Reinstatement',
        defaultMessage: 'Error Starting Reinstatement'
    },
    returnStarting: {
        id: 'reinstatement.common.directives.templates.policy-reinstatement.Error Starting',
        defaultMessage: 'There has been an error starting the reinstatement'
    },
    sourceType: {
        id: 'reinstatement.common.Source Type',
        defaultMessage: 'Source Type'
    },
    reason: {
        id: 'reinstatement.common.Reason',
        defaultMessage: 'Reason'
    },
    effectiveDate: {
        id: 'reinstatement.common.Reinstatement Effective Date',
        defaultMessage: 'Reinstatement Effective Date'
    },
    policyNumber: {
        id: 'reinstatement.common.Policy Number',
        defaultMessage: 'Policy Number'
    }
});
