import { defineMessages } from 'react-intl';

export default defineMessages({
    optionalCoverages: {
        id: 'quoteandbind.componenets.Optional Coverages.Optional Coverage.Optional Coverages',
        defaultMessage: 'Optional Coverages'
    },
    optionalCovUpgradeCov: {
        id: 'quoteandbind.common.OptionalCoverages.Optional Coverage.Gold Offering.Select Package',
        defaultMessage: 'Select Package'
    },
    optionalCovGold: {
        id: 'quoteandbind.common.OptionalCoverages.Optional Coverage.Gold',
        defaultMessage: 'Gold'
    },
    optionalCovPlatinum: {
        id: 'quoteandbind.common.OptionalCoverages.Optional Coverage.Platinum',
        defaultMessage: 'Platinum'
    },
    questionCircleIcon: {
        id: 'quoteandbind.common.OptionalCoverages.Optional Coverage.Gold Offering.Coverage Comparison',
        defaultMessage: 'Coverage Comparison'
    },
    popularOptionalCovs: {
        id: 'quoteandbind.eh.common.OptionalCoverages.Popular Optional Coverages',
        defaultMessage: 'Popular Optional Coverages'
    },
    moreOptionalCovs: {
        id: 'quoteandbind.eh.common.OptionalCoverages.More Optional Coverages',
        defaultMessage: 'More Optional Coverages'
    },
    exclusions: {
        id: 'quoteandbind.eh.common.OptionalCoverages.Exclusions',
        defaultMessage: 'Exclusions'
    },
    conditions: {
        id: 'quoteandbind.eh.common.OptionalCoverages.Conditions',
        defaultMessage: 'Conditions'
    },
    backToCoverages: {
        id: 'quoteandbind.eh.common.OptionalCoverages.BACK TO COVERAGES',
        defaultMessage: 'BACK TO COVERAGES'
    },
    completeMissingFields: {
        id: 'quoteandbind.eh.common.OptionalCoverages.Please complete the missing fields.',
        defaultMessage: 'Please complete the missing fields.'
    },
    optionalCoveragesLabel: {
        id: 'quoteandbind.eh.common.OptionalCoverages.Optional Coverage Package',
        defaultMessage: 'Optional Coverage Package'
    },
    optionalCoverageSelect: {
        id: 'quoteandbind.eh.common.QuoteClauseTable.selectCoverage',
        defaultMessage: 'Select any coverages marked with'
    },
    optionalCoverageLabel: {
        id: 'quoteandbind.eh.common.QuoteClauseTable.discountMessage',
        defaultMessage: 'to receive a Package Discount. The more coverages selected, the greater the discount.'
    },
});
