import { defineMessages } from 'react-intl';

export default defineMessages({
    approvalType: {
        id: 'uwIssueDetail.component.uWIssue.Approval Type',
        defaultMessage: 'Approval Type'
    },
    shortDescription: {
        id: 'uwIssueDetail.component.uWIssue.shortDescription',
        defaultMessage: 'Short Description'
    },
    longDescription: {
        id: 'uwIssueDetail.component.uWIssue.longDescription',
        defaultMessage: 'Long Description'
    },
    reopen: {
        id: 'uwIssueDetail.component.uWIssue.Reopen',
        defaultMessage: 'Reopen'
    },
    reject: {
        id: 'uwIssueDetail.component.uWIssue.Reject',
        defaultMessage: 'Reject'
    },
    approve: {
        id: 'uwIssueDetail.component.uWIssue.Ok',
        defaultMessage: 'Ok'
    },
    cancel: {
        id: 'uwIssueDetail.component.uWIssue.Cancel',
        defaultMessage: 'Cancel'
    },
    allowEdit: {
        id: 'uwIssueDetail.component.uWIssue.Allow Edit',
        defaultMessage: 'Allow Edit'
    },
    through: {
        id: 'uwIssueDetail.component.uWIssue.Through',
        defaultMessage: 'Through'
    },
    validUntil: {
        id: 'uwIssueDetail.component.uWIssue.Valid Until',
        defaultMessage: 'Valid Until'
    },
    yes: {
        id: 'uwIssueDetail.component.uWIssue.Yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'uwIssueDetail.component.uWIssue.No',
        defaultMessage: 'No'
    },
    none: {
        id: 'uwIssueDetail.component.uWIssue.None',
        defaultMessage: 'None'
    },
    quote: {
        id: 'uwIssueDetail.component.uWIssue.Quote',
        defaultMessage: 'Quote'
    },
    rateRelease: {
        id: 'uwIssueDetail.component.uWIssue.Rate Release',
        defaultMessage: 'Rate Release'
    },
    quoteRelease: {
        id: 'uwIssueDetail.component.uWIssue.Quote Release',
        defaultMessage: 'Quote Release'
    },
    bind: {
        id: 'uwIssueDetail.component.uWIssue.Bind',
        defaultMessage: 'Bind'
    },
    issuance: {
        id: 'uwIssueDetail.component.uWIssue.Issuance',
        defaultMessage: 'Issuance'
    }
});
