import React, { useContext } from 'react';
import {
    find as _find
} from 'lodash';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';


function createViewModelNode(vmObject, dtoName) {
    const viewModelService = useContext(ViewModelServiceContext);
    const vmNode = viewModelService.create(
        vmObject,
        'pc',
        dtoName
    );
    return vmNode;
};


/**
 * @param {String} dtoName full name of dto that typekey attribute exists on
 * @param {String} attributeName name of the typekey attribute on given dto
 * @returns {Obect} Array of available typekey values with name mapped for translation
 */
function getAvailableTypekeyValues(dtoName, attributeName) {
    let typeList = createViewModelNode({}, dtoName);
    typeList = typeList[attributeName].aspects.availableValues;
    return typeList.map(
        (key) => {
            return ({
                name: {
                    id: key.name
                },
                code: key.code
            });
        }
    );
};

/**
 * Translates the value that matches the given typecodeToTranslate to get the name attribute of the typekey
 * This function can be used directly to prevent overhead of recreating the availableTypekeyValues 
 * for every element in an array for multiple successive calls.
 * Ex: Looping over losses/violations to get typekey name of the lossType/violationType attribute
 *
 * @param {String} typecodeToTranslate typecode value at propertyName to translate into display name
 * @param {Object} availableValues list of available typelist values mapped using the getAvailableTypelistValues function
 * @returns {String} translated value of typekey (typekey.DisplayName) that mtaches typecodeToTranslate on given typelist
 * 
 */
function getTypeKeyNameGivenAvailableValues(typecodeToTranslate, availableTypekeyValues) {
    const translator = useTranslator();
    const matchingTypekeyValue = availableTypekeyValues.find( i => i.code === typecodeToTranslate);
    return translator(matchingTypekeyValue.name)
}

/**
 * Uses the dtoName and propertyName to generate and map a list of available typelist values
 * then translates the value that matches the given typecodeToTranslate to get the name attribute of the typekey
 * 
 * @param {String} dtoName full name of dto that typelist attribute exists on
 * @param {String} propertyName name of the typelist attribute on given dto
 * @param {String} typecodeToTranslate typecode value at propertyName to translate into display name
 * @returns {String} translated value of typekey (typekey.DisplayName) that matches typecodeToTranslate on given typelist
 * 
 */
function getTypeKeyName(dtoName, propertyName, typecodeToTranslate) {
    const availableTypekeyValues = getAvailableTypekeyValues(dtoName, propertyName)
    return getTypeKeyNameGivenAvailableValues(typecodeToTranslate, availableTypekeyValues)
}

/**
 * get available values for typekey and typefilter
 * @param {*} viewModelService
 * @param {*} typekeyName
 * @param {*} typekeyFilter
 * @returns {*} array of availableValues
 */
function getAvailableValuesForTypekey(viewModelService, typekeyName, typekeyFilter = null) {
    let typelist = [];
    if (viewModelService && typekeyName) {
        let typelistVM = viewModelService.productMetadata
            .get('pc')
            .types.getTypelist(typekeyName);
        if (typekeyFilter) {
            typelistVM = _find(typelistVM.filters, { name: typekeyFilter });
        }
        typelist = typelistVM.codes ? typelistVM.codes : [];
    }
    return typelist.map(
        (key) => {
            return ({
                name: {
                    id: key.name
                },
                code: key.code
            });
        }
    );
}

export default { getAvailableTypekeyValues, getTypeKeyName, getTypeKeyNameGivenAvailableValues, getAvailableValuesForTypekey };
