import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import PCEUUnderlyingPolicyPage from './pages/UnderlyingPolicy/UnderlyingPolicyPage';
import PCEUViewUnderlyingPolicyPage from './pages/UnderlyingPolicy/viewOnly/ViewUnderlyingPolicyPage';
import PCEUVehicleOperatorPage from './pages/VehicleOperator/EUVehicleOperatorPage';
import PCEUViewVehicleOperatorPage from './pages/VehicleOperator/viewOnly/ViewEUVehicleOperatorPage';
import PCEUCoveragesPage from './pages/Coverages/CoveragesPage';
import PCEUViewCoveragesPage from './pages/Coverages/viewOnly/ViewCoveragesPage';
import PCEUPolicyChangeDiffPage from './pages/PolicyChangeDiff/PolicyChangeDiffPage';
import PCEUViewPolicyChangeDiffPage from './pages/PolicyChangeDiff/viewOnly/ViewPolicyChangeDiffPage';
import PCEUInsuredDetailsPage from './pages/InsuredDetails/InsuredDetailsPage';
import PCEUViewInsuredDetailsPage from './pages/InsuredDetails/viewOnly/ViewInsuredDetailsPage';
import PCEUStartChangePage from './pages/StartChange/StartChangePage';
import PCEUViewStartChangePage from './pages/StartChange/viewOnly/ViewStartChangePage';
import PCEUThirdPartyInterestPage from './pages/ThirdPartyInterest/ThirdPartyInterestPage';
import PCEUViewThirdPartyInterestPage from './pages/ThirdPartyInterest/viewOnly/ViewThirdPartyInterestPage';
import PCEURiskAnalysisPage from './pages/RiskAnalysis/RiskAnalysisPage';
import PCEUPremiumBoxComponent from './components/PCEUPremiumBoxComponent/PCEUPremiumBoxComponent';
import PCEUViewRiskAnalysisPage from './pages/RiskAnalysis/viewOnly/ViewRiskAnalysisPage';
import PCEUThankYouPage from './pages/ThankYou/ThankYouPage';
import PCEUPreQualificationPage from './pages/EUPreQualification/EUPreQualificationPage';
import PCEUViewPreQualificationPage from './pages/EUPreQualification/viewOnly/ViewEUPreQualificationPage';
import PCEUClauseComponentVM from './components/Clauses/PCEUClauseComponentVM';
import PCEUSingleClauseComponentVM from './components/Clauses/PCEUSingleClauseComponentVM';
import PCEUClauseComponent from './components/Clauses/PCEUClauseComponent';

setComponentMapOverrides(
    {
        PCEUUnderlyingPolicyPage: { component: 'PCEUUnderlyingPolicyPage' },
        PCEUVehicleOperatorPage: { component: 'PCEUVehicleOperatorPage' },
        PCEUCoveragesPage: { component: 'PCEUCoveragesPage' },
        PCEUThirdPartyInterestPage: { component: 'PCEUThirdPartyInterestPage' },
        PCEUInsuredDetailsPage: { component: 'PCEUInsuredDetailsPage' },
        PCEUPolicyChangeDiffPage: { component: 'PCEUPolicyChangeDiffPage' },
        PCEUStartChangePage: { component: 'PCEUStartChangePage' },
        PCEURiskAnalysisPage: { component: 'PCEURiskAnalysisPage' },
        PCEUViewStartChangePage: { component: 'PCEUViewStartChangePage' },
        PCEUViewInsuredDetailsPage: { component: 'PCEUViewInsuredDetailsPage' },
        PCEUViewUnderlyingPolicyPage: { component: 'PCEUViewUnderlyingPolicyPage' },
        PCEUViewVehicleOperatorPage: { component: 'PCEUViewVehicleOperatorPage' },
        PCEUViewRiskAnalysisPage: { component: 'PCEUViewRiskAnalysisPage' },
        PCEUViewCoveragesPage: { component: 'PCEUViewCoveragesPage' },
        PCEUViewThirdPartyInterestPage: { component: 'PCEUViewThirdPartyInterestPage' },
        PCEUViewPolicyChangeDiffPage: { component: 'PCEUViewPolicyChangeDiffPage' },
        PCEUPremiumBoxComponent: { component: 'PCEUPremiumBoxComponent' },
        PCEUThankYouPage: { component: 'PCEUThankYouPage' },
        PCEUPreQualificationPage: { component: 'PCEUPreQualificationPage' },
        PCEUViewPreQualificationPage: { component: 'PCEUViewPreQualificationPage' },
        PCEUClauseComponentVM: { component: 'PCEUClauseComponentVM' },
        PCEUSingleClauseComponentVM: { component: 'PCEUSingleClauseComponentVM' },
        PCEUClauseComponent: { component: 'PCEUClauseComponent' }
    },
    {
        PCEUUnderlyingPolicyPage,
        PCEUVehicleOperatorPage,
        PCEUCoveragesPage,
        PCEUThirdPartyInterestPage,
        PCEUInsuredDetailsPage,
        PCEUPolicyChangeDiffPage,
        PCEUStartChangePage,
        PCEURiskAnalysisPage,
        PCEUViewStartChangePage,
        PCEUViewInsuredDetailsPage,
        PCEUViewUnderlyingPolicyPage,
        PCEUViewVehicleOperatorPage,
        PCEUViewRiskAnalysisPage,
        PCEUViewCoveragesPage,
        PCEUViewThirdPartyInterestPage,
        PCEUViewPolicyChangeDiffPage,
        PCEUPremiumBoxComponent,
        PCEUThankYouPage,
        PCEUPreQualificationPage,
        PCEUViewPreQualificationPage,
        PCEUClauseComponentVM,
        PCEUSingleClauseComponentVM,
        PCEUClauseComponent
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as EUPolicyChangeWizard } from './EUPolicyChangeWizard';
