import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class DriverLicenseValidationService {
    /**
     * Validates a drivers license
     * @param {String} DriverLicenseDTO DriverLicenseDTO
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static validateDriverLicense = (DriverLicenseDTO, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('drivervalidation'),
            'validateDriverLicense',
            [DriverLicenseDTO],
            additionalHeaders
        );
    }
}
