import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useRef
} from 'react';
import {
    get, filter, map, sortBy, uniqBy
} from 'lodash';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { CoverageUtil } from 'e1p-portals-util-js';
import messages from '../QuotePage.messages';
import metadata from '../QuotePage.metadata.json5';

function structureClauseTableData(coverage) {
    // putting ID into an object as the Jutro table component expects an object
    return {
        publicID: coverage.publicID
    };
}

function generateClauseData(columnData, coveragePath) {
    return columnData.map(({ lob, code }) => {
        const completeCoveragePath = `coverages.${coveragePath}`;

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

const getCoveragesUniqueID = (rewriteVM) => {
    const offerings = get(rewriteVM, 'lobData.personalUmbrella_EU.offerings.value');
    const coverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.coverages.map(structureClauseTableData)
    )), 'publicID');

    return {
        coverages
    };
};

const generateColumnData = (rewriteVM) => {
    const lobOfferingPath = 'lobData.personalUmbrella_EU.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = get(rewriteVM, `${lobOfferingPath}.value`);
    const quoteOfferings = get(rewriteVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings.map((lobOffering, lobIndex) => {
        const quoteDataIndex = quoteOfferings.findIndex(
            (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
        );
        const quoteData = quoteOfferings[quoteDataIndex];

        return {
            name: lobOffering.branchName,
            code: lobOffering.branchCode,
            quote: {
                path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                data: quoteData
            },
            lob: {
                path: `${lobOfferingPath}.children[${lobIndex}]`,
                data: lobOffering
            }
        };
    });

    return sortBy(columnData, ['code']);
};

const generateTableData = (rewriteVM, columnData, translator) => {
    const uniqueID = getCoveragesUniqueID(rewriteVM);

    const coverages = {
        header: translator(messages.policyLevelCoverages),
        data: uniqueID.coverages,
        tableContent: generateClauseData(columnData, 'coverages')
    };

    return [coverages];
};

function ViewQuotePage(props) {
    const {
        wizardData: rewriteVM,
        updateWizardData,
        stopSkipping
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const { authHeader, authUserData } = useAuthentication();
    const isNewBusiness = get(rewriteVM, 'baseData.value.businessTransactionType_Ext') === 'NewBusiness';
    const isQuickQuote = useRef(
        get(rewriteVM.value, 'quoteType') === 'Quick'
    ).current;


    const buyNow = useCallback(
        async () => rewriteVM,
        [rewriteVM]
    );

    const generateOverrides = useCallback(() => {
        const columnData = generateColumnData(rewriteVM);
        const { underwritingIssues, viewOnly } = props;

        return {
            '@field': {
                // apply to all fields
                labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                readOnly: viewOnly
            },
            quotePageLoadingIndicator: {
                loaded: true,
                text: translator(messages.creatingYourOfferingMessage)
            },
            quotePageContainer: {
                visible: true
            },
            quoteProposalLinkContainer: {
                visible: isNewBusiness
            },
            quoteProposalLinkId: {
                disabled: true
            },
            quoteTable: {
                columnData,
                tableData: generateTableData(rewriteVM, columnData, translator),
                underwritingIssues,
                submissionVM: rewriteVM,
                updateWizardData,
                authHeader,
                viewOnlyMode: viewOnly,
                modifiers: get(rewriteVM, 'lobData.personalUmbrella_EU.modifiers.value', []),
                authUserData,
                opCo
            },
            personalLiabilityLimitUwIssueInfoMessageDiv: {
                visible: false
            },
            quoteProposalFailureErrorDiv: {
                visible: false
            },
            paperlessEmailChangedMessageDiv:{
                visible: false
            }
        };
        // prevent complaining props and showQuoteStartDate is missing in the dependencies list
        // watch props will cause refresh function ref multiple time,
        // showQuoteStartDate is generated from build time, so won't change in the runtime
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, rewriteVM, translator, opCo]);

    useEffect(() => {
        const offerings = get(rewriteVM, 'quoteData.offeredQuotes.value');
        const draftOffers = filter(offerings, ['status', 'Draft']);
        const draftBranchCodes = map(draftOffers, 'branchCode');

        setStaleQuoteBranchCode(draftBranchCodes);
        // Above logic only needs to run once when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // eslint-disable-next-line no-warning-comments
        // FIXME: Weird skipping issue from GW wizard platform code
        stopSkipping();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // overriding coverage help text for given clause.
    useEffect(() => {
        get(rewriteVM, 'value.lobData.personalUmbrella_EU.offerings[0].coverages.coverages').forEach((clause) => {
            const coveragesHelpText = CoverageUtil.getCoveragesHelpTextForEU(clause);

            if (coveragesHelpText) {
                clause.description = coveragesHelpText
            }
        })
    }, [rewriteVM]);

    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);

    return (
        <WizardPage
            shouldLink
            finish={isQuickQuote}
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveCallbackMap: {
                        onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
                        onStaleQuoteBranchCode,
                        onRecalculate: () => { },
                        onResetQuote: () => { },
                        onChangeSubmissionAndSync: () => { },
                        onChangeSubmission: () => { },
                        onSyncCoverages: () => { },
                        onPrint: () => { },
                    },
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={rewriteVM}
                        overrideProps={generateOverrides()}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                );
            }}
        </WizardPage>
    );
}

ViewQuotePage.propTypes = wizardProps;
export default ViewQuotePage;
