import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class ProducerDetailsService {
    /**
     * Fetches policy Detals
     * @param {String} externalId String
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getProducerDetails = (externalId, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('producerDetails'),
            'getProducerDetailsForExternalId',
            [externalId],
            additionalHeaders
        );
    }

    /**
     * Gets all licensed producers, if user is not a licensed producer
     * @param {String} jobID String
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getLicensedProducers = (jobID, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('producerDetails'),
            'getLicensedProducers',
            [jobID],
            additionalHeaders
        );
    }
}
