import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './HeatingSystemComponent.metadata.json5';
import styles from './HeatingSystemComponent.scss';
import './HeatingSystemComponent.messages';

function HeatingSystemComponent(props) {
    const {
        data: heatingSystemVM,
        labelPosition,
        path,
        id,
        onValidate,
        onValueChange,
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [heatingSystemVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                heatingSystemVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [heatingSystemVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={heatingSystemVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

HeatingSystemComponent.propTypes = {
    value: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};
HeatingSystemComponent.defaultProps = {
    value: {},
    labelPosition: 'top',
    path: undefined
};

export default HeatingSystemComponent;
