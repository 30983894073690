import React, {
    useCallback,
    useState,
    useContext,
    useMemo,
    useEffect
} from 'react';
import { get, set } from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import {
    parseErrors
} from '@xengage/gw-portals-edge-validation-js';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { PreRenewalDirectionService } from 'e1p-capability-renewal';
import { commonMessages } from 'e1p-platform-translations';
import PropTypes from 'prop-types';
import styles from './E1PPreRenewalDirectionComponent.modules.scss';
import metadata from './E1PPreRenewalDirectionComponent.metadata.json5';
import messages from './E1PPreRenewalDirectionComponent.messages';

import { Button } from '@jutro/legacy/components';

function E1PPreRenewalDirectionComponent(props) {
    const {
        title,
        policyData,
        authHeader,
        isOpen,
        onResolve,
        onReject,
        initialPreRenewalDirectionVM
    } = props;
    const { authUserData } = useAuthentication();
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [preRenewalDirectionVM, updatePreRenewalDirectionVM] = useState(
        initialPreRenewalDirectionVM
    );
    const [isCreatingPreRenewalDirection, setIsCreatingPreRenewalDirection] = useState(false);
    const [isCreatingInteractiveLetter, setIsCreatingInteractiveLetter] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [preRenewalDirectionAvailableVals, setPreRenewalDirectionAvailableVals] = useState([]);
    const [callIsSuccessfulWithWarnings, setCallIsSuccessfulWithWarnings] = useState(false);
    const [isNoteAdded, setIsNoteAdded] = useState(false);
    const [noteDefaultValues, setNoteDefaultValues] = useState(undefined);
    const intl = useContext(IntlContext);

    const {
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation(
        'E1PPreRenewalDirectionComponent'
    );

    useEffect(() => {
        const prerenwalDirectionCodeAndPermissionMap = {
            nonrenew: authUserData.permissions_Ext.includes('nonrenew'),
            nottaken: authUserData.permissions_Ext.includes('nottakerenewal')
        };

        // only two options need to be shown on UI i.e nonrenew and not taken
        // and these option are visible to user based on permissions.
        // getting available prerenewal direction values based on permission
        setPreRenewalDirectionAvailableVals(viewModelService.productMetadata.get('pc')
            .types.getTypelist('PreRenewalDirection')
            .codes
            .filter((item) => item.code === 'nonrenew' || item.code === 'nottaken')
            .filter((item) => prerenwalDirectionCodeAndPermissionMap[item.code])
            .map((item) => ({
                    code: item.code,
                    name: {
                        id: item.name
                    }
                })));

        // calling loadDetails method to get default values(note security level, note topic)
        // for prerenewal direction, in future we will pass policy number to get letter mailing date
        PreRenewalDirectionService
            .loadDetails(get(policyData, 'policyNumber'), authHeader)
            .then((response) => {
                const errorsAndWarnings = get(response, 'errorsAndWarnings', []);

                if (errorsAndWarnings.length === 0) {
                    // storing notes default values in state variable
                    // to reuse it as we are setting note's node undefined when we click on remove
                    setNoteDefaultValues(response.note);
                    response.policyNumber = get(policyData, 'policyNumber');
                    set(preRenewalDirectionVM, 'value', response);

                    const newPreRenewalDirectionVM = viewModelService.clone(preRenewalDirectionVM);

                    updatePreRenewalDirectionVM(newPreRenewalDirectionVM);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            set(preRenewalDirectionVM, `${path}.value`, value);

            const newPreRenewalDirectionVM = viewModelService.clone(preRenewalDirectionVM);

            updatePreRenewalDirectionVM(newPreRenewalDirectionVM);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    /**
     * Helper function gets called when user clicks on add note
     * and defaults note's fields
     */
    const onAddOfNote = useCallback(
        () => {
            const noteDefaultVal = noteDefaultValues || {
                securityLevel_Ext: 'internalonly',
                topic: 'prerenewal'
            };

            writeValue(noteDefaultVal, 'note');
            setIsNoteAdded(true);
        }, [noteDefaultValues, writeValue]
    );

    /**
     * Helper function gets called when user clicks on create letter
     */
    const onCreateLetter = useCallback(
        async () => {
            setIsCreatingInteractiveLetter(true);
            // Create Interactive Letter
            preRenewalDirectionVM.value.flowStepIDs_Ext = ['prerenewaldirection'];

            // Request for create non renewal interactive letter 
            const nonRenewalInteractiveLetterResponse = await PreRenewalDirectionService.createNonRenewalInteractiveLetter(
                preRenewalDirectionVM.value, authHeader
            );

            if (nonRenewalInteractiveLetterResponse?.interactiveLetterRedirectURL) {
                // Open window with URL
                window.open(
                    nonRenewalInteractiveLetterResponse.interactiveLetterRedirectURL,
                    '_blank'
                );
            }

            setIsCreatingInteractiveLetter(false);
        }, [authHeader, preRenewalDirectionVM.value]
    );

    /**
     * Helper function gets called when user clicks on delete button
     * Setting note node to undefined
     */
    const onRemoveOfNote = useCallback(
        () => {
            writeValue(undefined, 'note');
            setIsNoteAdded(false);
        }, [writeValue]
    );

    const onOK = useCallback(
        () => {
            onResolve(preRenewalDirectionVM);
        }, [onResolve, preRenewalDirectionVM]
    );

    const onSave = useCallback(
        async () => {
            setIsCreatingPreRenewalDirection(true);
            preRenewalDirectionVM.value.flowStepIDs_Ext = ['prerenewaldirection'];

            const preRenewalDirectionResponse = await PreRenewalDirectionService
                .createPreRenewalDirection(preRenewalDirectionVM.value, authHeader);
            const errorsAndWarnings = get(preRenewalDirectionResponse, 'errorsAndWarnings', []);
            const blockingUWIssues = errorsAndWarnings?.underwritingIssues?.filter((item) => item?.approvalBlockingPoint !== 'NonBlocking');

            set(errorsAndWarnings, 'underwritingIssues', blockingUWIssues);

            const errors = parseErrors(errorsAndWarnings);
            const exceptionsPath = 'errorsAndWarnings.exceptions_Ext';
            const exceptions = get(preRenewalDirectionResponse, exceptionsPath, []);

            setIsCreatingPreRenewalDirection(false);

            if (errors?.length > 0 || exceptions.length > 0) {
                if (
                    errors?.length > 0
                    && errors
                        .filter((error) => error.level === 'LEVEL_WARN').length === errors.length
                ) {
                    // User clicked on save button and successfully added prerenewal direction
                    // but received some warnings we will show warnings and show Ok button
                    // and will not call add prerenewal direction api again
                    // to achieve these setting below state variable
                    setCallIsSuccessfulWithWarnings(true);
                }

                setValidationErrors(errors.concat(exceptions));

                return false;
            }

            onResolve(preRenewalDirectionVM);

            return setIsCreatingPreRenewalDirection(false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    const policyTermLabel = useMemo(
        () =>
            `${intl.formatDate(
                new Date(
                    `${get(policyData, 'latestPeriod.effectiveDate')}T12:00:00`
                ),
                {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    timeZone: 'UTC',
                }
            )} ` +
            `to ${intl.formatDate(
                new Date(
                    `${get(policyData, 'latestPeriod.expirationDate')}T12:00:00`
                ),
                {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    timeZone: 'UTC',
                }
            )}`,
        [policyData, intl]
    );

    const noteRelatedTo = `Policy ${get(policyData, 'policyNumber')} ${get(policyData, 'product.productName')}`;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'top',
            showRequired: true,
            readOnly: callIsSuccessfulWithWarnings,
            autoComplete: false
        },
        e1pPreRenewalDirectionLoader: {
            loaded: !isCreatingPreRenewalDirection,
            text: messages.addingPreRenewalDirection
        },
        e1pPreRenewalDirectionPageContent: {
            visible: !isCreatingPreRenewalDirection
        },
        policyTerm: {
            value: policyTermLabel,
            readOnly: true
        },
        prerenewalDirection: {
            availableValues: preRenewalDirectionAvailableVals,
            required: true,
            readOnly: callIsSuccessfulWithWarnings
        },
        e1pPreRenewalDirectionNoteFieldsMainContainer: {
            visible: authUserData?.permissions_Ext.includes('notecreate')
        },
        addNoteButtonId: {
            visible: !isNoteAdded && !callIsSuccessfulWithWarnings
        },
        removeNoteButtonId: {
            visible: !callIsSuccessfulWithWarnings
        },
        addNoteLabelAndRemoveButtonContainer: {
            visible: isNoteAdded
        },
        e1pPreRenewalDirectionNoteFields: {
            visible: isNoteAdded
        },
        noteTopic: {
            readOnly: true
        },
        noteRelatedTo: {
            value: noteRelatedTo
        },
        noteText: {
            required: isNoteAdded
        },
        wizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        e1pPreRenewalDirectionCreateLetterMainContainer: {
            visible: get(preRenewalDirectionVM, ['selectedPreRenewalDirection', 'value', 'code']) === 'nonrenew'
        },
        createLetterButton: {
            disabled: isCreatingInteractiveLetter
        },
        createLetterMailingDate: {
            dateDTO: preRenewalDirectionVM.nonRenewLetterMailingDate,
            readOnly: true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            onCreateLetter,
            onAddOfNote,
            onRemoveOfNote
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    const isLoading = useCallback(() => !isCreatingPreRenewalDirection, [isCreatingPreRenewalDirection]);

    useEffect(() => {
        registerComponentValidation(isLoading);
    }, [isLoading, registerComponentValidation]);

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                preRenewalDirectionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, preRenewalDirectionVM]
    );

    // User clicked on save button and successfully added prerenewal direction
    // but received some warnings, we will show warnings and show Ok button instead of save button,
    // will not be calling add prerenewal direction api again on click of ok
    const onSaveButtonText = callIsSuccessfulWithWarnings
        ? translator(commonMessages.close) : translator(messages.save);
    const onSaveFnCall = callIsSuccessfulWithWarnings ? onOK : onSave;

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={title} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={preRenewalDirectionVM}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onSaveFnCall} disabled={!isComponentValid}>
                    {onSaveButtonText}
                </Button>
                <Button
                    onClick={onCancel}
                    hidden={callIsSuccessfulWithWarnings}
                    disabled={isCreatingPreRenewalDirection}
                    type="outlined"
                >
                    {translator(messages.cancel)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

E1PPreRenewalDirectionComponent.propTypes = {
    title: PropTypes.string.isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    policyData: PropTypes.shape({}).isRequired,
    initialPreRenewalDirectionVM: PropTypes.shape({}).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired
};

export default E1PPreRenewalDirectionComponent;
