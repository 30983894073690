import { ClausesUtil as PlatformClausesUtil } from '@xengage/gw-policycommon-util-js';
import _ from 'lodash';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';

/**
 * Sets the value for the clause
 * @function getPathToTerm
 * @param {String} changedPath the path to the clause
 * @returns {Object} returns the new updated object
 */
function getPathToTerm(changedPath) {
    // onBlur event returns an object instead of path as a String
    const path = _.isString(changedPath) ? changedPath : changedPath.model;
    const matched = /(.*.terms(.children)?\[\d+\])(.*$)/.exec(path);
    if (!matched) {
        return changedPath;
    }
    const [, termPath] = matched;
    return termPath;
}

/**
 * Sets the value for the clause
 * @function setClauseTermUpdated
 * @param {Object} data model for data access
 * @param {String} path the path to the clause
 */
function setClauseTermUpdated(data, path) {
    const pathToTermVM = getPathToTerm(path);
    const pathToTerm = ViewModelUtil.getNonVMPath(pathToTermVM);
    _.set(data, `${pathToTerm}.updated`, true);
}

/**
 * Sets the value for the clause
 * @function setClauseValue
 * @param {Object} vm the object that is being updated
 * @param {String} value the value the property is being updated to
 * @param {String} path the path to the clause
 * @returns {Object} returns the new updated object
 */
function setClauseValue(vm, value, path) {
    const clonedModel = _.cloneDeep(vm.value);
    if (path.includes('.terms')) {
        setClauseTermUpdated(clonedModel, path);
    }

    const basePath = PlatformClausesUtil.getObjectPathFromChangedPath(path);
    const nonVMChangedPath = ViewModelUtil.getNonVMPath(path);
    const nonVMBasePath = ViewModelUtil.getNonVMPath(basePath);
    // removed the use of stereotype as this is converting from string to premitive float
    // eslint-disable-next-line max-len
    // e.g. "0.30" is getting converted to 0.3 which is causing failures in setting the coverage terms
    const realValue = value;

    _.set(clonedModel, nonVMChangedPath, value);

    // set flag to changed
    _.set(clonedModel, `${nonVMBasePath}.updated`, true);

    // checks the type of the realValue and the value in the base object
    // incase the realValue is an empty string and the correct type can
    // be taken from the base object
    _.set(clonedModel, nonVMChangedPath, realValue);

    // eslint-disable-next-line no-param-reassign
    vm.value = clonedModel;

    return vm;
}

export default { setClauseValue };
