import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'gw-capability-policychange-common-react';
import RNEHInsuredDetailsPage from './pages/InsuredDetails/InsuredDetailsPage';
import RNEHViewInsuredDetailsPage from './pages/InsuredDetails/viewOnly/ViewInsuredDetailsPage';
import RNEHHomeownersPropertyPage from './pages/HO3/Property/PropertyPage';
import RNEHViewHomeownersPropertyPage from './pages/HO3/Property/viewOnly/ViewPropertyPage';
import RNEHRentersPropertyPage from './pages/HO4/Property/PropertyPage';
import RNEHViewRentersPropertyPage from './pages/HO4/Property/viewOnly/ViewPropertyPage';
import RNEHCondoPropertyPage from './pages/HO6/Property/PropertyPage';
import RNEHViewCondoPropertyPage from './pages/HO6/Property/viewOnly/ViewPropertyPage';
import RNEHSeasonalPropertyPage from './pages/HF9/Property/PropertyPage';
import RNEHRenewalChangeDiff from './pages/RenewalChangeDiff/RenewalChangeDiffPage';
import RNEHViewRenewalChangeDiff from './pages/RenewalChangeDiff/viewOnly/ViewRenewalChangeDiffPage';
import RNEHViewSeasonalPropertyPage from './pages/HF9/Property/viewOnly/ViewPropertyPage';
import RNEHRiskAnalysisPage from './pages/RiskAnalysis/RiskAnalysisPage';
import RNEHViewRiskAnalysisPage from './pages/RiskAnalysis/viewOnly/ViewRiskAnalysisPage';
import RNEHCoveragePage from './pages/Coverages/CoveragesPage';
import RNEHViewCoveragePage from './pages/Coverages/viewOnly/ViewCoveragesPage';
import RNEHThirdPartyInterestPage from './pages/ThirdPartyInterest/ThirdPartyInterestPage';
import RNEHViewThirdPartyInterestPage from './pages/ThirdPartyInterest/viewOnly/ViewThirdPartyInterestPage';
import EHRenewalPremiumBoxComponent from './components/EHRenewalPremiumBoxComponent/EHRenewalPremiumBoxComponent';

setComponentMapOverrides(
    {
        RNEHInsuredDetailsPage: { component: 'RNEHInsuredDetailsPage' },
        RNEHHomeownersPropertyPage: { component: 'RNEHHomeownersPropertyPage' },
        RNEHRentersPropertyPage: { component: 'RNEHRentersPropertyPage' },
        RNEHSeasonalPropertyPage: { component: 'RNEHSeasonalPropertyPage' },
        RNEHCondoPropertyPage: { component: 'RNEHCondoPropertyPage' },
        RNEHCoveragePage: { component: 'RNEHCoveragePage' },
        RNEHThirdPartyInterestPage: { component: 'RNEHThirdPartyInterestPage' },
        RNEHRiskAnalysisPage: { component: 'RNEHRiskAnalysisPage' },
        RNEHViewInsuredDetailsPage: { component: 'RNEHViewInsuredDetailsPage' },
        RNEHViewRiskAnalysisPage: { component: 'RNEHViewRiskAnalysisPage' },
        RNEHViewThirdPartyInterestPage: { component: 'RNEHViewThirdPartyInterestPage' },
        RNEHViewCoveragePage: { component: 'RNEHViewCoveragePage' },
        RNEHViewHomeownersPropertyPage: { component: 'RNEHViewHomeownersPropertyPage' },
        RNEHViewRentersPropertyPage: { component: 'RNEHViewRentersPropertyPage' },
        RNEHViewCondoPropertyPage: { component: 'RNEHViewCondoPropertyPage' },
        RNEHViewSeasonalPropertyPage: { component: 'RNEHViewSeasonalPropertyPage' },
        RNEHRenewalChangeDiff: { component: 'RNEHRenewalChangeDiff' },
        RNEHViewRenewalChangeDiff: { component: 'RNEHViewRenewalChangeDiff' },
        EHRenewalPremiumBoxComponent: { component: 'EHRenewalPremiumBoxComponent' }
    },
    {
        RNEHInsuredDetailsPage,
        RNEHHomeownersPropertyPage,
        RNEHCoveragePage,
        RNEHThirdPartyInterestPage,
        RNEHRentersPropertyPage,
        RNEHCondoPropertyPage,
        RNEHSeasonalPropertyPage,
        RNEHRiskAnalysisPage,
        RNEHViewInsuredDetailsPage,
        RNEHViewRiskAnalysisPage,
        RNEHViewThirdPartyInterestPage,
        RNEHViewCoveragePage,
        RNEHViewHomeownersPropertyPage,
        RNEHViewRentersPropertyPage,
        RNEHViewCondoPropertyPage,
        RNEHViewSeasonalPropertyPage,
        RNEHRenewalChangeDiff,
        RNEHViewRenewalChangeDiff,
        EHRenewalPremiumBoxComponent
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as EHRenewalWizard } from './EHRenewalWizard';
