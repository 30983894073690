import { v4 as generateGuid } from 'uuid';

const STICKY_SESSION_ID = 'X_GWRE_SESSION';

export default {
    /*
        Customer can override the sticky session id and can generate their own session id.
    */

    // This param should be string value
    stickySessionName: STICKY_SESSION_ID,


    // This should be a function, which return string value
    customerGenerateSessionID: function customerGenerateSessionID() {
        let generateID;
        if (!sessionStorage.getItem(STICKY_SESSION_ID)) {
            generateID = generateGuid();
            sessionStorage.setItem(STICKY_SESSION_ID, generateID);
        } else {
            generateID = sessionStorage.getItem(STICKY_SESSION_ID);
        }
        return generateID;
    }

}
