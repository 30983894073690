import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    uniqBy as _uniqBy
} from 'lodash';
import { useUWIssueUtil, useTPIUtil } from 'e1p-capability-hooks';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useHistory } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { EndorsementService } from 'e1p-capability-policychange';
import { PolicyChangeUtil, PaymentPageUtil } from 'e1p-portals-util-js';
import { PolicyDiffService, ChangePhraseService } from 'e1p-capability-policyjob';
import { policyChangeMessages, commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import messages from './PolicyChangeDiffPage.messages';
import metadata from './PolicyChangeDiffPage.metadata.json5';
import styles from './PolicyChangeDiffPage.module.scss';

function PolicyChangeDiff(props) {
    const modalApi = useModal();
    const {
        wizardData: policyChangeVM, updateWizardData, isSkipping,
        jumpTo, steps, updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const history = useHistory();
    const [policyDiffData, setPolicyDiffData] = useState(null);
    const [policyChangePhraseData, setPolicyChangePhraseData] = useState([]);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [signatureSuccess, setSignatureSuccess] = useState(false);
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [isOOSComoponentValid, updateIsOOSComoponentValid] = useState(true);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPerformingQuote, setIsPerformingQuote] = useState(false);
    const jobNumber = policyChangeVM?.jobID?.value;
    // It is used to show loader message 'Preparing Required Documents' when gettingSignatureDocument is true
    const [gettingSignatureDocument, setGettingSignatureDocument] = useState(false);
    const { jumptToTPIPageIfTPIOfTrustConditionFails } = useTPIUtil();
    const [isWithDrawingChangeJob, setIsWithdrawingChangeJob] = useState(false);
    const {
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('PolicyChangeDiff');

    const {
        hasUWIssuesOfType,
        showUnderwritingIssuesPopup,
        hasUWIssues
    } = useUWIssueUtil(
        policyChangeVM,
        updateWizardData,
        jumpTo,
        steps
    );

    // Check if any form requires signature
    const isSignatureRequired = _get(policyChangeVM, 'lobData.homeowners_EH.hasSignatureRequiredFormsInCurrentJob.value');

    const conflictsPresent = !_isEmpty(_get(policyChangeVM, 'value.conflicts', []));

    const [activeTab, setActiveTab] = useState(conflictsPresent ? 'changeConflictsTab' : 'changePhrases');
    
    // when confilcts are resolved, conflicts tab is getting removed,
    // then we need to set change phrases as active tab
    useEffect(() => {
        setActiveTab(conflictsPresent ? 'changeConflictsTab' : 'changePhrases');
    },[conflictsPresent]);
    
    useEffect(() => {
        registerComponentValidation(
            () => !hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease'])
                    && ((isSignatureRequired && signatureSuccess) || !isSignatureRequired)
                    && !conflictsPresent
        );
    }, [
        hasUWIssuesOfType, isSignatureRequired,
        registerComponentValidation, signatureSuccess, conflictsPresent
    ]);

    const checkuwIssues = useCallback(() => {
        if (hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease'])) {
            showUnderwritingIssuesPopup();
        }
    }, [hasUWIssuesOfType, showUnderwritingIssuesPopup]);

    const isQuoted = _get(policyChangeVM, 'status.value.code') === 'Quoted';

    useEffect(() => {
        const checkUwIssuesAndGetChangeSummary = () => {
            // Show UW issues if quote is blocked
            checkuwIssues();

            // Call the policy diff
            PolicyDiffService.getPolicyDiffWithPrevious(
                [policyChangeVM.jobID.value], authHeader
            ).then(setPolicyDiffData);

            ChangePhraseService.getChangePhrase(
                policyChangeVM.jobID.value, authHeader
            ).then((response) => {
                const mappedPhrases = response.map((phrase) => ({
                        name: phrase
                    }));

                setPolicyChangePhraseData(mappedPhrases);
            });
        };
        // IAP-1278
        // jumping to tpi page if tpi of trust condition fails
        const hasJumped = jumptToTPIPageIfTPIOfTrustConditionFails(policyChangeVM,
            jumpTo,
            steps,
            '/third-party-interest');

        if (hasJumped) {
            return true;
        }

        /**
         * E1PAP1PC-14509 :
         * will perform saveAndQuote if job is not quoted yet and
         * there are not errors, exceptions, conflicts and uwIssues
         */
        const fieldIssues = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
        const exceptions = _get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

        if (!isQuoted
            && _isEmpty(fieldIssues) && _isEmpty(exceptions)
            && !conflictsPresent
            && !hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease'])) {
            setIsPerformingQuote(true);
            EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            ).then((quoteResponse) => {
                _set(policyChangeVM, 'value', quoteResponse);
                updateWizardData(policyChangeVM);
                setIsPerformingQuote(false);
                checkUwIssuesAndGetChangeSummary();
            }).catch(() => {
                setIsSavingCurrentPageChanges(false);
            });
        } else {
            checkUwIssuesAndGetChangeSummary();
        }
        // It should call when page is render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const withdraw = useCallback(() => {
        setIsWithdrawingChangeJob(true);

        return PolicyChangeUtil.withdrawEndorsement(jobNumber, authHeader, history).then(() => {
            setIsWithdrawingChangeJob(false);
        });
    }, [authHeader, history, jobNumber]);

    /**
 * Check if signature is valid
 */
    const isPageValid = useCallback(() => {
        const isSignatureDone = _get(policyChangeVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;

        // If signature required and completed and there are no UW issues
        return (!isSignatureRequired || isSignatureDone) && !checkuwIssues();
    }, [policyChangeVM, signatureSuccess, isSignatureRequired, checkuwIssues]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, signatureSuccess]);


    const writeValue = useCallback(
        (value, path) => {
            _set(policyChangeVM, path, value);
            updateWizardData(policyChangeVM);
        }, [policyChangeVM, updateWizardData]
    );

    const setErrors = useCallback((removeUnnecessaryErrors = false) => {
        const isSignatureDone = _get(policyChangeVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;
        const isSignatureMissing = isSignatureRequired && !isSignatureDone;
        
        const isUwIssuePresent = hasUWIssues();
        
        // no payments in policyChange
        const shouldShowMissingPaymentError = false;

        const errors = PaymentPageUtil.getValidationErrorsForPaymentPage(
            validationErrors, shouldShowMissingPaymentError, isSignatureMissing, isUwIssuePresent, translator, removeUnnecessaryErrors);

        const uniqueErrors= _uniqBy(errors, 'description');

        setValidationErrors(uniqueErrors);

        return uniqueErrors;

    }, [policyChangeVM, signatureSuccess, isSignatureRequired, hasUWIssues, validationErrors, translator]);

    useEffect(() => {
        if (signatureSuccess) {
            setErrors(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureSuccess])

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WizardSingleErrorComponent
        },
        resolveCallbackMap: {
            sortString: DatatableUtil.sortString
        }
    };

    const loadingTextMessages = useMemo(() => {
        let loadingMessage = translator(messages.completingYourPurchaseMessage);

        if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (gettingSignatureDocument) {
            loadingMessage = translator(e1pCommonMessages.prepareReqDocuments);
        } else if (isPerformingQuote) {
            loadingMessage = translator(e1pCommonMessages.performingQuoteOperation);
        } else if (isWithDrawingChangeJob) {
            loadingMessage = translator(policyChangeMessages.withdrawingPolicychange);
        }

        return loadingMessage;
    }, [
        translator, isSavingCurrentPageChanges,
        gettingSignatureDocument, isPerformingQuote,
        isWithDrawingChangeJob
    ]);

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isSavingEndorsement && !isSkipping && !isWithDrawingChangeJob
            && !isSavingCurrentPageChanges && !isPerformingQuote && !gettingSignatureDocument,
        [gettingSignatureDocument, isPerformingQuote, isSavingCurrentPageChanges,
        isSavingEndorsement, isSkipping, isWithDrawingChangeJob]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        policyChangeDiffPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: loadingTextMessages
        },
        policyChangeDiffPageContainer: {
            visible: !isSavingEndorsement && !isSkipping && !isWithDrawingChangeJob
                && !isSavingCurrentPageChanges && !isPerformingQuote,
            // Facing some issues, if i try to hide the container using visibility. 
            // Initially, visibility is false so all the components will be unmount 
            // when the visibility become true then all components will be mount again 
            // it will re-trigger all the react hook components so handled hiding using CSS
            className: gettingSignatureDocument ? styles.hideContainer : ''
        },
        ehPolicyDiff: {
            quote: policyDiffData,
            visible: !_isEmpty(policyDiffData)
        },
        changePhraseDataTable: {
            data: policyChangePhraseData
        },
        policychangeSummaryTabset: {
            defaultActiveTab: 'changePhrases',
            activeTab,
            onTabChange: (tabId) => {
                setActiveTab(tabId);
            }
        },
        PremiumBox: {
            policyChangeVM,
            viewOnlyMode: true,
            authHeader,
            authUserData
        },
        changeConflictsTab: {
            visible: conflictsPresent
        },
        changeConflicts: {
            conflicts: _get(policyChangeVM, 'value.conflicts', []),
            jobNumber: _get(policyChangeVM, 'value.jobID', ''),
            authHeader,
            changeEffectiveDate: _get(policyChangeVM.value, 'baseData.effectiveDate'),
            writeValue,
            isPageSubmitted,
            updateIsPageSubmitted,
            updateIsOOSComoponentValid
        },
        signatureTab: {
            visible: isSignatureRequired
        },
        // Should be visible whenever custom validation errors are triggered
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        signatureComponent: {
            submissionVM: policyChangeVM,
            updateWizardData,
            authHeader,
            LOB: 'homeowners_EH',
            onSignatureSuccess: setSignatureSuccess,
            producerCodeOptions,
            setGettingSignatureDocument,
            setProducerCodeOptions,
            showErrors: isPageSubmitted
        },
        deliverDocumentsIndComponent: {
            visible: authUserData.permissions_Ext.includes('docdeliveryind_ext'),
            deliverDocInd: _get(policyChangeVM, 'value.shouldDeliverDocuments_Ext'),
            setDeliverDocInd: ((value) => {
                _set(policyChangeVM, 'value.shouldDeliverDocuments_Ext', value);
                updateWizardData(policyChangeVM);
            })
        }
    };

    const bindPolicyChange = useCallback(
        async () => {
            setIsSavingEndorsement(true);

            const paymentDetails = {

            };
            const bindResponse = await EndorsementService.bindChange(
                [policyChangeVM.value, paymentDetails], authHeader
            );

            if (bindResponse.policyChange?.errorsAndWarnings) {
                _set(policyChangeVM, 'value.errorsAndWarnings', bindResponse.policyChange.errorsAndWarnings);
                updateWizardData(policyChangeVM);
                setIsSavingEndorsement(false);

                // If there is UW issue which blocks bind, displays underwriting issue popup
                if (hasUWIssuesOfType(['BlocksBind'])) {
                    showUnderwritingIssuesPopup();
                }

                return false;
            }

            policyChangeVM.value = bindResponse.policyChange;
            updateWizardData(policyChangeVM);
            setIsSavingEndorsement(false);
            _set(policyChangeVM, 'changesAppliedForward', bindResponse.changesAppliedForward);

            return policyChangeVM;
        },
        [
            authHeader,
            hasUWIssuesOfType,
            policyChangeVM,
            showUnderwritingIssuesPopup,
            updateWizardData
        ]
    );

    /**
     * set errors and move to signature tab if signature is required and only signature is pending.
     */
    const setErrorsAndMovetoSignatureTabIfRequired = useCallback(() => {
        const activeErrors = setErrors();

        const isOnlySignatureErrorLeft =
            activeErrors.length === 1 &&
            activeErrors.find(
                (error) =>
                    error.description ===
                    translator(e1pCommonMessages.signatureRequiredInfoMessage)
            );
        const isSignatureDone = _get(policyChangeVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;

        if (isSignatureRequired && !isSignatureDone && isOnlySignatureErrorLeft) {
            setActiveTab('signatureTab');
        }
    },[isSignatureRequired, policyChangeVM, setErrors, signatureSuccess, translator]);

    const onNext = useCallback(
        async () => {
            let callBindApi = true;

            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                setErrorsAndMovetoSignatureTabIfRequired();
                window.scrollTo(0, 0);

                return false;
            }

            // E1PAP1PC-13936: we need to show confiramtaion box.
            // if there is bound or unbound renewal present on the policy
            const isBoundRenewalExists = _get(policyChangeVM, 'value.hasFutureBoundRenewal_Ext', false);
            const isUnboundRenewalExists = _get(policyChangeVM, 'value.hasFutureUnBoundRenewal_Ext', false);

            if (isBoundRenewalExists || isUnboundRenewalExists) {
                const results = await modalApi.showConfirm({
                    title: policyChangeMessages.applyChangesToRenewalTitle,
                    message: policyChangeMessages.applyChangesToRenewalDescription,
                    confirmButtonText: policyChangeMessages.applyChanges
                });

                if (results === 'cancel') {
                    callBindApi = false;
                }
            }

            if (callBindApi) {
                return bindPolicyChange();
            }

            return false;
        },
        [isComponentValid, policyChangeVM, setErrorsAndMovetoSignatureTabIfRequired, modalApi, bindPolicyChange]
    );

    const saveAndQuote = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                setErrorsAndMovetoSignatureTabIfRequired();
                window.scrollTo(0, 0);

                return false;
            }

            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            _set(policyChangeVM, 'value', quoteResponse);

            return updateWizardData(policyChangeVM);
        },
        [authHeader, isComponentValid, policyChangeVM, setErrorsAndMovetoSignatureTabIfRequired, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote();

                const fieldIssues = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, policyChangeVM, updateWizardSnapshot]
    );

    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, policyChangeVM, id, path, overrideProps), [overrideProps, policyChangeVM]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            onNext={onNext}
            showNext={isQuoted}
            nextLabel={translator(policyChangeMessages.applyChanges)}
            disablePrevious={gettingSignatureDocument}
            disableNext={gettingSignatureDocument}
            showCustom={!isQuoted}
            onCustom={saveAndQuote}
            showWithdraw
            onWithdraw={withdraw}
            isPageSubmittedWithErrors={isPageSubmitted && !isOOSComoponentValid}
            onSave={onSave}
            showOnSave
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                resolveValue={readValue}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}


PolicyChangeDiff.propTypes = wizardProps;
export default PolicyChangeDiff;
