// import { set as _set } from 'lodash';
import PaymentService from '../services/PaymentService';

/**
 * 
 * @param {String} transactionID job number from PC
 * @param {*} authHeader jwt
 * @returns {String | Undefined} reciept number of string if existing. Otherwise undefined.
 */
const getReceiptNumber = async (transactionID, authHeader) => {
    try {
        const { paymentReceipts } = await PaymentService
            .retrievePaymentDetailsForTransactionId(transactionID, authHeader);
        const receipt = paymentReceipts.find((receiptElt) => receiptElt.paymentStatus === 'ACCEPTED');
        return receipt?.receiptNumber;
    } catch (_) {
        return undefined;
    }
};


export default { getReceiptNumber };
