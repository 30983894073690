import { defineMessages } from 'react-intl';

export default defineMessages({
    enteredAddress: {
        id: 'addressStandardizationResults.sub title.Entered Address',
        defaultMessage: 'Entered Address'
    },
    standardizedAddress: {
        id: 'addressStandardizationResults.sub title.Standardized address(es)',
        defaultMessage: 'Standardized address(es)'
    },
    noExactMatchFoundMessage: {
        id: 'addressStandardizationResults.No exact address found.',
        defaultMessage: 'No exact address found.'
    },
    useThisAddress: {
        id: 'addressStandardizationResults.Use this Address',
        defaultMessage: 'Use this Address'
    }
});
