import React, {
    useCallback, useState, useEffect
} from 'react';
import {
    set as _set,
    cloneDeep as _cloneDeep,
    get as _get,
    stubFalse as _stubFalse
} from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { AutoLossService } from 'e1p-capability-gateway';
import { TypekeyUtil } from 'e1p-portals-util-js';
import { useMVRLicenseStatusUtil } from 'e1p-capability-hooks';
import PropTypes from 'prop-types';
import {
    useModal,
} from '@jutro/components';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from './LossTableComponent.metadata.json5';
import messages from './LossTableComponent.messages';

const mvrReorderApplicableTransactions = ['PolicyChange', 'Rewrite'];

/**
 *
 * @param {Object} props this is the wizard props
 * @returns {*} the JSX code to render to the DOM tree.
 * @purpose This component is for EA auto losses and
 *   auto violations.
 */
function LossTableComponent(props) {
    const modalApi = useModal();
    const {
        lossesAndViolations,
        quoteId,
        authUserData,
        authHeader,
        viewOnlyMode,
        updateBaseDataComponent,
        accountHolderName,
        drivers,
        policyState,
        jobType,
        lineOfBusiness
    } = props;
    const translator = useTranslator();
    const [dataForComponent, updateDataForComponent] = useState(lossesAndViolations);
    const [loadingOverrides, setLoadingOverrides] = useState(false);
    const [reorderingMVRReports, setReorderingMVRReports] = useState(false);
    const [noHitMessage, setNoHitMessage] = useState('');
    const [showNoHitMessage, setShowNoHitMessage] = useState(false);
    const {
        checkNoHitStatus
    } = useMVRLicenseStatusUtil();

    const canViewDisputeCheckbox = authUserData?.permissions_Ext.includes('disuptelosses_ext');

    const modifyForOverrideCallLoss = (lossArray) => {
        const lossObject = [];

        lossArray.forEach((record) => {
            const recordObj = {
                incurDate: record.incurDate,
                lossType: record.lossType,
                recordId: record.recordId,
                isRemoved: record.isRemoved
            };

            lossObject.push(recordObj);
        });

        return lossObject;
    };

    const modifyForOverrideCallViolation = (violationArray) => {
        const violationObject = [];

        violationArray.forEach((record) => {
            const recordObj = {
                violationType: record.violationType,
                recordId: record.recordId,
                isRemoved: record.isRemoved
            };

            violationObject.push(recordObj);
        });

        return violationObject;
    };


    const saveOverrides = useCallback((value, path) => {
        const dataForComponentClone = _cloneDeep(dataForComponent);

        _set(dataForComponentClone, path, value);

        setLoadingOverrides(true);

        const losses = AutoLossService.saveAutoOverrides(
            quoteId,
            modifyForOverrideCallLoss(_get(dataForComponentClone, `lobData.${lineOfBusiness}.autoLossRecords.value`)),
            modifyForOverrideCallViolation(_get(dataForComponentClone, `lobData.${lineOfBusiness}.autoViolationRecords.value`)),
            authHeader
        );

        losses.then((response) => {
            const autoLossRecords = [];

            response.autoLossRecords.filter((record) => record.source.sourceType !== 'Self-Declared').forEach((lossVM) => {
                autoLossRecords.push(lossVM);
            });

            const autoViolationRecords = [];

            response.autoViolationRecords.filter((record) => record.source.sourceType !== 'Self-Declared').forEach((violationVM) => {
                _set(
                    violationVM,
                    'operatorDisplayName',
                    `${violationVM.assignment.firstName} ${violationVM.assignment.lastName}`
                );
                autoViolationRecords.push(violationVM);
            });

            _set(dataForComponentClone, `lobData.${lineOfBusiness}.autoLossRecords.value`, autoLossRecords);
            _set(dataForComponentClone, `lobData.${lineOfBusiness}.autoViolationRecords.value`, autoViolationRecords);
        }).catch(() => {
            modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: messages.saveOverridesErrorTitle,
                message: messages.saveOverridesErrorMessage
            });
        }).finally(() => {
            updateDataForComponent(dataForComponentClone);
            updateBaseDataComponent(dataForComponentClone);
            setLoadingOverrides(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountHolderName, authHeader, dataForComponent, quoteId, updateBaseDataComponent, modalApi]);

    /**
     * <Mapping Coverages based on the recordIndex, and subsequently mapping to perilIndex (double index not possible in metadata)>
     * @param {Number} recordIndex <This is the actual record of the loss>
     * @param {Array} perils <This is an array indicating the the different perils>
     */
    const getClueCoverageContent = useCallback(
        (recordIndex, perils) =>
            perils?.map((peril, perilIndex) => ({
                id: `clueLossTableData${recordIndex}_${perilIndex}`,
                type: 'container',
                component: 'div',
                content: [
                    {
                        id: `clueCoverage${recordIndex}_${perilIndex}`,
                        component: 'input',
                        type: 'field',
                        componentProps: {
                            value: peril.peril,
                            readOnly: true,
                        },
                    },
                ],
            })),
        []
    );

    /**
    * <Mapping Loss Amount based on the recordIndex, and subsequently mapping to perilIndex (double index not possible in metadata)>
    * @param {Number} recordIndex <This is the actual record of the loss>
    * @param {Array} perils <This is an array indicating the the different perils>
    */
    const getClueLossAmountContent = useCallback(
        (recordIndex, perils) =>
            perils?.map((peril, perilIndex) => ({
                id: `clueLossAmountTableData${recordIndex}_${perilIndex}`,
                type: 'container',
                component: 'div',
                content: [
                    {
                        id: `clueLossAmount${recordIndex}_${perilIndex}`,
                        component: 'input',
                        type: 'field',
                        componentProps: {
                            value: peril.perilAmount,
                            readOnly: true,
                        },
                    },
                ],
            })),
        []
    );

    // IAP-1326 : Auto - Display MVR Force Order capability for Internal Users
    const reorderMVR = useCallback(async (publicID, operatorName) => {
        const popupResult = await modalApi.showConfirm({
            title: messages.reorderMVRDialogTitle,
            message: translator(messages.reorderMVRDialogMessage, { operatorName }),
            confirmButtonText: messages.reorderMVR,
            cancelButtonText: e1pCommonMessages.cancel
        });

        if (popupResult === 'cancel') {
            // User clicks cancel, not ordering MVR
            return _stubFalse();
        }

        const dataForComponentClone = _cloneDeep(dataForComponent);

        setReorderingMVRReports(true);

        AutoLossService.forceOrderAutoIncidents(
            quoteId,
            'MVR',
            publicID,
            authHeader
        ).then((response) => {
            // Response returns all records for all operators, mapping them back to component

            const updatedAutoViolationRecords = _get(response, 'autoViolationRecords', [])
                .map((violationRecord) => {
                    _set(violationRecord,
                        'operatorDisplayName',
                        `${violationRecord.assignment.firstName} ${violationRecord.assignment.lastName}`)

                    return { ...violationRecord }
                });

            const updatedAutoLossRecords = _get(response, 'autoLossRecords', [])
                .map((lossRecord) => ({ ...lossRecord }));

            _set(dataForComponentClone, `lobData.${lineOfBusiness}.autoViolationRecords.value`, updatedAutoViolationRecords);
            _set(dataForComponentClone, `lobData.${lineOfBusiness}.autoLossRecords.value`, updatedAutoLossRecords);
            _set(dataForComponentClone, `lobData.${lineOfBusiness}.mvrLicenseStatusRecords.value`, _get(response, 'mvrlicenseStatus', []));
            updateDataForComponent(dataForComponentClone);
            updateBaseDataComponent(dataForComponentClone);
        }).catch(() => {
            modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: messages.reOrderMVRErrorTitle,
                message: messages.reOrderMVRErrorMessage
            });
        }).finally(() => {
            setReorderingMVRReports(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authHeader, dataForComponent, quoteId, translator, updateBaseDataComponent, modalApi]);

    const getColumnsForMVRLicenseTable = useCallback(() => {
        let columns = ["1fr", "1fr", "1fr", "1fr"];

        if (
            !viewOnlyMode
            && authUserData.permissions_Ext.includes("forcereordermvr_ext")
            && mvrReorderApplicableTransactions.includes(jobType)
        ) {
            columns = ["1fr", "1fr", "1fr", "1fr", "1fr"];
        }

        return columns;
    }, [authUserData.permissions_Ext, jobType, viewOnlyMode]);


    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        // if (_get(lossesAndViolations, `lobData?.${lineOfBusiness}?.autoLossRecords?.value`, []).length === 0) {
        //     return overrideProps;
        // }
        if (_get(lossesAndViolations, `lobData.${lineOfBusiness}.autoLossRecords.value`)) { // just change to personalUmbrella_EU
            const dtoName = 'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoLossRecordDTO';
            const availableLossTypes = TypekeyUtil.getAvailableTypekeyValues(dtoName, 'lossType');

            _get(dataForComponent, `lobData.${lineOfBusiness}.autoLossRecords.value`).forEach((autoLossRecord, recordIndex) => {
                const firstName = _get(autoLossRecord, 'assignment.firstName', '');
                const lastName = _get(autoLossRecord, 'assignment.lastName', '');
                const lossRecordAssignmentName = `${firstName} ${lastName}`;
                const policyHolderName = _get(autoLossRecord, 'assignment.roleInClaim') === 'POLICYHOLDER' ? lossRecordAssignmentName : '-';
                const vehicleOperatorName = _get(autoLossRecord, 'assignment.roleInClaim') === 'VEHICLE OPERATOR' ? lossRecordAssignmentName : '-';
                const orderingInfoList = _get(dataForComponent, `lobData.${lineOfBusiness}.orderingInfo.value`, []);
                // find report ordering info by sourceIdentifier
                const orderingInfo = orderingInfoList.find(
                    info =>
                        info.additionalInfo.reportId ===
                        autoLossRecord.source.sourceIdentifier
                );
                const reportOrderDate = _get(orderingInfo, 'additionalInfo.reportOrderDate');

                overrideProps[`cluePolicyHolder${recordIndex}`] = {
                    value: policyHolderName,
                    readOnly: true
                };
                overrideProps[`clueOperator${recordIndex}`] = {
                    value: vehicleOperatorName,
                    readOnly: true
                };
                overrideProps[`clueIncurDate${recordIndex}`] = {
                    dateDTO: autoLossRecord.incurDate,
                    readOnly: true,
                    updateDateDto: () => { }
                };
                overrideProps[`clueDateOrderedID${recordIndex}`] = {
                    dateDTO: reportOrderDate,
                    readOnly: true
                };
                overrideProps[`clueLossType${recordIndex}`] = {
                    value: autoLossRecord.lossType ? TypekeyUtil.getTypeKeyNameGivenAvailableValues(autoLossRecord.lossType, availableLossTypes) : '-',
                    readOnly: true
                };
                overrideProps[`clueCoverageContainer${recordIndex}`] = {
                    value: autoLossRecord.perils,
                    readOnly: true,
                    content: getClueCoverageContent(recordIndex, autoLossRecord.perils)
                };
                overrideProps[`clueLossAmountContainer${recordIndex}`] = {
                    value: autoLossRecord.perils,
                    readOnly: true,
                    content: getClueLossAmountContent(recordIndex, autoLossRecord.perils)
                };
                overrideProps[`clueDisputeRecordId${recordIndex}`] = {
                    onValueChange: saveOverrides,
                    value: autoLossRecord.isRemoved,
                    path: `lobData.${lineOfBusiness}.autoLossRecords.value[${recordIndex}].isRemoved`,
                    visible: canViewDisputeCheckbox,
                    readOnly: viewOnlyMode
                };
                overrideProps[`clueConsideredRecordId${recordIndex}`] = {
                    value: autoLossRecord.rateStatus === 'Active'
                };
            });
        }

        if (_get(lossesAndViolations, `lobData.${lineOfBusiness}.autoViolationRecords.value`)) {
            const dtoName = 'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoViolationRecordDTO';
            const availableViolationTypes = TypekeyUtil.getAvailableTypekeyValues(dtoName, 'violationType');

            _get(dataForComponent, `lobData.${lineOfBusiness}.autoViolationRecords.value`).forEach((autoViolationRecord, recordIndex) => {
                const orderingInfoList = _get(dataForComponent, `lobData.${lineOfBusiness}.orderingInfo.value`, []);
                // find report ordering info by sourceIdentifier
                const orderingInfo = orderingInfoList.find(
                    (info) =>
                        info.additionalInfo.reportId ===
                        autoViolationRecord.source.sourceIdentifier
                );
                const reportOrderDate = _get(orderingInfo, 'additionalInfo.reportOrderDate');

                overrideProps[`mvrOperator${recordIndex}`] = {
                    value: autoViolationRecord.operatorDisplayName,
                    readOnly: true
                };
                overrideProps[`mvrViolationType${recordIndex}`] = {
                    value: TypekeyUtil.getTypeKeyNameGivenAvailableValues(autoViolationRecord.violationType, availableViolationTypes),
                    readOnly: true
                };
                overrideProps[`mvrIncidentDate${recordIndex}`] = {
                    dateDTO: autoViolationRecord.incidentDate,
                    readOnly: true,
                    updateDateDto: () => { }
                };
                overrideProps[`mvrConvictionDate${recordIndex}`] = {
                    dateDTO: autoViolationRecord.convictionDate,
                    readOnly: true,
                    updateDateDto: () => { }
                };
                overrideProps[`mvrSource${recordIndex}`] = {
                    value: autoViolationRecord.source.sourceType,
                    disabled: true
                };
                overrideProps[`mvrDateOrderedID${recordIndex}`] = {
                    dateDTO: reportOrderDate,
                    readOnly: true
                };
                overrideProps[`mvrDisputeRecordId${recordIndex}`] = {
                    onValueChange: saveOverrides,
                    value: autoViolationRecord.isRemoved,
                    path: `lobData.${lineOfBusiness}.autoViolationRecords.value[${recordIndex}].isRemoved`,
                    visible: canViewDisputeCheckbox,
                    readOnly: viewOnlyMode
                };

                overrideProps[`mvrPrayerForJudgmentRecordId${recordIndex}`] = {
                    path: `lobData.${lineOfBusiness}.autoViolationRecords.value[${recordIndex}].prayerForJudgementInd`,
                    visible: policyState === 'NC'
                };
                overrideProps[`mvrConsideredRecordId${recordIndex}`] = {
                    value: autoViolationRecord.rateStatus === 'Active'
                };
            });
        }

        if (_get(lossesAndViolations, `lobData.${lineOfBusiness}.mvrLicenseStatusRecords.value`)) {
            _get(dataForComponent, `lobData.${lineOfBusiness}.mvrLicenseStatusRecords.value`).forEach((mvrLicenseStatusRecord, recordIndex) => {
                const driverNode = drivers?.value.find(
                    (driver) =>
                        driver.integrationId ===
                        mvrLicenseStatusRecord.integrationId
                );
                const publicID = _get(driverNode, 'publicID');
                const name = _get(driverNode, 'person.displayName', '-');
                const isMVRNoHit = mvrLicenseStatusRecord.mvrstatus === 'No-Hit';
                // if value of mvrStatus is coming as "No Hit", then show "Not Available" in license Status
                // and result column.
                const licenseStatus = isMVRNoHit ? 'Not Available'
                    : _get(mvrLicenseStatusRecord, 'licenseStatus', '-');
                const mvrResult = isMVRNoHit ? 'Not Available'
                    : _get(mvrLicenseStatusRecord, 'results', '-');

                overrideProps[`mvrAndLicenseOperator${recordIndex}`] = {
                    value: name,
                    readOnly: true
                };
                overrideProps[`mvrStatusData${recordIndex}`] = {
                    value: mvrLicenseStatusRecord.mvrstatus,
                    readOnly: true
                };
                overrideProps[`licenseStatus${recordIndex}`] = {
                    value: licenseStatus,
                    readOnly: true
                };
                overrideProps[`mvrAndLicenseResultsColumn${recordIndex}`] = {
                    value: mvrResult,
                    readOnly: true
                };
                overrideProps.mvrAndLicenseDataTableLabelContainer = {
                    columns: getColumnsForMVRLicenseTable(),
                    path: `lobData.${lineOfBusiness}.mvrLicenseStatusRecords`,
                };
                overrideProps[`mvrAndLicenseTableRow${recordIndex}`] = {
                    columns: getColumnsForMVRLicenseTable()
                };
                overrideProps[`mvrAndLicenseReorderMVR${recordIndex}`] = {
                    onClick: () => { reorderMVR(publicID, name) },
                    visible: !viewOnlyMode
                        && authUserData.permissions_Ext.includes("forcereordermvr_ext")
                        && mvrReorderApplicableTransactions.includes(jobType)
                };
            });
        }

        return overrideProps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lossesAndViolations?.lobData?.personalAuto_EA?.autoLossRecords, lossesAndViolations?.lobData?.personalUmbrella_EU?.autoLossRecords]);

    useEffect(() => {
        const mvrLicenseStatusRecords = _get(dataForComponent, `lobData?.${lineOfBusiness}?.mvrLicenseStatusRecords?.value`);

        if (mvrLicenseStatusRecords) {
            checkNoHitStatus(mvrLicenseStatusRecords, drivers.value, setShowNoHitMessage, setNoHitMessage);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForComponent.lobData, drivers, checkNoHitStatus]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            // showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        mvrPrayerForJudgmentLabelID: {
            visible: policyState === 'NC'
        },
        lossComponentMainDiv: {
            visible: !loadingOverrides && !reorderingMVRReports
        },
        lossComponentLoader: {
            loaded: !loadingOverrides && !reorderingMVRReports,
            text: loadingOverrides ?
                translator(messages.savingOverrides)
                : translator(messages.orderingMVR)
        },
        clueDisputeLabelID: {
            visible: canViewDisputeCheckbox
        },
        mvrDisputeLabelID: {
            visible: canViewDisputeCheckbox
        },
        mvrNoHitInfoMessage: {
            message: translator(noHitMessage)
        },
        mvrNoHitInfoMessageDiv: {
            visible: lineOfBusiness !== 'personalUmbrella_EU' && !viewOnlyMode && showNoHitMessage
        },
        mvrAndLicenseDataTableContainer: {
            path: `lobData.${lineOfBusiness}.mvrLicenseStatusRecords.value`
        },
        mvrDataTableIterable: {
            path: `lobData.${lineOfBusiness}.autoViolationRecords.value`
        },
        mvrDataTable: {
            path: `lobData.${lineOfBusiness}.autoViolationRecords`,
        },
        clueDataTableIterable: {
            path: `lobData.${lineOfBusiness}.autoLossRecords.value`,
        },
        clueDataTable: {
            path: `lobData.${lineOfBusiness}.autoLossRecords`,
        },
        mvrAndLicenseDataTableDiv: {
            visible: lineOfBusiness !== 'personalUmbrella_EU'
        },
        mvrAndLicenseResults: {
            visible: lineOfBusiness !== 'personalUmbrella_EU'
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                dataForComponent,
                id,
                path,
                overrideProps
            ),
        [overrideProps, dataForComponent]
    );

    const updateData = useCallback(
        (newData) => {
            updateDataForComponent(newData);
        }, [updateDataForComponent]
    );


    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={dataForComponent}
            overrideProps={overrideProps}
            onModelChange={updateData}
            resolveValue={readValue}
        />
    );
}

LossTableComponent.propTypes = {
    lossesAndViolations: PropTypes.shape({
        lobData: PropTypes.shape({
        })
    }),
    viewOnlyMode: PropTypes.bool,
    quoteId: PropTypes.string,
    authHeader: PropTypes.shape({}),
    authUserData: PropTypes.shape({}),
    updateBaseDataComponent: PropTypes.func,
    accountHolderName: PropTypes.string,
    drivers: PropTypes.shape([]),
    policyState: PropTypes.string,
    jobType: PropTypes.string,
    lineOfBusiness: PropTypes.string
};
LossTableComponent.defaultProps = {
    lossesAndViolations: {},
    quoteId: '',
    authHeader: {},
    viewOnlyMode: false,
    updateBaseDataComponent: undefined,
    accountHolderName: '',
    drivers: [],
    jobType: undefined,
    lineOfBusiness: 'personalAuto_EA',
    authUserData: undefined
};
export default withAuthenticationContext(LossTableComponent);
