import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingReportsMessage: {
        id: 'quoteandbind.eu.views.eu-reports.Loading Reports',
        defaultMessage: 'Loading reports...'
    },
    verifiedPremium: {
        id: 'quoteandbind.eu.views.eu-reports.Verified Premium',
        defaultMessage: 'Verified Premium'
    },
    premiumIncrease: {
        id: 'quoteandbind.eu.views.eu-reports.Premium Increase Due To Reports',
        defaultMessage: 'Premium Increase'
    },
    mvrResultsHeading: {
        id: 'quoteandbind.eu.views.eu-reports.MVR Results',
        defaultMessage: 'MVR Results'
    },
    viewOperatorHeader: {
        id: 'quoteandbind.eu.views.eu-reports.Operator',
        defaultMessage: 'Operator'
    },
    incidentDate: {
        id: 'quoteandbind.eu.views.eu-reports.Incident Date',
        defaultMessage: 'Incident Date'
    },
    incidentStatus: {
        id: 'quoteandbind.eu.views.eu-reports.Incident Status',
        defaultMessage: 'Incident Status'
    },
    incidentSeverity: {
        id: 'quoteandbind.eu.views.eu-reports.Incident Severity',
        defaultMessage: 'Incident Severity'
    },
    violationType: {
        id: 'quoteandbind.eu.views.eu-reports.Violation Type',
        defaultMessage: 'Violation Type'
    },
    incurDate: {
        id: 'quoteandbind.eu.views.eu-reports.Incur Date',
        defaultMessage: 'Incur Date'
    },
    atFault: {
        id: 'quoteandbind.eu.views.eu-reports.At Fault',
        defaultMessage: 'At Fault'
    },
    claimNumber: {
        id: 'quoteandbind.eu.views.eu-reports.Claim Number',
        defaultMessage: 'Claim Number'
    },
    claimStatus: {
        id: 'quoteandbind.eu.views.eu-reports.Claim Status',
        defaultMessage: 'Claim Status'
    },
    lossType: {
        id: 'quoteandbind.eu.views.eu-reports.Loss Type',
        defaultMessage: 'Loss Type'
    },
    lossAmount: {
        id: 'quoteandbind.eu.views.eu-reports.Loss Amount',
        defaultMessage: 'Loss Amount'
    },
    reportsErrorTitle: {
        id: 'quoteandbind.eu.views.eu-reports.Sorry!',
        defaultMessage: 'Sorry!'
    },
    reportsErrorMessage: {
        id: 'quoteandbind.eu.views.eu-reports.We were unable to retrieve the reports.',
        defaultMessage: 'We were unable to retrieve the reports.'
    }
});
