import { defineMessages } from 'react-intl';

export default defineMessages({
    searchByAccount: {
        id: 'e1p.gateway.directives.gateway-dashboard.search.Search by account or policy ID number',
        defaultMessage: 'Search by account or policy ID number'
    },
    noSearchResultsFound: {
        id: 'e1p.gateway.directives.gateway-dashboard.search.No results found',
        defaultMessage: 'No results found'
    },
    recentlyVisitedAccounts: {
        id: 'e1p.gateway.views.dashboard.Recently Visited Accounts',
        defaultMessage: 'Recently Visited Accounts'
    },
    recentlyVisitedPolicies: {
        id: 'e1p.gateway.views.dashboard.Recently Visited Policies',
        defaultMessage: 'Recently Visited Policies'
    },
    noAccountsFound: {
        id: 'e1p.gateway.views.dashboard.No Accounts Found',
        defaultMessage: 'No Accounts Found'
    },
    noPoliciesFound: {
        id: 'e1p.gateway.views.dashboard.No Policies Found',
        defaultMessage: 'No Policies Found'
    },
    searchResults: {
        id: 'e1p.gateway.directives.gateway-dashboard.search.Search Results',
        defaultMessage: 'Search Results for "{query}"'
    }
});
