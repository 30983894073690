import React, { useCallback, useState, useEffect } from 'react';
import { isEmpty, get } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { PolicyDiffService, ChangePhraseService } from 'e1p-capability-policyjob';
import styles from '../RenewalChangeDiffPage.module.scss';
import metadata from '../RenewalChangeDiffPage.metadata.json5';
import messages from '../RenewalChangeDiffPage.messages';

function RenewalChangeDiff(props) {
    const {
        wizardData: renewalVM, updateWizardData
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [policyDiffData, setPolicyDiffData] = useState(null);
    const [renewalPhraseData, setRenewalPhraseData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        PolicyDiffService.getPolicyDiffWithPrevious(
            [renewalVM.jobID.value], authHeader
        )
            .then(setPolicyDiffData)
            .finally(() => {
                setIsLoading(false);
            });

        ChangePhraseService.getChangePhrase(
            renewalVM.jobID.value, authHeader
        ).then((response) => {
            const mappedPhrases = response.map((phrase) => ({
                    name: phrase
                }));

            setRenewalPhraseData(mappedPhrases);
        }).finally(() => {
            setIsLoading(false);
        });
        // It should call when page is render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isSignatureRequired = get(renewalVM, 'lobData.homeowners_EH.hasSignatureRequiredFormsInCurrentJob.value');

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WizardSingleErrorComponent
        },
        resolveCallbackMap: {
            sortString: DatatableUtil.sortString
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        premiumMightChangeMessageDiv: {
            visible: renewalVM.baseData.periodStatus.value.code !== 'Bound'
        },
        renewalChangeDiffPageLoadingIndicator: {
            loaded: !isLoading,
            text: translator(messages.loadingPolicyDiff)
        },
        renewalChangeDiffPageContainer: {
            visible: !isLoading
        },
        ehPolicyDiff: {
            quote: policyDiffData,
            visible: !isEmpty(policyDiffData)
        },
        changePhraseDataTable: {
            data: renewalPhraseData
        },
        changeConflictsTab: {
            visible: false
        },
        e1pOOSConflictsInlineErrorMessageComponent: {
            visible: false
        },
        PremiumBox: {
            renewalVM,
            viewOnlyMode: true,
            authHeader,
            showMonthlyPaymentComponent: false
        },
        changeConflicts: {
            conflicts: [],
            jobNumber: '',
            authHeader: '',
            changeEffectiveDate: new Date(),
            writeValue: () => { }
        },
        signatureTab: {
            visible: isSignatureRequired
        },
        // Hiding errors in view flow
        WizardSingleErrorComponent: {
            visible: false
        },
        signatureComponent: {
            submissionVM: renewalVM,
            updateWizardData,
            authHeader,
            LOB: 'homeowners_EH',
            producerCodeOptions,
            setProducerCodeOptions,
            viewOnlyMode: true,
            onSignatureSuccess: () => { }
        }
    };


    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, renewalVM, id, path, overrideProps), [overrideProps, renewalVM]);

    return (
        <WizardPage
            shouldLink
            showNext={false}
            finish
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                resolveValue={readValue}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RenewalChangeDiff.propTypes = wizardProps;
export default RenewalChangeDiff;
