import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'gw-capability-policychange-common-react';
import StartCancellationPage from './pages/StartCancellation/StartCancellationPage';
import BindCancellationPage from './pages/BindCancellation/BindCancellationPage';


setComponentMapOverrides(
    {
        StartCancellationPage: { component: 'StartCancellationPage' },
        BindCancellationPage: { component: 'BindCancellationPage' },
    },
    {
        StartCancellationPage,
        BindCancellationPage

    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PolicyCancelWizard } from './PolicyCancelWizard';
