import { defineMessages } from 'react-intl';

export default defineMessages({
    allOpen: {
        id: 'e1p.gateway.directives.ActivitySummary.All Open',
        defaultMessage: 'All Open'
    },
    allCompleted: {
        id: 'e1p.gateway.directives.ActivitySummary.All Completed',
        defaultMessage: 'All Completed'
    },
    allClosed: {
        id: 'e1p.gateway.directives.ActivitySummary.All Closed',
        defaultMessage: 'All Closed'
    },
    overdue: {
        id: 'e1p.gateway.directives.ActivitySummary.Overdue',
        defaultMessage: 'Overdue Only'
    },
    openAssignedToMe: {
        id: 'e1p.gateway.directives.ActivitySummary.Open, Assigned to me',
        defaultMessage: 'Open, Assigned to me'
    },
    activityType: {
        id: 'e1p.gateway.directives.activity-summary.Type',
        defaultMessage: 'Type'
    },
    activtyDueDate: {
        id: 'e1p.gateway.directives.activity-summary.Due Date',
        defaultMessage: 'Due Date'
    },
    activitySubject: {
        id: 'e1p.gateway.directives.activity-summary.Subject',
        defaultMessage: 'Subject'
    },
    activityAssignedTo: {
        id: 'e1p.gateway.directives.activity-summary.Assign To',
        defaultMessage: 'Assign To'
    },
    activityPriority: {
        id: 'e1p.gateway.directives.activity-summary.Priority',
        defaultMessage: 'Priority'
    },
    activityDescription: {
        id: 'e1p.gateway.directives.activity-summary.Description',
        defaultMessage: 'Description'
    },
    activityTypePlaceHolder: {
        id: 'e1p.gateway.directives.activity-summary.Please select activity type',
        defaultMessage: 'Please select activity type'
    },
    activityPriorityPlaceHolder: {
        id: 'e1p.gateway.directives.activity-summary.Priority.select',
        defaultMessage: '--Choose Priority--'
    },
    noTypeSelected: {
        id: 'e1p.gateway.directives.add-activity.Close',
        defaultMessage: 'Close'
    },
    noActivityType: {
        id: 'e1p.gateway.directives.add-activity.You must choose an activity type',
        defaultMessage: 'No Activity Type Selected'
    },
    noActivityTypeMessage: {
        id: 'e1p.gateway.directives.add-activity.You must first select an activity type',
        defaultMessage: 'You must first select an activity type.'
    },
    activityCreated: {
        id: 'e1p.gateway.directives.add-activity.Activity created',
        defaultMessage: 'Activity created'
    },
    searchActivities: {
        id: 'e1p.gateway.directives.activity-summary.Search activities',
        defaultMessage: 'Search activities'
    },
    cancel: {
        id: 'e1p.gateway.directives.activity-summary.Cancel',
        defaultMessage: 'Cancel'
    },
    addActivity: {
        id: 'e1p.gateway.directives.activity-summary.Add Activity',
        defaultMessage: 'Add Activity'
    },
    followupActivityCreated: {
        id: 'e1p.gateway.directives.add-activity.Follow-up Activity created',
        defaultMessage: 'Follow-up Activity created'
    }
});
