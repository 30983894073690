import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import appConfig from 'app-config';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withModalContext } from '@jutro/components';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import { RewriteService } from 'gw-capability-gateway';
import { e1pDateUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TransactionViewFlowUtil } from 'e1p-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import UnderwritingComponent from '../../Components/UnderwritingComponent/UnderwritingComponent';
import gatewayMessages from '../../gateway.messages';
import metadata from './Summary.metadata.json5';
import messages from './Summary.messages';
import styles from './Summary.module.scss';

const productMap = {
    PersonalAuto_EA: 'personalAuto_EA',
    Homeowners_EH: 'homeowners_EH',
    PersonalUmbrella_EU: 'personalUmbrella_EU'
};

class RewriteSummaryWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }),
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                loadRewriteSummary: PropTypes.shape({}).isRequired,
                updateJobSummary: PropTypes.func.isRequired
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        authUserData: PropTypes.shape({
            userType: PropTypes.string,
            permissions_Ext: PropTypes.array
        })
    };

    static defaultProps = {
        authHeader: undefined,
        authUserData: undefined
    }

    state = {
        showContinueRewriteButton: false,
        canWithdraw: false,
        isLoading: true
    };

    componentDidMount() {
        this.canWithdraw();
        this.getShowContinueRewrite();
    };

    getShowContinueRewrite = async () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: rewrite }
            },
            authUserData
        } = this.props;
        let showContinueRewriteButton = authUserData?.permissions_Ext.includes('createrewrite');

        // IAP_5173, Agent can only do rewrite for Homeowners policy
        // and should be able to continue rewrite on Homeowners policy
        if (authUserData?.roles_Ext.includes('ext_sales_service')) {
            // IAP-5604, temporary hide rewrite option for agent,
            // put back the below condition in if block, once backend issues is resolved
            // && rewrite.productCode !== 'Homeowners_EH'
            showContinueRewriteButton = false;
        }

        this.setState({ showContinueRewriteButton, isLoading: false });
    };

    canWithdraw = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: rewrite }
            },
            authUserData
        } = this.props;

        // withdraw permission is policy specific hence edit transaction permission is aplied
        let withdrawPermission = ['withdraw', 'editrewrite'].every(
            (permission) => authUserData.permissions_Ext.includes(permission)
        );

        // IAP_5173, Agent can only do rewrite for Homeowners policy
        // and should be able to withdraw Homeowners policy
        if (authUserData?.roles_Ext.includes('ext_sales_service')) {
            // IAP-5604, temporary hide rewrite option for agent,
            // put back the below condition in if block, once backend issues is resolved
            // && rewrite.productCode !== 'Homeowners_EH'
            withdrawPermission = false;
        }

        if (!_.isEmpty(rewrite)) {
            const translator = this.context;

            if (
                withdrawPermission
                && rewrite.status
                !== translator({
                    id: 'typekey.PolicyPeriodStatus.Bound',
                    defaultMessage: 'Bound'
                })
                && !rewrite.closeDate
            ) {
                this.setState({ canWithdraw: true });
            }
        }
    };

    updateWithDrawRewrite = (jobStatusCode, rewrite) => {
        const translator = this.context;
        const status = translator({
            id: `typekey.PolicyPeriodStatus.${jobStatusCode}`,
            defaultMessage: jobStatusCode
        });

        _.set(rewrite, 'status', status);
        _.set(rewrite, 'statusCode', jobStatusCode);
        this.updateJobSummary(rewrite);
    };

    onContinueTransaction = () => {
        const { lobRewriteURL, endorsementProducts } = appConfig;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: rewrite }
            },
            history
        } = this.props;

        const productCode = _.get(rewrite, 'productCode');
        const policyType = rewrite.policy.lobs[productMap[productCode]].policyType_Ext;
        const rewriteURL = lobRewriteURL[productCode];

        if (endorsementProducts.includes(productCode)) {
            history.push(rewriteURL, {
                jobNumber: rewrite.jobNumber,
                policyNumber: rewrite.policy.policyNumber,
                policyType
            });
        } else {
            JobUtil.openJobInXCenter(rewrite.jobNumber);
        }
    };

    onViewTransaction = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: rewrite }
            },
            history
        } = this.props;
        const productCode = _.get(rewrite, 'productCode');
        const policyType = rewrite.policy.lobs[productMap[productCode]].policyType_Ext;

        return TransactionViewFlowUtil.goToTransactionSpecificViewOnlyFlow(
            history, rewrite?.jobNumber, policyType,
            rewrite?.type, rewrite?.productCode,
            rewrite?.policy?.policyNumber
        );
    };

    onWithdrawTransaction = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: rewrite }
            },
            authHeader
        } = this.props;

        this.props.modalContext.showConfirm({
            title: messages.withdrawRewrite,
            message: messages.sureWithdrawRewrite,
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: translator(e1pCommonMessages.withdrawTransaction, { transaction: 'REWRITE' })
        }).then((result) => {
            if (result === 'cancel') {
                return;
            }

            RewriteService.withdrawRewriteByRewriteNumber(
                rewrite.jobNumber,
                authHeader
            ).then(
                () => {
                    this.updateWithDrawRewrite('Withdrawn', rewrite);
                },
                () => {
                    this.props.modalContext.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedToWithdrawRewrite,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.close
                    }).catch(_.noop);
                }
            );
        }, _.noop);
    };

    getTransactionActionContent = () => {
        const translator = this.context;

        return {
            withDrawContent: translator(messages.withdrawRewrite),
            continueContent: translator(messages.continueRewrite),
            viewContent: translator(messages.viewRewrite)
        };
    };

    getRewriteToProceed = () => {
        const { canWithdraw, showContinueRewriteButton } = this.state;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: rewrite }
            }
        } = this.props;

        const translator = this.context;

        if (
            rewrite
            && (rewrite.statusCode === 'Withdrawn' || rewrite.statusCode === 'Bound')
        ) {
            return {
                isContinueTransaction: false,
                isWithdrawTransaction: false,
                isViewTransaction: rewrite.canUserView
            };
        }

        const additionalConditionToShoContinueButton = !rewrite.closeDate
            && rewrite.status !== translator({
                id: 'typekey.PolicyPeriodStatus.Bound',
                defaultMessage: 'Bound'
            });

        return {
            isContinueTransaction:
                showContinueRewriteButton
                && additionalConditionToShoContinueButton
                && !rewrite.isEditLocked,
            isWithdrawTransaction: canWithdraw,
            isViewTransaction: rewrite.canUserView
        };
    };

    getPolicyLinkVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: rewrite }
            }
        } = this.props;

        const policyNumber = _.get(rewrite, 'policy.latestPeriod.policyNumber', 'Unassigned');

        return policyNumber !== 'Unassigned';
    };

    updateJobSummary = (rewrite) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { updateJobSummary }
            }
        } = this.props;

        if (updateJobSummary) {
            updateJobSummary(rewrite);
        }
    };

    render() {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRewriteSummary: rewrite }
            }
        } = this.props;

        const policyNumber = _.get(rewrite, 'policy.latestPeriod.policyNumber');
        const { isLoading } = this.state;

        if (_.isEmpty(rewrite)) {
            return <E1PLoader loaded={!isLoading} />;
        }

        const overrides = {
            '@field': {
                readOnly: true,
                autoComplete: false
            },
            transactionActions: {
                transactionActionContent: this.getTransactionActionContent(),
                transactionVisibleActions: this.getRewriteToProceed()
            },
            policyLinkDiv: {
                visible: this.getPolicyLinkVisible()
            },
            policyInfoLink: {
                content: policyNumber,
                to: `/policies/${policyNumber}/summary`
            },
            quotedBoundContainer: {
                visible: rewrite.statusCode === 'Quoted' || rewrite.statusCode === 'Bound'
            },
            summaryPolicyInceptionId: {
                value: e1pDateUtil.convertToUTCForDateWithoutTZ(_.get(rewrite, 'policy.latestPeriod.effectiveDate'))
            },
            rewriteTermEffectiveDate: {
                value: e1pDateUtil.convertToUTCForDateWithoutTZ(_.get(rewrite, 'termEffectiveDate'))
            },
            producerCodeOfRecordOrgId: {
                value: `${_.get(
                    rewrite,
                    'policy.latestPeriod.producerCodeOfRecordOrg'
                )} (${_.get(rewrite, 'policy.latestPeriod.externalID_Ext')})`
            },
            producerOfServiceId: {
                value: `${_.get(
                    rewrite,
                    'policy.latestPeriod.producerCodeOfServiceOrg'
                )} (${_.get(rewrite, 'policy.latestPeriod.externalID_Ext')})`
            },
            preferredUnderwriterId: {
                value: _.get(
                    rewrite,
                    'policy.latestPeriod.assignedUWName_Ext', ''
                )
            }

        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                underwritingcomponent: UnderwritingComponent
            },
            resolveCallbackMap: {
                onContinueTransaction: this.onContinueTransaction,
                onWithdrawTransaction: this.onWithdrawTransaction,
                onViewTransaction: this.onViewTransaction
            }
        };
        const readValue = (id, path) => readViewModelValue(metadata.pageContent, rewrite, id, path, overrides);

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewrite}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        );
    }
}

const RewriteSummary = withModalContext(RewriteSummaryWithoutModalContext);

export default withAuthenticationContext(RewriteSummary);
