import React, {
    useCallback, useContext, useEffect, useRef
} from 'react';
import _ from 'lodash';
import { Grid } from '@jutro/layout';
import { useModal } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import * as PropTypes from 'prop-types';
import { commonMessages } from 'e1p-platform-translations';
import EUAddressComponent from '../EUAddressComponent/EUAddressComponent';
import messages from '../../E1PEUUnderlyingPolicyComponent.messages';
import styles from '../../E1PEUUnderlyingPolicyComponent.module.scss';
import metadata from './EUAdditionalPremises.metadata.json5';


import { IconButton, DropdownSelectField } from '@jutro/legacy/components';


function EUAdditionalPremises(props) {
    const modalApi = useModal();
    const {
        model: additionalPremisesVM,
        onModelChange,
        onValidate,
        labelPosition,
        visible,
        id,
        viewOnlyMode,
        showErrors
    } = props;
    const accordionRef = useRef(null);
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        isComponentValid,
        onValidate: setComponentValidation,
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [additionalPremisesVM, id, onValidate, isComponentValid]);

    useEffect(() => {
        if (_.isUndefined(additionalPremisesVM.value)) {
            additionalPremisesVM.value = [];
        }

        if (!viewOnlyMode) {
            onModelChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openAddedPremises = useCallback(() => {
        if (accordionRef.current) {
            accordionRef.current
                .toggleAccordionOpen(`additionalPremise${additionalPremisesVM.children.length - 1}`);
        }
    }, [additionalPremisesVM]);

    const addPremise = useCallback(
        () => {
            const { _xCenter, _dtoName } = additionalPremisesVM;
            const newAdditionalPremiseVM = viewModelService.create({ isManuallyAddedInd: true }, _xCenter, _dtoName);

            additionalPremisesVM.pushElement(newAdditionalPremiseVM);
            onModelChange();
            openAddedPremises();
        },
        [additionalPremisesVM, viewModelService, onModelChange, openAddedPremises]
    );

    const addAddress = useCallback(
        ({ path }) => {
            const addressesVM = _.get(additionalPremisesVM, path);

            if (_.isUndefined(addressesVM.value)) {
                addressesVM.value = [];
            }

            const { _xCenter, _dtoName } = addressesVM;
            const newAddress = viewModelService.create({}, _xCenter, _dtoName);

            addressesVM.pushElement(newAddress);
            onModelChange();
        },
        [additionalPremisesVM, viewModelService, onModelChange]
    );

    const removeAdditionalPremise = useCallback(
        (premiseToRemove, index) => {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.removeAdditionalPremise,
                message: messages.confirmDelete,
                confirmButtonText: commonMessages.confirm,
                cancelButtonText: commonMessages.cancel
            }).then((results) => {
                // First delete premise's addresses
                const currentAddresses = _.get(premiseToRemove, 'addresses');

                currentAddresses.value = [];
                onModelChange();
                // Then delete the premise
                _.pullAt(additionalPremisesVM.value, index);
                _.remove(accordionRef.current.state.openedAccordions,
                    (el) => (el === `additionalPremise${index}`));
                onModelChange();
            }, () => { });
        },
        [additionalPremisesVM.value, modalApi, onModelChange]
    );

    const removeAddress = useCallback(
        ({ path }) => {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.removeAddress,
                message: messages.confirmDelete,
                messageProps: {
                    confirmButtonText: messages.delete,
                    cancelButtonText: messages.cancel
                }
            }).then((results) => {
                const pathArray = path.split('.');
                const premiseIdentifier = pathArray[0];
                const [, premiseIndex] = /\[(\d+)\][^[]*$/.exec(premiseIdentifier) || [];
                const pathIdentifier = pathArray.slice(-1);
                const [, addressIndex] = /\[(\d+)\][^[]*$/.exec(pathIdentifier) || [];
                const currentPremise = _.get(additionalPremisesVM.children, premiseIndex);
                const currentAddresses = _.get(currentPremise, 'addresses');

                _.pullAt(currentAddresses.value, addressIndex);
                onModelChange();

                if (_.isEmpty(currentAddresses.value)) {
                    _.pullAt(additionalPremisesVM.value, premiseIndex);
                    onModelChange();
                }
            }, () => { });
        },
        [additionalPremisesVM.children, additionalPremisesVM.value, modalApi, onModelChange]
    );

    const renderAdditionalPremiseHeader = useCallback((additionalPremise, index) => {
        const premiseType = _.get(additionalPremise, 'premiseType');

        return (isOpen) => (
            <div className={styles.accordionHeaders}>
                <Grid columns={['2fr', '0.5fr', '0.5fr']} gap="small">
                    <DropdownSelectField
                        readOnly
                        id={`premiseTypeAccordionHeader${index}`}
                        availableValues={premiseType.aspects.availableValues.map((item) => ({
                                code: item.code,
                                name: {
                                    id: item.name,
                                }
                            }))}
                        value={premiseType.value}
                        placeholder=""
                    />
                    {(!viewOnlyMode)
                        ? (
                            <React.Fragment>
                                <IconButton
                                    id={`editAccordionHeader${index}`}
                                    icon={isOpen ? 'mi-unfold-less' : 'mi-edit'}
                                    size="large"
                                    iconClassName={styles.editIcon}
                                />
                                <IconButton
                                    id={`deleteAccordionHeader${index}`}
                                    icon="mi-delete"
                                    size="large"
                                    iconClassName={styles.deleteIcon}
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                        removeAdditionalPremise(additionalPremise, index);
                                    }}
                                />
                            </React.Fragment>
                        ) : (null)
                    }
                </Grid>
            </div>
        );
    }, [removeAdditionalPremise, viewOnlyMode]);

    const generateOverrides = useCallback(
        () => {
            const overrides = [];

            additionalPremisesVM.children.forEach((premise, index) => {
                overrides.push({
                    [`additionalPremise${index}`]: {
                        renderHeader: renderAdditionalPremiseHeader(premise, index),
                        errorState: !premise.aspects.subtreeValid,
                        showErrors
                    }
                });
                premise.addresses.children.forEach((address, addressIndex) => {
                    overrides.push({
                        [`premiseAddress${addressIndex}`]: {
                            model: address,
                            onModelChange,
                            onValidate: setComponentValidation,
                            showErrors
                        }
                    });
                });
            });

            return Object.assign({}, ...overrides);
        },
        [additionalPremisesVM.children, renderAdditionalPremiseHeader, showErrors, onModelChange, setComponentValidation]
    );

    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition,
            visible,
            showRequired: true,
            disabled: viewOnlyMode,
            showErrors,
            autoComplete: false
        },
        additionalPremises: {
            ref: accordionRef,
            showErrors
        },
        addEUAdditionalPremise: {
            visible: !viewOnlyMode
        },
        ...generateOverrides()
    };

    const writeValue = useCallback(
        (newVal, localPath) => {
            _.set(additionalPremisesVM, `${localPath}.value`, newVal);
            onModelChange();
        },
        [onModelChange, additionalPremisesVM]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            addPremise,
            addAddress,
            removeAddress
        },
        resolveComponentMap: {
            euaddresscomponent: EUAddressComponent
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={additionalPremisesVM}
                overrideProps={overrideProps}
                onValidationChange={setComponentValidation}
                onValueChange={writeValue}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

EUAdditionalPremises.propTypes = {
    model: PropTypes.shape({}),
    onModelChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    labelPosition: PropTypes.string,
    visible: PropTypes.bool,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    showErrors: PropTypes.bool
};
EUAdditionalPremises.defaultProps = {
    labelPosition: 'top',
    visible: true,
    id: '',
    viewOnlyMode: false,
    showErrors: false,
    onValidate: undefined,
    model: undefined
};
export default EUAdditionalPremises;
