
import _ from 'lodash';

/**
 * This FeatureUtil can be used to enable features which are
 * specefic to particular state like compOnly is applicable for PA and UT
 * if feature is available always going forward, we can simply return true always
 * from here, so that we dont need to change multiple files
 */

function isCompOnlyFeatureAvailableForState(stateToCheck) {
    const compOnlyFeatureAvailableforStates = ['PA', 'UT', 'TN', 'NH', 'VA', 'NC', 'SC', 'MT', 'RI', 'CT', 'ME', 'WI'];
    if (_.isUndefined(stateToCheck)) {
        return false;
    }
    return _.includes(compOnlyFeatureAvailableforStates, stateToCheck);
}

/**
 * 
 * @param {*} stateToCheck 
 * @returns {Boolean}
 * This function is used to show or  hide Surcharge section on coverage page
 */
function isSurchargeSectionAvailableForState(stateToCheck){
    const surchargeSectionNotAvailableForStates = ['TN', 'NC'];
    if (_.isUndefined(stateToCheck)) {
        return true;
    }
    return !_.includes(surchargeSectionNotAvailableForStates, stateToCheck);
}

/**
 * 
 * @param {*} stateToCheck 
 * @returns {Boolean}
 * This function is used to show Travel Package section on coverage page
 */
function isTravelPackageVisibleForState(stateToCheck) {
    const travelPackageisNotAvailableForStates = ['NC'];
    if (_.isUndefined(stateToCheck)) {
        return true;
    }
    return !_.includes(travelPackageisNotAvailableForStates, stateToCheck);
}

/**
 * 
 * @param {*} transactionVM 
 * @returns {Boolean}
 * This Function is used to show Financial Responsibility Filing Field on the Driver screen
 */
function isFinancialResponsibilityFormAvailable(transactionVM) {
    const policyState = _.get(transactionVM, 'baseData.policyAddress.state.value.code', _.get(transactionVM, 'policyAddress.state.value.code'));
    const drivers = _.get(transactionVM, 'value.lobData.personalAuto_EA.coverables.drivers');
    const areAnyDriverAvailableAsOperator =  !_.isEmpty(drivers) && _.some(drivers, (driver) => driver.policyDriverRoleType === 'operator');
    const isJobTypeSubmission = _.get(transactionVM, 'value.baseData.jobType') === 'Submission';
    
    let isFinancialResponsibilityAvailable = false;
    switch (policyState) {
        case 'VA':
            isFinancialResponsibilityAvailable = !isJobTypeSubmission && areAnyDriverAvailableAsOperator
            break;
        case 'NH':
            isFinancialResponsibilityAvailable = areAnyDriverAvailableAsOperator;
            break;
        default:
            isFinancialResponsibilityAvailable = false;
    }
    return isFinancialResponsibilityAvailable;
}

/**
 * 
 * @param {*} stateToCheck 
 * @returns {Boolean}
 * This function is used to show or hide National Guard section on driver page
 */
function isActiveNationalGuardAvailableForState(stateToCheck){
    const surchargeSectionNotAvailableForStates = ['MT'];
    if (_.isUndefined(stateToCheck)) {
        return false;
    }
    return _.includes(surchargeSectionNotAvailableForStates, stateToCheck);
}

export default {
    isCompOnlyFeatureAvailableForState,
    isSurchargeSectionAvailableForState,
    isTravelPackageVisibleForState,
    isFinancialResponsibilityFormAvailable,
    isActiveNationalGuardAvailableForState
};
