import React, {
    useCallback, useEffect, useMemo
} from 'react';
import { readViewModelValue, appendMetadataWithIndex } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import metadata from './ProtectiveCheckboxGroup.metadata.json5';
import styles from './ProtectiveCheckboxGroup.module.scss';
import messages from './ProtectiveCheckboxGroup.messages.js';

function ProtectiveCheckboxGroup(props) {
    const {
        viewOnlyMode,
        deviceLabel,
        singleSelect,
        protectiveDevicesAvailableValues,
        selectedDevices,
        showFields,
        setShowFields,
        setSelectedDevices,
        alarmAvailableValues,
        selectedAlarmType,
        setSelectedAlarmType,
        showErrors,
        id,
        onValidate,
        alarmLabel,
        index,
        setDropdownActive
    } = props;

    const resolvers = {
        resolveClassNameMap: styles
    };
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const formattedMetadata = useMemo(() => appendMetadataWithIndex(metadata.pageContent, index), [index]);

    useEffect(() => {
        if (setSelectedAlarmType && !selectedDevices?.includes('fireAlarm') && !selectedDevices?.includes('burglarAlarm')) {
            setSelectedAlarmType('');
        }
    }, [selectedDevices, setSelectedAlarmType]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        [`protectiveTypeCheck${index}`]: {
            readOnly: viewOnlyMode,
            label: deviceLabel,
            value: showFields,
            onValueChange: ((value) => {
                setShowFields(value);

                if (!value) {
                    setSelectedDevices([]);

                    if (setSelectedAlarmType) { setSelectedAlarmType(''); }
                }
            }),
            showRequired: true,
            showErrors,
            tooltip: id === 'fireTypeID' ? { text: messages.sprinklerHelpText } : undefined,
        },
        [`protectiveTypeaheadMultiSelect${index}`]: {
            availableValues: protectiveDevicesAvailableValues,
            value: selectedDevices,
            visible: showFields && !singleSelect,
            onValueChange: ((value) => {
                if (value === null) {
                    setSelectedDevices([]);
                } else {
                    setSelectedDevices(value);
                }
            }),
            onFocus: () => { setDropdownActive(true) },
            onBlur: () => { setDropdownActive(false) },
            showRequired: true,
            showErrors,
            required: true
        },
        [`protectiveSelect${index}`]: {
            availableValues: protectiveDevicesAvailableValues,
            value: selectedDevices,
            visible: showFields && singleSelect,
            onValueChange: ((value) => {
                if (value) { setSelectedDevices(value); }
            }),
            showRequired: true,
            showErrors,
            required: true
        },
        [`alarmTypes${index}`]: {
            availableValues: alarmAvailableValues,
            value: selectedAlarmType,
            label: alarmLabel,
            visible: selectedDevices?.includes('fireAlarm') || selectedDevices?.includes('burglarAlarm'),
            onValueChange: ((value) => {
                if (value) { setSelectedAlarmType(value); }
            }),
            showRequired: true,
            showErrors,
            required: true
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
            metadata.pageContent,
            fieldId,
            fieldPath,
            overrideProps
        ),
        [overrideProps]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ViewModelForm
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
            onValidationChange={setComponentValidation}
        />
    );
}


ProtectiveCheckboxGroup.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
        clone: PropTypes.func
    }),
    viewOnlyMode: PropTypes.bool,
    deviceLabel: PropTypes.shape({}),
    alarmLabel: PropTypes.shape({}),
    singleSelect: PropTypes.bool,
    protectiveDevicesAvailableValues: PropTypes.arrayOf(PropTypes.shape({})),
    selectedDevices: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string]),
    setSelectedDevices: PropTypes.func.isRequired,
    showFields: PropTypes.bool,
    setShowFields: PropTypes.func.isRequired,
    alarmAvailableValues: PropTypes.arrayOf(PropTypes.shape({})),
    selectedAlarmType: PropTypes.string,
    setSelectedAlarmType: PropTypes.func,
    showErrors: PropTypes.bool,
    id: PropTypes.string,
    onValidate: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
};

ProtectiveCheckboxGroup.defaultProps = {
    viewOnlyMode: false,
    deviceLabel: {},
    alarmLabel: {},
    singleSelect: false,
    protectiveDevicesAvailableValues: [],
    selectedDevices: [],
    showFields: false,
    alarmAvailableValues: [],
    selectedAlarmType: '',
    setSelectedAlarmType: undefined,
    showErrors: false,
    id: undefined
};

export default ProtectiveCheckboxGroup;
