import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class DocumentRetrievalService {
    /**
     * Fetches policy documents from Nuxeo
     * @param {String} policyNumber String
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getDocuments = (policyNumber, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('policydocument'),
            'retrivePolicyDocuments',
            [policyNumber],
            additionalHeaders
        );
    }

    /**
     * Fetches quote documents from Nuxeo
     * @param {String} quoteNumber String
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getQuoteDocuments = (quoteNumber, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('policydocument'),
            'retrieveQuoteDocuments',
            [quoteNumber],
            additionalHeaders
        );
    }
}
