import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
    get as _get,
    filter as _filter,
    cloneDeep as _cloneDeep,
    isEmpty as _isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useModal } from '@jutro/components';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { JobUtil, DatatableUtil } from '@xengage/gw-portals-util-js';
import { TransactionViewFlowUtil } from 'e1p-portals-util-js';
import { SignatureService } from 'e1p-capability-gateway';
import { commonMessages } from 'e1p-platform-translations';
import moment from 'moment';
import messages from './PolicyTransactions.messages';
import metadata from './PolicyTransactions.metadata.json5';
import styles from './PolicyTransactions.module.scss';

import { Link as LinkComponent } from '@jutro/router';

const regenerateDocumentJobTypes = [
    'Rewrite',
    'PolicyChange'
];

function getCell(items, index, property) {
    return items[property.id];
}

function canUserOpenJobInXCenter(transaction) {
    return transaction.canUserView;
}

function PolicyTransactions(props) {
    const modalApi = useModal();
    const {
        transactionResponse,
        policyTransactionStatusValues,
        setIsReinstatementStarted,
        intl,
        policyType,
        authHeader,
        history,
        // typeOfTransactions used to determine what types of transaction we are showing in this component
        // it could be either of ['OPEN', 'COMPLETED', 'EXPIRED_AND_WITHDRAWN']
        typeOfTransactions
    } = props;
    const [policyTransactionData, setPolicyTransactionData] = useState([]);
    const [selectedTransactionStatus, setSelectedTransactionStatus] = useState('all');
    const [searchTransactionKeyword, setSearchTransactionKeyword] = useState(undefined);
    const [isCallingService, setIsCallingService] = useState(false);
    const translator = useTranslator();

    const getDueDate = (data) => {
        const dueDate = moment(data.contingencyDueDate).format('MM/DD/YYYY');

        return dueDate;
    };

    const getCreateDate = useCallback((data) => {
        const createDate = moment(data.createTime).format('MM/DD/YYYY');

        return createDate;
    }, []);

    const showOpenJobInXCenter = useCallback((item) => (
            item.type !== 'Submission'
            && item.type !== 'PolicyChange'
            && item.type !== 'Cancellation'
            && item.type !== 'Renewal'
            && item.type !== 'Rewrite'
        ), []);

    const goToViewOnlyFlow = useCallback((job) => TransactionViewFlowUtil.goToTransactionSpecificViewOnlyFlow(
            history, job.jobNumber, policyType, job.type, job.productCode, job.policyNumber
        ), [history, policyType]);

    const getJobNumberLink = useCallback((item, index, property) => {
        const viewPolicyEnabledTransactions = ['Submission', 'PolicyChange', 'Rewrite', 'Renewal'];

        if (canUserOpenJobInXCenter(item)) {
            if (!showOpenJobInXCenter(item.type)) {
                return (
                    <LinkComponent
                        href="/"
                        onClick={() => JobUtil.openJobInXCenter(item.jobNumber)}
                    >
                        {item[property.id]}
                    </LinkComponent>
                );
            }

            if (item.status === 'Bound'
                && viewPolicyEnabledTransactions.includes(item.type)) {
                return (
                    <LinkComponent
                        className={styles.policyTransactionLink}
                        onClick={() => goToViewOnlyFlow(item)}
                    >
                        {item[property.id]}
                    </LinkComponent>
                );
            }

            return (
                <LinkComponent
                    className={styles.policyTransactionLink}
                    to={JobUtil.getJobDetailURLByJobType(item.type, item.jobNumber)}
                >
                    {item[property.id]}
                </LinkComponent>
            );
        }

        return <span>{item.jobNumber}</span>;
    }, [goToViewOnlyFlow, showOpenJobInXCenter]);


    const getSearchFilterValues = useCallback((transactionData, keyword) => {
        const lowerCaseFilterValue = keyword.toLocaleLowerCase();

        return _filter(transactionData, (res) => Object.keys(res).some(
                (key) => typeof res[key] === 'string'
                    && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            ));
    }, []);

    const getFormattedCurrency = useCallback((premiumAmount, index) => (
            <CurrencyField
                id={`currency${index}`}
                value={premiumAmount}
                readOnly
                hideLabel
                showOptional={false}
                className={styles.currencyStyle}
            />
        ), []);

    const getCellForCreateTime = useCallback((items, index, property) => intl.formatDate(new Date(items[property.id]), { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }), [intl]);

    const getMappedTransactionData = useCallback((policyTransactionResponse, filter) => {
        const selectedStatus = _get(filter, 'selectedTransactionStatus');
        const searchKeyword = _get(filter, 'searchTransactionKeyword');
        const transactionData = _cloneDeep(policyTransactionResponse);

        let policyTransactionDataTemp = transactionData.map((policy, index) => ({
                jobNumber: policy.jobNumber,
                status: policy.status,
                displayType: policy.displayType,
                policyDisplayStatus: policy.policyDisplayStatus,
                totalPremium: getFormattedCurrency(policy.totalPremium, index),
                policyEffectiveDate: intl.formatDate(
                    new Date(
                        policy.policyEffectiveDate
                    ),
                    {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        timeZone: 'UTC'
                    }
                ),
                createTime: policy.createTime,
                canUserView: policy.canUserView,
                type: policy.type,
                termNumber_Ext: policy.termNumber_Ext,
                /**
                 * E1PAP1PC-14654 :
                 * Changing Display name for System User to System
                 */
                updatedByUser_Ext: policy.updatedByUser_Ext === 'System User'
                    ? 'System' : policy.updatedByUser_Ext,
                policyNumber: policy.policyNumber,
                productCode: policy.product.productCode,
                transactionCost_Ext: getFormattedCurrency(policy.transactionCost_Ext, index),
                hasSignatureRequiredFormsInCurrentJob_Ext: policy.hasSignatureRequiredFormsInCurrentJob_Ext,
                policyChangeReason: (policy.policyChangeReason) ? translator({
                    id: `typekey.PolicyChangeReason_Ext.${policy.policyChangeReason}`,
                    defaultMessage: policy.policyChangeReason
                }) : '-',
            })).sort((a, b) => new Date(b.policyEffectiveDate).getTime()
                - new Date(a.policyEffectiveDate).getTime());

        // To get the list of reinstatements which are in quoted and draft status
        const reinstateStatus = _filter(policyTransactionDataTemp, (transaction) => transaction.type === 'Reinstatement' && (transaction.status === 'Draft' || transaction.status === 'Quoted'));

        if (!_isEmpty(reinstateStatus)) {
            setIsReinstatementStarted(true);
        }

        if (selectedStatus && selectedStatus !== 'all') {
            policyTransactionDataTemp = _filter(policyTransactionDataTemp, (transaction) => transaction.status === selectedStatus);
        }

        if (searchKeyword) {
            policyTransactionDataTemp = getSearchFilterValues(
                policyTransactionDataTemp,
                searchKeyword
            );
        }

        setPolicyTransactionData(policyTransactionDataTemp);
    }, [getFormattedCurrency, intl, translator, setIsReinstatementStarted, getSearchFilterValues]);


    useEffect(() => {
        getMappedTransactionData(transactionResponse);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionResponse]);

    const handleFilterValueChange = useCallback((value) => {
        setSelectedTransactionStatus(value);
        getMappedTransactionData(transactionResponse, {
            selectedTransactionStatus: value,
            searchTransactionKeyword
        });
    }, [getMappedTransactionData, setSelectedTransactionStatus, transactionResponse, searchTransactionKeyword]);

    const handleSearchValueChange = useCallback((value) => {
        setSearchTransactionKeyword(value);
        getMappedTransactionData(transactionResponse, {
            searchTransactionKeyword: value,
            selectedTransactionStatus
        });
    }, [getMappedTransactionData, setSearchTransactionKeyword, transactionResponse, selectedTransactionStatus]);

    const sortCurrency = useCallback((value1, value2) => {
        const amount1 = _get(value1, 'props.value.amount', undefined);
        const amount2 = _get(value2, 'props.value.amount', undefined);

        if (amount1 === undefined) {
            return -1;
        }

        if (amount2 === undefined) {
            return 1;
        }

        return amount1 - amount2;
    }, []);

    /**
     * Calls backend api for given job number to get URL for documents
     * and on success opens another tab to show documents
     */
    const getDocumentsToSign = useCallback(async (jobNumber) => {
        setIsCallingService(true);

        const signatureResponseDTO = await SignatureService
            .getSigningURLForJob(jobNumber, authHeader);
        const errorMessage = _get(signatureResponseDTO, 'exceptions_Ext[0].errorMessage');

        setIsCallingService(false);

        if (errorMessage) {
            return modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: translator(messages.generateDocErrorTitle),
                message: errorMessage
            });
        }

        const documentWindow = window.open(
            signatureResponseDTO.printAndSignDocumentURL,
            '_blank'
        );

        // Pop-up blocker is on
        if (!documentWindow) {
            return modalApi.showAlert({
                status: 'warning',
                icon: 'mi-error-outline',
                title: translator(messages.popupBlockerAlertTitle),
                message: translator(messages.popupBlockerAlertMessage),
            });
        }
    }, [authHeader, modalApi, translator]);

    /**
     * Generate Document link to show document on click of link
     * Will show Regenerate Document link in case job type is submission, rewrite or policy change
     * and HasSignatureRequiredFormsInCurrentJob_Ext is true
     * In all remaining scenarios will show Not Applicable
     * User Story :: E1PAP1PC-11185
     */
    const regenerateDocumentLink = useCallback((item, index) => {
        let linkText = translator(messages.notApplicable);

        if (item.type === 'Submission'
            || (regenerateDocumentJobTypes.includes(item.type)
                && item.hasSignatureRequiredFormsInCurrentJob_Ext)) {
            linkText = translator(messages.regenerateDocument);

            return (
                <LinkComponent
                    id={`regenerateDocumentLink${index}`}
                    className={styles.policyTransactionLink}
                    onClick={() => getDocumentsToSign(item.jobNumber)}
                >
                    {linkText}
                </LinkComponent>
            );
        }

        return linkText;
    }, [getDocumentsToSign, translator]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            autoComplete: false
        },
        policyTransactionsLoader: {
            loaded: !isCallingService,
            text: translator(messages.gettingDocumentLoadingMessage)
        },
        policyTransactionContainer: {
            visible: !isCallingService,
        },
        policyTransactionStatus: {
            availableValues: policyTransactionStatusValues,
            value: selectedTransactionStatus
        },
        summaryPolicyTransactionsTableGrid: {
            data: policyTransactionData,
            localSorting: true
        },
        summaryPolicyTransactionsId: {
            className: styles.gwTransactionsStyle,
            value: (() => {
                let displayHeading = messages.openPolicyTransactions;

                switch (typeOfTransactions) {
                    case 'COMPLETED':
                        displayHeading = messages.completedPolicyTransactions;
                        break;
                    case 'EXPIRED_AND_WITHDRAWN':
                        displayHeading = commonMessages.expiredAndWithdrawnTransaction;
                        break;
                    default:
                        displayHeading = messages.openPolicyTransactions;
                        break;
                }

                return translator(displayHeading);
            })()
        },
        searchFilter: {
            value: searchTransactionKeyword
        },
        transactionStatusColumnTitle: {
            visible: false
        },
        printToReSign: {
            visible: typeOfTransactions === 'COMPLETED'
        }
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                policyTransactionData,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [policyTransactionData, overrideProps]
    );
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getDueDate,
            getCreateDate,
            getCell,
            getJobNumberLink,
            handleFilterValueChange,
            handleSearchValueChange,
            sortDate: DatatableUtil.sortDate,
            sortString: DatatableUtil.sortString,
            sortCurrency,
            getCellForCreateTime,
            regenerateDocumentLink
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PolicyTransactions.propTypes = {
    transactionResponse: PropTypes.arrayOf(PropTypes.shape({})),
    policyTransactionStatusValues: PropTypes.arrayOf(PropTypes.shape({})),
    setIsReinstatementStarted: PropTypes.func,
    intl: PropTypes.shape({}).isRequired,
    policyType: PropTypes.string,
    authHeader: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired,
    typeOfTransactions: PropTypes.string.isRequired
};

PolicyTransactions.defaultProps = {
    transactionResponse: [],
    policyTransactionStatusValues: [],
    setIsReinstatementStarted: () => { },
    policyType: ''
};

export default PolicyTransactions;
//
