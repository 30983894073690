import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class StandardizedAddressService {
    static getStandardizedAddress(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('standardizedAddress'),
            'getStandardizedAddress',
            [dto],
            additionalHeaders
        );
    }
}
