import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { ActivitiesService } from 'gw-capability-gateway';
import OpenActivitiesComponent from '../../Components/OpenActivitiesComponent/OpenActivitiesComponent';
import metadata from './Activities.metadata.json5';

class CancellationActivities extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}),
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string,
                getCancellationSummaryCount: PropTypes.func
            }),
            basePath: PropTypes.string
        }).isRequired
    };

    static defaultProps = {
        authHeader: undefined
    }

    getActivities = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                quoteDetailsData: { jobNumber }
            }
        } = this.props;
        const getAvailableActivities = await ActivitiesService.getActivitiesForJob(
            jobNumber,
            authHeader
        );

        return getAvailableActivities;
    };

    createNewActivity = async (newActivityData) => {
        const {
            authHeader,
            fromAccountLanding: {
                quoteDetailsData: { getCancellationSummaryCount }
            }
        } = this.props;
        const newActivityResponse = await ActivitiesService.createNewActivity(
            newActivityData,
            null,
            authHeader
        );

        if (!_.isEmpty(newActivityResponse)) {
            getCancellationSummaryCount();

            return newActivityResponse;
        }

        return {};
    };

    render() {
        const {
            fromAccountLanding: {
                quoteDetailsData: { jobNumber }
            }
        } = this.props;
        const overrides = {
            cancellationActivities: {
                getActivities: this.getActivities,
                createNewActivity: this.createNewActivity,
                activityEntity: 'job',
                activityEntityId: jobNumber
            }
        };
        const resolvers = {
            resolveComponentMap: {
                openactivitiescomponent: OpenActivitiesComponent
            }
        };

        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

export default withAuthenticationContext(CancellationActivities);
