import React, {
    useCallback, useContext, useMemo, useState, useEffect, useRef
} from 'react';
import {
    cloneDeep as _cloneDeep,
    get as _get,
    set as _set,
    isEmpty as _isEmpty
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useModal } from '@jutro/components';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { EndorsementService } from 'e1p-capability-policychange';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    useSniUtil, useLandingPageUtil, useNewBusinessUtil, useEffectiveDateUtil
} from 'e1p-capability-hooks';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { useTranslator } from '@jutro/locale';
import moment from 'moment';
import appConfig from 'app-config';
import messages from './InsuredDetailsPage.messages';
import metadata from './InsuredDetailsPage.metadata.json5';

const LOB = 'personalUmbrella_EU';

function InsuredDetailsPage(props) {
    const modalApi = useModal();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const translator = useTranslator();
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [policyIsBackDated, setPolicyIsBackDated] = useState(false);
    const [effDateChanged, setEffDateChanged] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const isMounted = useRef(false);
    const { daysInFutureForBookroll, daysInFuture, daysInPast } = appConfig.dateInputConfig;
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        updateWizardSnapshot,
        authUserData
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const {
        getProducerDetailsFromExternalId,
        createPrimaryNamedInsuredVM
    } = useNewBusinessUtil(
        policyChangeVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        () => { } // can't change in policy change so parm not needed
    );
    const {
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        onValidate
    } = useValidation('euInsuredDetailsPage');

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    const { createSecondaryNamedInsuredVM, removeSni } = useSniUtil(
        policyChangeVM,
        updateWizardData,
        viewModelService,
        LOB,
        disregardFieldValidation
    );

    const {
        getEffectiveDateBounds
    } = useEffectiveDateUtil(policyChangeVM, updateWizardData, false);

    // If Book Transfer is Yes, allow quoting up to 120 days in the future, otherwise 60 days
    const { maxEffectiveDate, minEffectiveDate } = useMemo(
        () => producerCodeDetails.bookTransferIndicator ? getEffectiveDateBounds(
                policyChangeVM.baseData.periodStartDate.value,
                policyChangeVM.baseData.periodEndDate.value,
                daysInPast,
                daysInFutureForBookroll
            ) : getEffectiveDateBounds(
                policyChangeVM.baseData.periodStartDate.value,
                policyChangeVM.baseData.periodEndDate.value,
                daysInPast,
                daysInFuture
            ),
        [
            getEffectiveDateBounds,
            policyChangeVM.baseData.periodEndDate.value,
            policyChangeVM.baseData.periodStartDate.value,
            producerCodeDetails.bookTransferIndicator,
            daysInFutureForBookroll,
            daysInFuture,
            daysInPast
        ]
    );

    const addNewPrimaryNamedInsured = useCallback(() => {
        const primaryNamedInsuredObj = {
            person: {
                lastName: '',
                firstName: '',
                dateOfBirth: {
                    year: undefined,
                    month: undefined,
                    day: undefined
                },
                // save primary address
                primaryAddress: (() => {
                    // default new person address to prior pni
                    const priorPniAddress = _get(
                        policyChangeVM,
                        `lobData[${LOB}].primaryNamedInsured.person.primaryAddress.value`
                    );

                    // Needs new public ID to make it a new adress in the DB
                    _set(priorPniAddress, 'publicID', undefined);

                    return priorPniAddress;
                })()
            }
        };
        const { _dtoName, _xCenter } = policyChangeVM.lobData[LOB].primaryNamedInsured;
        const primaryNamedInsuredVM = viewModelService.create(
            primaryNamedInsuredObj,
            _xCenter,
            _dtoName
        );

        _set(
            policyChangeVM,
            `lobData[${LOB}].primaryNamedInsured.value`,
            primaryNamedInsuredVM.value
        );
        updateWizardData(policyChangeVM);
    }, [policyChangeVM, updateWizardData, viewModelService]);

    useEffect(() => {
        // keeping the IF condition here, but under no circumstances should it be undefined
        if (policyChangeVM.baseData.externalID_Ext?.value) {
            (async () => {
                const details = await getProducerDetailsFromExternalId(
                    policyChangeVM.baseData.externalID_Ext.value
                );

                setProducerCodeDetails(details);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setBackdateInformation = () => {
        const policyEffectiveDate = moment(policyChangeVM.baseData.effectiveDate.value);
        const effectiveDateBeforeToday = moment().isAfter(policyEffectiveDate, 'd');

        setPolicyIsBackDated(effectiveDateBeforeToday);
    };

    useEffect(() => {
        setBackdateInformation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);
            // Need this for validation rules
            _set(policyChangeVM, 'flowStepIDs_Ext.value', ['namedinsured']);
            _set(policyChangeVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);

            if (policyChangeVM.jobID.value === undefined) {
                const response = await EndorsementService.checkEffectiveDateIsValid(
                    [
                        policyChangeVM.value.policyNumber,
                        policyChangeVM.value.baseData.effectiveDate
                    ],
                    authHeader
                );

                if (response) {
                    const saveResponse = await EndorsementService.saveEndorsement(
                        [policyChangeVM.value],
                        authHeader
                    );

                    _set(policyChangeVM, 'value', saveResponse);
                    updateWizardData(policyChangeVM);
                    setIsSavingEndorsement(false);

                    return policyChangeVM;
                }
            } else {
                const saveResponse = await EndorsementService.saveEndorsement(
                    [policyChangeVM.value],
                    authHeader
                );

                _set(policyChangeVM, 'value', saveResponse);
                updateWizardData(policyChangeVM);
                setIsSavingEndorsement(false);

                return policyChangeVM;
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [authHeader, isComponentValid, policyChangeVM, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, policyChangeVM, updateWizardSnapshot]
    );

    const generateOverrides = useCallback(() => {
        const overrides = {};
        
        overrides.secondaryNamedInsured = {
            hideSSN: true
        };
        overrides.primaryNamedInsured = {
            hideSSN: true,
            isPNI: true
        };

        return overrides;
    }, []);

    const swapPniSni = useCallback(() => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: e1pCommonMessages.swapPniSniTitle,
            message: e1pCommonMessages.swapPniSniMessage,
            confirmButtonText: e1pCommonMessages.confirm,
            cancelButtonText: e1pCommonMessages.cancel
        }).then(
            (result) => {
                if (result !== 'cancel') {
                    const pniPerson = _cloneDeep(
                        policyChangeVM.lobData[LOB].primaryNamedInsured.person.value
                    );
                    const sniPerson = _cloneDeep(
                        policyChangeVM.lobData[LOB].secondaryNamedInsured.person.value
                    );

                    policyChangeVM.lobData[LOB].primaryNamedInsured.person.value = sniPerson;
                    policyChangeVM.lobData[LOB].secondaryNamedInsured.person.value = pniPerson;
                    // since we are swapping PNI and SNI, we need remove publicID, FixedID and integrationID from both policyContactRoles
                    // i.e PNI and SNI
                    _set(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.fixedId.value`, undefined);
                    _set(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.publicID.value`, undefined);
                    _set(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.integrationId.value`, undefined);
                    _set(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.fixedId.value`, undefined);
                    _set(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.publicID.value`, undefined);
                    _set(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.integrationId.value`, undefined);

                    // If paperless Email id exist and pni email id is undefiend
                    // then update pni email to paperless email
                    if (!_get(policyChangeVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value')
                        && !!_get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessEmail.value')) {
                        _set(policyChangeVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value',
                            _get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessEmail.value'));
                    }

                    updateWizardData(policyChangeVM);
                }
            },
            () => { }
        );
    }, [policyChangeVM, updateWizardData, modalApi]);

    const onChangeLossAfterDate = useCallback((value) => {
        _set(policyChangeVM, 'lobData.personalUmbrella_EU.lossOccurredBetweenEffectiveDateAndCreateDateInd', value);
        _set(policyChangeVM, 'hasLossOnOrAfterEffDate_Ext', value);
        updateWizardData(policyChangeVM);
    }, [policyChangeVM, updateWizardData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        agencyName: {
            value: producerCodeDetails.agencyName
        },
        bookTransferIndicator: {
            value: producerCodeDetails.bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: producerCodeDetails.serviceCenterIndicatorValue
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        changeEffectiveDate: {
            dateDTO: policyChangeVM.baseData.effectiveDate,
            updateDateDto: () => {
                setEffDateChanged(true);
                setBackdateInformation();
                updateWizardData(policyChangeVM);
            },
            minDate: minEffectiveDate,
            maxDate: maxEffectiveDate,
            showErrors: true,
            onValidate
        },
        coverageRateAsOfDate: {
            dateDTO: policyChangeVM.baseData.periodRateAsOfDate,
            updateDateDto: () => updateWizardData(policyChangeVM),
            defaultToToday: true,
            showErrors: true,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        policyInformationInfoContainer: {
            columns: authUserData.permissions_Ext.includes('editrateasofdate')
                ? ['0.25fr', '0.25fr', '0.25fr', '0.25fr'] : ['0.25fr', '0.25fr', '0.25fr']
        },
        insuredDetailsPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        insuredDetailsPageContainer: {
            visible: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges
        },
        mailingAndBillingAddressComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            showErrors: isPageSubmitted
        },
        addSni: {
            visible: !_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        sniContainer: {
            visible: !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        removeSni: {
            visible: !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        swapPniSni: {
            visible: !!_get(policyChangeVM, `value.lobData[${LOB}].secondaryNamedInsured.person.publicID`)
                && !!_get(policyChangeVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`)
        },
        euPriorAddressComponent: {
            submissionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            viewModelService,
            createPrimaryNamedInsuredVM,
            visible: true,
            disabled: true,
            disregardFieldValidation,
            LOB,
            showErrors: isPageSubmitted
        },
        changePniComponent: {
            accountNumber: _get(policyChangeVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(policyChangeVM, 'baseData.accountNumber.value'),
            allowNewContact: false,
            excludedContacts: [
                _get(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            showErrors: isPageSubmitted
        },
        changeSniComponent: {
            accountNumber: _get(policyChangeVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(policyChangeVM, 'baseData.accountNumber.value')
                && !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            excludedContacts: [
                _get(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            showErrors: isPageSubmitted
        },
        lossQuestionEmptyContainer: {
            visible: !policyIsBackDated || !effDateChanged,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 3 : 2
        },
        lossQuestionContainer: {
            visible: policyIsBackDated && effDateChanged,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 3 : 2
        },
        lossOccurredBetweenEffectiveDateAndCreateDateInd: {
            onValueChange: onChangeLossAfterDate
        },
        agencyInformationMainDiv: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: policyChangeVM,
            authHeader,
            LOB
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate,
            onRemoveSni: removeSni,
            onAddNewPni: addNewPrimaryNamedInsured,
            onSwapPniSni: swapPniSni
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    const saveAndQuote = useCallback(
        async () => {
            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            _set(policyChangeVM, 'value', quoteResponse);
            updateWizardData(policyChangeVM);

            return policyChangeVM.value;
        },
        [authHeader, policyChangeVM, updateWizardData]
    );

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);
            policyChangeVM.value = await saveAndQuote();
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //   If underwriting issues, we will go to change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            // Update the component's state only if it is currently mounted.
            if (isMounted.current) {
                setIsSavingEndorsement(false);
            }

            return false;
        },
        [
            getLandingPageIndexForQuotedJob,
            isComponentValid,
            jumpTo,
            policyChangeVM,
            saveAndQuote,
            steps,
            updateWizardData,
            updateWizardSnapshot
        ]
    );

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showPrevious={false}
            showCustom
            onCustom={onCustom}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

InsuredDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(InsuredDetailsPage);
