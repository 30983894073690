import React, {
    useEffect,
    useState,
    useContext,
    useCallback,
    useMemo,
    useRef
} from 'react';
import PropTypes from 'prop-types';
import { get as _get, isEqual as _isEqual, some as _some, includes as _includes } from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Grid, GridItem } from '@jutro/layout';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    commonMessages as e1pCommonMessages, euCommonMessages
} from 'e1p-platform-translations';
import { ViewModelServiceContext} from '@xengage/gw-portals-viewmodel-react';
import { TypekeyUtil } from 'e1p-portals-util-js';
import { E1PDateComponent } from 'e1p-capability-policyjob-react';
import { Tooltip, ToggleField, useModal } from '@jutro/components';

import { IconButton, InputField, DropdownSelectField } from '@jutro/legacy/components';

const JURISDICTIONS_COUNTRIES = [
    'CA',
    'PR',
    'VI',
    'GU',
    'AS',
    'MP',
    'Other_ext',
];

function EUVehicleOperatorGridComponent(props) {
    const {
        id,
        transactionVM,
        updateWizardData,
        drivers,
        path,
        viewOnlyMode,
        showErrors,
        onValueChange,
        onValidate,
        setCheckScrolling
    } = props;
    
    const modalApi = useModal();

    const viewModelService = useContext(ViewModelServiceContext);

    const [driverLabels, setDriverLabels] = useState([]);
    const [driverFields, setDriverFields] = useState([]);
    const [anyLicenseInternationalState, setAnyLicenseInternationalState] = useState(false);

    const currentUnderlyingPolicies = useRef(_get(transactionVM,
        'lobData.personalUmbrella_EU.coverables.underlyingPolicies.value', [])).current;

    const policyHasVehicleUnderlyingPolicy = useMemo(
        () =>
            _some(currentUnderlyingPolicies, policy =>
                _includes(
                    [
                        'personalauto',
                        'personalaononowner',
                        'otherspecialityvehicle',
                    ],
                    policy.policyType
                )
            ),
        [currentUnderlyingPolicies]
    );

    const policyHasMotorcycleUnderlyingPolicy = useMemo(
        () =>
            _some(
                currentUnderlyingPolicies,
                policy => policy.policyType === 'cycle'
            ),
        [currentUnderlyingPolicies]
    );

    const {
        isComponentValid,
        onValidate: setComponentValidation,
        disregardFieldValidation
    } = useValidation(id);

    const translator = useContext(TranslatorContext);

    useEffect(() => {
        const anyLicenseInternational = drivers.children.some(
            vehicleOperator =>
                _get(vehicleOperator, 'driversLicenseState.value.code') ===
                'INT_ext'
        );

        setAnyLicenseInternationalState(!!anyLicenseInternational);
    }, [drivers, transactionVM]);

    useEffect(() => {
        const alwaysShowLabels = [
            {
                id: 'firstName',
                content: {
                    id: 'e1p.platform.common.First Name',
                    defaultMessage: 'First Name',
                },
                className: 'grid-label required',
            },
            {
                id: 'middleInitial',
                content: {
                    id: 'e1p.platform.common.Middle Initial',
                    defaultMessage: 'Middle Initial',
                },
                className: 'grid-label',
            },
            {
                id: 'lastName',
                content: {
                    id: 'e1p.platform.common.Last Name',
                    defaultMessage: 'Last Name',
                },
                className: 'grid-label required',
            },
            {
                id: 'suffix',
                content: {
                    id: 'e1p.platform.common.Suffix',
                    defaultMessage: 'Suffix',
                },
                className: 'grid-label',
            },
            {
                id: 'dateOfBirth',
                content: {
                    id: 'e1p.platform.common.Date of Birth',
                    defaultMessage: 'Date of Birth',
                },
                className: 'grid-label required',
            },
            {
                id: 'licenseNumber',
                content: {
                    id: "e1p.platform.lob.eu.Driver's License Number",
                    defaultMessage: "Driver's License Number",
                },
                className: 'grid-label required',
            },
            {
                id: 'licenseState',
                content: {
                    id: "e1p.platform.lob.eu.Driver's License State",
                    defaultMessage: "Driver's License State",
                },
                className: 'grid-label required',
            }
        ];

        if (anyLicenseInternationalState) {
            alwaysShowLabels.push({
                id: 'licenseCountry',
                content: {
                    id: "e1p.platform.lob.eu.Driver's License Country",
                    defaultMessage: "Driver's License Country",
                },
                className: 'grid-label required',
            });
        }

        alwaysShowLabels.push({
            id: 'operatorType',
            content: [
                {
                    id: 'operatorTypeLabel',
                    content: {
                        id: 'e1p.platform.lob.eu.Operator Type',
                        defaultMessage: 'Operator Type',
                    },
                },
                {
                    id: 'operatorTypeLabelHelpText',
                    tooltipText: {
                        id:
                            'e1p.platform.lob.eu.OperatorTypeHelpText.Only resident relatives who are Operators can be included in an Umbrella policy. Drivers who are Non-Operators or Excluded Operators on the Auto policy are not covered under the Umbrella policy.',
                        defaultMessage:
                            'Only resident relatives who are Operators can be included in an Umbrella policy. Drivers who are Non-Operators or Excluded Operators on the Auto policy are not covered under the Umbrella policy.',
                    },
                },
            ],
            className: 'grid-label required',
        });

        if (policyHasVehicleUnderlyingPolicy) {
            alwaysShowLabels.push({
                id: 'privatePassengerAuto',
                content: {
                    id: 'e1p.platform.lob.eu.Operator of private passenger auto?',
                    defaultMessage: 'Operator of private passenger auto?',
                },
                className: 'grid-label required',
            });
        }

        if (policyHasMotorcycleUnderlyingPolicy) {
            alwaysShowLabels.push({
                id: 'motorcycleOperator',
                content: {
                    id:'e1p.platform.lob.eu.Operator of Motorcycle?',
                    defaultMessage: 'Operator of Motorcycle?',
                },
                className: 'grid-label required',
            });
        }

        setDriverLabels(alwaysShowLabels);
    }, [policyHasVehicleUnderlyingPolicy, policyHasMotorcycleUnderlyingPolicy, anyLicenseInternationalState]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        // removing onValidate from depedency array, it was rendering continously
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isComponentValid]);

    const getDriverFields = useCallback((driver) => {
        const startingDate = new Date();
        const baseFields = [
            {
                id: 'driverFirstName',
                type: 'field',
                component: 'input',
                componentProps: {
                    path: 'firstName',
                    required: true,
                    maxLength: 30,
                },
            },
            {
                id: 'middleName',
                type: 'field',
                component: 'input',
                componentProps: {
                    placeholder: '',
                    path: 'middleName',
                    maxLength: 1,
                },
            },
            {
                id: 'driverLastName',
                type: 'field',
                component: 'input',
                componentProps: {
                    required: true,
                    placeholder: '',
                    path: 'lastName',
                    maxLength: 40,
                },
            },
            {
                id: 'driverSuffix',
                type: 'field',
                component: 'dropdownSelect',
                componentProps: {
                    placeholder: '',
                    path: 'suffix',
                },
            },
            {
                id: 'driverDateOfBirth',
                type: 'field',
                component: 'E1PDateComponent',
                componentProps: {
                    isRequired: true,
                    onValidate: 'setComponentValidation',
                    dateDTO: driver.dateOfBirth,
                    maxDate: {
                        year: startingDate.getFullYear() - 16,
                        month: startingDate.getMonth(),
                        day: startingDate.getDate(),
                    },
                },
            },
            {
                id: 'driverLicenseNumber',
                type: 'field',
                component: 'input',
                componentProps: {
                    required: true,
                    path: 'driversLicense',
                },
            },
            {
                id: 'driverLicenceState',
                type: 'field',
                component: 'dropdownSelect',
                componentProps: {
                    required: true,
                    path: 'driversLicenseState',
                    searchable: true,
                },
            }
        ];

        if (anyLicenseInternationalState) {
            baseFields.push({
                id: 'driverLicenseCountry',
                type: 'field',
                component: 'dropdownSelect',
                componentProps: {
                    path: 'driversLicenseCountry',
                    placeholder: '',
                    searchable: true,
                },
            })
        }

        baseFields.push({
            id: 'driverOperatorType',
            type: 'field',
            component: 'dropdownSelect',
            componentProps: {
                placeholder: '',
                path: 'operatorType',
                searchable: true,
            },
        });

        if (policyHasVehicleUnderlyingPolicy) {
            baseFields.push( {
                id: 'personalVehicleOperatorInd',
                type: 'field',
                component: 'Toggle',
                componentProps: {
                    controlClassName: 'toggleContainer',
                    required: true,
                    availableValues: [
                        {
                            code: 'true',
                            name: {
                                id: 'e1p.platform.common.Yes',
                                defaultMessage: 'Yes',
                            },
                        },
                        {
                            code: 'false',
                            name: {
                                id: 'e1p.platform.common.No',
                                defaultMessage: 'No',
                            },
                        },
                    ],
                    path: 'personalVehicleOperatorInd',
                },
            });
        }

        if (policyHasMotorcycleUnderlyingPolicy) {
            baseFields.push( {
                id: 'cycleOperatorInd',
                type: 'field',
                component: 'Toggle',
                componentProps: {
                    controlClassName: 'toggleContainer',
                    required: true,
                    availableValues: [
                        {
                            code: 'true',
                            name: {
                                id: 'e1p.platform.common.Yes',
                                defaultMessage: 'Yes',
                            },
                        },
                        {
                            code: 'false',
                            name: {
                                id: 'e1p.platform.common.No',
                                defaultMessage: 'No',
                            },
                        },
                    ],
                    path: 'cycleOperatorInd',
                },
            });
        }

        return baseFields;
    }, [policyHasVehicleUnderlyingPolicy, policyHasMotorcycleUnderlyingPolicy, anyLicenseInternationalState]);

    useEffect(() => {
        const tempFields = driverFields;

        drivers.children.forEach((driver, index) => {
            tempFields[index] = getDriverFields(driver);
        });
        setDriverFields(tempFields);
    }, [driverFields, getDriverFields, drivers.children]);

    const formatLabel = (labelObject) => {
        if (labelObject.id === 'operatorType') {
            return (
                <div id={labelObject.id} className="grid-label grid-label-icon">
                    <span id={labelObject.content[0].id} className="required">
                        {translator(labelObject.content[0].content)}
                    </span>
                    <Tooltip
                        id={labelObject.content[1].id}
                        trigger="mouseenter"
                        content={translator(labelObject.content[1].tooltipText)}
                    >
                        <IconButton icon="mi-help-outline" />
                    </Tooltip>
                </div>
            );
        }

        return (
            <div id={labelObject.id} className={labelObject.className}>
                {translator(labelObject.content)}
            </div>
        );
    };

    const availableValuesForSuffix = useMemo(() => {
        const typelistWithNoneCode = [
            {
                code: 'None',
                name: translator(e1pCommonMessages.none),
            },
        ];

        return typelistWithNoneCode.concat(
            TypekeyUtil.getAvailableValuesForTypekey(
                viewModelService,
                'NameSuffix'
            )
        );
    }, [translator, viewModelService]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    // Function to get jurisdiction Countries from list of
    // available countries
    const getAvailableValuesForLicenseCountry = useCallback((operator) => {
        const isInternational =
            operator.driversLicenseState.value?.code === 'INT_ext';

        if (isInternational) {
            // Code to extract available jurisdiction Countries If International
            // is selected under Driver's State
            return operator.driversLicenseCountry.aspects.availableValues
                .filter((value) => JURISDICTIONS_COUNTRIES.includes(value.code))
                .map((feature) => ({
                    code: feature.code,
                    name: {
                        defaultMessage: feature.name
                            .replace(/([A-Z])/g, ' $1')
                            .trim(),
                        id: feature.name,
                    },
                }));
        }

        return undefined;
    }, []);

    const onDriverLicenseCountryChange = useCallback(
        (value, changedPath) => {
            handleValueChange(value, changedPath);
            // adding license country on driver obj
            handleValueChange(
                value,
                `${changedPath.split('.')[0]}driversLicenseCountry`
            );
        },
        [handleValueChange]
    );

    const onDriverLicenseStateChange = useCallback(
        (value, changedPath) => {
            handleValueChange(value, changedPath);

            const indexObject = changedPath.split('.')[0];

            // adding license state on driver obj
            handleValueChange(value, `${indexObject}driversLicenseState`);

            if (value !== 'INT_ext') {
                // IAP-1697, when licenseState is any US state,
                // then _set license country to undefined
                onDriverLicenseCountryChange(
                    undefined,
                    `${indexObject}.driversLicenseCountry`
                );
            }
        },
        [handleValueChange, onDriverLicenseCountryChange]
    );

    const formatField = (row, column) => {
        const driver = drivers.children[column - 1];
        const driverData = driverFields[column - 1];
        const driverField = driverData ? driverData[row - 2] : undefined;

        const readOnlyValue = false;
        let className;

        if (driverField.component === 'input') {
            const fieldPath = driverField.componentProps.path
                ? `[${column - 1}].${driverField.componentProps.path}`
                : undefined;
            const value = driverField.componentProps.value
                ? driverField.componentProps.value
                : _get(driver, `${driverField.componentProps.path}.value`);

            return (
                <InputField
                    id={`${driverField.id}${column - 1}`}
                    path={fieldPath}
                    hideLabel
                    required={driverField.componentProps.required}
                    maxLength={driverField.componentProps.maxLength}
                    placeholder=""
                    onValueChange={handleValueChange}
                    value={value}
                    readOnly={viewOnlyMode || readOnlyValue}
                    className={className}
                    showErrors={showErrors}
                    showRequired
                    onValidationChange={setComponentValidation}
                />
            );
        }

        if (driverField.component === 'dropdownSelect') {
            const fieldPath = driverField.componentProps.path
                ? `[${column - 1}].${driverField.componentProps.path}`
                : undefined;
            const value = driverField.componentProps.value
                ? driverField.componentProps.value
                : _get(driver, `${driverField.componentProps.path}.value`);
            let onChange = handleValueChange;
            // eslint-disable-next-line prefer-destructuring
            let availableOptions = driverField.availableOptions;
            let defaultValue;

            if (driverField.id === 'driverSuffix') {
                onChange = (suffixValue, suffixPath) => {
                    let updatedValue = suffixValue;

                    if (suffixValue === 'None') {
                        updatedValue = undefined;
                    }

                    handleValueChange(updatedValue, suffixPath);
                };
                availableOptions = availableValuesForSuffix;
            } else if (driverField.id === 'driverLicenceState') {
                onChange = onDriverLicenseStateChange;
                availableOptions = _get(
                    driver,
                    `${driverField.componentProps.path}.aspects.availableValues`
                ).map((stateObj) => ({
                        code: stateObj.code,
                        name: translator({
                            id: stateObj.name,
                            defaultMessage: stateObj.name,
                        }),
                    }));
            } else if (driverField.id === 'driverLicenseCountry') {
                if (driver.driversLicenseState.value?.code !== 'INT_ext') {
                    return <GridItem key={`${driverField.id}${column - 1}`} />;
                }

                onChange = onDriverLicenseCountryChange;
                availableOptions = getAvailableValuesForLicenseCountry(driver);
            } else if (driverField.id === 'driverOperatorType') {
                availableOptions = _get(
                    driver,
                    `${driverField.componentProps.path}.aspects.availableValues`
                ).map((stateObj) => ({
                        code: stateObj.code,
                        name: translator({
                            id: stateObj.name,
                            defaultMessage: stateObj.name,
                        }),
                    }));
            }

            return (
                <DropdownSelectField
                    id={`${driverField.id}${column - 1}`}
                    alwaysShowPlaceholder={false}
                    placeholder=""
                    availableValues={availableOptions}
                    onValueChange={onChange}
                    value={value}
                    hideLabel
                    required={driverField.componentProps.required}
                    path={fieldPath}
                    readOnly={viewOnlyMode || readOnlyValue}
                    className={className}
                    defaultValue={defaultValue}
                    showErrors={showErrors}
                    showRequired
                    onValidationChange={setComponentValidation}
                    searchable={driverField.componentProps.searchable}
                />
            );
        }

        if (driverField.component === 'E1PDateComponent') {
            let updateDate;

            if (driverField.id === 'driverDateOfBirth') {
                updateDate = () => {
                    handleValueChange(
                        driver.dateOfBirth.value,
                        `[${column - 1}].dateOfBirth`
                    );
                };
            }

            return (
                <E1PDateComponent
                    id={`${driverField.id}${column - 1}`}
                    isRequired={driverField.componentProps.isRequired}
                    onValidate={setComponentValidation}
                    updateDateDto={updateDate}
                    dateDTO={driverField.componentProps.dateDTO}
                    showErrors={showErrors}
                    showRequired
                    onValidationChange={setComponentValidation}
                    maxDate={driverField.componentProps.maxDate}
                    minDate={driverField.componentProps.minDate}
                    readOnly={viewOnlyMode}
                />
            );
        }

        if (driverField.component === 'Toggle') {
            const onChange = handleValueChange;
            const value = driverField.componentProps.value ? driverField.componentProps.value : _get(driver, `${driverField.componentProps.path}.value`);
            const fieldPath = driverField.componentProps.path ? `[${column - 1}].${driverField.componentProps.path}` : undefined;
            // eslint-disable-next-line prefer-destructuring
            const defaultValue = driverField.componentProps.defaultValue;

            return (
                // To match all editable toggle field, show every toggle field on the page under same grid configuration
                <Grid tag="div" key={`${driverField.id}${column - 1}-grid`} columns={[
                    "3fr",
                    "1fr"
                ]}>
                    <ToggleField
                        id={`${driverField.id}${column - 1}`}
                        key={`${driverField.id}${column - 1}`}
                        value={value}
                        dataType="string"
                        controlClassName="toggleContainer"
                        availableValues={driverField.componentProps.availableValues}
                        onValueChange={onChange}
                        defaultValue={defaultValue}
                        path={fieldPath}
                        required={driverField.componentProps.required}
                        showErrors={showErrors}
                        showRequired
                        onValidationChange={setComponentValidation}
                        readOnly={viewOnlyMode}
                    />
                </Grid>)
        }

        return (
            <span>
                {row} {column}
            </span>
        );
    };

    const onRemoveVehicleOperator = useCallback(
        (evt) => {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: euCommonMessages.removeVehicleOperatorTitle,
                message: euCommonMessages.removeVehicleOperatorMessage,
                confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'VEHICLE OPERATOR' }),
                cancelButtonText: e1pCommonMessages.cancel
                
            }).then((result) => {
                if (result !== 'cancel') {
                    const vehicleOperatorsListPath = 'lobData.personalUmbrella_EU.vehicleOperators.value';
                    const vehicleOperatorPath = evt.path;
                    const vehicleOperator = _get(transactionVM, vehicleOperatorPath).value;
                    const vehicleOperatorList = _get(transactionVM, vehicleOperatorsListPath);
                    const totalDriversCount = (vehicleOperatorList.length) - 1;
                    const vehicleIndex = vehicleOperatorList.findIndex(
                        (exposure) => _isEqual(exposure, vehicleOperator)
                    );

                    vehicleOperatorList.splice(vehicleIndex, 1);
                    disregardFieldValidation(`euVehicleOperatorComponent${totalDriversCount}`);
                    disregardFieldValidation(`dateOfBirth${totalDriversCount}`);
                    updateWizardData(transactionVM);
                    setCheckScrolling(true);
                }
            }, () => { });
        },
        [translator, transactionVM, disregardFieldValidation, updateWizardData, setCheckScrolling, modalApi]
    );


    const removeVehicleOperator = (index) => {
        const evt = {
            path: `${path}[${index}]`,
        };

        onRemoveVehicleOperator(evt);
    };

    const getDriverName = (driver) => {
        let titleString = '';

        if (driver.firstName.value) {
            titleString = driver.firstName.value;
        }

        if (driver.middleName.value) {
            titleString = `${titleString} ${driver.middleName.value}.`;
        }

        if (driver.lastName.value) {
            titleString = `${titleString} ${driver.lastName.value}`;
        }

        if (driver.suffix.value?.code) {
            titleString = `${titleString}, ${translator({
                id: driver.suffix.value.name,
                defaultValue: driver.suffix.value.code,
            })}`;
        }

        return titleString === ''
            ? translator(euCommonMessages.newDriver)
            : titleString;
    };

    const COLUMNS = drivers.length + 1;
    const columnConfig = ['310px'];

    for (let column = 0; column < drivers.length; column++) {
        const columnWidthToPush = '300px'; // driver column with margin will come at 310

        columnConfig.push(columnWidthToPush);
    }

    const tableContent = [];
    const ROWS = driverLabels.length + 1;
    const numberOfCells = COLUMNS * ROWS;

    for (let cell = 0; cell <= numberOfCells - 1; cell++) {
        const rowNumber = Math.floor(cell / COLUMNS) + 1;
        const columnNumber = cell % COLUMNS;

        if (cell === 0) {
            // empty cell
            tableContent.push(<GridItem className="grid-item-sticky grid-item-label p-8" key={`quotename${cell}`} />)
        }
        // Driver Name
        else if (cell === columnNumber) {
            tableContent.push(
                <GridItem
                    tag="div"
                    key={`driverNameGridItem${cell}`}
                    className="grid-border-thin font-b"
                >
                    <Grid
                        tag="div"
                        key={`driverTitleHeader${cell}`}
                        columns={['14rem', '1rem']}
                        className="p-5"
                    >
                        <div
                            key={`driverLabelID${cell}`}
                            className="font-grid-title"
                        >
                            {getDriverName(drivers.children[cell - 1])}
                        </div>
                        {!viewOnlyMode ? (
                            <IconButton
                                id={`eaDeleteDriver${cell}`}
                                icon="mi-delete"
                                onClick={() =>
                                    removeVehicleOperator(columnNumber - 1)
                                }
                            />
                        ) : undefined}
                    </Grid>
                </GridItem>
            );
        }
        // first column - render row headers
        else if (cell % COLUMNS === 0) {
            let itemStyle = 'grid-item-label';

            // background style
            if (rowNumber % 2 === 0) {
                itemStyle += ' grid-item-color-even';
            }

            // last row of the grid
            if (rowNumber === ROWS) {
                itemStyle += ' grid-item-last';
            }

            const labelIndex = rowNumber - 2;

            tableContent.push(
                <GridItem
                    tag="div"
                    key={`driverLabel${cell}`}
                    className={itemStyle}
                >
                    {formatLabel(driverLabels[labelIndex])}
                </GridItem>
            );
        } else {
            let itemStyle = 'grid-item';

            // background style
            if (rowNumber % 2 === 0) {
                itemStyle += ' grid-item-color-even';
            }

            // last row of the grid
            if (rowNumber === ROWS) {
                itemStyle += ' grid-item-last';
            }

            const driverData = driverFields[columnNumber - 1];
            const driverField = driverData
                ? driverData[rowNumber - 2]
                : undefined;

            if (driverField !== undefined) {
                tableContent.push(
                    <GridItem
                        tag="div"
                        key={`driverDataCell${cell}`}
                        className={itemStyle}
                        id={`${driverField.id}${columnNumber - 1}`}
                    >
                        {formatField(rowNumber, columnNumber)}
                    </GridItem>
                );
            }
        }
    }

    return (
        <Grid className="mt-8" columns={columnConfig} hgap="small" vgap="none">
            {tableContent}
        </Grid>
    );
}

EUVehicleOperatorGridComponent.propTypes = {
    id: PropTypes.string,
    transactionVM: PropTypes.shape({}),
    drivers:  PropTypes.shape({}).isRequired,
    path: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    showErrors: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    setCheckScrolling: PropTypes.func.isRequired
};

export default EUVehicleOperatorGridComponent;
