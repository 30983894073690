import React, {
    useCallback, useContext, useEffect, useState, useMemo
} from 'react';
import {
    get as _get, set as _set, cloneDeep as _cloneDeep,
    findIndex as _findIndex, isEmpty as _isEmpty
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { RenewalService } from 'e1p-capability-renewal';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import {
    useNewBusinessUtil,
    useSniUtil
} from 'e1p-capability-hooks';
import messages from './InsuredDetailsPage.messages';
import wizardMessages from '../../EURenewalWizard.messages';
import metadata from './InsuredDetailsPage.metadata.json5';

const LOB = 'personalUmbrella_EU';

function InsuredDetailsPage(props) {
    const modalApi = useModal();
    const {
        initialValidation,
        isComponentValid,
        onValidate,
        disregardFieldValidation
    } = useValidation('InsuredDetailsPage');
    const {
        wizardData: renewalVM,
        updateWizardData,
        authUserData,
        isSkipping,
        jumpTo,
        updateWizardSnapshot,
        steps,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        onCustom2: handleDiscardChanges
    } = props;
    const translator = useTranslator();
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const isDiscardButtonActive = _get(renewalVM, 'value.canDiscard_Ext', false);
    const coveragesIndex = _findIndex(steps, ({ path }) => path === '/coverages');
    const changeSummaryIndex = _findIndex(steps, ({ path }) => path === '/change-review');
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');

    const {
        getLocationAndProducerCodes,
        getProducerDetailsFromProducerCodes,
        getProducerDetailsFromExternalId
    } = useNewBusinessUtil(
        renewalVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        setLocationAndProducerCodes
    );

    const { createSecondaryNamedInsuredVM, removeSni, addSniInCoverable } = useSniUtil(
        renewalVM,
        updateWizardData,
        viewModelService,
        LOB,
        disregardFieldValidation
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [renewalVM, isComponentValid, isPageSubmitted]);

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(renewalVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(renewalVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    useEffect(() => {
        // get available producer codes for given state
        const policyState = _get(renewalVM, 'baseData.policyAddress.state.value.code');

        if (policyState && isAgent) {
            // only for agents
            getAndSetLocationCodes(policyState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const isProducerCodeDisabled = useMemo(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(renewalVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(renewalVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);

            return true;
        }

        return false;
    }, [locationAndProducerCodes, renewalVM]);

    useEffect(() => {
        const {
            producerCode
        } = producerCodeDetails;
        const existingProducerCode = _get(
            renewalVM,
            'value.baseData.producerCode_Ext',
            renewalVM.value.baseData.producerCode
        );

        if (existingProducerCode && isAgent
            && existingProducerCode !== producerCode) {
            (async () => {
                // only for agents
                const details = await getProducerDetailsFromProducerCodes(
                    renewalVM.value.baseData.producerCode_Ext,
                    locationAndProducerCodes.producerCodes,
                    renewalVM.value.baseData.externalID_Ext
                );

                // in case we dont get any response from service
                if (details) {
                    setProducerCodeDetails(details);
                    setAgencyName(details.agencyName);
                    setBookTransferIndicator(details.bookTransferIndicator);
                    setServiceCenterIndicator(details.serviceCenterIndicatorValue);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renewalVM.baseData.producerCode_Ext.value]);

    const locationCodeChange = useCallback(
        (value) => {
            _set(renewalVM, 'baseData.externalID_Ext', value);

            const selectedProducerCode = locationAndProducerCodes?.producerCodes
                ?.find((producerCode) => producerCode?.externalId_Ext === value);

            _set(renewalVM, 'baseData.producerCode_Ext', selectedProducerCode?.code);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData, locationAndProducerCodes]
    );

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, address, phone, externalID, name, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            _set(renewalVM, 'baseData.value.producerCode_Ext', producerCode);
            _set(renewalVM, 'baseData.externalID_Ext.value', externalID);
            updateWizardData(renewalVM);
            setProducerCodeDetails({ producerCode, address, phone });
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [renewalVM, updateWizardData],
    );

    const generateOverrides = useCallback(() => {
        const overrides = {};

        overrides.secondaryNamedInsured = {
            hideSSN: true
        };

        overrides.primaryNamedInsured = {
            hideSSN: true,
            isPNI: true

        };

        return overrides;
    }, []);

    const addNewPrimaryNamedInsured = useCallback(() => {
        const primaryNamedInsuredObj = {
            person: {
                lastName: '',
                firstName: '',
                dateOfBirth: {
                    year: undefined,
                    month: undefined,
                    day: undefined
                },
                // save primary address
                primaryAddress: (() => {
                    // default new person address to prior pni
                    const priorPniAddress = _get(
                        renewalVM,
                        `lobData[${LOB}].primaryNamedInsured.person.primaryAddress.value`
                    );

                    // Needs new public ID to make it a new adress in the DB
                    _set(priorPniAddress, 'publicID', undefined);

                    return priorPniAddress;
                })()
            }
        };
        const { _dtoName, _xCenter } = renewalVM.lobData[LOB].primaryNamedInsured;
        const primaryNamedInsuredVM = viewModelService.create(
            primaryNamedInsuredObj,
            _xCenter,
            _dtoName
        );

        _set(
            renewalVM,
            `lobData[${LOB}].primaryNamedInsured.value`,
            primaryNamedInsuredVM.value
        );
        updateWizardData(renewalVM);
    }, [renewalVM, updateWizardData, viewModelService]);

    /**
    * Setting mailingAddress and billingAddressValues based on indicators
    */
    const setCorrectAddressValues = useCallback(
        () => {
            // if mailingAddressSameInd is true then set policyAddress value to mailingAddress
            if (
                _get(
                    renewalVM,
                    'baseData.isMailingAddressSame_Ext.value'
                )
            ) {
                _set(
                    renewalVM,
                    'baseData.policyMailingAddress_Ext.value',
                    _get(renewalVM, 'baseData.policyAddress.value')
                );
            }

            //  if billingAddressSameInd is true then set mailingAddress value to billingAddress
            if (
                _get(
                    renewalVM,
                    'baseData.isBillingAddressSame_Ext.value'
                )
            ) {
                _set(
                    renewalVM,
                    'baseData.policyBillingAddress_Ext.value',
                    _get(renewalVM, 'baseData.policyMailingAddress_Ext.value')
                );
            }
        }, [renewalVM]
    );

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);
            setCorrectAddressValues();
            // Need this for validation rules
            _set(renewalVM, 'flowStepIDs_Ext.value', ['namedinsured']);
            _set(renewalVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);

            const saveResponse = await RenewalService.saveRenewal(
                [renewalVM.value],
                authHeader
            );

            _set(renewalVM, 'value', saveResponse);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);
            //   sni exist or not is being checked in the method itself, so no need to check here
            addSniInCoverable();

            return renewalVM;
        },
        [
            addSniInCoverable,
            authHeader,
            isComponentValid,
            renewalVM,
            setCorrectAddressValues,
            updateWizardData
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = _get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    const handleAddressValueChange = useCallback(
        (newVal, localPath) => {
            _set(renewalVM, localPath, newVal);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData]
    );

    useEffect(() => {
        // keeping the IF condition here, but under no circumstances should it be undefined
        if (renewalVM.baseData.externalID_Ext?.value) {
            (async () => {
                const details = await getProducerDetailsFromExternalId(
                    renewalVM.baseData.externalID_Ext.value
                );

                setProducerCodeDetails(details);
            })();
        }

        // we want to make each step as visited so that user
        // can navigate to any page by clicking on tabs on chevron
        if (isPageJumpEnabled) {
            updateIsPageJumpEnabled(false);
            jumpTo(coveragesIndex, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const swapPniSni = useCallback(() => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: e1pCommonMessages.swapPniSniTitle,
            message: e1pCommonMessages.swapPniSniMessage,
            confirmButtonText: e1pCommonMessages.confirm,
            cancelButtonText: e1pCommonMessages.cancel
        }).then(
            (result) => {
                if (result !== 'cancel') {
                    const pniPerson = _cloneDeep(
                        renewalVM.lobData[LOB].primaryNamedInsured.person.value
                    );
                    const sniPerson = _cloneDeep(
                        renewalVM.lobData[LOB].secondaryNamedInsured.person.value
                    );

                    renewalVM.lobData[LOB].primaryNamedInsured.person.value = sniPerson;
                    renewalVM.lobData[LOB].secondaryNamedInsured.person.value = pniPerson;
                    // since we are swapping PNI and SNI, we need remove publicID,
                    // FixedID and integrationID from both policyContactRoles
                    // i.e PNI and SNI
                    _set(renewalVM, `lobData[${LOB}].primaryNamedInsured.fixedId.value`, undefined);
                    _set(renewalVM, `lobData[${LOB}].primaryNamedInsured.publicID.value`, undefined);
                    _set(renewalVM, `lobData[${LOB}].primaryNamedInsured.integrationId.value`, undefined);
                    _set(renewalVM, `lobData[${LOB}].secondaryNamedInsured.fixedId.value`, undefined);
                    _set(renewalVM, `lobData[${LOB}].secondaryNamedInsured.publicID.value`, undefined);
                    _set(renewalVM, `lobData[${LOB}].secondaryNamedInsured.integrationId.value`, undefined);

                    // If paperless Email id exist and pni email id is undefiend
                    // then update pni email to paperless email
                    if (!_get(renewalVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value')
                        && !!_get(renewalVM, 'lobData.personalUmbrella_EU.paperlessEmail.value')) {
                        _set(renewalVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value',
                            _get(renewalVM, 'lobData.personalUmbrella_EU.paperlessEmail.value'));
                    }

                    updateWizardData(renewalVM);
                }
            },
            () => { }
        );
    }, [renewalVM, modalApi, updateWizardData]);

    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            _set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setIsSavingRenewal(false);

            return false;
        },
        [
            authHeader,
            changeSummaryIndex,
            isComponentValid,
            jumpTo,
            renewalVM,
            updateWizardData,
            updateWizardSnapshot
        ]
    );

    const getLoadingMessage = useCallback(() => {
        let loadingMessage = translator(messages.loadingNextPageMessage);

        if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isStandardizingAddress) {
            loadingMessage = translator(messages.standardizingAddressMessage);
        }

        return loadingMessage;
    }, [isSavingCurrentPageChanges, isStandardizingAddress, translator]);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        insuredDetailsPageLoadingIndicator: {
            loaded:
                !isStandardizingAddress
                && !isSavingRenewal
                && !isSkipping
                && !isSavingCurrentPageChanges,
            text: getLoadingMessage()
        },
        insuredDetailsPageContainer: {
            visible:
                !isStandardizingAddress
                && !isSavingRenewal
                && !isSkipping
                && !isSavingCurrentPageChanges
        },
        periodStartDate: {
            updateDateDto: () => updateWizardData(renewalVM),
            dateDTO: renewalVM.baseData.periodStartDate,
        },
        periodEndDate: {
            dateDTO: renewalVM.baseData.periodEndDate,
            updateDateDto: () => updateWizardData(renewalVM),
        },
        policyInformationInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        sniContainer: {
            visible: !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        removeSni: {
            visible: !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        addSni: {
            visible: !_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        swapPniSni: {
            visible: !!_get(renewalVM, `value.lobData[${LOB}].secondaryNamedInsured.person.publicID`)
                && !!_get(renewalVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`)
        },
        changePniComponent: {
            accountNumber: _get(renewalVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(renewalVM, 'baseData.accountNumber.value'),
            excludedContacts: [
                _get(renewalVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            showErrors: isPageSubmitted
        },
        changeSniComponent: {
            accountNumber: _get(renewalVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(renewalVM, 'baseData.accountNumber.value')
                && !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            excludedContacts: [
                _get(renewalVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            showErrors: isPageSubmitted
        },
        mailingAndBillingAddressComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            showErrors: isPageSubmitted
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        policyType: {
            value: 'Umbrella'
        },
        insuredResidenceAddress: {
            addressVM: _get(renewalVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate,
            onAddressChange: (value, path) => handleAddressValueChange(value, `baseData.policyAddress.${path}`),
            viewOnlyMode: true,
            showParentLoader: setIsStandardizingAddress
        },
        internalProducerDetailsComponent: {
            defaultValue: renewalVM.baseData.externalID_Ext?.value,
            visible: !isAgent
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            disabled: isProducerCodeDisabled,
            onValueChange: locationCodeChange,
            visible: isAgent
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        showErrors: isPageSubmitted,
        agencyInformationMainDiv: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: renewalVM,
            authHeader,
            LOB
        },
        renewalTermEffectiveDate: {
            dateDTO: renewalVM.baseData.effectiveDate,
            updateDateDto: () => {},
            readOnly: true,
            showErrors: isPageSubmitted,
            onValidate
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate,
            onRemoveSni: removeSni,
            onAddNewPni: addNewPrimaryNamedInsured,
            onSwapPniSni: swapPniSni,
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    const readValue = (id, path) => readViewModelValue(metadata.pageContent, renewalVM, id, path, overrideProps);

    return (
        <WizardPage
            onNext={onNext}
            showRenewalPrevious={false}
            skipWhen={initialValidation}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            onSave={onSave}
            showOnSave
            showPrevious={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

InsuredDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(InsuredDetailsPage);
