import { defineMessages } from 'react-intl';

export default defineMessages({
    firstNameIncludeLettersAndAllowedPersonCharacter: {
        id: 'e1pPortalUtils.personNameValidator.firstNameIncludeLettersAndAllowedPersonCharacter.{name} : First Name should only include letters and the special characters Hyphen (-) or Apostrophe (\') and space',
        defaultMessage: '{name} : First Name should only include letters and the special characters Hyphen (-) or Apostrophe (\') and space',
    },
    firstNameShouldNotIncludeAndOrOrWords: {
        id: 'e1pPortalUtils.personNameValidator.firstNameShouldNotIncludeAndOrOrWords.{name} : First Name should not include the words "and" or "or"',
        defaultMessage: '{name} : First Name should not include the words "and" or "or"',
    },
    lastNameIncludeLettersAndAllowedPersonCharacter: {
        id: 'e1pPortalUtils.personNameValidator.lastNameIncludeLettersAndAllowedPersonCharacter.{name} : Last Name should only include letters and the special characters Hyphen (-) or Apostrophe (\') and space',
        defaultMessage: '{name} : Last Name should only include letters and the special characters Hyphen (-) or Apostrophe (\') and space',
    },
    lastNameShouldNotIncludeAndOrOrWords: {
        id: 'e1pPortalUtils.personNameValidator.lastNameShouldNotIncludeAndOrOrWords.{name} : Last Name should not include the words "and" or "or"',
        defaultMessage: '{name} : Last Name should not include the words "and" or "or"',
    },
    firstNameAtLeastOneLetter: {
        id: 'e1pPortalUtils.personNameValidator.firstNameAtLeastOneLetter.{name} : First Name requires at least one letter',
        defaultMessage: '{name} : First Name requires at least one letter',
    },
    lastNameAtLeastOneLetter: {
        id: 'e1pPortalUtils.personNameValidator.lastNameAtLeastOneLetter.{name} : Last Name requires at least one letter',
        defaultMessage: '{name} : Last Name requires at least one letter',
    },
    firstNameShouldNotIncludeEstateOrTrustWords: {
        id: 'e1pPortalUtils.personNameValidator.firstNameShouldNotIncludeEstateOrTrustWords.{name} : First Name should not include the words "Estate" or "Trust" with spaces before or after',
        defaultMessage: '{name} : First Name should not include the words "Estate" or "Trust" with spaces before or after',
    },
    lastNameShouldNotIncludeEstateOrTrustWords: {
        id: 'e1pPortalUtils.personNameValidator.lastNameShouldNotIncludeEstateOrTrustWords.{name} : Last Name should not include the words "Estate" or "Trust" with spaces before or after',
        defaultMessage: '{name} : Last Name should not include the words "Estate" or "Trust" with spaces before or after',
    },
    lastNameShouldNotIncludeNLNNWord: {
        id: 'e1pPortalUtils.personNameValidator.lastNameShouldNotIncludeNLNNWord.{name} : Last Name should not include the letters NLN unless embedded in a word',
        defaultMessage: '{name} : Last Name should not include the letters NLN unless embedded in a word',
    },
    middleNameShouldBeALetter: {
        id: 'e1pPortalUtils.personNameValidator.middleNameShouldBeALetter.{name} : Middle Initial should be a letter.',
        defaultMessage: '{name} : Middle Initial should be a letter.',
    },
});
