import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '@jutro/layout';
import { uniqBy as _uniqBy } from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import EASingleClauseComponentVM from '../Clauses/SingleClauseComponentVM';
import messages from './EaSideBySidePolicyLevelComponent.messages';
import styles from './EaSideBySidePolicyLevelComponent.module.scss';

function EaSideBySidePolicyLevelComponent(props) {
    const {
        lobOfferings,
        onChangeSubmissionAndSync,
        onChangeSubmission,
        onSyncCoverages,
        onValidate,
        hasExtendedAttributeEditability,
        policyState,
        quoteDataOfferings,
        showErrors
    } = props;

    const translator = useTranslator();
    const [isLoading, setIsLoading] = useState(undefined);
    const id = 'EaSideBySidePolicyLevelComponent';
    const { isComponentValid, onValidate: setIsComponentValidate } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, lobOfferings, onValidate, isComponentValid]);

    /** Callback from main quote page */
    const syncCoverages = useCallback((value, path, lobPath, quotePath, clauseID) => {
        setIsLoading(clauseID);

        if (onSyncCoverages) {
            onSyncCoverages(value, path, lobPath, quotePath).then(() => {
                setIsLoading(undefined);
            });
        }
    }, [onSyncCoverages]);

    /** Callback from main quote page */
    const changeSubmissionAndSync = useCallback((value, path, lobPath, quotePath, clauseID) => {
        setIsLoading(clauseID);

        if (onChangeSubmissionAndSync) {
            return Promise.resolve(onChangeSubmissionAndSync(value, path, lobPath, quotePath).then(() => {
                setIsLoading(undefined);
            }));
        }
    }, [onChangeSubmissionAndSync]);

    /**
     * Will return unique set of line coverage across the versions 
     * @returns {Array} list of objects - cov public ids
     */
    const getAllLineCoverage = useCallback(() => {
        const lineCoverages = _uniqBy(lobOfferings.flatMap((offering) => (
            offering.coverages.lineCoverages.filter((lineCoverage) => !lineCoverage.isHiddenInVersion)
        )), 'publicID');

        return lineCoverages;
    }, [lobOfferings]);

    const COLUMNS = lobOfferings.length + 1;
    const columnConfig = [];

    for (let column = 0; column < COLUMNS; column++) {
        let columnWidthToPush = '320px';

        if (column === 0) {
            columnWidthToPush = '210px';
        }

        columnConfig.push(columnWidthToPush);
    }

    const cslCoverageCodes = ['EA_CombinedSingleLimitCoverageBundle', 'EA_SplitLimitCoverageBundle'];
    // don't want to manipulate the actual lob data so making a copy
    const lineCovs = getAllLineCoverage()
        // filter out CSL
        .filter((coverage) => !cslCoverageCodes.includes(coverage.codeIdentifier));
    const rowHeaders = [''] // Need first cell to be empty
        .concat(lineCovs);
    const ROWS = rowHeaders.length;
    const numberOfCells = COLUMNS * ROWS;
    const tableContent = [];

    for (let cell = 0; cell <= numberOfCells - 1; cell++) {
        // There will always be 3 rows based on mock,
        //   but cols differ based on # of versions
        const rowNumber = Math.floor(cell / COLUMNS) + 1;
        const columnNumber = cell % COLUMNS;
        const OFFSET = 1; // array starts at 0 and first element is a header
        const offeringIndex = columnNumber - OFFSET;
        const path = `lobData.personalAuto_EA.offerings.children[${offeringIndex}].coverages.lineCoverages`;

        // first column - render row headers
        if (cell === 0 || (cell % COLUMNS === 0)) {
            tableContent.push
            (<GridItem
                className={rowNumber === ROWS ? styles.qDetailCellBottomLeftHeader : styles.qDetailCellLeftHeader}
                tag="div" key={`lineLevelGridItem${cell}`}>
                <span key={`lineLevelCell${cell}`}>
                    {rowHeaders.shift().name}
                </span>
            </GridItem>)
        } else if (rowNumber === 1) {
            tableContent.push(
                <span key={`quotename${cell}`} className={styles.qDetailCellVersionName}>
                    {lobOfferings[cell - 1].branchName}
                </span>
            );
        } else {
            const covHeader = lineCovs[rowNumber - 2];
            const clause = lobOfferings[offeringIndex].coverages.lineCoverages.find(
                (coverage) => coverage.publicID === covHeader.publicID
            );
            const clauseIndex = lobOfferings[offeringIndex].coverages.lineCoverages.findIndex(
                (coverage) => coverage.publicID === covHeader.publicID
            );

            if (clause) {
                const currentQuoteOffering = quoteDataOfferings
                    .find((quoteOffering) => quoteOffering.branchCode === lobOfferings[offeringIndex].branchCode);
                const isCurrentOfferingNotQuoted = currentQuoteOffering.status !== 'Quoted';

                tableContent.push
                (<GridItem
                    tag="div" key={`quotecell${cell}`}
                    className={rowNumber === ROWS ? styles.qDetailCellBottom : styles.qDetailCell}>
                    <EASingleClauseComponentVM
                        value={clause}
                        path={`${path}.children[${clauseIndex}]`}
                        onChangeClause={onChangeSubmission}
                        onSyncCoverages={(value, termPath) => {
                            syncCoverages(
                                value, termPath, `lobData.personalAuto_EA.offerings.children[${offeringIndex}]`,
                                `quoteData.offeredQuotes.children[${offeringIndex}]`, clause.publicID
                            )
                        }}
                        onChangeSubmissionAndSync={(value, termPath) => {
                            changeSubmissionAndSync(
                                value, termPath, `lobData.personalAuto_EA.offerings.children[${offeringIndex}]`,
                                `quoteData.offeredQuotes.children[${offeringIndex}]`, clause.publicID
                            )
                        }}
                        loadingClause={isLoading}
                        idPrefex={clause.publicID}
                        labelTop
                        onValidate={setIsComponentValidate}
                        isQuoteStale={isCurrentOfferingNotQuoted}
                        hasExtendedAttributeEditability={hasExtendedAttributeEditability}
                        policyState={policyState}
                        showErrors={showErrors}
                    />
                </GridItem>)
            } else {
                tableContent.push(
                    <GridItem
                        tag="div" key={`quotecell${cell}`}
                        className={rowNumber === ROWS ? styles.qDetailCellBottom : styles.qDetailCell}>
                        <span className={styles.noCoverageText}>{translator(messages.notApplicable)}</span>
                    </GridItem>
                )
            }

        }
    }

    return (
        <div className={styles.tableSpacingAbove}>
            <h4>{translator(messages.policyLevelCoverages)}</h4>
            <Grid className={styles.tableSpacingAbove} columns={columnConfig} gap="none">
                {tableContent}
            </Grid>
        </div>
    );
}

EaSideBySidePolicyLevelComponent.propTypes = {
    lobOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    quoteDataOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    policyState: PropTypes.string.isRequired,
    hasExtendedAttributeEditability: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    showErrors: PropTypes.bool
};

EaSideBySidePolicyLevelComponent.defaultProps = {
    showErrors: false,
    hasExtendedAttributeEditability: undefined
};

export default EaSideBySidePolicyLevelComponent;
