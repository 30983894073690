import messages from './PersonNameValidator.messages';

/**
 * Checks whether given string contains any character other than
 * letter, space, hyphen,apostrophe or not
 * @param {String} name
 * @returns {Boolean}
 */
const validateForOnlyLettersAndAllowedSpecialCharacters = (name) => {
    // May get used in future
    // const regex = new RegExp('^[a-zA-Z]([a-zA-Z- \']*[a-zA-Z])?$');
    const regex = new RegExp('^[a-zA-Z- \']+$');
    return regex.test(name);
};

/**
 * Checks whether given string contains at least one letter or not
 * @param {String} name
 * @returns {Boolean}
 */
const validateForAtLeastOneLetter = (name) => {
    const regex = new RegExp('.*[a-zA-Z]+.*');
    return regex.test(name);
};

/**
 * Checks whether given string contains only letters
 * @param {String} name
 * @returns {Boolean}
 */
const validateForOnlyLetters = (name) => {
    const regex = new RegExp('^[a-zA-Z]+$');
    return regex.test(name);
};

/**
 * Checks whether given word contains in the string or not
 * @param {String} name
 * @param {String} word
 * @returns {Boolean}
 */
const validateForParticularWord = (name, word) => {
    const upperCaseFirstName = name.toUpperCase();
    const wordUpperCase = word.toUpperCase();
    return upperCaseFirstName.includes(` ${wordUpperCase} `)
        || upperCaseFirstName.startsWith(`${wordUpperCase} `)
        || upperCaseFirstName.endsWith(` ${wordUpperCase}`)
        || upperCaseFirstName === wordUpperCase;
};

/**
 * Checks whether given string contains "and" or "or" words
 * @param {String} name
 * @returns{Boolean}
 */
const validateForAndOrOrWords = (name) => {
    return validateForParticularWord(name, 'and') || validateForParticularWord(name, 'or');
};

/**
 * Checks whether given string contains "estate" or "trust" words
 * @param {String} name
 * @returns{Boolean}
 */
const validateForEstateOrTrustWords = (name) => {
    return validateForParticularWord(name, 'Estate') || validateForParticularWord(name, 'Trust');
};

/**
 * Checks whether given string contains "NLN" word
 * @param {String} name
 * @returns{Boolean}
 */
const validateForNLNWord = (name) => {
    return validateForParticularWord(name, 'NLN');
};

/**
 * Returns validation message to indicate name should contain only
 * letters and allowed special characters
 * @param {String} name
 * @param {Boolean} isValidatingFirstName
 * @param {Function} translator
 * @returns {[{String, String}]}
 */
const getIncludeLettersAndAllowedPersonCharacterValidationMessage = (name,
    isValidatingFirstName, translator) => {
    const includeLettersAndAllowedPersonCharacter = isValidatingFirstName
        ? messages.firstNameIncludeLettersAndAllowedPersonCharacter
        : messages.lastNameIncludeLettersAndAllowedPersonCharacter;
    return [
        translator(
            includeLettersAndAllowedPersonCharacter,
            { name: name }
        )
    ];
};

/**
 * Returns validation message to indicate name should contain at least one letter
 * @param {String} name
 * @param {Boolean} isValidatingFirstName
 * @param {Function} translator
 * @returns {[{String, String}]}
 */
const getAtLeastOneLetterValidationMessage = (name,
    isValidatingFirstName, translator) => {
    const atLeastOneLetterValidationMessage = isValidatingFirstName
        ? messages.firstNameAtLeastOneLetter
        : messages.lastNameAtLeastOneLetter;
    return [
        translator(
            atLeastOneLetterValidationMessage,
            { name: name }
        )
    ];
};

/**
 * Returns validation message to indicate name should not contain "and" or "or" words
 * @param {String} name
 * @param {Function} translator
 * @returns {[{String}]}
 */
const getShouldNotIncludeAndOrOrWordsValidationMessage = (name,
    translator) => {
    return [
        translator(
            messages.firstNameShouldNotIncludeAndOrOrWords,
            { name: name }
        )
    ];
};

/**
 * Returns validation message to indicate name should not contain "estate" or "trust" words
 * @param {String} name
 * @param {Boolean} isValidatingFirstName
 * @param {Function} translator
 * @returns {[{String}]}
 */
const getShouldNotIncludeEstateOrTrustWordsValidationMessage = (name,
    isValidatingFirstName, translator) => {
    const shouldNotIncludeEstateOrTrustWords = isValidatingFirstName
        ? messages.firstNameShouldNotIncludeEstateOrTrustWords
        : messages.lastNameShouldNotIncludeEstateOrTrustWords;
    return [
        translator(
            shouldNotIncludeEstateOrTrustWords,
            { name: name }
        )
    ];
};

/**
 * Returns validation message to indicate name should not contain NLN word
 * @param {String} name
 * @param {Function} translator
 * @returns {[{String}]}
 */
const getShouldNotIncludeNLNNWordValidationMessage = (name, translator) => {
    return [
        translator(
            messages.lastNameShouldNotIncludeNLNNWord,
            { name: name }
        )
    ];
};

/**
 * Returns validation message to indicate name should not contain only words
 * @param {String} name
 * @param {Function} translator
 * @returns {[{String}]}
 */
const getShouldIncludeOnlyLettersValidationMessage = (name, translator) => {
    return [
        translator(
            messages.middleNameShouldBeALetter,
            { name: name }
        )
    ];
};

/**
 * Returns validation message based on validation issue
 * @param {String} name
 * @param {Boolean} isValidatingFirstName
 * @param {Function} translator
 * @returns {[{String}]}
 */
const getNameValidationMessage = (name, isValidatingFirstName, translator) => {
    if (!validateForOnlyLettersAndAllowedSpecialCharacters(name)) {
        return getIncludeLettersAndAllowedPersonCharacterValidationMessage(
            name, isValidatingFirstName, translator
        );
    }
    if (!validateForAtLeastOneLetter(name)) {
        return getAtLeastOneLetterValidationMessage(
            name, isValidatingFirstName, translator
        );
    }
    if (validateForEstateOrTrustWords(name)) {
        return getShouldNotIncludeEstateOrTrustWordsValidationMessage(
            name, isValidatingFirstName, translator
        );
    }
    return null;
};

/**
 * Returns validation messages based on validation issue for First Name
 * @param {String} name
 * @param {Boolean} isValidatingFirstName
 * @param {Function} translator
 * @returns {[{String}]}
 */
const getFirstNameValidationMessage = (name, isValidatingFirstName, translator) => {
    if (validateForAndOrOrWords(name)) {
        return getShouldNotIncludeAndOrOrWordsValidationMessage(
            name, translator
        );
    }
    return getNameValidationMessage(name, isValidatingFirstName, translator);
};

/**
 * Returns validation messages based on validation issue for Last Name
 * @param {String} name
 * @param {Boolean} isValidatingFirstName
 * @param {Function} translator
 * @returns {[{String}]}
 */
const getLastNameValidationMessage = (name, isValidatingFirstName, translator) => {
    if (validateForNLNWord(name)) {
        return getShouldNotIncludeNLNNWordValidationMessage(
            name, translator
        );
    }
    return getNameValidationMessage(name, isValidatingFirstName, translator);
};

/**
 * Returns validation messages based on validation issue for Middle Name
 * @param {String} name
 * @param {Function} translator
 * @returns {[{String}]}
 */
const getMiddleNameValidationMessage = (name, translator) => {
    if (!validateForOnlyLetters(name)) {
        return getShouldIncludeOnlyLettersValidationMessage(
            name, translator
        );
    }
    return null;
};

/**
 * Validates first name and returns validation message based on validation issue
 * @param {String} name
 * @param {Function} translator
 * @returns {[{String}]}
 */
const validateFirstName = (name, translator) => {
    if (!name || name === '') {
        return null;
    }
    return getFirstNameValidationMessage(name, true, translator);
};

/**
 * Validates last name and returns validation message based on validation issue
 * @param {String} name
 * @param {Function} translator
 * @returns {[{String}]}
 */
const validateLastName = (name, translator) => {
    if (!name || name === '') {
        return null;
    }
    return getLastNameValidationMessage(name, false, translator);
};

/**
 * Validates middle name and returns validation message based on validation issue
 * @param {String} name
 * @param {Function} translator
 * @returns {[{String}]}
 */
const validateMiddleName = (name, translator) => {
    if (!name || name === '') {
        return null;
    }
    return getMiddleNameValidationMessage(name, translator);
};

export default { validateFirstName, validateLastName, validateMiddleName };
