import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, set, isEqual
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from '../VehiclesPage.metadata.json5';
import messages from '../VehiclesPage.messages';

function ViewVehiclesPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [pageInitialized, setIsPageInitialized] = useState(false);
    const [availableMakes, setAvailableMakes] = useState({});
    const [availableModels, setAvailableModels] = useState({});
    const [availableSeries, setAvailableSeries] = useState({});
    const {
        onValidate,
    } = useValidation('ViewVehiclePage');
    const [checkScrolling, setCheckScrolling] = useState(false);

    policyChangeVM.flowStepIDs_Ext.value = ['vehicle'];
    policyChangeVM.entryCompletionStepIDs_Ext.value = ['vehicle'];

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(() => policyChangeVM, [policyChangeVM]);

    /**
     * Helper effect for handling the component initialization logic.
     */
    useEffect(() => {
        if (!pageInitialized) {
            const vehiclesPath = 'lobData.personalAuto_EA.coverables.vehicles';
            const vehicles = get(policyChangeVM, `${vehiclesPath}.value`);

            const newVehicles = vehicles.map((vehicle) => {
                const newVehicle = {
                    ...vehicle,
                    vin: vehicle.vin,
                    costNew: vehicle.costNew
                };
                
                return newVehicle;
            });

            // Update the submission VM with new list of vehicles
            if (!isEqual(newVehicles, vehicles)) {
                set(policyChangeVM, `${vehiclesPath}.value`, newVehicles);
                updateWizardData(policyChangeVM);
            }

            setIsPageInitialized(true);
        }
    }, [pageInitialized, policyChangeVM, updateWizardData]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            readOnly: true
        },
        vehiclesPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.loadingNextPageMessage)
        },
        vehicleLookupErrorNotificationDiv: {
            visible: false
        },
        vehiclesPageContainer: {
            visible: true
        },
        eaAddAnotherVehicleButtonContainer: {
            visible: false
        },
        vehicleInformationSubHeaderID: {
            visible: false
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('eavehiclecontainerID')
        },
        oneCarMinimumNotificationDiv: {
            visible: false
        },
        vehicleInfoChangedMessageDiv: {
            visible: false
        },
        EAVehicleGrid: {
            vehicles: get(policyChangeVM, 'lobData.personalAuto_EA.coverables.vehicles', []),
            path: 'lobData.personalAuto_EA.coverables.vehicles.children',
            onValidate,
            viewModelService,
            showErrors: false,
            policyState: get(policyChangeVM, 'baseData.policyAddress.state.value.code'),
            vehiclePageDisregardFieldValidation: () => {},
            policyChangeVM,
            onValidationChange: onValidate,
            transactionVM: policyChangeVM,
            updateWizardData,
            setCheckScrolling,
            addVehicleVM: () => {},
            viewOnlyMode: true,
            availableMakes,
            setAvailableMakes,
            availableModels,
            setAvailableModels,
            availableSeries,
            setAvailableSeries
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [policyChangeVM, overrideProps]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onAddVehicleClick: () => { },
            onAddAdditionalInterest: () => { },
            onRemoveVehicle: () => { },
            onValidate,
            onPrefillData: undefined,
            onGaragingAddressChange: () => { },
            onVehicleLookupError: () => { },
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewVehiclesPage.propTypes = wizardProps;
export default ViewVehiclesPage;
