import React, { useEffect, useState, useCallback } from 'react';
import {
    get as _get,
    includes as _includes,
    isEmpty as _isEmpty,
    noop as _noop,
    set as _set,
    toLower as _toLower
} from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { Wizard } from 'e1p-portals-wizard-react';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { OnPageOperationsUtil } from 'e1p-portals-util-js';
import { useModal } from '@jutro/components';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useSubmissionCreation } from 'e1p-capability-hooks';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { error as loggerError } from '@jutro/logger';
import unverifiedWizardConfig from './config/unverified/ea-wizard-config.json5';
import wizardStepToFieldMapping from './config/common/ea-wizard-step-to-field-mapping.json5';
import verifiedWizardConfig from './config/verified/ea-wizard-config.json5';
import verifiedViewWizardConfig from './config/view-only/verified/ea-wizard-config.json5';
import unVerifiedViewWizardConfig from './config/view-only/unverified/ea-wizard-config.json5';
import messages from './PEEAWizard.messages';

function PEEAWizard(props) {
    const modalApi = useModal();
    const [wizardConfig, setWizardConfig] = useState(unverifiedWizardConfig);
    const { steps, title } = wizardConfig;
    const { authHeader } = useAuthentication();
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [transitionSubmission, setTransitionSubmission] = useState({});
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isTransitioningToFull, setIsTransitioningToFull] = useState(false);
    const [viewOnly, setViewOnly] = useState(false);
    const { viewModelService, history, location } = props;
    const { onPageCancel, onPagePrevious } = OnPageOperationsUtil();
    const parsedParameters = queryString.parse(location.search);
    // const [policyType, setPolicyType] = useState(parsedParameters.policyType);
    const { initializeSubmission } = useSubmissionCreation(
        parsedParameters,
        authHeader,
        viewModelService,
        setInitialSubmission,
        location,
        setIsLoading,
        history
    );

    // Initial submission is required to set the wizard steps,
    useEffect(() => {
        const getInitialSubmission = async () => {
            let tempSubmission = {};

            if (!isTransitioningToFull) {
                tempSubmission = await initializeSubmission();
            } else if (!_isEmpty(transitionSubmission)) {
                _set(transitionSubmission, 'transitioning', true);
                setInitialSubmission(transitionSubmission);
                tempSubmission = transitionSubmission;
                setWizardConfig(verifiedWizardConfig);
            }
            
            return tempSubmission;
        };
        // get the submission data first before setting wizard steps

        getInitialSubmission().then((submission) =>{
            if (location.viewOnly) {
                let viewWizardConfig = verifiedViewWizardConfig;
                
                if (_get(submission, 'value.quoteType') === 'Quick'){
                    viewWizardConfig = unVerifiedViewWizardConfig;
                }

                setWizardConfig(viewWizardConfig);
                setViewOnly(true);
            } else if (_get(submission, 'value.quoteType') === 'Full') {
                setWizardConfig(verifiedWizardConfig);
                // isSkpping prop is taking care of loading indicator on Policy Details Page
                _set(submission, 'transitioning', true);
                setInitialSubmission(submission);
            }

        }).finally(()=>{
            setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTransitioningToFull, transitionSubmission]
    );


    const handleFinish = useCallback(
        ({ wizardData }) => {
            if (_get(wizardData, 'value.baseData.periodStatus') === 'Bound') {
                // Back to policy on finish of view only
                const policyNumber = _get(wizardData, 'value.policyNumber');

                history.push(`/policies/${policyNumber}/summary`);
            } else {
                setIsLoading(true);
                history.push({
                    pathname: '/quote-ea',
                    search: '?productCode=PersonalAuto_EA&policyType=EAFamilyCar',
                    isRouteChangedDuringFlow: true
                });
                setIsTransitioningToFull(true);
                setTransitionSubmission(wizardData);
            }
        },
        [history]
    );

    const handleCancel = useCallback(
        ({ wizardSnapshot, param = {}, wizardData }) => {
            const { search, pathname: nextPath = '' } = param;
            const skipModal = ['knockoutpage', 'logout', 'summary'];
            const lastPathName = nextPath.replace(/\/?.*\//g, '');
            const isStatusBound = _get(wizardSnapshot, 'baseData.periodStatus.value.code') === 'Bound';
            const isCreatingNewQuote = nextPath?.includes('/quote-');

            if (
                (isStatusBound && !isCreatingNewQuote)
                || skipModal.includes(lastPathName)
                || hasErrorOccurred
                || (viewOnly && !isCreatingNewQuote)
            ) {
                return true;
            }

            // When user tries to create new quote from submission view flow for same lob
            if (viewOnly && isCreatingNewQuote) {
                return Promise.resolve(history.push({
                    pathname: '/new-quote-redirect',
                    search,
                    originalPath: nextPath
                }));
            }

            return Promise.resolve(onPageCancel(wizardData.value, wizardSnapshot.value)
                .then((popupResult) => {
                    if (!popupResult || popupResult === 'cancel') {
                        return _noop();
                    }

                    setIsLoading(true);

                    const isSubmissionExisting = _get(
                        wizardSnapshot,
                        'baseData.periodStatus.value.code'
                    );
                    const redirectPath = isSubmissionExisting
                        ? `/quotes/${_get(wizardData, 'value.quoteID', '')}/summary`
                        : '/';

                    if (nextPath?.includes('/quote-')) {
                        /**
                         * IAP-336
                         * When user tries to create new quote from existing quote flow
                         * If user tries for same lob then as url is same we wont mount
                         * specific lob wizard again so to do that redirecting to dummy url
                         * and from there we are directing to actual url
                         */
                        return history.push({
                            pathname: '/new-quote-redirect',
                            search,
                            originalPath: nextPath
                        });
                    }

                    return history.push(nextPath || redirectPath);
                }).catch(_noop()));
        },
        [hasErrorOccurred, history, onPageCancel, viewOnly]
    );

    const handlePrevious = useCallback((previousProps) => {
        const {
            wizardData, wizardSnapshot, updateWizardData, cloneData
        } = previousProps;

        return Promise.resolve(
            onPagePrevious(
                wizardData, wizardSnapshot,
                updateWizardData, cloneData, viewOnly
            )
        );
    }, [onPagePrevious, viewOnly]);

    const handleError = useCallback(
        (error) => {
            const isQuotePage = _includes(_toLower(_get(error, 'gwInfo.method')), 'quote');
            const quoteIDFromWizard = _get(error, 'gwInfo.params[0].quoteID');
            const quoteIDFromLocation = _get(location, 'state.quoteentry.quoteID');
            const quoteID = quoteIDFromWizard || quoteIDFromLocation;
            const pathname = _get(location, 'pathname');
            // Can't go to quote summary if submission never started
            const redirectPath = quoteID ? `/quotes/${quoteID}/summary` : '/';
            const state = {
                pathname: redirectPath,
                state: error
            };

            loggerError(`MSA-EHWizard-Error: ${error}
            Pathname: ${pathname}`);

            return modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
                message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred
            }).then(() => {
                setHasErrorOccurred(true);

                return <Redirect to={state} />;
            });
        },
        [location, modalApi]
    );

    if (isLoading) {
        return (
            <E1PLoader
                loaded={!isLoading}
            />
        );
    }

    if (!initialSubmission) {
        return null;
    }

    if (parsedParameters.experienceID) {
        initialSubmission.baseData.experienceID_Ext = parsedParameters.experienceID;
    }

    return (
        <ErrorBoundary onError={handleError}>
            {/* Hidden field needed for GA on each wizard page */}
            <p id="sourceOfEntry" style={{ display: 'none' }}>
                {_get(
                    initialSubmission,
                    'baseData.quoteSource_Ext.sourceType.value.code',
                    'directentry'
                )}
            </p>
            <Wizard
                viewOnly={viewOnly}
                initialSteps={steps}
                wizardTitle={title}
                initialData={initialSubmission}
                onCancel={handleCancel}
                onFinish={handleFinish}
                onPrevious={handlePrevious}
                skipCompletedSteps
                wizardStepToFieldMapping={wizardStepToFieldMapping}
                isPageJumpEnabled={viewOnly}
                onPreviousModalProps={{
                    title: commonMessages.wantToJump,
                    message: commonMessages.wantToJumpMessage,
                    messageProps: {
                        ok: commonMessages.yesModel,
                        close: commonMessages.cancelModel
                    }
                }}
            />
        </ErrorBoundary>
    );
}

PEEAWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            quoteentry: PropTypes.shape({
                postalCode: PropTypes.string,
                quoteID: PropTypes.string
            })
        }),
        search: PropTypes.string,
        viewOnly: PropTypes.bool
    }).isRequired
};

export default withViewModelService(PEEAWizard);
