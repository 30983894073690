import React, {
    useEffect,
    useState,
    useCallback
} from 'react';
import _ from 'lodash';
import { getConfigValue } from 'e1p-portals-util-js';
import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import { Icon, ModalNextProvider, useModal, ModalNextEmitter } from '@jutro/components';
import { Table, TableColumn } from '@jutro/legacy/datatable';
import './App.scss';
import { ViewModelServiceFactory } from '@xengage/gw-portals-viewmodel-js';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { policyJobComponentMap, policyJobComponents } from 'gw-capability-policyjob-react';
import { e1pPolicyJobComponentMap, e1pPolicyJobComponents } from 'e1p-capability-policyjob-react';
import { useDataLayer } from 'e1p-capability-hooks';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { AccurateBreakpointPropagation } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import appConfig from 'app-config';
import { routeConfirmationModal } from '@jutro/router';
import vmTranslator, { messages as commonMessages } from '@xengage/gw-platform-translations';
import { platformComponents, platformComponentMap, ImageComponent } from 'gw-components-platform-react';
// import { brandingData, setBranding } from 'gw-portals-branding-js';
import {
    MenuBarComponent,
    PageHeaderComponent,
    producerEngageComponentMap,
    producerEngageAdditionalComponents,
    EsignConfirmationPage,
    ExitConfirmationPage,
    DashboardStateContextProvider,
    AmfamOktaTokenContextProvider,
    E1PAttributeConfigContextProvider
} from 'e1p-capability-gateway-react';
import { SelectProducerContextProvider } from 'gw-gateway-common-react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import { ErrorBoundary } from '@xengage/gw-portals-error-react';
// import { RoutingTracking } from 'gw-portals-tracking-react';
import { withAuthenticationProvider } from '@xengage/gw-digital-auth-react';
import { CustomQuoteService } from 'gw-capability-gateway-quoteandbind';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { UserService, SubmissionService, JobService } from 'gw-capability-gateway';
import { WizardPageTemplateWithTitle } from 'e1p-portals-wizard-react';
import PageRouting from '../routes/PageRouting';
import messages from './App.messages';

import { Main, CurrencyField } from '@jutro/legacy/components';

const modalEmitter = new ModalNextEmitter();
// make sure the IFRAME is imported as is

setComponentMapOverrides({
    ...platformComponentMap,
    ...producerEngageComponentMap,
    ...policyJobComponentMap,
    ...e1pPolicyJobComponentMap,
    Icon: { component: 'Icon' },
    Table: { component: 'Table' },
    TableColumn: { component: 'TableColumn' },
    CurrencyField: { component: 'CurrencyField' },
    WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
    // replace the native IMG component with a proxied version
    img: { component: 'img' }
},
{
    ...platformComponents,
    ...producerEngageAdditionalComponents,
    ...policyJobComponents,
    ...e1pPolicyJobComponents,
    Icon,
    Table,
    TableColumn,
    CurrencyField,
    WizardPageTemplateWithTitle,
    img: ImageComponent
});


// eslint-disable-next-line arrow-body-style
const getUserAuthData = (authData) => {
    return UserService.getGatewayCurrentUser(authData.authHeader).catch(() => {
        if(authData) { authData.logout(); } 
    });
};

function App() {
    const modalApi = useModal();
    const translator = useTranslator();
    const [viewModelService, updateViewModelService] = useState(undefined);
    const environment = _.get(appConfig, 'env.AMFAM_ENV', 'local');

    useDataLayer();
   
    useEffect(() => {
        if (window.self === window.top) { 
            const theBody = document.getElementsByTagName('body')[0];

            theBody.className += ' digitalRoot';
        }
    }, [])

    useEffect(
        () => {
            const translatorFn = vmTranslator(translator);

            import(
                /* webpackChunkName: "product-metadata" */
                'product-metadata'
            ).then((productMetadata) => {
                const { default: result } = productMetadata;

                updateViewModelService(
                    ViewModelServiceFactory.getViewModelService(result, translatorFn)
                );
            });
        },
        [translator]
    );
    // if (brandingData.BRANDING) {
    //     setBranding();
    // }

    const handleError = useCallback((error = {}) => {
        const state = {
            pathname: '/',
            state: error
        };

        return modalApi.showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).then(
            () => <Redirect to={state} />,
            _.noop
        );
    }, [modalApi]);

    useEffect(() => {
        const script = document.createElement('script');
        const dynatraceScript = getConfigValue('endpoints.dynatrace');

        script.src = dynatraceScript;
        script.async = true;
        script.crossOrigin = 'anonymous';
        script.cors = true;

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);
    
    /**
     * IAP-5192, for local development hide the transparent overlay which is being applied by OOTB,
     * once error boundary cathces the error.
     * This is not required for higher env's like INT, DEV, etc.
     * Reason: In Local env, transparant overlay make the UI unresponsive
     */
    useEffect(() => {
        const hideOverlayStyle = document.createElement("style");

        document.head.appendChild(hideOverlayStyle);

        if (environment === 'local') {
            hideOverlayStyle.sheet.insertRule("iframe[style*='z-index: 2147483647'] {display: none !important;}");
        }

        return () => {
            document.head.removeChild(hideOverlayStyle);
        };
    }, [environment]);

    useEffect(() => {
        _.set(window, '__giamfam.modalApi', { modalApi});
    }, [modalApi]);

    return (
        <AccurateBreakpointPropagation>
            <DependencyProvider
                value={{
                    LoadSaveService: LoadSaveService,
                    CustomQuoteService: CustomQuoteService,
                    UserService: UserService,
                    quoteUnderWritingService: {
                        referToUnderwriter: SubmissionService.referToUnderwriter,
                        withdrawJobByJobNumber: JobService.withdrawJobByJobNumber,
                        approveUnderwritingIssue: LoadSaveService.approveUnderwritingIssue
                    }
                }}
            >
                <ViewModelServiceContext.Provider value={viewModelService}>
                    <Router
                        basename="/producer-engage"
                        getUserConfirmation={(message,callback) => routeConfirmationModal(message,callback,modalApi)}>
                        <ModalNextProvider>
                            <ErrorBoundary onError={handleError}>
                                <Switch>
                                    <Route exact path="/esignconfirmation" component={EsignConfirmationPage} />
                                    <Route exact path="/exitapplication" component={ExitConfirmationPage} />
                                    <Route path="/">
                                        <SelectProducerContextProvider>
                                            <DashboardStateContextProvider>
                                                <AmfamOktaTokenContextProvider>
                                                    <E1PAttributeConfigContextProvider>
                                                        <PageHeaderComponent/>
                                                        <MenuBarComponent />
                                                        <Main contentClassName="producerEngageContainer">
                                                            <PageRouting />
                                                        </Main>
                                                    </E1PAttributeConfigContextProvider>
                                                </AmfamOktaTokenContextProvider>
                                            </DashboardStateContextProvider>
                                        </SelectProducerContextProvider>
                                    </Route>
                                </Switch>
                            </ErrorBoundary>
                        </ModalNextProvider>
                    </Router>
                </ViewModelServiceContext.Provider>
            </DependencyProvider>
        </AccurateBreakpointPropagation>
    );
}

export default withAuthenticationProvider({ onAuthDataCreation: getUserAuthData })(App);

