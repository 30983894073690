import { defineMessages } from 'react-intl';

export default defineMessages({
    eaPriorCarrierTitle: {
        id: 'policyjob.components.sni-prior-policy-grid.Prior Carrier',
        defaultMessage: 'Prior Carrier'
    },
    removeDriverTitle: {
        id: 'policyjob.components.sni-prior-policy-grid.Confirmation',
        defaultMessage: 'Confirmation'
    },
    removeDriverDescription: {
        id:
      'policyjob.components.sni-prior-policy-grid.Are you sure to delete the prior carrier details?',
        defaultMessage: 'Are you sure to delete the prior carrier details?'
    },
    primaryNamedInsuredTitle: {
        id: 'policyjob.components.sni-prior-policy-grid.Primary Named Insured:',
        defaultMessage: 'Primary Named Insured:'
    },
    priorPolicyCarrierNameLabel: {
        id:
      'policyjob.components.sni-prior-policy-grid.Current/Prior Carrier Name',
        defaultMessage: 'Current/Prior Carrier Name'
    },
    priorPolicyPreviousPolicyLabel: {
        id: 'policyjob.components.sni-prior-policy-grid.Previous Policy #',
        defaultMessage: 'Previous Policy #'
    },
    priorPolicyYearsWithCompanyLabel: {
        id: 'policyjob.components.sni-prior-policy-grid.Years w/ company',
        defaultMessage: 'Years w/ company'
    },
    priorPolicyBILimitsLabel: {
        id: 'policyjob.components.sni-prior-policy-grid.BI Limits',
        defaultMessage: 'BI Limits'
    },
    priorPolicyCoverageLapseLabel: {
        id: 'policyjob.components.sni-prior-policy-grid.Coverage Lapse',
        defaultMessage: 'Coverage Lapse'
    },
    collisionCoverage: {
        id: 'policyjob.components.sni-prior-policy-grid.Collision Coverage',
        defaultMessage: 'Collision Coverage'
    },
    comprehensiveCoverage: {
        id: 'policyjob.components.sni-prior-policy-grid.Comprehensive Coverage',
        defaultMessage: 'Comprehensive Coverage'
    },
    BILimitPP: {
        id: 'policyjob.components.sni-prior-policy-grid.BI Limit(PP)',
        defaultMessage: 'BI Limit(PP)'
    },
    BILimitPO: {
        id: 'policyjob.components.sni-prior-policy-grid.BI Limit(PO)',
        defaultMessage: 'BI Limit(PO)'
    },
    BILimitCSL: {
        id: 'policyjob.components.sni-prior-policy-grid.BI Limit(CSL)',
        defaultMessage: 'BI Limit(CSL)'
    },
    priorPolicyRole: {
        id: 'policyjob.components.sni-prior-policy-grid.Policy Role',
        defaultMessage: 'Policy Role'
    },
    noPriorPolicies: {
        id: 'policyjob.components.sni-prior-policy-grid.No prior carrier results',
        defaultMessage: 'No prior carrier results. Please add any prior carriers manually.'
    },
    secondaryNamedInsuredTitle: {
        id: 'policyjob.components.sni-prior-policy-grid.Secondary Named Insured:',
        defaultMessage: 'Secondary Named Insured:'
    },
});
