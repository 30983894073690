import { defineMessages } from 'react-intl';

export default defineMessages({
    carrier: {
        id: 'quoteandbind.ea.views.component.priorPolicy.Carrier',
        defaultMessage: 'Carrier',
    },
    priorPolicyPreviousPolicyLabel: {
        id: 'quoteandbind.ea.views.component.priorPolicy.Previous Policy #',
        defaultMessage: 'Previous Policy #',
    },
    priorPolicyNumberMessageProps: {
        id: 'quoteandbind.ea.views.component.priorPolicy.Input value is incomplete',
        defaultMessage: 'Input value is incomplete',
    },
    priorPolicyYesLabel: {
        id: 'quoteandbind.ea.views.component.priorPolicy.Yes',
        defaultMessage: 'Yes',
    },
    priorPolicyNoLabel: {
        id: 'quoteandbind.ea.views.component.priorPolicy.No',
        defaultMessage: 'No',
    },
});
