import React, {
    useCallback, useContext, useEffect, useMemo, useState, useRef
} from 'react';
import {
    get, set, find, findIndex, isEmpty,
    unset, pullAt
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { PrefillService } from 'e1p-capability-quoteandbind';
import { EntityUtil } from '@xengage/gw-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { e1pContactUtil, useDriverPageUtil, useCreditReportUtil } from 'e1p-capability-hooks';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { commonMessages as e1pCommonMessages, eaCommonMessages, eaValidationAndInfoMessages } from 'e1p-platform-translations';
import {
    EAValidatonUtil
} from 'e1p-portals-util-js';
import EADriverPrefill from './EADriverPrefill/EADriverPrefill';
import metadata from './DriversPage.metadata.json5';

const LOB = 'personalAuto_EA';

function EADriversPage(props) {
    const modalApi = useModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        isSkipping,
        setIsPageValid,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingSubmission, setIsSavingSubmission] = useState(false);
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [sniHasDefensiveCourse, setSNIHasDefensiveCourse] = useState(false);
    const [pniHasDefensiveCourse, setPNIHasDefensiveCourse] = useState(false);
    const hasNewSNIAdded = useRef(false);
    const hasSaveOperationCompleted = useRef(false);
    const [isAccountContactsFetched, setIsAccountContactsFetched] = useState(false);
    const [driversWithLicenseValidationError, setDriversWithLicenseValidationErrors] = useState([]);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        registerInitialComponentValidation
    } = useValidation('EADriversPage');
    const { authHeader, authUserData } = useAuthentication();
    const [prefillData, setPrefillData] = useState('');
    const [prefillCompleted, setPrefillCompleted] = useState(false);
    const [isCreditReportRequired, setIsCreditReportRequired] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const policyState = get(submissionVM, 'baseData.policyAddress.state.value.code');
    const [isExistingDriverDeleted, setIsExistingDriverDeleted] = useState(false);
    const [prefillWasCalled, setPrefillWasCalled] = useState(false);

    /**
    * Setting current wizard page is valid or not,using this info
    * to show popup(The changes you have made on this page may be lost.)
    * on click of previous or page jump
    */
    useEffect(() => {
        setIsPageValid(isComponentValid);
    }, [isComponentValid, setIsPageValid]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        // IAP-3939 : stop user on drivers page if no driver has been added on the quote
        registerInitialComponentValidation(
            () => !isEmpty(
                    get(submissionVM, 'lobData.personalAuto_EA.coverables.drivers.value', []).filter((driver) => driver.fixedId)
                )
        );
    }, [registerInitialComponentValidation, submissionVM])

    const {
        showReportPopup
    } = useCreditReportUtil(
        translator,
        LOB
    );

    submissionVM.flowStepIDs_Ext.value = ['driver', 'namedinsured'];
    submissionVM.entryCompletionStepIDs_Ext.value = ['driver', 'namedinsured'];

    const newPrefill = useCallback(() => {
        if (submissionVM.lobData.personalAuto_EA.prefillDrivers && !prefillCompleted) {
            const prefillDrivers = [];
            const DriversArray = submissionVM.lobData.personalAuto_EA.coverables.drivers.value;

            if (submissionVM.lobData.personalAuto_EA.prefillDrivers && submissionVM.lobData.personalAuto_EA.prefillDrivers.value !== '') {
                submissionVM.lobData.personalAuto_EA.prefillDrivers.children.forEach((prefillDriver) => {
                    if (prefillDriver.driverStatus.value.code === 'AddedToQuote') {
                        const newDriverObj = {
                            prefillDriverID: prefillDriver.prefillDriverID.value ? prefillDriver.prefillDriverID.value : EntityUtil.nextId(),
                            person: {
                                firstName: prefillDriver.firstName.value ? prefillDriver.firstName.value : '',
                                middleName: prefillDriver.middleName.value ? prefillDriver.middleName.value : '',
                                lastName: prefillDriver.lastName.value ? prefillDriver.lastName.value : '',
                                dateOfBirth: prefillDriver.dateOfBirth.value ? prefillDriver.dateOfBirth.value : '',
                                licenseNumber: prefillDriver.licenseNumber.value ? prefillDriver.licenseNumber.value : '',
                                gender: prefillDriver.value.gender ? prefillDriver.value.gender : undefined,
                                maritalStatus: prefillDriver.value.maritalStatus ? prefillDriver.value.maritalStatus : undefined,
                                ssn: prefillDriver.value.ssn ? prefillDriver.value.ssn : ''
                            }
                        };

                        if (prefillDriver.licenseState.value) {
                            newDriverObj.person.licenseState = prefillDriver.licenseState.value.code;
                        }

                        prefillDriver.prefillDriverID = newDriverObj.prefillDriverID;

                        const {
                            _xCenter,
                            _dtoName
                        } = submissionVM.lobData.personalAuto_EA.coverables.drivers;
                        const driverVM = viewModelService.create(newDriverObj, _xCenter, _dtoName);

                        prefillDrivers.push(driverVM);
                    }
                });

                const diffPrefill = prefillDrivers.filter(
                    (prefillDriver) => !DriversArray.some(
                        (driver) => {
                            // Prefill driver could come through without date of birth
                            // we will exit this one as a non-match
                            if (!prefillDriver.person.dateOfBirth) {
                                return false;
                            }

                            return (prefillDriver.person.firstName.value.toUpperCase() === driver.person.firstName.toUpperCase()
                                && prefillDriver.person.lastName.value.toUpperCase() === driver.person.lastName.toUpperCase()
                                && prefillDriver.person.dateOfBirth.day.value === driver.person.dateOfBirth.day
                                && prefillDriver.person.dateOfBirth.year.value === driver.person.dateOfBirth.year
                                && prefillDriver.person.dateOfBirth.month.value === driver.person.dateOfBirth.month);
                        }
                    )
                );

                const intersection = prefillDrivers.filter(
                    (prefillDriver) => DriversArray.some(
                        (driver) => {
                            // Prefill driver could come through without date of birth
                            // we will exit this one as a non-match
                            if (!prefillDriver.person.dateOfBirth) {
                                return false;
                            }

                            return (prefillDriver.person.firstName.value.toUpperCase() === driver.person.firstName.toUpperCase()
                                && prefillDriver.person.lastName.value.toUpperCase() === driver.person.lastName.toUpperCase()
                                && prefillDriver.person.dateOfBirth.day.value === driver.person.dateOfBirth.day
                                && prefillDriver.person.dateOfBirth.year.value === driver.person.dateOfBirth.year
                                && prefillDriver.person.dateOfBirth.month.value === driver.person.dateOfBirth.month);
                        }
                    )
                );

                if (intersection.length !== 0) {
                    intersection.forEach((subDriver) => {
                        const matchingDriver = DriversArray.find((matchDriver) => (
                            subDriver.person.firstName.value.toUpperCase() === matchDriver.person.firstName.toUpperCase()
                            && subDriver.person.lastName.value.toUpperCase() === matchDriver.person.lastName.toUpperCase()
                            && subDriver.person.dateOfBirth
                            && subDriver.person.dateOfBirth.day.value === matchDriver.person.dateOfBirth.day
                            && subDriver.person.dateOfBirth.year.value === matchDriver.person.dateOfBirth.year
                            && subDriver.person.dateOfBirth.month.value === matchDriver.person.dateOfBirth.month));

                        subDriver.ageFirstLicensed.value = matchingDriver.ageFirstLicensed;
                        subDriver.person.gender.value = matchingDriver.person.gender;
                        subDriver.person.ssn.value = matchingDriver.person.ssn;
                        subDriver.person.maritalStatus.value = matchingDriver.person.maritalStatus;
                        subDriver.person.suffix.value = matchingDriver.person.suffix;
                        subDriver.person.firstName = matchingDriver.person.firstName;
                        subDriver.person.lastName = matchingDriver.person.lastName;

                        if (get(matchingDriver, 'person.publicID', false)) {
                            set(subDriver, 'person.publicID.value', matchingDriver.person.publicID);
                        }

                        set(matchingDriver, 'person', subDriver.person.value);
                        set(matchingDriver, 'prefillDriverID', subDriver.prefillDriverID.value);
                    });
                }

                const newPNIArray = [...DriversArray, ...diffPrefill];
                const tempArray = [];

                newPNIArray.forEach((driver) => {
                    tempArray.push(driver.value ? driver.value : driver);
                });
                submissionVM.lobData.personalAuto_EA.coverables.drivers.value = tempArray;

                const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
                const driversList = get(submissionVM, driversPath);

                driversList.forEach((driver) => {
                    /**
                     * E1PAP1PC-12823
                     * If relationshipTo Pni is spouse then that driver should be sni
                     */
                    if (driver.relationshipToNI === 'spouse') {
                        set(driver, 'role', 'sni');
                    }
                });

                setPrefillCompleted(true);
                updateWizardData(submissionVM);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefillData]);

    const showModal = useCallback(async (vm, prefill) => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            submissionVM: vm,
            authHeader,
            updateWizardData,
            prefillData: prefill,
            viewModelService
        };
        const results = await modalApi.showModal(<EADriverPrefill {...componentProps} />);

        return results;
    }, [authHeader, modalApi, updateWizardData, viewModelService]);

    const prefillDataAction = useCallback(
        (prefill) => {
            set(submissionVM.value, 'lobData.personalAuto_EA.priorPolicies', prefill.priorPolicies);
            set(submissionVM.value, 'lobData.personalAuto_EA.priorPolicyUpdates', prefill.priorPolicyUpdates);

            if (prefill.prefillDrivers.length <= 1) {
                newPrefill();
            } else {
                showModal(submissionVM, prefill).then(() => {
                    // this is where you could take the data passed back from the modal
                    // EX: const modifiedVM = viewModelService.clone(wrapperObj.issueVM);
                    newPrefill();
                }).catch();
            }
        }, [newPrefill, showModal, submissionVM]
    );

    const createDriverVM = useCallback((driver, isSNI) => {
        const driverObj = {
            person: driver.person.value
        };

        const {
            _dtoName,
            _xCenter
        } = submissionVM.lobData.personalAuto_EA.coverables.drivers;
        const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);

        if (isSNI) {
            set(driverVM, 'relationshipToNI', driver.relationshipToNI.value);
            set(driverVM, 'value.role', 'sni');
        }

        submissionVM.lobData.personalAuto_EA.coverables.drivers.pushElement(
            driverVM
        );
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData, viewModelService]);

    const getPrefill = useCallback(async () => {
        setPrefillCompleted(false);

        try {
            const response = await PrefillService.loadPrefill(
                submissionVM.value,
                authHeader
            );

            setPrefillData(response);
            updateWizardData(submissionVM);
            prefillDataAction(response);
            setPrefillWasCalled(true);
        } catch {
            modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: 'Pre-Fill Household Drivers',
                message: 'Pre-Fill report cannot be ordered. Please save your data and try again later.'
            });
            setPrefillCompleted(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';

        const drivers = get(submissionVM, `${driversPath}.value`);

        // Fix for E1PAP1PC-13078
        // Don't remove prefill drivers if comprater, because we load prefill, then convert to
        // full quote and it passes through this code and wipes before additional info
        if (submissionVM.baseData.quoteSource_Ext.sourceType?.value?.code !== 'comprater') {
            // Fix for E1PAP1PC-12503
            // Removing the prefill drivers so that any old prefills drivers are removed during the updateDraftSubmission call
            // and updated prefill information will be sent back as response from PC.
            submissionVM.lobData.personalAuto_EA.prefillDrivers = [];
        }

        if (isEmpty(drivers)) {
            createDriverVM(submissionVM.lobData.personalAuto_EA.primaryNamedInsured, false);

            if (submissionVM.lobData.personalAuto_EA.secondaryNamedInsured.person) {
                createDriverVM(submissionVM.lobData.personalAuto_EA.secondaryNamedInsured, true);
            }

            // If it is first time visiting the page, need to call credit report
            setIsCreditReportRequired(true);
            getPrefill();
        } else {
            // User chose to go back and change PNI to a new Contact
            const pni = get(submissionVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
            const pniDriver = drivers.find((driver) => driver.person.publicID === pni.person.publicID);

            if (!pniDriver) {
                createDriverVM(submissionVM.lobData.personalAuto_EA.primaryNamedInsured, false);
                setIsCreditReportRequired(true);
            }

            // Submission already started and user goes back to add SNI
            const sniPublicID = submissionVM.lobData.personalAuto_EA.secondaryNamedInsured.person
                ? submissionVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value : undefined;
            const sniDriver = drivers.find((driver) => driver.person.publicID === sniPublicID);

            // Populate SNI as a driver
            if (sniPublicID && !sniDriver) {
                createDriverVM(submissionVM.lobData.personalAuto_EA.secondaryNamedInsured, true);
                // If secondary named insured person is added, need to call credit report
                setIsCreditReportRequired(true);
            }

            setPrefillCompleted(true);
        }

        setIsPageInitialized(true);
        // No array dependencies needed in this hook.
        // The logic of initializing drivers data needs to be executed only once
        // when landing into drivers page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';
        const drivers = get(submissionVM, `${driversPath}`);
        const pniPublicID = submissionVM.lobData.personalAuto_EA.primaryNamedInsured.person.publicID.value;
        const sniPublicID = submissionVM.lobData.personalAuto_EA.secondaryNamedInsured.person ? submissionVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value : undefined;
        let pni = find(drivers.children, (driver) => driver.person.publicID.value === pniPublicID);
        const pniIndex = findIndex(drivers.children, (driver) => driver.person.publicID.value === pniPublicID);

        // Unset role. Fixes bug where you come back to drivers from a further page
        //   and ArrayUpdater has changed the ordering of your drivers and role has persisted
        //   so you end up seeing both  drivers as PNI
        drivers.children.forEach((driverNode) => {
            unset(driverNode, 'role');
        });

        // Put PNI back as first driver
        if (pniIndex > 0) {
            pni = viewModelService.clone(pni);
            pullAt(drivers.value, pniIndex);
            drivers.value.unshift(pni.value);
            updateWizardSnapshot(submissionVM);
        }

        if (sniPublicID) {
            const sni = find(drivers.children, (driver) => driver.person.publicID.value === sniPublicID);

            if (sni) {
                set(sni, 'value.role', 'sni');
            }
        }

        if (pni) {
            set(pni, 'value.role', 'pni');
        }
    }, [submissionVM, submissionVM.lobData.personalAuto_EA.coverables.drivers.value, viewModelService, updateWizardSnapshot]);

    const getCreditReportStatus = useCallback(
        (quoteID) => showReportPopup(
            quoteID,
            authHeader,
            submissionVM
        ),
        [authHeader, showReportPopup, submissionVM]
    );

    const {
        setSpouseAsSNI,
        checkMoreThanOneSNIRoles,
        setRelationShipStatusForSNI,
        isPNIAndSNIValidForDefensiveDiscount,
        isDefensiveDriverDiscountAvailableForDriver,
        isLicenseNumberRequired,
        getDriverAge,
        isFinancialResponsibilityFormAvailableForDriver,
        checkDuplicateDrivers
    } = useDriverPageUtil(
        submissionVM,
        updateWizardData,
        viewModelService,
        setValidationErrors,
        translator
    );

    const onNext = useCallback(
        async () => {
            setValidationErrors([]);

            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            // IAP-4716, check duplicate drivers
            const hasDuplicateDrivers = checkDuplicateDrivers();

            if (hasDuplicateDrivers) {
                return false;
            }

            const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
            const driversList = get(submissionVM, driversPath);

            driversList.forEach((driver) => {
                /**
                 * E1PAP1PC-12823
                 * If relationshipTo Pni is spouse then that driver should be sni
                 * Marking driver as sni and function setSpouseAsSNI will convert it to sni
                 */
                if (driver.relationshipToNI === 'spouse') {
                    set(driver, 'role', 'sni');
                }
            });

            // E1PAP1PC-12823
            // Checks if more than one sni roles are present and if yes then sets page level error
            const hasMoreThanOneSNIRoles = checkMoreThanOneSNIRoles();

            if (hasMoreThanOneSNIRoles) {
                return false;
            }

            /**
             * IAP-3005 : removing prompt to set Spouse as SNI
             * When Spouse is added as driver we will add it as SNI on the policy
             */
            setSpouseAsSNI(hasNewSNIAdded);

            // E1PAP1PC-12823
            // syncs relationship status for sni
            setRelationShipStatusForSNI();

            setIsSavingSubmission(true);

            const pniPath = 'lobData.personalAuto_EA.primaryNamedInsured.person.value';
            const pniPerson = get(submissionVM, pniPath);

            driversList.forEach((driver, index) => {
                if (
                    isEmpty(driver.person.displayName)
                    || !driver.person.displayName.includes(
                        `${driver.person.firstName} ${driver.person.lastName}`
                    )
                ) {
                    const displayname = `${driver.person.firstName} ${driver.person.lastName}`;

                    set(
                        submissionVM,
                        `${driversPath}[${index}].person.displayName`,
                        displayname
                    );
                }

                const driverPerson = get(submissionVM, `${driversPath}[${index}].person`);

                if (e1pContactUtil.samePerson(driverPerson, pniPerson)) {
                    set(submissionVM,
                        `${driversPath}[${index}].person.emailAddress1`,
                        submissionVM.lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value);
                    set(submissionVM,
                        `${driversPath}[${index}].person.homeNumber`,
                        submissionVM.lobData.personalAuto_EA.primaryNamedInsured.person.homeNumber.value);
                    set(submissionVM,
                        `${driversPath}[${index}].person.cellNumber`,
                        submissionVM.lobData.personalAuto_EA.primaryNamedInsured.person.cellNumber.value);
                    set(submissionVM,
                        `${driversPath}[${index}].isInsurancePolicyRefused`,
                        'no');
                }

                set(submissionVM,
                    `${driversPath}[${index}].isInsurancePolicyRefused`,
                    'no');
                set(submissionVM,
                    `${driversPath}[${index}].isDrivingPrivilegeRevoked`,
                    'no');
            });

            const quoteID = get(submissionVM, 'quoteID.value');

            submissionVM.value = await LoadSaveService
                .updateDraftSubmission(submissionVM.value, authHeader);
            updateWizardData(submissionVM);
            hasSaveOperationCompleted.current = true;

            if (isCreditReportRequired || hasNewSNIAdded.current) {
                if (!(await getCreditReportStatus(quoteID))) {
                    setIsSavingSubmission(false);

                    return false;
                }
            }

            setIsSavingSubmission(false);

            return submissionVM;
        },
        [
            LoadSaveService, authHeader, checkMoreThanOneSNIRoles, getCreditReportStatus,
            isComponentValid, isCreditReportRequired, setSpouseAsSNI, submissionVM,
            updateWizardData, setValidationErrors, setRelationShipStatusForSNI,
            checkDuplicateDrivers
        ]
    );

    const onSave = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }
            
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (hasSaveOperationCompleted.current && isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    /**
                    * E1PAP1PC-14867 :
                    * wizardData and wizardSnapshot not being equal due to
                    * some defaulting on each page so doing this temp fix
                    */
                    const pniDriver = submissionVM.value.lobData.personalAuto_EA
                        .coverables.drivers.find((driver) => driver.role === 'pni');

                    if (pniDriver) {
                        set(pniDriver, 'relationshipToNI', undefined);
                    }

                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [isComponentValid, onNext, submissionVM, updateWizardSnapshot]
    );

    // // this grabs the latest element on the page -- this is to make sure this element has been loaded
    const latestDriverElement = document.getElementById(`driverFirstName${submissionVM.lobData.personalAuto_EA.coverables.drivers.children.length - 1}`);

    useEffect(() => {
        // indexStale set in the add driver function
        // once latest element is loaded and a new driver is added
        // we check if the button should be visible
        if (
            latestDriverElement &&
            indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale,
        latestDriverElement
    ]);

    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        overrideProps.WizardSingleErrorComponent = {
            issuesList: validationErrors
        };

        return overrideProps;
    }, [validationErrors]);

    const getLoadingIndicatorMessage = useMemo(
        () => {
            let loadingMessage = '';

            if (!prefillCompleted) {
                loadingMessage = translator(eaCommonMessages.loadingPrefillDataMessage);
            } else if (isSavingCurrentPageChanges) {
                loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
            } else if (!isPageInitialized || isSavingSubmission || isSkipping) {
                loadingMessage = translator(eaCommonMessages.loadingNextPageMessage);
            }

            return loadingMessage;
        },
        [
            prefillCompleted, isSavingCurrentPageChanges, isPageInitialized,
            isSavingSubmission, isSkipping, translator
        ]
    );

    const getMessageForFinancialRespBasedonState = () => {
        let financialRespMessage = translator(eaValidationAndInfoMessages.sr22WithCompOnly);

        if (policyState === 'VA') {
            financialRespMessage = translator(eaValidationAndInfoMessages.sr22fr44WithCompOnly);
        }

        return financialRespMessage;
    };

    // IAP-4225, if existing driver is removed, then we need to call save API
    useEffect(() => {
        const callOnSave = async () => {
            await onSave();
        }

        if (isExistingDriverDeleted && isComponentValid) {
            callOnSave();
            setIsExistingDriverDeleted(false);
        }
    }, [isComponentValid, isExistingDriverDeleted, onSave]);

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => isPageInitialized && prefillCompleted
        && !isSavingSubmission && !isSkipping && !isSavingCurrentPageChanges,
        [isPageInitialized, isSavingCurrentPageChanges, isSavingSubmission,
            isSkipping, prefillCompleted]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        driversPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        driverContainer: {
            visible: isPageLoaded
        },
        addDriver: {
            accountNumber: get(submissionVM, 'baseData.accountNumber.value'),
            authHeader,
            excludedContacts: (() => {
                const drivers = get(submissionVM.value, 'lobData.personalAuto_EA.coverables.drivers', []);

                return drivers.map((driver) => driver.person.publicID);
            })(),
            viewModelService,
            setCheckScrolling,
            setIndexStale,
            setIsAccountContactsFetched,
            isAccountContactsFetched
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('eadrivercontainer')
        },
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        defensiveDriverDiscountNotificationDiv: {
            visible: isPNIAndSNIValidForDefensiveDiscount() && ((pniHasDefensiveCourse && !sniHasDefensiveCourse)
                || (!pniHasDefensiveCourse && sniHasDefensiveCourse))
        },
        financialResponsibilityWithCompOnlyMessage: {
            message: getMessageForFinancialRespBasedonState()
        },
        financialResponsibilityWithCompOnlyMessageDiv: {
            visible: EAValidatonUtil.isFinancialResponsibilitySelectedWithAllCompOnlyVehicle(submissionVM)
        },
        nationalGuardWithDefensiveDriverMessageDiv: {
            visible: EAValidatonUtil.isNationalGuardSelectedWithoutDefensiveDriver(submissionVM),
        },
        EADriverGrid: {
            key: get(submissionVM, 'lobData.personalAuto_EA.coverables.drivers', []).length,
            drivers: get(submissionVM, 'lobData.personalAuto_EA.coverables.drivers', []),
            path: 'lobData.personalAuto_EA.coverables.drivers.children',
            onValidate,
            viewModelService,
            showErrors: isPageSubmitted,
            policyState: get(submissionVM, 'baseData.policyAddress.state.value.code'),
            driverPageDisregardFieldValidation: disregardFieldValidation,
            authHeader,
            checkLicenseRequired: (driver) => isLicenseNumberRequired(submissionVM, driver),
            driversWithLicenseValidationError,
            setDriversWithLicenseValidationErrors,
            getDriverAge,
            periodStartDate: get(submissionVM, 'baseData.periodStartDate.value'),
            priorPolicyList: get(submissionVM, 'lobData.personalAuto_EA.priorPolicyUpdates'),
            isFinancialResponsibilityFormAvailableForDriver,
            isNewSubmission: true,
            onValidationChange: onValidate,
            setSNIHasDefensiveCourse,
            setPNIHasDefensiveCourse,
            authUserData,
            transactionVM: submissionVM,
            isDefensiveDriverDiscountAvailableForDriver,
            updateWizardData,
            setCheckScrolling,
            setIsExistingDriverDeleted,
            showValidationMessage: () => {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            },
            setIsAccountContactsFetched
        },
        driverInformationSubHeaderID: {
            content: prefillCompleted && submissionVM.lobData.personalAuto_EA.prefillDrivers.length <= 1
                ? translator(eaCommonMessages.noAdditionalPrefillSubheading) : translator(eaCommonMessages.driverInformationSubHeading),
            visible: prefillWasCalled
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
            metadata.pageContent,
            submissionVM,
            id,
            path,
            overrideProps
        ),
        [overrideProps, submissionVM]
    );
    const resolvers = {
        resolveCallbackMap: {
            onValidate,
            onPrefillData: prefillDataAction,
            onViewModelService: viewModelService
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

EADriversPage.propTypes = wizardProps;
export default EADriversPage;
