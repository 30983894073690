 import React, {
    useCallback, useContext, useEffect, useRef, useState
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    isUndefined as _isUndefined,
    pullAt as _pullAt
} from 'lodash';
import { useModal } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import appConfig from 'app-config';
import { useTranslator } from '@jutro/locale';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './UnVerifiedLossesAndViolationComponent.metadata.json5';
import messages from './UnVerifiedLossesAndViolationComponent.messages';

const statesNotIncludingAutoLosses = appConfig.statesNotIncludingAutoLosses ?? [];

function UnVerifiedLossesAndViolationComponent(props) {
    const modalApi = useModal();
    const {
        submissionVM,
        updateWizardData,
        setLossesPageValid,
        disregardFieldValidationParentPage,
        onValidateParentPage,
        showErrors,
        viewOnlyMode,
        updateIsPageSubmitted
    } = props;
    const policyType = _get(submissionVM, 'lobData.homeowners_EH.policyType.value.code');
    const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');
     
    const { onValidate } = useValidation(
        'UnVerifiedLossesAndViolationComponent'
    );
    const [lossTypeCodes, setLossTypeCodes] = useState([]);
    const propertyLossListRef = useRef('lobData.homeowners_EH.manualPropertyLossRecords.value');
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    useEffect(() => {
        const currentPropLosses = _get(submissionVM, propertyLossListRef.current);

        if (_isUndefined(currentPropLosses)) {
            _set(submissionVM, propertyLossListRef.current, []);
        } else if (!_isEmpty(currentPropLosses)) {
            _set(submissionVM, 'lobData.homeowners_EH.hasManualPropertyLosses.value', true);
        }

        updateWizardData(submissionVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeLossRow = useCallback((rowToRemove, index, path) => {
        const currentLostList = _get(submissionVM, path);

        if (_isEmpty(rowToRemove)) {
            _pullAt(currentLostList, index);
            updateWizardData(submissionVM);

            return;
        }

        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: messages.removeLossTitle,
            message: messages.removeLossDescription,
            confirmButtonText: translator(commonMessages.removeItemButtonText, { itemToRemove: 'LOSS' }),
            cancelButtonText: commonMessages.cancel
            
        }).then(() => {
            _pullAt(currentLostList, index);
            updateWizardData(submissionVM);
        }, () => { });
    }, [submissionVM, modalApi, translator, updateWizardData]);

    const onValueChange = useCallback((newValue, path) => {
        _set(submissionVM, path, newValue);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const onAddPropLoss = useCallback(() => {
        submissionVM.lobData.homeowners_EH.manualPropertyLossRecords.value.push({
            claimDate: '',
            source: {

            },
            lossType: '',
            claimAmount: '',
        });
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);


    const onRemovePropLossRow = useCallback((rowToRemove) => {
        const indexOfRow = parseInt((rowToRemove.nativeEvent.currentTarget.activeElement.id.match(/\d+$/)[0]), 10);

        removeLossRow(rowToRemove, indexOfRow, propertyLossListRef.current);
    }, [removeLossRow]);

    const resolvers = {
        resolveCallbackMap: {
            onRemovePropLossRow,
            onAddPropLoss,
            onValueChange
        }
    };

    const generateVisibilityProps = () => {
        const isPropertyLossesTableVisible = _get(submissionVM, 'lobData.homeowners_EH.hasManualPropertyLosses.value') || false;

        if (_isEmpty(lossTypeCodes) && !_isEmpty(_get(submissionVM, propertyLossListRef.current))) {
            const lossTypeAvailableValues = _get(submissionVM, 'lobData.homeowners_EH.manualPropertyLossRecords')
                .children[0].lossType.aspects.availableValues.map((key) => ({
                        name: {
                            id: key.name
                        },
                        code: key.code
                    }));

            setLossTypeCodes(lossTypeAvailableValues);
        }

        return {
            PropertyLossesTable: {
                visible: isPropertyLossesTableVisible,
                noDataText: messages.noLossAdded
            },
            PropLossTableHeader: {
                visible: isPropertyLossesTableVisible
            },
            UnderwritingPageGrid: {
                visible: isPropertyLossesTableVisible
            },
            '@field': {
                onValueChange,
                textAlign: 'center',
                showRequired: true,
                showErrors,
                autoComplete: false
            },
            PropLossDescription: {
                availableValues: lossTypeCodes
            },
            PropLossDate: {
                maxDate: new Date()
            }
        };
    };

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false,
            readOnly: viewOnlyMode
        },
        AtFaultAutoLosses: {
            visible: (policyType === 'HO3' || policyType === 'HF9') && !statesNotIncludingAutoLosses.includes(policyState),
            required: (policyType === 'HO3' || policyType === 'HF9') && !statesNotIncludingAutoLosses.includes(policyState)
        },
        AutoLosses: {
            visible: (policyType === 'HO3' || policyType === 'HF9') && !statesNotIncludingAutoLosses.includes(policyState),
            required: (policyType === 'HO3' || policyType === 'HF9') && !statesNotIncludingAutoLosses.includes(policyState)
        },
        selfDeclaredPropertyLossContainer: {
            submissionVM,
            updateWizardData,
            viewModelService,
            onValidateParentPage,
            disregardFieldValidationParentPage,
            isInlineComponent: true,
            showErrors,
            readOnly: viewOnlyMode,
            updateIsPageSubmitted,
            lineOfBusiness: 'homeowners_EH'
        },
        AutoLossResponse: {
            readOnly: viewOnlyMode
        },
        ...generateVisibilityProps()
    };

    useEffect(() => {
        // In cases where participant chooses to report loss(es), ensure that the loss related table is not empty and has valid values.
        let pageDoesNotCheck = true;

        if ((policyType === 'HO3' || policyType === 'HF9') && policyState !== 'IN') {
            pageDoesNotCheck = (_isUndefined(_get(submissionVM, 'lobData.homeowners_EH.hasManualPropertyAutoLosses.value'))
                || _isUndefined(_get(submissionVM, 'lobData.homeowners_EH.hasManualPropertyAtFaultAutoLosses.value'))
                || (_get(submissionVM, 'lobData.homeowners_EH.hasManualPropertyLosses.value')
                    && (_isEmpty(_get(submissionVM, propertyLossListRef.current))
                        || !_get(submissionVM, 'lobData.homeowners_EH.manualPropertyLossRecords.aspects.subtreeValid'))));
        } else {
            pageDoesNotCheck = ((_get(submissionVM, 'lobData.homeowners_EH.hasManualPropertyLosses.value')
                && (_isEmpty(_get(submissionVM, propertyLossListRef.current))
                    || !_get(submissionVM, 'lobData.homeowners_EH.manualPropertyLossRecords.aspects.subtreeValid'))));
        }

        setLossesPageValid(!pageDoesNotCheck);
    }, [submissionVM, updateWizardData, setLossesPageValid, policyType, policyState]);

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={onValidate}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

UnVerifiedLossesAndViolationComponent.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    setLossesPageValid: PropTypes.func.isRequired,
    onValidateParentPage: PropTypes.func,
    disregardFieldValidationParentPage: PropTypes.func,
    showErrors: PropTypes.bool,
    viewOnlyMode: PropTypes.bool
};

UnVerifiedLossesAndViolationComponent.defaultProps = {
    onValidateParentPage: () => { },
    disregardFieldValidationParentPage: () => { },
    showErrors: false,
    viewOnlyMode: false
};

export default UnVerifiedLossesAndViolationComponent;
