import { defineMessages } from 'react-intl';

export default defineMessages({
    inForce: {
        id: 'policyStatus.widgets.inputs.In Force',
        defaultMessage: 'In Force',
    },
    promise: {
        id: 'policyStatus.widgets.inputs.Promise',
        defaultMessage: 'Promise',
    },
    cancelled: {
        id: 'policyStatus.widgets.inputs.Cancelled',
        defaultMessage: 'Cancelled',
    },
    inForceLast90Days: {
        id: 'policyStatus.widgets.inputs.In Force Last 90 Days',
        defaultMessage: 'In Force (last 90 days)',
    }
});
