import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { commonMessages } from 'e1p-platform-translations';
import usePrintElements from "./usePrintElements";
import styles from './PrintComponent.module.scss';
import { Button } from '@jutro/legacy/components';

function PrintComponent(props) {
    const { containerIdsToPrint, buttonType } = props;
    const translator = useTranslator();
    const noPrintStyle = styles.noPrint;
    const preservePrintStyle = styles.preservePrint;
    const preserveAncestorStyle = styles.preserveAncestor;

    const { print } = usePrintElements(noPrintStyle, preservePrintStyle, preserveAncestorStyle);

    const handlePrint = () => {
        const elementsToPrint = [];

        if (containerIdsToPrint && containerIdsToPrint.length > 0) {
            containerIdsToPrint.forEach((containerId) => {
                const containerElement = document.getElementById(containerId);

                if(containerElement){
                    elementsToPrint.push(containerElement);
                }
            });
             
            if (elementsToPrint) {
                print(elementsToPrint);
            }
        }
    };

    return (
        <Button id={`print${containerIdsToPrint}`} onClick={handlePrint} className="do-not-print" type={buttonType} icon="mi-print">
            {translator(commonMessages.printLabel)}
        </Button>
    );
}

PrintComponent.propTypes = {
    containerIdsToPrint: PropTypes.array.isRequired,
    buttonType: PropTypes.string
};

PrintComponent.defaultProps = {
    containerIdsToPrint: [],
    buttonType: "text"
};
export default PrintComponent;
