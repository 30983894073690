import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { E1PLoader } from 'e1p-capability-policyjob-react';

const E1PNewQuoteRedirectComponent = (props) => {
    const history = useHistory();

    useEffect(() => {
        /**
         * IAP-1538
             * When user bridges from Agents Only for the first time the URL has double 
             * producer-engage which causes issues when converting to full quote. This
             * will correct the pathname.
         */
        let pathname = '';

        if (props.location.pathname.split('/')[1] === 'producer-engage') {
            pathname = `/${props.location.pathname.split('/')[2]}`
        } else {

            /**
             * IAP-336
             * When user tries to create new quote from existing quote flow
             * If user tries for same lob then as url is same we wont mount
             * specific lob wizard again so to do that we redirected to dummy url
             * and from here we are directing to actual url
             */
            pathname = props.location.originalPath;
        }

        history.push(
            {
                pathname,
                search: props.location.search
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <E1PLoader loaded={false} />;
};


E1PNewQuoteRedirectComponent.propTypes = {
    location: PropTypes.shape({
        originalPath: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired
    }).isRequired,
};

export default withAuthenticationContext(E1PNewQuoteRedirectComponent);
