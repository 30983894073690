import { defineMessages } from 'react-intl';

export default defineMessages({
    copyVehicleCoverages: {
        id: 'components.e1pCopyVehicleCoveragesModal.Copy Vehicle Coverages',
        defaultMessage: 'Copy Vehicle Coverages'
    },
    copyFrom: {
        id: 'components.e1pCopyVehicleCoveragesModal.Copy From',
        defaultMessage: 'Copy From'
    },
    copyTo: {
        id: 'components.e1pCopyVehicleCoveragesModal.Copy to',
        defaultMessage: 'Copy to'
    },
    copyFromRequiredError: {
        id: 'components.e1pCopyVehicleCoveragesModal.Choose a vehicle to copy coverages from.',
        defaultMessage: 'Choose a vehicle to copy coverages from.'
    },
    copyToRequiredError: {
        id: 'components.e1pCopyVehicleCoveragesModal.Select the vehicle(s) you would like to copy coverages to.',
        defaultMessage: 'Select the vehicle(s) you would like to copy coverages to.'
    },
});
