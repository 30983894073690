import { defineMessages } from 'react-intl';

export default defineMessages({
    insuredDetails: {
        id: 'endorsement.eh.directives.templates.eh-policy-changes.Insured',
        defaultMessage: 'Insured'
    },
    propertyPage: {
        id: 'endorsement.eh.directives.templates.eh-policy-changes.Property',
        defaultMessage: 'Property'
    },
    underwritingPage: {
        id: 'endorsement.eh.directives.templates.eh-policy-changes.Underwriting',
        defaultMessage: 'Underwriting'
    },
    exitTransaction: {
        id: 'endorsement.eh.wizard.step.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'endorsement.eh.wizard.step.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'endorsement.eh.wizard.step.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'endorsement.eh.wizard.step.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    },
    quotePage: {
        id: 'endorsement.eh.directives.templates.eh-policy-changes.Coverages',
        defaultMessage: 'Coverages'
    },
    recalculateButtonLabel: {
        id: 'endorsement.eh.directives.templates.eh-policy-changes.recalculateButtonLabel',
        defaultMessage: 'Recalculate'
    },
    confirmation: {
        id: 'endorsement.eh.directives.templates.eh-policy-changes.Confirmation',
        defaultMessage: 'Confirmation'
    },
    anErrorOccurred: {
        id: 'gw-portals-policytransaction-pc-eh.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'gw-portals-policytransaction-pc-eh.wizard.error.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'gw-portals-policytransaction-pc-eh.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'gw-portals-policytransaction-pc-eh.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    tpiPageTitle: {
        id: 'endorsement.eh.wizard.step.Third-Party Interest',
        defaultMessage: 'Third-Party Interest'
    },
    riskAnalysisPage: {
        id: 'endorsement.eh.directives.templates.eh-policy-changes.Risk Analysis',
        defaultMessage: 'Risk Analysis'
    }
});
