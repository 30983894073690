import { defineMessages } from 'react-intl';

export default defineMessages({
    unableToSavePriorCarrier: {
        id: 'riskAnalysis.rewrite.tabLabel.Unable to Update Prior Carriers',
        defaultMessage: 'Unable to Update Prior Carriers'
    },
    reportsErrorTitle: {
        id: 'rewrite.ea.views.riskAnalysis.Error Retrieving Auto Losses',
        defaultMessage: 'Error Retrieving Auto Losses'
    },
    reportsErrorMessage: {
        id: 'rewrite.ea.views.riskAnalysis.An error occurred attempting to retrieving Auto Losses.',
        defaultMessage: 'An error occurred attempting to retrieving Auto Losses.'
    },
    loading: {
        id: 'rewrite.ea.views.riskAnalysis.Loading changes...',
        defaultMessage: 'Loading changes...'
    },
    loadingNextPageMessage: {
        id: 'rewrite.ea.views.riskAnalysis.Loading next Page...',
        defaultMessage: 'Loading next page...'
    },
});
