import React, {
    useCallback, useContext, useState, useEffect
} from 'react';
import {
    get as _get,
    set as _set,
    includes as _includes
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { RandomNumberUtil } from 'e1p-portals-util-js';
import { isRequired } from 'e1p-portals-required-validator-js';
import '../PolicyDetailsPage.messages';
import {
    useViewPolicyUtil
} from 'e1p-capability-hooks';
import appConfig from 'app-config';
import metadata from '../PolicyDetailsPage.metadata.json5';
import requiredMetadata from '../PolicyDetailsPage.requiredness';

const LOB = 'personalAuto_EA';

function ViewPolicyDetailsPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        authUserData,
        steps,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        markFurthestStep,
        changeNextSteps,
        currentStep
    } = props;
    const translator = useTranslator();
    const [requiredFields, updateRequiredFields] = useState([]);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const { authHeader } = useAuthentication();
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const sessionIdPrefixEdit = 'EA - VW - ';
    const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');
    const sessionHeader = sessionIdPrefixEdit + RandomNumberUtil.randomFixedInteger(10);
    const { opCo } = useContext(AmfamOktaTokenContext);
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');

    // Add assignment page if the state is enabled
    const adjustStepsForState = useCallback(async () => {
        // check for enabled state
        const enabledStates = appConfig.driverAssignmentStates;
        const nextSteps = [...steps];
        const driverAssignmentStepIndex = nextSteps.findIndex((step) => step.path === '/view-assignment');

        // not needed for state and does not exist in steps already
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex < 0) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // not needed for the state, but previous state selected needed it
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.splice(driverAssignmentStepIndex, 1); // remove
            nextSteps.shift(); // remove current page (always first elt)
            changeNextSteps(nextSteps);

            return nextSteps;
        }

        // needed for the state, and already has it
        if (enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // needed for the state and not already in the steps
        const driverAssignmentPage = {
            id: 'E1PEADriverAssignmentPage',
            title: {
                id: 'quoteandbind.ea.wizard.step.Drivers Assignment',
                defaultMessage: 'Driver Assignment'
            },
            path: '/view-assignment',
            component: 'EAViewDriverAssignmentPage',
            stepProps: {
                template: 'WizardPageTemplateWithTitle'
            }
        };
        const indexOfVehiclePage = nextSteps.findIndex((step) => step.path === '/view-risk-analysis');

        // insert driver assignment
        nextSteps.splice(indexOfVehiclePage, 0, driverAssignmentPage)
        // remove current page (always first elt)
        nextSteps.shift();
        changeNextSteps(nextSteps);

        return nextSteps;
    }, [changeNextSteps, policyState, steps]);

    const {
        getLandingPageIndexForViewOnlyPolicy,
        getProducerDetails
    } = useViewPolicyUtil();

    useEffect(() => {
        authHeader['afe-session-id'] = sessionHeader;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // steps need to change before jumping
        adjustStepsForState().then((newSteps) => {
            // add current step back because we need all steps
            newSteps.unshift(currentStep);

            // in view only flow we want to make each step as visited so that user
            // can navigate to any page by clicking on tabs on chevron
            if (isPageJumpEnabled) {
                updateIsPageJumpEnabled(false);
                jumpTo(getLandingPageIndexForViewOnlyPolicy(LOB, newSteps, 'Submission'), true);
            }

            markFurthestStep((newSteps.length - 1));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initialRequiredFields = ['applicantsResideInSameStateInd', 'isUmbrellaAssociated']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
    }, [policyState]);

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            _set(submissionVM, 'baseData.producerCode_Ext', producerCode);
            _set(submissionVM, 'baseData.externalID_Ext.value', externalID);
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [submissionVM],
    );

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(() => submissionVM, [submissionVM]);

    useEffect(() => {
        let isMounted = true;

        if (submissionVM.baseData.externalID_Ext?.value) {
            getProducerDetails(
                submissionVM.baseData.externalID_Ext.value, authHeader
            ).then((details) => {
                if(isMounted)
                {
                    setProducerCodeDetails(details);
                    setAgencyName(details.agencyName);
                    setBookTransferIndicator(details.bookTransferIndicator);
                    setServiceCenterIndicator(details.serviceCenterIndicatorValue);
                }
            }).catch((error) => {
                /**
                 * E1PAP1PC-15099 :
                 * This is rare scenario; will never happen in production
                 * When we bound policy using dummy producer code
                 * and when other user tries to view this policy we get exception from service
                 */
                if (submissionVM.baseData.value.exceptions_Ext) {
                    submissionVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        submissionVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(submissionVM);
            });
        }

        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM.baseData.producerCode_Ext.value]);

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: undefined,
            onValidate: () => {},
            onRemoveSni: undefined,
            disclosureLinkHandler: undefined,
            disclosureCheckHandlerClick: undefined,
            guidelinesLinkHandler: undefined,
            guidelinesCheckHandler: undefined,
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        policyDetailsPageLoadingIndicator: {
            loaded: true
        },
        effectiveDateValidationMessage: {
            visible: false
        },
        sniContainer: {
            visible: !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        coverageStartDate: {
            dateDTO: submissionVM.baseData.periodStartDate,
            defaultToToday: true,
            showErrors: false
        },
        coverageEndDate: {
            dateDTO: submissionVM.baseData.periodEndDate,
            updateDateDto: () => {},
            readOnly: true
        },
        personalInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        originalEffectiveDate: {
            dateDTO: submissionVM.baseData.periodStartDate,
            updateDateDto: () => {},
            readOnly: true,
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        dateOfBirth: {
            updateDateDto: () => {},
            dateDTO: submissionVM.baseData?.accountHolder?.dateOfBirth
        },
        addSni: {
            visible: false
        },
        removeSni: {
            visible: false
        },
        secondaryNamedInsured: {
            visible: !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            viewOnlyMode: true
        },
        noSecondaryNamedInsuredMessageID: {
            visible: !_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        accountSearchComponent: {
            visible: false
        },
        submissionPolicyType: {
            readOnly: true
        },
        mailingAndBillingAddressComponent: {
            transactionVM: submissionVM,
            viewOnlyMode: true,
            lob: LOB
        },
        excessLiabilityInd: {
            visible: _includes(requiredFields, 'isUmbrellaAssociated'),
        },
        e1pAcknowledgementComponent: {
            acknowledgements: _get(submissionVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: _get(submissionVM, 'baseData.policyAddress.state.value.code'),
                name: translator({ id: _get(submissionVM, 'baseData.policyAddress.state.value.name') })
            },
            viewOnly: true,
            lob: LOB
        },
        insuredResidenceAddress: {
            addressVM: _get(submissionVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            viewOnlyMode: true
        },
        primaryNamedInsured: {
            viewOnlyMode: true,
            isPNI: true
        },
        locationCode: {
            visible: isAgent
        },
        ExternalProducerDetailsComponent: {
            viewOnlyMode: true,
            visible: !isAgent,
            defaultValue: submissionVM.baseData.externalID_Ext?.value
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        changePniComponent: {
            visible: false
        },
        changeSniComponent: {
            visible: false
        },
        EaRatingDateTestComponent: {
            visible: false
        },
        applicantsResideInSameStateInd: {
            visible: _includes(requiredFields, 'applicantsResideInSameStateInd'),
        },
        quoteCreationDate: {
            dateDTO: submissionVM.baseData.quoteCreationDate_Ext,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        rateAsOfDate: {
            dateDTO: submissionVM.baseData.periodRateAsOfDate,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        ratingDate: {
            dateDTO: submissionVM.baseData.ratingDate,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        agencyInformationPageContainer: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: submissionVM,
            authHeader,
            LOB: 'personalAuto_EA',
            viewOnlyMode: true
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

ViewPolicyDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewPolicyDetailsPage);
