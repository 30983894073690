import { defineMessages } from 'react-intl';

export default defineMessages({
    vendorPriorCarrierResults: {
        id: 'quoteandbind.ea.views.ea-underwriting-details.Self-Declared Prior Carriers',
        defaultMessage: 'Self-Declared Prior Carriers'
    },
    insuredPriorCarrierResults: {
        id: 'quoteandbind.ea.views.ea-underwriting-details.Prior Carrier Report',
        defaultMessage: 'Prior Carrier Report'
    }
});
