import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class ReinstatementService {
    static createPolicyReinstatementTransaction(reinstatementDTO, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('reinstatement'),
            'createPolicyReinstatementTransaction', [reinstatementDTO], additionalHeaders);
    }

    static quote(jobNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('reinstatement'),
            'quote', [jobNumber], additionalHeaders);
    }

    static retrieve(jobNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('reinstatement'),
            'retrieve', [jobNumber], additionalHeaders);
    }

    static bind(jobNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('reinstatement'),
            'bind', [jobNumber], additionalHeaders);
    }

    static withdraw(jobNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('reinstatement'),
            'withdraw', [jobNumber], additionalHeaders);
    }

    static getUWIssuesForReinstatement(jobNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayreinstatement'),
            'getUWIssuesForReinstatement', [jobNumber], additionalHeaders);
    }
}
