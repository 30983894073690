import React, {
    useCallback, useContext, useEffect, useState, useMemo, useRef
} from 'react';
import {
    get as _get, set as _set, findIndex as _findIndex, some as _some,
    isUndefined as _isUndefined
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps, WizardPageTemplateWithTitle } from 'e1p-portals-wizard-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    useSniUtil,
    useViewPolicyUtil
} from 'e1p-capability-hooks';
import { TransactionViewFlowUtil } from 'e1p-portals-util-js';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import metadata from '../InsuredDetailsPage.metadata.json5';
import viewOnlyWizardConfig from '../../../config/ViewOnly/eu-view-wizard-config.json5';
import EUViewVehicleOperatorPage from '../../VehicleOperator/ViewOnly/ViewVehicleOperatorPage';

const LOB = 'personalUmbrella_EU';

function EUViewRenewalInsuredDetailsPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        jumpTo,
        markFurthestStep,
        currentStepIndex,
        changeNextSteps,
        currentStep
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const {
        getLandingPageIndexForViewOnlyPolicy,
        getProducerDetails
    } = useViewPolicyUtil();

    const { createSecondaryNamedInsuredVM, removeSni } = useSniUtil(
        renewalVM,
        updateWizardData,
        viewModelService,
        LOB,
        () => {}
    );

    const underlyingPoliciesVM = useMemo(() =>
        _get(renewalVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []),
        [renewalVM]);

    const generateOverrides = useCallback(() => {
        const overrides = {};

        overrides.secondaryNamedInsured = {
            viewOnlyMode: true,
            hideSSN: true
        };

        overrides.primaryNamedInsured = {
            viewOnlyMode: true,
            hideSSN: true,
            isPNI: true
        };

        return overrides;
    }, []);

    const addNewPrimaryNamedInsured = useCallback(() => {
        const primaryNamedInsuredObj = {
            person: {
                lastName: '',
                firstName: '',
                dateOfBirth: {
                    year: undefined,
                    month: undefined,
                    day: undefined
                },
                // save primary address
                primaryAddress: (() => {
                    // default new person address to prior pni
                    const priorPniAddress = _get(
                        renewalVM,
                        `lobData[${LOB}].primaryNamedInsured.person.primaryAddress.value`
                    );

                    // Needs new public ID to make it a new adress in the DB
                    _set(priorPniAddress, 'publicID', undefined);

                    return priorPniAddress;
                })()
            }
        };
        const { _dtoName, _xCenter } = renewalVM.lobData[LOB].primaryNamedInsured;
        const primaryNamedInsuredVM = viewModelService.create(
            primaryNamedInsuredObj,
            _xCenter,
            _dtoName
        );

        _set(
            renewalVM,
            `lobData[${LOB}].primaryNamedInsured.value`,
            primaryNamedInsuredVM.value
        );
        updateWizardData(renewalVM);
    }, [renewalVM, updateWizardData, viewModelService]);

    const onNext = useCallback(() => renewalVM, [renewalVM]);

    useEffect(() => {
        // keeping the IF condition here, but under no circumstances should it be undefined
        if (renewalVM.baseData.externalID_Ext?.value) {
            getProducerDetails(
                renewalVM.baseData.externalID_Ext.value, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
            }).catch((error) => {
                /**
                 * E1PAP1PC-15099 :
                 * This is rare scenario; will never happen in production
                 * When we bound policy using dummy producer code
                 * and when other user tries to view this policy we get exception from service
                 */
                if (renewalVM.baseData.value.exceptions_Ext) {
                    renewalVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        renewalVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(renewalVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const differentAutoExposures = ['personalauto', 'personalaononowner', 'otherspecialityvehicle', 'cycle'];

    const vehicleOperatorPage = useRef({
        id: 'EURenewalVehicleOperatorPage',
        path: '/view-vehicle-operator',
        component: EUViewVehicleOperatorPage,
        title: {
            id: 'renewal.eu.directives.templates.page.Vehicle Operator',
            defaultMessage: 'Vehicle Operator',
        },
        stepProps: {
            template: WizardPageTemplateWithTitle
        },
    }).current;

    const handleVehicleOperatorPage = useCallback(async () => {
        const remainingSteps = viewOnlyWizardConfig.steps.slice(currentStepIndex + 1, viewOnlyWizardConfig.steps.length);
        const indexToUnderlyingPolicyPage = _findIndex(
            remainingSteps, (step) => step.path === '/view-underlying-policy'
        );
        const vehicleUnderlyingPolicyExists = _some(underlyingPoliciesVM.value,
            (policy) => differentAutoExposures.includes(policy.policyType));

        if (vehicleUnderlyingPolicyExists) {
            remainingSteps.splice(indexToUnderlyingPolicyPage + 1, 0, vehicleOperatorPage);
        }

        changeNextSteps(remainingSteps);

        return remainingSteps;
    }, [changeNextSteps, currentStepIndex, differentAutoExposures, underlyingPoliciesVM.value, vehicleOperatorPage]);

    useEffect(() => {

        if (_isUndefined(underlyingPoliciesVM.value)) {
            _set(underlyingPoliciesVM, 'value', []);
        }

        handleVehicleOperatorPage().then((remainingSteps) => {
            TransactionViewFlowUtil.jumpToPageAndMarkFurthestVisitedStep(
                remainingSteps, currentStep,
                isPageJumpEnabled, updateIsPageJumpEnabled, jumpTo,
                getLandingPageIndexForViewOnlyPolicy, markFurthestStep,
                LOB, _get(renewalVM, 'baseData.value.jobType')
            );
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            disabled: true
        },
        insuredDetailsPageLoadingIndicator: {
            loaded: true
        },
        insuredDetailsPageContainer: {
            visible: true
        },
        periodStartDate: {
            dateDTO: renewalVM.baseData.periodStartDate,
        },
        periodEndDate: {
            dateDTO: renewalVM.baseData.periodEndDate,
            disabled: true
        },
        policyInformationInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        agencyName: {
            value: producerCodeDetails.agencyName
        },
        bookTransferIndicator: {
            value: producerCodeDetails.bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: producerCodeDetails.serviceCenterIndicatorValue
        },
        sniInformation: {
            visible: !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        sniContainer: {
            visible: !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        addSni: {
            visible: false
        },
        changeSniComponent: {
            visible: false
        },
        addNewPni: {
            visible: false
        },
        swapPniSni: {
            visible: false
        },
        changePniComponent: {
            visible: false
        },
        insuredResidenceAddressWarningMessageDiv: {
            visible: false
        },
        mailingAndBillingAddressComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            onValidate: () => {},
            lob: LOB,
            viewOnlyMode: true
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        removeSni: {
            visible: false
        },
        policyType: {
            value: 'Umbrella'
        },
        insuredResidenceAddress: {
            addressVM: _get(renewalVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate: () => {},
            onAddressChange: () => { },
            viewOnlyMode: true
        },
        internalProducerDetailsComponent: {
            defaultValue: renewalVM.baseData.externalID_Ext?.value,
            visible: true,
            viewOnlyMode: true
        },
        locationCode: {
            visible: false
        },
        agencyInformationMainDiv: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: renewalVM,
            authHeader,
            LOB,
            viewOnlyMode: true
        },
        renewalTermEffectiveDate: {
            dateDTO: renewalVM.baseData.effectiveDate,
            updateDateDto: () => {},
            readOnly: true,
            showErrors: false,
            onValidate: () => {}
        },
        ...generateOverrides()
    };


    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate: () => {},
            onRemoveSni: removeSni,
            onAddNewPni: addNewPrimaryNamedInsured,
            onSwapPniSni: () => {}
        }
    };

    const readValue = (id, path) => readViewModelValue(metadata.pageContent, renewalVM, id, path, overrideProps);

    return (
        <WizardPage
            onNext={onNext}
            showRenewalPrevious={false}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={() => {}}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

EUViewRenewalInsuredDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(EUViewRenewalInsuredDetailsPage);
