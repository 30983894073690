import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PEEAWizard } from 'e1p-capability-gateway-quote-ea-react';
import { PEEHWizard } from 'e1p-capability-gateway-quote-eh-react';
import { PEPolicyViewCommonWizard } from 'e1p-capability-gateway-policy-view-common-react';
import { PEEUWizard } from 'e1p-capability-gateway-quote-eu-react';
import { EAPolicyChangeWizard } from 'e1p-capability-policychange-ea-react';
import { EHPolicyChangeWizard } from 'e1p-capability-policychange-eh-react';
import { PolicyCancelWizard } from 'e1p-capability-policycancel-common-react';
import { PolicyReinstatementWizard } from 'e1p-capability-reinstatement-common-react';
import { PEEARewriteWizard } from 'e1p-capability-rewrite-ea-react';
import { PEEHRewriteWizard } from 'e1p-capability-rewrite-eh-react';
import { PEEURewriteWizard } from 'e1p-capability-rewrite-eu-react';
import { EARenewalWizard } from 'e1p-capability-renewal-ea-react';
import { EURenewalWizard } from 'e1p-capability-renewal-eu-react';
import { EHRenewalWizard } from 'e1p-capability-renewal-eh-react';
import { EUPolicyChangeWizard } from 'e1p-capability-policychange-eu-react';
import { CapabilityRoute } from 'gw-components-platform-react';
import {
    Accounts,
    Policies,
    Activities,
    Analytics,
    AccountDetails,
    PolicyDetails,
    SearchResults,
    QuoteDetails,
    Renewal,
    Endorsement,
    Cancellation,
    Reinstatement,
    ContactForAssistance,
    Rewrite,
    RedirectComponent,
    AccountsDashboard,
    PoliciesDashboard
} from 'e1p-capability-gateway-react';
import { Preferences } from 'gw-pages-platform-react';
import { PolicyDocumentError } from 'gw-capability-document-react';
import { E1PNewQuoteRedirectComponent } from 'e1p-capability-policyjob-react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';

import appConfig from 'app-config';

const { gatewayAnalytics } = appConfig;

function PageRouting() {
    return (
        <Switch>
            <Route exact path="/" component={RedirectComponent} />
            <Route exact path="/accounts" component={AccountsDashboard} />
            {/* keeping the old dashboard, so that we can verify the data, also if we need to revert in case of performance issues */}
            <Route exact path="/oldaccountsdashboard" component={Accounts} />
            <Route exact path="/oldpolicies" component={Policies} />
            <Route exact path="/policies" component={PoliciesDashboard} />
            <Route path="/activities" component={Activities} />
            {gatewayAnalytics && <Route path="/analytics" component={Analytics} />}
            <Route path="/accounts/:accountNumber" component={AccountDetails} />
            <Route path="/policies/:policyNumber" component={PolicyDetails} />
            <CapabilityRoute capability="quote" path="/new-quote-redirect" component={E1PNewQuoteRedirectComponent} />
            <CapabilityRoute capability="quote" lob="ea" path="/quote-ea" component={PEEAWizard} />
            <CapabilityRoute capability="quote" lob="eh" path="/quote-eh" component={PEEHWizard} />
            <CapabilityRoute capability="quote" lob="eu" path="/quote-eu" component={PEEUWizard} />
             <CapabilityRoute capability="rewrite" path="/rewrite/view/:jobNumber" component={Rewrite} />
            <CapabilityRoute capability="rewrite" lob="ea" path="/rewrite/rewrite-ea" component={PEEARewriteWizard} />
            <CapabilityRoute capability="rewrite" lob="eh" path="/rewrite/rewrite-eh" component={PEEHRewriteWizard} />
            <CapabilityRoute capability="rewrite" lob="eu" path="/rewrite/rewrite-eu" component={PEEURewriteWizard} />
            <CapabilityRoute capability="policychange" lob="ea" path="/endorsement-ea" component={EAPolicyChangeWizard} />
            <CapabilityRoute capability="policychange" lob="eu" path="/endorsement-eu" component={EUPolicyChangeWizard} />
            <CapabilityRoute capability="policychange" lob="eh" path="/endorsement-eh" component={EHPolicyChangeWizard} />
            <CapabilityRoute capability="policyview" path="/policy-view" component={PEPolicyViewCommonWizard} />
            <CapabilityRoute capability="policycancel" path="/cancel" component={PolicyCancelWizard} />
            <Route path="/reinstatement/view/:jobNumber" component={Reinstatement} />
            <CapabilityRoute capability="reinstatement" path="/reinstatement" component={PolicyReinstatementWizard} />
            <CapabilityRoute capability="reinstatement" path="/reinstatement/:jobNumber" component={PolicyReinstatementWizard} />
            <Route exact path="/search/:searchText" component={SearchResults} />
            <Route exact path="/preferences" component={Preferences} />
            <CapabilityRoute capability="quote" path="/quotes/:jobNumber" component={QuoteDetails} />
            <CapabilityRoute capability="renewal" path="/renewal/:jobNumber" component={Renewal} />
            <CapabilityRoute capability="renewal" lob="ea" path="/renewal-ea" component={EARenewalWizard} />
            <CapabilityRoute capability="renewal" lob="eu" path="/renewal-eu" component={EURenewalWizard} />
            <CapabilityRoute capability="renewal" lob="eh" path="/renewal-eh" component={EHRenewalWizard} />
            <CapabilityRoute capability="policychange" path="/change/:jobNumber" component={Endorsement} />
            <Route path="/cancellation/:jobNumber" component={Cancellation} />
            <Route exact path="/contact-for-assistance/" component={ContactForAssistance} />
            <CapabilityRoute capability="document" exact path="/documenterror" component={PolicyDocumentError} />
            <Route render={() => <Redirect to="/" />} />
        </Switch>
    );
}

PageRouting.propTypes = {
    authUserData: PropTypes.shape({
        userType: PropTypes.string
    })
};

PageRouting.defaultProps = {
    authUserData: undefined
}

export default withAuthenticationContext(PageRouting);
