import { defineMessages } from 'react-intl';

export default defineMessages({
    contactUnderwriter: {
        id: 'endorsement.eh.confirmationPage.Changes automatically applied contact underwriter',
        defaultMessage: 'Changes automatically applied contact underwriter'
    },
    changeBoundSuccessfully: {
        id: 'endorsement.eh.confirmationPaged.Your change was bound successfully',
        defaultMessage: 'Your change was bound successfully'
    },
    backToPolicy: {
        id: 'endorsement.eh.confirmationPage.Back To Policy',
        defaultMessage: 'Back To Policy'
    },
    changeBound: {
        id: 'endorsement.eh.confirmationPage.Change Bound',
        defaultMessage: 'Change Bound'
    }
});
