import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { Wizard } from 'e1p-portals-wizard-react';
import { PolicyService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import wizardConfig from './config/reinstatement-wizard-config.json5';
import wizardStepToFieldMapping from './config/wizard-step-to-field-mapping.json5';
import messages from './PolicyReinstatementWizard.messages';
import styles from './PolicyReinstatementWizard.module.scss';

function PolicyReinstatementWizard(props) {
    const modalApi = useModal();
    const { steps, title } = wizardConfig;
    const [initialPolicy, setInitialPolicy] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history, authHeader } = props;

    useEffect(
        () => {
            if (!location.state) {
                history.push('/');

                return;
            }

            const { state: { policyNumber } } = location;

            if (!policyNumber) {
                return;
            }

            const errorModalBox = (errorMessage) => {
                modalApi.showConfirm(errorMessage).then(() => {
                    setIsLoading(false);
                    history.push(`/policies/${policyNumber}/summary`);
                });
            };

            PolicyService.getPolicy(policyNumber, authHeader)
                .then((responseData) => {
                    const policy = viewModelService.create(
                        responseData,
                        'pc',
                        'edge.capabilities.gateway.policy.dto.PolicyDTO'
                    );

                    setInitialPolicy(policy);
                    setIsLoading(false);
                }).catch(() => {
                    errorModalBox({
                        title: commonMessages.unableToLoadDraftPolicy,
                        message: commonMessages.somethingWentWrong,
                        status: 'warning',
                        icon: 'mi-error-outline'
                    });
                });
        }, // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleOnCancel = useCallback((cancelProps) => {
        const { param = {} } = cancelProps;

        return modalApi.showConfirm({
            title: messages.exitTransaction,
            message: messages.infoWillBeSavedAsDraft,
            confirmButtonText: e1pCommonMessages.exitTransactionLabel,
            cancelButtonText: messages.cancelLabel
        }).then((results) => {
            if (results === 'cancel') {
                return _.noop();
            }

            setIsLoading(true);

            const jobIDFromLocation = _.get(location, 'state.policyNumber');
            const redirectPath = `/policies/${jobIDFromLocation}/summary`;
            const { search, pathname: nextPath = '' } = param;

            if (nextPath?.includes('/quote-') || !_.isEmpty(nextPath)) {
                return history.push({
                    pathname: nextPath,
                    search
                });
            }

            return history.push(redirectPath);
        });
    }, [history, location, modalApi]);


    const onHandleFinish = useCallback(({ wizardData }) => {
        setIsLoading(true);

        const jobNumber = wizardData.jobID.value;

        return history.push({
            pathname: `/reinstatement/view/${jobNumber}/summary`,
            isRouteChangedDuringFlow: true
        });
    }, [history]);


    const handleError = useCallback((error) => {
        const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const jobIDFromLocation = _.get(location, 'state.policyNumber');
        const redirectPath = `/policies/${jobIDFromLocation}/summary`;
        const state = {
            pathname: redirectPath,
            state: error
        };

        return modalApi.showAlert({
            status: 'error',
            icon: 'mi-error-outline',
            title: isQuotePage ? messages.cancelQuoteError : messages.anErrorOccurredTitle,
            message: isQuotePage ? messages.cancelQuoteErrorMessage : messages.anErrorOccurred,
        }).then(() => <Redirect to={state} />);
    }, [location, modalApi]);

    if (isLoading) {
        return (
            <E1PLoader loaded={!isLoading} />
        );
    }

    if (!initialPolicy) {
        return null;
    }

    const { state: { jobID } } = location;

    return (
        <div className={styles.hoPolicyChangeWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    onFinish={onHandleFinish}
                    initialData={{
                        policyVM: initialPolicy,
                        jobNumber: jobID

                    }}
                    wizardStepToFieldMapping={wizardStepToFieldMapping}
                    onCancel={handleOnCancel}
                    onPreviousModalProps={{
                        title: platformMessages.wantToJump,
                        message: platformMessages.wantToJumpMessage,
                        messageProps: {
                            ok: platformMessages.yes,
                            close: platformMessages.no
                        }
                    }}
                />
            </ErrorBoundary>
        </div>
    );
}

PolicyReinstatementWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            jobID: PropTypes.string
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,

};

export default withAuthenticationContext(PolicyReinstatementWizard);
