import React, { Component } from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    TranslatorContext,
    withIntl
} from '@jutro/locale';
import _ from 'lodash';
import appConfig from 'app-config';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { BrandContentService } from 'e1p-capability-gateway';
import { DropdownMenuAvatar } from '@jutro/components';
import { DropdownMenuLink } from '@jutro/router';
import { e1pUserAccessUtil } from 'e1p-capability-hooks';
import { withAmfamOktaTokenContext } from '../E1PStateManagement/AmfamOktaTokenContext';

import OpCoSelect from '../OpCoSelect/OpCoSelect';
import metadata from './PageHeaderComponent.metadata.json5';

import pageHeaderStyles from './PageHeaderComponent.module.scss';

import messages from './PageHeaderComponent.messages';


class PageHeaderComponent extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func,
            location: PropTypes.shape({
                pathname: PropTypes.string
            })
        }).isRequired,
        logout: PropTypes.func.isRequired,
        authUserData: PropTypes.shape({
            displayName: PropTypes.string,
            userType: PropTypes.string
        }).isRequired
    };

    static contextType = TranslatorContext;

    state = {
        isLogout: false,
        showStartNewButton: false,
        brandedLogoLink: '',
        searchText: '',
        showError: false
    };

    componentDidMount() {
        this.getShowStartNewQuoteButton();
        this.getBrandedLogoLink();
    };

    componentDidUpdate(prevProps) {
        const { authUserData, opCo } = this.props;

        if (!prevProps.authUserData && authUserData) {
            this.getShowStartNewQuoteButton();
        }

        if (prevProps.opCo !== opCo) {
            this.getBrandedLogoLink(opCo);
        }
    }

    getBrandedLogoLink = async (changedOpCo = undefined) => {
        const { authHeader, opCo } = this.props;
        const partnerMap = {
            MSA: '529',
            CONNECT: '540'
        }
        
        let partner = partnerMap[opCo];

        if (changedOpCo) {
            partner = partnerMap[changedOpCo];
        }

        // hardcoded for local; mock should be adjusted for PC
        if (partner === '540') {
            const connectUrl = 'https://e1p-branding-cdn-01-preprod.amfam.com/Brand_ADVSR.jpg'

            this.setState({ brandedLogoLink: connectUrl || '' });

            return;
        }

        BrandContentService.getLogo(
            partner,
            authHeader
        ).then((response) => {
            this.setState({ brandedLogoLink: response?.logoLink || '' });
        }
        ).catch(() => {
            // If branding service fails we will display stored branding images
            let brandImage = '';
            let opCoForBranding = opCo;

            if(changedOpCo){
                opCoForBranding = changedOpCo; 
            }

            switch (opCoForBranding) {
                case 'MSA':
                    brandImage = 'branding/Brand_MSA.jpg';
                    break;
                case 'CONNECT':
                    brandImage = 'branding/Brand_CONNECT.jpg';
                    break;
                default:
                    brandImage = 'branding/Brand_MSA.jpg';
                    break;
            }

            this.setState({ brandedLogoLink: brandImage });
        });

    }

    getShowStartNewQuoteButton = async () => {
        const { authUserData, opCo } = this.props;
        const { isNewQuoteFunctionalityVisible } = e1pUserAccessUtil(authUserData, opCo);
        const showStartNewButton = authUserData?.permissions_Ext.includes('createsubmission')
            && authUserData?.permissions_Ext.includes('accountcreate')
            /**
             * IAP-5668 - Hide New Quote Button in Producer Engage Header
             *  This will be reverted once we remove accountcreate permission from agent in PC
             */
            &&  isNewQuoteFunctionalityVisible()

        this.setState({ showStartNewButton });
    };

    writeValue = (value) => {
        this.setState({ searchText: value });
    };

    renderTrigger = () => {
        const { authUserData } = this.props;
        const translator = this.context;

        return (
            <DropdownMenuAvatar
                id="dropdown"
                isOpen={false}
                subtitle=""
                title={authUserData.displayName}
                username={authUserData.displayName}
                alignRight
            >
                {/* <React.Fragment> */}
                    <DropdownMenuLink
                        onClick={() => { window.location.replace('exitapplication') }}
                    >
                        <span>{translator(messages.signOut)}</span>
                    </DropdownMenuLink>
                {/* </React.Fragment> */}
            </DropdownMenuAvatar>
        );
    };

    goToLogout = () => {
        const { isLogout } = this.state;
        const { logout } = this.props;

        if (!isLogout) {
            logout();
            this.setState({
                isLogout: true
            });
        }
    };

    goToNewQuote = () => {
        const { history } = this.props;
        const fromLocation = history.location.pathname;
        const { lobQuoteURL } = appConfig;
        const lobPaths = Object.values(lobQuoteURL) || [];
        const fromLOB = lobPaths.filter((value) => fromLocation.indexOf(value) !== -1);

        const previousLocation = {
            prevLocation: !!fromLOB.length
        };

        return history.push('/new-quote-account-search', previousLocation);
    };

    // Support for drop down menu linking to quote wizard
    goToPageDetails = (evt) => {
        const prodDetails = evt.productDetails;
        const { history } = this.props;
        const { lobQuoteURL } = appConfig;

        return history.push({
            pathname: lobQuoteURL[prodDetails.lob],
            search: `?productCode=${prodDetails.lob}&policyType=${prodDetails.policyType}`,
        });
    };

    goToQuoteThisAccount = () => {
        const { history } = this.props;
        const {
            history: {
                location: { pathname }
            }
        } = this.props;
        const accountNumber = pathname.match('accounts/(.*)/(.*)');

        return history.push(`/new-quote/${accountNumber[1]}`);
    };

    render() {
        const { searchText, showError, showStartNewButton, brandedLogoLink } = this.state;
        const { history, authUserData, opCoConfig, opCo } = this.props;
        const allowedPolicyTypesForOpCo = _.get(opCoConfig, 'allowedPolicyTypes', []);

        const resolvers = {
            resolveClassNameMap: pageHeaderStyles,
            resolveCallbackMap: {
                goToLogout: this.goToLogout,
                goToNewQuote: this.goToNewQuote,
                goToQuoteThisAccount: this.goToQuoteThisAccount,
                goToPageDetails: this.goToPageDetails
            },
            resolveComponentMap: {
                opcoselect: OpCoSelect
            }
        };
        const overrideProps = {
            headerSearchBox: {
                onEnter: () => {
                    if (!searchText) {
                        this.setState({
                            showError: true
                        });

                        return false;
                    }

                    return history.push(`/search/${searchText.toUpperCase()}`);
                },
                onValueChange: this.writeValue,
                value: searchText,
                showErrors: showError,
                autoComplete: false
            },
            gwAuthStatus: {
                renderTrigger: authUserData ? this.renderTrigger : _.noop
            },
            logo: {
                visible: brandedLogoLink !== '',
                style: { backgroundImage: `url(${brandedLogoLink})` }
            },
            newQuoteMenuButton: {
                visible: showStartNewButton
            },
            paeaLink: {
                visible: allowedPolicyTypesForOpCo.includes('EAFamilyCar')
            },
            ho3ehLink: {
                visible: allowedPolicyTypesForOpCo.includes('HO3')
            },
            ho6ehLink: {
                visible: allowedPolicyTypesForOpCo.includes('HO6')
            },
            ho4ehLink: {
                visible: allowedPolicyTypesForOpCo.includes('HO4')
            },
            hf9ehLink: {
                visible: allowedPolicyTypesForOpCo.includes('HF9')
            },
            umbeuLink: {
                visible: allowedPolicyTypesForOpCo.includes('Umbrella')
            },
            moreMenuComponentID: {
                visible: opCo === 'CONNECT'
            }
        };

        return (
            <MetadataContent
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                {...resolvers} />
        );
    }
}

export default withRouter(withIntl(withAuthenticationContext(withAmfamOktaTokenContext(PageHeaderComponent))));
