/*
 * Utilities for loading and retrieving values from the
 * environment-based configuration.
 */
import { get as _get } from 'lodash';
import appConfig from 'app-config';
import devConfig from './dev/config.json5';
import dev2Config from './dev2/config.json5';
import dev3Config from './dev3/config.json5';
import qaConfig from './qa/config.json5';
import qa2Config from './qa2/config.json5';
import intConfig from './int/config.json5';
import int2Config from './int2/config.json5';
import localConfig from './local/config.json5';
import uatConfig from './uat/config.json5';
import perfConfig from './perf/config.json5';
import perf2Config from './perf2/config.json5';
import sbxConfig from './sbx/config.json5';
import prodConfig from './prod/config.json5';

/**
 * Returns the value of the configuration corresponding to
 * the object path given.
 * @param {string} path - The object path
 * @param {*} [defaultValue] - The default value if no value found at given path
 * @returns {*} - The configuration value
 * @throws {Error} - If `required` is true and value if undefined
 */
export default function getConfigValue(path, defaultValue) {
    /**
     * Need to specify AMFAM_ENV=<some env> in the DEPLOY
     *   form in Team city. Do not put it in the build form anymore.
     */
    const environment = _get(appConfig, 'env.AMFAM_ENV', 'local');
    let config;

    switch (environment) {
        case 'dev':
            config = devConfig;
            break;
        case 'dev2':
            config = dev2Config;
            break;
        case 'dev3':
            config = dev3Config;
            break;
        case 'qa':
            config = qaConfig;
            break;
        case 'qa2':
            config = qa2Config;
            break;
        case 'int':
            config = intConfig;
            break;
        case 'int2':
            config = int2Config;
            break;
        case 'uat':
            config = uatConfig;
            break;
        case 'perf':
            config = perfConfig;
            break;
        case 'perf2':
            config = perf2Config;
            break;
        case 'sbx':
            config = sbxConfig;
            break;
        case 'prod':
            config = prodConfig;
            break;
        default:
            config = localConfig;
            break;
    }

    const value = _get(config, path);

    if (defaultValue === undefined && value === undefined) {
        throw new Error(`No configuration defined for '${path}', or default value given`);
    }

    return (value !== undefined) ? value : defaultValue;
}
