import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    get, set, some, findIndex, isEmpty
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { PrefillService } from 'e1p-capability-quoteandbind';
import { RenewalService } from 'e1p-capability-renewal';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { E1PEHHO3PropertyDetailsComponent } from 'e1p-capability-policyjob-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import moment from 'moment';
import wizardMessages from '../../../EHRenewalWizard.messages';

function PropertyPage(props) {
    const {
        wizardData: renewalVM, updateWizardData, isSkipping,
        steps, jumpTo,
        onCustom2: handleDiscardChanges,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [prefillData, setPrefillData] = useState('');
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isReplacementCostStale, setIsReplacementCostStale] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [floorMaterialErrorState, updateFloorMaterialErrorState] = useState(false);
    const [insideWallMaterialPercentageSumErrorState, updateInsideWallMaterialPercentageSumErrorState] = useState(false);
    const isDiscardButtonActive = get(renewalVM, 'value.canDiscard_Ext', false);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [isReplacementRecalculateCompleted, setIsReplacementRecalculateCompleted] = useState(true);
    const [coolingSystemType, updateCoolingSystemType] = useState(undefined);
    const [heatingSystemType, updateHeatingSystemType] = useState(undefined);
    const [isOutstandingChangesPresent, setIsOutstandingChangesPresent] = useState(true);
    const hasReplacementCost = get(renewalVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value', undefined);
    const changeSummaryIndex = findIndex(steps, ({ path }) => path === '/change-summary');

    const {
        isComponentValid,
        initialValidation,
        registerComponentValidation,
        disregardFieldValidation,
        onValidate
    } = useValidation('PropertyPage');

    const isNumberOfFullBathsValid = useMemo(() => {
        const numberOfFullBaths = get(renewalVM, 'lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value');

        return numberOfFullBaths === undefined
            || (numberOfFullBaths >= 1
                && numberOfFullBaths <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renewalVM.lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value]);

    const isNumberOfHalfBathsValid = useMemo(() => {
        const numberOfHalfBaths = get(renewalVM, 'lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value');

        return numberOfHalfBaths === undefined
            || (numberOfHalfBaths >= 0
                && numberOfHalfBaths <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renewalVM.lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value]);

    const isNumberOfRoomsWithCathedralVaultedCeilingsValid = useMemo(() => {
        const numberOfRoomsWithCathedralVaultedCeilings = get(renewalVM, 'lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value');

        return numberOfRoomsWithCathedralVaultedCeilings === undefined
            || (numberOfRoomsWithCathedralVaultedCeilings >= 0
                && numberOfRoomsWithCathedralVaultedCeilings <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renewalVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value]);

    const dateFormat = (date) => {
        const newDate = moment(date).toDate();
        const momentDate = moment(newDate)
            .format('YYYY-MM-DDTHH:mm:ss')
            .concat('Z');

        return momentDate;
    };

    useEffect(() => {
        registerComponentValidation(() => (!insideWallMaterialPercentageSumErrorState
                && !floorMaterialErrorState
                && heatingSystemType !== undefined
                && coolingSystemType !== undefined
                && isNumberOfFullBathsValid
                && isNumberOfHalfBathsValid
                && isNumberOfRoomsWithCathedralVaultedCeilingsValid))
    }, [
        coolingSystemType, floorMaterialErrorState, heatingSystemType,
        insideWallMaterialPercentageSumErrorState,
        isNumberOfFullBathsValid, isNumberOfHalfBathsValid,
        isNumberOfRoomsWithCathedralVaultedCeilingsValid,
        registerComponentValidation
    ])

    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            /**
             * IAP-4661 : allow recalculate on save
             */
            if (!isComponentValid
                || ((!hasReplacementCost
                    || isReplacementCostStale)
                    && !calledFromOnSave)
            ) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            const correctDateFormat = dateFormat(
                renewalVM.lobData.homeowners_EH.priorPolicies.children[0]?.policyExpirationDate.value
            );

            set(
                renewalVM,
                'lobData.homeowners_EH.priorPolicies.children[0].policyExpirationDate.value',
                correctDateFormat
            );
            setIsSavingRenewal(true);

            let serverCall = RenewalService.saveAndQuoteRenewal;

            if (calledFromOnSave && !hasReplacementCost) {
                serverCall = RenewalService.saveRenewal;
                updateIsPageSubmitted(false);
            }

            const saveResponse = await serverCall(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', saveResponse);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);

            return renewalVM;
        },
        [
            isComponentValid, hasReplacementCost, isReplacementCostStale, 
            renewalVM, authHeader, updateWizardData
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    useEffect(() => {
        setIsPageInitialized(true);

        const heatingSystems = get(renewalVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            updateHeatingSystemType(heatingSystems[0].heatingType);
        }

        const coolingSystems = get(renewalVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');

        if (coolingSystems !== undefined && coolingSystems.length > 0) {
            updateCoolingSystemType(coolingSystems[0].coolingType);
        }
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (prefillData !== '') {
            set(renewalVM, 'value.lobData.homeowners_EH.coverables', prefillData.homeowners_EH.coverables);

            const heatingSystems = get(renewalVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

            if (heatingSystems !== undefined && heatingSystems.length > 0) {
                updateHeatingSystemType(heatingSystems[0].heatingType);
            }

            const coolingSystems = get(renewalVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');

            if (coolingSystems !== undefined && coolingSystems.length > 0) {
                updateCoolingSystemType(coolingSystems[0].coolingType);
            }

            updateWizardData(renewalVM);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefillData]);

    // Required fields for recalculate the replacement cost
    const requiredFieldsForReplacementCost = [
        'yearBuilt',
        'numberOfStories',
        'totalSquareFeet',
        'exteriorWallFinish',
        'roofType',
        'roofShape',
        'garages', // has children
        'slopeType',
        'numberOfRoomsWithCathedralVaultedCeilings',
        'numberOfRoomsWithCrownMolding',
        'ceilingHeightType',
        'numberOfFullBaths',
        'numberOfHalfBaths',
        'kitchenCounterTopType',
        'foundationType',
        'basementFinishedArea',
        'heatingSystems',
        'coolingSystems',
        'insideWallMaterials', // has children
        'floors', // has children
    ];
    // /**
    //  * isRecalculateReplacementCostDisable, will save Boolean value, which will be used to enable or disable
    //  * the recalculate ReplacementCost button on UI, if any of the value in requiredFieldsForReplacementCost array
    //  * is undefined or empty, then it will returns true, else false.
    //  */
    const isRecalculateReplacementCostDisable = (subVM) => {
        const isDisabled = some(requiredFieldsForReplacementCost, (requiredField) => {
            const requiredFieldValue = get(subVM.lobData.homeowners_EH.coverables.construction, `${requiredField}.value`);

            // requiredFieldValue could be string, integer, or array.
            if (Array.isArray(requiredFieldValue)) {
                return requiredFieldValue.length === 0;
            }

            return requiredFieldValue === undefined || requiredFieldValue === '';
        });

        return isDisabled || !isComponentValid;
    };

    const isRecalculateRequired = !hasReplacementCost && !isRecalculateReplacementCostDisable(renewalVM);

    const recalculateReplacementCost = useCallback(
        () => {
            if (isRecalculateRequired) {
                setIsReplacementRecalculateCompleted(false);

                const PrefillServicePromise = PrefillService.orderReplacementCost(
                    renewalVM.jobID.value,
                    renewalVM.lobData.value,
                    authHeader
                );

                PrefillServicePromise
                    .then((response) => {
                        setIsReplacementCostStale(false);
                        setPrefillData(response);
                        setIsReplacementRecalculateCompleted(true);
                        setIsOutstandingChangesPresent(true);
                        // E1PAP1PC-15700 : save all data till this point
                        set(
                            renewalVM.lobData.homeowners_EH.coverables,
                            'value',
                            response.homeowners_EH.coverables
                        );
                        set(renewalVM, 'value.flowStepIDs_Ext', ['property']);
                        RenewalService.saveRenewal(
                            [renewalVM.value],
                            authHeader
                        ).then((saveRenewalResp) => {
                            set(renewalVM, 'value', saveRenewalResp);
                            updateWizardData(renewalVM);
                            updateWizardSnapshot(renewalVM);
                        }).finally(() => {
                            setIsReplacementRecalculateCompleted(true);
                        });
                    })
                    .catch(() => {

                        setIsReplacementRecalculateCompleted(true);

                        if (!isEmpty(get(renewalVM, 'baseData.value.exceptions_Ext', []))) {
                            renewalVM.baseData.value.exceptions_Ext.push(
                                { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                            );
                        } else {
                            set(
                                renewalVM.value,
                                `baseData.exceptions_Ext[${0}]`,
                                { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                            );
                        }

                        updateWizardData(renewalVM);
                    });
            } else {
                setIsOutstandingChangesPresent(false);
            }
        }, [isRecalculateRequired, renewalVM, authHeader, updateWizardData, updateWizardSnapshot, translator]);



    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => isPageInitialized && isReplacementRecalculateCompleted && !isSavingRenewal
            && !isSkipping && !isSavingCurrentPageChanges,
        [isPageInitialized, isSavingCurrentPageChanges, isSavingRenewal,
        isSkipping, isReplacementRecalculateCompleted]);

    const reviewChanges = useCallback(
        async () => {
            if (
                !isComponentValid
                || insideWallMaterialPercentageSumErrorState
                || floorMaterialErrorState
                || heatingSystemType === undefined
                || coolingSystemType === undefined
                || !isNumberOfFullBathsValid
                || !isNumberOfHalfBathsValid
                || !isNumberOfRoomsWithCathedralVaultedCeilingsValid
                || !hasReplacementCost
                || isReplacementCostStale
            ) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);
            jumpTo(changeSummaryIndex, true, quoteResponse);

            return false;
        },
        [
            isComponentValid, insideWallMaterialPercentageSumErrorState, floorMaterialErrorState,
            heatingSystemType, coolingSystemType, isNumberOfFullBathsValid, updateWizardData,
            isNumberOfRoomsWithCathedralVaultedCeilingsValid,
            renewalVM, authHeader, isNumberOfHalfBathsValid, jumpTo, changeSummaryIndex,
            hasReplacementCost, isReplacementCostStale
        ]
    );
    const generateOverrides = useCallback(() => {
        const overrideProps = {
            roofAgeMessage: {
                visible: false
            },
            partialPrefillNotification: {
                visible: false
            },
            propertyPrefillUnsuccessfulInfoMessage: {
                visible: false
            },
            covAWillBeUpdatedBasedOnReplacementCostInfoMessage: {
                visible: false
            }
        }

        return overrideProps;
    }, []);

    if (!isPageInitialized) {
        return null;
    }


    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            skipWhen={initialValidation}
            onNext={onNext}
            disableCustom={!isComponentValid}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            onSave={onSave}
            showOnSave
        >
            <E1PEHHO3PropertyDetailsComponent
                onValidate={onValidate}
                transactionVM={renewalVM}
                updateWizardData={updateWizardData}
                viewOnlyMode={false}
                parentOverrideProps={generateOverrides}
                isOutstandingChangesPresent={isOutstandingChangesPresent}
                disregardFieldValidation={disregardFieldValidation}
                recalculateReplacementCost={recalculateReplacementCost}
                updateFloorMaterialErrorState={updateFloorMaterialErrorState}
                updateInsideWallMaterialPercentageSumErrorState={updateInsideWallMaterialPercentageSumErrorState}
                isSavingTransaction={isSavingRenewal}
                isReplacementRecalculateCompleted={isReplacementRecalculateCompleted}
                isSavingCurrentPageChanges={isSavingCurrentPageChanges}
                setIsReplacementCostStale={setIsReplacementCostStale}
                isPageSubmitted={isPageSubmitted}
                isPageInitialized={isPageInitialized}
                isSkipping={isSkipping}
                isRecalculateReplacementCostDisable={isRecalculateReplacementCostDisable}
                insideWallMaterialPercentageSumErrorState={insideWallMaterialPercentageSumErrorState}
                floorMaterialErrorState={floorMaterialErrorState}
                heatingSystemType={heatingSystemType}
                updateHeatingSystemType={updateHeatingSystemType}
                coolingSystemType={coolingSystemType}
                updateCoolingSystemType={updateCoolingSystemType}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
