import { defineMessages } from 'react-intl';

export default defineMessages({
    yourHome: {
        id: 'quoteandbind.eh.wizard.step.Your Home',
        defaultMessage: 'Home'
    },
    construction: {
        id: 'quoteandbind.eh.wizard.step.Construction',
        defaultMessage: 'Construction'
    },
    discount: {
        id: 'quoteandbind.eh.wizard.step.Discount',
        defaultMessage: 'Discount'
    },
    additionalOptional: {
        id: 'quoteandbind.eh.views.rating.Additional (optional)',
        defaultMessage: 'Additional (optional)'
    },
    anErrorOccurred: {
        id: 'quoteandbind.eh.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'quoteandbind.eh.wizard.error.title',
        defaultMessage: 'Error'
    },
    safetyNet: {
        id: 'quoteandbind.eh.views.rating.Trampoline has a safety net',
        defaultMessage: 'Does the trampoline have a safety net?'
    },
    exitTransaction: {
        id: 'quoteandbind.eh.wizard.steps.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'quoteandbind.eh.wizard.steps.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'quoteandbind.eh.wizard.steps.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'quoteandbind.eh.wizard.steps.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    }
});
