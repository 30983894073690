import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, set, isEqual, unset, isEmpty, findIndex
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { VehicleUtil } from 'e1p-portals-util-js';
import { useGaragingAddressUtil, useLandingPageUtil } from 'e1p-capability-hooks';
import { EndorsementService } from 'e1p-capability-policychange';
import { commonMessages as e1pCommonMessages, eaCommonMessages, eaValidationAndInfoMessages } from 'e1p-platform-translations';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import appConfig from 'app-config';
import metadata from './VehiclesPage.metadata.json5';
import messages from './VehiclesPage.messages';

const LOB = 'personalAuto_EA';

function VehiclesPage(props) {
    const modalApi = useModal();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [pageInitialized, setIsPageInitialized] = useState(false);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [vehicleLookupError, setVehicleLookupError] = useState(false);
    const { generateGaragingAddressDisplayName } = useGaragingAddressUtil();
    const { authHeader } = useAuthentication();
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [availableMakes, setAvailableMakes] = useState({});
    const [availableModels, setAvailableModels] = useState({});
    const [availableSeries, setAvailableSeries] = useState({});
    const [indexStale, setIndexStale] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('VehiclePage');

    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    policyChangeVM.flowStepIDs_Ext.value = ['vehicle'];
    policyChangeVM.entryCompletionStepIDs_Ext.value = ['vehicle'];

    const defaultGaragingAddress = get(policyChangeVM, 'baseData.policyAddress.value');

    // array of vehicle objects (not vmnodes)
    const vehiclesObjectArray = get(policyChangeVM, 'lobData.personalAuto_EA.coverables.vehicles.value', []);
    const atleastOneCarBeingAdded = (() => {
        if (vehiclesObjectArray.length > 1) { return true; }

        const make = get(vehiclesObjectArray[0], 'make');
        const model = get(vehiclesObjectArray[0], 'model');
        const year = get(vehiclesObjectArray[0], 'year');
        const vin = get(vehiclesObjectArray[0], 'vin');

        if (vin && year) { return true; }

        if (make && model && year) { return true; }

        return false;
    })();

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    /**
     * Helper callback for creating a new vehicle "VMNode" object and adding it to the list of
     * vehicles on the policy.
     */
    const createAndAddVehicleVM = useCallback(() => {
        const vehicleObj = {
            garagingLocation: defaultGaragingAddress
        };
        const { _xCenter, _dtoName } = policyChangeVM.lobData.personalAuto_EA.coverables.vehicles;
        const vehicleVM = viewModelService.create(vehicleObj, _xCenter, _dtoName);

        if (!vehicleVM.safetyFeatures.value) {
            vehicleVM.safetyFeatures.value = [];
        }

        // vehicleVM.callShowAll = false;
        policyChangeVM.lobData.personalAuto_EA.coverables.vehicles.pushElement(vehicleVM);
        policyChangeVM.lobData.personalAuto_EA.coverables.vehicles.children.forEach((vehicle) => {
            set(vehicle, 'callShowAll', false);
        });
        setCheckScrolling(true);
        setIndexStale(true);

        return updateWizardData(policyChangeVM);
    }, [
        defaultGaragingAddress, policyChangeVM, viewModelService, updateWizardData
    ]);

    /**
     * Helper callback for removing a vehicle from the list of vehicles on the quote.
     */
    const removeVehicleFromQuote = useCallback(
        (evt) => {
            const vehicleListPath = 'lobData.personalAuto_EA.coverables.vehicles.value';
            const currentPath = evt.path;
            const vehiclePath = currentPath.replace(/\.children\[(\d+)\]/, '.children[$1].value');
            const vehicle = get(policyChangeVM, vehiclePath);
            const premiumAdjustments = get(policyChangeVM, 'premiumAdjustments_Ext.value', []);
            const vehicleHasPremiumAdjustmentRecord = findIndex(
                premiumAdjustments,
                (premiumAdjustment) => premiumAdjustment.vehicleID === vehicle.fixedId
            ) !== -1;
            const vehicleList = get(policyChangeVM, vehicleListPath);
            const vehicleIndex = vehicleList.findIndex((vehicleFromList) => isEqual(vehicleFromList, vehicle));

            modalApi.showConfirm({
                title: eaCommonMessages.removeVehicleTitle,
                confirmButtonText: eaCommonMessages.removeVehicle,
                cancelButtonText: e1pCommonMessages.cancel,
                message: vehicleHasPremiumAdjustmentRecord
                    ? messages.premiumAdjustmentMessage : eaCommonMessages.removeVehicleDescription
            }).then((result) => {
                if (result !== 'cancel') {
                    // splice is faster and less confusing than _.remove
                    vehicleList.splice(vehicleIndex, 1);
                    // Jutro numbers instances of iterable. Last instance in dom needs to be disregarded (not deleted index).
                    disregardFieldValidation(`vehicle${vehicleList.length}`);
                    updateWizardData(policyChangeVM);
                    setCheckScrolling(true);
                }
            }, () => { });
        },
        [disregardFieldValidation, policyChangeVM, updateWizardData, modalApi]
    );

    // this grabs the latest element on the page --
    // this is to make sure this element has been loaded
    const latestVehicleElement = document.getElementById(`eavehicleContainer2${policyChangeVM.lobData.personalAuto_EA.coverables.vehicles.children.length - 1}`);

    useEffect(() => {
        // indexStale set in the add vehicle function
        // once latest element is loaded and a new vehicle is added
        // we check if the button should be visible
        if (latestVehicleElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestVehicleElement]);

    const onVehicleLookupError = useCallback(
        (value) => {
            setVehicleLookupError(value);
        },
        []
    );

    /**
     * Helper callback for adding an additional interest to the policy.
     */
    const addAdditionalInterest = useCallback(
        (additionalInterestPath) => {
            const vehicleAdditionalInterests = get(policyChangeVM, additionalInterestPath);

            // initialize additional interest VM
            vehicleAdditionalInterests.value = vehicleAdditionalInterests.value || [];

            const additionalInterestTypedPath = 'lobData.personalAuto_EA.coverables.addInterestTypeCategory';
            const initialValue = {
                policyAdditionalInterest: {
                    subtype: 'Person',
                    primaryAddress: {}
                }
            };
            const initialContext = {
                AdditionalInterestType: get(policyChangeVM, additionalInterestTypedPath)
            };
            const { _xCenter, _dtoName } = vehicleAdditionalInterests;
            const currentAdditionalInterest = viewModelService.create(
                initialValue,
                _xCenter,
                _dtoName,
                initialContext
            );

            vehicleAdditionalInterests.pushElement(currentAdditionalInterest);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData, viewModelService]
    );

    const policyState = get(policyChangeVM, 'baseData.policyAddress.state.value.code');

    /**
     * If state requires drivers assignment and this page has been visited, we will
     *   take the user to the drivers assignment page to check for updates
     */
    const needsDriverAssignmentUpdate = useCallback(
        async (calledFromSave = false) => {
            const enabledStates = appConfig.driverAssignmentStates;
            const driverAssignmentStepIndex = steps.findIndex((step) => step.path === '/assignment');
            const shouldSkipDriverAssignmentPage = VehicleUtil.shouldSkipDriverAssignmentPage(policyChangeVM);

            /**
             * If driver assignment is available for given state and 
             * shouldSkipDriverAssignmentPage indicator(given job has one driver and one vehicle and vehicle is not garaged out fo state more than 6 months) is true 
             * then perform driver assignment for given vehicle
             */
            if (driverAssignmentStepIndex > 0 && shouldSkipDriverAssignmentPage) {
                VehicleUtil.setDriverAssignmentWhenOneDriverOneVehiclePresent(policyChangeVM, updateWizardData);
            }

            if (enabledStates.includes(policyState)) {
                policyChangeVM.value = await EndorsementService.saveEndorsement(
                    [policyChangeVM.value],
                    authHeader
                );
                updateWizardData(policyChangeVM);

                const validationErrs = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const hasNoConflicts = isEmpty(get(policyChangeVM, 'value.conflicts', []));
                const hasNoExceptions = isEmpty(get(policyChangeVM, 'baseData.exceptions_Ext.value', []));

                // Need to stay on the page if field issues
                if (validationErrs.length === 0 && hasNoConflicts && hasNoExceptions) {
                    // Skip driver assignment page
                    updateWizardSnapshot(policyChangeVM)

                    if (driverAssignmentStepIndex > 0 && !calledFromSave && shouldSkipDriverAssignmentPage) {
                        const riskAnalysisPageIndex = findIndex(steps, ({ path }) => path === '/risk-analysis');

                        jumpTo(riskAnalysisPageIndex, true, policyChangeVM.value);
                        
                        return true;
                    }

                    if (calledFromSave && shouldSkipDriverAssignmentPage) {
                        // dont jump to driver assignment as we have defaulted driver assignment
                        // stay on the same page or land to review summary in case onCustom is called
                        return false
                    }

                    jumpTo(driverAssignmentStepIndex, true);

                    return true;
                }

                // needs to but has errors so don't jump
                return true;
            }

 return false;
        },
        [steps, policyChangeVM, policyState, updateWizardData, authHeader, jumpTo, updateWizardSnapshot],
    );

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(async (_, calledFromSave = false) => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setIsSavingEndorsement(true);

        const shouldEndFunctionExecution = await needsDriverAssignmentUpdate(calledFromSave);

        if (shouldEndFunctionExecution) {
            setIsSavingEndorsement(false);

            return false;
        }

        try {
            unset(policyChangeVM.value, 'bindData');


            policyChangeVM.value = await EndorsementService.saveAndQuoteEndorsement(
                [policyChangeVM.value],
                authHeader
            );
            // Below we are setting errors and warnings path to be congruent to the
            // paths in the wizard, so the right warning will pop up thus preventing
            // the user from moving forward if there is a validation issue with the
            // fields or information provided
            set(policyChangeVM, 'errorsAndWarnings', policyChangeVM.errorsAndWarnings_Ext);
            set(policyChangeVM, 'errorsAndWarnings_Ext.value', policyChangeVM.value.errorsAndWarnings);
            updateWizardData(policyChangeVM);
            setIsSavingEndorsement(false);

            return policyChangeVM;
        } catch {
            setIsSavingEndorsement(false);
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: commonMessages.genericError,
                message: commonMessages.genericErrorMessage,
                messageProps: {
                    confirmButtonText: commonMessages.cancelModel
                },
                showCancelBtn: false
            });

            return false;
        }
    }, [isComponentValid, needsDriverAssignmentUpdate, policyChangeVM, authHeader, updateWizardData, modalApi]);

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    /**
                     * E1PAP1PC-14986 :
                     * wizardData and wizardSnapshot not being equal due to
                     * some defaulting on each page so doing this temp fix
                     */
                    policyChangeVM.lobData.personalAuto_EA.coverables
                        .vehicles.children.forEach((vehicle) => {
                            generateGaragingAddressDisplayName(vehicle.garagingLocation);
                        });
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [generateGaragingAddressDisplayName, onNext, policyChangeVM, updateWizardSnapshot]
    );

    /**
     * Helper effect for handling the component initialization logic.
     */
    useEffect(() => {
        if (!pageInitialized) {
            const vehiclesPath = 'lobData.personalAuto_EA.coverables.vehicles';
            const vehicles = get(policyChangeVM, `${vehiclesPath}.value`);

            const newVehicles = vehicles.map((vehicle) => {
                const newVehicle = {
                    ...vehicle,
                    vin: vehicle.vin,
                    costNew: vehicle.costNew
                };

                return newVehicle;
            });

            // Update the submission VM with new list of vehicles
            if (!isEqual(newVehicles, vehicles)) {
                set(policyChangeVM, `${vehiclesPath}.value`, newVehicles);
                updateWizardData(policyChangeVM);
            }

            setIsPageInitialized(true);
        }
    }, [pageInitialized, policyChangeVM, updateWizardData]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        vehiclesPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        vehicleLookupErrorNotificationDiv: {
            visible: vehicleLookupError
        },
        vehiclesPageContainer: {
            visible: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges
        },
        oneCarMinimumNotificationDiv: {
            visible: !atleastOneCarBeingAdded && isPageSubmitted
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleGridContainer')
        },
        vehicleInfoChangedMessage: {
            message: translator(eaValidationAndInfoMessages.vehicleHasUpdatedMessage)
        },
        vehicleInfoChangedMessageDiv: {
            visible: (() => {
                const vehicles = get(policyChangeVM, 'value.lobData.personalAuto_EA.coverables.vehicles', []);
                const doesVehicleInfoChanged = vehicles.some((vehicle) => !!vehicle.vehicleInfoChanged);

                return doesVehicleInfoChanged;
            })()
        },
        EAVehicleGrid: {
            key: get(policyChangeVM, 'lobData.personalAuto_EA.coverables.vehicles', []).length,
            vehicles: get(policyChangeVM, 'lobData.personalAuto_EA.coverables.vehicles', []),
            path: 'lobData.personalAuto_EA.coverables.vehicles.children',
            onValidate,
            viewModelService,
            showErrors: isPageSubmitted,
            policyState: get(policyChangeVM, 'baseData.policyAddress.state.value.code'),
            vehiclePageDisregardFieldValidation: disregardFieldValidation,
            authHeader,
            onValidationChange: onValidate,
            transactionVM: policyChangeVM,
            updateWizardData,
            setCheckScrolling,
            addVehicleVM: createAndAddVehicleVM,
            isSkipping,
            availableMakes,
            setAvailableMakes,
            availableModels,
            setAvailableModels,
            availableSeries,
            setAvailableSeries
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [policyChangeVM, overrideProps]
    );

    const saveAndQuote = useCallback(
        async () => {
            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteResponse);
            updateWizardData(policyChangeVM);

            return policyChangeVM.value;
        },
        [authHeader, policyChangeVM, updateWizardData]
    );

    const showCustom = true;

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            const shouldEndFunctionExecution = await needsDriverAssignmentUpdate(true);

            if (shouldEndFunctionExecution) {
                setIsSavingEndorsement(false);

                return false;
            }

            policyChangeVM.value = await saveAndQuote();
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [
            policyChangeVM,
            saveAndQuote,
            updateWizardData,
            updateWizardSnapshot,
            getLandingPageIndexForQuotedJob,
            steps,
            jumpTo,
            isComponentValid,
            needsDriverAssignmentUpdate
        ]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onAddVehicleClick: createAndAddVehicleVM,
            onAddAdditionalInterest: addAdditionalInterest,
            onRemoveVehicle: removeVehicleFromQuote,
            onValidate,
            onPrefillData: undefined,
            onVehicleLookupError
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showCustom={showCustom}
            onCustom={onCustom}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

VehiclesPage.propTypes = wizardProps;
export default VehiclesPage;