import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { ActivitiesService } from 'gw-capability-gateway';
import { useModal } from '@jutro/components';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './ReassignActivity.metadata.json5';
import styles from './ReassignActivity.module.scss';
import messages from './ReassignActivity.messages';

const ReassignActivity = ({ activity, onUpdateActivity }) => {
    const modalApi = useModal();
    const { authHeader } = useAuthentication();
    const [selectedValue, setSelectedValue] = useState('');
    const [editable, setIsEditable] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const activityWrapperStyle = classNames(styles.gwReassignActivityWrapper, {
        [styles.gwReassignActivityDisplay]: activity.canReassign
    });
    const enableEditing = useCallback(() => {
        if (activity.canReassign) {
            setIsEditable(true);
        }
    }, [activity]);
    const writeValue = useCallback(
        (value) => {
            setSelectedValue(value);
        },
        [setSelectedValue]
    );
    const getAvailableAssignableUser = useCallback((activities) => (
            activities.assignableUsers
            && activities.assignableUsers.map((availableUser) => ({
                    code: availableUser,
                    name: availableUser
                }))
        ), []);
    const resetAfterReassign = useCallback(() => {
        setLoading(false);
        setSelectedValue('');
        setIsEditable(false);
    }, [setLoading, setSelectedValue, setIsEditable]);
    const assignToSelectedUser = useCallback(async () => {
        setLoading(true);
        ActivitiesService.reassignActivity(selectedValue, activity.publicID, authHeader)
            .then((response) => {
                onUpdateActivity(response);
                resetAfterReassign();
            })
            .catch((error) => {
                resetAfterReassign();

                const errorMessage = _.get(error, 'baseError.data.error.message');

                if (errorMessage) {
                    modalApi.showAlert({
                        title: messages.unableToReassign,
                        message: error.baseError.data.error.message,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.close
                    }).catch(_.noop);
                }
            });
    }, [selectedValue, activity.publicID, authHeader, onUpdateActivity, resetAfterReassign, modalApi]);
    
    const disableEditing = useCallback(() => {
        setSelectedValue('');
        setIsEditable(false);
    }, [setIsEditable, setSelectedValue]);

    const overrideProps = {
        assignableUserList: {
            availableValues: getAvailableAssignableUser(activity),
            value: selectedValue
        },
        reassignActivityWrapper: {
            visible: !editable,
            className: activityWrapperStyle
        },
        selectUserContainer: {
            visible: editable
        },
        assignedName: {
            content: _.get(activity, 'assignedTo.displayName')
        },
        reassignPageLoader: {
            loaded: !isLoading
        },
        activityReassignContainer: {
            visible: !isLoading
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleValueChange: writeValue,
            enableEditing,
            disableEditing,
            assignToSelectedUser,
            onClickPrevent: (e) => e.stopPropagation()
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
};

ReassignActivity.propTypes = {
    activity: PropTypes.shape({}).isRequired,
    onUpdateActivity: PropTypes.func
};

export default ReassignActivity;
