import { defineMessages } from 'react-intl';

export default defineMessages({
    partnerInformation: {
        id: 'e1p.connect.policyDetails.Partner Information',
        defaultMessage: 'Partner Information'
    },
    partner: {
        id: 'e1p.connect.policyDetails.Partner',
        defaultMessage: 'Partner'
    },
    advisorName: {
        id: 'e1p.connect.policyDetails.Advisor Name',
        defaultMessage: 'Advisor Name'
    },
    advisorID: {
        id: 'e1p.connect.policyDetails.Advisor ID',
        defaultMessage: 'Advisor ID'
    },
    advisorLocation: {
        id: 'e1p.connect.policyDetails.Location',
        defaultMessage: 'Location'
    },
    sourceOfEntry: {
        id: 'e1p.connect.policyDetails.Source Type',
        defaultMessage: 'Source of Entry'
    },
    directEntry: {
        id: 'e1p.connect.policyDetails.Direct Entry',
        defaultMessage: 'Direct Entry'
    },
    moonshot: {
        id: 'e1p.connect.policyDetails.Moonshot',
        defaultMessage: 'Moonshot'
    },
    liftoff: {
        id: 'e1p.connect.policyDetails.Liftoff',
        defaultMessage: 'Liftoff'
    },
    ameripriseAdvisor: {
        id: 'e1p.connect.policyDetails.Ameriprise Advisor Referral',
        defaultMessage: 'Ameriprise Advisor Referral'
    },
    ameripriseDirect: {
        id: 'e1p.connect.policyDetails.Ameriprise Direct',
        defaultMessage: 'Ameriprise Direct'
    },
    costco: {
        id: 'e1p.connect.policyDetails.Costco',
        defaultMessage: 'Costco'
    },
    connectNonPartner: {
        id: 'e1p.connect.policyDetails.CONNECT Non Partner',
        defaultMessage: 'CONNECT Non Partner'
    }
});
