import _ from 'lodash';
import { QuoteProposalService } from 'e1p-capability-gateway';

/**
 * @param {Object} submissionVM
 * @param {Function} setQuoteProposalCompleted
 * @param {Function} setQuoteProposalLink
 * @param {Object} authHeader header to pass to the backend (e.g. auth)
 * @param {Function} setIsQuoteProposalFailed
 */

function fetchQuoteProposal(submissionVM, setQuoteProposalCompleted, setQuoteProposalLink, authHeader, setIsQuoteProposalFailed) {
    setQuoteProposalCompleted(false);

    // quoteID for submission and jobID for rewrite
    const quoteID = _.get(submissionVM, 'quoteID.value') || _.get(submissionVM, 'jobID.value');

    QuoteProposalService.retriveQuoteProposalURL(
        quoteID,
        authHeader
    ).then((response) => {
        setQuoteProposalCompleted(true);
        setQuoteProposalLink(`${response.quoteProposalURL}`);
    }).catch(() => {
        setIsQuoteProposalFailed(true);
    });
};

export default { fetchQuoteProposal };
