import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processUWIssue(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('uwissue'), method, data, additionalHeaders);
}


export default class UnderwritingIssuesService {

    /**
    * Reopen Underwriting Issue.
    *
    * @param {Object} quoteID quoteID
    * @param {Object} [uwIssues] uwIssues
    * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
    * @returns {Promise} the promise from the backend call
    * @memberof UnderwritingIssuesService
    */
    static reopenUnderwritingIssues(quoteID, uwIssues, additionalHeaders = {}) {
        return processUWIssue(
            'reopenUnderwritingIssues',
            [quoteID, uwIssues],
            additionalHeaders
        );
    }

    /**
     * Reopen Underwriting Issue.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} [uwIssues] uwIssues
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof UnderwritingIssuesService
     */
    static rejectUnderwritingIssues(quoteID, uwIssues, additionalHeaders = {}) {
        return processUWIssue(
            'rejectUnderwritingIssues',
            [quoteID, uwIssues],
            additionalHeaders
        );
    }

    /**
     * Reopen Underwriting Issue.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} [uwIssues] uwIssues
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof UnderwritingIssuesService
     */
    static approveUnderwritingIssues(quoteID, uwIssues, additionalHeaders = {}) {
        return processUWIssue(
            'approveUnderwritingIssues',
            [quoteID, uwIssues],
            additionalHeaders
        );
    }

}
