import React, { useEffect, useState, useCallback } from 'react';
import {
    get as _get,
    set as _set,
    find as _find
} from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ModalNext, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { UWIssueFormatUtil } from 'e1p-portals-util-js'
import { commonMessages } from 'e1p-platform-translations';
import metadata from './RiskAnalysisUWIssuesComponent.metadata.json5';

import { Button } from '@jutro/legacy/components';

function RiskAnalysisUWIssuesComponent(props) {
    const {
        onValidate,
    } = useValidation('RiskAnalysisUWIssuesComponent');
    const {
        submissionVM,
        updateWizardData,
        isPopup,
        isOpen,
        onReject,
        showErrors,
        viewOnlyMode
    } = props;
    const translator = useTranslator();
    const [underwritingIssues, setUnderwritingIssues] = useState([]);
    const [chargeHighProfileSurcharge, setChargeHighProfileSurcharge] = useState(_get(submissionVM, 'lobData.personalUmbrella_EU.chargeHighProfileSurcharge.value', false));
    const jobTypeCode = _get(submissionVM, 'baseData.jobType.value.code', '');
    const jobNumber = jobTypeCode !== 'Submission'
        ? _get(submissionVM, 'jobID.value')
        : _get(submissionVM, 'quoteID.value');
    const underwritingIssuesPath = 'value.errorsAndWarnings.underwritingIssues';
    
    useEffect(() => {
        const uwIssues = _get(submissionVM, underwritingIssuesPath);

        if (uwIssues) {
            setUnderwritingIssues(UWIssueFormatUtil.formatAndSortUWIssues(uwIssues));

            if (_find(uwIssues, (issue) => issue.uwissueCode_Ext === "HighProfileInsuredOther_EU")
                && _get(submissionVM, 'lobData.personalUmbrella_EU.chargeHighProfileSurcharge.value') === undefined) {
                setChargeHighProfileSurcharge(false);
                _set(submissionVM, 'lobData.personalUmbrella_EU.chargeHighProfileSurcharge', false);
            }
        }
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_get(submissionVM, underwritingIssuesPath)]);

    const setUnderwritingIssuesData = useCallback((uwIssues) => {
        if (uwIssues) {
            _set(submissionVM, underwritingIssuesPath, uwIssues);
            updateWizardData(submissionVM);
            setUnderwritingIssues(UWIssueFormatUtil.formatAndSortUWIssues(uwIssues));
        }
    }, [submissionVM, underwritingIssuesPath, updateWizardData]);
    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        quoteDetailsUWIssues: {
            underwritingIssues,
            quoteID: jobNumber,
            setunderwritingIssues: setUnderwritingIssuesData,
            showActionButtons: !isPopup,
            chargeHighProfileSurcharge,
            setChargeHighProfileSurchargeInd: (value) => {
                setChargeHighProfileSurcharge(value)
                _set(submissionVM, 'lobData.personalUmbrella_EU.chargeHighProfileSurcharge', value);
                updateWizardData(submissionVM);
            },
            viewOnlyMode
        },
        showErrors
    };

    const onCancel = useCallback(() => {
        onReject();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inLineComponent = (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={onValidate}
        />
    );

    if (!isPopup) {
        return inLineComponent;
    }

    return (
        <ModalNext className="container-width-40x70" isOpen={isOpen}>
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    onValidationChange={onValidate}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    {translator(commonMessages.close)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

RiskAnalysisUWIssuesComponent.propTypes = {
    submissionVM: PropTypes.shape({
        quoteID: PropTypes.shape({ value: PropTypes.string })
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    steps: PropTypes.arrayOf(PropTypes.shape({})),
    isPopup: PropTypes.bool,
    isOpen: PropTypes.bool,
    redirectURL: PropTypes.string,
    showErrors: PropTypes.bool,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    viewOnlyMode: PropTypes.bool
};

RiskAnalysisUWIssuesComponent.defaultProps = {
    isPopup: false,
    steps: [],
    redirectURL: undefined,
    showErrors: false,
    viewOnlyMode: false,
    isOpen: undefined,
    onReject: undefined,
    onResolve: undefined
};
export default withRouter(RiskAnalysisUWIssuesComponent);
