import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '@jutro/layout';
import { CurrencyValue, useModal } from '@jutro/components';
import { get as _get, uniqBy as _uniqBy } from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import classNames from 'classnames';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import {
    VehiclePackageCovRadioGroupComponent,
    PackageDifferenceComponent,
    ComprehensiveOnlyCheckboxComponent,
    ComprehensiveOnlyDescriptionComponent,
    E1PCopyVehicleCoveragesComponent
} from 'e1p-capability-policyjob-react';
import { FeatureUtil } from 'e1p-portals-util-js';
import htmlParser from 'html-react-parser';
import coveragesHelpText from '../QuoteClauseTable/coveragesHelpText.json5';
import EASingleClauseComponentVM from '../Clauses/SingleClauseComponentVM';
import messages from './EAVehicleLevelCoveragesComponent.messages';
import styles from './EAVehicleLevelCoveragesComponent.module.scss';

import { IconButton } from '@jutro/legacy/components';

import { Link } from '@jutro/router';

function getCoveragesHelpText(clause) {
    const coveragesHelpTextMessageDetails = _get(coveragesHelpText, clause.codeIdentifier, undefined);
    const coveragesHelpTextMessage = coveragesHelpTextMessageDetails ? htmlParser(coveragesHelpTextMessageDetails.toString()) : undefined;

    return ({
        message: coveragesHelpTextMessage,
        visible: !!coveragesHelpTextMessage
    });
}

function EAVehicleLevelCoveragesComponent(props) {
    const modalApi = useModal();
    const {
        lobOfferings,
        onChangeSubmissionAndSync,
        onChangeSubmission,
        onSyncCoverages,
        onValidate,
        hasExtendedAttributeEditability,
        policyState,
        vehicles,
        lobIndex,
        quoteIndex,
        isQuoteStale,
        viewOnlyMode,
        onCompOnlyChange,
        isCompOnlyVisible,
        policyTransactionVM,
        updateWizardData,
        onCompOnlyChangeForCopyCoverages,
        columnData,
        onForceUpdateCoverages,
        updatePlatinumADD,
        showErrors,
        updatingLineLevelCoverages,
        setUpdatingVehicleLevelCoverages
    } = props;

    const translator = useTranslator();
    const [isLoading, setIsLoading] = useState(undefined);
    const id = 'EAVehicleLevelCoveragesComponent';
    const { isComponentValid, onValidate: setIsComponentValid } = useValidation(id);
    const isTravelPackageVisible = FeatureUtil.isTravelPackageVisibleForState(policyState);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, lobOfferings, onValidate, isComponentValid]);

    useEffect(() => {
        setIsLoading(updatingLineLevelCoverages);
    }, [updatingLineLevelCoverages]);

    const compOnlyDescriptionHandler = useCallback(async () => {
        const result = await modalApi.showModal(
            <ComprehensiveOnlyDescriptionComponent />
        );

        return result;
    }, [modalApi]);


    const changeCompOnlyForCopyCoverages = () => {
        setIsLoading(true);
        setUpdatingVehicleLevelCoverages(true);

        if (onCompOnlyChangeForCopyCoverages) {
            onCompOnlyChangeForCopyCoverages().then(() => {
                setIsLoading(false);
                setUpdatingVehicleLevelCoverages(false);
            });
        }
    }

    const changeCompOnly = useCallback((value, vehiclePublicID) => {
        if (onCompOnlyChange) {
            setIsLoading(true);
            setUpdatingVehicleLevelCoverages(true);
            onCompOnlyChange(value, vehiclePublicID).then(() => {
                setIsLoading(false);
                setUpdatingVehicleLevelCoverages(false);
            });
        }
    }, [onCompOnlyChange, setUpdatingVehicleLevelCoverages]);

    /** Callback from main quote page */
    const syncCoverages = useCallback((value, path, lobPath, quotePath, clauseID) => {
        setIsLoading(true);
        setUpdatingVehicleLevelCoverages(true);

        if (onSyncCoverages) {
            onSyncCoverages(value, path, lobPath, quotePath).then(() => {
                setIsLoading(undefined);
                setUpdatingVehicleLevelCoverages(undefined);
            });
        }
    }, [onSyncCoverages, setUpdatingVehicleLevelCoverages]);

    /** Callback from main quote page */
    const changeSubmissionAndSync = useCallback(async (value, path, lobPath, quotePath, clauseID) => {
        // "lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages[0].children[0].selected"
        // "lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[0].coverages.children[0].selected"
        setIsLoading(true);
        setUpdatingVehicleLevelCoverages(true);

        if (onChangeSubmissionAndSync) {
            return Promise.resolve(onChangeSubmissionAndSync(value, path, lobPath, quotePath).then(() => {
                setIsLoading(undefined);
                setUpdatingVehicleLevelCoverages(undefined);
            }));
        }
    }, [onChangeSubmissionAndSync, setUpdatingVehicleLevelCoverages]);

    const whatsIncludedHandler = useCallback(async () => {
        const componentProps = {
            lob: 'PersonalAuto_EA',
            policyState
        };
        const result = await modalApi.showModal(
            <PackageDifferenceComponent {...componentProps} />
        );

        return result;
    }, [modalApi, policyState]);

    const getTravelPackageHeaderContent = useCallback((headerClause) => (
        <GridItem
            className={styles.travelPackagesHeader}
            tag="div" key={`vehicleLevelGridItem${0}`}>
            <span key={`vehicleLevelCell${0}`} className={styles.travelPackagesLabel}>
                {headerClause.name}
            </span>
            <Link
                to="/"
                onClick={(e) => {
                    e.preventDefault();
                    whatsIncludedHandler();
                }}
                className={styles.travelPackagesLink}>
                {translator(e1pCommonMessages.seeWhatsIncluded)}
            </Link>
        </GridItem>
    ), [translator, whatsIncludedHandler]);

    const getTravelPackageClauseContent = useCallback((className, lobPath, quotePath, vehicleIndex, isCompOnlyVehicle) => (
        <GridItem
            className={className}
            tag="div" key={`travelGridItem${vehicleIndex}${lobIndex}`}>
            <div hidden={isCompOnlyVehicle}>
                <VehiclePackageCovRadioGroupComponent
                    data={lobOfferings[lobIndex].coverages.vehicleCoverages[vehicleIndex].coverages}
                    index={vehicleIndex}
                    isLoading={isLoading}
                    onPackageCovChange={
                        (value, termPath) => changeSubmissionAndSync(value, termPath, lobPath, quotePath)
                    }
                    viewOnly={viewOnlyMode}
                    visible={!isCompOnlyVehicle}
                    basePath={`lobData.personalAuto_EA.offerings.children[${lobIndex}].coverages.vehicleCoverages.children[${vehicleIndex}].coverages`}
                />
            </div>
        </GridItem>
    ), [changeSubmissionAndSync, isLoading, lobIndex, lobOfferings, viewOnlyMode]);

    const forceUpdateCoverages = (lobPath, changedPath) => {
        if (onForceUpdateCoverages) {
            setIsLoading(true);
            setUpdatingVehicleLevelCoverages(true);
            Promise.resolve(onForceUpdateCoverages(lobPath, _get(columnData, '[0].lob.path'))).then(() => {
                setIsLoading(false);
                setUpdatingVehicleLevelCoverages(false);
                updatePlatinumADD();
            })
        }
    };

    const vehicleTables = [];
    // vehicles.forEach((vehicle, vehicleIndex) => {

    const COLUMNS = vehicles.length + 1;
    const columnConfig = [];

    for (let column = 0; column < COLUMNS; column++) {
        let columnWidthToPush = '320px';

        if (column === 0) {
            columnWidthToPush = '210px';
        }

        columnConfig.push(columnWidthToPush);
    }

    // union of coverages of all vehicles
    const allUniqueVehicleCoverage = _uniqBy(lobOfferings[lobIndex].coverages.vehicleCoverages
        .flatMap((vehicleCoverages) => (
            vehicleCoverages.coverages.filter((coverage) => !coverage.isHiddenInVersion)
        )), 'publicID');

    const travelPackageNodeArray = isTravelPackageVisible ? [{ name: 'Travel Packages', codeIdentifier: 'travelCell' }] : [];
    const coverageWithoutPackages = travelPackageNodeArray
        .concat(allUniqueVehicleCoverage)
        .concat({ name: 'Subtotal', codeIdentifier: 'subtotal' })
        // filter out gold and platinum
        .filter((coverage) => coverage.coverageCategoryCode !== 'EA_EndorsementPackage')
    // To account for comp only row when visible
    const ROWSMODIFIER1 = isCompOnlyVisible ? 2 : 1;
    const ROWSMODIFIER2 = isCompOnlyVisible ? 3 : 2;
    // console.log(coverageWithoutPackages, '\n', travelPackages);
    const rowHeaders = [...coverageWithoutPackages];
    const ROWS = rowHeaders.length + ROWSMODIFIER1;
    const numberOfCells = COLUMNS * ROWS;
    const tableContent = [];

    for (let cell = 0; cell <= numberOfCells - 1; cell++) {
        const rowNumber = Math.floor(cell / COLUMNS) + 1;
        const columnNumber = cell % COLUMNS;
        const OFFSET = 1; // array starts at 0 and first element is a header
        const vehicleIndexFromOfferings = columnNumber - OFFSET;
        let vehicle;
        let path;

        if (vehicleIndexFromOfferings > -1) {
            path = `lobData.personalAuto_EA.offerings.children[${lobIndex}].coverages.vehicleCoverages.children[${vehicleIndexFromOfferings}].coverages`;
            vehicle = vehicles.find((veh) => veh.integrationId === lobOfferings[lobIndex].coverages.vehicleCoverages[vehicleIndexFromOfferings].integrationID)
        }// first column, first row - vehicle display name

        if (cell === 0) {
            tableContent.push(
                <GridItem key={`quotename${cell}`} className={styles.stickyLabel} />
            );
        }
        // first column - render row headers
        else if ((cell % COLUMNS === 0)) {
            if (rowNumber === 2 && isCompOnlyVisible) {
                tableContent.push
                    (<GridItem
                        className={styles.subTotalGridItem}
                        tag="div" key={`comprehensiveOnlyLink${cell}`}>
                        <Link
                            to="/"
                            onClick={(e) => {
                                e.preventDefault();
                                compOnlyDescriptionHandler();
                            }}
                            className={styles.travelPackagesLink}
                            disable={viewOnlyMode}>
                            {translator(e1pCommonMessages.comprehensiveOnly)}
                        </Link>
                    </GridItem>)
            } else {
                const headerClause = rowHeaders.shift();
                const toolTipDetails = getCoveragesHelpText(headerClause);

                if (headerClause.codeIdentifier === 'travelCell') {
                    // render travel stuff
                    tableContent.push(
                        getTravelPackageHeaderContent(headerClause)
                    )
                } else if (headerClause.codeIdentifier === 'subtotal') {
                    tableContent.push
                        (<GridItem
                            className={styles.subTotalGridItem}
                            tag="div" key={`vehicleLevelGridItem${cell}`}>
                            <span key={`vehicleLevelCell${cell}`}>
                                {headerClause.name}
                            </span>
                        </GridItem>)
                } else {
                    const isBottomLeftHeader = rowNumber === ROWS - ROWSMODIFIER1;
                    const gridItemStyle = classNames(styles.displayInlineGrid, { [styles.qDetailCellBottomLeftHeader]: isBottomLeftHeader, [styles.qDetailCellLeftHeader]: !isBottomLeftHeader });

                    tableContent.push
                        (<GridItem
                            className={gridItemStyle}
                            tag="div" key={`vehicleLevelGridItem${cell}`}>
                            <span key={`vehicleLevelCell${cell}`}>
                                {headerClause.name}
                            </span>
                            {toolTipDetails.visible ? (<IconButton
                                id={`tooltip${cell}`}
                                icon="mi-help-outline"
                                size="medium"
                                onClick={(evt) => {
                                    evt.stopPropagation();
                                }}
                                tooltip={{ text: toolTipDetails.message }}
                                className={styles.coveragesHelpTextIconButton}
                                iconClassName={styles.coveragesHelpTextIcon}
                            />) : undefined}
                        </GridItem>)
                }
            }
        } else if (rowNumber === 1) { // first row - quote version name
            // Vehicle Name Cell
            tableContent.push(
                viewOnlyMode ? <GridItem
                    className={styles.greyBackgroundHeader}
                    tag="div" key={`vehicleNameGridItem${cell}`}>
                    <span key={`vehicleNameCell${cell}`}>
                        {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                    </span>
                </GridItem> : <GridItem
                    className={styles.greyBackgroundHeader}
                    tag="div" key={`vehicleNameGridItem${cell}`}>
                    <E1PCopyVehicleCoveragesComponent
                        vehicleName={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                        transactionVM={policyTransactionVM}
                        offeringPath={_get(columnData, '[0].lob.path')}
                        updateWizardData={updateWizardData}
                        handleCompOnlyChangeInCopyCoverages={changeCompOnlyForCopyCoverages}
                        forceUpdateCoverages={forceUpdateCoverages} />
                </GridItem>
            )
        } else {
            const covHeader = coverageWithoutPackages[rowNumber - ROWSMODIFIER2];

            if (rowNumber === 2 && isCompOnlyVisible) {
                tableContent.push(
                    <GridItem
                        tag="div" key={`CompOnlyCheckboxField${cell}`}
                        className={styles.qDetailCell}>
                        <ComprehensiveOnlyCheckboxComponent
                            data={vehicle}
                            index
                            isLoading={isLoading}
                            onCompOnlyHandler={changeCompOnly}
                            coverageAmount={undefined}
                            viewOnly={viewOnlyMode}
                        />
                    </GridItem>
                )
            } else if (covHeader.codeIdentifier === 'travelCell') {
                const className = rowNumber === ROWS - ROWSMODIFIER1 ? styles.qDetailCellBottom : styles.qDetailCell;

                tableContent.push
                    (
                        getTravelPackageClauseContent(
                            className, `lobData.personalAuto_EA.offerings.children[${lobIndex}]`,
                            `quoteData.offeredQuotes.children[${quoteIndex}]`,
                            vehicleIndexFromOfferings, vehicle.compOnlyInd
                        )
                    )
            } else if (covHeader.codeIdentifier === 'subtotal') {
                tableContent.push(
                    isQuoteStale ?
                        <GridItem key={`quotename${cell}`} className={styles.subTotalGridItemAmount} />
                        :
                        <GridItem key={`quotename${cell}`} className={styles.subTotalGridItemAmount}>
                            <CurrencyValue
                                id={`version${lobIndex}vehicle${vehicleIndexFromOfferings}premium`}
                                showFractions
                                value={{
                                    amount: lobOfferings[lobIndex].coverages.vehicleCoverages[vehicleIndexFromOfferings].premiumSummary.find(
                                        (summary) => summary.premiumSummaryType === 'EAPerVehicleCoverageSubtotal'
                                    )?.amount,
                                    currency: 'USD'
                                }}
                            />
                        </GridItem>
                );
            } else {
                const clause = lobOfferings[lobIndex].coverages.vehicleCoverages[vehicleIndexFromOfferings].coverages.find(
                    (coverage) => coverage.publicID === covHeader.publicID
                );
                const clauseIndex = lobOfferings[lobIndex].coverages.vehicleCoverages[vehicleIndexFromOfferings].coverages.findIndex(
                    (coverage) => coverage.publicID === covHeader.publicID
                );

                if (clause) {
                    tableContent.push
                        (<GridItem
                            tag="div" key={`quotecell${cell}`}
                            className={rowNumber === ROWS - ROWSMODIFIER1 ? styles.qDetailCellBottom : styles.qDetailCell}>
                            <EASingleClauseComponentVM
                                value={clause}
                                path={`${path}.children[${clauseIndex}]`}
                                onChangeClause={onChangeSubmission}
                                onSyncCoverages={(value, termPath) => {
                                    syncCoverages(
                                        value, termPath, `lobData.personalAuto_EA.offerings.children[${lobIndex}]`,
                                        `quoteData.offeredQuotes.children[${quoteIndex}]`, clause.publicID
                                    )
                                }}
                                onChangeSubmissionAndSync={(value, termPath) => {
                                    changeSubmissionAndSync(
                                        value, termPath, `lobData.personalAuto_EA.offerings.children[${lobIndex}]`,
                                        `quoteData.offeredQuotes.children[${quoteIndex}]`, clause.publicID
                                    )
                                }}
                                loadingClause={isLoading}
                                idPrefex={clause.publicID}
                                labelTop
                                onValidate={setIsComponentValid}
                                isQuoteStale={isQuoteStale}
                                hasExtendedAttributeEditability={hasExtendedAttributeEditability}
                                policyState={policyState}
                                viewOnlyMode={viewOnlyMode}
                                isCompOnlyVehicle={vehicle.compOnlyInd}
                                showErrors={showErrors}
                            />
                        </GridItem>)
                }
                else {
                    tableContent.push(
                        <GridItem
                            tag="div" key={`quotecell${cell}`}
                            className={rowNumber === ROWS - ROWSMODIFIER1 ? styles.qDetailCellBottom : styles.qDetailCell}>
                            { /* for compOnlyVehicle we will show empty cov boxes */ }
                            {
                                vehicle.compOnlyInd ? <span className={styles.noCoverageText} />
                                    : <span className={styles.noCoverageText}>
                                        {translator(messages.notApplicable)}
                                    </span>
                            }
                        </GridItem>
                    )
                }
            }
        }
    }

    vehicleTables.push(
        <Grid key='vehicleLevelCoverages' className={styles.tableSpacingAbove} columns={columnConfig} gap="none">
            {tableContent}
        </Grid>
    )
    // });

    return (
        <div className={styles.tableSpacingAbove}>
            {vehicleTables}
        </div>
    );
}

EAVehicleLevelCoveragesComponent.propTypes = {
    lobOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    quoteDataOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    policyState: PropTypes.string.isRequired,
    hasExtendedAttributeEditability: PropTypes.bool,
    onValidate: PropTypes.func,
    vehicles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    showErrors: PropTypes.bool,
    updatingLineLevelCoveragesL: PropTypes.bool,
    setUpdatingVehicleLevelCoverages: PropTypes.func
};

EAVehicleLevelCoveragesComponent.defaultProps = {
    showErrors: false,
    hasExtendedAttributeEditability: undefined,
    onValidate: undefined,
    updatingLineLevelCoveragesL: false,
    setUpdatingVehicleLevelCoverages: () => { }
}

export default EAVehicleLevelCoveragesComponent;
