import React, { useCallback, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import FilterOptions from './FilterOptions';
import styles from './ActivityAdvancedFilter.module.scss';
import ActivitiesLandingUtil from './ActivitiesLandingUtil';
import messages from './ActivityAdvancedFilter.messages';
import { Link } from '@jutro/router';

const ActivityAdvancedFilter = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const ActivityUtil = new ActivitiesLandingUtil();
    const {
        activities, selectedTile, onValueChange, viewModelService
    } = props;
    const translator = useTranslator();
    const [customFilters, setCustomFilters] = useState(ActivityUtil.customFilter.filters);

    useEffect(() => {
        const activityVM = viewModelService.create(
            {},
            'pc',
            'edge.capabilities.gateway.activity.dto.ActivityDTO'
        );

        ActivityUtil.createCustomFilterOptions(activities, activityVM, translator);
        ActivityUtil.setCustomFilterFieldsForQuickFilter(selectedTile);
        setCustomFilters([...ActivityUtil.customFilter.filters]);
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterCallback = useCallback((filters) => {
        onValueChange(filters);
    }, [onValueChange]);

    const writeValue = useCallback(
        (filters) => {
            const selectedFilters = ActivityUtil.selectedOption(customFilters, filters);

            filterCallback(selectedFilters);
            setCustomFilters([...selectedFilters]);
        },
        [ActivityUtil, customFilters, filterCallback]
    );

    const setAllOptions = useCallback(
        (event, index, value) => {
            event.preventDefault();
            ActivityUtil.setAllFilterOptionsSelected(customFilters[index], value);
            filterCallback(customFilters);
            setCustomFilters([...customFilters]);
        },
        [ActivityUtil, customFilters, filterCallback]
    );

    const clearAllSelectedOptions = useCallback(
        (event) => {
            event.preventDefault();
            ActivityUtil.setCustomFiltersSelected(customFilters, false);
            filterCallback(customFilters);
            setCustomFilters([...customFilters]);
        },
        [ActivityUtil, customFilters, filterCallback]
    );

    return (
        <div>
            <div className={styles.gwAdvancedFilter}>
                <div className={styles.gwFilterSection}>
                    {customFilters.map((filter, index) => (
                        <Fragment key={filter.displayKey.id}>
                            <div id={`fragmentDiv${filter.displayKey.id}`} className={styles.gwFilterSectionTitle}>
                                {translator(filter.displayKey)}
                            </div>
                            <FilterOptions key={`filterOption${filter.displayKey.id}`} filterDisplayKey={filter.displayKey.id} filters={filter} onValueChange={writeValue} />
                            {filter.options.length > 1 && (
                                <div id={`filterOptionDiv${filter.displayKey.id}`} className={styles.gwFilterSelectLink}>
                                    <Link
                                        id={`selectAll${index}`}
                                        onClick={(e) => setAllOptions(e, index, true)}
                                        to="/"
                                    >
                                        {translator(messages.activitieslandingSelectAll)}
                                    </Link>
                                    <Link
                                        id={`clearAll${index}`}
                                        onClick={(e) => setAllOptions(e, index, false)}
                                        to="/"
                                    >
                                        {translator(messages.activitieslandingClearAll)}
                                    </Link>
                                </div>
                            )}
                        </Fragment>
                    ))}
                </div>
            </div>
            <div className={styles.gwClearFilterLink}>
                <Link key="linkToClearAllFilters" id="clearAll" onClick={(e) => clearAllSelectedOptions(e)} to="/">
                    {translator(messages.activitieslandingClearAllFilters)}
                </Link>
            </div>
        </div>
    );
};

ActivityAdvancedFilter.propTypes = {
    activities: PropTypes.arrayOf(PropTypes.shape([])),
    selectedTile: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        create: PropTypes.func.isRequired
    }).isRequired
};
ActivityAdvancedFilter.defaultProps = {
    activities: [],
    selectedTile: undefined
};

export default withViewModelService(ActivityAdvancedFilter);
