import _ from 'lodash';

/**
 * @param {Object} transactionVM
 * @param {String} LOB
 * @returns {Object} returns selectedOfferingIndex and SelectedOffering
 */
function getSelectedOffering(transactionVM, LOB) {
    let selectedOfferingIndex = 0;
    let transactionVMObject = transactionVM;

    if (transactionVM._xCenter) {
        transactionVMObject = _.get(transactionVM, 'value');
    }

    const offerings = _.get(transactionVMObject, `lobData.${LOB}.offerings`, []);
    let selectedOffering = _.get(transactionVMObject, `lobData.${LOB}.offerings[0]`);

    if (offerings.length > 1) {
        const offeredQuotes = _.get(transactionVMObject, 'quoteData.offeredQuotes', []);
        const selectedQuoteBranchCode = offeredQuotes?.find((quote) => quote.selected)?.branchCode;

        if (selectedQuoteBranchCode) {
            selectedOfferingIndex = offerings
                .findIndex((offering) => offering.branchCode === selectedQuoteBranchCode);
            selectedOffering = _.get(transactionVMObject, `lobData.${LOB}.offerings[${selectedOfferingIndex}]`);
        }
    }

    return { selectedOfferingIndex, selectedOffering }
}

export default { getSelectedOffering };
