import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, withModalContext } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { TypekeyUtil, OfferingUtil, QuoteProposalUtil } from 'e1p-portals-util-js';
import _ from 'lodash';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './EHReports.metadata.json5';
import styles from './EHReports.module.scss';
import messages from './EHReports.messages';

import { Button } from '@jutro/legacy/components';

function EHReportsPage(props) {
    const {
        submissionVM,
        updateWizardData,
        prefillData,
        prefillAutoData,
        isOpen,
        onResolve,
        closePopupAction
    } = props;

    const {
        onValidate,
    } = useValidation('EHReportsPage');

    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);
    const dtoName = 'amfam.edge.capabilities.policyjob.common.propertyloss.dto.PropertyLossRecordDTO'
    const availableLossTypes = TypekeyUtil.getAvailableTypekeyValues(dtoName,'lossType')

    if (prefillData && prefillData.value !== '') {
        if (prefillData.propertyLossRecords !== undefined) {
            prefillData.propertyLossRecords.forEach((lossRecord) => {
                lossRecord.lossTypeDesc = TypekeyUtil.getTypeKeyNameGivenAvailableValues(lossRecord.lossType, availableLossTypes);
            });
        }
    }

    useEffect(() => {
        QuoteProposalUtil.fetchQuoteProposal(
            submissionVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();

            if (quoteProposalLink) {
                window.open(quoteProposalLink, '_blank');

                return true;
            }

            return false;
        }, [quoteProposalLink]
    );

    // When payment option changes, we need to get a new doc url
    useEffect(() => {
        if (submissionVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            submissionVM.paymentOptionChanged = false;
        }
    }, [authHeader, submissionVM, submissionVM.paymentOptionChanged]);

    useEffect(() => {
        if (prefillData && prefillData.value !== '') {
            submissionVM.lobData.homeowners_EH.propertyLossRecords = prefillData.propertyLossRecords;

            if (submissionVM.lobData.homeowners_EH.propertyLossRecords !== undefined) {
                submissionVM.lobData.homeowners_EH.propertyLossRecords.forEach((element) => {
                    element.orderedDate = `${element.claimDate.month + 1}/${element.claimDate.day}/${element.claimDate.year}`;
                });
                updateWizardData(submissionVM);
            }
        }

        if (prefillAutoData && prefillAutoData.value !== '') {
            submissionVM.lobData.homeowners_EH.autoPropertyLossRecords = prefillAutoData.autoLossRecords;

            if (submissionVM.lobData.homeowners_EH.autoPropertyLossRecords !== undefined) {
                submissionVM.lobData.homeowners_EH.autoPropertyLossRecords.forEach((element) => {
                    element.orderedDate = `${element.incurDate.month + 1}/${element.incurDate.day}/${element.incurDate.year}`;
                });
                updateWizardData(submissionVM);
            }
        }
    }, [prefillData, submissionVM, updateWizardData, prefillAutoData]);

    const onOk = useCallback(
        () => {
            const wrapperObj = {
                prefillData,
                action: 'GO_TO_NextPage'
            };

            onResolve(wrapperObj);
        }, [onResolve, prefillData]
    );
    const getVerifiedPremium = useMemo(
        () => {
            if (submissionVM.quoteData.offeredQuotes) {
                const { selectedOfferingIndex } = OfferingUtil.getSelectedOffering(submissionVM, 'homeowners_EH');
                const paymentPlansList = submissionVM.lobData.homeowners_EH.offerings.children[selectedOfferingIndex].paymentPlans.value;
                const selectedPlan = _.filter(paymentPlansList, (plan) => plan.isSelected);
                const totalPremium = selectedPlan[0].total;

                if (!totalPremium && totalPremium !== 0) {
                    return undefined;
                }

                let differenceInPremium = totalPremium.amount - _.get(submissionVM, 'unverfiedPremium');

                if (typeof(differenceInPremium) !== 'number') {
                    differenceInPremium = 0;
                }

                _.set(submissionVM, 'differenceInPremium', differenceInPremium);

                return totalPremium;
            }

            return undefined;
        }, [submissionVM]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        paymentOptionsID: {
            submissionVM,
            authHeader,
            updateWizardData,
            LOB: 'homeowners_EH',
            viewOnly: true
        },
        totalPremiumID: {
            value: getVerifiedPremium
        },
        quoteProposalLinkContainer: {
            onClick: (e) => openDocument(e),
            disabled: !quoteProposalCompleted
        },
        changePremiumID: {
            value: { currency: 'usd', amount: _.get(submissionVM, 'differenceInPremium', 0) }
        },
        autoLossesContainer: {
            visible: !!_.get(submissionVM, 'lobData.homeowners_EH.autoPropertyLossRecords', undefined)
        },
        propertyLossesContainer: {
            visible: !!_.get(submissionVM, 'lobData.homeowners_EH.propertyLossRecords', undefined)
        },
        propertyLossResultStatus: {
            value: _.get(submissionVM, 'lobData.homeowners_EH.propertyLossRecords', [])
                .length > 0 ? translator(messages.notClearResults) : translator(messages.clearResults)
        },
        autoLossResultStatus: {
            value: _.get(submissionVM, 'lobData.homeowners_EH.autoPropertyLossRecords', [])
                .length > 0 ? translator(messages.notClearResults) : translator(messages.clearResults)
        },
        PropertyLossDataTable: {
            visible: _.get(submissionVM, 'lobData.homeowners_EH.propertyLossRecords', []).length > 0
        },
        AutoLossDataTable: {
            visible: _.get(submissionVM, 'lobData.homeowners_EH.autoPropertyLossRecords', []).length > 0
        },
        quoteProposalFailureErrorDiv: {
            visible: isQuoteProposalFailed
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        },
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps),
        [submissionVM, overrideProps]
    );


    return (
        <ModalNext isOpen={isOpen} className={styles.fullScreen} onAfterClose={closePopupAction}>
            <ModalHeader />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onOk}>
                    {commonMessages.next}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

EHReportsPage.propTypes = wizardProps;
export default withModalContext(EHReportsPage);
