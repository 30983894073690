import { defineMessages } from 'react-intl';

export default defineMessages({
    chooseHeatingType: {
        id: 'quoteandbind.eh.views.component.heatingsystem.Heating Type',
        defaultMessage: 'Heating Type',
    },
    isPrimaryYes: {
        id: 'quoteandbind.eh.views.component.heatingsystem.isPrimary.Yes',
        defaultMessage: 'Yes',
    },
    isPrimaryNo: {
        id: 'quoteandbind.eh.views.component.heatingsystem.isPrimary.No',
        defaultMessage: 'No',
    }
});
