import React, {
    useCallback, useState, useEffect, useMemo
} from 'react';
import {
    set as _set,
    get as _get
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useUWIssueUtil } from 'e1p-capability-hooks';
import { ReinstatementService } from 'e1p-capability-reinstatement';
import metadata from './QuoteReinstatementPage.metadata.json5';
import messages from './QuoteReinstatementPage.messages';

function QuoteReinstatementPage(props) {
    const modalApi = useModal();
    const {
        wizardData: reinstatementVM,
        updateWizardData,
        steps,
        jumpTo,
    } = props;
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [isProcessingReinstatement, updateIsProcessingReinstatement] = useState(false);
    const [isLoading, updateIsLoading] = useState(false);
    const [isQuoted, updateIsQuoted] = useState(false);
    const jobID = _get(reinstatementVM, 'jobID.value');
    const redirectURL = `/reinstatement/view/${jobID}/risk-analysis`;
    const {
        hasUWIssues,
        showUnderwritingIssuesPopup
    } = useUWIssueUtil(
        reinstatementVM,
        updateWizardData,
        jumpTo,
        steps,
        redirectURL
    );

    const checkAndShowUWIssues = useCallback(() => {
        if (hasUWIssues()) {
            showUnderwritingIssuesPopup();
        }
    }, [hasUWIssues, showUnderwritingIssuesPopup]);

    useEffect(() => {
        updateIsLoading(true);

        if (reinstatementVM.baseData.periodStatus.value.code === 'Draft') {
            ReinstatementService.quote(reinstatementVM.jobID.value, authHeader)
                .then((response) => {
                    _set(reinstatementVM, 'value', response);
                    _set(reinstatementVM, 'effectiveDate_Ext.value', response.effectiveDate);

                    const uwIssuesInRespone = _get(response, 'errorsAndWarnings');

                    if (uwIssuesInRespone) {
                        _set(reinstatementVM, 'value.errorsAndWarnings_Ext', uwIssuesInRespone);
                    }

                    const isJobQuoted = reinstatementVM.baseData.periodStatus.value.code === 'Quoted';

                    updateIsQuoted(isJobQuoted);
                    updateWizardData(reinstatementVM);
                    checkAndShowUWIssues();
                }).catch(() => {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: translator(messages.errorQuoting),
                        message: translator(messages.returnQuoting),
                    });
                }).finally(() => {
                    updateIsLoading(false);
                });
        } else if (reinstatementVM.baseData.periodStatus.value.code === 'Quoted') {
            updateIsQuoted(true);
            updateIsLoading(false);

            const uwIssuesInRespone = _get(reinstatementVM, 'errorsAndWarnings');

            if (uwIssuesInRespone) {
                _set(reinstatementVM, 'value.errorsAndWarnings_Ext', uwIssuesInRespone);
            }

            checkAndShowUWIssues();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goNext = useCallback(async () => {
        updateIsProcessingReinstatement(true);

        if (jobID !== null) {
            await ReinstatementService.bind(jobID, authHeader).then((response) => {
                _set(reinstatementVM, 'value', response);
                updateWizardData(reinstatementVM);
            });
        }

        updateIsProcessingReinstatement(false);

        return reinstatementVM;
    }, [authHeader, jobID, reinstatementVM, updateWizardData]);

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(
        () => !isProcessingReinstatement && !isLoading,
        [isLoading, isProcessingReinstatement]
    );

    const overrideProps = {
        '@field': {
            showOptional: true,
            autoComplete: false
        },
        quoteReinstatementPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: translator(messages.completingYourReinstatementMessage)
        },
        quoteReinstatementPageContainer: {
            visible: isPageLoaded
        }
    };

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            nextLabel={messages.resinstateNow}
            onNext={goNext}
            showPrevious={false}
            disableNext={!isQuoted}
            showCancel
            finish
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={reinstatementVM}
                overrideProps={overrideProps}
                // onValueChange={this.writeValue}
            />
        </WizardPage>
    );
}

QuoteReinstatementPage.propTypes = wizardProps;
export default QuoteReinstatementPage;
