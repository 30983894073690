import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { Wizard } from 'e1p-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { error as loggerError } from '@jutro/logger';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { RenewalService } from 'e1p-capability-renewal';
import { OnPageOperationsUtil } from 'e1p-portals-util-js';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import renewalWizardConfig from './config/ea-wizard-config.json5';
import viewRenewalWizardConfig from './config/viewOnly/ea-wizard-config.json5';
import wizardStepToFieldMapping from './config/ea-wizard-step-to-field-mapping.json5';
import messages from './EARenewalWizard.messages';
import styles from './EARenewalWizard.module.scss';

function EARenewalWizard(props) {
    const modalApi = useModal();
    const [wizardConfig, setWizardConfig] = useState(renewalWizardConfig);
    const { steps, title } = wizardConfig;
    const [initialRenewal,
        setinitialRenewal
    ] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [viewOnly, setViewOnly] = useState(false);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history, authHeader } = props;
    const { onPageCancel, onPagePrevious } = OnPageOperationsUtil();
    const [
        isPageJumpEnabled,
        updateIsPageJumpEnabled
    ] = useState(false);

    useEffect(() => {
        if (!location.state) {
            history.push('/');

            return;
        }

        if (location.state.viewOnly) {
            setViewOnly(true);
            setWizardConfig(viewRenewalWizardConfig);
        }

        const { state: { policyNumber, jobNumber, clickedButtonValue } } = location;
        const errorModalBox = (errorMessage) => {
            modalApi.showConfirm(errorMessage).then(() => {
                setIsLoading(false);

                let path = `/contactAgent/${policyNumber}`;

                if (jobNumber) {
                    path = `/renewal/${jobNumber}/summary`;
                }

                history.push(path);
            });
        };

        if (clickedButtonValue === 'editRenewal') {
            RenewalService.startEditRenewal(
                jobNumber,
                authHeader
            ).then((response) => {
                const renewal = viewModelService.create(
                    response,
                    'pc',
                    'edge.capabilities.renewal.dto.RenewalDataDTO'
                );

                setinitialRenewal(renewal);
                updateIsPageJumpEnabled(true);
                setIsLoading(false);
            });
        } else if (jobNumber) {
            RenewalService.retrieveRenewal([jobNumber], authHeader)
                .then((responseData) => {
                    const renewal = viewModelService.create(
                        responseData,
                        'pc',
                        'edge.capabilities.renewal.dto.RenewalDataDTO'
                    );

                    // need to map the producer code over
                    // producer code is assigned from the submission and cannot be changed
                    _.set(renewal, 'baseData.producerCode_Ext.value',
                        responseData.baseData.producerCode);
                    setinitialRenewal(renewal);
                    updateIsPageJumpEnabled(true);
                    setIsLoading(false);
                }).catch(() => {
                    errorModalBox({
                        title: messages.unableToLoadDraftPolicy,
                        message: messages.somethingWentWrong,
                        status: 'warning',
                        icon: 'mi-error-outline'
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFinish = useCallback((finishParams) => {
        const {
            wizardData: renewalVM,
        } = finishParams;
        const jobID = _.get(renewalVM.value, 'jobID');

        return history.push(`/renewal/${jobID}/summary`);
    }, [history]);

    const handleOnCancel = useCallback((cancelParams) => {
        const {
            wizardData: renewalVM,
            wizardSnapshot,
            currentStepIndex,
            param = {}
        } = cancelParams;
        const { search, pathname: nextPath = '' } = param;
        const skipModal = ['knockoutpage', 'logout', 'summary'];
        let lastPathName = '';

        if (nextPath) {
            lastPathName = nextPath.replace(/\/?.*\//g, '');
        }

        const isStatusBound = _.get(wizardSnapshot, 'value.status') === 'Bound';

        if ((!_.isEmpty(nextPath) && nextPath.startsWith('/contactAgent'))
            || hasErrorOccurred
            || (isStatusBound && currentStepIndex > 0)
            || skipModal.includes(lastPathName)
            || viewOnly
        ) {
            return true;
        }

        return Promise.resolve(onPageCancel(renewalVM.value, wizardSnapshot.value)
            .then((popupResult) => {
                if (!popupResult || popupResult === 'cancel') {
                    return _.noop();
                }

                setIsLoading(true);

                const jobID = _.get(renewalVM.value, 'jobID');
                const policynumber = _.get(renewalVM.value, 'policyNumber');
                let redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/renewal/${jobID}/summary`;

                if (jobID === undefined) {
                    redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/policies/${policynumber}/summary`;
                }

                if (nextPath?.includes('/quote-')) {
                    return history.push({
                        pathname: nextPath,
                        search
                    });
                }

                return history.push(redirectLobPath);
            }).catch(_.noop()));
    }, [hasErrorOccurred, history, onPageCancel, viewOnly]);

    const handlePrevious = useCallback((previousProps) => {
        const {
            wizardData, wizardSnapshot, updateWizardData, cloneData
        } = previousProps;

        return Promise.resolve(
            onPagePrevious(
                wizardData, wizardSnapshot,
                updateWizardData, cloneData, viewOnly
            )
        );
    }, [onPagePrevious, viewOnly]);

    const handleError = useCallback((error) => {
        const redirectPath = '/contact-for-assistance';
        const pathname = _.get(location, 'pathname');
        const state = {
            pathname: redirectPath,
            state: error,
            policyNumber: _.get(location, 'state.policyNumber')
        };

        loggerError(`MSA-EAWizard-Error: ${error}
            Pathname: ${pathname}`);
        setHasErrorOccurred(true);

        return <Redirect to={state} />;
    }, [location]);

    if (isLoading) {
        return (
            <E1PLoader loaded={!isLoading} />
        );
    }

    if (!initialRenewal) {
        return null;
    }

    return (
        <div className={styles.hoRenewalWizardContainer}>
            <ErrorBoundary onError={handleError}>
                {/* Hidden field needed for GA on each wizard page */}
                <p id="sourceOfEntry" style={{ display: 'none' }}>
                    {_.get(
                        initialRenewal,
                        'baseData.quoteSource_Ext.sourceType.value.code',
                        'directentry'
                    )}
                </p>
                <Wizard
                    initialSteps={steps}
                    skipCompletedSteps={false}
                    wizardStepToFieldMapping={wizardStepToFieldMapping}
                    wizardTitle={title}
                    initialData={initialRenewal}
                    viewOnly={viewOnly}
                    onPrevious={handlePrevious}
                    isRenewal
                    onCancel={handleOnCancel}
                    onFinish={handleFinish}
                    onPreviousModalProps={{
                        title: platformMessages.wantToJump,
                        message: platformMessages.wantToJumpMessage,
                        messageProps: {
                            ok: platformMessages.yes,
                            close: platformMessages.no
                        }
                    }}
                    isPageJumpEnabled={isPageJumpEnabled}
                />
            </ErrorBoundary>
        </div>
    );
}

EARenewalWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            viewOnly: PropTypes.bool,
            latestPeriod: PropTypes.shape({
                effectiveDate: PropTypes.string,
                cancellationDate: PropTypes.string
            })
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired
};

export default withAuthenticationContext(EARenewalWizard);
