import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    get as _get,
    set as _set,
    orderBy as _orderBy,
    some as _some,
    isEmpty as _isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { error, info } from '@jutro/logger';
import { PriorCarrierService } from 'e1p-capability-gateway';
import { useAttributeConfigUtilForEA, usePriorCarrierUtil } from 'e1p-capability-hooks';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import appConfig from 'app-config';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import './EAPriorCarrierComponent.messages';
import metadata from './EAPriorCarrierComponent.metadata.json5';

/**
 * @purpose EAPriorCarrierComponent is the main component for showing all the prior policies
 * both prefill and ManuallyAdded
 * ParentComponent: Riskanalysispage
 *
 * @param {Object} props
 * @returns {JSX}
 */
function EAPriorCarrierComponent(props) {
    const {
        submissionVM,
        id,
        onValidate,
        onValueChange,
        disregardFieldValidation,
        viewOnlyMode,
        setPriorCarrierChanged,
        showErrors,
        showPriorCarrierEffectiveDateMessage
    } = props;
    const [pniCoverageLapse, setPniCoverageLapse] = useState(false);
    const [sniCoverageLapse, setSniCoverageLapse] = useState(false);
    const [priorCarrierNames, setPriorCarrierNames] = useState([]);
    const [isLoadingCarrierNames, setIsLoadingCarrierNames] = useState(false);
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const { authHeader } = useAuthentication();
    const [priorPolicyUpdateReasonAvailableValues, setPriorPolicyUpdateReasonAvailableValues] = useState([]);
    const manualPNIPriorCarrierRef = useRef();
    const manualSNIPriorCarrierRef = useRef();

    const {
        hasCoverageLapse
    } = usePriorCarrierUtil();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [submissionVM, id, onValidate, isComponentValid]);

    useEffect(() => {
        setIsLoadingCarrierNames(true);

        let isMounted = true;

        PriorCarrierService.fetchPriorCarrierNamesDTO(authHeader).then((response) => {
            if (isMounted) {
                info(`Prior carrier names fetched - ${JSON.stringify(response)}`);
                setPriorCarrierNames(response.map((carrier) => ({ code: carrier.priorCarrierName, name: carrier.priorCarrierName })));
            }
        }).catch((err) => {
            error(`Error fetching prior carrier names - ${JSON.stringify(err)}`);
        }).finally(() => {
            if (isMounted) {
                setIsLoadingCarrierNames(false);
            }
        });

        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code', _get(submissionVM, 'policyAddress.state.value.code'));
    const auto2dot0States = appConfig.auto2dot0States ?? [];
    const isAuto2dot0State = auto2dot0States.includes(policyState);

    const {
        getAvailableValuesForPriorPolicyUpdateReasons
    } = useAttributeConfigUtilForEA(submissionVM);

    useEffect(() => {
        if (isAuto2dot0State) {
            getAvailableValuesForPriorPolicyUpdateReasons()
                .then((availableValues) => {
                    setPriorPolicyUpdateReasonAvailableValues(availableValues);
                });
        }
        // Only update on initial load    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // changedpath is the full path of the node.
    // so we dont need const fullPath = `${path}.${changedPath}`;
    const handleValueChange = useCallback(
        (value, changedPath) => {
            if (onValueChange) {
                onValueChange(value, changedPath);
            }
        },
        [onValueChange]
    );

    // Sort based on expiration date - descending order
    const getPriorPolicyDescOrder = (policies) => {
        _set(policies, 'value', _orderBy(policies.value, (policy) => new Date(_get(policy, 'policyHolderExpirationDate')), ['desc']));

        return policies;
    };

    /**
     * IAP-4715 : Auto - Retain prior insurance declared info
     */

    const pniHasPriorPolicyUpdates = _get(submissionVM, 'lobData.personalAuto_EA.priorPolicyUpdates.value', [])
        .find(
            (priorPolicyUpdate) => priorPolicyUpdate.integrationId === _get(
                submissionVM, 'lobData.personalAuto_EA.primaryNamedInsured.integrationId.value'
            )
                && priorPolicyUpdate.publicID !== undefined
        );

    const pniHasManualPriorPolicies = _some(_get(submissionVM, 'lobData.personalAuto_EA.priorPolicies.value', []),
        (priorPolicy) => (priorPolicy.integrationId === _get(submissionVM, 'lobData.personalAuto_EA.primaryNamedInsured.integrationId.value')
            && priorPolicy.policySource !== 'Prefill'));

    const pniPriorPolicyManualGridVisible = pniCoverageLapse
        || !_some(_get(submissionVM, 'lobData.personalAuto_EA.priorPolicies.value', []),
            (priorPolicy) => (priorPolicy.integrationId === _get(submissionVM, 'lobData.personalAuto_EA.primaryNamedInsured.integrationId.value')
                && priorPolicy.policySource === 'Prefill'))
        || (pniHasPriorPolicyUpdates || pniHasManualPriorPolicies);

    /**
     * IAP-3866
     * Manual prior carrier declaration will be available for sni only if sni does have lapse in covergae and pni does not
     * If both NI's have lapse in coverage we willl show manual prior carrier grid to PNI only
     * If sni has any priorpolicy update records(militry deployment or any manual prior carriers from any other ui) we will show component
     */
    const sniHasPriorPolicyUpdates = _get(submissionVM, 'lobData.personalAuto_EA.priorPolicyUpdates.value', [])
        .find(
            (priorPolicyUpdate) => priorPolicyUpdate.integrationId === _get(
                submissionVM, 'lobData.personalAuto_EA.secondaryNamedInsured.integrationId.value'
            )
                && priorPolicyUpdate.publicID !== undefined
        );
    const sniHasManualPriorPolicies = _some(_get(submissionVM, 'lobData.personalAuto_EA.priorPolicies.value', []),
        (priorPolicy) => (priorPolicy.integrationId === _get(submissionVM, 'lobData.personalAuto_EA.secondaryNamedInsured.integrationId.value')
            && priorPolicy.policySource !== 'Prefill'));
    const sniHasCoverageLapseOrNoPrefillPolicy = sniCoverageLapse
        || !_some(_get(submissionVM, 'lobData.personalAuto_EA.priorPolicies.value', []),
            (priorPolicy) => (priorPolicy.integrationId === _get(submissionVM, 'lobData.personalAuto_EA.secondaryNamedInsured.integrationId.value')
                && priorPolicy.policySource === 'Prefill'))

    const sniPriorPolicyManualGridVisible =
        (!!submissionVM.lobData.personalAuto_EA.secondaryNamedInsured.value
            && sniHasCoverageLapseOrNoPrefillPolicy
        )
        || (sniHasPriorPolicyUpdates || sniHasManualPriorPolicies);

    useEffect(() => {
        if (!sniPriorPolicyManualGridVisible) {
            // setting visibility to false does not make component valid, so setting it valid once component becomes hidden
            setComponentValidation(true, 'sniPriorPolicyManualGridID');

            if (isAuto2dot0State) {
                // remove prior policy update if sniPriorPolicyManulGrid becomes hidden
                const priorPolicyUpdateList = _get(submissionVM, 'lobData.personalAuto_EA.priorPolicyUpdates.value', []);
                const priorPolicyUpdateIndexToBeRemoved = priorPolicyUpdateList
                    .findIndex((priorPolicyUpdate) =>
                        (
                            priorPolicyUpdateReasonAvailableValues.some((availableValue) => availableValue.code === priorPolicyUpdate.reason)
                            || _isEmpty(priorPolicyUpdateReasonAvailableValues) // it takes time to load values
                        )
                        && priorPolicyUpdate.integrationId === _get(submissionVM, 'lobData.personalAuto_EA.secondaryNamedInsured.integrationId.value')
                        && priorPolicyUpdate.type === 'PriorPolicyLapseReason_Ext'
                        && priorPolicyUpdate.publicID === undefined);

                if (priorPolicyUpdateIndexToBeRemoved > -1) {
                    priorPolicyUpdateList.splice(priorPolicyUpdateIndexToBeRemoved, 1);
                }

                if (onValueChange) {
                    onValueChange(priorPolicyUpdateList, 'lobData.personalAuto_EA.priorPolicyUpdates');

                    if (setPriorCarrierChanged) {
                        setPriorCarrierChanged(true);
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        priorPolicyUpdateReasonAvailableValues, sniPriorPolicyManualGridVisible
    ]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnlyMode,
            showRequired: true,
            autoComplete: false
        },
        pniPriorPolicyGridID: {
            data: getPriorPolicyDescOrder(_get(submissionVM, 'lobData.personalAuto_EA.priorPolicies', [])),
            priorPolicyUpdatesVM: _get(submissionVM, 'lobData.personalAuto_EA.priorPolicyUpdates', []),
            primaryNamedInsured: submissionVM.lobData.personalAuto_EA.primaryNamedInsured,
            disregardFieldValidation,
            viewOnlyMode,
            pniCoverageLapse,
            hasPNILapsed: (() => {
                setPniCoverageLapse(
                    hasCoverageLapse(submissionVM.lobData.personalAuto_EA.primaryNamedInsured.integrationId.value,
                        _get(submissionVM, 'lobData.personalAuto_EA.priorPolicies.value', []))
                );
            }),
            policyStartDate: submissionVM.baseData.periodStartDate,
            setPriorCarrierChanged,
            policyState: _get(submissionVM, 'baseData.policyAddress.state.value.code', _get(submissionVM, 'policyAddress.state.value.code')),
            coverageInforceIndChange: (value) => {
                if (!value) {
                    if (manualPNIPriorCarrierRef.current) {
                        manualPNIPriorCarrierRef.current(value);
                    }
                }
            }
        },
        manualPriorCarrierSection: {
            visible: pniPriorPolicyManualGridVisible || sniPriorPolicyManualGridVisible
        },
        // Shows when PNI has a lapse in coverage OR no records returned from prefill
        pniPriorPolicyManualGridID: {
            data: _get(submissionVM, 'lobData.personalAuto_EA.priorPolicies', []),
            priorPolicyUpdatesVM: _get(submissionVM, 'lobData.personalAuto_EA.priorPolicyUpdates', []),
            namedInsured: submissionVM.lobData.personalAuto_EA.primaryNamedInsured,
            isSNI: false,
            viewOnlyMode,
            visible: pniPriorPolicyManualGridVisible,
            policyStartDate: submissionVM.baseData.periodStartDate,
            setPriorCarrierChanged,
            showErrors,
            priorCarrierNames,
            transactionVM: submissionVM,
            priorPolicyUpdateReasonAvailableValues,
            manualPriorCarrierFuncRef: manualPNIPriorCarrierRef
        },
        // Shows only when there is SNI, and there is lapse OR no reports from prefill
        sniPriorPolicyManualGridID: {
            data: getPriorPolicyDescOrder(_get(submissionVM, 'lobData.personalAuto_EA.priorPolicies', [])),
            priorPolicyUpdatesVM: _get(submissionVM, 'lobData.personalAuto_EA.priorPolicyUpdates', []),
            namedInsured: submissionVM.lobData.personalAuto_EA.secondaryNamedInsured,
            isSNI: true,
            viewOnlyMode,
            visible: sniPriorPolicyManualGridVisible,
            policyStartDate: submissionVM.baseData.periodStartDate,
            setPriorCarrierChanged,
            showErrors,
            priorCarrierNames,
            transactionVM: submissionVM,
            priorPolicyUpdateReasonAvailableValues,
            manualPriorCarrierFuncRef: manualSNIPriorCarrierRef
        },
        sniPriorPolicyGridID: {
            visible: !!submissionVM.lobData.personalAuto_EA.secondaryNamedInsured.value,
            priorPolicyUpdatesVM: _get(submissionVM, 'lobData.personalAuto_EA.priorPolicyUpdates', []),
            data: _get(submissionVM, 'lobData.personalAuto_EA.priorPolicies', []),
            secondaryNamedInsured: submissionVM.lobData.personalAuto_EA.secondaryNamedInsured,
            viewOnlyMode,
            sniCoverageLapse,
            hasSNILapsed: (() => {
                setSniCoverageLapse(
                    !!submissionVM.lobData.personalAuto_EA.secondaryNamedInsured.value
                    && hasCoverageLapse(submissionVM.lobData.personalAuto_EA.secondaryNamedInsured.integrationId.value,
                        _get(submissionVM, 'lobData.personalAuto_EA.priorPolicies.value', []))
                );
            }),
            policyStartDate: submissionVM.baseData.periodStartDate,
            setPriorCarrierChanged,
            policyState: _get(submissionVM, 'baseData.policyAddress.state.value.code', _get(submissionVM, 'policyAddress.state.value.code')),
            coverageInforceIndChange: (value) => {
                if (!value) {
                    if (manualSNIPriorCarrierRef.current) {
                        manualSNIPriorCarrierRef.current(value);
                    }
                }
            }
        },
        priorCarrierEffectiveDateMessageDiv: {
            visible: showPriorCarrierEffectiveDateMessage
        }
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
            metadata.pageContent,
            submissionVM,
            fieldId,
            fieldPath,
            overrideProps
        ),
        [submissionVM, overrideProps]
    );
    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    if (isLoadingCarrierNames) {
        return <E1PLoader loaded={!isLoadingCarrierNames} />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

EAPriorCarrierComponent.propTypes = {
    value: PropTypes.shape({}),
    submissionVM: PropTypes.shape({
        lobData: PropTypes.shape({

            personalAuto_EA: PropTypes.shape({
                priorPolicies: PropTypes.shape({
                    children: PropTypes.arrayOf(PropTypes.shape({}))
                }),
                priorPolicyUpdates: PropTypes.shape({
                    children: PropTypes.arrayOf(PropTypes.shape({}))
                }),
                primaryNamedInsured: PropTypes.shape({
                    value: PropTypes.shape({}),
                    person: PropTypes.shape({}),
                    integrationId: PropTypes.shape({
                        value: PropTypes.string
                    }),
                }),
                secondaryNamedInsured: PropTypes.shape({
                    value: PropTypes.shape({}),
                    person: PropTypes.shape({
                    }),
                    integrationId: PropTypes.shape({
                        value: PropTypes.string
                    }),
                })
            })
        }),
        baseData: PropTypes.shape({
            periodStartDate: PropTypes.shape({
            })
        })
    }),
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    disregardFieldValidation: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    setPriorCarrierChanged: PropTypes.func,
    showErrors: PropTypes.bool,
    showPriorCarrierEffectiveDateMessage: PropTypes.bool
};
EAPriorCarrierComponent.defaultProps = {
    value: {},
    submissionVM: {},
    viewOnlyMode: false,
    showErrors: false,
    setPriorCarrierChanged: undefined,
    showPriorCarrierEffectiveDateMessage: false
};

export default withAuthenticationContext(EAPriorCarrierComponent);
