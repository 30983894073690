import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo,
    useRef
} from 'react';
import {
    get as _get,
    isEmpty as _isEmpty,
    includes as _includes,
    set as _set,
    cloneDeep as _cloneDeep,
    isUndefined as _isUndefined,
    findIndex as _findIndex
} from 'lodash';
import moment from 'moment';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { AccountService, AccountSearchUtil } from 'e1p-capability-gateway';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { isRequired } from 'e1p-portals-required-validator-js';
import { RandomNumberUtil, AddressUtil } from 'e1p-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import {
    AccountsFoundComponent,
} from 'e1p-capability-policyjob-react';
import {
    useEffectiveDateUtil,
    useNewBusinessUtil,
    useSniUtil,
    e1pUSStatesUtil,
    useDataLayer
} from 'e1p-capability-hooks';
import appConfig from 'app-config';
import messages from './PolicyDetailsPage.messages';
import metadata from './PolicyDetailsPage.metadata.json5';
import requiredMetadata from './PolicyDetailsPage.requiredness';

const LOB = 'personalAuto_EA';
const pniPath = 'lobData.personalAuto_EA.primaryNamedInsured';
const experienceIdMap = {
    MSA: '7119', // always the same for MSA
    CONNECT: '7192' // This will change. It probably has to be an array and have an ID for each partner. Not sure yet.
}

/**
 *
 * @param {Object} submission response from create new submission
 * @param {VMNode} submissionVM Values from the form that need to be
 *   set on the new submission response. This will then be sent
 *   to update draft
 * @returns {Object} submission
 */
async function updateSubmissionFromForm(submission, submissionVM) {
    if (submissionVM.flowStepIDs_Ext?.value) {
        _set(submission, 'flowStepIDs_Ext', submissionVM.flowStepIDs_Ext.value);
    }

    if (submissionVM.entryCompletionStepIDs_Ext?.value) {
        _set(submission, 'entryCompletionStepIDs_Ext', submissionVM.entryCompletionStepIDs_Ext.value);
    }

    _set(
        submission,
        `lobData[${LOB}].applicantsResideInSameStateInd`,
        submissionVM.lobData[LOB].applicantsResideInSameStateInd.value
    );
    _set(
        submission,
        `lobData[${LOB}].ownsHomeIndicator`,
        submissionVM.lobData[LOB].ownsHomeIndicator.value
    );
    _set(
        submission,
        `lobData[${LOB}].isUmbrellaAssociated`,
        submissionVM.lobData[LOB].isUmbrellaAssociated.value
    );
    _set(
        submission,
        `lobData[${LOB}].acknowledgements`,
        submissionVM.lobData[LOB].acknowledgements.value
    );
    _set(submission, 'baseData.quoteSource_Ext', {
        sourceType: 'directentry',
        sourceName: 'msaagentsonly'
    });
    _set(
        submission,
        `lobData[${LOB}].secondaryNamedInsured`,
        submissionVM.lobData[LOB].secondaryNamedInsured.value
    );

    if (submissionVM.lobData[LOB].primaryNamedInsured.priorAddresses) {
        _set(
            submission,
            `lobData[${LOB}].primaryNamedInsured.priorAddresses`,
            submissionVM.lobData[LOB].primaryNamedInsured.priorAddresses.value
        );
    }

    _set(
        submission,
        `lobData[${LOB}].primaryNamedInsured`,
        submissionVM.lobData[LOB].primaryNamedInsured.value
    );
    _set(
        submission,
        `lobData[${LOB}].membership`,
        submissionVM.lobData[LOB].membership.value
    );
    _set(
        submission,
        `lobData[${LOB}].advisor`,
        submissionVM.lobData[LOB].advisor.value
    );

    return submission;
}

function PolicyDetailsPage(props) {
    const modalApi = useModal();
    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerInitialComponentValidation,
        disregardFieldValidation,
        registerComponentValidation
    } = useValidation('PolicyDetailsPage');
    const {
        wizardData: submissionVM, updateWizardData, authUserData,
        isSkipping, updateWizardSnapshot, wizardSnapshot,
        changeNextSteps,
        steps
    } = props;
    const translator = useTranslator();
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [producerCodeDetails, setProducerCodeDetails] = useState(undefined);
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const [requiredFields, updateRequiredFields] = useState([]);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const [isAnExistingAccount, setIsAnExistingAccount] = useState(false);
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [submissionExists, setSubmissionExists] = useState(false);
    const [submissionCreationInProgress, setSubmissionCreationInProgress] = useState(false);
    const [isComponentInitialized, setIsComponentInitialized] = useState(false);
    const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');
    const hasPerformedAccountSearch = useRef(false);
    const [USStates, setUSStates] = useState([]);
    const [initialDrivers, setInitialDrivers] = useState([]);
    const sessionIdPrefixEdit = 'EA - NB - ';
    const sessionHeader = sessionIdPrefixEdit + RandomNumberUtil.randomFixedInteger(10);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [showSearchButtonErrors, setShowSearchButtonErrors] = useState(false);
    // Rating date and quote creation date need to be editable for rate testing
    const [testRatingDate, setTestRatingDate] = useState(undefined);
    const [testQuoteCreationDate, setTestQuoteCreationDate] = useState(undefined);
    // Experience ID to override partner for a quote
    const [overriddenExperienceId, setOverriddenExperienceId] = useState(undefined);
    const environment = _get(appConfig, 'env.AMFAM_ENV', 'local');
    const { daysInFutureForBookroll, daysInFuture } = appConfig.dateInputConfig;
    const { operatingCompanyConfig } = appConfig;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const isConnectOpCo = opCo === 'CONNECT';

    if (!_get(submissionVM, 'baseData.experienceID_Ext.value')) {
        _set(submissionVM, 'baseData.experienceID_Ext.value', experienceIdMap[opCo]);
    }

    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const canChangeRiskState = authUserData?.permissions_Ext.includes('editriskaddress_ext');

    const { addNewQuoteTagIfApplicable } = useDataLayer();

    useEffect(()=>{
        addNewQuoteTagIfApplicable(submissionVM);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    // Add assignment page if the state is enabled
    useEffect(() => {
        // check for enabled state
        const enabledStates = appConfig.driverAssignmentStates ?? [];
        const nextSteps = [...steps];
        const driverAssignmentStepIndex = nextSteps.findIndex((step) => step.path === '/assignment');

        // not needed for state and does not exist in steps already
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex < 0) {
            return;
        }

        // not needed for the state, but previous state selected needed it
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.splice(driverAssignmentStepIndex, 1); // remove
            nextSteps.shift(); // remove current page (always first elt)
            changeNextSteps(nextSteps);

            return;
        }

        // needed for the state, and already has it
        if (enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            return;
        }

        // needed for the state and not already in the steps
        const driverAssignmentPage = {
            id: 'E1PEADriverAssignmentPage',
            title: {
                id: 'quoteandbind.ea.wizard.step.Drivers Assignment',
                defaultMessage: 'Driver Assignment'
            },
            path: '/assignment',
            component: 'DriverAssignmentPage',
            stepProps: {
                template: 'WizardPageTemplateWithTitle'
            }
        };
        const indexOfVehiclePage = nextSteps.findIndex((step) => step.path === '/risk-analysis');

        // insert driver assignment
        nextSteps.splice(indexOfVehiclePage, 0, driverAssignmentPage)
        // remove current page (always first elt)
        nextSteps.shift();
        changeNextSteps(nextSteps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyState]);

    useEffect(() => {
        // IAP-370: if full quote additional information page is removed
        // and flow proceeds to next page
        const nextSteps = [...steps];
        const additionalInfoStepIndex = nextSteps.findIndex((step) => step.path === '/additionalInformation');

        if (submissionVM.value.quoteType === 'Full' && additionalInfoStepIndex > -1) {
            nextSteps.splice(additionalInfoStepIndex, 1); // remove
            nextSteps.shift(); // remove current page (always first elt)
            changeNextSteps(nextSteps);
        }
    }, [changeNextSteps, steps, submissionVM.value.quoteType])

    const {
        handlePeriodStartDateChange,
        getDefaultPeriodEndDate,
        getEffectiveDateBoundsForSubmission
    } = useEffectiveDateUtil(submissionVM, updateWizardData, false);

    const {
        createAccountHolderVM,
        getLocationAndProducerCodes,
        startSubmission,
        createPrimaryNamedInsuredVM,
        getProducerDetailsFromProducerCodes
    } = useNewBusinessUtil(
        submissionVM,
        updateWizardData,
        viewModelService,
        LOB,
        isAnExistingAccount,
        authHeader,
        setLocationAndProducerCodes
    );

    const { createSecondaryNamedInsuredVM, removeSni } = useSniUtil(
        submissionVM,
        updateWizardData,
        viewModelService,
        LOB,
        disregardFieldValidation
    );

    const markAccountSearchCompleted = () => {
        hasPerformedAccountSearch.current = true;
    };

    // If Book Transfer is Yes, allow quoting up to 120 days in the future, otherwise 60 days  
    const { maxEffectiveDate, minEffectiveDate, currentMinEffectiveDate } = useMemo(
        () => bookTransferIndicator ? getEffectiveDateBoundsForSubmission(daysInFutureForBookroll)
                : getEffectiveDateBoundsForSubmission(daysInFuture),
        [getEffectiveDateBoundsForSubmission, bookTransferIndicator, daysInFutureForBookroll, daysInFuture]
    );

    useEffect(() => {
        const initialRequiredFields = ['ownsHomeIndicator', 'applicantsResideInSameStateInd', 'isUmbrellaAssociated']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
    }, [policyState]);

    useEffect(() => {
        if (submissionVM.baseData.quoteSource_Ext.sourceType === undefined) {
            const model = {
                sourceType: 'directentry',
                sourceName: 'msaagentsonly'
            };

            submissionVM.baseData.quoteSource_Ext.value = model;
        }

        authHeader['afe-session-id'] = sessionHeader;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper for getting producer code on initial render and
     * then when state changes. Will only run when a state changes
     * and on initial render so no need to make a callback as the param
     * will always be a new value.
     * @param {String} location State code
     * @param {Boolean} initialCallForExistingAccount
     */
    const getAndSetLocationCodes = async (location, initialCallForExistingAccount = false) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            if (isConnectOpCo) {
                _set(submissionVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            } else {
                _set(submissionVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
                _set(submissionVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
            }
        } else if (!initialCallForExistingAccount) {
            // dont want to reset below data on continue quote
            // or when user is creating new quote for existing account
            _set(submissionVM, 'baseData.producerCode_Ext', undefined);
            _set(submissionVM, 'baseData.externalID_Ext.value', undefined);
            setProducerCodeDetails({});
            setAgencyName(undefined);
            setBookTransferIndicator(undefined);
            setServiceCenterIndicator(undefined);
        }
    };

    useEffect(() => {
        // Policy state will not exist for new account so nothing will happen
        // If New quote for an account we will use accountholders state to set
        // policy state and that state here will set the initial producer code
        if (!isSkipping && policyState && (isAgent || isConnectOpCo)) {
            // only looking up for agents
            getAndSetLocationCodes(policyState, true).then(() => {
                setIsComponentInitialized(true);
            }).catch(() => setIsComponentInitialized(true));
        } else {(setIsComponentInitialized(true));}
        // Only run on initial render. Helper will be called again from
        //   the state fields onChange handler
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping]);

    /**
     * Since our risk State field is editable, so we need to reset the policy address with selectedState
     */
    const resetPolicyAddress = useCallback((selectedState) => {
        const { _xCenter, _dtoName } = _get(submissionVM, 'baseData.policyAddress');
        const policyAddressVM = viewModelService.create({ state: selectedState }, _xCenter, _dtoName);

        return policyAddressVM.value;
    }, [submissionVM, viewModelService]);

    const onPolicyStateChange = useCallback(
        async (newValue, path) => {
            // Set state on the VM
            _set(submissionVM, path, newValue);
            // Reset policy address
            _set(submissionVM, 'baseData.policyAddress.value', resetPolicyAddress(newValue));
            // Update data on the wizard component
            updateWizardData(submissionVM);

            // Get Producer codes for the state
            if (newValue !== undefined && (isAgent || isConnectOpCo)) {
                // We will not do this for external users
                await getAndSetLocationCodes(newValue);
                // Because above function needs a refresh
                updateWizardData(submissionVM);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getLocationAndProducerCodes, submissionVM, updateWizardData]
    );

    /**
     * Helper function to handle below scenario:
     * if insured residence address is entered and user clicks on search account
     * and choses existing account; to keep old addresses in place for
     * mailing and billing address
     */
    const setCorrectAddressAfterChosingExistingAccount = useCallback(
        (policyAddress) => {
            const mailingSameAsEnterred = AddressUtil.isSameAddress(
                policyAddress,
                _get(
                    submissionVM,
                    'baseData.policyMailingAddress_Ext.value'
                )
            );

            _set(
                submissionVM,
                'baseData.isMailingAddressSame_Ext.value',
                mailingSameAsEnterred
            );
        }, [submissionVM]
    );
    const setPNI = useCallback(
        (accountHolder) => {
            _set(submissionVM, `${pniPath}.person.value`, accountHolder);

            /**
                if insured residence address is entered and user clicks on search account
                and choses existing account; to keep old address in place for
                mailing and billing address calling fn setCorrectAddressAfterChosingExistingAccount
             */
            if (
                !!_get(submissionVM, 'baseData.policyAddress.addressLine1.value')
                && !!_get(submissionVM, 'baseData.policyAddress.city.value')
                && !!_get(submissionVM, 'baseData.policyAddress.state.value')
                && !!_get(submissionVM, 'baseData.policyAddress.postalCode.value')
            ) {
                setCorrectAddressAfterChosingExistingAccount(accountHolder.primaryAddress);
            }

            _set(submissionVM, 'baseData.policyAddress.value', accountHolder.primaryAddress);
            updateWizardData(submissionVM);
        },
        [setCorrectAddressAfterChosingExistingAccount, submissionVM, updateWizardData]
    );

    useEffect(() => {
        const accountHolderPath = 'baseData.accountHolder';
        const accountHolder = _get(submissionVM, `${accountHolderPath}.value`);
        const accountExists = !_isEmpty(accountHolder);

        if (!accountExists) {
            // Needed for account search
            createAccountHolderVM();
        }

        const pni = _get(submissionVM, `${pniPath}.value`);

        // Create PrimaryNamedInsured if necessary and populate it with accountHolder data
        if (_isEmpty(pni)) {
            createPrimaryNamedInsuredVM();

            if (accountExists) {
                _set(submissionVM, `${pniPath}.person.value`, accountHolder);

                // when transaction is not started, wizardPageHeader needs updated value
                // from PNI object to show the PNI Name in breadcrum
                // and renderContextComponent is using wizardsnapshot to read the values,
                // updateWizardData wont work here
                if (!_get(submissionVM, 'quoteID.value')) {
                    updateWizardSnapshot(submissionVM);
                }
            }
        }

        setIsAnExistingAccount(accountExists);

        // Set submission as existing (new quote for this account)
        if (_get(submissionVM, 'quoteID.value')) {
            setSubmissionExists(true);
        }
        // executed only once on initial render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AccountService]);

    const createNewAccount = useCallback(async () => {
        // Create accountHolder from pni data for account creation
        const accountHolder = {
            person: _get(submissionVM, `${pniPath}.person.value`)
        };
        const addressForAccountCreation = _get(submissionVM, 'baseData.policyAddress.value');

        // since we are showing policy address on UI, we need to create account with that address only
        // thats why we are copying policyAddress in person.primary address
        _set(accountHolder, 'person.primaryAddress', addressForAccountCreation);

        const accountHolderViewVM = viewModelService.create(
            accountHolder,
            'pc',
            'amfam.edge.capabilities.policycommon.accountcontact.dto.AccountHolderDTO',
            {
                ProducerCodeRequired: true
            }
        );
        const newAccount = {};

        newAccount.accountHolder = _get(accountHolderViewVM, 'value');

        // E1PAP1PC-12445
        // Setting selected producer code to account.producerCodes
        // to assign correct producer code to account
        const selectedProducerCode = locationAndProducerCodes?.producerCodes?.filter(
            (producerCode) => producerCode.name
                === submissionVM.value.baseData.producerCode_Ext
        );

        newAccount.producerCodes = selectedProducerCode;

        // If user is underwriter; structure for locationAndProducerCodes is different
        if (!newAccount.producerCodes) {
            const { producerCodes } = locationAndProducerCodes.find(
                (codeObject) => codeObject.producerCodes.name
                    === submissionVM.value.baseData.producerCode_Ext
            );

            newAccount.producerCodes = [producerCodes];
        }

        const expID = _get(submissionVM, 'baseData.experienceID_Ext.value');

        // overriddenExperienceId is from setting in special rating test fields
        // expID is if it already exists on base data; came in from a url param bridge
        // experienceIdMap is for direct entry; new quote+new account
        newAccount.experienceID = overriddenExperienceId || expID || experienceIdMap[opCo];

        const newAccountResponse = await AccountService.getNewAccountForContact(
            newAccount,
            authHeader
        );

        return newAccountResponse;
    }, [authHeader, locationAndProducerCodes, opCo, overriddenExperienceId, submissionVM, viewModelService]);

    const showAccountsModal = useCallback(async (vm) => {
        const componentProps = {
            title: 'Existing Accounts',
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            accountsVM: vm
        };

        return modalApi.showModal(<AccountsFoundComponent {...componentProps} />);
    }, [modalApi]);

    const createNewAccountForSubmission = useCallback(async () => {
        const newAccountResponse = await createNewAccount();

        if (newAccountResponse.accountNumber) {
            _set(
                submissionVM,
                'baseData.accountNumber.value',
                newAccountResponse.accountNumber
            );
            setIsAnExistingAccount(true);
        } else {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.accountTypeErrorMessage,
                message: messages.newAccountCreateErrorMessage
            }).then(() => {
                setSubmissionCreationInProgress(false);
                window.history.back();
            });
        }
    }, [createNewAccount, submissionVM, modalApi]);

    const areAcknowledgementsAccepted = useCallback(() => {
        const isSourceTypeCompRater = _get(
            submissionVM,
            'baseData.quoteSource_Ext.sourceType.value.code'
        ) === 'comprater';

        if (isSourceTypeCompRater && isSkipping) {
            return true;
        }

        if (submissionVM.value.lobData.personalAuto_EA.acknowledgements === undefined) {
            return false;
        }

        return submissionVM.value.lobData.personalAuto_EA.acknowledgements.every(
            (acknowledgement) => acknowledgement.acknowledgeAnswerType === 'accept'
        );
    }, [submissionVM, isSkipping]);

    const handleAcknowledgementsValueChange = useCallback(
        (value) => {
            _set(submissionVM, `lobData[${LOB}].acknowledgements`, value);
            updateWizardData(submissionVM);
        },
        [updateWizardData, submissionVM]
    );

    const isProducerCodeDisabled = useMemo(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(submissionVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(submissionVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);

            return true;
        }

        return false;
    }, [locationAndProducerCodes, submissionVM]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isStandardizingAddress) {
            loadingMessage = translator(messages.standardizingAddressMessage);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isSkipping) {
            loadingMessage = translator(messages.loadingNextPageMessage);
        } else {
            loadingMessage = translator(messages.creatingYourSubmissionMessage);
        }

        return loadingMessage;
    }, [isStandardizingAddress, isSavingCurrentPageChanges, isSkipping, translator]);

    /**
     * Setting mailingAddress and billingAddressValues based on indicators
     */
    const setCorrectAddressValues = useCallback(
        () => {
            // if mailingAddressSameInd is true then set policyAddress value to mailingAddress
            if (
                _get(
                    submissionVM,
                    'baseData.isMailingAddressSame_Ext.value'
                )
            ) {
                _set(
                    submissionVM,
                    'baseData.policyMailingAddress_Ext.value',
                    _get(submissionVM, 'baseData.policyAddress.value')
                );
            }

            //  if billingAddressSameInd is true then set mailingAddress value to billingAddress
            if (
                _get(
                    submissionVM,
                    'baseData.isBillingAddressSame_Ext.value'
                )
            ) {
                _set(
                    submissionVM,
                    'baseData.policyBillingAddress_Ext.value',
                    _get(submissionVM, 'baseData.policyMailingAddress_Ext.value')
                );
            }

            // if isGaragingAddressSame_Ext is true on vehicle, then set policy address in garaging location
            if (!_isEmpty(
                _get(submissionVM, 'lobData.personalAuto_EA.coverables.vehicles.value')
            )) {
                submissionVM.lobData.personalAuto_EA.coverables.vehicles.children.forEach((vehicle) => {
                    if (_get(vehicle, 'isGaragingAddressSame_Ext.value')) {
                        _set(vehicle, 'garagingLocation.value', _get(submissionVM, 'baseData.policyAddress.value'));
                    }
                });
            }
        }, [submissionVM]
    );

    /**
     * since user can now edit the policy Address(insured residence information),
     * so after creation of job, we need to use the address entered by user or which is
     * available in submissionVM for policy address, and need to sync it to other addresses as well
     */
    const setPolicyMailingBillingAdressValuesAfterSubmissionCreation = useCallback(
        (submission) => {
            const policyAddressOnVM = _get(submissionVM, 'baseData.policyAddress.value');

            _set(submission, 'baseData.policyAddress', policyAddressOnVM);

            const mailingSameAsEnterred = AddressUtil.isSameAddress(
                _get(
                    submission,
                    'baseData.policyAddress'
                ),
                _get(
                    submissionVM,
                    'baseData.policyMailingAddress_Ext.value'
                )
            );
            const billingSameAsEnterred = AddressUtil.isSameAddress(
                _get(
                    submissionVM,
                    'baseData.policyMailingAddress_Ext.value'
                ),
                _get(
                    submissionVM,
                    'baseData.policyBillingAddress_Ext.value'
                )
            );

            _set(
                submission,
                'baseData.isBillingAddressSame_Ext',
                billingSameAsEnterred
            );
            _set(
                submission,
                'baseData.isMailingAddressSame_Ext',
                mailingSameAsEnterred
            );
            _set(
                submission,
                'baseData.policyBillingAddress_Ext',
                submissionVM.baseData.policyBillingAddress_Ext.value
            );
            _set(
                submission,
                'baseData.policyMailingAddress_Ext',
                submissionVM.baseData.policyMailingAddress_Ext.value
            );
        }, [submissionVM]
    );

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setSubmissionCreationInProgress(true);
        setCorrectAddressValues();

        // No account or no submission existing yet
        if (!isAnExistingAccount || !submissionExists) {
            // Create an account if not existing
            if (!isAnExistingAccount) {
                // If user hasn't searched for the account, perform search
                if (!hasPerformedAccountSearch.current) {
                    const accountHolder = {
                        person: _get(submissionVM, `${pniPath}.person.value`)
                    };

                    _set(
                        accountHolder,
                        'person.primaryAddress',
                        submissionVM.baseData.policyAddress.value
                    );

                    const connectConfigObject = operatingCompanyConfig[opCo].experiences;
                    let partnerCode;

                    for (const key in connectConfigObject) {
                        // eslint-disable-next-line no-prototype-builtins
                        if (connectConfigObject.hasOwnProperty(key)) {
                            if (connectConfigObject[key].experienceId === _get(submissionVM, 'baseData.experienceID_Ext.value')) {
                                partnerCode = key;

                                if (connectConfigObject[key].retired && connectConfigObject[key].mergedPartnerCode) {
                                    partnerCode = connectConfigObject[key].mergedPartnerCode;
                                }
                            }
                        }
                    }

                    const response = await AccountSearchUtil.lookupAccount(
                        accountHolder.person,
                        submissionVM.baseData.value.producerCode_Ext,
                        partnerCode,
                        authHeader, viewModelService
                    );

                    try {
                        // user selected an account
                        const { chosenAccount } = await showAccountsModal(response);

                        if (chosenAccount.accountHolder) {
                            submissionVM.baseData.accountHolder.value = chosenAccount.accountHolder.person;
                            submissionVM.baseData.accountNumber.value = chosenAccount.accountNumber;
                            submissionVM.baseData.policyAddress.value = chosenAccount.accountHolder.person.primaryAddress;
                            setIsAnExistingAccount(true);
                        } else {
                            // Create new account when user selects Create New Account
                            // Button/Radio Button
                            await createNewAccountForSubmission();
                        }
                    } catch {
                        // User clicks on Cancel button to stop the creation of Account
                        setSubmissionCreationInProgress(false);

                        return false;
                    }
                } else {
                    // User has searched manually and wants to use a new account
                    await createNewAccountForSubmission();
                }
            }

            // Create new submission
            let submission = await startSubmission({ testQuoteCreationDate, testRatingDate, overriddenExperienceId });

            // Set values from the form
            setPolicyMailingBillingAdressValuesAfterSubmissionCreation(submission);
            submission = await updateSubmissionFromForm(submission, submissionVM, viewModelService, authHeader);

            _set(submissionVM, 'value', submission);
            // Need this for validation rules
            _set(submissionVM, 'flowStepIDs_Ext.value', ['namedinsured']);
            _set(submissionVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);

            if (!_isEmpty(_get(submissionVM, 'value.baseData.producerCode_Ext'))) {
                _set(
                    submissionVM,
                    'baseData.value.producerCode',
                    _get(submissionVM, 'value.baseData.producerCode_Ext')
                );
            }

            // Update draft with value from the form
            authHeader['afe-session-id'] = sessionIdPrefixEdit + _get(submissionVM, 'quoteID.value');

            const QuoteDataResponse = await LoadSaveService.updateDraftSubmission(
                submissionVM.value,
                authHeader
            );

            _set(submissionVM, 'value', QuoteDataResponse);

            if(_get(submissionVM, 'value.baseData.accountNumber')){
                setIsAnExistingAccount(true);
            }

            if (_get(submissionVM, 'value.quoteID')) {
                setSubmissionExists(true);
            }

            setSubmissionCreationInProgress(false);

            return submissionVM;
        }

        _set(submissionVM, 'baseData.quoteSource_Ext.value', {
            sourceType: 'directentry',
            sourceName: 'msaagentsonly'
        });
        // Need this for validation rules
        _set(submissionVM, 'flowStepIDs_Ext.value', ['namedinsured']);
        _set(submissionVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);

        // sync NI changes to Drivers
        const drivers = _get(submissionVM.value, 'lobData.personalAuto_EA.coverables.drivers');
        const pni = _get(submissionVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
        const sni = _get(submissionVM.value, 'lobData.personalAuto_EA.secondaryNamedInsured');
        const pniDriver = drivers.find((driver) => driver.person.publicID === pni.person.publicID);
        // SNI may be undefined so being null safe there
        // checking sni public id in case user comes back to insured page to add sni,
        // we dont have public id in this case
        const sniDriver = drivers.find((driver) => sni?.person?.publicID && driver.person.publicID === sni?.person?.publicID);

        if (pniDriver) {
            // updates to existing pni contact
            _set(pniDriver, 'person', pni.person);
            // setting the license on the person otherwise
            // updateDraftSubmission wipes it off when it's just under driver
            _set(pniDriver, 'person.licenseState', pniDriver.licenseState);
            _set(pniDriver, 'person.licenseNumber', pniDriver.licenseNumber);
        }

        if (pni && !pniDriver) {
            // check if PNI is already present in existing drivers
            // if present then add in the drivers, but do not create new here, that is handled in driver page
            if (!_isEmpty(initialDrivers)) {
                const initialPniDriver = initialDrivers
                    .find((driver) => driver.person.publicID === pni?.person?.publicID);

                if (initialPniDriver) {
                    _set(initialPniDriver, 'person', pni.person);
                    _set(initialPniDriver, 'relationshipToNI', undefined);
                    drivers.push(initialPniDriver);

                    _set(submissionVM.value, 'lobData.personalAuto_EA.coverables.drivers', drivers);
                }
            }
        }

        if (sni && sniDriver) {
            // updates to existing sni contact
            _set(sniDriver, 'person', sni?.person);
            // setting the license on the person otherwise
            // updateDraftSubmission wipes it off when it's just under driver
            _set(sniDriver, 'person.licenseState', sniDriver.licenseState);
            _set(sniDriver, 'person.licenseNumber', sniDriver.licenseNumber);
        }

        if (sni && !sniDriver) {
            // check if SNI is already present in existing drivers
            // if present then add in the drivers, but do not create new here, that is handled in driver page
            if (!_isEmpty(initialDrivers)) {
                const initialSniDriver = initialDrivers
                    .find((driver) => driver.person.publicID === sni?.person?.publicID);

                if (initialSniDriver) {
                    _set(initialSniDriver, 'person', sni.person);
                    _set(initialSniDriver, 'relationshipToNI', sni?.relationshipToNI);
                    drivers.push(initialSniDriver);

                    _set(submissionVM.value, 'lobData.personalAuto_EA.coverables.drivers', drivers);
                }
            }
        }

        if (!_isEmpty(_get(submissionVM, 'value.baseData.producerCode_Ext'))) {
            _set(
                submissionVM,
                'baseData.value.producerCode',
                _get(submissionVM, 'value.baseData.producerCode_Ext')
            );
        }

        // Update draft with changes to NI
        authHeader['afe-session-id'] = sessionIdPrefixEdit + _get(submissionVM, 'quoteID.value');

        const QuoteDataResponse = await LoadSaveService.updateDraftSubmission(
            submissionVM.value,
            authHeader
        );

        _set(submissionVM, 'value', QuoteDataResponse);
        setSubmissionCreationInProgress(false);

        return submissionVM;
    }, [isComponentValid, setCorrectAddressValues, isAnExistingAccount, submissionExists, submissionVM, authHeader, startSubmission, testQuoteCreationDate, testRatingDate, overriddenExperienceId, setPolicyMailingBillingAdressValuesAfterSubmissionCreation, viewModelService, operatingCompanyConfig, opCo, showAccountsModal, createNewAccountForSubmission, initialDrivers]);

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    /**
                    * E1PAP1PC-14867 :
                    * wizardData and wizardSnapshot not being equal due to
                    * some defaulting on each page so doing this temp fix
                    */
                    if (submissionVM.baseData.value.producerCode) {
                        _set(submissionVM, 'baseData.value.producerCode_Ext', submissionVM.baseData.value.producerCode);
                    }

                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, submissionVM, updateWizardSnapshot]
    );

    const doesSubmissionExist = useCallback(() => (
            _get(submissionVM.value, 'quoteType') === 'Full'
            || !!_get(submissionVM, 'quoteID.value')
        ), [submissionVM]);

    useEffect(() => {
        const stateValues = e1pUSStatesUtil.getUSStates(viewModelService);

        setUSStates(stateValues);

        const existingDrivers = _cloneDeep(_get(submissionVM.value, 'lobData.personalAuto_EA.coverables.drivers'));

        setInitialDrivers(existingDrivers);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkIsEffectiveDateValid = useCallback(
        () => {
            // If user does not have back date permissions and the policy effective date is 5 days past the current date then return false
            const policyEffectiveDate = moment(submissionVM.baseData?.periodStartDate?.value);

            if (policyEffectiveDate?.isBefore(moment().subtract(5, 'days'), 'd')
                && !(authUserData?.permissions_Ext.includes('increasedeffectivedaterange_ext') || authUserData.permissions_Ext.includes('unlimitedbackdate'))) {
                _set(submissionVM, 'transitioning', false);

                return false;
            }

            return true;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissionVM.baseData.periodStartDate?.value]
    );

    // IAP-808, user should land on insuredPage for copied quote
    // backend is resetting acknowledgements and sourceType to null/undefined
    const isUserAllowedToProceedForCopiedQuotes = useCallback(() => {
        const sourceTypeCondition = (_get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value') === undefined
            || _get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'directentry');

        if (sourceTypeCondition) {
            return _get(submissionVM, 'value.lobData.personalAuto_EA.acknowledgements.length') > 0
                && submissionVM.value.lobData.personalAuto_EA.acknowledgements.every(
                    (acknowledgement) => acknowledgement?.acknowledgeAnswerType === 'accept');
        }

        return true;
    }, [submissionVM]);

    const isPageValid = useCallback(() =>
        doesSubmissionExist() && checkIsEffectiveDateValid() && isUserAllowedToProceedForCopiedQuotes(),
        [checkIsEffectiveDateValid, doesSubmissionExist, isUserAllowedToProceedForCopiedQuotes]);

    useEffect(() => {
        // For skipWhen
        registerInitialComponentValidation(isPageValid);
        // For disableNext
        registerComponentValidation(() => areAcknowledgementsAccepted() && checkIsEffectiveDateValid());
    }, [
        checkIsEffectiveDateValid,
        registerInitialComponentValidation,
        registerComponentValidation,
        isPageValid,
        areAcknowledgementsAccepted
    ]);

    const handleAddressValueChange = useCallback(
        (newVal, localPath) => {
            _set(submissionVM, localPath, newVal);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    useEffect(() => {
        // Don't hit any apis if this is a continue quote
        if (isSkipping) {return;}

        const  producerCode = producerCodeDetails?.producerCode;
        const existingProducerCode = _get(
            submissionVM, 'value.baseData.producerCode_Ext',
            _get(submissionVM, 'value.baseData.producerCode')
        );

        if (existingProducerCode && isAgent
            && existingProducerCode !== producerCode) {
            (async () => {
                authHeader['afe-session-id'] = sessionIdPrefixEdit + _get(submissionVM, 'quoteID.value');

                const details = await getProducerDetailsFromProducerCodes(
                    existingProducerCode,
                    locationAndProducerCodes.producerCodes,
                    submissionVM.value.baseData.externalID_Ext
                );

                // in case we dont get any response from service
                if (details) {
                    setProducerCodeDetails(details);
                    setAgencyName(details.agencyName);
                    setBookTransferIndicator(details.bookTransferIndicator);
                    setServiceCenterIndicator(details.serviceCenterIndicatorValue);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM.baseData.producerCode_Ext.value, isSkipping]);

    useEffect(() => {
        /**
         * TODO:IAP-4140 need to remove this logic once the ProducerAPI is fixed from backend for CONNECT and OPCO dropdown is removed from page Header
         * 
         * Description: clear the exception if the producercode is available, this is required when 
         * user selected toggle as Connect and started new quote and on change of state user will get the exception
         * then user selected toggle as MSA, and provide location code, which will fetch the producercode, 
         * but we will be still showing the exception, that we need to remove once we have producerCode.
         */
        if (!isSkipping) {
            const existingProducerCode = _get(
                submissionVM, 'value.baseData.producerCode_Ext',
                _get(submissionVM, 'value.baseData.producerCode')
            );
            const exceptions = _get(submissionVM, 'value.baseData.exceptions_Ext', []);
            const isShowingExceptionForProducerCode = _findIndex(exceptions, { errorMessage: translator(e1pCommonMessages.genericErrorText) }) > -1;

            if (existingProducerCode && isShowingExceptionForProducerCode && exceptions.length === 1) {
                _set(submissionVM, 'value.baseData.exceptions_Ext', []);
                updateWizardData(submissionVM);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM.baseData.producerCode_Ext.value, submissionVM.baseData.exceptions_Ext.value, isSkipping]);

    const locationCodeChange = useCallback(
        (value) => {
            _set(submissionVM, 'baseData.externalID_Ext', value);

            const selectedProducerCode = locationAndProducerCodes?.producerCodes
                ?.find((producerCode) => producerCode?.externalId_Ext === value);

            _set(submissionVM, 'baseData.producerCode_Ext', selectedProducerCode?.code);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData, locationAndProducerCodes]
    );

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, address, phone, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            setLocationAndProducerCodes(
                [
                    {
                        producerCodes: { code: producerCode, name: producerCode },
                        locationCodes: { code: externalID, name: externalID }
                    }
                ]
            );

            _set(submissionVM, 'baseData.producerCode_Ext', producerCode);
            _set(submissionVM, 'baseData.externalID_Ext.value', externalID);
            setProducerCodeDetails({ producerCode, address, phone });
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [submissionVM],
    );

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate,
            onRemoveSni: removeSni,
            onMarkAccountSearchCompleted: markAccountSearchCompleted,
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !submissionCreationInProgress
            && !isSkipping
            && !isSavingCurrentPageChanges, [isSavingCurrentPageChanges, isSkipping, submissionCreationInProgress]);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            showRequired: true,
            autoComplete: false
        },
        policyState: {
            onValueChange: onPolicyStateChange,
            availableValues: e1pUSStatesUtil.getStateValues(USStates, translator),
            readOnly: !canChangeRiskState
        },
        policyDetailsPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        policyDetailsPageContainer: {
            visible: isPageLoaded
        },
        coverageStartDate: {
            updateDateDto: handlePeriodStartDateChange,
            dateDTO: submissionVM.baseData.periodStartDate,
            defaultToToday: true,
            minDate: minEffectiveDate,
            maxDate: maxEffectiveDate,
            currentMinDate: currentMinEffectiveDate,
            showErrors: isPageSubmitted || !_isUndefined(_get(submissionVM.value, 'quoteID'))
        },
        coverageEndDate: {
            dateDTO: submissionVM.baseData.periodEndDate,
            updateDateDto: () => updateWizardData(submissionVM),
            readOnly: true,
            defaultValue: getDefaultPeriodEndDate
        },
        personalInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        originalEffectiveDate: {
            dateDTO: submissionVM.baseData.periodStartDate,
            updateDateDto: () => updateWizardData(submissionVM),
            readOnly: true,
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            readOnly: isProducerCodeDisabled,
            onValueChange: locationCodeChange,
            visible: isAgent,
            showErrors: isPageSubmitted || showSearchButtonErrors
        },
        ExternalProducerDetailsComponent: {
            visible: !isAgent,
            defaultValue: submissionVM.baseData.externalID_Ext?.value,
            showErrors: isPageSubmitted || showSearchButtonErrors,
            producerCodeDetails
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        sniContainer: {
            visible: !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        removeSni: {
            visible: !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        primaryNamedInsured: {
            showErrors: isPageSubmitted,
            isPNI: true
        },
        addSni: {
            visible: !_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },

        secondaryNamedInsured: {
            visible: !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            showErrors: isPageSubmitted
        },
        policyDetailsContainer: {
            visible: !submissionCreationInProgress
        },
        mailingAndBillingAddressComponent: {
            transactionVM: submissionVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            wizardSnapshot,
            showErrors: isPageSubmitted
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        rentOrOwn: {
            required: _includes(requiredFields, 'ownsHomeIndicator')
        },
        excessLiabilityInd: {
            required: true,
            visible: _includes(requiredFields, 'isUmbrellaAssociated'),
        },
        e1pAcknowledgementComponent: {
            acknowledgements: _get(submissionVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: policyState,
                name: _get(submissionVM, 'baseData.policyAddress.state.value.name')
                    ? translator({ id: _get(submissionVM, 'baseData.policyAddress.state.value.name') })
                    : undefined
            },
            onValueChange: handleAcknowledgementsValueChange,
            showErrors: isPageSubmitted,
            lob: LOB
        },
        sourceType: {
            readOnly: true
        },
        accountSearchComponent: {
            viewModelService,
            setIsAnExistingAccount,
            setPNI,
            authHeader,
            visible: !_get(submissionVM, 'baseData.accountNumber.value'),
            showSearchButtonErrors,
            setShowSearchButtonErrors,
            searchButtonErrorFunc: () => {
                setShowSearchButtonErrors(true);
                window.scrollTo(0, 0);

                return false;
            }
        },
        changePniComponent: {
            accountNumber: _get(submissionVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(submissionVM, 'baseData.accountNumber.value'),
            excludedContacts: [
                _get(submissionVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            drivers: _get(
                submissionVM.value,
                `lobData[${LOB}].coverables.drivers`,
                undefined
            )
        },
        changeSniComponent: {
            accountNumber: _get(submissionVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(submissionVM, 'baseData.accountNumber.value')
                && !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            excludedContacts: [
                _get(submissionVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            drivers: _get(
                submissionVM.value,
                `lobData[${LOB}].coverables.drivers`,
                undefined
            )
        },
        insuredResidenceAddress: {
            addressVM: _get(submissionVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate,
            onAddressChange: (value, path) => handleAddressValueChange(value, `baseData.policyAddress.${path}`),
            viewOnlyMode: false,
            showParentLoader: setIsStandardizingAddress,
            showErrors: isPageSubmitted
        },
        EaRatingDateTestComponent: {
            testRatingDate,
            setTestRatingDate,
            testQuoteCreationDate,
            setTestQuoteCreationDate,
            overriddenExperienceId,
            setOverriddenExperienceId,
            // not possible to change after account is created
            canChangeExperienceId: !_get(submissionVM, 'baseData.accountNumber.value', undefined),
            visible: !_get(submissionVM, 'quoteID.value', false)
                && environment !== 'prod'
        },
        applicantsResideInSameStateInd: {
            required: true,
            visible: _includes(requiredFields, 'applicantsResideInSameStateInd'),
        },
        quoteCreationDate: {
            dateDTO: submissionVM.baseData.quoteCreationDate_Ext,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        rateAsOfDate: {
            dateDTO: submissionVM.baseData.periodRateAsOfDate,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        ratingDate: {
            dateDTO: submissionVM.baseData.ratingDate,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        agencyInformationPageContainer: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: submissionVM,
            authHeader,
            LOB: 'personalAuto_EA'
        }
    };

    const readValue = (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);

    if (!isComponentInitialized) {
        return null;
    }

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            onNext={onNext}
            showPrevious={false}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave={!!_get(submissionVM, 'quoteID.value', false)}
            isPageSubmittedWithErrors={(isPageSubmitted && !isComponentValid) || showSearchButtonErrors}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

PolicyDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(PolicyDetailsPage);