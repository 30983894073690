import React, { useCallback, useState } from 'react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslator } from '@jutro/locale';
import { commonMessages } from 'e1p-platform-translations';
import { CreditService } from 'e1p-capability-gateway';
import { get } from 'lodash';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { e1pDateUtil } from 'e1p-capability-hooks';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import messages from './E1PReorderCreditModalComponent.messages';

import { Button } from '@jutro/legacy/components';

function E1PReorderCreditModalComponent(props) {
    const {
        isOpen,
        onReject,
        onResolve,
        authHeader,
        policyNumber,
        effectiveDate
    } = props;

    const translator = useTranslator();
    const [isReorderingCreditReport, setIsReorderingCreditReport] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    /**
     * Helper function gets called when user clicks on reorder credit
     */
    const onReorderReport = useCallback(
        async () => {
            setIsReorderingCreditReport(true);
            setValidationErrors([]);

            try {
                // Backend requires either of jobNumber / policyNumber
                // Sending effectiveDate in utc format as part of E1PAP1PC-15062
                await CreditService.orderCreditReconsideration(
                    undefined, policyNumber, e1pDateUtil.convertToUTC(effectiveDate), authHeader
                );
                onResolve(true);
            } catch (error) {
                const errorMessage = get(error, 'baseError');

                setValidationErrors([{ description: errorMessage }]);
            }

            setIsReorderingCreditReport(false);
        }, [authHeader, effectiveDate, onResolve, policyNumber]
    );

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={translator(messages.reorderCreditModalTitle)} />
            <ModalBody>
                <E1PLoader
                    loaded={!isReorderingCreditReport}
                    text={translator(messages.reorderInProgressLabel)}
                />
                <WizardSingleErrorComponent issuesList={validationErrors} />
                {
                    !isReorderingCreditReport
                    && (
                        <div>
                            {`${translator(messages.reorderCreditModalConformationMessage)} ${moment(effectiveDate).format('l')}.`}
                        </div>
                    )
                }
            </ModalBody>
            <ModalFooter>
                <Button type="outlined" onClick={onCancel} disabled={isReorderingCreditReport}>
                    {translator(commonMessages.cancel)}
                </Button>
                <Button
                    onClick={onReorderReport}
                    disabled={isReorderingCreditReport}
                >
                    {translator(messages.reorderCreditModalButtonLabel)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

E1PReorderCreditModalComponent.propTypes = {
    authHeader: PropTypes.shape({}).isRequired,
    isOpen: PropTypes.bool.isRequired,
    policyNumber: PropTypes.string.isRequired,
    effectiveDate: PropTypes.string.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired
};
export default E1PReorderCreditModalComponent;
