import { defineMessages } from 'react-intl';

export default defineMessages({
    applyingChangesMessage: {
        id: 'quoteandbind.eu.views.payment-details.Applying changes...',
        defaultMessage: 'Applying changes...'
    },
    completingYourPurchaseMessage: {
        id: 'quoteandbind.eu.views.payment-details.Completing your purchase...',
        defaultMessage: 'Completing your purchase...'
    },
    loadingPayments: {
        id: 'quoteandbind.eu.views.payment-details.Please wait while we communicate with the payments system...',
        defaultMessage: 'Please wait while we communicate with the payments system...'
    },
    paymentBuyNow: {
        id: 'quoteandbind.eu.views.payment-details.Buy Now',
        defaultMessage: 'Buy Now'
    },
    backToPolicy: {
        id: 'quoteandbind.eu.views.payment-details.Back To Policy',
        defaultMessage: 'Back To Policy'
    },
    issueLabel: {
        id: 'quoteandbind.e1p.eu.views.payment-details.issueLabel',
        defaultMessage: 'Issue'
    },
    unableToBindTitle: {
        id: 'quoteandbind.e1p.eu.views.payment-details.Unable to bind',
        defaultMessage: 'Unable to bind'
    }
});
