import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useModal } from '@jutro/components';
import { withIntl, useTranslator } from '@jutro/locale';
import _ from 'lodash';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { useHistory } from 'react-router-dom';
import { TransactionViewFlowUtil } from 'e1p-portals-util-js';
// eslint-disable-next-line import/no-relative-packages
import { TimelineService } from '../../../../capabilities/e1p-capability-journey';
import metadata from './HistoryTableComponent.metadata.json5';
import message from './HistoryTableComponent.messages';

import { Button, CheckboxField } from '@jutro/legacy/components';

import { Link as LinkComponent } from '@jutro/router';

function HistoryTableComponent(props) {
    const modalApi = useModal();
    const {
        events,
        policyType,
        updateSelectedHistoryJobIdTypes,
        redirectToAnotherTile,
        authHeader
    } = props;

    const history = useHistory();
    const translator = useTranslator();

    const [historyRecordsSelected, updateHistoryRecordsSelected] = useState([]);

    useEffect(() => {
        if (events.length > 0) {
            updateHistoryRecordsSelected(events);
        }
    }, [events]);

    const getEffectiveDateCell = (data) => {
        const { intl } = props;

        if (data.effectiveDate) {
            return intl.formatDate(new Date(data.effectiveDate), {
                timeZone: 'UTC'
            });
        }

        return undefined;
    };

    const getTimestampCell = (data) => {
        let modifiedDate;

        if (data.timestamp) {
            modifiedDate = moment(data.timestamp).format('MM/DD/YYYY - h:mm a');

            return modifiedDate;
        }

        return undefined;
    };

    const goToViewOnlyFlow = useCallback((job) => TransactionViewFlowUtil.goToTransactionSpecificViewOnlyFlow(
            history, job.jobID, policyType, job.type, job.productCode, job.policyNumber
        ), [history, policyType]);

    const getJobNumberLink = (item) => {
        if (item.status === 'Bound'
            && (item.type === 'Submission' || item.type === 'PolicyChange')) {
            return (
                <LinkComponent
                    onClick={() => goToViewOnlyFlow(item)}
                >
                    {item.jobID}
                </LinkComponent>
            );
        }

        return (
            <LinkComponent
                to={JobUtil.getJobDetailURLByJobType(item.type, item.jobID)}
            >
                {item.jobID}
            </LinkComponent>
        );
    };
    const goToDocumentTile = useCallback(async (job) => {
        await redirectToAnotherTile('documents');

        return history.push({
            pathname: `/policies/${job.policyNumber}/documents`,
            jobNumber: job.jobID
        });
    }, [history, redirectToAnotherTile]);

    const getViewDocumentLink = (item) => {
        if (item.jobID) {
            return (
                <LinkComponent
                    onClick={() => goToDocumentTile(item)}
                >
                    {translator(message.viewDocuments)}
                </LinkComponent>
            );
        }

        return (
            <span />
        );
    };

    const getDocURL = async (event, item) => {
        event.preventDefault();

        try {
            const responseData = await TimelineService.getDocumentURL(item, authHeader);

            if (!_.isEmpty(responseData)) {
                window.open(responseData);
            }
        } catch (error) {
            modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: message.retrieveDocumentError,
                message: error.baseError
            }).catch(_.noop);
        }
    };

    const getRetrieveLetterButton = (item) => {
        if (item.eventName.includes('Interactive Letter Sent')) {
            return (
                <Button type="outlined" onClick={(e) => getDocURL(e, item)}>
                    {translator(message.retrieveDocumentMessage)}
                </Button>
            );
        }

        return (
            <span />
        );
    };

    const getCell = (item, index, { id: property }) => item[property] ? item[property] : '-';

    const handleCheckBoxClick = useCallback((item) => {
        const newSelectedHistoryRecords = [];

        _.forEach(historyRecordsSelected, (historyRecord) => {
            if (historyRecord.timestamp === item.timestamp
                && historyRecord.eventName === item.eventName) {
                newSelectedHistoryRecords.push({
                    ...historyRecord,
                    selected: !historyRecord.selected
                });
            } else {
                newSelectedHistoryRecords.push(historyRecord);
            }
        });

        updateHistoryRecordsSelected(newSelectedHistoryRecords);

        const selectedHistoryRecords = _.filter(
            newSelectedHistoryRecords, (record) => record.selected
        );

        updateSelectedHistoryJobIdTypes(_.map(selectedHistoryRecords, (record) => ({
                jobId: record.jobID,
                jobType: record.type
            })));
    }, [historyRecordsSelected, updateSelectedHistoryJobIdTypes]);

    const getCheckboxDataCell = useCallback(
        (item, index) => (
                !item.eventName.includes('Letter')
                    ? (
                        <CheckboxField
                            name={item.subject}
                            id={`id${index}`}
                            value={item.selected}
                            onValueChange={() => handleCheckBoxClick(item)}
                        />
                    ) : <span />
            ),
        [handleCheckBoxClick]
    );


    const resolvers = {
        resolveCallbackMap: {
            getEffectiveDateCell,
            getTimestampCell,
            getCheckboxDataCell,
            getJobNumberLink,
            getViewDocumentLink,
            getRetrieveLetterButton,
            getCell
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        historyTable: {
            data: _.filter(historyRecordsSelected, (record) => record.eventName !== 'Purging enabled'),
            visible: (!_.isEmpty(events)),
        },
        // showing this column only when if 'Interactive Letter Sent' event is available in historyRecordsSelected
        historyRetrieveLetter: {
            visible: !!_.find(historyRecordsSelected, (record) => record.eventName.includes('Interactive Letter Sent'))
        }
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                events,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [events, overrideProps]
    );

    const data = {
        events
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={data}
            overrideProps={overrideProps}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

HistoryTableComponent.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({})),
    policyType: PropTypes.string,
    updateSelectedHistoryJobIdTypes: PropTypes.func.isRequired,
    redirectToAnotherTile: PropTypes.func.isRequired,
    intl: PropTypes.shape({}),
    authHeader: PropTypes.shape({}).isRequired
};
HistoryTableComponent.defaultProps = {
    events: {},
    policyType: {},
    intl: {}
};
export default withIntl(HistoryTableComponent);
