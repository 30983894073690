/* eslint-disable arrow-body-style */
import React, {
    useCallback, useEffect, useState, useRef, useMemo, useContext
} from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import _ from 'lodash';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { useDataLayer } from 'e1p-capability-hooks';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { error as loggerError } from '@jutro/logger';
import config from 'app-config';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { wizardStepProps } from './prop-types/wizardPropTypes';
import useWizardSteps from './hooks/useWizardSteps';
import useWizardData from './hooks/useWizardData';
import useWizardErrors from './hooks/useWizardErrors';
import { WizardContext } from './WizardContext';
import WizardRoutes from './WizardRoutes';

const coverageExceptionEntityNames = [
    'EHDwellingCov_Ext', 'EHLineCov_Ext',
    'EAVehicleCov_Ext', 'EAAutoLineCov_Ext',
    'EUUnderlyingPolicyCov_Ext', 'EULineCov_Ext'
];

const checkIsDataValid = (data) => {
    return (
        !ViewModelUtil.isViewModelNode(data) || (data.aspects.subtreeValid && data.aspects.valid)
    );
};

const checkDataValidityDuringTransition = (wizardData, wizardSnapshot, modalMessageProps,
    cloneData, updateWizardData, isPageValid = true, viewOnly = false) => {

    const { modalApi } = _.get(window, '__giamfam.modalApi');

    return viewOnly || (isPageValid && checkIsDataValid(wizardData))
        ? wizardData
        : modalApi.showConfirm(modalMessageProps).then(
            (results) => {
                if (results === 'cancel') {
                    return _.stubFalse();
                }

                updateWizardData(cloneData(wizardSnapshot));

                return wizardSnapshot;
            }, _.stubFalse
        );
};

/*
 E1PAP1PC-13531 : when user clicks on chevron if data is changed on page show discard message dialog
 to check whether data is changed or not on current page comparing wizardData with wizardSnapshot
 */
const checkHasChangedOrNotDuringTransition = (wizardData, wizardSnapshot,
    cloneData, updateWizardData, viewOnly = false) => {

    const { modalApi } = _.get(window, '__giamfam.modalApi');

    return viewOnly || _.isEqual(wizardData.value, wizardSnapshot.value)
        ? wizardData
        : modalApi.showConfirm({
            title: e1pCommonMessages.unsavedChangeHeader,
            message: e1pCommonMessages.unsavedChangeMessage,
            confirmButtonText: e1pCommonMessages.leave,
            cancelButtonText: e1pCommonMessages.cancel
        }).then(
            (results) => {
                if (results === 'cancel') {
                    return _.stubFalse();
                }

                updateWizardData(cloneData(wizardSnapshot));

                return wizardSnapshot;
            }, _.stubFalse
        );
};

const checkContinue = (result) => {
    if (result === false) {
        return Promise.reject(new Error(false));
    }

    return Promise.resolve(result);
};

const handleOnPrevious = (wizardProps) => {
    const {
        wizardData, wizardSnapshot, onPreviousModalProps,
        cloneData, updateWizardData, isPageValid, viewOnly
    } = wizardProps;

    if (viewOnly) {
        return wizardData;
    }

    return checkDataValidityDuringTransition(wizardData, wizardSnapshot, onPreviousModalProps,
        cloneData, updateWizardData, isPageValid);
};

function Wizard(props) {
    const {
        wizardTitle,
        initialSteps,
        initialData,
        skipCompletedSteps,
        onFinish,
        onCancel,
        onCustom1,
        onPrevious,
        onPreviousModalProps,
        onKnockOut,
        wizardStepToFieldMapping,
        location,
        history,
        match,
        basePath = match.url,
        skipAdditionalInfo,
        isPageJumpEnabled: isPageJumpEnabledForPolicyChange,
        viewOnly,
        isRenewal,
        isPolicyView,
        isPageValid: onIsPageValid,
        isAllPagesSubmittedFlow: isAllPagesSubmittedFlowWizard,
        furthestVisiblePageIndexOnStart: initialFurthestVisiblePageIndexOnStart
    } = props;
    const { authUserData } = useAuthentication();
    const [
        furthestVisiblePageIndexOnStart,
        updateFurthestVisitedIndexOnStart
    ] = useState(initialFurthestVisiblePageIndexOnStart);

    const [jumpToPageIndex, setJumpToPageIndex] = useState(undefined);
    const { enableAllTabsFlowTransactions } = config;

    const [
        isAllPagesSubmittedFlow,
        updateIsAllPageSubmittedFlow
    ] = useState(isAllPagesSubmittedFlowWizard);

    const {
        data, updateData, snapshot, updateSnapshot, cloneData
    } = useWizardData(initialData);

    const transactionType = _.get(data, 'value.baseData.jobType');
    const [isJSorAPIErrorOccured, setIsJSorAPIErrorOccured] = useState(false);
    const isMounted = useRef(false);
    const locationPath = _.get(location, 'pathname');
    const referencePath = useRef(undefined);

    const allPageFlowEnabled = isAllPagesSubmittedFlow
        && enableAllTabsFlowTransactions.includes(transactionType);

    const {
        steps,
        currentStepIndex,
        currentStep,
        goNext,
        goPrevious,
        jumpTo,
        markStepSubmitted,
        changeNextSteps,
        removeCurrentPageFromSteps,
        isSkipping,
        stopSkipping,
        clearVisitedStepsAfterCurrent,
        disableAllSteps,
        markFurthestStep,
        furthestVisitedStepID
    } = useWizardSteps(
        initialSteps,
        skipCompletedSteps,
        allPageFlowEnabled,
        furthestVisiblePageIndexOnStart
    );

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    const submittedStepIds = _.filter(steps, 'submitted').map((step) => step.id);

    // adding currentpage ID to submitted steps,
    // so that the error will show on current page, even though user has not clicked next
    if (!submittedStepIds.includes(currentStep.id)) {
        submittedStepIds.push(currentStep.id);
    }

    const {
        acknowledgeError,
        reportErrors,
        stepsWithErrors,
        hasNewErrors,
        underwritingIssues,
        knockOutErrors,
        updateWizardErrors
    } = useWizardErrors(wizardStepToFieldMapping, submittedStepIds, furthestVisitedStepID);

    const [toggle, flip] = useState(false);
    // purpose of this state is to set the value of defaultActiveTab on Risk Analysis page
    // based upon the last visited page' value.
    // For now, we are using it in EA LOB(submission flow)
    const [lastVisitedRiskAnalysisTab, setLastVisitedRiskAnalysisTab] = useState('');

    // state data for issues and errors
    const [isUwIssuesInitialized, updateIsUwIssuesInitialized] = useState(false);

    // IAP-6030, Guidewire Fix for promptService deprecation
    const unblockNavigation = useRef();
    const [lastNavigationCancel, setLastNavigationCancel] = useState(new Date()); 
    const [applicationExceptions, updateApplicationExceptions] = useState(undefined);
    // Can be passed into the wizard to skip the additional info pop up
    // Current usage is only for EH when prefill doesn't return any/complete data
    const [
        shouldSkipAdditionalInfo,
        setShouldSkipAdditionalInfo
    ] = useState(skipAdditionalInfo);

    const [
        isPropertyPrefillOrderedMessage,
        setIsPropertyPrefillOrderedMessage
    ] = useState(false);

    const [
        orderReportsAfterAdditionalInfo,
        setOrderReportsAfterAdditionalInfo
    ] = useState(false);

    // used to identify if user is transitioning through cheveron tab
    const [
        isLoadingThroughPageCheveron,
        setIsLoadingThroughPageCheveron
    ] = useState(false);
    // stores reference to onNext function for current page
    const onPageNextFn = useRef(undefined);

    /**
     * currently being used in Policy change for dynamically landing on particular page
     * based on Change reason and change source
     */
    const [
        isPageJumpEnabled,
        updateIsPageJumpEnabled
    ] = useState(isPageJumpEnabledForPolicyChange);

    const [isPageValid, setIsPageValid] = useState(onIsPageValid);

    useEffect(() => {
        if (!isUwIssuesInitialized) {
            // errorsAndWarnings is not set because updateWizardData
            //  is NOT called right after retrieving data from the API
            if (underwritingIssues.length === 0) {
                const errorsPath = ViewModelUtil.isViewModelNode(data)
                    ? 'errorsAndWarnings.value'
                    : 'errorsAndWarnings';
                const errorsAndWarnings = _.get(data, errorsPath);

                reportErrors(errorsAndWarnings);
            }

            updateIsUwIssuesInitialized(true);
        }
    }, [data, isUwIssuesInitialized, reportErrors, underwritingIssues.length]);

    const errorsForStep = stepsWithErrors[currentStep.id] || [];

    /**
     * If covterm exception is present remove it from exception list
     * and create validation issue for covterm exception
     * @param {Object} exceptions list of exceptions
     * @return {Object} returns exceptions and validation issue 
     */
    const convertCoverageExceptionToValidationIssue = useCallback((exceptions) => {
        const coverageExceptionIndex = exceptions?.findIndex(
            (exception) => coverageExceptionEntityNames
                .some((coverageExceptionSubstring) => {
                    return _.get(exception, 'errorMessage', '').includes(coverageExceptionSubstring)
                }
                ));
        let coverageTermIssue;

        if (coverageExceptionIndex > -1) {
            const coverageException = exceptions[coverageExceptionIndex];

            exceptions.splice(coverageExceptionIndex, 1);
            coverageTermIssue = {
                type: 'error',
                reason: _.get(coverageException, 'errorMessage', ''),
                relatedEntity: {
                    dtoFieldName: 'Coverage',
                    dtoName: 'amfam.edge.capabilities.policyjob.common.dto.CoverageDTO'
                }

            }
        }

        return { exceptions, coverageTermIssue };
    }, []);

    const updateWizardData = useCallback(
        (newData) => {
            const exceptionsPath = ViewModelUtil.isViewModelNode(newData)
                ? 'baseData.exceptions_Ext.value'
                : 'baseData.exceptions_Ext';
            const exceptions = _.get(newData, exceptionsPath, []);

            const altExceptionsPath = ViewModelUtil.isViewModelNode(newData)
                ? 'exceptions_Ext.value'
                : 'exceptions_Ext';
            const altExceptions = _.get(newData, altExceptionsPath, []);

            const {
                exceptions: filteredExceptions,
                coverageTermIssue
            } = convertCoverageExceptionToValidationIssue([...exceptions, ...altExceptions]);

            if (filteredExceptions.length > 0) {
                updateApplicationExceptions(filteredExceptions);
            } else {
                updateApplicationExceptions(undefined);
            }



            let errorsPath = ViewModelUtil.isViewModelNode(newData)
                ? 'errorsAndWarnings.value'
                : 'errorsAndWarnings';
            let errorsAndWarnings = _.get(newData, errorsPath);
            // for policyChange and Renewal in backend we are mapping errors to
            //   ErrorsAndWarnings_Ext and using annotation to use errorsAndWarnings so
            //   need below changes

            if (!errorsAndWarnings) {
                errorsPath = 'value.errorsAndWarnings';
                errorsAndWarnings = _.get(newData, errorsPath);

                if (!errorsAndWarnings) {
                    // renewal has errorsAndWarnings_Ext
                    errorsPath = 'value.errorsAndWarnings_Ext';
                    errorsAndWarnings = _.get(newData, errorsPath);
                }
            }

            let fieldErrorsPath = ViewModelUtil.isViewModelNode(newData)
                ? 'errorsAndWarnings.validationIssues.fieldIssues.value'
                : 'errorsAndWarnings.validationIssues.fieldIssues';
            let fieldErrorsAndWarnings = _.get(newData, fieldErrorsPath);

            // The paths in the backend are different than what appear on the frontend, that
            //   is why the below condition exists, as it creates the right path for the backend to
            //   recognize the error warning. This is also due to the insertion of _Ext
            //   as a valid path
            if (!fieldErrorsAndWarnings) {
                fieldErrorsPath = 'errorsAndWarnings_Ext.validationIssues.fieldIssues.value';
                fieldErrorsAndWarnings = _.get(newData, fieldErrorsPath);
            }

            if (!viewOnly) {
                // in view only flow we want to make each step as
                //    visited so that user can navigate to any
                //    page by clicking on tabs on chevron
                clearVisitedStepsAfterCurrent();
            }

            flip(!toggle);

            /** *
             * IAP-1694 
             * As backend does not send validation issue for covTerm not present exceptions
             * and backend will not default covterms for selected coverages also
             * In front end we will convert exception to validation issue 
             * and land user on coverage page.
             */
            if (coverageTermIssue) {
                if (_.isEmpty(_.get(errorsAndWarnings, 'validationIssues.fieldIssues', []))) {
                    if (!errorsAndWarnings) {
                        errorsAndWarnings = {

                        };
                    }

                    _.set(errorsAndWarnings, 'validationIssues.fieldIssues', [coverageTermIssue])
                } else {
                    _.set(
                        errorsAndWarnings,
                        'validationIssues.fieldIssues',
                        [..._.get(errorsAndWarnings, 'validationIssues.fieldIssues', []), coverageTermIssue]
                    );
                }

                _.set(newData, errorsPath, errorsAndWarnings)
            }

            updateData(newData);

            return reportErrors(errorsAndWarnings);
        },
        [convertCoverageExceptionToValidationIssue, toggle, updateData, reportErrors, viewOnly, clearVisitedStepsAfterCurrent]
    );

    const updateWizardSnapshot = useCallback(
        (newData) => {
            updateSnapshot(cloneData(newData));

            return updateWizardData(newData);
        },
        [cloneData, updateSnapshot, updateWizardData]
    );

    const finishCallback = useCallback(
        (params) => onFinish({
            steps,
            currentStepIndex,
            wizardData: data,
            params
        }),
        [steps, currentStepIndex, data, onFinish]
    );

    const onCustom1CallBack = useCallback(
        (params) => onCustom1({
            wizardData: data,
            updateWizardData,
            params
        }),
        [onCustom1, data, updateWizardData]
    );

    const previousCallback = useCallback(
        (param) => {
            return Promise.resolve(
                onPrevious({
                    wizardSnapshot: snapshot,
                    wizardData: data,
                    onPreviousModalProps,
                    param,
                    cloneData,
                    updateWizardData,
                    viewOnly,
                    isPageValid
                })
            )
                .then(checkContinue)
                .then(goPrevious)
                .catch((error) => {
                    if (_.get(error, 'message') === 'false') {
                        // do nothing as we don't want to proceed
                        return;
                    }

                    throw error;
                });
        },
        [
            onPrevious,
            snapshot,
            data,
            onPreviousModalProps,
            cloneData,
            updateWizardData,
            viewOnly,
            isPageValid,
            goPrevious
        ]
    );

    const wizardUpdateOperation = useMemo(() => {
        const operation = updateSnapshot ? updateWizardSnapshot : updateWizardData;

        return (newWizardData) => operation(newWizardData);
    }, [updateSnapshot, updateWizardData, updateWizardSnapshot]);

    /**
     * Jumps to given page index if there are no errors or exceptions
     */
    const jumpToIfNoErrors = useCallback((index) => {
        const errorsOnly = errorsForStep.filter((error) => {
            return error.level === ErrorLevel.LEVEL_ERROR;
        });

        if (!hasNewErrors && !applicationExceptions && _.isEmpty(errorsOnly)) {
            jumpTo(index, true);
        }
    }, [applicationExceptions, errorsForStep, hasNewErrors, jumpTo]);


    useEffect(() => {
        // page index can be 0 : so comparing undefined
        if (jumpToPageIndex !== undefined) {
            setJumpToPageIndex(undefined);
            jumpToIfNoErrors(jumpToPageIndex);
        }
    }, [jumpToIfNoErrors, jumpToPageIndex]);

    const callPageOnNext = useCallback((wizardData, index) => {
        setIsLoadingThroughPageCheveron(true);

        return Promise.resolve(onPageNextFn.current(wizardData))
            .then(checkContinue)
            .then(wizardUpdateOperation)
            .then(() => setJumpToPageIndex(index))
            .catch((error) => {
                if (_.get(error, 'message') === 'false') {
                    // do nothing as we don't want to proceed
                    return;
                }

                throw error;
            }).finally(() => {
                 // Update the component's state only if it is currently mounted.
                 if (isMounted.current) {
                    setIsLoadingThroughPageCheveron(false);
                 }
            });
    }, [wizardUpdateOperation]);

    const onPageJump = useCallback(
        ({
            wizardData, wizardSnapshot, index
        }) => {
            /**
             * E1PAP1-3654 :
             * perform onNext functionality if user clicks on page chevron.
             * isAllPagesSubmittedFlow indicates if this transaction flow allows navigation
             * to any page tab by clicking on chevron
             */
            if (enableAllTabsFlowTransactions.includes(transactionType)
                && isAllPagesSubmittedFlow
                && currentStepIndex < furthestVisiblePageIndexOnStart
                && onPageNextFn.current) {
                return callPageOnNext(wizardData, index);
            }

            const pageTransitionPromise = Promise.resolve(
                checkHasChangedOrNotDuringTransition(wizardData, wizardSnapshot,
                    cloneData, updateWizardData, viewOnly)
            );

            setIsLoadingThroughPageCheveron(true);
            pageTransitionPromise
                .then((result) => {
                    if (result !== false) {
                        jumpTo(index);

                        // in view only flow we want to make each step
                        //    as visited so that user can navigate to
                        //     any page by clicking on tabs on chevron
                        if (viewOnly) {
                            markFurthestStep(steps.length - 1);
                        }
                    }
                })
                .catch((error) => {
                    if (_.get(error, 'message') === 'false') {
                        // do nothing as we don't want to proceed
                        return;
                    }

                    throw error;
                }).finally(() => {
                    setIsLoadingThroughPageCheveron(false);
                });
        },
        [
            isAllPagesSubmittedFlow, callPageOnNext, cloneData, currentStepIndex,
            jumpTo, markFurthestStep, steps.length, updateWizardData, viewOnly,
            furthestVisiblePageIndexOnStart, enableAllTabsFlowTransactions, transactionType
        ]
    );

    // IAP-6030, Guidewire Fix for promptService deprecation
    const cancelCallback = useCallback(
        async param => {
            // the current wizard is very permissive and allows the
            // `onCancel` to be anything. This means that the wizard does
            // not get to know if the callback was successful or failed.
            if (typeof onCancel === 'function') {
                // Given the current implementation of all the `onCancel` functions
                // in the project, we have to be optimistic and assume they will do
                // the "right thing", meaning they will decide wether or not to
                // navigate away from the page. This also means we have no control
                // over accidental navigations that should be prevented
                if (unblockNavigation.current){
                    unblockNavigation.current();
                }
                    
                const onCancelResult = await onCancel({
                    steps,
                    currentStepIndex,
                    wizardSnapshot: snapshot,
                    wizardData: data,
                    param,
                });

                if (onCancelResult === false || onCancelResult === undefined) {
                    setLastNavigationCancel(new Date());
                }
                
                if(_.isBoolean(onCancelResult)) {
                    return onCancelResult
                }
                
                if (onCancelResult === null) {
                    // we do accept null and assume user developer has handled
                    // the transition (current behavior for all the `onCancel`
                    // passed as props)
                    return true;
                }

                return onCancelResult;
            }

            // we can consider handling specific objects for which the behavior is more restrictive
            return undefined;

        },
        [onCancel, steps, currentStepIndex, snapshot, data, unblockNavigation]
    );

    const e1pGoNext = useCallback(
        () => {
            if (
                _.isEmpty(applicationExceptions)
                && _.isEmpty(data.baseData?.exceptions_Ext?.value)
            ) {
                return goNext();
            }

            return false;
             
        }, [applicationExceptions, data.baseData?.exceptions_Ext, goNext]
    );

    useEffect(() => {
        if (hasNewErrors) {
            const errorStepIds = Object.keys(stepsWithErrors);
            const stepIndex = _.findIndex(steps, (step) => errorStepIds.includes(step.id));
            const isErrorPresent = stepsWithErrors[steps[stepIndex].id]?.find((error) => {
                return error.level === ErrorLevel.LEVEL_ERROR;
            });

            // We will jump to page only if its error, for warnings we will keep showing warnings on upcoming pages
            if (isErrorPresent) {
                jumpTo(stepIndex, true);
            }
        }
        // don't add any other dependencies. It will cause too many renders.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasNewErrors]);

    // IAP-6030, Guidewire Fix for promptService deprecation
    useEffect(() => {
        const unblock = history.block((...navigationInfo) => {
            const [ destLocation ] = navigationInfo;
            const { search, state, pathname: desiredDestinationPath, isRouteChangedDuringFlow } = destLocation;
            const isWizardPath = desiredDestinationPath.startsWith(basePath);

            const continueNavigation = () => {
                unblock();
                history.push(desiredDestinationPath);
            }

            // if triggered by the wizard, we ignore it
            // isRouteChangedDuringFlow needed for quick to full quote conversion
            if (isWizardPath && (_.isEmpty(search) || isRouteChangedDuringFlow)) {
                continueNavigation();

                return true;
            }

            // we dont prompt user for confirmation, if the flow is view only, 
            // or if the job is bound and user is getting redirected to summary page
            // data is our wizardDataVM
            if (viewOnly ||
                (_.get(data, 'value.baseData.periodStatus') === 'Bound' && !isWizardPath)
            ) {
                continueNavigation();

                return true;
            }

            cancelCallback(destLocation).then((result) => {
               
                if(result === false || result === undefined) {
                    // user developer does not want us to allow the transition transition
                    // therefore we do not invoke a new history push
                    return;
                }

                if(result === true) {
                    // user developer wants us to handle the navigation
                    unblock();
                    history.push({pathname: desiredDestinationPath, search, state});
                }

            })

            // always block the transiton an defer to the promptMessageHandler
            // when to continue the navigation
            return false;
        });
        
        unblockNavigation.current = unblock;

        return unblock;
        // the lastNavigationCancel dependency is used to store the time the 
        // cancelCallback was last called and is essential for triggering the useEffect 
    }, [history, cancelCallback, basePath, lastNavigationCancel, data, viewOnly])

    // For google analytics
    const { operatingCompanyConfig } = config;
    const { opCo } = useContext(AmfamOktaTokenContext);
    let sourceOfEntry = _.get(
        data,
        'baseData.quoteSource_Ext.sourceType.value.code',
        'directentry'
    );
    const connectConfigObject = operatingCompanyConfig[opCo].experiences;
    const partnerID = _.get(data, 'baseData.partnerCode_Ext.value');
    let experienceID = data?.baseData?.experienceID_Ext?.value || connectConfigObject[partnerID];
    
    sourceOfEntry = sourceOfEntry || 'VALUE_NOT_SET_YET';
    experienceID = experienceID || 'VALUE_NOT_SET_YET';

    const roles = authUserData.roles_Ext || 'VALUE_NOT_SET_YET';

    useDataLayer(sourceOfEntry, experienceID, roles);

    if (!_.isEmpty(knockOutErrors)) {
        onKnockOut({
            steps,
            knockOutErrors,
            wizardData: data
        });
    }

    // ErrorBoundary will catch API error and js error when system throws service exception or error. 
    // We will set usestate variable(setIsJSorAPIErrorOccured) if ErrorBoundary catches the error
    // and display the errror conditionally.
    const handleError = useCallback(
        (error) => {
            referencePath.current = locationPath

            if (!isJSorAPIErrorOccured) {
                setIsJSorAPIErrorOccured(true);
                loggerError(`E1P-Wizard-Error: ${error}
                Pathname: ${locationPath}`);
            }
        },[isJSorAPIErrorOccured, locationPath]
    );
	
    // It will reset the error once API issue resolved.
    useEffect(
        () => {
            if (isJSorAPIErrorOccured && locationPath !== referencePath.current) {
                setIsJSorAPIErrorOccured(false);
            }
        }, [isJSorAPIErrorOccured, locationPath]
    );

    /*
     * current step has authority over the URL.
     *
     * If current step is not matching the URL,
     * we adjust by sending a redirect to the step URL
     */
    const expectedPath = `${basePath}${currentStep.path}`;

    if (!location.pathname.startsWith(expectedPath)) {
        const nextLocation = {
            pathname: expectedPath,
            state: location.state
        };

        return <Redirect to={nextLocation} />;
    }

    const wizardProps = {
        // wizard details
        wizardTitle,
        // steps props
        steps,
        changeNextSteps,
        currentStepIndex,
        removeCurrentPageFromSteps,
        currentStep,
        // transitions props
        isSkipping,
        stopSkipping,
        e1pGoNext,
        goPrevious: previousCallback,
        markStepSubmitted,
        clearVisitedStepsAfterCurrent,
        jumpTo,
        disableAllSteps,
        finish: finishCallback,
        onCustom1: onCustom1CallBack,
        cancel: cancelCallback,
        onPageJump,
        shouldSkipAdditionalInfo,
        setShouldSkipAdditionalInfo,
        isPropertyPrefillOrderedMessage,
        setIsPropertyPrefillOrderedMessage,
        orderReportsAfterAdditionalInfo,
        setOrderReportsAfterAdditionalInfo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        // wizard data props
        wizardData: data,
        updateWizardData,
        updateWizardSnapshot,
        wizardSnapshot: snapshot,
        // errors props
        hasNewErrors,
        errorsForStep,
        stepsWithErrors,
        underwritingIssues,
        acknowledgeError,
        reportErrors,
        applicationExceptions,
        // Policy View
        //   Can be used to alter transitioning and etc.
        //   Currently used to alter onPrevious
        viewOnly,
        isRenewal,
        isPolicyView,
        markFurthestStep,
        setIsPageValid,
        lastVisitedRiskAnalysisTab,
        setLastVisitedRiskAnalysisTab,
        onPageNextFn,
        furthestVisiblePageIndexOnStart,
        isAllPagesSubmittedFlow: allPageFlowEnabled,
        updateIsAllPageSubmittedFlow,
        updateFurthestVisitedIndexOnStart,
        updateWizardErrors,
        isLoadingThroughPageCheveron,
        isJSorAPIErrorOccured
    };

    // we pass the props to both the component
    // and the context to allow nested components to reuse them
    // without prop-drilling
    return (
        <ErrorBoundary onError={handleError}>
            <WizardContext.Provider value={wizardProps}>
                <WizardRoutes basePath={basePath} {...wizardProps} />
            </WizardContext.Provider>
        </ErrorBoundary>
    );
}

Wizard.propTypes = {
    /** the title for this wizard */
    wizardTitle: PropTypes.oneOfType([
        PropTypes.shape({
            id: PropTypes.string
        }),
        PropTypes.string
    ]),
    /**
     * the steps that will compose this wizard
     * (once initialized, they cannot be changed from the props)
     */
    initialSteps: PropTypes.arrayOf(PropTypes.shape(wizardStepProps)).isRequired,
    /**
     * the data to pass to the wizard
     * (once initialized, it cannot be changed from props)
     */
    initialData: PropTypes.shape({}),
    /**
     * the React Router location
     */
    location: PropTypes.shape({
        pathname: PropTypes.string,
        state: PropTypes.shape({})
    }).isRequired,
    /**
     * the React Router history
     */
    history: PropTypes.shape({}).isRequired,
    /**
     * The React Router match
     */
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    /**
     * Steps to match errors to the specific wizard step (e.g. backend validation)
     */
    wizardStepToFieldMapping: PropTypes.shape({}),
    /**
     * whether the wizard should begin while skipping the completed steps
     * (once initialized, this cannot be changed from the props)
     */
    skipCompletedSteps: PropTypes.bool,
    /**
     * Callback that will be called when the wizard is finished.
     * This will be called with one parameter containing all the wizard data
     */
    onFinish: PropTypes.func,
    onCustom1: PropTypes.func,
    /**
     * Callback that will be called when the wizard is canceled.
     * This will be called with one parameter containing all the wizard data
     */
    onCancel: PropTypes.func,
    onPrevious: PropTypes.func,
    onPreviousModalProps: PropTypes.shape({
        title: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        message: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        status: PropTypes.string,
        icon: PropTypes.string,
        confirmButtonText: PropTypes.string,
        cancelButtonText: PropTypes.string
    }),
    /**
     * Callback that will be called when the wizard is interrupted because of
     * a knock-out error.
     * This will be called with one parameter containing all the wizard data
     */
    onKnockOut: PropTypes.func,
    /**
     * Where this wizard is based
     * If not passed the current path of the page will be used.
     * This is particularly useful when nesting one wizard inside another
     */
    basePath: PropTypes.string,
    /**
     * Determines if we should skip additional info page
     * Only used for HO3 submission right now
     */
    skipAdditionalInfo: PropTypes.bool,
    /**
     * used for dynamically landing on specific page, for policyChange transaction
     */
    isPageJumpEnabled: PropTypes.bool,

    viewOnly: PropTypes.bool,
    isRenewal: PropTypes.bool,

    isPolicyView: PropTypes.bool,
    /**
     * used to check current wizard page is valid or not,using this info
     * to show popup(The changes you have made on this page may be lost.)
     * on click of previous or page jump
     */
    isPageValid: PropTypes.bool,
    /**
     * indicates if this transaction flow allows navigation
     * to any page tab by clicking on chevron
     */
    isAllPagesSubmittedFlow: PropTypes.bool,
    /**
     * furthest clickable page index in wizard flow
     */
    furthestVisiblePageIndexOnStart: PropTypes.number
};

Wizard.defaultProps = {
    wizardTitle: null,
    initialData: null,
    skipCompletedSteps: false,
    onCancel: _.noop,
    onPrevious: handleOnPrevious,
    onPreviousModalProps: {
        title: commonMessages.wantToJump,
        message: commonMessages.wantToJumpMessage,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    },
    onFinish: _.noop,
    onCustom1: _.noop,
    wizardStepToFieldMapping: {},
    basePath: undefined,
    onKnockOut: _.noop,
    skipAdditionalInfo: false,
    isPageJumpEnabled: false,
    viewOnly: false,
    isRenewal: false,
    isPolicyView: false,
    isPageValid: undefined,
    isAllPagesSubmittedFlow: false,
    furthestVisiblePageIndexOnStart: undefined
};

export default withRouter(Wizard);
