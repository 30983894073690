import React, {
    useCallback,
    useState,
    useContext,
    useEffect
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

import { AccountService, PolicyDetailsService, AccountSearchUtil } from 'e1p-capability-gateway';
import { useDashboardStateContext } from 'e1p-capability-gateway-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import AccountSearchCriteriaFilterComponent from './AccountSearchCriteriaFilterComponent/AccountSearchCriteriaFilterComponent';
import AccountsTableComponent from './AccountsTableComponent/AccountsTableComponent';
import metadata from './AccountsDashboard.metadata.json5';
import './AccountsDashboard.messages';

const emptyResponse = { rows: [], numberOfRows: 0 };
const MAX_NUMBER_OF_RECORDS_FOR_REFINE_RESULT = 250;
const DEFAULT_PAGE_STATE = {
    selectedAccountView: 'recentlyViewedAccounts',
    dataTableKey: 'recentlyViewedAccounts',
    searchCriteriaObj : undefined,
    dataTableConfig: { sorted: [], pageSize: 25, page: 0}
}

/**
 * 
 * @param {*} selectedAccountView 
 * @returns {[]} array for sorted property for dataTable
 * 
 * This will be used whenever user selects different radio button on UI like
 * "All Accounst or Last 100 Viewed"
 */
function getDefaultSortForAccountView(selectedAccountView) {
    return selectedAccountView === 'recentlyViewedAccounts' 
        ? []
        : [ {
            id: 'dateCreated',
            desc: true
        }
        ];
}

function AccountsDashboard(props) {
    const {
        labelPosition,
        showOptional
    } = props;
    
    // fields to get and set, page states from context the 
    const { accountsDashboardState, setAccountsDashboardState} = useDashboardStateContext();
    const viewModelService = useContext(ViewModelServiceContext);
    const [accountSearchVM, setAccountSearchVM] = useState(undefined);
    const [isCallingApi, setIsCallingApi] = useState(false);
    const [exceptions, setExceptions] = useState([]);
    const intl = useContext(IntlContext);
    const [selectedAccountView, updateSelectedAccountView] = useState(DEFAULT_PAGE_STATE.selectedAccountView);
    const [dataTableKey, updateDataTableKey] = useState(DEFAULT_PAGE_STATE.dataTableKey);
    const [dataTableConfigState, setDataTableConfigState] = useState(DEFAULT_PAGE_STATE.dataTableConfig);
    const { authHeader, authUserData } = useAuthentication();
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    /**
     * this is used to control the async call from child component,
     * for example if user is landing on the dashboard for the first time, then accountsDashboardState will be undefined
     * and the child component will fecth the data based on default  values set above,
     * 
     * for any subsequent visit to the dashboard, accountsDashboardState will have last filter states, 
     * for this scenario we need to call backend based on previous state once the component is loaded sucessfully.
     * setting this to true in useEffect.
     *  */ 
    const [shouldFetchData, setShouldFetchData] = useState(_.isEmpty(accountsDashboardState));


    /**
     * This function will set the current filter state the context
     */
    const updateDashboardStateToContext = useCallback((dataTableConfig) => {
        const pageStateObj = {
            selectedAccountView,
            dataTableKey,
            searchCriteriaObj : _.get(accountSearchVM, 'value'),
            dataTableConfig
        }

        setAccountsDashboardState(pageStateObj);
    }, [accountSearchVM, dataTableKey, selectedAccountView, setAccountsDashboardState]);

    useEffect(() => {
        // set state variables based on previous filters
        if(!_.isEmpty(accountsDashboardState)) {
            updateSelectedAccountView(accountsDashboardState.selectedAccountView);
            updateDataTableKey(accountsDashboardState.dataTableKey);
            setDataTableConfigState(accountsDashboardState.dataTableConfig);

            if(!_.isUndefined(accountsDashboardState.searchCriteriaObj) && !_.isEmpty(accountsDashboardState.searchCriteriaObj)) {
                const searchCriteriaVM = AccountSearchUtil.createAccountSearchVM(viewModelService);

                setAccountSearchVM(_.set(searchCriteriaVM, 'value', accountsDashboardState.searchCriteriaObj));
            }

            setShouldFetchData(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAccountViewSelection = useCallback((value) => {
        updateSelectedAccountView(value);
        updateDataTableKey(value);
        // set dataTable config to default state, whenever radio selection is changed.
        setDataTableConfigState({...DEFAULT_PAGE_STATE.dataTableConfig, sorted: getDefaultSortForAccountView(value)});
        setExceptions(undefined);
    }, []);

    const constructDisplayNameFromAddress = (address) => {
        let displayAddress = '';

        if (address) {
            displayAddress = `${address?.addressLine1}, ${address?.city}, ${address?.state} ${address?.postalCode}`;
        }

        return displayAddress;
    };

    const processResponseData = useCallback((accountsData) => {
        const accountArray = accountsData.map((accountInfo) => {
            // Search result from backned api's are mapped to different DTO's.
            // hence OR condition for accountHolder and address fields.
            const account = {
                accountHolder:
                    accountInfo.accountHolder?.person?.displayName ||
                    accountInfo.accountHolder,
                dateCreated: intl.formatDate(
                    new Date(accountInfo.accountCreatedDate),
                    {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        timeZone: 'UTC'
                    }
                ),
                address:
                    accountInfo?.accountHolderAddress?.displayName ||
                    constructDisplayNameFromAddress(
                        accountInfo?.accountHolder?.person?.primaryAddress
                    ),
                accountNumber: accountInfo.accountNumber
            };

            return account;
        });

        return accountArray;
    }, [intl]);

    const fetchRecentlyViewedAccounts = useCallback((paginationParams) => {
        setIsCallingApi(true);
        setExceptions(undefined);

        return AccountService.getPaginatedRecentlyViewedAccounts(paginationParams, authHeader)
            .then((response) => {
                if (response.exceptions_Ext) {
                    setExceptions(response.exceptions_Ext);
                }

                if (response.accounts && response.accounts?.length > 0) {
                    const data = processResponseData(response.accounts);

                    return {
                        rows: data,
                        numberOfRows: response.maxNumberOfResults
                    };
                }

                return emptyResponse;
            }).finally(() => {
                setIsCallingApi(false);
            });
    }, [authHeader, processResponseData]);

    const fetchAllAccounts = useCallback((paginationParams) => {
        setIsCallingApi(true);
        setExceptions(undefined);

        // backend is not using 1st and 3rd parameter hence sending null.
        return AccountService.getPaginatedAccountsForCurrentUser(null, paginationParams, null, authHeader)
            .then((response) => {
                if (response.exceptions_Ext) {
                    setExceptions(response.exceptions_Ext);
                }

                if (response.accounts && response.accounts?.length > 0) {
                    const data = processResponseData(response.accounts);

                    return {
                        rows: data,
                        numberOfRows: response.maxNumberOfResults
                    };
                }

                return emptyResponse;
            }).finally(() => {
                setIsCallingApi(false);
            });
    }, [authHeader, processResponseData]);

    const fetchRefineAccounts = useCallback((paginationParams) => {
        const searchCriteriaObj = _.get(viewModelService.clone(accountSearchVM), 'value');

        if (!searchCriteriaObj) {
            return;
        }

        setIsCallingApi(true);
        setExceptions(undefined);

        // we dont need to send producercode to backend if AllLocations option is selected on the UI
        // All Location option is just for user to let him know that
        // backend will search the account, in his assigned locations only
        if (searchCriteriaObj.producerCode_Ext && searchCriteriaObj.producerCode_Ext === 'allLocations') {
            _.unset(searchCriteriaObj, 'producerCode_Ext')
        }

        if (searchCriteriaObj.createdInLastXDays_Ext && searchCriteriaObj.createdInLastXDays_Ext === -1) {
            // dont send createdInLastXDays_Ext to backend, if anytime option is selected, 
            // because backend will consider all the accounts,
            _.unset(searchCriteriaObj, 'createdInLastXDays_Ext')
        }

        const fetchAccounts = () =>AccountService.searchAccountsWithPagination(searchCriteriaObj, paginationParams, authHeader)
                .then((response) => {
                    const exceptionForMaxNoOfResults = response.exceptions_Ext && response.maxNumberOfResults && response.maxNumberOfResults > MAX_NUMBER_OF_RECORDS_FOR_REFINE_RESULT;

                    // IAP-3082, show exceptions other than Max number of record found
                    if (!exceptionForMaxNoOfResults && response.exceptions_Ext) {
                        setExceptions(response.exceptions_Ext);
                    }

                    if (response.accounts && response.accounts?.length > 0) {
                        const data = processResponseData(response.accounts);

                        return {
                            rows: data,
                            numberOfRows: response.maxNumberOfResults
                        };
                    }

                    return emptyResponse;
                }).finally(() => {
                    setIsCallingApi(false);
                })

        if(!isAgent) {
            // fetch producercode for UW Login
            const locationCode = _.get(searchCriteriaObj, 'producerCode_Ext');

            if (locationCode) {
                return PolicyDetailsService.getProducerDetails(
                    locationCode,
                    authHeader
                ).then((producerResponse) => {
                    _.set(searchCriteriaObj, 'producerCode_Ext', producerResponse.producerCode);

                    return fetchAccounts();
                }).catch(() => {
                    setIsCallingApi(false);

                    return emptyResponse;
                })
            }
        }

        return fetchAccounts();
    }, [accountSearchVM, authHeader, isAgent, processResponseData, viewModelService]);

    const onRefineAccountsData = useCallback((searchCriteriaVM) => {
        if (selectedAccountView === 'refineAccounts') {
            setAccountSearchVM(searchCriteriaVM);
            updateDataTableKey(`${selectedAccountView}${new Date().getTime()}`);
            // set dataTable config to default state, whenever search button is clicked.
            // every new search call should be with default state,
            // but if user changes sorting criteria then it will search with new sorting criteria.
            setDataTableConfigState({...DEFAULT_PAGE_STATE.dataTableConfig, sorted: getDefaultSortForAccountView(selectedAccountView)});
        }
    }, [selectedAccountView]);

    const loadData = useCallback(({ sorted, pageSize, page }) => {
        // set page filter states to context
        updateDashboardStateToContext({ sorted, pageSize, page });

        let orderBy = 'AccountHolder';

        // we need to send orderby based on which api we are going to call,
        // backend has configured different field name for sorting for different apis
        switch (_.get(sorted, '0.id')) {
            case 'dateCreated':
                orderBy = 'AccountCreatedDate';

                if (['allAccounts', 'refineAccounts'].includes(selectedAccountView)) {
                    orderBy = 'createtime';
                }

                break;
            case 'accountNameID':
                orderBy = 'accountholder';
                break;
            default:
                orderBy = undefined;
                break;
        }

        const startIndex = pageSize * page;
        const offsetEndMinusOne = startIndex + pageSize - 1;
        const paginationParams = {
            offsetStart: startIndex,
            offsetEnd: offsetEndMinusOne,
            orderBy,
            orderByDescending: _.get(sorted, '0.desc', true)
        };
        let result = emptyResponse;

        switch (selectedAccountView) {
            case 'allAccounts':
                result = fetchAllAccounts(paginationParams);
                break;
            case 'recentlyViewedAccounts':
                result = fetchRecentlyViewedAccounts(paginationParams);
                break;
            case 'refineAccounts':
                if (selectedAccountView === dataTableKey) {
                    // this will excute only once when user selects the option
                    result = emptyResponse;
                } else {
                    result = fetchRefineAccounts(paginationParams);
                }

                break;
            default:
                result = emptyResponse;
        }

        return result;
    }, [dataTableKey, fetchAllAccounts, fetchRecentlyViewedAccounts, fetchRefineAccounts, selectedAccountView, updateDashboardStateToContext]);

    const resolvers = {
        resolveComponentMap: {
            WizardSingleErrorComponent,
            AccountSearchCriteriaFilterComponent,
            AccountsTableComponent
        },
        resolveCallbackMap: {
            onValidate: () => { },
        }
    };

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            disabled: isCallingApi,
            autoComplete: false
        },
        accountsViewOptionsID: {
            value: selectedAccountView,
            onValueChange: handleAccountViewSelection
        },
        accountsTableContainer: {
            dataTableKey,
            // this will set the deafult config property in dataTable
            dataTableConfig: dataTableConfigState,
            onFetchData: loadData,
            visible: shouldFetchData
        },
        wizardSingleErrorComponent: {
            issuesList: exceptions
        },
        accountFiltersContainer: {
            visible: selectedAccountView === 'refineAccounts',
            isCallingApi,
            onRefineAccountsData,
            accountSearchCriteriaVM: accountSearchVM,
            isAgent
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

AccountsDashboard.propTypes = {
    labelPosition: PropTypes.string,
    showOptional: PropTypes.bool
};
AccountsDashboard.defaultProps = {
    labelPosition: 'top', // I want labels on top by default
    showOptional: false
};
export default AccountsDashboard;
