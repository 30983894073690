import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ImageComponent } from 'gw-components-platform-react';
import messages from './E1PProductImageComponent.messages';

const srcImages = {
    Homeowners_EH: 'lob-images/e1p-home.svg',
    PersonalAuto_EA: 'lob-images/e1p-auto.svg',
    PersonalUmbrella_EU: 'lob-images/e1p-umbrella.svg',
};

function E1PProductImageComponent(props) {


    const {
        productCode,
        title
    } = props;
    const translator = useTranslator();
    const productTitle = title || translator(messages[_.camelCase(productCode)]);

    return (
        <ImageComponent
            title={productTitle}
            src={srcImages[productCode]}
            showLoader={false}
            alt=""
        />
    );
}

E1PProductImageComponent.propTypes = {
    productCode: PropTypes.string.isRequired,
    title: PropTypes.string
};
E1PProductImageComponent.defaultProps = {
    title: undefined
};
export default E1PProductImageComponent;
