import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { get as _get, isNil as _isNil, includes as _includes } from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { useTranslator } from '@jutro/locale';
import { isRequired } from 'e1p-portals-required-validator-js';
import requiredMetadata from '../PaymentDetailsPage.requiredness';
import metadata from '../PaymentDetailsPage.metadata.json5';
import messages from '../PaymentDetailsPage.messages';

const LOB = 'homeowners_EH';
const INITIAL_PAYMENT_OPTION_PATH = 'baseData.value.conversionDTO.initialPaymentType';

function ViewPaymentDetailsPage(props) {
    const {
        wizardData: submissionVM
    } = props;
    const PAY_MONTHLY = 'af:payMthly3';
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const [requiredFields, updateRequiredFields] = useState([]);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();

    /**
     * Initialization code
     */
    useEffect(() => {
        const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');
        const initialRequiredFields = ['consentToRateAcknowledgement']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Filter the selected offering
    const selectedQuoteOffering = _get(submissionVM, 'quoteData.offeredQuotes.value').find(
        (quoteOffering) => quoteOffering.selected
    );

    const selectedOffering = _get(submissionVM, 'lobData.homeowners_EH.offerings.value').find((offering) => offering.branchCode === selectedQuoteOffering.branchCode);

    const ratePlans = _get(selectedOffering, 'ratePlans', []);

    // UseMemo to compute CTR and NCRB plan offering details if rate plan changes
    const { ctrPlanOffering, ncrbPlanOffering } = useMemo(
        () => {
            // CTR and NCRB premium details for consent to rate acknowledgement 
            const ctrOffering = ratePlans?.find((plan) => plan.ratePlanType === 'CTR');

            const ncrbOffering = ratePlans?.find((plan) => plan.ratePlanType === 'NCRB');

            return {
                ctrPlanOffering: ctrOffering,
                ncrbPlanOffering: ncrbOffering
            };
        },
        [ratePlans]
    );

    const availableValuesForPaymentOptions = useMemo(() => {
        const correctOffering = submissionVM.lobData.homeowners_EH.offerings.value[0];

        if (correctOffering.paymentPlans !== undefined) {
            return correctOffering.paymentPlans
                .filter((paymentPlans) => paymentPlans.name)
                .reduce(
                    (paymentPlanMap, paymentPlan) => ({
                        ...paymentPlanMap,
                        [paymentPlan.billingId]: paymentPlan
                    }),
                    {}
                );
        }

        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(submissionVM.lobData.homeowners_EH.offerings.value)]);

    // ctrPremium should be based of selected payment plan premium
    const ctrPremium = availableValuesForPaymentOptions[
        _get(submissionVM, 'bindData.selectedPaymentPlan.value')
    ]?.total?.amount;

    const getRiskAddress = () => {
        const {addressLine1, addressLine2, city, state, postalCode} = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.ehlocation.value');

        return `${addressLine1}, ${addressLine2 ? `${addressLine2 }, ` : ''}${city}, ${state} ${postalCode}`;
    };

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            readOnly: true
        },
        paymentDetailsPageLoadingIndicator: {
            loaded: true
        },
        iframePaymentus: {
            visible: false
        },
        iFrameLoader: {
            loaded: true
        },
        totalPremiumID: {
            value: !_isNil(
                availableValuesForPaymentOptions[
                    _get(submissionVM, 'bindData.selectedPaymentPlan.value')
                ]
            )
                ? availableValuesForPaymentOptions[
                    _get(submissionVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined
        },
        totalPremiumWithFullPayID: {
            value: !_isNil(availableValuesForPaymentOptions[PAY_MONTHLY])
                ? availableValuesForPaymentOptions[PAY_MONTHLY].total
                : undefined
        },
        producerCodeDrpDwn: {
            // eslint-disable-next-line no-prototype-builtins
            visible: submissionVM.value.hasOwnProperty('licensedProducers'),
            value: _get(submissionVM, 'bindData.value.licensedProducerID_Ext', '')
        },
        producerCode: {
            // eslint-disable-next-line no-prototype-builtins
            visible: submissionVM.value.hasOwnProperty('licensedProducers')
        },
        monthlyPaymentScheduleComponent: {
            visible: false
        },
        mailingAndBillingAddressComponent: {
            transactionVM: submissionVM,
            updateWizardData: () => {},
            viewOnlyMode: true,
            lob: LOB
        },
        iframePDF: {
            visible: false
        },
        signButton: {
            visible: false
        },
        signPolicyLoader: {
            loaded: true
        },
        paymentOptionsID: {
            submissionVM,
            authHeader: {},
            updateWizardData: () => {},
            LOB,
            viewOnly: true
        },
        // IAP-371 show TPI component in only read only Mode
        e1ptpiDisplayTableContainer: {
            transactionVM: submissionVM,
            authHeader,
            updateWizardData: () => {},
            setIsSavingTPI: () => { },
            showErrors: false,
            onValidate: () => { },
            disregardFieldValidationParentPage: () => {},
            viewOnlyMode: true,
            showTitle: false
        },
        addThirdPartyInterestButton: {
            visible: false
        },
        paymentusComponent: {
            submissionVM,
            updateWizardData: () => {},
            visible: false,
            setKeepShowingIframeAfterPayment: () => { },
            disableAllSteps: () => {},
        },
        quoteProposalLinkContainer: {
            disabled: true
        },
        signatureComponent: {
            submissionVM,
            updateWizardData: () => {},
            authHeader,
            LOB,
            onSignatureSuccess: () => {},
            producerCodeOptions,
            setProducerCodeOptions,
            viewOnlyMode: true
        },
        insuredContactInfo: {
            viewOnlyMode: true,
            showPaperless: true
        },
        phoneNumberValidationDiv: {
            visible: false
        },
        consentToRateMessage: {
            // if CTR plan exists and if CTR is selected then Consent to rate acknowledgement should be displayed with NCRB and CTR premiums
            visible: _includes(requiredFields, 'consentToRateAcknowledgement') && ctrPlanOffering && ctrPlanOffering.isSelected,
            content: translator(messages.consentToRateAcknowledgementMessage,
                {
                    NCRBPremium: ncrbPlanOffering?.totalPremium,
                    CTRPremium: ctrPremium
                }),
        },
        paymentOptions: {
            value: _get(submissionVM, INITIAL_PAYMENT_OPTION_PATH),
            visible: !!_get(submissionVM, INITIAL_PAYMENT_OPTION_PATH)
        },
        AutoPayOptionToggle: {
            visible: _get(submissionVM, INITIAL_PAYMENT_OPTION_PATH) !== 'PayLater'
        },
        riskAddress: {
            value: getRiskAddress()
        },
        quoteProposalFailureErrorDiv: {
            visible: false
        },
        paperlessEmailChangedMessageDiv: {
            visible: false
        },
        userNotAuthorisedErrorDiv: {
            visible: false
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            signPolicy: () => { }
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps),
        [submissionVM, overrideProps]
    );

    return (
        <WizardPage
            showNext={false}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

ViewPaymentDetailsPage.propTypes = wizardProps;
export default ViewPaymentDetailsPage;
