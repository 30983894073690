import React, {
    useCallback, useContext, useEffect, useMemo, useState, useRef
} from 'react';
import {
    get as _get, set as _set, some as _some, isUndefined as _isUndefined, findIndex as _findIndex
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps, WizardPageTemplateWithTitle } from 'e1p-portals-wizard-react';
import { TransactionViewFlowUtil } from 'e1p-portals-util-js';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import {
    useSniUtil,
    useNewBusinessUtil,
    useViewPolicyUtil
} from 'e1p-capability-hooks';
import ViewEUVehicleOperatorPage from "e1p-capability-quoteandbind-eu-react/pages/VehicleOperator/viewOnly/ViewEUVehicleOperatorPage";
import metadata from '../PolicyDetailsPage.metadata.json5';
import verifiedViewOnlyWizardConfig from '../../../config/viewOnly/verified/eu-wizard-config.json5';
import unVerifiedViewOnlyWizardConfig from '../../../config/viewOnly/unverified/eu-wizard-config.json5';

const LOB = 'personalUmbrella_EU';

function ViewPolicyDetailsPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        authUserData,
        steps,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        markFurthestStep,
        currentStepIndex,
        changeNextSteps,
        currentStep,
        isSkipping
    } = props;
    const translator = useTranslator();
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();

    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');

    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const underlyingPoliciesVM = useMemo(
        () => _get(submissionVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []),
        [submissionVM]
    );

    const {
        getLocationAndProducerCodes
    } = useNewBusinessUtil(
        submissionVM,
        () => {},
        viewModelService,
        LOB,
        false,
        authHeader,
        setLocationAndProducerCodes
    );

    const {
        getLandingPageIndexForViewOnlyPolicy,
        getProducerDetails
    } = useViewPolicyUtil();

    const { createSecondaryNamedInsuredVM, removeSni } = useSniUtil(
        submissionVM,
        () => {},
        viewModelService,
        LOB,
        () => {}
    );

    /**
         * Helper for getting producer code on initial render and
         *   then when state changes. Will only run when a state changes
         *   and on initial render so no need to make a callback as the param
         *   will always be a new value.
         * @param {String} location State code
         */
    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(submissionVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(submissionVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    /**
         * Initialize the producer code for an existing account
         *  as policy state will already exist
         */

    useEffect(() => {
        // Policy state will not exist for new account so nothing will happen
        // If New quote for an account we will use accountholders state to set
        // policy state and that state here will set the initial producer code
        if (policyState && isAgent) {
            // only look up for agents
            getAndSetLocationCodes(policyState).then(() => {
            });
        }
        // Only run on initial render. Helper will be called again from
        //   the state fields onChange handler
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(submissionVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(submissionVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);
        }
    }, [locationAndProducerCodes, submissionVM]);

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            setLocationAndProducerCodes(
                [
                    {
                        producerCodes: { code: producerCode, name: producerCode },
                        locationCodes: { code: externalID, name: externalID }
                    }
                ]
            );
            _set(submissionVM, 'baseData.producerCode_Ext', producerCode);
            _set(submissionVM, 'baseData.externalID_Ext.value', externalID);
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [submissionVM],
    );

    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        overrideProps.secondaryNamedInsured = {
            hideSSN: true
        };
    }, []);

    useEffect(() => {
        if (policyState && isAgent) {
            // only look up for agents
            getLocationAndProducerCodes(policyState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyState]);

    useEffect(() => {
        if (submissionVM.baseData.quoteSource_Ext.sourceType === undefined) {
            const model = {
                sourceType: 'directentry',
                sourceName: 'msaagentsonly'
            };

            submissionVM.baseData.quoteSource_Ext.value = model;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const vehicleOperatorPage = useRef({
        id: 'QNBViewEUVehicleOperatorPage',
        path: '/view-vehicle-operator',
        component: ViewEUVehicleOperatorPage,
        title: {
            id: 'quoteandbind.eu.wizard.step.Vehicle Operator',
            defaultMessage: 'Vehicle Operator',
        },
        stepProps: {
            template: WizardPageTemplateWithTitle
        },
    }).current;

    const handleVehicleOperatorPage = useCallback(async () => {
        let remainingSteps = verifiedViewOnlyWizardConfig.steps.slice(currentStepIndex + 1, verifiedViewOnlyWizardConfig.steps.length);

        if (_get(submissionVM, 'value.quoteType') === 'Quick') {
            remainingSteps = unVerifiedViewOnlyWizardConfig.steps.slice(currentStepIndex + 1, unVerifiedViewOnlyWizardConfig.steps.length);
        }

        const indexToUnderlyingPolicyPage = _findIndex(
            remainingSteps, (step) => step.path === '/view-underlying-policy'
        );

        const differentAutoExposures = ['personalauto', 'personalaononowner', 'otherspecialityvehicle', 'cycle'];

        const vehicleUnderlyingPolicyExists = _some(underlyingPoliciesVM.value,
            (policy) => differentAutoExposures.includes(policy.policyType));

        if (vehicleUnderlyingPolicyExists) {
            remainingSteps.splice(indexToUnderlyingPolicyPage + 1, 0, vehicleOperatorPage);
        }

        changeNextSteps(remainingSteps);

        return remainingSteps;
    }, [
        changeNextSteps, currentStepIndex,
        submissionVM, underlyingPoliciesVM.value, vehicleOperatorPage
    ]);

    useEffect(() => {
        if (_isUndefined(underlyingPoliciesVM.value)) {
            _set(underlyingPoliciesVM, 'value', []);
        }

        handleVehicleOperatorPage().then((remainingSteps) => {
            // add current step back because we need all steps to get correct index
            TransactionViewFlowUtil.jumpToPageAndMarkFurthestVisitedStep(
                remainingSteps, currentStep,
                isPageJumpEnabled, updateIsPageJumpEnabled, jumpTo,
                getLandingPageIndexForViewOnlyPolicy, markFurthestStep,
                LOB, _get(submissionVM, 'baseData.value.jobType')
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps.length]);

    const onNext = useCallback(
        () => submissionVM,
        [submissionVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate: () => {},
            onRemoveSni: removeSni,
            onMarkAccountSearchCompleted: () => {},
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    useEffect(() => {
        if (submissionVM.baseData.externalID_Ext?.value && !isSkipping) {
            getProducerDetails(
                submissionVM.baseData.externalID_Ext.value, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
                setAgencyName(details.agencyName);
                setBookTransferIndicator(details.bookTransferIndicator);
                setServiceCenterIndicator(details.serviceCenterIndicatorValue);
            }).catch((error) => {
                /**
                 * E1PAP1PC-15099 :
                 * This is rare scenario; will never happen in production
                 * When we bound policy using dummy producer code
                 * and when other user tries to view this policy we get exception from service
                 */
                if (submissionVM.baseData.value.exceptions_Ext) {
                    submissionVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        submissionVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(submissionVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM.baseData.externalID_Ext.value]);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        policyDetailsPageLoadingIndicator: {
            loaded: true
        },
        effectiveDateValidationMessage: {
            visible: false
        },
        coverageStartDate: {
            dateDTO: submissionVM.baseData.periodStartDate,
            defaultToToday: true,
            showErrors: false
        },
        coverageEndDate: {
            dateDTO: submissionVM.baseData.periodEndDate
        },
        personalInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        secNamedInsuredContainer: {
            visible: !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        dateOfBirth: {
            dateDTO: submissionVM.baseData?.accountHolder?.dateOfBirth
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        addSni: {
            visible: false
        },
        secondaryNamedInsured: {
            visible: !!_get(submissionVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            viewOnlyMode: true,
            hideSSN: true
        },
        mailingAndBillingAddressComponent: {
            transactionVM: submissionVM,
            updateWizardData: () => {},
            onValidate: () => {},
            viewOnlyMode: true,
            lob: LOB
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        removeSni: {
            visible: false
        },
        e1pAcknowledgementComponent: {
            acknowledgements: _get(submissionVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: _get(submissionVM, 'baseData.policyAddress.state.value.code'),
                name: translator({ id: _get(submissionVM, 'baseData.policyAddress.state.value.name') })
            },
            viewOnly: true,
            lob: LOB
        },
        accountSearchComponent: {
            visible: false
        },
        insuredResidenceAddress: {
            addressVM: _get(submissionVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate:  () => {},
            onAddressChange: () => { },
            viewOnlyMode: true
        },
        euPriorAddressComponent: {
            submissionVM,
            updateWizardData: () => {},
            onValidate:  () => {},
            viewModelService,
            visible: true,
            disregardFieldValidation: () => {},
            LOB,
            viewOnlyMode: true
        },
        primaryNamedInsured: {
            viewOnlyMode: true,
            hideSSN: true,
            isPNI: true
        },
        locationCode: {
            visible: isAgent
        },
        ExternalProducerDetailsComponent: {
            viewOnlyMode: true,
            visible: !isAgent,
            defaultValue: submissionVM.baseData.externalID_Ext?.value
        },
        changePniComponent: {
            visible: false
        },
        changeSniComponent: {
            visible: false
        },
        EuRatingDateTestComponent: {
            visible: false
        },
        quoteCreationDate: {
            dateDTO: submissionVM.baseData.quoteCreationDate_Ext,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        rateAsOfDate: {
            dateDTO: submissionVM.baseData.periodRateAsOfDate,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        ratingDate: {
            dateDTO: submissionVM.baseData.ratingDate,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        agencyInformationPageContainer: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: submissionVM,
            authHeader,
            LOB,
            viewOnlyMode: true
        },
        ...generateOverrides()
    };

    const readValue = (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
            showPrevious={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

ViewPolicyDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewPolicyDetailsPage);
