import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class PriorCarrierService {
    /**
     * Validates a drivers license
     * @param {String} quoteDataDto quoteDataDto
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static updatePriorCarrierAndUpdateDTOs = (quoteDataDto, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('priorcarrier'),
            'updatePriorCarrierAndUpdateDTOs',
            [quoteDataDto],
            additionalHeaders
        );
    }

    /**
     * Validates priorCarrier service
     * @param {String} priorCarrierPolicyDetailsDataDTO priorCarrierPolicyDetailsDataDTO
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static savePriorCarrierPolicies = (priorCarrierPolicyDetailsDataDTO, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('priorcarrier'),
            'savePriorCarrierPolicies',
            [priorCarrierPolicyDetailsDataDTO],
            additionalHeaders
        );
    }

    /**
     * This method is used read the prior carrier names from the database table
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} result should be a list of prior carrier names
     */
    static fetchPriorCarrierNamesDTO = (additionalHeaders) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('priorcarrier'),
            'fetchPriorCarrierNamesDTO',
            [],
            additionalHeaders
        );
    }
}
