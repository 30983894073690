import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropdownMenuButton, DropdownMenuSeparator, DropdownMenuHeader } from '@jutro/components';
import { ContactService } from 'e1p-capability-gateway';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { useTranslator } from '@jutro/locale';

import messages from './AddDriverComponent.messages';

import { Button } from '@jutro/legacy/components';

import { DropdownMenuLink } from '@jutro/router';

/**
 * E1PAP1PC-9747 & 9755 This component allows a user
 *   to change a person Object on a NI, driver, etc. to
 *   a person contact that belongs to an account. Initially
 *   state is undefined and shows a loader, if call is not
 *   successful, state is set to an empty list and loader goes away.
 *   Succesful call loads list of all contacts.
 *
 *   It is important to notice the difference between using this
 *   menu to create new person VS editing fields on a NI. New person here
 *   will be wiping public ID and therefore making a new contact, whereas
 *   editing the fields on a NI will only be editing an existing contact
 *
 * @param {Object} props see prop types
 * @returns {JSX}
 */
function AddDriverComponent(props) {
    const {
        value: driversArray,
        path,
        onValueChange,
        accountNumber,
        authHeader,
        allowNewContact,
        // This is array of public IDs
        //   - Might change prop name at another time
        excludedContacts,
        viewModelService,
        setCheckScrolling,
        setIndexStale,
        setIsAccountContactsFetched,
        isAccountContactsFetched
    } = props;
    const translator = useTranslator();
    // Logic relies on this starting as undefined instead of empty list
    const [contacts, setContacts] = useState(undefined);
    const [contactsLoaded, setContactsLoaded] = useState(false);


    const getPersonContacts = useCallback(() => {
        ContactService.getAccountContacts(accountNumber, authHeader)
            .then((contactDtos) => {
                // returning an empty object when empty for some reason
                if (contactDtos.length > 0) {
                    setContacts(contactDtos);
                } else {
                    // empty list instead of empty object that is returned
                    setContacts([]);
                }

                setIsAccountContactsFetched(true);
            })
            .catch(() => {
                // Not worried about call failing here
                //   If somehow this were reached, set list to empty
                setContacts([]);
            })
            .finally(() => setContactsLoaded(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // fetch account contacts only once
        if(!isAccountContactsFetched){
            getPersonContacts();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAccountContactsFetched]);

    const addDriver = useCallback((driverPerson) => {
        const driverObj = {
            person: driverPerson,
            licenseNumber: driverPerson.licenseNumber,
            licenseState: driverPerson.licenseState
        };
        const driverVM = viewModelService.create(
            driverObj,
            'pc',
            'amfam.edge.capabilities.policyjob.lob.ea.coverables.dto.EADriverDTO'
        );

        driversArray.value.push(
            driverVM.value
        );
        onValueChange(driversArray.value, path);

        if (setCheckScrolling) { setCheckScrolling(true); }

        if (setIndexStale) { setIndexStale(true); }
    }, [
        viewModelService, driversArray.value, onValueChange,
        path, setCheckScrolling, setIndexStale,
    ]);


    let existingContacts = [];

    if (contacts) {
        existingContacts = contacts
            // filter out company contacts
            .filter((contact) => contact.person)
            // filter out the NI from change to list (not done on BE)
            .filter((contact) => !excludedContacts.includes(contact.person.publicID))
            // eslint-disable-next-line arrow-body-style
            .map((contact) => {
                return (
                    <DropdownMenuLink
                        key={contact.person.publicID}
                        onClick={() => addDriver(contact.person)}
                    >
                        {contact.person.displayName}
                    </DropdownMenuLink>
                );
            });
    }

    if (existingContacts.length !== 0 && contactsLoaded) {
        return (
            <DropdownMenuButton
                id="dropdownMenuButton"
                buttonText={translator(messages.addDriver)}
                type="outlined"
                icon="mi-expand-more"
                iconPosition="right"
            >
                {
                    allowNewContact ? (
                        <DropdownMenuHeader
                            title={translator(messages.new)}
                        >
                            <DropdownMenuSeparator />
                            <DropdownMenuLink
                                onClick={() => addDriver({})}
                            >
                                {translator(messages.newPerson)}
                            </DropdownMenuLink>
                            <DropdownMenuSeparator />
                        </DropdownMenuHeader>
                    ) : undefined
                }
                {
                    existingContacts.length > 0 ? (
                        <DropdownMenuHeader
                            title={translator(messages.existingPerson)}
                        >
                            <DropdownMenuSeparator />
                            {
                                existingContacts || <E1PLoader loaded={contactsLoaded} />
                            }
                            <DropdownMenuSeparator />
                        </DropdownMenuHeader>
                    ) : undefined
                }
            </DropdownMenuButton>
        );
    }

 return (
        <Button
            onClick={() => addDriver({})}
            type="outlined"
            icon="mi-add"
        >
            {translator(messages.addDriver)}
        </Button>
    );
}

AddDriverComponent.propTypes = {
    value: PropTypes.shape({}).isRequired,
    path: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    accountNumber: PropTypes.string.isRequired,
    allowNewContact: PropTypes.bool,
    excludedContacts: PropTypes.arrayOf(PropTypes.string),
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    setCheckScrolling: PropTypes.func,
    setIndexStale: PropTypes.func,
    isAccountContactsFetched: PropTypes.bool,
    setIsAccountContactsFetched: PropTypes.func
};

AddDriverComponent.defaultProps = {
    allowNewContact: true,
    excludedContacts: [],
    setCheckScrolling: undefined,
    setIndexStale: undefined,
    isAccountContactsFetched: false,
    setIsAccountContactsFetched: undefined
};
export default AddDriverComponent;
