import { defineMessages } from 'react-intl';

export default defineMessages({
    lineOfBusiness: {
        id: 'e1p.gateway.dashboard.jobs.searchCriteriaFilter.Line of Business',
        defaultMessage: 'Line of Business',
    },
    personalAuto: {
        id: 'e1p.gateway.dashboard.jobs.Personal Auto',
        defaultMessage: 'Personal Auto',
    },
    homeowners: {
        id: 'e1p.gateway.dashboard.jobs.Homeowners',
        defaultMessage: 'Homeowners',
    },
    umbrella: {
        id: 'e1p.gateway.dashboard.jobs.Umbrella',
        defaultMessage: 'Umbrella',
    },
    quoteNumber: {
        id: 'e1p.gateway.dashboard.jobs.searchCriteriaFilter.Quote Number',
        defaultMessage: 'Quote Number',
    },
    policyNumber: {
        id: 'e1p.gateway.dashboard.jobs.searchCriteriaFilter.Policy Number',
        defaultMessage: 'Policy Number',
    },
    dateIssued: {
        id: 'e1p.gateway.dashboard.jobs.searchCriteriaFilter.Date Issued',
        defaultMessage: 'Date Issued',
    },
    status: {
        id: 'e1p.gateway.dashboard.jobs.searchCriteriaFilter.Status',
        defaultMessage: 'Status',
    }
});
