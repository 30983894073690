import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import PropTypes from 'prop-types';
import {
    PolicyService as GatewayPolicyService,
    PolicySearchUtil,
    PolicyDetailsService,
} from 'e1p-capability-gateway';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';

import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import JobSearchCriteriaFilterComponent from './JobSearchCriteriaFilterComponent/JobSearchCriteriaFilterComponent';
import PoliciesTableComponent from './PoliciesTableComponent/PoliciesTableComponent';
import { withDashboardStateContext } from '../E1PStateManagement/E1PDashboardStateContext';
import metadata from './PoliciesDashboard.metadata.json5';
import styles from './PoliciesDashboard.module.scss';

const availableTilesForTransactionsView = [
    'quotes',
    'change',
    'cancellation',
    'renewal',
    'rewrite',
];
const policyTiles = ['allPolicies', 'recentlyViewedPolicies', 'refinePolicies'];
const emptyResponse = { rows: [], numberOfRows: 0 };
const MAX_NUMBER_OF_RECORDS_FOR_REFINE_RESULT = 250;
// this is used to set default/initial values for the page
const DEFAULT_PAGE_STATE = {
    currentView: 'recentlyViewedPolicies',
    selectedPolicyView: 'recentlyViewedPolicies',
    dataTableKey: 'recentlyViewedPolicies',
    searchCriteriaObj: undefined,
    dataTableConfig: { sorted: [], pageSize: 25, page: 0 },
};

/**
 *
 * @param {*} selectedAccountView
 * @returns {[]} array for sorted property for dataTable
 *
 * This will be used whenever user selects different radio button or tiles on UI
 */
function getDefaultSortForTileView (selectedAccountView) {
    return selectedAccountView === 'recentlyViewedPolicies'
        ? []
        : [
              {
                  id: 'effectiveDate',
                  desc: true,
              },
          ];
}

class PoliciesDashboard extends Component {
    static propTypes = {
        match: PropTypes.shape({
            path: PropTypes.string,
            params: PropTypes.shape({
                PolicyNumber: PropTypes.string,
            }),
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func,
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.string,
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        intl: PropTypes.shape({}).isRequired,
        producerCode: PropTypes.string,
    };

    static defaultProps = {
        producerCode: undefined
    }

    static contextType = TranslatorContext;

    /**
     * 'shouldFetchData' is used to control the async call from child component,
     * for example if user is landing on the dashboard for the first time, then policiesDashboardState will be undefined
     * and the child component will fecth the data based on default  values set above,
     *
     * for any subsequent visit to the dashboard, policiesDashboardState will have last filter states,
     * for this scenario we need to call backend based on previous state once the component is loaded sucessfully.
     * setting this to true in componentDidMount.
     *  */
    state = {
        toggleFilter: true,
        currentView: DEFAULT_PAGE_STATE.currentView,
        selectedPolicyView: DEFAULT_PAGE_STATE.selectedPolicyView,
        dataTableKey: DEFAULT_PAGE_STATE.dataTableKey,
        dataTableConfig: DEFAULT_PAGE_STATE.dataTableConfig,
        policyLandingLoader: false,
        quoteNumberVisible: false,
        exceptions: [],
        shouldFetchData: _.isEmpty(this.props?.policiesDashboardState),
        isAgent: (() => {
            const { authUserData } = this.props;

            return authUserData.roles_Ext.includes('ext_sales_service');
        })()
    };

    componentDidMount () {
        const { viewModelService, policiesDashboardState } = this.props;

        // set values from the context object
        if (!_.isEmpty(policiesDashboardState)) {
            const searchCriteriaVM =
                PolicySearchUtil.createPolicySearchVM(viewModelService);
            const isQuoteNumberVisible = !policyTiles.includes(
                policiesDashboardState.currentView
            );

            if (
                !_.isUndefined(policiesDashboardState.searchCriteriaObj) &&
                !_.isEmpty(policiesDashboardState.searchCriteriaObj)
            ) {
                _.set(
                    searchCriteriaVM,
                    'value',
                    policiesDashboardState.searchCriteriaObj
                );
            }

            this.setState({
                currentView: policiesDashboardState.currentView,
                selectedPolicyView: policiesDashboardState.selectedPolicyView,
                dataTableKey: policiesDashboardState.dataTableKey,
                dataTableConfig: policiesDashboardState.dataTableConfig,
                quoteNumberVisible: isQuoteNumberVisible,
                jobSearchVM: searchCriteriaVM,
                shouldFetchData: true,
            });
        } else {
            this.createJobSearchVM();
        }
    }

    componentDidUpdate () {
        const { currentView, quoteNumberVisible } = this.state;
        const isQuoteNumberVisible = !policyTiles.includes(currentView);

        if (quoteNumberVisible !== isQuoteNumberVisible) {
            this.setState({
                quoteNumberVisible: isQuoteNumberVisible,
            });
        }
    }

    // This function will set the current filter state to the context
    updateDashboardStateToContext = dataTableConfig => {
        const { setPoliciesDashboardState } = this.props;
        const { currentView, selectedPolicyView, dataTableKey, jobSearchVM } =
            this.state;
        const pageStateObj = {
            currentView,
            selectedPolicyView,
            dataTableKey,
            searchCriteriaObj: _.get(jobSearchVM, 'value'),
            dataTableConfig,
        };

        if (setPoliciesDashboardState) {
            setPoliciesDashboardState(pageStateObj);
        }
    };

    createJobSearchVM = () => {
        const { viewModelService } = this.props;
        const searchCriteriaVM =
            PolicySearchUtil.createPolicySearchVM(viewModelService);

        this.setState({ jobSearchVM: searchCriteriaVM });
    };

    getFormattedCurrency = (premiumAmount, policyNumber) => {
        const totalPremiumCurrencyId = `totalPremium_${policyNumber}`;

        return (
            <CurrencyField
                id={totalPremiumCurrencyId}
                value={premiumAmount}
                readOnly
                hideLabel
                showOptional={false}
            />
        );
    };

    handleOnClick = id => {
        this.setState({
            policyLandingLoader: true,
        });

        const isPolicyTile = id === 'policies';

        this.setState({
            quoteNumberVisible: !isPolicyTile,
            currentView: isPolicyTile ? 'recentlyViewedPolicies' : id,
            selectedPolicyView: isPolicyTile ? 'recentlyViewedPolicies' : '',
            policyLandingLoader: false,
            exceptions: [],
            dataTableKey: isPolicyTile ? 'recentlyViewedPolicies' : id,
            dataTableConfig: {
                ...DEFAULT_PAGE_STATE.dataTableConfig,
                sorted: getDefaultSortForTileView(id),
            },
        });
        this.createJobSearchVM();
    };

    getSortingFieldName = fieldID => {
        const { currentView } = this.state;
        let sortingFieldName = '';

        switch (fieldID) {
            case 'effectiveDate':
                sortingFieldName = policyTiles.includes(currentView)
                    ? 'policyeffectivedate'
                    : 'jobeffectivedate';

                if (currentView === 'recentlyViewedPolicies') {
                    sortingFieldName = 'effective';
                }

                break;
            case 'expirationDate':
                sortingFieldName = 'expiration';
                break;
            case 'accountHolderName':
                sortingFieldName = 'primaryinsuredname';
                break;
            case 'policyNumber':
                sortingFieldName = 'policynumber';
                break;
            case 'dateCreated':
                sortingFieldName = policyTiles.includes(currentView)
                    ? 'policycreateddate'
                    : 'jobcreateddate';

                if (currentView === 'recentlyViewedPolicies') {
                    sortingFieldName = 'createddate_ext';
                }

                break;
            case 'jobNumber':
                sortingFieldName = 'jobnumber';
                break;
            case 'riskState': {
                sortingFieldName = 'riskstate';

                if (currentView === 'recentlyViewedPolicies') {
                    sortingFieldName = 'riskstate_ext';
                }

                break;
            }
            case 'locationCode': {
                sortingFieldName = 'locationcode';

                if (currentView === 'recentlyViewedPolicies') {
                    sortingFieldName = 'locationcode_ext';
                }

                break;
            }
            default:
                sortingFieldName = policyTiles.includes(currentView)
                    ? 'policyeffectivedate'
                    : 'jobeffectivedate';

                // By default we won't be sending order by effective date for getViewedPolicies api
                if (currentView === 'recentlyViewedPolicies') {
                    sortingFieldName = undefined;
                }
        }

        return sortingFieldName;
    };

    processTransactionResponseData = transactionData => {
        const transactionItems = [];
        const { currentView } = this.state;
        const { intl } = this.props;

        if (_.isArray(transactionData)) {
            transactionData.forEach(quoteItems => {
                let totalPremium;
                let effectiveDate;
                let createDate;
                let status;
                let accountName;
                let expirationDate;

                if (policyTiles.includes(currentView)) {
                    totalPremium = this.getFormattedCurrency(
                        quoteItems.premium,
                        quoteItems.policyNumber
                    );
                    effectiveDate = quoteItems.effective;
                    expirationDate = quoteItems.expiration;
                    createDate = quoteItems.createdDate_Ext;
                    status = quoteItems.displayStatus;
                    accountName = quoteItems.primaryInsuredName;
                } else {
                    const { amount } = quoteItems.totalPremium;

                    totalPremium =
                        amount !== 0
                            ? this.getFormattedCurrency(
                                  quoteItems.totalPremium,
                                  quoteItems.policyNumber
                              )
                            : '-';
                    effectiveDate = quoteItems.jobEffectiveDate_Ext;
                    expirationDate = quoteItems.jobExpirationDate_Ext;
                    createDate = quoteItems.createTime;
                    status = quoteItems.status;
                    accountName = quoteItems.primaryInsuredName_Ext;
                }

                const address = quoteItems.address
                    ? quoteItems.address
                    : quoteItems.address_Ext;

                const transactionItem = {
                    productCode: quoteItems.product.productCode,
                    premium: totalPremium,
                    jobStatus: status,
                    effectiveDate: intl.formatDate(new Date(effectiveDate), { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }),
                    expirationDate: intl.formatDate(new Date(expirationDate), { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }),
                    jobNumber: quoteItems.jobNumber,
                    accountNumber: quoteItems.accountNumber,
                    accountHolderName: accountName,
                    policyNumber: quoteItems.policyNumber,
                    dateCreated: intl.formatDate(new Date(createDate), { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }),
                    address: `${address.addressLine1}, ${address.city}, ${address.state} ${address.postalCode}`,
                    locationCode: quoteItems.locationCode_Ext,
                    riskState: quoteItems.riskState_Ext,
                };

                transactionItems.push(transactionItem);
            });
        }

        return transactionItems;
    };

    fetchTransactionData = async paginationParams => {
        this.setState({
            exceptions: [],
            isCallingApi: true,
        });

        const response = await this.fetchRefinePolicies(paginationParams);

        const dataToPass = response.jobSummaries;
        const data = this.processTransactionResponseData(dataToPass);

        this.setState({ isCallingApi: false });

        return {
            rows: data,
            numberOfRows: response.maxNumberOfResults,
        };
    };

    fetchRefinePolicies = async paginationParams => {
        const { authHeader, viewModelService } = this.props;
        const { jobSearchVM, currentView, isAgent } = this.state;
        const searchCriteriaObj = _.get(
            viewModelService.clone(jobSearchVM),
            'value'
        );

        if (!searchCriteriaObj) {
            return;
        }

        this.setState({ isCallingApi: true, exceptions: undefined });

        // we dont need to send producercode to backend if AllLocations option is selected on the UI
        // All Location option is just for user to let him know that
        // backend will search the account, in his assigned locations only
        if (
            searchCriteriaObj.producerCode &&
            searchCriteriaObj.producerCode === 'allLocations'
        ) {
            _.unset(searchCriteriaObj, 'producerCode');
        }

        if (
            searchCriteriaObj.createdInLastXDays_Ext &&
            searchCriteriaObj.createdInLastXDays_Ext === -1
        ) {
            // dont send createdInLastXDays_Ext to backend, if anytime option is selected,
            // because backend will consider all the accounts,
            _.unset(searchCriteriaObj, 'createdInLastXDays_Ext');
        }

        if (
            searchCriteriaObj.issuedInLastXDays_Ext &&
            searchCriteriaObj.issuedInLastXDays_Ext === -1
        ) {
            // dont send issuedInLastXDays_Ext to backend, if anytime option is selected,
            // because backend will consider all the accounts,
            _.unset(searchCriteriaObj, 'issuedInLastXDays_Ext');
        }

        let response = {
            jobSummaries: [],
            maxNumberOfResults: 0,
            exceptions_Ext: [],
        };

        if (!isAgent) {
            // fetch producercode for UW Login only
            const locationCode = _.get(searchCriteriaObj, 'producerCode');

            if (locationCode) {
                try {
                    const policyDetails =
                        await PolicyDetailsService.getProducerDetails(
                            locationCode,
                            authHeader
                        );

                    _.set(
                        searchCriteriaObj,
                        'producerCode',
                        policyDetails.producerCode
                    );
                } catch {
                    return response;
                }
            }
        }

        switch (currentView) {
            case 'quotes':
                response = await GatewayPolicyService.retrieveJobs(
                    paginationParams,
                    'submission',
                    searchCriteriaObj,
                    authHeader
                );
                break;
            case 'change':
                response = await GatewayPolicyService.retrieveJobs(
                    paginationParams,
                    'policyChange',
                    searchCriteriaObj,
                    authHeader
                );
                break;
            case 'cancellation':
                response = await GatewayPolicyService.retrieveJobs(
                    paginationParams,
                    'cancellation',
                    searchCriteriaObj,
                    authHeader
                );
                break;
            case 'renewal':
                response = await GatewayPolicyService.retrieveJobs(
                    paginationParams,
                    'renewal',
                    searchCriteriaObj,
                    authHeader
                );
                break;
            case 'rewrite':
                response = await GatewayPolicyService.retrieveJobs(
                    paginationParams,
                    'rewrite',
                    searchCriteriaObj,
                    authHeader
                );
                break;
            case 'refinePolicies':
                response = await GatewayPolicyService.searchPolicies(
                    searchCriteriaObj,
                    paginationParams,
                    authHeader
                );
                break;
            default:
        }

        const exceptionForMaxNoOfResults =
            response.exceptions_Ext &&
            response.maxNumberOfResults &&
            response.maxNumberOfResults >
                MAX_NUMBER_OF_RECORDS_FOR_REFINE_RESULT;

        // IAP-3082, show exceptions other than Max number of record found
        if (!exceptionForMaxNoOfResults && response.exceptions_Ext) {
            this.setState({ exceptions: response.exceptions_Ext });
        }

        this.setState({ isCallingApi: false });

        return response;
    };

    onRefineJobsData = searchCriteriaVM => {
        const { currentView } = this.state;

        this.setState({
            jobSearchVM: searchCriteriaVM,
            dataTableKey: `${currentView}${new Date().getTime()}`,
            // set data table config to default state
            dataTableConfig: {
                ...DEFAULT_PAGE_STATE.dataTableConfig,
                sorted: getDefaultSortForTileView(currentView),
            },
        });
    };

    fetchPolicyData = async paginationParams => {
        const { authHeader } = this.props;
        const { currentView, selectedPolicyView, dataTableKey } = this.state;

        this.setState({
            exceptions: [],
            isCallingApi: true,
        });

        let response = {
            jobSummaries: [],
            maxNumberOfResults: 0,
            exceptions_Ext: [],
        };

        switch (currentView) {
            // Last 100 viewed policies 'refinePolicies'
            case 'recentlyViewedPolicies':
                response = await GatewayPolicyService.getRecentlyViewedPolicies(
                    paginationParams,
                    authHeader
                );
                break;
            // allPolicies
            case 'allPolicies':
                response = await GatewayPolicyService.getRecentlyIssuedPolicies(
                    null,
                    paginationParams,
                    authHeader
                );
                break;
            case 'refinePolicies':
                if (dataTableKey === selectedPolicyView) {
                    // this will excute only once when user selects the option
                    this.setState({ isCallingApi: false });

                    return emptyResponse;
                }

                response = await this.fetchRefinePolicies(paginationParams);
                break;
            default:
                response = await GatewayPolicyService.getRecentlyViewedPolicies(
                    paginationParams,
                    authHeader
                );
        }

        const exceptionForMaxNoOfResults =
            response.exceptions_Ext &&
            response.maxNumberOfResults &&
            response.maxNumberOfResults >
                MAX_NUMBER_OF_RECORDS_FOR_REFINE_RESULT;

        // IAP-3082, show exceptions other than Max number of record found
        if (!exceptionForMaxNoOfResults && response.exceptions_Ext) {
            this.setState({ exceptions: response.exceptions_Ext });
        }

        const dataToPass = policyTiles.includes(currentView)
            ? response.policySummaries
            : response.jobSummaries;
        const data = this.processTransactionResponseData(dataToPass);

        this.setState({ isCallingApi: false });

        return {
            rows: data,
            numberOfRows: response.maxNumberOfResults,
        };
    };

    loadTransactionsData = ({ sorted, pageSize, page }) => {
        // save current page filters state to context object
        this.updateDashboardStateToContext({ sorted, pageSize, page });

        const { currentView } = this.state;
        const orderBy = this.getSortingFieldName(_.get(sorted, '0.id'));
        const startIndex = pageSize * page;
        const offsetEndMinusOne = startIndex + pageSize - 1;
        const paginationParams = {
            offsetStart: startIndex,
            offsetEnd: offsetEndMinusOne,
            orderBy,
            orderByDescending: _.get(sorted, '0.desc', true),
        };
        const transactionData = { rows: [], numberOfRows: 0 };

        if (availableTilesForTransactionsView.includes(currentView)) {
            return this.fetchTransactionData(paginationParams);
        }

        if (policyTiles.includes(currentView)) {
            return this.fetchPolicyData(paginationParams);
        }

        return transactionData;
    };

    // helper method for radio options on policies tile
    handlePoliciesViewOptionChange = selectedOption => {
        this.setState({
            selectedPolicyView: selectedOption,
            currentView: selectedOption,
            dataTableKey: selectedOption,
            dataTableConfig: {
                ...DEFAULT_PAGE_STATE.dataTableConfig,
                sorted: getDefaultSortForTileView(selectedOption),
            },
            exceptions: [],
        });
        this.createJobSearchVM();
    };

    render () {
        const {
            toggleFilter,
            currentView,
            policyLandingLoader,
            exceptions,
            quoteNumberVisible,
            selectedPolicyView,
            dataTableKey,
            jobSearchVM,
            isCallingApi,
            dataTableConfig,
            shouldFetchData,
            isAgent,
        } = this.state;

        const tableKey = dataTableKey;
        const overrides = {
            policyLandingLoader: {
                loaded: !policyLandingLoader,
            },
            policyContentContainer: {
                visible: !policyLandingLoader,
            },
            tableContainer: {
                className: cx(styles.gwPoliciesContainerSection, {
                    [styles.gwPolicyInfoQuickTable]: toggleFilter,
                    [styles.gwPolicyInfoAdvancedTable]: !toggleFilter,
                }),
            },
            policiesAndJobTableComponent: {
                onFetchData: this.loadTransactionsData,
                dataTableKey: tableKey,
                quoteNumberVisible,
                isAgent,
                currentView,
                // used to set deafult config in child component
                dataTableConfig,
                visible: shouldFetchData,
            },
            [currentView]: {
                active: true,
            },
            wizardSingleErrorComponent: {
                issuesList: exceptions,
            },
            policiesViewOptionsID: {
                visible: policyTiles.includes(currentView),
                value: selectedPolicyView,
                onValueChange: this.handlePoliciesViewOptionChange,
                disabled: isCallingApi,
            },
            jobsFiltersContainer: {
                visible:
                    selectedPolicyView === 'refinePolicies' ||
                    availableTilesForTransactionsView.includes(currentView),
                isCallingApi,
                onRefineJobsData: this.onRefineJobsData,
                isPolicy:
                    !availableTilesForTransactionsView.includes(currentView),
                createJobSearchVM: this.createJobSearchVM,
                setJobSearchVM: value => {
                    this.setState({ jobSearchVM: value });
                },
                jobSearchVM,
                selectedTileView: currentView,
            },
            policies: {
                active: policyTiles.includes(currentView),
            },
        };

        const resolvers = {
            resolveComponentMap: {
                WizardSingleErrorComponent,
                JobSearchCriteriaFilterComponent,
                PoliciesTableComponent,
            },
            resolveCallbackMap: {
                getCell: this.getCell,
                getProductImage: this.getProductImage,
                getLink: this.getLink,
                handleOnClick: this.handleOnClick,
            },
            resolveClassNameMap: styles,
        };

        const policyLandingPage = (
            <MetadataContent
                uiProps={metadata.pageContent}
                overrideProps={overrides}
                {...resolvers}
            />
        );

        return policyLandingPage;
    }
}
export const PoliciesPage = PoliciesDashboard;
export default withViewModelService(
    withIntl(
        withAuthenticationContext(withDashboardStateContext(PoliciesDashboard))
    )
);
