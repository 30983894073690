import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class VehicleInfoLookupService {
    static lookupVehicleInfoBasedOnVinAndYear(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupBasedOnVinAndYear',
            [dto],
            additionalHeaders
        );
    }

    static lookupBasedOnYear(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupMakesBasedOnYear',
            [dto],
            additionalHeaders
        );
    }

    static lookupBasedOnMakeAndYear(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupModelsBasedOnMakeAndYear',
            [dto],
            additionalHeaders
        );
    }

    static lookupBasedOnMakeModelYear(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupBasedOnMakeModelYear',
            [dto],
            additionalHeaders
        );
    }

    static lookupBasedOnMakeModelYearSeries(dto, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehicleInfoLookup'),
            'lookupBasedOnMakeModelYearSeries',
            [dto],
            additionalHeaders
        );
    }
}
