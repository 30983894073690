import { find as _find } from 'lodash';

function getUSStates(viewModelService) {
    let stateValues = [];
    const stateTypelistFilters = viewModelService.productMetadata
        .get('pc')
        .types.getTypelist('State').filters;

    if (stateTypelistFilters) {
        stateValues = _find(stateTypelistFilters, { name: 'USStates_Ext' }).codes;
    }

    return stateValues;
}

function getStateValues(USStates, translator) {
    const availableValues = USStates.map((code) => {
        return {
            code: code.code,
            name: translator({ id: code.name })
        };
    });
    return availableValues;
}

export default {
    getUSStates,
    getStateValues
};
