import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from './E1PPreferredTPIExistComponent.metadata.json5';
import styles from './E1PPreferredTPIExistComponent.module.scss';
import messages from './E1PPreferredTPIExistComponent.messages';

import { Button } from '@jutro/legacy/components';

function E1PPreferredTPIExistComponent(props) {
    const {
        preferredTPI,
        isOpen,
        onReject,
        onResolve
    } = props;

    const address = _.get(preferredTPI, 'address');
    const addressDisplayName = `${address?.address1}, ${address?.city}, ${address?.state} ${address?.zip5}`;
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        preferredTPIName: {
            content: _.get(preferredTPI, 'bankPrimaryName', _.get(preferredTPI, 'bankSecondaryName')),
        },
        preferredAddressName: {
            content: addressDisplayName
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    const onOk = useCallback(
        () => {
            onResolve();
        }, [onResolve]
    );

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={messages.alternateTPIFound} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    {e1pCommonMessages.cancel}
                </Button>
                <Button onClick={onOk} type="filled">
                    {e1pCommonMessages.submit}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

E1PPreferredTPIExistComponent.propTypes = {
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isInLine: PropTypes.bool.isRequired,
    preferredTPI: PropTypes.shape({
    }).isRequired
};

export default E1PPreferredTPIExistComponent;
