import React, { useCallback } from 'react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from '../QualificationPage.metadata.json5';

function EUViewRenewalPreQualificationPage(props) {
    const {
        wizardData: renewalVM
    } = props;
    const translator = useTranslator();

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(() => renewalVM, [renewalVM]);

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            readOnly: true
        },
        preQualificationPageLoadingIndicator: {
            loaded: true,
            text: translator(e1pCommonMessages.loadingNextPage)
        },
        preQualificationPageContainer: {
            visible: true
        },
        euPreQualificationComponent: {
            data: renewalVM,
            updateWizardData: () => {},
            showErrors: false,
            viewOnlyMode: true
        }
    };

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate: () => {}
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={() => {}}
                onValidationChange={() => {}}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

EUViewRenewalPreQualificationPage.propTypes = wizardProps;
export default withRouter(EUViewRenewalPreQualificationPage);
