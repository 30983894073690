const ALREADY_APPROVED = 'AlreadyApproved';
const NON_BLOCKING = 'NonBlocking';

const formatAndSortUWIssues = (underwritingIssues) => {
    const mappedUWIssuedData = underwritingIssues.map((uwIssue) => {
        const uwIssueFormatted = Object.assign({}, uwIssue);
        uwIssueFormatted.currentBlockingPoint = (uwIssue.currentBlockingPoint === NON_BLOCKING
            && uwIssue.hasApprovalOrRejection)
            ? ALREADY_APPROVED : uwIssue.currentBlockingPoint;
        return uwIssueFormatted;
    });
    const sortedUWIssueData = mappedUWIssuedData.sort((uwIssue1, uwIssue2) => {
        let sortOrder = uwIssue1.hasApprovalOrRejection - uwIssue2.hasApprovalOrRejection;
        if (sortOrder === 0) {
            sortOrder = uwIssue1.currentBlockingPoint > uwIssue2.currentBlockingPoint ? 1 : -1;
        }
        return sortOrder;
    });
    return sortedUWIssueData;
};

export default {
    formatAndSortUWIssues
};