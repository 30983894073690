import { defineMessages } from 'react-intl';

export default defineMessages({
    doingRateMessage: {
        id: 'renewal.eh.views.riskAnalysis.Please wait while we rate your policy...',
        defaultMessage: 'Please wait while we rate your policy...'
    },
    lossesAndViolationTabHeading: {
        id: 'renewal.eh.views.riskAnalysis.lossesAndViolation.Losses',
        defaultMessage: 'Losses'
    },
    miscellaneaousReportsTabHeading: {
        id: 'renewal.eh.views.riskAnalysis.miscellaneaousReports.Miscellaneous Reports',
        defaultMessage: 'Miscellaneous Reports'
    },
    creditTabHeading: {
        id: 'renewal.eh.views.riskAnalysis.credit.Credit',
        defaultMessage: 'Credit'
    }
});
