import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class ChangePhraseService {
    static getChangePhrase(jobNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('changephrase'),
            'getChangePhrase', [jobNumber], additionalHeaders);
    }
}
