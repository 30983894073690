import { defineMessages } from 'react-intl';

export default defineMessages({
    orderingGeoDataMessage: {
        id: 'quoteandbind.eh.geoDataComponent.Ordering Geo Data...',
        defaultMessage: 'Ordering Geo Data...'
    },
    details: {
        id: 'quoteandbind.eh.geoDataComponent.Details',
        defaultMessage: 'Details'
    },
    fireStationDetails: {
        id: 'quoteandbind.eh.geoDataComponent.Fire Station Details',
        defaultMessage: 'Fire Station Details'
    },
    address: {
        id: 'quoteandbind.eh.geoDataComponent.Address',
        defaultMessage: 'Address'
    },
    zip4: {
        id: 'quoteandbind.eh.geoDataComponent.Zip4',
        defaultMessage: 'Zip4'
    },
    censusBlockGroup: {
        id: 'quoteandbind.eh.geoDataComponent.Census Block Group',
        defaultMessage: 'Census Block Group'
    },
    fipsCode: {
        id: 'quoteandbind.eh.geoDataComponent.Fips Code',
        defaultMessage: 'Fips Code'
    },
    quakeZone: {
        id: 'quoteandbind.eh.geoDataComponent.Quake Zone',
        defaultMessage: 'Quake Zone'
    },
    catZone: {
        id: 'quoteandbind.eh.geoDataComponent.Cat Zone',
        defaultMessage: 'Cat Zone'
    },
    deductibleGroup: {
        id: 'quoteandbind.eh.geoDataComponent.Deductible Group',
        defaultMessage: 'Deductible Group'
    },
    distanceToWaterSource: {
        id: 'quoteandbind.eh.geoDataComponent.Distance to Water Source',
        defaultMessage: 'Distance to Water Source'
    },
    fireStationData: {
        id: 'quoteandbind.eh.geoDataComponent.Fire Station Data',
        defaultMessage: 'Fire Station Data'
    },
    placeCode: {
        id: 'quoteandbind.eh.geoDataComponent.Place Code',
        defaultMessage: 'Place Code'
    },
    placeName: {
        id: 'quoteandbind.eh.geoDataComponent.Place Name',
        defaultMessage: 'Place Name'
    },
    fireStationDepartmentId: {
        id: 'quoteandbind.eh.geoDataComponent.Fire Station Department Id',
        defaultMessage: 'Fire Station Department Id'
    },
    fireDepartmentType: {
        id: 'quoteandbind.eh.geoDataComponent.Fire Station Department Type',
        defaultMessage: 'Fire Station Department Type'
    },
    fireStationId: {
        id: 'quoteandbind.eh.geoDataComponent.Fire Station Id',
        defaultMessage: 'Fire Station Id'
    },
    fireStationDriveTimeAMPeak: {
        id: 'quoteandbind.eh.geoDataComponent.Fire Station Drive Time AM Peak',
        defaultMessage: 'Fire Station Drive Time AM Peak'
    },
    fireStationDriveTimePMPeak: {
        id: 'quoteandbind.eh.geoDataComponent.Fire Station Drive Time PM Peak',
        defaultMessage: 'Fire Station Drive Time PM Peak'
    },
    fireStationDriveTimeOffPeak: {
        id: 'quoteandbind.eh.geoDataComponent.Fire Station Drive Time Off Peak',
        defaultMessage: 'Fire Station Drive Time Off Peak'
    },
    fireStationDriveTimeNight: {
        id: 'quoteandbind.eh.geoDataComponent.Fire Station Drive Time Night',
        defaultMessage: 'Fire Station Drive Time Night'
    },
    fireStationDriveDistance: {
        id: 'quoteandbind.eh.geoDataComponent.Fire Station Drive Distance',
        defaultMessage: 'Fire Station Drive Distance'
    },
    fireStationDriveTimeMean: {
        id: 'quoteandbind.eh.geoDataComponent.Fire Station Drive Time Mean',
        defaultMessage: 'Fire Station Drive Time Mean'
    },
    nearestWaterBody: {
        id: 'quoteandbind.eh.geoDataComponent.Nearest Water Body',
        defaultMessage: 'Nearest Water Body'
    },
    fireRefID: {
        id: 'quoteandbind.eh.geoDataComponent.Wildfire Reference ID',
        defaultMessage: 'Wildfire Reference ID'
    },
    wildfireRiskType: {
        id: 'quoteandbind.eh.geoDataComponent.Wildfire Risk Type',
        defaultMessage: 'Wildfire Risk Type'
    },
    wildfireRiskScore: {
        id: 'quoteandbind.eh.geoDataComponent.Wildfire Risk Score',
        defaultMessage: 'Wildfire Risk Score'
    },
    wildfireDetails: {
        id: 'quoteandbind.eh.geoDataComponent.Wildfire Details',
        defaultMessage: 'Wildfire Details'
    },
    windpool: {
        id: 'quoteandbind.eh.geoDataComponent.Wind Pool',
        defaultMessage: 'Wind Pool'
    },
    aTerritory: {
        id: 'quoteandbind.eh.geoDataComponent.Territory',
        defaultMessage: 'Territory'
    }
});