import { defineMessages } from 'react-intl';

export default defineMessages({
    addGaragingAddress: {
        id: 'ea.vehiclegridcomponent.Add Garaging Address',
        defaultMessage: 'Add Garaging Address'
    },
    year: {
        id: 'ea.vehiclegridcomponent.Year',
        defaultMessage: 'Year'
    },
    make: {
        id: 'ea.vehiclegridcomponent.Make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'ea.vehiclegridcomponent.Model',
        defaultMessage: 'Model'
    },
    series: {
        id: 'ea.vehiclegridcomponent.Series',
        defaultMessage: 'Series'
    },
    vin: {
        id: 'ea.vehiclegridcomponent.Vehicle ID Number (VIN)',
        defaultMessage: 'Vehicle ID Number (VIN)'
    },
    costNew: {
        id: 'ea.vehiclegridcomponent.Cost New',
        defaultMessage: 'Cost New'
    },
    primaryUse: {
        id: 'ea.vehiclegridcomponent.Primary Use',
        defaultMessage: 'Primary Use'
    },
    currentOdometerReading: {
        id: 'ea.vehiclegridcomponent.Current Odometer Reading',
        defaultMessage: 'Current Odometer Reading'
    },
    autoSafetyEquipment: {
        id: 'ea.vehiclegridcomponent.Auto Safety Equipment',
        defaultMessage: 'Auto Safety Equipment'
    },
    antiTheftDevices: {
        id: 'ea.vehiclegridcomponent.Anti-Theft Devices',
        defaultMessage: 'Anti-Theft Devices'
    },
    garagingAddress: {
        id: 'ea.vehiclegridcomponent.Garaging Address',
        defaultMessage: 'Garaging Address'
    },
    otherThanNamedInsured: {
        id: 'ea.vehiclegridcomponent.Person(s) other than named insured owns or co-owns this vehicle',
        defaultMessage: 'Person(s) other than named insured owns or co-owns this vehicle'
    },
    noAntiTheft: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.AntiTheftEmpty',
        defaultMessage: 'No Anti-Theft Categories have been added for this vehicle.'  
    },
    addAntiTheft: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Add Anti-Theft',
        defaultMessage: 'Add Anti-Theft'
    },
    addToList : {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Add Safety to List',
        defaultMessage: 'Add to List'
    }
});
