import { defineMessages } from 'react-intl';

export default defineMessages({
    primaryTitle: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Primary:',
        defaultMessage: 'Primary:'
    },
    // because lodash to camel case
    personalAutoEa: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.PersonalAutoEA Quote With QuoteID',
        defaultMessage: 'Personal Auto: {quoteNumber} |'
    },
    homeownersEh: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.HomeOwnersEH Quote With QuoteID And PolicyType',
        defaultMessage: 'Homeowners - {policyType}: {quoteNumber} |'
    },
    personalUmbrellaEu: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Umbrella Quote With QuoteID',
        defaultMessage: 'Umbrella: {quoteNumber} |'
    },
    personalAutoEaPolicy: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.PersonalAutoEA Quote With Policy Number',
        defaultMessage: 'Personal Auto: '
    },
    homeownersEhPolicy: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.HomeOwnersEH Quote With Policy Number And PolicyType',
        defaultMessage: 'Homeowners - {policyType}: '
    },
    personalUmbrellaEuPolicy: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Umbrella Quote With Policy Number',
        defaultMessage: 'Umbrella: '
    },
    homeownersEhPolicyChange: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Policy Changes - HomeownersEH With PolicyType',
        defaultMessage: 'Homeowners - {policyType}: {quoteNumber} |'
    },
    personalUmbrellaEuPolicyChange: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Policy Changes - Umbrella',
        defaultMessage: 'Umbrella: {quoteNumber} |'
    },
    personalAutoEaPolicyChange: {
        id: 'endorsement.directives.templates.pa.Policy Changes - Personal AutoEA',
        defaultMessage: 'Personal Auto: {quoteNumber} |'
    },
    homeownersEhRewrite: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Rewrite - HomeownersEH With PolicyType',
        defaultMessage: 'Homeowners - {policyType} Rewrite: {jobID} |'
    },
    personalUmbrellaEuRewrite: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Rewrite - Umbrella',
        defaultMessage: 'Umbrella Rewrite: {jobID} |'
    },
    personalAutoEaRewrite: {
        id: 'endorsement.directives.templates.pa.Rewrite - Personal AutoEA',
        defaultMessage: 'Personal Auto Rewrite: {jobID} |'
    },
    cancellation: {
        id: 'endorsement.directives.templates.pa.Cancellation',
        defaultMessage: 'General Cancellation: {quoteNumber} |'
    },
    jobID: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Job ID:',
        defaultMessage: 'Job ID: '
    },
    effectiveDateRange: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Effective Date Range',
        defaultMessage: ' {effectiveDate} - {expirationDate}'
    },
    noAccount: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Account Name',
        defaultMessage: 'Account Name'
    },
});
