import { defineMessages } from 'react-intl';

export default defineMessages({
    searchTransactions: {
        id: 'e1p.gateway.views.policy-detail.Search Transactions',
        defaultMessage: 'Search Transactions'
    },
    jobNumber: {
        id: 'e1p.gateway.directives.policy-transaction.Quote Number',
        defaultMessage: 'Quote Number'
    },
    transactionStatus: {
        id: 'e1p.gateway.directives.policy-transaction.Transaction Status',
        defaultMessage: 'Transaction Status'
    },
    transactionType: {
        id: 'e1p.gateway.directives.policy-transaction.Transaction',
        defaultMessage: 'Transaction'
    },
    term: {
        id: 'e1p.gateway.directives.policy-transaction.Term',
        defaultMessage: 'Term'
    },
    periodStatus: {
        id: 'e1p.gateway.directives.policy-transaction.Status',
        defaultMessage: 'Status'
    },
    premium: {
        id: 'e1p.gateway.directives.policy-transaction.Premium',
        defaultMessage: 'Premium'
    },
    effectiveDate: {
        id: 'e1p.gateway.directives.policy-transaction.Effective Date',
        defaultMessage: 'Effective Date'
    },
    createDate: {
        id: 'e1p.gateway.directives.policy-transaction.Create Date',
        defaultMessage: 'Create Date'
    },
    openPolicyTransactions: {
        id: 'e1p.gateway.directives.policy-transaction.Open Transactions',
        defaultMessage: 'Open Transactions'
    },
    completedPolicyTransactions: {
        id: 'e1p.gateway.directives.policy-transaction.Completed Transactions',
        defaultMessage: 'Completed Transactions'
    },
    regenerateDocument: {
        id: 'e1p.gateway.directives.policy-transaction.Regenerate Document',
        defaultMessage: 'Regenerate Document'
    },
    printToResign: {
        id: 'e1p.gateway.directives.policy-transaction.Print to Re-Sign',
        defaultMessage: 'Print to Re-Sign'
    },
    notApplicable: {
        id: 'e1p.gateway.directives.policy-transaction.Not Applicable',
        defaultMessage: 'Not Applicable'
    },
    gettingDocumentLoadingMessage: {
        id: 'e1p.gateway.directives.policy-transaction.Generating Documents...',
        defaultMessage: 'Generating Documents...'
    },
    generateDocErrorTitle: {
        id: 'e1p.gateway.directives.policy-transaction.Error Occurred While Generating Documents',
        defaultMessage: 'Error Occurred While Generating Documents'
    },
    popupBlockerAlertTitle: {
        id: 'e1p.gateway.directives.policy-transaction.Please Disable Your Pop-up Blocker.',
        defaultMessage: 'Please Disable Your Pop-up Blocker.'
    },
    popupBlockerAlertMessage: {
        id: 'e1p.gateway.directives.policy-transaction.Please disable your pop-up blocker and click the "Regenerate Document" link again, so we can display your documents.',
        defaultMessage: 'Please disable your pop-up blocker and click the "Regenerate Document" link again, so we can display your documents.'
    },
    changeReason: {
        id: 'e1p.gateway.directives.policy-transaction.Change Reason',
        defaultMessage: 'Change Reason'
    }
});
