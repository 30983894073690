import React, {
    useCallback, useContext, useEffect, useMemo, useState, useRef
} from 'react';
import {
    get as _get, set as _set, intersection as _intersection,
    some as _some, isUndefined as _isUndefined, findIndex as _findIndex
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps, WizardPageTemplateWithTitle } from 'e1p-portals-wizard-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import {
    useViewPolicyUtil
} from 'e1p-capability-hooks';
import metadata from './InsuredPage.metadata.json5';
import ViewEUVehicleOperatorPage from '../PolicyViewVehicleOperatorPage/EUVehicleOperatorPage';
import viewOnlyWizardConfig from '../../../config/eu/policy-view-eu-wizard-config.json5';

const LOB = 'personalUmbrella_EU';

function ViewPolicyDetailsPage(props) {
    const {
        wizardData: policyViewVM,
        updateWizardData,
        authUserData,
        steps,
        markFurthestStep,
        currentStepIndex,
        changeNextSteps,
        currentStep
    } = props;
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const agentRoles = ['ext_sales_service'];
    const isAgent = _intersection(authUserData.roles_Ext, agentRoles).length > 0;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const {
        getLocationAndProducerCodes,
        getProducerDetails
    } = useViewPolicyUtil();
    const underlyingPoliciesVM = useMemo(() => _get(policyViewVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []), [policyViewVM]);
    /**
         * Helper for getting producer code on initial render and
         *   then when state changes. Will only run when a state changes
         *   and on initial render so no need to make a callback as the param
         *   will always be a new value.
         * @param {String} location State code
         */

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(
            location, authHeader, setLocationAndProducerCodes
        );

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(policyViewVM, 'producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(policyViewVM, 'externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    /**
         * Initialize the producer code for an existing account
         *  as policy state will already exist
         */

    useEffect(() => {
        markFurthestStep((steps.length - 1));

        if (isAgent) {
            getAndSetLocationCodes(_get(policyViewVM, 'policyAddress.state.value.code'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(policyViewVM, 'producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(policyViewVM, 'externalID_Ext', locationAndProducerCodes.locationCodes[0].code);
        }
    }, [locationAndProducerCodes, policyViewVM]);

    useEffect(() => {
        if (policyViewVM.value.externalID_Ext) {
            getProducerDetails(
                policyViewVM.value.externalID_Ext, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
                setAgencyName(details.agencyName);
                setBookTransferIndicator(details.bookTransferIndicator);
                setServiceCenterIndicator(details.serviceCenterIndicatorValue);
            }).catch((error) => {
                /**
                 * E1PAP1PC-15099 :
                 * This is rare scenario; will never happen in production
                 * When we bound policy using dummy producer code
                 * and when other user tries to view this policy we get exception from service
                 */
                if (policyViewVM.value.exceptions_Ext) {
                    policyViewVM.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        policyViewVM.value,
                        `exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(policyViewVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyViewVM.value.externalID_Ext, authHeader]);

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            setLocationAndProducerCodes(
                [
                    {
                        producerCodes: { code: producerCode, name: producerCode },
                        locationCodes: { code: externalID, name: externalID }
                    }
                ]
            );
            _set(policyViewVM, 'producerCode_Ext', producerCode);
            _set(policyViewVM, 'externalID_Ext.value', externalID);
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [policyViewVM],
    );

    const vehicleOperatorPage = useRef({
        id: 'E1PPolicyViewEUVehicleOperatorPage',
        path: '/view-policy-vehicle-operator',
        component: ViewEUVehicleOperatorPage,
        title: {
            id: 'e1p.policyview.wizard.step.Vehicle Operator',
            defaultMessage: 'Vehicle Operator',
        },
        stepProps: {
            template: WizardPageTemplateWithTitle
        },
    }).current;

   
    const handleVehicleOperatorPage = useCallback(async() => {
        const remainingSteps = viewOnlyWizardConfig.steps.slice(currentStepIndex + 1, viewOnlyWizardConfig.steps.length);
        const indexToUnderlyingPolicyPage = _findIndex(
            remainingSteps, (step) => step.path === '/view-policy-underlying-policy'
        );
        const differentAutoExposures = ['personalauto', 'personalaononowner', 'otherspecialityvehicle', 'cycle'];

        const vehicleUnderlyingPolicyExists = _some(underlyingPoliciesVM.value,
            (policy) => differentAutoExposures.includes(policy.policyType));

        if (vehicleUnderlyingPolicyExists) {
            remainingSteps.splice(indexToUnderlyingPolicyPage + 1, 0, vehicleOperatorPage);
        }

        changeNextSteps(remainingSteps);

        return remainingSteps;
    }, [changeNextSteps, currentStepIndex, underlyingPoliciesVM.value, vehicleOperatorPage]);

    useEffect(() => {
        if (_isUndefined(underlyingPoliciesVM.value)) {
            _set(underlyingPoliciesVM, 'value', []);
        }

        handleVehicleOperatorPage().then((remainingSteps) => {
            // add current step back because we need all steps to get correct index
            remainingSteps.unshift(currentStep);

            // in view only flow we want to make each step as visited so that user
            // can navigate to any page by clicking on tabs on chevron
            markFurthestStep(remainingSteps.length - 1);
        });
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps.length]);

    const onNext = useCallback(() => policyViewVM, [
        policyViewVM
    ]);

    const resolvers = {
        resolveCallbackMap: {
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };
    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        policyDetailsPageLoadingIndicator: {
            loaded: true
        },
        coverageStartDate: {
            dateDTO: policyViewVM.periodStartDate,
            defaultToToday: true,
            showErrors: true
        },
        coverageEndDate: {
            dateDTO: policyViewVM.periodEndDate
        },
        personalInfoContainer: {
            columns: authUserData.permissions_Ext.includes('editrateasofdate')
                ? ['0.25fr', '0.25fr', '0.25fr', '0.25fr'] : ['0.25fr', '0.25fr', '0.25fr']
        },
        secNamedInsuredContainer: {
            visible: !!_get(policyViewVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        dateOfBirth: {
            dateDTO: policyViewVM.baseData?.accountHolder?.dateOfBirth
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        addSni: {
            visible: false
        },
        secondaryNamedInsured: {
            visible: !!_get(policyViewVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            viewOnlyMode: true,
            hideSSN: true
        },
        mailingAndBillingAddressComponent: {
            transactionVM: policyViewVM,
            viewOnlyMode: true,
            isPolicyView: true,
            lob: LOB
        },
        removeSni: {
            visible: false
        },
        accountSearchComponent: {
            visible: false
        },
        insuredResidenceAddress: {
            addressVM: _get(policyViewVM, 'policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            viewOnlyMode: true,
            showParentLoader: false
        },
        euPriorAddressComponent: {
            policyViewVM,
            updateWizardData: () => {},
            viewModelService,
            visible: true,
            LOB,
            viewOnlyMode: true
        },
        primaryNamedInsured: {
            viewOnlyMode: true,
            hideSSN: true,
            isPNI: true
        },
        locationCode: {
            visible: isAgent
        },
        ExternalProducerDetailsComponent: {
            viewOnlyMode: true,
            visible: !isAgent,
            defaultValue: policyViewVM.externalID_Ext?.value
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        quoteCreationDate: {
            dateDTO: policyViewVM.quoteCreationDate_Ext,
            updateDateDto: () => {},
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        rateAsOfDate: {
            dateDTO: policyViewVM.periodRateAsOfDate,
            updateDateDto: () => {},
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        ratingDate: {
            dateDTO: policyViewVM.ratingDate,
            updateDateDto: () => {},
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        agencyDetailsInfoContainer: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: policyViewVM,
            authHeader,
            LOB,
            viewOnlyMode: true
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
            showPrevious={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyViewVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewPolicyDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewPolicyDetailsPage);
