export default class SubmissionVMSelector {
    constructor(lob) {
        this.lob = lob;
    }

    getPniPerson = (submissionVM) => {
        return submissionVM.lobData[this.lob].primaryNamedInsured.person;
    };

    getSniPerson = (submissionVM) => {
        return submissionVM.lobData[this.lob].secondaryNamedInsured?.person;
    };

    getPni = (submissionVM) => {
        return submissionVM.lobData[this.lob].primaryNamedInsured;
    };

    getSni = (submissionVM) => {
        return submissionVM.lobData[this.lob].secondaryNamedInsured;
    };

    /**
     * Selects the sni or pni name
     * @param {any} submissionVM the model
     * @param {function} personSelector pni or sni selector
     * @returns {string} the promise from the backend call
     */
    getPersonName = (submissionVM, personSelector) => {
        const person = personSelector(submissionVM);
        return person ? `${person.firstName.value} ${person.lastName.value}` : '';
    };
}
