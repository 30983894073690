import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'quoteandbind.common.e1p.Exit',
        defaultMessage: 'Exit'
    },
    next: {
        id: 'quoteandbind.common.e1p.Next',
        defaultMessage: 'Next'
    },
    previous: {
        id: 'quoteandbind.common.e1p.Previous',
        defaultMessage: 'Previous'
    },
    custom: {
        id: 'quoteandbind.common.e1p.custom',
        defaultMessage: 'Quote'
    },
    exit: {
        id: 'quoteandbind.common.e1p.Exit',
        defaultMessage: 'Exit'
    },
    withdraw: {
        id: 'policychange.common.e1p.withdraw',
        defaultMessage: 'Withdraw Change'
    },
    custom1: {
        id: 'quoteandbind.common.e1p.custom1',
        defaultMessage: 'Apply Changes'
    }
});
