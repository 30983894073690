 
import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo,
    useRef
} from 'react';
import {
    get as _get,
    set as _set,
    some as _some,
    isEmpty as _isEmpty,
    findIndex as _findIndex
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { PrefillService } from 'e1p-capability-quoteandbind';
import { CreditService } from 'e1p-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { usePropertyPageUtil, useOOSConflictPageLandingUtil } from 'e1p-capability-hooks';
import { RewriteService } from 'e1p-capability-rewrite';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { E1PEHHO3PropertyDetailsComponent } from 'e1p-capability-policyjob-react';


function PropertyPage(props) {
    const translator = useTranslator();
    // eslint-disable-max-len
    const { authHeader } = useAuthentication();
    const {
        wizardData: rewriteVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        currentStepIndex,
        changeNextSteps,
        updateWizardSnapshot
    } = props;
    const stepsRef = useRef(steps);
    const coveragesIndex = _findIndex(steps, ({ path }) => path === '/coverage');
    const viewModelService = useContext(ViewModelServiceContext);
    const [isReplacementCostStale, setIsReplacementCostStale] = useState(false);
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [floorMaterialErrorState, updateFloorMaterialErrorState] = useState(false);
    const [insideWallMaterialPercentageSumErrorState, updateInsideWallMaterialPercentageSumErrorState] = useState(false);
    const [isSavingRewrite, setIsSavingRewrite] = useState(false);
    const [heatingSystemType, updateHeatingSystemType] = useState(undefined);
    const [coolingSystemType, updateCoolingSystemType] = useState(undefined);
    const [creditScoreMoreThan597, updateCreditScoreMoreThan597] = useState(false);
    const [creditReportLoaded, setCreditReportLoaded] = useState(true);
    const [isReplacementRecalculateCompleted, setIsReplacementRecalculateCompleted] = useState(true);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isOutstandingChangesPresent, setIsOutstandingChangesPresent] = useState(true);
    const hasReplacementCost = _get(rewriteVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value', undefined);

    useEffect(() => {
        /**
         * Using useRef to access current updated steps.
         * as we are adding new conflicts step and landing user on this newly created step
         * "step" state variable from props does not give us updated value inside useOOSConflictPageLandingUtil
         * it refers initial rendered value only(as we are adding new step and want to land user
         * on new step in)
         */
        stepsRef.current = steps;
         
    }, [steps]);

    const {
        removeOrAddAndLandOnConflictsPage
    } = useOOSConflictPageLandingUtil(
        stepsRef,
        currentStepIndex,
        changeNextSteps,
        jumpTo
    );

    const {
        isComponentValid,
        initialValidation,
        disregardFieldValidation,
        onValidate,
        registerComponentValidation
    } = useValidation('PropertyPage');

    const {
        addInsideWallMaterial,
        addFloorMaterial,
        addGarage
    } = usePropertyPageUtil(
        rewriteVM,
        hasReplacementCost,
        updateWizardData,
        viewModelService,
        setIsReplacementCostStale
    );

    // if validation errors are thrown we cant jump to cov page
    const shouldNotGoToCoverages = (apiResponse) => {
        const validationErrors = _get(apiResponse, 'errorsAndWarnings.validationIssues.fieldIssues', []);
        const exceptions = _get(apiResponse, 'baseData.exceptions_Ext', []);

        return validationErrors.length > 0 || exceptions.length > 0;
    };

    const isNumberOfFullBathsValid = useMemo(() => {
        const numberOfFullBaths = _get(rewriteVM, 'lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value');

        return numberOfFullBaths === undefined
            || (numberOfFullBaths >= 1
                && numberOfFullBaths <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewriteVM.lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value]);

    const isNumberOfHalfBathsValid = useMemo(() => {
        const numberOfHalfBaths = _get(rewriteVM, 'lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value');

        return numberOfHalfBaths === undefined
            || (numberOfHalfBaths >= 0
                && numberOfHalfBaths <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewriteVM.lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value]);

    const isNumberOfRoomsWithCathedralVaultedCeilingsValid = useMemo(() => {
        const numberOfRoomsWithCathedralVaultedCeilings = _get(rewriteVM, 'lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value');

        return numberOfRoomsWithCathedralVaultedCeilings === undefined
            || (numberOfRoomsWithCathedralVaultedCeilings >= 0
                && numberOfRoomsWithCathedralVaultedCeilings <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewriteVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value]);

    useEffect(() => {
             // eslint-disable-next-line arrow-body-style
             registerComponentValidation(() => {
            return (!insideWallMaterialPercentageSumErrorState
                && !floorMaterialErrorState
                && heatingSystemType !== undefined
                && coolingSystemType !== undefined
                && isNumberOfFullBathsValid
                && isNumberOfHalfBathsValid
                && isNumberOfRoomsWithCathedralVaultedCeilingsValid)
        })
    }, [
        coolingSystemType, floorMaterialErrorState, heatingSystemType,
        insideWallMaterialPercentageSumErrorState,
        isNumberOfFullBathsValid, isNumberOfHalfBathsValid,
        isNumberOfRoomsWithCathedralVaultedCeilingsValid,
        registerComponentValidation
    ])

    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            /**
                 * IAP-4661 : allow recalculate on save
                 */
            if (!isComponentValid
                || ((!hasReplacementCost
                    || isReplacementCostStale)
                    && !calledFromOnSave)
            ) {
                updateIsPageSubmitted(true);
                setIsOutstandingChangesPresent(true);
                window.scrollTo(0, 0);

                return false;
            }

            // Set a different message for replacement cost
            if (isReplacementCostStale && !calledFromOnSave) {
                updateIsPageSubmitted(false);
                setIsOutstandingChangesPresent(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRewrite(true);

            // Store quotedata before quote
            const quoteData = _get(rewriteVM, 'quoteData.value');

            let serverCall = RewriteService.saveAndQuote;

            if (calledFromOnSave && !hasReplacementCost) {
                serverCall = RewriteService.updateDraftRewrite;
                updateIsPageSubmitted(false);
            }

            const response = await serverCall([rewriteVM.value], authHeader);

            const respQuoteData = _get(response, 'quoteData');

            if (respQuoteData === undefined) {
                _set(response, 'quoteData', quoteData);
            }

            _set(rewriteVM, 'value', response);
            updateWizardData(rewriteVM);

            if (!calledFromOnSave) {
                // E1PAP1PC-13853 :
                // If we get conflicts in saveAndQuote, we will add conflicts page if its not present
                // and user will land on conflicts page
                // If we had conflicts and we came back and made changes such that after saveAndQuote
                // if there are no conflicts we will remove conflicts page if its present
                const hasConflicts = !_isEmpty(_get(rewriteVM, 'value.conflicts', []));

                removeOrAddAndLandOnConflictsPage(hasConflicts);

                if (hasConflicts) {
                    setIsSavingRewrite(false);

                    return false;
                }
            }

            // Check for validation errors and stop page before jumping
            if (shouldNotGoToCoverages(rewriteVM.value)) {
                // stay on page
                updateWizardData(rewriteVM);
                setIsSavingRewrite(false);

                return false;
            }

            setIsSavingRewrite(false);

            if (!calledFromOnSave) {
                jumpTo(coveragesIndex, true);
            }

            // Must return false to deny wizardpageonnext promise
            return false;
        },
        [
            isComponentValid, hasReplacementCost, isReplacementCostStale,
            rewriteVM, authHeader, updateWizardData, jumpTo, coveragesIndex,
            removeOrAddAndLandOnConflictsPage
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = _get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, rewriteVM, updateWizardSnapshot]
    );


    const getCreditData = (async () => {
        if (!isSkipping) {
            setCreditReportLoaded(false);
            CreditService.getCreditReport(
                _get(rewriteVM, 'jobID.value'),
                authHeader
            ).then((response) => {
                const reportWithoutNoHit = response.creditRecords.find((creditRecord) => creditRecord.reportStatus !== 'NOHIT');

                if (reportWithoutNoHit !== undefined) {
                    updateCreditScoreMoreThan597(reportWithoutNoHit.creditScore >= 597);
                }

                setCreditReportLoaded(true);
            }).catch((exception) => {
                if (rewriteVM.value.baseData.exceptions_Ext) {
                    rewriteVM.value.baseData.exceptions_Ext.push(
                        { errorMessage: exception.baseError ? exception.baseError : exception.message }
                    );
                } else {
                    _set(
                        rewriteVM.value.baseData,
                        `exceptions_Ext[${0}]`,
                        { errorMessage: exception.baseError ? exception.baseError : exception.message }
                    );
                }

                updateWizardData(rewriteVM);
                updateCreditScoreMoreThan597(false);
            }).finally(() => {
                setCreditReportLoaded(true);
            });
        }
    });

    useEffect(() => {
        getCreditData();

        const heatingSystems = _get(rewriteVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            updateHeatingSystemType(heatingSystems[0].heatingType);
        }

        const coolingSystems = _get(rewriteVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');

        if (coolingSystems !== undefined && coolingSystems.length > 0) {
            updateCoolingSystemType(coolingSystems[0].coolingType);
        }

        updateWizardData(rewriteVM);
        setIsPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
/**
     * Below are the required fields for replacement cost to be recalculated.
     */
    const requiredFieldsForReplacementCost = useMemo(() => {
        const fields = [
            'yearBuilt',
            'numberOfStories',
            'totalSquareFeet',
            'exteriorWallFinish',
            'roofType',
            'roofShape',
            'garages', // has children
            'slopeType',
            'numberOfRoomsWithCathedralVaultedCeilings',
            'numberOfRoomsWithCrownMolding',
            'ceilingHeightType',
            'numberOfFullBaths',
            'numberOfHalfBaths',
            'kitchenCounterTopType',
            'foundationType',
            'basementFinishedArea',
            'heatingSystems',
            'coolingSystems',
            'insideWallMaterials', // has children
            'floors', // has children
            // 'firePlaces'// has children commenting fireplaces as its optional
        ];
        
        return fields;
    }, []);
    /**
     * isRecalculateReplacementCostDisable, will save Boolean value, which will be used to enable or disable
     * the recalculate ReplacementCost button on UI, if any of the value in requiredFieldsForReplacementCost array
     * is undefined or empty, then it will returns true, else false.
     * @param {VMNode} viewModel
     * @returns {boolean}
     */
    const isRecalculateReplacementCostDisable = (viewModel) => {
        const isDisabled = _some(requiredFieldsForReplacementCost, (requiredField) => {
            const requiredFieldValue = _get(viewModel.lobData.homeowners_EH.coverables.construction, `${requiredField}.value`);

            // requiredFieldValue could be string, integer, or array.
            if (Array.isArray(requiredFieldValue)) {
                return requiredFieldValue.length === 0;
            }

            return requiredFieldValue === undefined || requiredFieldValue === '';
        });

        return isDisabled || !isComponentValid;
    };

    const isRecalculateRequired = !hasReplacementCost && !isRecalculateReplacementCostDisable(rewriteVM);

    const recalculateReplacementCost = useCallback(
        async () => {
            if (isRecalculateRequired) {
                try {
                    setIsReplacementRecalculateCompleted(false);

                    const prefillServiceResponse = await PrefillService.orderReplacementCost(
                        rewriteVM.jobID.value,
                        rewriteVM.lobData.value,
                        authHeader
                    );

                     
                    if (prefillServiceResponse?.homeowners_EH?.exceptions_Ext) {
                         
                        rewriteVM.baseData.exceptions_Ext = prefillServiceResponse?.homeowners_EH?.exceptions_Ext;
                        setIsReplacementRecalculateCompleted(true);
                    } else {
                        setIsReplacementCostStale(false);
                        setIsOutstandingChangesPresent(true);
                        // E1PAP1PC-15700 : save all data till this point
                        _set(
                            rewriteVM.lobData.homeowners_EH.coverables,
                            'value',
                            prefillServiceResponse.homeowners_EH.coverables
                        );
                        _set(rewriteVM, 'value.flowStepIDs_Ext', ['property']);
                        RewriteService.updateDraftRewrite(
                            [rewriteVM.value],
                            authHeader
                        ).then((saveRewriteResp) => {
                            const quoteData = _get(rewriteVM, 'quoteData.value');
                            const respQuoteData = _get(saveRewriteResp, 'quoteData');

                            if (respQuoteData === undefined) {
                                // in case validation error occurs and flow lands on coverage page we need quote data
                                _set(saveRewriteResp, 'quoteData', quoteData);
                            }

                            _set(rewriteVM, 'value', saveRewriteResp);
                            updateWizardData(rewriteVM);
                            updateWizardSnapshot(rewriteVM);
                        }).finally(() => {
                            setIsReplacementRecalculateCompleted(true);
                        });
                    }
                }
                catch {
                    setIsReplacementRecalculateCompleted(true);

                    if (!_isEmpty(_get(rewriteVM, 'baseData.value.exceptions_Ext', []))) {
                        rewriteVM.baseData.value.exceptions_Ext.push(
                            { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                        );
                    } else {
                        _set(
                            rewriteVM.value,
                            `baseData.exceptions_Ext[${0}]`,
                            { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                        );
                    }

                    updateWizardData(rewriteVM);
                }

                updateWizardData(rewriteVM);
            } else {
                setIsOutstandingChangesPresent(false);
            }
        }, [authHeader, isRecalculateRequired, rewriteVM, translator, updateWizardData, updateWizardSnapshot]
    );

    useEffect(() => {
        if (rewriteVM.lobData.homeowners_EH.coverables.construction.garages.value.length === 0) {
            addGarage();
        }

        if (rewriteVM.lobData.homeowners_EH.coverables.construction.floors.value.length === 0) {
            addFloorMaterial();
        }

        if (rewriteVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.length === 0) {
            addInsideWallMaterial();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line arrow-body-style
    const isPageLoaded = useMemo(() => {
          return isPageInitialized && creditReportLoaded && !isSavingRewrite
            && !isSkipping && isReplacementRecalculateCompleted && !isSavingCurrentPageChanges;
    }, [creditReportLoaded, isPageInitialized, isReplacementRecalculateCompleted, isSavingCurrentPageChanges, isSavingRewrite, isSkipping]);

    const generateOverrides = useCallback(() => {
        const overrideProps = {
            roofAgeMessage: {
                visible: false
            },
            partialPrefillNotification: {
                visible: false
            },
            propertyPrefillUnsuccessfulInfoMessage: {
                visible: false
            },
            covAWillBeUpdatedBasedOnReplacementCostInfoMessage: {
                visible: false
            }
        }

        return overrideProps;
    }, []);

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <E1PEHHO3PropertyDetailsComponent
                onValidate={onValidate}
                transactionVM={rewriteVM}
                updateWizardData={updateWizardData}
                viewOnlyMode={false}
                parentOverrideProps={generateOverrides}
                isOutstandingChangesPresent={isOutstandingChangesPresent}
                disregardFieldValidation={disregardFieldValidation}
                recalculateReplacementCost={recalculateReplacementCost}
                creditReportLoaded={creditReportLoaded}
                updateFloorMaterialErrorState={updateFloorMaterialErrorState}
                updateInsideWallMaterialPercentageSumErrorState={updateInsideWallMaterialPercentageSumErrorState}
                isSavingTransaction={isSavingRewrite}
                isReplacementRecalculateCompleted={isReplacementRecalculateCompleted}
                isSavingCurrentPageChanges={isSavingCurrentPageChanges}
                setIsReplacementCostStale={setIsReplacementCostStale}
                isPageSubmitted={isPageSubmitted}
                creditScoreMoreThan597={creditScoreMoreThan597}
                isPageInitialized={isPageInitialized}
                isSkipping={isSkipping}
                isRecalculateReplacementCostDisable={isRecalculateReplacementCostDisable}
                insideWallMaterialPercentageSumErrorState={insideWallMaterialPercentageSumErrorState}
                floorMaterialErrorState={floorMaterialErrorState}
                heatingSystemType={heatingSystemType}
                updateHeatingSystemType={updateHeatingSystemType}
                coolingSystemType={coolingSystemType}
                updateCoolingSystemType={updateCoolingSystemType}
                creditChecked
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
