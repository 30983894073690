import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { appendMetadataWithIndex } from '@xengage/gw-jutro-adapters-react';
import metadata from './EAGaragingAddressComponent.metadata.json5';
import styles from './EAGaragingAddressComponent.module.scss';

function EAGaragingAddressComponent(props) {
    const {
        index,
        garagingAddressFormData,
        onGaragingAddressRadioButtonValueChange,
        viewOnlyMode
    } = props;

    /**
     * Helper memo for appending the vehicle index to each metadata ID.
     */
    const formattedMetadata = useMemo(() => appendMetadataWithIndex(metadata.pageContent, index), [index]);

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onGaragingAddressRadioButtonValueChange
        },
        resolveClassNameMap: styles
    };

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        [`sameResidenceAddressRadioButton${index}`]: {
            visible: !viewOnlyMode
        },
        [`otherAddressRadioButtonDiv${index}`]: {
            visible: !viewOnlyMode
        },
        [`garagingAddressDisplay${index}`]: {
            readOnly: garagingAddressFormData.garagingAddressDisplay !== '',
            className: garagingAddressFormData.garagingAddressDisplay !== '' ? 'viewOnlyField' : 'displayNoneField'
        },
        [`otherAddressEditIcon${index}`]: {
            visible: !viewOnlyMode && _.get(garagingAddressFormData,'garagingAddressRadioSelection') === 'differentAddress',
            onClick: ()=>{
                onGaragingAddressRadioButtonValueChange('editDifferentAddress');
            }
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ViewModelForm
            model={{ garagingAddressFormData }}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

/**
 * Define expected property types to be passed into this Jutro component.
 */
EAGaragingAddressComponent.propTypes = {
    index: PropTypes.number.isRequired,
    garagingAddressFormData: PropTypes.shape({
        garagingAddressRadioSelection: PropTypes.string.isRequired,
        garagingAddressDisplay: PropTypes.string.isRequired
    }).isRequired,
    onGaragingAddressRadioButtonValueChange: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool
};

EAGaragingAddressComponent.defaultProps = {
    viewOnlyMode: false
};

export default EAGaragingAddressComponent;
