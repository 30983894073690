import React, { useCallback, useState, useEffect } from 'react';
import {
    get as _get, findIndex as _findIndex, set as _set,
    isEmpty as _isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { RenewalService } from 'e1p-capability-renewal';
import metadata from './RiskAnalysisPage.metadata.json5';
import messages from './RiskAnalysisPage.messages';
import wizardMessages from '../../EURenewalWizard.messages';

function RiskAnalysisPage(props) {
    const { authHeader } = useAuthentication();
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        onCustom2: handleDiscardChanges,
        jumpTo,
        updateWizardSnapshot,
        steps,
        authUserData
    } = props;
    
    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(renewalVM, 'lobData.personalUmbrella_EU.premiumStability.value') !== undefined;

    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [saveAndQuoteInProcess, setSaveAndQuoteInProcess] = useState(false);
    const [activeTab, updateActiveTab] = useState('');
    const translator = useTranslator();
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        registerInitialComponentValidation,
        registerComponentValidation
    } = useValidation('RiskAnalysisPage');
    const isDiscardButtonActive = _get(renewalVM, 'value.canDiscard_Ext', false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const changeSummaryIndex = _findIndex(steps, ({ path }) => path === '/change-review');

    const isQuoted = useCallback(() => (
            _get(renewalVM, 'baseData.periodStatus.value.code') === 'Quoted'
        ), [renewalVM]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [renewalVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        registerInitialComponentValidation(isQuoted);
    }, [isQuoted, registerInitialComponentValidation]);

    const saveAndQuote = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setSaveAndQuoteInProcess(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            _set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            setSaveAndQuoteInProcess(false);

            return renewalVM;
        },
        [isComponentValid, renewalVM, authHeader, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote();

                const fieldIssues = _get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = _get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, renewalVM, updateWizardSnapshot]
    );

    const getDefaultActiveTab = () => {
        if (_get(renewalVM.value, 'errorsAndWarnings?.underwritingIssues.length') > 0) {
            return 'uwIssuesTab';
        }

        return 'lossesAndViolationTab';
    };

    const isPageValid = useCallback(() => {
        const isQuoteBlocked = renewalVM.errorsAndWarnings?.underwritingIssues?.value?.some(
            (issue) => issue.currentBlockingPoint === 'BlocksQuote' || issue.currentBlockingPoint === 'BlocksQuoteRelease'
        );

        return !isQuoteBlocked;
    }, [renewalVM.errorsAndWarnings]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation]);

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
    };

    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setSaveAndQuoteInProcess(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            _set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setSaveAndQuoteInProcess(false);

            return false;
        },
        [
            authHeader, changeSummaryIndex, isComponentValid, jumpTo,
            renewalVM, updateWizardData, updateWizardSnapshot
        ]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: !saveAndQuoteInProcess && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: !saveAndQuoteInProcess && !isSavingCurrentPageChanges,
            defaultActiveTab: getDefaultActiveTab(),
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            activeTab: activeTab !== '' ? activeTab : getDefaultActiveTab()
        },
        lossesAndViolationComponent: {
            submissionVM: renewalVM,
            isSkipping,
            showErrors: isPageSubmitted,
            authUserData,
            isVerified: true,
            updateWizardData,
            disregardFieldValidation,
            updateIsPageSubmitted
        },
        UWIssuesComponentId: {
            submissionVM: renewalVM,
            updateWizardData,
            authHeader
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(renewalVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        },
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    return (
        <WizardPage
            skipWhen={initialValidation}
            onNext={saveAndQuote}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
