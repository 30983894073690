import { useState, useContext, useCallback } from 'react';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import _ from 'lodash';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

/**
 * @callback updateDataCallback
 * @param {Object} newData the new data for the wizard
 *
 * @typedef {Object} WizardDataHook
 * @property {Object} data the data for the wizard
 * @property {Object} snapshot the snapshot for the wizard data
 *                      (e.g. can be used to retain last backend value)
 * @property {updateDataCallback} updateData a function to update the wizard data
 * @property {updateDataCallback} updateSnapshot a function to update the wizard snapshot
 */
/**
 * Returns the wizard data hook
 * @param {Object} [initialData] the initial data
 * @returns {WizardDataHook}
 */

export default function useWizardData(initialData) {
    const viewModelService = useContext(ViewModelServiceContext);

    const cloneData = useCallback(
        (newData) => {
        // clone deep is needed here as we could have a normal object containing a vm
            return _.cloneDeepWith(newData, (value) => {
                if (ViewModelUtil.isViewModelNode(value)) {
                    return viewModelService.clone(value);
                }
                // return undefined to use cloneDeep normally
                return undefined;
            });
        }, [viewModelService]
    );

    const [
        data = initialData,
        updateData
    ] = useState(undefined);

    const [
        snapshot,
        updateSnapshot
    ] = useState(() => cloneData(initialData, viewModelService));

    return {
        data,
        updateData,
        snapshot,
        updateSnapshot,
        cloneData
    };
}
