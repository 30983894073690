import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, set, isUndefined, includes, pullAt, isEmpty
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { Grid } from '@jutro/layout';
import { Icon, useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import * as PropTypes from 'prop-types';
import { e1pUSStatesUtil } from 'e1p-capability-hooks';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { euCommonMessages, commonMessages } from 'e1p-platform-translations';
import metadata from './EUPersonalPropertyExposure.metadata.json5';

import { InputField } from '@jutro/legacy/components';

const categoryValuesForCarrier = {
    mainstreetamerica: 'MSACarrierName'
};

function EUPersonalPropertyExposure(props) {
    const modalApi = useModal();
    const {
        model: propertyExposureVM,
        onModelChange,
        onValidate,
        labelPosition,
        visible,
        setPrimaryHomeIndicator,
        carrierName,
        viewOnlyMode,
        id,
        showErrors,
        parentIndex,
        underlyingPolicyIsImportedInd
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [tabIsInitialized, setTabIsInitialized] = useState(false);
    const [openBusinessExposure, setOpenBusinessExposure] = useState([]);
    const [USStates, setUSStates] = useState([]);

    const {
        isComponentValid,
        onValidate: setComponentValidation,
    } = useValidation(id);

    useEffect(() => {
        // custom zipcode validation
        const zipCode = get(propertyExposureVM, 'riskLocation.zipCode.value');
        // eslint-disable-next-line prefer-regex-literals, security/detect-unsafe-regex
        const zipCodeValidation = new RegExp('^[0-9]{5}(-[0-9]{4})?$');
        const isValid = (zipCodeValidation.test(zipCode));

        if (onValidate) {
            onValidate(isComponentValid && isValid, id);
        }
    }, [propertyExposureVM, propertyExposureVM.riskLocation?.zipCode?.value, id, onValidate, isComponentValid]);

    useEffect(() => {
        const stateValues = e1pUSStatesUtil.getUSStates(viewModelService);

        setUSStates(stateValues);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper effect for initializing the property exposure view-model.
     */
    useEffect(() => {
        const { _xCenter, _dtoName } = propertyExposureVM;

        if (isUndefined(propertyExposureVM.value)) {
            const newPropertyExposureVM = viewModelService.create(
                { businessExposures: [] },
                _xCenter,
                _dtoName
            );

            propertyExposureVM.value = newPropertyExposureVM.value;
        }

        if (isEmpty(propertyExposureVM.riskLocation.value)) {
            const riskLocationAddressVM = viewModelService.create(
                {},
                _xCenter,
                'amfam.edge.capabilities.policyjob.lob.eu.coverables.dto.EUAddressDTO'
            );

            propertyExposureVM.riskLocation.value = riskLocationAddressVM.value;
        }

        if (!viewOnlyMode) {
            onModelChange();
        }

        setTabIsInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for handling value changes on the model.
     */
    const writeValue = useCallback(
        (newVal, localPath) => {
            set(propertyExposureVM, `${localPath}.value`, newVal);
            onModelChange();
        },
        [onModelChange, propertyExposureVM]
    );

    const openAddedBusiness = useCallback(() => {
        setOpenBusinessExposure([`businessExposure${propertyExposureVM.businessExposures.value.length - 1}underlyingBusinessExposures`]);
    }, [propertyExposureVM]);

    const handlePropertyTypeChange = useCallback((newVal, localPath, { beforeValue }) => {
        if (newVal !== beforeValue) {
            setPrimaryHomeIndicator(includes(
                ['PrimaryResidence', 'PrimaryResidenceWithRental'],
                newVal
            ));

            if (newVal === 'PrimaryResidence') {
                set(propertyExposureVM.value, 'numberOfUnits', null);
            }
        }

        writeValue(newVal, localPath);
    }, [propertyExposureVM, setPrimaryHomeIndicator, writeValue]);

    const removeUnderlyingBusinessExposure = useCallback(
        (index) => {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: euCommonMessages.removeBusinessExposure,
                message: translator(commonMessages.removeItemMessage, { itemToRemove: 'Business Exposure' }),
                confirmButtonText: translator(commonMessages.removeItemButtonText, { itemToRemove: 'BUSINESS EXPOSURE' }),
                cancelButtonText: commonMessages.cancel,
                
            }).then((result) => {
                if (result !== 'cancel') {
                    set(propertyExposureVM.businessExposures.value, index, null);
                    pullAt(propertyExposureVM.businessExposures.value, index);
                    onModelChange();
                }
            }, () => { });
        },
        [propertyExposureVM.businessExposures, modalApi, onModelChange, translator]
    );

    const renderBusinessExposureHeader = useCallback(
        (businessExposure, index) => () => (
                <Grid columns={['1fr', '1fr', '1fr', '1fr', '1fr']} gap="small">
                        <InputField
                            readOnly
                            autoComplete={false}
                            id={`typeAccordionHeader${index}`}
                            value={businessExposure.type.value && businessExposure.category?.value?.code === 'homebusiness'
                                ? translator({ id: `typekey.EUBusinessType_Ext.${businessExposure.type.value.code}` })
                                : null
                            }
                        />
                        <InputField
                            readOnly
                            autoComplete={false}
                            id={`nameAccordionHeader${index}`}
                            value={includes(['homebusiness', 'businesspursuits'], businessExposure.category?.value?.code)
                                ? businessExposure.name.value : null}
                        />
                        <InputField
                            id={`categoryAccordionHeader${index}`}
                            readOnly
                            autoComplete={false}
                            value={
                                businessExposure.category.value
                                    ? translator({ id: `typekey.BusinessCategory_Ext.${businessExposure.category.value.code}` })
                                    : null
                            }
                        />
                        <React.Fragment>
                            <InputField
                                id={`descriptionAccordionHeader${index}`}
                                readOnly
                                autoComplete={false}
                                value={includes(['homebusiness', 'businesspursuits'], businessExposure.category?.value?.code)
                                    ? businessExposure.description.value : null}
                            />
                            {
                                !viewOnlyMode && <Icon
                                    id={`deleteAccordionHeader${index}`}
                                    icon="mi-delete"
                                    size="large"
                                    className="iconAsButton"
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                        removeUnderlyingBusinessExposure(index);
                                    }}
                                />
                            }
                        </React.Fragment>
                    </Grid>
            ),
        [removeUnderlyingBusinessExposure, translator, viewOnlyMode]
    );

    useEffect(() => {
        if (
            propertyExposureVM.conductBusinessActivitiesInd.value === false
            || propertyExposureVM.conductBusinessActivitiesInd.value === undefined
        ) {
            propertyExposureVM.businessExposures.value = [];
        }
    }, [propertyExposureVM.businessExposures, propertyExposureVM.conductBusinessActivitiesInd]);

    const getBusinessExposureCategories = useCallback((businessExposure) => {
        let carrierNameCodeValue = '';

        if (carrierName.value !== undefined) {
            carrierNameCodeValue = carrierName.value.code;
        }

        const carrierNameFilter = categoryValuesForCarrier[carrierNameCodeValue];
        let availableCategoryValues = businessExposure.category.aspects.availableValues.map(
            (item) => ({
                    code: item.code,
                    name: {
                        id: item.name,
                    }
                })
        );

        if (carrierNameFilter !== undefined) {
            availableCategoryValues = businessExposure.category.aspects.availableValues[0].typelist
                .getFilter(carrierNameFilter).codes.map((item) => ({
                        code: item.code,
                        name: {
                            id: item.name,
                        }
                    }));
        }

        return availableCategoryValues;
    }, [carrierName]);

    const generateOverrides = useCallback(() => {
        const overrides = [];

        if (!tabIsInitialized) {
            return overrides;
        }

        propertyExposureVM.businessExposures.children.forEach((businessExposure, index) => {
            const selectedBusinessExposure = get(businessExposure, 'category.value.code', null);

            overrides.push({
                [`businessExposure${index}`]: {
                    renderHeader: renderBusinessExposureHeader(businessExposure, index),
                    errorState: !businessExposure.aspects.subtreeValid,
                },
            });
            overrides.push({
                [`BECategoryField${index}`]: {
                    availableValues: getBusinessExposureCategories(businessExposure),
                },
                [`BENameField${index}`]: {
                    visible: includes(['homebusiness', 'businesspursuits'], selectedBusinessExposure)
                },
                [`BEDescriptionField${index}`]: {
                    visible: includes(['homebusiness', 'businesspursuits'], selectedBusinessExposure)
                },
                [`BETypeField${index}`]: {
                    visible: selectedBusinessExposure === 'homebusiness'
                },
                [`BEOtherBusinessTypeField${index}`]: {
                    visible: selectedBusinessExposure === 'homebusiness' && get(businessExposure, 'type.value.code', null) === 'other'
                }
            });
        });

        return Object.assign({}, ...overrides);
    }, [tabIsInitialized, propertyExposureVM,
        renderBusinessExposureHeader, getBusinessExposureCategories]);

    /**
     * Specify the component properties to be overridden and given dynamic behavior.
     */


    useEffect(() => {
        if (propertyExposureVM.businessExposures.children.length > 0) {
            set(propertyExposureVM, 'conductBusinessActivitiesInd.value', true);
        } else {
            set(propertyExposureVM, 'conductBusinessActivitiesInd.value', false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyExposureVM.businessExposures?.children?.length]);

    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const propertyExposureElement = document.getElementById(`propertyExposureTab${parentIndex}`);

        if (propertyExposureElement && propertyExposureVM.businessExposures.length > 0) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const lastIndex = propertyExposureVM.businessExposures?.length - 1;
            const businessExposureObj = get(propertyExposureVM, `value.businessExposures[${lastIndex}]`);

            const businessExposureElement = propertyExposureElement.querySelector(`input[id="BECategoryField${lastIndex}"]`);

            if (businessExposureElement
                && isEmpty(businessExposureObj.category)
                && businessExposureElement.focus !== undefined) {
                businessExposureElement.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyExposureVM.businessExposures.length]);

    const overrideProps = {
        '@field': {
            labelPosition,
            visible,
            showRequired: true,
            readOnly: viewOnlyMode,
            showErrors,
            autoComplete: false
        },
        numberOfUnits: {
            visible: includes(['PrimaryResidenceWithRental'],
                get(propertyExposureVM, 'propertyType.value.code', null)),
            readOnly: underlyingPolicyIsImportedInd || viewOnlyMode
        },
        propertyType: {
            readOnly: underlyingPolicyIsImportedInd || viewOnlyMode
        },
        AddressLine1: {
            readOnly: underlyingPolicyIsImportedInd || viewOnlyMode
        },
        AddressLine2: {
            readOnly: underlyingPolicyIsImportedInd || viewOnlyMode
        },
        City: {
            readOnly: underlyingPolicyIsImportedInd || viewOnlyMode
        },
        State: {
            readOnly: underlyingPolicyIsImportedInd || viewOnlyMode,
            availableValues: e1pUSStatesUtil.getStateValues(USStates, translator)
        },
        ZipCode: {
            readOnly: underlyingPolicyIsImportedInd || viewOnlyMode
        },
        underlyingBusinessExposures: {
            accordionStates: openBusinessExposure,
            visible: !includes(['SeasonalSecondaryResidence'],
                get(propertyExposureVM, 'propertyType.value.code', null))
        },
        businessExposureMessage: {
            visible: get(propertyExposureVM, 'conductBusinessActivitiesInd.value', false)
                && propertyExposureVM.businessExposures.children.length === 0
        },
        addBEButton: {
            visible: !viewOnlyMode
        },
        swimmingPoolInd: {
            readOnly: (() => {
                const propertyPolicyType = get(propertyExposureVM,
                    'propertyPolicyType.value.code', []);

                // Swimming Pool field will be read-only for systematically added underlying policies for HO3 and HF9 
                return viewOnlyMode || (underlyingPolicyIsImportedInd && includes(['HO3', 'HF9'], propertyPolicyType));

            })()
        },
        ...generateOverrides(),
    };

    const AddBusinessExposure = () => {
        propertyExposureVM.businessExposures.value.push({
            type: undefined,
            name: undefined,
            category: undefined,
            description: undefined,
            isManuallyAddedInd: true
        });
        openAddedBusiness();
        onModelChange();
    };

    /**
     * Specify custom resolvers for this component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onAddBusinessExposure: AddBusinessExposure,
            handlePropertyTypeChange
        },
    };

    /**
     * Specify the component rendering behavior using a combination of JavaScript and JSX.
     */
    return (
        <div>
            <E1PLoader
                loaded={tabIsInitialized}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={propertyExposureVM}
                    overrideProps={overrideProps}
                    onValidationChange={setComponentValidation}
                    onValueChange={writeValue}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </E1PLoader>
        </div>
    );
}

EUPersonalPropertyExposure.propTypes = {
    model: PropTypes.shape({}).isRequired,
    onModelChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    setPrimaryHomeIndicator: PropTypes.func.isRequired,
    labelPosition: PropTypes.string,
    visible: PropTypes.bool,
    carrierName: PropTypes.shape({}),
    viewOnlyMode: PropTypes.bool,
    id: PropTypes.string,
    showErrors: PropTypes.bool,
    parentIndex: PropTypes.number.isRequired,
    underlyingPolicyIsImportedInd: PropTypes.bool
};

EUPersonalPropertyExposure.defaultProps = {
    labelPosition: 'top',
    visible: true,
    carrierName: undefined,
    viewOnlyMode: false,
    id: '',
    showErrors: false,
    underlyingPolicyIsImportedInd: false
};

export default EUPersonalPropertyExposure;
