// import {OktaAuth} from '@okta/okta-auth-js';
import LandingPage from './LandingPage/LandingPage';
import SRELandingPage from './SRELandingPage/SRELandingPage';
import EsignConfirmationPage from './EsignConfirmationPage/EsignConfirmationPage';
import ExitConfirmationPage from './ExitConfirmationPage/ExitConfirmationPage';
import Accounts from './Accounts/Accounts';
import Policies from './Policies/Policies';
import Activities from './Activities/Activities';
import Analytics from './Analytics/Analytics';
import AccountDetails from './Accounts/AccountDetails/AccountDetails';
import PolicyDetails from './Policies/PolicyDetails/PolicyDetails';
import SearchResults from './SearchResults/SearchResults';
import QuoteDetails from './QuoteDetails/QuoteDetails';
import Renewal from './Renewal/Renewal';
import Endorsement from './Endorsement/Endorsement';
import Cancellation from './Cancellation/Cancellation';
import ContactForAssistance from './ContactForAssistance/ContactForAssistance';
import Reinstatement from './Reinstatement/Reinstatement';
import Rewrite from './Rewrite/Rewrite';

// E1P custom change - Plans to migrate to customer folder in future
// import DocumentsComponent from './Components/DocumentsComponent/DocumentsComponent';
import DocumentsComponent from './Components/E1PDocumentsComponent/DocumentsComponent';

import MenuBarComponent from './Components/MenuBar/MenuBarComponent';
import PageHeaderComponent from './Components/PageHeader/PageHeaderComponent';
import ContactRoles from './Components/ContactRoles/ContactRoles';
import gatewayMessages from './gateway.messages';
import accountMessages from './Accounts/Accounts.messages';
import DashboardAccountSearchComponent from './Components/DashboardAccountSearchComponent/DashboardAccountSearchComponent';
import RedirectComponent from './RedirectComponent/RedirectComponent';
import AccountsDashboard from './Components/E1PAccountsDashboardComponent/AccountsDashboard';
import PoliciesDashboard from './Components/E1PPoliciesDashboardComponent/PoliciesDashboard';

const producerEngageComponentMap = {
    ContactRoles: { component: 'ContactRoles' },
};

const producerEngageAdditionalComponents = {
    ContactRoles,
};

export { default as summaryMessages } from './Endorsement/Summary/Summary.messages';

export {
    LandingPage as default,
    EsignConfirmationPage,
    ExitConfirmationPage,
    SRELandingPage,
    Accounts,
    Policies,
    Activities,
    Analytics,
    AccountDetails,
    PolicyDetails,
    Reinstatement,
    SearchResults,
    QuoteDetails,
    Renewal,
    Endorsement,
    Cancellation,
    ContactForAssistance,
    DocumentsComponent,
    MenuBarComponent,
    PageHeaderComponent,
    ContactRoles,
    producerEngageComponentMap,
    producerEngageAdditionalComponents,
    gatewayMessages,
    accountMessages,
    Rewrite,
    DashboardAccountSearchComponent,
    RedirectComponent,
    AccountsDashboard,
    PoliciesDashboard,
    // OktaAuth
};

export {
    default as DashboardStateContextConsumer, DashboardStateContextProvider, DashboardStateContext,
    withDashboardStateContext, useDashboardStateContext
} from './Components/E1PStateManagement/E1PDashboardStateContext';
export {
    default as AmfamOktaTokenContext, AmfamOktaTokenContextProvider, withAmfamOktaTokenContext
} from './Components/E1PStateManagement/AmfamOktaTokenContext';

export {
    default as E1PAttributeConfigContextConsumer, E1PAttributeConfigContextProvider, E1PAttributeConfigStateContext,
    withE1PAttributeConfigStateContext, useE1PAttributeConfigStateContext
} from './Components/E1PStateManagement/E1PAttributeConfigContext';

