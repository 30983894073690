
import { createG11nLocalStorageStore } from '@jutro/locale';
import { LocaleService } from 'gw-portals-i18n-react';
import LocaleConfig from 'locale-config';

function G11nStore() {
    const localeService = new LocaleService()
 
     const store = createG11nLocalStorageStore({
         name: 'g11nstore',
     }, {
         language: localeService.getPreferredLanguage(),
         locale: localeService.getPreferredLocale(),
         country: localeService.getDefaultCountryCode(),
         currency: localeService.getDefaultCurrencyCode(),
         languages: localeService.getAvailableLanguages(),
         locales: localeService.getAvailableLocales(),      
     })
 
     function localeToIETF(locale) {
         return locale.replace(/_/g, '-');
     }
 
     const GW_LOCALE_REGEX = /^([x]{2}|[y]{2})$/;
     
 
     store.subscribe((state) => state.language, (language) => {
         const activeLanguage =
         language || localeToIETF(LocaleConfig.preferredLocale);

         localStorage.setItem('selectedLanguage', activeLanguage);

         // The portals don't have a specific locale chooser
         // so we change the locale when changing the language

         if (!GW_LOCALE_REGEX.test(activeLanguage)) {
             store.setState({ locale: activeLanguage });
         }
     }, {
         fireImmediately: true,
     });

    return store;
} 

export default G11nStore;

