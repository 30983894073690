import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { Wizard } from 'e1p-portals-wizard-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { error as loggerError } from '@jutro/logger';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { OnPageOperationsUtil } from 'e1p-portals-util-js';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import ho3WizardConfig from './config/HO3/rewrite-eh-wizard-config.json5';
import ho4WizardConfig from './config/HO4/rewrite-eh-wizard-config.json5';
import ho6WizardConfig from './config/HO6/rewrite-eh-wizard-config.json5';
import hf9WizardConfig from './config/HF9/rewrite-eh-wizard-config.json5';
import hf9ViewWizardConfig from './config/viewOnly/HF9/view-rewrite-eh-wizard-config.json5';
import ho3ViewWizardConfig from './config/viewOnly/HO3/view-rewrite-eh-wizard-config.json5';
import ho4ViewWizardConfig from './config/viewOnly/HO4/view-rewrite-eh-wizard-config.json5';
import ho6ViewWizardConfig from './config/viewOnly/HO6/view-rewrite-eh-wizard-config.json5';
import wizardStepToFieldMapping from './config/rewrite-eh-wizard-step-to-field-mapping.json5';
import messages from './PEEHRewriteWizard.messages';
import styles from './PEEHRewriteWizard.module.scss';

const policyTypeMapForRewriteViewOnly = {
    HO3: {
        wizardConfig: ho3ViewWizardConfig
    },
    HO4: {
        wizardConfig: ho4ViewWizardConfig
    },
    HO6: {
        wizardConfig: ho6ViewWizardConfig
    },
    HF9: {
        wizardConfig: hf9ViewWizardConfig
    }
};
const policyTypeMapForRewriteOnly = {
    HO3: {
        wizardConfig: ho3WizardConfig
    },
    HO4: {
        wizardConfig: ho4WizardConfig
    },
    HO6: {
        wizardConfig: ho6WizardConfig
    },
    HF9: {
        wizardConfig: hf9WizardConfig
    }
};

function PEEHRewriteWizard(props) {
    const modalApi = useModal();
    const [wizardConfig, setWizardConfig] = useState(ho3WizardConfig);
    const { steps, title } = wizardConfig;
    const [initialRewriteVM, setInitialRewriteVM] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history, authHeader } = props;
    const { onPageCancel, onPagePrevious } = OnPageOperationsUtil();

    useEffect(() => {
        if (!location.state) {
            history.push('/');

            return;
        }

        const { state: { policyNumber, jobNumber, policyType } } = location;

        if (!policyNumber && !jobNumber) {
            return;
        }

        if (policyType) {
            if (location.state.viewOnly) {
                setWizardConfig(policyTypeMapForRewriteViewOnly[policyType].wizardConfig);
            } else {
                setWizardConfig(policyTypeMapForRewriteOnly[policyType].wizardConfig);
            }
        }

        const errorModalBox = (errorMessage) => {
            modalApi.showConfirm(errorMessage).then(() => {
                setIsLoading(false);

                let path = `/policies/${policyNumber}/summary`;

                if (jobNumber) {
                    path = `/rewrite/view/${jobNumber}/summary`;
                }

                history.push(path);
            });
        };

        if (policyNumber && !jobNumber) {
            const rewrite = viewModelService.create(
                {},
                'pc',
                'amfam.edge.capabilities.rewrite.dto.RewriteDataDTO'
            );

            rewrite.policyNumber = policyNumber;
            setInitialRewriteVM(rewrite);
            setIsLoading(false);
        }

        if (jobNumber) {
            RewriteService.retrieve([jobNumber], authHeader)
                .then((responseData) => {
                    const rewrite = viewModelService.create(
                        responseData,
                        'pc',
                        'amfam.edge.capabilities.rewrite.dto.RewriteDataDTO'
                    );

                    setInitialRewriteVM(rewrite);
                    setIsLoading(false);
                }).catch(() => {
                    errorModalBox({
                        title: commonMessages.unableToLoadDraftPolicy,
                        message: commonMessages.somethingWentWrong,
                        status: 'warning',
                        icon: 'mi-error-outline'
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnCancel = useCallback((cancelParams) => {
        const {
            wizardData,
            wizardSnapshot,
            param = {}
        } = cancelParams;
        const { search, pathname: nextPath = '' } = param;

        if (hasErrorOccurred || location?.state?.viewOnly) {
            return true;
        }

        return Promise.resolve(onPageCancel(wizardData.value, wizardSnapshot.value)
            .then((popupResult) => {
                if (!popupResult || popupResult === 'cancel') {
                    return _.noop();
                }

                setIsLoading(true);

                const { state: { policyNumber } } = location;
                const jobNumber = _.get(wizardData, 'jobID.value');
                let redirectPath = `/policies/${policyNumber}/summary`;

                if (jobNumber) {
                    redirectPath = `/rewrite/view/${jobNumber}/summary`;
                }

                if (nextPath?.includes('/quote-') || !_.isEmpty(nextPath)) {
                    return history.push({
                        pathname: nextPath,
                        search
                    });
                }

                return history.push(redirectPath);
            }).catch(_.noop()));
    }, [hasErrorOccurred, history, location, onPageCancel]);

    const handlePrevious = useCallback((previousProps) => {
        const {
            wizardData, wizardSnapshot, updateWizardData, cloneData
        } = previousProps;

        return Promise.resolve(
            onPagePrevious(
                wizardData, wizardSnapshot,
                updateWizardData, cloneData, location.state.viewOnly
            )
        );
    }, [location.state.viewOnly, onPagePrevious]);


    const handleError = useCallback((error) => {
        const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const jobIDFromLocation = _.get(location, 'state.policyNumber');
        const pathname = _.get(location, 'pathname');
        const redirectPath = `/policies/${jobIDFromLocation}/summary`;
        const state = {
            pathname: redirectPath,
            state: error
        };

        loggerError(`MSA-EHWizard-Error: ${error}
            Pathname: ${pathname}`);

        return modalApi.showAlert({
            status: 'error',
            icon: 'mi-error-outline',
            title: isQuotePage ? messages.cancelQuoteError : messages.anErrorOccurredTitle,
            message: isQuotePage ? messages.cancelQuoteErrorMessage : messages.anErrorOccurred,
        }).then(() => {
            setHasErrorOccurred(true);

            return <Redirect to={state} />;
        });
    }, [location, modalApi]);

    if (isLoading) {
        return (
            <E1PLoader loaded={!isLoading} />
        );
    }

    if (!initialRewriteVM) {
        return null;
    }


    return (
        <div className={styles.hoPolicyChangeWizardContainer}>
            <ErrorBoundary onError={handleError}>
                {/* Hidden field needed for GA on each wizard page */}
                <p id="sourceOfEntry" style={{ display: 'none' }}>
                    {_.get(
                        initialRewriteVM,
                        'baseData.quoteSource_Ext.sourceType.value.code',
                        'directentry'
                    )}
                </p>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    viewOnly={location.state.viewOnly}
                    initialData={
                        initialRewriteVM
                    }
                    onCancel={handleOnCancel}
                    onPrevious={handlePrevious}
                    wizardStepToFieldMapping={wizardStepToFieldMapping}
                    onPreviousModalProps={{
                        title: commonMessages.wantToJump,
                        message: commonMessages.wantToJumpMessage,
                        messageProps: {
                            ok: platformMessages.yes,
                            close: platformMessages.no
                        }
                    }}
                />
            </ErrorBoundary>
        </div>
    );
}

PEEHRewriteWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            jobID: PropTypes.string,
            viewOnly: PropTypes.bool
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }),

};

export default withAuthenticationContext(PEEHRewriteWizard);
