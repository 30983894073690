import React from 'react';
import PropTypes from 'prop-types';

import { e1pDateUtil } from 'e1p-capability-hooks';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './BillingMiscellaneousReportComponent.metadata.json5';
import styles from './BillingMiscellaneousReportComponent.module.scss';
import './BillingMiscellaneousReportComponent.messages';

function BillingMiscellaneousReportComponent({ data }) {
    const overrides = {
        cancellationActivitiesTable: {
            data
        }
    };
    const getCell = (item, index, property) => item[property.id];

    const getFormatDate = (item, index, property) => e1pDateUtil.utcToString(item[property.id]);
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell,
            getFormatDate
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

BillingMiscellaneousReportComponent.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        invoicePreviews: PropTypes.arrayOf({}).isRequired
    })).isRequired
};

BillingMiscellaneousReportComponent.defaultProps = {
    className: 'BillingMiscellaneousReportComponent'
};

export default BillingMiscellaneousReportComponent;
