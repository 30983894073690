import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import EARewriteStartPage from './pages/StartRewrite/StartRewritePageEA';
import E1PRewriteEAPolicyDetailsPage from './pages/InsuredDetailsPage/RewriteEAPolicyDetailsPage';
import E1PRewriteEADriversPage from './pages/DriversPage/DriversPage';
import E1PRewriteEAVehiclesPage from './pages/VehiclesPage/VehiclesPage';
import E1PRewriteEADriverAssignmentPage from './pages/DriverAssignment/DriverAssignmentPage';
import E1PRewriteEARiskAnalysisPage from './pages/RiskAnalysisPage/RiskAnalysisPage';
import E1PRewriteEACoveragesPage from './pages/CoveragesPage/CoveragePage';
import E1PRewriteEAThirdPartyInterestPage from './pages/ThirdPartyInterest/ThirdPartyInterestPage';
import E1PRewriteEAPaymentDetailsPage from './pages/PaymentDetails/PaymentDetailsPage';
import E1PRewriteEAThankYouPage from './pages/ThankYou/ThankYouPage';
import E1PViewRewriteEAPolicyDetailsPage from './pages/InsuredDetailsPage/ViewOnly/ViewRewriteEAPolicyDetailsPage';
import E1PViewRewriteEADriversPage from './pages/DriversPage/ViewOnly/ViewDriversPage';
import E1PViewRewriteEAVehiclesPage from './pages/VehiclesPage/ViewOnly/ViewVehiclesPage';
import E1PViewRewriteEARiskAnalysisPage from './pages/RiskAnalysisPage/viewOnly/ViewEARiskAnalysisPage';
import E1PViewRewriteEACoveragesPage from './pages/CoveragesPage/ViewOnly/ViewCoveragePage';
import E1PViewRewriteEAThirdPartyInterestPage from './pages/ThirdPartyInterest/ViewOnly/ViewThirdPartyInterestPage';
import E1PViewRewriteEAPaymentDetailsPage from './pages/PaymentDetails/ViewOnly/ViewPaymentDetailsPage';
import E1PViewRewriteEADriverAssignmentPage from './pages/DriverAssignment/ViewOnly/ViewDriverAssignmentPage';


setComponentMapOverrides(
    {
        EARewriteStartPage: { component: 'EARewriteStartPage' },
        E1PRewriteEAPolicyDetailsPage: { component: 'E1PRewriteEAPolicyDetailsPage' },
        E1PRewriteEADriversPage: { component: 'E1PRewriteEADriversPage' },
        E1PRewriteEAVehiclesPage: { component: 'E1PRewriteEAVehiclesPage' },
        E1PRewriteEARiskAnalysisPage: { component: 'E1PRewriteEARiskAnalysisPage' },
        E1PRewriteEACoveragesPage: { component: 'E1PRewriteEACoveragesPage' },
        E1PRewriteEAThirdPartyInterestPage: { component: 'E1PRewriteEAThirdPartyInterestPage' },
        E1PRewriteEAPaymentDetailsPage: { component: 'E1PRewriteEAPaymentDetailsPage' },
        E1PRewriteEAThankYouPage: { component: 'E1PRewriteEAThankYouPage' },
        E1PViewRewriteEAPolicyDetailsPage: { component: 'E1PViewRewriteEAPolicyDetailsPage' },
        E1PViewRewriteEADriversPage: { component: 'E1PViewRewriteEADriversPage' },
        E1PViewRewriteEAVehiclesPage: { component: 'E1PViewRewriteEAVehiclesPage' },
        E1PViewRewriteEARiskAnalysisPage: { component: 'E1PViewRewriteEARiskAnalysisPage' },
        E1PViewRewriteEACoveragesPage: { component: 'E1PViewRewriteEACoveragesPage' },
        E1PViewRewriteEAThirdPartyInterestPage: { component: 'E1PViewRewriteEAThirdPartyInterestPage' },
        E1PViewRewriteEAPaymentDetailsPage: { component: 'E1PViewRewriteEAPaymentDetailsPage' },
        E1PRewriteEADriverAssignmentPage: { component: 'E1PRewriteEADriverAssignmentPage' },
        E1PViewRewriteEADriverAssignmentPage: { component: 'E1PViewRewriteEADriverAssignmentPage' }
    },
    {
        EARewriteStartPage,
        E1PRewriteEAPolicyDetailsPage,
        E1PRewriteEADriversPage,
        E1PRewriteEAVehiclesPage,
        E1PRewriteEARiskAnalysisPage,
        E1PRewriteEACoveragesPage,
        E1PRewriteEAThirdPartyInterestPage,
        E1PRewriteEAPaymentDetailsPage,
        E1PRewriteEAThankYouPage,
        E1PViewRewriteEAPolicyDetailsPage,
        E1PViewRewriteEADriversPage,
        E1PViewRewriteEAVehiclesPage,
        E1PViewRewriteEARiskAnalysisPage,
        E1PViewRewriteEACoveragesPage,
        E1PViewRewriteEAThirdPartyInterestPage,
        E1PViewRewriteEAPaymentDetailsPage,
        E1PRewriteEADriverAssignmentPage,
        E1PViewRewriteEADriverAssignmentPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PEEARewriteWizard } from './PEEARewriteWizard';
