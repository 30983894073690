import { defineMessages } from 'react-intl';

export default defineMessages({
    assignToUserInMyGroup: {
        id: 'e1pAssignActivitiesComponent.Assign to a user in my groups',
        defaultMessage: 'Assign to a user in my groups'
    },
    assignToAQueue: {
        id: 'e1pAssignActivitiesComponent.Assign to a queue',
        defaultMessage: 'Assign to a queue'
    },
    assignActivity: {
        id: 'e1pAssignActivitiesComponent.Assign Activity',
        defaultMessage: 'Assign Activity'
    },
    assigningActivities: {
        id: 'e1pAssignActivitiesComponent.Assigning Activities...',
        defaultMessage: 'Assigning Activities...'
    }
});
