import { defineMessages } from 'react-intl';

export default defineMessages({
    matchesPNIorSNITitle: {
        id: 'common.E1PEHTPIDisplayTableComponent.Interest Type is Primary Named Insured and/or Secondary Named Insured',
        defaultMessage: 'Interest Type is Primary Named Insured and/or Secondary Named Insured'
    },
    matchesPNIorSNIMessage: {
        id: 'common.E1PEHTPIDisplayTableComponent.Interest Type cannot be Primary Named Insured or/and Secondary Named Insured. Please remove the Interest.',
        defaultMessage: 'Interest Type cannot be Primary Named Insured or/and Secondary Named Insured. Please remove the Interest.'
    },
});
