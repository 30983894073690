/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { info as infoLogger, error as errorLogger } from '@jutro/logger';
// import { OktaAuth } from 'e1p-capability-gateway-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { getConfigValue } from 'e1p-portals-util-js';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import _ from 'lodash';
import config from 'app-config';
import {getConfigValue as getConfigValue2 } from '@jutro/config';

// opCo literals to be compared with if required
const MSA = 'MSA';
const CONNECT = 'CONNECT';

// default opCo to be set
const DEFAULT_OPCO = _.get(config, 'operatingCompanySelector.defaultSelectedOpCo', MSA);

const AmfamOktaTokenContext = React.createContext({
    amfamAccessTokenHeader: undefined,
    opCo: DEFAULT_OPCO,
    changeOpCo: () => {},
    opCoConfig: {},
    isMSAOpCo: DEFAULT_OPCO === MSA,
    isCONNECTOpCo: DEFAULT_OPCO === CONNECT
});

// branch to merge in after master commenting so pre-prod will work in meantime 
export const AmfamTokenContextProvider = (props) => {
    const [amfamAccessTokenHeader, setAmfamAccessTokenHeader] = useState();
    // Once we have users, we will be able to determine opco from prtnerIDs and/or domain
    // name on the user. Right now there is a picker to choose. Picker might also be needed
    // by IT support and claims but will find out later
    const [opCo, setOpCo] = useState(DEFAULT_OPCO);
    const [isMSAOpCo, setIsMSAOpCo] = useState(opCo === MSA);
    const [isCONNECTOpCo, setIsCONNECTOpCo] = useState(opCo === CONNECT);
    const changeOpCo = (value) => {
        setOpCo(value);
    }
    const [opCoConfig, setOpCoConfig] = useState({});

    // Update opCo Config, as soon as opCo is changed
    useEffect(() => {
        const { operatingCompanyConfig } = config;

        setOpCoConfig(_.get(operatingCompanyConfig, `${opCo}`, {}));
        setIsMSAOpCo(opCo === MSA);
        setIsCONNECTOpCo(opCo === CONNECT);
    }, [opCo]);

    const getAmfamToken = useCallback(async() => {
        const isProduction = _.get(config, 'env.AMFAM_ENV', 'local') === 'prod';
        const amfamOktaEnvVariable = isProduction
            ? config.amfamOktaConfig.production : config.amfamOktaConfig.preproduction;
        const {
            issuer, authorizeUrl, tokenUrl, clientId, defaultLocalUrl
        } = amfamOktaEnvVariable;
        const redirectUri = getConfigValue(
            'endpoints.amfamOktaRedirectUri',
            defaultLocalUrl
        );
        const amfamOktaConfig = {
            issuer,
            authorizeUrl,
            tokenUrl,
            pkce: true,
            clientId,
            redirectUri,
            // Use authorization_code flow
            responseType: 'code',
            devMode: true,
            scopes: ['payment_scope'],
            tokenManager: {
                autoRemove: false,
                // expireEarlySeconds: 10800, - DEV Only: set for early expiration of token
                // Set auto renew token to true
                autoRenew: true,
                // Use unique storage key to avoid conflict of multiple application tokens
                storageKey: 'AMFAM_OKTA_TOKEN'
            }
        };
        const authClient = new OktaAuth(amfamOktaConfig);

        if (!redirectUri) {
            throw new Error('Enterprise Okta not configured for this environment.');
        }

        // Triggered when new token is added to token manager
        authClient.tokenManager.on('added', (key, token) => {
            infoLogger(`AmFam Okta: Added ${key}`);
            setAmfamAccessTokenHeader(`${token.tokenType} ${token.accessToken}`);
        });
        // Triggered when a token has been removed from token manager
        authClient.tokenManager.on('removed', (key) => {
            infoLogger(`AmFam Okta: Removed ${key}`);
            setAmfamAccessTokenHeader(undefined);
        });
        // Triggered when a token has expired
        authClient.tokenManager.on('expired', (key) => {
            infoLogger(`AmFam Okta: Token with key ${key} has expired`);
            setAmfamAccessTokenHeader(undefined);
        });
        // Triggered when a token has been renewed
        authClient.tokenManager.on('renewed', (key, newToken) => {
            infoLogger(`AmFam Okta: Token with key ${key} has been renewed`);
            setAmfamAccessTokenHeader(`${newToken.tokenType} ${newToken.accessToken}`);
        });
        // Triggered when an OAuthError is returned via the API (typically during token renew)
        authClient.tokenManager.on('error', (err) => {
            errorLogger(`AmFam Okta: Token manager error: ${err}`);
            setAmfamAccessTokenHeader(undefined);
        });

        try {
            // Get token for the first time without user prompt
            const { tokens } = await authClient.token.getWithoutPrompt({
                responseType: ['token', 'id_token'],
            });

            authClient.tokenManager.setTokens(tokens);

            // These IDs may need to be exposed as well to limit the parnter dropdown options
            const partnerIds = _.get(tokens, 'accessToken.claims.ptnrIds', []);
            const partnerMap = {
                MSA: ['529'],
                CONNECT: ['534', '540', '559']
            };
            const isMsaUser = partnerIds.some((id) => partnerMap.MSA.includes(id));
            const isConnectUser = partnerIds.some((id) => partnerMap.CONNECT.includes(id));

            if (isMsaUser && !isConnectUser) {
                changeOpCo(MSA);
            } else if (!isMsaUser && isConnectUser) {
                changeOpCo(CONNECT);
            } else {
                // If user has none or both, for some reason, default OpCo, 
                //   and give them the opco selector (add later; should be in prod)
                changeOpCo(DEFAULT_OPCO);
            }
        } catch (error) {
            errorLogger(`AmFam Okta ${error}`);
        }
    }, [
        // setAmfamAccessTokenHeader
    ]);
 
    useEffect(() => {
        getAmfamToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AmfamOktaTokenContext.Provider value={{amfamAccessTokenHeader, opCo, changeOpCo, opCoConfig, isMSAOpCo, isCONNECTOpCo}}>
            {props.children}
        </AmfamOktaTokenContext.Provider>
    )
};

export const AmfamOktaTokenContextProvider = withAuthenticationContext(
    AmfamTokenContextProvider
);

// used to wrap around class components
export function withAmfamOktaTokenContext(WrappedComponent) {
    function WithAmfamOktaTokenContext(props) {
        const amfamOktaTokenProps = useContext(AmfamOktaTokenContext);

        return <WrappedComponent {...amfamOktaTokenProps} {...props} />;
    }

    hoistNonReactStatic(WithAmfamOktaTokenContext, WrappedComponent);

    return WithAmfamOktaTokenContext;
}

export default AmfamOktaTokenContext;