import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { Wizard } from 'e1p-portals-wizard-react';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { QuoteInfoComponentContext } from 'gw-capability-quoteandbind-common-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { MockUpUtil } from '@xengage/gw-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from './EHWizard.messages';

import HoQuoteInfo from './components/HoQuoteInfo/HoQuoteInfo';
import wizardConfig from './config/ho-wizard-config.json5';

const PATH_TO_MOCK_DATA = 'quote.ho';

const ACTION_MSG = {
    cancelAction: {
        title: messages.exitTransaction,
        message: messages.infoWillBeSavedAsDraft,
        messageProps: {
            confirmButtonText: e1pCommonMessages.exitTransactionLabel,
            cancelButtonText: messages.cancelLabel
        }
    },
    previousAction: {
        title: commonMessages.wantToJump,
        message: commonMessages.wantToJumpMessage,
        messageProps: {
            messageProps: {
                ok: commonMessages.yes,
                close: commonMessages.close
            }
        }
    }
};

function setCoverageStartDate() {
    const currentDate = new Date();
    const coverageStartDate = {
        day: currentDate.getDate(),
        month: currentDate.getMonth(),
        year: currentDate.getFullYear()
    };

    return coverageStartDate;
}

function setPrepData(address) {
    const realData = {
        baseData: {
            policyAddress: address,
            periodStartDate: setCoverageStartDate()
        }
    };

    return MockUpUtil.setMockData(realData, PATH_TO_MOCK_DATA);
}

function removeMockData(submission) {
    return MockUpUtil.cleanUpMockedProperties(
        submission,
        PATH_TO_MOCK_DATA,
        'baseData.accountHolder.emailAddress1',
        'baseData.policyAddress.addressLine1',
        'baseData.policyAddress.city',
        'baseData.policyAddress.displayName'
    );
}

function createSubmissionOnPartialAddress(address) {
    const data = setPrepData(address);

    return LoadSaveService.createSubmission(data);
}

function EHWizard(props) {
    const modalApi = useModal();
    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [shouldSkipValidSteps, setShouldSkipValidSteps] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history } = props;

    useEffect(() => {
        const viewModelContext = {
            AccountEmailRequired: true,
            AccountDOBRequired: true
        };

        let submissionPromise;

        if (!viewModelService) {
            history.push('/');

            return;
        }

        if (_.has(location, 'state.address')) {
            const { address } = location.state;

            submissionPromise = createSubmissionOnPartialAddress(address);
        } else if (_.has(location, 'state.submission')) {
            const { submission } = location.state;

            setShouldSkipValidSteps(true);
            submissionPromise = Promise.resolve(submission);
        } else if (_.has(location, 'search')) {
            const parsedParms = queryString.parse(location.search);

            submissionPromise = LoadSaveService.retrieveSubmission(parsedParms);
        } else {
            return;
        }

        submissionPromise.then((response) => {
            const submissionVM = viewModelService.create(
                removeMockData(response),
                'pc',
                'edge.capabilities.quote.submission.dto.QuoteDataDTO',
                viewModelContext
            );

            setInitialSubmission(submissionVM);
            setIsLoading(false);
        });
        // only execute this once per component lifecycle
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCancel = useCallback((wizardProps) => {
        const { wizardSnapshot, wizardData } = wizardProps;
        const latestSnapshot = (wizardData.aspects.subtreeValid && wizardData.aspects.valid)
            ? wizardData : wizardSnapshot;

        const quoteID = _.get(wizardSnapshot, 'quoteID');

        const periodStatus = _.get(latestSnapshot, 'baseData.periodStatus.value.code');

        if (periodStatus === 'Bound') {
            // allow transition once the submission is bound
            return true;
        }

        return modalApi.showConfirm(ACTION_MSG.cancelAction).then((results) => {
            if (results === 'cancel') {
                return _.noop();
            }

            let serverCall = LoadSaveService.updateDraftSubmission;

            if (periodStatus === 'Quoted') {
                serverCall = LoadSaveService.updateQuotedSubmission;
            }

            const wizardDataFromSnapshot = _.get(latestSnapshot, 'value');
            const dataToSave = MockUpUtil.setMockData(wizardDataFromSnapshot, PATH_TO_MOCK_DATA);

            setIsLoading(true);

            const redirectPath = periodStatus ? `/quotes/${quoteID}/summary` : '/';

            return serverCall(dataToSave).then(() => {
                history.push(redirectPath);
            }).catch(() => {
                modalApi.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: commonMessages.unableToSaveQuote,
                    message: commonMessages.unableToSaveQuoteMessage
                });
                history.push('/');
            });
        });
    },
    [modalApi, history]);

    if (isLoading) {
        return (
            <E1PLoader loaded={!isLoading} />
        );
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <QuoteInfoComponentContext.Provider value={HoQuoteInfo}>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                initialData={initialSubmission}
                onCancel={handleCancel}
                skipCompletedSteps={shouldSkipValidSteps}
            />
        </QuoteInfoComponentContext.Provider>
    );
}

EHWizard.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
        state: PropTypes.shape({
            address: PropTypes.shape({}),
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
};

export default EHWizard;
