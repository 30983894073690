import { defineMessages } from 'react-intl';

export default defineMessages({
    historySourceTitle: {
        id: 'historyTable.dtcolumn.source',
        defaultMessage: 'Source'
    },
    historyEventNameTitle: {
        id: 'historyTable.dtcolumn.Event Name',
        defaultMessage: 'Transaction Type'
    },
    historyUserTitle: {
        id: 'historyTable.dtcolumn.user',
        defaultMessage: 'User'
    },
    historyDescriptionTitle: {
        id: 'historyTable.dtcolumn.Description',
        defaultMessage: 'Description'
    },
    historyJobIDHeader: {
        id: 'historyTable.dtcolumn.Work Order',
        defaultMessage: 'Work Order'
    },
    historyEffectiveDateTitle: {
        id: 'historyTable.dtcolumn.effectiveDate',
        defaultMessage: 'Effective Date'
    },
    historyTimestampTitle: {
        id: 'historyTable.dtcolumn.timestamp',
        defaultMessage: 'Event Timestamp'
    },
    historyViewDocument: {
        id: 'historyTable.dtcolumn.Documents',
        defaultMessage: 'Documents'
    },
    viewDocuments: {
        id: 'historyTable.dtcolumn.View',
        defaultMessage: 'View'
    },
    retrieveDocumentMessage: {
        id: 'historyTable.dtcolumn.Retrieve Letter',
        defaultMessage: 'Retrieve Letter'
    },
    retrieveDocumentError: {
        id: 'historyTable.dtcolumn.Document Retrieval Error',
        defaultMessage: 'Document Retrieval Error'
    }
});
