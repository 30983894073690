import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import classNames from 'classnames';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { RewriteService } from 'e1p-capability-rewrite';
import { useTranslator } from '@jutro/locale';
import {
    get as _get,
    set as _set,
    isNil as _isNil
} from 'lodash';
import PropTypes from 'prop-types';
import metadata from './PaymentOptionsComponent.metadata.json5';
import styles from './PaymentOptionsComponent.module.scss';
import messages from './PaymentOptionsComponent.messages';

const getOffering = (submission, LOB) => {
    const lobOfferings = _get(submission, `lobData[${LOB}].offerings`);
    const quoteOfferings = _get(submission, 'quoteData.offeredQuotes', []);
    let selectedOffering = lobOfferings.find(
        (lobOffering) =>
            lobOffering.branchCode ===
            quoteOfferings.find(version => version.selected)?.branchCode
    );

    // Ideally selected offering should be present or if we dont have quoteData;
    if(!selectedOffering){
        // eslint-disable-next-line prefer-destructuring
        selectedOffering = lobOfferings[0];
    }

    return selectedOffering;
};

function PaymentOptionsComponent(props) {
    const {
        submissionVM,
        authHeader,
        updateWizardData,
        setIsCallingService,
        LOB,
        isQuoteStale,
        viewOnly
    } = props;
    const translator = useTranslator();
    const [updatingPaymentOption, setUpdatingPaymentOption] = useState(false);
    const [paymentOptionSelected, setPaymentOptionSelected] = useState('');
    const [monthlyMessage, setMonthlyMessage] = useState('');
    const [fullPayMessage, setFullPayMessage] = useState('');
    const TPI_FULL_PAY = 'af:tpiFullPayR1';
    const PAY_FULL = 'af:payAFTFull';
    const PAY_MONTHLY = 'af:payMthly3';
    const [paymentOptionUpdated, setPaymentOptionUpdated] = useState(false);
    const isRewriteFullTermJob = _get(submissionVM, 'value.baseData.businessTransactionType_Ext') === 'FullTerm';
    const paymentCheckBoxStyles = classNames({
        [styles.disablePayment]: isRewriteFullTermJob
    });

    const triggerPaymentusChange = useCallback((paymentPlan) => {
        setIsCallingService(true);
        setUpdatingPaymentOption(true);
        _set(
            submissionVM,
            'selectedPaymentPlan_Ext.value',
            paymentPlan
        );

        let serverCall = LoadSaveService.changePaymentPlans;

        if (_get(submissionVM, '_dtoName') === 'amfam.edge.capabilities.rewrite.dto.RewriteDataDTO') {
            serverCall = RewriteService.changePaymentPlan;
        }

        // if transactionType is rewrite, then change servercall
        serverCall(
            _get(submissionVM, 'value'),
            authHeader
        ).then((response) => {
            const correctOffering = getOffering(_get(submissionVM, 'value'), LOB);
            const offerringFromResponse = getOffering(response, LOB);
            const quoteDataIndex = _get(submissionVM, `lobData[${LOB}].offerings.value`).findIndex(
                (qdOffering) => qdOffering.branchCode === correctOffering.branchCode
            );

            _set(submissionVM, `lobData[${LOB}].offerings.children[${quoteDataIndex}].paymentPlans.value`, offerringFromResponse.paymentPlans);

            // E1PAP1PC-9447: Coverages were not getting updated on payment plan change
            _set(submissionVM, `lobData[${LOB}].offerings.children[${quoteDataIndex}].coverages.value`, offerringFromResponse.coverages);
            // E1PAP1PC-12834 : updating modifiers according to the selected
            // payment plan and also the rate ID
            _set(submissionVM, `lobData[${LOB}].modifiers`, response.lobData[LOB].modifiers);
            _set(submissionVM, 'baseData.ratingServiceKey_Ext.value', response.baseData.ratingServiceKey_Ext);
            // Setting this flag to true allows us to call fetch quote proposal in the coverage page
            // This enables us to call fetch quote proposal whenever a payment plan changes
            _set(submissionVM, 'paymentOptionChanged', true);

            const licensedProducerID = _get(submissionVM, 'value.bindData.licensedProducerID');

            if (!_isNil(_get(submissionVM, 'value.bindData'))) {
                const licensedProducer = _get(submissionVM, 'bindData.licensedProducer_Ext')
                    || _get(submissionVM, 'bindData.value.licensedProducerID');

                _set(response, 'bindData.licensedProducer_Ext', licensedProducer);
                _set(response, 'bindData.licensedProducerID', licensedProducer);
                _set(submissionVM, 'bindData.value', _get(response, 'bindData'));
            }

            // Setting licensed prodcuer id id present
            // as we had already selected/defaulted licensed producerid
            if (licensedProducerID) {
                _set(submissionVM, 'value.bindData.licensedProducerID', licensedProducerID);
            }

            updateWizardData(submissionVM);
            setPaymentOptionSelected(paymentPlan);
            setIsCallingService(false);
            setPaymentOptionUpdated(!paymentOptionUpdated);
            setUpdatingPaymentOption(false);
        }).finally(() => {
            setIsCallingService(false);
            setUpdatingPaymentOption(false);
        });
    }, [
        setIsCallingService,
        submissionVM,
        authHeader,
        LOB,
        updateWizardData,
        paymentOptionUpdated
    ]);

    const availableValuesForPaymentOptions = (() => {
        const correctOffering = getOffering(_get(submissionVM, 'value'), LOB);

        if (correctOffering?.paymentPlans !== undefined) {
            return correctOffering.paymentPlans
                .filter((paymentPlans) => paymentPlans.name)
                .reduce((paymentPlanMap, paymentPlan) => ({
                    ...paymentPlanMap,
                    [paymentPlan.billingId]: paymentPlan
                }), {});
        }

        return {};
    })();

    const convertToTPIPayorPresent = useMemo(() => {
        const additionalInterests = _get(
            submissionVM,
            'lobData.homeowners_EH.coverables.yourHome.additionalInterests',
            []
        );
        // if any add. int. has conver to payor indicator set, then convert to payer as present.
        const shouldConvertTpi = additionalInterests
            .filter((tpi) => tpi.convertTPIToPayerInd?.value === true)
            .length > 0;

        if (shouldConvertTpi) {
            return true;
        }

        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentOptionUpdated, isQuoteStale]);

    const onPaymentRadioButtonValueChange = useCallback((value) => {
        if (!isQuoteStale) {
            if (value === PAY_MONTHLY
                && !_isNil(availableValuesForPaymentOptions[PAY_MONTHLY])
                && !availableValuesForPaymentOptions[PAY_MONTHLY].isSelected) {
                triggerPaymentusChange(PAY_MONTHLY);
            } else if (value === PAY_FULL
                && !_isNil(availableValuesForPaymentOptions[PAY_FULL])
                && !availableValuesForPaymentOptions[PAY_FULL].isSelected) {
                triggerPaymentusChange(PAY_FULL);
            }
        }
    }, [availableValuesForPaymentOptions, isQuoteStale, triggerPaymentusChange]);

    const resolvers = {
        resolveClassNameMap: styles,
    };

    useEffect(() => {
        const paymentOptions = {
            monthly: false,
            fullPay: false
        };

        paymentOptions.fullPay = (convertToTPIPayorPresent[PAY_MONTHLY]
            || !_isNil(availableValuesForPaymentOptions[PAY_FULL]))
            ? availableValuesForPaymentOptions[PAY_FULL].isSelected : undefined;

        paymentOptions.monthly = (!convertToTPIPayorPresent[PAY_MONTHLY]
            && !_isNil(availableValuesForPaymentOptions[PAY_MONTHLY]))
            ? availableValuesForPaymentOptions[PAY_MONTHLY].isSelected : undefined;

        setPaymentOptionSelected(paymentOptions.fullPay ? PAY_FULL : PAY_MONTHLY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const numberWithCommas = (x) => {
            const pattern = /(-?\d+)(\d{3})/;

            while (pattern.test(x)) {
                // eslint-disable-next-line no-param-reassign
                x = x.replace(pattern, '$1,$2');
            }

            return x;
        };
        const fullPay = !_isNil(availableValuesForPaymentOptions[PAY_FULL])
            ? availableValuesForPaymentOptions[PAY_FULL].total.amount : '';
        const monthly = !_isNil(availableValuesForPaymentOptions[PAY_MONTHLY])
            ? availableValuesForPaymentOptions[PAY_MONTHLY].total.amount : '';
        const strings = {
            fullPay: fullPay.toString(),
            monthly: monthly.toString()
        };

        if (strings.fullPay && strings.fullPay.indexOf('.') === strings.fullPay.length - 2) {
            strings.fullPay = `${strings.fullPay}0`;
        }

        strings.fullPay = numberWithCommas(strings.fullPay);

        if (strings.monthly && strings.monthly.indexOf('.') === strings.monthly.length - 2) {
            strings.monthly = `${strings.monthly}0`;
        }

        strings.monthly = numberWithCommas(strings.monthly);

        const fullPayAmount = isQuoteStale ? '-' : `$${strings.fullPay}`;
        const monthlyPayAmount = isQuoteStale ? '-' : `$${strings.monthly}`;

        setFullPayMessage(`${messages.fullPay.defaultMessage} ${fullPayAmount}`);
        setMonthlyMessage(`${messages.monthly.defaultMessage} ${monthlyPayAmount}`);

    }, [availableValuesForPaymentOptions, isQuoteStale]);

    const overrideProps = {
        '@field': {
            readOnly: viewOnly,
            autoComplete: false
        },
        paymentOptionsRadio: {
            readOnly: viewOnly || isRewriteFullTermJob || convertToTPIPayorPresent[PAY_MONTHLY],
            availableValues: [
                {
                    code: PAY_FULL,
                    name: fullPayMessage
                },
                {
                    code: PAY_MONTHLY,
                    name: monthlyMessage
                }],
            value: paymentOptionSelected,
            onValueChange: onPaymentRadioButtonValueChange
        },
        payInFullOrMonthly: {
            visible: _isNil(availableValuesForPaymentOptions[TPI_FULL_PAY]),
        },
        tpiFullPayContainer: {
            visible: !_isNil(availableValuesForPaymentOptions[TPI_FULL_PAY]),
        },
        tpiFullPay: {
            onValueChange: () => { },
            value: !_isNil(availableValuesForPaymentOptions[TPI_FULL_PAY])
                ? availableValuesForPaymentOptions[TPI_FULL_PAY].isSelected : undefined,
            readOnly: viewOnly || isRewriteFullTermJob,
            controlClassName: paymentCheckBoxStyles
        },
        tpiFullPayAmount: {
            readOnly: true,
            value: !_isNil(availableValuesForPaymentOptions[TPI_FULL_PAY])
                ? availableValuesForPaymentOptions[TPI_FULL_PAY].total : undefined,
            visible: !isQuoteStale
        },
        changingPaymentPlanLoader: {
            loaded: !updatingPaymentOption,
            text: translator(messages.changingPaymentPlans)
        },
        payInFullOrMonthlyOuter: {
            visible: !updatingPaymentOption
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={submissionVM}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PaymentOptionsComponent.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    LOB: PropTypes.string.isRequired,
    updateWizardData: PropTypes.func,
    setIsCallingService: PropTypes.func,
    authHeader: PropTypes.shape({}).isRequired,
    isQuoteStale: PropTypes.bool,
    viewOnly: PropTypes.bool
};

PaymentOptionsComponent.defaultProps = {
    isQuoteStale: false,
    setIsCallingService: () => { },
    viewOnly: false
};
export default PaymentOptionsComponent;
