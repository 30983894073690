import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, withModalContext } from '@jutro/components';
import { Button } from '@jutro/legacy/components';
import _ from 'lodash';
import metadata from './UWIssueDetail.metadata.json5';
import styles from './UWIssueDetail.module.scss';
import messages from './UWIssueDetail.messages';



const UWIssueDetail = (props) => {
    const translator = useTranslator();
    const {
        uwIssueVM,
        title,
        viewModelService,
        isOpen,
        onResolve,
        onReject
    } = props;
    const [uwFormData, updateUWFormData] = useState(uwIssueVM);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const displayAsThroughValue = {
        BlocksQuote: translator(messages.none),
        BlocksRateRelease: translator(messages.quote),
        BlocksQuoteRelease: translator(messages.rateRelease),
        BlocksBind: translator(messages.quoteRelease),
        BlocksIssuance: translator(messages.bind),
        NonBlocking: translator(messages.issuance),
    };

    const {
        isComponentValid,
        onValidate
    } = useValidation('uwIssueDetails');

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = viewModelService.clone(uwFormData);

            _.set(nextFormData, path, value);
            updateUWFormData(nextFormData);
        },
        [uwFormData, viewModelService]
    );

    const approveHandler = useCallback(() => {
        const wrapperObj = {
            functionToCall: 'approveUWIssue',
            issueVM: uwFormData
        };

        onResolve(wrapperObj);
    }, [onResolve, uwFormData]);

    const cancelHandler = useCallback((evt) => {
        evt.stopPropagation();
        onReject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const approvalBlockingPointValueRange = useMemo(
        () =>
            uwIssueVM.approvalBlockingPointValueRange_Ext.value.map(item => ({
                code: item,
                name: displayAsThroughValue[item] ?? item
            })),
        [
            displayAsThroughValue,
            uwIssueVM.approvalBlockingPointValueRange_Ext.value,
        ]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            approveHandler,
            cancelHandler
        }
    };
    const overrideProps = {
        approvalTypeID: {
            defaultValue: 'AsIs'
        },
        through: {
            defaultValue: 'NonBlocking',
            availableValues: approvalBlockingPointValueRange
        },
        validUntil: {
            defaultValue: 'Rescinded'
        },
    };

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={title} className={styles.modalTitle} />
            <ModalBody className={styles.modalWidth}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={uwFormData}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    onValidationChange={onValidate}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={cancelHandler} type="outlined">
                    {'Cancel'}
                </Button>
                <Button onClick={approveHandler} disabled={!isComponentValid}>
                    {'Ok'}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
};

UWIssueDetail.propTypes = {
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    uwIssueVM: PropTypes.shape({
        approvalBlockingPointValueRange_Ext: PropTypes.shape({
            value: PropTypes.arrayOf({})
        })
    }).isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    title: PropTypes.string
};
UWIssueDetail.defaultProps = {
    title: ''
};
export default withModalContext(UWIssueDetail);
