import React, {
    useCallback
} from 'react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { E1PEHHO6PropertyDetailsComponent } from 'e1p-capability-policyjob-react';

function PropertyPage(props) {
    const { wizardData: rewriteVM } = props;

    const onNext = useCallback(
        () => rewriteVM,
        [rewriteVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <E1PEHHO6PropertyDetailsComponent
                onValidate={() => {}}
                transactionVM={rewriteVM}
                updateWizardData={() => {}}
                isPageSubmitted={false}
                setShouldSkipAdditionalInfo={() => {}}
                isSavingCurrentPageChanges={false}
                isSavingQuote={false}
                heatingSystemType={() => {}}
                updateHeatingSystemType={() => {}}
                viewOnlyMode
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
