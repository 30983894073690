import React, { useEffect, useState, useCallback } from 'react';
import {
    get as _get,
    includes as _includes,
    noop as _noop,
    set as _set,
    toLower as _toLower
} from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { Wizard } from 'e1p-portals-wizard-react';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { error as loggerError } from '@jutro/logger';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useSubmissionCreation } from 'e1p-capability-hooks';
import { OnPageOperationsUtil } from 'e1p-portals-util-js';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import messages from './PEEHWizard.messages';
import unverifiedWizardConfig from './config/unverified/HO3/eh-wizard-config.json5';
import wizardStepToFieldMapping from './config/common/eh-wizard-step-to-field-mapping.json5';
import ho6UnverifiedWizardConfig from './config/unverified/HO6/eh-wizard-config.json5';
import verifiedWizardConfig from './config/verified/HO3/eh-wizard-config.json5';
import ho6VerifiedWizardConfig from './config/verified/HO6/eh-wizard-config.json5';
import ho3VerifiedViewWizardConfig from './config/view-only/verified/HO3/eh-wizard-config.json5';
import ho3UnVerifiedViewWizardConfig from './config/view-only/unverified/HO3/eh-wizard-config.json5';
import ho6VerifiedViewWizardConfig from './config/view-only/verified/HO6/eh-wizard-config.json5';
import ho6UnVerifiedViewWizardConfig from './config/view-only/unverified/HO6/eh-wizard-config.json5';
import ho4UnverifiedWizardConfig from './config/unverified/HO4/eh-wizard-config.json5';
import ho4VerifiedViewWizardConfig from './config/view-only/verified/HO4/eh-wizard-config.json5';
import ho4UnVerifiedViewWizardConfig from './config/view-only/unverified/HO4/eh-wizard-config.json5';
import hf9UnverifiedWizardConfig from './config/unverified/HF9/eh-wizard-config.json5';
import hf9VerifiedViewWizardConfig from './config/view-only/verified/HF9/eh-wizard-config.json5';
import hf9UnVerifiedViewWizardConfig from './config/view-only/unverified/HF9/eh-wizard-config.json5';
import ho4VerifiedWizardConfig from './config/verified/HO4/eh-wizard-config.json5';
import hf9VerifiedWizardConfig from './config/verified/HF9/eh-wizard-config.json5';


function PEEHWizard(props) {
    const modalApi = useModal();
    const [wizardConfig, setWizardConfig] = useState(unverifiedWizardConfig);
    const { authHeader } = useAuthentication();
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { viewModelService, history, location } = props;
    const [transitionSubmission, setTransitionSubmission] = useState({});
    const [isTransitioningToFull, setisTransitioningToFull] = useState(false);
    const [shouldSkipAdditionalInfo, setShouldSkipAdditionalInfo] = useState(false);
    const [viewOnly, setViewOnly] = useState(false);
    const parsedParameters = queryString.parse(location.search);
    const { policyType } = parsedParameters;
    const { onPageCancel, onPagePrevious } = OnPageOperationsUtil();
    const { initializeSubmission } = useSubmissionCreation(
        parsedParameters,
        authHeader,
        viewModelService,
        setInitialSubmission,
        location,
        setIsLoading,
        history
    );

    const policyTypeMapUnverified = {
        HO3: {
            wizardConfig: unverifiedWizardConfig
        },
        HO6: {
            wizardConfig: ho6UnverifiedWizardConfig
        },
        HO4: {
            wizardConfig: ho4UnverifiedWizardConfig
        },
        HF9: {
            wizardConfig: hf9UnverifiedWizardConfig
        }
    };
    const policyTypeMapVerified = {
        HO3: {
            wizardConfig: verifiedWizardConfig
        },
        HO6: {
            wizardConfig: ho6VerifiedWizardConfig
        },
        HO4: {
            wizardConfig: ho4VerifiedWizardConfig
        },
        HF9: {
            wizardConfig: hf9VerifiedWizardConfig

        }
    };

    const policyTypeMapViewOnlyVerified = {
        HO3: {
            wizardConfig: ho3VerifiedViewWizardConfig
        },
        HO4: {
            wizardConfig: ho4VerifiedViewWizardConfig
        },
        HO6: {
            wizardConfig: ho6VerifiedViewWizardConfig
        },
        HF9: {
            wizardConfig: hf9VerifiedViewWizardConfig
        }
    };

    const policyTypeMapViewOnlyUnVerified = {
        HO3: {
            wizardConfig: ho3UnVerifiedViewWizardConfig
        },
        HO4: {
            wizardConfig: ho4UnVerifiedViewWizardConfig
        },
        HO6: {
            wizardConfig: ho6UnVerifiedViewWizardConfig
        },
        HF9: {
            wizardConfig: hf9UnVerifiedViewWizardConfig
        }
    };

    const { steps, title } = wizardConfig;

    useEffect(
        () => {
            if (!isTransitioningToFull) {
                initializeSubmission();
            } else {
                if (
                    location.shouldSkipAdditionalInfo
                    || _get(initialSubmission, 'value.quoteType') === 'Full'
                ) {
                    setShouldSkipAdditionalInfo(true);
                }

                _set(transitionSubmission, 'transitioning', true);
                setInitialSubmission(transitionSubmission);
                setWizardConfig(
                    policyTypeMapVerified[policyType]
                        ? policyTypeMapVerified[policyType].wizardConfig
                        : verifiedWizardConfig
                );
                setIsLoading(false);
            }
        },
        // Disabled so we don't rerun this function on every re-render
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isTransitioningToFull]
    );

    useEffect(
        () => {
            const quoteType = _get(initialSubmission, 'value.quoteType');

            if (location.viewOnly) {
                let viewOnlyWizard = policyTypeMapViewOnlyVerified[policyType]
                    ? policyTypeMapViewOnlyVerified[policyType].wizardConfig
                    : ho3VerifiedViewWizardConfig;

                if (quoteType === 'Quick') {
                    viewOnlyWizard = policyTypeMapViewOnlyUnVerified[policyType]
                        ? policyTypeMapViewOnlyUnVerified[policyType].wizardConfig
                        : ho3UnVerifiedViewWizardConfig;
                }

                setWizardConfig(
                    viewOnlyWizard
                );
                setViewOnly(true);
            } else if (quoteType === 'Full') {
                setWizardConfig(
                    policyTypeMapVerified[policyType]
                        ? policyTypeMapVerified[policyType].wizardConfig
                        : verifiedWizardConfig
                );
                // isSkpping prop is taking care of loading indicator on Policy Details Page
                _set(initialSubmission, 'transitioning', true);
            } else if (!isTransitioningToFull && quoteType === 'Quick') {
                // If not switching to full and still in quick quote
                // Important to note, we can now be in the full quote without
                //   QuoteType equal to full.
                setWizardConfig(
                    policyTypeMapUnverified[policyType]
                        ? policyTypeMapUnverified[policyType].wizardConfig
                        : unverifiedWizardConfig
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [initialSubmission]
    );

    const handleFinish = useCallback(
        ({ wizardData, params }) => {
            setIsLoading(true);
            history.push({
                pathname: '/quote-eh',
                search: `?productCode=Homeowners_EH&policyType=${wizardData.lobData.homeowners_EH.policyType.value.code}`,
                shouldSkipAdditionalInfo: params?.shouldSkipAdditionalInfo || false,
                isRouteChangedDuringFlow: true
            });
            setisTransitioningToFull(true);
            setTransitionSubmission(wizardData);
        },
        [history]
    );

    const handleCancel = useCallback(
        (cancelProps) => {
            const {
                wizardSnapshot,
                param = {},
                wizardData
            } = cancelProps;
            const { search, pathname: nextPath = '' } = param;
            const lastPathName = nextPath.replace(/\/?.*\//g, '');
            const skipModal = ['knockoutpage', 'logout', 'summary'];
            const isStatusBound = _get(wizardSnapshot, 'baseData.periodStatus.value.code') === 'Bound';
            const isCreatingNewQuote = nextPath?.includes('/quote-');

            if (
                (isStatusBound && !isCreatingNewQuote)
                || skipModal.includes(lastPathName)
                || hasErrorOccurred
                || (viewOnly && !isCreatingNewQuote)
            ) {
                return true;
            }

            // When user tries to create new quote from submission view flow for same lob
            if (viewOnly && isCreatingNewQuote) {
                return Promise.resolve(history.push({
                    pathname: '/new-quote-redirect',
                    search,
                    originalPath: nextPath
                }));
            }

            return Promise.resolve(onPageCancel(wizardData.value, wizardSnapshot.value)
                .then((popupResult) => {
                    if (!popupResult || popupResult === 'cancel') {
                        return _noop();
                    }

                    setIsLoading(true);

                    const isSubmissionExisting = _get(
                        wizardSnapshot,
                        'baseData.periodStatus.value.code'
                    );
                    const redirectPath = isSubmissionExisting
                        ? `/quotes/${_get(wizardData, 'value.quoteID', '')}/summary`
                        : '/';

                    if (nextPath?.includes('/quote-')) {
                        /**
                         * IAP-336
                         * When user tries to create new quote from existing quote flow
                         * If user tries for same lob then as url is same we wont mount
                         * specific lob wizard again so to do that redirecting to dummy url
                         * and from there we are directing to actual url
                         */
                        return history.push({
                            pathname: '/new-quote-redirect',
                            search,
                            originalPath: nextPath
                        });
                    }

                    return history.push(nextPath || redirectPath);
                }).catch(_noop()));
        },
        [hasErrorOccurred, history, onPageCancel, viewOnly]
    );

    const handlePrevious = useCallback((previousProps) => {
        const {
            wizardData, wizardSnapshot, updateWizardData, cloneData
        } = previousProps;

        return Promise.resolve(
            onPagePrevious(wizardData, wizardSnapshot, updateWizardData, cloneData, viewOnly)
        );
    }, [onPagePrevious, viewOnly]);

    const handleError = useCallback(
        (error) => {
            const isQuotePage = _includes(_toLower(_get(error, 'gwInfo.method')), 'quote');
            const quoteIDFromWizard = _get(error, 'gwInfo.params[0].quoteID');
            const quoteIDFromLocation = _get(location, 'state.quoteentry.quoteID');
            const quoteIDFromSubmission = _get(initialSubmission, 'value.quoteID');
            const quoteID = quoteIDFromWizard || quoteIDFromLocation || quoteIDFromSubmission;
            const pathname = _get(location, 'pathname');
            const redirectPath = `/quotes/${quoteID}/summary`;
            const state = {
                pathname: redirectPath,
                state: error
            };

            loggerError(`MSA-EHWizard-Error: ${error}
            Location Pathname: ${pathname}`);

            return modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: isQuotePage ? messages.saveQuoteError : messages.anErrorOccurredTitle,
                message: isQuotePage ? messages.saveQuoteErrorMessage : messages.anErrorOccurred
            }).then(() => {
                setHasErrorOccurred(true);

                return <Redirect to={state} />;
            });
        },
        [initialSubmission, location, modalApi]
    );

    if (isLoading) {
        return (
            <E1PLoader
                loaded={!isLoading}
            />
        );
    }

    if (!initialSubmission) {
        return null;
    }

    if (parsedParameters.experienceID) {
        initialSubmission.baseData.experienceID_Ext = parsedParameters.experienceID;
    }

    return (
        <ErrorBoundary onError={handleError}>
            {/* Hidden field needed for GA on each wizard page */}
            <p id="sourceOfEntry" style={{ display: 'none' }}>
                {_get(
                    initialSubmission,
                    'baseData.quoteSource_Ext.sourceType.value.code',
                    'directentry'
                )}
            </p>
            <Wizard
                initialSteps={steps}
                wizardTitle={title}
                viewOnly={viewOnly}
                initialData={initialSubmission}
                onCancel={handleCancel}
                onFinish={handleFinish}
                onPrevious={handlePrevious}
                skipCompletedSteps
                wizardStepToFieldMapping={wizardStepToFieldMapping}
                isPageJumpEnabled={viewOnly}
                onPreviousModalProps={{
                    title: commonMessages.wantToJump,
                    message: commonMessages.wantToJumpMessage,
                    messageProps: {
                        ok: commonMessages.yesModel,
                        close: commonMessages.cancelModel
                    }
                }}
                skipAdditionalInfo={shouldSkipAdditionalInfo}
            />
        </ErrorBoundary>
    );
}

PEEHWizard.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            quoteentry: PropTypes.shape({
                postalCode: PropTypes.string,
                quoteID: PropTypes.string
            })
        }),
        search: PropTypes.string,
        viewOnly: PropTypes.bool,
        shouldSkipAdditionalInfo: PropTypes.bool
    }).isRequired
};

export default withViewModelService(PEEHWizard);
