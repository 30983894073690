import React from 'react';
import { FieldComponent } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { Link } from '@jutro/router';
import styles from './E1PFieldLinkComponent.module.scss';



class E1PFieldLinkComponent extends FieldComponent {
    static contextType = TranslatorContext;

    static propTypes = {
        ...FieldComponent.propTypes,
    };

    renderControl() {
        const { value, to, id } = this.props;

        return (
            <Link className={styles.fieldLinkColor} to={to} id={id}>
                {value}
            </Link>
        );
    }

    render() {
        return super.render();
    }
}

export default E1PFieldLinkComponent;
