import { defineMessages } from 'react-intl';

export default defineMessages({
    UnderwritingNotInHouseholdDriverLabel: {
        id: 'quoteandbind.ea.views.ea-RiskAnalysisMisc.Driver Label',
        defaultMessage: 'Driver'
    },
    UnderwritingNotInHouseholdGenderLabel: {
        id: 'quoteandbind.ea.views.ea-RiskAnalysisMisc.Gender Label',
        defaultMessage: 'Gender'
    },
    UnderwritingNotInHouseholdStatusLabel: {
        id: 'quoteandbind.ea.views.ea-RiskAnalysisMisc.Status Label',
        defaultMessage: 'Status'
    },
    UnderwritingNotInHouseholdNotInHouseHoldLabel: {
        id: 'quoteandbind.ea.views.ea-RiskAnalysisMisc.Not in Household Drivers Label',
        defaultMessage: 'Not in Household Drivers'
    },
});
