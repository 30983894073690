import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Route, Redirect, withRouter, useHistory
} from 'react-router-dom';

const CommonRoutes = (props) => {
    const { steps, basePath, location: { pathname: origPathName, search, state } } = props;
    const history = useHistory();

    useEffect(() => {
        const modifiedPathname = origPathName.replace(/\/\s*$/, "")

        history.push({ pathname: modifiedPathname, search, state });
    }, [history, origPathName, search, state])

    const pathname = origPathName.replace(/\/\s*$/, "");

    const isPathMatch = pathname ? steps.some((step) => [basePath, step.path].join('') === pathname) : true;
    // const isPathMatch = pathname ? steps.some((step) => [basePath, step.path].join('') === pathname) : true;
    const routes = isPathMatch ? steps.map(({ path, component, componentPath }) => {
        const composedPath = [basePath, path].join('');
        const Component = component || require(`../${componentPath}`).default;

        return (
            <Route
                key={composedPath}
                path={composedPath}
                render={
                    (renderProps) => (
                        <Component {...renderProps} fromAccountLanding={props} {...props} />
                    )
                }
            />
        );
    }) : <Route render={() => <Redirect to="/" />} />;

    return (
        <div>
            {routes}
        </div>
    );
};

CommonRoutes.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        path: PropTypes.string,
    })).isRequired,
    basePath: PropTypes.string.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string
    }).isRequired,
};
export default withRouter(CommonRoutes);
