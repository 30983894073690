import React, {
    useCallback
} from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import metadata from './EUPreQualificationPage.metadata.json5';

function ViewEUPreQualificationPage(props) {
    const { wizardData: policyViewVM } = props;

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(async () => policyViewVM, [policyViewVM]);

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {

        '@field': {
            readOnly: true
        },
        preQualificationPageLoadingIndicator: {
            loaded: true
        },
        euPreQualificationComponent: {
            data: policyViewVM,
            updateWizardData: () => {},
            showErrors: false,
            viewOnlyMode: true,
            onValidate: () => {}
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyViewVM}
                overrideProps={overrideProps}
            />
        </WizardPage>
    );
}

ViewEUPreQualificationPage.propTypes = wizardProps;
export default withRouter(ViewEUPreQualificationPage);
