
/**
 * Define custom job types here. 
 * You should define the type and a redirect function to append the corresponding
 * job number
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const jobTypes = [

    {
        type: 'Submission',
        redirectPath: (jobNumber) => `/quotes/${jobNumber}/summary`
    },
    {
        type: 'PolicyChange',
        redirectPath: (jobNumber) => `/change/${jobNumber}/summary`
    },
    {
        type: 'Cancellation',
        redirectPath: (jobNumber) => `/cancellation/${jobNumber}/summary`
    },
    {
        type: 'Renewal',
        redirectPath: (jobNumber) => `/renewal/${jobNumber}/summary`
    },
    {
        type: 'Rewrite',
        redirectPath: (jobNumber) => `/rewrite/view/${jobNumber}/summary`
    },
    {
        type: 'Reinstatement',
        redirectPath: (jobNumber) => `/reinstatement/view/${jobNumber}/summary`
    }
];

module.exports = {
    /**
     * Function for handling the redirectPath from outside JobUtil
     * The called baked into JobUtil takes the following parameters
     * And should return a string
     * @param {String} type 
     * @param {String} jobNumber 
     * @returns {String}
     */
    getRedirectPath: (type, jobNumber) => {
        const selectedJob = jobTypes.find((jobType) => jobType.type === type);
        if (selectedJob === undefined) {
            return '/';
        }
        return selectedJob.redirectPath(jobNumber);
    }     
}