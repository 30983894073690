import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    noop as _noop,
    uniq as _uniq
} from 'lodash';
import {
    useModal,
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { usePriorPolicyUpdateUtil, useUWIssueUtil } from 'e1p-capability-hooks';
import { AutoLossService, PriorCarrierService } from 'e1p-capability-gateway';
import { TimelineService } from 'e1p-capability-journey';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import metadata from './RiskAnalysisPage.metadata.json5';
import messages from './RiskAnalysisPage.messages';
import requiredMetadata from './RiskAnalysisPage.requiredness';

function RiskAnalysisPage(props) {
    const modalApi = useModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        steps,
        authUserData,
        jumpTo,
        isSkipping,
        lastVisitedRiskAnalysisTab,
        setLastVisitedRiskAnalysisTab,
        updateWizardSnapshot
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [isQuoting, setIsQuoting] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [creditReportResponse, setCreditReportResponse] = useState({});
    const [dataForComponent, updateDataForComponent] = useState({});
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const [overlappingException, setOverlappingException] = useState(false);
    const [isRetrievingReports, setIsRetrievingReports] = useState(false);
    const [isLossReportNoHit, setIsLossReportNoHit] = useState(false);
    const [priorCarrierChanged, setPriorCarrierChanged] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [visibleFields, updateVisibleFields] = useState([]);
    const [showPrayerForJudgementError, setShowPrayerForJudgementError] = useState(false);
    const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');
    const [manualLossList, setManualLossList] = useState([]);
    const [activeTab, updateActiveTab] = useState('');
    const {opCo} = useContext(AmfamOktaTokenContext);

    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        registerComponentValidation,
        registerInitialComponentValidation
    } = useValidation('RiskAnalysisPage');
    const {
        modifyDates
    } = usePriorPolicyUpdateUtil(submissionVM.lobData.personalAuto_EA.priorPolicyUpdates);
    const {
        hasUWIssues
    } = useUWIssueUtil(
        submissionVM,
        updateWizardData,
        jumpTo,
        steps
    );

    useEffect(() => {
        const initialVisibleFields = ['declareLossStatementThreeYears', 'declareLossStatementFiveYears']; // Fields to look up by partner/state

        updateVisibleFields(
            isRequired(initialVisibleFields, requiredMetadata, policyState, opCo)
        );
        // User can't able to change policy state on Risk Analysis Page,
        // hence we need to run this useEffect only single time.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opCo]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(submissionVM, 'lobData.personalAuto_EA.premiumStability.value') !== undefined;

    const isPageValid = useCallback(() => !overlappingException && !isLossReportNoHit
        , [overlappingException, isLossReportNoHit]);

    const uwIssuesPresent = useMemo(() =>
        hasUWIssues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [submissionVM?.errorsAndWarnings?.underwritingIssues?.value]);

    const createAutoLossVM = useCallback((vmObject, dtoName) => {
        const vmNode = viewModelService.create(
            vmObject,
            'pc',
            dtoName
        );

        return vmNode;
    }, [viewModelService]);

    useEffect(() => {
        if (!isSkipping) {
            // calling loadAutoLosses only in full app mode
            if (_get(submissionVM, 'value.quoteType') === 'Full') {
                setIsRetrievingReports(true);

                const historyPromise = TimelineService.getTimeline(
                    {
                        policyNumber: _get(submissionVM, 'value.policyNumber'),
                        relatedItem: 'Policy'
                    },
                    authHeader
                );

                historyPromise.then((response) => {
                    const lossHistoryEvent = response.events.find((event) => event.description?.includes('Self-Declared'))?.description;

                    if (lossHistoryEvent) {
                        setManualLossList(lossHistoryEvent.split("\n"));
                    }
                })

                const AutoLossRecordsPromise = AutoLossService.loadAutoLosses(
                    _get(submissionVM, 'quoteID.value'),
                    authHeader
                );

                AutoLossRecordsPromise
                    .then((response) => {
                        const autoLossRecords = [];
                        const filteredAutoLossRecords = response.autoLossRecords
                            .filter((loss) => loss.source?.sourceType !== 'Self-Declared');

                        filteredAutoLossRecords.forEach((result) => {
                            const recordVM = createAutoLossVM(
                                result,
                                'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoLossRecordDTO'
                            );

                            autoLossRecords.push(recordVM.value);
                        });

                        const autoViolationRecords = [];
                        const filteredAutoViolationRecords = response.autoViolationRecords
                            .filter((violation) => violation.source?.sourceType !== 'Self-Declared');

                        filteredAutoViolationRecords.forEach((result) => {
                            const violationVM = createAutoLossVM(
                                result,
                                'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoViolationRecordDTO'
                            );

                            _set(
                                violationVM,
                                'value.operatorDisplayName',
                                `${violationVM.value.assignment.firstName} ${violationVM.value.assignment.lastName}`
                            );
                            autoViolationRecords.push(violationVM.value);
                        });

                        _set(dataForComponent, 'lobData.personalAuto_EA.autoLossRecords.value', autoLossRecords); 
                        _set(dataForComponent, 'lobData.personalAuto_EA.autoViolationRecords.value', autoViolationRecords);
                        _set(dataForComponent, 'lobData.personalAuto_EA.mvrLicenseStatusRecords.value', response.mvrlicenseStatus);
                        _set(dataForComponent, 'lobData.personalAuto_EA.orderingInfo.value', response.orderingInfo);
                        updateDataForComponent(dataForComponent);
                    })
                    .catch(() => {
                        modalApi.showAlert({
                            status: 'error',
                            icon: 'mi-error-outline',
                            title: messages.reportsErrorTitle,
                            message: messages.reportsErrorMessage
                        });
                        setIsLossReportNoHit(true);
                    })
                    .finally(() => {
                        setIsRetrievingReports(false);
                    });
            }
        }
        // No array dependencies needed in this hook.
        // The logic of initializing losses data needs to be executed only once
        // when landing into losses page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping]);

    useEffect(() => {
        // We can skip page (on continue) to covs page if there hasn't been a quote yet
        registerInitialComponentValidation(() =>  _get(submissionVM, 'baseData.periodStatus.value.code') === 'Quoted' || !!_get(submissionVM, 'value.quoteData'));
        // register validation for disabling next button
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, registerInitialComponentValidation, submissionVM]);

    const onNext = useCallback(
        async () => {
            if (!isComponentValid || showPrayerForJudgementError) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsQuoting(true);
            modifyDates(submissionVM);

            if (priorCarrierChanged) {
                const priorPolicies = _get(submissionVM, 'lobData.personalAuto_EA.priorPolicies.value');
                const jobNumber = _get(submissionVM, 'quoteID.value') ?? _get(submissionVM, 'jobID.value');
                const priorCarrierPolicyDetailsDataDTO = { jobNumber, priorPolicies }

                try {
                    const priorCarrierResponse = await PriorCarrierService
                        .savePriorCarrierPolicies(priorCarrierPolicyDetailsDataDTO, authHeader);

                    // We can't continue to quote with errors, so exit function
                    if (!_isEmpty(_get(priorCarrierResponse, 'exceptions_Ext', []))) {
                        _set(submissionVM, 'baseData.value.exceptions_Ext', priorCarrierResponse.exceptions_Ext);
                        updateWizardData(submissionVM);

                        return false
                    }

                    _set(submissionVM, 'lobData.personalAuto_EA.priorPolicies', priorPolicies);
                } catch (error) {
                    const errorMessage = _get(error, 'baseError.data.error.message');

                    if (errorMessage) {
                        modalApi.showAlert({
                            title: messages.unableToSavePriorCarrier,
                            message: error.baseError.data.error.message,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.close
                        }).catch(_noop);
                    }

                    setIsQuoting(false);

                    return false;
                }
            }

            modifyDates(submissionVM);

            const priorQuoteData = _get(submissionVM.value, 'quoteData');

            submissionVM.value = await LoadSaveService
                .saveAndQuoteSubmission(submissionVM.value, authHeader);

            const postQuoteData = _get(submissionVM.value, 'quoteData');

            if(!postQuoteData) {
                _set(submissionVM.value, 'quoteData', priorQuoteData );
            }

            // if there are errors, the loader needs to go away and page content nees to show again
            setIsQuoting(false);

            return submissionVM;
        },
        [isComponentValid, showPrayerForJudgementError, modifyDates, submissionVM, priorCarrierChanged, LoadSaveService, authHeader, updateWizardData, modalApi]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, submissionVM, updateWizardSnapshot]
    );

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isRetrievingReports) {
            loadingMessage = translator(messages.loading);
        } else if (isQuoting) {
            loadingMessage = translator(messages.ratingYourPolicyMessage);
        } else if (isSkipping) {
            loadingMessage = translator(messages.loadingNextPageMessage);
        }

        return loadingMessage;
    }, [isRetrievingReports, isQuoting, isSkipping, translator]);

    const checkCondition = useCallback(() => {
        if (uwIssuesPresent) {
            return 'uwIssuesTabID';
        }

        if (lastVisitedRiskAnalysisTab === 'EAReportsLossID') {
            setLastVisitedRiskAnalysisTab(undefined);

            return 'lossesAndViolationsTabID';
        }

        return 'priorCarrierTabID';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uwIssuesPresent]);

    const onChangePrayerForJudgement = useCallback(() => {
        // Get all manual added violations
        const manualViolationRecords = _get(submissionVM, 'lobData.personalAuto_EA.manualViolationRecords.value', []);
        const selectedPrayerForJudgementOperators = [];

        manualViolationRecords.forEach((violationRecord) => {
            // Add to selected driver list when prayer for judgement is selected
            if (_get(violationRecord, 'assignment.publicId') && violationRecord.prayerForJudgementInd) {
                selectedPrayerForJudgementOperators.push(_get(violationRecord, 'assignment.publicId'));
            }
        });
        // Show error message if driver with prayer for judgement indicator appears more than one time
        setShowPrayerForJudgementError(selectedPrayerForJudgementOperators.length !== _uniq(selectedPrayerForJudgementOperators).length);
    }, [submissionVM, setShowPrayerForJudgementError]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        RiskAnalysisPageLoadingIndicator: {
            loaded: !isQuoting && !isSkipping,
            text: getLoadingIndicatorMessage
        },
        RiskAnalysisPageContainer: {
            visible: !isQuoting && !isSkipping && !isSavingCurrentPageChanges,
            defaultActiveTab: checkCondition(),
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            activeTab: activeTab !== '' ? activeTab : checkCondition()
        },
        RiskAnalysisPageLoader: {
            loaded: !isQuoting && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.ratingYourPolicy)
        },
        EAPriorCarrierComponentId: {
            submissionVM,
            disregardFieldValidation,
            visible: !isSkipping,
            setPriorCarrierChanged,
            showErrors: isPageSubmitted
        },
        EALossAndViolationComponentId: {
            lobDataModel: submissionVM.lobData.personalAuto_EA,
            onModelChange: () => updateWizardData(submissionVM),
            viewModelService,
            disregardFieldValidation,
            policyState,
            operators: submissionVM.lobData.personalAuto_EA.coverables.drivers?.value?.map((driver) => ({
                    publicId: driver.publicID,
                    displayName: driver.person.displayName
                })),
            showErrors: isPageSubmitted,
            periodStartDate: submissionVM.baseData.periodStartDate,
            onChangePrayerForJudgement,
            showPrayerForJudgementError,
            authUserData,
            isVerified: submissionVM.value.quoteType === 'Full',
            updateIsPageSubmitted
        },
        EARiskAnalysisMiscComponentId: {
            submissionVM,
            isSkipping,
            onValidate,
            updateWizardData,
            shouldShowCedingField: policyState === 'NC'
        },
        EACreditReportsComponentId: {
            submissionVM,
            creditReportResponse,
            setCreditReportResponse,
            updateWizardData,
            lineOfBusiness: submissionVM.lobData.personalAuto_EA,
            lobName: 'personalAuto_EA',
            authUserData,
            id: 'EACreditReportsComponentId',
            onValidate,
            setOverlappingException
        },
        lossesAndViolationID: {
            visible: submissionVM.value.quoteType === 'Full' && !_isEmpty(dataForComponent),
            lossesAndViolations: dataForComponent,
            authHeader,
            quoteId: _get(submissionVM, 'quoteID.value'),
            updateBaseDataComponent: updateDataForComponent,
            accountHolderName: submissionVM.baseData.accountHolder.displayName.value,
            drivers: submissionVM.lobData.personalAuto_EA.coverables.drivers,
            policyState,
            jobType: _get(submissionVM, 'value.baseData.jobType')
        },
        EAUWIssuesComponentId: {
            submissionVM,
            updateWizardData,
            authHeader,
            // history
        },
        billingMiscellaneousReportComponent: {
            visible: authUserData?.permissions_Ext.includes('canviewbillingreport'),
            data: _get(submissionVM,'value.baseData.cancelActivities_Ext',[])
        },
        declareStatementThreeYears: {
            visible: visibleFields.includes('declareLossStatementThreeYears')
        },
        declareStatementFiveYears: {
            visible: visibleFields.includes('declareLossStatementFiveYears')
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(submissionVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: submissionVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        },
        manualLossesHistory: {
            manualLossList,
            visible: _get(submissionVM, 'value.quoteType') === 'Full' && manualLossList.length > 0
        },
        noMiscReportResultsMessageId: {
            visible: (() => 
                // If misc report content is empty 
                 !_get(document.getElementById('miscellaneousReportsBodyId'), 'innerText') 
                    && !_get(document.getElementById('billingMiscellaneousReportComponent'), 'innerText')
            )()
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
