import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, find, findIndex, unset, pullAt, set
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDriverPageUtil } from 'e1p-capability-hooks';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import metadata from '../DriversPage.metadata.json5';

function EADriversPage(props) {
    const { wizardData: rewriteVM, authUserData, viewOnly } = props;
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        isDefensiveDriverDiscountAvailableForDriver,
        getDriverAge,
        isFinancialResponsibilityFormAvailableForDriver
    } = useDriverPageUtil();

    

    const [checkScrolling, setCheckScrolling] = useState(true);

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(() => rewriteVM, [rewriteVM]);

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';
        const drivers = get(rewriteVM, `${driversPath}`);
        const pniPublicID = rewriteVM.lobData.personalAuto_EA.primaryNamedInsured.person.publicID.value;
        const sniPublicID = rewriteVM.lobData.personalAuto_EA.secondaryNamedInsured.person ? rewriteVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value : undefined;

        let pni = find(drivers.children, (driver) => driver.person.publicID.value === pniPublicID);
        const pniIndex = findIndex(drivers.children, (driver) => driver.person.publicID.value === pniPublicID);

        // Unset role. Fixes bug where you come back to drivers from a further page
        //   and ArrayUpdater has changed the ordering of your drivers and role has persisted
        //   so you end up seeing both  drivers as PNI
        drivers.children.forEach((driverNode) => {
            unset(driverNode, 'role');
        });

        // Put PNI back as first driver
        if (pniIndex > 0) {
            pni = viewModelService.clone(pni);
            pullAt(drivers.value, pniIndex);
            drivers.value.unshift(pni.value);
        }

        if (sniPublicID) {
            const sni = find(drivers.children, (driver) => driver.person.publicID.value === sniPublicID);

            if (sni) {
                set(sni, 'value.role', 'sni');
            }
        }

        if (pni) {
            set(pni, 'value.role', 'pni');
        }
    }, [rewriteVM, rewriteVM.lobData.personalAuto_EA.coverables.drivers.value, viewModelService]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            readOnly: viewOnly,
            labelPosition: 'top'
        },
        driversPageLoadingIndicator: {
            loaded: true
        },
        driversPageContainer: {
            visible: true
        },
        addDriver: {
            visible: false
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('eadrivercontainer')
        },
        defensiveDriverDiscountNotificationDiv: {
            visible: false
        },
        financialResponsibilityWithCompOnlyMessageDiv: {
            visible: false
        },
        nationalGuardWithDefensiveDriverMessageDiv:{
            visible: false
        },
        EADriverGrid: {
            drivers: get(rewriteVM, 'lobData.personalAuto_EA.coverables.drivers', []),
            path: 'lobData.personalAuto_EA.coverables.drivers.children',
            onValidate: () => { },
            viewModelService,
            showErrors: false,
            policyState: get(rewriteVM, 'baseData.policyAddress.state.value.code'),
            driverPageDisregardFieldValidation: () => { },
            checkLicenseRequired: () => { },
            driversWithLicenseValidationError: [],
            setDriversWithLicenseValidationErrors: () => { },
            getDriverAge,
            periodStartDate: get(rewriteVM, 'baseData.periodStartDate.value'),
            priorPolicyList: get(rewriteVM, 'lobData.personalAuto_EA.priorPolicyUpdates'),
            onRemoveDriver: () => { },
            isFinancialResponsibilityFormAvailableForDriver,
            isNewSubmission: false,
            viewOnlyMode: true,
            onValidationChange: () => { },
            setSNIHasDefensiveCourse: () => { },
            setPNIHasDefensiveCourse: () => { },
            authUserData,
            transactionVM: rewriteVM,
            isDefensiveDriverDiscountAvailableForDriver,
            updateWizardData: () => { },
            setCheckScrolling
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, rewriteVM, id, path, overrideProps),
        [overrideProps, rewriteVM]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };


    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

EADriversPage.propTypes = wizardProps;
export default withAuthenticationContext(EADriversPage);
