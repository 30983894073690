import { defineMessages } from 'react-intl';

export default defineMessages({
    unverifiedPremium: {
        id: 'quoteandbind.eu.QuoteClauseTable.Unverified Premium',
        defaultMessage: 'Unverified Premium'
    },
    withoutPaidInFullDiscount: {
        id: 'quoteandbind.eu.QuoteClauseTable.Without Paid-In Full Discount',
        defaultMessage: 'Without Paid-In Full Discount'
    },
    policyTerm: {
        id: 'quoteandbind.eu.QuoteClauseTable.Policy Term',
        defaultMessage: 'Policy Term'
    },
    sixMonths: {
        id: 'quoteandbind.eu.QuoteClauseTable.6 Months',
        defaultMessage: '6 Months'
    },
    twelveMonths: {
        id: 'quoteandbind.eu.QuoteClauseTable.12 Months',
        defaultMessage: '12 Months'
    },
    discounts: {
        id: 'quoteandbind.eu.QuoteClauseTable.Discounts',
        defaultMessage: 'Discounts'
    },
    paidInFullDiscount: {
        id: 'quoteandbind.eu.QuoteClauseTable.Paid-In-Full Discount',
        defaultMessage: 'Paid-In-Full Discount'
    },
    policyLevelCoverages: {
        id: 'quoteandbind.eu.QuoteClauseTable.Policy Level Coverages',
        defaultMessage: 'Policy Level Coverages'
    },
    marketingLine: {
        id: 'quoteandbind.eu.QuoteClauseTable.A short marketing line about the benefits of upgrading.',
        defaultMessage: 'A short marketing line about the benefits of upgrading.'
    },
    coverages: {
        id: 'quoteandbind.eu.QuoteClauseTable.Coverages',
        defaultMessage: 'Coverages'
    },
    yes: {
        id: 'quoteandbind.eu.QuoteClauseTable.Yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'quoteandbind.eu.QuoteClauseTable.No',
        defaultMessage: 'No'
    },
    limit: {
        id: 'quoteandbind.eu.QuoteClauseTable.Limit',
        defaultMessage: 'Limit'
    },
    premium: {
        id: 'quoteandbind.eu.QuoteClauseTable.Premium',
        defaultMessage: 'Premium'
    },
    paperLess: {
        id: 'quoteandbind.common.EUQuoteClauseTable.Paperless',
        defaultMessage: 'Paperless'
    },
    emptyPremium: {
        id: 'quoteandbind.eu.views.payment-details.-',
        defaultMessage: '-'
    },
    exit: {
        id: 'quoteandbind.eu.common.directives.CommonOfferingSelection.Exit',
        defaultMessage: 'Exit'
    },
    ulPolicyInfoMessage: {
        id: 'quoteandbind.eu.QuoteClauseTable.Please ensure all underlying policy information is up-to-date for the most accurate Umbrella quote.',
        defaultMessage: 'Please ensure all underlying policy information is up-to-date for the most accurate Umbrella quote.'
    }
});
