module.exports = {

    /**
     * Key values for Lines of Business
     * May be a good idea to ensure that 
     * multiple mutations of your line name are covered 
     * where requirements apply as shown below:
     *     CommercialAuto: 'CA',
     *     BusinessAuto: 'CA',
     *     BusinessAutoLine: 'CA',
     */
    lobKeys: {
        PersonalAuto_EA: 'EA', // custom product
        PersonalUmbrella_EU: 'EU', // custom product
        Homeowners_EH: 'EH'
    },
    
    /**
     * Normalised Icons for Line of business
     * Add to this object in the following format,
     * in the case of HomeOwners, you would add: 
     *     HO: {
     *   image: 'home-owners',
     *   font: 'mi-home'
     *  },
     */
    normalisedLobIcons: {
    // Adding Custom iocons for E1P
        EH: {
            image: 'home-owners',
            font: 'mi-home'
        },
        EA: {
            image: 'personal-auto',
            font: 'mi-directions_car'
        },
        EU: {
            image: 'umbrella',
            font: 'mi-umbrella'
        },      
    },

    /**
     * Material icons for Lines of Business
     * Objects should be formatted like so, 
     * Where line is Personal auto: 
     *     PA: {
     *      image: 'personal-auto',
     *      font: 'mi-directions_car'
     *  },
     */
    materialLobIcons: {
    // Adding Custom iocons for E1P
        EH: {
            image: 'home-owners',
            font: 'mi-home'
        },
        EA: {
            image: 'personal-auto',
            font: 'mi-directions_car'
        },
        EU: {
            image: 'umbrella',
            font: 'mi-umbrella'
        },
    },     
}