import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('gatewayquote'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class LoadSaveService
 */
export default class LoadSaveService {
    /**
     * Updates, saves and performs a quote on an existing submission.
     * Generates quotes for all available product offerings.
     *
     * @param {Object} data tge submission that will be saved
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof LoadSaveService
     */
    static saveAndQuoteSubmission(data, additionalHeaders = {}) {
        // eslint-disable-next-line no-param-reassign
        data.flowStepIDs_Ext = [];
        // eslint-disable-next-line no-param-reassign
        data.entryCompletionStepIDs_Ext = [];
        return processSubmission('saveAndQuote', [data], additionalHeaders);
    }

    static canPurchase(data, additionalHeaders = {}) {
        return processSubmission('canPurchase', [data], additionalHeaders);
    }

    /**
     * Retrieves a submission.
     *
     * @param {Object} data the retrieval payload (QuoteRetrievalDTO)
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof LoadSaveService
     */
    static retrieveSubmission(data, additionalHeaders = {}) {
        return processSubmission('retrieve', [data], additionalHeaders);
    }

    /**
     * Retrieves underlying policies.
     *
     * @param {string} quoteID quoteID of submission that needs to be found
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof LoadSaveService
     */
    static retrieveUnderlyingPolicies(quoteID, additionalHeaders = {}) {
        return processSubmission('retrieveAndUpdateULPolicies', [quoteID], additionalHeaders);
    }

    /**
     * Updates a quoted submission with LOB Data.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateQuotedSubmissionWithLOBData(data, additionalHeaders = {}) {
        return processSubmission('updateQuotedSubmissionWithLOBData', [data], additionalHeaders);
    }

    /**
     * Retrieves a submission.
     *
     * @param {string} quoteID quoteID of submission that needs to be found
     * @param {string} sessionUUID current session id
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static retrievePaymentPlans(quoteID, sessionUUID, additionalHeaders = {}) {
        return processSubmission('retrievePaymentPlans', [quoteID, sessionUUID], additionalHeaders);
    }

    /**
     * Binds the submission.
     *
     * @param {Object} data the submission to be bound
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static bindSubmission(data, additionalHeaders = {}) {
        return processSubmission('bind', [data], additionalHeaders);
    }

    /**
     * Updates an existing draft submission.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateDraftSubmission(data, additionalHeaders = {}) {
        return processSubmission('updateDraftSubmission', [data], additionalHeaders);
    }

    /**
     * Updates a quoted submission.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static updateQuotedSubmission(data, additionalHeaders = {}) {
        return processSubmission('updateQuotedSubmission', [data], additionalHeaders);
    }

    /**
     * Updates coverages.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} sessionUUID sessionUUID
     * @param {Object} lobCoverages the coverages to be saved
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */

    static updateCoverages(quoteID, sessionUUID, lobCoverages, additionalHeaders = {}) {
        return processSubmission(
            'updateCoverages',
            [quoteID, sessionUUID, lobCoverages],
            additionalHeaders
        );
    }

    /**
     * Approve Underwriting Issue.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} uwIssue uwIssue
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static approveUnderwritingIssue(quoteID, uwIssue, additionalHeaders = {}) {
        return processSubmission(
            'approveUnderwritingIssue',
            [quoteID, uwIssue],
            additionalHeaders
        );
    }

    /**
     * Reopen Underwriting Issue.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} [uwIssues] uwIssues
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static reopenUnderwritingIssues(quoteID, uwIssues, additionalHeaders = {}) {
        return processSubmission(
            'reopenUnderwritingIssues',
            [quoteID, uwIssues],
            additionalHeaders
        );
    }

    /**
     * Reopen Underwriting Issue.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} [uwIssues] uwIssues
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static rejectUnderwritingIssues(quoteID, uwIssues, additionalHeaders = {}) {
        return processSubmission(
            'rejectUnderwritingIssues',
            [quoteID, uwIssues],
            additionalHeaders
        );
    }

    /**
     * Reopen Underwriting Issue.
     *
     * @param {Object} quoteID quoteID
     * @param {Object} [uwIssues] uwIssues
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static approveUnderwritingIssues(quoteID, uwIssues, additionalHeaders = {}) {
        return processSubmission(
            'approveUnderwritingIssues',
            [quoteID, uwIssues],
            additionalHeaders
        );
    }

    /**
     * Updates payment plans.
     *
     * @param {Object} data the submission with the selected payment plan
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof LoadSaveService
     */
    static changePaymentPlans(data, additionalHeaders = {}) {
        return processSubmission('changePaymentPlan', [data], additionalHeaders);
    }

    /**
     * Converts to full app,
     * updates draft,
     * and get's verified quote
     *
     * @param {Object} quoteDataDto the current submissionVM
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof LoadSaveService
     */
    static convertToFullApp(quoteDataDto, additionalHeaders = {}) {
        return processSubmission('convertToFullApp', [quoteDataDto], additionalHeaders);
    }

    /**
     * Pre validated,
     * updates draft,
     * and get's validated quote or validation errors
     *
     * @param {Object} quoteDataDto the current submissionVM
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof LoadSaveService
     */

    static preValidate(data, additionalHeaders = {}) {
        return processSubmission('preValidate', [data], additionalHeaders);
    }

    /**
     * update draft submission api call with updateOptions
     * updateOptions are used to forcefully updating coverages
     * @param {Object} quoteDataDTO current submission
     * @param {Object} updateOptions update options for updating coverages
     * @param {Object} additionalHeaders  additional headers to pass to the backend(e.g. auth)
     * @returns {Promise} the promise from backend call
     */
    static updateDraftSubmissionWithOptions (quoteDataDTO, updateOptions, additionalHeaders = {}){
        return processSubmission('updateDraftSubmissionWithOptions',[quoteDataDTO, updateOptions], additionalHeaders);
    }
}
