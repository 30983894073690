import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import E1PMSALoader from './MSALoader/E1PMSALoader';
import metadata from './E1PLoader.metadata.json5';

function E1PLoader(props) {
    const { loaded, text, children, className } = props;
    const overrideProps = {
        E1PLoadingIndicator: {
            loaded,
            text,
            content: children,
            className,
            renderLoader: () => <E1PMSALoader />
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
        />
    );
}

E1PLoader.propTypes = {
    loaded: PropTypes.bool.isRequired,
    text: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};

E1PLoader.defaultProps = {
    text: undefined,
    children: null
};
export default E1PLoader;
