/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import htmlParser from 'html-react-parser';
 
import appConfig from 'app-config';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withModalContext } from '@jutro/components';
import {
    TranslatorContext,
    FormattedMessage
} from '@jutro/locale';
import {
    parseErrors
} from '@xengage/gw-portals-edge-validation-js';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { RenewalService } from 'gw-capability-gateway';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { commonMessages } from 'e1p-platform-translations';
import { RenewalService as e1pRenewalService } from 'e1p-capability-renewal';
import { E1PRenewalNonRenewComponent } from 'e1p-capability-policyjob-react';
import TransactionSummaryCardComponent from '../../Components/TransactionSummaryCardComponent/TransactionSummaryCardComponent';
import PolicySummaryCardComponent from '../../Components/PolicySummaryCardComponent/PolicySummaryCardComponent';
import ProducerSummaryCardComponent from '../../Components/ProducerSummaryCardComponent/ProducerSummaryCardComponent';
import UnderwritingComponent from '../../Components/UnderwritingComponent/UnderwritingComponent';
import gatewayMessages from '../../gateway.messages';
import metadata from './Summary.metadata.json5';
import messages from './Summary.messages';
import styles from '../Renewal.module.scss';

const NOT_TAKEN = 'nottaken';
const NON_RENEW = 'nonrenew';
const RENEW = 'renew';

class RenewalsSummaryWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }),
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                loadRenewalSummary: PropTypes.shape({}).isRequired,
                updateJobSummary: PropTypes.func.isRequired,
                refreshJobSummary: PropTypes.func.isRequired
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        authUserData: PropTypes.shape({
            userType: PropTypes.string,
            permissions_Ext: PropTypes.arrayOf(
                PropTypes.string
            )
        }),
    };

    static defaultProps = {
        authHeader: undefined,
        authUserData: undefined
    }

    state = {
        canWithdraw: false,
        isLoading: true,
        exceptions: []
    };

    componentDidMount() {
        this.setIsLoading(true);
        this.canWithdraw();
        this.setIsLoading(false);
    };

    getNewPremium = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;
        const totalPremium = _.get(renewal, 'latestPeriod.totalPremium');
        const taxesAndFees = _.get(renewal, 'latestPeriod.taxesAndFees');

        return (
            <FormattedMessage
                {...messages.totalPremiumAndTaxes}
                values={{
                    totalPremium: (
                        <CurrencyField
                            id="totalPremium"
                            value={totalPremium}
                            readOnly
                            hideLabel
                            datatype="object"
                            className={styles.taxesAndFees}
                        />
                    ),
                    taxesAndFees: (
                        <CurrencyField
                            id="taxesAndFees"
                            value={taxesAndFees}
                            readOnly
                            hideLabel
                            datatype="object"
                            className={styles.taxesAndFees}
                        />
                    )
                }}
            />
        );
    };

    getPremiumPercentage = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;

        return translator(
            messages.increaseOf,
            { value: _.get(renewal, 'changeInCostPercentage') }
        );
    };

    canWithdraw = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;

        if (!_.isEmpty(renewal)) {
            const translator = this.context;

            if (
                renewal.status
                !== translator({
                    id: 'typekey.PolicyPeriodStatus.Bound',
                    defaultMessage: 'Bound'
                })
                && !renewal.closeDate
            ) {
                this.setState({ canWithdraw: true });
            }
        }
    };

    onViewTransaction = () => {
        const { lobRenewalURL, renewalProducts } = appConfig;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
            history
        } = this.props;
        const productToLOBName = {
            Homeowners_EH: 'homeowners_EH',
            PersonalAuto_EA: 'personalAuto_EA',
            PersonalUmbrella_EU: 'personalUmbrella_EU'
        };

        const productCode = _.get(renewal, 'productCode');
        const policyData = _.get(renewal, 'policy');
        const policyType = _.get(policyData, `lobs.${productToLOBName[productCode]}.policyType_Ext`);

        if (renewalProducts.includes(productCode)) {
            history.push(lobRenewalURL[productCode], {
                jobNumber: renewal.jobNumber,
                policyNumber: renewal.policy.policyNumber,
                policyData,
                viewOnly: true,
                policyType
            });
        }
    };

    handleEditRenewalButton = (editRenewal = '') => {
        const { lobRenewalURL, renewalProducts } = appConfig;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
            history
        } = this.props;
        const productToLOBName = {
            Homeowners_EH: 'homeowners_EH',
            PersonalAuto_EA: 'personalAuto_EA',
            PersonalUmbrella_EU: 'personalUmbrella_EU'
        };
        const productCode = _.get(renewal, 'productCode');
        const policyData = _.get(renewal, 'policy');
        const policyType = _.get(policyData, `lobs.${productToLOBName[productCode]}.policyType_Ext`);

        if (renewalProducts.includes(productCode)) {
            history.push(lobRenewalURL[productCode], {
                jobNumber: renewal.jobNumber,
                policyNumber: renewal.policy.policyNumber,
                clickedButtonValue: editRenewal,
                policyType,
                policyData
            });
        }
    };

    updateWithDrawRenewal = (jobStatusCode, renewal) => {
        const translator = this.context;
        const status = translator({
            id: `typekey.PolicyPeriodStatus.${jobStatusCode}`,
            defaultMessage: jobStatusCode
        });

        _.set(renewal, 'status', status);
        _.set(renewal, 'statusCode', jobStatusCode);
        this.updateJobSummary(renewal);
    };

    isRenewalButtonsContainerVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
        } = this.props;
        const { renewalProducts } = appConfig;
        const productCode = _.get(renewal, 'productCode');

        return renewalProducts.includes(productCode);
    }

    isWithdrawRenewalButtonVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
            authUserData
        } = this.props;
        // canWithdraw is just checking if renwal is not bound and renewal is not closed
        const { canWithdraw } = this.state;
        let withdrawRenewalPermission = false;
        const renewalDirection = _.get(renewal, 'policy.preRenewalDirection_Ext');

        if (renewalDirection === 'nonrenew') {
            withdrawRenewalPermission = authUserData.permissions_Ext.includes('nonrenew') && authUserData.permissions_Ext.includes('withdraw');
        } else if (renewalDirection === 'nottaken') {
            withdrawRenewalPermission = authUserData.permissions_Ext.includes('nottakerenewal') && authUserData.permissions_Ext.includes('withdraw');
        } else {
            // In all other renewal states, user with editrenewal
            // and withdraw permissions must be able to withdraw renewal
            withdrawRenewalPermission = authUserData.permissions_Ext.includes('editrenewal') && authUserData.permissions_Ext.includes('withdraw');
        }

        const isButtonVisible = canWithdraw && withdrawRenewalPermission;

        return isButtonVisible;
    }

    withDrawRenewal = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
            history,
            authHeader
        } = this.props;
        const {
            policy: {
                policyNumber
            },
            iscreditReconsiderationRequested_Ext
        } = renewal;

        this.props.modalContext.showConfirm({
            title: messages.withdrawTransaction,
            message: iscreditReconsiderationRequested_Ext
                ? htmlParser(translator(messages.withdrawRenewalCreditReconsiderationMessage))
                : translator(messages.withdrawRenewalInfoMessage),
            confirmButtonText: translator(messages.withdrawRenewalLabel),
            cancelButtonText: translator(commonMessages.cancel)
        }).then((results) => {
            if (results === 'cancel') {
                this.setState(() => ({ isLoading: false }));

                return _.noop();
            }

            return RenewalService.withdrawRenewalByRenewalNumber(renewal.jobNumber, authHeader)
                .then(() => {
                    this.updateWithDrawRenewal('Withdrawn', renewal);
                    history.push(`/policies/${policyNumber}/summary`);
                })
                .catch(() => {
                    this.props.modalContext.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedToRenewal,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.close
                    }).catch(_.noop);
                });
        }, _.noop);
    };


    getPolicyLinkVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;

        return (
            !_.isEmpty(renewal.latestPeriod)
            && renewal.latestPeriod.policyNumber
            && renewal.latestPeriod.policyNumber !== 'Unassigned'
        );
    };

    redirectToPolicy = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;

        const policyNumber = _.get(renewal, 'policy.latestPeriod.policyNumber');

        return (
            <Link to={`/policies/${policyNumber}/summary`} className={styles.removeLinkStyle}>
                {policyNumber}
            </Link>
        );
    };

    updateJobSummary = (renewal) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { updateJobSummary }
            }
        } = this.props;

        if (updateJobSummary) {
            updateJobSummary(renewal);
        }
    };

    refreshJobSummary = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { refreshJobSummary }
            }
        } = this.props;

        if (refreshJobSummary) {
            this.setIsLoading(true);
            refreshJobSummary();
            this.setIsLoading(false);
        }
    };

    setIsLoading = (value) => {
        this.setState({ isLoading: value });
    }

    openRenewableDirectionModal = async () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
            authHeader
        } = this.props;
        const policyNumber = _.get(renewal, 'policy.latestPeriod.policyNumber');
        const componentProps = {
            authHeader,
            renewalJobNumber: renewal.jobNumber,
            policyNumber
        };

        await this.props.modalContext
            .showModal(<E1PRenewalNonRenewComponent {...componentProps} />);
        this.refreshJobSummary();
    }

    setRenewalDirection = (selectedRenewlDirection) => {
        if (selectedRenewlDirection === NON_RENEW) {
            this.openRenewableDirectionModal();

            return;
        }

        this.setIsLoading(true);

        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
            authHeader
        } = this.props;

        let serviceCall = e1pRenewalService.markRenewalRenew;
        let requestParam = [renewal.jobNumber];

        if (selectedRenewlDirection === NOT_TAKEN) {
            serviceCall = e1pRenewalService.markRenewalNotTaken;
            requestParam = [renewal.jobNumber, ['prerenewaldirection']];
        }

        serviceCall(
            ...requestParam,
            authHeader
        ).then((response) => {
            // check errors and Warnings in the call.
            const validations = response.errorsAndWarnings_Ext?.validationIssues
                ? parseErrors(response.errorsAndWarnings_Ext) : [];

            if (!_.isEmpty(response.baseData?.exceptions_Ext)
                || !_.isEmpty(validations)) {
                // exception in the call
                const exceptionsAndValidations = _.get(response, 'baseData.exceptions_Ext', []);

                this.setState({
                    exceptions: exceptionsAndValidations
                        .concat(validations)
                });
            } else {
                this.setState(
                    {
                        exceptions: []
                    }, () => {
                        this.refreshJobSummary();
                    }
                );
            }
        }).catch(_.noop).finally(() => {
            this.setIsLoading(false);
        });
    };

    // this method will be called for each dropdown menu option,
    // and will return boolean if the option is visible or not
    // The visibility of the options will depend on the permissions provided to user
    // Refer story https://amfament.atlassian.net/browse/E1PAP1PC-13615 for more details
    isRenewalDirectionVisible = (renewalDirection) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;
        const { authUserData } = this.props;
        let isDirectionVisible = false;

        switch (renewalDirection) {
            case 'nonrenew':
                isDirectionVisible = renewal.statusCode !== 'NonRenewing' && authUserData.permissions_Ext.includes('nonrenew');
                break;
            case 'nottaken':
                isDirectionVisible = renewal.statusCode !== 'NotTaking' && (authUserData.permissions_Ext.includes('nonrenew') || authUserData.permissions_Ext.includes('nottakerenewal'));
                break;
            case 'renew':
                isDirectionVisible = renewal.statusCode !== 'Renewing' && (authUserData.permissions_Ext.includes('nonrenew') || authUserData.permissions_Ext.includes('nottakerenewal'));
                break;
            default:
                isDirectionVisible = false;
        }

        return isDirectionVisible;
    };

    isEditDirectionMenuVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;

        // edit renewal direction button is not visible if the last step of the workflow is executed
        if (renewal.statusCode === 'NotTaken' || renewal.statusCode === 'NonRenewed') {
            return false;
        }

        const isRenewMenuOptionAvailable = this.isRenewalDirectionVisible(RENEW);
        const isNonRenewMenuOptionAvailable = this.isRenewalDirectionVisible(NON_RENEW);
        const isNotTakenMenuOptionAvailable = this.isRenewalDirectionVisible(NOT_TAKEN);
        // if non of the option is available,
        // then we will not show edit Renewal Directional Menu button
        const isMenuButtonVisible = isRenewMenuOptionAvailable
            || isNotTakenMenuOptionAvailable
            || isNonRenewMenuOptionAvailable;

        // if canEdit is true that means renewal is in workflow,else renewal is not in workflow.
        const nonRenewPreRenewalDirectionHasTerminatedAgent = !!(_.get(renewal, 'nonRenewReasonCode'));
        const isMenuDropdownVisible = isMenuButtonVisible
            && renewal.canStartEdit_Ext
            && !nonRenewPreRenewalDirectionHasTerminatedAgent;

        return isMenuDropdownVisible;
    }

    isEditRenewalButtonVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
            authUserData
        } = this.props;

        // edit renewal Button is not visible if the last step of the workflow is executed
        if (renewal.statusCode === 'NotTaken' || renewal.statusCode === 'NonRenewed') {
            return false;
        }

        const showEditRenewalpermission = authUserData.permissions_Ext.includes('editrenewal');
        const isInWorkflow = _.get(renewal, 'canStartEdit_Ext', false);

        // When renewal work order is in "Renewing " status then
        // "Edit renewal" button will be available on the Job Summary page  until
        // Bind & Issue step (I.E  when Renewal Transaction Status changes to ‘Bound’)
        if (renewal.statusCode === 'Renewing') {
            return showEditRenewalpermission && isInWorkflow;
        }

        return false;
    }

    isContinueEditRenewalButtonVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            },
            authUserData
        } = this.props;

        // Continue editing renewal Button is not visible if the last step
        // of the workflow is executed
        if (renewal.statusCode === 'NotTaken' || renewal.statusCode === 'NonRenewed') {
            return false;
        }

        const showEditRenewalpermission = authUserData.permissions_Ext.includes('editrenewal');
        const inEditMode = _.get(renewal, 'canContinueEdit_Ext', false);

        // When renewal work order is in "Draft" or "Quoted"" status then
        // "Continue Editing renewal" button will be available on the Job Summary page  until
        // Bind & Issue step (I.E  when Renewal Transaction Status changes to ‘Bound’)
        if (renewal.statusCode === 'Draft' || renewal.statusCode === 'Quoted') {
            return showEditRenewalpermission && inEditMode;
        }

        return false;
    }

    render() {
        const {
            isLoading, exceptions
        } = this.state;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadRenewalSummary: renewal }
            }
        } = this.props;
        const isInWorkflow = _.get(renewal, 'canStartEdit_Ext', false);
        const isDiscardButtonActive = _.get(renewal, 'canDiscard_Ext', false);
        const quotedState = _.get(renewal, 'statusCode') === 'Quoted';
        const policyNumber = _.get(renewal, 'latestPeriod.policyNumber');
        const overrides = {
            '@field': {
                readOnly: true,
                autoComplete: false
            },
            summaryPageLoadingIndicator: {
                loaded: !isLoading
            },
            summaryPageMainContainer: {
                visible: !isLoading
            },
            renewalButtonGroupLoadingIndicator: {
                loaded: !isLoading
            },
            renewalButtonGroupContainer: {
                visible: !isLoading && this.isRenewalButtonsContainerVisible()
            },
            WizardSingleErrorComponent: {
                issuesList: exceptions
            },
            withdrawRenewalButtonId: {
                visible: this.isWithdrawRenewalButtonVisible()
            },
            policyInfoLink: {
                visible: this.getPolicyLinkVisible(),
                value: this.redirectToPolicy()
            },
            columnSpace: {
                visible: true,
                className:
                    (renewal.statusCode === 'Quoted' && renewal.changeInCost.amount === 0)
                        || (renewal.statusCode === 'Quoted'
                            && (renewal.changeInCost.amount > 0 || renewal.changeInCost.amount < 0))
                        ? ''
                        : styles.columnHorizontal
            },
            statusQuotedCost: {
                visible: renewal.statusCode === 'Quoted' && renewal.changeInCost.amount === 0
            },
            quotedChangeInCost: {
                visible:
                    renewal.statusCode === 'Quoted'
                    && (renewal.changeInCost.amount > 0 || renewal.changeInCost.amount < 0)
            },
            viewPolicyLink: {
                content: `${renewal.productCode} (${policyNumber})`,
                to: `/policies/${renewal.latestPeriod.policyNumber}/summary`
            },
            newPremiumContainer: {
                content: this.getNewPremium()
            },
            changeInCostPercentage: {
                message: this.getPremiumPercentage()
            },
            quotedCostContainer: {
                visible: quotedState
            },
            producerCodeOfRecordOrgId: {
                value: `${_.get(
                    renewal,
                    'policy.latestPeriod.producerCodeOfRecordOrg'
                )} (${_.get(renewal, 'policy.latestPeriod.producerCodeOfRecord')})`
            },
            producerOfServiceId: {
                value: `${_.get(
                    renewal,
                    'policy.latestPeriod.producerCodeOfServiceOrg'
                )} (${_.get(renewal, 'policy.latestPeriod.producerCodeOfService')})`
            },
            preferredUnderwriterId: {
                value: _.get(
                    renewal,
                    'policy.latestPeriod.assignedUWName_Ext', ''
                )
            },
            continueEditRenewalButtonId: {
                visible: this.isContinueEditRenewalButtonVisible()
            },
            editRenewalButtonId: {
                visible: this.isEditRenewalButtonVisible()
            },
            preRenewalDirectionNotificationContainer: {
                visible: !isInWorkflow && !isDiscardButtonActive && renewal.statusCode !== 'Withdrawn'
            },
            notTakenMenuLink: {
                onClick: (() => this.setRenewalDirection(NOT_TAKEN)),
                visible: this.isRenewalDirectionVisible(NOT_TAKEN)
            },
            nonRenewMenuLink: {
                onClick: (() => this.setRenewalDirection(NON_RENEW)),
                visible: this.isRenewalDirectionVisible(NON_RENEW)
            },
            renewMenuLink: {
                onClick: (() => this.setRenewalDirection(RENEW)),
                visible: this.isRenewalDirectionVisible(RENEW)
            },
            editRenewalDirectionButtonMenu: {
                visible: this.isEditDirectionMenuVisible()
            },
            nonRenewReason: {
                visible: !!(_.get(renewal, 'nonRenewReasonCode'))
            },
            transactionSummaryCardComponent: {
                transactionSummaryInfo: {
                    transactionCreateTime: _.get(renewal, 'createTime'),
                    termEffectiveDate: _.get(renewal, 'latestPeriod.effectiveDate'),
                    transactionStatus: _.get(renewal, 'latestPeriod.displayStatus'),
                    policyInceptionDate: _.get(renewal, 'policy.latestPeriod.effectiveDate')
                }
            },
            producerSummaryCardComponent: {
                producerSummaryInfo: {
                    producerCodeOfRecordOrg: _.get(renewal, 'policy.latestPeriod.producerCodeOfRecordOrg'),
                    producerOfService: _.get(renewal, 'policy.latestPeriod.producerCodeOfServiceOrg'),
                    preferredUnderwriter: _.get(renewal, 'policy.latestPeriod.assignedUWName_Ext')
                }
            },
            policySummaryCardComponent: {
                policySummaryInfo: {
                    totalPremium: _.get(renewal, 'policy.latestPeriod.totalPremium'),
                    taxesAndFees: _.get(renewal, 'policy.latestPeriod.taxesAndFees'),
                    totalCost: _.get(renewal, 'policy.latestPeriod.totalCost'),
                }
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                underwritingcomponent: UnderwritingComponent,
                WizardSingleErrorComponent,
                TransactionSummaryCardComponent,
                ProducerSummaryCardComponent,
                PolicySummaryCardComponent
            },
            resolveCallbackMap: {
                onViewTransaction: this.onViewTransaction,
                onWithdrawRenewal: this.withDrawRenewal,
                onContinueEditRenewalTransaction: () => this.handleEditRenewalButton(),
                handleEditRenewalButton: () => this.handleEditRenewalButton('editRenewal'),
            }
        };
        const readValue = (id, path) => readViewModelValue(metadata.pageContent, renewal, id, path, overrides);

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewal}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        );
    }
}

const RenewalsSummary = withModalContext(RenewalsSummaryWithoutModalContext);

export default withAuthenticationContext(RenewalsSummary);
