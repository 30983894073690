import React, { useMemo, useCallback, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '@jutro/layout';
import { ToggleField, Tooltip, useModal } from '@jutro/components';

import config from 'app-config';
import { E1PDateComponent, E1PSSNComponent, E1PLicenseComponent, EAMilitaryDeploymentModal } from 'e1p-capability-policyjob-react';
import { usePriorPolicyMilitaryDeploymentUpdateUtil, e1pDateUtil, useAttributeConfigUtilForEA, useSniUtil } from 'e1p-capability-hooks';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import {
    get as _get,
    find as _find,
    set as _set,
    isNil as _isNil,
    toString as _toString,
    range as _range,
    includes as _includes,
    isEqual as _isEqual,
    isEmpty as _isEmpty,
    findIndex as _findIndex,
    remove as _remove
} from 'lodash';
import moment from 'moment';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import {
    ConsoleHelper,
    TypekeyUtil,
    FeatureUtil
} from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages, eaCommonMessages } from 'e1p-platform-translations';
import NonOperatorComponent from '../NonOperatorComponent/NonOperatorComponent';
import DefensiveDriverComponent from '../DefensiveDriverComponent/DefensiveDriverComponent';

import { IconButton, InputField, DropdownSelectField, InputNumberField } from '@jutro/legacy/components';

const statesDerivingYearsExperience = ['NC'];
// eslint-disable-next-line camelcase
const Jurisdictions_Countries = ['CA', 'PR', 'VI', 'GU', 'AS', 'MP', 'Other_ext'];

function EADriverGridComponent(props) {
    const modalApi = useModal();
    const {
        id,
        onValidate,
        drivers,
        onValueChange,
        path,
        viewModelService,
        showErrors,
        viewOnlyMode,
        policyState,
        authHeader,
        checkLicenseRequired,
        driversWithLicenseValidationError,
        setDriversWithLicenseValidationErrors,
        getDriverAge,
        periodStartDate,
        priorPolicyList,
        isFinancialResponsibilityFormAvailableForDriver,
        isNewSubmission,
        authUserData,
        transactionVM,
        isDefensiveDriverDiscountAvailableForDriver,
        setSNIHasDefensiveCourse,
        setPNIHasDefensiveCourse,
        updateWizardData,
        setCheckScrolling,
        setIsExistingDriverDeleted,
        showValidationMessage,
        isAdditionalInfo,
        setIsAccountContactsFetched
    } = props;
    const { isComponentValid, onValidate: setComponentValidation, disregardFieldValidation } = useValidation(id);
    const isActiveNationalGuardIndicatorVisible = FeatureUtil.isActiveNationalGuardAvailableForState(policyState);
    const [showAwayAtSchoolQuestionsState, setShowAwayAtSchoolQuestionsState] = useState(false);
    const [isSchoolZipCodeRequried, setIsSchoolZipCodeRequried] = useState(false);
    const [anyPersonLessThan25AndOperatorState, setAnyPersonLessThan25AndOperatorState] = useState(false);
    const [anyDriverDeceasedState, setAnyDriverDeceasedState] = useState(false);
    const [anyLicenseInternationalState, setAnyLicenseInternationalState] = useState(false);
    const [isDefensiveDriverIndicatorVisibleState, setIsDefensiveDriverIndicatorVisibleState] = useState(false);
    const [isDefensiveToolTipTextVisibleState, setIsDefensiveToolTipTextVisibleState] = useState(false);
    const [anyDriverLicensedInPuertoOrCanadaState, setAnyDriverLicensedInPuertoOrCanadaState] = useState(false);
    const [isFinancialResponsibilityFormAvailableState, setIsFinancialResponsibilityFormAvailableState] = useState(false);
    const [driverLabels, setDriverLabels] = useState([]);
    const [driverFields, setDriverFields] = useState([]);
    const [policyDriverRoleTypes, setPolicyDriverRoleTypes] = useState([]);
    const [maritalStatusAvailableValues, setMaritalStatusAvailableValues] = useState([]);
    const { opCo } = useContext(AmfamOktaTokenContext);

    const translator = useTranslator();
    const { ageAllowedToDrive } = config.personalAutoConfig;
    const {
        getMilitaryDeploymentRecordIndex,
        removeMilitaryDeployments
    } = usePriorPolicyMilitaryDeploymentUpdateUtil();

    const { removeSni } = useSniUtil(
        transactionVM,
        updateWizardData,
        viewModelService,
        'personalAuto_EA',
        disregardFieldValidation
    );

    const canAccessDeceaseDataPermission = authUserData?.permissions_Ext.includes('canaccessdeceasedata');

    /**
     * IAP-3680 : Risk Role Attribute Changes
     */
    const {
        getAvailableValuesForAllFieldsForDriverPage,
        getAvailableValuesForPriorPolicyUpdateReasons
    } = useAttributeConfigUtilForEA(transactionVM);

    useEffect(() => {
        let isMounted = true;

        getAvailableValuesForAllFieldsForDriverPage()
            .then((availableValuesForMultipleFields) => {
                const {
                    maritalStatusAvailableValues: maritalStatuses,
                    policyDriverRoleTypeAvailableValues
                } = availableValuesForMultipleFields;

                if (isMounted) {
                    setPolicyDriverRoleTypes(policyDriverRoleTypeAvailableValues);
                    setMaritalStatusAvailableValues(maritalStatuses);
                }
            });

        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let anyLicenseInternational = false;
        let isDefensiveDriverIndicatorVisible = false;
        let showAwayAtSchoolQuestions = false;
        let isSchoolZipCodeRequiredForAnyDriver = false;
        let anyPersonLessThan25AndOperator = false;
        let anyDriverDeceased = false;
        let isDefensiveToolTipTextVisible = false;
        let anyDriverLicensedInPuertoOrCanada = false;

        drivers.children.forEach((driver) => {
            if (anyLicenseInternational === false) {
                anyLicenseInternational = _get(driver, 'person.licenseState.value.code') === 'INT_ext';
            }

            const driverRoleType = _get(driver, 'policyDriverRoleType.value.code');
            const isSNI = _get(driver, 'value.role') === 'sni';
            const driverAge = getDriverAge(driver);
            const isActiveNationalGuard = _get(driver, 'value.activeNationalGuardMemberInd', false);

            if (isDefensiveDriverIndicatorVisible === false) {
                const {
                    isDefensiveDriverDiscountAvailable,
                    isDefensiveDriverDiscountToolTipVisible
                } = isDefensiveDriverDiscountAvailableForDriver(
                    driverAge,
                    policyState,
                    _get(driver, 'value.role') === 'pni',
                    isSNI,
                    driverRoleType,
                    isActiveNationalGuard
                );

                isDefensiveDriverIndicatorVisible = isDefensiveDriverDiscountAvailable;
                isDefensiveToolTipTextVisible = isDefensiveDriverDiscountToolTipVisible;
            }

            if (anyPersonLessThan25AndOperator === false && driverAge && driverAge < 25 && driverRoleType === 'operator') {
                anyPersonLessThan25AndOperator = true;
            }

            if (showAwayAtSchoolQuestions === false && driver.awayAtSchoolInd.value && anyPersonLessThan25AndOperator) {
                showAwayAtSchoolQuestions = true;
            }

            if (isSchoolZipCodeRequiredForAnyDriver === false && _get(driver, 'awayAtSchoolCountry.value.code') === 'US') {
                isSchoolZipCodeRequiredForAnyDriver = true;
            }

            if (anyDriverDeceased === false) {
                anyDriverDeceased = _get(driver, 'person.isDeceased.value', false) && ['pni', 'sni'].includes(_get(driver, 'value.role'));
            }

            if (anyDriverLicensedInPuertoOrCanada === false) {
                anyDriverLicensedInPuertoOrCanada = _get(drivers, 'value').some((subDriver) =>
                    subDriver.licensedInCanadaOrPuertoRicoInd);
            }
        })

        setShowAwayAtSchoolQuestionsState(showAwayAtSchoolQuestions);
        setIsSchoolZipCodeRequried(isSchoolZipCodeRequiredForAnyDriver);
        setAnyPersonLessThan25AndOperatorState(anyPersonLessThan25AndOperator);
        setAnyDriverDeceasedState(anyDriverDeceased);
        setAnyLicenseInternationalState(anyLicenseInternational);
        setIsDefensiveDriverIndicatorVisibleState(isDefensiveDriverIndicatorVisible);
        setIsDefensiveToolTipTextVisibleState(isDefensiveToolTipTextVisible);
        setAnyDriverLicensedInPuertoOrCanadaState(anyDriverLicensedInPuertoOrCanada);
        setIsFinancialResponsibilityFormAvailableState(FeatureUtil.isFinancialResponsibilityFormAvailable(transactionVM));
    }, [drivers, getDriverAge, isDefensiveDriverDiscountAvailableForDriver, policyState, transactionVM]);

    // TNC coverage filed for auto 2.0
    // includedInTNCCoverage
    const shouldShowTncQuestion = policyState === 'WI'
        && drivers.children.some((aDriverVM) => {
            const age = getDriverAge(aDriverVM);

            return age >= 18
                && _get(aDriverVM, 'policyDriverRoleType.value.code') === 'operator'
                && age - _get(aDriverVM, 'ageFirstLicensed.value') >= 2;
        });

    useEffect(() => {
        // this is only to show label as required
        const shouldMarkLicenseNumberAndStateRequired = _get(transactionVM, 'value.quoteType') ? _get(transactionVM, 'value.quoteType') === 'Full' : true;

        const alwaysShowLabels = [
            {
                id: 'First',
                content: {
                    id: 'e1p.platform.common.First Name',
                    defaultMessage: 'First Name'
                },
                className: 'grid-label required'
            },
            {
                id: 'Middle',
                content: {
                    id: 'e1p.platform.common.Middle Initial',
                    defaultMessage: 'Middle Initial'
                },
                className: 'grid-label'
            },
            {
                id: 'Last',
                content: {
                    id: 'e1p.platform.common.Last Name',
                    defaultMessage: 'Last Name'
                },
                className: 'grid-label required'
            },
            {
                id: 'Suffix',
                content: {
                    id: 'e1p.platform.common.Suffix',
                    defaultMessage: 'Suffix'
                },
                className: 'grid-label'
            },
            {
                id: 'Date',
                content: {
                    id: 'e1p.platform.common.Date of Birth',
                    defaultMessage: 'Date of Birth'
                },
                className: 'grid-label required'
            },
            {
                id: 'Age',
                content: {
                    id: 'e1p.platform.common.Age',
                    defaultMessage: 'Age'
                },
                className: 'grid-label'
            },
            {
                id: 'SSN',
                content: {
                    id: 'e1p.platform.common.Social Security Number',
                    defaultMessage: 'Social Security Number'
                },
                className: 'grid-label'
            },
            {
                id: 'Gender',
                content: {
                    id: 'e1p.platform.common.Gender',
                    defaultMessage: 'Gender'
                },
                className: 'grid-label required'
            },
            {
                id: 'PolicyRole',
                content: {
                    id: 'e1p.platform.common.Role',
                    defaultMessage: 'Role'
                },
                className: 'grid-label required'
            },
            {
                id: 'Policy',
                content: {
                    id: 'e1p.platform.common.Role Type',
                    defaultMessage: 'Role Type'
                },
                className: 'grid-label required'
            },
            {
                id: 'RelationshiptoNI',
                content: {
                    id: 'e1p.platform.common.Relationship To Named Insured',
                    defaultMessage: 'Relationship to Named Insured'
                },
                className: 'grid-label required'
            },
            {
                id: 'Marital',
                content: {
                    id: 'e1p.platform.common.Marital Status',
                    defaultMessage: 'Marital Status'
                },
                className: 'grid-label required'
            },
            {
                id: 'Number',
                content: {
                    id: "e1p.platform.lob.ea.Driver's License Number",
                    defaultMessage: "Driver's License Number"
                },
                className: shouldMarkLicenseNumberAndStateRequired ? 'grid-label required' : 'grid-label'
            },
            {
                id: 'State',
                content: {
                    id: "e1p.platform.lob.ea.Driver's License State",
                    defaultMessage: "Driver's License State"
                },
                className: shouldMarkLicenseNumberAndStateRequired ? 'grid-label required' : 'grid-label'
            }
        ];

        if (anyLicenseInternationalState) {
            alwaysShowLabels.push({
                id: 'licenseCountry',
                content: {
                    id: "e1p.platform.lob.ea.Driver's License Country",
                    defaultMessage: "Driver's License Country"
                },
                className: 'grid-label'
            });
        }

        alwaysShowLabels.push({
            id: 'Year',
            component: 'div',
            content: {
                id: 'e1p.platform.lob.ea.Age First Licensed',
                defaultMessage: 'Age First Licensed'
            },
            className: 'grid-label required'
        });

        if (statesDerivingYearsExperience.includes(
            policyState
        )) {
            alwaysShowLabels.push({
                id: 'licensedInCanadaOrPuertoRicoIndLabel',
                component: 'div',
                content: {
                    id: 'e1p.platform.lob.ea.Have you ever been licensed in Canada and/or Puerto Rico?',
                    defaultMessage: 'Have you ever been licensed in Canada and/or Puerto Rico?'
                },
                className: 'grid-label required'
            });
        }

        if (statesDerivingYearsExperience.includes(policyState)
            && anyDriverLicensedInPuertoOrCanadaState) {
            alwaysShowLabels.push(
                {
                    id: 'yearsLicensedInCanadaOrPuertoRicoIndLabel',
                    component: 'div',
                    content: {
                        id: 'e1p.platform.lob.ea.Number of years of driving experience in Canada and/or Puerto Rico',
                        defaultMessage: 'Number of years of driving experience in Canada and/or Puerto Rico'
                    },
                    className: 'grid-label required'
                }
            );
        }

        // TNC coverage field for auto2.0
        if (shouldShowTncQuestion) {
            alwaysShowLabels.push({
                id: 'isIncludedInTncLabel',
                component: 'div',
                content: {
                    id: 'e1p.platform.lob.ea.drivers.Will this driver be included in TNC coverage?',
                    defaultMessage: 'Will this driver be included in TNC coverage?'
                },
                className: 'grid-label required'
            });
        }

        if (canAccessDeceaseDataPermission) {
            alwaysShowLabels.push({
                id: 'isDeceased',
                component: 'div',
                content: {
                    id: 'e1p.platform.common.Deceased',
                    defaultMessage: 'Deceased'
                },
                className: 'grid-label required'
            });
        }

        if (anyDriverDeceasedState && canAccessDeceaseDataPermission) {
            alwaysShowLabels.push({
                id: 'deceasedDate',
                component: 'div',
                content: {
                    id: 'e1p.platform.common.Deceased Date',
                    defaultMessage: 'Deceased Date'
                },
                className: 'grid-label required'
            });
        }

        if (isActiveNationalGuardIndicatorVisible) {
            alwaysShowLabels.push({
                id: 'activeNationalGuardIndContainer',
                content: [
                    {
                        id: 'activeNationalGuardInd',
                        content: translator(eaCommonMessages.activeNationalGuard,
                            {
                                state: translator({
                                    id: `typekey.State.${policyState}`,
                                    defaultMessage: policyState
                                })
                            }),
                    },
                    {
                        id: 'activeNationalGuardTooltipHelpText',
                        tooltipText: {
                            id: 'e1p.platform.lob.ea.To be eligible for the National Guard discount, the driver must also qualify for the Defensive Driver discount.',
                            defaultMessage: 'To be eligible for the National Guard discount, the driver must also qualify for the Defensive Driver discount.'
                        }
                    }
                ]
            });
        }

        if (isDefensiveDriverIndicatorVisibleState) {
            if (isDefensiveToolTipTextVisibleState) {
                alwaysShowLabels.push({
                    id: 'DefensiveDriverIndContainer',
                    content: [
                        {
                            id: 'DefensiveDriverInd',
                            content: translator(eaCommonMessages.defensiveDriverCourse)
                        },
                        {
                            id: 'defensiveTooltipHelpText',
                            tooltipText: {
                                id: 'e1p.platform.lob.ea.For the Defensive Driver discount to apply, Primary Named Insured and, if applicable, Secondary Named Insured must both be at least 55 years old and have taken a Defensive Driver course within the last three years.',
                                defaultMessage: 'For the Defensive Driver discount to apply, Primary Named Insured and, if applicable, Secondary Named Insured must both be at least 55 years old and have taken a Defensive Driver course within the last three years.'
                            }
                        }
                    ]
                });
            }
            else {
                alwaysShowLabels.push({
                    id: 'DefensiveDriverLabel',
                    type: 'container',
                    component: 'div',
                    className: 'grid-label required',
                    content: {
                        id: 'e1p.platform.lob.ea.Person has taken a Defensive Driver Course',
                        defaultMessage: 'Person has taken a Defensive Driver Course'
                    }
                });
            }
        }

        if (anyPersonLessThan25AndOperatorState) {
            alwaysShowLabels.push({
                id: 'AwayAtSchool',
                component: 'div',
                content: {
                    id: 'e1p.platform.lob.ea.Student more than 100 miles away without a vehicle',
                    defaultMessage: 'Student more than 100 miles away without a vehicle'
                },
                className: 'grid-label required'
            });
        }

        if (showAwayAtSchoolQuestionsState) {
            alwaysShowLabels.push({
                id: 'schoolCountryLabel',
                component: 'div',
                content: {
                    id: 'e1p.platform.lob.ea.School Country',
                    defaultMessage: 'School Country'
                },
                className: 'grid-label required'
            });
            alwaysShowLabels.push({
                id: 'schoolZipLabel',
                component: 'div',
                content: {
                    id: 'e1p.platform.lob.ea.School Zip Code',
                    defaultMessage: 'School Zip Code'
                },
                className: isSchoolZipCodeRequried ? 'grid-label required' : 'grid-label'
            });
        }

        if (anyPersonLessThan25AndOperatorState) {
            alwaysShowLabels.push({
                id: 'GoodStudent',
                component: 'div',
                content: {
                    id: 'e1p.platform.lob.ea.Good Student',
                    defaultMessage: 'Good Student'
                },
                className: 'grid-label required'
            });
        }

        alwaysShowLabels.push({
            id: 'militaryPersonnelToggle',
            component: 'div',
            content: {
                id: 'e1p.platform.lob.ea.Military Personnel returning from active duty',
                defaultMessage: 'Military Personnel returning from active duty'
            },
            className: 'grid-label required'
        });

        if (isFinancialResponsibilityFormAvailableState) {
            alwaysShowLabels.push({
                id: 'financialResponsibilityFilingToggle',
                component: 'div',
                content: {
                    id: 'e1p.platform.lob.ea.drivers.Financial Responsibility Filing?',
                    defaultMessage: 'Financial Responsibility Filing?'
                },
                className: 'grid-label required'
            });
        }

        setDriverLabels(alwaysShowLabels);
    }, [
        anyDriverLicensedInPuertoOrCanadaState, anyDriverDeceasedState, anyLicenseInternationalState,
        getDriverAge, isActiveNationalGuardIndicatorVisible, isDefensiveDriverDiscountAvailableForDriver,
        policyState, transactionVM, translator, canAccessDeceaseDataPermission, isDefensiveDriverIndicatorVisibleState,
        isDefensiveToolTipTextVisibleState, anyPersonLessThan25AndOperatorState, showAwayAtSchoolQuestionsState,
        isFinancialResponsibilityFormAvailableState, shouldShowTncQuestion, isSchoolZipCodeRequried
    ]);

    const getDriverFields = useCallback((driver) => {
        const startingDate = new Date();
        const driverAge = getDriverAge(driver);
        const baseFields = [
            {
                id: "driverFirstName",
                type: "field",
                component: "input",
                componentProps: {
                    path: "person.firstName",
                    required: true,
                    maxLength: 30
                }
            },
            {
                id: "middleName",
                type: "field",
                component: "input",
                componentProps: {
                    placeholder: "",
                    path: "person.middleName",
                    maxLength: 1
                }
            },
            {
                id: "driverLastName",
                type: "field",
                component: "input",
                componentProps: {
                    required: true,
                    placeholder: "",
                    path: "person.lastName",
                    maxLength: 40
                }
            },
            {
                id: "driverSuffix",
                type: "field",
                component: "dropdownSelect",
                componentProps: {
                    path: "person.suffix",
                    placeholder: ""
                }
            },
            {
                id: "driverDateOfBirth",
                type: "field",
                component: "E1PDateComponent",
                componentProps: {
                    isRequired: true,
                    onValidate: "setComponentValidation",
                    dateDTO: driver.person.dateOfBirth,
                    maxDate: {
                        year: startingDate.getFullYear() - 16,
                        month: startingDate.getMonth(),
                        day: startingDate.getDate()
                    }
                }
            },
            {
                id: "driverAge",
                type: "field",
                component: "input",
                componentProps: {
                    value: driverAge
                }
            },
            {
                id: "ssn",
                type: "container",
                component: "div",
                content: [
                    {
                        id: "ssnComponent",
                        component: "E1PSSNComponent"
                    }
                ]
            },
            {
                id: "driverGender",
                type: "field",
                component: "dropdownSelect",
                componentProps: {
                    required: true,
                    path: "person.gender",
                    placeholder: ""
                }
            },
            {
                id: "driverContactRole",
                type: "field",
                component: "dropdownSelect",
                componentProps: {
                    placeholder: "",
                    path: "role"
                }
            },
            {
                id: "policyDriverRoleTypeMainGrid",
                component: "div",
                type: "container",
                componentProps: {
                    className: "policyDriverRoleTypeDropdownMainGrid"
                },
                content: [
                    {
                        id: "policyDriverRoleType",
                        type: "field",
                        component: "dropdownSelect",
                        componentProps: {
                            required: true,
                            path: "policyDriverRoleType",
                            placeholder: ""
                        }
                    },
                    {
                        id: "policyDriverRoleTypeIcon",
                        type: "field",
                        component: "IconButton",
                        componentProps: {
                            iconClassName: "editIcon",
                            icon: "mi-edit",
                            size: "small"
                        }
                    }
                ]
            },
            {
                id: "relationshipToNI",
                type: "field",
                component: "dropdownSelect",
                componentProps: {
                    path: "relationshipToNI",
                    placeholder: ""
                }
            },
            {
                id: "maritalStatus",
                type: "field",
                component: "dropdownSelect",
                componentProps: {
                    required: true,
                    path: "person.maritalStatus",
                    placeholder: ""
                }
            },
            {
                id: "driverLicenseNumberDiv",
                type: "container",
                component: "div",
                content: [
                    {
                        id: "driverLicenseNumber",
                        type: "field",
                        component: "E1PLicenseComponent",
                        componentProps: {
                            passViewModel: true,
                            onValidate: "setComponentValidation",
                            onValueChange: "onValueChange"
                        }
                    }
                ]
            },
            {
                id: "driverLicenceState",
                type: "field",
                component: "dropdownSelect",
                componentProps: {
                    path: "person.licenseState",
                    placeholder: "",
                    searchable: true
                }
            }
        ];

        if (anyLicenseInternationalState) {
            baseFields.push(
                {
                    id: "driverLicenseCountry",
                    type: "field",
                    component: "dropdownSelect",
                    componentProps: {
                        path: "person.licenseCountry",
                        placeholder: "",
                        searchable: true
                    }
                });
        }

        baseFields.push({
            id: "driverAgeFirstLicenced",
            type: "field",
            component: "dropdownSelect",
            componentProps: {
                required: true,
                placeholder: "",
                path: 'ageFirstLicensed'
            }
        });

        if (statesDerivingYearsExperience.includes(policyState)) {
            baseFields.push({
                id: "licensedInCanadaOrPuertoRicoIndToggle",
                type: "field",
                component: "Toggle",
                componentProps: {
                    defaultValue: false,
                    controlClassName: "toggleContainer",
                    availableValues: [
                        {
                            code: "true",
                            name: {
                                id: "e1p.platform.common.Yes",
                                defaultMessage: "Yes"
                            }
                        },
                        {
                            code: "false",
                            name: {
                                id: "e1p.platform.common.No",
                                defaultMessage: "No"
                            }
                        }
                    ],
                    path: "licensedInCanadaOrPuertoRicoInd"
                }
            });
        }

        if (statesDerivingYearsExperience.includes(policyState)
            && anyDriverLicensedInPuertoOrCanadaState) {
            baseFields.push({
                id: "yearsOfDrivingExpInCanadaOrPRInput",
                type: "field",
                component: "InputNumber",
                componentProps: {
                    path: "yearsOfDrivingExpInCanadaOrPR",
                    minValue: 1,
                    required: statesDerivingYearsExperience.includes(policyState)
                        && !!_get(driver, `licensedInCanadaOrPuertoRicoInd.value`, false)
                }
            });
        }

        // show if some driver in drivers list qualifies
        if (shouldShowTncQuestion) {
            baseFields.push({
                id: "includedInTNCCoverageField",
                type: "field",
                component: "Toggle",
                componentProps: {
                    defaultValue: false,
                    controlClassName: "toggleContainer",
                    required: true,
                    availableValues: [
                        {
                            code: "true",
                            name: {
                                id: "e1p.platform.common.Yes",
                                defaultMessage: "Yes"
                            }
                        },
                        {
                            code: "false",
                            name: {
                                id: "e1p.platform.common.No",
                                defaultMessage: "No"
                            }
                        }
                    ],
                    path: "includedInTNCCoverage"
                }
            });
        }

        if (canAccessDeceaseDataPermission) {
            baseFields.push({
                id: "driverDeceased",
                type: "field",
                component: "Toggle",
                componentProps: {
                    defaultValue: false,
                    controlClassName: "toggleContainer",
                    required: canAccessDeceaseDataPermission,
                    availableValues: [
                        {
                            code: "true",
                            name: {
                                id: "e1p.platform.common.Yes",
                                defaultMessage: "Yes"
                            }
                        },
                        {
                            code: "false",
                            name: {
                                id: "e1p.platform.common.No",
                                defaultMessage: "No"
                            }
                        }
                    ],
                    path: "person.isDeceased"
                }
            });
        }

        if (anyDriverDeceasedState && canAccessDeceaseDataPermission) {
            baseFields.push(
                {
                    id: "driverDeceasedDate",
                    type: "field",
                    component: "E1PDateComponent",
                    componentProps: {
                        onValidate: "setComponentValidation",
                        isRequired: _get(driver, 'person.isDeceased.value', false) && canAccessDeceaseDataPermission,
                        dateDTO: driver.person.deceasedDate,
                        maxDate: e1pDateUtil.getFormattedCurrentDate(),
                        minDate: driver.person.dateOfBirth.value
                    }
                }
            );
        }

        if (isActiveNationalGuardIndicatorVisible) {
            const activeNationalGuardApplicableForCurrentDriver = _get(driver, 'policyDriverRoleType.value.code') === 'operator';

            if (activeNationalGuardApplicableForCurrentDriver) {
                if (_get(driver, 'activeNationalGuardMemberInd.value') === undefined) {
                    _set(driver, 'activeNationalGuardMemberInd.value', false);
                }
            } else {
                // for non-operators this will always be undefined
                _set(driver, 'activeNationalGuardMemberInd.value', undefined);
            }

            baseFields.push(
                {
                    id: "activeNationalGuardMemberInd",
                    type: "field",
                    component: "Toggle",
                    componentProps: {
                        controlClassName: "toggleContainer",
                        availableValues: [
                            {
                                code: "true",
                                name: {
                                    id: "e1p.platform.common.Yes",
                                    defaultMessage: "Yes"
                                }
                            },
                            {
                                code: "false",
                                name: {
                                    id: "e1p.platform.common.No",
                                    defaultMessage: "No"
                                }
                            }
                        ],
                        path: "activeNationalGuardMemberInd",
                        required: _get(driver, 'policyDriverRoleType.value.code') === 'operator'
                    }
                }
            );
        }

        const viewDefensiveDiscount = () => {
            const checkPNIAndSNIHasDefensiveCourse = (sni, pni) => {
                const hasTakenCourse = !!(_get(driver, 'defensiveDriverCourseInd.value', []));

                if (sni) {
                    setSNIHasDefensiveCourse(hasTakenCourse);
                } else if (pni) {
                    setPNIHasDefensiveCourse(hasTakenCourse);
                }
            };

            let isDefensiveDriverIndicatorVisible = false;

            if (_get(driver, 'policyDriverRoleType.value.code') === 'operator') {
                const isSNI = _get(driver, 'value.role') === 'sni';
                const isPNI = _get(driver, 'value.role') === 'pni';

                /**
            * IAP -123 :
            * Defensive Driver discount for "PA" should display only for PNI
            * and SNI where the Op’s age is >= 55.
            */
                if (policyState === 'PA') {
                    if (driverAge >= 55 && (isSNI || isPNI)) {
                        if (checkPNIAndSNIHasDefensiveCourse) {
                            checkPNIAndSNIHasDefensiveCourse(isSNI, isPNI);
                        }
                    }
                }

                const isActiveNationalGuard = _get(driver, 'value.activeNationalGuardMemberInd', false);
                const {
                    isDefensiveDriverDiscountAvailable
                } = isDefensiveDriverDiscountAvailableForDriver(
                    driverAge,
                    policyState,
                    isPNI,
                    isSNI,
                    _get(driver, 'policyDriverRoleType.value.code'),
                    isActiveNationalGuard
                );

                isDefensiveDriverIndicatorVisible = isDefensiveDriverDiscountAvailable;
            }

            return isDefensiveDriverIndicatorVisible;
        };

        if (isDefensiveDriverIndicatorVisibleState) {
            baseFields.push({
                component: "Grid",
                id: "defensiveDriverGrid",
                type: "container",
                componentProps: {
                    columns: [
                        "3fr",
                        "1fr"
                    ],
                    vgap: "small",
                    visible: viewDefensiveDiscount()
                },
                content: [
                    {
                        id: "takenDefensiveDriverCourse",
                        type: "field",
                        component: "Toggle",
                        componentProps: {
                            controlClassName: "toggleContainer",
                            availableValues: [
                                {
                                    code: "true",
                                    name: {
                                        id: "e1p.platform.common.Yes",
                                        defaultMessage: "Yes"
                                    }
                                },
                                {
                                    code: "false",
                                    name: {
                                        id: "e1p.platform.common.No",
                                        defaultMessage: "No"
                                    }
                                }
                            ],
                            path: "defensiveDriverCourseInd",
                            required: _get(driver, 'policyDriverRoleType.value.code') === 'operator'
                        }
                    },
                    {
                        id: "showDefensiveDriverInfo",
                        type: "action",
                        component: "IconButton",
                        componentProps: {
                            icon: "mi-edit"
                        }
                    }
                ]
            });
        }

        if (anyPersonLessThan25AndOperatorState) {
            baseFields.push({
                id: "personAwayAtSchool",
                type: "field",
                component: "Toggle",
                componentProps: {
                    controlClassName: "toggleContainer",
                    availableValues: [
                        {
                            code: "true",
                            name: {
                                id: "e1p.platform.common.Yes",
                                defaultMessage: "Yes"
                            }
                        },
                        {
                            code: "false",
                            name: {
                                id: "e1p.platform.common.No",
                                defaultMessage: "No"
                            }
                        }
                    ],
                    path: "awayAtSchoolInd",
                    required: !!(driverAge && driverAge < 25 && _get(driver, 'policyDriverRoleType.value.code') === 'operator')
                }
            });
        }

        if (showAwayAtSchoolQuestionsState) {
            baseFields.push({
                id: "driverAwayAtSchoolCountryId",
                type: "field",
                component: "dropdownSelect",
                componentProps: {
                    path: "awayAtSchoolCountry",
                    placeholder: "",
                    required: driver.awayAtSchoolInd.value
                }
            });
            baseFields.push({
                id: "driverAwayAtSchoolZipId",
                type: "field",
                component: "input",
                componentProps: {
                    placeholder: "",
                    path: "awayAtSchoolZipCode",
                    // Required only if person is away at school and country selected is US
                    required: !!(driver.awayAtSchoolInd.value && _get(driver, 'awayAtSchoolCountry.value.code') === 'US')
                }
            });
        }

        if (anyPersonLessThan25AndOperatorState) {
            baseFields.push({
                component: "Grid",
                id: "goodStudentDiscountGrid",
                type: "container",
                componentProps: {
                    columns: [
                        "3fr",
                        "1fr"
                    ],
                    gap: "none"
                },
                content: [
                    {
                        id: "goodStudentDiscount",
                        type: "field",
                        component: "Toggle",
                        componentProps: {
                            controlClassName: "toggleContainer",
                            availableValues: [
                                {
                                    code: "true",
                                    name: {
                                        id: "e1p.platform.common.Yes",
                                        defaultMessage: "Yes"
                                    }
                                },
                                {
                                    code: "false",
                                    name: {
                                        id: "e1p.platform.common.No",
                                        defaultMessage: "No"
                                    }
                                }
                            ],
                            path: "goodStudentInd",
                            required: !!(driverAge && driverAge < 25 && _get(driver, 'policyDriverRoleType.value.code') === 'operator')
                        }
                    }
                ]
            });
        }

        baseFields.push({
            component: "Grid",
            id: "militaryDeploymentToggleGrid",
            type: "container",
            componentProps: {
                columns: [
                    "3fr",
                    "1fr"
                ],
                vgap: "small"
            },
            content: [
                {
                    id: "militaryDeploymentToggle",
                    type: "field",
                    component: "Toggle",
                    componentProps: {
                        className: "militaryDeployment",
                        path: "militaryDeploymentInd",
                        availableValues: [
                            {
                                id: "true",
                                displayName: {
                                    id: "e1p.platform.common.Yes",
                                    defaultMessage: "Yes"
                                }
                            },
                            {
                                id: "false",
                                displayName: {
                                    id: "e1p.platform.common.No",
                                    defaultMessage: "No"
                                }
                            }
                        ]
                    }
                },
                {
                    id: "showMilitaryDeploymentInfo",
                    type: "action",
                    component: "IconButton",
                    componentProps: {
                        iconClassName: "editIcon",
                        icon: "mi-edit"
                    }
                }
            ]
        });

        if (isFinancialResponsibilityFormAvailableState) {
            baseFields.push(
                {
                    id: "financialResponsibilityFiling",
                    type: "container",
                    component: "div",
                    content: [
                        {
                            id: "financialResponsibilityFilingToggle",
                            type: "field",
                            component: "Toggle",
                            componentProps: {
                                controlClassName: "toggleContainer",
                                availableValues: [
                                    {
                                        id: "true",
                                        displayName: {
                                            id: "e1p.platform.common.Yes",
                                            defaultMessage: "Yes"
                                        }
                                    },
                                    {
                                        id: "false",
                                        displayName: {
                                            id: "e1p.platform.common.No",
                                            defaultMessage: "No"
                                        }
                                    }
                                ]
                            }
                        }, {
                            id: "financialResponsibilityFilingType",
                            type: "field",
                            component: "dropdownSelect",
                            componentProps: {
                                path: "financialResponsibility.filingForm",
                                label: {
                                    id: "e1p.platform.common.Type",
                                    defaultMessage: "Type"
                                },
                                availableValues: [
                                    {
                                        code: "form_sr22",
                                        name: "SR22"
                                    },
                                    {
                                        code: "form_fr44",
                                        name: "FR44"
                                    }
                                ]
                            }
                        },
                        {
                            id: "financialResponsibilityFilingSubtype",
                            type: "field",
                            component: "dropdownSelect",
                            componentProps: {
                                path: "financialResponsibility.filingFormSubtype",
                                labelClassName: "financialResponsibilityFilingSubtype",
                                label: {
                                    id: "e1p.platform.common.Subtype",
                                    defaultMessage: "Subtype"
                                },
                                availableValues: [
                                    {
                                        code: "owner",
                                        name: "Owner"
                                    },
                                    {
                                        code: "operator",
                                        name: "Operator"
                                    }
                                ]
                            }
                        }
                    ]
                }
            );
        }

        return baseFields;
    }, [getDriverAge, anyLicenseInternationalState, policyState, anyDriverLicensedInPuertoOrCanadaState, shouldShowTncQuestion, canAccessDeceaseDataPermission, anyDriverDeceasedState, isActiveNationalGuardIndicatorVisible, isDefensiveDriverIndicatorVisibleState, anyPersonLessThan25AndOperatorState, showAwayAtSchoolQuestionsState, isFinancialResponsibilityFormAvailableState, setSNIHasDefensiveCourse, setPNIHasDefensiveCourse, isDefensiveDriverDiscountAvailableForDriver]);

    useEffect(() => {
        const tempFields = driverFields;

        drivers.children.forEach((driver, index) => {
            tempFields[index] = getDriverFields(driver);

        })
        setDriverFields(tempFields);
    }, [driverFields, getDriverFields, drivers.children])

    const showNonOperatorModal = useCallback(
        (vm) => {
            const componentProps = {
                title: 'Non-Operator Status',
                iconClassType: false,
                showCloseBtn: true,
                showCancelBtn: true,
                driverVM: vm,
                viewModelService,
                onValidate: setComponentValidation,
                id: 'nonOperatorComponent',
                viewOnlyMode,
                driverPageDisregardFieldValidation: disregardFieldValidation
            };

            return modalApi.showModal(
                <NonOperatorComponent {...componentProps} />
            );
        },
        [
            viewModelService, setComponentValidation,
            disregardFieldValidation, viewOnlyMode, modalApi
        ]
    );

    const showDefensiveDriverModal = useCallback((vm) => {
        const componentProps = {
            title: 'Defensive Driver Course Details',
            iconClassType: false,
            showCloseBtn: true,
            showCancelBtn: true,
            driverVM: vm,
            viewModelService,
            viewOnlyMode,
            policyState
        };

        return modalApi.showModal(
            <DefensiveDriverComponent {...componentProps} />
        );
    }, [viewModelService, viewOnlyMode, policyState, modalApi]);

    const handleValueChange = useCallback((value, changedPath) => {
        let fullPath = `${path}.${changedPath}`;

        if (onValueChange) {
            if (isAdditionalInfo) {
                fullPath = changedPath;
            }

            onValueChange(value, fullPath);
        }
    }, [isAdditionalInfo, onValueChange, path]);

    const onViewCourseDetails = useCallback(
        (driver) => {
            showDefensiveDriverModal(driver).then((wrapper) => {
                _set(driver, 'defenseDriverCompletedDueToViolationInd', wrapper.driverVM.defenseDriverCompletedDueToViolationInd.value);
                _set(driver, 'defenseDrvCourseDate', wrapper.driverVM.defenseDrvCourseDate.value);
                _set(driver, 'defenseDrvCourseType', wrapper.driverVM.defenseDrvCourseType.value.code);
            }).catch((error) => {
                // control will come here if user closes modal by clicking on cancel.
                // Also shouldCloseOnOverlayClick and shouldCloseOnEsc is _set to false in Defensive driver component.
                // IAP-2028, if no selection is made on the popup then defensiveDriverCourseInd should be _set to false
                if (_get(driver, 'value.defenseDriverCompletedDueToViolationInd') === undefined
                    && _get(driver, 'value.defenseDrvCourseDate') === undefined
                    && _get(driver, 'value.defenseDrvCourseType') === undefined) {
                    handleValueChange(false, 'defensiveDriverCourseInd');
                }

                ConsoleHelper(`user closed the modal. Error: ${error}`, 'INFO')
            }
            );
        }, [handleValueChange, showDefensiveDriverModal]
    );

    const onShowNonOperatorModal = useCallback(
        (clickedOnEditIcon, driver, changedPath) => {
            showNonOperatorModal(driver)
                .then((wrapper) => {
                    // chose a type and said OK
                    _set(driver, 'nonOperatorOtherDescText', wrapper.driverVM.nonOperatorOtherDescText.value);
                    _set(driver, 'nonOperatorDescType', wrapper.driverVM.nonOperatorDescType.value);

                    // Will have been _set for not licensed by choice or suspense/revoke license
                    if (wrapper.driverVM.value.suspRevokePleaseExplain) {
                        _set(driver, 'suspRevokePleaseExplain', wrapper.driverVM.suspRevokePleaseExplain.value);
                    } else {
                        // User could have started out with this choice and then changed it
                        _set(driver, 'suspRevokePleaseExplain', undefined);
                    }

                    // Set the filing indicator to false when the role type changed from operator to non-operator
                    if (_get(driver, ['financialResponsibility', 'filingInd', 'value'], false)) {
                        _set(driver, 'financialResponsibility.filingInd', false);
                    }

                    handleValueChange('nonoperator', changedPath);
                })
                .catch(() => {
                    // cancelled
                    if (!clickedOnEditIcon) {
                        _set(driver, 'nonOperatorOtherDescText', undefined);
                        _set(driver, 'nonOperatorDescType', undefined);
                        _set(driver, 'suspRevokePleaseExplain', undefined);
                        handleValueChange(undefined, changedPath);
                    }
                });
        }, [handleValueChange, showNonOperatorModal]);

    /**
   * Helper callback for showing the EA Military Deployment Modal window.
   */
    const showEAMilitaryDeploymentModal = useCallback(
        async (driver, index) => {
            const componentProps = {
                id: 'militaryDeploymentToggle',
                title: eaCommonMessages.addMilitaryDeploymentInfo,
                driverVM: driver,
                index,
                authHeader,
                viewModelService,
                showCloseBtn: true,
                showCancelBtn: true,
                periodStartDate,
                priorPolicyList,
                viewOnlyMode,
                onValidate: setComponentValidation,
                driverPageDisregardFieldValidation: disregardFieldValidation,
                onValueChange,
                policyState,
                getAvailableValuesForPriorPolicyUpdateReasons
            };
            const result = await modalApi.showModal(<EAMilitaryDeploymentModal {...componentProps} />);

            return result;
        },
        [
            authHeader,
            periodStartDate,
            priorPolicyList,
            viewModelService,
            viewOnlyMode,
            modalApi,
            setComponentValidation,
            disregardFieldValidation,
            onValueChange,
            policyState,
            getAvailableValuesForPriorPolicyUpdateReasons
        ]
    );

    const onChangeOfMilitaryDeploymentToggle = useCallback((value, driver, index) => {
        if (value) {
            showEAMilitaryDeploymentModal(driver, index).then((response) => {
                if (onValueChange) {
                    onValueChange(response.priorPolicyUpdatesList,
                        'lobData.personalAuto_EA.priorPolicyUpdates');
                }
            });
        } else {
            const role = _get(driver, 'value.role', '') === 'sni' ? 'PolicySecNamedInsured' : 'PolicyPriNamedInsured';
            const integrationId = role === 'PolicyPriNamedInsured'
                ? _get(driver, '_parent._parent._parent.primaryNamedInsured.integrationId.value', '')
                : _get(driver, '_parent._parent._parent.secondaryNamedInsured.integrationId.value', '');
            const updatedPriorPolicyUpdates = removeMilitaryDeployments(
                integrationId, priorPolicyList
            );

            if (onValueChange) {
                onValueChange(updatedPriorPolicyUpdates,
                    'lobData.personalAuto_EA.priorPolicyUpdates');
            }
        }
    }, [
        onValueChange,
        priorPolicyList,
        removeMilitaryDeployments,
        showEAMilitaryDeploymentModal
    ]);

    const getMilitaryDeploymentToggleValue = useCallback((driver) => {
        const role = _get(driver, 'value.role', '') === 'sni' ? 'PolicySecNamedInsured' : 'PolicyPriNamedInsured';
        const integrationId = role === 'PolicyPriNamedInsured'
            ? _get(driver, '_parent._parent._parent.primaryNamedInsured.integrationId.value', '')
            : _get(driver, '_parent._parent._parent.secondaryNamedInsured.integrationId.value', '');
        const indexPresent = getMilitaryDeploymentRecordIndex(integrationId, priorPolicyList);

        return indexPresent > -1;
    }, [getMilitaryDeploymentRecordIndex, priorPolicyList]);

    const availableValuesForSuffix = useMemo(() => {
        const typelistWithNoneCode = [{
            code: 'None',
            name: translator(e1pCommonMessages.none)
        }];

        return typelistWithNoneCode.concat(
            TypekeyUtil.getAvailableValuesForTypekey(viewModelService, 'NameSuffix')
        );
    }, [translator, viewModelService]);

    const getStateSpecificAvailableValuesForGender = useCallback((driver) => {
        const genderTypelistMetadata = driver.person.gender.aspects.availableValues[0].typelist;
        const stateSpecificFilterName = `EAGenderFilter_${policyState}_Ext`;
        // Default gender types - male/female applicable to all states unless stated explicitly
        let genderTypes = _find(genderTypelistMetadata.filters, {
            name: 'EAGenderFilter_Ext'
        }).codes;

        // Check if there exist state specific filter - if so, use values associated with that filter
        if (genderTypelistMetadata.hasFilter(stateSpecificFilterName)) {
            genderTypes = genderTypelistMetadata.getFilter(stateSpecificFilterName).codes;
        }

        const availableValues = genderTypes.map((typeCode) => ({
            code: typeCode.code,
            name: translator({ id: typeCode.name })
        }));

        return availableValues;
    }, [policyState, translator]);

    const getRelationshiptoNIValues = useCallback((driver) => {
        let relationshipToNI = [];

        relationshipToNI = driver.relationshipToNI.aspects.availableValues.filter((value) => value.code !== 'trustee');

        // We are removing trustee from the available dropdown
        return relationshipToNI.map(
            (feature) => ({
                code: feature.code,
                name: {
                    defaultMessage: feature.name.replace(/([A-Z])/g, ' $1').trim(),
                    id: feature.name
                }
            })
        );
    }, []);

    const onChangeOfDriverContactRole = useCallback(
        (driverContactRole, changedPath, driver) => {
            _set(driver, 'value.role', driverContactRole);
            handleValueChange(driverContactRole, changedPath);
        }, [handleValueChange]);

    const onRelationshipToNIChanges = useCallback(
        (relationshipToNI, changedPath, driverVM) => {
            _set(driverVM, 'value.relationshipToNI', relationshipToNI);
            handleValueChange(relationshipToNI, changedPath);

            if (relationshipToNI === 'spouse') {
                // Changing existing sni's role(whose relations to pni is not spouse )
                // to policy driver
                const driversList = _get(drivers, 'children', []);
                const driverWithSniRoleAndNotSpouse = driversList
                    ?.filter((driver) => _get(driver, 'value.role') === 'sni'
                        && _get(driver, 'value.relationshipToNI') !== 'spouse');

                driverWithSniRoleAndNotSpouse.forEach((driver) => {
                    _set(driver, 'value.role', 'policyDriver');
                });

                const rolePath = `${changedPath.split('.')[0]}.role`;

                // If relationship to pni is spouse then marking this driver as sni
                onChangeOfDriverContactRole('sni', rolePath, driverVM);
            }
        },
        [drivers, handleValueChange, onChangeOfDriverContactRole]
    );

    const onDriverLicenseCountryChange = useCallback((value, changedPath) => {
        handleValueChange(value, changedPath);
        // adding license country on driver obj
        handleValueChange(value, `${changedPath.split('.')[0]}licenseCountry`);
    }, [handleValueChange]);

    const onDriverLicenseStateChange = useCallback((value, changedPath, driverVM) => {
        handleValueChange(value, changedPath);

        const indexObject = changedPath.split('.')[0];

        // adding license state on driver obj
        handleValueChange(value, `${indexObject}licenseState`);

        if (value !== 'INT_ext') {
            // IAP-1697, when licenseState is any US state, 
            // then _set license country to undefined
            onDriverLicenseCountryChange(undefined, `${indexObject}.person.licenseCountry`);

            if (onValueChange) {
                if (driverVM.value?.role === 'pni') {
                    onValueChange(undefined, 'lobData.personalAuto_EA.primaryNamedInsured.person.licenseCountry');
                    onValueChange(undefined, 'baseData.accountHolder_Ext.value.licenseCountry');
                } else if (driverVM.value?.role === 'sni') {
                    onValueChange(undefined, 'lobData.personalAuto_EA.secondaryNamedInsured.person.licenseCountry');
                }
            }
        }
    }, [handleValueChange, onDriverLicenseCountryChange, onValueChange]);

    const getAvailableValuesForLicenseCountry = useCallback((driver) => {
        let licenseCountry = [];
        const isInternational = driver.person.licenseState.value?.code === 'INT_ext';

        if (isInternational) {
            licenseCountry = driver.person.licenseCountry
                .aspects.availableValues.filter((value) =>
                    // eslint-disable-next-line camelcase
                    Jurisdictions_Countries.indexOf(value.code) !== -1
                );

            // We are removing trustee from the available dropdown
            return licenseCountry.map(
                (feature) => ({
                    code: feature.code,
                    name: {
                        defaultMessage: feature.name.replace(/([A-Z])/g, ' $1').trim(),
                        id: feature.name
                    }
                })
            );
        }

        return undefined;
    }, []);

    const isDriverNonOperatorWithLicenseNotReqReasons = (driver) => {
        const msaLicenseNotNeeded = driver.policyDriverRoleType === 'nonoperator'
            && (driver.nonOperatorDescType === 'NBL' || driver.nonOperatorDescType === 'NCL');

        const connectLicenseNotNeeded = driver.policyDriverRoleType === 'nonoperator';

        return opCo === 'CONNECT' ? connectLicenseNotNeeded : msaLicenseNotNeeded;
    };

    const driverAgeFirstLicencedAvailableValues = useCallback((dateOfBirth) => {
        const today = new Date();
        const currentYear = today.getUTCFullYear();
        const earliestYear = _isNil(dateOfBirth)
            ? currentYear - ageAllowedToDrive : (dateOfBirth.year + ageAllowedToDrive);

        if (earliestYear > currentYear) {
            // return an empty object instead of just an empty array so that
            // the dropdown menu will show "No options"
            return [{}];
        }

        // _.range is not inclusive. Need to add -1 to _get correct years
        const month = _isNil(dateOfBirth) ? 0 : dateOfBirth.month;
        const MONTH_DIFF = today.getMonth() - month;
        let topAge = currentYear - earliestYear + ageAllowedToDrive + 1;

        if ((MONTH_DIFF < 0)
            || ((MONTH_DIFF === 0)
                && (today.getDate() < moment(dateOfBirth).date()))
        ) {
            topAge += -1;
        }

        const ageRange = _range(ageAllowedToDrive, topAge);

        return ageRange
            .map(_toString)
            .map((age) => ({
                code: age,
                name: age
            }));
    }, [ageAllowedToDrive]);

    const onRemoveDriver = useCallback(
        (evt) => {

            const driverListPath = 'lobData.personalAuto_EA.coverables.drivers.value';
            const driverPath = evt.path;
            const driver = _get(transactionVM, driverPath).value;
            const driverList = _get(transactionVM, driverListPath);
            const totalDriversCount = (driverList.length) - 1;
            const driverIndex = driverList.findIndex((driverFromList) => _isEqual(driverFromList, driver));
            let { removeDriverTitle } = eaCommonMessages;
            let { removeDriverDescription } = eaCommonMessages;
            const isRemovingSNIDriver = driver.role === 'sni';

            if (isRemovingSNIDriver) {
                removeDriverTitle = eaCommonMessages.deleteSNIDriverConfirmationTitle;
                removeDriverDescription = eaCommonMessages.deleteSNIDriverConfirmationMessage;
            }

            if (driver.prefillDriverID) {
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: removeDriverTitle,
                    message: removeDriverDescription,
                    confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'DRIVER' }),
                    cancelButtonText: e1pCommonMessages.cancel
                }).then((result) => {
                    if (result !== 'cancel') {
                        if (!_isEmpty(_get(transactionVM, 'value.lobData.personalAuto_EA.prefillDrivers'))) {
                            const prefillDriverIndex = _findIndex(transactionVM.lobData.personalAuto_EA.prefillDrivers.value, (prefillDriver) => prefillDriver.prefillDriverID === driver.prefillDriverID);

                            if (prefillDriverIndex > -1) {
                                _set(transactionVM, `value.lobData.personalAuto_EA.prefillDrivers.${prefillDriverIndex}.driverStatus`, 'NotInHousehold');
                            }
                        }

                        // remove driver
                        // splice is faster and less confusing than _.remove
                        driverList.splice(driverIndex, 1);

                        // remove vehicle driver
                        const vehicleListPath = 'value.lobData.personalAuto_EA.coverables.vehicles';
                        const vehicles = _get(transactionVM, vehicleListPath, []);
                        const newVehicles = [...vehicles];

                        newVehicles.forEach((vehicle) => {
                            _remove(vehicle.vehicleDrivers, (vehicleDriver) => vehicleDriver.driverID === driver.fixedID);
                        });
                        _set(transactionVM, vehicleListPath, newVehicles);
                        _set(transactionVM, driverListPath, driverList);
                        // Need to remove last driver index's id as that driver will be moved to previous driver's id
                        // on delete of any driver and then we have to remove the extra id i.e of last driver.
                        // custom components in driver component need to be be de-reged on remove
                        disregardFieldValidation(`driverDateOfBirth${totalDriversCount}`);
                        disregardFieldValidation(`driverLicenseNumber${totalDriversCount}`);

                        if (isRemovingSNIDriver) {
                            removeSni();
                        }

                        updateWizardData(transactionVM);
                        setCheckScrolling(true);

                        // IAP-4225, if existing driver is removed, then we need to call save API
                        if (driver.publicID) {
                            if (!isComponentValid) {
                                showValidationMessage();
                            }

                            setIsExistingDriverDeleted(true);
                        }

                        if (setIsAccountContactsFetched) { setIsAccountContactsFetched(false); }
                    }
                });
            } else {
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: removeDriverTitle,
                    message: removeDriverDescription,
                    confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'DRIVER' }),
                    cancelButtonText: e1pCommonMessages.cancel
                }).then((result) => {
                    if (result !== 'cancel') {
                        // splice is faster and less confusing than _.remove
                        driverList.splice(driverIndex, 1);

                        // remove vehicle driver
                        const vehicleListPath = 'value.lobData.personalAuto_EA.coverables.vehicles';
                        const vehicles = _get(transactionVM, vehicleListPath, []);
                        const newVehicles = [...vehicles];

                        newVehicles.forEach((vehicle) => {
                            _remove(vehicle.vehicleDrivers, (vehicleDriver) => vehicleDriver.driverID === driver.fixedId);
                        });
                        _set(transactionVM, vehicleListPath, newVehicles);
                        _set(transactionVM, driverListPath, driverList);
                        disregardFieldValidation(`driverDateOfBirth${totalDriversCount}`);
                        disregardFieldValidation(`driverLicenseNumber${totalDriversCount}`);

                        if (isRemovingSNIDriver) {
                            removeSni();
                        }

                        updateWizardData(transactionVM);
                        setCheckScrolling(true);

                        // IAP-4225, if existing driver is removed, then we need to call save API
                        if (driver.publicID) {
                            if (!isComponentValid) {
                                showValidationMessage();
                            }

                            setIsExistingDriverDeleted(true);
                        }

                        if (setIsAccountContactsFetched) { setIsAccountContactsFetched(false); }
                    }
                });
            }
        },
        [
            transactionVM, translator, disregardFieldValidation, updateWizardData,
            setCheckScrolling, removeSni, isComponentValid, setIsExistingDriverDeleted,
            showValidationMessage, modalApi, setIsAccountContactsFetched
        ]
    );

    const getDriverName = (driver) => {
        let titleString = '';

        if (driver.person.firstName.value) { titleString = driver.person.firstName.value; }

        if (driver.person.middleName.value) { titleString = `${titleString} ${driver.person.middleName.value}.`; }

        if (driver.person.lastName.value) { titleString = `${titleString} ${driver.person.lastName.value}`; }

        if (driver.person.suffix.value?.code) {
            titleString = `${titleString}, ${translator({
                id: driver.person.suffix.value.name,
                defaultValue: driver.person.suffix.value.code
            })}`;
        }

        return titleString === '' ? translator(eaCommonMessages.newDriver) : titleString;
    }

    const formatLabel = (labelObject) => {
        if (labelObject.id === 'activeNationalGuardIndContainer' || labelObject.id === 'DefensiveDriverIndContainer') {
            return (
                <div id={labelObject.id} className="grid-label grid-label-icon">
                    <span id={labelObject.content[0].id} className="required">
                        {labelObject.content[0].content}
                    </span>
                    <Tooltip
                        id={labelObject.content[1].id}
                        trigger="mouseenter"
                        content={translator(labelObject.content[1].tooltipText)}
                    >
                        <IconButton
                            visible
                            icon="mi-help-outline"
                        />
                    </Tooltip>
                </div>
            )
        }

        return (<div id={labelObject.id} className={labelObject.className}>
            {translator(labelObject.content)}
        </div>)
    };

    const formatField = (row, column) => {
        const driver = drivers.children[column - 1];
        const driverData = driverFields[column - 1];
        const driverField = driverData ? driverData[row - 2] : undefined;
        const policyDriverRoleType = _get(driver, 'policyDriverRoleType.value.code');
        const driverAge = getDriverAge(driver);
        const hideLicenseNumberAndState = isDriverNonOperatorWithLicenseNotReqReasons(
            driver.value
        );
        const isLicenseRequiredForDriver = checkLicenseRequired(driver);
        let readOnlyValue = false;
        let areDropdownAvailableValuesPresent = true;
        let className;

        if (driverField.id === 'relationshipToNI') {
            const fieldPath = driverField.componentProps.path ? `[${column - 1}].${driverField.componentProps.path}` : undefined;
            const value = driverField.componentProps.value ? driverField.componentProps.value : _get(driver, `${driverField.componentProps.path}.value`);

            if (driver.value?.role !== 'pni') {
                return (<DropdownSelectField
                    id={`${driverField.id}${column - 1}`}
                    alwaysShowPlaceholder={false}
                    placeholder=''
                    availableValues={getRelationshiptoNIValues(driver)}
                    onValueChange={(changedValue) => { onRelationshipToNIChanges(changedValue, fieldPath, driver) }}
                    value={value}
                    hideLabel
                    required={driver.value?.role !== 'pni'}
                    path={fieldPath}
                    readOnly={viewOnlyMode || readOnlyValue}
                    className={className}
                    showErrors={showErrors}
                    showRequired
                    onValidationChange={setComponentValidation}
                />)
            }

            return (
                <InputField
                    id={`${driverField.id}${column - 1}`}
                    hideLabel
                    value={translator(e1pCommonMessages.self)}
                    readOnly
                    showErrors={showErrors}
                    showRequired
                    onValidationChange={setComponentValidation}
                />
            )

        }

        if (driverField.component === 'input') {
            const fieldPath = driverField.componentProps.path ? `[${column - 1}].${driverField.componentProps.path}` : undefined;
            let value = driverField.componentProps.value ? driverField.componentProps.value : _get(driver, `${driverField.componentProps.path}.value`);

            if (driverField.id === 'driverAge') {
                readOnlyValue = true;

                if (driver.person.dateOfBirth.value === undefined) {
                    value = 'Current Age';
                }
            }
            else if (driverField.id === 'driverAwayAtSchoolZipId' && (driver.awayAtSchoolInd.value === undefined || !driver.awayAtSchoolInd.value)) {
                return (<GridItem key={`${driverField.id}${column - 1}`} />);
            }

            return (
                <InputField
                    id={`${driverField.id}${column - 1}`}
                    path={fieldPath}
                    hideLabel
                    required={driverField.componentProps.required}
                    maxLength={driverField.componentProps.maxLength}
                    placeholder=''
                    onValueChange={handleValueChange}
                    value={value}
                    readOnly={viewOnlyMode || readOnlyValue}
                    className={className}
                    showErrors={showErrors}
                    showRequired
                    onValidationChange={setComponentValidation}
                />
            )
        }

        if (driverField.component === 'dropdownSelect') {
            const fieldPath = driverField.componentProps.path ? `[${column - 1}].${driverField.componentProps.path}` : undefined;
            let value = driverField.componentProps.value ? driverField.componentProps.value : _get(driver, `${driverField.componentProps.path}.value`);
            let onChange = handleValueChange;
            let { availableOptions } = driverField;
            let isDropDownValueRequired = driverField.componentProps.required;
            let defaultValue;

            if (driverField.id === 'driverSuffix') {
                onChange = (suffixValue, suffixPath) => {
                    let updatedValue = suffixValue;

                    if (suffixValue === 'None') {
                        updatedValue = undefined;
                    }

                    handleValueChange(updatedValue, suffixPath);
                };
                availableOptions = availableValuesForSuffix
            } else if (driverField.id === 'driverGender') {
                availableOptions = getStateSpecificAvailableValuesForGender(driver);
            } else if (driverField.id === 'driverContactRole') {
                availableOptions = [
                    {
                        code: 'sni',
                        name: 'Secondary Named Insured'
                    },
                    {
                        code: 'policyDriver',
                        name: 'Policy Driver'
                    }
                ];
                onChange = (changedValue) => { onChangeOfDriverContactRole(changedValue, fieldPath, driver) };

                if (driver.value?.role === 'pni') {
                    value = 'Primary Named Insured';
                    readOnlyValue = true;
                } else if (driver.value?.role === 'sni') {
                    value = 'sni';
                    readOnlyValue = _get(transactionVM, 'lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value') === _get(driver, 'person.publicID.value');
                } else {
                    value = 'policyDriver'
                }
            } else if (driverField.id === 'maritalStatus') {
                availableOptions = maritalStatusAvailableValues;
                /**
                * If component is readonly, available values should have type codes which are present on the actual field
                * otherwise it throws error
                * Making sure that maritalStatusAvailableValues are not empty in viewOnlyMode(as it may take time to call api and get the values)
                */
                areDropdownAvailableValuesPresent = !_isEmpty(maritalStatusAvailableValues)
            }
            else if (driverField.id === 'driverLicenceState') {
                if (hideLicenseNumberAndState) {
                    return (<GridItem key={`licenseNotNeeded${row}${column - 1}`} />)
                }

                onChange = (newValue, changedPath) => {
                    onDriverLicenseStateChange(newValue, changedPath, driver)
                }

                availableOptions = _get(driver, `${driverField.componentProps.path}.aspects.availableValues`).map((stateObj) => ({
                    code: stateObj.code,
                    name: translator({
                        id: stateObj.name,
                        defaultMessage: stateObj.name
                    })
                }));
                isDropDownValueRequired = isLicenseRequiredForDriver;
            } else if (driverField.id === 'driverLicenseCountry') {
                if (driver.person.licenseState.value?.code !== 'INT_ext') {
                    return (<GridItem key={`${driverField.id}${column - 1}`} />)
                }

                onChange = onDriverLicenseCountryChange
                availableOptions = getAvailableValuesForLicenseCountry(driver);
            } else if (driverField.id === 'driverAgeFirstLicenced') {
                availableOptions = driverAgeFirstLicencedAvailableValues(_get(driver, 'person.dateOfBirth.value'));
                defaultValue = 16;
                onChange = (newValue, changedPath) => {
                    handleValueChange(newValue, changedPath);

                    // reset tnc cov ind to undefined if driver has less than 2 years of experience
                    if (policyState === 'WI' && driverAge - newValue < 2) {
                        handleValueChange(undefined, `[${column - 1}].includedInTNCCoverage`);
                    }
                }
            } else if (driverField.id === 'driverAwayAtSchoolCountryId') {
                if (driver.awayAtSchoolInd.value === undefined || !driver.awayAtSchoolInd.value) {
                    return (<GridItem key={`${driverField.id}${column - 1}`} />);
                }

                onChange = (newValue, changedPath) => {
                    // If selected country is US and zipcode is not entered
                    if (newValue === 'US' && !_get(driver, 'awayAtSchoolZipCode.value')) {
                        setComponentValidation(false, `[${column - 1}].awayAtSchoolZipCode`);
                    } else {
                        setComponentValidation(true, `[${column - 1}].awayAtSchoolZipCode`);
                    }

                    handleValueChange(newValue, changedPath);
                }
                availableOptions = _get(driver, `${driverField.componentProps.path}.aspects.availableValues`).map((stateObj) => ({
                    code: stateObj.code,
                    name: translator({
                        id: stateObj.name,
                        defaultMessage: stateObj.name
                    })
                }));
            }

            return (
                <DropdownSelectField
                    id={`${driverField.id}${column - 1}`}
                    alwaysShowPlaceholder={false}
                    placeholder=''
                    availableValues={availableOptions}
                    onValueChange={onChange}
                    value={value}
                    hideLabel
                    required={isDropDownValueRequired}
                    path={fieldPath}
                    readOnly={(viewOnlyMode || readOnlyValue) && areDropdownAvailableValuesPresent}
                    className={className}
                    defaultValue={defaultValue}
                    showErrors={showErrors}
                    showRequired
                    onValidationChange={setComponentValidation}
                    searchable={driverField.componentProps.searchable}
                />
            )
        }

        if (driverField.component === 'E1PDateComponent') {
            let updateDate;

            if (driverField.id === 'driverDateOfBirth') {
                updateDate = () => {
                    handleValueChange(driver.person.dateOfBirth.value, `[${column - 1}].person.dateOfBirth`);

                    // reset tnc cov ind to undefined if driver has less than 18 years of age
                    if (policyState === 'WI' && getDriverAge(driver) < 18) {
                        handleValueChange(undefined, `[${column - 1}].includedInTNCCoverage`);
                    }
                }
            }
            else if (driverField.id === 'driverDeceasedDate') {
                if (!_get(driver, 'person.isDeceased.value', false)
                    || !['pni', 'sni'].includes(_get(driver, 'value.role'))) {
                    return (<GridItem key={`${driverField.id}${column - 1}`} />);
                }

                updateDate = () => handleValueChange(driver.person.deceasedDate.value, `[${column - 1}].person.deceasedDate`);
            }

            return (
                <E1PDateComponent
                    id={`${driverField.id}${column - 1}`}
                    isRequired={driverField.componentProps.isRequired}
                    onValidate={setComponentValidation}
                    updateDateDto={updateDate}
                    dateDTO={driverField.componentProps.dateDTO}
                    showErrors={showErrors}
                    showRequired
                    onValidationChange={setComponentValidation}
                    maxDate={driverField.componentProps.maxDate}
                    minDate={driverField.componentProps.minDate}
                    readOnly={viewOnlyMode}
                />
            )
        }

        if (driverField.component === 'div') {
            if (driverField.id === 'ssn') {
                return (
                    <div id={`${driverField.id}${column - 1}`}>
                        <E1PSSNComponent
                            viewOnlyMode={viewOnlyMode}
                            onValueChange={handleValueChange}
                            personVM={_get(driver, 'person')}
                            onValidate={setComponentValidation}
                            hideLabel
                            showErrors={showErrors}
                            showRequired
                            onValidationChange={setComponentValidation}
                            path='person'
                        />
                    </div>
                )
            }

            if (driverField.id === 'policyDriverRoleTypeMainGrid') {
                const onChange = async (value, changedPath) => {
                    // Show non operator modal
                    if (value === 'nonoperator') {
                        // add disregard validation for license number and state
                        disregardFieldValidation(`driverLicenseNumberDiv${column - 1}`);
                        setComponentValidation(true, `[${column - 1}].person.licenseState`);
                        await onShowNonOperatorModal(false, driver, changedPath);

                        // only operators need value in this field
                        if (policyState === 'WI') {
                            handleValueChange(undefined, `[${column - 1}]includedInTNCCoverage`);
                            // TNC toggle is marked as required by default but it not visible/required if roletype 'nonoperator' and WI state
                            // so making component validation true to avoid validation error.
                            setComponentValidation(true, `[${column - 1}].includedInTNCCoverage`);
                        }
                    } else {
                        // Didn't choose non operator
                        _set(driver, 'nonOperatorOtherDescText', undefined);
                        _set(driver, 'nonOperatorDescType', undefined);
                        _set(driver, 'suspRevokePleaseExplain', undefined);
                        handleValueChange(value, changedPath);
                    }
                };
                const fieldPath = driverField.content[0].componentProps.path ? `[${column - 1}].${driverField.content[0].componentProps.path}` : undefined;
                const value = _get(driver, `${driverField.content[0].componentProps.path}.value.code`);

                return (
                    <div id={`${driverField.id}${column - 1}`}>
                        <DropdownSelectField
                            id={`${driverField.content[0].id}${column - 1}`}
                            alwaysShowPlaceholder={false}
                            placeholder=''
                            defaultValue='operator'
                            availableValues={policyDriverRoleTypes}
                            onValueChange={onChange}
                            value={value}
                            hideLabel
                            required={driverField.content[0].componentProps.required}
                            path={fieldPath}
                            /**
                                * If component is readonly, available values should have type codes which are present on the actual field
                                * otherwise it throws error
                                * Making sure that policyDriverRoleTypes are not empty in viewOnlyMode(as it may take time to call api and get the values)
                                */
                            readOnly={(viewOnlyMode && !_isEmpty(policyDriverRoleTypes))}
                            showErrors={showErrors}
                            showRequired
                            onValidationChange={setComponentValidation}
                            className={className}
                        />

                        {policyDriverRoleType === 'nonoperator' ? (
                            <IconButton
                                id={`${driverField.content[1].id}${column - 1}`}
                                onClick={() => onShowNonOperatorModal(true, driver, fieldPath)}
                                icon={viewOnlyMode ? 'mi-visibility' : 'mi-edit'}
                                disabled={false}
                            />
                        ) : (undefined)}
                    </div>
                )
            }

            if (driverField.id === 'driverLicenseNumberDiv') {
                if (hideLicenseNumberAndState) {
                    return (<GridItem key={`licenseNotNeeded${row}${column - 1}`} />)
                }

                return (
                    <E1PLicenseComponent
                        id={`${driverField.id}${column - 1}`}
                        drivers={drivers}
                        authHeader={authHeader}
                        viewModelService={viewModelService}
                        path={`${path}[${column - 1}]`}
                        driverVM={driver}
                        viewOnlyMode={viewOnlyMode}
                        licenseNumberRequired={isLicenseRequiredForDriver}
                        driversWithLicenseValidationError={driversWithLicenseValidationError}
                        setDriversWithLicenseValidationErrors={setDriversWithLicenseValidationErrors}
                        onValueChange={onValueChange}
                        onValidate={setComponentValidation}
                        hideLabel
                        showErrors={showErrors}
                        showRequired
                        onValidationChange={setComponentValidation}
                    />
                )
            }

            if (driverField.id === 'financialResponsibilityFiling') {
                const toggleFieldPath = driverField.content[0].componentProps.path ? `[${column - 1}].${driverField.content[0].componentProps.path}` : undefined;
                const toggleValue = _get(driver, ['financialResponsibility', 'filingInd', 'value'], false);
                const typeFieldPath = driverField.content[1].componentProps.path ? `[${column - 1}].${driverField.content[1].componentProps.path}` : undefined;
                const subTypeFieldPath = driverField.content[2].componentProps.path ? `[${column - 1}].${driverField.content[2].componentProps.path}` : undefined;
                const { isFinancialResponsibilityFormVisible, isFinancialResponsibilityFormEditable } = isFinancialResponsibilityFormAvailableForDriver(policyState, policyDriverRoleType, isNewSubmission);
                const typeFieldsVisible = isFinancialResponsibilityFormVisible && _get(driver, ['financialResponsibility', 'filingInd', 'value'], false);
                const subTypeDefaultValue = _includes(['sni', 'pni'], _get(driver, 'value.role')) ? {
                    "code": "owner",
                    "name": "Owner"
                } : {
                    "code": "operator",
                    "name": "Operator"
                }

                return (
                    <div id={`${driverField.id}${column - 1}`}>
                        {/* To match all editable toggle field, show every toggle field on the page under same grid configuration */}
                        <Grid tag="div" key={`${driverField.id}${column - 1}-grid`} columns={[
                            "3fr",
                            "1fr"
                        ]}>
                            <ToggleField
                                id={`${driverField.content[0].id}${column - 1}`}
                                value={toggleValue}
                                dataType="string"
                                controlClassName="toggleContainer"
                                availableValues={driverField.content[0].componentProps.availableValues}
                                onValueChange={(changedValue) => {
                                    if (!_get(driver, ['financialResponsibility', 'value'])) {
                                        _set(driver, ['financialResponsibility', 'value'], {});
                                    }

                                    handleValueChange(changedValue, `[${column - 1}].financialResponsibility.filingInd`);

                                    if (changedValue) {
                                        // Set default to SR22
                                        handleValueChange('form_sr22', `[${column - 1}].financialResponsibility.filingForm`);
                                    }
                                }}
                                path={toggleFieldPath}
                                showErrors={showErrors}
                                showRequired
                                onValidationChange={setComponentValidation}
                                readOnly={viewOnlyMode}
                            />
                        </Grid>
                        {typeFieldsVisible ? (<React.Fragment>
                            <DropdownSelectField
                                id={`${driverField.content[1].id}${column - 1}`}
                                alwaysShowPlaceholder={false}
                                placeholder=''
                                availableValues={driverField.content[1].componentProps.availableValues}
                                onValueChange={handleValueChange}
                                value={_get(driver, ['financialResponsibility', 'filingForm', 'value'])}
                                label={translator(driverField.content[1].componentProps.label)}
                                required={driver.value?.role !== 'pni'}
                                path={typeFieldPath}
                                className={className}
                                readOnly={!isFinancialResponsibilityFormEditable || viewOnlyMode}
                                hideLabel={false}
                                showErrors={showErrors}
                                showRequired
                                onValidationChange={setComponentValidation}
                            />
                            <DropdownSelectField
                                id={`${driverField.content[2]}${column - 1}`}
                                alwaysShowPlaceholder={false}
                                placeholder=''
                                availableValues={driverField.content[2].componentProps.availableValues}
                                value={subTypeDefaultValue}
                                label={translator(driverField.content[2].componentProps.label)}
                                required={driver.value?.role !== 'pni'}
                                path={subTypeFieldPath}
                                readOnly
                                className={className}
                                hideLabel={false}
                                showErrors={showErrors}
                                showRequired
                                onValidationChange={setComponentValidation}
                            />
                        </React.Fragment>) : (undefined)}
                    </div>
                )
            }
        }

        if (driverField.component === 'Toggle') {
            let onChange = handleValueChange;
            const value = driverField.componentProps.value ? driverField.componentProps.value : _get(driver, `${driverField.componentProps.path}.value`);
            const fieldPath = driverField.componentProps.path ? `[${column - 1}].${driverField.componentProps.path}` : undefined;
            let { defaultValue } = driverField.componentProps;

            if (driverField.id === 'licensedInCanadaOrPuertoRicoIndToggle') {
                onChange = (changedValue, changedPath) => {
                    handleValueChange(changedValue, changedPath);

                    if (!changedValue) {
                        _set(driver, 'value.yearsOfDrivingExpInCanadaOrPR', undefined);
                    }
                }
            } else if (driverField.id === 'driverDeceased') {
                if (!['pni', 'sni'].includes(_get(driver, 'value.role'))) {
                    return (<GridItem key={`${driverField.id}${column - 1}`} />);
                }

                onChange = (changedValue, changedPath) => {
                    handleValueChange(changedValue, changedPath);

                    if (!changedValue) {
                        handleValueChange(undefined, `[${column - 1}].person.deceasedDate`);
                        // disregard field validation for deceasedDate
                        disregardFieldValidation(`driverDeceasedDate${column - 1}`);
                    }
                };
            } else if (driverField.id === 'activeNationalGuardMemberInd' && policyDriverRoleType !== 'operator') {
                return (<GridItem key={`${driverField.id}${column - 1}`} />);
            } else if (driverField.id === 'personAwayAtSchool') {
                const driverOperatorAndUnder25 = driverAge && driverAge < 25 && policyDriverRoleType === 'operator';

                defaultValue = driverOperatorAndUnder25 ? false : undefined;
                onChange = (changedValue, changedPath) => {
                    if (changedValue) {
                        // Default to US when person at school is yes
                        handleValueChange('US', `[${column - 1}].awayAtSchoolCountry`);
                        setComponentValidation(true, `[${column - 1}].awayAtSchoolCountry`);
                    } else {
                        // When person at school is selcted as no, remove county/zipcode values
                        handleValueChange(undefined, `[${column - 1}].awayAtSchoolZipCode`);
                        handleValueChange(undefined, `[${column - 1}].awayAtSchoolCountry`);
                        setComponentValidation(true, `[${column - 1}].awayAtSchoolZipCode`);
                        setComponentValidation(true, `[${column - 1}].awayAtSchoolCountry`);
                    }

                    handleValueChange(changedValue, changedPath);
                };

                if (!driverOperatorAndUnder25) {
                    return (<GridItem key={`${driverField.id}${column - 1}`} />);
                }
            } else if (driverField.id === 'includedInTNCCoverageField') {
                // TNC coverage field. Only set for driver > 18, operator, and >2 driving experience
                const wouldQualifyForTnc = driverAge >= 18
                    && policyDriverRoleType === 'operator'
                    && driverAge - _get(driver, 'ageFirstLicensed.value') >= 2;

                // Don't show toggle and make value undefined
                if (!wouldQualifyForTnc) {
                    return (<GridItem key={`${driverField.id}${column - 1}`} />);
                }
            }

            return (
                // To match all editable toggle field, show every toggle field on the page under same grid configuration
                <Grid tag="div" key={`${driverField.id}${column - 1}-grid`} columns={[
                    "3fr",
                    "1fr"
                ]}>
                    <ToggleField
                        id={`${driverField.id}${column - 1}`}
                        key={`${driverField.id}${column - 1}`}
                        value={value}
                        dataType="string"
                        controlClassName="toggleContainer"
                        availableValues={driverField.componentProps.availableValues}
                        onValueChange={onChange}
                        defaultValue={defaultValue}
                        path={fieldPath}
                        required={driverField.componentProps.required}
                        showErrors={showErrors}
                        showRequired
                        onValidationChange={setComponentValidation}
                        readOnly={viewOnlyMode}
                    />
                </Grid>)
        }

        if (driverField.component === 'InputNumber') {
            const fieldPath = driverField.componentProps.path ? `[${column - 1}].${driverField.componentProps.path}` : undefined;
            const value = _get(driver, `${driverField.componentProps.path}.value`, '');

            if (driverField.id === 'yearsOfDrivingExpInCanadaOrPRInput') {
                return driver.licensedInCanadaOrPuertoRicoInd.value ? (
                    <InputNumberField
                        id={`${driverField.id}${column - 1}`}
                        path={fieldPath}
                        hideLabel
                        required={driverField.componentProps.required}
                        maxLength={driverField.componentProps.maxLength}
                        minLength={driverField.componentProps.minLength}
                        placeholder=''
                        onValueChange={handleValueChange}
                        value={value}
                        readOnly={viewOnlyMode || readOnlyValue}
                        className={className}
                        showErrors={showErrors}
                        showRequired
                        onValidationChange={setComponentValidation}
                    />
                ) : (<GridItem key={`${driverField.id}${column - 1}`} />)
            }
        }

        if (driverField.component === 'Grid') {
            let onToolTipClick;
            let onToggleChange = handleValueChange;
            let toggleValue = driverField.content[0].componentProps.value ? driverField.content[0].componentProps.value : _get(driver, `${driverField.content[0].componentProps.path}.value`);
            const toggleFieldPath = driverField.content[0].componentProps.path ? `[${column - 1}].${driverField.content[0].componentProps.path}` : undefined;
            let toggleDefaultValue = driverField.content[0].componentProps.defaultValue;
            let showTooltip = true;

            if (driverField.id === 'defensiveDriverGrid') {
                if (!driverField.componentProps.visible) {
                    return (<GridItem key={`${driverField.id}${column - 1}`} />);
                }

                onToggleChange = (changedValue, changedPath) => {
                    if (driver.defensiveDriverCourseInd?.value !== changedValue) {
                        handleValueChange(changedValue, changedPath);

                        // if moving from no to yes show the modal
                        if (driver.defensiveDriverCourseInd?.value) {
                            onViewCourseDetails(driver);
                        }
                    }
                };
                toggleDefaultValue = policyDriverRoleType === 'operator' ? false : undefined;
                onToolTipClick = () => {
                    onViewCourseDetails(driver);
                }
                showTooltip = !viewOnlyMode && driver.defensiveDriverCourseInd?.value
            }
            else if (driverField.id === 'goodStudentDiscountGrid') {
                const driverOperatorAndUnder25 = driverAge && driverAge < 25 && policyDriverRoleType === 'operator';

                if (!driverOperatorAndUnder25) {
                    return (<GridItem key={`${driverField.id}${column - 1}`} />);
                }

                toggleDefaultValue = (driverAge && driverAge < 25 && policyDriverRoleType === 'operator') ? false : undefined;

                return (<GridItem
                    tag="div" key={`${driverField.id}GridItem${column - 1}`} id={`${driverField.id}${column - 1}`}>
                    <Grid tag="div" key={`${driverField.id}${column - 1}`} columns={driverField.componentProps.columns}>
                        <ToggleField
                            id={`${driverField.content[0].id}${column - 1}`}
                            value={toggleValue}
                            dataType="string"
                            controlClassName="toggleContainer"
                            availableValues={driverField.content[0].componentProps.availableValues}
                            onValueChange={onToggleChange}
                            defaultValue={toggleDefaultValue}
                            path={toggleFieldPath}
                            required={driverField.content[0].componentProps.required}
                            showErrors={showErrors}
                            showRequired
                            onValidationChange={setComponentValidation}
                            readOnly={viewOnlyMode}
                        />
                    </Grid></GridItem>)
            }

            if (driverField.id === 'militaryDeploymentToggleGrid') {
                if (driver.value?.role !== 'pni' && driver.value?.role !== 'sni') {
                    return (<GridItem key={`${driverField.id}${column - 1}`} />);
                }

                onToggleChange = (value) => {
                    onChangeOfMilitaryDeploymentToggle(value, driver, column - 1);
                }
                toggleValue = getMilitaryDeploymentToggleValue(driver);
                showTooltip = getMilitaryDeploymentToggleValue(driver);
                onToolTipClick = () => {
                    onChangeOfMilitaryDeploymentToggle(true, driver, column - 1);
                }
            }

            return (<GridItem
                tag="div" key={`${driverField.id}GridItem${column - 1}`} id={`${driverField.id}${column - 1}`}>
                <Grid tag="div" key={`${driverField.id}${column - 1}`} columns={driverField.componentProps.columns}>
                    <ToggleField
                        id={`${driverField.content[0].id}${column - 1}`}
                        value={toggleValue}
                        dataType="string"
                        controlClassName="toggleContainer"
                        availableValues={driverField.content[0].componentProps.availableValues}
                        onValueChange={onToggleChange}
                        defaultValue={toggleDefaultValue}
                        path={toggleFieldPath}
                        required={driverField.content[0].componentProps.required}
                        showErrors={showErrors}
                        showRequired
                        onValidationChange={setComponentValidation}
                        readOnly={viewOnlyMode}
                    />
                    {showTooltip ?
                        (<IconButton
                            id={`${driverField.content[1].id}${column - 1}`}
                            icon={viewOnlyMode ? 'mi-visibility' : 'mi-edit'}
                            onClick={onToolTipClick}
                        />) : (undefined)}
                </Grid></GridItem>)
        }

        return (<span>
            {row} {column}
        </span>)
    }

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        // removing onValidate from depedency array, it was rendering EADriverGrid continously from additional info page
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isComponentValid]);

    const COLUMNS = drivers.length + 1;
    const columnConfig = ['310px']; // Heading should be at 310px

    for (let column = 0; column < drivers.length; column++) {
        const columnWidthToPush = '300px'; // driver column with margin will come at 310

        columnConfig.push(columnWidthToPush);
    }

    const ROWS = driverLabels.length + 1;
    const numberOfCells = COLUMNS * ROWS;
    const tableContent = [];

    const removeDriver = (index) => {
        const evt = {
            path: `lobData.personalAuto_EA.coverables.drivers.children[${index}]`
        };

        onRemoveDriver(evt);
    }

    for (let cell = 0; cell <= numberOfCells - 1; cell++) {
        const rowNumber = Math.floor(cell / COLUMNS) + 1;
        const columnNumber = cell % COLUMNS;

        if (cell === 0) {
            // empty cell
            tableContent.push(<GridItem className="grid-item-sticky grid-item-label" key={`quotename${cell}`} />)
        }
        // Driver Name
        else if (cell === columnNumber) {
            const isDriverPni = drivers.children[columnNumber - 1].value?.role === 'pni';

            tableContent.push
                (<GridItem
                    tag="div" key={`driverNameGridItem${cell}`} className="grid-border-thin font-b">
                    <Grid tag="div" key={`driverTitleHeader${cell}`} columns={["14rem", "1rem"]} className="p-5">
                        <div key={`driverLabelID${cell}`} className="font-grid-title">
                            {getDriverName(drivers.children[cell - 1])}
                        </div>
                        {!viewOnlyMode && !isDriverPni && !isAdditionalInfo ? (<IconButton
                            id={`eaDeleteDriver${cell}`}
                            icon="mi-delete"
                            onClick={() => removeDriver(columnNumber - 1)}
                        />) : (undefined)}
                    </Grid>
                </GridItem>)
        }
        // first column - render row headers
        else if ((cell % COLUMNS === 0)) {
            let itemStyle = "grid-item-label";

            // background style
            if (rowNumber % 2 === 0) {
                itemStyle += " grid-item-color-even";
            }

            // last row of the grid
            if (rowNumber === ROWS) {
                itemStyle += " grid-item-last";
            }

            const labelIndex = rowNumber - 2;

            tableContent.push
                (<GridItem
                    tag="div" key={`driverLabel${cell}`} className={itemStyle}>
                    {formatLabel(driverLabels[labelIndex])}
                </GridItem>)
        } else {
            let itemStyle = "grid-item";

            // background style
            if (rowNumber % 2 === 0) {
                itemStyle += " grid-item-color-even";
            }

            // last row of the grid
            if (rowNumber === ROWS) {
                itemStyle += " grid-item-last";
            }

            const driverData = driverFields[columnNumber - 1];
            const driverField = driverData ? driverData[rowNumber - 2] : undefined;

            if (driverField !== undefined) {
                tableContent.push(<GridItem
                    tag="div" key={`driverDataCell${cell}`} className={itemStyle} id={`${driverField.id}${columnNumber - 1}`}>
                    {formatField(rowNumber, columnNumber)}
                </GridItem>)
            }
        }
    }

    return (
        <Grid className="mt-8" columns={columnConfig} hgap="small" vgap="none">
            {tableContent}
        </Grid>
    );
}


EADriverGridComponent.propTypes = {
    policyState: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    drivers: PropTypes.shape({}).isRequired,
    setIsExistingDriverDeleted: PropTypes.func,
    showValidationMessage: PropTypes.func,
    isAdditionalInfo: PropTypes.bool
};

EADriverGridComponent.defaultProps = {
    showValidationMessage: () => { },
    isAdditionalInfo: false,
    setIsExistingDriverDeleted: undefined
};

export default EADriverGridComponent;
