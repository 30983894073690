import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { BrandContentService } from 'e1p-capability-gateway';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import messages from './ExitConfirmationPage.messages';
import styles from './ExitConfirmationPage.module.scss';

function ExitConfirmationPage() {
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [logoSrc, setLogoSrc] = useState('');

    useEffect(() => {
        const partner = '529';

        BrandContentService.getLogo(
            partner,
            authHeader
        ).then((brandServiceRespone) => {
            setLogoSrc(brandServiceRespone?.logoLink || '')
        }).catch(() => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.confirmationContainer}>
            <img alt="branded logo" src={logoSrc} width="250px" height="250px"/>
            <p>{translator(messages.signoutMessage)}</p>
        </div>
    );
}

ExitConfirmationPage.propTypes = {
    authHeader: PropTypes.shape({}).isRequired
};

export default withAuthenticationContext(ExitConfirmationPage);
