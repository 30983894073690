import moment from 'moment';

const dateFormat = 'MM/DD/YY';

function getCurrentDate() {
    return new Date();
}

function getCurrentDateYear() {
    return getCurrentDate().getFullYear();
}

function getMinDate() {
    const minDay = 1;
    const minMonth = 0;
    const minYear = 1900;
    return new Date(minYear, minMonth, minDay);
}

function getMinDateYear() {
    return getMinDate().getFullYear();
}

function getFormattedCurrentDate() {
    const currentDate = getCurrentDate();
    return { year: currentDate.getFullYear(), month: currentDate.getMonth(), day: currentDate.getDate() };
}

function getFormatedDate(date) {
    return moment(new Date(date.year, date.month, date.day)).format('M/D/YYYY');
}

function differenceInDays(date1, date2) {
    const dt1 = new Date(date1);
    const dt2 = new Date(date2);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate())
        - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
}

function calculateAge(year) {
    if (year !== undefined) {
        const dt1 = new Date();
        const dt2 = new Date(year.toString());
        return dt1.getFullYear() - dt2.getFullYear();
    }
    return undefined;
}

function checkIfDateIsInRange(dateToCheck, sDate, eDate) {
    const startDate = new Date(sDate.year, sDate.month, sDate.day);
    const endDate = new Date(eDate.year, eDate.month, eDate.day);
    const dateToValidate = new Date(dateToCheck.year, dateToCheck.month, dateToCheck.day);
    if (startDate <= dateToValidate && dateToValidate <= endDate) {
        return true;
    }
    return false;
}

function getFormattedCustomDate(date) {
    return { year: date.getFullYear(), month: date.getMonth(), day: date.getDate() };
}

function utcToString(utcString) {
    return moment(utcString).format(dateFormat);
}

function stringToMoment(utcString) {
    return moment(utcString).format('YYYYMMDD');
}

function isDateBackDated(dateValue) {
    const dateToCompareAgainst = moment.utc(dateValue);
    const currentDate = moment().utc();
    return currentDate.startOf('day')
        .diff(dateToCompareAgainst.startOf('day'), 'days') > 0;
}

/**
 * Converts date to UTC format
 * @param {Date} dateValue
 * @returns {Date} date in utc format
 */
function convertToUTC(dateValue) {
    if(dateValue){
        return moment.utc(dateValue).format('YYYY-MM-DDTHH:mm:ss');
    }
    return dateValue;
}

/**
 * Converts date to UTC format
 * @param {Date} dateValue date string without timezone
 * @returns {Date} date in utc format
 */
function convertToUTCForDateWithoutTZ(dateValue) {
    if(dateValue){
        return moment.utc(dateValue).format('YYYY-MM-DD');
    }
    return dateValue;
}

export default {
    calculateAge,
    getCurrentDate,
    differenceInDays,
    checkIfDateIsInRange,
    getFormatedDate,
    getFormattedCurrentDate,
    getCurrentDateYear,
    getMinDateYear,
    utcToString,
    stringToMoment,
    isDateBackDated,
    convertToUTC,
    convertToUTCForDateWithoutTZ,
    getFormattedCustomDate
};
