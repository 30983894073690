import { commonMessages } from 'e1p-platform-translations';
import PartnerService from '../services/PartnerService';

/**
 *
 * @purpose Used to verify Costco Membership
 * @param {*} membershipNumber user entered membership number
 * @param {String} jobNumber if available
 * @param {Object} authHeader auth token
 * @param {Object} translator to translate the strings/objects
 * @returns {*} info message banner with membership tier and status values
 */
const connectCostcoMembershipValidation = async (
    membershipNumber,
    jobNumber,
    authHeader,
    translator
) => {
    let returnMessage;
    let membershipStatus;
    let membershipTier;
    try {
        const eligibiltyInformation = await PartnerService.validateCostcoMembership(
            membershipNumber,
            jobNumber,
            authHeader
        );
        membershipTier = eligibiltyInformation.memberTier;
        membershipStatus = eligibiltyInformation.memberStatus;

        switch (eligibiltyInformation.memberStatus) {
            case 'Active':
            case 'Inactive':
            case 'Expired':
                // Validation complete, the Costco membership is <Active/ Inactive/ Expired>, <Executive/Goldstar> and number is <membershipnumber>.
                returnMessage = translator(
                    commonMessages.costcoMembershipValidationComplete,
                    {
                        memberStatus: eligibiltyInformation.memberStatus,
                        memberTier:
                            eligibiltyInformation.memberTier === 'EXEC'
                                ? 'Executive'
                                : 'Goldstar',
                        membershipNumber: eligibiltyInformation.membershipNumber,
                    }
                );
                break;
            // Validation complete, the Costco membership is <invalid>.
            case 'invalid':
                returnMessage = translator(commonMessages.costcoMembershipValidationInvalid);
                break;
            // Validation incomplete, Costco web services tool is unavailable.
            case 'error':
                returnMessage = translator(commonMessages.costcoMembershipValidationError);
                break;
            default: 
                returnMessage = '';
                break;
        }
    } catch (err) {
        returnMessage = translator(commonMessages.costcoMembershipValidationError);
    }
    return {
        membershipStatus,
        membershipTier,
        message: returnMessage
    };
};

export default { connectCostcoMembershipValidation };