import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, withModalContext } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { EndorsementService } from 'e1p-capability-policychange';
import { RenewalService } from 'e1p-capability-renewal';
import { EHScheduleItemsComponent } from 'e1p-capability-policyjob-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { ClausesUtil as e1pClausesUtil } from 'e1p-portals-util-js';
import metadata from './OptionalCoveragesPopup.metadata.json5';
import messages from './OptionalCoveragesPopup.messages';
import styles from './OptionalCoveragesPopup.module.scss';

import { Button } from '@jutro/legacy/components';

const structureCustomQuote = (policyChangeVM, clauses) => 
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        coverages: clauses.homeowners_EH
    })
;

const getCustomQuote = (vm, lobPath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages, lobName, null
        );
    }

    return structureCustomQuote(vm, clausesToUpdate);
};

const getSchedules = (policyChangeVM, onScheduleChange, viewOnlyMode, parentPageSubmitted) => {
    const map = new Map();
    const schedules = _.get(policyChangeVM, 'lobData.homeowners_EH.offerings.children[0].coverages.schedules.children', []);

    schedules.forEach((schedule, index) => {
        const ehScheduleItemsComponent = (
            <EHScheduleItemsComponent
                data={schedule}
                value={schedule.value}
                labelPosition="left"
                id={`scheduleItem${index}`}
                onScheduleChange={onScheduleChange}
                path={`lobData.homeowners_EH.offerings.children[0].coverages.schedules.children[${index}]`}
                showTitle
                readOnly={viewOnlyMode}
                parentPageSubmitted={parentPageSubmitted}
            />
        );

        map.set(schedule?.value?.referenceCode, ehScheduleItemsComponent);
    });

    return map;
};

function OptionalCoveragesPopup(props) {
    const {
        policyChangeVM,
        updateWizardData,
        viewModelService,
        title,
        authHeader,
        closePopupAction,
        isOpen,
        viewOnlyMode,
        onResolve,
        goldCovSelected,
        optionalCoveragesModified,
        platinumCovSelected,
        hasGoldOrPlatinumBoxVisible,
        policyState
    } = props;

    const {
        isComponentValid,
        disregardFieldValidation,
        onValidate,
        registerComponentValidation
    } = useValidation('OptionalCoveragesPopup');
    const translator = useTranslator();

    const [goldCheckBoxValue, updateGoldCheckBoxValue] = useState(goldCovSelected);
    const [platinumCheckBoxValue, updatePlatinumCheckBoxValue] = useState(platinumCovSelected);
    const [isWhatsIncludedClosed, setIsWhatsIncludedClosed] = useState(false);
    const [isWhatsIncludedInLine, setIsWhatsIncludedInLine] = useState(false);
    const [clickedOnWhatsIncludedLinkValue, setClickedUpdateOnWhatsIncludedLinkValue] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);

    useEffect(() => {
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);

    useEffect(() => {
        registerComponentValidation(() => policyChangeVM.value.lobData.homeowners_EH.offerings[0]
                .coverages.schedules.filter((schedule) => schedule.referenceCode)
                .every((schedule) => schedule.scheduleItems.length > 0));
    }, [policyChangeVM.value.lobData.homeowners_EH.offerings, registerComponentValidation]);

    const clickedUpdateOnWhatsIncludedLinks = useCallback(
        () => {
            setClickedUpdateOnWhatsIncludedLinkValue(true);
            setIsWhatsIncludedInLine(true);
            setIsWhatsIncludedClosed(true);
        }, []
    );
    const clickedUpdateOnWhatsIncludedCloseLinks = useCallback(
        () => {
            setClickedUpdateOnWhatsIncludedLinkValue(false);
            setIsWhatsIncludedClosed(false);
        }, []
    );
    const [loadingClause, updateLoadingClause] = useState();
    const [clonedpolicyChangeVM, updateClonedpolicyChangeVM] = useState(viewModelService.clone(policyChangeVM));

    const changeSubmission = useCallback(
        (value, changedPath) => {
            _.set(policyChangeVM, changedPath, value);
            e1pClausesUtil.setClauseValue(policyChangeVM, value, changedPath);

            const newpolicyChangeVM = viewModelService.clone(policyChangeVM);

            updateWizardData(newpolicyChangeVM);
            updateClonedpolicyChangeVM(newpolicyChangeVM);
        }, [policyChangeVM, updateWizardData, viewModelService]
    );

    const writeValue = useCallback(
        (value, path) => {
            if (value !== undefined && path !== undefined) {
                _.set(policyChangeVM, path, value);

                const newpolicyChangeVM = viewModelService.clone(policyChangeVM);

                updateClonedpolicyChangeVM(newpolicyChangeVM);
                updateWizardData(policyChangeVM);
            }
        },
        [policyChangeVM, updateWizardData, viewModelService]
    );

    const onUpdateCustomQuote = useCallback(
        async (_basePath, lobPath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(policyChangeVM, lobPath, lobName, false);
            let updatedCoverages;
            const { _dtoName } = policyChangeVM;
            const sessionUUID = _.get(policyChangeVM, 'sessionUUID.value');

            // This is a reusable component
            if (_dtoName === 'edge.capabilities.renewal.dto.RenewalDataDTO') {
                updatedCoverages = await RenewalService.updateCoverages(
                    policyChangeVM.value.jobID,
                    { homeowners_EH: customQuote.coverages },
                    sessionUUID,
                    authHeader
                );
                optionalCoveragesModified();
            } else {
                updatedCoverages = await EndorsementService.updateCoverages(
                    policyChangeVM.value.jobID, [], { homeowners_EH: customQuote.coverages }, authHeader
                );
            }

            const changedPath = lobPath.replace(/.children/, '');
            const updatedClauses = _.get(updatedCoverages, `${changedPath}.coverages`);
            const newpolicyChangeVM = viewModelService.clone(policyChangeVM);

            _.set(policyChangeVM, `${lobPath}.coverages`, updatedClauses);

            const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                policyChangeVM, newpolicyChangeVM, `${lobPath}.coverages.coverages`
            );
            const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                policyChangeVM, newpolicyChangeVM, `${lobPath}.coverages.schedules`
            );
            const allRemovedFields = [
                ...removedFieldsFromBaseCoverages, ...removedFieldsFromAdditionalCoverages
            ];

            disregardFieldValidation(allRemovedFields);
            updateWizardData(policyChangeVM);
            updateLoadingClause(undefined);

            return updatedCoverages;
        },
        [authHeader, disregardFieldValidation, optionalCoveragesModified, policyChangeVM, updateWizardData, viewModelService]
    );

    const onClauseChange = useCallback((schedule, path) => {
        const lobOfferingPath = 'lobData.homeowners_EH.offerings.children[0]';

        writeValue(schedule, path);

        return onUpdateCustomQuote({}, lobOfferingPath);
    }, [onUpdateCustomQuote, writeValue]);


    const syncClauses = useCallback(async () => {
        updateLoadingClause(true);
        await onClauseChange();

        const newpolicyChangeVMCloned = viewModelService.clone(policyChangeVM);

        updateClonedpolicyChangeVM(newpolicyChangeVMCloned);
        updateWizardData(newpolicyChangeVMCloned);
    }, [onClauseChange, policyChangeVM, updateWizardData, viewModelService]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncClauses(value, changedPath);
        },
        [changeSubmission, syncClauses]
    );

    const toggleGoldPackageCov = useCallback(
        () => {
            const basePath = 'lobData.homeowners_EH.offerings.children[0].coverages.coverages';
            const offerings = _.get(policyChangeVM, 'lobData.homeowners_EH.offerings.children[0]');

            const clauseIndex = offerings.value.coverages.coverages.findIndex(
                (clause) => clause.codeIdentifier === 'EH_GoldCoverageBundle'
            );
            const fullPath = `${basePath}.children[${clauseIndex}].selected`;

            changeSubmissionAndSync(!goldCheckBoxValue, fullPath);
            updateGoldCheckBoxValue(!goldCheckBoxValue);
        }, [policyChangeVM, changeSubmissionAndSync, goldCheckBoxValue]
    );

    const togglePlatinumPackageCov = useCallback(
        () => {
            const basePath = 'lobData.homeowners_EH.offerings.children[0].coverages.coverages';
            const offerings = _.get(policyChangeVM, 'lobData.homeowners_EH.offerings.children[0]');

            const clauseIndex = offerings.value.coverages.coverages.findIndex(
                (clause) => clause.codeIdentifier === 'EH_PlatinumCoverageBundle'
            );
            const fullPath = `${basePath}.children[${clauseIndex}].selected`;

            changeSubmissionAndSync(!platinumCheckBoxValue, fullPath);
            updatePlatinumCheckBoxValue(!platinumCheckBoxValue);
        }, [policyChangeVM, changeSubmissionAndSync, platinumCheckBoxValue]
    );

    const onScheduleChange = useCallback(
        (schedule, path) => {
            const lobOfferingPath = 'lobData.homeowners_EH.offerings.children[0]';

            writeValue(schedule, `${path}.value`);

            return onUpdateCustomQuote({}, lobOfferingPath);
        },
        [onUpdateCustomQuote, writeValue]
    );

    const policyType = _.get(policyChangeVM, 'value.policyType', undefined)
        // component is reused for renewal; you have to go to this path
        || _.get(policyChangeVM, 'value.lobData.homeowners_EH.policyType');
    const textContent = (
        <React.Fragment>
            {translator(messages.optionalCoverageSelect)}
            <span className={styles.addIcon} />
            {translator(messages.optionalCoverageLabel)}
        </React.Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        goldCheckboxField: {
            value: goldCheckBoxValue,
            disabled: viewOnlyMode || platinumCheckBoxValue,
        },
        platinumCheckboxField: {
            value: platinumCheckBoxValue,
            disabled: viewOnlyMode || goldCheckBoxValue
        },
        packageDifference: {
            isInLine: isWhatsIncludedInLine,
            visible: clickedOnWhatsIncludedLinkValue,
            lob: 'Homeowners_EH'
        },
        optionalCoverageHeader: {
            visible: hasGoldOrPlatinumBoxVisible && (policyType === 'HO3' || policyType === 'HF9')
        },
        optionalCoveragesMainContainer: {
            visible: !hasGoldOrPlatinumBoxVisible && (policyType === 'HO3' || policyType === 'HF9')
        },
        optionalCoveragePackageMsg: {
            content: textContent
        },
        whatsIncludedItem: {
            visible: hasGoldOrPlatinumBoxVisible && (policyType === 'HO3' || policyType === 'HF9')
        },
        whatsincludedCloseLink: {
            visible: isWhatsIncludedClosed
        },
        whatsIncludedLink: {
            visible: !isWhatsIncludedClosed
        },
        goldCoverageOfferingGrid: {
            visible: policyType === 'HO3'
        },
        platinumCoverageOfferingGrid: {
            visible: policyType === 'HO3'
        },
        popularOptionalCoverages: {
            loadingClause,
            uiGroup: 'Popular Optional Coverages',
            labelPosition: 'top',
            schedulesMap: getSchedules(policyChangeVM, onScheduleChange, viewOnlyMode, isPageSubmitted),
            viewOnlyMode,
            isMandatoryCoverages: false,
            policyType,
            isPageSubmitted,
            onValidate,
            hasGoldOrPlatinumBoxVisible,
            policyState
        },
        moreOptionalCoverages: {
            loadingClause,
            uiGroup: 'More Optional Coverages',
            labelPosition: 'top',
            schedulesMap: getSchedules(policyChangeVM, onScheduleChange, viewOnlyMode, isPageSubmitted),
            viewOnlyMode,
            isPageSubmitted,
            onValidate,
            hasGoldOrPlatinumBoxVisible,
            policyType,
            policyState
        },
        exclusions: {
            loadingClause,
            isExclusionOrCondition: true,
            labelPosition: 'top',
            viewOnlyMode,
            isPageSubmitted,
            onValidate
        },
        conditions: {
            loadingClause,
            isExclusionOrCondition: true,
            labelPosition: 'top',
            viewOnlyMode,
            isPageSubmitted,
            onValidate
        },
        errorMessageDiv: {
            visible: isPageSubmitted
        },
        specialLossSettlementComponent: {
            changeSubmissionAndSync,
            transactionVM: policyChangeVM,
            viewOnlyMode
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            toggleGoldPackageCov,
            togglePlatinumPackageCov,
            clickedUpdateOnWhatsIncludedLinks,
            clickedUpdateOnWhatsIncludedCloseLinks
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                clonedpolicyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, clonedpolicyChangeVM]
    );

    const onOk = useCallback(
        (evt) => {
            if (!isComponentValid) {
                evt.stopPropagation();
                updateIsPageSubmitted(true);
                // updateParentPageSubmitted(true);
                closePopupAction();

                return false;
            }

            closePopupAction();

            return onResolve();
        }, [closePopupAction, isComponentValid, onResolve]
    );

    return (

        <ModalNext
            isOpen={isOpen}
            className={styles.fullScreen}
        >
            <ModalHeader title={title} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={clonedpolicyChangeVM}
                    onModelChange={updateWizardData}
                    callbackMap={resolvers.resolveCallbackMap}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onOk}>
                    {messages.backToCoverages}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

OptionalCoveragesPopup.propTypes = {
    policyChangeVM: PropTypes.shape({
        value: PropTypes.shape({
            jobID: PropTypes.string,
            lobData: PropTypes.shape({
                homeowners_EH: PropTypes.shape({
                    offerings: PropTypes.arrayOf((
                        PropTypes.shape({
                            coverages: PropTypes.arrayOf(
                                PropTypes.shape({
                                    schedules: PropTypes.arrayOf(
                                        PropTypes.shape({
                                        })
                                    ),
                                    filter: PropTypes.func
                                })
                            )
                        })
                    ))
                })
            })
        }),
    }),
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
        clone: PropTypes.func
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    goldCovSelected: PropTypes.bool.isRequired,
    platinumCovSelected: PropTypes.bool.isRequired,
    coveragesModified: PropTypes.shape({
        updated: PropTypes.bool
    }),
    authHeader: PropTypes.shape({}).isRequired,
    viewOnlyMode: PropTypes.bool,
    closePopupAction: PropTypes.func,
    optionalCoveragesModified: PropTypes.func,
    title: PropTypes.string.isRequired,
    hasGoldOrPlatinumBoxVisible: PropTypes.bool.isRequired,
    policyState: PropTypes.string.isRequired
};
OptionalCoveragesPopup.defaultProps = {
    viewOnlyMode: false,
    policyChangeVM: {},
    closePopupAction: () => { },
    optionalCoveragesModified: () => { }
};
export default withModalContext(OptionalCoveragesPopup);
