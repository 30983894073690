import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class PolicyChangeService {
    /**
     * Fetch Estimated Payment Review
     * @param {string[]} data
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getEstimatedPaymentPreview = (data, additionalHeaders) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicyChange'),
            'getEstimatedPaymentPreview',
            data,
            additionalHeaders
        );
    };
}
