import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    useRef
} from 'react';
import {
    get, set, find, findIndex, isEmpty, unset, pullAt
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useDriverPageUtil, useCreditReportUtil } from 'e1p-capability-hooks';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { commonMessages as e1pCommonMessages, eaCommonMessages, eaValidationAndInfoMessages } from 'e1p-platform-translations';
import { RenewalService } from 'e1p-capability-renewal';
import { EAValidatonUtil, VehicleUtil } from 'e1p-portals-util-js';
import appConfig from 'app-config';
import metadata from './DriversPage.metadata.json5';
import wizardMessages from '../../EARenewalWizard.messages';

const LOB = 'personalAuto_EA';

function EADriversPage(props) {
    const modalApi = useModal();
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        setIsPageValid,
        steps,
        jumpTo,
        updateWizardSnapshot
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);

    const translator = useTranslator();
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [isCreditReportRequired, setIsCreditReportRequired] = useState(false);
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const hasNewSNIAdded = useRef(false);
    const hasSaveOperationCompleted = useRef(false);
    const [indexStale, setIndexStale] = useState(false);
    const [sniHasDefensiveCourse, setSNIHasDefensiveCourse] = useState(false);
    const [pniHasDefensiveCourse, setPNIHasDefensiveCourse] = useState(false);

    const [driversWithLicenseValidationError, setDriversWithLicenseValidationErrors] = useState([]);
    const changeSummaryIndex = findIndex(steps, ({ path }) => path === '/change-summary');
    const [validationErrors, setValidationErrors] = useState([]);
    const policyState = get(renewalVM, 'baseData.policyAddress.state.value.code');
    const [isExistingDriverDeleted, setIsExistingDriverDeleted] = useState(false);
    const [isAccountContactsFetched, setIsAccountContactsFetched] = useState(false);

    const {
        setSpouseAsSNI,
        checkMoreThanOneSNIRoles,
        setRelationShipStatusForSNI,
        isPNIAndSNIValidForDefensiveDiscount,
        isDefensiveDriverDiscountAvailableForDriver,
        isFinancialResponsibilityFormAvailableForDriver,
        isLicenseNumberRequired,
        getDriverAge,
        checkDuplicateDrivers
    } = useDriverPageUtil(
        renewalVM,
        updateWizardData,
        viewModelService,
        setValidationErrors,
        translator
    );

    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('EADriversPage');

    const {
        showReportPopup
    } = useCreditReportUtil(
        translator,
        LOB
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
            /**
             * Setting current wizard page is valid or not,using this info
             * to show popup(The changes you have made on this page may be lost.)
             * on click of previous or page jump
             */
            setIsPageValid(isComponentValid);
        }
    }, [isComponentValid, isPageSubmitted, setIsPageValid]);

    const { authHeader, authUserData } = useAuthentication();

    renewalVM.flowStepIDs_Ext.value = ['driver', 'namedinsured'];
    renewalVM.entryCompletionStepIDs_Ext.value = ['driver', 'namedinsured'];

    const createDriverVM = useCallback(
        (driver, isSNI) => {
            const driverObj = {
                person: driver.person.value
            };
            const {
                _dtoName,
                _xCenter
            } = renewalVM.lobData.personalAuto_EA.coverables.drivers;
            const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);

            if (isSNI) { set(driverVM, 'relationshipToNI', driver.relationshipToNI.value); }

            renewalVM.lobData.personalAuto_EA.coverables.drivers.pushElement(driverVM);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData, viewModelService]
    );

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';
        const drivers = get(renewalVM, `${driversPath}.value`);

        if (isEmpty(drivers)) {
            createDriverVM(renewalVM.lobData.personalAuto_EA.primaryNamedInsured, false);

            if (renewalVM.lobData.personalAuto_EA.secondaryNamedInsured.person) {
                createDriverVM(
                    renewalVM.lobData.personalAuto_EA.secondaryNamedInsured, true
                );
            }

            // If it is first time visiting the page, need to call credit report
            setIsCreditReportRequired(true);
        } else {
            // User chose to change PNI to a new Contact
            const pni = get(renewalVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
            const pniDriver = drivers.find((driver) => driver.person.publicID === pni.person.publicID);

            if (!pniDriver) {
                createDriverVM(renewalVM.lobData.personalAuto_EA.primaryNamedInsured, false);
                setIsCreditReportRequired(true);
            }

            // Submission already started and user goes back to add SNI
            const sniPublicID = renewalVM.lobData.personalAuto_EA.secondaryNamedInsured.person
                ? renewalVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value : undefined;
            const sniDriver = drivers.find((driver) => driver.person.publicID === sniPublicID);

            // Populate SNI as a driver
            if (sniPublicID && !sniDriver) {
                createDriverVM(renewalVM.lobData.personalAuto_EA.secondaryNamedInsured, true);
                // If secondary named insured person is added, need to call credit report
                setIsCreditReportRequired(true);
            } else if (sniPublicID && sniDriver) {
                // user went back to insured page and added sni
                // on insured page we are adding sni in drivers node
                // so sni will be present but policyDriverRoleType will be undefined
                if (!get(sniDriver, 'policyDriverRoleType')) {
                    setIsCreditReportRequired(true);
                }
            }
        }

        setIsPageInitialized(true);
        // No array dependencies needed in this hook.
        // The logic of initializing drivers data needs to be executed only once
        // when landing into drivers page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';
        const drivers = get(renewalVM, `${driversPath}`);
        const pniPublicID = renewalVM
            .lobData.personalAuto_EA.primaryNamedInsured.person.publicID.value;
        const sniPublicID = renewalVM
            .lobData.personalAuto_EA.secondaryNamedInsured.person
            ? renewalVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value
            : undefined;
        let pni = find(
            drivers.children,
            driver => driver.person.publicID.value === pniPublicID
        );
        const pniIndex = findIndex(
            drivers.children,
            driver => driver.person.publicID.value === pniPublicID
        );

        // Unset role.Fixes bug where you come back to drivers from a further page
        // and ArrayUpdater has changed the ordering of your drivers and role has persisted
        // so you end up seeing both  drivers as PNI
        drivers.children.forEach((driverNode) => {
            unset(driverNode, 'role');
        });

        // Put PNI back as first driver
        if (pniIndex > 0) {
            pni = viewModelService.clone(pni);
            pullAt(drivers.value, pniIndex);
            drivers.value.unshift(pni.value);
        }

        if (sniPublicID) {
            const sni = find(drivers.children, (driver) => driver.person.publicID.value === sniPublicID);

            if (sni) {
                set(sni, 'value.role', 'sni');
            }
        }

        if (pni) {
            set(pni, 'value.role', 'pni');
        }
    }, [renewalVM, renewalVM.lobData.personalAuto_EA.coverables.drivers.value, viewModelService]);

    const getCreditReportStatus = useCallback(
        (quoteID) => showReportPopup(
                quoteID,
                authHeader,
                renewalVM
            ),
        [authHeader, renewalVM, showReportPopup]
    );

    const initializeSave = (vm) => {
        unset(vm.value, 'bindData');

        const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
        const driversList = get(vm, driversPath);

        driversList.forEach((driver, index) => {
            if (
                isEmpty(driver.person.displayName)
                || !driver.person.displayName.includes(
                    `${driver.person.firstName} ${driver.person.lastName}`
                )
            ) {
                const displayname = `${driver.person.firstName} ${driver.person.lastName}`;

                set(vm, `${driversPath}[${index}].person.displayName`, displayname);
            }

            // Not asking for these fields but PC requires them
            set(vm,
                `${driversPath}[${index}].isInsurancePolicyRefused`,
                'no');
            set(vm,
                `${driversPath}[${index}].isDrivingPrivilegeRevoked`,
                'no');
        });
    };

    const doSave = useCallback(
        async () => {
            initializeSave(renewalVM);
            renewalVM.value = await RenewalService.saveRenewal(
                [renewalVM.value],
                authHeader
            );
            updateWizardData(renewalVM);

            return renewalVM.value;
        },
        [authHeader, renewalVM, updateWizardData]
    );

    /**
     * If state requires drivers assignment and this page has been visited, we will
     *   take the user to the drivers assignment page to check for updates
     */
    const needsDriverAssignmentUpdate = useCallback(
        async () => {
            const enabledStates = appConfig.driverAssignmentStates;
            const driverAssignmentStepIndex = steps.findIndex((step) => step.path === '/assignment');
            const shouldSkipDriverAssignmentPage = VehicleUtil.shouldSkipDriverAssignmentPage(renewalVM);

            /**
            * If driver assignment is available for given state and 
            * shouldSkipDriverAssignmentPage indicator(given job has one driver and one vehicle and vehicle is not garaged out fo state more than 6 months) is true 
            * then perform driver assignment for given vehicle
            */
            if (driverAssignmentStepIndex > 0 && shouldSkipDriverAssignmentPage) {
                VehicleUtil.setDriverAssignmentWhenOneDriverOneVehiclePresent(renewalVM, updateWizardData);
            }

            // not needed for state and does not exist in steps already
            if (enabledStates.includes(policyState)) {
                renewalVM.value = await RenewalService.saveRenewal(
                    [renewalVM.value],
                    authHeader
                );
                updateWizardData(renewalVM);

                const validationErrs = get(renewalVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const hasNoConflicts = isEmpty(get(renewalVM, 'value.conflicts', []));
                const hasNoExceptions = isEmpty(get(renewalVM, 'baseData.exceptions_Ext.value', []));

                // Need to stay on the page if field issues
                if (validationErrs.length === 0 && hasNoConflicts && hasNoExceptions) {
                    if (driverAssignmentStepIndex > 0 && shouldSkipDriverAssignmentPage) {
                        // keep the normal flow no need to jump to any page
                        return false;
                    }

                    updateWizardSnapshot(renewalVM);
                    jumpTo(driverAssignmentStepIndex, true);

                    return true;
                }

                // needs to but has errors so don't jump
                return true;
            }

            return false;
        },
        [authHeader, jumpTo, policyState, renewalVM, steps, updateWizardData, updateWizardSnapshot]);

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => {
            setValidationErrors([]);

            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            // IAP-4716, check duplicate drivers
            const hasDuplicateDrivers = checkDuplicateDrivers();

            if (hasDuplicateDrivers) {
                setIsSavingRenewal(false);

                return false;
            }

            try {
                const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
                const driversList = get(renewalVM, driversPath);

                driversList.forEach((driver) => {
                    /**
                     * E1PAP1PC-12823
                     * If relationshipTo Pni is spouse then that driver should be sni
                     * Marking driver as sni and function setSpouseAsSNI will convert it to sni
                     */
                    if (driver.relationshipToNI === 'spouse') {
                        set(driver, 'role', 'sni');
                    }
                });

                // E1PAP1PC-12823
                // Checks if more than one sni roles are present and if yes then sets page level error
                const hasMoreThanOneSNIRoles = checkMoreThanOneSNIRoles();

                if (hasMoreThanOneSNIRoles) {
                    setIsSavingRenewal(false);

                    return false;
                }

                /**
                * IAP-3005 : removing prompt to set Spouse as SNI
                * When Spouse is added as driver we will add it as SNI on the policy
                */
                setSpouseAsSNI(hasNewSNIAdded);

                // E1PAP1PC-12823
                // syncs relationship status for sni
                setRelationShipStatusForSNI();

                // function will default required fields for a new driver, that are not on the UI
                // no jump can happen before this or it will cause issues
                const saveResponse = await doSave();

                renewalVM.value = saveResponse;
                hasSaveOperationCompleted.current = true;

                if (isCreditReportRequired || hasNewSNIAdded.current) {
                    if (!(await getCreditReportStatus(get(renewalVM, 'jobID.value')))) {
                        setIsSavingRenewal(false);

                        return false;
                    }
                }

                const shouldEndFunctionExecution = await needsDriverAssignmentUpdate();

                if (shouldEndFunctionExecution) {
                    setIsSavingRenewal(false);

                    return false;
                }

                setIsSavingRenewal(false);

                return renewalVM;
            } catch {
                setIsSavingRenewal(false);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: commonMessages.genericError,
                    message: commonMessages.genericErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });

                return false;
            }
        },
        [checkDuplicateDrivers, modalApi, checkMoreThanOneSNIRoles, doSave, getCreditReportStatus, isComponentValid, isCreditReportRequired, needsDriverAssignmentUpdate, renewalVM, setRelationShipStatusForSNI, setSpouseAsSNI]
    );


    const onSave = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }
            
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (hasSaveOperationCompleted.current && isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    /**
                     * E1PAP1PC-14986 :
                     * wizardData and wizardSnapshot not being equal due to
                     * some defaulting on each page so doing this temp fix
                     */
                    const pni = get(renewalVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
                    const pniDriver = get(renewalVM, 'lobData.personalAuto_EA.coverables.drivers.value')
                        .find((driver) => driver.person.publicID === pni.person.publicID);

                    if (pniDriver) {
                        set(pniDriver, 'relationshipToNI', undefined);
                        set(pniDriver, 'role', 'pni');
                    }

                    const sni = get(renewalVM.value, 'lobData.personalAuto_EA.secondaryNamedInsured');

                    if (sni) {
                        const sniDriver = get(renewalVM, 'lobData.personalAuto_EA.coverables.drivers.value')
                            ?.find((driver) => driver.person.publicID === sni.person.publicID);

                        if (sniDriver) {
                            set(sniDriver, 'role', 'sni');
                        }
                    }

                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [isComponentValid, onNext, renewalVM, updateWizardSnapshot]
    );

    // this grabs the latest element on the page -- this is to make sure this element has been loaded
    const latestDriverElement = document.getElementById(`driverFirstName${renewalVM.lobData.personalAuto_EA.coverables.drivers.children.length - 1}`);

    useEffect(() => {
        // indexStale set in the add driver function
        // once latest element is loaded and a new driver is added
        // we check if the button should be visible
        if (latestDriverElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestDriverElement]);

    // IAP-4225, if existing driver is removed, then we need to call save API
    useEffect(() => {
        const callOnSave = async () => {
            await onSave();
        }

        if (isExistingDriverDeleted && isComponentValid) {
            callOnSave();
            setIsExistingDriverDeleted(false);
        }
    }, [isComponentValid, isExistingDriverDeleted, onSave]);

    const reviewChanges = useCallback(
        async () => {
            setValidationErrors([]);

            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            // IAP-4716, check duplicate drivers
            const hasDuplicateDrivers = checkDuplicateDrivers();

            if (hasDuplicateDrivers) {
                setIsSavingRenewal(false);

                return false;
            }

            const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
            const driversList = get(renewalVM, driversPath);

            driversList.forEach((driver) => {
                /**
                 * E1PAP1PC-12823
                 * If relationshipTo Pni is spouse then that driver should be sni
                 * Marking driver as sni and function setSpouseAsSNI will convert it to sni
                 */
                if (driver.relationshipToNI === 'spouse') {
                    set(driver, 'role', 'sni');
                }
            });

            // E1PAP1PC-12823
            // Checks if more than one sni roles are present and if yes then sets page level error
            const hasMoreThanOneSNIRoles = checkMoreThanOneSNIRoles();

            if (hasMoreThanOneSNIRoles) {
                setIsSavingRenewal(false);

                return false;
            }

            /**
            * IAP-3005 : removing prompt to set Spouse as SNI
            * When Spouse is added as driver we will add it as SNI on the policy
            */
            setSpouseAsSNI(hasNewSNIAdded);

            // E1PAP1PC-12823
            // syncs relationship status for sni
            setRelationShipStatusForSNI();
            initializeSave(renewalVM);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            hasSaveOperationCompleted.current = true;

            if (isCreditReportRequired || hasNewSNIAdded.current) {
                if (!(await getCreditReportStatus(get(renewalVM, 'jobID.value')))) {
                    setIsSavingRenewal(false);

                    return false;
                }
            }

            const fieldIssues = get(renewalVM,
                'value.errorsAndWarnings.validationIssues.fieldIssues', []);
            const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);
            const shouldEndFunctionExecution = await needsDriverAssignmentUpdate();

            if (shouldEndFunctionExecution) {
                setIsSavingRenewal(false);

                return false;
            }

            /**
             * E1PAP1PC-14884 :
             * Jump to change summary page only if we don't have any error or exception
             */
            if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                jumpTo(changeSummaryIndex, true);
            }

            setIsSavingRenewal(false);

            return false;
        },
        [authHeader, changeSummaryIndex, checkDuplicateDrivers, checkMoreThanOneSNIRoles, getCreditReportStatus, isComponentValid, isCreditReportRequired, jumpTo, needsDriverAssignmentUpdate, renewalVM, setRelationShipStatusForSNI, setSpouseAsSNI, updateWizardData, updateWizardSnapshot]
    );

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (!isPageInitialized) {
            loadingMessage = translator(eaCommonMessages.loadingPrefillDataMessage);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isSavingRenewal || isSkipping) {
            loadingMessage = translator(eaCommonMessages.loadingNextPageMessage);
        }

        return loadingMessage;
    }, [isPageInitialized, isSavingCurrentPageChanges, isSavingRenewal, isSkipping, translator]);

    const getMessageForFinancialRespBasedonState = () => {
        let financialRespMessage = translator(eaValidationAndInfoMessages.sr22WithCompOnly);

        if (policyState === 'VA') {
            financialRespMessage = translator(eaValidationAndInfoMessages.sr22fr44WithCompOnly);
        }

        return financialRespMessage;
    };

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        driversPageLoadingIndicator: {
            loaded: isPageInitialized && !isSavingRenewal
                && !isSkipping && !isSavingCurrentPageChanges,
            text: getLoadingIndicatorMessage
        },
        driverPageMainContainer: {
            visible: isPageInitialized && !isSavingRenewal
                && !isSkipping && !isSavingCurrentPageChanges
        },
        driverHeaderInfoID: {
            visible: isPageInitialized && !isSavingRenewal && !isSkipping
        },
        addDriver: {
            accountNumber: get(renewalVM, 'baseData.accountNumber.value'),
            authHeader,
            excludedContacts: (() => {
                const drivers = get(renewalVM.value, 'lobData.personalAuto_EA.coverables.drivers', []);

                return drivers.map((driver) => driver.person.publicID);
            })(),
            viewModelService,
            setCheckScrolling,
            setIndexStale,
            setIsAccountContactsFetched,
            isAccountContactsFetched
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('eadrivercontainer')
        },
        DefensiveDriverInd: {
            visible: get(renewalVM, 'baseData.policyAddress.state.value.code') !== 'IN'
        },
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        defensiveDriverDiscountNotificationDiv: {
            visible: isPNIAndSNIValidForDefensiveDiscount() && ((pniHasDefensiveCourse && !sniHasDefensiveCourse)
                || (!pniHasDefensiveCourse && sniHasDefensiveCourse))
        },
        financialResponsibilityWithCompOnlyMessageDiv: {
            visible: EAValidatonUtil.isFinancialResponsibilitySelectedWithAllCompOnlyVehicle(renewalVM)
        },
        financialResponsibilityWithCompOnlyMessage: {
            message: getMessageForFinancialRespBasedonState()
        },
        nationalGuardWithDefensiveDriverMessageDiv: {
            visible: EAValidatonUtil.isNationalGuardSelectedWithoutDefensiveDriver(renewalVM),
        },
        EADriverGrid: {
            key: get(renewalVM, 'lobData.personalAuto_EA.coverables.drivers', []).length,
            drivers: get(renewalVM, 'lobData.personalAuto_EA.coverables.drivers', []),
            path: 'lobData.personalAuto_EA.coverables.drivers.children',
            onValidate,
            viewModelService,
            showErrors: isPageSubmitted,
            policyState: get(renewalVM, 'baseData.policyAddress.state.value.code'),
            driverPageDisregardFieldValidation: disregardFieldValidation,
            authHeader,
            checkLicenseRequired: (driver) => isLicenseNumberRequired(renewalVM, driver),
            driversWithLicenseValidationError,
            setDriversWithLicenseValidationErrors,
            getDriverAge,
            periodStartDate: get(renewalVM, 'baseData.periodStartDate.value'),
            priorPolicyList: get(renewalVM, 'lobData.personalAuto_EA.priorPolicyUpdates'),
            // onRemoveDriver: removeDriver,
            isFinancialResponsibilityFormAvailableForDriver,
            isNewSubmission: false,
            onValidationChange: onValidate,
            authUserData,
            transactionVM: renewalVM,
            isDefensiveDriverDiscountAvailableForDriver,
            setSNIHasDefensiveCourse,
            setPNIHasDefensiveCourse,
            updateWizardData,
            setCheckScrolling,
            setIsExistingDriverDeleted,
            showValidationMessage: () => {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            },
            setIsAccountContactsFetched
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate,
            onPrefillData: undefined,
            onViewModelService: viewModelService
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            onSave={onSave}
            showOnSave
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

EADriversPage.propTypes = wizardProps;
export default EADriversPage;
