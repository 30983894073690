import React, {
    useContext, useCallback, useEffect, useRef, useMemo
} from 'react';
import {
    get, set, isUndefined
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import metadata from '../UnderlyingPolicyPage.metadata.json5';

function ViewUnderlyingPolicyPage(props) {
    const {
        wizardData: rewriteVM,
        viewOnly
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const underlyingPoliciesVM = useMemo(() => get(rewriteVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []), [rewriteVM]);
    const jobType = get(rewriteVM.value, 'baseData.jobType');

    useEffect(() => {
        if (isUndefined(underlyingPoliciesVM.value)) {
            set(underlyingPoliciesVM, 'value', []);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(() => rewriteVM, [rewriteVM]);


    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            readOnly: viewOnly
        },
        underlyingPolicyPageLoadingIndicator: {
            loaded: true
        },
        underlyingPolicyPageContainer: {
            visible: true
        },
        euUnderlyingPolicyComponent: {
            data: rewriteVM,
            updateWizardData: () => {},
            isPageSubmitted: false,
            viewOnlyMode: true,
            deletedItemsRef: useRef([]),
            isPrivatePassengerQuestionVisible: false,
            onValidate: undefined,
            jobType
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
            />
        </WizardPage>
    );
}

ViewUnderlyingPolicyPage.propTypes = wizardProps;
export default ViewUnderlyingPolicyPage;
