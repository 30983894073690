import { defineMessages } from 'react-intl';

export default defineMessages({
    alternateTPIFound: {
        id: 'common.E1PPreferredTPIExistComponent.Alternate Third Party Found',
        defaultMessage: 'Alternate Third Party Found'
    },
    organizationHasPreferredContact: {
        id: 'common.E1PPreferredTPIExistComponent.This organization has a preferred contact for billing and/or policy document.',
        defaultMessage: 'This organization has a preferred contact for billing and/or policy document.'
    },
    tpiWillBeSaved: {
        id: 'common.E1PPreferredTPIExistComponent.The Third-Party Interest will be saved with this contact.',
        defaultMessage: 'The Third-Party Interest will be saved with this contact.'
    }
});
