import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import {
    set, get, some, findIndex, isEmpty
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { PrefillService } from 'e1p-capability-quoteandbind';
import { CreditService } from 'e1p-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { commonMessages as e1pCommonMessages, ehCommonMessages } from 'e1p-platform-translations';
import { RenewalService } from 'e1p-capability-renewal';
import metadata from './PropertyPage.metadata.json5';
import wizardMessages from '../../../EHRenewalWizard.messages';
import styles from './PropertyPage.module.scss';

function PropertyPage(props) {
    const translator = useTranslator();
    // eslint-disable-max-len
    const { authHeader } = useAuthentication();
    const {
        wizardData: renewalVM, updateWizardData,
        steps, jumpTo,
        onCustom2: handleDiscardChanges,
        updateWizardSnapshot
    } = props;

    const [isPageInitialized, setPageInitialized] = useState(false);
    const [isReplacementCostCalculated, setIsReplacementCostCalculated] = useState(false);
    const [isRecalculating, setIsRecalculating] = useState(false);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [heatingSystemType, updateHeatingSystemType] = useState(undefined);
    const [coolingSystemType, updateCoolingSystemType] = useState(undefined);
    const [creditScoreMoreThan597, updateCreditScoreMoreThan597] = useState(false);
    const [creditReportLoaded, setCreditReportLoaded] = useState(true);
    const isDiscardButtonActive = get(renewalVM, 'value.canDiscard_Ext', false);
    const changeSummaryIndex = findIndex(steps, ({ path }) => path === '/change-summary');
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isOutstandingChangesPresent, setIsOutstandingChangesPresent] = useState(true);
    const [isSubmittedWithoutRc, updateIsSubmittedWithoutRc] = useState(false);

    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerComponentValidation
    } = useValidation('PropertyPage');

    useEffect(() => {
        registerComponentValidation(() => (heatingSystemType !== undefined
                && coolingSystemType !== undefined))
    }, [coolingSystemType, heatingSystemType, registerComponentValidation]);


    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                setIsOutstandingChangesPresent(true);
                window.scrollTo(0, 0);

                return false;
            }

            // Set a different message for replacement cost
            if (isReplacementCostCalculated && !calledFromOnSave) {
                updateIsSubmittedWithoutRc(true);
                updateIsPageSubmitted(false);
                window.scrollTo(0, 0);

                return false;
            }

            updateIsSubmittedWithoutRc(false);
            setIsSavingRenewal(true);

            let serverCall = RenewalService.saveAndQuoteRenewal;

            if (calledFromOnSave && isReplacementCostCalculated) {
                serverCall = RenewalService.saveRenewal;
                updateIsPageSubmitted(false);
            }

            const saveResponse = await serverCall(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', saveResponse);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);

            return renewalVM;
        },
        [
            authHeader, isComponentValid, renewalVM,
            updateWizardData, isReplacementCostCalculated
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    const getCreditData = (async () => {
        setCreditReportLoaded(false);
        CreditService.getCreditReport(
            get(renewalVM, 'jobID.value'),
            authHeader
        ).then((response) => {
            const reportWithoutNoHit = response.creditRecords?.find((creditRecord) => creditRecord.reportStatus !== 'NOHIT');

            if (reportWithoutNoHit !== undefined) {
                updateCreditScoreMoreThan597(reportWithoutNoHit.creditScore >= 597);
            }

            setCreditReportLoaded(true);
        }).catch((exception) => {
            if (renewalVM.value.baseData.exceptions_Ext) {
                renewalVM.value.baseData.exceptions_Ext.push(
                    { errorMessage: exception.baseError ? exception.baseError : exception.message }
                );
            } else {
                set(
                    renewalVM.value.baseData,
                    `exceptions_Ext[${0}]`,
                    { errorMessage: exception.baseError ? exception.baseError : exception.message }
                );
            }

            updateWizardData(renewalVM);
            updateCreditScoreMoreThan597(false);
        }).finally(() => {
            setCreditReportLoaded(true);
        });
    });

    useEffect(() => {
        if (isEmpty(renewalVM.lobData.homeowners_EH.coverables.yourHome.value)) {
            renewalVM.lobData.homeowners_EH.coverables.yourHome = {};
        }

        if (isEmpty(renewalVM.lobData.homeowners_EH.coverables.construction.value)) {
            renewalVM.lobData.homeowners_EH.coverables.construction = {};
        }

        getCreditData();

        const heatingSystems = get(renewalVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            updateHeatingSystemType(heatingSystems[0].heatingType);
        }

        const coolingSystems = get(renewalVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');

        if (coolingSystems !== undefined && coolingSystems.length > 0) {
            updateCoolingSystemType(coolingSystems[0].coolingType);
        }

        updateWizardData(renewalVM);
        setPageInitialized(true);

        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // Logic for enabling and disabling the recalculate button
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const requiredFieldsForReplacementCost = [
        'yearBuilt',
        'numberOfStories',
        'totalSquareFeet',
        'exteriorWallFinish',
        'roofType',
        'roofShape',
        'garages', // has children
        'slopeType',
        'numberOfRoomsWithCathedralVaultedCeilings',
        'numberOfRoomsWithCrownMolding',
        'ceilingHeightType',
        'numberOfFullBaths',
        'numberOfHalfBaths',
        'kitchenCounterTopType',
        'foundationType',
        'basementFinishedArea',
        'heatingSystems',
        'coolingSystems',
        'yearRoofInstalled',
        'insideWallMaterials', // has children
        'floors', // has children
    ];
    // /**
    //  * isRecalculateReplacementCostDisable, will save Boolean value, which will be used to enable or disable
    //  * the recalculate ReplacementCost button on UI, if any of the value in requiredFieldsForReplacementCost array
    //  * is undefined or empty, then it will returns true, else false.
    //  */
    const isRecalculateReplacementCostDisable = (pcVM) => {
        const isDisabled = some(requiredFieldsForReplacementCost, (requiredField) => {
            const requiredFieldValue = get(pcVM.lobData.homeowners_EH.coverables.construction, `${requiredField}.value`);

            // requiredFieldValue could be string, integer, or array.
            if (Array.isArray(requiredFieldValue)) {
                return requiredFieldValue.length === 0;
            }

            return requiredFieldValue === undefined || requiredFieldValue === '';
        });

        return isDisabled || !isComponentValid;
    };

    const isRecalculateRequired = isReplacementCostCalculated && !isRecalculateReplacementCostDisable(renewalVM) && !isRecalculating;

    const recalculateReplacementCost = useCallback(
        async () => {
            if (isRecalculateRequired) {
                setIsRecalculating(true);
                updateIsSubmittedWithoutRc(false);

                const response = await PrefillService.orderReplacementCost(
                    renewalVM.jobID.value,
                    renewalVM.lobData.value,
                    authHeader
                );

                if (response?.homeowners_EH?.exceptions_Ext) {
                    renewalVM.baseData.exceptions_Ext = response?.homeowners_EH?.exceptions_Ext;
                    updateWizardData(renewalVM);
                    setIsRecalculating(false);
                } else {
                    setIsReplacementCostCalculated(false);
                    setIsOutstandingChangesPresent(true);
                    // E1PAP1PC-15700 : save all data till this point
                    set(
                        renewalVM.lobData.homeowners_EH.coverables,
                        'value',
                        response.homeowners_EH.coverables
                    );
                    set(renewalVM, 'value.flowStepIDs_Ext', ['property']);
                    RenewalService.saveRenewal(
                        [renewalVM.value],
                        authHeader
                    ).then((saveRenewalResp) => {
                        set(renewalVM, 'value', saveRenewalResp);
                        updateWizardData(renewalVM);
                        updateWizardSnapshot(renewalVM);
                    }).finally(() => {
                        setIsRecalculating(false);
                    });
                }
            } else {
                setIsOutstandingChangesPresent(false);
            }
        }, [isRecalculateRequired, renewalVM, authHeader, updateWizardData, updateWizardSnapshot]
    );

    const resolvers = {
        resolveCallbackMap: {
            recalculateReplacementCostClick: recalculateReplacementCost,
            onValidate,
        },
        resolveClassNameMap: styles
    };


    const onFormValueChange = useCallback(
        (newValue, path) => {
            const deconstructedPath = path.split('.');
            const isRcField = requiredFieldsForReplacementCost
                .concat(['firePlaces', 'styleOfHome']) // optional but does effect RC
                .some(
                    (field) => deconstructedPath.includes(field)
                );

            if (!isReplacementCostCalculated && isRcField) {
                set(
                    renewalVM,
                    'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                    undefined
                );
                setIsReplacementCostCalculated(true);
            }

            set(renewalVM, path, newValue);
            updateWizardData(renewalVM);
        },
        [isReplacementCostCalculated, renewalVM, requiredFieldsForReplacementCost, updateWizardData]
    );

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(
        () => {
            let loadingMessage = '';

            if (isRecalculating) {
                loadingMessage = translator(ehCommonMessages.recalculatingReplacementCostAndSavingAllInfo);
            } else if (isSavingCurrentPageChanges) {
                loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
            } else if (!isPageInitialized || isSavingRenewal) {
                loadingMessage = translator(ehCommonMessages.loadingNextPageMessage);
            }

            return loadingMessage;
        },
        [
            isRecalculating, isSavingCurrentPageChanges, isPageInitialized,
            isSavingRenewal, translator
        ]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => creditReportLoaded
            && !isSavingRenewal
            && !isSavingCurrentPageChanges
            && !isRecalculating,
        [creditReportLoaded, isRecalculating, isSavingCurrentPageChanges, isSavingRenewal]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            disabled: isRecalculating,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        replacementCostMissingNotificationDiv: {
            visible: isSubmittedWithoutRc
        },
        propertyPageLoader: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        propertyPageMainDiv: {
            visible: isPageLoaded
        },
        replacementCostRecalculate: {
            disabled: isRecalculateReplacementCostDisable(renewalVM)
                || isRecalculating
        },
        noOutstandingChangesNotificationDiv: {
            visible: !isOutstandingChangesPresent
        },
        replacementCostWithDollorrid: {
            content: (() => {
                if (isRecalculating) {
                    return [
                        {
                            id: 'InlineLoader',
                            type: 'element',
                            component: 'InlineLoader',
                            componentProps: {
                                loading: true,
                                size: 'medium',
                                loadingMessage: '',
                                successMessage: ''
                            }
                        }
                    ];
                }

 return [
                    {
                        id: 'replacementCost',
                        component: 'Currency',
                        type: 'field',
                        componentProps: {
                            readOnly: true,
                            className: 'replacementCost',
                            path: 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount',
                            label: {
                                id: '',
                                defaultMessage: ''
                            },
                            layout: 'reversed',
                            dataType: 'number'
                        }
                    }
                ];
            })()
        },
        e1pEHHF9PropertyDetailsComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            isPageSubmitted,
            heatingSystemType,
            updateHeatingSystemType,
            coolingSystemType,
            updateCoolingSystemType,
            setIsReplacementCostStale: (value) => { setIsReplacementCostCalculated(value); },
            creditScoreMoreThan597,
        },
    };

    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid
                || heatingSystemType === undefined
                || coolingSystemType === undefined
                || isReplacementCostCalculated) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            // Set a different message for replacement cost
            if (isReplacementCostCalculated) {
                updateIsPageSubmitted(false);
                updateIsSubmittedWithoutRc(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);
            jumpTo(changeSummaryIndex, true, quoteResponse);

            return false;
        },
        [
            isComponentValid, heatingSystemType, coolingSystemType, updateWizardData,
            isReplacementCostCalculated, authHeader, renewalVM, changeSummaryIndex, jumpTo
        ]
    );

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    if (!isPageInitialized) {
        return null;
    }


    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            isPageSubmittedWithErrors={
                isPageSubmitted
                && (!isComponentValid
                    || heatingSystemType === undefined
                    || coolingSystemType === undefined
                    || isReplacementCostCalculated)
            }
            skipWhen={initialValidation}
            onNext={onNext}
            onCustom={reviewChanges}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            onSave={onSave}
            showOnSave
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValueChange={onFormValueChange}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
