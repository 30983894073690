import { defineMessages } from 'react-intl';

export default defineMessages({
    eaUnderwritingOperator: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-underwriting.Choose Operator',
        defaultMessage: 'Choose Operator',
    },
    eaUnderwritingViolation: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-underwriting.Choose Violation',
        defaultMessage: 'Choose Violation',
    },
    eaUnderwritingDescription: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-underwriting.Choose Description',
        defaultMessage: 'Choose Description',
    },
    eaUnderwritingLossAmount: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-underwriting.Loss Amount',
        defaultMessage: 'Loss Amount',
    },
    eaUnderwritingServerity: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-underwriting.Choose Serverity',
        defaultMessage: 'Choose Serverity',
    },
    eaUnderwritingSource: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-underwriting.Choose Source',
        defaultMessage: 'Choose Source',
    },
    eaUnderwritingDelete: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-underwriting.delete',
        defaultMessage: 'delete',
    },
    lossUsedInRatingYes: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-loss.Yes',
        defaultMessage: 'Yes'

    },
    lossUsedInRatingNo: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-loss.No',
        defaultMessage: 'No'
    }
});
