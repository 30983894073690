import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo,
    useRef
} from 'react';
import {
    get as _get,
    set as _set,
    includes as _includes,
    isEqual as _isEqual,
    remove as _remove
} from 'lodash';
import { useModal } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { PropertyFlowUtil } from 'e1p-portals-util-js';
import { e1pDateUtil, usePropertyPageUtil } from 'e1p-capability-hooks';
import { E1PPropertyPageSectionHeaderComponent } from 'e1p-capability-policyjob-react';
import { commonMessages as e1pCommonMessages, ehCommonMessages } from 'e1p-platform-translations';
import config from 'app-config';
import htmlParser from 'html-react-parser';
import metadata from './E1PEHHO3PropertyDetailsComponent.metadata.json5';
import styles from './E1PEHHO3PropertyDetailsComponent.module.scss';
import requiredMetadata from './E1PEHHO3PropertyDetailsComponent.requiredness';

const SECTION_STATUS = {
    SUCCESS: 'success',
    FAILURE: 'failure',
    NONE: 'none'
};

function E1PEHHO3PropertyDetailsComponent(props) {
    const translator = useContext(TranslatorContext);
    const {
        onValidate,
        transactionVM,
        updateWizardData,
        viewOnlyMode,
        parentOverrideProps,
        isOutstandingChangesPresent,
        disregardFieldValidation,
        recalculateReplacementCost,
        creditReportLoaded,
        updateFloorMaterialErrorState,
        updateInsideWallMaterialPercentageSumErrorState,
        prefillCompleted,
        isSavingTransaction,
        savingPrefillData,
        isReplacementRecalculateCompleted,
        isSavingCurrentPageChanges,
        setIsReplacementCostStale,
        isPageSubmitted,
        creditScoreMoreThan597,
        isPageInitialized,
        isSkipping,
        isRecalculateReplacementCostDisable,
        heatingSystemType,
        updateHeatingSystemType,
        coolingSystemType,
        updateCoolingSystemType,
        insideWallMaterialPercentageSumErrorState,
        floorMaterialErrorState,
        creditChecked,
        isSubmittedWithoutRc
    } = props;
    
    const modalApi = useModal();

    const { authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [interiorStatus, updateInteriorStatus] = useState(SECTION_STATUS.NONE);
    const [exteriorStatus, updateExteriorStatus] = useState(SECTION_STATUS.NONE);
    const [basementStatus, updateBasementStatus] = useState(SECTION_STATUS.NONE);
    const [animalsStatus, updateAnimalsStatus] = useState(SECTION_STATUS.NONE);
    const [propertyInformationStatus, updatePropertyInformationStatus] = useState(SECTION_STATUS.NONE);
    const [heatingAndCoolingStatus, updateHeatingAndCoolingStatus] = useState(SECTION_STATUS.NONE);
    const policyState = _get(transactionVM, 'baseData.policyAddress.state.value.code') || _get(transactionVM, 'policyAddress.state.value.code');
    const [requiredFields, updateRequiredFields] = useState([]);
    const [expandedSections, setExpandedSections] = useState(
        viewOnlyMode ? [] : [
            'interiorAccordionCard',
            'exteriorAccordionCard',
            'basementAccordionCard',
            'animalsAccordionCard',
            'propertyInformationAccordionCard',
            'heatingAndCoolingAccordionCard'
        ]
    );

    const canChangeYearBuilt = authUserData?.permissions_Ext.includes('edityearbuilt_ext');
    const hasReplacementCost = _get(transactionVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value', undefined);
    const {
        addInsideWallMaterial,
        addFloorMaterial,
        addGarage
    } = usePropertyPageUtil(
        transactionVM,
        hasReplacementCost,
        updateWizardData,
        viewModelService,
        setIsReplacementCostStale
    );

    const jobType = _get(transactionVM.value, 'baseData.jobType') || _get(transactionVM, 'value.jobType');
    const updateInspectionData = useRef(false);
    const currentDateYear = useMemo(() => e1pDateUtil.getCurrentDateYear(), []);
    const { minYearBuilt } = config.enterpriseHomeownersConfig;

    const renderAccordionHeader = (title, status) => (isOpen) => (
            <E1PPropertyPageSectionHeaderComponent
                isOpen={isOpen}
                title={title}
                status={status}
            />
        );

    useEffect(
        () => {
            const initialRequiredFields = [
                'yearBuilt',
                'styleOfHome',
                'numberOfStories',
                'numberOfUnits',
                'totalSquareFeet',
                'propertySlope',
                'foundationType',
                'basementFinishedAreaType',
                'extereriorWallFinish',
                'roofMaterial',
                'yearRoofInstalled',
                'roofShape',
                'ceilingHeight',
                'roomsWithCathedralCeilings',
                'roomsWithCrownMoldings',
                'kitchenCountertop',
                'numberOfHalfBaths',
                'numberOfFullBaths',
                'totalNumberOfPeopleLiving',
                'hasSwimmingPool',
                'doesYourHomeHaveFence',
                'hasDogInHome',
                'highRiskDogBreedInd',
                'primaryHeatingSystem',
                'primaryCoolingSystem',
                'hasWoodOrCoalStove'
            ]; // Fields to look up by partner/state

            updateRequiredFields(
                isRequired(
                    initialRequiredFields,
                    requiredMetadata,
                    policyState,
                    'MSA'
                )
            );
            // When policystate changes update the required fields
        }, [policyState]
    );

    /**
     * Using the following booleans to make sure the status icons don't turn green until all
     *   added rows have completed fields
     */
    const areGarageFieldsIncomplete = transactionVM.lobData.homeowners_EH.coverables.construction.garages.value
        .some((garage) => {
              if (garage.garageType.toLocaleLowerCase() === 'none') {return false;}

            return !garage.garageSize || !garage.garageType;
        });

    const areFloorFieldsIncomplete = transactionVM.lobData.homeowners_EH.coverables.construction.floors.value
        .some((floor) => !floor.floorType || !floor.percentage);

    const areWallFieldsIncomplete = transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.value
        .some((wall) => !wall.materialType || !wall.percentage);

    const areFireplaceFieldsIncomplete = transactionVM.lobData.homeowners_EH.coverables.construction.firePlaces.value
        .some((firePlace) => !firePlace.heatingUnitType);


    useEffect(
        () => {
            const interiorWalls = _get(
                transactionVM, 'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value', []
            );
            const floors = _get(
                transactionVM, 'lobData.homeowners_EH.coverables.construction.floors.value', []
            );

            if (transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.ceilingHeightType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.ceilingHeightType.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCrownMolding.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCrownMolding.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.kitchenCounterTopType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.kitchenCounterTopType.value !== ''
                && (!insideWallMaterialPercentageSumErrorState)
                && (!floorMaterialErrorState)
                && interiorWalls.length > 0
                && floors.length > 0
                && !areWallFieldsIncomplete
                && !areFloorFieldsIncomplete) {
                updateInteriorStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || interiorStatus !== SECTION_STATUS.NONE) {
                updateInteriorStatus(SECTION_STATUS.FAILURE);
            }
        },
        [
            transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.ceilingHeightType.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCrownMolding.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.kitchenCounterTopType.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.value.length,
            transactionVM.lobData.homeowners_EH.coverables.construction.floors.value.length,
            insideWallMaterialPercentageSumErrorState,
            floorMaterialErrorState,
            transactionVM,
            isPageSubmitted,
            interiorStatus,
            areWallFieldsIncomplete,
            areFloorFieldsIncomplete,
            transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.value.length,
            transactionVM.lobData.homeowners_EH.coverables.construction.floors.value.length,
        ]
    );

    useEffect(
        () => {
            if (transactionVM.lobData.homeowners_EH.coverables.construction.foundationType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.foundationType.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.basementFinishedAreaType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.basementFinishedAreaType.value !== '') {
                updateBasementStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || basementStatus !== SECTION_STATUS.NONE) {
                updateBasementStatus(SECTION_STATUS.FAILURE);
            }
        },
        [
            transactionVM.lobData.homeowners_EH.coverables.construction.foundationType.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.basementFinishedAreaType.value,
            isPageSubmitted,
            basementStatus
        ]
    );

    useEffect(
        () => {
            const yearBuilt = parseInt(
                transactionVM.lobData.homeowners_EH.coverables.construction.yearBuilt.value,
                10
            );
            const hasValidYearBuilt = (currentDateYear >= yearBuilt) && (yearBuilt >= minYearBuilt);

            if (hasValidYearBuilt
                && transactionVM.lobData.homeowners_EH.coverables.construction.styleOfHome.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.styleOfHome.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.totalSquareFeet.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.totalSquareFeet.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfStories.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.numberOfStories.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.totalNumberOfPeopleLiving.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.totalNumberOfPeopleLiving.value !== '') {
                updatePropertyInformationStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || propertyInformationStatus !== SECTION_STATUS.NONE) {
                updatePropertyInformationStatus(SECTION_STATUS.FAILURE);
            }
        },
        [
            transactionVM.lobData.homeowners_EH.coverables.construction.yearBuilt.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.styleOfHome.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.totalSquareFeet.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.numberOfStories.value,
            transactionVM.lobData.homeowners_EH.coverables.yourHome.totalNumberOfPeopleLiving.value,
            transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value,
            currentDateYear,
            minYearBuilt,
            isPageSubmitted,
            propertyInformationStatus
        ]
    );

    useEffect(
        () => {
            const isHighRiskDogBreedRequired = _includes(requiredFields, 'highRiskDogBreedInd');
            const isHightRiskDogBreedAnswered = transactionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value !== undefined
            && transactionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value !== '';

            if (transactionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === false) {
                updateAnimalsStatus(SECTION_STATUS.SUCCESS);
            }
            // dogInHome is true
            else if (transactionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === true) {
                // highRiskdog is visible
                if (isHighRiskDogBreedRequired && isHightRiskDogBreedAnswered) {
                    updateAnimalsStatus(SECTION_STATUS.SUCCESS);
                }
                // highRiskdog is not visible and hasDogInHome is true
                else if (!isHighRiskDogBreedRequired) {
                    updateAnimalsStatus(SECTION_STATUS.SUCCESS);
                }
                else if (isPageSubmitted || animalsStatus !== SECTION_STATUS.NONE) {
                    updateAnimalsStatus(SECTION_STATUS.FAILURE);
                }
            }
        },
        [animalsStatus, isPageSubmitted, requiredFields, transactionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value, transactionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value]
    );

    useEffect(
        () => {
            if (transactionVM.lobData.homeowners_EH.coverables.construction.hasWoodOrCoalStove.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.hasWoodOrCoalStove.value !== ''
                && heatingSystemType !== undefined
                && coolingSystemType !== undefined
                && !areFireplaceFieldsIncomplete) {
                updateHeatingAndCoolingStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || heatingAndCoolingStatus !== SECTION_STATUS.NONE) {
                updateHeatingAndCoolingStatus(SECTION_STATUS.FAILURE);
            }
        },
        [
            transactionVM.lobData.homeowners_EH.coverables.construction.hasWoodOrCoalStove.value,
            heatingSystemType,
            coolingSystemType,
            isPageSubmitted,
            heatingAndCoolingStatus,
            areFireplaceFieldsIncomplete
        ]
    );

    useEffect(
        () => {
            if (transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value !== ''

                && transactionVM.lobData.homeowners_EH.coverables.construction.roofShape.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.roofShape.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.roofType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.roofType.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.slopeType.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.slopeType.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.doesYourHomeHaveFence.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.doesYourHomeHaveFence.value !== ''
                && transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value !== ''
                && ((transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value === true
                    && transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence.value)
                    || transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value === false
                    || creditScoreMoreThan597)
                && transactionVM.lobData.homeowners_EH.coverables.construction.garages.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.garages.value.length > 0
                && !areGarageFieldsIncomplete) {
                updateExteriorStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || exteriorStatus !== SECTION_STATUS.NONE) {
                updateExteriorStatus(SECTION_STATUS.FAILURE);
            }
        },
        [
            transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.roofShape.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.roofType.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.slopeType.value,
            transactionVM.lobData.homeowners_EH.coverables.yourHome.doesYourHomeHaveFence.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence.value,
            creditScoreMoreThan597,
            transactionVM.lobData.homeowners_EH.coverables.construction.garages.value,
            transactionVM.lobData.homeowners_EH.coverables.construction.garages.value.length,
            isPageSubmitted,
            exteriorStatus,
            areGarageFieldsIncomplete
        ]
    );

    const removeInsideWallMaterial = useCallback(
        (evt) => {
            const insideWallMaterialListPath = 'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value';
            const insideWallMaterialPath = evt.path;
            const insideWallMaterial = _get(transactionVM, insideWallMaterialPath).value;
            const insideWallMaterialList = _get(transactionVM, insideWallMaterialListPath);
            const insideWallMaterialIndex = insideWallMaterialList
                .findIndex((insideWallMaterialFromList) => _isEqual(insideWallMaterialFromList, insideWallMaterial));

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: ehCommonMessages.removeInsideWallMaterial,
                message: ehCommonMessages.removeInsideWallMaterialDescription,
                confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'INTERIOR WALL TYPE' }),
                cancelButtonText: e1pCommonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const newInsideWallMaterialList = _remove(insideWallMaterialList, (insideWallMaterialFromList) => (
                        _isEqual(insideWallMaterialFromList, insideWallMaterial)));

                    _set(transactionVM, newInsideWallMaterialList, insideWallMaterialListPath);
                    disregardFieldValidation(`wallMaterial${insideWallMaterialIndex}`);

                    if (hasReplacementCost) {
                        setIsReplacementCostStale(true);
                        _set(
                            transactionVM,
                            'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                            undefined
                        );
                    }

                    updateWizardData(transactionVM);
                }
            }, () => { });
        },
        [disregardFieldValidation, modalApi, setIsReplacementCostStale, hasReplacementCost, transactionVM, updateWizardData, translator]
    );
    const removeFloorMaterial = useCallback(
        (evt) => {
            const floorMaterialListPath = 'lobData.homeowners_EH.coverables.construction.floors.value';
            const floorMaterialPath = evt.path;
            const floorMaterial = _get(transactionVM, floorMaterialPath).value;
            const floorMaterialList = _get(transactionVM, floorMaterialListPath);
            const floorMaterialIndex = floorMaterialList
                .findIndex((floorMaterialFromList) => _isEqual(floorMaterialFromList, floorMaterial));

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: ehCommonMessages.removeFloorMaterial,
                message: ehCommonMessages.removeFloorMaterialDescription,
                confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'FLOOR MATERIAL' }),
                cancelButtonText: e1pCommonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const newFloorMaterialList = _remove(floorMaterialList, (floorMaterialFromList) => (
                        _isEqual(floorMaterialFromList, floorMaterial)));

                    _set(transactionVM, newFloorMaterialList, floorMaterialListPath);
                    disregardFieldValidation(`floorMaterial${floorMaterialIndex}`);

                    if (hasReplacementCost) {
                        setIsReplacementCostStale(true);
                        _set(
                            transactionVM,
                            'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                            undefined
                        );
                    }

                    updateWizardData(transactionVM);
                }
            }, () => { });
        },
        [disregardFieldValidation, modalApi, setIsReplacementCostStale, hasReplacementCost, transactionVM, updateWizardData, translator]
    );
    const removeGarage = useCallback(
        (evt) => {
            const garageListPath = 'lobData.homeowners_EH.coverables.construction.garages.value';
            const garagePath = evt.path;
            const garageMaterial = _get(transactionVM, garagePath).value;
            const garageList = _get(transactionVM, garageListPath);
            const garageIndex = garageList
                .findIndex((garageFromList) => _isEqual(garageFromList, garageMaterial));

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: ehCommonMessages.removeEHGarage,
                message: ehCommonMessages.removeEHGarageDescription,
                confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'GARAGE' }),
                cancelButtonText: e1pCommonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const newGarageList = _remove(garageList, (garageFromList) => (
                        _isEqual(garageFromList, garageMaterial)));

                    _set(transactionVM, newGarageList, garageListPath);
                    disregardFieldValidation(`ehGarage${garageIndex}`);
                    updateWizardData(transactionVM);

                    if (hasReplacementCost) {
                        setIsReplacementCostStale(true);
                        _set(
                            transactionVM,
                            'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                            undefined
                        );
                    }
                }
            }, () => { });
        },
        [disregardFieldValidation, modalApi, setIsReplacementCostStale, hasReplacementCost, transactionVM, updateWizardData, translator]
    );

    const removeFirePlace = useCallback(
        (evt) => {
            const firePlaceListPath = 'lobData.homeowners_EH.coverables.construction.firePlaces.value';
            const firePlacePath = evt.path;
            const firePlaceMaterial = _get(transactionVM, firePlacePath).value;
            const firePlaceList = _get(transactionVM, firePlaceListPath);
            const firePlaceIndex = firePlaceList
                .findIndex((firePlaceFromList) => _isEqual(firePlaceFromList, firePlaceMaterial));

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: ehCommonMessages.removeFirePlace,
                message: ehCommonMessages.removeFirePlaceDescription,
                confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'FIRE PLACE' }),
                cancelButtonText: e1pCommonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const newFirePlaceList = _remove(firePlaceList, (firePlaceFromList) => (
                        _isEqual(firePlaceFromList, firePlaceMaterial)));

                    _set(transactionVM, newFirePlaceList, firePlaceListPath);
                    disregardFieldValidation(`firePlace${firePlaceIndex}`);

                    if (hasReplacementCost) {
                        setIsReplacementCostStale(true);
                        _set(
                            transactionVM,
                            'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                            undefined
                        );
                    }

                    updateWizardData(transactionVM);
                }
            }, () => { });
        },
        [transactionVM, modalApi, translator, disregardFieldValidation, hasReplacementCost, updateWizardData, setIsReplacementCostStale]
    );

    const addFirePlace = useCallback(() => {
        const firePlace = {
            heatingUnitType: '',
            heatingUnitTypeDescription: ''
        };
        const {
            _xCenter,
            _dtoName,
        } = transactionVM.lobData.homeowners_EH.coverables.construction.firePlaces;
        const firePlaceVM = viewModelService.create(firePlace, _xCenter, _dtoName);

        transactionVM.lobData.homeowners_EH.coverables.construction.firePlaces.value.push(
            firePlaceVM.value
        );

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(transactionVM);
    }, [hasReplacementCost, setIsReplacementCostStale, transactionVM, updateWizardData, viewModelService]);

    const floorMaterialErrorVisibility = () => {
        const floorMaterialPercentageSumInValid = (transactionVM.lobData.homeowners_EH.coverables
         .construction.floors.value.reduce((sum, el) => sum + +el.percentage, 0) !== 100 && transactionVM.lobData.homeowners_EH.coverables.construction.floors.length > 0);

        if (floorMaterialErrorState !== floorMaterialPercentageSumInValid) {
            updateFloorMaterialErrorState(floorMaterialPercentageSumInValid);
        }

        return floorMaterialPercentageSumInValid;
    };

    const insideWallMaterialErrorVisibility = () => {
        const insideWallMaterialPercentageSumInValid = (transactionVM.lobData.homeowners_EH.coverables
            .construction.insideWallMaterials.value.reduce((sum, el) => sum + +el.percentage, 0) !== 100 && transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.length > 0);

        if (insideWallMaterialPercentageSumErrorState !== insideWallMaterialPercentageSumInValid) {
            updateInsideWallMaterialPercentageSumErrorState(insideWallMaterialPercentageSumInValid);
        }

        return insideWallMaterialPercentageSumInValid;
    };

    const dogInHomeChange = useCallback(
        (value) => {
            _set(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome', value);

            if (!value) {
                _set(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd', undefined);
            }

            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData]
    );

    const swimmingPoolFenceChange = useCallback(
        (value) => {
            _set(transactionVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence', value);
            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData]
    );

    const swimmingPoolValueChange = useCallback(
        (value) => {
            _set(transactionVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool', value);

            if (!value) {
                _set(transactionVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence', undefined);
            }

            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData]
    );

    const heatingSystemTypes = useMemo(() => {
        const heatingSystemDTO = viewModelService.create({}, 'pc', 'amfam.edge.capabilities.policyjob.lob.eh.coverables.dto.EHHeatingSystemDTO');

        return heatingSystemDTO.heatingType.aspects.availableValues.map((item) => ({
                code: item.code,
                name: {
                    id: item.name,
                }
            }));
    }, [viewModelService]);

    const heatingSystemTypeChange = useCallback((value) => {
        updateHeatingSystemType(value);

        const heatingSystems = _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            _set(transactionVM.value, `lobData.homeowners_EH.coverables.construction.heatingSystems[${0}].heatingType`, value);
        } else {
            const heatingSystem = {
                heatingType: value,
                isPrimary: true
            };
            const {
                _xCenter,
                _dtoName,
            } = transactionVM.lobData.homeowners_EH.coverables.construction.heatingSystems;
            const heatingSystemVM = viewModelService.create(heatingSystem, _xCenter, _dtoName);

            transactionVM.lobData.homeowners_EH.coverables.construction.heatingSystems.value.push(
                heatingSystemVM.value
            );
        }

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(transactionVM);
    }, [updateHeatingSystemType, transactionVM, hasReplacementCost, updateWizardData, viewModelService, setIsReplacementCostStale]);

    const coolingSystemTypes = useMemo(() => {
        const coolingSystemDTO = viewModelService.create({}, 'pc', 'amfam.edge.capabilities.policyjob.lob.eh.coverables.dto.EHCoolingSystemDTO');

          return coolingSystemDTO.coolingType.aspects.availableValues.map((item) => ({
                code: item.code,
                name: {
                    id: item.name,
                }
            }));
    }, [viewModelService]);

    const coolingSystemTypeChange = useCallback((value) => {
        updateCoolingSystemType(value);

        const coolingSystems = _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');

        if (coolingSystems !== undefined && coolingSystems.length > 0) {
            _set(transactionVM.value, `lobData.homeowners_EH.coverables.construction.coolingSystems[${0}].coolingType`, value);
            _set(transactionVM.value, `lobData.homeowners_EH.coverables.construction.coolingSystems[${0}].isPrimary`, true);
        } else {
            const coolingSystem = {
                coolingType: value,
                isPrimary: true
            };
            const {
                _xCenter,
                _dtoName,
            } = transactionVM.lobData.homeowners_EH.coverables.construction.coolingSystems;
            const coolingSystemVM = viewModelService.create(coolingSystem, _xCenter, _dtoName);

            transactionVM.lobData.homeowners_EH.coverables.construction.coolingSystems.value.push(
                coolingSystemVM.value
            );
        }

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(transactionVM);
    }, [updateCoolingSystemType, transactionVM, hasReplacementCost, updateWizardData, viewModelService, setIsReplacementCostStale]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(
        () => {
            let loadingMessage = '';

            if (!prefillCompleted) {
                loadingMessage = translator(ehCommonMessages.loadingPrefill);
            } else if (isSavingCurrentPageChanges) {
                loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
            } else if (!isPageInitialized || isSavingTransaction || isSkipping) {
                loadingMessage = translator(ehCommonMessages.loadingNextPageMessage);
            } else if (!isReplacementRecalculateCompleted) {
                loadingMessage = translator(ehCommonMessages.recalculatingReplacementCostAndSavingAllInfo);
            } else if (savingPrefillData) {
                loadingMessage = translator(ehCommonMessages.savingPrefillData);
            }

            return loadingMessage;
        },
        [
            prefillCompleted, isSavingCurrentPageChanges, isPageInitialized,
            isSavingTransaction, isSkipping, isReplacementRecalculateCompleted, translator,
            savingPrefillData
        ]
    );

    const checkSwimmingPoolFenceVisibility = useMemo(() => {

        if(viewOnlyMode || !creditChecked){
            return !!_get(transactionVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool');
        }

        return !!_get(transactionVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool') && !creditScoreMoreThan597;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creditScoreMoreThan597, transactionVM.lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value, viewOnlyMode, creditChecked]);

    const replacementCostFieldOnChange = useCallback(
        (value, path) => {
            _set(transactionVM, path, value);

            if (['lobData.homeowners_EH.coverables.construction.totalSquareFeet',
                'lobData.homeowners_EH.coverables.construction.yearBuilt']
                .includes(path)) {
                updateInspectionData.current = true;
            }

            if (path === 'lobData.homeowners_EH.coverables.construction.roofShape') {
                PropertyFlowUtil.addOrRemoveTotalHipRoofWindstormMitigationFeature(
                    viewModelService, transactionVM,
                    'totalHipRoof', value);
            }

            updateWizardData(transactionVM);

            if (hasReplacementCost) {
                setIsReplacementCostStale(true);
                _set(
                    transactionVM,
                    'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                    undefined
                );
            }
        },
        [hasReplacementCost, transactionVM, setIsReplacementCostStale, updateWizardData, viewModelService],
    );

    const resolvers = {
        resolveCallbackMap: {
            onAddInsideWallMaterialClick: addInsideWallMaterial,
            onRemoveInsideWallMaterialClick: removeInsideWallMaterial,
            onAddFloorMaterialClick: addFloorMaterial,
            onRemoveFloorMaterialClick: removeFloorMaterial,
            recalculateReplacementCostClick: recalculateReplacementCost,
            onAddGarageClick: addGarage,
            onRemoveGarageClick: removeGarage,
            onAddFirePlaceClick: addFirePlace,
            onRemoveFirePlaceClick: removeFirePlace,
            onValidate,
        },
        resolveClassNameMap: styles
    };

    const generateOverrides = useCallback(() => {
        const floors = _get(
            transactionVM,
            'lobData.homeowners_EH.coverables.construction.floors.value',
            []
        );
        const wallMaterials = _get(
            transactionVM,
            'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value',
            []
        );
        const garages = _get(
            transactionVM,
            'lobData.homeowners_EH.coverables.construction.garages.value',
            []
        );
        const firePlaces = _get(
            transactionVM,
            'lobData.homeowners_EH.coverables.construction.firePlaces.value',
            []
        );
     const floorOverrides = floors.map((_, index) => ({
                [`floorMaterial${index}`]: {
                    onValueChange: replacementCostFieldOnChange,
                    showErrors: isPageSubmitted,
                    viewOnly: viewOnlyMode
                },
                [`deleteFloorMaterial${index}`]: {
                    visible: !viewOnlyMode
                }
            }));
        const wallMaterialsOverrides = wallMaterials.map((_, index) => ({
                [`wallMaterial${index}`]: {
                    onValueChange: replacementCostFieldOnChange,
                    showErrors: isPageSubmitted,
                    viewOnly: viewOnlyMode
                },
                [`ehDeleteInsideWallMaterial${index}`]: {
                    visible: !viewOnlyMode
                }
            }));
        const garagesOverrides = garages.map((_, index) => ({
                [`ehGarage${index}`]: {
                    onValueChange: replacementCostFieldOnChange,
                    showErrors: isPageSubmitted,
                    viewOnly: viewOnlyMode
                },
                [`deleteGarage${index}`]: {
                    visible: !viewOnlyMode
                }
            }));
        const firePlacesOverrides = firePlaces.map((_, index) => ({
                [`firePlace${index}`]: {
                    onValueChange: replacementCostFieldOnChange,
                    viewOnly: viewOnlyMode
                },
                [`deleteFirePlace${index}`]: {
                    visible: !viewOnlyMode
                }
            }));

        return Object.assign(
            {},
            ...floorOverrides,
            ...wallMaterialsOverrides,
            ...garagesOverrides,
            ...firePlacesOverrides
        );
    }, [isPageSubmitted, replacementCostFieldOnChange, transactionVM, viewOnlyMode]);


    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false,
            readOnly: viewOnlyMode
        },
        propertyPageLoadingIndicator: {
            loaded: isPageInitialized && prefillCompleted && creditReportLoaded
                && !isSavingTransaction && !isSkipping && isReplacementRecalculateCompleted
                && !isSavingCurrentPageChanges && !savingPrefillData,
            text: getLoadingIndicatorMessage
        },
        propertyPageContainer: {
            visible: isPageInitialized && prefillCompleted && creditReportLoaded
                && !isSavingTransaction && !isSkipping && isReplacementRecalculateCompleted
                && !isSavingCurrentPageChanges && !savingPrefillData
        },
        replacementCostErrorNotificationDiv: {
            visible: isSubmittedWithoutRc || (isPageSubmitted
                && !_get(transactionVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value')
            )},
        yearBuilt: {
            required: _includes(requiredFields, 'yearBuilt'),
            maxValue: currentDateYear,
            showErrors: isPageSubmitted,
            onValidate,
            onValueChange: replacementCostFieldOnChange,
            minValue: minYearBuilt,
            readOnly: viewOnlyMode || (jobType !== 'Submission' && !canChangeYearBuilt)
        },
        styleOfHome: {
            required: _includes(requiredFields, 'styleOfHome'),
            onValueChange: replacementCostFieldOnChange
        },
        numberOfStories: {
            required: _includes(requiredFields, 'numberOfStories'),
            onValueChange: replacementCostFieldOnChange
        },
        numberOfUnits: {
            required: _includes(requiredFields, 'numberOfUnits')
        },
        totalSquareFeet: {
            required: _includes(requiredFields, 'totalSquareFeet'),
            onValueChange: replacementCostFieldOnChange
        },
        propertySlope: {
            required: _includes(requiredFields, 'propertySlope'),
            onValueChange: replacementCostFieldOnChange
        },
        foundationType: {
            required: _includes(requiredFields, 'foundationType'),
            onValueChange: replacementCostFieldOnChange
        },
        basementFinishedAreaType: {
            required: _includes(requiredFields, 'basementFinishedAreaType'),
            onValueChange: replacementCostFieldOnChange
        },
        roofMaterial: {
            required: _includes(requiredFields, 'roofMaterial'),
            onValueChange: replacementCostFieldOnChange
        },
        roofShape: {
            required: _includes(requiredFields, 'roofShape'),
            onValueChange: replacementCostFieldOnChange
        },
        yearRoofInstalled: {
            required: _includes(requiredFields, 'yearRoofInstalled'),
            maxValue: currentDateYear,
            minValue: _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.yearBuilt.value'),
            showErrors: isPageSubmitted,
            onValidate
        },
        totalNumberOfPeopleLiving: {
            required: _includes(requiredFields, 'totalNumberOfPeopleLiving')
        },
        extereriorWallFinish: {
            required: _includes(requiredFields, 'extereriorWallFinish'),
            onValueChange: replacementCostFieldOnChange
        },
        ceilingHeight: {
            required: _includes(requiredFields, 'ceilingHeight'),
            onValueChange: replacementCostFieldOnChange
        },
        roomsWithCrownMoldings: {
            required: _includes(requiredFields, 'roomsWithCrownMoldings'),
            onValueChange: replacementCostFieldOnChange
        },
        roomsWithCathedralCeilings: {
            required: _includes(requiredFields, 'roomsWithCathedralCeilings'),
            onValueChange: replacementCostFieldOnChange
        },
        kitchenCountertop: {
            required: _includes(requiredFields, 'kitchenCountertop'),
            onValueChange: replacementCostFieldOnChange
        },
        numberOfHalfBaths: {
            required: _includes(requiredFields, 'numberOfHalfBaths'),
            onValueChange: replacementCostFieldOnChange
        },
        numberOfFullBaths: {
            required: _includes(requiredFields, 'numberOfFullBaths'),
            onValueChange: replacementCostFieldOnChange
        },
        floorMaterialError: {
            visible: floorMaterialErrorVisibility()
        },
        insideWallMaterialError: {
            visible: insideWallMaterialErrorVisibility()
        },
        dogInHome: {
            onValueChange: dogInHomeChange,
              required: _includes(requiredFields, 'hasDogInHome'),
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        highRiskDogBreedIndGridWithSeprator: {
            visible: _includes(requiredFields, 'highRiskDogBreedInd')
            && !!_get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
        },
        highRiskDogBreedInd: {
            required: _includes(requiredFields, 'highRiskDogBreedInd')
            && !!_get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        swimmingPool: {
            onValueChange: swimmingPoolValueChange,
            required: _includes(requiredFields, 'hasSwimmingPool'),
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        swimmingPoolFence: {
            required: checkSwimmingPoolFenceVisibility,
            value: _get(transactionVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence'),
            onValueChange: swimmingPoolFenceChange,
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        swimmingPoolFenceGridWithSeprator: {
            visible: checkSwimmingPoolFenceVisibility
        },
        sixtyftFence: {
            required: _includes(requiredFields, 'doesYourHomeHaveFence'),
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        addFloorMaterial: {
            visible: !viewOnlyMode && transactionVM.lobData.homeowners_EH.coverables.construction.floors.length < 5
        },
        addInsideWallMaterial: {
            visible: !viewOnlyMode && transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.length < 5
        },
        primaryHeatingSystem: {
            availableValues: heatingSystemTypes,
            onValueChange: heatingSystemTypeChange,
            value: heatingSystemType,
            required: _includes(requiredFields, 'primaryHeatingSystem')
        },
        primaryCoolingSystem: {
            availableValues: coolingSystemTypes,
            onValueChange: coolingSystemTypeChange,
            value: coolingSystemType,
            required: _includes(requiredFields, 'primaryCoolingSystem'),
        },
        woodCoalStove: {
            required: _includes(requiredFields, 'hasWoodOrCoalStove'),
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        dogBreedSet1: {
            content: htmlParser(translator(ehCommonMessages.akitaAndPitbull))
        },
        dogBreedSet2: {
            content: htmlParser(translator(ehCommonMessages.alaskanAndRottweiler))
        },
        dogBreedSet3: {
            content: htmlParser(translator(ehCommonMessages.chowChowAndStaffordshire))
        },
        dogBreedSet4: {
            content: htmlParser(translator(ehCommonMessages.doberman))
        },
        addFirePlace: {
            visible: !viewOnlyMode && transactionVM.lobData.homeowners_EH.coverables.construction.firePlaces.length < 3
        },
        interiorAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.interior, interiorStatus)
        },
        basementAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.basementTitle, basementStatus)
        },
        heatingAndCoolingAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.heatingAndCooling, heatingAndCoolingStatus)
        },
        animalsAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.animals, animalsStatus)
        },
        exteriorAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.exterior, exteriorStatus)
        },
        propertyInformationAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.propertyInformation, propertyInformationStatus)
        },
        replacementCostRecalculate: {
            disabled: isRecalculateReplacementCostDisable(transactionVM),
            visible: !viewOnlyMode
        },
        noOutstandingChangesNotificationDiv: {
            visible: !isOutstandingChangesPresent
        },
        Accordion: {
            accordionStates: expandedSections,
            onUpdateAccordionStates: (openSections) => {
                setExpandedSections(openSections);
            }
        },
        replacementCost: {
            readOnly: true
        },
        addGarage: {
            visible: !viewOnlyMode
        },
        // iterable components in replacement cost
        ...generateOverrides(),
        ...parentOverrideProps()
    };

      const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                transactionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, transactionVM]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionVM}
            resolveValue={readValue}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={onValidate}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

E1PEHHO3PropertyDetailsComponent.propTypes = {
    coolingSystemType: PropTypes.string,
    floorMaterialErrorState: PropTypes.bool,
    heatingSystemType: PropTypes.string,
    insideWallMaterialPercentageSumErrorState: PropTypes.bool,
    transactionVM: PropTypes.shape({}).isRequired,
    updateCoolingSystemType: PropTypes.func.isRequired,
    updateFloorMaterialErrorState: PropTypes.func,
    updateHeatingSystemType: PropTypes.func.isRequired,
    updateInsideWallMaterialPercentageSumErrorState: PropTypes.func,
    parentOverrideProps: PropTypes.func,
    disregardFieldValidation: PropTypes.func,
    onValidate: PropTypes.func,
    recalculateReplacementCost: PropTypes.func,
    setIsReplacementCostStale: PropTypes.func,
    updateWizardData: PropTypes.func,
    creditReportLoaded: PropTypes.bool,
    creditScoreMoreThan597: PropTypes.bool,
    isOutstandingChangesPresent: PropTypes.bool,
    isPageInitialized: PropTypes.bool,
    isPrefillSuccessful: PropTypes.bool,
    isRecalculateReplacementCostDisable: PropTypes.func,
    isReplacementRecalculateCompleted: PropTypes.bool,
    isSavingCurrentPageChanges: PropTypes.bool,
    isSavingTransaction: PropTypes.bool,
    isSkipping: PropTypes.bool,
    prefillCompleted: PropTypes.bool,
    savingPrefillData: PropTypes.bool,
    viewOnlyMode: PropTypes.bool,
    creditChecked: PropTypes.bool,
    isSubmittedWithoutRc: PropTypes.bool
};

E1PEHHO3PropertyDetailsComponent.defaultProps = {
    parentOverrideProps:  () => { },
    disregardFieldValidation: () => { },
    onValidate: () => { },
    recalculateReplacementCost: () => { },
    setIsReplacementCostStale: () => { },
    isRecalculateReplacementCostDisable: () => { },
    updateWizardData: () => { },
    creditReportLoaded: true,
    creditScoreMoreThan597: true,
    isOutstandingChangesPresent: true,
    isPageInitialized: true,
    isReplacementRecalculateCompleted: true,
    prefillCompleted: true,
    isPageSubmitted: false,
    isSavingCurrentPageChanges: false,
    isSavingTransaction: false,
    isSkipping: false,
    savingPrefillData: false,
    viewOnlyMode: false,
    creditChecked: false,
    insideWallMaterialPercentageSumErrorState: false,
    updateInsideWallMaterialPercentageSumErrorState: () =>{},
    floorMaterialErrorState: false,
    updateFloorMaterialErrorState: () =>{},
    isSubmittedWithoutRc: false
};
export default E1PEHHO3PropertyDetailsComponent;
