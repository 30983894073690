import React, { useEffect, useState, useCallback } from 'react';
import { get as _get, set as _set } from 'lodash';
import { eaCommonMessages } from 'e1p-platform-translations';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { DriverAssignmentTable } from 'e1p-capability-policyjob-react';
import { useDriverAssignmentPageUtil } from 'e1p-capability-hooks';
import moment from 'moment';

const dobToAge = (dob) => {
    const TODAY = moment();
    const BIRTH_DATE = moment(dob);
    const MONTH_DIFF = TODAY.month() - BIRTH_DATE.month();
    let driverAge = TODAY.year() - BIRTH_DATE.year();

    if (
        MONTH_DIFF < 0 ||
        (MONTH_DIFF === 0 && TODAY.date() < BIRTH_DATE.date())
    ) {
        driverAge += -1;
    }

    return driverAge !== 0 ? driverAge : undefined;
};

function ViewDriverAssignmentPage(props) {
    const { wizardData: submissionVM } = props;

    const [principalDriversData, setPrincipalDriversData] = useState([]);
    const [occasionalDriversData, setOccasionalDriversData] = useState([]);
    const translator = useTranslator();
    const operatorFilter = (driver) => driver.policyDriverRoleType === 'operator';

    const {
        shouldOccupationCellShow,
        showOccupationColumn,
        checkOccupation,
    } = useDriverAssignmentPageUtil(
        submissionVM,
        principalDriversData,
        setPrincipalDriversData,
        () => {}
    );

    useEffect(() => {
        const drivers = _get(
            submissionVM.value,
            'lobData.personalAuto_EA.coverables.drivers'
        ).filter(operatorFilter);
        const vehicles = _get(
            submissionVM.value,
            'lobData.personalAuto_EA.coverables.vehicles'
        );
        const principalData = [];
        const occasionalData = [];

        if (drivers.length >= vehicles.length) {
            // for this case/for principal, vehicles will be static
            let allFoundOccasionalVehicleDrivers = [];

            vehicles.forEach((vehicle) => {
                const foundVehicleDriver = vehicle.vehicleDrivers.find(
                    (vehicleDriver) => vehicleDriver.primaryDriver
                );
                const foundOccasionalVehicleDriversOnVehicle = vehicle.vehicleDrivers.filter(
                    (vehicleDriver) => !vehicleDriver.primaryDriver
                );

                foundOccasionalVehicleDriversOnVehicle.forEach(
                    (vehDriverData) => {
                        _set(
                            vehDriverData,
                            'vehicle.vehicleName',
                            `${vehicle.year} ${vehicle.make} ${vehicle.model}`
                        );
                        _set(vehDriverData, 'vehicle.vin', vehicle.vin);
                        _set(vehDriverData, 'vehicle.fixedId', vehicle.fixedId);
                    }
                );
                allFoundOccasionalVehicleDrivers = allFoundOccasionalVehicleDrivers.concat(
                    foundOccasionalVehicleDriversOnVehicle
                );

                const driver = drivers.find((vehicleDriver) => (
                        foundVehicleDriver?.driverID === vehicleDriver.fixedId
                    ));
                const driverAge = dobToAge(driver?.person.dateOfBirth);

                principalData.push(
                    checkOccupation({
                        vehicle: {
                            vehicleName: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
                            vin: vehicle.vin,
                            fixedId: vehicle.fixedId,
                        },
                        driver: {
                            fixedId: foundVehicleDriver?.driverID || undefined,
                            driverName: driverAge
                                ? `${driver?.person.displayName} - ${driverAge}`
                                : '',
                        },
                    })
                );
            });

            // ocassional drivers should be remaining drivers that aren't primaries
            // in the case that vehicles were added so that drivers now equal vehicles
            //   the drivers now have to become primary instead of occasional
            if (
                allFoundOccasionalVehicleDrivers.length > 0 &&
                drivers.length !== vehicles.length
            ) {
                // servicing transaction or sub where drivers are assigned already
                allFoundOccasionalVehicleDrivers.forEach((vehicleDriverRow) => {
                    const driver = drivers.find((vehicleDriver) => (
                            vehicleDriverRow.driverID === vehicleDriver.fixedId
                        ));
                    const driverAge = dobToAge(driver?.person.dateOfBirth);

                    occasionalData.push({
                        driver: {
                            fixedId: vehicleDriverRow.driverID,
                            driverName: driverAge
                                ? `${driver?.person.displayName} - ${driverAge}`
                                : '',
                        },
                        vehicle: vehicleDriverRow.vehicle,
                    });
                });
            } else {
                // no operators ever added
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < drivers.length - vehicles.length; i++) {
                    occasionalData.push({ driver: {}, vehicle: {} });
                }
            }
        } else {
            // for this case/for principal, drivers will be static
            drivers.forEach((driver) => {
                const driverAge = dobToAge(driver.person.dateOfBirth);
                const vehicleUiObject = {
                    vehicleName: undefined,
                    vin: undefined,
                    fixedId: undefined,
                };

                vehicles.forEach((vehicle) => {
                    const vehicleHasDriver = vehicle.vehicleDrivers.find(
                        (vehicleDriver) => vehicleDriver.driverID === driver.fixedId
                    );

                    if (vehicleHasDriver) {
                        _set(
                            vehicleUiObject,
                            'vehicleName',
                            `${vehicle.year} ${vehicle.make} ${vehicle.model}`
                        );
                        _set(vehicleUiObject, 'vin', vehicle.vin);
                        _set(vehicleUiObject, 'fixedId', vehicle.fixedId);
                    }
                });
                principalData.push(
                    checkOccupation({
                        vehicle: vehicleUiObject,
                        driver: {
                            driverName: `${driver.person.displayName} - ${driverAge}`,
                            fixedId: driver.fixedId,
                        },
                    })
                );
            });
        }

        setOccasionalDriversData(occasionalData);
        setPrincipalDriversData(principalData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAvailableDrivers = useCallback(
        () => {
            const drivers = _get(
                submissionVM.value,
                'lobData.personalAuto_EA.coverables.drivers'
            ).filter(operatorFilter);

            return drivers.map((driver) => {
                const driverAge = dobToAge(driver.person.dateOfBirth);

                return {
                    code: driver.fixedId,
                    name: `${driver.person.displayName} - ${driverAge}`,
                };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onNext = useCallback(async () => submissionVM, [submissionVM]);

    const mainContent = (
        <React.Fragment>
            {principalDriversData.length > 0 ? (
                <DriverAssignmentTable
                    id="principleDriversTable"
                    header={translator(eaCommonMessages.principalDrivers)}
                    principalDriverData={principalDriversData}
                    getAvailableDrivers={getAvailableDrivers}
                    handleDriverChange={() => {}}
                    numberOfDrivers={
                        _get(
                            submissionVM.value,
                            'lobData.personalAuto_EA.coverables.drivers',
                            []
                        ).filter(operatorFilter).length
                    }
                    numberOfVehicles={_get(
                        submissionVM.value,
                        'lobData.personalAuto_EA.coverables.vehicles.length'
                    )}
                    viewOnlyMode
                    showOccupationColumn={showOccupationColumn}
                    shouldOccupationCellShow={shouldOccupationCellShow}
                />
            ) : undefined}
            {occasionalDriversData.length > 0 ? (
                <DriverAssignmentTable
                    id="occasionalDriversTable"
                    header={translator(eaCommonMessages.occasionalDrivers)}
                    occasionalDriverData={occasionalDriversData}
                    numberOfDrivers={
                        _get(
                            submissionVM.value,
                            'lobData.personalAuto_EA.coverables.drivers',
                            []
                        ).filter(operatorFilter).length
                    }
                    // getAvailableDrivers={getAvailableDrivers}
                    // handleDriverChange={onDriverChangeCallback}
                    numberOfVehicles={_get(
                        submissionVM.value,
                        'lobData.personalAuto_EA.coverables.vehicles.length'
                    )}
                    viewOnlyMode
                    showOccupationColumn={showOccupationColumn}
                />
            ) : undefined}
        </React.Fragment>
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <div className="mt-8">
                <h4>
                    {translator(eaCommonMessages.driverAssignmentPageTitle)}
                </h4>
                <p>
                    {translator(eaCommonMessages.driverAssignmentPageSubTitle)}
                </p>
                {mainContent}
            </div>
        </WizardPage>
    );
}

ViewDriverAssignmentPage.propTypes = wizardProps;

export default ViewDriverAssignmentPage;
