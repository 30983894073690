import { defineMessages } from 'react-intl';

export default defineMessages({
    completingYourPurchaseMessage: {
        id: 'renewal.eh.renewalChangeDiffPage.Completing your purchase...',
        defaultMessage: 'Completing your purchase...'
    },
    loadingPolicyDiff: {
        id: 'renewal.eh.renewalChangeDiffPage.Loading...',
        defaultMessage: 'Loading...'
    },
    changesPhrases: {
        id: 'renewal.eh.policyDiffPage.Change Phrases',
        defaultMessage: 'Change Phrases'
    },
    issue: {
        id: 'renewal.eh.policyDiffPage.Issue',
        defaultMessage: 'Issue'
    },
    bindError: {
        id: 'renewal.eh.config.Unable to bind change',
        defaultMessage: 'Unable to bind change'
    },
    bindErrorMessage: {
        id: 'renewal.eh.config.An error occurred while attempting to bind changes',
        defaultMessage: 'An error occurred while attempting to bind changes'
    },
    changeConflict: {
        id: 'renewal.eh.renewalChangeDiffPage.tab.Change Conflicts',
        defaultMessage: 'Change Conflicts'
    },
    signature: {
        id: 'renewal.eh.renewalChangeDiffPage.tab.Signature',
        defaultMessage: 'Signature'
    },
    differencesTab: {
        id: 'renewal.eh.renewalChangeDiffPage.tab.Differences',
        defaultMessage: 'Differences'
    },
    noDataText: {
        id: 'renewal.eh.policyDiffPage.No Change Phrases',
        defaultMessage: 'No Change Phrases'
    }
});
