import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { RenewalService } from 'gw-capability-gateway';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
 
import appConfig from 'app-config';
import { PageHeaderComponent } from '../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import DocumentsPage from '../Policies/E1PDocuments/Documents';
import CommonRoutes from '../Common/CommonRouting';
import metadata from './Renewal.metadata.json5';
import styles from './Renewal.module.scss';
import pageRouting from '../Common/Renewal-config.json5';

const { capabilitiesConfig } = appConfig;

const DocumentPageService = {
    getDocuments: RenewalService.getDocumentsForRenewal,
    generateUploadToken: GatewayDocumentService.generateUploadToken,
    uploadDocument: GatewayDocumentService.uploadDocument,
    removeDocument: GatewayDocumentService.removeDocument,
    downloadDocument: GatewayDocumentService.downloadDocument
};

function getRoutes() {
    const documentRoute = {
        path: '/documents',
        component: DocumentsPage
    };

    if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'document' })) {
        return [...pageRouting, documentRoute];
    }

    return pageRouting;
}

class Renewal extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }),
        match: PropTypes.shape({
            params: PropTypes.shape({
                jobNumber: PropTypes.string
            }),
            url: PropTypes.string
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string
        }).isRequired
    };

    state = {
        currentView: 'summary',
        tileCountData: {},
        renewal: {}
    };

    componentDidMount() {
        this.onPageLoad();
    }

    onPageLoad = () => {
        this.getRenewalSummaryCount();
        this.getJobByJobNumber();
    }

    handleTilesOnClick = (id) => {
        this.setState({ currentView: id });
    };

    getRenewalSummaryCount = () => {
        const {
            authHeader,
            match: {
                params: { jobNumber }
            },
            location: { pathname }
        } = this.props;

        const routePath = pathname && (pathname.trim().split('/') || []);
        const selectedPageFromPath = routePath[routePath.length - 1];
        const activeTile = selectedPageFromPath || 'summary';

        RenewalService.getRenewalSummary(jobNumber, authHeader).then((response) => {
            this.setState({
                currentView: activeTile,
                tileCountData: response
            });
        });
    };

    updateRenewalSummary = (renewal) => {
        this.setState({ renewal });
    };

    getJobByJobNumber = async () => {
        const {
            authHeader,
            match: {
                params: { jobNumber }
            }
        } = this.props;

        const renewal = await RenewalService.findJobByJobNumber(jobNumber, authHeader);

        this.updateRenewalSummary(renewal);
    };

    render() {
        const { currentView, tileCountData, renewal } = this.state;

        if (_.isEmpty(renewal)) {
            return null;
        }

        const renewalVM = {
            renewal,
            tileCountData
        };
        const overrides = {
            [currentView]: {
                active: true
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                handleTilesOnClick: this.handleTilesOnClick
            },
            resolveComponentMap: {
                pageheadercomponent: PageHeaderComponent
            }
        };
        const QuoteDetailspage = (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
        const {
            match: {
                url,
                params: { jobNumber }
            }
        } = this.props;
        const quoteData = {
            jobNumber,
            loadRenewalSummary: renewal,
            updateJobSummary: this.updateRenewalSummary,
            getRenewalSummaryCount: this.getRenewalSummaryCount,
            refreshJobSummary: this.onPageLoad
        };

        return (
            <DependencyProvider value={{ DocumentPageService }}>
                {QuoteDetailspage}
                <CommonRoutes
                    steps={getRoutes()}
                    basePath={url}
                    setDocumentCount={this.getRenewalSummaryCount}
                    jobNumber={jobNumber}
                    quoteDetailsData={quoteData}
                />
            </DependencyProvider>
        );
    }
}

export default withAuthenticationContext(Renewal);
