const overrides = {
    field: {
        propertyLosses: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT']
                }
            }
        },
        policyTypeHF9: {
            partner: {
                MSA: {
                    states: ['VA', 'NC', 'SC']
                }
            }
        }
    }
};

export default overrides;
