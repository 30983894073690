import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue, appendMetadataWithIndex } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './EALossReportComponent.metadata.json5';
import './EALossReportComponent.messages';
import styles from './EALossReportComponent.module.scss';

function EALossReportComponent(props) {
    const {
        data: lossesVM,
        labelPosition,
        showOptional,
        path,
        id,
        onValidate,
        onValueChange,
        operators,
        viewOnlyMode,
        index,
        showErrors
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    const formattedMetadata = useMemo(() => appendMetadataWithIndex(metadata.pageContent, index), [index]);

    /**
     * E1PAP1PC-13490, we need to set focus on the first element of the loss node
     * when ever the new loss is added.
     * this useEffect will execute only once the component is rendered and based on condition
     * it will focus the first element on the UI.
     */
    useEffect(() => {
        const lossOperatorElement = document.getElementById(`lossOperator${index}`);

        if (lossOperatorElement
            && lossOperatorElement.focus !== undefined
            && _.isEmpty(_.get(lossesVM, 'value.assignment.publicId'))) {
            lossOperatorElement.focus();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [lossesVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;

        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, path]);

    const startingDate = new Date();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            readOnly: viewOnlyMode,
            showErrors,
            autoComplete: false
        },
        [`lossType${index}`]: { required: !_.get(lossesVM, 'lossType.value') },
        [`lossIncidentDate${index}`]: {
            isRequired: true,
            maxDate: {
                year: startingDate.getFullYear(),
                month: startingDate.getMonth(),
                day: startingDate.getDate() - 1
            },
            updateDateDto: (newDate) => handleValueChange(
                newDate, 'incurDate'
            ),
            dateDTO: lossesVM?.incurDate?.value,
            onValidate
        },
        [`lossOperator${index}`]: {
            availableValues: operators.map((operator) => ({
                    code: `${operator.publicId}`,
                    name: `${operator.displayName}`
                })),
            required: !_.get(lossesVM, 'assignment.publicId.value')
        },
        [`lossSource${index}`]: {
            readOnly: true,
            value: 'Self-Declared'
        }
    };

    const readValue = useCallback(
        (fieldId, fieldPath) =>
            readViewModelValue(
                metadata.pageContent,
                lossesVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [lossesVM, overrideProps]
    );

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={formattedMetadata}
            model={lossesVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

EALossReportComponent.propTypes = {
    data: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    showOptional: PropTypes.bool,
    operators: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    index: PropTypes.number.isRequired,
    showErrors: PropTypes.bool
};
EALossReportComponent.defaultProps = {
    data: {},
    labelPosition: 'top',
    path: undefined,
    showOptional: true,
    id: undefined,
    viewOnlyMode: false,
    onValidate: undefined,
    showErrors: false
};
export default EALossReportComponent;
