import { defineMessages } from 'react-intl';

export default defineMessages({
    vin: {
        id: 'DriverAssignmentTable.component.header.VIN',
        defaultMessage: 'VIN'
    },
    vehicle: {
        id: 'DriverAssignmentTable.component.Vehicle',
        defaultMessage: 'Vehicle'
    },
    driver: {
        id: 'DriverAssignmentTable.component.Driver',
        defaultMessage: 'Driver'
    },
    occupation:{
        id: 'DriverAssignmentTable.component.Occupation',
        defaultMessage: 'Occupation'
    },
    student:{
        id: 'DriverAssignmentTable.component.Student',
        defaultMessage: 'Student'
    },
    activeMilitaryCode:{
        id: 'DriverAssignmentTable.component.activeMilitary',
        defaultMessage: 'activeMilitary'
    },
    activeMilitary:{
        id: 'DriverAssignmentTable.component.Active Military',
        defaultMessage: 'Active Military'
    },
    other:{
        id: 'DriverAssignmentTable.component.other',
        defaultMessage: 'Other'
    },
    occupationHelpText:{
        id: 'DriverAssignmentTable.component.Occupation is required for the Principal Driver of any vehicle not garaged in NC.',
        defaultMessage: 'Occupation is required for the Principal Driver of any vehicle not garaged in NC.'
    }
});
