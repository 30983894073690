import React, {
    useContext, useCallback, useEffect
} from 'react';
import { get, set, sortBy, uniqBy
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import appConfig from 'app-config';
import messages from './QuotePage.messages';
import styles from './QuotePage.module.scss';

import metadata from './QuotePage.metadata.json5';


function generateClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeCoveragePath = 'coverages.coverages';

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

function generateExclusionClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeExclusionPath = 'exclusions';

        return {
            code,
            path: `${lob.path}.${completeExclusionPath}`,
            clauses: get(lob.data, completeExclusionPath.replace(/\.children/, ''))
        };
    });
}

function generateConditionClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeConditionPath = 'conditions';

        return {
            code,
            path: `${lob.path}.${completeConditionPath}`,
            clauses: get(lob.data, completeConditionPath.replace(/\.children/, ''))
        };
    });
}

const generateColumnData = (policyViewVM) => {
    const lobOfferingPath = 'lobData.homeowners_EH.offerings';
    // const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = get(policyViewVM, `${lobOfferingPath}.value`);
    // const quoteOfferings = get(policyViewVM, `${quoteOfferingPath}.value`);

    const columnData = lobOfferings.map((lobOffering, lobIndex) => ({
            name: lobOffering.branchName,
            code: lobOffering.branchCode,
            quote: {
                path: '',
                data: {}
            },
            lob: {
                path: `${lobOfferingPath}.children[${lobIndex}]`,
                data: lobOffering
            },
            policyViewVM
        }));

    // }).filter(({ code }) => code !== 'CUSTOM');
    return sortBy(columnData, ['code']);
};


const getExclusionsUniqueID = (policyViewVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (exclusion) => ({
        publicID: exclusion.publicID,
        coverageCategoryCode: exclusion.exclusionCategoryCode,
        uigroup: exclusion.uigroup
    });

    const offerings = get(policyViewVM, 'lobData.homeowners_EH.offerings.value');
    const exclusions = uniqBy(
        offerings.flatMap((offering) => offering.exclusions.map(structureClauseTableData)),
        'publicID'
    );

    return {
        exclusions
    };
};

const getConditionsUniqueID = (policyViewVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (condition) => ({
        publicID: condition.publicID,
        coverageCategoryCode: condition.conditionCategoryCode,
        uigroup: condition.uigroup
    });

    const offerings = get(policyViewVM, 'lobData.homeowners_EH.offerings.value');
    const conditions = uniqBy(
        offerings.flatMap((offering) => offering.conditions.map(structureClauseTableData)),
        'publicID'
    );

    return {
        conditions
    };
};

const getCoveragesUniqueID = (policyViewVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (coverage) => ({
        publicID: coverage.publicID,
        coverageCategoryCode: coverage.coverageCategoryCode,
        uigroup: coverage.uigroup
    });
    const offerings = get(policyViewVM, 'lobData.homeowners_EH.offerings.value');
    const offerringsWithCovsNotHidden = offerings.map((offerring) => {
        const offerringFormatted = { ...offerring};

        offerringFormatted.coverages.coverages = offerring.coverages.coverages
            .filter((coverage) => coverage.isHiddenInVersion !== true);

        return offerringFormatted;
    });
    const baseCoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap(
            (offering) => offering.coverages.coverages.map(structureClauseTableData)
        ),
        'publicID'
    );
    const additionalCoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap(
            (offering) => offering.coverages.schedules.map(structureClauseTableData)
        ),
        'publicID'
    );
    const sectionICoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Section I')
            .map(structureClauseTableData)),
        'publicID'
    );
    const sectionIICoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Section II')
            .map(structureClauseTableData)),
        'publicID'
    );
    const deductibles = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Deductibles')
            .map(structureClauseTableData)),
        'publicID'
    );

    return {
        baseCoverages,
        additionalCoverages,
        sectionICoverages,
        sectionIICoverages,
        deductibles
    };
};


const generateTableData = (policyViewVM, columnData) => {
    const uniqueID = getCoveragesUniqueID(policyViewVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateClauseData(columnData, coverageType)
    }));
};

const generateExclusionTableData = (policyViewVM, columnData) => {
    const uniqueID = getExclusionsUniqueID(policyViewVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateExclusionClauseData(columnData, coverageType)
    }));
};

const generateConditionTableData = (policyViewVM, columnData) => {
    const uniqueID = getConditionsUniqueID(policyViewVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateConditionClauseData(columnData, coverageType)
    }));
};

const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;

function QuotePage(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: policyViewVM
    } = props;

    useEffect(() => {
        // displaying only one offering
        if (policyViewVM.value.lobData.homeowners_EH.offerings.length > 1) {
            policyViewVM.value.lobData.homeowners_EH.offerings = policyViewVM
                .value.lobData.homeowners_EH.offerings
                .filter((offering) => offering.branchCode !== 'CUSTOM');
        }

        if (policyViewVM.lobData.homeowners_EH.paperlessInd.value === undefined
            && get(policyViewVM, 'quoteSource_Ext.sourceType.value.code') === 'directentry') {
            set(policyViewVM, 'lobData.homeowners_EH.paperlessInd.value', true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePrint = () => {
        window.print();
    };

    const checkComparatorFlow = useCallback(() => {
        if (get(policyViewVM, 'quoteSource_Ext.sourceType')) {
            const isSourceTypeCompRater = get(
                policyViewVM, 'quoteSource_Ext.sourceType.value.code'
            ) === 'comprater';
            const quoteType = get(policyViewVM, 'quoteType_Ext.value.code');

            return (isSourceTypeCompRater && quoteType === 'Quick');
        }

        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkDisable = useCallback(() => {
        if (policyViewVM.value.lobData.homeowners_EH.acknowledgements === undefined) {
            return false;
        }

        return policyViewVM.value.lobData.homeowners_EH.acknowledgements
            .some((acknowledgement) => acknowledgement.acknowledgeAnswerType === 'reject');
    }, [policyViewVM]);

    const buyNow = useCallback(
        async () => policyViewVM,
        [policyViewVM]
    );

    const columnData = generateColumnData(policyViewVM);

    const policyType = get(policyViewVM, 'lobData.homeowners_EH.policyType.value.code');

    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            readOnly: true
        },
        quotePageLoadingIndicator: {
            loaded: true
        },
        quoteProposalLinkContainer: {
            visible: false
        },
        quoteTableHO3: {
            columnData,
            tableData: generateTableData(policyViewVM, columnData),
            tableDataExclusion: generateExclusionTableData(policyViewVM, columnData),
            tableDataCondition: generateConditionTableData(policyViewVM, columnData),
            underwritingIssues: [],
            filterUWIssuesInCustomOffering: false,
            submissionVM: policyViewVM,
            modifiers: get(policyViewVM, 'lobData.homeowners_EH.modifiers.value'),
            isComprater: checkComparatorFlow(),
            onDisableNextForTable: checkDisable,
            viewModelService,
            updateWizardData: () => {},
            authHeader,
            jumpTo: undefined,
            steps: [],
            setFieldsChangedOnCoveragePage: undefined,
            fieldsChangedOnCoveragePage: false,
            policyType,
            quoteIsStale: false,
            onScheduleChange: undefined,
            viewOnlyMode: true
        },
        startDate: {
            visible: showQuoteStartDate
        },
        printPage: {
            visible: !showQuoteStartDate
        }
    };

    return (
        <WizardPage
            shouldLink
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveClassNameMap: styles,
                    resolveCallbackMap: {
                        onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
                        onRecalculate: () => { },
                        onResetQuote: () => { },
                        onChangeSubmissionAndSync: () => { },
                        onChangeSubmission: () => { },
                        onSyncCoverages: () => { },
                        onScheduleChange: () => { },
                        onStaleQuoteBranchCode: () => { },
                        onPrint: handlePrint,
                        updateWizardData: () => {}
                    }
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={policyViewVM}
                        overrideProps={overrideProps}
                        classNameMap={resolvers.resolveClassNameMap}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                );
            }}
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
