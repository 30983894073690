import React, {
    useContext, useCallback, useState, useEffect, useMemo
} from 'react';
import {
    get, set, isUndefined, findIndex, isEmpty, filter,
    includes, find
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { RenewalService } from 'e1p-capability-renewal';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useModal } from '@jutro/components';
import { PackageDifferenceComponent, ProtectiveDevices, PackageQualifyingEndorsementsComponent } from 'e1p-capability-policyjob-react';
import { OptionalCoveragesPopup } from 'e1p-capability-policychange-eh-react';
import { commonMessages as e1pCommonMessages, ehCommonMessages } from 'e1p-platform-translations';
import { QuoteProposalService } from 'e1p-capability-gateway';
import { ConsoleHelper, PropertyFlowUtil, ClausesUtil as e1pClausesUtil } from 'e1p-portals-util-js';
import wizardMessages from '../../EHRenewalWizard.messages';
import metadata from './CoveragesPage.metadata.json5';
import messages from './CoveragesPage.messages';
import styles from './CoveragesPage.module.scss';

const OPTIONAL_COVERAGE_CATEGORIES = [
    'EHLineAddGrp',
    'EH_OptionalProp',
    'EH_OptionalLiability',
    'EH_PersonalProtection',
    'EH_HomeBusiness',
    'EH_AdditionalProp',
    'EH_Home',
    'EH_PersonalBelongings',
    // 'EH_EndorsementPackage'
];

const structureCustomQuote = (renewalVM, clauses) => 
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        coverages: clauses.homeowners_EH
    })
;

const getCustomQuote = (vm, lobPath, lobName, filterChangedClauses = false) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages, lobName, null
        );
    }

    return structureCustomQuote(vm, clausesToUpdate);
};

function CoveragesPage(props) {
    const modalApi = useModal();
    const [loadingClause, updateLoadingClause] = useState();

    const translator = useTranslator();
    const {
        wizardData: renewalVM, updateWizardData, isSkipping, authHeader,
        steps, jumpTo,
        onCustom2: handleDiscardChanges,
        updateWizardSnapshot,
        authUserData,
        e1pGoNext,
        markFurthestStep,
        currentStepIndex
    } = props;
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [isQuoteStale, setIsQuoteStale] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const [selectedOptionalCoverages, updateSelectedOptionalCovs] = useState([]);
    const [coveragesModified, updateCoveragesModified] = useState(false);
    const [hasProtectiveDevices, setHasProtectiveDevices] = useState(false);
    const [isFetchingQuoteProposal, setIsFetchingQuoteProposal] = useState(false);
    // canStartEdit_Ext will be true if the renewal is in workflow, else it will be false
    const isRenewalInWorkflow = get(renewalVM, 'value.canStartEdit_Ext', false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const isDiscardButtonActive = get(renewalVM, 'value.canDiscard_Ext', false);
    const changeSummaryIndex = findIndex(steps, ({ path }) => path === '/change-summary');
    const paperLessIndValue = get(renewalVM, 'lobData.homeowners_EH.paperlessInd.value') === undefined
        ? false : get(renewalVM, 'lobData.homeowners_EH.paperlessInd.value');
    const {
        initialValidation,
        disregardFieldValidation,
        registerComponentValidation,
        isComponentValid,
        onValidate
    } = useValidation('CoveragesPage');
    const policyState = get(renewalVM, 'baseData.policyAddress.state.value.code');
    const modifiers = get(renewalVM, 'lobData.homeowners_EH.modifiers.value', []);
    const hasGoldOrPlatinumBoxVisible = !!(get(renewalVM, 'lobData.homeowners_EH.offerings.value[0].coverages.coverages', [])
        .find(({ codeIdentifier }) => includes(['EH_GoldCoverageBundle', 'EH_PlatinumCoverageBundle'], codeIdentifier)));

    const hasExtendedAttributeEditability = authUserData.permissions_Ext.includes('extendedattributeeditability_ext');

    const changeSubmission = useCallback(
        (value, changedPath) => {
            set(renewalVM, changedPath, value);
            e1pClausesUtil.setClauseValue(renewalVM, value, changedPath);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData]
    );

    const checkHasProtectiveDevice = (vm) => {
        if (get(vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value').length) {
            setHasProtectiveDevices(true);
        } else {
            setHasProtectiveDevices(false);
        }
    };

    const showProtectiveDevicePopup = useCallback(async () => {
         
        const componentProps = {
            iconClassType: false,
            showCloseBtn: true,
            showCancelBtn: true,
            submissionVM: renewalVM,
            viewModelService,
            translator,
            viewOnlyMode: false
        };
        const result = await modalApi.showModal(<ProtectiveDevices {...componentProps} />);

        return result;
    }, [renewalVM, translator, viewModelService, modalApi]);

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = get(renewalVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(renewalVM, 'lobData.homeowners_EH.paperlessInd.value');
        const paperlessEmail = get(renewalVM, 'lobData.homeowners_EH.paperlessEmail.value');

        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [renewalVM, isPaperlessEmailUpdated]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [renewalVM, isComponentValid, isPageSubmitted]);

    // In Renewal flow user will directly land on coverage page so making each step as visited
    // so that user can navigate to visited pages by clicking on tabs on chevron
    useEffect(() => {
        markFurthestStep(currentStepIndex); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps]);

    const onShowProtectiveDevicesPopup = useCallback(
        () => {
            try {
                showProtectiveDevicePopup().then((wrapperObj) => {
                    checkHasProtectiveDevice(wrapperObj.vm);

                    const responseFromPopup = get(
                        wrapperObj.vm,
                        'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value',
                        []
                    );

                    set(
                        renewalVM,
                        'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value',
                        responseFromPopup
                    );
                    set(
                        renewalVM,
                        'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd.value',
                        get(wrapperObj.vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd.value')
                    );
                    updateWizardData(renewalVM);
                     
                }).catch(() => {
                    checkHasProtectiveDevice(renewalVM);
                });
            } catch {
                 
                ConsoleHelper('user closed the modal', 'INFO');
            }
        }, [renewalVM, showProtectiveDevicePopup, updateWizardData]
    );

    const onProtectiveDevicesChange = (value) => {
         
        setHasProtectiveDevices(value);

        if (value) {
            onShowProtectiveDevicesPopup();
        } else {
            set(renewalVM, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value', []);
            set(
                renewalVM,
                'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd',
                undefined
            );
        }
    };

    const updateTPIOfTrust = (value, path) => {
        set(renewalVM, path, value);
        updateWizardData(renewalVM);
    }

    const writeValue = useCallback(
        (value, path) => {
            if (value !== undefined && path !== undefined) {
                set(renewalVM, path, value);
                updateWizardData(renewalVM);
            }
        },
        [renewalVM, updateWizardData]
    );

    const onUpdateCustomQuote = useCallback(
        (_basePath, lobPath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(renewalVM, lobPath, lobName, false);

            return RenewalService.updateCoverages(
                renewalVM.value.jobID,
                { homeowners_EH: customQuote.coverages },
                renewalVM.sessionUUID.value,
                authHeader
            ).then((updatedSubmission) => {
                const changedPath = lobPath.replace(/.children/, '');
                const updatedClauses = get(updatedSubmission, `${changedPath}.coverages`);
                const newRenewalVM = viewModelService.clone(renewalVM);

                set(renewalVM, `${lobPath}.coverages`, updatedClauses);

                const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                    renewalVM, newRenewalVM, `${lobPath}.coverages.coverages`
                );
                const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                    renewalVM, newRenewalVM, `${lobPath}.coverages.schedules`
                );
                const allRemovedFields = [
                    ...removedFieldsFromBaseCoverages, ...removedFieldsFromAdditionalCoverages
                ];

                setIsQuoteStale(true);
                disregardFieldValidation(allRemovedFields);
                updateWizardData(renewalVM);
                updateCoveragesModified(!coveragesModified);
                updateLoadingClause(undefined);
            });
        },
        [
            authHeader, disregardFieldValidation,
            renewalVM, updateWizardData,
            viewModelService, coveragesModified
        ]
    );

    const onClauseChange = useCallback((schedule, path) => {
        const lobOfferingPath = 'lobData.homeowners_EH.offerings.children[0]';

        writeValue(schedule, path);

        return onUpdateCustomQuote({}, lobOfferingPath);
    }, [onUpdateCustomQuote, writeValue]);


    const syncClauses = useCallback(async () => {
        updateLoadingClause(true);
        await onClauseChange();
        updateWizardData(renewalVM);
    }, [onClauseChange, renewalVM, updateWizardData]);

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncClauses(value, changedPath);
        },
        [changeSubmission, syncClauses]
    );

    const onScheduleChange = useCallback(
        (schedule, path) => {
            const lobOfferingPath = 'lobData.homeowners_EH.offerings.children[0]';

            writeValue(schedule, `${path}.value`);

            return onUpdateCustomQuote({}, lobOfferingPath);
        },
        [onUpdateCustomQuote, writeValue]
    );

    const optionalCoveragesModified = useCallback(() => {
        updateCoveragesModified(!coveragesModified);
        setIsQuoteStale(true);
    }, [coveragesModified]);

    const updatePaperlessIndAndEmail = (value, path) => {
        set(renewalVM, path, value);

        switch (path) {
            case 'lobData.homeowners_EH.paperlessInd':
                if (value) {
                    const pniEmail = get(renewalVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value');

                    if (get(renewalVM, 'lobData.homeowners_EH.paperlessEmail.value') === undefined) {
                        set(renewalVM, 'lobData.homeowners_EH.paperlessEmail.value', pniEmail);
                    }
                } else {
                    set(renewalVM, 'lobData.homeowners_EH.paperlessEmail.value', undefined);
                }

                break;
            default:
                break;
        }

        setIsPaperlessEmailUpdated(true);
        updateWizardData(renewalVM);
    };

    const saveAndQuote = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (!get(renewalVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value')
                && !!get(renewalVM, 'lobData.homeowners_EH.paperlessEmail.value')) {
                set(renewalVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value',
                    get(renewalVM, 'lobData.homeowners_EH.paperlessEmail.value'));
            }

            const quoteRenewal = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteRenewal);
            // await PolicyChangeUtil.updatePolicyChangeVMCustomFields(renewalVM);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);

            // Tushar is working on apply changes action button, leaving commented for him
            // const validationErrors = get(
            //     renewalVM,
            //     'errorsAndWarnings.validationIssues.fieldIssues', []
            // );
            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page

            // Someone has a jira to do the third apply changes button
            // if (false) {
            //     let newLandingPageIndex = -1;
            //     newLandingPageIndex = getLandingPageIndexForQuotedJob(
            //         LOB,
            //         steps
            //     );
            //     if (newLandingPageIndex >= 0) {
            //         jumpTo(newLandingPageIndex, true);
            //     }
            //     return false;
            // }
            return renewalVM;
        },
        [authHeader, isComponentValid, renewalVM, updateWizardData]
    );

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            const shouldContinue = await saveAndQuote();

            if (!shouldContinue) { return false; }

            return renewalVM;
        },
        [isComponentValid, saveAndQuote, renewalVM]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    const showWhatsIncludedPopup = async () => {
        const offerings = get(renewalVM, 'lobData.homeowners_EH.offerings.children[0]');

        const platinumOrGoldClauseIndex = offerings.value.coverages.coverages.findIndex(
            (clause) => clause.codeIdentifier === 'EH_PlatinumCoverageBundle' || clause.codeIdentifier === 'EH_GoldCoverageBundle'
        );

        if (platinumOrGoldClauseIndex === -1) {
            const componentProps = {
                coverages: offerings.coverages.coverages.value
            };
            const result = await modalApi.showModal(
                <PackageQualifyingEndorsementsComponent {...componentProps} />
            );

            return result;
        }

        const componentProps = {
            lob: 'Homeowners_EH'
        };
        // IAP-83: Gold and Platinum will continue to use existing component
        const result = await modalApi.showModal(
            <PackageDifferenceComponent {...componentProps} />
        );

        return result;
    };

    const recalculate = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (!get(renewalVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value')
                && !!get(renewalVM, 'lobData.homeowners_EH.paperlessEmail.value')) {
                set(renewalVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value',
                    get(renewalVM, 'lobData.homeowners_EH.paperlessEmail.value'));
            }

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            setIsQuoteStale(false);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            setIsSavingRenewal(false);

            return false;
        },
        [authHeader, isComponentValid, renewalVM, updateWizardData, updateWizardSnapshot]
    );

    const getTotalPremium = useCallback(() => {
        const payPlans = get(renewalVM.value, 'lobData.homeowners_EH.offerings[0].paymentPlans');
        const selectedPlan = payPlans.filter((plan) => plan.isSelected);
        const totalPremium = selectedPlan[0]?.total?.amount;

        if ((!totalPremium && totalPremium !== 0) || isQuoteStale) {
            return undefined;
        }

        return { currency: 'usd', amount: totalPremium };
    }, [isQuoteStale, renewalVM]);

    const policyType = get(renewalVM, 'value.lobData.homeowners_EH.policyType');

    const showOptionalCoveragePopOver = async () => {
        const offerings = get(renewalVM, 'lobData.homeowners_EH.offerings.children[0]');
        const goldBundledCov = offerings.value.coverages.coverages.find((clause) => clause.codeIdentifier === 'EH_GoldCoverageBundle');
        const platinumBundledCov = offerings.value.coverages.coverages.find((clause) => clause.codeIdentifier === 'EH_PlatinumCoverageBundle');
        const componentProps = {
            title: translator(messages.optionalCoverageLabel),
            iconClassType: false,
            showCancelBtn: false,
            showSaveBtn: true,
            // possible refactor later - update prop name
            //   by exporting reusable components we only need to make changes in 1 place
            policyChangeVM: renewalVM,
            updateWizardData,
            viewModelService,
            authHeader,
            optionalCoveragesModified,
            goldCovSelected: goldBundledCov ? goldBundledCov.selected : false,
            platinumCovSelected: platinumBundledCov ? platinumBundledCov.selected : false,
            translator,
            hasGoldOrPlatinumBoxVisible,
            policyState
        };
        const result = await modalApi.showModal(<OptionalCoveragesPopup {...componentProps} />);

        return result;
    };


    // Optional coverage subtotal amount
    const scheduleList = get(renewalVM, 'lobData.homeowners_EH.offerings.value[0].coverages.schedules', []);
    let optionalCoverageSubtotalAmount = get(get(renewalVM, 'lobData.homeowners_EH.offerings.value[0].premiumSummary', [])
        .find((prem) => prem.premiumSummaryType === 'EHOptionalCvgSubtotal'), 'amount', 0);

    // Add schedule coverage total amount to optional coverage total
    optionalCoverageSubtotalAmount += get(scheduleList.find((schedule) => get(schedule, 'premiumSummary.premiumSummaryType') === 'TotalScheduleCoverage'), 'premiumSummary.amount', 0);

    const discountSubtotal = get(renewalVM, 'lobData.homeowners_EH.offerings.value[0].premiumSummary', [])
        .find((prem) => prem.premiumSummaryType === 'EHDiscountSavingsSubtotal')?.amount || 0;

    // for content box in quoteheader that is in the second column
    const primaryContentData = () => {
        if (!hasGoldOrPlatinumBoxVisible) {
            return translator(messages.optionalCoveragePackage);
        }

        return translator(messages.goldPlatinumPrimaryLabel);
    };
    const secondaryContentData = () => {
        if (!hasGoldOrPlatinumBoxVisible) {
            return (
                <React.Fragment>
                    {translator(messages.discountMessageSelect)}
                    <span className={styles.addIcon} />
                    {translator(messages.discountMessageLabel)}
                </React.Fragment>
            );
        }

        return translator(messages.goldPlatinumSecondaryLabel);
    };

    const getSelectedOptionalCov = () => {
        const newSubVM = viewModelService.clone(renewalVM);
        const tempSelectedOptionalCoverages = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).coverages.coverages.filter((coverage) => OPTIONAL_COVERAGE_CATEGORIES
                .indexOf(coverage.coverageCategoryCode.value) >= 0 && coverage.value.selected);
        const selectedExclusions = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).exclusions.filter((exclusion) => exclusion.selected.value === true);
        const selectedConditions = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).conditions.filter((condition) => condition.selected.value === true);

        // For state CT - check if special loss settlement is selected - add it to optional coverage
        if (policyState === 'CT') {
            const dwellingCovA = find(get(newSubVM.lobData.homeowners_EH.offerings, ['value', 0, 'coverages', 'coverages'], []), {codeIdentifier: 'EH_DwellingCovA'});
            const isSpecialLossSettlementSelected = !!find(get(dwellingCovA, ['terms'], []), (term) => term.chosenTerm && term.type === 'OptionEH_DwellingCovA_SpecialLossSettlementType');

            if (isSpecialLossSettlementSelected) {
                tempSelectedOptionalCoverages.push({
                    amount: undefined,
                    name: translator(ehCommonMessages.specialLossSettlement)
                });
            }
        }

        const selectedOptionalCoveragesExclusionsAndConditions = [
            ...tempSelectedOptionalCoverages,
            ...selectedExclusions,
            ...selectedConditions
        ];

        return selectedOptionalCoveragesExclusionsAndConditions;
    };

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges, [isSavingCurrentPageChanges, isSavingRenewal, isSkipping]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showErrors: isPageSubmitted,
            showRequired: true,
            autoComplete: false
        },
        premiumMightChangeMessageDiv: {
            visible: renewalVM.baseData.periodStatus.value.code !== 'Bound'
        },
        coveragesPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        coveragesPageContainer: {
            visible: isPageLoaded
        },
        policyTermComponent: {
            transactionVM: renewalVM,
            authHeader,
            updateWizardData
        },
        paymentOptionsID: {
            submissionVM: renewalVM,
            authHeader,
            updateWizardData,
            LOB: 'homeowners_EH',
            isQuoteStale
        },
        monthlyPaymentScheduleComponent: {
            quoteID: get(renewalVM, 'jobID.value'),
            authHeader,
            transactionTotalAmount: getTotalPremium(),
            changeInCost: renewalVM.transactionCost?.value,
            startDate: renewalVM.baseData.periodStartDate.value,
            endDate: renewalVM.baseData.periodEndDate.value,
            jobTypeCode: renewalVM.baseData.jobType.value.code,
            offerings: get(renewalVM, 'lobData.homeowners_EH.offerings.value')
        },
        paperlessInd: {
            required: true,
            value: get(renewalVM, 'lobData.homeowners_EH.paperlessInd.value'),
            onValueChange: updatePaperlessIndAndEmail,
            labelPosition: 'top',
        },
        isThisNewHomePurchase: {
            labelPosition: 'top',
            visible: (policyType === 'HO3' || policyType === 'HF9') && policyState !== 'MT',
            required: true,
            disabled: true
        },
        buyNowButton: {
            visible: !isQuoteStale,
            onClick: async () => {
                await onNext();

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    e1pGoNext();
                }
            }
        },
        recalculateButton: {
            visible: isQuoteStale,
            onClick: () => recalculate()
        },
        isProtectiveDevicesIcon: {
            onClick: onShowProtectiveDevicesPopup,
            visible: hasProtectiveDevices,
            icon: 'mi-edit'
        },
        isProtectiveDevices: {
            value: hasProtectiveDevices,
            onValueChange: onProtectiveDevicesChange,
            // disabled: viewOnlyMode,
            visible: policyType !== 'HO4',
            required: true,
            labelPosition: 'top'
        },
        isProtectiveDevicesMainGrid: {
            visible: policyType !== 'HO4'
        },
        paperlessEmailId: {
            visible: paperLessIndValue,
            required: paperLessIndValue,
            onValueChange: updatePaperlessIndAndEmail,
            labelPosition: 'top',
        },
        tpiOfTrust: {
            onValueChange: updateTPIOfTrust,
            visible: policyType === 'HO3' && policyState === 'NC',
            labelPosition: 'top'
        },
        wizardPageHeader: { wizardSubmission: renewalVM },
        sectionICoverages: {
            loadingClause,
            uiGroup: 'Section I',
            hideLabel: true,
            labelPosition: 'top',
            policyState,
            isQuoteStale
        },
        sectionIICoverages: {
            loadingClause,
            uiGroup: 'Section II',
            hideLabel: true,
            labelPosition: 'top',
            isQuoteStale
        },
        deductibles: {
            loadingClause,
            uiGroup: 'Deductibles',
            labelPosition: 'top',
            hasExtendedAttributeEditability,
            isQuoteStale
        },
        scheduleItemContainer: {
            loadingClause,
            categoryDisplayName: translator(messages.schedules)
        },
        addOrEditOptionalCovButton: {
            onClick: () => showOptionalCoveragePopOver()
        },
        seeOptionalCovButton: {
            onClick: () => showOptionalCoveragePopOver()
        },
        supportingPolicyComponentContainer: {
            transactionVM: renewalVM,
            LOB: 'homeowners_EH',
            updateWizardData,
            viewOnly: false
        },
        GoldPlatinumGrid: {
            visible: true,
            className: (policyType === 'HO3' || policyType === 'HF9') ? 'goldPlatinumBox' : 'optionalCovBox'
        },
        // for the below: we keep the box with opt cov button in it for non ho3
        goldPlatinumPrimaryHeading: {
            visible: policyType === 'HO3' || policyType === 'HF9',
            content: primaryContentData()
        },
        goldPlatinumSecondaryHeading: {
            visible: policyType === 'HO3' || policyType === 'HF9',
            content: secondaryContentData()
        },
        goldPlatinumLinkGridId: {
            visible: policyType === 'HO3' || policyType === 'HF9',
        },
        quoteProposalLinkContainer: {
            onClick: (e) => {
                e.preventDefault();
                setIsFetchingQuoteProposal(true);
                QuoteProposalService.retriveQuoteProposalURL(
                    get(renewalVM, 'jobID.value'),
                    authHeader
                ).then((response) => {
                    window.open(response.quoteProposalURL, '_blank');
                    setIsFetchingQuoteProposal(false);
                }).catch(() => {
                    setIsFetchingQuoteProposal(false);
                });
            },
            disabled: renewalVM.baseData.periodStatus === 'Quoted'
                || isFetchingQuoteProposal
        },
        protectiveDeviceRequireInfoMessageBasedOnCovADiv: {
            visible: PropertyFlowUtil.isProtectiveDeviceRequiredMessageVisible(renewalVM),
            message: PropertyFlowUtil.contentBasedOnState(renewalVM, translator)
        },
        optionalCoverageSubtotalValue: {
            value: { currency: 'usd', amount: optionalCoverageSubtotalAmount }
        },
        ehSurchargeListComponentId: {
            value: modifiers.filter(
                (item) => item.applied && item.modifierType !== "discount"
            )
        },
        ehDiscountsListComponentId: {
            visible: !!filter(modifiers, { applied: true, modifierType: 'discount' }).length,
            value: modifiers,
            discountsTotal: discountSubtotal
        },
        ncrbDiscountAvailabilityMessageDiv: {
            visible: policyState === 'NC'
        },
        windstormMitigationDiscountComponentContainer: {
            visible: (policyType === 'HO3' && includes(['NC', 'SC', 'CT'], policyState) || (policyType === 'HF9' && policyState === 'CT'))
        },
        windstormMitigationDiscountComponent: {
            transactionVM: renewalVM,
            viewOnly: false,
            updateWizardData,
            setFieldsChangedOnCoveragePage: () => { },
            policyState
        },
        windstormMitigationDiscountInfoMessageDiv: {
            visible: PropertyFlowUtil.isWindstormMitigationDiscountInfoMessagePresent(renewalVM)
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        selectedOptionalCoverageDiv: {
            visible: getSelectedOptionalCov().length > 0
        },
        selectedOptionalCoverageLabel: {
            content: getSelectedOptionalCov().length > 0 ? translator(ehCommonMessages.selectedOptionalCovLabel) : translator(ehCommonMessages.emptyOptionalCovLabel)
        },
        totalPremiumID: {
            value: (() => getTotalPremium())()
        }
    };


    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onScheduleChange,
            onValidate,
            whatsIncludedHandler: showWhatsIncludedPopup

        },
    };
    const isPageLoading = useCallback(() => isUndefined(loadingClause), [loadingClause]);

    useEffect(() => {
        checkHasProtectiveDevice(renewalVM);

        // set isQuoteStale, when status is draft
        if (get(renewalVM, 'value.baseData.periodStatus') === 'Draft') {
            setIsQuoteStale(true);
        }
    }, [renewalVM]);


    useEffect(() => {
        registerComponentValidation(isPageLoading);
    }, [isPageLoading, registerComponentValidation]);

    useEffect(() => {
        updateCoveragesModified(false);

        const optionalSelectedCoverages = getSelectedOptionalCov();

        updateSelectedOptionalCovs({ selectedOptionalCoverages: optionalSelectedCoverages });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coveragesModified]);

    const reviewChanges = useCallback(
        async () => {
            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setIsSavingRenewal(false);

            return false;
        },
        [renewalVM, authHeader, updateWizardData, jumpTo, changeSummaryIndex]
    );

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            showNext={!isQuoteStale}
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
            disableCustom={!isComponentValid}
            showCustom={!isRenewalInWorkflow && isQuoteStale}
            customLabel={wizardMessages.recalculateButtonLabel}
            onCustom={recalculate}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            showCustom1
            onCustom1={reviewChanges}
            custom1Label={wizardMessages.reviewChangesLabel}
            onSave={onSave}
            showOnSave
        >

            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{ ...selectedOptionalCoverages, ...renewalVM }}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

CoveragesPage.propTypes = wizardProps;

export default withAuthenticationContext(CoveragesPage);
