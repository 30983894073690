import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class PolicyDiffService {
    static getPolicyDiffWithPrevious(data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policydiff'),
            'getPolicyDiffWithPrevious',
            data,
            additionalHeaders
        );
    }

    /**
     * Returns the policy diff tree for given jobs
     *
     * @param {string} jobNumber1 job number to compare
     * @param {string} jobNumber2 job number to compare
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call with the policy diff tree for given jobs
     * @memberof PolicyDiffService
     */
    static compareRecords(jobNumber1, jobNumber2, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policydiff'),
            'compareJobs',
            [jobNumber1, jobNumber2],
            additionalHeaders
        );
    }
}
