import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    cloneDeep as _cloneDeep,
    get as _get,
    pullAt as _pullAt,
    set as _set,
    find as _find,
    filter as _filter,
    isEmpty as _isEmpty
} from 'lodash';
import {
    ModalBody, ModalFooter, ModalHeader, ModalNext, useModal
} from '@jutro/components';
import { ServiceManager } from '@jutro/legacy/services';
import { TPIUtil, PersonNameValidator } from 'e1p-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { commonMessages as e1pCommonMessages, ehValidationAndInfoMessages } from 'e1p-platform-translations';
import { useAddressStandardization, e1pUSStatesUtil } from 'e1p-capability-hooks';
import { E1PContactsFoundComponent } from "e1p-capability-policyjob-react";
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import metadata from './E1PEHTPISearchDetailComponent.metadata.json5';

/**
 * This component file models the EH TPI input panel on the lower section of the "TPI Search" popup.
 *
 * @param {Object} props An object containing the properties passed into this component.
 * @returns {Object} An object containing the data required for rendering this component.
 */
const E1PEHTPISearchDetailComponent = (props) => {
    const modalApi = useModal();
    const {
        id,
        tpiDetailVM,
        viewModelService,
        isControlledTPI,
        isTPIContactTypeCompany,
        isTPITypeMortgagee,
        tpiBasePath,
        isTPITypeTrust,
        isAddingNewTPI,
        authHeader,
        showPopup,
        transactionVM,
        updateIsSearchTPIVisible,
        saveThirdPartyInterestClickHandler,
        onResolve,
        onReject,
        isOpen,
        isEditingTPI,
        showErrors,
        onValidate,
        disregardFieldValidationParentPage,
        setIsAddingTPI,
        isTPIFromSearchResult
        // preferredTPIContactVM
    } = props;
    const [tempTPIDetailVM, setTempTPIDetailVM] = useState(tpiDetailVM);
    const [trusteeDetailVM, setTrusteeDetailVM] = useState(undefined);
    const [isAddingNewTrustee, setIsAddingNewTrustee] = useState(false);
    const [isEditingTrustee, setIsEditingTrustee] = useState(false);
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [isSearchingContacts, setIsSearchingContacts] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [addressStandardizationResultDTO, updateAddressStandardizationResultDTO] = useState(
        undefined
    );
    const [enteredStateForAddressStandardization, updateEnteredState] = useState(undefined);
    const [oldTPIAddress, setOldTPIAddress] = useState({});
    const [errorWarningMessage, updateErrorWarningMessage] = useState('');
    const [showErrorWarning, updateShowErrorWarning] = useState(false);
    const { getStandardizedAddress, standardizeAddressIfApplicable } = useAddressStandardization(
        viewModelService,
        authHeader
    );
    const {
        onValidate: setComponentValidation,
        isComponentValid,
        disregardFieldValidation
    } = useValidation(id);
    const localeService = ServiceManager.getService('locale-service');
    const translator = useTranslator();
    const MAXIMUM_NUMBER_OF_TRUSTEES = 3;
    const [isTPIasPayerSelectedOnPolicy, updateTPIasPayerSelectedOnPolicy] = useState(false);
    const isEscrowSelected = tempTPIDetailVM?.escrowInd?.value || false;
    const [isBillMortgageeSelectedOnPolicy, updateBillMortgageeSelectedOnPolicy] = useState(false);

    const ADDL_INTEREST_TYPE_TL = viewModelService.productMetadata
        .get('pc')
        .types.getTypelist('AdditionalInterestType');
    // state in Address DTO for TPI is String, but we need to show states as dropdown
    const [USStates, setUSStates] = useState([]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);

    useEffect(() => {
        const productCode = _get(transactionVM, 'baseData.productCode.value');
        const tpiPath = TPIUtil.getTPIBasePath(productCode);

        const allTPIs = _get(transactionVM, `${tpiPath}.value`, []);

        const isBillMortgageeSelected = !!_find(allTPIs, { sendBillToTPIInd: true });
        const billMortgageeOnCurrentTpi = _get(tempTPIDetailVM, 'sendBillToTPIInd.value');

        if (billMortgageeOnCurrentTpi && isBillMortgageeSelected) {
            updateBillMortgageeSelectedOnPolicy(false);
        } else {
            updateBillMortgageeSelectedOnPolicy(isBillMortgageeSelected);
        }

        const isPayerSelected = !!_find(allTPIs, { convertTPIToPayerInd: true });
        const payerOnCurrentTpi = _get(tempTPIDetailVM, 'convertTPIToPayerInd.value');

        if (payerOnCurrentTpi && isPayerSelected) {
            updateTPIasPayerSelectedOnPolicy(false);
        } else {
            updateTPIasPayerSelectedOnPolicy(isPayerSelected);
        }

        const USStatesTypekey = e1pUSStatesUtil.getUSStates(viewModelService);
        const states = e1pUSStatesUtil.getStateValues(USStatesTypekey, translator);

        setUSStates(states);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateAndShowWarning = useCallback((textToDisplay) => {
        // if text to display is empty then hide error
        updateShowErrorWarning(!_isEmpty(textToDisplay));
        updateErrorWarningMessage(textToDisplay);
    }, []);

        /**
     * Helper function for creating a new trustee details "VMNode" object.
     *
     * @returns {Object} A "VMNode" object containing the default trustee details.
     */
        const createNewTrusteeDetailVM = useCallback((existingTrustee) => {
            let templateTrusteeDetailsObject;
    
            if (existingTrustee) {
                templateTrusteeDetailsObject = existingTrustee;
            }
            else {
                templateTrusteeDetailsObject = {
                    address: {
                        country: localeService.getDefaultCountryCode()
                    }
                }
            }

            const newTrusteeDetailVM = viewModelService.create(
                templateTrusteeDetailsObject,
                'pc',
                'amfam.edge.capabilities.policyjob.lob.eh.coverables.dto.EHTrusteeDTO'
            );

            return newTrusteeDetailVM;
        }, [localeService, viewModelService]);
    
    /**
     * Helper effect for initializing the trustee address display names.
     */
    useEffect(() => {
        const allTrustees = _get(tpiDetailVM, 'trustees.value', []);

        allTrustees.forEach((trustee) => {
            // we dont have postalcode in trustee DTO, hence populating zipcode in postal code for displayname
            _set(trustee, 'address.postalCode', trustee.address?.zipCode);

            const trusteeAddressDisplayName = TPIUtil.getFormattedTPIAddress(trustee.address);

            _set(trustee, 'address.displayName', trusteeAddressDisplayName);
        });
        setTempTPIDetailVM(tpiDetailVM);

        // IAP-5352, add trusteedetail obj if new trust is being added
        if (isAddingNewTPI && isTPITypeTrust && allTrustees.length === 0) {
            setTrusteeDetailVM(createNewTrusteeDetailVM());
            setIsAddingNewTrustee(true);
        }
    }, [createNewTrusteeDetailVM, isAddingNewTPI, isTPITypeTrust, tpiDetailVM]);

    /**
     * Helper callback for writing the "bill to TPI" values to the view model.
     */
    const writeBillToTPIValues = useCallback((value, nextFormData) => {
        // Need to update "Billing Assigned Date" when "Send Bill to TPI" is set to "yes"
        const billDatePath = 'billingAssignedDate';

        if (value) {
            const today = new Date();

            _set(nextFormData, billDatePath, today);
        } else {
            _set(nextFormData, billDatePath, undefined);
            _set(nextFormData, 'assignAtRenewalInd.value', undefined);
        }

        return nextFormData;
    }, []);

    /**
     * Helper callback for writing values to the view model.
     */
    const writeValue = useCallback(
        (value, path) => {
            /**
             * We are updating standardized address on saveTPICall 
             * And as state update is async, updating tpiDetailsVM object value
             */
            _set(tempTPIDetailVM, path, value);

            let nextFormData = viewModelService.clone(tempTPIDetailVM);

            _set(nextFormData, path, value);

            if (path === 'sendBillToTPIInd') {
                nextFormData = writeBillToTPIValues(value, nextFormData);
            }

            setTempTPIDetailVM(nextFormData);
        },
        [tempTPIDetailVM, viewModelService, writeBillToTPIValues]
    );


    const onSelectAddress = useCallback(
        (addressToUse) => {
            const addressVM = tempTPIDetailVM.address;

            addressVM.value = {
                ...addressVM.value,
                address1: _get(addressToUse, 'addressLine1', _get(addressToUse, 'address1')),
                address2: _get(addressToUse, 'addressLine2', _get(addressToUse, 'address2')),
                city: addressToUse.city,
                postalCode: addressToUse.postalCode,
                state: addressToUse.state,
                country: 'US'
            };
            writeValue(addressVM.value, 'address.value');
            updateEnteredState(undefined);
            updateAddressStandardizationResultDTO(undefined);
        },
        [tempTPIDetailVM, writeValue]
    );

    const handleAddressBlur = useCallback(
        async (_, { value, beforeValue }) => {
            if (value === beforeValue) {
                return;
            }

            const addressVM = _get(tempTPIDetailVM, 'address');

            // set addressline1 and addressLine2, since the addressStandardization needs it
            // and we dont have addressline1 and addressLine2 in addressDTO, we have address1 and address2
            _set(addressVM, 'addressLine1', tempTPIDetailVM.address.address1);
            _set(addressVM, 'addressLine2', tempTPIDetailVM.address.address2);
            updateAddressStandardizationResultDTO(undefined);

            if (
                _get(addressVM, 'addressLine1.value')
                && _get(addressVM, 'city.value')
                && _get(addressVM, 'state.value')
                && _get(addressVM, 'postalCode.value')
            ) {
                setIsStandardizingAddress(true);

                const standardizedAddressResult = await getStandardizedAddress(addressVM, false);

                if (!standardizedAddressResult?.standardizationChoices) {
                    setIsStandardizingAddress(false);

                    return;
                }

                const allExactMatchAddresses = standardizedAddressResult.standardizationChoices
                    .filter((choice) => choice.isExactMatch);

                // if more than one matches or no matches then show the popup
                if (allExactMatchAddresses?.length === 1) {
                    const addressToUse = {
                        addressLine1: allExactMatchAddresses[0].addressLine1,
                        addressLine2: allExactMatchAddresses[0].addressLine2 ? allExactMatchAddresses[0].addressLine2 : '',
                        city: allExactMatchAddresses[0].city,
                        postalCode: allExactMatchAddresses[0].postalCode,
                        state: allExactMatchAddresses[0].state,
                        country: 'US'
                    };

                    onSelectAddress(addressToUse);
                    updateAddressStandardizationResultDTO(undefined);
                } else {
                    const address = addressVM.value;
                    const enteredAddress = { ...address };

                    _set(enteredAddress, 'tempID', address.state);
                    _set(
                        enteredAddress,
                        'formattedDisplayName',
                        `${address.address1}, ${address.address2}, ${address.city}, ${address.state}, ${address.postalCode}`
                    );
                    updateEnteredState(enteredAddress);
                    updateAddressStandardizationResultDTO(standardizedAddressResult);
                }

                setIsStandardizingAddress(false);
            }
        },
        [tempTPIDetailVM, getStandardizedAddress, onSelectAddress]
    );

    const { formattedBankName, formattedAddress } = useMemo(() => ({
            formattedBankName: TPIUtil.getFormattedTPIBankName(_get(tempTPIDetailVM, 'value')),
            formattedAddress: TPIUtil.getFormattedTPIAddress(_get(tempTPIDetailVM, 'value'))
        }), [tempTPIDetailVM]);

    const showContactsModal = useCallback(async (contactRecords) => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            contactRecords
        };

        return modalApi.showModal(<E1PContactsFoundComponent {...componentProps} />);
    }, [modalApi]);

    const onAddressChange = useCallback((value, path) => {
        let actualPath = path;
        const actualValue = value;

        if (path === 'address.addressLine1') {
            actualPath = 'address.address1'
        } else if (path === 'address.addressLine2') {
            actualPath = 'address.address2'
        }
        else if (path === 'address.value') {
            if (value.addressLine1) {
                _set(actualValue, 'address1', value.addressLine1);
            }

            if (value.addressLine2) {
                _set(actualValue, 'address2', value.addressLine2);
            }
        }

        writeValue(actualValue, actualPath)
    }, [writeValue]);

    const saveThirdPartyInterest = useCallback(async () => {
        if (!tempTPIDetailVM) {
            updateAndShowWarning(e1pCommonMessages.selectAThirdPartyError);

            return false;
        }

        if (!isComponentValid) {
            updateIsPageSubmitted(true);
        
            return false;
        }

        if (isTPITypeTrust && _get(tempTPIDetailVM, 'trustees.value', []).length === 0 || isAddingNewTrustee || isEditingTrustee) {
            // IAP-5352, show error if trustee detail is not available
            updateAndShowWarning(ehValidationAndInfoMessages.missingTrusteeDetailsTPI);
            window.scrollTo(0, 0);

            return false;
        }

        // clear the error
        updateAndShowWarning();

        // Commenting all preferred code as we don't have test data
        // const preferredTPIBankName = _get(preferredTPIContactVM, 'value.bankPrimaryName')?.toUpperCase();
        // const selectedTPIDetailsBankName = _get(tempTPIDetailVM, 'value.bankPrimaryName')?.toUpperCase();
        // if (isTPITypeMortgagee && _get(preferredTPIContactVM, 'value') && preferredTPIBankName === selectedTPIDetailsBankName) {
        //     try {
        //         await showPreferredContactExistModal(_get(preferredTPIContactVM, 'value'));

        //         // as user has clicked on submit replace tpi with preferred tpi
        //         TPIUtil.mapTPIDataToPreferredTPI(_get(tempTPIDetailVM, 'value'), _get(preferredTPIContactVM, 'value'));
        //         _set(tempTPIDetailVM, 'value', _get(preferredTPIContactVM, 'value'))
        //     } catch{
        //         // user clicked on cancel 
        //         return false
        //     }
        // }

        /**
         * if user adds new uncontrolled tpi or choose existing uncontrolled tpi 
         * we will standardize the address before saving tpi
         */
        if ((isAddingNewTPI || !isControlledTPI) && !isEditingTPI) {
            setIsStandardizingAddress(true);

            const isAddressStandardized = await standardizeAddressIfApplicable(
                oldTPIAddress,
                tempTPIDetailVM.address,
                onAddressChange,
                'address.value'
            )

            setIsStandardizingAddress(false);

            if (!isAddressStandardized) {
                // address is not standardized
                return false;
            }

            setOldTPIAddress(tempTPIDetailVM.address.value);
        }

        if (isAddingNewTPI) {
            // need to populate address from addressDTO to PrimaryAddress
            // since we cannot create new contact without address in backend
            const address = tempTPIDetailVM.address.value;

            address.addressLine1 = address.address1;
            address.addressLine2 = address.address2;

            if (isTPIContactTypeCompany) {
                _set(tempTPIDetailVM, 'value.company.primaryAddress', address);
            } else {
                _set(tempTPIDetailVM, 'value.person.primaryAddress', address);
            }

            setIsSearchingContacts(true);

            let contacts = [];

            try {
                contacts = await TPIUtil.searchContacts(
                    _get(tempTPIDetailVM, 'value'),
                    _get(transactionVM, 'value.baseData.accountHolder.accountNumber'),
                    authHeader
                );
            } catch (error) {
                // some error occurred while searching contacts
                return false;
            } finally {
                setIsSearchingContacts(false);
            }

            try {
                if (!_isEmpty(contacts)) {
                    const { chosenContact } = await showContactsModal(contacts);

                    // if subtype is present it means user has selected an existing contact
                    // if its not present user has clicked on create new contact
                    if (chosenContact.contactSubtype) {
                        TPIUtil.mapChosenContactToTPIContact(_get(tempTPIDetailVM, 'value'), chosenContact);
                    }
                }
            } catch {
                // user clicked on cancel and did not choose any contact
                return false
            }
        }

        disregardFieldValidation(id);
        disregardFieldValidationParentPage(id);

        if (showPopup) {
            return onResolve(tempTPIDetailVM.value);
        }

        return saveThirdPartyInterestClickHandler(tempTPIDetailVM.value);
    }, [
        tempTPIDetailVM, isComponentValid, isAddingNewTPI, isControlledTPI,
        disregardFieldValidation, id, disregardFieldValidationParentPage,
        showPopup, saveThirdPartyInterestClickHandler, updateAndShowWarning,
        isTPIContactTypeCompany, standardizeAddressIfApplicable, onAddressChange,
        transactionVM, authHeader, showContactsModal, onResolve, oldTPIAddress,
        isEditingTPI, isTPITypeTrust, isAddingNewTrustee, isEditingTrustee
    ]
    );

    /**
     * Helper callback for handling "Add Trustee" button clicks.
     */
    const addTrusteeButtonOnClickHandler = useCallback(
        (event) => {
            event.preventDefault();

            const newTrusteeDetailVM = createNewTrusteeDetailVM();

            setTrusteeDetailVM(newTrusteeDetailVM);
            setIsAddingNewTrustee(true);
        },
        [createNewTrusteeDetailVM]
    );

    /**
     * Helper callback for handling "Cancel" button clicks on the trustee details panel.
     */
    const cancelTrusteeButtonOnClickHandler = useCallback((event) => {
        event.preventDefault();
        setTrusteeDetailVM(undefined);
        setIsAddingNewTrustee(false);
        setIsEditingTrustee(false);
    }, []);

    /**
     * Helper callback for retrieving the "AdditionalInterestType" typelist values for EH.
     */
    const getAddlInterestTypeCodesForEH = useCallback(() => {
        const addlInterestTypeCodes = [];

        addlInterestTypeCodes.push(
            _find(ADDL_INTEREST_TYPE_TL.codes, {
                code: 'AdditionalInterest_Ext'
            })
        );

        const policyTypeCode = _get(transactionVM, 'lobData.homeowners_EH.policyType.value.code')
            ?? _get(transactionVM, 'policyType_Ext.value.code'); // For policy change transactions

        if (policyTypeCode === 'HO3' || policyTypeCode === 'HO6' || policyTypeCode === 'HF9') {
            addlInterestTypeCodes.push(
                _find(ADDL_INTEREST_TYPE_TL.codes, {
                    code: 'AdditionalInsured_Ext'
                })
            );

            const filteredCodes = _find(ADDL_INTEREST_TYPE_TL.filters, {
                name: 'EHControlledTPIFilter_Ext'
            }).codes;

            filteredCodes.forEach((typeCode) => {
                addlInterestTypeCodes.push(
                    _find(ADDL_INTEREST_TYPE_TL.codes, {
                        code: typeCode.code
                    })
                );
            });

            // Homeowner and secondary/seasonal can add trust
            if (['HO3', 'HF9'].includes(policyTypeCode)) {
                addlInterestTypeCodes.push(
                    _find(ADDL_INTEREST_TYPE_TL.codes, {
                        code: 'TRUST_Ext'
                    })
                );
            }
        }

        const publicID = _get(tpiDetailVM, 'value.person')
            ? _get(tpiDetailVM, 'value.person.publicID')
            : _get(tpiDetailVM, 'value.company.publicID');

        const filterredCodes = TPIUtil.filterInterestTypesForGivenUser(
            addlInterestTypeCodes,
            transactionVM,
            tpiBasePath,
            publicID
        );

        // update addlInterestTypeCodes with interestCodes which are present for given user
        addlInterestTypeCodes.splice(0, addlInterestTypeCodes.length);
        addlInterestTypeCodes.push(...filterredCodes);

        const availableValues = addlInterestTypeCodes.map((typeCode) => ({
                code: typeCode.code,
                name: translator({ id: typeCode.name })
            }));

        return availableValues;
    }, [ADDL_INTEREST_TYPE_TL.codes, ADDL_INTEREST_TYPE_TL.filters, tpiBasePath, tpiDetailVM, transactionVM, translator]);

    /**
     * Helper callback for filtering the "AdditionalInterestType" typelist values based on whether
     * they're already in use on the policy.
     */
    const filterAddlInterestTypeCodes = useCallback(
        (initialAddlInterestTypeCodes) => {
            const allExistingTPIsOnPolicy = _get(transactionVM, `${tpiBasePath}.value`, []);
            const alreadyUsedAddlInterestTypeCodes = allExistingTPIsOnPolicy.map((item) => item.addlInterestType);
            const filteredAddlInterestTypeCodes = _filter(
                initialAddlInterestTypeCodes,
                (typeCode) => {
                    const isTypeCodeAvailable = alreadyUsedAddlInterestTypeCodes.indexOf(typeCode.code) === -1;
                    const canTypeCodeHaveMultipleOccurrences = !typeCode.code.includes('MORTGAGEE');

                    return isTypeCodeAvailable || canTypeCodeHaveMultipleOccurrences;
                }
            );

            return filteredAddlInterestTypeCodes;
        },
        [tpiBasePath, transactionVM]
    );

    const getAddlInterestTypeCodes = useMemo(() => {
        const addlInterestTypesForPerson = ['AdditionalInsured_Ext', 'AdditionalInterest_Ext', 'Titleholder_Ext', 'AdditionalDesignee_Ext'];
        let addlInterestTypeCodes = [];

        addlInterestTypeCodes = getAddlInterestTypeCodesForEH();
        addlInterestTypeCodes = filterAddlInterestTypeCodes(addlInterestTypeCodes);

        const currentTpisInterestType = _find(ADDL_INTEREST_TYPE_TL.codes, {
            code: _get(tpiDetailVM, 'value.addlInterestType')
        });

        if (!isTPIContactTypeCompany) {
            addlInterestTypeCodes = addlInterestTypeCodes.filter(
                (addlInterestTypeCode) => addlInterestTypesForPerson.includes(addlInterestTypeCode.code)
            );
        }

        // in interest type dropdown given users interest type should be
        // present to display it in edit mode
        addlInterestTypeCodes.push({
            code: currentTpisInterestType?.code,
            name: translator({ id: currentTpisInterestType?.name })
        });

        return addlInterestTypeCodes;
    }, [
        getAddlInterestTypeCodesForEH, filterAddlInterestTypeCodes,
        ADDL_INTEREST_TYPE_TL.codes, tpiDetailVM, isTPIContactTypeCompany,
        translator
    ]);

    /**
     * Helper callback for handling saving the trustee details to the TPI "VMNode" object.
     */
    const saveTrusteeDetailsToVM = useCallback(
        (trusteeDetailValue) => {
            const trusteeAddressDisplayName = TPIUtil.getFormattedTPIAddress(
                trusteeDetailValue.address
            );

            _set(trusteeDetailValue, 'address.displayName', trusteeAddressDisplayName);

            const allTrustees = _cloneDeep(_get(tempTPIDetailVM, 'trustees.value', []));

            if (isEditingTrustee) {
                const indexOfEditedTrustee = allTrustees.findIndex((trustee) => trustee.publicID === trusteeDetailValue.publicID);

                _set(allTrustees, `[${indexOfEditedTrustee}]`, trusteeDetailValue);
            } else {
                allTrustees.push(trusteeDetailValue);
            }

            writeValue(allTrustees, 'trustees');
            setTrusteeDetailVM(undefined);
            setIsAddingNewTrustee(false);
            setIsEditingTrustee(false);
        },
        [isEditingTrustee, tempTPIDetailVM, writeValue]
    );

    /**
     * Helper callback for removing a trustee record from the trustee table.
     */
    const removeTrusteeRecord = useCallback(
        (rowToRemove) => {
            const allTrustees = _cloneDeep(_get(tempTPIDetailVM, 'trustees.value', []));

            if (allTrustees.length > 0) {
                _pullAt(allTrustees, rowToRemove);
                writeValue(allTrustees, 'trustees');
            }
        },
        [tempTPIDetailVM, writeValue]
    );

    /**
     * Helper function for handling when the "Remove Trustee" button is clicked.
     *
     * @param {*} item An object containing the set of VMNode properties related to the table row.
     * @param {*} rowToRemove A number representing the currently selected table row.
     */
    const removeTrusteeButtonOnClickHandler = useCallback((item, rowToRemove) => {
        removeTrusteeRecord(rowToRemove);
    }, [removeTrusteeRecord]);

    /**
           * Helper function for handling when the "Edit Trustee" button is clicked.
           *
           * @param {*} item An object containing the set of VMNode properties related to the table row.
           */
    const editTrusteeButtonOnClickHandler = (item) => {
        const newTrusteeDetailVM = createNewTrusteeDetailVM(item);

        setTrusteeDetailVM(newTrusteeDetailVM);
        setIsEditingTrustee(true);
    }

    const generateOverridePropsForUncontrolledPerson = useCallback(() => ({
            additionalInterestTypePerson: {
                visible: false,
                availableValues: getAddlInterestTypeCodes,
            },
            personFirstName: {
                readOnly: !isAddingNewTPI,
                required: isAddingNewTPI && !isTPIContactTypeCompany,
                validationMessages: PersonNameValidator.validateFirstName(_get(tempTPIDetailVM, 'person.firstName.value'), translator),
                showErrors: !!_get(tempTPIDetailVM, 'person.firstName.value', false) || isPageSubmitted || showErrors
            },
            personMiddleName: {
                readOnly: !isAddingNewTPI,
                validationMessages: PersonNameValidator.validateMiddleName(_get(tempTPIDetailVM, 'person.middleName.value'), translator),
                showErrors: !!_get(tempTPIDetailVM, 'person.middleName.value', false) || isPageSubmitted || showErrors
            },
            personLastName: {
                readOnly: !isAddingNewTPI,
                required: isAddingNewTPI && !isTPIContactTypeCompany,
                validationMessages: PersonNameValidator.validateLastName(_get(tempTPIDetailVM, 'person.lastName.value'), translator),
                showErrors: !!_get(tempTPIDetailVM, 'person.lastName.value', false) || isPageSubmitted || showErrors
            },
            personSuffix: {
                readOnly: !isAddingNewTPI
            },
            personAddressLine1: {
                required: true,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personAddressLine2: {
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personCity: {
                required: true,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personPostalCode: {
                required: true,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personAddressInfoContainer: {
                visible: isEditingTPI
            },
            personAddress: {
                addressVM: _get(tempTPIDetailVM, 'address'),
                labelPosition: 'top',
                showCountry: true,
                showOptional: false,
                onValidate: setComponentValidation,
                viewOnlyMode: false,
                showParentLoader: setIsStandardizingAddress,
                showErrors: isPageSubmitted || showErrors,
                onAddressChange: (value, path) => onAddressChange(value, `address.${path}`),
                visible: !isEditingTPI && !!tempTPIDetailVM,
                shouldStandardizeOnBlur: false
            },
            personState: {
                availableValues: USStates,
                required: true,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personCountry: {
                required: true,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            }
        }), [
        isAddingNewTPI, getAddlInterestTypeCodes, isTPIContactTypeCompany,
        tempTPIDetailVM, translator, isPageSubmitted, handleAddressBlur,
        isEditingTPI, setComponentValidation, USStates, onAddressChange, showErrors
    ]);


    const generateOverridePropsForUncontrolledCompany = useCallback(() => ({
            additionalInterestType: {
                visible: false,
                availableValues: getAddlInterestTypeCodes,
            },
            companyName: {
                readOnly: !isAddingNewTPI,
                required: isAddingNewTPI && isTPIContactTypeCompany
            },
            companyAddressLine1: {
                required: isTPIContactTypeCompany,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            companyAddressLine2: {
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            companyCity: {
                required: isTPIContactTypeCompany,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            companyPostalCode: {
                required: isTPIContactTypeCompany,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            companyState: {
                availableValues: USStates,
                required: isTPIContactTypeCompany,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            companyCountry: {
                required: isTPIContactTypeCompany,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            companyAddressInfoContainer: {
                visible: isEditingTPI
            },
            companyAddress: {
                addressVM: _get(tempTPIDetailVM, 'address'),
                labelPosition: 'top',
                showCountry: true,
                showOptional: false,
                onValidate: setComponentValidation,
                viewOnlyMode: false,
                showParentLoader: setIsStandardizingAddress,
                showErrors: isPageSubmitted || showErrors,
                onAddressChange: (value, path) => onAddressChange(value, `address.${path}`),
                visible: !isEditingTPI && !!tempTPIDetailVM,
                shouldStandardizeOnBlur: false
            },
        }), [
        USStates, getAddlInterestTypeCodes, handleAddressBlur, isAddingNewTPI,
        isEditingTPI, isPageSubmitted, isTPIContactTypeCompany, onAddressChange,
        setComponentValidation, tempTPIDetailVM, showErrors
    ]);

    const onCancel = useCallback(() => {
        if (setIsAddingTPI) {
            setIsAddingTPI(false);
        }

        disregardFieldValidation(id);
        disregardFieldValidationParentPage(id);

        if (showPopup) {
            return onReject();
        }

        updateIsSearchTPIVisible(false);
    }, [
        disregardFieldValidation, disregardFieldValidationParentPage,
        id, onReject, showPopup, updateIsSearchTPIVisible, setIsAddingTPI
    ]);

    const getTPIDetailsHeaderContent = useCallback(() => {
        let tpiDetailsHeaderContent = e1pCommonMessages.createNewTPI;

        if (isTPIFromSearchResult) {
            tpiDetailsHeaderContent = e1pCommonMessages.tpiDetails;
        } else if (isEditingTPI) {
            tpiDetailsHeaderContent = e1pCommonMessages.editThirdPartyInterestDetails;
        }

        return tpiDetailsHeaderContent;
    }, [isEditingTPI, isTPIFromSearchResult]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            showErrors: isPageSubmitted || showErrors,
            autoComplete: false
        },
        ehTPISearchDetailComponentIndicator: {
            loaded: !isStandardizingAddress && !isSearchingContacts,
            text: isStandardizingAddress
                ? translator(e1pCommonMessages.standardizingAddressMessage)
                : translator(e1pCommonMessages.searchingContacts)
        },
        ehTPISearchDetailComponentContainer: {
            visible: !isStandardizingAddress && !!tpiDetailVM && !isSearchingContacts
        },
        errorWarningContainer: {
            visible: showErrorWarning
        },
        errorAndWarningText: {
            message: translator(errorWarningMessage)
        },
        addressStandardizationComponent: {
            addressStandardizationResultDTO,
            visible: !!addressStandardizationResultDTO && !!tpiDetailVM && isEditingTPI,
            modalDisplay: false,
            selectInlineAddress: onSelectAddress,
            enteredAddress: enteredStateForAddressStandardization
        },
        tpiActionsContainer: {
            visible: !isStandardizingAddress && !isSearchingContacts
        },
        controlledTPIAddress: {
            value: formattedAddress
        },
        controlledTPIBankName: {
            value: formattedBankName
        },
        sendBillToTPIButton: {
            required: isTPITypeMortgagee && isEscrowSelected,
            visible: isTPITypeMortgagee && isEscrowSelected,
            disabled: isBillMortgageeSelectedOnPolicy,
            value: isBillMortgageeSelectedOnPolicy ? false : _get(tempTPIDetailVM, 'sendBillToTPIInd.value')
        },
        tpiAsPayerButton: {
            visible: isTPITypeMortgagee && !isEscrowSelected,
            disabled: isTPIasPayerSelectedOnPolicy
        },
        assignNowOrAtRenewalSelect: {
            defaultValue: _get(tempTPIDetailVM, 'sendBillToTPIInd.value')
                ? 'false'
                : undefined,
            required: _get(tempTPIDetailVM, 'sendBillToTPIInd.value') === true,
            visible: _get(tempTPIDetailVM, 'sendBillToTPIInd.value') === true
        },
        uncontrolledPersonTPIContainer: {
            visible: !isControlledTPI && !isTPIContactTypeCompany
        },
        uncontrolledCompanyTPIContainer: {
            visible: !isControlledTPI && isTPIContactTypeCompany
        },
        mortgageeDetailsContainer: {
            visible: isTPITypeMortgagee
        },
        trusteeDetailsContainer: {
            visible: isTPITypeTrust
        },
        addTrusteeButton: {
            disabled:
                _get(tempTPIDetailVM, 'trustees.value', []).length >= MAXIMUM_NUMBER_OF_TRUSTEES,
            visible: isTPITypeTrust && !isAddingNewTrustee && !isEditingTrustee
        },
        addTrusteePanelComponent: {
            trusteeDetailVM,
            viewModelService,
            cancelTrusteeButtonOnClickHandler,
            saveTrusteeDetailsToVM,
            authHeader,
            visible: isTPITypeTrust && (isAddingNewTrustee || isEditingTrustee),
            isEditingTPI,
            showErrors: showErrors || isPageSubmitted,
            disregardFieldValidationParentPage: disregardFieldValidation,
            onValidate: setComponentValidation
        },
        tpiDetailsHeading: {
            content: getTPIDetailsHeaderContent()
        },
        controlledTPIContainer: {
            visible: isEditingTPI && isControlledTPI
        },
        completeMissingFieldMessageDiv: {
            visible: isPageSubmitted && !isComponentValid
        },
        mortgageeNotSetupToBillSubHeader:{
            visible: isTPITypeMortgagee && !isEscrowSelected &&  !!_get(tempTPIDetailVM, 'convertTPIToPayerInd.value'),
            style: { fontWeight: 'var(--font-weight-normal-bold)', marginTop: 'var(--GW-SPACING-4)', marginBottom: 'var(--GW-SPACING-4)' }
        },
        ...generateOverridePropsForUncontrolledPerson(),
        ...generateOverridePropsForUncontrolledCompany()
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                tempTPIDetailVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [tempTPIDetailVM, overrideProps]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            saveThirdPartyInterest,
            addTrusteeButtonOnClickHandler,
            removeTrusteeButtonOnClickHandler,
            editTrusteeButtonOnClickHandler,
            onCancel
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    const tpiDetailsPanel = (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={tempTPIDetailVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={setComponentValidation}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );

    if (!showPopup) {
        return tpiDetailsPanel;
    }

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={tempTPIDetailVM}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    onValidationChange={setComponentValidation}
                    resolveValue={readValue}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter />
        </ModalNext>

    );
};

/**
 * Define expected types for properties to be passed into this Jutro component.
 */
E1PEHTPISearchDetailComponent.propTypes = {
    viewModelService: PropTypes.shape({
        clone: PropTypes.func.isRequired,
        create: PropTypes.func.isRequired,
        productMetadata: PropTypes.shape({
            get: PropTypes.func
        }).isRequired
    }).isRequired,
    tpiDetailVM: PropTypes.shape({
        value: PropTypes.shape({
            addlInterestType: PropTypes.string,
            person: PropTypes.shape({
                publicID: PropTypes.string
            }),
            company: PropTypes.shape({
                publicID: PropTypes.string
            }),
        })
    }),
    isControlledTPI: PropTypes.bool.isRequired,
    isTPIContactTypeCompany: PropTypes.bool.isRequired,
    tpiBasePath: PropTypes.string,
    id: PropTypes.string.isRequired,
    isTPITypeMortgagee: PropTypes.bool.isRequired,
    isTPITypeTrust: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    showPopup: PropTypes.bool,
    authHeader: PropTypes.shape({}).isRequired,
    isAddingNewTPI: PropTypes.bool,
    isEditingTPI: PropTypes.bool,
    transactionVM: PropTypes.shape({}).isRequired,
    updateIsSearchTPIVisible: PropTypes.func.isRequired,
    saveThirdPartyInterestClickHandler: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired,
    onResolve: PropTypes.func,
    onReject: PropTypes.func,
    onValidate: PropTypes.func.isRequired,
    disregardFieldValidationParentPage: PropTypes.func.isRequired,
    setIsAddingTPI: PropTypes.func,
    isTPIFromSearchResult: PropTypes.bool
    // preferredTPIContactVM: PropTypes.shape({})
};

/**
 * Define default values for properties to be passed into this Jutro component.
 */
E1PEHTPISearchDetailComponent.defaultProps = {
    isAddingNewTPI: false,
    showPopup: false,
    isEditingTPI: false,
    onResolve: undefined,
    onReject: undefined,
    setIsAddingTPI: undefined,
    isTPIFromSearchResult: false
    // preferredTPIContactVM: undefined
};

export default E1PEHTPISearchDetailComponent;
