import React, {
    useCallback, useEffect, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import { find as _find } from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { PolicyDetailsService } from 'e1p-capability-gateway';
import messages from './ExternalProducerDetailsComponent.messages';
import { InputField } from '@jutro/legacy/components';

function ExternalProducerDetailsComponent(props) {
    const {
        labelPosition,
        id,
        onValidate,
        defaultValue,
        onHandleProducerApiResponse,
        viewOnlyMode,
        producerCodeDetails,
        showErrors,
        agencyDetailsNeeded,
        isRequired,
        clearLocationCodeReference
    } = props;
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const translator = useTranslator();
    const [externalLocationCode, setExternalLocationCode] = useState(defaultValue);
    const [errors, setErrors] = useState(undefined);
    const [successMessage, setSuccessMessage] = useState(undefined);
    const [isFetchingDetails, setIsFetchingDetails] = useState(false);
    const isMounted = useRef(true);

    // this will reset the input box for location code
    const clearLocationCode = () => {
        setExternalLocationCode('');
        setSuccessMessage(undefined);
        setErrors(undefined);
    }

    useEffect(() => {
        if (onValidate) {
            const isValid = !errors && isComponentValid;

            onValidate(isValid, id);
        }
    }, [id, onValidate, isComponentValid, errors]);

    const callProducerApi = useCallback(async (externalID) => {
        try {
            setIsFetchingDetails(true);
            setErrors(undefined);
            setSuccessMessage(translator(messages.fetchingDetails));

            const policyDetails = await PolicyDetailsService.getProducerDetails(
                externalID,
                authHeader
            );

            if(isMounted.current){
                const { producerCode, name, bookRollIndicator, disposition } = policyDetails;
                // select primary address returned
                const address = policyDetails?.contactDetails?.addresses?.find(
                    (addressInList) => addressInList.primaryAddressIndicator
                );
                const phone = policyDetails?.contactDetails?.phones?.find(
                    (phoneInList) => phoneInList.phoneNumber
                ).phoneNumber;
                const serviceCenterIndicatorValue = _find(disposition, (val) => val.key === 'SERVICE_CENTER_INDICATOR')?.value === 'Y';
                
                onHandleProducerApiResponse({
                    producerCode, address, phone, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
                });
                setIsFetchingDetails(false);
                setErrors(undefined);
                setSuccessMessage(translator(messages.agencyFound));
                setComponentValidation(true);
            }
           
        } catch {
            setIsFetchingDetails(false);
            setSuccessMessage(undefined);
            setErrors([translator(messages.noAgencyFound)]);
            setComponentValidation(false);
        }
    }, [authHeader, onHandleProducerApiResponse, setComponentValidation, translator]);

    useEffect(() => {
        // Sometimes when you click next, the route comes back before landing
        //   on drivers page. So if agency details already exist don't make the call
        //   on page load.
        if ((defaultValue && !producerCodeDetails) && agencyDetailsNeeded) {
            callProducerApi(defaultValue);
        }

        if(clearLocationCodeReference){
            clearLocationCodeReference.current = clearLocationCode;
        }

        return ()=>{
            isMounted.current = false;
        }
        // Only run on mount in case we are returning to page after sub is already started
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, externalLocationCode]);

    const handleValueChange = useCallback(
        (value) => {
            setExternalLocationCode(value);
        },
        [setExternalLocationCode]
    );

    const handleBlur = useCallback(async (_, { value, beforeValue }) => {
        // exiting field after removing all input
        //   OR exiting without changing input
        if (value === beforeValue || !value) {
            if(!value){
                clearLocationCode();
            }

            return;
        }

        callProducerApi(value);
    }, [callProducerApi]);

    const disableField = viewOnlyMode || isFetchingDetails;

    return (
        <InputField
            autoComplete={false}
            dataType="string"
            hideLabel={false}
            id="InputField"
            label={translator(messages.externalLocationCode)}
            labelPosition={labelPosition}
            onValueChange={handleValueChange}
            showErrors={showErrors}
            value={externalLocationCode}
            onValidationChange={setComponentValidation}
            onBlur={handleBlur}
            validationMessages={errors}
            successMessage={successMessage}
            readOnly={disableField}
            defaultValue={defaultValue}
            required={isRequired}
            showRequired={isRequired}
        />
    );
}

ExternalProducerDetailsComponent.propTypes = {
    value: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    onHandleProducerApiResponse: PropTypes.func,
    onValidate: PropTypes.func,
    id: PropTypes.string,
    defaultValue: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    producerCodeDetails: PropTypes.shape({}),
    showErrors: PropTypes.bool,
    agencyDetailsNeeded: PropTypes.bool,
    isRequired: PropTypes.bool,
    clearLocationCodeReference: PropTypes.oneOfType([
        PropTypes.func, 
        PropTypes.shape({ current: PropTypes.object })
    ])
};
ExternalProducerDetailsComponent.defaultProps = {
    value: {},
    labelPosition: 'top', // I want labels on top by default
    id: undefined,
    defaultValue: undefined,
    viewOnlyMode: false,
    producerCodeDetails: undefined,
    showErrors: false,
    agencyDetailsNeeded: true,
    isRequired: true,
    clearLocationCodeReference: undefined,
    onValidate: undefined
};
export default ExternalProducerDetailsComponent;
