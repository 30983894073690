import React, {
    useCallback, useContext, useEffect, useMemo, useState, useRef
} from 'react';
import {
    findIndex as _findIndex,
    get as _get,
    isNil as _isNil,
    set as _set,
    isEmpty as _isEmpty,
    isUndefined as _isUndefined,
    find as _find
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react'
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { QuoteProposalUtil, PaymentPageUtil } from 'e1p-portals-util-js';
import { WizardContext } from 'e1p-portals-wizard-react/Wizard/WizardContext';
import { AutoLossService, PolicyDetailsService, AccountService } from 'e1p-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { PaymentHelper } from 'e1p-capability-payment';
import { error as loggerError } from '@jutro/logger';
import { useUWIssueUtil, e1pUserAccessUtil } from 'e1p-capability-hooks';
import {
    parseErrors
} from '@xengage/gw-portals-edge-validation-js';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
 
import appConfig from 'app-config';
import EAReports from './Reports/EAReports';
import metadata from './PaymentDetailsPage.metadata.json5';
import messages from './PaymentDetailsPage.messages';

const LOB = 'personalAuto_EA';
const INITIAL_PAYMENT_OPTION_PATH = 'baseData.value.conversionDTO.initialPaymentType';
// need to toggle paymentus per env alignment
const environment = _get(appConfig, 'env.AMFAM_ENV', 'local');
const envsWithoutPayment = ['dev', 'local', 'sbx', 'dev2', 'int2', 'qa2', 'dev3', 'gwupgrade1', 'int', 'perf2'];
const requiresPaymentForEnvironment = !envsWithoutPayment.includes(environment);
// Integrations not set up
const ignoreValidation = ['int2', 'qa2', 'dev3'].includes(environment);
// IAP-5702, propertyPolicy is required to bind auto policy
const shouldCheckBoundPropertyPolicyForEnviroment = ['prod', 'uat'].includes(environment);

function PaymentDetailsPage(props) {
    const modalApi = useModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        steps,
        jumpTo,
        disableAllSteps,
        isSkipping,
        lastVisitedRiskAnalysisTab,
        setLastVisitedRiskAnalysisTab,
        wizardSnapshot
    } = props;

    const {
        hasUWIssues
    } = useUWIssueUtil(
        submissionVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const lastVisitedRiskAnalysisTabRef = useRef(lastVisitedRiskAnalysisTab);
    const [isCallingService, setIsCallingService] = useState(false);
    const [isCallingCanPurchase, setIsCallingCanPurchase] = useState(false);
    const [isVMInitialized, setIsVMInitialized] = useState(false);
    const [bindOperationsInProcess, setBindOperationsInProcess] = useState(false);
    const [signatureSuccess, setSignatureSuccess] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isMailingComponentValid, updateIsBillingComponentValid] = useState(true);
    const [isBillingComponentValid, updateIsMailingComponentValid] = useState(true);
    const [isInsuredComponentValid, updateIsInsuredComponentValid] = useState(true);
    const { authHeader, authUserData } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('PaymentDetailsPage');
    const [underwritingIssuesPopupAppeared, setUnderwritingIssuesPopupAppeared] = useState(false);
    const [keepShowingIframeAfterPayment, setKeepShowingIframeAfterPayment] = useState(false);
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);
    const [fieldIssue, setFieldIssue] = useState(false);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const translator = useTranslator();
    const amfamOktaToken = useContext(AmfamOktaTokenContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const wizardContext = useContext(WizardContext);
    const PAY_MONTHLY = 'af:payMthly3';
    const isPaymentDone = !!_get(submissionVM, 'bindData.value.paymentRefNumber');
    const [selectedPaymentOption, setSelectedPaymentOption] = useState(_get(submissionVM, INITIAL_PAYMENT_OPTION_PATH));
    const isSignatureStatusAsCompleted = _get(submissionVM, 'value.baseData.signature_Ext.status') === 'COMPLETED';
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    // It is used to show loader message 'Preparing Required Documents' when gettingSignatureDocument is true
    const [gettingSignatureDocument, setGettingSignatureDocument] = useState(false);
    // It is used to show loader message 'Saving the Insureds contact information' when isInsuredContactUpdating is true
    const [isInsuredContactUpdating, setIsInsuredContactUpdating] = useState(false);
    const { paymentRequiredForOpco } = appConfig;
    const { opCo } = amfamOktaToken;
    const [requiresPayment, setIsRequiresPayment] = useState(requiresPaymentForEnvironment && paymentRequiredForOpco.includes(opCo))
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);
    const canUserIssueWithoutPayment = authUserData.permissions_Ext.includes('issuewithoutpayment_ext');
    const accountNumber = _get(submissionVM, 'baseData.accountNumber.value');
    const policyState = _get(
        submissionVM,
        'baseData.policyAddress.state.value.code',
        _get(submissionVM, 'policyAddress.state.value.code')
    );
    const isAgent = authUserData?.roles_Ext.includes('ext_sales_service');
    
    const shouldCheckForBoundPropertyPolicyForState = !['NH','NC', 'UT'].includes(policyState);

    // IAP-5702, shouldCheckForBoundPropertyPolicy is the main identifier to check bound property policy
    // and this should be executed only for external agents.
    const shouldCheckForBoundPropertyPolicy =
        shouldCheckBoundPropertyPolicyForEnviroment &&
        shouldCheckForBoundPropertyPolicyForState &&
        isAgent;
    
    const [isBoundPropertyPolicyAvailableAtAccount, setIsBoundPropertyPolicyAvailableAtAccount ] = useState(false);

    const { isUserAuthorisedToPayAndBind } = e1pUserAccessUtil(authUserData, opCo);
    const canUserPayAndBindQuote = isUserAuthorisedToPayAndBind();

    useEffect(() => {
        // It will make payment required if the environment and operating company condition matches (MSA)
        setIsRequiresPayment(requiresPaymentForEnvironment && paymentRequiredForOpco.includes(opCo))
    }, [opCo, paymentRequiredForOpco]);

    useEffect(() => {
        lastVisitedRiskAnalysisTabRef.current = lastVisitedRiskAnalysisTab;
         
    }, [lastVisitedRiskAnalysisTab]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isBillingComponentValid && isMailingComponentValid && isPageSubmitted && isInsuredComponentValid) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isPageSubmitted, isBillingComponentValid, isMailingComponentValid, isInsuredComponentValid]);

    const showModalReports = useCallback(
        async (vm, retrievedData) => {
            const componentProps = {
                submissionVM: vm,
                updateWizardData,
                incidentData: retrievedData,
                wizardContext,
                steps,
                jumpTo,
                lastVisitedRiskAnalysisTab: lastVisitedRiskAnalysisTabRef,
                setLastVisitedRiskAnalysisTab
            };
            const result = await modalApi.showModal(<EAReports {...componentProps} />);

            return result;
        },
        [jumpTo, setLastVisitedRiskAnalysisTab, steps, updateWizardData, wizardContext, modalApi]
    );

    const showPopupIfUWIssueExists = useCallback((ignoreRvpUW = false) => {
        if (hasUWIssues(ignoreRvpUW) && !underwritingIssuesPopupAppeared) {
            setUnderwritingIssuesPopupAppeared(true);

            const indexForCoveragePage = _findIndex(steps, ({ path }) => path === '/coverages');

            jumpTo(indexForCoveragePage);
        }
    }, [hasUWIssues, jumpTo, steps, underwritingIssuesPopupAppeared]);


    const popOverAction = useCallback(
        async (retrievedData) => {
            showModalReports(submissionVM, retrievedData)
                .then(() => {
                    showPopupIfUWIssueExists();
                })
                .catch(() => { });
        },
        [showModalReports, submissionVM, showPopupIfUWIssueExists]
    );

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = _get(submissionVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmail = _get(submissionVM, 'lobData.personalAuto_EA.paperlessEmail.value');

        return isPaperlessEmailUpdated && pniEmail && pniEmail !== paperlessEmail;
    }, [submissionVM, isPaperlessEmailUpdated]);

    /**
     * Check quote data dto for validation errors and exceptions
     *   that come from in page api response (not onNext calls)
     */
    const checkForInPageErrors = useCallback(
        (qdd) => {
            const errorsAndWarnings = _get(qdd, 'errorsAndWarnings', []);

            // uw goes in pop up; not top banner
            delete errorsAndWarnings.underwritingIssues;

            const exceptionsPath = 'baseData.exceptions_Ext';
            const errors = parseErrors(errorsAndWarnings);
            const exceptions = _get(qdd, exceptionsPath, []);
            const errorConvertingToFullApp = errors?.length > 0 || exceptions.length > 0;

            if (errorConvertingToFullApp) {
                setValidationErrors(errors.concat(exceptions));
            }
        },
        [],
    );

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();

            if (quoteProposalLink) {
                window.open(quoteProposalLink, '_blank');

                return true;
            }
        }, [quoteProposalLink]
    );

    // When payment option changes, we need to get a new doc url
    useEffect(() => {
        if (submissionVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            submissionVM.paymentOptionChanged = false;
        }
    }, [authHeader, submissionVM, submissionVM.paymentOptionChanged]);

    const checkCanPurchase = useCallback(async () => {
        if (!isSkipping
            && submissionVM.value.quoteType === 'Full'
            // Check if period status for submission is quoted to call canPurchase or  disable steps
            && submissionVM.baseData.periodStatus.value.code === 'Quoted') {
            if (isPaymentDone && requiresPayment) {
                disableAllSteps();
            }

            setIsCallingCanPurchase(true);

            const response = await LoadSaveService.canPurchase(submissionVM.value, authHeader);

            // have to set uw issues from the can purchase so the util can see them
            _set(
                submissionVM.value,
                'errorsAndWarnings.underwritingIssues',
                response.errorsAndWarnings.underwritingIssues
            );

            // this is to hide paymentus component if there is any validation error
            const fieldError = _get(response, 'errorsAndWarnings.validationIssues.fieldIssues', []);

            setFieldIssue(!!(fieldError.length));

            const licensedProducers = _get(response, 'licensedProducers.licensedProducers', []);

            _set(submissionVM.value, 'licensedProducers', response.licensedProducers);
            // check for validation errors
            checkForInPageErrors(response);
            // update wizard data with any uw issues and show if exists
            updateWizardData(submissionVM);
            // wizardsnapshot not updating automatically on next
            updateWizardSnapshot(submissionVM);
            // If UW issues exists on can purchase, redirect user to Coverage page
            showPopupIfUWIssueExists();

            // set producer code options for signin agents
            if (licensedProducers.length > 0) {
                setProducerCodeOptions(
                    licensedProducers.map((value) => ({
                            code: value.producerID,
                            name: `${value.producerName.firstName} ${value.producerName.lastName}`
                        }))
                );
            }

            setIsCallingCanPurchase(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping, submissionVM.value.quoteType, submissionVM.baseData.periodStatus.value.code]);

    useEffect(() => {
        if (!isVMInitialized && submissionVM.value.quoteType === 'Full') {
            submissionVM.bindData.paymentDetails.value ??= {};
            setIsVMInitialized(true);
        }
    }, [isVMInitialized, submissionVM]);

    const getAutoIncidentDetails = useCallback(async () => {
        if (submissionVM.convertedFromQuickToFull === true) {
            _set(submissionVM, 'convertedFromQuickToFull', false);
            AutoLossService.loadAutoLosses(_get(submissionVM, 'quoteID.value'), authHeader)
                .then((response) => {
                    const filteredAutoLossRecords = response.autoLossRecords?.filter(
                        (loss) => loss.source?.sourceType !== 'Self-Declared');

                    const filteredAutoViolationRecords = response.autoViolationRecords?.filter(
                        (violation) => violation.source?.sourceType !== 'Self-Declared');

                    _set(response, 'autoLossRecords', filteredAutoLossRecords);
                    _set(response, 'autoViolationRecords', filteredAutoViolationRecords);

                    popOverAction(response);
                })
                .finally(() => {
                    updateWizardData(submissionVM);
                });
             
        } else {
            showPopupIfUWIssueExists();
        }
    }, [authHeader, popOverAction, submissionVM, updateWizardData, showPopupIfUWIssueExists]);

    const setErrors = useCallback((removeUnnecessaryErrors = false) => {

        const shouldShowMissingPaymentError = requiresPayment && !isPaymentDone && selectedPaymentOption !== 'PayLater' && !canUserIssueWithoutPayment;

        const isSignatureMissing = _get(submissionVM, 'baseData.signatureType_ext.value') === undefined || !signatureSuccess;
        const isUwIssuePresent = hasUWIssues();

        const uniqueErrors = PaymentPageUtil.getValidationErrorsForPaymentPage(
            validationErrors, shouldShowMissingPaymentError, isSignatureMissing, isUwIssuePresent, translator, removeUnnecessaryErrors);

        setValidationErrors(uniqueErrors);

    }, [hasUWIssues, isPaymentDone, signatureSuccess, submissionVM, translator, validationErrors, selectedPaymentOption, requiresPayment, canUserIssueWithoutPayment]);

    useEffect(() => {
        if (signatureSuccess || isPaymentDone) {
            setErrors(true);
        }
        // want to trigger this only on signatureSuccess update and payment update
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureSuccess, isPaymentDone])


    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            setErrors();
            window.scrollTo(0, 0);

            return false;
        }

        setBindOperationsInProcess(true);

        // If book transfer indicator is yes, and initial payment option has changed - save it before binding it
        if (bookTransferIndicator && _get(submissionVM, INITIAL_PAYMENT_OPTION_PATH) !== selectedPaymentOption) {
            _set(submissionVM, INITIAL_PAYMENT_OPTION_PATH, selectedPaymentOption);

            submissionVM.value = await LoadSaveService.updateQuotedSubmission(
                submissionVM.value,
                authHeader
            );

            updateWizardData(submissionVM);

            const errorsAndWarnings = _get(submissionVM, 'value.errorsAndWarnings', {});
            const errors = parseErrors(errorsAndWarnings);
            const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

            // In case of error on updating quoted submission, show errors and do not bind
            if (errors.length || exceptions.length) {
                setValidationErrors(errors.concat(exceptions));
                setBindOperationsInProcess(false);

                return false;
            }
        }

        const quoteData = _get(submissionVM, 'quoteData');

        // If below int, we still need to set a payment ref number
        //   because we will still get a validation issue
        if (!requiresPayment) {
            const { paymentReferenceNumberForLocal } = appConfig.amfamRewritePaymentConfig;

            _set(submissionVM, 'bindData.value.paymentRefNumber', paymentReferenceNumberForLocal);
        }

        const response = await LoadSaveService.bindSubmission(submissionVM.value, authHeader);

        response.quoteData = quoteData.value;
        submissionVM.value = response;
        updateWizardData(submissionVM);

        const errorsAndWarnings = _get(submissionVM, 'value.errorsAndWarnings', {});
        const errors = parseErrors(errorsAndWarnings);
        const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

        // In case of error on bind submission, show errors and do not bind
        if (errors.length || exceptions.length) {
            setValidationErrors(errors.concat(exceptions));
            setBindOperationsInProcess(false);

            return false;
        }

        setBindOperationsInProcess(false);

        return submissionVM;
    }, [LoadSaveService, authHeader, isComponentValid, setErrors, submissionVM, updateWizardData, bookTransferIndicator, selectedPaymentOption, requiresPayment]);

    /**
     * Initialization code
     */
    useEffect(
        () => {
            setIsCallingService(true);
            // on mount get quote proposal
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );

            // Check book transfer status from Location Code
            const externalId = _get(submissionVM, 'baseData.externalID_Ext.value');

            if (externalId) {
                PolicyDetailsService.getProducerDetails(
                    externalId,
                    authHeader
                ).then((policyDetails) => {
                    setBookTransferIndicator(_get(policyDetails, 'bookRollIndicator', false));

                    if (!selectedPaymentOption && _get(policyDetails, 'bookRollIndicator', false)) {
                        setSelectedPaymentOption('PayLater');
                    }
                }).catch((error) => {
                    loggerError(`Error fetching book transfer indicator details: ${error}`);
                    setValidationErrors(
                        [
                            ...validationErrors,
                            {
                                level: 'LEVEL_ERROR',
                                description: translator(e1pCommonMessages.genericErrorText)
                            }
                        ]
                    );
                });
            }

            const isQuoteQuick = submissionVM.value.quoteType === 'Quick';

            if (!isQuoteQuick) {
                // check payment receipt if full quote and continue (never on convert from quick to full)
                if (!isPaymentDone && requiresPayment) {
                    PaymentHelper.getReceiptNumber(
                        _get(submissionVM, 'quoteID.value', submissionVM.jobID?.value), authHeader
                    ).then((referenceNumber) => {
                        // set value from receipt in case it did not get saved to PC
                        if (referenceNumber) {
                            _set(submissionVM, 'bindData.value.paymentRefNumber', referenceNumber);
                            updateWizardData(submissionVM);
                        }
                    }).catch((err) => {
                        loggerError(err);
                    });
                }
            }


        // IAP-5702, propertyPolicy is required to bind auto policy
        if (shouldCheckForBoundPropertyPolicy) {
            // call backend API to fetch account policies then filter bound property policies
            AccountService.getAccountDetails(accountNumber, authHeader).then((accountDetails) => {
                const policySummaries = _get(accountDetails, 'policySummaries', []);
                const isBoundPropertyAvailable = !!_find(policySummaries, {
                    isIssued: true,
                    isCancelled: false,
                    product: { productCode: 'Homeowners_EH' },
                });
                
                setIsBoundPropertyPolicyAvailableAtAccount(isBoundPropertyAvailable);
            }).catch((err) => {
                loggerError(err);
            });
        }

            const convertToFullAppAndOrderReports = async () => {
                let verifiedQuoteDataPromise;

                if (submissionVM.value.quoteType === 'Quick') {
                    verifiedQuoteDataPromise = LoadSaveService.convertToFullApp(
                        submissionVM.value,
                        authHeader
                    );
                }

                if (verifiedQuoteDataPromise) {
                    verifiedQuoteDataPromise.then(async (verifiedQuoteDataDto) => {
                        submissionVM.value = verifiedQuoteDataDto;

                        const underwritingIssues = _get(verifiedQuoteDataDto, 'errorsAndWarnings.underwritingIssues');

                        updateWizardData(submissionVM);
                        updateWizardSnapshot(submissionVM);
                        checkForInPageErrors(verifiedQuoteDataDto);

                        if (submissionVM.value.quoteType === 'Full') {
                            // only order if successful conversion
                            // Errors should take user back to appropriate page
                            //   and then they'll come back here and try again
                            _set(
                                submissionVM.value,
                                'errorsAndWarnings.underwritingIssues',
                                underwritingIssues
                            );
                            showPopupIfUWIssueExists(true);
                            await checkCanPurchase();

                            if (!hasUWIssues(true)) {
                                getAutoIncidentDetails();
                            }
                        }

                        setIsCallingService(false);
                    });
                } else {
                    // we should call can purchase on continue quote
                    //   this is getting our licensed producers
                    showPopupIfUWIssueExists();
                    await checkCanPurchase();

                    if (!hasUWIssues(true)) {
                        getAutoIncidentDetails();
                    }

                    setIsCallingService(false);
                }

                updateWizardSnapshot(submissionVM);
            };

            convertToFullAppAndOrderReports();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const availableValuesForPaymentOptions = useMemo(() => {
        const correctOffering = submissionVM.lobData.personalAuto_EA.offerings.value[0];

        if (correctOffering.paymentPlans !== undefined) {
            return correctOffering.paymentPlans
                .filter((paymentPlans) => paymentPlans.name)
                .reduce(
                    (paymentPlanMap, paymentPlan) => ({
                        ...paymentPlanMap,
                        [paymentPlan.billingId]: paymentPlan
                    }),
                    {}
                );
        }

        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(submissionVM.lobData.personalAuto_EA.offerings.value)]);


    /**
     * Helper callback which opens the "Third-Party Interest" wizard page.
     */
    const openTPIPageHandler = useCallback(() => {
        const indexForTPIPage = _findIndex(steps, ({ path }) => path === '/third-party-interest');

        jumpTo(indexForTPIPage);
    }, [steps, jumpTo]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isCallingService) {
            loadingMessage = translator(messages.applyingChangesMessage);
        } else if (isInsuredContactUpdating) {
            loadingMessage = translator(e1pCommonMessages.savingContactinfoMessage);
        } else if (gettingSignatureDocument) {
            loadingMessage = translator(e1pCommonMessages.prepareReqDocuments);
        } else if (bindOperationsInProcess) {
            loadingMessage = translator(messages.completingYourPurchaseMessage);
        }

        return loadingMessage;
    }, [isSavingCurrentPageChanges, isCallingService, isInsuredContactUpdating, gettingSignatureDocument, bindOperationsInProcess, translator]);

    const getTotalPremium = () => {
        const selectedPlan = availableValuesForPaymentOptions[
            _get(submissionVM, 'bindData.selectedPaymentPlan.value')
        ]?.total;

        if (selectedPlan) {
            const totalPremium = selectedPlan.amount;

            return { currency: 'usd', amount: totalPremium };
        }

        return undefined;
    };

    const handleContactInfoUpdate = useCallback(async () => {
        // If paperless Email id exist and pni email id is undefiend, then update pni email to paperless email
        if (_isUndefined(_get(submissionVM.value, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1'))
            && !!_get(submissionVM.value, 'lobData.personalAuto_EA.paperlessEmail')) {
            _set(submissionVM.value, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1',
                _get(submissionVM.value, 'lobData.personalAuto_EA.paperlessEmail'));
        }

        // sync NI changes to Drivers
        const drivers = _get(submissionVM.value, 'lobData.personalAuto_EA.coverables.drivers');
        const pni = _get(submissionVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
        const pniDriver = drivers.find((driver) => driver.person.publicID === pni.person.publicID);

        if (pniDriver) {
            // updates to existing pni contact
            _set(pniDriver, 'person', pni.person);
            // setting the license on the person otherwise
            // update wipes it off when it's just under driver
            _set(pniDriver, 'person.licenseState', pniDriver.licenseState);
            _set(pniDriver, 'person.licenseNumber', pniDriver.licenseNumber);
        }

        const signatureType = _get(submissionVM.value, 'baseData.signatureType_ext', undefined);

        // contact change requires another quote
        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
            submissionVM.value, authHeader
        );
        _set(submissionVM.value, 'baseData.signatureType_ext', signatureType);
        updateWizardSnapshot(submissionVM);
        updateWizardData(submissionVM);
    }, [LoadSaveService, authHeader, submissionVM, updateWizardData, updateWizardSnapshot]);

    const onSave = useCallback(
        async () => {
            try {
                if (!isMailingComponentValid || !isBillingComponentValid || !isInsuredComponentValid) {
                    updateIsPageSubmitted(true);
                    window.scrollTo(0, 0);

                    return false;
                }

                setIsSavingCurrentPageChanges(true);

                const licensedProducerID = _get(submissionVM, 'value.bindData.licensedProducerID');
                const signatureType = _get(submissionVM.value, 'baseData.signatureType_ext', undefined);
                // We get licensedProducers in canPurchase call so storing it in variable before we do saveAndQuote
                const licensedProducers = _get(submissionVM, 'value.licensedProducers');

                if (bookTransferIndicator) {
                    _set(submissionVM, INITIAL_PAYMENT_OPTION_PATH, selectedPaymentOption);
                }

                const response = await LoadSaveService.saveAndQuoteSubmission(
                    submissionVM.value,
                    authHeader
                );

                _set(response, 'licensedProducers', licensedProducers);
                _set(submissionVM, 'value', response);
                _set(submissionVM.value, 'baseData.signatureType_ext', signatureType);
                QuoteProposalUtil.fetchQuoteProposal(
                    submissionVM,
                    setQuoteProposalCompleted,
                    setQuoteProposalLink,
                    authHeader,
                    setIsQuoteProposalFailed
                );

                const fieldError = _get(response, 'errorsAndWarnings.validationIssues.fieldIssues', []);

                setFieldIssue(!!(fieldError.length));
                // check for validation errors
                checkForInPageErrors(response);
                updateWizardData(submissionVM);

                const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldError) && _isEmpty(exceptions)) {
                    /**
                    * E1PAP1PC-14986 :
                    * wizardData and wizardSnapshot not being equal due to
                    * some defaulting on each page so doing this temp fix
                    */
                    _set(submissionVM, 'value.bindData.licensedProducerID', licensedProducerID);
                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        },
        [
            LoadSaveService, authHeader, checkForInPageErrors, isBillingComponentValid,
            isMailingComponentValid, submissionVM, updateWizardData, updateWizardSnapshot,
            isInsuredComponentValid, bookTransferIndicator, selectedPaymentOption
        ]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isCallingService
            && !bindOperationsInProcess
            && !isCallingCanPurchase
            && !isSavingCurrentPageChanges
            && !gettingSignatureDocument
            && !isInsuredContactUpdating,
            [bindOperationsInProcess, gettingSignatureDocument, isCallingCanPurchase,
            isCallingService, isInsuredContactUpdating, isSavingCurrentPageChanges]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'top',
            readOnly: isPaymentDone && requiresPayment,
            autoComplete: false,
            showRequired: true
        },
        paymentDetailsPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        paymentDetailsPageContainer: {
            visible: !isCallingService && !bindOperationsInProcess
                && !isCallingCanPurchase && !isSavingCurrentPageChanges && !isInsuredContactUpdating,
            // Facing some issues, if i try to hide the container using visibility. 
            // Initially, visibility is false so all the components will be unmount 
            // when the visibility become true then all components will be mount again 
            // it will re-trigger all the react hook components so handled hiding using CSS
            className: gettingSignatureDocument ? "display-none" : "mt-8"
        },
        totalPremiumID: {
            value: getTotalPremium()
        },
        totalPremiumWithFullPayID: {
            value: !_isNil(availableValuesForPaymentOptions[PAY_MONTHLY])
                ? availableValuesForPaymentOptions[PAY_MONTHLY].total
                : undefined
        },
        quoteProposalLinkContainer: {
            onClick: (e) => openDocument(e),
            disabled: !quoteProposalCompleted
        },
        monthlyPaymentScheduleComponent: {
            quoteID: _get(submissionVM, 'quoteID.value', submissionVM.jobID?.value),
            authHeader,
            transactionTotalAmount: !_isNil(availableValuesForPaymentOptions[
                _get(submissionVM, 'bindData.selectedPaymentPlan.value')
            ])
                ? availableValuesForPaymentOptions[
                    _get(submissionVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined,
            changeInCost: submissionVM.transactionCost?.value,
            startDate: submissionVM.baseData.periodStartDate.value,
            endDate: submissionVM.baseData.periodEndDate.value,
            jobTypeCode: submissionVM.baseData.jobType.value.code,
            offerings: _get(submissionVM, 'lobData.personalAuto_EA.offerings.value'),
            quoteOfferings: _get(submissionVM, 'quoteData.offeredQuotes.value', [])
        },
        mailingAndBillingAddressComponent: {
            transactionVM: submissionVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            viewOnlyMode: isPaymentDone && requiresPayment,
            showErrors: isPageSubmitted,
            updateIsMailingComponentValid,
            updateIsBillingComponentValid,
            wizardSnapshot
        },
        paymentOptionsID: {
            submissionVM,
            authHeader,
            updateWizardData,
            LOB,
            viewOnly: isPaymentDone && requiresPayment
        },
        tpiComponentId: {
            submissionVM,
            viewModelService,
            authHeader,
            updateWizardData
        },
        addTPIButton: {
            disabled: isPaymentDone && requiresPayment
        },
        policyTermComponent: {
            transactionVM: submissionVM,
            authHeader,
            updateWizardData,
            viewOnly: true // as discussed with satish donot allow user to change on payment page
        },
        displayPNIAddress: {
            readOnly: true
        },
        paymentOptions: {
            visible: !!bookTransferIndicator
                && !hasUWIssues()
                && submissionVM.value.quoteType === 'Full'
                && !isSkipping
                && !fieldIssue,
            value: selectedPaymentOption,
            onValueChange: setSelectedPaymentOption
        },
        paymentusComponent: {
            submissionVM,
            wizardSnapshot,
            amfamAccessToken: amfamOktaToken.amfamAccessTokenHeader,
            updateWizardData,
            // Show payment component when payment is not made, submission Quote is available and has no UW issues
            visible: canUserPayAndBindQuote
                && (!isPaymentDone || keepShowingIframeAfterPayment) && requiresPayment
                && (bookTransferIndicator === false || selectedPaymentOption === 'PayNow')
                && !hasUWIssues()
                && submissionVM.value.quoteType === 'Full'
                && !isSkipping
                && !fieldIssue,
            setKeepShowingIframeAfterPayment,
            setValidationErrors,
            disableAllSteps,
            LOB
        },
        signatureComponent: {
            submissionVM,
            updateWizardData,
            authHeader,
            LOB,
            onSignatureSuccess: setSignatureSuccess,
            disabled: signatureSuccess,
            readOnly: hasUWIssues(),
            producerCodeOptions,
            setProducerCodeOptions,
            setGettingSignatureDocument,
            // Only make it visible after payment ref number is available & canPurchase call is not in progress
            visible: (isPaymentDone || !requiresPayment || selectedPaymentOption === 'PayLater' || canUserIssueWithoutPayment) && !isCallingCanPurchase,
            showErrors: isPageSubmitted,
            wizardSnapshot,
            saveLocationCodeChanges: onSave,
            checkCanPurchase,
            locationCodeEditable: true
        },
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        insuredContactInfo: {
            showPaperless: true,
            updateIsInsuredComponentValid,
            showErrors: isPageSubmitted,
            setIsPaperlessEmailUpdated,
            setIsInsuredContactUpdating
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        // IAP-371 show TPI component in only read only Mode
        e1ptpiDisplayTableContainer: {
            transactionVM: submissionVM,
            viewModelService,
            authHeader,
            updateWizardData,
            setIsSavingTPI: () => { },
            showErrors: false,
            onValidate: () => { },
            disregardFieldValidationParentPage: () => { },
            viewOnlyMode: true,
            showTitle: false
        },
        quoteProposalFailureErrorDiv: {
            visible: isQuoteProposalFailed
        },
        propertyPolicyRequiredErrorDiv: {
            visible: shouldCheckForBoundPropertyPolicy && !isBoundPropertyPolicyAvailableAtAccount
        },
        paymentusAndSignatureContainer: {
            visible: !(shouldCheckForBoundPropertyPolicy && !isBoundPropertyPolicyAvailableAtAccount)
        },
        userNotAuthorisedErrorDiv: {
            visible: !canUserPayAndBindQuote
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            openTPIPageHandler,
            onValidate,
            onSaveClick: handleContactInfoUpdate
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps),
        [submissionVM, overrideProps]
    );

    /**
     * need to check payment reference Number and SignatureType, if ENV is not Localhost.
     * for localhost we are defaulting payment reference number
     */
    const isPageValid = useCallback(() => {
        if (shouldCheckForBoundPropertyPolicy && !isBoundPropertyPolicyAvailableAtAccount) {
            return false;
        }

        if (requiresPayment && !isPaymentDone && selectedPaymentOption !== 'PayLater' && !canUserIssueWithoutPayment) {
            return false;
        }

        const isSignatureDone = _get(submissionVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;

        // E1PAP1PC-12520
        if (ignoreValidation && isSignatureDone) {
            return true;
        }

        const licensedProducerSelected = _get(submissionVM, 'value.bindData.licensedProducerID', false);

        return isSignatureDone && !hasUWIssues() && licensedProducerSelected;
    }, [shouldCheckForBoundPropertyPolicy, isBoundPropertyPolicyAvailableAtAccount, requiresPayment,
        isPaymentDone, selectedPaymentOption, canUserIssueWithoutPayment, submissionVM, signatureSuccess, hasUWIssues]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, signatureSuccess, submissionVM]);


    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            nextLabel={messages.issueLabel}
            onNext={onNext}
            disablePrevious={(isPaymentDone && requiresPayment) || gettingSignatureDocument || isInsuredContactUpdating}
            disableNext={gettingSignatureDocument || isInsuredContactUpdating}
            showNext={canUserPayAndBindQuote}
            showCancel={!isSignatureStatusAsCompleted}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && (!isMailingComponentValid || !isBillingComponentValid || !isInsuredComponentValid)
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PaymentDetailsPage.propTypes = wizardProps;
export default PaymentDetailsPage;
