import { defineMessages } from 'react-intl';

export default defineMessages({
    fixClassId: {
        id: 'quoteandbind.ea.AddCreditFix.Credit Report Fix',
        defaultMessage: 'Credit Report Fix'
    },
    fixReportDateId: {
        id: 'quoteandbind.ea.AddCreditFix.Report Date',
        defaultMessage: 'Report Date'
    },
    fixScoreId: {
        id: 'quoteandbind.ea.AddCreditFix.Score',
        defaultMessage: 'Score'
    },
    fixStatusId: {
        id: 'quoteandbind.ea.AddCreditFix.Status',
        defaultMessage: 'Status'
    },
    fixCreditKey: {
        id: 'quoteandbind.ea.AddCreditFix.Credit Key',
        defaultMessage: 'Credit Key'
    }
});
