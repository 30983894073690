import React, {
    useCallback,
} from 'react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ehCommonMessages } from 'e1p-platform-translations';
import metadata from '../PropertyPage.metadata.json5';
import styles from '../PropertyPage.module.scss';


function PropertyPage(props) {
    const translator = useTranslator();
    
    const { wizardData: submissionVM } = props;


    const onNext = useCallback(
        () => submissionVM,
        [submissionVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            recalculateReplacementCostClick: undefined,
            onValidate: undefined,
        },
        resolveClassNameMap: styles
    };


    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        propertyPageLoader: {
            loaded: true,
            loadingMessage: translator(ehCommonMessages.loadingPrefill)
        },
        replacementCostRecalculate: {
            visible: false
        },
        roofAgeMessageDiv: {
            visible: false
        },
        replacementCostMissingNotificationDiv: {
            visible: false
        },
        noOutstandingChangesNotificationDiv: {
            visible: false
        },
        partialPrefillNotificationDiv: {
            visible: false
        },
        propertyPrefillUnsuccessfulInfoMessageDiv: {
            visible: false
        },
        covAWillBeUpdatedBasedOnReplacementCostInfoMessageDiv: {
            visible: false
        },
        e1pEHHF9PropertyDetailsComponent: {
            transactionVM: submissionVM,
            updateWizardData: () => {},
            isPageSubmitted: false,
            heatingSystemType: () => {},
            updateHeatingSystemType: () => {},
            coolingSystemType: () => {},
            updateCoolingSystemType: () => {},
            setIsReplacementCostStale: false,
            creditScoreMoreThan597: false,
            viewOnlyMode: true,
        }
        
    };

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
