import React, { Component } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { CreditCardUtil } from '@xengage/gw-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import PaymentStyles from './PaymentComponent.module.scss';
import metadata from './PaymentComponent.metadata.json5';
import messages from './PaymentComponent.messages';

class PaymentComponent extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        model: PropTypes.shape({
            paymentMethod: PropTypes.string,
            bankAccountData: PropTypes.shape({}),
            creditCardData: PropTypes.shape({})
        }),
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        onCallback: PropTypes.func.isRequired,
        xCenter: PropTypes.string.isRequired,
        title: PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }),
        hideTitle: PropTypes.bool,
        onCancel: PropTypes.func.isRequired,
        nextLabel: PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }),
        previousLabel: PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }),
        isSetupPayment: PropTypes.bool,
        isCustomFooter: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isSelectedInvoiceAmount: PropTypes.bool,
        ...validationPropTypes
    };

    static defaultProps = {
        model: undefined,
        title: undefined,
        hideTitle: false,
        nextLabel: undefined,
        previousLabel: undefined,
        isSetupPayment: false,
        isCustomFooter: false,
        isDisabled: false,
        isSelectedInvoiceAmount: false
    }

    state = {
        paymentMethod: '',
        accountCreditCardVM: {},
        accountBankDetailVM: {},
        showValidationErrors: false
    };

    componentDidMount = () => {
        let { accountCreditCardVM, accountBankDetailVM } = this.state;
        const { viewModelService, xCenter, model } = this.props;
        const initValue = {
            paymentMethod: '',
            bankAccountData: {},
            creditCardData: {}
        };

        const mergedModel = Object.assign({}, initValue, model);
        const { creditCardData: cardDetailsModel } = mergedModel;
        const { bankAccountData: bankDetailsModel } = mergedModel;
        let { paymentMethod } = mergedModel;
        if (Object.keys(cardDetailsModel).length > 0) {
            paymentMethod = 'creditcard';
        } else {
            paymentMethod = 'wire';
        }
        accountCreditCardVM = viewModelService.create(
            cardDetailsModel,
            xCenter,
            'edge.capabilities.billing.dto.AccountCreditCardDTO'
        );
        accountBankDetailVM = viewModelService.create(
            bankDetailsModel,
            xCenter,
            'edge.capabilities.billing.dto.AccountBankDetailsDTO'
        );
        this.setState({
            paymentMethod,
            accountCreditCardVM,
            accountBankDetailVM
        });
    };

    isModelValid = (viewModel) => {
        return viewModel.aspects
            ? viewModel.aspects.valid && viewModel.aspects.subtreeValid
            : false;
    };

    handlePaymentOptions = (data) => {
        this.setState({
            paymentMethod: data,
            showValidationErrors: false
        });
    };

    writeValue = (value, path) => {
        const { paymentMethod, accountCreditCardVM, accountBankDetailVM } = this.state;
        if (paymentMethod === 'wire') {
            _.set(accountBankDetailVM, path, value);
            this.setState({ accountBankDetailVM });
        } else {
            _.set(accountCreditCardVM, path, value);
            this.setState({ accountCreditCardVM });
        }
    };

    payNowClicked = () => {
        const { onCallback } = this.props;
        const { paymentMethod, accountCreditCardVM, accountBankDetailVM } = this.state;
        const paymentVM = paymentMethod === 'wire' ? accountBankDetailVM : accountCreditCardVM;
        const isComponentValid = this.isModelValid(paymentVM);

        if (!isComponentValid) {
            this.setState({
                showValidationErrors: true
            });
            return;
        }

        if (onCallback) {
            onCallback(paymentVM, paymentMethod);
        }
    };

    render() {
        const translator = this.context;
        const {
            paymentMethod,
            accountCreditCardVM,
            accountBankDetailVM,
            showValidationErrors
        } = this.state;
        const paymentVM = paymentMethod === 'wire' ? accountBankDetailVM : accountCreditCardVM;
        const {
            title, nextLabel, previousLabel, onCancel, isCustomFooter, isDisabled,
            isSetupPayment, hideTitle, setComponentValidation, isSelectedInvoiceAmount
        } = this.props;

        const isComponentValid = this.isModelValid(paymentVM);
        const creditCardIssuer = _.get(accountCreditCardVM.value, 'creditCardIssuer', undefined);
        
        const overrides = {
            '@field': {
                phoneWide: {
                    labelPosition: 'top'
                }
            },
            automaticPaymentMethod: {
                visible: isSetupPayment,
                value: translator(messages.bankAccountSource)
            },
            paymentOptions: {
                value: paymentMethod,
                labelPosition: 'left',
                visible: !isSetupPayment
            },
            bankAccountContainer: {
                visible: paymentMethod === 'wire'
            },
            creditCardNumber: {
                mask: CreditCardUtil.getInputMaskForIssuerCode(creditCardIssuer)
            },
            creditCardContainer: {
                visible: paymentMethod === 'creditcard'
            },
            payNow: {
                onClick: this.payNowClicked,
                content: translator(nextLabel) || translator(messages.paymentComponentBuyNow),
                disabled: isDisabled || isSelectedInvoiceAmount || !isComponentValid
            },
            paymentOptionsTitle: {
                content: translator(title) || translator(messages.paymentMethod),
                visible: !hideTitle
            },
            cancel: {
                content: translator(previousLabel) || translator(messages.cancel)
            },
            buttonContainer: {
                className: cx(PaymentStyles.paymentButtonFooter, {
                    [PaymentStyles.paymentCustomFooter]: isCustomFooter
                })
            }
        };

        const resolvers = {
            resolveClassNameMap: PaymentStyles,
            resolveCallbackMap: {
                handlePaymentOptions: this.handlePaymentOptions,
                onCancel: onCancel
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.componentContent, paymentVM, id, path, overrides);
        };

        return (
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={paymentVM}
                showErrors={showValidationErrors}
                overrideProps={overrides}
                resolveValue={readValue}
                onValidationChange={setComponentValidation}
                onValueChange={this.writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    }
}

export default withValidation(withViewModelService(PaymentComponent));
