import React, { useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    get as _get
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { usePriorCarrierUtil } from 'e1p-capability-hooks';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import metadata from './SniPriorPolicyGridComponent.metadata.json5';
import styles from './SniPriorPolicyGridComponent.module.scss';
import messages from './SniPriorPolicyGridComponent.messages';

/**
 * @purpose SniPriorPolicyGridComponent is used to show only prefill prior policies
 * for SNI, integrationID is used to filter the SNI policies from whole prior Policies Array
 *
 * For manual addition of prior policies, PriorPolicyManualGridComponent is being used.
 *
 * ParentComponent: EAPriorCarrierComponent
 *
 * TODO: The name and usage should be update so it is clear what is what
 * @param {Object} props
 * @returns {JSX}
 */
function SniPriorPolicyGridComponent(props) {
    const {
        data: priorPoliciesVMList,
        secondaryNamedInsured,
        labelPosition,
        path,
        id,
        onValidate,
        onValueChange,
        viewOnlyMode,
        sniCoverageLapse,
        policyStartDate,
        hasSNILapsed,
        setPriorCarrierChanged,
        authUserData,
        priorPolicyUpdatesVM,
        policyState,
        coverageInforceIndChange
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        disregardFieldValidation
    } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        removePriorPolicy,
        getLatestPriorPolicy,
        createPriorPolicyVM
    } = usePriorCarrierUtil(
        priorPoliciesVMList,
        viewModelService,
        onValueChange,
        path,
        disregardFieldValidation,
        messages
    );

    const hasViewRiskCoverageLapsePermission = authUserData?.permissions_Ext.includes('viewriskcoveragelapse');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;

        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, path]);

    const generateOverrides = useCallback(
        () => {
            const overrideProps = {};
            const integrationId = _get(secondaryNamedInsured, 'integrationId.value');
            // Find latest sni prior policy from prefill
            const latestSniPolicyFromPrefill = getLatestPriorPolicy(integrationId);

            priorPoliciesVMList.value.forEach((priorPolicy, index) => {
                if (_get(secondaryNamedInsured, 'value')) {
                    /**
                    * SNI and PNI are using the same list of prior policies
                    * But visually we are seperating into two sections
                    * based on the contact ID so we will only
                    * show the SNI prior policies in this GRID
                    */
                    overrideProps[`secondaryNamedPriorPolicyID${index}`] = {
                        visible: priorPolicy.integrationId === integrationId && _get(
                            priorPolicy, 'policySource'
                        ) === 'Prefill',
                        viewOnlyMode,
                        policyStartDate,
                        checkLapsed: hasSNILapsed,
                        priorPolicyUpdatesVM,
                        setPriorCarrierChanged,
                        isLatestPolicy: latestSniPolicyFromPrefill === priorPolicy,
                        policyState,
                        handleDispute: (value) => {
                            if (value) {
                                createPriorPolicyVM(integrationId);
                                setPriorCarrierChanged(true);
                            } else {
                                coverageInforceIndChange(value);
                            }
                        }
                    };
                    overrideProps[`secondaryNamedPriorPolicyContainer${index}`] = {
                        visible: priorPolicy.integrationId === integrationId && _get(
                            priorPolicy, 'policySource'
                        ) === 'Prefill'
                    };
                    // Prior carriers from prefill cannot be deleted. All prior policies that are
                    //   manual should be going to SniManualPriorPolicyGridComponent anyway so there
                    //   shouldn't be a delete button in this file at all but keeping it here to
                    //   be safe, for now.
                    //   E1PAP1PC-10981
                    overrideProps[`secondaryNamedEaDeletePriorPolicy${index}`] = {
                        visible: priorPolicy.integrationId === integrationId
                            && !viewOnlyMode && _get(priorPolicy, 'policySource') === 'ManuallyAdded',
                        onClick: (evt) => {
                            removePriorPolicy(evt);
                            setPriorCarrierChanged(true);
                        }
                    };
                }
            });

            return overrideProps;
        },
        [secondaryNamedInsured, getLatestPriorPolicy, priorPoliciesVMList, viewOnlyMode, policyStartDate, hasSNILapsed,
            priorPolicyUpdatesVM, setPriorCarrierChanged, policyState, coverageInforceIndChange, createPriorPolicyVM, removePriorPolicy],
    );

    const isVendorProvidedEmpty = () => {
        const integrationId = _get(secondaryNamedInsured, 'integrationId.value');

        return !priorPoliciesVMList.children.some((priorPolicy) => (priorPolicy.integrationId.value === integrationId
            && priorPolicy.policySource.value.code === 'Prefill'));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition,
            showRequired: true,
            autoComplete: false
        },
        sniDisplayNameLabel: {
            readOnly: true,
            value: _get(secondaryNamedInsured, 'person.displayName.value')
        },
        secondaryNamedModifyPriorCarrierContainer: {
            disabled: viewOnlyMode,
            visible: !viewOnlyMode
        },
        secondaryNamedCoverageLapseReason: {
            readOnly: viewOnlyMode,
            visible: sniCoverageLapse || hasViewRiskCoverageLapsePermission
        },
        secondaryNamedPriorPolicyLabelContainer: {
            visible: !isVendorProvidedEmpty()
        },
        ...generateOverrides()
    };

    const readValue = useCallback((fieldId, fieldPath) => readViewModelValue(
        metadata.pageContent, priorPoliciesVMList, fieldId, fieldPath, overrideProps
    ), [overrideProps, priorPoliciesVMList]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRemovePriorPolicy: removePriorPolicy,
            onValidate,
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={priorPoliciesVMList}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

SniPriorPolicyGridComponent.propTypes = {
    data: PropTypes.shape({}),
    policyStartDate: PropTypes.shape({}),
    secondaryNamedInsured: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string,
    sniCoverageLapse: PropTypes.bool.isRequired,
    viewOnlyMode: PropTypes.bool,
    hasSNILapsed: PropTypes.func.isRequired,
    setPriorCarrierChanged: PropTypes.func,
    authUserData: PropTypes.shape({}),
    priorPolicyUpdatesVM: PropTypes.shape({}),
    policyState: PropTypes.string.isRequired
};
SniPriorPolicyGridComponent.defaultProps = {
    data: {},
    policyStartDate: {},
    secondaryNamedInsured: {},
    labelPosition: 'top',
    path: undefined,
    id: undefined,
    viewOnlyMode: false,
    authUserData: undefined,
    priorPolicyUpdatesVM: {},
    setPriorCarrierChanged: undefined
};
export default withAuthenticationContext(SniPriorPolicyGridComponent);
