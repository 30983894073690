import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicleLevelCoverages: {
        id: 'EaSideBySideVehicleLevelComponent.component.Vehicle Level Coverages',
        defaultMessage: 'Vehicle Level Coverages'
    },
    notApplicable: {
        id: 'EaSideBySideVehicleLevelComponent.component.Not applicable',
        defaultMessage: 'Not applicable'
    }
});
