/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { CurrencyValue } from '@jutro/components';
import { Grid, GridItem } from '@jutro/layout';
import listOfCoveragesReferenceCode from 'e1p-portals-util-js/EHWhatsIncluded-config.json';
import { SymbolCurrencyComponent, E1PLoader } from 'e1p-capability-policyjob-react';
import styles from './EHClauseComponent.module.scss';

import { CheckboxField } from '@jutro/legacy/components';

import { Link } from '@jutro/router';

class ClauseComponent extends Component {
    /**
     * @memberof gw-components-platform-react.ClauseComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.displayName - name of clause to display
     * @prop {bool} propTypes.readOnly - if the clause selection is read only
     * @prop {bool} propTypes.value - is the clause is seleceted
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {bool} propTypes.isLoading - should the clause be loading
     * @prop {string} propTypes.loadingMessage - message to be shown while loading
     * @prop {string} propTypes.containerClassName - clause container class
     */

    static propTypes = {
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        readOnly: PropTypes.bool,
        onValueChange: PropTypes.func.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape({})),
        value: PropTypes.bool,
        path: PropTypes.string,
        isLoading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        checked: PropTypes.bool,
        isEditable: PropTypes.bool,
        isMandatoryCoverages: PropTypes.bool,
        containerClassName: PropTypes.string,
        labelPosition: PropTypes.string,
        isDwellingCovA: PropTypes.bool,
        amount: PropTypes.shape({}),
        replacementCost: PropTypes.shape({}),
        referenceCode: PropTypes.string,
        schedulesMap: PropTypes.shape({}),
        policyType: PropTypes.string,
        jumpTo: PropTypes.func,
        steps: PropTypes.arrayOf(PropTypes.shape({})),
        viewOnlyMode: PropTypes.bool,
        hasGoldOrPlatinumBoxVisible: PropTypes.bool,
        policyState: PropTypes.string,
        isQuoteStale: PropTypes.bool
    }

    static defaultProps = {
        readOnly: false,
        path: undefined,
        value: undefined,
        isLoading: false,
        loadingMessage: '',
        checked: false,
        children: undefined,
        isEditable: true,
        containerClassName: undefined,
        labelPosition: 'left',
        amount: undefined,
        isMandatoryCoverages: false,
        replacementCost: undefined,
        isDwellingCovA: true,
        jumpTo: () => { },
        steps: [],
        referenceCode: '',
        schedulesMap: new Map(),
        policyType: '',
        viewOnlyMode: false,
        isQuoteStale: false
    }

    handleChange = (value) => {
        const { onValueChange, path } = this.props;

        if (onValueChange) {
            onValueChange(value, path);
        }
    }

    renderEditableValue() {
        const {
            displayName,
            readOnly,
            value,
            id,
            isLoading,
            loadingMessage,
            children,
            path,
            checked,
            containerClassName,
            labelPosition,
            replacementCost,
            isDwellingCovA,
            amount,
            isMandatoryCoverages,
            schedulesMap,
            referenceCode,
            policyType,
            steps,
            jumpTo,
            viewOnlyMode,
            hasGoldOrPlatinumBoxVisible,
            policyState,
            isQuoteStale
        } = this.props;

        const goToPropertyPage = () => {
            const indexToUnderwritingPage = _.findIndex(
                steps, (step) => step.path === '/property'
            );

            jumpTo(indexToUnderwritingPage);
        };
        const hasChildren = !_.isNil(children);
        const hasChildrenWithForSelectedCov = hasChildren && value;
        const hasSchedulesWithForSelectedCov = value && schedulesMap.get(referenceCode);
        let clauseContainerStyles = classNames(styles.changeEHClauseOptional, containerClassName);

        if(isMandatoryCoverages){
            clauseContainerStyles = classNames(styles.changeEHClause, containerClassName);
        }

        const checkboxControlStyles = classNames({
            [styles.clauseAndTermSpacing]: hasChildren
        });
        const checkboxStyles = classNames({
            [styles.clauseNoPadding]: !hasChildren
        });
        // showing discount icon with each coverage if condition gets satisfied.
        const checkboxLabelStyles = () => {
            if (!hasGoldOrPlatinumBoxVisible && (policyType === 'HO3' || policyType === 'HF9')) {
                const showDiscountIcon = listOfCoveragesReferenceCode[policyState]?.includes(referenceCode);
                const styleClass = classNames({
                    [styles.appendIcon]: showDiscountIcon
                });

                return styleClass;
            }

            return "";
        };

        let displayCheckboxAndChildren = (
            <Grid
                columns={[
                    '1fr',
                    '1fr',
                    '1fr'
                ]}
                gap="medium"
                id="Grid"
                rows={[
                    '1fr'
                ]}
                tag="div"
            >
                <GridItem
                    className="jut__Containers__highlightHover"
                    tag="div"
                    colSpan="1"
                >
                    <React.Fragment>
                        <CheckboxField
                            id={id}
                            label={displayName}
                            value={value}
                            readOnly={readOnly}
                            onValueChange={this.handleChange}
                            className={checkboxStyles}
                            controlClassName={checkboxControlStyles}
                            labelClassName={checkboxLabelStyles()}
                            path={path}
                            checked={checked}
                            labelPosition={labelPosition}
                            showInlineLabel
                            layout="full-width"
                        />
                        {(isDwellingCovA && (policyType === 'HO3' || policyType === 'HF9'))
                            ? (
                                <Link
                                    iconPosition="left"
                                    id="button"
                                    type="outlined"
                                    className={styles.editCoverages}
                                    onClick={() => goToPropertyPage()}
                                    disabled={viewOnlyMode}
                                >
                                    Replacement Cost: &nbsp;
                                    <SymbolCurrencyComponent
                                        value={replacementCost}
                                    />
                                </Link>
                            ) : null}

                    </React.Fragment>
                </GridItem>
                <GridItem
                    className="jut__Containers__highlightHover"
                    tag="div"
                >
                    {value ? children : null}
                </GridItem>

                {(!isQuoteStale) ? (
                    <GridItem
                        className={styles.premium}
                        tag="div"
                    >
                        <SymbolCurrencyComponent
                            value={amount}
                        />
                    </GridItem>
                )
                    : (
                        <GridItem
                            className={styles.premium}
                            tag="div"
                        >
                            <span>-</span>
                        </GridItem>
                    )
                }

            </Grid>

        );

        if (!isMandatoryCoverages) {
            displayCheckboxAndChildren = (
                    <Grid
                        columns={[
                            '2.25fr',
                            '1fr'
                        ]}
                        gap="medium"
                        id="Grid"
                        rows={[
                            '1fr'
                        ]}
                        tag="div"
                    >
                        <GridItem
                            className="jut__Containers__highlightHover"
                            tag="div"
                            colSpan="1"
                        >
                            <CheckboxField
                                id={id}
                                label={displayName}
                                value={value}
                                readOnly={readOnly}
                                onValueChange={this.handleChange}
                                className={checkboxStyles}
                                controlClassName={checkboxControlStyles}
                                labelClassName={checkboxLabelStyles()}
                                path={path}
                                checked={checked}
                                labelPosition={labelPosition}
                                showInlineLabel
                                layout="full-width"
                            />
                        </GridItem>
                        {(hasChildrenWithForSelectedCov) ? (
                            <GridItem
                                    className="jut__Containers__highlightHover"
                                    tag="div"
                                    colSpan="1"
                                >
                                    {value ? children : null}
                                </GridItem>
                        ) : null}

                        {(hasSchedulesWithForSelectedCov) ? (
                            <GridItem
                                    className={styles.schedule}
                                    tag="div"
                                    colSpan="2"
                                >
                                    <div>
                                        {schedulesMap.get(referenceCode)}
                                    </div>
                                </GridItem>
                        ) : null}
                    </Grid>
            );
        }

        return (
            <div className={clauseContainerStyles}>
                {isLoading
                    ? (
                        <E1PLoader
                            loaded={!isLoading}
                            text={loadingMessage}
                        />
                    ) : (
                        displayCheckboxAndChildren
                    )
                }
            </div>
        );
    }

    renderReadOnlyValue() {
        const {
            displayName, children, amount
        } = this.props;

        return (
            <div className={styles.readOnlyGrid}>
                <span className={styles.readOnlyGridSlotLeft}>
                    {displayName}
                    <span className={styles.readOnlyGridAmount}>
                        {!_.isUndefined(amount) ? (
                            <CurrencyValue
                                amount={amount.amount}
                                currency={amount.currency}
                                showFractions
                            />
                        ) : undefined
                        }
                    </span>
                </span>
                <div className={styles.readOnlyGridSlotRight}>
                    {children}
                </div>
            </div>
        );
    }

    render() {
        const { isEditable } = this.props;

        return isEditable ? this.renderEditableValue() : this.renderReadOnlyValue();
    }
}

export default ClauseComponent;
