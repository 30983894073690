import React, {
    useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    get as _get,
    set as _set,
    pullAt as _pullAt,
    isEmpty as _isEmpty
} from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './E1PPremiumAdjustmentTableComponent.metadata.json5';

function E1PPremiumAdjustmentTableComponent(props) {
    const {
        transactionVM,
        lob,
        updateWizardData,
        onValidate,
        viewModelService,
        viewOnlyMode,
        id,
        showErrors
    } = props;

    const {
        isComponentValid,
        onValidate: setComponentValidation,
        disregardFieldValidation
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [transactionVM, onValidate, isComponentValid, id]);

    const writeValue = useCallback(
        (newVal, path) => {
            _set(transactionVM, `${path}.value`, newVal);
            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData]
    );

    const addPremiumAdjustment = useCallback(() => {
        const premiumAdjustmentVMList = _get(transactionVM, 'premiumAdjustments_Ext', []);

        if (_isEmpty(premiumAdjustmentVMList.value)) {
            _set(premiumAdjustmentVMList, 'value', []);
        }

        const premiumAdjustmentDTO = {
            vehicleID: undefined,
            amount: undefined,
            reason: undefined
        };
        const { _dtoName, _xCenter } = premiumAdjustmentVMList;
        const premiumAdjustmentVM = viewModelService.create(
            premiumAdjustmentDTO,
            _xCenter,
            _dtoName
        );

        premiumAdjustmentVMList.pushElement(premiumAdjustmentVM);
        _set(transactionVM, 'premiumAdjustments_Ext', premiumAdjustmentVMList);
        updateWizardData(transactionVM);
    }, [transactionVM, updateWizardData, viewModelService]);

    const removePremiumAdjustment = useCallback(
        (evt) => {
            const pathArray = evt.path.split('.');
            const pathIdentifier = pathArray.slice(-1);
            const [, premiumAdjustmentIndex] = /\[(\d+)\][^[]*$/.exec(pathIdentifier) || [];
            const premiumAdjustmentLastIndex = _get(
                transactionVM, 'premiumAdjustments_Ext.value', []
            ).length - 1;

            disregardFieldValidation(`e1pPremiumAdjustmentComponent${premiumAdjustmentIndex}`);
            disregardFieldValidation(`e1pPremiumAdjustmentComponent${premiumAdjustmentLastIndex}`);
            _pullAt(transactionVM.premiumAdjustments_Ext.value, premiumAdjustmentIndex);
            updateWizardData(transactionVM);
        },
        [transactionVM, disregardFieldValidation, updateWizardData]
    );
    const generateOverrides = useCallback(() => {
        const overrideProps = {};
        const premiumAdjustments = _get(transactionVM, 'premiumAdjustments_Ext', []);

        premiumAdjustments.forEach((policy, index) => {
            overrideProps[`e1pPremiumAdjustmentComponent${index}`] = {
                viewOnlyMode,
                lob,
                showErrors,
                onValidate: setComponentValidation,
                vehicles: _get(transactionVM, 'lobData.personalAuto_EA.coverables.vehicles.value', []),
                premiumAdjustmentRecords: _get(transactionVM, 'premiumAdjustments_Ext.value', [])
            };
            overrideProps[`premiumAdjustmentDelete${index}`] = {
                visible: !viewOnlyMode
            };
            overrideProps[`premiumAdjustmentsContainer${index}`] = {
                columns: lob === 'PersonalAuto_EA'
                    ? ['5fr', '0.25fr']
                    : ['3.5fr', '0.25fr', '2fr']
            };
        });

        return overrideProps;
    }, [lob, transactionVM, setComponentValidation, showErrors, viewOnlyMode]);

    const getAddPremiumAdjustmentButtonVisibility = useCallback(
        () => {
            const premiumAdjustmentRecords = _get(transactionVM, 'premiumAdjustments_Ext.value', []);

            if (lob === 'PersonalAuto_EA') {
                const vehicles = _get(transactionVM, 'lobData.personalAuto_EA.coverables.vehicles.value', []);

                return (vehicles.length > premiumAdjustmentRecords.length) && !viewOnlyMode;
            }

            return _isEmpty(premiumAdjustmentRecords) && !viewOnlyMode;
        }, [lob, transactionVM, viewOnlyMode]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        addPremiumAdjustmentDiv: {
            visible: getAddPremiumAdjustmentButtonVisibility()
        },
        premiumAdjustmentsIterable: {
            visible: !_isEmpty(_get(transactionVM, 'premiumAdjustments_Ext.value', []))
        },
        noPremiumAdjustmentAddedText: {
            visible: viewOnlyMode
                && _isEmpty(_get(transactionVM, 'premiumAdjustments_Ext.value', []))
        },
        vehicle: {
            visible: lob === 'PersonalAuto_EA',
        },
        premiumAdjustmentLabelContainer: {
            columns: lob === 'PersonalAuto_EA'
                ? ['1.5fr', '1.5fr', '2fr']
                : ['1.5fr', '2fr', '2fr']
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation,
            onAddPremiumAdjustment: addPremiumAdjustment,
            onRemovePremiumAdjustment: removePremiumAdjustment
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={writeValue}
            model={transactionVM}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

E1PPremiumAdjustmentTableComponent.propTypes = {
    transactionVM: PropTypes.shape({}).isRequired,
    lob: PropTypes.string.isRequired,
    updateWizardData: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        create: PropTypes.func.isRequired
    }).isRequired,
    viewOnlyMode: PropTypes.bool,
    id: PropTypes.string.isRequired,
    showErrors: PropTypes.bool,
};

E1PPremiumAdjustmentTableComponent.defaultProps = {
    viewOnlyMode: false,
    showErrors: false,
};

export default E1PPremiumAdjustmentTableComponent;
