/* eslint-disable camelcase */
import React, { Component } from 'react';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { Grid, GridItem } from '@jutro/layout';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { JobUtil, DatatableUtil } from '@xengage/gw-portals-util-js';
import { E1PProductImageComponent, CopyQuoteComponent } from 'e1p-capability-policyjob-react';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './CompletedTransactions.metadata.json5';
import styles from './CompletedTransactions.module.scss';
import summaryStyles from '../Summary/Summary.module.scss';
import messages from '../Accounts.messages';
import { Link } from '@jutro/router';

const getFormattedCurrency = (premiumAmount, index) => (
        <CurrencyField
            id={`currency${index}`}
            value={premiumAmount}
            readOnly
            hideLabel
            showOptional={false}
        />
    );

function getClosedCell(items, index, property) {
    return items[property.id];
}

;

function sortCurrency(value1, value2) {
    const amount1 = _.get(value1, 'props.value.amount', undefined);
    const amount2 = _.get(value2, 'props.value.amount', undefined);

    if (amount1 === undefined) {
        return -1;
    }

    if (amount2 === undefined) {
        return 1;
    }

    return amount1 - amount2;
}

;

class AccountCompletedTransactions extends Component {
    static propTypes = {
        transactionsResponse: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        policyTransactionStatusValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        intl: PropTypes.shape({}).isRequired,
        // typeOfTransactions used to determine what types of transaction we are showing in this component
        // it could be either of ['COMPLETED', 'EXPIRED_AND_WITHDRAWN']
        typeOfTransactions: PropTypes.string.isRequired,
        setCopyQuoteMessage: PropTypes.func,
        refreshOpenTransactions: PropTypes.func,
    };

    static contextType = TranslatorContext;

    state = {
        // this will only be updated once from api load
        completedTransactionArray: [],
        // this array will be the data after filters/search applied
        //   updated frequently and used to trigger the re-render
        refinedTransactionArray: [],
        selectedTransactionStatus: 'all',
        searchJobNumberString: ''
    };

    componentDidMount() {
        const { transactionsResponse } = this.props;

        this.updateCompletedTransactions(transactionsResponse);
    };

    componentDidUpdate(prevProps) {
        const {
            transactionsResponse
        } = this.props;
        const isEqual = _.isEqual(prevProps.transactionsResponse, transactionsResponse);

        if (!isEqual) {
            this.updateCompletedTransactions(transactionsResponse);
        }
    }

    updateCompletedTransactions = (transactionArray) => {
        const { selectedTransactionStatus, searchJobNumberString } = this.state;

        this.setState({
            completedTransactionArray: transactionArray
        },
        () => {
            this.setTransactionData(transactionArray, selectedTransactionStatus, searchJobNumberString);
        });
    }

    setTransactionData = async (transactionArray, filter, jobNumberString) => {
        const { intl } = this.props;
        const transactions = [...transactionArray];

        let mappedData = transactions.map(
            (transDetails, index) => ({
                    // first mapping from the api will have the code nested
                    productCode: transDetails.productCode || transDetails.product.productCode,
                    effectiveDate: transDetails.effectiveDate || intl.formatDate(
                        new Date(transDetails.jobEffectiveDate_Ext),
                        {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            timeZone: 'UTC'
                        }
                    ),
                    expirationDate: transDetails.expirationDate || intl.formatDate(
                        new Date(transDetails.jobExpirationDate_Ext),
                        {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            timeZone: 'UTC'
                        }
                    ),
                    type: transDetails.type,
                    status: transDetails.status,
                    jobNumber: transDetails.jobNumber,
                    // changed key from response, so different after mount
                    insuredName: transDetails.insuredName || transDetails.accountHolderName,
                    // already formatted post mount
                    premium: transDetails.premium || getFormattedCurrency(
                        transDetails.totalPremium,
                        index
                    )
                })
        );

        if (filter && filter !== 'all') {
            mappedData = mappedData.filter((transaction) => transaction.status === filter);
        }

        if (jobNumberString) {
            const lowerCaseFilterValue = jobNumberString.toLocaleLowerCase();

            mappedData = _.filter(mappedData, (res) => Object.keys(res).some(
                    (key) => typeof res[key] === 'string'
                        && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
                ));
        }

        // data used to render
        this.setState({
            refinedTransactionArray: mappedData
        });

    };

    handleFilterValueChange = (value) => {
        const { completedTransactionArray, searchJobNumberString } = this.state;

        this.setState({
            selectedTransactionStatus: value
        });
        this.setTransactionData(completedTransactionArray, value, searchJobNumberString);
    }

    handleSearchValueChange = (value) => {
        const { completedTransactionArray, selectedTransactionStatus } = this.state;

        this.setState({
            searchJobNumberString: value
        });
        this.setTransactionData(completedTransactionArray, selectedTransactionStatus, value);
    }

    getJobNumberLink = (item, index, property) => {
        const viewPolicyEnabledTransactions = [
            'Submission',
            'PolicyChange',
            'Rewrite',
            'Renewal'
        ];

        if (
            item.status === 'Bound' &&
            viewPolicyEnabledTransactions.includes(item.type)
        ) {
            return (
                <Link
                    href='/'
                    onClick={() => this.goToViewOnlyFlow(item)}
                    className={styles.link}
                >
                    {item[property.id]}
                </Link>
            );
        }

        return (
            <Link
                to={JobUtil.getJobDetailURLByJobType(item.type, item.jobNumber)}
                className={styles.link}
            >
                {item[property.id]}
            </Link>
        );
    };

    getProductImage = (item) => {
        const productDisplayName = {
            Homeowners_EH: 'Homeowners',
            PersonalAuto_EA: 'Auto',
            PersonalUmbrella_EU: 'Umbrella'
        }[item.productCode];

        return (
            <Grid columns={['1fr']} gap="small">
                <GridItem tag="div">
                    <div className={summaryStyles.iconClass}>
                        <E1PProductImageComponent
                            productCode={item.productCode}
                        />
                    </div>
                    <div className={summaryStyles.productLabel}>{productDisplayName}</div>
                </GridItem>
            </Grid>
        );
    };

    getCopyQuote = (item) => {
        if(item.type === 'Submission' && item.status !== 'In Force') {
            const { setCopyQuoteMessage, refreshOpenTransactions } = this.props;

            return (
                <CopyQuoteComponent
                    quoteNumber={item.jobNumber}
                    setCopyQuoteMessage={setCopyQuoteMessage}
                    refreshOpenTransactions={refreshOpenTransactions}
                />
            );
        }

        return <div/>;
    };

    sortProduct = (value1, value2) => {
        const productDisplayName = {
            Homeowners_EH: 'Homeowners',
            PersonalAuto_EA: 'Auto',
            PersonalUmbrella_EU: 'Umbrella'
        };

        return DatatableUtil.sortString(productDisplayName[value1], productDisplayName[value2]);
    }

    render() {
        const {
            refinedTransactionArray,
            selectedTransactionStatus,
            searchJobNumberString
        } = this.state;
        const { policyTransactionStatusValues, typeOfTransactions } = this.props;
        const translator = this.context;

        const overrides = {
            CompletedTransactionsTable: {
                data: refinedTransactionArray
            },
            policyTransactionStatus: {
                availableValues: policyTransactionStatusValues,
                value: selectedTransactionStatus
            },
            searchFilter: {
                value: searchJobNumberString
            },
            summaryPolicyTransactionsId: {
                content: (() => {
                    let displayHeading = {};

                    switch (typeOfTransactions) {
                        case 'COMPLETED':
                            displayHeading = messages.completedTransaction;
                            break;
                        case 'EXPIRED_AND_WITHDRAWN':
                            displayHeading = commonMessages.expiredAndWithdrawnTransaction;
                            break;
                        default:
                            displayHeading = messages.completedTransaction;
                            break;
                    }

                    return translator(displayHeading);
                })()
            },
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getJobNumberLink: this.getJobNumberLink,
                handleFilterValueChange: this.handleFilterValueChange,
                handleSearchValueChange: this.handleSearchValueChange,
                getProductImage: this.getProductImage,
                getClosedCell: getClosedCell,
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortCurrency: sortCurrency,
                sortProduct: this.sortProduct,
                getCopyQuote: this.getCopyQuote
            }
        };
        const CompletedTransactionPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;

        return (
            <div>{CompletedTransactionPage}</div>
        );
    }
}

export const AccountCompletedTransactionsComponent = AccountCompletedTransactions;
export default withIntl(withAuthenticationContext(AccountCompletedTransactions));
