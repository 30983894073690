import React, {
    useCallback, useContext, useEffect, useState, useMemo, useRef
} from 'react';
import {
    get,
    set,
    isEmpty,
    isUndefined,
    filter,
    sortBy,
    cloneDeep,
    isNil,
    noop,
    map,
    findIndex,
    find
} from 'lodash';
import config from 'app-config';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { withRouter } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useModal } from '@jutro/components';
import { EndorsementService } from 'e1p-capability-policychange';
import EATravelPackagesConfig from 'e1p-portals-util-js/EATravelPackages-config.json';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useLandingPageUtil, usePriorPolicyUpdateUtil, useModifierUtil } from 'e1p-capability-hooks';
import { PolicyChange } from 'gw-capability-policychange-common-react';
import { commonMessages as e1pCommonMessages, eaCommonMessages } from 'e1p-platform-translations';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import PolicyChangeUtil from 'e1p-portals-util-js/PolicyChangeUtil';
import { CoverageUtil, VehicleUtil, FeatureUtil, ClausesUtil as e1pClausesUtil } from 'e1p-portals-util-js';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import {
    PackageDifferenceComponent,
    ComprehensiveOnlyDescriptionComponent
} from 'e1p-capability-policyjob-react';
import metadata from './CoveragePage.metadata.json5';
import messages from './CoveragePage.messages';

const structureCustomQuote = (policyChangeVM, clauses) => 
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        coverages: clauses.personalAuto_EA
    })
;

const getCustomQuote = (vm, lobPath, lobName, filterChangedClauses = false) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages, lobName, null
        );
    }

    return structureCustomQuote(vm, clausesToUpdate);
};

const LOB = 'personalAuto_EA';

function CoveragePage(props) {
    const modalApi = useModal();
    const translator = useTranslator();
    const [loadingClause, updateLoadingClause] = useState();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        authHeader,
        steps,
        jumpTo,
        updateWizardSnapshot,
        authUserData,        
        e1pGoNext
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [columnData, setColumnData] = useState({});
    const [platinumSelectedState, setPlatinumSelected] = useState(false);
    const [platStateChanged, setPlatStateChanged] = useState(false);
    const [ADDSelectedBeforePlatinum, setADDSelectedBeforePremium] = useState(false);
    const [ADDClauseInCoverages, setADDClauseInCoverages] = useState(false);
    const [ADDSelectedState, setADDSelected] = useState(undefined);
    const viewModelService = useContext(ViewModelServiceContext);
    const paperLessIndValue = get(policyChangeVM, 'lobData.personalAuto_EA.paperlessInd.value') === undefined
        ? false : get(policyChangeVM, 'lobData.personalAuto_EA.paperlessInd.value');
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [selectedVehicleCoverages, setSelectedVehicleCoverages] = useState({});
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const policyState = get(policyChangeVM, 'baseData.policyAddress.state.value.code');
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [isQuoteStale, setIsQuoteStale] = useState(false);
    const [updatingLineLevelCoverages, setUpdatingLineLevelCoverages] = useState(false);
    const [updatingVehicleLevelCoverages, setUpdatingVehicleLevelCoverages] = useState(false);
    const isCompOnlyFeatureAvailable = FeatureUtil.isCompOnlyFeatureAvailableForState(policyState);
    const isSurchargeSectionVisible = FeatureUtil.isSurchargeSectionAvailableForState(policyState);
    const isTravelPackageVisible = FeatureUtil.isTravelPackageVisibleForState(policyState);

    const {
        isComponentValid,
        onValidate,
        initialValidation,
        registerComponentValidation,
        disregardFieldValidation,
        registerInitialComponentValidation
    } = useValidation('EACoveragePage');

    const navRef = useRef();

    const {
        modifyDates
    } = usePriorPolicyUpdateUtil(policyChangeVM.lobData.personalAuto_EA.priorPolicyUpdates);

    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    const {
        structuredModifiers
    } = useModifierUtil(policyChangeVM);
    const modifiers = structuredModifiers;

    const hasExtendedAttributeEditability = authUserData.permissions_Ext.includes('extendedattributeeditability_ext');

    const writeValue = useCallback(
        (value, path) => {
            set(policyChangeVM, path, value);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    const whatsIncludedHandler = useCallback(async () => {
        const componentProps = {
            lob: 'PersonalAuto_EA',
            policyState
        };
        const result = await modalApi.showModal(
            <PackageDifferenceComponent {...componentProps} />
        );

        return result;
    }, [modalApi, policyState]);

    const compOnlyDescriptionHandler = async () => {
        const result = await modalApi.showModal(
            <ComprehensiveOnlyDescriptionComponent />
        );

        return result;
    };

    const updatePlatinumADD = useCallback(() => {
        let tableContent = policyChangeVM.lobData.personalAuto_EA
            .offerings?.children[0].coverages?.vehicleCoverages?.value[0]?.coverages;
        const tableData = policyChangeVM.lobData.personalAuto_EA
            .offerings?.children[0].coverages?.vehicleCoverages;
        let platinumSelected = false;

        for (let i = 1; i < tableData.length; i += 1) {
            const vehicleCoverages = tableData.value[i].coverages;
            const platinumClause = vehicleCoverages.filter((cov) => cov.codeIdentifier === 'EA_PlatinumCoverageBundle');

            if (platinumClause[0]?.selected) {
                platinumSelected = true;
            }
        }

        if (!platinumSelected && platinumSelectedState) {
            setPlatinumSelected(platinumSelected);
            setPlatStateChanged(true);
        } else if (platinumSelected && !platinumSelectedState) {
            setPlatinumSelected(platinumSelected);
            setPlatStateChanged(false);
        }

        tableContent = policyChangeVM.lobData.personalAuto_EA.offerings?.children[0]?.coverages?.lineCoverages.value;

        const ADDClause = tableContent.filter((cov) => cov.codeIdentifier === 'EA_AccidentalDeathAndDismemberment_Line');

        // ADD is not in every product version; running below code will break things
        if (ADDClause.length === 0) {
            setADDClauseInCoverages(false);

            return undefined;
        }

        setADDClauseInCoverages(true);

        const ADDSelected = ADDClause[0]?.selected;

        if (ADDSelected && !platinumSelectedState && ADDSelectedState === false) {
            setADDSelected(ADDSelected);
            setADDSelectedBeforePremium(true);
        } else if (!ADDSelected && ADDSelectedState) {
            setADDSelected(ADDSelected);
        }

        if (ADDSelectedState === undefined) {
            setADDSelected(ADDSelected);
        }
    }, [ADDSelectedState, platinumSelectedState, policyChangeVM.lobData.personalAuto_EA.offerings]);

    const generateColumnData = () => {
        const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
        const lobOfferings = get(policyChangeVM, `${lobOfferingPath}.value`);
        let filteredOfferings = cloneDeep(lobOfferings);

        filteredOfferings = filteredOfferings.filter((offering) => offering.branchCode !== 'CUSTOM');

        const colData = filteredOfferings
            .map((lobOffering, lobIndex) => {
                // Added this condition to hide the vehicleCoverages from UI for which hidden
                // indicator is coming as true.
                const vehicleData = get(lobOffering, 'coverages.vehicleCoverages', []);

                vehicleData.forEach((coverage, index) => {
                    const filteredCoverages = coverage.coverages
                        .filter((cov) => !(cov.isHiddenInVersion));

                    set(lobOffering, `coverages.vehicleCoverages[${[index]}].coverages`, filteredCoverages);
                });

                return {
                    name: lobOffering.branchName,
                    code: lobOffering.branchCode,
                    quote: {
                        path: '',
                        data: {}
                    },
                    lob: {
                        path: `${lobOfferingPath}.children[${lobIndex}]`,
                        data: lobOffering
                    },
                    policyViewVM: policyChangeVM
                };
            });

        return sortBy(colData, ['code']);
    };


    useEffect(() => {
        const colData = generateColumnData();

        setColumnData(colData);
        updatePlatinumADD();
        // when the onNext is not called, because page is valid for riskAnalysis
        // we need to modify dates on coverage page
        modifyDates(policyChangeVM);
        // set isQuoteStale, when status is draft
        setIsQuoteStale(get(policyChangeVM, 'value.status') === 'Draft');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    /**
     *
     * @param {Obj} newPolicyChangeDTO policychange dto after clause update
     * @param {Obj} oldDTO policychange prior to server call
     */
    const setFieldsFromForm = (newPolicyChangeDTO, oldDTO) => {
        const isPaperLess = get(oldDTO, 'lobData.personalAuto_EA.paperlessInd', false);

        set(newPolicyChangeDTO, 'lobData.personalAuto_EA.paperlessInd', isPaperLess);

        const email = get(oldDTO, 'lobData.personalAuto_EA.paperlessEmail', undefined);

        set(newPolicyChangeDTO, 'lobData.personalAuto_EA.paperlessEmail', email);
    };

    const onUpdateCustomQuote = useCallback(
        async (_basePath, lobPath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(policyChangeVM, lobPath, lobName, false);
            const oldPolicyChangeVM = viewModelService.clone(policyChangeVM);
            const getRemovedClausesID = (newPolicyChangeVM, path) => ClausesUtil.getRemovedClausesID(
                oldPolicyChangeVM, newPolicyChangeVM, path
            );
            const updatedCoverages = await EndorsementService.updateCoverages(
                policyChangeVM.value.jobID,
                [],
                { personalAuto_EA: customQuote.coverages },
                authHeader
            );

            const changedPath = lobPath.replace(/.children/, '');

            // Need to persist entered data
            setFieldsFromForm(updatedCoverages, policyChangeVM.value);
            // Set model to server respone
            policyChangeVM.value = new PolicyChange(updatedCoverages);

            const updatedClauses = get(updatedCoverages, `${changedPath}.coverages`);

            set(policyChangeVM, `${lobPath}.coverages`, updatedClauses);

            const removedFieldsFromLineCoverages = getRemovedClausesID(policyChangeVM, `${lobPath}.coverages.lineCoverages`);
            const vehicleCoveragePath = `${lobPath}.coverages.vehicleCoverages`;
            const removedFieldsFromVehicleCoverages = get(policyChangeVM, `${vehicleCoveragePath}.value`)
                .flatMap((coverage, index) => getRemovedClausesID(policyChangeVM, `${vehicleCoveragePath}.children[${index}].coverages`));
            const allRemovedFields = [
                ...removedFieldsFromLineCoverages,
                ...removedFieldsFromVehicleCoverages
            ];

            disregardFieldValidation(allRemovedFields);
            updateWizardData(policyChangeVM);
            updateLoadingClause(undefined);
            setIsQuoteStale(true);

            return updatedCoverages;
        },
        [
            authHeader,
            disregardFieldValidation,
            policyChangeVM,
            updateWizardData,
            viewModelService
        ]
    );

    const onForceUpdateCoverages = useCallback(
        async () => {
            const updateOptions = {
                fullCoverageUpdate: true,
                useGenericValueAsString: true,
                shouldSyncCoveragesAfterUpdate: true,
                forceUpdate: true,
            }

            return Promise.resolve(EndorsementService.saveWithOptions(
                policyChangeVM.value,
                updateOptions,
                authHeader
            ).then((saveWithOptionsResponse) => {
                set(policyChangeVM, 'value', saveWithOptionsResponse);
                updateWizardData(policyChangeVM);
                updateLoadingClause(undefined);
                setIsQuoteStale(true);
            }));

        },
        [authHeader, policyChangeVM, updateWizardData]
    );

    const onClauseChange = useCallback((schedule, path) => {
        const lobOfferingPath = 'lobData.personalAuto_EA.offerings.children[0]';

        writeValue(schedule, path);

        return onUpdateCustomQuote({}, lobOfferingPath);
    }, [onUpdateCustomQuote, writeValue]);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            // IAP-2626 : SC Auto - When UM and/or UIMBI Rejected, PE needs to send an stacking indicator to PC
            CoverageUtil.changeStackingTermValueorUMBIAndUIMBICoverages(policyChangeVM, changedPath, value, updateWizardData);
            set(policyChangeVM, changedPath, value);
            e1pClausesUtil.setClauseValue(policyChangeVM, value, changedPath);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = get(policyChangeVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(policyChangeVM, 'lobData.personalAuto_EA.paperlessInd.value');
        const paperlessEmail = get(policyChangeVM, 'lobData.personalAuto_EA.paperlessEmail.value');

        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [policyChangeVM, isPaperlessEmailUpdated]);

    const syncClauses = useCallback(async (value, changedPath) => {
        const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
        const baseObject = get(policyChangeVM, basePath);

        updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
        await onClauseChange();
        updateLoadingClause(undefined);
        updateWizardData(policyChangeVM);
    }, [onClauseChange, policyChangeVM, updateWizardData]);

    const performChangeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);

            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const coverageListPath = ClausesUtil.getObjectPathFromChangedPath(basePath);
            const selectedVehicleCoverage = get(
                policyChangeVM, ClausesUtil.getObjectPathFromChangedPath(coverageListPath), {}
            );
            const vehicleIndex = findIndex(
                get(policyChangeVM, 'lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.value', {}),
                (vehicle) => vehicle.fixedId === selectedVehicleCoverage.fixedId
            );
            const selectedCoverage = get(policyChangeVM, basePath, {});

            const keys = Object.keys(EATravelPackagesConfig);

            // If selected coverage change is for travel package
            if (keys.includes(selectedCoverage.codeIdentifier)) {
                const coverages = get(policyChangeVM, coverageListPath, {});

                // Save corresponding selected values related to package
                if (value) {
                    const newSelectedVehicleCoverages = {};

                    EATravelPackagesConfig[selectedCoverage.codeIdentifier].forEach((key) => {
                        const coverage = find(coverages, (cov) => cov.codeIdentifier === key);

                        if (coverage) {
                            set(newSelectedVehicleCoverages, [vehicleIndex, key, 'selected'], coverage.selected);
                            set(newSelectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm'], get(coverage, 'terms[0].chosenTerm'));
                        }
                    });
                    setSelectedVehicleCoverages(
                        { selectedVehicleCoverages, ...newSelectedVehicleCoverages }
                    );

                    return syncClauses(value, changedPath);
                }

                // Sync new change and check for previously selected values
                return syncClauses(value, changedPath).then(() => {
                    let isChosenTerm = false;

                    Object.keys(get(selectedVehicleCoverages, vehicleIndex, {})).forEach((key) => {
                        const index = findIndex(coverages, (cov) => cov.codeIdentifier === key);
                        const selected = get(selectedVehicleCoverages, [vehicleIndex, key, 'selected'], false);
                        const chosenTerm = get(selectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm']);

                        // Make selection of previously selected items
                        if (index >= 0 && selected) {
                            changeSubmission(true, `lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[${vehicleIndex}].coverages.children[${index}].selected`);

                            // If there is any updated term (dropdown value) in previous value
                            if (chosenTerm) {
                                isChosenTerm = true;
                            }
                        }
                    });

                    // Update chosen Terms if exists
                    if (isChosenTerm) {
                        return syncClauses(value, changedPath).then(() => {
                            Object.keys(
                                get(selectedVehicleCoverages, vehicleIndex, {})
                            ).forEach((key) => {
                                const index = findIndex(coverages,
                                    (cov) => cov.codeIdentifier === key);
                                const selected = get(selectedVehicleCoverages, [vehicleIndex, key, 'selected'], false);
                                const chosenTerm = get(selectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm']);

                                if (index >= 0 && selected) {
                                    if (chosenTerm) {
                                        changeSubmission(chosenTerm, `lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[${vehicleIndex}].coverages.children[${index}].terms.children[0].chosenTerm`);
                                    }
                                }
                            });
                        });
                    }

                    // Remove previously selected values
                    const newSelectedVehicleCoverages = {};

                    set(newSelectedVehicleCoverages, [vehicleIndex], {});
                    setSelectedVehicleCoverages(
                        { selectedVehicleCoverages, ...newSelectedVehicleCoverages }
                    );

                    return syncClauses(value, changedPath);
                });
            }

            return syncClauses(value, changedPath);
        },
        [changeSubmission, policyChangeVM, selectedVehicleCoverages, syncClauses]
    );


    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            // vehicleName for the selected Index
            let selectedVehicleName = get(
                policyChangeVM, `${changedPath}._parent._parent._parent._parent._parent.vehicleName.value`
            );

            // If vehicleName not found
            if (!selectedVehicleName) {
                selectedVehicleName = get(
                    policyChangeVM, `${changedPath}._parent._parent._parent.vehicleName.value`
                );
            }

            const diminishingDeductibleArray = get(policyChangeVM, 'lobData.personalAuto_EA.diminishingDeductible.value');

            // find if there is a diminishingDeductible for secletedVehicleName
            const diminishingDeductibleFound = diminishingDeductibleArray.find(
                (dimValue) => dimValue.vehicleDisplayName === selectedVehicleName
            );
            // Check if there is a credit Accrued for the the corresponding vehicle
            const isCreditAccrued = diminishingDeductibleFound?.amount > 0;

            // When dimishing deductable is deselected
            const diminishingDeductibleRemoved = get(
                policyChangeVM, `${changedPath}._parent.codeIdentifier.value`
            ) === 'EA_DiminishingDeductibleVeh' && value === false;

            const isComprehensiveDeleted = get(
                policyChangeVM, `${changedPath}._parent.codeIdentifier.value`
            ) === 'EA_Comprehensive' && !value;

            const isCollisionDeleted = get(
                policyChangeVM, `${changedPath}._parent.codeIdentifier.value`
            ) === 'EA_Collision' && !value;

            // When Comprehensive or Collision is reduced below 500
            const isComprehensiveChanged = get(
                policyChangeVM, `${changedPath}._parent.patternCodeIdentifier.value`
            ) === 'EA_Comprehensive_Deductible' && value < 500;

            const isCollisionChanged = get(
                policyChangeVM, `${changedPath}._parent.patternCodeIdentifier.value`
            ) === 'EA_Collision_Deductible' && value < 500;

            // Display confirmation pop up only if there is a creditAccrued
            if (isCreditAccrued && (diminishingDeductibleRemoved
                || isComprehensiveChanged || isCollisionChanged
                || isComprehensiveDeleted || isCollisionDeleted)) {
                return Promise.resolve(modalApi.showConfirm({
                    title: eaCommonMessages.diminishingDeductibleCreditTitle,
                    message: eaCommonMessages.diminishingDeductibleCreditInfoMessage,
                    confirmButtonText: eaCommonMessages.removeCredit,
                    cancelButtonText: e1pCommonMessages.cancel
                }).then((result) => {
                    if (result === 'cancel') {
                        return noop();
                    }

                    return Promise.resolve(performChangeSubmissionAndSync(value, changedPath).then(() => {
                        updatePlatinumADD();
                    }));
                }, noop));
            }

            return Promise.resolve(performChangeSubmissionAndSync(value, changedPath).then(() => {
                updatePlatinumADD();
            }));
        }, [modalApi, performChangeSubmissionAndSync, policyChangeVM, updatePlatinumADD]
    );

    const updatePaperlessIndAndEmail = (value, path) => {
        const newPath = path.split('policyChangeVM.')[1];

        set(policyChangeVM, newPath, value);

        switch (newPath) {
            case 'lobData.personalAuto_EA.paperlessInd':
                if (value) {
                    const pniEmail = get(policyChangeVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value');

                    if (get(policyChangeVM, 'lobData.personalAuto_EA.paperlessEmail.value') === undefined) {
                        set(policyChangeVM, 'lobData.personalAuto_EA.paperlessEmail.value', pniEmail);
                    }
                } else {
                    set(policyChangeVM, 'lobData.personalAuto_EA.paperlessEmail.value', undefined);
                }

                break;
            default:
                break;
        }

        setIsPaperlessEmailUpdated(true);
        updateWizardData(policyChangeVM);
    };

    const getTotalPremium = useCallback(() => {
        const payPlans = get(columnData[0], 'lob.data.paymentPlans');
        const selectedPlan = filter(payPlans, (plan) => plan.isSelected);
        const totalPremium = selectedPlan[0]?.total?.amount;

        if ((!totalPremium && totalPremium !== 0) || isQuoteStale) {
            return undefined;
        }

        return { currency: 'usd', amount: totalPremium };
    }, [columnData, isQuoteStale]);

    const saveAndQuote = useCallback(
        async (calledFromOnSaveOrCustom = false) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            updateIsPageSubmitted(false);

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (!get(policyChangeVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value')
                && !!get(policyChangeVM, 'lobData.personalAuto_EA.paperlessEmail.value')) {
                set(policyChangeVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value',
                    get(policyChangeVM, 'lobData.personalAuto_EA.paperlessEmail.value'));
            }

            let quoteEndorsement;

            try {
                setIsSavingEndorsement(true);
                quoteEndorsement = await EndorsementService.saveAndQuoteEndorsement(
                    [(policyChangeVM.value)],
                    authHeader
                );
            } catch {
                setIsSavingEndorsement(false);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: messages.saveCoverageError,
                    message: messages.saveCoverageErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });
            }

            PolicyChangeUtil.updatePolicyChangeDTOCustomFields(quoteEndorsement);
            policyChangeVM.value = new PolicyChange(quoteEndorsement);
            updateWizardData(policyChangeVM);
            setIsQuoteStale(false);

            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
            const hasConflicts = !isEmpty(get(policyChangeVM, 'value.conflicts', []));

            // Need to stay on the page if field issues
            if (validationErrors.length === 0 && hasConflicts && !calledFromOnSaveOrCustom) {
                let newLandingPageIndex = -1;

                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );

                if (newLandingPageIndex >= 0) {
                    jumpTo(newLandingPageIndex, true);
                }

                return false;
            }

            setIsSavingEndorsement(false);

            return policyChangeVM;
        },
        [authHeader, getLandingPageIndexForQuotedJob, isComponentValid, jumpTo, modalApi, policyChangeVM, steps, updateWizardData]
    );

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            await saveAndQuote();

            return policyChangeVM;
        },
        [isComponentValid, policyChangeVM, saveAndQuote]
    );

    const recalculate = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingCurrentPageChanges(true);

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (isUndefined(get(policyChangeVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value'))
                && !!get(policyChangeVM, 'lobData.personalAuto_EA.paperlessEmail.value')) {
                set(policyChangeVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value',
                    get(policyChangeVM, 'lobData.personalAuto_EA.paperlessEmail.value'));
            }

            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteResponse);
            setIsQuoteStale(false);
            updateWizardData(policyChangeVM);
            setIsSavingCurrentPageChanges(false);

            return false;
        },
        [authHeader, isComponentValid, policyChangeVM, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote(true);

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, policyChangeVM, updateWizardSnapshot]
    );

    const handleCompOnlyChangeInCopyCoverages = useCallback(async (vehiclePublicID) => {

        updateLoadingClause(vehiclePublicID);

        // call save and quote
        let quoteEndorsement;

        try {
            quoteEndorsement = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );
        } catch {
            updateLoadingClause(undefined);
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.saveCoverageError,
                message: messages.saveCoverageErrorMessage,
                messageProps: {
                    confirmButtonText: commonMessages.cancelModel
                },
                showCancelBtn: false
            });
        }

        PolicyChangeUtil.updatePolicyChangeDTOCustomFields(quoteEndorsement);
        policyChangeVM.value = new PolicyChange(quoteEndorsement);
        updateWizardData(policyChangeVM);        
        setIsQuoteStale(false);

        const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

        if (validationErrors.length > 0) {
            window.scrollTo(0, 0);
        }

        updateLoadingClause(undefined);
    }, [authHeader, modalApi, policyChangeVM, updateWizardData])

    const handleCompOnlyChange = useCallback(
        async (value, vehiclePublicID) => {
            /**
             * get index of vehicle and set compOnlyInd
             */
            updateLoadingClause(vehiclePublicID);

            const vehicleIndex = VehicleUtil.getVehicleIndexBasedonPublicID(policyChangeVM, vehiclePublicID);

            set(policyChangeVM, `value.lobData.personalAuto_EA.coverables.vehicles.${vehicleIndex}.compOnlyInd`, value);
            updateWizardData(policyChangeVM);

            if (value) {
                // set all the vehicle coverage with selected as false
                const vehicleCoverages = VehicleUtil.getVehicleCoveragesBasedonPublicID(policyChangeVM, vehiclePublicID);
                const updatedCoverages = vehicleCoverages.map((cov) => {
                    if (cov.codeIdentifier !== 'EA_Comprehensive') {
                        set(cov, 'selected', false);
                    } else {
                        set(cov, 'selected', true);
                    }

                    return cov;
                });
                const vehicleCoverageIndex = VehicleUtil.getVehicleCoverageIndexBasedonPublicID(policyChangeVM, vehiclePublicID);

                set(policyChangeVM, `value.lobData.personalAuto_EA.offerings.0.coverages.vehicleCoverages.${vehicleCoverageIndex}.coverages`, updatedCoverages);
                updateWizardData(policyChangeVM);
            }

            // check if every vehicle is componly then we need to update all line coverages to selected as false
            if (VehicleUtil.isEveryVehicleCompOnly(policyChangeVM)) {
                const lineCoverages = VehicleUtil.getLineLevelCoverages(policyChangeVM);
                const updatedLineCoverages = lineCoverages.map((cov) => {
                    set(cov, 'selected', false);

                    return cov;
                });

                set(policyChangeVM, 'value.lobData.personalAuto_EA.offerings.0.coverages.lineCoverages', updatedLineCoverages);
                updateWizardData(policyChangeVM);
            }

            // call save and quote
            let quoteEndorsement;

            try {
                quoteEndorsement = await EndorsementService.saveAndQuoteEndorsement(
                    [(policyChangeVM.value)],
                    authHeader
                );
            } catch {
                updateLoadingClause(undefined);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: messages.saveCoverageError,
                    message: messages.saveCoverageErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });
            }

            PolicyChangeUtil.updatePolicyChangeDTOCustomFields(quoteEndorsement);
            policyChangeVM.value = new PolicyChange(quoteEndorsement);
            updateWizardData(policyChangeVM);
            setIsQuoteStale(false);

            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            if (validationErrors.length > 0) {
                window.scrollTo(0, 0);
            }

            updateLoadingClause(undefined);
        },
        [authHeader, modalApi, policyChangeVM, updateWizardData]
    );

    const generateOverrides = useCallback(
        () => {
            const vehicles = get(policyChangeVM, 'lobData.personalAuto_EA.coverables.vehicles.value');
            const premiumTypeLabel = get(policyChangeVM, 'quoteType_Ext.value.code') === 'Quick'
                ? 'Unverified Premium' : 'Verified Premium';
            const overrides = {};

            if (!isEmpty(vehicles)) {
                vehicles.forEach((_, i) => {
                    overrides[`vehicleCoverage${i}`] = {
                        loadingClause
                    };
                });
            }

            overrides[premiumTypeLabel] = {
                content: premiumTypeLabel
            };

            const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
            const lobOfferings = get(policyChangeVM, `${lobOfferingPath}.value`);

            overrides.gridContainerLineCoverage = {
                lobOfferings,
                onChangeSubmissionAndSync: changeSubmissionAndSync,
                onChangeSubmission: changeSubmission,
                onSyncCoverages: syncClauses,
                onValidate,
                hasExtendedAttributeEditability,
                policyState,
                lobIndex: 0,
                quoteIndex: 0,
                showErrors: isPageSubmitted,
                isQuoteStale,
                setUpdatingLineLevelCoverages,
                updatingVehicleLevelCoverages
            };
            overrides.gridContainerVehicleCoverage = {
                lobOfferings,
                onChangeSubmissionAndSync: changeSubmissionAndSync,
                onChangeSubmission: changeSubmission,
                onSyncCoverages: syncClauses,
                onValidate,
                hasExtendedAttributeEditability,
                policyState,
                lobIndex: 0,
                quoteIndex: 0,
                vehicles: get(
                    policyChangeVM,
                    'lobData.personalAuto_EA.coverables.vehicles.value'
                ),
                quoteDataOfferings: get(policyChangeVM, 'quoteData.offeredQuotes.value'),
                isCompOnlyVisible: isCompOnlyFeatureAvailable,
                onCompOnlyChange: handleCompOnlyChange,
                policyTransactionVM: policyChangeVM,
                updateWizardData,
                onCompOnlyChangeForCopyCoverages: handleCompOnlyChangeInCopyCoverages,
                columnData,
                onForceUpdateCoverages,
                updatePlatinumADD,
                showErrors: isPageSubmitted,
                isQuoteStale,
                setUpdatingVehicleLevelCoverages,
                updatingLineLevelCoverages
            };

            return overrides;
        },
        [
            policyChangeVM, changeSubmissionAndSync, changeSubmission, syncClauses, 
            onValidate, hasExtendedAttributeEditability, policyState, isPageSubmitted, 
            isQuoteStale, updatingVehicleLevelCoverages, isCompOnlyFeatureAvailable, 
            handleCompOnlyChange, updateWizardData, handleCompOnlyChangeInCopyCoverages,
             columnData, onForceUpdateCoverages, updatePlatinumADD, updatingLineLevelCoverages,
              loadingClause
            ]
    );

    const getCovPath = (ID, index) => {
        let covPath = '';

        if (ID === 'Vehicle') {
            covPath = `lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[${index}].coverages`;
        } else if (ID === 'Line') {
            covPath = 'lobData.personalAuto_EA.offerings.children[0].coverages.lineCoverages';
        } else {
            throw new Error('You did not pass in the required ID parameter');
        }

 return covPath;
    };

    const discountChange = (value, path) => {
        const newPath = path.split('policyChangeVM.')[1];

        set(policyChangeVM, newPath, value);
        updateWizardData(policyChangeVM, false);
    };

    const availableValuesForPaymentOptions = useMemo(() => {
        const correctOffering = get(policyChangeVM, 'value.lobData.personalAuto_EA.offerings[0]', undefined);

        if (correctOffering?.paymentPlans !== undefined) {
            return correctOffering.paymentPlans
                .filter((paymentPlans) => paymentPlans.name)
                .reduce(
                    (paymentPlanMap, paymentPlan) => ({
                        ...paymentPlanMap,
                        [paymentPlan.billingId]: paymentPlan
                    }),
                    {}
                );
        }

        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(policyChangeVM.lobData.personalAuto_EA.offerings.value)]);

    // Backend has Split / Combined Single Limit as Checkbox options, we need radio box behavior
    // Method handles switching between Split / Combined Single Limit
    const onPolicyLevelCoverageBundleChange = (value) => {
        const lineCoverages = get(policyChangeVM.value, 'lobData.personalAuto_EA.offerings.0.coverages.lineCoverages');
        const splitLimitIndex = findIndex(lineCoverages, (cov) => cov.codeIdentifier === 'EA_SplitLimitCoverageBundle');
        const combinedLimitIndex = findIndex(lineCoverages, (cov) => cov.codeIdentifier === 'EA_CombinedSingleLimitCoverageBundle');
        const isSplitLimitSelected = value === 'EA_SplitLimitCoverageBundle';

        // We need to update both before calling custom quote api to update coverage,
        // Updating combined limit first & split limit with sync later to update coverage selection
        changeSubmission(!isSplitLimitSelected, `${getCovPath('Line')}.children[${combinedLimitIndex}].selected`);
        changeSubmissionAndSync(isSplitLimitSelected, `${getCovPath('Line')}.children[${splitLimitIndex}].selected`);
    };

    // Returns policy line coverage endorsement bundle radio options (Split / Combined Single Limit)
    const getAvailablePolicyLineCoverageBundles = () => {
        const lineCoverages = get(policyChangeVM, getCovPath('Line'), {});

        return map(filter(lineCoverages.value, (cov) => cov.coverageCategoryCode === 'EA_EndorsementPackage', []), (cov) => ({
                id: cov.codeIdentifier,
                displayName: cov.name
            }));
    };

    // Returns selected policy line coverage endorsement bundle
    const getSelectedPolicyLineCoverageBundle = () => {
        const lineCoverages = get(policyChangeVM, getCovPath('Line'), {});

        return get(find(lineCoverages.value, (cov) => cov.coverageCategoryCode === 'EA_EndorsementPackage' && cov.selected), 'codeIdentifier');
    };

    let vehicleDiscountSubtotal = 0;
    let discountSubtotal = 0;
    const lineDiscountSubtotal = get(policyChangeVM, 'lobData.personalAuto_EA.offerings.value[0].premiumSummary', [])
        .find((prem) => prem.premiumSummaryType === 'TotalDiscounts')?.amount || 0;

    get(policyChangeVM, 'lobData.personalAuto_EA.offerings.value[0].coverages.vehicleCoverages').forEach((vehicleCoverage) => {
        vehicleDiscountSubtotal += get(vehicleCoverage, 'premiumSummary', [])
            .find((prem) => prem.premiumSummaryType === 'TotalVehicleDiscounts')?.amount || 0;
    })
    discountSubtotal = vehicleDiscountSubtotal + lineDiscountSubtotal;

    const isLiabilityCovCeded = get(policyChangeVM, 'lobData.personalAuto_EA.cededIndicator.value', false) || get(policyChangeVM, 'lobData.personalAuto_EA.uwcededIndicator.value', false)

    const isTNCCoverageSelected = useMemo(
        () => CoverageUtil.isCoverageSelected(policyChangeVM, 'EA_TNCCov'),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [policyChangeVM.value]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges;
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            showRequired: true,
            autoComplete: false
        },
        ADDWarningDiv: {
            visible: ADDClauseInCoverages && platStateChanged && !ADDSelectedBeforePlatinum

        },
        coveragesPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        paperlessDiscountContainer: {
            visible: get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled'])
        },
        PaperlessDiscountToggle: {
            required: true,
            value: get(policyChangeVM, 'lobData.personalAuto_EA.paperlessInd.value'),
            onValueChange: updatePaperlessIndAndEmail
        },
        paperlessEmailId: {
            visible: paperLessIndValue,
            required: paperLessIndValue,
            onValueChange: updatePaperlessIndAndEmail,
            value: policyChangeVM.lobData.personalAuto_EA.paperlessEmail.value
        },
        coveragesPageContainer: {
            visible: isPageLoaded
        },
        AutoPayDiscountToggle: {
            onValueChange: discountChange,
            value: get(policyChangeVM, 'lobData.personalAuto_EA.autoPayDiscInd.value')
        },
        physicalDamageCovRemovalMsgDiv: {
            visible: policyState === 'NC' && get(policyChangeVM, 'lobData.personalAuto_EA.uwcededIndicator.value', false)
        },
        policyTermComponent: {
            transactionVM: policyChangeVM,
            authHeader,
            updateWizardData,
            viewOnly: true
        },
        monthlyPaymentScheduleComponent: {
            quoteID: get(policyChangeVM, 'quoteID.value', policyChangeVM.jobID?.value),
            authHeader,
            transactionTotalAmount: !isNil(
                availableValuesForPaymentOptions[get(policyChangeVM, 'bindData.selectedPaymentPlan.value')]
            )
                ? availableValuesForPaymentOptions[
                    get(policyChangeVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined,
            changeInCost: policyChangeVM.transactionCost?.value,
            startDate: policyChangeVM.baseData.periodStartDate.value,
            endDate: policyChangeVM.baseData.periodEndDate.value,
            jobTypeCode: policyChangeVM.baseData.jobType.value.code,
            offerings: get(policyChangeVM, 'lobData.personalAuto_EA.offerings.value')
        },
        paymentOptionsID: {
            submissionVM: policyChangeVM,
            authHeader,
            updateWizardData,
            LOB: 'personalAuto_EA',
            viewOnly: true,
            isQuoteStale
        },
        buyNowButton: {
            visible: !isQuoteStale,
            onClick: async () => {
                await onNext();

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    e1pGoNext();
                }
            },
            disabled: !isUndefined(loadingClause)
            
        },
        recalculateButton: {
            visible: isQuoteStale,
            onClick: () => recalculate(),
            disabled: !isUndefined(loadingClause)
        },
        eaSurchargeListComponentId: {
            value: modifiers.filter(
                (item) => item.applied && item.modifierType === "surcharge"
            ),
            visible: (!!filter(modifiers, { applied: true, modifierType: 'fee' }).length
                || !!filter(modifiers, { applied: true, modifierType: 'surcharge' }).length
            ) && isSurchargeSectionVisible,
            transactionVM: policyChangeVM,
            lob: 'PersonalAuto_EA'
        },
        eaDiscountsListComponentId: {
            visible: !!filter(modifiers, { applied: true, modifierType: 'discount' }).length,
            value: modifiers,
            discountsTotal: discountSubtotal !== 0 ? discountSubtotal : undefined
        },
        supportingPolicyComponentContainer: {
            transactionVM: policyChangeVM,
            LOB: 'personalAuto_EA',
            updateWizardData,
            viewOnly: false,
            authHeader
        },
        scrollingComponentId: {
            navRef,
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('gridContainerVehicleCoverageContainer'),
            scrollWidth: 320
        },
        policyLevelCoverageBundle: {
            availableValues: getAvailablePolicyLineCoverageBundles(),
            value: getSelectedPolicyLineCoverageBundle(),
            onValueChange: onPolicyLevelCoverageBundleChange,
            // Only make it visible if options are available for policy level endorsement packages
            visible: getAvailablePolicyLineCoverageBundles().length > 0
        },
        travelPackageGrid: {
            visible: isTravelPackageVisible
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        LiabilityCovCededMsgDiv: {
            visible: policyState === 'NC' && isLiabilityCovCeded
        },
        tncGapMinimumPDLimitMsgDiv: {
            visible: policyState === 'IN' && isTNCCoverageSelected
        },
        totalPremiumID: {
            value: (() => getTotalPremium())()
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onValidate,
            whatsIncludedHandler,
            compOnlyDescriptionHandler
        },
    };

    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, policyChangeVM, id, path, overrideProps), [overrideProps, policyChangeVM]);

    const showCustom = true;
    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            updateIsPageSubmitted(false);
            await saveAndQuote(true);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            return false;
        },
        [
            getLandingPageIndexForQuotedJob,
            isComponentValid,
            jumpTo,
            policyChangeVM,
            saveAndQuote,
            steps,
            updateWizardSnapshot
        ]
    );

    const isPageLoading = useCallback(() => isUndefined(loadingClause), [loadingClause]);

    const isQuoted = useCallback(() => get(policyChangeVM, 'status') === 'quoted', [policyChangeVM]);

    useEffect(() => {
        registerComponentValidation(isPageLoading);
        registerInitialComponentValidation(isQuoted);
    }, [isPageLoading, isQuoted, registerComponentValidation, registerInitialComponentValidation]);

    const dataForComponent = {
        columnData,
        modifiers,
        policyChangeVM
    };

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            showNext={!isQuoteStale}
            disableNext={!isUndefined(loadingClause)}
            showCustom1={showCustom}
            onCustom1={onCustom}
            custom1Label={e1pCommonMessages.reviewChanges}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted}
            showCustom={isQuoteStale}
            customLabel={eaCommonMessages.recalculateButtonLabel}
            onCustom={recalculate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={dataForComponent}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

CoveragePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.shape({})
    }).isRequired,
    ...wizardProps
};
export default withRouter(withAuthenticationContext(CoveragePage));
