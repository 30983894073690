import React, {
    useContext, useCallback, useEffect, useRef, useMemo,
} from 'react';
import {
    get, set, isUndefined
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import metadata from '../UnderlyingPolicyPage.metadata.json5';

function ViewUnderlyingPolicyPage(props) {
    const {
        wizardData: submissionVM
    } = props;

    const breakpoint = useContext(BreakpointTrackerContext);
    const underlyingPoliciesVM = useMemo(() => get(submissionVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []), [submissionVM]);
    const jobType = get(submissionVM.value, 'baseData.jobType');

    useEffect(() => {
        if (isUndefined(underlyingPoliciesVM.value)) {
            set(underlyingPoliciesVM, 'value', []);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(() => submissionVM, [submissionVM]);

    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            readOnly: true
        },
        underlyingPolicyPageLoadingIndicator: {
            loaded: true
        },
        euUnderlyingPolicyComponent: {
            data: submissionVM,
            updateWizardData: () => {},
            isPageSubmitted: false,
            viewOnlyMode: true,
            deletedItemsRef: useRef([]),
            onValidate: undefined,
            isPrivatePassengerQuestionVisible: false,
            jobType
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
            />
        </WizardPage>
    );
}

ViewUnderlyingPolicyPage.propTypes = wizardProps;
export default ViewUnderlyingPolicyPage;
