import { defineMessages } from 'react-intl';

export default defineMessages({
    sixMonths: {
        id: 'common.component.PolicyTermComponent.sixMonths',
        defaultMessage: '6 Months'
    },
    twelveMonths: {
        id: 'common.component.PolicyTermComponent.twelveMonths',
        defaultMessage: '12 Months'
    },
    changingPolicyTerm: {
        id: 'common.component.PolicyTermComponent.changingPolicyTerm',
        defaultMessage: 'Changing Policy Term...'
    }
});
