const overrides = {
    field: {
        stateViolation: {
            partner: {
                MSA: {
                    states: ['VA']
                }
            }
        },
    }
};

export default overrides;