import { defineMessages } from 'react-intl';

export default defineMessages({
    addressStandardizationTitle: {
        id: 'standardizationResults.No exact address found.',
        defaultMessage: 'No exact address found.'
    },
    useThisAddress: {
        id: 'standardizationResults.Use this Address',
        defaultMessage: 'Use this Address'   
    }, 
    enteredAddress: {
        id: 'standardizationResults.Entered Address',
        defaultMessage: 'Entered Address'
    }, 
    standardizedAddress: {
        id: 'standardizationResults.Standardized address(es)',
        defaultMessage: 'Standardized address(es)'
    }
});
