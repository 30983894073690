
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useUWIssueUtil, useLandingPageUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { EndorsementService } from 'e1p-capability-policychange';
import metadata from './RiskAnalysisPage.metadata.json5';
import messages from './RiskAnalysisPage.messages';

const LOB = 'personalUmbrella_EU';

function RiskAnalysisPage(props) {
    // eslint-disable-max-len
    const { authHeader } = useAuthentication();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        updateWizardSnapshot,
        isSkipping,
        steps,
        jumpTo,
        authUserData
    } = props;
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);    
    const [activeTab, updateActiveTab] = useState('');
    const policyChangeSource = _get(policyChangeVM, 'value.policyChangeSource', '');
    const policyChangeReason = _get(policyChangeVM, 'value.policyChangeReason', '');
    const translator = useTranslator();

    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('RiskAnalysisPage');

    const {
        hasUWIssues
    } = useUWIssueUtil(
        policyChangeVM,
        updateWizardData
    );

    const {
        getLandingPageIndexForQuotedJob,
        getDefaultTab
    } = useLandingPageUtil();

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(policyChangeVM, 'lobData.personalUmbrella_EU.premiumStability.value') !== undefined;

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    const defaultTabBasedOnReason = useMemo(() => getDefaultTab(LOB, policyChangeSource, policyChangeReason), [getDefaultTab, policyChangeSource, policyChangeReason]);

    const saveAndQuote = useCallback(
        async (_, isQuote = false, calledFromOnSave = false) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            if (!isQuote) {
                const saveResponse = await EndorsementService.saveEndorsement(
                    [policyChangeVM.value],
                    authHeader
                );

                _set(policyChangeVM, 'value', saveResponse);
                updateWizardData(policyChangeVM);
            } else {
                const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                    [(policyChangeVM.value)],
                    authHeader
                );

                _set(policyChangeVM, 'value', quoteResponse);
                updateWizardData(policyChangeVM);
            }

            setIsSavingEndorsement(false);

            const hasConflicts = !_isEmpty(_get(policyChangeVM, 'value.conflicts', []));

            if (!calledFromOnSave && hasConflicts) {
                let newLandingPageIndex = -1;

                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );

                if (newLandingPageIndex >= 0) {
                    jumpTo(newLandingPageIndex, true);
                }

                return false;
            }

            return policyChangeVM;
        },
        [authHeader, getLandingPageIndexForQuotedJob, isComponentValid, jumpTo, policyChangeVM, steps, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote(undefined, false, true);

                const fieldIssues = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, policyChangeVM, updateWizardSnapshot]
    );

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            const resp = await saveAndQuote(undefined, true);

            policyChangeVM.value = resp?.value;
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            return false;
        },
        [isComponentValid, saveAndQuote, policyChangeVM, updateWizardData, updateWizardSnapshot, getLandingPageIndexForQuotedJob, steps, jumpTo]
    );

    const uwIssuesPresent = useMemo(() => 
         hasUWIssues()
        // eslint-disable-next-line react-hooks/exhaustive-deps, camelcase
    , [policyChangeVM?.errorsAndWarnings_Ext?.underwritingIssues?.value]);

    const getDefaultActiveTab = useMemo(() => {
        if (uwIssuesPresent) {
            return 'uwIssuesTab';
        }

        return defaultTabBasedOnReason === false ? 'lossesAndViolationTab' : defaultTabBasedOnReason;
    }, [uwIssuesPresent, defaultTabBasedOnReason]);

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
            defaultActiveTab: getDefaultActiveTab,
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            activeTab: activeTab !== '' ? activeTab : getDefaultActiveTab
        },
        lossesAndViolationComponent: {
            submissionVM: policyChangeVM,
            isSkipping,
            showErrors: isPageSubmitted,
            authUserData,
            isVerified: true,
            updateWizardData,
            disregardFieldValidation,
            updateIsPageSubmitted
        },
        UWIssuesComponentId: {
            submissionVM: policyChangeVM,
            updateWizardData,
            authHeader,
            showErrors: isPageSubmitted
        },
        diminishingAvailableAmount: {
            labelPosition: 'left'
        },
        diminishingAvailableAmountLatest: {
            labelPosition: 'left'
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(policyChangeVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        },
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    return (
        <WizardPage
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            skipWhen={initialValidation}
            onNext={saveAndQuote}
            showCustom
            onSave={onSave}
            showOnSave
            onCustom={onCustom}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
