import React, {
    useContext, useCallback, useEffect, useRef, useMemo, useState
} from 'react';
import {
    get, set, every, includes, isUndefined, pullAt, some, find, isEmpty
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, WizardPageTemplateWithTitle, wizardProps } from 'e1p-portals-wizard-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { RewriteService } from 'e1p-capability-rewrite';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useOOSConflictPageLandingUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import messages from './UnderlyingPolicyPage.messages';
import metadata from './UnderlyingPolicyPage.metadata.json5';
import EUVehicleOperatorPage from '../VehicleOperatorPage/EUVehicleOperatorPage';

function UnderlyingPolicyPage(props) {
    const {
        wizardData: rewriteVM,
        updateWizardData,
        currentStepIndex,
        changeNextSteps,
        isSkipping,
        steps,
        jumpTo,
        updateWizardSnapshot
    } = props;
    const stepsRef = useRef(steps);
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation('UnderlyingPolicyPage');
    const [isSavingRewrite, setIsSavingRewrite] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const deletedItemsRef = useRef([]);
    const underlyingPoliciesVM = useMemo(() => get(rewriteVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []), [rewriteVM]);
    const jobType = get(rewriteVM.value, 'baseData.jobType');

    useEffect(() => {
        /**
         * Using useRef to access current updated steps.
         * as we are adding new conflicts step and landing user on this newly created step
         * "step" state variable from props does not give us updated value inside useOOSConflictPageLandingUtil
         * it refers initial rendered value only(as we are adding new step and want to land user
         * on new step in)
         */
        stepsRef.current = steps;
    }, [steps]);

    const {
        removeOrAddAndLandOnConflictsPage
    } = useOOSConflictPageLandingUtil(
        stepsRef,
        currentStepIndex,
        changeNextSteps,
        jumpTo
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted]);

    // DO NOT change the ID of this page without changing step to field mappings config
    const vehicleOperatorPage = useRef({
        id: 'EURewriteVehicleOperatorPage',
        path: '/vehicle-operator',
        component: EUVehicleOperatorPage,
        title: {
            id: 'rewrite.eu.directives.templates.page.Vehicle Operator',
            defaultMessage: 'Vehicle Operator',
        },
        stepProps: {
            template: WizardPageTemplateWithTitle
        },
    }).current;

    const handleVehicleOperatorPage = useCallback(() => {
        const remainingSteps = steps.slice(currentStepIndex + 1, steps.length);
        const vehicleUnderlyingPolicyExists = some(underlyingPoliciesVM.value,
            (policy) => includes(['personalauto', 'personalaononowner', 'otherspecialityvehicle', 'cycle'],
                policy.policyType));

        if (vehicleUnderlyingPolicyExists && !find(steps, ['id', vehicleOperatorPage.id])) {
            remainingSteps.unshift(vehicleOperatorPage);
        }

        if (!vehicleUnderlyingPolicyExists && find(steps, ['id', vehicleOperatorPage.id])) {
            remainingSteps.shift(vehicleOperatorPage);
        }

        changeNextSteps(remainingSteps);

        return vehicleUnderlyingPolicyExists;
    }, [changeNextSteps, currentStepIndex, steps, underlyingPoliciesVM.value, vehicleOperatorPage]);

    useEffect(() => {
        if (isUndefined(underlyingPoliciesVM.value)) {
            set(underlyingPoliciesVM, 'value', []);
        }

        updateWizardData(rewriteVM);
        handleVehicleOperatorPage();
        registerComponentValidation(() => every(underlyingPoliciesVM.children,
                (el, index) => (el.aspects.subtreeValid
                    || some(deletedItemsRef.current, (i) => i === index)))
                && !!(underlyingPoliciesVM.value
                    .filter((_, index) => !deletedItemsRef?.current?.includes(index))
                    .find((policy) => policy.policyType === 'personalproperty' && policy.isPrimaryInd)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRewrite(true);
            pullAt(underlyingPoliciesVM.value, deletedItemsRef.current);
            set(rewriteVM, 'flowStepIDs_Ext.value', ['underlyingproperty']);
            set(rewriteVM, 'entryCompletionStepIDs_Ext.value', ['underlyingproperty']);
            rewriteVM.value = await RewriteService.updateDraftRewrite(
                [rewriteVM.value],
                authHeader
            );

            const vehicleUnderlyingPolicyExists = await handleVehicleOperatorPage();

            // if we are not showing vehicle operator page then we need to do saveAndQuote from here only
            if (!vehicleUnderlyingPolicyExists) {
                rewriteVM.value = await RewriteService.saveAndQuote(
                    [rewriteVM.value],
                    authHeader
                );
                updateWizardData(rewriteVM);

                if (!calledFromOnSave) {
                    /**
                      E1PAP1PC-13853 :
                      If we get conflicts in saveAndQuote, we will add conflicts page if its not present
                      and user will land on conflicts page
                      If we had conflicts and we came back and made changes such that after saveAndQuote
                      if there are no conflicts we will remove conflicts page if its present
                     */
                    const hasConflicts = !isEmpty(get(rewriteVM, 'value.conflicts', []));

                    removeOrAddAndLandOnConflictsPage(hasConflicts);

                    if (hasConflicts) {
                        setIsSavingRewrite(false);

                        return false;
                    }
                }
            }

            // updating deletedItemsRef to empty, once we delete underlying policy.
            deletedItemsRef.current = [];
            updateWizardData(rewriteVM);
            setIsSavingRewrite(false);
            // Added Two underlying policies and deleted auto , backend is sending validations for auto
            // so updated wizarddata to show them on UI.
            updateWizardData(rewriteVM);
            updateWizardSnapshot(rewriteVM);

            return rewriteVM;
        },
        [
            authHeader, handleVehicleOperatorPage, isComponentValid, updateWizardData,
            rewriteVM, removeOrAddAndLandOnConflictsPage, underlyingPoliciesVM, updateWizardSnapshot
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    // Updating noOperatorReasonOther value , which makes wizard and snapshot data equal.
                    // It will avoid unnecessary modal popup for saved changes.
                    if(get(rewriteVM.lobData.personalUmbrella_EU, 'noOperatorReasonOther.value', undefined) === undefined) {
                        set(rewriteVM.lobData.personalUmbrella_EU, 'noOperatorReasonOther.value', undefined);
                    }

                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, rewriteVM, updateWizardSnapshot]
    );

    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        underlyingPolicyPageLoadingIndicator: {
            loaded: !isSavingRewrite && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        underlyingPolicyPageContainer: {
            visible: !isSavingRewrite && !isSkipping && !isSavingCurrentPageChanges
        },
        euUnderlyingPolicyComponent: {
            data: rewriteVM,
            updateWizardData,
            isPageSubmitted,
            isPrivatePassengerQuestionVisible: false,
            deletedItemsRef,
            jobType
        }
    };

    const writeValue = useCallback(
        (newVal, path) => {
            set(rewriteVM, `${path}.value`, newVal);
            updateWizardData(rewriteVM);
        },
        [rewriteVM, updateWizardData]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            nextLabel={translator(messages.allUnderlyingPolicisAdded)}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

UnderlyingPolicyPage.propTypes = wizardProps;
export default UnderlyingPolicyPage;
