import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';

function extractVMObject(data) {
    for (const key in data) {
        if (ViewModelUtil.isViewModelNode(data[key])) {
            return data[key];
        }
    }
    return undefined;
}

export default {
    extractVMObject
};