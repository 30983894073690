import React, {
    useMemo
} from 'react';
import {
    get as _get
} from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './ThankyouPagePolicyDocumentsComponent.metadata.json5';
import styles from './ThankyouPagePolicyDocumentsComponent.module.scss';
import './ThankyouPagePolicyDocumentsComponent.messages';

function ThankyouPagePolicyDocumentsComponent(props) {
    const {
        submissionVM,
    } = props;

    const policyNumber = useMemo(() => {
        if (_get(submissionVM, 'bindData.policyNumber.value')) {
            return _get(submissionVM, 'bindData.policyNumber.value');
        }

        return _get(submissionVM, 'policyNumber.value');
    }, [submissionVM]);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        linkToDocumentsID: {
            to: `/policies/${policyNumber}/documents`
        }

    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ThankyouPagePolicyDocumentsComponent.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
};

export default ThankyouPagePolicyDocumentsComponent;
