import React, { useEffect } from 'react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import htmlParser from 'html-react-parser';
import { valueTypeMap, getComponentDataType, getPropDataType } from '../../ScheduleItemsUtil';
import styles from '../../ScheduleItemsComponent.module.scss';
import messages from './SimpleScheduleItem.messages';
import scheduleCoveragesHelpText from './scheduleCoveragesHelpText.json5';

function formatAvailableValues(availableValues, scheduleItem, id, currentValue) {
    // we have to remove the already selected item from typelist of Personal Property -
    // Increased Special Limits of Liability (E1PAP1PC-13013)
    let alreadyAddedScheduledTypes = [];
    if (id === 'EH_NamedPerilIncCovC_ScheduleType') {
        scheduleItem.scheduleItems.forEach((item) => {
            alreadyAddedScheduledTypes.push(item.itemData[id].typeCodeValue);
        });
        // if user clicks on edit button, then we have to show the selected
        // currentValue in dropdown.
        if (!_.isUndefined(currentValue)) {
            alreadyAddedScheduledTypes = alreadyAddedScheduledTypes
                .filter((val) => val !== currentValue);
        }
    }

    return _.sortBy(availableValues, 'priority')
        .filter((data) => !alreadyAddedScheduledTypes.includes(data.code))
        .map((availableValue) => ({
            code: availableValue.code,
            name: availableValue.displayKey
        }));
}

function getScheduleCoveragesHelpText(id) {
    const coveragesHelpTextMessageDetails = _.get(scheduleCoveragesHelpText, id, undefined);
    const coveragesHelpTextMessage = coveragesHelpTextMessageDetails
        ? htmlParser(coveragesHelpTextMessageDetails.toString()) : undefined;
    return ({
        message: coveragesHelpTextMessage,
        visible: !!coveragesHelpTextMessage
    });
}

function hasErrors(scheduleItem, value, path, modalData) {
    const isFirstItem = _.isEmpty(scheduleItem.scheduleItems);
    const isValueUnique = scheduleItem.scheduleItems.filter((item) => {
        return item.itemNumber !== modalData.itemNumber;
    })
        .every((item) => {
            const itemValue = _.get(item, path);
            return itemValue !== value;
        });

    return !isValueUnique && !isFirstItem;
}

function getValidationMessage(isUnique, scheduleItem, value, path, modalData, translator) {
    return (isUnique && hasErrors(scheduleItem, value, path, modalData)
        ? [translator(messages.thisFieldMustBeUnique)] : undefined)
        || (value === 0.00 ? [translator(messages.requiredField)] : undefined);
}

export default function SimpleScheduleItem({
    info,
    translator,
    modalData,
    scheduleItem,
    onValueChange,
    onValidate,
    parentOverrides,
    isPageSubmitted,
    parentPageSubmitted
}) {
    const {
        id,
        valueType,
        required,
        label,
        editable,
        availableTypeListValues = [],
        currency,
        isUnique
    } = info;

    const path = `itemData.${id}.${valueTypeMap[valueType]}`;

    const dataType = getComponentDataType(info);
    const value = _.get(modalData, path);
    const toolTipDetails = getScheduleCoveragesHelpText(id);

    const metadata = {
        content: [{
            id: id,
            type: 'field',
            datatype: dataType,
            componentProps: {
                dataType: getPropDataType(info),
                required: required,
                showRequired: true,
                availableValues: formatAvailableValues(
                    availableTypeListValues, scheduleItem, id, value
                ),
                currency: currency,
                path: path,
                readOnly: !editable,
                label: label,
                value: value,
                tooltip: {
                    text: toolTipDetails.message,
                    placement: 'right'
                },
                validationMessages: getValidationMessage(
                    isUnique, scheduleItem, value, path, modalData, translator
                ),
                className: styles.rowMargin,
                onValueChange: onValueChange,
                placeholder: '',
                showErrors: isPageSubmitted || parentPageSubmitted,
                searchable: true,
                ...parentOverrides['@field']
            }
        }]
    };

    //const isFieldValid = validateContentFromMetadata(metadata);
    // useEffect(() => {
    //     if (onValidate) {
    //         onValidate(isFieldValid, id);
    //     }
    // }, [onValidate, id, isFieldValid]);

    return <MetadataContent uiProps={metadata} />;
}
