import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';
import { DocumentUploadService as OOTBDocumentUploadService } from '@xengage/gw-portals-document-js';


export default class DocumentUploadService {
    static uploadDocument = (documentDTO, file, additionalHeaders = {}) => {
        return OOTBDocumentUploadService.send(getProxiedServiceUrl('documentupload'), file, documentDTO, additionalHeaders);
    }

    static documentUploadToken = (params, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('documentuploadtoken'),
            'generateUploadToken',
            params,
            additionalHeaders
        );
    }
}
