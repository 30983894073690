import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { PrintComponent } from 'e1p-capability-policyjob-react';
import WhatsIncludedArrays from 'e1p-portals-util-js/EHWhatsIncluded-config.json';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './PackageQualifyingEndorsementsComponent.metadata.json5';
import styles from './PackageQualifyingEndorsementsComponent.module.scss';

import { Button } from '@jutro/legacy/components';

function PackageQualifyingEndorsementsComponent(props) {
    const {
        isOpen,
        onReject,
        coverages
    } = props;

    const qualifyingEndorsementArray = useMemo(() => {
        const qualifyingCoverages = [];
        const sortFunction = (firstEl, secondEl) => {
            if (firstEl.coverageName < secondEl.coverageName) {
                return -1;
            }

            if (firstEl.coverageName > secondEl.coverageName) {
                return 1;
            }

            return 0;
        };

        coverages.forEach((coverage) => {
            // cut off the front EH_
            if (WhatsIncludedArrays.PA.includes(coverage.codeIdentifier.slice(3))) {
                qualifyingCoverages.push({ coverageName: coverage.name });
            }
        });

        return qualifyingCoverages.sort(sortFunction);
    }, [coverages]);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        qualifyingBulletpoint: {
            data: qualifyingEndorsementArray
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    const viewModel = (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );

    const printButton = (
        <PrintComponent
            containerIdsToPrint={["PackageQualifyingEndorsementsPageID"]}
        />
    );

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader />
            <ModalBody id='PackageQualifyingEndorsementsPageID'>
                {viewModel}
            </ModalBody>
            <ModalFooter>
                {printButton}
                <Button onClick={onCancel} type="text" className="closeButton">
                    {commonMessages.close}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

PackageQualifyingEndorsementsComponent.propTypes = {
    onReject: PropTypes.func,
    isOpen: PropTypes.bool,
    coverages: PropTypes.array
};
PackageQualifyingEndorsementsComponent.defaultProps = {
    coverages: undefined
};
export default PackageQualifyingEndorsementsComponent;
