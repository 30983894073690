import { defineMessages } from 'react-intl';

export default defineMessages({
    interactiveTitle: {
        id: 'gateway.views.account-detail.Interactive Letter',
        defaultMessage: 'Interactive Letter'
    },
    noURLFound: {
        id: 'gateway.views.account-detail.There might be something wrong, please try again later.',
        defaultMessage: 'There might be something wrong, please try again later.'
    },
    noInteractiveTemplateFound: {
        id: 'gateway.views.account-detail.Either no template found or something went wrong.',
        defaultMessage: 'Either no template found or something went wrong.'
    }
});
