import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    find as _find,
    get as _get,
    set as _set,
    filter as _filter,
    remove as _remove,
    isEmpty as _isEmpty
} from 'lodash';
import {
    ModalNext, ModalHeader, ModalBody, ModalFooter, useModal
} from '@jutro/components';
import { TPIUtil, PersonNameValidator } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { E1PContactsFoundComponent } from "e1p-capability-policyjob-react";
import { useAddressStandardization, e1pUSStatesUtil } from 'e1p-capability-hooks';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import './E1PEATPISearchDetailComponent.messages';
import metadata from './E1PEATPISearchDetailComponent.metadata.json5';
import styles from './E1PEATPISearchDetailComponent.module.scss';

/**
 * This component file models the EA TPI input panel on the lower section of the "TPI Search" popup.
 *
 * @param {Object} props An object containing the properties passed into this component.
 * @returns {Object} An object containing the data required for rendering this component.
 */
const E1PEATPISearchDetailComponent = (props) => {
    const modalApi = useModal();
    const {
        id,
        tpiDetailVM,
        viewModelService,
        onResolve,
        isControlledTPI,
        isTPIContactTypeCompany,
        isAddingNewTPI,
        tpiBasePath,
        transactionVM,
        vehicleID,
        authHeader,
        isOpen,
        showPopup,
        onReject,
        saveThirdPartyInterestClickHandler,
        isEditingTPI,
        showErrors,
        onValidate,
        disregardFieldValidationParentPage,
        updateIsSearchTPIVisible,
        updateAndShowWarning,
        setIsAddingTPI,
        isTPIFromSearchResult
    } = props;
    const {
        onValidate: setIsComponentValid,
        isComponentValid,
        disregardFieldValidation
    } = useValidation(id);
    const [tempTPIDetailVM, setTempTPIDetailVM] = useState(tpiDetailVM);
    const [chosenVehicle, updateChosenVehicle] = useState(vehicleID);
    const [titleHolderAvailableValues, setTitleholderAvailableValues] = useState([]);
    const [isComponentInitialized, setIsComponentInitialized] = useState(false);
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSearchingContacts, setIsSearchingContacts] = useState(false);
    const [oldTPIAddress, setOldTPIAddress] = useState({});
    const [addressStandardizationResultDTO, updateAddressStandardizationResultDTO] = useState(
        undefined
    );
    const [enteredStateForAddressStandardization, updateEnteredState] = useState(undefined);
    const { getStandardizedAddress, standardizeAddressIfApplicable } = useAddressStandardization(
        viewModelService,
        authHeader
    );
    const translator = useTranslator();
    // state in Address DTO for TPI is String, but we need to show states as dropdown
    const [USStates, setUSStates] = useState([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const lineLevelInterestTypes = ['AdditionalDesignee_Ext'];

    useEffect(() => {
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        if (tpiDetailVM) {
            const newTpiDetailVM = viewModelService.clone(tpiDetailVM);

            setTempTPIDetailVM(newTpiDetailVM);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tpiDetailVM?.value]);

    const ADDL_INTEREST_TYPE_TL = viewModelService.productMetadata
        .get('pc')
        .types.getTypelist('AdditionalInterestType');

    useEffect(() => {
        if (_get(tempTPIDetailVM, 'addlInterestType.value.code') === 'TRUST_Ext') {
            const nextData = viewModelService.clone(tempTPIDetailVM);

            _set(
                nextData,
                'titleholderType',
                _find(tempTPIDetailVM.titleholderType.aspects.availableValues, (value) => value.code === 'Trustee')
            );
            setTempTPIDetailVM(nextData);
        }

        const USStatesTypekey = e1pUSStatesUtil.getUSStates(viewModelService);
        const states = e1pUSStatesUtil.getStateValues(USStatesTypekey, translator);

        setUSStates(states);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for writing values to the view model.
     */
    const writeValue = useCallback(
        (value, path) => {
            /**
            * We are updating standardized address on saveTPICall 
            * And as state update is async, updating tpiDetailsVM object value
            */
            _set(tempTPIDetailVM, path, value);

            const nextFormData = viewModelService.clone(tempTPIDetailVM);

            _set(nextFormData, path, value);
            setTempTPIDetailVM(nextFormData);
        },
        [tempTPIDetailVM, viewModelService]
    );

    const onSelectAddress = useCallback(
        (addressToUse) => {
            const addressVM = tempTPIDetailVM.address;

            addressVM.value = {
                ...addressVM.value,
                address1: _get(addressToUse, 'addressLine1', _get(addressToUse, 'address1')),
                address2: _get(addressToUse, 'addressLine2', _get(addressToUse, 'address2')),
                city: addressToUse.city,
                postalCode: addressToUse.postalCode,
                state: addressToUse.state,
                country: 'US'
            };
            writeValue(addressVM.value, 'address.value');
            updateEnteredState(undefined);
            updateAddressStandardizationResultDTO(undefined);
        },
        [tempTPIDetailVM, writeValue]
    );


    const handleAddressBlur = useCallback(
        async (_, { value, beforeValue }) => {
            if (value === beforeValue) {
                return;
            }

            const addressVM = tempTPIDetailVM.address;

            // set addressline1 and addressLine2, since the addressStandardization needs it
            // and we dont have addressline1 and addressLine2 in addressDTO, we have address1 and address2
            _set(addressVM, 'addressLine1', tempTPIDetailVM.address.address1);
            _set(addressVM, 'addressLine2', tempTPIDetailVM.address.address2);
            updateAddressStandardizationResultDTO(undefined);

            if (
                _get(addressVM, 'addressLine1.value')
                && _get(addressVM, 'city.value')
                && _get(addressVM, 'state.value')
                && _get(addressVM, 'postalCode.value')
            ) {
                setIsStandardizingAddress(true);

                const standardizedAddressResult = await getStandardizedAddress(addressVM, false);

                if (!standardizedAddressResult?.standardizationChoices) {
                    setIsStandardizingAddress(false);

                    return;
                }

                const allExactMatchAddresses = standardizedAddressResult.standardizationChoices.filter((choice) => choice.isExactMatch);

                // if only one exact match then map it directly otherwise show the popup
                if (allExactMatchAddresses?.length === 1) {
                    const addressToUse = {
                        addressLine1: allExactMatchAddresses[0].addressLine1,
                        addressLine2: allExactMatchAddresses[0].addressLine2 ? allExactMatchAddresses[0].addressLine2 : '',
                        city: allExactMatchAddresses[0].city,
                        postalCode: allExactMatchAddresses[0].postalCode,
                        state: allExactMatchAddresses[0].state,
                        country: 'US'
                    };

                    onSelectAddress(addressToUse);
                    updateAddressStandardizationResultDTO(undefined);
                } else {
                    const address = addressVM.value;
                    const enteredAddress = { ...address };

                    _set(enteredAddress, 'tempID', address.state);
                    _set(
                        enteredAddress,
                        'formattedDisplayName',
                        `${address.address1}, ${address.address2}, ${address.city}, ${address.state}, ${address.postalCode}`
                    );
                    updateEnteredState(enteredAddress);
                    updateAddressStandardizationResultDTO(standardizedAddressResult);
                }

                setIsStandardizingAddress(false);
            }
        },
        [tempTPIDetailVM?.address, getStandardizedAddress, onSelectAddress]
    );

    const { formattedBankName, formattedAddress } = useMemo(() => ({
        formattedBankName: TPIUtil.getFormattedTPIBankName(_get(tempTPIDetailVM, 'value')),
        formattedAddress: TPIUtil.getFormattedTPIAddress(_get(tempTPIDetailVM, 'value'))
    }), [tempTPIDetailVM]);

    const showContactsModal = useCallback(async (contactRecords) => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            contactRecords
        };

        return modalApi.showModal(<E1PContactsFoundComponent {...componentProps} />);
    }, [modalApi]);

    const onAddressChange = useCallback((value, path) => {
        let actualPath = path;
        const actualValue = value;

        if (path === 'address.addressLine1') {
            actualPath = 'address.address1'
        } else if (path === 'address.addressLine2') {
            actualPath = 'address.address2'
        }
        else if (path === 'address.value') {
            if (value.addressLine1) {
                _set(actualValue, 'address1', value.addressLine1);
            }

            if (value.addressLine2) {
                _set(actualValue, 'address2', value.addressLine2);
            }
        }

        writeValue(actualValue, actualPath)
    }, [writeValue]);

    const saveThirdPartyInterest = useCallback(
        async () => {
            if (!tempTPIDetailVM) {
                updateAndShowWarning(e1pCommonMessages.selectAThirdPartyError);

                return false;
            }

            if (!isComponentValid) {
                updateIsPageSubmitted(true);

                return false;
            }

            /**
             * If user adds new uncontrolled tpi or choose existing uncontrolled tpi
             * we will standardize the address before saving tpi
             */
            if ((isAddingNewTPI || !isControlledTPI) && !isEditingTPI) {
                setIsStandardizingAddress(true);

                const isAddressStandardized = await standardizeAddressIfApplicable(
                    oldTPIAddress,
                    tempTPIDetailVM.address,
                    onAddressChange,
                    'address.value'
                )

                setIsStandardizingAddress(false);

                if (!isAddressStandardized) {
                    // address is not standardized
                    return false;
                }

                setOldTPIAddress(tempTPIDetailVM.address.value);
            }

            if (isAddingNewTPI) {
                // need to populate address from addressDTO to PrimaryAddress
                // since we cannot create new contact without address in backend
                const address = tempTPIDetailVM.address.value;

                address.addressLine1 = address.address1;
                address.addressLine2 = address.address2;

                if (isTPIContactTypeCompany) {
                    _set(tempTPIDetailVM, 'value.company.primaryAddress', address);
                } else {
                    _set(tempTPIDetailVM, 'value.person.primaryAddress', address);
                }

                setIsSearchingContacts(true);

                let contacts = [];

                try {
                    contacts = await TPIUtil.searchContacts(
                        _get(tempTPIDetailVM, 'value'),
                        _get(transactionVM, 'value.baseData.accountHolder.accountNumber'),
                        authHeader
                    );
                } catch (error) {
                    // some error occurred while searching contacts
                    return false;
                } finally {
                    setIsSearchingContacts(false);
                }

                try {
                    if (!_isEmpty(contacts)) {
                        const { chosenContact } = await showContactsModal(contacts);

                        // if subtype is present it means user has selected an existing contact
                        // if its not present user has clicked on create new contact
                        if (chosenContact.contactSubtype) {
                            TPIUtil.mapChosenContactToTPIContact(_get(tempTPIDetailVM, 'value'), chosenContact);
                        }
                    }
                } catch {
                    // user clicked on cancel and did not choose any contact
                    return false
                }
            }

            disregardFieldValidation(id);
            disregardFieldValidationParentPage(id);

            if (showPopup) {
                return onResolve({ VehicleTPIDetailObj: tempTPIDetailVM.value, vehicleID: chosenVehicle });
            }

            return saveThirdPartyInterestClickHandler({ VehicleTPIDetailObj: tempTPIDetailVM.value, vehicleID: chosenVehicle })
        },
        [
            tempTPIDetailVM, isComponentValid, isAddingNewTPI, disregardFieldValidation, id,
            disregardFieldValidationParentPage, showPopup, saveThirdPartyInterestClickHandler,
            chosenVehicle, updateAndShowWarning, standardizeAddressIfApplicable, oldTPIAddress,
            onAddressChange, isTPIContactTypeCompany, transactionVM, authHeader, showContactsModal,
            onResolve, isControlledTPI, isEditingTPI
        ]
    );

    /**
         * Helper callback for filtering the "AdditionalInterestType" typelist values based on whether
         * they're already in use on the policy.
         */
    const filterAddlInterestTypeCodes = useCallback(
        (initialAddlInterestTypeCodes) => {
            const allExistingTPIsOnPolicy = _get(transactionVM, `${tpiBasePath}.value`, []);
            const alreadyUsedAddlInterestTypeCodes = allExistingTPIsOnPolicy.map((item) => item.addlInterestType);
            const filteredAddlInterestTypeCodes = _filter(
                initialAddlInterestTypeCodes,
                (typeCode) => {
                    const isTypeCodeAvailable = alreadyUsedAddlInterestTypeCodes.indexOf(typeCode.code) === -1;
                    const canTypeCodeHaveMultipleOccurrences = !typeCode.code.includes('MORTGAGEE');

                    return isTypeCodeAvailable || canTypeCodeHaveMultipleOccurrences;
                }
            );

            return filteredAddlInterestTypeCodes;
        },
        [transactionVM, tpiBasePath]
    );

    /**
    * Helper callback for retrieving the "AdditionalInterestType" typelist values for EA.
    */
    const getAddlInterestTypeCodesForEA = useCallback(() => {
        const addlInterestTypeCodes = [];
        const filteredCodes = _find(ADDL_INTEREST_TYPE_TL.filters, {
            name: 'EAControlledTPIFilter_Ext'
        }).codes;

        filteredCodes.forEach((typeCode) => {
            addlInterestTypeCodes.push(
                _find(ADDL_INTEREST_TYPE_TL.codes, {
                    code: typeCode.code
                })
            );
        });

        const policyStateCode = _get(transactionVM, 'baseData.policyAddress.state.value.code');

        if (policyStateCode !== 'CA' && policyStateCode !== 'NJ' && policyStateCode !== 'NY' && policyStateCode !== 'CT') {
            _remove(addlInterestTypeCodes, (typeCode) => typeCode.code === 'AdditionalDesignee_Ext');
        }

        const availableValues = addlInterestTypeCodes.map((typeCode) => ({
            code: typeCode.code,
            name: translator({ id: typeCode.name })
        }));

        return availableValues;
    }, [ADDL_INTEREST_TYPE_TL.codes, ADDL_INTEREST_TYPE_TL.filters, transactionVM, translator]);

    const getAddlInterestTypeCodes = useMemo(() => {
        const addlInterestTypesForPerson = ['AdditionalInsured_Ext', 'AdditionalInterest_Ext', 'Titleholder_Ext', 'AdditionalDesignee_Ext'];
        let addlInterestTypeCodes = [];

        addlInterestTypeCodes = getAddlInterestTypeCodesForEA();
        addlInterestTypeCodes = filterAddlInterestTypeCodes(addlInterestTypeCodes);

        if (!isTPIContactTypeCompany) {
            addlInterestTypeCodes = addlInterestTypeCodes.filter(
                (addlInterestTypeCode) => addlInterestTypesForPerson.includes(addlInterestTypeCode.code)
            );
        }

        return addlInterestTypeCodes;
    }, [filterAddlInterestTypeCodes, getAddlInterestTypeCodesForEA, isTPIContactTypeCompany]);


    const generateOverridePropsForUncontrolledPerson = useCallback(
        () => ({
            additionalInterestTypePerson: {
                visible: false,
                availableValues: getAddlInterestTypeCodes,
                className: (() => {
                    if (!isAddingNewTPI) {
                        return 'vehicleReadOnly';
                    }

                    return '';
                })(),
            },
            personFirstName: {
                readOnly: !isAddingNewTPI,
                required: isAddingNewTPI && !isTPIContactTypeCompany,
                validationMessages: PersonNameValidator
                    .validateFirstName(_get(tempTPIDetailVM, 'person.firstName.value'), translator),
                showErrors: !!_get(tempTPIDetailVM, 'person.firstName.value', false) || isPageSubmitted || showErrors,
                visible: !isTPIFromSearchResult
            },
            personMiddleName: {
                readOnly: !isAddingNewTPI,
                validationMessages: PersonNameValidator
                    .validateMiddleName(_get(tempTPIDetailVM, 'person.middleName.value'), translator),
                showErrors: !!_get(tempTPIDetailVM, 'person.middleName.value', false) || isPageSubmitted || showErrors,
                visible: !isTPIFromSearchResult
            },
            personLastName: {
                readOnly: !isAddingNewTPI,
                required: isAddingNewTPI && !isTPIContactTypeCompany,
                validationMessages: PersonNameValidator
                    .validateLastName(_get(tempTPIDetailVM, 'person.lastName.value'), translator),
                showErrors: !!_get(tempTPIDetailVM, 'person.lastName.value', false) || isPageSubmitted || showErrors,
                visible: !isTPIFromSearchResult
            },
            personSuffix: {
                readOnly: !isAddingNewTPI,
                visible: !isTPIFromSearchResult
            },
            personAddressLine1: {
                required: true,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personAddressLine2: {
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personCity: {
                required: true,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personPostalCode: {
                required: true,
                onBlur: handleAddressBlur,
                className: (() => 'editableZip')(),
                visible: isEditingTPI
            },
            personState: {
                availableValues: USStates,
                required: true,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personAddressInfoContainer: {
                visible: isEditingTPI
            },
            personAddress: {
                addressVM: _get(tempTPIDetailVM, 'address'),
                labelPosition: 'top',
                showCountry: false,
                showOptional: false,
                onValidate: setIsComponentValid,
                viewOnlyMode: false,
                showParentLoader: setIsStandardizingAddress,
                showErrors: isPageSubmitted || showErrors,
                onAddressChange: (value, path) => onAddressChange(value, `address.${path}`),
                visible: !isEditingTPI && !!tempTPIDetailVM,
                shouldStandardizeOnBlur: false
            },
        }),
        [
            USStates, getAddlInterestTypeCodes, handleAddressBlur, isAddingNewTPI,
            isEditingTPI, isPageSubmitted, isTPIContactTypeCompany, onAddressChange,
            setIsComponentValid, showErrors, tempTPIDetailVM, translator, isTPIFromSearchResult
        ]
    );

    const setChosenVehicle = useCallback((e) => {
        if (e) {
            updateChosenVehicle(e);
        }
    }, []);

    const generateOverridePropsForUncontrolledCompany = useCallback(() => ({
        additionalInterestType: {
            visible: false,
            availableValues: getAddlInterestTypeCodes
        },
        companyName: {
            readOnly: !isAddingNewTPI,
            required: isAddingNewTPI && isTPIContactTypeCompany,
            visible: !isTPIFromSearchResult
        },
        companyAddressLine1: {
            required: isTPIContactTypeCompany,
            onBlur: handleAddressBlur,
            visible: isEditingTPI
        },
        companyAddressLine2: {
            onBlur: handleAddressBlur,
            visible: isEditingTPI
        },
        companyCity: {
            required: isTPIContactTypeCompany,
            onBlur: handleAddressBlur,
            visible: isEditingTPI
        },
        companyPostalCode: {
            required: isTPIContactTypeCompany,
            onBlur: handleAddressBlur,
            className: 'editableZip',
            visible: isEditingTPI
        },
        companyState: {
            availableValues: USStates,
            required: isTPIContactTypeCompany,
            onBlur: handleAddressBlur,
            visible: isEditingTPI
        },
        companyAddressInfoContainer: {
            visible: isEditingTPI
        },
        companyAddress: {
            addressVM: _get(tempTPIDetailVM, 'address'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate: setIsComponentValid,
            viewOnlyMode: false,
            showParentLoader: setIsStandardizingAddress,
            showErrors: isPageSubmitted || showErrors,
            onAddressChange: (value, path) => onAddressChange(value, `address.${path}`),
            visible: !isEditingTPI && !!tempTPIDetailVM,
            shouldStandardizeOnBlur: false
        },
    }), [
        USStates, getAddlInterestTypeCodes, handleAddressBlur, isAddingNewTPI,
        isEditingTPI, isPageSubmitted, isTPIContactTypeCompany, onAddressChange,
        setIsComponentValid, showErrors, tempTPIDetailVM, isTPIFromSearchResult
    ]);

    const generateOverrides = useCallback(() => {
        const overrides = {};
        const availableVehicles = [];
        const interestType = _get(tempTPIDetailVM, 'value.addlInterestType');
        const publicID = isTPIContactTypeCompany
            ? _get(tempTPIDetailVM, 'value.company.publicID')
            : _get(tempTPIDetailVM, 'value.person.publicID');
        const tpiid = _get(tempTPIDetailVM, 'value.tpiid');

        transactionVM.lobData.personalAuto_EA.coverables.vehicles.children.forEach((vehicle) => {
            const tempObj = {
                code: `${vehicle.publicID.value}${vehicle.year.value}${vehicle.make.value}${vehicle.model.value}`,
                name: `${vehicle.year.value} ${vehicle.make.value} ${vehicle.model.value}`
            };
            // Deciding whether this vehicle is available for given user with given interest type to add as a tpi
            // such that we will not have same tpi with same interest on one vehicle
            const vehiclesAddInterests = _get(vehicle, 'value.additionalInterests', []);

            if (!vehicleID) {
                const interestTypePresent = vehiclesAddInterests.some((vehiclesAddInterest) => {
                    const vehiclesAddInterestID = _get(vehiclesAddInterest, 'company.publicID', false)
                        ? _get(vehiclesAddInterest, 'company.publicID')
                        : _get(vehiclesAddInterest, 'person.publicID');

                    return (
                        vehiclesAddInterestID === publicID
                        || (
                            vehiclesAddInterest.tpiid && tpiid && vehiclesAddInterest.tpiid === tpiid
                        ))
                        && vehiclesAddInterest.addlInterestType === interestType;
                });

                if (!interestTypePresent) {
                    availableVehicles.push(tempObj);
                }
            } else {
                availableVehicles.push(tempObj);
            }
        });
        overrides.vehicleDropdown = {
            // visible: true
            availableValues: availableVehicles,
            onValueChange: (e) => {
                setChosenVehicle(e);
            },
            className: (() => {
                if (!isAddingNewTPI) {
                    return 'vehicleReadOnly';
                }

                return 'vehicleDropdownStyle';
            })(),
            value: chosenVehicle,
            readOnly: !!(vehicleID),
            visible: tempTPIDetailVM ?
                !lineLevelInterestTypes.includes(_get(tempTPIDetailVM, 'addlInterestType.value.code'))
                || _get(transactionVM, 'baseData.policyAddress.state.value.code') !== 'CT' : true
        };

        if (_get(tempTPIDetailVM, 'titleholderType.value')) {
            overrides.relationshipToNIDropdown = {
                visible:
                    !isTPIContactTypeCompany
                    && _get(tempTPIDetailVM, 'addlInterestType.value.code') === 'Titleholder_Ext'
                    && (_get(tempTPIDetailVM, 'titleholderType.value.code') === 'Individual'
                        || _get(tempTPIDetailVM, 'titleholderType.value.code') === 'JointOwner'),
                required:
                    !isTPIContactTypeCompany
                    && _get(tempTPIDetailVM, 'addlInterestType.value.code') === 'Titleholder_Ext'
                    && (_get(tempTPIDetailVM, 'titleholderType.value.code') === 'Individual'
                        || _get(tempTPIDetailVM, 'titleholderType.value.code') === 'JointOwner')
            };
            overrides.loanLeaseNumber = {
                visible:
                    _get(tempTPIDetailVM, 'addlInterestType.value.code') === 'Titleholder_Ext'
                    && _get(tempTPIDetailVM, 'titleholderType.value.code') === 'Lessor'
            };
        } else {
            overrides.relationshipToNIDropdown = {
                visible: false
            };
            overrides.loanLeaseNumber = {
                visible: _get(tempTPIDetailVM, 'addlInterestType.value.code') === 'LIEN'
            };
        }

        return overrides;
    }, [tempTPIDetailVM, isTPIContactTypeCompany, transactionVM, chosenVehicle, vehicleID, lineLevelInterestTypes, setChosenVehicle, isAddingNewTPI]);

    useEffect(() => {
        const addIntEaVM = viewModelService.create(
            {},
            'pc',
            'amfam.edge.capabilities.policyjob.lob.ea.coverables.dto.EAVehicleAddlInterestDTO'
        );

        const availableValuesEA = isTPIContactTypeCompany
            ? addIntEaVM.titleholderType.aspects.availableValues[0].typelist.getFilter('EAOrgTitleHolderType').codes
            : addIntEaVM.titleholderType.aspects.availableValues[0].typelist.getFilter('EAPersonTitleHolderType').codes;

        const initialEaTypeCodes = availableValuesEA.map((typeCode) => ({
            code: typeCode.code,
            name: translator({
                id: typeCode.name,
                defaultMessage: typeCode.name
            })
        }));

        setTitleholderAvailableValues(initialEaTypeCodes);
        setIsComponentInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCancel = useCallback(() => {
        if (setIsAddingTPI) {
            setIsAddingTPI(false);
        }

        disregardFieldValidation(id);
        disregardFieldValidationParentPage(id);

        if (showPopup) {
            return onReject();
        }

        updateIsSearchTPIVisible(false);
    }, [
        disregardFieldValidation, disregardFieldValidationParentPage,
        id, onReject, showPopup, updateIsSearchTPIVisible, setIsAddingTPI
    ]);

    const getTPIDetailsHeaderContent = useCallback(() => {
        let tpiDetailsHeaderContent = e1pCommonMessages.createNewTPI;

        if (isTPIFromSearchResult) {
            tpiDetailsHeaderContent = e1pCommonMessages.tpiDetails;
        } else if (isEditingTPI) {
            tpiDetailsHeaderContent = e1pCommonMessages.editThirdPartyInterestDetails;
        }

        return tpiDetailsHeaderContent;
    }, [isEditingTPI, isTPIFromSearchResult]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        eaTPISearchDetailComponentIndicator: {
            loaded: !isStandardizingAddress && !isSearchingContacts,
            text: isStandardizingAddress
                ? translator(e1pCommonMessages.standardizingAddressMessage)
                : translator(e1pCommonMessages.searchingContacts)
        },
        eaTPISearchDetailComponentContainer: {
            visible: !isStandardizingAddress && !isSearchingContacts && !!tpiDetailVM
        },
        addressStandardizationComponent: {
            addressStandardizationResultDTO,
            visible: !!addressStandardizationResultDTO && !!tpiDetailVM && isEditingTPI,
            modalDisplay: false,
            selectInlineAddress: onSelectAddress,
            enteredAddress: enteredStateForAddressStandardization
        },
        tpiActionsContainer: {
            visible: !isStandardizingAddress && !isSearchingContacts
        },
        tpiDetailsHeading: {
            content: getTPIDetailsHeaderContent()
        },
        controlledTPIContainer: {
            visible: isControlledTPI
        },
        controlledTPIContainerFirstRow: {
            visible: isEditingTPI && isControlledTPI
        },
        controlledTPIContainerSecondRow: {
            visible: isControlledTPI
        },
        controlledTPIAddress: {
            value: formattedAddress
        },
        controlledTPIBankName: {
            value: formattedBankName
        },
        uncontrolledPersonTPIContainer: {
            visible: !isControlledTPI && !isTPIContactTypeCompany
        },
        uncontrolledCompanyTPIContainer: {
            visible: !isControlledTPI && isTPIContactTypeCompany
        },
        titleHolderTypeDropdown: {
            visible:
                _get(tempTPIDetailVM, 'addlInterestType.value.code') === 'Titleholder_Ext'
                || _get(tempTPIDetailVM, 'addlInterestType.value.code') === 'TRUST_Ext',
            required: _get(tempTPIDetailVM, 'addlInterestType.value.code') === 'Titleholder_Ext',
            // value: tempTPIDetailVM.addlInterestType.value.code === 'TRUST_Ext'
            //    ? _find(tempTPIDetailVM.titleholderType.aspects.availableValues,
            //     (value) => { return value.code === 'Trustee'; }) : undefined,
            readOnly: _get(tempTPIDetailVM, 'addlInterestType.value.code') === 'TRUST_Ext',
            availableValues: titleHolderAvailableValues
        },
        completeMissingFieldMessageDiv: {
            visible: isPageSubmitted && !isComponentValid
        },
        ...generateOverrides(),
        ...generateOverridePropsForUncontrolledPerson(),
        ...generateOverridePropsForUncontrolledCompany()
    };


    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
            metadata.pageContent,
            tempTPIDetailVM,
            fieldId,
            fieldPath,
            overrideProps
        ),
        [tempTPIDetailVM, overrideProps]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            saveThirdPartyInterest,
            onCancel
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    if (!isComponentInitialized) {
        return null;
    }

    const tpiDetailsPanel = (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={tempTPIDetailVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={setIsComponentValid}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );

    if (!showPopup) {
        return tpiDetailsPanel;
    }

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader />
            <ModalBody className={styles.modalWidth}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={tempTPIDetailVM}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    onValidationChange={setIsComponentValid}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter />
        </ModalNext>
    );
};

/**
 * Define expected types for properties to be passed into this Jutro component.
 */
E1PEATPISearchDetailComponent.propTypes = {
    viewModelService: PropTypes.shape({
        clone: PropTypes.func,
        create: PropTypes.func,
        productMetadata: PropTypes.shape({
            get: PropTypes.func
        }).isRequired
    }).isRequired,
    transactionVM: PropTypes.shape({
        lobData: PropTypes.shape({
            personalAuto_EA: PropTypes.shape({
                coverables: PropTypes.shape({
                    vehicles: PropTypes.shape({
                        children: PropTypes.arrayOf(PropTypes.shape({}))
                    })
                })
            })
        })
    }).isRequired,
    tpiDetailVM: PropTypes.shape({
        value: PropTypes.shape({})
    }),
    isControlledTPI: PropTypes.bool.isRequired,
    isTPIContactTypeCompany: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    vehicleID: PropTypes.string,
    tpiBasePath: PropTypes.string,
    showPopup: PropTypes.bool,
    authHeader: PropTypes.shape({}).isRequired,
    saveThirdPartyInterestClickHandler: PropTypes.func,
    showErrors: PropTypes.bool,
    onResolve: PropTypes.func,
    onReject: PropTypes.func,
    onValidate: PropTypes.func,
    disregardFieldValidationParentPage: PropTypes.func.isRequired,
    updateIsSearchTPIVisible: PropTypes.func,
    id: PropTypes.string,
    isEditingTPI: PropTypes.bool,
    isTPIFromSearchResult: PropTypes.bool,
    setIsAddingTPI: PropTypes.func
};

/**
 * Define default values for properties to be passed into this Jutro component.
 */
E1PEATPISearchDetailComponent.defaultProps = {
    isAddingNewTPI: false,
    vehicleID: undefined,
    showPopup: false,
    onResolve: undefined,
    onReject: undefined,
    isEditingTPI: false,
    isTPIFromSearchResult: false,
    setIsAddingTPI: undefined,
    tpiDetailVM: undefined,
    tpiBasePath: undefined,
    saveThirdPartyInterestClickHandler: undefined,
    showErrors: false,
    onValidate: undefined,
    updateIsSearchTPIVisible: undefined,
    id: undefined
};

export default E1PEATPISearchDetailComponent;
