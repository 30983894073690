import { JsonRPCService as PlatformJsonRpcService } from '@xengage/gw-portals-transport-js';
import { v4 as generateGuid } from 'uuid';


export default class JsonRPCService {
    /**
     * Sends the JSON-RPC request to the given endpoint.
     * @param {string} serviceEndpoint - The service endpoint
     * @param {string} method - The RPC method to invoke
     * @param {Array} params - The parameters to invoke the service with
     * @param {Object} [additionalHeaders] - Additional headers to pass to the backend
     * @returns {Promise} - The promise from the remote call
     * 
     * Adds custom unique trace header to each request
     */
    static send(serviceEndpoint, method, params, additionalHeaders = {}) {
        // Note this change is done to send unique id for every call that is made to PC from PE.
        const traceHeader = { 'afe-trace-id': generateGuid() };

        return (
            PlatformJsonRpcService.send(serviceEndpoint, method, params, { ...additionalHeaders, ...traceHeader })
        );
    }
}
