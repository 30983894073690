import { defineMessages } from 'react-intl';

export default defineMessages({
    producerInformation: {
        id: 'e1p.gateway.view.producer-detail-summary.Producer Information',
        defaultMessage: 'Producer Information'
    },
    producerOfRecord: {
        id: 'e1p.gateway.view.producer-detail-summary.Producer of Record',
        defaultMessage: 'Producer of Record',
    },
    producerOfService: {
        id: 'e1p.gateway.view.producer-detail-summary.Producer of Service',
        defaultMessage: 'Producer of Service',
    },
    preferredUnderwriter: {
        id: 'e1p.gateway.view.producer-detail-summary.Preferred Underwriter',
        defaultMessage: 'Preferred Underwriter',
    }
});
