/* eslint-disable no-restricted-properties */
/**
 * Returns a random number of specific length passed as parameter.
 *
 * @param {Integer} length of random number.
 * @returns {boolean} Random number that is being returned.
 */
function randomFixedInteger(length) {
    return Math.floor(Math.pow(10, length - 1)
                + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
}

export default { randomFixedInteger };
