
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    useRef
} from 'react';
import {
    get, set, sortBy, uniqBy, isUndefined
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useModal } from '@jutro/components';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { QuoteProposalService, InspectionService } from 'e1p-capability-gateway';
import { useUWIssueUtil, useSideBySideQuoteUtil } from 'e1p-capability-hooks';
import { PropertyFlowUtil, ClausesUtil as e1pClausesUtil, QuoteProposalUtil } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages, ehValidationAndInfoMessages } from 'e1p-platform-translations';
import appConfig from 'app-config';
import messages from './QuotePage.messages';
import metadata from './QuotePage.metadata.json5';

function generateClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeCoveragePath = 'coverages.coverages';

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

function generateExclusionClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeExclusionPath = 'exclusions';

        return {
            code,
            path: `${lob.path}.${completeExclusionPath}`,
            clauses: get(lob.data, completeExclusionPath.replace(/\.children/, ''))
        };
    });
}

function generateConditionClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeConditionPath = 'conditions';

        return {
            code,
            path: `${lob.path}.${completeConditionPath}`,
            clauses: get(lob.data, completeConditionPath.replace(/\.children/, ''))
        };
    });
}

const structureCustomQuote = (submissionVM, affectedQuote, clauses, exclusionClauses, conditionClauses) =>
// convert OfferingDTO to CustomQuotedDTO structure
({
    quote: affectedQuote,
    quoteID: submissionVM.quoteID.value,
    sessionUUID: submissionVM.sessionUUID.value,
    periodStart: submissionVM.baseData.periodStartDate.value,
    periodEnd: submissionVM.baseData.periodEndDate.value,
    coverages: clauses,
    exclusions_Ext: exclusionClauses,
    conditions_Ext: conditionClauses,
})
    ;

const getCustomQuote = (
    vm,
    lobPath,
    quotePath,
    lobName,
    filterChangedClauses = false
) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    const quoteOffering = get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    const updatedExclusions = lobOffering.exclusions.filter((exclusion) => exclusion.updated === true);
    const updatedConditions = lobOffering.conditions.filter((condition) => condition.updated === true);

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate, updatedExclusions, updatedConditions);
};

const getSelectedOffering = (submissionVM) => {
    const lobOfferingPath = 'lobData.homeowners_EH.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';
    const lobOfferings = get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = get(submissionVM, `${quoteOfferingPath}.value`);
    const selectedVersion = quoteOfferings.find((version) => version.selected);
    const lobIndex = lobOfferings.findIndex((offering) => offering.branchCode === selectedVersion.branchCode);

    return {
        lobOfferingPath,
        quoteOfferingPath,
        lobOfferings,
        quoteOfferings,
        selectedVersion,
        lobIndex
    };
};

const generateColumnData = (submissionVM) => {
    const selectedOffering = getSelectedOffering(submissionVM);

    let columnData = selectedOffering.lobOfferings.map((lobOffering, lobIndex) => {
        const quoteDataIndex = selectedOffering.quoteOfferings.findIndex(
            (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
        );
        // eslint-disable-next-line security/detect-object-injection
        const quoteData = selectedOffering.quoteOfferings[quoteDataIndex];

        return {
            name: lobOffering.branchName,
            code: lobOffering.branchCode,
            quote: {
                path: `${selectedOffering.quoteOfferingPath}.children.${quoteDataIndex}`,
                data: quoteData
            },
            lob: {
                path: `${selectedOffering.lobOfferingPath}.children.${lobIndex}`,
                data: lobOffering
            },
            submissionVM
        };
    });

    columnData = columnData.length > 1 ? [columnData.find((offering) => offering.code === selectedOffering.selectedVersion.branchCode)] : columnData;

    return sortBy(columnData, ['code']);
};


const getExclusionsUniqueID = (submissionVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (exclusion) => ({
        publicID: exclusion.publicID,
        coverageCategoryCode: exclusion.exclusionCategoryCode,
        uigroup: exclusion.uigroup
    });

    const offerings = get(submissionVM, 'lobData.homeowners_EH.offerings.value');
    const exclusions = uniqBy(
        offerings.flatMap((offering) => offering.exclusions.map(structureClauseTableData)),
        'publicID'
    );

    return {
        exclusions
    };
};

const getConditionsUniqueID = (submissionVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (condition) => ({
        publicID: condition.publicID,
        coverageCategoryCode: condition.conditionCategoryCode,
        uigroup: condition.uigroup
    });

    const offerings = get(submissionVM, 'lobData.homeowners_EH.offerings.value');
    const conditions = uniqBy(
        offerings.flatMap((offering) => offering.conditions.map(structureClauseTableData)),
        'publicID'
    );

    return {
        conditions
    };
};

const getCoveragesUniqueID = (submissionVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (coverage) => ({
        publicID: coverage.publicID,
        coverageCategoryCode: coverage.coverageCategoryCode,
        uigroup: coverage.uigroup
    });
    const offerings = get(submissionVM, 'lobData.homeowners_EH.offerings.value');

    const offerringsWithCovsNotHidden = offerings.map((offerring) => {
        const offerringFormatted = { ...offerring };

        offerringFormatted.coverages.coverages = offerring.coverages.coverages.filter((coverage) => coverage.isHiddenInVersion !== true);

        return offerringFormatted;
    });
    const baseCoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages.map(structureClauseTableData)),
        'publicID'
    );
    const additionalCoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.schedules.map(structureClauseTableData)),
        'publicID'
    );
    const sectionICoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Section I')
            .map(structureClauseTableData)),
        'publicID'
    );
    const sectionIICoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Section II')
            .map(structureClauseTableData)),
        'publicID'
    );
    const deductibles = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Deductibles')
            .map(structureClauseTableData)),
        'publicID'
    );

    return {
        baseCoverages,
        additionalCoverages,
        sectionICoverages,
        sectionIICoverages,
        deductibles
    };
};


const generateTableData = (submissionVM, columnData) => {
    const uniqueID = getCoveragesUniqueID(submissionVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateClauseData(columnData, coverageType)
    }));
};

const generateExclusionTableData = (submissionVM, columnData) => {
    const uniqueID = getExclusionsUniqueID(submissionVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateExclusionClauseData(columnData, coverageType)
    }));
};

const generateConditionTableData = (submissionVM, columnData) => {
    const uniqueID = getConditionsUniqueID(submissionVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateConditionClauseData(columnData, coverageType)
    }));
};

const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
const LOB = 'homeowners_EH';

function QuotePage(props) {
    const modalApi = useModal();
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const [fieldsChangedOnCoveragePage, setFieldsChangedOnCoveragePage] = useState(false);
    const [quoteIsStale, setQuoteIsStale] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [isQuoting, setIsQuoting] = useState(false);
    const [isBuying, setIsBuying] = useState(false);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSideBySideView, updateIsSideBySideView] = useState(false);
    const [showMaxVersionMessage, setShowMaxVersionMessage] = useState(false);
    const [showMinVersionMessage, setShowMinVersionMessage] = useState(false);
    const [showDeleteVersionMessage, setShowDeleteVersionMessage] = useState(false);
    const [selectedVersionName, setSelectedVersionName] = useState(false);
    const [showQuoteProposalError, setShowQuoteProposalError] = useState(false);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const { authHeader, authUserData } = useAuthentication();
    const {
        isComponentValid,
        onValidate,
        disregardFieldValidation,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('QuotePage');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: submissionVM,
        wizardSnapshot,
        updateWizardData,
        updateWizardSnapshot,
        stopSkipping,
        jumpTo,
        steps,
        cancel: onCancel,
        isPropertyPrefillOrderedMessage,
        furthestVisiblePageIndexOnStart,
        updateFurthestVisitedIndexOnStart,
        shouldSkipAdditionalInfo
    } = props;
    const [unverfiedFlow, setUnvefiedFlow] = useState(true);
    const [firstCoveragePageVisit, setFirstCoveragePageVisit] = useState(false);
    const [optionalVisited, setOptionalVisited] = useState(false);
    const [ACVCoverageSelected, setACVCoverageSelected] = useState(false);
    const translator = useTranslator();
    // eslint-disable-next-line no-secrets/no-secrets
    const policyType = useRef(get(submissionVM, 'lobData.homeowners_EH.policyType.value.code')).current;
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);
    const isQuoteDataAvailable = !!get(submissionVM, 'value.quoteData');

    const {
        hasUWIssuesOfType,
        showUnderwritingIssuesPopup,
        hasUWIssues
    } = useUWIssueUtil(
        submissionVM,
        updateWizardData,
        jumpTo,
        steps
    );

    // fetch quoteProposal for side by side
    const fetchQuoteProposalForSBS = useCallback(() => {
        setQuoteProposalCompleted(false);
        QuoteProposalService.retrieveSideBySideQuoteProposalURL(
            get(submissionVM, 'quoteID.value'),
            authHeader
        ).then((response) => {
            setQuoteProposalCompleted(true);
            setQuoteProposalLink(`${response.sideBySideQuoteProposalURL}`);
        }).catch(() => {
            setIsQuoteProposalFailed(true);
            updateWizardData(submissionVM);
        });
    }, [authHeader, submissionVM, updateWizardData]);

    const {
        enterSideBySide,
        onUpdateQuoteVersionName,
        onAddQuote,
        onSetSelectedVersion,
        onWithdrawSideBySideVersion,
        onRecalculateSideBySide,
    } = useSideBySideQuoteUtil(
        authHeader,
        submissionVM,
        updateWizardData,
        setIsQuoting,
        updateWizardSnapshot,
        setShowMaxVersionMessage,
        setShowMinVersionMessage,
        setShowDeleteVersionMessage,
        updateIsSideBySideView,
        fetchQuoteProposalForSBS,
        setSelectedVersionName,
        getCustomQuote,
        setShowQuoteProposalError,
        'homeowners_EH'
    );

    useEffect(() => {
        // Quote type is no longer set on leaving this page
        //   so will check to see if a verified step exists
        //   This function hooks into initialValidation for SkipWhen
        const isFullQuote = steps.find((step) => step.id === 'EHPaymentDetailsPage');

        registerInitialComponentValidation(() => isFullQuote && get(submissionVM, 'baseData.periodStatus.value.code') === 'Quoted');
    }, [registerInitialComponentValidation, steps, submissionVM]);

    const checkuwIssues = useCallback((tempSubmission) => {
        if (hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease', 'Rejected'], tempSubmission)) {
            setQuoteIsStale(true);
            showUnderwritingIssuesPopup(tempSubmission);
        }
    }, [hasUWIssuesOfType, showUnderwritingIssuesPopup]);

    const showPaperlessEmailMessage = useCallback(() => {
        // eslint-disable-next-line no-secrets/no-secrets
        const pniEmail = get(submissionVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(submissionVM, 'lobData.homeowners_EH.paperlessInd.value');
        const paperlessEmail = get(submissionVM, 'lobData.homeowners_EH.paperlessEmail.value');

        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [submissionVM, isPaperlessEmailUpdated]);

    useEffect(() => {
        // eslint-disable-next-line no-warning-comments
        // FIXME: Weird skipping issue from GW wizard platform code
        set(submissionVM, 'quoteType_Ext.value', get(submissionVM.value, 'quoteType'));
        // Weird skipping issue from GW wizard platform code
        stopSkipping();
        // If payment page isn't in steps we  are still in quick quote and coverage page
        //   will not be the last page in flow (finish should not run)
        setUnvefiedFlow(!steps.find((step) => step.id === 'EHPaymentDetailsPage'));

        if (!furthestVisiblePageIndexOnStart) {
            updateFurthestVisitedIndexOnStart(steps.findIndex((step) => step.id === 'EHQuotePage'));

            // IAP-2320: on next of property page flow lands on coverage page and risk analysis
            // step values submitted and visited are false
            if (steps.find(({ path }) => path === '/risk-analysis').submitted === false
                && steps.find(({ path }) => path === '/risk-analysis').visited === false) {
                setFirstCoveragePageVisit(true);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // at all other places we will have selected offerring only here we have chance of it being undefined as quote data will be undefined
        if ((policyType === 'HO3' || policyType === 'HF9') && isQuoteDataAvailable) {
            const selectedOffering = getSelectedOffering(submissionVM);

            // eslint-disable-next-line no-secrets/no-secrets
            if (selectedOffering.lobOfferings[selectedOffering.lobIndex].coverages.coverages.find((coverage) => coverage.codeIdentifier === "EH_RoofActualCashValueCostCovWindHail")?.selected === true) {
                setACVCoverageSelected(true);
            } else {
                setACVCoverageSelected(false);
            }
        }
    }, [policyType, submissionVM, isQuoteDataAvailable])

    const writeValue = useCallback(
        (value, path) => {
            set(submissionVM, path, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const getNewSubmissionVM = useCallback(
        (response, lobPath, quotePath, lobName) => {
            const clonedSubmissionVM = viewModelService.clone(submissionVM);
            const updatedClauses = get(response, `coverages.${lobName}`);
            const updatedExclusions = get(response, 'exclusions_Ext');
            const updatedConditions = get(response, 'conditions_Ext');

            // Update local offering with new one from xcenter
            set(clonedSubmissionVM, `${lobPath}.coverages`, updatedClauses);
            set(clonedSubmissionVM, `${lobPath}.exclusions_Ext`, updatedExclusions);
            set(clonedSubmissionVM, `${lobPath}.conditions_Ext`, updatedConditions);

            // Update local quote with new one from xcenter
            const status = get(response, 'quote.status', 'Draft');

            set(clonedSubmissionVM, `${quotePath}.status`, status);
            // Update premium with new one from xcenter
            set(clonedSubmissionVM, `${quotePath}.premium`, response.quote.premium);
            // Update local errorsAndWarnings with new one from xcenter
            set(clonedSubmissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
            set(clonedSubmissionVM, 'baseData.exceptions_Ext', response.exceptions_Ext);

            return clonedSubmissionVM;
        },
        [viewModelService, submissionVM]
    );

    const onUpdateCustomQuote = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(
                submissionVM,
                lobPath,
                quotePath,
                lobName,
                true
            );

            return CustomQuoteService.updateCustomQuoteCoverages(
                customQuote,
                authHeader
            )
                .then((response) => {
                    const newSubmissionVM = getNewSubmissionVM(
                        response,
                        lobPath,
                        quotePath,
                        lobName
                    );
                    const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.coverages`
                    );
                    const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM,
                        newSubmissionVM,
                        `${lobPath}.coverages.schedules`
                    );
                    const allRemovedFields = [
                        ...removedFieldsFromBaseCoverages,
                        ...removedFieldsFromAdditionalCoverages
                    ];

                    setQuoteIsStale(true);
                    disregardFieldValidation(allRemovedFields);
                    updateWizardData(newSubmissionVM);

                    return newSubmissionVM;
                })
                .catch(() => {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: 'Something went wrong while updating coverages',
                        message: ''
                    });
                });
        },
        [submissionVM, CustomQuoteService, authHeader, getNewSubmissionVM, disregardFieldValidation, updateWizardData, modalApi]
    );

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            const selectedOffering = getSelectedOffering(submissionVM);
            const lobOfferingPath = `lobData.homeowners_EH.offerings.children[${selectedOffering.lobIndex}]`;
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const offering = get(submissionVM, `${lobOfferingPath}.value`);

            setStaleQuoteBranchCode(offering.branchCode);

            return onUpdateCustomQuote(basePath, lobPath, quotePath);
        },
        [onUpdateCustomQuote, submissionVM]
    );

    const handlePrint = () => {
        window.print();
    };

    const onScheduleChange = useCallback(
        (schedule, path) => {
            const selectedOffering = getSelectedOffering(submissionVM);
            const lobOffering = `lobData.homeowners_EH.offerings.children[${selectedOffering.lobIndex}]`;
            const quoteOfferingChildren = `quoteData.offeredQuotes.children[${selectedOffering.lobIndex}]`;

            writeValue(schedule, `${path}.value`);

            const offering = get(submissionVM, `${lobOffering}.value`);

            setStaleQuoteBranchCode(offering.branchCode);

            return onUpdateCustomQuote({}, lobOffering, quoteOfferingChildren);
        },
        [onUpdateCustomQuote, submissionVM, writeValue]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            setFieldsChangedOnCoveragePage(true);
            updateWizardData(
                e1pClausesUtil.setClauseValue(submissionVM, value, changedPath)
            );

            return submissionVM;
        },
        [submissionVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            changeSubmission(value, changedPath);

            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeSubmission, syncCoverages]
    );

    const recalculate = useCallback(
        async (lobPath, quotePath) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            updateIsPageSubmitted(false);
            setIsQuoting(true);

            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(
                submissionVM,
                lobPath,
                quotePath,
                lobName
            );

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            // eslint-disable-next-line no-secrets/no-secrets
            if (isUndefined(get(submissionVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value'))
                && !!get(submissionVM, 'lobData.homeowners_EH.paperlessEmail.value')) {
                // eslint-disable-next-line no-secrets/no-secrets
                set(submissionVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value',
                    get(submissionVM, 'lobData.homeowners_EH.paperlessEmail.value'));
            }

            const selectedOffering = getSelectedOffering(submissionVM);

            // calling save and quote as we fields other than coverages are modified
            return LoadSaveService
                .saveAndQuoteSubmission(submissionVM.value, authHeader)
                .then((response) => {
                    setStaleQuoteBranchCode(undefined);
                    setFieldsChangedOnCoveragePage(false);

                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    const quoteData = get(newSubmissionVM, 'quoteData.value');
                    const respQuoteData = get(response, 'quoteData');

                    if (respQuoteData === undefined) {
                        set(response, 'quoteData', quoteData);
                    }

                    set(newSubmissionVM, 'value', response);

                    // Run Inspection whenever Coverage A changes for HO3 and HF9.
                    if ((policyType === 'HO3' || policyType === 'HF9')) {
                        const currentCovA = get(
                            get(newSubmissionVM, `lobData.homeowners_EH.offerings.value[${selectedOffering.lobIndex}].coverages.coverages`, []).find((cov) => cov.name === 'Coverage A - Dwelling'), 'terms[0].chosenTerm'
                        );
                        const previousCovA = get(
                            get(wizardSnapshot, `lobData.homeowners_EH.offerings.value[${selectedOffering.lobIndex}].coverages.coverages`, []).find((cov) => cov.name === 'Coverage A - Dwelling'), 'terms[0].chosenTerm'
                        );

                        if (newSubmissionVM.value.quoteType === 'Full'
                            && currentCovA !== previousCovA) {
                            InspectionService.runInspection(get(submissionVM, 'quoteID.value'), authHeader)
                                .then((inspectionDetails) => {
                                    set(
                                        submissionVM,
                                        'lobData.homeowners_EH.coverables.yourHome.inspectionDetails',
                                        inspectionDetails
                                    );
                                });
                        }
                    }

                    setQuoteIsStale(false);
                    QuoteProposalUtil.fetchQuoteProposal(
                        submissionVM,
                        setQuoteProposalCompleted,
                        setQuoteProposalLink,
                        authHeader,
                        setIsQuoteProposalFailed
                    );
                    updateWizardData(newSubmissionVM);
                    updateWizardSnapshot(newSubmissionVM);
                    checkuwIssues(newSubmissionVM);
                    // Recalculate should clear this message
                    setShowQuoteProposalError(false);

                    return response;
                })
                .catch(() => {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: 'Something went wrong in recalculate operation',
                        message: ''
                    });

                    const lobOfferingPath = `lobData.homeowners_EH.offerings.children[${selectedOffering.lobIndex}]`;
                    const offering = get(submissionVM, `${lobOfferingPath}.value`);

                    setStaleQuoteBranchCode(offering.branchCode);
                    setFieldsChangedOnCoveragePage(true);

                    return customQuote;
                })
                .finally(() => {
                    setIsQuoting(false);
                });
        },
        [isComponentValid, submissionVM, authHeader, viewModelService, policyType, updateWizardData, updateWizardSnapshot, checkuwIssues, wizardSnapshot, modalApi]
    );

    const syncCoveragesAndRecalculate = useCallback(
        async (value, changedPath, lobPath, quotePath) => {
            await syncCoverages(value, changedPath, lobPath, quotePath);

            return recalculate(lobPath, quotePath);
        }, [recalculate, syncCoverages]
    );

    const checkComparatorFlow = useCallback(() => {
        if (get(submissionVM, 'baseData.quoteSource_Ext.sourceType')) {
            set(submissionVM, 'quoteType_Ext.value', get(submissionVM.value, 'quoteType'));

            const isSourceTypeCompRater = get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'comprater';
            const quoteType = get(submissionVM, 'quoteType_Ext.value.code');

            return (isSourceTypeCompRater && quoteType === 'Quick');
        }

        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkDisable = useCallback(() => {
        if (submissionVM.value.lobData.homeowners_EH.acknowledgements === undefined) {
            return false;
        }

        return submissionVM.value.lobData.homeowners_EH.acknowledgements
            .some((acknowledgement) => acknowledgement.acknowledgeAnswerType === 'reject');
    }, [submissionVM]);

    const buyNow = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsBuying(true);

            const isSourceTypeCompRater = get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'comprater';

            if (isSourceTypeCompRater && submissionVM.value.quoteType === 'Quick') {
                // E1PAP1PC-10604 making this api call as a short to mid term solution. This will call out any validation errors on a comprater
                // transaction before going to the full flow with additional validation
                const validatedQuoteDataDto = await LoadSaveService.preValidate(
                    submissionVM.value,
                    authHeader
                );

                if (validatedQuoteDataDto?.errorsAndWarnings) {
                    set(submissionVM, 'errorsAndWarnings', validatedQuoteDataDto.errorsAndWarnings);
                }
            }

            const selectedOffering = getSelectedOffering(submissionVM);

            if ((policyType === 'HO3' || policyType === 'HF9')) {
                const currentCovA = get(
                    get(submissionVM, `lobData.homeowners_EH.offerings.value[${selectedOffering.lobIndex}].coverages.coverages`, []).find((cov) => cov.name === 'Coverage A - Dwelling'), 'terms[0].chosenTerm'
                );
                const previousCovA = get(
                    get(wizardSnapshot, `lobData.homeowners_EH.offerings.value[${selectedOffering.lobIndex}].coverages.coverages`, []).find((cov) => cov.name === 'Coverage A - Dwelling'), 'terms[0].chosenTerm'
                );

                if (submissionVM.value.quoteType === 'Full'
                    && currentCovA !== previousCovA) {
                    const inspectionDetails = await InspectionService.runInspection(get(submissionVM, 'quoteID.value'), authHeader);

                    set(
                        submissionVM,
                        'lobData.homeowners_EH.coverables.yourHome.inspectionDetails',
                        inspectionDetails
                    );
                }
            }

            // For EH we are not forcing recalculate on parperless email change, need to save on next as well
            const response = await LoadSaveService.saveAndQuoteSubmission(
                submissionVM.value,
                authHeader
            );

            set(submissionVM, 'value', response);

            updateWizardData(submissionVM);

            setIsBuying(false);

            return submissionVM;
        },
        [authHeader, isComponentValid, policyType, submissionVM, updateWizardData, wizardSnapshot]
    );

    const columnData = isQuoteDataAvailable ? generateColumnData(submissionVM) : [];

    const handleAcknowledgementsValueChange = useCallback((value) => {
        set(submissionVM, `lobData[${LOB}].acknowledgements`, value);
        updateWizardData(submissionVM);
    }, [updateWizardData, submissionVM]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isQuoting) {
            loadingMessage = translator(messages.ratingYourPolicyMessage);
        } else if (isBuying) {
            loadingMessage = translator(messages.creatingYourOfferingMessage);
        }

        return loadingMessage;
    }, [translator, isQuoting, isBuying]);

    useEffect(() => {
        /**
         * IAP-4729 : if user lands on coverage page without quote data
         * (eg. user clicks on recalculate(we call updateDraft to save replacement cost) 
         * and validation issues occurs for coverage page ten flow takes user to quote page without quote data), 
         * we will call saveAndQuote; We need quote data to display coverages
         */
        if (!isQuoteDataAvailable) {
            setIsBuying(true);
            LoadSaveService
                .saveAndQuoteSubmission(submissionVM.value, authHeader)
                .then((response) => {
                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    const oldQuoteData = get(newSubmissionVM, 'quoteData.value');
                    const respQuoteData = get(response, 'quoteData');

                    if (respQuoteData === undefined) {
                        set(response, 'quoteData', oldQuoteData);
                    }

                    set(newSubmissionVM, 'value', response);
                    updateWizardData(newSubmissionVM);
                    setIsBuying(false);
                    QuoteProposalUtil.fetchQuoteProposal(
                        newSubmissionVM,
                        setQuoteProposalCompleted,
                        setQuoteProposalLink,
                        authHeader,
                        setIsQuoteProposalFailed
                    );
                }).finally(() => {
                    setIsBuying(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        QuoteProposalUtil.fetchQuoteProposal(
            submissionVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (submissionVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            submissionVM.paymentOptionChanged = false;
        }
    }, [authHeader, submissionVM, submissionVM.paymentOptionChanged]);

    const openDocument = useCallback(
        (e, screenSource) => {
            e.preventDefault();

            switch (screenSource) {
                case 'MAIN':
                    if (!quoteIsStale && !fieldsChangedOnCoveragePage) {
                        // eslint-disable-next-line security/detect-non-literal-fs-filename
                        window.open(quoteProposalLink, '_blank');

                        return true;
                    }

                    setShowQuoteProposalError(true);
                    break;
                case 'SIDE_BY_SIDE':
                    // eslint-disable-next-line no-case-declarations
                    const quoteOfferingPath = 'quoteData.offeredQuotes';
                    // eslint-disable-next-line no-case-declarations
                    const quoteOfferings = get(submissionVM, `${quoteOfferingPath}.value`, []);
                    // eslint-disable-next-line no-case-declarations
                    const unquotedPeriod = quoteOfferings.find((offering) => offering.status !== 'Quoted');

                    if (!unquotedPeriod) {
                        // eslint-disable-next-line security/detect-non-literal-fs-filename
                        window.open(quoteProposalLink, '_blank');

                        return true;
                    }

                    setShowQuoteProposalError(true);
                    break;
                default:
                    break;
            }

            return true;
        }, [submissionVM, quoteIsStale, fieldsChangedOnCoveragePage, quoteProposalLink]
    );

    useEffect(() => {
        if (submissionVM.value.quoteType === 'Full' && hasUWIssues()) {
            // this will show UW issues popup with all the UW Issue types
            showUnderwritingIssuesPopup(submissionVM);
        } else if (submissionVM.value.quoteType === 'Quick') {
            // in quick quote flow we need to show uwIssues popup if the type is
            // 'BlocksQuote', 'BlocksQuoteRelease', 'Rejected'
            // internally checkuwIssues method will check for UWIssues,
            // so no need to add condition hasUWIssues in else if
            checkuwIssues(submissionVM);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isQuoting && !isBuying, [isBuying, isQuoting]);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            autoComplete: false
        },
        quotePageLoadingIndicator: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        quotePageContainer: {
            visible: isPageLoaded
        },
        quoteTableHO3: {
            columnData,
            tableData: generateTableData(submissionVM, columnData),
            tableDataExclusion: generateExclusionTableData(submissionVM, columnData),
            tableDataCondition: generateConditionTableData(submissionVM, columnData),
            underwritingIssues: get(submissionVM, 'errorsAndWarnings.underwritingIssues.value') === undefined ? [] : get(submissionVM, 'errorsAndWarnings.underwritingIssues.value'),
            filterUWIssuesInCustomOffering: false,
            submissionVM,
            modifiers: get(submissionVM, 'lobData.homeowners_EH.modifiers.value'),
            isComprater: checkComparatorFlow(),
            visible: !isBuying && !isSideBySideView && isQuoteDataAvailable,
            onDisableNextForTable: checkDisable,
            viewModelService,
            updateWizardData,
            authHeader,
            jumpTo,
            steps,
            onViewQuoteProposal: openDocument,
            isQuoteProposalReady: quoteProposalCompleted,
            setFieldsChangedOnCoveragePage,
            fieldsChangedOnCoveragePage,
            policyType,
            quoteIsStale,
            onScheduleChange,
            onCancel,
            isPageSubmitted,
            authUserData,
            isSideBySideView,
            updateIsPageSubmitted,
            setOptionalVisited,
            setIsPaperlessEmailUpdated
        },
        startDate: {
            visible: showQuoteStartDate
        },
        printPage: {
            visible: !showQuoteStartDate
        },
        e1pAcknowledgementComponentContainer: {
            visible: checkComparatorFlow() && !isSideBySideView
        },
        e1pAcknowledgementComponent: {
            acknowledgements: get(submissionVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: get(submissionVM, 'baseData.policyAddress.state.value.code'),
                name: translator({ id: get(submissionVM, 'baseData.policyAddress.state.value.name') })
            },
            onValueChange: handleAcknowledgementsValueChange,
            hideConsumerReportDisclosure: checkComparatorFlow(),
            policyType,
            lob: LOB
        },
        prefillReportsOrderedForRatingDiv: {
            visible: (policyType === 'HO3' || policyType === 'HF9') && isPropertyPrefillOrderedMessage && !isSideBySideView,
        },
        prefillReportsOrderedForRating: {
            className: !checkComparatorFlow() ? 'my-2' : undefined
        },
        quoteProposalValidationDiv: {
            visible: !isQuoting && showQuoteProposalError
        },
        protectiveDeviceRequireInfoMessageBasedOnCovADiv: {
            visible: PropertyFlowUtil.isProtectiveDeviceRequiredMessageVisible(submissionVM) && !isSideBySideView,
        },
        protectiveDeviceRequireInfoMessageBasedOnCovA: {
            message: PropertyFlowUtil.contentBasedOnState(submissionVM, translator)
        },
        ncrbDiscountAvailabilityMessageDiv: {
            visible: get(submissionVM, 'baseData.policyAddress.state.value.code') === 'NC' && !isSideBySideView
        },
        enterSideBySide: {
            visible: !isSideBySideView && (policyType === 'HO3' || policyType === 'HF9'),
            content: !submissionVM.sideBySide_Ext.value ? translator(e1pCommonMessages.enterSideBySideButtonText) : translator(e1pCommonMessages.viewSideBySideButtonText),
            icon: !submissionVM.sideBySide_Ext.value ? "mi-add" : undefined,
            onClick: (event) => enterSideBySide(event)
        },
        sideBySideContent: {
            transactionVM: submissionVM,
            visible: isSideBySideView && (policyType === 'HO3' || policyType === 'HF9'),
            backButtonFunction: (value) => {
                updateIsSideBySideView(value);
                // fecth quote proposal for selected quote, since we are coming back to coverage page
                QuoteProposalUtil.fetchQuoteProposal(
                    submissionVM,
                    setQuoteProposalCompleted,
                    setQuoteProposalLink,
                    authHeader,
                    setIsQuoteProposalFailed
                );
            },
            addVersionFunction: () => {
                /**
                 * IAP-3206, disable Quote Proposal button, so that user should recalculate, 
                 * to get the latest details on quote proposal document
                 */
                setQuoteProposalCompleted(false);
                onAddQuote();
            },
            lobOfferings: get(submissionVM, 'lobData.homeowners_EH.offerings.value'),
            quoteDataOfferings: get(submissionVM, 'quoteData.offeredQuotes.value'),
            updateQdd: () => { updateWizardData(submissionVM) },
            setNewQdd: (newQdd) => {
                set(submissionVM, 'value', newQdd);
                updateWizardData(submissionVM);
            },
            updateQuoteVersionName: (versionId, versionName) => { onUpdateQuoteVersionName(submissionVM.value, versionId, versionName); },
            setSelectedVersion: (versionId) => { onSetSelectedVersion(versionId); },
            onRecalculateSideBySide,
            onWithdrawSideBySideVersion: (versionId) => {
                /**
                 * IAP-3206, disable Quote Proposal button, so that user should recalculate, 
                 * to get the latest details on quote proposal document
                 */
                setQuoteProposalCompleted(false);
                onWithdrawSideBySideVersion(versionId);
            },
            onViewQuoteProposal: openDocument,
            changeSubmissionAndSync,
            changeSubmission,
            syncCoverages,
            onValidate,
            policyState: get(submissionVM, 'baseData.policyAddress.state.value.code'),
            hasExtendedAttributeEditability: authUserData?.permissions_Ext.includes('extendedattributeeditability_ext'),
            isQuoteProposalReady: quoteProposalCompleted,
            policyType,
            authUserData,
            underwritingIssues: get(submissionVM, 'errorsAndWarnings.underwritingIssues.value') === undefined
                ? []
                : get(submissionVM, 'errorsAndWarnings.underwritingIssues.value'),
            onScheduleChange
        },
        maxVersionsMessageDiv: {
            // never should be visible on the main coverage screen
            visible: showMaxVersionMessage && isSideBySideView
        },
        minVersionsMessageDiv: {
            // never should be visible on the main coverage screen
            visible: showMinVersionMessage && isSideBySideView
        },
        deleteVersionsMessageDiv: {
            visible: showDeleteVersionMessage && isSideBySideView
        },
        deleteVersionsMessage: {
            message: translator(e1pCommonMessages.deleteSelectedVersionError, {
                versionName: selectedVersionName
            })
        },
        ACVAppliedMessageDiv: {
            // IAP-2320: ACVAppliedBasedOnAge should only display the very first time they land on 
            // coverage page, after that ACVApplies will display
            visible: (policyType === 'HO3' || policyType === 'HF9') && ACVCoverageSelected && !isSideBySideView,
        },
        ACVAppliedMessage: {
            message: firstCoveragePageVisit && !optionalVisited ? translator(ehValidationAndInfoMessages.ACVAppliedBasedOnAge) : translator(ehValidationAndInfoMessages.ACVApplies)
        },
        windstormMitigationDiscountInfoMessageDiv: {
            visible: PropertyFlowUtil.isWindstormMitigationDiscountInfoMessagePresent(submissionVM) && !isSideBySideView
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        quoteProposalFailureErrorDiv: {
            visible: isPageLoaded && isQuoteProposalFailed
        },
    };

    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            showNext={false}
            showCancel={false}
            showPrevious={false}
            skipWhen={initialValidation}
            finish={unverfiedFlow && shouldSkipAdditionalInfo}
            isPageSubmittedWithErrors={isPageSubmitted}
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveCallbackMap: {
                        onBuyNow: () => buyNow().then(onNext),
                        onRecalculate: recalculate,
                        onChangeSubmissionAndSync: changeSubmissionAndSync,
                        onChangeSubmission: changeSubmission,
                        onSyncCoverages: syncCoverages,
                        onScheduleChange,
                        onStaleQuoteBranchCode,
                        onPrint: handlePrint,
                        updateWizardData,
                        onSyncCoveragesAndRecalculate: syncCoveragesAndRecalculate
                    }
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardData}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                );
            }}
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
