
import {
    isNil as _isNil,
    get as _get,
    filter as _filter,
    isEmpty as _isEmpty,
    set as _set
} from 'lodash';
import {
    e1pDateUtil
} from 'e1p-capability-hooks';
import { ContactService } from 'e1p-capability-gateway';

/**
 * Helper function which derives a formatted address string for the given TPI object.
 *
 * @param {Object} tpi The TPI object to have its address formatted. This object may contain
 *                     information structured according to the back-end types "TPISearchResulDTO"
 *                     or "AdditionalInterestDTO".
 * @returns {String} The formatted address string.
 */
function getFormattedTPIAddress(tpi) {
    let formattedTPIAddress = '';
    if (!tpi) {
        return formattedTPIAddress;
    }
    /**
     * Format the address if the address properties are directly available without going through
     * the contact-subtype properties, otherwise navigate to the appropriate contact address using
     * the "else if" blocks.
     */
    if (!_isNil(tpi.address) || !_isNil(tpi.addressLine1)) {
        const addressLine1 = !_isNil(tpi.address) ? tpi.address.address1 : tpi.addressLine1;
        const addressLine2 = !_isNil(tpi.address) ? tpi.address.address2 : tpi.addressLine2;
        const addressCity = !_isNil(tpi.address) ? tpi.address.city : tpi.city;
        const addressState = !_isNil(tpi.address) ? tpi.address.state : tpi.state;
        const addressPostalCode = !_isNil(tpi.address) ? tpi.address.postalCode : tpi.postalCode;
        const addressZip4 = !_isNil(tpi.address) ? tpi.address.zip4 : tpi.zip4;
        const addressZip5 = !_isNil(tpi.address) ? tpi.address.zip5 : tpi.zip5;

        if (_isNil(addressLine1)) {
            return formattedTPIAddress;
        }
        const definedAddressFields = [addressLine1];
        if (!_isNil(addressLine2) && addressLine2 !== '') {
            definedAddressFields.push(addressLine2);
        }

        definedAddressFields.push(addressCity);
        definedAddressFields.push(addressState);

        if (!_isNil(addressPostalCode) && addressPostalCode !== '') {
            definedAddressFields.push(addressPostalCode);
        } else if (
            !_isNil(addressZip4)
            && addressZip4 !== ''
            && !_isNil(addressZip5)
            && addressZip5 !== ''
        ) {
            // IAP-1709 : address zip should be 5-4
            definedAddressFields.push(`${addressZip5}-${addressZip4}`);
        }

        formattedTPIAddress = definedAddressFields.join(', ');
    } else if (!_isNil(tpi.company)) {
        formattedTPIAddress = getFormattedTPIAddress(tpi.company.primaryAddress);
    } else if (!_isNil(tpi.person)) {
        formattedTPIAddress = getFormattedTPIAddress(tpi.person.primaryAddress);
    }

    return formattedTPIAddress;
}

/**
 * Helper function which derives a formatted bank name string for the given TPI object.
 *
 * @param {Object} tpi The TPI object to have its bank name formatted. This object may contain
 *                     information structured according to the back-end types "TPISearchResulDTO",
 *                     "EAVehicleAddlInterestDTO", or "EHDwellingAddlInterestDTO".
 * @param {Boolean} useBankDTOPath A boolean indicating whether the BankDTO path should be used.
 * @returns {String} The formatted bank name string.
 */
function getFormattedTPIBankName(tpi, useBankDTOPath = false) {
    if (_isNil(tpi)) {
        return '';
    }

    const bankPrimaryName = useBankDTOPath ? tpi.bank.name1 : tpi.bankPrimaryName;
    const bankSecondaryName = useBankDTOPath ? tpi.bank.name2 : tpi.bankSecondaryName;
    const bankTertiaryName = useBankDTOPath ? tpi.bank.name3 : tpi.bankTertiaryName;

    if (_isNil(bankPrimaryName)) {
        return '';
    }
    const definedBankNameFields = [bankPrimaryName];
    if (!_isNil(bankSecondaryName) && bankSecondaryName !== '') {
        definedBankNameFields.push(bankSecondaryName);
    }
    if (!_isNil(bankTertiaryName) && bankTertiaryName !== '') {
        definedBankNameFields.push(bankTertiaryName);
    }

    const formattedTPIBankName = definedBankNameFields.join(' ');
    return formattedTPIBankName;
}

/**
 * Helper function which derives the TPI base path using the given product code.
 *
 * @param {String} productCode The code which represents the transaction's line of business (LOB).
 * @returns {String} The VMNode path which points to the base TPI DTO for the given LOB.
 */
function getTPIBasePath(productCode) {
    let tpiBasePath = '';
    if (productCode === 'PersonalAuto_EA') {
        tpiBasePath = 'lobData.personalAuto_EA.coverables.vehicles.children[0].additionalInterests';
    } else if (productCode === 'Homeowners_EH') {
        tpiBasePath = 'lobData.homeowners_EH.coverables.yourHome.additionalInterests';
    } else if (productCode === 'PersonalUmbrella_EU') {
        tpiBasePath = 'lobData.personalUmbrella_EU.additionalInsureds';
    }
    return tpiBasePath;
}

/**
 * @param {Object} transactionVM
 * @returns {Object} list of additionalInterests present on policy for EA
 */
function getAllTPIsForEA(transactionVM) {
    return _get(transactionVM, 'value.lobData.personalAuto_EA.coverables.vehicles', [])
        .reduce((allAdditionalInterests, vehicle) => {
            return [...allAdditionalInterests, ...vehicle.additionalInterests]
        }, [])
}

/**
 * filters interest type codes for given user such that it will return interest type codes
 * which are not present on policy for given user,
 * so that same TPI with same interest  cannot be added multiple times
 * @param {array of interest types code} addlInterestTypeCodes
 * @param {transactionVM} transactionVM
 * @param {tpiBasePath depends upon line} tpiBasePath
 * @param {public id of contact} existingContactPublicID
 * @returns {array of interest types code}
 */

const filterInterestTypesForGivenUser = (addlInterestTypeCodes, transactionVM, tpiBasePath, existingContactPublicID) => {
    const allExistingTPIsOnJob = _get(transactionVM, `${tpiBasePath}.value`, []);
    const currentUserWithAllRoles = allExistingTPIsOnJob.filter((tpi) => {
        const publicID = tpi.person ? _get(tpi, 'person.publicID') : _get(tpi, 'company.publicID');
        return publicID === existingContactPublicID;
    });
    const alreadyUsedAddlInterestTypeCodesForGivenUser = currentUserWithAllRoles
        .map((item) => {
            return item.addlInterestType;
        });
    const filteredAddlInterestTypeCodes = _filter(
        addlInterestTypeCodes,
        (typeCode) => {
            const isTypeCodeAvailable = alreadyUsedAddlInterestTypeCodesForGivenUser
                .indexOf(typeCode.code) === -1;
            return isTypeCodeAvailable;
        }
    );
    return filteredAddlInterestTypeCodes;
};

const searchContacts = async (additionalInterest, accountNumber, authHeader) => {
    const contactSubtype = _isEmpty(additionalInterest.person) ? 'Company' : 'Person';
    const contactSearchRequest = {
        accountID: accountNumber,
        contactSubtype: contactSubtype,
        addressLine1: _get(additionalInterest, 'addressLine1', _get(additionalInterest, 'address1')),
        addressLine2: _get(additionalInterest, 'addressLine2', _get(additionalInterest, 'address2')),
        city: _get(additionalInterest, 'city'),
        state: _get(additionalInterest, 'state'),
        country: _get(additionalInterest, 'country'),
        postalCode: _get(additionalInterest, 'postalCode', _get(additionalInterest, 'zipCode')),
    }
    switch (contactSubtype) {
        case 'Company':
            contactSearchRequest.companyName = _get(additionalInterest, 'company.name');
            break;
        case 'Person':
            contactSearchRequest.firstName = _get(additionalInterest, 'person.firstName');
            contactSearchRequest.middleName = _get(additionalInterest, 'person.middleName');
            contactSearchRequest.lastName = _get(additionalInterest, 'person.lastName');
            break;
        default:
            break;
    }
    return Promise.resolve(ContactService.searchContacts(contactSearchRequest, authHeader))
};

const mapChosenContactToTPIContact = (additionalInterest, chosenContact) => {
    // Update address field with chosen contact instead of user filled entries
    _set(additionalInterest, 'address.address1', chosenContact.addressLine1);
    _set(additionalInterest, 'address.address2', chosenContact.addressLine2);
    if (chosenContact.contactSubtype === 'Person') {
        if (chosenContact.dateOfBirth) {
            _set(additionalInterest, 'person.dateOfBirth', e1pDateUtil.convertToUTC(chosenContact.dateOfBirth));
        }
        _set(additionalInterest, 'person.firstName', chosenContact.firstName);
        _set(additionalInterest, 'person.middleName', chosenContact.middleName);
        _set(additionalInterest, 'person.lastName', chosenContact.lastName);
        _set(additionalInterest, 'person.primaryAddress.addressLine1', chosenContact.addressLine1);
        _set(additionalInterest, 'person.primaryAddress.addressLine2', chosenContact.addressLine2);
        _set(additionalInterest, 'person.primaryAddress.city', chosenContact.city);
        _set(additionalInterest, 'person.primaryAddress.state', chosenContact.state);
        _set(additionalInterest, 'person.primaryAddress.country', chosenContact.country);
        _set(additionalInterest, 'person.primaryAddress.postalCode', chosenContact.postalCode);
        _set(additionalInterest, 'person.publicID', chosenContact.publicID);
    } else if (chosenContact.contactSubtype === 'Company') {
        _set(additionalInterest, 'company.name', chosenContact.displayName);
        _set(additionalInterest, 'company.primaryAddress.addressLine1', chosenContact.addressLine1);
        _set(additionalInterest, 'company.primaryAddress.addressLine2', chosenContact.addressLine2);
        _set(additionalInterest, 'company.primaryAddress.city', chosenContact.city);
        _set(additionalInterest, 'company.primaryAddress.state', chosenContact.state);
        _set(additionalInterest, 'company.primaryAddress.country', chosenContact.country);
        _set(additionalInterest, 'company.primaryAddress.postalCode', chosenContact.postalCode);
        _set(additionalInterest, 'company.publicID', chosenContact.publicID);
    }
}

const mapTPIDataToPreferredTPI = (selectedTPI, preferredTPI) => {
    _set(preferredTPI, 'loanNumber', _get(selectedTPI, 'loanNumber'))
    _set(preferredTPI, 'sendBillToTPIInd', _get(selectedTPI, 'sendBillToTPIInd'))
    _set(preferredTPI, 'convertTPIToPayerInd', _get(selectedTPI, 'convertTPIToPayerInd'))
    _set(preferredTPI, 'assignAtRenewalInd', _get(selectedTPI, 'assignAtRenewalInd'))
    _set(preferredTPI, 'billingAssignedDate', _get(selectedTPI, 'billingAssignedDate'))
}

export default {
    getFormattedTPIAddress,
    getFormattedTPIBankName,
    getTPIBasePath,
    filterInterestTypesForGivenUser,
    searchContacts,
    mapChosenContactToTPIContact,
    mapTPIDataToPreferredTPI,
    getAllTPIsForEA
};
