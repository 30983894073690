import { defineMessages } from 'react-intl';

export default defineMessages({

    surcharges: {
        id: 'submission.ea.Quote.Surcharges Title',
        defaultMessage: 'Surcharges, Taxes and Fees'
    },

    surchargesTotal: {
        id: 'submission.common.Quote.Surcharge Total',
        defaultMessage: 'Total'
    },

    surchargeHeaderCharge: {
        id: 'submission.ea.Quote.Surcharge Header Charge',
        defaultMessage: 'Charge'
    },

    surchargeHeaderAmount: {
        id: 'submission.ea.Quote.Surcharge Header Amount',
        defaultMessage: 'Amount'
    },
    surchargeName: {
        id: 'submission.ea.Quote.Motor Vehicle Law Enforcement fee for {make} {model} {year}',
        defaultMessage: 'Motor Vehicle Law Enforcement fee for {make} {model} {year}'
    }

});
