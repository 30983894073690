import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {
    cloneDeep as _cloneDeep,
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    includes as _includes,
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { EndorsementService } from 'e1p-capability-policychange';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import {
    useSniUtil, useLandingPageUtil,
    useNewBusinessUtil,
    useEffectiveDateUtil
} from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import moment from 'moment';
import appConfig from 'app-config';
import messages from './InsuredDetailsPage.messages';
import metadata from './InsuredDetailsPage.metadata.json5';
import requiredMetadata from './InsuredDetailsPage.requiredness';

const LOB = 'personalAuto_EA';

function InsuredDetailsPage(props) {
    const modalApi = useModal();
    const {
        wizardData: policyChangeVM, updateWizardData, wizardSnapshot,
        isSkipping, steps, jumpTo, updateWizardSnapshot, authUserData
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [policyIsBackDated, setPolicyIsBackDated] = useState(false);
    const [effDateChanged, setEffDateChanged] = useState(false);
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [initialDrivers, setInitialDrivers] = useState([]);
    const [requiredFields, updateRequiredFields] = useState([]);
    const policyState = _get(policyChangeVM, 'baseData.policyAddress.state.value.code');
    const { opCo } = useContext(AmfamOktaTokenContext);
    const { daysInFutureForBookroll, daysInFuture, daysInPast } = appConfig.dateInputConfig;
    const {
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        onValidate,
        registerInitialComponentValidation
    } = useValidation('InsuredDetailsPage');
    const {
        getProducerDetailsFromExternalId
    } = useNewBusinessUtil(
        policyChangeVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        () => { } // can't change in policy change so parm not needed
    );
    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    const {
        getEffectiveDateBounds
    } = useEffectiveDateUtil(policyChangeVM, updateWizardData, false);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        const initialRequiredFields = ['ownsHomeIndicator', 'applicantsResideInSameStateInd', 'isUmbrellaAssociated']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
    }, [policyState]);

    const { maxEffectiveDate, minEffectiveDate } = useMemo(
        () => 
            // If Book Transfer is Yes, allow quoting up to 120 days in the future, otherwise 60 days
             producerCodeDetails.bookTransferIndicator ? getEffectiveDateBounds(
                policyChangeVM.baseData.periodStartDate.value,
                policyChangeVM.baseData.periodEndDate.value,
                daysInPast,
                daysInFutureForBookroll
            ) : getEffectiveDateBounds(
                policyChangeVM.baseData.periodStartDate.value,
                policyChangeVM.baseData.periodEndDate.value,
                daysInPast,
                daysInFuture
            )
        ,
        [
            getEffectiveDateBounds,
            policyChangeVM.baseData.periodEndDate.value,
            policyChangeVM.baseData.periodStartDate.value,
            producerCodeDetails.bookTransferIndicator,
            daysInFutureForBookroll,
            daysInFuture,
            daysInPast

        ]
    );

    useEffect(() => {
        // keeping the IF condition here, but under no circumstances should it be undefined
        if (policyChangeVM.baseData.externalID_Ext.value) {
            (async () => {
                const details = await getProducerDetailsFromExternalId(
                    policyChangeVM.baseData.externalID_Ext.value
                );

                setProducerCodeDetails(details);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setBackdateInformation = () => {
        const policyEffectiveDate = moment(policyChangeVM.baseData.effectiveDate.value);
        const effectiveDateBeforeToday = moment().isAfter(policyEffectiveDate, 'd');

        setPolicyIsBackDated(effectiveDateBeforeToday);
    };

    useEffect(() => {
        // _set(policyChangeVM, 'baseData.producerCode_Ext.value', policyChangeVM.value.baseData.producerCode);
        // getAndSetLocationCodes(_get(policyChangeVM, 'baseData.policyAddress.state.value.code'));
        setBackdateInformation();

        const existingDrivers = _cloneDeep(_get(policyChangeVM.value, 'lobData.personalAuto_EA.coverables.drivers'));

        setInitialDrivers(existingDrivers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasTransactionStarted = useCallback(() => !!_get(policyChangeVM, 'quoteID.value'), [policyChangeVM]);

    useEffect(() => {
        registerInitialComponentValidation(hasTransactionStarted);
    }, [hasTransactionStarted, registerInitialComponentValidation]);

    const { createSecondaryNamedInsuredVM, removeSni, addSniInCoverable } = useSniUtil(
        policyChangeVM,
        updateWizardData,
        viewModelService,
        LOB,
        disregardFieldValidation
    );

    const addNewPrimaryNamedInsured = useCallback(() => {
        const primaryNamedInsuredObj = {
            person: {
                lastName: '',
                firstName: '',
                dateOfBirth: {
                    year: undefined,
                    month: undefined,
                    day: undefined
                },
                // save primary address
                primaryAddress: (() => {
                    // default new person address to prior pni
                    const priorPniAddress = _get(
                        policyChangeVM,
                        'baseData.policyAddress.value'
                    );

                    // Needs new public ID to make it a new adress in the DB
                    _set(priorPniAddress, 'publicID', undefined);

                    return priorPniAddress;
                })()
            }
        };
        const { _dtoName, _xCenter } = policyChangeVM.lobData[LOB].primaryNamedInsured;
        const primaryNamedInsuredVM = viewModelService.create(
            primaryNamedInsuredObj,
            _xCenter,
            _dtoName
        );

        _set(
            policyChangeVM,
            `lobData[${LOB}].primaryNamedInsured.value`,
            primaryNamedInsuredVM.value
        );
        updateWizardData(policyChangeVM);
    }, [policyChangeVM, updateWizardData, viewModelService]);

    const syncNamedInsuredToDrivers = useCallback(() => {
        // sync NI changes to Drivers
        const drivers = _get(policyChangeVM.value, 'lobData.personalAuto_EA.coverables.drivers');
        const pni = _get(policyChangeVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
        const sni = _get(policyChangeVM.value, 'lobData.personalAuto_EA.secondaryNamedInsured');
        const pniDriver = drivers.find((driver) => driver.person.publicID === pni.person.publicID);
        // SNI may be undefined so being null safe there
        const sniDriver = drivers.find((driver) => driver.person.publicID === sni?.person?.publicID);

        _set(policyChangeVM, 'baseData.accountHolder_Ext.value', pni.person);

        // updates to existing pni contact
        if (pniDriver && pni) {
            _set(pniDriver, 'person', { ...pniDriver.person, ...pni.person });
        }

        if (pni && !pniDriver) {
            // check if PNI is already present in existing drivers
            // if present then add in the drivers, but do not create new here, that is handled in driver page
            if (!_isEmpty(initialDrivers)) {
                const initialPniDriver = initialDrivers
                    .find((driver) => driver.person.publicID === pni?.person?.publicID);

                if (initialPniDriver) {
                    _set(initialPniDriver, 'person', pni.person);
                    _set(initialPniDriver, 'relationshipToNI', undefined);
                    drivers.push(initialPniDriver);

                    _set(policyChangeVM.value, 'lobData.personalAuto_EA.coverables.drivers', drivers);
                }
            }
        }

        // updates to existing sni contact
        if (sniDriver && sni) {
            _set(sniDriver, 'person', { ...sniDriver.person, ...sni.person });
        }

        if (sni && !sniDriver) {
            // check if SNI is already present in existing drivers
            // if present then add in the drivers, but do not create new here, that is handled in driver page
            if (!_isEmpty(initialDrivers)) {
                const initialSniDriver = initialDrivers
                    .find((driver) => driver.person.publicID === sni?.person?.publicID);

                if (initialSniDriver) {
                    _set(initialSniDriver, 'person', sni.person);
                    _set(initialSniDriver, 'relationshipToNI', sni?.relationshipToNI);
                    drivers.push(initialSniDriver);

                    _set(policyChangeVM.value, 'lobData.personalAuto_EA.coverables.drivers', drivers);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialDrivers, policyChangeVM.value]);

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);
            // Need this for validation rules
            _set(policyChangeVM, 'flowStepIDs_Ext.value', ['namedinsured']);
            _set(policyChangeVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);
            syncNamedInsuredToDrivers();

            if (policyChangeVM.jobID.value === undefined) {
                const response = await EndorsementService.checkEffectiveDateIsValid(
                    [
                        policyChangeVM.value.policyNumber,
                        policyChangeVM.value.baseData.effectiveDate
                    ],
                    authHeader
                );

                if (response) {
                    const saveResponse = await EndorsementService.saveEndorsement(
                        [policyChangeVM.value],
                        authHeader
                    );

                    _set(policyChangeVM, 'value', saveResponse);
                    updateWizardData(policyChangeVM);
                    setIsSavingEndorsement(false);

                    return policyChangeVM;
                }
            } else {
                const saveResponse = await EndorsementService.saveEndorsement(
                    [policyChangeVM.value],
                    authHeader
                );

                _set(policyChangeVM, 'value', saveResponse);
                updateWizardData(policyChangeVM);
                // sni exist or not is being checked in the method itself, so no need to check here
                addSniInCoverable();
                setIsSavingEndorsement(false);

                return policyChangeVM;
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [addSniInCoverable, authHeader, isComponentValid, policyChangeVM, syncNamedInsuredToDrivers, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    /**
                     * E1PAP1PC-14986 :
                     * wizardData and wizardSnapshot not being equal due to
                     * some defaulting on each page so doing this temp fix
                     */
                    if (policyChangeVM.baseData.value.producerCode) {
                        _set(policyChangeVM,
                            'baseData.value.producerCode_Ext',
                            policyChangeVM.baseData.value.producerCode);
                    }

                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, policyChangeVM, updateWizardSnapshot]
    );

    const handleAddressValueChange = useCallback(
        (newVal, localPath) => {
            _set(policyChangeVM, localPath, newVal);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    const generateOverrides = useCallback(() => {
        const overrides = {};

        overrides.primaryNamedInsured = {
            isPNI: true
        };

        return overrides;
    }, []);

    const swapPniSni = useCallback(() => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: e1pCommonMessages.swapPniSniTitle,
            message: e1pCommonMessages.swapPniSniMessage,
            confirmButtonText: e1pCommonMessages.confirm,
            cancelButtonText: e1pCommonMessages.cancel
        }).then(
            (result) => {
                if (result !== 'cancel') {
                    const pniPerson = _cloneDeep(
                        policyChangeVM.lobData[LOB].primaryNamedInsured.person.value
                    );
                    const sniPerson = _cloneDeep(
                        policyChangeVM.lobData[LOB].secondaryNamedInsured.person.value
                    );
                    let drivers = _get(policyChangeVM.value, `lobData[${LOB}].coverables.drivers`);
                    let sniDriver = drivers
                        .find((driver) => driver.person.publicID === sniPerson?.publicID);

                    if (!sniDriver) {
                        addSniInCoverable();
                        drivers = _get(policyChangeVM.value, `lobData[${LOB}].coverables.drivers`);
                        sniDriver = drivers
                            .find((occupant) => occupant.person.publicID === sniPerson.publicID);
                        // setting driver role for SNI to operator, since sni is newly added.
                        _set(sniDriver, 'policyDriverRoleType', 'operator');
                    }

                    const pniDriver = drivers
                        .find((occupant) => occupant.person.publicID === pniPerson.publicID);

                    const relationshipToNI = _get(policyChangeVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`, 'notrelated');

                    _set(pniDriver, 'relationshipToNI', relationshipToNI);
                    _set(sniDriver, 'relationshipToNI', undefined);
                    // now swap PNI and SNI
                    policyChangeVM.lobData[LOB].primaryNamedInsured.person.value = sniPerson;
                    policyChangeVM.lobData[LOB].secondaryNamedInsured.person.value = pniPerson;
                    // since we are swapping PNI and SNI, we need remove publicID, FixedID and integrationID from both policyContactRoles
                    // i.e PNI and SNI
                    _set(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.fixedId.value`, undefined);
                    _set(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.publicID.value`, undefined);
                    _set(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.integrationId.value`, undefined);
                    _set(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.fixedId.value`, undefined);
                    _set(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.publicID.value`, undefined);
                    _set(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.integrationId.value`, undefined);

                    // If paperless Email id exist and pni email id is undefiend
                    // then update pni email to paperless email
                    if (!_get(policyChangeVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value')
                        && !!_get(policyChangeVM, 'lobData.personalAuto_EA.paperlessEmail.value')) {
                        _set(policyChangeVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value',
                            _get(policyChangeVM, 'lobData.personalAuto_EA.paperlessEmail.value'));
                    }

                    updateWizardData(policyChangeVM);
                }
            },
            () => { }
        );
    }, [addSniInCoverable, policyChangeVM, modalApi, updateWizardData]);

    const getLoadingMessage = useCallback(
        () => {
            let loadingMessage = translator(messages.loadingNextPageMessage);

            if (isSavingCurrentPageChanges) {
                loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
            } else if (isStandardizingAddress) {
                loadingMessage = translator(messages.standardizingAddressMessage);
            }

            return loadingMessage;
        }, [isSavingCurrentPageChanges, isStandardizingAddress, translator]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            showRequired: true,
            autoComplete: false
        },
        insuredDetailsPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping
                && !isStandardizingAddress && !isSavingCurrentPageChanges,
            text: getLoadingMessage()
        },
        insuredDetailsPageContainer: {
            visible: !isSavingEndorsement && !isSkipping
                && !isStandardizingAddress && !isSavingCurrentPageChanges
        },
        mailingAndBillingAddressComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            showErrors: isPageSubmitted,
            wizardSnapshot
        },
        addSni: {
            visible: !_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        agencyName: {
            value: producerCodeDetails.agencyName
        },
        bookTransferIndicator: {
            value: producerCodeDetails.bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: producerCodeDetails.serviceCenterIndicatorValue
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        swapPniSni: {
            visible: !!_get(policyChangeVM, `value.lobData[${LOB}].secondaryNamedInsured.person.publicID`)
                && !!_get(policyChangeVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`)
        },
        sniContainer: {
            visible: !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        removeSni: {
            visible: !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        periodStartDate: {
            dateDTO: policyChangeVM.baseData.periodStartDate,
            updateDateDto: () => updateWizardData(policyChangeVM)
        },
        excessLiabilityInd: {
            required: true,
            visible: _includes(requiredFields, 'isUmbrellaAssociated'),
        },
        changeEffectiveDate: {
            dateDTO: policyChangeVM.baseData.effectiveDate,
            updateDateDto: () => {
                setEffDateChanged(true);
                setBackdateInformation();
                updateWizardData(policyChangeVM);
            },
            minDate: minEffectiveDate,
            maxDate: maxEffectiveDate,
            showErrors: isPageSubmitted,
            onValidate
        },
        periodEndDate: {
            dateDTO: policyChangeVM.baseData.periodEndDate,
            updateDateDto: () => updateWizardData(policyChangeVM)
        },
        coverageRateAsOfDate: {
            dateDTO: policyChangeVM.baseData.periodRateAsOfDate,
            updateDateDto: () => updateWizardData(policyChangeVM),
            defaultToToday: true,
            showErrors: true,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        policyInformationInfoContainer: {
            columns: authUserData.permissions_Ext.includes('editrateasofdate')
                ? ['0.25fr', '0.25fr', '0.25fr', '0.25fr'] : ['0.25fr', '0.25fr', '0.25fr']
        },
        dateOfBirth: {
            updateDateDto: () => updateWizardData(policyChangeVM),
            dateDTO: policyChangeVM.lobData.personalAuto_EA?.primaryNamedInsured.person.dateOfBirth,
        },
        insuredResidenceAddress: {
            addressVM: _get(policyChangeVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate,
            onAddressChange: (value, path) => handleAddressValueChange(value, `baseData.policyAddress.${path}`),
            viewOnlyMode: true,
            showParentLoader: setIsStandardizingAddress
        },
        changePniComponent: {
            accountNumber: _get(policyChangeVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(policyChangeVM, 'baseData.accountNumber.value'),
            allowNewContact: false,
            excludedContacts: [
                _get(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            drivers: _get(
                policyChangeVM.value,
                `lobData[${LOB}].coverables.drivers`,
                undefined
            )
        },
        changeSniComponent: {
            accountNumber: _get(policyChangeVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(policyChangeVM, 'baseData.accountNumber.value')
                && !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            excludedContacts: [
                _get(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            drivers: _get(
                policyChangeVM.value,
                `lobData[${LOB}].coverables.drivers`,
                undefined
            )
        },
        lossQuestionEmptyContainer: {
            visible: !policyIsBackDated || !effDateChanged,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 3 : 2
        },
        lossQuestionContainer: {
            visible: policyIsBackDated && effDateChanged,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 3 : 2
        },
        lossOccurredBetweenEffectiveDateAndCreateDateInd: {
            defaultValue: policyChangeVM.value.hasLossOnOrAfterEffDate
        },
        rentOrOwn: {
            required: _includes(requiredFields, 'ownsHomeIndicator')
        },
        applicantsResideInSameStateInd: {
            required: true,
            visible: _includes(requiredFields, 'applicantsResideInSameStateInd'),
        },
        agencyInformationMainDiv: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: policyChangeVM,
            authHeader,
            LOB: 'personalAuto_EA'
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate,
            onRemoveSni: removeSni,
            onAddNewPni: addNewPrimaryNamedInsured,
            onSwapPniSni: swapPniSni
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    const saveAndQuote = useCallback(
        async () => {
            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            _set(policyChangeVM, 'value', quoteResponse);
            updateWizardData(policyChangeVM);
            addSniInCoverable();

            return policyChangeVM.value;
        },
        [addSniInCoverable, authHeader, policyChangeVM, updateWizardData]
    );

    const showCustom = true;

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);
            syncNamedInsuredToDrivers();
            policyChangeVM.value = await saveAndQuote();
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = _get(policyChangeVM, 'errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [isComponentValid, syncNamedInsuredToDrivers, policyChangeVM, saveAndQuote, updateWizardData, updateWizardSnapshot, getLandingPageIndexForQuotedJob, steps, jumpTo]
    );

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showPrevious={false}
            showCustom={showCustom}
            onCustom={onCustom}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

InsuredDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(InsuredDetailsPage);
