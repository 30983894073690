import React, { useEffect, useState } from 'react';
import { isEmpty as _isEmpty } from 'lodash';
import { Grid, GridItem } from '@jutro/layout';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { RoofScoreService } from 'e1p-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import messages from './EHRoofScoreComponent.messages';

function EHRoofScoreComponent(props) {
    const { quoteID, shouldCallApi } = props;
    const [roofScoreDataResponse, setRoofScoreDataResponse] = useState([]);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();

    /* empty params triggers this block whenever the page loads or re-renders */
    // call to external Api is only done when policy Type is HO3 and HF9, else we will not call the API
    // shouldCallApi is being passed from parent component, there we are checking policy type
    useEffect(() => {
        if (_isEmpty(roofScoreDataResponse) && quoteID && shouldCallApi) {
            RoofScoreService.getRoofScoreData(
                quoteID,
                authHeader
            ).then((response) => {
                if (response.roofScoreRecords !== undefined
                    && response.roofScoreRecords[0].perilScores?.length > 0) {
                    setRoofScoreDataResponse(response.roofScoreRecords[0].perilScores);
                }
            }).finally(() => {
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            columns={['100px', '100px']}
            gap="small"
            vgap="none"
            id="Grid"
            tag="div"
            className="gridHeaderBorder"
        >
            <GridItem className="labelItem" key="perilNameTitle">
                {translator(messages.perilName)}
            </GridItem>
            <GridItem className="labelItem" key="perilValueTitle">
                {translator(messages.perilValue)}
            </GridItem>
            {
                roofScoreDataResponse.map((score, index) => (
                        <React.Fragment key={`roofScoreGridItem-${index}`}>
                            <GridItem className="gridCell" key={`perilNameGridItem-${index}`}>
                                {score.perilName}
                            </GridItem>
                            <GridItem className="gridCell" key={`perilValueGridItem-${index}`}>
                                {score.perilValue}
                            </GridItem>
                        </React.Fragment>
                    ))
            }
        </Grid>
    );
}

EHRoofScoreComponent.propTypes = {
    quoteID: PropTypes.string.isRequired,
    shouldCallApi: PropTypes.bool
};

EHRoofScoreComponent.defaultProps = {
    shouldCallApi: true
};
export default EHRoofScoreComponent;
