import { defineMessages } from 'react-intl';

export default defineMessages({
    riskAddressSameAsResidence: {
        id: 'quoteandbind.eh.views.component.riskAddressComponent.Risk address same as Residence Address',
        defaultMessage: 'Risk address same as Residence Address'
    },
    riskAddressSameAsResidenceYes: {
        id: 'quoteandbind.eh.views.component.riskAddressComponent.Risk address same as Residence Address.Yes',
        defaultMessage: 'Yes'
    },
    riskAddressSameAsResidenceNo: {
        id: 'quoteandbind.eh.views.component.riskAddressComponent.Risk address same as Residence Address.No',
        defaultMessage: 'No'
    },
    riskAddress: {
        id: 'quoteandbind.eh.views.component.riskAddressComponent.Risk Address',
        defaultMessage: 'Risk Address'
    }
});
