import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import {
    ModalNext,
    ModalHeader,
    ModalBody
} from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './E1PNoteDetailModal.metadata.json5';
import styles from './E1PNoteDetailModal.module.scss';

function E1PNoteDetailModal(props) {
    const {
        NoteDetailVM,
        title,
        viewModelService,
        authUserData,
        policyNumber,
        isOpen,
        onResolve,
        onReject
    } = props;
    const [noteVM, updateNoteVM] = useState(NoteDetailVM);
    const { isComponentValid, onValidate } = useValidation('E1PNoteDetailModal');

    // internal users has permission to create or edit internal notes
    const isInternalUser = authUserData?.permissions_Ext.includes('createintnote') || authUserData?.permissions_Ext.includes('editintnote');

    const onSaveButtonClick = useCallback(() => {
        onResolve(noteVM.value);
    }, [noteVM, onResolve]);

    const handleCancelButtonClick = useCallback(
        (evt) => {
            evt.stopPropagation();
            onReject();
        },
        [onReject]
    );

    const getDefaultValueForSecurityLevel = useCallback(() => {
        if (isInternalUser) {
            return 'internalonly';
        }

        return 'unrestricted';
    }, [isInternalUser]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            onSaveButtonClick,
            onCancelButtonClick: handleCancelButtonClick
        }
    };

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showRequired: true,
            autoComplete: false
        },
        notesRelatedTo: {
            value: policyNumber || 'Unassigned'
        },
        saveNoteButton: {
            disabled: !isComponentValid
        },
        notesSecurityLevel: {
            visible: isInternalUser,
            defaultValue: getDefaultValueForSecurityLevel()
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                noteVM,
                id,
                path,
                overrideProps
            ),
        [noteVM, overrideProps]
    );

    /**
     * Helper callback for writing values to the view model.
     */
    const writeValue = useCallback(
        (value, path) => {
            const newNoteVM = viewModelService.clone(noteVM);

            _.set(newNoteVM, path, value);
            updateNoteVM(newNoteVM);
        },
        [noteVM, viewModelService]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ModalHeader title={title} className={styles.modalTitle} />
            <ModalBody className={styles.popoverWidth}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={noteVM}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
        </ModalNext>
    );
}

E1PNoteDetailModal.propTypes = wizardProps;
export default E1PNoteDetailModal;
