import React, {
    useCallback, useState
} from 'react';
import {
    get
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import metadata from './EUVehicleOperatorPage.metadata.json5';

function ViewEUVehicleOperatorPage(props) {
    const {
        wizardData: policyViewVM, isSkipping
    } = props;
    const [checkScrolling, setCheckScrolling] = useState(false);

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            readOnly: true
        },
        vehicleOperatorPageLoadingIndicator: {
            loaded: !isSkipping
        },
        vehicleOperatorPageContainer: {
            visible: !isSkipping
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleOperatorsContainer')
        },
        EUVehicleOperatorGrid: {
            key: get(policyViewVM, 'lobData.personalUmbrella_EU.vehicleOperators', []).length,
            transactionVM: policyViewVM,
            drivers: get(policyViewVM, 'lobData.personalUmbrella_EU.vehicleOperators', []),
            path: 'lobData.personalUmbrella_EU.vehicleOperators.children',
            onValidate: () => {},
            showErrors: false,
            setCheckScrolling,
            updateWizardData: () => {},
            viewOnlyMode: true
        },
        addVehicleOperator: {
            visible: false
        }
    };

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            addVehicleOperator: undefined,
            onValidate: () => {}
        }
    };

    // if validation errors are thrown we cant jump to cov page

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => policyViewVM,
        [policyViewVM]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyViewVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewEUVehicleOperatorPage.propTypes = wizardProps;
export default ViewEUVehicleOperatorPage;
