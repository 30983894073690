import appConfig from 'app-config';
import {get as _get} from 'lodash';

function e1pUserAccessUtil(authUserData, opCo) {
    const environment = _get(appConfig, 'env.AMFAM_ENV', 'local');
    const envsWithQuotingTurnedOff = ['uat', 'prod'];
    const isQuotingOn = !envsWithQuotingTurnedOff.includes(environment);

    function isNewQuoteFunctionalityVisible() {
        return (
            isQuotingOn ||
            opCo !== 'MSA' || 
            !authUserData?.roles_Ext.includes('ext_sales_service')
        )
    }

    /**
     * IAP-6122, for MSA External user should not be able to make payment
     * and bind the transaction on PROD and UAT enviroment.
     * @returns Boolean
     */
    function isUserAuthorisedToPayAndBind() {
        const isMSA = opCo === 'MSA';
        const isExternalAgent = authUserData?.roles_Ext.includes('ext_sales_service');
        const enviromentsToCheck = ['uat', 'prod'];
        const shouldValidateOnEnvironment = enviromentsToCheck.includes(environment);
        
        if (shouldValidateOnEnvironment && isMSA) {
            return !isExternalAgent;
        }

        return true;
    }

    return {
        isNewQuoteFunctionalityVisible,
        isUserAuthorisedToPayAndBind
    };
}

export default  e1pUserAccessUtil;
