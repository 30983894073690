import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class AutoLossService {
    /**
     * Orders auto loss reports
     * @param {String} jobNumber String
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static loadAutoLosses = (jobNumber, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('autoloss'),
            'getAutoIncidents',
            [jobNumber],
            additionalHeaders
        );
    }

    /**
     * force orders auto incidents
     * @param {String} jobNumber 
     * @param {String} reportSource 
     * @param {String} publicID 
     * @param {Object} additionalHeaders 
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static forceOrderAutoIncidents = (jobNumber, reportSource, publicID, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('autoloss'),
            'forceOrderAutoIncidents',
            [jobNumber, reportSource, publicID],
            additionalHeaders
        );
    }

    static saveAutoOverrides = (
        jobNumber,
        autoLossRecords,
        autoViolationRecords,
        additionalHeaders = {}
    ) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('autoloss'),
            'saveOverrides',
            [jobNumber, autoLossRecords, autoViolationRecords],
            additionalHeaders
        );
    }
}
