import React, {
    useCallback,
    useContext,
    useState,
    useEffect
} from 'react';
import {
    get as _get, set as _set
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import {
    useViewPolicyUtil
} from 'e1p-capability-hooks';
import moment from 'moment';
import messages from '../InsuredDetailsPage.messages';
import metadata from '../InsuredDetailsPage.metadata.json5';


const LOB = 'homeowners_EH';

function InsuredDetailsPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        authUserData,
        markFurthestStep,
        steps,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled
    } = props;
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [lapseInCoverage, updateLapseInCoverage] = useState(undefined);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [policyIsBackDated, setPolicyIsBackDated] = useState(false);
    const [effDateChanged, setEffDateChanged] = useState(false);

    const {
        getLandingPageIndexForViewOnlyPolicy,
        getProducerDetails
    } = useViewPolicyUtil();

    const policyForm = _get(renewalVM, 'lobData.homeowners_EH.policyFormType.value');
    const policyType = _get(renewalVM, 'lobData.homeowners_EH.value.policyType');
    const usageType = translator({ id: _get(renewalVM, 'lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value.name') });

    const setBackdateInformation = () => {
        const policyEffectiveDate = moment(renewalVM.baseData.effectiveDate.value);
        const effectiveDateBeforeToday = moment().isAfter(policyEffectiveDate, 'd');

        setPolicyIsBackDated(effectiveDateBeforeToday);
    };

    useEffect(() => {
        if (isPageJumpEnabled) {
            updateIsPageJumpEnabled(false);
            jumpTo(getLandingPageIndexForViewOnlyPolicy(LOB, steps, 'Renewal'), true);
        }

        markFurthestStep((steps.length - 1));
        // Only check one time on component mount if we want to jump to other page
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setBackdateInformation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // keeping the IF condition here, but under no circumstances should it be undefined
        if (_get(renewalVM, 'baseData.externalID_Ext.value')) {
            getProducerDetails(
                renewalVM.baseData.externalID_Ext.value, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
            }).catch((error) => {
                /**
                 * E1PAP1PC-15099 :
                 * This is rare scenario; will never happen in production
                 * When we bound policy using dummy producer code
                 * and when other user tries to view this policy we get exception from service
                 */
                if (renewalVM.baseData.value.exceptions_Ext) {
                    renewalVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        renewalVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(renewalVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            renewalVM.lobData.homeowners_EH.priorPolicies.value !== undefined
            && renewalVM.lobData.homeowners_EH.priorPolicies.length > 0
        ) {
            updateLapseInCoverage(
                !renewalVM.lobData.homeowners_EH.priorPolicies.value[0].continuousCoverageInd
            );
        }
    }, [
        renewalVM.lobData.homeowners_EH.priorPolicies.length,
        renewalVM.lobData.homeowners_EH.priorPolicies.value]);

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => renewalVM,
        [renewalVM]
    );

    const generateOverrides = useCallback(() => {
        const overrides = {};
        
        overrides.secondaryNamedInsured = {
            viewOnlyMode: true
        };

        overrides.primaryNamedInsured = {
            viewOnlyMode: true,
            isPNI: true
        };

        return overrides;
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        agencyName: {
            value: producerCodeDetails.agencyName
        },
        bookTransferIndicator: {
            value: producerCodeDetails.bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: producerCodeDetails.serviceCenterIndicatorValue
        },
        producerAddressComponent: {
            address: _get(producerCodeDetails, 'address'),
            phone: _get(producerCodeDetails, 'phone')
        },
        renewalTermEffectiveDate: {
            dateDTO: renewalVM.baseData.effectiveDate,
            updateDateDto: () => {
                setEffDateChanged(true);
                setBackdateInformation();
                updateWizardData(renewalVM);
            },
            showErrors: false,
            onValidate: () => {}
        },
        policyInformationInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr', '0.25fr']
        },
        insuredDetailsPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.loadingNextPageMessage)
        },
        insuredDetailsPageContainer: {
            visible: true
        },
        secNamedInsuredContainer: {
            visible: !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        mailingAndBillingAddressComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            onValidate: () => {},
            viewOnlyMode: true,
            lob: LOB
        },
        addNewPni: {
            visible: false
        },
        addSni: {
            visible: false
        },
        sniContainer: {
            visible: !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        changePniComponent: {
            visible: false
        },
        changeSniComponent: {
            visible: false
        },
        riskAddressComponent: {
            submissionVM: renewalVM,
            updateWizardData,
            onValidate: () => {},
            viewModelService,
            viewOnlyMode: true
        },
        ehPriorAddressComponent: {
            submissionVM: renewalVM,
            updateWizardData,
            onValidate: () => {},
            viewModelService,
            viewOnlyMode: true,
            visible: policyType === 'HO3' || policyType === 'HF9',
            LOB
        },
        insuranceHistoryMainDiv: {
            visible: policyType !== 'HO4'
        },
        coverageLapse: {
            value: lapseInCoverage
        },
        policyForm: {
            visible: usageType !== undefined || policyType === 'HO4',
            value: policyForm
        },
        usageType: {
            value: usageType,
            visible: policyType !== 'HO4'
        },
        lastPolicyExpirationDate: {
            // HO4 will not have prior policies, so implement check for undefined
            dateDTO: renewalVM.lobData.homeowners_EH
                .priorPolicies.children[0]?.policyExpirationDate
        },
        swapPniSni: {
            visible: false
        },
        insuredResidenceAddressWarningMessageDiv: {
            visible: false
        },
        hO4InsuredResidenceAddressWarningMessageDiv: {
            visible: false
        },
        lossQuestionEmptyContainer: {
            visible: !policyIsBackDated || !effDateChanged,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 4 : 3
        },
        lossQuestionContainer: {
            visible: policyIsBackDated && effDateChanged,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 4 : 3
        },
        lossOccurredBetweenEffectiveDateAndCreateDateInd: {
            defaultValue: renewalVM.value.hasLossOnOrAfterEffDate
        },
        primaryNamedInsured: {
            viewOnlyMode: true
        },
        removeSni: {
            visible: false
        },
        internalProducerDetailsComponent: {
            defaultValue: renewalVM.baseData.externalID_Ext?.value,
            visible: true,
            viewOnlyMode: true
        },
        locationCode: {
            visible: false
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: () => { },
            onValidate: () => {},
            onRemoveSni: () => { },
            onAddNewPni: () => { },
            onSwapPniSni: () => { }
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={() => {}}
            />
        </WizardPage>
    );
}

InsuredDetailsPage.propTypes = wizardProps;

export default withAuthenticationContext(InsuredDetailsPage);
