import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import metadata from './ConfirmationPage.metadata.json5';
import styles from './ConfirmationPage.module.scss';
import messages from './ConfirmationPage.messages';
import { Link } from '@jutro/router';

function ConfirmationPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const translator = useTranslator();

    const policyNumber = useMemo(() => _.get(submissionVM, 'policyNumber.value'), [submissionVM]);

    const jobID = useMemo(() => _.get(submissionVM, 'jobID.value'), [submissionVM]);

    const getPolicyChangeMessage = () => (
            <div>
                <span>{translator(messages.changeBoundSuccessfully)}</span>
                <span>: </span>
                <Link to={`/change/${jobID}/summary`}>{jobID}</Link>
            </div>
        );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        policyChangeConfirmationMessage: {
            className: styles.confirmationMessage,
            content: getPolicyChangeMessage()
        },
        backToPolicyButtonId: {
            to: `/policies/${policyNumber}/summary`
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVM]
    );


    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            resolveValue={readValue}
        />
    );
}

ConfirmationPage.propTypes = wizardProps;
export default ConfirmationPage;
