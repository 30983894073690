import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDocumentsHeadingID: {
        id: 'quoteandbind.eh.full.views.thankyou.policyDocuments.policyDocumentsHeadingID',
        defaultMessage: 'Policy Documents'
    },
    navigateToDocuments: {
        id: 'quoteandbind.eh.full.views.thankyou.policyDocuments.navigateToDocuments',
        defaultMessage: 'For a full list of documents, navigate to the Documents Tab'
    }
});
