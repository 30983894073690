import React, { useCallback, useEffect, useState } from 'react';
import {
    get as _get,
    set as _set,
    isUndefined as _isUndefined
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ServiceManager } from '@jutro/legacy/services';
import PropTypes from 'prop-types';
import './CommonPriorAddressComponent.messages';
import metadata from './CommonPriorAddressComponent.metadata.json5';


/**
 * @param {*} props wizard props
 * @returns {*} none
 */
function CommonPriorAddressComponent(props) {
    const {
        id,
        submissionVM,
        updateWizardData,
        onValidate,
        viewOnlyMode,
        viewModelService,
        createPrimaryNamedInsuredVM,
        disregardFieldValidation,
        LOB,
        showErrors
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const [isComponentInitialized, setIsComponentInitialized] = useState(false);
    const [livedAtAddressInd, setLivedAtAddressInd] = useState(false);
    const localeService = ServiceManager.getService('locale-service');
    /**
     * Helper callback for handling an address change on the submission.
     */
    const handleAddressChange = useCallback(
        (value, path) => {
            _set(submissionVM, path, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );


    /**
     * Helper effect for initializing the policy mailing/billing addresses and indicators.
     */
    useEffect(() => {
        if (_isUndefined(_get(submissionVM, `lobData.${LOB}.primaryNamedInsured.value`))) {
            createPrimaryNamedInsuredVM();
            updateWizardData(submissionVM);
        }

        const priorAddressComponent = _get(submissionVM, `lobData.${LOB}.primaryNamedInsured.priorAddresses.value`);

        setLivedAtAddressInd(priorAddressComponent === undefined || priorAddressComponent.length === 0);
        setIsComponentInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for handling changes to the mailing address toggle value.
     */
    const priorAddressToggleHandler = useCallback(
        (value) => {
            setLivedAtAddressInd(value);

            if (!value) {
                const {
                    _dtoName,
                    _xCenter
                } = _get(submissionVM, `lobData.${LOB}.primaryNamedInsured.priorAddresses`);

                // Create first VMNode and push to vmlistnode
                const priorAddressObj = {
                    priorAddress: {
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        postalCode: '',
                        country: localeService.getDefaultCountryCode()
                    },
                    mostRecentPriorAddress: true
                };
                const priorAddressVM = viewModelService.create(priorAddressObj, _xCenter, _dtoName);
                const allPriorAddresses = _get(
                    submissionVM,
                    `lobData.${LOB}.primaryNamedInsured.priorAddresses.value`,
                    []
                );

                allPriorAddresses.push(
                    priorAddressVM.value
                );
                _set(
                    submissionVM,
                    `lobData.${LOB}.primaryNamedInsured.priorAddresses.value`,
                    allPriorAddresses
                );
            } else {
                _set(
                    submissionVM,
                    `lobData.${LOB}.primaryNamedInsured.priorAddresses.value`,
                    []
                );
                disregardFieldValidation('commonPriorAddressComponent');
            }

            updateWizardData(submissionVM);
        },
        [updateWizardData, submissionVM, LOB, localeService, viewModelService, disregardFieldValidation]
    );

    /**
     * Helper effect for validating data fields on this component.
     */
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            priorAddressToggleHandler
        }
    };
    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            readOnly: viewOnlyMode,
            showRequired: true,
            showErrors,
            autoComplete: false
        },
        PriorAddressForEH: {
            value: livedAtAddressInd,
            visible: LOB === 'homeowners_EH',
            required: LOB === 'homeowners_EH'
        },
        commonPriorAddressComponentContainer: {
            visible: !livedAtAddressInd
        },
        commonPriorAddressComponent: {
            addressVM: _get(submissionVM, `lobData.${LOB}.primaryNamedInsured.priorAddresses.children[0].priorAddress`),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate,
            onAddressChange: (value, path) => handleAddressChange(value, `lobData.${LOB}.primaryNamedInsured.priorAddresses.children[0].priorAddress.${path}`),
            viewOnlyMode,
            showErrors
        },
    };

    /**
     * Helper callback for reading values from the view model.
     *
     * @param {*} _id
     * @param {*} path
     * @returns {*} The value as read from the view model.
     */
    const readValue = (_id, path) => readViewModelValue(metadata.pageContent, submissionVM, _id, path, overrideProps);

    /**
     * Define rendering behaviors for this Jutro component.
     */
    if (!isComponentInitialized) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

/**
 * Define expected property types to be passed into this Jutro component.
 */
CommonPriorAddressComponent.propTypes = {
    id: PropTypes.string.isRequired,
    submissionVM: PropTypes.shape({
        lobData: PropTypes.shape({
        })
    }).isRequired,
    updateWizardData: PropTypes.func,
    onValidate: PropTypes.func,
    viewOnlyMode: PropTypes.bool,
    viewModelService: PropTypes.shape({}).isRequired,
    createPrimaryNamedInsuredVM: PropTypes.func,
    disregardFieldValidation: PropTypes.func,
    LOB: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    showErrors: PropTypes.bool
};

/**
 * Define default property values to be passed into this Jutro component.
 */
CommonPriorAddressComponent.defaultProps = {
    updateWizardData: () => { },
    onValidate: () => { },
    createPrimaryNamedInsuredVM: () => { },
    disregardFieldValidation: () => { },
    viewOnlyMode: false,
    disabled: false,
    showErrors: false
};

export default CommonPriorAddressComponent;
