/* eslint-disable import/no-relative-packages */
import React, {
    useContext, useCallback, useEffect, useRef, useMemo, useState,
} from 'react';
import {
    get, set, every, isUndefined, some, find, isEmpty, pullAt
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps, WizardPageTemplateWithTitle } from 'e1p-portals-wizard-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { UmbrellaFlowUtil } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import messages from './UnderlyingPolicyPage.messages';
import metadata from './UnderlyingPolicyPage.metadata.json5';
import EUVehicleOperatorPage from '../VehicleOperator/EUVehicleOperatorPage';
import unverifiedWizardConfig from '../../../e1p-capability-gateway-quote-eu-react/config/unverified/eu-wizard-config.json5';
import verifiedWizardConfig from '../../../e1p-capability-gateway-quote-eu-react/config/verified/eu-wizard-config.json5';

const DIFFERENT_AUTO_EXP = ['personalauto', 'personalaononowner', 'otherspecialityvehicle', 'cycle'];

function UnderlyingPolicyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        currentStepIndex,
        changeNextSteps,
        isSkipping,
        steps,
        updateWizardSnapshot
    } = props;

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerComponentValidation,
        registerInitialComponentValidation
    } = useValidation('UnderlyingPolicyPage');

    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isSavingQuote, setIsSavingQuote] = useState(false);
    const [isLoadingULPolicies, setIsLoadingULPolicies] = useState(false);
    const [isPrivatePassengerQuestionVisible, setIsPrivatePassengerQuestionVisible] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const deletedItemsRef = useRef([]);
    const underlyingPoliciesVM = useMemo(() => get(submissionVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []), [submissionVM]);
    const jobType = get(submissionVM.value, 'baseData.jobType');

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        const vehicleUnderlyingPolicyExists = some(underlyingPoliciesVM.value,
            (policy) => DIFFERENT_AUTO_EXP.includes(policy.policyType));

        /**
         * stop on underlying policy page if no underlying policies
         * or status is in draft and there is no underlying auto policy
         * (if status is in draft with underlying auto policy we will stop user at vehicle operator page)
         */
        registerInitialComponentValidation(() => !isEmpty(get(submissionVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies.value', [])) 
            && (
                get(submissionVM, 'value.baseData.periodStatus') === 'Quoted'
                || vehicleUnderlyingPolicyExists
                ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // DO NOT change the ID of this page without changing step to field mappings config
    const vehicleOperatorPage = useRef({
        id: 'gwEUVehicleOperatorPage',
        path: '/vehicle-operator',
        component: EUVehicleOperatorPage,
        title: {
            id: 'quoteandbind.eu.wizard.step.Vehicle Operator',
            defaultMessage: 'Vehicle Operator',
        },
        stepProps: {
            template: WizardPageTemplateWithTitle
        },
    }).current;

    const applicableSteps = useMemo(
        () => {
            const isFullQuote = !!steps.find((step) => step.id === 'GWQuoteEUPaymentDetailsPage');

            if (isFullQuote) {
                return verifiedWizardConfig.steps;
            }

            return unverifiedWizardConfig.steps;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const handleVehicleOperatorPage = useCallback(() => {
        const remainingSteps = applicableSteps.slice(currentStepIndex + 1, applicableSteps.length);
        const vehicleUnderlyingPolicyExists = some(underlyingPoliciesVM.value,
            (policy) => DIFFERENT_AUTO_EXP.includes(policy.policyType));

        if (vehicleUnderlyingPolicyExists && !find(applicableSteps, ['id', vehicleOperatorPage.id])) {
            remainingSteps.unshift(vehicleOperatorPage);
        }

        if (!vehicleUnderlyingPolicyExists && find(applicableSteps, ['id', vehicleOperatorPage.id])) {
            remainingSteps.shift(vehicleOperatorPage);
        }

        changeNextSteps(remainingSteps);

        return vehicleUnderlyingPolicyExists;
    }, [
        changeNextSteps,
        currentStepIndex,
        applicableSteps,
        underlyingPoliciesVM.value,
        vehicleOperatorPage
    ]);

    useEffect(() => {
        if (isUndefined(underlyingPoliciesVM.value)) {
            set(underlyingPoliciesVM, 'value', []);
        }

        updateWizardData(submissionVM);
        handleVehicleOperatorPage();
        registerComponentValidation(() => every(underlyingPoliciesVM.children,
            (el, index) => (el.aspects.subtreeValid
                || some(deletedItemsRef.current, (i) => i === index)))
            && !!(underlyingPoliciesVM.value
                .filter((_, index) => !deletedItemsRef?.current?.includes(index))
                .find((policy) => policy.policyType === 'personalproperty' && policy.isPrimaryInd)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(
        async () => {
            const resRelPrivatePassengerAutoIndUndefined = UmbrellaFlowUtil
                .checkPrivatePassengerAutoQuestionVisibility(
                    submissionVM,
                    updateWizardData,
                    setIsPrivatePassengerQuestionVisible,
                    onValidate,
                    deletedItemsRef
                );

            if (!isComponentValid || resRelPrivatePassengerAutoIndUndefined) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingQuote(true);

            pullAt(underlyingPoliciesVM.value, deletedItemsRef.current);

            const propertyUnderlyingPolicyExists = some(underlyingPoliciesVM.value,
                (underlyingPolicy) => underlyingPolicy.policyType === 'personalproperty');

            if (propertyUnderlyingPolicyExists) {
                set(submissionVM, 'flowStepIDs_Ext.value', ['underlyingproperty']);
                set(submissionVM, 'entryCompletionStepIDs_Ext.value', ['underlyingproperty']);
                submissionVM.value = await LoadSaveService.updateDraftSubmission(
                    submissionVM.value,
                    authHeader
                );
            }

            const vehicleUnderlyingPolicyExists = await handleVehicleOperatorPage();

            if (!vehicleUnderlyingPolicyExists) {
                // Default autopay to true if null or undefined before rating otherwise retain value
                submissionVM.lobData.personalUmbrella_EU.autoPayDiscInd.value ??= true;
                submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
                    submissionVM.value,
                    authHeader
                );
            }

            // updating deletedItemsRef to empty, once we delete underlying policy.
            deletedItemsRef.current = [];
            setIsSavingQuote(false);
            // Added Two underlying policies and deleted auto , backend is sending validations for auto
            // so updated wizarddata to show them on UI.
            updateWizardData(submissionVM);
            updateWizardSnapshot(submissionVM);

            return submissionVM;
        },
        [
            authHeader, handleVehicleOperatorPage, isComponentValid,
            submissionVM, underlyingPoliciesVM,
            updateWizardData, onValidate, updateWizardSnapshot
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    // Updating noOperatorReasonOther value , which makes wizard and snapshot data equal.
                    // It will avoid unnecessary modal popup for saved changes.
                    if (get(submissionVM.lobData.personalUmbrella_EU, 'noOperatorReasonOther.value', undefined) === undefined) {
                        set(submissionVM.lobData.personalUmbrella_EU, 'noOperatorReasonOther.value', undefined);
                    }

                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, submissionVM, updateWizardSnapshot]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isSavingQuote && !isSkipping && !isSavingCurrentPageChanges && !isLoadingULPolicies, [isLoadingULPolicies, isSavingCurrentPageChanges, isSavingQuote, isSkipping]);

    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        underlyingPolicyPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: (() => {
                let message = translator(messages.loadingNextPageMessage);

                if (isSavingCurrentPageChanges) {
                    message = translator(e1pCommonMessages.savingCurrentPageChanges);
                } else if (isLoadingULPolicies) {
                    message = translator(messages.ulPoliciesRefreshMessage);
                }

                return message;
            })()
        },
        underlyingPolicyPageContainer: {
            visible: isPageLoaded
        },
        euUnderlyingPolicyComponent: {
            data: submissionVM,
            updateWizardData,
            isPageSubmitted,
            setIsLoadingULPolicies,
            isPrivatePassengerQuestionVisible,
            deletedItemsRef,
            jobType
        }
    };

    const writeValue = useCallback(
        (newVal, path) => {
            set(submissionVM, `${path}.value`, newVal);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        }
    };

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            onNext={onNext}
            nextLabel={translator(messages.allUnderlyingPolicisAdded)}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

UnderlyingPolicyPage.propTypes = wizardProps;
export default UnderlyingPolicyPage;
