import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';

import metadata from './E1PPageHeaderCompletionStatusComponent.metadata.json5';
import styles from './E1PPageHeaderCompletionStatusComponent.module.scss';

function E1PPageHeaderCompletionStatusComponent(props) {
    const {
        sectionHeader,
        isSectionValid
    } = props;
    const translator = useTranslator();
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        checkMark: {
            className: isSectionValid ? 'checkCircleIcon' : 'checkCircleIconGrey'
        },
        sectionHeader: {
            content: translator(sectionHeader)
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };
    return (
        <>
            <ViewModelForm
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </>
    );
}
E1PPageHeaderCompletionStatusComponent.propTypes = {
    sectionHeader: PropTypes.string.isRequired,
    isSectionValid: PropTypes.bool
};
E1PPageHeaderCompletionStatusComponent.defaultProps = {
    isSectionValid: false
};

export default E1PPageHeaderCompletionStatusComponent;
