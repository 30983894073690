import React from 'react';

import { ModalNext } from '@jutro/components';
import PropTypes from 'prop-types';

import {
    e1pDateUtil
} from 'e1p-capability-hooks';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import styles from './MonthlyPaymentScheduleModal.module.scss';
import metadata from './MonthlyPaymentScheduleModal.metadata.json5';
import SymbolCurrencyComponent from '../../SymbolCurrencyComponent/SymbolCurrencyComponent';

const columnToShowTotalString = 'invoiceDate';
const columnToShowTotalAmount = 'amount';

const getCurrency = (amount) => (
    {
        amount,
        currency: 'USD'
    }
);
const renderCurrencyField = (amount) => {
    if (amount !== undefined) {
        return <SymbolCurrencyComponent value={getCurrency(amount)} />;
    }

    return null;
};

function MonthlyPaymentSchedule(props) {
    const {
        isOpen,
        data,
        onResolve,
        changeInCost,
        transactionTotalAmount,
        startDate,
        endDate,
        jobTypeCode
    } = props;
    const invoicePreviews = [...data.invoicePreviews.sort(
        (a, b) => e1pDateUtil.stringToMoment(a.invoiceDate)
        - e1pDateUtil.stringToMoment(b.invoiceDate)
    )];
    const total = invoicePreviews.reduce((acc, item) => acc + item.amount, 0);

    const getCell = (item, index, property) => (item[property.id]);
    const getMoneyCell = (item, index, property) => item.total && property.id === columnToShowTotalAmount
            ? renderCurrencyField(total)
            : renderCurrencyField(item[property.id]);
    const getChangeInCost = (item) => item.total
            ? null
            : renderCurrencyField(item.changeAmount);
    const getFormatDate = (item, index, property) => {
        if (item.total) {
            if (property.id === columnToShowTotalString) {return 'Total';}

            return null;
        }

        return e1pDateUtil.utcToString(item[property.id]);
    };
    const onReturn = () => {
        onResolve(undefined);
    };
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            autoComplete: false
        },
        term: {
            value: `${startDate} - ${endDate}`
        },
        returnButton: {
            onClick: onReturn
        },
        changeInCost: {
            value: getCurrency(changeInCost.amount)
        },
        transactionPremium: {
            value: getCurrency(transactionTotalAmount.amount)
        },
        invoiceHasDownPaymentInfoMsgDiv: {
            visible: jobTypeCode === 'Submission'
        },
        tablePaymentScheduleDetail: {
            data: invoicePreviews
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell,
            getMoneyCell,
            getFormatDate,
            getChangeInCost
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={styles.fullScreen}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </ModalNext>
    );
}

MonthlyPaymentSchedule.propTypes = {
    isOpen: PropTypes.bool,
    data: PropTypes.shape({
        invoicePreviews: PropTypes.array.isRequired
    }).isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    onResolve: PropTypes.func,
    transactionTotalAmount: PropTypes.shape({
        amount: PropTypes.number.isRequired
    }).isRequired,
    changeInCost: PropTypes.shape({
        amount: PropTypes.number.isRequired
    }).isRequired,
    jobTypeCode: PropTypes.string
};
export default MonthlyPaymentSchedule;
