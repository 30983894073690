import React, {
    useCallback,
    useState,
    useRef,
    useMemo
} from 'react';
import {
    get, set, findIndex
} from 'lodash';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { EHHO4PropertyDetailsComponent } from 'e1p-capability-policyjob-react';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

function PropertyPage(props) {
    const { authHeader } = useAuthentication();
    const {
        wizardData: submissionVM,
        updateWizardData,
        isSkipping,
        jumpTo,
        steps,
        updateWizardSnapshot,
        setShouldSkipAdditionalInfo
    } = props;
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const calledFromOnSave = useRef(false);
    const {
        isComponentValid,
        initialValidation,
        onValidate
    } = useValidation('PropertyPage');

    // if validation errors are thrown we cant jump to cov page
    const shouldNotGoToCoverages = (apiResponse) => {
        const validationErrors = get(apiResponse, 'errorsAndWarnings.validationIssues.fieldIssues', []);
        const exceptions = get(apiResponse, 'baseData.exceptions_Ext', []);

        return validationErrors.length > 0 || exceptions.length > 0;
    };

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            const quoteData = get(submissionVM, 'quoteData.value');
            const response = await LoadSaveService
                .saveAndQuoteSubmission(submissionVM.value, authHeader);
            const respQuoteData = get(response, 'quoteData');

            if (respQuoteData === undefined) {
                set(response, 'quoteData', quoteData);
            }

            set(submissionVM, 'value', response);
            updateWizardData(submissionVM);
            setIsSavingEndorsement(false);

            // Check for validation errors and stop page before jumping
            if (shouldNotGoToCoverages(submissionVM.value)) {
                // stay on page
                updateWizardData(submissionVM);
                setIsSavingEndorsement(false);

                return false;
            }

            const coveragesIndex = findIndex(steps, ({ path }) => path === '/coverages');

            updateWizardSnapshot(submissionVM);

            // Don't want to jump in case of save call
            if (!calledFromOnSave.current) {
                jumpTo(coveragesIndex, true, submissionVM.value);
            }

            // Must return false to deny wizardpageonnext promise
            return false;
        },
        [isComponentValid, submissionVM, authHeader, updateWizardData, steps, updateWizardSnapshot, jumpTo]
    );

    const onSave = useCallback(
        async () => {
            calledFromOnSave.current = true;
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();
                calledFromOnSave.current = false;
                setIsSavingCurrentPageChanges(false);
            } catch {
                calledFromOnSave.current = false;
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    // eslint-disable-next-line arrow-body-style
    const isPageLoaded = useMemo(() => {
        return !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges;
    },[isSavingCurrentPageChanges, isSavingEndorsement, isSkipping]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <EHHO4PropertyDetailsComponent
                onValidate={onValidate}
                transactionVM={submissionVM}
                updateWizardData={updateWizardData}
                isPageSubmitted={isPageSubmitted}
                setShouldSkipAdditionalInfo={setShouldSkipAdditionalInfo}
                isSavingCurrentPageChanges={isSavingCurrentPageChanges}
                isSkipping={isSkipping}
                isSavingEndorsement={isSavingEndorsement}
                viewOnly={false}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
