import { defineMessages } from 'react-intl';

export default defineMessages({
    privatePassengerExposure: {
        id: 'common.eu.Underlying Policy.Private Passenger Exposure',
        defaultMessage: 'Private Passenger Exposure'
    },
    state: {
        id: 'common.eu.Underlying Policy.State',
        defaultMessage: 'State'
    },
    namedInsuredRelResOfYourHouseholdInd: {
        id: 'common.eu.Underlying Policy.NamedInsuredRelResOfYourHouseholdInd',
        defaultMessage: 'Is the primary or secondary named insured on this underlying policy a relative who is a resident of your household or an unmarried dependent child, ward, or foster child who is under the age of 24 and attending college full time?'
    },
    watercraftLiability: {
        id: 'common.eu.Underlying Policy.Is Liability coverage provided by Homeowner or Watercraft policy?',
        defaultMessage: 'Is Liability coverage provided by Homeowner or Watercraft policy?'
    },
    propertyCoverageELabel: {
        id: 'common.eu.Underlying Policy.PropertyELabel',
        defaultMessage: 'Liability Limit'
    },
    otherCoverageELabel: {
        id: 'common.eu.Underlying Policy.OtherELabel',
        defaultMessage: 'Coverage E Liability Limit'
    },
    ulPolicyDetails: {
        id: 'common.eu.components.Underlying Policy.UL Policy Details',
        defaultMessage: 'UL Policy Details'
    },
    personalPropertyExp: {
        id: 'common.eu.components.Underlying Policy.Personal Property Exposure',
        defaultMessage: 'Personal Property Exposure'
    },
    vehicleExp: {
        id: 'common.eu.components.Underlying Policy.Vehicle',
        defaultMessage: 'Vehicle'
    },
    cycleExp: {
        id: 'common.eu.components.Underlying Policy.Motorcycle Exposures',
        defaultMessage: 'Motorcycle Exposures'
    },
    recreationVehicleExp: {
        id: 'common.eu.components.Underlying Policy.Recreational Vehicle Exposures',
        defaultMessage: 'Recreational Vehicle Exposures'
    },
    watercraftExp: {
        id: 'common.eu.components.Underlying Policy.Watercraft Exposures',
        defaultMessage: 'Watercraft Exposures'
    },
    renterDwellingExp: {
        id: 'common.eu.components.Underlying Policy.Rental Dwelling Exposures',
        defaultMessage: 'Rental Dwelling Exposures'
    },
    effectiveDate: {
        id: 'common.eu.components.Underlying Policy.Effective Date',
        defaultMessage: 'Effective Date'
    },
    expirationDate: {
        id: 'common.eu.components.Underlying Policy.Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    exposure: {
        id: 'common.eu.Underlying Policy.Exposure',
        defaultMessage: 'Exposure'
    },
    quoteNumber: {
        id: 'common.eu.Underlying Policy.PolicyQuoteNumber',
        defaultMessage: 'Quote/Policy Number'
    },
    UMUIMHeadingLabel: {
        id: 'common.eu.components.Underlying Policy.If your underlying policy has combined Uninsured/Underinsured coverage, please provide those limits here.',
        defaultMessage: 'If your underlying policy has combined Uninsured/Underinsured coverage, please provide those limits here.'
    }
});
