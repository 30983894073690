import React, {
    useCallback,
    useEffect,
    useState,
    useRef,
    useMemo
} from 'react';
import {
    get, set, findIndex
} from 'lodash';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { E1PEHHO6PropertyDetailsComponent } from 'e1p-capability-policyjob-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { LoadSaveService } from 'e1p-capability-quoteandbind';


function PropertyPage(props) {
    const { authHeader } = useAuthentication();
    const {
        wizardData: submissionVM,
        updateWizardData,
        steps,
        jumpTo,
        updateWizardSnapshot,
        setShouldSkipAdditionalInfo
    } = props;
  
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingQuote, setIsSavingQuote] = useState(false);
    const [heatingSystemType, updateHeatingSystemType] = useState(undefined);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const calledFromOnSave = useRef(false);

    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerInitialComponentValidation
    } = useValidation('PropertyPage');
 

    const isPageValid = useCallback(() => {
        const heatingSystems = get(submissionVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value', []);

        return heatingSystems.length > 0;
    }, [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(isPageValid);
    }, [registerInitialComponentValidation, isPageValid]);

    // if validation errors are thrown we cant jump to cov page
    const shouldNotGoToCoverages = (apiResponse) => {
        const validationErrors = get(apiResponse, 'errorsAndWarnings.validationIssues.fieldIssues', []);
        const exceptions = get(apiResponse, 'baseData.exceptions_Ext', []);

        return validationErrors.length > 0 || exceptions.length > 0;
    };

    const onNext = useCallback(
        async () => {
            if (!isComponentValid || heatingSystemType === undefined) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingQuote(true);

            const quoteData = get(submissionVM, 'quoteData.value');
            const response = await LoadSaveService
                .saveAndQuoteSubmission(submissionVM.value, authHeader);
            const respQuoteData = get(response, 'quoteData');

            if (respQuoteData === undefined) {
                set(response, 'quoteData', quoteData);
            }

            set(submissionVM, 'value', response);
            updateWizardData(submissionVM);
            setIsSavingQuote(false);

            // Check for validation errors and stop page before jumping
            if (shouldNotGoToCoverages(submissionVM.value)) {
                // stay on page
                updateWizardData(submissionVM);
                setIsSavingQuote(false);

                return false;
            }

            // updateSnapshot since we are jumping the page,
            // wizardPage onNext will update snapshot automatically
            updateWizardSnapshot(submissionVM);

            // Don't want to jump in case of save call
            if (!calledFromOnSave.current) {
                const coveragesIndex = findIndex(steps, ({ path }) => path === '/coverages');

                jumpTo(coveragesIndex, true, submissionVM.value);
            }

            // Must return false to deny wizardpageonnext promise
            return false;
        },
        [isComponentValid, heatingSystemType, submissionVM, authHeader, updateWizardData, steps, updateWizardSnapshot, jumpTo]
    );

    const onSave = useCallback(
        async () => {
            calledFromOnSave.current = true;
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();
                calledFromOnSave.current = false;
                setIsSavingCurrentPageChanges(false);
            } catch {
                calledFromOnSave.current = false;
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isSavingQuote && !isSavingCurrentPageChanges,[isSavingCurrentPageChanges, isSavingQuote]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <E1PEHHO6PropertyDetailsComponent
                onValidate={onValidate}
                transactionVM={submissionVM}
                updateWizardData={updateWizardData}
                isPageSubmitted={isPageSubmitted}
                setShouldSkipAdditionalInfo={setShouldSkipAdditionalInfo}
                isSavingCurrentPageChanges={isSavingCurrentPageChanges}
                isSavingQuote={isSavingQuote}
                heatingSystemType={heatingSystemType}
                updateHeatingSystemType={updateHeatingSystemType}
                viewOnlyMode={false}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
