import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import {
    get, set, filter, map, sortBy, uniqBy, isUndefined
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useUWIssueUtil } from 'e1p-capability-hooks';
import { UmbrellaFlowUtil, ClausesUtil as e1pClausesUtil, QuoteProposalUtil, CoverageUtil } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import messages from './QuotePage.messages';
import metadata from './QuotePage.metadata.json5';

function structureClauseTableData(coverage) {
    // putting ID into an object as the Jutro table component expects an object
    return {
        publicID: coverage.publicID
    };
}

function generateClauseData(columnData, coveragePath) {
    return columnData.map(({ lob, code }) => {
        const completeCoveragePath = `coverages.${coveragePath}`;

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

// eslint-disable-next-line arrow-body-style
const structureCustomQuote = (submissionVM, affectedQuote, clauses) => {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    };
};

const getCoveragesUniqueID = (submissionVM) => {
    const offerings = get(submissionVM, 'lobData.personalUmbrella_EU.offerings.value');
    const coverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.coverages.map(structureClauseTableData)
    )), 'publicID');

    return {
        coverages
    };
};

const generateColumnData = (submissionVM) => {
    const lobOfferingPath = 'lobData.personalUmbrella_EU.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = get(submissionVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings.map((lobOffering, lobIndex) => {
        const quoteDataIndex = quoteOfferings.findIndex(
            (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
        );
        const quoteData = quoteOfferings[quoteDataIndex];

        return {
            name: lobOffering.branchName,
            code: lobOffering.branchCode,
            quote: {
                path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                data: quoteData
            },
            lob: {
                path: `${lobOfferingPath}.children[${lobIndex}]`,
                data: lobOffering
            }
        };
    });

    return sortBy(columnData, ['code']);
};

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    const quoteOffering = get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(lobOffering.coverages, lobName, null);
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

const generateTableData = (submissionVM, columnData, translator) => {
    const uniqueID = getCoveragesUniqueID(submissionVM);

    const coverages = {
        header: translator(messages.policyLevelCoverages),
        data: uniqueID.coverages,
        tableContent: generateClauseData(columnData, 'coverages')
    };

    return [coverages];
};

function QuotePage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        stopSkipping,
        jumpTo,
        steps,
        cancel: onCancel,
        isComponentValid,
        isSkipping
    } = props;
    const {
        onValidate,
        disregardFieldValidation
    } = useValidation('QuotePage');
    const translator = useTranslator();
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [fieldsChangedOnCoveragePage, setFieldsChangedOnCoveragePage] = useState(false);
    const [quoteIsStale, setQuoteIsStale] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isQuoting, setIsQuoting] = useState(false);
    // const [initialSubmissionVM, setInitialSubmissionVM] = useState(undefined);
    const [isBuying, setIsBuying] = useState(false);
    const [
        hasAlreadyApprovedLiabilityLimitUWIssue,
        setHasAlreadyApprovedLiabilityLimitUWIssue
    ] = useState(false);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);

    // Quote type is no longer set on leaving this page
    //   so will check to see if a verified step exists
    const isFullQuote = steps.find((step) => step.id === 'GWQuoteEUPaymentDetailsPage');
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);

    const {
        hasUWIssuesOfType,
        showUnderwritingIssuesPopup,
        hasUWIssues
    } = useUWIssueUtil(
        submissionVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName, true);

            return CustomQuoteService.updateCustomQuoteCoverages(
                customQuote,
                authHeader
            ).then((response) => {
                const updatedClauses = get(response, `coverages.${lobName}`);

                if (updatedClauses) {
                    const newSubmissionVM = viewModelService.clone(submissionVM);

                    // Update the offering status to stale
                    setStaleQuoteBranchCode(response.quote.branchCode);
                    // Update local offering with new one from xcenter
                    set(submissionVM, `${lobPath}.coverages`, updatedClauses);

                    // Update local quote status with new one from xcenter
                    const status = get(response, 'quote.status', 'Draft');

                    set(submissionVM, `${quotePath}.status`, status);
                    // Update local errorsAndWarnings with new one from xcenter
                    set(submissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
                    // Update premium with new one from xcenter
                    set(submissionVM, `${quotePath}.premium`, response.quote.premium);

                    const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                        submissionVM, newSubmissionVM, `${lobPath}.coverages.coverages`
                    );
                    const allRemovedFields = [...removedFieldsFromBaseCoverages];

                    setQuoteIsStale(true);
                    disregardFieldValidation(allRemovedFields);
                    set(submissionVM, 'baseData.exceptions_Ext', response.exceptions_Ext);
                    updateWizardData(submissionVM);
                }
            });
        },
        [
            submissionVM, CustomQuoteService, authHeader,
            viewModelService, disregardFieldValidation, updateWizardData
        ]
    );

    const checkuwIssues = useCallback((newestSubmission) => {
        if (hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease', 'Rejected'], newestSubmission)) {
            setQuoteIsStale(true);
            showUnderwritingIssuesPopup(newestSubmission);
        }
    }, [hasUWIssuesOfType, showUnderwritingIssuesPopup]);

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = get(submissionVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(submissionVM, 'lobData.personalUmbrella_EU.paperlessInd.value'); 
        const paperlessEmail = get(submissionVM, 'lobData.personalUmbrella_EU.paperlessEmail.value');
 
        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [submissionVM, isPaperlessEmailUpdated]);

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);

            return onClauseChange(basePath, lobPath, quotePath);
        },
        [onClauseChange]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(e1pClausesUtil.setClauseValue(submissionVM, value, changedPath));
        },
        [submissionVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            changeSubmission(value, changedPath);

            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeSubmission, syncCoverages]
    );

    const recalculate = useCallback(
        (lobPath, quotePath) => {
            // turn loader on
            setIsQuoting(true);

            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName);

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (isUndefined(get(submissionVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value'))
                && !!get(submissionVM, 'lobData.personalUmbrella_EU.paperlessEmail.value')) {
                set(submissionVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value',
                    get(submissionVM, 'lobData.personalUmbrella_EU.paperlessEmail.value'));
            }

            // calling save and quote as we fields other than coverages are modified
            return LoadSaveService
                .saveAndQuoteSubmission(submissionVM.value, authHeader)
                .then((response) => {
                    setStaleQuoteBranchCode(undefined);
                    setFieldsChangedOnCoveragePage(false);

                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    const quoteData = get(newSubmissionVM, 'quoteData.value');
                    const respQuoteData = get(response, 'quoteData');

                    if (respQuoteData === undefined) {
                        set(response, 'quoteData', quoteData);
                    }

                    set(newSubmissionVM, 'value', response);
                    setQuoteIsStale(false);
                    QuoteProposalUtil.fetchQuoteProposal(
                        submissionVM,
                        setQuoteProposalCompleted,
                        setQuoteProposalLink,
                        authHeader,
                        setIsQuoteProposalFailed
                    );
                    updateWizardData(newSubmissionVM);
                    checkuwIssues(newSubmissionVM);
                    /**
                     * E1PAP1PC-15339 :
                     * Need to set this variable as
                     * we don't want to show info message if UW issue is approved
                     * If uw issue is approved and user comes back on coverage page and changes
                     * LiabilityLimit cov limit >=6000000;
                     * update coverage response does not send uw issues back so we need this
                     */
                    setHasAlreadyApprovedLiabilityLimitUWIssue(
                        UmbrellaFlowUtil.hasLiabilityLimitChangeBlockQuoteUwIssueApproved(
                            get(newSubmissionVM, 'value.errorsAndWarnings.underwritingIssues',
                                get(newSubmissionVM, 'value.errorsAndWarnings_Ext.underwritingIssues', []))
                        )
                    );

                    return response;
                })
                .catch(() => {
                    // Something went wrong
                    const lobOfferingPath = 'lobData.personalUmbrella_EU.offerings.children[0]';
                    const offering = get(submissionVM, `${lobOfferingPath}.value`);

                    setStaleQuoteBranchCode(offering.branchCode);
                    setFieldsChangedOnCoveragePage(true);

                    return customQuote;
                })
                .finally(() => {
                    // turn loader off
                    setIsQuoting(false);
                });
        },
        [
            submissionVM, authHeader, LoadSaveService, 
            viewModelService, updateWizardData, checkuwIssues
        ]
    );

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const buyNow = useCallback(
        async () => {
            let paperLessIndDetailsValid = submissionVM.lobData
                .personalUmbrella_EU.paperlessInd.value !== undefined;

            if (submissionVM.lobData.personalUmbrella_EU.paperlessInd.value) {
                const paperEmail = get(submissionVM, 'lobData.personalUmbrella_EU.paperlessEmail');

                paperLessIndDetailsValid = paperEmail.value !== undefined
                    && paperEmail.value !== '' && paperEmail.aspects.valid;
            }

            if (!paperLessIndDetailsValid || !isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            updateIsPageSubmitted(false);
            setIsBuying(true);
            set(submissionVM, 'quoteType_Ext.value', get(submissionVM.value, 'quoteType'));

            let convertedFromQuickToFull = get(submissionVM, 'convertedFromQuickToFull');

            if (convertedFromQuickToFull === undefined && submissionVM.quoteType_Ext.value.code === 'Quick') {
                convertedFromQuickToFull = true;
            }

            // Similar to pattern used in homeowners'.Purpose is to show report page popup only once
            set(submissionVM, 'convertedFromQuickToFull', convertedFromQuickToFull);
            updateWizardData(submissionVM);

            return submissionVM;
        },
        [submissionVM, updateWizardData, isComponentValid]
    );

    useEffect(() => {
        if(!isSkipping){
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (submissionVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            submissionVM.paymentOptionChanged = false;
        }
    }, [authHeader, submissionVM, submissionVM.paymentOptionChanged]);

    // overriding coverage help text for given clause.
    useEffect(() => {
        get(submissionVM, 'value.lobData.personalUmbrella_EU.offerings[0].coverages.coverages').forEach((clause) => {
            const coveragesHelpText = CoverageUtil.getCoveragesHelpTextForEU(clause);

            if (coveragesHelpText) {
                clause.description = coveragesHelpText
            }
        })
    }, [submissionVM]);

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();
            window.open(quoteProposalLink, '_blank');
        }, [quoteProposalLink]
    );

    const generateOverrides = useCallback(() => {
        const columnData = generateColumnData(submissionVM);
        const { quoteID } = submissionVM;

        return {
            '@field': {
                showRequired: true,
                showErrors: isPageSubmitted,
                autoComplete: false
            },
            quotePageLoadingIndicator: {
                loaded: !isBuying && !isQuoting,
                text: isQuoting
                    ? translator(e1pCommonMessages.ratingYourPolicyMessage)
                    : translator(messages.creatingYourOfferingMessage)
            },
            quotePageContainer: {
                visible: !isBuying && !isQuoting,
            },
            quoteTable: {
                columnData,
                tableData: generateTableData(submissionVM, columnData, translator),
                underwritingIssues: get(submissionVM, 'errorsAndWarnings.underwritingIssues.value') === undefined
                    ? [] : get(submissionVM, 'errorsAndWarnings.underwritingIssues.value'),
                quoteID: quoteID.value,
                submissionVM,
                updateWizardData,
                authHeader,
                quoteIsStale,
                setFieldsChangedOnCoveragePage,
                onViewQuoteProposal: openDocument,
                isQuoteProposalReady: quoteProposalCompleted && !fieldsChangedOnCoveragePage && !quoteIsStale,
                fieldsChangedOnCoveragePage,
                onCancel,
                isPageSubmitted,
                updateIsPageSubmitted,
                onValidate,
                modifiers: get(submissionVM, 'lobData.personalUmbrella_EU.modifiers.value', []),
                authUserData,
                setIsPaperlessEmailUpdated,
                opCo
            },
            personalLiabilityLimitUwIssueInfoMessageDiv: {
                visible: UmbrellaFlowUtil
                    .isPersonalLiabilityLimitUwIssueInfoMessage(
                        submissionVM,
                        hasAlreadyApprovedLiabilityLimitUWIssue
                    )
            },
            paperlessEmailChangedMessageDiv: {
                visible: showPaperlessEmailMessage()
            },
            quoteProposalFailureErrorDiv: {
                visible: isQuoteProposalFailed
            },
        };
    }, [
        submissionVM,
        isBuying,
        translator,
        updateWizardData,
        authHeader,
        quoteIsStale,
        fieldsChangedOnCoveragePage,
        quoteProposalCompleted,
        openDocument,
        onCancel,
        onValidate,
        isPageSubmitted,
        authUserData,
        isQuoting,
        hasAlreadyApprovedLiabilityLimitUWIssue,
        isQuoteProposalFailed,
        showPaperlessEmailMessage,
        opCo
    ]);

    useEffect(() => {
        const offerings = get(submissionVM, 'quoteData.offeredQuotes.value');
        const draftOffers = filter(offerings, ['status', 'Draft']);
        const draftBranchCodes = map(draftOffers, 'branchCode');

        setStaleQuoteBranchCode(draftBranchCodes);

        // Above logic only needs to run once when component is mounted
        if (submissionVM.lobData.personalUmbrella_EU.paperlessInd.value === undefined
            && get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'directentry') {
            set(submissionVM, 'lobData.personalUmbrella_EU.paperlessInd.value', true);
        }

        setQuoteIsStale(hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease']));
        // Only run on page mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // eslint-disable-next-line no-warning-comments
        // FIXME: Weird skipping issue from GW wizard platform code
        stopSkipping();
        /**
         * E1PAP1PC-15339 :
         * Need to set this variable as we don't want to show info message if UW issue is approved
         * If uw issue is approved and user comes back on coverage page and changes
         * LiabilityLimit cov limit >=6000000;
         * update coverage response does not send uw issues back so we need this
         */
        setHasAlreadyApprovedLiabilityLimitUWIssue(
            UmbrellaFlowUtil.hasLiabilityLimitChangeBlockQuoteUwIssueApproved(
                get(submissionVM, 'value.errorsAndWarnings.underwritingIssues',
                    get(submissionVM, 'value.errorsAndWarnings_Ext.underwritingIssues', []))
            )
        );

        if (submissionVM.value.quoteType === 'Full' && hasUWIssues()) {
            // this will show UW issues popup with all the UW Issue types
            showUnderwritingIssuesPopup(submissionVM);
        } else if (submissionVM.value.quoteType === 'Quick') {
            // in quick quote flow we need to show uwIssues popup if the type is
            // 'BlocksQuote', 'BlocksQuoteRelease', 'Rejected'
            // internally checkuwIssues method will check for UWIssues,
            // so no need to add condition hasUWIssues in else if
            checkuwIssues(submissionVM);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);

    return (
        <WizardPage
            showNext={false}
            showPrevious={false}
            showCancel={false}
            skipWhen={() => isFullQuote && get(submissionVM, 'value.baseData.periodStatus') === 'Quoted'}
            finish={!isFullQuote}
            isPageSubmittedWithErrors={isPageSubmitted}
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveCallbackMap: {
                        onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
                        onStaleQuoteBranchCode,
                        onRecalculate: recalculate,
                        onChangeSubmissionAndSync: changeSubmissionAndSync,
                        onChangeSubmission: changeSubmission,
                        onSyncCoverages: syncCoverages,
                        onPrint: handlePrint,
                        onValidate
                    },
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={generateOverrides()}
                        onModelChange={updateWizardData}
                        callbackMap={resolvers.resolveCallbackMap}
                        onValidationChange={onValidate}
                    />
                );
            }}
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
