import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from "classnames";
import _ from 'lodash';
import E1PLoader from '../E1PLoader/E1PLoader';
import styles from './E1pIframeComponent.module.scss';

// Supported aspect ratios for iFrame component
const aspectRatios = ["4/3", "16/9", "17/9", "2/1"];

// Assign class based on provided aspect ratio
const aspectRatioStyle = aspectRatios.reduce(((reduced, ratio) => {
    const suffix = _.replace(ratio, "/", "x");

    reduced[ratio] = `aspect-ratio-${suffix}`;

    return  reduced;
}), {});

/**
 * A component for rendering iframe
 * 
 * @param {object} props - the props for rendering the component
 * @param {string} props.id - attribute id for iframe
 * @param {string} props.src - src attribute value to load inside the iframe
 * @param {string} props.title - attribute title for iframe
 * @param {string} props.aspectRatio - aspect ratio of iframe (supported values: 4/3, 16/9, 17/0, 2/1)
 * @param {string} props.height - height of iframe either in % or px values
 * @param {string} props.className - additional classes applied to component
 * @param {function} props.onLoad - function to trigger on load of specified src in iframe
 * @param {boolean} props.showLoader - a flag to indicate if the loader should be shown
 * @param {boolean} props.noBorder - a flag to indicate if the border should be not shown
 * @returns {object} an iframe element
 */
function E1PIframeComponent(props) {
    const { aspectRatio, showLoader, className, noBorder, src, title, id, loaderClassName, onLoad, height } = props;
    
    const aspectRatioClass = styles[aspectRatioStyle[aspectRatio]];
    const containerClasses = aspectRatioClass ? cx(styles.iframeContainer, aspectRatioClass) : styles.iframeContainer;
    const iframeClasses = cx(styles.iframe, {
        [styles.noBorder]: noBorder
    }, className);
    const [showIframeLoader, setShowIframeLoader] = useState(showLoader);
    const frameContainerStyle = { paddingBottom: height };
    const onIframeLoad = useCallback(() => {
        setShowIframeLoader(false);

        if (onLoad) {
            onLoad();
        }
    }, [onLoad]);
    
    return <div className={containerClasses} style={frameContainerStyle}>
        <E1PLoader loaded={!showIframeLoader} className={cx(styles.loaderIframe, loaderClassName)} />
        <iframe src={src} className={iframeClasses} title={title} id={id} onLoad={onIframeLoad} />
    </div>;
}

E1PIframeComponent.propTypes = {
    id: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    title: PropTypes.string,
    aspectRatio: PropTypes.oneOf(aspectRatios),
    height: PropTypes.string,
    className: PropTypes.string,
    onLoad: PropTypes.func,
    showLoader: PropTypes.bool,
    noBorder: PropTypes.bool
};

E1PIframeComponent.defaultProps = {
    height: '50%',
    showLoader: true,
    title: undefined
};
export default E1PIframeComponent;
