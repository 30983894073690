import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { set as _set } from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ReinstatementService } from 'e1p-capability-reinstatement';
import { PageHeaderComponent } from '../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import CommonRoutes from '../Common/CommonRouting';
import metadata from './Reinstatement.metadata.json5';
import styles from './Reinstatement.module.scss';
import pageRouting from '../Common/Reinstatement-config.json5';

function Reinstatement(props) {
    const [currentView, setCurrentView] = useState('summary');
    const [reinstatement, setReinstatement] = useState({});
    const viewModelService = useContext(ViewModelServiceContext);

    const handleTilesOnClick = (id) => {
        setCurrentView(id);
    };

    const createDataVM = (model) => viewModelService.create(model,
            'pc', 'edge.capabilities.reinstatement.dto.ReinstatementDataDTO');

    const getReinstatementSummaryCount = () => {
        const {
            location: { pathname }
        } = props;

        const routePath = pathname && (pathname.trim().split('/') || []);
        const selectedPageFromPath = routePath[routePath.length - 1];
        const activeTile = selectedPageFromPath || 'summary';

        setCurrentView(activeTile);
    };

    const updateReinstatementSummary = (reinstatementData) => {
        // settig below values for header component as for reinstatement we are getting data in different object structure
        _set(reinstatementData, 'value.status', reinstatementData.value.baseData.periodStatus);
        _set(reinstatementData, 'value.type', reinstatementData.value.baseData.jobType);
        _set(reinstatementData, 'value.displayType', reinstatementData.value.baseData.jobType);
        _set(reinstatementData, 'value.statusCode', reinstatementData.value.baseData.periodStatus);
        _set(reinstatementData, 'value.policy.policyNumber', reinstatementData.value.policyNumber);
        _set(reinstatementData, 'value.policy.account.accountHolder.displayName', reinstatementData.value.baseData.accountHolder_Ext.displayName);
        _set(reinstatementData, 'value.policy.account.accountNumber', reinstatementData.value.baseData.accountNumber);
        _set(reinstatementData, 'value.policy.product.productName', reinstatementData.value.baseData.productName);
        _set(reinstatementData, 'value.policy.policyNumber', reinstatementData.value.policyNumber);
        _set(reinstatementData, 'value.jobNumber', reinstatementData.value.jobID);
        setReinstatement(reinstatementData);
    };

    const getJobByJobNumber = async () => {
        const {
            authHeader, match: { params: { jobNumber } }
        } = props;

        const reinstatementDataDTO = await ReinstatementService.retrieve(jobNumber, authHeader);
        const reinstatementVM = createDataVM(reinstatementDataDTO);

        updateReinstatementSummary(reinstatementVM);
        setReinstatement(reinstatementVM);
    };

    useEffect(() => {
        getReinstatementSummaryCount();
        getJobByJobNumber();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const overrides = {
        [currentView]: {
            active: true
        },
        pageTitle: {
            value: reinstatement.value
        }
    };
    const reinstatementVM = { reinstatement };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleTilesOnClick
        },
        resolveComponentMap: {
            pageheadercomponent: PageHeaderComponent
        }
    };
    const { match: { url, params: { jobNumber } } } = props;
    const quoteData = {
        jobNumber,
        loadReinstatementSummary: reinstatement,
        updateJobSummary: updateReinstatementSummary,
        viewModelService
    };

    return (
        <React.Fragment>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={reinstatementVM}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
            <CommonRoutes
                steps={pageRouting}
                basePath={url}
                quoteDetailsData={quoteData}
            />
        </React.Fragment>
    );
}


Reinstatement.propTypes = {
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            jobNumber: PropTypes.string
        }),
        url: PropTypes.string
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string
    }).isRequired
};

export default withAuthenticationContext(Reinstatement);
