import { defineMessages } from 'react-intl';

export default defineMessages({
    save: {
        id: 'quoteandbind.common.ProtectiveDevice.Save',
        defaultMessage: 'Save'
    },
    cancel: {
        id: 'quoteandbind.common.ProtectiveDevice.Cancel',
        defaultMessage: 'Cancel'
    },
    fireProtective: {
        id: 'quoteandbind.common.ProtectiveDevice.Fire Protective Devices',
        defaultMessage: 'Fire Protective Devices'
    },
    theftProtective: {
        id: 'quoteandbind.common.ProtectiveDevice.Theft Protective Devices',
        defaultMessage: 'Theft Protective Devices'
    },
    waterProtective: {
        id: 'quoteandbind.common.ProtectiveDevice.Water Protective Devices',
        defaultMessage: 'Water Protective Devices'
    },
    shareData: {
        id: 'quoteandbind.common.ProtectiveDevice.Opt-in to share data from connected devices?',
        defaultMessage: 'Opt-in to share data from connected devices?'
    },
    fireAlarmLabel: {
        id: 'quoteandbind.common.ProtectiveDevice.Fire Alarm Type',
        defaultMessage: 'Fire Alarm Type'
    },
    burglarAlarmLabel: {
        id: 'quoteandbind.common.ProtectiveDevice.Burglar Alarm Type',
        defaultMessage: 'Burglar Alarm Type'
    },
    protectiveSubHeader: {
        id: 'quoteandbind.common.ProtectiveDevice.For any selected devices, proof needs to be submitted to receive the Secure Home discount.',
        defaultMessage: 'For any selected devices, proof needs to be submitted to receive the Secure Home discount.'
    },
    selectAProtectiveDevice: {
        id: 'quoteandbind.common.ProtectiveDevice.Select a protective device type.',
        defaultMessage: 'Select a protective device type.'
    },
    dataShareHelpText: {
        id: 'quoteandbind.common.ProtectiveDevice.Opting in for data share will allow Main Street America Insurance to retrieve information from the device. By choosing to share data, this policy will qualify for an additional discount.',
        defaultMessage: 'Opting in for data share will allow Main Street America Insurance to retrieve information from the device. By choosing to share data, this policy will qualify for an additional discount.'
    },
    missingFields: {
        id: 'missing fields',
        defaultMessage: 'Please complete the missing fields.'
    },
    centralBurglaryAndDirectLineToPoliceStationNotAllowed: {
        id: 'common.components.ProtectiveDevicesPage.Central Burglary and Direct Line to Police Station not allowed.',
        defaultMessage: 'Central Burglary and Direct Line to Police Station not allowed.'
    },
    centralFireAlarnAndDirectLineToFireStationNotAllowed: {
        id: 'common.components.ProtectiveDevicesPage.Central Fire Alarm and Direct Line to Fire Station not allowed.',
        defaultMessage: 'Central Fire Alarm and Direct Line to Fire Station not allowed.'
    },
    automaticSprinklerLimitedAreasAndAutomaticSprinklerSystemsNotAllowed: {
        id: 'common.components.ProtectiveDevicesPage.Only one of the following device types are allowed: Sprinkler, Automatic Sprinkler System, Automatic Sprinkler System Limited Areas.',
        defaultMessage: 'Only one of the following device types are allowed: Sprinkler, Automatic Sprinkler System, Automatic Sprinkler System Limited Areas.'
    },
    protectiveDeviceModalHeader: {
        id: 'common.components.ProtectiveDevicesPage.Protective Devices',
        defaultMessage: 'Protective Devices'
    }
});
