import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    get as _get, isEqual as _isEqual, find as _find
} from 'lodash';
import './EARiskAnalysisMiscComponent.messages';
import metadata from './EARiskAnalysisMiscComponent.metadata.json5';

function EARiskAnalysisMiscComponent(props) {
    const {
        submissionVM,
        path,
        id,
        onValidate,
        onValueChange,
        isSkipping,
        viewOnlyMode,
        showHousehold,
        updateWizardData,
        shouldShowCedingField
    } = props;

    const { isComponentValid } = useValidation(id);
    const [geoDataResponse, setGeoDataResponse] = useState({});
    const jobNumber = _get(submissionVM, 'quoteID.value') ?? _get(submissionVM, 'jobID.value');
    const isQuoteTypeFull = _get(submissionVM, 'value.quoteType') ? _get(submissionVM, 'value.quoteType') === 'Full' : true;

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [submissionVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const generateOverrides = useCallback(
        () => {
            const overrideProps = {};
            const prefillDrivers = _get(submissionVM, 'lobData.personalAuto_EA.prefillDrivers.value');

            if (prefillDrivers) {
                prefillDrivers.forEach((_prefillDriver, index) => {
                    overrideProps[`prefillDriver${index}`] = {
                        viewOnlyMode
                    };
                });
            }

            return overrideProps;
        },
        [submissionVM, viewOnlyMode],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        geoDataComponent: {
            quoteID: jobNumber,
            geoDataResponse,
            setGeoDataResponse,
            vehicles: _get(submissionVM, 'lobData.personalAuto_EA.coverables.vehicles.value', [])
        },
        rvpSection: {
            submissionVM,
            updateWizardData,
            viewOnlyMode,
            riskAnalysis: true,
            onValidate
        },
        vehicleHistory: {
            quoteID: jobNumber,
            visible: isQuoteTypeFull,
            isSkipping
        },
        underwritingNotInHousehold: {
            visible: !(_isEqual(
                _find(submissionVM.lobData.personalAuto_EA.prefillDrivers.children, (driver) => driver.driverStatus.value.code === 'NotInHousehold'), undefined
            )) && showHousehold
        },
        underwritingNotInHouseholdLabel: {
            visible: !(_isEqual(
                _find(submissionVM.lobData.personalAuto_EA.prefillDrivers.children, (driver) => driver.driverStatus.value.code === 'NotInHousehold'), undefined
            )) && showHousehold
        },
        ncCedingIndicator: {
            visible: shouldShowCedingField,
            readOnly: true // needs to override the '@field'
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [submissionVM, overrideProps]
    );
    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

EARiskAnalysisMiscComponent.propTypes = {
    value: PropTypes.shape({}),
    submissionVM: PropTypes.shape({
        lobData: PropTypes.shape({
            personalAuto_EA: PropTypes.shape({
                prefillDrivers: PropTypes.shape({
                    children: PropTypes.arrayOf(PropTypes.shape({}))
                }),
            })
        }),
    }),
    path: PropTypes.string,
    isSkipping: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    viewOnlyMode: PropTypes.bool,
    showHousehold: PropTypes.bool,
    updateWizardData: PropTypes.func,
    shouldShowCedingField: PropTypes.bool
};

EARiskAnalysisMiscComponent.defaultProps = {
    value: {},
    submissionVM: {},
    path: undefined,
    viewOnlyMode: false,
    isSkipping: false,
    showHousehold: true,
    updateWizardData: undefined,
    shouldShowCedingField: false
};

export default EARiskAnalysisMiscComponent;
