import React, {
    useContext, useCallback, useEffect, useState,
} from 'react';
import {
    get, set, isUndefined, findIndex, isEmpty
} from 'lodash';
import { RenewalService } from 'e1p-capability-renewal';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import messages from './VehicleOperatorPage.messages';
import wizardMessages from '../../EURenewalWizard.messages';
import metadata from './VehicleOperatorPage.metadata.json5';

function VehicleOperatorPage(props) {
    const {
        wizardData: renewalVM, updateWizardData, isSkipping,
        onCustom2: handleDiscardChanges,
        jumpTo,
        updateWizardSnapshot,
        steps,
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        onValidate, initialValidation, isComponentValid,
        registerComponentValidation
    } = useValidation('VehicleOperatorPage');
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);
    const changeSummaryIndex = findIndex(steps, ({ path }) => path === '/change-review');
    const viewModelService = useContext(ViewModelServiceContext);
    const isDiscardButtonActive = get(renewalVM, 'value.canDiscard_Ext', false);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [renewalVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        if (isUndefined(renewalVM.lobData.personalUmbrella_EU.vehicleOperators.value)) {
            set(renewalVM.lobData.personalUmbrella_EU.vehicleOperators, 'value', []);
        }

        updateWizardData(renewalVM);
        registerComponentValidation(() => renewalVM.lobData.personalUmbrella_EU.vehicleOperators.aspects.subtreeValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const vehicleOperatorElement = document.getElementById(
            `firstName${renewalVM.lobData.personalUmbrella_EU.vehicleOperators.length - 1}`
        );

        if (vehicleOperatorElement
            && isEmpty(vehicleOperatorElement.value)
            && vehicleOperatorElement.focus !== undefined) {
            vehicleOperatorElement.focus();
        }
    }, [renewalVM.lobData.personalUmbrella_EU.vehicleOperators.length]);


    // this grabs the latest element on the page --
    // this is to make sure this element has been loaded
    const latestVehicleOperatorElement = document.getElementById(
        `euVehicleOperatorComponentDiv${renewalVM.lobData
            .personalUmbrella_EU.vehicleOperators.children.length - 1}`
    );

    useEffect(() => {
        // indexStale set in the add vehicle operator function
        // once latest element is loaded and a new vehicle operator is added
        // we check if the button should be visible
        if (latestVehicleOperatorElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestVehicleOperatorElement]);

    const addVehicleOperator = useCallback(
        () => {
            const {
                _xCenter,
                _dtoName
            } = renewalVM.lobData.personalUmbrella_EU.vehicleOperators;
            const newVehicleOperatorVM = viewModelService.create({}, _xCenter, _dtoName);

            renewalVM.lobData.personalUmbrella_EU.vehicleOperators.pushElement(
                newVehicleOperatorVM
            );
            updateWizardData(renewalVM);
            setCheckScrolling();
            setIndexStale(true);
        },
        [viewModelService, updateWizardData, renewalVM]
    );
    
    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setIsSavingRenewal(false);

            return false;
        },
        [
            authHeader,
            changeSummaryIndex,
            isComponentValid,
            jumpTo,
            renewalVM,
            updateWizardData,
            updateWizardSnapshot
        ]
    );

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        vehicleOperatorPageLoadingIndicator: {
            loaded: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        vehicleOperatorPageContainer: {
            visible: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleOperatorsContainer')
        },
        EUVehicleOperatorGrid: {
            key: get(renewalVM, 'lobData.personalUmbrella_EU.vehicleOperators', []).length,
            transactionVM: renewalVM,
            drivers: get(renewalVM, 'lobData.personalUmbrella_EU.vehicleOperators', []),
            path: 'lobData.personalUmbrella_EU.vehicleOperators.children',
            onValidate,
            showErrors: isPageSubmitted,
            setCheckScrolling,
            updateWizardData
        },
    };

    /**
     * Helper callback for writing values to the view model.
     */
    const writeValue = useCallback(
        (newVal, path) => {
            set(renewalVM, `${path}.value`, newVal);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData]
    );

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            addVehicleOperator,
            onValidate
        },
        resolveComponentMap: {
        }
    };

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const newrenewalVM = viewModelService.clone(renewalVM);
            const quoteData = get(newrenewalVM, 'quoteData.value');
            const response = await RenewalService.saveAndQuoteRenewal(
                [renewalVM.value],
                authHeader
            );
            const respQuoteData = get(response, 'quoteData');

            if (respQuoteData === undefined) {
                set(response, 'quoteData', quoteData);
            }

            set(newrenewalVM, 'value', response);
            updateWizardData(newrenewalVM);
            setIsSavingRenewal(false);

            return newrenewalVM;
        },
        [authHeader, isComponentValid, renewalVM, updateWizardData, viewModelService]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            onSave={onSave}
            showOnSave
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={writeValue}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

VehicleOperatorPage.propTypes = wizardProps;
export default VehicleOperatorPage;
