/* eslint-disable no-plusplus */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { InlineNotification } from '@jutro/components';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { get as _get, set as _set } from 'lodash';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { eaCommonMessages, commonMessages as e1pCommonMessages} from 'e1p-platform-translations';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { DriverAssignmentTable, E1PLoader } from 'e1p-capability-policyjob-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDriverAssignmentPageUtil } from 'e1p-capability-hooks';
import moment from 'moment';

const dobToAge = (dob) => {
    const TODAY = moment();
    const BIRTH_DATE = moment(dob);
    const MONTH_DIFF = TODAY.month() - BIRTH_DATE.month();
    let driverAge = TODAY.year() - BIRTH_DATE.year();

    if (
        MONTH_DIFF < 0 ||
        (MONTH_DIFF === 0 && TODAY.date() < BIRTH_DATE.date())
    ) {
        driverAge += -1;
    }

    return driverAge;
};

function DriverAssignmentPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
    } = props;

    const [principalDriversData, setPrincipalDriversData] = useState([]);
    const [occasionalDriversData, setOccasionalDriversData] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [validationErrors, setValidationErrors] = useState([]);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);

    const { onValidate, initialValidation } = useValidation(
        'EADriverAssignmentPage'
    );

    const {
        shouldOccupationCellShow,
        showOccupationColumn,
        onOccupationChangeCallback,
        checkOccupation,
    } = useDriverAssignmentPageUtil(
        submissionVM,
        principalDriversData,
        setPrincipalDriversData,
        updateWizardData
    );

    const operatorFilter = (driver) => driver.policyDriverRoleType === 'operator';

    useEffect(() => {
        const drivers = _get(
            submissionVM.value,
            'lobData.personalAuto_EA.coverables.drivers'
        ).filter(operatorFilter);
        const vehicles = _get(
            submissionVM.value,
            'lobData.personalAuto_EA.coverables.vehicles'
        );
        const principalData = [];
        const occasionalData = [];

        if (drivers.length >= vehicles.length) {
            // for this case/for principal, vehicles will be static
            let allFoundOccasionalVehicleDrivers = [];

            vehicles.forEach((vehicle) => {
                const foundVehicleDriver = vehicle.vehicleDrivers.find(
                    (vehicleDriver) => vehicleDriver.primaryDriver
                );
                const foundOccasionalVehicleDriversOnVehicle = vehicle.vehicleDrivers.filter(
                    (vehicleDriver) => !vehicleDriver.primaryDriver
                );

                foundOccasionalVehicleDriversOnVehicle.forEach(
                    (vehDriverData) => {
                        _set(
                            vehDriverData,
                            'vehicle.vehicleName',
                            `${vehicle.year} ${vehicle.make} ${vehicle.model}`
                        );
                        _set(vehDriverData, 'vehicle.vin', vehicle.vin);
                        _set(vehDriverData, 'vehicle.fixedId', vehicle.fixedId);
                    }
                );
                allFoundOccasionalVehicleDrivers = allFoundOccasionalVehicleDrivers.concat(
                    foundOccasionalVehicleDriversOnVehicle
                );
                principalData.push(
                    checkOccupation({
                        vehicle: {
                            vehicleName: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
                            vin: vehicle.vin,
                            fixedId: vehicle.fixedId,
                        },
                        driver: {
                            fixedId: foundVehicleDriver?.driverID || undefined,
                        },
                        // vehicle driver fixed id if already existing
                        fixedID: foundVehicleDriver?.fixedID,
                    })
                );
            });

            // ocassional drivers should be remaining drivers that aren't primaries
            // in the case that vehicles were added so that drivers now equal vehicles
            //   the drivers now have to become primary instead of occasional
            if (
                allFoundOccasionalVehicleDrivers.length > 0 &&
                drivers.length !== vehicles.length
            ) {
                // servicing transaction or sub where drivers are assigned already
                allFoundOccasionalVehicleDrivers.forEach((vehicleDriverRow) => {
                    occasionalData.push({
                        driver: { fixedId: vehicleDriverRow.driverID },
                        vehicle: vehicleDriverRow.vehicle,
                        // vehicle driver fixed id if already existing
                        fixedID: vehicleDriverRow?.fixedID,
                    });
                });

                const numberNewDrivers =
                    drivers.length -
                    (allFoundOccasionalVehicleDrivers.length +
                        principalData.length);

                if (numberNewDrivers > 0) {
                    for (let i = 0; i < numberNewDrivers; i++) {
                        occasionalData.push({ driver: {}, vehicle: {} });
                    }
                } else {
                    for (let i = numberNewDrivers; i < 0; i++) {
                        // based on changes to drivers/vehicles, some occ drivers might need to go
                        occasionalData.pop();
                    }
                }
            } else {
                // no operators ever added
                for (let i = 0; i < drivers.length - vehicles.length; i++) {
                    occasionalData.push({ driver: {}, vehicle: {} });
                }
            }
        } else {
            // for this case/for principal, drivers will be static
            drivers.forEach((driver) => {
                const driverAge = dobToAge(driver.person.dateOfBirth);
                const vehicleUiObject = {
                    vehicleName: undefined,
                    vin: undefined,
                    fixedId: undefined,
                };
                let vehicleDriverFixedID;

                vehicles.forEach((vehicle) => {
                    const vehicleHasDriver = vehicle.vehicleDrivers.find(
                        (vehicleDriver) => vehicleDriver.driverID === driver.fixedId
                    );

                    if (vehicleHasDriver) {
                        _set(
                            vehicleUiObject,
                            'vehicleName',
                            `${vehicle.year} ${vehicle.make} ${vehicle.model}`
                        );
                        _set(vehicleUiObject, 'vin', vehicle.vin);
                        _set(vehicleUiObject, 'fixedId', vehicle.fixedId);
                        vehicleDriverFixedID = vehicleHasDriver.fixedID;
                    }
                });
                principalData.push(
                    checkOccupation({
                        vehicle: vehicleUiObject,
                        driver: {
                            driverName: `${driver.person.displayName} - ${driverAge}`,
                            fixedId: driver.fixedId,
                        },
                        fixedID: vehicleDriverFixedID,
                    })
                );
            });
        }

        setOccasionalDriversData(occasionalData);
        setPrincipalDriversData(principalData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // When not all drivers are assigned to vehicles, display validation message.
    const anyUnassignedDrivers = useCallback(() => {
        let hasUnassignedDrivers = false;
        const drivers = _get(
            submissionVM.value,
            'lobData.personalAuto_EA.coverables.drivers'
        ).filter(operatorFilter);
        const allAssignedOperators = principalDriversData.concat(
            occasionalDriversData
        );

        hasUnassignedDrivers = drivers.some((driver) => {
            const foundIndex = allAssignedOperators.findIndex(
                (assignedOperator) => (
                        assignedOperator.driver.fixedId === driver.fixedId &&
                        assignedOperator.vehicle.fixedId // occ may have driver but no veh selected
                    )
            );

            return foundIndex < 0;
        });

        return hasUnassignedDrivers;
    }, [occasionalDriversData, principalDriversData, submissionVM.value]);

    const getVehiclesDtoArray = useCallback(
        (primaryDrivers, nonPrimaryDrivers) => {
            const vehicles = [
                ..._get(
                    submissionVM.value,
                    'lobData.personalAuto_EA.coverables.vehicles'
                ),
            ];
            const newVehiclesArray = [];

            vehicles.forEach((vehicle, index) => {
                const uiPrimaryVehicleDrivers = primaryDrivers.filter(
                    (vehicleDriver) =>
                        vehicleDriver.vehicle?.fixedId === vehicle.fixedId
                );
                const uiNonPrimaryDrivers = nonPrimaryDrivers.filter(
                    (vehicleDriver) =>
                        vehicleDriver.vehicle?.fixedId === vehicle.fixedId
                );
                const allVehDrivers = [];

                uiPrimaryVehicleDrivers.forEach((vehicleDriver) => {
                    const vehicleDriverDto = {
                        primaryDriver: true,
                        driverID: vehicleDriver.driver.fixedId,
                        fixedID: vehicleDriver.fixedID,
                    };
                    const {
                        _xCenter,
                        _dtoName,
                    } = submissionVM.lobData.personalAuto_EA.coverables.vehicles.children[
                        index
                    ].vehicleDrivers;
                    const vehicleDriverVM = viewModelService.create(
                        vehicleDriverDto,
                        _xCenter,
                        _dtoName
                    );

                    allVehDrivers.push(vehicleDriverVM.value);
                });
                uiNonPrimaryDrivers.forEach((vehicleDriver) => {
                    const vehicleDriverDto = {
                        primaryDriver: false,
                        driverID: vehicleDriver.driver.fixedId,
                        fixedID: vehicleDriver.fixedID,
                    };
                    const {
                        _xCenter,
                        _dtoName,
                    } = submissionVM.lobData.personalAuto_EA.coverables.vehicles.children[
                        index
                    ].vehicleDrivers;
                    const vehicleDriverVM = viewModelService.create(
                        vehicleDriverDto,
                        _xCenter,
                        _dtoName
                    );

                    allVehDrivers.push(vehicleDriverVM.value);
                });
                _set(vehicle, 'vehicleDrivers', allVehDrivers);
                newVehiclesArray.push(vehicle);
            });

            return newVehiclesArray;
        },
        [
            submissionVM.lobData.personalAuto_EA.coverables.vehicles.children,
            submissionVM.value,
            viewModelService,
        ]
    );

    // When more drivers than vehicles or no. of drivers equal vehicles, and if a vehicle is not assigned a primary operator, throw a validation error
    const anyUnassignedVehicles = useCallback(() => {
        if (principalDriversData.length < 1) {
            return false; // page not initialized yet
        }

        let hasUnassignedVehicles = false;
        const updatedVehicles = getVehiclesDtoArray(
            principalDriversData,
            occasionalDriversData
        );
        const drivers = _get(
            submissionVM.value,
            'lobData.personalAuto_EA.coverables.drivers'
        ).filter(operatorFilter);
        const vehicles = _get(
            submissionVM.value,
            'lobData.personalAuto_EA.coverables.vehicles'
        );

        if (drivers.length >= vehicles.length) {
            hasUnassignedVehicles = updatedVehicles.some((vehicle) => {
                // make sure the veh has one driver that has ID and is primary
                const vehicleHasPrimary = vehicle.vehicleDrivers.some(
                    (vehicleDriver) => 
                        // primaryDriver is defaulted to true; we really just need to check there is a driver ID as well
                         (
                            vehicleDriver.primaryDriver &&
                            vehicleDriver.driverID
                        )
                    
                );

                return !vehicleHasPrimary;
            });
        }

        return hasUnassignedVehicles;
    }, [
        getVehiclesDtoArray,
        occasionalDriversData,
        principalDriversData,
        submissionVM.value,
    ]);

    // When an operator is assigned as Primary or Occasional operator on more than one vehicle, throw a validation error
    const anyOverAssignedDrivers = useCallback(() => {
        let hasOverAssignedDrivers = false;
        const drivers = _get(
            submissionVM.value,
            'lobData.personalAuto_EA.coverables.drivers'
        ).filter(operatorFilter);
        const allAssignedOperators = principalDriversData.concat(
            occasionalDriversData
        );

        // use some to find a driver assigned on more than one vehicle
        hasOverAssignedDrivers = drivers.some((driver) => {
            const foundDriverInstances = allAssignedOperators.filter(
                (assignedOperator) => assignedOperator.driver.fixedId === driver.fixedId
            );

            return foundDriverInstances.length > 1;
        });

        return hasOverAssignedDrivers;
    }, [occasionalDriversData, principalDriversData, submissionVM.value]);

    const getAvailableDrivers = useCallback(
        () => {
            const drivers = _get(
                submissionVM.value,
                'lobData.personalAuto_EA.coverables.drivers'
            ).filter(operatorFilter);

            return drivers.map((driver) => {
                const driverAge = dobToAge(driver.person.dateOfBirth);

                return {
                    code: driver.fixedId,
                    name: `${driver.person.displayName} - ${driverAge}`,
                };
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const getAvailableVehicles = useCallback(
        () => {
            const vehicles = _get(
                submissionVM.value,
                'lobData.personalAuto_EA.coverables.vehicles'
            );

            return vehicles.map((vehicle) => ({
                    code: vehicle.fixedId,
                    name: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
                }));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onDriverChangeCallback = useCallback(
        (value, selectedRowOfData, id, index) => {
            let newData;

            if (id === 'principleDriversTable') {
                const driverIndex = principalDriversData.findIndex((row) => (
                        row.vehicle.fixedId ===
                        selectedRowOfData.vehicle.fixedId
                    ));

                newData = [...principalDriversData];
                _set(newData[driverIndex], 'driver.fixedId', value);
                setPrincipalDriversData(newData);
            } else {
                newData = [...occasionalDriversData];
                _set(newData[index], 'driver.fixedId', value);
                setOccasionalDriversData(newData);
            }

            // even though subvm not changed itself, need this to signal next function to go off
            //   if the user is navigating to this page from a previous click
            updateWizardData(submissionVM);
        },
        [
            occasionalDriversData,
            principalDriversData,
            submissionVM,
            updateWizardData,
        ]
    );

    const onVehicleChangeCallback = useCallback(
        (value, id, index) => {
            let newData;

            if (id === 'principleDriversTable') {
                newData = [...principalDriversData];
                _set(newData[index], 'vehicle.fixedId', value);
                // considered a new assignment if vehicle driver is changed to different vehicle
                _set(newData[index], 'fixedID', undefined);

                // get the vin to set
                const vehicleFromQdd = _get(
                    submissionVM.value,
                    'lobData.personalAuto_EA.coverables.vehicles'
                ).find((vehicle) => vehicle.fixedId === value);

                _set(newData[index], 'vehicle.vin', vehicleFromQdd?.vin);
                setPrincipalDriversData(newData);
            } else {
                newData = [...occasionalDriversData];
                _set(newData[index], 'vehicle.fixedId', value);
                // considered a new assignment if vehicle driver is changed to different vehicle
                _set(newData[index], 'fixedID', undefined);

                // get the vin to set
                const vehicleFromQdd = _get(
                    submissionVM.value,
                    'lobData.personalAuto_EA.coverables.vehicles'
                ).find((vehicle) => vehicle.fixedId === value);

                _set(newData[index], 'vehicle.vin', vehicleFromQdd?.vin);
                setOccasionalDriversData(newData);
            }

            // even though subvm not changed itself, need this to signal next function to go off
            //   if the user is navigating to this page from a previous click
            updateWizardData(submissionVM);
        },
        [
            occasionalDriversData,
            principalDriversData,
            submissionVM,
            updateWizardData,
        ]
    );


    const anyOccupationFieldMissing = useCallback(() => principalDriversData?.find((data) => (
                shouldOccupationCellShow(data) && data.occupation === undefined
            )), [principalDriversData, shouldOccupationCellShow]);

    const onNext = useCallback(async () => {
        setisLoading(true);
        updateIsPageSubmitted(true);

        const errors = [];
        const hasUnassignedDrivers = anyUnassignedDrivers();
        const hasUnassignedVehicles = anyUnassignedVehicles();
        const hasAnyOverAssignedDrivers = anyOverAssignedDrivers();
        const hasAnyOccupationFieldMissing = anyOccupationFieldMissing();

        if (hasUnassignedDrivers) {
            errors.push(eaCommonMessages.unassignedDriverValidation);
        }

        if (hasUnassignedVehicles) {
            errors.push(eaCommonMessages.unassignedVehicleValidation);
        }

        if (hasAnyOverAssignedDrivers) {
            errors.push(eaCommonMessages.overAssignedDriverValidation);
        }

        if (hasAnyOccupationFieldMissing) {
            errors.push(e1pCommonMessages.pleaseCompleteTheMissingFields);
        }

        if (errors.length > 0) {
            setValidationErrors(errors);
            window.scrollTo(0, 0);
            setisLoading(false);

            return false;
        }

        const updatedVehicles = getVehiclesDtoArray(
            principalDriversData,
            occasionalDriversData
        );

        _set(
            submissionVM.value,
            'lobData.personalAuto_EA.coverables.vehicles',
            updatedVehicles
        );
        // save and quote
        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
            submissionVM.value,
            authHeader
        );
        setisLoading(false);

        return submissionVM;
    }, [
        LoadSaveService,
        anyUnassignedDrivers,
        anyUnassignedVehicles,
        authHeader,
        getVehiclesDtoArray,
        occasionalDriversData,
        principalDriversData,
        submissionVM,
        anyOverAssignedDrivers,
        anyOccupationFieldMissing,
    ]);

    const onSave = useCallback(async () => {
        setisLoading(true);
        updateIsPageSubmitted(true);

        const errors = [];
        const hasUnassignedDrivers = anyUnassignedDrivers();
        const hasUnassignedVehicles = anyUnassignedVehicles();
        const hasAnyOverAssignedDrivers = anyOverAssignedDrivers();
        const hasAnyOccupationFieldMissing = anyOccupationFieldMissing();

        if (hasUnassignedDrivers) {
            errors.push(eaCommonMessages.unassignedDriverValidation);
        }

        if (hasUnassignedVehicles) {
            errors.push(eaCommonMessages.unassignedVehicleValidation);
        }

        if (hasAnyOverAssignedDrivers) {
            errors.push(eaCommonMessages.overAssignedDriverValidation);
        }

        if (hasAnyOccupationFieldMissing) {
            errors.push(e1pCommonMessages.pleaseCompleteTheMissingFields);
        }

        if (errors.length > 0) {
            setValidationErrors(errors);
            window.scrollTo(0, 0);
            setisLoading(false);

            return false;
        }

        setValidationErrors([]);

        const updatedVehicles = getVehiclesDtoArray(
            principalDriversData,
            occasionalDriversData
        );

        _set(
            submissionVM.value,
            'lobData.personalAuto_EA.coverables.vehicles',
            updatedVehicles
        );
        // Update draft
        submissionVM.value = await LoadSaveService.updateDraftSubmission(
            submissionVM.value,
            authHeader
        );
        updateWizardData(submissionVM);
        // wizardsnapshot not updating automatically on next E1PAP1PC-10263
        updateWizardSnapshot(submissionVM);
        setisLoading(false);

        return submissionVM;
    }, [
        LoadSaveService,
        anyUnassignedDrivers,
        anyUnassignedVehicles,
        authHeader,
        getVehiclesDtoArray,
        occasionalDriversData,
        principalDriversData,
        submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        anyOverAssignedDrivers,
        anyOccupationFieldMissing,
    ]);

    const renderInlineValidations = useCallback(() => validationErrors.map((error, index) => (
                <InlineNotification
                    id={`driverAssignmentError${index}`}
                    className="my-2"
                    isDismissable
                    isEmbeddedNotification={false}
                    // Validation error OR Exception
                    message={error}
                    type="error"
                />
            )), [validationErrors]);

    let mainContent = (
        <React.Fragment>
            {principalDriversData.length > 0 ? (
                <DriverAssignmentTable
                    id="principleDriversTable"
                    header={translator(eaCommonMessages.principalDrivers)}
                    getAvailableDrivers={getAvailableDrivers}
                    principalDriverData={principalDriversData}
                    handleDriverChange={onDriverChangeCallback}
                    handleVehicleChange={onVehicleChangeCallback}
                    getAvailableVehicles={getAvailableVehicles}
                    numberOfDrivers={
                        _get(
                            submissionVM.value,
                            'lobData.personalAuto_EA.coverables.drivers',
                            []
                        ).filter(operatorFilter).length
                    }
                    numberOfVehicles={_get(
                        submissionVM.value,
                        'lobData.personalAuto_EA.coverables.vehicles.length'
                    )}
                    onValidate={onValidate}
                    showErrors={isPageSubmitted}
                    handleOccupationChange={onOccupationChangeCallback}
                    showOccupationColumn={showOccupationColumn}
                    shouldOccupationCellShow={shouldOccupationCellShow}
                />
            ) : undefined}
            {occasionalDriversData.length > 0 ? (
                <DriverAssignmentTable
                    id="occasionalDriversTable"
                    header={translator(eaCommonMessages.occasionalDrivers)}
                    getAvailableDrivers={getAvailableDrivers}
                    occasionalDriverData={occasionalDriversData}
                    handleDriverChange={onDriverChangeCallback}
                    handleVehicleChange={onVehicleChangeCallback}
                    getAvailableVehicles={getAvailableVehicles}
                    numberOfDrivers={
                        _get(
                            submissionVM.value,
                            'lobData.personalAuto_EA.coverables.drivers',
                            []
                        ).filter(operatorFilter).length
                    }
                    numberOfVehicles={_get(
                        submissionVM.value,
                        'lobData.personalAuto_EA.coverables.vehicles.length'
                    )}
                    onValidate={onValidate}
                    showErrors={isPageSubmitted}
                    showOccupationColumn={showOccupationColumn}
                />
            ) : undefined}
        </React.Fragment>
    );

    if (isLoading) {
        mainContent = <E1PLoader loaded={false} />;
    }

    return (
        <WizardPage
            isLoadingWholePage={isLoading}
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
        >
            <div className="mt-8">
                {renderInlineValidations()}
                <h4>
                    {translator(eaCommonMessages.driverAssignmentPageTitle)}
                </h4>
                <p>
                    {translator(eaCommonMessages.driverAssignmentPageSubTitle)}
                </p>
                {mainContent}
            </div>
        </WizardPage>
    );
}

DriverAssignmentPage.propTypes = wizardProps;

export default DriverAssignmentPage;
