import React from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { getConfigValue } from 'e1p-portals-util-js';
import metadata from './MoreMenuComponent.metadata.json5';
import styles from './MoreMenuComponent.module.scss';
import './MoreMenuComponent.messages';

const GOOGLE_URI = 'https://www.google.com/';

function MoreMenuComponent() {
    
    const handleMenuItemClick = (evt) => {
        const externalSystemType = evt.redirectToType;

        let externalEndpoint = GOOGLE_URI;

        /**
         * TODO: once the external system URI are available,
         * add URI's to each env config file with fields id as
         * externalPaymentSystem, externalBillingInquirySystem
         */
        if(externalSystemType) {
            externalEndpoint = getConfigValue(`endpoints.${externalSystemType}`, GOOGLE_URI);
        }

        window.open(externalEndpoint, '_blank');
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleMenuItemClick
        }
    };
    
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

MoreMenuComponent.propTypes = {};

MoreMenuComponent.defaultProps = {};

export default MoreMenuComponent;
