import { defineMessages } from 'react-intl';

export default defineMessages({
    useCommonUnderlyingMailingAddress: {
        id: 'platform.inputs.confirmAddress-details.Do you want to use this as the Mailing Address?',
        defaultMessage: 'Do you want to use this as the Mailing Address?'
    },
    whichUnderlyingMailingAddressToUse: {
        id: 'platform.inputs.confirmAddress-details.Which Mailing Address do you want to use?',
        defaultMessage: 'Which Mailing Address do you want to use?'
    },
    isMailingSameAsResidence: {
        id: 'platform.inputs.confirmAddress-details.Mailing Address same as Residence Address',
        defaultMessage: 'Mailing Address same as Residence Address'
    },
    isMailingSameAsResidenceYes: {
        id: 'platform.inputs.confirmAddress-details.isMailingSameAsResidence.Yes',
        defaultMessage: 'Yes'
    },
    isMailingSameAsResidenceNo: {
        id: 'platform.inputs.confirmAddress-details.isMailingSameAsResidence.No',
        defaultMessage: 'No'
    },
    mailingAddress: {
        id: 'platform.inputs.confirmAddress-details.Mailing Address',
        defaultMessage: 'Mailing Address'
    },
    useCommonUnderlyingBillingAddress: {
        id: 'platform.inputs.confirmAddress-details.Do you want to use this as the Billing Address?',
        defaultMessage: 'Do you want to use this as the Billing Address?'
    },
    whichUnderlyingBillingAddressToUse: {
        id: 'platform.inputs.confirmAddress-details.Which Billing Address do you want to use?',
        defaultMessage: 'Which Billing Address do you want to use?'
    },
    isBillingSameAsMailing: {
        id: 'platform.inputs.confirmAddress-details.Billing Address same as Mailing Address',
        defaultMessage: 'Billing Address same as Mailing Address'
    },
    isBillingSameAsMailingYes: {
        id: 'platform.inputs.confirmAddress-details.isBillingSameAsMailing.Yes',
        defaultMessage: 'Yes'
    },
    isBillingSameAsMailingNo: {
        id: 'platform.inputs.confirmAddress-details.isBillingSameAsMailing.No',
        defaultMessage: 'No'
    },
    billingAddress: {
        id: 'platform.inputs.confirmAddress-details.Billing Address',
        defaultMessage: 'Billing Address'
    }
});
