import { useCallback } from 'react';

import {
    get as _get,
    isEmpty as _isEmpty,
    set as _set,
    isEqual as _isEqual,
    remove as _remove,
    find as _find
} from 'lodash';

/**
 * @purpose The purpose of this util is to reuse code between
 *   Verified and unverified flows
 * @param {VMNode} priorPoliciesVMList Prior policy dto
 * @param {Function} viewModelService
 * @param {Function} onValueChange updates state
 * @param {String} path
 * @param {Function} disregardFieldValidation needed for removals
 * @param {*} messages Needed for the modals
 * @returns {*} object
 */
function usePriorCarrierUtil(
    priorPoliciesVMList,
    viewModelService,
    onValueChange,
    path,
    disregardFieldValidation = undefined,
    messages = {}
) {
    const createPriorPolicyVM = useCallback(
        (integrationId) => {
            if (_isEmpty(priorPoliciesVMList.value)) {
                _set(priorPoliciesVMList, 'value', []);
            }

            const priorPolicyDto = {
                policyInceptionDate: undefined,
                policyHolderExpirationDate: undefined,
                policyRole: 'PolicyNamedInsured',
                continuousCoverageInd: false,
                integrationId,
                coverages: [{ coverageCode: 'BodilyInjury' }],
                policyType: 'Auto',
                policyStatus: 'Active'
            };
            const { _dtoName, _xCenter } = priorPoliciesVMList;
            const priorPolicies = viewModelService.create(
                priorPolicyDto,
                _xCenter,
                _dtoName
            );

            _set(
                priorPolicies,
                'policySource',
                _find(priorPolicies.policySource.aspects.availableValues, (value) => value.code === 'ManuallyAdded')
            );
            priorPoliciesVMList.value.push(priorPolicies.value);
            onValueChange(priorPoliciesVMList.value, `${path}.value`);
        },
        [onValueChange, path, priorPoliciesVMList, viewModelService]
    );

    const removePriorPolicy = useCallback(
        (evt) => {
            const priorPolicyListPath = 'lobData.personalAuto_EA.priorPolicies';
            const priorPolicyPath = evt.path;
            const priorPolicy = _get(priorPoliciesVMList, priorPolicyPath).value;
            const priorPolicyList = priorPoliciesVMList.value;
            const { modalApi } = _get(window, '__giamfam.modalApi');

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.removeDriverTitle,
                message: messages.removePolicyUpdateDescription
            }).then((result) => {
                if (result !== 'cancel') {
                    const arrayForNamedInsured = priorPolicyList.filter((policy) => (
                        priorPolicy.integrationId === policy.integrationId
                        && priorPolicy.policySource === policy.policySource
                    ));
                    const pNamedIndex = arrayForNamedInsured.findIndex(
                        (pNamedFromList) => _isEqual(pNamedFromList, priorPolicy)
                    );
                    const newpriorPolicyList = _remove(priorPolicyList,
                        (pNamedFromList) => _isEqual(pNamedFromList, priorPolicy));

                    _set(priorPoliciesVMList, newpriorPolicyList, priorPolicyListPath);
                    disregardFieldValidation(`primaryNamedPriorPolicyID${pNamedIndex}`);
                    disregardFieldValidation(`secondaryNamedPriorPolicyID${pNamedIndex}`);
                    onValueChange(priorPoliciesVMList.value, `${path}.value`);
                }
            });
        },
        [
            disregardFieldValidation,
            messages.removeDriverTitle,
            messages.removePolicyUpdateDescription,
            onValueChange,
            path,
            priorPoliciesVMList
        ]
    );

    /**
     * returns latest prior policy for given named insured
     */
    const getLatestPriorPolicy = useCallback((integrationID, priorPoliciesValues = undefined) => {

        const priorPolicies = priorPoliciesValues || _get(priorPoliciesVMList, 'value', []);

        const namedInsuredPriorPolicies = priorPolicies
            .filter((priorPolicy) => priorPolicy.integrationId === integrationID
                && _get(priorPolicy, 'policySource') === 'Prefill');

        const latestPriorPolicy = namedInsuredPriorPolicies.reduce((latestPolicy, currentPriorPol) => {
            const currentPolExpDate = new Date(currentPriorPol.policyHolderExpirationDate);
            const latestPolicyExpDate = new Date(latestPolicy.policyHolderExpirationDate);

            return latestPolicyExpDate > currentPolExpDate ? latestPolicy : currentPriorPol;
        }, [])

        return latestPriorPolicy;
    }, [priorPoliciesVMList]);

    /**
     * returns if latest prior policy has coverage lapse or not
     */
    const hasCoverageLapse = useCallback((integrationID, priorPoliciesValues) => {

        const latestPriorPolicy = getLatestPriorPolicy(integrationID, priorPoliciesValues);

        return !latestPriorPolicy?.continuousCoverageInd;

    }, [getLatestPriorPolicy]);

    return {
        createPriorPolicyVM,
        removePriorPolicy,
        getLatestPriorPolicy,
        hasCoverageLapse
    };
}

export default usePriorCarrierUtil;
