import { defineMessages } from 'react-intl';

export default defineMessages({
    activities: {
        id: 'e1p.gateway.views.activities-landing.Activities',
        defaultMessage: 'Activities'
    },
    advanceFilter: {
        id: 'e1p.gateway.views.activities-landing.Advanced Filter',
        defaultMessage: 'Advanced Filter'
    },
    allOpen: {
        id: 'e1p.gateway.views.activities-landing.All Open',
        defaultMessage: 'All Open'
    },
    createdByYou: {
        id: 'e1p.gateway.views.activities-landing.Created By Me',
        defaultMessage: 'Created By Me'
    },
    allCompleted: {
        id: 'e1p.gateway.views.activities-landing.All Completed',
        defaultMessage: 'All Completed'
    },
    allCanceled: {
        id: 'e1p.gateway.views.activities-landing.All Canceled',
        defaultMessage: 'All Canceled'
    },
    yourComplete: {
        id: 'e1p.gateway.views.activities-landing.My Completed',
        defaultMessage: 'My Completed'
    },
    yourOpen: {
        id: 'e1p.gateway.views.activities-landing.My Open',
        defaultMessage: 'My Open'
    },
    note: {
        id: 'e1p.gateway.directives.activity-view-summary.Note',
        defaultMessage: 'Note'
    },
    noActivitiesFound: {
        id: 'e1p.gateway.directives.activity-schedule.No Activities found.',
        defaultMessage: 'No Activities found.'
    },
    dueAfterThisWeek: {
        id: 'e1p.gateway.directives.activity-schedule.Due after this week',
        defaultMessage: 'DUE AFTER THIS WEEK'
    },
    overdue: {
        id: 'e1p.gateway.directives.activity-schedule.Overdue',
        defaultMessage: 'OVERDUE'
    },
    dueToday: {
        id: 'e1p.gateway.directives.activity-schedule.Due Today',
        defaultMessage: 'DUE TODAY'
    },
    dueTomorrow: {
        id: 'e1p.gateway.directives.activity-schedule.Due Tomorrow',
        defaultMessage: 'DUE TOMORROW'
    },
    dueThisWeek: {
        id: 'e1p.gateway.directives.activity-schedule.Due this week',
        defaultMessage: 'DUE THIS WEEK'
    },
    completedActivity: {
        id: 'e1p.gateway.directives.activity-schedule.Complete',
        defaultMessage: 'Completed',
    }
});
