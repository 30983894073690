import React, {
    useCallback, useContext, useMemo, useState, useEffect
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    cloneDeep as _cloneDeep,
    pullAt as _pullAt
} from 'lodash';
import { EndorsementService } from 'e1p-capability-policychange';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import {
    useSniUtil,
    useEffectiveDateUtil,
    useLandingPageUtil,
    useNewBusinessUtil,
    e1pDateUtil
} from 'e1p-capability-hooks';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import moment from 'moment';
import appConfig from 'app-config';
import messages from './InsuredDetailsPage.messages';
import metadata from './InsuredDetailsPage.metadata.json5';


const LOB = 'homeowners_EH';

function InsuredDetailsPage(props) {
    const modalApi = useModal();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const translator = useTranslator();
    const [lapseInCoverage, updateLapseInCoverage] = useState(undefined);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [policyIsBackDated, setPolicyIsBackDated] = useState(false);
    const [effDateChanged, setEffDateChanged] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const isAnExistingAccount = true;
    const {
        wizardData: policyChangeVM, updateWizardData, isSkipping, steps, jumpTo, updateWizardSnapshot, authUserData
    } = props;
    const [initialHouseholdMembers, setInitialHouseholdMembers] = useState([]);
    const { daysInFutureForBookroll, daysInFuture, daysInPast } = appConfig.dateInputConfig;

    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [lastPolicyExpirationDate] = useState(
        _cloneDeep(policyChangeVM.value.lobData.homeowners_EH
        .priorPolicies[0]?.policyExpirationDate));
    const {
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        onValidate
    } = useValidation('InsuredDetailsPage');
    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    const { createSecondaryNamedInsuredVM, removeSni, addSniInCoverable } = useSniUtil(
        policyChangeVM,
        updateWizardData,
        viewModelService,
        LOB,
        disregardFieldValidation
    );

    const {
        getProducerDetailsFromExternalId
    } = useNewBusinessUtil(
        policyChangeVM,
        updateWizardData,
        viewModelService,
        LOB,
        isAnExistingAccount,
        authHeader,
        () => { } // can't change in policy change so parm not needed
    );

    const policyType = _get(policyChangeVM, 'value.policyType_Ext', _get(policyChangeVM, 'value.policyType'));
    const policyForm = _get(policyChangeVM, 'lobData.homeowners_EH.policyFormType.value');
    const {
        getEffectiveDateBounds
    } = useEffectiveDateUtil(policyChangeVM, updateWizardData, false);

    const { maxEffectiveDate, minEffectiveDate } = useMemo(
        () =>
            // If Book Transfer is Yes, allow quoting up to 120 days in the future, otherwise 60 days
            producerCodeDetails.bookTransferIndicator ? getEffectiveDateBounds(
                policyChangeVM.baseData.periodStartDate.value,
                policyChangeVM.baseData.periodEndDate.value,
                daysInPast,
                daysInFutureForBookroll
            ) : getEffectiveDateBounds(
                policyChangeVM.baseData.periodStartDate.value,
                policyChangeVM.baseData.periodEndDate.value,
                daysInPast,
                daysInFuture
            )
        ,
        [
            getEffectiveDateBounds,
            policyChangeVM.baseData.periodEndDate.value,
            policyChangeVM.baseData.periodStartDate.value,
            producerCodeDetails.bookTransferIndicator,
            daysInFutureForBookroll,
            daysInFuture,
            daysInPast
        ]
    );

    const setBackdateInformation = () => {
        const policyEffectiveDate = moment(policyChangeVM.baseData.effectiveDate.value);
        const effectiveDateBeforeToday = moment().isAfter(policyEffectiveDate, 'd');

        setPolicyIsBackDated(effectiveDateBeforeToday);
    };

    useEffect(() => {
        setBackdateInformation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // keeping the IF condition here, but under no circumstances should it be undefined
        if (policyChangeVM.baseData.externalID_Ext?.value) {
            (async () => {
                const details = await getProducerDetailsFromExternalId(
                    policyChangeVM.baseData.externalID_Ext.value
                );

                setProducerCodeDetails(details);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const existingHouseholdOccupants = _cloneDeep(_get(policyChangeVM.value, 'lobData.homeowners_EH.coverables.householdOccupants'));

        setInitialHouseholdMembers(existingHouseholdOccupants);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            policyChangeVM.lobData.homeowners_EH.priorPolicies.value !== undefined
            && policyChangeVM.lobData.homeowners_EH.priorPolicies.length > 0
        ) {
            updateLapseInCoverage(
                !policyChangeVM.lobData.homeowners_EH.priorPolicies.value[0].continuousCoverageInd
            );
        }
    }, [
        policyChangeVM.lobData.homeowners_EH.priorPolicies.length,
        policyChangeVM.lobData.homeowners_EH.priorPolicies.value]);

    const addNewPrimaryNamedInsured = useCallback(() => {
        const namedInsuredVM = _get(
            policyChangeVM,
            `lobData[${LOB}].primaryNamedInsured`
        );
        const householdOccupants = _get(policyChangeVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');

        if (householdOccupants) {
            const niIndex = householdOccupants.findIndex(
                (occupant) => occupant.person.publicID === namedInsuredVM.person.publicID.value
            );

            _pullAt(householdOccupants, niIndex);
        }

        const primaryNamedInsuredObj = {
            person: {
                lastName: '',
                firstName: '',
                dateOfBirth: {
                    year: undefined,
                    month: undefined,
                    day: undefined
                },
                // save primary address
                primaryAddress: (() => {
                    // default new person address to prior pni
                    const priorPniAddress = _get(
                        policyChangeVM,
                        `lobData[${LOB}].primaryNamedInsured.person.primaryAddress.value`
                    );

                    // Needs new public ID to make it a new adress in the DB
                    _set(priorPniAddress, 'publicID', undefined);

                    return priorPniAddress;
                })()
            }
        };
        const { _dtoName, _xCenter } = policyChangeVM.lobData[LOB].primaryNamedInsured;
        const primaryNamedInsuredVM = viewModelService.create(
            primaryNamedInsuredObj,
            _xCenter,
            _dtoName
        );

        _set(
            policyChangeVM,
            `lobData[${LOB}].primaryNamedInsured.value`,
            primaryNamedInsuredVM.value
        );
        updateWizardData(policyChangeVM);
    }, [policyChangeVM, updateWizardData, viewModelService]);

    const loadFieldsFromInsuredDetailsPage = useCallback((responseData) => {
        _set(responseData, 'baseData.effectiveDate', policyChangeVM.baseData.effectiveDate.value);
        _set(responseData, 'policyChangeSource_Ext', policyChangeVM.policyChangeSource_Ext.value);
        _set(responseData, 'policyChangeReason_Ext', policyChangeVM.policyChangeReason_Ext.value);
        _set(
            responseData,
            `lobData[${LOB}].secondaryNamedInsured`,
            policyChangeVM.lobData[LOB].secondaryNamedInsured.value
        );
        _set(
            responseData,
            `lobData[${LOB}].primaryNamedInsured`,
            policyChangeVM.lobData[LOB].primaryNamedInsured.value
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dateFormat = (date) => {
        const newDate = moment(date).toDate();
        const momentDate = moment(newDate)
            .format('YYYY-MM-DDTHH:mm:ss')
            .concat('Z');

        return momentDate;
    };

    const syncNamedInsuredToHouseholdMembers = useCallback(() => {
        // sync NI changes to householdoccupants
        //   changes to NI will not override data in the occ so must be synced
        const householdOccupants = _get(policyChangeVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
        const pni = _get(policyChangeVM.value, 'lobData.homeowners_EH.primaryNamedInsured');
        const sni = _get(policyChangeVM.value, 'lobData.homeowners_EH.secondaryNamedInsured');

        _set(policyChangeVM, 'baseData.accountHolder_Ext.value', pni.person);

        const pniOccupant = householdOccupants
            .find((occupant) => occupant.person.publicID === pni.person.publicID);
        // SNI may be undefined so being null safe there
        const sniOccupant = householdOccupants
            .find((occupant) => occupant.person.publicID === sni?.person?.publicID);

        if (pniOccupant) {
            // updates to existing pni contact
            _set(pniOccupant, 'person', pni.person);
        }

        if (sni && sniOccupant) {
            // updates to existing sni contact
            _set(sniOccupant, 'person', sni.person);
        }

        if (pni && !pniOccupant) {
            // check first in the existing household occupants before creating new
            if (!_isEmpty(initialHouseholdMembers)) {
                const initialPniOccupant = initialHouseholdMembers
                    .find((occupant) => occupant.person.publicID === pni?.person?.publicID);

                if (initialPniOccupant) {
                    _set(initialPniOccupant, 'person', pni.person);
                    _set(initialPniOccupant, 'relationshipToNI', undefined);
                    householdOccupants.push(initialPniOccupant);
                }
            } else {
                householdOccupants.push({ person: pni.person });
            }

            // only update this if pni/sni changes
            _set(policyChangeVM, `lobData[${LOB}].coverables.householdOccupants`, householdOccupants);
        }

        if (sni && !sniOccupant) {
            // check first in the existing household occupants before creating new
            if (!_isEmpty(initialHouseholdMembers)) {
                const initialSniOccupant = initialHouseholdMembers
                    .find((occupant) => occupant.person.publicID === sni?.person?.publicID);

                if (initialSniOccupant) {
                    _set(initialSniOccupant, 'person', sni.person);
                    _set(initialSniOccupant, 'relationshipToNI', sni.relationshipToNI);
                    householdOccupants.push(initialSniOccupant);
                } else {
                    householdOccupants.push(
                        { person: sni.person, relationshipToNI: sni.relationshipToNI }
                    );
                }
            } else {
                householdOccupants.push(
                    { person: sni.person, relationshipToNI: sni.relationshipToNI }
                );
            }

            // only update this if pni/sni changes
            _set(policyChangeVM, `lobData[${LOB}].coverables.householdOccupants`, householdOccupants);
        }
    }, [initialHouseholdMembers, policyChangeVM]);

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            const correctDateFormat = dateFormat(
                policyChangeVM.lobData.homeowners_EH.priorPolicies.children[0]?.policyExpirationDate.value
            );

            _set(
                policyChangeVM,
                'lobData.homeowners_EH.priorPolicies.children[0].policyExpirationDate.value',
                correctDateFormat
            );
            setIsSavingEndorsement(true);
            syncNamedInsuredToHouseholdMembers();

            if (policyChangeVM.jobID.value === undefined) {
                const response = await EndorsementService.checkEffectiveDateIsValid(
                    [
                        policyChangeVM.value.policyNumber,
                        policyChangeVM.value.baseData.effectiveDate
                    ],
                    authHeader
                );

                // Need this for validation rules
                _set(policyChangeVM, 'flowStepIDs_Ext.value', ['namedinsured']);
                _set(policyChangeVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);

                if (response) {
                    const responseData = await EndorsementService.loadEndorsementWithEffectiveDate(
                        [
                            policyChangeVM.value.policyNumber,
                            policyChangeVM.value.baseData.effectiveDate
                        ],
                        authHeader
                    );

                    loadFieldsFromInsuredDetailsPage(responseData);

                    const saveResponse = await EndorsementService.saveEndorsement(
                        [policyChangeVM.value],
                        authHeader
                    );

                    if (saveResponse.baseData.exceptions_Ext) {
                        // This code is to handle the exception by showing it
                        // using Inline Notification Component
                        _set(policyChangeVM, 'baseData.exceptions_Ext.value', saveResponse.baseData.exceptions_Ext);
                        updateWizardData(policyChangeVM);
                    } else {
                        _set(policyChangeVM, 'value', saveResponse);
                        updateWizardData(policyChangeVM);
                        setIsSavingEndorsement(false);

                        return policyChangeVM;
                    }
                }
            } else {
                _set(policyChangeVM, 'flowStepIDs_Ext.value', ['namedinsured']);
                _set(policyChangeVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);

                const saveResponse = await EndorsementService.saveEndorsement(
                    [policyChangeVM.value],
                    authHeader
                );

                if (saveResponse.baseData.exceptions_Ext) {
                    // This code is to handle the exception by showing it
                    // using Inline Notification Component
                    _set(policyChangeVM, 'baseData.exceptions_Ext.value', saveResponse.baseData.exceptions_Ext);
                    updateWizardData(policyChangeVM);
                } else {
                    _set(policyChangeVM, 'value', saveResponse);
                    updateWizardData(policyChangeVM);
                    // sni exist or not is being checked in the method itself, so no need to check here
                    addSniInCoverable();
                    setIsSavingEndorsement(false);

                    return policyChangeVM;
                }
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [addSniInCoverable, authHeader, isComponentValid, loadFieldsFromInsuredDetailsPage, policyChangeVM, syncNamedInsuredToHouseholdMembers, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, policyChangeVM, updateWizardSnapshot]
    );


    const generateOverrides = useCallback(() => {
        const overrides = {};

        overrides.primaryNamedInsured = {
            isPNI: true
        };

        return overrides;
    }, []);

    const swapPniSni = useCallback(() => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: e1pCommonMessages.swapPniSniTitle,
            message: e1pCommonMessages.swapPniSniMessage,
            confirmButtonText: e1pCommonMessages.confirm,
            cancelButtonText: e1pCommonMessages.cancel
        }).then(
            (result) => {
                if (result !== 'cancel') {
                    const pniPerson = {
                        ..._get(
                            policyChangeVM,
                            'lobData.homeowners_EH.primaryNamedInsured.person.value'
                        )
                    };
                    const sniPerson = {
                        ..._get(policyChangeVM, 'lobData.homeowners_EH.secondaryNamedInsured.person.value')
                    };

                    let householdOccupants = _get(policyChangeVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
                    let sniOccupant = householdOccupants
                        .find((occupant) => occupant.person.publicID === sniPerson?.publicID);

                    if (!sniOccupant) {
                        addSniInCoverable();
                        householdOccupants = _get(policyChangeVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
                        sniOccupant = householdOccupants
                            .find((occupant) => occupant.person.publicID === sniPerson.publicID);
                    }

                    const pniOccupant = householdOccupants
                        .find((occupant) => occupant.person.publicID === pniPerson.publicID);

                    const relationshipToNI = _get(policyChangeVM, 'value.lobData.homeowners_EH.secondaryNamedInsured.relationshipToNI', 'notrelated');

                    _set(pniOccupant, 'relationshipToNI', relationshipToNI);
                    _set(sniOccupant, 'relationshipToNI', undefined);
                    // swap PNI and SNI
                    _set(
                        policyChangeVM,
                        'lobData.homeowners_EH.primaryNamedInsured.person.value',
                        sniPerson
                    );
                    _set(
                        policyChangeVM,
                        'lobData.homeowners_EH.secondaryNamedInsured.person.value',
                        pniPerson
                    );
                    // since we are swapping PNI and SNI, we need remove publicID, FixedID and integrationID from both policyContactRoles
                    // i.e PNI and SNI
                    _set(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.fixedId.value', undefined);
                    _set(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.publicID.value', undefined);
                    _set(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.integrationId.value', undefined);
                    _set(policyChangeVM, 'lobData.homeowners_EH.secondaryNamedInsured.fixedId.value', undefined);
                    _set(policyChangeVM, 'lobData.homeowners_EH.secondaryNamedInsured.publicID.value', undefined);
                    _set(policyChangeVM, 'lobData.homeowners_EH.secondaryNamedInsured.integrationId.value', undefined);

                    // If paperless Email id exist and pni email id is undefiend
                    // then update pni email to paperless email
                    if (!_get(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value')
                        && !!_get(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value')) {
                        _set(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value',
                            _get(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value'));
                    }

                    updateWizardData(policyChangeVM);
                }
            },
            () => { }
        );
    }, [addSniInCoverable, policyChangeVM, updateWizardData, modalApi]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            showRequired: true,
            autoComplete: false
        },
        agencyName: {
            value: producerCodeDetails.agencyName
        },
        bookTransferIndicator: {
            value: producerCodeDetails.bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: producerCodeDetails.serviceCenterIndicatorValue
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        changeEffectiveDate: {
            dateDTO: policyChangeVM.baseData.effectiveDate,
            updateDateDto: () => {
                setEffDateChanged(true);
                setBackdateInformation();
                updateWizardData(policyChangeVM);
            },
            minDate: minEffectiveDate,
            maxDate: maxEffectiveDate,
            showErrors: isPageSubmitted,
            onValidate
        },
        coverageRateAsOfDate: {
            dateDTO: policyChangeVM.baseData.periodRateAsOfDate,
            updateDateDto: () => updateWizardData(policyChangeVM),
            defaultToToday: true,
            showErrors: isPageSubmitted,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        policyInformationInfoContainer: {
            columns: authUserData.permissions_Ext.includes('editrateasofdate')
                ? ['0.25fr', '0.25fr', '0.25fr', '0.25fr', '0.25fr'] : ['0.25fr', '0.25fr', '0.25fr', '0.25fr']
        },
        insuredDetailsPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        insuredDetailsPageContainer: {
            visible: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges
        },
        mailingAndBillingAddressComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            showErrors: isPageSubmitted,
        },
        addSni: {
            visible: !_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        sniContainer: {
            visible: !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        removeSni: {
            visible: !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        changePniComponent: {
            accountNumber: _get(policyChangeVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(policyChangeVM, 'baseData.accountNumber.value'),
            allowNewContact: false,
            excludedContacts: [
                _get(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            householdOccupants: _get(
                policyChangeVM.value,
                `lobData[${LOB}].coverables.householdOccupants`,
                undefined
            )
        },
        changeSniComponent: {
            accountNumber: _get(policyChangeVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(policyChangeVM, 'baseData.accountNumber.value')
                && !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            excludedContacts: [
                _get(policyChangeVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            householdOccupants: _get(
                policyChangeVM.value,
                `lobData[${LOB}].coverables.householdOccupants`,
                undefined
            )
        },
        riskAddressComponent: {
            submissionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            viewModelService,
            viewOnlyMode: policyType !== 'HO4',
            showErrors: isPageSubmitted
        },
        ehPriorAddressComponent: {
            submissionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            viewModelService,
            viewOnlyMode: true,
            visible: policyType === 'HO3' || policyType === 'HF9',
            LOB,
            showErrors: isPageSubmitted,
        },
        coverageLapseAndExpirationDate: {
            visible: policyType !== 'HO4'
        },
        coverageLapse: {
            value: lapseInCoverage,
            readOnly: true
        },
        policyForm: {
            value: policyForm
        },
        usageType: {
            visible: policyType !== 'HO4'
        },
        insuranceHistoryMainDiv: {
            visible: policyType !== 'HO4'
        },
        lastPolicyExpirationDate: {
            // HO4 will not have prior policies, so implement check for undefined
            dateDTO: lastPolicyExpirationDate,
            readOnly: true
        },
        swapPniSni: {
            visible: !!_get(policyChangeVM, `value.lobData[${LOB}].secondaryNamedInsured.person.publicID`)
                && !!_get(policyChangeVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`)
        },
        riskState: {
            readOnly: true
        },
        insuredResidenceAddressWarningMessageDiv: {
            visible: policyType !== 'HO4'
        },
        hO4InsuredResidenceAddressWarningMessageDiv: {
            visible: policyType === 'HO4'
        },
        lossQuestionEmptyContainer: {
            visible: !policyIsBackDated || !effDateChanged,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 4 : 3
        },
        lossQuestionContainer: {
            visible: policyIsBackDated && effDateChanged,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 4 : 3
        },
        lossOccurredBetweenEffectiveDateAndCreateDateInd: {
            defaultValue: policyChangeVM.value.hasLossOnOrAfterEffDate
        },
        policyType: {
            readOnly: true,
            value: policyType
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate,
            onRemoveSni: removeSni,
            onAddNewPni: addNewPrimaryNamedInsured,
            onSwapPniSni: swapPniSni
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
            metadata.pageContent,
            policyChangeVM,
            id,
            path,
            overrideProps
        ),
        [overrideProps, policyChangeVM]
    );

    const saveAndQuote = useCallback(
        async (isQuote) => {
            syncNamedInsuredToHouseholdMembers();

            if (!isQuote) {
                const saveResponse = await EndorsementService.saveEndorsement(
                    [policyChangeVM.value],
                    authHeader
                );

                _set(policyChangeVM, 'value', saveResponse);
            } else {
                const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                    [(policyChangeVM.value)],
                    authHeader
                );

                _set(policyChangeVM, 'value', quoteResponse);
            }

            updateWizardData(policyChangeVM);
            // sni exist or not is being checked in the method itself, so no need to check here
            addSniInCoverable();

            return policyChangeVM.value;
        },
        [addSniInCoverable, authHeader, policyChangeVM, syncNamedInsuredToHouseholdMembers, updateWizardData]
    );

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            const correctDateFormat = dateFormat(
                policyChangeVM.lobData.homeowners_EH.priorPolicies.children[0]?.policyExpirationDate.value
            );

            _set(
                policyChangeVM,
                'lobData.homeowners_EH.priorPolicies.children[0].policyExpirationDate.value',
                correctDateFormat
            );
            setIsSavingEndorsement(true);
            policyChangeVM.value = await saveAndQuote(true);
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [getLandingPageIndexForQuotedJob, isComponentValid, jumpTo, policyChangeVM, saveAndQuote, steps, updateWizardData, updateWizardSnapshot]
    );


    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showPrevious={false}
            showCustom
            onCustom={onCustom}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

InsuredDetailsPage.propTypes = wizardProps;

export default withAuthenticationContext(InsuredDetailsPage);
