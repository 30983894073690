const overrides = {
    field: {

        numberOfUnits: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        extereriorWallFinish: {
            partner: {
                MSA: {
                    states: ['NC']
                }
            }
        },
        hasDogInHome: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        highRiskDogBreedInd: {
            partner: {
                MSA: {
                    // IAP-5194, highRiskDogBreedInd not available for IL
                    states: ['IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        }
    }
};
export default overrides;
