import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import {
    TranslatorContext, withIntl
} from '@jutro/locale';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import PropTypes from 'prop-types';
import { PolicyService as GatewayPolicyService } from 'e1p-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import appConfig from 'app-config';
import {
    FieldLinkComponent,
    Currency as CurrencyField
} from 'gw-components-platform-react';
import { E1PProductImageComponent } from 'e1p-capability-policyjob-react';
import metadata from './Policies.metadata.json5';
import styles from './Policies.module.scss';
import messages from './Policies.messages';

const { gatewayParamConfig = {} } = appConfig;

const availableTilesForPoliciesView = ['recentlyViewedTile', 'recentlyIssuedTile'];
const availableTilesForTransactionsView = ['quotes', 'change', 'cancellation', 'renewal'];

const tableHeadings = {
    recentlyViewedTile: messages.recentlyViewedPolicies,
    recentlyIssuedTile: messages.recentlyIssuedPolicy,
    Policies: messages.policies,
    Quotes: messages.policyLandingQuotes,
    Renewal: messages.policyLandingRenewal,
    Change: messages.policyLandingChange,
    Cancellation: messages.policyLandingCancellation,
    quotes: messages.openQuotes,
    renewal: messages.openRenewals,
    change: messages.openChanges,
    cancellation: messages.openCancellations
};

class Policies extends Component {
    static propTypes = {
        match: PropTypes.shape({
            path: PropTypes.string,
            params: PropTypes.shape({
                PolicyNumber: PropTypes.string
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.string
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        intl: PropTypes.func.isRequired,
        producerCode: PropTypes.string.isRequired
    };

    static contextType = TranslatorContext;

    state = {
        toggleFilter: true,
        currentView: '',
        policyLandingLoader: false,
        policyNumberVisible: true,
        exceptions: []
    };

    componentDidMount() {
        const { location } = this.props;
        const { state } = location;

        if (state) {
            const { selectedTile } = state;
            const isPolicyNumberVisible = selectedTile !== 'quotes';

            this.setState({
                currentView: selectedTile !== undefined ? selectedTile : 'recentlyViewedTile',
                policyNumberVisible: isPolicyNumberVisible
            });
        } else {
            this.setState({ currentView: 'recentlyViewedTile' });
        }
    }

    getFormattedCurrency = (premiumAmount, policyNumber) => {
        const totalPremiumCurrencyId = `totalPremium_${policyNumber}`;

        return (
            <CurrencyField
                id={totalPremiumCurrencyId}
                value={premiumAmount}
                readOnly
                hideLabel
                showOptional={false}
            />
        );
    };

   
    handleOnClick = async (id) => {
        this.setState({
            policyLandingLoader: true
        });

        const isPolicyNumberVisible = id !== 'quotes';

        this.setState({ policyNumberVisible: isPolicyNumberVisible });
        this.setState(
            {
                currentView: id,
                policyLandingLoader: false,
                exceptions: []
            }
        );
    };

    getCell = (item, index, { id: property }) => {
        const translator = this.context;
        let toolTipMessage = '';

        switch (property) {
            case 'displayStatus':
                toolTipMessage = translator(messages.status);
                break;
            case 'primaryInsuredName':
                toolTipMessage = translator(messages.namedInsured);
                break;
            case 'premium':
                toolTipMessage = translator(messages.premium);
                break;
            case 'created':
                toolTipMessage = translator(messages.created);
                break;
            case 'jobStatus':
                toolTipMessage = translator(messages.jobStatus);
                break;
            case 'effectiveDate':
                toolTipMessage = translator(messages.effectiveDate);
                break;
            case 'expirationDate':
                toolTipMessage = translator(messages.expirationDate);
                break;
            default:
                toolTipMessage = '';
        }

        return (
            <span title={toolTipMessage}>
                {item[property]}
            </span>
        );
    };

    getProductImage = (item) => (
            <div className={styles.iconClass}>
                <E1PProductImageComponent
                    productCode={item.productCode}
                />
            </div>
        );

    getLink = (item, index, { id: property }) => {
        const { currentView } = this.state;
        const translator = this.context;
        let toolTipMessage = '';
        let redirectRoute = '';

        switch (property) {
            case 'policyNumber':
                toolTipMessage = translator(messages.policyNumber);
                redirectRoute = 'policies';
                break;
            case 'jobNumber':
                toolTipMessage = translator(messages.jobNumber);
                redirectRoute = currentView;
                break;
            default:
                toolTipMessage = '';
        }

        return (
            <FieldLinkComponent
                id={`policy${item[property]}`}
                to={`/${redirectRoute}/${item[property]}/summary`}
                className={styles.removeLinkStyle}
                title={translator(toolTipMessage)}
                value={item[property]}
            />
        );
    };

    getAccountNumberLink = (item, index, { id: property }) => {
        const { accountHolderName, accountNumber } = item;
        const translator = this.context;
        let toolTipMessage = translator(messages.account);
        const redirectRoute = 'accounts';
        let valueToshow = accountHolderName;

        switch (property) {
            case 'account':
            case 'accountNumber':
                valueToshow = accountHolderName;
                toolTipMessage = translator(messages.account);
                break;
            case 'policyAccountNumber':
            case 'transactionAccountNumber':
                valueToshow = accountNumber;
                toolTipMessage = translator(messages.accountNumber);
                break;
            default:
                toolTipMessage = '';
        }

        return (
            <FieldLinkComponent
                id={`account${accountNumber}`}
                to={`/${redirectRoute}/${accountNumber}/summary`}
                className={styles.removeLinkStyle}
                title={translator(toolTipMessage)}
                value={valueToshow}
            />
        );
    };

    getViewedInfo = () => {
        const { currentView } = this.state;
        const translator = this.context;

        return translator(tableHeadings[currentView]);
    };

    processPolicyResponseData = (policyData) => {
        const policyItems = [];
        const { intl } = this.props;

        policyData.forEach((policyDetails) => {
            const premiumAmount = this.getFormattedCurrency(
                policyDetails.premium, policyDetails.policyNumber
            );
            const policy = {
                productCode: policyDetails.product.productCode,
                insuredName: policyDetails.primaryInsuredName,
                displayStatus: policyDetails.displayStatus,
                accountNumber: policyDetails.accountNumber,
                policyNumber: policyDetails.policyNumber,
                account: policyDetails.accountNumber,
                accountHolderName: policyDetails.accountHolderName,
                primaryInsuredName: policyDetails.primaryInsuredName,
                premium: premiumAmount,
                effectiveDate: intl.formatDate(new Date(policyDetails.effective), {
                    year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC'
                }),
                expirationDate: intl.formatDate(new Date(policyDetails.expiration), {
                    year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC'
                })
            };

            policyItems.push(policy);
        });

        return policyItems;
    }

    loadRecentlyViewedPoliciesData = async (paginationParams) => {
        const { authHeader } = this.props;

        return GatewayPolicyService.getRecentlyViewedPolicies(paginationParams, authHeader)
            .then((response) => {
                if(response.exceptions_Ext && !_.isEmpty(response.exceptions_Ext)) {
                    this.setState({
                        exceptions: response.exceptions_Ext
                    },
                    () => ({ rows: [], numberOfRows: 0 }));
                }

                const data = this.processPolicyResponseData(response.policySummaries);

                return {
                    rows: data,
                    numberOfRows: response.maxNumberOfResults
                };
            });
    }

    loadRecentlyIssuedPoliciesData = async (paginationParams) => {
        const { authHeader } = this.props;
        const numberOfDays= gatewayParamConfig.policiesCreatedInLastXDays;

        return GatewayPolicyService.getRecentlyIssuedPolicies(numberOfDays, paginationParams, authHeader)
            .then((response) => {
                if(response.exceptions_Ext && !_.isEmpty(response.exceptions_Ext)) {
                    this.setState({
                        exceptions: response.exceptions_Ext
                    },
                    () => ({ rows: [], numberOfRows: 0 }));
                }

                const data = this.processPolicyResponseData(response.policySummaries);

                return {
                    rows: data,
                    numberOfRows: response.maxNumberOfResults
                };
            });
    }

    getSortingFieldName = (fieldID) => {
        let sortingFieldName = '';

        switch(fieldID) {
            case 'effectiveDate':
                sortingFieldName = 'Effective';    
                break;
            case 'expirationDate':
                sortingFieldName = 'Expiration';
                break;
            case 'policyAccountNumber':
            case 'transactionAccountNumber':
                sortingFieldName = 'AccountNumber';
                break;
            case 'policyNumber':
                sortingFieldName = 'PolicyNumber';
                break;
            case 'created':
                sortingFieldName = 'CreateTime';
                break;
            case 'jobNumber':
                sortingFieldName = 'JobNumber';
                break;
            default:
                sortingFieldName = 'Effective';
        }

        return sortingFieldName;
    }

    loadPolicesData = ({ sorted, pageSize, page }) => {
        const { currentView } = this.state;
        const orderBy = this.getSortingFieldName(_.get(sorted, '0.id'));
        const startIndex = pageSize * page;
        const offsetEndMinusOne = startIndex + pageSize - 1;
        const paginationParams = {
            offsetStart: startIndex,
            offsetEnd: offsetEndMinusOne,
            orderBy,
            orderByDescending: _.get(sorted, '0.desc', true)
        };

        this.setState({
            exceptions: []
        });

        if (availableTilesForPoliciesView.includes(currentView)) {
            if(currentView === 'recentlyViewedTile') {
                return this.loadRecentlyViewedPoliciesData(paginationParams);
            }

            if (currentView === 'recentlyIssuedTile') {
                return this.loadRecentlyIssuedPoliciesData(paginationParams);
            }
        }

        return { rows: [], numberOfRows: 0 };
    }

    processTransactionResponseData = (transactionData) => {
        const transactionItems = [];
        const { intl } = this.props;

        if (_.isArray(transactionData)) {
            transactionData.forEach((quoteItems) => {
                const { amount } = quoteItems.totalPremium;
                const totalPremium = (amount !== 0) ? this.getFormattedCurrency(
                    quoteItems.totalPremium, quoteItems.policyNumber
                ) : '-';
                
                const transactionItem = {
                    productCode: quoteItems.product.productCode,
                    premium: totalPremium,
                    jobStatus: quoteItems.status,
                    jobNumber: quoteItems.jobNumber,
                    accountNumber: quoteItems.accountNumber,
                    accountHolderName: quoteItems.accountHolderName,
                    policyNumber: quoteItems.policyNumber,
                    account: quoteItems.accountNumber,
                    created: intl.formatDate(new Date(quoteItems.createTime), { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })
                };

                transactionItems.push(transactionItem);
            
            });
        }

        return transactionItems
    }

    fetchTransactionData = async (paginationParams) => {
        const { authHeader } = this.props;
        const { currentView } = this.state;

        this.setState({ exceptions: [] });

        let response = { jobSummaries: [], maxNumberOfResults: 0, exceptions_Ext: [] };

        switch(currentView) {
            case 'quotes':
                response =  await GatewayPolicyService.getOpenSubmissions(paginationParams, authHeader);
                break;
            case 'change':
                response = await GatewayPolicyService.getOpenPolicyChanges(paginationParams, authHeader);
                break;
            case 'cancellation':
                response = await GatewayPolicyService.getOpenCancellations(paginationParams, authHeader);
                break;
            case 'renewal':
                response = await GatewayPolicyService.getOpenRenewals(paginationParams, authHeader);
                break;
            default:
                response = { jobSummaries: [], maxNumberOfResults: 0, exceptions_Ext: [] };
        }

        if(response.exceptions_Ext && !_.isEmpty(response.exceptions_Ext)) {
            this.setState({
                exceptions: response.exceptions_Ext
            });
        }

        const data = this.processTransactionResponseData(response.jobSummaries);

        return {
            rows: data,
            numberOfRows: response.maxNumberOfResults
        };
    }

    loadTransactionsData = ({ sorted, pageSize, page }) => {
        const { currentView } = this.state;
        const orderBy = this.getSortingFieldName(_.get(sorted, '0.id'));
        const startIndex = pageSize * page;
        const offsetEndMinusOne = startIndex + pageSize - 1;
        const paginationParams = {
            offsetStart: startIndex,
            offsetEnd: offsetEndMinusOne,
            orderBy,
            orderByDescending: _.get(sorted, '0.desc', true)
        };
        const transactionData = { rows: [], numberOfRows: 0 };

        if (availableTilesForTransactionsView.includes(currentView)) {
            return this.fetchTransactionData(paginationParams);
        }

        return transactionData;
    }

    render() {
        const {
            toggleFilter,
            currentView,
            policyLandingLoader,
            policyNumberVisible,
            exceptions
        } = this.state;
        const isPolicyTableVisible = availableTilesForPoliciesView.includes(currentView);
        const isJobTableVisible = availableTilesForTransactionsView.includes(currentView);
        const tableKey = currentView;
        const overrides = {
            policyViewedInfo: {
                content: this.getViewedInfo()
            },
            policyLandingLoader: {
                loaded: !policyLandingLoader
            },
            policyContentContainer: {
                visible: !policyLandingLoader
            },
            tableContainer: {
                className: cx(styles.gwPoliciesContainerSection, {
                    [styles.gwPolicyInfoQuickTable]: toggleFilter,
                    [styles.gwPolicyInfoAdvancedTable]: !toggleFilter
                })
            },
            // table ID for recentlyViewed and recentlyIssued tiles
            recentlyViewedPoliciesTableGrid: {
                onFetchData: this.loadPolicesData,
                key: tableKey,
                visible: isPolicyTableVisible
            },
            openPolicyNumberTitle: {
                visible: policyNumberVisible
            },
            // table ID for transaction view tiles
            jobTable: {
                onFetchData: this.loadTransactionsData,
                key: tableKey,
                visible: isJobTableVisible
            },
            [currentView]: {
                active: true,
            },
            wizardSingleErrorComponent: {
                issuesList: exceptions
            }
        };

        const resolvers = {
            resolveComponentMap: {
                WizardSingleErrorComponent
            },
            resolveCallbackMap: {
                getCell: this.getCell,
                getProductImage: this.getProductImage,
                getLink: this.getLink,
                getAccountNumberLink: this.getAccountNumberLink,
                handleOnClick: this.handleOnClick
            },
            resolveClassNameMap: styles
        };

        const policyLandingPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;

        return policyLandingPage;
    }
}
export const PoliciesPage = Policies;
export default withIntl(withAuthenticationContext(Policies));
