import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import { ConsoleHelper } from 'e1p-portals-util-js';
import {
    get, isEmpty, filter, includes, set
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useModal } from '@jutro/components';
import { PackageDifferenceComponent, ProtectiveDevices, PackageQualifyingEndorsementsComponent } from 'e1p-capability-policyjob-react';
import { ehCommonMessages } from 'e1p-platform-translations';
import OptionalCoveragePopupComponent from '../../../components/OptionalCoveragesPopup/OptionalCoveragesPopup';
import metadata from '../CoveragesPage.metadata.json5';
import messages from '../CoveragesPage.messages';
import styles from '../CoveragesPage.module.scss';

const OPTIONAL_COVERAGE_CATEGORIES = [
    'EHLineAddGrp',
    'EH_OptionalProp',
    'EH_OptionalLiability',
    'EH_PersonalProtection',
    'EH_HomeBusiness',
    'EH_AdditionalProp',
    'EH_Home',
    'EH_PersonalBelongings',
    // 'EH_EndorsementPackage'
];

function ViewCoveragesPage(props) {
    const modalApi = useModal();
    const translator = useTranslator();
    const {
        wizardData: policyChangeVM, authHeader, updateWizardData,
        steps,
        jumpTo
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const [selectedOptionalCoverages, updateSelectedOptionalCovs] = useState([]);
    const [coveragesModified, updateCoveragesModified] = useState(false);
    const paperLessIndValue = get(policyChangeVM, 'lobData.homeowners_EH.paperlessInd.value') === undefined
        ? false : get(policyChangeVM, 'lobData.homeowners_EH.paperlessInd.value');
    const [hasProtectiveDevices, setHasProtectiveDevices] = useState(false);
    const [isQuoteStale, setIsQuoteStale] = useState(false);
    const modifiers = get(policyChangeVM, 'lobData.homeowners_EH.modifiers.value', []);
    const policyState = get(policyChangeVM, 'baseData.policyAddress.state.value.code');
    const {
        onValidate
    } = useValidation('CoveragesPage');
    
    const hasGoldOrPlatinumBoxVisible = !!(get(policyChangeVM, 'lobData.homeowners_EH.offerings.value[0].coverages.coverages', [])
        .find(({ codeIdentifier }) => includes(['EH_GoldCoverageBundle', 'EH_PlatinumCoverageBundle'], codeIdentifier)));

    const onNext = useCallback(() => policyChangeVM, [policyChangeVM]);

    const checkHasProtectiveDevice = (vm) => {
        if (get(vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value').length) {
            setHasProtectiveDevices(true);
        } else {
            setHasProtectiveDevices(false);
        }
    };

    useEffect(() => {
        checkHasProtectiveDevice(policyChangeVM);
        // set isQuoteStale, when status is draft
        setIsQuoteStale(get(policyChangeVM, 'value.status') === 'Draft');
    }, [policyChangeVM]);

    const showOptionalCoveragePopOver = async () => {
        const offerings = get(policyChangeVM, 'lobData.homeowners_EH.offerings.children[0]');
        const goldBundledCov = offerings.value.coverages.coverages.find((clause) => clause.codeIdentifier === 'EH_GoldCoverageBundle');
        const platinumBundledCov = offerings.value.coverages.coverages.find((clause) => clause.codeIdentifier === 'EH_PlatinumCoverageBundle');
        const componentProps = {
            title: translator(messages.optionalCoverageLabel),
            iconClassType: false,
            showCancelBtn: false,
            showSaveBtn: true,
            policyChangeVM,
            updateWizardData,
            viewModelService,
            authHeader,
            goldCovSelected: goldBundledCov ? goldBundledCov.selected : false,
            platinumCovSelected: platinumBundledCov ? platinumBundledCov.selected : false,
            translator,
            viewOnlyMode: true,
            hasGoldOrPlatinumBoxVisible,
            policyState
        };
        const result = await modalApi.showModal(<OptionalCoveragePopupComponent {...componentProps} />);

        return result;
    };

    const generateOverrides = useCallback(() => {
        const schedules = get(policyChangeVM, 'lobData.homeowners_EH.offerings.children[0].coverages.schedules.value', []);
        const overrides = {};

        if (!isEmpty(schedules)) {
            schedules.forEach((vehicle, i) => {
                overrides[`scheduleItem${i}`] = {
                    readOnly: true
                };
            });
        }

        return overrides;
    }, [policyChangeVM]);

    const showProtectiveDevicePopup = useCallback(async () => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: true,
            showCancelBtn: true,
            submissionVM: policyChangeVM,
            viewModelService,
            translator,
            viewOnlyMode: true
        };
        const result = await modalApi.showModal(<ProtectiveDevices {...componentProps} />);

        return result;
    }, [policyChangeVM, translator, viewModelService, modalApi]);

    const onShowProtectiveDevicesPopup = useCallback(
        () => {
            try {
                showProtectiveDevicePopup().then((wrapperObj) => {
                    set(
                        policyChangeVM,
                        'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value',
                        get(wrapperObj.vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value', [])
                    );
                    set(
                        policyChangeVM,
                        'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd.value',
                        get(wrapperObj.vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd.value')
                    );
                    updateWizardData(policyChangeVM);
                }).catch((error) => {
                    ConsoleHelper(`user closed the modal. Error: ${error}`);
                });
            } catch {
                ConsoleHelper('user closed the modal');
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [showProtectiveDevicePopup]
    );

    const policyType = get(policyChangeVM, 'value.policyType');
    const replacementCost = policyChangeVM.value.lobData.homeowners_EH
        .coverables.yourHome.valuation.estimatedReplacementCostAmount;

    const discountSubtotal = get(policyChangeVM, 'lobData.homeowners_EH.offerings.value[0].premiumSummary', [])
        .find((prem) => prem.premiumSummaryType === 'EHDiscountSavingsSubtotal')?.amount || 0;
    const primaryContentData = () => {
        if (!hasGoldOrPlatinumBoxVisible) {
            return translator(messages.optionalCoveragePackage);
        }

        return translator(messages.goldPlatinumPrimaryLabel);
    };
    const secondaryContentData = () => {
        if (!hasGoldOrPlatinumBoxVisible) {
            return (
                <React.Fragment>
                    {translator(messages.discountMessageSelect)}
                    <span className={styles.addIcon} />
                    {translator(messages.discountMessageLabel)}
                </React.Fragment>
            );
        }

        return translator(messages.goldPlatinumSecondaryLabel);
    };

    const getSelectedOptionalCov = () => {
        const newSubVM = viewModelService.clone(policyChangeVM);
        const tempSelectedOptionalCoverages = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).coverages.coverages.filter((coverage) => OPTIONAL_COVERAGE_CATEGORIES
                .indexOf(coverage.coverageCategoryCode.value) >= 0 && coverage.value.selected);
        const selectedExclusions = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).exclusions.filter((exclusion) => exclusion.selected.value === true);
        const selectedConditions = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).conditions.filter((condition) => condition.selected.value === true);

        const selectedOptionalCoveragesExclusionsAndConditions = [
            ...tempSelectedOptionalCoverages,
            ...selectedExclusions,
            ...selectedConditions
        ];

        return selectedOptionalCoveragesExclusionsAndConditions;
    };

    useEffect(() => {
        updateCoveragesModified(false);

        const optionalSelectedCoverages = getSelectedOptionalCov();

        updateSelectedOptionalCovs({ selectedOptionalCoverages: optionalSelectedCoverages });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coveragesModified]);

    const getTotalPremium = useCallback(() => {
        const payPlans = get(policyChangeVM.value, 'lobData.homeowners_EH.offerings[0].paymentPlans');
        const selectedPlan = payPlans.filter((plan) => plan.isSelected);
        const totalPremium = selectedPlan[0]?.total?.amount;

        if (!totalPremium && totalPremium !== 0) {
            return undefined;
        }

        return { currency: 'usd', amount: totalPremium };
    }, [policyChangeVM]);

    const showWhatsIncludedPopup = async () => {
        const offerings = get(policyChangeVM, 'lobData.homeowners_EH.offerings.children[0]');

        const platinumOrGoldClauseIndex = offerings.value.coverages.coverages.findIndex(
            (clause) => clause.codeIdentifier === 'EH_PlatinumCoverageBundle' || clause.codeIdentifier === 'EH_GoldCoverageBundle'
        );

        if (platinumOrGoldClauseIndex === -1) {
            const componentProps = {
                coverages: offerings.coverages.coverages.value
            };
            const result = await modalApi.showModal(
                <PackageQualifyingEndorsementsComponent {...componentProps} />
            );

            return result;
        }

        const componentProps = {
            lob: 'Homeowners_EH'
        };
        // IAP-83: Gold and Platinum will continue to use existing component
        const result = await modalApi.showModal(
            <PackageDifferenceComponent {...componentProps} />
        );

        return result;
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            readOnly: true
        },
        coveragesPageLoadingIndicator: {
            loaded: true
        },
        coveragesPageContainer: {
            visible: true
        },
        OptionalCovTopGrid: {
            visible: policyType !== 'HO3' && policyType !== 'HF9',
        },
        paperlessInd: {
            value: get(policyChangeVM, 'lobData.homeowners_EH.paperlessInd.value'),
            labelPosition: 'top'
        },
        isProtectiveDevicesMainGrid: {
            visible: policyType !== 'HO4',
        },
        isProtectiveDevicesIcon: {
            onClick: onShowProtectiveDevicesPopup,
            visible: hasProtectiveDevices,
            icon: 'mi-visibility'
        },
        isProtectiveDevices: {
            value: hasProtectiveDevices,
            onValueChange: () => { },
            labelPosition: 'top'
        },
        tpiOfTrust: {
            visible: policyType === 'HO3' && policyState === 'NC',
            labelPosition: 'top'
        },
        paperlessEmailId: {
            visible: paperLessIndValue,
            labelPosition: 'top'
        },
        wizardPageHeader: { wizardSubmission: policyChangeVM },
        ehPolicyChangeBaseCoveragesId: {
            loadingClause: false,
            categoryDisplayName: translator(messages.coverages),
            isEditable: false
        },
        scheduleItemContainer: {
            loadingClause: false,
            categoryDisplayName: translator(messages.schedules)
        },
        isThisNewHomePurchase: {
            labelPosition: 'top',
            visible: (policyType === 'HO3' || policyType === 'HF9') && policyState !== 'MT'
        },
        GoldPlatinumGrid: {
            visible: policyType === 'HO3' || policyType === 'HF9'
        },
        goldPlatinumPrimaryHeading: {
            visible: policyType === 'HO3' || policyType === 'HF9',
            content: primaryContentData()
        },
        goldPlatinumSecondaryHeading: {
            visible: policyType === 'HO3' || policyType === 'HF9',
            content: secondaryContentData()
        },
        sectionICoverages: {
            loadingClause: undefined,
            uiGroup: 'Section I',
            hideLabel: true,
            labelPosition: 'top',
            viewOnlyMode: true,
            policyType,
            replacementCost,
            steps,
            jumpTo
        },
        sectionIICoverages: {
            loadingClause: undefined,
            uiGroup: 'Section II',
            hideLabel: true,
            labelPosition: 'top',
            viewOnlyMode: true
        },
        deductibles: {
            loadingClause: undefined,
            uiGroup: 'Deductibles',
            labelPosition: 'top',
            viewOnlyMode: true
        },
        supportingPolicyComponentContainer: {
            transactionVM: policyChangeVM,
            LOB: 'homeowners_EH',
            updateWizardData,
            viewOnly: true
        },
        addEditOptionalCovDiv: {
            visible: true
        },
        addOrEditOptionalCovButton: {
            onClick: () => showOptionalCoveragePopOver(),
            content: translator(ehCommonMessages.viewOptionalCoverages),
            icon: 'mi-visibility',
            visible: getSelectedOptionalCov().length > 0
        },
        seeOptionalCovButton: {
            onClick: () => showOptionalCoveragePopOver(),
            content: translator(ehCommonMessages.viewOptionalCoverages),
            icon: 'mi-visibility'
        },
        ehSurchargeListComponentId: {
            value: modifiers.filter(
                (item) => item.applied && item.modifierType !== "discount"
            )
        },
        ehDiscountsListComponentId: {
            visible: !!filter(modifiers, { applied: true, modifierType: 'discount' }).length,
            value: modifiers,
            discountsTotal: discountSubtotal
        },
        protectiveDeviceRequireInfoMessageBasedOnCovADiv: {
            visible: false
        },
        optionalCoverageSubtotalValue: {
            value: {
                currency: 'usd', amount: (() => {
                    // Optional coverage subtotal amount
                    const scheduleList = get(policyChangeVM, 'lobData.homeowners_EH.offerings.value[0].coverages.schedules', []);
                    let optionalCoverageSubtotalAmount = get(get(policyChangeVM, 'lobData.homeowners_EH.offerings.value[0].premiumSummary', [])
                        .find((prem) => prem.premiumSummaryType === 'EHOptionalCvgSubtotal'), 'amount', 0);

                    // Add schedule coverage total amount to optional coverage total
                    optionalCoverageSubtotalAmount += get(scheduleList.find((schedule) => get(schedule, 'premiumSummary.premiumSummaryType') === 'TotalScheduleCoverage'), 'premiumSummary.amount', 0);

                    return optionalCoverageSubtotalAmount;
                })()
            }
        },
        windstormMitigationDiscountComponentContainer: {
            visible: (policyType === 'HO3' && includes(['NC', 'SC', 'CT'], policyState) || (policyType === 'HF9' && policyState === 'CT'))
        },
        windstormMitigationDiscountComponent: {
            transactionVM: policyChangeVM,
            viewOnly: true,
            updateWizardData,
            setFieldsChangedOnCoveragePage: () => { },
            policyState
        },
        windstormMitigationDiscountInfoMessageDiv: {
            visible: false
        },
        selectedOptionalCoverageDiv: {
            visible: getSelectedOptionalCov().length > 0
        },
        selectedOptionalCoverageLabel: {
            content: getSelectedOptionalCov().length > 0 ? translator(ehCommonMessages.selectedOptionalCovLabel) : translator(ehCommonMessages.emptyOptionalCovLabel)
        },
        paymentOptionsID: {
            submissionVM: policyChangeVM,
            authHeader,
            updateWizardData,
            LOB: 'homeowners_EH',
            viewOnly: true,
            isQuoteStale: false
        },
        monthlyPaymentScheduleComponent: {
            quoteID: get(policyChangeVM, 'jobID.value'),
            authHeader,
            transactionTotalAmount: getTotalPremium(),
            changeInCost: policyChangeVM.transactionCost?.value,
            startDate: policyChangeVM.baseData.periodStartDate.value,
            endDate: policyChangeVM.baseData.periodEndDate.value,
            jobTypeCode: policyChangeVM.baseData.jobType.value.code,
            offerings: get(policyChangeVM, 'lobData.homeowners_EH.offerings.value'),
            viewOnlyMode: true
        },
        quoteProposalLinkContainer: {
            visible: false
        },
        policyTermComponent: {
            transactionVM: policyChangeVM,
            authHeader,
            updateWizardData,
            viewOnly: true
        },
        buyNowButton: {
            visible: false
        },
        recalculateButton: {
            visible: false
        },
        totalPremiumID: {
            value: (() => getTotalPremium())()
        },
        paperlessEmailChangedMessageDiv:{
            visible: false
        },
        ncrbDiscountAvailabilityMessageDiv: {
            visible: false
        },
        ...generateOverrides()
    };


    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeClause: () => { },
            onSyncCoverages: () => { },
            onChangeSubmissionAndSync: () => { },
            onScheduleChange: () => { },
            onValidate,
            whatsIncludedHandler: showWhatsIncludedPopup
        },
    };

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{ ...selectedOptionalCoverages, ...policyChangeVM }}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

ViewCoveragesPage.propTypes = wizardProps;

export default withAuthenticationContext(ViewCoveragesPage);
