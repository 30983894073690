import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class VehicleHistoryService {
    static getVehicleHistoryRecords(data, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('vehiclehistory'),
            'getVehicleHistoryRecords',
            [data],
            additionalHeaders
        );
    }
}
