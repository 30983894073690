import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { E1PProductImageComponent, E1PFieldLinkComponent } from 'e1p-capability-policyjob-react';
import metadata from './PoliciesTableComponent.metadata.json5';
import styles from './PoliciesTableComponent.module.scss';
import messages from './PoliciesTableComponent.messages';

/**
 * @param {*} props 
 * IAP-3238 and IAP-3363, as part of these ticket, refactored this component,
 * so that Account APIs, should not be called unless props are changed,
 * 
 * Issue: This component is re-rendering whenever the parent component is re-rendered, even with same props.
 * 
 * Why this change? :
 * we are refactoring this componet  because it is having dataTable with async fetch call,
 * and as soon as this renders async call is getting fired (even though all the Props are same during re-render).
 * So we will control the re-rendering of this component only when the props changes.
 * This change will increase the performance of the component.
 * 
 * you can read more here
 * https://reactjs.org/docs/react-api.html#reactmemo
 * 
 */

const PoliciesTableComponent = (props) => {
    const {
        dataTableKey,
        dataTableConfig,
        onFetchData,
        isAgent,
        quoteNumberVisible,
        currentView
    } = props;
    
    const translator = useTranslator();

    const getAccountLink = useCallback((item) => (
            <E1PFieldLinkComponent
                id={`account${item.accountNumber}`}
                value={item.accountHolder}
                to={`/accounts/${item.accountNumber}/summary`}
            />
        ), []);

    const getCell = useCallback((item, index, { id: property }) => {
        let toolTipMessage = '';

        switch (property) {
            case 'displayStatus':
                toolTipMessage = translator(messages.status);
                break;
            case 'primaryInsuredName':
                toolTipMessage = translator(messages.accountName);
                break;
            case 'premium':
                toolTipMessage = translator(messages.premium);
                break;
            case 'created':
                toolTipMessage = translator(messages.dateCreated);
                break;
            case 'jobStatus':
                toolTipMessage = translator(messages.status);
                break;
            case 'effectiveDate':
                toolTipMessage = translator(messages.effectiveDate);
                break;
            default:
                toolTipMessage = '';
        }

        return (
            <span title={toolTipMessage}>
                {item[property]}
            </span>
        );
    }, [translator]);

    const getProductImage = useCallback((item) => (
            <div className={styles.iconClass}>
                <E1PProductImageComponent
                    productCode={item.productCode}
                />
            </div>
        ), []);

    const getLink = useCallback((item, index, { id: property }) => {
        let toolTipMessage = '';
        let redirectRoute = '';

        switch (property) {
            case 'policyNumber':
                toolTipMessage = translator(messages.policyNumber);
                redirectRoute = 'policies';
                break;
            case 'jobNumber':
                toolTipMessage = translator(messages.quoteNumber);

                if (currentView === 'rewrite') {
                    redirectRoute = 'rewrite/view'
                }
                else {
                    redirectRoute = currentView;
                }

                break;
            default:
                toolTipMessage = '';
        }

        return (
            <E1PFieldLinkComponent
                id={`policy${item[property]}`}
                to={`/${redirectRoute}/${item[property]}/summary`}
                className={styles.removeLinkStyle}
                title={translator(toolTipMessage)}
                value={item[property]}
            />
        );
    }, [currentView, translator]);
    
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getAccountLink,
            getCell,
            getLink,
            getProductImage
        }
    };
    const overrideProps = {
        resultsTableGrid: {
            onFetchData,
            key: dataTableKey,
            defaultConfig: dataTableConfig
        },
        addressTitle: {
            visible: isAgent
        },
        riskStateTitle: {
            visible: !isAgent
        },
        locationCodeTitle: {
            visible: !isAgent
        },
        quoteNumberTitle: {
            visible: quoteNumberVisible
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
};

PoliciesTableComponent.propTypes = {
    dataTableKey: PropTypes.string.isRequired,
    dataTableConfig: PropTypes.shape({}).isRequired,
    onFetchData: PropTypes.func.isRequired
};

// this function will determine when to render this component.
function areEqual(prevProps, nextProps) {
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
    const arePropsEqual = prevProps.dataTableKey === nextProps.dataTableKey
    && prevProps.currentView === nextProps.currentView
    && prevProps.quoteNumberVisible === nextProps.quoteNumberVisible
    && _.isEqual(prevProps.dataTableConfig, nextProps.dataTableConfig);

    return arePropsEqual;
}

// read the component description why we are using React.memo
export default React.memo(PoliciesTableComponent, areEqual);
