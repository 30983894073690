import React, {
    useCallback, useContext, useEffect, useState, useRef
} from 'react';
import { get as _get, isEmpty as _isEmpty } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useOOSConflictPageLandingUtil, useTPIUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './ThirdPartyInterestPage.metadata.json5';

function ThirdPartyInterestPage(props) {
    const {
        wizardData: rewriteVM,
        updateWizardData,
        isSkipping,
        steps,
        currentStepIndex,
        clearVisitedStepsAfterCurrent,
        e1pGoNext,
        jumpTo,
        changeNextSteps,
        updateWizardSnapshot
    } = props;

    const stepsRef = useRef(steps);
    const { authHeader } = useAuthentication();
    const {
        isComponentValid,
        onValidate,
        disregardFieldValidation
    } = useValidation('ThirdPartyInterestPage');
    const [isSavingRewrite, setIsSavingRewrite] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isSavingTPI, setIsSavingTPI] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const { checkAndShowDialogForPresenceOfTrust } = useTPIUtil();
    const [isAddingTPI, setIsAddingTPI] = useState(false);
    const viewOnlyMode = false;

    useEffect(() => {
        /**
         * Using useRef to access current updated steps.
         * as we are adding new conflicts step and landing user on this newly created step
         * "step" state variable from props does not give us updated value inside useOOSConflictPageLandingUtil
         * it refers initial rendered value only(as we are adding new step and want to land user
         * on new step in)
         */
        stepsRef.current = steps;
    }, [steps]);

    const {
        removeOrAddAndLandOnConflictsPage
    } = useOOSConflictPageLandingUtil(
        stepsRef,
        currentStepIndex,
        changeNextSteps,
        jumpTo
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted]);

    /**
     * Helper effect for clearing the visited steps after this page. This ensures that the "onNext"
     * callback is executed in each situation where we may have made changes to TPIs. This effect
     * will also skip the TPI Page if the Payment Details Page hasn't been visited yet.
     */
    useEffect(() => {
        if (!isSkipping && steps[currentStepIndex].visited) {
            clearVisitedStepsAfterCurrent();
        } else if (!steps[currentStepIndex].visited) {
            e1pGoNext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for saving TPIs if changes were made while visiting this screen.
     */
    const saveTPIChangesIfNecessary = useCallback(async () => {
        // Save and quote again since something is changed on the TPI
        const response = await RewriteService.saveAndQuote(
            [rewriteVM.value],
            authHeader
        );

        rewriteVM.value = response;

        return rewriteVM.value;
    }, [rewriteVM, authHeader]);

    /**
     * Helper callback for handling wizard page navigation after the "Next" button is pressed.
     */
    const onNext = useCallback(async (_, calledFromOnSave = false) => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        const popResult = await checkAndShowDialogForPresenceOfTrust(rewriteVM, updateWizardData);

        if (!popResult) {
            // user clicked cancel on presence of trust confirmation dialog
            return false;
        }

        setIsSavingRewrite(true);
        rewriteVM.value = await saveTPIChangesIfNecessary();
        setIsSavingRewrite(false);
        updateWizardData(rewriteVM);

        if (!calledFromOnSave) {
            // E1PAP1PC-13853 :
            // If we get conflicts in saveAndQuote, we will add conflicts page if its not present
            // and user will land on conflicts page
            // If we had conflicts and we came back and made changes such that after saveAndQuote
            // if there are no conflicts we will remove conflicts page if its present
            const hasConflicts = !_isEmpty(_get(rewriteVM, 'value.conflicts', []));

            removeOrAddAndLandOnConflictsPage(hasConflicts);

            if (hasConflicts) {
                setIsSavingRewrite(false);

                return false;
            }
        }

        return rewriteVM;
    }, [
        isComponentValid, checkAndShowDialogForPresenceOfTrust, rewriteVM,
        updateWizardData, saveTPIChangesIfNecessary, removeOrAddAndLandOnConflictsPage]
    );

    const onSave = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = _get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [isComponentValid, onNext, rewriteVM, updateWizardSnapshot]
    );


    const getLoadingMessage = useCallback(() => {
        let loadingMessage = translator(e1pCommonMessages.loadingNextPage);

        if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isSavingTPI) {
            loadingMessage = translator(e1pCommonMessages.savingTPI);
        }

        return loadingMessage;
    }, [isSavingCurrentPageChanges, isSavingTPI, translator]);


    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        thirdPartyInterestPageLoadingIndicator: {
            loaded: !isSavingRewrite && !isSkipping
                && !isSavingTPI && !isSavingCurrentPageChanges,
            text: getLoadingMessage()
        },
        thirdPartyInterestPageContainer: {
            visible: !isSavingRewrite && !isSkipping
                && !isSavingTPI && !isSavingCurrentPageChanges
        },
        e1ptpiDisplayTableContainer: {
            transactionVM: rewriteVM,
            viewModelService,
            authHeader,
            updateWizardData,
            setIsSavingTPI,
            showErrors: isPageSubmitted,
            onValidate,
            disregardFieldValidationParentPage: disregardFieldValidation,
            setIsAddingTPI
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, rewriteVM, id, path, overrideProps),
        [rewriteVM, overrideProps]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            onSave={onSave}
            showCancel={!isAddingTPI}
            showNext={!isAddingTPI}
            showOnSave={!isAddingTPI}
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                overrideProps={overrideProps}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

ThirdPartyInterestPage.propTypes = wizardProps;
export default ThirdPartyInterestPage;
