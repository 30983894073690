import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './E1PTransactionActionsComponent.metadata.json5';
import styles from './E1PTransactionActionsComponent.module.scss';
import { Button } from '@jutro/legacy/components';

const ActionMessageButton = (props) => {
    const translator = useTranslator();
    const {
        id, value, onClick, type, className
    } = props;

    return (
        <Button id={id} className={className} type={type} onClick={onClick}>
            {translator(value)}
        </Button>
    );
};

ActionMessageButton.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired
};

const E1PTransactionActionsComponent = (props) => {
    const {
        onContinueTransaction,
        onWithdrawTransaction,
        onViewTransaction,
        transactionActionContent,
        transactionVisibleActions
    } = props;
    const overrides = {
        continueTransaction: {
            visible: _.get(transactionVisibleActions, 'isContinueTransaction') || false
        },
        withdrawTransaction: {
            visible: _.get(transactionVisibleActions, 'isWithdrawTransaction') || false
        },
        viewTransaction: {
            visible: _.get(transactionVisibleActions, 'isViewTransaction') || false
        },
        transactionActionContainer: {
            visible:
                _.get(transactionVisibleActions, 'isContinueTransaction')
                || _.get(transactionVisibleActions, 'isWithdrawTransaction')
                || _.get(transactionVisibleActions, 'isViewTransaction')
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleWithdrawTransaction: onWithdrawTransaction,
            handleContinueTransaction: onContinueTransaction,
            handleViewTransaction: onViewTransaction
        },
        resolveComponentMap: {
            actionmessagecomponent: ActionMessageButton
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionActionContent}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

E1PTransactionActionsComponent.propTypes = {
    transactionActionContent: PropTypes.shape({}).isRequired,
    onContinueTransaction: PropTypes.func.isRequired,
    onWithdrawTransaction: PropTypes.func.isRequired,
    onViewTransaction: PropTypes.func.isRequired,
    transactionVisibleActions: PropTypes.shape({
        isContinueTransaction: PropTypes.bool.isRequired,
        isWithdrawTransaction: PropTypes.bool.isRequired,
        isViewTransaction: PropTypes.bool.isRequired
    }).isRequired
};

export default E1PTransactionActionsComponent;
