import React, { useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    get as _get
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { usePriorCarrierUtil } from 'e1p-capability-hooks';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import metadata from './PniPriorPolicyGridComponent.metadata.json5';
import styles from './PniPriorPolicyGridComponent.module.scss';
import messages from './PniPriorPolicyGridComponent.messages';

/**
 * @purpose PniPriorPolicyGridComponent is used to show only prefill prior policies
 * for PNI, integrationID is used to filter the PNI policies from whole prior Policies Array
 *
 * For manual addition of prior policies, PriorPolicyManualGridComponent is being used.
 *
 * ParentComponent: EAPriorCarrierComponent
 * @param {Object} props
 * @returns {JSX}
 */
function PniPriorPolicyGridComponent(props) {
    const {
        data: priorPoliciesVMList,
        primaryNamedInsured,
        labelPosition,
        path,
        id,
        onValidate,
        onValueChange,
        disregardFieldValidation,
        viewOnlyMode,
        pniCoverageLapse,
        hasPNILapsed,
        policyStartDate,
        setPriorCarrierChanged,
        authUserData,
        priorPolicyUpdatesVM,
        policyState,
        coverageInforceIndChange
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation,
    } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        removePriorPolicy,
        getLatestPriorPolicy,
        createPriorPolicyVM
    } = usePriorCarrierUtil(
        priorPoliciesVMList,
        viewModelService,
        onValueChange,
        path,
        disregardFieldValidation,
        messages
    );

    const hasViewRiskCoverageLapsePermission = authUserData?.permissions_Ext.includes('viewriskcoveragelapse');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;

        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, path]);

    const generateOverrides = useCallback(
        () => {
            const overrideProps = {};
            const integrationId = _get(primaryNamedInsured, 'integrationId.value');
            // Find latest pni prior policy from prefill
            const latestPniPolicyFromPrefill = getLatestPriorPolicy(integrationId);

            priorPoliciesVMList.value.forEach((priorPolicy, index) => {
                /**
                 * SNI and PNI are using the same list of prior policies
                 * But visually we are seperating into two sections
                 * based on the contact ID so we will only
                 * show the PNI prior policies in this GRID
                 */
                overrideProps[`primaryNamedPriorPolicyID${index}`] = {
                    visible: priorPolicy.integrationId === integrationId && _get(
                        priorPolicy, 'policySource'
                    ) === 'Prefill',
                    viewOnlyMode,
                    policyStartDate,
                    checkLapsed: hasPNILapsed,
                    priorPolicyUpdatesVM,
                    setPriorCarrierChanged,
                    isLatestPolicy: latestPniPolicyFromPrefill === priorPolicy,
                    policyState,
                    handleDispute: (value) => {
                        if (value) {
                            createPriorPolicyVM(integrationId);
                            setPriorCarrierChanged(true);
                        } else {
                            coverageInforceIndChange(value);
                        }
                    }
                };
                overrideProps[`primaryNamedPriorPolicyContainer${index}`] = {
                    visible: priorPolicy.integrationId === integrationId && _get(
                        priorPolicy, 'policySource'
                    ) === 'Prefill',
                };
                // Prior carriers from prefill cannot be deleted. All prior policies that are manual
                //   should be going to PniManualPriorPolicyGridComponent anyway so there
                //   shouldn't be a delete button in this file at all but keeping it here to
                //   be safe, for now.
                //   E1PAP1PC-10981
                overrideProps[`primaryNamedEaDeletePriorPolicy${index}`] = {
                    visible: priorPolicy.integrationId === integrationId && !viewOnlyMode && _get(
                        priorPolicy, 'policySource'
                    ) === 'ManuallyAdded',
                    onClick: (evt) => {
                        removePriorPolicy(evt);
                        setPriorCarrierChanged(true);
                    }
                };
            });

            return overrideProps;
        },
        [primaryNamedInsured, getLatestPriorPolicy, priorPoliciesVMList.value, viewOnlyMode, policyStartDate, hasPNILapsed,
            priorPolicyUpdatesVM, setPriorCarrierChanged, policyState, createPriorPolicyVM, coverageInforceIndChange, removePriorPolicy],
    );

    const isVendorProvidedEmpty = () => {
        const integrationId = _get(primaryNamedInsured, 'integrationId.value');

        return !priorPoliciesVMList.children.some((priorPolicy) => (priorPolicy.integrationId.value === integrationId
            && priorPolicy.policySource.value.code === 'Prefill'));
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition,
            showRequired: true,
            autoComplete: false
        },
        pniDisplayNameLabel: {
            value: _get(primaryNamedInsured, 'person.displayName.value'),
            readOnly: true
        },
        primaryNamedModifyPriorCarrierContainer: {
            visible: !viewOnlyMode,
            disabled: viewOnlyMode,
        },
        primaryNamedCoverageLapseReason: {
            visible: pniCoverageLapse || hasViewRiskCoverageLapsePermission,
            readOnly: viewOnlyMode,
        },
        primaryNamedPriorPolicyLabelContainer: {
            visible: !isVendorProvidedEmpty()
        },
        ...generateOverrides()
    };

    const readValue = useCallback((fieldId, fieldPath) => readViewModelValue(
        metadata.pageContent, priorPoliciesVMList, fieldId, fieldPath, overrideProps
    ), [overrideProps, priorPoliciesVMList]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRemovePriorPolicy: removePriorPolicy,
            onValidate,
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={priorPoliciesVMList}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

PniPriorPolicyGridComponent.propTypes = {
    data: PropTypes.shape({}),
    primaryNamedInsured: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    policyStartDate: PropTypes.shape({}),
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string,
    disregardFieldValidation: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    pniCoverageLapse: PropTypes.bool.isRequired,
    hasPNILapsed: PropTypes.func.isRequired,
    setPriorCarrierChanged: PropTypes.func,
    authUserData: PropTypes.shape({}),
    priorPolicyUpdatesVM: PropTypes.shape({}),
    policyState: PropTypes.string.isRequired
};
PniPriorPolicyGridComponent.defaultProps = {
    data: {},
    policyStartDate: {},
    primaryNamedInsured: {},
    labelPosition: 'top',
    path: undefined,
    id: undefined,
    viewOnlyMode: false,
    authUserData: undefined,
    priorPolicyUpdatesVM: {},
    setPriorCarrierChanged: undefined
};
export default withAuthenticationContext(PniPriorPolicyGridComponent);
