import React, { useCallback, useState, useEffect } from 'react';
import { Chevron, ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { get, startCase } from 'lodash';
import { Grid } from '@jutro/layout';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { commonMessages } from 'e1p-platform-translations';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { PolicyDiffService } from 'e1p-capability-policyjob';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import messages from './TransactionComparisonModalComponent.messages';
import styles from './TransactionComparisonModalComponent.module.scss';

import { Button, Accordion, AccordionCard } from '@jutro/legacy/components';

function TransactionComparisonModalComponent(props) {
    const {
        isOpen,
        authHeader,
        onResolve,
        selectedJobIdTypes
    } = props;
    const translator = useTranslator();
    const COLUMN_CONFIGURATION = ['auto', '20rem', '20rem'];
    const [isLoadingCompareRecords, setIsLoadingCompareRecords] = useState(true);
    const [compareRecords, setCompareRecords] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        const getComparisonData = async () => {
            setIsLoadingCompareRecords(true);

            try {
                const response = await PolicyDiffService
                    .compareRecords(
                        get(selectedJobIdTypes, [0, 'jobId']),
                        get(selectedJobIdTypes, [1, 'jobId']),
                        authHeader
                    );

                setCompareRecords(response);
            } catch {
                setValidationErrors([
                    {
                        level: ErrorLevel.LEVEL_ERROR,
                        description: commonMessages.genericErrorText
                    }
                ]);
            } finally {
                setIsLoadingCompareRecords(false);
            }
        };

        getComparisonData();
    // on mount load job comparison data
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onBackToPolicyClick = useCallback(
        () => {
            onResolve();
        }, [onResolve]
    );

    const renderAccordionHeader = (isAccordionOpen, accordionTitle) => (
            <React.Fragment>
                <Chevron isOpen={isAccordionOpen} align="left" className={styles.chevronStyle} />
                <Grid
                    columns={COLUMN_CONFIGURATION}
                    gap="xlarge"
                    className={styles.gridMargin}
                >
                    <span className={styles.rootLabel}>{accordionTitle.label}</span>
                    <span className={styles.gridColumn}>{accordionTitle.value1}</span>
                    <span className={styles.gridColumn}>{accordionTitle.value2}</span>
                </Grid>
            </React.Fragment>
        );

    const generateQuoteGrid = (currentNode) => (
            <Grid columns={COLUMN_CONFIGURATION} gap="xlarge" className={styles.gridMargin}>
                <span>
                    {currentNode.label}
                </span>
                <span className={styles.gridColumn}>
                    {currentNode.value1}
                </span>
                <span className={styles.gridColumn}>
                    {currentNode.value2}
                </span>
            </Grid>
        );

    const generateQuoteTable = (currentNodes) =>
        currentNodes.map(currentNode => {
            if (currentNode.isLeaf) {
                return generateQuoteGrid(currentNode);
            }

            return (
                <Accordion
                    id={`quote${currentNode.label}`}
                    closeOthers={false}
                    showFrame={false}
                    boldFont={false}
                    chevronAlignment="left"
                    defaultOpenedId={`quoteCard${currentNode.label}`}
                >
                    <AccordionCard
                        id={`quoteCard${currentNode.label}`}
                        cardBodyClassName={styles.cardBodyMargins}
                        header={isAccordionOpen =>
                            renderAccordionHeader(isAccordionOpen, currentNode)
                        }
                    >
                        {generateQuoteTable(currentNode.children)}
                    </AccordionCard>
                </Accordion>
            );
        });

    const renderQuoteTable = () => (
            <Accordion
                id="quote-transaction-comparison"
                closeOthers={false}
                showFrame={false}
                chevronAlignment="right"
                defaultOpenedId="quote-card-transaction-comparison"
            >
                <AccordionCard cardBodyClassName={styles.cardBodyMain} id="quote-card-transaction-comparison">
                    <Grid
                        columns={COLUMN_CONFIGURATION}
                        gap="xlarge"
                    >
                        <div className={styles.gridTitle}>
                            {translator(messages.quoteChangeItem)}
                        </div>
                        <div className={styles.gridTitle}>
                            {`${startCase(get(selectedJobIdTypes, [0, 'jobType'], ''))}:  ${get(selectedJobIdTypes, [0, 'jobId'])}`}
                        </div>
                        <div className={styles.gridTitle}>
                            {`${startCase(get(selectedJobIdTypes, [1, 'jobType']), '')}:  ${get(selectedJobIdTypes, [1, 'jobId'])}`}
                        </div>
                    </Grid>
                    {generateQuoteTable(compareRecords)}
                </AccordionCard>
            </Accordion>
        );

    return (
        <ModalNext isOpen={isOpen} className={styles.transactionComparisonModal}>
            <ModalHeader title={translator(messages.transactionComparisonHeader)} />
            <ModalBody>
                <E1PLoader
                    loaded={!isLoadingCompareRecords}
                />
                <WizardSingleErrorComponent issuesList={validationErrors} />
                {
                    !isLoadingCompareRecords
                    && renderQuoteTable()
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={onBackToPolicyClick}
                    styles={{ float: 'left' }}
                >
                    {translator(messages.backToPolicyHistoryButton)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

TransactionComparisonModalComponent.propTypes = {
    authHeader: PropTypes.shape({}).isRequired,
    isOpen: PropTypes.bool.isRequired,
    selectedJobIdTypes: PropTypes.arrayOf(PropTypes.shape({
        jobId: PropTypes.string.isRequired,
        jobType: PropTypes.string.isRequired,
    })).isRequired,
    onResolve: PropTypes.func.isRequired,
};
export default TransactionComparisonModalComponent;
