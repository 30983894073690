import React, {
    useCallback,
    useMemo,
    useState
} from 'react';
import {
    get as _get,
    isNil as _isNil
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import metadata from '../PaymentDetailsPage.metadata.json5';
import '../PaymentDetailsPage.messages';

const LOB = 'personalAuto_EA';

function ViewPaymentDetailsPage(props) {
    const {
        wizardData: rewriteVM,
        viewOnly
    } = props;

    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const { authHeader, authUserData } = useAuthentication();

    const availableValuesForPaymentOptions = useMemo(() => {
        const correctOffering = rewriteVM.lobData.personalAuto_EA.offerings.value[0];

        if (correctOffering.paymentPlans !== undefined) {
            return correctOffering.paymentPlans
                .filter((paymentPlans) => paymentPlans.name)
                .reduce((paymentPlanMap, paymentPlan) => ({
                    ...paymentPlanMap,
                    [paymentPlan.billingId]: paymentPlan
                }), {});
        }

        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rewriteVM.lobData.personalAuto_EA.offerings.value)]);

    const hasSignatureRequiredFormsInCurrentJob = _get(
        rewriteVM, 'lobData.personalAuto_EA.hasSignatureRequiredFormsInCurrentJob.value'
    );

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'top',
            readOnly: viewOnly
        },
        paymentDetailsPageLoadingIndicator: {
            loaded: true,
        },
        paymentDetailsPageContainer: {
            visible: true
        },
        policyTermComponent: {
            transactionVM: rewriteVM,
            viewOnly
        },
        totalPremiumID: {
            value: !_isNil(availableValuesForPaymentOptions[_get(rewriteVM, 'bindData.selectedPaymentPlan.value')])
                ? availableValuesForPaymentOptions[_get(rewriteVM, 'bindData.selectedPaymentPlan.value')].total : undefined
        },
        mailingAndBillingAddressComponent: {
            transactionVM: rewriteVM,
            updateWizardData: () => {},
            onValidate: () => { },
            viewOnlyMode: true,
            lob: LOB
        },
        monthlyPaymentScheduleComponent: {
            visible: false
        },
        quoteProposalLinkContainer: {
            disabled: true
        },
        paymentOptionsID: {
            submissionVM: rewriteVM,
            authHeader: {},
            updateWizardData: () => {},
            LOB,
            viewOnly: true
        },
        paymentusComponent: {
            submissionVM: rewriteVM,
            visible: false
        },
        signatureComponent: {
            submissionVM: rewriteVM,
            updateWizardData: () => {},
            authHeader,
            LOB,
            onSignatureSuccess: () => {},
            producerCodeOptions,
            setProducerCodeOptions,
            viewOnlyMode: true,
            isSignatureRequired: hasSignatureRequiredFormsInCurrentJob
        },
        insuredContactInfo: {
            viewOnlyMode: true
        },
        deliverDocumentsIndComponent: {
            visible: authUserData.permissions_Ext.includes('docdeliveryind_ext'),
            deliverDocInd: _get(rewriteVM, 'value.shouldDeliverDocuments_Ext', true),
            setDeliverDocInd: () => { },
            viewOnlyMode: true
        },
        // IAP-371 show TPI component in only read only Mode
        e1ptpiDisplayTableContainer: {
            transactionVM: rewriteVM,
            authHeader,
            updateWizardData: () => {},
            setIsSavingTPI: () => { },
            showErrors: false,
            onValidate: () => { },
            disregardFieldValidationParentPage: () => { },
            viewOnlyMode: true,
            showTitle: false
        },
        addTPIButton: {
            visible: false
        },
        quoteProposalFailureErrorDiv: {
            visible: false
        },
        paperlessEmailChangedMessageDiv: {
            visible: false
        },
        userNotAuthorisedErrorDiv: {
            visible: false
        }
    };


    const resolvers = {
        resolveCallbackMap: {
            signPolicy: undefined
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, rewriteVM, id, path, overrideProps),
        [rewriteVM, overrideProps]
    );

    return (
        <WizardPage
            showNext={false}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

ViewPaymentDetailsPage.propTypes = wizardProps;
export default ViewPaymentDetailsPage;
