import React, {
    useCallback, useContext, useEffect, useMemo, useState, useRef
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    noop as _noop,
    uniq as _uniq
} from 'lodash';
import {
    useModal,
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { RewriteService } from 'e1p-capability-rewrite';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { usePriorPolicyUpdateUtil, useOOSConflictPageLandingUtil, useUWIssueUtil } from 'e1p-capability-hooks';
import { AutoLossService, PriorCarrierService } from 'e1p-capability-gateway';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import styles from './RiskAnalysisPage.module.scss';
import metadata from './RiskAnalysisPage.metadata.json5';
import messages from './RiskAnalysisPage.messages';
import requiredMetadata from './RiskAnalysisPage.requiredness';

function RiskAnalysisPage(props) {
    const modalApi = useModal();
    const {
        wizardData: rewriteVM,
        updateWizardData,
        isSkipping,
        steps,
        currentStepIndex,
        authUserData,
        changeNextSteps,
        jumpTo,
        updateWizardSnapshot
    } = props;

    const stepsRef = useRef(steps);
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    // const [isPageInitialized, setPageInitialized] = useState(false);
    const [isQuoting, setIsQuoting] = useState(false);
    const [creditReportResponse, setCreditReportResponse] = useState({});
    const [dataForComponent, updateDataForComponent] = useState({});
    // const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const [isRetrievingReports, setIsRetrievingReports] = useState(false);
    const [isLossReportNoHit, setIsLossReportNoHit] = useState(false);
    const [overlappingException, setOverlappingException] = useState(false);
    const [priorCarrierChanged, setPriorCarrierChanged] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [visibleFields, updateVisibleFields] = useState([]);
    const [showPrayerForJudgementError, setShowPrayerForJudgementError] = useState(false);
    const [activeTab, updateActiveTab] = useState('');
    const policyState = _get(rewriteVM, 'baseData.policyAddress.state.value.code');
    const isRewriteNewBusiness = _get(rewriteVM, 'baseData.value.businessTransactionType_Ext') === 'NewBusiness';
    const { opCo } = useContext(AmfamOktaTokenContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        registerComponentValidation,
        registerInitialComponentValidation
    } = useValidation('RiskAnalysisPage');

    const {
        hasUWIssues
    } = useUWIssueUtil(
        rewriteVM,
        updateWizardData,
        jumpTo,
        steps
    );

    useEffect(() => {
        /**
         * Using useRef to access current updated steps.
         * as we are adding new conflicts step and landing user on this newly created step
         * "step" state variable from props does not give us updated value inside useOOSConflictPageLandingUtil
         * it refers initial rendered value only(as we are adding new step and want to land user
         * on new step in)
         */
        stepsRef.current = steps;
    }, [steps]);

    const {
        removeOrAddAndLandOnConflictsPage
    } = useOOSConflictPageLandingUtil(
        stepsRef,
        currentStepIndex,
        changeNextSteps,
        jumpTo
    );

    const {
        modifyDates
    } = usePriorPolicyUpdateUtil(rewriteVM.lobData.personalAuto_EA.priorPolicyUpdates);
    // user should have necessary permission and premium stability is maped then show the tab and details

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(rewriteVM, 'lobData.personalAuto_EA.premiumStability.value') !== undefined;

    const isSubmissionQuoted = useCallback(() => rewriteVM.baseData.periodStatus.get()?.code === 'Quoted', [rewriteVM]);

    useEffect(() => {
        const initialVisibleFields = ['declareLossStatementThreeYears', 'declareLossStatementFiveYears']; // Fields to look up by partner/state

        updateVisibleFields(
            isRequired(initialVisibleFields, requiredMetadata, policyState, opCo)
        );
        // User can't able to change policy state on Risk Analysis Page,
        // hence we need to run this useEffect only single time.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opCo]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted]);

    const isPageValid = useCallback(() => !overlappingException && !isLossReportNoHit
        , [overlappingException, isLossReportNoHit]);

    const createAutoLossVM = useCallback((vmObject, dtoName) => {
        const vmNode = viewModelService.create(
            vmObject,
            'pc',
            dtoName
        );

        return vmNode;
    }, [viewModelService]);

    useEffect(() => {
        if (!isSkipping) {
            setIsRetrievingReports(true);

            const AutoLossRecordsPromise = AutoLossService.loadAutoLosses(
                _get(rewriteVM, 'jobID.value'),
                authHeader
            );

            AutoLossRecordsPromise
                .then((response) => {
                    const autoLossRecords = [];

                    response.autoLossRecords.filter((record) => record.source.sourceType !== 'Self-Declared').forEach((result) => {
                        const recordVM = createAutoLossVM(
                            result,
                            'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoLossRecordDTO'
                        );

                        autoLossRecords.push(recordVM.value);
                    });

                    const autoViolationRecords = [];

                    response.autoViolationRecords.filter((record) => record.source.sourceType !== 'Self-Declared').forEach((result) => {
                        const violationVM = createAutoLossVM(
                            result,
                            'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoViolationRecordDTO'
                        );

                        _set(
                            violationVM,
                            'value.operatorDisplayName',
                            `${violationVM.value.assignment.firstName} ${violationVM.value.assignment.lastName}`
                        );
                        autoViolationRecords.push(violationVM.value);
                    });

                    _set(dataForComponent, 'lobData.personalAuto_EA.autoLossRecords.value', autoLossRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.autoViolationRecords.value', autoViolationRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.mvrLicenseStatusRecords.value', response.mvrlicenseStatus);
                    _set(dataForComponent, 'lobData.personalAuto_EA.orderingInfo.value', response.orderingInfo);
                    updateDataForComponent(dataForComponent);
                })
                .catch(() => {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: messages.reportsErrorTitle,
                        message: messages.reportsErrorMessage
                    });
                    setIsLossReportNoHit(true);
                })
                .finally(() => {
                    setIsRetrievingReports(false);
                });
        }
        // No array dependencies needed in this hook.
        // The logic of initializing losses data needs to be executed only once
        // when landing into losses page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping]);

    useEffect(() => {
        // We can skip page (on continue) to covs page if there hasn't been a quote yet
        registerInitialComponentValidation(isSubmissionQuoted);
        // register validation for disabling next button
        registerComponentValidation(isPageValid);
    }, [isPageValid, isSubmissionQuoted, registerComponentValidation, registerInitialComponentValidation, rewriteVM]);

    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            if (!isComponentValid || showPrayerForJudgementError) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsQuoting(true);
            modifyDates(rewriteVM);

            if (priorCarrierChanged) {
                const priorPolicies = _get(rewriteVM, 'lobData.personalAuto_EA.priorPolicies.value');
                const jobNumber = _get(rewriteVM, 'jobID.value');
                const priorCarrierPolicyDetailsDataDTO = { jobNumber, priorPolicies }

                try {
                    const priorCarrierResponse = await PriorCarrierService
                        .savePriorCarrierPolicies(priorCarrierPolicyDetailsDataDTO, authHeader);

                    // We can't continue to save and quote with errors, so exit function
                    if (!_isEmpty(_get(priorCarrierResponse, 'exceptions_Ext', []))) {
                        _set(rewriteVM, 'baseData.value.exceptions_Ext', priorCarrierResponse.exceptions_Ext);
                        updateWizardData(rewriteVM);
                        setIsQuoting(false);

                        return false
                    }

                    _set(rewriteVM, 'lobData.personalAuto_EA.priorPolicies.value', priorPolicies);
                } catch (error) {
                    const errorMessage = _get(error, 'baseError.data.error.message');

                    if (errorMessage) {
                        modalApi.showAlert({
                            title: messages.unableToSavePriorCarrier,
                            message: error.baseError.data.error.message,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.close
                        }).catch(_noop);
                    }
                }
            }

            modifyDates(rewriteVM);
            rewriteVM.value = await RewriteService
                .saveAndQuote([rewriteVM.value], authHeader);
            updateWizardData(rewriteVM);

            if (!calledFromOnSave) {
                // E1PAP1PC-13853 :
                // If we get conflicts in saveAndQuote, we will add conflicts page if its not present
                // and user will land on conflicts page
                // If we had conflicts and we came back and made changes such that after saveAndQuote
                // if there are no conflicts we will remove conflicts page if its present
                const hasConflicts = !_isEmpty(_get(rewriteVM, 'value.conflicts', []));

                removeOrAddAndLandOnConflictsPage(hasConflicts);

                if (hasConflicts) {
                    setIsQuoting(false);

                    return false;
                }
            }

            setIsQuoting(false);

            return rewriteVM;
        },
        [
            isComponentValid, modifyDates, rewriteVM,
            priorCarrierChanged, authHeader, updateWizardData,
            removeOrAddAndLandOnConflictsPage, showPrayerForJudgementError,
            modalApi
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = _get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, rewriteVM, updateWizardSnapshot]
    );

    const onChangePrayerForJudgement = useCallback(() => {
        // Get all manual added violations
        const manualViolationRecords = _get(rewriteVM, 'lobData.personalAuto_EA.manualViolationRecords.value', []);
        const selectedPrayerForJudgementOperators = [];

        manualViolationRecords.forEach((violationRecord) => {
            // Add to selected driver list when prayer for judgement is selected
            if (_get(violationRecord, 'assignment.publicId') && violationRecord.prayerForJudgementInd) {
                selectedPrayerForJudgementOperators.push(_get(violationRecord, 'assignment.publicId'));
            }
        });
        // Show error message if driver with prayer for judgement indicator appears more than one time
        setShowPrayerForJudgementError(selectedPrayerForJudgementOperators.length !== _uniq(selectedPrayerForJudgementOperators).length);
    }, [rewriteVM, setShowPrayerForJudgementError]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isRetrievingReports) {
            loadingMessage = translator(messages.loading);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isQuoting) {
            loadingMessage = translator(messages.ratingYourPolicyMessage);
        } else if (isSkipping) {
            loadingMessage = translator(messages.loadingNextPageMessage);
        }

        return loadingMessage;
    }, [isRetrievingReports, isSavingCurrentPageChanges, isQuoting, isSkipping, translator]);

    const uwIssuesPresent = useMemo(() =>
        hasUWIssues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [rewriteVM?.errorsAndWarnings_Ext?.underwritingIssues?.value]);

    const getDefaultActiveTab = useMemo(() => {
        if (uwIssuesPresent) {
            return 'uwIssuesTabID';
        }

        return 'priorCarrierTabID';
    }, [uwIssuesPresent]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        RiskAnalysisPageContainer: {
            visible: !isQuoting && !isSkipping && !isSavingCurrentPageChanges,
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            defaultActiveTab: getDefaultActiveTab,
            activeTab: activeTab !== '' ? activeTab : getDefaultActiveTab
        },
        RiskAnalysisPageLoader: {
            loaded: !isQuoting && !isSkipping && !isSavingCurrentPageChanges,
            text: getLoadingIndicatorMessage
        },
        EAPriorCarrierComponentId: {
            submissionVM: rewriteVM,
            disregardFieldValidation,
            setPriorCarrierChanged,
            showErrors: isPageSubmitted,
            viewOnlyMode: !isRewriteNewBusiness,
            showPriorCarrierEffectiveDateMessage: false
        },
        EALossAndViolationComponentId: {
            lobDataModel: rewriteVM.lobData.personalAuto_EA,
            onModelChange: () => updateWizardData(rewriteVM),
            viewModelService,
            disregardFieldValidation,
            operators: rewriteVM.lobData.personalAuto_EA.coverables.drivers?.value?.map((driver) => ({
                publicId: driver.publicID,
                displayName: driver.person.displayName
            })),
            showErrors: isPageSubmitted,
            policyState,
            periodStartDate: rewriteVM.baseData.periodStartDate,
            showPrayerForJudgementError,
            onChangePrayerForJudgement,
            authUserData,
            isVerified: true,
            updateIsPageSubmitted
        },
        EARiskAnalysisMiscComponentId: {
            submissionVM: rewriteVM,
            isSkipping,
            onValidate,
            shouldShowCedingField: policyState === 'NC'
        },
        EACreditReportsComponentId: {
            submissionVM: rewriteVM,
            creditReportResponse,
            setCreditReportResponse,
            updateWizardData,
            lineOfBusiness: rewriteVM.lobData.personalAuto_EA,
            lobName: 'personalAuto_EA',
            authUserData,
            id: 'EACreditReportsComponentId',
            onValidate,
            setOverlappingException
        },
        vehicleReportTabID: {
            visible: rewriteVM.baseData.periodStatus.value.code === 'Quoted'
        },
        lossesAndViolationID: {
            visible: !_isEmpty(dataForComponent),
            lossesAndViolations: dataForComponent,
            authHeader,
            updateBaseDataComponent: updateDataForComponent,
            quoteId: _get(rewriteVM, 'jobID.value'),
            drivers: rewriteVM.lobData.personalAuto_EA.coverables.drivers,
            policyState,
            jobType: _get(rewriteVM, 'value.baseData.jobType'),
            accountHolderName: _get(rewriteVM, 'baseData.accountHolder.person.displayName.value')
        },
        EAUWIssuesComponentId: {
            submissionVM: rewriteVM,
            updateWizardData,
            authHeader,
            // history
        },
        billingMiscellaneousReportComponent: {
            visible: authUserData?.permissions_Ext.includes('canviewbillingreport'),
            data: _get(rewriteVM,'value.baseData.cancelActivities_Ext',[])
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(rewriteVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: rewriteVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        },
        declareStatementThreeYears: {
            visible: visibleFields.includes('declareLossStatementThreeYears')
        },
        declareStatementFiveYears: {
            visible: visibleFields.includes('declareLossStatementFiveYears')
        },
        noMiscReportResultsMessageId: {
            visible: (() =>
                // If misc report content is empty 
                !_get(document.getElementById('miscellaneousReportsBodyId'), 'innerText')
                && !_get(document.getElementById('billingMiscellaneousReportComponent'), 'innerText')
            )()
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
            metadata.pageContent,
            rewriteVM,
            id,
            path,
            overrideProps
        ),
        [overrideProps, rewriteVM]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
