import { defineMessages } from 'react-intl';

export default defineMessages({
    optionalCoverages: {
        id: 'endorsement.eh.views.coverages.optioanalCoverages.Optional Coverages',
        defaultMessage: 'Date'
    },
    popularOptionalCoverages: {
        id: 'endorsement.eh.views.coverages.optioanalCoverages.Popular Optional Coverages',
        defaultMessage: 'Popular Optional Coverages'
    },
    moreOptionalCoverages: {
        id: 'endorsement.eh.views.coverages.optioanalCoverages.More Optional Coverages',
        defaultMessage: 'More Optional Coverages'
    },
    conditions: {
        id: 'endorsement.eh.views.coverages.optioanalCoverages.Conditions',
        defaultMessage: 'Conditions'
    },
    exclusions: {
        id: 'endorsement.eh.views.coverages.optioanalCoverages.Exclusions',
        defaultMessage: 'Exclusions'
    },
    backToCoverages: {
        id: 'endorsement.eh.views.coverages.optioanalCoverages.BACK TO COVERAGES',
        defaultMessage: 'BACK TO COVERAGES'
    },
    completeMissingFields: {
        id: 'endorsement.eh.views.coverages.optioanalCoverages.Please complete the missing fields.',
        defaultMessage: 'Please complete the missing fields.'
    },
    optionalCoverageSelect: {
        id: 'endorsement.eh.views.coverages.optioanalCoverages.selectCoverage',
        defaultMessage: 'Select any coverages marked with'
    },
    optionalCoverageLabel: {
        id: 'endorsement.eh.views.coverages.optioanalCoverages.discountMessage',
        defaultMessage: 'to receive a Package Discount. The more coverages selected, the greater the discount.'
    },
});
