import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';
import JobService from './JobService';

export default class RewriteService {
    static findJobByJobNumber(jobNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayRewrite'),
            'findJobByJobNumber', [jobNumber], additionalHeaders);
    }

    static withdrawRewriteByRewriteNumber(jobNumber, additionalHeaders) {
        return JobService.withdrawJobByJobNumber(jobNumber, additionalHeaders);
    }
}
