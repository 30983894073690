import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { ModalNext, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'e1p-portals-wizard-react';
import { QuoteProposalUtil } from 'e1p-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './EUReports.metadata.json5';
import styles from './EUReports.module.scss';

import { Button } from '@jutro/legacy/components';

function EUReports(props) {
    const {
        submissionVM,
        updateWizardData,
        incidentData,
        isOpen,
        onResolve,
        closePopupAction
    } = props;


    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);

    useEffect(() => {
        if (incidentData && incidentData.value !== '') {
            submissionVM.lobData.personalAuto_EA.incidentData = incidentData;

            const paymentPlansList = submissionVM.lobData.personalUmbrella_EU.offerings.children[0].paymentPlans.value;
            const selectedPlan = paymentPlansList.filter((element) => element.isSelected);
            const verifiedPremium = selectedPlan[0].total;
            const unVerifiedPremium = _.get(submissionVM, 'unverfiedPremium');
            const premiumIncrease = { currency: 'usd', amount: verifiedPremium.amount - unVerifiedPremium };

            _.set(submissionVM, 'verifiedPremium', verifiedPremium);
            _.set(submissionVM, 'premiumIncrease', premiumIncrease);

            if (submissionVM.lobData.personalAuto_EA.incidentData.autoLossRecords) {
                submissionVM.lobData.personalAuto_EA.incidentData.autoLossRecords
                    .forEach((element) => {
                        const assignDisplayName = `${_.get(element,'assignment.firstName')} ${_.get(element,'assignment.lastName')}`;
                        const policyHolderName = _.get(element, 'assignment.roleInClaim') === 'POLICYHOLDER' ? assignDisplayName : '-';
                        const vehicleOperatorName = _.get(element, 'assignment.roleInClaim') === 'VEHICLE OPERATOR' ? assignDisplayName : '-';

                        _.set(element, 'policyHolderName', policyHolderName);
                        _.set(element, 'vehicleOperatorName', vehicleOperatorName);

                        _.set(element, 'lossType', translator({ id: `typekey.AutoLossType_Ext.${element.lossType}` }));
                    });
            }

            if (submissionVM.lobData.personalAuto_EA.incidentData.autoViolationRecords) {
                submissionVM.lobData.personalAuto_EA.incidentData.autoViolationRecords.forEach(
                    (element) => {
                        element.operator = _.get(
                            submissionVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.displayName.value', ''
                        );
                        element.assignment.displayName = `${element.assignment.firstName} ${element.assignment.lastName}`;
                        _.set(element, 'violationType', translator({ id: `typekey.ViolationType_Ext.${element.violationType}` }));
                    }
                );
            }

            updateWizardData(submissionVM);
        }
    }, [incidentData, submissionVM, translator, updateWizardData]);

    useEffect(() => {
        QuoteProposalUtil.fetchQuoteProposal(
            submissionVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();

            if (quoteProposalLink) {
                window.open(quoteProposalLink, '_blank');

                return true;
            }
        }, [quoteProposalLink]
    );

    // When payment option changes, we need to get a new doc url
    useEffect(() => {
        if (submissionVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            submissionVM.paymentOptionChanged = false;
        }
    }, [authHeader, submissionVM, submissionVM.paymentOptionChanged]);

    const onOk = useCallback(
        () => {
            const wrapperObj = {
                incidentData,
            };

            onResolve(wrapperObj);
        }, [incidentData, onResolve]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        quoteProposalLinkContainer: {
            onClick: (e) => openDocument(e),
            disabled: !quoteProposalCompleted
        },
        paymentOptionsID: {
            submissionVM,
            authHeader,
            updateWizardData,
            LOB: 'personalUmbrella_EU',
            viewOnly: true
        },
        quoteProposalFailureErrorDiv: {
            visible: isQuoteProposalFailed
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        },
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps),
        [submissionVM, overrideProps]
    );


    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onOk}
            className={styles.fullScreen}
            onAfterClose={closePopupAction}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
            <ModalFooter>
                <Button
                    id="okButton"
                    onClick={onOk}
                >
                    {commonMessages.next}
                </Button>
            </ModalFooter>
        </ModalNext>

    );
}

EUReports.propTypes = wizardProps;
export default EUReports;
