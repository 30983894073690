import React, {
    useContext, useCallback, useEffect, useState, useRef
} from 'react';
import {
    get, set, isUndefined, isEmpty
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { EndorsementService } from 'e1p-capability-policychange';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useLandingPageUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import messages from './EUVehicleOperatorPage.messages';
import metadata from './EUVehicleOperatorPage.metadata.json5';

const LOB = 'personalUmbrella_EU';

function EUVehicleOperatorPage(props) {
    const modalApi = useModal();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);
    const { authHeader } = useAuthentication();
    const {
        onValidate, initialValidation,
        isComponentValid
    } = useValidation('EUVehicleOperatorPage');
    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isUndefined(policyChangeVM.lobData.personalUmbrella_EU.vehicleOperators.value)) {
            set(policyChangeVM.lobData.personalUmbrella_EU.vehicleOperators, 'value', []);
        }

        updateWizardData(policyChangeVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const vehicleOperatorElement = document.getElementById(
            `firstName${policyChangeVM.lobData.personalUmbrella_EU.vehicleOperators.length - 1}`
        );

        if (vehicleOperatorElement
            && isEmpty(vehicleOperatorElement.value)
            && vehicleOperatorElement.focus !== undefined) {
            vehicleOperatorElement.focus();
        }
    }, [policyChangeVM.lobData.personalUmbrella_EU.vehicleOperators.length]);

    // this grabs the latest element on the page --
    // this is to make sure this element has been loaded
    const latestVehicleOperatorElement = document.getElementById(
        `euVehicleOperatorComponentDiv${policyChangeVM.lobData.personalUmbrella_EU.vehicleOperators.children.length - 1}`
    );

    useEffect(() => {
        // indexStale set in the add vehicle operator function
        // once latest element is loaded and a new vehicle operator is added
        // we check if the button should be visible
        if (latestVehicleOperatorElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestVehicleOperatorElement]);

    const addVehicleOperator = useCallback(
        () => {
            const {
                _xCenter,
                _dtoName
            } = policyChangeVM.lobData.personalUmbrella_EU.vehicleOperators;
            const newVehicleOperatorVM = viewModelService.create({}, _xCenter, _dtoName);

            policyChangeVM.lobData.personalUmbrella_EU.vehicleOperators.pushElement(
                newVehicleOperatorVM
            );
            updateWizardData(policyChangeVM);
            setCheckScrolling();
            setIndexStale(true);
        },
        [viewModelService, updateWizardData, policyChangeVM]
    );

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        vehicleOperatorPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        vehicleOperatorPageContainer: {
            visible: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleOperatorsContainer')
        },
        EUVehicleOperatorGrid: {
            key: get(policyChangeVM, 'lobData.personalUmbrella_EU.vehicleOperators', []).length,
            transactionVM: policyChangeVM,
            drivers: get(policyChangeVM, 'lobData.personalUmbrella_EU.vehicleOperators', []),
            path: 'lobData.personalUmbrella_EU.vehicleOperators.children',
            onValidate,
            showErrors: isPageSubmitted,
            setCheckScrolling,
            updateWizardData
        },
    };

    /**
     * Helper callback for writing values to the view model.
     */
    const writeValue = useCallback(
        (newVal, path) => {
            set(policyChangeVM, `${path}.value`, newVal);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            addVehicleOperator,
            onValidate
        }
    };

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            try {
                policyChangeVM.value = await EndorsementService.saveEndorsement(
                    [(policyChangeVM.value)],
                    authHeader
                );
            } catch {
                setIsSavingEndorsement(false);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: messages.saveCoverageError,
                    message: messages.saveCoverageErrorMessage,
                    messageProps: {
                        ok: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });
            }

            setIsSavingEndorsement(false);

            return policyChangeVM;
        },
        [authHeader, isComponentValid, modalApi, policyChangeVM]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, policyChangeVM, updateWizardSnapshot]
    );

    const saveAndQuote = useCallback(
        async () => {
            const saveResponse = await EndorsementService.saveEndorsement(
                [policyChangeVM.value],
                authHeader
            );

            set(policyChangeVM, 'value', saveResponse);
            updateWizardData(policyChangeVM);

            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteResponse);
            updateWizardData(policyChangeVM);

            return policyChangeVM.value;
        },
        [authHeader, policyChangeVM, updateWizardData]
    );

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);
            policyChangeVM.value = await saveAndQuote();
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }
            
            // Update the component's state only if it is currently mounted.
            if (isMounted.current) {
                setIsSavingEndorsement(false);
            }

            return false;
        },
        [getLandingPageIndexForQuotedJob, jumpTo, policyChangeVM, isComponentValid,
            saveAndQuote, steps, updateWizardData, updateWizardSnapshot]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showCustom
            onCustom={onCustom}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

EUVehicleOperatorPage.propTypes = wizardProps;
export default EUVehicleOperatorPage;
