import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ModalNext, ModalBody, ModalFooter, ModalHeader } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { Grid, GridItem } from '@jutro/layout';
import appConfig from 'app-config';
import { SignatureUtil } from 'e1p-capability-gateway';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { get, set, startCase } from 'lodash';
import styles from './RemoteClick2SignModal.module.scss';
import messages from './RemoteClick2SignModal.messages';

import { Button, InputField } from '@jutro/legacy/components';

function RemoteClick2SignModal(props) {
    const {
        submissionVM,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        quoteID,
        authHeader,
        signature,
        onSignatureSuccess,
        recipients,
        showErrorMessage,
        licensedProducerID
    } = props;
    const translator = useTranslator();
    const [isLoading, setIsLoading] = useState(false);
    const lobName = appConfig.lobNameMap[get(submissionVM, 'baseData.productCode.value')];
    const paperlessInd = get(submissionVM, `lobData.${lobName}.value.paperlessInd`, false);
    const paperlessEmail = get(submissionVM, `lobData.${lobName}.value.paperlessEmail`, undefined);
    
    const [recipientList, setRecipientList] = useState(
        recipients.map((recipient) => {
            // IAP-5529, use paperlessEmail if available
            if (recipient.role === 'PrimaryNamedInsured' && paperlessInd && paperlessEmail) {
                return ({
                        ...recipient,
                        email: paperlessEmail
                    });
            }

            return ({
                        email: '',
                        ...recipient
                    });
        })
    );

    const cancelSignatureModal = useCallback(() => {
        onResolve(undefined);
    }, [onResolve]);

    const remoteClick2SignCall = async () => {
        try {
            setIsLoading(true);

            const response = await SignatureUtil.signPolicy(signature, quoteID, authHeader, recipientList, licensedProducerID);
            // Check for exceptions in the response & show modal alert with error message
            const errorMessage = get(response, 'exceptions_Ext[0].errorMessage');

            if (errorMessage) {
                showErrorMessage(messages.remoteClickErrorMessageTitle, errorMessage);
                onSignatureSuccess(false);
                onResolve(false);
            } else {
                set(submissionVM, 'value.baseData.signature_Ext.status', response.sigantureStatus);
                set(submissionVM, 'value.baseData.signature_Ext.signatureType', response.signatureType);
                onSignatureSuccess(true);

                //* Sign button will be hidden only if we have signature status as completed
                if (response.sigantureStatus === 'COMPLETED') {
                    onResolve(true);
                } else {
                    onResolve(false);
                }
            }
        } catch {
            showErrorMessage(messages.remoteClickErrorMessageTitle, messages.remoteClick2SignError);
            onResolve(false);
        } finally {
            setIsLoading(false);
        }
    };

    const onRecipientEmailChange = useCallback((value, index) => {
        const recipietListCopy = [...recipientList];

        set(recipietListCopy, [index, 'email'], value);
        setRecipientList(recipietListCopy);
    }, [recipientList]);

    const isRecipientEmailListInvalid = useCallback(() => recipientList.find((recipient) => !recipient.email), [recipientList]);

    return (
        <ModalNext
            className={styles.partialScreen}
            isOpen={isOpen}
        >
            <ModalHeader title={translator(messages.remoteClick2Sign)} />
            <ModalBody>
                {!isLoading ?
                    (<React.Fragment><div>{translator(messages.verifyDefault)}</div>
                        <br />
                        <Grid
                            columns={['1fr', '1fr', '1fr']}
                            gap="small"
                            vgap="small"
                            id="Grid"
                            tag="div"
                        >
                            <GridItem className="labelItem">
                                {translator(messages.name)}
                            </GridItem>
                            <GridItem className="labelItem">
                                {translator(messages.role)}
                            </GridItem>
                            <GridItem className="labelItem">
                                {translator(messages.email)}
                            </GridItem>
                            {
                                recipientList.map((recipient, index) => (
                                        <React.Fragment key={`recepeint${index}`} >
                                            <GridItem key={`recipeintName${index}`} className={styles.gridCell}>
                                                {`${get(recipient, 'contact.firstName', '')} ${get(recipient, 'contact.lastName', '')}`}
                                            </GridItem>
                                            <GridItem key={`recipeintRole${index}`} className={styles.gridCell}>
                                                {startCase(recipient.role)}
                                            </GridItem>
                                            <GridItem className={styles.gridCell}>
                                                <InputField
                                                    value={recipient.email}
                                                    autoComplete={false}
                                                    id={`recipeintemail${index}`}
                                                    onValueChange={(value) => onRecipientEmailChange(value, index)}
                                                />
                                            </GridItem>
                                        </React.Fragment>
                                    ))
                            }
                        </Grid></React.Fragment>)
                    : (<E1PLoader loaded={!isLoading}/>) 
                }
            </ModalBody>
            <ModalFooter>
                <Button onClick={remoteClick2SignCall} disabled={isLoading || isRecipientEmailListInvalid()}>
                    {actionBtnLabel}
                </Button>
                <Button onClick={cancelSignatureModal} disabled={isLoading} type="outlined">
                    {cancelBtnLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

RemoteClick2SignModal.propTypes = {
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    quoteID: PropTypes.string.isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    signature: PropTypes.string.isRequired,
    onSignatureSuccess: PropTypes.func.isRequired,
    recipients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    showErrorMessage: PropTypes.func.isRequired,
    licensedProducerID: PropTypes.string.isRequired,
    submissionVM: PropTypes.shape({}).isRequired
};

RemoteClick2SignModal.defaultProps = {
    isOpen: undefined,
    onResolve: undefined
};

export default RemoteClick2SignModal;
