import React, {
    useCallback, useEffect
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    ModalNext,
    ModalBody
} from '@jutro/components';
import PropTypes from 'prop-types';
import styles from './EAVehiclePrefill.module.scss';
import metadata from './EAVehiclePrefill.metadata.json5';
import './EAVehiclePrefill.messages';

function EAVehiclePrefill(props) {
    const {
        submissionVM,
        updateWizardData,
        prefillData,
        isOpen,
        onResolve
    } = props;

    const { onValidate, isComponentValid } = useValidation(
        'EAVehiclePrefill'
    );

    const handleStatusChange = useCallback((value, index) => {
        const fullPath = `lobData.personalAuto_EA.prefillVehicles.children[${index}].addToQuote`;

        _.set(submissionVM, fullPath, value);
        submissionVM.value.prefillVehicles[index].addToQuote = value;
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    useEffect(
        () => {
            const vehiclesDTO = _.get(prefillData, 'value', []);

            if (prefillData !== '') {
                vehiclesDTO.forEach((vehicle) => {
                    _.set(vehicle, 'addToQuote', true);
                });
                submissionVM.value.prefillVehicles = prefillData.value;
            }

            updateWizardData(submissionVM);
        }, [prefillData, submissionVM, updateWizardData]
    );

    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        submissionVM.lobData.personalAuto_EA.prefillVehicles.children.forEach((driver, index) => {
            overrideProps[`prefillVehicle${index}`] = {
                handleStatusChange
            };
        });

        return overrideProps;
    }, [handleStatusChange, submissionVM.lobData.personalAuto_EA.prefillVehicles.children]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        addLoss: {
            disabled: !isComponentValid,
        },
        prefillDescription: {
            visible: !_.isEmpty(prefillData.value)
        },
        eavehicleprefilllabels: {
            visible: !_.isEmpty(prefillData.value)
        },
        eavehicleprefillbody: {
            visible: !_.isEmpty(prefillData.value)
        },
        eavehicleprefillEmptyError: {
            visible: _.isEmpty(prefillData.value)
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVM]
    );

    const onOk = useCallback(
        () => {
            const wrapperObj = {
                prefillData
            };

            // this is where we can pass any data back on close
            onResolve(wrapperObj);
        }, [onResolve, prefillData]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            onOk
        },
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onOk}
            className="full-screen"
        >
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
        </ModalNext>

    );
}

EAVehiclePrefill.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
            prefillVehicles: PropTypes.arrayOf(
                PropTypes.shape({})
            )
        }),
        lobData: PropTypes.shape({
            personalAuto_EA: PropTypes.shape({
                prefillVehicles: PropTypes.shape({
                    children: PropTypes.arrayOf(
                        PropTypes.shape({})
                    )
                })
            })
        })
    }).isRequired,
    prefillData: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func
};

EAVehiclePrefill.defaultProps = {
    isOpen: false,
    onResolve:undefined
};

export default EAVehiclePrefill;
