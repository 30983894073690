
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef
} from 'react';
import {
    get as _get, some as _some, isUndefined as _isUndefined, set as _set, findIndex as _findIndex
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps, WizardPageTemplateWithTitle } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useViewPolicyUtil } from 'e1p-capability-hooks';
import { TransactionViewFlowUtil } from 'e1p-portals-util-js';
import { useTranslator } from '@jutro/locale';
import metadata from '../StartChangePage.metadata.json5';
import PCEUViewVehicleOperatorPage from '../../VehicleOperator/viewOnly/ViewEUVehicleOperatorPage';
import viewOnlyWizardConfig from '../../../config/viewOnly/eu-wizard-config.json5';

const LOB = 'personalUmbrella_EU';

function ViewStartChangePage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        currentStep,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        markFurthestStep,
        currentStepIndex,
        changeNextSteps
    } = props;
    const translator = useTranslator();
    const {
        onValidate
    } = useValidation('ViewStartChangePage');

    const {
        getLandingPageIndexForViewOnlyPolicy
    } = useViewPolicyUtil();

    const underlyingPoliciesVM = useMemo(() => _get(policyChangeVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []), [policyChangeVM]);

    const vehicleOperatorPage = useRef({
        id: 'PCEUVehicleOperatorsPage',
        path: '/view-vehicle-operator',
        component: PCEUViewVehicleOperatorPage,
        title: {
            id: 'policychange.eu.wizard.step.Vehicle Operator',
            defaultMessage: 'Vehicle Operator',
        },
        stepProps: {
            template: WizardPageTemplateWithTitle
        },
    }).current;

    const handleVehicleOperatorPage = useCallback(async () => {
        const differentAutoExposures = ['personalauto', 'personalaononowner', 'otherspecialityvehicle', 'cycle'];
        const remainingSteps = viewOnlyWizardConfig.steps
            .slice(currentStepIndex + 1, viewOnlyWizardConfig.steps.length);
        const indexToUnderlyingPolicyPage = _findIndex(
            remainingSteps, (step) => step.path === '/view-underlying-policy'
        );
        const vehicleUnderlyingPolicyExists = _some(underlyingPoliciesVM.value,
            (policy) => differentAutoExposures.includes(policy.policyType));

        if (vehicleUnderlyingPolicyExists) {
            remainingSteps.splice(indexToUnderlyingPolicyPage + 1, 0, vehicleOperatorPage);
        }

        changeNextSteps(remainingSteps);

        return remainingSteps;
    }, [changeNextSteps, currentStepIndex, underlyingPoliciesVM.value,
        vehicleOperatorPage]);

    useEffect(() => {
        if (_isUndefined(underlyingPoliciesVM.value)) {
            _set(underlyingPoliciesVM, 'value', []);
        }
    }, [underlyingPoliciesVM]);

    useEffect(() => {
        handleVehicleOperatorPage().then((remainingSteps) => {
            TransactionViewFlowUtil.jumpToPageAndMarkFurthestVisitedStep(
                remainingSteps, currentStep,
                isPageJumpEnabled, updateIsPageJumpEnabled, jumpTo,
                getLandingPageIndexForViewOnlyPolicy, markFurthestStep,
                LOB, _get(policyChangeVM, 'baseData.value.jobType')
            );
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(() => policyChangeVM, [policyChangeVM]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            readOnly: true
        },
        openPolicyChangeInfoMessageDiv: {
            visible: false
        },
        changeEffectiveDate: {
            dateDTO: policyChangeVM.baseData.effectiveDate,
            updateDateDto: () => updateWizardData(policyChangeVM),
            showErrors: true,
            onValidate
        },
        changeDescription: {
            visible: (_get(policyChangeVM, 'policyChangeReason_Ext.value.code') === 'other')
        },
        changeSource: {
            visible: false
        },
        changeReason: {
            visible: false
        },
        changeSourceViewOnly: {
            value: policyChangeVM.value.policyChangeSource !== undefined
                ? translator({
                    id: `typekey.PolicyChangeSource_Ext.${policyChangeVM.value.policyChangeSource}`,
                    defaultMessage: policyChangeVM.value.policyChangeSource
                })
                : ''
        },
        changeReasonViewOnly: {
            value: policyChangeVM.value.policyChangeReason !== undefined
                ? translator({
                    id: `typekey.PolicyChangeReason_Ext.${policyChangeVM.value.policyChangeReason}`,
                    defaultMessage: policyChangeVM.value.policyChangeReason
                })
                : ''
        },
        lossOccurredBetweenEffectiveDateAndCreateDateInd: {
            visible: policyChangeVM.value.hasLossOnOrAfterEffDate_Ext !== undefined 
            || policyChangeVM.value.hasLossOnOrAfterEffDate !== undefined,
            value: policyChangeVM.value.hasLossOnOrAfterEffDate_Ext === undefined
                ? policyChangeVM.value.hasLossOnOrAfterEffDate
                : policyChangeVM.value.hasLossOnOrAfterEffDate_Ext
        },
        startPageLoader: {
            loaded: true
        },
        policyChangeDetailsDiv: {
            visible: true
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

ViewStartChangePage.propTypes = wizardProps;

export default ViewStartChangePage;
