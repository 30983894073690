import React, { useState, useCallback, useEffect } from 'react';
import { Grid, GridItem } from '@jutro/layout';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { CheckboxField, DropdownSelectField } from '@jutro/legacy/components';

function EHSpecialLossOptionalCoverageComponent(props) {
    const {
        viewOnlyMode,
        transactionVM,
        changeSubmissionAndSync,
        currentOfferingIndex
    } = props;
    const [specialLossChecked, setSpecialLossChecked] = useState(false);
    const [specialLossLimit, setSpecialLossLimit] = useState();
    // find index of COverage A - Dwelling
    const coverageIndex = _.findIndex(_.get(transactionVM, `lobData.homeowners_EH.offerings.children.${currentOfferingIndex}.coverages.coverages.value`), {codeIdentifier: 'EH_DwellingCovA'});
    const policyState = _.get(transactionVM, 'baseData.policyAddress.state.value.code') ||  _.get(transactionVM, 'policyAddress.state.value.code');

    useEffect(() => {
        const limit = _.get(transactionVM, `lobData.homeowners_EH.offerings.children.${currentOfferingIndex}.coverages.coverages.children[${coverageIndex}].terms.children[1].chosenTerm.value`);

        setSpecialLossChecked(limit > 0);
        setSpecialLossLimit(limit);
    }, [coverageIndex, currentOfferingIndex, transactionVM])

    const getAvailableValues = useCallback(() => {
        const options = _.get(transactionVM, `lobData.homeowners_EH.offerings.children.${currentOfferingIndex}.coverages.coverages.children[${coverageIndex}].terms.children[1].value.options`, []);

        return _.filter(options, (option) => !!option.code).map((option) => ({
                code: option.code,
                name: option.name
            }));
    }, [coverageIndex, currentOfferingIndex, transactionVM]);

    const onSpecialLossValueChange = useCallback((value) => {
        if (!value) {
            // Since we do not have selected flag from PC side for special loss settlement, we need to remove selected term on uncheck
            changeSubmissionAndSync(undefined, `lobData.homeowners_EH.offerings.children.${currentOfferingIndex}.coverages.coverages.children[${coverageIndex}].terms.children[1].chosenTerm.value`, `lobData.homeowners_EH.offerings.children[${currentOfferingIndex}]`, `quoteData.offeredQuotes.children[${currentOfferingIndex}]`);
            setSpecialLossLimit(undefined);
        } else {
            // Since we do not have selected flag from PC side for special loss settlement, we need to default it to first option for terms on check
            const defaultLimit = _.get(_.first(getAvailableValues()), 'code');

            changeSubmissionAndSync(defaultLimit, `lobData.homeowners_EH.offerings.children.${currentOfferingIndex}.coverages.coverages.children[${coverageIndex}].terms.children[1].chosenTerm.value`, `lobData.homeowners_EH.offerings.children[${currentOfferingIndex}]`, `quoteData.offeredQuotes.children[${currentOfferingIndex}]`);
            setSpecialLossLimit(defaultLimit); 
        }

        setSpecialLossChecked(value);
    }, [changeSubmissionAndSync, coverageIndex, currentOfferingIndex, getAvailableValues]);

    const onSpecialLossLimitChange = useCallback((value) => {
        setSpecialLossLimit(value);
        changeSubmissionAndSync(value, `lobData.homeowners_EH.offerings.children.${currentOfferingIndex}.coverages.coverages.children[${coverageIndex}].terms.children[1].chosenTerm.value`, `lobData.homeowners_EH.offerings.children[${currentOfferingIndex}]`, `quoteData.offeredQuotes.children[${currentOfferingIndex}]`);
    }, [changeSubmissionAndSync, coverageIndex, currentOfferingIndex]);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>
            {
                // Only for CT state Special Loss Settlement option should appear
                (coverageIndex >= 0 && policyState === 'CT') &&
                <Grid columns={['2.25fr', '1fr']}>
                    <GridItem>
                        <CheckboxField
                            id="special-loss-checkbox"
                            label={{
                                id: "e1p.platform.eh.Special Loss Settlement",
                                defaultMessage: 'Special Loss Settlement'
                            }}
                            showInlineLabel
                            readOnly={viewOnlyMode}
                            value={specialLossChecked}
                            onValueChange={onSpecialLossValueChange}
                        />
                    </GridItem>
                    <GridItem>
                        {specialLossChecked && 
                            <DropdownSelectField
                                id="special-loss-limit"
                                availableValues={getAvailableValues()}
                                label={{
                                    id: "e1p.platform.eh.Limit",
                                    defaultMessage: 'Limit'
                                }}
                                readOnly={viewOnlyMode}
                                value={specialLossLimit}
                                onValueChange={onSpecialLossLimitChange}
                            />
                        }
                    </GridItem>
                </Grid>
            } 
        </React.Fragment>
    );
}

EHSpecialLossOptionalCoverageComponent.propTypes = {
    viewOnlyMode: PropTypes.bool,
    changeSubmissionAndSync: PropTypes.func,
    transactionVM: PropTypes.shape({}).isRequired,
    currentOfferingIndex: PropTypes.number
};

EHSpecialLossOptionalCoverageComponent.defaultProps = {
    viewOnlyMode: false,
    changeSubmissionAndSync: () => {},
    currentOfferingIndex: 0
};

export default EHSpecialLossOptionalCoverageComponent;
