import React, {
    useCallback
} from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import metadata from './PropertyPage.metadata.json5';
import styles from './PropertyPage.module.scss';

function PropertyPage(props) {
    const { wizardData: policyViewVM } = props;

    const onNext = useCallback(
        () => policyViewVM,
        [policyViewVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            recalculateReplacementCostClick: undefined,
            onValidate: undefined,
        },
        resolveClassNameMap: styles
    };


    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        propertyPageLoader: {
            loaded: true
        },
        replacementCostRecalculate: {
            visible: false
        },
        e1pEHHF9PropertyDetailsComponent: {
            transactionVM: policyViewVM,
            updateWizardData: () => {},
            isPageSubmitted: false,
            heatingSystemType: () => {},
            updateHeatingSystemType: () => {},
            coolingSystemType: () => {},
            updateCoolingSystemType: () => {},
            setIsReplacementCostStale: false,
            creditScoreMoreThan597: false,
            viewOnlyMode: true,
        },
    };

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyViewVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
