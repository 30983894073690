import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class PropertyLossService {
    /**
     * Returns property loss record details
     * @param {String} jobNumber String
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static loadPropertyLosses = (jobNumber, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('propertyloss'),
            'getPropertyLosses',
            [jobNumber],
            additionalHeaders
        );
    }

    /**
     * Returns auto loss record details
     * @param {String} jobNumber String
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static loadAutoLosses = (jobNumber, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('propertylossauto'),
            'getAutoLosses',
            [jobNumber],
            additionalHeaders
        );
    }

    static savePropertyOverrides = (
        jobNumber,
        PropertyLossRecords,
        additionalHeaders = {}
    ) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('propertyloss'),
            'saveOverrides',
            [jobNumber, PropertyLossRecords],
            additionalHeaders
        );
    }

    static saveAutoOverrides = (
        jobNumber,
        autoLossRecords,
        additionalHeaders = {}
    ) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('propertylossauto'),
            'saveOverrides',
            [jobNumber, autoLossRecords],
            additionalHeaders
        );
    }
}
