/* eslint-disable no-case-declarations */
/* eslint-disable radix */
import React, {
    useCallback, useContext, useEffect, useMemo, useState, useRef
} from 'react';
import {
    get, set, filter, map, sortBy, uniqBy, findIndex, find, isUndefined, includes
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useModal } from '@jutro/components';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { PrefillService } from 'e1p-capability-quoteandbind';
import { QuoteProposalService } from 'e1p-capability-gateway';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { ClausesUtil as e1pClausesUtil, CoverageUtil, QuoteProposalUtil, OfferingUtil } from 'e1p-portals-util-js';
import EATravelPackagesConfig from 'e1p-portals-util-js/EATravelPackages-config.json';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { usePriorPolicyUpdateUtil, useUWIssueUtil, useModifierUtil, useSideBySideQuoteUtil, useAdditionalInfoUtil } from 'e1p-capability-hooks';
import messages from './QuotePage.messages';
import metadata from './QuotePage.metadata.json5';

function structureClauseTableData(coverage) {
    // putting ID into an object as the Jutro table component expects an object
    return {
        publicID: coverage.publicID
    };
}

function generateClauseData(columnData, coveragePath, vehicleFixedID) {
    return columnData.map(({ lob, code }) => {
        let completeCoveragePath = `coverages.${coveragePath}`;

        if (vehicleFixedID) {
            const vehicleOfferings = lob.data.coverages.vehicleCoverages;
            const vehicleOfferingIndex = vehicleOfferings.findIndex(
                (vehicleCoverage) => vehicleCoverage.fixedId === vehicleFixedID
            );

            completeCoveragePath = `coverages.${coveragePath}.children[${vehicleOfferingIndex}].coverages`;
        }

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

const structureCustomQuote = (submissionVM, affectedQuote, clauses) => 
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    })
;

/**
 * Original a flat map from all offerings
 * I have changed this to only return selected version
 * @param {VMNode} submissionVM 
 * @returns {Array} list of objects - cov public ids
 */
const getCoveragesUniqueIDLineCoverages = (submissionVM) => {
    const selectedOfferings = get(
        submissionVM,
        'lobData.personalAuto_EA.offerings.value'
    ).find((lobOffering) => get(submissionVM, 'quoteData.offeredQuotes.value').find(
            (quoteOffering) => quoteOffering.selected
        ).branchCode === lobOffering.branchCode);

    const lineCoverages = selectedOfferings.coverages.lineCoverages.map(structureClauseTableData);

    return lineCoverages;
};

const getCoveragesUniqueIDVehicleCoverages = (submissionVM, vehFixedId) => {
    const offerings = get(
        submissionVM,
        'lobData.personalAuto_EA.offerings.value'
    );

    const vehicleCoverages = uniqBy(offerings.flatMap((offering) => (
        get(offering, ['coverages', 'vehicleCoverages'], [])
            .filter((vehicleCoverage) => vehicleCoverage.fixedId === vehFixedId)
            .flatMap(({ coverages }) => (
                coverages.map(structureClauseTableData)
            ))
    )), 'publicID');

    return vehicleCoverages;
};

const generateColumnData = (submissionVM) => {
    const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = get(submissionVM, `${quoteOfferingPath}.value`) || [];
    const selectedVersion = quoteOfferings.find((version) => version.selected);

    let columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];

            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                }
            };
        });

    columnData = columnData.length > 1 ? [columnData.find((offering) => offering.code === selectedVersion.branchCode)]
        : columnData;

    return sortBy(columnData, ['code']);
};

const getCustomQuote = (
    vm,
    lobPath,
    quotePath,
    lobName,
    filterChangedClauses = false
) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    const quoteOffering = get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

const generateTableData = (submissionVM, columnData, translator) => {
    const vehicles = get(
        submissionVM,
        'lobData.personalAuto_EA.coverables.vehicles.value'
    );
    const lineCovsUniqueIDs = getCoveragesUniqueIDLineCoverages(submissionVM);

    const lineCoverages = {
        header: translator(messages.generalCoverages),
        data: lineCovsUniqueIDs,
        tableContent: generateClauseData(columnData, 'lineCoverages'),
        coverageType: 'lineCoverages'
    };

    const vehicleCoverages = vehicles.map((vehicle) => {
        // getting vehicle coverage public ids for given vehicle
        const vehCovUniqueIDs = getCoveragesUniqueIDVehicleCoverages(submissionVM, vehicle.fixedId);

        return {
            header: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
            data: vehCovUniqueIDs,
            tableContent: generateClauseData(
                columnData,
                'vehicleCoverages',
                vehicle.fixedId
            ),
            coverageType: 'vehicleCoverages',
            fixedId: vehicle.fixedId
        };
    });

    return [lineCoverages, ...vehicleCoverages];
};

const LOB = 'personalAuto_EA';

function QuotePage(props) {
    const modalApi = useModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        stopSkipping,
        jumpTo,
        steps,
        cancel: onCancel,
        isSkipping
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const translator = useTranslator();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const [quoteIsStale, setQuoteIsStale] = useState(false);
    const [isQuoting, setIsQuoting] = useState(false);
    const [fieldsChangedOnCoveragePage, setFieldsChangedOnCoveragePage] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { authHeader, authUserData } = useAuthentication();
    const isAdditionalInfoComplete = useRef(false);
    const {
        onValidate,
        disregardFieldValidation,
        isComponentValid
    } = useValidation('QuotePage');
    const {
        hasUWIssuesOfType,
        showUnderwritingIssuesPopup,
        hasUWIssues
    } = useUWIssueUtil(
        submissionVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const viewModelService = useContext(ViewModelServiceContext);
    const [prefillCompleted, setPrefillCompleted] = useState(true);
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [selectedVehicleCoverages, setSelectedVehicleCoverages] = useState({});
    const [isBuying, setIsBuying] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    // state used to toggle side by side vs selected version
    const [isSideBySideView, updateIsSideBySideView] = useState(false);
    const [showMaxVersionMessage, setShowMaxVersionMessage] = useState(false);
    const [showMinVersionMessage, setShowMinVersionMessage] = useState(false);
    const [showDeleteVersionMessage, setShowDeleteVersionMessage] = useState(false);
    const [selectedVersionName, setSelectedVersionName] = useState(false);
    const [showQuoteProposalError, setShowQuoteProposalError] = useState(false);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);

    const {
        modifyDates
    } = usePriorPolicyUpdateUtil(submissionVM.lobData.personalAuto_EA.priorPolicyUpdates);
    const { additionalInfoIsProvided } = useAdditionalInfoUtil(submissionVM);

    // fetch quoteProposal for side by side
    const fetchQuoteProposalForSBS = useCallback(() => {
        setQuoteProposalCompleted(false);
        QuoteProposalService.retrieveSideBySideQuoteProposalURL(
            get(submissionVM, 'quoteID.value'),
            authHeader
        ).then((response) => {
            setQuoteProposalCompleted(true);
            setQuoteProposalLink(`${response.sideBySideQuoteProposalURL}`);
        }).catch(() => {
            setIsQuoteProposalFailed(true);
            updateWizardData(submissionVM);
        });
    }, [authHeader, submissionVM, updateWizardData]);

    const {
        enterSideBySide,
        onUpdateQuoteVersionName,
        onAddQuote,
        onSetSelectedVersion,
        onWithdrawSideBySideVersion,
        onRecalculateSideBySide,
    } = useSideBySideQuoteUtil(
        authHeader,
        submissionVM,
        updateWizardData,
        setIsQuoting,
        updateWizardSnapshot,
        setShowMaxVersionMessage,
        setShowMinVersionMessage,
        setShowDeleteVersionMessage,
        updateIsSideBySideView,
        fetchQuoteProposalForSBS,
        setSelectedVersionName,
        getCustomQuote,
        setShowQuoteProposalError,
        'personalAuto_EA'
    );

    const {
        structuredModifiers,
        getStatePACSurcharge
    } = useModifierUtil(submissionVM);
    const modifiers = structuredModifiers;

    // Quote type is no longer set on leaving this page
    //   so will check to see if a verified step exists
    const isFullQuote = steps.find((step) => step.id === 'EAPaymentDetailsPage');

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(
                submissionVM,
                lobPath,
                quotePath,
                lobName,
                true
            );

            return CustomQuoteService.updateCustomQuoteCoverages(
                customQuote,
                authHeader
            ).then((response) => {
                const updatedClauses = get(response, `coverages.${lobName}`);
                const newSubmissionVM = viewModelService.clone(submissionVM);

                // Update the offering status to stale
                setStaleQuoteBranchCode(response.quote.branchCode);

                // Update local offering with new one from xcenter
                if (updatedClauses.vehicleCoverages) {
                    set(submissionVM, `${lobPath}.coverages.vehicleCoverages`, updatedClauses.vehicleCoverages);
                }

                // Custom quote not always mapping back line coverages
                if (updatedClauses.lineCoverages) {
                    set(submissionVM, `${lobPath}.coverages.lineCoverages`, updatedClauses.lineCoverages);
                }

                // Update local quote status with new one from xcenter
                const status = get(response, 'quote.status', 'Draft');

                set(submissionVM, `${quotePath}.status`, status);
                // Update local errorsAndWarnings with new one from xcenter
                set(submissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
                // Update premium with new one from xcenter
                set(submissionVM, `${quotePath}.premium`, response.quote.premium);

                const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                    submissionVM,
                    newSubmissionVM,
                    `${lobPath}.coverages.lineCoverages`
                );
                const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                    submissionVM,
                    newSubmissionVM,
                    `${lobPath}.coverages.vehicleCoverages`
                );
                const allRemovedFields = [
                    ...removedFieldsFromBaseCoverages,
                    ...removedFieldsFromAdditionalCoverages
                ];

                setQuoteIsStale(true);
                disregardFieldValidation(allRemovedFields);
                set(submissionVM, 'errorsAndWarnings', response.errorsAndWarnings);
                set(submissionVM, 'baseData.exceptions_Ext', response.exceptions_Ext);
                updateWizardData(submissionVM);
                updateWizardSnapshot(submissionVM);
            });
        },
        [
            CustomQuoteService, authHeader,
            disregardFieldValidation,
            submissionVM, updateWizardData,
            updateWizardSnapshot, viewModelService
        ]
    );

    /**
     * Below function gets called when we copy coverages from one vehicle to another vehicle/s
     * updateDraftSubmissionWithOptions api updated coverages forcefully
     */
    const onClauseChangeForceUpdate = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(
                submissionVM,
                lobPath,
                quotePath,
                lobName,
                true
            );
            const updateOptions = {
                fullCoverageUpdate: true,
                useGenericValueAsString: true,
                shouldSyncCoveragesAfterUpdate: true,
                forceUpdate: true,
            }
            const quoteData = get(submissionVM, 'value.quoteData');

            return Promise.resolve(
                LoadSaveService.updateDraftSubmissionWithOptions(submissionVM.value, updateOptions, authHeader)
                    .then((response) => {
                        setStaleQuoteBranchCode(customQuote.quote.branchCode);
                        setQuoteIsStale(true);
                        // resetting quote data as updateDraftSubmission call response does not return quote data
                        set(response, 'quoteData', quoteData);
                        set(submissionVM, 'value', response);
                        updateWizardData(submissionVM);
                        updateWizardSnapshot(submissionVM);
                    })
            )
        },
        [LoadSaveService, authHeader, submissionVM, updateWizardData, updateWizardSnapshot]
    );

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = get(submissionVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(submissionVM, 'lobData.personalAuto_EA.paperlessInd.value');
        const paperlessEmail = get(submissionVM, 'lobData.personalAuto_EA.paperlessEmail.value');

        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [submissionVM, isPaperlessEmailUpdated]);

    const checkuwIssues = useCallback((newestSubmission) => {
        if (hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease', 'Rejected'])) {
            setQuoteIsStale(true);
            showUnderwritingIssuesPopup(newestSubmission);
        }
    }, [hasUWIssuesOfType, showUnderwritingIssuesPopup]);

    useEffect(() => {
        // eslint-disable-next-line no-warning-comments
        // FIXME: Weird skipping issue from GW wizard platform code
        stopSkipping();
        // when the onNext is not called, because page is valid for riskAnalysis
        // we need to modify dates on quote page
        modifyDates(submissionVM);

        if (submissionVM.value.quoteType === 'Full' && hasUWIssues()) {
            // this will show UW issues popup with all the UW Issue types
            showUnderwritingIssuesPopup(submissionVM);
        } else if (submissionVM.value.quoteType === 'Quick') {
            // in quick quote flow we need to show uwIssues popup if the type is
            // 'BlocksQuote', 'BlocksQuoteRelease', 'Rejected'
            // internally checkuwIssues method will check for UWIssues,
            // so no need to add condition hasUWIssues in else if
            checkuwIssues(submissionVM);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const offering = get(submissionVM, `${lobPath}.value`);

            setStaleQuoteBranchCode(offering.branchCode);

            return onClauseChange(basePath, lobPath, quotePath);
        },
        [onClauseChange, submissionVM]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            // IAP-2626 : SC Auto - When UM and/or UIMBI Rejected, PE needs to send an stacking indicator to PC
            CoverageUtil.changeStackingTermValueorUMBIAndUIMBICoverages(submissionVM, changedPath, value, updateWizardData);
            updateWizardData(
                e1pClausesUtil.setClauseValue(submissionVM, value, changedPath)
            );
        },
        [submissionVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            setShowDeleteVersionMessage(false);
            setShowMaxVersionMessage(false);
            setShowMinVersionMessage(false);
            changeSubmission(value, changedPath);

            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const coverageListPath = ClausesUtil.getObjectPathFromChangedPath(basePath);
            const selectedVehicleCoverage = get(
                submissionVM, ClausesUtil.getObjectPathFromChangedPath(coverageListPath), {}
            );
            const vehicleIndex = findIndex(
                get(submissionVM, `${lobPath}.coverages.vehicleCoverages.value`, {}),
                (vehicle) => vehicle.fixedId === selectedVehicleCoverage.fixedId
            );
            const selectedCoverage = get(submissionVM, basePath, {});

            const keys = Object.keys(EATravelPackagesConfig);

            // If selected coverage change is for travel package
            if (keys.includes(selectedCoverage.codeIdentifier)) {
                const coverages = get(submissionVM, coverageListPath, {});

                // Save corresponding selected values related to package
                if (value) {
                    const newSelectedVehicleCoverages = {};

                    EATravelPackagesConfig[selectedCoverage.codeIdentifier].forEach((key) => {
                        const coverage = find(coverages, (cov) => cov.codeIdentifier === key);

                        if (coverage) {
                            set(newSelectedVehicleCoverages, [vehicleIndex, key, 'selected'], coverage.selected);
                            set(newSelectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm'], get(coverage, 'terms[0].chosenTerm'));
                        }
                    });
                    setSelectedVehicleCoverages(
                        { selectedVehicleCoverages, ...newSelectedVehicleCoverages }
                    );

                    return syncCoverages(value, changedPath, lobPath, quotePath);
                }

                // Sync new change and check for previously selected values
                return syncCoverages(value, changedPath, lobPath, quotePath).then(() => {
                    let isChosenTerm = false;

                    Object.keys(get(selectedVehicleCoverages, vehicleIndex, {})).forEach((key) => {
                        const index = findIndex(coverages, (cov) => cov.codeIdentifier === key);
                        const selected = get(selectedVehicleCoverages, [vehicleIndex, key, 'selected'], false);
                        const chosenTerm = get(selectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm']);

                        // Make selection of previously selected items
                        if (index >= 0 && selected) {
                            changeSubmission(true, `${lobPath}.coverages.vehicleCoverages.children[${vehicleIndex}].coverages.children[${index}].selected`);

                            // If there is any updated term (dropdown value) in previous value
                            if (chosenTerm) {
                                isChosenTerm = true;
                            }
                        }
                    });

                    // Update chosen Terms if exists
                    if (isChosenTerm) {
                        return syncCoverages(value, changedPath, lobPath, quotePath).then(() => {
                            Object.keys(
                                get(selectedVehicleCoverages, vehicleIndex, {})
                            ).forEach((key) => {
                                const index = findIndex(coverages,
                                    (cov) => cov.codeIdentifier === key);
                                const selected = get(selectedVehicleCoverages, [vehicleIndex, key, 'selected'], false);
                                const chosenTerm = get(selectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm']);

                                if (index >= 0 && selected) {
                                    if (chosenTerm) {
                                        changeSubmission(chosenTerm, `lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[${vehicleIndex}].coverages.children[${index}].terms.children[0].chosenTerm`);
                                    }
                                }
                            });
                        });
                    }

                    // Remove previously selected values
                    const newSelectedVehicleCoverages = {};

                    set(newSelectedVehicleCoverages, [vehicleIndex], {});
                    setSelectedVehicleCoverages(
                        { selectedVehicleCoverages, ...newSelectedVehicleCoverages }
                    );

                    return syncCoverages(value, changedPath, lobPath, quotePath);
                });
            }

            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeSubmission, selectedVehicleCoverages, submissionVM, syncCoverages]
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    const recalculate = useCallback(
        async (lobPath, quotePath) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            updateIsPageSubmitted(false);
            setIsQuoting(true);

            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(
                submissionVM,
                lobPath,
                quotePath,
                lobName
            );

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (isUndefined(get(submissionVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value'))
                && !!get(submissionVM, 'lobData.personalAuto_EA.paperlessEmail.value')) {
                set(submissionVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value',
                    get(submissionVM, 'lobData.personalAuto_EA.paperlessEmail.value'));
            }

            return LoadSaveService
                .saveAndQuoteSubmission(submissionVM.value, authHeader)
                .then((response) => {
                    setStaleQuoteBranchCode(undefined);
                    setFieldsChangedOnCoveragePage(false);

                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    const quoteData = get(newSubmissionVM, 'quoteData.value');
                    const respQuoteData = get(response, 'quoteData');

                    if (respQuoteData === undefined) {
                        set(response, 'quoteData', quoteData);
                    }

                    set(newSubmissionVM, 'value', response);
                    setQuoteIsStale(false);
                    QuoteProposalUtil.fetchQuoteProposal(
                        submissionVM,
                        setQuoteProposalCompleted,
                        setQuoteProposalLink,
                        authHeader,
                        setIsQuoteProposalFailed
                    );
                    updateWizardData(newSubmissionVM);
                    updateWizardSnapshot(newSubmissionVM);
                    checkuwIssues(newSubmissionVM);
                    // Recalculate should clear this message
                    setShowQuoteProposalError(false);

                    return response;
                })
                .catch(() => {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: 'Something went wrong in recalculate operation',
                        message: ''
                    });

                    const lobOfferingPath = 'lobData.personalAuto_EA.offerings.children[0]';
                    const offering = get(submissionVM, `${lobOfferingPath}.value`);

                    setStaleQuoteBranchCode(offering.branchCode);
                    setFieldsChangedOnCoveragePage(true);

                    return customQuote;
                })
                .finally(() => {
                    setIsQuoting(false);
                });
        },
        [
            isComponentValid, submissionVM, LoadSaveService, authHeader,
            viewModelService, updateWizardData,
            updateWizardSnapshot, checkuwIssues, modalApi]
    );

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const isCompraterFlow = useMemo(() => {
        if (get(submissionVM, 'baseData.quoteSource_Ext.sourceType')) {
            const isSourceTypeCompRater = get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'comprater';

            set(submissionVM, 'quoteType_Ext.value', get(submissionVM.value, 'quoteType'));

            // Comprater payload should be passing in quotetype here
            const quoteType = get(submissionVM, 'quoteType_Ext.value.code');

            return (isSourceTypeCompRater && quoteType === 'Quick');
        }

        return false;
    }, [submissionVM]);


    const buyNow = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            let isUnVerifiedBILimitEligible = false;
            let unVerifiedUWCededInd = false;

            if (submissionVM.value.quoteType === 'Quick') {
                // check the split EA_BodilyInjury limits are greater than or equal 250/500
                const { selectedOfferingIndex } = OfferingUtil.getSelectedOffering(submissionVM, 'personalAuto_EA');
                const selectedLineCoverage = get(submissionVM.lobData.personalAuto_EA.offerings, ['value', selectedOfferingIndex, 'coverages', 'lineCoverages'], []);
                const bodilyInjury = find(selectedLineCoverage, { codeIdentifier: 'EA_BodilyInjury' });

                isUnVerifiedBILimitEligible = !!find(get(bodilyInjury, ['terms'], []), (term) => parseInt(term.chosenTerm.split('/')[0]) >= 250 
                    && parseInt(term.chosenTerm.split('/')[1]) >= 500 && term.type === 'PackageEA_BodilyInjury_LimitType');

                // Get the UWCededIndicator from Unverified quote and send it to EA reports page
                // It helps to compare the UWCededIndicator value between unverified and verified quotes.
                unVerifiedUWCededInd = get(submissionVM, 'lobData.personalAuto_EA.uwcededIndicator.value', false)
            }

            setIsBuying(true);
            set(submissionVM, 'quoteType_Ext.value', get(submissionVM.value, 'quoteType'));

            let convertedFromQuickToFull = get(submissionVM, 'convertedFromQuickToFull');
            const isSourceTypeCompRater = get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'comprater';

            if ((isSourceTypeCompRater || convertedFromQuickToFull === undefined) && submissionVM.quoteType_Ext.value.code === 'Quick') {
                convertedFromQuickToFull = true;
            }

            if (submissionVM.baseData.quoteSource_Ext.sourceType?.value?.code === 'comprater') {
                // E1PAP1PC-10604 making this api call as a short to mid term solution.
                // This will call out any validation errors on a comprater
                // transaction before going to the full flow with additional validation
                if (submissionVM.value.quoteType === 'Quick') {
                    const validatedQuoteDataDto = await LoadSaveService.preValidate(
                        submissionVM.value,
                        authHeader
                    );

                    if (validatedQuoteDataDto?.errorsAndWarnings) {
                        set(submissionVM, 'errorsAndWarnings', validatedQuoteDataDto.errorsAndWarnings);
                    }

                    setPrefillCompleted(false);

                    // order prefill stores the prefill data on the backend
                    let lobData;

                    try {
                        lobData = await PrefillService.loadPrefill(submissionVM.value, authHeader);

                        if (lobData) {
                            set(submissionVM, `lobData[${LOB}].value`, lobData);
                        }
                    } catch (error) {
                        set(
                            submissionVM,
                            'value.baseData.exceptions_Ext',
                            [{ errorMessage: translator(e1pCommonMessages.genericErrorText) }]
                        );
                        updateWizardData(submissionVM);
                        setShowDeleteVersionMessage(true);

                        return false;
                    } finally {
                        setPrefillCompleted(true);
                        setIsBuying(false);
                    }

                }
            }

            set(submissionVM, 'convertedFromQuickToFull', convertedFromQuickToFull);
            // set varaible isUnVerifiedBILimitEligible
            // It is used in EAReports results screen to display reduced BI Limit information message
            set(submissionVM, 'isUnVerifiedBILimitEligible', isUnVerifiedBILimitEligible);
            // set varaible unVerifiedUWCededInd
            // It is used in EAReports results screen to display PhysicalDamage coverage Available and unavailable message
            set(submissionVM, 'unVerifiedUWCededInd', unVerifiedUWCededInd);
            updateWizardData(submissionVM);

            // running it here after prefill call gets done
            const isAdditionalInfoProvided = await additionalInfoIsProvided(authHeader, updateWizardData);

            isAdditionalInfoComplete.current = isAdditionalInfoProvided;
            setIsBuying(false);

            return true;
        },
        [isComponentValid, submissionVM, additionalInfoIsProvided, authHeader, updateWizardData, LoadSaveService, translator]
    );

    const handleAcknowledgementsValueChange = useCallback((value) => {
        set(submissionVM, `lobData[${LOB}].acknowledgements`, value);
        updateWizardData(submissionVM);
    }, [updateWizardData, submissionVM]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (!prefillCompleted) {
            loadingMessage = translator(messages.loadingPrefillDataMessage);
        } else if (isQuoting) {
            loadingMessage = translator(messages.ratingYourPolicyMessage);
        } else if (isBuying) {
            loadingMessage = translator(messages.creatingYourOfferingMessage);
        }

        return loadingMessage;
    }, [prefillCompleted, isQuoting, isBuying, translator]);


    useEffect(() => {
        // on mount get quote proposal
        if(!isSkipping){
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openDocument = useCallback(
        (e, screenSource) => {
            e.preventDefault();

            switch (screenSource) {
                case 'MAIN':
                    if (!quoteIsStale && !fieldsChangedOnCoveragePage) {
                        window.open(quoteProposalLink, '_blank');

                        return true;
                    }

                    setShowQuoteProposalError(true);
                    break;
                case 'SIDE_BY_SIDE':
                    const quoteOfferingPath = 'quoteData.offeredQuotes';
                    const quoteOfferings = get(submissionVM, `${quoteOfferingPath}.value`, []);
                    const unquotedPeriod = quoteOfferings.find((offering) => offering.status !== 'Quoted');

                    if (!unquotedPeriod) {
                        window.open(quoteProposalLink, '_blank');

                        return true;
                    }

                    setShowQuoteProposalError(true);
                    break;
                default:
                    break;
            }
        }, [submissionVM, quoteIsStale, fieldsChangedOnCoveragePage, quoteProposalLink]
    );

    // When policy term changes, we need to get a new doc url
    useEffect(() => {
        if (submissionVM.policyTermChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            submissionVM.policyTermChanged = false;
        }
    }, [authHeader, submissionVM, submissionVM.policyTermChanged]);

    // When payment option changes, we need to get a new doc url
    useEffect(() => {
        if (submissionVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            submissionVM.paymentOptionChanged = false;
        }
    }, [authHeader, submissionVM, submissionVM.paymentOptionChanged]);

    const generateOverrides = useCallback(() => {
        const columnData = generateColumnData(submissionVM);
        const { quoteID } = submissionVM;

        const accidentForgivenessApplied = () => {
            if (
                submissionVM.lobData.personalAuto_EA
                    .accidentForgivenessStatuses.children.length !== 0
                && submissionVM.lobData.personalAuto_EA.accidentForgivenessStatuses.children[0].code.value === 'Earned Accident Forgiveness'
            ) {
                return true;
            }

            return false;
        };
        const alwaysShowSurchargeComponentStateArray = ['SC'];

        const policyState = get(
            submissionVM,
            'baseData.policyAddress.state.value.code',
            get(submissionVM, 'policyAddress.state.value.code')
        );

        const initialOverrides = {
            quotePageLoadingIndicator: {
                loaded: prefillCompleted && !isBuying && !isQuoting,
                text: getLoadingIndicatorMessage
            },
            quotePageContainer: {
                visible: prefillCompleted && !isBuying && !isQuoting
            },
            quoteTable: {
                columnData,
                tableData: generateTableData(submissionVM, columnData, translator),
                modifiers,
                quoteID: quoteID.value,
                submissionVM,
                viewModelService,
                updateWizardData,
                authHeader,
                setFieldsChangedOnCoveragePage,
                fieldsChangedOnCoveragePage,
                visible: !isBuying && !isSideBySideView,
                isComprater: isCompraterFlow,
                onViewQuoteProposal: openDocument,
                isQuoteProposalReady: quoteProposalCompleted,
                underwritingIssues: get(submissionVM, 'errorsAndWarnings.underwritingIssues.value') === undefined ? [] : get(submissionVM, 'errorsAndWarnings.underwritingIssues.value'),
                onCancel,
                authUserData,
                isPageSubmitted,
                updateIsPageSubmitted,
                isSideBySideView,
                isSurchargeLineCoverageAvailable: getStatePACSurcharge().length > 0,
                alwaysShowSurchargeComponent: includes(alwaysShowSurchargeComponentStateArray, policyState),
                setIsPaperlessEmailUpdated,
                onValidate,
                opCo
            },
            e1pAcknowledgementComponent: {
                acknowledgements: get(submissionVM, `lobData[${LOB}].acknowledgements`),
                policyState: {
                    code: get(submissionVM, 'baseData.policyAddress.state.value.code'),
                    name: translator({ id: get(submissionVM, 'baseData.policyAddress.state.value.name') })
                },
                onValueChange: handleAcknowledgementsValueChange,
                visible: isCompraterFlow && !isSideBySideView,
                lob: LOB
            },
            enterSideBySide: {
                visible: !isSideBySideView,
                content: !submissionVM.sideBySide_Ext.value ? translator(e1pCommonMessages.enterSideBySideButtonText) : translator(e1pCommonMessages.viewSideBySideButtonText),
                icon: !submissionVM.sideBySide_Ext.value ? "mi-add" : undefined,
                onClick: (event) => enterSideBySide(event)
            },
            sideBySideContent: {
                visible: isSideBySideView,
                backButtonFunction: (value) => {
                    updateIsSideBySideView(value);
                    // fecth quote proposal for selected quote, since we are coming back to coverage page
                    QuoteProposalUtil.fetchQuoteProposal(
                        submissionVM,
                        setQuoteProposalCompleted,
                        setQuoteProposalLink,
                        authHeader,
                        setIsQuoteProposalFailed
                    );
                },
                addVersionFunction: () => {
                    /**
                     * IAP-3206, disable Quote Proposal button, so that user should recalculate, 
                     * to get the latest details on quote proposal document
                     */
                    setQuoteProposalCompleted(false);
                    onAddQuote();
                },
                lobOfferings: get(submissionVM, 'lobData.personalAuto_EA.offerings.value'),
                quoteDataOfferings: get(submissionVM, 'quoteData.offeredQuotes.value'),
                updateQdd: () => { updateWizardData(submissionVM) },
                setNewQdd: (newQdd) => {
                    set(submissionVM, 'value', newQdd);
                    updateWizardData(submissionVM);
                },
                updateQuoteVersionName: (versionId, versionName) => { onUpdateQuoteVersionName(submissionVM.value, versionId, versionName); },
                setSelectedVersion: (versionId) => { onSetSelectedVersion(versionId); },
                onRecalculateSideBySide: () => {
                    if (!isComponentValid) {
                        updateIsPageSubmitted(true);
                        window.scrollTo(0, 0);

                        return false;
                    }

                    return onRecalculateSideBySide();
                },
                onWithdrawSideBySideVersion: (versionId) => {
                    /**
                     * IAP-3206, disable Quote Proposal button, so that user should recalculate, 
                     * to get the latest details on quote proposal document
                     */
                    setQuoteProposalCompleted(false);
                    onWithdrawSideBySideVersion(versionId);
                },
                onViewQuoteProposal: openDocument,
                changeSubmissionAndSync,
                changeSubmission,
                syncCoverages,
                onValidate,
                policyState: get(submissionVM, 'baseData.policyAddress.state.value.code'),
                hasExtendedAttributeEditability: authUserData?.permissions_Ext.includes('extendedattributeeditability_ext'),
                isQuoteProposalReady: quoteProposalCompleted,
                vehicles: get(
                    submissionVM,
                    'lobData.personalAuto_EA.coverables.vehicles.value'
                ),
                showErrors: isPageSubmitted
            },
            maxVersionsMessageDiv: {
                // never should be visible on the main coverage screen
                visible: showMaxVersionMessage && isSideBySideView
            },
            minVersionsMessageDiv: {
                // never should be visible on the main coverage screen
                visible: showMinVersionMessage && isSideBySideView
            },
            deleteVersionsMessage: {
                message: translator(e1pCommonMessages.deleteSelectedVersionError, {
                    versionName: selectedVersionName
                })
            },
            deleteVersionsMessageDiv: {
                visible: showDeleteVersionMessage && isSideBySideView
            },
            quoteProposalValidationDiv: {
                visible: !isQuoting && showQuoteProposalError
            },
            accidentForgivenessMessageDiv: {
                visible: submissionVM.value.quoteType === 'Full' && accidentForgivenessApplied()
            },
            paperlessEmailChangedMessageDiv: {
                visible: showPaperlessEmailMessage()
            },
            quoteProposalFailureErrorDiv: {
                visible: !isQuoting && isQuoteProposalFailed
            },
        };

        return { ...initialOverrides};
    }, [
        submissionVM, prefillCompleted, isBuying, isQuoting, getLoadingIndicatorMessage,
        translator, modifiers, viewModelService, updateWizardData, authHeader,
        fieldsChangedOnCoveragePage, isSideBySideView, isCompraterFlow, openDocument,
        quoteProposalCompleted, onCancel, authUserData, isPageSubmitted, getStatePACSurcharge,
        onValidate, handleAcknowledgementsValueChange, changeSubmissionAndSync, changeSubmission,
        syncCoverages, showMaxVersionMessage, showMinVersionMessage, showDeleteVersionMessage,
        selectedVersionName, showQuoteProposalError, showPaperlessEmailMessage, enterSideBySide,
        onAddQuote, onUpdateQuoteVersionName, onSetSelectedVersion, isComponentValid,
        onRecalculateSideBySide, onWithdrawSideBySideVersion, isQuoteProposalFailed, opCo
    ]);

    useEffect(() => {
        const offerings = get(submissionVM, 'quoteData.offeredQuotes.value');
        const draftOffers = filter(offerings, ['status', 'Draft']);
        const draftBranchCodes = map(draftOffers, 'branchCode');

        setStaleQuoteBranchCode(draftBranchCodes);
        // Above logic only needs to run once when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);


    const checkDisable = useCallback(() => {
        if (submissionVM.value.lobData.personalAuto_EA.acknowledgements === undefined) {
            return false;
        }

        return submissionVM.value.lobData.personalAuto_EA.acknowledgements
            .some((acknowledgement) => acknowledgement.acknowledgeAnswerType === 'reject');
    }, [submissionVM]);

    return (
        <WizardPage
            showNext={false}
            showPrevious={false}
            showCancel={false}
            skipWhen={() => isFullQuote && get(submissionVM, 'baseData.periodStatus.value.code') === 'Quoted'}
            finish={!isFullQuote && isAdditionalInfoComplete.current}
            isPageSubmittedWithErrors={isPageSubmitted}
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveCallbackMap: {
                        onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(
                            (buyNowResp) => {
                                if (buyNowResp) {
                                    return onNext();
                                }
                            }
                        ),
                        onStaleQuoteBranchCode,
                        onRecalculate: recalculate,
                        onChangeSubmissionAndSync: changeSubmissionAndSync,
                        onChangeSubmission: changeSubmission,
                        onSyncCoverages: syncCoverages,
                        onClauseChangeForceUpdate,
                        onPrint: handlePrint,
                        onDisableNextForTable: checkDisable
                    }
                };

                if (!submissionVM.quoteData.value?.offeredQuotes) {
                    return <h1>No coverage data</h1>;
                }

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={generateOverrides()}
                        onModelChange={updateWizardData}
                        callbackMap={resolvers.resolveCallbackMap}
                        onValidationChange={onValidate}
                    />
                );
            }}
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
