
import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty
} from 'lodash';
import {
    useModal,
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useLandingPageUtil } from 'e1p-capability-hooks';
import { AutoLossService } from 'e1p-capability-gateway';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import metadata from '../RiskAnalysisPage.metadata.json5';
import messages from '../RiskAnalysisPage.messages';
import requiredMetadata from '../RiskAnalysisPage.requiredness';

const LOB = 'personalAuto_EA';

function ViewRiskAnalysisPage(props) {
    const modalApi = useModal();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        authUserData
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [dataForComponent, updateDataForComponent] = useState({});
    const { authHeader } = useAuthentication();
    const [isRetrievingReports, setIsRetrievingReports] = useState(false);
    const policyChangeSource = _get(policyChangeVM, 'value.policyChangeSource', '');
    const policyChangeReason = _get(policyChangeVM, 'value.policyChangeReason', '');
    const [visibleFields, updateVisibleFields] = useState([]);
    const policyState = _get(policyChangeVM, 'baseData.policyAddress.state.value.code');
    const {opCo} = useContext(AmfamOktaTokenContext);
    const {
        onValidate,
        disregardFieldValidation
    } = useValidation('ViewRiskAnalysisPage');

    const {
        getDefaultTab
    } = useLandingPageUtil();

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(policyChangeVM, 'lobData.personalAuto_EA.premiumStability.value') !== undefined;

    const defaultTabBasedOnReason = useMemo(
        () => getDefaultTab(LOB, policyChangeSource, policyChangeReason),
        [getDefaultTab, policyChangeSource, policyChangeReason]
    );

    const saveAndQuote = useCallback(() => policyChangeVM, [policyChangeVM]);

    const getDefaultActiveTab = useMemo(
        () =>
            defaultTabBasedOnReason === false
                ? 'priorCarrierTabID'
                : defaultTabBasedOnReason,
        [defaultTabBasedOnReason]
    );

    const createAutoLossVM = useCallback((vmObject, dtoName) => {
        const vmNode = viewModelService.create(
            vmObject,
            'pc',
            dtoName
        );

        return vmNode;
    }, [viewModelService]);

    useEffect(() => {
        const initialVisibleFields = ['declareLossStatementThreeYears', 'declareLossStatementFiveYears']; // Fields to look up by partner/state

        updateVisibleFields(
            isRequired(initialVisibleFields, requiredMetadata, policyState, opCo)
        );
        // User can't able to change policy state on Risk Analysis Page,
        // hence we need to run this useEffect only single time.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opCo]);

    useEffect(() => {
        if (!isSkipping) {
	        setIsRetrievingReports(true);

            const AutoLossRecordsPromise = AutoLossService.loadAutoLosses(
                _get(policyChangeVM, 'jobID.value'),
                authHeader
            );

            AutoLossRecordsPromise
                .then((response) => {
                    const autoLossRecords = [];

                    response.autoLossRecords.filter((record)=>record.source.sourceType !== 'Self-Declared').forEach((result) => {
                        const recordVM = createAutoLossVM(
                            result,
                            'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoLossRecordDTO'
                        );

                        autoLossRecords.push(recordVM.value);
                    });

                    const autoViolationRecords = [];

                    response.autoViolationRecords.filter((record)=>record.source.sourceType !== 'Self-Declared').forEach((result) => {
                        const violationVM = createAutoLossVM(
                            result,
                            'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoViolationRecordDTO'
                        );

                        _set(
                            violationVM,
                            'value.operatorDisplayName',
                            `${violationVM.value.assignment.firstName} ${violationVM.value.assignment.lastName}`
                        );
                        autoViolationRecords.push(violationVM.value);
                    });

                    _set(dataForComponent, 'lobData.personalAuto_EA.autoLossRecords.value', autoLossRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.autoViolationRecords.value', autoViolationRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.mvrLicenseStatusRecords.value', response.mvrlicenseStatus);
                    _set(dataForComponent, 'lobData.personalAuto_EA.orderingInfo.value', response.orderingInfo);
                    updateDataForComponent(dataForComponent);
                })
                .catch(() => {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: messages.reportsErrorTitle,
                        message: messages.reportsErrorMessage
                    });
                })
                .finally(() => {
                    setIsRetrievingReports(false);
                });
        }
        // No array dependencies needed in this hook.
        // The logic of initializing losses data needs to be executed only once
        // when landing into losses page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isRetrievingReports) {
            loadingMessage = translator(messages.loading);
        } else if (isSkipping) {
            loadingMessage = translator(messages.loadingNextPageMessage);
        }

        return loadingMessage;
    }, [isRetrievingReports, isSkipping, translator]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        tabs: {
            defaultActiveTab: getDefaultActiveTab
        },
        riskAnalysisPageLoadingIndicator: {
            loaded: true,
            text: getLoadingIndicatorMessage
        },
        riskAnalysisPageContainer: {
            visible: true
        },
        EAPriorCarrierComponentId: {
            submissionVM: policyChangeVM,
            disregardFieldValidation,
            viewOnlyMode: true
        },
        EALossAndViolationComponentId: {
            lobDataModel: policyChangeVM.lobData.personalAuto_EA,
            onModelChange: () => updateWizardData(policyChangeVM),
            viewModelService,
            disregardFieldValidation,
            operators: policyChangeVM.lobData.personalAuto_EA.coverables.drivers?.value?.map((driver) => ({
                    publicId: driver.publicID,
                    displayName: driver.person.displayName
                })),
            viewOnlyMode: true,
            policyState,
            authUserData,
            isVerified: true
        },
        EARiskAnalysisMiscComponentId: {
            submissionVM: policyChangeVM,
            viewOnlyMode: true,
            isSkipping,
            onValidate,
            shouldShowCedingField: policyState === 'NC'
        },
        billingMiscellaneousReportComponent: {
            visible: authUserData?.permissions_Ext.includes('canviewbillingreport'),
            data: _get(policyChangeVM,'value.baseData.cancelActivities_Ext',[])
        },
        diminishingAvailableAmount: {
            labelPosition: 'left'
        },
        diminishingAvailableAmountLatest: {
            labelPosition: 'left'
        },
        EACreditReportsComponentId: {
            submissionVM: policyChangeVM,
            creditReportResponse: () => {},
            setCreditReportResponse: () => {},
            updateWizardData,
            lineOfBusiness: policyChangeVM.lobData.personalAuto_EA,
            viewOnlyMode: true,
            lobName: 'personalAuto_EA',
            authUserData,
            id: 'EACreditReportsComponentId',
            onValidate,
            setOverlappingException: () => {}
        },
        vehicleReportTabID: {
            viewOnlyMode: true
        },
        lossesAndViolationID: {
            visible: !_isEmpty(dataForComponent),
            lossesAndViolations: dataForComponent,
            viewOnlyMode: true,
            drivers: policyChangeVM.lobData.personalAuto_EA.coverables.drivers,
            policyState
        },
        EAUWIssuesComponentId: {
            submissionVM: policyChangeVM,
            updateWizardData,
            authHeader,
            viewOnlyMode: true
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(policyChangeVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            showErrors: false,
            viewOnlyMode: true
        },
        declareStatementThreeYears: {
            visible: visibleFields.includes('declareLossStatementThreeYears')
        },
        declareStatementFiveYears: {
            visible: visibleFields.includes('declareLossStatementFiveYears')
        },
        noMiscReportResultsMessageId: {
            visible: (() => 
                // If misc report content is empty 
                 !_get(document.getElementById('miscellaneousReportsBodyId'), 'innerText') 
                    && !_get(document.getElementById('billingMiscellaneousReportComponent'), 'innerText')
            )()
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <WizardPage
            onNext={saveAndQuote}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewRiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewRiskAnalysisPage);
