const overrides = {
    field: {
        applicantsResideInSameStateInd: {
            partner: {
                MSA: {
                    states: ['NH']
                }
            }
        },
        isUmbrellaAssociated: {
            partner: {
                MSA: {
                    states: ['NC']
                }
            }
        }
    }
};
export default overrides;
