import _ from 'lodash';

/**
 * Helper function to find out available values from config data 
 * for given dtoPath and dtoField 
 * @param {Object} configData 
 * @param {String} dtoPath 
 * @param {String} dtoField 
 * @returns {[Object]} returns list of available values (name:'', description: '')
 */
const getAvailableValuesForGivenDTOPathAndDTOField = (configData, dtoPath, dtoField) => {
    let availableValues = [];
    // dtoModel contains list of object, object contains dto path and data attribute(fields on dto and its metadata) for given dto
    const dtoModels = _.get(configData, 'dtomodels', []);
    // We will iterate through all dto models till we find given dto path 
    for (let dtoModelIterator = 0;
        dtoModelIterator < dtoModels.length;
        dtoModelIterator++) {

        if (dtoModels[dtoModelIterator].dtoPath === dtoPath) {
            // As we have found dto path we will look for given dtoField from the dtoModel
            for (let dataAttributeIterator = 0;
                dataAttributeIterator < dtoModels[dtoModelIterator].dataAttributes.length;
                dataAttributeIterator++) {
                // once we finds the dtoFeild on dtoModel we will return available values
                if (dtoModels[dtoModelIterator].dataAttributes[dataAttributeIterator].dtoField === dtoField) {
                    availableValues = dtoModels[dtoModelIterator].dataAttributes[dataAttributeIterator].availableValues;
                    break;
                }
            }
            break;
        }
    }
    return availableValues;
};

/**
 * Helper function to get dtoPath and dtoField from config file
 * @param {String} lookupFieldName 
 * @param {Object} pathConfig
 * @returns {Object} returns dto path and dto field respective to lookup field form json file
 */
const getDTOPathAndDTOFieldName = (lookupFieldName, pathConfig) => {
    const configFeilds = pathConfig[lookupFieldName];
    return { dtoPath: configFeilds.dtoPath, dtoField: configFeilds.dtoField };
}

/**
 * Helper function to get available values for given field and when config data is already retrieved
 * @param {Object} configData 
 * @param {String} fieldName 
 * @param {Object} pathConfig
 * @returns {Object} returns mapped(code: '', name:'') available values
 */
const getMappedAvailableValuesForGivenFieldAndConfigData = (configData, fieldName, pathConfig) => {
    const configFields = getDTOPathAndDTOFieldName(fieldName, pathConfig);
    const availableValues = getAvailableValuesForGivenDTOPathAndDTOField(
        configData,
        configFields.dtoPath,
        configFields.dtoField);
    const mappedValues = availableValues.map((policyDriverRoleType) => ({
        code: policyDriverRoleType.code,
        name: policyDriverRoleType.description
    }));
    return mappedValues;
};

export default {
    getAvailableValuesForGivenDTOPathAndDTOField,
    getMappedAvailableValuesForGivenFieldAndConfigData
};