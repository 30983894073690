/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
    useContext,
    useCallback,
    useState,
    useMemo
} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { ToggleField, useModal } from '@jutro/components';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { UWIssueDetailPopup } from 'e1p-capability-policyjob-react';
import { UnderwritingIssuesService } from 'e1p-capability-gateway';
import { ConsoleHelper } from 'e1p-portals-util-js';
import { commonMessages, euCommonMessages } from 'e1p-platform-translations';
import metadata from './UnderwritingIssues.metadata.json5';
import styles from './UnderwritingIssues.module.scss';
import messages from './UnderwritingIssues.messages';
import UWIssueDetail from '../UWIssueDetail/UWIssueDetail';

import { Button } from '@jutro/legacy/components';

import { Link } from '@jutro/router';

const ALREADY_APPROVED = 'AlreadyApproved';
const NON_BLOCKING = 'NonBlocking';

const DisplayUnderwritingIssues = (props) => {
    const modalApi = useModal();
    const {
        underwritingIssues,
        setunderwritingIssues,
        blockQuote,
        quoteID,
        filterUWIssuesInCustomOffering,
        showActionButtons,
        chargeHighProfileSurcharge,
        setChargeHighProfileSurchargeInd,
        viewOnlyMode,
        hideHighProfileSurcharge
    } = props;

    const [formData, updateFormData] = useState({});
    const [underwritingIssuesLoading, setUnderwritingIssuesLoading] = useState(false);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    const createUWVMNode = useCallback((uwIssue) => {
        const VMNode = viewModelService.create(uwIssue,
            'pc', 'edge.capabilities.policycommon.validation.dto.UWIssueDTO');

        return VMNode;
    }, [viewModelService]);

    const showModal = useCallback(async (uwIssueVM) => {
        const componentProps = {
            title: translator(messages.riskApprovalDetails),
            iconClassType: false,
            showCloseBtn: true,
            showCancelBtn: true,
            uwIssueVM,
            viewModelService
        };
        const result = await modalApi.showModal(<UWIssueDetail {...componentProps} />);

        return result;
    }, [viewModelService, modalApi, translator]);

    const showUWIssueDetailPopup = useCallback(async (uwIssue) => {
        const uwIssueVM = createUWVMNode(uwIssue);
        const componentProps = {
            title: translator(messages.riskApprovalDetails),
            iconClassType: false,
            showCloseBtn: true,
            showCancelBtn: true,
            uwIssueVM,
            viewModelService
        };
        const result = await modalApi.showModal(<UWIssueDetailPopup {...componentProps} />);

        return result;
    }, [viewModelService, modalApi, translator, createUWVMNode]);

    const handleError = useCallback((errorMessage) => {
        modalApi.showAlert({
            status: 'error',
            icon: 'mi-error-outline',
            title: translator(messages.modalError),
            message: errorMessage
        });
    }, [modalApi, translator]);

    const uwIssueActions = useCallback((wrapperObj) => {
        const modifiedVM = wrapperObj.issueVM;

        switch (wrapperObj.functionToCall) {
            case 'reopenUWIssue':
                modifiedVM.currentBlockingPoint = (modifiedVM.currentBlockingPoint === ALREADY_APPROVED
                    && modifiedVM.hasApprovalOrRejection)
                    ? NON_BLOCKING : modifiedVM.currentBlockingPoint;
                setUnderwritingIssuesLoading(true);
                UnderwritingIssuesService
                    .reopenUnderwritingIssues(quoteID, [modifiedVM], authHeader)
                    .then((uwResponse) => {
                        setunderwritingIssues(uwResponse);
                        setUnderwritingIssuesLoading(false);
                    }).catch(() => {
                        handleError(translator(messages.uwIssueReopenError));
                        setUnderwritingIssuesLoading(false);
                    });
                break;
            case 'rejectUWIssue':
                setUnderwritingIssuesLoading(true);
                UnderwritingIssuesService
                    .rejectUnderwritingIssues(quoteID, [modifiedVM], authHeader)
                    .then((uwResponse) => {
                        setunderwritingIssues(uwResponse);
                        setUnderwritingIssuesLoading(false);
                    }).catch(() => {
                        handleError(translator(messages.uwIssueRejectError));
                        setUnderwritingIssuesLoading(false);
                    });
                break;
            case 'approveUWIssue':
                setUnderwritingIssuesLoading(true);
                _.set(modifiedVM, 'approvalDate_Ext', new Date());
                UnderwritingIssuesService
                    .approveUnderwritingIssues(quoteID, [modifiedVM], authHeader)
                    .then((uwResponse) => {
                        setunderwritingIssues(uwResponse);
                        setUnderwritingIssuesLoading(false);
                    }).catch(() => {
                        handleError(translator(messages.uwIssueApproveError));
                        setUnderwritingIssuesLoading(false);
                    });
                break;
            default:
                ConsoleHelper('matching method not found', 'ERROR');
        }
    }, [authHeader, quoteID, setunderwritingIssues, handleError, translator]);

    const triggerUWModal = useCallback((evt, uwIssue) => {
        evt.preventDefault();

        try {
            const uwVM = createUWVMNode(uwIssue);

            showModal(uwVM).then((wrapperObjResp) => {
                const wrapperObj = wrapperObjResp;

                wrapperObj.issueVM = wrapperObj.issueVM.value;
                uwIssueActions(wrapperObj);
            }).catch((error) => ConsoleHelper(`user closed the modal. Error: ${error}`));
        } catch {
            ConsoleHelper('user closed the modal');
        }
    }, [createUWVMNode, showModal, uwIssueActions]);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);

            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
        },
        [formData]
    );

    const getUwIssuesByBlockingPoint = useMemo(() => {
        const blockingUWIssues = underwritingIssues;

        return _.groupBy(blockingUWIssues, (issue) => issue.currentBlockingPoint);
    }, [underwritingIssues]);

    const displayableIssues = useMemo(() => {
        let issues = _.keys(getUwIssuesByBlockingPoint);

        if (filterUWIssuesInCustomOffering) {
            issues = issues.filter((val) => val !== 'CUSTOM');
        }

        return issues;
    }, [filterUWIssuesInCustomOffering, getUwIssuesByBlockingPoint]);

    // This might be required later
    // const hasRejected = useMemo(() => {
    //     return underwritingIssues.some((uwIssue) => uwIssue.currentBlockingPoint === 'Rejected');
    // }, [underwritingIssues]);

    // if (hasRejected) {
    //     history.push('/knockoutpage', { underwritingIssues });
    // }


    // This might be required later
    // const referToUnderwriter = useCallback(() => {
    //     if (!quoteUnderWritingService) {
    //         return;
    //     }
    //     const { notes } = formData;
    //     quoteUnderWritingService.referToUnderwriter(quoteID, notes, authHeader)
    //         .then(() => {
    //             history.push(`/quotes/${quoteID}/summary`);
    //         });
    // }, [authHeader, quoteID, history, formData, quoteUnderWritingService]);

    // const withdrawHandler = useCallback(() => {
    //     if (!quoteUnderWritingService) {
    //         return;
    //     }
    //     quoteUnderWritingService.withdrawJobByJobNumber(quoteID, authHeader)
    //         .then(() => {
    //             history.push(`/quotes/${quoteID}/summary`);
    //         });
    // }, [authHeader, quoteID, history, quoteUnderWritingService]);

    const getBlockingPointDisplayKey = useCallback((blockingPoint) => {
        switch (blockingPoint) {
            case 'BlocksBind':
                return translator(messages.blocksBind);
            case 'BlocksQuote':
                return translator(messages.blocksQuote);
            case 'BlocksQuoteRelease':
                return translator(messages.blocksQuoteRelease);
            case 'NonBlocking':
                return translator(messages.nonBlocking);
            case 'Rejected':
                return translator(messages.rejected);
            case 'AlreadyApproved':
                return translator(messages.alreadyApproved);
            case 'BlocksIssuance':
                return translator(messages.blocksIssuance);
            default:
                return blockingPoint;
        }
    }, [translator]);

    const generateIssuesData = useCallback((issuesByBlockingPoint, blockingPointIndex) => {
        const getUwIssueByBindOrBlock = issuesByBlockingPoint;

        return getUwIssueByBindOrBlock.map((issue, index) => (
                <div key={`uwIssueContainer${blockingPointIndex}${index}`} className={styles.uwIssueBorder}>
                    <div key={`uwIssueBody${blockingPointIndex}${index}`} className={styles.uwIssueBody}>
                        {(showActionButtons)
                            ? (
                                <Link
                                    id={`uwIssueLink${blockingPointIndex}${index}`}
                                    type="outlined"
                                    onClick={() => showUWIssueDetailPopup(issue)}
                                    className={styles.gwIssueDescription}
                                >
                                    <span className={styles.gwIssueDescription}>
                                        {issue.longDescription}
                                    </span>
                                </Link>
                            )
                            : (
                                <span key={`span${blockingPointIndex}${index}`} className={styles.gwIssueDescription}>
                                    {issue.longDescription}
                                </span>

                            )
                        }
                        <div key={`uwIssueBtnContainer${blockingPointIndex}${index}`} hidden={!showActionButtons || viewOnlyMode} className="">
                            <Button
                                id={`reopen${blockingPointIndex}${index}`}
                                className={styles.uwActionButton}
                                type="outlined"
                                onClick={() => uwIssueActions({ functionToCall: 'reopenUWIssue', issueVM: issue })}
                                hidden={!issue.hasApprovalOrRejection || !issue.canUserReopen || issue.currentBlockingPoint === NON_BLOCKING}
                            >
                                {translator(messages.reopen)}
                            </Button>
                            <Button
                                id={`reject${blockingPointIndex}${index}`}
                                className={styles.uwActionButton}
                                type="outlined"
                                onClick={() => uwIssueActions({ functionToCall: 'rejectUWIssue', issueVM: issue })}
                                hidden={issue.hasApprovalOrRejection || !issue.canUserReject || issue.currentBlockingPoint === NON_BLOCKING}
                            >
                                {translator(messages.reject)}
                            </Button>
                            <Button
                                id={`approve${blockingPointIndex}${index}`}
                                className={styles.uwActionButton}
                                type="outlined"
                                onClick={(evt) => triggerUWModal(evt, issue)}
                                hidden={issue.hasApprovalOrRejection || !issue.canUserApprove || issue.currentBlockingPoint === NON_BLOCKING}
                            >
                                {translator(messages.approve)}
                            </Button>
                        </div>
                    </div>
                    <div key={`highProfileSurcharge${blockingPointIndex}${index}`} className="p-2">
                        {(!hideHighProfileSurcharge
                            && issue.currentBlockingPoint === ALREADY_APPROVED
                            && issue.uwissueCode_Ext === "HighProfileInsuredOther_EU")
                            ? (
                                <ToggleField
                                    id={`chargeHighProfileSurchargeIndToggleField${blockingPointIndex}${index}`}
                                    value={chargeHighProfileSurcharge}
                                    dataType="boolean"
                                    controlClassName="toggleFieldWidth-150"
                                    label={translator(euCommonMessages.applyHighProfileSurcharge)}
                                    availableValues={[
                                        {
                                            displayName: translator(commonMessages.yes),
                                            id: true
                                        },
                                        {
                                            displayName: translator(commonMessages.no),
                                            id: false
                                        }
                                    ]}
                                    readOnly={!issue.canUserReopen || viewOnlyMode}
                                    onValueChange={setChargeHighProfileSurchargeInd}
                                    labelPosition="top"
                                />) : undefined}
                    </div>
                </div>
            ));
    }, [showActionButtons, viewOnlyMode, translator, hideHighProfileSurcharge, chargeHighProfileSurcharge, setChargeHighProfileSurchargeInd, showUWIssueDetailPopup, uwIssueActions, triggerUWModal]);

    const underWritingIssuesTable = useCallback(() => {
        const renderByBlockingPoint = displayableIssues;

        return (
            <div>
                {renderByBlockingPoint.map((bp, index) => (
                        <div key={`uwIssueForBlockingPointDiv${index}`}>
                            <div key={`blockingPointDisplayKey${index}`} className="mt-8"><h4 key={`blockingPointDisplayKeyH4${index}`}>{getBlockingPointDisplayKey(bp)}</h4></div>
                            {generateIssuesData(getUwIssuesByBlockingPoint[bp], index)}
                        </div>
                    ))}
            </div>
        );
    }, [generateIssuesData, displayableIssues, getUwIssuesByBlockingPoint, getBlockingPointDisplayKey]);

    const resolvers = {
        resolveClassNameMap: styles
    };
    const overrideProps = {
        gwAlert: {
            visible: !underwritingIssuesLoading && (blockQuote || displayableIssues.length > 0)
        },
        uWIssuesLoader: {
            loaded: !underwritingIssuesLoading,
            text: translator(messages.loadingUWIssues)
        },
        gwAlertHeading: {
            content: translator(messages.followingQuotes),
            visible: showActionButtons
        },
        gwAlertHeadingUwIssues: {
            content: translator(messages.uwIssues),
            visible: !showActionButtons
        },
        issuesTable: {
            content: underWritingIssuesTable()
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={formData}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
};

DisplayUnderwritingIssues.propTypes = {
    underwritingIssues: PropTypes.arrayOf(PropTypes.shape({
        currentBlockingPoint: PropTypes.string,
        longDescription: PropTypes.string,
        canUserApprove: PropTypes.bool,
        canUserReject: PropTypes.bool,
        canUserReopen: PropTypes.bool,

    })),
    setunderwritingIssues: PropTypes.func.isRequired,
    blockQuote: PropTypes.bool,
    quoteID: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    filterUWIssuesInCustomOffering: PropTypes.bool,
    showActionButtons: PropTypes.bool,
    viewOnlyMode: PropTypes.bool,
    hideHighProfileSurcharge: PropTypes.bool
};
DisplayUnderwritingIssues.defaultProps = {
    underwritingIssues: [],
    blockQuote: false,
    filterUWIssuesInCustomOffering: true,
    showActionButtons: true,
    viewOnlyMode: false,
    hideHighProfileSurcharge: false
};

const UnderwritingIssues = (props) => {
    const {
        underwritingIssues
    } = props;
    const hasUwIssues = underwritingIssues.length;

    return hasUwIssues ? <DisplayUnderwritingIssues {...props} /> : 'No Underwriting Issues Raised.';
};

UnderwritingIssues.propTypes = {
    underwritingIssues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    quoteID: PropTypes.string.isRequired,
    filterUWIssuesInCustomOffering: PropTypes.bool,
    viewOnlyMode: PropTypes.bool
};

UnderwritingIssues.defaultProps = {
    filterUWIssuesInCustomOffering: true,
    viewOnlyMode: false
};

export default withRouter(UnderwritingIssues);
