/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';

import { FormattedDate, FormattedMessage, useTranslator } from '@jutro/locale';
import { e1pDateUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import styles from './WizardPageHeader.module.scss';
import messages from './WizardPageHeader.messages';

/**
 * @prop {string} propTypes.wizardSubmission - submission view model
 * @prop {string} propTypes.isSideHeader - flag to specify side header
 */

const WizardPageHeader = (props) => {
    const translator = useTranslator();
    const [wizardHeaderTemplate, setWizardHeaderTemplate] = useState('');
    const [accountRoute, setAccountRoute] = useState('');
    const [accountName, setAccountName] = useState('');
    const [effectiveDate, setEffectiveDate] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [editEffectiveDate, setEditEffectiveDate] = useState('');
    const [showEditEffectiveDate, setShowEditEffectiveDate] = useState(false);
    const [hasTransactionStarted, updateHasTransactionStarted] = useState(false);
    const [lobWithQuoteNumber, setLobWithQuoteNumber] = useState('');
    const [formattedLob, setFormattedLob] = useState('');
    const {
        wizardSubmission, isSideHeader, shouldLink, isPolicyView
    } = props;
    const QUOTE_TRANSACTION = [
        'PersonalAuto_EA',
        'Homeowners_EH',
        'PersonalUmbrella_EU',
        'Cancellation',
        'Rewrite'
    ];
    const [policyNumber, setPolicyNumber] = useState(_.get(wizardSubmission.value, 'policyNumber', ''));
    const [jobStatus, setJobStatus] = useState(_.get(wizardSubmission, 'baseData.periodStatus.value.code', ''));

    const productToLOBName = {
        Homeowners_EH: 'homeowners_EH',
        PersonalAuto_EA: 'personalAuto_EA',
        PersonalUmbrella_EU: 'personalUmbrella_EU'
    };
    const getPNIName = useCallback((productCode, jobType) => {
        const pniObjPath = `lobData.${productToLOBName[productCode]}.primaryNamedInsured.person`;
        let pniName = '';
        const noAccountText = translator(messages.noAccount);

        switch (jobType) {
            case 'Submission':
            case 'PolicyChange':
            case 'Rewrite':
            case 'Renewal':
                {
                    const personObj = _.get(wizardSubmission.value, pniObjPath);
                    let displayName = _.get(personObj, 'displayName');
                    const firstName = _.get(personObj, 'firstName');
                    const lastName = _.get(personObj, 'lastName');

                    if (!displayName && firstName && lastName) {
                        displayName = `${firstName} ${lastName}`;
                    }

                    pniName = displayName;
                }
                break;
            case 'Cancellation':
                pniName = _.get(wizardSubmission.value, 'latestPeriod.primaryInsuredName');
                break;
            case 'Reinstatement':
                pniName = _.get(wizardSubmission.value, 'baseData.primaryNamedInsuredName_Ext');
                break;
            default: // this case is for policy view
                {
                // for policyView jobType will be undefined and it will reach in default case
                    const personObj = _.get(wizardSubmission.value, pniObjPath);
                    let displayName = _.get(personObj, 'displayName');

                    if (!displayName) {
                        displayName = `${_.get(personObj, 'firstName')} ${_.get(personObj, 'lastName')}`;
                    }

                    pniName = displayName;
                }
                break;
        }

        return pniName || noAccountText;
    }, [productToLOBName, translator, wizardSubmission.value]);

    const getPolicyTypeDisplayName = useCallback((productCode, jobType) => {
        let policyTypePath = `value.lobData.${productToLOBName[productCode]}.policyType`;

        // for policy Change and Reinstatement, policyType is outside lobdata
        if (jobType === 'PolicyChange' || jobType === 'Reinstatement') {
            policyTypePath = `value.policyType`;
        }

        const policyTypeCode = _.get(wizardSubmission, policyTypePath);

        let policyTypeDisplayName = '';

        // for Reinstatement, we are not getting policyType from backend,since we dont have lobData for reinstatement
        if(policyTypeCode) {
            policyTypeDisplayName = translator({
                id: `typekey.PolicyType_Ext.${policyTypeCode}`,
                defaultMessage: policyTypeCode
            });
        }

        return policyTypeDisplayName;
    }, [productToLOBName, translator, wizardSubmission]);

    useEffect(() => {
        // Get product details from the wizard data
        const dtoName = _.get(wizardSubmission, '_dtoName');
        let productCode = isPolicyView ? _.get(wizardSubmission.value, 'productCode') : _.get(wizardSubmission.value, 'baseData.productCode');
        let jobType = _.get(wizardSubmission.value, 'baseData.jobType');
        const policyTypeDisplayName = getPolicyTypeDisplayName(productCode, jobType);
        let lob = ((jobType === 'PolicyChange') && `${productCode}${jobType}`)
            || productCode;

        // Cancellation does not exist until user clicks next on
        // the cancellation screen, cancellation not created yet
        // Don't want to show breadscrumb on start cancellation screen
        if (dtoName === 'edge.capabilities.gateway.job.cancellation.dto.CancellationDTO' && _.get(wizardSubmission.value, 'jobNumber')) {
            lob = 'Cancellation';
            productCode = _.get(wizardSubmission.value, 'productCode');
            jobType = 'Cancellation';
        }

        if (dtoName === 'amfam.edge.capabilities.rewrite.dto.RewriteDataDTO') {
            productCode = _.get(wizardSubmission.value, 'baseData.productCode');
            jobType = 'Rewrite';
            lob = `${productCode}${jobType}`;
        }

        // Only PC, and QNB, and Cancellation will work right now
        if (!lob) {
            return;
        }


        let accountNumber = _.get(wizardSubmission.value, 'baseData.accountNumber');
        const getAccountName = getPNIName(productCode, jobType);

        if (jobType === 'Cancellation') {
            accountNumber = _.get(wizardSubmission.value, 'policy.account.accountNumber');
        }

        // See if the header or sidebar is enabled for the product
        if (lob && QUOTE_TRANSACTION.includes(productCode)) {
            setWizardHeaderTemplate('transaction');
        }

        // Get effective date range
        let periodStartDate = _.get(wizardSubmission.value, 'baseData.periodStartDate');
        let periodEndDate = _.get(wizardSubmission.value, 'baseData.periodEndDate');

        // Set dates for message format
        if (jobType === 'Cancellation') {
            periodStartDate = _.get(wizardSubmission.value, 'policy.latestPeriod.effectiveDate');
            periodEndDate = _.get(wizardSubmission.value, 'policy.latestPeriod.expirationDate');

            const cancellationEffectiveDate = _.get(wizardSubmission.value, 'effectiveDate');

            setEditEffectiveDate(
                new Date(
                    cancellationEffectiveDate?.year,
                    cancellationEffectiveDate?.month,
                    cancellationEffectiveDate?.day
                )
            );
            setShowEditEffectiveDate(true);
            setJobStatus(_.get(wizardSubmission, 'statusCode.value.code', ''));
            setPolicyNumber(_.get(wizardSubmission.value, 'policy.policyNumber', ''));
        }

        if (jobType === 'PolicyChange') {
            const policyChangeEffectiveDate = _.get(wizardSubmission.value, 'baseData.effectiveDate');

            setEditEffectiveDate(
                new Date(
                    policyChangeEffectiveDate?.year,
                    policyChangeEffectiveDate?.month,
                    policyChangeEffectiveDate?.day
                )
            );
            setShowEditEffectiveDate(true);
            setJobStatus(_.get(wizardSubmission.value, 'status'));
        }

        if (jobType === 'Reinstatement') {
            const reinstatementEffectiveDate = _.get(wizardSubmission.value, 'effectiveDate');

            setEditEffectiveDate(
                new Date(
                    reinstatementEffectiveDate?.year,
                    reinstatementEffectiveDate?.month,
                    reinstatementEffectiveDate?.day
                )
            );
            setShowEditEffectiveDate(true);
        }

        setEffectiveDate(
            new Date(periodStartDate?.year, periodStartDate?.month, periodStartDate?.day)
        );
        setExpirationDate(
            new Date(periodEndDate?.year, periodEndDate?.month, periodEndDate?.day)
        );

        if (jobType === 'Cancellation') {
            setEffectiveDate(
                new Date(e1pDateUtil.convertToUTC(periodStartDate))
            );
            setExpirationDate(
                new Date(e1pDateUtil.convertToUTC(periodEndDate))
            );
        }


        // If submission
        let quoteNumber = _.get(wizardSubmission.value, 'quoteID');
        // If any other transaction
        let jobID = _.get(wizardSubmission.value, 'jobID');

        if (jobType === 'Cancellation') {
            jobID = _.get(wizardSubmission.value, 'jobNumber');
            quoteNumber = _.get(wizardSubmission.value, 'policy.policyNumber');
        }

        // decide if we should enable link to transaction summary
        if (quoteNumber || jobID || isPolicyView) {
            updateHasTransactionStarted(true);
        }

        if ((shouldLink && jobStatus === 'Bound') || isPolicyView) {
            lob = `${productCode}Policy`;
        }

        // now we are showing the header in Thankyou page as well,
        // so instead of quoteNumber we need to show PolicyNumber just on thankyou page
        const quoteNumberOrPolicyNumber = jobStatus === 'Bound' ? policyNumber : quoteNumber;
        const jobIdOrPolicyNumber = jobStatus === 'Bound' ? policyNumber : jobID;

        // Generate label for link to job summary
        setLobWithQuoteNumber(translator(messages[_.camelCase(lob)], {
            policyType: policyTypeDisplayName,
            quoteNumber:
                quoteNumberOrPolicyNumber
                || _.get(wizardSubmission.value, 'policyNumber')
                || translator(e1pCommonMessages.policyOrQuoteNumber),
            jobID: jobIdOrPolicyNumber ||  translator(e1pCommonMessages.policyOrQuoteNumber)

        }));

        if (isPolicyView) {
            accountNumber = _.get(wizardSubmission.value, 'accountNumber');
            periodStartDate = _.get(wizardSubmission.value, 'periodStartDate');
            periodEndDate = _.get(wizardSubmission.value, 'periodEndDate');
            setEffectiveDate(
                new Date(periodStartDate?.year, periodStartDate?.month, periodStartDate?.day)
            );
            setExpirationDate(
                new Date(periodEndDate?.year, periodEndDate?.month, periodEndDate?.day)
            );
        }

        // set Link to account
        setAccountRoute(`/accounts/${accountNumber}/summary`);
        setAccountName(`${getAccountName} |`);
        setFormattedLob(translator(messages[_.camelCase(lob)], {policyType: policyTypeDisplayName}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobStatus, policyNumber, wizardSubmission]);

    // MSA will have the header on top but
    // I will leave functionality to toggle a sidebar
    const titleContainerClass = cx({
        // on top
        [styles.gwWizardPageTitle]: !isSideHeader,
        // sidebar
        [styles.gwWizardSidePageTitle]: isSideHeader
    });

    // MSA will have the header on top but
    // I will leave functionality to toggle a sidebar
    const categoryContainerClass = cx({
        // on top
        [styles.gwWizardPageCategory]: !isSideHeader,
        // sidebar
        [styles.gwWizardSidePageCategory]: isSideHeader
    });

    // MSA will have the header on top but
    // I will leave functionality to toggle a sidebar
    const associatedPageClass = cx({
        // on top
        [styles.gwWizardPageAssociated]: !isSideHeader,
        // sidebar
        [styles.gwWizardSidePageAssociated]: isSideHeader
    });

    // MSA will have the header on top but
    // I will leave functionality to toggle a sidebar
    const associatedPageClassLink = cx({
        // on top
        [styles.gwWizardPageAssociatedLink]: !isSideHeader,
        // sidebar
        [styles.gwWizardSidePageAssociated]: isSideHeader
    });

    let policyState = wizardSubmission.value.baseData?.policyAddress.state ? wizardSubmission.value.baseData.policyAddress.state : translator(e1pCommonMessages.state);
    let locationCode = _.get(wizardSubmission, 'value.baseData.externalID_Ext', translator(e1pCommonMessages.locationCode));
    const dtoName = _.get(wizardSubmission, '_dtoName');

    if (dtoName === 'edge.capabilities.gateway.job.cancellation.dto.CancellationDTO') {
        policyState = wizardSubmission.value?.policy?.account?.accountHolder.primaryAddress.state ? wizardSubmission.value.policy.account.accountHolder.primaryAddress.state : translator(e1pCommonMessages.state);
        locationCode = _.get(wizardSubmission, 'value.externalID_Ext', translator(e1pCommonMessages.locationCode));
    }

    if (isPolicyView) {
        policyState = _.get(wizardSubmission, 'value.policyAddress.state', translator(e1pCommonMessages.state));
        locationCode = _.get(wizardSubmission, 'value.externalID_Ext', translator(e1pCommonMessages.locationCode));
    }

    const pipeBreak = ' | ';
    const editEffectiveDateElement = showEditEffectiveDate
        ? (<FormattedDate value={editEffectiveDate} />) : (<div />);

    const submissionQuote = wizardHeaderTemplate === 'transaction' && (
        <div className={titleContainerClass}>
            <div className={categoryContainerClass}>
                <div className={styles.headerTab}>
                    {
                        (hasTransactionStarted && shouldLink && jobStatus === 'Bound') || isPolicyView
                            ? (
                                <>
                                    <div className={associatedPageClass}>{formattedLob}</div>
                                    <Link className={associatedPageClassLink} to={`/policies/${policyNumber}/summary`}>
                                        {policyNumber}
                                    </Link>
                                    <div className={associatedPageClass}> |</div>
                                </>
                            )
                            : <div className={associatedPageClass}>{lobWithQuoteNumber}</div>
                    }
                    {accountName && shouldLink
                        ? (
                            <Link className={associatedPageClass} to={accountRoute}>
                                {accountName}
                            </Link>
                        )
                        : (
                            <div className={associatedPageClass}>
                                {accountName}
                            </div>
                        )
                    }
                    {` ${policyState} |`}
                    {` ${locationCode} |`}
                    {<FormattedMessage
                        {...messages.effectiveDateRange}
                        values={{
                            effectiveDate: effectiveDate ?  <FormattedDate value={effectiveDate} /> : translator(e1pCommonMessages.effectiveDate),
                            expirationDate: expirationDate ? <FormattedDate value={expirationDate} /> : translator(e1pCommonMessages.expirationDate)
                        }}
                    />}
                    {`${pipeBreak}`}
                    {editEffectiveDateElement}
                </div>
            </div>
        </div>
    );


    return (
        <>
            {submissionQuote}
        </>
    );
};

WizardPageHeader.propTypes = {
    wizardSubmission: PropTypes.shape({
        value: PropTypes.shape({}),
        _dtoName: PropTypes.string
    }).isRequired,
    isSideHeader: PropTypes.bool,
    lobFlow: PropTypes.string,
    shouldLink: PropTypes.bool
};

WizardPageHeader.defaultProps = {
    isSideHeader: false,
    lobFlow: '',
    shouldLink: false
};

export default WizardPageHeader;
