
import React, {
    useCallback,
    useMemo,
    useState
} from 'react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { get as _get, isNil as _isNil} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import metadata from '../PaymentDetailsPage.metadata.json5';

const LOB = 'personalUmbrella_EU';
const INITIAL_PAYMENT_OPTION_PATH = 'baseData.value.conversionDTO.initialPaymentType';

function ViewPaymentDetailsPage(props) {
    const {
        wizardData: submissionVM
    } = props;
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const { authHeader } = useAuthentication();
    const PAY_MONTHLY = 'af:payMthly3';

    const availableValuesForPaymentOptions = useMemo(() => {
        const correctOffering = submissionVM.lobData.personalUmbrella_EU.offerings.value[0];

        if (correctOffering.paymentPlans !== undefined) {
            return correctOffering.paymentPlans
                .filter((paymentPlans) => paymentPlans.name)
                .reduce(
                    (paymentPlanMap, paymentPlan) => ({
                        ...paymentPlanMap,
                        [paymentPlan.billingId]: paymentPlan
                    }),
                    {}
                );
        }

        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(submissionVM.lobData.personalUmbrella_EU.offerings.value)]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            readOnly: true
        },
        paymentDetailsPageLoadingIndicator: {
            loaded: true
        },
        iframePaymentus: {
            visible: false
        },
        iFrameLoader: {
            loaded: true
        },
        totalPremiumID: {
            value: !_isNil(
                availableValuesForPaymentOptions[
                    _get(submissionVM, 'bindData.selectedPaymentPlan.value')
                ]
            )
                ? availableValuesForPaymentOptions[
                    _get(submissionVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined
        },
        totalPremiumWithFullPayID: {
            value: !_isNil(availableValuesForPaymentOptions[PAY_MONTHLY])
                ? availableValuesForPaymentOptions[PAY_MONTHLY].total
                : undefined
        },
        quoteProposalLinkContainer: {
            disabled: true
        },
        producerCodeDrpDwn: {
            // eslint-disable-next-line no-prototype-builtins
            visible: submissionVM.value.hasOwnProperty('licensedProducers'),
            // eslint-disable-next-line no-prototype-builtins
            required: submissionVM.value.hasOwnProperty('licensedProducers'),
            value: _get(submissionVM, 'bindData.value.licensedProducerID_Ext', '')
        },
        chooseLicenceProDiv: {
            // eslint-disable-next-line no-prototype-builtins
            visible: submissionVM.value.hasOwnProperty('licensedProducers')
        },
        mailingAndBillingAddressComponent: {
            transactionVM: submissionVM,
            updateWizardData: () => {},
            viewOnlyMode: true,
            lob: LOB
        },
        iframePDF: {
            visible: false
        },
        signButton: {
            visible: false
        },
        signPolicyLoader: {
            loaded: true
        },
        paymentOptionsID: {
            submissionVM,
            authHeader: {},
            updateWizardData: () => {},
            LOB: 'personalUmbrella_EU',
            viewOnly: true
        },
        // IAP-371 show TPI component in only read only Mode
        e1ptpiDisplayTableContainer: {
            transactionVM: submissionVM,
            authHeader,
            updateWizardData: () => {},
            setIsSavingTPI: () => { },
            showErrors: false,
            onValidate: () => { },
            disregardFieldValidationParentPage: () => { },
            viewOnlyMode: true,
            showTitle: false
        },
        addThirdPartyInterestButton: {
            visible: false
        },
        paymentusComponent: {
            submissionVM,
            updateWizardData: () => {},
            // Show payment component when payment is not made, submission Quote is available and has no UW issues
            visible: false,
            setKeepShowingIframeAfterPayment: () => { },
            disableAllSteps: () => {},
        },
        monthlyPaymentScheduleComponent: {
            visible: false
        },
        signatureComponent: {
            submissionVM,
            updateWizardData: () => {},
            authHeader,
            LOB,
            onSignatureSuccess: () => { },
            producerCodeOptions,
            setProducerCodeOptions,
            viewOnlyMode: true,
            visible: true
        },
        insuredContactInfo: {
            viewOnlyMode: true,
            showPaperless: true
        },
        paymentOptions: {
            value: _get(submissionVM, INITIAL_PAYMENT_OPTION_PATH),
            visible: !!_get(submissionVM, INITIAL_PAYMENT_OPTION_PATH) 
        },
        quoteProposalFailureErrorDiv: {
            visible: false
        },
        paperlessEmailChangedMessageDiv:{
            visible: false
        },
        userNotAuthorisedErrorDiv: {
            visible: false
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            signPolicy: () => { }
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    const readValue = useCallback(
        // eslint-disable-next-line arrow-body-style
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    return (
        <WizardPage
            showNext={false}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

ViewPaymentDetailsPage.propTypes = wizardProps;
export default ViewPaymentDetailsPage;
