import {
    useCallback
} from 'react';

import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty
} from 'lodash';

function usePropertyPageUtil(
    transactionVM, hasReplacementCost, updateWizardData, viewModelService, setIsReplacementCostStale
) {

    const addInsideWallMaterial = useCallback(() => {
        const wallMaterialNotProvided = _isEmpty(
            _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value', [])
        );
        const wallMaterial = {
            materialType: '',
            percentage: ''
        };
        if (wallMaterialNotProvided) {
            wallMaterial.percentage = 100;
        }
        const {
            _xCenter,
            _dtoName,
        } = transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials;
        const wallMaterialVM = viewModelService.create(wallMaterial, _xCenter, _dtoName);
        transactionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.value.push(
            wallMaterialVM.value
        );
        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }
        updateWizardData(transactionVM);
    }, [hasReplacementCost, setIsReplacementCostStale, transactionVM, updateWizardData, viewModelService]);

    const addFloorMaterial = useCallback(() => {
        const floorMaterialNotProvided = _isEmpty(
            _get(transactionVM, 'lobData.homeowners_EH.coverables.construction.floors.value', [])
        );
        const floor = {
            floorType: '',
            percentage: ''
        };
        if (floorMaterialNotProvided) {
            floor.percentage = 100;
        }
        const {
            _xCenter,
            _dtoName,
        } = transactionVM.lobData.homeowners_EH.coverables.construction.floors;
        const floorMaterialVM = viewModelService.create(floor, _xCenter, _dtoName);
        transactionVM.lobData.homeowners_EH.coverables.construction.floors.value.push(
            floorMaterialVM.value
        );
        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }
        updateWizardData(transactionVM);
    }, [hasReplacementCost, setIsReplacementCostStale, transactionVM, updateWizardData, viewModelService]);

    const addGarage = useCallback(() => {
        const garage = {
            garageType: '',
            garageSize: ''
        };
        const {
            _xCenter,
            _dtoName,
        } = transactionVM.lobData.homeowners_EH.coverables.construction.garages;
        const garageVM = viewModelService.create(garage, _xCenter, _dtoName);
        transactionVM.lobData.homeowners_EH.coverables.construction.garages.value.push(
            garageVM.value
        );
        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                transactionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }
        updateWizardData(transactionVM);
    }, [hasReplacementCost, setIsReplacementCostStale, transactionVM, updateWizardData, viewModelService]);


    return {
        addInsideWallMaterial,
        addFloorMaterial,
        addGarage
    };
}

export default usePropertyPageUtil;