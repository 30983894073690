/* eslint-disable arrow-body-style */
import React, {
    Fragment, useContext, useCallback, useEffect, useMemo, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useViewPolicyUtil } from 'e1p-capability-hooks';
import { useErrors, ErrorBoundary } from '@xengage/gw-portals-error-react';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { InlineNotification } from '@jutro/components';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { WizardContext } from './WizardContext';
import wizardMessages from './Wizard.messages';

const TRANSACTION_NEXT = 'next';
const TRANSACTION_PREVIOUS = 'previous';
const TRANSACTION_CANCEL = 'cancel';

function checkContinue(result) {
    if (result === false) {
        return Promise.reject(new Error(false));
    }

    return Promise.resolve(result);
}

function WizardPage(props) {
    const { throwReactError } = useErrors();
    const wizardContext = useContext(WizardContext);
    const [isLoadingCancel, updateLoadingCancel] = useState(false);
    const [isLoadingPrevious, updateLoadingPrevious] = useState(false);
    const [isLoadingCustom, updateLoadingCustom] = useState(false);
    const [isLoadingSave, updateLoadingSave] = useState(false);
    const [isLoadingCustom1, updateLoadingCustom1] = useState(false);
    const [isLoadingWithdraw, updateLoadingWithdraw] = useState(false);
    const [isLoadingNext, updateLoadingNext] = useState(false);
    const [transitionDirection, updateTransitionDirection] = useState(undefined);
    const isMounted = useRef(false);
    const {
        isSkipping,
        stopSkipping,
        e1pGoNext: wizardGoNext,
        goPrevious: wizardGoPrevious,
        cancel: wizardCancel,
        finish: wizardFinish,
        markStepSubmitted,
        wizardData,
        wizardSnapshot,
        updateWizardData,
        updateWizardSnapshot,
        currentStepIndex,
        currentStep = {},
        steps,
        hasNewErrors,
        errorsForStep,
        applicationExceptions,
        shouldSkipAdditionalInfo,
        viewOnly,
        isRenewal,
        isPolicyView,
        setIsPageValid,
        onPageNextFn,
        isAllPagesSubmittedFlow,
        updateWizardErrors,
        isLoadingThroughPageCheveron,
        clearVisitedStepsAfterCurrent,
        isJSorAPIErrorOccured,
        ...otherWizardProps
    } = wizardContext;
    const { stepProps = {} } = currentStep;
    const { template: templateFromConfig } = stepProps;

    const {
        template,
        skipWhen,
        onPrevious: pageOnPrevious,
        onCancel: pageOnCancel,
        onNext: pageOnNext,
        onCustom: pageOnCustom,
        onCustom1: pageOnCustom1,
        onWithdraw: pageOnWithdraw,
        onSave: pageOnSave,
        finish,
        children,
        updateSnapshot,
        isPageSubmittedWithErrors,
        isLoadingWholePage,
        ...otherProps
    } = props;

    const Template = templateFromConfig || template;

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isSkipping) {
            Promise.resolve(skipWhen(wizardData)).then((shouldSkip) => {
                if (shouldSkip) {
                    wizardGoNext();
                } else {
                    stopSkipping();
                }
            }).catch(throwReactError);
        }
    });

    useEffect(() => {
        // This will clear all steps after current step in edit flow
        // so that on next functionality will always execute
        if (!isSkipping && !viewOnly) {
            clearVisitedStepsAfterCurrent();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        // Defaulting page valid as undefined
        setIsPageValid(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        landOnPolicySummary
    } = useViewPolicyUtil();

    const transitionNext = useCallback(() => {
        const errorsOnly = errorsForStep.filter((error) => {
            return error.level === ErrorLevel.LEVEL_ERROR;
        });

        if (!hasNewErrors && _.isEmpty(applicationExceptions) && _.isEmpty(errorsOnly)) {
            if (finish) {
                wizardFinish({ shouldSkipAdditionalInfo });
            } else {
                wizardGoNext();
            }
        } else {
            updateTransitionDirection(undefined);
        }
    }, [
        errorsForStep,
        finish,
        applicationExceptions,
        hasNewErrors,
        wizardFinish,
        wizardGoNext,
        shouldSkipAdditionalInfo
    ]);

    useEffect(() => {
        switch (transitionDirection) {
            case TRANSACTION_NEXT:
                transitionNext();
                break;
            case TRANSACTION_PREVIOUS:
                wizardGoPrevious();
                break;
            case TRANSACTION_CANCEL:
                wizardCancel();
                break;
            default:
        }
    }, [transitionDirection, transitionNext, wizardCancel, wizardGoPrevious]);

    const onCancel = useCallback(() => {
        updateLoadingCancel(true);

        if (viewOnly) {
            return Promise.resolve()
                .then(() => {
                    landOnPolicySummary(wizardData);
                })
                .catch((error) => {
                    if (_.get(error, 'message') === 'false') {
                        // do nothing as we don't want to proceed
                        return;
                    }

                    throwReactError(error);
                })
                .finally(() => {
                    updateLoadingCancel(false);
                });
        }

        return Promise.resolve(pageOnCancel(wizardData))
            .then(updateWizardData)
            .then(() => updateTransitionDirection(TRANSACTION_CANCEL))
            .catch(throwReactError)
            .finally(() => {
                updateLoadingCancel(false);
                updateTransitionDirection(undefined);
            });
    }, [
        viewOnly,
        pageOnCancel,
        wizardData,
        updateWizardData,
        throwReactError,
        landOnPolicySummary
    ]);

    const onPrevious = useCallback(() => {
        updateLoadingPrevious(true);

        return Promise.resolve(pageOnPrevious(wizardData))
            .then(checkContinue)
            .then(() => updateTransitionDirection(TRANSACTION_PREVIOUS))
            .catch((error) => {
                if (_.get(error, 'message') === 'false') {
                    // do nothing as we don't want to proceed
                    return;
                }

                throwReactError(error);
            })
            .finally(() => {
                updateLoadingPrevious(false);
                updateTransitionDirection(undefined);
            });
    }, [pageOnPrevious, wizardData, throwReactError]);

    const onCustom = useCallback(() => {
        // Clearing off the error; if onCustom(reviewChanges) call generates same error as it had before
        // hasNewErrors from useWizardErrors.js stays false as we did not updated new errors, and we dont display errors
        updateWizardErrors([]);
        updateLoadingCustom(true);

        return Promise.resolve(pageOnCustom(wizardData))
            .catch((error) => {
                if (_.get(error, 'message') === 'false') {
                    // do nothing as we don't want to proceed
                    return;
                }

                throwReactError(error);
            })
            .finally(() => {
                // Update the component's state only if it is currently mounted.
                if (isMounted.current) {
                    updateLoadingCustom(false);
                }
            });
    }, [updateWizardErrors, pageOnCustom, wizardData, throwReactError]);

    const onSave = useCallback(() => {
        // Clearing off the error; if save call generates same error as it had before
        // hasNewErrors from useWizardErrors.js stays false as we did not updated new errors, and we dont display errors
        updateWizardErrors([]);
        updateLoadingSave(true);

        return Promise.resolve(pageOnSave(wizardData))
            .catch((error) => {
                if (_.get(error, 'message') === 'false') {
                    // do nothing as we don't want to proceed
                    return;
                }

                throwReactError(error);
            })
            .finally(() => {
                updateLoadingSave(false);
            });
    }, [updateWizardErrors, pageOnSave, wizardData, throwReactError]);

    const onCustom1 = useCallback(() => {
        // Clearing off the error; if custom1(applyChanges) generates same error as it had before
        // hasNewErrors from useWizardErrors.js stays false as we did not updated new errors, and we dont display errors
        updateWizardErrors([]);
        updateLoadingCustom1(true);

        return Promise.resolve(pageOnCustom1(wizardData))
            .catch((error) => {
                if (_.get(error, 'message') === 'false') {
                    // do nothing as we don't want to proceed
                    return;
                }

                throwReactError(error);
            })
            .finally(() => {
                updateLoadingCustom1(false);
            });
    }, [updateWizardErrors, pageOnCustom1, wizardData, throwReactError]);

    const onWithdraw = useCallback(() => {
        updateLoadingWithdraw(true);

        return Promise.resolve(pageOnWithdraw(wizardData))
            .catch((error) => {
                if (_.get(error, 'message') === 'false') {
                    // do nothing as we don't want to proceed
                    return;
                }

                throwReactError(error);
            })
            .finally(() => {
                updateLoadingWithdraw(false);
            });
    }, [pageOnWithdraw, wizardData, throwReactError]);

    const isLastStep = useMemo(() => currentStepIndex === steps.length - 1, [
        currentStepIndex,
        steps
    ]);

    const nextStepVisitedAlready = useMemo(() => {
        if (isLastStep) {
            return false;
        }

        return steps[currentStepIndex + 1].visited;
    }, [currentStepIndex, isLastStep, steps]);

    const wizardUpdateOperation = useMemo(() => {
        const operation = updateSnapshot ? updateWizardSnapshot : updateWizardData;

        return (newWizardData) => operation(newWizardData);
    }, [updateSnapshot, updateWizardData, updateWizardSnapshot]);

    // stores reference to onNext function for current page
    // so that we can use this when user clicks on any page tab
    onPageNextFn.current = pageOnNext;


    const onNext = useCallback(() => {
        if (nextStepVisitedAlready && !isAllPagesSubmittedFlow) {
            wizardGoNext();

            return Promise.resolve();
        }

        // Clearing off the error; if onNext call generates same error as it had before
        // hasNewErrors from useWizardErrors.js stays false as we did not updated new errors, and we dont display errors
        updateWizardErrors([]);
        markStepSubmitted();
        updateLoadingNext(true);

        return Promise.resolve(pageOnNext(wizardData))
            .then(checkContinue)
            .then((resp) => {
                    if (isMounted.current) {
                        wizardUpdateOperation(resp)
                    }
                }
            )
            .then(() => {
                if (isMounted.current) {
                    updateTransitionDirection(TRANSACTION_NEXT)
                }
            })
            .catch((error) => {

                if (_.get(error, 'message') === 'false') {
                    // do nothing as we don't want to proceed
                    return;
                }

                throwReactError(error);
            })
            .finally(() => {
                // Update the component's state only if it is currently mounted.
                if (isMounted.current) {
                    updateLoadingNext(false);
                }
            });
    }, [
        nextStepVisitedAlready,
        markStepSubmitted,
        pageOnNext,
        wizardData,
        wizardUpdateOperation,
        wizardGoNext,
        throwReactError,
        isAllPagesSubmittedFlow,
        updateWizardErrors
    ]);

    const handleError = useCallback(
        (error) => {
            // eslint-disable-next-line no-param-reassign
            error.gwInfo = wizardSnapshot;
            throw error;
        },
        [wizardSnapshot]
    );

    const takesNoProps = Template === Fragment || _.isString(Template);
    const pageProps = {
        ...otherProps,
        ...otherWizardProps,
        errorsForStep,
        isLoadingCancel,
        onCancel,
        isRenewal,
        isLoadingPrevious,
        onPrevious,
        isLoadingNext,
        onNext,
        isLoadingCustom,
        isLoadingSave,
        isLoadingCustom1,
        isLoadingWithdraw,
        onCustom,
        onSave,
        onCustom1,
        onWithdraw,
        wizardData,
        wizardSnapshot,
        applicationExceptions,
        viewOnly,
        isPolicyView,
        exitLabel: wizardMessages.exit,
        isPageSubmittedWithErrors,
        isSkipping,
        isLoadingWholePage: isLoadingWholePage || isLoadingThroughPageCheveron
    };
    const templateProps = takesNoProps ? {} : pageProps;
    const renderProps = _.isFunction(children);

    return (
        <ErrorBoundary onError={handleError} shouldSetUnhandledRejection={false}>
            <Template {...templateProps}>
                {isJSorAPIErrorOccured && <InlineNotification id="apiErrorMessage" message={e1pCommonMessages.genericErrorText} type="error" isDismissable={false} className="my-2" />}
                {renderProps ? children(pageProps) : children}</Template>
        </ErrorBoundary>
    );
}

const messageShape = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string
    })
])

WizardPage.propTypes = {
    /**
     * The template to be used to render this Wizard page
     * The template will be responsible for rendering the children of this page
     */
    template: PropTypes.elementType,
    /**
     * If true the *onNext* will also finish the wizard
     * */
    finish: PropTypes.bool,

    /**
     * The function that should be called when going to the next step
     *
     * The function can return either a value or a promise.
     * The function will be given the current wizardData and *must return/resolve*
     * the next wizard data
     * If the function returns/resolves to false, the wizard won't continue to the next page
     */
    onNext: PropTypes.func,
    /**
     * whether the Next button should be disabled in the wizard
     */
    disableNext: PropTypes.bool,
    /**
     * whether the next button should be shown or not
     */
    showNext: PropTypes.bool,
    /**
     * the label that should be displayed on the next button
     */
    nextLabel: messageShape,
    /**
     * the tooltip message that should be displayed on the next button on hover
     */
    nextButtonTooltip: PropTypes.string,

    /**
     * The function that should be called when canceling the wizard.
     *
     * The function can return either a value or a promise.
     * The function will be given the current wizardData and *must return/resolve*
     * the next (for the cancelation state) wizard data
     * If the function returns/resolves to false, the wizard won't continue to the previous page
     */
    onPrevious: PropTypes.func,
    /**
     * whether the Previous button should be disabled in the wizard
     */
    disablePrevious: PropTypes.bool,
    /**
     * whether the previous button should be shown or not
     */
    showPrevious: PropTypes.bool,
    /**
     * the label that should be displayed on the previous button
     */
    previousLabel: messageShape,

    /**
     * The function that should be called when going to the previous step.
     *
     * The function can return either a value or a promise.
     * The function will be given the current wizardData and *must return/resolve*
     * the next (for the previous step) wizard data *There is no way to prevent the cancelation*
     * once it is triggered.
     */
    onCancel: PropTypes.func,
    /**
     * whether the Cancel button should be disabled in the wizard
     */
    disableCancel: PropTypes.bool,
    /**
     * whether the cancel button should be shown or not
     */
    showCancel: PropTypes.bool,
    /**
     * the label that should be displayed on the previous button
     */
    cancelLabel: messageShape,

    onCustom: PropTypes.func,

    /**
     * once on save is triggered
     */
    onSave: PropTypes.func,
    /**
     * whether the Save button should be disabled in the wizard
     */
    disableOnSave: PropTypes.bool,
    /**
    * whether the Save button should be shown or not
    */
    showOnSave: PropTypes.bool,
    /**
     * the label that should be displayed on the Save button
     */
    onSaveLabel: messageShape,

    onCustom1: PropTypes.func,

    onWithdraw: PropTypes.func,
    withdrawLabel: messageShape,
    /**
     * whether the Custom button should be disabled in the wizard
     */
    disableCustom: PropTypes.bool,
    disableCustom1: PropTypes.bool,
    /**
     * whether the Custom button should be shown or not
     */
    showCustom: PropTypes.bool,
    showCustom1: PropTypes.bool,
    /**
     * the label that should be displayed on the custom button
     */
    showRenewalCancel: PropTypes.bool,
    showRenewalPrevious: PropTypes.bool,

    showWithdraw: PropTypes.bool,


    customLabel: messageShape,
    custom1Label: messageShape,

    /**
     * gives the skip condition to the wizard page (when a page should be skipped)
     * This only applies if the wizard is trying to skip pages
     */
    skipWhen: PropTypes.func,
    /** whether the onNext should also update the wizard snapshot */
    updateSnapshot: PropTypes.bool,
    /** children can be passed as react object or through render props */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /**
     * used to display red error banner in the page template
     */
    isPageSubmittedWithErrors: PropTypes.bool,
    custom1ButtonType: PropTypes.string,
    /**
     * whether sidebar should be shown
     */
    hideSidebar: PropTypes.bool,

    isLoadingWholePage: PropTypes.bool,

    isJSorAPIErrorOccured: PropTypes.bool,
};

WizardPage.defaultProps = {
    template: Fragment,
    finish: false,

    onNext: (wizardData) => Promise.resolve(wizardData),
    disableNext: false,
    showNext: true,
    nextLabel: wizardMessages.next,
    nextButtonTooltip: '',
    isPageSubmittedWithErrors: false,

    onPrevious: (wizardData) => Promise.resolve(wizardData),
    disablePrevious: false,
    showPrevious: true,
    previousLabel: wizardMessages.previous,

    onCancel: (wizardData) => Promise.resolve(wizardData),
    disableCancel: false,
    showCancel: true,
    cancelLabel: wizardMessages.cancel,

    onCustom: (wizardData) => Promise.resolve(wizardData),
    disableCustom: false,
    showCustom: false,
    customLabel: e1pCommonMessages.reviewChanges,

    onCustom1: (wizardData) => Promise.resolve(wizardData),
    disableCustom1: false,
    showCustom1: false,
    custom1Label: wizardMessages.custom1,

    updateSnapshot: true,
    skipWhen: () => Promise.resolve(false),
    children: null,

    onWithdraw: (wizardData) => Promise.resolve(wizardData),
    showWithdraw: false,
    withdrawLabel: wizardMessages.withdraw,

    onSave: (wizardData) => Promise.resolve(wizardData),
    disableOnSave: false,
    showOnSave: false,
    onSaveLabel: e1pCommonMessages.save,

    showRenewalCancel: true,
    showRenewalPrevious: true,
    custom1ButtonType: 'outlined',
    hideSidebar: false,
    isLoadingWholePage: false
};

export default WizardPage;
