import React, {
    useCallback,
    useContext,
    useState
} from 'react';
import {
    find as _find,
    findLast as _findLast,
    get as _get,
    noop as _noop,
    pullAt as _pullAt,
    set as _set,
    isEmpty as _isEmpty,
    trim as _trim
} from 'lodash';
import {
    E1PEATPISearchDetailComponent
} from 'e1p-capability-policyjob-react';
import { useModal } from '@jutro/components';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { TPIUtil, ConsoleHelper } from 'e1p-portals-util-js';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTPIUtil } from 'e1p-capability-hooks';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import metadata from './E1PEATPIDisplayTableComponent.metadata.json5';
import messages from './E1PEATPIDisplayTableComponent.messages';
import styles from './E1PEATPIDisplayTableComponent.module.scss';

/**
 * This component file models the TPI table which appears on the EA "Third-Party Interest" page.
 *
 * @param {Object} props An object containing the properties passed into this component.
 * @returns {Object} An object containing the data required for rendering this component.
 */
function E1PEATPIDisplayTableComponent(props) {
    const modalApi = useModal();
    const {
        transactionVM,
        authHeader,
        updateWizardData,
        updateWizardSnapshot,
        viewOnlyMode,
        setIsSavingTPI,
        showErrors,
        onValidate,
        disregardFieldValidationParentPage,
        setIsAddingTPI,
        showTitle
    } = props;
    const { handleTPITableOnCell, updateNewlyAddedTPI } = useTPIUtil();
    const [isSearchTPIVisible, updateIsSearchTPIVisible] = useState(false);
    const stateCode = _get(transactionVM, 'baseData.policyAddress.state.value.code');
    const productCode = _get(transactionVM, 'baseData.productCode.value');
    const tpiBasePath = TPIUtil.getTPIBasePath(productCode);
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const allTPIsForPolicy = TPIUtil.getAllTPIsForEA(transactionVM);

    /**
     * Helper callback for determining whether the vehicle TPIs have duplicate designees.
     */
    const checkDuplicateDesignee = useCallback(() => {
        transactionVM.lobData.personalAuto_EA.coverables.vehicles.children.forEach((vehicle) => {
            const eaVehicleAddlInterests = vehicle.additionalInterests;

            if (eaVehicleAddlInterests && stateCode === 'NJ') {
                let duplicateAdditionalDesignee = {};
                const firstAdditionalDesignee = _find(
                    eaVehicleAddlInterests.children,
                    (additionalInterest) => (
                            additionalInterest.addlInterestType.value.code
                            === 'AdditionalDesignee_Ext'
                        )
                );

                if (firstAdditionalDesignee.value) {
                    duplicateAdditionalDesignee = _findLast(
                        eaVehicleAddlInterests.children,
                        (additionalInterest) => (
                                additionalInterest.addlInterestType.value
                                    .code === 'AdditionalDesignee_Ext'
                                && additionalInterest.tpiid
                                    .value !== firstAdditionalDesignee.tpiid.value
                            )
                    );
                }

                if (duplicateAdditionalDesignee && duplicateAdditionalDesignee.value) {
                    return modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: messages.duplicateAdditionalDesigneeTitle,
                        message: messages.duplicateAdditionalDesigneeMessage
                    }).then(() => { });
                }
            }

            return _noop;
        });

        return undefined;
    }, [modalApi, stateCode, transactionVM.lobData.personalAuto_EA.coverables.vehicles.children]);

    /**
     * Helper callback for showing the appropriate TPI detail component.
     */
    const showTPIDetailComponent = useCallback(
        async (addlInterestTPIVM, vehicleID) => {
            const componentProps = {
                viewModelService,
                tpiDetailVM: addlInterestTPIVM,
                isControlledTPI: _get(addlInterestTPIVM, 'tpiid.value') !== undefined,
                isTPIContactTypeCompany: _get(addlInterestTPIVM, 'company.value') !== undefined,
                isAddingNewTPI: false,
                tpiBasePath,
                transactionVM,
                vehicleID,
                updateWizardData,
                showPopup: true,
                authHeader,
                isEditingTPI: true,
                disregardFieldValidationParentPage
            };
            const result = await modalApi.showModal(
                <E1PEATPISearchDetailComponent {...componentProps} />
            );

            return result;
        },
        [
            authHeader, disregardFieldValidationParentPage, tpiBasePath,
            transactionVM, updateWizardData, viewModelService, modalApi
        ]
    );

    /**
     * Helper callback for handling opening/closure of the Third Party Search modal component.
     */
    const openTPISearchModal = useCallback(
        (tpiObj, index, additionalInfoPath) => {
            // show modal popover and pass the tpiVM to edit the tpi
            const addlInterestTPIVM = viewModelService.create(
                tpiObj,
                'pc',
                'amfam.edge.capabilities.policyjob.lob.ea.coverables.dto.EAVehicleAddlInterestDTO'
            );

            const vehiclePath = additionalInfoPath
                .split('.additionalInterests')[0]
                .replace(/\.children\.(\d+)/, '.children[$1]');
            const vehicle = _get(transactionVM, vehiclePath);
            const vehicleID = `${vehicle.publicID.value}${vehicle.year.value}${vehicle.make.value}${vehicle.model.value}`;

            try {
                showTPIDetailComponent(addlInterestTPIVM, vehicleID)
                    .then((tpiDetailObj) => {
                        // Now add the tpi detail to the "transactionVM"
                        const allTPIs = _get(transactionVM, additionalInfoPath);

                        // Remove from the index
                        _pullAt(allTPIs, index);
                        // Add to the TPI array
                        allTPIs.push(tpiDetailObj.VehicleTPIDetailObj);
                        _set(transactionVM.value, additionalInfoPath, allTPIs);
                        updateWizardData(transactionVM);
                        checkDuplicateDesignee();
                    })
                    .catch();
            } catch {
                ConsoleHelper('user closed the modal');
            }
        },
        [
            checkDuplicateDesignee,
            showTPIDetailComponent,
            transactionVM,
            updateWizardData,
            viewModelService
        ]
    );

    const openTPILineSearchModal = useCallback(
        (tpiObj, index) => {
            // show modal popover and pass the tpiVM to edit the tpi
            const addlInterestTPIVM = viewModelService.create(
                tpiObj,
                'pc',
                'amfam.edge.capabilities.policyjob.lob.ea.dto.EAAutoLineAddlInterestDTO'
            );

            try {
                showTPIDetailComponent(addlInterestTPIVM, undefined)
                    .then((tpiDetailObj) => {
                        // Now add the tpi detail to the "transactionVM"
                        const allTPIs = _get(transactionVM, 'lobData.personalAuto_EA.additionalInterests.value');

                        // Remove from the index
                        _pullAt(allTPIs, index);
                        // Add to the TPI array
                        allTPIs.push(tpiDetailObj.VehicleTPIDetailObj);
                        _set(transactionVM.value, 'lobData.personalAuto_EA.additionalInterests.value', allTPIs);
                        updateWizardData(transactionVM);
                    })
                    .catch();
            } catch {
                ConsoleHelper('user closed the modal');
            }
        },
        [showTPIDetailComponent, transactionVM, updateWizardData, viewModelService]
    );

    /**
     * Helper function for generating the TPI name 
     * 
     * @param {Object} item An object containing the set of VMNode properties related to the table.
     * @returns {String} TPI Name
     */
    const getTPIName = (item) => {
        let tpiName = '';

        if (item.bankPrimaryName !== undefined) {
            tpiName = item.bankPrimaryName;

            // IAP-1709 : Display full name for searched tpis
            if (!_isEmpty(_trim(item.bankSecondaryName))) {
                tpiName = tpiName.concat(' ', item.bankSecondaryName);
            }

            // IAP-3482 : Display full name for searched tpis
            if (!_isEmpty(_trim(item.bankTertiaryName))) {
                tpiName = tpiName.concat(' ', item.bankTertiaryName);
            }
        } else if (item.company !== undefined) {
            tpiName = item.company.name;
        } else if (item.person !== undefined) {
            tpiName = item.person.firstName.concat(' ', item.person.lastName);
        }

        return tpiName;
    };

    /**
     * Helper callback for checking whether the input person matches the policy's PNI or SNI.
     */
    const isTPIPersonPNIOrSNI = useCallback(
        (TPI) => {
            let notMatchPNIAndSNI = true;

            if (
                TPI.person.publicID
                === transactionVM.lobData.personalAuto_EA.primaryNamedInsured.person.publicID
                    .value
                || (transactionVM.lobData.personalAuto_EA.secondaryNamedInsured.person
                    && TPI.person.publicID
                    === transactionVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID
                        .value)
            ) {
                notMatchPNIAndSNI = false;
            }

            return notMatchPNIAndSNI;
        },
        [transactionVM]
    );


    const updateNewlyAddedTPIAndUpdateWizardData = useCallback(
        async (wrapper) => {
            let allTPIs = [];

            // line level TPI if there is no vehicle id in wrapper
            if (wrapper.vehicleID === undefined) {
                allTPIs = _get(transactionVM, 'lobData.personalAuto_EA.additionalInterests.value', []);
                allTPIs.push(wrapper.VehicleTPIDetailObj);
                _set(transactionVM, 'lobData.personalAuto_EA.additionalInterests.value', allTPIs);
            }
            else {
                // Now add the tpi detail to the "transactionVM"
                const vehicle = _find(
                    transactionVM.lobData.personalAuto_EA.coverables.vehicles.children,
                    (tempVehicle) => {
                        const tempID = `${tempVehicle.publicID.value}${tempVehicle.year.value}${tempVehicle.make.value}${tempVehicle.model.value}`;

                        return tempID === wrapper.vehicleID;
                    }
                );

                allTPIs = _get(vehicle, 'additionalInterests.value', []);

                if (
                    wrapper.VehicleTPIDetailObj.addlInterestType === 'Titleholder_Ext'
                    && wrapper.VehicleTPIDetailObj.titleholderType === 'Individual'
                    && wrapper.VehicleTPIDetailObj.person
                    && !isTPIPersonPNIOrSNI(wrapper.VehicleTPIDetailObj)
                ) {
                    return modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: messages.matchesPNIorSNITitle,
                        message: messages.matchesPNIorSNIMessage
                    }).then(() => { });
                }

                allTPIs.push(wrapper.VehicleTPIDetailObj);
                _set(vehicle, 'additionalInterests.value', allTPIs);
            }

            setIsSavingTPI(true);

            const updateDraftResponse = await updateNewlyAddedTPI(
                transactionVM.value, authHeader
            );

            _set(transactionVM, 'value', updateDraftResponse);
            updateWizardData(transactionVM);
            updateWizardSnapshot(transactionVM);
            setIsSavingTPI(false);
            updateIsSearchTPIVisible(false);
            checkDuplicateDesignee();

            if (setIsAddingTPI) {
                setIsAddingTPI(false);
            }

            return allTPIs;
        },
        [
            authHeader,
            checkDuplicateDesignee,
            isTPIPersonPNIOrSNI,
            setIsSavingTPI,
            transactionVM,
            updateNewlyAddedTPI,
            updateWizardData,
            setIsAddingTPI,
            modalApi,
            updateWizardSnapshot
        ]
    );
    /**
     * Helper callback for removing a TPI record from the TPI table.
     */
    const removeLineTPIRecord = useCallback(
        (path, item, rowToRemove) => {
            const allTPIs = _get(transactionVM, path);

            if (allTPIs.value.length > 0) {
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: e1pCommonMessages.removeTPITitle,
                    message: e1pCommonMessages.removeTPIDescription,
                    confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'THIRD-PARTY INTEREST' }),
                    cancelButtonText: e1pCommonMessages.cancel
                }).then((results) => {
                    if (results !== 'cancel') {
                        _pullAt(allTPIs.value, rowToRemove);
                        _set(transactionVM, path, allTPIs.value);
                        updateWizardData(transactionVM);

                        return transactionVM;
                    }

                    return _noop();
                });
            }
        },
        [transactionVM, updateWizardData, modalApi, translator]
    );

    const removeTPIRecord = useCallback(
        (path, item, rowToRemove) => {
            const vehiclePath = path.concat('additionalInterests');
            const allTPIs = _get(transactionVM, vehiclePath);

            if (allTPIs.value.length > 0) {
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: e1pCommonMessages.removeTPITitle,
                    message: e1pCommonMessages.removeTPIDescription,
                    confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'THIRD-PARTY INTEREST' }),
                    cancelButtonText: e1pCommonMessages.cancel
                }).then((results) => {
                    if (results !== 'cancel') {
                        _pullAt(allTPIs.value, rowToRemove);
                        _set(transactionVM, `${vehiclePath}.value`, allTPIs.value);
                        updateWizardData(transactionVM);

                        return transactionVM;
                    }

                    return _noop();
                });
            }
        },
        [transactionVM, modalApi, translator, updateWizardData]
    );

    /**
     * Helper function for handling when the "Remove Third-Party Interest" button is clicked.
     *
     * @param {*} path An object containing the path to the currently selected vehicle VMNode.
     * @param {*} item An object containing the set of VMNode properties related to the table row.
     * @param {*} rowToRemove A number representing the currently selected table row.
     */
    const removeTPIButtonOnClickHandler = ({ path }, item, rowToRemove) => {
        removeTPIRecord(path, item, rowToRemove);
    };

    /**
     * Helper function for handling when the "Remove Third-Party Interest" button is clicked.
     *
     * @param {*} path An object containing the path to the currently selected vehicle VMNode.
     * @param {*} item An object containing the set of VMNode properties related to the table row.
     * @param {*} rowToRemove A number representing the currently selected table row.
     */
    const removeLineTPIButtonOnClickHandler = ({ path }, item, rowToRemove) => {
        removeLineTPIRecord(path, item, rowToRemove);
    };

    /**
     * Helper function for handling when edit tpi option is clicked
     * 
     * @param {Object} path A path to tpi object
     * @param {Object} item TPI Object 
     * @param {Number} index index of TPI object
     */
    const editTPIButtonOnClickHandler = ({ path }, item, index) => {
        openTPISearchModal(item, index, path)
    }

    /**
     * Helper function for handling when edit tpi option is clicked
     * 
     * @param {Object} path A path to tpi object
     * @param {Object} item TPI Object 
     * @param {Number} index index of TPI object
     */
    const editTPILineButtonOnClickHandler = ({ path }, item, index) => {
        openTPILineSearchModal(item, index, path)
    }

    /**
     * Helper callback for determining whether at least one TPI exists on the policy's vehicles.
     */
    const tpiExistOnPolicy = useCallback(() => {
        const tpiExists = _find(
            transactionVM.lobData.personalAuto_EA.coverables.vehicles.children,
            (tempVehicle) => tempVehicle.additionalInterests.children.length > 0
        );

        return tpiExists !== undefined;
    }, [transactionVM.lobData.personalAuto_EA.coverables.vehicles.children]);

    /**
     * Generate property overrides for dynamic fields on this Jutro component.
     */
    const generateOverrides = useCallback(() => {
        const vehicles = _get(
            transactionVM,
            'lobData.personalAuto_EA.coverables.vehicles.value',
            []
        );

        const overrides = vehicles.map((vehicle, index) => ({
                [`tpiVehicleName${index}`]: {
                    value: `${transactionVM.lobData.personalAuto_EA.coverables.vehicles.children[index].year.value} ${transactionVM.lobData.personalAuto_EA.coverables.vehicles.children[index].make.value} ${transactionVM.lobData.personalAuto_EA.coverables.vehicles.children[index].model.value}`
                },
                [`thirdPartyContainer${index}`]: {
                    visible: vehicle.additionalInterests !== undefined
                },
                [`tpiTableActions${index}`]: {
                    visible: !viewOnlyMode
                },
                thirdPartyInterestCheck: {
                    value: tpiExistOnPolicy(),
                    readOnly: true
                }
            }));

        return Object.assign({}, ...overrides);
    }, [transactionVM, tpiExistOnPolicy, viewOnlyMode]);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'top',
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        addThirdPartyInterestButton: {
            visible: !viewOnlyMode
        },
        eaTPIDisplayContainer: {
            visible: !isSearchTPIVisible
        },
        noTpiHasBeenAdded: {
            visible: _isEmpty(allTPIsForPolicy) && transactionVM.lobData.personalAuto_EA.additionalInterests.value.length === 0
        },
        eaVehicleTPIContainerIterableGrid: {
            visible: !_isEmpty(allTPIsForPolicy)
        },
        eaVehicleTPIHeader: {
            visible: !_isEmpty(allTPIsForPolicy) && transactionVM.lobData.personalAuto_EA.additionalInterests.value.length > 0
        },
        eaLineTPIContainerGrid: {
            visible: transactionVM.lobData.personalAuto_EA.additionalInterests.value.length > 0
        },
        searchTPI: {
            onClick: (() => {
                updateIsSearchTPIVisible(true);

                if (setIsAddingTPI) {
                    setIsAddingTPI(true);
                }
            })
        },
        tpiSearchComponent: {
            visible: isSearchTPIVisible,
            productCode,
            tpiBasePath,
            viewModelService,
            authHeader,
            transactionVM,
            isAddingExistingContact: false,
            existingContact: false,
            isPerson: false,
            updateIsSearchTPIVisible,
            saveThirdPartyInterestClickHandler: updateNewlyAddedTPIAndUpdateWizardData,
            showErrors,
            onValidate,
            disregardFieldValidationParentPage,
            setIsAddingTPI
        },
        addTPIComponentContainer: {
            visible: !viewOnlyMode
        },
        thirdPartyInterestTitle: {
            visible: showTitle
        },
        tpiLineTableActions: {
            visible: !viewOnlyMode
        },
        ...generateOverrides()
    };

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            getTPIName,
            handleTPITableOnCell,
            removeTPIButtonOnClickHandler,
            removeLineTPIButtonOnClickHandler,
            editTPIButtonOnClickHandler,
            editTPILineButtonOnClickHandler
        },
        resolveClassNameMap: styles
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

/**
 * Define expected types for properties to be passed into this Jutro component.
 */
E1PEATPIDisplayTableComponent.propTypes = {
    transactionVM: PropTypes.shape({
        lobData: PropTypes.shape({
            personalAuto_EA: PropTypes.shape({
                primaryNamedInsured: PropTypes.shape({
                    person: PropTypes.shape({
                        publicID: PropTypes.shape({
                            value: PropTypes.string
                        })
                    })
                }),
                secondaryNamedInsured: PropTypes.shape({
                    person: PropTypes.shape({
                        publicID: PropTypes.shape({
                            value: PropTypes.string
                        })
                    })
                }),
                coverables: PropTypes.shape({
                    vehicles: PropTypes.shape({
                        children: PropTypes.arrayOf(PropTypes.shape({}))
                    })
                })
            })
        }),
        baseData: PropTypes.shape({
            policyAddress: PropTypes.shape({
                state: PropTypes.shape({
                    value: PropTypes.shape({
                        code: PropTypes.string
                    })
                })
            })
        }),
        value: PropTypes.shape({})
    }),
    authHeader: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    updateWizardSnapshot: PropTypes.func,
    showErrors: PropTypes.bool.isRequired,
    setIsSavingTPI: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    disregardFieldValidationParentPage: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    setIsAddingTPI: PropTypes.func,
    showTitle: PropTypes.bool
};

/**
 * Define default values for properties to be passed into this Jutro component.
 */
E1PEATPIDisplayTableComponent.defaultProps = {
    transactionVM: {},
    viewOnlyMode: false,
    setIsAddingTPI: undefined,
    showTitle: true,
    updateWizardSnapshot: undefined
};

export default E1PEATPIDisplayTableComponent;
