import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useRef,
    useMemo
} from 'react';
import {
    get, set, filter, map, sortBy, uniqBy, isEmpty, isUndefined
} from 'lodash';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useModal } from '@jutro/components';
import { RewriteService } from 'e1p-capability-rewrite';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { UmbrellaFlowUtil, ClausesUtil as e1pClausesUtil, QuoteProposalUtil, CoverageUtil } from 'e1p-portals-util-js';
import { useUWIssueUtil, useOOSConflictPageLandingUtil } from 'e1p-capability-hooks';
import messages from './QuotePage.messages';
import metadata from './QuotePage.metadata.json5';

function structureClauseTableData(coverage) {
    // putting ID into an object as the Jutro table component expects an object
    return {
        publicID: coverage.publicID
    };
}

function generateClauseData(columnData, coveragePath) {
    return columnData.map(({ lob, code }) => {
        const completeCoveragePath = `coverages.${coveragePath}`;

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

const structureCustomQuote = (rewriteVM, affectedQuote, clauses) => 
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        quote: affectedQuote,
        quoteID: rewriteVM.jobID.value,
        coverages: clauses.personalUmbrella_EU
    })
;

const getCoveragesUniqueID = (rewriteVM) => {
    const offerings = get(rewriteVM, 'lobData.personalUmbrella_EU.offerings.value');
    const coverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.coverages.map(structureClauseTableData)
    )), 'publicID');

    return {
        coverages
    };
};

const generateColumnData = (rewriteVM) => {
    const lobOfferingPath = 'lobData.personalUmbrella_EU.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = get(rewriteVM, `${lobOfferingPath}.value`);
    const quoteOfferings = get(rewriteVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings.map((lobOffering, lobIndex) => {
        const quoteDataIndex = quoteOfferings.findIndex(
            (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
        );
        const quoteData = quoteOfferings[quoteDataIndex];

        return {
            name: lobOffering.branchName,
            code: lobOffering.branchCode,
            quote: {
                path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                data: quoteData
            },
            lob: {
                path: `${lobOfferingPath}.children[${lobIndex}]`,
                data: lobOffering
            }
        };
    });

    return sortBy(columnData, ['code']);
};

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    const quoteOffering = get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
         
        clausesToUpdate = ClausesUtil.structureClausesForServer(lobOffering.coverages, lobName, null);
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

const generateTableData = (rewriteVM, columnData, translator) => {
    const uniqueID = getCoveragesUniqueID(rewriteVM);

    const coverages = {
        header: translator(messages.policyLevelCoverages),
        data: uniqueID.coverages,
        tableContent: generateClauseData(columnData, 'coverages')
    };

    return [coverages];
};

const LOB = 'personalUmbrella_EU';

function QuotePage(props) {
    const modalApi = useModal();
    const {
        wizardData: rewriteVM,
        updateWizardData,
        updateWizardSnapshot,
        stopSkipping,
        jumpTo,
        steps,
        cancel: onCancel,
        isComponentValid,
        currentStepIndex,
        changeNextSteps
    } = props;
    const {
        onValidate,
        disregardFieldValidation,
    } = useValidation('QuotePage');
    const stepsRef = useRef(steps);
    const { opCo } = useContext(AmfamOktaTokenContext);
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const [quoteIsStale, setQuoteIsStale] = useState(false);
    const [isQuoting, setIsQuoting] = useState(false);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isBuying, setIsBuying] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [fieldsChangedOnCoveragePage, setFieldsChangedOnCoveragePage] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const isNewBusiness = get(rewriteVM, 'baseData.value.businessTransactionType_Ext') === 'NewBusiness';
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);
    const [
        hasAlreadyApprovedLiabilityLimitUWIssue,
        setHasAlreadyApprovedLiabilityLimitUWIssue
    ] = useState(false);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const isQuickQuote = useRef(
        get(rewriteVM.value, 'quoteType') === 'Quick'
    ).current;

    useEffect(() => {
        /**
         * Using useRef to access current updated steps.
         * as we are adding new conflicts step and landing user on this newly created step
         * "step" state variable from props does not give
         * us updated value inside useOOSConflictPageLandingUtil
         * it refers initial rendered value only(as we are adding new step and want to land user
         * on new step in)
         */
        stepsRef.current = steps;
         
    }, [steps]);

    // overriding coverage help text for given clause.
    useEffect(() => {
        get(rewriteVM, 'value.lobData.personalUmbrella_EU.offerings[0].coverages.coverages').forEach((clause) => {
            const coveragesHelpText = CoverageUtil.getCoveragesHelpTextForEU(clause);

            if (coveragesHelpText) {
                clause.description = coveragesHelpText
            }
        })
    }, [rewriteVM]);

    const {
        removeOrAddAndLandOnConflictsPage
    } = useOOSConflictPageLandingUtil(
        stepsRef,
        currentStepIndex,
        changeNextSteps,
        jumpTo
    );

    const {
        hasUWIssuesOfType,
        showUnderwritingIssuesPopup
    } = useUWIssueUtil(
        rewriteVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(
                rewriteVM,
                lobPath,
                quotePath,
                lobName,
                false
            );

            return RewriteService.updateCoverages(
                 
                [rewriteVM.jobID.value, { personalUmbrella_EU: customQuote.coverages }],
                authHeader
            ).then((response) => {
                const updatedClauses = get(response, 'lobData.personalUmbrella_EU.offerings[0].coverages');
                const newrewriteVM = viewModelService.clone(rewriteVM);

                // Update the offering status to stale
                // Update local offering with new one from xcenter
                set(rewriteVM, `${lobPath}.coverages`, updatedClauses);

                // Update local quote status with new one from xcenter
                const status = get(response, 'quoteData.offeredQuotes[0].status', 'Draft');

                set(rewriteVM, `${quotePath}.status`, status);
                // Update local errorsAndWarnings with new one from xcenter
                set(rewriteVM, 'errorsAndWarnings', response.errorsAndWarnings);
                // Update premium with new one from xcenter
                set(rewriteVM, `${quotePath}.premium`, response.quoteData.offeredQuotes[0]?.premium?.premium);
                rewriteVM.value = response;

                const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                    rewriteVM, newrewriteVM, `${lobPath}.coverages.coverages`
                );
                const allRemovedFields = [...removedFieldsFromBaseCoverages];

                setQuoteIsStale(true);
                disregardFieldValidation(allRemovedFields);
                updateWizardData(rewriteVM);
            });
        },
        [authHeader, disregardFieldValidation, rewriteVM, updateWizardData, viewModelService]
    );

    const checkuwIssues = useCallback((newestSubmission) => {
        if (hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease', 'Rejected'])) {
            setQuoteIsStale(true);
            showUnderwritingIssuesPopup(newestSubmission);
        }
    }, [hasUWIssuesOfType, showUnderwritingIssuesPopup]);

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);

            return onClauseChange(basePath, lobPath, quotePath);
        },
        [onClauseChange]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(e1pClausesUtil.setClauseValue(rewriteVM, value, changedPath));
        },
        [rewriteVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            changeSubmission(value, changedPath);

            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeSubmission, syncCoverages]
    );

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = get(rewriteVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(rewriteVM, 'lobData.personalUmbrella_EU.paperlessInd.value');
        const paperlessEmail = get(rewriteVM, 'lobData.personalUmbrella_EU.paperlessEmail.value');

        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [rewriteVM, isPaperlessEmailUpdated]);

    const recalculate = useCallback(
        (lobPath, quotePath) => {
            setIsQuoting(true);

            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(
                rewriteVM,
                lobPath,
                quotePath,
                lobName
            );

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (isUndefined(get(rewriteVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value'))
                && !!get(rewriteVM, 'lobData.personalUmbrella_EU.paperlessEmail.value')) {
                set(rewriteVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value',
                    get(rewriteVM, 'lobData.personalUmbrella_EU.paperlessEmail.value'));
            }

            return RewriteService.saveAndQuote([rewriteVM.value], authHeader).then(
                (response) => {
                    setStaleQuoteBranchCode(undefined);
                    setFieldsChangedOnCoveragePage(false);

                    const updatedClauses = get(response, 'lobData.personalUmbrella_EU.offerings[0].coverages');

                    // Update local offering with new one from xcenter
                    set(rewriteVM, `${lobPath}.coverages`, updatedClauses);
                    set(rewriteVM, `${lobPath}.paymentPlans`, response.lobData.personalUmbrella_EU.offerings[0].paymentPlans);

                    const status = get(response, 'quoteData.offeredQuotes[0].status', 'Draft');

                    set(rewriteVM, `${quotePath}.status`, status);
                    // Update local errorsAndWarnings with new one from xcenter
                    set(rewriteVM, 'errorsAndWarnings', response.errorsAndWarnings);
                    // Update premium with new one from xcenter
                    set(rewriteVM, `${quotePath}.premium`, response.quoteData?.offeredQuotes[0]?.premium);
                    set(rewriteVM, 'value.baseData.exceptions_Ext', response.baseData.exceptions_Ext);
                    set(rewriteVM, 'value.conflicts', response.conflicts);
                    set(response, 'quote.premium', response.quoteData.offeredQuotes[0].premium);
                    QuoteProposalUtil.fetchQuoteProposal(
                        rewriteVM,
                        setQuoteProposalCompleted,
                        setQuoteProposalLink,
                        authHeader,
                        setIsQuoteProposalFailed
                    );
                    setQuoteIsStale(false);
                    updateWizardData(rewriteVM);
                    updateWizardSnapshot(rewriteVM);
                    // state update is async, need to directly pass in
                    checkuwIssues(rewriteVM);

                    // E1PAP1PC-13853 :
                    // If we get conflicts in saveAndQuote,
                    // we will add conflicts page if its not present
                    // and user will land on conflicts page. If we had conflicts
                    // and we came back and made changes such that after saveAndQuote
                    // if there are no conflicts we will remove conflicts page if its present
                    const hasConflicts = !isEmpty(get(rewriteVM, 'value.conflicts', []));

                    removeOrAddAndLandOnConflictsPage(hasConflicts);
                    /**
                     * E1PAP1PC-15339 :
                     * Need to set this variable as
                     * we don't want to show info message if UW issue is approved
                     * If uw issue is approved and user comes back on coverage page and changes
                     * LiabilityLimit cov limit >=6000000;
                     * update coverage response does not send uw issues back so we need this
                     */
                    setHasAlreadyApprovedLiabilityLimitUWIssue(
                        UmbrellaFlowUtil.hasLiabilityLimitChangeBlockQuoteUwIssueApproved(
                            get(response, 'errorsAndWarnings.underwritingIssues',
                                get(response, 'errorsAndWarnings_Ext.underwritingIssues', []))
                        )
                    );

                    return response;
                }
            ).catch(() => {
                modalApi.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: 'Something went wrong in recalculate operation',
                    message: ''
                });

                const lobOfferingPath = 'lobData.personalUmbrella_EU.offerings.children[0]';
                const offering = get(rewriteVM, `${lobOfferingPath}.value`);

                setStaleQuoteBranchCode(offering.branchCode);
                setFieldsChangedOnCoveragePage(true);

                return customQuote;
            })
                .finally(() => {
                    setIsQuoting(false);
                });
        },
        [
            rewriteVM, authHeader, updateWizardData, updateWizardSnapshot,
            checkuwIssues, removeOrAddAndLandOnConflictsPage, modalApi
        ]
    );

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const buyNow = useCallback(
        async () => {
            let paperLessIndDetailsValid = rewriteVM.lobData
                .personalUmbrella_EU.paperlessInd.value !== undefined;

            if (rewriteVM.lobData.personalUmbrella_EU.paperlessInd.value) {
                const paperEmail = get(rewriteVM, 'lobData.personalUmbrella_EU.paperlessEmail');

                paperLessIndDetailsValid = paperEmail.value !== undefined
                    && paperEmail.value !== '' && paperEmail.aspects.valid;
            }

            if (!paperLessIndDetailsValid || !isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            updateIsPageSubmitted(false);
            setIsBuying(true);

            const retrievedSubmission = await RewriteService.retrieve(
                [rewriteVM.jobID.value],
                authHeader
            );

            const paperlessInd = get(rewriteVM, `lobData[${LOB}].paperlessInd.value`);
            const autoPay = get(rewriteVM, `lobData[${LOB}].autoPayDiscInd.value`, false);
            // If email was given from paperless modal save it
            const newEmail = get(rewriteVM, `lobData[${LOB}].paperlessEmail.value`, undefined);

            set(retrievedSubmission, `lobData[${LOB}].primaryNamedInsured.person.emailAddress1`, newEmail);
            set(retrievedSubmission, `lobData[${LOB}].paperlessInd`, paperlessInd);
            set(retrievedSubmission, `lobData[${LOB}].paperlessEmail`, newEmail);
            set(retrievedSubmission, `lobData[${LOB}].autoPayDiscInd`, autoPay);

            set(rewriteVM, 'value', retrievedSubmission);
            updateWizardData(rewriteVM);

            return rewriteVM;
        },
        [authHeader, rewriteVM, updateWizardData, isComponentValid]
    );

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isQuoting) {
            loadingMessage = translator(messages.ratingYourPolicyMessage);
        }

 if (isBuying) {
            loadingMessage = translator(messages.creatingYourOfferingMessage);
        }

        return loadingMessage;
    }, [isQuoting, isBuying, translator]);

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();
            window.open(quoteProposalLink, '_blank');
        }, [quoteProposalLink]
    );

    const generateOverrides = useCallback(() => {
        const columnData = generateColumnData(rewriteVM);
        const { jobID } = rewriteVM;

        return {
            '@field': {
                // apply to all fields
                labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                showRequired: true,
                showErrors: isPageSubmitted,
                autoComplete: false
            },
            quotePageLoadingIndicator: {
                loaded: !isBuying && !isQuoting,
                text: getLoadingIndicatorMessage
            },
            quotePageContainer: {
                visible: !isBuying && !isQuoting,
            },
            quoteProposalLinkContainer: {
                visible: isNewBusiness
            },
            quoteTable: {
                columnData,
                tableData: generateTableData(rewriteVM, columnData, translator),
                underwritingIssues: get(rewriteVM, 'errorsAndWarnings.underwritingIssues.value') === undefined ? [] : get(rewriteVM, 'errorsAndWarnings.underwritingIssues.value'),
                quoteID: jobID.value,
                submissionVM: rewriteVM,
                updateWizardData,
                authHeader,
                quoteIsStale,
                onCancel,
                onViewQuoteProposal: openDocument,
                isQuoteProposalReady: quoteProposalCompleted && !fieldsChangedOnCoveragePage && !quoteIsStale,
                isPageSubmitted,
                updateIsPageSubmitted,
                onValidate,
                setFieldsChangedOnCoveragePage,
                fieldsChangedOnCoveragePage,
                modifiers: get(rewriteVM, 'lobData.personalUmbrella_EU.modifiers.value', []),
                authUserData,
                setIsPaperlessEmailUpdated,
                opCo
            },
            personalLiabilityLimitUwIssueInfoMessageDiv: {
                visible: UmbrellaFlowUtil
                    .isPersonalLiabilityLimitUwIssueInfoMessage(
                        rewriteVM,
                        hasAlreadyApprovedLiabilityLimitUWIssue
                    )
            },
            paperlessEmailChangedMessageDiv: {
                visible: showPaperlessEmailMessage()
            },
            quoteProposalFailureErrorDiv: {
                visible: isQuoteProposalFailed
            },
        };
    }, [
        rewriteVM, breakpoint, isPageSubmitted, isBuying,
        isQuoting, getLoadingIndicatorMessage, hasAlreadyApprovedLiabilityLimitUWIssue,
        translator, updateWizardData, authHeader, quoteIsStale,
        onCancel, onValidate, fieldsChangedOnCoveragePage, authUserData, openDocument,
        isNewBusiness, quoteProposalCompleted, isQuoteProposalFailed, opCo, showPaperlessEmailMessage
    ]);

    useEffect(() => {
        const offerings = get(rewriteVM, 'quoteData.offeredQuotes.value');
        const draftOffers = filter(offerings, ['status', 'Draft']);
        const draftBranchCodes = map(draftOffers, 'branchCode');

        setStaleQuoteBranchCode(draftBranchCodes);

        // Above logic only needs to run once when component is mounted
        if (rewriteVM.lobData.personalUmbrella_EU.paperlessInd.value === undefined
            && get(rewriteVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'directentry') {
            set(rewriteVM, 'lobData.personalUmbrella_EU.paperlessInd.value', true);
        }

        setQuoteIsStale(hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease']));
        /**
         * E1PAP1PC-15339 :
         * Need to set this variable as
         * we don't want to show info message if UW issue is approved
         * If uw issue is approved and user comes back on coverage page and changes
         * LiabilityLimit cov limit >=6000000;
         * update coverage response does not send uw issues back so we need this
         */
        setHasAlreadyApprovedLiabilityLimitUWIssue(
            UmbrellaFlowUtil.hasLiabilityLimitChangeBlockQuoteUwIssueApproved(
                get(rewriteVM, 'value.errorsAndWarnings.underwritingIssues',
                    get(rewriteVM, 'value.errorsAndWarnings_Ext.underwritingIssues', []))
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // on mount get quote proposal
        QuoteProposalUtil.fetchQuoteProposal(
            rewriteVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );
        // eslint-disable-next-line no-warning-comments
        // FIXME: Weird skipping issue from GW wizard platform code
        stopSkipping();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);

    return (
        <WizardPage
            showNext={false}
            showPrevious={false}
            showCancel={false}
            skipWhen={() => !isQuickQuote}
            isPageSubmittedWithErrors={isPageSubmitted}
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveCallbackMap: {
                        onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
                        onStaleQuoteBranchCode,
                        onRecalculate: recalculate,
                        onChangeSubmissionAndSync: changeSubmissionAndSync,
                        onChangeSubmission: changeSubmission,
                        onSyncCoverages: syncCoverages,
                        onPrint: handlePrint
                    },
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={rewriteVM}
                        overrideProps={generateOverrides()}
                        onModelChange={updateWizardData}
                        callbackMap={resolvers.resolveCallbackMap}
                        onValidationChange={onValidate}
                    />
                );
            }}
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
