import React, {
    useCallback, useContext, useEffect, useMemo, useState, useRef
} from 'react';
import {
    filter as _filter,
    get as _get,
    includes as _includes,
    set as _set,
    isEmpty as _isEmpty,
    cloneDeep as _cloneDeep
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import {
    useEffectiveDateUtil,
    useNewBusinessUtil,
    useSniUtil,
    e1pUSStatesUtil,
    e1pDateUtil,
    useCreditReportUtil
} from 'e1p-capability-hooks';
import { RewriteService } from 'e1p-capability-rewrite';
import moment from 'moment';
import appConfig from 'app-config';
import messages from './RewriteEHPolicyDetailsPage.messages';
import metadata from './RewriteEHPolicyDetailsPage.metadata.json5';
import requiredMetadata from './RewriteEHPolicyDetailsPage.requiredness';

const LOB = 'homeowners_EH';

function InsuredPage(props) {
    const modalApi = useModal();
    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerInitialComponentValidation,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation('InsuredPage');
    const {
        wizardData: rewriteVM,
        updateWizardData,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    // Need the producer code details to be undefined for the external producer code lookup
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [requiredFields, updateRequiredFields] = useState([]);
    const [lapseInCoverage, updateLapseInCoverage] = useState(undefined);
    const [lastPolicyExpirationDate, updateLastPolicyExpirationDate] = useState(undefined);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [updateDraftInProgress, setUpdateDraftInProgress] = useState(false);
    const hasPerformedAccountSearch = useRef(false);
    const [USStates, setUSStates] = useState([]);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const policyState = _get(rewriteVM, 'baseData.policyAddress.state.value.code');
    const policyType = _get(rewriteVM, 'lobData.homeowners_EH.policyType.value.code');
    const isPolicyTypeHO4 = _get(rewriteVM, 'lobData.homeowners_EH.policyType.value.code') === 'HO4';
    const [initialPniPublicId, setInitialPniPublicId] = useState(false);
    const [initialHouseholdMembers, setInitialHouseholdMembers] = useState([]);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const { daysInFutureForBookroll, daysInFuture } = appConfig.dateInputConfig;

    const {
        handlePeriodStartDateChange,
        getDefaultPeriodEndDate,
        getEffectiveDateBoundsForSubmission
    } = useEffectiveDateUtil(rewriteVM, updateWizardData);

    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');

    const {
        getLocationAndProducerCodes,
        createPrimaryNamedInsuredVM,
        getProducerDetailsFromProducerCodes
    } = useNewBusinessUtil(
        rewriteVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        setLocationAndProducerCodes
    );

    const { createSecondaryNamedInsuredVM, removeSni, addSniInCoverable } = useSniUtil(
        rewriteVM,
        updateWizardData,
        viewModelService,
        LOB,
        disregardFieldValidation
    );

    const {
        showReportPopup
    } = useCreditReportUtil(
        translator,
        LOB
    );

    const markAccountSearchCompleted = () => {
        hasPerformedAccountSearch.current = true;
    };
    const usageType = translator({ id: _get(rewriteVM, 'lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value.name') });
    const policyForm = _get(rewriteVM, 'lobData.homeowners_EH.policyFormType.value');

    useEffect(() => {
        const stateValues = e1pUSStatesUtil.getUSStates(viewModelService);

        setUSStates(stateValues);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkAcknowledgementsPresents = useCallback(() => {
        if (rewriteVM.value.lobData.homeowners_EH.acknowledgements === undefined) {
            return false;
        }

        return rewriteVM.value.lobData.homeowners_EH.acknowledgements.every(
            (acknowledgement) => acknowledgement.acknowledgeAnswerType === 'accept'
        );
    }, [rewriteVM.value.lobData.homeowners_EH.acknowledgements]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted && checkAcknowledgementsPresents()) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted, checkAcknowledgementsPresents]);

    const { maxEffectiveDate, minEffectiveDate } = useMemo(
        () => 
            // If Book Transfer is Yes, allow quoting up to 120 days in the future, otherwise 60 days 
             bookTransferIndicator ? getEffectiveDateBoundsForSubmission(daysInFutureForBookroll)
                : getEffectiveDateBoundsForSubmission(daysInFuture)
        ,
        [getEffectiveDateBoundsForSubmission, bookTransferIndicator, daysInFutureForBookroll, daysInFuture]
    );

    const availablePolicyTypes = useMemo(() => {
        const { availableValues } = rewriteVM.lobData.homeowners_EH.policyType.aspects;
        const ehLinePolicyTypes = _filter(availableValues, (availableValue) => availableValue.belongsToCategory({
                code: 'EHLine_Ext',
                typelist: { name: 'PolicyLine' }
            }));

        return ehLinePolicyTypes.map((ehLinePolicyType) => ({ code: ehLinePolicyType.code, name: translator({ id: ehLinePolicyType.name }) }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Get PNI and SNI person information on load of page
        // used for determining if we want to make credit report call later
        setInitialPniPublicId(_get(rewriteVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`));

        const existingHouseholdOccupants = _cloneDeep(_get(rewriteVM.value, 'lobData.homeowners_EH.coverables.householdOccupants'));

        setInitialHouseholdMembers(existingHouseholdOccupants);

        // Only run on initial render. Helper will be called again from
        //   the state fields onChange handler
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (rewriteVM.baseData.quoteSource_Ext.sourceType === undefined) {
            const model = {
                sourceType: 'directentry',
                sourceName: 'msaagentsonly'
            };

            rewriteVM.baseData.quoteSource_Ext.value = model;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            rewriteVM.lobData.homeowners_EH.priorPolicies.value !== undefined
            && rewriteVM.lobData.homeowners_EH.priorPolicies.length > 0
        ) {
            if (
                rewriteVM.lobData.homeowners_EH.priorPolicies.value[0].policyExpirationDate
                !== undefined
            ) {
                updateLastPolicyExpirationDate(
                    e1pDateUtil.convertToUTC(
                        _get(
                            rewriteVM,
                            'lobData.homeowners_EH.priorPolicies.value[0].policyExpirationDate'
                        )
                    )
                );
            }

            updateLapseInCoverage(
                !rewriteVM.lobData.homeowners_EH.priorPolicies.value[0].continuousCoverageInd
            );
        }

        updateWizardData(rewriteVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCreditReportStatus = useCallback(
        (quoteID) => showReportPopup(
                quoteID,
                authHeader,
                rewriteVM
            ),
        [authHeader, showReportPopup, rewriteVM]
    );


    useEffect(() => {
        const initialRequiredFields = ['usageType']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
         
    }, [policyState]);

    const lastPolicyExpirationDateChange = useCallback(
        (value) => {
            if (value !== undefined) {
                updateLastPolicyExpirationDate(value);

                if (rewriteVM.lobData.homeowners_EH.priorPolicies.value === undefined) {
                    _set(rewriteVM, 'value.lobData.homeowners_EH.priorPolicies', []);
                }

                if (rewriteVM.lobData.homeowners_EH.priorPolicies.length === 0) {
                    const priorPolicy = {
                        policySource: 'ManuallyAdded',
                        integrationId: '',
                        policyRole: 'PolicyPriNamedInsured'
                    };
                    const priorPolicyVM = viewModelService.create(
                        priorPolicy,
                        'pc',
                        'amfam.edge.capabilities.policyjob.common.priorpolicy.dto.PriorPolicyDTO'
                    );

                    rewriteVM.lobData.homeowners_EH
                        .priorPolicies.value.push(priorPolicyVM.value);
                }

                _set(
                    rewriteVM.value,
                    `lobData.homeowners_EH.priorPolicies[${0}].policyExpirationDate`,
                    moment(new Date(value.year, value.month, value.day))
                        .format('YYYY-MM-DDTHH:mm:ss')
                        .concat('Z')
                );
                updateWizardData(rewriteVM);
            }
        },
        [updateWizardData, rewriteVM, viewModelService]
    );
    const lapseInCoverageChange = useCallback(
        (value) => {
            updateLapseInCoverage(value);

            if (rewriteVM.lobData.homeowners_EH.priorPolicies.value === undefined) {
                _set(rewriteVM, 'value.lobData.homeowners_EH.priorPolicies', []);
            }

            if (rewriteVM.lobData.homeowners_EH.priorPolicies.length === 0) {
                const priorPolicy = {
                    policySource: 'ManuallyAdded',
                    integrationId: '',
                    policyRole: 'PolicyPriNamedInsured'
                };
                const priorPolicyVM = viewModelService.create(
                    priorPolicy,
                    'pc',
                    'amfam.edge.capabilities.policyjob.common.priorpolicy.dto.PriorPolicyDTO'
                );

                rewriteVM.lobData.homeowners_EH.priorPolicies.value.push(priorPolicyVM.value);
            }

            _set(
                rewriteVM.value,
                `lobData.homeowners_EH.priorPolicies[${0}].continuousCoverageInd`,
                !value
            );
            updateWizardData(rewriteVM);
        },
        [rewriteVM, updateWizardData, viewModelService]
    );

    const onNext = useCallback(async () => {
        try {
            const shouldGoNext = isComponentValid && checkAcknowledgementsPresents();

            if (!shouldGoNext) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setUpdateDraftInProgress(true);

            const producerCode = _get(rewriteVM, 'baseData.producerCode_Ext.value');

            // sync NI changes to householdoccupants
            //   changes to NI will not override data in the occ so must be synced
            const householdOccupants = _get(rewriteVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
            const pni = _get(rewriteVM.value, 'lobData.homeowners_EH.primaryNamedInsured');
            const sni = _get(rewriteVM.value, 'lobData.homeowners_EH.secondaryNamedInsured');
            const pniOccupant = householdOccupants
                .find((driver) => driver.person.publicID === pni.person.publicID);
            // SNI may be undefined so being null safe there
            const sniOccupant = householdOccupants
                .find((driver) => driver.person.publicID === sni?.person?.publicID);

            if (pniOccupant) {
                // updates to existing pni contact
                _set(pniOccupant, 'person', pni.person);
            }

            if (sni && sniOccupant) {
                // updates to existing sni contact
                _set(sniOccupant, 'person', sni.person);
            }

            if (pni && !pniOccupant) {
                // check first in the existing household occupants before creating new
                if (!_isEmpty(initialHouseholdMembers)) {
                    const initialPniOccupant = initialHouseholdMembers
                        .find((occupant) => occupant.person.publicID === pni.person.publicID);

                    if (initialPniOccupant) {
                        _set(initialPniOccupant, 'person', pni.person);
                        _set(initialPniOccupant, 'relationshipToNI', undefined);
                        householdOccupants.push(initialPniOccupant);
                    } else {
                        householdOccupants.push({ person: pni.person });
                    }
                } else {
                    householdOccupants.push({ person: pni.person });
                }

                // only update this if pni/sni changes
                _set(rewriteVM, `lobData[${LOB}].coverables.householdOccupants`, householdOccupants);
            }

            if (sni && !sniOccupant) {
                // check first in the existing household occupants before creating new
                if (!_isEmpty(initialHouseholdMembers)) {
                    const initialSniOccupant = initialHouseholdMembers
                        .find((occupant) => occupant.person.publicID === sni?.person?.publicID);

                    if (initialSniOccupant) {
                        _set(initialSniOccupant, 'person', sni.person);
                        _set(initialSniOccupant, 'relationshipToNI', sni.relationshipToNI);
                        householdOccupants.push(initialSniOccupant);
                    } else {
                        householdOccupants.push(
                            { person: sni.person, relationshipToNI: sni.relationshipToNI }
                        );
                    }
                } else {
                    householdOccupants.push(
                        { person: sni.person, relationshipToNI: sni.relationshipToNI }
                    );
                }

                // only update this if pni/sni changes
                _set(rewriteVM, `lobData[${LOB}].coverables.householdOccupants`, householdOccupants);
            }

            _set(rewriteVM, 'flowStepIDs_Ext.value', ['namedinsured']);
            _set(rewriteVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);
            _set(rewriteVM, 'baseData.accountHolder.person.value', pni.person);
            rewriteVM.value = await RewriteService.updateDraftRewrite(
                [rewriteVM.value],
                authHeader
            );
            _set(rewriteVM, 'baseData.producerCode_Ext.value', producerCode);
            updateWizardData(rewriteVM);

            // If PNI changed then only call credit report
            if (initialPniPublicId !== _get(rewriteVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`)) {
                if (!(await getCreditReportStatus(_get(rewriteVM, 'jobID.value')))) {
                    setUpdateDraftInProgress(false);

                    return false;
                }
            }

            setUpdateDraftInProgress(false);

            return rewriteVM;
        } catch {
            setUpdateDraftInProgress(false);
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: commonMessages.genericError,
                message: commonMessages.genericErrorMessage,
                messageProps: {
                    confirmButtonText: commonMessages.cancelModel
                },
                showCancelBtn: false
            });

            return false;
        }
    }, [
        isComponentValid, checkAcknowledgementsPresents,
        rewriteVM, initialPniPublicId, authHeader,
        getCreditReportStatus, initialHouseholdMembers,
        updateWizardData, modalApi
    ]);

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, rewriteVM, updateWizardSnapshot]
    );

    const doesSubmissionExist = useCallback(() => !!_get(rewriteVM.value, 'jobID'), [rewriteVM]);

    const isPageValid = useCallback(() => doesSubmissionExist(), [doesSubmissionExist]);

    useEffect(
        () => {
            registerInitialComponentValidation(isPageValid);
        },
        [
            registerInitialComponentValidation,
            registerComponentValidation,
            isPageValid
        ]
    );

    const handleAddressValueChange = useCallback(
        (newVal, localPath) => {
            _set(rewriteVM, localPath, newVal);
            updateWizardData(rewriteVM);
        },
        [rewriteVM, updateWizardData]
    );

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(rewriteVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(rewriteVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    useEffect(() => {
        // get available producer codes for given state
        if (policyState && isAgent) {
            // only for agents
            getAndSetLocationCodes(policyState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const isProducerCodeDisabled = useMemo(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(rewriteVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(rewriteVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);

            return true;
        }

        return false;
    }, [locationAndProducerCodes, rewriteVM]);

    useEffect(() => {
        const {
            producerCode
        } = producerCodeDetails;
        const existingProducerCode = _get(
            rewriteVM,
            'value.baseData.producerCode_Ext',
            rewriteVM.value.baseData.producerCode
        );

        if (existingProducerCode && isAgent
            && existingProducerCode !== producerCode) {
            (async () => {
                // only for agents
                const details = await getProducerDetailsFromProducerCodes(
                    rewriteVM.value.baseData.producerCode_Ext,
                    locationAndProducerCodes.producerCodes,
                    rewriteVM.value.baseData.externalID_Ext
                );

                // in case we dont get any response from service
                if (details) {
                    setProducerCodeDetails(details);
                    setAgencyName(details.agencyName);
                    setBookTransferIndicator(details.bookTransferIndicator);
                    setServiceCenterIndicator(details.serviceCenterIndicatorValue);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewriteVM.baseData.producerCode_Ext.value]);

    const locationCodeChange = useCallback(
        (value) => {
            _set(rewriteVM, 'baseData.externalID_Ext', value);

            const selectedProducerCode = locationAndProducerCodes?.producerCodes
                ?.find((producerCode) => producerCode?.externalId_Ext === value);

            _set(rewriteVM, 'baseData.producerCode_Ext', selectedProducerCode?.code);
            updateWizardData(rewriteVM);
        },
        [rewriteVM, updateWizardData, locationAndProducerCodes]
    );

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, address, phone, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            _set(rewriteVM, 'baseData.producerCode_Ext', producerCode);
            _set(rewriteVM, 'baseData.externalID_Ext.value', externalID);
            setProducerCodeDetails({ address, phone });
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [rewriteVM],
    );

    const swapPniSni = useCallback(() => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: translator(e1pCommonMessages.swapPniSniTitle),
            message: translator(e1pCommonMessages.swapPniSniMessage)
        }).then(
            (result) => {
                if (result !== 'cancel') {
                    const pniPerson = {
                        ..._get(
                            rewriteVM,
                            'lobData.homeowners_EH.primaryNamedInsured.person.value'
                        )
                    };
                    const sniPerson = {
                        ..._get(rewriteVM, 'lobData.homeowners_EH.secondaryNamedInsured.person.value')
                    };

                    let householdOccupants = _get(rewriteVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
                    let sniOccupant = householdOccupants
                        .find((occupant) => occupant.person.publicID === sniPerson?.publicID);

                    if (!sniOccupant) {
                        addSniInCoverable();
                        householdOccupants = _get(rewriteVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
                        sniOccupant = householdOccupants
                            .find((occupant) => occupant.person.publicID === sniPerson.publicID);
                    }

                    const pniOccupant = householdOccupants
                        .find((occupant) => occupant.person.publicID === pniPerson.publicID);

                    const relationshipToNI = _get(rewriteVM, 'value.lobData.homeowners_EH.secondaryNamedInsured.relationshipToNI', 'notrelated');

                    _set(pniOccupant, 'relationshipToNI', relationshipToNI);
                    _set(sniOccupant, 'relationshipToNI', undefined);
                    // swap PNI and SNI
                    _set(
                        rewriteVM,
                        'lobData.homeowners_EH.primaryNamedInsured.person.value',
                        sniPerson
                    );
                    _set(
                        rewriteVM,
                        'lobData.homeowners_EH.secondaryNamedInsured.person.value',
                        pniPerson
                    );
                    // since we are swapping PNI and SNI, we need remove publicID, FixedID and integrationID from both policyContactRoles
                    // i.e PNI and SNI
                    _set(rewriteVM, 'lobData.homeowners_EH.primaryNamedInsured.fixedId.value', undefined);
                    _set(rewriteVM, 'lobData.homeowners_EH.primaryNamedInsured.publicID.value', undefined);
                    _set(rewriteVM, 'lobData.homeowners_EH.primaryNamedInsured.integrationId.value', undefined);
                    _set(rewriteVM, 'lobData.homeowners_EH.secondaryNamedInsured.fixedId.value', undefined);
                    _set(rewriteVM, 'lobData.homeowners_EH.secondaryNamedInsured.publicID.value', undefined);
                    _set(rewriteVM, 'lobData.homeowners_EH.secondaryNamedInsured.integrationId.value', undefined);

                    // If paperless Email id exist and pni email id is undefiend
                    // then update pni email to paperless email
                    if (!_get(rewriteVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value')
                        && !!_get(rewriteVM, 'lobData.homeowners_EH.paperlessEmail.value')) {
                        _set(rewriteVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value',
                            _get(rewriteVM, 'lobData.homeowners_EH.paperlessEmail.value'));
                    }

                    updateWizardData(rewriteVM);
                }
            },
            () => { }
        );
    }, [addSniInCoverable, rewriteVM, translator, updateWizardData, modalApi]);

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate,
            onRemoveSni: removeSni,
            onMarkAccountSearchCompleted: markAccountSearchCompleted,
            onHandleProducerApiResponse: handleProducerApiRespone,
            onSwapPniSni: swapPniSni
        }
    };

    const handleAcknowledgementsValueChange = useCallback(
        (value) => {
            _set(rewriteVM, `lobData[${LOB}].acknowledgements`, value);
            updateWizardData(rewriteVM);
        },
        [updateWizardData, rewriteVM]
    );

    /**
     * Helper memo for dynamically generate
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isStandardizingAddress) {
            loadingMessage = translator(messages.standardizingAddressMessage);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else {
            loadingMessage = translator(messages.loadingNextPageMessage);
        }

        return loadingMessage;
    }, [isStandardizingAddress, isSavingCurrentPageChanges, translator]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        policyDetailsPageLoadingIndicator: {
            loaded:
                !isStandardizingAddress
                && !updateDraftInProgress
                && !isSavingCurrentPageChanges,
            text: getLoadingIndicatorMessage
        },
        policyDetailsPageContainer: {
            visible:
                !isStandardizingAddress
                && !updateDraftInProgress
                && !isSavingCurrentPageChanges
        },
        submissionPolicyType: {
            availableValues: availablePolicyTypes
        },
        coverageStartDate: {
            updateDateDto: handlePeriodStartDateChange,
            dateDTO: rewriteVM.baseData.periodStartDate,
            defaultToToday: true,
            minDate: minEffectiveDate,
            maxDate: maxEffectiveDate,
            showErrors: isPageSubmitted,
            isRequired: true,
        },
        coverageEndDate: {
            dateDTO: rewriteVM.baseData.periodEndDate,
            updateDateDto: () => updateWizardData(rewriteVM),
            readOnly: true,
            defaultValue: getDefaultPeriodEndDate
        },
        personalInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        originalEffectiveDate: {
            dateDTO: rewriteVM.baseData.periodStartDate,
            updateDateDto: () => updateWizardData(rewriteVM),
            readOnly: true,
        },
        lastPolicyExpirationDate: {
            dateDTO: lastPolicyExpirationDate,
            updateDateDto: lastPolicyExpirationDateChange,
            isRequired: !isPolicyTypeHO4
        },
        ExternalProducerDetailsComponent: {
            defaultValue: rewriteVM.baseData.externalID_Ext?.value,
            visible: !isAgent
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            disabled: isProducerCodeDisabled,
            onValueChange: locationCodeChange,
            visible: isAgent
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        sniContainer: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        removeSni: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        policyState: {
            availableValues: e1pUSStatesUtil.getStateValues(USStates, translator)
        },
        changePniComponent: {
            accountNumber: _get(rewriteVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(rewriteVM, 'baseData.accountNumber.value'),
            excludedContacts: [
                _get(rewriteVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            householdOccupants: _get(
                rewriteVM.value,
                `lobData[${LOB}].coverables.householdOccupants`,
                undefined
            )
        },
        changeSniComponent: {
            accountNumber: _get(rewriteVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(rewriteVM, 'baseData.accountNumber.value')
                && !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            excludedContacts: [
                _get(rewriteVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            householdOccupants: _get(
                rewriteVM.value,
                `lobData[${LOB}].coverables.householdOccupants`,
                undefined
            )
        },
        newHomePurchaseMainDiv: {
            visible: policyType === 'HO3' || policyType === 'HF9'
        },
        insuranceHistoryMainDiv: {
            visible: !isPolicyTypeHO4
        },
        addSni: {
            visible: !_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        secondaryNamedInsured: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            showErrors: isPageSubmitted
        },
        mailingAndBillingAddressComponent: {
            transactionVM: rewriteVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            showErrors: isPageSubmitted
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        coverageLapseAndExpirationDate: {
            visible: !isPolicyTypeHO4,
            readOnly: true
        },
        coverageLapse: {
            value: lapseInCoverage,
            onValueChange: lapseInCoverageChange,
            disabled: true
        },
        usageType: {
            value: usageType,
            visible: !isPolicyTypeHO4
        },
        e1pAcknowledgementComponent: {
            acknowledgements: _get(rewriteVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: _get(rewriteVM, 'baseData.policyAddress.state.value.code'),
                name: translator({ id: _get(rewriteVM, 'baseData.policyAddress.state.value.name') })
            },
            onValueChange: handleAcknowledgementsValueChange,
            policyType,
            showErrors: isPageSubmitted && !checkAcknowledgementsPresents(),
            lob: LOB
        },
        sourceType: {
            disabled: true
        },
        policyForm: {
            visible: usageType !== undefined || isPolicyTypeHO4,
            value: policyForm
        },
        primaryNamedInsured: {
            showErrors: isPageSubmitted,
            isPNI: true
        },
        dateOfBirth: {
            updateDateDto: () => updateWizardData(rewriteVM),
            dateDTO: rewriteVM.baseData?.accountHolder?.person?.dateOfBirth,
            isRequired: _includes(requiredFields, 'dateOfBirth'),
            maxDate: (() => {
                const today = new Date();

                return { day: today.getDate(), month: today.getMonth(), year: today.getFullYear() };
            })(),
        },
        insuredResidenceAddress: {
            addressVM: _get(rewriteVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate,
            showErrors: isPageSubmitted,
            onAddressChange: (value, path) => handleAddressValueChange(value, `baseData.policyAddress.${path}`),
            viewOnlyMode: true,
            showParentLoader: setIsStandardizingAddress
        },
        riskAddressComponent: {
            submissionVM: rewriteVM,
            updateWizardData,
            onValidate,
            viewModelService,
            viewOnlyMode: !isPolicyTypeHO4,
            showErrors: isPageSubmitted
        },
        ehPriorAddressComponent: {
            submissionVM: rewriteVM,
            updateWizardData,
            onValidate,
            viewModelService,
            createPrimaryNamedInsuredVM,
            visible: policyType === 'HO3' || policyType === 'HF9',
            disregardFieldValidation,
            LOB,
            showErrors: isPageSubmitted
        },
        swapPniSni: {
            visible: !!_get(rewriteVM, `value.lobData[${LOB}].secondaryNamedInsured.person.publicID`)
                && !!_get(rewriteVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`)
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, rewriteVM, id, path, overrideProps),
        [overrideProps, rewriteVM]
    );

    const isPageSubmittedWithErrors = isPageSubmitted
        && (!isComponentValid || !checkAcknowledgementsPresents());

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmittedWithErrors}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

InsuredPage.propTypes = wizardProps;

export default withAuthenticationContext(InsuredPage);
