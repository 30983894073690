import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'e1p.platform.common.CANCEL',
        defaultMessage: 'CANCEL'
    },
    discard: {
        id: 'e1p.platform.common.DISCARD',
        defaultMessage: 'DISCARD'
    },
    exit: {
        id: 'e1p.platform.common.EXIT',
        defaultMessage: 'EXIT'
    },
    save: {
        id: 'e1p.platform.common.Save',
        defaultMessage: 'Save'
    },
    discardChanges: {
        id: 'e1p.platform.common.Discard Changes?',
        defaultMessage: 'Discard Changes?'
    },
    exitTransaction: {
        id: 'e1p.platform.common.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    unsavedChangeDiscard: {
        id: 'e1p.platform.common.Any unsaved changes will be discarded.',
        defaultMessage: 'Any unsaved changes will be discarded.'
    },
    infoSavedAsDraft: {
        id: 'e1p.platform.common.The information entered will be saved as a draft.',
        defaultMessage: 'The information entered will be saved as a draft.'
    },
    savingCurrentPageChanges: {
        id: 'e1p.platform.common.Saving Current Page Changes...',
        defaultMessage: 'Saving Current Page Changes...'
    },
    genericErrorText: {
        id: 'e1p.platform.common.An Error occurred. Please try again later. If the error persists, contact customer service.',
        defaultMessage: 'An Error occurred. Please try again later. If the error persists, contact customer service.'
    },
    genericDeleteModalMessage: {
        id: 'e1p.platform.Confirm Delete',
        defaultMessage: 'Please Confirm Delete.'
    },
    ok: {
        id: 'e1p.platform.common.OK',
        defaultMessage: 'OK'
    },
    confirm: {
        id: 'e1p.platform.common.CONFIRM',
        defaultMessage: 'CONFIRM'
    },
    signatureRequiredInfoMessage: {
        id: 'e1p.platform.common.signatureRequired.Signature(s) required prior to issuance. Please navigate to the Signature tab to complete the signature process.',
        defaultMessage: 'Signature(s) required prior to issuance. Please navigate to the Signature tab to complete the signature process.'
    },
    missingDataDash: {
        id: 'e1p.platform.common.-',
        defaultMessage: '-'
    },
    errorOccurredRetrievingCreditReports: {
        id: 'e1p.platform.common.Error Occurred While Retrieving Credit Reports',
        defaultMessage: 'Error Occurred While Retrieving Credit Reports'
    },
    priorAddress: {
        id: 'e1p.platform.common.Prior Address',
        defaultMessage: 'Prior Address'
    },
    bookTransferIndicator: {
        id: 'e1p.platform.common.Book Transfer',
        defaultMessage: 'Book Transfer'
    },
    serviceCenterIndicator: {
        id: 'e1p.platform.common.Service Center',
        defaultMessage: 'Service Center'
    },
    primaryNamedInsured: {
        id: 'e1p.platform.common.Primary Named Insured',
        defaultMessage: 'Primary Named Insured'
    },
    secondaryNamedInsured: {
        id: 'e1p.platform.common.Secondary Named Insured',
        defaultMessage: 'Secondary Named Insured'
    },
    dateOfBirth: {
        id: 'e1p.platform.common.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    premium: {
        id: 'e1p.platform.common.Premium',
        defaultMessage: 'Premium'
    },
    subTotal: {
        id: 'e1p.platform.common.Subtotal',
        defaultMessage: 'Subtotal'
    },
    supportingPolicyToggle: {
        id: 'e1p.platform.common.Supporting Policies',
        defaultMessage: 'Supporting Policies'
    },
    yes: {
        id: 'e1p.platform.common.Yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'e1p.platform.common.No',
        defaultMessage: 'No'
    },
    supportingModalCancel: {
        id: 'e1p.platform.common.Cancel',
        defaultMessage: 'Cancel'
    },
    close: {
        id: 'e1p.platform.common.Close',
        defaultMessage: 'Close'
    },
    supportingModalSave: {
        id: 'e1p.platform.common.Save & Close',
        defaultMessage: 'Save & Close'
    },
    supportingModalAddPolicy: {
        id: 'e1p.platform.common.Add Policy',
        defaultMessage: 'Add Policy'
    },
    supportingModalPolicyLine: {
        id: 'e1p.platform.common.Policy Line',
        defaultMessage: 'Policy Line'
    },
    supportingModalPolicyType: {
        id: 'e1p.platform.common.Policy Type',
        defaultMessage: 'Policy Type'
    },
    supportingModalPolicySource: {
        id: 'e1p.platform.common.Source',
        defaultMessage: 'Source'
    },
    supportingModalPolicyStatus: {
        id: 'e1p.platform.common.Policy Status',
        defaultMessage: 'Policy Status'
    },
    supportingModalPolicyNumber: {
        id: 'e1p.platform.common.Policy Number',
        defaultMessage: 'Policy Number'
    },
    expirationDate: {
        id: 'e1p.platform.common.Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    effectiveDate: {
        id: 'e1p.platform.common.Effective Date',
        defaultMessage: 'Effective Date'
    },
    policyOrQuoteNumber: {
        id: 'e1p.platform.common.Policy/Quote Number',
        defaultMessage: 'Policy/Quote Number'
    },
    noneProvided: {
        id: 'e1p.platform.common.None Provided',
        defaultMessage: 'None Provided'
    },
    reviewChanges: {
        id: 'e1p.platform.common.Review Changes',
        defaultMessage: 'Review Changes'
    },
    performingQuoteOperation: {
        id: 'e1p.platform.common.Performing Quote Operation...',
        defaultMessage: 'Performing Quote Operation...'
    },
    updatedBy: {
        id: 'e1p.platform.common.Updated By',
        defaultMessage: 'Updated By'
    },
    seeWhatsIncluded: {
        id: 'e1p.platform.common.SEE WHAT’S INCLUDED',
        defaultMessage: 'SEE WHAT’S INCLUDED'
    },
    ratingYourPolicyMessage: {
        id: 'e1p.platform.common.Please wait while we rate your policy...',
        defaultMessage: 'Please wait while we rate your policy...'
    },
    pleaseCompleteTheMissingFields: {
        id: 'e1p.platform.common.Please complete the missing fields.',
        defaultMessage: 'Please complete the missing fields.'
    },
    locationCode: {
        id: 'e1p.platform.common.Location Code',
        defaultMessage: 'Location Code'
    },
    locationCodeTooltip: {
        id: 'e1p.platform.common.Location Code(s) available. Contact MSA at FOSCLicensing@MSAGROUP.COM to request updates.',
        defaultMessage: 'Location Code(s) available. Contact MSA at FOSCLicensing@MSAGROUP.COM to request updates.'
    },
    firstName: {
        id: 'e1p.platform.common.First Name',
        defaultMessage: 'First Name'
    },
    middleName: {
        id: 'e1p.platform.common.M.I.',
        defaultMessage: 'M.I.'
    },
    lastName: {
        id: 'e1p.platform.common.Last Name',
        defaultMessage: 'Last Name'
    },
    suffix: {
        id: 'e1p.platform.common.Suffix',
        defaultMessage: 'Suffix'
    },
    addDriver: {
        id: 'e1p.platform.common.Add Driver',
        defaultMessage: 'Add Driver'
    },
    deleteDriver: {
        id: 'e1p.platform.common.Delete Driver',
        defaultMessage: 'Delete Driver'
    },
    comprehensiveOnly: {
        id: 'e1p.platform.common.Comprehensive Only',
        defaultMessage: 'Comprehensive Only'
    },
    paperlessEmail: {
        id: 'e1p.platform.common.Paperless Email',
        defaultMessage: 'Paperless Email'
    },
    addVehicle: {
        id: 'e1p.platform.common.Add Vehicle',
        defaultMessage: 'Add Vehicle'
    },
    compOnly: {
        id: 'e1p.platform.common.Comp Only',
        defaultMessage: 'Comp Only'
    },
    deleteVehicle: {
        id: 'e1p.platform.common.Delete Vehicle',
        defaultMessage: 'Delete Vehicle'
    },
    description: {
        id: 'e1p.platform.common.Description',
        defaultMessage: 'Description'
    },
    quoteProposal: {
        id: 'e1p.platform.common.QuoteProposal',
        defaultMessage: 'Quote Proposal'
    },
    quoteProposalHelpText: {
        id: 'e1p.platform.common.The Quote Proposal reflects current coverage selections. Save an updated Quote Proposal after each change as these are not automatically saved with the policy documents.',
        defaultMessage: 'The Quote Proposal reflects current coverage selections. Save an updated Quote Proposal after each change as these are not automatically saved with the policy documents.'
    },
    none: {
        id: 'e1p.platform.common.None',
        defaultMessage: 'None'
    },
    gold: {
        id: 'e1p.platform.common.Gold',
        defaultMessage: 'Gold'
    },
    platinum: {
        id: 'e1p.platform.common.Platinum',
        defaultMessage: 'Platinum'
    },
    loadingNextPage: {
        id: 'e1p.platform.common.Loading Next Page...',
        defaultMessage: 'Loading Next Page...'
    },
    self: {
        id: 'e1p.platform.common.Self',
        defaultMessage: 'Self'
    },
    assign: {
        id: 'e1p.platform.common.ASSIGN',
        defaultMessage: 'ASSIGN'
    },
    requiredFieldMessage: {
        id: 'e1p.platform.common.This is a required field',
        defaultMessage: 'This is a required field'
    },
    thirdPartyInterest: {
        id: 'e1p.platform.common.Third-Party Interest',
        defaultMessage: 'Third-Party Interest'
    },
    name: {
        id: 'e1p.platform.common.Name',
        defaultMessage: 'Name'
    },
    interestType: {
        id: 'e1p.platform.common.Interest Type',
        defaultMessage: 'Interest Type'
    },
    insuredType: {
        id: 'e1p.platform.common.Insured Type',
        defaultMessage: 'Insured Type'
    },
    address: {
        id: 'e1p.platform.common.Address',
        defaultMessage: 'Address'
    },
    loanNumber: {
        id: 'e1p.platform.common.Loan Number',
        defaultMessage: 'Loan Number'
    },
    searchForTPI: {
        id: 'e1p.platform.common.SEARCH FOR A THIRD PARTY',
        defaultMessage: 'SEARCH FOR A THIRD PARTY'
    },
    noThirdPartyInterestAdded: {
        id: 'e1p.platform.common.No Third-Party Interest has been added.',
        defaultMessage: 'No Third-Party Interest has been added.'
    },
    searchCriteria: {
        id: 'e1p.platform.common.Search Criteria',
        defaultMessage: 'Search Criteria'
    },
    startsWith: {
        id: 'e1p.platform.common.Starts With',
        defaultMessage: 'Starts With'
    },
    includes: {
        id: 'e1p.platform.common.Includes',
        defaultMessage: 'Includes'
    },
    type: {
        id: 'e1p.platform.common.Type',
        defaultMessage: 'Type'
    },
    subType: {
        id: 'e1p.platform.common.Subtype',
        defaultMessage: 'Subtype'
    },
    person: {
        id: 'e1p.platform.common.Person',
        defaultMessage: 'Person'
    },
    organization: {
        id: 'e1p.platform.common.Organization',
        defaultMessage: 'Organization'
    },
    city: {
        id: 'e1p.platform.common.City',
        defaultMessage: 'City'
    },
    state: {
        id: 'e1p.platform.common.State',
        defaultMessage: 'State'
    },
    search: {
        id: 'e1p.platform.common.SEARCH',
        defaultMessage: 'SEARCH'
    },
    clear: {
        id: 'e1p.platform.common.CLEAR',
        defaultMessage: 'CLEAR'
    },
    searchResults: {
        id: 'e1p.platform.common.Search Result',
        defaultMessage: 'Search Result'
    },
    noResultFound: {
        id: 'e1p.platform.common.No results found',
        defaultMessage: 'No results found'
    },
    aliasName: {
        id: 'e1p.platform.common.Alias Name',
        defaultMessage: 'Alias Name'
    },
    comments: {
        id: 'e1p.platform.common.Comments',
        defaultMessage: 'Comments'
    },
    saveThirdPartyInterest: {
        id: 'e1p.platform.common.SAVE THIRD-PARTY INTEREST',
        defaultMessage: 'SAVE THIRD-PARTY INTEREST'
    },
    addressLine1: {
        id: 'e1p.platform.common.Address Line 1',
        defaultMessage: 'Address Line 1'
    },
    addressLine2: {
        id: 'e1p.platform.common.Address Line 2',
        defaultMessage: 'Address Line 2'
    },
    zipCode: {
        id: 'e1p.platform.common.Zip Code',
        defaultMessage: 'Zip Code'
    },
    billing: {
        id: 'e1p.platform.common.Billing',
        defaultMessage: 'Billing'
    },
    tpiNumber: {
        id: 'e1p.platform.common.Third-Party Interest Number',
        defaultMessage: 'Third-Party Interest Number'
    },
    selectAThirdPartyError: {
        id: 'e1p.platform.common.Select a Third Party or create a new Third Party to continue.',
        defaultMessage: 'Select a Third Party or create a new Third Party to continue.'
    },
    savingTPI: {
        id: 'e1p.platform.common.Saving Third-Party Interest...',
        defaultMessage: 'Saving Third-Party Interest...'
    },
    remove: {
        id: 'e1p.platform.common.REMOVE',
        defaultMessage: 'REMOVE'
    },
    removeTPITitle: {
        id: 'e1p.platform.common.Remove Third-Party Interest',
        defaultMessage: 'Remove Third-Party Interest'
    },
    removeTPIDescription: {
        id: 'e1p.platform.common.The selected Third-Party Interest will be removed',
        defaultMessage: 'The selected Third-Party Interest will be removed'
    },
    addThirdPartyInterestDetails: {
        id: 'e1p.platform.common.Add Third-Party Interest Details',
        defaultMessage: 'Add Third-Party Interest Details'
    },
    createNewTPI: {
        id: 'e1p.platform.common.Create a New Third Party',
        defaultMessage: 'Create a New Third Party'
    },
    standardizingAddressMessage: {
        id: 'e1p.platform.common.Standardizing Address...',
        defaultMessage: 'Standardizing Address...'
    },
    searchingContacts: {
        id: 'e1p.platform.common.Searching Contacts...',
        defaultMessage: 'Searching Contacts...'
    },
    submit: {
        id: 'e1p.platform.common.SUBMIT',
        defaultMessage: 'SUBMIT'
    },
    quoteCreationDate: {
        id: 'e1p.platform.common.Quote Creation Date',
        defaultMessage: 'Quote Creation Date'
    },
    rateAsOfDate: {
        id: 'e1p.platform.common.Rate as of Date',
        defaultMessage: 'Rate as of Date'
    },
    ratingDate: {
        id: 'e1p.platform.common.Rating Date',
        defaultMessage: 'Rating Date'
    },
    deceased: {
        id: 'e1p.platform.common.Deceased',
        defaultMessage: 'Deceased'
    },
    titleholderType: {
        id: 'e1p.platform.common.Titleholder Type',
        defaultMessage: 'Titleholder Type'
    },
    relationshipToNI: {
        id: 'e1p.platform.common.Relationship To Named Insured',
        defaultMessage: 'Relationship To Named Insured'
    },
    loanLeaseNumber: {
        id: 'e1p.platform.common.Loan/Lease Number',
        defaultMessage: 'Loan/Lease Number'
    },
    additionalInterestType: {
        id: 'e1p.platform.common.Additional Interest Type',
        defaultMessage: 'Additional Interest Type'
    },
    editThirdPartyInterestDetails: {
        id: 'e1p.platform.common.Edit Third-Party Interest Details',
        defaultMessage: 'Edit Third-Party Interest Details'
    },
    ratingPlan: {
        id: 'e1p.platform.common.Rating Plan',
        defaultMessage: 'Rating Plan'
    },
    paperless: {
        id: 'e1p.platform.common.Paperless',
        defaultMessage: 'Paperless'
    },
    email: {
        id: 'e1p.platform.common.Email',
        defaultMessage: 'Email'
    },
    deleteSelectedVersionError: {
        id: 'e1p.platform.common.{versionName} is the selected quote and cannot be deleted. Select a different quote before deleting {versionName}.',
        defaultMessage: '{versionName} is the selected quote and cannot be deleted. Select a different quote before deleting {versionName}.'
    },
    year: {
        id: "e1p.platform.common.Year",
        defaultMessage: "Year"
    },
    enterSideBySideButtonText: {
        id: 'e1p.platform.common.CREATE SIDE-BY-SIDE QUOTES',
        defaultMessage: 'CREATE SIDE-BY-SIDE QUOTES'
    },
    viewSideBySideButtonText: {
        id: 'e1p.platform.common.VIEW SIDE-BY-SIDE QUOTES',
        defaultMessage: 'VIEW SIDE-BY-SIDE QUOTES'
    },
    deleteQuote: {
        id: 'e1p.platform.common.Delete Quote?',
        defaultMessage: 'Delete Quote?'
    },
    deleteQuoteMessage: {
        id: 'e1p.platform.common.The selected quote will be deleted. This cannot be undone.',
        defaultMessage: 'The selected quote will be deleted. This cannot be undone.'
    },
    viewQuoteProposal: {
        id: 'e1p.platform.common.ViewQuoteProposal',
        defaultMessage: 'VIEW QUOTE PROPOSAL'
    },
    notApplicable: {
        id: 'e1p.platform.common.Not applicable',
        defaultMessage: 'Not applicable'
    },
    line: {
        id: 'e1p.platform.common.Line',
        defaultMessage: 'Line'
    },
    status: {
        id: 'e1p.platform.common.Status',
        defaultMessage: 'Status'
    },
    jobNumber: {
        id: 'e1p.platform.common.Quote Number',
        defaultMessage: 'Quote Number'
    },
    searchTransactions: {
        id: 'e1p.platform.common.Search by Quote Number',
        defaultMessage: 'Search by Quote Number'
    },
    expiredAndWithdrawnTransaction: {
        id: 'e1p.platform.common.Expired and Withdrawn Transactions',
        defaultMessage: 'Expired and Withdrawn Transactions'
    },
    or: {
        id: 'e1p.platform.common.OR',
        defaultMessage: 'OR'
    },
    preferred: {
        id: 'e1p.platform.common.Preferred',
        defaultMessage: 'Preferred'
    },
    viewQualifyingCoverages: {
        id: 'e1p.platform.common.VIEW QUALIFYING COVERAGES',
        defaultMessage: 'VIEW QUALIFYING COVERAGES'
    },
    addlInsuredType: {
        id: 'e1p.platform.common.Addl Insured Type',
        defaultMessage: 'Addl Insured Type'
    },
    addlInsuredTypeSubtype: {
        id: 'e1p.platform.common.Addl Insured Subtype',
        defaultMessage: 'Addl Insured Subtype'
    },
    relationshipToNIs: {
        id: 'e1p.platform.common.Relationship to NI(s)',
        defaultMessage: 'Relationship to NI(s)'
    },
    tpiDetails: {
        id: 'e1p.platform.common.Third-Party Interest Details',
        defaultMessage: 'Third-Party Interest Details'
    },
    oktaSessionExpirationMessage: {
        id: 'e1p.platform.common.Your Okta session has expired. Please save entry, exit the application and sign back in via Agents Only to continue.',
        defaultMessage: 'Your Okta session has expired. Please save entry, exit the application and sign back in via Agents Only to continue.'
    },
    paymentusServiceError: {
        id: 'e1p.platform.common.An error occurred applying the payment. Please contact customer service.',
        defaultMessage: 'An error occurred applying the payment. Please contact customer service.'
    },
    ssn: {
        id: 'e1p.platform.common.Social Security Number',
        defaultMessage: 'Social Security Number'
    },
    incompleteInput: {
        id: 'e1p.platform.common.Input value is incomplete',
        defaultMessage: 'Input value is incomplete'
    },
    dateOrdered: {
        id: 'e1p.platform.common.Date Ordered',
        defaultMessage: 'Date Ordered'
    },
    other: {
        id: 'e1p.platform.common.Other',
        defaultMessage: 'Other'
    },
    residenceAddress: {
        id: 'e1p.platform.common.Residence Address',
        defaultMessage: 'Residence Address'
    },
    payLater: {
        id: 'e1p.platform.common.Pay Later',
        defaultMessage: 'Pay Later'
    },
    payNow: {
        id: 'e1p.platform.common.Pay Now',
        defaultMessage: 'Pay Now'
    },
    paymentOptions: {
        id: 'e1p.platform.common.Payment Options',
        defaultMessage: 'Payment Options'
    },
    noneSelected: {
        id: 'e1p.platform.common.None Selected',
        defaultMessage: 'None Selected'
    },
    additionalInformation: {
        id: 'e1p.platform.common.Additional Information',
        defaultMessage: 'Additional Information'
    },
    copyCoverages: {
        id: 'e1p.platform.common.Copy Coverages',
        defaultMessage: 'Copy Coverages'
    },
    invalidZipCodeError: {
        id: 'e1p.platform.common.Please enter a valid ZIP code',
        defaultMessage: 'Please enter a valid ZIP code.'
    },
    printPageLabel: {
        id: 'e1p.platform.common.PRINT PAGE',
        defaultMessage: 'PRINT PAGE'
    },
    thankYouMessage: {
        id: "e1p.platform.common.Thank you for placing your business with partner",
        defaultMessage: "Thank you for placing your business with {partner}!"
    },
    prepareReqDocuments: {
        id: 'e1p.platform.common.Preparing Required Documents',
        defaultMessage: 'Preparing Required Documents'
    },
    add: {
        id: 'e1p.platform.common.ADD',
        defaultMessage: 'ADD'
    },
    edit: {
        id: 'e1p.platform.common.EDIT',
        defaultMessage: 'EDIT'
    },
    swapPNISNI: {
        id: 'e1p.platform.common.Swap Primary and Secondary Named Insured',
        defaultMessage: 'Swap Primary and Secondary Named Insured'
    },
    swapPniSniTitle: {
        id: 'e1p.platform.common.swapPniSniTitle.Swap Primary and Secondary Named Insured',
        defaultMessage: 'Swap Primary and Secondary Named Insured'
    },
    swapPniSniMessage: {
        id: 'e1p.platform.common.swapPniSniMessage.Are you sure you want to swap Primary and Secondary Named Insured?',
        defaultMessage: 'Are you sure you want to swap Primary and Secondary Named Insured?'
    },
    leave: {
        id: 'e1p.platform.common.LEAVE',
        defaultMessage: 'LEAVE'
    },
    unsavedChangeHeader: {
        id: 'e1p.platform.common.There are unsaved changes',
        defaultMessage: 'There are unsaved changes'
    },
    unsavedChangeMessage: {
        id: 'e1p.platform.common.All changes on this page will be lost if you leave without saving.',
        defaultMessage: 'All changes on this page will be lost if you leave without saving.'
    },
    riskAddress: {
        id: 'e1p.platform.common.Risk Address',
        defaultMessage: 'Risk Address'
    },
    addressStandardizationErrorTitle: {
        id: 'e1p.platform.common.Unable to standardize address',
        defaultMessage: 'Unable to standardize address',
    },
    addressStandardizationPostalErrorMessage: {
        id: 'e1p.platform.common.Zip Code is invalid for the State.',
        defaultMessage: 'Zip Code is invalid for the State.',
    },
    policyPremium: {
        id: 'e1p.platform.common.Policy Premium',
        defaultMessage: 'Policy Premium',
    },
    payment: {
        id: 'e1p.platform.common.Payment',
        defaultMessage: 'Payment',
    },
    withdraw: {
        id: 'e1p.platform.common.Withdraw',
        defaultMessage: 'Withdraw'
    },
    continue: {
        id: 'e1p.platform.common.Continue',
        defaultMessage: 'Continue'
    },
    missingPayment: {
        id: 'e1p.platform.common.Missing Payment',
        defaultMessage: 'Missing Payment'
    },
    openUw: {
        id: 'e1p.platform.common.There are underwriting issues open',
        defaultMessage: 'There are underwriting issues open'
    },
    savingContactinfoMessage: {
        id: 'e1p.platform.common.Saving the Insured\'s contact information...',
        defaultMessage: 'Saving the Insured\'s contact information...'
    },
    delete: {
        id: 'e1p.platform.common.DELETE',
        defaultMessage: 'DELETE'
    },
    next: {
        id: 'e1p.platform.common.NEXT',
        defaultMessage: 'NEXT'
    },
    removeItemMessage: {
        id: 'e1p.platform.common.The selected item will be removed.',
        defaultMessage: 'The selected {itemToRemove} will be removed.'
    },
    downPaymentCouldNotBeCalculatedTitle: {
        id: 'e1p.platform.common.An error occurred while retrieving the down payment.',
        defaultMessage: 'An error occurred while retrieving the down payment.'
    },
    downPaymentCouldNotBeCalculatedMessage: {
        id: 'e1p.platform.common.The down payment could not be calculated. Please try again later. If the error persists, contact customer service.',
        defaultMessage: 'The down payment could not be calculated. Please try again later. If the error persists, contact customer service.'
    },
    costcoMembershipValidationComplete: {
        id: 'e1p.platform.common.Validation complete, the Costco membership is status and number is',
        defaultMessage: 'Validation complete, the Costco membership is {memberStatus}, {memberTier} and number is {membershipNumber}.'
    },
    costcoMembershipValidationInvalid: {
        id: 'e1p.platform.common.Validation complete, the Costco membership is invalid.',
        defaultMessage: 'Validation complete, the Costco membership is invalid.'
    },
    costcoMembershipValidationError: {
        id: 'e1p.platform.common.Validation incomplete, Costco web services tool is unavailable.',
        defaultMessage: 'Validation incomplete, Costco web services tool is unavailable.'
    },
    msa: {
        id: 'e1p.platform.common.Main Street America Insurance',
        defaultMessage: 'Main Street America Insurance'
    },
    connect: {
        id: 'e1p.platform.common.CONNECT',
        defaultMessage: 'CONNECT'
    },
    misConfigureValidationRulePretext: {
        id: 'e1p.platform.common.This rule needs a story to map the error to the correct page.',
        defaultMessage: 'This rule needs a story to map the error to the correct page.'
    },
    withdrawTransaction: {
        id: 'e1p.platform.common.WITHDRAW TRANSACTION',
        defaultMessage: 'WITHDRAW {transaction}'
    },
    removeItemButtonText: {
        id: 'e1p.platform.common.REMOVE ITEM',
        defaultMessage: 'REMOVE {itemToRemove}'
    },
    exitTransactionLabel: {
        id: 'e1p.platform.common.EXIT TRANSACTION',
        defaultMessage: 'EXIT TRANSACTION'
    },
    printLabel: {
        id: 'e1p.platform.common.PRINT',
        defaultMessage: 'PRINT'
    },
    insured: {
        id: 'e1p.platform.common.Insured',
        defaultMessage: 'Insured'
    },
    invalidDateEntered: {
        id: 'e1p.platform.common.Invalid date entered',
        defaultMessage: 'Invalid date entered'
    },
    lineOfBusiness: {
        id: 'e1p.platform.common.Line of Business',
        defaultMessage: 'Line of Business'
    },
    clueResults: {
        id: 'e1p.platform.common.CLUE Results',
        defaultMessage: 'CLUE Results'
    },
    underwritingIssues: {
        id: 'e1p.platform.common.Underwriting Issues',
        defaultMessage: 'Underwriting Issues'
    },
    noReportResultDisplayMessage: {
        id: 'e1p.platform.common.There are no reports to display.',
        defaultMessage: 'There are no reports to display.'
    },
    coverages: {
        id: 'e1p.platform.common.Coverages',
        defaultMessage: 'Coverages'
    },
    limit: {
        id: 'e1p.platform.common.Limit',
        defaultMessage: 'Limit'
    },
    recalculate: {
        id: 'e1p.platform.common.RECALCULATE',
        defaultMessage: 'RECALCULATE'
    },
    saveCoverageError: {
        id: 'e1p.platform.common.Unable to save coverages change',
        defaultMessage: 'Unable to save coverages change'
    },
    saveCoverageErrorMessage: {
        id: 'e1p.platform.common.An error occurred while attempting to save coverage changes',
        defaultMessage: 'An error occurred while attempting to save coverage changes'
    },
    discounts: {
        id: 'e1p.platform.common.Discounts',
        defaultMessage: 'Discounts'
    },
    totalPremium: {
        id: 'e1p.platform.common.Total Premium',
        defaultMessage: 'Total Premium'
    },
    premiumChange: {
        id: 'e1p.platform.common.reports.premiumBox.Premium Change',
        defaultMessage: 'Premium Change'
    },
    reportResults: {
        id: 'e1p.platform.common.reports.Report Results',
        defaultMessage: 'Report Results'
    },   
    middleInitial: {
        id: 'e1p.platform.common.Middle Initial',
        defaultMessage: 'Middle Initial'
    },
    age: {
        id: 'e1p.platform.common.Age',
        defaultMessage: 'Age'
    },
    gender: {
        id: 'e1p.platform.common.Gender',
        defaultMessage: 'Gender'
    },
    role: {
        id: 'e1p.platform.common.Role',
        defaultMessage: 'Role'
    },
    roleType: {
        id: 'e1p.platform.common.Role Type',
        defaultMessage: 'Role Type'
    },
    pleaseExplain: {
        id: 'e1p.platform.common.Please Explain',
        defaultMessage: 'Please Explain'
    },
    maritalStatus: {
        id: 'e1p.platform.common.Marital Status',
        defaultMessage: 'Marital Status'
    },
    deceasedDate: {
        id: 'e1p.platform.common.Deceased Date',
        defaultMessage: 'Deceased Date'
    },
    limits: {
        id: 'e1p.platform.common.Limits',
        defaultMessage: 'Limits'
    },
    // used in wizard steps cheveron
    changeSummary: {
        id: 'e1p.platform.common.wizard.step.Change Summary',
        defaultMessage: 'Change Summary'
    },
    // used in change diff page as section heading
    changeSummaryDiffTitle: {
        id: 'e1p.platform.common.change-summary.diff-page.tab.Change Summary',
        defaultMessage: 'Change Summary'
    },
    autoPayDiscount: {
        id: 'e1p.platform.common.Auto-Pay (Checking/Savings Only)',
        defaultMessage: 'Auto-Pay (Checking/Savings Only)'
    },
    vehicleType: {
        id: 'e1p.platform.common.Vehicle Type',
        defaultMessage: 'Vehicle Type'
    },
    paperlessDiscountTooltip: {
        id: 'e1p.platform.common.Both Policy and Billing documents are required to be sent electronically to receive the discount.',
        defaultMessage: 'Both Policy and Billing documents are required to be sent electronically to receive the discount.'
    },
    removeCreditTitle: {
        id: 'e1p.platform.common.Remove Credit Record',
        defaultMessage: 'Remove Credit Record'
    },
    removeCreditDescription: {
        id: 'e1p.platform.common.The selected credit record will be removed',
        defaultMessage: 'The selected credit record will be removed'
    },
    userNotAuthorisedToBind: {
        id: 'e1p.platform.common.User does not have permission to quote or bind the policy transaction',
        defaultMessage: 'User does not have permission to quote or bind the policy transaction'
    }
});
