import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class RoofScoreService {
    /**
     * Fetch Roof Score data
     * @param {String} jobNumber String
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getRoofScoreData = (jobNumber, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('roofscore'),
            'getRoofScoreDetails',
            [jobNumber],
            additionalHeaders
        );
    }
}
