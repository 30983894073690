import React, {
    useCallback, useContext, useState, useEffect
} from 'react';
import {
    get as _get,
    intersection as _intersection,
    set as _set,
    includes as _includes
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import './InsuredPage.messages';
import {
    useViewPolicyUtil
} from 'e1p-capability-hooks';
import { isRequired } from 'e1p-portals-required-validator-js';
import appConfig from 'app-config';
import metadata from './InsuredPage.metadata.json5';
import requiredMetadata from './InsuredPage.requiredness';

const LOB = 'personalAuto_EA';

function PolicyViewInsuredPage(props) {
    const {
        wizardData: policyViewVM,
        updateWizardData,
        authUserData,
        steps,
        markFurthestStep,
        changeNextSteps,
        currentStep
    } = props;
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const { authHeader } = useAuthentication();
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const agentRoles = ['ext_sales_service'];
    const [requiredFields, updateRequiredFields] = useState([]);
    const isAgent = _intersection(authUserData.roles_Ext, agentRoles).length > 0;
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const policyState = _get(policyViewVM, 'policyAddress.state.value.code');
    const { opCo } = useContext(AmfamOktaTokenContext);

    const {
        getLocationAndProducerCodes,
        getProducerDetails
    } = useViewPolicyUtil();

    const getAndSetLocationCodes = async () => {
        const foundLocationCodes = await getLocationAndProducerCodes(policyState, authHeader, setLocationAndProducerCodes);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(policyViewVM, 'producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(policyViewVM, 'externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };


    // Add assignment page if the state is enabled
    const adjustStepsForState = useCallback(async () => {
        // check for enabled state
        const enabledStates = appConfig.driverAssignmentStates;
        const nextSteps = [...steps];
        const driverAssignmentStepIndex = nextSteps.findIndex((step) => step.path === '/view-assignment');

        // not needed for state and does not exist in steps already
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex < 0) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // not needed for the state, but previous state selected needed it
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.splice(driverAssignmentStepIndex, 1); // remove
            nextSteps.shift(); // remove current page (always first elt)
            changeNextSteps(nextSteps);

            return nextSteps;
        }

        // needed for the state, and already has it
        if (enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // needed for the state and not already in the steps
        const driverAssignmentPage = {
            id: 'E1PEADriverAssignmentPage',
            title: {
                id: 'e1p.platform.lob.ea.DriverAssignment.Driver Assignment',
                defaultMessage: 'Driver Assignment'
            },
            path: '/view-assignment',
            component: 'E1PPolicyViewDriverAssignmentPage',
            stepProps: {
                template: 'WizardPageTemplateWithTitle'
            }
        };
        const indexOfCoveragePage = nextSteps.findIndex((step) => step.path === '/view-policy-coverage');

        // insert driver assignment
        nextSteps.splice(indexOfCoveragePage, 0, driverAssignmentPage)
        // remove current page (always first elt)
        nextSteps.shift();
        changeNextSteps(nextSteps);

        return nextSteps;
    }, [changeNextSteps, policyState, steps]);

    useEffect(() => {
        adjustStepsForState().then((newSteps) => {
            // add current step back because we need all steps
            newSteps.unshift(currentStep);
            // in view only flow we want to make each step as visited so that user
            // can navigate to any page by clicking on tabs on chevron
            markFurthestStep((newSteps.length - 1));
        });

        if (isAgent) {
            getAndSetLocationCodes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initialRequiredFields = ['applicantsResideInSameStateInd', 'isUmbrellaAssociated']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
    }, [policyState]);

    useEffect(() => {
        if (policyViewVM.value.externalID_Ext) {
            getProducerDetails(
                policyViewVM.value.externalID_Ext, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
                setAgencyName(details.agencyName);
                setBookTransferIndicator(details.bookTransferIndicator);
                setServiceCenterIndicator(details.serviceCenterIndicatorValue);
            }).catch((error) => {
                /**
                 * E1PAP1PC-15099 :
                 * This is rare scenario; will never happen in production
                 * When we bound policy using dummy producer code
                 * and when other user tries to view this policy we get exception from service
                 */
                if (policyViewVM.value.exceptions_Ext) {
                    policyViewVM.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        policyViewVM.value,
                        `exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(policyViewVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyViewVM.value.externalID_Ext, authHeader]);

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            setLocationAndProducerCodes(
                [
                    {
                        producerCodes: { code: producerCode, name: producerCode },
                        locationCodes: { code: externalID, name: externalID }
                    }
                ]
            );
            _set(policyViewVM, 'producerCode_Ext', producerCode);
            _set(policyViewVM, 'externalID_Ext.value', externalID);
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [policyViewVM],
    );

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(() => policyViewVM, [policyViewVM]);

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: undefined,
            onValidate: () => { },
            onRemoveSni: undefined,
            disclosureLinkHandler: undefined,
            disclosureCheckHandlerClick: undefined,
            guidelinesLinkHandler: undefined,
            guidelinesCheckHandler: undefined,
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        policyDetailsPageLoadingIndicator: {
            loaded: true
        },
        sniContainer: {
            visible: !!_get(policyViewVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        coverageStartDate: {
            dateDTO: policyViewVM.periodStartDate
        },
        coverageEndDate: {
            dateDTO: policyViewVM.periodEndDate,
            readOnly: true
        },
        personalInfoContainer: {
            columns: authUserData.permissions_Ext.includes('editrateasofdate')
                ? ['0.25fr', '0.25fr', '0.25fr', '0.25fr'] : ['0.25fr', '0.25fr', '0.25fr']
        },
        originalEffectiveDate: {
            dateDTO: policyViewVM.periodStartDate,
            readOnly: true,
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        dateOfBirth: {
            dateDTO: policyViewVM.baseData?.accountHolder?.dateOfBirth
        },
        secNamedInsuredContainer: {
            visible: !!_get(policyViewVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        secondaryNamedInsured: {
            viewOnlyMode: true
        },
        addSni: {
            visible: false
        },
        removeSni: {
            visible: false
        },
        noSecondaryNamedInsuredMessageID: {
            visible: !_get(policyViewVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        accountSearchComponent: {
            visible: false
        },
        mailingAndBillingAddressComponent: {
            transactionVM: policyViewVM,
            viewOnlyMode: true,
            lob: LOB,
            isPolicyView: true
        },
        insuredResidenceAddress: {
            addressVM: _get(policyViewVM, 'policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            viewOnlyMode: true
        },
        primaryNamedInsured: {
            viewOnlyMode: true,
            isPNI: true
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            readOnly: true,
            visible: isAgent
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        excessLiabilityInd: {
            required: true,
            visible: _includes(requiredFields, 'isUmbrellaAssociated'),
        },
        ExternalProducerDetailsComponent: {
            viewOnlyMode: true,
            visible: !isAgent,
            defaultValue: policyViewVM.externalID_Ext?.value
        },
        applicantsResideInSameStateInd: {
            required: true,
            visible: _includes(requiredFields, 'applicantsResideInSameStateInd'),
        },
        quoteCreationDate: {
            dateDTO: policyViewVM.quoteCreationDate_Ext,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        rateAsOfDate: {
            dateDTO: policyViewVM.periodRateAsOfDate,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        ratingDate: {
            dateDTO: policyViewVM.ratingDate,
            updateDateDto: () => { },
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        agencyDetailsInfoContainer: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: policyViewVM,
            authHeader,
            LOB: 'personalAuto_EA',
            viewOnlyMode: true
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyViewVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

PolicyViewInsuredPage.propTypes = wizardProps;
export default withAuthenticationContext(PolicyViewInsuredPage);
