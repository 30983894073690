import { defineMessages } from 'react-intl';

export default defineMessages({
    accountTitleHo: {
        id: 'cancel.common.directives.templates.policy-cancel.Policy Start',
        defaultMessage: 'Start Cancellation:'
    },
    cancelQuoteError: {
        id: 'cancel.common.directives.templates.policy-cancel.Unable to cancel',
        defaultMessage: 'Unable to cancel'
    },
    cancelQuoteErrorMessage: {
        id: 'cancel.common.directives.templates.policy-cancel.An error occurred while attempting to cancel.',
        defaultMessage: 'An error occurred while attempting to cancel.'
    },
    anErrorOccurredTitle: {
        id: 'cancel.common.directives.templates.policy-cancel.error.title',
        defaultMessage: 'Error'
    },
    anErrorOccurred: {
        id: 'cancel.common.directives.templates.policy-cancel.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    exitTransaction: {
        id: 'cancel.common.directives.templates.policy-cancel.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'cancel.common.directives.templates.policy-cancel.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'cancel.common.directives.templates.policy-cancel.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'cancel.common.directives.templates.policy-cancel.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    }
});
