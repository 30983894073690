import React, {
    useCallback
} from 'react';
import {
    get as _get
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from '../RiskAnalysisPage.metadata.json5';

function ViewRiskAnalysisPage(props) {
    const { authHeader } = useAuthentication();
    const {
        wizardData: renewalVM,
        isSkipping,
        authUserData
    } = props;

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(renewalVM, 'lobData.personalUmbrella_EU.premiumStability.value') !== undefined;

    const getDefaultActiveTab = () => {
        if (_get(renewalVM.value, 'errorsAndWarnings?.underwritingIssues.length') > 0) {
            return 'uwIssuesTab';
        }

        return 'lossesAndViolationTab';
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: () => {},
        },
    };

    const onNext = useCallback(() => renewalVM, [renewalVM]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top'
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: true
        },
        riskAnalysisTabs: {
            visible: true,
            defaultActiveTab: getDefaultActiveTab()
        },
        lossesAndViolationComponent: {
            authUserData,
            isVerified: true,
            updateWizardData: () => {},
            disregardFieldValidation: () => {},
            submissionVM: renewalVM,
            isSkipping,
            showErrors: false,
            viewOnlyMode: true
        },
        UWIssuesComponentId: {
            submissionVM: renewalVM,
            updateWizardData: () => {},
            authHeader,
            viewOnlyMode: true
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(renewalVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: renewalVM,
            updateWizardData: () => {},
            onValidate: () => {},
            showErrors: false,
            viewOnlyMode: true
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={() => {}}
                onValidationChange={() => {}}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewRiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewRiskAnalysisPage);
