import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class PolicyService {
    static getOpenSubmissionsForCurrentUser(authHeader = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policyPolicy'),
            'getOpenSubmissionsForCurrentUser',
            [],
            authHeader
        );
    }

    static getOpenPolicyChangesForCurrentUser(authHeader = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policyPolicy'),
            'getOpenPolicyChangesForCurrentUser',
            [],
            authHeader
        );
    }

    static getOpenCancellationsForCurrentUser(authHeader = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policyPolicy'),
            'getOpenCancellationsForCurrentUser',
            [],
            authHeader
        );
    }

    static getOpenRenewalsForCurrentUser(authHeader = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policyPolicy'),
            'getOpenRenewalsForCurrentUser',
            [],
            authHeader
        );
    }

    static getOpenCancellations(queryOptions, authHeader = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policyPolicy'),
            'getOpenCancellations',
            [queryOptions],
            authHeader
        );
    }

    static getOpenPolicyChanges(queryOptions, authHeader = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policyPolicy'),
            'getOpenPolicyChanges',
            [queryOptions],
            authHeader
        );
    }

    static getOpenRenewals(queryOptions, authHeader = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policyPolicy'),
            'getOpenRenewals',
            [queryOptions],
            authHeader
        );
    }

    static getOpenSubmissions(queryOptions, authHeader = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policyPolicy'),
            'getOpenSubmissions',
            [queryOptions],
            authHeader
        );
    }

    static getRecentlyIssuedPolicies(numberOfDays, queryOptions, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getIssuedPolicies',
            [numberOfDays, queryOptions],
            additionalHeaders
        );
    }

    static getRecentlyViewedPolicies(queryOptions, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'getViewedPolicies',
            [queryOptions],
            additionalHeaders
        );
    }
    
    static retrieveJobs(queryOptions, jobType, jobSearchCriteria, authHeader = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('policyPolicy'),
            'retrieveJobs',
            [queryOptions, jobType, jobSearchCriteria],
            authHeader
        );
    }

    static searchPolicies(policySearchCriteria, queryOptions, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayPolicy'),
            'searchPolicies',
            [policySearchCriteria, queryOptions],
            additionalHeaders
        );
    }

}
