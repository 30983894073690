import { defineMessages } from 'react-intl';

export default defineMessages({
    changeToButton: {
        id: 'ChangeNamedInsuredComponent.changeToButton',
        defaultMessage: 'Change To'
    },
    new: {
        id: 'ChangeNamedInsuredComponent.New',
        defaultMessage: 'New'
    },
    newPerson: {
        id: 'ChangeNamedInsuredComponent.New Person',
        defaultMessage: 'New Person'
    },
    existingPerson: {
        id: 'AccountSearchComponent.Existing Person',
        defaultMessage: 'Existing Person'
    }
});
