import React, { useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { Grid } from '@jutro/layout';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { usePriorPolicyMilitaryDeploymentUpdateUtil, e1pDateUtil } from 'e1p-capability-hooks';
import { E1PDateComponent } from 'e1p-capability-policyjob-react';
import moment from 'moment';
import config from 'app-config';
import messages from './EAMilitaryDeploymentModal.messages';
import metadata from './EAMilitaryDeploymentModal.metadata.json5';
import styles from './EAMilitaryDeploymentModal.module.scss';

import { IconButton, Button } from '@jutro/legacy/components';

function EAMilitaryDeploymentModal(props) {
    const {
        id,
        title,
        driverVM,
        showOptional,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
        priorPolicyList,
        periodStartDate,
        viewOnlyMode,
        onValidate,
        driverPageDisregardFieldValidation,
        onValueChange,
        policyState,
        getAvailableValuesForPriorPolicyUpdateReasons
    } = props;
    const { ageAllowedForMilitaryDeployment } = config.personalAutoConfig;
    const { isComponentValid, disregardFieldValidation, onValidate: setComponentValidation } = useValidation('EAMilitaryDeploymentModal');
    const [uiContent, setUIContent] = useState([]);
    const [priorPolicyUpdateList, setPriorPolicyUpdateList] = useState([]);
    const translator = useTranslator();
    const [priorPolicyUpdateReasonAvailableValues, setPriorPolicyUpdateReasonAvailableValues] = useState([]);
    const auto2dot0States = config.auto2dot0States ?? [];
    const isAuto2dot0State = auto2dot0States.includes(policyState);

    useEffect(() => {
        if (isAuto2dot0State && getAvailableValuesForPriorPolicyUpdateReasons) {
            getAvailableValuesForPriorPolicyUpdateReasons()
                .then((availableValues) => {
                    setPriorPolicyUpdateReasonAvailableValues(availableValues);
                });
        }
        // Only update on initial load    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [priorPolicyList, id, onValidate, isComponentValid]);

    const {
        createPriorPolicyMilitaryDeploymentUpdatesVM,
        getMilitaryDeploymentRecords,
        removeMilitaryDeployments
    } = usePriorPolicyMilitaryDeploymentUpdateUtil();

    const getNamedInsuredInformation = useMemo(() => {
        const role = _.get(driverVM, 'value.role', '') === 'sni' ? 'PolicySecNamedInsured' : 'PolicyPriNamedInsured';
        const integrationId = role === 'PolicyPriNamedInsured'
            ? _.get(driverVM, '_parent._parent._parent.primaryNamedInsured.integrationId.value', '')
            : _.get(driverVM, '_parent._parent._parent.secondaryNamedInsured.integrationId.value', '');

        return { role, integrationId };
    }, [driverVM])

    /**
     * Helper callback for handling a mouse-click on the "Update" button.
     */
    const onSave = useCallback(() => {
        const wrapperObj = {
            priorPolicyUpdatesList: priorPolicyUpdateList.value
        };

        if (isAuto2dot0State) {
            const priorPolicyUpdateIndexToBeRemoved = priorPolicyUpdateList
                .value?.findIndex((priorPolicyUpdate) =>
                    priorPolicyUpdateReasonAvailableValues.some((availableValue) => availableValue.code === priorPolicyUpdate.reason)
                    && priorPolicyUpdate.integrationId === getNamedInsuredInformation.integrationId
                    && priorPolicyUpdate.type === 'PriorPolicyLapseReason_Ext');

            if (priorPolicyUpdateIndexToBeRemoved > -1) {
                priorPolicyUpdateList.value?.splice(priorPolicyUpdateIndexToBeRemoved, 1);
            }
        }

        onResolve(wrapperObj);
    }, [getNamedInsuredInformation.integrationId, isAuto2dot0State, onResolve, priorPolicyUpdateList, priorPolicyUpdateReasonAvailableValues]);

    /**
     * Helper callback for handling a mouse-click on the "Cancel" button.
     */
    const onCancel = useCallback(
        (evt) => {
            if (driverPageDisregardFieldValidation) {
                driverPageDisregardFieldValidation('militaryDeploymentToggle');
            }

            evt.stopPropagation();
            onReject();
        },
        [onReject, driverPageDisregardFieldValidation]
    );

    const setReturnDateValue = useCallback((value, militaryDeploymentRecord) => {
        if (value) {
            _.set(militaryDeploymentRecord, 'endDate', e1pDateUtil.convertToUTC(value));
        }
    }, []);

    const setDeploymentDateValue = useCallback((value, militaryDeploymentRecord) => {
        if (value) {
            _.set(militaryDeploymentRecord, 'startDate', e1pDateUtil.convertToUTC(value));
        }
    }, []);

    const getMaxReturnDate = useCallback(() => {
        const maxReturnDate = new Date(moment(periodStartDate));

        return maxReturnDate;
    }, [periodStartDate]);

    const getMinReturnDate = useCallback((militaryDeploymentRecord) =>
        new Date(militaryDeploymentRecord.startDate), []);

    const getMaxDeploymentDate = useCallback((militaryDeploymentRecord) =>
        new Date(militaryDeploymentRecord.endDate), []);

    const getMinDeploymentDate = useCallback(() => {
        const dateOfBirth = _.get(driverVM, 'value.person.dateOfBirth', new Date());
        const minDeploymentDate = new Date(moment(dateOfBirth));

        minDeploymentDate.setFullYear(minDeploymentDate.getFullYear() + ageAllowedForMilitaryDeployment);

        return minDeploymentDate;
    }, [ageAllowedForMilitaryDeployment, driverVM]);

    const removeMilitaryDeploymentDate = useCallback((militaryDeploymentRecord, index) => {
        const tempPriorPolicyUpdateList = _.cloneDeep(priorPolicyUpdateList);
        const newUpdatedPriorPolicyList = tempPriorPolicyUpdateList.value
            ?.filter(
                (priorPolicyUpdate) => !_.isEqual(priorPolicyUpdate, militaryDeploymentRecord)
            );

        _.set(tempPriorPolicyUpdateList, 'value', newUpdatedPriorPolicyList);
        disregardFieldValidation(`deploymentDate${index}`);
        disregardFieldValidation(`returnDate${index}`);
        setPriorPolicyUpdateList(tempPriorPolicyUpdateList);
    }, [disregardFieldValidation, priorPolicyUpdateList]);

    const tempContent = useMemo(() => {
        const militaryDeploymentRecords = getMilitaryDeploymentRecords(
            getNamedInsuredInformation.integrationId, priorPolicyUpdateList
        );
        const content = [];

        militaryDeploymentRecords.forEach((militaryDeploymentRecord, index) => {
            const deploymentUiRecord = (
                <div key={`militaryDeploymentModalDiv${index}`} className={styles.militaryDeploymentDates}>
                    <Grid id={`militaryDeploymentModalGrid${index}`} columns={['1fr', '1fr', '0.25fr']} gap="small">
                        <E1PDateComponent
                            id={`deploymentDate${index}`}
                            dateDTO={militaryDeploymentRecord.startDate}
                            updateDateDto={
                                (value) => setDeploymentDateValue(value, militaryDeploymentRecord)
                            }
                            maxDate={getMaxDeploymentDate(militaryDeploymentRecord)}
                            minDate={getMinDeploymentDate()}
                            label={{ id: '', defaultMessage: '' }}
                            labelPosition="top"
                            hideLabel
                            showErrors
                            isRequired
                            onValidate={setComponentValidation}
                            readOnly={viewOnlyMode}
                        />
                        <E1PDateComponent
                            id={`returnDate${index}`}
                            dateDTO={militaryDeploymentRecord.endDate}
                            defaultToToday
                            updateDateDto={
                                (value) => setReturnDateValue(value, militaryDeploymentRecord)
                            }
                            minDate={getMinReturnDate(militaryDeploymentRecord)}
                            maxDate={getMaxReturnDate()}
                            labelPosition="top"
                            hideLabel
                            showErrors
                            isRequired
                            onValidate={setComponentValidation}
                            readOnly={viewOnlyMode}
                        />
                        <IconButton
                            id={`deleteDeploymentRecord${index}`}
                            icon="mi-delete"
                            size="large"
                            iconClassName={styles.deleteIcon}
                            hidden={viewOnlyMode}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                removeMilitaryDeploymentDate(militaryDeploymentRecord, index);
                            }}
                        />
                    </Grid>
                </div>
            );

            content.push(deploymentUiRecord);
        });
        setUIContent(content);

        return content;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(priorPolicyUpdateList.value), priorPolicyUpdateList.value?.length]);

    const onAddMilitaryDeploymentButtonClick = useCallback(() => {
        const tempPriorPolicyUpdateList = _.cloneDeep(priorPolicyUpdateList);
        const { integrationId, role } = getNamedInsuredInformation;

        createPriorPolicyMilitaryDeploymentUpdatesVM(_.get(driverVM, 'value.person.publicID', ''),
            role, integrationId, periodStartDate, tempPriorPolicyUpdateList);
        setPriorPolicyUpdateList(tempPriorPolicyUpdateList);
    }, [
        driverVM,
        createPriorPolicyMilitaryDeploymentUpdatesVM,
        getNamedInsuredInformation,
        periodStartDate,
        priorPolicyUpdateList
    ]);

    useEffect(() => {
        const { integrationId, role } = getNamedInsuredInformation;
        // Get military deployment records from prior policy list
        const militaryDeploymentRecords = getMilitaryDeploymentRecords(
            integrationId, priorPolicyList
        );

        // check if military deployment record have start date and end date
        if (_.findIndex(
            militaryDeploymentRecords, (record) => !record.startDate && !record.endDate
        ) > -1) {
            // If dates are not available remove military deployment from prior policy list
            const updatedPriorPolicyUpdates = removeMilitaryDeployments(integrationId, priorPolicyList);

            if (onValueChange) {
                onValueChange(updatedPriorPolicyUpdates, 'lobData.personalAuto_EA.priorPolicyUpdates');
            }

            const newPriorPolicyList = viewModelService.clone(priorPolicyList);
            // Create temporary new record for military deployment

            createPriorPolicyMilitaryDeploymentUpdatesVM(_.get(driverVM, 'value.person.publicID', ''),
                role, integrationId, periodStartDate, newPriorPolicyList);
            setPriorPolicyUpdateList(newPriorPolicyList);
        } else {
            const newPriorPolicyList = viewModelService.clone(priorPolicyList);

            setPriorPolicyUpdateList(newPriorPolicyList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation,
            onAddMilitaryDeploymentButtonClick,
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition: 'top',
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        eaMilitaryDeploymentGridContainer: {
            content: uiContent
        },
        addButton: {
            visible: (() => {
                if (viewOnlyMode) {
                    return false;
                }

                const militaryDeploymentRecords = getMilitaryDeploymentRecords(
                    getNamedInsuredInformation.integrationId, priorPolicyUpdateList
                );

                return militaryDeploymentRecords.length === 0;
            })()
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (fieldId, path) => readViewModelValue(
                metadata.pageContent,
                priorPolicyUpdateList,
                fieldId,
                path,
                overrideProps
            ),
        [priorPolicyUpdateList, overrideProps]
    );

    const cancelCloseButtonText = viewOnlyMode
        ? translator(messages.closeButton) : translator(messages.cancelButton);

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ModalHeader title={title} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={priorPolicyUpdateList}
                    overrideProps={overrideProps}
                    onValidationChange={setComponentValidation}
                    resolveValue={readValue}
                    onValueChange={() => { }}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    {cancelCloseButtonText}
                </Button>
                <Button
                    onClick={onSave}
                    hidden={viewOnlyMode}
                    disabled={!isComponentValid || viewOnlyMode}
                >
                    {translator(messages.saveButton)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

EAMilitaryDeploymentModal.propTypes = {
    driverVM: PropTypes.shape({}),
    viewOnlyMode: PropTypes.bool,
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
        clone: PropTypes.func
    }).isRequired,
    onReject: PropTypes.func,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    title: PropTypes.shape({}),
    priorPolicyList: PropTypes.shape({}),
    periodStartDate: PropTypes.shape({}),
    showOptional: PropTypes.bool,
    onValidate: PropTypes.func.isRequired,
    driverPageDisregardFieldValidation: PropTypes.func.isRequired,
    id: PropTypes.string,
    onValueChange: PropTypes.func.isRequired
};
EAMilitaryDeploymentModal.defaultProps = {
    driverVM: {},
    viewOnlyMode: false,
    showOptional: false,
    title: undefined,
    priorPolicyList: {
        value: []
    },
    periodStartDate: new Date(),
    id: undefined,
    isOpen: false,
    onResolve: undefined,
    onReject: undefined
};
export default EAMilitaryDeploymentModal;
