import { defineMessages } from 'react-intl';

export default defineMessages({
    yourHome: {
        id: 'quoteandbind.ea.wizard.step.Drivers',
        defaultMessage: 'Drivers'
    },
    insured: {
        id: 'quoteandbind.ea.wizard.step.Insured',
        defaultMessage: 'Insured'
    },
    construction: {
        id: 'quoteandbind.ea.wizard.step.Vehicles',
        defaultMessage: 'Vehicles'
    },
    riskAnalysis: {
        id: 'quoteandbind.ea.wizard.step.Risk Analysis',
        defaultMessage: 'Risk Analysis'
    },
    underwriting: {
        id: 'quoteandbind.ea.wizard.step.Underwriting',
        defaultMessage: 'Underwriting'
    },
    getYourQuoteFaster: {
        id: 'quoteandbind.views.ea.contact-details.Get your quote faster!',
        defaultMessage: 'Get your quote faster!'
    },
    scanDriverLicenseDescription: {
        id: 'quoteandbind.views.ea.contact-details.Scan your driver license barcode to automatically enter the necessary information.',
        defaultMessage: 'Scan your driver license barcode to automatically enter the necessary information.'
    },
    scanDriverLicenseBarcode: {
        id: 'platform.ea.documentupload.templates.document-upload.Scan driver license barcode',
        defaultMessage: 'Scan driver license barcode'
    },
    scanDriverLicenseBarcodeAutomaticallyEnterDetails: {
        id: 'quoteandbind.views.ea.contact-details.Scan your driver license barcode to automatically enter the necessary information.',
        defaultMessage: 'Scan your driver license barcode to automatically enter the necessary information.'
    },
    prefillVehicles: {
        id: 'quoteandbind.ea.wizard.step.VehiclePrefill',
        defaultMessage: 'Vehicle Prefill'
    },
    exitTransaction: {
        id: 'quoteandbind.ea.wizard.steps.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'quoteandbind.ea.wizard.steps.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'quoteandbind.ea.wizard.steps.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'quoteandbind.ea.wizard.steps.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    },
    prefillDrivers: {
        id: 'quoteandbind.ea.wizard.step.Driver Prefill',
        defaultMessage: 'Driver Prefill'
    }
});
