import { defineMessages } from 'react-intl';

export default defineMessages({
    policyChangeStarted: {
        id: 'e1p.gateway.views.endorsement-detail.This policy change has been successfully started.',
        defaultMessage: 'This policy change has been successfully started.'
    },
    continuePolicyChange: {
        id: 'e1p.gateway.views.endorsement-detail.Continue Policy Change',
        defaultMessage: 'Continue Policy Change'
    },
    policyChangePremiusCalculated: {
        id: 'e1p.gateway.views.endorsement-detail.The premium for this policy change has been calculated',
        defaultMessage: 'The premium for this policy change has been calculated'
    },
    mayWithDrawPolicyChange: {
        id: 'e1p.gateway.views.endorsement-detail.You may withdraw the policy change',
        defaultMessage: 'You may withdraw the policy change'
    },
    uwIssuesHaveBeenRaised: {
        id: 'e1p.gateway.views.endorsement-detail.Underwriting issues have been raised for this policy change.',
        defaultMessage: 'Underwriting issues have been raised for this policy change.'
    },
    uwIssuesHaveBeenApproved: {
        id: 'e1p.gateway.views.endorsement-detail.All underwriting issues have been approved for this policy change.',
        defaultMessage: 'All underwriting issues have been approved for this policy change.'
    },
    policyWithdrawn: {
        id: 'e1p.gateway.views.endorsement-detail.This policy change has been withdrawn',
        defaultMessage: 'This policy change has been withdrawn'
    },
    policyChangeBound: {
        id: 'e1p.gateway.views.endorsement-detail.This policy change has been bound',
        defaultMessage: 'This policy change has been bound'
    },
    mayWithdrawOrContinuePolicyChangeWizard: {
        id: 'e1p.gateway.views.endorsement-detail.You may either withdraw or continue to the policy change.',
        defaultMessage: 'You may either withdraw or continue to the policy change.'
    },
    mayWithdrawOrContinue: {
        id: 'e1p.gateway.views.endorsement-detail.You may either withdraw this change or continue to the policy change.',
        defaultMessage: 'You may either withdraw this change or continue to the policy change.'
    },
    mayWithdrawPolicyChange: {
        id: 'e1p.gateway.views.endorsement-detail.You withdraw the policy change',
        defaultMessage: 'You withdraw the policy change'
    },
    lastDays: {
        id: 'e1p.gateway.views.endorsement-detail.last30Days',
        defaultMessage: 'Issued in Last 30 Days'
    },
    openBound: {
        id: 'e1p.gateway.views.endorsement-detail.Open - Bound',
        defaultMessage: 'Open - Bound'
    },
    openNotBound: {
        id: 'e1p.gateway.views.endorsement-detail.Open - Not Bound',
        defaultMessage: 'Open - Not Bound'
    },
    errorLoadingDocuments: {
        id: 'e1p.gateway.views.endorsement-documents.Error loading documents.',
        defaultMessage: 'Error loading documents.'
    },
    status: {
        id: 'e1p.gateway.views.endorsement-documents.Status',
        defaultMessage: 'Status'
    },
    underwritingIssues: {
        id: 'e1p.gateway.views.endorsement-documents.Underwriting Issues',
        defaultMessage: 'Underwriting Issues'
    },
    errorLoadingNotes: {
        id: 'e1p.gateway.views.endorsement-notes.Error loading notes.',
        defaultMessage: 'Error loading notes.'
    },
    cancel: {
        id: 'e1p.gateway.views.endorsement.Cancel',
        defaultMessage: 'Cancel'
    },
    failedtoSavePolicychange: {
        id: 'e1p.gateway.views.endorsement.Failed to Save Policy Change',
        defaultMessage: 'Failed to Save Policy Change'
    },
    saveAndExit: {
        id: 'e1p.gateway.views.endorsement.Save and Exit',
        defaultMessage: 'Save and Exit'
    },
    quote: {
        id: 'e1p.gateway.views.endorsement.Quote',
        defaultMessage: 'Quote'
    },
    savingPolicyChange: {
        id: 'e1p.gateway.views.endorsement.Saving Policy Change',
        defaultMessage: 'Saving Policy Change'
    },
    unableWithdrawPolicyChange: {
        id: 'e1p.gateway.views.endorsement.Unable to withdraw this policy change',
        defaultMessage: 'Unable to withdraw this policy change'
    },
    withdrawingPolicyChange: {
        id: 'e1p.gateway.views.endorsement.Withdrawing Policy Change',
        defaultMessage: 'Withdrawing Policy Change'
    },
    failedToWithdrawPolicyChange: {
        id: 'e1p.gateway.views.endorsement-detail.Failed to withdraw policy change',
        defaultMessage: 'Failed to withdraw policy change'
    },
    error: {
        id: 'e1p.gateway.directives.JobSummary.Error',
        defaultMessage: 'Error'
    },
    details: {
        id: 'e1p.gateway.directives.JobSummary.Error.Details',
        defaultMessage: 'Error loading quote information.'
    },
    preferredUnderwriter: {
        id: 'e1p.gateway.views.endorsement-detail.Preferred Underwriter',
        defaultMessage: 'Preferred Underwriter'
    },
    policyChangeTermEffectiveDate: {
        id: 'e1p.gateway.views.policy-detail-summary.PolicyChange Term Effective Date',
        defaultMessage: 'Term Effective Date'
    },
    cannotContinuePolicyChange: {
        id: 'e1p.gateway.views.endorsement-detail.This policy change is no longer available due to another bound transaction. If needed, create a new policy change.',
        defaultMessage: 'This policy change is no longer available due to another bound transaction. If needed, create a new policy change.'
    },
    viewPolicyChange: {
        id: 'e1p.gateway.views.endorsement-detail.View Policy Change',
        defaultMessage: 'View Policy Change'
    },
    preemptionInfoMessage: {
        id: 'e1p.gateway.views.endorsement-detail.This policy change is no longer available due to another bound transaction. If needed, create a new policy change.',
        defaultMessage: 'This policy change is no longer available due to another bound transaction. If needed, create a new policy change.'
    },
});
