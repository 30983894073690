import React, {
    useContext, useCallback, useState, useMemo, useEffect
} from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { Loader } from '@jutro/components';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CreditCardUtil } from '@xengage/gw-portals-util-js';
import metadata from './PaymentDetailsPage.metadata.json5';
import messages from './PaymentDetailsPage.messages';

const PAYMENT_METHOD = {
    bank: 'wire',
    credit: 'creditcard'
};

/* eslint-disable no-param-reassign */
function initialiseVM(submissionVM) {
    submissionVM.bindData.paymentDetails.value = submissionVM.bindData.paymentDetails.value || {};
}
/* eslint-enable no-param-reassign */

function PaymentDetailsPage(props) {
    const { wizardData: submissionVM, updateWizardData, wizardSnapshot } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const [isLoading, updateIsLoading] = useState(false);
    const [isVMInitialised, updateIsVMInitialised] = useState(false);
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [hasRetrievedPaymentPlans, updateHasRetrievedPaymentPlans] = useState(false);
    const { isComponentValid, registerComponentValidation } = useValidation('PaymentDetailsPage');

    useEffect(() => {
        if (!isVMInitialised) {
            initialiseVM(submissionVM);
            updateIsVMInitialised(true);
        }
    }, [isVMInitialised, submissionVM]);

    const { monthlyAmount, totalAmount } = useMemo(() => {
        const chosenQuoteData = submissionVM.quoteData.offeredQuotes.value.find(
            ({ publicID }) => publicID === submissionVM.bindData.chosenQuote.value
        );

        return {
            monthlyAmount: _.get(chosenQuoteData, 'premium.monthlyPremium'),
            totalAmount: _.get(chosenQuoteData, 'premium.total')
        };
    }, [submissionVM]);

    const formatPaymentData = useCallback(() => {
        const paymentDetailsPath = 'bindData.paymentDetails';
        const paymentMethod = _.get(submissionVM, `${paymentDetailsPath}.paymentMethod.value`);

        if (paymentMethod === PAYMENT_METHOD.bank) {
            _.set(submissionVM, `${paymentDetailsPath}.creditCardData`, undefined);
        } else if (paymentMethod === PAYMENT_METHOD.credit) {
            _.set(submissionVM, `${paymentDetailsPath}.bankAccountData`, undefined);
        }

        return submissionVM.value;
    }, [submissionVM]);

    const handleNext = useCallback(async () => {
        submissionVM.value = await LoadSaveService.bindSubmission(formatPaymentData(), authHeader);
        return submissionVM;
    }, [LoadSaveService, authHeader, formatPaymentData, submissionVM]);

    if (!hasRetrievedPaymentPlans && !isLoading) {
        const { quoteID, sessionUUID } = submissionVM.value;
        updateIsLoading(true);
        LoadSaveService.retrievePaymentPlans(quoteID, sessionUUID, authHeader)
            .then((paymentPlans) => {
                _.set(submissionVM, 'bindData.paymentPlans', paymentPlans);
                updateWizardData(submissionVM);
                updateHasRetrievedPaymentPlans(true);
            })
            .finally(() => {
                updateIsLoading(false);
            });
    }

    const handlePaymentOptionChange = useCallback(
        (value) => {
            let dataToOmit;
            if (value === PAYMENT_METHOD.bank) {
                dataToOmit = 'creditCardData';
                submissionVM.bindData.paymentDetails.bankAccountData.value = _.get(
                    submissionVM.value,
                    'bindData.paymentDetails.bankAccountData',
                    {}
                );
            } else {
                dataToOmit = 'bankAccountData';
                submissionVM.bindData.paymentDetails.creditCardData.value = _.get(
                    submissionVM.value,
                    'bindData.paymentDetails.creditCardData',
                    {}
                );
            }

            submissionVM.value = _.omit(
                submissionVM.value,
                `bindData.paymentDetails.${dataToOmit}`
            );

            _.set(submissionVM, 'bindData.paymentDetails.paymentMethod', value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const isModelValid = useCallback(() => {
        const paymentMethod = _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value');
        const bankAccountData = _.get(submissionVM, 'bindData.paymentDetails.bankAccountData', {});
        const creditCardData = _.get(submissionVM, 'bindData.paymentDetails.creditCardData', {});

        const paymentVM = paymentMethod === 'wire' ? bankAccountData : creditCardData;
        if (!_.isEmpty(paymentVM)) {
            return paymentVM.aspects.valid && paymentVM.aspects.subtreeValid;
        }
        return true;
    }, [submissionVM]);

    useEffect(() => {
        registerComponentValidation(isModelValid);
    }, [registerComponentValidation, isModelValid]);

    const paymentMethod = _.get(submissionVM, 'bindData.paymentDetails.paymentMethod.value');
    
    const creditCardIssuer = _.get(
        submissionVM,
        'bindData.paymentDetails.creditCardData.creditCardIssuer.code.value'
    );

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        monthlyPremium: {
            value: monthlyAmount
        },
        totalPremium: {
            value: totalAmount
        },
        paymentOptions: {
            onValueChange: handlePaymentOptionChange
        },
        bankAccountContainer: {
            visible: paymentMethod === PAYMENT_METHOD.bank
        },
        creditCardContainer: {
            visible: paymentMethod === PAYMENT_METHOD.credit
        },
        creditCardNumber: {
            mask: CreditCardUtil.getInputMaskForIssuerCode(creditCardIssuer)
        }
    };

    

    const handleCancel = useCallback(() => {
        return isComponentValid ? submissionVM : wizardSnapshot;
    }, [isComponentValid, submissionVM, wizardSnapshot]);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );

    return isLoading ? (
        <Loader loaded={!isLoading} />
    ) : (
        <WizardPage
            nextLabel={messages.paymentBuyNow}
            onNext={handleNext}
            disableNext={!isComponentValid}
            onCancel={handleCancel}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

PaymentDetailsPage.propTypes = wizardProps;
export default PaymentDetailsPage;
