import { defineMessages } from 'react-intl';

export default defineMessages({
    personalInjuryProtection: {
        id: 'common.component.personalInjuryProtection.Personal Injury Protection Coverages',
        defaultMessage: 'Personal Injury Protection Coverages'
    },
    coverageTitle: {
        id: 'common.component.personalInjuryProtection.dtcolumn.coverageName',
        defaultMessage: 'Coverage'
    },
    columnA: {
        id: 'common.component.personalInjuryProtection.dtcolumn.A',
        defaultMessage: 'A'
    },
    columnB: {
        id: 'common.component.personalInjuryProtection.dtcolumn.B',
        defaultMessage: 'B'
    },
    columnC: {
        id: 'common.component.personalInjuryProtection.dtcolumn.C',
        defaultMessage: 'C'
    },
    columnD: {
        id: 'common.component.personalInjuryProtection.dtcolumn.D',
        defaultMessage: 'D'
    },
    columnE: {
        id: 'common.component.personalInjuryProtection.dtcolumn.E',
        defaultMessage: 'E'
    },
});
