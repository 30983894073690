import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { withModalContext } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { ServiceManager } from '@jutro/legacy/services';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { ActivitiesService } from 'e1p-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { SelectProducerCode, withProducerContext } from 'gw-gateway-common-react';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import ActivityTableComponent from '../Components/Activity/ActivityTableComponent/ActivityTableComponent';
import metadata from './LandingPage.metadata.json5';
import styles from './Landing.module.scss';
import '../Activities/Activities.messages';
import gatewayMessages from '../gateway.messages';

class LandingPageWithoutModalContext extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        jobsCreatedInLastXDays: PropTypes.number,
        producerCode: PropTypes.shape({}).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authUserData: PropTypes.shape({}).isRequired
    };

    static defaultProps = {
        jobsCreatedInLastXDays: 1
    };

    static contextType = TranslatorContext;

    localeService = ServiceManager.getService('locale-service');

    state = {
        activitiesAllCompletedCount: 0,
        activitiesCanceledCount: 0,
        activitiesFuture: [],
        activitiesOverdue: [],
        activitiesThisWeek: [],
        activitiesToday: [],
        activitiesTomorrow: [],
        allCompletedActivityTileStatus: false,
        allCancelledActivityTileStatus: false,
        displayNoActivities: false,
        summaries: [],
        isLoading: true,
        activityResponseData: []
    };

    componentDidMount() {
        this.getActivitySummaries();

        const activeLanguage = localStorage.getItem('selectedLanguage');

        if (activeLanguage === undefined) {
            localStorage.setItem('selectedLanguage', this.localeService.getPreferredLocale());
        }
    }

    getBreakpointTracker = () => <BreakpointTrackerContext.Consumer />;

    createNote = async (data, publicID) => {
        const { authHeader } = this.props;
        const { activityResponseData } = this.state;
        const activityNoteData = await ActivitiesService.createNoteForActivity(
            publicID,
            data,
            authHeader
        );
        const activityObj = activityResponseData.find((obj) => obj.publicID === publicID);

        activityObj.notes.push(activityNoteData);

        if (!_.isUndefined(activityNoteData)) {
            this.props.modalContext.showAlert({
                title: gatewayMessages.noteCreated,
                message: gatewayMessages.noteCreatedSuccessfully,
                status: 'success',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).then(() => {
                this.getResponseData(activityResponseData);
            }, _.noop);
        }
    };

    handleValueChange = (producerCode) => {
        this.filterAccountJobSummaries(producerCode);
    };

    filterAccountJobSummaries = (producerCode) => {
        const { summaries } = this.state;
        const accountJobsSummary = summaries.find((res) => res.producerCode === producerCode);

        this.setState({
            isLoading: false
        });
    };

    getActivitySummaries = () => {
        const { authHeader } = this.props;

        ActivitiesService.getActivitiesForUser(authHeader).then((response) => {
            const activities = _.map(response, (activity) => _.extend({}, activity, { expanded: false }));

            this.setState({ activitiesResult: activities });
            this.setState({ activitiesResult: activities, isLoading: false });
            this.getResponseData(activities);
        });
    };

    getNoOfDays = (data) => {
        const currentDate = new Date();
        const eDate = new Date(data.dueDate);
        const noOfDays = currentDate - eDate;
        const noOfDueDay = noOfDays / (1000 * 3600 * 24);

        return Math.floor(noOfDueDay);
    };

    onClickComplete = (data) => {
        const { activitiesResult } = this.state;
        const { authHeader } = this.props;
        const activityObj = activitiesResult.find((obj) => obj.publicID === data);

        ActivitiesService.markActivityAsCompleted(data, authHeader).then((completeActivity) => {
            if (activityObj) {
                activityObj.status = completeActivity.status;
                activityObj.closeDate = completeActivity.closeDate;
                activityObj.completedDate = completeActivity.completedDate;
                activityObj.canComplete = false;
            }

            this.setState({
                publicId: data,
                activitiesResult: [...activitiesResult, activityObj]
            });
        });
    };

    activitiesTodayData = (data, status) => data.filter((activitiesInfo) => {
        const today = moment();

        return (
            activitiesInfo.status === status
            && moment(activitiesInfo.dueDate).isSame(today, 'd')
        );
    });

    activitiesFutureData = (data, status) => data.filter((activitiesInfo) => (
            activitiesInfo.status === status
            && !moment(activitiesInfo.dueDate).isBefore(moment().endOf('isoWeek'), 'd')
        ));

    activitiesOverdueData = (data) => data.filter((activitiesInfo) => this.getNoOfDays(activitiesInfo) > 0);

    activitiesThisWeekData = (data, status) => data.filter((activitiesInfo) => {
        const today = moment();
        const tomorrow = moment().add(1, 'days');

        return (
            !moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
            && !moment(activitiesInfo.dueDate).isSame(today, 'd')
            && activitiesInfo.status === status
            && (this.getNoOfDays(activitiesInfo) > 0
                ? false
                : this.getNoOfDays(activitiesInfo) * -1
                < moment(activitiesInfo.dueDate).isoWeekday())
        );
    });

    activitiesTomorrowData = (data, status) => data.filter((activitiesInfo) => {
        const tomorrow = moment().add(1, 'days');

        return (
            activitiesInfo.status === status
            && moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
        );
    });

    getResponseData = (activitiesResponse) => {
        const activityUndefinedOverdue = [];
        const activitiesData = [];
        let activityOverdue = [];

        activitiesResponse.forEach((activitiesInfo) => {
            if (_.isUndefined(activitiesInfo.dueDate) && activitiesInfo.status !== 'complete') {
                activityUndefinedOverdue.push(activitiesInfo);
            } else {
                activitiesData.push(activitiesInfo);
            }
        });

        const activitiesFuture = this.activitiesFutureData(activitiesData, 'open').filter(
            (activitiesInfo) => activitiesInfo.isAssignedToCurrentUser
        );
        const activitiesOverdue = this.activitiesOverdueData(activitiesData).filter(
            (activitiesInfo) => activitiesInfo.isAssignedToCurrentUser
        );
        const activitiesToday = this.activitiesTodayData(activitiesData, 'open').filter(
            (activitiesInfo) => activitiesInfo.isAssignedToCurrentUser
        );
        const activitiesThisWeek = this.activitiesThisWeekData(activitiesData, 'open').filter(
            (activitiesInfo) => activitiesInfo.isAssignedToCurrentUser
        );
        const activitiesTomorrow = this.activitiesTomorrowData(activitiesData, 'open').filter(
            (activitiesInfo) => activitiesInfo.isAssignedToCurrentUser
        );

        activityOverdue = activityUndefinedOverdue.filter((activitiesInfo) => activitiesInfo.isAssignedToCurrentUser);

        if (
            activitiesOverdue.length === 0
            && activitiesToday.length === 0
            && activitiesFuture.length === 0
            && activitiesTomorrow.length === 0
        ) {
            this.setState({
                displayNoActivities: true,
                activitiesOverdue,
                activitiesToday,
                activitiesFuture,
                activitiesThisWeek,
                activitiesTomorrow,
                activityResponseData: activitiesData
            });
        } else {
            this.setState({
                activitiesOverdue: activityOverdue.concat(
                    _.orderBy(activitiesOverdue, 'dueDate', 'asc')
                ),
                activitiesToday: _.sortBy(activitiesToday, 'dueDate'),
                activitiesFuture: _.sortBy(activitiesFuture, 'dueDate'),
                activitiesThisWeek: _.sortBy(activitiesThisWeek, 'dueDate'),
                activitiesTomorrow: _.sortBy(activitiesTomorrow, 'dueDate'),
                displayNoActivities: false,
                activityResponseData: activitiesData
            });
        }
    };

    handleTilesOnClick = (id) => {
        let pathRedirect = '';
        const { history } = this.props;

        switch (id) {
            case 'openQuotesTile':
                pathRedirect = {
                    pathname: 'policies',
                    state: {
                        selectedTile: 'quotes'
                    }
                };
                break;
            case 'policyChangeTile':
                pathRedirect = {
                    pathname: 'policies',
                    state: {
                        selectedTile: 'change'
                    }
                };
                break;
            case 'cancellationTile':
                pathRedirect = {
                    pathname: 'policies',
                    state: {
                        selectedTile: 'cancellation'
                    }
                };
                break;
            case 'renewalTile':
                pathRedirect = {
                    pathname: 'policies',
                    state: {
                        selectedTile: 'renewal'
                    }
                };
                break;
            default:
                break;
        }

        return history.push(pathRedirect);
    };

    canAccessActivities = () => {
        const { authUserData } = this.props;

        return authUserData?.permissions_Ext.includes('actview_ext');
    };

    render() {
        const breakpointContext = this.getBreakpointTracker();
        const {
            type: { _currentValue: breakpoint }
        } = breakpointContext;
        const {
            allCompletedActivityTileStatus,
            allCancelledActivityTileStatus,
            activitiesOverdue,
            activitiesToday,
            activitiesFuture,
            displayNoActivities,
            activitiesThisWeek,
            activitiesTomorrow,
            activitiesCanceledCount,
            activitiesAllCompletedCount,
            openSubmissions,
            openCancellations,
            openRenewals,
            openPolicyChanges,
            isLoading,
            publicId
        } = this.state;

        if (isLoading) {
            return <E1PLoader loaded={!isLoading} />;
        }

        const overrides = {
            policyChangeTile: {
                value: openPolicyChanges,
                tileSize: breakpoint === 'tablet' ? 'medium' : 'large'
            },
            openQuotesTile: {
                value: openSubmissions,
                tileSize: breakpoint === 'tablet' ? 'medium' : 'large'
            },
            cancellationTile: {
                value: openCancellations,
                tileSize: breakpoint === 'tablet' ? 'medium' : 'large'
            },
            renewalTile: {
                value: openRenewals,
                tileSize: breakpoint === 'tablet' ? 'medium' : 'large'
            },
            allCompletedActivityTile: {
                value: activitiesAllCompletedCount,
                active: allCompletedActivityTileStatus
            },
            allCancelledActivityTile: {
                value: activitiesCanceledCount,
                active: allCancelledActivityTileStatus
            },
            activitiesTableContainer: {
                activitiesOverdue,
                activitiesToday,
                activitiesTomorrow,
                activitiesThisWeek,
                activitiesFuture,
                activitiesComplete: [],
                displayNoActivities,
                createNote: this.createNote,
                onClickComplete: this.onClickComplete,
                publicId
            },
            activitiesTableWrapper: {
                visible: this.canAccessActivities()
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                selectproducercode: SelectProducerCode,
                activitytablecomponent: ActivityTableComponent
            },
            resolveCallbackMap: {
                handleTilesOnClick: this.handleTilesOnClick,
                handleFilterClick: this.handleFilterClick,
                handleValueChange: this.handleValueChange
            }
        };
        const landingPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;

        return <div className={styles.landingStyles}>{landingPage}</div>;
    }
}

const LandingPage = withModalContext(LandingPageWithoutModalContext);

export const LandingPageComponent = LandingPage;
export default withAuthenticationContext(withProducerContext(LandingPage));
