import React, { useCallback, useState, useMemo } from 'react';
import {
    get as _get
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useUWIssueUtil } from 'e1p-capability-hooks';
import metadata from '../RiskAnalysisPage.metadata.json5';
import styles from '../RiskAnalysisPage.module.scss';

function RiskAnalysisPage(props) {
    const { authHeader } = useAuthentication();
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        authUserData
    } = props;

    const jobNumber = _get(renewalVM, 'jobID.value');
    const [geoDataResponse, setGeoDataResponse] = useState({});
    const [responseAuto, setResponseAuto] = useState([]);
    const [responseProperty, setResponseProperty] = useState({});

    const {
        hasUWIssues
    } = useUWIssueUtil(
        renewalVM,
        updateWizardData,
        jumpTo,
        steps
    );


    const saveAndQuote = useCallback(
        () => renewalVM,
        [renewalVM]
    );

    const uwIssuesPresent = useMemo(() => 
         hasUWIssues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [renewalVM?.errorsAndWarnings_Ext?.underwritingIssues?.value]);

    const getDefaultActiveTab = useMemo(() => {
        if (uwIssuesPresent) {
            return 'uwIssuesTab';
        }

        return 'lossesAndViolationTab';
    }, [uwIssuesPresent]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: () => {},
        },
    };
    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(renewalVM, 'lobData.homeowners_EH.premiumStability.value') !== undefined;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            disabled: true
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: true
        },
        riskAnalysisTabs: {
            visible: true,
            defaultActiveTab: getDefaultActiveTab
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(renewalVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            onValidate: () => {},
            showErrors: false,
            viewOnlyMode: true
        },
        lossesAndViolationComponent: {
            responseAuto,
            setResponseAuto,
            responseProperty,
            setResponseProperty,
            submissionVM: renewalVM,
            updateWizardData,
            setLossesPageValid: () => {},
            isSkipping,
            authUserData,
            viewOnlyMode: true
        },
        miscellaneaousReportsTab: {
            // visible: false
        },
        EHRiskAnalysisMiscComponentId: {
            submissionVM: renewalVM,
            visible: true
        },
        creditReportsComponent: {
            submissionVM: renewalVM,
            updateWizardData,
            lineOfBusiness: renewalVM.lobData.homeowners_EH,
            lobName: 'homeowners_EH',
            authUserData,
            id: 'creditReportsComponent',
            onValidate: () => {},
            setOverlappingException: () => {},
            viewOnlyMode: true
        },
        geoDataComponent: {
            quoteID: jobNumber,
            geoDataResponse,
            setGeoDataResponse,
            policyState: _get(renewalVM, 'baseData.policyAddress.state.value.code')
        },
        uwIssueComponent: {
            submissionVM: renewalVM,
            updateWizardData,
            authHeader,
            viewOnlyMode: true
        },
        diminishingAvailableAmount: {
            labelPosition: 'left'
        },
        diminishingAvailableAmountLatest: {
            labelPosition: 'left'
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    return (
        <WizardPage
            onNext={saveAndQuote}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={() => {}}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
