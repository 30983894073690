
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './EANotInHouseholdComponent.metadata.json5';
import './EANotInHouseholdComponent.messages';


function EANotInHouseholdComponent(props) {
    const {
        value: driverVM,
        showOptional,
        viewOnlyMode
    } = props;
    const [addToQuote] = useState(driverVM.driverStatus === 'AddedToQuote');
    const [notInHousehold] = useState(driverVM.driverStatus === 'NotInHousehold');

    useEffect(() => {
        if (!driverVM.displayName) {
            const middleName = driverVM.middleName ? driverVM.middleName : '';

            driverVM.displayName = `${driverVM.firstName} ${middleName} ${driverVM.lastName}`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (addToQuote) {
            driverVM.driverStatus = 'AddedToQuote';
        } else {
            driverVM.driverStatus = 'NotInHousehold';
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addToQuote, driverVM.driverStatus, notInHousehold]);

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition: 'top',
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        eaPrefillNotInHousehold: {
            value: notInHousehold,
            readOnly: true,
            visible: driverVM.driverStatus === 'NotInHousehold'
        },
        eaPrefillDriverName: {
            readOnly: true,
            visible: driverVM.driverStatus === 'NotInHousehold'
        },
        eaPrefillDriverdateOfBirth: {
            readOnly: true,
            visible: driverVM.driverStatus === 'NotInHousehold'
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={driverVM}
            overrideProps={overrideProps}
        />
    );
}

EANotInHouseholdComponent.propTypes = {
    value: PropTypes.shape({}),
    showOptional: PropTypes.bool,
    viewOnlyMode: PropTypes.bool
};
EANotInHouseholdComponent.defaultProps = {
    value: {},
    showOptional: true,
    viewOnlyMode: false,
};
export default EANotInHouseholdComponent;
