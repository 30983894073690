import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import htmlParser from 'html-react-parser';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './ComprehensiveOnlyDescriptionComponent.metadata.json5';
import styles from './ComprehensiveOnlyDescriptionComponent.module.scss';
import messages from './ComprehensiveOnlyDescriptionComponent.messages';

import { Button } from '@jutro/legacy/components';

function ComprehensiveOnlyDescriptionComponent(props) {
    const {
        isOpen,
        onReject,
        isInLine
    } = props;

    const compOnlyDescriptionText = '<span>By selecting Comprehensive Only coverage, the Named Insured(s) can request to remove the following important coverages. This option is intended only for vehicles which are <b>stored and not driven at all</b>. Please be sure that you have discussed all the following coverage removals and warnings with the Named Insured(s):</span>';

    const compOnlyUnorderedListItems = {
        0: '<span>All Policy Level Coverages are removed for a comprehensive Only vehicle (depending on the State): <b>Bodily Injury, Property Damage, Medical Payments and/or Medical Expense, Personal Injury, First Party Benefits, Accidental Death & Dismemberment, No-Fault, Uninsured, Underinsured</b> and <b>Collision coverage</b>. This list does not represent all the coverages removed.</span>',
        1: '<span>All Vehicle Level Coverage(s) are removed. The ONLY coverage that will remain on you client\'s policy is physical damage coverage known as “Other than Collision,” also called Comprehensive.</span>',
        2: '<span>It is the Named Insured\'s responsibility to advise the Company when coverages should be added back on to the vehicle.</span>',
        3: '<span><u>WARNING:</u> Removal of these coverages means that this policy will no longer comply with minimum state financial responsibility requirements. Liability, Personal Injury and Uninsured and Underinsured Coverage may be mandated your state. If any of these coverages are mandatory, inform your client it is their responsibility to ensure that all state regulatory requirements are met (e.g., Tags turned in, etc.).</span>',
        4: '<span><u>WARNING:</u> Removal of these coverages does not comply with any agreements this policyholder may have made to carry Physical Damage coverage with their lienholder, loss payee, lessor, or additional insureds.</span>',
        5: '<span><u>WARNING:</u> For Clients with an Main Street America Insurance Umbrella. The deletion of Liability coverage from the above policy will mean that there is NO PRIMARY Coverage on that vehicle. The Umbrella Policy requires a policyholder to carry primary automobile liability insurance on all owned vehicles. Your client may have an uncovered gap in insurance benefits provided by an MSA GROUP Umbrella policy.</span>',
        6: '<span><u>WARNING:</u> If this request deletes coverage from a single vehicle policy, when your client rents a car or any type of automobile or uses a nonowned vehicle, they will only have Other Than Collision (Comprehensive) to provide physical damage coverage on that rented vehicle or any type of non-owned vehicles.</span>',
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        compOnlyMainDescription: {
            content: htmlParser(compOnlyDescriptionText)
        },
        itemDesc0: {
            content: htmlParser(compOnlyUnorderedListItems[0])
        },
        itemDesc1: {
            content: htmlParser(compOnlyUnorderedListItems[1])
        },
        itemDesc2: {
            content: htmlParser(compOnlyUnorderedListItems[2])
        },
        itemDesc3: {
            content: htmlParser(compOnlyUnorderedListItems[3])
        },
        itemDesc4: {
            content: htmlParser(compOnlyUnorderedListItems[4])
        },
        itemDesc5: {
            content: htmlParser(compOnlyUnorderedListItems[5])
        },
        itemDesc6: {
            content: htmlParser(compOnlyUnorderedListItems[6])
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {}
    };

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    const viewModel = (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
    const isInLineComponent = (
        <ModalBody>
            {viewModel}
        </ModalBody>
    );

    if (isInLine) {
        return isInLineComponent;
    }

    return (
        <ModalNext isOpen={isOpen} className={styles.popUp}>
            <ModalHeader title={messages.compOnlyHeaderDescription} />
            <ModalBody>
                {viewModel}
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="filled">
                    {commonMessages.close}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

ComprehensiveOnlyDescriptionComponent.propTypes = {
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isInLine: PropTypes.bool
};

ComprehensiveOnlyDescriptionComponent.defaultProps = {
    isInLine: false
};

export default ComprehensiveOnlyDescriptionComponent;
