import React, {
    useCallback, useContext, useMemo, useRef
} from 'react';
import {
    get, sortBy, uniqBy
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useModifierUtil } from 'e1p-capability-hooks';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import messages from '../QuotePage.messages';
import metadata from '../QuotePage.metadata.json5';

function structureClauseTableData(coverage) {
    // putting ID into an object as the Jutro table component expects an object
    return {
        publicID: coverage.publicID
    };
}

function generateClauseData(columnData, coveragePath, vehicleFixedID) {
    return columnData.map(({ lob, code }) => {
        let completeCoveragePath = `coverages.${coveragePath}`;

        if (vehicleFixedID) {
            const vehicleOfferings = lob.data.coverages.vehicleCoverages;
            const vehicleOfferingIndex = vehicleOfferings.findIndex(
                (vehicleCoverage) => vehicleCoverage.fixedId === vehicleFixedID
            );

            completeCoveragePath = `coverages.${coveragePath}.children[${vehicleOfferingIndex}].coverages`;
        }

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

const getCoveragesUniqueID = (submissionVM) => {
    const offerings = get(
        submissionVM,
        'lobData.personalAuto_EA.offerings.value'
    );
    const lineCoverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.lineCoverages.map(structureClauseTableData)
    )), 'publicID');
    const vehicleCoverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.vehicleCoverages.flatMap(({ coverages }) => (
            coverages.map(structureClauseTableData)
        ))
    )), 'publicID');

    return {
        lineCoverages,
        vehicleCoverages
    };
};

const generateColumnData = (submissionVM) => {
    const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = get(submissionVM, `${lobOfferingPath}.value`);
    const quoteOfferings = get(submissionVM, `${quoteOfferingPath}.value`) || [];
    const selectedVersion = quoteOfferings.find((version) => version.selected);

    let columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];

            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                }
            };
        });

    columnData = columnData.length > 1 ? [columnData.find((offering) => offering.code === selectedVersion.branchCode)]
        : columnData;

    return sortBy(columnData, ['code']);
};

const generateTableData = (submissionVM, columnData, translator) => {
    const vehicles = get(
        submissionVM,
        'lobData.personalAuto_EA.coverables.vehicles.value'
    );
    const uniqueID = getCoveragesUniqueID(submissionVM);

    const lineCoverages = {
        header: translator(messages.generalCoverages),
        data: uniqueID.lineCoverages,
        tableContent: generateClauseData(columnData, 'lineCoverages'),
        coverageType: 'lineCoverages'
    };

    const vehicleCoverages = vehicles.map((vehicle) => ({
        header: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
        data: uniqueID.vehicleCoverages,
        tableContent: generateClauseData(
            columnData,
            'vehicleCoverages',
            vehicle.fixedId
        ),
        coverageType: 'vehicleCoverages',
        fixedId: vehicle.fixedId
    }));

    return [lineCoverages, ...vehicleCoverages];
};

function ViewQuotePage(props) {
    const {
        wizardData: submissionVM
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const isQuickQuote = useRef(
        get(submissionVM.value, 'quoteType') === 'Quick'
    ).current;
    const {
        getStatePACSurcharge
    } = useModifierUtil(submissionVM);

    const isCompraterFlow = useMemo(() => {
        if (get(submissionVM, 'baseData.quoteSource_Ext.sourceType')) {
            const isSourceTypeCompRater = get(submissionVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'comprater';
            const quoteType = get(submissionVM, 'quoteType_Ext.value.code');

            return (isSourceTypeCompRater && quoteType === 'Quick');
        }

        return false;
    }, [submissionVM]);

    const {
        structuredModifiers
    } = useModifierUtil(submissionVM);
    const modifiers = structuredModifiers;

    const buyNow = useCallback(
        async () => submissionVM, [submissionVM]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const accidentForgivenessApplied = () => {
        if (
            submissionVM.lobData.personalAuto_EA.accidentForgivenessStatuses.children.length !== 0
            && submissionVM.lobData.personalAuto_EA.accidentForgivenessStatuses.children[0].code.value === 'Earned Accident Forgiveness'
        ) {
            return true;
        }

        return false;
    };

    const generateOverrides = useCallback(() => {
        const columnData = generateColumnData(submissionVM);
        const { quoteID } = submissionVM;

        const initialOverrides = {
            '@field': {
                // apply to all fields
                labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
            },
            quotePageLoadingIndicator: {
                loaded: true
            },
            quoteProposalLinkId: {
                disabled: true
            },
            accidentForgivenessMessageDiv: {
                visible: submissionVM.value.quoteType === 'Full' && accidentForgivenessApplied()
            },
            quoteTable: {
                columnData,
                tableData: generateTableData(submissionVM, columnData, translator),
                modifiers,
                quoteID: quoteID.value,
                submissionVM,
                viewModelService,
                updateWizardData: () => {},
                authHeader,
                visible: true,
                isComprater: isCompraterFlow,
                viewOnlyMode: true,
                authUserData,
                isSurchargeLineCoverageAvailable: getStatePACSurcharge().length > 0,
                opCo
            },
            e1pAcknowledgementComponent: {
                acknowledgements: get(submissionVM, 'lobData.personalAuto_EA.acknowledgements'),
                policyState: {
                    code: get(submissionVM, 'baseData.policyAddress.state.value.code'),
                    name: translator({ id: get(submissionVM, 'baseData.policyAddress.state.value.name') })
                },
                visible: isCompraterFlow,
                viewOnly: true,
                lob: 'personalAuto_EA'
            },
            enterSideBySide: {
                visible: false
            },
            sideBySideContent: {
                visible: false
            },
            maxVersionsMessageDiv: {
                visible: false
            },
            minVersionsMessageDiv: {
                visible: false
            },
            deleteVersionsMessageDiv: {
                visible: false
            },
            quoteProposalValidationDiv: {
                visible: false
            },
            quoteProposalFailureErrorDiv: {
                visible: false
            },
            paperlessEmailChangedMessageDiv: {
                visible: false
            }
        };

        return { ...initialOverrides};
    }, [accidentForgivenessApplied,
        authHeader,
        authUserData,
        breakpoint,
        isCompraterFlow,
        modifiers,
        submissionVM,
        translator,
        viewModelService,
        getStatePACSurcharge,
        opCo
    ]);

    return (
        <WizardPage
            finish={isQuickQuote}
            shouldLink
            showNext={get(submissionVM, 'value.quoteType') === 'Full'}
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveCallbackMap: {
                        onBuyNow: () => buyNow().then(onNext),
                        onStaleQuoteBranchCode: () => { },
                        onRecalculate: () => { },
                        onResetQuote: () => { },
                        onChangeSubmissionAndSync: () => { },
                        onChangeSubmission: () => { },
                        onSyncCoverages: () => { },
                        onPrint: () => { },
                        onDisableNextForTable: () => { }
                    }
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={generateOverrides()}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                );
            }}
        </WizardPage>
    );
}

ViewQuotePage.propTypes = wizardProps;
export default ViewQuotePage;
