import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    get as _get,
    set as _set
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import moment from 'moment';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { EndorsementService } from 'e1p-capability-policychange';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { RandomNumberUtil } from 'e1p-portals-util-js';
import { useLandingPageUtil, useEndorsementUtil } from 'e1p-capability-hooks';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './StartChangePage.metadata.json5';
import messages from './StartChangePage.messages';

const LOB = 'personalUmbrella_EU';

function StartChangePage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        steps,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        updateWizardSnapshot,
        updateIsAllPageSubmittedFlow
    } = props;
    const { authHeader, authUserData } = useAuthentication();
    const [isLoading, setIsLoading] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const { changeSourceValues } = useEndorsementUtil(policyChangeVM, authUserData);
    const {
        initialValidation,
        isComponentValid,
        onValidate,
        registerInitialComponentValidation
    } = useValidation('StartChangePage');
    const sessionIdPrefixEdit = 'EU - PC - ';
    const sessionHeader = sessionIdPrefixEdit + RandomNumberUtil.randomFixedInteger(10);
    const canAddEditPremiumAdjustment = authUserData.permissions_Ext.includes('editpremiumadjustment_ext');
    const canRateCorrectionSupression = authUserData.permissions_Ext.includes('ratecorrectionsuppression_ext');
    const {
        getLandingPageIndexForQuotedJob,
        getLandingPageIndex
    } = useLandingPageUtil();
    const translator = useTranslator();
    const [policyIsBackDated, setPolicyIsBackDated] = useState(false);
    const hasTransactionStarted = useCallback(() => !!_get(policyChangeVM, 'jobID.value'), [policyChangeVM]);

    useEffect(() => {
        registerInitialComponentValidation(hasTransactionStarted);
    }, [hasTransactionStarted, registerInitialComponentValidation]);

    useEffect(() => {
        const value = changeSourceValues[0]?.code;

        // Default the value of Source dropdown, incase if there is only 1 option available.
        if (changeSourceValues?.length === 1) {
            _set(policyChangeVM, 'policyChangeSource_Ext.value', value);
            updateWizardData(policyChangeVM);
        }

        if (isPageJumpEnabled && hasTransactionStarted()) {
            updateIsPageJumpEnabled(false);

            let newLandingPageIndex = -1;

            if (_get(policyChangeVM, 'status.value.code') === 'Quoted') {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            } else {
                newLandingPageIndex = getLandingPageIndex(
                    LOB,
                    policyChangeVM.policyChangeSource_Ext.value.code,
                    policyChangeVM.policyChangeReason_Ext.value
                        ? policyChangeVM.policyChangeReason_Ext.value.code : null,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsLoading(true);

            if (policyChangeVM.jobID.value === undefined) {
                authHeader['afe-session-id'] = sessionHeader;

                const createResponse = await EndorsementService
                    .createPolicyChange(policyChangeVM.value.policyNumber,
                        policyChangeVM.value.baseData.effectiveDate,
                        policyChangeVM.policyChangeReason_Ext.value.code,
                        policyChangeVM.policyChangeSource_Ext.value.code,
                        policyChangeVM.hasLossOnOrAfterEffDate_Ext.value,
                        authHeader);

                if (createResponse.baseData.exceptions_Ext) {
                    // This code is to handle the exception by showing it
                    // using Inline Notification Component
                    _set(policyChangeVM, 'baseData.exceptions_Ext.value', createResponse.baseData.exceptions_Ext);
                    updateWizardData(policyChangeVM);
                    setIsLoading(false);
                } else {
                    // the description field is on the start page and needs to be saved if user has entered the value.
                    const description = _get(policyChangeVM, 'description.value');
                    
                    if(description){
                        _set(createResponse, 'description', description);
                    }

                    const saveResponse = await EndorsementService.saveEndorsement(
                        [(createResponse)],
                        authHeader
                    );

                    _set(policyChangeVM, 'value', saveResponse);
                    policyChangeVM.policyType_Ext.value ??= saveResponse.policyType;
                    policyChangeVM.policyChangeReason_Ext.value ??= saveResponse.policyChangeReason;
                    policyChangeVM.policyChangeSource_Ext.value ??= saveResponse.policyChangeSource;
                    policyChangeVM.hasLossOnOrAfterEffDate_Ext.value ??= saveResponse.hasLossOnOrAfterEffDate;
                    authHeader['afe-session-id'] = sessionIdPrefixEdit + _get(policyChangeVM, 'jobID.value');
                    updateWizardData(policyChangeVM);
                    updateWizardSnapshot(policyChangeVM);
                    setIsLoading(false);

                    if (_get(policyChangeVM, 'jobID.value')) {
                        /**
                         * E1PAP1PC-14508 :
                         * once job is created, updating state variable
                         * so that all pages will be available to navigate
                         */
                        updateIsAllPageSubmittedFlow(true);
                    }

                    const newLandingPageIndex = getLandingPageIndex(
                        LOB,
                        policyChangeVM.policyChangeSource_Ext.value.code,
                        policyChangeVM.policyChangeReason_Ext.value
                            ? policyChangeVM.policyChangeReason_Ext.value.code
                            : null,
                        steps
                    );

                    if (newLandingPageIndex >= 0) {
                        jumpTo(newLandingPageIndex, true);

                        return false;
                    }

                    return policyChangeVM;
                }
            } else {
                 
                 
                authHeader['afe-session-id'] = sessionIdPrefixEdit + _get(policyChangeVM, 'jobID.value');

                const saveResponse = await EndorsementService.saveEndorsement(
                    [(policyChangeVM.value)],
                    authHeader
                );

                _set(policyChangeVM, 'value', saveResponse);
                policyChangeVM.policyType_Ext.value ??= saveResponse.policyType;
                policyChangeVM.policyChangeReason_Ext.value ??= saveResponse.policyChangeReason;
                policyChangeVM.policyChangeSource_Ext.value ??= saveResponse.policyChangeSource;
                updateWizardData(policyChangeVM);
                setIsLoading(false);

                return policyChangeVM;
            }

            return false;
        },
        [
            authHeader,
            getLandingPageIndex,
            isComponentValid,
            jumpTo,
            policyChangeVM,
            sessionHeader,
            steps,
            updateWizardData,
            updateWizardSnapshot,
            updateIsAllPageSubmittedFlow
        ]
    );

    const onChangeSource = useCallback((value) => {
        _set(policyChangeVM, 'policyChangeSource_Ext.value', value);
        // on change of source setting reason to undefined
        _set(policyChangeVM, 'policyChangeReason_Ext', undefined);
        updateWizardData(policyChangeVM);
    }, [policyChangeVM, updateWizardData]);

    const onChangeReason = useCallback((value) => {
        _set(policyChangeVM, 'policyChangeReason_Ext.value', value);
        updateWizardData(policyChangeVM);
    }, [policyChangeVM, updateWizardData]);

    const onLossAfterEffDateChange = useCallback((value) => {
        _set(policyChangeVM, 'hasLossOnOrAfterEffDate_Ext.value', value);
        updateWizardData(policyChangeVM);
    }, [policyChangeVM, updateWizardData]);

    const reasonValues = useMemo(() => {
        const currentPolicyChangeReason = _get(
            policyChangeVM,
            'value.policyChangeReason_Ext',
            policyChangeVM.value.policyChangeReason
        );

        return policyChangeVM.policyChangeReason_Ext.aspects
            .availableValues
            //  E1PAP1PC-15622
            //  don't show premiumadjustment option if user does not have permission
            .filter(
                (availableValue) => availableValue.code !== 'premiumadjustment'
                    || (canAddEditPremiumAdjustment)
                    || currentPolicyChangeReason === 'premiumadjustment' // if policy change is created already
            )
            //  IAP-5743
            //  don't show ratecorrectionsupression option if user does not have permission
            .filter(
                (availableValue) => availableValue.code !== 'ratecorrectionsupression'
                || canRateCorrectionSupression
                || currentPolicyChangeReason === 'ratecorrectionsupression' // if policy change is created already
            ).map(
                (item) => ({
                        code: item.code,
                        name: {
                            id: item.name,
                        }
                    })
            );
        // we want reason values to be updated based upon change in policychange source
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        canAddEditPremiumAdjustment,
        policyChangeVM.policyChangeReason_Ext.aspects.availableValues
    ]);

    /**
           * Helper memo for determining whether the policy period is back-dated.
           */
    useEffect(() => {
        const policyEffectiveDate = moment(policyChangeVM.baseData.effectiveDate.value);
        const effectiveDateBeforeToday = moment().isAfter(policyEffectiveDate, 'd');

        setPolicyIsBackDated(effectiveDateBeforeToday);
    }, [policyChangeVM.baseData.effectiveDate.value]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            readOnly: !!_get(policyChangeVM, 'jobID.value'),
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        openPolicyChangeInfoMessageDiv: {
            visible: _get(policyChangeVM, 'value.hasOpenPolicyChangeJob_Ext', false)
                && !_get(policyChangeVM, 'jobID.value')
        },
        changeEffectiveDate: {
            dateDTO: policyChangeVM.baseData.effectiveDate,
            updateDateDto: () => updateWizardData(policyChangeVM),
            onValidate,
            showErrors: isPageSubmitted
        },
        changeSource: {
            required: true,
            onValueChange: onChangeSource,
            availableValues: changeSourceValues,
            value: policyChangeVM.value.policyChangeSource_Ext === undefined
                ? policyChangeVM.value.policyChangeSource
                : policyChangeVM.value.policyChangeSource_Ext,
            readOnly: changeSourceValues.length === 1 || !!_get(policyChangeVM, 'jobID.value')
        },
        changeReason: {
            required: true,
            availableValues: reasonValues,
            onValueChange: onChangeReason,
            value: policyChangeVM.value.policyChangeReason_Ext === undefined
                ? policyChangeVM.value.policyChangeReason
                : policyChangeVM.value.policyChangeReason_Ext
        },
        changeDescription: {
            visible: (_get(policyChangeVM, 'policyChangeReason_Ext.value.code') === 'other')
        },
        lossOccurredBetweenEffectiveDateAndCreateDateInd: {
            visible: policyIsBackDated,
            value: policyChangeVM.value.hasLossOnOrAfterEffDate_Ext === undefined
                ? policyChangeVM.value.hasLossOnOrAfterEffDate
                : policyChangeVM.value.hasLossOnOrAfterEffDate_Ext,
            onValueChange: onLossAfterEffDateChange,
            readOnly: false
        },
        changeSourceViewOnly: {
            visible: false
        },
        changeReasonViewOnly: {
            visible: false
        },
        startPageLoader: {
            loaded: !isLoading,
            text: translator(commonMessages.loadingNextPage)
        },
        policyChangeDetailsDiv: {
            visible: !isLoading
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    return (
        <WizardPage
            isLoadingWholePage={isLoading}
            onNext={onNext}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            skipWhen={initialValidation}
            showPrevious={false}
            cancelLabel={translator(messages.policyChangesaveAndExitLabel)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

StartChangePage.propTypes = wizardProps;

export default StartChangePage;
