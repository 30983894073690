import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class RewriteService {
    static createPolicyRewriteTransactionWithLocalDate(data, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('rewrite'),
            'createPolicyRewriteTransactionWithLocalDate',
            data,
            additionalHeaders
        );
    }

    static updateDraftRewrite(data, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('rewrite'),
            'updateDraftRewrite', data, additionalHeaders);
    }

    /**
     * Updates a quoted rewrite.
     *
     * @param {Object} data the submission to be saved as draft
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} the promise from the backend call
     * @memberof RewriteService
     */
    static updateQuotedRewrite(data, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('rewrite'),
            'updateQuotedRewrite', data, additionalHeaders);
    }

    static retrieve(data, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('rewrite'),
            'retrieve', data, additionalHeaders);
    }

    static updateCoverages(data, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('rewrite'),
            'updateCoverages', data, additionalHeaders);
    }

    static saveAndQuote(data, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('rewrite'),
            'saveAndQuote', data, additionalHeaders);
    }

    static changePaymentPlan(data, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('rewrite'),
            'changePaymentPlan', [data], additionalHeaders);
    }

    static bind(data, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('rewrite'),
            'bind', data, additionalHeaders);
    }

    static canPurchase(data, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('rewrite'),
            'canPurchase', [data], additionalHeaders);
    }

    /**
     * Fetch Estimated Payment Review
     * @param {string[]} data
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */

    static getEstimatedPaymentPreview = (data, additionalHeaders) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('rewrite'),
            'getEstimatedPaymentPreview',
            data,
            additionalHeaders
        );
    };

    static getUWIssuesForRewrite(jobNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayrewrite'),
            'getUWIssuesForRewrite', [jobNumber], additionalHeaders);
    }

    /**
     * Handles OOS conflicts and quotes the perios
     * @param {Object} oosConflicts =
     * @param {Object} additionalHeaders additional headers to pass to the backend
     * @returns {Promise} a promise from the backend call with rewriteDataDTO
     */
    static handleOOSConflictsAndQuote(oosConflicts, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('rewrite'),
            'handleOOSConflictsAndQuote', oosConflicts, additionalHeaders);
    }

    /**
    * update draft rewrite api call with updateOptions
    * updateOptions are used to forcefully updating coverages
    * @param {Object} rewriteDataDTO current rewrite
    * @param {Object} updateOptions update options for updating coverages
    * @param {Object} additionalHeaders  additional headers to pass to the backend(e.g. auth)
    * @returns {Promise} the promise from backend call
    */
    static updateDraftRewriteWithOptions(rewriteDataDTO, updateOptions, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('rewrite'),
            'updateDraftRewriteWithOptions', [rewriteDataDTO, updateOptions], additionalHeaders);
    }

    /**
     * get payment plans for rewrite job
     * @param {String} rewriteJobId rewrite job id
     * @param {Object} additionalHeaders additional headers to pass to the backend(e.g. auth header)
     * @returns {Promise} the promise from backend call
     */
    static retrievePaymentPlans(rewriteJobId, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('rewrite'),
            'retrievePaymentPlans', [rewriteJobId], additionalHeaders);
    }
}
