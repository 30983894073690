import React, {
    useCallback
} from 'react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { E1PEHHO6PropertyDetailsComponent } from 'e1p-capability-policyjob-react';

function ViewPropertyPage(props) {
    
    const { wizardData: policyChangeVM } = props;

    const onNext = useCallback(() => policyChangeVM, [policyChangeVM]);

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <E1PEHHO6PropertyDetailsComponent
                onValidate={() => {}}
                transactionVM={policyChangeVM}
                updateWizardData={() => {}}
                isPageSubmitted={false}
                setShouldSkipAdditionalInfo={() => {}}
                isSavingCurrentPageChanges={false}
                isSavingQuote={false}
                heatingSystemType={() => {}}
                updateHeatingSystemType={() => {}}
                viewOnlyMode
            />
        </WizardPage>
    );
}

ViewPropertyPage.propTypes = wizardProps;
export default ViewPropertyPage;
