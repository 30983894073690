import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { SubmissionService, JobService } from 'gw-capability-gateway';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import appConfig from 'app-config';
import DocumentsPage from '../Policies/E1PDocuments/Documents';
import { PageHeaderComponent } from '../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import CommonRoutes from '../Common/CommonRouting';
import metadata from './QuoteDetails.metadata.json5';
import pageRouting from '../Common/QuoteDetails-config.json5';

const { capabilitiesConfig } = appConfig;

const DocumentPageService = {
    getDocuments: SubmissionService.getDocumentsForSubmission,
    generateUploadToken: GatewayDocumentService.generateUploadToken,
    uploadDocument: GatewayDocumentService.uploadDocument,
    removeDocument: GatewayDocumentService.removeDocument,
    downloadDocument: GatewayDocumentService.downloadDocument
};

function getRoutes() {
    const documentRoute = {
        path: '/documents',
        component: DocumentsPage
    };

    if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'document' })) {
        return [...pageRouting, documentRoute];
    }

    return pageRouting;
}

class QuoteDetails extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }),
        match: PropTypes.shape({
            params: PropTypes.shape({
                jobNumber: PropTypes.string
            }),
            url: PropTypes.string
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string
        }).isRequired,
        authUserData: PropTypes.shape({
        })
    };

    static defaultProps = {
        authHeader: undefined,
        authUserData: undefined
    }

    state = {
        currentView: 'summary',
        tilesCountSubmissionData: {},
        quotes: {}
    };

    componentDidMount() {
        this.getQuoteSummaryCount();
        this.getJobByJobNumber();
    }

    handleTilesOnClick = (id) => {
        this.setState({ currentView: id });
    };

    getQuoteSummaryCount = () => {
        const {
            authHeader,
            match: {
                params: { jobNumber }
            },
            location: { pathname }
        } = this.props;

        const routePath = pathname && (pathname.trim().split('/') || []);
        const selectedPageFromPath = routePath[routePath.length - 1];
        const activeTile = selectedPageFromPath || 'summary';

        SubmissionService.getSubmissionSummary(jobNumber, authHeader).then((response) => {
            this.setState({
                currentView: activeTile,
                tilesCountSubmissionData: response
            });
        });
    };

    updateQuoteSummary = (quotes) => {
        this.setState({ quotes });
    };

    canAccessActivities = () => {
        const { authUserData } = this.props;

        return authUserData?.permissions_Ext.includes('actview_ext');
    };

    getJobByJobNumber = async () => {
        const {
            authHeader,
            match: {
                params: { jobNumber }
            }
        } = this.props;

        const submission = await JobService.findJobByJobNumber(jobNumber, authHeader);

        this.updateQuoteSummary(submission);
    };

    render() {
        const { currentView, tilesCountSubmissionData, quotes } = this.state;
        const policyNumber = _.get(quotes, 'policy.policyNumber', undefined);

        if (_.isEmpty(quotes)) {
            return null;
        }

        const overrides = {
            [currentView]: {
                active: true
            },
            activities: {
                visible: this.canAccessActivities(),
                active: currentView === 'activities'
            },
        };
        const quoteVM = { quotes, tilesCountSubmissionData };
        const resolvers = {
            resolveCallbackMap: {
                handleTilesOnClick: this.handleTilesOnClick
            },
            resolveComponentMap: {
                pageheadercomponent: PageHeaderComponent
            }
        };
        const QuoteDetailspage = (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={quoteVM}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );

        const {
            match: {
                url,
                params: { jobNumber }
            }
        } = this.props;
        const quoteData = {
            jobNumber,
            policyNumber,
            loadQuoteSummary: quotes,
            updateJobSummary: this.updateQuoteSummary,
            getQuoteSummaryCount: this.getQuoteSummaryCount
        };

        return (
            <DependencyProvider value={{ DocumentPageService }}>
                {QuoteDetailspage}
                <CommonRoutes
                    steps={getRoutes()}
                    basePath={url}
                    setDocumentCount={this.getQuoteSummaryCount}
                    jobNumber={jobNumber}
                    policyNumber={policyNumber}
                    quoteDetailsData={quoteData}
                />
            </DependencyProvider>
        );
    }
}

export default withAuthenticationContext(QuoteDetails);
