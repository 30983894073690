import React, {
    useCallback, useContext, useState, useEffect
} from 'react';
import { get as _get, set as _set } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useViewPolicyUtil } from 'e1p-capability-hooks';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { SubmissionVMSelectors, RandomNumberUtil } from 'e1p-portals-util-js';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import moment from 'moment';
import messages from '../InsuredDetailsPage.messages';
import metadata from '../InsuredDetailsPage.metadata.json5';

const LOB = 'homeowners_EH';

function ViewInsuredDetailsPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [lapseInCoverage, updateLapseInCoverage] = useState(undefined);
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const [policyIsBackDated, setPolicyIsBackDated] = useState(false);
    const {
        wizardData: policyChangeVM, updateWizardData, authUserData
    } = props;
    const {
        onValidate
    } = useValidation('InsuredDetailsPage');
    const { authHeader } = useAuthentication();
    const policyForm = _get(policyChangeVM, 'lobData.homeowners_EH.policyFormType.value');
    const policyType = _get(policyChangeVM, 'value.policyType_Ext');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const submissionVMSelector = new SubmissionVMSelectors(LOB);

    const sessionIdPrefixEdit = 'EH - VW - ';
    const sessionHeader = sessionIdPrefixEdit + RandomNumberUtil.randomFixedInteger(10);
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const { getLocationAndProducerCodes, getProducerDetails } = useViewPolicyUtil();

    useEffect(() => {
        const producerCodeValue = _get(policyChangeVM, 'baseData.producerCode_Ext.value');

        if (!producerCodeValue) {
            _set(
                policyChangeVM,
                'baseData.producerCode_Ext',
                _get(policyChangeVM, 'baseData.value.producerCode')
            );
        }

        if (
            policyChangeVM.lobData.homeowners_EH.priorPolicies.value !== undefined
            && policyChangeVM.lobData.homeowners_EH.priorPolicies.length > 0
        ) {
            updateLapseInCoverage(
                !policyChangeVM.lobData.homeowners_EH.priorPolicies.value[0].continuousCoverageInd
            );
        }
    }, [locationAndProducerCodes, policyChangeVM, submissionVMSelector]);

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location, authHeader, setLocationAndProducerCodes);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(policyChangeVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(policyChangeVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    const setBackdateInformation = () => {
        const policyEffectiveDate = moment(policyChangeVM.baseData.effectiveDate.value);
        const effectiveDateBeforeToday = moment().isAfter(policyEffectiveDate, 'd');

        setPolicyIsBackDated(effectiveDateBeforeToday);
    };

    useEffect(() => {
        getAndSetLocationCodes(_get(policyChangeVM, 'policyAddress.state.value.code'));
        setBackdateInformation();
        authHeader['afe-session-id'] = sessionHeader;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (policyChangeVM.baseData.externalID_Ext?.value) {
            getProducerDetails(
                policyChangeVM.baseData.externalID_Ext.value, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
                setAgencyName(details.agencyName);
                setBookTransferIndicator(details.bookTransferIndicator);
                setServiceCenterIndicator(details.serviceCenterIndicatorValue);
            }).catch((error) => {
                /**
                * E1PAP1PC-15099 :
                * This is rare scenario; will never happen in production
                * When we bound policy using dummy producer code
                * and when other user tries to view this policy we get exception from service
                */
                if (policyChangeVM.baseData.value.exceptions_Ext) {
                    policyChangeVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        policyChangeVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(policyChangeVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyChangeVM.baseData.externalID_Ext.value]);


    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(() => policyChangeVM, [policyChangeVM]);

    const generateOverrides = useCallback(() => {
        const overrides = {};
        
        overrides.secondaryNamedInsured = {
            viewOnlyMode: true
        };

        overrides.primaryNamedInsured = {
            viewOnlyMode: true,
            isPNI: true
        };

        return overrides;
    }, []);



    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        changeEffectiveDate: {
            dateDTO: policyChangeVM.baseData.effectiveDate,
            updateDateDto: () => { },
            showErrors: true,
            onValidate
        },
        coverageRateAsOfDate: {
            dateDTO: policyChangeVM.baseData.periodRateAsOfDate,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        insuranceHistoryMainDiv: {
            visible: policyType !== 'HO4'
        },
        policyInformationInfoContainer: {
            columns: authUserData.permissions_Ext.includes('editrateasofdate')
                ? ['0.25fr', '0.25fr', '0.25fr', '0.25fr'] : ['0.25fr', '0.25fr', '0.25fr']
        },
        insuredDetailsPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.loadingNextPageMessage)
        },
        insuredDetailsPageContainer: {
            visible: true
        },
        mailingAndBillingAddressComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            viewOnlyMode: true
        },
        addSni: {
            visible: false
        },
        addNewPni: {
            visible: false
        },
        removeSni: {
            visible: false
        },
        sniContainer: {
            visible: !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        sniInformation: {
            visible: !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        riskAddressComponent: {
            submissionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            viewModelService,
            viewOnlyMode: true
        },
        ehPriorAddressComponent: {
            submissionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            viewModelService,
            viewOnlyMode: true,
            visible: policyType === 'HO3' || policyType === 'HF9',
            LOB
        },
        coverageLapseAndExpirationDate: {
            visible: policyType !== 'HO4'
        },
        coverageLapse: {
            value: lapseInCoverage
        },
        policyForm: {
            value: policyForm
        },
        usageType: {
            visible: policyType !== 'HO4'
        },
        lastPolicyExpirationDate: {
            dateDTO: policyChangeVM.lobData.homeowners_EH
                .priorPolicies?.children[0]?.policyExpirationDate
        },
        swapPniSni: {
            visible: false
        },
        ExternalProducerDetailsComponent: {
            viewOnlyMode: true,
            defaultValue: policyChangeVM.baseData.externalID_Ext?.value
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        changePniComponent: {
            visible: false
        },
        changeSniComponent: {
            visible: false
        },
        insuredResidenceAddressWarningMessageDiv: {
            visible: false
        },
        hO4InsuredResidenceAddressWarningMessageDiv: {
            visible: false
        },
        lossQuestionEmptyContainer: {
            visible: !policyIsBackDated,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 3 : 2
        },
        lossQuestionContainer: {
            visible: policyIsBackDated,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 3 : 2
        },
        lossOccurredBetweenEffectiveDateAndCreateDateInd: {
            defaultValue: policyChangeVM.value.hasLossOnOrAfterEffDate
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: () => { },
            onValidate,
            onRemoveSni: () => { },
            onAddNewPni: () => { },
            onSwapPniSni: () => { }
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

ViewInsuredDetailsPage.propTypes = wizardProps;

export default withAuthenticationContext(ViewInsuredDetailsPage);
