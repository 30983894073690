import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    set as _set,
    get as _get,
    isEmpty as _isEmpty
} from 'lodash';
import { EndorsementService } from 'e1p-capability-policychange';
import { useTranslator } from '@jutro/locale';
import { useLandingPageUtil, useTPIUtil } from 'e1p-capability-hooks';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PolicyChangeUtil from 'e1p-portals-util-js/PolicyChangeUtil';
import metadata from './ThirdPartyInterestPage.metadata.json5';

const LOB = 'homeowners_EH';

function ThirdPartyInterestPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        updateWizardSnapshot
    } = props;
    const { authHeader } = useAuthentication();
    const {
        isComponentValid,
        onValidate,
        disregardFieldValidation
    } = useValidation('ThirdPartyInterestPage');
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isSavingTPI, setIsSavingTPI] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const { checkAndShowDialogForPresenceOfTrust } = useTPIUtil();
    const [isAddingTPI, setIsAddingTPI] = useState(false);
    const viewOnlyMode = false;

    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    /**
     * Helper callback for saving TPIs if changes were made while visiting this screen.
     */
    const saveTPIChangesIfNecessary = useCallback(async (isQuote) => {
        if (isQuote) {
            // Save and quote again since something is changed on the TPI
            policyChangeVM.value = await EndorsementService.saveEndorsement(
                [policyChangeVM.value],
                authHeader
            );
        } else {
            // Do rateless quote to bring back to quoted status
            _set(policyChangeVM, 'baseData.ratingStyle_Ext.value', 'RatelessQuote_Ext');
            // This call will save and quote
            policyChangeVM.value = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );
        }

        PolicyChangeUtil.updatePolicyChangeVMCustomFields(policyChangeVM);

        return policyChangeVM.value;
    }, [policyChangeVM, authHeader]);

    /**
     * Helper callback for handling wizard page navigation after the "Next" button is pressed.
     */
    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        const popResult = await checkAndShowDialogForPresenceOfTrust(
            policyChangeVM,
            updateWizardData);

        if (!popResult) {
            // user clicked cancel on presence of trust confirmation dialog
            return false;
        }

        setIsSavingEndorsement(true);
        policyChangeVM.value = await saveTPIChangesIfNecessary(false);
        updateWizardData(policyChangeVM);
        setIsSavingEndorsement(false);

        return policyChangeVM;
    }, [
        checkAndShowDialogForPresenceOfTrust, isComponentValid,
        policyChangeVM, saveTPIChangesIfNecessary, updateWizardData
    ]);

    const onSave = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingCurrentPageChanges(true);

            try {
                policyChangeVM.value = await saveTPIChangesIfNecessary(false);
                updateWizardData(policyChangeVM);

                const fieldIssues = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [isComponentValid, policyChangeVM, saveTPIChangesIfNecessary, updateWizardData, updateWizardSnapshot]
    );

    const getLoadingMessage = useCallback(() => {
        let loadingMessage = translator(e1pCommonMessages.loadingNextPage);

        if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isSavingTPI) {
            loadingMessage = translator(e1pCommonMessages.savingTPI);
        }

        return loadingMessage;
    }, [isSavingCurrentPageChanges, isSavingTPI, translator]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        thirdPartyInterestPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping
                && !isSavingTPI && !isSavingCurrentPageChanges,
            text: getLoadingMessage()
        },
        thirdPartyInterestPageContainer: {
            visible: !isSavingEndorsement && !isSkipping
                && !isSavingTPI && !isSavingCurrentPageChanges
        },
        e1ptpiDisplayTableContainer: {
            transactionVM: policyChangeVM,
            viewModelService,
            authHeader,
            updateWizardData,
            setIsSavingTPI,
            showErrors: isPageSubmitted,
            onValidate,
            disregardFieldValidationParentPage: disregardFieldValidation,
            setIsAddingTPI
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            const popResult = await checkAndShowDialogForPresenceOfTrust(
                policyChangeVM,
                updateWizardData);

            if (!popResult) {
                // user clicked cancel on presence of trust confirmation dialog
                return false;
            }

            setIsSavingEndorsement(true);
            policyChangeVM.value = await saveTPIChangesIfNecessary(true);
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            // Do not proceed if "saveTPIChangesIfNecessary" raised validation issues
            if (
                policyChangeVM.errorsAndWarnings_Ext.value !== undefined
                && policyChangeVM.errorsAndWarnings_Ext.validationIssues.value !== undefined
                && policyChangeVM.errorsAndWarnings_Ext.validationIssues.issues.value !== undefined
                && policyChangeVM.errorsAndWarnings_Ext.validationIssues.issues.value.length > 0
            ) {
                setIsSavingEndorsement(false);

                return false;
            }

            let newLandingPageIndex = -1;
            const validationErrors = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [
            checkAndShowDialogForPresenceOfTrust, getLandingPageIndexForQuotedJob,
            jumpTo, policyChangeVM, saveTPIChangesIfNecessary, steps, updateWizardData,
            updateWizardSnapshot, isComponentValid
        ]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            onSave={onSave}
            onCustom={onCustom}
            showCustom={!isAddingTPI}
            showCancel={!isAddingTPI}
            showNext={!isAddingTPI}
            showOnSave={!isAddingTPI}
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                overrideProps={overrideProps}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

ThirdPartyInterestPage.propTypes = wizardProps;
export default ThirdPartyInterestPage;
