import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class EndorsementService
 */
export default class RenewalService {
    static createRenewalTransaction(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'createPolicyRenewalTransaction',
            [policyNumber],
            additionalHeaders
        );
    }

    static saveRenewal(renewalVM, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'updateDraftRenewal',
            renewalVM,
            additionalHeaders
        );
    }

    static saveAndQuoteRenewal(renewalVM, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'saveAndQuote',
            renewalVM,
            additionalHeaders
        );
    }

    static retrieveRenewal(jobNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'retrieve',
            jobNumber,
            additionalHeaders
        );
    }

    static updateCoverages(jobNumber, coverages, sessionUUID, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'updateCoverages',
            [jobNumber, coverages, sessionUUID],
            additionalHeaders
        );
    }

    static startEditRenewal(jobNumber, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'startEditRenewal',
            [jobNumber],
            additionalHeaders
        );
    }

    static markRenewalNotTaken(renewalNumber, flowStepIds, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'markRenewalNotTaken',
            [renewalNumber, flowStepIds],
            additionalHeaders
        );
    }

    static markRenewalNonRenew(renewalNumber, flowStepIds, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'markRenewalNonRenew',
            [renewalNumber, flowStepIds],
            additionalHeaders
        );
    }

    static markRenewalRenew(renewalNumber, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'markRenewalRenew',
            [renewalNumber],
            additionalHeaders
        );
    }

    static applyRenewalChanges(jobNumber, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayRenewals'),
            'applyChangesToRenewal',
            jobNumber, additionalHeaders);
    }

    static getEstimatedPaymentPreview = (data, additionalHeaders) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'getEstimatedPaymentPreview',
            data,
            additionalHeaders
        );
    }

    /**
     * Creates renewal direction non renewal interactive letter
     *
     * @param {Object} data renewalDirection dto
     * @returns {Promise} the promise from the backend call with intractive letter URL
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof RenewalService
     */
    static createNonRenewalInteractiveLetter(data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'createNonRenewalInteractiveLetter',
            data,
            additionalHeaders
        );
    }


    /**
    * update draft renewal api call with updateOptions
    * updateOptions are used to forcefully updating coverages
    * @param {Object} renewalDataDTO current rewrite
    * @param {Object} updateOptions update options for updating coverages
    * @param {Object} additionalHeaders  additional headers to pass to the backend(e.g. auth)
    * @returns {Promise} the promise from backend call
    */
    static updateDraftRenewalWithOptions(renewalDataDTO, updateOptions, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayRenewals'),
            'updateDraftRenewalWithOptions',
            [renewalDataDTO, updateOptions],
            additionalHeaders
        );
    }
}
