import React, { useContext, useEffect, useState, useCallback } from 'react';

import { E1PLoader } from 'e1p-capability-policyjob-react';
import PropTypes from 'prop-types';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { VehicleHistoryService } from 'e1p-capability-gateway';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './VehicleHistory.metadata.json5';

function VehicleHistory(props) {
    const { quoteID, isSkipping } = props;
    const { onValidate } = useValidation('VehicleHistoryComponent');
    const { authHeader } = useAuthentication();
    const [vehicleHistoryVM, setVehicleHistoryVM] = useState(undefined);
    const viewModelService = useContext(ViewModelServiceContext);
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [failedCall, setFailedCall] = useState(false);

    useEffect(() => {
        let isMounted = true;

        if (!isSkipping) {
            VehicleHistoryService.getVehicleHistoryRecords(quoteID, authHeader)
                .then((response) => {
                    if(isMounted){
                        if (response !== undefined) {
                            const VehicleHistoryVM = viewModelService.create(
                                response,
                                'pc',
                                'amfam.edge.capabilities.policyjob.common.vehiclehistory.dto.TranslatedVehicleHistoryDTO'
                            );

                            setVehicleHistoryVM(VehicleHistoryVM);
                            setPageInitialized(true);
                        }
                    }
                })
                .catch(() => {
                    setPageInitialized(true);
                    setFailedCall(true);
                });
        }

        return ()=>{
            isMounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            readOnly: true,
            autoComplete: false,
        },
        FailedCallDiv: {
            visible: failedCall,
        },
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                vehicleHistoryVM,
                id,
                path,
                overrideProps
            ),
        [vehicleHistoryVM, overrideProps]
    );

    if (!isPageInitialized) {
        return <E1PLoader loaded={isPageInitialized} />;
    }

    return (
        <MetadataForm
            uiProps={metadata.pageContent}
            data={vehicleHistoryVM}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            resolveValue={readValue}
        />
    );
}

VehicleHistory.defaultProps = {
    isSkipping: false,
};

VehicleHistory.propTypes = {
    quoteID: PropTypes.string.isRequired,
    isSkipping: PropTypes.bool,
};

export default VehicleHistory;
