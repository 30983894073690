/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { TooltipIcon } from '@jutro/components';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import {commonMessages} from 'e1p-platform-translations';
import styles from './QuoteProposalComponent.module.scss';

import { Link } from '@jutro/router';

function QuoteProposalComponent(props) {
    const {
        onClick,
        disabled
    } = props;
    const translator = useTranslator();

    return (
        <div hidden={disabled}>
            <Link
                onClick={onClick}
                icon="insert-drive-file"
                className={styles.quoteProposal}
            >
                {translator(commonMessages.viewQuoteProposal)}
            </Link>

            <TooltipIcon
                className={styles.toolTipIcon}
                placement="top"
                id="tooltip-icon"
                text={translator(commonMessages.quoteProposalHelpText)}
            />
        </div>
    );
}

QuoteProposalComponent.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

QuoteProposalComponent.defaultProps = {
    onClick: () => {},
    disabled: false,
};

export default QuoteProposalComponent;
