import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import {
    get, set, isEqual, unset, findIndex, isEmpty
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useGaragingAddressUtil } from 'e1p-capability-hooks';
import { VehicleUtil } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages, eaValidationAndInfoMessages } from 'e1p-platform-translations';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { RenewalService } from 'e1p-capability-renewal';
import appConfig from 'app-config';
import metadata from './VehiclesPage.metadata.json5';
import messages from './VehiclesPage.messages';
import wizardMessages from '../../EARenewalWizard.messages';

function VehiclesPage(props) {
    const modalApi = useModal();
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [pageInitialized, setIsPageInitialized] = useState(false);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [vehicleLookupError, setVehicleLookupError] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);
    const changeSummaryIndex = findIndex(steps, ({ path }) => path === '/change-summary');
    const { generateGaragingAddressDisplayName } = useGaragingAddressUtil();
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [availableMakes, setAvailableMakes] = useState({});
    const [availableModels, setAvailableModels] = useState({});
    const [availableSeries, setAvailableSeries] = useState({});

    const { authHeader } = useAuthentication();
    // array of vehicle objects (not vmnodes)
    const vehiclesObjectArray = get(renewalVM, 'lobData.personalAuto_EA.coverables.vehicles.value', []);
    const atleastOneCarBeingAdded = (() => {
        if (vehiclesObjectArray.length > 1) { return true; }

        const make = get(vehiclesObjectArray[0], 'make');
        const model = get(vehiclesObjectArray[0], 'model');
        const year = get(vehiclesObjectArray[0], 'year');
        const vin = get(vehiclesObjectArray[0], 'vin');

        if (vin && year) { return true; }

        if (make && model && year) { return true; }

        return false;
    })();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('VehiclePage');

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [renewalVM, isComponentValid, isPageSubmitted]);

    renewalVM.flowStepIDs_Ext.value = ['vehicle'];
    renewalVM.entryCompletionStepIDs_Ext.value = ['vehicle'];

    const defaultGaragingAddress = get(renewalVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.primaryAddress.value');

    /**
     * Helper callback for creating a new vehicle "VMNode" object and adding it to the list of
     * vehicles on the policy.
     */
    const createAndAddVehicleVM = useCallback(() => {
        const vehicleObj = {
            garagingLocation: defaultGaragingAddress
        };
        const { _xCenter, _dtoName } = renewalVM.lobData.personalAuto_EA.coverables.vehicles;
        const vehicleVM = viewModelService.create(vehicleObj, _xCenter, _dtoName);

        if (!vehicleVM.safetyFeatures.value) {
            vehicleVM.safetyFeatures.value = [];
        }

        vehicleVM.callShowAll = false;
        renewalVM.lobData.personalAuto_EA.coverables.vehicles.pushElement(vehicleVM);
        renewalVM.lobData.personalAuto_EA.coverables.vehicles.children.forEach((vehicle) => {
            set(vehicle, 'callShowAll', false);
        });
        setCheckScrolling(true);
        setIndexStale(true);

        return updateWizardData(renewalVM);
    }, [renewalVM, updateWizardData, viewModelService, defaultGaragingAddress]);

    const onVehicleLookupError = useCallback(
        (value) => {
            setVehicleLookupError(value);
        },
        []
    );

    // this grabs the latest element on the page --
    // this is to make sure this element has been loaded
    const latestVehicleElement = document.getElementById(`eavehicleContainer2${renewalVM.lobData.personalAuto_EA.coverables.vehicles.children.length - 1}`);

    useEffect(() => {
        // indexStale set in the add vehicle function
        // once latest element is loaded and a new vehicle is added
        // we check if the button should be visible
        if (latestVehicleElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestVehicleElement]);

    const policyState = get(renewalVM, 'baseData.policyAddress.state.value.code');

    /**
     * If state requires drivers assignment and this page has been visited, we will
     *   take the user to the drivers assignment page to check for updates
     */
    const needsDriverAssignmentUpdate = useCallback(
        async (calledFromSave = false) => {
            const enabledStates = appConfig.driverAssignmentStates;
            const driverAssignmentStepIndex = steps.findIndex((step) => step.path === '/assignment');
            const shouldSkipDriverAssignmentPage = VehicleUtil.shouldSkipDriverAssignmentPage(renewalVM);

            /**
             * If driver assignment is available for given state and 
             * shouldSkipDriverAssignmentPage indicator(given job has one driver and one vehicle and vehicle is not garaged out fo state more than 6 months) is true 
             * then perform driver assignment for given vehicle
             */
            if (driverAssignmentStepIndex > 0 && shouldSkipDriverAssignmentPage) {
                VehicleUtil.setDriverAssignmentWhenOneDriverOneVehiclePresent(renewalVM, updateWizardData);
            }

            if (enabledStates.includes(policyState)) {
                renewalVM.value = await RenewalService.saveRenewal(
                    [renewalVM.value],
                    authHeader
                );
                updateWizardData(renewalVM);

                const validationErrs = get(renewalVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const hasNoConflicts = isEmpty(get(renewalVM, 'value.conflicts', []));
                const hasNoExceptions = isEmpty(get(renewalVM, 'baseData.exceptions_Ext.value', []));

                // Need to stay on the page if field issues
                if (validationErrs.length === 0 && hasNoConflicts && hasNoExceptions) {
                    updateWizardSnapshot(renewalVM)

                    // Skip driver assignment page
                    if (driverAssignmentStepIndex > 0 && !calledFromSave && shouldSkipDriverAssignmentPage) {
                        const riskAnalysisPageIndex = findIndex(steps, ({ path }) => path === '/risk-analysis');

                        jumpTo(riskAnalysisPageIndex, true, renewalVM.value);

                        return true;
                    }

                    if (calledFromSave && shouldSkipDriverAssignmentPage) {
                        // dont jump to driver assignment as we have defaulted driver assignment
                        // stay on the same page or land to review summary in case onCustom is called
                        return false
                    }

                    jumpTo(driverAssignmentStepIndex, true);

                    return true;
                }

                // needs to but has errors so don't jump
                return true;
            }

 return false;
        },
        [steps, renewalVM, policyState, updateWizardData, authHeader, updateWizardSnapshot, jumpTo],
    );

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(async (_, calledFromSave = false) => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setIsSavingRenewal(true);

        const shouldEndFunctionExecution = await needsDriverAssignmentUpdate(calledFromSave);

        if (shouldEndFunctionExecution) {
            setIsSavingRenewal(false);

            return false;
        }

        try {
            unset(renewalVM.value, 'bindData');
            // eslint - disable - next - line max - len
            renewalVM.value = await RenewalService.saveAndQuoteRenewal(
                [renewalVM.value],
                authHeader
            );
            // Below we are setting errors and warnings path to be congruent to the
            // paths in the wizard, so the right warning will pop up thus preventing
            // the user from moving forward if there is a validation issue with the
            //   fields or information provided
            set(renewalVM, 'errorsAndWarnings', renewalVM.errorsAndWarnings_Ext);
            set(renewalVM, 'errorsAndWarnings_Ext.value', renewalVM.value.errorsAndWarnings);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);

            return renewalVM;
        } catch {
            setIsSavingRenewal(false);
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: commonMessages.genericError,
                message: commonMessages.genericErrorMessage,
                messageProps: {
                    confirmButtonText: commonMessages.cancelModel
                },
                showCancelBtn: false
            });

            return false;
        }
    }, [authHeader, isComponentValid, renewalVM, updateWizardData, needsDriverAssignmentUpdate, modalApi]);

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    /**
                    * E1PAP1PC-14986 :
                    * wizardData and wizardSnapshot not being equal due to
                    * some defaulting on each page so doing this temp fix
                    */
                    renewalVM.lobData.personalAuto_EA.coverables
                        .vehicles.children.forEach((vehicle) => {
                            generateGaragingAddressDisplayName(vehicle.garagingLocation);
                        });
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [generateGaragingAddressDisplayName, onNext, renewalVM, updateWizardSnapshot]
    );

    /**
     * Helper effect for handling the component initialization logic.
     */
    useEffect(() => {
        if (!pageInitialized) {
            const vehiclesPath = 'lobData.personalAuto_EA.coverables.vehicles';
            const vehicles = get(renewalVM, `${vehiclesPath}.value`);

            const newVehicles = vehicles.map((vehicle) => {
                const newVehicle = {
                    ...vehicle,
                    vin: vehicle.vin,
                    costNew: vehicle.costNew
                };

                return newVehicle;
            });

            // Update the submission VM with new list of vehicles
            if (!isEqual(newVehicles, vehicles)) {
                set(renewalVM, `${vehiclesPath}.value`, newVehicles);
                updateWizardData(renewalVM);
            }

            setIsPageInitialized(true);
        }
        // eslint - disable - next - line max - len
    }, [pageInitialized, renewalVM, updateWizardData]);

    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const shouldEndFunctionExecution = await needsDriverAssignmentUpdate(true);

            if (shouldEndFunctionExecution) {
                setIsSavingRenewal(false);

                return false;
            }

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setIsSavingRenewal(false);

            return false;
        },
        [
            authHeader, changeSummaryIndex, isComponentValid, jumpTo,
            renewalVM, updateWizardData, updateWizardSnapshot, needsDriverAssignmentUpdate
        ]
    );

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        vehiclesPageLoadingIndicator: {
            loaded: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        vehicleLookupErrorNotificationDiv: {
            visible: vehicleLookupError
        },
        vehiclesPageContainer: {
            visible: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges
        },
        oneCarMinimumNotificationDiv: {
            visible: !atleastOneCarBeingAdded && isPageSubmitted
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleGridContainer')
        },
        vehicleInfoChangedMessageDiv: {
            message: translator(eaValidationAndInfoMessages.vehicleHasUpdatedMessage),
            visible: (() => {
                const vehicles = get(renewalVM, 'value.lobData.personalAuto_EA.coverables.vehicles', []);
                const doesVehicleInfoChanged = vehicles.some((vehicle) => !!vehicle.vehicleInfoChanged);

                return doesVehicleInfoChanged;
            })()
        },
        EAVehicleGrid: {
            key: get(renewalVM, 'lobData.personalAuto_EA.coverables.vehicles', []).length,
            vehicles: get(renewalVM, 'lobData.personalAuto_EA.coverables.vehicles', []),
            path: 'lobData.personalAuto_EA.coverables.vehicles.children',
            onValidate,
            viewModelService,
            showErrors: isPageSubmitted,
            policyState: get(renewalVM, 'baseData.policyAddress.state.value.code'),
            vehiclePageDisregardFieldValidation: disregardFieldValidation,
            authHeader,
            onValidationChange: onValidate,
            transactionVM: renewalVM,
            updateWizardData,
            setCheckScrolling,
            addVehicleVM: createAndAddVehicleVM,
            isSkipping,
            availableMakes,
            setAvailableMakes,
            availableModels,
            setAvailableModels,
            availableSeries,
            setAvailableSeries
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [renewalVM, overrideProps]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onAddVehicleClick: createAndAddVehicleVM,
            onValidate,
            onPrefillData: undefined,
            onVehicleLookupError
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && atleastOneCarBeingAdded
                && !isComponentValid
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

VehiclesPage.propTypes = wizardProps;
export default VehiclesPage;
