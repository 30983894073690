import React, {
    useCallback, useEffect, useState, useContext
} from 'react';
import {
    cloneDeep as _cloneDeep,
    get as _get,
    set as _set,
    find as _find,
    unset as _unset
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import './MailingAndBillingAddressComponent.messages';
import metadata from './MailingAndBillingAddressComponent.metadata.json5';

/**
 * Helper function for returning policy address. On PolicyDetailsPage's,
 * accountHolder may not be populated if creating a submission without an account.
 *
 * @param {VMNode} transactionVM A VMNode containing transaction information (e.g. submission, policy change, etc.).
 * @param {string} lob containing the line of business.
 * @returns {VMNode} A VMNode containing the policy address.
 */
function getResidenceAddress(transactionVM) {
    const residenceAddress = _cloneDeep(
        _get(transactionVM, 'baseData.policyAddress.value')
    );

    return residenceAddress;
}

/**
 * @param {*} props wizard props
 * @returns {*} none
 */
function MailingAndBillingAddressComponent(props) {
    const {
        id,
        transactionVM,
        updateWizardData,
        onValidate,
        viewOnlyMode,
        lob,
        isPolicyView,
        tooltip,
        showErrors,
        updateIsMailingComponentValid,
        updateIsBillingComponentValid,
        wizardSnapshot
    } = props;
    const { isComponentValid, onValidate: setComponentValidation, disregardFieldValidation } = useValidation(id);
    const [isComponentInitialized, setIsComponentInitialized] = useState(false);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    /**
     * Helper callback for handling an address change on the submission.
     */
    const handleAddressChange = useCallback(
        (value, path) => {
            _set(transactionVM, path, value);
            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData]
    );

    const handleMailingAddressChange = useCallback(
        (value, path) => {
            handleAddressChange(value, path);

            const isBillingAddressSame = _get(transactionVM, 'baseData.isBillingAddressSame_Ext.value');

            if (isBillingAddressSame) {
                const billingPath = path.replace('policyMailingAddress_Ext', 'policyBillingAddress_Ext');

                if (path === 'baseData.policyMailingAddress_Ext.value') {
                    const billingAddress = _get(transactionVM, 'baseData.policyBillingAddress_Ext.value');
                    const billingValue = _cloneDeep(value);

                    if (billingAddress.publicID) {
                        billingValue.publicID = billingAddress.publicID;
                        billingValue.addressType = 'billing';
                    }

                    handleAddressChange(billingValue, billingPath);
                } else {
                    handleAddressChange(value, billingPath);
                }
            }
        }, [handleAddressChange, transactionVM]
    );

    const getAvailableValuesForCountry = useCallback(() => {
        let values = [];
        const typelistFilters = viewModelService.productMetadata.get('pc').types
            .getTypelist('Country').filters;

        if (typelistFilters) {
            values = _find(typelistFilters, { name: 'NonUSTerritories_Ext' }).codes;
        }

        if (!values || values.length === 0) { return undefined; }

        const availableValues = values.map((code) => ({
                code: code.code,
                name: translator({ id: code.name })
            }));

        return availableValues;
    }, [viewModelService, translator]);

    /**
     * Helper callback for setting the policy mailing address to its default value.
     */
    const setMailingAddressToDefault = useCallback(() => {
        const residenceAddress = getResidenceAddress(transactionVM, lob);

        /**
        * E1PAP1PC-14867 :
        * wizardData and wizardSnapshot not being equal due to
        * some defaulting on each page so doing this temp fix
        */
        if (wizardSnapshot) {
            _set(wizardSnapshot, 'value.baseData.policyMailingAddress_Ext.publicID', residenceAddress.publicID);
            _set(wizardSnapshot, 'value.baseData.policyMailingAddress_Ext.addressType', residenceAddress.addressType);

            if (!residenceAddress.addressType) {
                _unset(wizardSnapshot, 'value.baseData.policyMailingAddress_Ext.addressType');
            }

            _set(wizardSnapshot, 'value.baseData.policyMailingAddress_Ext.county', residenceAddress.county);

            if (!residenceAddress.county) {
                _unset(wizardSnapshot, 'value.baseData.policyMailingAddress_Ext.county');
            }
        }

        handleAddressChange(residenceAddress, 'baseData.policyMailingAddress_Ext');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for setting the policy billing address to its default value.
     */
    const setBillingAddressToDefault = useCallback(() => {
        let address = {};
        const isMailingAddressSame = _get(transactionVM, 'baseData.isMailingAddressSame_Ext.value');

        if (isMailingAddressSame) {
            address = getResidenceAddress(transactionVM, lob);
        } else {
            address = _cloneDeep(_get(transactionVM, 'baseData.policyMailingAddress_Ext.value'));
        }

        /**
        * E1PAP1PC-14867 :
        * wizardData and wizardSnapshot not being equal due to
        * some defaulting on each page so doing this temp fix
        */
        if (wizardSnapshot) {
            _set(wizardSnapshot, 'value.baseData.policyBillingAddress_Ext.publicID', address.publicID);
            _set(wizardSnapshot, 'value.baseData.policyBillingAddress_Ext.addressType', address.addressType);

            if (!address.addressType) {
                _unset(wizardSnapshot, 'value.baseData.policyBillingAddress_Ext.addressType');
            }

            _set(wizardSnapshot, 'value.baseData.policyBillingAddress_Ext.county', address.county);

            if (!address.county) {
                _unset(wizardSnapshot, 'value.baseData.policyBillingAddress_Ext.county');
            }

        }

        handleAddressChange(address, 'baseData.policyBillingAddress_Ext');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper effect for initializing the policy mailing/billing addresses and indicators.
     */
    useEffect(() => {
        const isMailingAddressSame = _get(transactionVM, 'baseData.isMailingAddressSame_Ext.value');
        const isBillingAddressSame = _get(transactionVM, 'baseData.isBillingAddressSame_Ext.value');

        if (isMailingAddressSame === undefined) {
            _set(transactionVM, 'baseData.isMailingAddressSame_Ext.value', true);
        }

        if (isBillingAddressSame === undefined) {
            _set(transactionVM, 'baseData.isBillingAddressSame_Ext.value', true);
        }

        if (isMailingAddressSame === undefined || isMailingAddressSame === true) {
            setMailingAddressToDefault();
        }

        if (isBillingAddressSame === undefined || isBillingAddressSame === true) {
            setBillingAddressToDefault();
        }

        updateWizardData(transactionVM);
        setIsComponentInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for handling changes to the mailing address toggle value.
     */
    const mailingAddressToggleHandler = useCallback(
        (value) => {
            _set(transactionVM, 'baseData.isMailingAddressSame_Ext.value', value);
            setMailingAddressToDefault();
            /**
             * fix for: E1PAP1PC-15422
             * onChange of toggle we are always setting default value for mailing address,
             * so component will always be valid, hence disregarding the field validation
             * for the component, only when toggle is changed.
             * if particular field is changed manually, then validation will work as expected.
             * */
            disregardFieldValidation('mailingAddressComponent');
        },
        [transactionVM, setMailingAddressToDefault, disregardFieldValidation]
    );

    /**
     * Helper callback for handling changes to the billing address toggle value.
     */
    const billingAddressToggleHandler = useCallback(
        (value) => {
            _set(transactionVM, 'baseData.isBillingAddressSame_Ext.value', value);
            setBillingAddressToDefault();
            /**
             * fix for: E1PAP1PC-15422
             * onChange of toggle we are always setting default value for billing address,
             * so component will always be valid, hence disregarding the field validation
             * for the component, only when toggle is changed.
             * if particular field is changed manually, then validation will work as expected.
             * */
            disregardFieldValidation('billingAddressComponent');
        },
        [transactionVM, setBillingAddressToDefault, disregardFieldValidation]
    );

    /**
     * Helper effect for validating data fields on this component.
     */
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            mailingAddressToggleHandler,
            billingAddressToggleHandler
        }
    };
    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            readOnly: viewOnlyMode,
            showRequired: true,
            autoComplete: false
        },
        isMailingSameAsResidence: {
            path: isPolicyView ? 'isMailingAddressSame_Ext' : 'baseData.isMailingAddressSame_Ext'
        },
        isBillingSameAsMailing: {
            path: isPolicyView ? 'isBillingAddressSame_Ext' : 'baseData.isBillingAddressSame_Ext',
            tooltip
        },
        mailingSameAsResidenceInnerContainer: {
            visible: isPolicyView ? !_get(transactionVM, 'isMailingAddressSame_Ext.value') : !_get(transactionVM, 'baseData.isMailingAddressSame_Ext.value')
        },
        mailingAddressComponent: {
            addressVM: isPolicyView ? _get(transactionVM, 'policyMailingAddress_Ext') : _get(transactionVM, 'baseData.policyMailingAddress_Ext'),
            labelPosition: 'top',
            showCountry: true,
            showOptional: false,
            onValidate: setComponentValidation,
            onAddressChange: (value, path) => handleMailingAddressChange(value, `baseData.policyMailingAddress_Ext.${path}`),
            viewOnlyMode,
            countryValues: getAvailableValuesForCountry(),
            isMailingOrBilling: true,
            showErrors,
            updateIsAddressValid: updateIsMailingComponentValid,
        },
        billingSameAsMailingInnerContainer: {
            visible: isPolicyView ? !_get(transactionVM, 'isBillingAddressSame_Ext.value') : !_get(transactionVM, 'baseData.isBillingAddressSame_Ext.value')
        },
        billingAddressComponent: {
            addressVM: isPolicyView ? _get(transactionVM, 'policyBillingAddress_Ext') : _get(transactionVM, 'baseData.policyBillingAddress_Ext'),
            labelPosition: 'top',
            showCountry: true,
            showOptional: false,
            onValidate: setComponentValidation,
            onAddressChange: (value, path) => handleAddressChange(value, `baseData.policyBillingAddress_Ext.${path}`),
            viewOnlyMode,
            countryValues: getAvailableValuesForCountry(),
            isMailingOrBilling: true,
            updateIsAddressValid: updateIsBillingComponentValid,
            showErrors
        }
    };

    /**
     * Helper callback for reading values from the view model.
     *
     * @param {*} _id
     * @param {*} path
     * @returns {*} The value as read from the view model.
     */
    const readValue = (_id, path) => readViewModelValue(metadata.pageContent, transactionVM, _id, path, overrideProps);

    /**
     * Define rendering behaviors for this Jutro component.
     */
    if (!isComponentInitialized) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

/**
 * Define expected property types to be passed into this Jutro component.
 */
MailingAndBillingAddressComponent.propTypes = {
    id: PropTypes.string.isRequired,
    transactionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func,
    onValidate: PropTypes.func,
    viewOnlyMode: PropTypes.bool,
    isPolicyView: PropTypes.bool,
    lob: PropTypes.string,
    tooltip: PropTypes.shape({}),
    showErrors: PropTypes.bool,
    updateIsMailingComponentValid: PropTypes.func,
    updateIsBillingComponentValid: PropTypes.func,
    wizardSnapshot: PropTypes.shape({})
};

/**
 * Define default property values to be passed into this Jutro component.
 */
MailingAndBillingAddressComponent.defaultProps = {
    updateWizardData: () => { },
    onValidate: () => { },
    viewOnlyMode: false,
    isPolicyView: false,
    lob: undefined,
    tooltip: undefined,
    showErrors: false,
    updateIsMailingComponentValid: () => { },
    updateIsBillingComponentValid: () => { },
    wizardSnapshot: undefined
};

export default MailingAndBillingAddressComponent;
