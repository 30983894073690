import React, { useCallback, useEffect, useState } from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { TimelineService } from 'e1p-capability-journey';
import { useUWIssueUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from './RiskAnalysisPage.metadata.json5';
import messages from './RiskAnalysisPage.messages';

function RiskAnalysisPage(props) {
    const { authHeader } = useAuthentication();
    const {
        wizardData: submissionVM,
        updateWizardData,
        isSkipping,
        jumpTo,
        steps,
        authUserData,
        updateWizardSnapshot
    } = props;
    const fullQuoteType = _get(submissionVM, 'value.quoteType') === 'Full';
    const quoteID = _get(submissionVM, 'value.quoteID');
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [saveAndQuoteInProcess, setSaveAndQuoteInProcess] = useState(false);
    const [lossesPageValid, setLossesPageValid] = useState(true);
    const [geoDataResponse, setGeoDataResponse] = useState({});
    const [responseAuto, setResponseAuto] = useState([]);
    const [responseProperty, setResponseProperty] = useState({});
    const [overlappingException, setOverlappingException] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const translator = useTranslator();
    const [manualLossList, setManualLossList] = useState([]);
    const [activeTab, updateActiveTab] = useState('');
    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerComponentValidation,
        registerInitialComponentValidation,
        disregardFieldValidation
    } = useValidation('RiskAnalysisPage');

    const {
        hasUWIssues
    } = useUWIssueUtil(
        submissionVM,
        updateWizardData,
        jumpTo,
        steps
    );

    useEffect(()=>{
        if(fullQuoteType){
            const historyPromise = TimelineService.getTimeline(
                {
                    policyNumber: _get(submissionVM, 'value.policyNumber'),
                    relatedItem: 'Policy'
                },
                authHeader
            );

            historyPromise.then((response) => {
                const lossHistoryEvent = response.events.find((event) => event.description?.includes('Self-Declared'))?.description;

                if (lossHistoryEvent) {
                    setManualLossList(lossHistoryEvent.split("\n"));
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    const isPageValid = useCallback(() => lossesPageValid && !overlappingException, [lossesPageValid, overlappingException]);

    useEffect(() => {
        // if isskipping is true, so before skipping this page, we are checking if the status is quoted or quote data is present
        registerInitialComponentValidation(() => _get(submissionVM, 'baseData.periodStatus.value.code') === 'Quoted' || !!_get(submissionVM, 'value.quoteData'));
        registerComponentValidation(isPageValid);
        setIsPageInitialized(true);
    }, [isPageValid, registerComponentValidation, registerInitialComponentValidation, submissionVM]);

    const saveAndQuote = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setSaveAndQuoteInProcess(true);

            const quoteData = _get(submissionVM, 'quoteData.value');
            const response = await LoadSaveService.saveAndQuoteSubmission(
                submissionVM.value,
                authHeader
            );

            _set(submissionVM, 'value', response);

            const respQuoteData = _get(response, 'quoteData');

            if (respQuoteData === undefined) {
                _set(response, 'quoteData', quoteData);
            }

            updateWizardData(submissionVM);
            setSaveAndQuoteInProcess(false);

            return submissionVM;
        }, [authHeader, isComponentValid, submissionVM, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote();

                const fieldIssues = _get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, submissionVM, updateWizardSnapshot]
    );

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(submissionVM, 'lobData.homeowners_EH.premiumStability.value') !== undefined;

    const getDefaultActiveTab = () => {
        if (hasUWIssues()) {
            return 'uwIssuesTab';
        }

        return fullQuoteType ? 'verifiedLossesAndViolationTab' : 'lossesAndViolationTab';
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: !saveAndQuoteInProcess && !isSavingCurrentPageChanges && isPageInitialized,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: !saveAndQuoteInProcess && isPageInitialized && !isSavingCurrentPageChanges,
            defaultActiveTab: getDefaultActiveTab(),
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            activeTab: activeTab !== '' ? activeTab : getDefaultActiveTab()
        },
        lossesAndViolationTab: {
            visible: !fullQuoteType
        },
        verifiedLossesAndViolationTab: {
            visible: fullQuoteType
        },
        verifiedLossesAndViolationComponent: {
            responseAuto,
            setResponseAuto,
            responseProperty,
            setResponseProperty,
            submissionVM,
            updateWizardData,
            setLossesPageValid,
            isSkipping,
            authUserData,
            onValidateParentPage: onValidate,
            disregardFieldValidationParentPage: disregardFieldValidation,
            showErrors: isPageSubmitted,
            updateIsPageSubmitted,
            lineOfBusiness: 'homeowners_EH'
        },
        lossesAndViolationComponent: {
            submissionVM,
            updateWizardData,
            isSkipping,
            setLossesPageValid,
            onValidateParentPage: onValidate,
            disregardFieldValidationParentPage: disregardFieldValidation,
            showErrors: isPageSubmitted,
            updateIsPageSubmitted
        },
        miscellaneaousReportsTab: {
            // visible: false
        },
        EHRiskAnalysisMiscComponentId: {
            submissionVM
        },
        creditReportsComponent: {
            submissionVM,
            updateWizardData,
            lineOfBusiness: submissionVM.lobData.homeowners_EH,
            lobName: 'homeowners_EH',
            authUserData,
            id: 'creditReportsComponent',
            onValidate,
            setOverlappingException
        },
        geoDataComponent: {
            quoteID,
            geoDataResponse,
            setGeoDataResponse,
            policyState: _get(submissionVM, 'baseData.policyAddress.state.value.code')
        },
        uwIssueComponent: {
            submissionVM,
            updateWizardData,
            authHeader
        },
        diminishingAvailableAmount: {
            labelPosition: 'left'
        },
        diminishingAvailableAmountLatest: {
            labelPosition: 'left'
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(submissionVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: submissionVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        },
        manualLossesHistory:{
            manualLossList,
            visible: fullQuoteType && manualLossList.length > 0
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVM]
    );

    return (
        <WizardPage
            skipWhen={initialValidation}
            onNext={saveAndQuote}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
