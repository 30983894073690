import React, {
    useEffect, useState, useCallback, useMemo, useContext,
} from 'react';
import {
    get, set, isUndefined, some, isEmpty
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext, Grid, GridItem } from '@jutro/layout';
import { Icon, useModal } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { UmbrellaFlowUtil } from 'e1p-portals-util-js';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { commonMessages } from 'e1p-platform-translations';
import UnderlyingPolicyDetails from './components/EUUnderlyingPolicyDetails/UnderlyingPolicyDetails';
import metadata from './E1PEUUnderlyingPolicyComponent.metadata.json5';
import messages from './E1PEUUnderlyingPolicyComponent.messages';

function E1PEUUnderlyingPolicyComponent(props) {
    const modalApi = useModal();
    const {
        data: transactionVM,
        id,
        onValidate,
        viewOnlyMode,
        isPageSubmitted,
        updateWizardData,
        setIsLoadingULPolicies,
        deletedItemsRef,
        jobType,
        isPrivatePassengerQuestionVisible: privatePassengerQstnAvailabilityInd,
    } = props;

    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();
    const {
        isComponentValid,
        disregardFieldValidation,
        onValidate: setComponentValidation,
    } = useValidation(id);
    const [
        underlyingPoliciesAreValid,
        setUnderlyingPoliciesAreValid,
    ] = useState([]);
    const [
        isPrivatePassengerQuestionVisible,
        setIsPrivatePassengerQuestionVisible,
    ] = useState(false);
    const [openAccordions, setOpenAccordions] = useState([]);
    const underlyingPoliciesVM = useMemo(() => get(
            transactionVM,
            'lobData.personalUmbrella_EU.coverables.underlyingPolicies',
            []
        ), [transactionVM]);
    const viewModelService = useContext(ViewModelServiceContext);
    const policyPni = get(
        transactionVM,
        'lobData.personalUmbrella_EU.primaryNamedInsured.person.value',
        []
    );
    const policySni = get(
        transactionVM,
        'lobData.personalUmbrella_EU.secondaryNamedInsured.person.value',
        []
    );
    const isAutoExposureDependency = JSON.stringify(
        transactionVM.lobData.personalUmbrella_EU.coverables.underlyingPolicies
            .value
    );
    const translator = useTranslator();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    useEffect(() => {
        setIsPrivatePassengerQuestionVisible(
            privatePassengerQstnAvailabilityInd
        );
    }, [privatePassengerQstnAvailabilityInd]);

    useEffect(() => {
        const passengerAutoUnderlyingPolicyExists = some(
            underlyingPoliciesVM.value.filter(
                (_, index) => !deletedItemsRef?.current?.includes(index)
            ),
            (underlyingPolicy) =>
                underlyingPolicy.policyType === 'personalauto' ||
                underlyingPolicy.policyType === 'personalaononowner'
        );

        if (passengerAutoUnderlyingPolicyExists) {
            set(
                transactionVM.lobData.personalUmbrella_EU
                    .resRelPrivatePassengerAutoInd,
                'value',
                passengerAutoUnderlyingPolicyExists
            );
            set(
                transactionVM.lobData.personalUmbrella_EU,
                'reasonForNotDrivingPrivateAuto.value',
                []
            );
            set(
                transactionVM.lobData.personalUmbrella_EU,
                'noOperatorReasonOther.value',
                undefined
            );
            updateWizardData(transactionVM);
            setIsPrivatePassengerQuestionVisible(false);
            disregardFieldValidation('privatePassengerQuestion');
        }

        const resRelPrivatePassengerAutoIndUndefined = isUndefined(
            get(
                transactionVM.lobData.personalUmbrella_EU
                    .resRelPrivatePassengerAutoInd,
                'value'
            )
        );

        if (
            !passengerAutoUnderlyingPolicyExists &&
            !resRelPrivatePassengerAutoIndUndefined
        ) {
            setIsPrivatePassengerQuestionVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        deletedItemsRef?.current?.length,
        isPrivatePassengerQuestionVisible,
        isAutoExposureDependency,
    ]);

    const removeDeletedUnderlyingPolicies = useCallback(
        (index) => {
            deletedItemsRef.current.push(index);
            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData, deletedItemsRef]
    );

    const openAddedPolicy = useCallback(() => {
        setOpenAccordions([`underlyingPolicy${underlyingPoliciesVM.length - 1}underlyingPolicies`]);
    }, [underlyingPoliciesVM]);

    // Refresh UL Policy information
    const refreshULPolicy = useCallback(async () => {
        setIsLoadingULPolicies(true);

        const response = await LoadSaveService.retrieveUnderlyingPolicies(
            transactionVM.quoteID.value,
            authHeader
        );

        if (!isEmpty(response.exceptions_Ext)) {
            set(
                transactionVM,
                'baseData.exceptions_Ext.value',
                response.exceptions_Ext
            );
            updateWizardData(transactionVM);
            setIsLoadingULPolicies(false);

            return;
        }

        // retrived UL policies
        const retrievedULPolicies = response.underlyingPolicies;
        const retrivedUlPolicyNumbers = [];

        // Extract Retrived policies policy numbers
        retrievedULPolicies.forEach((ulPolicy) => {
            retrivedUlPolicyNumbers.push(ulPolicy.policyNumber);
        });

        // Extract newly added manual policies which should be left undisturbed when refresh is performed
        const newlyAddedULPolicies = underlyingPoliciesVM.value.filter(
            (existingUlPolicy) => (
                    !retrivedUlPolicyNumbers.includes(
                        existingUlPolicy.policyNumber
                    ) && existingUlPolicy.isManuallyAddedInd
                )
        );

        // Final list of UL policies that needs to be displayed on UI
        underlyingPoliciesVM.value = [
            ...retrievedULPolicies,
            ...newlyAddedULPolicies,
        ];
        updateWizardData(transactionVM);
        setIsLoadingULPolicies(false);
        openAddedPolicy();
    }, [
        underlyingPoliciesVM,
        transactionVM,
        authHeader,
        updateWizardData,
        setIsLoadingULPolicies,
        openAddedPolicy,
    ]);

    const addUnderlyingPolicy = useCallback(() => {
        const { _xCenter, _dtoName } = underlyingPoliciesVM;
        const pni = get(
            transactionVM.value,
            'lobData.personalUmbrella_EU.primaryNamedInsured.person'
        );
        const sni = get(
            transactionVM.value,
            'lobData.personalUmbrella_EU.secondaryNamedInsured.person'
        );
        const tempUnderlyingPolicy = {
            personalPropertyExposure: {
                lineType: undefined,
            },
            isManuallyAddedInd: true,
            primaryNamedInsured: {
                firstName: pni.firstName,
                lastName: pni.lastName,
                middleName: pni.middleName,
                suffix: pni.suffix,
                dateOfBirth: pni.dateOfBirth,
            },
        };

        if (sni) {
            tempUnderlyingPolicy.secondaryNamedInsured = {
                firstName: sni?.firstName,
                lastName: sni?.lastName,
                middleName: sni?.middleName,
                suffix: sni?.suffix,
                dateOfBirth: sni?.dateOfBirth,
            };
        }

        const newUnderlyingPolicyVM = viewModelService.create(
            tempUnderlyingPolicy,
            _xCenter,
            _dtoName
        );

        underlyingPoliciesVM.value.push(newUnderlyingPolicyVM.value);
        updateWizardData(transactionVM);
        openAddedPolicy();
    }, [
        underlyingPoliciesVM,
        viewModelService,
        updateWizardData,
        transactionVM,
        openAddedPolicy,
    ]);

    const removeUnderlyingPolicy = useCallback(
        (index) => {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.removeUnderlyingPolicy,
                message: translator(commonMessages.removeItemMessage, {
                    itemToRemove: 'Underlying Policy',
                }),
                confirmButtonText: translator(
                    commonMessages.removeItemButtonText,
                    { itemToRemove: 'UNDERLYING POLICY' }
                ),
                cancelButtonText: commonMessages.cancel,
            }).then(
                (result) => {
                    if (result !== 'cancel') {
                        removeDeletedUnderlyingPolicies(index);
                        disregardFieldValidation(`ulPolicyDetails${index}`);
                    }
                },
                () => { }
            );
        },
        [disregardFieldValidation, modalApi, removeDeletedUnderlyingPolicies, translator]
    );

    const renderUnderlyingPolicyHeader = useCallback(
        (underlyingPolicy, index) => {
            const policyType = get(underlyingPolicy, 'policyType');
            // For system policies propertyPolicyType would be populated
            const propertyType = get(
                underlyingPolicy,
                'personalPropertyExposure.propertyPolicyType.value'
            )
                ? get(
                    underlyingPolicy,
                    'personalPropertyExposure.propertyPolicyType'
                )
                : get(
                    underlyingPolicy,
                    'personalPropertyExposure.propertyType'
                );
            const carrierName = get(underlyingPolicy, 'carrierName');
            const firstName = `${get(
                underlyingPolicy,
                'primaryNamedInsured.firstName.value',
                '-'
            )}`;
            const midInitial = ` ${get(
                underlyingPolicy,
                'primaryNamedInsured.middleName.value',
                ''
            ).charAt(0)}`;
            const lastName = ` ${get(
                underlyingPolicy,
                'primaryNamedInsured.lastName.value',
                '-'
            )}`;
            const isValidIconClass = () => {
                if (underlyingPoliciesAreValid[index]) {
                    return 'success-icon icon-size-large';
                }

                if (isPageSubmitted) {
                    return 'error-icon icon-size-large';
                }

                return 'display-none';
            };
            const getIcon = () => {
                if (isPageSubmitted && !underlyingPoliciesAreValid[index]) {
                    return 'mi-error-outline';
                }

                return 'mi-check-circle';
            };

            return (isOpen) => (
                <div className="container-width-100">
                    <Grid
                        columns={[
                            '1fr',
                            '1fr',
                            '1fr',
                            '1fr',
                            '1fr',
                            '0.25fr',
                            '0.25fr',
                        ]}
                        gap="small"
                    >
                        <GridItem className="display-flex font-b">
                            <Icon
                                id={`validStatusAccordionHeader${index}`}
                                icon={getIcon()}
                                size="large"
                                className={isValidIconClass()}
                            />
                            {policyType && policyType.value
                                ? translator({
                                    id: policyType.value.name,
                                    name: policyType.value.code,
                                })
                                : '-'}
                        </GridItem>
                        <GridItem className="font-b">
                            {propertyType && propertyType.value
                                ? translator({
                                    id: propertyType.value.name,
                                    name: propertyType.value.code,
                                })
                                : '-'}
                        </GridItem>
                        <GridItem className="font-b">
                            {`${firstName}${midInitial}${lastName}`}
                        </GridItem>
                        <GridItem className="font-b">
                            {carrierName && carrierName.value
                                ? translator({
                                    id: carrierName.value.name,
                                    name: carrierName.value.code,
                                })
                                : '-'}
                        </GridItem>
                        <GridItem className="font-b">
                            {underlyingPolicy.policyNumber.value}
                        </GridItem>
                        <Icon
                            id={`editAccordionHeader${index}`}
                            icon={isOpen ? 'mi-unfold-less' : 'mi-edit'}
                            size="large"
                            hidden={viewOnlyMode}
                            className="iconAsButton"
                        />
                        <Icon
                            id={`deleteAccordionHeader${index}`}
                            icon="mi-delete"
                            size="large"
                            hidden={viewOnlyMode}
                            className="iconAsButton"
                            onClick={(evt) => {
                                evt.stopPropagation();
                                removeUnderlyingPolicy(index);
                            }}
                        />
                    </Grid>
                </div>
            );
        },
        [
            isPageSubmitted,
            removeUnderlyingPolicy,
            translator,
            underlyingPoliciesAreValid,
            viewOnlyMode,
        ]
    );

    const setUnderlyingPoliciesAreValidArray = useCallback(
        (isValid, index) => {
            // will go inside of if loop only when we have indexed element in array
            if (underlyingPoliciesAreValid[index] !== undefined) {
                underlyingPoliciesAreValid[index] = isValid;
                setUnderlyingPoliciesAreValid(underlyingPoliciesAreValid);
            } else {
                underlyingPoliciesAreValid.push(isValid);
                setUnderlyingPoliciesAreValid(underlyingPoliciesAreValid);
            }
        },
        [underlyingPoliciesAreValid]
    );

    const generateOverrides = useCallback(() => {
        const overrides = [];

        underlyingPoliciesVM.forEach((underlyingPolicy, index) => {
            overrides.push({
                [`ulPolicyDetails${index}`]: {
                    model: underlyingPolicy,
                    onModelChange: () => updateWizardData(transactionVM),
                    labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                    onValidate: setComponentValidation,
                    isPniOrSniMatch:
                        UmbrellaFlowUtil.checkIfNamedInsuredMatch(
                            policyPni,
                            get(
                                underlyingPolicy,
                                'primaryNamedInsured.value',
                                []
                            )
                        ) ||
                        UmbrellaFlowUtil.checkIfNamedInsuredMatch(
                            policySni,
                            get(
                                underlyingPolicy,
                                'secondaryNamedInsured.value',
                                []
                            )
                        ),
                    setUnderlyingPolicyIsValid: (isValid) =>
                        setUnderlyingPoliciesAreValidArray(isValid, index),
                    showErrors: isPageSubmitted,
                    viewOnlyMode,
                },
                [`underlyingPolicy${index}`]: {
                    visible: !some(
                        deletedItemsRef.current,
                        (idx) => idx === index
                    ),
                    renderHeader: renderUnderlyingPolicyHeader(
                        underlyingPolicy,
                        index
                    ),
                    errorState: !underlyingPolicy.aspects.subtreeValid,
                    showErrors: isPageSubmitted,
                },
            });
        });

        overrides.push({
            underlyingPolicies: {
                showErrors: isPageSubmitted && !viewOnlyMode,
                accordionStates: openAccordions
            }
        });

        return Object.assign({}, ...overrides);
    }, [underlyingPoliciesVM, isPageSubmitted, viewOnlyMode, openAccordions, breakpoint, setComponentValidation, policyPni, policySni,
        deletedItemsRef, renderUnderlyingPolicyHeader, updateWizardData, transactionVM, setUnderlyingPoliciesAreValidArray]);

    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false,
            readOnly: viewOnlyMode,
        },
        
        emptyUlPolicies: {
            visible:
                underlyingPoliciesVM.children.length ===
                deletedItemsRef.current.length,
        },
        addULpoliciesButton: {
            visible: !viewOnlyMode,
        },
        mandatoryPolicyTypeDiv: {
            visible: !viewOnlyMode && jobType !== 'PolicyChange',
        },
        // Make it available for submission transaction
        refreshUnderlyingPolicyInfo: {
            visible: jobType === 'Submission' && !viewOnlyMode,
        },
        underlyingPolicyPageTitle: {
            visible: jobType === 'PolicyChange',
        },
        titleDivider: {
            visible: jobType === 'PolicyChange',
        },
        privatePassengerQuestion: {
            lobDataModel: transactionVM.lobData.personalUmbrella_EU,
            onModelChange: () => updateWizardData(transactionVM),
            viewModelService,
            isPrivatePassengerQuestionVisible,
            visible: isPrivatePassengerQuestionVisible,
            isPageSubmitted,
            viewOnlyMode,
            underlyingPolicyPageOnValidate: onValidate,
            onValidate: setComponentValidation,
        },
        ...generateOverrides(),
    };

    const writeValue = useCallback(
        (newVal, path) => {
            set(transactionVM, `${path}.value`, newVal);
            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData]
    );

    const resolvers = {
        resolveCallbackMap: {
            addUnderlyingPolicy,
            onRefreshULPolicy: refreshULPolicy,
            renderUnderlyingPolicyHeader,
            onValidate: setComponentValidation,
        },
        resolveComponentMap: {
            underlyingpolicydetails: UnderlyingPolicyDetails,
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionVM}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={setComponentValidation}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            onValueChange={writeValue}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

E1PEUUnderlyingPolicyComponent.propTypes = {
    data: PropTypes.shape({}),
    onValidate: PropTypes.func,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    isPageSubmitted: PropTypes.bool,
    setIsLoadingULPolicies: PropTypes.func,
    isPrivatePassengerQuestionVisible: PropTypes.bool,
    deletedItemsRef: PropTypes.shape({}),
    jobType: PropTypes.string,
};

E1PEUUnderlyingPolicyComponent.defaultProps = {
    data: {},
    id: undefined,
    viewOnlyMode: false,
    isPageSubmitted: false,
    onValidate: undefined,
    setIsLoadingULPolicies: () => { }
};
export default E1PEUUnderlyingPolicyComponent;
