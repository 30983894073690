import { defineMessages } from 'react-intl';

export default defineMessages({
    fullPay: {
        id: 'common.component.PaymentOptionsComponent.Full Pay',
        defaultMessage: 'Full Pay'
    },
    monthly: {
        id: 'common.component.PaymentOptionsComponent.Monthly',
        defaultMessage: 'Monthly'
    },
    tpiFullPay: {
        id: 'common.component.PaymentOptionsComponent.Mortgagee Bill',
        defaultMessage: 'Mortgagee Bill'
    },
    changingPaymentPlans: {
        id: 'common.component.PaymentOptionsComponent.Changing Payment Plans...',
        defaultMessage: 'Changing Payment Plans...'
    },
});
