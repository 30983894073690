import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import messages from './SideBySideActionBarComponent.messages';
import styles from './SideBySideActionBarComponent.module.scss';
import { Button } from '@jutro/legacy/components';

function SideBySideActionBarComponent(props) {
    const {
        backButtonFunction,
        addVersionFunction,
        onRecalculateSideBySide,
        onViewQuoteProposal,
        isQuoteProposalReady,
        quoteInDraftStatus
    } = props;

    const translator = useTranslator();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Grid className={styles.toolbarContainer} columns={['4fr', 'auto', 'auto', 'auto', 'auto']} gap="small">
            {isLoading ?
                (
                    <E1PLoader
                        loaded={!isLoading}
                        text={translator(messages.updatingVersionName)}
                    />
                )
                :(<React.Fragment>
                    <h4>{translator(messages.quoteDetailsTitle)}</h4>
                    <Button
                        onClick={() => backButtonFunction(false)}
                        icon="mi-arrow_back"
                        type="outlined"
                    >
                        {translator(messages.backToCoverages)}
                    </Button>
                    <Button
                        onClick={(e) => onViewQuoteProposal(e, 'SIDE_BY_SIDE')}
                        icon="insert-drive-file"
                        type="outlined"
                        disabled={!isQuoteProposalReady}
                    >
                        {translator(messages.viewQuoteProposal)}
                    </Button>
                    <Button
                        onClick={async () => {
                            setIsLoading(true); 
                            await addVersionFunction();
                            setIsLoading(false);
                        }}
                        icon="mi-add"
                        type="outlined"
                    >
                        {translator(messages.addQuote)}
                    </Button>
                    <Button
                        onClick={() => onRecalculateSideBySide()}
                        type="filled"
                        disabled={!quoteInDraftStatus}
                    >
                        {translator(messages.recalculate)}
                    </Button>
                </React.Fragment>)
            }
            
        </Grid>
    );
}

SideBySideActionBarComponent.propTypes = {
    backButtonFunction: PropTypes.func.isRequired,
    addVersionFunction: PropTypes.func.isRequired,
    onRecalculateSideBySide: PropTypes.func.isRequired,
    onViewQuoteProposal: PropTypes.func.isRequired,
    isQuoteProposalReady: PropTypes.bool.isRequired,
    quoteInDraftStatus:  PropTypes.bool.isRequired
};

export default SideBySideActionBarComponent;
