import { defineMessages } from 'react-intl';

export default defineMessages({
    policyTerm: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Policy Term',
        defaultMessage: 'Policy Term'
    },
    direction: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Direction',
        defaultMessage: 'Direction'
    },
    addingPreRenewalDirection: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Adding Pre-Renewal Direction...',
        defaultMessage: 'Adding Pre-Renewal Direction...'
    },
    save: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Save',
        defaultMessage: 'Save'
    },
    ok: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Ok',
        defaultMessage: 'Ok'
    },
    cancel: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Cancel',
        defaultMessage: 'Cancel'
    },
    addNoteBtnLabel: {
        id: 'common.component.e1pPreRenewalDirectionComponent.buttonLabel.ADD NOTE',
        defaultMessage: 'ADD NOTE'
    },
    removeBtnLabel: {
        id: 'common.component.e1pPreRenewalDirectionComponent.buttonLabel.REMOVE',
        defaultMessage: 'REMOVE'
    },
    addNote: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Add Note',
        defaultMessage: 'Add Note'
    },
    topic: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Topic',
        defaultMessage: 'Topic'
    },
    subject: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Subject',
        defaultMessage: 'Subject'
    },
    relatedTo: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Related to',
        defaultMessage: 'Related to'
    },
    securityLevel: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Security Level',
        defaultMessage: 'Security Level'
    },
    note: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Note',
        defaultMessage: 'Note'
    },
    createLetterInformationMessage: {
        id: 'common.component.e1pPreRenewalDirectionComponent.An interactive letter must be created to save a pre-renewal direction of Non-Renew.',
        defaultMessage: 'An interactive letter must be created to save a pre-renewal direction of Non-Renew.'
    },
    createLetterMailingDate: {
        id: 'common.component.e1pPreRenewalDirectionComponent.Letter Mailing Date',
        defaultMessage: 'Letter Mailing Date'
    },
    createLetterButton: {
        id: 'common.component.e1pPreRenewalDirectionComponent.CREATE LETTER',
        defaultMessage: 'CREATE LETTER'
    }
});
