import React, {
    useEffect, useContext, useState, useCallback
} from 'react';
import { useModal } from '@jutro/components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import cx from 'classnames';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import { error as loggerError } from '@jutro/logger';
import { ActivitiesService } from 'gw-capability-gateway';
import { NoteService } from 'e1p-capability-gateway';
import htmlParser from 'html-react-parser';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { e1pDateUtil } from 'e1p-capability-hooks';
import ActivityTableComponent from '../Activity/ActivityTableComponent/ActivityTableComponent';
import metadata from './OpenActivitiesComponent.metadata.json5';
import styles from './OpenActivitiesComponent.module.scss';
import messages from './OpenActivitiesComponent.messages';
import gatewayMessages from '../../gateway.messages';

const getNoOfDays = (data) => {
    const currentDate = new Date();
    const eDate = new Date(data.dueDate);
    const noOfDays = currentDate - eDate;
    const noOfDueDay = noOfDays / (1000 * 3600 * 24);

    return Math.floor(noOfDueDay);
};

const activitiesOverdueData = (data) => data.filter((activitiesInfo) => activitiesInfo.status !== 'complete' && getNoOfDays(activitiesInfo) > 0);

const activitiesThisWeekData = (data, status) => data.filter((activitiesInfo) => {
    const today = moment();
    const tomorrow = moment().add(1, 'days');

    return (
        !moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
        && !moment(activitiesInfo.dueDate).isSame(today, 'd')
        && activitiesInfo.status === status
        && (getNoOfDays(activitiesInfo) > 0
            ? false
            : getNoOfDays(activitiesInfo) * -1
            < moment(activitiesInfo.dueDate).isoWeekday())
    );
});

const activitiesTodayData = (data, status) => data.filter((activitiesInfo) => {
    const today = moment();

    return (
        activitiesInfo.dueDate !== undefined
        && activitiesInfo.status === status
        && moment(activitiesInfo.dueDate).isSame(today, 'd')
    );
});

const activitiesTomorrowData = (data, status) => data.filter((activitiesInfo) => {
    const tomorrow = moment().add(1, 'days');

    return (
        activitiesInfo.status === status
        && moment(activitiesInfo.dueDate).isSame(tomorrow, 'd')
    );
});

const activitiesFutureData = (data, status) => data.filter((activitiesInfo) => (
        activitiesInfo.status === status
        && !moment(activitiesInfo.dueDate).isBefore(moment().endOf('isoWeek'), 'd')
    ));

const getActivitiesOverdue = (activityList) => {
    const today = moment();

    return activityList.filter((activity) => activity.status !== 'complete' && (_.isUndefined(activity.dueDate) || today.isAfter(activity.dueDate, 'day')));
};

const filterActivitiesByValue = (activityArrayResult, filterValue) => {
    const lowerCaseFilterValue = filterValue.toLocaleLowerCase();

    return _.filter(activityArrayResult, (res) => Object.keys(res).some(
            (key) => typeof res[key] === 'string'
                && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
        ));
};

const filterActivitiesByStatus = (filterValue, statusMap, openActivityData) => {
    let activityList = [];

    switch (filterValue) {
        case statusMap.allOpen:
            activityList = openActivityData.filter((act) => act.status === 'open');
            break;
        case statusMap.allClose:
            activityList = openActivityData.filter(
                (activitiesInfo) => activitiesInfo.status === 'complete'
            );
            break;
        case statusMap.overDueOnly:
            activityList = activitiesOverdueData(openActivityData);
            break;
        case statusMap.openAssignedToMe:
            activityList = openActivityData.filter((act) => act.status === 'open'
                && act.isAssignedToCurrentUser);
            break;
        default:
            break;
    }

    return activityList;
};

const splitActivitiesForTable = (activityList) => ({
        activitiesComplete: activityList.filter(
            (activitiesInfo) => activitiesInfo.status === 'complete'
        ),
        activitiesFuture: activitiesFutureData(activityList, 'open'),
        activitiesOverdue: getActivitiesOverdue(activityList),
        activitiesThisWeek: activitiesThisWeekData(
            activityList,
            'open'
        ),
        activitiesToday: activitiesTodayData(activityList, 'open'),
        activitiesTomorrow: activitiesTomorrowData(
            activityList,
            'open'
        )
    });

const DEFAULT_FOLLOWUP_ACTIVITY_DETAILS = {
    isAddingFollowupActivity: false,
    canCompleteBasedOnActivity: false,
    basedOnActivityPublicID: undefined,
    followupActivityCompletionMessage: undefined,
    followupActivityType: undefined
}

function OpenActivitiesComponent(props) {
    const modalApi = useModal();
    const {
        id: componentId,
        authHeader,
        getActivities,
        activityEntityId,
        activityEntity,
        createNewActivity,
        getPolicyDetails,
        getActivitiesTileUpdatedCount,
        authUserData
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [openActivityResponse, updateOpenActivityResponse] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [activityFilter, setActivityFilter] = useState({
        statusFilter: '',
        searchFilter: ''
    });
    const [selectedUser, updateSelectedUser] = useState([]);
    const [showComponent, updateShowComponent] = useState(false);
    const [displayNoActivities, updateDisplayNoActivities] = useState(false);
    const [addActivitySubmissionVM, updateVMData] = useState({});
    const [newActivityTypeAvailableValues, updateNewActivityTypeAvailableValues] = useState([]);
    const [newActivityPriorityAvailableValues, updateNewActivityPriorityAvailableValues] = useState(
        []
    );
    const [userAvailableValues, updateUserAvailableValues] = useState([]);
    const [activityOptions, updateActivityOptions] = useState();
    const [showActivityButton, updateShowActivityButton] = useState(false);
    const [assignableUserData, updateAssignableUserData] = useState([]);
    const [activityPatternData, updateActivityPatternData] = useState([]);
    const [isAddActivityLoading, setIsAddActivityLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [publicId, setPublicId] = useState('');
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation(
        componentId
    );
    const canActcreate = authUserData?.permissions_Ext.includes('actcreate');

    const [followupActivityDetails, setFollowupActivityDetails] = useState(DEFAULT_FOLLOWUP_ACTIVITY_DETAILS);

    const intl = useContext(IntlContext);

    const dateFormat = useCallback((date) => intl.formatDate(new Date(date), { year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' }), [intl]);

    const getActivityOptions = useCallback(
        () => {
            const openActivityOptionsArray = [
                translator(messages.allOpen),
                translator(messages.allCompleted),
                translator(messages.overdue),
                translator(messages.openAssignedToMe)
            ];

            return openActivityOptionsArray.map((key) => ({
                    code: key,
                    name: key
                }));
        },
        [translator]
    );

    const dateIsInFuture = useCallback((date) => {
        if (!date) {
            return false;
        }

        const currentTime = new Date();

        currentTime.setHours(0, 0, 0, 0);

        // new Date(year, month, day, hours, minutes, seconds, milliseconds)
        const selectedDate = new Date(date.year, date.month, date.day);

        selectedDate.setHours(0, 0, 0, 0);

        return selectedDate >= currentTime;
    }, []);

    const isPageValid = useCallback(() => {
        const dueDate = _.get(addActivitySubmissionVM.value, 'dueDate');
        const escalationDate = _.get(addActivitySubmissionVM.value, 'escalationDate');
        const isFutureDueDate = dateIsInFuture(dueDate);
        const isFutureEscalationDate = dateIsInFuture(escalationDate);

        return isFutureDueDate && isFutureEscalationDate;
    }, [addActivitySubmissionVM, dateIsInFuture]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation]);

    const getActivityDataTable = useCallback(
        (activityResponseData, filter) => {
            let updatedActivityList = activityResponseData;
            const statusMap = {
                allOpen: translator(messages.allOpen),
                overDueOnly: translator(messages.overdue),
                allClose: translator(messages.allCompleted),
                openAssignedToMe: translator(messages.openAssignedToMe)
            };

            if (_.toString(filter.statusFilter) !== '') {
                updatedActivityList = filterActivitiesByStatus(
                    filter.statusFilter, statusMap, updatedActivityList
                );
            }

            if (_.toString(filter.searchFilter) !== '') {
                updatedActivityList = filterActivitiesByValue(
                    updatedActivityList, filter.searchFilter
                );
            }

            setActivityList(_.sortBy(updatedActivityList, 'dueDate'));
            setActivityFilter(filter);

            if (!updatedActivityList.length) {
                updateDisplayNoActivities(true);
            } else {
                updateDisplayNoActivities(false);
            }
        },
        [translator]
    );

    const responseData = useCallback(
        async () => {
            setIsLoading(true);

            const filter = _.cloneDeep(activityFilter);

            filter.statusFilter = translator(messages.allOpen);

            const response = await getActivities();
            const activities = _.map(response, (activity) => _.extend({}, activity, { expanded: false }));

            getActivityDataTable(activities, filter);
            updateOpenActivityResponse(activities);
            updateActivityOptions(getActivityOptions(activities));
            setIsLoading(false);

            return true;
        },
        [activityFilter, getActivities, getActivityDataTable, getActivityOptions, translator]
    );

    useEffect(() => {
        responseData();
        updateShowActivityButton(canActcreate);
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNote = async (data, publicID) => {
        const activityNoteData = await ActivitiesService.createNoteForActivity(
            publicID,
            data,
            authHeader
        );

        if (!_.isUndefined(activityNoteData)) {
            modalApi.showAlert({
                title: gatewayMessages.noteCreated,
                message: gatewayMessages.noteCreatedSuccessfully,
                status: 'success',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.close
            }).then(() => {
                const activityObj = openActivityResponse.find((obj) => obj.publicID === publicID);

                activityObj.notes.push(activityNoteData);
                getActivityDataTable(openActivityResponse, activityFilter);
            }, _.noop);
        }
    };

    const editNote = async(data) => {
        try {
            const policyNoteData = await NoteService.editNote(
                data,
                authHeader
            );
            
            if (!_.isEmpty(policyNoteData)) {
                // refresh activities
                await responseData();
            }
        } catch (e) {
            modalApi.showAlert({
                title: gatewayMessages.modalError,
                message: messages.errorUpdatingNotes,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.close
            }).catch(_.noop);
        }
    };

    const deleteNote = async (data) => {
        try {
            const policyNoteData = await NoteService.deleteNote(
                data,
                authHeader
            );

            // delete note api will return empty response if successfull
            if (_.isEmpty(policyNoteData)) {
                // refresh activities
                await responseData();
            }
        } catch (e) {
            modalApi.showAlert({
                title: gatewayMessages.modalError,
                message: messages.errorDeletingNotes,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.close
            }).catch(_.noop);
        }
    };
    
    const createVM = useCallback(
        (model) => viewModelService.create(
                model,
                'pc',
                'edge.capabilities.gateway.activity.dto.ActivityDTO'
            ),
        [viewModelService]
    );

    const handleChange = useCallback(
        (value, path) => {
            const userData = assignableUserData;
            const addActivityResponseData = activityPatternData;
            const newActivitySubmissionVM = viewModelService.clone(addActivitySubmissionVM);
            let newActivityResult = [];

            _.set(newActivitySubmissionVM.value, path, value !== '' ? value : undefined);

            switch (path) {
                case 'jobType':
                    newActivityResult = addActivityResponseData.find((ob) => ob.code === value);
                    _.set(newActivitySubmissionVM.value, 'dueDate', newActivityResult.dueDate);
                    _.set(newActivitySubmissionVM.value, 'subject', newActivityResult.subject);
                    _.set(
                        newActivitySubmissionVM.value,
                        'escalationDate',
                        newActivityResult.escalationDate
                    );
                    _.set(newActivitySubmissionVM.value, 'priority', newActivityResult.priority);
                    _.set(
                        newActivitySubmissionVM.value,
                        'description',
                        newActivityResult.description
                    );
                    _.set(newActivitySubmissionVM.value, 'selectedAssignee', userData[0]);
                    _.set(newActivitySubmissionVM.value, 'mandatory', newActivityResult.mandatory);
                    _.set(newActivitySubmissionVM.value, 'code', newActivityResult.code);
                    _.set(newActivitySubmissionVM.value, 'recurring', newActivityResult.recurring);
                    _.set(
                        newActivitySubmissionVM.value,
                        'assignedTo',
                        newActivityResult.assignedTo
                    );

                    if (
                        !newActivityResult.assignedTo
                        && !_.get(newActivitySubmissionVM.value, 'assignedTo')
                        && selectedUser
                    ) {
                        _.set(
                            newActivitySubmissionVM.value,
                            'assignedTo',
                            selectedUser
                        );
                    }

                    updateSelectedUser(newActivitySubmissionVM.value.selectedAssignee);
                    break;
                case 'assignedTo':
                    _.set(newActivitySubmissionVM.value, 'assignedTo', value);
                    updateSelectedUser(value);
                    break;
                default:
                    break;
            }

            updateVMData(newActivitySubmissionVM);
        },
        [activityPatternData, addActivitySubmissionVM, assignableUserData, viewModelService, selectedUser]
    );

    const handleFilterChange = (value, filterType) => {
        const filter = _.cloneDeep(activityFilter);

        if (filterType === 'statusFilter') {
            filter.statusFilter = value;
            getActivityDataTable(openActivityResponse, filter);
        }

        if (filterType === 'searchFilter') {
            filter.searchFilter = value;
            getActivityDataTable(openActivityResponse, filter);
        }
    };

    useEffect(() => {
        const addNewActivityResponse = activityPatternData;
        const userData = assignableUserData;
        const subjectDataArray = addNewActivityResponse.map((key) => ({
                name: key.subject,
                code: key.code
            }));

        const uniqueActivityPriority = _.uniqBy(addNewActivityResponse, 'priority');
        const priorityDataArray = uniqueActivityPriority.map((key) => ({
                name: _.startCase(_.toLower(key.priority)),
                code: key.priority
            }));

        const userDataObject = userData.map((key) => ({
                name: key,
                code: key
            }));
        const defaultSelectedUser = userData[0];

        if (!_.isEmpty(activityPatternData)) {
            updateShowComponent(true);
        }

        updateNewActivityTypeAvailableValues(subjectDataArray);
        updateNewActivityPriorityAvailableValues(priorityDataArray);
        updateUserAvailableValues(userDataObject);
        updateSelectedUser(defaultSelectedUser);
    }, [activityPatternData, assignableUserData, updateSelectedUser]);

    const getAssignableUserForActivity = useCallback(
        (activityPatterns) => {
            const res = activityPatterns;

            ActivitiesService.getAssignableUserForActivity(
                activityEntity,
                activityEntityId,
                res,
                authHeader
            ).then((activity) => {
                updateAssignableUserData(activity.assignableUsers);
            });
        },
        [activityEntity, activityEntityId, authHeader]
    );

    const getActivityPatternsFor = useCallback(
        async () => {
            const activityPatterns = await ActivitiesService.getActivityPatternsFor(activityEntity, authHeader);

            if (activityPatterns) {
                getAssignableUserForActivity(activityPatterns[0]);
                updateActivityPatternData([...activityPatterns]);
                setIsAddActivityLoading(false);
            }
        },
        [activityEntity, authHeader, getAssignableUserForActivity]
    );

    const addNewActivityClick = useCallback(
        () => {
            setIsAddActivityLoading(true);

            const addActivitySubmissionData = createVM({});

            updateVMData(addActivitySubmissionData);
            getActivityPatternsFor();
        },
        [createVM, getActivityPatternsFor]
    );

    const getActivityPatternForFollowUpActivity = useCallback(
        async () => {
            const activityPatterns = await ActivitiesService.getActivityPatternsFor(activityEntity, authHeader);

            if (activityPatterns) {
                updateActivityPatternData([...activityPatterns]);
            }
        }, [activityEntity, authHeader]);

    const addFollowUpActivity = useCallback(async (activityData) => {
        // take user to Add Activity section
        window.scrollTo({
            top: 150,
            left: 0,
            behavior: 'smooth'
        });
        setIsAddActivityLoading(true);
        await getActivityPatternForFollowUpActivity();

        // set followup Activity details
        const completeMessage = htmlParser(`The activity,<b> ${activityData.subject} due ${dateFormat(e1pDateUtil.convertToUTC(activityData.dueDate))}, </b> has been marked complete.`);
        const activityDetails = {
            isAddingFollowupActivity: true,
            canCompleteBasedOnActivity: activityData.canComplete,
            basedOnActivityPublicID: activityData.publicID,
            followupActivityCompletionMessage: completeMessage,
            followupActivityType: activityData.activityPattern.subject
        };

        setFollowupActivityDetails(activityDetails);

        // set assignable users
        const {assignableUsers} = activityData;

        updateAssignableUserData(assignableUsers);

        // set assignedTo user
        let assignedToIndex = 0;
        const assignedToDisplayName = _.get(activityData, 'assignedTo.displayName');

        if (assignedToDisplayName) {
            // find assignedTo user in assignable user, since we dont have exact name we will search with startswith
            assignedToIndex = assignableUsers.findIndex((user) => user.startsWith(assignedToDisplayName));

            if (assignedToIndex === -1) {
                // no matching user found, then select the first user
                assignedToIndex = 0;
            }
        }

        const assignedToUser = assignableUsers[assignedToIndex];

        updateSelectedUser(assignedToUser);

        const activity = {
            jobType: activityData.activityPattern.code,
            dueDate: activityData.dueDate,
            subject: `Follow up - ${activityData.subject}`,
            escalationDate: activityData.escalationDate,
            assignedTo: assignedToUser,
            priority: activityData.priority,
            mandatory: activityData.mandatory,
            code: activityData.activityPattern.code,
            recurring: activityData.recurring,
            description: activityData.description
        };

        // create activityVM
        const addActivitySubmissionData = createVM(activity);

        updateVMData(addActivitySubmissionData);
        setIsAddActivityLoading(false);

    }, [createVM, dateFormat, getActivityPatternForFollowUpActivity]);

    const addNewActivityCancelClick = useCallback(
        () => {
            updateShowComponent(false);
            updateVMData({});
            setFollowupActivityDetails(DEFAULT_FOLLOWUP_ACTIVITY_DETAILS);
        },
        []
    );

    const handleError = useCallback(
        (title, message) => modalApi.showAlert({
                title,
                message,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: messages.noTypeSelected
            }).catch(_.noop),
        [modalApi]
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);

    const addNewActivityAddClick = useCallback(
        async () => {
            if(!isComponentValid){
                updateIsPageSubmitted(true);

                return false;
            }

            let newActivityData = {
                ...addActivitySubmissionVM.value,
                activityPattern: {
                    code: _.get(addActivitySubmissionVM.value, 'code'),
                    priority: _.get(addActivitySubmissionVM.value, 'priority')
                }
            };

            newActivityData = _.omit(newActivityData, 'jobType');

            if (
                !newActivityData.assignedTo
                && selectedUser
            ) {
                _.set(
                    newActivityData,
                    'assignedTo',
                    selectedUser
                );
            }

            setIsAddActivityLoading(true);

            switch (activityEntity) {
                case 'account':
                    newActivityData.accountNumber = activityEntityId;
                    break;
                case 'policy':
                    newActivityData.policyNumber = activityEntityId;
                    break;
                case 'job':
                    newActivityData.jobNumber = activityEntityId;
                    break;
                default:
                    break;
            }

            if (!_.get(addActivitySubmissionVM.value, 'jobType')) {
                handleError(messages.noActivityType, messages.noActivityTypeMessage);
                setIsAddActivityLoading(false);
            } else {
                _.set(newActivityData, 'dueDate', e1pDateUtil.convertToUTC(newActivityData.dueDate));
                _.set(newActivityData, 'escalationDate', e1pDateUtil.convertToUTC(newActivityData.escalationDate));

                const addResponse = await createNewActivity(newActivityData);

                if (!_.isEmpty(addResponse) && !_.isUndefined(addResponse)) {

                    const successTitle = _.get(followupActivityDetails, 'isAddingFollowupActivity', false)
                        ? messages.followupActivityCreated
                        : messages.activityCreated;
                    let sucessMessage = '';

                    // check followup activity if we need to mark complete
                    if (_.get(followupActivityDetails, 'isAddingFollowupActivity')
                        && _.get(followupActivityDetails, 'canCompleteBasedOnActivity')
                        && _.get(followupActivityDetails, 'basedOnActivityPublicID')) {
                        // complete basedon Activity
                        try {
                            const completeActivityResponse = await ActivitiesService.markActivityAsCompleted(_.get(followupActivityDetails, 'basedOnActivityPublicID'), authHeader);

                            if (_.get(completeActivityResponse, 'status') === 'complete') {
                                sucessMessage = _.get(followupActivityDetails, 'followupActivityCompletionMessage');
                            }
                        } catch (e) {
                            loggerError(e);
                        }
                    }

                    modalApi.showAlert({
                        title: successTitle,
                        message: sucessMessage,
                        status: 'info',
                        icon: 'mi-error-outline'
                    }).catch(_.noop);

                    if (getActivitiesTileUpdatedCount) {
                        getActivitiesTileUpdatedCount();
                    }
                }

                const updatedActivityResponseArray = [...openActivityResponse, addResponse];

                updateOpenActivityResponse(updatedActivityResponseArray);

                const filter = _.cloneDeep(activityFilter);

                filter.statusFilter = translator(messages.allOpen);

                getActivityDataTable(updatedActivityResponseArray, filter);
                updateActivityOptions(getActivityOptions(updatedActivityResponseArray));
                updateShowComponent(false);
                updateVMData({});
                setIsAddActivityLoading(false);

                if (!_.isUndefined(getPolicyDetails)) {
                    getPolicyDetails();
                }
            }
        },
        [
            activityEntity,
            activityEntityId,
            activityFilter,
            addActivitySubmissionVM.value,
            createNewActivity,
            getActivitiesTileUpdatedCount,
            getActivityDataTable,
            getActivityOptions,
            getPolicyDetails,
            handleError,
            openActivityResponse,
            translator,
            selectedUser,
            authHeader,
            followupActivityDetails,
            isComponentValid,
            modalApi
        ]
    );

    const onClickComplete = useCallback(
        (data) => {
            const activityObj = openActivityResponse.find((obj) => obj.publicID === data);

            ActivitiesService.markActivityAsCompleted(data, authHeader).then((completeActivity) => {
                if (activityObj) {
                    activityObj.status = completeActivity.status;
                    activityObj.closeDate = completeActivity.closeDate;
                    activityObj.completedDate = completeActivity.completedDate;
                    activityObj.canComplete = false;
                }

                setPublicId(data);
            });
        },
        [authHeader, openActivityResponse]
    );

    const writeValue = useCallback(
        (value, path) => {
            const updateActivitySubmissionVM = viewModelService.clone(addActivitySubmissionVM);

            _.set(addActivitySubmissionVM.value, path, value);
            updateVMData(updateActivitySubmissionVM);
        },
        [addActivitySubmissionVM, viewModelService]
    );

    const tagType = !_.get(addActivitySubmissionVM.value, 'jobType');

    const {
        activitiesOverdue,
        activitiesComplete,
        activitiesFuture,
        activitiesThisWeek,
        activitiesToday,
        activitiesTomorrow
    } = splitActivitiesForTable(activityList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        statusFilter: {
            availableValues: activityOptions,
            value: activityFilter.statusFilter,
            disabled: showComponent
        },
        searchFilter: {
            value: activityFilter.searchFilter,
            disabled: showComponent
        },
        addNewActivityContainer: {
            visible: showComponent && !isAddActivityLoading
        },
        noActivityFound: {
            visible: displayNoActivities !== false
        },
        noActivitySeparator: {
            visible: displayNoActivities !== false
        },
        activitiesDetailGrid: {
            visible: displayNoActivities === false
        },
        type: {
            availableValues: newActivityTypeAvailableValues,
            value: _.get(addActivitySubmissionVM.value, 'jobType'),
            visible: !followupActivityDetails.isAddingFollowupActivity
        },
        followupActivityType: {
            visible: followupActivityDetails.isAddingFollowupActivity,
            value: followupActivityDetails.followupActivityType
        },
        priority: {
            availableValues: newActivityPriorityAvailableValues
        },
        assignedTo: {
            availableValues: userAvailableValues,
            disabled: tagType,
            value: selectedUser,
            showOptional: true,
        },
        addActivityButton: {
            visible: showActivityButton,
            disabled: showComponent
        },
        addNewActivityButton: {
            disabled: tagType
        },
        dueDate: {
            disabled: tagType,
            showErrors: isPageSubmitted,
            updateDateDto: () => writeValue(addActivitySubmissionVM.dueDate.value, 'dueDate'),
            dateDTO: addActivitySubmissionVM.dueDate,
        },
        subject: {
            disabled: tagType
        },
        escalationDate: {
            disabled: tagType,
            showErrors: isPageSubmitted,
            updateDateDto: () => writeValue(addActivitySubmissionVM.escalationDate.value, 'escalationDate'),
            dateDTO: addActivitySubmissionVM.escalationDate,
        },
        description: {
            disabled: tagType,
            showOptional: true,
        },
        loader: {
            loaded: !isAddActivityLoading
        },
        activitiesTableContainer: {
            activitiesOverdue,
            activitiesToday,
            activitiesTomorrow,
            activitiesThisWeek,
            activitiesFuture,
            activitiesComplete,
            displayNoActivities,
            createNote,
            editNote,
            deleteNote,
            onClickComplete,
            publicId,
            onAddFollowUpActivity: addFollowUpActivity,
            canCreateFollowUpActivity: canActcreate
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            activitytablecomponent: ActivityTableComponent
        },
        resolveCallbackMap: {
            handleLOBValueChange: (value) => handleFilterChange(value, 'statusFilter'),
            handleSearchValueChange: (value) => handleFilterChange(value, 'searchFilter'),
            addNewActivityClick,
            addNewActivityAddClick,
            addNewActivityCancelClick
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                addActivitySubmissionVM,
                id,
                path,
                overrideProps
            ),
        [addActivitySubmissionVM, overrideProps]
    );

    if (isLoading) {
        return <E1PLoader loaded={!isLoading} />;
    }

    return (
        <div className={cx(styles.summary)}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={addActivitySubmissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={handleChange}
                onValidationChange={onValidate}
                resolveValue={readValue}
            />
        </div>
    );
}

OpenActivitiesComponent.propTypes = {
    id: PropTypes.string.isRequired,
    authHeader: PropTypes.shape({}),
    getActivities: PropTypes.func.isRequired,
    activityEntityId: PropTypes.string.isRequired,
    activityEntity: PropTypes.string.isRequired,
    createNewActivity: PropTypes.func.isRequired,
    getPolicyDetails: PropTypes.func,
    getActivitiesTileUpdatedCount: PropTypes.func,
    authUserData: PropTypes.shape({
        userType: PropTypes.string
    })
};

OpenActivitiesComponent.defaultProps = {
    getActivitiesTileUpdatedCount: undefined,
    authHeader: undefined,
    authUserData: undefined,
    getPolicyDetails: undefined
};

export default withAuthenticationContext(OpenActivitiesComponent);
