import { defineMessages } from 'react-intl';

export default defineMessages({
    driverNotInHousehold: {
        defaultMessage: 'Not in Household',
        id: 'quoteandbind.ea.views.ea-driver-details.Not in Household:'
    },
    driverAddToQuote: {
        defaultMessage: 'Resident in Household',
        id: 'quoteandbind.ea.views.ea-driver-details.Resident in Household:'
    }
});
