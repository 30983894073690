import React, {
    useCallback, useState, useEffect
} from 'react';
import {
    set as _set
} from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './AddCreditExceptionComponent.module.scss';
import metadata from './AddCreditExceptionComponent.metadata.json5';
import './AddCreditExceptionComponent.messages';


import { Button } from '@jutro/legacy/components';


function AddCreditExceptionComponent(props) {
    const {
        creditVM,
        title,
        isOpen,
        onResolve,
        onReject,
        transactionEffectiveDate,
        viewModelService,
        editing
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation
    } = useValidation(
        'AddCreditExceptionComponent'
    );

    const [creditData, updateCreditData] = useState(creditVM);

    const scoreBasedOnType = {
        life: 98,
        other: 99
    };

    useEffect(() => {
        if (!editing) {
            const nextFormData = viewModelService.clone(creditData);

            _set(nextFormData, 'startDate', transactionEffectiveDate.value);

            const defaultEndDate = new Date(moment(transactionEffectiveDate.value).add(1, 'years'));
            const dateToPass = {
                year: defaultEndDate.getFullYear(),
                month: defaultEndDate.getMonth(),
                day: defaultEndDate.getDate()
            };

            _set(nextFormData, 'endDate', dateToPass);
            _set(nextFormData, 'reportOrderDate', new Date());
            updateCreditData(nextFormData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = viewModelService.clone(creditData);

            _set(nextFormData, path, value);
            updateCreditData(nextFormData);
        },
        [creditData, viewModelService]
    );

    const onExceptionClassChange = useCallback(
        async (newValue, path) => {
            const nextFormData = viewModelService.clone(creditData);

            _set(nextFormData, path, newValue);

            let newScore;

            if (newValue === 'life') {
                newScore = scoreBasedOnType.life;
            } else if (newValue === 'other') {
                newScore = scoreBasedOnType.other;
            }

            _set(nextFormData, 'score', newScore);
            updateCreditData(nextFormData);
        },
        [creditData, scoreBasedOnType.life, scoreBasedOnType.other, viewModelService]
    );


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'top',
            autoComplete: false
        },
        exceptionLifeEventId: {
            visible: creditData.exceptionType.value?.code === 'life',
            required: creditData.exceptionType.value?.code === 'life',
        },
        exceptionScoreId: {
            disabled: creditData.exceptionType.value?.code === 'life'
        },
        exceptionClassId: {
            onValueChange: onExceptionClassChange
        },
        exceptionStartDateId: {
            dateDTO: creditData.startDate
        },
        exceptionEndDateId: {
            dateDTO: creditData.endDate,
            updateDateDto: () => writeValue(creditData.endDate.value, 'endDate')
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                creditData,
                id,
                path,
                overrideProps
            ),
        [overrideProps, creditData]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
    };

    const onOk = useCallback(
        () => {
            if (!isComponentValid) {
                return false;
            }

            return onResolve(creditData);
        }, [isComponentValid, creditData, onResolve]
    );

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onOk}
            className={styles.smallPopUp}
        >
            <ModalHeader title={title} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={creditData}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    onValidationChange={setComponentValidation}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    Cancel
                </Button>
                <Button onClick={onOk} disabled={!isComponentValid}>
                        Add Credit Exception
                    </Button>
            </ModalFooter>
        </ModalNext>
    );
}

AddCreditExceptionComponent.propTypes = {
    creditVM: PropTypes.shape({
    }).isRequired,
    transactionEffectiveDate: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    title: PropTypes.string.isRequired,
    onReject: PropTypes.func,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    viewModelService: PropTypes.shape({
        create: PropTypes.func.isRequired,
        clone: PropTypes.func.isRequired
    }).isRequired,
    editing: PropTypes.bool.isRequired
};
AddCreditExceptionComponent.defaultProps = {
    onReject: undefined,
    isOpen: undefined,
    onResolve: undefined,
};
export default AddCreditExceptionComponent;
