import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import {
    get, set, filter, includes
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { PackageDifferenceComponent, ProtectiveDevices, PackageQualifyingEndorsementsComponent } from 'e1p-capability-policyjob-react';
import { ehCommonMessages } from 'e1p-platform-translations';
import { OptionalCoveragesPopup } from 'e1p-capability-policychange-eh-react';
import { ConsoleHelper } from 'e1p-portals-util-js';
import metadata from '../CoveragesPage.metadata.json5';
import messages from '../CoveragesPage.messages';
import styles from '../CoveragesPage.module.scss';

const OPTIONAL_COVERAGE_CATEGORIES = [
    'EHLineAddGrp',
    'EH_OptionalProp',
    'EH_OptionalLiability',
    'EH_PersonalProtection',
    'EH_HomeBusiness',
    'EH_AdditionalProp',
    'EH_Home',
    'EH_PersonalBelongings',
    // 'EH_EndorsementPackage'
];

function CoveragesPage(props) {
    const modalApi = useModal();
    const translator = useTranslator();
    const {
        wizardData: renewalVM,
        updateWizardData,
        authHeader
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const [selectedOptionalCoverages, updateSelectedOptionalCovs] = useState([]);
    const [coveragesModified, updateCoveragesModified] = useState(false);
    const [hasProtectiveDevices, setHasProtectiveDevices] = useState(false);
    const modifiers = get(renewalVM, 'lobData.homeowners_EH.modifiers.value', []);
    const paperLessIndValue = get(renewalVM, 'lobData.homeowners_EH.paperlessInd.value') === undefined
        ? false : get(renewalVM, 'lobData.homeowners_EH.paperlessInd.value');
    const policyState = get(renewalVM, 'baseData.policyAddress.state.value.code');

    const hasGoldOrPlatinumBoxVisible = !!(get(renewalVM, 'lobData.homeowners_EH.offerings.value[0].coverages.coverages', [])
        .find(({ codeIdentifier }) => includes(['EH_GoldCoverageBundle', 'EH_PlatinumCoverageBundle'], codeIdentifier)));

    const checkHasProtectiveDevice = (vm) => {
        if (get(vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value').length) {
            setHasProtectiveDevices(true);
        } else {
            setHasProtectiveDevices(false);
        }
    };

    const optionalCoveragesModified = useCallback(() => {
        updateCoveragesModified(!coveragesModified);
    }, [coveragesModified]);

    const onNext = useCallback(
        () => renewalVM,
        [renewalVM]
    );

    const showWhatsIncludedPopup = async () => {
        const offerings = get(renewalVM, 'lobData.homeowners_EH.offerings.children[0]');

        const platinumOrGoldClauseIndex = offerings.value.coverages.coverages.findIndex(
            (clause) => clause.codeIdentifier === 'EH_PlatinumCoverageBundle' || clause.codeIdentifier === 'EH_GoldCoverageBundle'
        );

        if (platinumOrGoldClauseIndex === -1) {
            const componentProps = {
                coverages: offerings.coverages.coverages.value
            };
            const result = await modalApi.showModal(
                <PackageQualifyingEndorsementsComponent {...componentProps} />
            );

            return result;
        }

        const componentProps = {
            lob: 'Homeowners_EH'
        };
        // IAP-83: Gold and Platinum will continue to use existing component
        const result = await modalApi.showModal(
            <PackageDifferenceComponent {...componentProps} />
        );

        return result;
    };

    const getTotalPremium = useCallback(() => {
        const payPlans = get(renewalVM.value, 'lobData.homeowners_EH.offerings[0].paymentPlans');
        const selectedPlan = payPlans.filter((plan) => plan.isSelected);
        const totalPremium = selectedPlan[0]?.total?.amount;

         if (!totalPremium && totalPremium !== 0) {
            return undefined;
        }

        return { currency: 'usd', amount: totalPremium };
    }, [renewalVM.value]);

    const policyType = get(renewalVM, 'value.lobData.homeowners_EH.policyType');
    const showOptionalCoveragePopOver = async () => {
        const offerings = get(renewalVM, 'lobData.homeowners_EH.offerings.children[0]');
        const goldBundledCov = offerings.value.coverages.coverages.find((clause) => clause.codeIdentifier === 'EH_GoldCoverageBundle');
        const platinumBundledCov = offerings.value.coverages.coverages.find((clause) => clause.codeIdentifier === 'EH_PlatinumCoverageBundle');
        const componentProps = {
            title: translator(messages.optionalCoverageLabel),
            iconClassType: false,
            showCancelBtn: false,
            showSaveBtn: true,
            // possible refactor later - update prop name
            //   by exporting reusable components we only need to make changes in 1 place
            policyChangeVM: renewalVM,
            updateWizardData,
            viewModelService,
            authHeader,
            optionalCoveragesModified,
            goldCovSelected: goldBundledCov ? goldBundledCov.selected : false,
            platinumCovSelected: platinumBundledCov ? platinumBundledCov.selected : false,
            translator,
            viewOnlyMode: true,
            hasGoldOrPlatinumBoxVisible,
            policyState
        };
        const result = await modalApi.showModal(<OptionalCoveragesPopup {...componentProps} />);

        return result;
    };

    const showProtectiveDevicePopup = useCallback(async () => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: true,
            showCancelBtn: true,
            submissionVM: renewalVM,
            viewModelService,
            translator,
            viewOnlyMode: true
        };
        const result = await modalApi.showModal(<ProtectiveDevices {...componentProps} />);

        return result;
    }, [renewalVM, translator, modalApi, viewModelService]);

    const onShowProtectiveDevicesPopup = useCallback(
        () => {
            try {
                showProtectiveDevicePopup().then((wrapperObj) => {
                    set(
                        renewalVM,
                        'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value',
                        get(wrapperObj.vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDevices.value', [])
                    );
                    set(
                        renewalVM,
                        'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd.value',
                        get(wrapperObj.vm, 'lobData.homeowners_EH.coverables.construction.homeProtectionDeviceDataSharingInd.value')
                    );
                    updateWizardData(renewalVM);
                }).catch((error) => {
                    ConsoleHelper(`user closed the modal. Error: ${error}`);
                });
            } catch {
                ConsoleHelper('user closed the modal');
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [showProtectiveDevicePopup]
    );

    const discountSubtotal = get(renewalVM, 'lobData.homeowners_EH.offerings.value[0].premiumSummary', [])
        .find((prem) => prem.premiumSummaryType === 'EHDiscountSavingsSubtotal')?.amount || 0;

    const primaryContentData = () => {
        if (!hasGoldOrPlatinumBoxVisible) {
            return translator(messages.optionalCoveragePackage);
        }

        return translator(messages.goldPlatinumPrimaryLabel);
    };
    const secondaryContentData = () => {
        if (!hasGoldOrPlatinumBoxVisible) {
            return (
                <React.Fragment>
                    {translator(messages.discountMessageSelect)}
                    <span className={styles.addIcon} />
                    {translator(messages.discountMessageLabel)}
                </React.Fragment>
            );
        }

        return translator(messages.goldPlatinumSecondaryLabel);
    };

    const getSelectedOptionalCov = () => {
        const newSubVM = viewModelService.clone(renewalVM);
        const tempSelectedOptionalCoverages = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).coverages.coverages.filter((coverage) => OPTIONAL_COVERAGE_CATEGORIES
                .indexOf(coverage.coverageCategoryCode.value) >= 0 && coverage.value.selected);
        const selectedExclusions = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).exclusions.filter((exclusion) => exclusion.selected.value === true);
        const selectedConditions = newSubVM.lobData.homeowners_EH.offerings
            .getElement(0).conditions.filter((condition) => condition.selected.value === true);

        const selectedOptionalCoveragesExclusionsAndConditions = [
            ...tempSelectedOptionalCoverages,
            ...selectedExclusions,
            ...selectedConditions
        ];

        return selectedOptionalCoveragesExclusionsAndConditions;
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            readOnly: true
        },
        premiumMightChangeMessageDiv: {
            visible: renewalVM.baseData.periodStatus.value.code !== 'Bound'
        },
        coveragesPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.loadingNextPageMessage)
        },
        coveragesPageContainer: {
            visible: true
        },
        policyTermComponent: {
            transactionVM: renewalVM,
            authHeader,
            updateWizardData,
            viewOnly: true
        },
        paymentOptionsID: {
            submissionVM: renewalVM,
            authHeader,
            updateWizardData,
            LOB: 'homeowners_EH',
            viewOnlyMode: true,
            isQuoteStale: false
        },
        monthlyPaymentScheduleComponent: {
            quoteID: get(renewalVM, 'jobID.value'),
            authHeader,
            transactionTotalAmount: getTotalPremium(),
            changeInCost: renewalVM.transactionCost?.value,
            startDate: renewalVM.baseData.periodStartDate.value,
            endDate: renewalVM.baseData.periodEndDate.value,
            jobTypeCode: renewalVM.baseData.jobType.value.code,
            offerings: get(renewalVM, 'lobData.homeowners_EH.offerings.value'),
            viewOnlyMode: true
        },
        goldPlatimumPrimaryHeadingHelpText: {
            disabled: false
        },
        buyNowButton: {
            visible: false
        },
        recalculateButton: {
            visible: false
        },
        whatsIncludedLink: {
            disabled: false
        },
        paperlessInd: {
            value: get(renewalVM, 'lobData.homeowners_EH.paperlessInd.value'),
            onValueChange: () => {},
            labelPosition: 'top',
        },
        isThisNewHomePurchase: {
            labelPosition: 'top',
            visible: (policyType === 'HO3' || policyType === 'HF9') && policyState !== 'MT',
            disabled: true
        },
        isProtectiveDevicesIcon: {
            onClick: onShowProtectiveDevicesPopup,
            visible: hasProtectiveDevices,
            icon: 'mi-visibility'
        },
        isProtectiveDevices: {
            value: hasProtectiveDevices,
            onValueChange: () => { },
            labelPosition: 'top'
        },
        isProtectiveDevicesMainGrid: {
            visible: policyType !== 'HO4'
        },
        paperlessEmailId: {
            visible: paperLessIndValue,
            onValueChange: () => {},
            labelPosition: 'top',
        },
        tpiOfTrust: {
            visible: policyType === 'HO3' && policyState === 'NC',
            labelPosition: 'top'
        },
        wizardPageHeader: { wizardSubmission: renewalVM },
        sectionICoverages: {
            loadingClause: false,
            uiGroup: 'Section I',
            hideLabel: true,
            labelPosition: 'top',
            viewOnlyMode: true,
            isQuoteStale: false
        },
        sectionIICoverages: {
            loadingClause: false,
            uiGroup: 'Section II',
            hideLabel: true,
            labelPosition: 'top',
            viewOnlyMode: true,
            isQuoteStale: false
        },
        deductibles: {
            loadingClause: false,
            uiGroup: 'Deductibles',
            labelPosition: 'top',
            viewOnlyMode: true,
            isQuoteStale: false
        },
        scheduleItemContainer: {
            loadingClause: false,
            categoryDisplayName: translator(messages.schedules)
        },
        addOrEditOptionalCovButton: {
            onClick: () => showOptionalCoveragePopOver(),
            content: translator(ehCommonMessages.viewOptionalCoverages),
            icon: 'mi-visibility',
            visible: getSelectedOptionalCov().length > 0
        },
        seeOptionalCovButton: {
            onClick: () => showOptionalCoveragePopOver(),
            content: translator(ehCommonMessages.viewOptionalCoverages),
            icon: 'mi-visibility'
        },
        supportingPolicyComponentContainer: {
            transactionVM: renewalVM,
            LOB: 'homeowners_EH',
            updateWizardData,
            viewOnly: true
        },
        GoldPlatinumGrid: {
            visible: true,
            className: (policyType === 'HO3' || policyType === 'HF9') ? 'goldPlatinumBox' : 'optionalCovBox'
        },
        goldPlatinumPrimaryHeading: {
            visible: policyType === 'HO3' || policyType === 'HF9',
            content: primaryContentData()
        },
        goldPlatinumSecondaryHeading: {
            visible: policyType === 'HO3' || policyType === 'HF9',
            content: secondaryContentData()
        },
        ehPolicyChangeBaseCoveragesId: {
            loadingClause: false,
            categoryDisplayName: translator(messages.coverages),
            isEditable: false
        },
        quoteProposalLinkContainer: {
            visible: false
        },
        protectiveDeviceRequireInfoMessageBasedOnCovADiv: {
            visible: false
        },
        ncrbDiscountAvailabilityMessageDiv: {
            visible: false
        },
        ehSurchargeListComponentId: {
            value: modifiers.filter(
                (item) => item.applied && item.modifierType !== "discount"
            )
        },
        ehDiscountsListComponentId: {
            visible: !!filter(modifiers, { applied: true, modifierType: 'discount' }).length,
            value: modifiers,
            discountsTotal: discountSubtotal
        },
        optionalCoverageSubtotalValue: {
            value: {
                currency: 'usd', amount: (() => {
                    // Optional coverage subtotal amount
                    const scheduleList = get(renewalVM, 'lobData.homeowners_EH.offerings.value[0].coverages.schedules', []);
                    let optionalCoverageSubtotalAmount = get(get(renewalVM, 'lobData.homeowners_EH.offerings.value[0].premiumSummary', [])
                        .find((prem) => prem.premiumSummaryType === 'EHOptionalCvgSubtotal'), 'amount', 0);

                    // Add schedule coverage total amount to optional coverage total
                    optionalCoverageSubtotalAmount += get(scheduleList.find((schedule) => get(schedule, 'premiumSummary.premiumSummaryType') === 'TotalScheduleCoverage'), 'premiumSummary.amount', 0);

                    return optionalCoverageSubtotalAmount;
                })()
            }
        },
        windstormMitigationDiscountComponentContainer: {
            visible: (policyType === 'HO3' && includes(['NC', 'SC', 'CT'], policyState) || (policyType === 'HF9' && policyState === 'CT'))
        },
        windstormMitigationDiscountComponent: {
            transactionVM: renewalVM,
            viewOnly: true,
            updateWizardData,
            setFieldsChangedOnCoveragePage: () => { },
            policyState
        },
        windstormMitigationDiscountInfoMessageDiv: {
            visible: false
        },
        paperlessEmailChangedMessageDiv: {
            visible: false
        },
        selectedOptionalCoverageDiv: {
            visible: getSelectedOptionalCov().length > 0
        },
        selectedOptionalCoverageLabel: {
            content: getSelectedOptionalCov().length > 0 ? translator(ehCommonMessages.selectedOptionalCovLabel) : translator(ehCommonMessages.emptyOptionalCovLabel)
        },
        totalPremiumID: {
            value: (() => getTotalPremium())()
        }
    };


    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeClause: () => { },
            onSyncCoverages: () => { },
            onChangeSubmissionAndSync: () => { },
            onScheduleChange: () => { },
            onValidate: () => {},
            whatsIncludedHandler: showWhatsIncludedPopup

        },
    };

    useEffect(() => {
        checkHasProtectiveDevice(renewalVM);
    }, [renewalVM]);

    useEffect(() => {
        updateCoveragesModified(false);

        const optionalSelectedCoverages = getSelectedOptionalCov();

        updateSelectedOptionalCovs({ selectedOptionalCoverages: optionalSelectedCoverages });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coveragesModified]);

    return (
        <WizardPage
            showPrevious
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{ ...selectedOptionalCoverages, ...renewalVM }}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={() => {}}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

CoveragesPage.propTypes = wizardProps;

export default withAuthenticationContext(CoveragesPage);
