import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { Tooltip, InlineLoader } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { SubmissionService } from 'e1p-capability-gateway';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import styles from './CopyQuoteComponent.module.scss';
import messages from './CopyQuoteComponent.messages'

import { Link } from '@jutro/router';

function CopyQuoteComponent(props) {
    const { quoteNumber, setCopyQuoteMessage, refreshOpenTransactions } = props;
    const translator = useTranslator();
    const [isCallingApi, setisCallingApi] = useState(false);
    const { authHeader } = useAuthentication();

    const callCopyQuote = useCallback(async (event) => {
        event.preventDefault();
        setisCallingApi(true);

        try {
            const response = await SubmissionService.copySubmission(quoteNumber, authHeader);

            if (response.exceptions_Ext) {
                setCopyQuoteMessage({ type: 'error', message: _.get(response.exceptions_Ext, '0.errorMessage') });
            }
            else if (response.jobNumber) {
                // call to refesh transaction data.
                if (setCopyQuoteMessage) {
                    setCopyQuoteMessage({ type: 'success', message: translator(messages.successMessage, { quoteNumber: response.jobNumber }) });
                }

                if (refreshOpenTransactions) {
                    refreshOpenTransactions();
                }
            }

            setisCallingApi(false);
        } catch (error) {
            setCopyQuoteMessage({ type: 'error', message: commonMessages.genericErrorMessage });
            setisCallingApi(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authHeader, quoteNumber, setCopyQuoteMessage, translator]);

    if (isCallingApi) {
        return (
            <InlineLoader
                id="InlineEsignLoader"
                loading={isCallingApi}
                size="medium"
                className={styles.colorStyle}
            />
        );
    }

    return (
        quoteNumber ? (
            <div className={styles.makeCopyTooltip} >
                <Tooltip
                    placement="top"
                    trigger="mouseenter"
                    id={`tooltipID${quoteNumber}`}
                    content={translator(messages.makeACopy)}
                >
                    <Link
                        id={`linkID${quoteNumber}`}
                        icon="mi-content-copy"
                        onClick={(event) => callCopyQuote(event)}
                        href="/"
                        className={styles.colorStyle}
                    />
                </Tooltip>
            </div>
        ) : <div />
    );
}

CopyQuoteComponent.propTypes = {
    quoteNumber: PropTypes.string.isRequired,
    setCopyQuoteMessage: PropTypes.func,
    refreshOpenTransactions: PropTypes.func

};

CopyQuoteComponent.defaultProps = {
    setCopyQuoteMessage: () => { },
    refreshOpenTransactions: () => { }
};
export default CopyQuoteComponent;
