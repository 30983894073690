import React, {
    useCallback,
} from 'react';
import { ehCommonMessages } from 'e1p-platform-translations';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from '../PropertyPage.metadata.json5';
import styles from '../PropertyPage.module.scss';

function ViewPropertyPage(props) {
    const translator = useTranslator();
    const { wizardData: policyChangeVM, updateWizardData } = props;

    const {
        onValidate
    } = useValidation('PropertyPage');

  

    const onNext = useCallback(
        async () => policyChangeVM,
        [policyChangeVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            recalculateReplacementCostClick: () => { },
            onValidate,
        },
        resolveClassNameMap: styles
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        replacementCostMissingNotificationDiv: {
            visible: false
        },
        noOutstandingChangesNotificationDiv: {
            visible: false
        },
        propertyPageLoader: {
            loaded: true,
            loadingMessage: translator(ehCommonMessages.loadingPrefill)
        },
        replacementCostRecalculate: {
            visible: false
        },
        replacementCostWithDollarrid: {
            content: (() => [
                    {
                        id: 'replacementCost',
                        component: 'Currency',
                        type: 'field',
                        componentProps: {
                            readOnly: true,
                            className: 'replacementCost',
                            path: 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount',
                            label: {
                                id: '',
                                defaultMessage: ''
                            },
                            layout: 'reversed',
                            dataType: 'number'
                        }
                    }
                ])()
        },
        e1pEHHF9PropertyDetailsComponent: {
            transactionVM: policyChangeVM,
            updateWizardData: () => {},
            isPageSubmitted: false,
            heatingSystemType: () => {},
            updateHeatingSystemType: () => {},
            coolingSystemType: () => {},
            updateCoolingSystemType: () => {},
            setIsReplacementCostStale: false,
            creditScoreMoreThan597: false,
            viewOnlyMode: true,
        },
    };


    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewPropertyPage.propTypes = wizardProps;
export default ViewPropertyPage;
