import { defineMessages } from 'react-intl';

export default defineMessages({
    transactionSummary: {
        id: 'e1p.gateway.view.transaction-detail-summary.Transaction Summary',
        defaultMessage: 'Transaction Summary',
    },
    renewalDirection: {
        id: 'e1p.gateway.view.transaction-detail-summary.Renewal Direction',
        defaultMessage: 'Renewal Direction',
    },
    createDate: {
        id: 'e1p.gateway.view.transaction-detail-summary.Create Date',
        defaultMessage: 'Create Date',
    },
    termEffectiveDate: {
        id: 'e1p.gateway.view.transaction-detail-summary.Term Effective Date',
        defaultMessage: 'Term Effective Date',
    },
    status: {
        id: 'e1p.gateway.view.transaction-detail-summary.Status',
        defaultMessage: 'Status',
    },
    policyInception: {
        id: 'e1p.gateway.view.transaction-detail-summary.Policy Inception',
        defaultMessage: 'Policy Inception',
    }
});
