import { defineMessages } from 'react-intl';

export default defineMessages({
    createLetterInformationMessage: {
        id: 'common.component.e1pRenewalNonRenewComponent.An interactive letter must be created to save a pre-renewal direction of Non-Renew.',
        defaultMessage: 'An interactive letter must be created to save a pre-renewal direction of Non-Renew.'
    },
    createLetterMailingDate: {
        id: 'common.component.e1pRenewalNonRenewComponent.Letter Mailing Date',
        defaultMessage: 'Letter Mailing Date'
    },
    createLetterButton: {
        id: 'common.component.e1pRenewalNonRenewComponent.CREATE LETTER',
        defaultMessage: 'CREATE LETTER'
    },
    editDirectionTitle: {
        id: 'common.component.e1pRenewalNonRenewComponent.Edit Status to Non-Renew',
        defaultMessage: 'Edit Status to Non-Renew'
    },
    updateDirectionMessage: {
        id: 'common.component.e1pRenewalNonRenewComponent.Updating direction to Non-Renew',
        defaultMessage: 'Updating direction to Non-Renew'
    },
    gettingNonRenewLetterMailingDate: {
        id: 'common.component.e1pRenewalNonRenewComponent.Getting Non-Renew Letter mailing date',
        defaultMessage: 'Getting Non-Renew Letter mailing date'
    },
    unableToGetNonRenewLetterMailingDate: {
        id: 'common.component.e1pRenewalNonRenewComponent.Unable to get Non-Renew Letter mailing date',
        defaultMessage: 'Sorry. We were unable to obtain letter mailing date.'
    }
});
