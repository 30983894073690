import React, {
    useCallback, useContext, useEffect,
    useMemo, useState, useRef
} from 'react';
import {
    get, set, find, findIndex,
    isEmpty, unset, pullAt, map
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { EndorsementService } from 'e1p-capability-policychange';
import {
    useLandingPageUtil,
    useDriverPageUtil,
    useCreditReportUtil
} from 'e1p-capability-hooks';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { commonMessages as e1pCommonMessages, eaCommonMessages, eaValidationAndInfoMessages } from 'e1p-platform-translations';
import { EAValidatonUtil, VehicleUtil } from 'e1p-portals-util-js';
import appConfig from 'app-config';
import metadata from './DriversPage.metadata.json5';

const LOB = 'personalAuto_EA';

function EADriversPage(props) {
    const modalApi = useModal();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        updateWizardSnapshot,
        setIsPageValid
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isCreditReportRequired, setIsCreditReportRequired] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [sniHasDefensiveCourse, setSNIHasDefensiveCourse] = useState(false);
    const [pniHasDefensiveCourse, setPNIHasDefensiveCourse] = useState(false);
    const [initialFilingformValue, setInitialFilingformValue] = useState([]);
    const [driversWithLicenseValidationError, setDriversWithLicenseValidationErrors] = useState([]);
    const hasNewSNIAdded = useRef(false);
    const hasSaveOperationCompleted = useRef(false);
    const [isAccountContactsFetched, setIsAccountContactsFetched] = useState(false);

    const policyState = get(policyChangeVM, 'baseData.policyAddress.state.value.code');
    const [isExistingDriverDeleted, setIsExistingDriverDeleted] = useState(false);


    const {
        setSpouseAsSNI,
        checkMoreThanOneSNIRoles,
        setRelationShipStatusForSNI,
        isPNIAndSNIValidForDefensiveDiscount,
        isDefensiveDriverDiscountAvailableForDriver,
        isLicenseNumberRequired,
        getDriverAge,
        isFinancialResponsibilityFormAvailableForDriver,
        checkDuplicateDrivers
    } = useDriverPageUtil(
        policyChangeVM,
        updateWizardData,
        viewModelService,
        setValidationErrors,
        translator
    );

    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation
        // registerInitialComponentValidation
    } = useValidation('EADriversPage');

    const {
        showReportPopup
    } = useCreditReportUtil(
        translator,
        LOB
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);

    /**
    * Setting current wizard page is valid or not,using this info
    * to show popup(The changes you have made on this page may be lost.)
    * on click of previous or page jump
    */
    useEffect(() => {
        setIsPageValid(isComponentValid);
    }, [setIsPageValid, isComponentValid]);

    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    const { authHeader, authUserData } = useAuthentication();

    policyChangeVM.flowStepIDs_Ext.value = ['driver', 'namedinsured'];
    policyChangeVM.entryCompletionStepIDs_Ext.value = ['driver', 'namedinsured'];

    const createDriverVM = useCallback(
        (driver, isSNI) => {
            const driverObj = {
                person: driver.person.value
            };
            const {
                _dtoName,
                _xCenter
            } = policyChangeVM.lobData.personalAuto_EA.coverables.drivers;
            const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);

            if (isSNI) { set(driverVM, 'relationshipToNI', driver.relationshipToNI.value); }

            policyChangeVM.lobData.personalAuto_EA.coverables.drivers.pushElement(driverVM);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData, viewModelService]
    );

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';
        const drivers = get(policyChangeVM, `${driversPath}.value`);

        if (isEmpty(drivers)) {
            createDriverVM(policyChangeVM.lobData.personalAuto_EA.primaryNamedInsured, false);

            if (policyChangeVM.lobData.personalAuto_EA.secondaryNamedInsured.person) {
                createDriverVM(
                    policyChangeVM.lobData.personalAuto_EA.secondaryNamedInsured, true
                );
            }

            // If it is first time visiting the page, need to call credit report
            setIsCreditReportRequired(true);
        } else {
            // User chose to change PNI to a new Contact
            const pni = get(policyChangeVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
            const pniDriver = drivers.find((driver) => driver.person.publicID === pni.person.publicID);

            if (!pniDriver) {
                createDriverVM(policyChangeVM.lobData.personalAuto_EA.primaryNamedInsured, false);
                setIsCreditReportRequired(true);
            }

            // Submission already started and user goes back to add SNI
            const sniPublicID = policyChangeVM.lobData.personalAuto_EA.secondaryNamedInsured.person
                ? policyChangeVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value : undefined;
            const sniDriver = drivers.find((driver) => driver.person.publicID === sniPublicID);

            // Populate SNI as a driver
            if (sniPublicID && !sniDriver) {
                createDriverVM(policyChangeVM.lobData.personalAuto_EA.secondaryNamedInsured, true);
                // If secondary named insured person is added, need to call credit report
                setIsCreditReportRequired(true);
            } else if (sniPublicID && sniDriver) {
                // user went back to insured page and added sni
                // on insured page we are adding sni in drivers node
                // so sni will be present but policyDriverRoleType will be undefined
                if (!get(sniDriver, 'policyDriverRoleType')) {
                    setIsCreditReportRequired(true);
                }
            }
        }

        setIsPageInitialized(true);
        // No array dependencies needed in this hook.
        // The logic of initializing drivers data needs to be executed only once
        // when landing into drivers page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Set initial filingform value for each driver
        const driverListPath = 'lobData.personalAuto_EA.coverables.drivers.value';
        const driverList = get(policyChangeVM, driverListPath, []);

        const tempInitialFilingformValue = map(driverList, (driver) => ({
            fixedId: get(driver, 'fixedId'),
            filingForm: get(driver, 'financialResponsibility.filingForm')
        }));

        setInitialFilingformValue(tempInitialFilingformValue);
        // Need to initialize the list only on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';
        const drivers = get(policyChangeVM, `${driversPath}`);
        const pniPublicID = policyChangeVM
            .lobData.personalAuto_EA.primaryNamedInsured.person.publicID.value;
        const sniPublicID = policyChangeVM
            .lobData.personalAuto_EA.secondaryNamedInsured.person
            ? policyChangeVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value
            : undefined;

        let pni = find(drivers.children, (driver) => driver.person.publicID.value === pniPublicID);
        const pniIndex = findIndex(drivers.children, (driver) => driver.person.publicID.value === pniPublicID);

        // Unset role. Fixes bug where you come back to drivers from a further page
        //   and ArrayUpdater has changed the ordering of your drivers and role has persisted
        //   so you end up seeing both  drivers as PNI
        drivers.children.forEach((driverNode) => {
            unset(driverNode, 'role');
        });

        // Put PNI back as first driver
        if (pniIndex > 0) {
            pni = viewModelService.clone(pni);
            pullAt(drivers.value, pniIndex);
            drivers.value.unshift(pni.value);
            updateWizardSnapshot(policyChangeVM);
        }


        if (sniPublicID) {
            const sni = find(drivers.children, (driver) => driver.person.publicID.value === sniPublicID);

            if (sni) {
                set(sni, 'value.role', 'sni');
            }
        }

        if (pni) {
            set(pni, 'value.role', 'pni');
        }
    }, [policyChangeVM, updateWizardSnapshot, viewModelService]);

    const getCreditReportStatus = useCallback(
        (quoteID) => showReportPopup(
            quoteID,
            authHeader,
            policyChangeVM
        ),
        [authHeader, policyChangeVM, showReportPopup]
    );

    const doSave = useCallback(
        async (isQuote) => {
            unset(policyChangeVM.value, 'bindData');

            const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
            const driversList = get(policyChangeVM, driversPath);

            driversList.forEach((driver, index) => {
                if (
                    isEmpty(driver.person.displayName)
                    || !driver.person.displayName.includes(
                        `${driver.person.firstName} ${driver.person.lastName}`
                    )
                ) {
                    const displayname = `${driver.person.firstName} ${driver.person.lastName}`;

                    set(policyChangeVM, `${driversPath}[${index}].person.displayName`, displayname);
                }

                set(policyChangeVM,
                    `${driversPath}[${index}].isInsurancePolicyRefused`,
                    'no');
                set(policyChangeVM,
                    `${driversPath}[${index}].isDrivingPrivilegeRevoked`,
                    'no');
            });

            if (!isQuote) {
                policyChangeVM.value = await EndorsementService.saveEndorsement(
                    [policyChangeVM.value],
                    authHeader
                );
                updateWizardData(policyChangeVM);
            }

            return policyChangeVM.value;
        },
        [authHeader, policyChangeVM, updateWizardData]
    );

    /**
 * If state requires drivers assignment and this page has been visited, we will
 *   take the user to the drivers assignment page to check for updates
 */
    const needsDriverAssignmentUpdate = useCallback(
        async () => {
            const enabledStates = appConfig.driverAssignmentStates;
            const driverAssignmentStepIndex = steps.findIndex((step) => step.path === '/assignment');
            const shouldSkipDriverAssignmentPage = VehicleUtil.shouldSkipDriverAssignmentPage(policyChangeVM);

            /**
            * If driver assignment is available for given state and 
            * shouldSkipDriverAssignmentPage indicator(given job has one driver and one vehicle and vehicle is not garaged out fo state more than 6 months) is true 
            * then perform driver assignment for given vehicle
            */
            if (driverAssignmentStepIndex > 0 && shouldSkipDriverAssignmentPage) {
                VehicleUtil.setDriverAssignmentWhenOneDriverOneVehiclePresent(policyChangeVM, updateWizardData);
            }

            if (enabledStates.includes(policyState)) {
                policyChangeVM.value = await EndorsementService.saveEndorsement(
                    [policyChangeVM.value],
                    authHeader
                );
                updateWizardData(policyChangeVM);

                const validationErrs = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const hasNoConflicts = isEmpty(get(policyChangeVM, 'value.conflicts', []));
                const hasNoExceptions = isEmpty(get(policyChangeVM, 'baseData.exceptions_Ext.value', []));

                // Need to stay on the page if field issues
                if (validationErrs.length === 0 && hasNoConflicts && hasNoExceptions) {
                    if (driverAssignmentStepIndex > 0 && shouldSkipDriverAssignmentPage) {
                        // keep the normal flow no need to jump to any page
                        return false;
                    }

                    updateWizardSnapshot(policyChangeVM);
                    jumpTo(driverAssignmentStepIndex, true);

                    return true;
                }

                // needs to but has errors so don't jump
                return true;
            }

            return false;
        },
        [authHeader, jumpTo, policyChangeVM, policyState, steps, updateWizardData, updateWizardSnapshot],
    );

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => {
            setValidationErrors([]);

            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            // IAP-4716, check duplicate drivers
            const hasDuplicateDrivers = checkDuplicateDrivers();

            if (hasDuplicateDrivers) {
                setIsSavingEndorsement(false);

                return false;
            }

            const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
            const driversList = get(policyChangeVM, driversPath);

            driversList.forEach((driver) => {
                /**
                 * E1PAP1PC-12823
                 * If relationshipTo Pni is spouse then that driver should be sni
                 * Marking driver as sni and function setSpouseAsSNI will convert it to sni
                 */
                if (driver.relationshipToNI === 'spouse') {
                    set(driver, 'role', 'sni');
                }
            });

            // E1PAP1PC-12823
            // Checks if more than one sni roles are present and if yes then sets page level error
            const hasMoreThanOneSNIRoles = checkMoreThanOneSNIRoles();

            if (hasMoreThanOneSNIRoles) {
                setIsSavingEndorsement(false);

                return false;
            }

            /**
            * IAP-3005 : removing prompt to set Spouse as SNI
            * When Spouse is added as driver we will add it as SNI on the policy
            */
            setSpouseAsSNI(hasNewSNIAdded);

            // E1PAP1PC-12823
            // syncs relationship status for sni
            setRelationShipStatusForSNI();

            const changedFilingFormFor = [];
            const newInitialValue = [];

            // Backend workaround for saving change of filing indicator form
            // Check for change of Filing Form Value
            driversList.forEach((driver) => {
                const fixedId = get(driver, 'fixedId');
                const newFilingForm = get(driver, 'financialResponsibility.filingForm');
                const newFilingInd = get(driver, 'financialResponsibility.filingInd');
                const initialFilingForm = find(initialFilingformValue, { fixedId });

                // Find only ones which are changed with indicator still on, for example SR22 to FR44 will be added but SR22 to NO won't be added
                if (initialFilingForm && initialFilingForm.filingForm && newFilingInd && initialFilingForm.filingForm !== newFilingForm) {
                    changedFilingFormFor.push({ fixedId, filingForm: newFilingForm });
                    // Mark filing indicator as false - backend workaround
                    set(driver, 'financialResponsibility.filingInd', false);
                    set(driver, 'financialResponsibility.filingForm', initialFilingForm.filingForm);
                }

                newInitialValue.push({ fixedId, filingForm: newFilingForm });
            });

            // If there are changes in any of driver save with Indicator set to No
            if (changedFilingFormFor.length > 0) {
                const saveDriverFilingFormResponse = await doSave(false);

                policyChangeVM.value = saveDriverFilingFormResponse;

                const newDriversList = get(policyChangeVM, driversPath, []);

                newDriversList.forEach((driver) => {
                    const currentChangeFilingForm = find(changedFilingFormFor, { fixedId: driver.fixedId });

                    if (currentChangeFilingForm) {
                        set(driver, 'financialResponsibility.filingInd', true);
                        set(driver, 'financialResponsibility.filingForm', currentChangeFilingForm.filingForm);
                    }
                });
            }

            // Set it to new Filing Form values on save
            setInitialFilingformValue(newInitialValue);

            try {
                // do save defaults required fields not on UI
                // Page should never jump without setting them
                const saveResponse = await doSave(false);

                policyChangeVM.value = saveResponse;
                hasSaveOperationCompleted.current = true;

                if (isCreditReportRequired || hasNewSNIAdded.current) {
                    if (!(await getCreditReportStatus(get(policyChangeVM, 'jobID.value')))) {
                        setIsSavingEndorsement(false);

                        return false;
                    }
                }

                // needs to go after field defaults and credit check
                const shouldEndFunctionExecution = await needsDriverAssignmentUpdate();

                if (shouldEndFunctionExecution) {
                    setIsSavingEndorsement(false);

                    return false;
                }

                setIsSavingEndorsement(false);

                return policyChangeVM;
            } catch {
                setIsSavingEndorsement(false);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: commonMessages.genericError,
                    message: commonMessages.genericErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });

                return false;
            }
        },
        [checkDuplicateDrivers, modalApi, checkMoreThanOneSNIRoles, doSave, getCreditReportStatus, initialFilingformValue, isComponentValid, isCreditReportRequired, needsDriverAssignmentUpdate, policyChangeVM, setRelationShipStatusForSNI, setSpouseAsSNI]
    );

    const onSave = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (hasSaveOperationCompleted.current && isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    /**
                     * E1PAP1PC-14986 :
                     * wizardData and wizardSnapshot not being equal due to
                     * some defaulting on each page so doing this temp fix
                     */
                    const pniDriver = policyChangeVM.value.lobData.personalAuto_EA
                        .coverables.drivers.find((driver) => driver.role === 'pni');

                    if (pniDriver) {
                        set(pniDriver, 'relationshipToNI', undefined);
                    }

                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [isComponentValid, onNext, policyChangeVM, updateWizardSnapshot]
    );

    // this grabs the latest element on the page -- this is to make sure this element has been loaded
    const latestDriverElement = document.getElementById(`driverFirstName${policyChangeVM.lobData.personalAuto_EA.coverables.drivers.children.length - 1}`);

    useEffect(() => {
        // indexStale set in the add driver function
        // once latest element is loaded and a new driver is added
        // we check if the button should be visible
        if (latestDriverElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestDriverElement]);

    // IAP-4225, if existing driver is removed, then we need to call save API
    useEffect(() => {
        const callOnSave = async () => {
            await onSave();
        }

        if (isExistingDriverDeleted && isComponentValid) {
            callOnSave();
            setIsExistingDriverDeleted(false);
        }
    }, [isComponentValid, isExistingDriverDeleted, onSave]);

    /**
     * Generate property overrides for dynamic components on this Jutro component.
     */
    const generateOverrides = useCallback(
        () => {
            const overrideProps = {};

            overrideProps.WizardSingleErrorComponent = {
                issuesList: validationErrors
            };

            return overrideProps;
        },
        [validationErrors]
    );

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (!isPageInitialized) {
            loadingMessage = translator(eaCommonMessages.loadingPrefillDataMessage);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isSavingEndorsement || isSkipping) {
            loadingMessage = translator(eaCommonMessages.loadingNextPageMessage);
        }

        return loadingMessage;
    }, [isPageInitialized, isSavingCurrentPageChanges, isSavingEndorsement, isSkipping, translator]);

    const getMessageForFinancialRespBasedonState = () => {
        let financialRespMessage = translator(eaValidationAndInfoMessages.sr22WithCompOnly);

        if (policyState === 'VA') {
            financialRespMessage = translator(eaValidationAndInfoMessages.sr22fr44WithCompOnly);
        }

        return financialRespMessage;
    };

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        driversPageLoadingIndicator: {
            loaded: isPageInitialized && !isSavingEndorsement
                && !isSkipping && !isSavingCurrentPageChanges,
            text: getLoadingIndicatorMessage
        },
        driverPageMainContainer: {
            visible: isPageInitialized && !isSavingEndorsement
                && !isSkipping && !isSavingCurrentPageChanges
        },
        driverHeaderInfoID: {
            visible: isPageInitialized && !isSavingEndorsement && !isSkipping
        },
        addDriver: {
            accountNumber: get(policyChangeVM, 'baseData.accountNumber.value'),
            authHeader,
            excludedContacts: (() => {
                const drivers = get(policyChangeVM.value, 'lobData.personalAuto_EA.coverables.drivers', []);

                return drivers.map((driver) => driver.person.publicID);
            })(),
            viewModelService,
            setCheckScrolling,
            setIndexStale,
            setIsAccountContactsFetched,
            isAccountContactsFetched
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('eadrivercontainer')
        },
        defensiveDriverDiscountNotificationDiv: {
            visible: isPNIAndSNIValidForDefensiveDiscount() && ((pniHasDefensiveCourse && !sniHasDefensiveCourse)
                || (!pniHasDefensiveCourse && sniHasDefensiveCourse))
        },
        financialResponsibilityWithCompOnlyMessageDiv: {
            visible: EAValidatonUtil.isFinancialResponsibilitySelectedWithAllCompOnlyVehicle(policyChangeVM)
        },
        financialResponsibilityWithCompOnlyMessage: {
            message: getMessageForFinancialRespBasedonState()
        },
        nationalGuardWithDefensiveDriverMessageDiv: {
            visible: EAValidatonUtil.isNationalGuardSelectedWithoutDefensiveDriver(policyChangeVM),
        },
        EADriverGrid: {
            key: get(policyChangeVM, 'lobData.personalAuto_EA.coverables.drivers', []).length,
            drivers: get(policyChangeVM, 'lobData.personalAuto_EA.coverables.drivers', []),
            path: 'lobData.personalAuto_EA.coverables.drivers.children',
            onValidate,
            viewModelService,
            showErrors: isPageSubmitted,
            policyState: get(policyChangeVM, 'baseData.policyAddress.state.value.code'),
            driverPageDisregardFieldValidation: disregardFieldValidation,
            authHeader,
            checkLicenseRequired: (driver) => isLicenseNumberRequired(policyChangeVM, driver),
            driversWithLicenseValidationError,
            setDriversWithLicenseValidationErrors,
            getDriverAge,
            periodStartDate: get(policyChangeVM, 'baseData.periodStartDate.value'),
            priorPolicyList: get(policyChangeVM, 'lobData.personalAuto_EA.priorPolicyUpdates'),
            isFinancialResponsibilityFormAvailableForDriver,
            isNewSubmission: false,
            onValidationChange: onValidate,
            authUserData,
            transactionVM: policyChangeVM,
            isDefensiveDriverDiscountAvailableForDriver,
            setSNIHasDefensiveCourse,
            setPNIHasDefensiveCourse,
            updateWizardData,
            setCheckScrolling,
            setIsExistingDriverDeleted,
            showValidationMessage: () => {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            },
            setIsAccountContactsFetched
        },
        ...generateOverrides()
    };

    const saveAndQuote = useCallback(
        async () => {
            const saveResponse = await doSave(true);

            policyChangeVM.value = saveResponse;
            updateWizardData(policyChangeVM);

            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteResponse);
            updateWizardData(policyChangeVM);

            if (isCreditReportRequired || hasNewSNIAdded.current) {
                if (!(await getCreditReportStatus(get(policyChangeVM, 'jobID.value')))) {
                    setIsSavingEndorsement(false);

                    return false;
                }
            }

            return policyChangeVM.value;
        },
        [authHeader, doSave, getCreditReportStatus, isCreditReportRequired, policyChangeVM, updateWizardData]
    );

    const showCustom = true;

    const onCustom = useCallback(
        async () => {
            setValidationErrors([]);

            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            // IAP-4716, check duplicate drivers
            const hasDuplicateDrivers = checkDuplicateDrivers();

            if (hasDuplicateDrivers) {
                setIsSavingEndorsement(false);

                return false;
            }

            const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
            const driversList = get(policyChangeVM, driversPath);

            driversList.forEach((driver) => {
                /**
                 * E1PAP1PC-12823
                 * If relationshipTo Pni is spouse then that driver should be sni
                 * Marking driver as sni and function setSpouseAsSNI will convert it to sni
                 */
                if (driver.relationshipToNI === 'spouse') {
                    set(driver, 'role', 'sni');
                }
            });

            // E1PAP1PC-12823
            // Checks if more than one sni roles are present and if yes then sets page level error
            const hasMoreThanOneSNIRoles = checkMoreThanOneSNIRoles();

            if (hasMoreThanOneSNIRoles) {
                setIsSavingEndorsement(false);

                return false;
            }

            /**
             * IAP-3005 : removing prompt to set Spouse as SNI
             * When Spouse is added as driver we will add it as SNI on the policy
             */
            setSpouseAsSNI(hasNewSNIAdded);

            // E1PAP1PC-12823
            // syncs relationship status for sni
            setRelationShipStatusForSNI();

            const saveAndQuoteResponse = await saveAndQuote();

            if (!saveAndQuoteResponse) {
                setIsSavingEndorsement(false);

                return false;
            }

            policyChangeVM.value = saveAndQuoteResponse;
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrs = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
            const hasConflicts = !isEmpty(get(policyChangeVM, 'value.conflicts', []));

            // Need to stay on the page if field issues
            if (validationErrs.length === 0 || hasConflicts) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            // Moving below save and check for driver page errors but above the jump to
            const shouldEndFunctionExecution = await needsDriverAssignmentUpdate();

            if (shouldEndFunctionExecution) {
                setIsSavingEndorsement(false);

                return false;
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [checkDuplicateDrivers, checkMoreThanOneSNIRoles, getLandingPageIndexForQuotedJob, isComponentValid, jumpTo, needsDriverAssignmentUpdate, policyChangeVM, saveAndQuote, setRelationShipStatusForSNI, setSpouseAsSNI, steps, updateWizardData, updateWizardSnapshot]
    );


    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
            metadata.pageContent,
            policyChangeVM,
            id,
            path,
            overrideProps
        ),
        [overrideProps, policyChangeVM]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate,
            onPrefillData: undefined,
            onViewModelService: viewModelService
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showCustom={showCustom}
            onCustom={onCustom}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

EADriversPage.propTypes = wizardProps;
export default EADriversPage;
