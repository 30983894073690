import { defineMessages } from 'react-intl';

export default defineMessages({
    date: {
        id: 'components.propertyLosses.Date',
        defaultMessage: 'Date'
    },
    code: {
        id: 'components.propertyLosses.Code',
        defaultMessage: 'Code'
    },
    description: {
        id: 'components.propertyLosses.Description',
        defaultMessage: 'Description'
    },
    amount: {
        id: 'components.propertyLosses.Amount',
        defaultMessage: 'Amount'
    },
    source: {
        id: 'components.propertyLosses.Source',
        defaultMessage: 'Source'
    },
    cat: {
        id: 'components.propertyLosses.CAT',
        defaultMessage: 'CAT'
    },
    removeAllLossesTitle: {
        id: 'quoteandbind.eh.controllers.underwriting-details.Remove All Losses?',
        defaultMessage: 'Remove All Losses?'
    },
    removeAllLossesDescription: {
        id: 'quoteandbind.eh.underwriting-details.remove-loss.Please note that this action will remove all entered losses.',
        defaultMessage: 'Please note that this action will remove all entered losses.'
    },
    removeLossTitle: {
        id: 'quoteandbind.eh.controllers.underwriting-details.Remove Loss?',
        defaultMessage: 'Remove Loss?'
    },
    removeLossDescription: {
        id: 'quoteandbind.eh.controllers.underwriting-details.The selected loss will be removed.',
        defaultMessage: 'The selected loss will be removed.'
    },
});
