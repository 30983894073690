import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import EARenewalInsuredDetailsPage from './pages/InsuredDetails/InsuredDetailsPage';
import EARenewalRiskAnalysisPage from './pages/RiskAnalysis/RiskAnalysisPage';
import EARenewalQuotePage from './pages/Coverages/CoveragePage';
import EARenewalVehiclePage from './pages/Vehicles/VehiclesPage';
import EARenewalDriverPage from './pages/Drivers/DriversPage';
import EARenewalDriverAssignmentPage from './pages/DriverAssignment/DriverAssignmentPage';
import EARenewalChangeDiff from './pages/RenewalChangeDiff/RenewalChangeDiffPage';
import EARenewalThirdPartyInterestPage from './pages/ThirdPartyInterest/ThirdPartyInterestPage';
import EARenewalClauseComponent from './components/EARenewalClauseComponent';
import EARenewalClausesComponentVM from './components/EARenewalClausesComponentVM';
import EARenewalSingleClauseComponentVM from './components/EARenewalSingleClauseComponentVM';
import EARenewalPremiumBoxComponent from './components/EARenewalPremiumBoxComponent/EARenewalPremiumBoxComponent';
import ViewEARenewalInsuredDetailsPage from './pages/InsuredDetails/viewOnly/ViewInsuredDetailsPage';
import ViewEARenewalDriverPage from './pages/Drivers/viewOnly/ViewDriversPage';
import ViewEARenewalVehiclePage from './pages/Vehicles/viewOnly/ViewVehiclesPage';
import ViewEARenewalRiskAnalysisPage from './pages/RiskAnalysis/viewOnly/ViewRiskAnalysisPage';
import ViewEARenewalQuotePage from './pages/Coverages/viewOnly/ViewCoveragePage';
import ViewEARenewalThirdPartyInterestPage from './pages/ThirdPartyInterest/viewOnly/ViewThirdPartyInterestPage';
import ViewEARenewalChangeDiff from './pages/RenewalChangeDiff/viewOnly/ViewRenewalChangeDiffPage';
import ViewEARenewalDriverAssignmentPage from './pages/DriverAssignment/ViewOnly/ViewDriverAssignmentPage';

setComponentMapOverrides(
    {
        EARenewalInsuredDetailsPage: { component: 'EARenewalInsuredDetailsPage' },
        EARenewalQuotePage: { component: 'EARenewalQuotePage' },
        EARenewalThirdPartyInterestPage: { component: 'EARenewalThirdPartyInterestPage' },
        EARenewalVehiclePage: { component: 'EARenewalVehiclePage' },
        EARenewalDriverPage: { component: 'EARenewalDriverPage' },
        EARenewalDriverAssignmentPage: { component: 'EARenewalDriverAssignmentPage' },
        EARenewalRiskAnalysisPage: { component: 'EARenewalRiskAnalysisPage' },
        EARenewalChangeDiff: { component: 'EARenewalChangeDiff' },
        EARenewalClauseComponent: { component: 'EARenewalClauseComponent' },
        EARenewalClausesComponentVM: { component: 'EARenewalClausesComponentVM' },
        EARenewalSingleClauseComponentVM: { component: 'EARenewalSingleClauseComponentVM' },
        EARenewalPremiumBoxComponent: { component: 'EARenewalPremiumBoxComponent' },
        ViewEARenewalInsuredDetailsPage: { component: 'ViewEARenewalInsuredDetailsPage' },
        ViewEARenewalDriverPage: { component: 'ViewEARenewalDriverPage' },
        ViewEARenewalVehiclePage: { component: 'ViewEARenewalVehiclePage' },
        ViewEARenewalRiskAnalysisPage: { component: 'ViewEARenewalRiskAnalysisPage' },
        ViewEARenewalQuotePage: { component: 'ViewEARenewalQuotePage' },
        ViewEARenewalThirdPartyInterestPage: { component: 'ViewEARenewalThirdPartyInterestPage' },
        ViewEARenewalChangeDiff: { component: 'ViewEARenewalChangeDiff' },
        ViewEARenewalDriverAssignmentPage: { component: 'ViewEARenewalDriverAssignmentPage' }
    },
    {
        EARenewalInsuredDetailsPage,
        EARenewalQuotePage,
        EARenewalThirdPartyInterestPage,
        EARenewalVehiclePage,
        EARenewalDriverPage,
        EARenewalRiskAnalysisPage,
        EARenewalChangeDiff,
        EARenewalClauseComponent,
        EARenewalClausesComponentVM,
        EARenewalSingleClauseComponentVM,
        EARenewalPremiumBoxComponent,
        ViewEARenewalInsuredDetailsPage,
        ViewEARenewalDriverPage,
        ViewEARenewalVehiclePage,
        ViewEARenewalRiskAnalysisPage,
        ViewEARenewalQuotePage,
        ViewEARenewalThirdPartyInterestPage,
        ViewEARenewalChangeDiff,
        EARenewalDriverAssignmentPage,
        ViewEARenewalDriverAssignmentPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as EARenewalWizard } from './EARenewalWizard';
