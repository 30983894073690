import { defineMessages } from 'react-intl';

export default defineMessages({
    chooseMaterialType: {
        id: 'quoteandbind.eh.views.component.insideWallMaterial.Choose Material Type',
        defaultMessage: 'Choose Material Type',
    },
    percentage: {
        id: 'quoteandbind.eh.views.component.insideWallMaterial.Percentage',
        defaultMessage: 'Percentage',
    }
});
