import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from './ManualPropertyLossesInlineComponent.metadata.json5';
import styles from './ManualPropertyLossesInlineComponent.module.scss';
import './ManualPropertyLossesInlineComponent.messages';


function ManualPropertyLossesInlineComponent(props) {
    const {
        submissionVM,
        updateWizardData,
        viewModelService
    } = props;
    const [submissionVMForLosses, updateSubmissionVMForLosses] = useState(submissionVM);
    const {
        disregardFieldValidation,
        onValidate
    } = useValidation('ManualPropertyLosses');

    useEffect(() => {
        const currentPropLosses = _.get(submissionVM, 'lobData.homeowners_EH.manualPropertyLossRecords');

        if (currentPropLosses.value === undefined) {
            _.set(submissionVM, 'lobData.homeowners_EH.manualPropertyLossRecords.value', []);
        }

        updateWizardData(submissionVM);
        updateSubmissionVMForLosses(submissionVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addPropertyLoss = useCallback(() => {
        const propertyLoss = {
            lossType: '',
            lossTypeDesc: '',
            source: {
                sourceType: ''
            }
        };
        const {
            _xCenter,
            _dtoName,
        } = submissionVMForLosses.lobData.homeowners_EH.manualPropertyLossRecords;
        const propertyLossVM = viewModelService.create(propertyLoss, _xCenter, _dtoName);

        submissionVMForLosses.lobData.homeowners_EH.manualPropertyLossRecords.value.push(
            propertyLossVM.value
        );

        const newsubmissionVMForLosses = viewModelService.clone(submissionVMForLosses);

        updateSubmissionVMForLosses(newsubmissionVMForLosses);
        updateWizardData(newsubmissionVMForLosses);
    }, [viewModelService, updateSubmissionVMForLosses, updateWizardData, submissionVMForLosses]);

    const removePropertyLoss = useCallback((evt) => {
        const manualPropertyLossesListPath = 'lobData.homeowners_EH.manualPropertyLossRecords.value';
        const manualPropertyLoss = evt.path;
        const manualPropertyLossMaterial = _.get(submissionVMForLosses, manualPropertyLoss).value;
        const manualPropertyLossList = _.get(submissionVMForLosses, manualPropertyLossesListPath);
        const manualPropertyLossIndex = manualPropertyLossList
            .findIndex((manualPropertyLossFromList) => _.isEqual(manualPropertyLossFromList, manualPropertyLossMaterial));

        const newManualPropertyLossList = _.remove(manualPropertyLossList, (manualPropertyLossListFromList) => (
            _.isEqual(manualPropertyLossListFromList, manualPropertyLossMaterial)));

        _.set(submissionVMForLosses, newManualPropertyLossList, manualPropertyLossesListPath);
        disregardFieldValidation(`ehPropertyLoss${manualPropertyLossIndex}`);

        const newsubmissionVMForLosses = viewModelService.clone(submissionVMForLosses);

        updateSubmissionVMForLosses(newsubmissionVMForLosses);
        updateWizardData(newsubmissionVMForLosses);
    }, [viewModelService, disregardFieldValidation, updateSubmissionVMForLosses, updateWizardData, submissionVMForLosses]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddPropertyLossClick: addPropertyLoss,
            onRemovePropertyLossClick: removePropertyLoss,
            onValidate
        }
    };

    const writeValue = useCallback(
        (value, path) => {
            const newsubmissionVMForLosses = viewModelService.clone(submissionVMForLosses);

            _.set(newsubmissionVMForLosses, path, value);
            updateSubmissionVMForLosses(newsubmissionVMForLosses);
            updateWizardData(newsubmissionVMForLosses);
        },
        [updateWizardData, updateSubmissionVMForLosses, submissionVMForLosses, viewModelService]
    );
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVMForLosses,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVMForLosses]
    );

    return (

        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVMForLosses}
            onModelChange={updateWizardData}
            callbackMap={resolvers.resolveCallbackMap}
            onValueChange={writeValue}
            overrideProps={overrideProps}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ManualPropertyLossesInlineComponent.propTypes = {
    submissionVM: PropTypes.shape({}),
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
        clone: PropTypes.func
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
};
ManualPropertyLossesInlineComponent.defaultProps = {
    submissionVM: {},
};
export default ManualPropertyLossesInlineComponent;
