import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, set, isUndefined, isEmpty, filter, isNil
} from 'lodash';
 
import config from 'app-config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useModal } from '@jutro/components';
import { EndorsementService } from 'e1p-capability-policychange';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useLandingPageUtil } from 'e1p-capability-hooks';
import { UmbrellaFlowUtil, ClausesUtil as e1pClausesUtil, CoverageUtil } from 'e1p-portals-util-js';
import { PolicyChange } from 'gw-capability-policychange-common-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import PolicyChangeUtil from 'e1p-portals-util-js/PolicyChangeUtil';
import wizardMessages from '../../EUPolicyChangeWizard.messages';
import metadata from './CoveragesPage.metadata.json5';

const structureCustomQuote = (policyChangeVM, clauses) => 
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        coverages: clauses.personalUmbrella_EU
    })
;
const getCustomQuote = (vm, lobPath, lobName, filterChangedClauses = false) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages, lobName, null
        );
    }

    return structureCustomQuote(vm, clausesToUpdate);
};

const LOB = 'personalUmbrella_EU';

function CoveragePage(props) {
    const modalApi = useModal();
    const [loadingClause, updateLoadingClause] = useState();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        authHeader,
        steps,
        jumpTo,
        authUserData,
        updateWizardSnapshot,
        e1pGoNext
    } = props;
    const paperLessIndValue = get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessInd.value') === undefined
        ? false : get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessInd.value');

    const translator = useTranslator();
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [
        hasAlreadyApprovedLiabilityLimitUWIssue,
        setHasAlreadyApprovedLiabilityLimitUWIssue
    ] = useState(false);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const [isQuoteStale, setIsQuoteStale] = useState(false);
    const modifiers = get(policyChangeVM, 'lobData.personalUmbrella_EU.modifiers.value', []);
    const showPremium = CoverageUtil.isTotalPremiumVisible(policyChangeVM,authUserData) && !isQuoteStale;
    const {
        isComponentValid,
        onValidate,
        initialValidation,
        registerComponentValidation,
        disregardFieldValidation,
        registerInitialComponentValidation
    } = useValidation('EUCoveragePage');


    useEffect(() => {
        /**
         * E1PAP1PC-15339 :
         * Need to set this variable as
         * we don't want to show info message if UW issue is approved
         * If uw issue is approved and user comes back on coverage page and changes
         * LiabilityLimit cov limit >=6000000;
         * update coverage response does not send uw issues back so we need this
         */
        setHasAlreadyApprovedLiabilityLimitUWIssue(
            UmbrellaFlowUtil.hasLiabilityLimitChangeBlockQuoteUwIssueApproved(
                get(policyChangeVM, 'value.errorsAndWarnings.underwritingIssues',
                    get(policyChangeVM, 'value.errorsAndWarnings_Ext.underwritingIssues', []))
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // overriding coverage help text for given clause.
    useEffect(() => {
        get(policyChangeVM, 'value.lobData.personalUmbrella_EU.offerings[0].coverages.coverages').forEach((clause) => {
            const coveragesHelpText = CoverageUtil.getCoveragesHelpTextForEU(clause);

            if (coveragesHelpText) {
                clause.description = coveragesHelpText
            }
        })
        // set isQuoteStale, when status is draft
        setIsQuoteStale(get(policyChangeVM, 'value.status') === 'Draft');
    }, [policyChangeVM]);

    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    const writeValue = useCallback(
        (value, path) => {
            set(policyChangeVM, path, value);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = get(policyChangeVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessInd.value'); 
        const paperlessEmail = get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessEmail.value');
 
        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [policyChangeVM, isPaperlessEmailUpdated]);

    const onUpdateCustomQuote = useCallback(
        async (_basePath, lobPath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(policyChangeVM, lobPath, lobName, false);
            const oldPolicyChangeVM = viewModelService.clone(policyChangeVM);
             
            const getRemovedClausesID = (newPolicyChangeVM, path) => ClausesUtil.getRemovedClausesID(
                oldPolicyChangeVM, newPolicyChangeVM, path
            );
            const updatedCoverages = await EndorsementService.updateCoverages(
                policyChangeVM.value.jobID,
                [],
                 
                { personalUmbrella_EU: customQuote.coverages },
                authHeader
            );

            // preventing the backend from updating stale paperlessInd data with user's latest preference for paperlessInd
            updatedCoverages.lobData.personalUmbrella_EU.paperlessInd = policyChangeVM.value.lobData.personalUmbrella_EU.paperlessInd;
            // preventing the email from being erased
            updatedCoverages.lobData.personalUmbrella_EU.paperlessEmail = policyChangeVM.value.lobData.personalUmbrella_EU.paperlessEmail;

            const changedPath = lobPath.replace(/.children/, '');

            policyChangeVM.value = new PolicyChange(updatedCoverages);

            const updatedClauses = get(updatedCoverages, `${changedPath}.coverages`);

            set(policyChangeVM, `${lobPath}.coverages`, updatedClauses);

            const allRemovedFields = [...getRemovedClausesID(policyChangeVM, `${lobPath}.coverages.coverages`)];

            disregardFieldValidation(allRemovedFields);
            updateWizardData(policyChangeVM);
            setIsQuoteStale(true);
            updateLoadingClause(undefined);

            return updatedCoverages;
        },
        [authHeader, disregardFieldValidation, policyChangeVM, updateWizardData,
            viewModelService]
    );

    const recalculate = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (!get(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value')
                && !!get(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value')) {
                set(policyChangeVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value',
                    get(policyChangeVM, 'lobData.homeowners_EH.paperlessEmail.value'));
            }

            const quoteResponse = await  EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteResponse);
            setIsQuoteStale(false);
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);
            setIsSavingEndorsement(false);

            return false;
        },
        [authHeader, isComponentValid, policyChangeVM, updateWizardData, updateWizardSnapshot]
    );

    const onClauseChange = useCallback((schedule, path) => {
        const lobOfferingPath = 'lobData.personalUmbrella_EU.offerings.children[0]';

        writeValue(schedule, path);

        return onUpdateCustomQuote({}, lobOfferingPath);
    }, [onUpdateCustomQuote, writeValue]);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            set(policyChangeVM, changedPath, value);
            e1pClausesUtil.setClauseValue(policyChangeVM, value, changedPath);
            updateWizardData(policyChangeVM);
        },
        [policyChangeVM, updateWizardData]
    );

    const syncClauses = useCallback(
        async (value, changedPath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const baseObject = get(policyChangeVM, basePath);

            updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
            await onClauseChange();
            updateWizardData(policyChangeVM);
        }, [onClauseChange, policyChangeVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncClauses(value, changedPath);
        },
        [changeSubmission, syncClauses]
    );


    const updatePaperlessIndAndEmail = (value, path) => {
        set(policyChangeVM, path, value);

        switch (path) {
            case 'lobData.personalUmbrella_EU.paperlessInd':
                if (value) {
                    const pniEmail = get(policyChangeVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value');

                    if (get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessEmail.value') === undefined) {
                        set(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessEmail.value', pniEmail);
                    }
                } else {
                    set(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessEmail.value', undefined);
                }

                break;
            default:
                break;
        }

        setIsPaperlessEmailUpdated(true);
        updateWizardData(policyChangeVM);
    };

    const discountChange = (value, path) => {
        set(policyChangeVM, path, value);
        updateWizardData(policyChangeVM, false);
    };

    const getTotalPremium = useCallback(() => {
        const payPlans = get(policyChangeVM, 'lobData.personalUmbrella_EU.offerings.value[0].paymentPlans');
        const selectedPlan = filter(payPlans, (plan) => plan.isSelected);
        const totalPremium = selectedPlan[0]?.total?.amount;

        if (!isNil(totalPremium)) {return { currency: 'usd', amount: totalPremium };}

        return undefined;

    }, [policyChangeVM]);

    const saveAndQuote = useCallback(
        async (calledFromOnSaveOrCustom = false) => {
            let quoteEndorsement;

            try {
                if (!isComponentValid) {
                    updateIsPageSubmitted(true);
                    window.scrollTo(0, 0);

                    return false;
                }

                updateIsPageSubmitted(false);
                setIsSavingEndorsement(true);

                // If paperless Email id exist and pni email id is undefiend
                // then update pni email to paperless email
                if (isUndefined(get(policyChangeVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value'))
                    && !!get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessEmail.value')) {
                    set(policyChangeVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value',
                        get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessEmail.value'));
                }

                quoteEndorsement = await EndorsementService.saveAndQuoteEndorsement(
                    [(policyChangeVM.value)],
                    authHeader
                );
            } catch {
                setIsSavingEndorsement(false);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: e1pCommonMessages.saveCoverageError,
                    message: e1pCommonMessages.saveCoverageErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });
            }

            await PolicyChangeUtil.updatePolicyChangeDTOCustomFields(quoteEndorsement);
            policyChangeVM.value = new PolicyChange(quoteEndorsement);
            updateWizardData(policyChangeVM);
            /**
             * E1PAP1PC-15339 :
             * Need to set this variable as
             * we don't want to show info message if UW issue is approved
             * If uw issue is approved and user comes back on coverage page and changes
             * LiabilityLimit cov limit >=6000000;
             * update coverage response does not send uw issues back so we need this
             */
            setHasAlreadyApprovedLiabilityLimitUWIssue(
                UmbrellaFlowUtil.hasLiabilityLimitChangeBlockQuoteUwIssueApproved(
                    get(policyChangeVM, 'value.errorsAndWarnings.underwritingIssues',
                        get(policyChangeVM, 'value.errorsAndWarnings_Ext.underwritingIssues', []))
                )
            );
            setIsSavingEndorsement(false);
            setIsQuoteStale(false);

            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
            const hasConflicts = !isEmpty(get(policyChangeVM, 'value.conflicts', []));

            if (!calledFromOnSaveOrCustom && hasConflicts && validationErrors.length === 0) {
                let newLandingPageIndex = -1;

                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );

                if (newLandingPageIndex >= 0) {
                    jumpTo(newLandingPageIndex, true);
                }

                return false;
            }

            return policyChangeVM;
        },
        [policyChangeVM, updateWizardData, isComponentValid, authHeader, modalApi, getLandingPageIndexForQuotedJob, steps, jumpTo]
    );

    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            await saveAndQuote(calledFromOnSave);

            let paperLessIndDetailsValid = policyChangeVM.lobData
                .personalUmbrella_EU.paperlessInd.value !== undefined;

            if (policyChangeVM.lobData.personalUmbrella_EU.paperlessInd.value) {
                const paperEmail = get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessEmail');

                paperLessIndDetailsValid = paperEmail.value !== undefined
                    && paperEmail.value !== '' && paperEmail.aspects.valid;
            }

            if (!paperLessIndDetailsValid || !isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            return policyChangeVM;
        },
        [policyChangeVM, saveAndQuote, isComponentValid]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, policyChangeVM, updateWizardSnapshot]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        coveragesPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(e1pCommonMessages.loadingNextPage)
        },
        coveragesPageContainer: {
            visible: isPageLoaded
        },
        paymentOptionsID: {
            submissionVM: policyChangeVM,
            authHeader,
            updateWizardData,
            LOB: 'personalUmbrella_EU',
            viewOnly: true,
            isQuoteStale
        },
        totalPremiumID: {
            visible: showPremium,
            value: (() => getTotalPremium())()
        },
        emptyPremium: {
            visible: !showPremium
        },
        monthlyPaymentScheduleComponent: {
            quoteID: get(policyChangeVM, 'quoteID.value', policyChangeVM.jobID?.value),
            authHeader,
            transactionTotalAmount: getTotalPremium(),
            changeInCost: policyChangeVM.transactionCost?.value,
            startDate: policyChangeVM.baseData.periodStartDate.value,
            endDate: policyChangeVM.baseData.periodEndDate.value,
            jobTypeCode: policyChangeVM.baseData.jobType.value.code,
            offerings: get(policyChangeVM, 'lobData.personalUmbrella_EU.offerings.value')
        },
        paperlessInd: {
            required: true,
            value: get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessInd.value'),
            onValueChange: updatePaperlessIndAndEmail,
            labelPosition: 'top',
            visible: get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled'])
        },
        paperlessEmailId: {
            visible: paperLessIndValue && get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled']),
            required: paperLessIndValue,
            onValueChange: updatePaperlessIndAndEmail,
            labelPosition: 'top',
        },
        AutoPayDiscountToggle: {
            labelPosition: 'top',
            onValueChange: discountChange,
            value: get(policyChangeVM, 'lobData.personalUmbrella_EU.autoPayDiscInd.value')
        },
        wizardPageHeader: { wizardSubmission: policyChangeVM },
        coverage: {
            loadingClause,
            isQuoteStale,
            labelPosition: 'top'
        },
        euSurchargeListComponentId: {
            // modifiers other than discount will be shown in surchage and fee section
            value: modifiers.filter(
                (item) => item.applied && item.modifierType !== "discount"
            ),
            transactionVM: policyChangeVM,
            updateWizardData
        },
        euDiscountsListComponentId: {
            visible: !!filter(modifiers, { applied: true, modifierType: 'discount' }).length,
            value: modifiers
        },
        personalLiabilityLimitUwIssueInfoMessageDiv: {
            visible: UmbrellaFlowUtil
                .isPersonalLiabilityLimitUwIssueInfoMessage(
                    policyChangeVM,
                    hasAlreadyApprovedLiabilityLimitUWIssue
                )
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        buyNowButton: {
            visible: !isQuoteStale,
            disabled: !isUndefined(loadingClause),
            onClick: async () => {
                await onNext();

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    e1pGoNext();
                }
            }
        },
        recalculateButton: {
            visible: isQuoteStale,
            disabled: !isUndefined(loadingClause),
            onClick: () => {
                recalculate();
            }
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onValidate
        },
    };

    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, policyChangeVM, id, path, overrideProps), [overrideProps, policyChangeVM]);

    const onCustom = useCallback(
        async () => {
            await saveAndQuote(true);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            return false;
        },
        [
            getLandingPageIndexForQuotedJob,
            jumpTo,
            policyChangeVM,
            saveAndQuote,
            steps,
            updateWizardSnapshot
        ]
    );

    const isPageLoading = useCallback(() => isUndefined(loadingClause), [loadingClause]);

    const isQuoted = useCallback(() => get(policyChangeVM, 'status') === 'quoted', [policyChangeVM]);

    useEffect(() => {
        registerComponentValidation(isPageLoading);
        registerInitialComponentValidation(isQuoted);
    }, [isPageLoading, isQuoted, registerComponentValidation, registerInitialComponentValidation]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted}
            showNext={!isQuoteStale}
            showCustom1
            onCustom1={onCustom}
            disableNext={!isUndefined(loadingClause)}
            custom1Label={e1pCommonMessages.reviewChanges}
            onSave={onSave}
            showCustom={isQuoteStale}
            customLabel={wizardMessages.recalculateButtonLabel}
            onCustom={recalculate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

CoveragePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};
export default withRouter(withAuthenticationContext(CoveragePage));
