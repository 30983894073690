import { defineMessages } from 'react-intl';

export default defineMessages({
    noPolicyNotes: {
        id: 'e1p.gateway.views.activity-summary.No notes associated with this policy',
        defaultMessage: 'There are no notes associated with this policy.'
    },
    errorLoadingNotes: {
        id: 'e1p.gateway.views.policy-detail.notes.error',
        defaultMessage: 'Could not retrieve notes for Policy Number: {policyNumber}'
    },
    errorCreatingNotes: {
        id: 'e1p.gateway.views.policy-detail.notes.Failed to create note',
        defaultMessage: 'Failed to create note'
    },
    errorUpdatingNotes: {
        id: 'e1p.gateway.views.policy-detail.notes.Failed to update note',
        defaultMessage: 'Failed to update note'
    },
    errorDeletingNotes: {
        id: 'e1p.gateway.views.policy-detail.notes.Failed to delete note',
        defaultMessage: 'Failed to delete note'
    }
});
