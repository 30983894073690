
import React, {
    useCallback, useContext, useEffect, useMemo, useRef, useState
} from 'react';
import {
    get, set, filter, map, sortBy, uniqBy, isEmpty, noop, findIndex, find, isUndefined
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useModal } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { VehicleUtil, ClausesUtil as e1pClausesUtil, CoverageUtil, QuoteProposalUtil } from 'e1p-portals-util-js';
import { RewriteService } from 'e1p-capability-rewrite';
import { commonMessages as e1pCommonMessages, eaCommonMessages } from 'e1p-platform-translations';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useUWIssueUtil, useOOSConflictPageLandingUtil, useModifierUtil } from 'e1p-capability-hooks';
import EATravelPackagesConfig from 'e1p-portals-util-js/EATravelPackages-config.json';
import messages from './CoveragePage.messages';
import metadata from './CoveragePage.metadata.json5';

function structureClauseTableData(coverage) {
    // putting ID into an object as the Jutro table component expects an object
    return {
        publicID: coverage.publicID
    };
}

function generateClauseData(columnData, coveragePath, vehicleFixedID) {
    return columnData.map(({ lob, code }) => {
        let completeCoveragePath = `coverages.${coveragePath}`;

        if (vehicleFixedID) {
            const vehicleOfferings = lob.data.coverages.vehicleCoverages;
            const vehicleOfferingIndex = vehicleOfferings.findIndex(
                (vehicleCoverage) => vehicleCoverage.fixedId === vehicleFixedID
            );

            completeCoveragePath = `coverages.${coveragePath}.children[${vehicleOfferingIndex}].coverages`;
        }

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

const structureCustomQuote = (rewriteVM, affectedQuote, clauses) => 
    // convert OfferingDTO to CustomQuotedDTO structure
     ({ coverages: clauses.personalAuto_EA })
;

const getCoveragesUniqueIDLineCoverages = (rewriteVM) => {
    const offerings = get(
        rewriteVM,
        'lobData.personalAuto_EA.offerings.value'
    );
    const lineCoverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.lineCoverages.map(structureClauseTableData)
    )), 'publicID');

    return lineCoverages;
};

const getCoveragesUniqueIDVehicleCoverages = (rewriteVM, vehFixedId) => {
    const offerings = get(
        rewriteVM,
        'lobData.personalAuto_EA.offerings.value'
    );

    const vehicleCoverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.vehicleCoverages
            .filter((vehicleCoverage) => vehicleCoverage.fixedId === vehFixedId)
            .flatMap(({ coverages }) => (
                coverages.map(structureClauseTableData)
            ))
    )), 'publicID');

    return vehicleCoverages;
};

const generateColumnData = (rewriteVM) => {
    const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = get(rewriteVM, `${lobOfferingPath}.value`);
    const quoteOfferings = get(rewriteVM, `${quoteOfferingPath}.value`) || [];

    let columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];

            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                }
            };
        });

    // .filter(({ code }) => code !== 'CUSTOM');
    columnData = columnData.length > 1 ? [columnData.find((offering) => offering.code === 'MSA - Good')]
        : columnData;

    return sortBy(columnData, ['code']);
};

const getCustomQuote = (
    vm,
    lobPath,
    quotePath,
    lobName,
    filterChangedClauses = false
) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    const quoteOffering = get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
         
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

const generateTableData = (rewriteVM, columnData, translator) => {
    const vehicles = get(
        rewriteVM,
        'lobData.personalAuto_EA.coverables.vehicles.value'
    );
    const lineCovsUniqueIDs = getCoveragesUniqueIDLineCoverages(rewriteVM);

    const lineCoverages = {
        header: translator(messages.generalCoveragesRewrite),
        data: lineCovsUniqueIDs,
        tableContent: generateClauseData(columnData, 'lineCoverages'),
        coverageType: 'lineCoverages'
    };

    const vehicleCoverages = vehicles.map((vehicle) => {
        // getting vehicle coverage public ids for given vehicle
        const vehCovUniqueIDs = getCoveragesUniqueIDVehicleCoverages(rewriteVM, vehicle.fixedId);

        return {
            header: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
            data: vehCovUniqueIDs,
            tableContent: generateClauseData(
                columnData,
                'vehicleCoverages',
                vehicle.fixedId
            ),
            coverageType: 'vehicleCoverages',
            fixedId: vehicle.fixedId
        };
    });

    return [lineCoverages, ...vehicleCoverages];
};

const LOB = 'personalAuto_EA';

function CoveragePage(props) {
    const modalApi = useModal();
    const {
        wizardData: rewriteVM,
        updateWizardData,
        stopSkipping,
        jumpTo,
        steps,
        cancel: onCancel,
        currentStepIndex,
        changeNextSteps,
        updateWizardSnapshot
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const stepsRef = useRef(steps);
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);

    useEffect(() => {
        /**
         * Using useRef to access current updated steps.
         * as we are adding new conflicts step and landing user on this newly created step
         * "step" state variable from props does not give us updated
         * value inside useOOSConflictPageLandingUtil
         * it refers initial rendered value only(as we are adding new step and want to land user
         * on new step in)
         */
        stepsRef.current = steps;
         
    }, [steps]);

    const {
        removeOrAddAndLandOnConflictsPage
    } = useOOSConflictPageLandingUtil(
        stepsRef,
        currentStepIndex,
        changeNextSteps,
        jumpTo
    );
    const {
        hasUWIssuesOfType,
        showUnderwritingIssuesPopup
    } = useUWIssueUtil(
        rewriteVM,
        updateWizardData,
        jumpTo,
        steps
    );
    const [isQuoting, setIsQuoting] = useState(false);
    const [fieldsChangedOnCoveragePage, setFieldsChangedOnCoveragePage] = useState(false);
     
    const { authHeader, authUserData } = useAuthentication();
    const {
        onValidate,
        disregardFieldValidation,
        isComponentValid
    } = useValidation('QuotePage');
    const viewModelService = useContext(ViewModelServiceContext);
     
    const [isBuying, setIsBuying] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [selectedVehicleCoverages, setSelectedVehicleCoverages] = useState({});
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const isQuickQuote = useRef(
        get(rewriteVM.value, 'quoteType') === 'Quick'
    ).current;

    const {
        structuredModifiers
    } = useModifierUtil(rewriteVM);
    const modifiers = structuredModifiers;

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(
                rewriteVM,
                lobPath,
                quotePath,
                lobName,
                false
            );

            return RewriteService.updateCoverages(
                 
                [rewriteVM.jobID.value, { personalAuto_EA: customQuote.coverages }],
                authHeader
            ).then((response) => {
                const exceptions = get(response, 'baseData.exceptions_Ext', []);

                if (isEmpty(exceptions)) {
                    const updatedClauses = get(response, 'lobData.personalAuto_EA.offerings[0].coverages');
                    const newrewriteVM = viewModelService.clone(rewriteVM);

                    // Update the offering status to stale
                    // Update local offering with new one from xcenter
                    set(rewriteVM, `${lobPath}.coverages`, updatedClauses);

                    // Update local quote status with new one from xcenter
                    const status = get(response, 'quoteData.offeredQuotes[0].status', 'Draft');

                    set(rewriteVM, `${quotePath}.status`, status);
                    // Update local errorsAndWarnings with new one from xcenter
                    set(rewriteVM, 'errorsAndWarnings', response.errorsAndWarnings);
                    // Update premium with new one from xcenter
                    set(rewriteVM, `${quotePath}.premium`, response.quoteData.offeredQuotes[0]?.premium?.premium);
                    rewriteVM.value = response;

                    const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                        rewriteVM,
                        newrewriteVM,
                        `${lobPath}.coverages.lineCoverages`
                    );
                    const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                        rewriteVM,
                        newrewriteVM,
                        `${lobPath}.coverages.vehicleCoverages`
                    );
                    const allRemovedFields = [
                        ...removedFieldsFromBaseCoverages,
                        ...removedFieldsFromAdditionalCoverages
                    ];

                    disregardFieldValidation(allRemovedFields);
                } else {
                    set(rewriteVM, 'value.baseData.exception_Ext', exceptions);
                }

                updateWizardData(rewriteVM);
            });
        },
        [rewriteVM, authHeader, viewModelService, disregardFieldValidation, updateWizardData]
    );

    /**
     * Below function gets called when we copy coverages from one vehicle to another vehicle/s
     * updateDraftRewriteWithOptions api updated coverages forcefully
     */
    const onClauseChangeForceUpdate = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (_lobPath, _quotePath) => {
            const updateOptions = {
                fullCoverageUpdate: true,
                useGenericValueAsString: true,
                shouldSyncCoveragesAfterUpdate: true,
                forceUpdate: true,
            }

            return Promise.resolve(
                RewriteService.updateDraftRewriteWithOptions(rewriteVM.value, updateOptions, authHeader)
                    .then((response) => {
                        set(rewriteVM, 'value', response);
                        updateWizardData(rewriteVM);
                        updateWizardSnapshot(rewriteVM);
                    })
            )
        },
        [authHeader, rewriteVM, updateWizardData, updateWizardSnapshot]
    );



    const checkuwIssues = useCallback((newestSubmission) => {
        if (hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease', 'Rejected'])) {
            showUnderwritingIssuesPopup(newestSubmission);
        }
    }, [hasUWIssuesOfType, showUnderwritingIssuesPopup]);

    useEffect(() => {
         
        checkuwIssues();
        stopSkipping();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            // const lobOfferingPath = 'lobData.personalAuto_EA.offerings.children[0]';
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
             
            // const offering = get(rewriteVM, `${lobOfferingPath}.value`);

            // setStaleQuoteBranchCode(offering.branchCode);
            return onClauseChange(basePath, lobPath, quotePath);
        },
        [onClauseChange]
    );

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = get(rewriteVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(rewriteVM, 'lobData.personalAuto_EA.paperlessInd.value');
        const paperlessEmail = get(rewriteVM, 'lobData.personalAuto_EA.paperlessEmail.value');

        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [rewriteVM, isPaperlessEmailUpdated]);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            // IAP-2626 : SC Auto - When UM and/or UIMBI Rejected, PE needs to send an stacking indicator to PC
            CoverageUtil.changeStackingTermValueorUMBIAndUIMBICoverages(rewriteVM, changedPath, value, updateWizardData);
            updateWizardData(
                e1pClausesUtil.setClauseValue(rewriteVM, value, changedPath)
            );
        },
        [rewriteVM, updateWizardData]
    );

    const performChangeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            changeSubmission(value, changedPath);

            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const coverageListPath = ClausesUtil.getObjectPathFromChangedPath(basePath);
            const selectedVehicleCoverage = get(
                rewriteVM, ClausesUtil.getObjectPathFromChangedPath(coverageListPath), {}
            );
            const vehicleIndex = findIndex(
                get(rewriteVM, 'lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.value', {}),
                (vehicle) => vehicle.fixedId === selectedVehicleCoverage.fixedId
            );
            const selectedCoverage = get(rewriteVM, basePath, {});

            const keys = Object.keys(EATravelPackagesConfig);

            // If selected coverage change is for travel package
            if (keys.includes(selectedCoverage.codeIdentifier)) {
                const coverages = get(rewriteVM, coverageListPath, {});

                // Save corresponding selected values related to package
                if (value) {
                    const newSelectedVehicleCoverages = {};

                    EATravelPackagesConfig[selectedCoverage.codeIdentifier].forEach((key) => {
                        const coverage = find(coverages, (cov) => cov.codeIdentifier === key);

                        if (coverage) {
                            set(newSelectedVehicleCoverages, [vehicleIndex, key, 'selected'], coverage.selected);
                            set(newSelectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm'], get(coverage, 'terms[0].chosenTerm'));
                        }
                    });
                    setSelectedVehicleCoverages(
                        { selectedVehicleCoverages, ...newSelectedVehicleCoverages }
                    );

                    return syncCoverages(value, changedPath, lobPath, quotePath);
                }

                // Sync new change and check for previously selected values
                return syncCoverages(value, changedPath, lobPath, quotePath).then(() => {
                    let isChosenTerm = false;

                    Object.keys(get(selectedVehicleCoverages, vehicleIndex, {})).forEach((key) => {
                        const index = findIndex(coverages, (cov) => cov.codeIdentifier === key);
                        const selected = get(selectedVehicleCoverages, [vehicleIndex, key, 'selected'], false);
                        const chosenTerm = get(selectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm']);

                        // Make selection of previously selected items
                        if (index >= 0 && selected) {
                            changeSubmission(true, `lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[${vehicleIndex}].coverages.children[${index}].selected`);

                            // If there is any updated term (dropdown value) in previous value
                            if (chosenTerm) {
                                isChosenTerm = true;
                            }
                        }
                    });

                    // Update chosen Terms if exists
                    if (isChosenTerm) {
                        return syncCoverages(value, changedPath, lobPath, quotePath).then(() => {
                            Object.keys(
                                get(selectedVehicleCoverages, vehicleIndex, {})
                            ).forEach((key) => {
                                const index = findIndex(coverages,
                                    (cov) => cov.codeIdentifier === key);
                                const selected = get(selectedVehicleCoverages, [vehicleIndex, key, 'selected'], false);
                                const chosenTerm = get(selectedVehicleCoverages, [vehicleIndex, key, 'chosenTerm']);

                                if (index >= 0 && selected) {
                                    if (chosenTerm) {
                                        changeSubmission(chosenTerm, `lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[${vehicleIndex}].coverages.children[${index}].terms.children[0].chosenTerm`);
                                    }
                                }
                            });
                        });
                    }

                    // Remove previously selected values
                    const newSelectedVehicleCoverages = {};

                    set(newSelectedVehicleCoverages, [vehicleIndex], {});
                    setSelectedVehicleCoverages(
                        { selectedVehicleCoverages, ...newSelectedVehicleCoverages }
                    );

                    return syncCoverages(value, changedPath, lobPath, quotePath);
                });
            }

            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeSubmission, rewriteVM, selectedVehicleCoverages, syncCoverages]
    );


    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            // vehicleName for the selected Index
            let selectedVehicleName = get(
                rewriteVM, `${changedPath}._parent._parent._parent._parent._parent.vehicleName.value`
            );

            // If vehicleName not found
            if (!selectedVehicleName) {
                selectedVehicleName = get(
                    rewriteVM, `${changedPath}._parent._parent._parent.vehicleName.value`
                );
            }

            const diminishingDeductibleArray = get(rewriteVM, 'lobData.personalAuto_EA.diminishingDeductible.value');

            // find if there is a diminishingDeductible for secletedVehicleName
            const diminishingDeductibleFound = diminishingDeductibleArray.find(
                (dimValue) => dimValue.vehicleDisplayName === selectedVehicleName
            );
            // Check if there is a credit Accrued for the the corresponding vehicle
            const isCreditAccrued = diminishingDeductibleFound?.amount > 0;
            // When dimishing deductable is deselected
            const diminishingDeductibleRemoved = get(
                rewriteVM, `${changedPath}._parent.codeIdentifier.value`
            ) === 'EA_DiminishingDeductibleVeh' && value === false;

            const isComprehensiveDeleted = get(
                rewriteVM, `${changedPath}._parent.codeIdentifier.value`
            ) === 'EA_Comprehensive' && !value;

            const isCollisionDeleted = get(
                rewriteVM, `${changedPath}._parent.codeIdentifier.value`
            ) === 'EA_Collision' && !value;


            // When Comprehensive or Collision is reduced below 500
            const isComprehensiveChanged = get(
                rewriteVM, `${changedPath}._parent.patternCodeIdentifier.value`
            ) === 'EA_Comprehensive_Deductible' && value < 500;

            const isCollisionChanged = get(
                rewriteVM, `${changedPath}._parent.patternCodeIdentifier.value`
            ) === 'EA_Collision_Deductible' && value < 500;

            if (isCreditAccrued && (diminishingDeductibleRemoved
                || isComprehensiveChanged || isCollisionChanged
                || isComprehensiveDeleted || isCollisionDeleted)) {
                return modalApi.showConfirm({
                    title: eaCommonMessages.diminishingDeductibleCreditTitle,
                    message: eaCommonMessages.diminishingDeductibleCreditInfoMessage,
                    confirmButtonText: eaCommonMessages.removeCredit,
                    cancelButtonText: e1pCommonMessages.cancel
                }).then((result) => {
                    if (result === 'cancel') {
                        return noop();
                    }

                    return performChangeSubmissionAndSync(value, changedPath, lobPath, quotePath);
                }, noop);
            }

            return performChangeSubmissionAndSync(value, changedPath, lobPath, quotePath);
        },
        [performChangeSubmissionAndSync, modalApi, rewriteVM]
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted]);


    const recalculate = useCallback(
        async (lobPath, quotePath) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            updateIsPageSubmitted(false);
            setIsQuoting(true);

            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(
                rewriteVM,
                lobPath,
                quotePath,
                lobName
            );

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (isUndefined(get(rewriteVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value'))
                && !!get(rewriteVM, 'lobData.personalAuto_EA.paperlessEmail.value')) {
                set(rewriteVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value',
                    get(rewriteVM, 'lobData.personalAuto_EA.paperlessEmail.value'));
            }

            if (fieldsChangedOnCoveragePage) {
                return RewriteService
                    .saveAndQuote([rewriteVM.value], authHeader)
                    .then((response) => {
                        setStaleQuoteBranchCode(undefined);
                        setFieldsChangedOnCoveragePage(false);

                        const newrewriteVM = viewModelService.clone(rewriteVM);
                        const quoteData = get(newrewriteVM, 'quoteData.value');
                        const respQuoteData = get(response, 'quoteData');

                        if (respQuoteData === undefined) {
                            set(response, 'quoteData', quoteData);
                        }

                        set(newrewriteVM, 'value', response);
                        QuoteProposalUtil.fetchQuoteProposal(
                            newrewriteVM,
                            setQuoteProposalCompleted,
                            setQuoteProposalLink,
                            authHeader,
                            setIsQuoteProposalFailed
                        );
                        updateWizardData(newrewriteVM);
                        updateWizardSnapshot(newrewriteVM);
                        // state update is async, need to directly pass in
                        checkuwIssues(newrewriteVM);

                        // E1PAP1PC-13853 :
                        // If we get conflicts in saveAndQuote, we will add conflicts page
                        // if its not present and user will land on conflicts page. If we had
                        // conflicts and we came back and made changes such that after saveAndQuote
                        // if there are no conflicts we will remove conflicts page if its present
                        const hasConflicts = !isEmpty(get(newrewriteVM, 'value.conflicts', []));

                        removeOrAddAndLandOnConflictsPage(hasConflicts);

                        return response;
                    })
                    .catch(() => {
                        modalApi.showAlert({
                            status: 'error',
                            icon: 'mi-error-outline',
                            title: 'Something went wrong in recalculate operation',
                            message: ''
                        });

                        const lobOfferingPath = 'lobData.personalAuto_EA.offerings.children[0]';
                        const offering = get(rewriteVM, `${lobOfferingPath}.value`);

                        setStaleQuoteBranchCode(offering.branchCode);
                        setFieldsChangedOnCoveragePage(true);

                        return customQuote;
                    })
                    .finally(() => {
                        setIsQuoting(false);
                    });
            }


            return RewriteService.saveAndQuote([rewriteVM.value], authHeader).then(
                (response) => {
                    setStaleQuoteBranchCode(undefined);

                    // Update local offering with new one from xcenter
                    const newrewriteVM = viewModelService.clone(rewriteVM);
                    const quoteData = get(newrewriteVM, 'quoteData.value');
                    const respQuoteData = get(response, 'quoteData');

                    if (respQuoteData === undefined) {
                        set(response, 'quoteData', quoteData);
                    }

                    set(newrewriteVM, 'value', response);
                    QuoteProposalUtil.fetchQuoteProposal(
                        newrewriteVM,
                        setQuoteProposalCompleted,
                        setQuoteProposalLink,
                        authHeader,
                        setIsQuoteProposalFailed
                    );
                    updateWizardData(newrewriteVM);
                    updateWizardSnapshot(newrewriteVM);

                    // state update is async, need to directly pass in
                    checkuwIssues(newrewriteVM);

                    // E1PAP1PC-13853 :
                    // If we get conflicts in saveAndQuote, we will add conflicts page
                    // if its not present and user will land on conflicts page. If we had
                    // conflicts and we came back and made changes such that after saveAndQuote
                    // if there are no conflicts we will remove conflicts page if its present
                    const hasConflicts = !isEmpty(get(newrewriteVM, 'value.conflicts', []));

                    removeOrAddAndLandOnConflictsPage(hasConflicts);

                    return response;
                }
            ).catch(() => {
                modalApi.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: 'Something went wrong in recalculate operation',
                    message: ''
                });

                const lobOfferingPath = 'lobData.personalAuto_EA.offerings.children[0]';
                const offering = get(rewriteVM, `${lobOfferingPath}.value`);

                setStaleQuoteBranchCode(offering.branchCode);
                setFieldsChangedOnCoveragePage(true);

                return customQuote;
            })
                .finally(() => {
                    setIsQuoting(false);
                });
        },
        [
            isComponentValid, rewriteVM, fieldsChangedOnCoveragePage, authHeader, viewModelService,
            updateWizardData, checkuwIssues, removeOrAddAndLandOnConflictsPage, updateWizardSnapshot, modalApi
        ]
    );

    const handlePrint = useCallback(() => {
        window.print();
    }, []);


    const buyNow = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsBuying(true);

            const retrievedSubmission = await RewriteService.retrieve(
                [rewriteVM.jobID.value],
                authHeader
            );

            const paperlessInd = get(rewriteVM, `lobData[${LOB}].paperlessInd.value`);
            const autoPay = get(rewriteVM, `lobData[${LOB}].autoPayDiscInd.value`, false);
            // If email was given from paperless modal save it
            const newEmail = get(rewriteVM, `lobData[${LOB}].paperlessEmail.value`, undefined);

            set(retrievedSubmission, `lobData[${LOB}].primaryNamedInsured.person.emailAddress1`, newEmail);
            set(retrievedSubmission, `lobData[${LOB}].paperlessInd`, paperlessInd);
            set(retrievedSubmission, `lobData[${LOB}].paperlessEmail`, newEmail);
            set(retrievedSubmission, `lobData[${LOB}].autoPayDiscInd`, autoPay);

            set(rewriteVM, 'value', retrievedSubmission);
            updateWizardData(rewriteVM);

            return rewriteVM;
        },
        [authHeader, isComponentValid, rewriteVM, updateWizardData]
    );

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isQuoting) {
            loadingMessage = translator(messages.ratingYourPolicyMessage);
        }

 if (isBuying) {
            loadingMessage = translator(messages.creatingYourOfferingMessage);
        }

        return loadingMessage;
    }, [isQuoting, isBuying, translator]);

    const accidentForgivenessApplied = (() => {
        if (
            rewriteVM.lobData.personalAuto_EA.accidentForgivenessStatuses.children.length !== 0
            && rewriteVM.lobData.personalAuto_EA.accidentForgivenessStatuses.children[0].code.value === 'Earned Accident Forgiveness'
        ) {
            return true;
        }

        return false;
    })();

    const handleCompOnlyChangeForVehicleForCopyCoverages = useCallback(
        async () => {
            // call save and quote
            let response;

            try {
                response = await RewriteService.saveAndQuote([rewriteVM.value], authHeader);
            } catch {
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: messages.saveCoverageError,
                    message: messages.saveCoverageErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });
            }

            rewriteVM.value = response;
            updateWizardData(rewriteVM);

            const fieldIssues = get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
            const exceptions = get(rewriteVM, 'baseData.exceptions_Ext.value', []);

            if (!isEmpty(fieldIssues)) {
                window.scrollTo(0, 0);
            } else if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                setStaleQuoteBranchCode(undefined);
            }
        },
        [authHeader, rewriteVM, updateWizardData, modalApi]
    );

    const handleCompOnlyChangeForVehicle = useCallback(
        async (value, vehiclePublicID) => {
            /**
             * get index of vehicle and set compOnlyInd
             */
            const vehicleIndex = VehicleUtil.getVehicleIndexBasedonPublicID(rewriteVM, vehiclePublicID);

            set(rewriteVM, `value.lobData.personalAuto_EA.coverables.vehicles[${vehicleIndex}].compOnlyInd`, value);
            updateWizardData(rewriteVM);

            if (value) {
                // set all the vehicle coverage with selected as false
                const vehicleCoverages = VehicleUtil.getVehicleCoveragesBasedonPublicID(rewriteVM, vehiclePublicID);
                const updatedCoverages = vehicleCoverages.map((cov) => {
                    if (cov.codeIdentifier !== 'EA_Comprehensive') {
                        set(cov, 'selected', false);
                    } else {
                        set(cov, 'selected', true);
                    }

                    return cov;
                });
                const vehicleCoverageIndex = VehicleUtil.getVehicleCoverageIndexBasedonPublicID(rewriteVM, vehiclePublicID);

                set(rewriteVM, `value.lobData.personalAuto_EA.offerings[0].coverages.vehicleCoverages.${vehicleCoverageIndex}.coverages`, updatedCoverages);
                updateWizardData(rewriteVM);
            }

            // check if every vehicle is componly then we need to update all line coverages to selected as false
            if (VehicleUtil.isEveryVehicleCompOnly(rewriteVM)) {
                const lineCoverages = VehicleUtil.getLineLevelCoverages(rewriteVM);
                const updatedLineCoverages = lineCoverages.map((cov) => {
                    set(cov, 'selected', false);

                    return cov;
                });

                set(rewriteVM, 'value.lobData.personalAuto_EA.offerings[0].coverages.lineCoverages', updatedLineCoverages);
                updateWizardData(rewriteVM);
            }

            // call save and quote
            let response;

            try {
                response = await RewriteService.saveAndQuote([rewriteVM.value], authHeader);
            } catch {
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: messages.saveCoverageError,
                    message: messages.saveCoverageErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });
            }

            rewriteVM.value = response;
            updateWizardData(rewriteVM);

            const fieldIssues = get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
            const exceptions = get(rewriteVM, 'baseData.exceptions_Ext.value', []);

            if (!isEmpty(fieldIssues)) {
                window.scrollTo(0, 0);
            } else if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                setStaleQuoteBranchCode(undefined);
            }
        },
        [authHeader, rewriteVM, modalApi, updateWizardData]
    );

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();
            window.open(quoteProposalLink, '_blank');
        }, [quoteProposalLink]
    );

    const generateOverrides = useCallback(() => {
        const columnData = generateColumnData(rewriteVM);
        const { jobID } = rewriteVM.value;

        const initialOverrides = {
            '@field': {
                // apply to all fields
                labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                autoComplete: false
            },
            quotePageLoadingIndicator: {
                loaded: !isBuying && !isQuoting,
                text: getLoadingIndicatorMessage
            },
            quotePageContainer: {
                visible: !isBuying && !isQuoting
            },
            quoteTable: {
                columnData,
                tableData: generateTableData(rewriteVM, columnData, translator),
                modifiers,
                quoteID: jobID.value,
                submissionVM: rewriteVM,
                viewModelService,
                updateWizardData,
                authHeader,
                setFieldsChangedOnCoveragePage,
                fieldsChangedOnCoveragePage,
                visible: !isBuying,
                underwritingIssues: get(rewriteVM, 'errorsAndWarnings.underwritingIssues.value') === undefined ? [] : get(rewriteVM, 'errorsAndWarnings.underwritingIssues.value'),
                onCancel,
                isPageSubmitted,
                onViewQuoteProposal: openDocument,
                isQuoteProposalReady: quoteProposalCompleted && !fieldsChangedOnCoveragePage,
                updateIsPageSubmitted,
                authUserData,
                setIsPaperlessEmailUpdated,
                onValidate,
                opCo
            },
            accidentForgivenessMessageDiv: {
                visible: rewriteVM.value.quoteType === 'Full' && accidentForgivenessApplied()
            },
            paperlessEmailChangedMessageDiv: {
                visible: showPaperlessEmailMessage()
            },
            quoteProposalFailureErrorDiv: {
                visible: isQuoteProposalFailed
            },
        };

        return { ...initialOverrides};
    }, [
        rewriteVM, breakpoint, isBuying, isQuoting, getLoadingIndicatorMessage,
        translator, modifiers, viewModelService, updateWizardData, authHeader,
        fieldsChangedOnCoveragePage, onCancel, isPageSubmitted, openDocument,
        quoteProposalCompleted, authUserData, onValidate, accidentForgivenessApplied,
        showPaperlessEmailMessage, isQuoteProposalFailed, opCo
    ]);

    useEffect(() => {
        QuoteProposalUtil.fetchQuoteProposal(
            rewriteVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );

        const offerings = get(rewriteVM, 'quoteData.offeredQuotes.value');
        const draftOffers = filter(offerings, ['status', 'Draft']);
        const draftBranchCodes = map(draftOffers, 'branchCode');

        setStaleQuoteBranchCode(draftBranchCodes);

        // Above logic only needs to run once when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);


    const checkDisable = useCallback(() => {
        if (rewriteVM.value.lobData.personalAuto_EA.acknowledgements === undefined) {
            return false;
        }

        return rewriteVM.value.lobData.personalAuto_EA.acknowledgements
            .some((acknowledgement) => acknowledgement.acknowledgeAnswerType === 'reject');
    }, [rewriteVM]);

    return (
        <WizardPage
            showNext={false}
            showPrevious={false}
            showCancel={false}
            skipWhen={() => !isQuickQuote}
            isPageSubmittedWithErrors={isPageSubmitted}
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveCallbackMap: {
                        onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
                        onStaleQuoteBranchCode,
                        onRecalculate: recalculate,
                        onChangeSubmissionAndSync: changeSubmissionAndSync,
                        onChangeSubmission: changeSubmission,
                        onSyncCoverages: syncCoverages,
                        onPrint: handlePrint,
                        onDisableNextForTable: checkDisable,
                        onCompOnlyChange: handleCompOnlyChangeForVehicle,
                        onCompOnlyChangeForCopyCoverages: handleCompOnlyChangeForVehicleForCopyCoverages,
                        onClauseChangeForceUpdate
                    }
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={rewriteVM}
                        overrideProps={generateOverrides()}
                        onModelChange={updateWizardData}
                        callbackMap={resolvers.resolveCallbackMap}
                        onValidationChange={onValidate}
                    />
                );
            }}
        </WizardPage>
    );
}

CoveragePage.propTypes = wizardProps;
export default CoveragePage;
