import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class BrandContentService {
    /**
     * Gets opCo logo from brand service
     * @param {String} partnerID 'MSA' but will parameterize
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getLogo = (partnerID, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('brandcontent'),
            'getLogo',
            [partnerID],
            additionalHeaders
        );
    }
}
