import React, {
    useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './E1PPremiumAdjustmentAmountComponent.metadata.json5';

function E1PPremiumAdjustmentAmountComponent(props) {
    const {
        amount,
        id,
        onValidate,
        viewOnlyMode,
        showErrors,
        onValueChange
    } = props;

    const [dataForComponent, setDataForComponent] = useState(
        {
            amountSign: Math.sign(amount) || -1,
            amountAbsoluteValue: Math.abs(amount) || amount
        }
    );

    useEffect(() => {
        if (amount) {
            setDataForComponent({
                amountSign: Math.sign(amount) || -1,
                amountAbsoluteValue: Math.abs(amount) || amount
            });
        }
    }, [amount]);

    const {
        isComponentValid,
        onValidate: setComponentValidation
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [amount, onValidate, isComponentValid, id]);

    const writeValue = useCallback(
        (value, changedPath) => {
            let newVal = value;

            if (changedPath === 'amountAbsoluteValue') {
                newVal = Math.abs(value);

                if (Math.abs(newVal) === 0) {
                    newVal = undefined;
                }
            }

            dataForComponent[changedPath] = newVal;

            const changedAmount = dataForComponent.amountAbsoluteValue
                * dataForComponent.amountSign;

            setDataForComponent(dataForComponent);

            if (onValueChange) {
                onValueChange(changedAmount, 'amount');
            }
        },
        [dataForComponent, onValueChange]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            readOnly: viewOnlyMode,
            showErrors,
            autoComplete: false
        },
        amountSign: {

        },
        absoluteAmount: {

        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={dataForComponent}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

E1PPremiumAdjustmentAmountComponent.propTypes = {
    amount: PropTypes.number.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({ create: PropTypes.func.isRequired }).isRequired,
    viewOnlyMode: PropTypes.bool,
    id: PropTypes.string.isRequired,
    showErrors: PropTypes.bool
};

E1PPremiumAdjustmentAmountComponent.defaultProps = {
    viewOnlyMode: false,
    showErrors: false
};

export default E1PPremiumAdjustmentAmountComponent;
