import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ActivitiesService } from 'e1p-capability-gateway';
import { commonMessages as e1pCommonMessages } from "e1p-platform-translations";
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import PropTypes from 'prop-types';
import metadata from './E1PAssignActivitiesComponent.metadata.json5';
import messages from './E1PAssignActivitiesComponent.messages';
import styles from './E1PAssignActivitiesComponent.modules.scss';


import { Button } from '@jutro/legacy/components';


function E1PAssignActivitiesComponent(props) {
    const {
        activitiesPublicIDs,
        isOpen,
        onResolve,
        onReject,
        authHeader
    } = props;
    const translator = useTranslator();
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation(
        'E1PAssignActivitiesComponent'
    );
    const [exceptions, setExceptions] = useState([]);
    const [isAssigningActivities, updateIsAssigningActivities] = useState(false);
    const [isLoadingUsersQueues, updateIsLoadingUsersQueues] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [assignedToUserOrQueue, updateAssignedToUserQueue] = useState(undefined);
    const [selectedUserOrQueue, updateSelectedUserOrQueue] = useState(undefined);
    const [availableUsers, updateAvailableUsers] = useState([]);
    const [availableQueues, updateAvailableQueues] = useState([]);
    const [assignToUserOrQueueAvailableValues, updateAssignToUserOrQueueAvailableValues] = useState(
        [
            {
                name: translator(messages.assignToUserInMyGroup),
                code: 'assignToUser'
            },
            {
                name: translator(messages.assignToAQueue),
                code: 'assignToQueue'
            }
        ]
    );

    useEffect(() => {
        registerComponentValidation(() => selectedUserOrQueue);
    }, [registerComponentValidation, selectedUserOrQueue]);

    useEffect(() => {
        // defaulting first drop down value to assign to user and calling backend api to get assignable users 
        updateAssignedToUserQueue('assignToUser');
        updateIsLoadingUsersQueues(true);
        ActivitiesService.getAssignableUsersForActivities(activitiesPublicIDs, authHeader)
            .then((assignableUsers) => {
                setExceptions(assignableUsers.exceptions_Ext);

                if (_.isEmpty(assignableUsers.exceptions_Ext)) {
                    updateAvailableUsers(
                        assignableUsers.assignee
                            .sort()
                            .map((assignee) => ({
                                    code: assignee,
                                    name: translator({
                                        id: assignee,
                                        defaultMessage: assignee
                                    })
                                }))
                    );

                    if (_.isEmpty(assignableUsers.assignee)) {
                        /** *
                         * if we don't find any assignable users
                         * we will remove assign to user from first drop down
                         * defaulting first dropdown to assignToQueue and calling backend api to get
                         * assignable queues
                         */
                        const updatedAvailableValues = assignToUserOrQueueAvailableValues
                            .filter((availableValue) => availableValue.code !== 'assignToUser');

                        updateAssignToUserOrQueueAvailableValues(updatedAvailableValues);
                        updateAssignedToUserQueue('assignToQueue');
                        updateIsLoadingUsersQueues(true);
                        ActivitiesService.getAssignableQueuesForCurrentUser(authHeader)
                            .then((assignableQueues) => {
                                setExceptions(assignableUsers.exceptions_Ext);

                                if (_.isEmpty(assignableQueues.exceptions_Ext)) {
                                    // if we have only one assignable queue we are defaulting its value
                                    if (assignableQueues.assignee.length === 1) {
                                        updateSelectedUserOrQueue(assignableQueues.assignee[0])
                                    }

                                    updateAvailableQueues(
                                        assignableQueues.assignee.map((assignee) => ({
                                                code: assignee,
                                                name: translator({
                                                    id: assignee,
                                                    defaultMessage: assignee
                                                })
                                            }))
                                    );
                                }
                            }).finally(() => {
                                updateIsLoadingUsersQueues(false);
                            })
                    }
                }
            }).finally(() => {
                updateIsLoadingUsersQueues(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * callback function when we change first dropdown to select to assign to user or queue
     * based upon selected values(assignToUser/assignToQueue) we will call different backend api
     * We are maintaining two separate use state to store assignable user and assignable queue
     * to avoid additional calls if user changes first drop down multiple times
     */
    const onValueChangeOfAssignUserOrQueue = useCallback((value) => {
        updateAssignedToUserQueue(value);
        updateSelectedUserOrQueue(undefined);
        setExceptions([]);

        if (value === 'assignToQueue') {
            if (_.isEmpty(availableQueues)) {
                updateIsLoadingUsersQueues(true);
                ActivitiesService.getAssignableQueuesForCurrentUser(authHeader)
                    .then((response) => {
                        setExceptions(response.exceptions_Ext);

                        if (_.isEmpty(response.exceptions_Ext)) {
                            if (response.assignee.length === 1) {
                                updateSelectedUserOrQueue(response.assignee[0])
                            }

                            updateAvailableQueues(
                                response.assignee.map((assignee) => ({
                                        code: assignee,
                                        name: translator({
                                            id: assignee,
                                            defaultMessage: assignee
                                        })
                                    }))
                            );
                        }
                    }).finally(() => {
                        updateIsLoadingUsersQueues(false);
                    })
            } else if (availableQueues.length === 1) {
                updateSelectedUserOrQueue(availableQueues[0])
            }
        } else if (value === 'assignToUser') {
            if (_.isEmpty(availableUsers)) {
                updateIsLoadingUsersQueues(true);
                ActivitiesService.getAssignableUsersForActivities(activitiesPublicIDs, authHeader)
                    .then((response) => {
                        setExceptions(response.exceptions_Ext);

                        if (_.isEmpty(response.exceptions_Ext)) {
                            updateAvailableUsers(
                                response.assignee
                                    .sort()
                                    .map((assignee) => ({
                                            code: assignee,
                                            name: translator({
                                                id: assignee,
                                                defaultMessage: assignee
                                            })
                                        }))
                            );
                        }
                    }).finally(() => {
                        updateIsLoadingUsersQueues(false);
                    })
            }
        }

    }, [activitiesPublicIDs, authHeader, availableQueues, availableUsers, translator]);

    const getAvailableUsersOrQueues = useCallback(() => {
        let availableValues = []

        if (assignedToUserOrQueue === 'assignToUser') {
            availableValues = availableUsers;
        }

        if (assignedToUserOrQueue === 'assignToQueue') {
            availableValues = availableQueues;
        }

        return availableValues;
    }, [assignedToUserOrQueue, availableQueues, availableUsers]);

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'top',
            showRequired: true,
            autoComplete: false
        },
        assignToUserOrQueue: {
            availableValues: assignToUserOrQueueAvailableValues,
            value: assignedToUserOrQueue,
            onValueChange: onValueChangeOfAssignUserOrQueue
        },
        selectedUserOrQueue: {
            required: true,
            availableValues: getAvailableUsersOrQueues(),
            value: selectedUserOrQueue,
            onValueChange: ((value) => {
                updateSelectedUserOrQueue(value);
                updateIsPageSubmitted(false);
                setExceptions([]);
            }),
            readOnly: getAvailableUsersOrQueues().length === 1,
            showErrors: (isPageSubmitted),
            searchable: true,
            validationMessages: (isPageSubmitted && !isComponentValid)
                ? [translator(e1pCommonMessages.requiredFieldMessage)]
                : []
        },
        completeMissingFieldMessageDiv: {
            visible: isPageSubmitted
                && !isComponentValid
        },
        wizardSingleErrorComponentError: {
            visible: !_.isEmpty(exceptions)
        },
        WizardSingleErrorComponent: {
            issuesList: exceptions
        },
        assignActivitiesLoader: {
            loaded: !isAssigningActivities && !isLoadingUsersQueues,
            text: isAssigningActivities ? translator(messages.assigningActivities) : null
        },
        assignActivitiesContainer: {
            visible: !isAssigningActivities && !isLoadingUsersQueues
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    const onAssign = useCallback(
        async () => {
            if (!selectedUserOrQueue) {
                updateIsPageSubmitted(true);

                return false;
            }

            updateIsAssigningActivities(true);

            /**
             * depending upon first dropdown value(assign to user or assign to queue)
             * we will call different backend apis
            */
            if (assignedToUserOrQueue === 'assignToUser') {
                await ActivitiesService.assignActivitiesToUser(activitiesPublicIDs, selectedUserOrQueue, authHeader)
                    .then((response) => {
                        updateIsAssigningActivities(false);

                        if (!_.isEmpty(response.exceptions_Ext)) {
                            setExceptions(response.exceptions_Ext)

                            return false;
                        }

                        return onResolve();
                    }).finally(() => {
                        updateIsAssigningActivities(false);

                    })
            }

            if (assignedToUserOrQueue === 'assignToQueue') {
                await ActivitiesService.reassignActivitiesToQueue(activitiesPublicIDs, selectedUserOrQueue, authHeader)
                    .then((response) => {
                        updateIsAssigningActivities(false);

                        if (!_.isEmpty(response.exceptions_Ext)) {
                            setExceptions(response.exceptions_Ext)

                            return false;
                        }

                        return onResolve();
                    }).finally(() => {
                        updateIsAssigningActivities(false);

                    })
            }
        }, [activitiesPublicIDs, assignedToUserOrQueue, authHeader, onResolve, selectedUserOrQueue]
    );

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ModalHeader title={messages.assignActivity} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    model={activitiesPublicIDs}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button hidden={isAssigningActivities || isLoadingUsersQueues} onClick={onCancel} type="outlined">
                    {translator(e1pCommonMessages.cancel)}
                </Button>
                <Button hidden={isAssigningActivities || isLoadingUsersQueues} onClick={onAssign}>
                    {translator(e1pCommonMessages.assign)}
                </Button>
            </ModalFooter>
        </ModalNext>

    );
}

E1PAssignActivitiesComponent.propTypes = {
    activitiesPublicIDs: PropTypes.arrayOf(PropTypes.string),
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    authHeader: PropTypes.string.isRequired
};

E1PAssignActivitiesComponent.defaultProps = {
    viewOnlyMode: false
};

export default E1PAssignActivitiesComponent;
