import { defineMessages } from 'react-intl';

export default defineMessages({
    orderingGeoDataMessage: {
        id: 'quoteandbind.geoDataComponent.Ordering Geo Data...',
        defaultMessage: 'Ordering Geo Data...'
    },
    address: {
        id: 'quoteandbind.geoDataComponent.Address',
        defaultMessage: 'Address'
    },
    zip4: {
        id: 'quoteandbind.geoDataComponent.Zip4',
        defaultMessage: 'Zip4'
    },
    censusBlockGroup: {
        id: 'quoteandbind.geoDataComponent.Census Block Group',
        defaultMessage: 'Census Block Group'
    },
    fipsCode: {
        id: 'quoteandbind.geoDataComponent.Fips Code',
        defaultMessage: 'Fips Code'
    }

});
