import { defineMessages } from 'react-intl';

export default defineMessages({
    allAccounts: {
        id: 'e1p.gateway.dashboard.account.All Accounts',
        defaultMessage: 'All Accounts'
    },
    lastViewed: {
        id: 'e1p.gateway.dashboard.account.Last 100 Accounts viewed',
        defaultMessage: 'Last 100 Accounts viewed'
    },
});
