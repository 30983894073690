import React, {
    useContext, useCallback, useState, useMemo, useEffect
} from 'react';
import {
    findIndex as _findIndex,
    get as _get,
    find as _find,
    set as _set,
    isNil as _isNil,
    isEmpty as _isEmpty,
    isUndefined as _isUndefined,
    includes as _includes
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PaymentHelper } from 'e1p-capability-payment';
import { error as loggerError } from '@jutro/logger';
import { RewriteService } from 'e1p-capability-rewrite';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { TPIUtil, QuoteProposalUtil, OfferingUtil, PropertyFlowUtil, PaymentPageUtil } from 'e1p-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { isRequired } from 'e1p-portals-required-validator-js';
import { useUWIssueUtil, useTPIUtil, e1pUserAccessUtil } from 'e1p-capability-hooks';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import {
    parseErrors
} from '@xengage/gw-portals-edge-validation-js';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import appConfig from 'app-config';
import requiredMetadata from './PaymentDetailsPage.requiredness';

 
import metadata from './PaymentDetailsPage.metadata.json5';
import messages from './PaymentDetailsPage.messages';

function initialiseVM(rewriteVM) {
    rewriteVM.lobData.homeowners_EH.offerings.children[0].paymentPlans.value = rewriteVM.lobData.homeowners_EH.offerings.children[0].paymentPlans.value || {};
}

// need to make paymentus required for INT and above
const environment = _get(appConfig, 'env.AMFAM_ENV', 'local');
const envsWithoutPayment = ['dev', 'local', 'sbx', 'dev2', 'int2', 'qa2', 'dev3', 'gwupgrade1', 'int', 'perf2'];
const isEnvWithPaymentus = !envsWithoutPayment.includes(environment);
const TPI_FULL_PAY = 'af:tpiFullPayR1';

function PaymentDetailsPage(props) {
    const {
        wizardData: rewriteVM,
        updateWizardData,
        steps,
        jumpTo,
        disableAllSteps,
        isSkipping,
        stopSkipping,
        updateWizardSnapshot,
        wizardSnapshot
    } = props;
    const [validationErrors, setValidationErrors] = useState([]);
    const [signatureSuccess, setSignatureSuccess] = useState(false);
    const [isCallingCanPurchase, setIsCallingCanPurchase] = useState(false);
    const [isVMInitialised, updateIsVMInitialised] = useState(false);
    const [bindOperationsInProcess, setBindOperationsInProcess] = useState(false);
    const { authHeader, authUserData } = useAuthentication();
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('PaymentDetailsPage');
    const [underwritingIssuesPopupAppeared, setUnderwritingIssuesPopupAppeared] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isMailingComponentValid, updateIsBillingComponentValid] = useState(true);
    const [isBillingComponentValid, updateIsMailingComponentValid] = useState(true);
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [keepShowingIframeAfterPayment, setKeepShowingIframeAfterPayment] = useState(false);
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const [fieldIssue, setFieldIssue] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [requiredFields, updateRequiredFields] = useState([]);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const [isBillingIdTPIFullPay, setIsBillingIdTPIFullPay] = useState(false);
    const [isConvertToTPIPayor, setIsConvertToTPIPayor] = useState(false);
    const translator = useTranslator();
    const amfamOktaToken = useContext(AmfamOktaTokenContext);
    const PAY_MONTHLY = 'af:payMthly3';
    const { jumptToTPIPageIfTPIOfTrustConditionFails } = useTPIUtil();
    const {
        hasUWIssues,
        showUnderwritingIssuesPopup
    } = useUWIssueUtil(
        rewriteVM,
        updateWizardData,
        jumpTo,
        steps
    );
    const LOB = 'homeowners_EH';
    const isNewBusiness = _get(rewriteVM, 'baseData.value.businessTransactionType_Ext') === 'NewBusiness';
    // paymentRequiredInd is coming from backend which tells us for which rewrite type we need to collect payment
    const isPaymentRequiredBackendInd = _get(rewriteVM, 'value.paymentRequiredInd_Ext');
    const requiresPayment = isEnvWithPaymentus && isPaymentRequiredBackendInd;
    const isPaymentDone = !!_get(rewriteVM, 'bindData.value.paymentRefNumber');
    const isSignatureStatusAsCompleted = _get(rewriteVM, 'value.baseData.signature_Ext.status') === 'COMPLETED';
    // It is used to show loader message 'Preparing Required Documents' when gettingSignatureDocument is true
    const [gettingSignatureDocument, setGettingSignatureDocument] = useState(false);
    // It is used to show loader message 'Saving the Insureds contact information' when isInsuredContactUpdating is true
    const [isInsuredContactUpdating, setIsInsuredContactUpdating] = useState(false);
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);

    const productCode = _get(rewriteVM, 'baseData.productCode.value');
    const tpiPath = TPIUtil.getTPIBasePath(productCode);

    const allTPIs = _get(rewriteVM, `${tpiPath}.value`, []);

    const isBillMortgageeSelected = !!_find(allTPIs, (tpi) => tpi.convertTPIToPayerInd || tpi.sendBillToTPIInd);
    const canUserIssueWithoutPayment = authUserData.permissions_Ext.includes('issuewithoutpayment_ext');

    const { opCo } = amfamOktaToken;
    const { isUserAuthorisedToPayAndBind } = e1pUserAccessUtil(authUserData, opCo);
    const canUserPayAndBindQuote = isUserAuthorisedToPayAndBind();

    useEffect(() => {
        if (!isPaymentDone && requiresPayment) {
            PaymentHelper.getReceiptNumber(
                _get(rewriteVM, 'jobID.value', rewriteVM.jobID?.value), authHeader
            ).then((referenceNumber) => {
                // set value from receipt in case it did not get saved to PC
                if (referenceNumber) {
                    _set(rewriteVM, 'bindData.value.paymentRefNumber', referenceNumber);
                    updateWizardData(rewriteVM);
                }
            }).catch((err) => {
                loggerError(err);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        () => {
            const { selectedOfferingIndex } = OfferingUtil.getSelectedOffering(rewriteVM, LOB);
            //  Checks whether billingID of selected payment plan is TPIFullPay or not
            //  returns true if billingID is TPIFullPay
            const selectedPlan = _get(
                rewriteVM, `lobData.homeowners_EH.offerings.children[${selectedOfferingIndex}].paymentPlans.value`, []
            ).find((plan) => plan.isSelected);

            setIsBillingIdTPIFullPay(selectedPlan?.billingId === TPI_FULL_PAY);

            const shouldConvertTpi = PropertyFlowUtil.shouldConvertTPIToPayer(rewriteVM);

            if (shouldConvertTpi) {
                setIsConvertToTPIPayor(true);
            } else {
                setIsConvertToTPIPayor(false);
            }
        }, [rewriteVM]
    );

    const showPopupIfUWIssueExists = useCallback(() => {
        if (hasUWIssues() && !underwritingIssuesPopupAppeared) {
            showUnderwritingIssuesPopup();
            setUnderwritingIssuesPopupAppeared(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Check quote data dto for validation errors and exceptions
     *   that come from in page api response (not onNext calls)
     */
    const checkForInPageErrors = useCallback(
        (qdd) => {
            const errorsAndWarnings = _get(qdd, 'errorsAndWarnings', []);

            // uw goes in pop up; not top banner
            delete errorsAndWarnings.underwritingIssues;

            const exceptionsPath = 'baseData.exceptions_Ext';
            const errors = parseErrors(errorsAndWarnings);
            const exceptions = _get(qdd, exceptionsPath, []);
            const errorConvertingToFullApp = errors?.length > 0 || exceptions.length > 0;

            if (errorConvertingToFullApp) {
                setValidationErrors(errors.concat(exceptions));
            }
        },
        [],
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isBillingComponentValid && isMailingComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isPageSubmitted, isBillingComponentValid, isMailingComponentValid]);

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = _get(rewriteVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmail = _get(rewriteVM, 'lobData.homeowners_EH.paperlessEmail.value');

        return isPaperlessEmailUpdated && pniEmail && pniEmail !== paperlessEmail;
    }, [rewriteVM, isPaperlessEmailUpdated]);

    const checkCanPurchase = useCallback(() => {
        setIsCallingCanPurchase(true);
        RewriteService.canPurchase(rewriteVM.value, authHeader)
            .then((response) => {
                // have to set uw issues from the can purchase so the util can see them
                _set(
                    rewriteVM.value,
                    'errorsAndWarnings.underwritingIssues',
                    response.errorsAndWarnings.underwritingIssues
                );

                // this is to hide paymentus component if there is any validation error
                const fieldError = _get(response, 'errorsAndWarnings.validationIssues.fieldIssues', []);

                setFieldIssue(!!(fieldError.length));

                const licensedProducers = _get(response, 'licensedProducers.licensedProducers', []);

                _set(rewriteVM.value, 'licensedProducers', response.licensedProducers);
                // check for validation errors
                checkForInPageErrors(response);
                // update wizard data with any uw issues and show if exists
                updateWizardData(rewriteVM);
                showPopupIfUWIssueExists();

                // set producer code options for signin agents
                if (licensedProducers.length > 0) {
                    setProducerCodeOptions(
                        licensedProducers.map((value) => ({
                                code: value.producerID,
                                name: `${value.producerName.firstName} ${value.producerName.lastName}`
                            }))
                    );
                }
            })
            .finally(() => {
                setIsCallingCanPurchase(false);
            });
    }, [authHeader, checkForInPageErrors, rewriteVM, showPopupIfUWIssueExists, updateWizardData]);

    const hasSignatureRequiredFormsInCurrentJob = rewriteVM.lobData.homeowners_EH?.hasSignatureRequiredFormsInCurrentJob?.value;

    useEffect(() => {
        // IAP-1278
        // jumping to tpi page if tpi of trust condition fails
        const hasJumped = jumptToTPIPageIfTPIOfTrustConditionFails(
            rewriteVM,
            jumpTo,
            steps,
            '/third-party-interest');

        if (hasJumped) {
            return true
        }
         
        if (!isSkipping && hasSignatureRequiredFormsInCurrentJob) {
            if (isPaymentDone && requiresPayment) {
                disableAllSteps();
            }

            // doesn't matter if payment is done or not, we need licensed producers
            checkCanPurchase();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSignatureRequiredFormsInCurrentJob]);


    useEffect(() => {
        if (!isVMInitialised) {
            initialiseVM(rewriteVM);
            updateIsVMInitialised(true);
        }
    }, [isVMInitialised, rewriteVM]);

    const setErrors = useCallback((removeUnnecessaryErrors = false) => {
        const shouldShowMissingPaymentError = requiresPayment && !isPaymentDone && !(isBillingIdTPIFullPay || isConvertToTPIPayor) && !canUserIssueWithoutPayment;

        const isSignatureMissing = _get(rewriteVM, 'baseData.signatureType_ext.value') === undefined || !signatureSuccess;
        const isUwIssuePresent = hasUWIssues();

        const uniqueErrors = PaymentPageUtil.getValidationErrorsForPaymentPage(
            validationErrors, shouldShowMissingPaymentError, isSignatureMissing, isUwIssuePresent, translator, removeUnnecessaryErrors);

        setValidationErrors(uniqueErrors);

    }, [
        validationErrors, requiresPayment, isPaymentDone, isBillingIdTPIFullPay,
        isConvertToTPIPayor, rewriteVM, signatureSuccess, hasUWIssues, translator, canUserIssueWithoutPayment
    ]);

    useEffect(() => {
        if (signatureSuccess || isPaymentDone) {
            setErrors(true);
        }
        // want to trigger this only on signatureSuccess update and payment update
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureSuccess, isPaymentDone]);


    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            setErrors();
            window.scrollTo(0, 0);

            return false;
        }

        setBindOperationsInProcess(true);

        const quoteData = _get(rewriteVM, 'quoteData');

        // If below int, we still need to set a payment ref number
        //   because we will still get a validation issue
        if (!requiresPayment) {
            const { paymentReferenceNumberForLocal } = appConfig.amfamRewritePaymentConfig;

            _set(rewriteVM, 'bindData.value.paymentRefNumber', paymentReferenceNumberForLocal);
        }

        const response = await RewriteService.bind([rewriteVM.value], authHeader);

        response.quoteData = quoteData.value;
        rewriteVM.value = response;
        setBindOperationsInProcess(false);
        updateWizardData(rewriteVM);

        return rewriteVM;
    }, [authHeader, requiresPayment, isComponentValid, setErrors, rewriteVM, updateWizardData]);

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();

            if (quoteProposalLink) {
                window.open(quoteProposalLink, '_blank');

                return true;
            }
        }, [quoteProposalLink]
    );

    // When payment option changes, we need to get a new doc url
    useEffect(() => {
        if (rewriteVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                rewriteVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            rewriteVM.paymentOptionChanged = false;
        }
    }, [authHeader, rewriteVM, rewriteVM.paymentOptionChanged]);

    const onSave = useCallback(
        async () => {
            try {
                if (!isMailingComponentValid || !isBillingComponentValid) {
                    updateIsPageSubmitted(true);
                    window.scrollTo(0, 0);

                    return false;
                }

                setIsSavingCurrentPageChanges(true);

                const signatureType = _get(rewriteVM.value, 'baseData.signatureType_ext', undefined);
                // We get licensedProducers in canPurchase call so storing it in variable before we do saveAndQuote
                const licensedProducers = _get(rewriteVM, 'value.licensedProducers');

                rewriteVM.value = await RewriteService.saveAndQuote(
                    [rewriteVM.value], authHeader
                );
                _set(rewriteVM.value, 'licensedProducers', licensedProducers);
                _set(rewriteVM.value, 'baseData.signatureType_ext', signatureType);
                QuoteProposalUtil.fetchQuoteProposal(
                    rewriteVM,
                    setQuoteProposalCompleted,
                    setQuoteProposalLink,
                    authHeader,
                    setIsQuoteProposalFailed
                );
                updateWizardData(rewriteVM);

                const fieldIssues = _get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        },
        [
            authHeader, isBillingComponentValid, isMailingComponentValid, 
            rewriteVM, updateWizardData, updateWizardSnapshot
        ]
    );


    /** *
     * using convertedFromQuickToFullTemp dummy variable, need to check why payment page is loading 3 times
     */
    useEffect(() => {
        stopSkipping();
        QuoteProposalUtil.fetchQuoteProposal(
            rewriteVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );

        const policyState = _get(rewriteVM, 'baseData.policyAddress.state.value.code');
        const initialRequiredFields = ['consentToRateAcknowledgement']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Filter the selected offering
    const selectedQuoteOffering = _get(rewriteVM, 'quoteData.offeredQuotes.value').find(
        (quoteOffering) => quoteOffering.selected
    );

    const selectedOffering = _get(rewriteVM, 'lobData.homeowners_EH.offerings.value').find((offering) => offering.branchCode === selectedQuoteOffering.branchCode);

    const ratePlans = _get(selectedOffering, 'ratePlans', []);

    // UseMemo to compute CTR and NCRB plan offering details if rate plan changes
    const { ctrPlanOffering, ncrbPlanOffering } = useMemo(
        () => {
            // CTR and NCRB premium details for consent to rate acknowledgement 
            const ctrOffering = ratePlans?.find((plan) => plan.ratePlanType === 'CTR');

            const ncrbOffering = ratePlans?.find((plan) => plan.ratePlanType === 'NCRB');

            return {
                ctrPlanOffering: ctrOffering,
                ncrbPlanOffering: ncrbOffering
            };
        },
        [ratePlans]
    );

    const availableValuesForPaymentOptions = useMemo(() => {
        const correctOffering = rewriteVM.lobData.homeowners_EH.offerings.value[0];

        if (correctOffering.paymentPlans !== undefined) {
            return correctOffering.paymentPlans
                .filter((paymentPlans) => paymentPlans.name)
                .reduce(
                    (paymentPlanMap, paymentPlan) => ({
                        ...paymentPlanMap,
                        [paymentPlan.billingId]: paymentPlan
                    }),
                    {}
                );
        }

        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rewriteVM.lobData.homeowners_EH.offerings.value)]);

    // ctrPremium should be based of selected payment plan premium
    const ctrPremium = availableValuesForPaymentOptions[
        _get(rewriteVM, 'bindData.selectedPaymentPlan.value')
    ]?.total?.amount;

    /**
     * Helper callback which opens the "Third-Party Interest" wizard page.
     */
    const openTPIPageHandler = useCallback(() => {
        const indexForTPIPage = _findIndex(steps, ({ path }) => path === '/third-party-interest');

        jumpTo(indexForTPIPage);
    }, [steps, jumpTo]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isInsuredContactUpdating) {
            loadingMessage = translator(e1pCommonMessages.savingContactinfoMessage);
        } else if (gettingSignatureDocument) {
            loadingMessage = translator(e1pCommonMessages.prepareReqDocuments);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (bindOperationsInProcess) {
            loadingMessage = translator(messages.completingYourPurchaseMessage);
        }

        return loadingMessage;
    }, [gettingSignatureDocument, isInsuredContactUpdating, isSavingCurrentPageChanges, bindOperationsInProcess, translator]);

    const handleContactInfoUpdate = useCallback(async () => {
        // If paperless Email id exist and pni email id is undefiend, then update pni email to paperless email
        if (_isUndefined(_get(rewriteVM.value, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1'))
            && !!_get(rewriteVM.value, 'lobData.homeowners_EH.paperlessEmail')) {
            _set(rewriteVM.value, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1',
                _get(rewriteVM.value, 'lobData.homeowners_EH.paperlessEmail'));
        }

        // sync NI changes to householdoccupants
        //   changes to NI will not override data in the occ so must be synced
        const householdOccupants = _get(rewriteVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
        const pni = _get(rewriteVM.value, 'lobData.homeowners_EH.primaryNamedInsured');
        const pniOccupant = householdOccupants
            .find((occupant) => occupant.person.publicID === pni.person.publicID);

        if (pniOccupant) {
            // updates to existing pni contact
            _set(pniOccupant, 'person', pni.person);
        }

        const signatureType = _get(rewriteVM.value, 'baseData.signatureType_ext', undefined);

        // contact change requires another quote
        rewriteVM.value = await RewriteService.saveAndQuote(
            [rewriteVM.value], authHeader
        );
        _set(rewriteVM.value, 'baseData.signatureType_ext', signatureType);
        updateWizardSnapshot(rewriteVM);
        updateWizardData(rewriteVM);
    }, [authHeader, rewriteVM, updateWizardData, updateWizardSnapshot]);

    const getRiskAddress = () => {
        const { addressLine1, addressLine2, city, state, postalCode } = _get(rewriteVM, 'lobData.homeowners_EH.coverables.yourHome.ehlocation.value');

        return `${addressLine1}, ${addressLine2 ? `${addressLine2}, ` : ''}${city}, ${state} ${postalCode}`;
    };

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !bindOperationsInProcess && !isCallingCanPurchase && !isSavingCurrentPageChanges && !gettingSignatureDocument
            && !isInsuredContactUpdating, [bindOperationsInProcess, gettingSignatureDocument, isCallingCanPurchase, isInsuredContactUpdating, isSavingCurrentPageChanges]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        paymentDetailsPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        paymentDetailsPageContainer: {
            visible: !bindOperationsInProcess && !isCallingCanPurchase && !isSavingCurrentPageChanges && !isInsuredContactUpdating,
            // Facing some issues, if i try to hide the container using visibility. 
            // Initially, visibility is false so all the components will be unmount 
            // when the visibility become true then all components will be mount again 
            // it will re-trigger all the react hook components so handled hiding using CSS
            className: gettingSignatureDocument ? 'display-none' : ''
        },
        twelveMonthsCheckboxField: {
            disabled: true
        },
        monthlyPaymentScheduleComponent: {
            quoteID: _get(rewriteVM, 'quoteID.value', rewriteVM.jobID?.value),
            authHeader,
            transactionTotalAmount: !_isNil(
                availableValuesForPaymentOptions[
                    _get(rewriteVM, 'bindData.selectedPaymentPlan.value')
                ]
            )
                ? availableValuesForPaymentOptions[
                    _get(rewriteVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined,
            changeInCost: rewriteVM.transactionCost?.value,
            startDate: rewriteVM.baseData.periodStartDate.value,
            endDate: rewriteVM.baseData.periodEndDate.value,
            jobTypeCode: rewriteVM.baseData.jobType.value.code,
            offerings: _get(rewriteVM, 'lobData.homeowners_EH.offerings.value')
        },
        quoteProposalLinkContainer: {
            onClick: (e) => openDocument(e),
            disabled: !quoteProposalCompleted
        },
        totalPremiumID: {
            value: !_isNil(
                availableValuesForPaymentOptions[
                    _get(rewriteVM, 'bindData.selectedPaymentPlan.value')
                ]
            )
                ? availableValuesForPaymentOptions[
                    _get(rewriteVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined
        },
        totalPremiumWithFullPayID: {
            value: !_isNil(availableValuesForPaymentOptions[PAY_MONTHLY])
                ? availableValuesForPaymentOptions[PAY_MONTHLY].total
                : undefined
        },
        mailingAndBillingAddressComponent: {
            transactionVM: rewriteVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            viewOnlyMode: false,
            updateIsMailingComponentValid,
            updateIsBillingComponentValid,
            showErrors: isPageSubmitted
        },
        consentToRateMessage: {
            // if CTR plan exists and if CTR is selected then Consent to rate acknowledgement should be displayed with NCRB and CTR premiums
            visible: _includes(requiredFields, 'consentToRateAcknowledgement') && ctrPlanOffering && ctrPlanOffering.isSelected,
            content: translator(messages.consentToRateAcknowledgementMessage,
                {
                    NCRBPremium: ncrbPlanOffering?.totalPremium,
                    CTRPremium: ctrPremium
                }),
        },
        paymentOptionsID: {
            submissionVM: rewriteVM,
            authHeader,
            updateWizardData,
            LOB,
            viewOnly: isPaymentDone
        },
        addThirdPartyInterestButton: {
            disabled: isPaymentDone && requiresPayment
        },
        // IAP-371 show TPI component in only read only Mode
        e1ptpiDisplayTableContainer: {
            transactionVM: rewriteVM,
            authHeader,
            updateWizardData,
            setIsSavingTPI: () => { },
            showErrors: false,
            onValidate: () => { },
            disregardFieldValidationParentPage: () => { },
            viewOnlyMode: true,
            showTitle: false
        },
        displayPNIAddress: {
            readOnly: true
        },
        riskAddress: {
            value: getRiskAddress()
        },
        AutoPayOptionToggle: {
            // Auto-Pay button should be visible after payment is made, so that user can see the selection made.
            visible: !isBillMortgageeSelected,
            disabled: isPaymentDone && requiresPayment
        },
        paymentusComponent: {
            submissionVM: rewriteVM,
            amfamAccessToken: amfamOktaToken.amfamAccessTokenHeader,
            wizardSnapshot,
            updateWizardData,
            visible: canUserPayAndBindQuote
                && (!isPaymentDone || keepShowingIframeAfterPayment) && requiresPayment
                && !hasUWIssues()
                && !fieldIssue
                // If payment plan is tpi full pay; payment is not required
                && !(isBillingIdTPIFullPay || isConvertToTPIPayor),
            setKeepShowingIframeAfterPayment,
            setValidationErrors,
            disableAllSteps,
            LOB
        },
        signatureComponent: {
            submissionVM: rewriteVM,
            updateWizardData,
            authHeader,
            LOB,
            onSignatureSuccess: setSignatureSuccess,
            disabled: signatureSuccess,
            readOnly: hasUWIssues(),
            producerCodeOptions,
            setProducerCodeOptions,
            setGettingSignatureDocument,
            // Only make it visible after payment ref number is available & canPurchase call is not in progress
            // or payment plan is tpi full pay
            visible: (() => {
                let visible = hasSignatureRequiredFormsInCurrentJob;

                // always show for tpi if signature forms are there
                if ((isBillingIdTPIFullPay || isConvertToTPIPayor || canUserIssueWithoutPayment) && hasSignatureRequiredFormsInCurrentJob) {
                    // break out of function
                    return true;
                }

                // payment not done and it was required
                if (!isPaymentDone && requiresPayment) {
                    visible = false;
                }

                // screen is loading
                if (isCallingCanPurchase) {
                    visible = false;
                }

                return visible;
            })(),
            isSignatureRequired: hasSignatureRequiredFormsInCurrentJob,
            saveLocationCodeChanges: onSave,
            checkCanPurchase,
            locationCodeEditable: true
        },
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        insuredContactContainer: {
            visible: !isNewBusiness
        },
        deliverDocumentsIndComponent: {
            visible: authUserData.permissions_Ext.includes('docdeliveryind_ext'),
            deliverDocInd: _get(rewriteVM, 'value.shouldDeliverDocuments_Ext'),
            setDeliverDocInd: ((value) => {
                _set(rewriteVM, 'value.shouldDeliverDocuments_Ext', value);
                updateWizardData(rewriteVM);
            })
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        insuredContactInfo: {
            setIsPaperlessEmailUpdated,
            setIsInsuredContactUpdating
        },
        quoteProposalFailureErrorDiv: {
            visible: isQuoteProposalFailed
        },
        userNotAuthorisedErrorDiv: {
            visible: !canUserPayAndBindQuote
        }
    };

    const handleUpdateAutoPay = useCallback(async (value, path) => {
        // there's no convert to full app so no need to check if this is getting called from the initial page load
        setIsSavingCurrentPageChanges(true);
        _set(rewriteVM, path, value);
        // auto pay ind needs to immediately because after payment is made fields will be locked
        rewriteVM.value = await RewriteService.saveAndQuote([rewriteVM.value], authHeader);
        updateWizardData(rewriteVM);
        setIsSavingCurrentPageChanges(false);
    }, [authHeader, rewriteVM, updateWizardData])

    const resolvers = {
        resolveCallbackMap: {
            openTPIPageHandler,
            onValidate,
            onSaveClick: handleContactInfoUpdate,
            onUpdateAutoPay: handleUpdateAutoPay
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, rewriteVM, id, path, overrideProps),
        [rewriteVM, overrideProps]
    );

    /**
     * need to check payment reference Number and SignatureType, if ENV is not Localhost.
     * for localhost we are defaulting payment reference number
     */
    const isPageValid = useCallback(() => {
        // IF payment plan is tpi full pay; payment is not required
        if (requiresPayment && !isPaymentDone && !(isBillingIdTPIFullPay || isConvertToTPIPayor) && !canUserIssueWithoutPayment) {
            return false;
        }

        const isQuoted = _get(rewriteVM, 'baseData.periodStatus.value.code') === 'Quoted';
        let isSignatureDone = _get(rewriteVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;
        let licensedProducerSelected = _get(rewriteVM, 'value.bindData.licensedProducerID', false);

        if (!hasSignatureRequiredFormsInCurrentJob) {
            // signature not required
            isSignatureDone = true;
            licensedProducerSelected = true;
        }

        return isQuoted && isSignatureDone && !hasUWIssues() && licensedProducerSelected;
    }, [
        requiresPayment, isPaymentDone, rewriteVM, signatureSuccess,
        hasSignatureRequiredFormsInCurrentJob, isBillingIdTPIFullPay,
        isConvertToTPIPayor, hasUWIssues, canUserIssueWithoutPayment
    ]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, signatureSuccess, rewriteVM, isPaymentDone]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            nextLabel={messages.issueLabel}
            onNext={onNext}
            disablePrevious={(isPaymentDone && requiresPayment) || gettingSignatureDocument || isInsuredContactUpdating}
            disableNext={gettingSignatureDocument || isInsuredContactUpdating}
            showNext={canUserPayAndBindQuote}
            showCancel={!isSignatureStatusAsCompleted}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && (!isMailingComponentValid || !isBillingComponentValid)
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PaymentDetailsPage.propTypes = wizardProps;
export default PaymentDetailsPage;
