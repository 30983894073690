import React, {
    useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@jutro/legacy/components';

function OverflowScrollingComponent(props) {
    const {
        checkScrolling,
        setCheckScrolling,
        scrollableDiv,
        scrollWidth
    } = props;
    const [leftScrollVisibility, setLeftScrollVisibility] = useState(false);
    const [rightScrollVisibility, setRightScrollVisibility] = useState(false);

    const setScrollVisibility = useCallback(() => {
        if (scrollableDiv) {
            const hasHorizontalScrollbar = scrollableDiv.scrollWidth > scrollableDiv.clientWidth;

            if (hasHorizontalScrollbar) {
                if (scrollableDiv !== undefined && scrollableDiv.scrollLeft !== 0) {
                    setLeftScrollVisibility(true);
                } else {
                    setLeftScrollVisibility(false);
                }

                if (scrollableDiv === undefined) {
                    setRightScrollVisibility(true);
                } else {
                    const currentPosition = scrollableDiv.scrollLeft;

                    scrollableDiv.scrollLeft += scrollWidth;

                    const scrolledAllTheWayRight = currentPosition === scrollableDiv.scrollLeft;

                    scrollableDiv.scrollLeft = currentPosition;

                    if (scrollableDiv === undefined || !scrolledAllTheWayRight) {
                        setRightScrollVisibility(true);
                    } else {
                        setRightScrollVisibility(false);
                    }
                }
            } else {
                setLeftScrollVisibility(false);
                setRightScrollVisibility(false);
            }
        }
    }, [scrollWidth, scrollableDiv]);

    const handleNav = (direction) => {
        if (direction === 'left') {
            scrollableDiv.scrollLeft -= scrollWidth;
        } else {
            scrollableDiv.scrollLeft += scrollWidth;
        }

        setScrollVisibility();
    };


    useEffect(() => {
        setScrollVisibility();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollableDiv]);

    useEffect(() => {
        if (checkScrolling) {
            setScrollVisibility();
            setCheckScrolling(false);
        }
    }, [checkScrolling, setCheckScrolling, setScrollVisibility]);

    return (
        <div>
            <IconButton
                id="leftButton"
                icon="mi-chevron-left"
                iconColor="neutral"
                onClick={() => handleNav('left')}
                hidden={!leftScrollVisibility}
            />
            <IconButton
                id="rightButton"
                icon="mi-chevron-right"
                iconColor="neutral"
                onClick={() => handleNav('right')}
                hidden={!rightScrollVisibility}
            />
        </div>
    );
}

/**
 * Define expected property types to be passed into this Jutro component.
 */
OverflowScrollingComponent.propTypes = {
    checkScrolling: PropTypes.bool,
    setCheckScrolling: PropTypes.func,
    scrollableDiv: PropTypes.shape({
        scrollWidth: PropTypes.number,
        clientWidth: PropTypes.number,
        scrollLeft: PropTypes.number
    }),
    scrollWidth: PropTypes.number
};

/**
 * Define default property values to be passed into this Jutro component.
 */
OverflowScrollingComponent.defaultProps = {
    checkScrolling: false,
    setCheckScrolling: undefined,
    scrollableDiv: undefined,
    scrollWidth: 310
};

export default OverflowScrollingComponent;
