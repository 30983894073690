import React, { useCallback, useEffect } from 'react';
import {
    get as _get,
    isEmpty as _isEmpty
} from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import styles from './InsideWallMaterialComponent.scss';
import metadata from './InsideWallMaterialComponent.metadata.json5';
import './InsideWallMaterialComponent.messages';

function InsideWallMaterialComponent(props) {
    const {
        data: insideWallMaterialVM,
        path,
        id,
        onValidate,
        onValueChange,
        viewOnly,
        showErrors,
        index
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [insideWallMaterialVM, id, onValidate, isComponentValid]);

    /**
     * E1PAP1PC-15716:E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const insideWallMaterialContainerElement = document.getElementById(`insideWallMaterialsGrid${index}`);

        if (insideWallMaterialContainerElement
            && _isEmpty(_get(insideWallMaterialVM, 'value.materialType'))) {
            const materialTypeElement = insideWallMaterialContainerElement.querySelector('input[id="materialType"]');

            if (materialTypeElement && materialTypeElement.focus !== undefined) {
                materialTypeElement.focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnly,
            showErrors,
            required: true,
            autoComplete: false
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                insideWallMaterialVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [insideWallMaterialVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={insideWallMaterialVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

InsideWallMaterialComponent.propTypes = {
    data: PropTypes.shape({}),
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    viewOnly: PropTypes.bool,
    showErrors: PropTypes.bool,
    index: PropTypes.number.isRequired
};
InsideWallMaterialComponent.defaultProps = {
    data: {},
    path: undefined,
    viewOnly: false,
    showErrors: false
};

export default InsideWallMaterialComponent;
