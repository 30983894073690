import React, { Component } from 'react';
import { Chevron, withModalContext } from '@jutro/components';
import moment from 'moment';
import PropTypes from 'prop-types';
import appConfig from 'app-config';
import _ from 'lodash';
import {
    TranslatorContext, withIntl
} from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { PolicyService } from 'gw-capability-gateway';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';

import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { e1pDateUtil } from 'e1p-capability-hooks';
import { PolicyDetailsService } from 'e1p-capability-gateway';
import { E1PPreRenewalDirectionComponent, E1PReorderCreditModalComponent } from 'e1p-capability-policyjob-react';
import { PreRenewalDirectionService, RenewalService } from 'e1p-capability-renewal';
import { withAmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import PolicyTransactions from '../PolicyTransactions/PolicyTransactions';
import metadata from './Summary.metadata.json5';
import styles from './Summary.module.scss';
import messages from './Summary.messages';
import gatewayMessages from '../../gateway.messages';

const productMap = {
    PersonalAuto_EA: 'personalAuto_EA',
    Homeowners_EH: 'homeowners_EH',
    PersonalUmbrella_EU: 'personalUmbrella_EU'
};

const statesThatNeedToHideReorderCreditButton = ['PA'];
const expiredAndWithdrawnStatusArray = ['Expired', 'Withdrawn'];

class SummaryWithoutModalContext extends Component {
    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    policyNumber: PropTypes.string
                }),
                updatePreRenewalDirection: PropTypes.func.isRequired,
                prerenewalDirectionTypeValues: PropTypes.arrayOf(
                    PropTypes.shape({})
                ).isRequired,
                getPolicyDetails: PropTypes.func.isRequired
            })
        }).isRequired,
        // CUSTOM E1P - Not segmenting because of dependencies
        history: PropTypes.shape({
            push: PropTypes.func,
            location: PropTypes.shape({
                pathname: PropTypes.string,
            }),
        }).isRequired,
        authHeader: PropTypes.shape({}),
        intl: PropTypes.shape({}),
        authUserData: PropTypes.shape({
            userType: PropTypes.string
        }),
        viewModelService: PropTypes.shape({
            create: PropTypes.func,
            productMetadata: PropTypes.shape({
                get: PropTypes.func
            })
        }).isRequired
    };

    static contextType = TranslatorContext;

    state = {
        policyData: '',
        disableCancelLink: true,
        disableChangePolicyLink: true,
        policyTransactionStatusValues: [],
        selectedTransactionStatus: 'all',
        searchTransactionKeyword: undefined,
        canAdvanceCancellation: false,
        // To check whether an existing reinstatement is in progress,
        isReinstatementStarted: false,
        isManualRenewalStarted: false,
        openTransactions: [],
        completedTransactions: [],
        loading: false,
        isAbinito: false,
        policyView: {
            asOfDate: new Date()
        },
        agencyName: '',
        // Needs to be separate from loading because
        //   getPolicyTransactions is setting loading to false before
        //   the agency details are loaded
        gettingAgencyName: false,
        isDeletingPreRenewalDirection: false,
        isRenewingPolicy: false,
        exceptions: []
    };

    // eslint-disable-next-line react/no-arrow-function-lifecycle
    componentDidMount = () => {
        this.hasAdvanceCancellationPermission();
        this.setPolicyCoveragesDetails();
        this.getPolicyTransactions();
        this.getAgencyName();
    };

    setPolicyCoveragesDetails = () => {
        const {
            fromAccountLanding: { policyDetailsData }
        } = this.props;
        const policyData = _.cloneDeep(policyDetailsData.policyResponse);
        const isAbinito = _.get(policyData, 'latestPeriod.cancellationReason_Ext') === 'abInitio_ext';
        const policyEffectiveDate = _.get(policyData, 'latestPeriod.effectiveDate');
        const policyExpirationDate = _.get(policyData, 'latestPeriod.expirationDate');
        // It will set asOfDate in policysummary screen to view policy details
        const asOfDate = this.getDefaultAsOfDate(policyEffectiveDate, policyExpirationDate)

        this.setState({ policyData, isAbinito, policyView: {
            asOfDate
        } });
        
    };

    // Get Agency details only for MSA opCo
    getAgencyName = async () => {
        const { authHeader, fromAccountLanding, isMSAOpCo } = this.props;

        if(!isMSAOpCo) {
            return;
        }

        this.setState({
            gettingAgencyName: true
        });

        const externalID = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.latestPeriod.externalID_Ext'
        );

        PolicyDetailsService.getProducerDetails(
            externalID,
            authHeader
        ).then((policyDetails) => {
            const { name } = policyDetails;

            this.setState({
                agencyName: name
            });
        }).catch(() => {
            this.setState({
                agencyName: ''
            });
        }).finally(() => {
            this.setState({
                gettingAgencyName: false
            });
        });
    }

    getPolicyTransactions = async () => {
        const { authHeader, fromAccountLanding, setJobNumber } = this.props;

        this.setState({
            loading: true
        });

        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const transactionResponse = await PolicyService.getPolicyTransactionsForPolicy(
            policyNumber,
            authHeader
        );
        const closedStatuses = ['Bound', 'Withdrawn', 'Rescinded', 'Not-taken', 'Non-renewed', 'Expired', 'Declined'];
        const completedTransaction = transactionResponse
            .filter((transactionResp) => closedStatuses.includes(transactionResp.status));
        const openTransactions = transactionResponse
            .filter((transactionResp) => !closedStatuses.includes(transactionResp.status));
        const policyTransactionStatusValues = this.getPolicyTransactionStatus();

        const submissionTrx = openTransactions.find((openTrx)=> openTrx.type === 'Submission');
        
        if(submissionTrx){
            setJobNumber(submissionTrx.jobNumber);
        }

        this.setState({
            policyTransactionStatusValues,
            selectedTransactionStatus: policyTransactionStatusValues[0].code,
            openTransactions,
            completedTransactions: completedTransaction,
            loading: false
        });
    };

    hasAdvanceCancellationPermission = () => {
        const { authUserData } = this.props;
        const canAdvanceCancellation = authUserData?.permissions_Ext.includes('advancecancellation');

        this.setState({ canAdvanceCancellation });
    };

    onChangePolicy = async () => {
        const { policyData } = this.state;
        const { fromAccountLanding, history } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const { lobEndorsementURL, endorsementProducts } = appConfig;
        const productCode = _.get(policyData, 'product.productCode');
        const latestPeriod = {};

        // Need these too data fields to start the change
        latestPeriod.cancellationDate = _.get(policyData, 'latestPeriod.cancellationDate');
        latestPeriod.effectiveDate = _.get(policyData, 'latestPeriod.effectiveDate');

        if (endorsementProducts.includes(productCode)) {
            history.push(lobEndorsementURL[productCode], { policyNumber, latestPeriod });
        } else {
            this.setState({ disableChangePolicyLink: false });
        }
    };

    onRenewalPolicy = async () => {
        // Renew Confirmation Pop Up
        const results = await this.props.modalContext.showConfirm({
            title: messages.renewTransactionTitle,
            message: messages.renewalConfirmationMessage,
            confirmButtonText: messages.startRenewalLabel,
            cancelButtonText: messages.cancelLabel
        });

        if (results === 'cancel') {
            return _.noop();
        }

        this.setState({
            isRenewingPolicy: true
        });

        const { authHeader } = this.props;
        const { fromAccountLanding, history } = this.props;
        const policy = _.get(fromAccountLanding, 'policyDetailsData.policyResponse');
        const policyNumber = _.get(policy, 'policyNumber');

        // service call to start the renew process upon confirmation
        return RenewalService.createRenewalTransaction(policyNumber, authHeader)
            .then((result) => {
                if (result) {
                    // Retrieving the updated transaction List inorder to
                    // refresh the open and completed Policy Transactions
                    this.getPolicyTransactions();

                    const getUpdatedPolicyDetails = _.get(fromAccountLanding, 'policyDetailsData.getPolicyDetails');

                    getUpdatedPolicyDetails();
                    history.push(`/policies/${policyNumber}/summary`);
                    this.setState({ isManualRenewalStarted: true });
                }

                return true;
            })
            .catch((error) => {
                const errorMessage = _.get(error, 'baseError');

                if (errorMessage) {
                    this.setState({
                        exceptions: [{ description: errorMessage.split('ErrorMessage:')[1] }]
                    });
                }
            }).catch(_.noop)
            .finally(() => {
                this.setState({
                    isRenewingPolicy: false
                });
            });
    };

    // out of the box cancle button click function
    // handleCancelButtonClick = () => {
    //     this.setState({ disableCancelLink: false });
    // };

    // E1P custom function for cancle button click
    handleCancelButtonClickE1P = () => {
        this.setState({ disableCancelLink: false });

        const { fromAccountLanding, history } = this.props;
        const policyNumber = _.get(fromAccountLanding, 'policyDetailsData.policyResponse.policyNumber');
        const jobNumber = null;
        const routeParam = { policyNumber, jobNumber };

        return history.push('/cancel',
            { routeParam });
    }

    handleReinstatementButtonClick = () => {
        this.setState({ disableCancelLink: false });

        const { fromAccountLanding, history } = this.props;
        const policyNumber = _.get(fromAccountLanding, 'policyDetailsData.policyResponse.policyNumber');

        return history.push('/reinstatement',
            { policyNumber });
    }

    handleRewriteButton = () => {
        const { policyData } = this.state;
        const { lobRewriteURL } = appConfig;

        this.setState({ disableCancelLink: false });

        const { fromAccountLanding, history } = this.props;
        const policyNumber = _.get(fromAccountLanding, 'policyDetailsData.policyResponse.policyNumber');
        const productCode = _.get(policyData, 'product.productCode');
        const policyType = policyData.lobs[productMap[productCode]].policyType_Ext;

        return history.push(lobRewriteURL[productCode],
            { policyNumber, policyType });
    }

    /**
     * E1P custom handler. Not segmented because there are so many dependencies
     * @param {Object} evt Callback props needed
     */
    handleViewButtonClick = async () => {
        const { fromAccountLanding, history } = this.props;
        const { policyView, policyData } = this.state;
        const { productCode } = _.get(policyData, 'product');
        const { policyViewUrl } = appConfig;
        const policyNumber = _.get(fromAccountLanding, 'policyDetailsData.policyResponse.policyNumber');
        const policyType = policyData.lobs[productMap[productCode]].policyType_Ext;

        return history.push({
            pathname: policyViewUrl,
            policyType,
            productCode,
            viewOnly: true,
            policyNumber,
            selectedDate: policyView.asOfDate
        });
    }

    /**
     * Helper function to refresh the summary page,
     * so that all the button visibility should be based on the
     * updated value of the policy
     */
    refreshPage = async () => {
        this.setState({
            loading: true
        });

        const {
            fromAccountLanding: {
                policyDetailsData: {
                    getPolicyDetails
                }
            }
        } = this.props;

        if (getPolicyDetails) {
            await getPolicyDetails();
        }

        this.setState({
            loading: false
        });
    }

    /**
     * Helper function triggers when user clicks on Add Pre-Renewal Direction
     * opens popup to add prerenewal direction
     * @returns {Object}
     */
    onAddOfPreRenewalDirection = async () => {
        const translator = this.context;
        const {
            authHeader,
            fromAccountLanding,
            viewModelService
        } = this.props;
        const policyData = _.get(fromAccountLanding, 'policyDetailsData.policyResponse');
        const preRenewalDirectionLoadDetailsVM = viewModelService
            .create(
                {
                    policyNumber: policyData.policyNumber
                },
                'pc', 'amfam.edge.capabilities.gateway.renewal.dto.PreRenewalDirectionDetailsDTO'
            );

        const componentProps = {
            title: translator(messages.addPreRenewalDirection),
            iconClassType: false,
            showCancelBtn: true,
            showSaveBtn: true,
            authHeader,
            policyData,
            initialPreRenewalDirectionVM: preRenewalDirectionLoadDetailsVM
        };

        const result = await this.props.modalContext
            .showModal(<E1PPreRenewalDirectionComponent {...componentProps} />);

        const prerenewalDirection = _.get(result, 'value.selectedPreRenewalDirection');

        if (prerenewalDirection) {
            const updatePreRenewalDirection = _.get(fromAccountLanding, 'policyDetailsData.updatePreRenewalDirection');

            updatePreRenewalDirection(prerenewalDirection);
            // refresh the page after adding or removing preRenewalDirection
            this.refreshPage();
        }

        return result;
    }

    /**
     * Helper function triggers when user clicks on Reorder Credit
     * Deletes prerenewal direction for given policy
     */
    onReorderCredit = async () => {
        const { authHeader, fromAccountLanding } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );

        const policyExpiryDate = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.latestPeriod.expirationDate'
        );

        const componentProps = {
            authHeader,
            policyNumber,
            effectiveDate: policyExpiryDate
        };

        const result = await this.props.modalContext
            .showModal(<E1PReorderCreditModalComponent {...componentProps} />);

        if (result) {
            this.getPolicyTransactions();
        }
    }

    isReorderCreditButtonVisible = () => {
        const { authUserData } = this.props;
        const { policyData } = this.state;
        const { renewalProducts } = appConfig;
        const productCode = _.get(policyData, 'product.productCode');
        const policyState = _.get(policyData, 'latestPeriod.baseState_Ext');
        // Have to hide reorder credit button if policy type is Personal Auto & policy state is PA.
        const hideCreditReorderButtonInPAPolicy = (productCode === 'PersonalAuto_EA' && statesThatNeedToHideReorderCreditButton.includes(policyState));
        // To enable reorder credit only for the product codes defined for renewal in Appconfig
        const isValidRenewalProductCode = renewalProducts.includes(productCode);
        // check for user permissions for credit reconsideration
        const canReorderCredit = authUserData.permissions_Ext.includes('addreconsideration_ext');
        // check if policy is cancelled - cancelled policies should not have reorder credit button
        const isPolicyCancelled = _.get(policyData, 'latestPeriod.canceled');

        return canReorderCredit && isValidRenewalProductCode && !isPolicyCancelled
            && !hideCreditReorderButtonInPAPolicy;
    }

    /**
     * Helper function triggers when user clicks on Delete Pre-Renewal Direction
     * Deletes prerenewal direction for given policy
     * @returns {Object}
     */
    onDeleteOfPreRenewalDirection = () => {
        this.setState({
            isDeletingPreRenewalDirection: true
        });

        const translator = this.context;
        const { authHeader, fromAccountLanding } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );

        return PreRenewalDirectionService
            .deletePreRenewalDirection(policyNumber, authHeader)
            .then(() => {
                // update states so that prerenewal direction gets updated on summary page
                const updatePreRenewalDirection = _.get(
                    fromAccountLanding,
                    'policyDetailsData.updatePreRenewalDirection'
                );

                updatePreRenewalDirection(undefined);
                // refresh the page after adding or removing preRenewalDirection
                this.refreshPage();
            })
            .catch(() => {
                this.props.modalContext.showAlert({
                    title: translator(messages.deletePreRenewalDirectionFailedTitle),
                    message: translator(messages.deletePreRenewalDirectionFailedMessage),
                    status: 'error',
                    icon: 'mi-error-outline'
                }).catch(_.noop);
            })
            .finally(() => {
                this.setState({
                    isDeletingPreRenewalDirection: false
                });
            });
    }

    onDoNotCancel = () => {
        this.setState({ disableCancelLink: true });
    };

    onDoNotChangePolicy = () => {
        this.setState({ disableChangePolicyLink: true });
    };

    // eslint-disable-next-line arrow-body-style
    getHeaderSection = (title) => {
        return (isOpen) => (
            <React.Fragment>
                <Chevron isOpen={isOpen} align="right" />
                <h2 className={`${styles.title} ${styles.gwOverrideDefaultMargin}`}>{title}</h2>
            </React.Fragment>
        );
    };

    isRewriteButtonVisible = () => {
        const { authUserData } = this.props;
        const { policyData, isAbinito } = this.state;
        let canRewrite = authUserData?.permissions_Ext.includes('createrewrite');

        // IAP_5173, Agent can only do rewrite for Property policy
        // for agent we have to chcek role i.e ext_sales_service
        if (authUserData?.roles_Ext.includes('ext_sales_service')) {
            // IAP-5604, temporary hide rewrite option for agent,
            // put back the below condition in if block, once backend issues is resolved
            // && policyData.product.productCode !== 'Homeowners_EH'
            canRewrite = false;
        }

        if (policyData.latestPeriod.canceled && !isAbinito && canRewrite) {
            return true;
        }

        return false;
    }

    isRenewalButtonVisible = () => {
        const { authUserData } = this.props;
        const { policyData, isAbinito, completedTransactions } = this.state;
        const { renewalProducts } = appConfig;
        // check if policy is cancelled - cancelled policies should not have start renewal button
        const isPolicyCancelled = _.get(policyData, 'latestPeriod.canceled');
        const productCode = _.get(policyData, 'product.productCode');
        // To enable renewal functionality only for the product codes defined in Appconfig
        const isValidRenewalProductCode = renewalProducts.includes(productCode);

        if (!isPolicyCancelled && isValidRenewalProductCode) {
            // Permission to create Renewal
            const createRenewalPermission = authUserData.permissions_Ext.includes('createrenewal');
            // Permission to start Manual Renewal when the policy status is set to NonRenewed
            const nonRenewPermission = authUserData.permissions_Ext.includes('nonrenew');
            // Permission to start Manual Renewal when the policy status is set to NotTaken
            const notTakeRenewalPermission = authUserData.permissions_Ext.includes('nottakerenewal');
            const completedNonRenewTransactions = completedTransactions
                .filter((transactionResp) => ['NonRenewed'].includes(transactionResp.status));
            const completedNotTakenRenewalTransactions = completedTransactions
                .filter((transactionResp) => ['NotTaken'].includes(transactionResp.status));

            // If the transaction status is Non-Renewed then only user with
            // nonrenew permission can start manual renewal
            if (completedNonRenewTransactions?.length) {
                return nonRenewPermission && createRenewalPermission;
            }

            // If the transaction status is Not-Taken then only user with
            // nottakerenewal permission can start manual renewal
            if (completedNotTakenRenewalTransactions?.length) {
                return notTakeRenewalPermission && createRenewalPermission;
            }

            // User with createRenewalPermission can start manual Renewal
            if (createRenewalPermission && !isAbinito) {
                return true;
            }
        }

        return false;
    }

    /**
     * Helper function which checks if we have any renewal transaction
     * other than bound or withdrawn status
     * @returns {Boolean}
     */
    renewalJobPresentNotWithdrawnOrBound = () => {
        const { openTransactions } = this.state;

        // openTransactions contains transactions which are not bound and not withdrawn
        // so only checking of transaction type
        return openTransactions
            .some((transaction) => transaction.type === 'Renewal');
    }

    /**
     * Helper function which checks whether addPrerenewalDirection button is visible or not
     * addPrerenewalDirection button will be visible if
     *  1. we don't have preRenewalDirection set
     *  2. user has editprerenewal permission
     *  3. policy is policyIssued
     *  4. we don't have renewal transaction other than bound or withdrawn status
     * @returns {Boolean}
     */
    isAddPrerenewalDirectionButtonVisible = () => {
        const { policyData } = this.state;
        const { renewalProducts } = appConfig;
        const productCode = _.get(policyData, 'product.productCode');
        // To enable renewal functionality only for the product codes defined in Appconfig
        const isValidRenewalProductCode = renewalProducts.includes(productCode);

        if (isValidRenewalProductCode) {
            const { fromAccountLanding, authUserData } = this.props;
            const renewalPresent = this.renewalJobPresentNotWithdrawnOrBound();
            const preRenewalDirection = _.get(
                fromAccountLanding,
                'policyDetailsData.policyResponse.preRenewalDirection_Ext'
            );
            const policyIssued = _.get(
                fromAccountLanding,
                'policyDetailsData.policyResponse.issued'
            );
            const canAddPrerenwalDirection = authUserData.permissions_Ext.includes('editprerenewal');
            const isPolicyCancelled = _.get(
                fromAccountLanding,
                'policyDetailsData.policyResponse.latestPeriod.canceled'
            );

            if (!preRenewalDirection
                && canAddPrerenwalDirection
                && policyIssued
                && !renewalPresent
                && !isPolicyCancelled) {
                return true;
            }
        }

        return false;
    }

    /**
     * Helper function which checks whether deletePrerenewalDirection button is visible or not
     * @returns {Boolean}
     */
    isDeletePrerenewalDirectionButtonVisible = () => {
        const { policyData } = this.state;
        const { renewalProducts } = appConfig;
        const productCode = _.get(policyData, 'product.productCode');
        // To enable renewal functionality only for the product codes defined in Appconfig
        const isValidRenewalProductCode = renewalProducts.includes(productCode);

        if (isValidRenewalProductCode) {
            const { fromAccountLanding, authUserData } = this.props;
            const renewalPresent = this.renewalJobPresentNotWithdrawnOrBound();
            const hasPrerenwalDirectionPermission = authUserData.permissions_Ext.includes('editprerenewal');
            const preRenewalDirection = _.get(
                fromAccountLanding,
                'policyDetailsData.policyResponse.preRenewalDirection_Ext'
            );
            const isPolicyCancelled = _.get(
                fromAccountLanding,
                'policyDetailsData.policyResponse.latestPeriod.canceled'
            );

            if (preRenewalDirection
                && hasPrerenwalDirectionPermission
                && !renewalPresent
                && !isPolicyCancelled) {
                return true;
            }
        }

        return false;
    }

    isReinstatementButtonVisible = () => {
        const { authUserData } = this.props;
        const { policyData, isAbinito } = this.state;
        const policyInceptionPeriod = policyData.periods.find((period) => period.jobType_Ext === 'Submission');
        let isVisible = false;
        const canReinstate = authUserData?.permissions_Ext.includes('createreinstate');

        if (policyData.latestPeriod.canceled && canReinstate) {
            const policyInceptionDate = new Date(policyInceptionPeriod.effectiveDate);
            const latestPeriodCancellationDate = new Date(policyData.latestPeriod.cancellationDate);
            const currentDate = new Date();

            let diffIndays = e1pDateUtil.differenceInDays(
                latestPeriodCancellationDate,
                currentDate
            );

            if (diffIndays > 60 && isAbinito) {
                // user should not be allowed to reinstate
                return false;
            }

            diffIndays = e1pDateUtil.differenceInDays(
                policyInceptionDate,
                latestPeriodCancellationDate
            );

            // policy is flat cancelled or policy is inforce for atleast 1 day, user should be allowed to reinstate
            if (diffIndays >= 0 && !isAbinito) {
                isVisible = true;
            }
        }

        return isVisible;
    }

    /**
     * Helper function which checks the currentdate in-between effective and expiration date,
     * returns currentdate if it satisfy the condition else policyEffectivedate
     * @param {Date} effectiveDate 
     * @param {Date} expirationDate 
     * @returns {Date}
     */
    getDefaultAsOfDate = (effectiveDate, expirationDate) => {
        const currentDate = e1pDateUtil.getFormattedCurrentDate();
        // checkIfDateIsInRange function requires year, month, day formatterd date
        const policyEffectiveDate = e1pDateUtil.getFormattedCustomDate(new Date(effectiveDate));
        const policyExpirationDate = e1pDateUtil.getFormattedCustomDate(new Date(expirationDate));
        
        return e1pDateUtil.checkIfDateIsInRange(currentDate, policyEffectiveDate, policyExpirationDate)
            ? currentDate : effectiveDate
    }

    getPolicyTransactionStatus = () => [
            {
                code: 'all',
                name: gatewayMessages.all
            },
            {
                code: 'Draft',
                name: {
                    id: 'typekey.PolicyPeriodStatus.Draft',
                    defaultMessage: 'Draft'
                }
            },
            {
                code: 'Quoted',
                name: {
                    id: 'typekey.PolicyPeriodStatus.Quoted',
                    defaultMessage: 'Quoted'
                }
            }
        ];

    getPolicyTransactionStatusForCompletedTransactions = () => [
            {
                code: 'all',
                name: gatewayMessages.allCompletedTransactions
            },
            {
                code: 'Bound',
                name: gatewayMessages.issued
            },
            {
                code: 'Declined',
                name: {
                    id: 'typekey.PolicyPeriodStatus.Declined',
                    defaultMessage: 'Declined'
                }
            },
            {
                code: 'Not-taken',
                name: {
                    id: 'typekey.PolicyPeriodStatus.NotTaken',
                    defaultMessage: 'Not-taken'
                }
            },
            {
                code: 'Non-renewed',
                name: {
                    id: 'typekey.PolicyPeriodStatus.NonRenewed',
                    defaultMessage: 'Non-renewed'
                }
            }
        ];

    getPolicyTransactionStatusForExpiredAndWithdrawnTransactions = () => [
            {
                code: 'all',
                name: gatewayMessages.all
            },
            {
                code: 'Expired',
                name: {
                    id: 'typekey.PolicyPeriodStatus.Expired',
                    defaultMessage: 'Expired'
                }
            },
            {
                code: 'Withdrawn',
                name: {
                    id: 'typekey.PolicyPeriodStatus.Withdrawn',
                    defaultMessage: 'Withdrawn'
                }
            }
        ];

    setIsReinstatementStarted = (value) => {
        this.setState({
            isReinstatementStarted: value
        });
    };

    setAsOfDateValue = (value) => {
        this.setState({
            policyView: { asOfDate: moment(value) }
        });
    }

    getPartnerMembsershipLevelDisplayName = (membershipCode) => {
        const translator = this.context;

        return membershipCode ? translator({ id: `typekey.MembershipLevel_Ext.${membershipCode}`, defaultMessage: membershipCode }) : '-';
    };

    render() {
        const {
            policyData,
            disableCancelLink,
            policyTransactionStatusValues,
            selectedTransactionStatus,
            searchTransactionKeyword,
            disableChangePolicyLink,
            canAdvanceCancellation,
            isReinstatementStarted,
            isManualRenewalStarted,
            openTransactions,
            completedTransactions,
            loading,
            policyView,
            isAbinito,
            agencyName,
            gettingAgencyName,
            isDeletingPreRenewalDirection,
            isRenewingPolicy,
            exceptions
        } = this.state;

        const {
            authHeader, history, fromAccountLanding, intl, authUserData
        } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const productCode = _.get(policyData, 'product.productCode');

        if (_.isEmpty(policyData)) {
            return null;
        }

        const policyType = policyData.lobs[productMap[productCode]].policyType_Ext;
        const isUWTermination = _.get(policyData, 'latestPeriod.cancellationReason_Ext', '') === 'uwreasons';
        const translator = this.context;
        const viewPolicyVisible = policyData.issued;
        const preRenewalDirection = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.preRenewalDirection_Ext'
        );
        const prerenewalDirectionTypeValues = _.get(
            fromAccountLanding,
            'policyDetailsData.prerenewalDirectionTypeValues'
        );
        const policyIssued = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.issued'
        );
        const overrides = {
            openTransactions: {
                transactionResponse: openTransactions,
                typeOfTransactions: 'OPEN', // ['OPEN', 'COMPLETED', 'EXPIRED_AND_WITHDRAWN']
                policyTransactionStatusValues,
                setIsReinstatementStarted: this.setIsReinstatementStarted,
                intl,
                policyType,
                productCode,
                visible: policyTransactionStatusValues.length > 0,
                authHeader,
                history
            },
            completedTransactions: {
                // IAP-2618: we need to filter expired and withdrawn from other completed transactions
                transactionResponse: completedTransactions.filter((transactionResp) => (
                        !expiredAndWithdrawnStatusArray.includes(transactionResp.status)
                    )),
                typeOfTransactions: 'COMPLETED', // ['OPEN', 'COMPLETED', 'EXPIRED_AND_WITHDRAWN']
                policyTransactionStatusValues: this.getPolicyTransactionStatusForCompletedTransactions(),
                setIsReinstatementStarted: this.setIsReinstatementStarted,
                intl,
                policyType,
                productCode,
                authHeader,
                history
            },
            expiredAndWithdrawnTransactions: {
                // IAP-2618: we need to filter expired and withdrawn from other completed transactions
                transactionResponse: completedTransactions.filter((transactionResp) => (
                        expiredAndWithdrawnStatusArray.includes(transactionResp.status)
                    )),
                typeOfTransactions: 'EXPIRED_AND_WITHDRAWN', // ['OPEN', 'COMPLETED', 'EXPIRED_AND_WITHDRAWN']
                policyTransactionStatusValues: this.getPolicyTransactionStatusForExpiredAndWithdrawnTransactions(),
                setIsReinstatementStarted: this.setIsReinstatementStarted,
                intl,
                policyType,
                productCode,
                authHeader,
                history
            },
            detailsSectionLoader: {
                loaded: !gettingAgencyName,
                text: translator(messages.loadingAgency)
            },
            detailsSectionId: {
                header: this.getHeaderSection(translator(messages.Details)),
                visible: !gettingAgencyName
            },
            coveragesSection: {
                header: this.getHeaderSection(translator(messages.Coverages)),
            },
            WizardSingleErrorComponent: {
                issuesList: exceptions
            },
            cancellationContacts: {
                visible: !disableCancelLink && disableChangePolicyLink,
                history,
                authHeader,
                policyNumber,
                policyData: _.get(fromAccountLanding, 'policyDetailsData.policyResponse')
            },
            changePolicyContacts: {
                visible: !disableChangePolicyLink && disableCancelLink,
                history,
                authHeader,
                policyNumber,
                policyData: _.get(fromAccountLanding, 'policyDetailsData.policyResponse')
            },
            changePolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                // E1P Custom: Move This Component, Remove changes from ootb
                visible: policyData.latestPeriod.canChange && !isAbinito
            },
            renewPolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                visible: this.isRenewalButtonVisible() && !isManualRenewalStarted && policyIssued
            },
            cancelPolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                visible:
                    !policyData.latestPeriod.canceled
                    && policyData.latestPeriod.canCancel
                    && canAdvanceCancellation
                    && !isAbinito
                    && policyIssued
            },
            policyVehicleInfo: {
                visible: policyData.product.productCode === 'PersonalAuto',
                value: policyData.lobs.personalAuto
            },
            policyHouseOwnersInfo: {
                visible:
                    productCode === 'Homeowners'
                    || productCode === 'HOPHomeowners'
                    || productCode === 'homeowners'
                    || productCode === 'HomeownersLine_HOE',
                value: policyData.lobs.homeOwners
            },
            policyTransactionStatus: {
                availableValues: policyTransactionStatusValues,
                value: selectedTransactionStatus
            },
            policyInceptionValueId: {
                value: e1pDateUtil.convertToUTCForDateWithoutTZ(
                    _.get(policyData, 'latestPeriod.effectiveDate')
                )
            },
            policyExpirationValueId: {
                value: e1pDateUtil.convertToUTCForDateWithoutTZ(
                    _.get(policyData,'latestPeriod.expirationDate')
                )
            },
            searchFilter: {
                value: searchTransactionKeyword
            },
            preRenewalDirectionValueId: {
                value: !_.isNil(
                    preRenewalDirection
                ) ? preRenewalDirection
                    : translator(messages.SummaryDetailsPreRenewalDirectionNone),
                availableValues: prerenewalDirectionTypeValues,
                visible: authUserData?.permissions_Ext.includes('viewprerenewal')
            },
            producerOfRecordValueId: {
                value: `${agencyName}(${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.externalID_Ext'
                )})`
            },
            producerOfServiceValueId: {
                value: `${agencyName}(${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.externalID_Ext'
                )})`
            },
            preferredUnderwriterValueId: {
                value: _.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.assignedUWName_Ext', ''
                )
            },
            reinstatementButtonID: {
                visible: this.isReinstatementButtonVisible()
                    && !isReinstatementStarted && (authUserData.permissions_Ext.includes('reinstateuwtermination_ext') || !isUWTermination)
            },
            rewriteButtonID: {
                visible: this.isRewriteButtonVisible() && (authUserData.permissions_Ext.includes('rewriteuwtermination_ext') || !isUWTermination)
            },
            policyTransactionsLoader: {
                loaded: !loading,
                text: translator(messages.loadingPolicy)
            },
            policyTransactionsMainContainer: {
                visible: !loading
            },
            policySummaryAsOfDate: {
                visible: viewPolicyVisible
            },
            asOfDate: {
                dateDTO: policyView.asOfDate,
                updateDateDto: (value, path) => this.setAsOfDateValue(value, path),
                defaultValue: policyView.asOfDate
            },
            addPrerenewalDirectionButtonId: {
                visible: this.isAddPrerenewalDirectionButtonVisible()
            },
            deletePrerenewalDirectionButtonId: {
                visible: this.isDeletePrerenewalDirectionButtonVisible()
            },
            policySummaryPageActionLoader: {
                loaded: !isDeletingPreRenewalDirection && !isRenewingPolicy,
                text: isDeletingPreRenewalDirection
                    ? translator(messages.deletingPreRenewalDirection)
                    : translator(messages.creatingRenewal)
            },
            policySummaryPageMainGrid: {
                visible: !isDeletingPreRenewalDirection && !isRenewingPolicy
            },
            reorderCreditButtonId: {
                visible: this.isReorderCreditButtonVisible() && policyIssued
            },
            summaryDetailGridProducer: {
                visible: this.props.isMSAOpCo
            },
            summaryDetailGridPartner: {
                visible: this.props.isCONNECTOpCo
            },
            partnerValue: {
                value: (() => {
                    const { operatingCompanyConfig } = appConfig;

                    if (this.props.isCONNECTOpCo) {
                        const partnerId = _.get(
                            fromAccountLanding,
                            'policyDetailsData.policyResponse.latestPeriod.partnerId_Ext'
                        );
                        // With the opCo selector there is a chance an MSA policy could be looked at here.
                        //   If partner ID is not in the connect experiences, will show a dummy message.
                        //   There won't be a situation like this for a prod user.
                        const dummyMessage = 'NOT A CONNECT POLICY';

                        return _.get(operatingCompanyConfig, `CONNECT.experiences[${partnerId}].partnerDisplayName`, dummyMessage);
                    }
                })()

            },
            partnerMembershipId: {
                visible: !!_.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.membership_Ext.membershipNumber'),
                value: _.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.membership_Ext.membershipNumber')
            },
            detailGridCostcoMembershipIdColumn: {
                visible: !!_.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.membership_Ext.membershipNumber')
            },
            partnerMembershipLevel: {
                visible: !!_.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.membership_Ext.membershipLevel'),
                value: this.getPartnerMembsershipLevelDisplayName(_.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.membership_Ext.membershipLevel'))
            },
            detailGridCostcoMembershipLevelColumn: {
                visible: !!_.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.membership_Ext.membershipLevel')
            },
            partnerAdvisorName: {
                visible: !!_.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.advisor_Ext.advisorName'),
                value: _.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.advisor_Ext.advisorName')
            },
            detailGridAdvisorNameColumn: {
                visible: !!_.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.advisor_Ext.advisorName'),
            },
            partnerAdvisorId: {
                visible: !!_.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.advisor_Ext.advisorID'),
                value: _.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.advisor_Ext.advisorID')
            },
            detailGridAdvisorIdColumn: {
                visible: !!_.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.advisor_Ext.advisorID')
            },
            partnerAdvisorLocation: {
                visible: !!_.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.advisor_Ext.advisorLocation'),
                value: _.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.advisor_Ext.advisorLocation')
            },
            detailGridAdvisorLocationColumn: {
                visible: !!_.get(fromAccountLanding, 'policyDetailsData.policyResponse.latestPeriod.advisor_Ext.advisorLocation')
            },
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getJobNumberLink: this.getJobNumberLink,
                onChangePolicy: this.onChangePolicy,
                onRenewalPolicy: this.onRenewalPolicy,
                // E1P Custom change for cancel button action
                handleCancelButtonClick: this.handleCancelButtonClickE1P,
                onDoNotCancel: this.onDoNotCancel,
                onDoNotChangePolicy: this.onDoNotChangePolicy,
                handleViewButtonClick: this.handleViewButtonClick,
                handleReinstatementButtonClick: this.handleReinstatementButtonClick,
                handleRewriteButton: this.handleRewriteButton,
                sortDate: DatatableUtil.sortDate,
                onAddOfPreRenewalDirection: this.onAddOfPreRenewalDirection,
                onDeleteOfPreRenewalDirection: this.onDeleteOfPreRenewalDirection,
                onReorderCredit: this.onReorderCredit
            },
            resolveComponentMap: {
                WizardSingleErrorComponent,
                policyTransactions: PolicyTransactions
            }
        };
        const readValue = (id, path) => readViewModelValue(metadata.pageContent, policyData, id, path, overrides);

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyData}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={readValue}
            />
        );
    }
}

const Summary = withModalContext(SummaryWithoutModalContext);

export const SummaryComponent = Summary;
export default withIntl(withViewModelService(withAmfamOktaTokenContext(withAuthenticationContext(Summary))));
