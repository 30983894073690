import React, { useContext, useCallback } from 'react';
import _ from 'lodash';
import { WizardPageTemplateWithTitle } from 'e1p-portals-wizard-react';
import { OOSConflictsRewritePageComponent } from 'e1p-capability-policyjob-react';
/**
 * Exports function to add conflicts page
 * and lands user on conflicts page
 * 
 * @param {object} stepsRef
 * @param {number} currentStepIndex
 * @param {func} changeNextSteps
 * @param {func} jumpTo
 * @param {func} updateWizardSnapshot
 */

function useOOSConflictPageLandingUtil(
    stepsRef,
    currentStepIndex,
    changeNextSteps,
    jumpTo
) {
    const conflictsPage = {
        id: 'OOSConflictsRewritePage',
        path: '/change-conflicts',
        component: OOSConflictsRewritePageComponent,
        title: 'Change Conflicts',
        stepProps: {
            template: WizardPageTemplateWithTitle
        },
    };

    /**
     * Helper function to add conflicts Page if its not present already in steps
     */
    const addChangeConflictsPage = useCallback(() => {
        const remainingSteps = stepsRef.current
            .slice(currentStepIndex + 1, stepsRef.current.length);
        if (!_.find(stepsRef.current, ['path', conflictsPage.path])) {
            const paymentPageIndex = _.findIndex(remainingSteps, ({ path }) => path === '/payment-details');
            remainingSteps.splice(paymentPageIndex, 0, conflictsPage);
        }
        changeNextSteps(remainingSteps);
    }, [changeNextSteps, currentStepIndex, conflictsPage, stepsRef]);

    /**
     * Helper function to remove conflicts Page if its present
     */
    const removeChangeConflictsPage = useCallback(() => {
        const remainingSteps = stepsRef.current
            .slice(currentStepIndex + 1, stepsRef.current.length);
        if (_.find(stepsRef.current, ['path', conflictsPage.path])) {
            const conflictPageIndex = _.findIndex(remainingSteps, ({ path }) => path === '/change-conflicts');
            remainingSteps.splice(conflictPageIndex, 1);
        }
        changeNextSteps(remainingSteps);
    }, [changeNextSteps, currentStepIndex, conflictsPage, stepsRef]);

    /**
     * Helper function to land user on conflicts page
     */
    const jumpToConflictsPage = useCallback(() => {
        const conflictsPageIndex = _.findIndex(stepsRef.current, ({ path }) => path === '/change-conflicts');
        jumpTo(conflictsPageIndex, true);
    }, [jumpTo, stepsRef]);

    const removeOrAddAndLandOnConflictsPage = useCallback(async (hasConflicts) => {
        if (hasConflicts) {
            addChangeConflictsPage();
            jumpToConflictsPage();
            return;
        }
        removeChangeConflictsPage();
    }, [addChangeConflictsPage, jumpToConflictsPage, removeChangeConflictsPage]);

    return {
        removeOrAddAndLandOnConflictsPage
    };
}

export default useOOSConflictPageLandingUtil;
