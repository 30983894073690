import React, { useCallback, useEffect, useState, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '@jutro/layout';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { get as _get, isEqual as _isEqual } from 'lodash';
import { ToggleField } from '@jutro/components';

import { IconButton, InputField, DropdownSelectField } from '@jutro/legacy/components';

function EUVehicleExposureGridComponent(props) {
    const {
        vehicleExposures,
        id,
        onValidate,
        onValueChange,
        viewOnlyMode,
        showErrors,
        isWatercraftExposure,
        isRecreationVehicleExposure,
        isPersonalVehicleExposure,
        newVehicleExposureTitle,
        removeVehicleExposure
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    const { authUserData } = useAuthentication();
    const translator = useContext(TranslatorContext);

    const [vehicleExposureLabels, setVehicleExposureLabels] = useState([]);
    const [vehicleExposureFields, setVehicleExposureFields] = useState([]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        // removing onValidate from depedency array, it was rendering continously
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isComponentValid]);

    useEffect(() => {
        let alwaysShowLabels = [
            {
                id: 'vehicleYear',
                content: {
                    id: 'e1p.platform.common.Year',
                    defaultMessage: 'Year',
                },
                className: 'grid-label required',
            }
        ];

        if(isRecreationVehicleExposure){
            alwaysShowLabels.unshift({
                id: 'vehicleType',
                content: {
                    id: 'e1p.platform.common.Vehicle Type',
                    defaultMessage: 'Vehicle Type',
                },
                className: 'grid-label required',
            })
        }

        if (!isWatercraftExposure) {
            alwaysShowLabels.push({
                id: 'vehicleVin',
                content: {
                    id: 'e1p.platform.common.VIN',
                    defaultMessage: 'VIN',
                },
                className: 'grid-label required',
            });
        }

        alwaysShowLabels = alwaysShowLabels.concat([
            {
                id: 'vehicleMake',
                content: {
                    id: 'e1p.platform.common.Make',
                    defaultMessage: 'Make',
                },
                className: 'grid-label required',
            },
            {
                id: 'vehicleModel',
                content: {
                    id: 'e1p.platform.common.Model',
                    defaultMessage: 'Model',
                },
                className: 'grid-label required',
            }
        ]);

        if (isWatercraftExposure) {
            alwaysShowLabels = alwaysShowLabels.concat([
                {
                    id: 'watercraftType',
                    content: {
                        id: 'e1p.platform.lob.eu.Watercraft Type',
                        defaultMessage: 'Watercraft Type',
                    },
                    className: 'grid-label',
                },
                {
                    id: 'hinNumber',
                    content: {
                        id: 'e1p.platform.lob.eu.HIN/Serial Number',
                        defaultMessage: 'HIN/Serial Number',
                    },
                    className: 'grid-label',
                },
                {
                    id: 'boatLength',
                    content: {
                        id: 'e1p.platform.lob.eu.Length (ft)',
                        defaultMessage: 'Length (ft)',
                    },
                    className: 'grid-label required',
                },
                {
                    id: 'horsePower',
                    content: {
                        id: 'e1p.platform.lob.eu.Horsepower',
                        defaultMessage: 'Horsepower',
                    },
                    className: 'grid-label required',
                },

            ]);
        }

        if (isPersonalVehicleExposure) {
            alwaysShowLabels = alwaysShowLabels.concat([
                {
                    id: 'isAntique',
                    content: {
                        id: 'e1p.platform.lob.eu.Antique/Classic',
                        defaultMessage: 'Antique/Classic',
                    },
                    className: 'grid-label required',
                },
                {
                    id: 'greaterThan15k',
                    content: {
                        id: 'e1p.platform.lob.eu.GWVR Greater than 15,000 lbs',
                        defaultMessage: 'GWVR Greater than 15,000 lbs',
                    },
                    className: 'grid-label required',
                },
            ]);
        }

        alwaysShowLabels.push({
            id: 'compOnly',
            content: {
                id: 'e1p.platform.common.Comp Only',
                defaultMessage: 'Comp Only',
            },
            className: 'grid-label required',
        });

        if (isWatercraftExposure) {
            alwaysShowLabels.push(
                {
                    id: 'description',
                    content: {
                        id: 'e1p.platform.common.Description',
                        defaultMessage: 'Description',
                    },
                    className: 'grid-label',
                }
            );
        }

        if (isRecreationVehicleExposure) {
            alwaysShowLabels.push({
                id: 'isLicensedForRoadUse',
                content: {
                    id: 'e1p.platform.lob.eu.Licensed for Road Use',
                    defaultMessage: 'Licensed for Road Use',
                },
                className: 'grid-label required',
            });
        }

        if (isPersonalVehicleExposure) {
            alwaysShowLabels = alwaysShowLabels.concat([
                {
                    id: 'isVehiclePassengerVan',
                    content: {
                        id: 'e1p.platform.lob.eu.Is Vehicle a 12+ Passenger Van',
                        defaultMessage: 'Is Vehicle a 12+ Passenger Van',
                    },
                    className: 'grid-label required',
                },
                {
                    id: 'isCompanyFurnishedVehicle',
                    content: {
                        id: 'e1p.platform.lob.eu.Company Furnished Vehicle',
                        defaultMessage: 'Company Furnished Vehicle',
                    },
                    className: 'grid-label required',
                }
            ]);
        }

        if (!_isEqual(alwaysShowLabels, vehicleExposureLabels)) {
            setVehicleExposureLabels(alwaysShowLabels);
        }
        
    }, [isPersonalVehicleExposure, isRecreationVehicleExposure, isWatercraftExposure, vehicleExposureLabels]);

    const getVehicleExposureFields = useCallback(() => {
        let baseFields = [

            {
                id: 'year',
                type: 'field',
                component: 'input',
                componentProps: {
                    path: 'year',
                    required: true
                },
            }
        ];

        if (isRecreationVehicleExposure) {
            baseFields.unshift({
                id: 'vehicleType',
                type: 'field',
                component: 'dropdownSelect',
                componentProps: {
                    path: 'recreationalVehicleType',
                    searchable: true,
                    required: true
                },
            })
        }

        if (!isWatercraftExposure) {
            baseFields.push({
                id: 'vin',
                type: 'field',
                component: 'input',
                componentProps: {
                    path: 'vin',
                    required: true
                },
            });
        }

        baseFields = baseFields.concat(
            [
                {
                    id: 'make',
                    type: 'field',
                    component: 'input',
                    componentProps: {
                        path: 'make',
                        required: true
                    },
                },
                {
                    id: 'model',
                    type: 'field',
                    component: 'input',
                    componentProps: {
                        path: 'model',
                        required: true
                    },
                }
            ]
        );

        if (isWatercraftExposure) {
            baseFields = baseFields.concat(
                [
                    {
                        id: 'watercraftType',
                        type: 'field',
                        component: 'dropdownSelect',
                        componentProps: {
                            path: 'watercraftType',
                            searchable: true,
                        },
                    },
                    {
                        id: 'hin',
                        type: 'field',
                        component: 'input',
                        componentProps: {
                            path: 'hin',
                        },
                    },
                    {
                        id: 'boatLength',
                        type: 'field',
                        component: 'input',
                        componentProps: {
                            path: 'boatLength',
                            required: true,
                            type: 'number'
                        },
                    },
                    {
                        id: 'horsePower',
                        type: 'field',
                        component: 'input',
                        componentProps: {
                            path: 'horsePower',
                            required: true
                        },
                    }
                ]
            );
        }

        if (isPersonalVehicleExposure) {
            baseFields = baseFields.concat([
                {
                    id: 'isAntique',
                    type: 'field',
                    component: 'Toggle',
                    componentProps: {
                        controlClassName: 'toggleContainer',
                        required: true,
                        availableValues: [
                            {
                                code: 'true',
                                name: {
                                    id: 'e1p.platform.common.Yes',
                                    defaultMessage: 'Yes',
                                },
                            },
                            {
                                code: 'false',
                                name: {
                                    id: 'e1p.platform.common.No',
                                    defaultMessage: 'No',
                                },
                            },
                        ],
                        path: 'isAntiqueClassic',
                    },
                },
                {
                    id: 'isGrossWeightOverThreshold',
                    type: 'field',
                    component: 'Toggle',
                    componentProps: {
                        controlClassName: 'toggleContainer',
                        required: true,
                        availableValues: [
                            {
                                code: 'true',
                                name: {
                                    id: 'e1p.platform.common.Yes',
                                    defaultMessage: 'Yes',
                                },
                            },
                            {
                                code: 'false',
                                name: {
                                    id: 'e1p.platform.common.No',
                                    defaultMessage: 'No',
                                },
                            },
                        ],
                        path: 'isGrossVehicleWeightOverThreshold',
                    },
                }
            ]);
        }

        baseFields.push(
            {
                id: 'isCompOnly',
                type: 'field',
                component: 'Toggle',
                componentProps: {
                    controlClassName: 'toggleContainer',
                    required: true,
                    availableValues: [
                        {
                            code: 'true',
                            name: {
                                id: 'e1p.platform.common.Yes',
                                defaultMessage: 'Yes',
                            },
                        },
                        {
                            code: 'false',
                            name: {
                                id: 'e1p.platform.common.No',
                                defaultMessage: 'No',
                            },
                        },
                    ],
                    path: isWatercraftExposure ? 'compOnlyInd' : 'isCompOnly',
                },
            }
        );

        if (isWatercraftExposure) {
            baseFields.push(
                {
                    id: 'description',
                    type: 'field',
                    component: 'input',
                    componentProps: {
                        path: 'description'
                    },
                }
            );
        }

        if (isRecreationVehicleExposure) {
            baseFields.push(
                {
                    id: 'isLicensedForRoadUse',
                    type: 'field',
                    component: 'Toggle',
                    componentProps: {
                        controlClassName: 'toggleContainer',
                        required: true,
                        availableValues: [
                            {
                                code: 'true',
                                name: {
                                    id: 'e1p.platform.common.Yes',
                                    defaultMessage: 'Yes',
                                },
                            },
                            {
                                code: 'false',
                                name: {
                                    id: 'e1p.platform.common.No',
                                    defaultMessage: 'No',
                                },
                            },
                        ],
                        path: 'isLicensedForRoadUse',
                    },
                }
            );
        }

        if (isPersonalVehicleExposure) {
            baseFields = baseFields.concat([
                {
                    id: 'isVehiclePassengerVan',
                    type: 'field',
                    component: 'Toggle',
                    componentProps: {
                        controlClassName: 'toggleContainer',
                        required: true,
                        availableValues: [
                            {
                                code: 'true',
                                name: {
                                    id: 'e1p.platform.common.Yes',
                                    defaultMessage: 'Yes',
                                },
                            },
                            {
                                code: 'false',
                                name: {
                                    id: 'e1p.platform.common.No',
                                    defaultMessage: 'No',
                                },
                            },
                        ],
                        path: 'isVehiclePassengerVan',
                    },
                },
                {
                    id: 'isCompanyFurnishedVehicle',
                    type: 'field',
                    component: 'Toggle',
                    componentProps: {
                        controlClassName: 'toggleContainer',
                        required: true,
                        availableValues: [
                            {
                                code: 'true',
                                name: {
                                    id: 'e1p.platform.common.Yes',
                                    defaultMessage: 'Yes',
                                },
                            },
                            {
                                code: 'false',
                                name: {
                                    id: 'e1p.platform.common.No',
                                    defaultMessage: 'No',
                                },
                            },
                        ],
                        path: 'isCompanyFurnishedVehicle',
                    },
                }
            ]);
        }

        return baseFields;
    }, [isPersonalVehicleExposure, isRecreationVehicleExposure, isWatercraftExposure]);

    useEffect(() => {
        const tempFields = vehicleExposureFields;

        vehicleExposures.children.forEach((_vehicleExposure, index) => {
            tempFields[index] = getVehicleExposureFields();
        });

        if (!_isEqual(tempFields, vehicleExposureFields)) {
            setVehicleExposureFields(tempFields);
        }
    }, [vehicleExposureFields, getVehicleExposureFields, vehicleExposures.children]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `children.${changedPath}`;

            if (changedPath === 'isCompanyFurnishedVehicle') {
                if (value) {
                    handleValueChange(false, 'isAntiqueClassic');
                    handleValueChange(false, 'isCompOnly');
                }
            }

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange]
    );

    const getVehicleExposureName = (vehicleExposure) => {
        let titleString = '';

        if (vehicleExposure.year.value) { titleString = `${titleString} ${vehicleExposure.year.value}`; }

        if (vehicleExposure.make.value) { titleString = `${titleString} ${vehicleExposure.make.value}`; }

        if (vehicleExposure.model.value) { titleString = `${titleString} ${vehicleExposure.model.value}`; }

        return titleString === '' ? newVehicleExposureTitle : titleString;
    };

    const onRemoveVehicleExposure = (index) => {
        const evt = {
            path: `children[${index}]`
        };

        removeVehicleExposure(evt);
    }

    const formatLabel = (labelObject) => (
            <div id={labelObject.id} className={labelObject.className}>
                {translator(labelObject.content)}
            </div>
        );

    const formatField = (row, column) => {
        const vehicleExposure = vehicleExposures.children[column - 1];
        const vehicleExposureData = vehicleExposureFields[column - 1];
        const vehicleExposureField = vehicleExposureData ? vehicleExposureData[row - 2] : undefined;

        let readOnlyValue = false;

        if (vehicleExposureField.id === 'vin') {
            const isExistingVehicle = _get(vehicleExposure, 'existingVehicleInd.value', false);
            const isVinEditable = isExistingVehicle
                ? authUserData.permissions_Ext.includes('editvin_ext')
                : true;

            readOnlyValue = !isVinEditable;
        }

        if (vehicleExposureField.component === 'input') {
            const fieldPath = vehicleExposureField.componentProps.path
                ? `[${column - 1}].${vehicleExposureField.componentProps.path}`
                : undefined;
            const value = vehicleExposureField.componentProps.value
                ? vehicleExposureField.componentProps.value
                : _get(vehicleExposure, `${vehicleExposureField.componentProps.path}.value`);

            return (
                <InputField
                    id={`${vehicleExposureField.id}${column - 1}`}
                    path={fieldPath}
                    hideLabel
                    type={vehicleExposureField.componentProps.type}
                    required={vehicleExposureField.componentProps.required}
                    maxLength={vehicleExposureField.componentProps.maxLength}
                    placeholder=""
                    onValueChange={handleValueChange}
                    value={value}
                    readOnly={viewOnlyMode || readOnlyValue}
                    showErrors={showErrors}
                    showRequired
                    onValidationChange={setComponentValidation}
                />
            );
        }

        if (vehicleExposureField.component === 'dropdownSelect') {
            const fieldPath = vehicleExposureField.componentProps.path
                ? `[${column - 1}].${vehicleExposureField.componentProps.path}`
                : undefined;
            const value = vehicleExposureField.componentProps.value
                ? vehicleExposureField.componentProps.value
                : _get(vehicleExposure, `${vehicleExposureField.componentProps.path}.value`,);
            const onChange = handleValueChange;
            const availableOptions = _get(
                vehicleExposure,
                `${vehicleExposureField.componentProps.path}.aspects.availableValues`,
                []
            ).map((stateObj) => ({
                    code: stateObj.code,
                    name: translator({
                        id: stateObj.name,
                        defaultMessage: stateObj.name,
                    }),
                }));
            let defaultValue;

            return (
                <DropdownSelectField
                    id={`${vehicleExposureField.id}${column - 1}`}
                    alwaysShowPlaceholder={false}
                    placeholder=""
                    availableValues={availableOptions}
                    onValueChange={onChange}
                    value={value}
                    hideLabel
                    required={vehicleExposureField.componentProps.required}
                    path={fieldPath}
                    readOnly={viewOnlyMode || readOnlyValue}
                    defaultValue={defaultValue}
                    showErrors={showErrors}
                    showRequired
                    onValidationChange={setComponentValidation}
                    searchable={vehicleExposureField.componentProps.searchable}
                />
            );
        }

        if (vehicleExposureField.component === 'Toggle') {
            const onChange = handleValueChange;
            const value = vehicleExposureField.componentProps.value ? vehicleExposureField.componentProps.value : _get(vehicleExposure, `${vehicleExposureField.componentProps.path}.value`);
            const fieldPath = vehicleExposureField.componentProps.path ? `[${column - 1}].${vehicleExposureField.componentProps.path}` : undefined;
            const {defaultValue} = vehicleExposureField.componentProps;

            return (
                // To match all editable toggle field, show every toggle field on the page under same grid configuration
                <Grid tag="div" key={`${vehicleExposureField.id}${column - 1}-grid`} columns={[
                    "3fr",
                    "1fr"
                ]}>
                    <ToggleField
                        id={`${vehicleExposureField.id}${column - 1}`}
                        key={`${vehicleExposureField.id}${column - 1}`}
                        value={value}
                        dataType="string"
                        controlClassName="toggleContainer"
                        availableValues={vehicleExposureField.componentProps.availableValues}
                        onValueChange={onChange}
                        defaultValue={defaultValue}
                        path={fieldPath}
                        required={vehicleExposureField.componentProps.required}
                        showErrors={showErrors}
                        showRequired
                        onValidationChange={setComponentValidation}
                        readOnly={viewOnlyMode}
                    />
                </Grid>)
        }

        return (
            <span>
                {row} {column}
            </span>
        );
    };

    const COLUMNS = vehicleExposures.length + 1;
    const columnConfig = ['310px'];

    for (let column = 0; column < vehicleExposures.length; column++) {
        const columnWidthToPush = '300px';

        columnConfig.push(columnWidthToPush);
    }

    const tableContent = [];
    const ROWS = vehicleExposureLabels.length + 1;
    const numberOfCells = COLUMNS * ROWS;

    for (let cell = 0; cell <= numberOfCells - 1; cell++) {
        const rowNumber = Math.floor(cell / COLUMNS) + 1;
        const columnNumber = cell % COLUMNS;

        if (cell === 0) {
            // empty cell
            tableContent.push(
                <GridItem
                    className="grid-item-sticky grid-item-label p-8"
                    key={`quotename${cell}`}
                />
            );
        }
        else if (cell === columnNumber) {
            tableContent.push(
                <GridItem
                    tag="div"
                    key={`vehicleExposureNameGridItem${cell}`}
                    className="grid-border-thin font-b"
                >
                    <Grid
                        tag="div"
                        key={`vehicleExposureTitleHeader${cell}`}
                        columns={['14rem', '1rem']}
                        className="p-5"
                    >
                        <div
                            key={`vehicleExposureLabelID${cell}`}
                            className="font-grid-title"
                        >
                            {getVehicleExposureName(vehicleExposures.children[cell - 1])}
                        </div>
                        {!viewOnlyMode ? (<IconButton
                            id={`eaDeleteVehicleExposure${cell}`}
                            icon="mi-delete"
                            onClick={() => onRemoveVehicleExposure(columnNumber - 1)}
                        />) : (undefined)}
                    </Grid>
                </GridItem>
            );
        }
        // first column - render row headers
        else if (cell % COLUMNS === 0) {
            let itemStyle = 'grid-item-label';

            // background style
            if (rowNumber % 2 === 0) {
                itemStyle += ' grid-item-color-even';
            }

            // last row of the grid
            if (rowNumber === ROWS) {
                itemStyle += ' grid-item-last';
            }

            const labelIndex = rowNumber - 2;

            tableContent.push(
                <GridItem
                    tag="div"
                    key={`vehicleExposureLabel${cell}`}
                    className={itemStyle}
                >
                    {formatLabel(vehicleExposureLabels[labelIndex])}
                </GridItem>
            );
        } else {
            let itemStyle = 'grid-item';

            // background style
            if (rowNumber % 2 === 0) {
                itemStyle += ' grid-item-color-even';
            }

            // last row of the grid
            if (rowNumber === ROWS) {
                itemStyle += ' grid-item-last';
            }

            const vehicleExposureData = vehicleExposureFields[columnNumber - 1];
            const vehicleExposureField = vehicleExposureData
                ? vehicleExposureData[rowNumber - 2]
                : undefined;

            if (vehicleExposureField !== undefined) {
                tableContent.push(
                    <GridItem
                        tag="div"
                        key={`vehicleExposureDataCell${cell}`}
                        className={itemStyle}
                        id={`${vehicleExposureField.id}${columnNumber - 1}`}
                    >
                        {formatField(rowNumber, columnNumber)}
                    </GridItem>
                );
            }
        }
    }

    return (
        <Grid className="mt-8" columns={columnConfig} hgap="small" vgap="none">
            {tableContent}
        </Grid>
    );
}

EUVehicleExposureGridComponent.propTypes = {
    vehicleExposures: PropTypes.shape({}),
    id: PropTypes.string,
    onValidate: PropTypes.func,
    onValueChange: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    showErrors: PropTypes.bool,
    isWatercraftExposure: PropTypes.bool.isRequired,
    isRecreationVehicleExposure: PropTypes.bool.isRequired,
    isPersonalVehicleExposure: PropTypes.bool.isRequired,
    newVehicleExposureTitle: PropTypes.string
};

EUVehicleExposureGridComponent.defaultProps = {
    onValidate: undefined
};

export default EUVehicleExposureGridComponent;
