import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { Chevron } from '@jutro/components';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import UnderwritingIssues from 'gw-components-platform-react/UnderwritingIssues/UnderwritingIssues';
import QuoteTableAccordionCardIterableComponent from 'gw-components-platform-react/QuoteTableAccordionCardIterableComponent/QuoteTableAccordionCardIterableComponent';
import metadata from './PCEAPremiumBoxComponent.metadata.json5';
import styles from './PCEAPremiumBoxComponent.module.scss';

function selectMetadata(breakpoint) {
    const contentArray = metadata.componentContent.content;
    let metadataToRender = '';

    if (breakpoint === 'phone') {
        metadataToRender = contentArray.filter((item) => item.id !== 'desktopTable');
    } else {
        metadataToRender = contentArray.filter((item) => item.id !== 'phoneTable' && item.id !== 'mobileAccordionHeader');
    }

    const filteredMetdata = {
        ...metadata,
        componentContent: {
            content: metadataToRender
        }
    };

    return filteredMetdata;
}

class PremiumBoxComponent extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        onChangeSubmission: PropTypes.func,
        viewOnlyMode: PropTypes.bool,
        showMonthlyPaymentComponent: PropTypes.bool,
        ...validationPropTypes
    };

    static defaultProps = {
        onChangeSubmission: undefined,
        viewOnlyMode: false,
        showMonthlyPaymentComponent: true
    }

    state = {
        formData: {},
    };

    componentDidMount() {
        const {
            policyChangeVM,
            updateWizardData,
            viewOnlyMode
        } = this.props;

        if (_.get(policyChangeVM, 'lobData.personalAuto_EA.paperlessInd.value') === undefined && !viewOnlyMode) {
            _.set(policyChangeVM, 'lobData.personalAuto_EA.paperlessInd.value', false);
            updateWizardData(policyChangeVM);
        }
    }

    writeValue = (value, path) => {
        const { formData } = this.state;

        _.set(formData, path, value);
        this.setState({ formData });
    };

    generateOverrides() {
        const {
            policyChangeVM,
            viewOnlyMode,
            authHeader,
            updateWizardData,
            showMonthlyPaymentComponent
        } = this.props;

        return {
            '@field': {
                // apply to all fields
                onValueChange: this.writeValue,
                readOnly: viewOnlyMode,
                showRequired: true,
                autoComplete: false
            },
            paymentOptionsID: {
                submissionVM: policyChangeVM,
                authHeader,
                updateWizardData,
                LOB: 'personalAuto_EA',
                viewOnly: viewOnlyMode
            },
            monthlyPaymentScheduleComponent: {
                quoteID: policyChangeVM.value.jobID,
                authHeader,
                transactionTotalAmount: policyChangeVM.totalCost?.value,
                changeInCost: policyChangeVM.transactionCost?.value,
                startDate: policyChangeVM.baseData.periodStartDate.value,
                endDate: policyChangeVM.baseData.periodEndDate.value,
                jobTypeCode: policyChangeVM.baseData.jobType.value.code,
                offerings: _.get(policyChangeVM, 'lobData.personalAuto_EA.offerings.value'),
                visible: showMonthlyPaymentComponent,
            },
            policyTermComponent: {
                transactionVM: policyChangeVM,
                authHeader,
                updateWizardData,
                viewOnly: true
            },
        };
    }

    renderClauseTable = (breakpoint) => {
        const {
            setComponentValidation, onChangeSubmission, policyChangeVM
        } = this.props;

        const { formData } = this.state;
        const dataForComponent = {
            ...formData,
            ..._.pick(this.props, ['columnData', 'tableData', 'modifiers']),
            ...policyChangeVM
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                chevron: Chevron,
                quotetableaccordioncarditerablecomponent: QuoteTableAccordionCardIterableComponent,
                underwritingissues: UnderwritingIssues
            },
            resolveCallbackMap: {
                onChangeSubmissionAndSync: this.changeSubmissionAndSync,
                onSyncCoverages: this.syncCoverages,
                onChangeSubmission,
            }
        };
        const generatedMetadata = selectMetadata(breakpoint);

        return (
            <ViewModelForm
                uiProps={generatedMetadata.componentContent}
                model={dataForComponent}
                overrideProps={this.generateOverrides(breakpoint)}
                onValueChange={this.writeValue}
                onValidationChange={setComponentValidation}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }

    render() {
        return (
            <BreakpointTrackerContext.Consumer>
                {this.renderClauseTable}
            </BreakpointTrackerContext.Consumer>
        );
    }
}

export default withValidation(PremiumBoxComponent);
