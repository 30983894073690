import React, { useCallback, useEffect, useState } from 'react';
import {
    cloneDeep as _cloneDeep,
    get as _get,
    set as _set,
    isUndefined as _isUndefined
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import './RiskAddressComponent.messages';
import metadata from './RiskAddressComponent.metadata.json5';


/**
 * @param {*} props wizard props
 * @returns {*} none
 */
function RiskAddressComponent(props) {
    const {
        id,
        showErrors,
        submissionVM,
        updateWizardData,
        onValidate,
        viewOnlyMode,
        viewModelService
    } = props;
    const { isComponentValid, onValidate: setComponentValidation, disregardFieldValidation } = useValidation(id);
    const [isComponentInitialized, setIsComponentInitialized] = useState(false);

    /**
     * Helper callback for handling an address change on the submission.
     */
    const handleAddressChange = useCallback(
        (value, path) => {
            _set(submissionVM, path, value);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    /**
     * Helper callback for setting the risk address to its default value.
     */
    const setRiskAddressToDefault = useCallback(() => {
        // always need to populate from policyAddress
        const residenceAddress = _cloneDeep(_get(submissionVM, 'baseData.policyAddress.value'));

        handleAddressChange(residenceAddress, 'lobData.homeowners_EH.coverables.yourHome.ehlocation.value');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * Helper effect for initializing the policy mailing/billing addresses and indicators.
     */
    useEffect(() => {
        if (_isUndefined(_get(submissionVM, 'lobData.homeowners_EH.coverables.value'))) {
            const coverables = {
                yourHome: {
                    ehlocation: {

                    }
                }
            };
            const coverablesDTO = viewModelService.create(
                coverables,
                'pc',
                'amfam.edge.capabilities.policyjob.lob.eh.coverables.dto.EHCoverablesDTO'
            );

            _set(submissionVM, 'lobData.homeowners_EH.coverables.value', coverablesDTO.value);
        }

        const isRiskAddressSame = _get(
            submissionVM,
            'lobData.homeowners_EH.coverables.yourHome.isRiskAddressSame_Ext.value'
        );

        if (isRiskAddressSame === undefined) {
            _set(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.isRiskAddressSame_Ext.value',
                true
            );
        }

        if (isRiskAddressSame === undefined || isRiskAddressSame === true) {
            setRiskAddressToDefault();
        }

        updateWizardData(submissionVM);
        setIsComponentInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for handling changes to the mailing address toggle value.
     */
    const riskAddressToggleHandler = useCallback(
        (value) => {
            _set(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.isRiskAddressSame_Ext.value',
                value
            );
            setRiskAddressToDefault();
            updateWizardData(submissionVM);
            /**
             * fix for: E1PAP1PC-15424
             * onChange of toggle we are always setting default value for risk address,
             * so component will always be valid, hence disregarding the field validation
             * for the component, only when toggle is changed.
             * if particular field is changed manually, then validation will work as expected.
             * */
            disregardFieldValidation('riskAddressComponent');
        },
        [
            submissionVM,
            setRiskAddressToDefault,
            updateWizardData,
            disregardFieldValidation
        ]
    );

    /**
     * Helper effect for validating data fields on this component.
     */
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            riskAddressToggleHandler
        }
    };
    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            readOnly: viewOnlyMode,
            showRequired: true,
            showErrors,
            autoComplete: false
        },
        riskAddressComponentContainer: {
            visible: !_get(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.isRiskAddressSame_Ext.value'
            )
        },
        riskAddressComponent: {
            addressVM: _get(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.ehlocation'
            ),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate: setComponentValidation,
            showErrors,
            onAddressChange: (value, path) => handleAddressChange(
                value,
                `lobData.homeowners_EH.coverables.yourHome.ehlocation.${path}`
            ),
            viewOnlyMode
        },
    };

    /**
     * Helper callback for reading values from the view model.
     *
     * @param {*} _id
     * @param {*} path
     * @returns {*} The value as read from the view model.
     */
    const readValue = (_id, path) => readViewModelValue(metadata.pageContent, submissionVM, _id, path, overrideProps);

    /**
     * Define rendering behaviors for this Jutro component.
     */
    if (!isComponentInitialized) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

/**
 * Define expected property types to be passed into this Jutro component.
 */
RiskAddressComponent.propTypes = {
    id: PropTypes.string.isRequired,
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func,
    onValidate: PropTypes.func,
    viewOnlyMode: PropTypes.bool,
    viewModelService: PropTypes.shape({}).isRequired,
    showErrors: PropTypes.bool
};

/**
 * Define default property values to be passed into this Jutro component.
 */
RiskAddressComponent.defaultProps = {
    updateWizardData: () => { },
    onValidate: () => { },
    viewOnlyMode: false,
    showErrors: false
};

export default RiskAddressComponent;
