import React, {
    useContext, useCallback, useEffect, useRef, useMemo
} from 'react';
import {
    get, set, isUndefined
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import metadata from './UnderlyingPolicyPage.metadata.json5';

function ViewUnderlyingPolicyPage(props) {
    const {
        wizardData: policyViewVM
    } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const underlyingPoliciesVM = useMemo(() => get(policyViewVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []), [policyViewVM]);
    const jobType = get(policyViewVM.value, 'baseData.jobType');

    useEffect(() => {
        if (isUndefined(underlyingPoliciesVM.value)) {
            set(underlyingPoliciesVM, 'value', []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(() => policyViewVM, [policyViewVM]);

 

    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            readOnly: true
        },
        underlyingPolicyPageLoadingIndicator: {
            loaded: true
        },
        euUnderlyingPolicyComponent: {
            data: policyViewVM,
            updateWizardData: () => {},
            isPageSubmitted: false,
            isPrivatePassengerQuestionVisible: false,
            viewOnlyMode: true,
            deletedItemsRef: useRef([]),
            onValidate: undefined,
            jobType

        }
    };

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyViewVM}
                overrideProps={overrideProps}
            />
        </WizardPage>
    );
}

ViewUnderlyingPolicyPage.propTypes = wizardProps;
export default ViewUnderlyingPolicyPage;
