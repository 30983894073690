import React, { useEffect, useMemo, useState } from 'react';
import {
    get as _get,
    isEmpty as _isEmpty
} from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { GeoService } from 'e1p-capability-gateway';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './GeoDataComponent.metadata.json5';
import messages from './GeoDataComponent.messages';

function GeoDataComponent(props) {
    const { quoteID, geoDataResponse, setGeoDataResponse } = props;
    const translator = useTranslator();
    const [geoDataLoaded, setGeoDataLoaded] = useState(false);
    const { onValidate } = useValidation(
        'GeoDataComponent'
    );
    const { authHeader } = useAuthentication();

    useEffect(() => {
        setGeoDataLoaded(!_isEmpty(geoDataResponse));

        if (_isEmpty(geoDataResponse)) {
            GeoService.getGeoData(
                quoteID,
                authHeader
            ).then((response) => {
                setGeoDataResponse(response);

                if (response.geoRecords !== undefined && response.geoRecords.length > 0) {
                    setGeoDataResponse(response.geoRecords[0]);
                }

                setGeoDataLoaded(true);
            }).finally(() => {
                setGeoDataLoaded(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAddressValue = useMemo(() => {
        const assignment = _get(geoDataResponse, 'assignment');

        if (assignment !== undefined) {
            return `${assignment.addressLine1}, ${assignment.city}, ${assignment.zip}, ${assignment.state}`;
        }

        return '';
    }, [geoDataResponse]);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            autoComplete: false
        },
        geoDataPageLoadingIndicator: {
            loaded: geoDataLoaded,
            text: translator(messages.orderingGeoDataMessage)
        },
        geoDataPageContainer: {
            visible: geoDataLoaded
        },
        address: {
            value: getAddressValue
        },
        geoWildfireData: {
            // Hide wildfire container if not applicable
            visible: geoDataResponse.frprefId
                && geoDataResponse.frprefId !== 'NA'
        }

    };
    

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={geoDataResponse}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
        />
    );
}

GeoDataComponent.propTypes = {
    quoteID: PropTypes.string.isRequired,
    geoDataResponse: PropTypes.shape({
        frprefId: PropTypes.string
    }).isRequired,
    setGeoDataResponse: PropTypes.func.isRequired,
    policyState: PropTypes.string.isRequired
};

export default GeoDataComponent;
