import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import styles from './AdvancedFilter.module.scss';
import { CheckboxField } from '@jutro/legacy/components';

class FilterOptions extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        filters: PropTypes.shape([]).isRequired,
        onValueChange: PropTypes.func.isRequired
    };

    writeValue = (value, path) => {
        const { filters, onValueChange } = this.props;

        filters.options[path].selected = value;

        if (onValueChange) {
            onValueChange(filters);
        }
    };

    render() {
        const translator = this.context;
        const { filters } = this.props;

        return (
            <Fragment>
                {filters.options.map((option, index) => {
                    const fieldId = option.displayKey.id || option.displayKey;

                    return (
                        <div className={styles.gw_filter_option} key={fieldId}>
                            <CheckboxField
                                id={`status${fieldId}`}
                                value={option.selected}
                                path={index}
                                showInlineLabel
                                label={translator(option.displayKey)}
                                showOptional={false}
                                onValueChange={this.writeValue}
                                labelClassName={styles.gwAdvancedFilterOptionsLabel}
                            />
                        </div>
                    );
                })}
            </Fragment>
        );
    }
}

export default FilterOptions;
