import React, {
    useCallback,
    useMemo,
    useEffect,
    useContext,
    useRef
} from 'react';
import PropTypes from 'prop-types';
import { get, set } from 'lodash';
import moment from 'moment';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { TooltipIcon } from '@jutro/components';
import { euCommonMessages } from 'e1p-platform-translations';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { e1pDateUtil,} from 'e1p-capability-hooks';
import { useTranslator } from '@jutro/locale';
import metadata from './EUPreQualificationComponent.metadata.json5';

function EUPreQualificationComponent(props) {
    const {
        data: transactionVM,
        id,
        onValidate,
        viewOnlyMode,
        showErrors,
        updateWizardData
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const translator = useTranslator();
    const lobDataEUPath = useRef('lobData.personalUmbrella_EU').current;
    const uwQuestionSetPath = useRef('lobData.personalUmbrella_EU.uwquestionSets.value[0].answers')
        .current;

    const viewModelService = useContext(ViewModelServiceContext);
    const { isDateBackDated } = e1pDateUtil;

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const handleValueChange = useCallback((value, changedPath) => {
        set(transactionVM, `value.${changedPath}`, value);
        updateWizardData(transactionVM);
    }, [transactionVM, updateWizardData]);

    /**
     * Helper callback for handling change events for the "resRelOwnLiveStockInd" value.
     */
    const handleResRelOwnLiveStockIndValueChange = useCallback(
        (value) => {
            if (value === true) {
                set(transactionVM, `${lobDataEUPath}.resRelOwnLiveStockInd.value`, true);
            } else {
                set(transactionVM, `${lobDataEUPath}.resRelOwnLiveStockInd.value`, false);
                set(transactionVM, `${lobDataEUPath}.isLiveStockForSaleInd.value`, undefined);
                set(transactionVM, `${lobDataEUPath}.numberOfLiveStock.value`, undefined);
            }

            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData, lobDataEUPath]
    );
    
    /**
         * Helper callback for handling change events for the "isLiveStockForSaleInd" value.
         */
    const handleIsLiveStockForSaleIndValueChange = useCallback(
        (value) => {
            set(transactionVM, `${lobDataEUPath}.isLiveStockForSaleInd.value`, value);
            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData, lobDataEUPath]
    );
    
    /**
         * Helper memo for retrieving the "numberOfLiveStock" typelist values.
         */
    const setNumberOfLiveStockAvailableValues = useMemo(() => {
        const availableValues =
            transactionVM.lobData.personalUmbrella_EU.numberOfLiveStock.aspects.availableValues.map(
                key => ({
                    name: { id: key.name },
                    code: key.code,
                })
            );

        return availableValues;
    }, [transactionVM]);
    
    /**
         * Helper memo for determining whether the policy period is back-dated.
         */
    const policyIsBackDated = useMemo(() => {
        const startDate = get(transactionVM, 'value.baseData.periodStartDate', get(transactionVM, 'value.periodStartDate'));
        const effectiveDateBeforeToday = isDateBackDated(startDate);

        return effectiveDateBeforeToday;
    }, [isDateBackDated, transactionVM]);
    
    /**
         * Helper callback for handling change events for the "householdPublicFigureInd" value.
         */
    const handleHouseholdPublicFigureIndValueChange = useCallback(
        (value) => {
            if (value === true) {
                set(transactionVM, `${lobDataEUPath}.householdPublicFigureInd.value`, true);
            } else {
                set(transactionVM, `${lobDataEUPath}.householdPublicFigureInd.value`, false);
                // reset all the dependent questions
                set(transactionVM, `${lobDataEUPath}.publicFigureCategory.value`, undefined);
                set(transactionVM, `${lobDataEUPath}.publicFigureCategoryDescInd.value`, undefined);
                set(transactionVM, `${lobDataEUPath}.workForACityWithPeopleInd.value`, undefined);
                set(transactionVM, `${lobDataEUPath}.primaryAudienceCityOrMetroInd.value`, undefined);
                set(transactionVM, `${lobDataEUPath}.numberOfSocialMediaFollowers.value`, undefined);
                set(transactionVM, `${lobDataEUPath}.partyLeaderInd.value`, undefined);
            }

            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData, lobDataEUPath]
    );
    
    /**
         * Helper memo for retrieving the "publicFigureCategory" typelist values.
         */
    const setPublicFigureCategoryAvailableValues = useMemo(() => {
        const typelist = viewModelService.productMetadata
            .get('pc')
            .types.getTypelist('EUPublicFigureCategoryType_Ext').codes;

        return typelist.map((code) => {
            if (code.code === 'federalofficial') {
                return {
                    code: code.code,
                    name: translator(euCommonMessages.federalOfficialLabel)
                };
            }

            return {
                code: code.code,
                name: translator({ id: code.name })
            };
        });
    }, [translator, viewModelService.productMetadata]);
    
    /**
         * Helper callback for handling change events for the "publicFigureCategory" value.
         */
    const handlePublicFigureCategoryValueChange = useCallback(
        (value) => {
            if (value !== 'other') {
                set(transactionVM, `${lobDataEUPath}.publicFigureCategoryDescInd.value`, undefined);
                set(transactionVM, `${lobDataEUPath}.chargeHighProfileSurcharge.value`, undefined);
            }

            set(transactionVM, `${lobDataEUPath}.publicFigureCategory.value`, value);
            // reset the dependent question
            set(transactionVM, `${lobDataEUPath}.publicFigureCategoryDescInd.value`, undefined);
            set(transactionVM, `${lobDataEUPath}.workForACityWithPeopleInd.value`, undefined);
            set(transactionVM, `${lobDataEUPath}.primaryAudienceCityOrMetroInd.value`, undefined);
            set(transactionVM, `${lobDataEUPath}.numberOfSocialMediaFollowers.value`, undefined);
            set(transactionVM, `${lobDataEUPath}.partyLeaderInd.value`, undefined);
            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData, lobDataEUPath]
    );
    
    /**
         * Helper memo for retrieving the "numberOfSocialMediaFollowers" typelist values.
         */
    const setNumberOfSocialMediaFollowersAvailableValues = useMemo(() => {
        const availableValues =
            transactionVM.lobData.personalUmbrella_EU.numberOfSocialMediaFollowers.aspects.availableValues.map(
                key => ({
                    name: { id: key.name },
                    code: key.code,
                })
            );

        return availableValues;
    }, [transactionVM]);
    
    /**
         * Helper callback for handling change events for the "EUHadLiabilityLossOver50KInLast3Years"
         * value.
         */
    const handleEUHadLiabilityLossOver50KInLast3YearsValueChange = useCallback(
        (value) => {
            if (value === true || value === 'true') {
                set(
                    transactionVM,
                    `${uwQuestionSetPath}.EUHadLiabilityLossOver50KInLast3Years`,
                    'true'
                );
            } else {
                set(
                    transactionVM,
                    `${uwQuestionSetPath}.EUHadLiabilityLossOver50KInLast3Years`,
                    'false'
                );
            }

            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData, uwQuestionSetPath]
    );
    
    /**
         * Helper callback for handling change events for the "EUHouseholdHasJudgement" value.
         */
    const handleEUHouseholdHasJudgementValueChange = useCallback(
        (value) => {
            if (value === true || value === 'true') {
                set(transactionVM, `${uwQuestionSetPath}.EUHouseholdHasJudgement`, 'true');
            } else {
                set(transactionVM, `${uwQuestionSetPath}.EUHouseholdHasJudgement`, 'false');
                set(transactionVM, `${uwQuestionSetPath}.EUHouseholdJudgementDescription`, null);
                set(transactionVM, `${uwQuestionSetPath}.EUJudgementIncidentDate`, null);
            }

            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData, uwQuestionSetPath]
    );
    
    /**
         * Helper callback for handling change events for the "EUJudgementIncidentDate" value.
         */
    const handleEUJudgementIncidentDateValueChange = useCallback(
        (value) => {
            // Convert "SimpleDateFormat" of "EEE MMM dd HH:mm:ss Z yyyy" into "Moment" format
            const dateAsString = moment(value).format('ddd MMM DD HH:mm:ss ZZ YYYY');

            set(transactionVM, `${uwQuestionSetPath}.EUJudgementIncidentDate`, dateAsString);
            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData, uwQuestionSetPath]
    );
    
    /**
         * Helper callback for handling change events for the "EUHouseholdTravelInternationally" value.
         */
    const handleEUHouseholdTravelInternationallyValueChange = useCallback(
        (value) => {
            if (value === true || value === 'true') {
                set(transactionVM, `${uwQuestionSetPath}.EUHouseholdTravelInternationally`, 'true');
                set(
                    transactionVM,
                    `${uwQuestionSetPath}.EUHouseholdInternationalTravelDEscription`,
                    null
                );
            } else {
                set(transactionVM, `${uwQuestionSetPath}.EUHouseholdTravelInternationally`, 'false');
            }

            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData, uwQuestionSetPath]
    );
    
    const overrideProps = useMemo(() => ({
            '@field': {
                showRequired: true,
                readOnly: viewOnlyMode,
                showErrors,
                autoComplete: false,
            },
            resRelOwnLiveStockInd: {
                onValueChange: handleResRelOwnLiveStockIndValueChange,
                required: true
            },
            resRelOwnLiveStock: {
                className: viewOnlyMode ? 'displayInline' : 'required displayInline'
            },
            isLiveStockForSaleInd: {
                onValueChange: handleIsLiveStockForSaleIndValueChange,
                required: transactionVM.lobData.personalUmbrella_EU.resRelOwnLiveStockInd.value,
                visible: transactionVM.lobData.personalUmbrella_EU.resRelOwnLiveStockInd.value ?? false
            },
            numberOfLiveStock: {
                availableValues: setNumberOfLiveStockAvailableValues,
                value: transactionVM.lobData.personalUmbrella_EU.numberOfLiveStock.value,
                required: transactionVM.lobData.personalUmbrella_EU.resRelOwnLiveStockInd.value === true,
                visible: transactionVM.lobData.personalUmbrella_EU.resRelOwnLiveStockInd.value === true
            },
            lossOccurredBetweenEffectiveDateAndCreateDateInd: {
                visible: policyIsBackDated
            },
            householdPublicFigureInd: {
                onValueChange: handleHouseholdPublicFigureIndValueChange
            },
            publicFigureCategory: {
                availableValues: setPublicFigureCategoryAvailableValues,
                onValueChange: handlePublicFigureCategoryValueChange,
                value: transactionVM.lobData.personalUmbrella_EU.publicFigureCategory.value,
                required:
                    transactionVM.lobData.personalUmbrella_EU.householdPublicFigureInd.value,
                visible:
                    transactionVM.lobData.personalUmbrella_EU.householdPublicFigureInd.value ?? false
            },
            publicFigureCategoryDescInd: {
                required:
                    get(transactionVM, `${lobDataEUPath}.publicFigureCategory.value.code`) === 'other',
                visible:
                    get(transactionVM, `${lobDataEUPath}.publicFigureCategory.value.code`) === 'other'
            },
            workForACityWithPeopleInd: {
                required:
                    get(transactionVM, `${lobDataEUPath}.publicFigureCategory.value.code`, null) === 'cityleader',
                visible:
                    get(transactionVM, `${lobDataEUPath}.publicFigureCategory.value.code`, null) === 'cityleader'
            },
            partyLeaderInd: {
                required:
                    get(transactionVM, `${lobDataEUPath}.publicFigureCategory.value.code`, null) === 'otherstateofficial',
                visible:
                    get(transactionVM, `${lobDataEUPath}.publicFigureCategory.value.code`, null) === 'otherstateofficial'
            },
            primaryAudienceCityOrMetroInd: {
                required:
                    get(transactionVM, `${lobDataEUPath}.publicFigureCategory.value.code`, null) === 'localregionalmedia',
                visible:
                    get(transactionVM, `${lobDataEUPath}.publicFigureCategory.value.code`, null) === 'localregionalmedia'
            },
            numberOfSocialMediaFollowers: {
                availableValues: setNumberOfSocialMediaFollowersAvailableValues,
                required:
                    get(transactionVM, `${lobDataEUPath}.publicFigureCategory.value.code`, null) === 'socialmediapersonalityinfluencer',
                visible:
                    get(transactionVM, `${lobDataEUPath}.publicFigureCategory.value.code`, null) === 'socialmediapersonalityinfluencer'
            },
            EUHadLiabilityLossOver50KInLast3Years: {
                onValueChange: handleEUHadLiabilityLossOver50KInLast3YearsValueChange
            },
            EUHouseholdHasJudgement: {
                onValueChange: handleEUHouseholdHasJudgementValueChange
            },
            EUHouseholdJudgementDescription: {
                required:
                    transactionVM.lobData.personalUmbrella_EU.uwquestionSets.value[0].answers
                        .EUHouseholdHasJudgement === 'true',
                visible:
                    transactionVM.lobData.personalUmbrella_EU.uwquestionSets.value[0].answers
                        .EUHouseholdHasJudgement === 'true'
            },
            EUJudgementIncidentDate: {
                minDate: moment().subtract(10, 'years').toDate(),
                maxDate: moment().toDate(),
                updateDateDto: handleEUJudgementIncidentDateValueChange,
                dateDTO: transactionVM.lobData.personalUmbrella_EU.uwquestionSets
                    .value[0].answers.EUJudgementIncidentDate,
                isRequired:
                    transactionVM.lobData.personalUmbrella_EU.uwquestionSets.value[0].answers
                        .EUHouseholdHasJudgement === 'true',
                visible:
                    transactionVM.lobData.personalUmbrella_EU.uwquestionSets.value[0].answers
                        .EUHouseholdHasJudgement === 'true'
            },
            EUHouseholdTravelInternationally: {
                onValueChange: handleEUHouseholdTravelInternationallyValueChange
            },
            EUHouseholdInternationalTravelDEscription: {
                required:
                    transactionVM.lobData.personalUmbrella_EU.uwquestionSets.value[0].answers
                        .EUHouseholdTravelInternationally === 'true',
                visible:
                    transactionVM.lobData.personalUmbrella_EU.uwquestionSets.value[0].answers
                        .EUHouseholdTravelInternationally === 'true'
            }
        }), [handleEUHadLiabilityLossOver50KInLast3YearsValueChange, handleEUHouseholdHasJudgementValueChange, handleEUHouseholdTravelInternationallyValueChange, handleEUJudgementIncidentDateValueChange, handleHouseholdPublicFigureIndValueChange, handleIsLiveStockForSaleIndValueChange, handlePublicFigureCategoryValueChange, handleResRelOwnLiveStockIndValueChange, lobDataEUPath, policyIsBackDated, setNumberOfLiveStockAvailableValues, setNumberOfSocialMediaFollowersAvailableValues, setPublicFigureCategoryAvailableValues, showErrors, transactionVM, viewOnlyMode]);
    

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        },
        resolveComponentMap: {
            TooltipIcon
        }
    };

    const readValue = useCallback((fieldId, fieldPath) => readViewModelValue(
            metadata.pageContent, transactionVM, fieldId, fieldPath, overrideProps
        ), [transactionVM, overrideProps]);

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

EUPreQualificationComponent.propTypes = {
    data: PropTypes.shape({}),
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    showErrors: PropTypes.bool,
};
EUPreQualificationComponent.defaultProps = {
    data: {},
    id: undefined,
    viewOnlyMode: false,
    showErrors: false,
};
export default EUPreQualificationComponent;
