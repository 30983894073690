import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import {
    get, set, isEmpty, findIndex
} from 'lodash';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { E1PEHHO6PropertyDetailsComponent } from 'e1p-capability-policyjob-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { RenewalService } from 'e1p-capability-renewal';
import wizardMessages from '../../../EHRenewalWizard.messages';

function PropertyPage(props) {
    const { authHeader } = useAuthentication();
    const {
        wizardData: renewalVM, updateWizardData,
        steps, jumpTo,
        onCustom2: handleDiscardChanges,
        updateWizardSnapshot
    } = props;
   
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [heatingSystemType, updateHeatingSystemType] = useState(undefined);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const isDiscardButtonActive = get(renewalVM, 'value.canDiscard_Ext', false);
    const changeSummaryIndex = findIndex(steps, ({ path }) => path === '/change-summary');

    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerInitialComponentValidation
    } = useValidation('PropertyPage');

    const isPageValid = useCallback(() => {
        const heatingSystems = get(renewalVM,
            'lobData.homeowners_EH.coverables.construction.heatingSystems.value', []);

        return heatingSystems.length > 0;
    }, [renewalVM]);

    useEffect(() => {
        registerInitialComponentValidation(isPageValid);
    }, [registerInitialComponentValidation, isPageValid]);


    const onNext = useCallback(
        async () => {
            if (!isComponentValid || heatingSystemType === undefined) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const saveResponse = await RenewalService.saveRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', saveResponse);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);

            return renewalVM;
        },
        [authHeader, heatingSystemType, isComponentValid, renewalVM, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    const reviewChanges = useCallback(
        async () => {
            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);
            jumpTo(changeSummaryIndex, true, quoteResponse);

            return false;
        },
        [authHeader, renewalVM, changeSummaryIndex, jumpTo, updateWizardData]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isSavingRenewal && !isSavingCurrentPageChanges,[isSavingCurrentPageChanges, isSavingRenewal]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            skipWhen={initialValidation}
            onNext={onNext}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            onSave={onSave}
            showOnSave
        >
            <E1PEHHO6PropertyDetailsComponent
                onValidate={onValidate}
                transactionVM={renewalVM}
                updateWizardData={updateWizardData}
                isPageSubmitted={isPageSubmitted}
                setShouldSkipAdditionalInfo={() => {}}
                isSavingCurrentPageChanges={isSavingCurrentPageChanges}
                isSavingQuote={isSavingRenewal}
                heatingSystemType={heatingSystemType}
                updateHeatingSystemType={updateHeatingSystemType}
                viewOnlyMode={false}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
