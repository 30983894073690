import React, {
    useState,
    useCallback,
    useEffect,
    useContext,
    useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { InlineLoader, useModal } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { SignatureUtil } from 'e1p-capability-gateway';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import htmlParser from 'html-react-parser';
import {
    get, find, set, isEmpty
} from 'lodash';
import {
    useNewBusinessUtil
} from 'e1p-capability-hooks';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import RemoteClick2SignModal from './RemoteClick2SignModal/RemoteClick2SignModal';
import metadata from './E1PSignatureComponent.metadata.json5';
import messages from './E1PSignatureComponent.messages';
import styles from './E1PSignatureComponent.module.scss';
// eslint-disable-next-line import/no-relative-packages
import useAttributeConfigUtilForEA from '../../../e1p-capability-hooks/AttributeConfigUtil/EA/useAttributeConfigUtilForEA';
// eslint-disable-next-line import/no-relative-packages
import useAttributeConfigUtilForEU from '../../../e1p-capability-hooks/AttributeConfigUtil/EU/useAttributeConfigUtilForEU';

function E1PSignatureComponent(props) {
    const modalApi = useModal();
    const {
        submissionVM,
        updateWizardData,
        authHeader,
        LOB,
        onSignatureSuccess,
        producerCodeOptions,
        setProducerCodeOptions,
        setGettingSignatureDocument,
        viewOnlyMode,
        isSignatureRequired,
        showErrors,
        wizardSnapshot,
        saveLocationCodeChanges,
        checkCanPurchase,
        locationCodeEditable
    } = props;

    const { opCo } = useContext(AmfamOktaTokenContext);
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const { authUserData } = useAuthentication();
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [signatureUrl, setSignatureUrl] = useState(undefined);
    const [showSignButton, setShowSignButton] = useState(false);
    // use to track if sign in API call is in progress and disable change option in middle of process
    const [isSignInProgress, setIsSignInProgress] = useState(false);
    const [showPopupBlockerMessage, setShowPopupBlockerMessage] = useState(false);
    const [signPolicyResponse, setSignPolicyResponse] = useState(undefined);
    // use for signature type available values based on opCo
    const [availableSignatureTypeValues, setAvailableSignatureTypeValues] = useState([]);

    // Licensed producers won't be returned on can purchase call, if the logged in
    //  user is authorized to sell
    const isCurrentProducerAuthorizedToSell = isEmpty(
        get(submissionVM, 'value.licensedProducers.licensedProducers', [])
    );
    const isSignatureCompleted = get(submissionVM, 'value.baseData.signature_Ext.status') === 'COMPLETED';
    const isSignatureErrored = get(submissionVM, 'value.baseData.signature_Ext.status') === 'ERROR';
    const isSignaturePresent = !isEmpty(get(submissionVM, 'value.baseData.signature_Ext.status', ''));
    const jobNumber = get(submissionVM, 'value.quoteID') ?? get(submissionVM, 'value.jobID');
    const helpTxt1 = translator(messages.signatureTypeHelpText1);
    const helpTxt2 = translator(messages.signatureTypeHelpText2);
    const helpTxt3 = translator(messages.signatureTypeHelpText3);
    const helpTxt4 = translator(messages.signatureTypeHelpText4);
    const signatureTypeHelptext = htmlParser(`<div><ul><li>${helpTxt1}</li><li>${helpTxt2}</li><li>${helpTxt3}</li><li>${helpTxt4}</li></ul><div>`);
    const agentIdWhoSignedThePolicy = get(submissionVM, 'value.bindData.licensedProducerID', false);
    // This is getting set to default owner, by PC, even when not signed yet
    let agentDisplayNameWhoSignedThePolicy = get(submissionVM, 'value.baseData.signingAgentDisplayName_Ext');

    agentDisplayNameWhoSignedThePolicy = agentDisplayNameWhoSignedThePolicy === 'Default Owner' ? undefined : agentDisplayNameWhoSignedThePolicy;

    const isNewBusiness = get(submissionVM, 'baseData.value.businessTransactionType_Ext', '') === 'NewBusiness';
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');

    const {
        getLocationAndProducerCodes
    } = useNewBusinessUtil(
        submissionVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        setLocationAndProducerCodes
    );

    /**
     * IAP-4196 : Signature type available values based on opCo
     */
    const {
        getAvailableValuesForSignatureType: getAvailableValuesForEASignatureType
    } = useAttributeConfigUtilForEA(submissionVM);
    const {
        getAvailableValuesForSignatureType: getAvailableValuesForEUSignatureType
    } = useAttributeConfigUtilForEU(submissionVM);

    useEffect(() => {
        let isMounted = true;

        if (LOB === 'personalAuto_EA') {
            getAvailableValuesForEASignatureType()
                .then((availableValues) => {
                    if(isMounted){
                        setAvailableSignatureTypeValues(availableValues);
                    }
                });
        }

        if (LOB === 'personalUmbrella_EU') {
            getAvailableValuesForEUSignatureType(opCo)
                .then((availableValues) => {
                    if(isMounted){
                        setAvailableSignatureTypeValues(availableValues);
                    }
                });
        }

        return () => {
            isMounted = false;
        }
        // Only update on initial load    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        set(
            submissionVM, 'baseData.signatureType_ext.value',
            get(submissionVM, 'value.baseData.signature_Ext.signatureType')
        );

        if (isSignaturePresent && !isSignatureErrored) {
            onSignatureSuccess(true);
        }

        setShowSignButton(
            !isSignatureCompleted
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Assign current license producer to bind policy if no producer codes are available
        if (isCurrentProducerAuthorizedToSell) {
            // Will always be the current logged in user
            let producerID = get(authUserData, 'producerId_Ext', undefined);
            // Will always be the current logged in user
            let displayName = get(authUserData, 'displayName', undefined);

            // if the person who signed is different than current logged in user
            //   we have to change the display to the actual signer
            if (agentIdWhoSignedThePolicy) {
                producerID = agentIdWhoSignedThePolicy;
                displayName = agentDisplayNameWhoSignedThePolicy;
            }

            if (producerID && displayName) {
                if (setProducerCodeOptions) {
                    setProducerCodeOptions([{
                        code: producerID,
                        name: displayName
                    }]);
                }
            }

            set(submissionVM, 'value.bindData.licensedProducerID', producerID);
        } else {
            set(submissionVM, 'value.bindData.licensedProducerID', undefined);

            /**
             * E1PAP1PC-14867 :
             * wizardData and wizardSnapshot not being equal due to
             * some defaulting on each page so doing this temp fix
             */
            if (wizardSnapshot) {
                set(wizardSnapshot, 'value.bindData.licensedProducerID', undefined);
            }
        }

        if (!viewOnlyMode) {
            updateWizardData(submissionVM);
        }
        // * Only update in case of producer code option list changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCurrentProducerAuthorizedToSell]);

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            set(submissionVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            set(submissionVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    useEffect(() => {
        // get available producer codes for given state
        const policyState = get(submissionVM, 'baseData.policyAddress.state.value.code');

        if (policyState && isAgent && opCo !== 'CONNECT') {
            // only for agents
            getAndSetLocationCodes(policyState, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isProducerCodeDisabled = useMemo(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            set(submissionVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            set(submissionVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);

            return true;
        }

        return false;
    }, [locationAndProducerCodes, submissionVM]);

    const locationCodeChange = useCallback(
        async (value) => {
            set(submissionVM, 'baseData.externalID_Ext', value);

            const selectedProducerCode = locationAndProducerCodes?.producerCodes
                ?.find((producerCode) => producerCode?.externalId_Ext === value);

            set(submissionVM, 'baseData.producerCode_Ext', selectedProducerCode?.code);
            updateWizardData(submissionVM);

            if (saveLocationCodeChanges) {
                // Call SaveAndQuote to save location code changes
                await saveLocationCodeChanges();

                // Call canPurchase to check bind level validation
                // and get changed signing agents values based on changed location Code
                if (checkCanPurchase) {
                    checkCanPurchase();
                }
            }
        },
        [
            submissionVM, locationAndProducerCodes.producerCodes,
            updateWizardData, saveLocationCodeChanges, checkCanPurchase
        ]
    );

    const handleProducerApiRespone = useCallback(
        async (producerDetails) => {
            const {
                producerCode, externalID
            } = producerDetails;

            setLocationAndProducerCodes(
                [
                    {
                        producerCodes: { code: producerCode, name: producerCode },
                        locationCodes: { code: externalID, name: externalID }
                    }
                ]
            );
            set(submissionVM, 'baseData.producerCode_Ext', producerCode);
            set(submissionVM, 'baseData.externalID_Ext.value', externalID);
            updateWizardData(submissionVM);

            if (saveLocationCodeChanges) {
                // E1PAP1PC-14821
                // Call SaveAndQuote to save location code changes
                await saveLocationCodeChanges();

                // E1PAP1PC-14821
                // Call canPurchase to check bind level validation
                // and get signing agents values based on changed location Code
                if (checkCanPurchase) {
                    checkCanPurchase();
                }
            }
        },
        [checkCanPurchase, saveLocationCodeChanges, submissionVM, updateWizardData],
    );

    const showErrorMessage = useCallback((title, message) => {
        modalApi.showAlert({
            status: 'error',
            icon: 'mi-error-outline',
            title,
            message
        });
    }, [modalApi]);

    const signPolicy = async () => {
        setIsSignInProgress(true);
        setGettingSignatureDocument(true);
        setSignatureUrl(undefined);

        const signature = get(submissionVM, 'value.baseData.signatureType_ext');
        const licensedProducerID = get(submissionVM, 'value.bindData.licensedProducerID');
        let errorMessage;

        switch (signature) {
            case 'InPersonClickSign': {
                let inPersonResponse = signPolicyResponse;

                // User clicked sign and had pop up blocker on. We are showing info message to turn it off.
                // We are storing whole sign policy response as a state variable
                // so that when they click sign again we use that saved response instead of calling the api again
                // E1PAP1PC-11439
                if (!signPolicyResponse) {
                    const inPersonSignatureRoles = await SignatureUtil.getSignatureRoles(jobNumber, signature, authHeader);
                    const recipients = get(inPersonSignatureRoles, 'recipients', []);

                    inPersonResponse = await SignatureUtil.signPolicy(
                        signature, jobNumber, authHeader, recipients, licensedProducerID
                    );
                }

                errorMessage = get(inPersonResponse, 'exceptions_Ext[0].errorMessage');

                if (errorMessage) {
                    showErrorMessage(messages.ESignErrorMessageTitle, errorMessage);
                } else if (inPersonResponse && inPersonResponse.inPersonSignDocumentURL) {
                    const documentPopup = window.open(
                        inPersonResponse.inPersonSignDocumentURL,
                        '_blank',
                        'width=840,height=680'
                    );

                    // Will be null if pop up blocker is on
                    if (documentPopup) {
                        // on renewal page we have signature tab; if we complete signature and move to different tab
                        // and again if we come back on signature tab all data gets wiped out
                        set(submissionVM, 'value.baseData.signature_Ext.status', inPersonResponse.sigantureStatus);
                        set(submissionVM, 'value.baseData.signature_Ext.signatureType', inPersonResponse.signatureType);
                        // Enables the issue button
                        onSignatureSuccess(true);

                        setShowPopupBlockerMessage(false);

                        //* Sign button will be hidden only if we have signature status as completed
                        if (inPersonResponse.sigantureStatus === 'COMPLETED') {
                            setShowSignButton(false);
                        } else {
                            setShowSignButton(true);
                        }
                    } else {
                        // Show pop up blocker warning
                        setShowPopupBlockerMessage(true);
                        setSignPolicyResponse(inPersonResponse);
                    }
                } else {
                    setShowSignButton(true);
                }

                setIsSignInProgress(false);
                break;
            }
            case 'RemoteClick2Sign': {
                const remoteClickSignatureRoles = await SignatureUtil.getSignatureRoles(jobNumber, signature, authHeader);
                const remoteClickComponentProps = {
                    actionBtnLabel: translator(messages.confirm),
                    cancelBtnLabel: translator(messages.cancel),
                    submissionVM,
                    LOB,
                    quoteID: jobNumber,
                    authHeader,
                    signature,
                    onSignatureSuccess,
                    updateWizardData,
                    recipients: get(remoteClickSignatureRoles, 'recipients', []),
                    licensedProducerID,
                    showErrorMessage
                };

                modalApi.showModal(<RemoteClick2SignModal {...remoteClickComponentProps} />)
                    .then((res) => {
                        if (!res) {
                            setShowSignButton(true);
                        } else {
                            setShowSignButton(false);
                        }

                        setIsSignInProgress(false);
                    });
                break;
            }
            case 'Wet': {
                const response = await SignatureUtil.signPolicy(signature, jobNumber, authHeader, [], licensedProducerID);

                errorMessage = get(response, 'exceptions_Ext[0].errorMessage');

                if (errorMessage) {
                    showErrorMessage(messages.ESignErrorMessageTitle, errorMessage);
                } else {
                    setSignatureUrl(response.printAndSignDocumentURL);
                    // on renewal page we have signature tab; if we complete signature and move to different tab
                    // and again if we come back on signature tab all data gets wiped out
                    set(submissionVM, 'value.baseData.signature_Ext.status', response.sigantureStatus);
                    set(submissionVM, 'value.baseData.signature_Ext.signatureType', response.signatureType);
                    onSignatureSuccess(true);

                    //* Sign button will be hidden only if we have signature status as completed
                    if (response.sigantureStatus === 'COMPLETED') {
                        setShowSignButton(false);
                    } else {
                        setShowSignButton(true);
                    }
                }

                setIsSignInProgress(false);
                break;
            }
            case 'AgencySignature': {
                let agencyResponse = signPolicyResponse;

                // User clicked sign and had pop up blocker on. We are showing info message to turn it off.
                // We are storing whole sign policy response as a state variable
                // so that when they click sign again we use that saved response instead of calling the api again
                // E1PAP1PC-11439
                if (!signPolicyResponse) {
                    agencyResponse = await SignatureUtil.signPolicy(signature, jobNumber, authHeader, [], licensedProducerID);
                }

                const agencyErrorMessage = get(agencyResponse, 'exceptions_Ext[0].errorMessage');

                if (agencyErrorMessage) {
                    showErrorMessage(messages.ESignErrorMessageTitle, agencyErrorMessage);
                } else if (agencyResponse && agencyResponse.agencySignDocumentURL) {
                    const documentPopup = window.open(
                        agencyResponse.agencySignDocumentURL,
                        '_blank',
                        'width=840,height=680'
                    );

                    // Will be null if pop up blocker is on
                    if (documentPopup) {
                        // on renewal page we have signature tab; if we complete signature and move to different tab
                        // and again if we come back on signature tab all data gets wiped out
                        set(submissionVM, 'value.baseData.signature_Ext.status', agencyResponse.sigantureStatus);
                        set(submissionVM, 'value.baseData.signature_Ext.signatureType', agencyResponse.signatureType);
                        // Enables the issue button
                        onSignatureSuccess(true);
                        setShowPopupBlockerMessage(false);

                        //* Sign button will be hidden only if we have signature status as completed
                        if (agencyResponse.sigantureStatus === 'COMPLETED') {
                            setShowSignButton(false);
                        } else {
                            setShowSignButton(true);
                        }
                    } else {
                        // Show pop up blocker warning
                        setShowPopupBlockerMessage(true);
                        setSignPolicyResponse(agencyResponse);
                    }
                } else {
                    setShowSignButton(true);
                }

                setIsSignInProgress(false);
                break;
            }
            case 'MailAndSign': {
                const mailAndSignResponse = await SignatureUtil.signPolicy(signature, jobNumber, authHeader, [], licensedProducerID);

                errorMessage = get(mailAndSignResponse, 'exceptions_Ext[0].errorMessage');

                if (errorMessage) {
                    showErrorMessage(messages.ESignErrorMessageTitle, errorMessage);
                } else {
                    set(submissionVM, 'value.baseData.signature_Ext.status', mailAndSignResponse.sigantureStatus);
                    set(submissionVM, 'value.baseData.signature_Ext.signatureType', mailAndSignResponse.signatureType);
                    onSignatureSuccess(true);

                    //* Sign button will be hidden only if we have signature status as completed
                    if (mailAndSignResponse.sigantureStatus === 'COMPLETED') {
                        setShowSignButton(false);
                    } else {
                        setShowSignButton(true);
                    }
                }

                setIsSignInProgress(false);
                break;
            }
            case 'OnlineSign': {
                const pniOnlineSignatureName = get(submissionVM, ['value', 'baseData', 'accountHolder', 'displayName']);
                const onlineSignResponse = await SignatureUtil.signPolicy(signature, jobNumber, authHeader, [], licensedProducerID, pniOnlineSignatureName);

                errorMessage = get(onlineSignResponse, 'exceptions_Ext[0].errorMessage');

                if (errorMessage) {
                    showErrorMessage(messages.ESignErrorMessageTitle, errorMessage);
                } else {
                    set(submissionVM, 'value.baseData.signature_Ext.status', onlineSignResponse.sigantureStatus);
                    set(submissionVM, 'value.baseData.signature_Ext.signatureType', onlineSignResponse.signatureType);
                    onSignatureSuccess(true);

                    //* Sign button will be hidden only if we have signature status as completed
                    if (onlineSignResponse.sigantureStatus === 'COMPLETED') {
                        setShowSignButton(false);
                    } else {
                        setShowSignButton(true);
                    }
                }

                setIsSignInProgress(false);
                break;
            }
            default:
            // do nothing
        }

        setGettingSignatureDocument(false);
    };

    /**
     * Helper callback for retrieving the "SignatureType" typelist values of type PEsignatureTypes.
     */
    const getAvailableValuesForSignature = useCallback(() => {
        let values = [];
        const typelistFilters = viewModelService.productMetadata.get('pc').types
            .getTypelist('SignatureType_Ext').filters;

        if (typelistFilters) {
            values = find(typelistFilters, { name: 'PEsignatureTypes' }).codes;
        } else {values = get(submissionVM, 'baseData.signatureType_ext.aspects.availableValues', []);}

        const availableValues = values.map((code) => ({
                code: code.code,
                name: translator({ id: code.name })
            }));

        return availableValues;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService, submissionVM?.baseData?.signatureType_ext, translator]);

    /**
     * Helper callback for reseting Iframe.
     */
    const onCloseSignatureIframe = useCallback(() => {
        setSignatureUrl(undefined);
    }, []);

    /**
     * Helper callback for changing signing agent
     */
    const onSigningAgentChange = useCallback((value, path) => {
        set(submissionVM, path, value);
        set(submissionVM, 'value.bindData.licensedProducerID', value);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const onSignatureTypeChange = useCallback((value, path) => {
        set(submissionVM, path, value);
        updateWizardData(submissionVM);
        setSignPolicyResponse(undefined);
    }, [submissionVM, updateWizardData]);

    const resolvers = {
        resolveCallbackMap: {
            signPolicy,
            onHandleProducerApiResponse: handleProducerApiRespone
        },
        resolveClassNameMap: styles
    };

    const overrideProps = {
        '@field': {
            readOnly: !showSignButton || isSignatureCompleted || viewOnlyMode,
            showRequired: true,
            showErrors,
            autoComplete: false
        },
        // sign policy iframe is used for InPersonClickSign and Wet (Print & Sign) signature types
        signPolicyIframeClose: {
            visible: !!signatureUrl,
            onClick: onCloseSignatureIframe
        },
        iframeSignature: {
            src: signatureUrl,
            visible: !!signatureUrl
        },
        signButton: {
            visible: showSignButton
                && !isSignatureCompleted
                && !viewOnlyMode
                && get(submissionVM, 'baseData.signatureType_ext.value', false)
                && isSignatureRequired,
            disabled: isSignInProgress
        },
        signatureType: {
            // For Auto & Umbrella use signature type from attribute config
            availableValues: ['personalAuto_EA', 'personalUmbrella_EU'].includes(LOB) ? availableSignatureTypeValues : getAvailableValuesForSignature(),
            readOnly: isSignInProgress || viewOnlyMode || isSignatureCompleted || !showSignButton,
            tooltip: {
                text: signatureTypeHelptext
            },
            onValueChange: onSignatureTypeChange,
            visible: isSignatureRequired && (['personalAuto_EA', 'personalUmbrella_EU'].includes(LOB) ? availableSignatureTypeValues.length > 0 : true)
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            readOnly: isProducerCodeDisabled || !showSignButton || isSignatureCompleted || viewOnlyMode || !locationCodeEditable,
            onValueChange: locationCodeChange,
            visible: isAgent && (isNewBusiness || isSignatureRequired)
        },
        ExternalProducerDetailsComponent: {
            visible: !isAgent && (isNewBusiness || isSignatureRequired) && opCo !== 'CONNECT',
            defaultValue: get(submissionVM, 'baseData.externalID_Ext.value'),
            agencyDetailsNeeded: false,
            viewOnlyMode: !showSignButton || isSignatureCompleted || viewOnlyMode || !locationCodeEditable
        },
        signature: {
            visible: isNewBusiness || isSignatureRequired
        },
        signingAgent: {
            availableValues: producerCodeOptions,
            onValueChange: onSigningAgentChange,
            readOnly: ((!showSignButton || isSignatureCompleted) && isCurrentProducerAuthorizedToSell)
                || viewOnlyMode,
            value: get(submissionVM, 'value.bindData.licensedProducerID'),
            visible: (isNewBusiness || isSignatureRequired)
                && !['PolicyChange', 'Renewal'].includes(get(submissionVM, 'value.baseData.jobType'))
        },
        popupinlinenotificationDiv: {
            visible: showPopupBlockerMessage
        }
    };

    if (isSignInProgress) {
        return (
            <InlineLoader
                id="InlineEsignLoader"
                loading
                loadingMessage={translator(messages.pleaseWait)}
                size="medium"
            />
        );
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={submissionVM}
            onModelChange={updateWizardData}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

E1PSignatureComponent.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
            baseData: PropTypes.shape({
                signatureType_ext: PropTypes.string
            }),
            bindData: PropTypes.shape({
                licensedProducerID: PropTypes.string
            })
        }),
        baseData: PropTypes.shape({
            signatureType_ext: PropTypes.shape({})
        })
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    LOB: PropTypes.string.isRequired,
    onSignatureSuccess: PropTypes.func.isRequired,
    setProducerCodeOptions: PropTypes.func.isRequired,
    setGettingSignatureDocument: PropTypes.func,
    disabled: PropTypes.bool,
    producerCodeOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    viewOnlyMode: PropTypes.bool,
    isSignatureRequired: PropTypes.bool,
    showErrors: PropTypes.bool,
    wizardSnapshot: PropTypes.shape({}),
    saveLocationCodeChanges: PropTypes.func,
    checkCanPurchase: PropTypes.func,
    locationCodeEditable: PropTypes.bool
};

E1PSignatureComponent.defaultProps = {
    disabled: false,
    viewOnlyMode: false,
    isSignatureRequired: true,
    showErrors: false,
    wizardSnapshot: undefined,
    saveLocationCodeChanges: undefined,
    checkCanPurchase: undefined,
    locationCodeEditable: false,
    setGettingSignatureDocument: undefined
};
export default E1PSignatureComponent;
