import { defineMessages } from 'react-intl';

export default defineMessages({
    makeACopy: {
        id: "components.copyQuote.Make a Copy",
        defaultMessage: "Make a Copy"
    },
    successMessage:{
        id: "components.copyQuote.A new transaction has been created.",
        defaultMessage: "A new transaction ({quoteNumber}) has been created."
    }
});
