import { defineMessages } from 'react-intl';

export default defineMessages({
    personalAuto_EAQuote: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.ea.Auto Insurance Quote',
        defaultMessage: 'Auto Insurance Quote'
    },
    location: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.ea.Location',
        defaultMessage: 'Location'
    },
    vehicles: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.ea.Vehicles',
        defaultMessage: 'Vehicles'
    },
    drivers: {
        id: 'integration.quoteandbind.directives.templates.qnb-info-bar.ea.Drivers',
        defaultMessage: 'Drivers'
    }
});
