import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {
    get, set, isEmpty, findIndex
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useModal } from '@jutro/components';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useGaragingAddressUtil } from 'e1p-capability-hooks';
import { VehicleUtil } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages, eaValidationAndInfoMessages } from 'e1p-platform-translations';
import AdditionalInterestComponent from '../../components/AdditionalInterest/AdditionalInterestComponent';
import metadata from './VehiclesPage.metadata.json5';
import messages from './VehiclesPage.messages';
import EAVehiclePrefill from './EAVehiclePrefill/EAVehiclePrefill';

function VehiclesPage(props) {
    const modalApi = useModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        isSkipping,
        updateWizardSnapshot,
        steps,
        jumpTo
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [prefillData, setPrefillData] = useState('');
    const [prefillCompleted, setPrefillCompleted] = useState(false);
    const [vehicleLookupError, setVehicleLookupError] = useState(false);
    const { generateGaragingAddressDisplayName } = useGaragingAddressUtil();
    const { authHeader } = useAuthentication();
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [prefillWasCalled, setPrefillWasCalled] = useState(false);
    const [availableMakes, setAvailableMakes] = useState({});
    const [availableModels, setAvailableModels] = useState({});
    const [availableSeries, setAvailableSeries] = useState({});

    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        registerInitialComponentValidation
    } = useValidation('VehiclePage');

    submissionVM.flowStepIDs_Ext.value = ['vehicle'];
    submissionVM.entryCompletionStepIDs_Ext.value = ['vehicle'];

    const translator = useTranslator();

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        // IAP-3939 : stop user on vehicles page if no vehicle has been added on the quote
        registerInitialComponentValidation(
            () => !isEmpty(get(submissionVM, 'lobData.personalAuto_EA.coverables.vehicles.value', []))
        );
    }, [registerInitialComponentValidation, submissionVM])

    // array of vehicle objects (not vmnodes)
    const vehiclesObjectArray = get(submissionVM, 'lobData.personalAuto_EA.coverables.vehicles.value', []);
    const atleastOneCarBeingAdded = (() => {
        if (vehiclesObjectArray.length > 1) { return true; }

        const make = get(vehiclesObjectArray[0], 'make');
        const model = get(vehiclesObjectArray[0], 'model');
        const year = get(vehiclesObjectArray[0], 'year');
        const vin = get(vehiclesObjectArray[0], 'vin');

        if (vin && year) { return true; }

        if (make && model && year) { return true; }

        return false;
    })();
    const defaultGaragingAddress = get(submissionVM, 'baseData.policyAddress.value');

    /**
     * Helper callback for creating a new vehicle "VMNode" object and adding it to the list of
     * vehicles on the policy.
     */
    const addVehicleVM = useCallback(() => {
        const vehicleObj = {
            garagingLocation: defaultGaragingAddress
        };
        const { _xCenter, _dtoName } = submissionVM.lobData.personalAuto_EA.coverables.vehicles;
        const vehicleVM = viewModelService.create(vehicleObj, _xCenter, _dtoName);

        if (!vehicleVM.safetyFeatures.value) {
            vehicleVM.safetyFeatures.value = [];
        }

        submissionVM.lobData.personalAuto_EA.coverables.vehicles.pushElement(vehicleVM);
        submissionVM.lobData.personalAuto_EA.coverables.vehicles.children.forEach((vehicle) => {
            set(vehicle, 'callShowAll', false);
        });
        setCheckScrolling(true);
        setIndexStale(true);

        return updateWizardData(submissionVM);
    }, [defaultGaragingAddress,
        submissionVM,
        viewModelService,
        updateWizardData]);

    /**
     * Helper callback for adding the vehicle prefill data to the policy.
     */
    const addVehiclePrefillDataToPolicy = useCallback(() => {
        if (submissionVM.value.prefillVehicles && !prefillCompleted) {
            const prefillVehicles = [];
            const existingVehicles = vehiclesObjectArray;

            if (submissionVM.value.prefillVehicles && submissionVM.value.prefillVehicles !== '') {
                submissionVM.value.prefillVehicles.forEach((newVehicleFromPrefill) => {
                    if (newVehicleFromPrefill.addToQuote) {
                        prefillVehicles.push(newVehicleFromPrefill);
                    }
                });

                // Get the list of vehicles which are present in the prefill but are
                // NOT already on the policy
                const newVehiclesFromPrefill = prefillVehicles.filter(
                    (newVehicleFromPrefill) => !existingVehicles.some((existingVehicle) =>
                        newVehicleFromPrefill.vin === existingVehicle.vin)
                );

                // Combine the list of existing vehicles and the newly added vehicles
                // from the prefill data
                const newVehicles = [...existingVehicles, ...newVehiclesFromPrefill];

                // Add one vehicle if the policy still has no vehicles after populating
                // from the prefill data
                if (isEmpty(newVehicles)) {
                    addVehicleVM(true);
                } else {
                    submissionVM.lobData.personalAuto_EA.coverables.vehicles.value = newVehicles;
                    submissionVM.lobData.personalAuto_EA.coverables.vehicles.children.forEach(
                        (vehicle) => {
                            vehicle.callShowAll = true;

                            if (!vehicle.safetyFeatures.value) {
                                vehicle.safetyFeatures.value = [];
                            }

                            if (!vehicle.garagingLocation.value) {
                                vehicle.garagingLocation = defaultGaragingAddress;
                            }
                        }
                    );
                }

                setPrefillCompleted(true);
            } else {
                submissionVM.lobData.personalAuto_EA.coverables.vehicles.children.forEach(
                    (vehicle) => {
                        if (vehicle.vin.value) {
                            vehicle.callShowAll = true;
                        }
                    }
                );
            }

            updateWizardData(submissionVM);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefillData]);

    /**
     * Helper callback for showing the EA Vehicle Prefill Popup window.
     */
    const showEAVehiclePrefillPopup = useCallback(
        async (vm, prefill) => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: true,
                submissionVM: vm,
                authHeader,
                updateWizardData,
                prefillData: prefill
            };
            const result = await modalApi.showModal(<EAVehiclePrefill {...componentProps} />);

            return result;
        },
        [authHeader, updateWizardData, modalApi]
    );

    /**
     * Helper callback for handling the vehicle data prefill operations.
     */
    const handleVehicleDataPrefill = useCallback(
        (prefill) => {
            if (prefill.value.length === 0) {
                submissionVM.value.prefillVehicles = prefill.value;
                addVehiclePrefillDataToPolicy();
            } else {
                showEAVehiclePrefillPopup(submissionVM, prefill).then(() => {
                    // this is where you could take the data passed back from the modal
                    // EX: const modifiedVM = viewModelService.clone(wrapperObj.issueVM);
                    addVehiclePrefillDataToPolicy();
                });
            }
        },
        [addVehiclePrefillDataToPolicy, showEAVehiclePrefillPopup, submissionVM]
    );

    // this grabs the latest element on the page --
    // this is to make sure this element has been loaded
    const latestVehicleElement = document.getElementById(`vehicleTitleHeader${submissionVM.lobData.personalAuto_EA.coverables.vehicles.children.length}`);

    useEffect(() => {
        // indexStale set in the add vehicle function
        // once latest element is loaded and a new vehicle is added
        // we check if the button should be visible
        if (latestVehicleElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestVehicleElement]);

    /**
     * Helper callback for retrieving and processing the vehicle prefill data.
     */
    const retrieveAndProcessVehiclePrefillData = useCallback(async () => {
        const prefillVehicles = get(submissionVM, 'lobData.personalAuto_EA.prefillVehicles', []);

        setPrefillData(prefillVehicles);
        handleVehicleDataPrefill(prefillVehicles);
        setPrefillWasCalled(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onVehicleLookupError = useCallback(
        (value) => {
            setVehicleLookupError(value);
        },
        []
    );

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(async (_, calledFromSave = false) => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setIsSavingEndorsement(true);
        // Default AutoPay before rating
        //   Set autopay to true if null or undefined
        //     otherwise retain value
        submissionVM.lobData.personalAuto_EA.autoPayDiscInd.value ??= true;

        const indexOfDriverAssignment = steps.findIndex((step) => step.path === '/assignment');

        const shouldSkipDriverAssignmentPage = VehicleUtil.shouldSkipDriverAssignmentPage(submissionVM);

        /**
         * If driver assignment is available for given state and 
         * shouldSkipDriverAssignmentPage indicator(given job has one driver and one vehicle and vehicle is not garaged out fo state more than 6 months) is true 
         * then perform driver assignment for given vehicle
         */
        if (indexOfDriverAssignment > 0 && !calledFromSave && shouldSkipDriverAssignmentPage) {
            VehicleUtil.setDriverAssignmentWhenOneDriverOneVehiclePresent(submissionVM, updateWizardData);
        }

        // Make a updateDraftSubmission call if driver assignment is there in Quote flow else call saveAndQuoteSubmission
        let serviceCall = LoadSaveService.saveAndQuoteSubmission;

        if (indexOfDriverAssignment > 0 && !shouldSkipDriverAssignmentPage) {
            serviceCall = LoadSaveService.updateDraftSubmission;
        }

        submissionVM.value = await serviceCall(
            submissionVM.value,
            authHeader
        );

        updateWizardData(submissionVM);
        updateWizardSnapshot(submissionVM);
        setIsSavingEndorsement(false);

        // Skip driver assignment page
        if (indexOfDriverAssignment > 0 && !calledFromSave && shouldSkipDriverAssignmentPage) {
            const riskAnalysisPageIndex = findIndex(steps, ({ path }) => path === '/risk-analysis');

            jumpTo(riskAnalysisPageIndex, true, submissionVM.value);

            return false;
        }

        return submissionVM;
    }, [
        isComponentValid, submissionVM, steps,
        LoadSaveService.saveAndQuoteSubmission,
        LoadSaveService.updateDraftSubmission,
        authHeader, updateWizardData, updateWizardSnapshot,
        jumpTo
    ]);

    const onSave = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    /**
                     * E1PAP1PC-14867 :
                     * wizardData and wizardSnapshot not being equal due to
                     * some defaulting on each page so doing this temp fix
                     */
                    submissionVM.lobData.personalAuto_EA.coverables
                        .vehicles.children.forEach((vehicle) => {
                            generateGaragingAddressDisplayName(vehicle.garagingLocation);
                        });
                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [generateGaragingAddressDisplayName, isComponentValid, onNext, submissionVM, updateWizardSnapshot]
    );

    /**
     * Helper effect for handling the component initialization logic.
     */
    useEffect(() => {
        if (!isPageInitialized) {
            const vehiclesPath = 'lobData.personalAuto_EA.coverables.vehicles';
            const vehicles = get(submissionVM, `${vehiclesPath}.value`);

            // Retrieve the vehicle prefill if no vehicles are present on the policy
            if (isEmpty(vehicles)) {
                retrieveAndProcessVehiclePrefillData();
            } else {
                setPrefillCompleted(true);
            }

            setIsPageInitialized(true);
        }
    }, [
        retrieveAndProcessVehiclePrefillData,
        isPageInitialized,
        submissionVM,
        updateWizardData,
        prefillCompleted,
        addVehicleVM
    ]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(
        () => {
            let loadingMessage = '';

            if (!prefillCompleted) {
                loadingMessage = translator(messages.loadingPrefillDataMessage);
            } else if (isSavingCurrentPageChanges) {
                loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
            } else if (!isPageInitialized || isSavingEndorsement || isSkipping) {
                loadingMessage = translator(messages.loadingNextPageMessage);
            }

            return loadingMessage;
        },
        [
            prefillCompleted, isSavingCurrentPageChanges,
            isPageInitialized, isSavingEndorsement, isSkipping, translator
        ]
    );

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        vehiclesPageLoadingIndicator: {
            loaded: isPageInitialized
                && prefillCompleted
                && !isSavingEndorsement
                && !isSkipping
                && !isSavingCurrentPageChanges,
            text: getLoadingIndicatorMessage
        },
        vehicleLookupErrorNotificationDiv: {
            visible: vehicleLookupError
        },
        vehiclesPageContainer: {
            visible: isPageInitialized
                && prefillCompleted
                && !isSavingEndorsement
                && !isSavingCurrentPageChanges
            // && !isSkipping
            // Can't put isSkipping here because the
            // 11.3 page decides its valid before
            // the main container is rendered in
            // which is the part that will make the page not valid
        },
        tpiComponentId: {
            submissionVM,
            viewModelService,
            authHeader,
            updateWizardData
        },
        oneCarMinimumNotificationDiv: {
            visible: !atleastOneCarBeingAdded && isPageSubmitted
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleGridContainer')
        },
        vehicleInfoChangedMessage: {
            message: translator(eaValidationAndInfoMessages.vehicleHasUpdatedMessage)
        },
        vehicleInfoChangedMessageDiv: {
            visible: (() => {
                const vehicles = get(submissionVM, 'value.lobData.personalAuto_EA.coverables.vehicles', []);
                const doesVehicleInfoChanged = vehicles.some((vehicle) => !!vehicle.vehicleInfoChanged);

                return doesVehicleInfoChanged;
            })()
        },
        EAVehicleGrid: {
            key: get(submissionVM, 'lobData.personalAuto_EA.coverables.vehicles', []).length,
            vehicles: get(submissionVM, 'lobData.personalAuto_EA.coverables.vehicles', []),
            path: 'lobData.personalAuto_EA.coverables.vehicles.children',
            onValidate,
            viewModelService,
            showErrors: isPageSubmitted,
            policyState: get(submissionVM, 'baseData.policyAddress.state.value.code'),
            vehiclePageDisregardFieldValidation: disregardFieldValidation,
            authHeader,
            onValidationChange: onValidate,
            transactionVM: submissionVM,
            updateWizardData,
            setCheckScrolling,
            addVehicleVM,
            isSkipping,
            availableMakes,
            setAvailableMakes,
            availableModels,
            setAvailableModels,
            availableSeries,
            setAvailableSeries
        },
        vehicleInformationSubHeaderID: {
            content: prefillCompleted && submissionVM.lobData.personalAuto_EA.prefillVehicles.value.length === 0
                ? translator(messages.vehicleNoPrefillSubheader) : translator(messages.eaVehicleInformationSubHeader),
            visible: prefillWasCalled
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps),
        [submissionVM, overrideProps]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onAddVehicleClick: addVehicleVM,
            onValidate,
            onPrefillData: handleVehicleDataPrefill,
            onVehicleLookupError
        },
        resolveComponentMap: {
            additionalinterestcomponent: AdditionalInterestComponent
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    if (!isPageInitialized) {
        return null;
    }

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && atleastOneCarBeingAdded
                && !isComponentValid
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

VehiclesPage.propTypes = wizardProps;
export default VehiclesPage;