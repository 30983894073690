import {
    get, includes, isEmpty
} from 'lodash';

function registerValidationForExposures(
    registerComponentValidation,
    underlyingPolicyVM
) {
    registerComponentValidation(() => {
        switch (get(underlyingPolicyVM, 'policyType.value.code')) {
            case 'personalproperty':
            {
                const businessExposures = get(
                    underlyingPolicyVM,
                    'value.personalPropertyExposure.businessExposures',
                    []
                );
                const businessExposuresValid = businessExposures.every((businessExposure) => {
                    return (
                        !isEmpty(businessExposure.category)
                            && (
                                !isEmpty(businessExposure.name)
                                || !includes(['homebusiness', 'businesspursuits'], businessExposure.category)
                            )
                            && (
                                !isEmpty(businessExposure.description)
                                || !includes(['homebusiness', 'businesspursuits'], businessExposure.category)
                            )
                            && (
                                !isEmpty(businessExposure.type)
                                || businessExposure.category !== 'homebusiness'
                            )
                            && (
                                !isEmpty(businessExposure.otherDescription)
                                || (businessExposure.category !== 'homebusiness' || businessExposure.type !== 'other')
                            )
                    );
                });
                return (
                    !isEmpty(get(underlyingPolicyVM, 'value.personalPropertyExposure.propertyType'))
                        && get(underlyingPolicyVM, 'value.personalPropertyExposure.swimmingPoolInd') !== undefined
                        && (
                            !isEmpty(get(underlyingPolicyVM, 'value.personalPropertyExposure.numberOfUnits'))
                            || get(underlyingPolicyVM, 'value.personalPropertyExposure.propertyType') !== 'PrimaryResidenceWithRental'
                        )
                        && !isEmpty(get(underlyingPolicyVM, 'value.personalPropertyExposure.riskLocation.addressLine1'))
                        && !isEmpty(get(underlyingPolicyVM, 'value.personalPropertyExposure.riskLocation.city'))
                        && !isEmpty(get(underlyingPolicyVM, 'value.personalPropertyExposure.riskLocation.state'))
                        && !isEmpty(get(underlyingPolicyVM, 'value.personalPropertyExposure.riskLocation.zipCode'))
                        && businessExposuresValid
                );
            }

            case 'commercialproperty':
            {
                const rentalDwellingExposures = get(
                    underlyingPolicyVM,
                    'value.rentalDwellingExposures',
                    []
                );
                const allRentalDwellingExposuresValid = rentalDwellingExposures.every(
                    (rentalDwelling) => {
                        return (
                            !isEmpty(get(rentalDwelling, 'riskLocationAddress.addressLine1'))
                                && !isEmpty(get(rentalDwelling, 'riskLocationAddress.city'))
                                && !isEmpty(get(rentalDwelling, 'riskLocationAddress.state'))
                                && !isEmpty(get(rentalDwelling, 'riskLocationAddress.zipCode'))
                        );
                    }
                );
                return !isEmpty(rentalDwellingExposures) && allRentalDwellingExposuresValid;
            }

            case 'personalauto':
            {
                const personalVehicles = get(underlyingPolicyVM, 'value.personalVehicleExposures', []);
                const allVehiclesValid = personalVehicles.every((vehicle) => {
                    return (
                        !isEmpty(vehicle.vin)
                            && vehicle.year !== undefined
                            && !isEmpty(vehicle.make)
                            && !isEmpty(vehicle.model)
                            && vehicle.isCompOnly !== undefined
                            && vehicle.isVehiclePassengerVan !== undefined
                            && vehicle.isCompanyFurnishedVehicle !== undefined
                            && vehicle.isAntiqueClassic !== undefined
                            && vehicle.isGrossVehicleWeightOverThreshold !== undefined
                    );
                });
                return !isEmpty(personalVehicles) && allVehiclesValid;
            }
            case 'personalwatercraft':
            {
                const personalWaterCrafts = get(underlyingPolicyVM, 'value.watercraftExposures', []);
                const allWaterCraftsValid = personalWaterCrafts.every((watercraft) => {
                    return (
                        watercraft.year !== undefined
                            && !isEmpty(watercraft.make)
                            && !isEmpty(watercraft.model)
                            && watercraft.compOnlyInd !== undefined
                            && watercraft.boatLength !== undefined
                            && watercraft.horsePower !== undefined
                    );
                });
                return !isEmpty(personalWaterCrafts) && allWaterCraftsValid;
            }
            case 'cycle':
            {
                const cycleExposures = get(underlyingPolicyVM, 'value.cycleExposures', []);
                const allCyclesValid = cycleExposures.every((cycle) => {
                    return (
                        !isEmpty(cycle.vin)
                            && cycle.year !== undefined
                            && !isEmpty(cycle.make)
                            && !isEmpty(cycle.model)
                            && cycle.isCompOnly !== undefined
                    );
                });
                return !isEmpty(cycleExposures) && allCyclesValid;
            }
            case 'otherspecialityvehicle':
            {
                const recreationVehicles = get(underlyingPolicyVM, 'value.recreationalVehicleExposures', []);
                const allRecreationVehiclesValid = recreationVehicles.every(
                    (recreationalVehicle) => {
                        return (
                            !isEmpty(recreationalVehicle.vin)
                                && recreationalVehicle.year !== undefined
                                && !isEmpty(recreationalVehicle.make)
                                && !isEmpty(recreationalVehicle.model)
                                && recreationalVehicle.isCompOnly !== undefined
                                && recreationalVehicle.isLicensedForRoadUse !== undefined
                                && recreationalVehicle.recreationalVehicleType !== undefined
                        );
                    }
                );
                return !isEmpty(recreationVehicles) && allRecreationVehiclesValid;
            }
            default:
                return true;
        }
    });
}

function onChangeOfPolicyTypeExposure(
    value,
    underlyingPolicyVM,
    disregardFieldValidation,
    writeValue
) {
    const oldPolicyTypeValue = get(underlyingPolicyVM, 'policyType.value.code', '');
    switch (oldPolicyTypeValue) {
        case 'personalproperty':
            disregardFieldValidation('propertyExposure');
            writeValue(undefined, 'personalPropertyExposure');
            break;
        case 'commercialproperty':
            disregardFieldValidation('rentalDwellingExposures');
            writeValue([], 'rentalDwellingExposures');
            break;
        case 'personalauto':
            disregardFieldValidation('personalVehicleExposures');
            writeValue([], 'personalVehicleExposures');
            break;
        case 'personalwatercraft':
            disregardFieldValidation('watercraftExposures');
            writeValue([], 'watercraftExposures');
            break;
        case 'cycle':
            disregardFieldValidation('cycleExposures');
            writeValue([], 'cycleExposures');
            break;
        case 'otherspecialityvehicle':
            disregardFieldValidation('recreationalVehicleExposures');
            writeValue([], 'recreationalVehicleExposures');
            break;
        default:
            disregardFieldValidation('propertyExposure');
    }
    writeValue(value, 'policyType');
}

export default {
    registerValidationForExposures,
    onChangeOfPolicyTypeExposure
};
