import React, { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import { ModalNext, ModalBody, ModalFooter } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import PropTypes from 'prop-types';
import styles from './E1PCreditReportModalComponent.module.scss';
import metadata from './E1PCreditReportModalComponent.metadata.json5';
import messages from './E1PCreditReportModalComponent.messages';

import { Button } from '@jutro/legacy/components';

function E1PCreditReportModalComponent(props) {
    const {
        pni,
        sni,
        LOB,
        noHitCreditRecords,
        isOpen,
        onResolve,
        onReject
    } = props;

    const translator = useTranslator();
    const [
        isProvideMoreInfoAndTryAgainTextVisible,
        setProvideMoreInfoAndTryAgainTextVisible
    ] = useState(false);
    const [noHITForPniEA, setNoHITForPniEA] = useState(false);
    const [noHITForSniEA, setNoHITForSniEA] = useState(false);

    useEffect(
        () => {
            // We are checking SSN and prior address only for homeowners
            if (LOB === 'homeowners_EH') {
                const ssnPresent = _.get(pni, 'value.person.ssn');
                const priorAddressPresent = _.get(
                    pni, 'value.priorAddresses[0].priorAddress.displayName'
                );

                setProvideMoreInfoAndTryAgainTextVisible(!ssnPresent || !priorAddressPresent);
            }

            if (LOB === 'personalAuto_EA') {
                const pniNoHitRecord = noHitCreditRecords
                    .find((creditRecord) => creditRecord?.assignment?.integrationId === _.get(pni, 'integrationId.value'));

                setNoHITForPniEA(pniNoHitRecord);

                const sniNoHitRecord = noHitCreditRecords
                    .find((creditRecord) => creditRecord?.assignment?.integrationId === _.get(sni, 'integrationId.value'));

                setNoHITForSniEA(sniNoHitRecord);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const getSSNValuePNI = useCallback(
        () => {
            const ssnValue = _.get(pni, 'value.person.ssn');

            return ssnValue || translator(e1pCommonMessages.noneProvided);
        }, [pni, translator]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            readOnly: true,
            autoComplete: false
        },
        pniComponentEH: {
            visible: LOB === 'homeowners_EH'
        },
        primaryNamedInsuredEH: {
            value: _.get(pni, 'value.person.displayName')
        },
        dateOfBirthEH: {
            dateDTO: _.get(pni, 'person.dateOfBirth')
        },
        socialSecurityNumberEH: {
            value: getSSNValuePNI()
        },
        currentAddressEH: {
            value: _.get(pni, 'value.person.primaryAddress.displayName')
        },
        priorAddressEH: {
            value: _.get(pni, 'value.priorAddresses[0].priorAddress.displayName')
                ?? translator(e1pCommonMessages.noneProvided)
        },
        provideMoreInfoAndTryAgainText: {
            visible: isProvideMoreInfoAndTryAgainTextVisible
        },
        provideInfoIsCorrectText: {
            visible: !isProvideMoreInfoAndTryAgainTextVisible
        },
        pniSniComponentEA: {
            visible: LOB === 'personalAuto_EA'
        },
        pniComponentEA: {
            visible: !!noHITForPniEA
        },
        sniComponentEA: {
            visible: !!noHITForSniEA
        },
        primaryNamedInsuredEA: {
            value: _.get(pni, 'value.person.displayName')
        },
        dateOfBirthPniEA: {
            dateDTO: _.get(pni, 'person.dateOfBirth')
        },
        secondaryNamedInsuredEA: {
            value: _.get(sni, 'value.person.displayName')
        },
        dateOfBirthSniEA: {
            dateDTO: _.get(sni, 'person.dateOfBirth')
        },
        currentAddressEA: {
            value: _.get(pni, 'value.person.primaryAddress.displayName')
        },
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const onUseNoHitCreditScore = useCallback(
        () => onResolve(true), [onResolve]
    );

    const onCancel = useCallback(() => onReject(), [onReject]);

    const creditReportModal = <MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={overrideProps}
        {...resolvers} />;

    return (
        <ModalNext
            isOpen={isOpen}
            className={styles.smallPopUp}
        >
            <ModalBody>
                {creditReportModal}
            </ModalBody>
            <ModalFooter>
                <Button type="outlined" onClick={onCancel}>
                    {translator(e1pCommonMessages.cancel)}
                </Button>
                <Button type="filled" onClick={onUseNoHitCreditScore}>
                    {translator(messages.useNoHitCreditScore)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

E1PCreditReportModalComponent.propTypes = {
    pni: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    sni: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    noHitCreditRecords: PropTypes.shape([

    ]).isRequired,
    LOB: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired
};
export default E1PCreditReportModalComponent;
