/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { Grid, GridItem } from '@jutro/layout';
import { CurrencyValue } from '@jutro/components';
import { IntlContext } from '@jutro/locale';
import { SymbolCurrencyComponent, E1PLoader } from 'e1p-capability-policyjob-react';
import styles from './EARenewalClauseComponent.module.scss';

import { CheckboxField } from '@jutro/legacy/components';

class EARenewalClauseComponent extends Component {
    /**
     * @memberof gw-components-platform-react.EARenewalClauseComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.displayName - name of clause to display
     * @prop {bool} propTypes.readOnly - if the clause selection is read only
     * @prop {bool} propTypes.value - is the clause is seleceted
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {bool} propTypes.isLoading - should the clause be loading
     * @prop {string} propTypes.loadingMessage - message to be shown while loading
     * @prop {string} propTypes.containerClassName - clause container class
     * @prop {bool} propTypes.showAmount - determine to show amount next to displayName
     */

    static contextType = IntlContext;

    static propTypes = {
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        readOnly: PropTypes.bool,
        onValueChange: PropTypes.func.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape({})),
        value: PropTypes.bool,
        path: PropTypes.string,
        isLoading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        checked: PropTypes.bool,
        isEditable: PropTypes.bool,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        containerClassName: PropTypes.string,
        amount: PropTypes.arrayOf(PropTypes.shape({})),
        noCoverage: PropTypes.bool,
        isQuoteStale: PropTypes.bool,
        numberOfTerms: PropTypes.number,
        isCompOnlyVehicle: PropTypes.bool
    };

    static defaultProps = {
        readOnly: false,
        path: undefined,
        value: undefined,
        isLoading: false,
        loadingMessage: '',
        checked: false,
        children: undefined,
        isEditable: true,
        description: undefined,
        containerClassName: undefined,
        amount: undefined,
        noCoverage: false,
        isQuoteStale: false,
        numberOfTerms: 0,
        isCompOnlyVehicle: false
    };

    handleChange = (value) => {
        const { onValueChange, path } = this.props;

        if (onValueChange) {
            onValueChange(value, path);
        }
    };

    getAmount = (labelName, labelAmount) => {
        const { isQuoteStale } = this.props;

        if (!_.isUndefined(labelAmount) && !isQuoteStale) {
            return <SymbolCurrencyComponent value={labelAmount} />;
        }

        return labelName;
    }

    renderEditableValue() {
        const {
            readOnly,
            value,
            id,
            isLoading,
            loadingMessage,
            children,
            path,
            checked,
            description,
            containerClassName,
            amount,
            noCoverage,
            numberOfTerms,
            isCompOnlyVehicle
        } = this.props;

        const hasChildren = !_.isNil(children);
        let clauseTermStyle = styles.oneTermClauseHeight;

        switch (numberOfTerms) {
            case 2:
                clauseTermStyle = styles.twoTermClauseHeight;
                break;
            case 3:
                clauseTermStyle = styles.threeTermClauseHeight;
                break;
            case 4:
                clauseTermStyle = styles.fourTermClauseHeight;
                break;
            case 5:
                clauseTermStyle = styles.fiveTermClauseHeight;
                break;
            case 6:
                clauseTermStyle = styles.sixTermClauseHeight;
                break;
            case 7:
                clauseTermStyle = styles.sevenTermClauseHeight;
                break;
            default:
                break;
        }

        const clauseLodingStyle = `${styles.clause} ${clauseTermStyle}`;
        const clauseContainerStyles = isLoading
            ? classNames(styles.clause, containerClassName)
            : classNames(clauseLodingStyle, containerClassName);
        const checkboxControlStyles = classNames({
            [styles.clauseAndTermSpacing]: hasChildren
        });
        const checkboxStyles = classNames({
            [styles.clauseNoPadding]: !hasChildren
        });

        // if isCompOnlyVehicle is true, then we will not show coverages section on the UI
        if (isCompOnlyVehicle) {
            return (
                <div className={clauseContainerStyles}>
                    {isLoading
                        ? (
                            <E1PLoader
                                loaded={!isLoading}
                                text={loadingMessage}
                            />
                        ) : (<span />)
                    }
                </div>
            );
        }

        if (noCoverage) {
            return (
                <div className={clauseContainerStyles}>
                    <span className={styles.noCoverageText}>Not applicable</span>
                </div>
            );
        }

        const getEmptyDivClassName = () => {
            switch (numberOfTerms) {
                case 0:
                    return styles.emptyDivNoTerm;
                case 1:
                    return styles.emptyDivOneTerm;
                case 2:
                    return styles.emptyDivTwoTerm;
                default:
                    return styles.emptyDivNoTerm;
            }
        };

        return (
            <div className={clauseContainerStyles}>
                {isLoading
                    ? (
                        <E1PLoader
                            loaded={!isLoading}
                            text={loadingMessage}
                        />
                    ) : (
                        <Grid
                                columns={[
                                    '0.3fr',
                                    '2fr',
                                    '0.85fr'
                                ]}
                                gap="none"
                                id="Grid"
                                rows={[
                                    '1fr',
                                ]}
                                tag="div"
                            >
                                <GridItem
                                    className="jut__Containers__highlightHover"
                                    tag="div"
                                >
                                    <CheckboxField
                                        id={id}
                                        value={value}
                                        readOnly={readOnly}
                                        onValueChange={this.handleChange}
                                        className={checkboxStyles}
                                        controlClassName={checkboxControlStyles}
                                        path={path}
                                        checked={checked}
                                        showInlineLabel
                                        layout="full-width"
                                        hideLabel
                                    />
                                </GridItem>
                                <GridItem
                                    className="jut__Containers__highlightHover"
                                    tag="div"
                                >
                                    {
                                        value && children
                                            ? children : <div className={getEmptyDivClassName()} />
                                    }
                                </GridItem>
                                <GridItem
                                    className={styles.premiumStyle}
                                    tag="div"
                                >
                                    <SymbolCurrencyComponent value={amount} />
                                </GridItem>
                            </Grid>
                    )
                }
            </div>
        );
    }

    renderReadOnlyValue() {
        const { displayName, children, amount } = this.props;

        return (
            <div className={styles.readOnlyGrid}>
                <span className={styles.readOnlyGridSlotLeft}>
                    {displayName}
                    <span className={styles.readOnlyGridAmount}>
                        {!_.isUndefined(amount) ? (
                            <CurrencyValue
                                amount={amount.amount}
                                currency={amount.currency}
                                showFractions
                            />
                        ) : undefined}
                    </span>
                </span>
                <div className={styles.readOnlyGridSlotRight}>{children}</div>
            </div>
        );
    }

    render() {
        const { isEditable } = this.props;

        return isEditable ? this.renderEditableValue() : this.renderReadOnlyValue();
    }
}

export default EARenewalClauseComponent;
