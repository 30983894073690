import React, {
    useCallback, useEffect, useMemo, useState
} from 'react';
import {
    get, set, isUndefined, isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from '../EUVehicleOperatorPage.metadata.json5';

function ViewEUVehicleOperatorPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);
    const {
        onValidate
    } = useValidation('EUVehicleOperatorPage');

    const vehicleOperatorsVM = useMemo(() => get(policyChangeVM, 'lobData.personalUmbrella_EU.vehicleOperators', []), [policyChangeVM]);

    useEffect(() => {
        if (isUndefined(vehicleOperatorsVM.value)) {
            set(vehicleOperatorsVM, 'value', []);
        }

        updateWizardData(policyChangeVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const vehicleOperatorElement = document.getElementById(`firstName${vehicleOperatorsVM.length - 1}`);

        if (vehicleOperatorElement
            && isEmpty(vehicleOperatorElement.value)
            && vehicleOperatorElement.focus !== undefined) {
            vehicleOperatorElement.focus();
        }
    }, [vehicleOperatorsVM.length]);

    // this grabs the latest element on the page --
    // this is to make sure this element has been loaded
    const latestVehicleOperatorElement = document.getElementById(
        `euVehicleOperatorComponentDiv${policyChangeVM.lobData.personalUmbrella_EU.vehicleOperators.children.length - 1}`
    );

    useEffect(() => {
        // indexStale set in the add vehicle operator function
        // once latest element is loaded and a new vehicle operator is added
        // we check if the button should be visible
        if (latestVehicleOperatorElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestVehicleOperatorElement]);

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: false
        },
        vehicleOperatorPageLoadingIndicator: {
            loaded: true
        },
        vehicleOperatorPageContainer: {
            visible: true
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleOperatorsContainer')
        },
        EUVehicleOperatorGrid: {
            key: get(policyChangeVM, 'lobData.personalUmbrella_EU.vehicleOperators', []).length,
            transactionVM: policyChangeVM,
            drivers: get(policyChangeVM, 'lobData.personalUmbrella_EU.vehicleOperators', []),
            path: 'lobData.personalUmbrella_EU.vehicleOperators.children',
            onValidate,
            showErrors: false,
            setCheckScrolling,
            updateWizardData,
            viewOnlyMode: true
        },
        addVehicleOperator: {
            visible: false
        },
    };

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => policyChangeVM,
        [policyChangeVM]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewEUVehicleOperatorPage.propTypes = wizardProps;
export default ViewEUVehicleOperatorPage;
