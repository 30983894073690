import { defineMessages } from 'react-intl';

export default defineMessages({
    roofScorePerilNameLabel: {
        id: 'quoteandbind.eh.views.roof-score.Peril Name',
        defaultMessage: 'Peril Name'
    },
    roofScorePerilValueLabel: {
        id: 'quoteandbind.eh.views.roof-score.Peril Value',
        defaultMessage: 'Peril Value'
    },
    roofScoreLabel: {
        id: 'quoteandbind.eh.views.roof-score.Roof Score Details',
        defaultMessage: 'Roof Score Details'
    },
    propertyInspectionLabel: {
        id: 'quoteandbind.eh.views.property.inspection.Property Inspection',
        defaultMessage: 'Property Inspection'
    }
});
