import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextPageMessage: {
        id: 'quoteandbind.ea.views.ea-additional-information.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    eaAccountHolderContactInfo: {
        id: 'quoteandbind.ea.views.ea-additional-information.Account Holder Contact Info',
        defaultMessage: 'Account Holder Contact Info'
    },
    eaCostNew: {
        id: 'quoteandbind.ea.views.ea-additional-information.Cost New',
        defaultMessage: 'Cost New'
    },
    eaUseOfVehicle: {
        id: 'quoteandbind.ea.views.ea-policy-info.Use of Vehicle',
        defaultMessage: 'Use of Vehicle'
    },
    eaCDistanceDriven: {
        id: 'quoteandbind.ea.views.ea-additional-information.Distance Driven',
        defaultMessage: 'Distance Driven'
    },
    accHolderPreferredEmailAddresses: {
        id: 'quoteandbind.ea.views.ea-additional-information.Preferred Email Addresses',
        defaultMessage: 'Email address the insured prefers to receive paperless and billing documents'
    },
    pageInfo: {
        id: 'quoteandbind.ea.views.ea-additional-information.Page Info',
        defaultMessage: 'We need you to provide the following missing or incomplete information before we can provide a verified quote.'
    },
    eaVinRequired: {
        id: 'quoteandbind.ea.views.ea-additional-information.Valid 17 digit VIN required for the following vehicle',
        defaultMessage: 'Valid 17 digit VIN required for the following vehicle'
    },
    vehicleInformation: {
        id: 'quoteandbind.ea.views.ea-additional-information.Vehicle Information',
        defaultMessage: 'Vehicle Information'
    },
    eaDriverLicenseInfoRequired: {
        id: 'quoteandbind.ea.views.ea-additional-information.Driver License Info Required',
        defaultMessage: 'Driver license number(s) and state required for the following driver(s)'
    },
    additionalDriverStatus: {
        id: 'quoteandbind.ea.views.ea-additional-information.Additional Driver Status',
        defaultMessage: 'Status of additional driver(s) found on the pre-fill report'
    },
    emailAddress: {
        id: 'quoteandbind.ea.views.ea-additional-information.Email Address',
        defaultMessage: 'Email Address'
    },
    nonOperatorStatus: {
        id: 'quoteandbind.ea.views.ea-additional-information.Non-Operator Status',
        defaultMessage: 'Non-Operator Status'
    },
    nonOperatorDescription: {
        id: 'quoteandbind.ea.views.ea-additional-information.Non-Operator Description',
        defaultMessage: 'Non-Operator Description'
    },
    provideOtherReason: {
        id: 'quoteandbind.ea.views.ea-additional-information.Provide Other Reason',
        defaultMessage: 'Provide Other Reason'
    },
    driverInHousehold: {
        id: 'AddedToQuote',
        defaultMessage: 'Licensed Driver in Household'
    },
    notInHousehold: {
        id: 'NotInHousehold',
        defaultMessage: 'Not In Household'
    },
    ratingPolicy: {
        id: 'quoteandbind.ea.views.ea-additional-information.Please wait while we rate your policy...',
        defaultMessage: 'Please wait while we rate your policy...'
    },
    transactionError: {
        id: 'quoteandbind.ea.views.ea-additional-information.Transaction Error',
        defaultMessage: 'Transaction Error'
    },
    paymentPlansErrorMessage: {
        id: 'quoteandbind.ea.views.ea-additional-information.Unable to retrieve payment plans',
        defaultMessage: 'Unable to retrieve payment plans'
    },
    ratingErrorMessage: {
        id: 'quoteandbind.ea.views.ea-additional-information.Unable to quote policy',
        defaultMessage: 'Unable to quote policy'
    },
    nonOperatorSuspRevokPleaseExplID: {
        id: 'quoteandbind.ea.views.ea-additional-information.NonOperator.Please Explain',
        defaultMessage: 'Please Explain'
    },
    membershipInformation:{
        id: 'quoteandbind.ea.views.ea-additional-information.Membership Information',
        defaultMessage: 'Membership Information'
    },
    membershipIdRequired:{
        id: 'quoteandbind.ea.views.ea-additional-information.Membership ID Required',
        defaultMessage: 'Membership ID Required'
    }
});
