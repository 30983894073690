import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './E1PSSNComponent.metadata.json5';

function E1PSSNComponent(props) {
    const {
        personVM,
        labelPosition,
        showOptional,
        path,
        id,
        onValidate,
        onValueChange,
        viewOnlyMode,
        showErrors,
        hideLabel
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const [editedSSNValue, setEditedSSNValue] = useState(_.get(personVM, 'ssn.value', ''));
    const [hasStartedChangingSSN, setHasStartedChangingSSN] = useState(false);
    const [mask, updateMask] = useState('***-**-9999')

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, personVM]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;

        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, path]);


    const handleSSNBlur = useCallback(async () => {
        // to display masked SSN we need this mask format
        updateMask('***-**-9999');
        setEditedSSNValue(_.get(personVM, 'ssn.value', ''));
        setHasStartedChangingSSN(false);
    }, [personVM]);

    const onSSNChange = useCallback(
        (value, changedFieldPath) => {
            // When we update mask Jutro calls onValueChange with null value so we need below condition
            if (value === null) {return;}

            let ssnValue = value;

            if (!hasStartedChangingSSN) {
                setHasStartedChangingSSN(true);
                // updating mask as we want to make sure only numbers can be entered for SSN
                updateMask('999-99-9999');
                ssnValue = '';
            }

            setEditedSSNValue(ssnValue);
            _.set(personVM, 'ssn.value', ssnValue);
            handleValueChange(ssnValue, changedFieldPath);
        }, [handleValueChange, hasStartedChangingSSN, personVM]
    );

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        ssnInputField: {
            onBlur: handleSSNBlur,
            showErrors: !isComponentValid || showErrors,
            onValueChange: onSSNChange,
            value: editedSSNValue,
            mask,
            alwaysShowMask: true,
            maskChar: _.isEmpty(_.trim(_.replace(editedSSNValue, '-', ''))) ? '_' : undefined,
            hideLabel
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={personVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
        />
    );
}

E1PSSNComponent.propTypes = {
    value: PropTypes.shape({}),
    personVM: PropTypes.shape({
        value: PropTypes.shape({}),

    }),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    showErrors: PropTypes.bool,
    hideLabel: PropTypes.bool
};

E1PSSNComponent.defaultProps = {
    value: {},
    personVM: {},
    labelPosition: 'top', // I want labels on top by default
    path: undefined,
    showOptional: false,
    id: undefined,
    viewOnlyMode: false,
    hideLabel: false,
    showErrors: false
};
export default E1PSSNComponent;
