import { defineMessages } from 'react-intl';

export default defineMessages({
    noCreditScoreText: {
        id: 'e1p.components.creditModalComponent.No Credit Score',
        defaultMessage: 'No Credit Score'
    },
    creditReportNotReturnedText: {
        id: 'e1p.components.creditModalComponent.A credit report was not returned. Please verify the information below.',
        defaultMessage: 'A credit report was not returned. Please verify the information below.'
    },
    socialSecurityNumber: {
        id: 'e1p.components.creditModalComponent.Social Security Number',
        defaultMessage: 'Social Security Number'
    },
    currentAddress: {
        id: 'e1p.components.creditModalComponent.Current Address',
        defaultMessage: 'Current Address'
    },
    provideMoreInfoAndTryAgainText: {
        id: 'e1p.components.creditModalComponent.Provide more information & try again, or use a "No Hit" credit score to continue.',
        defaultMessage: 'Provide more information & try again, or use a "No Hit" credit score to continue.'
    },
    provideInfoIsCorrectText: {
        id: 'e1p.components.creditModalComponent.If the provided information is correct, use a "No Hit" credit score to continue.',
        defaultMessage: 'If the provided information is correct, use a "No Hit" credit score to continue.'
    },
    useNoHitCreditScore: {
        id: 'e1p.components.creditModalComponent.USE NO HIT CREDIT SCORE',
        defaultMessage: 'USE NO HIT CREDIT SCORE'
    },
});
