
import _ from 'lodash';
import appConfig from 'app-config';

function getPathName(transaction, productCode) {
    const {
        lobQuoteURL,
        lobEndorsementURL,
        lobRewriteURL,
        lobRenewalURL
    } = appConfig;
    let pathName = '';
    switch (transaction) {
        case 'Submission':
            pathName = lobQuoteURL[productCode];
            break;
        case 'PolicyChange':
            pathName = lobEndorsementURL[productCode];
            break;
        case 'Rewrite':
            pathName = lobRewriteURL[productCode];
            break;
        case 'Renewal':
            pathName = lobRenewalURL[productCode];
            break;
        default:
            pathName = lobQuoteURL[productCode];
    }
    return pathName;
}

function goToTransactionSpecificViewOnlyFlow(history, jobNumber, policyType, transaction, productCode, policyNumber) {
    const pathName = getPathName(transaction, productCode);
    switch (transaction) {
        case 'Submission':
            return history.push({
                pathname: pathName,
                search: `?productCode=${productCode}&policyType=${policyType}&jobNumber=${jobNumber}`,
                viewOnly: true
            });
        case 'PolicyChange':
            return history.push(pathName, {
                jobNumber: jobNumber,
                policyNumber: policyNumber,
                viewOnly: true,
                policyType: policyType
            });
        case 'Rewrite':
            return history.push(pathName, {
                jobNumber: jobNumber,
                policyNumber: policyNumber,
                viewOnly: true,
                policyType: policyType
            });
        case 'Renewal':
            return history.push(pathName, {
                jobNumber: jobNumber,
                policyNumber: policyNumber,
                viewOnly: true,
                policyType: policyType
            });
        default:
            return false;
    }
};

/**
 * Helper function to jump to given index based on transaction and marking furthest visited page
 * @param {Object} remainingSteps 
 * @param {Object} currentStep 
 * @param {Boolean} isPageJumpEnabled 
 * @param {Function} updateIsPageJumpEnabled 
 * @param {Function} jumpTo 
 * @param {Function} getLandingPageIndexForViewOnlyPolicy 
 * @param {Function} markFurthestStep 
 * @param {String} LOB 
 * @param {String} jobType 
 */
const jumpToPageAndMarkFurthestVisitedStep = (
    remainingSteps, currentStep, isPageJumpEnabled,
    updateIsPageJumpEnabled, jumpTo,
    getLandingPageIndexForViewOnlyPolicy,
    markFurthestStep, LOB, jobType) => {

    remainingSteps.unshift(currentStep);
    // in view only flow we want to make each step as visited so that user can
    // navigate to any page by clicking on tabs on chevron
    if (isPageJumpEnabled) {
        updateIsPageJumpEnabled(false);
        jumpTo(getLandingPageIndexForViewOnlyPolicy(LOB, remainingSteps, jobType), true);
    }
    markFurthestStep((remainingSteps.length - 1));
}

export default {
    goToTransactionSpecificViewOnlyFlow,
    jumpToPageAndMarkFurthestVisitedStep
};
