/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { get as _get, set as _set, sortBy as _sortBy, isEmpty as _isEmpty } from 'lodash';
import { AccountService, UserService, PolicyDetailsService, SubmissionService } from 'e1p-capability-gateway';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { ServiceManager } from '@jutro/legacy/services';
import { useTranslator } from '@jutro/locale';
import { error as loggerError } from '@jutro/logger';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';

function useNewBusinessUtil(
    submissionVM,
    updateWizardData,
    viewModelService,
    lob,
    isAnExistingAccount,
    authHeader,
    setLocationAndProducerCodes
) {
    const localeService = ServiceManager.getService('locale-service');
    const translator = useTranslator();

    const createAccountHolderVM = useCallback(() => {
        if (!isAnExistingAccount) {
            const accountHolderObj = {
                lastName: '',
                firstName: '',
                primaryAddress: {
                    city: '',
                    state: '',
                    postalCode: '',
                    country: localeService.getDefaultCountryCode()
                }
            };
            const { _dtoName, _xCenter } = submissionVM.baseData.accountHolder;
            const accountHolderVM = viewModelService.create(accountHolderObj, _xCenter, _dtoName);

            _set(submissionVM, 'baseData.accountHolder.value', accountHolderVM.value);
            updateWizardData(submissionVM);
        }
    }, [isAnExistingAccount, localeService, submissionVM, updateWizardData, viewModelService]);

    /**
     * Returns format to be consumed in the pages
     * @param {Object} policyDetails response from getProducerDetails api method
     * @returns {Object}
     */
    const formatProducerDetails = (policyDetails) => {
        const producerCode = _get(policyDetails, 'producerCode');

        const address = _get(policyDetails, 'contactDetails.addresses', []).find(
            (add) => add.primaryAddressIndicator
        );
        const phone = _get(policyDetails, 'contactDetails.phones', []).find(
            (p) => p.phoneNumber
        ).phoneNumber;

        const serviceCenterIndicatorValue = _get(policyDetails, 'disposition', []).find(
            (disposition) => disposition.key === 'SERVICE_CENTER_INDICATOR'
        )?.value === 'Y';

        return {
            producerCode,
            address,
            phone,
            serviceCenterIndicatorValue,
            agencyName: policyDetails.name,
            bookTransferIndicator: policyDetails.bookRollIndicator
        };
    };

    /**
     * Gets agency name, location and etc.
     * @param {String} prodCode chosen producer code
     * @param {Array} producerCodes all producer codes for agent in state
     * @param {String} externalIdFromAcc externalId(in case of existing account)
     */
    const getProducerDetailsFromProducerCodes = async (prodCode, producerCodes, externalIdFromAcc = undefined) => {
        try {
            // E1PAP1PC-12445
            // As we are defaulting external id and producer code while creating submission for existing account
            // When user lands on insured page producerCodes is still empty
            // so to get producer details using externalId from account
            const externalId = producerCodes?.find((pd) => pd.code === prodCode)?.externalId_Ext
                || externalIdFromAcc;

            if (!externalId) return null;

            const policyDetails = await PolicyDetailsService.getProducerDetails(
                externalId,
                authHeader
            );

            return formatProducerDetails(policyDetails);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error.message);

            return {};
        }
    };

    /**
     * Gets agency name, location and etc. If external ID already set, we
     *   do not need to worry about finding it from producer code
     * @param {String} externalId
     */
    const getProducerDetailsFromExternalId = async (externalId) => {
        try {
            if (!externalId) return null;

            const policyDetails = await PolicyDetailsService.getProducerDetails(
                externalId,
                authHeader
            );

            return formatProducerDetails(policyDetails);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error.message);

            return {};
        }
    };

    const getLocationAndProducerCodes = useCallback(
        async (baseState) => {
            let producerCodes = [];
            let locationCodes = [];
            let producerCodeResponse = [];

            try {
                // clear exception before calling API
                _set(submissionVM, 'value.baseData.exceptions_Ext', []);
                updateWizardData(submissionVM);
                // get producer code based on state
                producerCodeResponse = await UserService.getAvailableProducerCodesBasedOnProducerIdAndState(
                    baseState,
                    authHeader
                );

                // no producer code received from api
                if(_isEmpty(producerCodeResponse)) {
                    _set(
                        submissionVM,
                        'baseData.exceptions_Ext',
                        [{ errorMessage: translator(e1pCommonMessages.genericErrorText) }]
                    );
                }
            } catch (error) {
                loggerError(error);
                _set(
                    submissionVM,
                    'baseData.exceptions_Ext',
                    [{ errorMessage: translator(e1pCommonMessages.genericErrorText) }]
                );
                updateWizardData(submissionVM);
            }

            producerCodes = _sortBy(producerCodeResponse, 'code').map((value) => {
                return {
                    code: value.code,
                    name: value.code,
                    displayName: value.displayValue,
                    externalId_Ext: value.externalId_Ext
                };
                });
            locationCodes = _sortBy(producerCodeResponse, 'code').map((value) => ({
                    code: value.externalId_Ext,
                    name: value.externalId_Ext,
                    displayName: value.displayValue,
                    externalId_Ext: value.externalId_Ext
                }));
            setLocationAndProducerCodes(
                {
                    producerCodes,
                    locationCodes
                }
            );

            return {
                producerCodes,
                locationCodes
            };
        },
        [authHeader, setLocationAndProducerCodes, submissionVM, translator, updateWizardData]
    );

    const searchAccounts = useCallback(async () => {
        const model = {
            contactType: 'person',
            country: 'US'
        };
        const accountSearchVM = viewModelService.create(
            model,
            'pc',
            'edge.capabilities.gateway.account.search.dto.AccountSearchCriteriaDTO'
        );

        _set(
            accountSearchVM,
            'firstName.value',
            submissionVM.baseData.accountHolder.firstName.value
        );
        _set(accountSearchVM, 'lastName.value', submissionVM.baseData.accountHolder.lastName.value);
        _set(
            accountSearchVM,
            'city.value',
            submissionVM.baseData.accountHolder.primaryAddress.city.value
        );
        _set(
            accountSearchVM,
            'state.value',
            submissionVM.baseData.accountHolder.primaryAddress.state.value.code
        );
        _set(
            accountSearchVM,
            'postalCode.value',
            submissionVM.baseData.accountHolder.primaryAddress.postalCode.value
        );

        const accounts = await AccountService.getPotentialExistingAccounts(
            accountSearchVM.value,
            authHeader
        );
        const userList = accounts.map((result) => {
            const user = {
                accountNumber: result.accountNumber,
                displayName: result.accountHolder.person.primaryAddress.displayName,
                contactName: result.accountHolder.person.displayName,
                canView: result.canUserView && result.canUserCreateSubmission
            };

            return user;
        });

        return userList;
    }, [authHeader, submissionVM, viewModelService]);

    const startSubmission = useCallback(async (testData = undefined) => {
        // passing in undefined if not provided, and PC will calculate the defaults
        let ratingDateString;
        let quoteCreationDateString;

        if (testData) {
            const { testRatingDate, testQuoteCreationDate } = testData;

            if (testData.testRatingDate) {
                // ratingDate must be a java string format; month for us starts at 0 so add 1
                const rDateMonth = testRatingDate.month > 8 ? testRatingDate.month + 1 : `0${testRatingDate.month + 1}`;
                const rDateDay = testRatingDate.day > 8 ? testRatingDate.day : `0${testRatingDate.day}`;

                ratingDateString = `${testRatingDate.year}-${rDateMonth}-${rDateDay}`;
            }

            if (testData.testQuoteCreationDate) {
                // quoteCreationDate must be a java string format; month for us starts at 0 so add 1
                const qcDateMonth = testQuoteCreationDate.month > 8 ? testQuoteCreationDate.month + 1 : `0${testQuoteCreationDate.month + 1}`;
                const qcDateDay = testQuoteCreationDate.day > 8 ? testQuoteCreationDate.day : `0${testQuoteCreationDate.day}`;

                quoteCreationDateString = `${testQuoteCreationDate.year}-${qcDateMonth}-${qcDateDay}`;
            }
        }

        const model = {
            country: submissionVM.baseData.policyAddress.country.value.code,
            accountNumber: submissionVM.baseData.accountNumber.value,
            productCode: submissionVM.baseData.productCode.value,
            state: submissionVM.baseData.policyAddress.state.value.code,
            effectiveDate: submissionVM.baseData.periodStartDate.value,
            policyType: submissionVM.lobData[lob].policyType.value.code,
            // use the override before submissionVM value
            experienceID_Ext: testData?.overriddenExperienceId || submissionVM.baseData.experienceID_Ext.value,
            producerCode: submissionVM.baseData.producerCode_Ext.value,
            ratingDate_Ext: ratingDateString,
            quoteCreationDate_Ext: quoteCreationDateString
        };
        const newSubmissionVM = viewModelService.create(
            model,
            'pc',
            'edge.capabilities.gateway.job.submission.dto.NewSubmissionDTO'
        );
        const SubmissionResponse = await SubmissionService.createSubmission(
            newSubmissionVM.value,
            authHeader
        );
        const newSubmissionResponse = SubmissionResponse;

        if (newSubmissionResponse.jobNumber > 0) {
            const SubmissionRetrievalResponse = await LoadSaveService.retrieveSubmission(
                { quoteID: newSubmissionResponse.jobNumber },
                authHeader
            );

            return SubmissionRetrievalResponse;
        }

        return undefined; // create acct/sub failed so don't go anywhere
    }, [authHeader, submissionVM, viewModelService, lob]);

    const createPrimaryNamedInsuredVM = useCallback(() => {
        const pniExist = _get(submissionVM, `lobData[${lob}].primaryNamedInsured.value`);

        if (!pniExist) {
            const primaryNamedInsuredObj = {
                person: {
                    lastName: '',
                    firstName: '',
                    primaryAddress: {
                        addressLine1: '',
                        addressLine2: '',
                        addressType: 'home',
                        city: '',
                        state: '',
                        postalCode: '',
                        country: localeService.getDefaultCountryCode(),
                    }
                },
                priorAddresses: []
            };
            const { _dtoName, _xCenter } = submissionVM.lobData[lob].primaryNamedInsured;
            const primaryNamedInsuredVM = viewModelService.create(
                primaryNamedInsuredObj,
                _xCenter,
                _dtoName
            );

            _set(
                submissionVM,
                `lobData[${lob}].primaryNamedInsured.value`,
                primaryNamedInsuredVM.value
            );
            updateWizardData(submissionVM);
        }
    }, [lob, submissionVM, updateWizardData, viewModelService, localeService]);

    return {
        createAccountHolderVM,
        getLocationAndProducerCodes,
        searchAccounts,
        startSubmission,
        createPrimaryNamedInsuredVM,
        getProducerDetailsFromProducerCodes,
        getProducerDetailsFromExternalId
    };
}

export default useNewBusinessUtil;
