/* eslint-disable react/jsx-no-constructed-context-values */
import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';

export const DashboardStateContext = React.createContext({
    accountsDashboardState: undefined,
    setAccountsDashboardState: () => {},
    policiesDashboardState: undefined,
    setPoliciesDashboardState: () => {}
});

/**
 * IAP-3238 and IAP-3363, 
 * This component is used to save the filter states for Accounts and Policies Dashboard.
 * so that once user comes back to the dashboard, they should see the last selected filters.
 */
class StateContextProvider extends Component {
    static propTypes = {
        children: PropTypes.shape({}).isRequired
    };

    state = {
        accountsDashboardState: undefined,
        setAccountsDashboardState: (dashboardState) => {
            this.setState({ accountsDashboardState: dashboardState });
        },
        policiesDashboardState: undefined,
        setPoliciesDashboardState: (dashboardState) => {
            this.setState({ policiesDashboardState: dashboardState });
        }
    };

    render() {
        const { children } = this.props;
        const {
            accountsDashboardState,
            setAccountsDashboardState,
            policiesDashboardState,
            setPoliciesDashboardState
        } = this.state;

        return (
            <DashboardStateContext.Provider
                value={{
                    accountsDashboardState,
                    setAccountsDashboardState,
                    policiesDashboardState,
                    setPoliciesDashboardState
                }}
            >
                {children}
            </DashboardStateContext.Provider>
        );
    }
}

// used to wrap around class components
export function withDashboardStateContext(WrappedComponent) {
    function WithDashBoardContext(props) {
        const dashboardProps = useContext(DashboardStateContext);

        return <WrappedComponent {...dashboardProps} {...props} />;
    }

    hoistNonReactStatic(WithDashBoardContext, WrappedComponent);

    return WithDashBoardContext;
}
// used for functional components
export function useDashboardStateContext () {
    return useContext(DashboardStateContext);
}
export const DashboardStateContextProvider = StateContextProvider; 
export default DashboardStateContext.Consumer;
