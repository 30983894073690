import React, { useMemo, useContext } from 'react';
import _ from 'lodash';
import { wizardProps, WizardPageHeader } from 'e1p-portals-wizard-react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { policyChangeMessages, commonMessages } from 'e1p-platform-translations';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import styles from './ThankYouPage.module.scss';
import metadata from './ThankYouPage.metadata.json5';

const LOB = 'personalAuto_EA';

function ThankYouPage(props) {
    const { wizardData: policyChangeVM } = props;

    const viewModelService = useContext(ViewModelServiceContext);

    const policyNumber = useMemo(() => _.get(policyChangeVM, 'policyNumber.value'), [policyChangeVM]);

    const onPrintHandler = () => {
        window.print();
    };

    const translator = useTranslator();
    const { opCo } = useContext(AmfamOktaTokenContext);

    const isFutureTermErrorMessageVisible = (
        _.get(policyChangeVM, 'value.hasFutureUnBoundRenewal_Ext', false)
        || _.get(policyChangeVM, 'value.hasFutureBoundRenewal_Ext', false)
    ) && !_.get(policyChangeVM, 'changesAppliedForward');

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        exitToDashBoardButtonId: {
            to: `/policies/${policyNumber}/summary`,
        },
        messagesContainer: {
            submissionVM: policyChangeVM,
            viewModelService,
            LOB
        },
        policyDocumentContainer: {
            submissionVM: policyChangeVM
        },
        addANewLineComponent: {
            submissionVM: policyChangeVM
        },
        requiredDocumentContainer: {
            submissionVM: policyChangeVM,
            viewModelService
        },
        wizardHeaderContainer: {
            wizardSubmission: policyChangeVM,
            isSideHeader: false,
            shouldLink: false,
            isPolicyView: false
        },
        futureTermErrorMessageDiv: {
            visible: isFutureTermErrorMessageVisible
        },
        futureTermErrorMessage: {
            message: policyChangeMessages.futureTermErrorMessage
        },
        thankyouMessageLabelId: {
            content: opCo === 'MSA' ? translator(commonMessages.thankYouMessage, { partner: translator(commonMessages.msa) })
                : translator(commonMessages.thankYouMessage, { partner: translator(commonMessages.connect) })
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            wizardpageheader: WizardPageHeader
        },
        resolveCallbackMap: {
            onPrintHandler
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyChangeVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

ThankYouPage.propTypes = wizardProps;
export default ThankYouPage; 
