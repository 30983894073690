import React, {
    useContext,
    useCallback,
    useState
} from 'react';
import { get } from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from '../VehiclesPage.metadata.json5';

function VehiclesPage(props) {
    const { wizardData: submissionVM } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [availableMakes, setAvailableMakes] = useState({});
    const [availableModels, setAvailableModels] = useState({});
    const [availableSeries, setAvailableSeries] = useState({});
    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        () => submissionVM,
        [submissionVM]
    );

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: breakpoint === 'top',
        },
        vehiclesPageLoadingIndicator: {
            loaded: true,
        },
        vehiclesPageContainer: {
            visible: true
        },
        eaAddAnotherVehicleButtonContainer: {
            visible: false
        },
        vehicleInformationSubHeaderID: {
            visible: false
        },
        vehicleLookupErrorNotificationDiv: {
            visible: false
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('eavehiclecontainerID')
        },
        oneCarMinimumNotificationDiv: {
            visible: false
        },
        fieldsClearedInfoMessage: {
            visible: false
        },
        yearVinInvalidInfoMessage: {
            visible: false
        },
        seventeenDigitsVinRequiredErrorMessage: {
            visible: false
        },
        vehicleInfoChangedMessageDiv: {
            visible: false
        },
        EAVehicleGrid: {
            vehicles: get(submissionVM, 'lobData.personalAuto_EA.coverables.vehicles', []),
            path: 'lobData.personalAuto_EA.coverables.vehicles.children',
            onValidate: () => {},
            viewModelService,
            showErrors: false,
            policyState: get(submissionVM, 'baseData.policyAddress.state.value.code'),
            vehiclePageDisregardFieldValidation: () => {},
            authHeader,
            onValidationChange: () => {},
            transactionVM: submissionVM,
            updateWizardData: () => {},
            setCheckScrolling,
            addVehicleVM: () => {},
            viewOnlyMode: true,
            availableMakes,
            setAvailableMakes,
            availableModels,
            setAvailableModels,
            availableSeries,
            setAvailableSeries
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps),
        [submissionVM, overrideProps]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

VehiclesPage.propTypes = wizardProps;
export default VehiclesPage;
