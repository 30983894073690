import _ from 'lodash';

function Vehicle(data) {
    if (data) {
        _.extend(this, data);
    }
    this.costNew = this.costNew || {};
}

Vehicle.prototype.getDisplayName = function (defaultName) {
    let name = this.make || '';
    if (this.model) {
        if (name) {
            name += ' ';
        }
        name += this.model;
    }
    if (this.year) {
        if (name) {
            name += ' ';
        }
        name += this.year;
    }
    if (name.length < 1) {
        name = defaultName;
    } else if (this.license) {
        name += ' (';
        name += this.license;
        if (this.licenseState) {
            name += '/';
            name += this.licenseState;
        }
        name += ')';
    }
    return name;
};

Vehicle.prototype.getVehicleStatesForCountry = (states, countryCode) => {
    return _.filter(states, (state) => {
        const countryConstraint = _.find(state.categories, (constraint) => {
            return _.keys(constraint).indexOf('Country') !== -1;
        });
        if (countryConstraint) {
            return countryConstraint.Country === countryCode;
        }
        return false;
    });
};

export default Vehicle;
