import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import InteractiveService from 'e1p-capability-gateway/services/InteractiveService';
import metadata from './InteractiveComponent.metadata.json5';
import messages from './InteractiveComponent.message';

const productCodeMapper = {
    Homeowners_EH: 'EHLine_Ext',
    PersonalAuto_EA: 'EAAutoLine_Ext',
    PersonalUmbrella_EU: 'EULine_Ext'
};

const InteractiveComponent = (props) => {
    const { policyDetailsData: { policyResponse }, fromAccountLanding: { policyNumber } } = props;
    const product = get(policyResponse, 'product.productCode');
    const locationState = get(policyResponse, 'account.accountHolder.primaryAddress.state');
    const { authHeader } = useAuthentication();
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [interactiveLetters, setInteractiveLetters] = useState([]);

    const createTemplate = useCallback((data) => {
        setIsLoading(true);
        InteractiveService
            .initiateNonTransactionalInteractiveLetter(
                data, policyNumber,
                authHeader
            ).then((interactiveLetterUrl) => {
                window.open(interactiveLetterUrl);
            }).catch(() => {
                setErrorMessage(messages.noURLFound);
                setHasError(true);
            }).finally(() => {
                setIsLoading(false);
            });
    }, [authHeader, policyNumber]);

    const getInteractiveLetters = () => {
        setIsLoading(true);

        const productCode = productCodeMapper[product];
        const interactiveSearchCriteriaDTO = {
            productCode,
            baseState: locationState
        };

        InteractiveService.getNonTransactionalLetterTemplates(
            interactiveSearchCriteriaDTO,
            authHeader
        ).then((documentTemplateDescriptorDtoArray) => {
            if (isEmpty(documentTemplateDescriptorDtoArray)) {
                setErrorMessage(messages.noInteractiveTemplateFound);
                setHasError(true);
            }

            setInteractiveLetters(documentTemplateDescriptorDtoArray);
        }).catch(() => {
            setErrorMessage(messages.noInteractiveTemplateFound);
            setHasError(true);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        getInteractiveLetters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const overrideProps = {
        InteractiveTableContainer: {
            policyNumber,
            interactiveLetters,
            createTemplate,
            visible: !isLoading
        },
        interactivePageLoadingIndicator: {
            loaded: !isLoading
        },
        errorNotification: {
            message: errorMessage
        },
        errorNotificationDiv: {
            visible: hasError
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
        />
    );
};

InteractiveComponent.propTypes = {
    fromAccountLanding: PropTypes.shape({
        policyNumber: PropTypes.string
    }).isRequired,
    policyDetailsData: PropTypes.shape({
        policyResponse: PropTypes.shape({})
    }).isRequired
};

export default InteractiveComponent;
