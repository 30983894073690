import React from 'react';
import {
    get as _get
} from 'lodash';
import PropTypes from 'prop-types';

import { useTranslator } from '@jutro/locale';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import messages from './EHPropertyInspectionComponent.messages';
import styles from './EHPropertyInspectionComponent.module.scss';

function EHPropertyInspectionComponent(props) {
    const { transactionVM } = props;
    const translator = useTranslator();

    const inspectionType = _get(
        transactionVM,
        'lobData.homeowners_EH.coverables.yourHome.inspectionDetails.inspectionType.value.name',
        e1pCommonMessages.missingDataDash.defaultMessage
    );
    const priorityScore = _get(
        transactionVM,
        'lobData.homeowners_EH.coverables.yourHome.inspectionDetails.priorityScore.value',
        e1pCommonMessages.missingDataDash.defaultMessage
    );
    const finalInspectionScore = _get(
        transactionVM,
        'lobData.homeowners_EH.coverables.yourHome.inspectionDetails.finalInspectionScore.value',
        e1pCommonMessages.missingDataDash.defaultMessage
    );

    return (
        <div>
            <p className={styles.labelItem}>
                {translator(messages.inspectionType)}
            </p>
            <p className={styles.dataItem}>
                {translator({
                    id: inspectionType
                })}
            </p>
            <p className={styles.labelItem}>
                {translator(messages.priorityScore)}
            </p>
            <p className={styles.dataItem}>
                {priorityScore}
            </p>
            <p className={styles.labelItem}>
                {translator(messages.finalInspectionScore)}
            </p>
            <p className={styles.dataItem}>
                {finalInspectionScore}
            </p>
        </div>
    );
}

EHPropertyInspectionComponent.propTypes = {
    transactionVM: PropTypes.shape({
        lobData: PropTypes.shape({
            personalAuto_EA: PropTypes.shape({
                primaryNamedInsured: PropTypes.shape({
                    person: PropTypes.shape({
                        publicID: PropTypes.shape({
                            value: PropTypes.shape({})
                        })
                    })
                }),
                secondaryNamedInsured: PropTypes.shape({
                    person: PropTypes.shape({
                        publicID: PropTypes.shape({
                            value: PropTypes.shape({})
                        })
                    })
                }),
                coverables: PropTypes.shape({
                    vehicles: PropTypes.shape({
                        children: PropTypes.shape({
                            forEach: PropTypes.func.isRequired
                        })
                    })
                })
            })
        }),
        baseData: PropTypes.shape({
            policyAddress: PropTypes.shape({
                state: PropTypes.shape({
                    value: PropTypes.shape({
                        code: PropTypes.string
                    })
                })
            })
        }),
        value: PropTypes.shape({})
    }),
};

EHPropertyInspectionComponent.defaultProps = {
    transactionVM: {}
};
export default EHPropertyInspectionComponent;
