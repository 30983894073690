import { defineMessages } from 'react-intl';

export default defineMessages({
    PrefillYear: {
        id: 'quoteandbind.ea.views.ea-vehicle-prefill-details.Year',
        defaultMessage: 'Year'
    },
    PrefillModel: {
        id: 'quoteandbind.ea.views.ea-vehicle-prefill-details.Model',
        defaultMessage: 'Model'
    },
    PrefillMake: {
        id: 'quoteandbind.ea.views.ea-vehicle-prefill-details.Make',
        defaultMessage: 'Make'
    },
    PrefillDescription: {
        id: 'quoteandbind.ea.views.ea-underwriting-details.Select vehicle(s) to add to quote from prefill report.',
        defaultMessage: "Select vehicle(s) to add to quote from prefill report."
    },
    VehiclePrefillContinue: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Continue',
        defaultMessage: 'Continue'
    },
    vehiclePrefillTitle: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Household Vehicles',
        defaultMessage: 'Household Vehicles'
    },
    prefillVehicle: {
        id: 'quoteandbind.ea.views.ea-vehicle-prefill-details.Vehicle',
        defaultMessage: 'Vehicle'
    },
    addToQuote: {
        id: 'quoteandbind.ea.views.ea-vehicle-prefill-details.Add to Quote?',
        defaultMessage: 'Add to Quote?'
    },
    PrefillVehicleError: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.error text',
        defaultMessage: 'Vehicle pre-fill report was returned without any vehicle information. Add vehicles as necessary'
    }
});
