import { defineMessages } from 'react-intl';

export default defineMessages({
    lossType: {
        id: 'components.manualPropertyLoss.Choose Loss Type',
        defaultMessage: 'Choose Loss Type'
    },
    lossDecription: {
        id: 'components.manualPropertyLoss.Description',
        defaultMessage: 'Description'
    },
    sourceType: {
        id: 'components.manualPropertyLoss.Source Type',
        defaultMessage: 'Source Type'
    }
});