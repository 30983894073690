import React, { useCallback, useState, useEffect, useRef } from 'react';
import { get, set, isEmpty } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { EndorsementService } from 'e1p-capability-policychange';
import { useLandingPageUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from './EUPreQualificationPage.metadata.json5';

const LOB = 'personalUmbrella_EU';

function EUPreQualificationPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { initialValidation, isComponentValid, onValidate } = useValidation(
        'EUPreQualificationPage'
    );
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const isMounted = useRef(false);

    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);


    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setIsSavingEndorsement(true);
        policyChangeVM.value = await EndorsementService.saveEndorsement(
            [policyChangeVM.value],
            authHeader
        );
        setIsSavingEndorsement(false);

        return policyChangeVM;
    }, [authHeader, isComponentValid, policyChangeVM]);

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, policyChangeVM, updateWizardSnapshot]
    );

    const saveAndQuote = useCallback(
        async () => {
            const saveAndQuoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', saveAndQuoteResponse);
            updateWizardData(policyChangeVM);

            return policyChangeVM.value;
        },
        [authHeader, policyChangeVM, updateWizardData]
    );

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);
            policyChangeVM.value = await saveAndQuote();
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //   If underwriting issues, we will go to change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            // Update the component's state only if it is currently mounted.
            if (isMounted.current) {
                setIsSavingEndorsement(false);
            }

            return false;
        },
        [
            getLandingPageIndexForQuotedJob,
            isComponentValid,
            jumpTo,
            policyChangeVM,
            saveAndQuote,
            steps,
            updateWizardData,
            updateWizardSnapshot
        ]
    );

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        preQualificationPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(e1pCommonMessages.loadingNextPage)
        },
        preQualificationPageContainer: {
            visible: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges
        },
        euPreQualificationComponent: {
            data: policyChangeVM,
            updateWizardData,
            showErrors: isPageSubmitted
        }
    };

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            showCustom
            onCustom={onCustom}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

EUPreQualificationPage.propTypes = wizardProps;
export default withRouter(EUPreQualificationPage);
