import React, {
    useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    set as _set, pullAt as _pullAt, isEmpty as _isEmpty, get as _get
} from 'lodash';
import { useModal, TooltipIcon } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './AutoLossesAndViolationsComponent.metadata.json5';
import messages from './AutoLossesAndViolationsComponent.messages';

function AutoLossesAndViolations(props) {
    const modalApi = useModal();
    const {
        lobDataModel,
        onModelChange,
        onValidate,
        labelPosition,
        visible,
        operators,
        disregardFieldValidation,
        viewModelService,
        viewOnlyMode,
        id,
        policyState,
        showErrors,
        periodStartDate,
        onChangePrayerForJudgement,
        showPrayerForJudgementError,
        authUserData,
        isVerified,
        updateIsPageSubmitted
    } = props;

    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const translator = useTranslator();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [lobDataModel, onValidate, isComponentValid, id]);

    useEffect(() => {
        lobDataModel.manualAutoLossRecords.value ??= [];
        lobDataModel.manualViolationRecords.value ??= [];
        lobDataModel.hasManualAutoLosses.value ??= false;
        lobDataModel.hasManualViolations.value ??= false;
        onModelChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (newVal, path) => {
            _set(lobDataModel, `${path}.value`, newVal);
            onModelChange();
        },
        [lobDataModel, onModelChange]
    );

    const addLoss = useCallback(() => {
        const lossObj = {
            assignment: {
                firstName: undefined,
                lastName: undefined,
                displayName: undefined,
                publicId: undefined,
            },
            operator: undefined,
            lossType: undefined,
            lossAmount: 0.00,
            source: {
                sourceType: 'Self-Declared'
            }
        };
        const {
            _dtoName,
            _xCenter,
        } = lobDataModel.manualAutoLossRecords;
        const lossesVM = viewModelService.create(lossObj, _xCenter, _dtoName);

        lobDataModel.manualAutoLossRecords.pushElement(lossesVM);
        updateIsPageSubmitted(false);
        onModelChange();
    }, [lobDataModel.manualAutoLossRecords, onModelChange, updateIsPageSubmitted, viewModelService]);

    const addViolation = useCallback(() => {
        const violationObj = {
            assignment: {
                integrationId: undefined,
                publicId: undefined,
                assignmentType: undefined,
                firstName: undefined,
                lastName: undefined
            },
            source: {
                sourceType: 'Self-Declared'
            },
            violationType: undefined
        };
        const {
            _dtoName: dto,
            _xCenter: xcenter,
        } = lobDataModel.manualViolationRecords;
        const violationsVM = viewModelService.create(violationObj, xcenter, dto);

        lobDataModel.manualViolationRecords.pushElement(violationsVM);
        onModelChange();
        updateIsPageSubmitted(false);
    }, [lobDataModel.manualViolationRecords, onModelChange, updateIsPageSubmitted, viewModelService]);


    const removeLoss = useCallback(
        (evt) => {
            const pathArray = evt.path.split('.');
            const pathIdentifier = pathArray.slice(-1);
            const [, lossIndex] = /\[(\d+)\][^[]*$/.exec(pathIdentifier) || [];

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.removeLossTitle,
                message: messages.removeLossDescription,
                confirmButtonText: translator(commonMessages.removeItemButtonText, { itemToRemove: 'LOSS' }),
                cancelButtonText: commonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const lossLastIndex = lobDataModel.manualAutoLossRecords.value.length - 1;

                    disregardFieldValidation(`manualLosses${lossLastIndex}`);
                    disregardFieldValidation(`lossIncidentDate${lossLastIndex}`);
                    _pullAt(lobDataModel.manualAutoLossRecords.value, lossIndex);

                    if (_isEmpty(lobDataModel.manualAutoLossRecords.value)) {
                        lobDataModel.hasManualAutoLosses.value = false;
                    }

                    onModelChange();
                }
            }, () => { });
        },
        [disregardFieldValidation, lobDataModel.hasManualAutoLosses,
            lobDataModel.manualAutoLossRecords.value, onModelChange, translator, modalApi]
    );

    const removeViolation = useCallback(
        (evt) => {
            const pathArray = evt.path.split('.');
            const pathIdentifier = pathArray.slice(-1);
            const [, violationIndex] = /\[(\d+)\][^[]*$/.exec(pathIdentifier) || [];

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.removeViolationTitle,
                message: messages.removeViolationDescription,
                confirmButtonText: translator(commonMessages.removeItemButtonText, { itemToRemove: 'VIOLATION' }),
                cancelButtonText: commonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const violationLastIndex = lobDataModel.manualViolationRecords.value.length - 1;

                    disregardFieldValidation(`manualViolations${violationLastIndex}`);
                    disregardFieldValidation(`violationIncidentDate${violationLastIndex}`);
                    _pullAt(lobDataModel.manualViolationRecords.value, violationIndex);

                    if (_isEmpty(lobDataModel.manualViolationRecords.value)) {
                        lobDataModel.hasManualViolations.value = false;
                    }

                    onModelChange();
                }
            }, () => { });
        },
        [disregardFieldValidation, lobDataModel.hasManualViolations,
            lobDataModel.manualViolationRecords.value, onModelChange, translator, modalApi]
    );

    const onToggleLossReportInd = useCallback((newVal, path) => {
        if (!newVal && !_isEmpty(lobDataModel.manualAutoLossRecords.value)) {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.removeAllLossesTitle,
                message: messages.removeAllLossesDescription,
                confirmButtonText: translator(commonMessages.removeItemButtonText, { itemToRemove: 'LOSSES' }),
                cancelButtonText: commonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    lobDataModel.manualAutoLossRecords.value.forEach((_, index) => {
                        disregardFieldValidation(`manualLosses${index}`);
                        disregardFieldValidation(`lossIncidentDate${index}`);
                    });
                    writeValue([], 'manualAutoLossRecords');
                    writeValue(newVal, path);
                }
            }, () => { });
        } else {
            if (newVal) {
                addLoss();// Auto add a loss shell when user confirms loss existence.
            }

            writeValue(newVal, path);
        }
    }, [addLoss, disregardFieldValidation, lobDataModel.manualAutoLossRecords.value, writeValue, translator, modalApi]);

    const onToggleViolationInd = useCallback((newVal, path) => {
        if (!newVal && !_isEmpty(lobDataModel.manualViolationRecords.value)) {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.removeAllViolationsTitle,
                message: messages.removeAllViolationsDescription,
                confirmButtonText: translator(commonMessages.removeItemButtonText, { itemToRemove: 'VIOLATIONS' }),
                cancelButtonText: commonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    lobDataModel.manualViolationRecords.value.forEach((_, index) => {
                        disregardFieldValidation(`manualViolations${index}`);
                        disregardFieldValidation(`violationIncidentDate${index}`);
                    });
                    lobDataModel.manualViolationRecords.value = [];
                    writeValue(newVal, path);
                }
            }, () => { writeValue(true, path); });
        } else {
            if (newVal) {
                addViolation();// Auto add a violation shell.
            }

            writeValue(newVal, path);
        }
    }, [addViolation, disregardFieldValidation, lobDataModel.manualViolationRecords, writeValue, translator, modalApi]);

    const generateOverrides = useCallback(() => {
        const overrideProps = {};
        const manualLosses = lobDataModel.manualAutoLossRecords.value ?? [];

        manualLosses.forEach((_, index) => {
            overrideProps[`manualLosses${index}`] = {
                operators,
                viewOnlyMode: viewOnlyMode || (isVerified && !authUserData.permissions_Ext.includes('editoverrides')),
                showErrors
            };
            overrideProps[`deleteLoss${index}`] = {
                // has to be quick quote or user has to have perm
                visible: _get(manualLosses, 'length') > 1 && !viewOnlyMode && (!isVerified || authUserData.permissions_Ext.includes('editoverrides'))
            };
        });

        const manualViolations = lobDataModel.manualViolationRecords.value ?? [];

        manualViolations.forEach((_, index) => {
            overrideProps[`manualViolations${index}`] = {
                operators,
                viewOnlyMode: viewOnlyMode || (isVerified && !authUserData.permissions_Ext.includes('editoverrides')),
                showErrors,
                periodStartDate,
                policyState,
                onChangePrayerForJudgement
            };
            overrideProps[`deleteViolation${index}`] = {
                // has to be quick quote or user has to have perm
                visible: _get(manualViolations, 'length') > 1 && !viewOnlyMode && (!isVerified || authUserData.permissions_Ext.includes('editoverrides'))
            };
            overrideProps[`violationContainer${index}`] = {
                columns: policyState === 'NC' ? ['7fr', '1fr'] : ['5.5fr', '1fr']
            }
        });

        return overrideProps;
    }, [lobDataModel.manualAutoLossRecords.value, lobDataModel.manualViolationRecords.value, operators, viewOnlyMode, isVerified, authUserData.permissions_Ext, showErrors, periodStartDate, policyState, onChangePrayerForJudgement]);

    const overrideProps = {
        '@field': {
            visible,
            labelPosition,
            showRequired: true,
            readOnly: viewOnlyMode || (isVerified && !authUserData.permissions_Ext.includes('editoverrides')),
            autoComplete: false
        },
        // this is pretty much the whole content
        autoLossesAndViolationsContainer: {
            // Quick quote always shows. Full quote will show if user has edit/view permission
            visible: !isVerified
                || (isVerified
                    && (authUserData.permissions_Ext.includes('editoverrides') || authUserData.permissions_Ext.includes('viewoverrides'))
                )
        },
        lossReportContainer: {
            visible: lobDataModel.hasManualAutoLosses.value ?? false
        },
        violationReportContainer: {
            visible: lobDataModel.hasManualViolations.value ?? false
        },
        addLoss: {
            // anyone can add in quick; permission for verified
            visible: !viewOnlyMode
                && (!isVerified || authUserData.permissions_Ext.includes('editoverrides'))
        },
        addViolation: {
            // anyone can add in quick; permission for verified
            visible: !viewOnlyMode
                && (!isVerified || authUserData.permissions_Ext.includes('editoverrides'))
        },
        prayerForJudgementLabel: {
            visible: policyState === 'NC'
        },
        prayerForJudgementErrorMessageDiv: {
            visible: policyState === 'NC' && !viewOnlyMode && showPrayerForJudgementError
        },
        violationLabelContainer: {
            columns: policyState === 'NC' ? ['1.5fr', '1.5fr', '1.5fr', '1.5fr', '1fr', '1fr'] : ['1.5fr', '1.5fr', '1.5fr', '1fr', '1fr']
        },
        ...generateOverrides(),
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddLossClick: addLoss,
            onRemoveLoss: removeLoss,
            onAddViolationClick: addViolation,
            onRemoveViolation: removeViolation,
            onValidate,
            onToggleLossReportInd,
            onToggleViolationInd
        },
        resolveComponentMap: {
            TooltipIcon
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={writeValue}
            model={lobDataModel}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

AutoLossesAndViolations.propTypes = {
    lobDataModel: PropTypes.shape({
        manualAutoLossRecords: PropTypes.shape({
            value: PropTypes.arrayOf(PropTypes.shape({})),
            pushElement: PropTypes.func,
            children: PropTypes.arrayOf(PropTypes.shape({}))
        }),
        manualViolationRecords: PropTypes.shape({
            value: PropTypes.arrayOf(PropTypes.shape({})),
            pushElement: PropTypes.func,
            children: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        hasManualAutoLosses: PropTypes.shape({
            value: PropTypes.bool
        }),
        hasManualViolations: PropTypes.shape({
            value: PropTypes.bool
        }),
    }).isRequired,
    onModelChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    labelPosition: PropTypes.string,
    disregardFieldValidation: PropTypes.func.isRequired,
    operators: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    viewModelService: PropTypes.shape({ create: PropTypes.func.isRequired }).isRequired,
    visible: PropTypes.bool,
    viewOnlyMode: PropTypes.bool,
    id: PropTypes.string.isRequired,
    showErrors: PropTypes.bool,
    policyState: PropTypes.string,
    periodStartDate: PropTypes.shape({}),
    onChangePrayerForJudgement: PropTypes.func,
    showPrayerForJudgementError: PropTypes.bool,
    authUserData: PropTypes.shape({}).isRequired,
    isVerified: PropTypes.bool.isRequired,
    updateIsPageSubmitted: PropTypes.func
};

AutoLossesAndViolations.defaultProps = {
    labelPosition: 'top',
    visible: true,
    viewOnlyMode: false,
    showErrors: false,
    periodStartDate: undefined,
    showPrayerForJudgementError: false,
    updateIsPageSubmitted: () => { }
};

export default AutoLossesAndViolations;
