import React, { useEffect, useCallback, useState, useMemo } from 'react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, withModalContext } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import PropTypes from 'prop-types';
import { EHScheduleItemsComponent } from 'e1p-capability-policyjob-react';
import { useTranslator } from '@jutro/locale';
import SingleClauseComponentForOptionalCovVM from '../EHClauses/SingleClauseComponentForOptionalCovVM';
import styles from './OptionalCoverages.module.scss';
import messages from './OptionalCoverages.messages';
import metadata from './OptionalCoverages.metadata.json5';

import { Button } from '@jutro/legacy/components';

const isGivenPackageCovSelected = (transactionVM, currentOfferingIndex, packagecovId) => (_.get(transactionVM,
        `value.lobData.homeowners_EH.offerings[${currentOfferingIndex}].coverages.coverages`,
        [])
        .some((coverage) => coverage.codeIdentifier === packagecovId && coverage.selected))

function OptionalCoverages(props) {
    const {
        title,
        viewOnlyMode,
        isOpen,
        onResolve,
        policyType,
        hasGoldOrPlatinumBoxVisible,
        changeSubmissionAndSync,
        transactionVM,
        currentOfferingIndex,
        authUserData,
        underwritingIssues,
        BLOCKS_QUOTES,
        changeSubmission,
        syncCoverages,
        onScheduleChange,
        currentOfferingBranchCode

    } = props;

    const translator = useTranslator();

    const { onValidate, isComponentValid, registerComponentValidation } = useValidation(
        'OptionalCoverages'
    );
    const [goldCheckBoxValue, updateGoldCheckBoxValue] = useState(
        isGivenPackageCovSelected(transactionVM, currentOfferingIndex, 'EH_GoldCoverageBundle')
    );
    const [platinumCheckBoxValue, updatePlatinumCheckBoxValue] = useState(
        isGivenPackageCovSelected(transactionVM, currentOfferingIndex, 'EH_PlatinumCoverageBundle')
    );
    const [isWhatsIncludedClosed, setIsWhatsIncludedClosed] = useState(false);
    const [isWhatsIncludedInLine, setIsWhatsIncludedInLine] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [clickedOnWhatsIncludedLinkValue, setClickedUpdateOnWhatsIncludedLinkValue] = useState(false);
    const [schedulesMap, setSchedulesMap] = useState([]);
    const [columnData, setColumnData] = useState(undefined);
    const [tableData, setTableData] = useState(undefined);
    const [tableDataExclusion, setTableDataExclusion] = useState(undefined);
    const [tableDataCondition, setTableDataCondition] = useState(undefined);
    const [coverageChangedToggle, setCoverageChangedToggle] = useState(false);
    const [isClauseLoading, setIsClauseLoading] = useState(false);


    const getSelectedOffering = () => {
        const lobOfferingPath = 'lobData.homeowners_EH.offerings';
        const quoteOfferingPath = 'quoteData.offeredQuotes';
        const lobOfferings = _.get(transactionVM, `${lobOfferingPath}.value`);
        const quoteOfferings = _.get(transactionVM, `${quoteOfferingPath}.value`, []);
        let selectedVersion = quoteOfferings.find((version) => version.branchCode === currentOfferingBranchCode);

        // Policy view flow will not have quote offerrings
        if (quoteOfferings && !selectedVersion) {
            selectedVersion = quoteOfferings[currentOfferingIndex];
        }

        // policy view offerings will not have quote offerings, it wi have only one offering
        let lobIndex = lobOfferings.findIndex((offering) => offering.branchCode === selectedVersion?.branchCode);

        if (!lobIndex) {
            lobIndex = 0;
        }

        return {
            lobOfferingPath,
            quoteOfferingPath,
            lobOfferings,
            quoteOfferings,
            selectedVersion,
            lobIndex
        };
    };

    const generateColumnData = () => {
        const selectedOffering = getSelectedOffering();

        let tempColumnData = selectedOffering.lobOfferings.map((lobOffering, lobIndex) => {
            const quoteDataIndex = selectedOffering.quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = selectedOffering.quoteOfferings[quoteDataIndex];

            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${selectedOffering.quoteOfferingPath}.children.${quoteDataIndex}`,
                    data: quoteData
                },
                lob: {
                    path: `${selectedOffering.lobOfferingPath}.children.${lobIndex}`,
                    data: lobOffering
                },
                submissionVM: transactionVM
            };
        });

        tempColumnData = tempColumnData.length > 1
            ? [tempColumnData.find((offering) => offering.code === selectedOffering.selectedVersion?.branchCode)]
            : tempColumnData;
        setColumnData([..._.sortBy(tempColumnData, ['code'])]);
    };

    const getExclusionsUniqueID = () => {
        // putting ID into an object as the Jutro table component expects an object
        const structureClauseTableData = (exclusion) => ({
            publicID: exclusion.publicID,
            coverageCategoryCode: exclusion.exclusionCategoryCode,
            uigroup: exclusion.uigroup
        });

        const offerings = _.get(transactionVM, 'lobData.homeowners_EH.offerings.value');
        const exclusions = _.uniqBy(
            offerings.flatMap((offering) => offering.exclusions.map(structureClauseTableData)),
            'publicID'
        );

        return {
            exclusions
        };
    };

    const getConditionsUniqueID = () => {
        // putting ID into an object as the Jutro table component expects an object
        const structureClauseTableData = (condition) => ({
            publicID: condition.publicID,
            coverageCategoryCode: condition.conditionCategoryCode,
            uigroup: condition.uigroup
        });

        const offerings = _.get(transactionVM, 'lobData.homeowners_EH.offerings.value');
        const conditions = _.uniqBy(
            offerings.flatMap((offering) => offering.conditions.map(structureClauseTableData)),
            'publicID'
        );

        return {
            conditions
        };
    };

    const getCoveragesUniqueID = () => {
        // putting ID into an object as the Jutro table component expects an object
        const structureClauseTableData = (coverage) => ({
            publicID: coverage.publicID,
            coverageCategoryCode: coverage.coverageCategoryCode,
            uigroup: coverage.uigroup
        });
        const offerings = _.get(transactionVM, 'lobData.homeowners_EH.offerings.value');

        const offerringsWithCovsNotHidden = offerings.map((offerring) => {
            const offerringFormatted = { ...offerring};

            offerringFormatted.coverages.coverages = offerring.coverages.coverages.filter((coverage) => coverage.isHiddenInVersion !== true);

            return offerringFormatted;
        });

        const baseCoverages = _.uniqBy(
            offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages.map(structureClauseTableData)),
            'publicID'
        );

        const additionalCoverages = _.uniqBy(
            offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.schedules.map(structureClauseTableData)),
            'publicID'
        );

        const sectionICoverages = _.uniqBy(
            offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
                .filter((coverage) => coverage.uigroup === 'Section I')
                .map(structureClauseTableData)),
            'publicID'
        );

        const sectionIICoverages = _.uniqBy(
            offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
                .filter((coverage) => coverage.uigroup === 'Section II')
                .map(structureClauseTableData)),
            'publicID'
        );

        const deductibles = _.uniqBy(
            offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
                .filter((coverage) => coverage.uigroup === 'Deductibles')
                .map(structureClauseTableData)),
            'publicID'
        );

        return {
            baseCoverages,
            additionalCoverages,
            sectionICoverages,
            sectionIICoverages,
            deductibles
        };
    };


    function generateClauseData() {
        return columnData.map(({ lob, code }) => {
            const completeCoveragePath = 'coverages.coverages';

            return {
                code,
                path: `${lob.path}.${completeCoveragePath}`,
                clauses: _.get(lob.data, completeCoveragePath.replace(/\.children/, ''))
            };
        });
    }

    function generateExclusionClauseData() {
        return columnData.map(({ lob, code }) => {
            const completeExclusionPath = 'exclusions';

            return {
                code,
                path: `${lob.path}.${completeExclusionPath}`,
                clauses: _.get(lob.data, completeExclusionPath.replace(/\.children/, ''))
            };
        });
    }

    function generateConditionClauseData() {
        return columnData.map(({ lob, code }) => {
            const completeConditionPath = 'conditions';

            return {
                code,
                path: `${lob.path}.${completeConditionPath}`,
                clauses: _.get(lob.data, completeConditionPath.replace(/\.children/, ''))
            };
        });
    }

    const generateTableData = () => {
        const uniqueID = getCoveragesUniqueID(transactionVM);

        setTableData(Object.keys(uniqueID).map((coverageType) => ({
            header: _.get(messages, coverageType),
            data: _.get(uniqueID, coverageType),
            tableContent: generateClauseData(columnData, coverageType)
        })));
    };

    const generateExclusionTableData = () => {
        const uniqueID = getExclusionsUniqueID(transactionVM);

        setTableDataExclusion(Object.keys(uniqueID).map((coverageType) => ({
            header: _.get(messages, coverageType),
            data: _.get(uniqueID, coverageType),
            tableContent: generateExclusionClauseData(columnData, coverageType)
        })));
    };

    const generateConditionTableData = () => {
        const uniqueID = getConditionsUniqueID(transactionVM);

        setTableDataCondition(Object.keys(uniqueID).map((coverageType) => ({
            header: _.get(messages, coverageType),
            data: _.get(uniqueID, coverageType),
            tableContent: generateConditionClauseData(columnData, coverageType)
        })));
    };

    useEffect(() => {
        generateColumnData();
        // trigger only when toggle changes(api call happens for data change)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coverageChangedToggle]);

    useEffect(() => {
        if (columnData) {
            generateTableData();
            generateExclusionTableData();
            generateConditionTableData();
        }
        // trigger only when columnDataChanges
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnData])

    useMemo(() => {
        const map = new Map();
        const schedules = _.get(transactionVM, `lobData.homeowners_EH.offerings.children[${currentOfferingIndex}].coverages.schedules.children`, []);

        schedules.forEach((schedule, index) => {
            const ehScheduleItemsComponent = (
                <EHScheduleItemsComponent
                    data={schedule}
                    value={schedule.value}
                    labelPosition="left"
                    id={`scheduleItem${index}`}
                    onScheduleChange={
                        async (value, covPath) => {
                            setIsClauseLoading(true);

                            const response = await onScheduleChange(value, covPath);

                            if (_.get(response, 'value')) {
                                _.set(transactionVM, 'value', response.value);
                                setCoverageChangedToggle(!coverageChangedToggle);
                            }

                            setIsClauseLoading(false);
                        }
                    }
                    path={`lobData.homeowners_EH.offerings.children[${currentOfferingIndex}].coverages.schedules.children[${index}]`}
                    showTitle
                    readOnly={viewOnlyMode}
                    parentPageSubmitted={isPageSubmitted}
                />
            );

            map.set(schedule?.value?.referenceCode, ehScheduleItemsComponent);
        });
        setSchedulesMap(map);
    }, [currentOfferingIndex, isPageSubmitted, transactionVM, viewOnlyMode, coverageChangedToggle, onScheduleChange]);

    const renderCellContentForOpCov = (clauseCellPublicID, index, renderCellContentProps) => {
        const policyState = _.get(
            transactionVM,
            'baseData.policyAddress.state.value.code',
            _.get(transactionVM, 'policyAddress.state.value.code')
        );
        const { path, clauses = [], code } = renderCellContentProps.data;
        const clauseIndex = clauses.findIndex(
            (clause) => clause.publicID === clauseCellPublicID.publicID
        );
        const clause = clauses[clauseIndex];
        const userRolePremiumViewRoles = authUserData?.permissions_Ext.includes('viewpremium_ext');
        const blocksQuoteUWIssues = underwritingIssues?.some((uwIssue) => BLOCKS_QUOTES.includes(uwIssue.currentBlockingPoint));
        const showPremium = !blocksQuoteUWIssues || userRolePremiumViewRoles;

        if (clauseIndex !== -1) {
            return (
                <SingleClauseComponentForOptionalCovVM
                    value={clause}
                    path={`${path}.children[${clauseIndex}]`}
                    onChangeClause={async (value, covPath) => {
                        const response = await changeSubmission(value, covPath, clause);

                        if (_.get(response, 'value')) {
                            _.set(transactionVM, 'value', response.value);
                            setCoverageChangedToggle(!coverageChangedToggle);
                        }
                    }}
                    onSyncCoverages={async (value, covPath) => {
                        setIsClauseLoading(true);

                        const response = await syncCoverages(value, covPath, clause.publicID);

                        if (_.get(response, 'value')) {
                            _.set(transactionVM, 'value', response.value);
                            setCoverageChangedToggle(!coverageChangedToggle);
                        }

                        setIsClauseLoading(false);
                    }}
                    onChangeSubmissionAndSync={async (value, covPath) => {
                        setIsClauseLoading(true);

                        const response = await changeSubmissionAndSync(value, covPath, clause.publicID);

                        if (_.get(response, 'value')) {
                            _.set(transactionVM, 'value', response.value);
                            setCoverageChangedToggle(!coverageChangedToggle);
                        }

                        setIsClauseLoading(false);
                    }}
                    loadingClause={isClauseLoading}
                    idPrefex={code}
                    labelTop
                    onValidate={onValidate}
                    isPageSubmitted={isPageSubmitted}
                    viewOnlyMode={viewOnlyMode}
                    showPremium={showPremium}
                    schedulesMap={schedulesMap}
                    policyType={policyType}
                    hasGoldOrPlatinumBoxVisible={hasGoldOrPlatinumBoxVisible}
                    policyState={policyState}
                    labelPosition='top'
                />
            );
        }

        return null;
    };

    useEffect(() => {
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);

    useEffect(() => {
        registerComponentValidation(() => transactionVM.value.lobData.homeowners_EH.offerings[currentOfferingIndex]
                .coverages.schedules.filter((schedule) => schedule.referenceCode)
                .every((schedule) => schedule.scheduleItems.length > 0));
    }, [currentOfferingIndex, registerComponentValidation, transactionVM.value.lobData.homeowners_EH.offerings]);

    const clickedUpdateOnWhatsIncludedLinks = useCallback(
        () => {
            setClickedUpdateOnWhatsIncludedLinkValue(true);
            setIsWhatsIncludedInLine(true);
            setIsWhatsIncludedClosed(true);
        }, []
    );
    const clickedUpdateOnWhatsIncludedCloseLinks = useCallback(
        () => {
            setClickedUpdateOnWhatsIncludedLinkValue(false);
            setIsWhatsIncludedClosed(false);
        }, []
    );

    function getTableColumnContentForOpCov(clmnData, tableInfo) {
        return clmnData.map((data, index) => ({
                id: `tableColumnContent${index}`,
                type: 'container',
                component: 'div',
                componentProps: {
                    renderCell: renderCellContentForOpCov,
                    data: tableInfo.tableContent.find((content) => content.code === data.code),
                    onValidate,
                    isPageSubmitted
                }
            }));
    }

    const generateDesktopTableOverridesForPopularOpCov = () => {
        let overrides = [];

        if (tableData !== undefined) {
            const tableDataAllCoverages = _.cloneDeep(tableData[0]);

            tableDataAllCoverages.data = tableDataAllCoverages.data.filter((cov) => cov.uigroup === 'Popular Optional Coverages');

            const tableDataAllCoveragesArr = [tableDataAllCoverages];

            overrides = tableDataAllCoveragesArr.map((tableInfo, index) => ({
                [`popularOptionalCoverage${index}`]: {
                    data: tableInfo.data,
                    // title: tableInfo.header,
                    content: getTableColumnContentForOpCov(columnData, tableInfo)
                }
            }));
        }

        return Object.assign({}, ...overrides);
    };

    const generateDesktopTableOverridesForMoreOpCov = () => {
        let overrides = [];

        if (tableData !== undefined) {
            const tableDataAllCoverages = _.cloneDeep(tableData[0]);

            tableDataAllCoverages.data = tableDataAllCoverages.data.filter((cov) => cov.uigroup === 'More Optional Coverages');

            const tableDataAllCoveragesArr = [tableDataAllCoverages];
            const { clauses } = tableDataAllCoverages.tableContent[0];
            const findName = (item) => {
                const givenClause = clauses.find((clause) => clause.publicID === item.publicID);

                if (!givenClause) {
                    return '';
                }

                return givenClause.name;
            };

            const alphabeticallySort = (a, b) => {
                if (findName(a) < findName(b)) {return -1;}

                if (findName(a) > findName(b)) {return 1;}

                return 0;
            };

            overrides = tableDataAllCoveragesArr.map((tableInfo, index) => ({
                [`moreOptionalCoverage${index}`]: {
                    data: tableInfo.data.sort(alphabeticallySort),
                    // title: tableInfo.header,
                    content: getTableColumnContentForOpCov(columnData, tableInfo)
                }
            }));
        }

        return Object.assign({}, ...overrides);
    };

    const generateDesktopTableOverridesForOpCovExclusion = () => {
        let overrides = [];

        if (tableDataExclusion !== undefined) {
            const tableDataAllCoverages = _.cloneDeep(tableDataExclusion[0]);
            const tableDataAllCoveragesArr = [tableDataAllCoverages];

            overrides = tableDataAllCoveragesArr.map((tableInfo, index) => ({
                [`quoteTableCovExclusions${index}`]: {
                    data: tableInfo.data,
                    // title: tableInfo.header,
                    content: getTableColumnContentForOpCov(columnData, tableInfo)
                }
            }));
        }

        return Object.assign({}, ...overrides);
    };

    const generateDesktopTableOverridesForOpCovCondition = () => {
        let overrides = [];

        if (tableDataCondition !== undefined) {
            const tableDataAllCoverages = _.cloneDeep(tableDataCondition[0]);
            const tableDataAllCoveragesArr = [tableDataAllCoverages];

            overrides = tableDataAllCoveragesArr.map((tableInfo, index) => ({
                [`quoteTableCovConditions${index}`]: {
                    data: tableInfo.data,
                    // title: tableInfo.header,
                    content: getTableColumnContentForOpCov(columnData, tableInfo)
                }
            }));
        }

        return Object.assign({}, ...overrides);
    };

    const generateDesktopOverrides = () => ({
            ...generateDesktopTableOverridesForPopularOpCov(),
            ...generateDesktopTableOverridesForMoreOpCov(),
            ...generateDesktopTableOverridesForOpCovExclusion(),
            ...generateDesktopTableOverridesForOpCovCondition()
        });

    const togglePackageCoverage = useCallback(async (value, packageCovCodeIdentifier) => {
        let { path } = tableData[0].tableContent[0];
        const clauseIndex = _.get(transactionVM,
            `value.lobData.homeowners_EH.offerings[${currentOfferingIndex}].coverages.coverages`,
            [])
            .findIndex((coverage) => coverage.codeIdentifier === packageCovCodeIdentifier);

        path = `${path}.children[${clauseIndex}].selected`;
        setIsClauseLoading(true);

        // package sould be present; just for safety
        if (clauseIndex !== -1) {
            const response = await changeSubmissionAndSync(value, path);

            if (_.get(response, 'value')) {
                _.set(transactionVM, 'value', response.value);
            }
        }

        setIsClauseLoading(false);
    }, [changeSubmissionAndSync, currentOfferingIndex, tableData, transactionVM]);

    const onToggleGoldPackageCovChange = useCallback(
        async () => {
            updateGoldCheckBoxValue(!goldCheckBoxValue);

            if (platinumCheckBoxValue) {
                updatePlatinumCheckBoxValue(!platinumCheckBoxValue);
                // unselect platinum
                await togglePackageCoverage(!platinumCheckBoxValue, 'EH_PlatinumCoverageBundle');
                // once platinum is unselect, select gold
                await togglePackageCoverage(!goldCheckBoxValue, 'EH_GoldCoverageBundle');
            } else {
                await togglePackageCoverage(!goldCheckBoxValue, 'EH_GoldCoverageBundle');
            }

            setCoverageChangedToggle(!coverageChangedToggle);

        }, [goldCheckBoxValue, platinumCheckBoxValue, coverageChangedToggle, togglePackageCoverage]
    );

    const onTogglePlatinumPackageCovChange = useCallback(
        async () => {
            updatePlatinumCheckBoxValue(!platinumCheckBoxValue);

            if (goldCheckBoxValue) {
                updateGoldCheckBoxValue(!goldCheckBoxValue);
                // unselect gold
                await togglePackageCoverage(!goldCheckBoxValue, 'EH_GoldCoverageBundle');
                // once gold is unselect, select platinum
                await togglePackageCoverage(!platinumCheckBoxValue, 'EH_PlatinumCoverageBundle');
            } else {
                await togglePackageCoverage(!platinumCheckBoxValue, 'EH_PlatinumCoverageBundle');
            }

            setCoverageChangedToggle(!coverageChangedToggle);
        }, [platinumCheckBoxValue, goldCheckBoxValue, coverageChangedToggle, togglePackageCoverage]
    );

    const textContent = (
        <React.Fragment>
            {translator(messages.optionalCoverageSelect)}
            <span className={styles.addIcon} />
            {translator(messages.optionalCoverageLabel)}
        </React.Fragment>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrides = {
        '@field': {
            // apply to all fields
            labelPosition: 'top',
            disabled: viewOnlyMode,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        optionalCoverageHeader: {
            visible: hasGoldOrPlatinumBoxVisible && (policyType === 'HO3' || policyType === 'HF9')
        },
        optionalCoveragesMainContainer: {
            visible: !hasGoldOrPlatinumBoxVisible && (policyType === 'HO3' || policyType === 'HF9')
        },
        optionalCoveragePackageMsg: {
            content: textContent
        },
        goldCheckboxField: {
            value: goldCheckBoxValue,
            disabled: viewOnlyMode
        },
        platinumCheckboxField: {
            value: platinumCheckBoxValue,
            disabled: viewOnlyMode
        },
        packageDifference: {
            isInLine: isWhatsIncludedInLine,
            visible: clickedOnWhatsIncludedLinkValue,
            lob: 'Homeowners_EH'
        },
        whatsIncludedItem: {
            visible: hasGoldOrPlatinumBoxVisible && (policyType === 'HO3' || policyType === 'HF9')
        },
        whatsincludedCloseLink: {
            visible: isWhatsIncludedClosed
        },
        whatsIncludedLink: {
            visible: !isWhatsIncludedClosed
        },
        exclusionsMainContainer: {
            visible: tableDataExclusion !== undefined && tableDataExclusion[0].data.length > 0
        },
        conditionsMainContainer: {
            visible: tableDataCondition !== undefined && tableDataCondition[0].data.length > 0
        },
        errorMessageDiv: {
            visible: isPageSubmitted
        },
        specialLossSettlementComponent: {
            changeSubmissionAndSync: async (value, covPath) => {
                setIsClauseLoading(true);

                const response = await changeSubmissionAndSync(value, covPath);

                if (_.get(response, 'value')) {
                    _.set(transactionVM, 'value', response.value);
                    setCoverageChangedToggle(!coverageChangedToggle);
                }

                setIsClauseLoading(false);
            },
            transactionVM,
            viewOnlyMode,
            currentOfferingIndex
        },
        ...generateDesktopOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            toggleGoldPackageCov: onToggleGoldPackageCovChange,
            togglePlatinumPackageCov: onTogglePlatinumPackageCovChange,
            clickedUpdateOnWhatsIncludedLinks,
            clickedUpdateOnWhatsIncludedCloseLinks
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dataForComponent = {
        columnData, tableData, tableDataExclusion, tableDataCondition
    };

    const onOk = useCallback(
        () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);

                return false;
            }

            return onResolve();
        }, [isComponentValid, onResolve]
    );

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                dataForComponent,
                id,
                path,
                goldCheckBoxValue,
                overrides
            ),
        [overrides, goldCheckBoxValue, dataForComponent]
    );

    return (
        <ModalNext
            isOpen={isOpen}
            className={styles.modalDialog}
        >
            <ModalHeader title={title} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={dataForComponent}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onOk}>
                    {messages.backToCoverages}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

OptionalCoverages.propTypes = {
    title: PropTypes.string.isRequired,
    viewOnlyMode: PropTypes.bool,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    policyType: PropTypes.string.isRequired,
    allSchedulesHaveAtLeastOneItem: PropTypes.bool,
    hasGoldOrPlatinumBoxVisible: PropTypes.bool.isRequired,
    changeSubmissionAndSync: PropTypes.func,
    transactionVM: PropTypes.shape({}).isRequired,
    currentOfferingIndex: PropTypes.number,
    currentOfferingBranchCode: PropTypes.string.isRequired
};

OptionalCoverages.defaultProps = {
    viewOnlyMode: false,
    allSchedulesHaveAtLeastOneItem: true,
    changeSubmissionAndSync: () => { },
    currentOfferingIndex: 0
};

export default withModalContext(OptionalCoverages);