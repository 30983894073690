import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Card, withModalContext } from '@jutro/components';
import { ServiceManager } from '@jutro/legacy/services';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import { Button } from '@jutro/legacy/components';
import generateSubComponents from './ScheduleItemTypes/ScheduleItemTypes';
import messages from './ScheduleItemsComponent.messages';
import styles from './EHScheduleItemDetailsModal.module.scss';


function EHScheduleItemDetailsModal(props) {
    const {
        propertyInfo,
        viewModelService,
        scheduleData,
        scheduleItem,
        labelPosition,
        id,
        isNew,
        onSaveAndCloseOfScheduleItemsDetailModal,
        parentPageSubmitted,
        setShowScheduleItemError
    } = props;
    const translator = useTranslator();
    const localeService = ServiceManager.getService('locale-service');
    const [modalData, setModalData] = useState(scheduleData);
    const { isComponentValid, onValidate } = useValidation(id);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const scheduleItemsLength = scheduleItem.scheduleItems.length;
    const modalOverrides = {
        '@field': {
            labelPosition,
            showOptional: false,
            autoComplete: false
        }
    };

    const handleChange = useCallback((value, path) => {
        const clonedData = _.cloneDeep(modalData);
        const newPath = ViewModelUtil.getNonVMPath(path);

        _.set(clonedData, newPath, value);
        setModalData(clonedData);
    }, [modalData]);

    const orderedPropertyInfo = useMemo(() => _.sortBy(propertyInfo, 'order'), [propertyInfo]);
    const defaultCountryCode = localeService.getDefaultCountryCode();
    const subComponents = orderedPropertyInfo
        .flatMap((info) => (
            generateSubComponents({
                info,
                modalData,
                viewModelService,
                translator,
                defaultCountryCode,
                scheduleItem,
                onValueChange: handleChange,
                onValidate,
                parentOverrides: modalOverrides,
                isNew,
                isPageSubmitted,
                parentPageSubmitted
            })
        ));

    const handleAdd = useCallback(
        () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                setShowScheduleItemError(true);

                return false;
            }

            setShowScheduleItemError(false);

            return onSaveAndCloseOfScheduleItemsDetailModal(modalData);
        },
        [
            isComponentValid, onSaveAndCloseOfScheduleItemsDetailModal,
            modalData, updateIsPageSubmitted, setShowScheduleItemError
        ]
    );

    const handleDismiss = useCallback((evt) => {
        evt.stopPropagation();
        onSaveAndCloseOfScheduleItemsDetailModal();
    }, [onSaveAndCloseOfScheduleItemsDetailModal]);

    return (
        <Card id={id} isPanel>
            {subComponents}
            <div className={styles.buttonContainer} key={`cardDiv${id}`}>
                <Button
                    onClick={handleDismiss}
                    type="text"
                    hidden={scheduleItemsLength === 0}
                    className={styles.cancelButton}
                    key={`cancelButton${id}`}
                >
                    {messages.scheduleCancel}
                </Button>
                <Button onClick={handleAdd} key={`saveButton${id}`}>{messages.save}</Button>
            </div>
        </Card>
    );
}

EHScheduleItemDetailsModal.propTypes = {
    propertyInfo: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    scheduleData: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    id: PropTypes.string.isRequired,
    scheduleItem: PropTypes.shape({
        displayName: PropTypes.string,
        scheduleItems: PropTypes.arrayOf(
            PropTypes.shape({})
        )
    }).isRequired,
    viewModelService: PropTypes.shape({}).isRequired,
    isNew: PropTypes.bool.isRequired,
    onSaveAndCloseOfScheduleItemsDetailModal: PropTypes.func.isRequired,
    parentPageSubmitted: PropTypes.bool,
    setShowScheduleItemError: PropTypes.func
};
EHScheduleItemDetailsModal.defaultProps = {
    scheduleData: {},
    labelPosition: 'left',
    parentPageSubmitted: false,
    setShowScheduleItemError: undefined
};
export default withModalContext(EHScheduleItemDetailsModal);
