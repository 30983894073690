import { defineMessages } from 'react-intl';

export default defineMessages({
    thisFieldMustBeUnique: {
        id: 'components.ehCoverages.schedules.dataItem.This field must be unique',
        defaultMessage: 'This field must be unique'
    },
    requiredField: {
        id: 'components.ehCoverages.schedules.dataItem.This is a required field',
        defaultMessage: 'This is a required field'
    }
});
