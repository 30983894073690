import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withModalContext } from '@jutro/components';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { JobService } from 'gw-capability-gateway';
import appConfig from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TransactionViewFlowUtil } from 'e1p-portals-util-js';
import { e1pDateUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import UnderwritingComponent from '../../Components/UnderwritingComponent/UnderwritingComponent';
import metadata from './Summary.metadata.json5';
import messages from './Summary.messages';
import gatewayMessages from '../../gateway.messages';

class QuoteDetailsSummaryWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}),
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                loadQuoteSummary: PropTypes.shape({}).isRequired,
                updateJobSummary: PropTypes.func.isRequired
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        authUserData: PropTypes.shape({
            userType: PropTypes.string
        }),
    };

    static defaultProps = {
        authHeader: undefined,
        authUserData: undefined
    }

    state = {
        showisContinueTransactionButton: false,
        canWithdraw: false,
        isLoading: true
    };

    componentDidMount() {
        this.canWithdraw();
        this.getShowisContinueTransaction();
    };

    getShowisContinueTransaction = async () => {
        const { authUserData } = this.props;
        const showisContinueTransactionButton = authUserData?.permissions_Ext.includes('createsubmission');

        this.setState({ showisContinueTransactionButton, isLoading: false });
    };

    canWithdraw = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            },
            authUserData
        } = this.props;
        const withdrawPermission = authUserData?.permissions_Ext.includes('withdraw');

        if (!_.isEmpty(submission)) {
            const translator = this.context;

            if (
                withdrawPermission
                && submission.status !== translator('typekey.PolicyPeriodStatus.Bound')
                && !submission.policy.issued
                && !submission.closeDate
            ) {
                this.setState({ canWithdraw: true });
            }
        }
    };

    onContinueTransaction = () => {
        const { lobQuoteURL } = appConfig;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            },
            history
        } = this.props;
        const productCode = _.get(submission, 'productCode');
        const postalCode = _.get(
            submission,
            'policy.account.accountHolder.primaryAddress.postalCode'
        );

        if (submission.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                const nextLocation = {
                    quoteentry: {
                        postalCode,
                        quoteID: submission.jobNumber
                    }
                };

                history.push(lobQuoteURL[productCode], nextLocation);
            } else {
                JobUtil.openJobInXCenter(submission.jobNumber);
            }
        }
    };

    onViewTransaction = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            },
            history
        } = this.props;
        const productCode = _.get(submission, 'productCode');
        const productMap = {
            PersonalAuto_EA: 'personalAuto_EA',
            Homeowners_EH: 'homeowners_EH',
            PersonalUmbrella_EU: 'personalUmbrella_EU'
        };
        const policyType = submission.policy.lobs[productMap[productCode]].policyType_Ext;

        return TransactionViewFlowUtil.goToTransactionSpecificViewOnlyFlow(
            history, submission?.jobNumber, policyType,
            submission?.type, submission?.productCode,
            submission?.policy?.policyNumber
        );
    };

    onWithdrawTransaction = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            },
            authHeader
        } = this.props;

        this.props.modalContext.showConfirm({
            title: messages.withdrawJob,
            message: `${translator(messages.sureWithDrawQuote)} ${submission.jobNumber}?`,
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: translator(e1pCommonMessages.withdrawTransaction, { transaction: 'QUOTE' }),
        }).then((result) => {
            if (result === 'confirm') {
                JobService.withdrawJobByJobNumber(submission.jobNumber, authHeader).then(
                    () => {
                        this.updateWithDrawQuote('Withdrawn', submission);
                    },
                    () => {
                        this.props.modalContext.showAlert({
                            title: gatewayMessages.modalError,
                            message: messages.failedWithdrawSubmission,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.close
                        }).catch(_.noop);
                    }
                );
            }
        }, _.noop);
    };

    getTransactionActionContent = () => {
        const translator = this.context;

        return {
            withDrawContent: translator(messages.withdrawQuote),
            continueContent: translator(messages.continueQuote),
            viewContent: translator(messages.viewQuote)
        };
    };

    getSubmissionToProceed = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const translator = this.context;
        const { canWithdraw, showisContinueTransactionButton } = this.state;

        if (
            submission
            && (submission.statusCode === 'Withdrawn' || submission.statusCode === 'Bound')
        ) {
            return {
                isContinueTransaction: false,
                isWithdrawTransaction: false,
                isViewTransaction: submission.canUserView
            };
        }

        const additionalConditionslToShowContinueButton = submission.status !== translator('typekey.PolicyPeriodStatus.Bound')
            && !submission.policy.issued
            && !submission.closeDate;

        return {
            isContinueTransaction:
                showisContinueTransactionButton && additionalConditionslToShowContinueButton && !submission.isEditLocked,
            isWithdrawTransaction: canWithdraw,
            isViewTransaction: submission.canUserView
        };
    };

    getPolicyLinkVisible = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const policyNumber = _.get(submission, 'policy.latestPeriod.policyNumber');

        if (policyNumber) {
            return policyNumber !== 'Unassigned';
        }

        return false;
    };

    getJobStatus = (job) => {
        const translator = this.context;

        if (job.policy) {
            if (job.policy.issued) {
                return translator(gatewayMessages.issued);
            }

            if (job.status === 'Not-taken') {
                return translator(gatewayMessages.notTaken);
            }
        }

        return job.status;
    };

    getStatusInfo = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;
        const translator = this.context;
        const submissionStatus = this.getJobStatus(submission);

        if (submissionStatus.toLowerCase() === 'bound' && submission.policy.issued) {
            return translator('gateway.directives.JobSummary.Issued');
        }

        return submissionStatus;
    };

    updateWithDrawQuote = (jobStatusCode, submission) => {
        const translator = this.context;
        const status = translator({
            id: `typekey.PolicyPeriodStatus.${jobStatusCode}`,
            defaultMessage: jobStatusCode
        });

        _.set(submission, 'status', status);
        _.set(submission, 'statusCode', jobStatusCode);
        this.updateJobSummary(submission);
    };

    updateJobSummary = (submission) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: { updateJobSummary }
            }
        } = this.props;

        if (updateJobSummary) {
            updateJobSummary(submission);
        }
    };

    render() {
        const {
            fromAccountLanding: {
                quoteDetailsData: { loadQuoteSummary: submission }
            }
        } = this.props;

        const policyNumber = _.get(submission, 'policy.latestPeriod.policyNumber');
        
        const { isLoading } = this.state;

        if (_.isEmpty(submission)) {
            return <E1PLoader loaded={!isLoading} />;
        }

        const overrides = {
            transactionActions: {
                transactionActionContent: this.getTransactionActionContent(),
                transactionVisibleActions: this.getSubmissionToProceed()
            },
            policyLinkDiv: {
                visible: this.getPolicyLinkVisible()
            },
            policyInfoLink: {
                content: policyNumber,
                to: `/policies/${policyNumber}/summary`
            },
            summaryPolicyInceptionId: {
                value: e1pDateUtil.convertToUTCForDateWithoutTZ(_.get(submission, 'policy.latestPeriod.effectiveDate'))
            },
            termEffectiveDate: {
                value: e1pDateUtil.convertToUTCForDateWithoutTZ(_.get(submission, 'termEffectiveDate'))
            },
            detailGridTotalColumn: {
                visible: submission.statusCode === 'Quoted'
            },
            producerCodeOfRecordOrgId: {
                value: `${_.get(
                    submission,
                    'policy.latestPeriod.producerCodeOfRecordOrg'
                )} (${_.get(submission, 'policy.latestPeriod.externalID_Ext')})`
            },
            producerOfServiceId: {
                value: `${_.get(
                    submission,
                    'policy.latestPeriod.producerCodeOfServiceOrg'
                )} (${_.get(submission, 'policy.latestPeriod.externalID_Ext')})`
            },
            preferredUnderwriterId: {
                value: _.get(
                    submission,
                    'policy.latestPeriod.assignedUWName_Ext', ''
                )
            },
            summaryStatusdDataId: {
                value: this.getStatusInfo()
            }
        };
        const resolvers = {
            resolveComponentMap: {
                underwritingcomponent: UnderwritingComponent
            },
            resolveCallbackMap: {
                onContinueTransaction: this.onContinueTransaction,
                onWithdrawTransaction: this.onWithdrawTransaction,
                onViewTransaction: this.onViewTransaction
            }
        };
        const readValue = (id, path) => readViewModelValue(metadata.pageContent, submission, id, path, overrides);

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submission}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={readValue}
            />
        );
    }
}

const QuoteDetailsSummary = withModalContext(QuoteDetailsSummaryWithoutModalContext);

export default withAuthenticationContext(QuoteDetailsSummary);
