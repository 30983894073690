
import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { useUWIssueUtil, useOOSConflictPageLandingUtil } from 'e1p-capability-hooks';
import metadata from './RewriteEHRiskAnalysisPage.metadata.json5';
import messages from './RewriteEHRiskAnalysisPage.messages';

function RiskAnalysisPage(props) {
    // eslint-disable-max-len
    const { authHeader } = useAuthentication();
    const {
        wizardData: rewriteVM,
        updateWizardData,
        isSkipping,
        jumpTo,
        steps,
        authUserData,
        currentStepIndex,
        changeNextSteps,
        updateWizardSnapshot
    } = props;

    const stepsRef = useRef(steps);
    const quoteID = _get(rewriteVM, 'value.jobID');
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [saveAndQuoteInProcess, setSaveAndQuoteInProcess] = useState(true);
    const [lossesPageValid, setLossesPageValid] = useState(true);
    const [creditReportResponse, setCreditReportResponse] = useState({});
    const [geoDataResponse, setGeoDataResponse] = useState({});
    const [responseAuto, setResponseAuto] = useState([]);    
    const [activeTab, updateActiveTab] = useState('');
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [responseProperty, setResponseProperty] = useState({});
    const [overlappingException, setOverlappingException] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const translator = useTranslator();
    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation('RiskAnalysisPage');

    useEffect(() => {
        /**
         * Using useRef to access current updated steps.
         * as we are adding new conflicts step and landing user on this newly created step
         * "step" state variable from props does not give us updated value inside useOOSConflictPageLandingUtil
         * it refers initial rendered value only(as we are adding new step and want to land user
         * on new step in)
         */
        stepsRef.current = steps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps]);

    const {
        removeOrAddAndLandOnConflictsPage
    } = useOOSConflictPageLandingUtil(
        stepsRef,
        currentStepIndex,
        changeNextSteps,
        jumpTo
    );

    const {
        hasUWIssues
    } = useUWIssueUtil(
        rewriteVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const isPageValid = useCallback(() => {
        return lossesPageValid && !overlappingException;
    }, [lossesPageValid, overlappingException]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted]);

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(rewriteVM, 'lobData.homeowners_EH.premiumStability.value') !== undefined;

    const saveAndQuote = useCallback(
        async (_, calledFromOnSave = false) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);
                return false;
            }
            setSaveAndQuoteInProcess(true);
            const response = await RewriteService.saveAndQuote(
                [rewriteVM.value],
                authHeader
            );
            _set(rewriteVM, 'value', response);
            updateWizardData(rewriteVM);
            if (!calledFromOnSave) {
                // E1PAP1PC-13853 :
                // If we get conflicts in saveAndQuote, we will add conflicts page if its not present
                // and user will land on conflicts page
                // If we had conflicts and we came back and made changes such that after saveAndQuote
                // if there are no conflicts we will remove conflicts page if its present
                const hasConflicts = !_isEmpty(_get(rewriteVM, 'value.conflicts', []));
                removeOrAddAndLandOnConflictsPage(hasConflicts);
                if (hasConflicts) {
                    setSaveAndQuoteInProcess(false);
                    return false;
                }
            }

            setSaveAndQuoteInProcess(false);
            return rewriteVM;
        },
        [
            authHeader, isComponentValid, removeOrAddAndLandOnConflictsPage,
            rewriteVM, updateWizardData
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);
            try {
                await saveAndQuote(undefined, true);
                const fieldIssues = _get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(rewriteVM, 'baseData.exceptions_Ext.value', []);
                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(rewriteVM);
                }
                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, rewriteVM, updateWizardSnapshot]
    );

    useEffect(() => {
        const hasManualPropertyLosses = _get(rewriteVM.value, 'lobData.homeowners_EH.hasManualPropertyLosses');
        const manualPropertyLossRecords = _get(rewriteVM.value, 'lobData.homeowners_EH.manualPropertyLossRecords');
        if (hasManualPropertyLosses !== undefined
            && ((hasManualPropertyLosses && manualPropertyLossRecords !== undefined && manualPropertyLossRecords.length > 0) || !hasManualPropertyLosses)) {
            if (rewriteVM.skipRiskAnalysisPage === true) {
                _set(rewriteVM, 'skipRiskAnalysisPage', false);
                const uwIssues = _get(rewriteVM.value, 'errorsAndWarnings.underwritingIssues');
                if (uwIssues === undefined || uwIssues.length === 0) {
                    let isQuoted = [];
                    if (rewriteVM.value.quoteData !== undefined) {
                        isQuoted = rewriteVM.value.quoteData.offeredQuotes.filter(
                            (offredQuote) => offredQuote.status === 'Quoted'
                        );
                    }
                    if (isQuoted.length > 0) {
                        setSaveAndQuoteInProcess(false);
                        props.e1pGoNext();
                    }
                }
                updateWizardData(rewriteVM);
            }
        }
        setSaveAndQuoteInProcess(false);
        setIsPageInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDefaultActiveTab = () => {
        if (hasUWIssues()) {
            return 'uwIssuesTab';
        }
        return 'verifiedLossesAndViolationTab';
    };


    const resolvers = {
        resolveCallbackMap: {
            onValidate: onValidate,
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: !saveAndQuoteInProcess && isPageInitialized && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: !saveAndQuoteInProcess && isPageInitialized && !isSavingCurrentPageChanges,
            defaultActiveTab: getDefaultActiveTab(),
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            activeTab: activeTab !== '' ? activeTab : getDefaultActiveTab()
        },
        verifiedLossesAndViolationComponent: {
            responseAuto: responseAuto,
            setResponseAuto: setResponseAuto,
            responseProperty: responseProperty,
            setResponseProperty: setResponseProperty,
            submissionVM: rewriteVM,
            updateWizardData: updateWizardData,
            setLossesPageValid: setLossesPageValid,
            isSkipping: isSkipping,
            authUserData,
            isRewrite: true,
            onValidateParentPage: onValidate,
            disregardFieldValidationParentPage: disregardFieldValidation,
            showErrors: isPageSubmitted,
            updateIsPageSubmitted: updateIsPageSubmitted
        },
        EHRiskAnalysisMiscComponentId: {
            submissionVM: rewriteVM,
            visible: true
        },
        creditReportsComponent: {
            submissionVM: rewriteVM,
            creditReportResponse,
            setCreditReportResponse,
            updateWizardData,
            lineOfBusiness: rewriteVM.lobData.homeowners_EH,
            lobName: 'homeowners_EH',
            authUserData,
            id: 'creditReportsComponent',
            onValidate,
            setOverlappingException
        },
        geoDataComponent: {
            quoteID: quoteID,
            geoDataResponse: geoDataResponse,
            setGeoDataResponse: setGeoDataResponse,
            policyState: _get(rewriteVM, 'baseData.policyAddress.state.value.code')
        },
        uwIssueComponent: {
            submissionVM: rewriteVM,
            updateWizardData: updateWizardData,
            authHeader
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(rewriteVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: rewriteVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        },
        diminishingAvailableAmount: {
            labelPosition: 'left'
        },
        diminishingAvailableAmountLatest: {
            labelPosition: 'left'
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                rewriteVM,
                id,
                path,
                overrideProps
            );
        },
        [overrideProps, rewriteVM]
    );

    return (
        <WizardPage
            skipWhen={initialValidation}
            onNext={saveAndQuote}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
