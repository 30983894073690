import React, { useCallback, useEffect, useState } from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { RewriteService } from 'e1p-capability-rewrite';
import messages from './OOSConflictsRewritePageComponent.messages';
import styles from './OOSConflictsRewritePageComponent.module.scss';
import metadata from './OOSConflictsRewritePageComponent.metadata.json5';

function OOSConflictsRewritePageComponent(props) {
    const modalApi = useModal();
    const {
        wizardData: rewriteVM,
        updateWizardData,
        removeCurrentPageFromSteps
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        onValidate, initialValidation, isComponentValid, registerComponentValidation
    } = useValidation('OOSConflictsRewrite');
    const [isHandlingConflicts, setIsHandlingConflicts] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);

    const writeValue = useCallback(
        (value, path) => {
            _set(rewriteVM, path, value);
            updateWizardData(rewriteVM);
        }, [rewriteVM, updateWizardData]
    );

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);

    useEffect(() => {
        // If overriden values(yes/no) are not provided for all conflicts item set component as invalid
        registerComponentValidation(() => _get(rewriteVM, 'value.conflicts', []).every((conflict) => conflict.override != null));
    }, [registerComponentValidation, rewriteVM]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            window.scrollTo(0, 0);
            updateIsPageSubmitted(true);

            return false;
        }

        setIsHandlingConflicts(true);

        try {
            const confirmModalNext = await modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.submitConfirmTitle,
                message: messages.submitConfirmMessage
            });

            if (confirmModalNext === 'cancel') {
                setIsHandlingConflicts(false);

                return false;
            }

            const request = {
                conflicts: _get(rewriteVM, 'value.conflicts', []),
                jobNumber: _get(rewriteVM, 'value.jobID')
            };
            const response = await RewriteService.handleOOSConflictsAndQuote([request], authHeader);

            rewriteVM.value = response;
            setIsHandlingConflicts(false);
            updateWizardData(rewriteVM);

            const hasConflicts = !_isEmpty(_get(rewriteVM, 'value.conflicts', []));
            const validations = _get(response, 'errorsAndWarnings.validationIssues.fieldIssues', []);
            const exceptions = _get(response, 'baseData.exceptions_Ext');

            if (hasConflicts || exceptions || !!validations.length) {
                return false;
            }

            removeCurrentPageFromSteps();

            return rewriteVM;
        } catch (exception) {
            setIsHandlingConflicts(false);

            return false;
        }
    }, [authHeader, isComponentValid, removeCurrentPageFromSteps, rewriteVM, updateWizardData, modalApi]);

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            autoComplete: false
        },
        conflictsComponentLoader: {
            loaded: !isHandlingConflicts,
            text: translator(messages.handlingConflicts)
        },
        conflictsComponentContainer: {
            visible: !isHandlingConflicts
        },
        changeConflicts: {
            conflicts: _get(rewriteVM, 'value.conflicts', []),
            jobNumber: _get(rewriteVM, 'value.jobID', ''),
            authHeader,
            changeEffectiveDate: _get(rewriteVM.value, 'baseData.effectiveDate'),
            writeValue,
            showSubmitButton: false,
            isPageSubmitted
        }
    };

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveClassNameMap: styles
    };


    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                // onValueChange={writeValue}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

OOSConflictsRewritePageComponent.propTypes = wizardProps;
export default OOSConflictsRewritePageComponent;
