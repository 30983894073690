import { defineMessages } from 'react-intl';

export default defineMessages({
    standardizingAddressMessage: {
        id: 'policyjob.common.components.addressComponent.Standardizing address...',
        defaultMessage: 'Standardizing address...'
    },
    commonAddressLine1: {
        id: 'policyjob.common.components.addressComponent.address.Line1',
        defaultMessage: 'Address Line 1'
    },
    commonAddressLine2: {
        id: 'policyjob.common.components.addressComponent.address.Line2',
        defaultMessage: 'Address Line 2'
    },
    commonAddressLine2Optional: {
        id: 'policyjob.common.components.addressComponent.address.Line2Optional',
        defaultMessage: 'Optional'
    },
    commonCity: {
        id: 'policyjob.common.components.addressComponent.address.City',
        defaultMessage: 'City'
    },
    commonState: {
        id: 'policyjob.common.components.addressComponent.address.State',
        defaultMessage: 'State'
    },
    commonZipCode: {
        id: 'policyjob.common.components.addressComponent.address.ZipCode',
        defaultMessage: 'Zip Code'
    },
    commonPostalCode: {
        id: 'policyjob.common.components.addressComponent.address.PostalCode',
        defaultMessage: 'Postal Code'
    },
    commonCountry: {
        id: 'policyjob.common.components.addressComponent.address.Country',
        defaultMessage: 'Country'
    },
    chooseCountry: {
        id: 'policyjob.common.components.addressComponent.address.ChooseCountry',
        defaultMessage: '--Choose Country--'
    },
    provinceTerritory: {
        id: 'policyjob.common.components.addressComponent.address.provinceTerritory',
        defaultMessage: 'Province/Territory'
    },
    allowedSpecialCharacters: {
        id: 'policyjob.common.components.addressComponent.address.allowedSpecialCharacters',
        defaultMessage: '{fieldName} should only include letters, numbers, spaces, and the following special characters: ., -, /, #, &.'
    }
});
