import React, {
    useContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
    get, set, isUndefined, isEmpty
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import messages from './EUVehicleOperatorPage.messages';
import metadata from './EUVehicleOperatorPage.metadata.json5';

function EUVehicleOperatorPage(props) {
    const {
        wizardData: submissionVM, updateWizardData, isSkipping, updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);
    const { authHeader } = useAuthentication();
    const {
        onValidate, initialValidation, isComponentValid,
        registerComponentValidation,
        registerInitialComponentValidation
    } = useValidation('EUVehicleOperatorPage');
    const [isSavingQuote, setIsSavingQuote] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        if (isUndefined(submissionVM.lobData.personalUmbrella_EU.vehicleOperators.value)) {
            set(submissionVM.lobData.personalUmbrella_EU.vehicleOperators, 'value', []);
        }

        updateWizardData(submissionVM);
        registerComponentValidation(() => submissionVM.lobData.personalUmbrella_EU.vehicleOperators.aspects.subtreeValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // stop on vehicle operator if there are no vehicle operators added.
        registerInitialComponentValidation(() => get(submissionVM, 'value.lobData.personalUmbrella_EU.vehicleOperators.length') > 0 );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // this grabs the latest element on the page --
    // this is to make sure this element has been loaded
    const latestVehicleOperatorElement = document.getElementById(
        `euVehicleOperatorComponentDiv${submissionVM.lobData.personalUmbrella_EU.vehicleOperators.children.length - 1}`
    );

    useEffect(() => {
        // indexStale set in the add vehicle operator function
        // once latest element is loaded and a new vehicle operator is added
        // we check if the button should be visible
        if (latestVehicleOperatorElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestVehicleOperatorElement]);

    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const vehicleOperatorElement = document.getElementById(
            `firstName${submissionVM.lobData.personalUmbrella_EU.vehicleOperators.length - 1}`
        );

        if (vehicleOperatorElement
            && isEmpty(vehicleOperatorElement.value)
            && vehicleOperatorElement.focus !== undefined) {
            vehicleOperatorElement.focus();
        }
    }, [submissionVM.lobData.personalUmbrella_EU.vehicleOperators.length]);

    const addVehicleOperator = useCallback(
        () => {
            const {
                _xCenter,
                _dtoName
            } = submissionVM.lobData.personalUmbrella_EU.vehicleOperators;
            const newVehicleOperatorVM = viewModelService.create({}, _xCenter, _dtoName);

            submissionVM.lobData.personalUmbrella_EU.vehicleOperators.pushElement(
                newVehicleOperatorVM
            );
            updateWizardData(submissionVM);
            setCheckScrolling(true);
            setIndexStale(true);
        },
        [viewModelService, updateWizardData, submissionVM]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isSavingQuote && !isSkipping && !isSavingCurrentPageChanges, [isSavingCurrentPageChanges, isSavingQuote, isSkipping]);

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        vehicleOperatorPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        vehicleOperatorPageContainer: {
            visible: isPageLoaded
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleOperatorsContainer')
        },
        EUVehicleOperatorGrid: {
            key: get(submissionVM, 'lobData.personalUmbrella_EU.vehicleOperators', []).length,
            transactionVM: submissionVM,
            drivers: get(submissionVM, 'lobData.personalUmbrella_EU.vehicleOperators', []),
            path: 'lobData.personalUmbrella_EU.vehicleOperators.children',
            onValidate,
            showErrors: isPageSubmitted,
            setCheckScrolling,
            updateWizardData
        }
    };

    /**
     * Helper callback for writing values to the view model.
     */
    const writeValue = useCallback(
        (newVal, path) => {
            set(submissionVM, `${path}.value`, newVal);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            addVehicleOperator,
            onValidate
        }
    };

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingQuote(true);

            const newSubmissionVM = viewModelService.clone(submissionVM);

            // Default autopay to true if null or undefined before rating otherwise retain value
            submissionVM.lobData.personalUmbrella_EU.autoPayDiscInd.value ??= true;

            const quoteData = get(newSubmissionVM, 'quoteData.value');

            submissionVM.flowStepIDs_Ext.value = ['vehicleoperators'];
            submissionVM.entryCompletionStepIDs_Ext.value = ['vehicleoperators'];

            const response = await LoadSaveService.saveAndQuoteSubmission(
                submissionVM.value, authHeader
            );

            setIsSavingQuote(false);

            const respQuoteData = get(response, 'quoteData');

            if (respQuoteData === undefined) {
                set(response, 'quoteData', quoteData);
            }

            set(newSubmissionVM, 'value', response);
            updateWizardData(newSubmissionVM);

            return newSubmissionVM;
        },
        [
            LoadSaveService,
            authHeader,
            isComponentValid,
            submissionVM,
            updateWizardData,
            viewModelService
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, submissionVM, updateWizardSnapshot]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

EUVehicleOperatorPage.propTypes = wizardProps;
export default EUVehicleOperatorPage;
