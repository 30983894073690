import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
    useMemo
} from 'react';
import {
    cloneDeep as _cloneDeep,
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    findIndex as _findIndex,
    includes as _includes
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import {
    ViewModelForm,
    ViewModelServiceContext
} from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';

import {
    useAuthentication,
    withAuthenticationContext
} from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    useSniUtil,
    useNewBusinessUtil
} from 'e1p-capability-hooks';
import { isRequired } from 'e1p-portals-required-validator-js';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { RenewalService } from 'e1p-capability-renewal';
import appConfig from 'app-config';
import messages from './InsuredDetailsPage.messages';
import wizardMessages from '../../EARenewalWizard.messages';
import metadata from './InsuredDetailsPage.metadata.json5';
import requiredMetadata from './InsuredDetailsPage.requiredness';

const LOB = 'personalAuto_EA';

function InsuredDetailsPage(props) {
    const modalApi = useModal();
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        authUserData,
        steps,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        updateWizardSnapshot,
        wizardSnapshot,
        changeNextSteps,
        currentStep
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [requiredFields, updateRequiredFields] = useState([]);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [initialDrivers, setInitialDrivers] = useState([]);
    const changeSummaryIndex = _findIndex(steps, ({ path }) => path === '/change-summary');
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const policyState = _get(renewalVM, 'baseData.policyAddress.state.value.code');
    const { opCo } = useContext(AmfamOktaTokenContext);
    const {
        initialValidation,
        isComponentValid,
        disregardFieldValidation,
        onValidate,
        registerInitialComponentValidation
    } = useValidation('InsuredDetailsPage');

    const {
        getLocationAndProducerCodes,
        getProducerDetailsFromProducerCodes,
        getProducerDetailsFromExternalId
    } = useNewBusinessUtil(
        renewalVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        setLocationAndProducerCodes
    );

    // Add assignment page if the state is enabled
    const adjustStepsForState = useCallback(async () => {
        // check for enabled state
        const enabledStates = appConfig.driverAssignmentStates;
        const nextSteps = [...steps];
        const driverAssignmentStepIndex = nextSteps.findIndex((step) => step.path === '/assignment');

        // not needed for state and does not exist in steps already
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex < 0) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // not needed for the state, but previous state selected needed it
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.splice(driverAssignmentStepIndex, 1); // remove
            nextSteps.shift(); // remove current page (always first elt)
            changeNextSteps(nextSteps);

            return nextSteps;
        }

        // needed for the state, and already has it
        if (enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // needed for the state and not already in the steps
        const driverAssignmentPage = {
            id: 'EARenewalDriverAssignmentPage',
            title: {
                id: 'quoteandbind.ea.wizard.step.Drivers Assignment',
                defaultMessage: 'Driver Assignment'
            },
            path: '/assignment',
            component: 'EARenewalDriverAssignmentPage',
            stepProps: {
                template: 'WizardPageTemplateWithTitle'
            }
        };
        const indexOfVehiclePage = nextSteps.findIndex((step) => step.path === '/risk-analysis');

        // insert driver assignment
        nextSteps.splice(indexOfVehiclePage, 0, driverAssignmentPage)
        // remove current page (always first elt)
        nextSteps.shift();
        changeNextSteps(nextSteps);

        return nextSteps;
    }, [changeNextSteps, policyState, steps]);

    useEffect(() => {
        // change steps before jumping
        adjustStepsForState().then((newSteps) => {
            // need all steps
            newSteps.unshift(currentStep);

            //   keeping the IF condition here, but under no circumstances should it be undefined
            if (renewalVM.baseData.externalID_Ext.value) {
                (async () => {
                    const details = await getProducerDetailsFromExternalId(
                        renewalVM.baseData.externalID_Ext.value
                    );

                    setProducerCodeDetails(details);
                })();
            }

            // we want to make each step as visited so that user
            // can navigate to any page by clicking on tabs on chevron
            if (isPageJumpEnabled) {
                updateIsPageJumpEnabled(false);

                const coveragesIndex = _findIndex(newSteps, ({ path }) => path === '/coverages');

                jumpTo(coveragesIndex, true);
            }
        });
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(renewalVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(renewalVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    useEffect(() => {
        // get available producer codes for given state
        if (policyState && isAgent) {
            // only for agents
            getAndSetLocationCodes(policyState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initialRequiredFields = ['applicantsResideInSameStateInd', 'isUmbrellaAssociated']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
    }, [policyState]);


    const isProducerCodeDisabled = useMemo(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(renewalVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(renewalVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);

            return true;
        }

        return false;
    }, [locationAndProducerCodes, renewalVM]);

    useEffect(() => {
        const {
            producerCode
        } = producerCodeDetails;
        const existingProducerCode = _get(
            renewalVM,
            'value.baseData.producerCode_Ext',
            renewalVM.value.baseData.producerCode
        );

        if (existingProducerCode && isAgent
            && existingProducerCode !== producerCode) {
            (async () => {
                // only for agents
                const details = await getProducerDetailsFromProducerCodes(
                    renewalVM.value.baseData.producerCode_Ext,
                    locationAndProducerCodes.producerCodes,
                    renewalVM.value.baseData.externalID_Ext
                );

                // in case we dont get any response from service
                if (details) {
                    setProducerCodeDetails(details);
                    setAgencyName(details.agencyName);
                    setBookTransferIndicator(details.bookTransferIndicator);
                    setServiceCenterIndicator(details.serviceCenterIndicatorValue);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renewalVM.baseData.producerCode_Ext.value]);

    const locationCodeChange = useCallback(
        (value) => {
            _set(renewalVM, 'baseData.externalID_Ext', value);

            const selectedProducerCode = locationAndProducerCodes?.producerCodes
                ?.find((producerCode) => producerCode?.externalId_Ext === value);

            _set(renewalVM, 'baseData.producerCode_Ext', selectedProducerCode?.code);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData, locationAndProducerCodes]
    );

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, address, phone, externalID, name, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            _set(renewalVM, 'baseData.value.producerCode_Ext', producerCode);
            _set(renewalVM, 'baseData.externalID_Ext.value', externalID);
            updateWizardData(renewalVM);
            setProducerCodeDetails({ producerCode, address, phone });
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [renewalVM, updateWizardData],
    );

    useEffect(() => {
        const existingDrivers = _cloneDeep(_get(renewalVM.value, 'lobData.personalAuto_EA.coverables.drivers'));

        setInitialDrivers(existingDrivers);
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasTransactionStarted = useCallback(() => !!_get(renewalVM, 'quoteID.value'), [renewalVM]);

    useEffect(() => {
        registerInitialComponentValidation(hasTransactionStarted);
    }, [hasTransactionStarted, registerInitialComponentValidation]);

    const { createSecondaryNamedInsuredVM, removeSni, addSniInCoverable } = useSniUtil(
        renewalVM,
        updateWizardData,
        viewModelService,
        LOB,
        disregardFieldValidation
    );

    const addNewPrimaryNamedInsured = useCallback(() => {
        const primaryNamedInsuredObj = {
            person: {
                lastName: '',
                firstName: '',
                dateOfBirth: {
                    year: undefined,
                    month: undefined,
                    day: undefined
                },
                // save primary address
                primaryAddress: (() => {
                    // default new person address to prior pni
                    const priorPniAddress = _get(
                        renewalVM,
                        'baseData.policyAddress.value'
                    );

                    // Needs new public ID to make it a new adress in the DB
                    _set(priorPniAddress, 'publicID', undefined);

                    return priorPniAddress;
                })()
            }
        };

        const { _dtoName, _xCenter } = renewalVM.lobData[LOB].primaryNamedInsured;
        const primaryNamedInsuredVM = viewModelService.create(
            primaryNamedInsuredObj,
            _xCenter,
            _dtoName
        );

        _set(
            renewalVM,
            `lobData[${LOB}].primaryNamedInsured.value`,
            primaryNamedInsuredVM.value
        );
        updateWizardData(renewalVM);
    }, [renewalVM, updateWizardData, viewModelService]);

    const syncNamedInsuredToDrivers = useCallback(() => {
        //   sync NI changes to Drivers
        const drivers = _get(renewalVM.value, 'lobData.personalAuto_EA.coverables.drivers');
        const pni = _get(renewalVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
        const sni = _get(renewalVM.value, 'lobData.personalAuto_EA.secondaryNamedInsured');
        const pniDriver = drivers.find((driver) => driver.person.publicID === pni.person.publicID);
        //   SNI may be undefined so being null safe there
        const sniDriver = drivers.find((driver) => driver.person.publicID === sni?.person?.publicID);

        //   updates to existing pni contact
        if (pniDriver && pni) {
            _set(pniDriver, 'person', { ...pniDriver.person, ...pni.person });
        }

        if (pni && !pniDriver) {
            //   check if PNI is already present in existing drivers
            //   if present then add in the drivers, but do not create new here, that is handled in driver page
            if (!_isEmpty(initialDrivers)) {
                const initialPniDriver = initialDrivers
                    .find((driver) => driver.person.publicID === pni?.person?.publicID);

                if (initialPniDriver) {
                    _set(initialPniDriver, 'person', pni.person);
                    _set(initialPniDriver, 'relationshipToNI', undefined);
                    drivers.push(initialPniDriver);

                    _set(renewalVM.value, 'lobData.personalAuto_EA.coverables.drivers', drivers);
                }
            }
        }

        //   updates to existing sni contact
        if (sniDriver && sni) {
            _set(sniDriver, 'person', { ...sniDriver.person, ...sni.person });
        }

        if (sni && !sniDriver) {
            //   check if SNI is already present in existing drivers
            //   if present then add in the drivers, but do not create new here, that is handled in driver page
            if (!_isEmpty(initialDrivers)) {
                const initialSniDriver = initialDrivers
                    .find((driver) => driver.person.publicID === sni?.person?.publicID);

                if (initialSniDriver) {
                    _set(initialSniDriver, 'person', sni.person);
                    _set(initialSniDriver, 'relationshipToNI', sni?.relationshipToNI);
                    drivers.push(initialSniDriver);

                    _set(renewalVM.value, 'lobData.personalAuto_EA.coverables.drivers', drivers);
                }
            }
        }
    }, [initialDrivers, renewalVM.value]);

    const setCorrectAddressValues = useCallback(
        () => {
            // if mailingAddressSameInd is true then set policyAddress value to mailingAddress
            if (
                _get(
                    renewalVM,
                    'baseData.isMailingAddressSame_Ext.value'
                )
            ) {
                _set(
                    renewalVM,
                    'baseData.policyMailingAddress_Ext.value',
                    _get(renewalVM, 'baseData.policyAddress.value')
                );
            }

            //  if billingAddressSameInd is true then set mailingAddress value to billingAddress
            if (
                _get(
                    renewalVM,
                    'baseData.isBillingAddressSame_Ext.value'
                )
            ) {
                _set(
                    renewalVM,
                    'baseData.policyBillingAddress_Ext.value',
                    _get(renewalVM, 'baseData.policyMailingAddress_Ext.value')
                );
            }

            // if isGaragingAddressSame_Ext is true on vehicle, then set policy address in garaging location
            if (!_isEmpty(
                _get(renewalVM, 'lobData.personalAuto_EA.coverables.vehicles.value')
            )) {
                renewalVM.lobData.personalAuto_EA.coverables.vehicles.children.forEach((vehicle) => {
                    if (_get(vehicle, 'isGaragingAddressSame_Ext.value')) {
                        _set(vehicle, 'garagingLocation.value', _get(renewalVM, 'baseData.policyAddress.value'));
                    }
                });
            }
        }, [renewalVM]
    );

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);
            setCorrectAddressValues();
            //   Need this for validation rules
            _set(renewalVM, 'flowStepIDs_Ext.value', ['namedinsured']);
            _set(renewalVM, 'entryCompletionStepIDs_Ext.value', ['namedinsured']);
            syncNamedInsuredToDrivers();

            const saveResponse = await RenewalService.saveRenewal(
                [renewalVM.value],
                authHeader
            );

            _set(renewalVM, 'value', saveResponse);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);
            //   sni exist or not is being checked in the method itself, so no need to check here
            addSniInCoverable();

            return renewalVM;
        },
        [addSniInCoverable, authHeader, isComponentValid, renewalVM, syncNamedInsuredToDrivers, updateWizardData, setCorrectAddressValues]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = _get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    /**
                    * E1PAP1PC-14986 :
                    * wizardData and wizardSnapshot not being equal due to
                    * some defaulting on each page so doing this temp fix
                    */
                    if (renewalVM.baseData.value.producerCode) {
                        _set(renewalVM,
                            'baseData.value.producerCode_Ext',
                            renewalVM.baseData.value.producerCode);
                    }

                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    const handleAddressValueChange = useCallback(
        (newVal, localPath) => {
            _set(renewalVM, localPath, newVal);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData]
    );

    const generateOverrides = useCallback(() => {
        const overrides = {};

        overrides.primaryNamedInsured = {
            isPNI: true
        };

        return overrides;
    }, []);

    const swapPniSni = useCallback(() => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: e1pCommonMessages.swapPniSniTitle,
            message: e1pCommonMessages.swapPniSniMessage,
            confirmButtonText: e1pCommonMessages.confirm,
            cancelButtonText: e1pCommonMessages.cancel
        }).then(
            (result) => {
                if (result !== 'cancel') {
                    const pniPerson = _cloneDeep(
                        renewalVM.lobData[LOB].primaryNamedInsured.person.value
                    );
                    const sniPerson = _cloneDeep(
                        renewalVM.lobData[LOB].secondaryNamedInsured.person.value
                    );
                    let drivers = _get(renewalVM.value, `lobData[${LOB}].coverables.drivers`);
                    let sniDriver = drivers
                        .find((driver) => driver.person.publicID === sniPerson?.publicID);

                    if (!sniDriver) {
                        addSniInCoverable();
                        drivers = _get(renewalVM.value, `lobData[${LOB}].coverables.drivers`);
                        sniDriver = drivers
                            .find((occupant) => occupant.person.publicID === sniPerson.publicID);
                        //   setting driver role for SNI to operator, since sni is newly added.
                        _set(sniDriver, 'policyDriverRoleType', 'operator');
                    }

                    const pniDriver = drivers
                        .find((occupant) => occupant.person.publicID === pniPerson.publicID);

                    const relationshipToNI = _get(renewalVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`, 'notrelated');

                    _set(pniDriver, 'relationshipToNI', relationshipToNI);
                    _set(sniDriver, 'relationshipToNI', undefined);
                    //   now swap PNI and SNI
                    renewalVM.lobData[LOB].primaryNamedInsured.person.value = sniPerson;
                    renewalVM.lobData[LOB].secondaryNamedInsured.person.value = pniPerson;
                    //   since we are swapping PNI and SNI, we need remove publicID, FixedID and integrationID from both policyContactRoles
                    //   i.e PNI and SNI
                    _set(renewalVM, `lobData[${LOB}].primaryNamedInsured.fixedId.value`, undefined);
                    _set(renewalVM, `lobData[${LOB}].primaryNamedInsured.publicID.value`, undefined);
                    _set(renewalVM, `lobData[${LOB}].primaryNamedInsured.integrationId.value`, undefined);
                    _set(renewalVM, `lobData[${LOB}].secondaryNamedInsured.fixedId.value`, undefined);
                    _set(renewalVM, `lobData[${LOB}].secondaryNamedInsured.publicID.value`, undefined);
                    _set(renewalVM, `lobData[${LOB}].secondaryNamedInsured.integrationId.value`, undefined);

                    // If paperless Email id exist and pni email id is undefiend
                    // then update pni email to paperless email
                    if (!_get(renewalVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value')
                        && !!_get(renewalVM, 'lobData.personalAuto_EA.paperlessEmail.value')) {
                        _set(renewalVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value',
                            _get(renewalVM, 'lobData.personalAuto_EA.paperlessEmail.value'));
                    }

                    updateWizardData(renewalVM);
                }
            },
            () => { }
        );
    }, [addSniInCoverable, modalApi, renewalVM, updateWizardData]);

    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            _set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setIsSavingRenewal(false);

            return false;
        },
        [
            authHeader, changeSummaryIndex, isComponentValid, jumpTo,
            renewalVM, updateWizardData, updateWizardSnapshot
        ]
    );

    const getLoadingMessage = useCallback(() => {
        let loadingMessage = translator(messages.loadingNextPageMessage);

        if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isStandardizingAddress) {
            loadingMessage = translator(messages.standardizingAddressMessage);
        }

        return loadingMessage;
    }, [isSavingCurrentPageChanges, isStandardizingAddress, translator]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false,
            showRequired: true
        },
        insuredDetailsPageLoadingIndicator: {
            loaded: !isSavingRenewal && !isSkipping
                && !isStandardizingAddress && !isSavingCurrentPageChanges,
            text: getLoadingMessage()
        },
        excessLiabilityInd: {
            required: true,
            visible: _includes(requiredFields, 'isUmbrellaAssociated'),
        },
        insuredDetailsPageContainer: {
            visible: !isSavingRenewal && !isSkipping
                && !isStandardizingAddress && !isSavingCurrentPageChanges
        },
        mailingAndBillingAddressComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            showErrors: isPageSubmitted,
            wizardSnapshot
        },
        addSni: {
            visible: !_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        swapPniSni: {
            visible: !!_get(renewalVM, `value.lobData[${LOB}].secondaryNamedInsured.person.publicID`)
                && !!_get(renewalVM, `value.lobData[${LOB}].secondaryNamedInsured.relationshipToNI`)
        },
        sniContainer: {
            visible: !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        removeSni: {
            visible: !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        periodStartDate: {
            dateDTO: renewalVM.baseData.periodStartDate,
            updateDateDto: () => updateWizardData(renewalVM),
            disabled: true,
        },
        periodEndDate: {
            dateDTO: renewalVM.baseData.periodEndDate,
            updateDateDto: () => updateWizardData(renewalVM)
        },
        policyInformationInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        dateOfBirth: {
            updateDateDto: () => updateWizardData(renewalVM),
            dateDTO: renewalVM.lobData.personalAuto_EA.primaryNamedInsured.person.dateOfBirth,
        },
        insuredResidenceAddress: {
            addressVM: _get(renewalVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate,
            onAddressChange: (value, path) => handleAddressValueChange(value, `baseData.policyAddress.${path}`),
            viewOnlyMode: true,
            showParentLoader: setIsStandardizingAddress
        },
        changePniComponent: {
            accountNumber: _get(renewalVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(renewalVM, 'baseData.accountNumber.value'),
            allowNewContact: false,
            excludedContacts: [
                _get(renewalVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            drivers: _get(
                renewalVM.value,
                `lobData[${LOB}].coverables.drivers`,
                undefined
            )
        },
        changeSniComponent: {
            accountNumber: _get(renewalVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: !!_get(renewalVM, 'baseData.accountNumber.value')
                && !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            excludedContacts: [
                _get(renewalVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            drivers: _get(
                renewalVM.value,
                `lobData[${LOB}].coverables.drivers`,
                undefined
            )
        },
        internalProducerDetailsComponent: {
            defaultValue: renewalVM.baseData.externalID_Ext?.value,
            visible: !isAgent
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            disabled: isProducerCodeDisabled,
            onValueChange: locationCodeChange,
            visible: isAgent
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        applicantsResideInSameStateInd: {
            required: true,
            visible: _includes(requiredFields, 'applicantsResideInSameStateInd'),
        },
        agencyInformationMainDiv: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: renewalVM,
            authHeader,
            LOB: 'personalAuto_EA'
        },
        renewalTermEffectiveDate: {
            dateDTO: renewalVM.baseData.effectiveDate,
            updateDateDto: () => {},
            readOnly: true,
            showErrors: isPageSubmitted,
            onValidate
        },
        // sourceType: {
        //     availableValues: renewalVM.baseData.quoteSource_Ext.sourceType.aspects.availableValues.map(
        //         (item) => {
        //             return {
        //                 code: item.code,
        //                 name: translator({ id: item.name })
        //             };
        //         }
        //     )
        // },
        // riskState: {
        //     availableValues: renewalVM.baseData.policyAddress.state.aspects.availableValues.map(
        //         (item) => {
        //             return {
        //                 code: item.code,
        //                 name: translator({ id: item.name })
        //             };
        //         }
        //     )
        // },
        // policyType: {
        //     availableValues: renewalVM.lobData.personalAuto_EA.policyType.aspects.availableValues.map(
        //         (item) => {
        //             return {
        //                 code: item.code,
        //                 name: translator({ id: item.name })
        //             };
        //         }
        //     )
        // },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onValidate,
            onRemoveSni: removeSni,
            onAddNewPni: addNewPrimaryNamedInsured,
            onSwapPniSni: swapPniSni,
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showRenewalPrevious={false}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            showPrevious={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

InsuredDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(InsuredDetailsPage);
