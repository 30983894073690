import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '@jutro/layout';
import { useModal } from '@jutro/components';
import { get as _get, isEmpty as _isEmpty } from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import {PersonalInjuryProtectionComponent} from 'e1p-capability-policyjob-react';
import EASingleClauseComponentVM from '../Clauses/SingleClauseComponentVM';
import messages from './EAPolicyLevelCoveragesComponent.messages';
import styles from './EAPolicyLevelCoveragesComponent.module.scss';

import { Link } from '@jutro/router';

function EAPolicyLevelCoveragesComponent(props) {
    const modalApi = useModal();
    const {
        lobOfferings,
        onChangeSubmissionAndSync,
        onChangeSubmission,
        onSyncCoverages,
        onValidate,
        hasExtendedAttributeEditability,
        policyState,
        isQuoteStale,
        lobIndex,
        quoteIndex,
        viewOnlyMode,
        showErrors,
        updatingVehicleLevelCoverages,
        setUpdatingLineLevelCoverages
    } = props;
    const translator = useTranslator();
    const [isLoading, setIsLoading] = useState(undefined);
    const id = 'EAPolicyLevelCoveragesComponent';
    const { isComponentValid, onValidate: setIsComponentValid } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, lobOfferings, onValidate, isComponentValid]);

    useEffect(() => {
        setIsLoading(updatingVehicleLevelCoverages);
    }, [updatingVehicleLevelCoverages]);

    /** Callback from main quote page */
    const syncCoverages = useCallback((value, path, lobPath, quotePath, clauseID) => {
        setIsLoading(true);
        setUpdatingLineLevelCoverages(true);

        if (onSyncCoverages) {
            onSyncCoverages(value, path, lobPath, quotePath).then(() => {
                setIsLoading(undefined);
                setUpdatingLineLevelCoverages(undefined);
            });
        }
    }, [onSyncCoverages, setUpdatingLineLevelCoverages]);

    const personalInjuryHandler = async () => {
        const componentProps = {
            lob: 'PersonalAuto_EA'
        };
        const result = await modalApi.showModal(
            <PersonalInjuryProtectionComponent {...componentProps} />
        );

        return result;
    };

    /** Callback from main quote page */
    const changeSubmissionAndSync = useCallback((value, path, lobPath, quotePath, clauseID) => {
        setIsLoading(true);
        setUpdatingLineLevelCoverages(true);

        if (onChangeSubmissionAndSync) {
            return Promise.resolve(onChangeSubmissionAndSync(value, path, lobPath, quotePath).then(() => {
                setIsLoading(undefined);
                setUpdatingLineLevelCoverages(undefined);
            }));
        }
    }, [onChangeSubmissionAndSync, setUpdatingLineLevelCoverages]);

    const columnConfig = ['210px', '320px'];
    const cslCoverageCodes = ['EA_CombinedSingleLimitCoverageBundle', 'EA_SplitLimitCoverageBundle'];
    // Line Level non hidden coverages without CSL /Split coverages
    const lineCovs = _get(lobOfferings[lobIndex], 'coverages.lineCoverages', [])
        .filter((lineCoverage) =>
            !lineCoverage.isHiddenInVersion
            && !cslCoverageCodes.includes(lineCoverage.codeIdentifier)
        );

    const rowHeaders = [...lineCovs];
    const ROWS = rowHeaders.length;
    const numberOfCells = 2 * ROWS;
    const tableContent = [];

    for (let cell = 0; cell <= numberOfCells - 1; cell++) {
        const rowNumber = Math.floor(cell / 2) + 1;
        const path = `lobData.personalAuto_EA.offerings.children[${lobIndex}].coverages.lineCoverages`;

        // first column - render row headers
        if (cell === 0 || (cell % 2 === 0)) {
            const covHeader = lineCovs[rowNumber - 1];
            const clause = lobOfferings[lobIndex].coverages.lineCoverages.find(
                (coverage) => coverage.publicID === covHeader.publicID
            );

            if (clause.codeIdentifier === 'EA_PIPCovUT') {
                tableContent.push
                (<Link
                    to="/"
                    onClick={(e) => {
                        e.preventDefault();
                        personalInjuryHandler();
                    }}
                    className={styles.personalInjuryLink}>
                    {rowHeaders.shift().name}
                </Link>)
            } else {
                tableContent.push
                (<GridItem
                    className={rowNumber === ROWS ? styles.qDetailCellBottomLeftHeader : styles.qDetailCellLeftHeader}
                    tag="div" key={`lineLevelGridItem${cell}`}>
                    <span key={`lineLevelCell${cell}`}>
                        {rowHeaders.shift().name}
                    </span>
                </GridItem>)
            }
        } else {
            const covHeader = lineCovs[rowNumber - 1];
            const clause = lobOfferings[lobIndex].coverages.lineCoverages.find(
                (coverage) => coverage.publicID === covHeader.publicID
            );
            const clauseIndex = lobOfferings[lobIndex].coverages.lineCoverages.findIndex(
                (coverage) => coverage.publicID === covHeader.publicID
            );

            if (clause) {
                let key = clause.publicID;
                if (!_isEmpty(clause.terms)) {
                    /**
                     * validation does not work properly when we have cross coverage dependency(when we update one coverage PC sets default value to other coverage)
                     * to avoid validation issues regenerating key so that component will remount again
                     */
                    key = key + _get(clause,'terms[0].options',[]).length;
                }
                tableContent.push
                (<GridItem
                    tag="div" key={`quotecell${cell}`}
                    className={rowNumber === ROWS ? styles.qDetailCellBottom : styles.qDetailCell}>
                    <EASingleClauseComponentVM
                        key={key}
                        value={clause}
                        path={`${path}.children[${clauseIndex}]`}
                        onChangeClause={onChangeSubmission}
                        onSyncCoverages={(value, termPath) => {
                            syncCoverages(
                                value, termPath, `lobData.personalAuto_EA.offerings.children[${lobIndex}]`,
                                `quoteData.offeredQuotes.children[${quoteIndex}]`, clause.publicID
                            )
                        }}
                        onChangeSubmissionAndSync={(value, termPath) => {
                            changeSubmissionAndSync(
                                value, termPath, `lobData.personalAuto_EA.offerings.children[${lobIndex}]`,
                                `quoteData.offeredQuotes.children[${quoteIndex}]`, clause.publicID
                            )
                        }}
                        loadingClause={isLoading}
                        idPrefex={clause.publicID}
                        labelTop
                        onValidate={setIsComponentValid}
                        isQuoteStale={isQuoteStale}
                        hasExtendedAttributeEditability={hasExtendedAttributeEditability}
                        policyState={policyState}
                        viewOnlyMode={viewOnlyMode}
                        showErrors={showErrors}

                    />
                </GridItem>)
            } else {
                tableContent.push(
                    <GridItem
                        tag="div" key={`quotecell${cell}`}
                        className={rowNumber === ROWS ? styles.qDetailCellBottom : styles.qDetailCell}>
                        <span className={styles.noCoverageText}>{translator(messages.notApplicable)}</span>
                    </GridItem>
                )
            }

        }
    }

    return (
        <div className="mt-4">
            <Grid columns={columnConfig} gap="none">
                {tableContent}
            </Grid>
        </div>
    );
}

EAPolicyLevelCoveragesComponent.propTypes = {
    lobOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    policyState: PropTypes.string.isRequired,
    hasExtendedAttributeEditability: PropTypes.bool,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    updatingVehicleLevelCoverages: PropTypes.bool,
    setUpdatingLineLevelCoverages: PropTypes.func
};

EAPolicyLevelCoveragesComponent.defaultProps = {
    showErrors: false,
    hasExtendedAttributeEditability: undefined,
    onValidate: undefined,
    updatingVehicleLevelCoverages: false,
    setUpdatingLineLevelCoverages: () => {}
}

export default EAPolicyLevelCoveragesComponent;
