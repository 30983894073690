import { defineMessages } from 'react-intl';

export default defineMessages({
    eaPriorCarrierTitle: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Prior Carrier',
        defaultMessage: 'Prior Carrier'
    },
    eaPriorCarrierAdd: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.ADD PRIOR CARRIER',
        defaultMessage: 'ADD PRIOR CARRIER'
    },
    removePriorPolicyTitle: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.removePriorPolicyTitle',
        defaultMessage: 'Remove Prior Carrier?'
    },
    removePriorPolicyDescription: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.The selected prior carrier will be removed.',
        defaultMessage: 'The selected prior carrier will be removed.'
    },
    removeCarrierLabel: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.removeCarrierLabel',
        defaultMessage: 'REMOVE CARRIER'
    },
    primaryNamedInsuredTitle: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Primary Named Insured:',
        defaultMessage: 'Primary Named Insured:'
    },
    priorPolicyCarrierNameLabel: {
        id:
      'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Current/Prior Carrier Name',
        defaultMessage: 'Current/Prior Carrier Name'
    },
    priorPolicyPreviousPolicyLabel: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Previous Policy #',
        defaultMessage: 'Previous Policy #'
    },
    priorPolicyInceptionDateLabel: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Inception Date',
        defaultMessage: 'Inception Date'
    },
    priorPolicyLastDateOfCoverageLabel: {
        id:
      'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Last Date of Coverage',
        defaultMessage: 'Last Date of Coverage'
    },
    priorPolicyYearsWithCompanyLabel: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Years w/ company',
        defaultMessage: 'Years w/ company'
    },
    priorPolicyBILimitsLabel: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.BI Limits',
        defaultMessage: 'BI Limits'
    },
    priorPolicyCoverageLapseLabel: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Coverage Lapse',
        defaultMessage: 'Coverage Lapse'
    },
    collisionCoverage: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Collision Coverage',
        defaultMessage: 'Collision Coverage'
    },
    comprehensiveCoverage: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Comprehensive Coverage',
        defaultMessage: 'Comprehensive Coverage'
    },
    BILimitPP: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.BI Limit(PP)',
        defaultMessage: 'BI Limit(PP)'
    },
    BILimitPO: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.BI Limit(PO)',
        defaultMessage: 'BI Limit(PO)'
    },
    priorPolicyRole: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Policy Role',
        defaultMessage: 'Policy Role'
    },
    pniLabel: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.pniLabel',
        defaultMessage: 'Primary Named Insured: '
    },
    sniLabel: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.sniLabel',
        defaultMessage: 'Secondary Named Insured: '
    },
    removeAllPriorCarriersTitle: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Remove All Prior Carriers?',
        defaultMessage: 'Remove All Prior Carriers?'
    },
    removeAllPriorCarriersMessage: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.Please note that this action will remove all entered prior carriers.',
        defaultMessage: 'Please note that this action will remove all entered prior carriers.'
    },
    thisInsuredCurrentlyHasCoverageInForce: {
        id: 'policyjob.components.prior-policy-manual-grid.ea-underwriting-details.This Insured currently has coverage inforce',
        defaultMessage: 'This Insured currently has coverage inforce'
    }
});
