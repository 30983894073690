import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { isRequired as isAvailable } from 'e1p-portals-required-validator-js';
import metadata from './PremiumComponent.metadata.json5';
import requiredAvailableMetadata from './PremiumComponent.requirednessAndAvailability';

const lobMap = {
    'Homeowners_EH': 'homeowners_EH',
    'PersonalAuto_EA': 'personalAuto_EA',
    'PersonalUmbrella_EU': 'personalUmbrella_EU'
};


function PremiumComponent(props) {
    const {
        transactionVM,
        updateWizardData,
        onValidate,
        viewOnlyMode,
        showErrors
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const [requiredAvailableFields, updateRequiredAvailableFields] = useState([]);
    const { authUserData } = useAuthentication();
    const productCode = _.get(transactionVM,
        'baseData.productCode.value',
        _.get(transactionVM, 'productCode.value'));
    const policyState = _.get(transactionVM,
        'baseData.policyAddress.state.value.code',
        _.get(transactionVM, 'policyAddress.state.value.code'));
    const [lobDataModel, setLobDataModel] = useState(_.get(transactionVM, `lobData[${lobMap[productCode]}]`));
    const canAddEditPremiumAdjustment = authUserData.permissions_Ext.includes('editpremiumadjustment_ext');
    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _.get(transactionVM, `lobData[${lobMap[productCode]}].premiumStability.value`) !== undefined;


    useEffect(() => {
        setLobDataModel(_.get(transactionVM, `lobData[${lobMap[productCode]}]`));

        const initialRequiredAvailableFields = [
            'ratingPlans'
        ]; // Fields to look up by partner/state

        updateRequiredAvailableFields(
            isAvailable(initialRequiredAvailableFields, requiredAvailableMetadata, policyState, 'MSA')
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    /**
     * Define property overrides for this Jutro component.
     * */
    const overrideProps = {
        '@field': {
            visible: true,
            readOnly: true,
            autoComplete: false
        },
        premiumStabilizationContainer: {
            visible: isPremiumStabilizationTabVisible
        },
        premiumStabilizationTable: {
            columns: productCode === 'Homeowners_EH' || productCode === 'PersonalUmbrella_EU' ? ['1fr', '1fr', '1fr'] : ['1fr', '1fr']
        },
        premiumStabilizationPolicyLimitingFactor: {
            visible: productCode === 'Homeowners_EH' || productCode === 'PersonalUmbrella_EU'
        },
        policyLimitingFactorValue: {
            visible: productCode === 'Homeowners_EH' || productCode === 'PersonalUmbrella_EU'
        },
        e1PPremiumAdjustmentTableComponent: {
            transactionVM,
            lob: productCode,
            updateWizardData,
            onValidate,
            viewModelService,
            viewOnlyMode: !canAddEditPremiumAdjustment || viewOnlyMode,
            showErrors,
            visible: canViewPremiumAdjustment
                && !!_.get(transactionVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value')
        },
        ratingPlan: {
            transactionVM,
            lob: lobMap[productCode],
            visible: productCode === 'Homeowners_EH'
                && requiredAvailableFields.includes('ratingPlans')
                && authUserData.permissions_Ext.includes("viewrateplans_ext")
        },
        noPremiumMessageId: {
            visible: (() => 
                // If premium tab content is empty 
                 !_.get(document.getElementById('premiumContainer'), 'innerText') && !_.get(document.getElementById('ratingPlan'), 'innerText')
            )()
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            model={lobDataModel}
        />
    );
}

PremiumComponent.propTypes = {
    transactionVM: PropTypes.shape({
    }).isRequired,
    updateWizardData: PropTypes.func,
    onValidate: PropTypes.func,
    viewOnlyMode: PropTypes.bool,
    showErrors: PropTypes.bool
};

PremiumComponent.defaultProps = {
    updateWizardData: undefined,
    onValidate: undefined,
    viewOnlyMode: false,
    showErrors: false
}

export default PremiumComponent;
