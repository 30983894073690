import { defineMessages } from 'react-intl';

export default defineMessages({
    applyingChangesMessage: {
        id: 'quoteandbind.common.views.payment-details.Applying changes...',
        defaultMessage: 'Applying changes...'
    },
    completingYourPurchaseMessage: {
        id: 'quoteandbind.common.views.payment-details.Completing your purchase...',
        defaultMessage: 'Completing your purchase...'
    },
    removeTPITitle: {
        id: 'quoteandbind.common.views.payment-details.tpi.Remove Loss?',
        defaultMessage: 'Remove TPI?'
    },
    removeTPIDescription: {
        id: 'quoteandbind.common.views.payment-details.tpi.message',
        defaultMessage: 'Are you sure you want to remove this TPI?'
    },
    confirmOk: {
        id: 'quoteandbind.common.views.payment-details.tpi.Yes',
        defaultMessage: 'Yes'
    },
    consentToRateAcknowledgementMessage: {
        id: 'quoteandbind.common.views.payment-details.In accordance with G.S. 58-36-30(B1), your policy is currently being rated under North Carolina Consent to Rate regulations. The premium using North Carolina Bureau\'s rates for Homeowners insurance coverage applied for is $ {NCRBPremium}. Our premium for this coverage is $ {CTRPremium}.',
        defaultMessage: `In accordance with G.S. 58-36-30(B1), your policy is currently being rated under North Carolina Consent to Rate regulations. The premium using North Carolina Bureau\'s rates for Homeowners insurance coverage applied for is \${NCRBPremium}. Our premium for this coverage is \${CTRPremium}.`
    },
    cancel: {
        id: 'quoteandbind.common.views.payment-details.tpi.No',
        defaultMessage: 'No'
    },
    signingAgent: {
        id: 'quoteandbind.common.views.payment-details.Signing Agent',
        defaultMessage: 'Signing Agent'
    },
    signatureType: {
        id: 'quoteandbind.common.views.payment-details.Signature Type',
        defaultMessage: 'Signature Type'
    },
    chooseSignature: {
        id: 'quoteandbind.common.views.payment-details.Choose-Signature',
        defaultMessage: '--Choose a Signature Type--'
    },
    locationCode: {
        id: 'quoteandbind.common.views.payment-details.Location Code',
        defaultMessage: 'Location Code'
    },
    locationCodeLabel: {
        id: 'quoteandbind.common.views.payment-details.Location Code',
        defaultMessage: 'Location Code'
    },
    SigningAgent: {
        id: 'quoteandbind.common.views.payment-details.Signing Agent',
        defaultMessage: 'Signing Agent'
    },
    paymentBuyNow: {
        id: 'quoteandbind.e1p.eh.views.payment-details.Buy Now',
        defaultMessage: 'Buy Now'
    },
    issueLabel: {
        id: 'quoteandbind.e1p.eh.views.payment-details.issueLabel',
        defaultMessage: 'Issue'
    },
    policyTerm: {
        id: 'quoteandbind.common.views.payment-details.Policy Term',
        defaultMessage: 'Policy Term'
    },
    twelvemonths: {
        id: 'quoteandbind.common.views.payment-details.12 Months',
        defaultMessage: '12 Months'
    },
    insuredContactTitle: {
        id: 'quoteandbind.common.views.payment-details.insuredContactTitle',
        defaultMessage: 'Insured Contact'
    },
    homePhone: {
        id: 'quoteandbind.common.views.payment-details.Home Phone',
        defaultMessage: 'Home Phone'
    },
    invalidPhone: {
        id: 'quoteandbind.common.views.payment-details.Value entered must be a valid phone number',
        defaultMessage: 'Value entered must be a valid phone number'
    },
    homeTooltip: {
        id: 'quoteandbind.common.views.payment-details.Home Phone.tooltip',
        defaultMessage: 'Home or Mobile Phone Required for Interior Inspection'
    },
    mobilePhone: {
        id: 'quoteandbind.common.views.payment-details.Mobile Phone',
        defaultMessage: 'Mobile Phone'
    },
    invalidMobilePhone: {
        id: 'quoteandbind.common.views.payment-details.Mobile.Value entered must be a valid phone number',
        defaultMessage: 'Value entered must be a valid phone number'
    },
    mobileTooltip: {
        id: 'quoteandbind.common.views.payment-details.Mobile Phone.tooltip',
        defaultMessage: 'Home or Mobile Phone Required for Interior Inspection'
    },
    email: {
        id: 'quoteandbind.common.views.payment-details.Email',
        defaultMessage: 'Email'
    },
    signature: {
        id: 'quoteandbind.common.views.payment-details.signature',
        defaultMessage: 'Signature'
    },
    additionalInformation: {
        id: 'quoteandbind.common.views.payment-details.Additional Information',
        defaultMessage: 'Additional Information'
    },
    unableToBindTitle: {
        id: 'quoteandbind.e1p.eh.views.payment-details.Unable to bind',
        defaultMessage: 'Unable to bind'
    },
    exitTransaction: {
        id: 'quoteandbind.common.eh.views.payment-details.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'quoteandbind.common.eh.views.payment-details.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'quoteandbind.common.eh.views.payment-details.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'quoteandbind.common.eh.views.payment-details.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'The information entered'
    }
});
