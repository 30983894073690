
import _ from 'lodash';
import { VehicleUtil } from 'e1p-portals-util-js';

/**
 * This util is used to validate certain rules for EA, based on the result we can show
 * Info and warning messages on the UI.
 * Please utilize this util to write the validation which is common accross transaction.
 */

/**
 * 
 * @param {*} transactionVM // VM for different transaction type i.e submission, policy change, rewrite etc
 * @returns {boolean} 
 * 
 * This will check if the any driver has an active filling and all the vehicles on the policy is compOnly
 * then info message is shown on the UI.
 */
function isFinancialResponsibilitySelectedWithAllCompOnlyVehicle(transactionVM) {
    const drivers = _.get(transactionVM.value, 'lobData.personalAuto_EA.coverables.drivers', []);
    const isFinancialRespSelectedOnAnyDriver = drivers.some((driver) => {
        return (_.get(driver, 'financialResponsibility.filingForm') === 'form_sr22'
        || _.get(driver, 'financialResponsibility.filingForm') === 'form_fr44') 
    && _.get(driver,'financialResponsibility.submitReason') !== 'canceled'
    && _.get(driver, 'financialResponsibility.filingInd', false);
    });
    return isFinancialRespSelectedOnAnyDriver && VehicleUtil.isEveryVehicleCompOnly(transactionVM);
}

/**
 * 
 * @param {*} transactionVM // VM for different transaction type i.e submission, policy change, rewrite etc
 * @returns {boolean} 
 * 
 * This will check if the any driver has National Guard discount is “Yes”, but Defensive Driver is “No”,
 * then info message is shown on the UI.
 */
function isNationalGuardSelectedWithoutDefensiveDriver(transactionVM) {
    const drivers = _.get(transactionVM.value, 'lobData.personalAuto_EA.coverables.drivers', []);
    const isNationalGuardWithoutDefensiveDriver = drivers.some((driver) => {
        return _.get(driver,'activeNationalGuardMemberInd', false)
    && _.get(driver, 'defensiveDriverCourseInd') === false;
    });
    return isNationalGuardWithoutDefensiveDriver;
}

export default {
    isFinancialResponsibilitySelectedWithAllCompOnlyVehicle,
    isNationalGuardSelectedWithoutDefensiveDriver
};