import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import config from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { Chevron } from '@jutro/components';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import { nullLiteral } from '@babel/types';
import QuoteTableAccordionCardIterableComponent from 'gw-components-platform-react/QuoteTableAccordionCardIterableComponent/QuoteTableAccordionCardIterableComponent';
import UnderwritingIssues from 'gw-components-platform-react/UnderwritingIssues/UnderwritingIssues';
import EUSingleClauseComponentVM from '../Clauses/SingleClauseComponentVM';
import metadata from './QuoteClauseTable.metadata.json5';
import styles from './EUQuoteClauseTable.module.scss';
import './QuoteClauseTable.messages';

const BLOCKS_QUOTES = ['BlocksQuote', 'BlocksQuoteRelease'];
const BLOCKS_BIND = 'BlocksBind';

function getChangedDataPath(changedPath, fullChangedPath, pathToChangeTo) {
    const localisedVMPath = changedPath.replace(fullChangedPath, pathToChangeTo);

    return ViewModelUtil.getNonVMPath(localisedVMPath);
}

function getChangedColumnData(changedPathOfModel, columnData) {
    // onBlur is called from Jutro with a object instead of a path
    const path = _.isString(changedPathOfModel) ? changedPathOfModel : changedPathOfModel.model;

    return columnData.find(({ lob }) => _.includes(path, lob.path));
}

function getChangedClause(path, columnData) {
    const changedObjectPath = ClausesUtil.getObjectPathFromChangedPath(path);
    const changedColumn = getChangedColumnData(path, columnData);
    const changedPath = getChangedDataPath(changedObjectPath, changedColumn.lob.path, 'lob.data');

    return _.get(changedColumn, changedPath);
}

function selectMetadata(breakpoint) {
    const contentArray = metadata.componentContent.content;
    let metadataToRender = '';

    if (breakpoint === 'phone') {
        metadataToRender = contentArray.filter((item) => item.id !== 'desktopTable');
    } else {
        metadataToRender = contentArray.filter((item) => item.id !== 'phoneTable' && item.id !== 'mobileAccordionHeader');
    }

    const filteredMetdata = {
        ...metadata,
        componentContent: {
            content: metadataToRender
        }
    };

    return filteredMetdata;
}

function getSelectedCoverages(data) {
    // only return the selected base coverages names
    const coveragesObject = _.get(data, 'lob.data.coverages');
    const coverageNames = Object.keys(coveragesObject).filter((name) => _.includes(name.toLowerCase(), 'coverages'));
    const baseCoverageName = _.first(coverageNames);
    const coverages = _.get(coveragesObject, baseCoverageName);

    return coverages.filter((cov) => cov.selected);
}

const PAYMENT_TYPES = {
    monthly: 'monthly',
    annually: 'annually'
};

const moneyPropsTypes = PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.number
});

class QuoteClauseTable extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        columnData: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string,
            quote: PropTypes.shape({
                path: PropTypes.string,
                premium: PropTypes.shape({
                    monthlyPremium: moneyPropsTypes,
                    total: moneyPropsTypes,
                })
            })
        })).isRequired,
        tableData: PropTypes.arrayOf(PropTypes.shape({
            header: PropTypes.string,
            data: PropTypes.arrayOf(PropTypes.shape({
                publicID: PropTypes.string
            })),
            tableContent: PropTypes.arrayOf(PropTypes.shape({
                path: PropTypes.string,
                clauses: PropTypes.arrayOf(PropTypes.shape({})),
                code: PropTypes.string
            }))
        })).isRequired,
        underwritingIssues: PropTypes.arrayOf(PropTypes.shape({})),
        onBuyNow: PropTypes.func,
        onRecalculate: PropTypes.func,
        onChangeSubmissionAndSync: PropTypes.func,
        onChangeSubmission: PropTypes.func,
        onSyncCoverages: PropTypes.func,
        onStaleQuoteBranchCode: PropTypes.func,
        ...validationPropTypes,
        viewOnlyMode: PropTypes.bool,
        quoteIsStale: PropTypes.bool,
        onValidate: PropTypes.func,
        onCancel: PropTypes.func,
        isPageSubmitted: PropTypes.bool,
        updateIsPageSubmitted: PropTypes.func,
        opCo: PropTypes.string.isRequired
    };

    static defaultProps = {
        onBuyNow: undefined,
        onRecalculate: undefined,
        onChangeSubmissionAndSync: undefined,
        onChangeSubmission: undefined,
        onSyncCoverages: undefined,
        onStaleQuoteBranchCode: undefined,
        underwritingIssues: [],
        viewOnlyMode: false,
        quoteIsStale: false,
        onValidate: undefined,
        onCancel: undefined,
        isPageSubmitted: false,
        updateIsPageSubmitted: () => { }
    }

    state = {
        formData: {},
        loadingClause: undefined,
        staleQuoteBranchCodes: this.getStaleCode(),
        resetBranchCodes: [],
        priceDifference: 0,
        isMounted: true
    };

    componentDidMount() {
        const {
            columnData, submissionVM,
            updateWizardData,
            onValidate, id,
            isComponentValid
        } = this.props;
        const resetBranchCodes = columnData.map((item) => item.code);

        this.setState({ resetBranchCodes });

        if (_.get(submissionVM, 'lobData.personalUmbrella_EU.paperlessInd.value') === undefined) {
            _.set(submissionVM, 'lobData.personalUmbrella_EU.paperlessInd.value', false);
            updateWizardData(submissionVM);
        }

        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }

    componentWillUnmount() {
        this.state.isMounted = false;
    }
    
    getTableColumnContent(columnData, tableInfo) {
        return columnData.map((data, index) => ({
                id: `quoteTableColumn${index}`,
                type: 'element',
                component: 'TableColumn',
                componentProps: {
                    cellClassName: 'gwTableCell',
                    renderCell: this.renderCellContent,
                    data: tableInfo.tableContent.find((content) => content.code === data.code)
                }
            }));
    }

    getTotalPremium() {
        const { columnData, submissionVM } = this.props;

        if (columnData) {
            const payPlans = _.get(columnData[0], 'lob.data.paymentPlans');
            const selectedPlan = _.filter(payPlans, (plan) => plan.isSelected);
            const totalPremium = selectedPlan[0]?.total?.amount;

            if (!totalPremium && totalPremium !== 0) {
                return undefined;
            }

            _.set(submissionVM, 'unverfiedPremium', totalPremium);

            return { currency: 'usd', amount: totalPremium };
        }

        return undefined;
    }

    getStaleCode() {
        const { onStaleQuoteBranchCode } = this.props;
        let val = [];

        if (onStaleQuoteBranchCode) {
            if (onStaleQuoteBranchCode()) {
                const staleCodes = onStaleQuoteBranchCode();

                if (_.isArray(staleCodes)) {
                    val = staleCodes;
                } else {
                    val.push(staleCodes);
                }
            }
        }

        return val;
    }

    getChangedOfferingPathsAndSetClauseToLoading(path) {
        const { columnData } = this.props;
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;

        const changedColumn = getChangedColumnData(path, columnData);
        const changedClause = getChangedClause(path, columnData);
        const lobPath = changedColumn.lob.path;
        const quotePath = changedColumn.quote.path;
        const updatedBranchCodes = resetBranchCodes.filter((item) => item !== changedColumn.code);

        staleQuoteBranchCodes.push(changedColumn.code);

        this.setState({
            loadingClause: {
                clauseID: changedClause.coveragePublicID || changedClause.publicID,
                quoteCode: changedColumn.code
            },
            staleQuoteBranchCodes,
            resetBranchCodes: updatedBranchCodes
        });

        return [lobPath, quotePath];
    }

    removeStaleQuote = (path) => {
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;
        const selectedColumn = _.get(this.props, path);

        const newStateQuoteArray = staleQuoteBranchCodes.filter((item) => (
            item !== selectedColumn.code
        ));
        const newResetBranchArray = resetBranchCodes.filter((item) => (
            item !== selectedColumn.code
        ));

        this.setState({
            staleQuoteBranchCodes: newStateQuoteArray,
            resetBranchCodes: newResetBranchArray
        });
    }

    renderMobileQuoteHeader = (accordionData, index, isFormValid) => (
        (isOpen) => {
            const { onValidate } = this.props;
            const {
                formData,
                staleQuoteBranchCodes
            } = this.state;
            const scheduleStaleCode = this.getStaleCode();

            if (_.isEmpty(staleQuoteBranchCodes) && !_.isEmpty(scheduleStaleCode)) {
                this.setState({ staleQuoteBranchCodes: scheduleStaleCode });
            }

            const isAnnually = formData.paymentToggle === PAYMENT_TYPES.annually;
            const isQuoteStale = _.includes(staleQuoteBranchCodes, accordionData.code);
            const price = isAnnually
                ? accordionData.quote.data.premium.total
                : accordionData.quote.data.premium.monthlyPremium;

            const dataForComponent = {
                price,
                quoteName: accordionData.name,
                selectedCoverages: getSelectedCoverages(accordionData)
            };

            // Due to having a new render content, there may be duplicate ID's
            const overrides = {
                monthlyAmountText: {
                    visible: formData.paymentToggle === PAYMENT_TYPES.monthly
                },
                annuallyAmountText: {
                    visible: formData.paymentToggle === PAYMENT_TYPES.annually
                },
                buyNowButton: {
                    path: `columnData[${index}]`,
                    visible: !isQuoteStale,
                    onClick: () => this.buyNow(accordionData.quote.path, accordionData.lob.path)
                },
                recalculateButton: {
                    path: `columnData[${index}]`,
                    visible: isQuoteStale,
                    disabled: !isFormValid,
                    onClick: () => this.recalculate(`columnData[${index}]`, accordionData.quote.path, accordionData.lob.path)
                },
                saveButton: {
                    path: `columnData[${index}]`,
                    onClick: () => this.recalculate(`columnData[${index}]`, accordionData.quote.path, accordionData.lob.path)
                },
                selectedCoverages: {
                    visible: !isOpen
                },
                moreInfoContainer: {
                    visible: !isOpen
                },
                accordionChevron: {
                    isOpen
                },
                accordionStickyHeader: {
                    className: classNames(styles.accordionStickyHeader, {
                        [styles.mobileStickyHeader]: isOpen
                    })
                },

            };

            const resolvers = {
                resolveClassNameMap: styles
            };

            const metadataToRender = metadata.contentForMobileView.content;

            return (
                <ViewModelForm
                    uiProps={metadataToRender}
                    model={dataForComponent}
                    overrideProps={overrides}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            );
        }
    )


    renderCellContent = (clauseCellPublicID, index, props) => {
        const { loadingClause } = this.state;
        const {
            onChangeSubmission,
            onValidate,
            viewOnlyMode,
            underwritingIssues,
            authUserData,
            columnData
        } = this.props;
        const { path, clauses = [], code } = props.data;
        const clauseIndex = clauses.findIndex(
            (clause) => clause.publicID === clauseCellPublicID.publicID
        );
        const clause = clauses[clauseIndex];
        const { staleQuoteBranchCodes } = this.state;
        const isQuoteStale = _.includes(staleQuoteBranchCodes, columnData[0].code) || columnData[0].quote.data.status === 'Draft';
        const isQuoteLoading = _.get(loadingClause, 'quoteCode') === code && _.isUndefined(loadingClause.clauseID);
        const isClauseLoading = (_.get(loadingClause, 'clauseID') === clause.publicID && _.get(loadingClause, 'quoteCode') === code);
        const userRolePremiumViewRoles = authUserData?.permissions_Ext.includes('viewpremium_ext');
        const allUWIssuesApproval = underwritingIssues?.every((item) => item?.approvalBlockingPoint === 'NonBlocking'
                || item?.currentBlockingPoint === 'NonBlocking');
        const blocksQuoteUWIssues = underwritingIssues?.some(
            (uwIssue) => BLOCKS_QUOTES.includes(uwIssue.currentBlockingPoint)
        );
        const showPremium = !isQuoteStale && (
            !blocksQuoteUWIssues
            || userRolePremiumViewRoles
            || allUWIssuesApproval);

        if (clauseIndex !== -1) {
            return (
                <EUSingleClauseComponentVM
                    value={clause}
                    path={`${path}.children[${clauseIndex}]`}
                    onChangeClause={onChangeSubmission}
                    onSyncCoverages={this.syncCoverages}
                    onChangeSubmissionAndSync={this.changeSubmissionAndSync}
                    loadingClause={isClauseLoading || isQuoteLoading}
                    idPrefex={code}
                    labelTop
                    onValidate={onValidate}
                    viewOnlyMode={viewOnlyMode}
                    showPremium={showPremium}
                />
            );
        }

        return null;
    }

    writeValue = (value, path) => {
        const { formData } = this.state;

        _.set(formData, path, value);
        this.setState({ formData });
    };

    discountChange = (value, path) => {
        const { submissionVM, updateWizardData, setFieldsChangedOnCoveragePage } = this.props;

        setFieldsChangedOnCoveragePage(true);

        const newPath = path.split('submissionVM.')[1];

        _.set(submissionVM, newPath, value);
        updateWizardData(submissionVM, false);
    };

    syncCoverages = (value, path) => {
        const { onSyncCoverages } = this.props;

        const [lobPath, quotePath] = this.getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onSyncCoverages) {
            onSyncCoverages(value, path, lobPath, quotePath).then(() => {
                this.setState({ loadingClause: undefined });
            });
        }
    }

    changeSubmissionAndSync = (value, path) => {
        const { onChangeSubmissionAndSync } = this.props;

        const [lobPath, quotePath] = this.getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onChangeSubmissionAndSync) {
            onChangeSubmissionAndSync(value, path, lobPath, quotePath).then(() => {
                this.setState({ loadingClause: undefined });
            });
        }
    }

    buyNow = (quotePath, lobPath) => {
        const { onBuyNow } = this.props;

        if (onBuyNow) {
            onBuyNow(lobPath, quotePath);
        }
    }

    recalculate = (columnPath, quotePath, lobPath) => {
        const {
            onRecalculate, columnData,
            isComponentValid, updateIsPageSubmitted
        } = this.props;
        const changedColumnData = getChangedColumnData(lobPath, columnData);

        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        updateIsPageSubmitted(false);

        let monthlyPayment = 0;

        if (changedColumnData.quote.data.status === 'Quoted') {
            monthlyPayment = changedColumnData.quote.data.premium.monthlyPremium.amount;
        }

        this.setState({
            loadingClause: {
                quoteCode: changedColumnData.code
            }
        });

        if (onRecalculate) {
            // could be saveAndQuote or updateCustomQuote
            onRecalculate(lobPath, quotePath).then((response) => {
                if (this.state.isMounted) {
                let newMonthlyPayment = nullLiteral;
                // customQuoteDTO is different than the quotedataDTO
                const premiumDTO = response.quote ? response.quote.premium
                    : response.quoteData.offeredQuotes[0].premium;

                if (changedColumnData.quote.data.status === 'Quoted') {
                    newMonthlyPayment = premiumDTO?.monthlyPremium?.amount;
                }

                this.removeStaleQuote(columnPath);
                this.setState({
                    priceDifference: {
                        columnPath,
                        difference: newMonthlyPayment - monthlyPayment
                    },
                    loadingClause: undefined
                });
            }
            });
        }
    }

    updatePaperlessIndAndEmail = (value, path) => {
        const { submissionVM, updateWizardData, setFieldsChangedOnCoveragePage, setIsPaperlessEmailUpdated } = this.props;

        _.set(submissionVM, path, value);
        setFieldsChangedOnCoveragePage(true);
        setIsPaperlessEmailUpdated(true);

        switch (path) {
            case 'lobData.personalUmbrella_EU.paperlessInd':
                if (value) {
                    const pniEmail = _.get(submissionVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value');

                    if (_.get(submissionVM, 'lobData.personalUmbrella_EU.paperlessEmail.value') === undefined) {
                        _.set(submissionVM, 'lobData.personalUmbrella_EU.paperlessEmail.value', pniEmail);
                    }
                } else {
                    _.set(submissionVM, 'lobData.personalUmbrella_EU.paperlessEmail.value', undefined);
                }

                break;
            default:
                break;
        }

        updateWizardData(submissionVM);
    }

    generateDesktopHeaderOverrides() {
        const {
            columnData,
            isComponentValid,
            quoteIsStale,
            fieldsChangedOnCoveragePage,
            viewOnlyMode,
            onCancel
        } = this.props;

        const {
            formData,
            staleQuoteBranchCodes,
            priceDifference
        } = this.state;

        const scheduleStaleCode = this.getStaleCode();

        if (_.isEmpty(staleQuoteBranchCodes) && !_.isEmpty(scheduleStaleCode)) {
            this.setState({ staleQuoteBranchCodes: scheduleStaleCode });
        }

        const overrides = columnData.map(({ code, quote, lob }, index) => {
            const columnPath = `columnData[${index}]`;
            const isQuoteStale = _.includes(staleQuoteBranchCodes, code) || columnData[0].quote.data.status === 'Draft';
            const clearAmount = { amount: undefined };

            let monthlyValue = clearAmount;
            let annuallyValue = clearAmount;
            let isQuoteBlocked = false;

            if (!_.isEmpty(quote.data)) {
                const { data } = quote;
                const monthlyPremium = { ...clearAmount, ...data.premium.monthlyPremium};
                const total = { ...clearAmount, ...data.premium.total};

                monthlyValue = { ...clearAmount, ...monthlyPremium};
                annuallyValue = { ...clearAmount, ...total};
            }

            isQuoteBlocked = isQuoteBlocked || quoteIsStale;

            const monthlyHeader = isQuoteStale ? { [`monthlyAmount${index}`]: { value: monthlyValue } } : {};
            const annuallyHeader = isQuoteStale ? { [`annuallyAmount${index}`]: { value: annuallyValue } } : {};
            const shouldShowPriceDifference = (
                _.includes(priceDifference.columnPath, `[${index}]`)
                && !isQuoteStale
                && priceDifference.difference !== 0
            );

            return {
                [`quoteAnnuallyAmount${index}`]: { visible: formData.paymentToggle === PAYMENT_TYPES.annually },
                [`quoteMonthlyAmount${index}`]: { visible: formData.paymentToggle === PAYMENT_TYPES.monthly },
                [`priceDifference${index}`]: { visible: shouldShowPriceDifference, value: priceDifference.difference },
                [`buyNowButton${index}`]: {
                    visible: !isQuoteStale && quote.data?.status !== 'Draft' && !fieldsChangedOnCoveragePage,
                    onClick: () => this.buyNow(quote.path, lob.path)
                },
                [`exitButton${index}`]: {
                    onClick: () => onCancel()
                },
                [`recalculateButton${index}`]: {
                    visible: (isQuoteStale || quote.data?.status === 'Draft' || !!fieldsChangedOnCoveragePage) && !viewOnlyMode,
                    disabled: !isComponentValid,
                    onClick: () => this.recalculate(columnPath, quote.path, lob.path)
                },
                [`saveButton${index}`]: {
                    onClick: () => this.recalculate(columnPath, quote.path, lob.path)
                },
                [`monthlyAmountText${index}`]: { visible: !isQuoteStale },
                [`annuallyAmountText${index}`]: { visible: !isQuoteStale },
                [`warningIcon${index}`]: {
                    visible: isQuoteBlocked
                },
                signatureSeperator: {
                    visible: !viewOnlyMode
                },
                ...monthlyHeader,
                ...annuallyHeader
            };
        });

        return Object.assign({}, ...overrides);
    }

    generatePhoneHeaderOverrides() {
        const { tableData, columnData, isComponentValid } = this.props;

        const overrides = columnData.map((data, index) => ({
                [`quoteTableAccordionCardIterableComponentContainer${index}`]: {
                    tableData,
                    columnData: data,
                    isFormValid: isComponentValid,
                    renderAccordionCardHeader: this.renderMobileQuoteHeader,
                    generatePhoneTableOverrides: this.generatePhoneTableOverrides.bind(this),
                    onValueChange: this.writeValue
                }
            }));

        return Object.assign({}, ...overrides);
    }

    generateDesktopTableOverrides() {
        const { tableData, columnData } = this.props;

        const overrides = tableData.map((tableInfo, index) => ({
            [`quoteTable${index}`]: {
                data: tableInfo.data,
                content: this.getTableColumnContent(columnData, tableInfo)
            }
        }));

        return Object.assign({}, ...overrides);
    }

    generatePhoneTableOverrides() {
        const { columnData: quoteInformation, tableData } = this.props;

        const overrides = quoteInformation.flatMap((quoteInfo, quoteIndex) => tableData.map((tableInfo) => ({
                [`quoteTable${quoteIndex}`]: {
                    data: tableInfo.data,
                    title: tableInfo.header
                },
                [`quoteTableColumn${quoteIndex}`]: {
                    renderCell: this.renderCellContent,
                    data: tableInfo.tableContent.find((content) => content.code === quoteInfo.code)
                }
            })));

        return Object.assign({}, ...overrides);
    }

    generateDesktopOverrides() {
        return {
            ...this.generateDesktopHeaderOverrides(),
            ...this.generateDesktopTableOverrides()
        };
    }

    generatePhoneOverrides() {
        return {
            ...this.generatePhoneHeaderOverrides()
        };
    }

    generateOverrides(breakpoint) {
        const {
            submissionVM,
            authHeader,
            updateWizardData,
            columnData,
            viewOnlyMode,
            underwritingIssues,
            authUserData,
            modifiers,
            isPageSubmitted,
            setFieldsChangedOnCoveragePage,
            onViewQuoteProposal,
            isQuoteProposalReady,
            opCo
        } = this.props;
        const breakpointOverrides = breakpoint === 'phone' ? this.generatePhoneOverrides() : this.generateDesktopOverrides();
        const { staleQuoteBranchCodes } = this.state;
        const isQuoteStale = _.includes(staleQuoteBranchCodes, columnData[0].code) || columnData[0].quote.data.status === 'Draft';
        const quoteType = _.get(submissionVM, 'quoteType.value.code')
            || _.get(submissionVM, 'quoteType_Ext.value.code')
            || _.get(submissionVM, 'value.quoteType');
        const premiumTypeLabel = quoteType === 'Quick' ? 'Unverified Premium' : 'Verified Premium';
        const userRolePremiumViewRoles = authUserData?.permissions_Ext.includes('viewpremium_ext');
        const allUWIssuesApproval = underwritingIssues?.every((item) => item?.approvalBlockingPoint === 'NonBlocking'
                || item?.currentBlockingPoint === 'NonBlocking');
        const blocksQuoteUWIssues = underwritingIssues?.some(
            (uwIssue) => BLOCKS_QUOTES.includes(uwIssue.currentBlockingPoint)
        );
        const blocksBindUWIssues = underwritingIssues?.some(
            (uwIssue) => BLOCKS_BIND.includes(uwIssue.currentBlockingPoint)
        );
        const hidePremiumForVerified = (_.get(submissionVM, 'quoteType_Ext.value.code') !== 'Quick' && _.get(submissionVM, 'value.quoteType') !== 'Quick') && blocksBindUWIssues;
        const showPremium = (!blocksQuoteUWIssues || userRolePremiumViewRoles || allUWIssuesApproval) && !hidePremiumForVerified && !isQuoteStale;

        return {
            '@field': {
                // apply to all fields
                onValueChange: this.writeValue,
                readOnly: viewOnlyMode,
                showRequired: true,
                showErrors: isPageSubmitted,
                autoComplete: false
            },
            paymentOptionsID: {
                submissionVM,
                authHeader,
                updateWizardData,
                LOB: 'personalUmbrella_EU',
                isQuoteStale,
                viewOnly: viewOnlyMode
            },
            paperlessInd: {
                value: _.get(submissionVM, 'lobData.personalUmbrella_EU.paperlessInd.value'),
                onValueChange: this.updatePaperlessIndAndEmail,
                visible: _.get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled'])
            },
            quoteProposalLinkContainer: {
                disabled: viewOnlyMode || !isQuoteProposalReady,
                onClick: (e) => onViewQuoteProposal(e),
            },
            paperlessEmailId: {
                visible: _.get(submissionVM, 'lobData.personalUmbrella_EU.paperlessInd.value') && _.get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled']),
                value: _.get(submissionVM, 'lobData.personalUmbrella_EU.paperlessEmail.value'),
                required: _.get(submissionVM, 'quoteType_Ext.value.code') === 'Full',
                onValueChange: this.updatePaperlessIndAndEmail
            },
            AutoPayDiscountToggle: {
                onValueChange: this.discountChange,
                defaultValue: true,
                disabled: viewOnlyMode
            },
            premiumTypeLabel: {
                content: premiumTypeLabel
            },
            tableHeader: {
                visible: !viewOnlyMode
            },
            accordionButtonContainer: {
                visible: !viewOnlyMode
            },
            totalPremiumID: {
                visible: showPremium
            },
            emptyPremium: {
                visible: !showPremium
            },
            monthlyPaymentScheduleComponent: {
                quoteID: _.get(submissionVM, 'quoteID.value', submissionVM.jobID?.value),
                authHeader,
                transactionTotalAmount: this.getTotalPremium(),
                changeInCost: submissionVM.transactionCost?.value,
                startDate: _.get(submissionVM, 'baseData.periodStartDate.value',
                    _.get(submissionVM, 'periodStartDate.value')),
                endDate: _.get(submissionVM, 'baseData.periodEndDate.value',
                    _.get(submissionVM, 'periodEndDate.value')),
                jobTypeCode: _.get(submissionVM, 'baseData.jobType.value.code', ''),
                offerings: _.get(submissionVM, 'lobData.personalUmbrella_EU.offerings.value'),
                visible: !(viewOnlyMode)
            },
            buyNowtableHeader: {
                visible: !viewOnlyMode
            },
            topBuyNowtableHeader: {
                visible: !viewOnlyMode
            },
            euSurchargeListComponentId: {
                // modifiers other than discount will be shown in surchage and fee section
                value: modifiers.filter(
                    (item) => item.applied && item.modifierType !== "discount"
                ),
                transactionVM: submissionVM,
                updateWizardData,
                setFieldsChangedOnCoveragePage,
                viewOnlyMode
            },
            euDiscountsListComponentId: {
                visible: !!_.filter(modifiers, { applied: true, modifierType: 'discount' }).length,
                value: modifiers
            },
            ulPolicyInfoMessage: {
                visible: !viewOnlyMode && _.get(submissionVM, 'baseData.jobType.value.code', '') === 'Submission'
            },
            ...breakpointOverrides
        };
    }

    renderClauseTable = (breakpoint) => {
        const { onValidate } = this.props;
        const { formData } = this.state;

        const dataForComponent = {
            ...formData,
            ..._.pick(this.props, ['columnData', 'tableData', 'submissionVM']),
            totalPremium: this.getTotalPremium()
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                chevron: Chevron,
                quotetableaccordioncarditerablecomponent: QuoteTableAccordionCardIterableComponent,
                underwritingissues: UnderwritingIssues
            }
        };
        const generatedMetadata = selectMetadata(breakpoint);

        return (
            <ViewModelForm
                uiProps={generatedMetadata.componentContent}
                model={dataForComponent}
                overrideProps={this.generateOverrides(breakpoint)}
                onValueChange={this.writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    }

    render() {
        return (
            <BreakpointTrackerContext.Consumer>
                {this.renderClauseTable}
            </BreakpointTrackerContext.Consumer>
        );
    }
}

export default withValidation(QuoteClauseTable);
