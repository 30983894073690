import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class InteractiveService {
    static getNonTransactionalLetterTemplates(
        interactiveSearchCriteriaDTO,
        authHeader
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('interactiveletterhandler'),
            'getNonTransactionalLetterTemplates',
            [interactiveSearchCriteriaDTO],
            authHeader
        );
    }

    static initiateNonTransactionalInteractiveLetter(
        templateDescriptorDTO,
        policyNumber,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('interactiveletterhandler'),
            'initiateNonTransactionalInteractiveLetter',
            [templateDescriptorDTO, policyNumber],
            additionalHeaders
        );
    }
}
