import React, {
    useContext, useCallback, useState
} from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import {
    ViewModelServiceContext,
    withViewModelService,
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import E1PNotesDataTable from './Components/E1PNotesDataTableComponent/E1PNotesDataTable';
import E1PNoteDetailModal from './Components/E1PNoteDetailModal/E1PNoteDetailModal';
import metadata from './NotesComponent.metadata.json5';
import messages from './NotesComponent.messages';
import styles from './NotesComponent.module.scss';

const NotesComponent = (props) => {
    const modalApi = useModal();
    const {
        initialNotesData,
        createNote,
        editNote,
        deleteNote,
        authUserData,
        policyNumber
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [areNotesExpanded, setNotesExpanded] = useState(false);

    const createVM = useCallback(
        (model) => viewModelService.create(
                model,
                'pc',
                'edge.capabilities.gateway.note.dto.NoteDTO'
            ),
        [viewModelService]
    );

    const addNoteHandler = useCallback(
        async () => {
        // create noteVM and show in Popup
            const noteVM = createVM({});
            const componentProps = {
                title: translator(messages.addNote),
                NoteDetailVM: noteVM,
                viewModelService,
                authUserData,
                policyNumber
            };
            const result = await modalApi.showModal(
                <E1PNoteDetailModal {...componentProps} />
            );

            if (result && createNote) {
                if (!result.securityLevel_Ext) {
                    const canCreateIntNote = authUserData?.permissions_Ext.includes('createintnote');

                    result.securityLevel_Ext = canCreateIntNote ? 'internalonly' : 'unrestricted';
                }

                createNote(result);
            }

            return result;
        },
        [authUserData, createNote, createVM, modalApi, policyNumber, translator, viewModelService]
    );

    const editNoteHandler = useCallback(
        async (data) => {
        // create noteVM and show in Popup
            const noteVM = createVM(data);
            const componentProps = {
                title: translator(messages.editNote),
                NoteDetailVM: noteVM,
                viewModelService,
                authUserData,
                policyNumber
            };
            const result = await modalApi.showModal(
                <E1PNoteDetailModal {...componentProps} />
            );

            if (result && editNote) {
                if (!result.securityLevel_Ext) {
                    const canEditIntNote = authUserData?.permissions_Ext.includes('editintnote');

                    result.securityLevel_Ext = canEditIntNote ? 'internalonly' : 'unrestricted';
                }

                editNote(result);
            }

            return result;
        },
        [authUserData, createVM, editNote, modalApi, policyNumber, translator, viewModelService]
    );

    const deleteNoteHandler = useCallback((data) => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: messages.removeNoteTitle,
            message: messages.removeNoteDescription,
            confirmButtonText: messages.deleteNote,
            cancelButtonText: messages.cancelNote
        }).then((result) => {
            if (result !== 'cancel' && deleteNote) {
                deleteNote(data);
            }
        }, () => { });
    }, [deleteNote, modalApi]);

    /**
     * 
     * @param {*} expandAllNotes 
     * Helper function to expand and collapse notes expander based on the parameter passed.
     * if expandAllNotes = true, then function will select all the notes
     * which are not expanded and expands them, and vice-versa.
     */
    const onHandleExpandNotes = (expandAllNotes) => {
        const noteTableDOM = document.getElementById('notesTableGrid');
        const notesSelector = expandAllNotes ? "false" : "true";

        if(noteTableDOM) {
            const allNotesExpanderDOM = noteTableDOM.querySelectorAll(`svg[aria-expanded="${notesSelector}"]`);

            allNotesExpanderDOM.forEach((node)=> {
                if(node && node.parentElement && node.parentElement.click) {
                    node.parentElement.click();
                }
            })
        }
    }

    const overrideProps = {
        notesAddButtonId: {
            visible: authUserData.permissions_Ext.includes('notecreate')
        },
        customNotesDataTableContainer: {
            initialNotesData,
            authUserData,
            policyNumber,
            editNote: editNoteHandler,
            deleteNote: deleteNoteHandler,
            expandAllNotes: () => {
                onHandleExpandNotes(areNotesExpanded);
            }
        },
        expandAllNotesID: {
            value: areNotesExpanded,
            onValueChange: (value) => {
                setNotesExpanded(value);
                onHandleExpandNotes(value);
            }
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            e1pNotesDataTableComponent: E1PNotesDataTable
        },
        resolveCallbackMap: {
            addNoteHandler
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

NotesComponent.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    initialNotesData: PropTypes.arrayOf(
        PropTypes.shape({
            noteTopic: PropTypes.string,
            noteSubject: PropTypes.string,
            body: PropTypes.string,
            createdDate: PropTypes.string,
            author: PropTypes.string
        })
    ).isRequired,
    createNote: PropTypes.func.isRequired,
    editNote: PropTypes.func,
    deleteNote: PropTypes.func,
    authUserData: PropTypes.shape({
        permissions_Ext: PropTypes.arrayOf(
            PropTypes.string
        )
    }).isRequired,
    policyNumber: PropTypes.string
};
NotesComponent.defaultProps = {
    policyNumber: 'Unassigned',
    editNote: undefined,
    deleteNote: undefined
};

export default withAuthenticationContext(withViewModelService(NotesComponent));
