import React, {
    useCallback, useState
} from 'react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import styles from './PaperlessDetailPopup.module.scss';
import metadata from './PaperlessDetailPopup.metadata.json5';
import './PaperlessDetailPopup.messages';


import { Button } from '@jutro/legacy/components';


function PaperlessDetailPopup(props) {
    const {
        person,
        viewModelService,
        onResolve,
        title,
        isOpen
    } = props;

    const {
        onValidate, isComponentValid
    } = useValidation('PaperlessDetailPopup');

    const [modelObj, updateModelObj] = useState(person);

    const addHandler = useCallback(() => {
        onResolve(modelObj.emailAddress1.value);
    }, [modelObj.emailAddress1.value, onResolve]);

    const handleValueChange = useCallback((value) => {
        const newEmail = viewModelService.clone(modelObj);

        _.set(newEmail, 'emailAddress1', value);
        updateModelObj(newEmail);
    }, [modelObj, viewModelService]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            addHandler
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        // okButtonID: {
        //     disabled: !isComponentValid
        // }
    };
    const readPaperlessDetailPopupValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                modelObj,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [modelObj, overrideProps]
    );

    return (
        <ModalNext
            isOpen={isOpen}
            className={styles.smallPopUp}
        >
            <ModalHeader title={title} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={modelObj}
                    overrideProps={overrideProps}
                    onValueChange={handleValueChange}
                    onValidationChange={onValidate}
                    resolveValue={readPaperlessDetailPopupValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={addHandler} disabled={!isComponentValid}>
                    Save
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

PaperlessDetailPopup.propTypes = {
    person: PropTypes.shape({
        emailAddress1: PropTypes.shape({})
    }).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    onResolve: PropTypes.func,
    isOpen: PropTypes.func,
    title: PropTypes.string
};

PaperlessDetailPopup.defaultProps = {
    onResolve: undefined,
    isOpen: undefined,
    title: undefined
};
export default PaperlessDetailPopup;
