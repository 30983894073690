import { defineMessages } from 'react-intl';

export default defineMessages({
    windStormMitigation: {
        id: 'components.E1PWindstormMitigationDiscount.Windstorm Mitigation',
        defaultMessage: 'Windstorm Mitigation'
    },
    lossMitigation: {
        id: 'components.E1PWindstormMitigationDiscount.Loss Mitigation',
        defaultMessage: 'Loss Mitigation'
    },
    openingProtection: {
        id: 'components.E1PWindstormMitigationDiscount.Opening Protection',
        defaultMessage: 'Opening Protection'
    }
});
