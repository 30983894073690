import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextPageMessageRewrite: {
        id: 'rewrite.ea.directives.templates.ea-edit-coverage.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    saveCoverageErrorRewrite: {
        id: 'rewrite.ea.config.Unable to save coverages change',
        defaultMessage: 'Unable to save coverages change'
    },
    saveCoverageErrorMessageRewrite: {
        id: 'rewrite.ea.config.An error occurred while attempting to save coverage changes',
        defaultMessage: 'An error occurred while attempting to save coverage changes'
    },
    generalCoveragesRewrite: {
        id: 'rewrite.ea.directives.MultiQuoteView.General Coverages - Apply to all vehicles',
        defaultMessage: 'General Coverages - Apply to all vehicles'
    }
});
