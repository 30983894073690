import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import EHRewriteStartPage from './pages/StartRewrite/StartRewritePageEH';
import RewriteEHPolicyDetailsPage from './pages/InsuredDetailsPage/RewriteEHPolicyDetailsPage';
import RewriteEHHomeownersPropertyPage from './pages/HO3/PropertyPage/PropertyPage';
import RewriteEHRentersPropertyPage from './pages/HO4/PropertyPage/PropertyPage';
import RewriteEHCondominuimPropertyPage from './pages/HO6/PropertyPage/PropertyPage';
import RewriteEHSeasonalPropertyPage from './pages/HF9/PropertyPage/PropertyPage';
import RewriteEHRiskAnalysisPage from './pages/RiskAnalysisPage/RewriteEHRiskAnalysisPage';
import RewriteEHCoveragePage from './pages/CoveragesPage/CoveragePage';
import RewriteEHTPIPage from './pages/ThirdPartyInterest/ThirdPartyInterestPage';
import RewriteEHPaymentDetailPage from './pages/PaymentDetails/PaymentDetailsPage';
import RewriteEHThankyouPage from './pages/ThankYou/ThankYouPage';
import RewriteViewEHPolicyDetailsPage from './pages/InsuredDetailsPage/viewOnly/ViewRewriteEHPolicyDetailsPage';
import RewriteViewEHSeasonalPropertyPage from './pages/HF9/PropertyPage/ViewOnly/ViewPropertyPage';
import RewriteViewEHHomeownersPropertyPage from './pages/HO3/PropertyPage/ViewOnly/ViewPropertyPage';
import RewriteViewEHRentersPropertyPage from './pages/HO4/PropertyPage/ViewOnly/ViewPropertyPage';
import RewriteViewEHCondominuimPropertyPage from './pages/HO6/PropertyPage/ViewOnly/ViewPropertyPage';
import RewriteViewEHRiskAnalysisPage from './pages/RiskAnalysisPage/ViewOnly/ViewRiskAnalysisPage';
import RewriteViewEHCoveragePage from './pages/CoveragesPage/ViewOnly/ViewCoveragePage';
import RewriteViewEHTPIPage from './pages/ThirdPartyInterest/ViewOnly/ViewThirdPartyInterestPage';
import RewriteViewEHPaymentDetailPage from './pages/PaymentDetails/ViewOnly/ViewPaymentDetailsPage';


setComponentMapOverrides(
    {
        EHRewriteStartPage: { component: 'EHRewriteStartPage' },
        RewriteEHPolicyDetailsPage: { component: 'RewriteEHPolicyDetailsPage' },
        RewriteEHHomeownersPropertyPage: { component: 'RewriteEHHomeownersPropertyPage' },
        RewriteEHRentersPropertyPage: { component: 'RewriteEHRentersPropertyPage' },
        RewriteEHRiskAnalysisPage: { component: 'RewriteEHRiskAnalysisPage' },
        RewriteEHCoveragePage: { component: 'RewriteEHCoveragePage' },
        RewriteEHTPIPage: { component: 'RewriteEHTPIPage' },
        RewriteEHPaymentDetailPage: { component: 'RewriteEHPaymentDetailPage' },
        RewriteEHThankyouPage: { component: 'RewriteEHThankyouPage' },
        RewriteEHCondominuimPropertyPage: { component: 'RewriteEHCondominuimPropertyPage' },
        RewriteEHSeasonalPropertyPage: { component: 'RewriteEHSeasonalPropertyPage' },
        RewriteViewEHPolicyDetailsPage: { component: 'RewriteViewEHPolicyDetailsPage' },
        RewriteViewEHSeasonalPropertyPage: { component: 'RewriteViewEHSeasonalPropertyPage' },
        RewriteViewEHRentersPropertyPage: { component: 'RewriteViewEHRentersPropertyPage' },
        RewriteViewEHHomeownersPropertyPage: { component: 'RewriteViewEHHomeownersPropertyPage' },
        RewriteViewEHCondominuimPropertyPage: { component: 'RewriteViewEHCondominuimPropertyPage' },
        RewriteViewEHRiskAnalysisPage: { component: 'RewriteViewEHRiskAnalysisPage' },
        RewriteViewEHCoveragePage: { component: 'RewriteViewEHCoveragePage' },
        RewriteViewEHTPIPage: { component: 'RewriteViewEHTPIPage' },
        RewriteViewEHPaymentDetailPage: { component: 'RewriteViewEHPaymentDetailPage' },
    },
    {
        EHRewriteStartPage,
        RewriteEHPolicyDetailsPage,
        RewriteEHHomeownersPropertyPage,
        RewriteEHRentersPropertyPage,
        RewriteEHRiskAnalysisPage,
        RewriteEHCoveragePage,
        RewriteEHTPIPage,
        RewriteEHPaymentDetailPage,
        RewriteEHThankyouPage,
        RewriteEHCondominuimPropertyPage,
        RewriteEHSeasonalPropertyPage,
        RewriteViewEHPolicyDetailsPage,
        RewriteViewEHSeasonalPropertyPage,
        RewriteViewEHHomeownersPropertyPage,
        RewriteViewEHRentersPropertyPage,
        RewriteViewEHCondominuimPropertyPage,
        RewriteViewEHRiskAnalysisPage,
        RewriteViewEHCoveragePage,
        RewriteViewEHTPIPage,
        RewriteViewEHPaymentDetailPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PEEHRewriteWizard } from './PEEHRewriteWizard';
