import React, {
    useMemo, useCallback, useEffect, useState, useContext
} from 'react';
import {
    get as _get,
    find as _find
} from 'lodash';
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { AccountService } from 'e1p-capability-gateway';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { e1pUserAccessUtil } from 'e1p-capability-hooks';
// import {  } from '@xengage/gw-digital-auth-react';

import metadata from './ThankyouPageAddNewLineComponent.metadata.json5';
import styles from './ThankyouPageAddNewLineComponent.module.scss';
import './ThankyouPageAddNewLineComponent.messages';

const shouldPromoteAutoLobs = ['Homeowners_EH', 'PersonalUmbrella_EU'];
const shouldPromoteUmbrellaLobs = ['Homeowners_EH', 'PersonalAuto_EA'];
const shouldPromoteHomeownersLobs = ['PersonalAuto_EA', 'PersonalUmbrella_EU'];

function ThankyouPageAddNewLineComponent(props) {
    const {
        submissionVM,
        history,
        authUserData
    } = props;
    const { authHeader } = useAuthentication();
    const { opCoConfig, opCo} = useContext(AmfamOktaTokenContext);
    const { isNewQuoteFunctionalityVisible } = e1pUserAccessUtil(authUserData, opCo);

    const allowedPolicyTypesForOpCo = _get(opCoConfig, 'allowedPolicyTypes', []);

    const [policySummariesAtAccount, setPolicySummariesAtAccount] = useState([]);

    const accountNumber = useMemo(() => _get(submissionVM, 'baseData.accountNumber.value'), [submissionVM]);

    const currentProduct = useMemo(() => _get(submissionVM, 'baseData.productCode.value'), [submissionVM]);

    const { lobQuoteURL } = appConfig;

    const onStartAutoHandler = useCallback(() => {
        const prodDetails = {
            lob: 'PersonalAuto_EA',
            policyType: 'EAFamilyCar'
        };

        return history.push({
            pathname: lobQuoteURL[prodDetails.lob],
            search: `?productCode=${prodDetails.lob}&policyType=${prodDetails.policyType}&accountNumber=${accountNumber}`,
        });
    }, [accountNumber, history, lobQuoteURL]);

    const onStartHomeHandler = useCallback(() => {
        const prodDetails = {
            lob: 'Homeowners_EH',
            policyType: 'HO3'
        };

        return history.push({
            pathname: lobQuoteURL[prodDetails.lob],
            search: `?productCode=${prodDetails.lob}&policyType=${prodDetails.policyType}&accountNumber=${accountNumber}`,
        });
    }, [accountNumber, history, lobQuoteURL]);

    const onStartUmbrellaHandler = useCallback(() => {
        const prodDetails = {
            lob: 'PersonalUmbrella_EU',
            policyType: 'Umbrella'
        };

        return history.push({
            pathname: lobQuoteURL[prodDetails.lob],
            search: `?productCode=${prodDetails.lob}&policyType=${prodDetails.policyType}&accountNumber=${accountNumber}`,
        });
    }, [accountNumber, history, lobQuoteURL]);

    const isPolicyTypeAvailableAtAccount = useCallback(
        (productType) =>
            !!_find(policySummariesAtAccount, {
                product: { productCode: productType },
            }),
        [policySummariesAtAccount]
    );

    useEffect(() => {
        AccountService.getAccountDetails(accountNumber, authHeader).then((response) => {
            const policySummaries = _get(response, 'policySummaries', []);

            setPolicySummariesAtAccount(policySummaries);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const isHomeNewLineQuoteDivVisible = !isPolicyTypeAvailableAtAccount('Homeowners_EH') && shouldPromoteHomeownersLobs.includes(currentProduct) && allowedPolicyTypesForOpCo.includes('HO3');
    const isAutoNewLineQuoteDivVisible = !isPolicyTypeAvailableAtAccount('PersonalAuto_EA') && shouldPromoteAutoLobs.includes(currentProduct) && allowedPolicyTypesForOpCo.includes('EAFamilyCar');
    const isUmbrellaNewLineQuoteDivVisible = !isPolicyTypeAvailableAtAccount('PersonalUmbrella_EU') && shouldPromoteUmbrellaLobs.includes(currentProduct) && allowedPolicyTypesForOpCo.includes('Umbrella');

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        addNewLineMainDiv: {
            // Show header message only if policy for any line is not present
            visible: isNewQuoteFunctionalityVisible() && (
                isHomeNewLineQuoteDivVisible || isAutoNewLineQuoteDivVisible || isUmbrellaNewLineQuoteDivVisible)
        },
        homeNewLineMainDiv: {
            visible: isHomeNewLineQuoteDivVisible
        },
        autoNewLineMainDiv: {
            visible: isAutoNewLineQuoteDivVisible
        },
        umbNewLineMainDiv: {
            visible: isUmbrellaNewLineQuoteDivVisible
        }

    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onStartAutoHandler,
            onStartHomeHandler,
            onStartUmbrellaHandler
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

ThankyouPageAddNewLineComponent.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default withAuthenticationContext(withRouter(ThankyouPageAddNewLineComponent));
