import React, {
    useCallback,
    useEffect,
    useState,
    useContext,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { PersonNameValidator, TypekeyUtil } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { useTranslator } from '@jutro/locale';
import {
    get as _get,
    trim as _trim,
    isEmpty as _isEmpty,
} from 'lodash';
import metadata from './NamedInsuredComponent.metadata.json5';
import './NamedInsuredComponent.messages';

function NamedInsuredComponent(props) {
    const {
        value: namedInsuredVM,
        labelPosition,
        showOptional,
        path,
        id,
        onValidate,
        onValueChange,
        visible,
        showErrors,
        viewOnlyMode,
        hideSSN,
        isPNI,
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        isValidationInProgress,
    } = useValidation(id);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    // Will be in read only mode if the NI exists on mount
    const [isEditing, setIsEditing] = useState(
        !_get(namedInsuredVM, 'person.publicID.value')
    );

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [namedInsuredVM, id, onValidate, isComponentValid]);

    useEffect(() => {
        if (!_get(namedInsuredVM, 'person.publicID.value', undefined)) {
            setIsEditing(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [namedInsuredVM.person?.publicID?.value]);

    useEffect(() => {
        // if component is invalid we want to keep this in edit mode so user can see errors
        if (
            !isEditing &&
            isComponentValid === false &&
            !isValidationInProgress &&
            !viewOnlyMode
        ) {
            setIsEditing(true);
        }
    }, [isComponentValid, isValidationInProgress, isEditing, viewOnlyMode]);

    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const niGridElement = document.getElementById(
            'namedInsuredInformationGrid'
        );

        if (
            !isPNI &&
            niGridElement &&
            _isEmpty(_get(namedInsuredVM, 'value.person.firstName'))
        ) {
            const firstNameElement = niGridElement.querySelector(
                'input[id="firstName"]'
            );

            if (firstNameElement && firstNameElement.focus !== undefined) {
                firstNameElement.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    // Disabled when person exists and not in edit mode and component is valid
    const isComponentReadOnly =
        viewOnlyMode ||
        (!isEditing && !!_get(namedInsuredVM, 'person.publicID.value'));

    const availableValuesForSuffix = useMemo(() => {
        const typelistWithNoneCode = [
            {
                code: 'None',
                name: translator(e1pCommonMessages.none),
            },
        ];

        return typelistWithNoneCode.concat(
            TypekeyUtil.getAvailableValuesForTypekey(
                viewModelService,
                'NameSuffix'
            )
        );
    }, [translator, viewModelService]);

    const handleSuffixChange = useCallback(
        (value, suffixPath) => {
            let updatedValue = value;

            if (value === 'None') {
                updatedValue = undefined;
            }

            handleValueChange(updatedValue, suffixPath);
        },
        [handleValueChange]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            visible,
            showRequired: true,
            showErrors,
            readOnly: isComponentReadOnly,
            /* SNI readOnly: viewOnlyMode
             * // Disabled when person exists and not in edit mode
             * || (!isEditing && !!_.get(sniVM, 'person.publicID.value')),
             */
            autoComplete: false,
        },
        firstName: {
            validationMessages: PersonNameValidator.validateFirstName(
                _get(namedInsuredVM, 'person.firstName.value'),
                translator
            ),
            showErrors:
                showErrors ||
                !!_get(namedInsuredVM, 'person.firstName.value', false),
        },
        lastName: {
            validationMessages: PersonNameValidator.validateLastName(
                _get(namedInsuredVM, 'person.lastName.value'),
                translator
            ),
            showErrors:
                showErrors ||
                !!_get(namedInsuredVM, 'person.lastName.value', false),
        },
        middleName: {
            validationMessages: PersonNameValidator.validateMiddleName(
                _get(namedInsuredVM, 'person.middleName.value'),
                translator
            ),
            showErrors:
                showErrors ||
                !!_get(namedInsuredVM, 'person.middleName.value', false),
        },
        dateOfBirth: {
            updateDateDto: () =>
                handleValueChange(
                    namedInsuredVM.person.dateOfBirth,
                    'person.dateOfBirth'
                ),
            dateDTO: namedInsuredVM.person.dateOfBirth,
            // Date of Birth is required for both PNI/SNI 
            isRequired: true,
            // Named insured has to be 18
            maxDate: (() => {
                const today = new Date();

                return {
                    day: today.getDate(),
                    month: today.getMonth(),
                    year: today.getFullYear() - 18,
                };
            })(),
            onValidate: setComponentValidation,
            // SNI TODO
            showErrors:
                showErrors ||
                !!_get(namedInsuredVM, 'person.dateOfBirth.value', false),
        },
        ssn: {
            // container div for SSN Component
            // hide fully for EU SNI for formatting 
            visible: isPNI || !hideSSN,
        },
        ssnComponent: {
            visible: !hideSSN,
            viewOnlyMode: isComponentReadOnly,
            onValueChange: handleValueChange,
            personVM: _get(namedInsuredVM, 'person'),
            onValidate: setComponentValidation,
        },
        insuredEmptyContainer: {
            colSpan : isPNI ? 2 : 1
        },
        insureHomeNumberField: {
            visible: isPNI,
        },
        insuredMobileNumberField: {
            visible: isPNI,
        },
        relationshipToNi: {
            visible: !isPNI,
        },
        EditContactButton: {
            icon: isEditing ? 'mi-save' : 'mi-edit',
            onClick: () => setIsEditing(!isEditing),
            // Only show if NI already exists in database
            visible:
                !viewOnlyMode &&
                !!_get(namedInsuredVM, 'person.publicID.value'),
        },
        EditContactWarningDiv: {
            // existing contact and is editing it
            visible:
                isEditing && !!_get(namedInsuredVM, 'person.publicID.value'),
        },
        suffix: {
            availableValues: availableValuesForSuffix,
            onValueChange: handleSuffixChange,
        },
        insuredEmailId: {
            visible: isPNI,
            onValueChange: (value, changedPath) => {
                let changedEmail = value;

                /**
                 * IAP-1254 - we don't want to send empty string when user removes email address
                 * as it triggers validation in backend.
                 * PE will send undefined in this case
                 */
                if (_isEmpty(_trim(changedEmail))) {
                    changedEmail = undefined;
                }

                handleValueChange(changedEmail, changedPath);
            },
        },
    };

    const readValue = useCallback(
        // eslint-disable-next-line arrow-body-style
        (fieldId, fieldPath) => {
            return readViewModelValue(
                metadata.pageContent,
                namedInsuredVM,
                fieldId,
                fieldPath,
                overrideProps
            );
        },
        [namedInsuredVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={namedInsuredVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
        />
    );
}

NamedInsuredComponent.propTypes = {
    value: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    onValidate: PropTypes.func,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    visible: PropTypes.bool,
    showOptional: PropTypes.bool,
    showErrors: PropTypes.bool,
    viewOnlyMode: PropTypes.bool,
    hideSSN: PropTypes.bool,
    isPNI: PropTypes.bool,
};
NamedInsuredComponent.defaultProps = {
    value: {},
    path: undefined,
    labelPosition: 'top', // I want labels on top by default
    id: undefined,
    showOptional: false,
    showErrors: false,
    viewOnlyMode: false,
    hideSSN: false,
    visible: true
};
export default NamedInsuredComponent;
