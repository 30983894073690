import { useCallback } from 'react';
import { filter as _filter, get as _get } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import useAttributeConfigUtil from '../useAttributeConfigUtil';
import useAttributeConfigDataOperationUtil from '../useAttributeConfigDataOperationUtil';
import pathConfig from './PathConfigurationForConfigMetadataForEU.json';


/**
 * Util for getting config data for EU fields
 * @param {Object} transactionVM 
 * @returns {*}
 */
function useAttributeConfigUtilForEU(transactionVM) {
    const { getAttributeconfig } = useAttributeConfigUtil(transactionVM);

    /**
     * Helper function to get available values for signature types
     **/
    const getAvailableValuesForSignatureType = useCallback(async (opCo) => {
        const configMetadata = await getAttributeconfig();
        //  PC is returning the combined list for  MSA  and CONNECT signature  options for umbrella. 
        //  Filtering down the superset list based on whether CONNECT vs MSA flow.
        const availableSignatureCodes = _get(config, ['operatingCompanyConfig', opCo, 'signatureOptionsForEU'], []);
        return _filter(useAttributeConfigDataOperationUtil
            .getMappedAvailableValuesForGivenFieldAndConfigData(configMetadata, 'signatureType', pathConfig), 
        (availableValue) => availableSignatureCodes.includes(availableValue.code));
    }, [getAttributeconfig]);

    
    return {
        getAvailableValuesForSignatureType
    }

}

export default useAttributeConfigUtilForEU;