import React, { useCallback, useState, useEffect } from 'react';
import {
    ModalNext,
    ModalHeader,
    ModalBody,
    withModalContext
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { EndorsementService } from 'e1p-capability-policychange';
import {
    get, set, isEmpty, remove, isEqual, find
} from 'lodash';
// eslint-disable-next-line import/no-relative-packages
import e1pMessages from '../../../../../modules-react/e1p-platform-translations/common/platform.messages';
import metadata from './SupportingPolicyModal.metadata.json5';
import styles from './SupportingPolicyModal.module.scss';
import messages from './SupportingPolicyModal.messages';

function SupportingPolicyModal(props) {
    const {
        isOpen,
        onResolve,
        onReject,
        transactionVM,
        path,
        viewModelService,
        viewOnly,
        LOB,
        authHeader,
        updateWizardData
    } = props;
    const translator = useTranslator();
    const {
        isComponentValid, onValidate, disregardFieldValidation, registerComponentValidation
    } = useValidation('SupportingPolicyModal');
    const [transactionVMClone, setTransactionVMClone] = useState(viewModelService.clone(transactionVM));
    const [policyLineValues, setPolicyLineValues] = useState([]);
    const [checkingNewPolicies, setCheckingNewPolicies] = useState(false);
    // state gets set if systematic policies are found
    const [newlyAddedPolicies, setNewlyAddedPolicies] = useState([]);
    /**
     * Helper callback for handling new policy add
     */
    const createAssociatedPolicyVM = useCallback(() => {
        const associatedPoliciesVMList = get(transactionVMClone, path);

        if (isEmpty(associatedPoliciesVMList.value)) {
            set(associatedPoliciesVMList, 'value', []);
        }

        const associatedPolicyDto = {
            policyLineName: undefined,
            policyTypeName: undefined,
            policyStatus: undefined,
            policySource: 'Manual',
            policyNumber: undefined
        };
        const { _dtoName, _xCenter } = associatedPoliciesVMList;
        const associatedPolicy = viewModelService.create(
            associatedPolicyDto,
            _xCenter,
            _dtoName
        );

        associatedPoliciesVMList.pushElement(associatedPolicy);
        set(transactionVMClone, path, associatedPoliciesVMList);
        setTransactionVMClone(viewModelService.clone(transactionVMClone));
    }, [transactionVMClone, path, viewModelService]);

    /**
     * Helper callback for handling a mouse-click on the "Cancel" button.
     */
    const handleCancelButtonClick = useCallback(
        (evt) => {
            evt.stopPropagation();
            // not really needed anymore becuase we won't let them hit cancel, but keeping here anyway
            onReject(newlyAddedPolicies);
        },
        [newlyAddedPolicies, onReject]
    );

    const getAvailableValuesForPolicyLine = () => {
        let values = [];
        const typelistFilters = viewModelService.productMetadata.get('pc').types
            .getTypelist('PolicyLine').filters;

        if (typelistFilters) {
            values = find(typelistFilters, { name: 'AmFamPolicyLines' }).codes;
        }

        if (!values || values.length === 0) { return undefined; }

        const availableValues = values.filter((code) => {
            switch (LOB) {
                case 'personalAuto_EA':
                    if (code.code === 'EHLine_Ext' || code.code === 'EULine_Ext') {
                        return true;
                    }

                    return false;
                case 'homeowners_EH':
                    // if current LOB is Homeowners and policy Type is HO3 and HF9
                    // we need to show PersonalAuto and Homeowners in the dropdown
                    if (['HO3', 'HF9'].includes(get(transactionVMClone, 'lobData.homeowners_EH.policyType.value.code', get(transactionVMClone, 'value.policyType')))) {
                        if (code.code === 'EHLine_Ext' || code.code === 'EAAutoLine_Ext') {
                            return true;
                        }
                    }

                    // if current LOB is Homeowners and for other policy Type i.e HO4 and HO6
                    // we need to show only PersonalAuto in the dropdown
                    if (code.code === 'EAAutoLine_Ext') {
                        return true;
                    }

                    return false;
                default:
                    if (code.code === 'EAAutoLine_Ext') {
                        return true;
                    }

                    return false;
            }
        }).map((code) => ({
                code: code.code,
                name: translator({ id: code.name })
            }));

        setPolicyLineValues(availableValues);

        return availableValues;
    };

    const combineMovingInPoliciesWithAssociatedPolicies = useCallback(() => {
        if (LOB === 'homeowners_EH') {
            const associatedPoliciesVMList = get(transactionVMClone, path);

            if (isEmpty(associatedPoliciesVMList.value)) {
                set(associatedPoliciesVMList, 'value', []);
            }

            const movingInPoliciesObj = get(transactionVMClone, `value.lobData.${LOB}.movingInPolicies`, []);

            movingInPoliciesObj.forEach((movingInPolicy) => {
                let shouldAddToAssociatedPolicyVM = true;

                if (get(movingInPolicy, 'source') === 'Systematic') {
                    // we need to check if the moveingInpolicy is already present in the associated policies array
                    const policiesFound = associatedPoliciesVMList.value.find((associatedPolicy) => get(associatedPolicy, 'policyNumber') === get(movingInPolicy, 'policyNumber'));

                    if (!isEmpty(policiesFound)) {
                        // policy found in associated policies array, so we dont need to add
                        // this will show as duplicate polcies on UI
                        shouldAddToAssociatedPolicyVM = false;
                    }
                }

                if (shouldAddToAssociatedPolicyVM) {
                    const { _dtoName, _xCenter } = associatedPoliciesVMList;
                    const associatedPolicyDto = {
                        policyLineType: 'EHLine_Ext', // this will always be homeowners
                        policyType: get(movingInPolicy, 'policyType'),
                        policyNumber: get(movingInPolicy, 'policyNumber'),
                        policySource: get(movingInPolicy, 'source'),
                        policyStatus: 'In Force L90D',
                        fixedId: get(movingInPolicy, 'fixedId'),
                        publicId: get(movingInPolicy, 'publicId')
                    };
                    const associatedPolicy = viewModelService.create(
                        associatedPolicyDto,
                        _xCenter,
                        _dtoName
                    );

                    associatedPoliciesVMList.pushElement(associatedPolicy);
                    set(transactionVMClone, path, associatedPoliciesVMList);
                }
            });
            setTransactionVMClone(viewModelService.clone(transactionVMClone));
        }
    }, [LOB, path, transactionVMClone, viewModelService]);

      /**
     * Checks newly addedd policies for given policy change
     * If found we add it to transaction data
     * @Returns {boolean} true if new policies and false otherwise
     */
      const checkForNewPolicies = useCallback(
        async () => {
            setCheckingNewPolicies(true);

            let response = [];

            try {
                response = await EndorsementService.getNewlyAddedAssociatedPolicies(
                    get(transactionVM, 'value.jobID'),
                    authHeader
                );

                if (!isEmpty(response)) {
                    const newlyAddedPoliciesTemp = [];
                    const associatedPoliciesVMList = get(transactionVMClone, path);

                    if (isEmpty(associatedPoliciesVMList.value)) {
                        set(associatedPoliciesVMList, 'value', []);
                    }

                    response.forEach((associatedPolicyDto) => {
                        const { _dtoName, _xCenter } = associatedPoliciesVMList;
                        const associatedPolicy = viewModelService.create(
                            associatedPolicyDto,
                            _xCenter,
                            _dtoName
                        );

                        associatedPoliciesVMList.pushElement(associatedPolicy);
                        newlyAddedPoliciesTemp.push(associatedPolicy.value);
                        set(transactionVMClone, path, associatedPoliciesVMList);
                    });
                    setNewlyAddedPolicies(newlyAddedPoliciesTemp);
                    setTransactionVMClone(viewModelService.clone(transactionVMClone));
                }
            }
            catch(exception) {
                if (transactionVM.value.baseData.exceptions_Ext) {
                    transactionVM.value.baseData.exceptions_Ext.push(
                        { errorMessage: exception.baseError ? exception.baseError : exception.message }
                    );
                } else {
                    set(
                        transactionVM.value.baseData,
                        `exceptions_Ext[${0}]`,
                        { errorMessage: exception.baseError ? exception.baseError : exception.message }
                    );
                }

                updateWizardData(transactionVM);
            }
            finally {
                setCheckingNewPolicies(false);
            }

            return response.length > 0;
        }, [authHeader, path, transactionVM, transactionVMClone, updateWizardData, viewModelService]
    );

    useEffect(() => {
        getAvailableValuesForPolicyLine();

        combineMovingInPoliciesWithAssociatedPolicies();

        // If there is no systematically or manually policy added
        // then create a row for manual policy.
        const associatedPoliciesVMList = get(transactionVMClone, path);
        const shouldCheckForNewPolicies = get(transactionVM, 'value.baseData.jobType') === 'PolicyChange'
                && !viewOnly

        if (shouldCheckForNewPolicies) {
            checkForNewPolicies().then((hasNewPolicies) => {
                if (isEmpty(associatedPoliciesVMList.value) && !hasNewPolicies) {
                    createAssociatedPolicyVM();
                }
            });
        } else if (isEmpty(associatedPoliciesVMList.value)) {
            createAssociatedPolicyVM();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for handling a mouse-click on the "Add Policy" button.
     */
    const handleAddPolicyButtonClick = useCallback(
        (evt) => {
            evt.stopPropagation();
            createAssociatedPolicyVM();
        },
        [createAssociatedPolicyVM]
    );

    /**
     * Helper callback for handling a mouse-click on the "Save & Close" button.
     */
    const handleSaveAssociatedPolicyButtonClick = useCallback(
        (evt) => {
            evt.stopPropagation();
            onResolve(get(transactionVMClone, path));
        },
        [onResolve, path, transactionVMClone]
    );

    /**
     * Helper callback for handling a mouse-click on the "Remove" button.
     */
    const handleRemovePriorAssociatedPolicyButtonClick = useCallback(
        (evt) => {
            const policyList = get(transactionVMClone, path);
            const removePolicy = get(policyList, evt.path);
            const policyIndex = policyList.value.findIndex((policy) => isEqual(removePolicy.value, policy));
            const newPolicyList = remove(policyList.value, (policy) => isEqual(removePolicy.value, policy));

            disregardFieldValidation(`supportingPolicyPolicyID${policyIndex}`);
            set(transactionVMClone, [path, 'value'], newPolicyList.value);
            setTransactionVMClone(viewModelService.clone(transactionVMClone));
        },
        [disregardFieldValidation, path, transactionVMClone, viewModelService]
    );


    const onValueChange = useCallback((value, changedPath) => {
        const convertedPath = changedPath.replace(/children\.(\d+)/, '.children[$1]');

        set(transactionVMClone, path + convertedPath, value);
        setTransactionVMClone(viewModelService.clone(transactionVMClone));
    }, [path, transactionVMClone, viewModelService]);

    const isValid = useCallback(() => {
        // Check if two policies have same policy number
        const policies = get(transactionVMClone, `${path}.value`, []).map((policy) => policy.policyNumber);
        const policiesWithNumber = [];

        policies.forEach((policyNumber) => {
            if (policyNumber) {
                policiesWithNumber.push(policyNumber);
            }
        });

        return new Set(policiesWithNumber).size === policiesWithNumber.length;
    }, [path, transactionVMClone]);

    useEffect(() => {
        registerComponentValidation(isValid);
    }, [isValid, registerComponentValidation]);

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            onCancelButtonClick: handleCancelButtonClick,
            onAddPolicyButtonClick: handleAddPolicyButtonClick,
            onSaveAssociatedPolicyButtonClick: handleSaveAssociatedPolicyButtonClick,
            onRemovePriorAssociatedPolicyButtonClick: handleRemovePriorAssociatedPolicyButtonClick,
            // onCheckForNewPoliciesButtonClick: checkForNewPolicies
        }
    };

    const isPolicyRemovable = useCallback((policy) => {
        const associatedPolicies = get(transactionVMClone, path, []);

        if (get(policy, 'value.policySource') === 'Systematic') {
            return false;
        }

        if ((policy.policySource.value === 'Manual') && (associatedPolicies.value.length === 1)) {
            return false;
        }

        return true;
    }, [path, transactionVMClone]);

    /**
     * Generate property overrides for dynamic components on this Jutro component.
     */
    const generateOverrides = useCallback(() => {
        const overrideProps = {};
        const associatedPolicies = get(transactionVMClone, path, []);

        let policyType = '';
        const productCode = get(transactionVMClone, 'value.baseData.productCode');

        if (productCode === 'Homeowners_EH') {
            policyType = get(transactionVMClone, 'lobData.homeowners_EH.policyType.value.code', get(transactionVMClone, 'value.policyType'));
        }

        associatedPolicies.forEach((policy, index) => {
            overrideProps[`supportingPolicyPolicyID${index}`] = {
                viewOnlyMode: viewOnly,
                policyLineValues,
                currentPolicyType: policyType,
                currentProductCode: productCode,
                jobType: get(transactionVMClone, 'value.baseData.jobType')
            };
            overrideProps[`supportingPolicyDelete${index}`] = {
                visible: !viewOnly && isPolicyRemovable(policy)
            };
        });

        return overrideProps;
    }, [isPolicyRemovable, path, policyLineValues, transactionVMClone, viewOnly]);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        supportingPolicyModalMainDiv: {
            visible: !checkingNewPolicies
        },
        supportingPolicyModalLoader: {
            loaded: !checkingNewPolicies,
            text: translator(messages.checkingNewPolicies)
        },
        saveButton: {
            disabled: !isComponentValid,
            visible: !viewOnly
        },
        addPolicyButton: {
            disabled: !isComponentValid,
            visible: !viewOnly
        },
        supportingPolicyContainer: {
            path
        },
        supportingDuplicatePolicyErrorDiv: {
            visible: !isValid()
        },
        cancelButton: {
            content: viewOnly
                ? translator(e1pMessages.close)
                : translator(e1pMessages.supportingModalCancel),
            visible: newlyAddedPolicies.length === 0
        },
        supportingSubHeaderLine: {
            content: translator(messages.supportingSubHeader)
        },
        ...generateOverrides()
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ModalNext
            isOpen={isOpen}
            className={styles.supportingModalDialog}
        >
            <ModalHeader title={translator(e1pMessages.supportingPolicyToggle)} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    model={get(transactionVMClone, path)}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    onValueChange={onValueChange}
                />
            </ModalBody>
        </ModalNext>
    );
}

SupportingPolicyModal.propTypes = wizardProps;
export default withModalContext(SupportingPolicyModal);
