import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextPageMessage: {
        id: 'endorsement.ea.insuredDetailsPage.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    lossOccurredAfterChangeEffectiveDate: {
        id:
      'endorsement.ea.insuredDetailsPage.Loss Occurred After Change Effective Date',
        defaultMessage:
      'A loss for this risk occurred on or after the effective date of the change?'
    },
    lossOccurredAfterEffectiveDate: {
        id: "endorsement.ea.insuredDetailsPage.Has any Liability Loss occurred in the household between the effective date and today's date?",
        defaultMessage: "Has any Liability Loss occurred in the household between the effective date and today's date?"
    },
    lossOccurredAfterChangeEffectiveDateYes: {
        id:
      'endorsement.ea.insuredDetailsPage.Loss Occurred After Change Effective Date.Yes',
        defaultMessage: 'Yes'
    },
    lossOccurredAfterChangeEffectiveDateNo: {
        id:
      'endorsement.ea.insuredDetailsPage.Loss Occurred After Change Effective Date.No',
        defaultMessage: 'No'
    },
    policyInformation: {
        id: 'endorsement.ea.insuredDetailsPage.Policy Information',
        defaultMessage: 'Policy Information'
    },
    lineOfBusiness: {
        id: 'endorsement.ea.insuredDetailsPage.Line Of Business',
        defaultMessage: 'Line Of Business'
    },
    periodStartDate: {
        id: 'endorsement.ea.insuredDetailsPage.Effective Date',
        defaultMessage: 'Effective Date'
    },
    periodEndDate: {
        id: 'endorsement.ea.insuredDetailsPage.Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    rateAsOfDate: {
        id: 'endorsement.ea.insuredDetailsPage.Rate as of Date',
        defaultMessage: 'Rate as of Date'
    },
    riskState: {
        id: 'endorsement.ea.insuredDetailsPage.Risk State',
        defaultMessage: 'Risk State'
    },
    policyType: {
        id: 'endorsement.ea.insuredDetailsPage.Policy Type',
        defaultMessage: 'Policy Type'
    },
    insuredInfo: {
        id: 'endorsement.ea.insuredDetailsPage.Insured Information',
        defaultMessage: 'Insured Information'
    },
    insuredFirstName: {
        id: 'endorsement.ea.insuredDetailsPage.insuredInformation.FirstName',
        defaultMessage: 'First Name'
    },
    insuredMiddleName: {
        id: 'endorsement.ea.insuredDetailsPage.insuredInformation.MiddleName',
        defaultMessage: 'M.I.'
    },
    insuredLastName: {
        id: 'endorsement.ea.insuredDetailsPage.insuredInformation.Last Name',
        defaultMessage: 'Last Name'
    },
    insuredSuffix: {
        id: 'endorsement.ea.insuredDetailsPage.insuredInformation.Suffix',
        defaultMessage: 'Suffix'
    },
    insuredAddress: {
        id: 'endorsement.ea.insuredDetailsPage.Insured Residence Information',
        defaultMessage: 'Insured Residence Address'
    },
    insuredAddressAddressLine1: {
        id: 'endorsement.ea.insuredDetailsPage.insuredAddress.Address Line 1',
        defaultMessage: 'Address Line 1'
    },
    insuredAddressAddressLine2: {
        id: 'endorsement.ea.insuredDetailsPage.insuredAddress.Address Line 2',
        defaultMessage: 'Address Line 2'
    },
    insuredAddressAddressCity: {
        id: 'endorsement.ea.insuredDetailsPage.insuredAddress.City',
        defaultMessage: 'City'
    },
    insuredAddressAddressZipCode: {
        id: 'endorsement.ea.insuredDetailsPage.insuredAddress.ZipCode',
        defaultMessage: 'Zip Code'
    },
    insuredAddressAddressState: {
        id: 'endorsement.ea.insuredDetailsPage.insuredAddress.State',
        defaultMessage: 'State'
    },
    addNewPni: {
        id: 'endorsement.ea.insuredDetailsPage.pni.Add New Pni',
        defaultMessage: 'Add New Pni'
    },
    agencyInformation: {
        id: 'endorsement.ea.insuredDetailsPage.Agency Information',
        defaultMessage: 'Agency Information'
    },
    locationCode: {
        id: 'endorsement.ea.insuredDetailsPage.Location Code',
        defaultMessage: 'Location Code'
    },
    chooseProducerCode: {
        id: 'endorsement.ea.insuredDetailsPage.Choose Producer Code',
        defaultMessage: 'Please Select'
    },
    agencyName: {
        id: 'endorsement.ea.insuredDetailsPage.AgencyName',
        defaultMessage: 'Agency Name'
    },
    sourceOfEntry: {
        id: 'endorsement.ea.insuredDetailsPage.Source Type',
        defaultMessage: 'Source of Entry'
    },
    standardizingAddressMessage: {
        id: 'endorsement.ea.insuredDetailsPage.Standardizing address...',
        defaultMessage: 'Standardizing address...'
    },
    rentOrOwn: {
        id: 'endorsement.ea.insuredDetailsPage.Rent or Own?',
        defaultMessage: 'Rent or Own?'
    },
    own: {
        id: 'endorsement.ea.insuredDetailsPage.Rent Or Own.Own',
        defaultMessage: 'Own'
    },
    rent: {
        id: 'endorsement.ea.insuredDetailsPage.Rent Or Own.Rent',
        defaultMessage: 'Rent'
    },
    policyChangeDate: {
        id: 'endorsement.ea.insuredDetailsPage.Policy Change Effective Date',
        defaultMessage: 'Policy Change Effective Date'
    },
});
