import React, {
    useCallback, useContext, useState, useEffect, useMemo
} from 'react';
import {
    get as _get,
    set as _set,
    includes as _includes
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import '../RewriteEAPolicyDetailsPage.messages';
import {
    useNewBusinessUtil,
    useViewPolicyUtil,
    e1pUSStatesUtil
} from 'e1p-capability-hooks';
import appConfig from 'app-config';
import metadata from '../RewriteEAPolicyDetailsPage.metadata.json5';
import requiredMetadata from '../RewriteEAPolicyDetailsPage.requiredness';

const LOB = 'personalAuto_EA';

function ViewPolicyDetailsPage(props) {
    const {
        wizardData: rewriteVM,
        updateWizardData,
        authUserData,
        steps,
        currentStep,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        markFurthestStep,
        viewOnly,
        changeNextSteps
    } = props;
    const translator = useTranslator();
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [requiredFields, updateRequiredFields] = useState([]);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const policyState = _get(rewriteVM, 'baseData.policyAddress.state.value.code');
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [USStates, setUSStates] = useState(e1pUSStatesUtil.getUSStates(viewModelService));

    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');

    const {
        getLocationAndProducerCodes
    } = useNewBusinessUtil(
        rewriteVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        setLocationAndProducerCodes
    );

    // Add assignment page if the state is enabled
    const adjustStepsForState = useCallback(async () => {
        // check for enabled state
        const enabledStates = appConfig.driverAssignmentStates;
        const nextSteps = [...steps];
        const driverAssignmentStepIndex = nextSteps.findIndex((step) => step.path === '/view-assignment');

        // not needed for state and does not exist in steps already
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex < 0) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // not needed for the state, but previous state selected needed it
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.splice(driverAssignmentStepIndex, 1); // remove
            nextSteps.shift(); // remove current page (always first elt)
            changeNextSteps(nextSteps);

            return nextSteps;
        }

        // needed for the state, and already has it
        if (enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // needed for the state and not already in the steps
        const driverAssignmentPage = {
            id: 'RWEADriverAssignmentPage',
            title: {
                id: 'quoteandbind.ea.wizard.step.Drivers Assignment',
                defaultMessage: 'Driver Assignment'
            },
            path: '/view-assignment',
            component: 'E1PViewRewriteEADriverAssignmentPage',
            stepProps: {
                template: 'WizardPageTemplateWithTitle'
            }
        };

        const indexOfVehiclePage = nextSteps.findIndex((step) => step.path === '/view-risk-analysis');

        // insert driver assignment
        nextSteps.splice(indexOfVehiclePage, 0, driverAssignmentPage)
        // remove current page (always first elt)
        nextSteps.shift();
        changeNextSteps(nextSteps);

        return nextSteps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyState]);

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        setLocationAndProducerCodes(foundLocationCodes);
    };

    const {
        getLandingPageIndexForViewOnlyPolicy,
        getProducerDetails
    } = useViewPolicyUtil();

    useEffect(() => {
        const stateValues = e1pUSStatesUtil.getUSStates(viewModelService);

        setUSStates(stateValues);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initialRequiredFields = ['applicantsResideInSameStateInd', 'isUmbrellaAssociated']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
    }, [policyState]);

    useEffect(() => {
        // adjust steps before jumping
        adjustStepsForState().then((newSteps) => {
            // need all steps
            newSteps.unshift(currentStep);

            // in view only flow we want to make each step as visited so that user
            // can navigate to any page by clicking on tabs on chevron
            if (isPageJumpEnabled) {
                updateIsPageJumpEnabled(false);
                jumpTo(getLandingPageIndexForViewOnlyPolicy(LOB, newSteps, 'Submission'), true);
            }

            markFurthestStep((newSteps.length - 1));

            if (isAgent) {
                getAndSetLocationCodes(_get(rewriteVM, 'baseData.policyAddress.state.value.code'));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isProducerCodeDisabled = useMemo(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(rewriteVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(rewriteVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);

            return true;
        }

        return false;
    }, [locationAndProducerCodes, rewriteVM]);

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            setLocationAndProducerCodes(
                [
                    {
                        producerCodes: { code: producerCode, name: producerCode },
                        locationCodes: { code: externalID, name: externalID }
                    }
                ]
            );
            _set(rewriteVM, 'baseData.producerCode_Ext', producerCode);
            _set(rewriteVM, 'baseData.externalID_Ext.value', externalID);
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [rewriteVM],
    );

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(() => rewriteVM, [rewriteVM]);

    useEffect(() => {
        if (rewriteVM.baseData.externalID_Ext?.value) {
            getProducerDetails(
                rewriteVM.baseData.externalID_Ext.value, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
                setAgencyName(details.agencyName);
                setBookTransferIndicator(details.bookTransferIndicator);
                setServiceCenterIndicator(details.serviceCenterIndicatorValue);
            }).catch((error) => {
                /**
                 * E1PAP1PC-15099 :
                 * This is rare scenario; will never happen in production
                 * When we bound policy using dummy producer code
                 * and when other user tries to view this policy we get exception from service
                 */
                if (rewriteVM.baseData.value.exceptions_Ext) {
                    rewriteVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        rewriteVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(rewriteVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewriteVM.baseData.producerCode_Ext.value]);

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: undefined,
            onRemoveSni: undefined,
            disclosureLinkHandler: undefined,
            disclosureCheckHandlerClick: undefined,
            guidelinesLinkHandler: undefined,
            guidelinesCheckHandler: undefined,
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnly
        },
        policyDetailsPageLoadingIndicator: {
            loaded: true
        },
        effectiveDateValidationMessage: {
            visible: false
        },
        policyDetailsPageContainer: {
            visible: true
        },
        sniContainer: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        coverageStartDate: {
            dateDTO: rewriteVM.baseData.periodStartDate,
            defaultToToday: true,
            showErrors: true
        },
        excessLiabilityInd: {
            required: true,
            visible: _includes(requiredFields, 'isUmbrellaAssociated'),
        },
        coverageEndDate: {
            dateDTO: rewriteVM.baseData.periodEndDate,
            updateDateDto: () => updateWizardData(rewriteVM),
            readOnly: true
        },
        originalEffectiveDate: {
            dateDTO: rewriteVM.baseData.periodStartDate,
            updateDateDto: () => updateWizardData(rewriteVM),
            readOnly: true,
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        addSni: {
            visible: false
        },
        removeSni: {
            visible: false
        },
        secondaryNamedInsured: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            viewOnlyMode: true
        },
        noSecondaryNamedInsuredMessageID: {
            visible: !_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        accountSearchComponent: {
            visible: false
        },
        submissionPolicyType: {
            readOnly: true
        },
        mailingAndBillingAddressComponent: {
            transactionVM: rewriteVM,
            viewOnlyMode: true,
            lob: LOB
        },
        e1pAcknowledgementComponent: {
            acknowledgements: _get(rewriteVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: _get(rewriteVM, 'baseData.policyAddress.state.value.code'),
                name: translator({ id: _get(rewriteVM, 'baseData.policyAddress.state.value.name') })
            },
            viewOnly: true,
            readOnly: viewOnly,
            lob: LOB
        },
        insuredResidenceAddress: {
            addressVM: _get(rewriteVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            viewOnlyMode: true
        },
        primaryNamedInsured: {
            viewOnlyMode: true,
            isPNI: true
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            readOnly: isProducerCodeDisabled,
            visible: isAgent
        },
        policyState: {
            availableValues: e1pUSStatesUtil.getStateValues(USStates, translator)
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        changePniComponent: {
            visible: false
        },
        changeSniComponent: {
            visible: false
        },
        ExternalProducerDetailsComponent: {
            defaultValue: rewriteVM.baseData.externalID_Ext?.value,
            producerCodeDetails,
            viewOnlyMode: true,
            visible: !isAgent
        },
        applicantsResideInSameStateInd: {
            required: true,
            visible: _includes(requiredFields, 'applicantsResideInSameStateInd'),
        },
        swapPniSni: {
            visible: false
        },
        agencyDetailsContainer: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: rewriteVM,
            authHeader,
            LOB: 'personalAuto_EA',
            viewOnlyMode: true
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            showPrevious={false}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

ViewPolicyDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewPolicyDetailsPage);
