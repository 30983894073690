import { defineMessages } from 'react-intl';

export default defineMessages({
    startNewQuote: {
        id: 'e1p.gateway.views.new-quote.Start New Quote',
        defaultMessage: 'Start New Quote',
    },
    searchPlaceHolder: {
        id: 'e1p.gateway.views.search-quote.Search by account or policy number',
        defaultMessage: 'Search by account or policy number...',
    },
    login: {
        id: 'e1p.auth.components.AuthStatus.Login',
        defaultMessage: 'Login',
    },
    account: {
        id: 'e1p.auth.components.AuthStatus.Account',
        defaultMessage: 'Account',
    },
    preferences: {
        id: 'e1p.auth.components.AuthStatus.Preferences',
        defaultMessage: 'Preferences',
    },
    signOut: {
        id: 'e1p.auth.components.AuthStatus.Sign Out',
        defaultMessage: 'Sign Out',
    },
    quoteAnyAccount: {
        id: 'e1p.gateway.views.new-quote.Quote for Any Account',
        defaultMessage: 'Quote for Any Account'
    },
    quoteThisAccount: {
        id: 'e1p.gateway.views.new-quote.Quote for This Account',
        defaultMessage: 'Quote for This Account'
    },
    searchBy: {
        id: 'e1p.gateway.views.search-quote.Search by Policy/Quote Number...',
        defaultMessage: 'Search by Policy/Quote Number...'
    },
    FAQ: {
        id: 'e1p.portal.index.FAQ',
        defaultMessage: 'FAQ'
    }
});
