import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleAdd: {
        id: 'components.ehCoverages.schedules.table.Add Item',
        defaultMessage: 'Add Item',
    },
    scheduleCancel: {
        id: 'components.ehCoverages.schedules.table.Cancel',
        defaultMessage: 'Cancel',
    },
    scheduleRemoveTitle: {
        id: 'components.ehCoverages.schedules.table.Remove Scheduled Item?',
        defaultMessage: 'Remove Scheduled Item?',
    },
    scheduleRemove: {
        id: 'components.ehCoverages.schedules.table.Are you sure you want to remove this item?',
        defaultMessage: 'Are you sure you want to remove this item?',
    },
    noItems: {
        id: 'components.ehCoverages.schedules.table-readonly.No items',
        defaultMessage: 'No items'
    },
    delete: {
        id: 'components.ehCoverages.schedules.table.Delete',
        defaultMessage: 'Delete'
    },
    cancel: {
        id: 'components.ehCoverages.schedules.table.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'components.ehCoverages.schedules.table.Save',
        defaultMessage: 'Save'
    },
    listOfScheduledItems: {
        id: 'components.ehCoverages.schedules.table.List of Scheduled Items',
        defaultMessage: 'List of Scheduled Items'
    }
});
