import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo,
    useRef
} from 'react';
import {
    get as _get,
    set as _set,
    findIndex as _findIndex,
    isEmpty as _isEmpty,
    some as _some,
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { e1pDateUtil } from 'e1p-capability-hooks';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { PrefillService, LoadSaveService } from 'e1p-capability-quoteandbind';
import { CreditService, InspectionService } from 'e1p-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { PropertyFlowUtil } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages, ehCommonMessages } from 'e1p-platform-translations'; 
import config from 'app-config';
import metadata from './PropertyPage.metadata.json5';
import styles from './PropertyPage.module.scss';

function PropertyPage(props) {
    const translator = useTranslator();
    // eslint-disable-max-len
    const { authHeader } = useAuthentication();
    const {
        wizardData: submissionVM,
        updateWizardData,
        isSkipping,
        jumpTo, steps,
        updateWizardSnapshot,
        setShouldSkipAdditionalInfo,
        setIsPropertyPrefillOrderedMessage
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const [isReplacementCostStale, setIsReplacementCostStale] = useState(false);
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [prefillLoaded, updatePrefillLoaded] = useState(true);
    const [updateDraftSubmissionLoader, updateUpdateDraftSubmissionLoader] = useState(false);
    const [prefillResponse, setPrefillResponse] = useState(undefined);
    const [heatingSystemType, updateHeatingSystemType] = useState(undefined);
    const [coolingSystemType, updateCoolingSystemType] = useState(undefined);
    const [creditScoreMoreThan597, updateCreditScoreMoreThan597] = useState(false);
    const [creditReportLoaded, setCreditReportLoaded] = useState(true);
    const [showRoofMessage, setShowRoofMessage] = useState(false);
    const [isOutstandingChangesPresent, setIsOutstandingChangesPresent] = useState(true);
    const [isReplacementRecalculateCompleted, setIsReplacementRecalculateCompleted] = useState(true);
    const [savingPrefillData, setSavingPrefillData] = useState(false);
    const prefillHitOnPageEnter = useRef(false);
    const updateInspectionData = useRef(false);
    const numberOfUnitsFromPrefill = useRef(undefined);
    const [isPrefillSuccessful, setIsPrefillSuccessful] = useState(true);
    const [isPartialPrefill, setIsPartialPrefill] = useState(false);
    const [isCoverageATermValWasPresentAndRCChanged, setIsCoverageATermValWasPresentAndRCChanged] = useState(undefined);

    const {
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation,
        onValidate,
        registerComponentValidation
    } = useValidation('PropertyPage');
    const hasReplacementCost = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value', undefined);

    const currentDateYear = useMemo(() => e1pDateUtil.getCurrentDateYear(), []);
    const { minYearBuilt } = config.enterpriseHomeownersConfig;

    // if validation errors are thrown we cant jump to cov page
    const shouldNotGoToCoverages = (apiResponse) => {
        const validationErrors = _get(apiResponse, 'errorsAndWarnings.validationIssues.fieldIssues', []);
        const exceptions = _get(apiResponse, 'baseData.exceptions_Ext', []);

        return validationErrors.length > 0 || exceptions.length > 0;
    };

    const addInsideWallMaterial = useCallback(() => {
        const wallMaterialNotProvided = _isEmpty(
            _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value', [])
        );
        const wallMaterial = {
            materialType: '',
            percentage: ''
        };

        if (wallMaterialNotProvided) {
            wallMaterial.percentage = 100;
        }

        const {
            _xCenter,
            _dtoName,
        } = submissionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials;
        const wallMaterialVM = viewModelService.create(wallMaterial, _xCenter, _dtoName);

        submissionVM.lobData.homeowners_EH.coverables.construction.insideWallMaterials.value.push(
            wallMaterialVM.value
        );

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(submissionVM);
    }, [hasReplacementCost, submissionVM, updateWizardData, viewModelService]);

    const addFloorMaterial = useCallback(() => {
        const floorMaterialNotProvided = _isEmpty(
            _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.floors.value', [])
        );
        const floor = {
            floorType: '',
            percentage: ''
        };

        if (floorMaterialNotProvided) {
            floor.percentage = 100;
        }

        const {
            _xCenter,
            _dtoName,
        } = submissionVM.lobData.homeowners_EH.coverables.construction.floors;
        const floorMaterialVM = viewModelService.create(floor, _xCenter, _dtoName);

        submissionVM.lobData.homeowners_EH.coverables.construction.floors.value.push(
            floorMaterialVM.value
        );

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(submissionVM);
    }, [hasReplacementCost, submissionVM, updateWizardData, viewModelService]);

    const addGarage = useCallback(() => {
        const garage = {
            garageType: '',
            garageSize: ''
        };
        const {
            _xCenter,
            _dtoName,
        } = submissionVM.lobData.homeowners_EH.coverables.construction.garages;
        const garageVM = viewModelService.create(garage, _xCenter, _dtoName);

        submissionVM.lobData.homeowners_EH.coverables.construction.garages.value.push(
            garageVM.value
        );

        if (hasReplacementCost) {
            setIsReplacementCostStale(true);
            _set(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                undefined
            );
        }

        updateWizardData(submissionVM);
    }, [hasReplacementCost, submissionVM, updateWizardData, viewModelService]);

    const checkMultiInstanceFieldAndAddDefaultIfNotPresent = useCallback(() => {
        const garageValueNotProvided = _isEmpty(
            _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.garages.value', [])
        );

        if (garageValueNotProvided) {
            addGarage();
        }

        const floorMaterialNotProvided = _isEmpty(
            _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.floors.value', [])
        );

        if (floorMaterialNotProvided) {
            addFloorMaterial();
        }

        const wallMaterialNotProvided = _isEmpty(
            _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value', [])
        );

        if (wallMaterialNotProvided) {
            addInsideWallMaterial();
        }

        updateWizardData(submissionVM);
    }, [addFloorMaterial, addGarage, addInsideWallMaterial, submissionVM, updateWizardData]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && hasReplacementCost && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted, hasReplacementCost]);

    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            if (!prefillHitOnPageEnter.current) {
                /**
                 * IAP-4661 : allow recalculate on save
                 */
                if (!isComponentValid
                    || ((!hasReplacementCost
                        || isReplacementCostStale)
                        && !calledFromOnSave)
                ) {
                    updateIsPageSubmitted(true);
                    setIsOutstandingChangesPresent(true);
                    window.scrollTo(0, 0);

                    return false;
                }
            }

            prefillHitOnPageEnter.current = false;

            // Set a different message for replacement cost
            if (isReplacementCostStale && !calledFromOnSave) {
                setIsOutstandingChangesPresent(true);
                // don't need to show this message
                updateIsPageSubmitted(false);
                window.scrollTo(0, 0);

                return false;
            }

            updateUpdateDraftSubmissionLoader(true);

            const quoteData = _get(submissionVM, 'quoteData.value');
            let serverCall = LoadSaveService.saveAndQuoteSubmission

            // if clicked on save and if we dont have replacement cost want to save existing data with update draft call
            if (calledFromOnSave
                && (!hasReplacementCost || isReplacementCostStale)) {
                serverCall = LoadSaveService.updateDraftSubmission
                // take of replacement cost error
                updateIsPageSubmitted(false);
            }

            const response = await serverCall(submissionVM.value, authHeader);
            const respQuoteData = _get(response, 'quoteData');

            if (respQuoteData === undefined) {
                _set(response, 'quoteData', quoteData);
            }

            _set(submissionVM, 'value', response);
            updateWizardData(submissionVM);

            const paymentStep = steps.find((step) => step.id === 'EHPaymentDetailsPage');

            if (paymentStep && updateInspectionData.current) {
                const inspectionDetails = await InspectionService.runInspection(_get(submissionVM, 'quoteID.value'), authHeader);

                _set(
                    submissionVM,
                    'lobData.homeowners_EH.coverables.yourHome.inspectionDetails',
                    inspectionDetails
                );
                updateWizardData(submissionVM);
            }

            updateUpdateDraftSubmissionLoader(false);

            // Check for validation errors and stop page before jumping
            if (shouldNotGoToCoverages(submissionVM.value)) {
                // stay on page
                updateWizardData(submissionVM);
                updateUpdateDraftSubmissionLoader(false);

                return false;
            }

            const coveragesIndex = _findIndex(steps, ({ path }) => path === '/coverages');

            updateWizardSnapshot(submissionVM);

            // Don't want to jump in case of save call
            if (!calledFromOnSave) {
                jumpTo(coveragesIndex, true, submissionVM.value);
            }

            // Must return false to deny wizardpageonnext promise
            return false;
        },
        [
            isComponentValid, hasReplacementCost, isReplacementCostStale, submissionVM,
            authHeader, updateWizardData, steps, updateWizardSnapshot, jumpTo
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);
                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext]
    );

    const defaultFieldsNotGettingFromPrefill = () => {
        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.totalNumberOfPeopleLiving.value', 2);
        numberOfUnitsFromPrefill.current = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value');

        if (_get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value') === undefined) {
            _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value', 'One');
        }

        _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value', false);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.doesYourHomeHaveFence.value', false);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasWoodOrCoalStove.value', false);

        if (_get(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value') === undefined) {
            _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value', false);
        }

        if (_get(submissionVM, 'lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value') === undefined) {
            _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value',
                _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.yearBuilt.value'));
        }

        updateWizardData(submissionVM);
    };


    // E1PAP1PC-15825
    // Remove default if partial prefill
    const removeDefaultFieldsNotGettingFromPrefill = () => {
        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.totalNumberOfPeopleLiving.value', undefined);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value', numberOfUnitsFromPrefill.current);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.doesYourHomeHaveFence.value', undefined);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasWoodOrCoalStove.value', undefined);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value', undefined);
        updateWizardData(submissionVM);
    };

    const getPrefill = (async () => {
        if (submissionVM.value.lobData.homeowners_EH.coverables.construction.yearBuilt === undefined && submissionVM.value.quoteType !== 'Full') {
            updatePrefillLoaded(false);
            PrefillService.loadPrefill(
                submissionVM.value,
                authHeader
            ).then((response) => {
                setPrefillResponse(response.coverables);
                setIsPrefillSuccessful(PropertyFlowUtil.checkIfPrefillIsSuccessful(response));
                updatePrefillLoaded(true);
            }).catch(() => {
                if (!_isEmpty(_get(submissionVM, 'baseData.value.exceptions_Ext', []))) {
                    submissionVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                    );
                } else {
                    _set(
                        submissionVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                    );
                }

                updateWizardData(submissionVM);
                updatePrefillLoaded(true);
                setIsPrefillSuccessful(false);
                checkMultiInstanceFieldAndAddDefaultIfNotPresent();
            });
        } else {
            checkMultiInstanceFieldAndAddDefaultIfNotPresent();
            // prefill call was not needed, but page still visited
            setShouldSkipAdditionalInfo(true);
        }
    });

    const getCreditData = (async () => {
        if (!isSkipping) {
            setCreditReportLoaded(false);
            CreditService.getCreditReport(
                _get(submissionVM, 'quoteID.value'),
                authHeader
            ).then((response) => {
                const reportWithoutNoHit = response.creditRecords.find((creditRecord) => creditRecord.reportStatus !== 'NOHIT');

                if (reportWithoutNoHit !== undefined) {
                    updateCreditScoreMoreThan597(reportWithoutNoHit.creditScore >= 597);
                }

                setCreditReportLoaded(true);
            }).catch((exception) => {
                if (submissionVM.value.baseData.exceptions_Ext) {
                    submissionVM.value.baseData.exceptions_Ext.push(
                        { errorMessage: exception.baseError ? exception.baseError : exception.message }
                    );
                } else {
                    _set(
                        submissionVM.value.baseData,
                        `exceptions_Ext[${0}]`,
                        { errorMessage: exception.baseError ? exception.baseError : exception.message }
                    );
                }

                updateWizardData(submissionVM);
                updateCreditScoreMoreThan597(false);
            }).finally(() => {
                setCreditReportLoaded(true);
            });
        }
    });

    const shouldShowRoofMessage = () => {
        let tempShowMessage = false;
        const roofTypeListForRoofAgelessThan25 = ['asphaltshingles', 'compositionshingles', 'woodshinglesshakes', 'tarorgravel'];
        const roofTypeListForRoofAgeMoreThan70 = ['claytile', 'slate', 'concretetile'];
        const roofAge = e1pDateUtil.calculateAge(_get(submissionVM, 'lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value'));

        if (roofTypeListForRoofAgelessThan25.includes(_get(submissionVM, 'lobData.homeowners_EH.coverables.construction.roofType.value.code'))
            && roofAge > 25) {
            setShowRoofMessage(true);
            tempShowMessage = true;
        } else if (roofTypeListForRoofAgeMoreThan70.includes(_get(submissionVM, 'lobData.homeowners_EH.coverables.construction.roofType.value.code'))) {
            if (roofAge > 70) {
                setShowRoofMessage(true);
                tempShowMessage = true;
            }
        }

        return tempShowMessage;
    };

    const setDefualtPercentageForWallMaterialAndFloorType = useCallback(
        () => {
            const floorMaterials = _get(
                submissionVM,
                'lobData.homeowners_EH.coverables.construction.floors.value', []
            );

            if (floorMaterials.length === 1
                && _get(floorMaterials, ['0', 'percentage']) === undefined) {
                _set(floorMaterials, ['0', 'percentage'], 100);
            }

            const wallMaterials = _get(
                submissionVM,
                'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value', []
            );

            if (wallMaterials.length === 1
                && _get(wallMaterials, ['0', 'percentage']) === undefined) {
                _set(wallMaterials, ['0', 'percentage'], 100);
            }
        }, [submissionVM]
    );

    useEffect(() => {
        if (prefillResponse !== undefined) {
            const usageType = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value');
            const isThisNewHomePurchase = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.isThisNewHomePurchase.value');
            const householdOccupants = _get(submissionVM, 'lobData.homeowners_EH.coverables.householdOccupants.value');
            const ehLocation = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.ehlocation.value');

            _set(submissionVM.lobData.homeowners_EH.coverables, 'value', prefillResponse);
            _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value', usageType);
            _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.isThisNewHomePurchase.value', isThisNewHomePurchase);
            _set(submissionVM, 'lobData.homeowners_EH.coverables.householdOccupants.value', householdOccupants);
            _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.ehlocation.value', ehLocation);
            defaultFieldsNotGettingFromPrefill();

            const heatingSystems = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');
            let tempHeatingSystemType;

            if (heatingSystems !== undefined && heatingSystems.length > 0) {
                updateHeatingSystemType(heatingSystems[0].heatingType);
                tempHeatingSystemType = heatingSystems[0].heatingType;
            }

            const coolingSystems = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');
            let tempCoolingSystemType;

            if (coolingSystems !== undefined && coolingSystems.length > 0) {
                updateCoolingSystemType(coolingSystems[0].coolingType);
                tempCoolingSystemType = coolingSystems[0].coolingType;
            }

            // E1PAP1PC-15435 , E1PAP1PC-15435 :
            // default percentage for wall and floor material to 100 if not present
            setDefualtPercentageForWallMaterialAndFloorType();
            updateWizardData(submissionVM);

            // E1PAP1PC-7590 : Show Roof Age Informational Message Based on Conditions
            const tempShowMessage = shouldShowRoofMessage();

            const replacmentCost = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value');

            updatePrefillLoaded(true);

            // E1PAP1PC-15825 :
            // if prefill missing some required values stay on the property page
            const allRequiredFieldsPresent = PropertyFlowUtil.checkAllRequiredFieldsArePresentHF9(
                submissionVM, creditScoreMoreThan597, false, currentDateYear,
                minYearBuilt, tempHeatingSystemType, tempCoolingSystemType
            );

            setIsPartialPrefill(!allRequiredFieldsPresent);

            const hasNoExceptions = _isEmpty(_get(submissionVM, 'baseData.exceptions_Ext.value', []));

            if (
                replacmentCost !== undefined && replacmentCost > 0
                && !tempShowMessage
                && hasNoExceptions
                && allRequiredFieldsPresent
            ) {
                // E1PAP1PC-15359 - prefill is successful and all required fields are present
                // show message on coverage page
                setIsPropertyPrefillOrderedMessage(true);
                prefillHitOnPageEnter.current = true;
                onNext();
            } else {
                // E1PAP1PC-7013 - For EH policies if prefill is a no hit and there is no information that is
                //   returned or partial data returned then there will be no default value on property page
                //   for any field and user is forced to enter data
                //   Additional information pop-up should not display
                setShouldSkipAdditionalInfo(true);
                // E1PAP1PC-15700 : save prefill data
                _set(submissionVM, 'value.flowStepIDs_Ext', ['property']);
                setSavingPrefillData(true);
                removeDefaultFieldsNotGettingFromPrefill();
                LoadSaveService.updateDraftSubmission(
                    submissionVM.value,
                    authHeader
                ).then((response) => {
                    _set(submissionVM, 'value', response);
                    updateWizardData(submissionVM);
                    updateWizardSnapshot(submissionVM);
                }).finally(() => {
                    setSavingPrefillData(false);
                    checkMultiInstanceFieldAndAddDefaultIfNotPresent();
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefillResponse]);

    useEffect(() => {
        if (_isEmpty(submissionVM.lobData.homeowners_EH.coverables.yourHome.value)) {
            submissionVM.lobData.homeowners_EH.coverables.yourHome = {};
        }

        if (_isEmpty(submissionVM.lobData.homeowners_EH.coverables.construction.value)) {
            submissionVM.lobData.homeowners_EH.coverables.construction = {};
        }

        getPrefill();
        getCreditData();

        const heatingSystems = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            updateHeatingSystemType(heatingSystems[0].heatingType);
        }

        const coolingSystems = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');

        if (coolingSystems !== undefined && coolingSystems.length > 0) {
            updateCoolingSystemType(coolingSystems[0].coolingType);
        }

        updateWizardData(submissionVM);
        setPageInitialized(true);

        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    /**
     * Below are the required fields for replacement cost to be recalculated.
     */
    const requiredFieldsForReplacementCost = [
        'yearBuilt',
        'numberOfStories',
        'totalSquareFeet',
        'exteriorWallFinish',
        'roofType',
        'roofShape',
        'garages', // has children
        'slopeType',
        'numberOfRoomsWithCathedralVaultedCeilings',
        'numberOfRoomsWithCrownMolding',
        'ceilingHeightType',
        'numberOfFullBaths',
        'numberOfHalfBaths',
        'kitchenCounterTopType',
        'foundationType',
        'basementFinishedAreaType',
        'heatingSystems',
        'coolingSystems',
        'insideWallMaterials', // has children
        'floors'];

    /**
     * isRecalculateReplacementCostDisable, will save Boolean value, which will be used to enable or disable
     * the recalculate ReplacementCost button on UI, if any of the value in requiredFieldsForReplacementCost array
     * is undefined or empty, then it will returns true, else false.
     * @param {VMNode} viewModel
     * @returns {boolean}
     */
    const isRecalculateReplacementCostDisable = (viewModel) => {
        const isDisabled = _some(requiredFieldsForReplacementCost, (requiredField) => {
            const requiredFieldValue = _get(viewModel.lobData.homeowners_EH.coverables.construction, `${requiredField}.value`);

            // requiredFieldValue could be string, integer, or array.
            if (Array.isArray(requiredFieldValue)) {
                return requiredFieldValue.length === 0;
            }

            return requiredFieldValue === undefined || requiredFieldValue === '';
        });

        return isDisabled || !isComponentValid;
    };

    const isRecalculateRequired = !hasReplacementCost && !isRecalculateReplacementCostDisable(submissionVM);

    const recalculateReplacementCost = useCallback(
        async () => {
            if (isRecalculateRequired) {
                setIsReplacementRecalculateCompleted(false);
                PrefillService.getReplacementCost(
                    submissionVM.value,
                    authHeader
                ).then((response) => {
                    if (response?.exceptions_Ext) {
                        submissionVM.baseData.exceptions_Ext = response?.exceptions_Ext;
                        updateWizardData(submissionVM);
                        setIsReplacementRecalculateCompleted(true);
                    } else {
                        setIsOutstandingChangesPresent(true);
                        setIsReplacementCostStale(false);

                        // IAP-437 : show info message on recalculate
                        // we are checking cov term value as it will set only after first recalculate or on quote call
                        let selectedOfferingIndex = 0;

                        if (_get(submissionVM, 'value.lobData.homeowners_EH.offerings', []).length > 1) {
                            const offeredQuotes = _get(submissionVM, 'quoteData.offeredQuotes.value', []);
                            const selectedQuoteBranchCode = offeredQuotes?.find((quote) => quote.selected)?.branchCode;

                            if (selectedQuoteBranchCode) {
                                selectedOfferingIndex = _get(submissionVM, 'value.lobData.homeowners_EH.offerings', [])
                                    .findIndex((offering) => offering.branchCode === selectedQuoteBranchCode)
                            }
                        }

                        const dwellingCovA = _get(submissionVM,
                            `value.lobData.homeowners_EH.offerings[${selectedOfferingIndex}].coverages.coverages`,
                            []).find((coverage) => coverage.codeIdentifier === 'EH_DwellingCovA');
                        const dwellingCovAHasTermValue = !_isEmpty(_get(dwellingCovA, 'terms', [])
                            .find((term) => term.patternCodeIdentifier === 'EH_DwellingCovA_Limit')?.chosenTermValue);

                        if (isCoverageATermValWasPresentAndRCChanged === undefined) {
                            setIsCoverageATermValWasPresentAndRCChanged(dwellingCovAHasTermValue);
                        }

                        // E1PAP1PC-15700 : save all data till this point
                        _set(submissionVM.lobData.homeowners_EH.coverables, 'value', response.coverables);
                        _set(submissionVM, 'value.flowStepIDs_Ext', ['property']);
                        LoadSaveService.updateDraftSubmission(
                            submissionVM.value,
                            authHeader
                        ).then((updateDraftResp) => {
                            _set(submissionVM, 'value', updateDraftResp);
                            updateWizardData(submissionVM);
                            updateWizardSnapshot(submissionVM);
                        }).finally(() => {
                            setIsReplacementRecalculateCompleted(true);
                        });
                    }
                }).catch(() => {
                    setIsReplacementRecalculateCompleted(true);

                    if (!_isEmpty(_get(submissionVM, 'baseData.value.exceptions_Ext', []))) {
                        submissionVM.baseData.value.exceptions_Ext.push(
                            { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                        );
                    } else {
                        _set(
                            submissionVM.value,
                            `baseData.exceptions_Ext[${0}]`,
                            { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                        );
                    }

                    updateWizardData(submissionVM);
                })

            } else {
                setIsOutstandingChangesPresent(false);
            }
        },
        [
            isRecalculateRequired, submissionVM, authHeader, updateWizardData,
            isCoverageATermValWasPresentAndRCChanged, updateWizardSnapshot,
            translator
        ]
    );

    const resolvers = {
        resolveCallbackMap: {
            recalculateReplacementCostClick: recalculateReplacementCost,
            onValidate,
        },
        resolveClassNameMap: styles
    };



    const getLoadingIndicatorMessage = useMemo(
        () => {
            let loadingMessage = '';

            if (isSavingCurrentPageChanges) {
                loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
            } else if (!prefillLoaded) {
                loadingMessage = translator(ehCommonMessages.loadingPrefill);
            } else if (!isPageInitialized || isSkipping) {
                loadingMessage = translator(ehCommonMessages.loadingNextPageMessage);
            } else if (!isReplacementRecalculateCompleted) {
                loadingMessage = translator(ehCommonMessages.recalculatingReplacementCostAndSavingAllInfo);
            } else if (savingPrefillData) {
                loadingMessage = translator(ehCommonMessages.savingPrefillData);
            }

            return loadingMessage;
        },
        [
            isSavingCurrentPageChanges, prefillLoaded, isPageInitialized, translator,
            isSkipping, isReplacementRecalculateCompleted, savingPrefillData
        ]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => prefillLoaded && creditReportLoaded
            && !updateDraftSubmissionLoader && !isSkipping
            && !isSavingCurrentPageChanges
            && isReplacementRecalculateCompleted
            && !savingPrefillData,
        [creditReportLoaded, isReplacementRecalculateCompleted, isSavingCurrentPageChanges,
            isSkipping, prefillLoaded, savingPrefillData, updateDraftSubmissionLoader]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        replacementCostMissingNotificationDiv: {
            visible: isPageSubmitted
                && !_get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value')
        },
        noOutstandingChangesNotificationDiv: {
            visible: !isOutstandingChangesPresent
        },
        replacementCostRecalculate: {
            disabled: isRecalculateReplacementCostDisable(submissionVM)
        },
        propertyPageLoader: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        propertyPageMainDiv: {
            visible: isPageLoaded
        },
        roofAgeMessageDiv: {
            visible: showRoofMessage && isPrefillSuccessful
        },
        partialPrefillNotificationDiv: {
            // IAP-1259 : dont show this message when roof age messqge is shown
            visible: isPartialPrefill && isPrefillSuccessful && !showRoofMessage
        },
        propertyPrefillUnsuccessfulInfoMessageDiv: {
            visible: !isPrefillSuccessful
        },
        covAWillBeUpdatedBasedOnReplacementCostInfoMessageDiv: {
            visible: !!isCoverageATermValWasPresentAndRCChanged
        },
        e1pEHHF9PropertyDetailsComponent: {
            transactionVM: submissionVM,
            updateWizardData,
            isPageSubmitted,
            heatingSystemType,
            updateHeatingSystemType,
            coolingSystemType,
            updateCoolingSystemType,
            setIsReplacementCostStale,
            creditScoreMoreThan597
        }
    };

    // this will be checked if isSkipping is true,
    // and will stop on this page if the condition returns false
    useEffect(() => {
        // For skipWhen
        registerInitialComponentValidation(
            () =>
                !!_get(
                    submissionVM,
                    'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value',
                    undefined
                )
        );
    }, [registerInitialComponentValidation, submissionVM]);

    useEffect(() => {
        registerComponentValidation(() => (heatingSystemType !== undefined
                && coolingSystemType !== undefined))
    }, [coolingSystemType, heatingSystemType, registerComponentValidation]);

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVM]
    );

    if (!isPageInitialized) {
        return null;
    }


    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
