/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useContext, useState } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

/**
 * IAP-3680 : risk role attribute changes
 * Context to store config date
 */
export const E1PAttributeConfigStateContext = React.createContext({
    attributeConfig: undefined,
    setAtributeConfig: () => { }
});

export const AttributeConfigContextProvider = (props) => {
    const [attributeConfig, setAtributeConfig] = useState(undefined);

    return (
        <E1PAttributeConfigStateContext.Provider value={{ attributeConfig, setAtributeConfig }}>
            {props.children}
        </E1PAttributeConfigStateContext.Provider>
    )
}

// used to wrap around class components
export function withE1PAttributeConfigStateContext(WrappedComponent) {
    function WithE1PAttributeConfigContext(props) {
        const attributeConfigProps = useContext(E1PAttributeConfigStateContext);

        return <WrappedComponent {...attributeConfigProps} {...props} />;
    }

    hoistNonReactStatic(WithE1PAttributeConfigContext, WrappedComponent);

    return WithE1PAttributeConfigContext;
}
// used for functional components
export function useE1PAttributeConfigStateContext() {
    return useContext(E1PAttributeConfigStateContext);
}
export const E1PAttributeConfigContextProvider = AttributeConfigContextProvider;
export default E1PAttributeConfigStateContext.Consumer;
