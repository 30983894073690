 
const overrides = {
    field: {
        yearBuilt: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        styleOfHome: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        numberOfStories: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        numberOfUnits: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        totalSquareFeet: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        propertySlope: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        foundationType: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        basementFinishedArea: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        extereriorWallFinish: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        yearRoofInstalled: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        roofMaterial: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        roofShape: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        ceilingHeight: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        roomsWithCathedralCeilings: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        roomsWithCrownMoldings: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        kitchenCountertop: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        numberOfHalfBaths: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        numberOfFullBaths: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        totalNumberOfPeopleLiving: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        hasSwimmingPool: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        doesYourHomeHaveFence: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        hasDogInHome: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        highRiskDogBreedInd: {
            partner: {
                MSA: {
                    // IAP-5194, highRiskDogBreedInd not available for IL
                    states: ['IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        primaryHeatingSystem: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        primaryCoolingSystem: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        basementFinishedAreaType: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']
                }
            }
        },
        hasWoodOrCoalStove: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT', 'NC', 'TN', 'VA', 'NH']  

                }
            }
        }
    }
};

export default overrides;
