import { defineMessages } from 'react-intl';

export default defineMessages({
    ratingDate: {
        id: 'quoteandbind.RatingDateTestComponent.Rating Date',
        defaultMessage: 'Rating Date'
    },
    quoteCreationDate: {
        id: 'quoteandbind.RatingDateTestComponent.Quote Creation Date',
        defaultMessage: 'Quote Creation Date'
    },
    changeVersion: {
        id: 'quoteandbind.RatingDateTestComponent.Click to change product version',
        defaultMessage: 'Click to change product version'
    },
    hideFields: {
        id: 'quoteandbind.RatingDateTestComponent.Click to hide section',
        defaultMessage: 'Click to hide section'
    },
    rateFields: {
        id: 'quoteandbind.RatingDateTestComponent.Rate Fields',
        defaultMessage: 'Rate Fields'
    },
    experienceID:{
        id: 'quoteandbind.RatingDateTestComponent.Experience ID',
        defaultMessage: 'Experience ID'
    }
});
