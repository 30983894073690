function usePrintElements(hideFromPrintClass, preservePrintClass, preserveAncestorClass) {

    const bodyElementName = "BODY";

    const hide = function (element) {
        if (!element.classList.contains(preservePrintClass)) {
            element.classList.add(hideFromPrintClass);
        }
    };

    const preserve = function (element, isStartingElement) {
        element.classList.remove(hideFromPrintClass);
        element.classList.add(preservePrintClass);
        if (!isStartingElement) {
            element.classList.add(preserveAncestorClass);
        }
    };

    const clean = function (element) {
        element.classList.remove(hideFromPrintClass);
        element.classList.remove(preservePrintClass);
        element.classList.remove(preserveAncestorClass);
    };

    const walkSiblings = function (element, callback) {
        let sibling = element.previousElementSibling;
        while (sibling) {
            callback(sibling);
            sibling = sibling.previousElementSibling;
        }
        sibling = element.nextElementSibling;
        while (sibling) {
            callback(sibling);
            sibling = sibling.nextElementSibling;
        }
    };

    const attachPrintClasses = function (element, isStartingElement) {
        preserve(element, isStartingElement);
        walkSiblings(element, hide);
    };

    // eslint-disable-next-line no-unused-vars
    const _cleanup = function (element, isStartingElement) {
        clean(element);
        walkSiblings(element, clean);
    };

    const walkTree = function (element, callback) {
        let currentElement = element;
        callback(currentElement, true);
        currentElement = currentElement.parentElement;
        while (currentElement && currentElement.nodeName !== bodyElementName) {
            callback(currentElement, false);
            currentElement = currentElement.parentElement;
        }
    };

    // while printing adding CSS will not impact the UI, changes by this will only be seen during print preview and print
    // since we are using @media print in scss file, so the CSS will be applied during print only.
    const print = function (elements) {
        // attach css class to print the selected elements 
        for (let i = 0; i < elements.length; i++) {
            walkTree(elements[i], attachPrintClasses);
        }
        // print the page
        window.print();
        // once print is done, remove the css classes from the elements
        for (let i = 0; i < elements.length; i++) {
            walkTree(elements[i], _cleanup);
        }
    };

    return {
        print
    };
};

export default usePrintElements;