import { defineMessages } from 'react-intl';

export default defineMessages({
    addNewLineHeadingID: {
        id: 'quoteandbind.eh.full.views.thankyou.addNewLine.addNewLineHeadingID',
        defaultMessage: 'Save Time and Money with Account Rounding'
    },
    startAutoQuote: {
        id: 'quoteandbind.eh.full.views.thankyou.addNewLine.startAutoQuote',
        defaultMessage: 'START AUTO QUOTE'
    },
    startHomeQuote: {
        id: 'quoteandbind.eh.full.views.thankyou.addNewLine.startHomeQuote',
        defaultMessage: 'START HOME QUOTE'
    },
    startUmbrellaQuote: {
        id: 'quoteandbind.eh.full.views.thankyou.addNewLine.startUmbrellaQuote',
        defaultMessage: 'START UMBRELLA QUOTE'
    },
    newQuoteForThisAccount: {
        id: 'quoteandbind.eh.full.views.thankyou.addNewLine.newQuoteForThisAccount',
        defaultMessage: 'New Quote for This Account'
    }
});
