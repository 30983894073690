import { defineMessages } from 'react-intl';


export default defineMessages({
    disclosureInfo: {
        id: 'components.E1PAcknowledgement.disclosureInfo',
        defaultMessage: 'Disclosure'
    },
    disclosureLabel: {
        id: 'components.E1PAcknowledgement.disclosureLabel',
        defaultMessage: 'I accept the'
    },
    disclosureInfoLink: {
        id: 'components.E1PAcknowledgement.disclosureInfoLink',
        defaultMessage: 'Consumer Report Disclosure'
    },
    guidelinesInfoLink: {
        id: 'components.E1PAcknowledgement.guidelinesInfoLink',
        defaultMessage: 'Underwriting Guidelines'
    },
    underwritingGuidelines: {
        id: 'components.E1PAcknowledgement.underwritingGuidelines',
        defaultMessage: 'Underwriting Guidelines'
    },
    underwritingGuidelinesLabel: {
        id: 'components.E1PAcknowledgement.guideLinesLabel',
        defaultMessage: 'I verify that this risk does not violate any of the '
    },
    legalDesclaime: {
        id: 'components.E1PAcknowledgement.legalDisclaimer',
        defaultMessage: 'Legal Disclaimer'
    },
    legalDisclaimerLabel: {
        id: 'components.E1PAcknowledgement.legalDisclaimerLabel',
        defaultMessage: 'I agree that the information collected for this application can be used in my applications for other policies within the program.'
    },
    liabilityExclusionText: {
        id: 'components.E1PAcknowledgement.Animal Liability, Pool Liability and Fungi, Wet or Dry Rot or Bacteria Coverage are excluded unless coverage is available and selected (see Optional Coverages).',
        defaultMessage: 'Animal Liability, Pool Liability and Fungi, Wet or Dry Rot or Bacteria Coverage are excluded unless coverage is available and selected (see Optional Coverages).'
    },
    requiredField: {
        id: 'components.E1PAcknowledgement.This is a required field',
        defaultMessage: 'This is a required field'
    },
    anErrorOccurred: {
        id: 'e1p.E1PAcknowledgement.error.cancel',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'e1p.E1PAcknowledgement.error.cancel.Error',
        defaultMessage: 'Error'
    },
    iAccept: {
        id: 'e1p.E1PAcknowledgement.consumerReport.cancel.I Accept',
        defaultMessage: 'I Accept'
    },
    disclosurePunctuation:{
        id: 'components.E1PAcknowledgement.disclosurePunctuation',
        defaultMessage: '.'
    },
    guidelinePunctuation:{
        id: 'components.E1PAcknowledgement.guidelinePunctuation',
        defaultMessage: '.'
    }
});
