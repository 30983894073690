import React, {
    useCallback, useContext, useEffect,
    useMemo, useState, useRef
} from 'react';
import {
    get, set, find, findIndex, isEmpty, unset, pullAt
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDriverPageUtil, useCreditReportUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages, eaCommonMessages, eaValidationAndInfoMessages } from 'e1p-platform-translations';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { EAValidatonUtil } from 'e1p-portals-util-js';
import metadata from './DriversPage.metadata.json5';

const LOB = 'personalAuto_EA';

function EADriversPage(props) {
    const modalApi = useModal();
    const {
        wizardData: rewriteVM,
        updateWizardData,
        isSkipping,
        authUserData,
        setIsPageValid,
        updateWizardSnapshot
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isCreditReportRequired, setIsCreditReportRequired] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [sniHasDefensiveCourse, setSNIHasDefensiveCourse] = useState(false);
    const [pniHasDefensiveCourse, setPNIHasDefensiveCourse] = useState(false);
    const [driversWithLicenseValidationError, setDriversWithLicenseValidationErrors] = useState([]);
    const [isAccountContactsFetched, setIsAccountContactsFetched] = useState(false);
    const hasNewSNIAdded = useRef(false);
    const hasSaveOperationCompleted = useRef(false);

    const policyState = get(rewriteVM, 'baseData.policyAddress.state.value.code');
    const [isExistingDriverDeleted, setIsExistingDriverDeleted] = useState(false);

    const {
        setSpouseAsSNI,
        checkMoreThanOneSNIRoles,
        setRelationShipStatusForSNI,
        isPNIAndSNIValidForDefensiveDiscount,
        isDefensiveDriverDiscountAvailableForDriver,
        isFinancialResponsibilityFormAvailableForDriver,
        isLicenseNumberRequired,
        getDriverAge,
        checkDuplicateDrivers
    } = useDriverPageUtil(
        rewriteVM,
        updateWizardData,
        viewModelService,
        setValidationErrors,
        translator
    );

    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation
        // registerInitialComponentValidation
    } = useValidation('EADriversPage');
    const { authHeader } = useAuthentication();

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);

    const {
        showReportPopup
    } = useCreditReportUtil(
        translator,
        LOB
    );

    /**
    * Setting current wizard page is valid or not,using this info
    * to show popup(The changes you have made on this page may be lost.)
    * on click of previous or page jump
    */
    useEffect(() => {
        setIsPageValid(isComponentValid);
    }, [isComponentValid, setIsPageValid]);

    rewriteVM.flowStepIDs_Ext.value = ['driver', 'namedinsured'];
    rewriteVM.entryCompletionStepIDs_Ext.value = ['driver', 'namedinsured'];

    const createDriverVM = useCallback(
        (driver, isSNI) => {
            const driverObj = {
                person: driver?.person?.value
            };
            const {
                _dtoName,
                _xCenter
            } = rewriteVM.lobData.personalAuto_EA.coverables.drivers;
            const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);

            if (isSNI) { set(driverVM, 'relationshipToNI', driver.relationshipToNI.value); }

            rewriteVM.lobData.personalAuto_EA.coverables.drivers.pushElement(driverVM);
            updateWizardData(rewriteVM);
        },
        [rewriteVM, updateWizardData, viewModelService]
    );

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';
        const drivers = get(rewriteVM, `${driversPath}.value`);

        if (isEmpty(drivers)) {
            createDriverVM(rewriteVM.lobData.personalAuto_EA.primaryNamedInsured, false);

            if (rewriteVM.lobData.personalAuto_EA.secondaryNamedInsured.person) {
                createDriverVM(
                    rewriteVM.lobData.personalAuto_EA.secondaryNamedInsured, true
                );
            }

            // If it is first time visiting the page, need to call credit report
            setIsCreditReportRequired(true);
        } else {
            // User chose to change PNI to a new Contact
            const pni = get(rewriteVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
            const pniDriver = drivers.find((driver) => driver.person.publicID === pni.person.publicID);

            if (!pniDriver) {
                createDriverVM(rewriteVM.lobData.personalAuto_EA.primaryNamedInsured, false);
                setIsCreditReportRequired(true);
            }

            // populating sni as driver
            const sniPublicID = get(rewriteVM, 'lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value', undefined);
            const sniDriver = drivers.find((driver) => driver.person.publicID === sniPublicID);

            if (sniPublicID && !sniDriver) {
                createDriverVM(rewriteVM.lobData.personalAuto_EA.secondaryNamedInsured, true);
                // If it secondary named insured person is added, need to call credit report
                setIsCreditReportRequired(true);
            } else if (sniPublicID && sniDriver) {
                // user went back to insured page and added sni
                // on insured page we are adding sni in drivers node
                // so sni will be present but policyDriverRoleType will be undefined
                if (!get(sniDriver, 'policyDriverRoleType')) {
                    setIsCreditReportRequired(true);
                }
            }
        }

        setIsPageInitialized(true);
        // No array dependencies needed in this hook.
        // The logic of initializing drivers data needs to be executed only once
        // when landing into drivers page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';
        const drivers = get(rewriteVM, `${driversPath}`);
        const pniPublicID = rewriteVM
            .lobData.personalAuto_EA.primaryNamedInsured.person.publicID.value;
        const sniPublicID = rewriteVM
            .lobData.personalAuto_EA.secondaryNamedInsured.person
            ? rewriteVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value
            : undefined;

        let pni = find(drivers.children, (driver) => driver.person.publicID.value === pniPublicID);
        const pniIndex = findIndex(drivers.children, (driver) => driver.person.publicID.value === pniPublicID);

        // Unset role. Fixes bug where you come back to drivers from a further page
        //   and ArrayUpdater has changed the ordering of your drivers and role has persisted
        //   so you end up seeing both  drivers as PNI
        drivers.children.forEach((driverNode) => {
            unset(driverNode, 'role');
        });

        // Put PNI back as first driver
        if (pniIndex > 0) {
            pni = viewModelService.clone(pni);
            pullAt(drivers.value, pniIndex);
            drivers.value.unshift(pni.value);
            updateWizardSnapshot(rewriteVM);
        }

        if (sniPublicID) {
            const sni = find(drivers.children, (driver) => driver.person.publicID.value === sniPublicID);

            if (sni) {
                set(sni, 'value.role', 'sni');
            }
        }

        if (pni) {
            set(pni, 'value.role', 'pni');
        }
    }, [rewriteVM, updateWizardSnapshot, viewModelService]);

    const getCreditReportStatus = useCallback(
        (quoteID) => showReportPopup(
                quoteID,
                authHeader,
                rewriteVM
            ),
        [authHeader, rewriteVM, showReportPopup]
    );

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => {
            setValidationErrors([]);

            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            // IAP-4716, check duplicate drivers
            const hasDuplicateDrivers = checkDuplicateDrivers();

            if (hasDuplicateDrivers) {
                setIsSavingEndorsement(false);

                return false;
            }

            const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
            const driversList = get(rewriteVM, driversPath);

            driversList.forEach((driver) => {
                /**
                 * E1PAP1PC-12823
                 * If relationshipTo Pni is spouse then that driver should be sni
                 * Marking driver as sni and function setSpouseAsSNI will convert it to sni
                 */
                if (driver.relationshipToNI === 'spouse') {
                    set(driver, 'role', 'sni');
                }
            });

            // E1PAP1PC-12823
            // Checks if more than one sni roles are present and if yes then sets page level error
            const hasMoreThanOneSNIRoles = checkMoreThanOneSNIRoles();

            if (hasMoreThanOneSNIRoles) {
                setIsSavingEndorsement(false);

                return false;
            }

            /**
            * IAP-3005 : removing prompt to set Spouse as SNI
            * When Spouse is added as driver we will add it as SNI on the policy
            */
            setSpouseAsSNI(hasNewSNIAdded);

            // E1PAP1PC-12823
            // syncs relationship status for sni
            setRelationShipStatusForSNI();
            unset(rewriteVM.value, 'bindData');
            driversList.forEach((driver, index) => {
                if (
                    isEmpty(driver.person.displayName)
                    || !driver.person.displayName.includes(
                        `${driver.person.firstName} ${driver.person.lastName}`
                    )
                ) {
                    const displayname = `${driver.person.firstName} ${driver.person.lastName}`;

                    set(rewriteVM, `${driversPath}[${index}].person.displayName`, displayname);
                }

                set(rewriteVM,
                    `${driversPath}[${index}].isInsurancePolicyRefused`,
                    'no');
                set(rewriteVM,
                    `${driversPath}[${index}].isDrivingPrivilegeRevoked`,
                    'no');
            });

            try {
                rewriteVM.value = await RewriteService.updateDraftRewrite(
                    [rewriteVM.value],
                    authHeader
                );
                updateWizardData(rewriteVM);
                hasSaveOperationCompleted.current = true;

                if (isCreditReportRequired || hasNewSNIAdded.current) {
                    if (!(await getCreditReportStatus(get(rewriteVM, 'jobID.value')))) {
                        setIsSavingEndorsement(false);

                        return false;
                    }
                }

                setIsSavingEndorsement(false);

                return rewriteVM;
            } catch {
                setIsSavingEndorsement(false);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: commonMessages.genericError,
                    message: commonMessages.genericErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });

                return false;
            }
        },
        [authHeader, checkDuplicateDrivers, modalApi, checkMoreThanOneSNIRoles, getCreditReportStatus, isComponentValid, isCreditReportRequired, rewriteVM, setRelationShipStatusForSNI, setSpouseAsSNI, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (hasSaveOperationCompleted.current && isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    /**
                     * E1PAP1PC-14986 :
                     * wizardData and wizardSnapshot not being equal due to
                     * some defaulting on each page so doing this temp fix
                     */
                    const pni = get(rewriteVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
                    const pniDriver = get(rewriteVM, 'lobData.personalAuto_EA.coverables.drivers.value')
                        .find((driver) => driver.person.publicID === pni.person.publicID);

                    if (pniDriver) {
                        set(pniDriver, 'relationshipToNI', undefined);
                        set(pniDriver, 'role', 'pni');
                    }

                    const sni = get(rewriteVM.value, 'lobData.personalAuto_EA.secondaryNamedInsured');

                    if (sni) {
                        const sniDriver = get(rewriteVM, 'lobData.personalAuto_EA.coverables.drivers.value')
                            ?.find((driver) => driver.person.publicID === sni.person.publicID);

                        if (sniDriver) {
                            set(sniDriver, 'role', 'sni');
                        }
                    }

                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [isComponentValid, onNext, rewriteVM, updateWizardSnapshot]
    );

    // this grabs the latest element on the page -- this is to make sure this element has been loaded
    const latestDriverElement = document.getElementById(`driverFirstName${rewriteVM.lobData.personalAuto_EA.coverables.drivers.children.length - 1}`);

    useEffect(() => {
        // indexStale set in the add driver function
        // once latest element is loaded and a new driver is added
        // we check if the button should be visible
        if (latestDriverElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestDriverElement]);

    // IAP-4225, if existing driver is removed, then we need to call save API
    useEffect(() => {
        const callOnSave = async () => {
            await onSave();
        }

        if (isExistingDriverDeleted && isComponentValid) {
            callOnSave();
            setIsExistingDriverDeleted(false);
        }
    }, [isComponentValid, isExistingDriverDeleted, onSave]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(
        () => {
            let loadingMessage = '';

            if (!isPageInitialized) {
                loadingMessage = translator(eaCommonMessages.loadingPrefillDataMessage);
            } else if (isSavingCurrentPageChanges) {
                loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
            } else if (isSavingEndorsement || isSkipping) {
                loadingMessage = translator(eaCommonMessages.loadingNextPageMessage);
            }

            return loadingMessage;
        },
        [isPageInitialized, isSavingCurrentPageChanges, isSavingEndorsement, isSkipping, translator]
    );

    const getMessageForFinancialRespBasedonState = () => {
        let financialRespMessage = translator(eaValidationAndInfoMessages.sr22WithCompOnly);

        if (policyState === 'VA') {
            financialRespMessage = translator(eaValidationAndInfoMessages.sr22fr44WithCompOnly);
        }

        return financialRespMessage;
    };

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        driversPageLoadingIndicator: {
            loaded: isPageInitialized && !isSavingEndorsement
                && !isSkipping && !isSavingCurrentPageChanges,
            text: getLoadingIndicatorMessage
        },
        driversPageContainer: {
            visible: isPageInitialized && !isSavingEndorsement
                && !isSkipping && !isSavingCurrentPageChanges
        },
        addDriver: {
            accountNumber: get(rewriteVM, 'baseData.accountNumber.value'),
            authHeader,
            excludedContacts: (() => {
                const drivers = get(rewriteVM.value, 'lobData.personalAuto_EA.coverables.drivers', []);

                return drivers.map((driver) => driver.person.publicID);
            })(),
            viewModelService,
            setCheckScrolling,
            setIndexStale,
            setIsAccountContactsFetched,
            isAccountContactsFetched
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('eadrivercontainer')
        },
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        defensiveDriverDiscountNotificationDiv: {
            visible: isPNIAndSNIValidForDefensiveDiscount() && ((pniHasDefensiveCourse && !sniHasDefensiveCourse)
                || (!pniHasDefensiveCourse && sniHasDefensiveCourse))
        },
        financialResponsibilityWithCompOnlyMessage: {
            message: getMessageForFinancialRespBasedonState()
        },
        financialResponsibilityWithCompOnlyMessageDiv: {
            visible: EAValidatonUtil.isFinancialResponsibilitySelectedWithAllCompOnlyVehicle(rewriteVM),
        },
        nationalGuardWithDefensiveDriverMessageDiv: {
            visible: EAValidatonUtil.isNationalGuardSelectedWithoutDefensiveDriver(rewriteVM),
        },

        EADriverGrid: {
            key: get(rewriteVM, 'lobData.personalAuto_EA.coverables.drivers', []).length,
            drivers: get(rewriteVM, 'lobData.personalAuto_EA.coverables.drivers', []),
            path: 'lobData.personalAuto_EA.coverables.drivers.children',
            onValidate,
            viewModelService,
            showErrors: isPageSubmitted,
            policyState: get(rewriteVM, 'baseData.policyAddress.state.value.code'),
            driverPageDisregardFieldValidation: disregardFieldValidation,
            authHeader,
            checkLicenseRequired: (driver) => isLicenseNumberRequired(rewriteVM, driver),
            driversWithLicenseValidationError,
            setDriversWithLicenseValidationErrors,
            getDriverAge,
            periodStartDate: get(rewriteVM, 'baseData.periodStartDate.value'),
            priorPolicyList: get(rewriteVM, 'lobData.personalAuto_EA.priorPolicyUpdates'),
            isFinancialResponsibilityFormAvailableForDriver,
            isNewSubmission: false,
            onValidationChange: onValidate,
            authUserData,
            transactionVM: rewriteVM,
            isDefensiveDriverDiscountAvailableForDriver,
            setSNIHasDefensiveCourse,
            setPNIHasDefensiveCourse,
            updateWizardData,
            setCheckScrolling,
            setIsExistingDriverDeleted,
            showValidationMessage: () => {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            },
            setIsAccountContactsFetched
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                rewriteVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, rewriteVM]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate,
            onPrefillData: undefined,
            onViewModelService: viewModelService
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

EADriversPage.propTypes = wizardProps;
export default withAuthenticationContext(EADriversPage);
