import { set as _set } from 'lodash';

export function prefillDriverToDriverVMAdapter(
    prefillDriver,
    index,
    submissionVM,
    viewModelService,
    setDrivers
) {
    const driverObj = {
        person: prefillDriver.value
    };
    const { _dtoName, _xCenter } = submissionVM.lobData.personalAuto_EA.coverables.drivers;
    const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);
    setDrivers((prevState) => {
        prevState[index] = driverVM;
        return prevState;
    });
    _set(driverVM, 'value.isInsurancePolicyRefused', 'no');
    _set(driverVM, 'value.isDrivingPrivilegeRevoked', 'no');
    return driverVM;
}
export function foundInDrivers(prefillDriver, submissionVM) {
    if (!prefillDriver || submissionVM.baseData.quoteSource_Ext.sourceType.value.code !== 'comprater') return false;
    const pFirstName = prefillDriver.firstName?.value.toUpperCase();
    const pLastName = prefillDriver.lastName?.value.toUpperCase();
    const pDay = prefillDriver.dateOfBirth.value.day;
    const pYear = prefillDriver.dateOfBirth.value.year;
    const pMonth = prefillDriver.dateOfBirth.value.month;
    const DriversArray = submissionVM.value.lobData.personalAuto_EA.coverables.drivers;
    const contains = DriversArray.some(
        ({
            person: {
                dateOfBirth: { day, month, year },
                firstName,
                lastName
            }
        }) => {
            return (
                pFirstName === firstName?.toUpperCase()
                && pLastName === lastName?.toUpperCase()
                && pDay === day
                && pYear === year
                && pMonth === month
            );
        }
    );
    return contains;
}
