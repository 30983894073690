import { defineMessages } from 'react-intl';

export default defineMessages({
    betterTogehterCovUpgrd: {
        id: 'quoteandbind.common.QuoteClauseTable.Optional Coverage.Better Together Coverage Upgrades',
        defaultMessage: 'Better Together Coverage Upgrades'
    },
    optionalCovUpgradeCov: {
        id: 'quoteandbind.common.QuoteClauseTable.Optional Coverage.Gold Offering.Upgrade Coverage',
        defaultMessage: 'Upgrade Coverage'
    },
    optionalCovWhatsIncldLnk: {
        id: 'quoteandbind.common.QuoteClauseTable.Optional Coverage.Gold Offering.WHAT\'S INCLUDED',
        defaultMessage: 'WHAT\'S INCLUDED'
    },
    optionalCovGold: {
        id: 'quoteandbind.common.QuoteClauseTable.Optional Coverage.Gold',
        defaultMessage: 'Gold'
    },
    optionalCovPlatinum: {
        id: 'quoteandbind.common.QuoteClauseTable.Optional Coverage.Platinum',
        defaultMessage: 'Platinum'
    },
    optionalCoveragesLabel: {
        id: 'quoteandbind.common.QuoteClauseTable.Optional Coverage.Optional Coverages',
        defaultMessage: 'Optional Coverages'
    },
    unverifiedPremium: {
        id: 'quoteandbind.eh.QuoteClauseTable.Unverified Premium',
        defaultMessage: 'Unverified Premium'
    },
    policyTerm: {
        id: 'quoteandbind.eh.QuoteClauseTable.6 Months',
        defaultMessage: '6 Months'
    },
    optionalCoveragePackage: {
        id: 'quoteandbind.eh.QuoteClauseTable.optionalCoveragePackage',
        defaultMessage: 'Optional Coverage Package'
    },
    discountMessageSelect: {
        id: 'quoteandbind.eh.QuoteClauseTable.selectCoverage',
        defaultMessage: 'Select any coverages marked with'
    },
    discountMessageLabel: {
        id: 'quoteandbind.eh.QuoteClauseTable.discountMessage',
        defaultMessage: 'to receive a Package Discount. The more coverages selected, the greater the discount.'
    },
    verifiedPremium: {
        id: 'quoteandbind.eh.QuoteClauseTable.VerifiedPremium',
        defaultMessage: 'Verified Premium'
    },
    mandatoryCoverageText: {
        id: 'quoteandbind.common.QuoteClauseTable.MandatoryCoverage.mandatoryCoverageText',
        defaultMessage: 'Mandatory Coverages'
    },
    questionCircleIcon: {
        id: 'quoteandbind.common.OptionalCoverages.Gold Offering.Coverage Comparison',
        defaultMessage: 'Coverage Comparison'
    },
    discountLabel: {
        id: 'quoteandbind.eh.QuoteClauseTable.Discounts',
        defaultMessage: 'Discounts'
    },
    paymentOptions: {
        id: 'quoteandbind.eh.QuoteClauseTable.Payment Options',
        defaultMessage: 'Payment Options'
    },
    propertyCoverages: {
        id: 'quoteandbind.eh.QuoteClauseTable.Property Coverages',
        defaultMessage: 'Property Coverages'
    },
    deductibles: {
        id: 'quoteandbind.eh.QuoteClauseTable.Deductibles',
        defaultMessage: 'Deductibles'
    },
    emptyPremium: {
        id: 'quoteandbind.common.views.payment-details.-',
        defaultMessage: '-'
    },
    exit: {
        id: 'quoteandbind.eh.common.directives.CommonOfferingSelection.Exit',
        defaultMessage: 'Exit'
    },
    goldPlatinumPrimaryLabel: {
        id: 'quoteandbind.eh.QuoteClauseTable.goldPlatinumPrimaryLabel',
        defaultMessage: 'Gold & Platinum Packages'
    },
    goldPlatinumSecondaryLabel: {
        id: 'quoteandbind.eh.QuoteClauseTable.goldPlatinumSecondaryLabel',
        defaultMessage: 'Select Gold or Platinum Packages to receive our Better Together Discount'
    }
});
