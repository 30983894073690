import { defineMessages } from 'react-intl';

export default defineMessages({
    all: {
        id: 'e1p.gateway.views.account-detail.All',
        defaultMessage: 'All'
    },
    issued: {
        id: 'e1p.gateway.directives.JobSummary.Issued',
        defaultMessage: 'Issued'
    },
    notTaken: {
        id: 'e1p.gateway.directives.JobSummary.Not Taken',
        defaultMessage: 'Not Taken'
    },
    errorLoadingDocument: {
        id: 'e1p.gateway.views.quote-documents.Error loading documents',
        defaultMessage: 'Error loading documents'
    },
    errorUploadingDocument: {
        id: 'e1p.gateway.views.quote-documents.Error uploading documents',
        defaultMessage: 'Error uploading documents'
    },
    modalError: {
        id: 'e1p.gateway.views.modal.Error',
        defaultMessage: 'Error'
    },
    errorLoadingNotes: {
        id: 'e1p.gateway.views.quote-notes.Error loading notes',
        defaultMessage: 'Error loading notes.'
    },
    noClaimsResult: {
        id: 'e1p.gateway.directives.ClaimSummaryDirective.ClaimSummary.There are no claims',
        defaultMessage: 'There are no claims'
    },
    gatewayViewsModalErrorData: {
        id: 'e1p.gateway.views.modal.Error.Data',
        defaultMessage: 'An error occurred while attempting to retrieve information. Please contact your administrator.'
    },
    withdrawRenewalCtrlTransaction: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Withdraw renewal transaction.',
        defaultMessage: 'Withdraw renewal transaction.'
    },
    startedByMistake: {
        id: 'e1p.gateway.controllers.renewal.RenewalDetailCtrl.Was this renewal started by mistake?',
        defaultMessage: 'Was this renewal started by mistake? {link}'
    },
    fileAClaim: {
        id: 'e1p.gateway.directives.ClaimSummaryDirective.claim-summary.File a Claim',
        defaultMessage: 'File a Claim'
    },
    noteCreated: {
        id: 'e1p.gateway.directives.activity-schedule.Done',
        defaultMessage: 'Note Created',
    },
    noteCreatedSuccessfully: {
        id: 'e1p.gateway.directives.activity-schedule.Note created',
        defaultMessage: 'You have successfully created a note.',
    },
    inactive: {
        id: 'e1p.gateway.contacts.common.components.Contacts.AccountContacts.Inactive',
        defaultMessage: 'Inactive',
    },
    errorLoadingUWIssues: {
        id: 'e1p.gateway.views.quote-documents.Error loading Under writing issues',
        defaultMessage: 'Error loading Under writing issues'
    },
    loadingUWIssues: {
        id: 'e1p.gateway.views.quote-documents.Loading Underwriting issues...',
        defaultMessage: 'Loading Underwriting issues...'
    },
    allCompletedTransactions: {
        id: 'e1p.gateway.directives.policy-summary.All Completed Transactions',
        defaultMessage: 'All Completed Transactions',
    },
    locationCode: {
        id: 'e1p.gateway.dashboard.searchCriteriaFilter.Location Code',
        defaultMessage: 'Location Code'
    },
    workStartedBy: {
        id: 'e1p.gateway.dashboard.searchCriteriaFilter.Work Started By',
        defaultMessage: 'Work Started By'
    },
    anyoneOption: {
        id: 'e1p.gateway.dashboard.Anyone',
        defaultMessage: 'Anyone'
    },
    meOption: {
        id: 'e1p.gateway.dashboard.Me',
        defaultMessage: 'Me'
    },
    firstName: {
        id: 'e1p.gateway.dashboard.searchCriteriaFilter.First Name',
        defaultMessage: 'First Name'
    },
    lastName: {
        id: 'e1p.gateway.dashboard.searchCriteriaFilter.Last Name',
        defaultMessage: 'Last Name'
    },
    dateCreated: {
        id: 'e1p.gateway.dashboard.searchCriteriaFilter.Date Created',
        defaultMessage: 'Date Created'
    },
    anyTimeOption: {
        id: 'e1p.gateway.dashboard.Anytime',
        defaultMessage: 'Anytime'
    },
    last7DaysOption: {
        id: 'e1p.gateway.dashboard.Last 7 Days',
        defaultMessage: 'Last 7 Days'
    },
    last30DaysOption: {
        id: 'e1p.gateway.dashboard.Last 30 Days',
        defaultMessage: 'Last 30 Days'
    },
    last60DaysOption: {
        id: 'e1p.gateway.dashboard.Last 60 Days',
        defaultMessage: 'Last 60 Days'
    },
    last90DaysOption: {
        id: 'e1p.gateway.dashboard.Last 90 Days',
        defaultMessage: 'Last 90 Days'
    },
    streetAddress: {
        id: 'e1p.gateway.dashboard.searchCriteriaFilter.Street Address',
        defaultMessage: 'Street Address'
    },
    state: {
        id: 'e1p.gateway.dashboard.searchCriteriaFilter.State',
        defaultMessage: 'State'
    },
    zipCode: {
        id: 'e1p.gateway.dashboard.searchCriteriaFilter.Zip Code',
        defaultMessage: 'Zip Code'
    },
    dashboardSearch: {
        id: 'e1p.gateway.dashboard.searchCriteriaFilter.SEARCH',
        defaultMessage: 'SEARCH'
    },
    clear: {
        id: 'e1p.gateway.dashboard.searchCriteriaFilter.CLEAR',
        defaultMessage: 'CLEAR'
    },
    allLocations: {
        id: 'e1p.gateway.dashboard.All Locations',
        defaultMessage: 'All Locations' 
    },
    noDataText: {
        id: 'e1p.gateway.dashboard.No results found for this filter',
        defaultMessage: 'No results found for this filter'
    },
    refineResults: {
        id: 'e1p.gateway.dashboard.Refine Results',
        defaultMessage: 'Refine Results'
    },
    partner: {
        id: 'e1p.gateway.dashboard.searchCriteriaFilter.Partner',
        defaultMessage: 'Partner'
    }
});
