import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class ActivitiesService {
    static getActivitiesForUser(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesForUser',
            [],
            additionalHeaders
        );
    }

    static getActivitiesForCurrentUserAndGroups(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesForCurrentUserAndGroups',
            [],
            additionalHeaders
        );
    }

    static getActivitiesForCurrentUserAndQueues(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesForCurrentUserAndQueues',
            [],
            additionalHeaders
        );
    }

    static getActivitiesForPolicy(policyNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesForPolicy', [policyNumber], additionalHeaders);
    }

    static getActivitiesForAccount(accountNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesForAccount', [accountNumber], additionalHeaders);
    }

    static getActivitiesForJob(jobNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesForJob', [jobNumber], additionalHeaders);
    }

    static getActivityPatternsFor(actPatternEntity, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getActivityPatternsFor', [actPatternEntity], additionalHeaders);
    }

    static getAssignableUserForActivity(actPatternEntity, id, activityPattern,
        additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getAssignableUserForActivity', [actPatternEntity, id, activityPattern], additionalHeaders);
    }

    static createNewActivity(activity, note, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'createNewActivity', [activity, note], additionalHeaders);
    }

    static createNoteForActivity(param, data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'createNoteForActivity',
            [param, data], additionalHeaders);
    }

    static markActivityAsCompleted(activityPublicID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'markActivityAsCompleted', [activityPublicID], additionalHeaders);
    }

    static markActivitiesAsCompleted(activitiesPublicIDs, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'markActivitiesAsCompleted', [activitiesPublicIDs], additionalHeaders);
    }

    static assignActivitiesToQueue(activitiesPublicIDs, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'assignActivitiesToQueue', [activitiesPublicIDs], additionalHeaders);
    }

    static assignActivitiesToCurrentUser(activitiesPublicIDs, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'assignActivitiesToCurrentUser', [activitiesPublicIDs], additionalHeaders);
    }

    static reassignActivity(selectedUser, id, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'reassignActivity', [selectedUser, id], additionalHeaders);
    }

    static getAssignableUsersForActivity(activityPublicID, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getAssignableUsersForActivity', [activityPublicID], additionalHeaders);
    }

    static getAssignableUsersForActivities(activityPublicIds, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getAssignableUsersForActivities', [activityPublicIds], additionalHeaders);
    }

    static assignActivitiesToUser(activityPublicIds, assignee, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'assignActivitiesToUser', [activityPublicIds, assignee], additionalHeaders);
    }

    static getAssignableQueuesForCurrentUser(additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'getAssignableQueuesForCurrentUser', [], additionalHeaders);
    }
    
    static reassignActivitiesToQueue(activityPublicIds, assignee, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('gatewayActivity'),
            'reassignActivitiesToQueue', [activityPublicIds, assignee], additionalHeaders);
    }
    
    static getActivitiesCountByStatus(status, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesCount',
            [
                {},
                {
                    offsetStart: 0,
                    offsetEnd: 10,
                    orderBy: 'CreateTime',
                    orderByDescending: true
                },
                [
                    {
                        propertyName: 'Status',
                        isDbProperty: true,
                        typeListName: 'ActivityStatus',
                        typeKeyCode: status,
                        orOperation: true
                    }
                ]
            ],
            additionalHeaders
        );
    }

    static getActivitiesCountByStatusForCurrentUser(status, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesCount',
            [
                {
                    assignedToMe: true
                },
                {
                    offsetStart: 0,
                    offsetEnd: 10,
                    orderBy: 'CreateTime',
                    orderByDescending: true
                },
                [
                    {
                        propertyName: 'Status',
                        isDbProperty: true,
                        typeListName: 'ActivityStatus',
                        typeKeyCode: status,
                        orOperation: true
                    }
                ]
            ],
            additionalHeaders
        );
    }

    static getCreatedeByCurrentUserActivitiesCount(additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayActivity'),
            'getActivitiesCount',
            [
                {
                    createdByMe: true
                },
                {
                    offsetStart: 0,
                    offsetEnd: 10,
                    orderBy: 'CreateTime',
                    orderByDescending: true
                },
                []
            ],
            additionalHeaders
        );
    }

    static getActivities(
        assignmentOptions,
        paginationOptions,
        statusPriorityOptions,
        additionalHeaders = {}
    ) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayActivity'),
            'getActivities',
            [
                assignmentOptions,
                paginationOptions,
                statusPriorityOptions
            ],
            additionalHeaders
        );
    }
}
