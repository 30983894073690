import React, { useCallback, useState } from 'react';
import {
    get as _get
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from '../RewriteEHRiskAnalysisPage.metadata.json5';
import messages from '../RewriteEHRiskAnalysisPage.messages';

function RiskAnalysisPage(props) {
   
    const {
        wizardData: rewriteVM, authUserData, viewOnly
    } = props;

    const quoteID = _get(rewriteVM, 'value.jobID');
    const [geoDataResponse, setGeoDataResponse] = useState({});
    const [responseAuto, setResponseAuto] = useState([]);
    const [responseProperty, setResponseProperty] = useState({});
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
   

    const onNext = useCallback(
        () => rewriteVM,
        [rewriteVM]
    );

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
    && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
    && _get(rewriteVM, 'lobData.homeowners_EH.premiumStability.value') !== undefined;

    const resolvers = {
        resolveCallbackMap: {
            onValidate: () => {},
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnly
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: true,
            defaultActiveTab: 'verifiedLossesAndViolationTab'
        },
        verifiedLossesAndViolationComponent: {
            responseAuto,
            setResponseAuto,
            responseProperty,
            setResponseProperty,
            submissionVM: rewriteVM,
            updateWizardData: () => {},
            setLossesPageValid: () => {},
            authUserData,
            viewOnlyMode: true
        },
        uwIssueComponent: {
            submissionVM: rewriteVM,
            updateWizardData: () => {},
            authHeader,
            viewOnlyMode: true
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(rewriteVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: rewriteVM,
            updateWizardData: () => {},
            onValidate: () => {},
            showErrors: false,
            viewOnlyMode: true
        },
        creditReportsComponent: {
            submissionVM: rewriteVM,
            updateWizardData: () => {},
            lineOfBusiness: rewriteVM.lobData.homeowners_EH,
            lobName: 'homeowners_EH',
            authUserData,
            id: 'creditReportsComponent',
            onValidate: () => {},
            viewOnlyMode: true
        },
        geoDataComponent: {
            quoteID,
            geoDataResponse,
            setGeoDataResponse,
            viewOnly,
            policyState: _get(rewriteVM, 'baseData.policyAddress.state.value.code')
        },
        diminishingAvailableAmount: {
            labelPosition: 'left'
        },
        diminishingAvailableAmountLatest: {
            labelPosition: 'left'
        },
        EHRiskAnalysisMiscComponentId: {
            submissionVM: rewriteVM
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                rewriteVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, rewriteVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={() => {}}
                onValidationChange={() => {}}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
