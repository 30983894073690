import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, DropdownMenuSeparator, DropdownMenuHeader } from '@jutro/components';
import { ContactService } from 'e1p-capability-gateway';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { useTranslator } from '@jutro/locale';

import messages from './E1PAddTPIComponent.messages';

import { Button } from '@jutro/legacy/components';

import { DropdownMenuLink } from '@jutro/router';

/**
 * E1PAP1PC-9753 This component allows a user
 *   to search TPI or use existing contact(person/organization)
 *   that belongs to an account. Initially
 *   state is undefined and shows a loader, if call is not
 *   successful, state is set to an empty list and loader goes away.
 *   Succesful call loads list of all contacts.
 * @param {Object} props see prop types
 * @returns {JSX}
 */
function E1PAddTPIComponent(props) {
    const {
        value: namedInsuredVM,
        accountNumber,
        authHeader,
        searchTPIClick,
        UseExistingContact
    } = props;
    const translator = useTranslator();
    const [contacts, setContacts] = useState(undefined);
    const [contactsLoaded, setContactsLoaded] = useState(false);

    const getAccountContacts = useCallback(() => {
        ContactService.getAccountContacts(accountNumber, authHeader)
            .then((conactDtos) => {
                // returning an empty object when empty for some reason
                if (conactDtos.length > 0) {
                    setContacts(conactDtos);
                } else {
                    // empty list instead of empty object that is returned
                    setContacts([]);
                }
            })
            .catch(() => {
                // Not worried about call failing here
                //   If somehow this were reached, set list to empty
                setContacts([]);
            })
            .finally(() => setContactsLoaded(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addExistingContact = useCallback((value, isPerson) => {
        UseExistingContact(value, isPerson);
    }, [UseExistingContact]);

    const renderTriggerAddTPI = useCallback(
        (dropDownProps, toggleMenu) => {
            const { isOpen } = dropDownProps;
            const onAddTPIClick = () => {
                toggleMenu(!isOpen);
            };

            // If menu opens and contacts haven't been initialised yet,
            //   then make the call to get them
            if (isOpen && !contacts) {
                getAccountContacts();
            }

            return (
                <Button onClick={onAddTPIClick} icon="mi-expand-more" iconPosition="right">
                    {translator(messages.addTPIButton)}
                </Button>
            );
        },
        [contacts, getAccountContacts, translator]
    );

    let existingPersons;
    let existingOrganizations;

    if (contacts) {
        existingPersons = contacts
            // filter out company contacts
            .filter((contact) => contact.person)
            .map((contact) => (
                    <DropdownMenuLink
                        key={contact.person.publicID}
                        onClick={() => addExistingContact(contact.person, true)}
                    >
                        {contact.person.displayName}
                    </DropdownMenuLink>
                ));
        existingOrganizations = contacts
            // filter out person contacts
            .filter((contact) => contact.company)
            .map((contact) => (
                    <DropdownMenuLink
                        key={contact.company.publicID}
                        onClick={() => addExistingContact(contact.company, false)}
                    >
                        {contact.company.displayName}
                    </DropdownMenuLink>
                ));
    }

    return (
        <DropdownMenu
            id="dropdownMenuButton"
            renderTrigger={renderTriggerAddTPI}
        >
            <DropdownMenuHeader title={translator(messages.searchTPI)}>
                <DropdownMenuSeparator />
                <DropdownMenuLink
                    onClick={() => searchTPIClick({})}
                >
                    {translator(messages.searchOrAddTPI)}
                </DropdownMenuLink>
            </DropdownMenuHeader>

            <DropdownMenuHeader title={translator(messages.existingPerson)}>
                <DropdownMenuSeparator />
                {
                    existingPersons || <E1PLoader loaded={contactsLoaded} />
                }
            </DropdownMenuHeader>

            <DropdownMenuHeader title={translator(messages.existingOrganization)}>
                <DropdownMenuSeparator />
                {
                    existingOrganizations || <E1PLoader loaded={contactsLoaded} />
                }
            </DropdownMenuHeader>

        </DropdownMenu>
    );
}

E1PAddTPIComponent.propTypes = {
    value: PropTypes.shape({}).isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    accountNumber: PropTypes.string.isRequired,
    searchTPIClick: PropTypes.func.isRequired,
    UseExistingContact: PropTypes.func.isRequired,
};
export default E1PAddTPIComponent;
