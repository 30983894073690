import { defineMessages } from 'react-intl';

export default defineMessages({
    applyingChangesMessage: {
        id: 'quoteandbind.ea.uwIssueComponent.Applying changes...',
        defaultMessage: 'Applying changes...'
    },
    blocksQuoteTitle: {
        id: 'quoteandbind.ea.uwIssueComponent.Blocks Quote',
        defaultMessage: 'Blocking Quote'
    }
});
