import React, { Component } from 'react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { SelectProducerCode } from 'gw-gateway-common-react';
import metadata from './Analytics.metadata.json5';
import analyticsStyles from './Analytics.module.scss';
import './Analytics.messages';

class Analytics extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired
    };

    state = {
        selectedTile: 'growthTile'
    };

    handleOnClick = (id) => {
        this.setState({
            selectedTile: id
        });
    };

    toggleVisibilityGrowth = (selectedTile) => {
        if (selectedTile === 'growthTile') {
            return true;
        }

        return false;
    };

    toggleVisibilityRatios = (selectedTile) => {
        if (selectedTile === 'lossRatiosTile') {
            return true;
        }

        return false;
    };

    render() {
        const { selectedTile } = this.state;

        const overrides = {
            growth: {
                visible: this.toggleVisibilityGrowth(selectedTile)
            },
            ratios: {
                visible: this.toggleVisibilityRatios(selectedTile)
            },
            [selectedTile]: {
                active: true
            }
        };
        const resolvers = {
            resolveComponentMap: {
                selectproducercode: SelectProducerCode
            },
            resolveCallbackMap: {
                handleOnClick: this.handleOnClick
            },
            resolveClassNameMap: analyticsStyles
        };

        const AnalyticsPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
        return <div className={cx(analyticsStyles.analytics)}>{AnalyticsPage}</div>;
    }
}
export default Analytics;
