import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import PropTypes from 'prop-types';
import styles from './NonOperatorComponent.module.scss';
import metadata from './NonOperatorComponent.metadata.json5';

import { Button } from '@jutro/legacy/components';

function NonOperatorComponent(props) {
    const {
        driverVM,
        title,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
        id,
        onValidate,
        driverPageDisregardFieldValidation,
        viewOnlyMode
    } = props;

    const translator = useTranslator();

    const { isComponentValid, onValidate: setComponentValidation } = useValidation(
        id
    );
    const [driverData, updateDriverData] = useState(driverVM);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, driverVM, id, onValidate]);

    useEffect(
        () => {
            const nextFormData = viewModelService.clone(driverVM);

            updateDriverData(nextFormData);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = viewModelService.clone(driverData);

            _.set(nextFormData, path, value);
            updateDriverData(nextFormData);
        },
        [driverData, viewModelService]
    );

    const nonOperatorDropdownListValues = useCallback(() => {
        let nonOperatorValues = [];

        nonOperatorValues = driverData.nonOperatorDescType
            .aspects.availableValues.filter((value) => value.code !== 'NDC');

        // We are removing trustee from the available dropdown
        return nonOperatorValues.map(
            (feature) => ({
                code: feature.code,
                name: {
                    defaultMessage: feature.name.replace(/([A-Z])/g, ' $1').trim(),
                    id: feature.name
                }
            })
        );
    }, [driverData.nonOperatorDescType.aspects.availableValues]);

    // Not lic. by choice and suspense/revoked lic. need an explaination
    const codeNeedsFurtherExplaination = ['NCL', 'SRL']
        .includes(driverData.nonOperatorDescType?.value?.code);

    const isNonOperatorOther = driverData.nonOperatorDescType?.value?.code === 'OTH';

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'top',
            showRequired: true,
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        NonOperatorDescription: {
            availableValues: nonOperatorDropdownListValues()
        },
        SuspRevokPleaseExplID: {
            required: codeNeedsFurtherExplaination,
            visible: codeNeedsFurtherExplaination
        },
        NonOperatorDescriptionText: {
            visible: isNonOperatorOther,
            required: isNonOperatorOther
        }
    };

    const readValue = useCallback(
        (fieldId, path) => readViewModelValue(
                metadata.pageContent,
                driverData,
                fieldId,
                path,
                overrideProps
            ),
        [overrideProps, driverData]
    );

    const resolvers = {
        resolveClassNameMap: styles
    };

    const onOk = useCallback(
        () => {
            if (!isComponentValid) {
                return false;
            }

            const wrapperObj = {
                driverVM: driverData
            };

            return onResolve(wrapperObj);
        }, [driverData, isComponentValid, onResolve]
    );

    const onCancel = useCallback(
        () => {
            if (driverPageDisregardFieldValidation) {
                driverPageDisregardFieldValidation('nonOperatorComponent');
            }

            onReject();
        }, [driverPageDisregardFieldValidation, onReject]
    );

    const cancelCloseButtonText = viewOnlyMode
        ? translator(e1pCommonMessages.close) : translator(e1pCommonMessages.cancel);

    return (
        <ModalNext isOpen={isOpen} onRequestClose={onOk}>
            <ModalHeader title={title} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={driverData}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    onValidationChange={setComponentValidation}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    {cancelCloseButtonText}
                </Button>
                <Button
                    onClick={onOk}
                    hidden={viewOnlyMode}
                    disabled={!isComponentValid || viewOnlyMode}
                >
                    {translator(e1pCommonMessages.save)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

NonOperatorComponent.propTypes = {
    driverVM: PropTypes.shape({
        possibleAddresses: PropTypes.shape({}),
        standardizationChoices: PropTypes.shape({})
    }).isRequired,
    title: PropTypes.string.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    onReject: PropTypes.func,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    id: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    driverPageDisregardFieldValidation: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool
};
NonOperatorComponent.defaultProps = {
    viewOnlyMode: false,
    isOpen: false,
    onReject: undefined,
    onResolve: undefined
};
export default NonOperatorComponent;
