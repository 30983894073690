import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitleText: {
        id: 'e1p.endorsement.common.views.contact-for-assistance.Error',
        defaultMessage: 'Error',
    },
    contactForAssistanceText: {
        id: 'e1p.endorsement.common.views.contact-for-assistance.An error has occurred. Please try again later or contact us for assistance.',
        defaultMessage: 'An error has occurred. Please try again later or contact us for assistance.',
    },
    backToPolicyButtonText: {
        id: 'e1p.endorsement.common.views.contact-for-assistance.Back to Policy',
        defaultMessage: 'Back to Policy',
    }
});
