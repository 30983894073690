
import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {
    findIndex as _findIndex,
    get as _get,
    isNil as _isNil,
    set as _set,
    isEmpty as _isEmpty,
    isUndefined as _isUndefined,
} from 'lodash';
import { AutoLossService, PolicyDetailsService } from 'e1p-capability-gateway';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardContext } from 'e1p-portals-wizard-react/Wizard/WizardContext';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { PaymentHelper } from 'e1p-capability-payment';
import { error as loggerError } from '@jutro/logger';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { QuoteProposalUtil, PaymentPageUtil } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { useUWIssueUtil, e1pUserAccessUtil } from 'e1p-capability-hooks';
import {
    parseErrors
} from '@xengage/gw-portals-edge-validation-js';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import appConfig from 'app-config';
import EUReports from './Reports/EUReports';
import messages from './PaymentDetailsPage.messages';
import metadata from './PaymentDetailsPage.metadata.json5';

const LOB = 'personalUmbrella_EU';
const INITIAL_PAYMENT_OPTION_PATH = 'baseData.value.conversionDTO.initialPaymentType';

function initializeVM(submissionVM) {
    if (submissionVM.bindData.value) {
        submissionVM.bindData.paymentDetails.value = submissionVM.bindData.paymentDetails.value || {};
    }
}

// need to toggle paymentus per env alignment
const environment = _get(appConfig, 'env.AMFAM_ENV', 'local');
// These environments doesn't have paymentus, this will make signature available and bind policy without payment
const envsWithoutPayment = ['dev', 'local', 'sbx', 'dev2', 'int2', 'qa2', 'dev3', 'gwupgrade1', 'int', 'perf2'];
const requiresPaymentForEnvironment = !envsWithoutPayment.includes(environment);

function PaymentDetailsPage(props) {
    const modalApi = useModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        wizardSnapshot,
        onPageJump,
        steps,
        jumpTo,
        disableAllSteps,
        isSkipping
    } = props;
    const [validationErrors, setValidationErrors] = useState([]);
    const [isCallingService, setIsCallingService] = useState(false);
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [isCallingCanPurchase, setIsCallingCanPurchase] = useState(false);
    const [isVMInitialized, setIsVMInitialized] = useState(false);
    const [bindOperationsInProcess, setBindOperationsInProcess] = useState(false);
    const [signatureSuccess, setSignatureSuccess] = useState(false);
    const { authHeader, authUserData } = useAuthentication();
    const [underwritingIssuesPopupAppeared, setUnderwritingIssuesPopupAppeared] = useState(false);
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const [keepShowingIframeAfterPayment, setKeepShowingIframeAfterPayment] = useState(false);
    const [fieldIssue, setFieldIssue] = useState(false);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('PaymentDetailsPage');
    const translator = useTranslator();
    const amfamOktaToken = useContext(AmfamOktaTokenContext);
    const wizardContext = useContext(WizardContext);
    const PAY_MONTHLY = 'af:payMthly3';
    const isPaymentDone = !!_get(submissionVM, 'bindData.value.paymentRefNumber', false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isMailingComponentValid, updateIsBillingComponentValid] = useState(true);
    const [isBillingComponentValid, updateIsMailingComponentValid] = useState(true);
    const [isInsuredComponentValid, updateIsInsuredComponentValid] = useState(true);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const isSignatureStatusAsCompleted = _get(submissionVM, 'value.baseData.signature_Ext.status') === 'COMPLETED';
    const [selectedPaymentOption, setSelectedPaymentOption] = useState(_get(submissionVM, INITIAL_PAYMENT_OPTION_PATH));
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    // It is used to show loader message 'Preparing Required Documents' when gettingSignatureDocument is true
    const [gettingSignatureDocument, setGettingSignatureDocument] = useState(false);
    // It is used to show loader message 'Saving the Insureds contact information' when isInsuredContactUpdating is true
    const [isInsuredContactUpdating, setIsInsuredContactUpdating] = useState(false);
    const { paymentRequiredForOpco } = appConfig;
    const { opCo } = amfamOktaToken;
    const [requiresPayment, setIsRequiresPayment] = useState(requiresPaymentForEnvironment && paymentRequiredForOpco.includes(opCo))
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);
    const canUserIssueWithoutPayment = authUserData.permissions_Ext.includes('issuewithoutpayment_ext');

    const { isUserAuthorisedToPayAndBind } = e1pUserAccessUtil(authUserData, opCo);
    const canUserPayAndBindQuote = isUserAuthorisedToPayAndBind();

    useEffect(() => {
        // It will make payment required if the environment and operating company condition matches (MSA)
        setIsRequiresPayment(requiresPaymentForEnvironment && paymentRequiredForOpco.includes(opCo))
    }, [opCo, paymentRequiredForOpco]);

    const {
        hasUWIssues
    } = useUWIssueUtil(
        submissionVM,
        updateWizardData,
        jumpTo,
        steps
    );


    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isBillingComponentValid && isMailingComponentValid && isPageSubmitted && isInsuredComponentValid) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isPageSubmitted, isBillingComponentValid, isMailingComponentValid, isInsuredComponentValid]);

    const showPopupIfUWIssueExists = useCallback(() => {
        if (hasUWIssues() && !underwritingIssuesPopupAppeared) {
            setUnderwritingIssuesPopupAppeared(true);

            const indexForCoveragePage = _findIndex(steps, ({ path }) => path === '/coverages');

            jumpTo(indexForCoveragePage);
        }
    }, [hasUWIssues, jumpTo, steps, underwritingIssuesPopupAppeared]);

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();

            if (quoteProposalLink) {
                window.open(quoteProposalLink, '_blank');

                return true;
            }
        }, [quoteProposalLink]
    );

    // When payment option changes, we need to get a new doc url
    useEffect(() => {
        if (submissionVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            submissionVM.paymentOptionChanged = false;
        }
    }, [authHeader, submissionVM, submissionVM.paymentOptionChanged]);

    const showModalReports = useCallback(
        async (vm, retrievedData) => {
            const componentProps = {
                submissionVM: vm,
                updateWizardData,
                incidentData: retrievedData,
                wizardContext,
                steps,
                wizardSnapshot,
                onPageJump,
                closePopupAction: showPopupIfUWIssueExists
            };
            const result = await modalApi.showModal(<EUReports {...componentProps} />);

            return result;
        },
        [onPageJump, steps, updateWizardData, wizardContext, wizardSnapshot, showPopupIfUWIssueExists, modalApi]
    );

    const popOverAction = useCallback(
        async (retrievedData) => {
            showModalReports(submissionVM, retrievedData)
                .then(() => { })
                .catch(() => { });
        },
        [showModalReports, submissionVM]
    );

    /**
     * Check quote data dto for validation errors and exceptions
     *   that come from in page api response (not onNext calls)
     */
    const checkForInPageErrors = useCallback(
        (qdd) => {
            const errorsAndWarnings = _get(qdd, 'errorsAndWarnings', []);

            // uw goes in pop up; not top banner
            delete errorsAndWarnings.underwritingIssues;

            const exceptionsPath = 'baseData.exceptions_Ext';
            const errors = parseErrors(errorsAndWarnings);
            const exceptions = _get(qdd, exceptionsPath, []);
            const errorConvertingToFullApp = errors?.length > 0 || exceptions.length > 0;

            if (errorConvertingToFullApp) {
                setValidationErrors(errors.concat(exceptions));
            }
        },
        [],
    );

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = _get(submissionVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmail = _get(submissionVM, 'lobData.personalUmbrella_EU.paperlessEmail.value');

        return isPaperlessEmailUpdated && pniEmail && pniEmail !== paperlessEmail;
    }, [submissionVM, isPaperlessEmailUpdated]);

    const checkCanPurchase = useCallback(async () => {
        if (!isSkipping
            && submissionVM.value.quoteType === 'Full'
            // Check if period status for submission is quoted to call canPurchase or  disable steps
            && submissionVM.baseData.periodStatus.value.code === 'Quoted') {
            if (isPaymentDone && requiresPayment) {
                disableAllSteps();
            }

            setIsCallingCanPurchase(true);

            const response = await LoadSaveService.canPurchase(submissionVM.value, authHeader);

            // have to set uw issues from the can purchase so the util can see them
            _set(
                submissionVM.value,
                'errorsAndWarnings.underwritingIssues',
                response.errorsAndWarnings.underwritingIssues
            );

            // this is to hide paymentus component if there is any validation error
            const fieldError = _get(response, 'errorsAndWarnings.validationIssues.fieldIssues', []);

            setFieldIssue(!!(fieldError.length));

            const licensedProducers = _get(response, 'licensedProducers.licensedProducers', []);

            _set(submissionVM.value, 'licensedProducers', response.licensedProducers);
            // check for validation errors
            checkForInPageErrors(response);
            // update wizard data with any uw issues and show if exists
            updateWizardData(submissionVM);
            // If UW issues exists on can purchase, redirect user to Coverage page
            showPopupIfUWIssueExists();

            // set producer code options for signin agents
            if (licensedProducers.length > 0) {
                setProducerCodeOptions(
                    licensedProducers.map((value) => ({
                            code: value.producerID,
                            name: `${value.producerName.firstName} ${value.producerName.lastName}`
                        }))
                );
            }

            setIsCallingCanPurchase(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isVMInitialized) {
            initializeVM(submissionVM);
            setIsVMInitialized(true);
        }
    }, [isVMInitialized, submissionVM]);

    const getAutoIncidentDetails = useCallback(async () => {
        setIsCallingService(true);
        AutoLossService.loadAutoLosses(_get(submissionVM, 'quoteID.value'), authHeader)
            .then((response) => {
                popOverAction(response);
            })
            .finally(() => {
                setIsCallingService(false);
                updateWizardData(submissionVM);
            });
    }, [authHeader, popOverAction, submissionVM, updateWizardData]);

    /**
     * Initialization code
     */
    useEffect(() => {
        QuoteProposalUtil.fetchQuoteProposal(
            submissionVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );

        // Check book transfer status from Location Code
        const externalId = _get(submissionVM, 'baseData.externalID_Ext.value');

        if (externalId) {
            PolicyDetailsService.getProducerDetails(
                externalId,
                authHeader
            ).then((policyDetails) => {
                setBookTransferIndicator(_get(policyDetails, 'bookRollIndicator', false));

                if (!selectedPaymentOption && _get(policyDetails, 'bookRollIndicator', false)) {
                    setSelectedPaymentOption('PayLater');
                }
            }).catch((error) => {
                loggerError(`Error fetching book transfer indicator details: ${error}`);
                setValidationErrors(
                    [
                        ...validationErrors,
                        {
                            level: 'LEVEL_ERROR',
                            description: translator(e1pCommonMessages.genericErrorText)
                        }
                    ]
                );
            });
        }

        const executeOnPageload = async () => {
            if (submissionVM.value.quoteType === 'Full') {
                showPopupIfUWIssueExists();
                await checkCanPurchase();

                // check payment receipt if full quote and continue (never on convert from quick to full)
                if (!isPaymentDone && requiresPayment) {
                    PaymentHelper.getReceiptNumber(
                        _get(submissionVM, 'quoteID.value', submissionVM.jobID?.value), authHeader
                    ).then((referenceNumber) => {
                        // set value from receipt in case it did not get saved to PC
                        if (referenceNumber) {
                            _set(submissionVM, 'bindData.value.paymentRefNumber', referenceNumber);
                            updateWizardData(submissionVM);
                        }
                    }).catch((err) => {
                        loggerError(err);
                    });
                }
            } else if (submissionVM.value.quoteType !== 'Full') {
                setIsCallingService(true);
                submissionVM.value = await LoadSaveService.convertToFullApp(
                    submissionVM.value,
                    authHeader
                );

                const underwritingIssues = _get(submissionVM, 'errorsAndWarnings.underwritingIssues.value');

                checkForInPageErrors(submissionVM.value);
                _set(
                    submissionVM.value,
                    'errorsAndWarnings.underwritingIssues',
                    underwritingIssues
                );
                setIsCallingService(false);
                updateWizardData(submissionVM);
                showPopupIfUWIssueExists();
                await checkCanPurchase();

                if (!hasUWIssues()) {
                    await getAutoIncidentDetails();
                }

                updateWizardSnapshot(submissionVM);
            }
        };

        executeOnPageload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const availableValuesForPaymentOptions = useMemo(() => {
        const correctOffering = submissionVM.lobData.personalUmbrella_EU.offerings.value[0];

        if (correctOffering.paymentPlans !== undefined) {
            return correctOffering.paymentPlans
                .filter((paymentPlans) => paymentPlans.name)
                .reduce(
                    (paymentPlanMap, paymentPlan) => ({
                        ...paymentPlanMap,
                        [paymentPlan.billingId]: paymentPlan
                    }),
                    {}
                );
        }

        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(submissionVM.lobData.personalUmbrella_EU.offerings.value)]);

    const setErrors = useCallback((removeUnnecessaryErrors = false) => {
        const shouldShowMissingPaymentError = requiresPayment && !isPaymentDone && selectedPaymentOption !== 'PayLater' && !canUserIssueWithoutPayment;

        const isSignatureMissing = _get(submissionVM, 'baseData.signatureType_ext.value') === undefined || !signatureSuccess;
        const isUwIssuePresent = hasUWIssues();

        const uniqueErrors = PaymentPageUtil.getValidationErrorsForPaymentPage(
            validationErrors, shouldShowMissingPaymentError, isSignatureMissing,
            isUwIssuePresent, translator, removeUnnecessaryErrors);

        setValidationErrors(uniqueErrors);


    }, [hasUWIssues, isPaymentDone, signatureSuccess, submissionVM, translator, validationErrors, selectedPaymentOption, requiresPayment, canUserIssueWithoutPayment]);

    useEffect(() => {
        if (signatureSuccess || isPaymentDone) {
            setErrors(true);
        }
        // want to trigger this only on signatureSuccess update and payment update
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureSuccess, isPaymentDone])

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            setErrors();
            window.scrollTo(0, 0);

            return false;
        }

        setBindOperationsInProcess(true);

        // If book transfer indicator is yes, and initial payment option has changed - save it before binding it
        if (bookTransferIndicator && _get(submissionVM, INITIAL_PAYMENT_OPTION_PATH) !== selectedPaymentOption) {
            _set(submissionVM, INITIAL_PAYMENT_OPTION_PATH, selectedPaymentOption);

            submissionVM.value = await LoadSaveService.updateQuotedSubmission(
                submissionVM.value,
                authHeader
            );

            updateWizardData(submissionVM);

            const errorsAndWarnings = _get(submissionVM, 'value.errorsAndWarnings', {});
            const errors = parseErrors(errorsAndWarnings);
            const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

            // In case of error on updating quoted submission, show errors and do not bind
            if (errors.length || exceptions.length) {
                setValidationErrors(errors.concat(exceptions));
                setBindOperationsInProcess(false);

                return false;
            }
        }

        const quoteData = _get(submissionVM, 'quoteData');

        if (!requiresPayment) {
            const { paymentReferenceNumberForLocal } = appConfig.amfamRewritePaymentConfig;

            _set(submissionVM, 'bindData.value.paymentRefNumber', paymentReferenceNumberForLocal);
        }

        const response = await LoadSaveService.bindSubmission(submissionVM.value, authHeader);

        response.quoteData = quoteData.value;
        submissionVM.value = response;
        updateWizardData(submissionVM);

        const errorsAndWarnings = _get(submissionVM, 'value.errorsAndWarnings', {});
        const errors = parseErrors(errorsAndWarnings);
        const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

        // In case of error on bind submission, show errors and do not bind
        if (errors.length || exceptions.length) {
            setValidationErrors(errors.concat(exceptions));
            setBindOperationsInProcess(false);

            return false;
        }

        setBindOperationsInProcess(false);

        return submissionVM;
    }, [LoadSaveService, authHeader, isComponentValid, setErrors, submissionVM, updateWizardData, bookTransferIndicator, selectedPaymentOption, requiresPayment]);

    /**
     * Helper callback which opens the "Third-Party Interest" wizard page.
     */
    const openTPIPageHandler = useCallback(() => {
        const indexForTPIPage = _findIndex(steps, ({ path }) => path === '/third-party-interest');

        jumpTo(indexForTPIPage);
    }, [steps, jumpTo]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isCallingService) {
            loadingMessage = translator(messages.applyingChangesMessage);
        } else if (isInsuredContactUpdating) {
            loadingMessage = translator(e1pCommonMessages.savingContactinfoMessage);
        } else if (gettingSignatureDocument) {
            loadingMessage = translator(e1pCommonMessages.prepareReqDocuments);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (bindOperationsInProcess) {
            loadingMessage = translator(messages.completingYourPurchaseMessage);
        }

        return loadingMessage;
    }, [isCallingService, isInsuredContactUpdating, gettingSignatureDocument, isSavingCurrentPageChanges, bindOperationsInProcess, translator]);

    const handleContactInfoUpdate = useCallback(async () => {
        // If paperless Email id exist and pni email id is undefiend, then update pni email to paperless email
        if (_isUndefined(_get(submissionVM.value, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1'))
            && !!_get(submissionVM.value, 'lobData.personalUmbrella_EU.paperlessEmail')) {
            _set(submissionVM.value, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1',
                _get(submissionVM.value, 'lobData.personalUmbrella_EU.paperlessEmail'));
        }

        const signatureType = _get(submissionVM.value, 'baseData.signatureType_ext', undefined);

        // contact change requires another quote
        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
            submissionVM.value, authHeader
        );
        _set(submissionVM.value, 'baseData.signatureType_ext', signatureType);
        updateWizardSnapshot(submissionVM);
        updateWizardData(submissionVM);
    }, [LoadSaveService, authHeader, submissionVM, updateWizardData, updateWizardSnapshot]);

    const onSave = useCallback(
        async () => {
            try {
                if (!isMailingComponentValid || !isBillingComponentValid || !isInsuredComponentValid) {
                    updateIsPageSubmitted(true);
                    window.scrollTo(0, 0);

                    return false;
                }

                setIsSavingCurrentPageChanges(true);

                const signatureType = _get(submissionVM.value, 'baseData.signatureType_ext', undefined);
                // We get licensedProducers in canPurchase call so storing it in variable before we do saveAndQuote
                const licensedProducers = _get(submissionVM, 'value.licensedProducers');

                if (bookTransferIndicator) {
                    _set(submissionVM, INITIAL_PAYMENT_OPTION_PATH, selectedPaymentOption);
                }

                const response = await LoadSaveService.saveAndQuoteSubmission(
                    submissionVM.value,
                    authHeader
                );

                _set(response, 'licensedProducers', licensedProducers);
                _set(submissionVM, 'value', response);
                _set(submissionVM.value, 'baseData.signatureType_ext', signatureType);
                QuoteProposalUtil.fetchQuoteProposal(
                    submissionVM,
                    setQuoteProposalCompleted,
                    setQuoteProposalLink,
                    authHeader,
                    setIsQuoteProposalFailed
                );

                const fieldError = _get(response, 'errorsAndWarnings.validationIssues.fieldIssues', []);

                setFieldIssue(!!(fieldError.length));
                // check for validation errors
                checkForInPageErrors(response);
                updateWizardData(submissionVM);

                const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldError) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        },
        [
            LoadSaveService, authHeader, checkForInPageErrors, isBillingComponentValid, isMailingComponentValid,
            submissionVM, updateWizardData, updateWizardSnapshot, isInsuredComponentValid, selectedPaymentOption,
            bookTransferIndicator
        ]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isCallingService && !bindOperationsInProcess
            && !isCallingCanPurchase && !isSavingCurrentPageChanges
            && !gettingSignatureDocument && !isInsuredContactUpdating, [bindOperationsInProcess, gettingSignatureDocument, isCallingCanPurchase, isCallingService, isInsuredContactUpdating, isSavingCurrentPageChanges]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'top',
            readOnly: isPaymentDone && requiresPayment,
            showErrors: isPageSubmitted,
            autoComplete: false,
            showRequired: true
        },
        paymentDetailsPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        quoteProposalLinkContainer: {
            onClick: (e) => openDocument(e),
            disabled: !quoteProposalCompleted
        },
        paymentDetailsPageContainer: {
            visible: !isCallingService && !bindOperationsInProcess
                && !isCallingCanPurchase && !isSavingCurrentPageChanges && !isInsuredContactUpdating,
            // Facing some issues, if i try to hide the container using visibility. 
            // Initially, visibility is false so all the components will be unmount 
            // when the visibility become true then all components will be mount again 
            // it will re-trigger all the react hook components so handled hiding using CSS
            className: gettingSignatureDocument ? "display-none" : "mt-8"
        },
        totalPremiumID: {
            value: !_isNil(availableValuesForPaymentOptions[
                _get(submissionVM, 'bindData.selectedPaymentPlan.value')
            ])
                ? availableValuesForPaymentOptions[
                    _get(submissionVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined
        },
        totalPremiumWithFullPayID: {
            value: !_isNil(availableValuesForPaymentOptions[PAY_MONTHLY])
                ? availableValuesForPaymentOptions[PAY_MONTHLY].total
                : undefined
        },
        monthlyPaymentScheduleComponent: {
            quoteID: _get(submissionVM, 'quoteID.value', submissionVM.jobID?.value),
            authHeader,
            transactionTotalAmount: !_isNil(availableValuesForPaymentOptions[
                _get(submissionVM, 'bindData.selectedPaymentPlan.value')
            ])
                ? availableValuesForPaymentOptions[
                    _get(submissionVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined,
            changeInCost: submissionVM.transactionCost?.value,
            startDate: submissionVM.baseData.periodStartDate.value,
            endDate: submissionVM.baseData.periodEndDate.value,
            jobTypeCode: submissionVM.baseData.jobType.value.code,
            offerings: _get(submissionVM, 'lobData.personalUmbrella_EU.offerings.value')
        },
        mailingAndBillingAddressComponent: {
            transactionVM: submissionVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            viewOnlyMode: isPaymentDone,
            updateIsMailingComponentValid,
            updateIsBillingComponentValid,
            showErrors: isPageSubmitted
        },
        paymentOptionsID: {
            submissionVM,
            authHeader,
            updateWizardData,
            LOB,
            viewOnly: isPaymentDone && requiresPayment
        },
        addThirdPartyInterestButton: {
            disabled: isPaymentDone && requiresPayment
        },
        // IAP-371 show TPI component in only read only Mode
        e1ptpiDisplayTableContainer: {
            transactionVM: submissionVM,
            authHeader,
            updateWizardData,
            setIsSavingTPI: () => { },
            showErrors: false,
            onValidate: () => { },
            disregardFieldValidationParentPage: () => { },
            viewOnlyMode: true,
            showTitle: false
        },
        displayPNIAddress: {
            readOnly: true
        },
        paymentOptions: {
            visible: !!bookTransferIndicator
                && !hasUWIssues()
                && submissionVM.value.quoteType === 'Full'
                && !isSkipping
                && !fieldIssue,
            value: selectedPaymentOption,
            onValueChange: setSelectedPaymentOption
        },
        paymentusComponent: {
            submissionVM,
            amfamAccessToken: amfamOktaToken.amfamAccessTokenHeader,
            updateWizardData,
            wizardSnapshot,
            // Show payment component when payment is not made, submission Quote is available and has no UW issues
            visible: canUserPayAndBindQuote
                && requiresPayment
                && (!isPaymentDone || keepShowingIframeAfterPayment)
                && (bookTransferIndicator === false || selectedPaymentOption === 'PayNow')
                && !hasUWIssues()
                && submissionVM.value.quoteType === 'Full'
                && !isSkipping
                && !fieldIssue,
            setKeepShowingIframeAfterPayment,
            setValidationErrors,
            disableAllSteps,
            LOB
        },
        signatureComponent: {
            submissionVM,
            updateWizardData,
            authHeader,
            LOB,
            onSignatureSuccess: setSignatureSuccess,
            disabled: signatureSuccess,
            readOnly: hasUWIssues(),
            producerCodeOptions,
            setProducerCodeOptions,
            setGettingSignatureDocument,
            // Only make it visible after payment done & canPurchase call is not in progress
            visible: (isPaymentDone || !requiresPayment || selectedPaymentOption === 'PayLater' || canUserIssueWithoutPayment) && !isCallingCanPurchase,
            saveLocationCodeChanges: onSave,
            checkCanPurchase,
            locationCodeEditable: true
        },
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        insuredContactInfo: {
            showPaperless: true,
            updateIsInsuredComponentValid,
            showErrors: isPageSubmitted,
            setIsPaperlessEmailUpdated,
            setIsInsuredContactUpdating
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        quoteProposalFailureErrorDiv: {
            visible: isQuoteProposalFailed
        },
        userNotAuthorisedErrorDiv: {
            visible: !canUserPayAndBindQuote
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            openTPIPageHandler,
            onValidate,
            onSaveClick: handleContactInfoUpdate
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps),
        [submissionVM, overrideProps]
    );

    /**
     * need to check payment reference Number and SignatureType, if ENV is not Localhost.
     * for localhost we are defaulting payment reference number
     */
    const isPageValid = useCallback(() => {
        const isQuoted = _get(submissionVM, 'baseData.periodStatus.value.code') === 'Quoted';
        const isSignatureDone = _get(submissionVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;
        const licensedProducerSelected = _get(submissionVM, 'value.bindData.licensedProducerID', false);

        if (requiresPayment && !isPaymentDone && selectedPaymentOption !== 'PayLater' && !canUserIssueWithoutPayment) {
            return false;
        }

        return isQuoted && isSignatureDone && !hasUWIssues() && licensedProducerSelected;
    }, [submissionVM, signatureSuccess, isPaymentDone, hasUWIssues, selectedPaymentOption, requiresPayment, canUserIssueWithoutPayment]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, signatureSuccess, submissionVM]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            nextLabel={messages.issueLabel}
            onNext={onNext}
            disablePrevious={(isPaymentDone && requiresPayment) || gettingSignatureDocument || isInsuredContactUpdating}
            disableNext={gettingSignatureDocument || isInsuredContactUpdating}
            showNext={canUserPayAndBindQuote}
            showCancel={!isSignatureStatusAsCompleted}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && (!isMailingComponentValid || !isBillingComponentValid || !isInsuredComponentValid)
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PaymentDetailsPage.propTypes = wizardProps;
export default PaymentDetailsPage;
