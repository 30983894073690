import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { withModalContext } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import {
    DocumentRetrievalService,
    DocumentUploadService
} from 'e1p-capability-gateway';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import DocumentsComponent from '../../Components/E1PDocumentsComponent/DocumentsComponent';
import gatewayMessages from '../../gateway.messages';
import metadata from './Documents.metadata.json5';
import messages from './Documents.messages';

class PolicyDocumentsWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}),
        policyNumber: PropTypes.string,
        jobNumber: PropTypes.string,
        basePath: PropTypes.string.isRequired
    }

    static defaultProps = {
        policyNumber: undefined,
        jobNumber: undefined,
        authHeader: undefined
    }

    state = {
        documentsData: [],
        isFetchingDocuments: false,
        isUploadingDocuments: false
    }

    componentDidMount() {
        this.getDocuments();
    }


    handleError = (title, message) => this.props.modalContext.showAlert({
            status: 'error',
            icon: 'mi-error-outline',
            title,
            message
        })

    setFetchingDocuments = (value) => {
        this.setState({
            isFetchingDocuments: value
        });
    };

    setUploadingDocuments = (value) => {
        this.setState({
            isUploadingDocuments: value
        });
    };

    getLoadingIndicatorMessage = () => {
        const { isFetchingDocuments, isUploadingDocuments } = this.state;
        const translator = this.context;
        let loadingMessage = '';

        if (isFetchingDocuments) {
            loadingMessage = translator(messages.fetchingDocumentsText);
        } else if (isUploadingDocuments) {
            loadingMessage = translator(messages.uploadingDocumentText);
        }

        return loadingMessage;
    }

    getDocuments = (async () => {
        const {
            authHeader, policyNumber, jobNumber, basePath
        } = this.props;

        try {
            this.setFetchingDocuments(true);

            let getAvailableDocumentsData = [];

            try {
                if (_.startsWith(basePath?.toLowerCase(), '/policies/') && policyNumber) {
                    // fetch documents for policy Number
                    getAvailableDocumentsData = await DocumentRetrievalService
                        .getDocuments(policyNumber, authHeader);
                } else if (jobNumber) {
                    // fetch documents for Job Number
                    getAvailableDocumentsData = await DocumentRetrievalService
                        .getQuoteDocuments(jobNumber, authHeader);
                }
            } catch {
                getAvailableDocumentsData = [];
            }

            this.setState({
                documentsData: getAvailableDocumentsData
            });
            this.setFetchingDocuments(false);
        } catch (e) {
            this.handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorLoadingDocument
            );
        }
    })


    getDocumentUploadToken = (async () => {
        const { authHeader } = this.props;

        try {
            const uploadTokenID = await DocumentUploadService
                .documentUploadToken([], authHeader);

            return uploadTokenID;
        } catch (e) {
            return this.handleError(
                commonMessages.errorUploadTitle,
                commonMessages.errorGenerateUploadToken
            );
        }
    });

    uploadDocument = (async (file) => {
        const {
            authHeader, policyNumber, jobNumber
        } = this.props;

        this.setUploadingDocuments(true);

        const uploadToken = await this.getDocumentUploadToken();
        const documentMetaDataTemplate = {
            docUID: uploadToken,
            documentType: 'letter_received',
            securityType: 'unrestricted',
            status: 'approved',
            policyNumber,
            jobNumber,
            name: file.name,
            description: file.name,
            mimeType: file.type,
            sessionID: uploadToken
        };

        try {
            const documentUploadData = await DocumentUploadService
                .uploadDocument(documentMetaDataTemplate, file,
                    authHeader);

            if (!_.isEmpty(documentUploadData)) {
                this.getDocuments();
            }

            this.setUploadingDocuments(false);
        } catch (error) {
            this.handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorUploadingDocument
            );
            this.setUploadingDocuments(false);
        }
    })

    getMetadataImplementation = () => {
        const { documentsData, isFetchingDocuments, isUploadingDocuments } = this.state;
        const translator = this.context;
        const {
            jobNumber, policyNumber
        } = this.props;
        const overrides = {
            policyDocuments: {
                visible: !isFetchingDocuments && !isUploadingDocuments,
                initialDocumentsData: documentsData,
                uploadDocument: this.uploadDocument,
                noDataMessage: translator(messages.noPolicyDocuments),
                handleRefreshDocuments: this.getDocuments,
                jobNumber,
                policyNumber
            },
            documentLoadingIndicator: {
                loaded: !isFetchingDocuments && !isUploadingDocuments,
                text: this.getLoadingIndicatorMessage()
            }
        };

        const resolvers = {
            resolveComponentMap: {
                documentscomponent: DocumentsComponent
            }
        };

        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }

    render() {
        return this.getMetadataImplementation();
    }
}

const PolicyDocuments = withModalContext(PolicyDocumentsWithoutModalContext);

export default withAuthenticationContext(PolicyDocuments);
