/* eslint-disable camelcase */
import { useCallback } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { RenewalService } from 'e1p-capability-renewal';
import { commonMessages, renewalMessages } from 'e1p-platform-translations';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

/**
 * Export applyRenewalChanges func which calls api to applyRenewalChanges
 *
 * @param {object} renewalVM
 * @param {func} updateWizardData
 * @param {func} setIsApplyingChanges
 */

function useRenewalPageUtil(
    renewalVM,
    updateWizardData,
    setIsApplyingChanges,
    showUnderwritingIssuesPopup
) {
    const history = useHistory();
    const { authHeader } = useAuthentication();
    const translator = useTranslator();

    /**
     * E1PAP1PC-14713
     * We are defaulting signatureType_ext and setting signature success
     * as Signature Component does not load without visiting signature tab
     * and all these variables gets defaulted on signature page
     * @param {Object} authUserData
     * @param {Function} setSignatureSuccess
     */
    const setDefaultValuesForSignature = (authUserData, setSignatureSuccess) => {
        _.set(
            renewalVM, 'value.baseData.signatureType_ext',
            _.get(renewalVM, 'value.baseData.signature_Ext.signatureType')
        );

        const isSignatureErrored = _.get(renewalVM, 'value.baseData.signature_Ext.status') === 'ERROR';
        const isSignaturePresent = !_.isEmpty(_.get(renewalVM, 'value.baseData.signature_Ext.status', ''));

        if (isSignaturePresent && !isSignatureErrored) {
            setSignatureSuccess(true);
        }
    };

    /**
     * We show confirmation popup.
     * On selection of Yes we will call applyRenewalChanges api.
     * If there are no errors or exceptions we will land user on Summary Page.
     * otherwise we keep user on same page or error handling framework will take user to
     * page having error/s.
     */
    const applyChangesToRenewal = useCallback(async () => {
        const { modalApi } = _.get(window, '__giamfam.modalApi');
        const modalNext = await modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: translator(renewalMessages.applyChanges),
            message: translator(renewalMessages.applyChangeMessage),
            confirmButtonText: translator(renewalMessages.applyChangeConfirmationYes),
            cancelButtonText: translator(commonMessages.cancel)
        });

        if (modalNext === 'cancel') {
            return _.noop();
        }

        try {
            setIsApplyingChanges(true);

            const jobID = _.get(renewalVM.value, 'jobID');
            const redirectedPath = `/renewal/${jobID}/summary`;
            // Apply changes to renewal
            const applyRenewalChangeResponse = await RenewalService
                .applyRenewalChanges([jobID], authHeader);
            const validationErrors = _.get(applyRenewalChangeResponse, 'errorsAndWarnings.validationIssues.fieldIssues', []);
            const underwritingIssues = _.get(applyRenewalChangeResponse, 'errorsAndWarnings.underwritingIssues', []);

            if (underwritingIssues.length > 0
                || validationErrors.length > 0) {
                const signatureType = _.get(renewalVM, 'value.baseData.signatureType_ext');

                _.set(renewalVM, 'value', applyRenewalChangeResponse);
                _.set(
                    renewalVM, 'value.baseData.signatureType_ext',
                    signatureType
                );

                if (underwritingIssues.length > 0) {
                    if (showUnderwritingIssuesPopup) {
                        showUnderwritingIssuesPopup(renewalVM);
                    }
                }

                updateWizardData(renewalVM);

                return false;
            }

            if (applyRenewalChangeResponse.baseData?.exceptions_Ext) {
                // when we are getting exceptions, we are not getting anything else like LOBData, etc back from backend.
                _.set(
                    renewalVM, 'value.baseData.exceptions_Ext',
                    applyRenewalChangeResponse.baseData.exceptions_Ext
                );
                updateWizardData(renewalVM);

                return false; 
            }
            
            _.set(renewalVM, 'value', applyRenewalChangeResponse);
            updateWizardData(renewalVM);

            // after applying changes to renewal landing user on renewal summary
            return history.push({
                pathname: redirectedPath,
                isRouteChangedDuringFlow: true
            });
        } catch {
            setIsApplyingChanges(false);

            return _.noop();
        } finally {
            setIsApplyingChanges(false);
        }
    }, [
        authHeader, history, renewalVM, setIsApplyingChanges,
        showUnderwritingIssuesPopup, translator, updateWizardData
    ]);

    return {
        applyChangesToRenewal,
        setDefaultValuesForSignature
    };
}

export default useRenewalPageUtil;
