import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';


export default class GeoService {
    /**
     * Fetch Geo data
     * @param {String} jobNumber String
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @returns {Promise} a promise from the backend call with no result (if successful)
     */
    static getGeoData = (jobNumber, additionalHeaders = {}) => {
        return JsonRPCService.send(
            getProxiedServiceUrl('geo'),
            'getGeoData',
            [jobNumber],
            additionalHeaders
        );
    }
}
