import React, { useCallback, useEffect, useState } from 'react';
import { get as _get, set as _set } from 'lodash';
import { Card } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAddressStandardization } from 'e1p-capability-hooks';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import PropTypes from 'prop-types';
import messages from './E1PEHTPITrusteeDetailComponent.messages';
import metadata from './E1PEHTPITrusteeDetailComponent.metadata.json5';
import styles from './E1PEHTPITrusteeDetailComponent.module.scss';

/**
 * This component file models the panel which appears when the "Add Trustee" button is clicked.
 *
 * @param {Object} props An object containing the properties passed into this component.
 * @returns {Object} An object containing the data required for rendering this component.
 */
const E1PEHTPITrusteeDetailComponent = (props) => {
    const {
        id,
        trusteeDetailVM,
        viewModelService,
        cancelTrusteeButtonOnClickHandler,
        saveTrusteeDetailsToVM,
        authHeader,
        isEditingTPI,
        showErrors,
        onValidate,
        disregardFieldValidationParentPage
    } = props;
    const [tempTrusteeDetailVM, setTempTrusteeDetailVM] = useState(trusteeDetailVM);
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [addressStandardizationResultDTO, updateAddressStandardizationResultDTO] = useState(
        undefined
    );
    const [enteredStateForAddressStandardization, updateEnteredState] = useState(undefined);
    const { getStandardizedAddress, standardizeAddressIfApplicable } = useAddressStandardization(
        viewModelService,
        authHeader
    );
    const [oldTPIAddress, setOldTPIAddress] = useState({});
    const {
        onValidate: setComponentValidation,
        isComponentValid,
        disregardFieldValidation
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    const translator = useTranslator();

    useEffect(() => {
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);


    useEffect(() => {
        setTempTrusteeDetailVM(trusteeDetailVM);
    }, [trusteeDetailVM]);

    /**
     * Helper callback for performing the address standardization as needed. Note that this logic
     * needs to translate "zipCode" into "postalCode" before the standardization can be performed.
     */
    const handleAddressBlur = useCallback(
        async (_, { value, beforeValue }) => {
            if (value === beforeValue) {
                return;
            }

            const addressVM = _get(tempTrusteeDetailVM, 'address');

            updateAddressStandardizationResultDTO(undefined);

            if (
                addressVM
                && _get(addressVM, 'addressLine1.value')
                && _get(addressVM, 'city.value')
                && _get(addressVM, 'state.value')
                && _get(addressVM, 'zipCode.value')
            ) {
                setIsStandardizingAddress(true);
                _set(addressVM, 'postalCode.value', addressVM.zipCode.value);

                const standardizedAddressResult = await getStandardizedAddress(addressVM, false);

                if (standardizedAddressResult) {
                    const address = addressVM.value;
                    const enteredAddress = { ...address };

                    _set(enteredAddress, 'tempID', address.state);
                    _set(
                        enteredAddress,
                        'formattedDisplayName',
                        `${address.addressLine1}, ${address.addressLine2}, ${address.city}, ${address.state}, ${address.zipCode}`
                    );
                    updateEnteredState(enteredAddress);
                    updateAddressStandardizationResultDTO(standardizedAddressResult);
                }

                setIsStandardizingAddress(false);
            }
        },
        [tempTrusteeDetailVM, getStandardizedAddress]
    );

    /**
     * Helper callback for writing values to the view model.
     */
    const writeValue = useCallback(
        (value, path) => {
            /**
             * We are updating standardized address on saveTrustee Call 
             * And as state update is async, updating tempTrusteeDetailVM object value
             */ 
            _set(tempTrusteeDetailVM, path, value);

            const nextFormData = viewModelService.clone(tempTrusteeDetailVM);

            _set(nextFormData, path, value);
            setTempTrusteeDetailVM(nextFormData);
        },
        [tempTrusteeDetailVM, viewModelService]
    );

    /**
     * Helper callback for handling when a standardized address is selected. Note that this logic
     * needs to translate "zipCode" into "postalCode" before the standardization can be performed.
     */
    const onSelectAddress = useCallback(
        (addressToUse) => {
            const addressVM = _get(tempTrusteeDetailVM, 'address');

            addressVM.value = {
                ...addressVM.value,
                addressLine1: addressToUse.addressLine1,
                addressLine2: addressToUse.addressLine2 ? addressToUse.addressLine2 : '',
                city: addressToUse.city,
                zipCode: _get(addressToUse, 'postalCode', _get(addressToUse, 'zipCode')),
                state: addressToUse.state,
                country: 'US'
            };
            writeValue(addressVM.value, 'tempTrusteeDetailVM.address');
            updateEnteredState(undefined);
            updateAddressStandardizationResultDTO(undefined);
        },
        [tempTrusteeDetailVM, writeValue]
    );

    const onAddressChange = useCallback((value, path) => {
        let actualPath = path;
        const actualValue = value;

        if (path === 'address.postalCode') {
            actualPath = 'address.zipCode'
        } else if (path === 'address.value') {
            if (value.postalCode) {
                _set(actualValue, 'zipCode', value.postalCode);
            }
        }

        writeValue(actualValue, actualPath)
    }, [writeValue]);

    /**
     * Helper callback for handling "Save" button clicks. This callback will save the entered
     * trustee details to the "trusteeDetailVM" state variable on the parent component.
     */
    const saveTrusteeButtonOnClickHandler = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);

            return false;
        }

        setIsStandardizingAddress(true);

        const isAddressStandardized = await standardizeAddressIfApplicable(
            oldTPIAddress,
            tempTrusteeDetailVM.address,
            onAddressChange,
            'address.value'
        )

        setIsStandardizingAddress(false);

        if (!isAddressStandardized) {
            // address is not standardized
            return false;
        }

        setOldTPIAddress(_get(tempTrusteeDetailVM, 'address.value'));
        saveTrusteeDetailsToVM(_get(tempTrusteeDetailVM, 'value'));
    }, [isComponentValid, oldTPIAddress, onAddressChange, saveTrusteeDetailsToVM, standardizeAddressIfApplicable, tempTrusteeDetailVM]);

    const onCancel = useCallback((event) => {
        disregardFieldValidation(id);
        disregardFieldValidationParentPage(id);
        cancelTrusteeButtonOnClickHandler(event)
    }, [cancelTrusteeButtonOnClickHandler, disregardFieldValidation, disregardFieldValidationParentPage, id]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted || showErrors,
            autoComplete: false
        },
        ehTPITrusteeDetailComponentIndicator: {
            loaded: !isStandardizingAddress,
            text: translator(e1pCommonMessages.standardizingAddressMessage)
        },
        ehTPITrusteeDetailComponentContainer: {
            visible: !isStandardizingAddress
        },
        trusteeFirstName: {
            required: true
        },
        trusteeLastName: {
            required: true
        },
        trusteeAddressLine1: {
            onBlur: handleAddressBlur,
            required: true,
            visible: isEditingTPI
        },
        trusteeAddressLine2: {
            onBlur: handleAddressBlur,
            visible: isEditingTPI
        },
        trusteeCity: {
            onBlur: handleAddressBlur,
            required: true,
            visible: isEditingTPI
        },
        trusteeState: {
            onBlur: handleAddressBlur,
            required: true,
            visible: isEditingTPI
        },
        trusteeZipCode: {
            onBlur: handleAddressBlur,
            required: true,
            visible: isEditingTPI
        },
        trusteeAddressInfoContainer: {
            visible: isEditingTPI
        },
        trusteeAddress: {
            addressVM: _get(tempTrusteeDetailVM, 'address'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate: setComponentValidation,
            viewOnlyMode: false,
            showParentLoader: setIsStandardizingAddress,
            showErrors: isPageSubmitted || showErrors,
            onAddressChange: (value, path) => onAddressChange(value, `address.${path}`),
            visible: !isEditingTPI && !!tempTrusteeDetailVM,
            shouldStandardizeOnBlur: false
        },
        trusteeAddressStandardizationComponent: {
            addressStandardizationResultDTO,
            visible: !!addressStandardizationResultDTO && isEditingTPI,
            modalDisplay: false,
            selectInlineAddress: onSelectAddress,
            enteredAddress: enteredStateForAddressStandardization
        },
        trusteeActionsContainer: {
            visible: !isStandardizingAddress
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                tempTrusteeDetailVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [tempTrusteeDetailVM, overrideProps]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onCancel,
            saveTrusteeButtonOnClickHandler
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <Card id="trusteeDetailViewPanel" className={styles.modalWidth} isPanel>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={tempTrusteeDetailVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={setComponentValidation}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </Card>
    );
};

/**
 * Define expected types for properties to be passed into this Jutro component.
 */
E1PEHTPITrusteeDetailComponent.propTypes = {
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    id: PropTypes.string.isRequired,
    trusteeDetailVM: PropTypes.shape({}).isRequired,
    cancelTrusteeButtonOnClickHandler: PropTypes.func.isRequired,
    saveTrusteeDetailsToVM: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    isEditingTPI: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired
};

export default E1PEHTPITrusteeDetailComponent;
