import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Icon
} from '@jutro/components';
import { SymbolCurrencyComponent } from 'e1p-capability-policyjob-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import metadata from './E1PRatingPlansComponent.metadata.json5';

const ratingPlanTypeValues = {
    'NCRB': 'NCRB Premium',
    'CTR': 'Consent to Rate Premium',
    'DEVIATION': 'Deviation Premium'
}

function E1PRatingPlansComponent(props) {
    const {
        transactionVM,
        lob
    } = props;
    const translator = useTranslator();

    const getRatingPlanType = (ratePlan) => translator(ratingPlanTypeValues[ratePlan.ratePlanType])

    const getPremium = (ratePlan) => <SymbolCurrencyComponent value={{ amount: ratePlan.totalPremium, currency: 'USD' }} />

    const renderIsSelected = (ratePlan) => {
        if (ratePlan.isSelected) {
            return <Icon icon="mi-check" />
        }

        return null;
    }

    /**
     * Define property overrides for this Jutro component.
     * */
    const overrideProps = {
        '@field': {
            visible: true,
            readOnly: true,
            autoComplete: false
        },
        ratingPlansDataTable: {
            data: (() => {
                const offerings = _.get(transactionVM, `lobData[${lob}].offerings.value`, []);
                let selectedOffering = offerings[0];

                if (offerings.length > 1) {
                    const offeredQuotes = _.get(transactionVM, 'quoteData.offeredQuotes.value', []);
                    const selectedQuoteBranchCode = offeredQuotes?.find((quote) => quote.selected)?.branchCode;

                    if (selectedQuoteBranchCode) {
                        selectedOffering = offerings.find((offering) => offering.branchCode === selectedQuoteBranchCode)
                    }
                }

                return _.get(selectedOffering, 'ratePlans', [])
            })()
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            getRatingPlanType,
            getPremium,
            renderIsSelected
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

E1PRatingPlansComponent.propTypes = {
    transactionVM: PropTypes.shape({}).isRequired,
    lob: PropTypes.string.isRequired
};

export default E1PRatingPlansComponent;
