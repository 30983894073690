import React, {
    useCallback, useEffect, useState
} from 'react';
import { useTranslator } from '@jutro/locale';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, withModalContext } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages } from 'e1p-platform-translations';
import PropTypes from 'prop-types';
import styles from './AddressStandardizationComponent.module.scss';
import metadata from './AddressStandardizationComponent.metadata.json5';
import  messages from './AddressStandardizationComponent.messages';

import { Button } from '@jutro/legacy/components';

/**
 *
 * @param {Object} props addressStandardizationResultDTO is the response from a standardized
 *   API call
 * @returns {*} a modal by default or inline viewmodelform
 *
 * @purpose You can pass in a addressStandardizationResultDTO and this component will display
 *   a modal (or inline) that has radio buttons for you to select a standard address from list of
 *   possible choices.
 *
 *   On close we pass back the selected address. If inline you must provide a function as a prop
 *   that will take the selected address and update state on the parent.
 */
function AddressStandardizationComponent(props) {
    const {
        addressStandardizationResultDTO,
        enteredAddress,
        title,
        modalDisplay,
        selectInlineAddress,
        isOpen,
        onResolve
    } = props;

    const translator = useTranslator();
    const { onValidate } = useValidation(
        'AddressStandardizationComponent'
    );
    const [addressData, updateAddressData] = useState(addressStandardizationResultDTO);
    const [isExactMatchNotFound, setIsExactMatchNotFound] = useState(false);

    const enteredAddressPossibleChoice = [{
        code: enteredAddress.state,
        name: {
            defaultMessage: enteredAddress.formattedDisplayName
                .replace('null, ', '')
                .replace(' ,', ''),
            id: enteredAddress.state
        }
    }];

    const writeValue = useCallback(
        (value) => {
            const nextFormData = _.cloneDeep(addressData);
            const addresses = [
                enteredAddress,
                ...addressData.standardizationChoices
            ];

            _.set(nextFormData, 'addressToUse', addresses.find((element) => element.tempID === value));
            updateAddressData(nextFormData);

            if (selectInlineAddress) {
                selectInlineAddress(nextFormData.addressToUse);
            }
        },
        [addressData, enteredAddress, selectInlineAddress]
    );

    useEffect(() => {
        addressData.standardizationChoices.forEach((address) => {
            if (address.addressLine2 === 'null') {
                _.set(address, 'addressLine2', undefined);
            }
        });

        const allExactMatchAddresses = addressData.standardizationChoices.filter((choice) => choice.isExactMatch);

        // If exact match is not found set it to non exact match choice - returned as standardized choices in response
        if (allExactMatchAddresses.length === 0) {
            setIsExactMatchNotFound(true);
            writeValue(_.get(addressData.standardizationChoices, [0, 'tempID']));
        }
        // Only set on initial load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setPossibleAddressValues = useCallback(() => {
        const possibleAddresses = [];

        addressData.standardizationChoices.forEach((address, index) => {
            const temp = {
                code: `${address.state}${index}`,
                name: {
                    defaultMessage: address.formattedDisplayName.replace('null, ', ''),
                    id: `${address.state}${index}`
                }
            };

            _.set(address, 'tempID', temp.code);
            possibleAddresses.push(temp);
        });

        return possibleAddresses;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        addressOptionRadio: {
            availableValues: setPossibleAddressValues()
        },
        enteredAddressContainer: {
           className: "mb-3"
        },
        enteredAddress: {
            availableValues: enteredAddressPossibleChoice
        },
        standizationChoicesContainer: {
            visible: !isExactMatchNotFound
        },
        noExactMatchContainer: {
            visible: isExactMatchNotFound
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                addressData,
                id,
                path,
                overrideProps
            ),
        [overrideProps, addressData]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        },
    };

    const onOk = useCallback(
        () => {
            const wrapperObj = {
                addressToUse: addressData.addressToUse
            };

            onResolve(wrapperObj);
        }, [addressData, onResolve]
    );


    // By default we will display a modal
    if (modalDisplay) {
        return (
            <ModalNext isOpen={isOpen} className={styles.smallPopUp}>
                <ModalHeader title={title} />
                <ModalBody>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={addressData}
                        overrideProps={overrideProps}
                        onValueChange={writeValue}
                        resolveValue={readValue}
                        classNameMap={resolvers.resolveClassNameMap}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onOk}>
                        {isExactMatchNotFound ? translator(messages.useThisAddress) : translator(commonMessages.continue)}
                    </Button>
                </ModalFooter>
            </ModalNext>
        );
    }

    // Display inline if modalDisplay was passed in as false
    return (

        <ViewModelForm
            uiProps={metadata.pageContent}
            model={addressData}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

AddressStandardizationComponent.propTypes = {
    addressStandardizationResultDTO: PropTypes.shape({
        possibleAddresses: PropTypes.shape({}),
        standardizationChoices: PropTypes.arrayOf(PropTypes.shape({})),
        addressToUse: PropTypes.shape({})
    }).isRequired,
    addressToUse: PropTypes.shape({}),
    title: PropTypes.string,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }),
    modalDisplay: PropTypes.bool,
    selectInlineAddress: PropTypes.func,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    enteredAddress: PropTypes.shape({
        state: PropTypes.string,
        formattedDisplayName: PropTypes.string
    })
};

AddressStandardizationComponent.defaultProps = {
    modalDisplay: true,
    selectInlineAddress: undefined,
    enteredAddress: undefined,
    addressToUse: undefined,
    isOpen: undefined,
    onResolve: undefined,
    viewModelService: undefined,
    title: undefined
};
export default withModalContext(AddressStandardizationComponent);
