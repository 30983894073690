import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    get as _get
} from 'lodash';
import { ehValidationAndInfoMessages } from 'e1p-platform-translations';
import metadata from './InsuredContactComponent.metadata.json5';
import styles from './InsuredContactComponent.module.scss';

/**
 * This component lets a user update and save, home, cell and email
 *   on a transaction. Currently used in payments pages.
 * @param {Object} props value of the named insured on a job
 * @returns {JSX}
 */
function InsuredContactComponent(props) {
    const {
        value: lobDataModel,
        labelPosition,
        showOptional,
        path,
        id,
        onValidate,
        onValueChange,
        visible,
        showErrors,
        viewOnlyMode,
        onSaveClick,
        disabled,
        showPaperless,
        updateIsInsuredComponentValid,
        setIsPaperlessEmailUpdated,
        setIsInsuredContactUpdating
    } = props;
    const translator = useTranslator();
    const policyType = _get(lobDataModel, 'policyType.value.code');

    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [lobDataModel, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    useEffect(() => {
        if (updateIsInsuredComponentValid) {
            updateIsInsuredComponentValid(isComponentValid);
        }
    }, [updateIsInsuredComponentValid, isComponentValid]);

    /**
     * Helper callback for running save and quote when the user clicks on insured contact
     * section fields and entered value is valid and changed.
     */
    const saveAndQuoteIfNecessary = useCallback(async (_, { beforeValue, value: newValue }) => {
        // Only save and Quote if the value is changed and not undefined and if value entered is valid
        updateIsInsuredComponentValid(isComponentValid);

        if (isComponentValid && newValue !== undefined && beforeValue !== newValue) {
            setIsInsuredContactUpdating(true);
            onSaveClick()
                .then(() => {
                    setIsInsuredContactUpdating(false);
                })
                .catch(() => {
                    setIsInsuredContactUpdating(false);
                });
        }
    }, [isComponentValid, onSaveClick, setIsInsuredContactUpdating, updateIsInsuredComponentValid]);

    const updatePaperlessEmail = useCallback(
        (value, changedPath) => {
            setIsPaperlessEmailUpdated(true);

            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path, setIsPaperlessEmailUpdated]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            visible,
            showRequired: true,
            showErrors,
            readOnly: disabled || viewOnlyMode,
            autoComplete: false
        },
        paperlessEmailId: {
            required: _get(lobDataModel, 'paperlessInd.value') === true,
            visible: showPaperless && _get(lobDataModel, 'paperlessInd.value') === true,
            onBlur: saveAndQuoteIfNecessary,
            onValueChange: updatePaperlessEmail
        },
        homeNumberField: {
            tooltip: {
                text: (policyType === 'HO3' || policyType === 'HF9') ? translator(ehValidationAndInfoMessages.phoneNumberRequiredForInteriorInspection) : ''
            },
            onBlur: saveAndQuoteIfNecessary
        },
        mobileNumberField: {
            tooltip: {
                text: (policyType === 'HO3' || policyType === 'HF9') ? translator(ehValidationAndInfoMessages.phoneNumberRequiredForInteriorInspection) : ''
            },
            onBlur: saveAndQuoteIfNecessary
        },
        email: {
            onBlur: saveAndQuoteIfNecessary
        }
    };


    const readValue = useCallback(
        (fieldId, fieldPath) =>
            readViewModelValue(
                metadata.pageContent,
                lobDataModel,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [lobDataModel, overrideProps]
    );

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={lobDataModel}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

InsuredContactComponent.propTypes = {
    value: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    onValidate: PropTypes.func,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    updateIsInsuredComponentValid: PropTypes.func,
    visible: PropTypes.bool,
    showOptional: PropTypes.bool,
    showErrors: PropTypes.bool,
    viewOnlyMode: PropTypes.bool,
    showPaperless: PropTypes.bool,
    onSaveClick: PropTypes.func,
    disabled: PropTypes.bool,
    setIsPaperlessEmailUpdated: PropTypes.func,
    setIsInsuredContactUpdating: PropTypes.func
};
InsuredContactComponent.defaultProps = {
    value: {},
    path: undefined,
    labelPosition: 'top', // I want labels on top by default
    id: undefined,
    showOptional: false,
    showErrors: false,
    viewOnlyMode: false,
    showPaperless: false,
    onSaveClick: undefined,
    disabled: false,
    updateIsInsuredComponentValid: () => { },
    setIsPaperlessEmailUpdated: () => { },
    setIsInsuredContactUpdating: undefined,
    visible: true,
    onValidate: undefined
};
export default InsuredContactComponent;
