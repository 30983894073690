import { defineMessages } from 'react-intl';

export default defineMessages({
    transactionComparisonHeader: {
        id: 'common.component.transactionComparisonModalComponent.Transaction Comparison',
        defaultMessage: 'Transaction Comparison'
    },
    backToPolicyHistoryButton: {
        id: 'common.component.transactionComparisonModalComponent.BACK TO POLICY HISTORY',
        defaultMessage: 'BACK TO POLICY HISTORY'
    },
    quoteChangeItem: {
        id: 'common.component.transactionComparisonModalComponent.Item',
        defaultMessage: 'Item'
    }
});
