import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Icon } from '@jutro/components';
import { Link } from '@jutro/router';
import { IntlContext } from '@jutro/locale';
import { Grid } from '@jutro/layout';
import {
    e1pStringUtil
}
    from 'e1p-capability-hooks';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { documentTypeCodeToNameMap, documentCategoryCodeToNameMap } from './DocumentsAttributesMap';
import metadata from './DocumentsComponent.metadata.json5';
// import messages from './DocumentsComponent.messages';
import styles from './DocumentsComponent.module.scss';


const DocumentsComponent = (props) => {
    const {
        initialDocumentsData,
        uploadDocument,
        showPagination,
        showHeader,
        authUserData,
        jobNumber,
        policyNumber,
        handleRefreshDocuments
    } = props;
    const [showDocumentUploadButton, updateShowDocumentUploadButton] = useState(false);
    const [dataDocuments, setDataDocuments] = useState([]);
    // state added for iap-3491 to show policypacket as a parent of all the child documents
    const [policyPacketDocuments, setPolicyPacketDocuments] = useState([]);
    const [typeFilterValue, setTypeFilterValue] = useState([]);
    const [documentTypeReason, setDocumentTypeReason] = useState(undefined);
    const [categoryFilterValue, setCategoryFilterValue] = useState([]);
    const [categoryTypeReason, setCategoryTypeReason] = useState(undefined);
    const [transactionFilterValue, setTransactionFilterValue] = useState([]);
    const [transactionTypeReason, setTransactionTypeReason] = useState(undefined);
    const [deliveryFilterValue, setDeliveryFilterValue] = useState([]);
    const [deliveryTypeReason, setDeliveryTypeReason] = useState(undefined);
    const [workOrderValue, setWorkOrderValue] = useState(undefined);
    const [agencyNameValue, setAgencyNameValue] = useState(undefined);
    const [startDateValue, setStartDateValue] = useState(undefined);
    const [endDateValue, setEndDateValue] = useState(undefined);
    const [documentTableConfig, setDocumentTableConfig] = useState({
        "pageSize": 20,
        "sorted": [
          {
            "id": "dateModified",
            "desc": true
          }
        ]
      });
    const intl = useContext(IntlContext);
    const getLongDateandShortTime = (dateModified) => {
        let modifiedDate;

        // IAP-5760, no need to convert to UTC, since date fields is string only, not timestamp.
        if (dateModified) {
            modifiedDate = `${intl.formatDate(new Date(dateModified.year, dateModified.month, dateModified.day), { year: 'numeric', month: 'short', day: 'numeric' })}`;
        }

        return modifiedDate;
    };

    const parseEffectiveDate = (date) => {
        let modifiedDate;

        if (date && date.length === 8) {
            const year = date.substring(0, 4);
            const month = date.substring(4, 6);
            const day = date.substring(6, 8);

            // if we get 20210921 we want the date to display 9/21/2021
            // there is an off by one error on month
            // IAP-5760, no need to convert to UTC, since date fields is string only, not timestamp.
            modifiedDate = `${intl.formatDate(new Date(year, month - 1, day), { year: 'numeric', month: 'short', day: 'numeric' })}`;
        }

        return modifiedDate;
    };

    const checkIfDateIsInRange = (dateToCheck, sDate, eDate) => {
        const startDate = new Date(sDate.year, sDate.month, sDate.day);
        const endDate = new Date(eDate.year, eDate.month, eDate.day);

        if (dateToCheck && dateToCheck.length === 8) {
            const year = dateToCheck.substring(0, 4);
            const month = dateToCheck.substring(4, 6);
            const day = dateToCheck.substring(6, 8);
            // if we get 20210921 we want the date to display 9/21/2021
            // there is an off by one error on month
            const modifiedDate = new Date(year, month - 1, day);

            if (startDate <= modifiedDate && modifiedDate <= endDate) {
                return true;
            }

            return false;
        }

        return false;
    };
    const canCreateDoc = authUserData?.permissions_Ext.includes('doccreate');

    const displayDocuments = (documentsData) => {
        if (!_.isEmpty(documentsData)) {
            const documents = documentsData.map((documentsInfo) => {
                const documentDateModified = getLongDateandShortTime(documentsInfo.dateModified);
                const documentDateEffective = parseEffectiveDate(documentsInfo.dateEffective);
                const documentType = documentTypeCodeToNameMap.get(documentsInfo.documentType) ?? documentsInfo.documentType;
                const documentCategory = documentCategoryCodeToNameMap.get(documentsInfo.documentCategory) ?? documentsInfo.documentCategory;

                return {
                    documentTitle: documentsInfo.documentTitle,
                    dateModified: documentDateModified,
                    dateEffective: documentDateEffective,
                    documentURL: documentsInfo.documentURL,
                    documentType,
                    transactionType: e1pStringUtil
                        .toUppercaseFirstChar(documentsInfo.transactionType),
                    description: documentsInfo.description,
                    documentCategory,
                    deliveryMethod: e1pStringUtil.toUppercaseFirstChar(documentsInfo.deliveryMethod),
                    jobNumber: documentsInfo.jobNumber
                };
            });
            const docsWithoutPolicyPacketDocs = documents.filter((document) => document.documentCategory !== 'Policy Packet Document');
            const onlyPolicyPacketDocs = documents.filter((document) => document.documentCategory === 'Policy Packet Document');

            setPolicyPacketDocuments(onlyPolicyPacketDocs)
            setDataDocuments(docsWithoutPolicyPacketDocs);

            return true;
        }

        setDataDocuments([]);

        return false;
    };

    // iap-3491 When refine criteria is added or cleared; we need to collpase
    const collapseExpanders = () => {
        // click is done on the div above the svg
        const expandableCells = document.querySelectorAll('.rt-expandable');

        for(let nodeIndex = 0; nodeIndex < expandableCells.length; nodeIndex++) {
            // aria-expanded is a property on the svg
            if (expandableCells[nodeIndex].firstChild.ariaExpanded === 'true') {
                expandableCells[nodeIndex].click();
            }
        }
    }

    const getFilteredData = useCallback(() => {
        // expanded policypackets need to be collapsed before rendering a new set of data
        collapseExpanders();

        const newData = initialDocumentsData.filter((document) => (_.isUndefined(documentTypeReason) ? !documentTypeReason : document.documentType === documentTypeReason)
        && (_.isUndefined(workOrderValue) ? !workOrderValue : document.jobNumber === workOrderValue)
        && (_.isUndefined(categoryTypeReason) ? !categoryTypeReason : document.documentCategory === categoryTypeReason)
        && (_.isUndefined(transactionTypeReason) ? !transactionTypeReason : document.transactionType === transactionTypeReason)
        && (_.isUndefined(deliveryTypeReason) ? !deliveryTypeReason : document.deliveryMethod === deliveryTypeReason)
        && (_.isUndefined(agencyNameValue) ? !agencyNameValue : document.documentTitle?.toLowerCase().includes(agencyNameValue?.trim().toLowerCase()))
        && ((_.isUndefined(startDateValue) || _.isUndefined(endDateValue)) ? true
            : checkIfDateIsInRange(document.dateEffective, startDateValue, endDateValue)));
        // Check if document is a policypacketdocument; show the policypacket with it, if so
        const policyPacketDocs = newData.filter((document) => document.documentCategory === 'policypacketdocument');

        policyPacketDocs.forEach((ppDoc) => {
            let parentPacket = newData.find((doc) => doc.documentCategory === 'policypacket' && doc.jobNumber === ppDoc.jobNumber);

            // if not in the new data; add it so the tree shows in search
            if (!parentPacket) {
                parentPacket = initialDocumentsData.find((doc) => doc.documentCategory === 'policypacket' && doc.jobNumber === ppDoc.jobNumber);
                newData.push(parentPacket);
            }
        })
        displayDocuments(newData);

        return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workOrderValue, initialDocumentsData, documentTypeReason, categoryTypeReason, transactionTypeReason, deliveryTypeReason, agencyNameValue, startDateValue, endDateValue]);


    useEffect(() => {
        if (showHeader) {
            // upload button will only be visible when policyNumber is available.
            updateShowDocumentUploadButton(canCreateDoc && !!policyNumber);
        }

        displayDocuments(initialDocumentsData);
        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!(_.isEmpty(jobNumber))) {
            getFilteredData();
        }
        // using only workOrderValue in dependency to call getFilteredData
        // whenever workorder value get changed by user.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workOrderValue]);

    useEffect(() => {
        setTypeFilterValue(_.uniqBy(initialDocumentsData, 'documentType').map((item) => ({
                code: item.documentType,
                name: documentTypeCodeToNameMap.get(item.documentType) ?? item.documentType
            })));
        setCategoryFilterValue(_.uniqBy(initialDocumentsData, 'documentCategory').map((item) => ({
                code: item.documentCategory,
                name: documentCategoryCodeToNameMap.get(item.documentCategory) ?? item.documentCategory
            })));
        setTransactionFilterValue(_.uniqBy(initialDocumentsData, 'transactionType').map((item) => ({
                code: item.transactionType,
                name: e1pStringUtil.toUppercaseFirstChar(item.transactionType)
            })));
        setDeliveryFilterValue(_.uniqBy(initialDocumentsData, 'deliveryMethod').map((item) => ({
                code: item.deliveryMethod,
                name: e1pStringUtil.toUppercaseFirstChar(item.deliveryMethod)
            })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openDocumentViewer = (event, documentUrl) => {
        event.preventDefault();
        // Open nuxeo in new tab
        window.open(documentUrl, '_blank');
    };

    const getDateModified = (items, index, { path: property }) => <span>{items[property]}</span>;

    const getNameLink = (item, index, { path: property }) => (
            <div>
                <Link
                    href={item[property]}
                    className={styles.documentName}
                    onClick={(e) => openDocumentViewer(e, item.documentURL)}
                >
                    <Icon 
                        icon={item.documentCategory === 'Policy Packet' ? 'mi-folder': "mi-file-copy"}
                        className={styles.fileIcon} />
                    {item[property]}
                </Link>
            </div>

        );


    // iap-3491 renders policypacketdocuments in a table-like grid, underneath the associatied policypacket
    const onRenderExpanderContent = useCallback((data) => {
        const transactionsPolicyPacketDocs = policyPacketDocuments.filter((ppDoc) => ppDoc.jobNumber === data.jobNumber);
        const gridItems = [];

        // Map will not work because it requires each child to be inside a parent
        // Each child needs to be in a flat array for the grid to render correctly
        transactionsPolicyPacketDocs.forEach((doc, index) => {
            gridItems.push(
                <div className={styles.expandableCellPadding} key={`pp-doc-url-${index}`}>
                    <Link
                        href={doc.documentURL}
                        className={styles.documentName}
                        onClick={(e) => openDocumentViewer(e, doc.documentURL)}
                    >
                        <Icon icon="mi-file-copy" className={styles.fileIcon} />
                        {doc.documentTitle}
                    </Link>
                </div>
            );
            gridItems.push(<div className={styles.expandableCellPadding}>{ doc.description }</div>);
            gridItems.push(<div className={styles.expandableCellPadding}>{ doc.documentType }</div>);
            gridItems.push(<div className={styles.expandableCellPadding}>{ doc.documentCategory }</div>);
            gridItems.push(<div className={styles.expandableCellPadding}>{ doc.transactionType }</div>);
            gridItems.push(<div className={styles.expandableCellPadding}>{ doc.dateEffective }</div>);
            gridItems.push(<div className={styles.expandableCellPadding}>{ doc.dateModified }</div>);
            gridItems.push(<div className={styles.expandableCellPadding}>{ doc.deliveryMethod }</div>);
            gridItems.push(<div className={styles.expandableCellPadding}>{ doc.jobNumber }</div>);
        });

        return (
            <Grid
                // key={`pp-doc-${index}`}
                columns={['2fr', '2fr', '1fr', '1fr', '1fr', '1fr', '1fr', '1fr', '1fr']}
                gap="none"
                justifyContent="right"
                valignContent="middle"
                valignItems="middle"
                // className={styles.activityGrid}
            >
                {gridItems}
            </Grid>
        );
    }, [policyPacketDocuments]);

    useEffect(() => {
        // iap-3491 We need to make sure only policypacket shows the expandable
        const docNodeList = document.querySelectorAll('.jut__DataTable__tableRowGroup');

        for(let nodeIndex = 0; nodeIndex < docNodeList.length; nodeIndex++) {
            // newline is needed to differentiate between policypacketdocument
            if (docNodeList[nodeIndex].innerText.indexOf('Policy Packet\n') === -1) {
                docNodeList[nodeIndex].firstChild.firstChild.style.visibility = 'hidden';
            } else {
                // this has to be done for refine search
                docNodeList[nodeIndex].firstChild.firstChild.style.visibility = 'visible';
            }
        }
    }, [dataDocuments, documentTableConfig]);

    const onUploadDocument = useCallback((file) => {
        uploadDocument(file);
    }, [uploadDocument]);

    const clearFilterData = async () => {
        await collapseExpanders();
        setDocumentTypeReason(undefined);
        setCategoryTypeReason(undefined);
        setTransactionTypeReason(undefined);
        setDeliveryTypeReason(undefined);
        setWorkOrderValue(undefined);
        setAgencyNameValue(undefined);
        setStartDateValue(undefined);
        setEndDateValue(undefined);
        displayDocuments(initialDocumentsData);
    };

    const writeValue = (
        (id, val) => {
            if (id === 'documentType') {
                setDocumentTypeReason(val);
            } else if (id === 'categoryType') {
                setCategoryTypeReason(val);
            } else if (id === 'transactionType') {
                setTransactionTypeReason(val);
            } else if (id === 'deliveryType') {
                setDeliveryTypeReason(val);
            } else if (id === 'workOrder') {
                setWorkOrderValue(val);
            } else if (id === 'agencyName') {
                setAgencyNameValue(val);
            } else if (id === 'fromStartDate') {
                setStartDateValue(val);
            } else if (id === 'toEndDate') {
                setEndDateValue(val);
            }
        });

    const shouldDocumentsBeShown = initialDocumentsData.length > 0;
    const overrideProps = {
        documentsSectionID: {
            visible: shouldDocumentsBeShown,
        },
        documentTableGrid: {
            data: dataDocuments,
            showPagination,
            config: documentTableConfig,
            renderExpanderContent: onRenderExpanderContent,
            onConfigChange: (config) => {
                setDocumentTableConfig(config);
            }
        },
        uploadDocumentsId: {
            visible: showDocumentUploadButton
        },
        documentsTitleId: {
            visible: showHeader
        },
        type: {
            value: documentTypeReason,
            availableValues: typeFilterValue,
            onValueChange: (val) => { writeValue('documentType', val); },
        },
        category: {
            value: categoryTypeReason,
            availableValues: categoryFilterValue,
            onValueChange: (val) => { writeValue('categoryType', val); },
        },
        transaction: {
            value: transactionTypeReason,
            availableValues: transactionFilterValue,
            onValueChange: (val) => { writeValue('transactionType', val); },
        },
        deliveryType: {
            value: deliveryTypeReason,
            availableValues: deliveryFilterValue,
            onValueChange: (val) => { writeValue('deliveryType', val); },
        },
        workOrder: {
            value: workOrderValue,
            onValueChange: (val) => { writeValue('workOrder', val); }
        },
        agencyName: {
            value: agencyNameValue,
            onValueChange: (val) => { writeValue('agencyName', val); }
        },
        fromStartDate: {
            updateDateDto: (val) => { writeValue('fromStartDate', val); },
            dateDTO: startDateValue,
            labelPosition: 'top'
        },
        toEndDate: {
            updateDateDto: (val) => { writeValue('toEndDate', val); },
            dateDTO: endDateValue,
            labelPosition: 'top'
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getNameLink,
            getDateModified,
            onUploadDocument,
            onRefreshDocuments: handleRefreshDocuments,
            clearFilterData,
            getFilteredData,
            sortDate: DatatableUtil.sortDate
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

DocumentsComponent.propTypes = {
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }),
    initialDocumentsData: PropTypes.arrayOf(PropTypes.shape({})),
    noDataMessage: PropTypes.string,
    showPagination: PropTypes.bool,
    uploadDocument: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
    authUserData: PropTypes.shape({
        userType: PropTypes.string
    }),
    handleRefreshDocuments: PropTypes.func.isRequired,
    jobNumber: PropTypes.string,
    policyNumber: PropTypes.string
};

DocumentsComponent.defaultProps = {
    showPagination: true,
    noDataMessage: '',
    showHeader: true,
    jobNumber: undefined,
    policyNumber: undefined,
    authHeader: undefined,
    authUserData: undefined
};

export default withAuthenticationContext(DocumentsComponent);
