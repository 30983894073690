import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import PropTypes from 'prop-types';
import styles from './DefensiveDriverComponent.module.scss';
import metadata from './DefensiveDriverComponent.metadata.json5';
import messages from './DefensiveDriverComponent.messages';

import { Button } from '@jutro/legacy/components';

// This variable is used to check the policy state condition and
// Makes type of course taken feild defaulted to Defensive driver and readonly
const courseTakenDefaultStates = ['SC', 'VA', 'RI', 'CT', 'ME'];
/**
 * IAP-3265, IAP-2593
 * moving voilation fields will not be visible for below mentioned state
 */
const movingViolationFieldNotAvailableForStates = ['RI', 'CT'];

function DefensiveDriverComponent(props) {
    const {
        driverVM,
        title,
        viewModelService,
        isOpen,
        onResolve,
        onReject,
        viewOnlyMode,
        policyState
    } = props;

    const translator = useTranslator();
    const { isComponentValid, onValidate } = useValidation(
        'DefensiveDriverComponent'
    );
    const [driverData, updateDriverData] = useState(viewModelService.clone(driverVM));
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = viewModelService.clone(driverData);

            _.set(nextFormData, path, value);
            updateDriverData(nextFormData);
        },
        [driverData, viewModelService]
    );


    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [driverVM, isComponentValid, isPageSubmitted]);

    useEffect(() => {
        const courseTaken = _.get(driverData, 'value.defenseDrvCourseType');

        if (courseTakenDefaultStates.includes(policyState) && (!courseTaken || _.isUndefined(courseTaken))) {
            // default defenseDrvCourseType value to DefensiveDriver
            writeValue('DefensiveDriver', 'defenseDrvCourseType');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'top',
            disabled: viewOnlyMode,
            showRequired: true,
            autoComplete: false,
            showErrors: isPageSubmitted
        },
        completeMissingFieldMessageDiv: {
            visible: isPageSubmitted && !isComponentValid
        },
        typeOfCourseTaken: {
            readOnly: courseTakenDefaultStates.includes(policyState)
        },
        dateOfCompletion: {
            value: _.get(driverData, 'defenseDrvCourseDate.value', '')
        },
        takeAsResultOfMovingViolation: {
            label: policyState === 'SC'
                ? translator(messages.DefensiveDriverMovingViolationWithAtFault)
                : translator(messages.DefensiveDriverMovingViolation),
            // this field is not visible for some states
            visible: !movingViolationFieldNotAvailableForStates.includes(policyState),
            required: !movingViolationFieldNotAvailableForStates.includes(policyState)
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                driverData,
                id,
                path,
                overrideProps
            ),
        [overrideProps, driverData]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        },
    };

    const onOk = useCallback(
        () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);

                return false;
            }

            const wrapperObj = {
                driverVM: driverData
            };

            return onResolve(wrapperObj);
        }, [isComponentValid, driverData, onResolve]
    );

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onOk}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <ModalHeader title={title} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={driverData}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    {translator(e1pCommonMessages.cancel)}
                </Button>
                <Button onClick={onOk}>
                    {translator(e1pCommonMessages.save).toUpperCase()}
                </Button>
            </ModalFooter>
        </ModalNext>

    );
}

DefensiveDriverComponent.propTypes = {
    driverVM: PropTypes.shape({
        possibleAddresses: PropTypes.shape({}),
        standardizationChoices: PropTypes.shape({})
    }).isRequired,
    title: PropTypes.string.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func
    }).isRequired,
    onReject: PropTypes.func,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    viewOnlyMode: PropTypes.bool,
    policyState: PropTypes.string.isRequired
};

DefensiveDriverComponent.defaultProps = {
    viewOnlyMode: false,
    isOpen: false,
    onResolve: undefined,
    onReject: undefined
};

export default DefensiveDriverComponent;
