import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import htmlParser from 'html-react-parser';
import moment from 'moment';
import {
    set as _set,
    filter as _filter,
    includes as _includes
} from 'lodash';
import { Grid } from '@jutro/layout';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import {
    withViewModelService,
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import metadata from './E1PNotesDataTable.metadata.json5';
import messages from './E1PNotesDataTable.messages';
import styles from './E1PNotesDataTable.module.scss';
import { IconButton } from '@jutro/legacy/components';

const E1PNotesDataTable = (props) => {
    const {
        initialNotesData,
        policyNumber,
        editNote,
        deleteNote,
        authUserData,
        expandAllNotes
    } = props;
    const translator = useTranslator();
    const [notesData, updateNotesData] = useState(initialNotesData);
    // state varible to track the state of dataTable
    const [checkExpandAllOption, updateCheckExpandAllOption] = useState({});

    const isInternalUser = authUserData?.permissions_Ext.includes('createintnote') || authUserData?.permissions_Ext.includes('delintnote');
    const isExternalUser = !isInternalUser;

    useEffect(() => {
        updateNotesData(initialNotesData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        // call expandAllNotes on dataTable state change
        if(expandAllNotes) {
            expandAllNotes();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[checkExpandAllOption]);

    const renderExpanderContent = useCallback((data) => (
            <div className={styles.noteRow}>
                <Grid
                    columns={['0.15fr', '1.25fr']}
                    gap="none"
                    justifyContent="center"
                    valignContent="middle"
                    valignItems="middle"
                >
                    <span className={styles.noteField}>{translator(messages.RelatedTo)}</span>
                    <span className={styles.noteFieldValue}>{policyNumber || 'Unassigned'}</span>
                    {
                        (isInternalUser) ? (
                            <React.Fragment>
                                <span className={styles.noteField}>{translator(messages.SecurityLevel)}</span>
                                <span className={styles.noteFieldValue}>
                                    {translator(
                                        {
                                            id: `typekey.NoteSecurityType.${data.securityLevel_Ext}`,
                                            defaultMessage: `typekey.NoteSecurityType.${data.securityLevel_Ext}`
                                        }
                                    )
                                    }
                                </span>
                            </React.Fragment>
                        ) : <span />
                    }
                </Grid>
                <Grid
                    columns={['0.15fr', '1.25fr']}
                    rows={['1fr']}
                    gap="none"
                    justifyContent="center"
                    valignContent="middle"
                    valignItems="middle"
                >
                    <span className={styles.noteField}>{translator(messages.Note)}</span>
                    <span className={styles.noteFieldValue}>{htmlParser(data.body)}</span>
                </Grid>
            </div>
        ), [isInternalUser, policyNumber, translator]);

    const getFormattedValue = (item, index, property) => {
        let formattedValue = '';

        switch (property.path) {
            case 'topic':
                formattedValue = translator(
                    {
                        id: `typekey.NoteTopicType.${item[property.path]}`,
                        defaultMessage: `typekey.NoteTopicType.${item[property.path]}`
                    }
                );
                break;
            case 'createdDate':
                formattedValue = moment(item[property.path]).format('MM/DD/YYYY - h:mm a');
                break;
            default:
                return item[property.path];
        }

        return formattedValue;
    };

    const filterDate = useCallback((filterValue, { path }) => (row) => {
            const formattedValue = moment(row[path]).format('MM/DD/YYYY - h:mm a');

            return _includes(formattedValue, filterValue);
        }, []);

    const handleEditRowClick = (data) => {
        editNote(data);
    };

    const handleDeleteRowClick = (data) => {
        deleteNote(data);
    };

    const allowUpdateRow = useCallback((rowData) => {
        const isSameUser = rowData.authorName === authUserData.displayName;

        return (isSameUser && (isInternalUser || isExternalUser));
    }, [authUserData.displayName, isExternalUser, isInternalUser]);

    const getActionsForRow = (item, index) => (
            <div>
                {
                    ((allowUpdateRow(item) || authUserData.permissions_Ext.includes('noteeditadmin_ext'))
                     && (authUserData.permissions_Ext.includes('noteedit')))
                        ? (
                            <IconButton
                                icon="mi-edit"
                                iconColor="neutral"
                                id={`IconEditButton${index}`}
                                size="medium"
                                className={styles.iconClass}
                                onClick={() => handleEditRowClick(item)}
                            />
                        ) : <div />
                }
                {
                    ((allowUpdateRow(item) || authUserData.permissions_Ext.includes('notedeleteadmin_ext'))
                    && (authUserData.permissions_Ext.includes('notedelete')))
                        ? (
                            <IconButton
                                icon="mi-delete"
                                iconColor="neutral"
                                id={`IconDeleteButton${index}`}
                                size="medium"
                                className={styles.iconClass}
                                onClick={() => handleDeleteRowClick(item)}
                            />
                        ) : <div />
                }
            </div>
        );
    const getNotesDataForDisplay = useCallback(() => {
        if (isExternalUser) {
            const filterdRowData = _filter(notesData, (row) => row.securityLevel_Ext === 'unrestricted');

            return filterdRowData;
        }

        return notesData;
    }, [isExternalUser, notesData]);

    /**
     * helper method to handle the expand/collapse all notes, whenever data table is changed,
     * like changing page number, size, sorting or filter
     */
    const handleConfigChange = useCallback((config) => {
        updateCheckExpandAllOption(config);
    }, []);

    const overrideProps = {
        notesTableGrid: {
            data: getNotesDataForDisplay(),
            renderExpanderContent,
            noDataText: translator(messages.noPolicyNotes),
            onConfigChange: handleConfigChange
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getFormattedValue,
            getActionsForRow,
            sortDate: DatatableUtil.sortDate,
            onRowClick: (data) => {
                _set(data, 'expanded', !data.expanded);
            },
            filterDate
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

E1PNotesDataTable.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired,
    initialNotesData: PropTypes.arrayOf(
        PropTypes.shape({
            noteTopic: PropTypes.string,
            noteSubject: PropTypes.string,
            body: PropTypes.string,
            createdDate: PropTypes.string,
            author: PropTypes.string
        })
    ).isRequired,
    editNote: PropTypes.func.isRequired,
    deleteNote: PropTypes.func.isRequired,
    authUserData: PropTypes.shape({
        displayName: PropTypes.string,
        permissions_Ext: PropTypes.arrayOf(
            PropTypes.string
        )
    }).isRequired,
    policyNumber: PropTypes.string,
    expandAllNotes: PropTypes.func
};

E1PNotesDataTable.defaultProps = {
    policyNumber: 'Unassigned',
    expandAllNotes: () => {}
};

export default withAuthenticationContext(withViewModelService(E1PNotesDataTable));
