import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { InlineNotification, useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { get } from 'lodash';
import { HistoryTableComponent } from 'e1p-capability-policyjob-react';
import { validationAndInfoMessages } from 'e1p-platform-translations';
import { TimelineService } from "e1p-capability-journey";
import messages from './HistoryComponent.messages';
import styles from './HistoryComponent.module.scss';
import TransactionComparisonModalComponent from '../TransactionComparisonModalComponent/TransactionComparisonModalComponent';

import { Button } from '@jutro/legacy/components';

const productMap = {
    PersonalAuto_EA: 'personalAuto_EA',
    Homeowners_EH: 'homeowners_EH',
    PersonalUmbrella_EU: 'personalUmbrella_EU'
};

function HistoryComponent(props) {
    const modalApi = useModal();
    const {
        authHeader,
        fromAccountLanding: {
            policyNumber
        },
        policyDetailsData: {
            policyResponse
        },
        redirectToAnotherTile
    } = props;
    const translator = useTranslator();

    const [eventsData, setEventsData] = useState([]);
    const [policyTypeData, setPolicyTypeData] = useState({});
    const [selectedHistoryJobIdTypes, setSelectedHistoryJobIdTypes] = useState([]);
    const [showComparisonError, setShowComparisonError] = useState(false);

    const getHistoryRecordData = async () => {
        const responseData = await TimelineService.getTimeline(
            {
                policyNumber,
                relatedItem: 'Policy'
            },
            authHeader
        );
        /**
         * E1PAP1PC-14654 :
         * Changing Display name for System User to System
         */
        const policyTimeLine = responseData?.events?.map((record) => ({ ...record, user: record.user === 'System User' ? 'System' : record.user }));
        const { productCode } = get(policyResponse, 'product');

        setEventsData(policyTimeLine);
        setPolicyTypeData(get(policyResponse, ['lobs', productMap[productCode], 'policyType_Ext'], {}));
    };

    useEffect(() => {
        getHistoryRecordData();
        // On mount load history records
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateSelectedHistoryJobIdTypes = useCallback((selectedJobIdTypes) => {
        setSelectedHistoryJobIdTypes(selectedJobIdTypes);

        // Remove error from screen if user selects exact two items
        if (showComparisonError && selectedJobIdTypes.length === 2) {
            setShowComparisonError(false);
        }
    }, [showComparisonError]);

    const onCompare = useCallback(async () => {
        if (selectedHistoryJobIdTypes.length === 2) {
            const componentProps = {
                authHeader,
                selectedJobIdTypes: selectedHistoryJobIdTypes
            };

            await modalApi.showModal(<TransactionComparisonModalComponent {...componentProps} />);
        } else {
            setShowComparisonError(true);
        }
    }, [authHeader, modalApi, selectedHistoryJobIdTypes]);

    return (
        <React.Fragment>
            {
                showComparisonError
                && <InlineNotification type="error" message={validationAndInfoMessages.cannotCompare} />
            }
            <div className={styles.pageHeader}>
                <Button
                    type="outlined"
                    onClick={onCompare}
                    className={styles.compareButton}
                >
                    {translator(messages.compare)}
                </Button>
                <h2>
                    {translator(messages.historyTitle)}
                </h2>
            </div>
            <HistoryTableComponent
                events={eventsData}
                policyType={policyTypeData}
                updateSelectedHistoryJobIdTypes={updateSelectedHistoryJobIdTypes}
                redirectToAnotherTile={redirectToAnotherTile}
                authHeader={authHeader}
            />
        </React.Fragment>
    );
}

HistoryComponent.propTypes = {
    fromAccountLanding: PropTypes.shape({
        policyNumber: PropTypes.string
    }).isRequired,
    policyDetailsData: PropTypes.shape({
        policyResponse: PropTypes.shape({})
    }).isRequired,
    authHeader: PropTypes.shape({}),
    redirectToAnotherTile: PropTypes.func.isRequired
};

HistoryComponent.defaultProps = {
    authHeader: undefined
};

export default withAuthenticationContext(HistoryComponent);
