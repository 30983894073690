import React, {
    useEffect, useContext, useState, useCallback, useMemo
} from 'react';
import { set as _set, get as _get } from 'lodash';
import { ViewModelForm, withViewModelService, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage } from 'e1p-portals-wizard-react';
import { e1pDateUtil } from 'e1p-capability-hooks';
import { useHistory } from 'react-router-dom';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { PolicyService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { RandomNumberUtil } from 'e1p-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import appConfig from 'app-config';
import metadata from './StartRewritePage.metadata.json5';
import messages from './StartRewritePage.messages';


function StartRewritePageEA(props) {
    const modalApi = useModal();
    const {
        updateWizardData, wizardData, authHeader, authUserData, steps, changeNextSteps
    } = props;
    const [rewriteDataVM, setRewriteDataVM] = useState(undefined);
    const [policyGraph, setPolicyGraph] = useState(undefined);
    const viewModelService = useContext(ViewModelServiceContext);
    const history = useHistory();
    const [rewriteDTO, setRewriteDTO] = useState(undefined);
    const [policyGraphFired, setPolicyGraphFired] = useState(false);
    const [expirationDate, setExpirationDate] = useState(undefined);
    const [rewriteReasonList, SetrewriteReasonList] = useState(undefined);
    const [ICSWarning, setICSWarning] = useState(false);
    const [policyFlatCancelled, setFlatCancelled] = useState(false);
    const [oneYearWarning, setOneYearWarning] = useState(false);
    const [loader, setLoader] = useState(true);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const sessionIdPrefixEdit = 'EA - RW - ';
    const sessionHeader = sessionIdPrefixEdit + RandomNumberUtil.randomFixedInteger(10);
    const {
        isComponentValid,
        registerComponentValidation,
        onValidate
    } = useValidation('StartRewritePageEA');

    // Remove assignment page if the state is enabled
    const policyState = _get(rewriteDataVM, 'baseData.policyAddress.state.value.code');

    useEffect(() => {
        // check for enabled state
        const enabledStates = appConfig.driverAssignmentStates;
        const nextSteps = [...steps];
        const driverAssignmentStepIndex = nextSteps.findIndex((step) => step.path === '/assignment');

        // not needed for state and does not exist in steps already
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex < 0) {
            return;
        }

        // not needed for the state, but previous state selected needed it
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.splice(driverAssignmentStepIndex, 1); // remove
            nextSteps.shift(); // remove current page (always first elt)
            changeNextSteps(nextSteps);

            return;
        }

        // needed for the state, and already has it
        if (enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            return;
        }

        // needed for the state and not already in the steps
        const driverAssignmentPage = {
            id: 'RWEADriverAssignmentPage',
            title: {
                id: 'quoteandbind.ea.wizard.step.Drivers Assignment',
                defaultMessage: 'Driver Assignment'
            },
            path: '/assignment',
            component: 'E1PRewriteEADriverAssignmentPage',
            stepProps: {
                template: 'WizardPageTemplateWithTitle'
            }
        };
        const indexOfVehiclePage = nextSteps.findIndex((step) => step.path === '/risk-analysis');

        // insert driver assignment
        nextSteps.splice(indexOfVehiclePage, 0, driverAssignmentPage)
        // remove current page (always first elt)
        nextSteps.shift();
        changeNextSteps(nextSteps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyState]);

    const translator = useTranslator();

    const handlePeriodStartDateChange = useCallback(
        (periodStartDate) => {
            _set(rewriteDTO, 'effectiveDate.value', periodStartDate);
            updateWizardData(rewriteDTO);
        },
        [rewriteDTO, updateWizardData]
    );

    // React Hook useMemo has a complex expression in the dependency array.
    //   Extract it to a separate variable so it can be statically checked.
    const rewriteReason = rewriteDTO?.rewriteReason_Ext?.value?.code;

    const isValid = useCallback(() => {
        if (ICSWarning) {
            return false;
        } // cant start a rewrite

        return true;
    }, [ICSWarning]);

    useEffect(() => {
        registerComponentValidation(isValid);
    }, [isValid, registerComponentValidation]);

    const shouldBlock = useMemo(() => {
        // agent doesnot have createRewrite permission, we dont want to show ICS warning to internal users
        const isAgent = !authUserData?.permissions_Ext.includes('createrewrite');
        const effectiveDateRep = rewriteDTO?.value?.effectiveDate;
        const effectiveDatePolicy = new Date(
            effectiveDateRep?.year,
            effectiveDateRep?.month,
            effectiveDateRep?.day
        );
        const cancelDate = expirationDate;
        const greaterThanThirty = e1pDateUtil.differenceInDays(
            cancelDate,
            effectiveDatePolicy
        ) > 30;

        if (isAgent === true && greaterThanThirty) {
            setICSWarning(true);

            return true;
        }

        setICSWarning(false);

        return false;
    }, [authUserData, expirationDate, rewriteDTO]);

    useEffect(() => {
        setLoader(true);

        const { policyNumber } = wizardData;

        setRewriteDataVM(wizardData);

        const rewrite = viewModelService.create(
            {},
            'pc',
            'edge.capabilities.gateway.job.rewrite.dto.RewriteDTO'
        );
        // To get the list of available rewriteReasons and alter the message for breakincoverage
        const reasonsList = rewrite.rewriteReason_Ext
            .aspects.availableValues.map((item) => {
                if (item.code === 'breakincoverage') {
                    return {
                        code: item.code,
                        name: translator({
                            id: 'breakincoverage',
                            defaultMessage: 'Break in coverage less than 60 days'
                        })
                    };
                }

                return {
                    code: item.code,
                    name: translator({ id: item.name })
                };
            });

        SetrewriteReasonList(reasonsList);
        rewrite.policyNumber = policyNumber;
        rewrite.effectiveDate = viewModelService.create(
            {},
            'pc',
            'edge.time.LocalDateDTO'
        );
        authHeader['afe-session-id'] = sessionHeader;

        const retrievePolicy = async () => {
            const policyResult = await PolicyService.getPolicy(policyNumber.value, authHeader);

            return policyResult;
        };

        if (!wizardData?.jobID?.value) {
            retrievePolicy().then((result) => {
                setPolicyGraph(result);
            });
        }

        setRewriteDTO(rewrite);
        setLoader(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (policyGraph !== undefined && policyGraphFired === false && !rewriteDataVM.jobID.value) {
            setLoader(true);

            const newDate = new Date(`${policyGraph?.latestPeriod?.cancellationDate} 00:00`);
            const newDateFull = {
                day: newDate.getDate(),
                month: newDate.getMonth(),
                year: newDate.getFullYear()
            };
            const policyEffectiveDate = new Date(`${policyGraph?.latestPeriod?.effectiveDate} 00:00`);
            const flatCancelCheck = e1pDateUtil.differenceInDays(newDate, policyEffectiveDate);

            if (flatCancelCheck === 0) {
                setFlatCancelled(true);
            } else {
                setFlatCancelled(false);
            }

            rewriteDTO.effectiveDate.value = newDateFull;
            setPolicyGraphFired(true);
            setExpirationDate(newDate);
            updateWizardData(rewriteDataVM);
            setLoader(false);
        }
    }, [policyGraph, policyGraphFired, rewriteDTO, rewriteDataVM, updateWizardData]);

    const onNext = async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setLoader(true);

        if (!rewriteDataVM.jobID.value) {
            const rewritePolicyNumber = rewriteDTO.policyNumber.value;
            const effectiveDate = rewriteDTO.effectiveDate.value;

            authHeader['afe-session-id'] = sessionHeader;

            const response = await RewriteService.createPolicyRewriteTransactionWithLocalDate(
                [rewritePolicyNumber, effectiveDate, rewriteReason], authHeader
            );

            if (
                response?.baseData?.exceptions_Ext
                && response?.baseData?.exceptions_Ext[0]?.errorMessage
            ) {
                modalApi.showAlert({
                    title: messages.error,
                    message: `Cannot start Rewrite: ${response?.baseData?.exceptions_Ext[0]?.errorMessage}`,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.close
                }).then(() => {
                    const { policyNumber } = rewriteDataVM.value;

                    history.push(`/policies/${policyNumber}/summary`);
                }).catch(() => { });

                return false;
            }

            rewriteDataVM.value = response;
        }

        setLoader(false);
        authHeader['afe-session-id'] = sessionIdPrefixEdit + _get(rewriteDataVM, 'jobID.value');

        return rewriteDataVM;
    };

    const minEffectiveDate = useMemo(() => {
        if (expirationDate) {
            const actualDate = {
                day: expirationDate.getDate(),
                month: expirationDate.getMonth(),
                year: expirationDate.getFullYear()
            };

            return actualDate;
        }

        return undefined;
    }, [expirationDate]);

    const maxEffectiveDate = useMemo(() => {
        if (minEffectiveDate) {
            const maxDate = new Date(
                expirationDate.getFullYear() + 1,
                expirationDate.getMonth(),
                expirationDate.getDate()
            );
            const effectiveDateRep = rewriteDTO?.value?.effectiveDate;
            const effectiveDatePolicy = new Date(
                effectiveDateRep?.year,
                effectiveDateRep?.month,
                effectiveDateRep?.day
            );
            const diffIndays = e1pDateUtil.differenceInDays(maxDate, effectiveDatePolicy);

            if (diffIndays >= 0) {
                setOneYearWarning(true);
            } else {
                setOneYearWarning(false);
            }

            return {
                day: expirationDate.getDate(),
                month: expirationDate.getMonth(),
                year: expirationDate.getFullYear() + 1
            };
        }

        return undefined;
    }, [expirationDate, minEffectiveDate, rewriteDTO]);

    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        rewriteReason: {
            availableValues: rewriteReasonList,
            visible: rewriteDataVM?.jobID?.value === undefined,
            showErrors: isPageSubmitted
        },
        rewriteEffectiveDate: {
            updateDateDto: handlePeriodStartDateChange,
            dateDTO: rewriteDTO?.effectiveDate,
            defaultToToday: true,
            showErrors: isPageSubmitted,
            visible: rewriteDataVM?.jobID?.value === undefined && !loader,
            minDate: policyFlatCancelled ? undefined : minEffectiveDate,
            maxDate: maxEffectiveDate,
            isRequired: true,
            onValidate
        },
        rewriteDataEffectiveDate: {
            updateDateDto: handlePeriodStartDateChange,
            dateDTO: rewriteDataVM?.baseData?.effectiveDate,
            defaultToToday: true,
            showErrors: isPageSubmitted,
            visible: rewriteDataVM?.jobID?.value !== undefined,
            readOnly: true,
            onValidate
        },
        rewriteDataReason: {
            visible: rewriteDataVM?.jobID?.value !== undefined
        },
        ICSNotificationDiv: {
            visible: shouldBlock && ICSWarning && !oneYearWarning
        },
        OneYearWarningDiv: {
            visible: oneYearWarning
        },
        rewriteDataWarningDiv: {
            visible: rewriteDataVM?.jobID?.value !== undefined
        },
        rewritePageIndicatorLoader: {
            loaded: !(rewriteDataVM?.jobID?.value === undefined && loader)
        },
        startRewritePageContainer: {
            visible: !loader
        },
        pageHeaderRewriteDiv: {
            visible: !loader
        }
    };


    return (
        <WizardPage
            isLoadingWholePage={loader}
            onNext={onNext}
            showPrevious={false}
            showCancel
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{ rewriteDTO, rewriteDataVM }}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={updateWizardData}
            />
        </WizardPage>
    );
}


export default withViewModelService(withAuthenticationContext(StartRewritePageEA));
