import React, { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, withModalContext } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import htmlParser from 'html-react-parser';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './OpeningProtectionComponent.metadata.json5';
import styles from './OpeningProtectionComponent.module.scss';
import OpeningProtectionHelpText from './OpeningProtectionHelpText.json5';

import { Button } from '@jutro/legacy/components';

function OpeningProtectionComponent(props) {
    const {
        transactionVM,
        viewModelService,
        isOpen,
        onResolve,
        viewOnly,
        title,
        setFieldsChangedOnCoveragePage
    } = props;
    const [transactionVMClone, setTransactionVMClone] = useState(viewModelService.clone(transactionVM));
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [shutters, setShutters] = useState();
    const translator = useTranslator();
    const { isComponentValid, onValidate } = useValidation('OpeningProtectionComponent');

    useEffect(() => {
        const windstormMitigationList = _.get(transactionVMClone,
            'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
            []);
        const shutterItem = _.find(windstormMitigationList, (windstormMitigation) => _.includes(['basicstormshutters', 'hurricanestormshutters', 'impactresistantglass'], windstormMitigation.featureType));

        if (shutterItem) {
            setShutters(shutterItem.featureType);
        }
        // It needs to execute on mount only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveAndNextHandler = useCallback(() => {
        if (isComponentValid) {
            const {
                _xCenter,
                _dtoName
            } = transactionVMClone.lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures;
            const newTransactionVMCloneData = viewModelService.clone(transactionVMClone);
            const windstormMitigationFeatureValues = [];

            if (shutters) {
                windstormMitigationFeatureValues.push(viewModelService.create({ featureType: shutters }, _xCenter, _dtoName).value); 
            }

            _.set(newTransactionVMCloneData,
                'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
                windstormMitigationFeatureValues);
            setTransactionVMClone(newTransactionVMCloneData);
            setFieldsChangedOnCoveragePage(true);
            onResolve(newTransactionVMCloneData);
        } else {
            updateIsPageSubmitted(true);
        }
    }, [isComponentValid, transactionVMClone, viewModelService, shutters, setFieldsChangedOnCoveragePage, onResolve]);

    const onCancel = useCallback(
        () => {
            onResolve(transactionVMClone);
        }, [onResolve, transactionVMClone]
    );
    
    const onShuttersChange = useCallback(
        (value) => {
            setShutters(value);
        }, [setShutters]
    );

    const resolvers = {
        resolveClassNameMap: styles
    };

    const overrideProps = {
        '@field': {
            readOnly: viewOnly,
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false,
            required: true
        },
        shuttersSelect: {
            value: shutters,
            onValueChange: onShuttersChange,
            tooltip: {
                text : htmlParser(OpeningProtectionHelpText.EH_OpeningProtectionHelpText),
                placement: 'right'
            }
        },
        completeMissingFieldMessageDiv: {
            visible: isPageSubmitted && !isComponentValid
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={styles.transactionComparisonModal}>
            <ModalHeader title={translator(title)}  />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={transactionVMClone}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    {translator(commonMessages.cancel)}
                </Button>
                <Button onClick={saveAndNextHandler} hidden={viewOnly}>
                    {translator(commonMessages.save)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

OpeningProtectionComponent.propTypes = {
    viewOnly: PropTypes.bool,
    setFieldsChangedOnCoveragePage: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    transactionVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.func.isRequired,
};

OpeningProtectionComponent.defaultProps = {
    viewOnly: false
};

export default withModalContext(OpeningProtectionComponent);
