import { defineMessages } from 'react-intl';

export default defineMessages({
    viewResultHeader: {
        id: 'quoteandbind.ea.views.ea-reports.Result',
        defaultMessage: 'Result'
    },
    viewOperatorHeader: {
        id: 'quoteandbind.ea.views.ea-reports.Operator',
        defaultMessage: 'Operator'
    },
    orderedReportResults: {
        id: 'quoteandbind.ea.views.ea-reports.Ordered Report Results',
        defaultMessage: 'Ordered Report Results'
    },
    orderedReportResultsSubHeader: {
        id: 'quoteandbind.ea.views.ea-reports.We need you to provide the following missing or incomplete information before we can provide a verified quote',
        defaultMessage: 'Provide the following missing or incomplete information before we can provide a verified quote'
    },
    mvrResultsHeading: {
        id: 'quoteandbind.ea.views.ea-reports.MVR Results',
        defaultMessage: 'MVR Results'
    },
    priorCarrierResults: {
        id: 'quoteandbind.ea.views.ea-reports.Prior Carrier Results',
        defaultMessage: 'Prior Carrier Results'
    },
    incidentDate: {
        id: 'quoteandbind.ea.views.ea-reports.Violation Date',
        defaultMessage: 'Violation Date'
    },
    convictionDate: {
        id: 'quoteandbind.ea.views.ea-reports.Conviction Date',
        defaultMessage: 'Conviction Date'
    },
    incidentStatus: {
        id: 'quoteandbind.ea.views.ea-reports.Incident Status',
        defaultMessage: 'Incident Status'
    },
    incidentSeverity: {
        id: 'quoteandbind.ea.views.ea-reports.Incident Severity',
        defaultMessage: 'Incident Severity'
    },
    violationType: {
        id: 'quoteandbind.ea.views.ea-reports.Incident Type',
        defaultMessage: 'Incident Type'
    },
    violationSource: {
        id: 'quoteandbind.ea.views.ea-reports.Source',
        defaultMessage: 'Source'
    },
    violationMVRStatus: {
        id: 'quoteandbind.ea.views.ea-reports.MVR Status',
        defaultMessage: 'MVR Status'
    },
    incurDate: {
        id: 'quoteandbind.ea.views.ea-reports.Date of Loss',
        defaultMessage: 'Date of Loss'
    },
    atFault: {
        id: 'quoteandbind.ea.views.ea-reports.At Fault',
        defaultMessage: 'At Fault'
    },
    claimNumber: {
        id: 'quoteandbind.ea.views.ea-reports.Claim Number',
        defaultMessage: 'Claim Number'
    },
    claimStatus: {
        id: 'quoteandbind.ea.views.ea-reports.Claim Status',
        defaultMessage: 'Claim Status'
    },
    lossType: {
        id: 'quoteandbind.ea.views.ea-reports.Incident Type',
        defaultMessage: 'Incident Type'
    },
    lossAmount: {
        id: 'quoteandbind.ea.views.ea-reports.Amount',
        defaultMessage: 'Amount'
    },
    mvrYes: {
        id: 'quoteandbind.ea.views.ea-reports.Yes',
        defaultMessage: 'Yes'
    },
    mvrNo: {
        id: 'quoteandbind.ea.views.ea-reports.No',
        defaultMessage: 'No'
    },
    clueCoverage: {
        id: 'quoteandbind.ea.views.ea-reports.Coverage',
        defaultMessage: 'Coverage'
    },
    saveOverridesErrorTitle:{
        id: 'quoteandbind.ea.views.ea-reports.Save Override Error',
        defaultMessage: 'Save Override Error'
    },
    saveOverridesErrorMessage:{
        id: 'quoteandbind.ea.views.ea-reports.Save Override Error Message',
        defaultMessage: 'Error in saving the dispute data.'
    },
    reOrderMVRErrorTitle:{
        id: 'quoteandbind.ea.views.ea-reports.Re-Order MVR Error',
        defaultMessage: 'Re-Order MVR Error'
    },
    reOrderMVRErrorMessage:{
        id: 'quoteandbind.ea.views.ea-reports.Error occurred re-ordering MVR.',
        defaultMessage: 'Error occurred re-ordering MVR.'
    },
    reorderMVR:{
        id: 'components.LossTableComponent.REORDER MVR',
        defaultMessage: 'REORDER MVR'
    },
    reorderMVRDialogTitle:{
        id: 'components.LossTableComponent.Reorder MVR?',
        defaultMessage: 'Reorder MVR?'
    },
    reorderMVRDialogMessage:{
        id: 'components.LossTableComponent.The Motor Vehicle Record will be reordered for {operatorName}.',
        defaultMessage: 'The Motor Vehicle Record will be reordered for {operatorName}.'
    },
    orderingMVR:{
        id: 'components.LossTableComponent.Ordering MVR...',
        defaultMessage: 'Ordering MVR...'
    },
    savingOverrides:{
        id: 'components.LossTableComponent.Saving Overrides...',
        defaultMessage: 'Saving Overrides...'
    }
});
