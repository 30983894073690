import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { PolicyDiffService, ChangePhraseService } from 'e1p-capability-policyjob';
import messages from '../PolicyChangeDiffPage.messages';
import metadata from '../PolicyChangeDiffPage.metadata.json5';

function ViewPolicyChangeDiff(props) {
    const {
        wizardData: policyChangeVM, updateWizardData
    } = props;
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const [policyDiffData, setPolicyDiffData] = useState(null);
    const [policyChangePhraseData, setPolicyChangePhraseData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        PolicyDiffService.getPolicyDiffWithPrevious(
            [policyChangeVM.jobID.value], authHeader
        ).then(setPolicyDiffData)
            .finally(() => {
                setIsLoading(false);
            });

        ChangePhraseService.getChangePhrase(
            policyChangeVM.jobID.value, authHeader
        ).then((response) => {
            const mappedPhrases = response.map((phrase) => ({
                    name: phrase
                }));

            setPolicyChangePhraseData(mappedPhrases);
        }).finally(() => {
            setIsLoading(false);
        });
        // It should call when page is render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isSignatureRequired = get(policyChangeVM, 'lobData.homeowners_EH.hasSignatureRequiredFormsInCurrentJob.value');

    const resolvers = {
        resolveCallbackMap: {
            sortString: DatatableUtil.sortString
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            readOnly: true
        },
        policyChangeDiffPageLoadingIndicator: {
            loaded: !isLoading,
            text: translator(messages.loadingPolicyDiff)
        },
        policyChangeDiffPageContainer: {
            visible: !isLoading
        },
        ehPolicyDiff: {
            quote: policyDiffData,
            visible: !isEmpty(policyDiffData)
        },
        changePhraseDataTable: {
            data: policyChangePhraseData
        },
        PremiumBox: {
            policyChangeVM,
            viewOnlyMode: true,
            authHeader,
            showMonthlyPaymentComponent: false
        },
        changeConflictsTab: {
            visible: false
        },
        changeConflicts: {
            conflicts: [],
            jobNumber: '',
            authHeader: '',
            changeEffectiveDate: new Date(),
            writeValue: () => { }
        },
        signatureTab: {
            visible: isSignatureRequired
        },
        // Hiding errors in view flow
        WizardSingleErrorComponent: {
            visible: false
        },
        signatureComponent: {
            submissionVM: policyChangeVM,
            updateWizardData,
            authHeader,
            LOB: 'homeowners_EH',
            onSignatureSuccess: () => { },
            producerCodeOptions,
            setProducerCodeOptions,
            viewOnlyMode: true
        },
        deliverDocumentsIndComponent: {
            visible: authUserData.permissions_Ext.includes('docdeliveryind_ext'),
            deliverDocInd: get(policyChangeVM, 'value.shouldDeliverDocuments_Ext', true),
            setDeliverDocInd: () => { },
            viewOnlyMode: true
        }
    };

    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, policyChangeVM, id, path, overrideProps), [overrideProps, policyChangeVM]);

    return (
        <WizardPage
            showNext={false}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewPolicyChangeDiff.propTypes = wizardProps;
export default ViewPolicyChangeDiff;
