import { defineMessages } from 'react-intl';

export default defineMessages({
    addThirdPartyInterest: {
        id: 'common.E1PEATPIDisplayTableComponent.addThirdPartyInterest',
        defaultMessage: 'Add Third-Party Interest'
    },
    tpiSearch: {
        id: 'common.E1PEATPIDisplayTableComponent.tpiSearch',
        defaultMessage: 'Third-Party Interest Search'
    },
    removeTPITitle: {
        id: 'common.E1PEATPIDisplayTableComponent.removeTPITitle',
        defaultMessage: 'Remove TPI?'
    },
    removeTPIDescription: {
        id: 'common.E1PEATPIDisplayTableComponent.removeTPIDescription',
        defaultMessage: 'Are you sure you want to remove this TPI?'
    },
    dtColumnName: {
        id: 'common.E1PEATPIDisplayTableComponent.dtColumnName',
        defaultMessage: 'Name'
    },
    dtColumnAddress: {
        id: 'common.E1PEATPIDisplayTableComponent.dtColumnAddress',
        defaultMessage: 'Address'
    },
    dtColumnInterestType: {
        id: 'common.E1PEATPIDisplayTableComponent.dtColumnInterestType',
        defaultMessage: 'Interest Type'
    },
    dtColumnLoanNumber: {
        id: 'common.E1PEATPIDisplayTableComponent.dtColumnLoanNumber',
        defaultMessage: 'Loan Number'
    },
    dtColumnTitleholderType: {
        id: 'common.E1PEATPIDisplayTableComponent.dtColumnTitleholderType',
        defaultMessage: 'Titleholder Type'
    },
    dtColumnRelationshipToNamedInsured: {
        id: 'common.E1PEATPIDisplayTableComponent.dtColumnRelationshipToNamedInsured',
        defaultMessage: 'Relationship To Named Insured'
    },
    dtColumnDeceased: {
        id: 'common.E1PEATPIDisplayTableComponent.dtColumnDeceased',
        defaultMessage: 'Deceased'
    },
    useExistingContact: {
        id: 'common.E1PEATPIDisplayTableComponent.Use Existing Contact',
        defaultMessage: 'Use Existing Contact'
    },
    matchesPNIorSNITitle: {
        id: 'common.E1PEATPIDisplayTableComponent.Interest Type is Primary Named Insured and/or Secondary Named Insured',
        defaultMessage: 'Interest Type is Primary Named Insured and/or Secondary Named Insured'
    },
    matchesPNIorSNIMessage: {
        id: 'common.E1PEATPIDisplayTableComponent.Interest Type cannot be Primary Named Insured or/and Secondary Named Insured. Please remove the Interest.',
        defaultMessage: 'Interest Type cannot be Primary Named Insured or/and Secondary Named Insured. Please remove the Interest.'
    },
    policyLevelHeader: {
        id: "common.E1PEATPIDisplayTableComponent.Policy Level",
        defaultMessage: "Policy Level"
    },
    vehicleLevelHeader: {
        id: "common.E1PEATPIDisplayTableComponent.Vehicle Level",
        defaultMessage: "Vehicle Level"
    }
});
