import { defineMessages } from 'react-intl';

export default defineMessages({
    doingRateMessage: {
        id: 'endorsement.eu.views.riskAnalysis.Please wait while we rate your policy...',
        defaultMessage: 'Please wait while we rate your policy...'
    },
    lossesAndViolationTabHeading: {
        id: 'endorsement.eu.views.riskAnalysis.Losses & Violations',
        defaultMessage: 'Losses & Violations'
    },
    creditTab: {
        id: 'endorsement.eu.views.riskAnalysis.Credit',
        defaultMessage: 'Credit'
    }
});
