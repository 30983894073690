import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'gw-capability-policychange-common-react';
import PCEAInsuredDetailsPage from './pages/InsuredDetails/InsuredDetailsPage';
import PCEAViewInsuredDetailsPage from './pages/InsuredDetails/viewOnly/ViewInsuredDetailsPage';
import PCEARiskAnalysisPage from './pages/RiskAnalysis/RiskAnalysisPage';
import PCEAViewRiskAnalysisPage from './pages/RiskAnalysis/viewOnly/ViewRiskAnalysisPage';
import PCEAQuotePage from './pages/Coverages/CoveragePage';
import PCEAViewQuotePage from './pages/Coverages/viewOnly/ViewCoveragePage';
import PCEAPolicyChangeDiffPage from './pages/PolicyChangeDiff/PolicyChangeDiffPage';
import PCEAViewPolicyChangeDiffPage from './pages/PolicyChangeDiff/viewOnly/ViewPolicyChangeDiffPage';
import PCEAVehiclePage from './pages/Vehicles/VehiclesPage';
import PCEAViewVehiclePage from './pages/Vehicles/viewOnly/ViewVehiclesPage';
import PCEADriverPage from './pages/Drivers/DriversPage';
import PCEAViewDriverPage from './pages/Drivers/viewOnly/ViewDriverPage';
import PCEAStartChangePage from './pages/StartChange/StartChangePage';
import PCEAViewStartChangePage from './pages/StartChange/viewOnly/ViewStartChangePage';
import PCEAThirdPartyInterestPage from './pages/ThirdPartyInterest/ThirdPartyInterestPage';
import PCEAClauseComponent from './components/PCEAClauseComponent';
import PCEAViewThirdPartyInterestPage from './pages/ThirdPartyInterest/viewOnly/ViewThirdPartyInterest';
import PCEAClausesComponentVM from './components/PCEAClausesComponentVM';
import PCEASingleClauseComponentVM from './components/PCEASingleClauseComponentVM';
import PCEAPremiumBoxComponent from './components/PCEAPremiumBoxComponent/PCEAPremiumBoxComponent';
import PCEAThankYouPage from './pages/ThankYou/ThankYouPage';
import PCEADriverAssignmentPage from './pages/DriverAssignment/DriverAssignmentPage';
import PCEAViewDriverAssignmentPage from './pages/DriverAssignment/ViewOnly/ViewDriverAssignmentPage';

setComponentMapOverrides(
    {
        PCEAInsuredDetailsPage: { component: 'PCEAInsuredDetailsPage' },
        PCEAQuotePage: { component: 'PCEAQuotePage' },
        PCEAThirdPartyInterestPage: { component: 'PCEAThirdPartyInterestPage' },
        PCEAPolicyChangeDiffPage: { component: 'PCEAPolicyChangeDiffPage' },
        PCEAVehiclePage: { component: 'PCEAVehiclePage' },
        PCEADriverPage: { component: 'PCEADriverPage' },
        PCEAStartChangePage: { component: 'PCEAStartChangePage' },
        PCEARiskAnalysisPage: { component: 'PCEARiskAnalysisPage' },
        PCEAViewStartChangePage: { component: 'PCEAViewStartChangePage' },
        PCEAViewInsuredDetailsPage: { component: 'PCEAViewInsuredDetailsPage' },
        PCEAViewDriverPage: { component: 'PCEAViewDriverPage' },
        PCEAViewVehiclePage: { component: 'PCEAViewVehiclePage' },
        PCEAViewRiskAnalysisPage: { component: 'PCEAViewRiskAnalysisPage' },
        PCEAViewQuotePage: { component: 'PCEAViewQuotePage' },
        PCEAViewThirdPartyInterestPage: { component: 'PCEAViewThirdPartyInterestPage' },
        PCEAViewPolicyChangeDiffPage: { component: 'PCEAViewPolicyChangeDiffPage' },
        PCEAClauseComponent: { component: 'PCEAClauseComponent' },
        PCEAClausesComponentVM: { component: 'PCEAClausesComponentVM' },
        PCEASingleClauseComponentVM: { component: 'PCEASingleClauseComponentVM' },
        PCEAThankYouPage: { component: 'PCEAThankYouPage' },
        PCEAPremiumBoxComponent: { component: 'PCEAPremiumBoxComponent' },
        PCEADriverAssignmentPage: { component: 'PCEADriverAssignmentPage' },
        PCEAViewDriverAssignmentPage: { component: 'PCEAViewDriverAssignmentPage' }
    },
    {
        PCEAInsuredDetailsPage,
        PCEAQuotePage,
        PCEAThirdPartyInterestPage,
        PCEAPolicyChangeDiffPage,
        PCEAVehiclePage,
        PCEADriverPage,
        PCEAStartChangePage,
        PCEARiskAnalysisPage,
        PCEAViewStartChangePage,
        PCEAViewInsuredDetailsPage,
        PCEAViewDriverPage,
        PCEAViewVehiclePage,
        PCEAViewRiskAnalysisPage,
        PCEAViewQuotePage,
        PCEAViewThirdPartyInterestPage,
        PCEAViewPolicyChangeDiffPage,
        PCEAClauseComponent,
        PCEAClausesComponentVM,
        PCEASingleClauseComponentVM,
        PCEAThankYouPage,
        PCEAPremiumBoxComponent,
        PCEADriverAssignmentPage,
        PCEAViewDriverAssignmentPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as EAPolicyChangeWizard } from './EAPolicyChangeWizard';
