import { defineMessages } from 'react-intl';

export default defineMessages({
    orderingRoofScoreDataMessage: {
        id: 'quoteandbind.roofScoreDataComponent.Ordering Roof Score Data...',
        defaultMessage: 'Ordering Roof Score Data...'
    },
    perilName: {
        id: 'quoteandbind.roofScoreDataComponent.Peril Name',
        defaultMessage: 'Peril Name'
    },
    perilValue: {
        id: 'quoteandbind.roofScoreDataComponent.Peril Value',
        defaultMessage: 'Peril Value'
    }
});
