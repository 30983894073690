import React, {
    useCallback, useEffect
} from 'react';
import _ from 'lodash';
import {
    ModalBody,
    ModalNext
} from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import styles from './EADriverPrefill.module.scss';
import metadata from './EADriverPrefill.metadata.json5';
import './EADriverPrefill.messages';

function EADriverPrefill(props) {
    const {
        submissionVM,
        updateWizardData,
        prefillData,
        viewModelService,
        isOpen,
        onResolve
    } = props;

    const { onValidate } = useValidation(
        'EADriverPrefill'
    );

    const handleStatusChange = useCallback((value, index) => {
        const fullPath = `lobData.personalAuto_EA.prefillDrivers.children[${index}].driverStatus`;

        _.set(submissionVM, fullPath, value);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

    const createPrefillDriverVM = useCallback((driver) => {
        const {
            _dtoName,
            _xCenter
        } = _.get(submissionVM, 'lobData.personalAuto_EA.prefillDrivers');

        const middleName = driver.middleName ? driver.middleName : '';

        driver.displayName = `${driver.firstName} ${middleName} ${driver.lastName}`;


        const driverVM = viewModelService.create(driver, _xCenter, _dtoName);

        driverVM.driverStatus = driverVM.driverStatus.aspects.availableValues.find((value) => value.code === 'AddedToQuote');

        return driverVM;
    }, [submissionVM, viewModelService]);

    useEffect(
        () => {
            if (prefillData !== '' && prefillData.prefillDrivers) {
                prefillData.prefillDrivers.forEach((driver) => {
                    const prefillDriverArray = _.get(submissionVM, 'lobData.personalAuto_EA.prefillDrivers.value', []);
                    const duplicate = prefillDriverArray.some((prefillDriver) => (prefillDriver.firstName.toUpperCase() === driver.firstName.toUpperCase()
                            && prefillDriver.lastName.toUpperCase() === driver.lastName.toUpperCase()
                            && prefillDriver.dateOfBirth.day === driver.dateOfBirth.day
                            && prefillDriver.dateOfBirth.year === driver.dateOfBirth.year
                            && prefillDriver.dateOfBirth.month === driver.dateOfBirth.month));

                    if (!duplicate) {
                        prefillDriverArray.push((createPrefillDriverVM(driver)).value);
                    }
                });
            }

            updateWizardData(submissionVM);
        }, [createPrefillDriverVM, prefillData, submissionVM, updateWizardData]
    );

    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        submissionVM.lobData.personalAuto_EA.prefillDrivers.children.forEach((driver, index) => {
            overrideProps[`prefillDriver${index}`] = {
                handleStatusChange
            };
        });

        return overrideProps;
    }, [handleStatusChange, submissionVM.lobData.personalAuto_EA.prefillDrivers.children]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        eaDriverPrefillLabels: {
            visible: !_.isEmpty(prefillData.prefillDrivers)
        },
        prefillSubTitle: {
            visible: !_.isEmpty(prefillData.prefillDrivers)
        },
        eaDriverPrefillBody: {
            visible: !_.isEmpty(prefillData.prefillDrivers)
        },
        eaDriverPrefillEmptyError: {
            visible: _.isEmpty(prefillData.prefillDrivers)
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVM]
    );

    const onOk = useCallback(
        () => {
            const wrapperObj = {
                prefillData
            };

            // this is where we can pass any data back on close
            onResolve(wrapperObj);
        }, [onResolve, prefillData]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            onPNI: _.get(submissionVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.value'),
            onSNI: _.get(submissionVM, 'lobData.personalAuto_EA.secondaryNamedInsured.person.value'),
            onOk
        },
    };

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onOk}
            className="full-screen"
        >
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
        </ModalNext>

    );
}

EADriverPrefill.propTypes = {
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({
            prefillDrivers: PropTypes.shape({})
        }),
        lobData: PropTypes.shape({
            personalAuto_EA: PropTypes.shape({
                prefillDrivers: PropTypes.shape({
                    children: PropTypes.arrayOf(
                        PropTypes.shape({})
                    )
                })
            })
        })
    }).isRequired,
    prefillData: PropTypes.shape({
        coverables: PropTypes.shape({
            drivers: PropTypes.arrayOf(
                PropTypes.shape({})
            )
        }),
        prefillDrivers: PropTypes.arrayOf(
            PropTypes.shape({})
        )
    }).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func,
        create: PropTypes.func
    }).isRequired,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func
};

EADriverPrefill.defaultProps = {
    isOpen: true,
    onResolve: undefined
}
export default EADriverPrefill;
