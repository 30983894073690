import { defineMessages } from 'react-intl';

export default defineMessages({
    searchingForMatchingTPIsMessage: {
        id: 'component.E1PTPISearchComponent.Searching for matching Third-Party Interests...',
        defaultMessage: 'Searching for matching Third-Party Interests...'
    },
    retrievingTPIDetails: {
        id: 'common.component.tpiSearch.Retrieving Third-Party Interest Details...',
        defaultMessage: 'Retrieving Third-Party Interest Details...'
    },
    searchForAThirdPartyInterest: {
        id: 'component.E1PTPISearchComponent.Search for a Third-Party Interest',
        defaultMessage: 'Search for a Third-Party Interest'
    },
    existingThirdParties: {
        id: 'component.E1PTPISearchComponent.Existing Third Parties',
        defaultMessage: 'Existing Third Parties'
    },
    preferredContactTooltip: {
        id: 'component.E1PTPISearchComponent.Preferred contact for billing and / or policy documents.',
        defaultMessage: 'Preferred contact for billing and / or policy documents.'
    },
    escrowIndicator: {
        id: 'component.E1PTPISearchComponent.Escrow Indicator',
        defaultMessage: 'Escrow Indicator'
    },
    newTPICanBeCreatedText: {
        id: 'component.E1PTPISearchComponent.If the desired Third Party is not displayed, a new Third Party can be created.',
        defaultMessage: 'If the desired Third Party is not displayed, a new Third Party can be created.'
    },
    createNewThirdParty: {
        id: 'component.E1PTPISearchComponent.NEW THIRD PARTY NOT LISTED ABOVE',
        defaultMessage: 'NEW THIRD PARTY NOT LISTED ABOVE'
    },
    preferredTooltipText: {
        id: 'component.E1PTPISearchComponent.Preferred contact for billing and / or policy documents.',
        defaultMessage: 'Preferred contact for billing and / or policy documents.'
    },
    tpiNumber: {
        id: 'component.E1PTPISearchComponent.Third-Party Interest Number',
        defaultMessage: 'Third-Party Interest Number'
    },
    tpiNumberSearchError: {
        id: 'component.E1PTPISearchComponent.Error occurred while searching Third-Party Interest with given Third-Party Interest Number',
        defaultMessage: 'Error occurred while searching Third-Party Interest with given Third-Party Interest Number'
    },
    searchForAThirdPartyInterestSubheader: {
        id: 'component.E1PTPISearchComponent.Start search with a partial Name, our system has a comprehensive database containing verified names and addresses for most Third-Party Interests.',
        defaultMessage: 'Start search with a partial Name, our system has a comprehensive database containing verified names and addresses for most Third-Party Interests.'
    },
    selectAThirdPartyWherePart1: {
        id: "component.E1PTPISearchComponent.Select a Third Party where",
        defaultMessage: "Select a Third Party where "
    },
    selectAThirdPartyWherePart2: {
        id: "component.E1PTPISearchComponent.Preferred",
        defaultMessage: "Preferred"
    },
    selectAThirdPartyWherePart3: {
        id: "component.E1PTPISearchComponent. is Yes. This is the preferred name and address for policy and billing documents provided to us by the Third Party.",
        defaultMessage: " is Yes. This is the preferred name and address for policy and billing documents provided to us by the Third Party."
    },
    noResultsFound: {
        id: "component.E1PTPISearchComponent.No results found. Adjust your search criteria before creating a new Third Party. Our system has a comprehensive database containing verified names and addresses for most Third-Party Interests.",
        defaultMessage: "No results found. Adjust your search criteria before creating a new Third Party. Our system has a comprehensive database containing verified names and addresses for most Third-Party Interests."
    },
    newTPINotSetupForBillingText: {
        id: "component.E1PTPISearchComponent.If a new Third Party is created, when a Preferred Third Party already exists, we will endorse the policy to the Preferred Third Party. This will result in a policy change.",
        defaultMessage: "If a new Third Party is created, when a Preferred Third Party already exists, we will endorse the policy to the Preferred Third Party. This will result in a policy change."
    }
});
