import React, { useCallback, useEffect } from 'react';
import {
    get as _get
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useViewPolicyUtil } from 'e1p-capability-hooks';
import appConfig from 'app-config';
import { useTranslator } from '@jutro/locale';
import metadata from '../StartChangePage.metadata.json5';
import '../StartChangePage.messages';
import styles from '../StartChangePage.module.scss';

const LOB = 'personalAuto_EA';

function ViewStartChangePage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        steps,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        markFurthestStep,
        changeNextSteps,
        currentStep
    } = props;
    const {
        onValidate,
    } = useValidation('ViewStartChangePage');

    const translator = useTranslator();

    const {
        getLandingPageIndexForViewOnlyPolicy
    } = useViewPolicyUtil();

    const adjustStepsForState = useCallback(async () => {
        // check for enabled state
        const policyState = _get(policyChangeVM, 'baseData.policyAddress.state.value.code');
        const enabledStates = appConfig.driverAssignmentStates;
        const nextSteps = [...steps];
        const driverAssignmentStepIndex = nextSteps.findIndex((step) => step.path === '/view-assignment');

        // not needed for state and does not exist in steps already
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex < 0) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // not needed for the state, but previous state selected needed it
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.splice(driverAssignmentStepIndex, 1); // remove
            nextSteps.shift(); // remove current page (always first elt)
            changeNextSteps(nextSteps);

            return nextSteps;
        }

        // needed for the state, and already has it
        if (enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // needed for the state and not already in the steps
        const driverAssignmentPage = {
            id: 'EAEndorsementDriverAssignmentPage',
            title: {
                id: 'quoteandbind.ea.wizard.step.Drivers Assignment',
                defaultMessage: 'Driver Assignment'
            },
            path: '/view-assignment',
            component: 'PCEAViewDriverAssignmentPage',
            stepProps: {
                template: 'WizardPageTemplateWithTitle'
            }
        };
        const indexOfVehiclePage = nextSteps.findIndex((step) => step.path === '/view-risk-analysis');

        // insert driver assignment
        nextSteps.splice(indexOfVehiclePage, 0, driverAssignmentPage)
        // remove current page (always first elt)
        nextSteps.shift();
        changeNextSteps(nextSteps);

        return nextSteps;
    }, [changeNextSteps, policyChangeVM, steps]);

    useEffect(() => {
        adjustStepsForState().then((newSteps) => {
            // add current step back because we need all steps
            newSteps.unshift(currentStep);
            markFurthestStep((newSteps.length - 1));

            if (isPageJumpEnabled) {
                updateIsPageJumpEnabled(false);
                jumpTo(getLandingPageIndexForViewOnlyPolicy(LOB, newSteps, 'PolicyChange'), true);
            }
        }); // need to adjust steps before jumping
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(() => policyChangeVM, [policyChangeVM]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            readOnly: true
        },
        openPolicyChangeInfoMessageDiv: {
            visible: false
        },
        changeEffectiveDate: {
            dateDTO: policyChangeVM.baseData.effectiveDate,
            updateDateDto: () => { },
            showErrors: true
        },
        changeDescription: {
            visible: (_get(policyChangeVM, 'policyChangeReason_Ext.value.code') === 'other')
        },
        changeSource: {
            visible: false
        },
        changeReason: {
            visible: false
        },
        changeSourceViewOnly: {
            value: policyChangeVM.value.policyChangeSource !== undefined
                ? translator({
                    id: `typekey.PolicyChangeSource_Ext.${policyChangeVM.value.policyChangeSource}`,
                    defaultMessage: policyChangeVM.value.policyChangeSource
                })
                : ''
        },
        changeReasonViewOnly: {
            value: policyChangeVM.value.policyChangeReason !== undefined
                ? translator({
                    id: `typekey.PolicyChangeReason_Ext.${policyChangeVM.value.policyChangeReason}`,
                    defaultMessage: policyChangeVM.value.policyChangeReason
                })
                : ''
        },
        lossOccurredBetweenEffectiveDateAndCreateDateInd: {
            visible: policyChangeVM.value.hasLossOnOrAfterEffDate_Ext !== undefined 
            || policyChangeVM.value.hasLossOnOrAfterEffDate !== undefined,
            value: policyChangeVM.value.hasLossOnOrAfterEffDate_Ext === undefined
                ? policyChangeVM.value.hasLossOnOrAfterEffDate
                : policyChangeVM.value.hasLossOnOrAfterEffDate_Ext
        },
        startPageLoader: {
            loaded: true
        },
        policyChangeDetailsDiv: {
            visible: true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

ViewStartChangePage.propTypes = wizardProps;

export default ViewStartChangePage;
