import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    noop as _noop
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { EndorsementService } from 'e1p-capability-policychange';
import htmlParser from 'html-react-parser';
import moment from 'moment';
import { commonMessages } from 'e1p-platform-translations';
import messages from './E1POOSConflictsComponent.messages';
import metadata from './E1POOSConflictsComponent.metadata.json5';
import styles from './E1POOSConflictsComponent.module.scss';

import { RadioButtonField } from '@jutro/legacy/components';

function E1POOSConflictsComponent(props) {
    const modalApi = useModal();
    const {
        conflicts,
        writeValue,
        authHeader,
        jobNumber,
        changeEffectiveDate,
        showSubmitButton,
        isPageSubmitted,
        updateIsPageSubmitted,
        updateIsOOSComoponentValid
    } = props;
    const translator = useTranslator();
    const [isLoading, setIsLoading] = useState(false);
    const [hasClickedSubmit, updateHasClickedSubmit] = useState(false);
    const [policyChangeEffHeader, setPolicyChangeEffHeader] = useState(translator(messages.policyChangeEff));
    const [overrideValuePresentForAllConflicts, setOverrideValuePresentForAllConflicts] = useState(false);

    useEffect(() => {
        setPolicyChangeEffHeader(
            translator(messages.policyChangeEff, {
                changeEffectiveDate:
                    moment(new Date(
                        changeEffectiveDate.year,
                        changeEffectiveDate.month,
                        changeEffectiveDate.day
                    )).format('MM/DD/YYYY')
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateValue = useCallback(
        (value, path) => {
            writeValue(value, path);
            setOverrideValuePresentForAllConflicts(conflicts?.every((conflict) => conflict.override != null));
        }, [conflicts, writeValue]
    );

    const onOverrideChange = useCallback((value, index) => {
        const conflictsToBeUpdated = conflicts[index];

        if (conflictsToBeUpdated) {
            conflictsToBeUpdated.override = value;
        }

        updateValue(conflicts, 'value.conflicts');
    }, [conflicts, updateValue]);

    const getRadionButtonAvailableValues = useMemo(() => [
            {
                name: translator(messages.overrrideFutureConflictsYes),
                code: 'true'
            },
            {
                name: translator(messages.overrrideFutureConflictsNo),
                code: 'false'
            }
        ], [translator]);

    const getOverrideDataCell = useCallback(
        (item, index) => {
            const id = item.id + item.propertyName + index;

            return (
                <RadioButtonField
                    id={id}
                    value={item.override}
                    availableValues={getRadionButtonAvailableValues}
                    controlClassName={styles.overrideConflict}
                    readOnly
                    defaultValue
                    showOptional={false}
                    required
                    showErrors={isPageSubmitted || hasClickedSubmit}
                    showRequired
                    onValueChange={(value) => onOverrideChange(value, index)}
                />
            );
        },
        [getRadionButtonAvailableValues, isPageSubmitted, onOverrideChange, hasClickedSubmit]
    );
    const getPropertyDescription = useCallback(
        (item) => htmlParser(item.propertyDescription.replaceAll('\n', '<br>')),
        []
    );

    const overrideConflictsValue = useCallback((value) => {
        conflicts.forEach((conflict) => { conflict.override = value; });
        updateValue(conflicts, 'value.conflicts');
    }, [conflicts, updateValue]);

    const overrideAll = useCallback(() => {
        overrideConflictsValue(true);
    }, [overrideConflictsValue]);

    const overrideNone = useCallback(() => {
        overrideConflictsValue(false);
    }, [overrideConflictsValue]);

    useEffect(() => {
        if (updateIsOOSComoponentValid) {
            updateIsOOSComoponentValid(overrideValuePresentForAllConflicts);
        }
    }, [updateIsOOSComoponentValid, overrideValuePresentForAllConflicts]);

    const handleOOSConflicts = useCallback(() => {
        if (!overrideValuePresentForAllConflicts) {
            window.scroll(0, 0);
            updateIsPageSubmitted(true);

            return false;
        }

        setIsLoading(true);
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: messages.submitConfirmTitle,
            message: messages.submitConfirmMessage,
            confirmButtonText: commonMessages.confirm,
            cancelButtonText: commonMessages.cancel
        }).then((results) => {
            if (results === 'cancel') {
                setIsLoading(false);

                return _noop();
            }

            const request = {
                conflicts,
                jobNumber
            };

            return EndorsementService.handleOOSConflicts([request], authHeader).then((response) => {
                updateValue(response, 'value');
                setIsLoading(false);
            });
        }, _noop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authHeader, conflicts, jobNumber, updateValue, modalApi]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        oosConflictsDataTable: {
            data: conflicts
        },
        oosConflictsPageLoader: {
            loaded: !isLoading,
            text: translator(messages.updatingOOSConflicts)
        },
        oosConflictsMainContainer: {
            visible: !isLoading,
        },
        policyChangeCurrentValue: {
            header: policyChangeEffHeader
        },
        submitButton: {
            visible: showSubmitButton
        }
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                conflicts,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [conflicts, overrideProps]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getOverrideDataCell,
            getPropertyDescription,
            handleOOSConflicts,
            overrideAll,
            overrideNone
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

E1POOSConflictsComponent.propTypes = {
    conflicts: PropTypes.shape([]).isRequired,
    writeValue: PropTypes.func.isRequired,
    authHeader: PropTypes.string.isRequired,
    jobNumber: PropTypes.string.isRequired,
    changeEffectiveDate: PropTypes.shape({
        year: PropTypes.number,
        month: PropTypes.number,
        day: PropTypes.number
    }).isRequired,
    showSubmitButton: PropTypes.bool,
    isPageSubmitted: PropTypes.bool,
    updateIsPageSubmitted: PropTypes.func,
    updateIsOOSComoponentValid: PropTypes.func
};

E1POOSConflictsComponent.defaultProps = {
    showSubmitButton: true,
    isPageSubmitted: false,
    updateIsPageSubmitted: () => { },
    updateIsOOSComoponentValid: () => { }
};

export default E1POOSConflictsComponent;
