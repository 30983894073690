import React, { } from 'react';
import PropTypes from 'prop-types';
import singleErrorStyles from './EASingleUWIssueComponent.module.scss';

function EASingleUWIssueComponent(props) {
    const { value: longDescription, id } = props;


    return (
        <div className={singleErrorStyles.uwIssuesContainer} key={id}>
            <span>{longDescription}</span>
        </div>
    );
}

EASingleUWIssueComponent.propTypes = {
    value: PropTypes.shape([]).isRequired,
    id: PropTypes.string
};
EASingleUWIssueComponent.defaultProps = {
    id: ''
};
export default EASingleUWIssueComponent;
