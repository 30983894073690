import React from 'react';
import PropTypes from 'prop-types';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import metadata from './ProducerSummaryCardComponent.metadata.json5';
import styles from './ProducerSummaryCardComponent.module.scss';

const ProducerSummaryCardComponenet = (props) => {
    const {
        producerSummaryInfo
    } = props;

    const overrideProps = {
        '@field': {
            readOnly: true,
            autoComplete: false
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    // const producerInfo = { producerCodeOfRecordOrg, producerOfService, preferredUnderwriter };

    return (
        <MetadataForm
            uiProps={metadata.pageContent}
            data={producerSummaryInfo}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap} />
    );
};

ProducerSummaryCardComponenet.propTypes = {
    producerSummaryInfo: PropTypes.shape({
        producerCodeOfRecordOrg: PropTypes.string.isRequired,
        producerOfService: PropTypes.string.isRequired,
        preferredUnderwriter: PropTypes.string.isRequired
    }).isRequired
};

ProducerSummaryCardComponenet.defaultProps = {};

export default ProducerSummaryCardComponenet;
