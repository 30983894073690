import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import { PaymentDetailsPage } from 'gw-capability-quoteandbind-common-react';
import 'e1p-capability-quoteandbind-eu-react';
import GWQuoteEUPolicyDetailsPage from './pages/PolicyDetails/PolicyDetailsPage';
import QNBViewEUPolicyDetailsPage from './pages/PolicyDetails/viewOnly/ViewPolicyDetailsPage';

setComponentMapOverrides(
    {
        GWQuoteEUPolicyDetailsPage: { component: 'GWQuoteEUPolicyDetailsPage' },
        GWQuoteEUPaymentDetailsPage: { component: 'GWQuoteEUPaymentDetailsPage' },
        QNBViewEUPolicyDetailsPage: { component: 'QNBViewEUPolicyDetailsPage' },
    },
    {
        GWQuoteEUPolicyDetailsPage,
        GWQuoteEUPaymentDetailsPage: PaymentDetailsPage,
        QNBViewEUPolicyDetailsPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PEEUWizard } from './PEEUWizard';
