import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    noop as _noop,
    uniq as _uniq
} from 'lodash';
import {
    useModal,
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { usePriorPolicyUpdateUtil, useUWIssueUtil, useLandingPageUtil, e1pDateUtil } from 'e1p-capability-hooks';
import { AutoLossService, PriorCarrierService } from 'e1p-capability-gateway';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { EndorsementService } from 'e1p-capability-policychange';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import metadata from './RiskAnalysisPage.metadata.json5';
import messages from './RiskAnalysisPage.messages';
import requiredMetadata from './RiskAnalysisPage.requiredness';

const LOB = 'personalAuto_EA';

function RiskAnalysisPage(props) {
    const modalApi = useModal();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        steps,
        authUserData,
        jumpTo,
        updateWizardSnapshot
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [creditReportResponse, setCreditReportResponse] = useState({});
    const [dataForComponent, updateDataForComponent] = useState({});
    const { authHeader } = useAuthentication();
    const [isRetrievingReports, setIsRetrievingReports] = useState(false);
    const [isLossReportNoHit, setIsLossReportNoHit] = useState(false);
    const [overlappingException, setOverlappingException] = useState(false);
    const [priorCarrierChanged, setPriorCarrierChanged] = useState(false);
    const policyChangeSource = _get(policyChangeVM, 'value.policyChangeSource', '');
    const policyChangeReason = _get(policyChangeVM, 'value.policyChangeReason', '');
    const [showPrayerForJudgementError, setShowPrayerForJudgementError] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [visibleFields, updateVisibleFields] = useState([]);
    const [activeTab, updateActiveTab] = useState('');
    const policyState = _get(policyChangeVM, 'baseData.policyAddress.state.value.code');
    const {opCo} = useContext(AmfamOktaTokenContext);

    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        registerComponentValidation,
        registerInitialComponentValidation
    } = useValidation('RiskAnalysisPage');
    const {
        modifyDates
    } = usePriorPolicyUpdateUtil(policyChangeVM.lobData.personalAuto_EA.priorPolicyUpdates);

    const {
        hasUWIssues
    } = useUWIssueUtil(
        policyChangeVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const {
        getLandingPageIndexForQuotedJob,
        getDefaultTab
    } = useLandingPageUtil();

    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(policyChangeVM, 'lobData.personalAuto_EA.premiumStability.value') !== undefined;

    const defaultTabBasedOnReason = useMemo(
        () => getDefaultTab(LOB, policyChangeSource, policyChangeReason),
        [getDefaultTab, policyChangeSource, policyChangeReason]
    );
    
    // check if period start date is same as policyChange Effective date
    const isPeriodStartSameAsPCEffectivedate = useMemo(() => {
        const periodStartDate = _get(policyChangeVM, 'value.baseData.periodStartDate.isodate_Ext');
        const policyChangeEffectiveDate = _get(policyChangeVM, 'value.baseData.effectiveDate.isodate_Ext');
        
        if (periodStartDate && policyChangeEffectiveDate) {
            return e1pDateUtil.differenceInDays(periodStartDate, policyChangeEffectiveDate) === 0;
        }

        return false;
    },[policyChangeVM]);

    useEffect(() => {
        const initialVisibleFields = ['declareLossStatementThreeYears', 'declareLossStatementFiveYears']; // Fields to look up by partner/state

        updateVisibleFields(
            isRequired(initialVisibleFields, requiredMetadata, policyState, opCo)
        );
        // User can't able to change policy state on Risk Analysis Page,
        // hence we need to run this useEffect only single time.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opCo]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    const saveAndQuote = useCallback(
        async (isCalledByCustomCall, calledFromSave = false) => {
            modifyDates(policyChangeVM);

            if (priorCarrierChanged) {
                const priorPolicies = _get(policyChangeVM, 'lobData.personalAuto_EA.priorPolicies.value');
                const jobNumber = _get(policyChangeVM, 'jobID.value');
                const priorCarrierPolicyDetailsDataDTO = { jobNumber, priorPolicies }

                try {
                    const priorCarrierResponse = await PriorCarrierService
                        .savePriorCarrierPolicies(priorCarrierPolicyDetailsDataDTO, authHeader);

                    // We can't continue to save and quote with errors, so exit function
                    if (!_isEmpty(_get(priorCarrierResponse, 'exceptions_Ext', []))) {
                        _set(policyChangeVM, 'baseData.value.exceptions_Ext', priorCarrierResponse.exceptions_Ext);
                        updateWizardData(policyChangeVM);

                        return false
                    }

                    _set(policyChangeVM, 'lobData.personalAuto_EA.priorPolicies', priorPolicies);
                } catch (error) {
                    const errorMessage = _get(error, 'baseError.data.error.message');

                    if (errorMessage) {
                        modalApi.showAlert({
                            title: messages.unableToSavePriorCarrier,
                            message: error.baseError.data.error.message,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.close
                        }).catch(_noop);
                    }
                }
            }

            modifyDates(policyChangeVM);
            policyChangeVM.value = await EndorsementService
                .saveAndQuoteEndorsement([policyChangeVM.value], authHeader);
            updateWizardData(policyChangeVM);

            const hasConflicts = !_isEmpty(_get(policyChangeVM, 'value.conflicts', []));
            const validationErrors = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Added this condition to redirect the flow to policyDiff Page
            // if there is any conflicts coming on click of next button.
            if (!calledFromSave && (!isCalledByCustomCall && hasConflicts && validationErrors.length === 0)) {
                updateWizardSnapshot(policyChangeVM);

                let newLandingPageIndex = -1;

                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );

                if (newLandingPageIndex >= 0) {
                    jumpTo(newLandingPageIndex, true);

                    return false;
                }
            }

            return policyChangeVM;
        },
        [
            modifyDates, policyChangeVM, priorCarrierChanged, authHeader, updateWizardData,
            updateWizardSnapshot, getLandingPageIndexForQuotedJob, steps, jumpTo, modalApi
        ]
    );

    const showCustom = true;
    const onNext = (async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setIsSavingEndorsement(true);

        const data = await saveAndQuote(false);

        setIsSavingEndorsement(false);

        return data;
    });

    const onSave = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote(false, true);

                const fieldIssues = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [isComponentValid, policyChangeVM, saveAndQuote, updateWizardSnapshot]
    );

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            const resp = await saveAndQuote(true);

            policyChangeVM.value = resp?.value;
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = _get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [
            isComponentValid, saveAndQuote, policyChangeVM, updateWizardData,
            updateWizardSnapshot, getLandingPageIndexForQuotedJob, steps, jumpTo
        ]
    );

    const isPageValid = useCallback(() => !overlappingException && !isLossReportNoHit, [overlappingException, isLossReportNoHit]);

    const uwIssuesPresent = useMemo(() => 
         hasUWIssues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [policyChangeVM?.errorsAndWarnings_Ext?.underwritingIssues?.value]);

    const getDefaultActiveTab = useMemo(() => {
        if (uwIssuesPresent) {
            return 'uwIssuesTabID';
        }

        return defaultTabBasedOnReason === false ? 'priorCarrierTabID' : defaultTabBasedOnReason;
    }, [uwIssuesPresent, defaultTabBasedOnReason]);

    const createAutoLossVM = useCallback((vmObject, dtoName) => {
        const vmNode = viewModelService.create(
            vmObject,
            'pc',
            dtoName
        );

        return vmNode;
    }, [viewModelService]);

    useEffect(() => {
        if (!isSkipping) {
	        setIsRetrievingReports(true);

            const AutoLossRecordsPromise = AutoLossService.loadAutoLosses(
                _get(policyChangeVM, 'jobID.value'),
                authHeader
            );

            AutoLossRecordsPromise
                .then((response) => {
                    const autoLossRecords = [];

                    response.autoLossRecords.filter((record)=>record.source.sourceType !== 'Self-Declared').forEach((result) => {
                        const recordVM = createAutoLossVM(
                            result,
                            'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoLossRecordDTO'
                        );

                        autoLossRecords.push(recordVM.value);
                    });

                    const autoViolationRecords = [];

                    response.autoViolationRecords.filter((record)=>record.source.sourceType !== 'Self-Declared')
                        .forEach((result) => {
                            const violationVM = createAutoLossVM(
                                result,
                                'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoViolationRecordDTO'
                            );

                            _set(
                                violationVM,
                                'value.operatorDisplayName',
                                `${violationVM?.value?.assignment?.firstName} ${violationVM?.value?.assignment?.lastName}`
                            );
                            autoViolationRecords.push(violationVM.value);
                        });

                    _set(dataForComponent, 'lobData.personalAuto_EA.autoLossRecords.value', autoLossRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.autoViolationRecords.value', autoViolationRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.mvrLicenseStatusRecords.value', response.mvrlicenseStatus);
                    _set(dataForComponent, 'lobData.personalAuto_EA.orderingInfo.value', response.orderingInfo);
                    updateDataForComponent(dataForComponent);
                })
                .catch(() => {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: messages.reportsErrorTitle,
                        message: messages.reportsErrorMessage
                    });
                    setIsLossReportNoHit(true);
                })
                .finally(() => {
                    setIsRetrievingReports(false);
                });
        }
        // No array dependencies needed in this hook.
        // The logic of initializing losses data needs to be executed only once
        // when landing into losses page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping]);

    useEffect(() => {
        // register validation for disabling next button
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, registerInitialComponentValidation, policyChangeVM]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(
        () => {
            let loadingMessage = '';

            if (isRetrievingReports) {
                loadingMessage = translator(messages.loading);
            } else if (isSavingCurrentPageChanges) {
                loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
            } else if (isSavingEndorsement) {
                loadingMessage = translator(messages.ratingYourPolicyMessage);
            } else if (isSkipping) {
                loadingMessage = translator(messages.loadingNextPageMessage);
            }

            return loadingMessage;
        },
        [
            isRetrievingReports, isSavingCurrentPageChanges,
            isSavingEndorsement, isSkipping, translator
        ]
    );

    const onChangePrayerForJudgement = useCallback(() => {
        // Get all manual added violations
        const manualViolationRecords = _get(policyChangeVM, 'lobData.personalAuto_EA.manualViolationRecords.value', []);
        const selectedPrayerForJudgementOperators = [];

        manualViolationRecords.forEach((violationRecord) => {
            // Add to selected driver list when prayer for judgement is selected
            if (_get(violationRecord, 'assignment.publicId') && violationRecord.prayerForJudgementInd) {
                selectedPrayerForJudgementOperators.push(_get(violationRecord, 'assignment.publicId'));
            }
        });
        // Show error message if driver with prayer for judgement indicator appears more than one time
        setShowPrayerForJudgementError(selectedPrayerForJudgementOperators.length !== _uniq(selectedPrayerForJudgementOperators).length);
    }, [policyChangeVM, setShowPrayerForJudgementError]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        riskAnalysisPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
            text: getLoadingIndicatorMessage
        },
        riskAnalysisPageContainer: {
            defaultActiveTab: getDefaultActiveTab,
            visible: !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            activeTab: activeTab !== '' ? activeTab : getDefaultActiveTab
        },
        EAPriorCarrierComponentId: {
            submissionVM: policyChangeVM,
            disregardFieldValidation,
            setPriorCarrierChanged,
            viewOnlyMode: !isPeriodStartSameAsPCEffectivedate,
            showPriorCarrierEffectiveDateMessage: !isPeriodStartSameAsPCEffectivedate
        },
        EALossAndViolationComponentId: {
            lobDataModel: policyChangeVM.lobData.personalAuto_EA,
            onModelChange: () => updateWizardData(policyChangeVM),
            viewModelService,
            disregardFieldValidation,
            operators: policyChangeVM.lobData.personalAuto_EA.coverables.drivers?.value?.map((driver) => ({
                    publicId: driver.publicID,
                    displayName: driver.person.displayName
                })),
            viewOnlyMode: false,
            showErrors: isPageSubmitted,
            authUserData,
            isVerified: true,
            policyState,
            periodStartDate: policyChangeVM.baseData.periodStartDate,
            showPrayerForJudgementError,
            onChangePrayerForJudgement,
            updateIsPageSubmitted
        },
        EARiskAnalysisMiscComponentId: {
            submissionVM: policyChangeVM,
            viewOnlyMode: true,
            isSkipping,
            onValidate,
            shouldShowCedingField: policyState === 'NC'
        },
        EACreditReportsComponentId: {
            submissionVM: policyChangeVM,
            creditReportResponse,
            setCreditReportResponse,
            updateWizardData,
            lineOfBusiness: policyChangeVM.lobData.personalAuto_EA,
            viewOnlyMode: false,
            lobName: 'personalAuto_EA',
            authUserData,
            id: 'EACreditReportsComponentId',
            onValidate,
            setOverlappingException
        },
        vehicleReportTabID: {
            viewOnlyMode: true
        },
        lossesAndViolationID: {
            visible: !_isEmpty(dataForComponent),
            lossesAndViolations: dataForComponent,
            updateBaseDataComponent: updateDataForComponent,
            quoteId: _get(policyChangeVM, 'jobID.value'),
            drivers: policyChangeVM.lobData.personalAuto_EA.coverables.drivers,
            policyState,
            accountHolderName: _get(policyChangeVM, 'baseData.accountHolder_Ext.displayName.value'),
            jobType: _get(policyChangeVM, 'value.baseData.jobType')
        },
        EAUWIssuesComponentId: {
            submissionVM: policyChangeVM,
            updateWizardData,
            authHeader
        },
        billingMiscellaneousReportComponent: {
            visible: authUserData?.permissions_Ext.includes('canviewbillingreport'),
            data: _get(policyChangeVM,'value.baseData.cancelActivities_Ext',[])
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(policyChangeVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        },
        declareStatementThreeYears: {
            visible: visibleFields.includes('declareLossStatementThreeYears')
        },
        declareStatementFiveYears: {
            visible: visibleFields.includes('declareLossStatementFiveYears')
        },
        noMiscReportResultsMessageId: {
            visible: (() => 
                // If misc report content is empty 
                 !_get(document.getElementById('miscellaneousReportsBodyId'), 'innerText') 
                    && !_get(document.getElementById('billingMiscellaneousReportComponent'), 'innerText')
            )()
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showCustom={showCustom}
            onSave={onSave}
            showOnSave
            onCustom={onCustom}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
