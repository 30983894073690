import React, { useContext, useEffect, useState, useCallback } from 'react';
import {
    set as _set,
    isEmpty as _isEmpty,
    noop as _noop,
    get as _get,
} from 'lodash';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import PropTypes from 'prop-types';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { RVPService } from 'e1p-capability-gateway';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import metadata from './RVPSection.metadata.json5';

function RVPSection (props) {
    const {
        submissionVM,
        updateWizardData,
        viewOnlyMode,
        onValidate,
        reportOrder,
        setIsUWIssueApprovedAndPolicyQuoted,
        isUWIssueApprovedAndPolicyQuoted,
    } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { onValidate: setComponentValidation, isComponentValid } =
        useValidation('RVPSectionComponent');
    const { authHeader } = useAuthentication();
    const [rvpSectionVM, setRVPSectionVM] = useState(undefined);
    const viewModelService = useContext(ViewModelServiceContext);
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [failedCall, setFailedCall] = useState(false);
    const [acknowledged, setAcknowledged] = useState(false);
    const isAcknowledged = rvpSectionVM?.isAcknowledged?.value;
    const jobNumber =
        _get(submissionVM, 'quoteID.value') ??
        _get(submissionVM, 'jobID.value');
    const isPolicyBounded =
        _get(submissionVM, 'baseData.periodStatus.value.code') === 'Bound';
    /* empty params means when the page loads for the first time */

    const checkValidationCheckbox = useCallback(() => {
        if (rvpSectionVM?.targetGroup?.value === 'MEDIUM') {
            return isAcknowledged;
        }

        return true;
    }, [isAcknowledged, rvpSectionVM?.targetGroup?.value]);

    useEffect(() => {
        if (onValidate) {
            onValidate(
                isComponentValid && checkValidationCheckbox(),
                'RVPSectionComponent'
            );
        }
    }, [
        onValidate,
        isComponentValid,
        isAcknowledged,
        setComponentValidation,
        checkValidationCheckbox,
    ]);

    useEffect(() => {
        let isMounted = true;

        RVPService.retrieveReport(jobNumber, authHeader).then((response) => {
            if(isMounted){
                if (response !== undefined) {
                    const RVPVM = viewModelService.create(
                        response[0],
                        'pc',
                        'amfam.edge.capabilities.policyjob.lob.ea.dto.RVPReportDTO'
                    );

                    setRVPSectionVM(RVPVM);
                    setPageInitialized(true);
                }
            }
        }).catch(() => {
            setPageInitialized(true);
            setFailedCall(true);
        });

        return () => {
            isMounted = false;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (newVal, path) => {
            _set(rvpSectionVM, `${path}.value`, newVal);

            const newRVPSectionVM = viewModelService.clone(rvpSectionVM);

            setRVPSectionVM(newRVPSectionVM);
        },
        [rvpSectionVM, viewModelService]
    );

    useEffect(() => {
        if (setComponentValidation) {
            if (acknowledged) {
                if (rvpSectionVM?.targetGroup?.value === 'MEDIUM') {
                    if (rvpSectionVM?.isAcknowledged?.value === true) {
                        setComponentValidation(true);

                        return;
                    }

                    setComponentValidation(false);

                    return;
                }
            }

            if (
                rvpSectionVM?.targetGroup?.value &&
                rvpSectionVM?.targetGroup?.value !== 'MEDIUM'
            ) {
                setComponentValidation(true);

                return;
            }

            setComponentValidation(false);
        }
    }, [isAcknowledged, rvpSectionVM, acknowledged, setComponentValidation]);

    const changeGuidelinesValue = useCallback(
        value => {
            writeValue(value, 'isAcknowledged');

            if (rvpSectionVM?.isAcknowledged?.value) {
                setAcknowledged(true);
                setIsUWIssueApprovedAndPolicyQuoted(true);
                // calling saveAndQuote in case user is going to payment page from EA Report
                // page, after approving the RVP UW Issue. So that, periodStatus will change to quoted.
                RVPService.approveUWIssue(jobNumber, authHeader)
                    .then(response => {
                        if (!_isEmpty(response)) {
                            _set(
                                submissionVM,
                                'errorsAndWarnings.underwritingIssues.value',
                                response
                            );

                            if (reportOrder) {
                                LoadSaveService.saveAndQuoteSubmission(
                                    submissionVM.value,
                                    authHeader
                                )
                                    .then(resp => {
                                        _set(submissionVM, 'value', resp);
                                        updateWizardData(submissionVM);
                                        setIsUWIssueApprovedAndPolicyQuoted(
                                            false
                                        );
                                    })
                                    .catch(_noop());
                            } else {
                                setIsUWIssueApprovedAndPolicyQuoted(false);
                            }

                            updateWizardData(submissionVM);
                        } else {
                            setIsUWIssueApprovedAndPolicyQuoted(false);
                        }
                    })
                    .catch(_noop());
            }
        },
        [
            writeValue,
            rvpSectionVM,
            setIsUWIssueApprovedAndPolicyQuoted,
            jobNumber,
            authHeader,
            submissionVM,
            reportOrder,
            updateWizardData,
            LoadSaveService,
        ]
    );

    const shouldNotVisible = useCallback(() => {
        if (
            isPolicyBounded &&
            rvpSectionVM?.targetGroup?.value === 'MEDIUM' &&
            !viewOnlyMode
        ) {
            return true;
        }

        return false;
    }, [isPolicyBounded, rvpSectionVM, viewOnlyMode]);

    const shouldNotVisibleThankYou = useCallback(() => {
        if (
            isPolicyBounded &&
            (rvpSectionVM?.targetGroup?.value === 'LOW' ||
                rvpSectionVM?.targetGroup?.value === 'HIGH') &&
            !viewOnlyMode
        ) {
            return true;
        }

        return false;
    }, [isPolicyBounded, rvpSectionVM, viewOnlyMode]);

    const absoluteShouldNotDisplayLow = useCallback(() => {
        if (rvpSectionVM?.targetGroup?.value === 'LOW') {
            return true;
        }

        return false;
    }, [rvpSectionVM]);

    const emptyResponseNotVisible = useCallback(() => {
        if (!rvpSectionVM?.targetGroup?.value) {
            return true;
        }

        return false;
    }, [rvpSectionVM]);

    const sectionLabelVisible = useCallback(() => (
            !shouldNotVisible() &&
            !emptyResponseNotVisible() &&
            !shouldNotVisibleThankYou() &&
            !absoluteShouldNotDisplayLow()
        ), [
        absoluteShouldNotDisplayLow,
        emptyResponseNotVisible,
        shouldNotVisible,
        shouldNotVisibleThankYou,
    ]);

    const sectionContainerVisible = useCallback(() => (
            !failedCall &&
            !emptyResponseNotVisible() &&
            !shouldNotVisibleThankYou() &&
            !absoluteShouldNotDisplayLow()
        ), [
        absoluteShouldNotDisplayLow,
        emptyResponseNotVisible,
        failedCall,
        shouldNotVisibleThankYou,
    ]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            readOnly: true,
            autoComplete: false,
        },
        rvpSectionPageLoader: {
            loaded: !isUWIssueApprovedAndPolicyQuoted,
        },
        rvpSectionPage: {
            visible: !isUWIssueApprovedAndPolicyQuoted,
        },
        FailedCallDiv: {
            visible: failedCall,
        },
        rvpSectionLabel: {
            visible: sectionLabelVisible(),
        },
        RVPSectionContainer: {
            visible: sectionContainerVisible(),
        },
        AgreementCheckboxID: {
            onValueChange: changeGuidelinesValue,
            value: isPolicyBounded ? true : rvpSectionVM?.isAcknowledged?.value,
            readOnly: isPolicyBounded,
            visible: rvpSectionVM?.targetGroup?.value === 'MEDIUM',
        },
        RVPTargetScore: {
            visible: !shouldNotVisible(),
        },
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                rvpSectionVM,
                id,
                path,
                overrideProps
            ),
        [rvpSectionVM, overrideProps]
    );

    if (!isPageInitialized) {
        return <E1PLoader loaded={isPageInitialized} />;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={rvpSectionVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={writeValue}
            resolveValue={readValue}
        />
    );
}

RVPSection.defaultProps = {
    viewOnlyMode: false,
    onValidate: undefined,
    reportOrder: false,
    setIsUWIssueApprovedAndPolicyQuoted: () => {},
    isUWIssueApprovedAndPolicyQuoted: false,
};

RVPSection.propTypes = {
    onValidate: PropTypes.func,
    viewOnlyMode: PropTypes.bool,
    submissionVM: PropTypes.shape({
        value: PropTypes.shape({}),
    }).isRequired,
    updateWizardData: PropTypes.func,
    reportOrder: PropTypes.bool,
    setIsUWIssueApprovedAndPolicyQuoted: PropTypes.func,
    isUWIssueApprovedAndPolicyQuoted: PropTypes.bool,
};

RVPSection.defaultProps = {
    updateWizardData: undefined
}
export default RVPSection;
