import React, { useCallback, useContext, useState } from 'react';
import {
    get as _get,
    noop as _noop,
    pullAt as _pullAt,
    set as _set,
    isEmpty as _isEmpty,
    trim as _trim
} from 'lodash';
import {
    E1PEUTPISearchDetailComponent
} from 'e1p-capability-policyjob-react';
import { useModal } from '@jutro/components';
import { TPIUtil, ConsoleHelper } from 'e1p-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTPIUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import PropTypes from 'prop-types';
import metadata from './E1PEUTPIDisplayTableComponent.metadata.json5';
import styles from './E1PEUTPIDisplayTableComponent.module.scss';

/**
 * This component file models the TPI table which appears on the EU "Third-Party Interest" page.
 *
 * @param {Object} props An object containing the properties passed into this component.
 * @returns {Object} An object containing the data required for rendering this component.
 */
function E1PEUTPIDisplayTableComponent(props) {
    const modalApi = useModal();
    const {
        transactionVM,
        authHeader,
        updateWizardData,
        viewOnlyMode,
        showErrors,
        onValidate,
        disregardFieldValidationParentPage,
        setIsAddingTPI,
        showTitle
    } = props;
    const { handleTPITableOnCell, updateNewlyAddedTPI } = useTPIUtil();
    const productCode = _get(transactionVM, 'baseData.productCode.value', _get(transactionVM, 'productCode.value'));
    const tpiBasePath = TPIUtil.getTPIBasePath(productCode);
    const [isSearchTPIVisible, updateIsSearchTPIVisible] = useState(false);
    const [isSavingTPI, setIsSavingTPI] = useState(false);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);

    /**
     * Helper callback for showing the appropriate TPI detail component.
     */
    const showTPIDetailComponent = useCallback(
        async (addlInterestTPIVM) => {
            const componentProps = {
                viewModelService,
                tpiDetailVM: addlInterestTPIVM,
                isControlledTPI: _get(addlInterestTPIVM, 'tpiid.value') !== undefined,
                isTPIContactTypeCompany: _get(addlInterestTPIVM, 'company.value') !== undefined,
                isAddingNewTPI: false,
                transactionVM,
                tpiBasePath,
                showPopup: true,
                authHeader,
                isEditingTPI: true,
                disregardFieldValidationParentPage
            };
            const result = await modalApi.showModal(
                <E1PEUTPISearchDetailComponent {...componentProps} />
            );

            return result;
        },
        [authHeader, disregardFieldValidationParentPage, modalApi, tpiBasePath, transactionVM, viewModelService]
    );

    /**
     * Helper callback for handling opening/closure of the Third Party Search modal component.
     */
    const openTPISearchModal = useCallback(
        (tpiObj, index) => {
            // show modal popover and pass the tpiVM to edit the tpi
            const addlInterestTPIVM = viewModelService.create(
                tpiObj,
                'pc',
                'amfam.edge.capabilities.policyjob.lob.eu.dto.EULineAddlInterestDTO'
            );

            try {
                showTPIDetailComponent(addlInterestTPIVM)
                    .then((tpiDetailObj) => {
                        // Now add the tpi detail to the "transactionVM"
                        const allTPIs = _get(transactionVM.value, tpiBasePath);

                        // Remove from the index
                        _pullAt(allTPIs, index);
                        // Add to the TPI array
                        allTPIs.push(tpiDetailObj);
                        _set(transactionVM.value, tpiBasePath, allTPIs);
                        updateWizardData(transactionVM);
                    })
                    .catch();
            } catch {
                ConsoleHelper('user closed the modal');
            }
        },
        [showTPIDetailComponent, transactionVM, tpiBasePath, updateWizardData, viewModelService]
    );

    /**
     * Helper function for generating the TPI name.
     *
     * @param {Object} item An object containing the set of VMNode properties related to the table.=
     * @returns {Object} TPI name cell.
     */
    const getTPIName = (item) => {
        let tpiName = '';

        if (item.bankPrimaryName !== undefined) {
            tpiName = item.bankPrimaryName;

            // IAP-1709 : Display full name for searched tpis
            if (!_isEmpty(_trim(item.bankSecondaryName))) {
                tpiName = tpiName.concat(' ', item.bankSecondaryName);
            }

            // IAP-3482 : Display full name for searched tpis
            if (!_isEmpty(_trim(item.bankTertiaryName))) {
                tpiName = tpiName.concat(' ', item.bankTertiaryName);
            }
        } else if (item.company !== undefined) {
            tpiName = item.company.name;
        } else if (item.person !== undefined) {
            tpiName = item.person.firstName.concat(' ', item.person.lastName);
        }

        return tpiName;
    };

    const updateNewlyAddedTPIAndUpdateWizardData = useCallback(
        async (tpiDetailObj) => {

            // Now add the tpi detail to the "transactionVM"
            setIsSavingTPI(true);

            const allTPIs = _get(transactionVM, `${tpiBasePath}.value`, []);

            allTPIs.push(tpiDetailObj);
            _set(transactionVM, `${tpiBasePath}.value`, allTPIs);

            const updateDraftResponse = await updateNewlyAddedTPI(
                transactionVM.value, authHeader
            );

            _set(transactionVM, 'value', updateDraftResponse);
            updateWizardData(transactionVM);
            setIsSavingTPI(false);
            updateIsSearchTPIVisible(false);

            if (setIsAddingTPI) {
                setIsAddingTPI(false);
            }
        },
        [
            authHeader, setIsAddingTPI, setIsSavingTPI, tpiBasePath,
            transactionVM, updateNewlyAddedTPI, updateWizardData
        ]
    );

    /**
     * Helper callback for removing a TPI record from the TPI table.
     */
    const removeTPIRecord = useCallback(
        (rowToRemove) => {
            const allTPIs = _get(transactionVM, tpiBasePath);

            if (allTPIs.value.length > 0) {
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: e1pCommonMessages.removeTPITitle,
                    message: e1pCommonMessages.removeTPIDescription,
                    confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'THIRD-PARTY INTEREST' }),
                    cancelButtonText: e1pCommonMessages.cancel
                }).then((results) => {
                    if (results !== 'cancel') {
                        _pullAt(allTPIs.value, rowToRemove);
                        _set(transactionVM, `${tpiBasePath}.value`, allTPIs.value);
                        updateWizardData(transactionVM);

                        return transactionVM;
                    }

                    return _noop();
                });
            }
        },
        [transactionVM, tpiBasePath, modalApi, translator, updateWizardData]
    );

    /**
     * Helper function for handling when the "Remove Third-Party Interest" button is clicked.
     *
     * @param {*} item An object containing the set of VMNode properties related to the table row.
     * @param {*} rowToRemove A number representing the currently selected table row.
     */
    const removeTPIButtonOnClickHandler = (item, rowToRemove) => {
        removeTPIRecord(rowToRemove);
    };

    /**
     * Helper function for handling when edit tpi option is clicked
     * 
     * @param {*} item An object containing the set of VMNode properties related to the table row.
     * @param {*} rowToEdit A number representing the currently selected table row.
     */
    const editTPIButtonOnClickHandler = (item, rowToEdit) => {
        openTPISearchModal(item, rowToEdit)
    }

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'top',
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        loader: {
            loaded: !isSavingTPI,
            text: translator(e1pCommonMessages.savingTPI)
        },
        euTPIDisplayTableAndSearchComponent: {
            visible: !isSavingTPI
        },
        tpiTableActions: {
            visible: !viewOnlyMode
        },
        euTPIDisplayContainer: {
            visible: !isSearchTPIVisible
        },
        noTpiHasBeenAdded: {
            visible: _isEmpty(_get(transactionVM, `${tpiBasePath}.value`, []))
        },
        tpiContainer: {
            visible: !_isEmpty(_get(transactionVM, `${tpiBasePath}.value`, []))
        },
        addTPIComponentContainer: {
            visible: !viewOnlyMode
        },
        thirdPartyInterestTitle: {
            visible: showTitle
        },
        searchTPI: {
            onClick: (() => {
                updateIsSearchTPIVisible(true);

                if (setIsAddingTPI) {
                    setIsAddingTPI(true);
                }
            })
        },
        tpiSearchComponent: {
            visible: isSearchTPIVisible,
            productCode,
            tpiBasePath,
            viewModelService,
            authHeader,
            transactionVM,
            isAddingExistingContact: false,
            existingContact: false,
            isPerson: false,
            updateIsSearchTPIVisible,
            saveThirdPartyInterestClickHandler: updateNewlyAddedTPIAndUpdateWizardData,
            showErrors,
            onValidate,
            disregardFieldValidationParentPage,
            setIsAddingTPI
        }
    };

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            getTPIName,
            handleTPITableOnCell,
            removeTPIButtonOnClickHandler,
            editTPIButtonOnClickHandler
        },
        resolveClassNameMap: styles
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

/**
 * Define expected types for properties to be passed into this Jutro component.
 */
E1PEUTPIDisplayTableComponent.propTypes = {
    transactionVM: PropTypes.shape({
        lobData: PropTypes.shape({}),
        baseData: PropTypes.shape({
            policyAddress: PropTypes.shape({
                state: PropTypes.shape({
                    value: PropTypes.shape({
                        code: PropTypes.string
                    })
                })
            })
        }),
        value: PropTypes.shape({})
    }),
    authHeader: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    showErrors: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
    disregardFieldValidationParentPage: PropTypes.func.isRequired,
    setIsAddingTPI: PropTypes.func,
    showTitle: PropTypes.bool
};

/**
 * Define default values for properties to be passed into this Jutro component.
 */
E1PEUTPIDisplayTableComponent.defaultProps = {
    transactionVM: {},
    viewOnlyMode: false,
    setIsAddingTPI: undefined,
    showTitle: true
};

export default E1PEUTPIDisplayTableComponent;
