
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    get as _get,
    set as _set,
    filter as _filter,
    find as _find,
    isEmpty as _isEmpty
} from 'lodash';
import {
    ModalNext, ModalHeader, ModalBody, ModalFooter, useModal
} from '@jutro/components';
import { TPIUtil, PersonNameValidator } from 'e1p-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAddressStandardization, e1pUSStatesUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { E1PContactsFoundComponent } from "e1p-capability-policyjob-react";
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import messages from './E1PEUTPISearchDetailComponent.messages';
import metadata from './E1PEUTPISearchDetailComponent.metadata.json5';
import styles from './E1PEUTPISearchDetailComponent.module.scss';

/**
 * This component file models the EU TPI input panel on the lower section of the "TPI Search" popup.
 *
 * @param {Object} props An object containing the properties passed into this component.
 * @returns {Object} An object containing the data required for rendering this component.
 */
const E1PEUTPISearchDetailComponent = (props) => {
    const modalApi = useModal();
    const {
        id,
        tpiDetailVM,
        viewModelService,
        onResolve,
        isControlledTPI,
        transactionVM,
        tpiBasePath,
        isTPIContactTypeCompany,
        isAddingNewTPI,
        authHeader,
        isOpen,
        showPopup,
        saveThirdPartyInterestClickHandler,
        isEditingTPI,
        showErrors,
        onValidate,
        disregardFieldValidationParentPage,
        updateIsSearchTPIVisible,
        updateAndShowWarning,
        setIsAddingTPI,
        isTPIFromSearchResult,
        onReject
    } = props;
    const [tempTPIDetailVM, setTempTPIDetailVM] = useState(tpiDetailVM);
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [addressStandardizationResultDTO, updateAddressStandardizationResultDTO] = useState(
        undefined
    );
    const [enteredStateForAddressStandardization, updateEnteredState] = useState(undefined);
    const [isSearchingContacts, setIsSearchingContacts] = useState(false);
    const [oldTPIAddress, setOldTPIAddress] = useState({});
    const { getStandardizedAddress, standardizeAddressIfApplicable } = useAddressStandardization(
        viewModelService,
        authHeader
    );
    const { onValidate: setComponentValidation, isComponentValid, disregardFieldValidation } = useValidation(id);
    const translator = useTranslator();

    const ADDL_INTEREST_TYPE_TL = viewModelService.productMetadata
        .get('pc')
        .types.getTypelist('AdditionalInterestType');
    // state in Address DTO for TPI is String, but we need to show states as dropdown
    const [USStates, setUSStates] = useState([]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, isComponentValid, onValidate]);

    useEffect(() => {
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);

    useEffect(() => {
        setTempTPIDetailVM(tpiDetailVM);
    }, [tpiDetailVM]);

    useEffect(() => {
        const USStatesTypekey = e1pUSStatesUtil.getUSStates(viewModelService);
        const states = e1pUSStatesUtil.getStateValues(USStatesTypekey, translator);

        setUSStates(states);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for writing values to the view model.
     */
    const writeValue = useCallback(
        (value, path) => {
            /**
           * We are updating standardized address on saveTPICall 
           * And as state update is async, updating tpiDetailsVM object value
           */
            _set(tempTPIDetailVM, path, value);

            const nextFormData = viewModelService.clone(tempTPIDetailVM);

            _set(nextFormData, path, value);
            setTempTPIDetailVM(nextFormData);
        },
        [tempTPIDetailVM, viewModelService]
    );

    const onSelectAddress = useCallback(
        (addressToUse) => {
            const addressVM = tempTPIDetailVM.address;

            addressVM.value = {
                ...addressVM.value,
                address1: _get(addressToUse, 'addressLine1', _get(addressToUse, 'address1')),
                address2: _get(addressToUse, 'addressLine2', _get(addressToUse, 'address2')),
                city: addressToUse.city,
                postalCode: addressToUse.postalCode,
                state: addressToUse.state,
                country: 'US'
            };
            writeValue(addressVM.value, 'address.value');
            updateEnteredState(undefined);
            updateAddressStandardizationResultDTO(undefined);
        },
        [tempTPIDetailVM, writeValue]
    );

    const handleAddressBlur = useCallback(
        async (_, { value, beforeValue }) => {
            if (value === beforeValue) {
                return;
            }

            const addressVM = _get(tempTPIDetailVM, 'address');

            // set addressline1 and addressLine2, since the addressStandardization needs it
            // and we dont have addressline1 and addressLine2 in addressDTO, we have address1 and address2
            _set(addressVM, 'addressLine1', tempTPIDetailVM.address.address1);
            _set(addressVM, 'addressLine2', tempTPIDetailVM.address.address2);
            updateAddressStandardizationResultDTO(undefined);

            if (
                _get(addressVM, 'addressLine1.value')
                && _get(addressVM, 'city.value')
                && _get(addressVM, 'state.value')
                && _get(addressVM, 'postalCode.value')
            ) {
                setIsStandardizingAddress(true);

                const standardizedAddressResult = await getStandardizedAddress(addressVM, false);

                if (!standardizedAddressResult?.standardizationChoices) {
                    setIsStandardizingAddress(false);

                    return;
                }

                const allExactMatchAddresses = standardizedAddressResult.standardizationChoices
                    .filter((choice) => choice.isExactMatch);

                // if only one exact match then map it directly otherwise show the popup
                if (allExactMatchAddresses?.length === 1) {
                    const addressToUse = {
                        addressLine1: allExactMatchAddresses[0].addressLine1,
                        addressLine2: allExactMatchAddresses[0].addressLine2 ? allExactMatchAddresses[0].addressLine2 : '',
                        city: allExactMatchAddresses[0].city,
                        postalCode: allExactMatchAddresses[0].postalCode,
                        state: allExactMatchAddresses[0].state,
                        country: 'US'
                    };

                    onSelectAddress(addressToUse);
                    updateAddressStandardizationResultDTO(undefined);
                } else {
                    const address = addressVM.value;
                    const enteredAddress = { ...address };

                    _set(enteredAddress, 'tempID', address.state);
                    _set(
                        enteredAddress,
                        'formattedDisplayName',
                        `${address.address1}, ${address.address2}, ${address.city}, ${address.state}, ${address.postalCode}`
                    );
                    updateEnteredState(enteredAddress);
                    updateAddressStandardizationResultDTO(standardizedAddressResult);
                }

                setIsStandardizingAddress(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [tempTPIDetailVM?.address, getStandardizedAddress, onSelectAddress]
    );

    /**
     * Helper callback for retrieving the "AdditionalInterestType" typelist values for EU.
     */
    const getAddlInterestTypeCodesForEU = useCallback(() => {
        const addlInterestTypeCodes = [];

        addlInterestTypeCodes.push(
            _find(ADDL_INTEREST_TYPE_TL.codes, {
                code: 'AdditionalInsured_Ext'
            })
        );

        const policyStateCode = _get(transactionVM, 'baseData.policyAddress.state.value.code');

        if (policyStateCode === 'NJ') {
            addlInterestTypeCodes.push(
                _find(ADDL_INTEREST_TYPE_TL.codes, {
                    code: 'AdditionalDesignee_Ext'
                })
            );
        }

        const publicID = _get(tpiDetailVM, 'value.person')
            ? _get(tpiDetailVM, 'value.person.publicID')
            : _get(tpiDetailVM, 'value.company.publicID');

        const filterredCodes = TPIUtil.filterInterestTypesForGivenUser(
            addlInterestTypeCodes,
            transactionVM,
            tpiBasePath,
            publicID
        );

        // update addlInterestTypeCodes with interestCodes which are present for given user
        addlInterestTypeCodes.splice(0, addlInterestTypeCodes.length);
        addlInterestTypeCodes.push(...filterredCodes);

        const availableValues = addlInterestTypeCodes.map((typeCode) => ({
                code: typeCode.code,
                name: translator({ id: typeCode.name })
            }));

        return availableValues;
    }, [
        ADDL_INTEREST_TYPE_TL.codes,
        tpiBasePath,
        tpiDetailVM,
        transactionVM,
        translator
    ]);

    /**
       * Helper callback for filtering the "AdditionalInterestType" typelist values based on whether
       * they're already in use on the policy.
       */
    const filterAddlInterestTypeCodes = useCallback(
        (initialAddlInterestTypeCodes) => {
            const allExistingTPIsOnPolicy = _get(transactionVM, `${tpiBasePath}.value`, []);
            const alreadyUsedAddlInterestTypeCodes = allExistingTPIsOnPolicy.map((item) => item.addlInterestType);
            const filteredAddlInterestTypeCodes = _filter(
                initialAddlInterestTypeCodes,
                (typeCode) => {
                    const isTypeCodeAvailable = alreadyUsedAddlInterestTypeCodes.indexOf(typeCode.code) === -1;
                    const canTypeCodeHaveMultipleOccurrences = !typeCode.code.includes('MORTGAGEE');

                    return isTypeCodeAvailable || canTypeCodeHaveMultipleOccurrences;
                }
            );

            return filteredAddlInterestTypeCodes;
        },
        [tpiBasePath, transactionVM]
    );

    const getAddlInterestTypeCodes = useMemo(() => {
        const addlInterestTypesForPerson = ['AdditionalInsured_Ext', 'AdditionalInterest_Ext', 'Titleholder_Ext', 'AdditionalDesignee_Ext'];
        let addlInterestTypeCodes = [];

        addlInterestTypeCodes = getAddlInterestTypeCodesForEU();
        addlInterestTypeCodes = filterAddlInterestTypeCodes(addlInterestTypeCodes);

        const currentTpisInterestType = _find(ADDL_INTEREST_TYPE_TL.codes, {
            code: _get(tpiDetailVM, 'value.addlInterestType')
        });

        if (!isTPIContactTypeCompany) {
            addlInterestTypeCodes = addlInterestTypeCodes.filter(
                (addlInterestTypeCode) => addlInterestTypesForPerson.includes(addlInterestTypeCode.code)
            );
        }

        // in interest type dropdown given users interest type should be
        // present to display it in edit mode
        addlInterestTypeCodes.push({
            code: currentTpisInterestType?.code,
            name: translator({ id: currentTpisInterestType?.name })
        });

        return addlInterestTypeCodes;
    }, [
        getAddlInterestTypeCodesForEU, filterAddlInterestTypeCodes,
        ADDL_INTEREST_TYPE_TL.codes, tpiDetailVM, isTPIContactTypeCompany,
        translator
    ]);

    const { formattedBankName, formattedAddress } = useMemo(() => ({
            formattedBankName: TPIUtil.getFormattedTPIBankName(_get(tempTPIDetailVM, 'value')),
            formattedAddress: TPIUtil.getFormattedTPIAddress(_get(tempTPIDetailVM, 'value'))
        }), [tempTPIDetailVM]);

    const showContactsModal = useCallback(async (contactRecords) => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            contactRecords
        };

        return modalApi.showModal(<E1PContactsFoundComponent {...componentProps} />);
    }, [modalApi]);

    const onAddressChange = useCallback((value, path) => {
        let actualPath = path;
        const actualValue = value;

        if (path === 'address.addressLine1') {
            actualPath = 'address.address1'
        } else if (path === 'address.addressLine2') {
            actualPath = 'address.address2'
        }
        else if (path === 'address.value') {
            if (value.addressLine1) {
                _set(actualValue, 'address1', value.addressLine1);
            }

            if (value.addressLine2) {
                _set(actualValue, 'address2', value.addressLine2);
            }
        }

        writeValue(actualValue, actualPath)
    }, [writeValue]);

    const saveThirdPartyInterest = useCallback(async () => {
        if (!tempTPIDetailVM) {
            updateAndShowWarning(e1pCommonMessages.selectAThirdPartyError);

            return false;
        }

        if (!isComponentValid) {
            updateIsPageSubmitted(true);

            return false;
        }

        /**
         * If user adds new uncontrolled tpi or choose existing uncontrolled tpi
         * we will standardize the address before saving tpi
         */
        if ((isAddingNewTPI || !isControlledTPI) && !isEditingTPI) {
            setIsStandardizingAddress(true);

            const isAddressStandardized = await standardizeAddressIfApplicable(
                oldTPIAddress,
                tempTPIDetailVM.address,
                onAddressChange,
                'address.value'
            );

            setIsStandardizingAddress(false);

            if (!isAddressStandardized) {
                // address is not standardized
                return false;
            }

            setOldTPIAddress(tempTPIDetailVM.address.value);
        }

        if (isAddingNewTPI) {
            const address = tempTPIDetailVM.address.value;

            address.addressLine1 = address.address1;
            address.addressLine2 = address.address2;

            if (isTPIContactTypeCompany) {
                _set(tempTPIDetailVM, 'value.company.primaryAddress', address);
            } else {
                _set(tempTPIDetailVM, 'value.person.primaryAddress', address);
            }

            setIsSearchingContacts(true);

            let contacts = [];

            try {
                contacts = await TPIUtil.searchContacts(
                    _get(tempTPIDetailVM, 'value'),
                    _get(transactionVM, 'value.baseData.accountHolder.accountNumber'),
                    authHeader
                );
            } catch (error) {
                // some error occurred while searching contacts
                return false;
            } finally {
                setIsSearchingContacts(false);
            }

            try {
                if (!_isEmpty(contacts)) {
                    const { chosenContact } = await showContactsModal(contacts);

                    // if subtype is present it means user has selected an existing contact
                    // if its not present user has clicked on create new contact
                    if (chosenContact.contactSubtype) {
                        TPIUtil.mapChosenContactToTPIContact(_get(tempTPIDetailVM, 'value'), chosenContact);
                    }
                }
            } catch {
                // user clicked on cancel and did not choose any contact
                return false
            }
        }

        disregardFieldValidation(id);
        disregardFieldValidationParentPage(id);

        if (showPopup) {
            return onResolve(tempTPIDetailVM.value);
        }

        return  saveThirdPartyInterestClickHandler(tempTPIDetailVM.value);
    }, [
        tempTPIDetailVM, isComponentValid, isAddingNewTPI, disregardFieldValidation, id,
        disregardFieldValidationParentPage, showPopup, saveThirdPartyInterestClickHandler,
        updateAndShowWarning, standardizeAddressIfApplicable, oldTPIAddress, onAddressChange,
        isTPIContactTypeCompany, transactionVM, authHeader, showContactsModal, onResolve,
        isEditingTPI, isControlledTPI
    ]);

    const onCancel = useCallback(() => {
        if (setIsAddingTPI) {
            setIsAddingTPI(false);
        }

        disregardFieldValidation(id);
        disregardFieldValidationParentPage(id);

        if (showPopup) {
            return onReject();
        }

        updateIsSearchTPIVisible(false);
    }, [
        disregardFieldValidation, disregardFieldValidationParentPage,
        id, onReject, showPopup, updateIsSearchTPIVisible, setIsAddingTPI
    ]);

    const getTPIDetailsHeaderContent = useCallback(() => {
        let tpiDetailsHeaderContent = e1pCommonMessages.createNewTPI;

        if (isTPIFromSearchResult) {
            tpiDetailsHeaderContent = e1pCommonMessages.tpiDetails;
        } else if (isEditingTPI) {
            tpiDetailsHeaderContent = e1pCommonMessages.editThirdPartyInterestDetails;
        }

        return tpiDetailsHeaderContent;
    }, [isEditingTPI, isTPIFromSearchResult]);

    const generateOverridePropsForUncontrolledPerson = useCallback(() => ({
            additionalInterestTypePerson: {
                visible: false,
                availableValues: getAddlInterestTypeCodes,
            },
            personFirstName: {
                readOnly: !isAddingNewTPI,
                required: isAddingNewTPI && !isTPIContactTypeCompany,
                validationMessages: PersonNameValidator
                    .validateFirstName(_get(tempTPIDetailVM, 'person.firstName.value'), translator),
                showErrors: !!_get(tempTPIDetailVM, 'person.firstName.value', false) || isPageSubmitted
            },
            personMiddleName: {
                readOnly: !isAddingNewTPI,
                validationMessages: PersonNameValidator
                    .validateMiddleName(_get(tempTPIDetailVM, 'person.middleName.value'), translator),
                showErrors: !!_get(tempTPIDetailVM, 'person.middleName.value', false) || isPageSubmitted
            },
            personLastName: {
                readOnly: !isAddingNewTPI,
                required: isAddingNewTPI && !isTPIContactTypeCompany,
                validationMessages: PersonNameValidator
                    .validateLastName(_get(tempTPIDetailVM, 'person.lastName.value'), translator),
                showErrors: !!_get(tempTPIDetailVM, 'person.lastName.value', false) || isPageSubmitted
            },
            personSuffix: {
                readOnly: !isAddingNewTPI
            },
            personAddressLine1: {
                required: true,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personAddressLine2: {
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personCity: {
                required: true,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personPostalCode: {
                required: true,
                onBlur: handleAddressBlur,
                className: (() => 'editableZip')(),
                visible: isEditingTPI
            },
            personState: {
                availableValues: USStates,
                required: true,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            personAddressInfoContainer: {
                visible: isEditingTPI
            },
            personAddress: {
                addressVM: _get(tempTPIDetailVM, 'address'),
                labelPosition: 'top',
                showCountry: true,
                showOptional: false,
                onValidate: setComponentValidation,
                viewOnlyMode: false,
                showParentLoader: setIsStandardizingAddress,
                showErrors: isPageSubmitted || showErrors,
                onAddressChange: (value, path) => onAddressChange(value, `address.${path}`),
                visible: !isEditingTPI && !!tempTPIDetailVM,
                shouldStandardizeOnBlur: false
            },
        }), [
        USStates, getAddlInterestTypeCodes, handleAddressBlur, isAddingNewTPI,
        isEditingTPI, isPageSubmitted, isTPIContactTypeCompany, onAddressChange,
        setComponentValidation, showErrors, tempTPIDetailVM, translator
    ]);

    const generateOverridePropsForUncontrolledCompany = useCallback(() => ({
            additionalInterestType: {
                visible: false,
                availableValues: getAddlInterestTypeCodes,
            },
            companyName: {
                readOnly: !isAddingNewTPI,
                required: isAddingNewTPI && isTPIContactTypeCompany
            },
            companyAddressLine1: {
                required: isTPIContactTypeCompany,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            companyAddressLine2: {
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            companyCity: {
                required: isTPIContactTypeCompany,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            companyPostalCode: {
                required: isTPIContactTypeCompany,
                onBlur: handleAddressBlur,
                className: 'editableZip',
                visible: isEditingTPI
            },
            companyState: {
                availableValues: USStates,
                required: isTPIContactTypeCompany,
                onBlur: handleAddressBlur,
                visible: isEditingTPI
            },
            companyAddressInfoContainer: {
                visible: isEditingTPI
            },
            companyAddress: {
                addressVM: _get(tempTPIDetailVM, 'address'),
                labelPosition: 'top',
                showCountry: true,
                showOptional: false,
                onValidate: setComponentValidation,
                viewOnlyMode: false,
                showParentLoader: setIsStandardizingAddress,
                showErrors: isPageSubmitted || showErrors,
                onAddressChange: (value, path) => onAddressChange(value, `address.${path}`),
                visible: !isEditingTPI && !!tempTPIDetailVM,
                shouldStandardizeOnBlur: false
            },
        }), [
        USStates, getAddlInterestTypeCodes, handleAddressBlur, isAddingNewTPI,
        isEditingTPI, isPageSubmitted, isTPIContactTypeCompany, onAddressChange,
        setComponentValidation, showErrors, tempTPIDetailVM
    ]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted || showErrors,
            autoComplete: false
        },
        euTPISearchDetailComponentContainer: {
            visible: !isStandardizingAddress && !isSearchingContacts && !!tpiDetailVM
        },
        euTPISearchDetailComponentIndicator: {
            loaded: !isStandardizingAddress && !isSearchingContacts,
            text: isStandardizingAddress
                ? translator(e1pCommonMessages.standardizingAddressMessage)
                : translator(e1pCommonMessages.searchingContacts)
        },
        addressStandardizationComponent: {
            addressStandardizationResultDTO,
            visible: !!addressStandardizationResultDTO && !!tpiDetailVM && isEditingTPI,
            modalDisplay: false,
            selectInlineAddress: onSelectAddress,
            enteredAddress: enteredStateForAddressStandardization
        },
        tpiActionsContainer: {
            visible: !isStandardizingAddress && !isSearchingContacts
        },
        controlledTPIContainer: {
            visible: isControlledTPI
        },
        controlledTPIAddress: {
            value: formattedAddress
        },
        controlledTPIBankName: {
            value: formattedBankName
        },
        isGrantorToggleButton: {
            readOnly: isEditingTPI,
            required: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'trust',
            visible: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'trust',
            value: _get(tempTPIDetailVM, 'grantor.value.code', undefined)
        },
        isIndividualOrFamilyToggleButton: {
            readOnly: isEditingTPI,
            required: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'trust',
            visible: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'trust',
            value: _get(tempTPIDetailVM, 'individualOrFamily.value.code', undefined)
        },
        isMemberToggleButton: {
            readOnly: isEditingTPI,
            required: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'llc',
            visible: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'llc',
            value: _get(tempTPIDetailVM, 'member.value.code', undefined)
        },
        isJointOwnershipToggleButton: {
            readOnly: isEditingTPI,
            required: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'llc',
            visible: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'llc',
            value: _get(tempTPIDetailVM, 'jointOwnership.value.code', undefined)
        },
        relationshipToNIDropdown: {
            readOnly: isEditingTPI,
            required: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'individual',
            visible: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'individual',
            value: _get(tempTPIDetailVM, 'relationshipToNI.value.code', undefined)
        },
        isDeceasedToggleButton: {
            disabled: !isAddingNewTPI,
            readOnly: !isAddingNewTPI,
            required: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'individual',
            visible: _get(tempTPIDetailVM, 'additionalInsuredType.value.code') === 'individual',
            value: _get(tempTPIDetailVM, 'deceased.value', undefined)
        },
        uncontrolledPersonTPIContainer: {
            visible: !isControlledTPI && !isTPIContactTypeCompany
        },
        uncontrolledCompanyTPIContainer: {
            visible: !isControlledTPI && isTPIContactTypeCompany
        },
        tpiDetailsHeading: {
            content: getTPIDetailsHeaderContent()
        },      
        completeMissingFieldMessageDiv: {
            visible: isPageSubmitted && !isComponentValid
        },
        ...generateOverridePropsForUncontrolledPerson(),
        ...generateOverridePropsForUncontrolledCompany()
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                tempTPIDetailVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [tempTPIDetailVM, overrideProps]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            saveThirdPartyInterest,
            onCancel
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    const tpiDetailsPanel = (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={tempTPIDetailVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={setComponentValidation}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );

    if (!showPopup) {
        return tpiDetailsPanel;
    }

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader />
            <ModalBody className={styles.modalWidth}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={tempTPIDetailVM}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    onValidationChange={setComponentValidation}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter />
        </ModalNext>
    );
};

/**
 * Define expected types for properties to be passed into this Jutro component.
 */
E1PEUTPISearchDetailComponent.propTypes = {
    viewModelService: PropTypes.shape({
        clone: PropTypes.func,
        productMetadata: PropTypes.shape({
            get: PropTypes.func
        }).isRequired
    }).isRequired,
    tpiDetailVM: PropTypes.shape({
        value: PropTypes.shape({
            addlInterestType: PropTypes.string,
            person: PropTypes.shape({
                publicID: PropTypes.string
            }),
            company: PropTypes.shape({
                publicID: PropTypes.string
            }),
        })
    }),
    onResolve: PropTypes.func,
    cancelButtonHandler: PropTypes.func,
    isControlledTPI: PropTypes.bool.isRequired,
    tpiBasePath: PropTypes.string,
    transactionVM: PropTypes.shape({}).isRequired,
    isTPIContactTypeCompany: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool,
    showPopup: PropTypes.bool,
    authHeader: PropTypes.shape({}).isRequired,
    isAddingNewTPI: PropTypes.bool,
    isEditingTPI: PropTypes.bool
};

/**
 * Define default values for properties to be passed into this Jutro component.
 */
E1PEUTPISearchDetailComponent.defaultProps = {
    isAddingNewTPI: false,
    showPopup: false,
    isEditingTPI: false,
    tpiDetailVM: undefined,
    isOpen: undefined,
    onReject: undefined,
    cancelButtonHandler: undefined,
    tpiBasePath: undefined
};

export default E1PEUTPISearchDetailComponent;
