import { defineMessages } from 'react-intl';

export default defineMessages({
    thankyouPage: {
        id: 'rewrite.eh.directives.templates.page.Thank You',
        defaultMessage: 'Thank You'
    },
    startRewrite: {
        id: 'rewrite.eh.directives.templates.page.Start Rewrite',
        defaultMessage: 'Start Rewrite'
    },
    startRewriteEHTitle: {
        id: 'rewrite.eh.directives.templates.page.Start Rewrite EH',
        defaultMessage: 'Start Rewrite EH'
    },
    insuredDetails: {
        id: 'rewrite.eh.directives.templates.page.Insured Details',
        defaultMessage: 'Insured Details'
    },
    property: {
        id: 'rewrite.eh.directives.templates.page.Property',
        defaultMessage: 'Property'
    },
    riskAnalysis: {
        id: 'rewrite.eh.directives.templates.page.Risk Analysis',
        defaultMessage: 'Risk Analysis'
    },
    coverage: {
        id: 'rewrite.eh.directives.templates.page.Coverage',
        defaultMessage: 'Coverage'
    },
    thirdPartyInterest: {
        id: 'rewrite.eh.directives.templates.page.Third-Party Interest',
        defaultMessage: 'Third-Party Interest'
    },
    anErrorOccurred: {
        id: 'gw-portals-policytransaction-rewrite-eh.wizard.error',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'gw-portals-policytransaction-rewrite-eh.wizard.error.title',
        defaultMessage: 'Error'
    },
    exitTransaction: {
        id: 'rewrite.eh.wizard.steps.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'rewrite.eh.wizard.steps.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'rewrite.eh.wizard.steps.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'rewrite.eh.wizard.steps.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    }
});
