import React, {
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import {
    get, set, isEmpty
} from 'lodash';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useLandingPageUtil } from 'e1p-capability-hooks';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { EHHO4PropertyDetailsComponent } from 'e1p-capability-policyjob-react';
import { EndorsementService } from 'e1p-capability-policychange';

const LOB = 'homeowners_EH';

function PropertyPage(props) {
    const { authHeader } = useAuthentication();
    const {
        wizardData: policyChangeVM, updateWizardData, isSkipping, steps, jumpTo, updateWizardSnapshot
    } = props;
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const {
        isComponentValid,
        initialValidation,
        onValidate
    } = useValidation('PropertyPage');

    const {
        getLandingPageIndexForQuotedJob
    } = useLandingPageUtil();

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [policyChangeVM, isComponentValid, isPageSubmitted]);

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            const saveResponse = await EndorsementService.saveEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', saveResponse);
            updateWizardData(policyChangeVM);
            setIsSavingEndorsement(false);

            return policyChangeVM;
        },
        [isComponentValid, policyChangeVM, authHeader, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, policyChangeVM, updateWizardSnapshot]
    );

    const saveAndQuote = useCallback(
        async () => {
            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteResponse);
            updateWizardData(policyChangeVM);

            return policyChangeVM.value;
        },
        [authHeader, policyChangeVM, updateWizardData]
    );

    const onCustom = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);
            policyChangeVM.value = await saveAndQuote();
            updateWizardData(policyChangeVM);
            updateWizardSnapshot(policyChangeVM);

            let newLandingPageIndex = -1;
            const validationErrors = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);

            // Need to stay on the page if field issues
            //  validationIssues.issues should never come up
            //  Only could come up if PC is calling OOTB rules engine which it should not
            //  Can't look at just errorsAndWarnings because we most go forward with UW issues
            //  and display the uw issues pop up on the change summary page
            if (validationErrors.length === 0) {
                newLandingPageIndex = getLandingPageIndexForQuotedJob(
                    LOB,
                    steps
                );
            }

            if (newLandingPageIndex >= 0) {
                jumpTo(newLandingPageIndex, true);
            }

            setIsSavingEndorsement(false);

            return false;
        },
        [isComponentValid, policyChangeVM, saveAndQuote, updateWizardData, updateWizardSnapshot, getLandingPageIndexForQuotedJob, steps, jumpTo]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,
        [isSavingCurrentPageChanges, isSavingEndorsement, isSkipping]);
		
    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            onCustom={onCustom}
            showCustom
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
        >
            <EHHO4PropertyDetailsComponent
                onValidate={onValidate}
                transactionVM={policyChangeVM}
                updateWizardData={updateWizardData}
                isPageSubmitted={isPageSubmitted}
                setShouldSkipAdditionalInfo={() => {}}
                isSavingCurrentPageChanges={isSavingCurrentPageChanges}
                isSkipping={isSkipping}
                isSavingEndorsement={isSavingEndorsement}
                viewOnly={false}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
