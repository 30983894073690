import React from 'react';
import PropTypes from 'prop-types';
import { InlineNotification } from '@jutro/components';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { withTracking } from '@xengage/gw-portals-tracking-react';
import singleErrorStyles from './WizardSingleErrorComponent.module.scss';

/**
 *
 * @param {Object} props IssueList
 * @returns {JSX} A nice list of errors
 * This component takes in a list of exception DTO or
 *   a list of validation issues
 */
function WizardSingleErrorComponent(props) {
    const { issuesList } = props;

    const issuesSection = issuesList.map((issue, index) => {
        let issueType = 'error'; // Exception always error
        if (issue.level) {
            // validation has level and could be a warning
            issueType = issue.level === ErrorLevel.LEVEL_ERROR ? 'error' : 'warning';
        }
        return (
            <div
                className={singleErrorStyles.gwIssuesContainer}
                // Validation error OR Exception
                key={issue.description || issue.errorMessage}
            >
                <InlineNotification
                    id={`InlineNotification${index}`}
                    isDismissable
                    isEmbeddedNotification={false}
                    // Validation error OR Exception
                    message={issue.description || issue.errorMessage}
                    type={issueType}
                />
            </div>
        );
    });

    return <div>{issuesSection}</div>;
}

WizardSingleErrorComponent.propTypes = {
    issuesList: PropTypes.arrayOf(PropTypes.shape({}))
};
WizardSingleErrorComponent.defaultProps = {
    issuesList: []
};
export default withTracking(WizardSingleErrorComponent);
