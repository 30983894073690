import { defineMessages } from 'react-intl';

export default defineMessages({

    updatingOOSConflicts: {
        id: 'components.e1pOOSConflictsComponent.Updating OOSConflicts',
        defaultMessage: 'Updating OOSConflicts'
    },
    submitConfirmTitle: {
        id: 'components.e1pOOSConflictsComponent.Submitted changes cannot be undone',
        defaultMessage: 'Submitted changes cannot be undone'
    },
    submitConfirmMessage: {
        id: 'components.e1pOOSConflictsComponent.Submitted changes cannot be undone. Are you sure you want to continue?',
        defaultMessage: 'Submitted changes cannot be undone. Are you sure you want to continue?'
    },
    overrideAll: {
        id: 'components.e1pOOSConflictsComponent.Override All',
        defaultMessage: 'Override All'
    },
    overrideNone: {
        id: 'components.e1pOOSConflictsComponent.Override None',
        defaultMessage: 'Override None'
    },
    overrrideFutureConflicts: {
        id: 'components.e1pOOSConflictsComponent.Override Future Conflict?',
        defaultMessage: 'Override Future Conflict?'
    },
    overrrideFutureConflictsYes: {
        id: 'components.e1pOOSConflictsComponent.Override Future Conflict.Yes',
        defaultMessage: 'Yes'
    },
    overrrideFutureConflictsNo: {
        id: 'components.e1pOOSConflictsComponent.Override Future Conflict.No',
        defaultMessage: 'No'
    },
    oosInstructionMessage: {
        id: 'components.e1pOOSConflictsComponent.Some values specified in this policy transaction conflict with changes made with future effective dates. Please indicate which future conflicts are to be overridden, and then select Submit.',
        defaultMessage: 'Some values specified in this policy transaction conflict with changes made with future effective dates. Please indicate which future conflicts are to be overridden, and then select Submit.'
    },
    oosInstructionMessage2: {
        id: 'components.e1pOOSConflictsComponent.Warning: Submitted changes cannot be undone',
        defaultMessage: 'Warning: Submitted changes cannot be undone'
    },
    submit: {
        id: 'components.e1pOOSConflictsComponent.Submit',
        defaultMessage: 'Submit'
    },
    item: {
        id: 'components.e1pOOSConflictsComponent.Item',
        defaultMessage: 'Item'
    },
    description: {
        id: 'components.e1pOOSConflictsComponent.Description',
        defaultMessage: 'Description'
    },
    policyChangeEff: {
        id: 'components.e1pOOSConflictsComponent.Change Eff.',
        defaultMessage: 'Change Eff. {changeEffectiveDate}'
    },
    conflict: {
        id: 'components.e1pOOSConflictsComponent.Conflict',
        defaultMessage: 'Conflict'
    },
    conflictEffDate: {
        id: 'components.e1pOOSConflictsComponent.Conflict Eff. Date',
        defaultMessage: 'Conflict Eff. Date'
    }
});
