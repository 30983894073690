import React, {
    useCallback, useContext, useEffect, useMemo, useState, useRef
} from 'react';
import {
    get as _get, set as _set, some as _some, isUndefined as _isUndefined, findIndex as _findIndex
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps, WizardPageTemplateWithTitle } from 'e1p-portals-wizard-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import {
    useSniUtil,
    useNewBusinessUtil,
    useViewPolicyUtil
} from 'e1p-capability-hooks';
import metadata from '../InsuredDetailPage.metadata.json5';
import viewOnlyWizardConfig from '../../../config/viewOnly/rewrite-eu-wizard-config.json5';
import EUViewRewriteVehicleOperatorPage from '../../VehicleOperatorPage/viewOnly/ViewEUVehicleOperator';


const LOB = 'personalUmbrella_EU';

function ViewPolicyDetailsPage(props) {
    const {
        wizardData: rewriteVM,
        updateWizardData,
        authUserData,
        steps,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        markFurthestStep,
        viewOnly,
        currentStepIndex,
        changeNextSteps
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const translator = useTranslator();
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const hasPerformedAccountSearch = useRef(false);

    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const policyState = _get(rewriteVM, 'baseData.policyAddress.state.value.code');

    const [producerCodeDetails, setProducerCodeDetails] = useState({});

    const underlyingPoliciesVM = useMemo(() => _get(rewriteVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []), [rewriteVM]);

    const {
        getLocationAndProducerCodes
    } = useNewBusinessUtil(
        rewriteVM,
        updateWizardData,
        viewModelService,
        LOB,
        false,
        authHeader,
        setLocationAndProducerCodes
    );

    const {
        getLandingPageIndexForViewOnlyPolicy,
        getProducerDetails
    } = useViewPolicyUtil();

    const { createSecondaryNamedInsuredVM, removeSni } = useSniUtil(
        rewriteVM,
        updateWizardData,
        viewModelService,
        LOB,
    );

    const markAccountSearchCompleted = () => {
        hasPerformedAccountSearch.current = true;
    };

    /**
         * Helper for getting producer code on initial render and
         *   then when state changes. Will only run when a state changes
         *   and on initial render so no need to make a callback as the param
         *   will always be a new value.
         * @param {String} location State code
         */
    const getAndSetProducerCodes = async (location) => {
        const foundProducerCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundProducerCodes.length === 1) {
            _set(rewriteVM, 'baseData.producerCode_Ext', foundProducerCodes[0].code);
        }

        markFurthestStep((steps.length - 1));
    };

    /**
         * Initialize the producer code for an existing account
         *  as policy state will already exist
         */

    useEffect(() => {
        // in view only flow we want to make each step as visited so that user can
        // navigate to any page by clicking on tabs on chevron
        markFurthestStep((steps.length - 1));

        if (isPageJumpEnabled) {
            updateIsPageJumpEnabled(false);
            jumpTo(getLandingPageIndexForViewOnlyPolicy(LOB, steps, 'Submission'), true);
        }

        // Policy state will not exist for new account so nothing will happen
        // If New quote for an account we will use accountholders state to set
        // policy state and that state here will set the initial producer code
        if (policyState && isAgent) {
            // only look up for agents
            getAndSetProducerCodes(policyState);
        }
        // Only run on initial render. Helper will be called again from
        //   the state fields onChange handler
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(rewriteVM, 'baseData.producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(rewriteVM, 'baseData.externalID_Ext', locationAndProducerCodes.locationCodes[0].code);
        }
    }, [locationAndProducerCodes, rewriteVM]);

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            setLocationAndProducerCodes(
                [
                    {
                        producerCodes: { code: producerCode, name: producerCode },
                        locationCodes: { code: externalID, name: externalID }
                    }
                ]
            );
            _set(rewriteVM, 'baseData.producerCode_Ext', producerCode);
            _set(rewriteVM, 'baseData.externalID_Ext.value', externalID);
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [rewriteVM],
    );

    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        overrideProps.secondaryNamedInsured = {
            hideSSN: true
        };
    }, []);

    useEffect(() => {
        if (policyState && isAgent) {
            // only look up for agents
            getLocationAndProducerCodes(policyState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyState]);

    useEffect(() => {
        if (rewriteVM.baseData.quoteSource_Ext.sourceType === undefined) {
            const model = {
                sourceType: 'directentry',
                sourceName: 'msaagentsonly'
            };

            rewriteVM.baseData.quoteSource_Ext.value = model;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (policyState !== undefined) {
            updateWizardData(rewriteVM);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyState]);

    const vehicleOperatorPage = useRef({
        id: 'EUViewRewriteVehicleOperatorPage',
        path: '/view-vehicle-operator',
        component: EUViewRewriteVehicleOperatorPage,
        title: {
            id: 'rewrite.eu.directives.templates.page.Vehicle Operator',
            defaultMessage: 'Vehicle Operator',
        },
        stepProps: {
            template: WizardPageTemplateWithTitle
        },
    }).current;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const differentAutoExposures = ['personalauto', 'personalaononowner', 'otherspecialityvehicle', 'cycle'];

    const handleVehicleOperatorPage = useCallback(() => {
        const remainingSteps = viewOnlyWizardConfig.steps
            .slice(currentStepIndex + 1, viewOnlyWizardConfig.steps.length);

        const indexToUnderlyingPolicyPage = _findIndex(
            remainingSteps, (step) => step.path === '/view-underlying-policy'
        );
        const vehicleUnderlyingPolicyExists = _some(underlyingPoliciesVM.value,
            (policy) => differentAutoExposures.includes(policy.policyType));

        if (vehicleUnderlyingPolicyExists) {
            remainingSteps.splice(indexToUnderlyingPolicyPage + 1, 0, vehicleOperatorPage);
        }

        changeNextSteps(remainingSteps);
    }, [changeNextSteps, currentStepIndex, differentAutoExposures, underlyingPoliciesVM.value, vehicleOperatorPage]);

    useEffect(() => {
        if (_isUndefined(underlyingPoliciesVM.value)) {
            _set(underlyingPoliciesVM, 'value', []);
        }

        handleVehicleOperatorPage();
        markFurthestStep((steps.length - 1));
    }, [handleVehicleOperatorPage, markFurthestStep, steps.length, underlyingPoliciesVM]);


    const onNext = useCallback(() => rewriteVM, [
        rewriteVM
    ]);

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: createSecondaryNamedInsuredVM,
            onRemoveSni: removeSni,
            onMarkAccountSearchCompleted: markAccountSearchCompleted,
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    useEffect(() => {
        if (rewriteVM.baseData.externalID_Ext?.value) {
            getProducerDetails(
                rewriteVM.baseData.externalID_Ext.value, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
                setAgencyName(details.agencyName);
                setBookTransferIndicator(details.bookTransferIndicator);
                setServiceCenterIndicator(details.serviceCenterIndicatorValue);
            }).catch((error) => {
                /**
                 * E1PAP1PC-15099 :
                 * This is rare scenario; will never happen in production
                 * When we bound policy using dummy producer code
                 * and when other user tries to view this policy we get exception from service
                 */
                if (rewriteVM.baseData.value.exceptions_Ext) {
                    rewriteVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        rewriteVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(rewriteVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewriteVM.baseData.externalID_Ext.value]);

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnly
        },
        policyDetailsPageLoadingIndicator: {
            loaded: true
        },
        policyDetailsPageContainer: {
            visible: true
        },
        coverageStartDate: {
            dateDTO: rewriteVM.baseData.periodStartDate,
            defaultToToday: true,
            showErrors: true
        },
        coverageEndDate: {
            dateDTO: rewriteVM.baseData.periodEndDate
        },
        personalInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        secNamedInsuredContainer: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        addSni: {
            visible: false
        },
        secondaryNamedInsured: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`),
            viewOnlyMode: true,
            hideSSN: true
        },
        mailingAndBillingAddressComponent: {
            transactionVM: rewriteVM,
            updateWizardData,
            viewOnlyMode: true,
            lob: LOB
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        removeSni: {
            visible: false
        },
        e1pAcknowledgementComponent: {
            acknowledgements: _get(rewriteVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: _get(rewriteVM, 'baseData.policyAddress.state.value.code'),
                name: translator({ id: _get(rewriteVM, 'baseData.policyAddress.state.value.name') })
            },
            viewOnly: true,
            lob: LOB
        },
        insuredResidenceAddress: {
            addressVM: _get(rewriteVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onAddressChange: () => { },
            viewOnlyMode: true,
            showParentLoader: false
        },
        primaryNamedInsured: {
            viewOnlyMode: true,
            hideSSN: true,
            isPNI: true
        },
        producerCode: {
            visible: isAgent
        },
        ExternalProducerDetailsComponent: {
            viewOnlyMode: true,
            visible: !isAgent,
            defaultValue: rewriteVM.baseData.externalID_Ext?.value
        },
        locationCode: {
            visible: isAgent
        },
        changePniComponent: {
            visible: false
        },
        changeSniComponent: {
            visible: false
        },
        swapPniSni: {
            visible: false
        },
        agencyInformationMainDiv: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: rewriteVM,
            authHeader,
            LOB,
            viewOnlyMode: true
        },
        ...generateOverrides()
    };

    const readValue = (id, path) => readViewModelValue(metadata.pageContent, rewriteVM, id, path, overrideProps);

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
            showPrevious={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

ViewPolicyDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewPolicyDetailsPage);
