const overrides = {
    field: {
        antiTheftDevices: {
            partner: {
                MSA: {
                    states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'RI'],
                },
            },
        },
        primaryUse: {
            partner: {
                MSA: {
                    states: ['NC'],
                },
            },
        },
    },
};
export default overrides;
