import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {
    findIndex as _findIndex,
    get as _get,
    isNil as _isNil,
    set as _set,
    isEmpty as _isEmpty,
    isUndefined as _isUndefined
} from 'lodash';
import appConfig from 'app-config';
import { useTranslator } from '@jutro/locale';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { PaymentHelper } from 'e1p-capability-payment';
import { error as loggerError } from '@jutro/logger';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { QuoteProposalUtil, PaymentPageUtil } from 'e1p-portals-util-js';
import { useUWIssueUtil, e1pUserAccessUtil } from 'e1p-capability-hooks';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import {
    parseErrors
} from '@xengage/gw-portals-edge-validation-js';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import metadata from './PaymentDetailsPage.metadata.json5';
import messages from './PaymentDetailsPage.messages';

const LOB = 'personalAuto_EA';

function initializeVM(rewriteDataVM) {
    rewriteDataVM.bindData.paymentDetails.value = rewriteDataVM.bindData.paymentDetails.value || {};
}

// Day one will not require payments for any rewrites
// need to make paymentus required for INT and above
const environment = _get(appConfig, 'env.AMFAM_ENV', 'local');
const envsWithoutPayment = ['dev', 'local', 'sbx', 'dev2', 'int2', 'qa2', 'dev3', 'gwupgrade1', 'int', 'perf2'];
const isEnvWithPaymentus = !envsWithoutPayment.includes(environment);

function PaymentDetailsPage(props) {
    const {
        wizardData: rewriteDataVM,
        updateWizardData,
        wizardSnapshot,
        steps,
        jumpTo,
        disableAllSteps,
        isSkipping,
        updateWizardSnapshot
    } = props;

    const {
        hasUWIssues,
        showUnderwritingIssuesPopup
    } = useUWIssueUtil(
        rewriteDataVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const [isVMInitialized, setIsVMInitialized] = useState(false);
    const [bindOperationsInProcess, setBindOperationsInProcess] = useState(false);
    const { authHeader, authUserData } = useAuthentication();
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('PaymentDetailsPage');
    const [underwritingIssuesPopupAppeared, setUnderwritingIssuesPopupAppeared] = useState(false);
    const [keepShowingIframeAfterPayment, setKeepShowingIframeAfterPayment] = useState(false);
    const [signatureSuccess, setSignatureSuccess] = useState(false);
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const [isCallingCanPurchase, setIsCallingCanPurchase] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [fieldIssue, setFieldIssue] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isMailingComponentValid, updateIsBillingComponentValid] = useState(true);
    const [isBillingComponentValid, updateIsMailingComponentValid] = useState(true);
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const translator = useTranslator();
    const amfamOktaToken = useContext(AmfamOktaTokenContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const PAY_MONTHLY = 'af:payMthly3';

    const isNewBusiness = _get(rewriteDataVM, 'baseData.value.businessTransactionType_Ext') === 'NewBusiness';
    // paymentRequiredInd is coming from backend which tells us for which rewrite type we need to collect payment
    const isPaymentRequiredBackendInd = _get(rewriteDataVM, 'value.paymentRequiredInd_Ext');
    const requiresPaymentForEnvironment = isEnvWithPaymentus && isPaymentRequiredBackendInd;
    const isPaymentDone = !!_get(rewriteDataVM, 'bindData.value.paymentRefNumber');
    const isSignatureStatusAsCompleted = _get(rewriteDataVM, 'value.baseData.signature_Ext.status') === 'COMPLETED';
    // It is used to show loader message 'Preparing Required Documents' when gettingSignatureDocument is true
    const [gettingSignatureDocument, setGettingSignatureDocument] = useState(false);
    // It is used to show loader message 'Saving the Insureds contact information' when isInsuredContactUpdating is true
    const [isInsuredContactUpdating, setIsInsuredContactUpdating] = useState(false);
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);
    const { paymentRequiredForOpco } = appConfig;
    const { opCo } = amfamOktaToken;
    const [requiresPayment, setIsRequiresPayment] = useState(requiresPaymentForEnvironment && paymentRequiredForOpco.includes(opCo));
    const canUserIssueWithoutPayment = authUserData.permissions_Ext.includes('issuewithoutpayment_ext');

    const { isUserAuthorisedToPayAndBind } = e1pUserAccessUtil(authUserData, opCo);
    const canUserPayAndBindQuote = isUserAuthorisedToPayAndBind();

    useEffect(() => {
        // It will make payment required if the environment, paymentRequiredInd and operating company condition matches (MSA)
        setIsRequiresPayment(requiresPaymentForEnvironment && paymentRequiredForOpco.includes(opCo))
    }, [opCo, paymentRequiredForOpco, requiresPaymentForEnvironment]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isBillingComponentValid && isMailingComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteDataVM, isPageSubmitted, isBillingComponentValid, isMailingComponentValid]);

    const showPopupIfUWIssueExists = useCallback(() => {
        if (hasUWIssues() && !underwritingIssuesPopupAppeared) {
            showUnderwritingIssuesPopup();
            setUnderwritingIssuesPopupAppeared(true);
        }
    }, [hasUWIssues, showUnderwritingIssuesPopup, underwritingIssuesPopupAppeared]);

    useEffect(() => {
        QuoteProposalUtil.fetchQuoteProposal(
            rewriteDataVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );

        // check payment receipt if full quote and continue (never on convert from quick to full)
        if (!isPaymentDone && requiresPayment) {
            PaymentHelper.getReceiptNumber(
                _get(rewriteDataVM, 'jobID.value', rewriteDataVM.jobID?.value), authHeader
            ).then((referenceNumber) => {
                // set value from receipt in case it did not get saved to PC
                if (referenceNumber) {
                    _set(rewriteDataVM, 'bindData.value.paymentRefNumber', referenceNumber);
                    updateWizardData(rewriteDataVM);
                }
            }).catch((err) => {
                loggerError(err);
            });
        }
        // Above logic only needs to run once when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = _get(rewriteDataVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmail = _get(rewriteDataVM, 'lobData.personalAuto_EA.paperlessEmail.value');

        return isPaperlessEmailUpdated && pniEmail && pniEmail !== paperlessEmail;
    }, [rewriteDataVM, isPaperlessEmailUpdated]);

    /**
     * Check quote data dto for validation errors and exceptions
     *   that come from in page api response (not onNext calls)
     */
    const checkForInPageErrors = useCallback(
        (qdd) => {
            const errorsAndWarnings = _get(qdd, 'errorsAndWarnings', []);

            // uw goes in pop up; not top banner
            delete errorsAndWarnings.underwritingIssues;

            const exceptionsPath = 'baseData.exceptions_Ext';
            const errors = parseErrors(errorsAndWarnings);
            const exceptions = _get(qdd, exceptionsPath, []);
            const errorConvertingToFullApp = errors?.length > 0 || exceptions.length > 0;

            if (errorConvertingToFullApp) {
                setValidationErrors(errors.concat(exceptions));
            }
        },
        [],
    );

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();

            if (quoteProposalLink) {
                window.open(quoteProposalLink, '_blank');

                return true;
            }
        }, [quoteProposalLink]
    );

    // When payment option changes, we need to get a new doc url
    useEffect(() => {
        if (rewriteDataVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                rewriteDataVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            rewriteDataVM.paymentOptionChanged = false;
        }
    }, [authHeader, rewriteDataVM, rewriteDataVM.paymentOptionChanged]);

    const checkCanPurchase = useCallback(async () => {
        if (!isSkipping) {
            if (isPaymentDone && requiresPayment) {
                disableAllSteps();
            } else {
                setIsCallingCanPurchase(true);

                const response = await RewriteService.canPurchase(rewriteDataVM.value, authHeader);

                // have to set uw issues from the can purchase so the util can see them
                _set(
                    rewriteDataVM.value,
                    'errorsAndWarnings.underwritingIssues',
                    response.errorsAndWarnings.underwritingIssues
                );

                // this is to hide paymentus component if there is any validation error
                const fieldError = _get(response, 'errorsAndWarnings.validationIssues.fieldIssues', []);

                setFieldIssue(!!(fieldError.length));

                const licensedProducers = _get(response, 'licensedProducers.licensedProducers', []);

                _set(rewriteDataVM.value, 'licensedProducers', response.licensedProducers);
                // check for validation errors
                checkForInPageErrors(response);
                // update wizard data with any uw issues and show if exists
                updateWizardData(rewriteDataVM);
                showPopupIfUWIssueExists();

                // set producer code options for signin agents
                if (licensedProducers.length > 0) {
                    setProducerCodeOptions(
                        licensedProducers.map((value) => ({
                                code: value.producerID,
                                name: `${value.producerName.firstName} ${value.producerName.lastName}`
                            }))
                    );
                }

                setIsCallingCanPurchase(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isVMInitialized) {
            initializeVM(rewriteDataVM);
            setIsVMInitialized(true);
        }
    }, [isVMInitialized, rewriteDataVM]);

    const hasSignatureRequiredFormsInCurrentJob = rewriteDataVM.lobData.personalAuto_EA?.hasSignatureRequiredFormsInCurrentJob?.value;


    useEffect(
        () => {
            const initialPopUps = async () => {
                // if new forms are generated, we need to get licensed producers
                if (hasSignatureRequiredFormsInCurrentJob) {
                    await checkCanPurchase();
                }
            };

            initialPopUps();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hasSignatureRequiredFormsInCurrentJob]
    );

    const availableValuesForPaymentOptions = useMemo(() => {
        const correctOffering = rewriteDataVM.lobData.personalAuto_EA.offerings.value[0];

        if (correctOffering.paymentPlans !== undefined) {
            return correctOffering.paymentPlans
                .filter((paymentPlans) => paymentPlans.name)
                .reduce(
                    (paymentPlanMap, paymentPlan) => ({
                        ...paymentPlanMap,
                        [paymentPlan.billingId]: paymentPlan
                    }),
                    {}
                );
        }

        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rewriteDataVM.lobData.personalAuto_EA.offerings.value)]);

    const setErrors = useCallback((removeUnnecessaryErrors = false) => {

        const shouldShowMissingPaymentError = requiresPayment && !isPaymentDone && !canUserIssueWithoutPayment;

        const isSignatureMissing = _get(rewriteDataVM, 'baseData.signatureType_ext.value') === undefined || !signatureSuccess;
        const isUwIssuePresent = hasUWIssues();

        const uniqueErrors = PaymentPageUtil.getValidationErrorsForPaymentPage(
            validationErrors, shouldShowMissingPaymentError, isSignatureMissing,
            isUwIssuePresent, translator, removeUnnecessaryErrors);

        setValidationErrors(uniqueErrors);
    }, [validationErrors, requiresPayment, isPaymentDone, rewriteDataVM, signatureSuccess, hasUWIssues, translator, canUserIssueWithoutPayment]);

    useEffect(() => {
        if (signatureSuccess || isPaymentDone) {
            setErrors(true);
        }
        // want to trigger this only on signatureSuccess update and payment update
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureSuccess, isPaymentDone]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            setErrors();
            window.scrollTo(0, 0);

            return false;
        }

        setBindOperationsInProcess(true);

        const quoteData = _get(rewriteDataVM, 'quoteData');

        if (!requiresPayment) {
            const { paymentReferenceNumberForLocal } = appConfig.amfamRewritePaymentConfig;

            _set(rewriteDataVM, 'bindData.value.paymentRefNumber', paymentReferenceNumberForLocal);
        }

        const response = await RewriteService.bind([rewriteDataVM.value], authHeader);

        response.quoteData = quoteData.value;
        rewriteDataVM.value = response;
        setBindOperationsInProcess(false);

        return rewriteDataVM;
    }, [authHeader, isComponentValid, requiresPayment, rewriteDataVM, setErrors]);


    /**
     * Helper callback which opens the "Third-Party Interest" wizard page.
     */
    const openTPIPageHandler = useCallback(() => {
        const indexForTPIPage = _findIndex(steps, ({ path }) => path === '/third-party-interest');

        jumpTo(indexForTPIPage);
    }, [steps, jumpTo]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isInsuredContactUpdating) {
            loadingMessage = translator(e1pCommonMessages.savingContactinfoMessage);
        } else if (gettingSignatureDocument) {
            loadingMessage = translator(e1pCommonMessages.prepareReqDocuments);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (bindOperationsInProcess) {
            loadingMessage = translator(messages.completingYourPurchaseMessageRewrite);
        }

        return loadingMessage;
    }, [isInsuredContactUpdating, gettingSignatureDocument, isSavingCurrentPageChanges, bindOperationsInProcess, translator]);

    const handleContactInfoUpdate = useCallback(async () => {
        // If paperless Email id exist and pni email id is undefiend
        // then update pni email to paperless email
        if (_isUndefined(_get(rewriteDataVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value'))
            && !!_get(rewriteDataVM, 'lobData.personalAuto_EA.paperlessEmail.value')) {
            _set(rewriteDataVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value',
                _get(rewriteDataVM, 'lobData.personalAuto_EA.paperlessEmail.value'));
        }

        // sync NI changes to Drivers
        const drivers = _get(rewriteDataVM.value, 'lobData.personalAuto_EA.coverables.drivers');
        const pni = _get(rewriteDataVM.value, 'lobData.personalAuto_EA.primaryNamedInsured');
        const pniDriver = drivers.find((driver) => driver.person.publicID === pni.person.publicID);

        if (pniDriver) {
            // updates to existing pni contact
            _set(pniDriver, 'person', pni.person);
            // setting the license on the person otherwise
            // update wipes it off when it's just under driver
            _set(pniDriver, 'person.licenseState', pniDriver.licenseState);
            _set(pniDriver, 'person.licenseNumber', pniDriver.licenseNumber);
        }

        const signatureType = _get(rewriteDataVM.value, 'baseData.signatureType_ext', undefined);

        // contact change requires another quote
        rewriteDataVM.value = await RewriteService.saveAndQuote(
            [rewriteDataVM.value], authHeader
        );
        _set(rewriteDataVM.value, 'baseData.signatureType_ext', signatureType);
        updateWizardSnapshot(rewriteDataVM);
        updateWizardData(rewriteDataVM);
    }, [authHeader, rewriteDataVM, updateWizardData, updateWizardSnapshot]);


    const onSave = useCallback(
        async () => {
            try {
                if (!isMailingComponentValid || !isBillingComponentValid) {
                    updateIsPageSubmitted(true);
                    window.scrollTo(0, 0);

                    return false;
                }

                setIsSavingCurrentPageChanges(true);

                const signatureType = _get(rewriteDataVM.value, 'baseData.signatureType_ext', undefined);
                const licensedProducerID = _get(rewriteDataVM, 'value.bindData.licensedProducerID');
                // We get licensedProducers in canPurchase call so storing it in variable before we do saveAndQuote
                const licensedProducers = _get(rewriteDataVM, 'value.licensedProducers');

                rewriteDataVM.value = await RewriteService.saveAndQuote(
                    [rewriteDataVM.value], authHeader
                );
                _set(rewriteDataVM.value, 'licensedProducers', licensedProducers);
                _set(rewriteDataVM.value, 'baseData.signatureType_ext', signatureType);
                QuoteProposalUtil.fetchQuoteProposal(
                    rewriteDataVM,
                    setQuoteProposalCompleted,
                    setQuoteProposalLink,
                    authHeader,
                    setIsQuoteProposalFailed
                );
                updateWizardData(rewriteDataVM);

                const fieldIssues = _get(rewriteDataVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(rewriteDataVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    /**
                   * E1PAP1PC-14986 :
                   * wizardData and wizardSnapshot not being equal due to
                   * some defaulting on each page so doing this temp fix
                   */
                    _set(rewriteDataVM, 'value.bindData.licensedProducerID', licensedProducerID);
                    updateWizardSnapshot(rewriteDataVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        },
        [
            authHeader, isBillingComponentValid, isMailingComponentValid,
            rewriteDataVM, updateWizardData, updateWizardSnapshot
        ]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(
        () =>
            !bindOperationsInProcess &&
            !isSavingCurrentPageChanges &&
            !gettingSignatureDocument &&
            !isInsuredContactUpdating,
        [
            bindOperationsInProcess,
            gettingSignatureDocument,
            isInsuredContactUpdating,
            isSavingCurrentPageChanges,
        ]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'top',
            readOnly: isPaymentDone && requiresPayment,
            autoComplete: false
        },
        paymentDetailsPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        paymentDetailsPageContainer: {
            visible: !bindOperationsInProcess && !isSavingCurrentPageChanges && !isInsuredContactUpdating,
            // Facing some issues, if i try to hide the container using visibility. 
            // Initially, visibility is false so all the components will be unmount 
            // when the visibility become true then all components will be mount again 
            // it will re-trigger all the react hook components so handled hiding using CSS
            className: gettingSignatureDocument ? "display-none" : "mt-8"
        },
        totalPremiumID: {
            value: !_isNil(
                availableValuesForPaymentOptions[
                _get(rewriteDataVM, 'bindData.selectedPaymentPlan.value')
                ]
            )
                ? availableValuesForPaymentOptions[
                    _get(rewriteDataVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined
        },
        totalPremiumWithFullPayID: {
            value: !_isNil(availableValuesForPaymentOptions[PAY_MONTHLY])
                ? availableValuesForPaymentOptions[PAY_MONTHLY].total
                : undefined
        },
        displayPNIAddress: {
            readOnly: true
        },
        monthlyPaymentScheduleComponent: {
            quoteID: _get(rewriteDataVM, 'quoteID.value', rewriteDataVM.jobID?.value),
            authHeader,
            transactionTotalAmount: !_isNil(
                availableValuesForPaymentOptions[
                _get(rewriteDataVM, 'bindData.selectedPaymentPlan.value')
                ]
            )
                ? availableValuesForPaymentOptions[
                    _get(rewriteDataVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined,
            changeInCost: rewriteDataVM.transactionCost?.value,
            startDate: rewriteDataVM.baseData.periodStartDate.value,
            endDate: rewriteDataVM.baseData.periodEndDate.value,
            jobTypeCode: rewriteDataVM.baseData.jobType.value.code,
            offerings: _get(rewriteDataVM, 'lobData.personalAuto_EA.offerings.value')
        },
        quoteProposalLinkContainer: {
            onClick: (e) => openDocument(e),
            disabled: !quoteProposalCompleted
        },
        mailingAndBillingAddressComponent: {
            transactionVM: rewriteDataVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            viewOnlyMode: isPaymentDone && requiresPayment,
            updateIsMailingComponentValid,
            updateIsBillingComponentValid,
            showErrors: isPageSubmitted,
            wizardSnapshot
        },
        paymentOptionsID: {
            submissionVM: rewriteDataVM,
            authHeader,
            updateWizardData,
            LOB,
            viewOnly: isPaymentDone
        },
        tpiComponentId: {
            rewriteDataVM,
            viewModelService,
            authHeader,
            updateWizardData
        },
        addTPIButton: {
            disabled: isPaymentDone && requiresPayment
        },
        policyTermComponent: {
            transactionVM: rewriteDataVM,
              authHeader,
            updateWizardData,
            viewOnly: true // as discussed with satish donot allow user to change on payment page
        },
        paymentusComponent: {
            submissionVM: rewriteDataVM,
            wizardSnapshot,
            amfamAccessToken: amfamOktaToken.amfamAccessTokenHeader,
            updateWizardData,
            visible: canUserPayAndBindQuote
                && (!isPaymentDone || keepShowingIframeAfterPayment) && requiresPayment
                && !hasUWIssues()
                && !fieldIssue,
            setKeepShowingIframeAfterPayment,
            setValidationErrors,
            disableAllSteps,
            LOB
        },
        signatureComponent: {
            submissionVM: rewriteDataVM,
            updateWizardData,
            authHeader,
            LOB,
            onSignatureSuccess: setSignatureSuccess,
            disabled: signatureSuccess,
            readOnly: hasUWIssues(),
            producerCodeOptions,
            setProducerCodeOptions,
            setGettingSignatureDocument,
            // Only make it visible after payment ref number is available.
            // If signarture is not required we don't want this visible and none
            //  of the logic should be executed in the component
            visible: hasSignatureRequiredFormsInCurrentJob && (isPaymentDone || !requiresPayment || canUserIssueWithoutPayment) && !isCallingCanPurchase,
            isSignatureRequired: hasSignatureRequiredFormsInCurrentJob,
            showErrors: isPageSubmitted,
             wizardSnapshot,
            saveLocationCodeChanges: onSave,
            checkCanPurchase,
            locationCodeEditable: true
        },
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        insuredContactContainer: {
            visible: !isNewBusiness
        },
        deliverDocumentsIndComponent: {
            visible: authUserData.permissions_Ext.includes('docdeliveryind_ext'),
            deliverDocInd: _get(rewriteDataVM, 'value.shouldDeliverDocuments_Ext'),
            setDeliverDocInd: ((value) => {
                _set(rewriteDataVM, 'value.shouldDeliverDocuments_Ext', value);
                updateWizardData(rewriteDataVM);
            })
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        insuredContactInfo: {
            setIsPaperlessEmailUpdated,
            setIsInsuredContactUpdating
        },
        // IAP-371 show TPI component in only read only Mode
        e1ptpiDisplayTableContainer: {
            transactionVM: rewriteDataVM,
            viewModelService,
            authHeader,
            updateWizardData,
            setIsSavingTPI: () => { },
            showErrors: false,
            onValidate: () => { },
            disregardFieldValidationParentPage: () => { },
            viewOnlyMode: true,
            showTitle: false
        },
        quoteProposalFailureErrorDiv: {
            visible: isQuoteProposalFailed
        },
        userNotAuthorisedErrorDiv: {
            visible: !canUserPayAndBindQuote
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            openTPIPageHandler,
            onValidate,
            onSaveClick: handleContactInfoUpdate
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    const readValue = useCallback(
        (id, path) =>
            readViewModelValue(
                metadata.pageContent,
                rewriteDataVM,
                id,
                path,
                overrideProps
            ),
        [rewriteDataVM, overrideProps]
    );

    /**
     * need to check payment reference Number and SignatureType, if ENV is not Localhost.
     * for localhost we are defaulting payment reference number
     */
    const isPageValid = useCallback(() => {
        if (requiresPayment && !isPaymentDone && !canUserIssueWithoutPayment) {
            return false;
        }

        let isSignatureDone = _get(rewriteDataVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;

        // for new business/forms not required, we don't care about licensed producer
        let licensedProducerSelected = _get(rewriteDataVM, 'value.bindData.licensedProducerID', false);

        if (!hasSignatureRequiredFormsInCurrentJob) {
            // no signature needed
            isSignatureDone = true;
            licensedProducerSelected = true;
        }

        return isSignatureDone && !hasUWIssues() && licensedProducerSelected;
    }, [requiresPayment, isPaymentDone, rewriteDataVM, signatureSuccess, hasSignatureRequiredFormsInCurrentJob, hasUWIssues, canUserIssueWithoutPayment]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, isPaymentDone, signatureSuccess]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            nextLabel={messages.issueLabelRewrite}
            onNext={onNext}
            disablePrevious={(isPaymentDone && requiresPayment) || gettingSignatureDocument || isInsuredContactUpdating}
            disableNext={gettingSignatureDocument || isInsuredContactUpdating}
            showNext={canUserPayAndBindQuote}
            showCancel={!isSignatureStatusAsCompleted}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && (!isMailingComponentValid || !isBillingComponentValid)
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteDataVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PaymentDetailsPage.propTypes = wizardProps;
export default PaymentDetailsPage;
