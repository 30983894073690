import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import './EHRiskAnalysisMiscComponent.messages';
import metadata from './EHRiskAnalysisMiscComponent.metadata.json5';


function EHRiskAnalysisMiscComponent(props) {
    const {
        submissionVM
    } = props;

    const quoteID = _get(submissionVM, 'quoteID.value') ?? _get(submissionVM, 'jobID.value');
    const policyType = _get(submissionVM, 'lobData.homeowners_EH.policyType.value.code') ?? _get(submissionVM, 'value.policyType');
    const isPolicyTypeHO3orHF9 = policyType === 'HO3' || policyType === 'HF9';

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true,
            autoComplete: false
        },
        roofScoreDataComponent: {
            quoteID,
            shouldCallApi: isPolicyTypeHO3orHF9
        },
        roofScoreTitle: {
            visible: isPolicyTypeHO3orHF9
        },
        roofScoreData: {
            visible: isPolicyTypeHO3orHF9
        },
        propertyInspectionTitle: {
            visible: isPolicyTypeHO3orHF9
        },
        propertyInspectionDataComponent: {
            transactionVM: submissionVM,
            visible: isPolicyTypeHO3orHF9
        },
        noMiscReportResultsMessageId: {
            visible: !isPolicyTypeHO3orHF9
        }
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [submissionVM, overrideProps]
    );

    const resolvers = {
        resolveCallbackMap: {}
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

EHRiskAnalysisMiscComponent.propTypes = {
    submissionVM: PropTypes.shape({})
};

EHRiskAnalysisMiscComponent.defaultProps = {
    submissionVM: {}
};

export default EHRiskAnalysisMiscComponent;
