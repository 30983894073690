import React, {
    useContext,
    useCallback,
    useState,
    useMemo,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { Button } from '@jutro/legacy/components';
import { valueTypeMap, getFormattedValue, processModalData } from './ScheduleItemsUtil';
import messages from './ScheduleItemsComponent.messages';
import styles from './ScheduleItemsComponent.module.scss';
import metadata from './ScheduleItemsComponent.metadata.json5';

function ScheduleItemsComponent(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const [isLoading, setLoading] = useState(false);
    const {
        value: scheduleItem,
        labelPosition,
        id,
        readOnly,
        onScheduleChange,
        path,
        showTitle,
        parentPageSubmitted
    } = props;
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [scheduleItemDetailsModalVisibility, setScheduleItemDetailsModalVisibility] = useState(false);
    const [scheduleDataDetails, setScheduleDataDetails] = useState({});
    const [showScheduleItemError, setShowScheduleItemError] = useState(false);
    const [localPathToSchedule, setLocalPathToSchedule] = useState(undefined);
    const renderCell = useCallback((items, index, property) => {
        const { id: pathToValue, valueType } = property.data;

        if (valueType === 'ICON') {
            const { icon, onClick } = property.data;

            return <Button className="actionIcon" icon={icon} type="outlined" onClick={onClick(items, index)} />;
        }

        const valueObject = _.get(items.itemData, pathToValue);
        const value = _.get(valueObject, valueTypeMap[valueType]);

        return getFormattedValue(value, valueType, property.data);
    }, []);

    const generateColumnOverrides = useCallback((columnData) => {
        const overrideProps = columnData.map((info, index) => ({
            [`scheduleItemColumn${index}`]: {
                renderCell,
                header: info.label
            }
        }));

        return Object.assign({}, ...overrideProps);
    }, [renderCell]);

    const onScheduleChangeCall = useCallback((clonedSchedule) => {
        if (onScheduleChange) {
            setLoading(true);
            onScheduleChange(clonedSchedule, path).finally(() => setLoading(false));
        }
    }, [onScheduleChange, path]);

    const onSaveAndCloseOfScheduleItemsDetailModal = useCallback((schedule) => {
        if (schedule) {
            const clonedSchedule = _.cloneDeep(scheduleItem);

            if (isAddingNew) {
                const newScheduleItem = {
                    '@deserialization-class': scheduleItem.deserializationClass,
                    ...schedule
                };

                clonedSchedule.scheduleItems = [...scheduleItem.scheduleItems, newScheduleItem];
            } else {
                _.set(clonedSchedule, localPathToSchedule, schedule);
            }

            onScheduleChangeCall(clonedSchedule);
        }

        setLocalPathToSchedule(undefined);
        setScheduleDataDetails({});
        setScheduleItemDetailsModalVisibility(false);
        setIsAddingNew(false);
    }, [isAddingNew, localPathToSchedule, onScheduleChangeCall, scheduleItem]);

    const editScheduleDetail = useCallback((schedule, index) => () => {
        setLocalPathToSchedule(`scheduleItems[${index}]`);
        setScheduleDataDetails(schedule);
        setScheduleItemDetailsModalVisibility(true);
    }, []);

    const addScheduleDetail = useCallback(() => {
        setIsAddingNew(true);
        setScheduleItemDetailsModalVisibility(true);
    }, []);

    useEffect(() => {
        /**
         * E1PAP1PC-13532
         * Displaying first schedule instance by default
         */
        if (scheduleItem.scheduleItems.length < 1 && scheduleItem.referenceCode) {
            addScheduleDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addScheduleDetail, scheduleItem.scheduleItems.length]);

    const removeSchedule = useCallback((scheduleToRemove) => () => {
        const clonedSchedule = _.cloneDeep(scheduleItem);

        clonedSchedule.scheduleItems = clonedSchedule.scheduleItems.filter(
            (schedule) => !_.isEqual(schedule, scheduleToRemove)
        );
        onScheduleChangeCall(clonedSchedule);
    }, [onScheduleChangeCall, scheduleItem]);

    const columnData = useMemo(() => {
        const orderedPropertyInfo = _.sortBy(scheduleItem.propertyInfos, 'order');
        const icons = [];
        const scheduleItemsCount = scheduleItem.scheduleItems.length;
        const trashIcon = {
            valueType: 'ICON',
            icon: 'mi-delete',
            onClick: removeSchedule
        };

        /**
         * E1PAP1PC-13532
         * Don't want to show cancel button while adding schedule item
         * when we have only one schedule item
         */
        if (scheduleItemsCount > 1) {
            icons.push(trashIcon);
        }

        icons.push(
            {
                valueType: 'ICON',
                icon: 'mi-edit',
                onClick: editScheduleDetail
            }
        );

        const iconsShow = !readOnly ? icons : [];

        return [...orderedPropertyInfo, ...iconsShow];
    }, [editScheduleDetail, readOnly, removeSchedule, scheduleItem.propertyInfos, scheduleItem.scheduleItems.length]);

    const renderAdd = useCallback(() => (
        <Button icon="mi-add" onClick={addScheduleDetail} type="outlined">
            {messages.scheduleAdd}
        </Button>
    ), [addScheduleDetail]);

    const overrideProps = {
        scheduleItemTable: {
            data: columnData,
            title: showTitle ? messages.listOfScheduledItems : '',
            renderTitleAction: !readOnly ? renderAdd : undefined,
            placeholder: readOnly ? messages.noItems : '',
            visible: !!scheduleItem.referenceCode
        },
        ehScheduleItemDetailsModal: {
            propertyInfo: scheduleItem.propertyInfos,
            scheduleData: processModalData(scheduleDataDetails),
            viewModelService,
            scheduleItem,
            labelPosition,
            id,
            isNew: isAddingNew,
            visible: scheduleItemDetailsModalVisibility,
            onSaveAndCloseOfScheduleItemsDetailModal,
            parentPageSubmitted,
            setShowScheduleItemError
        },
        errorMessage: {
            messageProps: {
                error: e1pCommonMessages.pleaseCompleteTheMissingFields
            }
        },
        errorMessageDiv: {
            visible: showScheduleItemError
        },
        ...generateColumnOverrides(columnData)
    };

    const readValue = useCallback((elementId, schedulePath) => _.get(scheduleItem, schedulePath), [scheduleItem]);

    const resolvers = {
        resolveValue: readValue,
        resolveClassNameMap: styles,
    };

    if (isLoading) {
        return <E1PLoader loaded={!isLoading} />;
    }

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
}

ScheduleItemsComponent.propTypes = {
    value: PropTypes.shape({
        scheduleItems: PropTypes.arrayOf(PropTypes.shape({})),
        propertyInfos: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    onScheduleChange: PropTypes.func,
    path: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    labelPosition: PropTypes.string,
    readOnly: PropTypes.bool,
    showTitle: PropTypes.bool,
    parentPageSubmitted: PropTypes.bool
};

ScheduleItemsComponent.defaultProps = {
    onScheduleChange: undefined,
    readOnly: false,
    labelPosition: undefined,
    showTitle: true,
    parentPageSubmitted: false
};

export default ScheduleItemsComponent;
