import { defineMessages } from 'react-intl';

export default defineMessages({
    validLicenseErrorMesage: {
        id: 'components.e1pLicenseComponent.Invalid driver license number',
        defaultMessage: 'Invalid driver license number'
    },
    licenseMatchMessage: {
        id: 'components.e1pLicenseComponent.LicenseMatchMsg',
        defaultMessage:
            "Sorry, but this driver's license number matches that of {driver}. Please ensure that all license numbers are unique."
    },
    licenseMatchTitle: {
        id: 'components.e1pLicenseComponent.License number is not unique',
        defaultMessage: 'License number is not unique'
    },
    licenseValidationError: {
        id: "components.e1pLicenseComponent.Driver's License Validation Error",
        defaultMessage: "Driver's License Validation Error"
    },
    licenseValidationErrorMessage: {
        id: "components.e1pLicenseComponent.The Driver's License validation service is currently down and this transaction cannot continue. Please retry later",
        defaultMessage: "The Driver's License validation service is currently down and this transaction cannot continue. Please retry later"
    },
    validatingDriverLicense: {
        id: "components.e1pLicenseComponent.Validating Driver's License number...",
        defaultMessage: "Validating Driver's License number..."
    },
});
