import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processUWHistory(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('underwriting'), method, data, additionalHeaders);
}


export default class UnderwritingService {
    /**
    * Reterive Underwriting Issue History.
    *
    * @param {Object} uwIssuesId uwIssuesId
    * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
    * @returns {Promise} the promise from the backend call
    * @memberof UnderwritingService
    */
    static getUWHistory(uwIssuesId, additionalHeaders = {}) {
        return processUWHistory(
            'getUWHistory',
            [uwIssuesId],
            additionalHeaders
        );
    }
}
