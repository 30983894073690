/* eslint-disable radix */
import React, { Component } from 'react';
import cx from 'classnames';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import { ActivitiesService } from 'e1p-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { E1PAssignActivitiesComponent } from 'e1p-capability-policyjob-react';
import { withModalContext } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import _ from 'lodash';
import ActivityTableComponent from '../Components/Activity/ActivityTableComponent/ActivityTableComponent';
import ActivityAdvancedFilter from '../Components/Activity/AdvancedFilter/ActivityAdvancedFilter';
import messages from './Activities.messages';
import metadata from './Activities.metadata.json5';
import styles from './Activities.module.scss';

const tableHeadings = {
    yourOpenActivityTile: messages.yourOpen,
    yourCompletedActivityTile: messages.yourComplete,
    createdByYouActivityTile: messages.createdByYou,
    allOpenActivityTile: messages.allOpen,
    allCompletedActivityTile: messages.allCompleted,
    allCancelledActivityTile: messages.allCanceled,
    advancedFilter: messages.activities
};

class ActivitiesWithoutModalContext extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({}),
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired
    };

    static defaultProps = {
        authHeader: undefined
    };

    static contextType = TranslatorContext;

    state = {
        activitiesYourCompleteCount: 0,
        activitiesYourOpenCount: 0,
        activitiesAllOpenCount: 0,
        activitiesCreatedByYouCount: 0,
        activitiesAllCompletedCount: 0,
        activitiesCanceledCount: 0,
        yourOpenActivityTileStatus: true,
        yourCompletedActivityTileStatus: false,
        createdByYouActivityTileStatus: false,
        allOpenActivityTileStatus: false,
        allCompletedActivityTileStatus: false,
        allCancelledActivityTileStatus: false,
        toggleAdvancedFilter: true,
        currentView: 'yourOpenActivityTile',
        activityTableKey: 'yourOpenActivityTile',
        isCallingService: false,
        selectedAdvancedFilters: [],
    };

    componentDidMount() {
        this.getTilesCount();
    }

    getTilesCount = () => {
        const { authHeader } = this.props;
        const allOpenActivityPromise = ActivitiesService.getActivitiesCountByStatus('open', authHeader);
        const openActivitiesForCurrentUserPromise = ActivitiesService.getActivitiesCountByStatusForCurrentUser('open', authHeader);
        const allCompletedActivitiesPromise = ActivitiesService.getActivitiesCountByStatus('complete', authHeader);
        const completedActivitesForCurrentUserPromise = ActivitiesService.getActivitiesCountByStatusForCurrentUser('complete', authHeader);
        const canceledActivitesPromise = ActivitiesService.getActivitiesCountByStatus('canceled', authHeader);
        const activitesCountCreatedByCurrentUserPromise = ActivitiesService.getCreatedeByCurrentUserActivitiesCount(authHeader);

        /**
         * IAP-3262
         * using Promise.allSettled, so that once all the API's are resolved, we can do setState only once.
         * this will render component only once, separte setStates here will re render the component multiple times,
         * which will call getActivities Api from the child component(even though all the props are same),
         * since the parent component is re-rendered. 
         */

        Promise.allSettled([
            allOpenActivityPromise,
            openActivitiesForCurrentUserPromise,
            allCompletedActivitiesPromise,
            completedActivitesForCurrentUserPromise,
            canceledActivitesPromise,
            activitesCountCreatedByCurrentUserPromise
        ]).then(([
            allOpenActivityResult,
            openActivitiesForCurrentUserResult,
            allCompletedActivitiesResult,
            completedActivitesForCurrentUserResult,
            canceledActivitesResult,
            activitesCountCreatedByCurrentUserResult
        ]) => {
            this.setState({
                activitiesAllOpenCount: allOpenActivityResult.status === 'fulfilled' ? parseInt(allOpenActivityResult.value) : 0,
                activitiesYourOpenCount: openActivitiesForCurrentUserResult.status === 'fulfilled' ? parseInt(openActivitiesForCurrentUserResult.value) : 0,
                activitiesAllCompletedCount: allCompletedActivitiesResult.status === 'fulfilled' ? parseInt(allCompletedActivitiesResult.value) : 0,
                activitiesYourCompleteCount: completedActivitesForCurrentUserResult.status === 'fulfilled' ? parseInt(completedActivitesForCurrentUserResult.value) : 0,
                activitiesCanceledCount: canceledActivitesResult.status === 'fulfilled' ? parseInt(canceledActivitesResult.value) : 0,
                activitiesCreatedByYouCount: activitesCountCreatedByCurrentUserResult.status === 'fulfilled' ? parseInt(activitesCountCreatedByCurrentUserResult.value) : 0
            });
        });
    }

    onClickCompleteActivities = (activitiesPublicIds) => {
        const { authHeader } = this.props;

        this.setState({
            isCallingService: true
        });
        ActivitiesService.markActivitiesAsCompleted(activitiesPublicIds, authHeader)
            .then(() => {
                this.getTilesCount();
                this.setState({
                    isCallingService: false
                });
            }, (error) => {
                this.props.modalContext.showAlert({
                    title: commonMessages.genericError,
                    message: error.baseError,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.close
                }).catch(_.noop);
                this.setState({
                    isCallingService: false
                });
            });
    }

    onClickAddActivitiesToMyQueue = (activitiesPublicIds) => {
        const { authHeader } = this.props;

        this.setState({
            isCallingService: true
        });
        ActivitiesService.assignActivitiesToCurrentUser(activitiesPublicIds, authHeader)
            .then(() => {
                this.getTilesCount();
                this.setState({
                    isCallingService: false
                });
            }, (error) => {
                this.props.modalContext.showAlert({
                    title: commonMessages.genericError,
                    message: error.baseError,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.close
                }).catch(_.noop);
                this.setState({
                    isCallingService: false
                });
            });
    }

    showActivitiesAssignmentPopOver = async (activitiesPublicIDs) => {
        const { authHeader } = this.props;
        const componentProps = {
            iconClassType: false,
            showCancelBtn: false,
            authHeader,
            activitiesPublicIDs
        };
        const result = await this.props.modalContext.showModal(
            <E1PAssignActivitiesComponent {...componentProps} />
        );

        return result;
    };

    onClickAssignActivities = (activitiesPublicIds) => {
        if (_.isEmpty(activitiesPublicIds)) {
            return;
        }

        // eslint-disable-next-line consistent-return
        return this.showActivitiesAssignmentPopOver(activitiesPublicIds)
            .then(() => {
                this.setState({
                    isCallingService: true
                });
                this.getTilesCount();
            }).catch(_.noop())
            .finally(() => {
                this.setState({
                    isCallingService: false
                });
            });
    }

    activityReassigned = () => {
        this.setState({
            isCallingService: true
        });
        this.getTilesCount();
        this.setState({
            isCallingService: false
        });
    };

    handleFilterClick = () => {
        const { toggleAdvancedFilter, currentView } = this.state;

        this.setState({ toggleAdvancedFilter: !toggleAdvancedFilter, selectedAdvancedFilters: [] });
        this.handleOnClick(currentView);
    };

    handleOnClick = (id) => {
        let yourOpenActivityTile = false;
        let yourCompletedActivityTile = false;
        let createdByYouActivityTile = false;
        let allOpenActivityTile = false;
        let allCompletedActivityTile = false;
        let allCancelledActivityTile = false;

        switch (id) {
            case 'yourOpenActivityTile':
                yourOpenActivityTile = true;
                break;
            case 'yourCompletedActivityTile':
                yourCompletedActivityTile = true;
                break;
            case 'createdByYouActivityTile':
                createdByYouActivityTile = true;
                break;
            case 'allOpenActivityTile':
                allOpenActivityTile = true;
                break;
            case 'allCompletedActivityTile':
                allCompletedActivityTile = true;
                break;
            case 'allCancelledActivityTile':
                allCancelledActivityTile = true;
                break;
            default:
                break;
        }

        this.setState({
            currentView: id,
            activityTableKey: id,
            yourOpenActivityTileStatus: yourOpenActivityTile,
            yourCompletedActivityTileStatus: yourCompletedActivityTile,
            createdByYouActivityTileStatus: createdByYouActivityTile,
            allOpenActivityTileStatus: allOpenActivityTile,
            allCompletedActivityTileStatus: allCompletedActivityTile,
            allCancelledActivityTileStatus: allCancelledActivityTile
        });
    };

    getSelectedTitle = () => {
        const { toggleAdvancedFilter, currentView } = this.state;

        return toggleAdvancedFilter ? tableHeadings[currentView] : messages.activities;
    };

    onFilterValueChange = (appliedFilters) => {
        const { currentView } = this.state;

        this.setState({
            // Guidewire Async data table has no method to update data with new filters manually
            // We need to re-create the table - assigning new key will re-mount the table
            activityTableKey: `${currentView}${new Date().getTime()}`,
            selectedAdvancedFilters: appliedFilters
        });
    };

    render() {
        const translator = this.context;
        const {
            yourOpenActivityTileStatus,
            yourCompletedActivityTileStatus,
            createdByYouActivityTileStatus,
            allOpenActivityTileStatus,
            allCompletedActivityTileStatus,
            allCancelledActivityTileStatus,
            activitiesYourOpenCount,
            activitiesCanceledCount,
            activitiesAllOpenCount,
            activitiesAllCompletedCount,
            activitiesCreatedByYouCount,
            activitiesYourCompleteCount,
            toggleAdvancedFilter,
            currentView,
            isCallingService,
            activitiesResult,
            activityTableKey,
            selectedAdvancedFilters,
        } = this.state;

        const overrides = {
            yourOpenActivityTile: {
                value: activitiesYourOpenCount,
                active: yourOpenActivityTileStatus
            },
            yourCompletedActivityTile: {
                value: activitiesYourCompleteCount,
                active: yourCompletedActivityTileStatus
            },
            createdByYouActivityTile: {
                value: activitiesCreatedByYouCount,
                active: createdByYouActivityTileStatus
            },
            allOpenActivityTile: {
                value: activitiesAllOpenCount,
                active: allOpenActivityTileStatus
            },
            allCompletedActivityTile: {
                value: activitiesAllCompletedCount,
                active: allCompletedActivityTileStatus
            },
            allCancelledActivityTile: {
                value: activitiesCanceledCount,
                active: allCancelledActivityTileStatus
            },
            advancedFilter: {
                activities: activitiesResult,
                selectedTile: currentView,
                visible: !toggleAdvancedFilter,
                onValueChange: this.onFilterValueChange
            },
            advanceFilterLink: {
                visible: toggleAdvancedFilter
            },
            quickFilterLink: {
                visible: !toggleAdvancedFilter
            },
            quickFilterContainer: {
                visible: toggleAdvancedFilter
            },
            activitiesTableWrapper: {
                className: cx(styles.gwActivitiesTableWrapper, {
                    [styles.gwAdvancedFilterTableContainer]: toggleAdvancedFilter,
                    [styles.gwQuickFilterTableContainer]: !toggleAdvancedFilter
                })
            },
            e1pActivitiesTableContainer: {
                tableKey: activityTableKey,
                onClickCompleteActivities: this.onClickCompleteActivities,
                onClickAddActivitiesToMyQueue: this.onClickAddActivitiesToMyQueue,
                onClickAssignActivities: this.onClickAssignActivities,
                activityReassigned: this.activityReassigned,
                currentView,
                currentViewHeading: translator(this.getSelectedTitle()),
                selectedAdvancedFilters: toggleAdvancedFilter ? [] : selectedAdvancedFilters
            },
            e1pActivityTableMainContainer: {
                visible: !isCallingService,
            },
            activitiesLoader: {
                loaded: !isCallingService
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                advancedfilter: ActivityAdvancedFilter,
                activitytablecomponent: ActivityTableComponent
            },
            resolveCallbackMap: {
                handleOnClick: this.handleOnClick,
                handleFilterClick: this.handleFilterClick,
                handleCustomFilterClick: this.handleCustomFilterClick
            }
        };

        return (
            <div className={styles.activities}>
                <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />
            </div>
        );
    }
}

const Activities = withModalContext(ActivitiesWithoutModalContext);

export const ActivitiesComponent = Activities;
export default withAuthenticationContext(Activities);
