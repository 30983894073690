import React, {
    useContext, useCallback, useEffect, useRef, useMemo, useState,
} from 'react';
import {
    get, set, every, includes, isUndefined,
    pullAt, some, find, findIndex, isEmpty
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, WizardPageTemplateWithTitle, wizardProps } from 'e1p-portals-wizard-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { RenewalService } from 'e1p-capability-renewal';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';

import messages from './UnderlyingPolicyPage.messages';
import wizardMessages from '../../EURenewalWizard.messages';
import metadata from './UnderlyingPolicyPage.metadata.json5';
import EUVehicleOperatorPage from '../VehicleOperator/VehicleOperatorPage';

function UnderlyingPolicyPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        currentStepIndex,
        changeNextSteps,
        isSkipping,
        jumpTo,
        updateWizardSnapshot,
        steps,
        onCustom2: handleDiscardChanges
    } = props;
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerComponentValidation
    } = useValidation('UnderlyingPolicyPage');
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const deletedItemsRef = useRef([]);
    const underlyingPoliciesVM = useMemo(() => get(renewalVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies', []), [renewalVM]);
    const isDiscardButtonActive = get(renewalVM, 'value.canDiscard_Ext', false);
    const changeSummaryIndex = findIndex(steps, ({ path }) => path === '/change-review');
    const jobType = get(renewalVM.value, 'baseData.jobType');

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [renewalVM, isComponentValid, isPageSubmitted]);

    // DO NOT change the ID of this page without changing step to field mappings config
    const vehicleOperatorPage = useRef({
        id: 'EURenewalVehicleOperatorPage',
        path: '/vehicle-operator',
        component: EUVehicleOperatorPage,
        title: {
            id: 'renewal.eu.directives.templates.page.Vehicle Operator',
            defaultMessage: 'Vehicle Operator',
        },
        stepProps: {
            template: WizardPageTemplateWithTitle
        },
    }).current;

    const handleVehicleOperatorPage = useCallback(() => {
        const remainingSteps = steps.slice(currentStepIndex + 1, steps.length);
        const vehicleUnderlyingPolicyExists = some(underlyingPoliciesVM.value,
            (policy) => includes(['personalauto', 'personalaononowner', 'otherspecialityvehicle', 'cycle'],
                policy.policyType));

        if (vehicleUnderlyingPolicyExists && !find(steps, ['id', vehicleOperatorPage.id])) {
            remainingSteps.unshift(vehicleOperatorPage);
        }

        if (!vehicleUnderlyingPolicyExists && find(steps, ['id', vehicleOperatorPage.id])) {
            remainingSteps.shift(vehicleOperatorPage);
        }

        changeNextSteps(remainingSteps);

        return vehicleUnderlyingPolicyExists;
    }, [changeNextSteps, currentStepIndex, steps, underlyingPoliciesVM.value, vehicleOperatorPage]);

    useEffect(() => {
        if (isUndefined(underlyingPoliciesVM.value)) {
            set(underlyingPoliciesVM, 'value', []);
        }

        updateWizardData(renewalVM);
        handleVehicleOperatorPage();
        registerComponentValidation(() => every(underlyingPoliciesVM.children,
                (el, index) => (el.aspects.subtreeValid
                    || some(deletedItemsRef.current, (i) => i === index)))
                && !!(underlyingPoliciesVM.value
                    .filter((_, index) => !deletedItemsRef?.current?.includes(index))
                    .find((policy) => policy.policyType === 'personalproperty' && policy.isPrimaryInd)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);
            pullAt(underlyingPoliciesVM.value, deletedItemsRef.current);
            set(renewalVM, 'flowStepIDs_Ext.value', ['underlyingproperty']);
            set(renewalVM, 'entryCompletionStepIDs_Ext.value', ['underlyingproperty']);

            const vehicleUnderlyingPolicyExists = await handleVehicleOperatorPage();

            // if we are not showing vehicle operator page then we need to do saveAndQuote from here only
            if (!vehicleUnderlyingPolicyExists) {
                renewalVM.value = await RenewalService.saveAndQuoteRenewal(
                    [renewalVM.value],
                    authHeader
                );
            } else {
                renewalVM.value = await RenewalService.saveRenewal(
                    [renewalVM.value],
                    authHeader
                );
            }

            // updating deletedItemsRef to empty, once we delete underlying policy.
            deletedItemsRef.current = [];
            setIsSavingRenewal(false);
            // Added Two underlying policies and deleted auto , backend is sending validations for auto
            // so updated wizarddata to show them on UI.
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);

            return renewalVM;
        },
        [authHeader, handleVehicleOperatorPage, isComponentValid, renewalVM, underlyingPoliciesVM, updateWizardData, updateWizardSnapshot]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    // Updating noOperatorReasonOther value , which makes wizard and snapshot data equal.
                    // It will avoid unnecessary modal popup for saved changes.
                    if(get(renewalVM.lobData.personalUmbrella_EU, 'noOperatorReasonOther.value', undefined) === undefined) {
                        set(renewalVM.lobData.personalUmbrella_EU, 'noOperatorReasonOther.value', undefined);
                    }

                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setIsSavingRenewal(false);

            return false;
        },
        [
            authHeader, changeSummaryIndex, isComponentValid, jumpTo,
            renewalVM, updateWizardData, updateWizardSnapshot
        ]
    );

    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        underlyingPolicyPageLoadingIndicator: {
            loaded: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessage)
        },
        underlyingPolicyPageContainer: {
            visible: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges
        },
        euUnderlyingPolicyComponent: {
            data: renewalVM,
            updateWizardData,
            isPageSubmitted,
            isPrivatePassengerQuestionVisible: false,
            deletedItemsRef,
            jobType
        }
    };

    const writeValue = useCallback(
        (newVal, path) => {
            set(renewalVM, `${path}.value`, newVal);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            nextLabel={translator(messages.allUnderlyingPolicisAdded)}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

UnderlyingPolicyPage.propTypes = wizardProps;
export default UnderlyingPolicyPage;
