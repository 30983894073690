import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import {
    get, set, includes, isEmpty
} from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { commonMessages as e1pCommonMessages, ehCommonMessages } from 'e1p-platform-translations';
import { PropertyFlowUtil } from 'e1p-portals-util-js';
import config from 'app-config';
import htmlParser from 'html-react-parser';
import { E1PPropertyPageSectionHeaderComponent } from 'e1p-capability-policyjob-react';
import metadata from './E1PEHHO6PropertyDetailsComponent.metadata.json5';
import styles from './E1PEHHO6PropertyDetailsComponent.module.scss';
import requiredMetadata from './E1PEHHO6PropertyDetailsComponent.requiredness';


const SECTION_STATUS = {
    SUCCESS: 'success',
    FAILURE: 'failure',
    NONE: 'none'
};

function E1PEHHO6PropertyDetailsComponent(props) {
    const {
        transactionVM,
        onValidate,
        isPageSubmitted,
        updateWizardData,
        setShouldSkipAdditionalInfo,
        isSavingCurrentPageChanges,
        isSavingQuote,
        viewOnlyMode,
        updateHeatingSystemType,
        heatingSystemType
    } = props;

    const { authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [exteriorStatus, updateExteriorStatus] = useState(SECTION_STATUS.NONE);
    const [animalsStatus, updateAnimalsStatus] = useState(SECTION_STATUS.NONE);
    const [propertyInformationStatus, updatePropertyInformationStatus] = useState(SECTION_STATUS.NONE);
    const [heatingAndCoolingStatus, updateHeatingAndCoolingStatus] = useState(SECTION_STATUS.NONE);
    const [expandedSections, setExpandedSections] = useState([
        'propertyInformationAccordionCard',
        'exteriorAccordionCard',
        'animalsAccordionCard',
        'heatingAndCoolingAccordionCard'
    ]);
    const translator = useTranslator();
    const policyState = get(transactionVM, 'baseData.policyAddress.state.value.code') || get(transactionVM, 'policyAddress.state.value.code');
    const [requiredFields, updateRequiredFields] = useState([]);
    const [areAllRequiredFieldsPresent, setAreAllRequiredFieldsPresent] = useState(false);
    const heatingTypeValue = get(transactionVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value.[0].heatingType',[])
    const canChangeYearBuilt = authUserData?.permissions_Ext.includes('edityearbuilt_ext');
    const jobType = get(transactionVM.value, 'baseData.jobType') || get(transactionVM, 'value.jobType');

    const renderAccordionHeader = (title, status) => (isOpen) => (
            <E1PPropertyPageSectionHeaderComponent
                isOpen={isOpen}
                title={title}
                status={status}
            />
        );

    const currentDateYear = (() => {
        const today = new Date();

        return today.getFullYear();
    })();
    const { minYearBuilt } = config.enterpriseHomeownersConfig;

    useEffect(() => {
        /**
         * IAP-3362 : HO4 & HO6-Unable to Navigate from Coverages Screen
         * As we are deciding to finish wizard flow on coverage page based on shouldSkipAdditionalInfo
         * We need to set this value to true for HO6
         */
        setShouldSkipAdditionalInfo(true);

        if (isEmpty(transactionVM.lobData.homeowners_EH.coverables.yourHome.value)) {
            transactionVM.lobData.homeowners_EH.coverables.yourHome = {};
            updateWizardData(transactionVM);
        }

        if (isEmpty(transactionVM.lobData.homeowners_EH.coverables.construction.value)) {
            transactionVM.lobData.homeowners_EH.coverables.construction = {};
            updateWizardData(transactionVM);
        }

        const heatingSystems = get(transactionVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');
        let tempHeatingSystemType;

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            updateHeatingSystemType(heatingSystems[0].heatingType);
            tempHeatingSystemType = heatingSystems[0].heatingType;
        }

        setIsPageInitialized(true);

        const allRequiredFieldsPresent = PropertyFlowUtil.checkAllRequiredFieldsArePresentHO6(transactionVM, currentDateYear, minYearBuilt,
            tempHeatingSystemType);

        setAreAllRequiredFieldsPresent(allRequiredFieldsPresent);

        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(
        () => {
            const initialRequiredFields = [
                'yearBuilt',
                'numberOfUnits',
                'extereriorWallFinish',
                'hasDogInHome',
                'highRiskDogBreedInd',
                'primaryHeatingSystem'
            ]; // Fields to look up by partner/state

            updateRequiredFields(
                isRequired(
                    initialRequiredFields,
                    requiredMetadata,
                    policyState,
                    'MSA'
                )
            );
            // When policystate changes update the required fields
        }, [policyState]
    );


    useEffect(
        () => {
            const yearBuilt = parseInt(
                transactionVM.lobData.homeowners_EH.coverables.construction.yearBuilt.value,
                10
            );
            const hasValidYearBuilt = (currentDateYear >= yearBuilt) && (yearBuilt >= minYearBuilt);

            if (hasValidYearBuilt
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value !== '') {
                updatePropertyInformationStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || propertyInformationStatus !== SECTION_STATUS.NONE) {
                updatePropertyInformationStatus(SECTION_STATUS.FAILURE);
            }
        },
        [
            transactionVM.lobData.homeowners_EH.coverables.construction.yearBuilt.value,
            transactionVM.lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value,
            minYearBuilt,
            currentDateYear,
            propertyInformationStatus,
            isPageSubmitted
        ]
    );

    useEffect(
        () => {
            const isHighRiskDogBreedRequired = includes(requiredFields, 'highRiskDogBreedInd');
            const isHightRiskDogBreedAnswered = transactionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value !== undefined
            && transactionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value !== '';
            const isAnimalBittenAnswered = transactionVM.lobData.homeowners_EH.coverables.yourHome.hasAnimalBitten.value !== undefined
            && transactionVM.lobData.homeowners_EH.coverables.yourHome.hasAnimalBitten.value !== '';

            // dogInHome is false
            if (transactionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === false) {
                updateAnimalsStatus(SECTION_STATUS.SUCCESS);
            }
            // dogInHome is true
            else if (transactionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === true) {
                // highRiskdog and animalBitten is visible
                if (isHighRiskDogBreedRequired && isHightRiskDogBreedAnswered && isAnimalBittenAnswered) {
                    updateAnimalsStatus(SECTION_STATUS.SUCCESS);
                }
                // highRiskdog is not visible and animalBitten is visible
                else if (!isHighRiskDogBreedRequired && isAnimalBittenAnswered) {
                    updateAnimalsStatus(SECTION_STATUS.SUCCESS);
                }
                else if (isPageSubmitted || animalsStatus !== SECTION_STATUS.NONE) {
                    updateAnimalsStatus(SECTION_STATUS.FAILURE);
                }
            }
        },
        [transactionVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value, transactionVM.lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd.value, transactionVM.lobData.homeowners_EH.coverables.yourHome.hasAnimalBitten.value, isPageSubmitted, animalsStatus, requiredFields]
    );

    useEffect(
        () => {
            if (heatingSystemType !== undefined) {
                updateHeatingAndCoolingStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || heatingAndCoolingStatus !== SECTION_STATUS.NONE) {
                updateHeatingAndCoolingStatus(SECTION_STATUS.FAILURE);
            }
        },
        [heatingAndCoolingStatus, heatingSystemType, isPageSubmitted]
    );

    useEffect(
        () => {
            if (transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value !== undefined
                && transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value !== '') {
                updateExteriorStatus(SECTION_STATUS.SUCCESS);
            } else if (isPageSubmitted || exteriorStatus !== SECTION_STATUS.NONE) {
                updateExteriorStatus(SECTION_STATUS.FAILURE);
            }
        },
        [exteriorStatus, isPageSubmitted, transactionVM.lobData.homeowners_EH.coverables.construction.exteriorWallFinish.value]
    );

    const heatingSystemTypes = useMemo(() => {
        const heatingSystemDTO = viewModelService.create({}, 'pc', 'amfam.edge.capabilities.policyjob.lob.eh.coverables.dto.EHHeatingSystemDTO');

        return heatingSystemDTO.heatingType.aspects.availableValues.map((item) => ({
                code: item.code,
                name: {
                    id: item.name,
                }
            }));
    }, [viewModelService]);

    const heatingSystemTypeChange = useCallback((value) => {
        updateHeatingSystemType(value);

        const heatingSystems = get(transactionVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            set(transactionVM.value, `lobData.homeowners_EH.coverables.construction.heatingSystems[${0}].heatingType`, value);
        } else {
            const heatingSystem = {
                heatingType: value,
                isPrimary: true
            };
            const {
                _xCenter,
                _dtoName,
            } = transactionVM.lobData.homeowners_EH.coverables.construction.heatingSystems;
            const heatingSystemVM = viewModelService.create(heatingSystem, _xCenter, _dtoName);

            transactionVM.lobData.homeowners_EH.coverables.construction.heatingSystems.value.push(
                heatingSystemVM.value
            );
        }

        updateWizardData(transactionVM);
    }, [transactionVM, viewModelService, updateWizardData, updateHeatingSystemType]);

    const dogInHomeChange = useCallback(
        (value) => {
            set(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome', value);

            if (!value) {
                set(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd', undefined);
                set(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasAnimalBitten', undefined);
            }

            updateWizardData(transactionVM);
        },
        [transactionVM, updateWizardData]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false,
            readOnly: viewOnlyMode
        },
        propertyPageLoadingIndicator: {
            loaded: isPageInitialized && !isSavingQuote && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(ehCommonMessages.loadingNextPageMessage)
        },
        propertyPageContainer: {
            visible: isPageInitialized && !isSavingQuote && !isSavingCurrentPageChanges
        },
        yearBuilt: {
            required: includes(requiredFields, 'yearBuilt'),
            maxValue: currentDateYear,
            onValidate,
            minValue: minYearBuilt,
            readOnly: viewOnlyMode || (jobType !== 'Submission' && !canChangeYearBuilt)
        },
        numberOfUnits: {
            required: includes(requiredFields, 'numberOfUnits')
        },
        extereriorWallFinish: {
            required: includes(requiredFields, 'extereriorWallFinish')
        },
        primaryHeatingSystem: {
            availableValues: heatingSystemTypes,
            onValueChange: heatingSystemTypeChange,
            value: viewOnlyMode ? heatingTypeValue : heatingSystemType,
            required: includes(requiredFields, 'primaryHeatingSystem')
        },
        dogInHome: {
            required: includes(requiredFields, 'hasDogInHome'),
            onValueChange: dogInHomeChange,
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        dogBreedSet1: {
            content: htmlParser(translator(ehCommonMessages.akitaAndPitbull))
        },
        dogBreedSet2: {
            content: htmlParser(translator(ehCommonMessages.alaskanAndRottweiler))
        },
        dogBreedSet3: {
            content: htmlParser(translator(ehCommonMessages.chowChowAndStaffordshire))
        },
        dogBreedSet4: {
            content: htmlParser(translator(ehCommonMessages.doberman))
        },
        highRiskDogBreedIndGridWithSeprator: {
            visible: includes(requiredFields, 'highRiskDogBreedInd')
            && !!get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
        },
        highRiskDogBreedInd: {
            required: includes(requiredFields, 'highRiskDogBreedInd')
            && !!get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        doesAnyDogHaveAnyBiteHistorydGridWithSeprator: {
            visible: !!get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
        },
        doesAnyDogHaveAnyBiteHistory: {
            required: !!get(transactionVM.value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome'),
            className: viewOnlyMode ? 'toggleFieldReadOnly' : 'toggleField'
        },
        heatingAndCoolingAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.heatingAndCooling, heatingAndCoolingStatus)
        },
        animalsAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.animals, animalsStatus)
        },
        exteriorAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.exterior, exteriorStatus)
        },
        propertyInformationAccordionCard: {
            renderHeader: renderAccordionHeader(ehCommonMessages.propertyInformation, propertyInformationStatus)
        },
        Accordion: {
            accordionStates: expandedSections,
            onUpdateAccordionStates: (openedSections) => {
                setExpandedSections(openedSections);
            }
        },
        pleaseProvideInfoOnPropertyDiv: {
            visible: jobType === 'Submission' && !areAllRequiredFieldsPresent && !viewOnlyMode
        }
    };

    const readValue = useCallback((fieldId, fieldPath) => readViewModelValue(
            metadata.pageContent, transactionVM, fieldId, fieldPath, overrideProps
        ), [transactionVM, overrideProps]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionVM}
            resolveValue={readValue}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            onValidationChange={onValidate}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

E1PEHHO6PropertyDetailsComponent.propTypes = {
    transactionVM: PropTypes.shape({}),
    onValidate:  PropTypes.func.isRequired,
    updateWizardData:  PropTypes.func.isRequired,
    isPageSubmitted: PropTypes.bool,
    setShouldSkipAdditionalInfo: PropTypes.func.isRequired,
    isSavingCurrentPageChanges: PropTypes.bool,
    isSavingQuote: PropTypes.bool,
    heatingSystemType: PropTypes.func,
    updateHeatingSystemType: PropTypes.func,
    viewOnlyMode: PropTypes.bool
};

E1PEHHO6PropertyDetailsComponent.defaultProps = {
    isPageSubmitted: false,
    onValidate: undefined
    
};
export default E1PEHHO6PropertyDetailsComponent;
