import React, { useCallback } from 'react';

import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { InlineNotification } from '@jutro/components';
import { pageTemplateProps } from '../../prop-types/wizardPropTypes';
import WizardPageHeader from '../../components/WizardPageHeader/WizardPageHeader';
import DigitalWizardPageTemplate from '../DigitalWizardPageTemplate';

import styles from './E1PDigitalWizardPageWithTitleTemplate.module.scss';

function DigitalWizardPageWithTitleTemplate(props) {
    const {
        children,
        errorsForStep,
        isSideHeader,
        applicationExceptions,
        shouldLink,
        isPolicyView,
        isPageSubmittedWithErrors,
        ...otherProps
    } = props;

    const renderWizardHeader = useCallback(
        (wizardData) => {
            const getSubmissionVM = wizardData.policyVM || wizardData.submissionVM || wizardData;
            return (
                <WizardPageHeader
                    wizardSubmission={getSubmissionVM}
                    isSideHeader={isSideHeader}
                    shouldLink={shouldLink}
                    isPolicyView={isPolicyView}
                />
            );
        },
        [isSideHeader, shouldLink, isPolicyView]
    );

    return (
        <div className={styles.peWizardContainer}>
            <DigitalWizardPageTemplate
                renderContextComponent={renderWizardHeader}
                showContextComponent
                showWizardHeader={false}
                {...otherProps}
            >
                {isPageSubmittedWithErrors
                && (
                    <InlineNotification
                        id="InlineNotification"
                        isDismissable={false}
                        isEmbeddedNotification={false}
                        message="Please complete the missing fields."
                        messageProps={{
                            error: ' ', // a space gets red of the error prefix
                        }}
                        type="error"
                        className={styles.e1pIssuesContainer}
                    />
                )
                }
                <WizardSingleErrorComponent
                    issuesList={errorsForStep}
                />
                <WizardSingleErrorComponent
                    issuesList={applicationExceptions}
                />
                {children}
            </DigitalWizardPageTemplate>
        </div>
    );
}

DigitalWizardPageWithTitleTemplate.propTypes = pageTemplateProps;
export default DigitalWizardPageWithTitleTemplate;
