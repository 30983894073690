import { useCallback } from 'react';
import { set, get } from 'lodash';
import { SideBySideService } from 'e1p-capability-sidebyside';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';

/**
 * @param {*} authHeader
 * @param {*} submissionVM
 * @param {*} updateWizardData
 * @param {*} setIsQuoting
 * @param {*} updateWizardSnapshot
 * @param {*} setShowMaxVersionMessage
 * @param {*} setShowMinVersionMessage
 * @param {*} setShowDeleteVersionMessage
 * @param {*} updateIsSideBySideView
 * @param {*} fetchQuoteProposal
 * @param {*} setSelectedVersionName
 * @param {*} getCustomQuote
 * @param {*} setShowQuoteProposalError
 * @param {*} lob
 * @returns {*} object
 */
function useSideBySideQuoteUtil (
    authHeader,
    submissionVM,
    updateWizardData,
    setIsQuoting,
    updateWizardSnapshot,
    setShowMaxVersionMessage,
    setShowMinVersionMessage,
    setShowDeleteVersionMessage,
    updateIsSideBySideView,
    fetchQuoteProposal,
    setSelectedVersionName,
    getCustomQuote,
    setShowQuoteProposalError,
    lob
) {

    const clearErrorsAndWarningsAndExceptions = useCallback(() => {
        set(submissionVM, 'value.errorsAndWarnings_Ext', undefined);
        set(submissionVM, 'baseData.exceptions_Ext.value', []);
        updateWizardData(submissionVM);
    }, [submissionVM, updateWizardData]);

        /**
     * Callback to recalculate side by side
     */
        const onRecalculateSideBySide = useCallback(async () => {
            setIsQuoting(true);
            setShowDeleteVersionMessage(false);
            setShowMaxVersionMessage(false);
            setShowMinVersionMessage(false);
            clearErrorsAndWarningsAndExceptions();

            const lobOfferingPath = `lobData.${lob}.offerings`;
            const quoteOfferingPath = 'quoteData.offeredQuotes';
            const lobOfferings = get(submissionVM, `${lobOfferingPath}.value`, []);
            const quoteOfferings = get(
                submissionVM,
                `${quoteOfferingPath}.value`,
                []
            );
            const customQuotes = [];

            lobOfferings.forEach((lobOffering, index) => {
                const quoteOfferingIndex = quoteOfferings.findIndex(
                    (quoteOffering) =>
                        quoteOffering.branchCode === lobOffering.branchCode
                );

                customQuotes.push(
                    getCustomQuote(
                        submissionVM,
                        `lobData.${lob}.offerings.children[${index}]`,
                        `quoteData.offeredQuotes.children[${quoteOfferingIndex}]`,
                        lob
                    )
                );
            });

            const updatedCustomQuotes = await SideBySideService.saveAndQuoteSideBySideVersions(
                customQuotes,
                authHeader
            );

            updatedCustomQuotes.forEach((updatedCustomQuote) => {
                const updatedClauses = get(
                    updatedCustomQuote,
                    `coverages.${lob}`
                );
                const lobQuoteIndex = lobOfferings.findIndex(
                    (offering) =>
                        offering.branchCode === updatedCustomQuote.quote.branchCode
                );
                const quoteIndex = quoteOfferings.findIndex(
                    (offering) =>
                        offering.branchCode === updatedCustomQuote.quote.branchCode
                );

                // Update local offering with new one from PC
                set(
                    submissionVM,
                    `${lobOfferingPath}.children.[${lobQuoteIndex}].coverages.value`,
                    updatedClauses
                );

                // Update local payment plans with new one from PC
                const updatedPaymentPlans = get(
                    updatedCustomQuote,
                    'paymentPlans_Ext',
                    []
                );

                set(
                    submissionVM,
                    `${lobOfferingPath}.children.[${lobQuoteIndex}].paymentPlans.value`,
                    updatedPaymentPlans
                );

                // Update local premium summary with new one from PC
                const updatedPremiumSummary = get(
                    updatedCustomQuote,
                    'premiumSummary',
                    []
                );

                set(
                    submissionVM,
                    `${lobOfferingPath}.children.[${lobQuoteIndex}].premiumSummary.value`,
                    updatedPremiumSummary
                );

                // Update local status with value from PC
                const status = get(updatedCustomQuote, 'quote.status', 'Draft');

                set(
                    submissionVM,
                    `${quoteOfferingPath}.children.[${quoteIndex}].status`,
                    status
                );
                // update local premium
                set(
                    submissionVM,
                    `${quoteOfferingPath}.children.[${quoteIndex}].premium`,
                    updatedCustomQuote.quote.premium
                );

                // update errors - Note that each custom quote version has it's own array so they have to be merged
                const errors = get(
                    submissionVM,
                    'errorsAndWarnings.value',
                    undefined
                );

                if (!errors && updatedCustomQuote.errorsAndWarnings) {
                    set(
                        submissionVM,
                        'errorsAndWarnings.value',
                        updatedCustomQuote.errorsAndWarnings
                    );
                }

                // set exceptions from all custom quotes versions
                set(submissionVM, 'baseData.exceptions_Ext.value',
                    get(submissionVM, 'baseData.exceptions_Ext.value', [])
                        .concat(get(updatedCustomQuote, 'exceptions_Ext', []))
                );
                updateWizardData(submissionVM);
                updateWizardSnapshot(submissionVM);
                // Recalculate should clear this message
                setShowQuoteProposalError(false);
            });
            fetchQuoteProposal();
            setIsQuoting(false);

            return submissionVM;
        }, [authHeader, fetchQuoteProposal, getCustomQuote, setIsQuoting,
            setShowDeleteVersionMessage, setShowMaxVersionMessage, lob,
            setShowMinVersionMessage, setShowQuoteProposalError, submissionVM,
            updateWizardData, updateWizardSnapshot, clearErrorsAndWarningsAndExceptions]);
    /**
     * Callback to start side by side quoting
     */
    const enterSideBySide = useCallback(
        async (e) => {
            e.preventDefault();
            setIsQuoting(true);

            // copies policy data from one version to others
            const newQdd = await SideBySideService.enterSideBySideMode(
                submissionVM.value,
                authHeader
            );

            set(submissionVM, 'value', newQdd);
            set(
                submissionVM,
                'quoteType_Ext.value',
                get(submissionVM.value, 'quoteType')
            );
            updateWizardData(submissionVM);
            setIsQuoting(false);
            updateWizardSnapshot(submissionVM);
            // If the message(s) was(were) up and the user goes back and forth between pages
            setShowMaxVersionMessage(false);
            setShowMinVersionMessage(false);
            setShowDeleteVersionMessage(false);
            updateIsSideBySideView(true);

            // business wants all versions quoted
            return onRecalculateSideBySide();
        },
        [authHeader, onRecalculateSideBySide, setIsQuoting, setShowDeleteVersionMessage, setShowMaxVersionMessage, setShowMinVersionMessage, submissionVM, updateIsSideBySideView, updateWizardData, updateWizardSnapshot]
    );

    /**
     * Callback to start side by side quoting
     */
    const onUpdateQuoteVersionName = useCallback(
        async (qdd, versionId, versionName) => {
            setIsQuoting(true);

            const newQdd = await SideBySideService.updateSideBySideQuoteName(
                qdd,
                versionId,
                versionName,
                authHeader
            );

            set(submissionVM, 'value', newQdd);
            set(
                submissionVM,
                'quoteType_Ext.value',
                get(submissionVM.value, 'quoteType')
            );
            setShowDeleteVersionMessage(false);
            setShowMaxVersionMessage(false);
            setShowMinVersionMessage(false);
            updateWizardData(submissionVM);
            setIsQuoting(false);

            return submissionVM;
        },
        [authHeader, setIsQuoting, setShowDeleteVersionMessage, setShowMaxVersionMessage, setShowMinVersionMessage, submissionVM, updateWizardData]
    );

    /**
     * Callback to add a quote version
     */
    const onAddQuote = useCallback(async () => {
        const quoteOfferingPath = 'quoteData.offeredQuotes';
        const quoteOfferings = get(
            submissionVM,
            `${quoteOfferingPath}.value`,
            []
        );

        setShowDeleteVersionMessage(false);

        if (quoteOfferings.length >= 3) {
            setShowMaxVersionMessage(true);

            return;
        }

        setIsQuoting(true);
        setShowMaxVersionMessage(false);
        setShowMinVersionMessage(false);

        const newQdd = await SideBySideService.addNewSideBySideVersion(
            submissionVM.value,
            authHeader
        );

        set(submissionVM, 'value', newQdd);
        set(
            submissionVM,
            'quoteType_Ext.value',
            get(submissionVM.value, 'quoteType')
        );
        updateWizardData(submissionVM);
        setIsQuoting(false);

        return submissionVM;
    }, [authHeader, setIsQuoting, setShowDeleteVersionMessage, setShowMaxVersionMessage, setShowMinVersionMessage, submissionVM, updateWizardData]);

    /**
     * Callback to change selected version
     */
    const onSetSelectedVersion = useCallback(
        async (versionId) => {
            setIsQuoting(true);

            const newQdd = await SideBySideService.selectVersion(
                submissionVM.value,
                versionId,
                authHeader
            );

            set(submissionVM, 'value', newQdd);
            set(
                submissionVM,
                'quoteType_Ext.value',
                get(submissionVM.value, 'quoteType')
            );
            updateWizardData(submissionVM);
            // Message might have been up - remove it
            setShowDeleteVersionMessage(false);
            setShowMaxVersionMessage(false);
            setShowMinVersionMessage(false);
            setIsQuoting(false);
            fetchQuoteProposal();

            return submissionVM;
        },
        [authHeader, fetchQuoteProposal, setIsQuoting, setShowDeleteVersionMessage, setShowMaxVersionMessage, setShowMinVersionMessage, submissionVM, updateWizardData]
    );

    /**
     * Callback withdraw/delete a version
     */
    const onWithdrawSideBySideVersion = useCallback(
        async (versionId) => {
            const { modalApi } = get(window, '__giamfam.modalApi');

            setShowMaxVersionMessage(false);

            const quoteOfferingPath = 'quoteData.offeredQuotes';
            const quoteOfferings =
                get(submissionVM, `${quoteOfferingPath}.value`) || [];
            // Client side validations
            const offeringToDelete = quoteOfferings.find((offering) => {
                return offering.publicID === versionId;
            });

            // should execute before checking selected version
            // Only one version exists
            if (quoteOfferings.length <= 1) {
                setShowMinVersionMessage(true);

                return;
            }

            // more than one version exist but is selected
            if (offeringToDelete.selected) {
                setShowDeleteVersionMessage(true);
                setSelectedVersionName(offeringToDelete.branchName);

                return;
            }

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: e1pCommonMessages.deleteQuote,
                message: e1pCommonMessages.deleteQuoteMessage,
                confirmButtonText: e1pCommonMessages.confirm,
                cancelButtonText: e1pCommonMessages.cancel
            }).then(
                async (result) => {
                    if (result !== 'cancel') {
                        // eslint-disable-next-line max-len
                        setShowDeleteVersionMessage(false);
                        setShowMinVersionMessage(false);
                        setIsQuoting(true);

                        const newQdd = await SideBySideService.withdrawSideBySideVersion(
                            submissionVM.value,
                            versionId,
                            authHeader
                        );

                        set(submissionVM, 'value', newQdd);
                        set(
                            submissionVM,
                            'quoteType_Ext.value',
                            get(submissionVM.value, 'quoteType')
                        );
                        updateWizardData(submissionVM);
                        // case where user tries to add but can't so then removes one
                        setIsQuoting(false);

                        return submissionVM;
                    }
                },
                () => {}
            );
        },
        [authHeader, setIsQuoting, setSelectedVersionName, setShowDeleteVersionMessage, setShowMaxVersionMessage, setShowMinVersionMessage, submissionVM, updateWizardData]
    );


    return {
        enterSideBySide,
        onUpdateQuoteVersionName,
        onAddQuote,
        onSetSelectedVersion,
        onWithdrawSideBySideVersion,
        onRecalculateSideBySide,
    };
}

export default useSideBySideQuoteUtil;
