import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo,
    useRef
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    some as _some,
    findIndex as _findIndex
} from 'lodash';

import { useTranslator } from '@jutro/locale';
import {  ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { PrefillService, LoadSaveService } from 'e1p-capability-quoteandbind';
import { CreditService, InspectionService } from 'e1p-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { PropertyFlowUtil } from 'e1p-portals-util-js';
import { e1pDateUtil, usePropertyPageUtil } from 'e1p-capability-hooks';
import { E1PEHHO3PropertyDetailsComponent } from 'e1p-capability-policyjob-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import config from 'app-config';

function PropertyPage(props) {
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        wizardData: submissionVM,
        updateWizardData,
        isSkipping,
        setShouldSkipAdditionalInfo,
        steps,
        jumpTo,
        updateWizardSnapshot,
        setIsPropertyPrefillOrderedMessage
    } = props;
    const coveragesIndex = _findIndex(steps, ({ path }) => path === '/coverages');
    const viewModelService = useContext(ViewModelServiceContext);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isReplacementCostStale, setIsReplacementCostStale] = useState(false);
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [floorMaterialErrorState, updateFloorMaterialErrorState] = useState(false);
    const [insideWallMaterialPercentageSumErrorState, updateInsideWallMaterialPercentageSumErrorState] = useState(false);
    const [prefillCompleted, setPrefillCompleted] = useState(true);
    const [isSavingSubmission, setIsSavingSubmission] = useState(false);
    const [prefillResponse, setPrefillResponse] = useState(undefined);
    const [heatingSystemType, updateHeatingSystemType] = useState(undefined);
    const [coolingSystemType, updateCoolingSystemType] = useState(undefined);
    const [creditScoreMoreThan597, updateCreditScoreMoreThan597] = useState(false);
    const [creditReportLoaded, setCreditReportLoaded] = useState(true);
    const [isReplacementRecalculateCompleted, setIsReplacementRecalculateCompleted] = useState(true);
    const [isCoverageATermValWasPresentAndRCChanged, setIsCoverageATermValWasPresentAndRCChanged] = useState(undefined);
    const [showRoofMessage, setShowRoofMessage] = useState(false);
    const [isOutstandingChangesPresent, setIsOutstandingChangesPresent] = useState(true);
    const [savingPrefillData, setSavingPrefillData] = useState(false);
    const [isPrefillSuccessful, setIsPrefillSuccessful] = useState(true);
    const [isPartialPrefill, setIsPartialPrefill] = useState(false);
    const hasReplacementCost = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value', undefined);
    const prefillHitOnPageEnter = useRef(false);
    const updateInspectionData = useRef(false);
    const numberOfUnitsFromPrefill = useRef(undefined);

    const {
        isComponentValid,
        initialValidation,
        disregardFieldValidation,
        registerInitialComponentValidation,
        onValidate,
        registerComponentValidation
    } = useValidation('PropertyPage');

    const {
        addInsideWallMaterial,
        addFloorMaterial,
        addGarage
    } = usePropertyPageUtil(
        submissionVM,
        hasReplacementCost,
        updateWizardData,
        viewModelService,
        setIsReplacementCostStale
    );

    const currentDateYear = useMemo(() => e1pDateUtil.getCurrentDateYear(), []);
    const { minYearBuilt } = config.enterpriseHomeownersConfig;

    /**
     * Below are the required fields for replacement cost to be recalculated.
     */
    const requiredFieldsForReplacementCost = useMemo(() => {
        const fields = [
            'yearBuilt',
            'numberOfStories',
            'totalSquareFeet',
            'exteriorWallFinish',
            'roofType',
            'roofShape',
            'garages', // has children
            'slopeType',
            'numberOfRoomsWithCathedralVaultedCeilings',
            'numberOfRoomsWithCrownMolding',
            'ceilingHeightType',
            'numberOfFullBaths',
            'numberOfHalfBaths',
            'kitchenCounterTopType',
            'foundationType',
            'basementFinishedAreaType',
            'heatingSystems', // has children
            'coolingSystems', // has children
            'insideWallMaterials', // has children
            'floors' // has children
            // 'firePlaces'// has children commenting fireplaces as its optional
        ];

        return fields;
    }, []);

    // if validation errors are thrown we cant jump to cov page
    const shouldNotGoToCoverages = (apiResponse) => {
        // e1pap1pc-10608
        // if quotedata is there we have already quoted once and been to cov page
        // so don't stop the flow here or we won't be able to land on coverage page with
        // any errors
        if (apiResponse.quoteData) {
            return false;
        }

        return true;
    };

    const isNumberOfFullBathsValid = useMemo(() => {
        const numberOfFullBaths = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value');

        return numberOfFullBaths === undefined
            || (numberOfFullBaths >= 1
                && numberOfFullBaths <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM.lobData.homeowners_EH.coverables.construction.numberOfFullBaths.value]);

    const isNumberOfHalfBathsValid = useMemo(() => {
        const numberOfHalfBaths = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value');

        return numberOfHalfBaths === undefined
            || (numberOfHalfBaths >= 0
                && numberOfHalfBaths <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM.lobData.homeowners_EH.coverables.construction.numberOfHalfBaths.value]);

    const isNumberOfRoomsWithCathedralVaultedCeilingsValid = useMemo(() => {
        const numberOfRoomsWithCathedralVaultedCeilings = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value');

        return numberOfRoomsWithCathedralVaultedCeilings === undefined
            || (numberOfRoomsWithCathedralVaultedCeilings >= 0
                && numberOfRoomsWithCathedralVaultedCeilings <= 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM.lobData.homeowners_EH.coverables.construction.numberOfRoomsWithCathedralVaultedCeilings.value]);


    const checkMultiInstanceFieldAndAddDefaultIfNotPresent = useCallback(() => {
        const garageValueNotProvided = _isEmpty(
            _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.garages.value', [])
        );

        if (garageValueNotProvided) {
            addGarage();
        }

        const floorMaterialNotProvided = _isEmpty(
            _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.floors.value', [])
        );

        if (floorMaterialNotProvided) {
            addFloorMaterial();
        }

        const wallMaterialNotProvided = _isEmpty(
            _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value', [])
        );

        if (wallMaterialNotProvided) {
            addInsideWallMaterial();
        }

        updateWizardData(submissionVM);
    }, [addFloorMaterial, addGarage, addInsideWallMaterial, submissionVM, updateWizardData]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && hasReplacementCost && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted, hasReplacementCost]);

    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            // we shouldn't check these if skipping after prefill; state won't be updated
            if (!prefillHitOnPageEnter.current) {
                /**
                 * IAP-4661 : allow recalculate on save
                 */
                if (!isComponentValid
                    || ((!hasReplacementCost
                        || isReplacementCostStale)
                        && !calledFromOnSave)
                ) {
                    updateIsPageSubmitted(true);
                    setIsOutstandingChangesPresent(true);
                    window.scrollTo(0, 0);

                    return false;
                }
            }

            prefillHitOnPageEnter.current = false;
            setIsSavingSubmission(true);

            // Store quotedata before quote
            const quoteData = _get(submissionVM, 'quoteData.value');
            // QUOTE
            let serverCall = LoadSaveService.saveAndQuoteSubmission

            // if clicked on save and if we dont have replacement cost want to save existing data with update draft call
            if (calledFromOnSave
                && (!hasReplacementCost || isReplacementCostStale)) {
                serverCall = LoadSaveService.updateDraftSubmission
                // take of replacement cost error
                updateIsPageSubmitted(false);
            }

            const response = await serverCall(submissionVM.value, authHeader);
            // Set new response with old quote data?
            const respQuoteData = _get(response, 'quoteData');

            if (respQuoteData === undefined) {
                _set(response, 'quoteData', quoteData);
            }

            // set view model with manipulated response
            _set(submissionVM, 'value', response);
            updateWizardData(submissionVM);

            const paymentStep = steps.find((step) => step.id === 'EHPaymentDetailsPage');

            if (paymentStep && updateInspectionData.current) {
                const inspectionDetails = await InspectionService.runInspection(_get(submissionVM, 'quoteID.value'), authHeader);

                _set(
                    submissionVM,
                    'lobData.homeowners_EH.coverables.yourHome.inspectionDetails',
                    inspectionDetails
                );
                updateWizardData(submissionVM);
            }

            // Check for validation errors and stop page before jumping
            if (shouldNotGoToCoverages(submissionVM.value)) {
                // stay on page
                updateWizardData(submissionVM);
                setIsSavingSubmission(false);

                return false;
            }

            setIsSavingSubmission(false);
            updateWizardSnapshot(submissionVM);

            // Don't want to jump in case of save call
            if (!calledFromOnSave) {
                jumpTo(coveragesIndex, true, submissionVM.value);
            }

            // Must return false to deny wizardpageonnext promise
            return false;
        },
        [
            steps, submissionVM, authHeader, updateWizardData, updateWizardSnapshot,
            isComponentValid, hasReplacementCost, isReplacementCostStale, jumpTo,
            coveragesIndex
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);
                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext]
    );

    /**
     * isRecalculateReplacementCostDisable, will save Boolean value, which will be used to enable or disable
     * the recalculate ReplacementCost button on UI, if any of the value in requiredFieldsForReplacementCost array
     * is undefined or empty, then it will returns true, else false.
     * @param {VMNode} viewModel
     * @returns {boolean}
     */
    const isRecalculateReplacementCostDisable = (viewModel) => {
        const isDisabled = _some(requiredFieldsForReplacementCost, (requiredField) => {
            const requiredFieldValue = _get(viewModel.lobData.homeowners_EH.coverables.construction, `${requiredField}.value`);

            // requiredFieldValue could be string, integer, or array.
            if (Array.isArray(requiredFieldValue)) {
                return requiredFieldValue.length === 0;
            }

            return requiredFieldValue === undefined || requiredFieldValue === '';
        });

        return isDisabled || !isComponentValid;
    };

    const isRecalculateRequired = !hasReplacementCost && !isRecalculateReplacementCostDisable(submissionVM);
    const recalculateReplacementCost = useCallback(
        async () => {
            if (isRecalculateRequired) {
                setIsReplacementRecalculateCompleted(false);
                PrefillService.getReplacementCost(
                    submissionVM.value,
                    authHeader
                ).then((prefillServiceResponse) => {
                    if (prefillServiceResponse?.exceptions_Ext) {
                        submissionVM.baseData.exceptions_Ext = prefillServiceResponse?.exceptions_Ext;
                        setIsReplacementRecalculateCompleted(true);
                    } else {
                        _set(submissionVM.lobData.homeowners_EH.coverables, 'value', prefillServiceResponse.coverables);
                        setIsReplacementCostStale(false);
                        setIsOutstandingChangesPresent(true);

                        // IAP-437 : show info message on recalculate
                        // we are checking cov term value as it will set only after first recalculate or on quote call
                        let selectedOfferingIndex = 0;

                        if (_get(submissionVM, 'value.lobData.homeowners_EH.offerings', []).length > 1) {
                            const offeredQuotes = _get(submissionVM, 'quoteData.offeredQuotes.value', []);
                            const selectedQuoteBranchCode = offeredQuotes?.find((quote) => quote.selected)?.branchCode;

                            if (selectedQuoteBranchCode) {
                                selectedOfferingIndex = _get(submissionVM, 'value.lobData.homeowners_EH.offerings', [])
                                    .findIndex((offering) => offering.branchCode === selectedQuoteBranchCode)
                            }
                        }

                        const dwellingCovA = _get(submissionVM,
                            `value.lobData.homeowners_EH.offerings[${selectedOfferingIndex}].coverages.coverages`,
                            []).find((coverage) => coverage.codeIdentifier === 'EH_DwellingCovA');
                        const dwellingCovAHasTermValue = !_isEmpty(_get(dwellingCovA, 'terms', [])
                            .find((term) => term.patternCodeIdentifier === 'EH_DwellingCovA_Limit')?.chosenTermValue);

                        if (isCoverageATermValWasPresentAndRCChanged === undefined) {
                            setIsCoverageATermValWasPresentAndRCChanged(dwellingCovAHasTermValue);
                        }

                        // E1PAP1PC-15700 : save all data till this point
                        _set(submissionVM, 'value.flowStepIDs_Ext', ['property']);
                        LoadSaveService.updateDraftSubmission(
                            submissionVM.value,
                            authHeader
                        ).then((response) => {
                            _set(submissionVM, 'value', response);
                            updateWizardData(submissionVM);
                            updateWizardSnapshot(submissionVM);
                        }).finally(() => {
                            setIsReplacementRecalculateCompleted(true);
                        });
                    }

                    updateWizardData(submissionVM);
                }).catch(() => {
                    setIsReplacementRecalculateCompleted(true);

                    if (!_isEmpty(_get(submissionVM, 'baseData.value.exceptions_Ext', []))) {
                        submissionVM.baseData.value.exceptions_Ext.push(
                            { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                        );
                    } else {
                        _set(
                            submissionVM.value,
                            `baseData.exceptions_Ext[${0}]`,
                            { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                        );
                    }

                    updateWizardData(submissionVM);
                })

            } else {
                setIsOutstandingChangesPresent(false);
            }
        },
        [
            authHeader, isCoverageATermValWasPresentAndRCChanged, isRecalculateRequired,
            submissionVM, translator, updateWizardData, updateWizardSnapshot
        ]
    );

    const defaultFieldsNotGettingFromPrefill = () => {
        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.totalNumberOfPeopleLiving.value', 2);
        numberOfUnitsFromPrefill.current = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value');

        if (_get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value') === undefined) {
            _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value', 'One');
        }

        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.doesYourHomeHaveFence.value', false);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasWoodOrCoalStove.value', false);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value', false);

        if (_get(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value') === undefined) {
            _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value', false);
        }

        _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value', false);

        if (_get(submissionVM, 'lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value') === undefined) {
            _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value',
                _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.yearBuilt.value'));
        }

        updateWizardData(submissionVM);
    };

    // E1PAP1PC-15825
    // Remove default if partial prefill
    const removeDefaultFieldsNotGettingFromPrefill = () => {
        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.totalNumberOfPeopleLiving.value', undefined);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value', numberOfUnitsFromPrefill.current);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.doesYourHomeHaveFence.value', undefined);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasWoodOrCoalStove.value', undefined);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value', undefined);
        _set(submissionVM, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool.value', undefined);
        updateWizardData(submissionVM);
    };

    const getPrefill = (async () => {
        if (submissionVM.value.lobData.homeowners_EH.coverables.construction.yearBuilt === undefined && submissionVM.value.quoteType !== 'Full') {
            setPrefillCompleted(false);
            PrefillService.loadPrefill(
                submissionVM.value,
                authHeader
            ).then((response) => {
                setPrefillResponse(response.coverables);
                setPrefillCompleted(true);
                setIsPrefillSuccessful(PropertyFlowUtil.checkIfPrefillIsSuccessful(response));
            }).catch(() => {
                if (!_isEmpty(_get(submissionVM, 'baseData.value.exceptions_Ext', []))) {
                    submissionVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                    );
                } else {
                    _set(
                        submissionVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: translator(e1pCommonMessages.genericErrorText) }
                    );
                }

                updateWizardData(submissionVM);
                setIsPrefillSuccessful(false);
                checkMultiInstanceFieldAndAddDefaultIfNotPresent();

            }).finally(() => {
                setPrefillCompleted(true);
            });
        } else {
            checkMultiInstanceFieldAndAddDefaultIfNotPresent();
            // prefill call was not needed, but page still visited
            setShouldSkipAdditionalInfo(true);
        }
    });

    const getCreditData = (async () => {
        if (!isSkipping) {
            setCreditReportLoaded(false);
            CreditService.getCreditReport(
                _get(submissionVM, 'quoteID.value'),
                authHeader
            ).then((response) => {
                const reportWithoutNoHit = response.creditRecords.find((creditRecord) => creditRecord.reportStatus !== 'NOHIT');

                if (reportWithoutNoHit !== undefined) {
                    updateCreditScoreMoreThan597(reportWithoutNoHit.creditScore >= 597);
                }

                setCreditReportLoaded(true);
            }).catch((exception) => {
                if (submissionVM.value.baseData.exceptions_Ext) {
                    submissionVM.value.baseData.exceptions_Ext.push(
                        { errorMessage: exception.baseError ? exception.baseError : exception.message }
                    );
                } else {
                    _set(
                        submissionVM.value.baseData,
                        `exceptions_Ext[${0}]`,
                        { errorMessage: exception.baseError ? exception.baseError : exception.message }
                    );
                }

                updateWizardData(submissionVM);
                updateCreditScoreMoreThan597(false);
            }).finally(() => {
                setCreditReportLoaded(true);
            });
        }
    });

    const shouldShowRoofMessage = () => {
        let tempShowMessage = false;
        const roofTypeListForRoofAgelessThan25 = ['asphaltshingles', 'compositionshingles', 'woodshinglesshakes', 'tarorgravel'];
        const roofTypeListForRoofAgeMoreThan70 = ['claytile', 'slate', 'concretetile'];
        const roofAge = e1pDateUtil.calculateAge(_get(submissionVM, 'lobData.homeowners_EH.coverables.construction.yearRoofInstalled.value'));

        if (roofTypeListForRoofAgelessThan25.includes(_get(submissionVM, 'lobData.homeowners_EH.coverables.construction.roofType.value.code'))
            && roofAge > 25) {
            setShowRoofMessage(true);
            tempShowMessage = true;
        } else if (roofTypeListForRoofAgeMoreThan70.includes(_get(submissionVM, 'lobData.homeowners_EH.coverables.construction.roofType.value.code'))) {
            if (roofAge > 70) {
                setShowRoofMessage(true);
                tempShowMessage = true;
            }
        }

        return tempShowMessage;
    };

    const setDefualtPercentageForWallMaterialAndFloorType = useCallback(
        () => {
            const floorMaterials = _get(
                submissionVM,
                'lobData.homeowners_EH.coverables.construction.floors.value', []
            );

            if (floorMaterials.length === 1
                && _get(floorMaterials, ['0', 'percentage']) === undefined) {
                _set(floorMaterials, ['0', 'percentage'], 100);
            }

            const wallMaterials = _get(
                submissionVM,
                'lobData.homeowners_EH.coverables.construction.insideWallMaterials.value', []
            );

            if (wallMaterials.length === 1
                && _get(wallMaterials, ['0', 'percentage']) === undefined) {
                _set(wallMaterials, ['0', 'percentage'], 100);
            }
        }, [submissionVM]
    );

    useEffect(() => {
        if ((prefillResponse !== undefined)) {
            const usageType = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value');
            const householdOccupants = _get(submissionVM, 'lobData.homeowners_EH.coverables.householdOccupants.value');
            const isThisNewHomePurchase = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.isThisNewHomePurchase.value');
            const ehLocation = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.ehlocation.value');

            _set(submissionVM.lobData.homeowners_EH.coverables, 'value', prefillResponse);
            _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value', usageType);
            _set(submissionVM, 'lobData.homeowners_EH.coverables.householdOccupants.value', householdOccupants);
            _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.isThisNewHomePurchase.value', isThisNewHomePurchase);
            _set(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.ehlocation.value', ehLocation);
            defaultFieldsNotGettingFromPrefill();

            const heatingSystems = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');
            let tempHeatingSystemType;

            if (heatingSystems !== undefined && heatingSystems.length > 0) {
                updateHeatingSystemType(heatingSystems[0].heatingType);
                tempHeatingSystemType = heatingSystems[0].heatingType;
            }

            const coolingSystems = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');
            let tempCoolingSystemType;

            if (coolingSystems !== undefined && coolingSystems.length > 0) {
                updateCoolingSystemType(coolingSystems[0].coolingType);
                tempCoolingSystemType = coolingSystems[0].coolingType;
            }

            // E1PAP1PC-15435 , E1PAP1PC-15435 :
            // default percentage for wall and floor material to 100 if not present
            setDefualtPercentageForWallMaterialAndFloorType();
            updateWizardData(submissionVM);

            // E1PAP1PC-7590 : Show Roof Age Informational Message Based on Conditions
            const tempShowMessage = shouldShowRoofMessage();
            const replacmentCost = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value');

            setPrefillCompleted(true);

            // E1PAP1PC-15825 :
            // if prefill missing some required values stay on the property page
            const allRequiredFieldsPresent = PropertyFlowUtil.checkAllRequiredFieldsArePresentHO3(
                submissionVM, creditScoreMoreThan597, currentDateYear, minYearBuilt,
                tempHeatingSystemType, tempCoolingSystemType, false
            );

            setIsPartialPrefill(!allRequiredFieldsPresent);

            const hasNoExceptions = _isEmpty(_get(submissionVM, 'baseData.exceptions_Ext.value', []));

            if (
                replacmentCost !== undefined && replacmentCost > 0
                && !tempShowMessage
                && hasNoExceptions
                && allRequiredFieldsPresent
            ) {
                // E1PAP1PC-15359 - prefill is successful and all required fields are present
                // show message on coverage page
                setIsPropertyPrefillOrderedMessage(true);
                prefillHitOnPageEnter.current = true;
                onNext();
            } else {
                // E1PAP1PC-7013 - For EH policies if prefill is a no hit and there is no information that is
                // returned or partial data returned then there will be no default value on property page
                // for any field and user is forced to enter data
                // Additional information pop-up should not display
                setShouldSkipAdditionalInfo(true);
                removeDefaultFieldsNotGettingFromPrefill();

                // E1PAP1PC-15700 : save prefill data
                _set(submissionVM, 'value.flowStepIDs_Ext', ['property']);
                setSavingPrefillData(true);
                LoadSaveService.updateDraftSubmission(
                    submissionVM.value,
                    authHeader
                ).then((response) => {
                    _set(submissionVM, 'value', response);
                    updateWizardData(submissionVM);
                    updateWizardSnapshot(submissionVM);
                }).finally(() => {
                    setSavingPrefillData(false);
                    checkMultiInstanceFieldAndAddDefaultIfNotPresent();
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefillResponse]);

    useEffect(() => {
        if (_isEmpty(submissionVM.lobData.homeowners_EH.coverables.yourHome.value)) {
            submissionVM.lobData.homeowners_EH.coverables.yourHome = {};
        }

        if (_isEmpty(submissionVM.lobData.homeowners_EH.coverables.construction.value)) {
            submissionVM.lobData.homeowners_EH.coverables.construction = {};
        }

        getPrefill();
        getCreditData();

        const heatingSystems = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.heatingSystems.value');

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            updateHeatingSystemType(heatingSystems[0].heatingType);
        }

        const coolingSystems = _get(submissionVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value');

        if (coolingSystems !== undefined && coolingSystems.length > 0) {
            updateCoolingSystemType(coolingSystems[0].coolingType);
        }

        updateWizardData(submissionVM);
        setIsPageInitialized(true);

        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateOverrides = useCallback(() => {
        const overrideProps = {
            roofAgeMessage: {
                visible: showRoofMessage && isPrefillSuccessful
            },
            partialPrefillNotification: {
                // IAP-1259 : dont show this message when roof age messqge is shown
                visible: isPartialPrefill && isPrefillSuccessful && !showRoofMessage
            },
            propertyPrefillUnsuccessfulInfoMessage: {
                visible: !isPrefillSuccessful
            },
            covAWillBeUpdatedBasedOnReplacementCostInfoMessage: {
                visible: !!isCoverageATermValWasPresentAndRCChanged
            }
        }
        
        return overrideProps;
    }, [isCoverageATermValWasPresentAndRCChanged, isPartialPrefill, isPrefillSuccessful, showRoofMessage]);

    // this will be chceked if isSkipping is true,
    // and will stop on this page if condition returns false
    useEffect(() => {
        // For skipWhen
        registerInitialComponentValidation(() => !!_get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.valuation.estimatedReplacementCostAmount.value', undefined));
    }, [registerInitialComponentValidation, submissionVM]);

    useEffect(() => {
        registerComponentValidation(() => (!insideWallMaterialPercentageSumErrorState
                && !floorMaterialErrorState
                && heatingSystemType !== undefined
                && coolingSystemType !== undefined
                && isNumberOfFullBathsValid
                && isNumberOfHalfBathsValid
                && isNumberOfRoomsWithCathedralVaultedCeilingsValid))
    }, [
        coolingSystemType, floorMaterialErrorState, heatingSystemType,
        insideWallMaterialPercentageSumErrorState,
        isNumberOfFullBathsValid, isNumberOfHalfBathsValid,
        isNumberOfRoomsWithCathedralVaultedCeilingsValid,
        registerComponentValidation
    ])

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => isPageInitialized && prefillCompleted && creditReportLoaded
            && !isSavingSubmission && !isSkipping && isReplacementRecalculateCompleted
            && !isSavingCurrentPageChanges && !savingPrefillData,
        [creditReportLoaded, isPageInitialized, isReplacementRecalculateCompleted,
        isSavingCurrentPageChanges, isSavingSubmission, isSkipping, prefillCompleted, savingPrefillData]);


    if (!isPageInitialized) {
        return null;
    }


    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <E1PEHHO3PropertyDetailsComponent
                onValidate={onValidate}
                transactionVM={submissionVM}
                updateWizardData={updateWizardData}
                viewOnlyMode={false}
                parentOverrideProps={generateOverrides}
                isOutstandingChangesPresent={isOutstandingChangesPresent}
                disregardFieldValidation={disregardFieldValidation}
                recalculateReplacementCost={recalculateReplacementCost}
                creditReportLoaded={creditReportLoaded}
                updateFloorMaterialErrorState={updateFloorMaterialErrorState}
                updateInsideWallMaterialPercentageSumErrorState={updateInsideWallMaterialPercentageSumErrorState}
                prefillCompleted={prefillCompleted}
                isSavingTransaction={isSavingSubmission}
                savingPrefillData={savingPrefillData}
                isReplacementRecalculateCompleted={isReplacementRecalculateCompleted}
                isSavingCurrentPageChanges={isSavingCurrentPageChanges}
                setIsReplacementCostStale={setIsReplacementCostStale}
                isPageSubmitted={isPageSubmitted}
                creditScoreMoreThan597={creditScoreMoreThan597}
                isPageInitialized={isPageInitialized}
                isSkipping={isSkipping}
                isRecalculateReplacementCostDisable={isRecalculateReplacementCostDisable}
                insideWallMaterialPercentageSumErrorState={insideWallMaterialPercentageSumErrorState}
                floorMaterialErrorState={floorMaterialErrorState}
                heatingSystemType={heatingSystemType}
                updateHeatingSystemType={updateHeatingSystemType}
                coolingSystemType={coolingSystemType}
                updateCoolingSystemType={updateCoolingSystemType}
                creditChecked
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
