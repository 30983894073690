import { defineMessages } from 'react-intl';

export default defineMessages({
    pageHeader: {
        id: 'EsignConfirmationPage.Your document has been completed!',
        defaultMessage: 'Your document has been completed!'
    },
    userDirections: {
        id: 'EsignConfirmationPage.Please close this window and continue your transaction.',
        defaultMessage: 'Please close this window and continue your transaction.'
    },
});
