/* eslint-disable react/jsx-no-useless-fragment */
import React, {
    useCallback,
    useContext, useEffect, useState
} from 'react';

import config from 'app-config';
import PropTypes from 'prop-types';
import { EAGaragingAddressModal, E1PLoader } from 'e1p-capability-policyjob-react';
import { Grid, GridItem } from '@jutro/layout';
import { useModal, ToggleField, Tooltip, InlineNotification } from '@jutro/components';
import htmlParser from 'html-react-parser';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { VehicleInfoLookupService } from 'e1p-capability-gateway';
import { VehicleUtil, TypekeyUtil, AddressUtil } from 'e1p-portals-util-js';
import { useGaragingAddressUtil } from 'e1p-capability-hooks';
import { isRequired } from 'e1p-portals-required-validator-js';
import {
    get as _get,
    isEqual as _isEqual,
    range as _range,
    map as _map,
    isEmpty as _isEmpty,
    find as _find,
    filter as _filter,
    set as _set,
    cloneDeep as _cloneDeep,
    isNumber as _isNumber
} from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { commonMessages as e1pCommonMessages, eaCommonMessages, eaValidationAndInfoMessages } from 'e1p-platform-translations';
import useAttributeConfigUtilForEA from "e1p-capability-hooks/AttributeConfigUtil/EA/useAttributeConfigUtilForEA";
import EAGaragingAddressComponent from '../EAGaragingAddressComponent/EAGaragingAddressComponent';
import messages from './EAVehicleGridComponent.messages';
import requiredMetadata from './EAVehicleGridComponent.requiredness';

import {
    IconButton,
    Button,
    CheckboxField,
    InputField,
    DropdownSelectField,
    TypeaheadMultiSelectField,
    InputNumberField,
} from '@jutro/legacy/components';

const VALID_VIN_LENGTH = 17;

// Returning AntiTheftDevice codes for RI state(in future we should be using Risk-Role attribute api for this)
function getTypecodesForAntiTheftDevices(viewModelService) {
    return TypekeyUtil
        .getAvailableValuesForTypekey(viewModelService,
            'VehSafetyEquipType_Ext',
            'MSA-RI').map((antiTheftDevice) => antiTheftDevice.code);
}

function EAVehicleGridComponent(props) {
    const {
        id,
        onValidate,
        vehicles,
        onValueChange,
        path,
        showErrors,
        viewOnlyMode,
        vehiclePageDisregardFieldValidation,
        transactionVM,
        updateWizardData,
        setCheckScrolling,
        addVehicleVM,
        policyState,
        authHeader,
        isSkipping,
        availableMakes,
        setAvailableMakes,
        availableModels,
        setAvailableModels,
        availableSeries,
        setAvailableSeries
    } = props;
    const modalApi = useModal();
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authUserData } = useAuthentication();
    const { isComponentValid, onValidate: setComponentValidation, registerComponentValidation } = useValidation(id);
    const { generateGaragingAddressDisplayName } = useGaragingAddressUtil();
    const [vehicleLabels, setVehicleLabels] = useState([]);
    const [vehicleFields, setVehicleFields] = useState([]);
    const [isFetchingVehicleData, updateIsFetchingVehicleData] = useState([]);
    const [systemSafetyFeatures, updateSystemSafetyFeatures] = useState([]);
    const [userEnteredSafetyFeatures, updateUserEnteredSafetyFeatures] = useState([]);
    const [garagingAddressFormData, setGaragingAddressFormData] = useState([]);
    const [visibleFields, updateVisibleFields] = useState([]);
    const [outOfStateMoreThan6Months, setOutOfStateMoreThan6Months] = useState(false);
    const [multipleSeriesReturned, setMultipleSeriesReturned] = useState(false);
    const [infoFieldsRemovedMessage, setInfoFieldsRemovedMessage] = useState([]);
    const [showInfoFieldsRemovedMessage, setShowInfoFieldsRemovedMessage] = useState(false);
    const [showYearVinInvalidMessage, setShowYearVinInvalidMessage] = useState(false);
    const [vehicleSafetyFeatures, setVehicleSafetyFeatures] = useState([]);
    const [
        showSeventeenDigitsVinRequiredErrorMessage,
        setShowSeventeenDigitsVinRequiredErrorMessage
    ] = useState(false);
    const [toggleForVINLengthValidationChanged, setToggleForVINLengthValidationChanged] = useState(false);
    const [showAddAntiTheft, updateShowAddAntiTheft] = useState(false);
    /**
     * IAP-4522 : Signature type available values based on opCo
     */
    const {
        getAvailableValuesForSafetyFeatures
    } = useAttributeConfigUtilForEA(transactionVM);


    useEffect(() => {
        const quoteType = _get(transactionVM, 'value.quoteType');
        const sourceType = _get(
            transactionVM,
            'baseData.quoteSource_Ext.sourceType.value.code'
        );
        // Show validation message if vin length is not 17 digits and its quick app for direct entry
        const doesAnyVehicleHasVinLessThan17Digits = _get(vehicles, 'value', [])
            .some((vehicleVM) =>
                !_isEmpty(_get(vehicleVM, 'vin', ''))
                && _get(vehicleVM, 'vin', '').length !== VALID_VIN_LENGTH
            ) && quoteType === 'Quick' && sourceType === 'directentry';

        registerComponentValidation(() => !doesAnyVehicleHasVinLessThan17Digits)
        setShowSeventeenDigitsVinRequiredErrorMessage(doesAnyVehicleHasVinLessThan17Digits);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleForVINLengthValidationChanged])

    const updateSafetyFeatureList = useCallback(() => {
        const tempSystemSelectedFeatures = [];
        const tempUserSelectedFeatures = [];

        vehicles.forEach((vehicleVM) => {
            const systemSelectedFeatures = [];
            const userSelectedFeatures = [];

            _get(vehicleVM, ['safetyFeatures', 'value'], []).forEach((feature) => {
                if ((!feature.userEquipmentAvailabilityInd)
                    && ![
                        'ActiveDevice',
                        'PassiveDevice',
                        ...getTypecodesForAntiTheftDevices(viewModelService)
                    ]
                        .includes(feature.vehicleEquipmentType)
                ) {
                    systemSelectedFeatures.push(feature);
                }
                else if (feature.userEquipmentAvailabilityInd
                    && ![
                        'ActiveDevice',
                        'PassiveDevice',
                        ...getTypecodesForAntiTheftDevices(viewModelService)
                    ]
                        .includes(feature.vehicleEquipmentType)) {
                    if (feature.vehicleEquipmentType === 'AirBags') {
                        switch (feature.airBagType) {
                            case 'Front':
                                userSelectedFeatures.push('airBagFront');
                                break;
                            case 'Both':
                                userSelectedFeatures.push('airBagFrontSide');
                                break;
                            default:
                                break;
                        }
                    }
                    else { userSelectedFeatures.push(feature.vehicleEquipmentType); }
                }
            });
            tempSystemSelectedFeatures.push(systemSelectedFeatures);
            tempUserSelectedFeatures.push(userSelectedFeatures);
        });

        updateSystemSafetyFeatures(tempSystemSelectedFeatures);
        updateUserEnteredSafetyFeatures(tempUserSelectedFeatures);
    }, [vehicles, viewModelService]);

    const setInfoMessageForFieldsCleared = useCallback((fieldsCleared, selectedValue) => {
        setInfoFieldsRemovedMessage(translator(
            eaCommonMessages.vehicleFieldsClearedMessage,
            { fieldsCleared, selectedValue }
        ));
        setShowInfoFieldsRemovedMessage(true);
    }, [translator]);

    const handleModel = useCallback((index) => {
        const vehicle = _get(transactionVM, `${path}[${index}]`);

        if (!isSkipping && !viewOnlyMode && vehicle.value.year
            && vehicle.value.make && vehicle.value.model && !vehicle.value.vin
            && _isEmpty(availableSeries[vehicle.value.year + vehicle.value.make + vehicle.value.model])) {
            const vehicleLookupDTO = {
                year: vehicle.value.year,
                make: vehicle.value.make,
                model: vehicle.value.model,
                partnerID: _get(transactionVM, 'baseData.partnerCode_Ext.value')
            };
            const updatedFetchingData = [...isFetchingVehicleData];

            _set(updatedFetchingData, index, true);
            updateIsFetchingVehicleData(updatedFetchingData);
            VehicleInfoLookupService.lookupBasedOnMakeModelYear(vehicleLookupDTO, authHeader)
                .then((vehicleInfo) => {
                    const tempSeriesList = [];

                    vehicleInfo.series.forEach((series) => {
                        const tempSeries = {
                            code: series,
                            name: series
                        };

                        tempSeriesList.push(tempSeries);
                    });

                    if (_get(vehicleInfo, 'series').length > 1) {
                        setMultipleSeriesReturned(true);
                    }

                    if (
                        vehicle.series.value !== undefined
                        && tempSeriesList.length > 1
                        && tempSeriesList.find(
                            (series) => series.code === vehicle.series.value
                        ) === undefined
                    ) {
                        if (setInfoMessageForFieldsCleared) {setInfoMessageForFieldsCleared('Series', 'Model');}

                        vehicle.series.value = undefined;
                    } else if (setShowInfoFieldsRemovedMessage) {
                        setShowInfoFieldsRemovedMessage(false);
                    }

                    // we will store series corresponding to year, make and model so that we dont have to call api again if same year, make and model is chosen
                    availableSeries[vehicle.value.year + vehicle.value.make + vehicle.value.model] = tempSeriesList;
                    setAvailableSeries(availableSeries);

                    if (tempSeriesList.length === 1) {
                        if (tempSeriesList[0]?.code === 'No Data') {
                            vehicle.series.value = undefined;
                        } else {
                            vehicle.series.value = tempSeriesList[0].code;
                        }
                    }

                    updateIsFetchingVehicleData(_set([...updatedFetchingData], index, false));
                })
                .catch(() => {
                    updateIsFetchingVehicleData(_set([...updatedFetchingData], index, false));
                });
        }
    }, [transactionVM, path, isSkipping, viewOnlyMode, isFetchingVehicleData, authHeader, availableSeries, setAvailableSeries, setInfoMessageForFieldsCleared]);

    const handleMake = useCallback((index) => {
        const vehicle = _get(transactionVM, `${path}[${index}]`);

        if (!isSkipping && !viewOnlyMode && vehicle.value.year
            && vehicle.value.make && !vehicle.value.vin &&
            _isEmpty(availableModels[vehicle.value.year + vehicle.value.make])
        ) {
            const vehicleLookupDTO = {
                year: vehicle.value.year,
                make: vehicle.value.make,
                partnerID: _get(transactionVM, 'baseData.partnerCode_Ext.value')
            };
            const updatedFetchingData = [...isFetchingVehicleData];

            _set(updatedFetchingData, index, true);
            updateIsFetchingVehicleData(updatedFetchingData);
            VehicleInfoLookupService.lookupBasedOnMakeAndYear(vehicleLookupDTO, authHeader)
                .then((vehicleInfo) => {
                    const tempModelsList = [];

                    vehicleInfo.models.forEach((model) => {
                        const tempModel = {
                            code: model,
                            name: model
                        };

                        tempModelsList.push(tempModel);
                    });
                    // we will store models corresponding to year and make so that we dont have to call api again if same year and make is chosen
                    availableModels[vehicle.value.year + vehicle.value.make] = tempModelsList;
                    setAvailableModels(availableModels);

                    if (tempModelsList.find(
                        (model) => model.code === vehicle.model.value
                    ) === undefined) {
                        if (vehicle.series.value && setInfoMessageForFieldsCleared) {setInfoMessageForFieldsCleared('Model and Series', 'Make');}
                        else if (vehicle.model.value && setInfoMessageForFieldsCleared) {setInfoMessageForFieldsCleared('Model', 'Make');}

                        vehicle.model.value = undefined;
                        vehicle.series.value = undefined;
                    } else if (setShowInfoFieldsRemovedMessage) {
                        setShowInfoFieldsRemovedMessage(false);
                    }

                    updateIsFetchingVehicleData(_set([...updatedFetchingData], index, false));

                    if (vehicle.model.value) {
                        handleModel(index);
                    }
                })
                .catch(() => {
                    updateIsFetchingVehicleData(_set([...updatedFetchingData], index, false));
                });
        }
    }, [transactionVM, path, isSkipping, viewOnlyMode, isFetchingVehicleData, authHeader, availableModels, setAvailableModels, setInfoMessageForFieldsCleared, handleModel]);

    const handleYear = useCallback((index) => {
        const vehicle = _get(transactionVM, `${path}[${index}]`);

        if (!isSkipping && !viewOnlyMode && vehicle.year.value
            && (!vehicle.value.vin)
            && _isEmpty(availableMakes[vehicle.year.value])
        ) {
            const updatedFetchingData = [...isFetchingVehicleData];

            _set(updatedFetchingData, index, true);
            updateIsFetchingVehicleData(updatedFetchingData);

            const vehicleLookupDTO = {
                year: vehicle.year.value,
                partnerID: _get(transactionVM, 'baseData.partnerCode_Ext.value')
            };

            VehicleInfoLookupService.lookupBasedOnYear(vehicleLookupDTO, authHeader).then((vehicleInfo) => {
                const tempMakesList = [];

                vehicleInfo.makes.forEach((make) => {
                    const tempMake = {
                        code: make,
                        name: make
                    };

                    tempMakesList.push(tempMake);
                });
                // we will store makes corresponding to year so that we dont have to call api again if same year is chosen
                availableMakes[String(vehicle.year.value)] = tempMakesList;
                setAvailableMakes(availableMakes);
                updateIsFetchingVehicleData(_set([...updatedFetchingData], index, false));

                if (tempMakesList.find(
                    (make) => make.code === vehicle.make.value
                ) === undefined) {
                    if (vehicle.series.value && setInfoMessageForFieldsCleared) {setInfoMessageForFieldsCleared('Make, Model, and Series', 'Year');}
                    else if (vehicle.model.value && setInfoMessageForFieldsCleared) {setInfoMessageForFieldsCleared('Make and Model', 'Year');}
                    else if (vehicle.make.value && setInfoMessageForFieldsCleared) {setInfoMessageForFieldsCleared('Make', 'Year');}

                    vehicle.make.value = undefined;
                    vehicle.model.value = undefined;
                    vehicle.series.value = undefined;
                } else if (setShowInfoFieldsRemovedMessage) {
                    setShowInfoFieldsRemovedMessage(false);
                }

                if (vehicle.make.value) {
                    // if your changes we have to refresh our models list
                    handleMake(index);
                }
            });
        }
    }, [transactionVM, path, isSkipping, viewOnlyMode, availableMakes, isFetchingVehicleData, authHeader, setAvailableMakes, setInfoMessageForFieldsCleared, handleMake]);


    const triggerVinLookup = useCallback((index) => {
        const vehicle = _get(transactionVM, `${path}[${index}]`, {});
        const quoteType = _get(transactionVM, 'value.quoteType');
        const sourceType = _get(
            transactionVM,
            'baseData.quoteSource_Ext.sourceType.value.code'
        );
        // If invalid vin wipe out the following

        if (vehicle.vin?.value?.length !== VALID_VIN_LENGTH) {
            if (!_isEmpty(_get(vehicle, 'vin.value'))
                && quoteType === 'Quick' && sourceType === 'directentry') {
                // changing toggle so that useEffect will trigger to check validation for vin length
                setToggleForVINLengthValidationChanged(!toggleForVINLengthValidationChanged);

                return;
            }

            vehicle.vin.value = undefined;
            vehicle.make.value = undefined;
            vehicle.model.value = undefined;
            vehicle.series.value = undefined;
            onValueChange(vehicle.value, `${path}.${index}.value`);
        }

        // changing toggle so that useEffect will trigger to check validation for vin length
        setToggleForVINLengthValidationChanged(!toggleForVINLengthValidationChanged);

        if (!isSkipping && vehicle.year.value && vehicle.value.vin) {
            const updatedFetchingData = [...isFetchingVehicleData];

            _set(updatedFetchingData, index, true);
            updateIsFetchingVehicleData(updatedFetchingData);

            const vehicleLookupDTO = {
                year: vehicle.year.value,
                vin: vehicle.value.vin,
                partnerID: _get(transactionVM, 'baseData.partnerCode_Ext.value')
            };

            VehicleInfoLookupService.lookupVehicleInfoBasedOnVinAndYear(
                vehicleLookupDTO, authHeader
            ).then((response) => {
                if (response.vinstatus === 'data_found') {
                    // Sets a field that shows a message on the additional info page
                    VehicleUtil.checkIfVehicleInfoChanged(response, vehicle.value);
                }

                vehicle.value = VehicleUtil.mapVinAndYearLookupToVehicle(vehicle.value, response);

                // When vehicle is data is set from VIN lookup, onValidationChange is not getting called
                // Adding manual validation for make, model, series and msrp - if value exists on vehicle data
                if (vehicle.value.make) {
                    setComponentValidation(true, `[${index}].make`);
                }

                if (vehicle.value.model) {
                    setComponentValidation(true, `[${index}].model`);
                }

                if (vehicle.value.series) {
                    setComponentValidation(true, `[${index}].series`);
                }

                if (_isNumber(vehicle.value.msrp)) {
                    setComponentValidation(true, `[${index}].msrp`);
                }

                onValueChange(vehicle.value, `${path}.value`);
                updateIsFetchingVehicleData(_set([...updatedFetchingData], index, false));
                updateSafetyFeatureList();

                if (response.year === 0) {
                    if (setShowYearVinInvalidMessage) {setShowYearVinInvalidMessage(true);}
                } else if (setShowYearVinInvalidMessage) {setShowYearVinInvalidMessage(false);}
            });
        }

        if (vehicle.year.value && !vehicle.value.vin) {
            handleYear(index);
            handleMake(index);
            handleModel(index);
        }
    }, [
        transactionVM, path, isSkipping, onValueChange, toggleForVINLengthValidationChanged, isFetchingVehicleData,
        authHeader, updateSafetyFeatureList, setComponentValidation, handleYear, handleMake, handleModel
    ]);

    useEffect(() => {
        const initialVisibleFields = ['antiTheftDevices', 'primaryUse']; // Fields to look up by partner/state

        updateVisibleFields(
            isRequired(initialVisibleFields, requiredMetadata, policyState, 'MSA')
        );

        // VIN validation for prefill vehicle - this need to run on mount only
        _get(vehicles, 'value', []).forEach((vehicle, index) => {
            const vehicleExistsInDatabase = _get(vehicle, 'fixedId');
            const vinStatusIsNotDataFound = _get(vehicle, 'vinstatus') !== 'data_found';

            // IAP-5661 - Do not do vin lookup if vin is undefined as this clears data on vehicles using year/make/model before we save
            if (_get(vehicle, 'vin') !== undefined) {
                // If vehicle is not assigned to quote and vinstatus is not verified, trigger vin lookup
                if (!vehicleExistsInDatabase && vinStatusIsNotDataFound) {
                    triggerVinLookup(index);
                }
            }
        });
        // User can't able to change state on Vehicle Page,
        // hence we need to run this useEffect only single time.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const vehicleList = _get(
            transactionVM,
            'lobData.personalAuto_EA.coverables.vehicles.value',
            []
        );

        // For NC state with Garaging address out of state, show additional vehicle question
        if (policyState === 'NC') {
            setOutOfStateMoreThan6Months(!!_find(vehicleList, (vehicleItem) => vehicleItem.garagingLocation.state !== policyState));
        }
        // adding garagingAddressFormData in dependency array so that above logic will run if garaging address gets changed
    }, [transactionVM, policyState, garagingAddressFormData]);

    useEffect(() => {
        vehicles.forEach((vehicleVM, index) => {
            const vehicleYear = _get(vehicleVM, ['value', 'year']);
            const vehicleVin = _get(vehicleVM, ['value', 'vin']);

            if (vehicleYear && !vehicleVin) {
                handleYear(index);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // Update safety feature list on load
    useEffect(() => {
        updateSafetyFeatureList();
    }, [updateSafetyFeatureList]);

    /**
     * Helper for calculating the initial garaging address selection for a vehicle.
     */
    useEffect(() => {
        const tempGaragingAddressFormData = [];

        vehicles.forEach((vehicleVM) => {
            if (vehicleVM.value) {
                let defaultGaragingAddress = _get(
                    vehicleVM,
                    'baseVMNode.container.baseData.policyAddress.value',
                    ''
                );

                if (viewOnlyMode) {
                    // for policy view we dont have baseData object, below change is to
                    // read policyAddress from correct path,
                    // this will take care of both policyview and transaction view also
                    defaultGaragingAddress = _get(
                        vehicleVM,
                        'baseVMNode.container.policyAddress.value',
                        _get(vehicleVM, 'baseVMNode.container.baseData.policyAddress.value')
                    );
                }

                const currentGaragingAddressVM = _get(vehicleVM, 'garagingLocation', '');

                generateGaragingAddressDisplayName(currentGaragingAddressVM);

                const currentGADisplayName = currentGaragingAddressVM.displayName.value;
                const usingSameAsResidentAddress = AddressUtil.isSameAddress(defaultGaragingAddress, currentGaragingAddressVM.value);
                const garagingAddressRadioButtonCode = usingSameAsResidentAddress
                    ? 'sameAsResidenceAddress'
                    : 'differentAddress';

                tempGaragingAddressFormData.push({
                    garagingAddressDisplay: currentGADisplayName,
                    garagingAddressRadioSelection: garagingAddressRadioButtonCode
                });
            }
        });

        setGaragingAddressFormData(tempGaragingAddressFormData);
    }, [generateGaragingAddressDisplayName, vehicles, viewOnlyMode, vehicles.length]);

    useEffect(() => {
        let alwaysShowLabels = [
            {
                id: 'yearLabelID',
                content: {
                    id: 'ea.vehiclegridcomponent.Year',
                    defaultMessage: 'Year'
                },
                className: 'grid-label required'
            },
            {
                id: 'vinLabelID',
                content: {
                    id: 'ea.vehiclegridcomponent.Vehicle ID Number (VIN)',
                    defaultMessage: 'Vehicle ID Number (VIN)'
                },
                className: 'grid-label'
            },
            {
                id: 'makeLabelID',
                content: {
                    id: 'ea.vehiclegridcomponent.Make',
                    defaultMessage: 'Make'
                },
                className: 'grid-label required'
            },
            {
                id: 'modelLabelID',
                content: {
                    id: 'ea.vehiclegridcomponent.Model',
                    defaultMessage: 'Model'
                },
                className: 'grid-label required'
            },
            {
                id: 'seriesLabelID',
                content: {
                    id: 'ea.vehiclegridcomponent.Series',
                    defaultMessage: 'Series'
                },
                className: multipleSeriesReturned ? 'grid-label required' : 'grid-label'
            },
            {
                id: 'vehicleTypeLabelID',
                content: {
                    id: 'e1p.platform.common.Vehicle Type',
                    defaultMessage: 'Vehicle Type'
                },
                className: 'grid-label required'
            },
            {
                id: 'vehicleCostLabelID',
                content: {
                    id: 'ea.vehiclegridcomponent.Cost New',
                    defaultMessage: 'Cost New'
                },
                className: 'grid-label required'
            }];

        if (visibleFields.includes('primaryUse')) {
            alwaysShowLabels.push({
                id: 'primaryUseLabelID',
                content: {
                    id: 'ea.vehiclegridcomponent.Primary Use',
                    defaultMessage: 'Primary Use'
                },
                className: 'grid-label required'
            });
        }

        alwaysShowLabels = [
            ...alwaysShowLabels,
            {
                id: 'currentOdometerLabelID',
                content: {
                    id: 'ea.vehiclegridcomponent.Current Odometer Reading',
                    defaultMessage: 'Current Odometer Reading'
                },
                className: 'grid-label'
            },
            {
                id: 'autoSafetyLabelID',
                content: {
                    id: 'ea.vehiclegridcomponent.Auto Safety Equipment',
                    defaultMessage: 'Auto Safety Equipment'
                },
                className: 'grid-label'
            }
        ];

        if (visibleFields.includes('antiTheftDevices')) {
            // For RI state show anti theft device tooltip
            if (policyState === 'RI') {
                alwaysShowLabels.push({
                    id: 'antiTheftDevicesLabelContainer',
                    content: [
                        {
                            id: 'antiTheftDevicesLabelID',
                            content: {
                                id: 'ea.vehiclegridcomponent.Anti-Theft Devices',
                                defaultMessage: 'Anti-Theft Devices'
                            },
                            className: 'grid-label'
                        },
                        {
                            id: 'antiTheftDevicesTooltipHelpText',
                            tooltipText: {
                                id: 'e1p.platform.lob.ea.antiTheftCategoryHelpText',
                                defaultMessage: 'Category I Devices: Alarm Only Devices – a device which sounds an audible alarm that can be heard at a distance of at least 300 feet for a minimum of three (3) minutes. \nCategory II Devices: Active Disabling Devices – a device which disables the vehicle by making the fuel, ignition, or starting system inoperative and a separate manual step is required to engage the device. \nCategory III Devices: Passive Disabling Devices – a device which disables the vehicle by making the fuel, ignition, or starting system inoperative and a separate manual step is not required to engage the device. \nCategory IV Devices: Vehicle Recovery System Devices – an electronic unit installed in a vehicle that is activated after the vehicle is stolen. When activated, the device provides information to law enforcement officials or another public or private entity regarding the vehicle’s location. The system provides for the routine delivery of the information to the appropriate law enforcement entity to assist in the recovery of the vehicle.'
                            }
                        }
                    ]
                });
            } else {
                alwaysShowLabels.push({
                    id: 'antiTheftDevicesLabelID',
                    content: {
                        id: 'ea.vehiclegridcomponent.Anti-Theft Devices',
                        defaultMessage: 'Anti-Theft Devices'
                    },
                    className: 'grid-label'
                });
            }
        }

        alwaysShowLabels.push(
            {
                id: 'garagingAddressLabelID',
                content: {
                    id: 'ea.vehiclegridcomponent.Garaging Address',
                    defaultMessage: 'Garaging Address'
                },
                className: 'grid-label required'
            }
        );

        if (outOfStateMoreThan6Months) {
            alwaysShowLabels.push(
                {
                    id: 'garageOutOfStateLabelID',
                    content: {
                        id: 'e1p.platform.lob.ea.ea-edit-vehicle.Vehicle is garaged out of the State more than 6 months a year',
                        defaultMessage: 'Vehicle is garaged out of the State more than 6 months a year'
                    },
                    className: 'grid-label required'
                }
            );
        }

        alwaysShowLabels.push(
            {
                id: 'otherThanNamedInsuredLabelID',
                content: {
                    id: "ea.vehiclegridcomponent.Person(s) other than named insured owns or co-owns this vehicle",
                    defaultMessage: "Person(s) other than named insured owns or co-owns this vehicle"
                },
                className: 'grid-label required'
            }
        );

        setVehicleLabels(alwaysShowLabels);
    }, [multipleSeriesReturned, outOfStateMoreThan6Months, policyState, transactionVM, translator, visibleFields]);

    /**
     * Helper callback for retrieving the "primaryUse" typelist values.
     */
    const getAvailableValuesForPrimaryUse = useCallback((vehicleVM) => {
        let values = [];
        const typelistFilters = viewModelService.productMetadata.get('pc').types
            .getTypelist('VehiclePrimaryUse').filters;
        let stateSpecificFilter = _find(typelistFilters, { name: `EA_AutoLine_${policyState}_Ext` });

        if (policyState === 'CA') {
            // When we implement CA state this filter name should change to 'EA_AutoLine_CA_Ext
            stateSpecificFilter = _find(typelistFilters, { name: 'EA_AutoLine_CA' });
        }

        if (stateSpecificFilter) {
            values = stateSpecificFilter.codes
        } else {
            // This is existing code, not sure which codes we will be using commonly as only for NC state we show primary use dropdown
            values = vehicleVM.primaryUse.aspects.availableValues.filter((value) => 
                ['pleasure_ext', 'ToWorkSchool_ext', 'FarmYearRoundTruck_ext', 'BusinessOccupationNotFarm_ext'].includes(value.code)
            );
        }

        const availableValues = values.map((code) => ({
                code: code.code,
                name: translator({ id: code.name })
            }));

        return availableValues;
    }, [
        translator,
        policyState,
        viewModelService
    ]);

    const setCarYears = useCallback(() => {
        const currentYear = new Date().getUTCFullYear();
        const { oldestCarYear } = config.personalAutoConfig;

        // range is not inclusive. Need to add -1 to get correct years
        const yearRange = _range(currentYear + 2, oldestCarYear - 1);

        return _map(yearRange, (year) => ({
                code: year.toString(),
                name: year.toString()
            }));
    }, []);

    /**
     * Helper callback for creating a new garaging address "VMNode" object.
     */
    const createNewGaragingAddressVM = useCallback(
        (radioButtonCode, vehicleVM) => {
            let templateAddressObject = {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: undefined,
                postalCode: '',
                country: 'US'
            };
            const { _xCenter, _dtoName } = vehicleVM.garagingLocation;

            if (radioButtonCode === 'editDifferentAddress') {
                templateAddressObject = _get(vehicleVM, 'garagingLocation.value');
            }

            const garagingAddressVM = viewModelService.create(
                templateAddressObject,
                _xCenter,
                _dtoName
            );

            return garagingAddressVM;
        },
        [viewModelService]
    );


    /**
    * Helper callback for showing the EA Garaging Address Modal window.
    */
    const showEAGaragingAddressModal = useCallback(
        (garagingAddressVM) => {
            const componentProps = {
                title: messages.addGaragingAddress,
                iconClassType: false,
                showCloseBtn: true,
                showCancelBtn: true,
                garagingAddressVM,
                authHeader,
                viewModelService
            };

            return modalApi.showModal(
                <EAGaragingAddressModal {...componentProps} />
            );
        },
        [authHeader, modalApi, viewModelService]
    );

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;

        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, path]);

    /**
    * Helper callback for updating a vehicle's garaging address using the return value from the
    * modal window.
    */
    const showEAGaragingAddressModalAndUpdateUsingReturnValue = useCallback(
        async (radioButtonCode, vehicleVM, index) => {
            const garagingAddressVM = createNewGaragingAddressVM(radioButtonCode, vehicleVM);

            showEAGaragingAddressModal(garagingAddressVM)
                .then((wrapperObj) => {
                    const newGaragingAddressVM = wrapperObj.modalGaragingAddressVM;

                    generateGaragingAddressDisplayName(newGaragingAddressVM);
                    _set(
                        vehicleVM,
                        'garagingLocation',
                        newGaragingAddressVM.value
                    );

                    const updatedFormData = _cloneDeep(garagingAddressFormData[index]);

                    _set(updatedFormData, 'garagingAddressDisplay', newGaragingAddressVM.displayName.value);

                    // if clicked on edit icon no need to change radioButtonCode 
                    if (radioButtonCode !== 'editDifferentAddress') {
                        _set(updatedFormData, 'garagingAddressRadioSelection', radioButtonCode);
                    }

                    const tempFormData = [...garagingAddressFormData]

                    _set(tempFormData, index, updatedFormData);
                    setGaragingAddressFormData(tempFormData);
                    handleValueChange(newGaragingAddressVM.value, 'vehicle.garagingLocation');
                })
                .catch(() => {

                    const defaultGaragingAddress = _get(
                        vehicleVM,
                        'baseVMNode.container.policyAddress.value',
                        _get(vehicleVM, 'baseVMNode.container.baseData.policyAddress.value')
                    );
                    const currentGaragingAddress = _get(vehicleVM, 'garagingLocation.value', '');
                    const usingSameAsResidentAddress = AddressUtil.isSameAddress(defaultGaragingAddress, currentGaragingAddress);
                    const garagingAddressRadioButtonCode = usingSameAsResidentAddress
                        ? 'sameAsResidenceAddress'
                        : 'differentAddress';

                    const updatedFormData = _cloneDeep(garagingAddressFormData[index]);

                    _set(updatedFormData, 'garagingAddressRadioSelection', garagingAddressRadioButtonCode);

                    const tempFormData = [...garagingAddressFormData]

                    _set(tempFormData, index, updatedFormData);
                    setGaragingAddressFormData(tempFormData);
                });
        },
        [
            createNewGaragingAddressVM,
            showEAGaragingAddressModal,
            generateGaragingAddressDisplayName,
            handleValueChange,
            garagingAddressFormData
        ]
    );

    /**
     * Helper callback for handling changes to a vehicle's garaging address.
     */
    const onGaragingAddressChange = useCallback(
        (radioButtonCode, vehicleVM, index) => {
            const defaultGaragingAddress = _get(
                vehicleVM,
                'baseVMNode.container.policyAddress.value',
                _get(vehicleVM, 'baseVMNode.container.baseData.policyAddress.value')
            );

            if (radioButtonCode === 'sameAsResidenceAddress') {
                if (defaultGaragingAddress !== '') {
                    _set(
                        vehicleVM,
                        'garagingLocation',
                        defaultGaragingAddress
                    );

                    const updatedFormData = _cloneDeep(garagingAddressFormData[index]);

                    _set(updatedFormData, 'garagingAddressDisplay', defaultGaragingAddress.displayName);
                    _set(updatedFormData, 'garagingAddressRadioSelection', radioButtonCode);

                    const tempFormData = [...garagingAddressFormData]

                    _set(tempFormData, index, updatedFormData);
                    setGaragingAddressFormData(tempFormData);
                    handleValueChange(defaultGaragingAddress, 'vehicle.garagingLocation');
                }
            } else {
                showEAGaragingAddressModalAndUpdateUsingReturnValue(radioButtonCode, vehicleVM, index);
            }
        },
        [garagingAddressFormData, handleValueChange, showEAGaragingAddressModalAndUpdateUsingReturnValue]
    );

    /**
     * Helper callback for processing actions after the radio button selection has changed.
     */
    const handleGaragingAddressRadioButtonValueChange = useCallback(
        (radioButtonCode, vehicleVM, index) => {
            const updatedFormData = _cloneDeep(garagingAddressFormData[index]);

            // if clicked on edit icon no need to change radioButtonCode 
            if (radioButtonCode !== 'editDifferentAddress') {
                _set(updatedFormData, 'garagingAddressRadioSelection', radioButtonCode);
            }

            if (policyState === 'NC' && radioButtonCode === 'sameAsResidenceAddress') {
                handleValueChange(undefined, `[${index}].garagedOutOfStateGreaterThan6Months`);
            }

            const tempFormData = [...garagingAddressFormData]

            _set(tempFormData, index, updatedFormData);
            setGaragingAddressFormData(tempFormData);
            onGaragingAddressChange(radioButtonCode, vehicleVM, index);
        },
        [garagingAddressFormData, handleValueChange, onGaragingAddressChange, policyState]
    );


    /**
     * Helper callback for retrieving the "vehicleType" typelist values.
     */
    const getAvailableValuesForVehicleType = useCallback((vehicle) => {
        let values = [];

        values = _filter(vehicle.vehicleType.aspects.availableValues, (availableValue) => availableValue.code === 'auto');

        const availableValues = values.map((code) => ({
                code: code.code,
                name: translator({ id: code.name })
            }));

        return availableValues;
    }, [translator]);

    const createVehicleSafetyVM = useCallback(
        (vehicle) => {
            const safetyObj = {
                vehicleEquipmentType: undefined,
                equipmentAvailability: undefined,
                userEquipmentAvailabilityInd: undefined,
                airBagType: undefined
            };
            const { _xCenter, _dtoName } = vehicle.safetyFeatures;
            const safetyVM = viewModelService.create(safetyObj, _xCenter, _dtoName);

            return safetyVM;
        },
        [viewModelService]
    );

    /**
     * Helper callback for creating a temporary vehicle safety feature "VMNode" object.
     */
    const createTemporaryVehicleSafetyVM = useCallback((vehicle) => createVehicleSafetyVM(vehicle),
        [createVehicleSafetyVM]);

    useEffect(() => {
        let isMounted = true;

        if (!viewOnlyMode) {
            let safetyFeaturesOptions = getAvailableValuesForSafetyFeatures().then((safetyValues) => {
                if (isMounted) {
                    safetyFeaturesOptions = safetyValues.filter((value) => ![
                            'PassiveDevice', 'ActiveDevice', ...getTypecodesForAntiTheftDevices(viewModelService)
                        ].includes(value.code));

                    const vehicleList = _get(
                        transactionVM,
                        'lobData.personalAuto_EA.coverables.vehicles.value',
                        []
                    );
                    const optionsPerVehicle = vehicleList.map((_, index) => {
                        const filteredCodes = _filter(safetyFeaturesOptions, (feature) => _get(systemSafetyFeatures, index, []).find((systemFeature) => systemFeature.vehicleEquipmentType === feature.code) === undefined).map(
                            (feature) => ({
                                code: feature.code,
                                name: {
                                    defaultMessage: feature.code.replace(/([A-Z])/g, ' $1').trim(),
                                    id: feature.code
                                }
                            })
                        );
                        const airBagsIndex = filteredCodes.findIndex((code) => code.code === 'AirBags');

                        if (airBagsIndex !== -1) {
                            filteredCodes.splice(airBagsIndex, 1);

                            if (_get(userEnteredSafetyFeatures, index, [])?.includes('airBagFront')) {
                                filteredCodes.push({
                                    code: 'airBagFront',
                                    name: {
                                        defaultMessage: 'Front Air Bags',
                                        id: 'airBagFront'
                                    }
                                });
                            } else if (_get(userEnteredSafetyFeatures, index, [])?.includes('airBagFrontSide')) {
                                filteredCodes.push({
                                    code: 'airBagFrontSide',
                                    name: {
                                        defaultMessage: 'Front and Side Air Bags',
                                        id: 'airBagFrontSide'
                                    }
                                });
                            } else {
                                filteredCodes.push({
                                    code: 'airBagFront',
                                    name: {
                                        defaultMessage: 'Front Air Bags',
                                        id: 'airBagFront'
                                    }
                                },
                                    {
                                        code: 'airBagFrontSide',
                                        name: {
                                            defaultMessage: 'Front and Side Air Bags',
                                            id: 'airBagFrontSide'
                                        }
                                    })
                            }
                        }

                        // We are removing ActiveDevice and Pasive Device as per business requirements,
                        //   and removing others for the state of Indiana
                        return filteredCodes;
                    });

                    setVehicleSafetyFeatures(optionsPerVehicle);
                }

            });
        }

        return () => {
            isMounted = false;
        }
        // only execute when vehicle is added (or removed)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionVM?.value?.lobData?.personalAuto_EA?.coverables?.vehicles?.length]);

    const getVehicleFields = useCallback((vehicle) => {
        let baseFields = [
            {
                id: "vehicleYear",
                type: "field",
                component: "dropdownSelect",
                componentProps: {
                    path: "year",
                    placeholder: '',
                    searchable: true,
                    required: true
                }
            },
            {
                id: "vehicleVin",
                type: "field",
                component: "input",
                componentProps: {
                    path: "vin",
                    required: false
                }
            }
        ];

        baseFields = vehicle.vin.value && vehicle.year.value ?
            [
                ...baseFields,
                {
                    id: "vehicleMake",
                    type: "field",
                    component: "input",
                    componentProps: {
                        path: "make",
                        readOnly: true
                    }
                },
                {
                    id: "vehicleModel",
                    type: "field",
                    component: "input",
                    componentProps: {
                        path: "model",
                        readOnly: true
                    }
                },
                {
                    id: "vehicleSeries",
                    type: "field",
                    component: "input",
                    componentProps: {
                        path: "series",
                        readOnly: true
                    }
                }
            ] :
            [
                ...baseFields,
                {
                    id: "vehicleMake",
                    type: "field",
                    component: "dropdownSelect",
                    componentProps: {
                        path: "make",
                        placeholder: '',
                        searchable: true,
                        required: true
                    }
                },
                {
                    id: "vehicleModel",
                    type: "field",
                    component: "dropdownSelect",
                    componentProps: {
                        path: "model",
                        placeholder: '',
                        searchable: true,
                        required: true
                    }
                },
                {
                    id: "vehicleSeries",
                    type: "field",
                    component: "dropdownSelect",
                    componentProps: {
                        path: "series",
                        placeholder: '',
                        searchable: true
                    }
                }
            ];

        baseFields = [
            ...baseFields,
            {
                id: "vehicleType",
                type: "field",
                component: "dropdownSelect",
                componentProps: {
                    path: "vehicleType",
                    placeholder: '',
                    searchable: true,
                    required: true
                }
            },
            {
                id: "vehicleCostNew",
                type: "field",
                component: "InputNumber",
                componentProps: {
                    path: "msrp",
                    required: true
                }
            }
        ];

        if (visibleFields.includes('primaryUse')) {
            baseFields.push(
                {
                    id: "primaryUse",
                    type: "field",
                    component: "dropdownSelect",
                    componentProps: {
                        path: "primaryUse",
                        placeholder: '',
                        searchable: true,
                        required: true
                    }
                }
            );
        }

        baseFields = [
            ...baseFields,
            {
                id: "currentOdometer",
                type: "field",
                component: "InputNumber",
                componentProps: {
                    path: "currentOdometerReading"
                }
            },
            {
                id: "safetyFeaturesTypeaheadMultiSelect",
                type: "field",
                component: "TypeaheadMultiSelect",
                componentProps: {
                    allowNew: false,
                    singleSelect: false,
                    stickyIndicator: true,
                    readOnlySeparator: ', '
                }
            }];

        if (visibleFields.includes('antiTheftDevices')) {
            baseFields.push(
                {
                    id: "activePassiveAntiTheftDeviceContainer",
                    type: "container",
                    component: "div"
                }
            );
        }

        baseFields.push(
            {
                id: "garagingAddressContainer",
                type: "container",
                component: "div"
            }
        );

        // If any of the vehicle is with out of state garage address
        if (outOfStateMoreThan6Months) {
            baseFields.push({
                id: "garagedOutOfStateMoreThanSixMonthsToggle",
                type: "field",
                component: "Toggle",
                componentProps: {
                    availableValues: [
                        {
                            code: "true",
                            name: {
                                id: "e1p.platform.common.Yes",
                                defaultMessage: "Yes"
                            }
                        },
                        {
                            code: "false",
                            name: {
                                id: "e1p.platform.common.No",
                                defaultMessage: "No"
                            }
                        }
                    ],
                    path: "garagedOutOfStateGreaterThan6Months",
                    required: true
                }
            });
        }

        baseFields.push(
            {
                id: "otherThanNamedInsuredToggle",
                type: "field",
                component: "Toggle",
                componentProps: {
                    availableValues: [
                        {
                            code: "true",
                            name: {
                                id: "e1p.platform.common.Yes",
                                defaultMessage: "Yes"
                            }
                        },
                        {
                            code: "false",
                            name: {
                                id: "e1p.platform.common.No",
                                defaultMessage: "No"
                            }
                        }
                    ],
                    path: "otherThanNamedInsuredOwnsInd",
                    required: true
                }
            }
        );

        return baseFields;
    }, [outOfStateMoreThan6Months, visibleFields]);


    useEffect(() => {
        const tempFields = [...vehicleFields];

        transactionVM.lobData.personalAuto_EA.coverables.vehicles.children.forEach((vehicle, index) => {
            tempFields[index] = getVehicleFields(vehicle);
        })

        // Only update the state variable in case of change in field value to avoid unnecessary re-render
        if (!_isEqual(vehicleFields, tempFields)) {
            setVehicleFields(tempFields);
        }
    }, [getVehicleFields, transactionVM.lobData.personalAuto_EA.coverables.vehicles.children, vehicleFields, isFetchingVehicleData])

    const onRemoveVehicle = useCallback(
        (evt) => {
            const vehicleListPath = 'lobData.personalAuto_EA.coverables.vehicles.value';
            const currentPath = evt.path;
            const vehiclePath = currentPath.replace(/\.children\[(\d+)\]/, '.children[$1].value');
            const vehicle = _get(transactionVM, vehiclePath);
            const vehicleList = _get(transactionVM, vehicleListPath);
            const vehicleIndex = vehicleList.findIndex((vehicleFromList) => _isEqual(vehicleFromList, vehicle));
            const totalVehiclesCount = (vehicleList.length) - 1;

            modalApi.showConfirm({
                title: eaCommonMessages.removeVehicleTitle,
                message: eaCommonMessages.removeVehicleDescription,
                confirmButtonText: eaCommonMessages.removeVehicle,
                cancelButtonText: e1pCommonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    vehicleList.splice(vehicleIndex, 1);

                    if (_get(vehicle, 'vin.length') !== VALID_VIN_LENGTH) {
                        // changing toggle so that useEffect will trigger to check validation for vin length
                        setToggleForVINLengthValidationChanged(!toggleForVINLengthValidationChanged)
                    }

                    vehiclePageDisregardFieldValidation(`vehicle${totalVehiclesCount}`);
                    updateWizardData(transactionVM);

                    // When vehicle list is empty, add vehicle with year and vin option
                    if (totalVehiclesCount === 0) {
                        addVehicleVM();
                    }

                    setCheckScrolling(true);
                }
            });

        },
        [
            transactionVM, vehiclePageDisregardFieldValidation, updateWizardData,
            setCheckScrolling, toggleForVINLengthValidationChanged, modalApi, addVehicleVM
        ]
    );

    const getVehicleName = (vehicle) => {
        let titleString = '';

        if (vehicle.year.value) { titleString = `${titleString} ${vehicle.year.value}`; }

        if (vehicle.make.value) { titleString = `${titleString} ${vehicle.make.value}`; }

        if (vehicle.model.value) { titleString = `${titleString} ${vehicle.model.value}`; }

        if (vehicle.series.value) { titleString = `${titleString} ${vehicle.series.value}`; }

        return titleString === '' ? translator(eaCommonMessages.newVehicle) : titleString;
    }

    const formatLabel = (labelObject) => {
        if (labelObject.id === 'antiTheftDevicesLabelContainer') {
            return (
                <div id={labelObject.id} className="grid-label grid-label-icon">
                    <span id={labelObject.content[0].id} className="required">
                        {translator(labelObject.content[0].content)}
                    </span>
                    <Tooltip
                        id={labelObject.content[1].id}
                        trigger="mouseenter"
                        content={htmlParser(translator(labelObject.content[1].tooltipText).replaceAll('\n', '<br>'))}
                    >
                        <IconButton
                            visible
                            icon="mi-help-outline"
                        />
                    </Tooltip>
                </div>
            )
        }

        return (<div id={labelObject.id} className={labelObject.className}>
            {translator(labelObject.content)}
        </div>)
    };

    const onYearChange = useCallback(
        (value, yearPath, index) => {
            handleValueChange(value, yearPath);

            const vin = _get(vehicles, ['value', index, 'vin']);

            if (vin) {
                triggerVinLookup(index);

                return;
            }

            handleYear(index);
        },
        [handleValueChange, handleYear, triggerVinLookup, vehicles],
    );

    const onMakeChange = useCallback(
        (value, makePath, index) => {
            handleValueChange(value, makePath);
            handleMake(index);
        },
        [handleMake, handleValueChange],
    );

    const onModelChange = useCallback(
        (value, makePath, index) => {
            handleValueChange(value, makePath);
            handleModel(index);
        },
        [handleModel, handleValueChange],
    );

    /**
     * Helper callback for adding a new vehicle safety feature to a given vehicle.
     */
    const onAddSafetyVehicleClick = useCallback(
        (evt) => {
            const newFeature = evt.feature.value;

            newFeature.userEquipmentAvailabilityInd = true;
            newFeature.equipmentAvailability = 'Optional';

            const vehicle = _get(transactionVM, `${evt.pathValue}.value`);
            let isSafetyFeaturePresent = false;

            // To check whether the newFeature that we want to add is already existing or not
            // To check whether the vehicleEquipmentType to be added is AirBags
            //  and if yes we check Airbag type to know whether it is existing feature or not
            if (newFeature.vehicleEquipmentType === 'AirBags') {
                isSafetyFeaturePresent = vehicle.safetyFeatures?.some(
                    (item) => (item.vehicleEquipmentType === evt.feature.value.vehicleEquipmentType
                        && item.airBagType === evt.feature.value.airBagType)
                );
            } else { // Executes whenevr the vehicleEquipmentType is not AirBags
                isSafetyFeaturePresent = vehicle.safetyFeatures?.some(
                    (item) => item.vehicleEquipmentType === evt.feature.value.vehicleEquipmentType
                );
            }

            if (!isSafetyFeaturePresent && evt.feature.value.vehicleEquipmentType) {
                newFeature.userEquipmentAvailabilityInd = true;
                vehicle.safetyFeatures.push(newFeature);
            }

            handleValueChange(vehicle.safetyFeatures, 'vehicle.safetyFeatures');
        },
        [handleValueChange, transactionVM]
    );

    /**
     * Helper callback for removing exiting vehicle safety feature from a given vehicle.
     */
    const onRemoveSafetyVehicleClick = useCallback(
        (evt) => {
            const vehicle = _get(transactionVM, `${evt.pathValue}.value`);

            if (evt.feature.value.vehicleEquipmentType) {
                // If the selected vehicleEquipmentType is airbag, then
                // we check the type of airbag and then remove it from the list
                if (evt.feature.value.vehicleEquipmentType === 'AirBags') {
                    vehicle.safetyFeatures = _filter(vehicle.safetyFeatures,
                        (feature) => ((feature.vehicleEquipmentType
                            !== evt.feature.value.vehicleEquipmentType)
                            || feature.airBagType !== evt.feature.value.airBagType));
                } else {
                    //  This block of code executes whenever the selected vehicleEquipmentType
                    //  is not airbag and filters out the rest of the features
                    vehicle.safetyFeatures = _filter(vehicle.safetyFeatures,
                        (feature) => feature.vehicleEquipmentType
                            !== evt.feature.value.vehicleEquipmentType);
                }

                handleValueChange(vehicle.safetyFeatures, 'vehicle.safetyFeatures');
            }
        },
        [handleValueChange, transactionVM]
    );

    const onActiveDeviceValueChange = useCallback((value, pathValue) => {
        if (value) {
            onAddSafetyVehicleClick({ pathValue, feature: { value: { vehicleEquipmentType: 'ActiveDevice' } } });
        } else {
            onRemoveSafetyVehicleClick({ pathValue, feature: { value: { vehicleEquipmentType: 'ActiveDevice' } } });
        }
    }, [onAddSafetyVehicleClick, onRemoveSafetyVehicleClick]);

    const onPassiveDeviceValueChange = useCallback((value, pathValue) => {
        if (value) {
            onAddSafetyVehicleClick({ pathValue, feature: { value: { vehicleEquipmentType: 'PassiveDevice' } } });
        } else {
            onRemoveSafetyVehicleClick({ pathValue, feature: { value: { vehicleEquipmentType: 'PassiveDevice' } } });
        }
    }, [onAddSafetyVehicleClick, onRemoveSafetyVehicleClick]);

    const updateSafetyFeatures = useCallback((vehicle, value, index) => {
        const requiredFeatures = vehicle.safetyFeatures.value.filter((feature) => !feature.userEquipmentAvailabilityInd);
        const selectedFeatures = vehicle.safetyFeatures.value.filter((feature) => feature.userEquipmentAvailabilityInd
                && (feature.vehicleEquipmentType === 'ActiveDevice' || feature.vehicleEquipmentType === 'PassiveDevice'));

        value.forEach((feature) => {
            const tempSafetyFeature = createTemporaryVehicleSafetyVM(vehicle);

            switch (feature) {
                case 'airBagFront':
                    tempSafetyFeature.vehicleEquipmentType = 'AirBags';
                    tempSafetyFeature.airBagType = 'Front';
                    break;
                case 'airBagFrontSide':
                    tempSafetyFeature.vehicleEquipmentType = 'AirBags';
                    tempSafetyFeature.airBagType = 'Both';
                    break;
                default:
                    tempSafetyFeature.vehicleEquipmentType = feature;
                    break;
            }

            tempSafetyFeature.userEquipmentAvailabilityInd.value = true;
            selectedFeatures.push(tempSafetyFeature.value);
        });

        const features = requiredFeatures.concat(selectedFeatures);

        handleValueChange(features, `[${index}]safetyFeatures`);
    }, [handleValueChange, createTemporaryVehicleSafetyVM]);


    const toggleAntiTheft = useCallback(() => {
        updateShowAddAntiTheft(!showAddAntiTheft)
    }, [showAddAntiTheft]);

    const getAntiTheftCategoryAvailableValues = useCallback((vehicleVM) => {
        if (!viewOnlyMode) {
            const tempSafetyFeature = createTemporaryVehicleSafetyVM(vehicleVM);
            const antiTheftCategoryOptions = tempSafetyFeature.vehicleEquipmentType
                .aspects.availableValues.filter((value) => getTypecodesForAntiTheftDevices(viewModelService).includes(value.code) && !vehicleVM.safetyFeatures.value?.some(
                        (item) => item.vehicleEquipmentType === value.code
                    ));

            return antiTheftCategoryOptions.map(
                (feature) => ({
                    code: feature.code,
                    name: {
                        defaultMessage: feature.code.replace(/([A-Z])/g, ' $1').trim(),
                        id: feature.code
                    }
                })
            );
        }

        return [];
    }, [viewModelService, createTemporaryVehicleSafetyVM, viewOnlyMode]);

    const setAntiTheftCategory = useCallback((vehicleVM) => {
        // If edit mode
        const chosenValue = vehicleVM.value.tempAntiTheftCategory;
        const tempSafetyFeature = createTemporaryVehicleSafetyVM(vehicleVM);

        if (!viewOnlyMode) {
            if (chosenValue) {
                const chosenAirbagType = vehicleVM.value.tempAirbagType;

                if (chosenAirbagType) {
                    _set(tempSafetyFeature, 'airBagType.value', chosenAirbagType);
                }

                tempSafetyFeature.vehicleEquipmentType.aspects.availableValues.forEach(
                    (equipmentType) => {
                        if (equipmentType.code === chosenValue) {
                            _set(tempSafetyFeature, 'vehicleEquipmentType.value', equipmentType);
                        }
                    }
                );
            }

            return tempSafetyFeature;
        }

        // View mode
        return undefined;
    }, [createTemporaryVehicleSafetyVM,
        viewOnlyMode]);

    const addAntiTheftCategoryClick = useCallback(
        (vehicleVM, fieldPath) => {
            const evt = {};

            evt.feature = setAntiTheftCategory(vehicleVM);
            evt.pathValue = fieldPath;
            toggleAntiTheft();
            onAddSafetyVehicleClick(evt);
            vehicleVM.value.tempAntiTheftCategory = undefined;
        },
        [onAddSafetyVehicleClick, toggleAntiTheft, setAntiTheftCategory]
    );

    const formatField = (row, column) => {
        const vehicle = vehicles.children[column - 1];
        const vehicleData = vehicleFields[column - 1];
        const vehicleField = vehicleData ? vehicleData[row - 2] : undefined;

        const readOnlyValue = false;
        let className;

        if (vehicleField.component === 'input') {
            const fieldPath = vehicleField.componentProps.path ? `[${column - 1}].${vehicleField.componentProps.path}` : undefined;
            const value = vehicleField.componentProps.value ? vehicleField.componentProps.value : _get(vehicle, `${vehicleField.componentProps.path}.value`);
            const onChange = handleValueChange;
            let onBlur;

            if (vehicleField.id === 'vehicleVin') {
                onBlur = () => { triggerVinLookup(column - 1) };
            }

            return (
                <InputField
                    id={`${vehicleField.id}${column - 1}`}
                    path={fieldPath}
                    hideLabel
                    required={vehicleField.componentProps.required}
                    maxLength={vehicleField.componentProps.maxLength}
                    placeholder=''
                    onValueChange={onChange}
                    value={value}
                    readOnly={viewOnlyMode || vehicleField.componentProps.readOnly}
                    className={className}
                    showErrors={showErrors}
                    showRequired
                    onBlur={onBlur}
                    onValidationChange={setComponentValidation}
                />
            )
        }

        if (vehicleField.component === 'dropdownSelect') {
            const fieldPath = vehicleField.componentProps.path ? `[${column - 1}].${vehicleField.componentProps.path}` : undefined;
            const value = vehicleField.componentProps.value ? vehicleField.componentProps.value : _get(vehicle, `${vehicleField.componentProps.path}.value`);
            let onChange = handleValueChange;
            let {availableOptions} = vehicleField;
            let defaultValue;
            let {visible} = vehicleField.componentProps;
            let {required} = vehicleField.componentProps;

            if (vehicleField.id === 'vehicleYear') {
                availableOptions = setCarYears();
                onChange = (changeValue, changePath) => { onYearChange(changeValue, changePath, column - 1) };
            }

            if (vehicleField.id === 'primaryUse') {
                availableOptions = getAvailableValuesForPrimaryUse(vehicle);
                visible = !!(vehicle.make.value && vehicle.model.value);
                required = visible;
            }

            if (vehicleField.id === 'vehicleType') {
                availableOptions = getAvailableValuesForVehicleType(vehicle);
                visible = !!(vehicle.make.value && vehicle.model.value);
                defaultValue = 'auto';
                required = visible;
            }

            if (vehicleField.id === 'vehicleMake') {
                availableOptions = availableMakes[String(_get(vehicle, 'year.value'))] || [];
                onChange = (changeValue, changePath) => { onMakeChange(changeValue, changePath, column - 1) };
                visible = !!(availableOptions.length > 0 || value);
                required = visible;
            }

            if (vehicleField.id === 'vehicleModel') {
                const yearMakeKey = _get(vehicle, 'year.value', '') + _get(vehicle, 'make.value', '');

                availableOptions = availableModels[yearMakeKey] || [];
                onChange = (changeValue, changePath) => { onModelChange(changeValue, changePath, column - 1) };
                visible = !!(availableOptions.length > 0 || value);
                required = visible;
            }

            if (vehicleField.id === 'vehicleSeries') {
                const yearMakeModelKey = _get(vehicle, 'year.value', '') + _get(vehicle, 'make.value', '') + _get(vehicle, 'model.value', '');

                availableOptions = availableSeries[yearMakeModelKey] || [];
                visible = !!(availableOptions.length > 0 || value);
            }

            return (
                <DropdownSelectField
                    id={`${vehicleField.id}${column - 1}`}
                    alwaysShowPlaceholder={false}
                    placeholder=''
                    availableValues={availableOptions}
                    onValueChange={onChange}
                    value={value}
                    hideLabel
                    required={required}
                    path={fieldPath}
                    readOnly={viewOnlyMode || readOnlyValue}
                    className={className}
                    defaultValue={defaultValue}
                    showErrors={showErrors}
                    showRequired
                    onValidationChange={setComponentValidation}
                    visible={visible}
                    searchable={vehicleField.componentProps.searchable}
                />
            )
        }

        if (vehicleField.component === 'Toggle') {
            const onChange = handleValueChange;
            const value = vehicleField.componentProps.value ? vehicleField.componentProps.value : _get(vehicle, `${vehicleField.componentProps.path}.value`);
            const fieldPath = vehicleField.componentProps.path ? `[${column - 1}].${vehicleField.componentProps.path}` : undefined;
            let {visible} = vehicleField.componentProps;
            let {required} = vehicleField.componentProps;

            if (vehicleField.id === 'otherThanNamedInsuredToggle') {
                visible = !!(vehicle.make.value && vehicle.model.value);
                required = visible;
            } else if (vehicleField.id === 'garagedOutOfStateMoreThanSixMonthsToggle') {
                // if veicle garaged state and policy state is same then done show toggle for the vehicle
                if (vehicle.garagingLocation.state.value.code === policyState) {
                    return <div />;
                }
            }

            return (<ToggleField
                id={`${vehicleField.id}${column - 1}`}
                value={value}
                dataType="string"
                availableValues={vehicleField.componentProps.availableValues}
                onValueChange={onChange}
                path={fieldPath}
                required={required}
                showErrors={showErrors}
                showRequired
                onValidationChange={setComponentValidation}
                readOnly={viewOnlyMode}
                visible={visible}
            />)
        }

        if (vehicleField.component === 'InputNumber') {
            const fieldPath = vehicleField.componentProps.path ? `[${column - 1}].${vehicleField.componentProps.path}` : undefined;
            let value = _get(vehicle, `${vehicleField.componentProps.path}.value`, '');

            if (viewOnlyMode) {
                // to avoid  warning Failed prop type: Invalid prop `value` of type `string` supplied to `FormattedNumber`, expected `number`.
                value = _get(vehicle, `${vehicleField.componentProps.path}.value`);
            }

            let {visible} = vehicleField.componentProps;
            let {required} = vehicleField.componentProps;

            if (vehicleField.id === 'vehicleCostNew') {
                visible = !!(vehicle.make.value && vehicle.model.value);
                required = visible;
            }

            if (vehicleField.id === 'currentOdometer') {
                visible = !!(vehicle.make.value && vehicle.model.value);
            }

            return <InputNumberField
                id={`${vehicleField.id}${column - 1}`}
                path={fieldPath}
                hideLabel
                required={required}
                maxLength={vehicleField.componentProps.maxLength}
                minLength={vehicleField.componentProps.minLength}
                placeholder=''
                onValueChange={handleValueChange}
                value={value}
                readOnly={viewOnlyMode || readOnlyValue}
                className={className}
                showErrors={showErrors}
                showRequired
                onValidationChange={setComponentValidation}
                visible={visible}
            />;
        }

        if (vehicleField.component === 'div') {
            if (vehicleField.id === 'garagingAddressContainer') {
                const visible = !!(vehicle.make.value && vehicle.model.value);

                return (
                    <React.Fragment>
                        {visible ?
                            <div id={`${vehicleField.id}${column - 1}`}>
                                <EAGaragingAddressComponent
                                    index={column - 1}
                                    viewOnlyMode={viewOnlyMode}
                                    visible={visible}
                                    garagingAddressFormData={garagingAddressFormData[column - 1]}
                                    onGaragingAddressRadioButtonValueChange={(value) => handleGaragingAddressRadioButtonValueChange(value, vehicle, column - 1)}
                                />
                            </div>
                            : <div />}
                    </React.Fragment>
                )
            }

            if (vehicleField.id === 'activePassiveAntiTheftDeviceContainer') {
                const visible = !!(vehicle.make.value && vehicle.model.value);

                if (policyState === 'RI') {
                    let antiTheftCategoryList = [];

                    const safetyFeatures = _get(vehicle, 'safetyFeatures.children', []);

                    antiTheftCategoryList = _filter(safetyFeatures, (safetyFeature) => {
                        if (getTypecodesForAntiTheftDevices(viewModelService).includes(safetyFeature.vehicleEquipmentType.value.code)) {
                            return safetyFeature;
                        }
                    });

                    const getTheftDisplayName = (antiTheftItem) => `${antiTheftItem.vehicleEquipmentType.value.code.replace(/([A-Z])/g, ' $1').trim()}`
                    const onRemoveAntiTheftCategory = (feature) => {
                        if (feature.value.vehicleEquipmentType) {
                            vehicle.safetyFeatures.value = _filter(vehicle.safetyFeatures.value,
                                (tempFeature) => tempFeature.vehicleEquipmentType
                                    !== feature.value.vehicleEquipmentType);
                            handleValueChange(vehicle.safetyFeatures.value, 'vehicle.safetyFeatures');
                        }

                        vehicle.value.tempAntiTheftCategory = undefined;
                    };

                    return (
                        <React.Fragment>
                            {visible ?
                                (<div id={`${vehicleField.id}${column - 1}`}>
                                    {antiTheftCategoryList.length === 0 ?
                                            <div>
                                                {translator(messages.noAntiTheft)}
                                            </div>
                                            : <Grid className="mb-4">
                                                {
                                                    _map(antiTheftCategoryList, (antiTheftItem) => (
                                                        <GridItem key={`antiTheftGridItemContainer-${column - 1}`}>
                                                            <Grid columns={["5.5fr", "1.0fr"]}>
                                                                <GridItem key={`antiTheftGridItem-${column - 1}`}>
                                                                    <InputField
                                                                        id={`antiTheftItem-${column - 1}`}
                                                                        hideLabel
                                                                        disabled
                                                                        value={getTheftDisplayName(antiTheftItem)}
                                                                        title=''
                                                                        placeholder=''
                                                                    />
                                                                </GridItem>
                                                                <GridItem key={`antiTheftGridItemIcon-${column - 1}`}>
                                                                    {!viewOnlyMode && antiTheftItem.userEquipmentAvailabilityInd.value ? (
                                                                        <React.Fragment>
                                                                            <IconButton
                                                                                icon="mi-delete"
                                                                                className="actionIcon"
                                                                                onClick={() => { onRemoveAntiTheftCategory(antiTheftItem) }}
                                                                            />
                                                                        </React.Fragment>) : <div />
                                                                    }
                                                                </GridItem>
                                                            </Grid>
                                                        </GridItem>
                                                    ))
                                                }
                                            </Grid>}
                                    {!viewOnlyMode ? (<React.Fragment>
                                            <Button onClick={toggleAntiTheft} type="outlined" className={showAddAntiTheft ? "mt-2 display-none" : "mt-2"}>
                                                {translator(messages.addAntiTheft)}
                                            </Button>
                                            <div className={showAddAntiTheft ? "mt-2" : "display-none"}>

                                                <DropdownSelectField
                                                    id={`antiTheftCategoryDropdown${column - 1}`}
                                                    alwaysShowPlaceholder={false}
                                                    placeholder=''
                                                    availableValues={getAntiTheftCategoryAvailableValues(vehicle)}
                                                    hideLabel
                                                    value={vehicle.value.tempAntiTheftCategory}
                                                    onValueChange={(value) => {
                                                        handleValueChange(value, `[${column - 1}].value.tempAntiTheftCategory`);
                                                        _set(vehicle, 'value.tempAntiTheftCategory', value)
                                                    }}
                                                    showErrors={showErrors}
                                                />
                                                <Button onClick={() => { addAntiTheftCategoryClick(vehicle, `${path}[${column - 1}]`) }} icon="mi-add" type="outlined" className="mt-2">
                                                    {translator(messages.addToList)}
                                                </Button>
                                            </div></React.Fragment>) : <div />
                                        }
                                </div>)
                                : <div />}
                        </React.Fragment>
                    )
                }

                const activeDeviceIdValue = _find(vehicle.safetyFeatures.children, (feature) => feature.vehicleEquipmentType?.value?.code === 'ActiveDevice') !== undefined;
                const passiveDeviceIdValue = _find(vehicle.safetyFeatures.children, (feature) => feature.vehicleEquipmentType?.value?.code === 'PassiveDevice') !== undefined;

                return (<React.Fragment>
                    {
                        visible ? <div id={`${vehicleField.id}${column - 1}`}>
                            <CheckboxField
                                id="activeDeviceID"
                                label="Active Device"
                                showInlineLabel
                                value={activeDeviceIdValue}
                                path={`${path}[${column - 1}]`}
                                readOnly={viewOnlyMode}
                                onValueChange={onActiveDeviceValueChange}
                            />
                            <CheckboxField
                                id="passiveDeviceID"
                                label="Passive Device"
                                showInlineLabel
                                value={passiveDeviceIdValue}
                                path={`${path}[${column - 1}]`}
                                readOnly={viewOnlyMode}
                                onValueChange={onPassiveDeviceValueChange}
                            />
                        </div> : <React.Fragment />
                    }
                </React.Fragment>);
            }
        }

        if (vehicleField.component === 'TypeaheadMultiSelect') {
            let {visible} = vehicleField.componentProps;
            let {availableOptions} = vehicleField;
            let value = [];
            let {onChange} = vehicleField.componentProps;
            let safetyEquipmentList = [];
            let readOnly = viewOnlyMode;

            if (vehicleField.id === 'safetyFeaturesTypeaheadMultiSelect') {
                availableOptions = vehicleSafetyFeatures[column - 1] || [];
                visible = !!(vehicle.make.value && vehicle.model.value);
                // If user do not have permission to override auto safety equipment or user is in view only mode, show it as readonly
                readOnly = viewOnlyMode || !authUserData.permissions_Ext.includes('overrideautosafetyequip_ext');
                value = _get(userEnteredSafetyFeatures, column - 1, []);
                onChange = (changeValue) => {
                    const updatedSafetyFeatures = [...userEnteredSafetyFeatures];

                    _set(updatedSafetyFeatures, column - 1, changeValue)
                    updateUserEnteredSafetyFeatures(updatedSafetyFeatures);
                    updateSafetyFeatures(vehicle, updatedSafetyFeatures[column - 1], column - 1);
                };

                const safetyFeatures = _get(systemSafetyFeatures, column - 1, []);

                safetyEquipmentList = _map(safetyFeatures, (safetyFeature) => {
                    if (safetyFeature.vehicleEquipmentType === 'AirBags') {
                        if (safetyFeature.airBagType === 'Front') {
                            return `${safetyFeature.airBagType.replace(/([A-Z])/g, ' $1').trim()} ${safetyFeature.vehicleEquipmentType.replace(/([A-Z])/g, ' $1').trim()}`;
                        }

                        if (safetyFeature.airBagType === 'Both') {
                            return `Front and Side ${safetyFeature.vehicleEquipmentType.replace(/([A-Z])/g, ' $1').trim()}`;
                        }

                        return `No ${safetyFeature.vehicleEquipmentType.replace(/([A-Z])/g, ' $1').trim()}`;
                    }

                    return `${safetyFeature.vehicleEquipmentType.replace(/([A-Z])/g, ' $1').trim()}`;
                });
            }

            return (
                <React.Fragment>
                    <Grid className="mb-4">
                        {
                            _map(safetyEquipmentList, (equipmentDisplayName, i) => (<GridItem key={`safetyEquipmentGridItem-${i}`}>
                                <InputField
                                    id={`safetyEquipmentItem-${column - 1}`}
                                    hideLabel
                                    disabled
                                    value={equipmentDisplayName}
                                    title=''
                                    placeholder=''
                                />
                            </GridItem>))
                        }
                    </Grid>
                    <TypeaheadMultiSelectField
                        readOnly={readOnly}
                        visible={visible}
                        availableValues={availableOptions}
                        value={value}
                        onValueChange={onChange}
                        id={vehicleField.id}
                        allowNew={vehicleField.componentProps.allowNew}
                        singleSelect={vehicleField.componentProps.singleSelect}
                        stickyIndicator={vehicleField.componentProps.stickyIndicator}
                        readOnlySeparator={vehicleField.componentProps.readOnlySeparator}
                    />
                </React.Fragment>
            );
        }

        return (<span>
            {row} {column}
        </span>)
    }

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const COLUMNS = vehicles.length + 1;
    const columnConfig = [];

    for (let column = 0; column < COLUMNS; column += 1) {
        const columnWidthToPush = '310px';

        columnConfig.push(columnWidthToPush);
    }

    const ROWS = vehicleLabels.length + 1;
    const numberOfCells = COLUMNS * ROWS;
    const tableContent = [];

    const removeVehicle = (index) => {
        const evt = {
            path: `lobData.personalAuto_EA.coverables.vehicles.children[${index}]`
        };

        onRemoveVehicle(evt);
    }

    for (let cell = 0; cell <= numberOfCells - 1; cell += 1) {
        const rowNumber = Math.floor(cell / COLUMNS) + 1;
        const columnNumber = cell % COLUMNS;

        if (cell === 0) {
            // empty cell
            tableContent.push(<GridItem className="grid-item-sticky grid-item-label" key={`quotename${cell}`} />)
        }
        // Vehicle Name
        else if (cell === columnNumber) {
            tableContent.push
                (<GridItem
                    tag="div" key={`vehicleNameGridItem${cell}`} className="grid-border-thin font-b">
                    <Grid tag="div" id={`vehicleTitleHeader${cell}`} key={`vehicleTitleHeader${cell}`} columns={["14rem", "1rem"]} className="p-5">
                        <div key={`vehicleLabelID${cell}`} className="font-grid-title">
                            {getVehicleName(vehicles.children[cell - 1])}
                        </div>
                        {!viewOnlyMode ? (<IconButton
                            id={`eaDeleteVehicle${cell}`}
                            icon="mi-delete"
                            onClick={() => removeVehicle(columnNumber - 1)}
                        />) : (undefined)}
                    </Grid>
                </GridItem>)
        }
        // first column - render row headers
        else if ((cell % COLUMNS === 0)) {
            let itemStyle = "grid-item-label";

            // background style
            if (rowNumber % 2 === 0) {
                itemStyle += " grid-item-color-even";
            }

            // last row of the grid
            if (rowNumber === ROWS) {
                itemStyle += " grid-item-last";
            }

            const labelIndex = rowNumber - 2;

            tableContent.push
                (<GridItem
                    tag="div" key={`vehicleLabel${cell}`} className={itemStyle}>
                    {formatLabel(vehicleLabels[labelIndex])}
                </GridItem>)
        } else {
            let itemStyle = "grid-item";

            // background style
            if (rowNumber % 2 === 0) {
                itemStyle += " grid-item-color-even";
            }

            // last row of the grid
            if (rowNumber === ROWS) {
                itemStyle += " grid-item-last";
            }

            const vehicleData = vehicleFields[columnNumber - 1];
            const vehicleField = vehicleData ? vehicleData[rowNumber - 2] : undefined;
            const isFetchingData = _get(isFetchingVehicleData, columnNumber - 1);

            if (vehicleField !== undefined) {
                let gridItemElement;

                if (isFetchingData) {
                    // If fetching data show loader for first row and empty content for other rows
                    gridItemElement = rowNumber - 2 === 0 ? <E1PLoader loaded={false} /> : <React.Fragment />;
                } else {
                    // If not fetching data, show actual field
                    gridItemElement = formatField(rowNumber, columnNumber);
                }

                tableContent.push(<GridItem
                    tag="div" key={`vehicleDataCell${cell}`} className={itemStyle} id={`${vehicleField.id}${columnNumber - 1}`}>
                    {gridItemElement}
                </GridItem>)
            }
        }
    }

    return (
        <React.Fragment>
            {showInfoFieldsRemovedMessage && <InlineNotification id="fieldsClearedInfoMessage" message={infoFieldsRemovedMessage} type="info" isDismissable={false} className="mt-5" />}
            {showYearVinInvalidMessage && <InlineNotification id="yearVinInvalidInfoMessage" message={translator(eaValidationAndInfoMessages.vinYearInvalid)} type="info" isDismissable={false} className="mt-5" />}
            {showSeventeenDigitsVinRequiredErrorMessage && <InlineNotification id="seventeenDigitsVinRequiredErrorMessage" message={translator(eaCommonMessages.seventeenDigitsVinRequiredErrorMessage)} type="error" isDismissable={false} className="mt-5" messageProps={{ error: " " }} />}

            <Grid className="mt-8" columns={columnConfig} hgap="small" vgap="none">
                {tableContent}
            </Grid>
        </React.Fragment>
    );
}

EAVehicleGridComponent.propTypes = {
    onValidate: PropTypes.func.isRequired,
    vehicles: PropTypes.shape({}).isRequired,
    policyState: PropTypes.string.isRequired,
    isSkipping: PropTypes.bool,
    availableMakes: PropTypes.shape({}),
    setAvailableMakes: PropTypes.func,
    availableModels: PropTypes.shape({}),
    setAvailableModels: PropTypes.func,
    availableSeries: PropTypes.shape({}),
    setAvailableSeries: PropTypes.func
};

EAVehicleGridComponent.defaultProps = {
    isSkipping: false,
    availableMakes: [],
    setAvailableMakes: undefined,
    availableModels: [],
    setAvailableModels: undefined,
    availableSeries: [],
    setAvailableSeries: undefined
};

export default EAVehicleGridComponent;
