import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, set, isUndefined, filter, findIndex, isEmpty, isNil
} from 'lodash';
 
import config from 'app-config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useModal } from '@jutro/components';
import { RenewalService } from 'e1p-capability-renewal';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { UmbrellaFlowUtil, ClausesUtil as e1pClausesUtil, CoverageUtil } from 'e1p-portals-util-js';
import { QuoteProposalService } from 'e1p-capability-gateway';
import metadata from './CoveragesPage.metadata.json5';
import wizardMessages from '../../EURenewalWizard.messages';

const structureCustomQuote = (renewalVM, clauses) => 
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        coverages: clauses.personalUmbrella_EU
    })
;
const getCustomQuote = (vm, lobPath, lobName, filterChangedClauses = false) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages, lobName, null
        );
    }

    return structureCustomQuote(vm, clausesToUpdate);
};
const LOB = 'personalUmbrella_EU';

function CoveragePage(props) {
    const modalApi = useModal();
    const [loadingClause, updateLoadingClause] = useState();
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        steps,
        jumpTo,
        updateWizardSnapshot,
        authHeader,
        onCustom2: handleDiscardChanges,
        authUserData,
        e1pGoNext,
        markFurthestStep,
        currentStepIndex
    } = props;
    const paperLessIndValue = get(renewalVM, 'lobData.personalUmbrella_EU.paperlessInd.value') === undefined
        ? false : get(renewalVM, 'lobData.personalUmbrella_EU.paperlessInd.value');

    const translator = useTranslator();
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [isFetchingQuoteProposal, setIsFetchingQuoteProposal] = useState(false);
    const [isQuoteStale, setIsQuoteStale] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const modifiers = get(renewalVM, 'lobData.personalUmbrella_EU.modifiers.value', []);
    // canStartEdit_Ext will be true if the renewal is in workflow, else it will be false
    const isRenewalInWorkflow = get(renewalVM, 'value.canStartEdit_Ext', false);
    const isDiscardButtonActive = get(renewalVM, 'value.canDiscard_Ext', false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [
        hasAlreadyApprovedLiabilityLimitUWIssue,
        setHasAlreadyApprovedLiabilityLimitUWIssue
    ] = useState(false);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const changeSummaryIndex = findIndex(steps, ({ path }) => path === '/change-review');
    const showPremium = CoverageUtil.isTotalPremiumVisible(renewalVM,authUserData) && !isQuoteStale;

    const {
        isComponentValid,
        onValidate,
        initialValidation,
        registerComponentValidation,
        disregardFieldValidation,
        registerInitialComponentValidation
    } = useValidation('EUCoveragePage');

    const writeValue = useCallback(
        (value, path) => {
            set(renewalVM, path, value);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData]
    );

    useEffect(() => {
        /**
         * E1PAP1PC-15339 :
         * Need to set this variable as
         * we don't want to show info message if UW issue is approved
         * If uw issue is approved and user comes back on coverage page and changes
         * LiabilityLimit cov limit >=6000000;
         * update coverage response does not send uw issues back so we need this
         */
        setHasAlreadyApprovedLiabilityLimitUWIssue(
            UmbrellaFlowUtil.hasLiabilityLimitChangeBlockQuoteUwIssueApproved(
                get(renewalVM, 'value.errorsAndWarnings.underwritingIssues',
                    get(renewalVM, 'value.errorsAndWarnings_Ext.underwritingIssues', []))
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // overriding coverage help text for given clause.
    useEffect(() => {
        get(renewalVM, 'value.lobData.personalUmbrella_EU.offerings[0].coverages.coverages').forEach((clause) => {
            const coveragesHelpText = CoverageUtil.getCoveragesHelpTextForEU(clause);

            if (coveragesHelpText) {
                clause.description = coveragesHelpText
            }
        });

        // set isQuoteStale, when status is draft
        if (get(renewalVM, 'value.baseData.periodStatus') === 'Draft') {
            setIsQuoteStale(true);
        }
    }, [renewalVM]);

    // In Renewal flow user will directly land on coverage page so making each step as visited
    // so that user can navigate to visited pages by clicking on tabs on chevron
    useEffect(() => {
        markFurthestStep(currentStepIndex); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps]);

    const onUpdateCustomQuote = useCallback(
        async (_basePath, lobPath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(renewalVM, lobPath, lobName, false);
            const oldPolicyChangeVM = viewModelService.clone(renewalVM);
             
            const getRemovedClausesID = (newPolicyChangeVM, path) => ClausesUtil.getRemovedClausesID(
                oldPolicyChangeVM, newPolicyChangeVM, path
            );
            const updatedCoverages = await RenewalService.updateCoverages(
                renewalVM.value.jobID,
                 
                { personalUmbrella_EU: customQuote.coverages },
                renewalVM.sessionUUID.value,
                authHeader
            );

            // preventing the backend from updating stale paperlessInd
            // data with user's latest preference for paperlessInd
            updatedCoverages.lobData.personalUmbrella_EU.paperlessInd = renewalVM.value
                .lobData.personalUmbrella_EU.paperlessInd;
            // preventing the email from being erased
            updatedCoverages.lobData.personalUmbrella_EU.paperlessEmail = renewalVM.value
                .lobData.personalUmbrella_EU.paperlessEmail;

            const changedPath = lobPath.replace(/.children/, '');

            renewalVM.value = updatedCoverages;

            const updatedClauses = get(updatedCoverages, `${changedPath}.coverages`);

            set(renewalVM, `${lobPath}.coverages`, updatedClauses);

            const allRemovedFields = [...getRemovedClausesID(renewalVM, `${lobPath}.coverages.coverages`)];

            disregardFieldValidation(allRemovedFields);
            setIsQuoteStale(true);
            updateWizardData(renewalVM);
            updateLoadingClause(undefined);

            return updatedCoverages;
        },
        [authHeader, disregardFieldValidation, renewalVM, updateWizardData,
            viewModelService]
    );
    const onClauseChange = useCallback((schedule, path) => {
        const lobOfferingPath = 'lobData.personalUmbrella_EU.offerings.children[0]';

        writeValue(schedule, path);

        return onUpdateCustomQuote({}, lobOfferingPath);
    }, [onUpdateCustomQuote, writeValue]);

    const changeSubmission = useCallback(
        (value, changedPath) => {
            set(renewalVM, changedPath, value);
            e1pClausesUtil.setClauseValue(renewalVM, value, changedPath);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData]
    );

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = get(renewalVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(renewalVM, 'lobData.personalUmbrella_EU.paperlessInd.value'); 
        const paperlessEmail = get(renewalVM, 'lobData.personalUmbrella_EU.paperlessEmail.value');
 
        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [renewalVM, isPaperlessEmailUpdated]);

    const syncClauses = useCallback(
        async (value, changedPath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const baseObject = get(renewalVM, basePath);

            updateLoadingClause(baseObject.coveragePublicID || baseObject.publicID);
            await onClauseChange();
            updateWizardData(renewalVM);
        }, [onClauseChange, renewalVM, updateWizardData]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncClauses(value, changedPath);
        },
        [changeSubmission, syncClauses]
    );


    const updatePaperlessIndAndEmail = (value, path) => {
        set(renewalVM, path, value);

        switch (path) {
            case 'lobData.personalUmbrella_EU.paperlessInd':
                if (value) {
                    const pniEmail = get(renewalVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value');

                    if (get(renewalVM, 'lobData.personalUmbrella_EU.paperlessEmail.value') === undefined) {
                        set(renewalVM, 'lobData.personalUmbrella_EU.paperlessEmail.value', pniEmail);
                    }
                } else {
                    set(renewalVM, 'lobData.personalUmbrella_EU.paperlessEmail.value', undefined);
                }

                break;
            default:
                break;
        }

        setIsPaperlessEmailUpdated(true);
        updateWizardData(renewalVM);
    };

    const discountChange = (value, path) => {
        set(renewalVM, path, value);
        updateWizardData(renewalVM, false);
    };

    const getTotalPremium = useCallback(() => {
        const offerings = get(renewalVM, `lobData[${LOB}].offerings.value`, []);
        let selectedOffering = offerings[0];

        if (offerings.length > 1) {
            const offeredQuotes = get(renewalVM, 'quoteData.offeredQuotes.value', []);
            const selectedQuoteBranchCode = offeredQuotes?.find((quote) => quote.selected)?.branchCode;

            if (selectedQuoteBranchCode) {
                selectedOffering = offerings.find((offering) => offering.branchCode === selectedQuoteBranchCode)
            }
        }

        const payPlans = get(selectedOffering, 'paymentPlans');
        const selectedPlan = filter(payPlans, (plan) => plan.isSelected);
        const totalPremium = selectedPlan[0]?.total?.amount;

        if (!isNil(totalPremium)) {return { currency: 'usd', amount: totalPremium };}

        return undefined;

    }, [renewalVM]);



    const saveAndQuote = useCallback(
        async () => {
            let quoteRenewal;

            try {
                setIsSavingRenewal(true);

                // If paperless Email id exist and pni email id is undefiend
                // then update pni email to paperless email
                if (isUndefined(get(renewalVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value'))
                    && !!get(renewalVM, 'lobData.personalUmbrella_EU.paperlessEmail.value')) {
                    set(renewalVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value',
                        get(renewalVM, 'lobData.personalUmbrella_EU.paperlessEmail.value'));
                }

                quoteRenewal = await RenewalService.saveAndQuoteRenewal(
                    [(renewalVM.value)],
                    authHeader
                );
            } catch {
                setIsSavingRenewal(false);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: e1pCommonMessages.saveCoverageError,
                    message: e1pCommonMessages.saveCoverageErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });
            }

            renewalVM.value = quoteRenewal;
            updateWizardData(renewalVM);
            /**
             * E1PAP1PC-15339 :
             * Need to set this variable as
             * we don't want to show info message if UW issue is approved
             * If uw issue is approved and user comes back on coverage page and changes
             * LiabilityLimit cov limit >=6000000;
             * update coverage response does not send uw issues back so we need this
             */
            setHasAlreadyApprovedLiabilityLimitUWIssue(
                UmbrellaFlowUtil.hasLiabilityLimitChangeBlockQuoteUwIssueApproved(
                    get(renewalVM, 'value.errorsAndWarnings.underwritingIssues',
                        get(renewalVM, 'value.errorsAndWarnings_Ext.underwritingIssues', []))
                )
            );
            setIsSavingRenewal(false);

            return renewalVM;
        },
        [authHeader, modalApi, renewalVM, updateWizardData]
    );

    const onNext = useCallback(
        async () => {
            await saveAndQuote();

            return renewalVM;
        },
        [renewalVM, saveAndQuote]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    const recalculate = useCallback(
        async () => {
            setIsSavingRenewal(true);

            // If paperless Email id exist and pni email id is undefiend
            // then update pni email to paperless email
            if (isUndefined(get(renewalVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value'))
                && !!get(renewalVM, 'lobData.personalUmbrella_EU.paperlessEmail.value')) {
                set(renewalVM, 'lobData.personalUmbrella_EU.primaryNamedInsured.person.emailAddress1.value',
                    get(renewalVM, 'lobData.personalUmbrella_EU.paperlessEmail.value'));
            }

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            setIsQuoteStale(false);
            updateWizardData(renewalVM);
            setIsSavingRenewal(false);

            return false;
        },
        [authHeader, renewalVM, updateWizardData]
    );

    const reviewChanges = useCallback(
        async () => {
            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setIsSavingRenewal(false);

            return false;
        },
        [authHeader, changeSummaryIndex, jumpTo, renewalVM, updateWizardData, updateWizardSnapshot]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            autoComplete: false
        },
        premiumMightChangeMessageDiv: {
            visible: renewalVM.baseData.periodStatus.value.code !== 'Bound'
        },
        coveragesPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(e1pCommonMessages.loadingNextPage)
        },
        coveragePageMainContainer: {
            visible: isPageLoaded
        },
        paymentOptionsID: {
            submissionVM: renewalVM,
            authHeader,
            updateWizardData,
            LOB: 'personalUmbrella_EU',
            isQuoteStale
        },
        totalPremiumID: {
            visible: showPremium,
            value: (() => getTotalPremium())()
        },
        emptyPremium: {
            visible: !showPremium
        },
        monthlyPaymentScheduleComponent: {
            quoteID: get(renewalVM, 'quoteID.value', renewalVM.jobID?.value),
            authHeader,
            transactionTotalAmount: getTotalPremium(),
            changeInCost: renewalVM.transactionCost?.value,
            startDate: renewalVM.baseData.periodStartDate.value,
            endDate: renewalVM.baseData.periodEndDate.value,
            jobTypeCode: renewalVM.baseData.jobType.value.code,
            offerings: get(renewalVM, 'lobData.personalUmbrella_EU.offerings.value')
        },
        paperlessInd: {
            required: true,
            value: get(renewalVM, 'lobData.personalUmbrella_EU.paperlessInd.value'),
            onValueChange: updatePaperlessIndAndEmail,
            labelPosition: 'top',
            visible: get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled'])
        },
        paperlessEmailId: {
            visible: paperLessIndValue && get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled']),
            required: paperLessIndValue,
            onValueChange: updatePaperlessIndAndEmail,
            labelPosition: 'top',
        },
        AutoPayDiscountToggle: {
            labelPosition: 'top',
            onValueChange: discountChange,
            value: get(renewalVM, 'lobData.personalUmbrella_EU.autoPayDiscInd.value')
        },
        buyNowButton: {
            visible: !isQuoteStale,
            onClick: async () => {
                await onNext();

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    e1pGoNext();
                }
            }
        },
        recalculateButton: {
            visible: isQuoteStale,
            onClick: () => recalculate()
        },
        wizardPageHeader: {
            wizardSubmission: renewalVM
        },
        coverage: {
            loadingClause,
            isQuoteStale,
            labelPosition: 'top'
        },
        euSurchargeListComponentId: {
            // modifiers other than discount will be shown in surchage and fee section
            value: modifiers.filter(
                (item) => item.applied && item.modifierType !== "discount"
            ),
            transactionVM: renewalVM,
            updateWizardData
        },
        euDiscountsListComponentId: {
            visible: !!filter(modifiers, { applied: true, modifierType: 'discount' }).length,
            value: modifiers
        },
        quoteProposalLinkContainer: {
            onClick: (e) => {
                e.preventDefault();
                setIsFetchingQuoteProposal(true);
                QuoteProposalService.retriveQuoteProposalURL(
                    get(renewalVM, 'jobID.value'),
                    authHeader
                ).then((response) => {
                    window.open(response.quoteProposalURL, '_blank');
                    setIsFetchingQuoteProposal(false);
                }).catch(() => {
                    setIsFetchingQuoteProposal(false);
                });
            },
            disabled: renewalVM.baseData.periodStatus === 'Quoted'
                || isFetchingQuoteProposal
        },
        personalLiabilityLimitUwIssueInfoMessageDiv: {
            visible: UmbrellaFlowUtil
                .isPersonalLiabilityLimitUwIssueInfoMessage(
                    renewalVM,
                    hasAlreadyApprovedLiabilityLimitUWIssue
                )
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
    };
    const resolvers = {
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onValidate
        },
    };

    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, renewalVM, id, path, overrideProps), [overrideProps, renewalVM]);

    const isPageLoading = useCallback(() => isUndefined(loadingClause), [loadingClause]);

    const isQuoted = useCallback(() => get(renewalVM, 'status') === 'quoted', [renewalVM]);

    useEffect(() => {
        registerComponentValidation(isPageLoading);
        registerInitialComponentValidation(isQuoted);
    }, [isPageLoading, isQuoted, registerComponentValidation, registerInitialComponentValidation]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            onNext={onNext}
            showNext={!isQuoteStale}
            showCustom={!isRenewalInWorkflow && isQuoteStale}
            customLabel={wizardMessages.recalculateButtonLabel}
            onCustom={recalculate}
            disableCustom={!isComponentValid}
            showCustom1
            onCustom1={reviewChanges}
            custom1Label={wizardMessages.reviewChangesLabel}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            onSave={onSave}
            showOnSave
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

CoveragePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};
export default withRouter(withAuthenticationContext(CoveragePage));
