import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import htmlParser from 'html-react-parser';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { ContentService } from 'e1p-capability-gateway';
import { getConfigValue } from 'e1p-portals-util-js';
import { useTranslator } from '@jutro/locale';
import { commonMessages } from 'e1p-platform-translations';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import PropTypes from 'prop-types';
import styles from './E1PAcknowledgementComponent.module.scss';
import metadata from './E1PAcknowledgementComponent.metadata.json5';
import messages from './E1PAcknowledgementComponent.messages';

const lobMap = {
    personalAuto_EA: 'Auto',    
    homeowners_EH: 'Homeowners',    
    personalUmbrella_EU: 'Umbrella'
}

function E1PAcknowledgementComponent(props) {
    const modalApi = useModal();
    const {
        acknowledgements,
        policyState,
        onValueChange,
        viewOnly,
        policyType,
        hideConsumerReportDisclosure,
        showErrors,
        lob
    } = props;
    const underwritingGuidelinesEndpoint = getConfigValue('endpoints.underwritingGuidelinesEndpoint');

    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const [disclosureCheck, updateDisclosureCheck] = useState(false);
    const [guidelinesCheck, updateGuidelinesCheck] = useState(false);
    const [legalDisclosureCheck, updateLegalCheck] = useState(false);
    const [animalDisclosureCheck, updateAnimalDisclosureCheck] = useState(false);
    const translator = useTranslator();

    const { onValidate } = useValidation('E1PAcknowledgementComponent');
    const { opCo } = useContext(AmfamOktaTokenContext);
    const handleValueChange = useCallback(() => {
        if (onValueChange) {
            onValueChange(acknowledgements);
        }
    }, [onValueChange, acknowledgements]);

    const setAcknowledgement = useCallback(
        (acknowledgementType, answerType) => {
            const acknowledgementToBeUpdated = acknowledgements.value.find(
                (acknowledgement) => acknowledgement?.acknowledgementType === acknowledgementType
            );

            _.set(acknowledgementToBeUpdated, 'acknowledgeAnswerType', answerType);
            handleValueChange();
        },
        [handleValueChange, acknowledgements.value]
    );

    const getApplicableAcknowledgements = useCallback(() => {
        const acknowledgementTypes = ['underwritingguidelines', 'legaldisclaimer'];

        if (policyType === 'HF9') {
            acknowledgementTypes.push('animalandpoolliabdisclaimer');
        }

        if (!hideConsumerReportDisclosure) {
            acknowledgementTypes.push('consumerreportdisclosure');
        }

        return acknowledgementTypes;
    }, [hideConsumerReportDisclosure, policyType]);

    const defaultAcknowledgement = useCallback((acknowledgementType) => {
        const acknowledgementToBeDefaulted = acknowledgements?.value?.find(
            (acknowledgement) => acknowledgement?.acknowledgementType === acknowledgementType
        );

        if (acknowledgementToBeDefaulted === undefined) {
            acknowledgements.value.push({
                acknowledgementType,
                acknowledgeAnswerType: 'reject',
                fixedId: undefined
            });
        }
    }, [acknowledgements.value]);

    const defaultAcknowledgments = useCallback(() => {
        const allAcknowledgementTypes = getApplicableAcknowledgements();

        allAcknowledgementTypes.forEach((acknowledgementType) => {
            defaultAcknowledgement(acknowledgementType);
        });
    }, [defaultAcknowledgement, getApplicableAcknowledgements]);

    useEffect(() => {
        if (acknowledgements.value === undefined || acknowledgements.value.length === 0) {
            _.set(acknowledgements, 'value', []);
        }

        // defaults all acknowledgements with reject value if acknowledgement is not present
        defaultAcknowledgments();

        const setAcknowledgementCheckBox = (acknowledgeType) => {
            const foundAcknowledgement = acknowledgements.value.find(
                (acknowledgement) => acknowledgement?.acknowledgementType === acknowledgeType
            );

            return foundAcknowledgement?.acknowledgeAnswerType === 'accept';
        };

        updateGuidelinesCheck(setAcknowledgementCheckBox('underwritingguidelines'));
        updateLegalCheck(setAcknowledgementCheckBox('legaldisclaimer'));

        if (!hideConsumerReportDisclosure) {
            updateDisclosureCheck(setAcknowledgementCheckBox('consumerreportdisclosure'));
        }

        if (policyType === 'HF9') {
            updateAnimalDisclosureCheck(setAcknowledgementCheckBox('animalandpoolliabdisclaimer'));
        }

        handleValueChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // if user changes policy types from hf9 to other policy type then we want to remove
        // animalandpoolliabdisclaimer from acknowledgements as its only applicable for hf9
        if (policyType !== 'HF9') {
            _.remove(
                acknowledgements?.value, (acknowledgement) => acknowledgement
                    .acknowledgementType === 'animalandpoolliabdisclaimer'
            );
        } else {
            defaultAcknowledgement('animalandpoolliabdisclaimer');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyType]);

    const changeDisclosureValue = useCallback(
        (value) => {
            if (value) {
                setAcknowledgement('consumerreportdisclosure', 'accept');
            } else {
                setAcknowledgement('consumerreportdisclosure', 'reject');
            }

            updateDisclosureCheck(value);
        },
        [setAcknowledgement]
    );

    const changeGuidelinesValue = useCallback(
        (value) => {
            if (value) {
                setAcknowledgement('underwritingguidelines', 'accept');
            } else {
                setAcknowledgement('underwritingguidelines', 'reject');
            }

            updateGuidelinesCheck(value);
        },
        [setAcknowledgement]
    );

    const changeLegalDisclaimer = useCallback(
        (value) => {
            if (value) {
                setAcknowledgement('legaldisclaimer', 'accept');
            } else {
                setAcknowledgement('legaldisclaimer', 'reject');
            }

            updateLegalCheck(value);
        },
        [setAcknowledgement]
    );

    const changeAnimalDisclouserCheck = useCallback(
        (value) => {
            if (value) {
                setAcknowledgement('animalandpoolliabdisclaimer', 'accept');
            } else {
                setAcknowledgement('animalandpoolliabdisclaimer', 'reject');
            }

            updateAnimalDisclosureCheck(value);
        },
        [setAcknowledgement]
    );

    // this will be used to fecth data for both consumer disclosure and underwriting guidelinies
    const getPrivacyNoticeInfo = useCallback((contentServiceType) => {
        // Create the requestDTO, and call the service
        const contentRequestVM = viewModelService.create(
            {},
            'pc',
            'amfam.edge.capabilities.policyjob.common.contentservice.dto.ContentRequestDTO'
        );

        _.set(contentRequestVM, 'contractState.value', policyState.code);
        _.set(contentRequestVM, 'contentServiceType.value', contentServiceType);
        _.set(contentRequestVM, 'lineOfBusiness.value', lobMap[lob]);

        const contentToShow = ContentService.getContent(contentRequestVM.value, authHeader)
            .then((response) => response.content)
            .catch(() => undefined);

        return contentToShow;
    }, [viewModelService, policyState, authHeader, lob]);

    const showModal = useCallback((title, contentForPopup) => {
        const componentProps = {
            title: translator(title),
            iconClassType: false,
            message: translator(htmlParser(contentForPopup)),
            confirmButtonText: viewOnly ? translator(commonMessages.close) : translator(messages.iAccept)
        };

        if (viewOnly) {
            return modalApi.showAlert(componentProps);
        }

        return modalApi.showConfirm(componentProps);
    }, [translator, viewOnly, modalApi]);

    const triggerGuidelineModal = useCallback(
        async (evt) => {
            evt.preventDefault();

            // IAP-1885 show the popup for auto 1.0 and 2.0 states
            if (lobMap[lob] === 'Auto') {
                const contentType = (opCo === 'MSA') ? 'underwritingguidelines' : 'underwritingguidelines2';
                const contentForPopup = await getPrivacyNoticeInfo(contentType);
                const title = translator(messages.underwritingGuidelines);

                if (contentForPopup === undefined) {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: translator(messages.anErrorOccurredTitle),
                        message: translator(messages.anErrorOccurred)
                    });
                } else {
                    showModal(title, contentForPopup)
                        .then((result) => {
                            if (result !== 'cancel') {
                                setAcknowledgement('underwritingguidelines', 'accept');
                                updateGuidelinesCheck(true);
                            }
                        })
                        .catch(() => {});
                }
            } else if (policyState.code && policyState.name) {
                // property and umbrella will have same functionality
                // If you click this link before choosing your state it takes you to a not found page
                window.open(`${underwritingGuidelinesEndpoint}`, '_blank');
                setAcknowledgement('underwritingguidelines', 'accept');
                updateGuidelinesCheck(true);
            }
        },
        [policyState.code, policyState.name, setAcknowledgement, underwritingGuidelinesEndpoint, lob, opCo, showModal, getPrivacyNoticeInfo, translator, modalApi]
    );

    const triggerContentModal = useCallback(
        async (evt) => {
            evt.preventDefault();

            const contentForPopup = await getPrivacyNoticeInfo('consumerreportdisclosure');
            const title = 'Consumer Report Disclosure';

            if (contentForPopup === undefined) {
                modalApi.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: translator(messages.anErrorOccurredTitle),
                    message: translator(messages.anErrorOccurred)
                });
            } else {
                showModal(title, contentForPopup)
                    .then((result) => {
                        if (result !== 'cancel') {
                            setAcknowledgement('consumerreportdisclosure', 'accept');
                            updateDisclosureCheck(true);
                        }
                    })
                    .catch(() => {
                    });
            }
        },
        [getPrivacyNoticeInfo, setAcknowledgement, showModal, translator, modalApi]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'top',
            readOnly: viewOnly,
            required: true,
            showRequired: true,
            autoComplete: false
        },
        disclosureCheckboxID: {
            value: disclosureCheck,
            onValueChange: changeDisclosureValue,
            showErrors: showErrors && !disclosureCheck,
            // checkboxes are werid; we have to set the message back to blank when checked
            validationMessages: !disclosureCheck ? [translator(messages.requiredField)] : []
        },
        disclosureInformationMainDiv: {
            visible: !hideConsumerReportDisclosure
        },
        unGuidelinesCheckboxID: {
            value: guidelinesCheck,
            onValueChange: changeGuidelinesValue,
            showErrors: showErrors && !guidelinesCheck,
            // checkboxes are werid; we have to set the message back to blank when checked
            validationMessages: !guidelinesCheck ? [translator(messages.requiredField)] : []
        },
        legalDisclaimerID: {
            value: legalDisclosureCheck,
            onValueChange: changeLegalDisclaimer,
            showErrors: showErrors && !legalDisclosureCheck,
            // checkboxes are werid; we have to set the message back to blank when checked
            validationMessages: !legalDisclosureCheck ? [translator(messages.requiredField)] : []
        },
        animalDisclosureCheckID: {
            value: animalDisclosureCheck,
            onValueChange: changeAnimalDisclouserCheck,
            showErrors: showErrors && !animalDisclosureCheck,
            // checkboxes are werid; we have to set the message back to blank when checked
            validationMessages: !animalDisclosureCheck ? [translator(messages.requiredField)] : []
        },
        animalDisclosureMainDiv: {
            visible: policyType === 'HF9',
            required: policyType === 'HF9',
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                acknowledgements,
                id,
                path,
                overrideProps
            ),
        [overrideProps, acknowledgements]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            disclosureLinkHandler: triggerContentModal,
            guidelinesLinkHandler: triggerGuidelineModal
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={acknowledgements}
            overrideProps={overrideProps}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

E1PAcknowledgementComponent.propTypes = {
    acknowledgements: PropTypes.shape({
        value: PropTypes.arrayOf(
            PropTypes.shape({})
        )
    }).isRequired,
    onValueChange: PropTypes.func,
    viewOnly: PropTypes.bool,
    hideConsumerReportDisclosure: PropTypes.bool,
    policyType: PropTypes.string,
    policyState: PropTypes.shape({}).isRequired,
    showErrors: PropTypes.bool,
    lob: PropTypes.string.isRequired
};

E1PAcknowledgementComponent.defaultProps = {
    viewOnly: false,
    hideConsumerReportDisclosure: false,
    showErrors: false,
    policyType: undefined,
    onValueChange: undefined
};
export default E1PAcknowledgementComponent;
