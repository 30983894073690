import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, find, findIndex, unset, pullAt, set
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDriverPageUtil } from 'e1p-capability-hooks';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import metadata from './DriversPage.metadata.json5';
import styles from './DriversPage.module.scss';

function EADriversPage(props) {
    const { wizardData: policyViewVM, authUserData, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);

    const [checkScrolling, setCheckScrolling] = useState(false);   

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(() => policyViewVM, [policyViewVM]);

    const {
        isDefensiveDriverDiscountAvailableForDriver,
        getDriverAge,
        isFinancialResponsibilityFormAvailableForDriver
    } = useDriverPageUtil();

    useEffect(() => {
        const driversPath = 'lobData.personalAuto_EA.coverables.drivers';
        const drivers = get(policyViewVM, `${driversPath}`);
        const pniPublicID = policyViewVM.lobData.personalAuto_EA.primaryNamedInsured.person.publicID.value;
        const sniPublicID = policyViewVM.lobData.personalAuto_EA.secondaryNamedInsured.person ? policyViewVM.lobData.personalAuto_EA.secondaryNamedInsured.person.publicID.value : undefined;
        const pniIntegrationID = policyViewVM.lobData.personalAuto_EA.primaryNamedInsured.integrationId.value;
        const sniIntegrationID = policyViewVM.lobData.personalAuto_EA.secondaryNamedInsured.person ? policyViewVM.lobData.personalAuto_EA.secondaryNamedInsured.integrationId.value : undefined;

        let pni = find(drivers.children, (driver) => driver.person.publicID.value === pniPublicID);
        const pniIndex = findIndex(drivers.children, (driver) => driver.person.publicID.value === pniPublicID);

        // Unset role. Fixes bug where you come back to drivers from a further page
        //   and ArrayUpdater has changed the ordering of your drivers and role has persisted
        //   so you end up seeing both  drivers as PNI
        drivers.children.forEach((driverNode) => {
            unset(driverNode, 'role');
        });

        // Put PNI back as first driver
        if (pniIndex > 0) {
            pni = viewModelService.clone(pni);
            pullAt(drivers.value, pniIndex);
            drivers.value.unshift(pni.value);
        }

        if (sniPublicID) {
            const sni = find(drivers.children, (driver) => driver.person.publicID.value === sniPublicID);

            if (sni) {
                set(sni, 'value.role', 'sni');
                set(sni, 'value.integrationId', sniIntegrationID);
            }
        }

        if (pni) {
            set(pni, 'value.role', 'pni');
            set(pni, 'value.integrationId', pniIntegrationID);
        }

        updateWizardData(policyViewVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            disabled: true,
            labelPosition: 'top'
        },
        driversPageLoadingIndicator: {
            loaded: true
        },
        addDriver: {
            visible: false
        },
        eaAddDriverSection: {
            visible: false
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('eadrivercontainer')
        },
        EADriverGrid: {
            drivers: get(policyViewVM, 'lobData.personalAuto_EA.coverables.drivers', []),
            path: 'lobData.personalAuto_EA.coverables.drivers.children',
            onValidate: ()=>{},
            viewModelService,
            showErrors: false,
            policyState: get(policyViewVM, 'baseData.policyAddress.state.value.code')
                || get(policyViewVM, 'policyAddress.state.value.code'),
            driverPageDisregardFieldValidation: ()=>{},
            checkLicenseRequired: ()=>{},
            driversWithLicenseValidationError: [],
            setDriversWithLicenseValidationErrors: ()=>{},
             getDriverAge,
            periodStartDate: get(policyViewVM, 'baseData.periodStartDate.value'),
            priorPolicyList: get(policyViewVM, 'lobData.personalAuto_EA.priorPolicyUpdates'),
            onRemoveDriver: ()=>{},
            isFinancialResponsibilityFormAvailableForDriver,
            isNewSubmission: true,
            viewOnlyMode: true,
            onValidationChange: ()=>{},
            setSNIHasDefensiveCourse: ()=>{},
            setPNIHasDefensiveCourse: ()=>{},
            authUserData,
            transactionVM: policyViewVM,
            isDefensiveDriverDiscountAvailableForDriver,
            updateWizardData: ()=>{},
            setCheckScrolling
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, policyViewVM, id, path, overrideProps),
        [overrideProps, policyViewVM]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddDriverClick: undefined,
            onRemoveDriver: undefined,
            onValidate: undefined,
            onPrefillData: undefined
        }
    };


    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyViewVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

EADriversPage.propTypes = wizardProps;
export default withAuthenticationContext(EADriversPage);
