import React, {
    useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {
    get, remove, findIndex, some, set, isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { usePriorPolicyUpdateUtil } from 'e1p-capability-hooks';
import appConfig from 'app-config';
import styles from './PriorPolicyComponent.module.scss';
import metadata from './PriorPolicyComponent.metadata.json5';
import './PriorPolicyComponent.messages';

/**
 * @purpose PriorPolicyComponent is being used to show single prior policy record
 * in grid format. This component is being used in PniPriorPolicyGridComponent
 * and SniPriorPolicyGridComponent.(both components is used for showing prefill prior policies)
 *
 * @param {*} props
 * @returns {*} object
 */
function PriorPolicyComponent(props) {
    const {
        data: priorPolicyVM,
        labelPosition,
        path,
        id,
        onValidate,
        onValueChange,
        viewOnlyMode,
        policyStartDate,
        checkLapsed,
        authUserData,
        priorPolicyUpdatesVM,
        setPriorCarrierChanged,
        isLatestPolicy,
        policyState,
        handleDispute
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation
    } = useValidation(id);
    const [reasonForCoverageLapse, updateReasonForCoverageLapse] = useState([]);
    const [valuesForLapse, setValuesForLapse] = useState([]);
    const [isCoverageLapse, updateIsCoverageLapse] = useState();

    const {
        createPriorPolicyUpdatesVM,
        removePriorPolicyUpdate,
    } = usePriorPolicyUpdateUtil(priorPolicyUpdatesVM);

    const auto2dot0States = appConfig.auto2dot0States ?? [];
    const isAuto2dot0State = auto2dot0States.includes(policyState);

    const hasUWConsiderationPermission = authUserData?.permissions_Ext.includes('uwconsideration_ext');

    useEffect(() => {
        if (checkLapsed) {
            checkLapsed();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [priorPolicyVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;

        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, path]);

    const handleCollisionCoverageToggle = useCallback((value) => {
        if (value) {
            priorPolicyVM.coverages.value.push({ coverageCode: 'Collision' });
        } else {
            remove(priorPolicyVM.coverages.value, (coverage) => coverage.coverageCode === 'Collision');
        }

        handleValueChange(priorPolicyVM.coverages.value, 'coverages');
    }, [handleValueChange, priorPolicyVM]);

    const handleComprehensiveCoverageToggle = useCallback((value) => {
        if (value) {
            priorPolicyVM.coverages.value.push({ coverageCode: 'Comprehensive' });
        } else {
            remove(priorPolicyVM.coverages.value, (coverage) => coverage.coverageCode === 'Comprehensive');
        }

        handleValueChange(priorPolicyVM.coverages.value, 'coverages');
    }, [handleValueChange, priorPolicyVM]);

    const BICovIndex = useMemo(
        () =>
            findIndex(
                priorPolicyVM.coverages.value,
                coverage => coverage.coverageCode === 'BodilyInjury'
            ),
        [priorPolicyVM.coverages.value]
    );

    const resolvers = {
        resolveClassNameMap: styles
    };

    useEffect(() => {
        if (hasUWConsiderationPermission) {
            let tempValuesForLapse = [
                {
                    code: 'UWConsideration',
                    name: 'UW Consideration'
                },
                {
                    code: 'dispute',
                    name: 'Dispute'
                }
            ];

            if (isAuto2dot0State) {
                // auto 2.0 states will not have uw consideration option
                tempValuesForLapse = tempValuesForLapse.filter((lapseValue) => lapseValue.code !== 'UWConsideration');
            }

            setValuesForLapse(tempValuesForLapse);
        } else {
            setValuesForLapse([
                {
                    code: 'dispute',
                    name: 'Dispute'
                }
            ]);
        }
    }, [hasUWConsiderationPermission, isAuto2dot0State]);

    /**
     *
     * @param {Object/String} e policy coverage date
     * The value of e could be an object or the date string from PC
     * If the e1p date component is visible (mounted), it will transform the
     * data to an object so it can be rendered properly. Otherwise it stays as a string.
     * We still need the date to see if coverage has lapse or not.
     */
    const checkCoverageLapse = (e) => {
        if (!e) { return; }

        const date = e.value || e;

        if (typeof (date) === 'object') {
            const tempDate = new Date(date.value.year, date.value.month, date.value.day);

            tempDate.setDate(tempDate.getDate() + 1);

            if (policyStartDate.value.isodate_Ext
                > tempDate.toISOString()) {
                updateIsCoverageLapse(true);
                set(priorPolicyVM, 'continuousCoverageInd', false);
            } else {
                updateIsCoverageLapse(false);
                set(priorPolicyVM, 'continuousCoverageInd', true);
            }

            if (checkLapsed) {
                checkLapsed();
            }
        } else if (typeof (date) === 'string') {
            // parse yyyy-mm-dd
            // Month and Day starts from 0, so need to subtract 1 from string
            const tempDate = new Date(
                date.substring(0, 4), // yyyy
                date.substring(5, 7) - 1, // mm - 1
                date.substring(8, 10), // dd
            );

            if (policyStartDate.value.isodate_Ext
                > tempDate.toISOString()) {
                updateIsCoverageLapse(true);
                set(priorPolicyVM, 'continuousCoverageInd', false);
            } else {
                updateIsCoverageLapse(false);
                set(priorPolicyVM, 'continuousCoverageInd', true);
            }

            if (checkLapsed) {
                checkLapsed();
            }
        }
    };

    useEffect(() => {
        // Only check Prior Policy for coverage lapse for latest policy
        if (isLatestPolicy) {
            checkCoverageLapse(get(priorPolicyVM, 'policyHolderExpirationDate'));
        }

        if (get(priorPolicyVM, 'isRemoved.value')) {
            updateReasonForCoverageLapse(['dispute']);
        } else if (priorPolicyUpdatesVM.value.length
            && priorPolicyUpdatesVM.value[0].isUWConsideration) {
            updateReasonForCoverageLapse(['UWConsideration']);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createPolicyUpdate = (reason) => {
        createPriorPolicyUpdatesVM(priorPolicyVM.integrationId.value, reason);
    };

    // checkbox group saves the input in an array with
    // the box selected as one of the values
    // this function searches for where that value is saved
    // then sets that value in the reason for coverage lapse
    const setLapseReason = (values) => {
        // current implementation treats this checkbox group as radio; its value is either UW cosideration or Dispute
        if (reasonForCoverageLapse.includes('dispute') || isEmpty(reasonForCoverageLapse)) {
            // if existing value was dispute and user selected uw consideration or no checkbox was previsouly selected and user selects uw consideration
            if (values.includes('UWConsideration')) {
                
                set(priorPolicyVM, 'isRemoved', undefined);

                if (!priorPolicyUpdatesVM.value.length) {
                    createPolicyUpdate('UWConsideration');
                }

                handleValueChange(undefined, 'isRemoved');

                if(reasonForCoverageLapse.includes('dispute')) {handleDispute(false);}
                
                updateReasonForCoverageLapse(['UWConsideration']);
            }
        }

        /**
         * if existing value was UWConsideration and user selected dispute 
         * or no checkbox was previsouly selected and user selects dispute
         */
        if (values.includes('dispute')
            && (reasonForCoverageLapse.includes('UWConsideration') || isEmpty(reasonForCoverageLapse))) {
            set(priorPolicyVM, 'isRemoved', true);
            handleValueChange(true, 'isRemoved');

            if (priorPolicyUpdatesVM.value.length) {
                removePriorPolicyUpdate(priorPolicyUpdatesVM.value.length - 1);
            }

            handleDispute(true);
            updateReasonForCoverageLapse(['dispute']);
        }

        // No boxes checked now
        if (isEmpty(values)) {
            // Dispute was unchecked
            if (reasonForCoverageLapse.includes('dispute')) {
                set(priorPolicyVM, 'isRemoved', undefined);
                handleDispute(false);
                handleValueChange(undefined, 'isRemoved');
                updateReasonForCoverageLapse(values);
            }

            // UWConsideration was unchecked
            if (reasonForCoverageLapse.includes('UWConsideration')) {
                // value is originally false, so putting it back to false
                set(priorPolicyVM, 'isRemoved', false);

                // Remove update VM
                const updateIndex = priorPolicyUpdatesVM.value.findIndex((update => update.integrationId === priorPolicyVM.integrationId.value));

                priorPolicyUpdatesVM.value.splice(updateIndex, 1);
                handleValueChange(undefined, false);
                // Set UI state for check box group back to blank
                updateReasonForCoverageLapse([]);
            }
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition,
            autoComplete: false
        },
        priorPolicyCarrierName: {
            required: get(priorPolicyVM, 'policySource.value.code') !== 'Prefill' && !get(priorPolicyVM, 'carrierName.value'),
            readOnly: get(priorPolicyVM, 'policySource.value.code') === 'Prefill'
                || viewOnlyMode,
            className: get(priorPolicyVM, 'policySource.value.code') === 'Prefill' ? styles.carrierName : ''
        },
        priorPolicyPreviousPolicy: {
            required: get(priorPolicyVM, 'policySource.value.code') !== 'Prefill' && !get(priorPolicyVM, 'policyNumber.value'),
            readOnly: get(priorPolicyVM, 'policySource.value.code') === 'Prefill'
                || viewOnlyMode
        },
        // removing dates per lexis nexis for r1
        // priorPolicyInceptionDate: {
        //     readOnly: get(priorPolicyVM, 'policySource.value.code') === 'Prefill'
        //         || viewOnlyMode,
        //     updateDateDto: handleValueChange,
        //     dateDTO: priorPolicyVM.policyInceptionDate,
        //     defaultToToday: true,
        //     showErrors: true,
        //     onValidate: onValidate
        // },
        // priorPolicyCoverageDate: {
        //     readOnly: get(priorPolicyVM, 'policySource.value.code') === 'Prefill'
        //         || viewOnlyMode,
        //     updateDateDto: ((value, changedPath) => {
        //         handleValueChange(value, changedPath);
        //         checkCoverageLapse(value);
        //     }),
        //     dateDTO: priorPolicyVM.policyHolderExpirationDate,
        //     defaultToToday: true,
        //     showErrors: true,
        //     onValidate: onValidate
        // },
        priorPolicyBIPerPersonLimit: {
            path: `coverages.children.${BICovIndex}.perPersonLimit`,
            readOnly: get(priorPolicyVM, 'policySource.value.code') === 'Prefill'
                || viewOnlyMode
        },
        priorPolicyBIPerOccurrenceLimit: {
            path: `coverages.children.${BICovIndex}.perOccurrenceLimit`,
            readOnly: get(priorPolicyVM, 'policySource.value.code') === 'Prefill'
                || viewOnlyMode
        },
        priorPolicyBICombinedSingleLimit: {
            path: `coverages.children.${BICovIndex}.combinedSingleLimit`,
            readOnly: get(priorPolicyVM, 'policySource.value.code') === 'Prefill'
                || viewOnlyMode
        },
        comprehensiveCoverageIndicator: {
            value: some(priorPolicyVM.coverages.value, (cov) => cov.coverageCode === 'Comprehensive'),
            onValueChange: handleComprehensiveCoverageToggle,
            readOnly: get(priorPolicyVM, 'policySource.value.code') === 'Prefill'
                || viewOnlyMode
        },
        collisionCoverageIndicator: {
            value: some(priorPolicyVM.coverages.value, (cov) => cov.coverageCode === 'Collision'),
            onValueChange: handleCollisionCoverageToggle,
            readOnly: get(priorPolicyVM, 'policySource.value.code') === 'Prefill'
                || viewOnlyMode
        },
        priorPolicyCoverageLapseToggle: {
            value: isCoverageLapse,
            readOnly: true
        },
        reasonForLapseCheckbox: {
            availableValues: valuesForLapse,
            visible: (!get(priorPolicyVM, 'continuousCoverageInd.value')
                || hasUWConsiderationPermission)
                && get(priorPolicyVM, 'policySource.value.code') === 'Prefill'
                && isLatestPolicy,
            readOnly: viewOnlyMode,
            value: reasonForCoverageLapse,
            onValueChange: (value) => {
                if (setPriorCarrierChanged) { setPriorCarrierChanged(true); }

                setLapseReason(value);
            }
        }
    };

    const readValue = useCallback((fieldId, fieldPath) => readViewModelValue(
        metadata.pageContent, priorPolicyVM, fieldId, fieldPath, overrideProps
    ), [priorPolicyVM, overrideProps]);

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={priorPolicyVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

PriorPolicyComponent.propTypes = {
    data: PropTypes.shape({}),
    policyStartDate: PropTypes.shape({
        value: PropTypes.shape({
            isodate_Ext: PropTypes.string,
        })
    }),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    checkLapsed: PropTypes.func,
    authUserData: PropTypes.shape({}),
    priorPolicyUpdatesVM: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.shape({
            length: PropTypes.string
        }))
    }),
    setPriorCarrierChanged: PropTypes.func,
    isLatestPolicy: PropTypes.bool,
    policyState: PropTypes.string.isRequired,
    handleDispute: PropTypes.func
};
PriorPolicyComponent.defaultProps = {
    data: {},
    policyStartDate: {},
    labelPosition: 'top',
    path: undefined,
    showOptional: true,
    id: undefined,
    viewOnlyMode: false,
    checkLapsed: undefined,
    priorPolicyUpdatesVM: {},
    setPriorCarrierChanged: undefined,
    isLatestPolicy: false,
    authUserData: undefined,
    handleDispute: undefined
};
export default withAuthenticationContext(PriorPolicyComponent);
