import { defineMessages } from 'react-intl';

export default defineMessages({
    homeownersEh: {
        id: 'E1PProductImageComponent.Homeowners',
        defaultMessage: 'Homeowners'
    },
    personalAutoEa: {
        id: 'E1PProductImageComponent.Personal Auto',
        defaultMessage: 'Personal Auto'
    },
    personalUmbrellaEu: {
        id: 'E1PProductImageComponent.Personal Umbrella',
        defaultMessage: 'Personal Umbrella'
    }
});
