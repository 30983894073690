import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, filter, isNil
} from 'lodash';
import config from 'app-config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { CoverageUtil } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from '../CoveragesPage.metadata.json5';

const LOB = 'personalUmbrella_EU';

function ViewCoveragePage(props) {
    const {
        wizardData: renewalVM,
        authUserData,
        authHeader
    } = props;
    const paperLessIndValue = get(renewalVM, 'lobData.personalUmbrella_EU.paperlessInd.value') === undefined
        ? false : get(renewalVM, 'lobData.personalUmbrella_EU.paperlessInd.value');
    const [isQuoteStale, setIsQuoteStale] = useState(false);
    const translator = useTranslator();
    const { opCo } = useContext(AmfamOktaTokenContext);
    const modifiers = get(renewalVM, 'lobData.personalUmbrella_EU.modifiers.value', []);
    const showPremium =CoverageUtil.isTotalPremiumVisible(renewalVM,authUserData) && !isQuoteStale;


    useEffect(() => {
        // set isQuoteStale, when status is draft
        if (get(renewalVM, 'value.baseData.periodStatus') === 'Draft') {
            setIsQuoteStale(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTotalPremium = useCallback(() => {
        const offerings = get(renewalVM, `lobData[${LOB}].offerings.value`, []);
        let selectedOffering = offerings[0];

        if (offerings.length > 1) {
            const offeredQuotes = get(renewalVM, 'quoteData.offeredQuotes.value', []);
            const selectedQuoteBranchCode = offeredQuotes?.find((quote) => quote.selected)?.branchCode;

            if (selectedQuoteBranchCode) {
                selectedOffering = offerings.find((offering) => offering.branchCode === selectedQuoteBranchCode)
            }
        }

        const payPlans = get(selectedOffering, 'paymentPlans');
        const selectedPlan = filter(payPlans, (plan) => plan.isSelected);
        const totalPremium = selectedPlan[0]?.total?.amount;

        if (!isNil(totalPremium)) {return { currency: 'usd', amount: totalPremium };}

        return undefined;

    }, [renewalVM]);

    const onNext = useCallback(
        async () => renewalVM,
        [renewalVM]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            disabled: true
        },
        premiumMightChangeMessageDiv: {
            visible: renewalVM.baseData.periodStatus.value.code !== 'Bound'
        },
        coveragesPageLoadingIndicator: {
            loaded: true,
            text: translator(e1pCommonMessages.loadingNextPage)
        },
        coveragesPageContainer: {
            visible: true
        },
        quoteProposalLinkContainer: {
            visible: false
        },
        paymentOptionsID: {
            submissionVM: renewalVM,
            authHeader,
            updateWizardData: () => {},
            LOB: 'personalUmbrella_EU',
            viewOnly: true,
            isQuoteStale
        },
        totalPremiumID: {
            visible: showPremium,
            value: (() => getTotalPremium())()
        },
        emptyPremium: {
            visible: !showPremium
        },
        paperlessInd: {
            value: get(renewalVM, 'lobData.personalUmbrella_EU.paperlessInd.value'),
            onValueChange: () => {},
            labelPosition: 'top',
            readOnly: true,
            visible: get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled'])
        },
        paperlessEmailId: {
            visible: paperLessIndValue && get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled']),
            labelPosition: 'top',
            readOnly: true
        },
        AutoPayDiscountToggle: {
            labelPosition: 'top',
            onValueChange: () => {},
            value: get(renewalVM, 'lobData.personalUmbrella_EU.autoPayDiscInd.value'),
            readOnly: true
        },
        buyNowButton: {
            visible: false
        },
        recalculateButton: {
            visible: false
        },
        wizardPageHeader: {
            wizardSubmission: renewalVM
        },
        coverage: {
            loadingClause: false,
            isEditable: false,
            showAmount: !isQuoteStale,
            labelPosition: 'top'
        },
        euSurchargeListComponentId: {
            // modifiers other than discount will be shown in surchage and fee section
            value: modifiers.filter(
                (item) => item.applied && item.modifierType !== "discount"
            ),
            transactionVM: renewalVM,
            updateWizardData: () => {},
            viewOnlyMode: true
        },
        euDiscountsListComponentId: {
            visible: !!filter(modifiers, { applied: true, modifierType: 'discount' }).length,
            value: modifiers
        },
        personalLiabilityLimitUwIssueInfoMessageDiv: {
            visible: false
        },
        paperlessEmailChangedMessageDiv: {
            visible: false
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            onChangeClause: () => { },
            onSyncCoverages: () => { },
            onChangeSubmissionAndSync: () => { },
            onValidate: () => {}
        },
    };

    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, renewalVM, id, path, overrideProps), [overrideProps, renewalVM]);


    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={() => {}}
                onValidationChange={() => {}}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewCoveragePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};
export default withRouter(withAuthenticationContext(ViewCoveragePage));
