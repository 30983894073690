import { defineMessages } from 'react-intl';

// It contains list of common validation messages. New common validations should be added here.
export default defineMessages({
    cannotCompare: {
        id: 'e1p.platform.common.info.Select two different transactions for comparison.',
        defaultMessage: 'Select two different transactions for comparison.'
    },
    uniquePolicyNumberRequired: {
        id: 'e1p.platform.common.info.A unique policy number is required for each supporting policy.',
        defaultMessage: 'A unique policy number is required for each supporting policy.'
    },
    effectiveDateCannotChanged: {
        id: 'e1p.platform.common.info.The policy effective date cannot be changed. If policy effective date is incorrect, withdraw the transaction and start a new one with the correct effective date.',
        defaultMessage: 'The policy effective date cannot be changed. If policy effective date is incorrect, withdraw the transaction and start a new one with the correct effective date.'
    },
    ICSNotificationRewrite: {
        id: 'e1p.platform.common.error.To start a Rewrite transaction with more than 30 days lapse in coverage, please contact customer service.',
        defaultMessage: 'To start a Rewrite transaction with more than 30 days lapse in coverage, please contact customer service.'
    },
    RVPFailedToLoad: {
        id: 'e1p.platform.common.error.RVP Service failed to load.',
        defaultMessage: 'RVP Service failed to load.'
    },
    invoiceHasDownPaymentInfoMsg: {
        id: 'e1p.platform.common.info.First Invoice Amount may include down payment.',
        defaultMessage: 'First Invoice Amount may include down payment.'
    },
    premiumSubjectToChangeMessage: {
        id: 'e1p.platform.common.info.Renewal premium is subject to change upon issuance.',
        defaultMessage: 'Renewal premium is subject to change upon issuance.'
    },
    anotherPolicyChangeOpen: {
        id: 'e1p.platform.common.info.There is another open policy change that will not be applied to any new change requests.',
        defaultMessage: 'There is another open policy change that will not be applied to any new change requests.'
    },
    effectiveDateIsMoreThanOneYear: {
        id: 'e1p.platform.common.error.Effective date of Rewrite is more than 1 year from the date of cancellation. Please withdraw this transaction and create a new Submission.',
        defaultMessage: 'Effective date of Rewrite is more than 1 year from the date of cancellation. Please withdraw this transaction and create a new Submission.'
    },
    paperlessEmailInfoMessage: {
        id: 'e1p.platform.common.info.Paperless email has been changed, update the Primary Named Insured\'s email if needed.',
        defaultMessage: 'Paperless email has been changed, update the Primary Named Insured\'s email if needed.'
    },
    quoteProposalErrorMessage: {
        id: 'e1p.platform.common.error.An error occurred while generating the Quote Proposal.',
        defaultMessage: 'An error occurred while generating the Quote Proposal.'
    },
    quoteProposalError: {
        id: 'e1p.platform.common.error.Recalculate premium to view the quote proposal.',
        defaultMessage: 'Recalculate premium to view the quote proposal.'
    },
    maxVersionsError: {
        id: 'e1p.platform.common.error.The maximum number of quotes have been added. To add another quote, delete an existing quote.',
        defaultMessage: 'The maximum number of quotes have been added. To add another quote, delete an existing quote.'
    },
    deleteOnlyVersionError: {
        id: 'e1p.platform.common.error.More than one quote needs to exist to delete a quote.',
        defaultMessage: 'More than one quote needs to exist to delete a quote.'
    } 
});
