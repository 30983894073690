import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { IntlContext } from '@jutro/locale';
import {
    isUndefined as _isUndefined
} from 'lodash';
import classNames from 'classnames';
import { Grid } from '@jutro/layout';

import { IconButton, TypeaheadMultiSelectField } from '@jutro/legacy/components';

function LimitsDropdownComponent(props) {
    const {
        id,
        path,
        limit,
        onLimitChange,
        label,
        multiSelectClasses,
        gridClasses,
        maxLimit,
        minLimit,
        limitIncrement,
        readOnly
    } = props;
    const intl = useContext(IntlContext);
    const getAvailableLimits = useCallback(
        () => {
            const values = [];
            // If choice is not in the range and multiple of 1000 it can still be added as new or come in from prefill
            // When that happens it is added but not in currency format so added this logic
            let choiceExists = false;

            for (let i = minLimit; i <= maxLimit; i += limitIncrement) {
                if (!choiceExists && limit === i) {
                    choiceExists = true;
                }

                const formattedAmount = intl.formatNumber(i, {
                    style: 'currency',
                    currency: 'USD',
                    currencyDisplay: 'symbol',
                });

                values.push({ code: String(i), name: formattedAmount });
            }

            if (!choiceExists  && !_isUndefined(limit)) {
                values.push({
                    code: String(limit),
                    name: intl.formatNumber(limit, {
                        style: 'currency',
                        currency: 'USD',
                        currencyDisplay: 'symbol',
                    }),
                });
            }

            return values;
        },
        [intl, limit, limitIncrement, maxLimit, minLimit]
    );

    return (
        <Grid
            columns={['3fr', '1fr']}
            className={classNames(gridClasses, 'align-items-center')}
            gap="none"
            id={`${id}Grid`}
            tag="div"
        >
            <TypeaheadMultiSelectField
                availableValues={getAvailableLimits()}
                id={`${id}typeahead`}
                labelPosition="left"
                label={label}
                className={classNames(multiSelectClasses)}
                path={path}
                value={limit ? [String(limit)] : [limit]}
                onValueChange={(value, changedPath) => {onLimitChange(Number(value), changedPath)}}
                readOnly={readOnly}
                allowNew
                singleSelect
                autoTrim
            />
            {limit && !readOnly && (
                <IconButton
                    icon="mi-backspace"
                    id={`IconEditButton${id}`}
                    size="medium"
                    onClick={() => onLimitChange(undefined, path)}
                />
            )}
        </Grid>
    );
}

LimitsDropdownComponent.propTypes = {
    multiSelectClasses: PropTypes.arrayOf(PropTypes.shape({})),
    gridClasses: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({})
    ])),
    limit: PropTypes.number,
    maxLimit: PropTypes.number,
    minLimit: PropTypes.number,
    limitIncrement: PropTypes.number,
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string
        })
    ]),
    onLimitChange: PropTypes.func.isRequired
};
LimitsDropdownComponent.defaultProps = {
    multiSelectClasses: [],
    gridClasses: [],
    limit: undefined,
    maxLimit: 1000000,
    minLimit: 10000,
    limitIncrement: 10000,
    readOnly: false,
    label: undefined
};
export default LimitsDropdownComponent;
