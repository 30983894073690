import React, {
    useCallback
} from 'react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { EHHO4PropertyDetailsComponent } from 'e1p-capability-policyjob-react';

function PropertyPage(props) {
    const { wizardData: policyViewVM } = props;
    const onNext = useCallback(
        () => policyViewVM,
        [policyViewVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <EHHO4PropertyDetailsComponent
                onValidate={() => {}}
                transactionVM={policyViewVM}
                updateWizardData={() => {}}
                isPageSubmitted={false}
                setShouldSkipAdditionalInfo={() => {}}
                isSavingCurrentPageChanges={false}
                isSkipping={false}
                isSavingEndorsement={false}
                viewOnly
            /> 
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
