import React, { useCallback } from 'react';
import _ from 'lodash';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { DropdownMenuButton, useModal } from '@jutro/components';
import { VehicleUtil } from 'e1p-portals-util-js';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import E1PCopyVehicleCoveragesModal from './E1PCopyVehicleCoveragesModal/E1PCopyVehicleCoveragesModal';


import { Button } from '@jutro/legacy/components';


import { DropdownMenuLink } from '@jutro/router';


/**
 * This is custom component to copy vehicle coverages
 * 
 * @param {Object} props
 * @returns {Component}
 */
const E1PCopyVehicleCoveragesComponent = (props) => {
    const modalApi = useModal();
    const {
        viewOnlyMode,
        vehicleName,
        transactionVM,
        offeringPath,
        updateWizardData,
        forceUpdateCoverages,
        handleCompOnlyChangeInCopyCoverages
    } = props;

    const translator = useTranslator();


    /**
     * Copying vehicle coverages from source vehicle to destination vehicle/s
     */
    const copyVehicleCoverages = useCallback((modalResponse) => {
        let path = '';
        const vehicleCoverageIndexToBeCopiedFrom = _.get(
            transactionVM,
            `${offeringPath}.coverages.vehicleCoverages.value`,
            []
        ).findIndex((vehicleCov) => vehicleCov.integrationID === modalResponse.vehicleSelectedForCopyingCovsFrom);

        const vehicleCoverageToCopyFrom = _.get(transactionVM,
            `${offeringPath}.coverages.vehicleCoverages.children[${vehicleCoverageIndexToBeCopiedFrom}].coverages.value`
        );

        if (vehicleCoverageToCopyFrom) {
            for (let i = 0; i < modalResponse.vehiclesSelectedForCopyingCovsTo.length; i++) {
                const vehicle = modalResponse.vehiclesSelectedForCopyingCovsTo[i];
                const vehicleCoverageIndexToBeCopiedTo = _.get(
                    transactionVM,
                    `${offeringPath}.coverages.vehicleCoverages.value`,
                    []
                ).findIndex((vehicleCov) => vehicleCov.integrationID === vehicle.integrationId);

                if (vehicleCoverageIndexToBeCopiedTo > -1) {

                    _.set(transactionVM,
                        `${offeringPath}.coverages.vehicleCoverages.children[${vehicleCoverageIndexToBeCopiedTo}].coverages.value`,
                        []);
                    // Copying vehicle coverages from source vehicle to destination vehicle
                    _.set(transactionVM,
                        `${offeringPath}.coverages.vehicleCoverages.children[${vehicleCoverageIndexToBeCopiedTo}].coverages.value`,
                        vehicleCoverageToCopyFrom);

                    path = `${offeringPath}.coverages.vehicleCoverages.children[${vehicleCoverageIndexToBeCopiedTo}].coverages.value`;
                }
            }

        }

        updateWizardData(transactionVM);

        return Promise.resolve(forceUpdateCoverages(offeringPath, path));
    }, [forceUpdateCoverages, offeringPath, transactionVM, updateWizardData]);

    /**
     * Opens Copy Coverages modal whenever user clicks on ... besides vehicle name
     */
    const showCopyCoveragesModal = useCallback(
        () => {
            const vehicles = _.get(transactionVM, 'value.lobData.personalAuto_EA.coverables.vehicles', []);
            const vehiclesForCopyCov = vehicles.map((vehicle) => ({
                    integrationId: vehicle.integrationId,
                    make: vehicle.make,
                    model: vehicle.model,
                    year: vehicle.year,
                    publicID: vehicle.publicID
                }));
            const componentProps = {
                iconClassType: false,
                showCloseBtn: true,
                showCancelBtn: true,
                vehiclesForCopyCov
            };

            return modalApi.showModal(
                <E1PCopyVehicleCoveragesModal {...componentProps} />
            );
        },
        [modalApi, transactionVM]
    );

    /**
     * Helper function for setting data when source vehicle(vehicle chosen for copying coverages from) has comp only 
     */
    const settingDataForCompOnlyCovs = useCallback((modalResponse, compOnlyIndOnSourceVehicle) => {

        const vehicleCoverageIndexToBeCopiedFrom = _.get(
            transactionVM,
            `${offeringPath}.coverages.vehicleCoverages.value`,
            []
        ).findIndex((vehicleCov) => vehicleCov.integrationID === modalResponse.vehicleSelectedForCopyingCovsFrom);

        const vehicleCoverageToCopyFrom = _.get(transactionVM,
            `${offeringPath}.coverages.vehicleCoverages.children[${vehicleCoverageIndexToBeCopiedFrom}].coverages.value`
        );

        // Creating immutable object to avoid updated flag changes at source vehicle
        // eslint-disable-next-line prefer-object-spread
        const comprehensiveCoverage = Object.assign(
            {},
            vehicleCoverageToCopyFrom.find((coverage) => coverage.codeIdentifier === 'EA_Comprehensive')
        );

        for (let i = 0; i < modalResponse.vehiclesSelectedForCopyingCovsTo.length; i++) {
            const vehiclePublicID = modalResponse.vehiclesSelectedForCopyingCovsTo[i].publicID;
            const vehicleIndex = VehicleUtil.getVehicleIndexBasedonPublicID(transactionVM, vehiclePublicID);

            _.set(transactionVM, `value.lobData.personalAuto_EA.coverables.vehicles.${vehicleIndex}.compOnlyInd`, compOnlyIndOnSourceVehicle);
            updateWizardData(transactionVM);

            if (compOnlyIndOnSourceVehicle) {
                // set all the vehicle coverage with selected as false
                const vehicleCoverages = VehicleUtil.getVehicleCoveragesBasedonPublicID(transactionVM, vehiclePublicID);
                const updatedCoverages = vehicleCoverages.map((cov) => {
                    if (cov.codeIdentifier !== 'EA_Comprehensive') {
                        _.set(cov, 'selected', false);
                    } else {
                        if (comprehensiveCoverage) {
                            /**
                             * Setting comprehensive coverage to destination vehicle from source vehicle
                             * to copy correct limit values
                             */
                            _.set(comprehensiveCoverage, 'selected', true);
                            _.set(comprehensiveCoverage, 'updated', true);
                            comprehensiveCoverage.terms = comprehensiveCoverage.terms?.map((term) => ({ ...term, updated: true }))

                            return comprehensiveCoverage;
                        }

                        _.set(cov, 'selected', true);
                    }

                    return cov;
                });
                const vehicleCoverageIndex = VehicleUtil.getVehicleCoverageIndexBasedonPublicID(transactionVM, vehiclePublicID);

                _.set(transactionVM, `${offeringPath}.value.coverages.vehicleCoverages.${vehicleCoverageIndex}.coverages`, updatedCoverages);
                updateWizardData(transactionVM);
            }

        }


        // check if every vehicle is componly then we need to update all line coverages to selected as false
        if (VehicleUtil.isEveryVehicleCompOnly(transactionVM)) {
            const lineCoverages = VehicleUtil.getLineLevelCoverages(transactionVM);
            const updatedLineCoverages = lineCoverages.map((cov) => {
                _.set(cov, 'selected', false);

                return cov;
            });

            _.set(transactionVM, 'value.lobData.personalAuto_EA.offerings.0.coverages.lineCoverages', updatedLineCoverages);
            updateWizardData(transactionVM);
        }
    }, [offeringPath, transactionVM, updateWizardData]);

    /**
     * below function gets called when user clicks on save on Copy Coverages popup
     */
    const onClickOfCopyCoveragesButton = useCallback(() => {
        showCopyCoveragesModal()
            .then((modalResponse) => {
                const sourceVehicleChosenToCopyCovsFrom = _.get(
                    transactionVM,
                    'value.lobData.personalAuto_EA.coverables.vehicles',
                    []
                ).find((vehicle) => vehicle.integrationId === modalResponse.vehicleSelectedForCopyingCovsFrom);

                /**
                 * If transaction is other than submission and  compOnly indicator is true
                 * for source vehicle(vehicle chosen for copying coverages from)
                 * We will change comp only only indicator on destination vehicle/s
                 * (Will change all vehicle coverages selection to false except Comprehensive coverage  )
                 * and call save and quote
                 */
                if (_.get(transactionVM, 'value.baseData.jobType') !== 'Submission') {
                    settingDataForCompOnlyCovs(modalResponse, sourceVehicleChosenToCopyCovsFrom.compOnlyInd);

                    // Passing public id of vehicle for loader
                    if (sourceVehicleChosenToCopyCovsFrom.compOnlyInd) {
                        return Promise.resolve(handleCompOnlyChangeInCopyCoverages(modalResponse.vehiclesSelectedForCopyingCovsTo[0]?.publicID));
                    }
                }

                /**
                 * If transaction is submission or compOnly indicator is false
                 * for source vehicle(vehicle chosen for copying coverages from)
                 * We will copy vehicle coverages from source vehicle to destination vehicle/s
                 * and will make api call for updateDraftXXXWithOptions
                 */
                return Promise.resolve(copyVehicleCoverages(modalResponse));

            })
            .catch(() => {

            })
    }, [settingDataForCompOnlyCovs, copyVehicleCoverages, handleCompOnlyChangeInCopyCoverages, showCopyCoveragesModal, transactionVM]);

    return (
        <div className="vehicleNameWithCopyCoverageButton">
            <p className="vehicleNameCopyCoverageComponent">
                {vehicleName}
            </p>
            <DropdownMenuButton
                id="copyVehCov-dropdown-menu-button"
                buttonText="..."
                type="text"
                className={
                    (
                        viewOnlyMode
                        || _.get(transactionVM, 'value.lobData.personalAuto_EA.coverables.vehicles', []).length < 2
                    )
                        ? "hideCopyCoverageDropdown"
                        : "copyCoverageDropdownMenuButton"
                }

            >
                <DropdownMenuLink
                    onClick={(e) => {
                        e.preventDefault();
                        onClickOfCopyCoveragesButton()
                    }}
                >
                    <Button
                        icon="mi-content-copy"
                        type="text"
                        actionType="button"
                    >
                        {translator(e1pCommonMessages.copyCoverages)}
                    </Button>
                </DropdownMenuLink>

            </DropdownMenuButton>
        </div >

    );
}

E1PCopyVehicleCoveragesComponent.propTypes = {
    viewOnlyMode: PropTypes.bool,
    transactionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    offeringPath: PropTypes.string.isRequired,
    forceUpdateCoverages: PropTypes.func.isRequired,
    handleCompOnlyChangeInCopyCoverages: PropTypes.func
};

E1PCopyVehicleCoveragesComponent.defaultProps = {
    viewOnlyMode: false,
    handleCompOnlyChangeInCopyCoverages: undefined
}

export default E1PCopyVehicleCoveragesComponent;