import { useEffect, useContext } from 'react';
import _ from 'lodash';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

/**
 * @param {String} sourceOfEntry Optional
 * @param {String} experienceID Optional
 */
function useDataLayer(sourceOfEntry, experienceID, roles) {
    const { opCo } = useContext(AmfamOktaTokenContext);
    const { authUserData } = useAuthentication();

    useEffect(() => {
        if (sourceOfEntry === 'VALUE_NOT_SET_YET'
            || experienceID === 'VALUE_NOT_SET_YET'
            || roles === 'VALUE_NOT_SET_YET') {
            return;
        }

        const dataLayerValues = {
            event: 'dlvs-loaded',
            dlvs: {
                sourceofentry: sourceOfEntry || 'none',
                experience: experienceID || 'none',
                OPCO: opCo || 'none',
                roles: authUserData.roles_Ext || 'none'
            }
        };

        // wait for both values to trigger the update
        if (!sourceOfEntry || !experienceID || !roles) {
            dataLayerValues.dlvs.sourceofentry = 'none';
            dataLayerValues.dlvs.experience = 'none';
            dataLayerValues.dlvs.roles = 'none';
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataLayerValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experienceID, opCo, sourceOfEntry])

    /**
     * add new quote tag if user had made significant changes to the quote
     * @param {Object} submissionVM 
     */
    const addNewQuoteTagIfApplicable = (submissionVM) => {

        const anyMeaningfulChangeMade = {
            Homeowners_EH: _.get(submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.numberOfUnits.value',
                undefined) !== undefined,// checking number of units as ho4 and ho6 won't have RC
            PersonalAuto_EA: !_.isEmpty(_.get(submissionVM, 'value.lobData.personalAuto_EA.coverables.drivers', [])),
            PersonalUmbrella_EU: _.get(submissionVM, 'lobData.personalUmbrella_EU.resRelOwnLiveStockInd.value', undefined) !== undefined // checking one of the pre-qualification as all other fields can be systematically pre-populated 
        }

        const productCode = _.get(submissionVM, 'value.baseData.productCode');

        // eslint-disable-next-line security/detect-object-injection
        if (anyMeaningfulChangeMade[productCode]) {
            window.dataLayer = window.dataLayer || [];

            const dataLayerValues = {
                event: 'dlvs-loaded',
                dlvs: 'isNewQuote'
            };

            window.dataLayer.push(dataLayerValues);
        }

    }

    return {
        addNewQuoteTagIfApplicable
    };
}

export default useDataLayer;
