import React, {
    useCallback,
    useEffect,
    useState
} from 'react';
import { get } from 'lodash';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { E1PEHHO3PropertyDetailsComponent } from 'e1p-capability-policyjob-react';
import { useValidation } from '@xengage/gw-portals-validation-react';

function PropertyPage(props) {
    const {
        wizardData: renewalVM
    } = props;
    const [coolingSystemType, updateCoolingSystemType] = useState(undefined);
    const [heatingSystemType, updateHeatingSystemType] = useState(undefined);
    const {
        initialValidation
    } = useValidation('PropertyPage');

    const onNext = useCallback(() => renewalVM, [renewalVM]);

    useEffect(() => {
        const heatingSystems = get(
            renewalVM,
            'lobData.homeowners_EH.coverables.construction.heatingSystems.value'
        );

        if (heatingSystems !== undefined && heatingSystems.length > 0) {
            updateHeatingSystemType(heatingSystems[0].heatingType);
        }

        const coolingSystems = get(
            renewalVM, 'lobData.homeowners_EH.coverables.construction.coolingSystems.value'
        );

        if (coolingSystems !== undefined && coolingSystems.length > 0) {
            updateCoolingSystemType(coolingSystems[0].coolingType);
        }
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateOverrides = useCallback(() => {
        const overrideProps = {
            roofAgeMessage: {
                visible: false
            },
            partialPrefillNotification: {
                visible: false
            },
            propertyPrefillUnsuccessfulInfoMessage: {
                visible: false
            },
            covAWillBeUpdatedBasedOnReplacementCostInfoMessage: {
                visible: false
            }
        }

        return overrideProps;
    }, []);

    return (
        <WizardPage
            skipWhen={initialValidation}
            onNext={onNext}
            shouldLink
        >
            <E1PEHHO3PropertyDetailsComponent
                transactionVM={renewalVM}
                viewOnlyMode
                heatingSystemType={heatingSystemType}
                updateHeatingSystemType={updateHeatingSystemType}
                coolingSystemType={coolingSystemType}
                updateCoolingSystemType={updateCoolingSystemType}
                parentOverrideProps={generateOverrides}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
