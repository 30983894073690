/* eslint-disable padding-line-between-statements */
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { EndorsementService } from 'e1p-capability-policychange';
import {commonMessages, policyChangeMessages} from 'e1p-platform-translations';

/** Maps jackson rpc fields to custom fields for the subject view model
 * @param {*} policyChangeVM Subject view model
 */
function updatePolicyChangeVMCustomFields(policyChangeVM) {
    policyChangeVM.policyType_Ext.value ??= policyChangeVM.value.policyType;
    policyChangeVM.policyChangeReason_Ext.value
    ??= policyChangeVM.value.policyChangeReason;
    policyChangeVM.policyChangeSource_Ext.value
    ??= policyChangeVM.value.policyChangeSource;
    policyChangeVM.hasLossOnOrAfterEffDate_Ext.value
    ??= policyChangeVM.value.hasLossOnOrAfterEffDate;
    policyChangeVM.conflicts_Ext.value ??= policyChangeVM.value.conflicts;
    policyChangeVM.flowStepIDs_Ext.value ??= policyChangeVM.value.flowStepIDs;
    policyChangeVM.entryCompletionStepIDs_Ext.value
    ??= policyChangeVM.value.flowStepIDs;
    policyChangeVM.errorsAndWarnings_Ext.value
    ??= policyChangeVM.value.errorsAndWarnings;
}

/** Similar to updatePolicyChangeVMCustomFields, but maps fields for policy change dto
 * @param {*} policyChangeDataDTO DTO to map custom fields for.
 */
function updatePolicyChangeDTOCustomFields(policyChangeDataDTO) {
    policyChangeDataDTO.policyType_Ext ??= policyChangeDataDTO.policyType;
    policyChangeDataDTO.policyChangeReason_Ext
    ??= policyChangeDataDTO.policyChangeReason;
    policyChangeDataDTO.policyChangeSource_Ext
    ??= policyChangeDataDTO.policyChangeSource;
    policyChangeDataDTO.hasLossOnOrAfterEffDate_Ext
    ??= policyChangeDataDTO.hasLossOnOrAfterEffDate;
    policyChangeDataDTO.conflicts_Ext ??= policyChangeDataDTO.conflicts;
    policyChangeDataDTO.flowStepIDs_Ext ??= policyChangeDataDTO.flowStepIDs;
    policyChangeDataDTO.entryCompletionStepIDs_Ext
    ??= policyChangeDataDTO.flowStepIDs;
    policyChangeDataDTO.errorsAndWarnings_Ext
    ??= policyChangeDataDTO.errorsAndWarnings;
}

/**
 * Shows confirmation dialog and if user click on withdraw, Withdraws endorsement 
 * @param {String} jobNumber 
 * @param {String} authHeader 
 * @param {Object} history 
 * @returns {Object}
 */
const withdrawEndorsement = (jobNumber, authHeader, history) => {
    const { modalApi } = _.get(window, '__giamfam.modalApi');

    return Promise.resolve(modalApi.showConfirm({
        title: policyChangeMessages.withdrawPolicyChange,
        message: policyChangeMessages.areYouSureToWithdrawPolicyChange,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.withdraw
    }).then((result) => {
        if (result === 'cancel') {
            return;
        }
        return EndorsementService.withdrawEndorsement(
            [jobNumber],
            authHeader
        ).then(() => {
            if (jobNumber) {
                const path = `/change/${jobNumber}/summary`;
                history.push(path);
            }
        })

    }, _.noop));
}

export default {
    updatePolicyChangeVMCustomFields,
    updatePolicyChangeDTOCustomFields,
    withdrawEndorsement
};
