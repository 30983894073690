import { defineMessages } from 'react-intl';

export default defineMessages({
    ratingYourPolicyMessage: {
        id: 'gateway-cancel.directives.cancel-policy.Please wait while we rate your policy...',
        defaultMessage: 'Please wait while we rate your policy...'
    },
    reason: {
        id: 'gateway-cancel.directives.cancel-policy.Reason',
        defaultMessage: 'Reason'
    },
    primaryReason: {
        id: 'gateway-cancel.directives.cancel-policy.Primary Reason',
        defaultMessage: 'Primary Reason Description'
    },
    otherInsuranceCarrier: {
        id: 'gateway-cancel.directives.cancel-policy.Other Insurance Carrier',
        defaultMessage: 'Other Insurance Carrier'
    },
    additionalReasonDesc: {
        id: 'gateway-cancel.directives.cancel-policy.Additional Reason Desc',
        defaultMessage: 'Additional Reason Description(s)'
    },
    otherInsurance: {
        id: 'gateway-cancel.directives.cancel-policy.Other Carrier',
        defaultMessage: 'Other Carrier'
    },
    cancellationCreatedDate: {
        id: 'gateway-cancel.views.cancellation-detail.Cancellation Created Date',
        defaultMessage: 'Cancellation Created Date'
    },
    continueCancellation: {
        id: 'gateway-cancel.views.cancellation-detail.Continue Cancellation',
        defaultMessage: 'Continue Cancellation'
    },
    policy: {
        id: 'gateway-cancel.views.cancellation-detail.Policy',
        defaultMessage: 'Policy'
    },
    account: {
        id: 'gateway-cancel.views.cancellation-detail.Account',
        defaultMessage: 'Account'
    },
    startCancellation: {
        id: 'gateway-cancel.directives.cancel-policy.Start Cancellation',
        defaultMessage: 'Start Cancellation'
    },
    donotCancel: {
        id: 'gateway-cancel.directives.cancel-policy.Do Not Cancel',
        defaultMessage: 'Do Not Cancel'
    },
    descriptionPolicy: {
        id: 'gateway-cancel.directives.cancel-policy.Description',
        defaultMessage: 'Description'
    },
    selectAfterDate: {
        id: 'gateway-cancel.directives.cancel-policy.Please set a date that is after the start date of the policy',
        defaultMessage: 'Please set a date that is after the start date of the policy'
    },
    selectBeforeDate: {
        id: 'gateway-cancel.directives.cancel-policy.Please set a date that is before the expiry date of the policy',
        defaultMessage: 'Please set a date that is before the expiry date of the policy'
    },
    reasonPlcaeHolder: {
        id: 'gateway-cancel.directives.cancel-policy.-- Please Choose --',
        defaultMessage: '-- Please Choose --'
    },
    forcefieldCheckID: {
        id: 'gateway-cancel.directives.cancel-policy.Field Force Referral',
        defaultMessage: 'Field Force Referral'
    },
    gettingEffectiveDate: {
        id: 'gateway-cancel.directives.cancel-policy.Loading Effective Date...',
        defaultMessage: 'Loading Effective Date...'
    },
    underwriterName: {
        id: 'gateway.views.new-quote.Underwriter Name',
        defaultMessage: 'Underwriter Name'
    }
});
