import { defineMessages } from 'react-intl';

export default defineMessages({
    remoteClick2Sign: {
        id: 'RemoteClick2SignModa.Remote Click2Sign',
        defaultMessage: 'Remote Click2Sign'
    },
    verifyDefault: {
        id: 'RemoteClick2SignModa.signature.Verify insured’s information below to start with signature process.',
        defaultMessage: 'Verify insured’s information below to start with signature process.'
    },
    remoteClick2SignError: {
        id: 'RemoteClick2SignModa.Error While Signing',
        defaultMessage: 'Error While Signing'
    },
    remoteClickErrorMessageTitle: {
        id: 'RemoteClick2SignModal.Remote Sign in Error',
        defaultMessage: 'Remote Sign in Error'
    },
    name: {
        id: 'RemoteClick2SignModal.Name',
        defaultMessage: 'Name'
    },
    role: {
        id: 'RemoteClick2SignModal.Role',
        defaultMessage: 'Role'
    },
    email: {
        id: 'RemoteClick2SignModal.Email',
        defaultMessage: 'Email'
    }
});
