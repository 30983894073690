import React, { useCallback, useContext, useEffect, useState } from 'react';
import { get as _get, isUndefined as _isUndefined, set as _set } from 'lodash';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import {
    useAuthentication,
    withAuthenticationContext,
} from '@xengage/gw-digital-auth-react';
import { useNewBusinessUtil, useViewPolicyUtil } from 'e1p-capability-hooks';
import appConfig from 'app-config';
import messages from '../InsuredPage.messages';
import metadata from '../InsuredPage.metadata.json5';

const LOB = 'homeowners_EH';
const dwellingUsageTypeFilterForPolicyTypes = {
    HO3: 'MSAHO3',
    HO6: 'MSAHO6',
    HF9: 'MSAHF9',
};
const statesNotIncludingAutoLosses =
    appConfig.statesNotIncludingAutoLosses ?? [];

function InsuredPage (props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        authUserData,
        steps,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        markFurthestStep,
    } = props;
    const translator = useTranslator();
    const [lapseInCoverage, updateLapseInCoverage] = useState(undefined);
    const [isThisNewHomePurchase, updateIsThisNewHomePurchase] =
        useState(undefined);
    const [usageType, updateUsageType] = useState(undefined);
    const [usageTypes, updateUsageTypes] = useState([]);
    const { authHeader } = useAuthentication();
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const viewModelService = useContext(ViewModelServiceContext);
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState(
        []
    );
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const policyState = _get(
        submissionVM,
        'baseData.policyAddress.state.value.code'
    );
    const policyType = _get(
        submissionVM,
        'lobData.homeowners_EH.policyType.value.code'
    );

    const { getLocationAndProducerCodes } = useNewBusinessUtil(
        submissionVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        setLocationAndProducerCodes
    );

    const { getLandingPageIndexForViewOnlyPolicy, getProducerDetails } =
        useViewPolicyUtil();

    useEffect(() => {
        updateUsageType(undefined);

        if (policyType !== 'HO4') {
            const yourHomeDTO = viewModelService.create(
                {},
                'pc',
                'amfam.edge.capabilities.policyjob.lob.eh.coverables.dto.EHYourHomeDTO'
            );
            const dwellingUsageFilter =
                dwellingUsageTypeFilterForPolicyTypes[
                    submissionVM.lobData.homeowners_EH.policyType.value.code
                ];
            const dwellingUsageTypes =
                yourHomeDTO.dwellingUsageType.aspects.availableValues[0].typelist
                    .getFilter(dwellingUsageFilter)
                    .codes.map(item => ({
                            code: item.code,
                            name: {
                                id: item.name,
                            },
                        }));

            updateUsageTypes(dwellingUsageTypes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAndSetLocationCodes = async location => {
        const foundLocationCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (
            foundLocationCodes &&
            foundLocationCodes.producerCodes.length === 1
        ) {
            _set(
                submissionVM,
                'baseData.producerCode_Ext',
                foundLocationCodes.producerCodes[0].code
            );
            _set(
                submissionVM,
                'baseData.externalID_Ext',
                foundLocationCodes.locationCodes[0].code
            );
        }
    };

    useEffect(() => {
        if (submissionVM.baseData.externalID_Ext?.value) {
            getProducerDetails(
                submissionVM.baseData.externalID_Ext.value,
                authHeader
            )
                .then(details => {
                    setProducerCodeDetails(details);
                    setAgencyName(details.agencyName);
                    setBookTransferIndicator(details.bookTransferIndicator);
                    setServiceCenterIndicator(
                        details.serviceCenterIndicatorValue
                    );
                })
                .catch(error => {
                    /**
                     * E1PAP1PC-15099 :
                     * This is rare scenario; will never happen in production
                     * When we bound policy using dummy producer code
                     * and when other user tries to view this policy we get exception from service
                     */
                    if (submissionVM.baseData.value.exceptions_Ext) {
                        submissionVM.baseData.value.exceptions_Ext.push({
                            errorMessage: error.message,
                        });
                    } else {
                        _set(
                            submissionVM.value,
                            `baseData.exceptions_Ext[${0}]`,
                            { errorMessage: error.message }
                        );
                    }

                    updateWizardData(submissionVM);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionVM.baseData.externalID_Ext.value]);

    useEffect(() => {
        // in view only flow we want to make each step as visited
        // so that user can navigate to any page by clicking on tabs on chevron
        if (isPageJumpEnabled) {
            updateIsPageJumpEnabled(false);
            jumpTo(
                getLandingPageIndexForViewOnlyPolicy(LOB, steps, 'Submission'),
                true
            );
        }

        if (isAgent) {
            getAndSetLocationCodes(
                _get(submissionVM, 'baseData.policyAddress.state.value.code')
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // IAP-5983, steps are not getting synced in the wizard.
        if(_get(submissionVM, 'value.quoteType') === 'Full') {
            markFurthestStep(steps.length - 1);
        }
        
    }, [markFurthestStep, steps.length, submissionVM]);

    useEffect(() => {
        if (
            locationAndProducerCodes.producerCodes &&
            locationAndProducerCodes.producerCodes.length === 1
        ) {
            _set(
                submissionVM,
                'baseData.producerCode_Ext',
                locationAndProducerCodes.producerCodes[0].code
            );
            _set(
                submissionVM,
                'baseData.externalID_Ext',
                locationAndProducerCodes.locationCodes[0].code
            );
        }
    }, [locationAndProducerCodes, submissionVM]);

    const handleProducerApiRespone = useCallback(
        producerDetails => {
            const {
                producerCode,
                name,
                externalID,
                bookRollIndicator,
                serviceCenterIndicatorValue,
            } = producerDetails;

            setLocationAndProducerCodes([
                {
                    producerCodes: { code: producerCode, name: producerCode },
                    locationCodes: { code: externalID, name: externalID },
                },
            ]);
            _set(submissionVM, 'baseData.producerCode_Ext', producerCode);
            _set(submissionVM, 'baseData.externalID_Ext.value', externalID);
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [submissionVM]
    );

    useEffect(() => {
        if (
            submissionVM.lobData.homeowners_EH.priorPolicies.value !==
                undefined &&
            submissionVM.lobData.homeowners_EH.priorPolicies.length > 0
        ) {
            updateLapseInCoverage(
                !submissionVM.lobData.homeowners_EH.priorPolicies.value[0]
                    .continuousCoverageInd
            );
        }

        if (
            submissionVM.lobData.homeowners_EH.coverables.yourHome !== undefined
        ) {
            updateIsThisNewHomePurchase(
                submissionVM.lobData.homeowners_EH.coverables.yourHome
                    .isThisNewHomePurchase.value
            );

            if (
                submissionVM.lobData.homeowners_EH.coverables.yourHome
                    .dwellingUsageType.value
            ) {
                updateUsageType(
                    submissionVM.lobData.homeowners_EH.coverables.yourHome
                        .dwellingUsageType.value.code
                );
            }
        }

        if (
            _isUndefined(
                _get(
                    submissionVM,
                    'lobData.homeowners_EH.hasManualPropertyAutoLosses.value'
                )
            )
        ) {
            _set(
                submissionVM,
                'lobData.homeowners_EH.hasManualPropertyAutoLosses.value',
                false
            );
        }

        if (
            _isUndefined(
                _get(
                    submissionVM,
                    'lobData.homeowners_EH.hasManualPropertyAtFaultAutoLosses.value'
                )
            )
        ) {
            _set(
                submissionVM,
                'lobData.homeowners_EH.hasManualPropertyAtFaultAutoLosses.value',
                false
            );
        }

        if (
            _isUndefined(
                _get(
                    submissionVM,
                    'lobData.homeowners_EH.hasManualPropertyLosses.value'
                )
            )
        ) {
            _set(
                submissionVM,
                'lobData.homeowners_EH.hasManualPropertyLosses.value',
                false
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateOverrides = useCallback(() => {
        const priorAddr = _get(
            submissionVM,
            'lobData.homeowners_EH.primaryNamedInsured.priorAddresses.value',
            []
        );
        const overrideProps = {};
        const initialRequiredFields = [
            'pAdressLine1',
            'pPostalCode',
            'pCity',
            'pState',
        ];

        priorAddr.forEach((priorAddress, index) => {
            let priorAddressTitle = translator(messages.ehPriorAddressTitle);

            priorAddressTitle = `${translator(messages.ehPriorAddressTitle)} ${
                index + 1
            }`;
            overrideProps[`ehPriorAddressTitle${index}`] = {
                value: priorAddressTitle,
            };
            overrideProps[`priorAddress${index}`] = {
                submissionVM,
                updateWizardData,
                requiredFields: initialRequiredFields,
                viewOnlyMode: true,
            };
        });
        overrideProps.ehAddPriorAddressButton = {
            visible: false,
        };

        return overrideProps;
    }, [submissionVM, translator, updateWizardData]);

    const onNext = useCallback(() => submissionVM, [submissionVM]);

    const resolvers = {
        resolveCallbackMap: {
            onAddPriorAddress: undefined,
            onRemovePriorAddress: undefined,
            onAddSni: undefined,
            onValidate: undefined,
            onRemoveSni: undefined,
            disclosureLinkHandler: undefined,
            disclosureCheckHandler: undefined,
            guidelinesLinkHandler: undefined,
            guidelinesCheckHandler: undefined,
            legalDisclosureCheckHandler: undefined,
            onHandleProducerApiResponse: handleProducerApiRespone,
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true,
        },
        policyDetailsPageLoadingIndicator: {
            loaded: true,
        },
        effectiveDateValidationMessage: {
            visible: false,
        },
        secNamedInsuredContainer: {
            visible: !!_get(
                submissionVM,
                `lobData[${LOB}].secondaryNamedInsured.value`
            ),
        },
        isThisNewHomePurchase: {
            value: isThisNewHomePurchase,
            onValueChange: () => {},
        },
        addSni: {
            visible: false,
        },
        lastPolicyExpirationDate: {
            // HO4 will not have prior policies, so implement check for undefined
            dateDTO:
                submissionVM.lobData.homeowners_EH.priorPolicies.children[0]
                    ?.policyExpirationDate,
        },
        coverageStartDate: {
            dateDTO: submissionVM.baseData.periodStartDate,
            defaultToToday: true,
            showErrors: true,
        },
        coverageEndDate: {
            dateDTO: submissionVM.baseData.periodEndDate,
            updateDateDto: () => updateWizardData(submissionVM),
        },
        personalInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr'],
        },
        dateOfBirth: {
            dateDTO: submissionVM.baseData?.accountHolder?.dateOfBirth,
        },
        agencyName: {
            value: agencyName,
        },
        bookTransferIndicator: {
            value: bookTransferIndicator,
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator,
        },
        newHomePurchaseMainDiv: {
            visible: policyType === 'HO3' || policyType === 'HF9',
        },
        insuranceHistoryMainDiv: {
            visible: policyType !== 'HO4',
        },
        coverageLapseAndExpirationDate: {
            visible: policyType !== 'HO4',
        },
        coverageLapse: {
            value: lapseInCoverage,
        },
        editPropertyLosses: {
            visible: false,
        },
        usageType: {
            availableValues: usageTypes,
            value: usageType,
            onValueChange: () => {},
            visible: policyType !== 'HO4',
        },
        atFaultAutoLossesContainer: {
            visible:
                policyType !== 'HO6' &&
                policyType !== 'HO4' &&
                !statesNotIncludingAutoLosses.includes(policyState),
        },
        autoLossesContainer: {
            visible:
                policyType !== 'HO6' &&
                policyType !== 'HO4' &&
                !statesNotIncludingAutoLosses.includes(policyState),
        },
        atFaultAutoLossesMainDiv: {
            visible: policyType !== 'HO6',
        },
        autoLossesMainDiv: {
            visible: policyType !== 'HO6',
        },
        accountSearchComponent: {
            visible: false,
        },
        mailingAndBillingAddressComponent: {
            transactionVM: submissionVM,
            viewOnlyMode: true,
            lob: LOB,
        },
        e1pAcknowledgementComponent: {
            acknowledgements: _get(
                submissionVM,
                `lobData[${LOB}].acknowledgements`
            ),
            policyState: {
                code: _get(
                    submissionVM,
                    'baseData.policyAddress.state.value.code'
                ),
                name: translator({
                    id: _get(
                        submissionVM,
                        'baseData.policyAddress.state.value.name'
                    ),
                }),
            },
            policyType,
            viewOnly: true,
            lob: LOB,
        },
        policyForm: {
            visible: usageType !== undefined || policyType === 'HO4',
            value: _get(
                submissionVM,
                'lobData.homeowners_EH.policyFormType.value'
            ),
        },
        insuredResidenceAddress: {
            addressVM: _get(submissionVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            viewOnlyMode: true,
        },
        riskAddressComponent: {
            submissionVM,
            updateWizardData,
            viewOnlyMode: true,
            viewModelService,
        },
        ehPriorAddressComponent: {
            submissionVM,
            updateWizardData,
            viewModelService,
            visible: policyType === 'HO3' || policyType === 'HF9',
            viewOnlyMode: true,
            LOB,
        },
        primaryNamedInsured: {
            viewOnlyMode: true,
            isPNI: true
        },
        removeSni: {
            visible: false,
        },
        locationCode: {
            visible: isAgent,
        },
        ExternalProducerDetailsComponent: {
            viewOnlyMode: true,
            visible: !isAgent,
            defaultValue: submissionVM.baseData.externalID_Ext?.value,
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone,
        },
        changePniComponent: {
            visible: false,
        },
        changeSniComponent: {
            visible: false,
        },
        EhRatingDateTestComponent: {
            visible: false,
        },
        quoteCreationDate: {
            dateDTO: submissionVM.baseData.quoteCreationDate_Ext,
            updateDateDto: () => {},
            readOnly: true,
            visible:
                authUserData.permissions_Ext.includes('viewratefields_ext'),
        },
        rateAsOfDate: {
            dateDTO: submissionVM.baseData.periodRateAsOfDate,
            updateDateDto: () => {},
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('editrateasofdate'),
        },
        ratingDate: {
            dateDTO: submissionVM.baseData.ratingDate,
            updateDateDto: () => {},
            readOnly: true,
            visible:
                authUserData.permissions_Ext.includes('viewratefields_ext'),
        },
        ...generateOverrides(),
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                submissionVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, submissionVM]
    );

    return (
        <WizardPage onNext={onNext} shouldLink showPrevious={false}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

InsuredPage.propTypes = wizardProps;

export default withAuthenticationContext(InsuredPage);
