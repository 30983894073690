import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { formatDate } from '@jutro/components';
import metadata from './E1PNotesComponent.metadata.json5';
import messages from './E1PNotesComponent.messages';
import styles from './E1PNotesComponent.module.scss';

const E1PNotesComponent = (props) => {
    const {
        initialNotesData,
        createNote,
        getNotesTileUpdatedCount,
        publicID,
        noDataMessage,
        openActivitiesNote,
        showNoteButton,
        viewModelService
    } = props;
    const translator = useTranslator();
    const [notesSubmissionVM, updateNotesSubmissionVM] = useState({});
    const [showNotes, updateShowNotes] = useState(false);
    const [notesDataTotal, updateNotesDataFiltered] = useState([]);
    const [noOfMoreNotes, updateNoOfMoreNotes] = useState(true);
    const [searchFilter, updateSearchFilter] = useState('');

    const [showAddNoteButton, updateShowAddNoteButton] = useState(false);

    const {
        onValidate,
        isComponentValid
    } = useValidation('E1PNotesComponent');

    const createVM = useCallback((model) =>
        viewModelService.create(model, 'pc', 'edge.capabilities.gateway.note.dto.NoteDTO'),
        [viewModelService]);

    const displayNotes = useCallback((notesData) => {
        const topicAvailableValues = _.get(notesSubmissionVM, 'topic.aspects.availableValues');

        if (!_.isEmpty(notesData) && !_.isEmpty(topicAvailableValues)) {
            const notes = _.orderBy(notesData, ['createdDate'], ['desc'])
                .map((notesInfo) => {
                    const topicName = _.get(topicAvailableValues.find((value) =>
                        value.code === notesInfo.topic), 'name');
                        
                    return {
                        noteTopic: translator(topicName),
                        noteSubject: notesInfo.subject,
                        body: notesInfo.body,
                        createdDate: notesInfo.createdDate,
                        author: notesInfo.authorName
                    };
                });
                
            return notes;
        }
        
        return notesData;
    }, [notesSubmissionVM, translator]);

    const getLatestNote = useCallback((latestNotesData) => {
        const topicAvailableValues = _.get(notesSubmissionVM, 'topic.aspects.availableValues');

        if (!_.isEmpty(latestNotesData)) {
            const displayNoteLatestData = _.orderBy(latestNotesData, ['createdDate'], ['desc'])[0];
            let topicName = displayNoteLatestData.topic;

            if (topicAvailableValues !== undefined) {
                topicName = _.get(topicAvailableValues.find((value) => value.code === displayNoteLatestData.topic), 'name');
            }

            const note = {
                noteTopic: translator(topicName),
                noteSubject: displayNoteLatestData.subject,
                body: displayNoteLatestData.body,
                createdDate: displayNoteLatestData.createdDate,
                author: displayNoteLatestData.authorName
            };

            return note;
        }

        return latestNotesData;
    }, [translator, notesSubmissionVM]);

    const getOpenActivitiesNotesList = useCallback(() => {
        const openActivitiesNotesList = noOfMoreNotes
            ? [getLatestNote(initialNotesData)]
            : displayNotes(initialNotesData);

        return openActivitiesNotesList;
    }, [initialNotesData, noOfMoreNotes, displayNotes, getLatestNote]);

    const showHideNotesOnClick = () => {
        updateNoOfMoreNotes(!noOfMoreNotes);
    };

    const shouldShowNotesButton = useCallback(async () => {
        const { authUserData } = props;
        const isPermisson = authUserData?.permissions_Ext.includes('notecreate');

        updateShowAddNoteButton(isPermisson);
    }, [props]);

    const writeValue = useCallback((value, path) => {
        const newNotesSubmissionVM = viewModelService.clone(notesSubmissionVM);

        _.set(newNotesSubmissionVM, path, value);
        updateNotesSubmissionVM(newNotesSubmissionVM);
    }, [notesSubmissionVM, viewModelService]);

    const handleFormSubmit = useCallback(() => {
        if (!_.isUndefined(publicID)) {
            createNote(notesSubmissionVM.value, publicID);

            if (getNotesTileUpdatedCount) {
                getNotesTileUpdatedCount();
            }
        } else {
            createNote(notesSubmissionVM.value);

            if (getNotesTileUpdatedCount) {
                getNotesTileUpdatedCount();
            }
        }

        updateShowNotes(false);
    }, [publicID, createNote, notesSubmissionVM.value, getNotesTileUpdatedCount]);

    const addNotesBox = useCallback(() => {
        updateNotesSubmissionVM(createVM({}));
        updateShowNotes(true);
    }, [updateShowNotes, createVM]);

    const cancelNotesBox = useCallback(() => {
        updateNotesSubmissionVM(createVM({}));
        updateShowNotes(false);
    }, [updateShowNotes, updateNotesSubmissionVM, createVM]);

    const formatAMPM = useCallback((date) => {
        let hours = date.getHours() % 12;
        let minutes = date.getMinutes();
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

        hours = hours || 12;
        minutes = minutes < 10 ? `0${minutes}` : minutes;

        const strTime = `${hours}:${minutes} ${ampm}`;

        return strTime;
    }, []);

    const getUserCellData = useCallback((items, index, { path: property }) => (
            <div>
                <div className={styles.noteTopic}>
                    {items[property]}
                </div>
                <div className={styles.noteDate}>
                    {formatDate(new Date(items.createdDate), { year: 'numeric', month: 'short', day: 'numeric' })}
                </div>
                <div className={styles.noteDate}>
                    {formatAMPM(new Date(items.createdDate))}
                </div>
            </div>
        ), [formatAMPM]);

    const getNotesCellData = useCallback((items, index, { path: property }) => (
            <div>
                <div className={styles.noteSubject}>
                    {items[property]}
                </div>
                <div className={styles.noteBody}>
                    {items.body}
                </div>
            </div>
        ), []);

    const getSearchFilterValues = useCallback((notesArrayResult, filter) => {
        const lowerCaseFilterValue = filter.value.toLocaleLowerCase();

        return _.filter(notesArrayResult, (res) => Object.keys(res).some((key) => typeof (res[key]) === 'string'
                && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)));
    }, []);

    const getTopicCellData = useCallback((items, index, { path: property }) => {
        const message = items[property] || '';
        const getValue = translator({
            id: message,
            defaultMessage: message
        });

        return <span className={styles.noteTopic}>{getValue}</span>;
    }, [translator]);

    const renderTableData = useCallback((item, index, { id: property }) => item[property], []);

    const getShowOrHideNoteLabel = useCallback(() => {
        const showOrHideLabel = noOfMoreNotes
            ? translator(messages.moreNotes,
                { value: initialNotesData.length - 1 })
            : translator(messages.hideNotes);

        return showOrHideLabel;
    }, [initialNotesData.length, translator, noOfMoreNotes]);

    const getFilteredNotesList = useCallback((notesDataList, filter) => {
        let notesDataFiltered = notesDataList;

        notesDataFiltered = (filter.type === 'SearchFilter')
            ? getSearchFilterValues(notesDataFiltered, filter) : notesDataList;
        updateNotesDataFiltered(notesDataFiltered);
    }, [updateNotesDataFiltered, getSearchFilterValues]);

    useEffect(() => {
        const model = {};
        const notesNextSubmissionVM = createVM(model);

        updateNotesSubmissionVM(notesNextSubmissionVM);
        shouldShowNotesButton();
        getFilteredNotesList(initialNotesData, '');
    }, [createVM,
        getFilteredNotesList,
        initialNotesData,
        shouldShowNotesButton,
        updateNotesSubmissionVM]);

    const handleSearchChange = (value, a, { id }) => {
        const filter = {
            type: null,
            value: null
        };

        if (id === 'searchFilter') {
            filter.type = 'SearchFilter';
            filter.value = value;
            updateSearchFilter(value);
            getFilteredNotesList(initialNotesData, filter);
        }
    };


    const overrideProps = {
        notesTitleContainer: {
            visible: !openActivitiesNote
        },
        notesSectionId: {
            visible: showNotes
        },
        notesDataContainer: {
            visible: (!_.isEmpty(initialNotesData))
        },
        noteAndShowHideSection: {
            visible: openActivitiesNote && !showNotes
        },
        notesTableGrid: {
            data: openActivitiesNote
                ? getOpenActivitiesNotesList(initialNotesData)
                : displayNotes(notesDataTotal),
            showSearch: false
        },
        notesAddButtonId: {
            visible: showAddNoteButton && !showNotes
        },
        noNotesDetails: {
            visible: (_.isEmpty(initialNotesData))
        },
        noNotesText: {
            message: noDataMessage
        },
        infoBoxIcon: {
            visible: (_.isUndefined(publicID))
        },
        notesAddDataid: {
            disabled: !isComponentValid
        },
        showHideLinkLabel: {
            visible: (initialNotesData.length > 1),
            content: openActivitiesNote ? getShowOrHideNoteLabel() : ''
        },
        notesAddButtonIdinShowHide: {
            visible: showAddNoteButton && !showNotes && openActivitiesNote && showNoteButton
        },
        notesContainer: {
            className: openActivitiesNote ? styles.notesContainerStyle : ''
        },
        notesText: {
            visible: openActivitiesNote && showNoteButton
        },
        searchFilter: {
            value: searchFilter,
            visible: !(_.isEmpty(initialNotesData))
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            addNotesBox,
            cancelNotesBox,
            handleFormSubmit,
            renderTableData,
            onUserCell: getUserCellData,
            onNotesCell: getNotesCellData,
            onTopicCell: getTopicCellData,
            showHideNotesOnClick,
            formatAMPM,
            handleSearchValueChange: handleSearchChange,
        }
    };
    const readValue = (id, path) => readViewModelValue(
            metadata.componentContent,
            notesSubmissionVM,
            id,
            path,
            overrideProps
        );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={notesSubmissionVM}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
            onValidationChange={onValidate}
        />
    );
};

E1PNotesComponent.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
        clone: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    initialNotesData: PropTypes.arrayOf(PropTypes.shape({
        noteTopic: PropTypes.string,
        noteSubject: PropTypes.string,
        body: PropTypes.string,
        createdDate: PropTypes.string,
        author: PropTypes.string,
    })).isRequired,
    publicID: PropTypes.string.isRequired,
    createNote: PropTypes.func.isRequired,
    openActivitiesNote: PropTypes.bool.isRequired,
    noDataMessage: PropTypes.string.isRequired,
    getNotesTileUpdatedCount: PropTypes.func.isRequired,
    showNoteButton: PropTypes.bool.isRequired,
    authUserData: PropTypes.shape({
        userType: PropTypes.string
    }).isRequired,
};
export default E1PNotesComponent;
