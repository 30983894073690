import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { Table, TableColumn } from '@jutro/legacy/datatable';
import { uwIssuesMessages } from 'gw-components-platform-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import gatewayMessages from '../../gateway.messages';
import UWUtil from './UWUtil';
import metadata from './UnderwritingComponent.metadata.json5';
import styles from './UnderwritingComponent.module.scss';
import messages from './UnderwritingComponent.messages';

const UWIssuesTableComponent = (props) => {
    const { uwIssues, noRecordsMessage, uwUtil } = props;
    const translator = useTranslator();

    const getBlockingPointDisplayKey = useCallback((blockingPoint) => {
        switch (blockingPoint) {
            case 'BlocksBind':
                return translator(uwIssuesMessages.blocksBind);
            case 'BlocksQuote':
                return translator(uwIssuesMessages.blocksQuote);
            case 'BlocksQuoteRelease':
                return translator(uwIssuesMessages.blocksQuoteRelease);
            case 'NonBlocking':
                return translator(uwIssuesMessages.nonBlocking);
            default:
                return blockingPoint;
        }
    }, [translator]);

    const getUWIssueStatusByOffering = useCallback(
        (uwIssue, index, id) => {
            const offering = id !== 'Status' ? id : undefined;
            const currentUWIssue = offering
                ? _.find(uwIssue.onOfferings, (offer) => offer.offering === offering)
                : uwIssue;

            let displayStatus = translator(messages.notApplicable);

            if (!currentUWIssue) {
                return displayStatus;
            }

            if (currentUWIssue.currentBlockingPoint) {
                displayStatus = getBlockingPointDisplayKey(currentUWIssue.currentBlockingPoint);
            }

            return currentUWIssue.currentBlockingPoint === 'NonBlocking'
                && currentUWIssue.hasApprovalOrRejection
                ? translator(messages.approved)
                : displayStatus;
        },
        [getBlockingPointDisplayKey, translator]
    );

    const getCell = useCallback(
        (items, index, property) => {
            const isIgnoreId = ['shortDescription', 'longDescription'].includes(property.id);

            if (!isIgnoreId) {
                return getUWIssueStatusByOffering(items, index, property.id);
            }

            return items[property.id];
        },
        [getUWIssueStatusByOffering]
    );
    const getHeader = useCallback((id) => {
        const headerInfo = {
            shortDescription: messages.shortDescription,
            longDescription: messages.longDescription,
            Status: messages.jobStatusUW
        };

        return headerInfo[id] || id;
    }, []);
    const getTableColumns = useCallback(() => {
        const offeringAndStatus = uwUtil.offerings || ['Status'];
        const bothOfferings = ['shortDescription', 'longDescription', offeringAndStatus];
        const flattenOfferings = _.uniq(_.flatten(bothOfferings));
        const dynamicColumn = flattenOfferings.map((issueOffer) => (
                <TableColumn
                    id={issueOffer}
                    textAlign="left"
                    header={getHeader(issueOffer)}
                    cell={getCell}
                />
            ));

        return dynamicColumn;
    }, [getCell, getHeader, uwUtil.offerings]);

    return (
        <Table data={uwIssues} placeholder={noRecordsMessage}>
            {getTableColumns()}
        </Table>
    );
};

UWIssuesTableComponent.propTypes = {
    uwIssues: PropTypes.shape([]).isRequired,
    noRecordsMessage: PropTypes.string.isRequired,
    uwUtil: PropTypes.shape({
        offerings: PropTypes.shape([])
    }).isRequired
};

const UnderwritingComponent = (props) => {
    const modalApi = useModal();
    const {
        job,
        continueJob,
        onWithdrawJob,
        onEditJob,
        jobService,
        authUserData,
        authHeader,
        onUpdateJobSummary
    } = props;
    const translator = useTranslator();
    const [formData, updateFormData] = useState({});
    const [showReferToUW, setShowReferToUW] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const uwutil = useMemo(() => UWUtil(job, authUserData.userType), []);

    const getCell = useCallback((items, index, property) => items[property.id], []);

    const showUWIssuesAlert = useCallback(() => uwutil.hasUwIssues && !['Bound', 'Withdrawn'].includes(job.statusCode),
        [job, uwutil]);

    const showMessageBox = () => job
            ? !job.isEditLocked
                  && showReferToUW
                  && job.statusCode !== 'Withdrawn'
                  && uwutil.uwIssuesAreBlocking
            : false;

    const showUWIssuesTriggeredMsg = () => showUWIssuesAlert()
        && !job.isEditLocked
        && uwutil.uwIssuesAreBlocking
        && !uwutil.uwIssuesHaveBeenInspectedByUW
        && ['Draft', 'Quoted'].includes(job.statusCode);

    const showReferredToUWMsg = useCallback(() => showUWIssuesAlert()
    && job.isEditLocked && uwutil.hasUwIssues && job.createdByMe,
    [job, showUWIssuesAlert, uwutil]);

    const showUWIssuesRejectedMsg = useCallback(() => showUWIssuesAlert()
    && !job.isEditLocked && uwutil.uwIssuesHaveBeenInspectedByUW,
    [job, showUWIssuesAlert, uwutil]);

    const showUWIssuesApprovedMsg = useCallback(() => showUWIssuesAlert()
    && !job.isEditLocked && !uwutil.uwIssuesAreBlocking,
    [job, showUWIssuesAlert, uwutil]);

    const theJobStateForLock = useCallback(() => ['Withdrawn'].includes(job.statusCode), [job]);

    const getNotificationContent = useCallback(() => ({
            infoMessageTitle: translator(messages[`approvedForThis${uwutil.jobType}`]),
            infoMessageDescription: continueJob.isContinueTransaction
                ? translator(messages[`withdrawOrContinue${uwutil.jobType}`])
                : translator(messages[`withdrawThe${uwutil.jobType}`]),
            withDrawContent: translator(messages[`withdraw${uwutil.jobType}`]),
            continueContent: translator(messages[`continue${uwutil.jobType}`])
        }), [continueJob.isContinueTransaction, translator, uwutil.jobType]);

    const getWithDrawInfo = useCallback(
        (isWithDraw) => {
            const { link, message } = uwutil.translateWithdrawn;

            return isWithDraw ? translator(link) : translator(message);
        },
        [translator, uwutil.translateWithdrawn]
    );

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);

            _.set(nextFormData, path, value);
            updateFormData(nextFormData);
        },
        [formData]
    );

    const referQuoteToUnderWriter = useCallback(() => {
        const { uwNoteForUnderwriter } = formData;
        const noteForUW = uwNoteForUnderwriter === '' ? null : uwNoteForUnderwriter;

        jobService.referToUnderwriter(job.jobNumber, noteForUW, authHeader).then(
            (jobResponse) => {
                setShowReferToUW(false);
                onUpdateJobSummary(jobResponse);
            },
            () => {
                modalApi.showAlert({
                    title: gatewayMessages.modalError,
                    message: messages.errorSendingJobForReview,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.close
                }).catch(_.noop);
            }
        );
    }, [authHeader, formData, job.jobNumber, jobService, modalApi, onUpdateJobSummary]);

    const onCancelReferUW = useCallback(() => {
        setShowReferToUW(false);
    }, []);

    const onReferToUW = useCallback(() => {
        setShowReferToUW(true);
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell,
            handleEditJob: onEditJob,
            handleUnderwrite: referQuoteToUnderWriter,
            handleWithDraw: onWithdrawJob,
            onCancelReferUW,
            onReferToUW,
            writeValue
        },
        resolveComponentMap: {
            uwissuestablecomponent: UWIssuesTableComponent,
        }
    };
    const overrideProps = {
        underwritingIssues: {
            uwIssues: job.underwritingIssues,
            uwUtil: uwutil,
            noRecordsMessage: translator(messages[`noOpenUnderwriting${uwutil.jobType}`])
        },
        uwIssuesWarningNotification: {
            job
        },
        quoteNotificationIsApproved: {
            notificationContent: showUWIssuesApprovedMsg() && getNotificationContent(),
            transactionVisibleActions: continueJob,
            visible: showUWIssuesApprovedMsg()
        },
        uwIssuesRaisedContainer: {
            visible: showUWIssuesTriggeredMsg()
        },
        referForReviewContainer: {
            visible:
                showReferredToUWMsg() && !showUWIssuesRejectedMsg() && !showUWIssuesApprovedMsg()
        },
        uwIssuesRejectedByUnderViewerContainer: {
            visible: showUWIssuesRejectedMsg() && !showUWIssuesApprovedMsg()
        },
        uwIssuesLockedContainer: {
            visible: uwutil.isLockedToUser && !showReferredToUWMsg() && !theJobStateForLock()
        },
        referToUWPanel: {
            visible: showMessageBox()
        },
        showIssuesHeader: {
            content: translator(messages[`underwriter${uwutil.jobType}`])
        },
        underWritingIssueLine1: {
            content: translator(messages[`toCompletePointersFor${uwutil.jobType}`])
        },
        editJobForAccept: {
            content: translator(messages[`edit${uwutil.jobType}`])
        },
        reviewForUnderWriter: {
            content: translator(messages[`review${uwutil.jobType}`])
        },
        referReviewForUW: {
            content: translator(messages[`underwriterReviewing${uwutil.jobType}`])
        },
        referredToUnderwriterMessage: {
            content: translator(uwutil.getReferredToUnderwriterMessage)
        },
        rejectedIssuesHeader: {
            title: !uwutil.offerings
                ? translator(messages.rejectedByUW)
                : translator(messages.offerUnresolvedIssue)
        },
        editOrReferUW: {
            content: translator(messages[`editOrRefer${uwutil.jobType}`])
        },
        withdrawLink: {
            content: getWithDrawInfo(true)
        },
        wishToContinue: {
            content: getWithDrawInfo()
        },
        referReviewWithDrawJob: {
            content: translator(messages[`withdraw${uwutil.jobType}`])
        },
        rejectReviewWithDrawJob: {
            content: translator(messages[`withdraw${uwutil.jobType}`])
        },
        rejectEditJob: {
            content: translator(messages[`warningEdit${uwutil.jobType}`])
        },
        raisedEditJob: {
            content: translator(messages[`warningEdit${uwutil.jobType}`])
        },
        lockedIssuesHeader: {
            content: translator(messages[`locked${uwutil.jobType}`])
        },
        referralInfo: {
            content: translator(messages[`referralInfo${uwutil.jobType}`])
        },
        wantToReferInfo: {
            content: translator(messages[`referToUW${uwutil.jobType}`])
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={formData}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

UnderwritingComponent.propTypes = {
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    job: PropTypes.shape({
        statusCode: PropTypes.string,
        isEditLocked: PropTypes.bool,
        createdByMe: PropTypes.bool,
        jobNumber: PropTypes.number,
        underwritingIssues: PropTypes.shape({})
    }),
    continueJob: PropTypes.shape({
        isContinueTransaction: PropTypes.bool
    }),
    onWithdrawJob: PropTypes.func.isRequired,
    onUpdateJobSummary: PropTypes.func.isRequired,
    onEditJob: PropTypes.func.isRequired,
    jobService: PropTypes.shape({
        referToUnderwriter: PropTypes.string
    }).isRequired,
    authUserData: PropTypes.shape({
        userType: PropTypes.string
    }).isRequired
};
UnderwritingComponent.defaultProps = {
    job: {},
    continueJob: {}
};

export default withAuthenticationContext(UnderwritingComponent);
