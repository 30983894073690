import { defineMessages } from 'react-intl';

export default defineMessages({
    unverifiedPremium: {
        id: 'quoteandbind.common.QuoteClauseTable.Unverified Premium',
        defaultMessage: 'Unverified Premium'
    },
    verifiedPremium: {
        id: 'quoteandbind.common.QuoteClauseTable.Verified Premium',
        defaultMessage: 'Verified Premium'
    },
    withoutPaidInFullDiscount: {
        id: 'quoteandbind.common.QuoteClauseTable.Without Paid-In Full Discount',
        defaultMessage: 'Without Paid-In Full Discount'
    },
    policyTerm: {
        id: 'quoteandbind.common.QuoteClauseTable.Policy Term',
        defaultMessage: 'Policy Term'
    },
    sixMonths: {
        id: 'quoteandbind.common.QuoteClauseTable.6 Months',
        defaultMessage: '6 Months'
    },
    twelveMonths: {
        id: 'quoteandbind.common.QuoteClauseTable.12 Months',
        defaultMessage: '12 Months'
    },
    enhanceCoverage: {
        id: 'quoteandbind.common.QuoteClauseTable.Enhance Coverage',
        defaultMessage: 'Enhance Coverage'
    },
    enhanceDiff: { // This will be deleted when we have real figures
        id: 'quoteandbind.common.QuoteClauseTable.+ $150.00',
        defaultMessage: '+ $150.00'
    },
    upgradeCoverages: {
        id: 'quoteandbind.common.QuoteClauseTable.Upgrade Coverages',
        defaultMessage: 'Upgrade Coverages'
    },
    enhancePlusCoverage: {
        id: 'quoteandbind.common.QuoteClauseTable.Enhance Plus Coverage',
        defaultMessage: 'Enhance Plus Coverage'
    },
    enhancePlusDiff: {
        id: 'quoteandbind.common.QuoteClauseTable.+ $350.00',
        defaultMessage: '+ $350.00'
    },
    discounts: {
        id: 'quoteandbind.common.QuoteClauseTable.Discounts',
        defaultMessage: 'Discounts'
    },
    paidInFullDiscount: {
        id: 'quoteandbind.common.QuoteClauseTable.Paid-In-Full Discount',
        defaultMessage: 'Paid-In-Full'
    },
    policyLevelCoverages: {
        id: 'quoteandbind.common.QuoteClauseTable.Policy Level Coverages',
        defaultMessage: 'Policy Level Coverages'
    },
    vehicleLevelUpgrades: {
        id: 'quoteandbind.common.QuoteClauseTable.Vehicle Level Upgrades',
        defaultMessage: 'Vehicle Level Upgrades'
    },
    marketingLine: {
        id: 'quoteandbind.common.QuoteClauseTable.A short marketing line about the benefits of upgrading.',
        defaultMessage: 'A short marketing line about the benefits of upgrading.'
    },
    vehicleLevelCoverages: {
        id: 'quoteandbind.common.QuoteClauseTable.Vehicle Level Coverages',
        defaultMessage: 'Vehicle Level Coverages'
    },
    coverages: {
        id: 'quoteandbind.common.QuoteClauseTable.Coverages',
        defaultMessage: 'Coverages'
    },
    Yes: {
        id: 'quoteandbind.common.QuoteClauseTable.Yes',
        defaultMessage: 'Yes'
    },
    No: {
        id: 'quoteandbind.common.QuoteClauseTable.No',
        defaultMessage: 'No'
    },
    paperlessDiscount: {
        id: 'quoteandbind.common.QuoteClauseTable.Paperless Discount',
        defaultMessage: 'Paperless'
    },
    paperlessDiscountEmail: {
        id: 'quoteandbind.common.QuoteClauseTable.PaperlessDiscount.Email',
        defaultMessage: 'Email'
    },
    emptyPremium: {
        id: 'quoteandbind.ea.views.payment-details.-',
        defaultMessage: '-'
    },
    exit: {
        id: 'quoteandbind.common.directives.CommonOfferingSelection.Exit',
        defaultMessage: 'Exit'
    }
});
