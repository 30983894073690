import { defineMessages } from 'react-intl';

export default defineMessages({
    exitTransaction: {
        id: 'rewrite.ea.wizard.steps.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'rewrite.ea.wizard.steps.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'rewrite.ea.wizard.steps.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'rewrite.ea.wizard.steps.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    },
    anErrorOccurred: {
        id: 'rewrite.ea.wizard.error.cancel',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'rewrite.ea.wizard.error.cancel.title',
        defaultMessage: 'Error'
    }
});
