import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import EURewriteStartPage from './pages/StartRewrite/StartRewritePageEU';
import EURewriteInsuredDetailPage from './pages/InsuredDetailPage/InsuredDetailPage';
import EURewriteQualificationPage from './pages/QualificationPage/EUPreQualificationPage';
import EURewriteUnderlyingPolicyPage from './pages/UnderlyingPolicyPage/UnderlyingPolicyPage';
import EURewriteVehicleOperatorPage from './pages/VehicleOperatorPage/EUVehicleOperatorPage';
import EURewriteRiskAnalysisPage from './pages/RiskAnalysisPage/RiskAnalysisPage';
import EURewriteQuotePage from './pages/QuotePage/QuotePage';
import EURewriteThirdPartyInterestPage from './pages/ThirdPartyInterestPage/ThirdPartyInterestPage';
import EURewritePaymentDetailPage from './pages/PaymentDetailPage/PaymentDetailsPage';
import EURewriteThankYouPage from './pages/ThankYouPage/ThankYouPage';
import EUViewRewriteInsuredDetailsPage from './pages/InsuredDetailPage/viewOnly/ViewInsuredDetailPage';
import EUViewRewritePreQualificationPage from './pages/QualificationPage/viewOnly/ViewEUPreQualificationPage';
import EUViewRewriteUnderlyingPolicyPage from './pages/UnderlyingPolicyPage/viewOnly/ViewUnderlyingPolicyPage';
import EUViewRewriteRiskAnalysisPage from './pages/RiskAnalysisPage/viewOnly/ViewRiskAnalysisPage';
import EUViewRewriteCoveragePage from './pages/QuotePage/viewOnly/ViewQuotePage';
import EUViewRewriteTPIPage from './pages/ThirdPartyInterestPage/viewOnly/ViewThirdPartyInterestPage';
import EUViewRewritePaymentDetailPage from './pages/PaymentDetailPage/viewOnly/ViewPaymentDetailsPage';

setComponentMapOverrides(
    {
        EURewriteStartPage: { component: 'EURewriteStartPage' },
        EURewriteInsuredDetailPage: { component: 'EURewriteInsuredDetailPage' },
        EURewriteQualificationPage: { component: 'EURewriteQualificationPage' },
        EURewriteUnderlyingPolicyPage: { component: 'EURewriteUnderlyingPolicyPage' },
        EURewriteVehicleOperatorPage: { component: 'EURewriteVehicleOperatorPage' },
        EURewriteRiskAnalysisPage: { component: 'EURewriteRiskAnalysisPage' },
        EURewriteQuotePage: { component: 'EURewriteQuotePage' },
        EURewriteThirdPartyInterestPage: { component: 'EURewriteThirdPartyInterestPage' },
        EURewritePaymentDetailPage: { component: 'EURewritePaymentDetailPage' },
        EURewriteThankYouPage: { component: 'EURewriteThankYouPage' },
        EUViewRewriteInsuredDetailsPage: { component: 'EUViewRewriteInsuredDetailsPage' },
        EUViewRewritePreQualificationPage: { component: 'EUViewRewritePreQualificationPage' },
        EUViewRewriteUnderlyingPolicyPage: { component: 'EUViewRewriteUnderlyingPolicyPage' },
        EUViewRewriteRiskAnalysisPage: { component: 'EUViewRewriteRiskAnalysisPage' },
        EUViewRewriteCoveragePage: { component: 'EUViewRewriteCoveragePage' },
        EUViewRewriteTPIPage: { component: 'EUViewRewriteTPIPage' },
        EUViewRewritePaymentDetailPage: { component: 'EUViewRewritePaymentDetailPage' }
    },
    {
        EURewriteStartPage,
        EURewriteInsuredDetailPage,
        EURewriteQualificationPage,
        EURewriteUnderlyingPolicyPage,
        EURewriteVehicleOperatorPage,
        EURewriteRiskAnalysisPage,
        EURewriteQuotePage,
        EURewriteThirdPartyInterestPage,
        EURewritePaymentDetailPage,
        EURewriteThankYouPage,
        EUViewRewriteInsuredDetailsPage,
        EUViewRewritePreQualificationPage,
        EUViewRewriteUnderlyingPolicyPage,
        EUViewRewriteRiskAnalysisPage,
        EUViewRewriteCoveragePage,
        EUViewRewriteTPIPage,
        EUViewRewritePaymentDetailPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PEEURewriteWizard } from './PEEURewriteWizard';
