import React from 'react';
import PropTypes from 'prop-types';
import { DropdownSelectField } from '@jutro/legacy/components';


/**
 * This custom dropdown component is created to make dropdown readOnly if 
 * it has only one available value to select or its readOnly(view only flow or component is not editable)
 * 
 * @param {Object} props
 * @returns {Component}
 */
const E1PDropdownSelectComponent = (props) => {
    const {
        readOnly,
        termOptions,
        ...otherProps
    } = props;

    const { value, availableValues } = otherProps;

    /**
     * termOptions is applicable only for Coverages 
     * as we don't pass 'None Selected' term option in available values
     * Note : we remove 'None Selected' from available values and add it as part of 'placeholder'
     * as it does not have any code so in UI it does not show up so we add is as placeholder
     */
    const availableValuesLength = termOptions?.length || availableValues.length;
    
    return (
        <DropdownSelectField
            readOnly={
                readOnly
                || (availableValuesLength === 1 && value !== undefined)
            } // dropdown will be readOnly when we have only one available option to select and its selected already
            {...otherProps} // passing other props as it is
        />

    );

}

E1PDropdownSelectComponent.propTypes = {
    readOnly: PropTypes.bool,
    termOptions: PropTypes.arrayOf(PropTypes.shape({}))
};

E1PDropdownSelectComponent.defaultProps = {
    readOnly: false,
    termOptions: undefined
}

export default E1PDropdownSelectComponent;