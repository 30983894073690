import { defineMessages } from 'react-intl';

export default defineMessages({
    messagesHeadingID: {
        id: 'quoteandbind.eh.full.views.thankyou.messages.messagesHeadingID',
        defaultMessage: 'Messages'
    },
    eaMultiProductDiscountMessage: {
        id: 'quoteandbind.eh.full.views.thankyou.messages.eaMultiProductDiscountMessage',
        defaultMessage: 'The Multi-Product Discount has been granted based on the presence of a companion Homeowner and/or Umbrella policy. If a companion policy(s) cannot be found the discount will be removed.'
    },
    paperlessIndicatorNo: {
        id: 'quoteandbind.eh.full.views.thankyou.messages.paperlessIndNo',
        defaultMessage: `Your client has chosen not to receive policy documents electronically.\n
        &nbsp;Please remind your client they can still create an account with {partner}.\n
        &nbsp;My Account provides 24/7 access to policy documents, claims, payment details and other account information.`
    },
    paperlessIndicatorYes: {
        id: 'quoteandbind.eh.full.views.thankyou.messages.paperlessIndYes',
        defaultMessage: `Your client has chosen to receive policy documents electronically.\n
        &nbsp;Please remind your client to create an account with {partner}.\n
        &nbsp;My Account provides 24/7 access to policy documents, claims, payment details and other account information.`
    },
    driversLicenseInStateMessage: {
        id: 'quoteandbind.ea.full.views.thankyou.messages.driversLicenseInState',
        defaultMessage: 'All Drivers must obtain a license in their resident state, other than Military, normally within 60 days of taking residence.'
    },
});
