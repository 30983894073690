import React, {
    useContext, useCallback, useState, useMemo, useRef, useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon, InfoLabel, Tooltip, useModal } from '@jutro/components';
import { Grid, GridItem } from '@jutro/layout';
import PropTypes from 'prop-types';
import {
    findIndex as _findIndex,
    filter as _filter,
    get as _get,
    map as _map,
    set as _set,
    isEqual as _isEqual,
    isUndefined as _isUndefined
} from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ActivitiesService } from 'e1p-capability-gateway';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { useTranslator } from '@jutro/locale';
import { ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import E1PActivityComponent from '../E1PActivityComponent/E1PActivityComponent';
import styles from './E1PActivityTableComponent.module.scss';
import metadata from './E1PActivityTableComponent.metadata.json5';
import messages from './E1PActivityTableComponent.messages';

import { Button } from '@jutro/legacy/components';

/**
 * @param {*} props 
 * IAP-3262, as part of this ticket, refactored this component,
 * so that getActivites API, should not be called unless props are changed,
 * 
 * Issue: This component is re-rendering whenever the parent component is re-rendered, even with same props.
 * 
 * Why this change? :
 * we are refactoring this componet  because it is having dataTable with async fetch call,
 * and as soon as this renders async call is getting fired (even though all the Props are same during re-render).
 * So we will control the re-rendering of this component only when the props changes.
 * This change will increase the performance of the component.
 * 
 * you can read more here
 * https://reactjs.org/docs/react-api.html#reactmemo
 * 
 */

const E1PActivityTableComponent = (props) => {
    const modalApi = useModal();
    const {
        createNote,
        onClickCompleteActivities,
        onClickAddActivitiesToMyQueue,
        onClickAssignActivities,
        currentView,
        currentViewHeading,
        activityReassigned,
        selectedAdvancedFilters,
        tableKey
    } = props;
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [tableActivities, updateTableActivities] = useState([]);
    const [selectedActivities, updateSelectedActivities] = useState([]);
    const [isNoActivitySelectedErrorVisible, setNoActivitySelectedErrorVisible] = useState(false);
    /**
     * IAP-3904: isActivitiesSelectedByUser is used to track if user has selected any activites on the page,
     * and without doing any operation on the selected activites, user is trying to move to next page.
     */
    const isActivitiesSelectedByUser = useRef(false);

    /**
     * IAP-3904: Function to reset the selected activites, if user is trying to move forward without doing any operation,
     * then in that case we need to reset the selected activites.
     */
    const resetSelectedActivites = useCallback(async () => {
        const activityTableDOM = document.getElementById('activitiesTable');

        if (activityTableDOM) {
            // find select all checkbox
            // we need to update the queryselector, if it gets updated in future digital upgrades
            const selectAllDOM = activityTableDOM.querySelector('input[data-testid="activitiesTable-select-all"]');

            if(selectAllDOM && selectAllDOM.click && isActivitiesSelectedByUser.current) {
                // without await the component will not work as expected.
                await selectAllDOM.click();

                if(selectAllDOM.value === 'true'){
                    await selectAllDOM.click();
                }
            }
        }
    }, []);

    /**
     * IAP-3904: everytime user moves to next page, tableActivities gets updated with the response from backend,
     * then reset the selection if user has made any selection in previous page.
     */
    useEffect(() =>{
        if(tableActivities) {
            resetSelectedActivites();
        }
    },[resetSelectedActivites, tableActivities]);

    const onSelectionChange = useCallback((selectedActivitiesPublicIDs) => {
        const selectedItems = [];

        if (selectedActivitiesPublicIDs.length > 0) {
            // create map with publicID as key 
            const activiesMap = new Map();

            tableActivities.forEach((activity) => {
                activiesMap.set(activity.publicID, activity);
            });
    
            // fetch the selected activity from the activityMap
            selectedActivitiesPublicIDs.forEach((publicID) => {
                const activityData = activiesMap.get(publicID);

                if(!_isUndefined(activityData)) {
                    selectedItems.push(activityData);
                }
            });
        }

        setNoActivitySelectedErrorVisible(false);
        updateSelectedActivities(selectedItems);
        isActivitiesSelectedByUser.current = selectedItems.length > 0;
    }, [tableActivities, updateSelectedActivities]);

    const onFetchData = useCallback(
        ({
            sorted,
            pageSize,
            page
        }) => {
            const orderBy = _get(sorted, [0, 'id'], 'priority');
            const isDesc = _get(sorted, [0, 'desc'], false);
            let assignmentOptions = {};
            const startIndex = pageSize * page;
            const offsetEndMinusOne = startIndex + pageSize - 1;
            const paginationOptions = {
                offsetStart: startIndex,
                offsetEnd: offsetEndMinusOne,
                orderBy,
                orderByDescending: isDesc
            };
            let statusPriorityOptions = [];

            // If advanced filters are selected, get selected filters and add to API request payload
            if (selectedAdvancedFilters.length > 0) {
                // Status options
                const selectedStatusFilterOptions = _filter(_get(selectedAdvancedFilters, [1, 'options'], []), (option) => option.selected);
                const selectedStatusOptions = _map(selectedStatusFilterOptions, (option) => ({
                        propertyName: 'Status',
                        isDbProperty: true,
                        typeListName: 'ActivityStatus',
                        typeKeyCode: option.code,
                        orOperation: true
                    }));
                // Category options
                const selectedCategoryFilterOptions = _filter(_get(selectedAdvancedFilters, [0, 'options'], []), (option) => option.selected);
                const selectedCatehoryOptions = _map(selectedCategoryFilterOptions, (option) => ({
                        propertyName: 'QueueCategory_Ext',
                        isDbProperty: true,
                        typeListName: 'QueueCategory_Ext',
                        typeKeyCode: option.code,
                        orOperation: true
                    }));
                // Priority Options
                const selectedPriorityFilterOptions = _filter(_get(selectedAdvancedFilters, [3, 'options'], []), (option) => option.selected);
                const selectedPriorityOptions = _map(selectedPriorityFilterOptions, (option) => ({
                        propertyName: 'Priority',
                        isDbProperty: true,
                        typeListName: 'Priority',
                        typeKeyCode: option.code,
                        orOperation: true
                    }));

                statusPriorityOptions = [...selectedStatusOptions, ...selectedPriorityOptions, ...selectedCatehoryOptions];

                // Assignment & created by options
                const selectedAssignmentFilterOptions = _filter(_get(selectedAdvancedFilters, [2, 'options'], []), (option) => option.selected);
                const selectedCreatedByFilterOptions = _filter(_get(selectedAdvancedFilters, [4, 'options'], []), (option) => option.selected);

                // If assignment is selected to either of option
                if (selectedAssignmentFilterOptions.length === 1) {
                    assignmentOptions = {
                        ...assignmentOptions,
                        // Send true for assigned to me option, and false for assigned to others option
                        'assignedToMe': selectedAssignmentFilterOptions[0].code === 'assignedToMe'
                    };
                }

                // If created by is selected to either of option
                if (selectedCreatedByFilterOptions.length === 1) {
                    assignmentOptions = {
                        ...assignmentOptions,
                        // Send true for created by mee option, and false for created by others option
                        'createdByMe': selectedCreatedByFilterOptions[0].code === 'createdByMe'
                    };
                }
            } else {
                switch (currentView) {
                    case 'yourOpenActivityTile':
                        assignmentOptions = {
                            assignedToMe: true
                        };
                        statusPriorityOptions.push({
                            propertyName: 'Status',
                            isDbProperty: true,
                            typeListName: 'ActivityStatus',
                            typeKeyCode: 'open',
                            orOperation: true
                        });
                        break;
                    case 'yourCompletedActivityTile':
                        assignmentOptions = {
                            assignedToMe: true
                        };
                        statusPriorityOptions.push({
                            propertyName: 'Status',
                            isDbProperty: true,
                            typeListName: 'ActivityStatus',
                            typeKeyCode: 'complete',
                            orOperation: true
                        });
                        break;
                    case 'createdByYouActivityTile':
                        assignmentOptions = {
                            createdByMe: true
                        };
                        break;
                    case 'allOpenActivityTile':
                        statusPriorityOptions.push({
                            propertyName: 'Status',
                            isDbProperty: true,
                            typeListName: 'ActivityStatus',
                            typeKeyCode: 'open',
                            orOperation: true
                        });
                        break;
                    case 'allCompletedActivityTile':
                        statusPriorityOptions.push({
                            propertyName: 'Status',
                            isDbProperty: true,
                            typeListName: 'ActivityStatus',
                            typeKeyCode: 'complete',
                            orOperation: true
                        });
                        break;
                    case 'allCancelledActivityTile':
                        statusPriorityOptions.push({
                            propertyName: 'Status',
                            isDbProperty: true,
                            typeListName: 'ActivityStatus',
                            typeKeyCode: 'canceled',
                            orOperation: true
                        });
                        break;
                    default:
                        break;
                }
            }

            return ActivitiesService.getActivities(
                assignmentOptions,
                paginationOptions,
                statusPriorityOptions,
                authHeader
            ).then((response) => {
                updateTableActivities(response.activities);
                updateSelectedActivities([]);

                return {
                    rows: response.activities,
                    numberOfRows: response.maxNumberOfResults
                };
            });
        }, [authHeader, currentView, selectedAdvancedFilters]
    );

    const getPriority = (data) => {
        let priorityType = '';

        switch (data.priority) {
            case 'high':
                priorityType = 'warning';
                break;
            case 'normal':
                priorityType = 'success';
                break;
            case 'urgent':
                priorityType = 'error';
                break;
            case 'low':
                priorityType = 'neutral';
                break;
            default:
                break;
        }

        return (
            <div>
                <Tooltip id={`tooltip_${data.publicID}`} placement="bottom" content={data.priorityDesc}>
                    <span>
                        <InfoLabel
                            id={`infoLabel_${data.publicID}`}
                            type={priorityType}
                            size="small"
                            message={translator({
                                id: `typekey.Priority.${data.priority}`,
                                defaultMessage: data.priority
                            })}
                        />
                    </span>
                </Tooltip>
            </div>
        );
    };

    const getNotes = (data) => (
            <div>
                {data.notes.length}
                <Icon tag="span" className={styles.activityRemoveNoteIcon} icon="sticky-note" />
            </div>
        );

    const getState = (data) => (
            <div>
                {translator({
                    id: `typekey.State.${data.state_Ext}`,
                    defaultMessage: data.state_Ext
                })}
            </div>
        );

    const getPolicy = (data) => {
        const redirectRoute = 'summary';
        let subjectData = '';

        if (data.policyNumber !== undefined && data.policyNumber !== null) {
            subjectData = (
                <div style={{ whiteSpace: 'normal' }}>
                    <RouterLink to={`/policies/${data.policyNumber}/${redirectRoute}`} className={styles.removeLinkStyle} title={data.policyNumber}>
                        {data.policyNumber}
                    </RouterLink>
                </div>
            );
        }

        return subjectData;
    };

    const getAccount = (data) => {
        const redirectRoute = 'summary';
        let subjectData = '';

        if (data.accountNumber !== undefined && data.accountNumber !== null) {
            subjectData = (
                <div style={{ whiteSpace: 'normal' }}>
                    <RouterLink to={`/accounts/${data.accountNumber}/${redirectRoute}`} className={styles.removeLinkStyle} title={data.accountNumber}>
                        {data.accountHolderName}
                    </RouterLink>
                </div>
            );
        }

        return subjectData;
    };

    const getDueDate = (data) => {
        const dueDate = moment(data.dueDate).format('MM/DD/YYYY');

        return dueDate;
    };

    const markActivitiesCompleted = useCallback(async () => {
        if (selectedActivities.length === 0) {
            setNoActivitySelectedErrorVisible(true);

            return;
        }

        await onClickCompleteActivities(selectedActivities.map((activity) => activity.publicID));
        updateSelectedActivities([]);
    }, [selectedActivities, onClickCompleteActivities]);

    const addActivitiesToMyQueue = useCallback(async () => {
        if (selectedActivities.length === 0) {
            setNoActivitySelectedErrorVisible(true);

            return;
        }

        await onClickAddActivitiesToMyQueue(selectedActivities.map((activity) => activity.publicID));
        updateSelectedActivities([]);
    }, [onClickAddActivitiesToMyQueue, selectedActivities]);

    const assignActivities = useCallback(async () => {
        if (selectedActivities.length === 0) {
            setNoActivitySelectedErrorVisible(true);

            return;
        }

        await onClickAssignActivities(selectedActivities.map((activity) => activity.publicID));
        updateSelectedActivities([]);
    }, [onClickAssignActivities, selectedActivities]);

    const openActivityDetailsPopup = useCallback(async (data) => {
        const activityVM = viewModelService.create(
            data,
            'pc',
            'edge.capabilities.gateway.activity.dto.ActivityDTO'
        );
        const componentProps = {
            title: 'Activity Details',
            activityVM,
            createNote,
            viewModelService,
            authHeader,
            activityReassigned

        };
        const result = await modalApi.showModal(<E1PActivityComponent {...componentProps} />);

        return result;
    }, [activityReassigned, authHeader, createNote, modalApi, viewModelService]);

    const getActivityType = useCallback((item) => 
         (
            <div>
                {translator(item.subject)}
            </div>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

    const renderExpanderContent = useCallback((data) => (
            <div className={styles.activityDetailExpander}>
                <Grid
                    columns={['1fr', '0.75fr', '0.75fr', '1.5fr']}
                    gap="none"
                    justifyContent="center"
                    valignContent="middle"
                    valignItems="middle"
                    className={styles.activityGrid}
                >
                    <span className={styles.activityField}>{translator(messages.activityAccount)}</span>
                    <span className={styles.activityField}>{translator(messages.activityState)}</span>
                    <span className={styles.activityField}>{translator(messages.activityPolicy)}</span>
                    <span className={styles.activityField}>{translator(messages.activityPriority)}</span>

                    <span className={styles.activityFieldValue}>{data.accountHolderName}</span>
                    <span className={styles.activityFieldValue}>
                        {translator({
                            id: `typekey.State.${data.state_Ext}`,
                            defaultMessage: data.state_Ext
                        })}
                    </span>
                    <span className={styles.activityFieldValue}>{data.policyNumber}</span>
                    <span className={styles.activityFieldValue}>
                        {translator({
                            id: `typekey.Priority.${data.priority}`,
                            defaultMessage: data.priority
                        })}
                    </span>

                    <span className={styles.activityField}>{translator(messages.activitySubject)}</span>
                    <span className={styles.activityField}>{translator(messages.activityDueDate)}</span>
                    <span className={styles.activityField}>{translator(messages.activityEscalationDate)}</span>
                    <span className={styles.activityField}>{translator(messages.activityAssignedTo)}</span>

                    <span className={styles.activityFieldValue}>{data.subject}</span>
                    <span className={styles.activityFieldValue}>
                        {moment(data.dueDate).format('MM/DD/YYYY')}
                    </span>
                    <span className={styles.activityFieldValue}>
                        {moment(data.escalationDate).format('MM/DD/YYYY')}
                    </span>
                    <span className={styles.activityFieldValue}>
                        {data?.assignedTo?.displayName ? data.assignedTo.displayName : '-'}
                    </span>

                    <GridItem colSpan="3"><span className={styles.activityField}>{translator(messages.activityDescription)}</span></GridItem>
                    <GridItem>
                        <span className={styles.activityField}>
                            {data.status === 'complete' ? (
                                translator(messages.activityCompletedDate)
                            ) : ''}
                        </span>
                    </GridItem>

                    <GridItem colSpan="3" valign="top"><span className={styles.activityFieldValue}>{data.description}</span></GridItem>
                    <GridItem>
                        <span>
                            {data.status === 'open' && data.canReassign ? (
                                <Button
                                    id="openActivityPopup"
                                    type="filled"
                                    onClick={() => openActivityDetailsPopup(data)}
                                >
                                    {translator(messages.activityAssign)}
                                </Button>
                            ) : ''}
                            {data.status === 'complete' ? (
                                <span className={styles.activityFieldValue}>
                                    {moment(data.completedDate).format('MM/DD/YYYY')}
                                </span>
                            ) : ''}
                        </span>
                    </GridItem>
                </Grid>
            </div>
        ), [openActivityDetailsPopup, translator]);

    const isMarkCompleteButtonEnabled = useMemo(() => {
        if (selectedActivities.length > 0) {
            return _findIndex(selectedActivities, (activity) => !activity.isAssignedToCurrentUser) === -1;
        }

        return false;
    }, [selectedActivities]);

    const isAddToMyQueueButtonEnabled = useMemo(() => {
        if (selectedActivities.length > 0) {
            return _findIndex(selectedActivities, (activity) => !activity.canReassign) === -1;
        }

        return false;
    }, [selectedActivities]);

    const overrideProps = {
        activitiesTable: {
            renderExpanderContent,
            key: tableKey,
            rowIdPath: 'publicID'
        },
        markActivitiesCompletedButton: {
            visible: currentView === 'yourOpenActivityTile',
            disabled: !isMarkCompleteButtonEnabled,
            onClick: markActivitiesCompleted
        },
        addToMyQueueButton: {
            visible: currentView === 'allOpenActivityTile',
            disabled: !isAddToMyQueueButtonEnabled,
            onClick: addActivitiesToMyQueue
        },
        assignButton: {
            visible: ['allOpenActivityTile', 'yourOpenActivityTile'].includes(currentView),
            onClick: assignActivities
        },
        selectedPoliciyTitleHeading: {
            content: currentViewHeading
        },
        noActivitySelectedErrorDiv: {
            visible: isNoActivitySelectedErrorVisible
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getPolicy,
            getAccount,
            getNotes,
            getPriority,
            markActivitiesCompleted,
            getDueDate,
            getActivityType,
            getState,
            onFetchData,
            onSelectionChange,
            assignActivities,
            onRowClick: (data) => {
                _set(data, 'expanded', !data.expanded);
            },
        }
    };

    const openActivityPage = <MetadataContent
        uiProps={metadata.pageContent}
        overrideProps={overrideProps}
        {...resolvers} />;

    return (
        <div className={classNames(styles.summary)}>
            {openActivityPage}
        </div>
    );
};

E1PActivityTableComponent.propTypes = {
    onClickCompleteActivities: PropTypes.func.isRequired,
    onClickAddActivitiesToMyQueue: PropTypes.func.isRequired,
    onClickAssignActivities: PropTypes.func.isRequired,
    createNote: PropTypes.func,
    currentView: PropTypes.string.isRequired,
    currentViewHeading: PropTypes.string.isRequired,
    activityReassigned: PropTypes.func.isRequired,
    selectedAdvancedFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

E1PActivityTableComponent.defaultProps = {
    createNote: () => {},
};

// this function will determine when to render this component.
function areEqual(prevProps, nextProps) {
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
    return prevProps.currentView === nextProps.currentView
    && prevProps.currentViewHeading === nextProps.currentViewHeading
    && prevProps.tableKey === nextProps.tableKey
    && _isEqual(prevProps.selectedAdvancedFilters, nextProps.selectedAdvancedFilters);
}

// read the component description why we are using React.memo
export default React.memo(withViewModelService(E1PActivityTableComponent), areEqual);
