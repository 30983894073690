import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty,
    findIndex as _findIndex,
    uniq as _uniq
} from 'lodash';
import {
    useModal,
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import {
    ViewModelServiceContext,
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import {
    useAuthentication,
    withAuthenticationContext
} from '@xengage/gw-digital-auth-react';
import {
    usePriorPolicyUpdateUtil,
    useUWIssueUtil,
    useLandingPageUtil
} from 'e1p-capability-hooks';
import { AutoLossService } from 'e1p-capability-gateway';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { RenewalService } from 'e1p-capability-renewal';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import metadata from './RiskAnalysisPage.metadata.json5';
import messages from './RiskAnalysisPage.messages';
import wizardMessages from '../../EARenewalWizard.messages';
import requiredMetadata from './RiskAnalysisPage.requiredness';

const LOB = 'personalAuto_EA';

function RiskAnalysisPage(props) {
    const modalApi = useModal();
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        steps,
        authUserData,
        jumpTo,
        updateWizardSnapshot
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const [creditReportResponse, setCreditReportResponse] = useState({});
    const [dataForComponent, updateDataForComponent] = useState({});
    const { authHeader } = useAuthentication();
    const [overlappingException, setOverlappingException] = useState(false);
    const [isRetrievingReports, setIsRetrievingReports] = useState(false);
    const [isLossReportNoHit, setIsLossReportNoHit] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [showPrayerForJudgementError, setShowPrayerForJudgementError] = useState(false);
    const [visibleFields, updateVisibleFields] = useState([]);
    const [activeTab, updateActiveTab] = useState('');
    const changeSummaryIndex = _findIndex(steps, ({ path }) => path === '/change-summary');
    const renewalSource = _get(renewalVM, 'value.renewalSource', '');
    const renewalReason = _get(renewalVM, 'value.renewalReason', '');
    const policyState = _get(renewalVM, 'baseData.policyAddress.state.value.code');
    const {opCo} = useContext(AmfamOktaTokenContext);
    const {
        initialValidation,
        onValidate,
        isComponentValid,
        disregardFieldValidation,
        registerComponentValidation,
        registerInitialComponentValidation
    } = useValidation('RiskAnalysisPage');
    const {
        modifyDates
    } = usePriorPolicyUpdateUtil(renewalVM.lobData.personalAuto_EA.priorPolicyUpdates);

    useEffect(() => {
        const initialVisibleFields = ['declareLossStatementThreeYears', 'declareLossStatementFiveYears']; // Fields to look up by partner/state

        updateVisibleFields(
            isRequired(initialVisibleFields, requiredMetadata, policyState, opCo)
        );
        // User can't able to change policy state on Risk Analysis Page,
        // hence we need to run this useEffect only single time.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opCo]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);

    const {
        hasUWIssues
    } = useUWIssueUtil(
        renewalVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const {
        getLandingPageIndexForQuotedJob,
        getDefaultTab
    } = useLandingPageUtil();
    
    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(renewalVM, 'lobData.personalAuto_EA.premiumStability.value') !== undefined;

    const defaultTabBasedOnReason = useMemo(
        () => getDefaultTab(LOB, renewalSource, renewalReason),
        [getDefaultTab, renewalSource, renewalReason]
    );

    const saveAndQuote = useCallback(
        async (_, calledFromOnSave = false) => {
            if (!isComponentValid || showPrayerForJudgementError) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            modifyDates(renewalVM);
            setIsSavingRenewal(true);

            const saveResponse = await RenewalService.saveRenewal(
                [renewalVM.value],
                authHeader
            );

            _set(renewalVM, 'value', saveResponse);
            modifyDates(renewalVM);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            _set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);

            if (!calledFromOnSave) {
                const hasConflicts = !_isEmpty(_get(renewalVM, 'value.conflicts', []));

                if (hasConflicts) {
                    let newLandingPageIndex = -1;

                    newLandingPageIndex = getLandingPageIndexForQuotedJob(
                        LOB,
                        steps
                    );

                    if (newLandingPageIndex >= 0) {
                        jumpTo(newLandingPageIndex, true);
                    }

                    setIsSavingRenewal(false);

                    return false;
                }
            }

            setIsSavingRenewal(false);

            return renewalVM;
        },
        [isComponentValid, showPrayerForJudgementError, modifyDates, renewalVM, authHeader, updateWizardData, getLandingPageIndexForQuotedJob, steps, jumpTo]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote(undefined, true);

                const fieldIssues = _get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = _get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, renewalVM, updateWizardSnapshot]
    );

    const isPageValid = useCallback(() => !overlappingException && !isLossReportNoHit, [isLossReportNoHit, overlappingException]);

    const uwIssuesPresent = useMemo(() => 
         hasUWIssues()
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    , [renewalVM?.errorsAndWarnings_Ext?.underwritingIssues?.value]);

    const defaultActiveTab = useMemo(() => {
        if (uwIssuesPresent) {
            return 'uwIssuesTabID';
        }

        return defaultTabBasedOnReason === false ? 'priorCarrierTabID' : defaultTabBasedOnReason;
    }, [uwIssuesPresent, defaultTabBasedOnReason]);

    const createAutoLossVM = useCallback((vmObject, dtoName) => {
        const vmNode = viewModelService.create(
            vmObject,
            'pc',
            dtoName
        );

        return vmNode;
    }, [viewModelService]);

    useEffect(() => {
        if (!isSkipping) {
            setIsRetrievingReports(true);

            const AutoLossRecordsPromise = AutoLossService.loadAutoLosses(
                _get(renewalVM, 'jobID.value'),
                authHeader
            );

            AutoLossRecordsPromise
                .then((response) => {
                    const autoLossRecords = [];

                    response.autoLossRecords.filter((record)=>record.source.sourceType !== 'Self-Declared').forEach((result) => {
                        const recordVM = createAutoLossVM(
                            result,
                            'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoLossRecordDTO'
                        );

                        autoLossRecords.push(recordVM.value);
                    });

                    const autoViolationRecords = [];

                    response.autoViolationRecords.filter((record)=>record.source.sourceType !== 'Self-Declared').forEach((result) => {
                              const violationVM = createAutoLossVM(
                            result,
                            'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoViolationRecordDTO'
                        );

                        _set(
                            violationVM,
                            'value.operatorDisplayName',
                            `${violationVM?.value?.assignment?.firstName} ${violationVM?.value?.assignment?.lastName}`
                        );
                        autoViolationRecords.push(violationVM.value);
                    });

                    _set(dataForComponent, 'lobData.personalAuto_EA.autoLossRecords.value', autoLossRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.autoViolationRecords.value', autoViolationRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.mvrLicenseStatusRecords.value', response.mvrlicenseStatus);
                    _set(dataForComponent, 'lobData.personalAuto_EA.orderingInfo.value', response.orderingInfo);
                    updateDataForComponent(dataForComponent);
                })
                .catch(() => {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: messages.reportsErrorTitle,
                        message: messages.reportsErrorMessage
                    });
                    setIsLossReportNoHit(true);
                })
                .finally(() => {
                    setIsRetrievingReports(false);
                });
        }
        //   No array dependencies needed in this hook.
        //   The logic of initializing losses data needs to be executed only once
        //   when landing into losses page.
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping]);

    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            modifyDates(renewalVM);
            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            _set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setIsSavingRenewal(false);

            return false;
        },
        [
            authHeader, changeSummaryIndex, isComponentValid, jumpTo,
            modifyDates, renewalVM, updateWizardData, updateWizardSnapshot
        ]
    );

    useEffect(() => {
        //   register validation for disabling next button
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, registerInitialComponentValidation, renewalVM]);

    /**
      * Helper memo for dynamically generating the loading indicator message.
      */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isRetrievingReports) {
            loadingMessage = translator(messages.loading);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (isSavingRenewal) {
            loadingMessage = translator(messages.ratingYourPolicyMessage);
        } else if (isSkipping) {
            loadingMessage = translator(messages.loadingNextPageMessage);
        }

        return loadingMessage;
    }, [isRetrievingReports, isSavingCurrentPageChanges, isSavingRenewal, isSkipping, translator]);

    const onChangePrayerForJudgement = useCallback(() => {
        // Get all manual added violations
        const manualViolationRecords = _get(renewalVM, 'lobData.personalAuto_EA.manualViolationRecords.value', []);
        const selectedPrayerForJudgementOperators = [];

        manualViolationRecords.forEach((violationRecord) => {
            // Add to selected driver list when prayer for judgement is selected
            if (_get(violationRecord, 'assignment.publicId') && violationRecord.prayerForJudgementInd) {
                selectedPrayerForJudgementOperators.push(_get(violationRecord, 'assignment.publicId'));
            }
        });
        // Show error message if driver with prayer for judgement indicator appears more than one time
        setShowPrayerForJudgementError(selectedPrayerForJudgementOperators.length !== _uniq(selectedPrayerForJudgementOperators).length);
    }, [renewalVM, setShowPrayerForJudgementError]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        riskAnalysisPageLoadingIndicator: {
            loaded: !isSavingRenewal && !isSkipping
                && !isSavingCurrentPageChanges,
            text: getLoadingIndicatorMessage
        },
        RiskAnalysisPageContainer: {
            visible: !isSavingRenewal && !isSkipping
                && !isSavingCurrentPageChanges,
            defaultActiveTab,
            onTabChange: (tabId) => {
                updateActiveTab(tabId);
            },
            activeTab: activeTab !== '' ? activeTab : defaultActiveTab
        },
        EAPriorCarrierComponentId: {
            submissionVM: renewalVM,
            disregardFieldValidation,
            viewOnlyMode: true,
            showErrors: isPageSubmitted,
            showPriorCarrierEffectiveDateMessage: false
        },
        EALossAndViolationComponentId: {
            lobDataModel: renewalVM.lobData.personalAuto_EA,
            onModelChange: () => updateWizardData(renewalVM),
              viewModelService,
            disregardFieldValidation,
            operators: renewalVM.lobData.personalAuto_EA.coverables.drivers?.value?.map((driver) => ({
                    publicId: driver.publicID,
                    displayName: driver.person.displayName
                })),
            viewOnlyMode: false,
            showErrors: isPageSubmitted,
            policyState,
            authUserData,
            isVerified: true,
            periodStartDate: renewalVM.baseData.periodStartDate,
            showPrayerForJudgementError,
            onChangePrayerForJudgement,
            updateIsPageSubmitted
        },
        EARiskAnalysisMiscComponentId: {
            submissionVM: renewalVM,
            viewOnlyMode: true,
            isSkipping,
            onValidate,
            showHousehold: false,
            shouldShowCedingField: policyState === 'NC'
        },
        EACreditReportsComponentId: {
            submissionVM: renewalVM,
            creditReportResponse,
            setCreditReportResponse,
            updateWizardData,
            lineOfBusiness: renewalVM.lobData.personalAuto_EA,
            viewOnlyMode: false,
            lobName: 'personalAuto_EA',
            authUserData,
            id: 'EACreditReportsComponentId',
            onValidate,
            setOverlappingException
        },
        vehicleReportTabID: {
            viewOnlyMode: true
        },
        lossesAndViolationID: {
            visible: !_isEmpty(dataForComponent),
            lossesAndViolations: dataForComponent,
            updateBaseDataComponent: updateDataForComponent,
            quoteId: _get(renewalVM, 'jobID.value'),
            drivers: renewalVM.lobData.personalAuto_EA.coverables.drivers,
            policyState,
            jobType: _get(renewalVM, 'value.baseData.jobType')
        },
        EAUWIssuesComponentId: {
            submissionVM: renewalVM,
            updateWizardData,
            authHeader
        },
        billingMiscellaneousReportComponent: {
            visible: authUserData?.permissions_Ext.includes('canviewbillingreport'),
            data: _get(renewalVM,'value.baseData.cancelActivities_Ext',[])
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(renewalVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            onValidate,
            showErrors: isPageSubmitted,
            viewOnlyMode: false
        },
        declareStatementThreeYears: {
            visible: visibleFields.includes('declareLossStatementThreeYears')
        },
        declareStatementFiveYears: {
            visible: visibleFields.includes('declareLossStatementFiveYears')
        },
        noMiscReportResultsMessageId: {
            visible: (() => 
                // If misc report content is empty 
                 !_get(document.getElementById('miscellaneousReportsBodyId'), 'innerText') 
                    && !_get(document.getElementById('billingMiscellaneousReportComponent'), 'innerText')
            )()
        }
    };

     const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <WizardPage
            onNext={saveAndQuote}
            skipWhen={initialValidation}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(RiskAnalysisPage);
