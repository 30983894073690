import { defineMessages } from 'react-intl';

export default defineMessages({
    suffixOptional: {
        id: 'quoteandbind.eh.views.eh-Suffix.optional',
        defaultMessage: 'Optional'
    },
    editContactWarning: {
        id: 'e1p.gateway.views.new-quote.Edit Contact Warning',
        defaultMessage: 'You are editing this contact. If you would like to change to another exiting contact, please click the "Change To" button and select another existing contact. If you would like to create a new contact, please click the "Add" button.'
    }
});
