import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class CreditService {
  /**
   * Fetch Credit reports
   * @param {String} jobNumber String
   * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
   * @returns {Promise} a promise from the backend call with no result (if successful)
   */
  static getCreditReport = (jobNumber, additionalHeaders = {}) => {
      return JsonRPCService.send(
          getProxiedServiceUrl('credit'),
          'getCreditReport',
          [jobNumber],
          additionalHeaders
      );
  }

  static getCreditDetailsForPolicy = (jobNumber, additionalHeaders = {}) => {
      return JsonRPCService.send(
          getProxiedServiceUrl('credit'),
          'getCreditDetailsForPolicy',
          [jobNumber],
          additionalHeaders
      );
  }

  static forceOrderCreditReport = (jobNumber, namedInsured, additionalHeaders = {}) => {
      return JsonRPCService.send(
          getProxiedServiceUrl('credit'),
          'forceOrderCreditReport',
          [jobNumber, namedInsured],
          additionalHeaders
      );
  }

  /**
   * Adds a credit reconsideration for the next renewal.
   * @param {String} jobNumber String
   * @param {String} policyNumber String
   * @param {String} effectiveDate Date
   * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
   * @returns {Promise} a promise from the backend call with no result (if successful)
   */
  static orderCreditReconsideration = (
      jobNumber,
      policyNumber,
      effectiveDate,
      additionalHeaders = {}
  ) => {
      return JsonRPCService.send(
          getProxiedServiceUrl('credit'),
          'orderCreditReconsideration',
          [jobNumber, policyNumber, effectiveDate],
          additionalHeaders
      );
  }
}
