import React, {
    useContext, useCallback, useState, useMemo, useEffect
} from 'react';
import {
    findIndex as _findIndex,
    get as _get,
    find as _find,
    isNil as _isNil,
    set as _set,
    cloneDeep as _cloneDeep,
    isEmpty as _isEmpty,
    isUndefined as _isUndefined,
    includes as _includes
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { TPIUtil, OfferingUtil, QuoteProposalUtil, PropertyFlowUtil, PaymentPageUtil } from 'e1p-portals-util-js';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { PropertyLossService, InspectionService, PolicyDetailsService } from 'e1p-capability-gateway';
import { PaymentHelper } from 'e1p-capability-payment';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { isRequired } from 'e1p-portals-required-validator-js';
import { useUWIssueUtil, useTPIUtil, e1pUserAccessUtil } from 'e1p-capability-hooks';
import {
    parseErrors
} from '@xengage/gw-portals-edge-validation-js';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { error as loggerError } from '@jutro/logger';
import appConfig from 'app-config';
import requiredMetadata from './PaymentDetailsPage.requiredness';
// import AdditionalInfo from './AdditionalInfo/AdditionalInfo';
import metadata from './PaymentDetailsPage.metadata.json5';
import messages from './PaymentDetailsPage.messages';
import EHReports from './Reports/EHReports';

function initialiseVM(submissionVM) {
    const { selectedOfferingIndex } = OfferingUtil.getSelectedOffering(submissionVM, 'homeowners_EH');

    _set(
        submissionVM,
        `lobData.homeowners_EH.offerings.children[${selectedOfferingIndex}].paymentPlans.value`,
        _get(submissionVM, `lobData.homeowners_EH.offerings.children[${selectedOfferingIndex}].paymentPlans.value`) || {}
    );
}

// need to toggle paymentus per env alignment
const environment = _get(appConfig, 'env.AMFAM_ENV', 'local');
const envsWithoutPayment = ['dev', 'local', 'sbx', 'dev2', 'int2', 'qa2', 'dev3', 'gwupgrade1', 'int', 'perf2'];
const requiresPayment = !envsWithoutPayment.includes(environment);
// Integrations not set up
const ignoreValidation = ['int2', 'qa2', 'dev3'].includes(environment);
const statesNotIncludingAutoLosses = appConfig.statesNotIncludingAutoLosses ?? [];
const INITIAL_PAYMENT_OPTION_PATH = 'baseData.value.conversionDTO.initialPaymentType';

function PaymentDetailsPage(props) {
    const modalApi = useModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        wizardContext,
        steps,
        jumpTo,
        updateWizardSnapshot,
        wizardSnapshot,
        disableAllSteps,
        isSkipping
    } = props;

    const { LoadSaveService } = useDependencies('LoadSaveService');
    const [isCallingService, setIsCallingService] = useState(false);
    const [signatureSuccess, setSignatureSuccess] = useState(false);
    const [isQuoting, setIsQuoting] = useState(false);
    const [isShowingPopup, setIsShowingPopup] = useState(false);
    const [isCallingCanPurchase, setIsCallingCanPurchase] = useState(false);
    const [isVMInitialised, updateIsVMInitialised] = useState(false);
    const [bindOperationsInProcess, setBindOperationsInProcess] = useState(false);
    const { authHeader, authUserData } = useAuthentication();
    const { isComponentValid, onValidate, registerComponentValidation } = useValidation('PaymentDetailsPage');
    const [underwritingIssuesPopupAppeared, setUnderwritingIssuesPopupAppeared] = useState(false);
    const [keepShowingIframeAfterPayment, setKeepShowingIframeAfterPayment] = useState(false);
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);
    const [fieldIssue, setFieldIssue] = useState(false);
    const translator = useTranslator();
    const amfamOktaToken = useContext(AmfamOktaTokenContext);
    const PAY_MONTHLY = 'af:payMthly3';
    const TPI_FULL_PAY = 'af:tpiFullPayR1';
    const [isBillingIdTPIFullPay, setIsBillingIdTPIFullPay] = useState(false);
    const [isConvertToTPIPayor, setIsConvertToTPIPayor] = useState(false);
    const policyType = _get(submissionVM, 'lobData.homeowners_EH.policyType.value.code');
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isMailingComponentValid, updateIsBillingComponentValid] = useState(true);
    const [isBillingComponentValid, updateIsMailingComponentValid] = useState(true);
    const [isInsuredComponentValid, updateIsInsuredComponentValid] = useState(true);
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [requiredFields, updateRequiredFields] = useState([]);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const [selectedPaymentOption, setSelectedPaymentOption] = useState(_get(submissionVM, INITIAL_PAYMENT_OPTION_PATH));
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    // It is used to show loader message 'Preparing Required Documents' when gettingSignatureDocument is true
    const [gettingSignatureDocument, setGettingSignatureDocument] = useState(false);
    // It is used to show loader message 'Saving the Insureds contact information' when isInsuredContactUpdating is true
    const [isInsuredContactUpdating, setIsInsuredContactUpdating] = useState(false);
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);

    const { jumptToTPIPageIfTPIOfTrustConditionFails } = useTPIUtil();
    const {
        hasUWIssues
    } = useUWIssueUtil(
        submissionVM,
        updateWizardData,
        jumpTo,
        steps
    );
    const LOB = 'homeowners_EH';
    const inspectionCodes = {
        EXTERIOR: 'Exterior',
        INTERIORANDEXTERIOR: 'InteriorAndExterior',
        INTERIOR: 'Interior'
    };
    const isPaymentDone = !!_get(submissionVM, 'bindData.value.paymentRefNumber');
    const isHomePhoneExist = !!_get(submissionVM, 'lobData.homeowners_EH.primaryNamedInsured.person.homeNumber.value');
    const isMobilePhoneExist = !!_get(submissionVM, 'lobData.homeowners_EH.primaryNamedInsured.person.cellNumber.value');
    const inspectionType = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.inspectionDetails.inspectionType.value');
    const isEHInspectionInterior = [inspectionCodes.INTERIORANDEXTERIOR, inspectionCodes.INTERIOR].includes(inspectionType?.code);
    const productCode = _get(submissionVM, 'baseData.productCode.value');
    const tpiPath = TPIUtil.getTPIBasePath(productCode);

    const allTPIs = _get(submissionVM, `${tpiPath}.value`, []);

    const isBillMortgageeSelected = !!_find(allTPIs, (tpi) => tpi.convertTPIToPayerInd || tpi.sendBillToTPIInd);
    const isSignatureStatusAsCompleted = _get(submissionVM, 'value.baseData.signature_Ext.status') === 'COMPLETED';
    const canUserIssueWithoutPayment = authUserData.permissions_Ext.includes('issuewithoutpayment_ext');

    const { opCo } = amfamOktaToken;
    const { isUserAuthorisedToPayAndBind } = e1pUserAccessUtil(authUserData, opCo);
    const canUserPayAndBindQuote = isUserAuthorisedToPayAndBind();

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();

            if (quoteProposalLink) {
                window.open(quoteProposalLink, '_blank');

                return true;
            }

            return false;
        }, [quoteProposalLink]
    );

    // When payment option changes, we need to get a new doc url
    useEffect(() => {
        if (submissionVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            submissionVM.paymentOptionChanged = false;
        }
    }, [authHeader, submissionVM, submissionVM.paymentOptionChanged]);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isBillingComponentValid && isMailingComponentValid && isPageSubmitted && isInsuredComponentValid) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isPageSubmitted, isBillingComponentValid, isMailingComponentValid, isInsuredComponentValid]);


    /**
     * Initialization code
     */
    useEffect(() => {
        // on mount get quote proposal
        QuoteProposalUtil.fetchQuoteProposal(
            submissionVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );

        const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');
        const initialRequiredFields = ['consentToRateAcknowledgement']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        () => {
            const { selectedOfferingIndex } = OfferingUtil.getSelectedOffering(submissionVM, LOB);
            //  Checks whether billingID of selected payment plan is TPIFullPay or not
            //  returns true if billingID is TPIFullPay
            const selectedPlan = _get(
                submissionVM, `lobData.homeowners_EH.offerings.children[${selectedOfferingIndex}].paymentPlans.value`, []
            ).find((plan) => plan.isSelected);

            setIsBillingIdTPIFullPay(selectedPlan?.billingId === TPI_FULL_PAY);

            const shouldConvertTpi = PropertyFlowUtil.shouldConvertTPIToPayer(submissionVM);

            if (shouldConvertTpi) {
                setIsConvertToTPIPayor(true);
            } else {
                setIsConvertToTPIPayor(false);
            }
        }, [submissionVM]
    );

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = _get(submissionVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmail = _get(submissionVM, 'lobData.homeowners_EH.paperlessEmail.value');

        return isPaperlessEmailUpdated && pniEmail && pniEmail !== paperlessEmail;
    }, [submissionVM, isPaperlessEmailUpdated]);

    const showPopupIfUWIssueExists = useCallback(() => {
        updateWizardData(submissionVM);

        if (hasUWIssues() && !underwritingIssuesPopupAppeared) {
            setUnderwritingIssuesPopupAppeared(true);

            const indexForCoveragePage = _findIndex(steps, ({ path }) => path === '/coverages');

            jumpTo(indexForCoveragePage);

            return true;
        }

        return false;
    }, [hasUWIssues, jumpTo, steps, submissionVM, underwritingIssuesPopupAppeared, updateWizardData]);

    const checkForInPageErrors = useCallback(
        (qdd) => {
            const errorsAndWarnings = _cloneDeep(_get(qdd, 'errorsAndWarnings', {}));

            // uw goes in pop up; not top banner
            delete errorsAndWarnings.underwritingIssues;

            const exceptionsPath = 'baseData.exceptions_Ext';
            const errors = parseErrors(errorsAndWarnings);
            const exceptions = _get(qdd, exceptionsPath, []);
            const errorConvertingToFullApp = errors?.length > 0 || exceptions.length > 0;

            if (errorConvertingToFullApp) {
                setValidationErrors(errors.concat(exceptions));
            }
        },
        [],
    );

    const checkCanPurchase = useCallback(async () => {
        if (!isSkipping
            && submissionVM.value.quoteType === 'Full'
            // Check if period status for submission is quoted to call canPurchase or  disable steps
            && submissionVM.baseData.periodStatus.value.code === 'Quoted') {
            if (isPaymentDone && requiresPayment) {
                disableAllSteps();
            }

            setIsCallingCanPurchase(true);

            const response = await LoadSaveService.canPurchase(submissionVM.value, authHeader);

            // have to set uw issues from the can purchase so the util can see them
            _set(
                submissionVM.value,
                'errorsAndWarnings.underwritingIssues',
                response.errorsAndWarnings.underwritingIssues
            );

            // this is to hide paymentus component if there is any validation error
            const fieldError = _get(response, 'errorsAndWarnings.validationIssues.fieldIssues', []);

            setFieldIssue(!!(fieldError.length));

            const licensedProducers = _get(response, 'licensedProducers.licensedProducers', []);

            _set(submissionVM.value, 'licensedProducers', response.licensedProducers);
            // check for validation errors
            checkForInPageErrors(response);
            // update wizard data with any uw issues and show if exists
            updateWizardData(submissionVM);

            const uwIssue = await showPopupIfUWIssueExists();

            if (uwIssue) {
                setIsShowingPopup(false);

                // if UW issues we jump to coverage, code execution here should stop
                return false;
            }

            // set producer code options for signin agents
            if (licensedProducers.length > 0) {
                setProducerCodeOptions(
                    licensedProducers.map((value) => ({
                            code: value.producerID,
                            name: `${value.producerName.firstName} ${value.producerName.lastName}`
                        }))
                );
            }

            setIsCallingCanPurchase(false);

            return true;
        }

        return true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping, submissionVM.value.quoteType, submissionVM.baseData.periodStatus.value.code]);

    useEffect(() => {
        if (!isVMInitialised) {
            initialiseVM(submissionVM);
            updateIsVMInitialised(true);
        }
    }, [isVMInitialised, submissionVM]);

    const setErrors = useCallback((removeUnnecessaryErrors = false) => {

        const shouldShowMissingPaymentError = requiresPayment && !isPaymentDone && selectedPaymentOption !== 'PayLater'
            && !(isBillingIdTPIFullPay || isConvertToTPIPayor) && !canUserIssueWithoutPayment;

        const isSignatureMissing = _get(submissionVM, 'baseData.signatureType_ext.value') === undefined || !signatureSuccess;
        const isUwIssuePresent = hasUWIssues();

        const uniqueErrors = PaymentPageUtil.getValidationErrorsForPaymentPage(
            validationErrors, shouldShowMissingPaymentError, isSignatureMissing, isUwIssuePresent, translator, removeUnnecessaryErrors);

        setValidationErrors(uniqueErrors);
    }, [
        hasUWIssues, isPaymentDone, signatureSuccess,
        submissionVM, translator, validationErrors,
        selectedPaymentOption, isBillingIdTPIFullPay,
        isConvertToTPIPayor, canUserIssueWithoutPayment
    ]);

    useEffect(() => {
        if (signatureSuccess || isPaymentDone) {
            setErrors(true);
        }
        // want to trigger this only on signatureSuccess update and payment update
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureSuccess, isPaymentDone])

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            setErrors();
            window.scrollTo(0, 0);

            return false;
        }

        setBindOperationsInProcess(true);

        // If book transfer indicator is yes, and initial payment option has changed - save it before binding it
        if (bookTransferIndicator && _get(submissionVM, INITIAL_PAYMENT_OPTION_PATH) !== selectedPaymentOption) {
            _set(submissionVM, INITIAL_PAYMENT_OPTION_PATH, selectedPaymentOption);

            submissionVM.value = await LoadSaveService.updateQuotedSubmission(
                submissionVM.value,
                authHeader
            );
            updateWizardData(submissionVM);

            const errorsAndWarnings = _get(submissionVM, 'value.errorsAndWarnings', {});
            const errors = parseErrors(errorsAndWarnings);
            const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

            // In case of error on updating quoted submission, show errors and do not bind
            if (errors.length || exceptions.length) {
                setValidationErrors(errors.concat(exceptions));
                setBindOperationsInProcess(false);

                return false;
            }
        }

        const quoteData = _get(submissionVM, 'quoteData');

        if (!requiresPayment) {
            const { paymentReferenceNumberForLocal } = appConfig.amfamRewritePaymentConfig;

            _set(submissionVM, 'bindData.value.paymentRefNumber', paymentReferenceNumberForLocal);
        }

        const response = await LoadSaveService.bindSubmission(submissionVM.value, authHeader);

        response.quoteData = quoteData.value;
        submissionVM.value = response;
        updateWizardData(submissionVM);

        const errorsAndWarnings = _get(submissionVM, 'value.errorsAndWarnings', {});
        const errors = parseErrors(errorsAndWarnings);
        const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

        // In case of error on bind submission, show errors and do not bind
        if (errors.length || exceptions.length) {
            setValidationErrors(errors.concat(exceptions));
            setBindOperationsInProcess(false);

            return false;
        }

        setBindOperationsInProcess(false);

        return submissionVM;
    }, [LoadSaveService, authHeader, isComponentValid, setErrors, submissionVM, updateWizardData, selectedPaymentOption, bookTransferIndicator]);

    const getDetails = useCallback(async () => {
        let responseAuto;
        const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');

        if (!statesNotIncludingAutoLosses.includes(policyState)) {
            responseAuto = await PropertyLossService.loadAutoLosses(
                _get(submissionVM, 'quoteID.value'),
                authHeader
            );
        }

        const responseHome = await PropertyLossService.loadPropertyLosses(
            _get(submissionVM, 'quoteID.value'),
            authHeader
        );
        const componentProps = {
            iconClassType: false,
            showCloseBtn: true,
            showCancelBtn: true,
            submissionVM,
            authHeader,
            updateWizardData,
            prefillAutoData: responseAuto,
            prefillData: responseHome,
            wizardContext,
            steps,
            jumpTo
        };

        setIsShowingPopup(false);

        return modalApi.showModal(<EHReports {...componentProps} />);
    }, [authHeader, jumpTo, modalApi, steps, submissionVM, updateWizardData, wizardContext]);

    const convertToFullApp = useCallback(async () => {
        setIsQuoting(true);

        // Defaulting autoPayDiscInd indicator to false
        if (_get(submissionVM, 'lobData.homeowners_EH.autoPayDiscInd.value') === undefined) {
            _set(submissionVM, 'lobData.homeowners_EH.autoPayDiscInd.value', false);
        }

        submissionVM.value = await LoadSaveService.convertToFullApp(
            submissionVM.value,
            authHeader
        );

        const underwritingIssues = _get(submissionVM, 'errorsAndWarnings.underwritingIssues.value');

        checkForInPageErrors(submissionVM.value);
        _set(
            submissionVM.value,
            'errorsAndWarnings.underwritingIssues',
            underwritingIssues
        );
        updateWizardData(submissionVM);

        const fieldError = _get(submissionVM.value, 'errorsAndWarnings.validationIssues.fieldIssues', []);
        const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

        if (_isEmpty(fieldError) && _isEmpty(exceptions)) {
            updateWizardSnapshot(submissionVM);
        }

        setIsQuoting(false);
    }, [LoadSaveService, authHeader, checkForInPageErrors, submissionVM, updateWizardData, updateWizardSnapshot]);

    const getDetailsForHO6 = useCallback(async () => {
        const responseHome = await PropertyLossService.loadPropertyLosses(
            _get(submissionVM, 'quoteID.value'),
            authHeader
        );

        setIsShowingPopup(false);

        const componentProps = {
            iconClassType: false,
            showCloseBtn: true,
            showCancelBtn: true,
            submissionVM,
            authHeader,
            updateWizardData,
            prefillData: responseHome,
            wizardContext,
            steps,
            jumpTo
        };

        return modalApi.showModal(<EHReports {...componentProps} />);
    }, [authHeader, jumpTo, modalApi, steps, submissionVM, updateWizardData, wizardContext]);

    const getDetailsForHO4 = useCallback(async () => {
        let responseAuto;
        const policyState = _get(submissionVM, 'baseData.policyAddress.state.value.code');

        if (!statesNotIncludingAutoLosses.includes(policyState)) {
            responseAuto = await PropertyLossService.loadAutoLosses(
                _get(submissionVM, 'quoteID.value'),
                authHeader
            );
        }

        const responseHome = await PropertyLossService.loadPropertyLosses(
            _get(submissionVM, 'quoteID.value'),
            authHeader
        );

        setIsShowingPopup(false);

        const componentProps = {
            iconClassType: false,
            showCloseBtn: true,
            showCancelBtn: true,
            submissionVM,
            authHeader,
            updateWizardData,
            prefillAutoData: responseAuto,
            prefillData: responseHome,
            wizardContext,
            steps,
            jumpTo
        };

        return modalApi.showModal(<EHReports {...componentProps} />);
    }, [authHeader, jumpTo, modalApi, steps, submissionVM, updateWizardData, wizardContext]);

    const orderReports = useCallback(async () => {
        const jumpingPage = showPopupIfUWIssueExists();

        if (jumpingPage) {
            setIsShowingPopup(false);

            // if UW issues we jump to coverage, code execution here should stop
            return false;
        }

        // E1PAP1PC-14396 the inspection information is also needed when we do not show additional info
        // All the reports are requested
        const inspectionDetails = await InspectionService.runInspection(_get(submissionVM, 'quoteID.value'), authHeader);

        _set(
            submissionVM,
            'lobData.homeowners_EH.coverables.yourHome.inspectionDetails',
            inspectionDetails
        );
        // E1PAP1PC-14445 forcing an update because we would like this information retained
        // when we go back in the flow and snapshot is only updated onNext
        updateWizardSnapshot(submissionVM);

        // call can purchase before reports
        const canProceedFurther = await checkCanPurchase();

        if (!canProceedFurther) {
            setIsShowingPopup(false);

            // if UW issues we jump to coverage, code execution here should stop
            return false;
        }

        const hasUWIssue = hasUWIssues();
        const fullAppNotQuoted = submissionVM.value.quoteType === 'Full'
            && submissionVM.baseData.periodStatus.value.code !== 'Quoted';

        /**
            E1PAP1PC-12515 :
            If we get uw issue in convertToFullApp and period is converted to full
            but period is in draft  status then canpurchase will not show uw issue
            in this case we want show uw issue and also report page
            E1PAP1PC-14131 : show reports popup only if its full app
         */
        if (
            (!hasUWIssue
                || (hasUWIssue && fullAppNotQuoted))
            && submissionVM.value.quoteType === 'Full'
        ) {
            await getDetails();
            // IAP-1278
            // jumping to tpi page if tpi of trust condition fails
            jumptToTPIPageIfTPIOfTrustConditionFails(submissionVM,
                jumpTo,
                steps,
                '/third-party-interest');
        } else {setIsShowingPopup(false);}

        setIsCallingService(false);

        return true;
    }, [authHeader, checkCanPurchase, getDetails, hasUWIssues, jumpTo, jumptToTPIPageIfTPIOfTrustConditionFails, showPopupIfUWIssueExists, steps, submissionVM, updateWizardSnapshot])

    const callReportsAndConvertToFullQuote = useCallback(async () => {
        if (policyType === 'HO3' || policyType === 'HF9') {
            // this is being set back to false in the getdetails
            //   not a fan of this
            setIsShowingPopup(true);
            convertToFullApp()
                .then(async () => {
                    await orderReports();
                })
                .catch(() => {
                    setIsQuoting(false);
                    setIsShowingPopup(false);
                });
        } else if (policyType === 'HO6') {
            setIsShowingPopup(true);
            convertToFullApp()
                .then(async () => {
                    const jumpingPage = showPopupIfUWIssueExists();

                    if (jumpingPage) {
                        setIsShowingPopup(false);

                        // if UW issues we jump to coverage, code execution here should stop
                        return false;
                    }

                    const canProceedFurther = await checkCanPurchase();

                    if (!canProceedFurther) {
                        setIsShowingPopup(false);

                        // if UW issues we jump to coverage, code execution here should stop
                        return false;
                    }

                    const hasUWIssue = hasUWIssues();
                    const fullAppNotQuoted = submissionVM.value.quoteType === 'Full'
                        && submissionVM.baseData.periodStatus.value.code !== 'Quoted';

                    /**
                        E1PAP1PC-12515 :
                        If we get uw issue in convertToFullApp and period is converted to full
                        but period is in draft  status then canpurchase will not show uw issue
                        in this case we want show uw issue and also report page
                        E1PAP1PC-14131 : show reports popup only if its full app
                     */
                    if (
                        (!hasUWIssue
                            || (hasUWIssue && fullAppNotQuoted))
                        && submissionVM.value.quoteType === 'Full'
                    ) {
                        await getDetailsForHO6();
                    } else {setIsShowingPopup(false);}

                    setIsCallingService(false);

                    return true;
                })
                .catch(() => {
                    setIsQuoting(false);
                    setIsShowingPopup(false);
                });
        } else if (policyType === 'HO4') {
            setIsShowingPopup(true);
            convertToFullApp()
                .then(async () => {
                    const jumpingPage = showPopupIfUWIssueExists();

                    if (jumpingPage) {
                        setIsShowingPopup(false);

                        // if UW issues we jump to coverage, code execution here should stop
                        return false;
                    }

                    const canProceedFurther = await checkCanPurchase();

                    if (!canProceedFurther) {
                        setIsShowingPopup(false);

                        // if UW issues we jump to coverage, code execution here should stop
                        return false;
                    }

                    const hasUWIssue = hasUWIssues();
                    const fullAppNotQuoted = submissionVM.value.quoteType === 'Full'
                        && submissionVM.baseData.periodStatus.value.code !== 'Quoted';

                    /**
                        E1PAP1PC-12515 :
                        If we get uw issue in convertToFullApp and period is converted to full
                        but period is in draft  status then canpurchase will not show uw issue
                        in this case we want show uw issue and also report page
                        E1PAP1PC-14131 : show reports popup only if its full app
                     */
                    if (
                        (!hasUWIssue
                            || (hasUWIssue && fullAppNotQuoted))
                        && submissionVM.value.quoteType === 'Full'
                    ) {
                        await getDetailsForHO4();
                    } else {setIsShowingPopup(false);}

                    setIsCallingService(false);

                    return true;
                })
                .catch(() => {
                    setIsQuoting(false);
                    setIsShowingPopup(false);
                });
        }

        setIsCallingService(false);

        return submissionVM;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * When page stops skipping show UW issues or show additional info and reports
     */
    useEffect(() => {
        if (!isSkipping) {
            if (submissionVM.value.quoteType === 'Quick') {
                setIsCallingService(true);
                callReportsAndConvertToFullQuote();
            } else if (submissionVM.convertedFromQuickToFull === true) {
                setIsCallingService(true);
                orderReports();
                setIsCallingService(false);
                _set(submissionVM, 'convertedFromQuickToFull', false);
            } else {
                // IAP-1278
                // jumping to tpi page if tpi of trust condition fails
                const hasJumped = jumptToTPIPageIfTPIOfTrustConditionFails(submissionVM,
                    jumpTo,
                    steps,
                    '/third-party-interest');

                if (hasJumped) {
                    return true;
                }

                // we should call can purchase on continue quote
                //   this is getting our licensed producers
                checkCanPurchase();

                // check payment receipt if full quote and continue (never on convert from quick to full)
                if (!isPaymentDone && requiresPayment) {
                    PaymentHelper.getReceiptNumber(
                        _get(submissionVM, 'quoteID.value', submissionVM.jobID?.value), authHeader
                    ).then((referenceNumber) => {
                        // set value from receipt in case it did not get saved to PC
                        if (referenceNumber) {
                            _set(submissionVM, 'bindData.value.paymentRefNumber', referenceNumber);
                            updateWizardData(submissionVM);
                        }
                    }).catch((err) => {
                        loggerError(err);
                    });
                }

            }

            // Check book transfer status from Location Code
            const externalId = _get(submissionVM, 'baseData.externalID_Ext.value');

            if (externalId) {
                PolicyDetailsService.getProducerDetails(
                    externalId,
                    authHeader
                ).then((policyDetails) => {
                    setBookTransferIndicator(_get(policyDetails, 'bookRollIndicator', false));

                    if (!selectedPaymentOption && _get(policyDetails, 'bookRollIndicator', false)) {
                        setSelectedPaymentOption('PayLater');
                    }
                }).catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error('Error fetching book transfer indicator details', error);
                    setValidationErrors(
                        [
                            ...validationErrors,
                            {
                                level: 'LEVEL_ERROR',
                                description: translator(e1pCommonMessages.genericErrorText)
                            }
                        ]
                    );
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping]);

    const availableValuesForPaymentOptions = useMemo(() => {
        const { selectedOfferingIndex } = OfferingUtil.getSelectedOffering(submissionVM, LOB);
        const correctOffering = submissionVM.lobData.homeowners_EH.offerings.value[selectedOfferingIndex];

        if (correctOffering.paymentPlans !== undefined) {
            return correctOffering.paymentPlans
                .filter((paymentPlans) => paymentPlans.name)
                .reduce(
                    (paymentPlanMap, paymentPlan) => ({
                        ...paymentPlanMap,
                        [paymentPlan.billingId]: paymentPlan
                    }),
                    {}
                );
        }

        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(submissionVM.lobData.homeowners_EH.offerings.value)]);

    // ctrPremium should be based of selected payment plan premium
    const ctrPremium = availableValuesForPaymentOptions[
        _get(submissionVM, 'bindData.selectedPaymentPlan.value')
    ]?.total?.amount;

    // Filter the selected offering
    const selectedQuoteOffering = _get(submissionVM, 'quoteData.offeredQuotes.value').find(
        (quoteOffering) => quoteOffering.selected
    );

    const selectedOffering = _get(submissionVM, 'lobData.homeowners_EH.offerings.value').find((offering) => offering.branchCode === selectedQuoteOffering.branchCode);

    const ratePlans = _get(selectedOffering, 'ratePlans', []);

    // UseMemo to compute CTR and NCRB plan offering details if rate plan changes
    const { ctrPlanOffering, ncrbPlanOffering } = useMemo(
        () => {
            // CTR and NCRB premium details for consent to rate acknowledgement 
            const ctrOffering = ratePlans?.find((plan) => plan.ratePlanType === 'CTR');

            const ncrbOffering = ratePlans?.find((plan) => plan.ratePlanType === 'NCRB');

            return {
                ctrPlanOffering: ctrOffering,
                ncrbPlanOffering: ncrbOffering
            };
        },
        [ratePlans]
    );

    /**
    * Helper callback which opens the "Third-Party Interest" wizard page.
    */
    const openTPIPageHandler = useCallback(() => {
        const indexForTPIPage = _findIndex(steps, ({ path }) => path === '/third-party-interest');

        jumpTo(indexForTPIPage);
    }, [steps, jumpTo]);

    /**
    * Helper memo for dynamically generating the loading indicator message.
    */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isCallingService) {
            loadingMessage = translator(messages.applyingChangesMessage);
        } else if (isInsuredContactUpdating) {
            loadingMessage = translator(e1pCommonMessages.savingContactinfoMessage);
        } else if (gettingSignatureDocument) {
            loadingMessage = translator(e1pCommonMessages.prepareReqDocuments);
        } else if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (bindOperationsInProcess) {
            loadingMessage = translator(messages.completingYourPurchaseMessage);
        }

        return loadingMessage;
    }, [isCallingService, isInsuredContactUpdating, gettingSignatureDocument, isSavingCurrentPageChanges, bindOperationsInProcess, translator]);

    const onSave = useCallback(
        async () => {
            try {
                if (!isMailingComponentValid || !isBillingComponentValid || !isInsuredComponentValid) {
                    updateIsPageSubmitted(true);
                    window.scrollTo(0, 0);

                    return false;
                }

                setIsSavingCurrentPageChanges(true);

                const signatureType = _get(submissionVM.value, 'baseData.signatureType_ext', undefined);

                // We get licensedProducers in canPurchase call so storing it in variable before we do saveAndQuote
                const licensedProducers = _get(submissionVM, 'value.licensedProducers');

                if (bookTransferIndicator) {
                    _set(submissionVM, INITIAL_PAYMENT_OPTION_PATH, selectedPaymentOption);
                }

                const response = await LoadSaveService.saveAndQuoteSubmission(
                    submissionVM.value,
                    authHeader
                );

                _set(response, 'licensedProducers', licensedProducers);
                _set(submissionVM, 'value', response);
                _set(submissionVM.value, 'baseData.signatureType_ext', signatureType);
                QuoteProposalUtil.fetchQuoteProposal(
                    submissionVM,
                    setQuoteProposalCompleted,
                    setQuoteProposalLink,
                    authHeader,
                    setIsQuoteProposalFailed
                );

                const fieldError = _get(response, 'errorsAndWarnings.validationIssues.fieldIssues', []);

                setFieldIssue(!!(fieldError.length));
                // check for validation errors
                checkForInPageErrors(response);
                updateWizardData(submissionVM);

                const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldError) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        },
        [
            isMailingComponentValid, isBillingComponentValid, isInsuredComponentValid, submissionVM,
            LoadSaveService, authHeader, updateWizardData, checkForInPageErrors, updateWizardSnapshot,
            selectedPaymentOption, bookTransferIndicator
        ]
    );

    const getRiskAddress = () => {
        const { addressLine1, addressLine2, city, state, postalCode } = _get(submissionVM, 'lobData.homeowners_EH.coverables.yourHome.ehlocation.value');

        return `${addressLine1}, ${addressLine2 ? `${addressLine2}, ` : ''}${city}, ${state} ${postalCode}`;
    };

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isCallingService && !bindOperationsInProcess && !isCallingCanPurchase
            && !isQuoting && !isShowingPopup && !isSavingCurrentPageChanges && !gettingSignatureDocument
            && !isInsuredContactUpdating, [bindOperationsInProcess, gettingSignatureDocument, isCallingCanPurchase, isCallingService, isInsuredContactUpdating, isQuoting, isSavingCurrentPageChanges, isShowingPopup]);

    /**
    * Define property overrides for this Jutro component.
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false,
            showRequired: true
        },
        paymentDetailsPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: getLoadingIndicatorMessage
        },
        paymentDetailsPageContainer: {
            visible: !isCallingService && !bindOperationsInProcess && !isCallingCanPurchase
                && !isQuoting && !isShowingPopup && !isSavingCurrentPageChanges && !isInsuredContactUpdating,
            // Facing some issues, if i try to hide the container using visibility. 
            // Initially, visibility is false so all the components will be unmount 
            // when the visibility become true then all components will be mount again 
            // it will re-trigger all the react hook components so handled hiding using CSS
            className: gettingSignatureDocument ? "display-none" : "mt-8"
        },
        totalPremiumID: {
            value: !_isNil(
                availableValuesForPaymentOptions[_get(
                    submissionVM, 'bindData.selectedPaymentPlan.value'
                )
                ]
            )
                ? availableValuesForPaymentOptions[
                    _get(submissionVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined
        },
        totalPremiumWithFullPayID: {
            value: !_isNil(availableValuesForPaymentOptions[PAY_MONTHLY])
                ? availableValuesForPaymentOptions[PAY_MONTHLY].total
                : undefined
        },
        quoteProposalLinkContainer: {
            onClick: (e) => openDocument(e),
            disabled: !quoteProposalCompleted
        },
        mailingAndBillingAddressComponent: {
            transactionVM: submissionVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            viewOnlyMode: false,
            updateIsMailingComponentValid,
            updateIsBillingComponentValid,
            showErrors: isPageSubmitted
        },
        consentToRateMessage: {
            // if CTR plan exists and if CTR is selected then Consent to rate acknowledgement should be displayed with NCRB and CTR premiums
            visible: _includes(requiredFields, 'consentToRateAcknowledgement') && ctrPlanOffering && ctrPlanOffering.isSelected,
            content: translator(messages.consentToRateAcknowledgementMessage,
                {
                    NCRBPremium: ncrbPlanOffering?.totalPremium,
                    CTRPremium: ctrPremium
                }),
        },
        monthlyPaymentScheduleComponent: {
            quoteID: _get(submissionVM, 'quoteID.value', submissionVM.jobID?.value),
            authHeader,
            transactionTotalAmount: !_isNil(availableValuesForPaymentOptions[
                _get(submissionVM, 'bindData.selectedPaymentPlan.value')
            ])
                ? availableValuesForPaymentOptions[
                    _get(submissionVM, 'bindData.selectedPaymentPlan.value')
                ].total
                : undefined,
            changeInCost: submissionVM.transactionCost?.value,
            startDate: submissionVM.baseData.periodStartDate.value,
            endDate: submissionVM.baseData.periodEndDate.value,
            jobTypeCode: submissionVM.baseData.jobType.value.code,
            offerings: _get(submissionVM, 'lobData.homeowners_EH.offerings.value'),
            quoteOfferings: _get(submissionVM, 'quoteData.offeredQuotes.value', [])
        },
        paymentOptionsID: {
            submissionVM,
            authHeader,
            updateWizardData,
            LOB,
            viewOnly: isPaymentDone && requiresPayment
        },
        addThirdPartyInterestButton: {
            disabled: isPaymentDone && requiresPayment
        },
        // IAP-371 show TPI component in only read only Mode
        e1ptpiDisplayTableContainer: {
            transactionVM: submissionVM,
            authHeader,
            updateWizardData,
            setIsSavingTPI: () => { },
            showErrors: false,
            onValidate: () => { },
            disregardFieldValidationParentPage: () => { },
            viewOnlyMode: true,
            showTitle: false
        },
        displayPNIAddress: {
            readOnly: true
        },
        riskAddress: {
            value: getRiskAddress()
        },
        phoneNumberValidationDiv: {
            visible: isEHInspectionInterior && !isHomePhoneExist && !isMobilePhoneExist
        },
        paymentOptions: {
            visible: !!bookTransferIndicator
                && !isQuoting && !hasUWIssues()
                && submissionVM.value.quoteType === 'Full'
                && !isSkipping
                && !fieldIssue
                // If payment plan is tpi full pay; payment is not required
                && !(isBillingIdTPIFullPay || isConvertToTPIPayor),
            value: selectedPaymentOption,
            onValueChange: setSelectedPaymentOption,
            readOnly: isPaymentDone && requiresPayment
        },
        paymentusComponent: {
            submissionVM,
            wizardSnapshot,
            amfamAccessToken: amfamOktaToken.amfamAccessTokenHeader,
            updateWizardData,
            // Show payment component when payment is not made, submission Quote is available and has no UW issues
            visible: canUserPayAndBindQuote
                && ((!isPaymentDone && requiresPayment) || keepShowingIframeAfterPayment)
                && (bookTransferIndicator === false || selectedPaymentOption === 'PayNow')
                && !isQuoting && !hasUWIssues()
                && submissionVM.value.quoteType === 'Full'
                && !isSkipping
                && !fieldIssue
                // If payment plan is tpi full pay; payment is not required
                && !(isBillingIdTPIFullPay || isConvertToTPIPayor),
            setKeepShowingIframeAfterPayment,
            setValidationErrors,
            disableAllSteps,
            LOB
        },
        signatureComponent: {
            submissionVM,
            updateWizardData,
            authHeader,
            LOB,
            onSignatureSuccess: setSignatureSuccess,
            disabled: signatureSuccess,
            readOnly: hasUWIssues(),
            producerCodeOptions,
            setProducerCodeOptions,
            setGettingSignatureDocument,
            // Only make it visible after payment ref number is available & canPurchase call is not in progress
            // or payment plan is tpi full pay
            visible: (() => {
                let visible = true;

                // always show for tpi
                if (isBillingIdTPIFullPay || isConvertToTPIPayor || selectedPaymentOption === 'PayLater' || canUserIssueWithoutPayment) {
                    // break out of function
                    return true;
                }

                // payment not done and it was required
                if (!isPaymentDone && requiresPayment) {
                    visible = false;
                }

                // screen is loading
                if (isCallingCanPurchase) {
                    visible = false;
                }

                return visible;
            })(),
            saveLocationCodeChanges: onSave,
            checkCanPurchase,
            locationCodeEditable: true
        },
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        insuredContactInfo: {
            showPaperless: true,
            updateIsInsuredComponentValid,
            showErrors: isPageSubmitted,
            setIsPaperlessEmailUpdated,
            setIsInsuredContactUpdating
        },
        AutoPayOptionToggle: {
            visible: !isBillMortgageeSelected && selectedPaymentOption !== 'PayLater',
            disabled: isPaymentDone && requiresPayment
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        quoteProposalFailureErrorDiv: {
            visible: isQuoteProposalFailed
        },
        userNotAuthorisedErrorDiv: {
            visible: !canUserPayAndBindQuote
        }
    };

    const handleContactInfoUpdate = useCallback(async () => {
        // If paperless Email id exist and pni email id is undefiend, then update pni email to paperless email
        if (_isUndefined(_get(submissionVM.value, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1'))
            && !!_get(submissionVM.value, 'lobData.homeowners_EH.paperlessEmail')) {
            _set(submissionVM.value, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1',
                _get(submissionVM.value, 'lobData.homeowners_EH.paperlessEmail'));
        }

        // sync NI changes to householdoccupants
        //   changes to NI will not override data in the occ so must be synced
        const householdOccupants = _get(submissionVM.value, 'lobData.homeowners_EH.coverables.householdOccupants');
        const pni = _get(submissionVM.value, 'lobData.homeowners_EH.primaryNamedInsured');
        const pniOccupant = householdOccupants
            .find((occupant) => occupant.person.publicID === pni.person.publicID);

        if (pniOccupant) {
            // updates to existing pni contact
            _set(pniOccupant, 'person', pni.person);
        }

        const signatureType = _get(submissionVM.value, 'baseData.signatureType_ext', undefined);

        // contact change requires another quote
        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(
            submissionVM.value, authHeader
        );
        _set(submissionVM.value, 'baseData.signatureType_ext', signatureType);
        updateWizardSnapshot(submissionVM);
        updateWizardData(submissionVM);
    }, [LoadSaveService, authHeader, submissionVM, updateWizardData, updateWizardSnapshot]);

    const handleUpdateAutoPay = useCallback(async (value, path) => {
        /**
         *  first time autoPayDiscInd will be undefined and as we are defaulting it 
         *  to false, onValueChange will get called. This is causing concurrent data change exceptions 
         *  as convertToFullApp is also in execution at same time; so first time we will not update value here 
         *  but will update before calling convertToFullApp
         */
        if (_get(submissionVM, 'lobData.homeowners_EH.autoPayDiscInd.value') === undefined) {
            return;
        }

        setIsQuoting(true);
        _set(submissionVM, path, value);
        // auto pay ind needs to immediately because after payment is made fields will be locked
        submissionVM.value = await LoadSaveService.saveAndQuoteSubmission(submissionVM.value, authHeader);
        updateWizardData(submissionVM);
        setIsQuoting(false);
    }, [LoadSaveService, authHeader, submissionVM, updateWizardData])

    const resolvers = {
        resolveCallbackMap: {
            openTPIPageHandler,
            onValidate,
            onSaveClick: handleContactInfoUpdate,
            onUpdateAutoPay: handleUpdateAutoPay
        },
        resolveComponentMap: {
            WizardSingleErrorComponent
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps),
        [submissionVM, overrideProps]
    );

    /**
    * need to check payment reference Number and SignatureType, if ENV is not Localhost.
    * for localhost we are defaulting payment reference number
    */
    const isPageValid = useCallback(() => {
        const isQuoted = _get(submissionVM, 'baseData.periodStatus.value.code') === 'Quoted';

        const isSignatureDone = _get(submissionVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;

        // E1PAP1PC-12520
        if (isQuoted && ignoreValidation && isSignatureDone) {
            return true;
        }

        const licensedProducerSelected = _get(submissionVM, 'value.bindData.licensedProducerID', false);

        // IF payment plan is tpi full pay; payment is not required
        if (requiresPayment && !isPaymentDone && selectedPaymentOption !== 'PayLater' && !(isBillingIdTPIFullPay || isConvertToTPIPayor) && !canUserIssueWithoutPayment) {
            return false;
        }

        return isQuoted && isSignatureDone && !hasUWIssues() && licensedProducerSelected;
    }, [hasUWIssues, isPaymentDone, signatureSuccess, submissionVM, isBillingIdTPIFullPay, isConvertToTPIPayor, selectedPaymentOption, canUserIssueWithoutPayment]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, signatureSuccess, submissionVM]);


    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            nextLabel={messages.issueLabel}
            onNext={onNext}
            disablePrevious={(isPaymentDone && requiresPayment) || gettingSignatureDocument || isInsuredContactUpdating}
            disableNext={gettingSignatureDocument || isInsuredContactUpdating}
            showNext={canUserPayAndBindQuote}
            showCancel={!isSignatureStatusAsCompleted}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={
                isPageSubmitted
                && (!isMailingComponentValid || !isBillingComponentValid || !isInsuredComponentValid)
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

PaymentDetailsPage.propTypes = wizardProps;
export default PaymentDetailsPage;

