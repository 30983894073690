import { defineMessages } from 'react-intl';

export default defineMessages({
    clearResults: {
        id: 'quoteandbind.eh.views.eh-reports.Clear Results',
        defaultMessage: 'CLEAR'
    },
    notClearResults: {
        id: 'quoteandbind.eh.views.eh-reports.Not Clear Results',
        defaultMessage: 'NOT CLEAR'
    }
});
