import React, {
    useCallback,
} from 'react';

import {
    get as _get,
    stubFalse as _stubFalse
} from 'lodash';
import { CreditService } from 'e1p-capability-gateway';
import { E1PCreditReportModalComponent } from 'e1p-capability-policyjob-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';

/**
 * E1PAP1PC-13497 :
 * Util to show credit report popup
 * @param {Function} translator
 * @param {String} LOB
 * @returns {*} exports showReportPopup function
 */
function useCreditReportUtil(
    translator, LOB
) {
    const showCreditReportModal = useCallback(
        async (noHitCreditRecords, wizardData) => {

            const { modalApi } = _get(window, '__giamfam.modalApi');
            const pni = _get(wizardData, `lobData.${LOB}.primaryNamedInsured`);
            const sni = _get(wizardData, `lobData.${LOB}.secondaryNamedInsured`);
            const componentProps = {
                title: '',
                iconClassType: false,
                showCloseBtn: false,
                showCancelBtn: false,
                LOB: LOB,
                noHitCreditRecords: noHitCreditRecords,
                pni: pni,
                sni: sni
            };
            return modalApi.showModal(
                <E1PCreditReportModalComponent {...componentProps} />
            );
        }, [LOB]
    );

    const showReportPopup = useCallback(
        async (quoteID, authHeader, wizardData) => {
            const pniIntegrationId = _get(wizardData, `lobData.${LOB}.primaryNamedInsured.integrationId.value`);
            const namedInsuredIntegrationIds = [pniIntegrationId];
            const sniIntegrationId = _get(wizardData, `lobData.${LOB}.secondaryNamedInsured.integrationId.value`);
            if (sniIntegrationId) {
                namedInsuredIntegrationIds.push(sniIntegrationId);
            }
            return CreditService.getCreditReport(quoteID, authHeader)
                .then((response) => {
                    const noHitCreditRecords = response?.creditRecords
                        ?.filter((creditReport) => creditReport.reportStatus === 'NOHIT'
                            && namedInsuredIntegrationIds
                                .includes(creditReport?.assignment?.integrationId))
                        ?? [];
                    if (!noHitCreditRecords.length && response?.creditRecords?.length > 0) {
                        // no reports found with NOHIT status and we have some reports in response
                        // returning true and not showing popup
                        return true;
                    }
                    return Promise.resolve(showCreditReportModal(noHitCreditRecords, wizardData))
                        .catch(() => {
                            // User clicked on CANCEL; returning false
                            return _stubFalse();
                        });
                }).catch((ex) => {
                    const { modalApi } = _get(window, '__giamfam.modalApi');
                    // Error occurred while retrieving reports
                    // Displaying error in dialog
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: translator(e1pCommonMessages.errorOccurredRetrievingCreditReports),
                        message: translator(ex?.baseError)
                    }).then(() => {
                        return _stubFalse();
                    });
                });
        }, [LOB, showCreditReportModal, translator]
    );

    return {
        showReportPopup
    };
}

export default useCreditReportUtil;
