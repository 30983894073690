import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextPageMessage: {
        id: 'policychange.eu.Underlying Policy.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    policyType: {
        id: 'policychange.eu.Underlying Policy.Policy Type',
        defaultMessage: 'Policy Type'
    },
    Insurer: {
        id: 'policychange.eu.Underlying Policy.Insurer',
        defaultMessage: 'Insurer'
    },
    ULPolicyDetails: {
        id: 'policychange.eu.Underlying Policy.UL Policy Details',
        defaultMessage: 'UL Policy Details'
    },
    policyNumber: {
        id: 'policychange.eu.Underlying Policy.Policy Number',
        defaultMessage: 'Policy Number'
    },
    delete: {
        id: 'policychange.eu.Underlying Policy.Delete',
        defaultMessage: 'Delete'
    },
    removeUnderlyingPolicy: {
        id: 'policychange.eu.Underlying Policy.Remove?',
        defaultMessage: 'Remove Underlying Policy?'
    },
    removeEAVehicleExposure: {
        id: 'policychange.eu.Underlying Policy.Remove EA Vehicle Exposure?',
        defaultMessage: 'Remove Vehicle Exposure?'
    },
    removeAdditionalPremise: {
        id: 'policychange.eu.Underlying Policy.Remove Additional Premise',
        defaultMessage: 'Remove Additional Premise?'
    },
    removeAddress: {
        id: 'policychange.eu.Underlying Policy.Remove Address',
        defaultMessage: 'Remove Address?'
    },
    confirmDelete: {
        id: 'policychange.eu.Underlying Policy.Confirm Delete',
        defaultMessage: 'Please Confirm Delete.'
    },
    cancel: {
        id: 'policychange.eu.Underlying Policy.Cancel',
        defaultMessage: 'Cancel'
    },
    allUnderlyingPolicisAdded: {
        id: 'policychange.eu.Underlying Policy.All Underlying Policies Added',
        defaultMessage: 'All Underlying Policies Added'
    },
    watercraftDescription: {
        id: 'policychange.eu.Underlying Policy.Watercraft Description',
        defaultMessage: 'Description'
    },
    saveCoverageError: {
        id: 'policychange.eu.config.Unable to save coverages change',
        defaultMessage: 'Unable to save coverages change'
    },
    saveCoverageErrorMessage: {
        id: 'policychange.eu.config.An error occurred while attempting to save coverage changes',
        defaultMessage: 'An error occurred while attempting to save coverage changes'
    },
});
