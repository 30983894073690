import {
    isEqual as _isEqual,
    noop as _noop,
    get as _get,
    stubFalse as _stubFalse
} from 'lodash';

import { useTranslator } from '@jutro/locale';
import htmlParser from 'html-react-parser';
import { commonMessages as e1pCommonMessages, renewalMessages as e1pRenewalMessages } from 'e1p-platform-translations';

function onPageOperationsUtil() {
    const translator = useTranslator();

    /**
    * E1PAP1PC-13531:
    * when user clicks on exit button on current page
    * depending upon data is changed on current page or not showing different dialogs
    * to check whether data is changed or not on current page comparing wizardData with
    * wizardSnapshot
    * @param {Object} wizardData wizard data which contains modified data on page
    * @param {Object} wizardSnapshot wizard snapshot which contains saved data after last page
    * @returns {*} returns modal action result
    */
    const onPageCancel = async (wizardData, wizardSnapshot) => {
        try {
            const snapShotEqualsWizardData = _isEqual(wizardData, wizardSnapshot);
            const isRenewal = _get(wizardData, 'baseData.jobType') === 'Renewal';
            const renewalExitMessage = htmlParser(translator(e1pRenewalMessages.exitMessage).replace('\n', '<br>'));
            // Check if it is renewal flow to consider the popup description and title accordingly
            const unsavedDraftMessage = !isRenewal && !snapShotEqualsWizardData
                ? e1pCommonMessages.unsavedChangeMessage : renewalExitMessage;
            const popupTitle = !isRenewal && !snapShotEqualsWizardData
                ? e1pCommonMessages.unsavedChangeHeader : e1pCommonMessages.exitTransaction;

            if (snapShotEqualsWizardData) {
                // Exit the page if wizard and snapshot data are equal
                return 'confirm';
            }
            // show popup if there are any unsaved changes to the quote
            const { modalApi } = _get(window, '__giamfam.modalApi');

            const popupResult = await modalApi.showConfirm({
                title: popupTitle,
                message: unsavedDraftMessage,
                confirmButtonText: e1pCommonMessages.leave,
                cancelButtonText: e1pCommonMessages.cancel
            });
            return popupResult;
        } catch {
            return _noop();
        }
    };

    /**
     * E1PAP1PC-13531:
     * When user clicks on previous button on current page
     * depending upon data is changed on current page or not showing different dialogs
     * to check whether data is changed or not on current page comparing wizardData with
     * wizardSnapshot
     * @param {Object} wizardData wizard data which contains modified data on page
     * @param {Object} wizardSnapshot wizard snapshot which contains saved data after last page
     * @param {Object} updateWizardData
     * @param {Object} cloneData
     * @param {Object} viewOnly
     * @returns {*} returns modal action result
     */
    const onPagePrevious = async (
        wizardData, wizardSnapshot,
        updateWizardData, cloneData, viewOnly
    ) => {
        try {
            const snapShotEqualsWizardData = _isEqual(wizardData.value, wizardSnapshot.value);
            if (snapShotEqualsWizardData || viewOnly) {
                return wizardData;
            }
            const { modalApi } = _get(window, '__giamfam.modalApi');

            const popupResult = await modalApi.showConfirm({
                title: e1pCommonMessages.unsavedChangeHeader,
                message: e1pCommonMessages.unsavedChangeMessage,
                confirmButtonText: e1pCommonMessages.leave,
                cancelButtonText: e1pCommonMessages.cancel
            });
            if (popupResult === 'cancel') {
                return _stubFalse();
            }
            updateWizardData(wizardSnapshot);
            return wizardSnapshot;
        } catch {
            return _stubFalse();
        }
    };

    return {
        onPageCancel,
        onPagePrevious
    };
}

export default onPageOperationsUtil;
