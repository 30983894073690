import { defineMessages } from 'react-intl';

export default defineMessages({
    lossesAndViolations: {
        id: 'policyjob.views.underwriting-details.Losses And Violations',
        defaultMessage: 'Losses And Violations'
    },
    lossReportQuestion: {
        id: 'policyjob.views.underwriting-details.loss report Label',
        defaultMessage: 'Any losses to report?'
    },
    opertor: {
        id: 'policyjob.views.loss-details.Operator',
        defaultMessage: 'Operator'
    },
    description: {
        id: 'policyjob.views.loss-details.Description',
        defaultMessage: 'Description'
    },
    incidentDate: {
        id: 'policyjob.views.loss-details.Incident Date',
        defaultMessage: 'Incident Date'
    },
    source: {
        id: 'policyjob.views.loss-details.Source',
        defaultMessage: 'Source'
    },
    delete: {
        id: 'policyjob.views.underwriting-details.Delete',
        defaultMessage: 'Delete'
    },lationsQuestions: {
        id: 'policyjob.views.underwriting-details.violation report Label',
        defaultMessage: 'Any violations to report?'
    },
    removeLossTitle: {
        id: 'quoteandbind.controllers.underwriting-details.Remove Loss?',
        defaultMessage: 'Remove Loss?'
    },
    removeLossDescription: {
        id: 'quoteandbind.underwriting-details.remove-loss.The selected loss will be removed.',
        defaultMessage: 'The selected loss will be removed.'
    },
    removeViolationTitle: {
        id: 'quoteandbind.controllers.underwriting-details.Remove Violation?',
        defaultMessage: 'Remove Violation?'
    },
    removeViolationDescription: {
        id: 'quoteandbind.underwriting-details.remove-loss.The selected violation will be removed.',
        defaultMessage: 'The selected violation will be removed.'
    },
    removeAllLossesTitle: {
        id: 'quoteandbind.controllers.underwriting-details.Remove All Losses?',
        defaultMessage: 'Remove All Losses?'
    },
    removeAllLossesDescription: {
        id: 'quoteandbind.underwriting-details.remove-loss.Please note that this action will remove all entered losses.',
        defaultMessage: 'Please note that this action will remove all entered losses.'
    },
    removeAllViolationsTitle: {
        id: 'quoteandbind.controllers.underwriting-details.Remove All Violations?',
        defaultMessage: 'Remove All Violations?'
    },
    removeAllViolationsDescription: {
        id: 'quoteandbind.underwriting-details.remove-loss.Please note that this action will remove all entered violations.',
        defaultMessage: 'Please note that this action will remove all entered violations.'
    },
    amount: {
        id: 'policyjob.directives.templates.edit-underwriting.Amount',
        defaultMessage: 'Amount'
    },
    usedInRating: {
        id: 'policyjob.views.loss-details.Used in Rating',
        defaultMessage: 'Used in Rating'
    }
});
