import { defineMessages } from 'react-intl';

export default defineMessages({
    historyTitle: {
        id: 'gateway.views.account-detail.History Details',
        defaultMessage: 'History'
    },
    compare: {
        id: 'gateway.views.history.COMPARE',
        defaultMessage: 'COMPARE'
    }
});
