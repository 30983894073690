import { defineMessages } from 'react-intl';

export default defineMessages({
    existingContactFound: {
        id: 'common.E1PContactFoundComponent.Existing Contact Found',
        defaultMessage: 'Existing Contact Found'
    },
    selectAnExistingContactOrCreateNew: {
        id: 'common.E1PContactFoundComponent.Select an existing contact or create a new one.',
        defaultMessage: 'Select an existing contact or create a new one.'
    },
    pleaseChoseContactOption: {
        id: 'common.E1PContactFoundComponent.Please Choose a Contact Option.',
        defaultMessage: 'Please Choose a Contact Option.'
    },
});
