import { defineMessages } from 'react-intl';

export default defineMessages({
    completingYourPurchaseMessage: {
        id: 'endorsement.ea.policyChangeDiffPage.Completing your purchase...',
        defaultMessage: 'Completing your purchase...'
    },
    loadingPolicyDiff: {
        id: 'endorsement.ea.policyChangeDiffPage.Loading...',
        defaultMessage: 'Loading...'
    },
    policyChangePhraseTitle: {
        id: 'endorsement.ea.policyChangeDiffPage.Change Phrases',
        defaultMessage: 'Change Phrases'
    },
    changesPhrases: {
        id: 'endorsement.ea.policyDiffPage.Change Phrases',
        defaultMessage: 'Change Phrases'
    },
    issue: {
        id: 'endorsement.ea.policyDiffPage.Issue',
        defaultMessage: 'Issue'
    },
    bindError: {
        id: 'endorsement.ea.config.Unable to bind change',
        defaultMessage: 'Unable to bind change'
    },
    bindErrorMessage: {
        id: 'endorsement.ea.config.An error occurred while attempting to bind changes',
        defaultMessage: 'An error occurred while attempting to bind changes'
    },
    changeConflict: {
        id: 'endorsement.ea.policyChangeDiffPage.tab.Change Conflicts',
        defaultMessage: 'Change Conflicts'
    },
    signature: {
        id: 'endorsement.ea.policyChangeDiffPage.tab.Signature',
        defaultMessage: 'Signature'
    },
    modalError: {
        id: 'e1p.gateway.policychangediff.views.modal.Error',
        defaultMessage: 'Error'
    },
    failedToWithdrawPolicyChange: {
        id: 'e1p.gateway.policychangediff.views.endorsement-detail.Failed to withdraw policy change',
        defaultMessage: 'Failed to withdraw policy change'
    }
});
