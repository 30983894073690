import React, { useCallback, useState, useEffect } from 'react';
import { ModalNext, ModalHeader, ModalFooter, ModalBody, withModalContext } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import _ from 'lodash';
import messages from './E1PCopyVehicleCoveragesModal.messages';
import metadata from './E1PCopyVehicleCoveragesModal.metadata.json5';
import styles from '../E1PCopyVehicleCoveragesComponent.modules.scss';

import { Button } from '@jutro/legacy/components';

function E1PCopyVehicleCoveragesModal(props) {
    const {
        isOpen,
        onResolve,
        onReject,
        vehiclesForCopyCov
    } = props;
    const translator = useTranslator();
    const [availableVehiclesCovsToBeCopiedFrom, updateAvailableVehicleCovsToBeCopiedFrom] = useState([]);
    const [availableVehiclesCovsToBeCopiedTo, updateAvailableVehicleCovsToBeCopiedTo] = useState([]);
    const [vehicleSelectedForCopyingCovsFrom, updateVehicleSelectedForCopyingCovsFrom] = useState(undefined);
    const [vehiclesSelectedForCopyingCovsTo, updateVehiclesSelectedForCopyingCovsTo] = useState([]);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isCopyToRequiredErrorMessageVisible, updateIsCopyToRequiredErrorMessageVisible] = useState(false);
    const {
        isComponentValid, onValidate, registerComponentValidation
    } = useValidation('E1PCopyVehicleCoveragesModal');

    const isValid = useCallback(() => !_.isEmpty(vehiclesSelectedForCopyingCovsTo),
        [vehiclesSelectedForCopyingCovsTo]);

    useEffect(() => {
        registerComponentValidation(isValid);
    }, [isValid, registerComponentValidation])

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [isComponentValid, isPageSubmitted]);


    useEffect(() => {
        updateAvailableVehicleCovsToBeCopiedFrom(vehiclesForCopyCov.map((vehicleForCopyCovClone) => ({
                code: vehicleForCopyCovClone.integrationId,
                name: `${vehicleForCopyCovClone.year} ${vehicleForCopyCovClone.make} ${vehicleForCopyCovClone.model} `
            })));
    }, [vehiclesForCopyCov]);


    useEffect(() => {
        updateAvailableVehicleCovsToBeCopiedTo(
            vehiclesForCopyCov.filter((vehicleForCopyCov) => vehicleForCopyCov.integrationId !== vehicleSelectedForCopyingCovsFrom)
        )
    }, [vehicleSelectedForCopyingCovsFrom, vehiclesForCopyCov])

    const onSelectionChange = useCallback((selectedIndexList) => {
        if (!_.isEmpty(selectedIndexList)) {
            updateIsCopyToRequiredErrorMessageVisible(false);
        }

        const selectedItems = [];

        selectedIndexList.forEach((index) => {
            selectedItems.push(availableVehiclesCovsToBeCopiedTo[index]);
        });
        updateVehiclesSelectedForCopyingCovsTo(selectedItems);
    }, [availableVehiclesCovsToBeCopiedTo]);

    /**
   * Helper callback for handling a mouse-click on the "Cancel" button.
   */
    const onCancel = useCallback(
        (evt) => {
            evt.stopPropagation();
            onReject();
        },
        [onReject]
    );


    /**
     * Helper callback for handling a mouse-click on the "Save" button.
     */
    const onSave = useCallback(
        (evt) => {
            if (!isComponentValid) {
                if (_.isEmpty(vehiclesSelectedForCopyingCovsTo)) {
                    updateIsCopyToRequiredErrorMessageVisible(true);
                }

                updateIsPageSubmitted(true);

                return false;
            }

            evt.stopPropagation();
            /**
             * Sending object containing 
             * 1. vehicle selected for copying coverages from
             * 2. vehicle(s) selected for copying coverages to
             */
            onResolve({ vehicleSelectedForCopyingCovsFrom, vehiclesSelectedForCopyingCovsTo });
        },
        [isComponentValid, onResolve, vehicleSelectedForCopyingCovsFrom, vehiclesSelectedForCopyingCovsTo]
    );


    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false,
            showErrors: isPageSubmitted,
            showRequired: true
        },
        copyFrom: {
            availableValues: availableVehiclesCovsToBeCopiedFrom,
            onValueChange: (value) => {
                updateVehicleSelectedForCopyingCovsFrom(value);
                updateVehiclesSelectedForCopyingCovsTo([]);
                updateIsCopyToRequiredErrorMessageVisible(false);
            },
            value: vehicleSelectedForCopyingCovsFrom,
            validationMessages: (() => vehicleSelectedForCopyingCovsFrom
                    ? []
                    : [translator(messages.copyFromRequiredError)])()
        },
        copyToDataTable: {
            data: availableVehiclesCovsToBeCopiedTo,
            key: vehicleSelectedForCopyingCovsFrom
        },
        copyToRequiredErrorMessage: {
            visible: isCopyToRequiredErrorMessageVisible
        },
        copyToContainer: {
            visible: !!vehicleSelectedForCopyingCovsFrom
        }
    };

    /**
    * Define mappings to be used when resolving values for this Jutro component.
    */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            onSelectionChange
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ModalHeader title={translator(messages.copyVehicleCoverages)} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    {translator(e1pCommonMessages.cancel)}
                </Button>
                <Button onClick={onSave}>
                    {_.upperCase(translator(e1pCommonMessages.save))}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

E1PCopyVehicleCoveragesModal.propTypes = {
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    onReject: PropTypes.func,
    vehiclesForCopyCov: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

E1PCopyVehicleCoveragesModal.defaultProps = {
    isOpen: undefined,
    onReject: undefined,
    onResolve: undefined

};
export default withModalContext(E1PCopyVehicleCoveragesModal);
