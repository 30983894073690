import { defineMessages } from 'react-intl';

export default defineMessages({
    errorUpdatingNotes: {
        id: 'e1p.gateway.views.rewrite-detail.notes.Failed to update note',
        defaultMessage: 'Failed to update note'
    },
    errorDeletingNotes: {
        id: 'e1p.gateway.views.rewrite-detail.notes.Failed to delete note',
        defaultMessage: 'Failed to delete note'
    }
});
