import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import { withModalContext } from '@jutro/components';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ReinstatementService } from 'e1p-capability-reinstatement';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './Summary.metadata.json5';
import styles from './Summary.module.scss';
import messages from './Summary.messages';

const { authUserData } = useAuthentication();

class ReinstatementSummaryWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        authUserData: PropTypes.shape({
            userType: PropTypes.string.isRequired
        }).isRequired,
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string.isRequired,
                loadReinstatementSummary: PropTypes.shape({}).isRequired,
                updateJobSummary: PropTypes.func.isRequired,
                viewModelService: PropTypes.func.isRequired
            })
        }).isRequired,
        history: PropTypes.isRequired
    }

    state = {
        isLoading: false,
        withdrawVM: {}
    };

    getNotificationContent = () => {
        const translator = this.context;
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadReinstatementSummary: reinstatementVM
                }
            }
        } = this.props;
        const content = {};
        const canCreateReinstate = authUserData?.permissions_Ext.includes('createreinstate');
        // withdraw permission is policy specific hence edit transaction permission is aplied
        const canWithdraw = ['withdraw', 'editreinstate'].every(
            (permission) => authUserData.permissions_Ext.includes(permission)
        );

        if (
            reinstatementVM
            && !['Bound', 'Quoted', 'Withdrawn'].includes(reinstatementVM.value.baseData.periodStatus)
        ) {
            content.infoMessageTitle = translator(messages.reinstatementSuccessfullyStarted);
            content.infoMessageDescription = translator(messages.continiueReinstatementQizard);
        }

        if (
            reinstatementVM
            && reinstatementVM.value.baseData.periodStatus === 'Quoted'
        ) {
            content.infoMessageTitle = translator(messages.premiumReinstatementCalculated);
            content.infoMessageDescription = translator(messages.changeReinstatementWizardToBind);
        }

        if (reinstatementVM.value && reinstatementVM.value.baseData.periodStatus === 'Withdrawn') {
            content.infoMessageTitle = translator(messages.thisReinstatementHasBeenWithDrawn);
            content.infoMessageDescription = '';
        }

        if (reinstatementVM.value && reinstatementVM.value.baseData.periodStatus === 'Bound') {
            content.infoMessageTitle = translator(messages.reinstatementHasBeenBound);
            content.infoMessageDescription = '';
        }

        if (!canCreateReinstate && !canWithdraw) {
            content.infoMessageDescription = '';
        }

        return {
            infoMessageTitle: content.infoMessageTitle,
            infoMessageDescription: content.infoMessageDescription,
            withDrawContent: translator(messages.withdrawReinstatement),
            continueContent: translator(messages.continueReinstatement)
        };
    };

    getReinstatementToProceed = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadReinstatementSummary: reinstatementVM
                }
            }
        } = this.props;
        const canCreateReinstate = authUserData?.permissions_Ext.includes('createreinstate');
        // withdraw permission is policy specific hence edit transaction permission is aplied
        const canWithdraw = ['withdraw', 'editreinstate'].every(
            (permission) => authUserData.permissions_Ext.includes(permission)
        );

        if (
            reinstatementVM.value
            && (reinstatementVM.value.baseData.periodStatus === 'Withdrawn' || reinstatementVM.value.baseData.periodStatus === 'Bound')
        ) {
            return {
                isContinueTransaction: false,
                isWithdrawTransaction: false
            };
        }

        return {
            isContinueTransaction: canCreateReinstate,
            isWithdrawTransaction: canWithdraw
        };
    };

    handleReinstatementButtonClick = () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadReinstatementSummary: reinstatementVM
                }
            }
        } = this.props;
        const { history } = this.props;
        const { jobID, policyNumber } = reinstatementVM.value;

        return history.push('/reinstatement',
            { jobID, policyNumber });
    }

    createDataVM = (model) => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    viewModelService
                }
            }
        } = this.props;

        return viewModelService.create(model,
            'pc', 'edge.capabilities.reinstatement.dto.ReinstatementDataDTO');
    };

    handleWithdrawTransaction = async () => {
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadReinstatementSummary: reinstatementVM
                }
            }
        } = this.props;
        const { authHeader } = this.props;
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    updateJobSummary
                }
            }
        } = this.props;
        const { jobID } = reinstatementVM.value;

        this.setState({ isLoading: true });
        this.props.modalContext.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: messages.withdrawReinstatement,
            message: messages.wouldYouLikeToWithdraw,
            confirmButtonText: commonMessages.confirm,
            cancelButtonText: commonMessages.cancel
        }).then((results) => {
            if (results === 'cancel') {
                this.setState(() => ({ isLoading: false }));

                return _.noop();
            }

            return ReinstatementService.withdraw(jobID, authHeader).then((response) => {
                const withdrawVM = this.createDataVM(response);

                updateJobSummary(withdrawVM);
                this.setState(() => ({ withdrawVM, isLoading: false }));
            });
        }, _.noop);
    }


    render() {
        const { isLoading } = this.state;
        const { withdrawVM } = this.state;
        const {
            fromAccountLanding: {
                quoteDetailsData: {
                    loadReinstatementSummary: reinstatementVM
                }
            }
        } = this.props;

        // eslint-disable-next-line no-nested-ternary
        const actualVM = withdrawVM.baseData ? withdrawVM.baseData.periodStatus.value.code === 'Withdrawn' ? withdrawVM : reinstatementVM : reinstatementVM;

        if (_.isEmpty(actualVM) || isLoading) {
            return (
                <E1PLoader loaded={!isLoading} />
            );
        }

        const overrides = {
            reinstatementButtonID: {
                visible: actualVM.value.baseData.periodStatus !== 'Withdrawn' && actualVM.value.baseData.periodStatus !== 'Bound'
            },
            WithdrawReinstatementID: {
                visible: actualVM.value.baseData.periodStatus !== 'Withdrawn' && actualVM.value.baseData.periodStatus !== 'Bound'
            },
            reinstatementEffectiveDate: {
                value: actualVM.value.effectiveDate
            },
            reinstatementNotification: {
                notificationContent: this.getNotificationContent(),
                transactionVisibleActions: this.getReinstatementToProceed()
            },
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
            },
            resolveCallbackMap: {
                onContinueTransaction: this.handleReinstatementButtonClick,
                onWithdrawTransaction: this.handleWithdrawTransaction
            }
        };

        const readValue = (id, path) => readViewModelValue(metadata.pageContent, reinstatementVM, id, path, overrides);

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={actualVM}
                overrideProps={overrides}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

const ReinstatementSummary = withModalContext(ReinstatementSummaryWithoutModalContext);

export default withAuthenticationContext(ReinstatementSummary);
