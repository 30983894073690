import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages } from 'e1p-platform-translations';
import messages from 'gw-capability-quoteandbind-common-react/quoteandbind.messages';
import vehicleMessages from '../../pages/Vehicles/VehiclesPage.messages';

import metadata from './AdditionalInterestComponent.metadata.json5';

function AdditionalInterestComponent(props) {
    const modalApi = useModal();
    const {
        id,
        value: vehicleVM,
        updateAdditionalInterests,
        onValidate
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const translator = useTranslator();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, vehicleVM, onValidate, isComponentValid]);

    const addedAdditionalInterestsTitle = useCallback((index) => {
        if (index === 0) {
            return translator(vehicleMessages.additionalInterestsForThisVehicle);
        }

        return translator(vehicleMessages.additionalInterestCount, {
            count: index + 1
        });
    }, [translator]);

    const generateOverrides = useCallback(() => {
        const overrides = vehicleVM.additionalInterests.children.map((additionalInterest, i) => {
            const subtype = _.get(additionalInterest, 'policyAdditionalInterest.subtype.value');

            return {
                [`companyContainer${i}`]: {
                    visible: subtype === 'Company'
                },
                [`personalContainer${i}`]: {
                    visible: subtype === 'Person'
                },
                [`paVehicleAdditionalInterest${i}`]: {
                    content: addedAdditionalInterestsTitle(i)
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [addedAdditionalInterestsTitle, vehicleVM.additionalInterests.children]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            autoComplete: false
        },
        ...generateOverrides()
    };

    const removeAdditionalInterest = useCallback(
        (evt) => {
            const additionInterestPath = evt.path;
            // removes `.children[index].value` and replace with `.value`
            // so we can get an array of Additional Interests
            const additionalInterestListPath = additionInterestPath.replace(/\.children\[\d+\]$/, '.value');
            const additionalInterestToRemove = _.get(vehicleVM, additionInterestPath);
            const additionalInterestList = _.get(vehicleVM, additionalInterestListPath);

            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.removeAdditionalInterest,
                message: messages.removeAdditionalInterestDesc,
                confirmButtonText: commonMessages.confirm,
                cancelButtonText: commonMessages.cancel
            }).then((result) => {
                if (result !== 'cancel') {
                    const newAdditionalInterestList = additionalInterestList.filter(
                        (additionalInterest, index) => {
                            const additionalInterestListPathWithoutValue = additionalInterestListPath.replace('.value', '');
                            const currentAdditionalInterestPath = `${additionalInterestListPathWithoutValue}.children[${index}]`;
                            const isCorrectPath = (
                                currentAdditionalInterestPath === additionInterestPath
                            );

                            return !(
                                isCorrectPath && _.isEqual(
                                    additionalInterest, additionalInterestToRemove.value
                                )
                            );
                        }
                    );

                    _.set(vehicleVM, additionalInterestListPath, newAdditionalInterestList);

                    updateAdditionalInterests();
                }
            }, () => {
            });
        },
        [vehicleVM, updateAdditionalInterests, modalApi]
    );

    const resolvers = {
        resolveCallbackMap: {
            onRemoveAdditionalInterest: removeAdditionalInterest,
            onValidate
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            onModelChange={updateAdditionalInterests}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
        />
    );
}

AdditionalInterestComponent.propTypes = {
    value: PropTypes.shape({}).isRequired,
    updateAdditionalInterests: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};

export default AdditionalInterestComponent;
