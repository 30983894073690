import React from 'react';
import PropTypes from 'prop-types';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import metadata from './OptionalCoveragesReadOnlyComponent.metadata.json5';
import styles from './OptionalCoveragesReadOnlyComponent.scss';

function OptionalCoveragesReadOnlyComponent(props) {
    const {
        value: clauses,
    } = props;
    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        // all read only/not reliant on state changes, should not be using ViewModelForm
        <MetadataForm
            uiProps={metadata.pageContent}
            data={clauses}
            classNameMap={resolvers.resolveClassNameMap} />
    );
}

OptionalCoveragesReadOnlyComponent.propTypes = {
    value: PropTypes.shape({})
};
OptionalCoveragesReadOnlyComponent.defaultProps = {
    value: {}
};
export default OptionalCoveragesReadOnlyComponent;
