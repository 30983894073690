import { defineMessages } from 'react-intl';

export default defineMessages({
    priorCarrierTabLabel: {
        id: 'riskAnalysis.tabLabel.Prior Carrier',
        defaultMessage: 'Prior Carrier'
    },
    driverActivityTabLabel: {
        id: 'riskAnalysis.tabLabel.Driver Activity',
        defaultMessage: 'Driver Activity'
    },
    vehicleReportTabLabel: {
        id: 'riskAnalysis.tabLabel.Vehicle Report',
        defaultMessage: 'Vehicle Report'
    },
    miscReportTabLabel: {
        id: 'riskAnalysis.tabLabel.Miscellaneous Reports',
        defaultMessage: 'Miscellaneous Reports'
    },
    creditTabLabel: {
        id: 'riskAnalysis.tabLabel.Credit',
        defaultMessage: 'Credit'
    },
    unableToSavePriorCarrier: {
        id: 'riskAnalysis.tabLabel.Unable to Update Prior Carriers',
        defaultMessage: 'Unable to Update Prior Carriers'
    }
});
