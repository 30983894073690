import React, { useCallback, useEffect, useRef } from 'react';
import {
    get,
    isEmpty
} from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
    e1pDateUtil
} from 'e1p-capability-hooks';
import metadata from './ManualPropertyLossComponent.metadata.json5';
import styles from './ManualPropertyLossComponent.module.scss';
import './ManualPropertyLossComponent.messages';

function ManualPropertyLossComponent(props) {
    const {
        data: propertyLossVM,
        path,
        id,
        onValidate,
        onValidateParentPage,
        onValueChange,
        disableFields,
        viewOnly,
        showErrors,
        index
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const claimDateRef = useRef(false);

    const currentDate = e1pDateUtil.getFormattedCurrentDate();

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        if (onValidateParentPage) {
            onValidateParentPage(isComponentValid, id);
        }
    }, [propertyLossVM, id, onValidate, onValidateParentPage, isComponentValid]);

    /**
     * E1PAP1PC-15716:E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        if (claimDateRef.current) {
            const manualPropertyLossElement = document.getElementById(`ehPropertyLossComponentGrid${index}`);

            if (manualPropertyLossElement
                    && isEmpty(get(propertyLossVM, 'value.claimDate'))) {
                const lossDateElement = manualPropertyLossElement.querySelector('input[id^="maskeDateID"]');

                if (lossDateElement
                        && lossDateElement.focus !== undefined) {
                    lossDateElement.focus();
                }
            }
        } else {
            claimDateRef.current = true;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimDateRef.current]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnly,
            showErrors,
            autoComplete: false
        },
        claimDate: {
            updateDateDto: (newDate) => handleValueChange(
                newDate, 'claimDate'
            ),
            dateDTO: propertyLossVM?.claimDate?.value,
            isRequired: true,
            maxDate: currentDate,
            showErrors
        },

        lossTypeDesc: {
            disabled: disableFields
        },
        amount: {
            disabled: disableFields
        },
        sourceType: {
            disabled: disableFields
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                propertyLossVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [propertyLossVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={propertyLossVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ManualPropertyLossComponent.propTypes = {
    data: PropTypes.shape({}),
    propertyLossVM: PropTypes.shape({}),
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValidateParentPage: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    disableFields: PropTypes.bool,
    viewOnly: PropTypes.bool,
    showErrors: PropTypes.bool,
    index: PropTypes.number.isRequired
};
ManualPropertyLossComponent.defaultProps = {
    data: {},
    propertyLossVM: {},
    path: undefined,
    disableFields: undefined,
    viewOnly: false,
    showErrors: false
};

export default ManualPropertyLossComponent;
