import { defineMessages } from 'react-intl';

export default defineMessages({
    completingYourCancellationMessage: {
        id: 'gateway-cancel.directives.bind-cancel-policy.Completing your cancellation...',
        defaultMessage: 'Completing your cancellation...'
    },
    scheduleButton: {
        id: 'gateway-cancel.directives.cancel-policy.Schedule Cancellation',
        defaultMessage: 'Schedule Cancellation'
    },
    createButton: {
        id: 'gateway-cancel.directives.cancel-policy.Create Letter',
        defaultMessage: 'Create Letter'
    },
    interactivePendingMessage: {
        id: 'gateway-cancel-bind.directives.cancel-policy.Interactive letter for cancellation is not complete.',
        defaultMessage: 'Interactive letter for cancellation is not complete.'
    },
    withdrawButton: {
        id: 'gateway-cancel.views.cancellation-detail.Withdraw Cancellation',
        defaultMessage: 'Withdraw Cancellation'
    },
    cancelNowButton: {
        id: 'gateway-cancel.views.cancellation-detail.Cancel Now',
        defaultMessage: 'Cancel Now'
    },
    withdrawCancellation: {
        id: 'gateway-cancel.views.cancellation-detail.Withdraw Cancellation',
        defaultMessage: 'Withdraw Cancellation'
    },
    wantWithdrawCancellation: {
        id: 'gateway-cancel.views.cancellation-detail.Are you sure you want to withdraw the cancellation?',
        defaultMessage: 'Are you sure you want to withdraw the cancellation?'
    },
    failedWithDrawCancellation: {
        id: 'gateway-cancel.views.cancellation-detail.Failed to withdraw cancellation',
        defaultMessage: 'Failed to withdraw cancellation'
    },
    rescindCancellation: {
        id: 'gateway-cancel.views.cancellation-detail.Rescind Cancellation',
        defaultMessage: 'Rescind Cancellation'
    },
    wantRescindCancellation: {
        id: 'gateway-cancel.views.cancellation-detail.Are you sure you want to rescind the cancellation?',
        defaultMessage: 'Are you sure you want to rescind the cancellation?'
    },
    failedRescindCancellation: {
        id: 'gateway-cancel.views.cancellation-detail.Failed to rescind cancellation',
        defaultMessage: 'Failed to rescind cancellation'
    },
    effectiveDate: {
        id: 'gateway-cancel.views.cancellation-detail.Effective Date',
        defaultMessage: 'Effective Date'
    },
    insuredResidenceAddress: {
        id: 'gateway-cancel.views.cancellation-detail.Insured Residence Address',
        defaultMessage: 'Insured Residence Address'
    },
    cancelEffectiveDate: {
        id: 'gateway-cancel.views.cancellation-detail.Cancel Effective Date',
        defaultMessage: 'Cancel Effective Date'
    },
    cancelReasonDescription: {
        id: 'gateway-cancel.views.cancellation-detail.Cancel Reason Description',
        defaultMessage: 'Cancel Reason Description'
    },
    cancelCredit: {
        id: 'gateway-cancel.views.cancellation-detail.Cancel Credit',
        defaultMessage: 'Cancel Credit'
    },
    periodStatus: {
        id: 'gateway-cancel.views.cancellation-detail.Period Status',
        defaultMessage: 'Period Status'
    },
    cancellationMessage: {
        id: 'gateway-cancel.views.cancellation-detail.You wont be able to undo once bound. Are you sure you want to bind this cancellation?',
        defaultMessage: 'You wont be able to undo once bound. Are you sure you want to bind this cancellation?'
    },
    boundRenewalCancellationMessage: {
        id: 'gateway-cancel.views.cancellation-detail.A bound renewal term already exists for this policy. By cancelling the current term the renewal term will be scheduled to be cancelled automatically.<br/><br/>This cannot be undone.',
        defaultMessage: 'A bound renewal term already exists for this policy. By cancelling the current term the renewal term will be scheduled to be cancelled automatically.<br/><br/>This cannot be undone.'
    },
    unBoundRenewalCancellationMessage: {
        id: 'gateway-cancel.views.cancellation-detail.An unbound renewal term already exists for this policy. By cancelling the current term, the renewal term will be withdrawn.<br/><br/>This cannot be undone.',
        defaultMessage: 'An unbound renewal term already exists for this policy. By cancelling the current term, the renewal term will be withdrawn.<br/><br/>This cannot be undone.'
    },
    cancelCurrentAndRenewalTermsTitle: {
        id: 'gateway-cancel.views.cancellation-detail.Cancel Current & Renewal Terms?',
        defaultMessage: 'Cancel Current & Renewal Terms?'
    },
});
