function samePerson(person1, person2) {
    let result = false;
    if (person1 && person2 && person1.dateOfBirth && person2.dateOfBirth) {
        result = (person1.firstName.toUpperCase() === person2.firstName.toUpperCase()
                    && person1.lastName.toUpperCase() === person2.lastName.toUpperCase()
                    && person1.dateOfBirth.day === person2.dateOfBirth.day
                    && person1.dateOfBirth.year === person2.dateOfBirth.year
                    && person1.dateOfBirth.month === person2.dateOfBirth.month);
    }
    return result;
}

export default {
    samePerson
};
