import { defineMessages } from 'react-intl';

export default defineMessages({
    DefensiveDriverTypeOfCourse: {
        id: 'quoteandbind.ea.defensiveDriver.Type of Course Taken',
        defaultMessage: 'Type of Course Taken'
    },
    DefensiveDriverSelectCourse: {
        id: 'quoteandbind.ea.defensiveDriver.Select a course',
        defaultMessage: 'Select a Course'
    },
    DefensiveDriverCourseCompletion: {
        id: 'quoteandbind.ea.defensiveDriver.Course Completion Date',
        defaultMessage: 'Course Completion Date'
    },
    DefensiveDriverMovingViolation: {
        id: 'quoteandbind.ea.defensiveDriver.Was this course completed',
        defaultMessage: 'Was this course completed as a result of a moving violation?'
    },
    DefensiveDriverMovingViolationWithAtFault: {
        id: 'quoteandbind.ea.defensiveDriver.Was this course completed and/or at-fault accident',
        defaultMessage: 'Was this course completed as a result of a moving violation and/or at-fault accident?'
    },
    DefensiveDriverYes: {
        id: 'quoteandbind.ea.defensiveDriver.Yes',
        defaultMessage: 'Yes'
    },
    DefensiveDriverNo: {
        id: 'quoteandbind.ea.defensiveDriver.No',
        defaultMessage: 'No'
    }
});
