import { defineMessages } from 'react-intl';

export default defineMessages({
    combinedSingleLimitLabel: {
        id: 'e1p.platform.lob.eu.Combined Single Limit',
        defaultMessage: 'Combined Single Limit'
    },
    splitLimitLabel: {
        id: 'e1p.platform.lob.eu.Split Limit',
        defaultMessage: 'Split Limit'
    },
    bodilyInjuryLiabilityLabel: {
        id: 'e1p.platform.lob.eu.Bodily Injury Liability',
        defaultMessage: 'Bodily Injury Liability'
    },
    propertyDamageLiabilityLabel: {
        id: 'e1p.platform.lob.eu.Property Damage Liability',
        defaultMessage: 'Property Damage Liability'
    },
    perPersonLabel: {
        id: 'e1p.platform.lob.eu.Per Person',
        defaultMessage: 'Per Person'
    },
    perOccuranceLabel: {
        id: 'e1p.platform.lob.eu.Per Occurrence',
        defaultMessage: 'Per Occurrence'
    },
    UMCoveragesLabel: {
        id: 'e1p.platform.lob.eu.Uninsured Motorist Bodily Injury',
        defaultMessage: 'Uninsured Motorist Bodily Injury'
    },
    UIMCoveragesLabel: {
        id: 'e1p.platform.lob.eu.Underinsured Motorist Bodily Injury',
        defaultMessage: 'Underinsured Motorist Bodily Injury'
    },
    UMUIMCoveragesLabel: {
        id: 'e1p.platform.lob.eu.Uninsured / Underinsured Motorist Bodily Injury',
        defaultMessage: 'Uninsured / Underinsured Motorist Bodily Injury'
    },
    driversLicense: {
        id: 'e1p.platform.lob.eu.Driver\'s License',
        defaultMessage: 'Driver\'s License'
    },
    driversLicenseState: {
        id: 'e1p.platform.lob.eu.Driver\'s License State',
        defaultMessage: 'Driver\'s License State'
    },
    driversLicenseCountry: {
        id: 'e1p.platform.lob.eu.Driver\'s License Country',
        defaultMessage: 'Driver\'s License Country'
    },
    operatorOfPrivatePassengerAuto: {
        id: 'e1p.platform.lob.eu.Operator of private passenger auto?',
        defaultMessage: 'Operator of private passenger auto?'
    },
    operatorOfMotorCycle: {
        id: 'e1p.platform.lob.eu.Operator of Motorcycle?',
        defaultMessage: 'Operator of Motorcycle?'
    },
    operatorType: {
        id: 'e1p.platform.lob.eu.Operator Type',
        defaultMessage: 'Operator Type'
    },
    vehicleOperator: {
        id: 'e1p.platform.lob.eu.Vehicle Operator',
        defaultMessage: 'Vehicle Operator'
    },
    removeVehicleOperatorTitle: {
        id: 'e1p.platform.lob.eu.Remove Vehicle Operator?',
        defaultMessage: 'Remove Vehicle Operator?'
    },
    removeVehicleOperatorMessage: {
        id: 'e1p.platform.lob.eu.The selected vehicle operator will be removed.',
        defaultMessage: 'The selected vehicle operator will be removed.'
    },
    operatorTypeHelpText: {
        id: 'e1p.platform.lob.eu.OperatorTypeHelpText.Only resident relatives who are Operators can be included in an Umbrella policy. Drivers who are Non-Operators or Excluded Operators on the Auto policy are not covered under the Umbrella policy.',
        defaultMessage: 'Only resident relatives who are Operators can be included in an Umbrella policy. Drivers who are Non-Operators or Excluded Operators on the Auto policy are not covered under the Umbrella policy.'
    },
    vehicleInformation: {
        id: 'e1p.platform.lob.eu.Vehicle Information',
        defaultMessage: 'Vehicle Information'
    },
    antiqueClassic: {
        id: 'e1p.platform.lob.eu.Antique/Classic',
        defaultMessage: 'Antique/Classic'
    },
    grossWeightOverThreshold: {
        id: 'e1p.platform.lob.eu.GWVR Greater than 15,000 lbs',
        defaultMessage: 'GWVR Greater than 15,000 lbs'
    },
    isVehiclePassengerVan: {
        id: 'e1p.platform.lob.eu.Is Vehicle a 12+ Passenger Van',
        defaultMessage: 'Is Vehicle a 12+ Passenger Van'
    },
    companyFurnishedVehicle: {
        id: 'e1p.platform.lob.eu.Company Furnished Vehicle',
        defaultMessage: 'Company Furnished Vehicle'
    },
    licensedForRoadUse: {
        id: 'e1p.platform.lob.eu.Licensed for Road Use',
        defaultMessage: 'Licensed for Road Use'
    },
    addRecreationalVehicle: {
        id: 'e1p.platform.lob.eu.Add Recreational Vehicle',
        defaultMessage: 'Add Recreational Vehicle'
    },
    addWatercraft: {
        id: 'e1p.platform.lob.eu.Add Watercraft',
        defaultMessage: 'Add Watercraft'
    },
    addMotorcycle: {
        id: 'e1p.platform.lob.eu.Add Motorcycle',
        defaultMessage: 'Add Motorcycle'
    },
    watercraftType: {
        id: 'e1p.platform.lob.eu.Watercraft Type',
        defaultMessage: 'Watercraft Type'
    },
    hinSerialNumber: {
        id: 'e1p.platform.lob.eu.HIN/Serial Number',
        defaultMessage: 'HIN/Serial Number'
    },
    lengthFt: {
        id: 'e1p.platform.lob.eu.Length (ft)',
        defaultMessage: 'Length (ft)'
    },
    horsePower: {
        id: 'e1p.platform.lob.eu.Horsepower',
        defaultMessage: 'Horsepower'
    },
    removeVehicleExposureTitle: {
        id: 'e1p.platform.lob.eu.Remove Vehicle Exposure?',
        defaultMessage: 'Remove Vehicle Exposure?'
    },
    removeVehicleExposureMessage: {
        id: 'e1p.platform.lob.eu.Please Confirm Delete',
        defaultMessage: 'Please Confirm Delete'
    },
    driverLicense: {
        id: "e1p.platform.lob.eu.Driver's License Number",
        defaultMessage: "Driver's License Number"
    },
    driverLicenseCountry: {
        id: "e1p.platform.lob.eu.Driver's License Country",
        defaultMessage: "Driver's License Country"
    },
    driverLicenseState: {
        id: "e1p.platform.lob.eu.Driver's License State",
        defaultMessage: "Driver's License State"
    },
    underwritingQuestion: {
        id: 'e1p.platform.lob.eu.Underwriting Question',
        defaultMessage: 'Underwriting Question'
    },
    resRelOwnLiveStockInd: {
        id: 'e1p.platform.lob.eu.Do you or a resident relative own livestock?',
        defaultMessage: 'Do you or a resident relative own livestock?'
    },
    howMany: {
        id: 'e1p.platform.lob.eu.How many?',
        defaultMessage: 'How many?'
    },
    livestockForCommercialSale: {
        id: 'e1p.platform.lob.eu. Are the livestock for commercial sale or other business use?',
        defaultMessage: ' Are the livestock for commercial sale or other business use?'
    },
    youOrResidentWithBittingHistoryDog: {
        id: 'e1p.platform.lob.eu.Do you or a resident relative have any dogs with a history of biting?',
        defaultMessage: 'Do you or a resident relative have any dogs with a history of biting?'
    },
    hasAnyLiabilityOccurredBetweenTheDates: {
        id: "e1p.platform.lob.eu.Has any Liability Loss occurred in the household between the effective date and today's date?",
        defaultMessage: "Has any Liability Loss occurred in the household between the effective date and today's date?"
    },
    youOrResidentRelativeToPublicFigure: {
        id: 'e1p.platform.lob.eu. Are you or a resident relative a public figure? (ex. Actor, actress, professional athlete, politician, etc.)?',
        defaultMessage: ' Are you or a resident relative a public figure? (ex. Actor, actress, professional athlete, politician, etc.)?'
    },
    category: {
        id: 'e1p.platform.lob.eu.Category',
        defaultMessage: 'Category'
    },
    representCityOf300kPeople: {
        id: 'e1p.platform.lob.eu.Do you represent a city of over 300,000 people?',
        defaultMessage: 'Do you represent a city of over 300,000 people?'
    },
    publicFigureCategoryOtherDescription: {
        id: 'e1p.platform.lob.eu.Public Figure Category Other Description',
        defaultMessage: 'Public Figure Category Other Description'
    },
    primaryResidenceCityOrMetro: {
        id: 'e1p.platform.lob.eu.Is your primary audience a city or metro area over 300,000 people?',
        defaultMessage: 'Is your primary audience a city or metro area over 300,000 people?'
    },
    areYouPartyLeaderOrGovernor: {
        id: 'e1p.platform.lob.eu.Are you a party Leader or Governor?',
        defaultMessage: 'Are you a party Leader or Governor?'
    },
    noOfFollowersOrSubscribers: {
        id: 'e1p.platform.lob.eu.Number of followers or subscribers on your largest social media platform.',
        defaultMessage: 'Number of followers or subscribers on your largest social media platform.'
    },
    householdMemberHaveJudgementAgainstThem: {
        id: 'e1p.platform.lob.eu.Does any household member have a judgement against them (including pending judgement) for libel, slander, defamation, etc.?',
        defaultMessage: 'Does any household member have a judgement against them (including pending judgement) for libel, slander, defamation, etc.?'
    },
    householdMemberTravelInternationally: {
        id: 'e1p.platform.lob.eu.Does anyone in the household live or travel internationally more than 6 months a year?',
        defaultMessage: 'Does anyone in the household live or travel internationally more than 6 months a year?'
    },
    describeInternationalTravel: {
        id: 'e1p.platform.lob.eu.Describe International Travel',
        defaultMessage: 'Describe International Travel'
    },
    qualification: {
        id: 'e1p.platform.lob.eu.Qualification',
        defaultMessage: 'Qualification'
    },
    federalOfficialLabel: {
        id: 'e1p.platform.lob.eu.Elected or Appointed Federal Official',
        defaultMessage: 'Elected or Appointed Federal Official'
    },
    removeBusinessExposure: {
        id: 'e1p.platform.lob.eu.Underlying Policy.Remove Business Exposure?',
        defaultMessage: 'Remove Business Exposure?'
    },
    applyHighProfileSurcharge: {
        id: 'component.quoteandbind.common.views.Apply High Profile Surcharge',
        defaultMessage: 'Apply High Profile Surcharge' 
    },
    verifiedPremium: {
        id: 'e1p.platform.lob.eu.verifiedPremium',
        defaultMessage: 'Verified Premium'
    },
    livestock: {
        id: "e1p.platform.lob.eu.Livestock does NOT include chickens or other fowl.  Livestock does include animals such as cattle, swine, sheep, goats, horses/ponies, mules, donkeys/burros, llamas/alpacas, bison/buffalo, emus, ostriches, elk, and deer.",
        defaultMessage: "Livestock does NOT include chickens or other fowl.  Livestock does include animals such as cattle, swine, sheep, goats, horses/ponies, mules, donkeys/burros, llamas/alpacas, bison/buffalo, emus, ostriches, elk, and deer."
    },
    newWatercraft: {
        id: 'e1p.platform.lob.eu.New Watercraft',
        defaultMessage: 'New Watercraft'
    },
    newRecreationalVehicle: {
        id: 'e1p.platform.lob.eu.New Recreational Vehicle',
        defaultMessage: 'New Recreational Vehicle'
    },
    newMotorcycle: {
        id: 'e1p.platform.lob.eu.New Motorcycle',
        defaultMessage: 'New Motorcycle'
    },
    newDriver: {
        id: 'e1p.platform.lob.eu.vehicles.New Driver',
        defaultMessage: 'New Driver'
    }
});
