import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { ModalNext, ModalFooter, ModalBody, ModalHeader } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { QuoteProposalUtil, OfferingUtil } from 'e1p-portals-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useMVRLicenseStatusUtil } from 'e1p-capability-hooks';
import { useTranslator } from '@jutro/locale';
import moment from 'moment';
import { commonMessages } from 'e1p-platform-translations';
import { Button } from '@jutro/legacy/components';
import metadata from './EAReports.metadata.json5';
import styles from './EAReports.module.scss';


function EAReports(props) {
    const {
        submissionVM,
        updateWizardData,
        steps,
        jumpTo,
        incidentData,
        isOpen,
        onResolve,
        lastVisitedRiskAnalysisTab
    } = props;

    const {
        onValidate
    } = useValidation('EAReports');

    const translator = useTranslator();
    const verifiedCededInd = _.get(submissionVM, 'lobData.personalAuto_EA.uwcededIndicator.value', false)
    const isPhysicalDamageCovAvailable = submissionVM.unVerifiedUWCededInd && !verifiedCededInd
    const isPhysicalDamageCovUnAvailable = !submissionVM.unVerifiedUWCededInd && verifiedCededInd
    const policyState = _.get(
        submissionVM,
        'baseData.policyAddress.state.value.code',
        _.get(submissionVM, 'policyAddress.state.value.code')
    );
    const [isUWIssueApprovedAndPolicyQuoted, setIsUWIssueApprovedAndPolicyQuoted] = useState(false);
    const [noHitMessage, setNoHitMessage] = useState('');
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [showNoHitMessage, setShowNoHitMessage] = useState(false);
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);
    const {
        checkNoHitStatus
    } = useMVRLicenseStatusUtil();

    const isLiabilityCovCededMoreThan250 = (_.get(submissionVM, 'lobData.personalAuto_EA.cededIndicator.value', false)
         || _.get(submissionVM, 'lobData.personalAuto_EA.uwcededIndicator.value', false)) && submissionVM.isUnVerifiedBILimitEligible
         
    const isLiabilityCovCededLessThan250 = (_.get(submissionVM, 'lobData.personalAuto_EA.cededIndicator.value', false)
         || _.get(submissionVM, 'lobData.personalAuto_EA.uwcededIndicator.value', false)) && !submissionVM.isUnVerifiedBILimitEligible

    const { authHeader } = useAuthentication();

    const dateFormat = (date) => {
        if (date === undefined) {
            return undefined;
        }

        const newdate = moment(date).toDate();

        return moment(newdate).format('MM/DD/YYYY');
    };

    useEffect(() => {
        QuoteProposalUtil.fetchQuoteProposal(
            submissionVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();

            if (quoteProposalLink) {
                window.open(quoteProposalLink, '_blank');

                return true;
            }
        }, [quoteProposalLink]
    );

    // When payment option changes, we need to get a new doc url
    useEffect(() => {
        if (submissionVM.paymentOptionChanged) {
            QuoteProposalUtil.fetchQuoteProposal(
                submissionVM,
                setQuoteProposalCompleted,
                setQuoteProposalLink,
                authHeader,
                setIsQuoteProposalFailed
            );
            submissionVM.paymentOptionChanged = false;
        }
    }, [authHeader, submissionVM, submissionVM.paymentOptionChanged]);

    useEffect(() => {
        if (incidentData && incidentData.value !== '') {
            submissionVM.lobData.personalAuto_EA.incidentData = incidentData;

            const paymentPlansList = submissionVM.lobData
                .personalAuto_EA.offerings.children[0].paymentPlans.value;
            const selectedPlan = paymentPlansList.filter((element) => element.isSelected);
            const verifiedPremium = selectedPlan[0].total;
            const unVerifiedPremium = _.get(submissionVM, 'unverfiedPremium');
            const premiumIncrease = { currency: 'usd', amount: verifiedPremium.amount - unVerifiedPremium };

            _.set(submissionVM, 'verifiedPremium', verifiedPremium);
            _.set(submissionVM, 'premiumIncrease', premiumIncrease);

            let autoLossRecords = _.get(submissionVM, 'lobData.personalAuto_EA.incidentData.autoLossRecords.value', null);

            if (autoLossRecords === null) {
                autoLossRecords = _.get(incidentData, 'autoLossRecords', null);
            }

            if (autoLossRecords) {
                autoLossRecords
                    .forEach((element) => {
                        const assignDisplayName = `${_.get(element,'assignment.firstName')} ${_.get(element,'assignment.lastName')}`;
                        const incurDate = _.get(element, 'incurDate');
                        const formattedIncurDate = dateFormat(incurDate);

                        _.set(element, 'incurDateDisplay', formattedIncurDate);

                        const policyHolderName = _.get(element,'assignment.roleInClaim') === 'POLICYHOLDER' ? assignDisplayName : '-';
                        const vehicleOperatorName = _.get(element,'assignment.roleInClaim') === 'VEHICLE OPERATOR' ? assignDisplayName : '-';

                        _.set(element, 'policyHolderName', policyHolderName);
                        _.set(element, 'vehicleOperatorName', vehicleOperatorName);

                        _.set(element, 'lossType', translator({ id: `typekey.AutoLossType_Ext.${element.lossType}` }));
                    });
            }

            let autoViolationRecords = _.get(submissionVM, 'lobData.personalAuto_EA.incidentData.autoViolationRecords.value', null);

            if (autoViolationRecords === null) {
                autoViolationRecords = _.get(incidentData, 'autoViolationRecords', null);
            }

            if (autoViolationRecords) {
                autoViolationRecords = autoViolationRecords.filter((violation) => violation.source?.sourceType !== 'Self-Declared');
                _.set(submissionVM, 'lobData.personalAuto_EA.incidentData.autoViolationRecords.value', autoViolationRecords);
                autoViolationRecords.forEach(
                    (element) => {
                        const operatorName = _.get(submissionVM,
                            'lobData.personalAuto_EA.primaryNamedInsured.person.displayName.value', '');
                        const assigDisplayName = `${element.assignment.firstName} ${element.assignment.lastName}`;
                        const incidentDate = _.get(element, 'incidentDate');
                        const formattedIncidentDate = dateFormat(incidentDate);
                        const convictionDate = _.get(element, 'convictionDate');
                        const formattedConvictionDate = dateFormat(convictionDate);
                        const prayerForJudgement = _.get(element, 'prayerForJudgementInd', false)

                        _.set(element, 'incidentDateDisplay', formattedIncidentDate);
                        _.set(element, 'convictionDateDisplay', formattedConvictionDate);
                        _.set(element, 'operator', operatorName);
                        _.set(element, 'assignment.displayName', assigDisplayName);
                        _.set(element, 'violationType', translator({ id: `typekey.ViolationType_Ext.${element.violationType}` }));
                        _.set(element, 'prayerForJudgementInd', prayerForJudgement ? 'Yes' : 'No');
                    }
                );
            }

            let mvrLicenseStatusRecords = _.get(submissionVM, 'lobData.personalAuto_EA.incidentData.mvrlicenseStatus.value', null);

            if (mvrLicenseStatusRecords === null) {
                mvrLicenseStatusRecords = _.get(incidentData, 'mvrlicenseStatus', null);
            }

            if (mvrLicenseStatusRecords) {
                const drivers = _.get(submissionVM,
                    'lobData.personalAuto_EA.coverables.drivers.value', []);

                mvrLicenseStatusRecords
                    .forEach((element) => {
                        const driverNode = drivers.find((driver) => driver.integrationId === element.integrationId);
                        const operatorName = _.get(driverNode, 'person.displayName', '-');

                        _.set(element, 'operator', operatorName);

                        const isMVRNoHit = element.mvrstatus === 'No-Hit';

                        // if value of mvrStatus is coming as "No Hit", then show "Not Available"
                        //  in license Status and result column.
                        if (isMVRNoHit) {
                            _.set(element, 'licenseStatus', 'Not Available');
                            _.set(element, 'results', 'Not Available');
                        }
                    });
            }

            updateWizardData(submissionVM);
        }
    }, [incidentData, submissionVM, translator, updateWizardData]);

    const onOk = useCallback(
        () => {
            const wrapperObj = {
                incidentData,
            };

            return onResolve(wrapperObj);
        }, [incidentData, onResolve]
    );

    const getVerifiedPremium = useMemo(
        () => {
            if (submissionVM.quoteData.offeredQuotes) {
                const paymentPlansList = submissionVM.lobData.personalAuto_EA
                    .offerings.children[0].paymentPlans.value;
                const selectedPlan = _.find(paymentPlansList, (plan) => plan.isSelected);
                const totalPremium = { currency: 'usd', amount: selectedPlan.total.amount };

                if (!totalPremium && totalPremium !== 0) {
                    return undefined;
                }

                let differenceInPremium = totalPremium.amount - _.get(submissionVM, 'unverfiedPremium');

                if (typeof (differenceInPremium) !== 'number') {
                    differenceInPremium = 0;
                }

                _.set(submissionVM, 'differenceInPremium', differenceInPremium);

                return totalPremium;
            }

            return undefined;
        }, [submissionVM]
    );

    useEffect(() => {
        if (lastVisitedRiskAnalysisTab.current === 'EAReportsLossID') {
            const indexToUnderwritingPage = _.findIndex(
                steps,
                ({ path }) => path === '/risk-analysis'
            );

            jumpTo(indexToUnderwritingPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastVisitedRiskAnalysisTab.current]);

    useEffect(() => {
        let mvrLicenseStatusRecords = _.get(submissionVM, 'lobData.personalAuto_EA.incidentData.mvrlicenseStatus.value', null);

        if (mvrLicenseStatusRecords === null) {
            mvrLicenseStatusRecords = _.get(incidentData, 'mvrlicenseStatus', null);
        }

        if (mvrLicenseStatusRecords) {
            const drivers = _.get(submissionVM,
                'lobData.personalAuto_EA.coverables.drivers.value', []);

            checkNoHitStatus(mvrLicenseStatusRecords, drivers, setShowNoHitMessage, setNoHitMessage);
        }
    }, [incidentData, checkNoHitStatus, submissionVM]);

    const accidentForgivenessApplied = () => {
        const accidentForgivenessStatuses = _.get(submissionVM, 'value.lobData.personalAuto_EA.accidentForgivenessStatuses', []);
        const isAccForgivenessEarned = !!accidentForgivenessStatuses.find((accidentForgivenessStatus) => accidentForgivenessStatus.code === 'Earned Accident Forgiveness');

        if (submissionVM.value.quoteType === 'Full' && isAccForgivenessEarned) {
            return true;
        }

        return false;
    };

    const isPurchasedAccidentForgivenessMessageVisible = () => {
        const accidentForgivenessStatuses = _.get(submissionVM, 'value.lobData.personalAuto_EA.accidentForgivenessStatuses', []);
        const isEligibleForPurchasedAccForgiveness = !!accidentForgivenessStatuses.find((accidentForgivenessStatus) => accidentForgivenessStatus.code === 'Purchased Accident Forgiveness Eligible');
        const { selectedOffering } = OfferingUtil.getSelectedOffering(submissionVM, 'personalAuto_EA');
        const allLineLevelCoverages = _.get(selectedOffering, ['coverages', 'lineCoverages'], []);
        const isPAFCoverageAvailable = !!_.find(allLineLevelCoverages, { codeIdentifier: 'EA_PurchasedAccidentForgiveness' });

        if (submissionVM.value.quoteType === 'Full' && isEligibleForPurchasedAccForgiveness && isPAFCoverageAvailable) {
            return true;
        }

        return false;
    };

    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            autoComplete: false
        },
        accidentForgivenessMessageDiv: {
            visible: accidentForgivenessApplied()
        },
        purchasedAccidentForgivenessMessageDiv: {
            visible: isPurchasedAccidentForgivenessMessageVisible()
        },
        physicalDamageCovRemovalMsgDiv: {
            visible: policyState === 'NC' && isPhysicalDamageCovUnAvailable
        },
        physicalDamageCovAvailableMsgDiv: {
            visible: policyState === 'NC' && isPhysicalDamageCovAvailable
        },
        bodilyInjuryReducedLiabLimitMsgDiv: {
            visible: policyState === 'NC' && isLiabilityCovCededMoreThan250
        },
        bodilyInjuryLiabLimitMsgDiv: {
            visible: policyState === 'NC' && isLiabilityCovCededLessThan250
        },
        mvrPrayerForJudgement: {
            visible: policyState === 'NC'
        },
        rvpSection: {
            submissionVM,
            updateWizardData,
            reportOrder: true,
            onValidate,
            setIsUWIssueApprovedAndPolicyQuoted,
            isUWIssueApprovedAndPolicyQuoted
        },
        quoteProposalLinkContainer: {
            onClick: (e) => openDocument(e),
            disabled: !quoteProposalCompleted
        },
        paymentOptionsID: {
            submissionVM,
            authHeader,
            updateWizardData,
            LOB: 'personalAuto_EA',
            viewOnly: true
        },
        totalPremiumID: {
            value: getVerifiedPremium
        },
        changePremiumID: {
            value: { currency: 'usd', amount: _.get(submissionVM, 'differenceInPremium', 0) }
        },
        policyTermComponent: {
            transactionVM: submissionVM,
            authHeader,
            updateWizardData,
            viewOnly: true
        },
        mvrNoHitInfoMessage: {
            message: translator(noHitMessage)},
        mvrNoHitInfoMessageDiv: {
            visible: showNoHitMessage
        },
        quoteProposalFailureErrorDiv: {
            visible: isQuoteProposalFailed
        },     
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        },
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps),
        [submissionVM, overrideProps]
    );


    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onOk}
            className={styles.fullScreen}
        >
            <ModalHeader />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={submissionVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    className={styles.footerButton}
                    id="okButton"
                    hidden={isUWIssueApprovedAndPolicyQuoted}
                    onClick={onOk}
                >
                    {commonMessages.next}
                </Button>
            </ModalFooter>
        </ModalNext>

    );
}

EAReports.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    jumpTo: PropTypes.func.isRequired,
    incidentData: PropTypes.shape({}).isRequired,
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    lastVisitedRiskAnalysisTab: PropTypes.shape({}).isRequired
};
EAReports.defaultProps = {
    isOpen: undefined,
    onResolve: undefined,
}

export default EAReports;
