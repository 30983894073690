import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { appendMetadataWithIndex, readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import metadata from './VehiclePackageCovRadioGroupComponent.metadata.json5';

function VehiclePackageCovRadioGroupComponent(props) {
    const {
        data: vehicleCoverages,
        index,
        onPackageCovChange,
        isLoading,
        viewOnly,
        basePath
    } = props;

    const getPackageCovValue = useCallback(() => {
        const selectedCovs = vehicleCoverages
            .filter((clause) => clause.selected).map((clause) => clause.codeIdentifier);
        let packageCovValue = 'none';

        if (selectedCovs.includes('EA_GoldCoverageBundle')) {
            packageCovValue = 'EA_GoldCoverageBundle';
        } else if (selectedCovs.includes('EA_PlatinumCoverageBundle')) {
            packageCovValue = 'EA_PlatinumCoverageBundle';
        }

        return packageCovValue;
    }, [vehicleCoverages]);

    const handlePackageCovChange = useCallback(async (value) => {
        if (onPackageCovChange) {
            const goldClauseSelectedIndex = vehicleCoverages.findIndex(
                (clause) => clause.codeIdentifier === 'EA_GoldCoverageBundle' && clause.selected
            );
            const platinumClauseSelectedIndex = vehicleCoverages.findIndex(
                (clause) => clause.codeIdentifier === 'EA_PlatinumCoverageBundle' && clause.selected
            );
            const goldClauseIndex = vehicleCoverages.findIndex(
                (clause) => clause.codeIdentifier === 'EA_GoldCoverageBundle'
            );
            const platinumClauseIndex = vehicleCoverages.findIndex(
                (clause) => clause.codeIdentifier === 'EA_PlatinumCoverageBundle'
            );

            switch (value) {
                case 'none':
                    // if none option is selected then unselect gold and platinum if selected
                    if (goldClauseSelectedIndex > -1) {
                        await Promise.resolve(onPackageCovChange(false, `${basePath}.children[${goldClauseSelectedIndex}].selected`));
                    }

                    if (platinumClauseSelectedIndex > -1) {
                        await Promise.resolve(onPackageCovChange(false, `${basePath}.children[${platinumClauseSelectedIndex}].selected`));
                    }

                    break;
                case 'EA_GoldCoverageBundle':
                    // if gold option is selected then check first if platinum is selected or not
                    // if yes then unselect platinum and select gold
                    if (platinumClauseSelectedIndex > -1) {
                        await Promise.resolve(onPackageCovChange(false, `${basePath}.children[${platinumClauseSelectedIndex}].selected`, 'EA_PlatinumCoverageBundle'))
                    }

                    // We should always have gold and platinum coverage present
                    // this is just safety check
                    if (goldClauseIndex > -1) {
                        return Promise.resolve(onPackageCovChange(true, `${basePath}.children[${goldClauseIndex}].selected`));
                    }

                    break;

                case 'EA_PlatinumCoverageBundle':
                    // if platinum option is selected then check first if gold is selected or not
                    // if yes then unselect gold and select platinum
                    if (goldClauseSelectedIndex > -1) {
                        await Promise.resolve(onPackageCovChange(false, `${basePath}.children[${goldClauseSelectedIndex}].selected`, 'EA_GoldCoverageBundle'))
                    }

                    // We should always have gold and platinum coverage present
                    // this is just safety check
                    if (platinumClauseIndex > -1) {
                        return Promise.resolve(onPackageCovChange(true, `${basePath}.children[${platinumClauseIndex}].selected`));
                    }

                    break;

                default:
                    break;
            }
        }
    }, [onPackageCovChange, basePath, vehicleCoverages]);

    const generateOverrides = useCallback(
        () => {
            const overrides = {};

            overrides[`vehiclePackageCovOptionsRadioButton${index}`] = {
                onValueChange: handlePackageCovChange,
                value: getPackageCovValue(),
                readOnly: viewOnly,
                visible: !isLoading
            };
            overrides[`vehiclePackageCovLoadingIndicator${index}`] = {
                loaded: !isLoading
            };

            return overrides;
        },
        [handlePackageCovChange, index, isLoading, viewOnly, getPackageCovValue]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            disabled: viewOnly
        },
        ...generateOverrides()
    };
    const resolvers = {
        resolveCallbackMap: {}
    };

    const formattedMetadata = useMemo(() => appendMetadataWithIndex(metadata.pageContent, index), [index]);

    const readValue = useCallback(
        (Id, Path) => readViewModelValue(
                formattedMetadata,
                vehicleCoverages,
                Id,
                Path,
                overrideProps
            ),
        [formattedMetadata, overrideProps, vehicleCoverages]
    );

    return (
        <ViewModelForm
            model={vehicleCoverages}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

VehiclePackageCovRadioGroupComponent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    index: PropTypes.number.isRequired,
    onPackageCovChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    viewOnly: PropTypes.bool,
    basePath: PropTypes.string.isRequired
};

VehiclePackageCovRadioGroupComponent.defaultProps = {
    data: [],
    viewOnly: false
};

export default VehiclePackageCovRadioGroupComponent;
