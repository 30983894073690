import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invocation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processPreRenewalDirection(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('prerenewal'), method, data, additionalHeaders);
}

/**
 * This is the server responsible for dealing with the PreRenewal Direction operations
 *
 * @export
 * @class PreRenewalDirectionService
 */
export default class PreRenewalDirectionService {
    /**
     * Creates Prerenewal direction
     *
     * @param {Object} data prerenewalDirection dto
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof PreRenewalDirectionService
     */
    static createPreRenewalDirection(data, additionalHeaders = {}) {
        return processPreRenewalDirection('createPreRenewalDirection', [data], additionalHeaders);
    }

    /**
     * Deletes Prerenewal direction
     *
     * @param {Object} data policyNumber
     * @returns {Promise} the promise from the backend call
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof PreRenewalDirectionService
     */
    static deletePreRenewalDirection(data, additionalHeaders = {}) {
        return processPreRenewalDirection('deletePreRenewalDirection', [data], additionalHeaders);
    }

    /**
     * Get default values for prerenewal direction
     * @param {String} policyNumber String
     * @param {*} additionalHeaders
     * @returns {Promise} the promise from the backend call
     * @memberof PreRenewalDirectionService
     */
    static loadDetails(policyNumber, additionalHeaders = {}) {
        return processPreRenewalDirection('loadDetails', [policyNumber], additionalHeaders);
    }

    /**
     * Creates Prerenewal direction non renewal interactive letter
     *
     * @param {Object} data prerenewalDirection dto
     * @returns {Promise} the promise from the backend call with intractive letter URL
     * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
     * @memberof PreRenewalDirectionService
     */
    static createNonRenewalInteractiveLetter(data, additionalHeaders = {}) {
        return processPreRenewalDirection('createNonRenewalInteractiveLetter', [data], additionalHeaders);
    }
}
