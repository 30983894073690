import React, {
    useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import styles from './AddCreditFixComponent.module.scss';
import metadata from './AddCreditFixComponent.metadata.json5';
import './AddCreditFixComponent.messages';


import { Button } from '@jutro/legacy/components';


function AddCreditFixComponent(props) {
    const {
        creditVM,
        title,
        isOpen,
        onResolve,
        onReject,
        viewModelService,
        transactionEffectiveDate
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation
    } = useValidation(
        'AddCreditFixComponent'
    );

    const [creditData, updateCreditData] = useState(creditVM);

    useEffect(() => {
        const nextFormData = viewModelService.clone(creditData);

        _.set(nextFormData, 'type.value', 'fix');
        _.set(nextFormData, 'startDate', transactionEffectiveDate.value);
        _.set(nextFormData, 'endDate', transactionEffectiveDate.value);
        updateCreditData(nextFormData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = viewModelService.clone(creditData);

            _.set(nextFormData, path, value);
            updateCreditData(nextFormData);
        },
        [creditData, viewModelService]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            showRequired: true,
            labelPosition: 'top',
            autoComplete: false
        },
        // currently not getting a value for Credit Report Fix, defaulting for now
        fixTypeId: {
            value: 'Manual/Other'
        },
        fixStartDateId: {
            dateDTO: creditData.startDate
        },
        fixEndDateId: {
            dateDTO: creditData.endDate,
            updateDateDto: () => writeValue(creditData.endDate.value, 'endDate')
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                creditData,
                id,
                path,
                overrideProps
            ),
        [overrideProps, creditData]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        },
    };

    const onOk = useCallback(
        () => {
            if (!isComponentValid) {
                return false;
            }

            return onResolve(creditData);
        }, [isComponentValid, creditData, onResolve]
    );

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    return (
        <ModalNext
            isOpen={isOpen}
            onRequestClose={onOk}
            className={styles.smallPopUp}
        >
            <ModalHeader title={title} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={creditData}
                    overrideProps={overrideProps}
                    onValueChange={writeValue}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    onValidationChange={setComponentValidation}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="outlined">
                    Cancel
                </Button>
                <Button onClick={onOk} disabled={!isComponentValid}>
                        Add Credit Fix
                    </Button>
            </ModalFooter>
        </ModalNext>
    );
}

AddCreditFixComponent.propTypes = {
    creditVM: PropTypes.shape({
    }).isRequired,
    transactionEffectiveDate: PropTypes.shape({
        value: PropTypes.shape({
        })
    }).isRequired,
    title: PropTypes.string.isRequired,
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({
        create: PropTypes.func.isRequired,
        clone: PropTypes.func.isRequired
    }).isRequired
};
export default AddCreditFixComponent;
