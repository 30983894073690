import React, {
    useCallback, useEffect, useState
} from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import appConfig from 'app-config';
import { routes } from './MenuBarComponent.json5';
import styles from './MenuBarComponent.module.scss';
import './MenuBarComponent.messages';

const { persona } = appConfig;

const MenuBar = () => {
    const translator = useTranslator();
    const authObject = useAuthentication();
    const { authUserData } = authObject;
    const isAgent = persona === 'producer';
    const [userAccessConfiguration, updateUserAccessConfiguration] = useState({});

    const updateConfiguation = useCallback((accessProperty) => {
        updateUserAccessConfiguration((previousState) => ({
            ...previousState,
            [accessProperty]: true
        }));
    }, []);

    useEffect(() => {
        if (authUserData) {
            const canViewActivities = authUserData?.permissions_Ext.includes('actview_ext');

            if (canViewActivities) {
                updateConfiguation('canViewActivities');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUserData]);

    const routeWithUserAccess = useCallback(
        (route) => {
            if (route.visibleWhen) {
                return userAccessConfiguration[route.visibleWhen] && route;
            }

            return route;
        },
        [userAccessConfiguration]
    );

    const NavItems = useCallback(() => {
        const otherUsersMenu = ['/activities', '/'];
        const menuListByAgent = routes.filter((menu) => isAgent ? routeWithUserAccess(menu) : otherUsersMenu.indexOf(menu.path) !== -1);

        return menuListByAgent.map(({
            title, path, icon, activeClassName
        }) => {
            const activeClass = styles[activeClassName];

            return (
                <NavLink key={path} exact={path === '/'} to={path} activeClassName={activeClass}>
                    {icon && <Icon icon={icon} className={styles.navIcon} />}
                    {translator(title)}
                </NavLink>
            );
        });
    }, [isAgent, routeWithUserAccess, translator]);

    return (
        <div className={styles.menuBar}>
            <NavItems />
        </div>
    );
};

export default MenuBar;
