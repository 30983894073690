import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import config from 'app-config';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { Chevron } from '@jutro/components';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { CoverageUtil, VehicleUtil, FeatureUtil } from 'e1p-portals-util-js';
import { withValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';
import QuoteTableAccordionCardIterableComponent from 'gw-components-platform-react/QuoteTableAccordionCardIterableComponent/QuoteTableAccordionCardIterableComponent';
import UnderwritingIssues from 'gw-components-platform-react/UnderwritingIssues/UnderwritingIssues';
import { nullLiteral } from '@babel/types';
import {
    PackageDifferenceComponent,
    VehiclePackageCovRadioGroupComponent,
    E1PLoader
} from 'e1p-capability-policyjob-react';
import EASingleClauseComponentVM from '../Clauses/SingleClauseComponentVM';
import metadata from './QuoteClauseTable.metadata.json5';
import styles from './QuoteClauseTable.module.scss';
import './QuoteClauseTable.messages';

const BLOCKS_QUOTES = ['BlocksQuote', 'BlocksQuoteRelease'];
const BLOCKS_BIND = 'BlocksBind';

function getChangedDataPath(changedPath, fullChangedPath, pathToChangeTo) {
    const localisedVMPath = changedPath.replace(fullChangedPath, pathToChangeTo);

    return ViewModelUtil.getNonVMPath(localisedVMPath);
}

function getChangedColumnData(changedPathOfModel, columnData) {
    // onBlur is called from Jutro with a object instead of a path
    const path = _.isString(changedPathOfModel) ? changedPathOfModel : changedPathOfModel.model;

    return columnData.find(({ lob }) => _.includes(path, lob.path));
}

function getChangedClause(path, columnData) {
    const changedObjectPath = ClausesUtil.getObjectPathFromChangedPath(path);
    const changedColumn = getChangedColumnData(path, columnData);
    const changedPath = getChangedDataPath(changedObjectPath, changedColumn.lob.path, 'lob.data');

    return _.get(changedColumn, changedPath);
}

function selectMetadata(breakpoint) {
    const contentArray = metadata.componentContent.content;
    let metadataToRender = '';

    if (breakpoint === 'phone') {
        metadataToRender = contentArray.filter((item) => item.id !== 'desktopTable');
    } else {
        metadataToRender = contentArray.filter((item) => item.id !== 'phoneTable' && item.id !== 'mobileAccordionHeader');
    }

    const filteredMetdata = {
        ...metadata,
        componentContent: {
            content: metadataToRender
        }
    };

    return filteredMetdata;
}

function getSelectedCoverages(data) {
    // only return the selected base coverages names
    const coveragesObject = _.get(data, 'lob.data.coverages');
    const coverageNames = Object.keys(coveragesObject).filter((name) => _.includes(name.toLowerCase(), 'coverages'));
    const baseCoverageName = _.first(coverageNames);
    const coverages = _.get(coveragesObject, baseCoverageName);

    return coverages.filter((cov) => cov.selected);
}

const whatsIncludedHandler = async (policyState) => {
    const componentProps = {
        lob: 'PersonalAuto_EA',
        policyState
    };
    const { modalApi } = _.get(window, '__giamfam.modalApi');
    const result = await modalApi.showModal(
        <PackageDifferenceComponent {...componentProps} />
    );

    return result;
};

/**
     *
     * @param {Array} vehicleArray array of all vehicles with each of their coverages
     * @param {Object} cov a coverage
     * @param {String} policyState a policy state
     * @returns {Number} Number of terms from the vehicle that has the most terms
     *   for a given coverage
     */
function getMaxNumberOfTermsForACoverage(vehicleArray, cov, policyState) {
    let maxTerms = 0;
    let threeLineLabel = false;

    let twoThreeLineLabel = false;

    // For two terms with one line label names and content
    let oneLineLabel = false;

    vehicleArray?.forEach((veh) => {

        const match = veh.coverages.find((vehCov) => cov.publicID === vehCov.publicID);
        const filteredTerms = match?.terms?.filter(
            (term) => CoverageUtil.isCovTermVisible(term.type, policyState)
        );

        // If two terms with two line names + (optional)
        // Skip terms which have isHiddenInVersion set to true while calculation terms
        if (!twoThreeLineLabel) {
            twoThreeLineLabel = match?.terms.length > 0
                && !match?.terms[0].isHiddenInVersion
                && match?.terms[0].name.length > 38
                && !match.terms[0].required
                && match?.terms[1]
                && !match?.terms[1].isHiddenInVersion
                && match?.terms[1].name.length > 38
                && !match.terms[1].required;
        }

        // If two terms with two lines or two terms with one term one line and second term on three lines
        // Skip terms which have isHiddenInVersion set to true while calculation terms
        if (!threeLineLabel) {
            threeLineLabel = match?.terms.length > 0
                && !match?.terms[0].isHiddenInVersion
                && match?.terms[0].name.length > 25
                && match?.terms[1]
                && !match?.terms[1].isHiddenInVersion
                && match?.terms[1].name.length > 25;
        }

        // For two terms with one line label names and content
        if (!oneLineLabel) {
            oneLineLabel = match?.terms.length > 0
                && !match?.terms[0].isHiddenInVersion
                && match?.terms[0].name.length > 0
                && match?.terms[1]
                && !match?.terms[1].isHiddenInVersion
                && match?.terms[1].name.length > 0;
        }

        // When filteredTerms is undefined, using Math.max with undefined will result in NaN
        if (!_.isUndefined(filteredTerms)) {
            maxTerms = Math.max(maxTerms, filteredTerms?.length);
        }
    });

    return {
        maxNumOfTermsForRow: maxTerms,
        threeLineLabel,
        twoThreeLineLabel,
        oneLineLabel
    };
}

;

const getAllVehicleCoverages = (tableData) => {
    const vehicleArray = _.cloneDeep(tableData.filter((cov) => cov.coverageType === 'vehicleCoverages'));

    vehicleArray.forEach((tableInfo) => {
        tableInfo.data = tableInfo.data.filter((elt) => elt.publicID !== 'EA_GoldCoverageBundle'
            && elt.publicID !== 'EA_PlatinumCoverageBundle');
        tableInfo.tableContent.forEach((offering) => {
            offering.clauses = offering.clauses.filter((elt) => elt.coverageCategoryCode !== 'EA_EndorsementPackage');
        });
    });

    // Vehicles can have a different set of coverages
    // we need to get the set off all coverages and pass in that as data
    // (EX: one vehicle may have OEM and the others dont, we need to show that cell
    // as no available instead of showing a misalligned grid)
    const allVehicleCoverages = _.uniqBy(vehicleArray.flatMap((vehicle) => (
        vehicle.tableContent[0].clauses)
        .filter((coverage) => !coverage.isHiddenInVersion)
    ), 'publicID');

    return allVehicleCoverages;
};


const moneyPropsTypes = PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.number
});

class QuoteClauseTable extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        columnData: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string,
            quote: PropTypes.shape({
                path: PropTypes.string,
                premium: PropTypes.shape({
                    monthlyPremium: moneyPropsTypes,
                    total: moneyPropsTypes,
                })
            })
        })).isRequired,
        tableData: PropTypes.arrayOf(PropTypes.shape({
            header: PropTypes.string,
            data: PropTypes.arrayOf(PropTypes.shape({
                publicID: PropTypes.string
            })),
            tableContent: PropTypes.arrayOf(PropTypes.shape({
                path: PropTypes.string,
                clauses: PropTypes.arrayOf(PropTypes.shape({})),
                code: PropTypes.string
            }))
        })).isRequired,
        underwritingIssues: PropTypes.arrayOf(PropTypes.shape({})),
        onBuyNow: PropTypes.func,
        onRecalculate: PropTypes.func,
        onChangeSubmissionAndSync: PropTypes.func,
        onChangeSubmission: PropTypes.func,
        onSyncCoverages: PropTypes.func,
        onStaleQuoteBranchCode: PropTypes.func,
        onDisableNextForTable: PropTypes.func.isRequired,
        viewOnlyMode: PropTypes.bool,
        onCancel: PropTypes.func,
        isPageSubmitted: PropTypes.bool,
        updateIsPageSubmitted: PropTypes.func,
        isSurchargeLineCoverageAvailable: PropTypes.bool,
        alwaysShowSurchargeComponent: PropTypes.bool,
        onClauseChangeForceUpdate: PropTypes.func,
        opCo: PropTypes.string.isRequired
    };

    static defaultProps = {
        onBuyNow: undefined,
        onRecalculate: undefined,
        onChangeSubmissionAndSync: undefined,
        onChangeSubmission: undefined,
        onSyncCoverages: undefined,
        onStaleQuoteBranchCode: undefined,
        underwritingIssues: [],
        viewOnlyMode: false,
        onCancel: undefined,
        isPageSubmitted: false,
        updateIsPageSubmitted: () => { },
        isSurchargeLineCoverageAvailable: false,
        alwaysShowSurchargeComponent: false,
        onClauseChangeForceUpdate: undefined
    }


    state = {
        formData: {},
        loadingClause: undefined,
        staleQuoteBranchCodes: this.getStaleCode(),
        resetBranchCodes: [],
        priceDifference: 0,
        fullPaySelected: true,
        platinumSelected: false,
        platStateChanged: false,
        ADDSelectedBeforePlatinum: false,
        ADDSelected: undefined,
        vehicleCoverageArray: undefined, // list of object that is { publicID: string }
        isCompOnlyFeatureAvailable: false,
        loadingCompOnly: false,
        isSurchargeSectionVisible: true,
        isTravelPackageVisible: true,
        isMounted: true,
        policyLevelBundleCoverage: '',
        updatingLineLevelCoverages: false,
        updatingVehicleLevelCoverages: false,
        isTNCCoverageSelected: false
    };

    componentDidMount() {
        const {
            columnData,
            submissionVM,
            updateWizardData,
            viewOnlyMode,
            tableData,
            authUserData
        } = this.props;
        const quoteOfferingPath = 'quoteData.offeredQuotes';
        const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`) || [];
        const selectedVersion = quoteOfferings.find((version) => version.selected);
        const displayedOffering = columnData.length > 1 ? [columnData.find((offering) => offering.code === selectedVersion.branchCode)] : columnData;
        const resetBranchCodes = columnData.map((item) => item.code);

        this.navRef = React.createRef();
        this.setState({ ADDClauseInCoverages: false });

        const hasExtendedAttributeEditability = authUserData?.permissions_Ext.includes('extendedattributeeditability_ext');
        const selectedPaymentPlan = _.find(displayedOffering[0].lob.data.paymentPlans,
            (plan) => plan.isSelected === true);

        this.setState({ resetBranchCodes });
        this.setState({ displayedOffering });
        this.setState({ hasExtendedAttributeEditability });
        this.setState({
            fullPaySelected: selectedPaymentPlan === undefined || selectedPaymentPlan.billingId === 'af:payAFTFull'
        });
        this.setState({ vehicleCovContainerDiv: document.getElementById('gridContainerVehicleCoverageContainer') });
        this.setState({ checkScrolling: true });

        if (_.get(submissionVM, 'lobData.personalAuto_EA.paperlessInd.value') === undefined && !viewOnlyMode) {
            _.set(submissionVM, 'lobData.personalAuto_EA.paperlessInd.value', false);
            updateWizardData(submissionVM);
        }

        const allVehicleCoverages = getAllVehicleCoverages(tableData);

        this.setState({ vehicleCoverageArray: allVehicleCoverages });
        this.setState({ policyLevelBundleCoverage: this.getSelectedPolicyLineCoverageBundle() });

        let policyState = _.get(submissionVM, 'baseData.policyAddress.state.value.code');

        if (viewOnlyMode) {
            // for policy view we dont have baseData object, below change is to
            // read policyAddress from correct path,
            // this will take care of both policyview and transaction view also
            policyState = _.get(
                submissionVM,
                'policyAddress.state.value.code',
                _.get(submissionVM, 'baseData.policyAddress.state.value.code')
            );
        }

        const isCompOnlyFeatureAvailable = FeatureUtil.isCompOnlyFeatureAvailableForState(policyState);
        const isSurchargeSectionVisible = FeatureUtil.isSurchargeSectionAvailableForState(policyState);
        const isTravelPackageVisible = FeatureUtil.isTravelPackageVisibleForState(policyState);
        const isTNCCoverageSelected = CoverageUtil.isCoverageSelected(submissionVM, 'EA_TNCCov');

        this.setState({
            isCompOnlyFeatureAvailable,
            isSurchargeSectionVisible,
            isTravelPackageVisible,
            isTNCCoverageSelected
        });

    }

    componentDidUpdate(prevProps) {
        const { tableData, columnData: newVersion, submissionVM } = this.props;
        const { columnData: oldVersion, tableData: oldTableData } = prevProps;
        const { vehicleCoverageArray } = this.state;

        // Make sure version displayed is correct on update (and enter of sbs)
        if (newVersion[0].code !== oldVersion[0].code) {
            this.selectOffering(newVersion[0].code);
        }

        const allVehicleCoverages = getAllVehicleCoverages(tableData);

        // List of vehicle coverages changes
        if (vehicleCoverageArray.length !== allVehicleCoverages.length) {
            this.setNewVehicleCoverages(allVehicleCoverages);
        }

        const isEqual = _.isEqual(tableData, oldTableData);
        
        if (!isEqual) {
            // if tableData is different i.e coverages are being changed by the user.
            // then check if TNC is selected or not
            this.setTNCCoverageSelected(CoverageUtil.isCoverageSelected(submissionVM, 'EA_TNCCov'));
        }
    }

    componentWillUnmount() {
        this.state.isMounted = false;
    }

    setTNCCoverageSelected = (isSelected) => {
        this.setState({ isTNCCoverageSelected: isSelected });
    }

    setNewVehicleCoverages = (allVehicleCoverages) => {
        this.setState({ vehicleCoverageArray: allVehicleCoverages });
    }

    setUpdatingLineLevelCoverages = (val) => {
        this.setState({ updatingLineLevelCoverages: val });
    }

    setUpdatingVehicleLevelCoverages = (val) => {
        this.setState({ updatingVehicleLevelCoverages: val });
    }

    getTableColumnContent(displayedOffering, tableInfo, vehicleIndex, tableType, cellClassStyleName = 'gwTableCell', isCompOnlyVehicle = false) {
        const columnData = tableInfo.tableContent.find((content) => content.code === displayedOffering[0].code);

        _.set(columnData, 'isCompOnlyVehicle', isCompOnlyVehicle);

        return [{
            id: `${tableType}${vehicleIndex}quoteTableColumn`,
            type: 'element',
            component: 'TableColumn',
            componentProps: {
                cellClassName: cellClassStyleName,
                renderCell: this.renderCellContent,
                data: columnData
            }
        }];
    }

    getCompOnlyTableColumnContent(vehicleObj, vehicleOffering, vehicleIndex, tableType, cellClassStyleName = 'e1pCoverageLabel e1pSmCell') {
        return [{
            id: `${tableType}${vehicleIndex}quoteTableColumn`,
            type: 'element',
            component: 'TableColumn',
            componentProps: {
                cellClassName: cellClassStyleName,
                renderCell: this.renderCompOnlyCellContent,
                data: vehicleObj
            }
        }];
    }

    getVehiclePackageCovTableColumnContent(vehicleOffering, vehicleIndex, tableType, cellClassStyleName = 'e1pCoverageLabel e1pSmCell', isCompOnlyVehicle = false) {
        return [{
            id: `${tableType}${vehicleIndex}quoteTableColumn`,
            type: 'element',
            component: 'TableColumn',
            componentProps: {
                cellClassName: cellClassStyleName,
                renderCell: this.renderVehiclePackageCovCellContent,
                data: vehicleOffering,
                isCompOnlyVehicle,
                vehicleIndex
            }
        }];
    }

    getTotalPremium() {
        const { displayedOffering } = this.state;
        const { columnData, submissionVM } = this.props;

        if (displayedOffering) {
            const branchStaleCode = this.getStaleCode();
            const isQuoteStale = _.includes(branchStaleCode, displayedOffering[0].code) || _.find(columnData, (data) => data.code === displayedOffering[0].code).quote.data.status === 'Draft';

            if (!isQuoteStale) {
                // end of delete
                const payPlans = _.get(columnData[0], 'lob.data.paymentPlans');
                const selectedPlan = _.filter(payPlans, (plan) => plan.isSelected);
                const totalPremium = selectedPlan[0]?.total?.amount;

                if (!totalPremium && totalPremium !== 0) {
                    return undefined;
                }

                _.set(submissionVM, 'unverfiedPremium', totalPremium);

                return { currency: 'usd', amount: totalPremium };
            }
        }

        return undefined;
    }

    getStaleCode() {
        const { onStaleQuoteBranchCode } = this.props;
        let val = [];

        if (onStaleQuoteBranchCode) {
            if (onStaleQuoteBranchCode()) {
                const staleCodes = onStaleQuoteBranchCode();

                if (_.isArray(staleCodes)) {
                    val = staleCodes;
                } else {
                    val.push(staleCodes);
                }
            }
        }

        return val;
    }

    getChangedOfferingPathsAndSetClauseToLoading(path) {
        const { columnData } = this.props;
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;
        const changedColumn = getChangedColumnData(path, columnData);
        const changedClause = getChangedClause(path, columnData);
        const lobPath = changedColumn.lob.path;
        const quotePath = changedColumn.quote.path;
        const updatedBranchCodes = resetBranchCodes.filter((item) => item !== changedColumn.code);

        staleQuoteBranchCodes.push(changedColumn.code);

        this.setState({
            loadingClause: {
                clauseID: changedClause?.coveragePublicID || changedClause?.publicID,
                quoteCode: changedColumn.code
            },
            staleQuoteBranchCodes,
            resetBranchCodes: updatedBranchCodes
        });

        return [lobPath, quotePath];
    }

    removeStaleQuote = (path) => {
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;
        const selectedColumn = _.get(this.props, path);

        const newStateQuoteArray = staleQuoteBranchCodes.filter((item) => (
            item !== selectedColumn.code
        ));
        const newResetBranchArray = resetBranchCodes.filter((item) => (
            item !== selectedColumn.code
        ));

        this.setState({
            staleQuoteBranchCodes: newStateQuoteArray,
            resetBranchCodes: newResetBranchArray
        });
    }

    selectOffering = (code) => {
        const { columnData } = this.props;
        const selectedOffering = columnData.find(((offering) => offering.code === code));

        this.setState({ displayedOffering: [selectedOffering] });
    }

    renderMobileQuoteHeader = (accordionData, index, isFormValid) => (
        (isOpen) => {
            const {
                staleQuoteBranchCodes
            } = this.state;
            const { submissionVM } = this.props;
            const policyState = _.get(
                submissionVM,
                'baseData.policyAddress.state.value.code',
                _.get(submissionVM, 'policyAddress.state.value.code')
            );
            const scheduleStaleCode = this.getStaleCode();

            if (_.isEmpty(staleQuoteBranchCodes) && !_.isEmpty(scheduleStaleCode)) {
                this.setState({ staleQuoteBranchCodes: scheduleStaleCode });
            }

            const isQuoteStale = _.includes(staleQuoteBranchCodes, accordionData.code);
            const price = accordionData.quote.data.premium.total;

            const dataForComponent = {
                price,
                quoteName: accordionData.name,
                selectedCoverages: getSelectedCoverages(accordionData)
            };

            // Due to having a new render content, there may be duplicate ID's
            const overrides = {
                monthlyAmountText: {
                    visible: false
                },
                annuallyAmountText: {
                    visible: true
                },
                buyNowButton: {
                    path: `columnData[${index}]`,
                    visible: !isQuoteStale,
                    onClick: () => this.buyNow(accordionData.quote.path, accordionData.lob.path)
                },
                recalculateButton: {
                    path: `columnData[${index}]`,
                    visible: isQuoteStale,
                    disabled: !isFormValid,
                    onClick: () => this.recalculate(`columnData[${index}]`, accordionData.quote.path, accordionData.lob.path)
                },
                saveButton: {
                    path: `columnData[${index}]`,
                    onClick: () => this.recalculate(`columnData[${index}]`, accordionData.quote.path, accordionData.lob.path)
                },
                selectedCoverages: {
                    visible: !isOpen
                },
                moreInfoContainer: {
                    visible: !isOpen
                },
                accordionChevron: {
                    isOpen
                },
                accordionStickyHeader: {
                    className: classNames(styles.accordionStickyHeader, {
                        [styles.mobileStickyHeader]: isOpen
                    })
                }
            };

            const resolvers = {
                resolveClassNameMap: styles,
                resolveCallbackMap: {
                    whatsIncludedHandler: () => whatsIncludedHandler(policyState)
                }
            };

            const metadataToRender = metadata.contentForMobileView.content;

            return (
                <ViewModelForm
                    uiProps={metadataToRender}
                    model={dataForComponent}
                    overrideProps={overrides}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            );
        }
    )

    updatePaperlessIndAndEmail = (value, path) => {
        const { submissionVM, updateWizardData, setFieldsChangedOnCoveragePage, setIsPaperlessEmailUpdated } = this.props;

        setFieldsChangedOnCoveragePage(true);
        setIsPaperlessEmailUpdated(true);
        _.set(submissionVM, path.replace('submissionVM.', ''), value);

        switch (path) {
            case 'submissionVM.lobData.personalAuto_EA.paperlessInd':
                if (value) {
                    const pniEmail = _.get(submissionVM, 'lobData.personalAuto_EA.primaryNamedInsured.person.emailAddress1.value');

                    if (_.get(submissionVM, 'lobData.personalAuto_EA.paperlessEmail.value') === undefined) {
                        _.set(submissionVM, 'lobData.personalAuto_EA.paperlessEmail.value', pniEmail);
                    }
                } else {
                    _.set(submissionVM, 'lobData.paperlessEmail.value', undefined);
                }

                break;
            case 'submissionVM.lobData.personalAuto_EA.paperlessEmail':
                if (_.get(submissionVM, 'lobData.personalAuto_EA.paperlessEmail.value') === "") {
                    _.set(submissionVM, 'lobData.personalAuto_EA.paperlessEmail.value', undefined);
                }

                break;
            default:
                break;
        }

        updateWizardData(submissionVM);
    }

    renderCellContent = (clauseCellPublicID, index, props) => {
        const { loadingClause, loadingCompOnly } = this.state;
        const {
            onChangeSubmission,
            onValidate,
            tableData,
            viewOnlyMode,
            columnData,
            underwritingIssues,
            authUserData,
            submissionVM
        } = this.props;
        const { path, clauses = [], code, isCompOnlyVehicle } = props.data;
        const scheduleStaleCode = this.getStaleCode();
        const isQuoteStale = _.includes(scheduleStaleCode, code) || columnData[0].status === 'Draft';
        let clauseIndex = clauses.findIndex(
            (clause) => clause.publicID === clauseCellPublicID.publicID
        );
        const clause = clauses[clauseIndex];
        const isQuoteLoading = _.get(loadingClause, 'quoteCode') === code && _.isUndefined(loadingClause?.clauseID);
        const isClauseLoading = _.get(loadingClause, 'quoteCode') === code;
        const hasExtendedAttributeEditability = authUserData?.permissions_Ext.includes('extendedattributeeditability_ext');
        const userRolePremiumViewRoles = authUserData?.permissions_Ext.includes('viewpremium_ext');
        const blocksQuoteUWIssues = underwritingIssues?.some((uwIssue) => BLOCKS_QUOTES.includes(uwIssue.currentBlockingPoint));
        const showPremium = !blocksQuoteUWIssues || userRolePremiumViewRoles;
        const policyState = _.get(
            submissionVM,
            'baseData.policyAddress.state.value.code',
            _.get(submissionVM, 'policyAddress.state.value.code')
        );
        const termDetails = getMaxNumberOfTermsForACoverage(
            columnData[0].lob.data.coverages?.vehicleCoverages, clauseCellPublicID, policyState
        );
        let { maxNumOfTermsForRow } = termDetails;
        const { threeLineLabel, oneLineLabel, twoThreeLineLabel } = termDetails;
        const isTravelPackage = clause?.coverageCategoryCode === 'EA_EndorsementPackage';

        if (!maxNumOfTermsForRow || isTravelPackage) {
            maxNumOfTermsForRow = undefined;
        }

        // Since bundles were split from vehicle we need to fix path for the sync
        if (clauseIndex !== -1 && isTravelPackage) {
            const vehicleCoverages = tableData.filter(
                (coverages) => coverages.coverageType === 'vehicleCoverages'
            );

            const vehicleCoverage = vehicleCoverages?.find((cov) => cov.tableContent[0].path === path);
            const realIndex = vehicleCoverage?.data?.findIndex(
                (elt) => elt.publicID === clauseCellPublicID.publicID
            );

            clauseIndex = realIndex;
        }

        /**
         * if a particular vehicle is comprehensive only,
         * then we need to hide all the coverages except comprehensive coverage
         */
        if (isCompOnlyVehicle && clauseCellPublicID.publicID !== 'EA_Comprehensive') {
            const isLoading = isClauseLoading || isQuoteLoading || loadingCompOnly;

            return (
                <div style={{ height: '40px' }}>
                    {isLoading
                        ? (
                            <E1PLoader
                                loaded={!isLoading}
                            />
                        ) : (<span />)
                    }
                </div>
            );
        }

        if (clauseIndex !== -1) {
            return (
                <EASingleClauseComponentVM
                    value={clause}
                    path={`${path}.children[${clauseIndex}]`}
                    onChangeClause={onChangeSubmission}
                    onSyncCoverages={this.syncCoverages}
                    onChangeSubmissionAndSync={this.changeSubmissionAndSync}
                    loadingClause={isClauseLoading || isQuoteLoading || loadingCompOnly}
                    idPrefex={code}
                    labelTop
                    onValidate={onValidate}
                    viewOnlyMode={viewOnlyMode}
                    isQuoteStale={isQuoteStale}
                    showPremium={showPremium}
                    maxNumOfTermsForRow={maxNumOfTermsForRow}
                    threeLineLabel={threeLineLabel}
                    twoThreeLineLabel={twoThreeLineLabel}
                    oneLineLabel={oneLineLabel}
                    hasExtendedAttributeEditability={hasExtendedAttributeEditability}
                    policyState={policyState}
                />
            );
        }

        const cellHeightStyle = () => {
            // Each vehicle could be checked or unchecked
            // if any vehicles have a cov checked, all boxes need to grow
            // for the max box size for that coverage
            switch (maxNumOfTermsForRow) {
                case undefined:
                    return styles.e1pNotApplicableSmallCell;
                case 1:
                    return styles.e1pNotApplicableSmallCell;
                case 2:
                    // two term clause with two or three line label names
                    if (threeLineLabel) {
                        return styles.twoTermFourRow;
                    }

                    // two term clause with one line label names
                    if (oneLineLabel) {
                        return styles.twoTermOneLine;
                    }

                    return styles.e1pNotApplicableMdCell;
                default:
                    return styles.e1pNotApplicableSmallCell;
            }
        };

        return (
            <div className={cellHeightStyle()}>
                <span className={styles.noCoverageText}>Not applicable</span>
            </div>
        );
    }

    renderVehiclePackageCovCellContent = (data, index, props) => {
        const { loadingClause, loadingCompOnly } = this.state;
        const { viewOnlyMode } = this.props;
        const { isCompOnlyVehicle, vehicleIndex } = props;
        const isQuoteLoading = !_.isEmpty(_.get(loadingClause, 'quoteCode'));
        const isClauseLoading = !_.isEmpty(_.get(loadingClause, 'quoteCode'));

        if (isCompOnlyVehicle) {
            const isLoading = isClauseLoading || isQuoteLoading || loadingCompOnly;

            return (
                <div style={{ height: '40px' }}>
                    {isLoading
                        ? (
                            <E1PLoader
                                loaded={!isLoading}
                            />
                        ) : (<span />)
                    }
                </div>
            );
        }

        return (
            <VehiclePackageCovRadioGroupComponent
                data={data.tableContent[0].clauses}
                index={vehicleIndex}
                isLoading={isClauseLoading || isQuoteLoading || loadingCompOnly}
                onPackageCovChange={this.onPackageCovChange}
                viewOnly={viewOnlyMode}
                basePath={data.tableContent[0].path}
            />
        );
    }

    writeValue = (value, path) => {
        const { formData } = this.state;

        _.set(formData, path, value);
        this.setState({ formData });
    };

    discountChange = (value, path) => {
        const { submissionVM, updateWizardData, setFieldsChangedOnCoveragePage } = this.props;
        const newPath = path.split('submissionVM.')[1];

        // Force a recalculate
        setFieldsChangedOnCoveragePage(true);
        _.set(submissionVM, newPath, value);
        updateWizardData(submissionVM, false);
    };

    syncCoverages = (value, path) => {
        const { onSyncCoverages } = this.props;

        const [lobPath, quotePath] = this.getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onSyncCoverages) {
            onSyncCoverages(value, path, lobPath, quotePath).then(() => {
                this.setState({ loadingClause: undefined });
            });
        }
    }

    updatePlatinumADD = () => {
        const { tableData } = this.props;
        const lineCoverages = tableData[0].tableContent[0].clauses;
        const ADDClause = lineCoverages.find(
            (cov) => cov.codeIdentifier === 'EA_AccidentalDeathAndDismemberment_Line'
        );

        let platinumSelected = false;

        for (let i = 1; i < tableData.length; i += 1) {
            const vehicleCoverages = tableData[i].tableContent[0].clauses;
            const platinumClause = vehicleCoverages?.filter((cov) => cov.codeIdentifier === 'EA_PlatinumCoverageBundle');

            if (platinumClause[0]?.selected) {
                platinumSelected = true;
            }
        }

        if (!platinumSelected) {
            this.setState((prevState) => {
                if (prevState.platinumSelected) {
                    return ({ platinumSelected, platStateChanged: true });
                }
            });
        } else if (platinumSelected) {
            this.setState((prevState) => {
                if (!prevState.platinumSelected) {
                    return ({ platinumSelected, platStateChanged: false });
                }

 return undefined;
            });
        }

        // ADD is not in every product version; running below code will break things
        if (!ADDClause) {
            this.setState({ ADDClauseInCoverages: false });

            return undefined;
        }

        this.setState({ ADDClauseInCoverages: true });


        const ADDSelected = ADDClause.selected;
        const { ADDSelected: ADDState } = this.state;

        if (ADDSelected) {
            this.setState((prevState) => {
                if (!prevState.platinumSelected && prevState.ADDSelected === false) {
                    return ({ ADDSelectedBeforePlatinum: true, ADDSelected });
                }

 return undefined;
            });
        } else if (!ADDSelected) {
            this.setState((prevState) => {
                if (prevState.ADDSelected) {
                    return ({ ADDSelected });
                }

 return undefined;
            });
        }

        if (ADDState === undefined) {
            this.setState({ ADDSelected });
        }

        return undefined;
    }

    changeSubmissionAndSync = (value, path) => {
        const { onChangeSubmissionAndSync } = this.props;
        const [lobPath, quotePath] = this.getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onChangeSubmissionAndSync) {
            return Promise.resolve(onChangeSubmissionAndSync(value, path, lobPath, quotePath).then(() => {
                this.setState({ loadingClause: undefined });
                this.updatePlatinumADD();
            }));
        }
    }

    onPackageCovChange = (value, path) => Promise.resolve(this.changeSubmissionAndSync(value, path))

    // Backend has Split / Combined Single Limit as Checkbox options, we need radio group behavior
    // Method handles switching between Split / Combined Single Limit
    onPolicyLevelCoverageBundleChange = async (value) => {
        const { submissionVM, onChangeSubmission } = this.props;
        // Get index of the correct lobdata offering
        const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
        const quoteOfferingPath = 'quoteData.offeredQuotes';
        const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
        const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`, []);
        const selectedVersion = quoteOfferings.find((version) => version.selected);
        const lobIndex = lobOfferings.findIndex((offering) => offering.branchCode === selectedVersion.branchCode);
        // Find index of the coverage in the correct lob offering
        const lineCoverages = _.get(submissionVM.value, `lobData.personalAuto_EA.offerings.${lobIndex}.coverages.lineCoverages`);
        const splitLimitIndex = _.findIndex(lineCoverages, (cov) => cov.codeIdentifier === 'EA_SplitLimitCoverageBundle');
        const combinedLimitIndex = _.findIndex(lineCoverages, (cov) => cov.codeIdentifier === 'EA_CombinedSingleLimitCoverageBundle');
        const isSplitLimitSelected = value === 'EA_SplitLimitCoverageBundle';
        // We need to update both before calling custom quote api to update coverage,
        // Updating combined limit first & split limit with sync later to update coverage selection

        await onChangeSubmission(!isSplitLimitSelected, `lobData.personalAuto_EA.offerings.children[${lobIndex}].coverages.lineCoverages.children[${combinedLimitIndex}].selected`);
        await this.changeSubmissionAndSync(isSplitLimitSelected, `lobData.personalAuto_EA.offerings.children[${lobIndex}].coverages.lineCoverages.children[${splitLimitIndex}].selected`);
        this.setState({ policyLevelBundleCoverage: value });
    }

    // Returns policy line coverage endorsement bundle radio options (Split / Combined Single Limit)
    getAvailablePolicyLineCoverageBundles = () => {
        const { tableData } = this.props;
        const lineCoverages = _.get(tableData, [0, 'tableContent', 0, 'clauses'], []);

        return _.map(_.filter(lineCoverages, (cov) => cov.coverageCategoryCode === 'EA_EndorsementPackage', []), (cov) => ({
                id: cov.codeIdentifier,
                displayName: cov.name
            }));
    }

    // Returns selected policy line coverage endorsement bundle
    getSelectedPolicyLineCoverageBundle = () => {
        const { tableData } = this.props;
        const lineCoverages = _.get(tableData, [0, 'tableContent', 0, 'clauses'], []);

        return _.get(_.find(lineCoverages, (cov) => cov.coverageCategoryCode === 'EA_EndorsementPackage' && cov.selected), 'codeIdentifier');
    }

    buyNow = (quotePath, lobPath) => {
        const {
            onBuyNow,
            quoteIsStale,
            isComprater,
            onDisableNextForTable,
            updateIsPageSubmitted,
            isComponentValid
        } = this.props;


        let isQuoteBlocked = false;

        if (isComprater) {
            isQuoteBlocked = onDisableNextForTable();
        }

        isQuoteBlocked = isQuoteBlocked || quoteIsStale;

        if (isQuoteBlocked || !isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        if (onBuyNow) {
            onBuyNow(lobPath, quotePath);
        }

        return false;
    }

    recalculate = (columnPath, quotePath, lobPath) => {
        const {
            onRecalculate,
            columnData, isComponentValid,
            updateIsPageSubmitted
        } = this.props;

        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        const changedColumnData = getChangedColumnData(lobPath, columnData);
        let monthlyPayment = 0;

        if (changedColumnData.quote.data.status === 'Quoted') {
            monthlyPayment = changedColumnData.quote.data.premium.monthlyPremium.amount;
        }

        this.setState({
            loadingClause: {
                quoteCode: changedColumnData.code
            }
        });

        if (onRecalculate) {
            // could be saveAndQuote or updateCustomQuote
            onRecalculate(lobPath, quotePath).then((response) => {
                if (this.state.isMounted) {
                    if (!response) {
                        this.setState({
                            loadingClause: undefined
                        });

                        return;
                    }

                    let newMonthlyPayment = nullLiteral;

                    this.removeStaleQuote(columnPath);

                    // customQuoteDTO is different than the quotedataDTO
                    const premiumDTO = response.quote ? response.quote.premium
                        : response.quoteData.offeredQuotes[0].premium;

                    if (changedColumnData.quote.data.status === 'Quoted') {
                        newMonthlyPayment = premiumDTO?.monthlyPremium?.amount;
                    }

                    this.setState({
                        priceDifference: {
                            columnPath,
                            difference: newMonthlyPayment - monthlyPayment
                        },
                        loadingClause: undefined
                    });
                }

            });
        }

        return true;
    }


    generateDesktopHeaderOverrides = () => {
        const {
            columnData,
            onDisableNextForTable,
            isComprater,
            fieldsChangedOnCoveragePage
        } = this.props;

        const {
            displayedOffering,
            staleQuoteBranchCodes,
            priceDifference,
            loadingClause
        } = this.state;


        const scheduleStaleCode = this.getStaleCode();

        if (_.isEmpty(staleQuoteBranchCodes) && !_.isEmpty(scheduleStaleCode)) {
            this.setState({ staleQuoteBranchCodes: scheduleStaleCode });
        }

        const overrides = columnData.map(({ code, quote, lob }, index) => {
            const columnPath = `columnData[${index}]`;
            const { viewOnlyMode, onCancel } = this.props;
            const isQuoteStale = _.includes(staleQuoteBranchCodes, code);
            const clearAmount = { amount: undefined };
            let monthlyValue = clearAmount;
            let annuallyValue = clearAmount;
            let isQuoteBlocked = false;

            if (!_.isEmpty(quote.data)) {
                const { data } = quote;
                const monthlyPremium = { ...clearAmount, ...data.premium.monthlyPremium};
                const total = { ...clearAmount, ...data.premium.total};

                monthlyValue = { ...clearAmount, ...monthlyPremium};
                annuallyValue = { ...clearAmount, ...total};
            }

            if (isComprater) {
                isQuoteBlocked = onDisableNextForTable();
            }

            const monthlyHeader = isQuoteStale ? { [`monthlyAmount${index}`]: { value: monthlyValue } } : {};
            const annuallyHeader = isQuoteStale ? { [`annuallyAmount${index}`]: { value: annuallyValue } } : {};
            const shouldShowPriceDifference = (
                _.includes(priceDifference.columnPath, `[${index}]`)
                && !isQuoteStale
                && priceDifference.difference !== 0
            );

            return {
                [`priceDifference${index}`]: { visible: shouldShowPriceDifference, value: priceDifference.difference },
                [`exitButton${index}`]: {
                    onClick: () => onCancel()
                },
                [`buyNowButton${index}`]: {
                    visible: !isQuoteStale && quote.data.status !== 'Draft' && !fieldsChangedOnCoveragePage
                        && displayedOffering && _.get(displayedOffering[0], 'code') === code,
                    onClick: () => this.buyNow(quote.path, lob.path)
                },
                [`recalculateButton${index}`]: {
                    visible: (isQuoteStale || quote.data.status === 'Draft' || !!fieldsChangedOnCoveragePage)
                        && displayedOffering && _.get(displayedOffering[0], 'code') === code && !viewOnlyMode,
                    disabled: !!(loadingClause),
                    onClick: () => this.recalculate(columnPath, quote.path, lob.path)
                },
                [`saveButton${index}`]: {
                    disabled: !!(loadingClause),
                    onClick: () => this.recalculate(columnPath, quote.path, lob.path)
                },
                [`warningIcon${index}`]: {
                    visible: isQuoteBlocked
                },
                [`topBuyNowButton${index}`]: {
                    visible: !isQuoteStale && quote.data.status !== 'Draft' && !fieldsChangedOnCoveragePage
                        && displayedOffering && _.get(displayedOffering[0], 'code') === code,
                    onClick: () => this.buyNow(quote.path, lob.path)
                },
                [`topRecalculateButton${index}`]: {
                    visible: (isQuoteStale || quote.data.status === 'Draft' || !!fieldsChangedOnCoveragePage)
                        && displayedOffering && _.get(displayedOffering[0], 'code') === code && !viewOnlyMode,
                    disabled: !!(loadingClause),
                    onClick: () => this.recalculate(columnPath, quote.path, lob.path)
                },
                signatureSeperator: {
                    visible: !viewOnlyMode
                },
                ...monthlyHeader,
                ...annuallyHeader
            };
        });

        return Object.assign({}, ...overrides);
    }

    setCheckScrolling = (value) => {
        this.setState({
            checkScrolling: value
        });
    };

    generatePhoneHeaderOverrides() {
        const { tableData, columnData, isComponentValid } = this.props;

        const overrides = columnData.map((data, index) => ({
                [`quoteTableAccordionCardIterableComponentContainer${index}`]: {
                    tableData,
                    columnData: data,
                    isFormValid: isComponentValid,
                    renderAccordionCardHeader: this.renderMobileQuoteHeader,
                    generatePhoneTableOverrides: this.generatePhoneTableOverrides.bind(this),
                    onValueChange: this.writeValue
                }
            }));

        return Object.assign({}, ...overrides);
    }

    generateDesktopTableOverrides() {
        const { tableData, columnData, submissionVM } = this.props;
        const { displayedOffering, vehicleCoverageArray } = this.state;
        const dataToDisplay = displayedOffering || columnData;
        // Vehicle specific coverage table
        const vehicleArray = _.cloneDeep(tableData.filter((cov) => cov.coverageType === 'vehicleCoverages'));
        const vehicleOverrides = vehicleArray.map((tableInfo, index) => {
            const vehicleFixedID = tableInfo.fixedId;
            const vehicleObj = VehicleUtil.getVehicleBasedonFixedID(submissionVM, vehicleFixedID);
            const isCompOnlyVehicle = _.get(vehicleObj, 'compOnlyInd', false);

            return ({
                [`vehicle${index}quoteTable`]: {
                    data: vehicleCoverageArray,
                    content: this.getTableColumnContent(
                        dataToDisplay, tableInfo, index, 'Vehicle', 'gwTableCellVehicle', isCompOnlyVehicle
                    )
                }
            });
        });
        // compOnly specefic coverageTable
        const compOnlyOverrides = [];

        vehicleArray.forEach((vehicleOffering, index) => {
            const vehicleObj = VehicleUtil.getVehicleBasedonFixedID(submissionVM, vehicleOffering.fixedId);

            compOnlyOverrides.push(
                {
                    [`vehicleCompOnly${index}quoteTable`]: {
                        data: [vehicleObj],
                        content: this.getCompOnlyTableColumnContent(
                            vehicleObj, vehicleOffering, index, 'VehicleCompOnly', 'gwTableCellVehicle e1pSmCell'
                        )
                    }
                }
            );
        });

        // vehicle package cov grid
        const vehiclePackageCovOverrides = [];

        vehicleArray.forEach((vehicleOffering, index) => {
            const vehicleObj = VehicleUtil.getVehicleBasedonFixedID(submissionVM, vehicleOffering.fixedId);
            const isCompOnlyVehicle = _.get(vehicleObj, 'compOnlyInd', false);

            vehiclePackageCovOverrides.push(
                {
                    [`vehiclePackageCov${index}quoteTable`]: {
                        data: [vehicleOffering],
                        content: this.getVehiclePackageCovTableColumnContent(
                            vehicleOffering, index, 'vehiclePackageCov', 'gwTableCellEndorsement', isCompOnlyVehicle
                        )
                    }
                }
            );
        });

        return Object.assign({}, ...vehicleOverrides
            .concat(compOnlyOverrides).concat(vehiclePackageCovOverrides));
    }

    generateLineGridOverrides() {
        const {
            viewOnlyMode, submissionVM,
            onChangeSubmission, onSyncCoverages,
            onValidate, isPageSubmitted, columnData
        } = this.props;
        const { hasExtendedAttributeEditability, staleQuoteBranchCodes, policyLevelBundleCoverage, updatingVehicleLevelCoverages } = this.state;
        const policyState = _.get(
            submissionVM,
            'baseData.policyAddress.state.value.code',
            _.get(submissionVM, 'policyAddress.state.value.code')
        );
        const overrides = {};
        const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
        const quoteOfferingPath = 'quoteData.offeredQuotes';
        const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
        const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`, []);
        // Policy view will not have quote data; defaulting to first element stops the screen from crashing
        const DEFAULT_INDEX = 0;
        const quoteIndex = quoteOfferings.findIndex((version) => version?.selected);
        const lobIndex = lobOfferings.findIndex((offering) => offering.branchCode === quoteOfferings[quoteIndex]?.branchCode);
        const isQuoteStale = _.includes(staleQuoteBranchCodes, columnData[0].code);

        overrides.gridContainerLineCoverage = {
            key: `policyLevelCovsWith${policyLevelBundleCoverage}`,
            lobOfferings,
            onChangeSubmissionAndSync: this.changeSubmissionAndSync,
            onChangeSubmission,
            onSyncCoverages,
            onValidate,
            isQuoteStale,
            hasExtendedAttributeEditability,
            policyState,
            lobIndex: lobIndex !== -1 ? lobIndex : DEFAULT_INDEX,
            quoteIndex: quoteIndex !== -1 ? quoteIndex : DEFAULT_INDEX,
            viewOnlyMode,
            showErrors: isPageSubmitted,
            setUpdatingLineLevelCoverages: this.setUpdatingLineLevelCoverages,
            updatingVehicleLevelCoverages
        }

        return overrides;
    }

    generatePhoneTableOverrides() {
        const { columnData: quoteInformation, tableData } = this.props;

        const overrides = quoteInformation.flatMap((quoteInfo, quoteIndex) => tableData.map((tableInfo) => ({
                [`quoteTable${quoteIndex}`]: {
                    data: tableInfo.data,
                    title: tableInfo.header
                },
                [`quoteTableColumn${quoteIndex}`]: {
                    renderCell: this.renderCellContent,
                    data: tableInfo.tableContent.find((content) => content.code === quoteInfo.code)
                }
            })));

        return Object.assign({}, ...overrides);
    }

    generateDesktopOverrides() {
        return {
            ...this.generateDesktopHeaderOverrides(),
            ...this.generateDesktopTableOverrides(),
            ...this.generateLineGridOverrides()
        };
    }


    generatePhoneOverrides() {
        return {
            ...this.generatePhoneHeaderOverrides()
        };
    }

    generateOverrides(breakpoint) {
        const {
            viewOnlyMode,
            modifiers,
            submissionVM,
            authHeader,
            updateWizardData,
            columnData,
            setFieldsChangedOnCoveragePage,
            underwritingIssues,
            authUserData,
            isPageSubmitted,
            isSurchargeLineCoverageAvailable,
            alwaysShowSurchargeComponent,
            onViewQuoteProposal,
            isQuoteProposalReady,
            isSideBySideView,
            onChangeSubmission,
            onSyncCoverages,
            onValidate,
            onCompOnlyChange,
            onCompOnlyChangeForCopyCoverages,
            onClauseChangeForceUpdate,
            opCo
        } = this.props;
        const {
            staleQuoteBranchCodes,
            checkScrolling,
            vehicleCovContainerDiv,
            ADDClauseInCoverages,
            isSurchargeSectionVisible,
            isTravelPackageVisible,
            hasExtendedAttributeEditability,
            isCompOnlyFeatureAvailable,
            updatingLineLevelCoverages,
            isTNCCoverageSelected
        } = this.state;
        const { platStateChanged, ADDSelectedBeforePlatinum } = this.state;
        const isQuoteStale = _.includes(staleQuoteBranchCodes, columnData[0].code);
        const premiumTypeLabel = _.get(
            submissionVM,
            'quoteType_Ext.value.code',
            _.get(submissionVM, 'value.quoteType') // set default
        ) === 'Quick' ? 'Unverified Premium' : 'Verified Premium';
        const paperLessIndValue = _.get(submissionVM, 'lobData.personalAuto_EA.paperlessInd.value') === undefined
            ? false : _.get(submissionVM, 'lobData.personalAuto_EA.paperlessInd.value');
        const breakpointOverrides = breakpoint === 'phone' ? this.generatePhoneOverrides() : this.generateDesktopOverrides();

        const userRolePremiumViewRoles = authUserData?.permissions_Ext.includes('viewpremium_ext');
        const allUWIssuesApproval = underwritingIssues?.every((item) => item?.approvalBlockingPoint === 'NonBlocking');
        const blocksQuoteUWIssues = underwritingIssues?.some((uwIssue) => BLOCKS_QUOTES.includes(uwIssue.currentBlockingPoint));
        const blocksBindUWIssues = underwritingIssues?.some((uwIssue) => BLOCKS_BIND.includes(uwIssue.currentBlockingPoint));
        const hidePremiumForVerified = (_.get(submissionVM, 'quoteType_Ext.value.code') !== 'Quick' && _.get(submissionVM, 'value.quoteType') !== 'Quick') && blocksBindUWIssues;
        const showPremium = (!blocksQuoteUWIssues || userRolePremiumViewRoles || allUWIssuesApproval) && !hidePremiumForVerified;
        const availablePolicyLineCoverageBundles = this.getAvailablePolicyLineCoverageBundles();
        let vehicleDiscountSubtotal = 0;
        let discountSubtotal = 0;
        const lineDiscountSubtotal = _.get(submissionVM, 'lobData.personalAuto_EA.offerings.value[0].premiumSummary', [])
            .find((prem) => prem.premiumSummaryType === 'TotalDiscounts')?.amount || 0;

        _.get(submissionVM, 'lobData.personalAuto_EA.offerings.value[0].coverages.vehicleCoverages').forEach((vehicleCoverage) => {
            vehicleDiscountSubtotal += _.get(vehicleCoverage, 'premiumSummary', [])
                .find((prem) => prem.premiumSummaryType === 'TotalVehicleDiscounts')?.amount || 0;
        })
        discountSubtotal = vehicleDiscountSubtotal + lineDiscountSubtotal;

        const policyState = _.get(
            submissionVM,
            'baseData.policyAddress.state.value.code',
            _.get(submissionVM, 'policyAddress.state.value.code')
        );
        const isLiabilityCovCeded = _.get(submissionVM, 'lobData.personalAuto_EA.cededIndicator.value', false) || _.get(submissionVM, 'lobData.personalAuto_EA.uwcededIndicator.value', false)
        const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
        const quoteOfferingPath = 'quoteData.offeredQuotes';
        const lobOfferings = _.get(submissionVM, `${lobOfferingPath}.value`);
        const quoteOfferings = _.get(submissionVM, `${quoteOfferingPath}.value`, []);
        // Policy view will not have quote data; defaulting to first element stops the screen from crashing
        const DEFAULT_INDEX = 0;
        const quoteIndex = quoteOfferings.findIndex((version) => version?.selected);
        const lobIndex = lobOfferings.findIndex((offering) => offering.branchCode === quoteOfferings[quoteIndex]?.branchCode);
        const isNewBusiness = _.get(submissionVM, 'value.baseData.businessTransactionType_Ext') === 'NewBusiness';

        return {
            '@field': {
                // apply to all fields
                onValueChange: this.writeValue,
                readOnly: viewOnlyMode,
                showErrors: isPageSubmitted,
                showRequired: true,
                autoComplete: false
            },
            buyNowtableHeader: {
                visible: !viewOnlyMode
            },
            LiabilityCovCededMsgDiv: {
                visible: !viewOnlyMode && policyState === 'NC' && isLiabilityCovCeded
            },
            ADDWarningDiv: {
                visible: ADDClauseInCoverages && platStateChanged && !ADDSelectedBeforePlatinum
            },
            physicalDamageCovRemovalMsgDiv: {
                visible: !viewOnlyMode && policyState === 'NC' && _.get(submissionVM, 'lobData.personalAuto_EA.uwcededIndicator.value', false)
            },
            topBuyNowtableHeader: {
                visible: !viewOnlyMode
            },
            eaSurchargeListComponentId: {
                value: modifiers.filter(
                    (item) => item.applied && item.modifierType === "surcharge"
                ),
                visible: isSurchargeSectionVisible && (_.filter(modifiers, { applied: true, modifierType: 'fee' }).length > 0
                    || _.filter(modifiers, { applied: true, modifierType: 'surcharge' }).length > 0
                    || isSurchargeLineCoverageAvailable
                    || alwaysShowSurchargeComponent),
                transactionVM: submissionVM,
                lob: 'PersonalAuto_EA',
                alwaysShowSurchargeComponent
            },
            eaDiscountsListComponentId: {
                visible: !!_.filter(modifiers, { applied: true, modifierType: 'discount' }).length,
                discountsTotal: discountSubtotal !== 0 ? discountSubtotal : undefined
            },
            PaperlessDiscountToggle: {
                required: true,
                value: _.get(submissionVM, 'lobData.personalAuto_EA.paperlessInd.value'),
                onValueChange: this.updatePaperlessIndAndEmail,
                visible: _.get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled'])
            },
            paperlessEmailId: {
                visible: paperLessIndValue && _.get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled']),
                onValueChange: this.updatePaperlessIndAndEmail,
            },
            AutoPayDiscountToggle: {
                // Defaulted BEFORE rate call on vehicles page
                //   Cannot be deaulted here, as that would be after rate
                onValueChange: this.discountChange,
            },
            paymentOptionsID: {
                submissionVM,
                authHeader,
                updateWizardData,
                LOB: 'personalAuto_EA',
                isQuoteStale,
                viewOnly: viewOnlyMode
            },
            policyTermComponent: {
                transactionVM: submissionVM,
                authHeader,
                updateWizardData,
                viewOnly: viewOnlyMode
            },
            premiumTypeLabel: {
                content: premiumTypeLabel
            },
            totalPremiumID: {
                visible: showPremium
            },
            emptyPremium: {
                visible: !showPremium
            },
            monthlyPaymentScheduleComponent: {
                quoteID: _.get(submissionVM, 'quoteID.value', submissionVM.jobID?.value),
                authHeader,
                transactionTotalAmount: this.getTotalPremium(),
                changeInCost: submissionVM.transactionCost?.value,
                startDate: _.get(submissionVM, 'baseData.periodStartDate.value',
                    _.get(submissionVM, 'periodStartDate.value')),
                endDate: _.get(submissionVM, 'baseData.periodEndDate.value',
                    _.get(submissionVM, 'periodStartDate.value')),
                jobTypeCode: _.get(submissionVM, 'baseData.jobType.value.code'),
                offerings: _.get(submissionVM, 'lobData.personalAuto_EA.offerings.value'),
                quoteOfferings: _.get(submissionVM, 'quoteData.offeredQuotes.value', []),
                visible: !(viewOnlyMode)
            },
            supportingPolicyComponentContainer: {
                transactionVM: submissionVM,
                LOB: 'personalAuto_EA',
                updateWizardData,
                viewOnly: viewOnlyMode,
                setFieldsChangedOnCoveragePage
            },
            gridContainerVehicleCoverage: {
                lobOfferings,
                onChangeSubmissionAndSync: this.changeSubmissionAndSync,
                onChangeSubmission,
                onSyncCoverages,
                onValidate,
                hasExtendedAttributeEditability,
                policyState,
                lobIndex: lobIndex !== -1 ? lobIndex : DEFAULT_INDEX,
                quoteIndex: quoteIndex !== -1 ? quoteIndex : DEFAULT_INDEX,
                viewOnlyMode,
                vehicles: _.get(
                    submissionVM,
                    'lobData.personalAuto_EA.coverables.vehicles.value'
                ),
                quoteDataOfferings: _.get(submissionVM, 'quoteData.offeredQuotes.value'),
                isQuoteStale,
                isCompOnlyVisible: isCompOnlyFeatureAvailable && !isNewBusiness,
                onCompOnlyChange,
                policyTransactionVM: submissionVM,
                updateWizardData,
                onCompOnlyChangeForCopyCoverages,
                columnData,
                onForceUpdateCoverages: onClauseChangeForceUpdate,
                updatePlatinumADD: this.updatePlatinumADD.bind(this),
                showErrors: isPageSubmitted,
                setUpdatingVehicleLevelCoverages: this.setUpdatingVehicleLevelCoverages,
                updatingLineLevelCoverages

            },
            scrollingComponentId: {
                navRef: this.navRef,
                checkScrolling,
                setCheckScrolling: this.setCheckScrolling,
                scrollableDiv: vehicleCovContainerDiv,
                scrollWidth: 320
            },
            quoteProposalLinkContainer: {
                visible: !isSideBySideView,
                disabled: viewOnlyMode || !isQuoteProposalReady,
                onClick: (e) => onViewQuoteProposal(e, 'MAIN'),
            },
            policyLevelCoverageBundle: {
                availableValues: availablePolicyLineCoverageBundles,
                value: this.getSelectedPolicyLineCoverageBundle(),
                onValueChange: this.onPolicyLevelCoverageBundleChange,
                // Only make it visible if options are available for policy level endorsement packages
                visible: availablePolicyLineCoverageBundles.length > 0
            },
            whatsIncludedLink: {
                disabled: viewOnlyMode
            },
            travelPackageGrid: {
                visible: isTravelPackageVisible
            },
            tncGapMinimumPDLimitMsgDiv: {
                visible: !viewOnlyMode && policyState === 'IN' && isTNCCoverageSelected
            },
            ...breakpointOverrides
        };
    }

    renderClauseTable = (breakpoint) => {
        const { setComponentValidation, submissionVM } = this.props;
        const { formData, displayedOffering, fullPaySelected } = this.state;
        const policyState = _.get(
            submissionVM,
            'baseData.policyAddress.state.value.code',
            _.get(submissionVM, 'policyAddress.state.value.code')
        );

        const dataForComponent = {
            ...formData,
            // ...,
            fullPaySelected,
            displayedOffering,
            ..._.pick(this.props, ['columnData',
                'tableData',
                'modifiers',
                'submissionVM'
            ]),
            totalPremium: this.getTotalPremium()
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                chevron: Chevron,
                quotetableaccordioncarditerablecomponent: QuoteTableAccordionCardIterableComponent,
                underwritingissues: UnderwritingIssues
            },
            resolveCallbackMap: {
                whatsIncludedHandler: () => whatsIncludedHandler(policyState)
            }
        };
        const generatedMetadata = selectMetadata(breakpoint);

        return (
            <ViewModelForm
                uiProps={generatedMetadata.componentContent}
                model={dataForComponent}
                overrideProps={this.generateOverrides(breakpoint)}
                onValueChange={this.writeValue}
                onValidationChange={setComponentValidation}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    }

    render() {
        return (
            <BreakpointTrackerContext.Consumer>
                {this.renderClauseTable}
            </BreakpointTrackerContext.Consumer>
        );
    }
}

export default withValidation(QuoteClauseTable);
