import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import { PrintComponent } from 'e1p-capability-policyjob-react';
import htmlParser from 'html-react-parser';
import coveragesAsPerStateForEA from 'e1p-portals-util-js/EAWhatsIncluded-config.json';
import metadata from './PackageDifferenceComponent.metadata.json5';
import styles from './PackageDifferenceComponent.module.scss';

import { Button } from '@jutro/legacy/components';

function PackageDifferenceComponent(props) {
    const {
        isOpen,
        onReject,
        isInLine,
        lob,
        policyState
    } = props;

    const coverageDifferenceArray = useMemo(() => {
        if (lob === 'PersonalAuto_EA') {
            return coveragesAsPerStateForEA[policyState];
        }

        return [
            { coverageName: 'Personal Property - Increased Special Limits of Liability (Jewelry, Watches and Furs)', goldValue: '$4,000', platinumValue: '$6,500' },
            { coverageName: 'Coverage C - Personal Property', goldValue: '70% of Coverage A', platinumValue: '70% of Coverage A' },
            { coverageName: 'Additional Amount of Insurance', goldValue: '25%', platinumValue: '50%' },
            { coverageName: 'Personal Property Replacement Cost', goldValue: 'Yes', platinumValue: 'Yes' },
            { coverageName: 'Refrigerated Personal Property', goldValue: 'Yes', platinumValue: 'Yes' },
            { coverageName: 'Water Back-up and Sewer Overflow', goldValue: '$5,000', platinumValue: '$10,000' },
            { coverageName: 'Special Personal Property', goldValue: 'Yes', platinumValue: 'Yes' },
            { coverageName: 'Ordinance or Law', goldValue: 'N/A', platinumValue: '25% of Coverage A' }
        ];
    }, [lob, policyState]);

    const getCell = (items, index, property) => htmlParser(items[property.id]);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        coverageDifferenceTable: {
            data: coverageDifferenceArray
        },
        afterTableInfo: {
            visible: lob === 'PersonalAuto_EA'
        },
        travelPackageTitle: {
            visible: lob === 'PersonalAuto_EA'
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell
        }
    };

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    const viewModel = (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
    const isInLineComponent = (
        <ModalBody>
            {viewModel}
        </ModalBody>
    );

    if (isInLine) {
        return isInLineComponent;
    }

    const printButton = (
        <PrintComponent
            containerIdsToPrint={["EHGoldPlatinumPackageDifferencePageID"]}
        />
    );

    return (
        <ModalNext isOpen={isOpen} className={styles.popUp}>
            <ModalHeader />
            <ModalBody id='EHGoldPlatinumPackageDifferencePageID' >
                {viewModel}
            </ModalBody>
            <ModalFooter>
                {printButton}
                <Button onClick={onCancel} type="filled">
                    {'CLOSE'}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

PackageDifferenceComponent.propTypes = {
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isInLine: PropTypes.bool.isRequired,
    lob: PropTypes.string.isRequired,
    policyState: PropTypes.string
};
PackageDifferenceComponent.defaultProps = {
    policyState: ''
};
export default PackageDifferenceComponent;
