import { defineMessages } from 'react-intl';

export default defineMessages({
    totalHipRoof: {
        id: 'components.E1PWindstormMitigationDiscountModal.Total Hip Roof',
        defaultMessage: 'Total Hip Roof'
    },
    totalHipRoofHelpText: {
        id: 'components.E1PWindstormMitigationDiscountModal.A Total Hip Roof is a roof that slopes in four directions such that the end formed by the intersection of slopes is a triangle',
        defaultMessage: 'A Total Hip Roof is a roof that slopes in four directions such that the end formed by the intersection of slopes is a triangle'
    },
    openingProtection: {
        id: 'components.E1PWindstormMitigationDiscountModal.Opening Protection',
        defaultMessage: 'Opening Protection'
    },
    openingProtectionHelpText: {
        id: 'components.E1PWindstormMitigationDiscountModal.Opening Protection Help Text',
        defaultMessage: 'Building opening protective features must have been certified as having met ASTM E 1886 and ASTM E 1996 or other equivalent, including AAMA 506 or TAS 201 and 203. Qualifying opening protection must be present at all exterior envelope openings (such as windows, garage doors, sliding doors, swinging doors, glass block, door sidelights, and skylights).'
    },
    designation: {
        id: 'components.E1PWindstormMitigationDiscountModal.Designation',
        defaultMessage: 'Designation'
    },
    designationHelpText: {
        id: 'components.E1PWindstormMitigationDiscountModal.Designation Help Text',
        defaultMessage: `Fortified for Safer Living : Prior to March 2019 this designation was Hurricane Fortified for Safer Living \n\nFortified Hurricane/Bronze Existing Roof : Prior to March 2019 this designation was Hurricane Fortified for Existing Homes Bronze Option 1 \n\nFortified Hurricane/Bronze New Roof : Prior to March 2019 this designation was Hurricane Fortified for Existing Homes Bronze Option 2 \n\nFortified Home - Hurricane/Silver Existing Roof : Prior to March 2019 this designation was Hurricane Fortified for Existing Homes Silver Option 1 \n\nFortified Home - Hurricane/Silver New Existing Roof : Prior to March 2019 this designation was Hurricane Fortified for Existing Homes Silver Option 2 \n\nFortified Home - Hurricane/Gold Existing Roof : Prior to March 2019 this designation was Hurricane Fortified for Existing Homes Gold Option 1 \n\nFortified Home - Hurricane/Gold New Roof : Prior to March 2019 this designation was Hurricane Fortified for Existing Homes Gold Option 2`
    },
    designationExpirationDate: {
        id: 'components.E1PWindstormMitigationDiscountModal.Designation Expiration Date',
        defaultMessage: 'Designation Expiration Date'
    }
});
