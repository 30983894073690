import { defineMessages } from 'react-intl';

export default defineMessages({
    supportingPolicyEHToolTip: {
        id: 'e1p.platform.lob.eh.Supporting Policies are used to determine eligibility for Multi-Product Discount, Secondary/Seasonal policies and Policy Conversion Discount. Multi-Product Discount applies to any Property policy whose named insured is also a named insured on an Auto policy with a qualifying company.',
        defaultMessage: 'Supporting Policies are used to determine eligibility for Multi-Product Discount, Secondary/Seasonal policies and Policy Conversion Discount. Multi-Product Discount applies to any Property policy whose named insured is also a named insured on an Auto policy with a qualifying company.'
    },
    safetyFirstDeviceTypesRequiredInfoMessageBasedOnCovA: {
        id: 'e1p.platform.lob.eh.Central fire alarm and central burglar alarm are required when Coverage A is greater than $750,000.',
        defaultMessage: 'Central fire alarm and central burglar alarm are required when Coverage A is greater than $750,000.'
    },
    secureHomeDeviceTypesRequiredInfoMessageBasedOnCovA: {
        id: 'e1p.platform.lob.eh.Professionally Monitored Fire and Burglar Alarms are required based on Coverage A.',
        defaultMessage: 'Professionally Monitored Fire and Burglar Alarms are required based on Coverage A.'
    },
    savingPrefillData: {
        id: 'e1p.platform.lob.eh.Saving Prefill Data...',
        defaultMessage: 'Saving Prefill Data...'
    },
    recalculatingReplacementCostAndSavingAllInfo: {
        id: 'e1p.platform.lob.eh.Recalculating Replacement Cost and Saving Data...',
        defaultMessage: 'Recalculating Replacement Cost and Saving Data...'
    },
    addAnother: {
        id: 'e1p.platform.lob.eh.Add',
        defaultMessage: '+ Add'
    },
    sendBillToTPI: {
        id: 'e1p.platform.lob.eh.Send Bill to TPI?',
        defaultMessage: 'Send Bill to TPI?'
    },
    billMortgagee: {
        id: 'e1p.platform.lob.eh.Bill Mortgagee',
        defaultMessage: 'Bill Mortgagee'
    },
    thirdPartyInterestOfTrust: {
        id: 'e1p.platform.lob.eh.Third-Party Interest of Trust',
        defaultMessage: 'Third-Party Interest of Trust'
    },
    thirdPartyInterestOfTrustTooltip: {
        id: 'e1p.platform.lob.eh.An additional premium applies when Residence Held In Trust applies for the North Carolina Rate Bureau rating plan.',
        defaultMessage: 'An additional premium applies when Residence Held In Trust applies for the North Carolina Rate Bureau rating plan.'
    },
    tpiPresenceOfTrustWillBeSelected: {
        id: 'e1p.platform.lob.eh.Third-Party Interest of Trust was not selected, but a trust was saved as a Third-Party Interest. Third-Party Interest of Trust will be selected, and premium will be recalculated.',
        defaultMessage: 'Third-Party Interest of Trust was not selected, but a trust was saved as a Third-Party Interest. Third-Party Interest of Trust will be selected, and premium will be recalculated.'
    },
    tpiPresenceOfTrustWillBeRemoved: {
        id: 'e1p.platform.lob.eh.Third-Party Interest of Trust was selected, but no trust was saved as a Third-Party Interest. Third-Party Interest of Trust will be removed, and premium will be recalculated.',
        defaultMessage: 'Third-Party Interest of Trust was selected, but no trust was saved as a Third-Party Interest. Third-Party Interest of Trust will be removed, and premium will be recalculated.'
    },
    protectiveDevices: {
        id: 'e1p.platform.lob.eh.Protective Devices',
        defaultMessage: 'Protective Devices'
    },
    newHomePurchase: {
        id: 'e1p.platform.lob.eh.New Home Purchase',
        defaultMessage: 'New Home Purchase'
    },
    newHomePurchaseTooltip: {
        id: 'e1p.platform.lob.eh.New purchase within 180 days',
        defaultMessage: 'New purchase within 180 days'
    },
    exterior: {
        id: 'e1p.platform.lob.eh.Exterior',
        defaultMessage: 'Exterior'
    },
    exteriorSidingType: {
        id: 'e1p.platform.lob.eh.Exterior Siding Type',
        defaultMessage: 'Exterior Siding Type'   
    },
    propertyCoverages: {
        id: 'e1p.platform.lob.eh.Property Coverages',
        defaultMessage: 'Property Coverages'
    },
    sectionICoverage: {
        id: 'e1p.platform.lob.eh.Section I Coverages',
        defaultMessage: 'Section I Coverages'
    },
    sectionIICoverage: {
        id: 'e1p.platform.lob.eh.Section II Coverages',
        defaultMessage: 'Section II Coverages'
    },
    deductibles: {
        id: 'e1p.platform.lob.eh.Deductibles',
        defaultMessage: 'Deductibles'
    },
    optionalCoverages: {
        id: 'e1p.platform.lob.eh.Optional Coverages',
        defaultMessage: 'Optional Coverages'
    },
    editOptionalCoverages: {
        id: 'e1p.platform.lob.eh.EDIT OPTIONAL COVERAGES',
        defaultMessage: 'EDIT OPTIONAL COVERAGES'
    },
    appliedOptionalCoverages: {
        id: 'e1p.platform.lob.eh.Applied Optional Coverages',
        defaultMessage: 'Applied Optional Coverages'
    },
    isBillingSameAsMailingHelpText: {
        id: 'e1p.platform.lob.eh.Insured Billing Address if not Bill Mortgagee.',
        defaultMessage: 'Insured Billing Address if not Bill Mortgagee.'
    },
    removeEHGarage: {
        id: 'e1p.platform.lob.eh.Remove Garage?',
        defaultMessage: 'Remove Garage?'
    },
    removeEHGarageDescription: {
        id: 'e1p.platform.lob.eh.The selected Garage will be removed.',
        defaultMessage: 'The selected Garage will be removed.'
    },
    removeInsideWallMaterial: {
        id: 'e1p.platform.lob.eh.Remove Interior Wall Type?',
        defaultMessage: 'Remove Interior Wall Type?'
    },
    removeInsideWallMaterialDescription: {
        id: 'e1p.platform.lob.eh.The selected Interior Wall Type will be removed.',
        defaultMessage: 'The selected Interior Wall Type will be removed.'
    },
    removeFloorMaterial: {
        id: 'e1p.platform.lob.eh.Remove Floor Material?',
        defaultMessage: 'Remove Floor Material?'
    },
    removeFloorMaterialDescription: {
        id: 'e1p.platform.lob.eh.The selected Floor Material will be removed.',
        defaultMessage: 'The selected Floor Material will be removed.'
    },
    removeFirePlace: {
        id: 'e1p.platform.lob.eh.Remove Fire Place?',
        defaultMessage: 'Remove Fire Place?'
    },
    removeFirePlaceDescription: {
        id: 'e1p.platform.lob.eh.The selected Fire Place will be removed.',
        defaultMessage: 'The selected Fire Place will be removed.'
    },
    selectedOptionalCovLabel: {
        id: 'e1p.platform.lob.eh.Mandatory and selected optional coverages',
        defaultMessage: 'Mandatory and selected optional coverages'
    },
    isItOneOfTheFollowingBreeds: {
        id: 'e1p.platform.lob.eh.Is it any of the following breeds:',
        defaultMessage: 'Is it any of the following breeds:'
    },
    akitaAndPitbull: {
        id: 'e1p.platform.lob.eh.- Akita &emsp; &emsp; &emsp; &emsp; &emsp; &ensp;- Pit Bull',
        defaultMessage: '- Akita &emsp; &emsp; &emsp; &emsp; &emsp; &ensp;- Pit Bull'
    },
    alaskanAndRottweiler: {
        id: 'e1p.platform.lob.eh.- Alaskan Malamute &ensp;  - Rottweiler',
        defaultMessage: '- Alaskan Malamute &ensp;  - Rottweiler'
    },
    chowChowAndStaffordshire: {
        id: 'e1p.platform.lob.eh.- Chow-Chow &emsp; &emsp; &emsp; - Staffordshire Terrier',
        defaultMessage: '- Chow-Chow &emsp; &emsp; &emsp; - Staffordshire Terrier'
    },
    doberman: {
        id: 'e1p.platform.lob.eh. - Doberman',
        defaultMessage: ' - Doberman'
    },
    limit: {
        id: 'e1p.platform.eh.Limit',
        defaultMessage: 'Limit'
    },
    specialLossSettlement: {
        id: 'e1p.platform.eh.Special Loss Settlement',
        defaultMessage: 'Special Loss Settlement'
    },
    atFaultAutoLossesLabel: {
        id: 'e1p.platform.eh.Have you had any Auto At-Fault losses in the past 3 years?',
        defaultMessage: 'Have you had any Auto At-Fault losses in the past 3 years?'
    },
    autoCompLossesLabel: {
        id: 'e1p.platform.eh.Have you had any Auto Comp losses in the past 3 years?',
        defaultMessage: 'Have you had any Auto Comp losses in the past 3 years?'
    },
    viewOptionalCoverages: {
        id: 'e1p.platform.common.VIEW OPTIONAL COVERAGES',
        defaultMessage: 'VIEW OPTIONAL COVERAGES'
    },
    propertyInformation: {
        id: 'e1p.platform.lob.eh.Property Information',
        defaultMessage: 'Property Information'
    },
    animals: {
        id: 'e1p.platform.lob.eh.Animals',
        defaultMessage: 'Animals'
    },
    dogsLabel: {
        id: 'e1p.platform.lob.eh.Dog(s) on Property?',
        defaultMessage: 'Dog(s) on Property?'
    },
    doesAnyDogHaveAnyBiteHistory: {
        id: 'e1p.platform.lob.eh.Does any dog have a bite history?',
        defaultMessage: 'Does any dog have a bite history?'
    },
    numberOfUnits: {
        id: 'e1p.platform.lob.eh.Number of Units',
        defaultMessage: 'Number of Units'
    },
    loadingNextPageMessage: {
        id: 'e1p.platform.lob.eh.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    yearBuilt: {
        id: 'e1p.platform.lob.eh.Year Built',
        defaultMessage: 'Year Built'
    },
    heatingAndCooling: {
        id: 'e1p.platform.lob.eh.Heating And Cooling',
        defaultMessage: 'Heating and Cooling'
    },
    primaryHeatingSystem: {
        id: 'e1p.platform.lob.eh.Primary Heating System',
        defaultMessage: 'Primary Heating System'
    },   
    styleOfHome: {
        id: 'e1p.platform.lob.eh.Style Of Home',
        defaultMessage: 'Style of Home'
    },
    numberInHousehold: {
        id: 'e1p.platform.lob.eh.Number of Residents/Tenants',
        defaultMessage: 'Number of Residents/Tenants'
    },
    numberOfStories: {
        id: 'e1p.platform.lob.eh.Number of Stories',
        defaultMessage: 'Number of Stories'
    },
    totalSquareFootage: {
        id: 'e1p.platform.lob.eh.Total Square Feet (living area not basement)',
        defaultMessage: 'Total Square Feet (living area not basement)'
    },
    yearRoofInstalled: {
        id: 'e1p.platform.lob.eh.Year Roof Installed',
        defaultMessage: 'Year Roof Installed'
    },
    roofMaterial: {
        id: 'e1p.platform.lob.eh.Roof Type',
        defaultMessage: 'Roof Type'
    },
    roofShape: {
        id: 'e1p.platform.lob.eh.Roof Shape',
        defaultMessage: 'Roof Shape'
    },
    garageType: {
        id: 'e1p.platform.lob.eh.Garage Type',
        defaultMessage: 'Garage Type'
    },
    garageSize: {
        id: 'e1p.platform.lob.eh.Garage Size',
        defaultMessage: 'Garage Size'
    },
    propertySlope: {
        id: 'e1p.platform.lob.eh.Property Slope',
        defaultMessage: 'Property Slope'
    },
    swimmingPool: {
        id: 'e1p.platform.lob.eh.Swimming Pool',
        defaultMessage: 'Swimming Pool'
    },
    swimmingPoolFence: {
        id: 'e1p.platform.lob.eh.Is swimming pool enclosed by a locked fence of at least 4 feet high?  Or for above‐ground pools not enclosed by a 4 foot fence, is the ladder removable and stairs lock in an upright position when not in use?',
        defaultMessage: 'Is swimming pool enclosed by a locked fence of at least 4 feet high?  Or for above‐ground pools not enclosed by a 4 foot fence, is the ladder removable and stairs lock in an upright position when not in use?'
    },
    sixtyFtFence: {
        id: 'e1p.platform.lob.eh.60 Ft of Fence or more',
        defaultMessage: '60 Ft of Fence or more'
    },
    cathedralVaultedCeilings: {
        id: 'e1p.platform.lob.eh.Rooms with Cathedral/Vaulted Ceilings',
        defaultMessage: 'Rooms with Cathedral/Vaulted Ceilings'
    },
    crownMolding: {
        id: 'e1p.platform.lob.eh.Rooms with Crown Molding ',
        defaultMessage: 'Rooms with Crown Molding '
    },
    ceilingHeight: {
        id: 'e1p.platform.lob.eh.Ceiling Height',
        defaultMessage: 'Ceiling Height'
    },
    fullBaths: {
        id: 'e1p.platform.lob.eh.Full Baths',
        defaultMessage: 'Full Baths'
    },
    halfBaths: {
        id: 'e1p.platform.lob.eh.Half Baths',
        defaultMessage: 'Half Baths'
    },
    kitchenCountertop: {
        id: 'e1p.platform.lob.eh.Kitchen Countertop',
        defaultMessage: 'Kitchen Countertop'
    },
    insideWallMaterials: {
        id: 'e1p.platform.lob.eh.Interior Wall Type',
        defaultMessage: 'Interior Wall Type'
    },    
    insideWallMaterialSumOfPercentageError: {
        id: 'e1p.platform.lob.eh.Sum of the percentage values entered for wall covering type  should be 100',
        defaultMessage: 'Sum of the percentage values entered for wall covering type should be 100'
    },
    floorMaterials: {
        id: 'e1p.platform.lob.eh.Floor Type',
        defaultMessage: 'Floor Type'
    },
    floorMaterialPercentage: {
        id: 'e1p.platform.lob.eh.Percentage',
        defaultMessage: 'Percentage'
    },
    floorMaterialSumOfPercentageError: {
        id: 'e1p.platform.lob.eh.Sum of the percentage values entered for floor type should be 100',
        defaultMessage: 'Sum of the percentage values entered for floor type should be 100'
    },
    foundationType: {
        id: 'e1p.platform.lob.eh.Foundation Type',
        defaultMessage: 'Foundation Type'
    },
    finishedBasementPercentage: {
        id: 'e1p.platform.lob.eh.Finished Basement Percentage',
        defaultMessage: 'Finished Basement Percentage'
    },
    primaryCoolingSystem: {
        id: 'e1p.platform.lob.eh.Primary Cooling System',
        defaultMessage: 'Primary Cooling System'
    },
    woodCoalStove: {
        id: 'e1p.platform.lob.eh.Wood/Coal Stove',
        defaultMessage: 'Wood/Coal Stove'
    },
    firePlaceType: {
        id: 'e1p.platform.lob.eh.Fireplace Type',
        defaultMessage: 'Fireplace Type'
    },
    replacementCostTitle: {
        id: 'e1p.platform.lob.eh.Replacement Cost',
        defaultMessage: 'Replacement Cost'
    },
    recalculateReplacementCost: {
        id: 'e1p.platform.lob.eh.RECALCULATE',
        defaultMessage: 'RECALCULATE'
    },
    replacementCostFieldInfo1: {
        id: 'e1p.platform.lob.eh.Fields Marked with',
        defaultMessage: 'Fields Marked with'
    },
    replacementCostFieldInfo2: {
        id: 'e1p.platform.lob.eh.are used to Calculate Replacement Cost',
        defaultMessage: 'are used to Calculate Replacement Cost'
    },
    loadingPrefill: {
        id: 'e1p.platform.lob.eh.Loading prefill data...',
        defaultMessage: 'Loading prefill data...'
    },
    interior: {
        id: 'e1p.platform.lob.eh.Interior',
        defaultMessage: 'Interior'
    },
    basementTitle: {
        id: 'e1p.platform.lob.eh.Basement',
        defaultMessage: 'Basement'
    },
    yes: {
        id: 'e1p.platform.lob.eh.Yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'e1p.platform.lob.eh.No',
        defaultMessage: 'No'
    },  
    emptyOptionalCovLabel: {
        id: 'e1p.platform.lob.eh.No mandatory or optional coverages are selected.',
        defaultMessage: 'No mandatory or optional coverages are selected.'
    },
    homeowners: {
        id: 'e1p.platform.lob.eh.Homeowners',
        defaultMessage: 'Homeowners'
    }
});
