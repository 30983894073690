import React, {
    useCallback,
    useEffect,
    useState,
    useContext
} from 'react';
import {
    get as _get,
    set as _set,
    includes as _includes
} from 'lodash';
import {
    ViewModelForm,
} from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';

import {
    useAuthentication,
    withAuthenticationContext
} from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import {
    useViewPolicyUtil
} from 'e1p-capability-hooks';
import appConfig from 'app-config';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import metadata from '../InsuredDetailsPage.metadata.json5';
import requiredMetadata from '../InsuredDetailsPage.requiredness';

const LOB = 'personalAuto_EA';

function ViewInsuredDetailsPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        viewOnly,
        markFurthestStep,
        steps,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        changeNextSteps,
        currentStep
    } = props;
    const { authHeader } = useAuthentication();
    const [isSavingRenewal] = useState(false);
    const [requiredFields, updateRequiredFields] = useState([]);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const policyState = _get(renewalVM, 'baseData.policyAddress.state.value.code');
    const { opCo } = useContext(AmfamOktaTokenContext);

    const {
        getLandingPageIndexForViewOnlyPolicy,
        getProducerDetails
    } = useViewPolicyUtil();

    // Add assignment page if the state is enabled
    const adjustStepsForState = useCallback(async () => {
        // check for enabled state
        const enabledStates = appConfig.driverAssignmentStates;
        const nextSteps = [...steps];
        const driverAssignmentStepIndex = nextSteps.findIndex((step) => step.path === '/view-assignment');

        // not needed for state and does not exist in steps already
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex < 0) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // not needed for the state, but previous state selected needed it
        if (!enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.splice(driverAssignmentStepIndex, 1); // remove
            nextSteps.shift(); // remove current page (always first elt)
            changeNextSteps(nextSteps);

            return nextSteps;
        }

        // needed for the state, and already has it
        if (enabledStates.includes(policyState) && driverAssignmentStepIndex > -1) {
            nextSteps.shift(); // need to remove for return value even though not changing

            return nextSteps;
        }

        // needed for the state and not already in the steps
        const driverAssignmentPage = {
            id: 'RenewalViewEADriverAssignmentPage',
            title: {
                id: 'quoteandbind.ea.wizard.step.Drivers Assignment',
                defaultMessage: 'Driver Assignment'
            },
            path: '/view-assignment',
            component: 'ViewEARenewalDriverAssignmentPage',
            stepProps: {
                template: 'WizardPageTemplateWithTitle'
            }
        };
        const indexOfVehiclePage = nextSteps.findIndex((step) => step.path === '/view-risk-analysis');

        // insert driver assignment
        nextSteps.splice(indexOfVehiclePage, 0, driverAssignmentPage)
        // remove current page (always first elt)
        nextSteps.shift();
        changeNextSteps(nextSteps);

        return nextSteps;
    }, [changeNextSteps, policyState, steps]);


    useEffect(() => {
        //   keeping the IF condition here, but under no circumstances should it be undefined
        if (renewalVM.baseData.externalID_Ext.value) {
            getProducerDetails(
                renewalVM.baseData.externalID_Ext.value, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
            }).catch((error) => {
                /**
                 * E1PAP1PC-15099 :
                 * This is rare scenario; will never happen in production
                 * When we bound policy using dummy producer code
                 * and when other user tries to view this policy we get exception from service
                 */
                if (renewalVM.baseData.value.exceptions_Ext) {
                    renewalVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        renewalVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(renewalVM);
            });
        }
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // change steps before jumping
        adjustStepsForState().then((newSteps) => {
            // need all steps
            newSteps.unshift(currentStep);

            if (isPageJumpEnabled) {
                updateIsPageJumpEnabled(false);
                jumpTo(getLandingPageIndexForViewOnlyPolicy(LOB, newSteps, 'Renewal'), true);
            }

            markFurthestStep((newSteps.length - 1));
            // Only check one time on component mount if we want to jump to other page
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initialRequiredFields = ['applicantsResideInSameStateInd', 'isUmbrellaAssociated']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
    }, [policyState]);


    const onNext = useCallback(() => renewalVM, [renewalVM]);

    const handleAddressValueChange = useCallback(
        (newVal, localPath) => {
            _set(renewalVM, localPath, newVal);
            updateWizardData(renewalVM);
        },
        [renewalVM, updateWizardData]
    );

    const generateOverrides = useCallback(() => {
        const overrides = {};

        overrides.secondaryNamedInsured = {
            viewOnlyMode: viewOnly
        };
        overrides.primaryNamedInsured = {
            viewOnlyMode: viewOnly,
            isPNI: true
        };

        return overrides;
    }, [viewOnly]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        insuredDetailsPageLoadingIndicator: {
            loaded: true
        },
        insuredDetailsPageContainer: {
            visible: !isSavingRenewal && !isSkipping && !isStandardizingAddress
        },
        mailingAndBillingAddressComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            onValidate: () => {},
            lob: LOB,
            viewOnlyMode: viewOnly
        },
        addSni: {
            visible: false
        },
        addNewPni: {
            visible: false
        },
        agencyName: {
            value: producerCodeDetails.agencyName
        },
        bookTransferIndicator: {
            value: producerCodeDetails.bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: producerCodeDetails.serviceCenterIndicatorValue
        },
        producerAddressComponent: {
            address: _get(producerCodeDetails, 'address'),
            phone: _get(producerCodeDetails, 'phone')
        },
        swapPniSni: {
            visible: false
        },
        sniContainer: {
            visible: !!_get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.value`),
        },
        periodStartDate: {
            dateDTO: renewalVM.baseData.periodStartDate,
            updateDateDto: () => {}
        },
        periodEndDate: {
            dateDTO: renewalVM.baseData.periodEndDate,
            updateDateDto: () => {}
        },
        policyInformationInfoContainer: {
            columns: ['0.25fr', '0.25fr', '0.25fr']
        },
        dateOfBirth: {
            updateDateDto: () => {},
            dateDTO: renewalVM.lobData.personalAuto_EA.primaryNamedInsured.person.dateOfBirth,
        },
        excessLiabilityInd: {
            readOnly: true,
            visible: _includes(requiredFields, 'isUmbrellaAssociated'),
        },
        insuredResidenceAddress: {
            addressVM: _get(renewalVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate: () => {},
            onAddressChange: (value, path) => handleAddressValueChange(value, `baseData.policyAddress.${path}`),
            viewOnlyMode: true,
            showParentLoader: setIsStandardizingAddress
        },
        changePniComponent: {
            accountNumber: _get(renewalVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: false,
            allowNewContact: false,
            excludedContacts: [
                _get(renewalVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            drivers: _get(
                renewalVM.value,
                `lobData[${LOB}].coverables.drivers`,
                undefined
            )
        },
        changeSniComponent: {
            accountNumber: _get(renewalVM, 'baseData.accountNumber.value'),
            authHeader,
            visible: false,
            excludedContacts: [
                _get(renewalVM, `lobData[${LOB}].primaryNamedInsured.person.publicID.value`, ''),
                _get(renewalVM, `lobData[${LOB}].secondaryNamedInsured.person.publicID.value`, '')
            ],
            drivers: _get(
                renewalVM.value,
                `lobData[${LOB}].coverables.drivers`,
                undefined
            )
        },
        internalProducerDetailsComponent: {
            defaultValue: renewalVM.baseData.externalID_Ext?.value,
            visible: true,
            viewOnlyMode: true
        },
        locationCode: {
            visible: false
        },
        applicantsResideInSameStateInd: {
            visible: _includes(requiredFields, 'applicantsResideInSameStateInd'),
            readOnly: true
        },
        removeSni: {
            visible: false
        },
        agencyInformationMainDiv: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: renewalVM,
            authHeader,
            LOB: 'personalAuto_EA',
            viewOnlyMode: true
        },
        renewalTermEffectiveDate: {
            dateDTO: renewalVM.baseData.effectiveDate,
            updateDateDto: () => {},
            readOnly: true,
            showErrors: false,
            onValidate: () => {}
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddSni: () => { },
            onValidate: () => { },
            onRemoveSni: () => { },
            onAddNewPni: () => { },
            onSwapPniSni: () => { }
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
            showRenewalPrevious={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={() => {}}
            />
        </WizardPage>
    );
}

ViewInsuredDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewInsuredDetailsPage);
