import _ from 'lodash';
// eslint-disable-next-line import/prefer-default-export
export function isRequired(initialRequiredFields, requiredMetadata, policyState, partner) {
    const requiredFields = [];
    initialRequiredFields.forEach((field) => {
        const statesPath = _.get(requiredMetadata, `field[${field}].partner[${partner}]`);
        if (statesPath) {
            const {
                states
            } = statesPath;
            if (states.includes(policyState)) {
                requiredFields.push(field);
            }
        }
    });
    return requiredFields;
}
