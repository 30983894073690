import React, {
    useContext,
    useCallback,
    useEffect
} from 'react';
import {
    get, cloneDeep, sortBy, uniqBy
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { CoverageUtil } from 'e1p-portals-util-js';
import appConfig from 'app-config';
import styles from './QuotePage.module.scss';
import messages from './QuotePage.messages';
import metadata from './QuotePage.metadata.json5';

function structureClauseTableData(coverage) {
    // putting ID into an object as the Jutro table component expects an object
    return {
        publicID: coverage.publicID
    };
}

function generateClauseData(columnData, coveragePath) {
    return columnData.map(({ lob, code }) => {
        const completeCoveragePath = `coverages.${coveragePath}`;

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

const getCoveragesUniqueID = (policyViewVM) => {
    const offerings = get(policyViewVM, 'lobData.personalUmbrella_EU.offerings.value');
    const coverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.coverages.map(structureClauseTableData)
    )), 'publicID');

    return {
        coverages
    };
};

const generateColumnData = (policyViewVM) => {
    const lobOfferingPath = 'lobData.personalUmbrella_EU.offerings';

    const lobOfferings = get(policyViewVM, `${lobOfferingPath}.value`);

    let filteredOfferings = cloneDeep(lobOfferings);

    if (filteredOfferings.length > 1) {
        filteredOfferings = filteredOfferings.filter((offering) => offering.branchCode !== 'CUSTOM');
    }

    const columnData = filteredOfferings
        .map((lobOffering, lobIndex) => ({
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: '',
                    data: {}
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                },
                policyViewVM
            }));

    return sortBy(columnData, ['code']);
};

const generateTableData = (policyViewVM, columnData, translator) => {
    const uniqueID = getCoveragesUniqueID(policyViewVM);

    const coverages = {
        header: translator(messages.policyLevelCoverages),
        data: uniqueID.coverages,
        tableContent: generateClauseData(columnData, 'coverages')
    };

    return [coverages];
};

function ViewQuotePage(props) {
    const {
        wizardData: policyViewVM
    } = props;

    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { opCo } = useContext(AmfamOktaTokenContext);
    const { authHeader, authUserData } = useAuthentication();
    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;

    const buyNow = useCallback(
        () => policyViewVM,
        [policyViewVM]
    );

    const generateOverrides = useCallback(() => {
        const columnData = generateColumnData(policyViewVM);
        const { underwritingIssues } = props;

        return {
            '@field': {
                // apply to all fields
                labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                readOnly: true
            },
            quotePageLoadingIndicator: {
                loaded: true
            },
            quoteTable: {
                columnData,
                tableData: generateTableData(policyViewVM, columnData, translator),
                underwritingIssues,
                submissionVM: policyViewVM,
                updateWizardData: () => {},
                authHeader,
                viewOnlyMode: true,
                modifiers: get(policyViewVM, 'lobData.personalUmbrella_EU.modifiers.value', []),
                authUserData,
                setFieldsChangedOnCoveragePage: () => { },
                opCo
            },
            startDate: {
                visible: showQuoteStartDate
            },
            print: {
                visible: !showQuoteStartDate
            }
        };
        // prevent complaining props and showQuoteStartDate is missing in the dependencies list
        // watch props will cause refresh function ref multiple time,
        // showQuoteStartDate is generated from build time, so won't change in the runtime
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, policyViewVM, translator, opCo]);

    // overriding coverage help text for given clause.
    useEffect(() => {
        get(policyViewVM, 'value.lobData.personalUmbrella_EU.offerings[0].coverages.coverages').forEach((clause) => {
            const coveragesHelpText = CoverageUtil.getCoveragesHelpTextForEU(clause);

            if (coveragesHelpText) {
                clause.description = coveragesHelpText
            }
        })
    }, [policyViewVM]);

    return (
        <WizardPage
            shouldLink
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveClassNameMap: styles,
                    resolveCallbackMap: {
                        onBuyNow: () => buyNow().then(onNext),
                        onStaleQuoteBranchCode: () => { },
                        onRecalculate: () => { },
                        onResetQuote: () => { },
                        onChangeSubmissionAndSync: () => { },
                        onChangeSubmission: () => { },
                        onSyncCoverages: () => { },
                        onPrint: () => { },
                    },
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={policyViewVM}
                        overrideProps={generateOverrides()}
                        classNameMap={resolvers.resolveClassNameMap}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                );
            }}
        </WizardPage>
    );
}

ViewQuotePage.propTypes = wizardProps;
export default ViewQuotePage;
