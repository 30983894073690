import React, {
    useCallback,
    useState,
    useContext,
    useMemo,
    useEffect
} from 'react';
import { get, set } from 'lodash';
import { CreditService } from 'e1p-capability-gateway';
import { wizardProps, WizardPage } from 'e1p-portals-wizard-react';
import {
    parseErrors
} from '@xengage/gw-portals-edge-validation-js';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { ehCommonMessages } from 'e1p-platform-translations';
import { e1pDateUtil } from 'e1p-capability-hooks';
import htmlParser from 'html-react-parser';
import styles from './AdditionalInfo.module.scss';
import metadata from './AdditionalInfo.metadata.json5';

function AdditionalInfo(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        removeCurrentPageFromSteps,
        shouldSkipAdditionalInfo
    } = props;
    const translator = useTranslator();
    const [additionaInfoVM, updateAdditionaInfoVM] = useState(submissionVM);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [creditScoreMoreThan597, setCreditScoreMoreThan597] = useState();
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const policyType = get(additionaInfoVM, 'lobData.homeowners_EH.policyType.value.code');
    const policyState = get(additionaInfoVM, 'baseData.policyAddress.state.value.code');
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { onValidate, isComponentValid } = useValidation(
        'AdditionalInfo'
    );

    useEffect(() => {
        const initialFunction = async () => {
            let response;

            try {
                response = await CreditService.getCreditReport(
                    get(submissionVM, 'quoteID.value'),
                    authHeader
                );
            } catch (exception) {
                if (submissionVM.value.baseData.exceptions_Ext) {
                    submissionVM.value.baseData.exceptions_Ext.push(
                        { errorMessage: exception.baseError ? exception.baseError : exception.message }
                    );
                } else {
                    set(
                        submissionVM.value.baseData,
                        `exceptions_Ext[${0}]`,
                        { errorMessage: exception.baseError ? exception.baseError : exception.message }
                    );
                }

                updateWizardData(submissionVM);
            }

            if (response !== undefined) {
                const reportWithoutNoHit = response.creditRecords.find(
                    (creditRecord) => creditRecord.reportStatus !== 'NOHIT'
                );

                if (reportWithoutNoHit !== undefined) {
                    setCreditScoreMoreThan597(reportWithoutNoHit.creditScore >= 597);
                }
            }
        }

        initialFunction();
    }, [authHeader, submissionVM, updateWizardData])

    const writeValue = useCallback(
        (value, path) => {
            set(additionaInfoVM, path, value);

            const newAdditionaInfoVM = viewModelService.clone(additionaInfoVM);

            updateAdditionaInfoVM(newAdditionaInfoVM);
            updateIsPageSubmitted(false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    useEffect(() => {
        // IAP-370: if full quote additional information page is removed
        // and flow proceeds to next page
        if (shouldSkipAdditionalInfo) {
            removeCurrentPageFromSteps();
        }  else {
            set(submissionVM, 'convertedFromQuickToFull', true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    const dogInHomeChange = useCallback(
        (value) => {
            writeValue(value, 'lobData.homeowners_EH.coverables.yourHome.hasDogInHome');

            if (!value) {
                writeValue(undefined, 'lobData.homeowners_EH.coverables.yourHome.highRiskDogBreedInd');
            }
        },
        [writeValue]
    );

    const swimmingPoolValueChange = useCallback(
        (value) => {
            writeValue(value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool');

            if (!value) {
                writeValue(undefined, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence');
            }
        },
        [writeValue]
    );

    const currentDateYear = useMemo(() => e1pDateUtil.getCurrentDateYear(), []);

    const onNext = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingEndorsement(true);

            // Defaulting autoPayDiscInd indicator to false
            if (get(additionaInfoVM, 'lobData.homeowners_EH.autoPayDiscInd.value') === undefined) {
                set(additionaInfoVM, 'lobData.homeowners_EH.autoPayDiscInd.value', false);
            }

            const QuoteDataResponse = await LoadSaveService
                .convertToFullApp(additionaInfoVM.value, authHeader);
            const errorsAndWarnings = get(QuoteDataResponse, 'errorsAndWarnings', []);
            const blockingUWIssues = errorsAndWarnings?.underwritingIssues?.filter((item) => item?.approvalBlockingPoint !== 'NonBlocking');

            set(errorsAndWarnings, 'underwritingIssues', blockingUWIssues);

            const errors = parseErrors(errorsAndWarnings);
            const exceptionsPath = 'baseData.exceptions_Ext';
            const exceptions = get(QuoteDataResponse, exceptionsPath, []);

            setIsSavingEndorsement(false);
            setValidationErrors(errors.concat(exceptions));
            set(submissionVM, 'value', QuoteDataResponse);
            set(submissionVM, 'convertedFromQuickToFull', true);
            updateWizardData(submissionVM);
            setIsSavingEndorsement(false);

            return submissionVM;
        }, [LoadSaveService, additionaInfoVM, authHeader, isComponentValid, submissionVM, updateWizardData]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        additionalInformationPageLoadingIndicator: {
            loaded: !isSavingEndorsement
        },
        additionalInformationPageContainer: {
            visible: !isSavingEndorsement
        },
        totalNumberOfPeopleLiving: {
            required: true
        },
        yearRoofInstalled: {
            required: true,
            maxValue: currentDateYear,
            minValue: get(submissionVM, 'lobData.homeowners_EH.coverables.construction.yearBuilt.value'),
            showErrors: true,
            onValidate
        },
        sixtyftfence: {
            required: true
        },
        woodCoalStove: {
            required: true
        },
        dogInHome: {
            required: policyType !== 'HF9',
            onValueChange: dogInHomeChange,
        },
        dogInHomeGridWithSeprator: {
            visible: policyType !== 'HF9'
        },
        // IAP-5194, high risk dog breed not applicable for IL
        highRiskDogBreedIndGridWithSeprator: {
            visible: !(['IL'].includes(policyState)) && additionaInfoVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === true
        },
        swimmingPoolFence: {
            required: get(additionaInfoVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool') === true,
            value: get(additionaInfoVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPoolFence'),
        },
        swimmingPool: {
            onValueChange: swimmingPoolValueChange,
            required: true
        },
        swimmingPoolFenceGridWithSeprator: {
            visible: !!get(additionaInfoVM.value, 'lobData.homeowners_EH.coverables.construction.hasSwimmingPool') && !creditScoreMoreThan597
        },
        dogBreedSet1: {
            content: htmlParser(translator(ehCommonMessages.akitaAndPitbull))
        },
        dogBreedSet2: {
            content: htmlParser(translator(ehCommonMessages.alaskanAndRottweiler))
        },
        dogBreedSet3: {
            content: htmlParser(translator(ehCommonMessages.chowChowAndStaffordshire))
        },
        dogBreedSet4: {
            content: htmlParser(translator(ehCommonMessages.doberman))
        },
        // IAP-5194, high risk dog breed not applicable for IL
        highRiskDogBreedInd: {
            required: !(['IL'].includes(policyState)) && additionaInfoVM.lobData.homeowners_EH.coverables.yourHome.hasDogInHome.value === true
        },
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        additionalInfoForVerifiedQuoteDiv: {
            visible: policyType === 'HO3' || policyType === 'HF9'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                additionaInfoVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, additionaInfoVM]
    );

    return (

        <WizardPage
            onNext={onNext}
            showOnSave={false}
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
            hideSidebar
            finish
            showCancel={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={additionaInfoVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
                resolveValue={readValue}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

AdditionalInfo.propTypes = wizardProps;

export default AdditionalInfo;
