import React, { useMemo } from 'react';
import { Grid, GridItem } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import styles from './EHPolicyTermComponent.module.scss';

import messages from './EHPolicyTermComponent.messages';
import { RadioButtonField } from '@jutro/legacy/components';

function EHPolicyTermComponent() {
    const TERM_ANNUAL = 'Annual';
    const translator = useTranslator();
    const getRadionButtonAvailableValues = useMemo(() => [
            {
                code: TERM_ANNUAL,
                name: translator(messages.twelveMonths)
            }
        ], [translator]);

    return (
        <div className={styles.policyTermPadding}>
                <Grid
                    columns={[
                        '6rem',
                        '14rem',
                        '8rem'
                    ]}
                    gap="none"
                    id="Grid"
                >
                    <GridItem>
                        <span className={styles.iconLabel}>
                            {translator(messages.policyTerm)}
                        </span>
                    </GridItem>
                    <GridItem>
                        <RadioButtonField
                            controlClassName={styles.radioControls}
                            readOnly
                            value={TERM_ANNUAL}
                            availableValues={getRadionButtonAvailableValues}
                            id="12MonthTerm"
                        />
                    </GridItem>
                </Grid>
            </div>
    );
}

export default EHPolicyTermComponent;
