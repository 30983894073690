import React, {
    useCallback, useEffect, useState
} from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';

import styles from './StandardizationComponent.module.scss';
import metadata from './StandardizationComponent.metadata.json5';
import './StandardizationComponent.messages';


function StandardizationComponent(props) {
    const {
        addressVM,
        showAddresses,
        setShowAddresses,
        viewModelService,
        garageVM,
        updateGarageVM,
        onResolve
    } = props;

    const { onValidate } = useValidation(
        'StandardizationComponent'
    );
    const [addressData, updateAddressData] = useState(addressVM);
    const [enteredAddress, updateEnteredAddress] = useState();
    const [isExactMatchNotFound, setIsExactMatchNotFound] = useState(false);

    const writeValue = useCallback(
        (value) => {
            const nextFormData = _.cloneDeep(addressData);
            const addresses = enteredAddress ? [
                enteredAddress,
                ...addressData.standardizationChoices
            ] : [...addressData.standardizationChoices];

            _.set(nextFormData, 'addressToUse', addresses.find((element) => element.tempID === value));
            
            updateAddressData(nextFormData);
        },
        [addressData, enteredAddress]
    );

    useEffect(() => {
        if (_.isEmpty(addressData) && !_.isEmpty(addressVM)) {
            updateAddressData(addressVM);
        }

        const garagingAddressDTO = {
            addressLine1: '',
            addressLine2: '',
            city: '',
            postalCode: '',
            state: ''
        };

        garagingAddressDTO.addressLine1 = _.get(garageVM, 'addressLine1').value;

        const addressLine2 = _.get(garageVM, 'addressLine2').value;

        garagingAddressDTO.addressLine2 = addressLine2 || '';
        garagingAddressDTO.city = _.get(garageVM, 'city').value;
        garagingAddressDTO.postalCode = _.get(garageVM, 'postalCode').value;
        garagingAddressDTO.state = _.get(garageVM, 'state').value.code;

        const updatedEnteredAddress = {
            ...garagingAddressDTO,
            tempID: garagingAddressDTO.state,
            formattedDisplayName: `${garagingAddressDTO.addressLine1}, ${garagingAddressDTO.addressLine2}, ${garagingAddressDTO.city}, ${garagingAddressDTO.state}, ${garagingAddressDTO.postalCode}`
        };

        updateEnteredAddress(updatedEnteredAddress);

        if (addressData && addressData.standardizationChoices) {
            addressData.standardizationChoices.forEach((address) => {
                if (address.addressLine2 === 'null') {
                    _.set(address, 'addressLine2', undefined);
                }
            });

            const newData = _.cloneDeep(addressData);

            updateAddressData(newData);

            const allExactMatchAddresses = addressData.standardizationChoices.filter((choice) => choice.isExactMatch);

            // If exact match is not found set it to non exact match choice - returned as standardized choices in response
            if (allExactMatchAddresses.length === 0) {
                setIsExactMatchNotFound(true);
                writeValue(_.get(addressData.standardizationChoices, [0, 'tempID']));
            }
        }
        // Only set on initial load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setPossibleAddressValues = useCallback(() => {
        const possibleAddresses = [];

        if (addressData && addressData.standardizationChoices) {
            addressData.standardizationChoices.forEach((address, index) => {
                const temp = {
                    code: `${address.state}${index}`,
                    name: {
                        defaultMessage: address.formattedDisplayName.replace('null, ', ''),
                        id: `${address.state}${index}`
                    }
                };

                _.set(address, 'tempID', temp.code);
                possibleAddresses.push(temp);
            });

            return possibleAddresses;
        }

        return [];
    }, [addressData]);

    const setPossibleEnteredAddressValues = useCallback(() => {
        if (enteredAddress) {
            return  [{
                code: enteredAddress.state,
                name: {
                    defaultMessage: enteredAddress.formattedDisplayName
                        .replace('null, ', '')
                        .replace(' ,', ''),
                    id: enteredAddress.state
                }
            }];
        }

        return [];
    }, [enteredAddress]);

    const cancelHandler = useCallback(() => {
        setShowAddresses(false);
    }, [setShowAddresses]);

    const useThisAddressHandler = useCallback(() => {
        if (addressData.addressToUse) {
            const addressObj = {
                addressLine1: addressData.addressToUse.addressLine1,
                addressLine2: addressData.addressToUse.addressLine2
                    ? addressData.addressToUse.addressLine2
                    : '',
                city: addressData.addressToUse.city,
                postalCode: addressData.addressToUse.postalCode,
                state: addressData.addressToUse.state,
                country: 'US'
            };
            const {
                _dtoName,
                _xCenter
            } = garageVM;
            const newAddressVM = viewModelService.create(
                addressObj,
                _xCenter,
                _dtoName
            );

            updateGarageVM(newAddressVM);
            onResolve(
                {
                    modalGaragingAddressVM: newAddressVM
                }
            );
        }

        setShowAddresses(false);
    }, [addressData.addressToUse, garageVM, onResolve, setShowAddresses, updateGarageVM, viewModelService]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'top',
            autoComplete: false
        },
        addressStandardizationTitle: {
            visible: !_.isEmpty(setPossibleAddressValues()) && showAddresses
        },
        addressOptionRadio: {
            availableValues: setPossibleAddressValues(),
            visible: showAddresses
        },
        enteredAddress: {
            availableValues: setPossibleEnteredAddressValues()
        },
        standardizeAddressActions: {
            visible: !_.isEmpty(setPossibleAddressValues()) && showAddresses
        },
        standizationChoicesContainer: {
            visible: !isExactMatchNotFound
        },
        noExactMatchContainer: {
            visible: isExactMatchNotFound
        },
        addressStandardizeContinueButton: {
            visible: !isExactMatchNotFound
        },
        addressStandardizeButton: {
            visible: isExactMatchNotFound
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                addressData,
                id,
                path,
                overrideProps
            ),
        [overrideProps, addressData]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            cancelHandler,
            useThisAddressHandler
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={addressData}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

StandardizationComponent.propTypes = {
    addressVM: PropTypes.shape({
        possibleAddresses: PropTypes.shape({}),
        standardizationChoices: PropTypes.arrayOf(PropTypes.shape({})),
        addressToUse: PropTypes.shape({})
    }).isRequired,
    viewModelService: PropTypes.shape({
        clone: PropTypes.func,
        create: PropTypes.func
    }).isRequired,
    showAddresses: PropTypes.bool,
    setShowAddresses: PropTypes.func.isRequired,
    garageVM: PropTypes.shape({}).isRequired,
    updateGarageVM: PropTypes.func.isRequired,
    onResolve: PropTypes.func,
};

StandardizationComponent.defaultProps = {
    showAddresses: false,
    onResolve: undefined
};


export default StandardizationComponent;
