import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
    useRef
} from 'react';
import {
    get, set, isEmpty, filter, sortBy, cloneDeep, isNil, map, find
} from 'lodash';
import config from 'app-config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { FeatureUtil } from 'e1p-portals-util-js';
import { useModifierUtil } from 'e1p-capability-hooks';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import metadata from '../CoveragePage.metadata.json5';


function ViewCoveragePage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        authHeader
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [columnData, setColumnData] = useState({});
    const [checkScrolling, setCheckScrolling] = useState(false);
    const paperLessIndValue = get(renewalVM, 'lobData.personalAuto_EA.paperlessInd.value') === undefined
        ? false : get(renewalVM, 'lobData.personalAuto_EA.paperlessInd.value');
    const policyState = get(renewalVM, 'baseData.policyAddress.state.value.code');
    const isCompOnlyFeatureAvailable = FeatureUtil.isCompOnlyFeatureAvailableForState(policyState);
    const isSurchargeSectionVisible = FeatureUtil.isSurchargeSectionAvailableForState(policyState);
    const isTravelPackageVisible = FeatureUtil.isTravelPackageVisibleForState(policyState);
    const [isQuoteStale, setIsQuoteStale] = useState(false);


    const navRef = useRef();

    const {
        structuredModifiers
    } = useModifierUtil(renewalVM);
    const modifiers = structuredModifiers;

    const generateColumnData = () => {
        const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
        const lobOfferings = get(renewalVM, `${lobOfferingPath}.value`);
        let filteredOfferings = cloneDeep(lobOfferings);

        filteredOfferings = filteredOfferings.filter((offering) => offering.branchCode !== 'CUSTOM');

        const colData = filteredOfferings
            .map((lobOffering, lobIndex) => {
                // Added this condition to hide the vehicleCoverages from UI for which hidden
                // indicator is coming as true.
                const vehicleData = get(lobOffering, 'coverages.vehicleCoverages', []);

                vehicleData.forEach((coverage, index) => {
                    const filteredCoverages = coverage.coverages
                        .filter((cov) => !(cov.isHiddenInVersion));

                    set(lobOffering, `coverages.vehicleCoverages[${index}].coverages`, filteredCoverages);
                });

                return {
                    name: lobOffering.branchName,
                    code: lobOffering.branchCode,
                    quote: {
                        path: '',
                        data: {}
                    },
                    lob: {
                        path: `${lobOfferingPath}.children[${lobIndex}]`,
                        data: lobOffering
                    },
                    policyViewVM: renewalVM
                };
            });

        return sortBy(colData, ['code']);
    };

    useEffect(() => {
        const colData = generateColumnData();

        setColumnData(colData);

        // set isQuoteStale, when status is draft
        if (get(renewalVM, 'value.baseData.periodStatus') === 'Draft') {
            setIsQuoteStale(true);
        }
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTotalPremium = useCallback(() => {
        const payPlans = get(columnData[0], 'lob.data.paymentPlans');
        const selectedPlan = filter(payPlans, (plan) => plan.isSelected);
        const totalPremium = selectedPlan[0]?.total?.amount;

        if ((!totalPremium && totalPremium !== 0) || isQuoteStale) {
            return undefined;
        }

        return { currency: 'usd', amount: totalPremium };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnData]);

    const onNext = useCallback(
        async () => renewalVM,
        [renewalVM]
    );
    const generateOverrides = useCallback(
        () => {
            const vehicles = get(renewalVM, 'lobData.personalAuto_EA.coverables.vehicles.value');
            const premiumTypeLabel = get(renewalVM, 'quoteType_Ext.value.code') === 'Quick'
                ? 'Unverified Premium' : 'Verified Premium';
            const overrides = {};

            if (!isEmpty(vehicles)) {
                vehicles.forEach((_, i) => {
                    overrides[`vehicleCoverage${i}`] = {
                        loadingClause: () => {}
                    };
                });
            }

            overrides[premiumTypeLabel] = {
                content: premiumTypeLabel
            };

            const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
            const lobOfferings = get(renewalVM, `${lobOfferingPath}.value`);

            overrides.gridContainerLineCoverage = {
                lobOfferings,
                onChangeSubmissionAndSync: () => {},
                onChangeSubmission: () => {},
                onSyncCoverages: () => {},
                onValidate: () => {},
                policyState,
                lobIndex: 0,
                quoteIndex: 0,
                viewOnlyMode: true,
                isQuoteStale: false
            };
            overrides.gridContainerVehicleCoverage = {
                lobOfferings,
                onChangeSubmissionAndSync: () => {},
                onChangeSubmission: () => {},
                onSyncCoverages: () => {},
                onValidate: () => {},
                policyState,
                lobIndex: 0,
                quoteIndex: 0,
                vehicles: get(
                    renewalVM,
                    'lobData.personalAuto_EA.coverables.vehicles.value'
                ),
                quoteDataOfferings: get(renewalVM, 'quoteData.offeredQuotes.value'),
                isCompOnlyVisible: isCompOnlyFeatureAvailable,
                onCompOnlyChange: () => { },
                policyTransactionVM: renewalVM,
                updateWizardData,
                onCompOnlyChangeForCopyCoverages: () => { },
                columnData,
                viewOnlyMode: true,
                isQuoteStale: false
            };

            return overrides;
        },
        [columnData, isCompOnlyFeatureAvailable, policyState, renewalVM, updateWizardData]
    );

    const getCovPath = (ID, index) => {
        let covPath = '';

        if (ID === 'Vehicle') {
            covPath = `lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[${index}].coverages`;
        } else if (ID === 'Line') {
            covPath = 'lobData.personalAuto_EA.offerings.children[0].coverages.lineCoverages';
        } else {
            throw new Error('You did not pass in the required ID parameter');
        }

 return covPath;
    };


    // Returns policy line coverage endorsement bundle radio options (Split / Combined Single Limit)
    const getAvailablePolicyLineCoverageBundles = () => {
        const lineCoverages = get(renewalVM, getCovPath('Line'), {});

        return map(filter(lineCoverages.value, (cov) => cov.coverageCategoryCode === 'EA_EndorsementPackage', []), (cov) => ({
                id: cov.codeIdentifier,
                displayName: cov.name
            }));
    };

    // Returns selected policy line coverage endorsement bundle
    const getSelectedPolicyLineCoverageBundle = () => {
        const lineCoverages = get(renewalVM, getCovPath('Line'), {});

        return get(find(lineCoverages.value, (cov) => cov.coverageCategoryCode === 'EA_EndorsementPackage' && cov.selected), 'codeIdentifier');
    };

    let vehicleDiscountSubtotal = 0;
    let discountSubtotal = 0;
    const lineDiscountSubtotal = get(renewalVM, 'lobData.personalAuto_EA.offerings.value[0].premiumSummary', [])
        .find((prem) => prem.premiumSummaryType === 'TotalDiscounts')?.amount || 0;

    get(renewalVM, 'lobData.personalAuto_EA.offerings.value[0].coverages.vehicleCoverages').forEach((vehicleCoverage) => {
        vehicleDiscountSubtotal += get(vehicleCoverage, 'premiumSummary', [])
            .find((prem) => prem.premiumSummaryType === 'TotalVehicleDiscounts')?.amount || 0;
    })
    discountSubtotal = vehicleDiscountSubtotal + lineDiscountSubtotal;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        premiumMightChangeMessageDiv: {
            visible: renewalVM.baseData.periodStatus.value.code !== 'Bound'
        },
        coveragesPageLoadingIndicator: {
            loaded: true
        },
        paperlessDiscountContainer: {
            visible: get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled'])
        },
        PaperlessDiscountToggle: {
            value: get(renewalVM, 'lobData.personalAuto_EA.paperlessInd.value'),
            onValueChange: () => {}
        },
        paperlessEmailId: {
            visible: paperLessIndValue,
            onValueChange: () => {},
            value: renewalVM.lobData.personalAuto_EA.paperlessEmail.value
        },
        coveragesPageContainer: {
            visible: true
        },
        buyNowButton: {
            visible: false
        },
        recalculateButton: {
            visible: false
        },
        AutoPayDiscountToggle: {
            onValueChange: () => {},
            value: get(renewalVM, 'lobData.personalAuto_EA.autoPayDiscInd.value')
        },
        totalPremiumID: {
            value: (() => {
                const totalPremium = getTotalPremium();

                if (!isNil(totalPremium)) { return totalPremium; }

                return undefined;
            })()
        },
        policyTermComponent: {
            transactionVM: renewalVM,
            authHeader,
            updateWizardData,
            viewOnly: true
        },
        paymentOptionsID: {
            submissionVM: renewalVM,
            authHeader,
            updateWizardData,
            LOB: 'personalAuto_EA',
            viewOnly: true,
            isQuoteStale: false
        },
        eaSurchargeListComponentId: {
            value: modifiers.filter(
                (item) => item.applied && item.modifierType === "surcharge"
            ),
            visible: (!!filter(modifiers, { applied: true, modifierType: 'fee' }).length
                || !!filter(modifiers, { applied: true, modifierType: 'surcharge' }).length
            ) && isSurchargeSectionVisible,
            transactionVM: renewalVM,
            lob: 'PersonalAuto_EA'
        },
        eaDiscountsListComponentId: {
            visible: !!filter(modifiers, { applied: true, modifierType: 'discount' }).length,
            value: modifiers,
            discountsTotal: discountSubtotal !== 0 ? discountSubtotal : undefined
        },
        supportingPolicyComponentContainer: {
            transactionVM: renewalVM,
            LOB: 'personalAuto_EA',
            updateWizardData,
            viewOnly: true
        },
        scrollingComponentId: {
            navRef,
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('gridContainerVehicleCoverageContainer'),
            scrollWidth: 320
        },
        quoteProposalLinkContainer: {
            visible: false
        },
        policyLevelCoverageBundle: {
            availableValues: getAvailablePolicyLineCoverageBundles(),
            value: getSelectedPolicyLineCoverageBundle(),
            onValueChange: () => { },
            // Only make it visible if options are available for policy level endorsement packages
            visible: getAvailablePolicyLineCoverageBundles().length > 0
        },
        whatsIncludedLink: {
            disabled: true
        },
        compOnlyLink: {
            disabled: true
        },
        ADDWarningDiv: {
            visible: false
        },
        LiabilityCovCededMsgDiv: {
            visible: false
        },
        physicalDamageCovRemovalMsgDiv: {
            visible: false
        },
        travelPackageGrid: {
            visible: isTravelPackageVisible
        },
        paperlessEmailChangedMessageDiv: {
            visible: false
        },
        tncGapMinimumPDLimitMsgDiv: {
            visible: false
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onChangeClause: () => {},
            onSyncCoverages: () => {},
            onChangeSubmissionAndSync: () => {},
            onValidate: () => {}
        },
    };

    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, renewalVM, id, path, overrideProps), [overrideProps, renewalVM]);


    const dataForComponent = {
        columnData,
        modifiers,
        renewalVM,
        totalPremium: getTotalPremium()
    };

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={dataForComponent}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={() => {}}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewCoveragePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.shape({})
    }).isRequired,
    ...wizardProps
};
export default withRouter(withAuthenticationContext(ViewCoveragePage));
