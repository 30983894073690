import React, {
    useCallback, useContext, useState, useEffect
} from 'react';
import { get, filter, isNil } from 'lodash';
import PropTypes from 'prop-types';
import config from 'app-config';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { CoverageUtil } from 'e1p-portals-util-js';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from '../CoveragesPage.metadata.json5';

function ViewCoveragePage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        authUserData,
        authHeader
    } = props;

    const paperLessIndValue = get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessInd.value') === undefined
        ? false : get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessInd.value');
    const modifiers = get(policyChangeVM, 'lobData.personalUmbrella_EU.modifiers.value', []);
    const translator = useTranslator();
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [isQuoteStale, setIsQuoteStale] = useState(false);
    const showPremium = CoverageUtil.isTotalPremiumVisible(policyChangeVM, authUserData) && !isQuoteStale;
    const {
        onValidate
    } = useValidation('EUCoveragePage');

    useEffect(() => {
        // set isQuoteStale, when status is draft
        setIsQuoteStale(get(policyChangeVM, 'value.status') === 'Draft');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(() => policyChangeVM, [policyChangeVM]);


    const getTotalPremium = useCallback(() => {
        const payPlans = get(policyChangeVM, 'lobData.personalUmbrella_EU.offerings.value[0].paymentPlans');
        const selectedPlan = filter(payPlans, (plan) => plan.isSelected);
        const totalPremium = selectedPlan[0]?.total?.amount;

        if (!isNil(totalPremium)) { return { currency: 'usd', amount: totalPremium }; }

        return undefined;

    }, [policyChangeVM]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            readOnly: true
        },
        coveragesPageLoadingIndicator: {
            loaded: true,
            text: translator(e1pCommonMessages.loadingNextPage)
        },
        coveragesPageContainer: {
            visible: true
        },
        paymentOptionsID: {
            submissionVM: policyChangeVM,
            authHeader,
            updateWizardData,
            LOB: 'personalUmbrella_EU',
            viewOnly: true,
            isQuoteStale
        },
        totalPremiumID: {
            visible: showPremium,
            value: (() => getTotalPremium())()
        },
        emptyPremium: {
            visible: !showPremium
        },
        paperlessInd: {
            required: true,
            value: get(policyChangeVM, 'lobData.personalUmbrella_EU.paperlessInd.value'),
            labelPosition: 'top',
            visible: get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled'])
        },
        paperlessEmailId: {
            visible: paperLessIndValue && get(config, ['operatingCompanyConfig', opCo, 'paperlessOptionEnabled']),
            required: paperLessIndValue,
            labelPosition: 'top'
        },
        AutoPayDiscountToggle: {
            labelPosition: 'top',
            value: get(policyChangeVM, 'lobData.personalUmbrella_EU.autoPayDiscInd.value')
        },
        wizardPageHeader: { wizardSubmission: policyChangeVM },
        coverage: {
            loadingClause: false,
            isEditable: false,
            labelPosition: 'top'
        },
        euSurchargeListComponentId: {
            // modifiers other than discount will be shown in surchage and fee section
            value: modifiers.filter(
                (item) => item.applied && item.modifierType !== "discount"
            ),
            transactionVM: policyChangeVM,
            updateWizardData,
            viewOnlyMode: true
        },
        euDiscountsListComponentId: {
            visible: !!filter(modifiers, { applied: true, modifierType: 'discount' }).length,
            value: modifiers
        },
        personalLiabilityLimitUwIssueInfoMessageDiv: {
            visible: false
        },
        buyNowButton: {
            visible: false
        },
        recalculateButton: {
            visible: false
        },
        paperlessEmailChangedMessageDiv: {
            visible: false
        }
    };
    const resolvers = {
        resolveCallbackMap: {
            onChangeClause: () => { },
            onSyncCoverages: () => { },
            onChangeSubmissionAndSync: () => { },
            onValidate
        },
    };

    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, policyChangeVM, id, path, overrideProps), [overrideProps, policyChangeVM]);


    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewCoveragePage.propTypes = {
    ...wizardProps
};
export default withRouter(withAuthenticationContext(ViewCoveragePage));
