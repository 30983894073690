import { defineMessages } from 'react-intl';

export default defineMessages({
    search: {
        id: 'AccountSearchComponent.Search',
        defaultMessage: 'Search'
    },
    startDate: {
        id: 'AccountSearchComponent.Last Name',
        defaultMessage: 'Last Name'
    },
    searchState: {
        id: 'AccountSearchComponent.State',
        defaultMessage: 'State'
    },
    searchDateOfBirth: {
        id: 'AccountSearchComponent.MM/DD/YYYY',
        defaultMessage: 'MM/DD/YYYY'
    },
    searchName: {
        id: 'quoteandbind.wizard.step.Policy Details.Insured Information',
        defaultMessage: 'Insured Information'
    },
    find: {
        id: 'quoteandbind.wizard.step.Policy Details.Find Existing Account',
        defaultMessage: 'Find Existing Account'
    }
});
