import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import {
    // Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    useModal
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import PropTypes from 'prop-types';
import { StandardizedAddressService } from 'e1p-capability-gateway';
import { e1pUSStatesUtil } from 'e1p-capability-hooks';
import { commonMessages } from 'e1p-platform-translations';
import messages from './EAGaragingAddressModal.messages';
import metadata from './EAGaragingAddressModal.metadata.json5';
import styles from './EAGaragingAddressModal.module.scss';

/**
 *
 * @param {*} props wizard props
 * @returns {*} none
 * @todo there is a hook for standardization and a component
 *   called address standardization that has duplicate functionality.
 *   We should refactor to have one address component that standardizes
 *   (optionally) and modal optional.
 */
function EAGaragingAddressModal(props) {
    const modalApi = useModal();
    const {
        garagingAddressVM,
        title,
        authHeader,
        viewModelService,
        isOpen,
        onResolve,
        onReject
    } = props;
    const translator = useTranslator();
    const [modalGaragingAddressVM, updateModalGaragingAddressVM] = useState(garagingAddressVM);
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [showAddresses, setShowAddresses] = useState(false);
    const [data, setData] = useState({});
    const { isComponentValid, onValidate } = useValidation('EAGaragingAddressModal');
    const [USStates, setUSStates] = useState([]);
    const [isPageSubmitted, setIsPageSubmitted] = useState(false);

    /**
     * Helper callback for handling a mouse-click on the "Cancel" button.
     */
    const handleCancelButtonClick = useCallback(
        (evt) => {
            evt.stopPropagation();
            onReject();
        },
        [onReject]
    );

    useEffect(() => {
        const stateValues = e1pUSStatesUtil.getUSStates(viewModelService);

        setUSStates(stateValues);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Helper callback for handling a mouse-click on the "Save" button.
     */
    const handleSaveButtonClick = useCallback(() => {
        setIsPageSubmitted(true);

        if (
            modalGaragingAddressVM.addressLine1.value
            && modalGaragingAddressVM.addressLine1.value !== ''
            && modalGaragingAddressVM.addressLine1.value.length > 0
            && modalGaragingAddressVM.city.value !== ''
            && modalGaragingAddressVM.city.value.length > 0
            && modalGaragingAddressVM.postalCode.value !== ''
            && modalGaragingAddressVM.postalCode.value.length > 0
            && modalGaragingAddressVM.state.value
        ) {
            const garagingAddressDTO = {
                addressLine1: '',
                addressLine2: '',
                city: '',
                postalCode: '',
                state: ''
            };

            garagingAddressDTO.addressLine1 = _.get(modalGaragingAddressVM, 'addressLine1').value;

            const addressLine2 = _.get(modalGaragingAddressVM, 'addressLine2').value;

            garagingAddressDTO.addressLine2 = addressLine2 || '';
            garagingAddressDTO.city = _.get(modalGaragingAddressVM, 'city').value;
            garagingAddressDTO.postalCode = _.get(modalGaragingAddressVM, 'postalCode').value;
            garagingAddressDTO.state = _.get(modalGaragingAddressVM, 'state').value.code;

            setIsStandardizingAddress(true);
            StandardizedAddressService.getStandardizedAddress(garagingAddressDTO, authHeader)
                .then((response) => {
                    if (!_.isEmpty(response.exceptions_Ext)) {
                        const errorMessage = _.get(response, 'exceptions_Ext[0].errorMessage', '');
                        const POSTAL_ERROR_TEXT = 'postalCode is out of State Range';
                        const errorMessageToShow = errorMessage.includes(POSTAL_ERROR_TEXT) ?
                            commonMessages.addressStandardizationPostalErrorMessage :
                            errorMessage

                        modalApi.showAlert({
                            status: 'error',
                            icon: 'mi-error-outline',
                            title: commonMessages.addressStandardizationErrorTitle,
                            message: errorMessageToShow
                        });

                        return;
                    }

                    if (!response.standardizationChoices) { return; }

                    const allExactMatchAddresses = response.standardizationChoices.filter(
                        (choice) => choice.isExactMatch
                    );

                    // if only one exact match then map it directly otherwise show the popup
                    if (allExactMatchAddresses?.length === 1) {
                        const addressToUse = {
                            addressLine1: allExactMatchAddresses[0].addressLine1,
                            addressLine2: allExactMatchAddresses[0].addressLine2
                                ? allExactMatchAddresses[0].addressLine2
                                : '',
                            city: allExactMatchAddresses[0].city,
                            postalCode: allExactMatchAddresses[0].postalCode,
                            state: allExactMatchAddresses[0].state,
                            country: 'US'
                        };
                        const addressDTO = viewModelService.create(
                            addressToUse,
                            'pc',
                            'edge.capabilities.address.dto.AddressDTO'
                        );

                        updateModalGaragingAddressVM(addressDTO);

                        const wrapperObj = {
                            modalGaragingAddressVM: addressDTO
                        };

                        onResolve(wrapperObj);
                    } else {
                        setData(response);
                        setShowAddresses(true);
                    }
                })
                .finally(() => {
                    setIsStandardizingAddress(false);
                });
        }
    }, [authHeader, modalApi, modalGaragingAddressVM, onResolve, viewModelService]);

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            onSaveButtonClick: handleSaveButtonClick,
            onCancelButtonClick: handleCancelButtonClick
        }
    };

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false,
            showErrors: isPageSubmitted,
            showRequired: true
        },
        requiredFieldMessageDiv: {
            visible: isPageSubmitted && !isComponentValid
        },
        eaGaragingAddressModalLoadingIndicator: {
            loaded: !isStandardizingAddress,
            text: translator(messages.standardizingAddressMessage)
        },
        eaGaragingAddressModalContainer: {
            visible: !isStandardizingAddress
        },
        addGaragingAddressSubHeader: {
            visible: !showAddresses && !isStandardizingAddress
        },
        eaGaragingAddressGridContainer: {
            visible: !showAddresses
        },
        addressPopupActions: {
            visible: !showAddresses
        },
        standardization: {
            addressVM: data,
            garageVM: modalGaragingAddressVM,
            showAddresses,
            setShowAddresses,
            updateGarageVM: updateModalGaragingAddressVM,
            viewModelService,
            onResolve,
            visible: showAddresses
        },
        standardizeButton: {
            visible: isComponentValid && (!showAddresses || !isComponentValid)
        },
        addressState: {
            availableValues: e1pUSStatesUtil.getStateValues(USStates, translator)
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                modalGaragingAddressVM,
                id,
                path,
                overrideProps
            ),
        [modalGaragingAddressVM, overrideProps]
    );

    /**
     * Helper callback for writing values to the view model.
     */
    const writeValue = useCallback(
        (value, path) => {
            const newGaragingAddressVM = viewModelService.clone(modalGaragingAddressVM);

            _.set(newGaragingAddressVM, path, value);
            updateModalGaragingAddressVM(newGaragingAddressVM);
        },
        [modalGaragingAddressVM, updateModalGaragingAddressVM, viewModelService]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ModalNext
            isOpen={isOpen}
        >
            <ModalHeader title={title} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={modalGaragingAddressVM}
                    overrideProps={overrideProps}
                    onValidationChange={onValidate}
                    resolveValue={readValue}
                    onValueChange={writeValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
        </ModalNext>
    );
}

EAGaragingAddressModal.propTypes = {
    garagingAddressVM: PropTypes.shape({}).isRequired,
    title: PropTypes.shape({}),
    authHeader: PropTypes.shape({}),
    viewModelService: PropTypes.shape({}),
    isOpen: PropTypes.bool,
    onResolve: PropTypes.func,
    onReject: PropTypes.func
};

EAGaragingAddressModal.defaultProps = {
    title: undefined,
    authHeader: undefined,
    viewModelService: undefined,
    isOpen: undefined,
    onResolve:undefined,
    onReject: undefined
};

export default EAGaragingAddressModal;
