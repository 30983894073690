import { get } from 'lodash';
import htmlParser from 'html-react-parser';
import appConfig from 'app-config';
import OfferingUtil from "../OfferingUtil";
import ClauseUtil from './ClausesUtil';
import stateConfig from './StateConfig';
import EUCoveragesHelpText from '../CoveragesHelpText/EUCoveragesHelpText.json5';

/**
 * Checks for covTerm visibility based on given state
 * @param {Object} covTerm
 * @param {String} policyState
 * @returns {Boolean}
 */
function isCovTermVisible(covTerm, policyState) {
    let covTermVisible = true;
    const covTermConfig = get(stateConfig, `covTerms[${covTerm}]`);

    if (covTermConfig?.hiddenInStates) {
        covTermVisible = !covTermConfig.hiddenInStates.includes(policyState);
    }

    return covTermVisible;
}

/**
 * Evaluate and makes the total premium visible if all the conditions satisfied
 * @param {object} transactionVM 
 * @param {object} authUserData 
 * @returns {Boolean}
 */
function isTotalPremiumVisible(transactionVM, authUserData) {
    const BLOCKS_QUOTES = ['BlocksQuote', 'BlocksQuoteRelease'];
    const BLOCKS_BIND = 'BlocksBind';
    const underwritingIssues = get(transactionVM, 'errorsAndWarnings.underwritingIssues.value') === undefined
        ? [] : get(transactionVM, 'errorsAndWarnings.underwritingIssues.value');
    const userRolePremiumViewRoles = authUserData?.permissions_Ext.includes('viewpremium_ext');
    const allUWIssuesApproval = underwritingIssues?.every((item) => item?.approvalBlockingPoint === 'NonBlocking'
            || item?.currentBlockingPoint === 'NonBlocking');
    const blocksQuoteUWIssues = underwritingIssues?.some(
        (uwIssue) => BLOCKS_QUOTES.includes(uwIssue.currentBlockingPoint)
    );
    const blocksBindUWIssues = underwritingIssues?.some(
        (uwIssue) => BLOCKS_BIND.includes(uwIssue.currentBlockingPoint)
    );
    const hidePremiumForVerified = blocksBindUWIssues;

    return (!blocksQuoteUWIssues || userRolePremiumViewRoles || allUWIssuesApproval) && !hidePremiumForVerified;
}

/**
 * IAP-2626 : 
 * For SC state if user changes limit term to 'Rejected' for UMBI or UIMBI coverages
 * changing Stacking option to 'No', 
 * if user changes to limit term to any other value other than 'Rejected'
 * changing stacking options ot 'Yes'
 * @param {Object} transactionVM 
 * @param {String} changedPath 
 * @param {Object} selectedValue 
 * @param {Function} updateWizardData 
 */
function changeStackingTermValueorUMBIAndUIMBICoverages(transactionVM, changedPath, selectedValue, updateWizardData) {

    if (
        get(stateConfig, 'defaultStackingOptionApplicableStates', []).includes(
            get(transactionVM, 'baseData.policyAddress.state.value.code')
        )
    ) {
        const patternCodesForUIMBIAndUMBICovLimitTerms = [
            'EA_UIMBI_Limit',
            'EA_UIMBI_UnderinsuredMotoristBodilyInjuryCSL',
            'EA_UMBI_Limit',
            'EA_UMBI_UninsuredMotoristBodilyInjuryCSL'
        ];
        const patternCodesForUIMBIAndUMBICovStackingTerms = [
            'EA_UIMBI_Stacking',
            'EA_UMBI_Stacking'
        ]
        const isChangingGivenLimitTerm = patternCodesForUIMBIAndUMBICovLimitTerms
            .includes(get(transactionVM, `${changedPath}._parent.value.patternCodeIdentifier`));

        if (isChangingGivenLimitTerm) {
            const indexForStackingTerm = get(transactionVM, `${changedPath}._parent._parent.value`, [])
                .findIndex((term) => patternCodesForUIMBIAndUMBICovStackingTerms.includes(term.patternCodeIdentifier));

            if (indexForStackingTerm > -1) {
                const stackingPath = `${changedPath.split('.terms.')[0]}.terms.children[${indexForStackingTerm}].directBooleanValue`;
                let stackingChangedValue = true;

                if(selectedValue === 'NoCoverage'){
                    stackingChangedValue = false; 
                }

                updateWizardData(
                    ClauseUtil.setClauseValue(transactionVM, stackingChangedValue, stackingPath)
                );
            }
        }
    }
}

/**
 * Checks coverage help text for given clause
 * @param {Object} clause
 * @returns {string}
 */
function getCoveragesHelpTextForEU(clause) {
    const coveragesHelpTextMessageDetails = get(EUCoveragesHelpText, clause.codeIdentifier, undefined);

    return coveragesHelpTextMessageDetails ? htmlParser(coveragesHelpTextMessageDetails.toString()) : undefined;
}


/**
 * This will check if the coverage is selected or not on the policy.
 * Only auto check is implemented right now, we can enhance this function as required.
 * 
 * @param {Object} transactionVM 
 * @param {String} coverageCode 
 * @returns {Boolean}
 */
function isCoverageSelected(transactionVM, coverageCode) {
    if (!coverageCode || !transactionVM) {
        return false;
    }

    const lobName = appConfig.lobNameMap[get(transactionVM, 'value.baseData.productCode')];
    // OfferingUtil.getSelectedOffering(submissionVM, 'personalAuto_EA');
    const { selectedOffering } =  OfferingUtil.getSelectedOffering(transactionVM, lobName);
    let isCovSelected = false;

    if (lobName === 'personalAuto_EA') {
        // check in line level coverages
        const isSelectedAtLineLevel =
            selectedOffering.coverages.lineCoverages.some(
                coverage =>
                    coverage.codeIdentifier === coverageCode &&
                    coverage?.selected === true
            );
        // check at vehicle level coverages
        const isSelectedAtVehicleLevel =
            selectedOffering.coverages.vehicleCoverages.some(vehicleCoverage =>
                vehicleCoverage.coverages.some(
                    coverage =>
                        coverage.codeIdentifier === coverageCode &&
                        coverage?.selected === true
                )
            );

        isCovSelected = isSelectedAtLineLevel || isSelectedAtVehicleLevel;
    }

    return isCovSelected;
}


export default {
    isCovTermVisible,
    isTotalPremiumVisible,
    changeStackingTermValueorUMBIAndUIMBICoverages,
    getCoveragesHelpTextForEU,
    isCoverageSelected
};
