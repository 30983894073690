/* eslint-disable no-prototype-builtins */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    get as _get,
    set as _set
} from 'lodash';
import { useModal } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { PartnerDataUtil } from 'e1p-capability-gateway';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { connectMessages } from 'e1p-platform-translations';
import appConfig from 'app-config';
import E1PAdvisorLookupComponent from '../E1PAdvisorLookupComponent/E1PAdvisorLookupComponent';
import metadata from './ConnectPartnerInformationComponent.metadata.json5';
import messages from './ConnectPartnerInformationComponent.messages';

const VALID_MEMBERSHIP_ID_LENGTH = 12;

function ConnectPartnerInformationComponent(props) {
    const {
        transactionVM,
        labelPosition,
        showRequired,
        id,
        onValidate,
        onValueChange,
        viewOnlyMode,
        showErrors,
        authHeader,
        LOB
    } = props;
    const modalApi = useModal();
    const translator = useTranslator();
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const [advisorLastName, setAdvisorLastName] = useState('');
    const [membershipIdValidationMessage, setMembershipIdValidationMessage] = useState([]);
    const [membershipVerificationMessage, setMembershipVerificationMessage] = useState('');
    const [selectedPartnerCode, setSelectedPartnerCode] = useState(
        _get(transactionVM, 'baseData.partnerCode_Ext.value', _get(transactionVM, 'partnerCode_Ext.value'))
    );
    const [isConnectPartnerReadOnly, setIsConnectPartnerReadOnly] = useState(false);
    const [partnerIdValues, setPartnerIdValues] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const { operatingCompanyConfig } = appConfig;

    useEffect(() => {
        const partnerObj = {};
        const connectConfigObject = _get(operatingCompanyConfig, "CONNECT.experiences");

        for (const key in connectConfigObject) {
            if (connectConfigObject.hasOwnProperty(key)) {
                partnerObj[`${connectConfigObject[key].experienceIdDisplayName}`] = {
                    partnerId: key
                }
            }
        }

        setPartnerIdValues(partnerObj);
    }, [operatingCompanyConfig]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [transactionVM, id, onValidate, isComponentValid]);

    const handleValueChange = useCallback((value, changedPath) => {
        if (onValueChange) {
            onValueChange(value, changedPath);
        }
    }, [onValueChange]);

    // IAP-3793: Need to initialize advisor/membership - otherwise error is thrown when 
    // setting id/location etc.
    useEffect(() => {
        if (LOB === 'personalUmbrella_EU') {
            return;
        }

        if (_get(transactionVM, `lobData.${LOB}.advisor.value`) === undefined) {
            _set(transactionVM, `lobData.${LOB}.advisor`, {
                advisorID: undefined,
                advisorName: undefined,
                advisorLocation: undefined
            });
        }

        if (_get(transactionVM, `lobData.${LOB}.membership.value`) === undefined) {
            _set(transactionVM, `lobData.${LOB}.membership`, {
                membershipNumber: undefined,
                membershipLevel: undefined,
                membershipStatus: undefined,
                membershipTier: undefined
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const accountNumberExists = !!_get(transactionVM, 'baseData.accountNumber.value', _get(transactionVM, 'accountNumber.value'));
        const jobTypeIsNotSubmission = _get(transactionVM, 'baseData.value.jobType', _get(transactionVM, 'value.jobType')) !== 'Submission';
        const submissionIsCreated = _get(transactionVM, 'quoteID.value');

        // account is not created
        if (!accountNumberExists) {
            setIsConnectPartnerReadOnly(false);

            return;
        }

        // account is created and transaction has been created
        if (accountNumberExists && (jobTypeIsNotSubmission || submissionIsCreated)) {
            setIsConnectPartnerReadOnly(true);

            return;
        }

        const connectConfigObject = _get(operatingCompanyConfig, 'CONNECT.experiences', {});

        /**
         * This code will execute only for new submission for existing account
         * Iterating connect partner codes
         * partner code on account will have multiple experience ids only if given partner code is available as mergedPartneCode on any partner code object
         * will return false in this case so partner dropwdown will be editable and user can select from available experience ids for given partner code
         * removing parner code from state variable so that user has to select the experience id again
         */
        for (const key in connectConfigObject) {
            if (connectConfigObject.hasOwnProperty(key)) {
                const {mergedPartnerCode} = connectConfigObject[key];
                const partnerCode = _get(transactionVM, 'baseData.partnerCode_Ext.value', _get(transactionVM, 'partnerCode_Ext.value'))

                if (mergedPartnerCode === partnerCode) {
                    setIsConnectPartnerReadOnly(false);
                    setSelectedPartnerCode();
                    break;
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const validateMembershipId = async () => {
        const membershipNumber = _get(transactionVM, `lobData.${LOB}.membership.membershipNumber.value`);
        const membershipLevel = _get(transactionVM, `lobData.${LOB}.membership.membershipLevel.value`);

        if (membershipNumber?.length === VALID_MEMBERSHIP_ID_LENGTH && membershipLevel !== undefined) {
            setIsLoading(true);

            const response = await PartnerDataUtil.connectCostcoMembershipValidation(
                membershipNumber,
                // Quote ID for new business and quote exists, Job ID for other trans, empty string for no sub created yet
                _get(transactionVM, 'quoteID.value', undefined) || _get(transactionVM, 'jobID.value', ''),
                authHeader,
                translator
            );

            setMembershipVerificationMessage(response.message);
            _set(transactionVM, `lobData.${LOB}.membership.membershipTier.value`, response.membershipTier);
            _set(transactionVM, `lobData.${LOB}.membership.membershipStatus.value`, response.membershipStatus);
            setIsLoading(false);
        }
    }

    const getConnectPartnerValues = useCallback(() => {
        const formattedDropdownValues = [];
        const connectConfigObject = _get(operatingCompanyConfig, 'CONNECT.experiences', {});
        const accountNumberExists = !!_get(transactionVM, 'baseData.accountNumber.value', _get(transactionVM, 'accountNumber.value'));
        const partnerCode = _get(transactionVM, 'baseData.partnerCode_Ext.value', _get(transactionVM, 'partnerCode_Ext.value'))

        for (const key in connectConfigObject) {
            if (connectConfigObject.hasOwnProperty(key)) {
                /**
                 * If account number is present then available values will be experience ids attached to the partner code
                 */
                if (!accountNumberExists || key === partnerCode || connectConfigObject[key].mergedPartnerCode === partnerCode) {
                    formattedDropdownValues.push(
                        {
                            id: key,
                            displayName: {
                                id: key,
                                defaultMessage: isConnectPartnerReadOnly
                                    ? connectConfigObject[key].partnerDisplayName
                                    : connectConfigObject[key].experienceIdDisplayName
                            }
                        }
                    );
                }
            }
        }

        return formattedDropdownValues;
    }, [isConnectPartnerReadOnly, operatingCompanyConfig, transactionVM]);

    const searchAdvisorByLastName = useCallback(() => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            advisorLastName
        };

        modalApi.showModal(<E1PAdvisorLookupComponent {...componentProps} />)
            .then((chosenAdvisor) => {
                if (chosenAdvisor) {
                    onValueChange(chosenAdvisor, `lobData.${LOB}.advisor`)
                }
            })
            .catch(() => {
                // Error Occured or user did not select any advisor and clicked on continue
            })
            .finally(() => {
                setAdvisorLastName('');
            })
    }, [LOB, modalApi, advisorLastName, onValueChange]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showRequired,
            labelPosition,
            readOnly: viewOnlyMode,
            showErrors,
            autoComplete: false
        },
        connectPartner: {
            readOnly: isConnectPartnerReadOnly,
            availableValues: getConnectPartnerValues(),
            onValueChange: ((value, changedPath) => {
                setSelectedPartnerCode(value);
                handleValueChange(value, changedPath);

                // set experience id if partner changes
                const connectConfigObject = _get(operatingCompanyConfig, 'CONNECT.experiences', {});

                handleValueChange(connectConfigObject[`${value}`].experienceId, 'baseData.experienceID_Ext');

                // umbrella does not have partner specific dtos
                if (LOB === 'personalUmbrella_EU') {return;}

                if (value !== partnerIdValues['Ameriprise Advisor Referral']?.partnerId && value !== partnerIdValues['Ameriprise Direct']?.partnerId) {
                    _set(transactionVM, `lobData.${LOB}.advisor`, {
                        advisorID: undefined,
                        advisorName: undefined,
                        advisorLocation: undefined
                    });
                    setAdvisorLastName('');
                }

                if (value !== partnerIdValues.Costco?.partnerId) {
                    _set(transactionVM, `lobData.${LOB}.membership`, {
                        membershipNumber: undefined,
                        membershipLevel: undefined,
                        membershipStatus: undefined,
                        membershipTier: undefined
                    });
                }
            }),
            value: selectedPartnerCode
        },
        advisorLastName: {
            visible: LOB !== 'personalUmbrella_EU' && !viewOnlyMode,
            value: advisorLastName,
            onValueChange: (value) => {
                setAdvisorLastName(value);
            }
        },
        searchByLastNameIcon: {
            visible: LOB !== 'personalUmbrella_EU' && !viewOnlyMode
        },
        advisorName: {
            visible: LOB !== 'personalUmbrella_EU' && !!_get(transactionVM, `lobData.${LOB}.advisor.advisorName.value`, undefined),
            value: _get(transactionVM, `lobData.${LOB}.advisor.advisorName.value`, undefined),
            readOnly: true
        },
        advisorLocation: {
            value: _get(transactionVM, `lobData.${LOB}.advisor.advisorLocation.value`, undefined),
            readOnly: true,
            visible: _get(transactionVM, `lobData.${LOB}.advisor.advisorLocation.value`, undefined) !== undefined
                && LOB !== 'personalUmbrella_EU'
        },
        advisorID: {
            value: _get(transactionVM, `lobData.${LOB}.advisor.advisorID.value`, undefined),
            readOnly: true,
            visible: _get(transactionVM, `lobData.${LOB}.advisor.advisorID.value`, undefined) !== undefined
                && LOB !== 'personalUmbrella_EU'
        },
        ameripriseFieldContainer: {
            visible: (_get(transactionVM, 'baseData.partnerCode_Ext.value', _get(transactionVM, 'partnerCode_Ext.value')) === partnerIdValues['Ameriprise Advisor Referral']?.partnerId
                || _get(transactionVM, 'baseData.partnerCode_Ext.value', _get(transactionVM, 'partnerCode_Ext.value')) === partnerIdValues['Ameriprise Direct']?.partnerId)
                && LOB !== 'personalUmbrella_EU'
        },
        costcoFieldContainer: {
            visible: _get(transactionVM, 'baseData.partnerCode_Ext.value', _get(transactionVM, 'partnerCode_Ext.value')) === partnerIdValues.Costco?.partnerId && LOB !== 'personalUmbrella_EU'
        },
        membershipID: {
            visible: LOB !== 'personalUmbrella_EU',
            path: `lobData.${LOB}.membership.membershipNumber`,
            onBlur: (_, { value, beforeValue }) => {
                if (value === beforeValue) {return;}

                const membershipNumber = _get(transactionVM, `lobData.${LOB}.membership.membershipNumber.value`);

                if (membershipNumber === undefined || membershipNumber?.length !== VALID_MEMBERSHIP_ID_LENGTH) {
                    setMembershipIdValidationMessage([translator(connectMessages.membershipIdValidation)]);
                    setMembershipVerificationMessage('');
                } else {
                    setMembershipIdValidationMessage([]);
                    validateMembershipId();
                }
            },
            validationMessages: membershipIdValidationMessage,
        },
        membershipInfoMessageDiv: {
            visible: _get(transactionVM, 'baseData.partnerCode_Ext.value') === partnerIdValues.Costco?.partnerId && membershipVerificationMessage.length !== 0,
        },
        membershipInfoMessage: {
            message: membershipVerificationMessage
        },
        membershipLevel: {
            visible: LOB !== 'personalUmbrella_EU',
            path: `lobData.${LOB}.membership.membershipLevel`,
            onBlur: (_, { value, beforeValue }) => {
                if (value && (!beforeValue || value.code !== beforeValue?.code)) {
                    validateMembershipId();
                }
            },
        },
        sourceType: {
            availableValues: [
                { id: 'directentry', displayName: { id: 'e1p.connect.policyDetails.Direct Entry', defaultMessage: 'Direct Entry' } },
                { id: 'moonshot', displayName: { id: 'e1p.connect.policyDetails.Moonshot', defaultMessage: 'Moonshot' } },
                { id: 'liftOff', displayName: { id: 'e1p.connect.policyDetails.Liftoff', defaultMessage: 'Liftoff' } },
            ],
            readOnly: true,
            defaultValue: 'directentry',
            value: _get(transactionVM, 'baseData.quoteSource_Ext.sourceType.value', _get(transactionVM, 'quoteSource_Ext.sourceType.value'))
        }
    };

    const readValue = useCallback((fieldId, fieldPath) => readViewModelValue(
            metadata.pageContent, transactionVM, fieldId, fieldPath, overrideProps
        ), [transactionVM, overrideProps]);

    const resolvers = {
        resolveCallbackMap: {
            searchAdvisorByLastName
        }
    };

    if (isLoading) {
        return (
            <React.Fragment>
                <h4>{translator(messages.partnerInformation)}</h4>
                <E1PLoader loaded={false} />
            </React.Fragment>
        )
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

ConnectPartnerInformationComponent.propTypes = {
    data: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    showOptional: PropTypes.bool,
    operators: PropTypes.shape([]).isRequired,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    index: PropTypes.number.isRequired,
    showErrors: PropTypes.bool
};
ConnectPartnerInformationComponent.defaultProps = {
    data: {},
    labelPosition: 'top',
    path: undefined,
    showOptional: true,
    id: undefined,
    viewOnlyMode: false,
    onValidate: undefined,
    showErrors: false
};
export default ConnectPartnerInformationComponent;
