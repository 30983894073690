import React, { useCallback, useState, useEffect } from 'react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { parseErrors } from '@xengage/gw-portals-edge-validation-js';
import { Button } from '@jutro/legacy/components';
import { get, set } from 'lodash';
import { RenewalService, PreRenewalDirectionService } from 'e1p-capability-renewal';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { E1PDateComponent, E1PLoader } from 'e1p-capability-policyjob-react';
import { commonMessages } from 'e1p-platform-translations';
import messages from './E1PRenewalNonRenewComponent.messages';
import styles from './E1PRenewalNonRenewComponent.module.scss';

function E1PRenewalNonRenewComponent(props) {
    const {
        isOpen,
        onReject,
        onResolve,
        authHeader,
        renewalJobNumber,
        policyNumber
    } = props;

    const translator = useTranslator();
    const [isCreatingInteractiveLetter, setIsCreatingInteractiveLetter] = useState(false);
    const [isSavingRenewalDirection, setIsSavingRenewalDirection] = useState(false);
    const [callIsSuccessfulWithWarnings, setCallIsSuccessfulWithWarnings] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [nonRenewLetterMailingDate, setNonRenewLetterMailingDate] = useState({});
    const [
        isGettingNonRenewLetterMailingDate,
        setIsGettingNonRenewLetterMailingDate
    ] = useState(false);

    const getNonRenewLetterMailingDateForPolicy = useCallback(
        async () => {
            setIsGettingNonRenewLetterMailingDate(true);

            try {
                const loadDetailsResponse = await PreRenewalDirectionService
                    .loadDetails(policyNumber, authHeader);
                const errorsAndWarnings = get(loadDetailsResponse, 'errorsAndWarnings', []);
                const exceptions = get(loadDetailsResponse, 'errorsAndWarnings.exceptions_Ext', []);

                if (errorsAndWarnings.length === 0 && exceptions.length === 0) {
                    setNonRenewLetterMailingDate(loadDetailsResponse.nonRenewLetterMailingDate);
                } else {
                    const errors = parseErrors(errorsAndWarnings);

                    if (errors.length > 0 || exceptions.length > 0) {
                        setValidationErrors(errors.concat(exceptions));
                    }

                    setNonRenewLetterMailingDate({});
                }
            } catch {
                setValidationErrors(
                    [{ description: messages.unableToGetNonRenewLetterMailingDate }]
                );
            }

            setIsGettingNonRenewLetterMailingDate(false);
        }, [authHeader, policyNumber]
    );

    useEffect(() => {
        getNonRenewLetterMailingDateForPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    const onOk = useCallback(
        () => {
            onResolve();
        }, [onResolve]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingRenewalDirection(true);
            setValidationErrors([]);

            const renewalDirectionResponse = await RenewalService
                .markRenewalNonRenew(renewalJobNumber, ['prerenewaldirection'], authHeader);

            // Check for errors and warnings
            const errorsAndWarnings = get(renewalDirectionResponse, 'errorsAndWarnings', []);
            const blockingUWIssues = get(
                errorsAndWarnings,
                'underwritingIssues',
                []
            ).filter(
                item => item && item.approvalBlockingPoint !== 'NonBlocking'
            );

            set(errorsAndWarnings, 'underwritingIssues', blockingUWIssues);

            const errors = parseErrors(errorsAndWarnings);
            const exceptions = get(renewalDirectionResponse, 'errorsAndWarnings.exceptions_Ext', []);

            if (errors.length > 0 || exceptions.length > 0) {
                if (
                    errors.length > 0
                    && errors
                        .filter((error) => error.level === 'LEVEL_WARN').length === errors.length
                ) {
                    // User clicked on save button and successfully added prerenewal direction
                    // but received some warnings we will show warnings and show Ok button
                    // and will not call add prerenewal direction api again
                    setCallIsSuccessfulWithWarnings(true);
                }

                setValidationErrors(errors.concat(exceptions));
                setIsSavingRenewalDirection(false);

                return false;
            }

            onResolve(renewalDirectionResponse);

            return setIsSavingRenewalDirection(false);
        }, [authHeader, onResolve, renewalJobNumber]
    );
    /**
     * Helper function gets called when user clicks on create letter
     */
    const onCreateLetter = useCallback(
        async () => {
            setIsCreatingInteractiveLetter(true);
            setValidationErrors([]);

            try {
                // Request for create non renewal interactive letter
                const interactiveLetterRedirectURL = await RenewalService
                    .createNonRenewalInteractiveLetter(
                        [renewalJobNumber], authHeader
                    );

                if (interactiveLetterRedirectURL) {
                    // Open window with URL
                    window.open(
                        interactiveLetterRedirectURL,
                        '_blank'
                    );
                } else {
                    setValidationErrors([{ description: commonMessages.genericErrorText }]);
                }
            } catch {
                setValidationErrors([{ description: commonMessages.genericErrorText }]);
            }

            setIsCreatingInteractiveLetter(false);
        }, [authHeader, renewalJobNumber]
    );

    return (
        <ModalNext isOpen={isOpen} className={styles.nonRenewModalStyling}>
            <ModalHeader title={translator(messages.editDirectionTitle)} />
            <ModalBody>
                <E1PLoader
                    loaded={!isSavingRenewalDirection && !isGettingNonRenewLetterMailingDate}
                    text={isGettingNonRenewLetterMailingDate
                        ? translator(messages.gettingNonRenewLetterMailingDate)
                        : translator(messages.updateDirectionMessage)}
                />
                {
                    (!isSavingRenewalDirection && !isGettingNonRenewLetterMailingDate)
                    && (
                        <Grid rows={['1fr']}>
                            <GridItem>
                                <WizardSingleErrorComponent issuesList={validationErrors} />
                            </GridItem>
                            <GridItem>
                                {translator(messages.createLetterInformationMessage)}
                            </GridItem>
                            <GridItem>
                                <E1PDateComponent
                                    id="letterMailingDate"
                                    label={translator(messages.createLetterMailingDate)}
                                    labelPosition="top"
                                    readOnly
                                    dateDTO={nonRenewLetterMailingDate}
                                />
                            </GridItem>
                            {
                                !isCreatingInteractiveLetter
                                && (
                                    <GridItem>
                                        <Button
                                            id="createInteractiveLetter"
                                            type="outlined"
                                            icon="mi-add"
                                            onClick={onCreateLetter}
                                        >
                                            {translator(messages.createLetterButton)}
                                        </Button>
                                    </GridItem>
                                )
                            }
                            <GridItem>
                                <E1PLoader loaded={!isCreatingInteractiveLetter} />
                            </GridItem>
                        </Grid>
                    )
                }
            </ModalBody>
            <ModalFooter>
                {!callIsSuccessfulWithWarnings
                    && (
                        <Button type="text" onClick={onCancel} disabled={isCreatingInteractiveLetter}>
                            {translator(commonMessages.cancel)}
                        </Button>
                    )
                }
                <Button
                    onClick={callIsSuccessfulWithWarnings ? onOk : onSave}
                    disabled={isCreatingInteractiveLetter}
                >
                    {callIsSuccessfulWithWarnings
                        ? translator(commonMessages.ok)
                        : translator(commonMessages.save)}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

E1PRenewalNonRenewComponent.propTypes = {
    authHeader: PropTypes.shape({}).isRequired,
    isOpen: PropTypes.bool.isRequired,
    renewalJobNumber: PropTypes.string.isRequired,
    onResolve: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    policyNumber: PropTypes.string.isRequired
};
export default E1PRenewalNonRenewComponent;
