import {
    get as _get,
    find as _find,
    set as _set,
    some as _some,
    isUndefined as _isUndefined,
    isEqual as _isEqual
} from 'lodash';

/**
 * Returns true if LiabilityLimitChangeBlockQuote_EU uw issues is approved
 * @param {Object} errorsAndWarnings
 * @returns {Boolean}
 */
const hasLiabilityLimitChangeBlockQuoteUwIssueApproved = (errorsAndWarnings) => {
    const personalLiabilityLimitApprovedUWIssue = _find(
        errorsAndWarnings,
        (uwIssue) => {
            return uwIssue.uwissueCode_Ext === 'LiabilityLimitChangeBlockQuote_EU'
                && uwIssue.currentBlockingPoint === 'NonBlocking';
        }
    );
    return !!personalLiabilityLimitApprovedUWIssue;
};

/**
 * E1PAP1PC-15339:
 * if liability limit is >= 6 million, show info message if current user is an agent
 * @param {Object} transactionVM transactionVM data for particular transaction
 * @param {Booleans} hasAlreadyApprovedLiabilityLimitUWIssue
 * @returns {Boolean} returns boolean
 */
const isPersonalLiabilityLimitUwIssueInfoMessage = (
    transactionVM, hasAlreadyApprovedLiabilityLimitUWIssue
) => {
    const personalLiabilityLimitCov = _find(
        _get(transactionVM, 'value.lobData.personalUmbrella_EU.offerings[0].coverages.coverages'),
        (coverage) => { return coverage.codeIdentifier === 'EU_PersonalLiabilityCov'; }
    );
    const personalLiabilityLimitCovPerOccLimit = _find(
        _get(personalLiabilityLimitCov, 'terms'),
        (covTerm) => {
            return covTerm.patternCodeIdentifier === 'EU_PersonalLiabilityCov_PerOccurrence'
                && covTerm.chosenTerm >= 6000000;
        }
    );
    if (personalLiabilityLimitCovPerOccLimit && !hasAlreadyApprovedLiabilityLimitUWIssue) {
        return true;
    }
    return false;
};

/**
 * IAP-1916 : 
 * Decides should we show privatePassengerQuestion or not based upon presence of 
 * personal auto/personal non-owner policy
 * If personal auto/personal non-owner policy exist then we default resRelPrivatePassengerAutoInd filed to true
 * @param {Object} transactionVM 
 * @param {Function} updateWizardData 
 * @param {Function} setIsPrivatePassengerQuestionVisible
 * @param {Function} onValidate
 * @param {Object} deletedULPoliciesRef
 * @returns {Boolean}
 */
const checkPrivatePassengerAutoQuestionVisibility = (
    transactionVM,
    updateWizardData, setIsPrivatePassengerQuestionVisible,
    onValidate, deletedULPoliciesRef
) => {

    const passengerAutoUnderlyingPolicyExists = _some(
        _get(transactionVM, 'lobData.personalUmbrella_EU.coverables.underlyingPolicies.value', [])
            .filter((_, index) => !deletedULPoliciesRef?.current?.includes(index)),
        (underlyingPolicy) => underlyingPolicy.policyType === 'personalauto'
            || underlyingPolicy.policyType === 'personalaononowner'
    );
    const resRelPrivatePassengerAutoIndUndefined = _isUndefined(
        _get(transactionVM.lobData.personalUmbrella_EU.resRelPrivatePassengerAutoInd, 'value')
    );
    if (passengerAutoUnderlyingPolicyExists) {
        _set(transactionVM.lobData.personalUmbrella_EU.resRelPrivatePassengerAutoInd,
            'value', passengerAutoUnderlyingPolicyExists);
        _set(transactionVM.lobData.personalUmbrella_EU, 'reasonForNotDrivingPrivateAuto.value', []);
        _set(transactionVM.lobData.personalUmbrella_EU, 'noOperatorReasonOther.value', undefined);
        updateWizardData(transactionVM);
        return false;
    }
    if (resRelPrivatePassengerAutoIndUndefined) {
        // Making component invalid so that we can see the error message
        onValidate(false, 'privatePassengerQuestion');
    }
    setIsPrivatePassengerQuestionVisible(true);
    return resRelPrivatePassengerAutoIndUndefined;
}

/**
 * IAP-3088 : 
 * Check if policy named insured matches with Underlying policy named insured 
 * For matching criteria we use lastName and DOB
 * @param {Object} namedInsured 
 * @param {Object} underlyingPolicyNamedInsured 
 * @returns {Boolean}
 */
const checkIfNamedInsuredMatch = (namedInsured, underlyingPolicyNamedInsured) => {
    // Policy policy named insured last Name
    const namedInsuredLastName = namedInsured.lastName?.toUpperCase();

    // Check for policy named insured match with Underlying policy named insured last name and DOB
    let isNamedInsuredMatch = false;

    // UnderlyingPolicy Named insured LastName
    const ulNamedInsuredLastName = underlyingPolicyNamedInsured.lastName?.toUpperCase();

    // If dateOfBirth Exists
    if (!_isUndefined(namedInsured?.dateOfBirth) && !_isUndefined(underlyingPolicyNamedInsured?.dateOfBirth)) {        
        isNamedInsuredMatch = ulNamedInsuredLastName === namedInsuredLastName
            && _isEqual(namedInsured.dateOfBirth, underlyingPolicyNamedInsured.dateOfBirth)
    }

    return isNamedInsuredMatch;
};

export default {
    isPersonalLiabilityLimitUwIssueInfoMessage,
    hasLiabilityLimitChangeBlockQuoteUwIssueApproved,
    checkPrivatePassengerAutoQuestionVisibility,
    checkIfNamedInsuredMatch
};
