const stateOverrideConfig = {
    covTerms: {
        OptionEA_UMPD_Vehicle_DeductibleType: {
            hiddenInStates: ['IN']
        }
    },
    defaultStackingOptionApplicableStates: ['SC']

};
export default stateOverrideConfig;
