import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import { ConsoleHelper } from 'e1p-portals-util-js';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AccountSearchUtil, UserService } from 'e1p-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import _ from 'lodash';

import styles from './DashboardAccountSearchComponent.module.scss';
import metadata from './DashboardAccountSearchComponent.metadata.json5';
import './DashboardAccountSearchComponent.messages';

function DashboardAccountSearchComponent(props) {
    const {
        labelPosition,
        showOptional,
        renderSearchResults,
        setIsPerformingAccountSearch,
        isPerformingAccountSearch
    } = props;
    const [accountSearchVM, setAccountSearchVM] = useState(undefined);
    const [showErrors, setShowErrors] = useState(false);
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [isCallingApi, setIsCallingApi] = useState(false);
    const [searchErrorMessage, setSearchErrorMessage] = useState(undefined);
    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');

    useEffect(() => {
        if (viewModelService) {
            setAccountSearchVM(AccountSearchUtil.createAccountSearchVM(viewModelService));
        }
    }, [viewModelService]);

    /**
     * The onValueChange for most of the fields
     */
    const handleValueChange = useCallback(
        (value, changedPath) => {
            // Changing path on firstname to value instead of VM was the only way
            //   for the field to ignore the required annotation on the DTO
            if (changedPath === 'value.firstName') {
                _.set(accountSearchVM, changedPath, value);
            } else {
                _.set(accountSearchVM.value, changedPath, value);
            }

            setAccountSearchVM(viewModelService.clone(accountSearchVM));
        },
        [accountSearchVM, viewModelService]
    );

    /**
     * onValueCHange for the state field
     * Looks up producer codes for the State
     */
    const handleStateChange = useCallback(
        async (value, changedPath) => {
            _.set(accountSearchVM.value, changedPath, value);
            setAccountSearchVM(viewModelService.clone(accountSearchVM));

            let producerCodes = [];
            let locationCodes = [];

            setIsCallingApi(true);

            if (value && isAgent) {
                try {
                    const producerCodeResponse = await UserService
                        .getAvailableProducerCodesBasedOnProducerIdAndState(
                            value,
                            authHeader
                        );

                    producerCodes = _.sortBy(producerCodeResponse, 'code').map((codeObject) => ({
                            code: codeObject.code,
                            name: codeObject.code,
                            displayName: codeObject.displayValue,
                            externalId_Ext: codeObject.externalId_Ext
                        }));
                    locationCodes = _.sortBy(producerCodeResponse, 'code').map((codeObject) => ({
                            code: codeObject.externalId_Ext,
                            name: codeObject.externalId_Ext,
                            displayName: codeObject.displayValue,
                            externalId_Ext: codeObject.externalId_Ext
                        }));
                } catch {
                    ConsoleHelper('Error retrieiving location codes', 'ERROR');
                }

                setLocationAndProducerCodes(
                    {
                        producerCodes,
                        locationCodes
                    }
                );
            }

            setIsCallingApi(false);
        },
        [accountSearchVM, authHeader, isAgent, viewModelService]
    );

    /**
     * Searches for accounts. Invoke callback function to the Accounts/Accounts.jsx
     */
    const onSearchAccounts = useCallback(async () => {
        setSearchErrorMessage(undefined);
        setShowErrors(false);

        const lastName = _.get(accountSearchVM, 'lastName.value');
        const primaryAddressState = _.get(accountSearchVM, 'state.value');
        const producerCode = _.get(accountSearchVM, 'producerCode_Ext.value');
        const validCriteria = isAgent ?
            lastName && primaryAddressState && producerCode :
            lastName && primaryAddressState;

        if (validCriteria) {
            try {
                setIsPerformingAccountSearch(true);

                const response = await AccountSearchUtil.lookupAccountFromDashboard(
                    accountSearchVM.value, authHeader
                );

                renderSearchResults(response);
                setIsPerformingAccountSearch(false);

                return response;
            } catch (error) {
                setIsPerformingAccountSearch(false);
                setSearchErrorMessage(error);
            }
        } else {
            renderSearchResults([]);
            setShowErrors(true);
        }

        return undefined;
    }, [accountSearchVM, authHeader, isAgent, renderSearchResults, setIsPerformingAccountSearch]);

    /**
     * When underwriter is logged in they enter a location code
     *   and we make an api call to get producer code and set it for the search
     */
    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode
            } = producerDetails;

            _.set(accountSearchVM, 'producerCode_Ext.value', producerCode);
            setAccountSearchVM(viewModelService.clone(accountSearchVM));
        },
        [accountSearchVM, viewModelService],
    );

    /**
     * When location code changes we need to set the matching producer code
     */
    const locationCodeChange = useCallback(
        (newLocationCode, changedPath) => {
            const selectedProducerCode = locationAndProducerCodes?.producerCodes
                ?.find((producerCode) => producerCode?.externalId_Ext === newLocationCode);

            _.set(accountSearchVM, changedPath, newLocationCode);
            _.set(accountSearchVM, 'producerCode_Ext.value', selectedProducerCode?.code);
            setAccountSearchVM(viewModelService.clone(accountSearchVM));
        },
        [locationAndProducerCodes.producerCodes, accountSearchVM, viewModelService]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: () => { },
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            disabled: isCallingApi,
            autoComplete: false
        },
        searchAccountButton: {
            onClick: () => onSearchAccounts(),
            disabled: isPerformingAccountSearch || isCallingApi
        },
        LocationCodeDropdownID: {
            availableValues: locationAndProducerCodes.locationCodes,
            onValueChange: locationCodeChange,
            visible: isAgent,
            showRequired: true,
            required: true,
            showErrors
        },
        ExternalProducerDetailsComponentID: {
            visible: !isAgent,
            isRequired: false
        },
        accountSearchState: {
            onValueChange: handleStateChange,
            showRequired: true,
            required: true,
            showErrors
        },
        AccountSearchErrorBannerIDDiv: {
            visible: !!searchErrorMessage
        },
        AccountSearchErrorBannerID: {
            message: searchErrorMessage
        },
        accountSearchLastName: {
            showRequired: true,
            required: true,
            showErrors
        },
        accountSearchFirstName: {
            showErrors: false
        }
    };


    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                accountSearchVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [accountSearchVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={accountSearchVM}
            overrideProps={overrideProps}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

DashboardAccountSearchComponent.propTypes = {
    value: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    showOptional: PropTypes.bool,
    authHeader: PropTypes.shape({}).isRequired,
    renderSearchResults: PropTypes.func.isRequired,
    setIsPerformingAccountSearch: PropTypes.func.isRequired,
    isPerformingAccountSearch: PropTypes.bool.isRequired,
};
DashboardAccountSearchComponent.defaultProps = {
    value: {},
    labelPosition: 'top', // I want labels on top by default
    showOptional: false
};
export default DashboardAccountSearchComponent;
