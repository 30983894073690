import React, {
    useContext, useCallback, useRef
} from 'react';
import { get } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import messages from '../UnderlyingPolicyPage.messages';
import metadata from '../UnderlyingPolicyPage.metadata.json5';

function ViewUnderlyingPolicyPage(props) {
    const {
        wizardData: renewalVM
    } = props;
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const jobType = get(renewalVM.value, 'baseData.jobType');

    const onNext = useCallback(() => renewalVM, [renewalVM]);

    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            showRequired: true
        },
        underlyingPolicyPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.loadingNextPageMessage)
        },
        underlyingPolicyPageContainer: {
            visible: true
        },
        euUnderlyingPolicyComponent: {
            data: renewalVM,
            updateWizardData: () => {},
            isPageSubmitted: false,
            isPrivatePassengerQuestionVisible: false,
            viewOnlyMode: true,
            deletedItemsRef: useRef([]),
            onValidate: undefined,
            jobType

        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: () => {}
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={() => {}}
                onValidationChange={() => {}}
                callbackMap={resolvers.resolveCallbackMap}
                onValueChange={() => {}}
            />
        </WizardPage>
    );
}

ViewUnderlyingPolicyPage.propTypes = wizardProps;
export default ViewUnderlyingPolicyPage;
