const covAOverrides = {
    partner: {
        MSA: [
            {
                states: ['IL', 'IN', 'KY', 'OH', 'NJ', 'PA', 'UT'],
                minValue: 0.95,
                maxValue: 1.2
            }
        ],
    }
};

export default covAOverrides;
