import { defineMessages } from 'react-intl';

export default defineMessages({
    search: {
        id: 'e1p.gateway.directives.activity.Search',
        defaultMessage: 'Search'
    },
    select: {
        id: 'e1p.gateway.directives.activity.Select',
        defaultMessage: 'Select'
    },
    dueDate: {
        id: 'e1p.gateway.directives.activity.Due Date',
        defaultMessage: 'Due Date'
    },
    activityType: {
        id: 'e1p.gateway.directives.activity.Activity Subject',
        defaultMessage: 'Activity Subject'
    },
    priority: {
        id: 'e1p.gateway.directives.activity.Priority',
        defaultMessage: 'Priority'
    },
    category: {
        id: 'e1p.gateway.directives.activity.Category',
        defaultMessage: 'Category'
    },
    product: {
        id: 'e1p.gateway.directives.activity.Product',
        defaultMessage: 'Product'
    },
    account: {
        id: 'e1p.gateway.directives.activity.Account',
        defaultMessage: 'Account'
    },
    policy: {
        id: 'e1p.gateway.directives.activity.Policy',
        defaultMessage: 'Policy'
    },
    description: {
        id: 'e1p.gateway.directives.activity.Description',
        defaultMessage: 'Description'
    },
    assignedQueue: {
        id: 'e1p.gateway.directives.activity.Assigned To',
        defaultMessage: 'Assigned To'
    },
    state: {
        id: 'e1p.gateway.directives.activity.State',
        defaultMessage: 'State'
    },
    completeActivityButton: {
        id: 'e1p.gateway.directives.activity-schedule.COMPLETE',
        defaultMessage: 'COMPLETE'
    },
    addToMyQueueActivityButton: {
        id: 'e1p.gateway.directives.activity-schedule.ADD TO MY QUEUE',
        defaultMessage: 'ADD TO MY QUEUE'
    },
    reassignActivityButton: {
        id: 'e1p.gateway.directives.activity.RE-ASSIGN ACTIVITY',
        defaultMessage: 'RE-ASSIGN ACTIVITY'
    },
    activityAccount: {
        id: 'e1p.gateway.directives.activity.activityAccount',
        defaultMessage: 'Account'
    },
    activityState: {
        id: 'e1p.gateway.directives.activity.activityState',
        defaultMessage: 'State'
    },
    activityPolicy: {
        id: 'e1p.gateway.directives.activity.activityPolicy',
        defaultMessage: 'Policy'
    },
    activityPriority: {
        id: 'e1p.gateway.directives.activity.activityPriority',
        defaultMessage: 'Priority'
    },
    activityDueDate: {
        id: 'e1p.gateway.directives.activity.activityDueDate',
        defaultMessage: 'Due Date'
    },
    activityEscalationDate: {
        id: 'e1p.gateway.directives.activity.activityEscalationDate',
        defaultMessage: 'Escalation Date'
    },
    activitySubject: {
        id: 'e1p.gateway.directives.activity.activitySubject',
        defaultMessage: 'Subject'
    },
    activityDescription: {
        id: 'e1p.gateway.directives.activity.activityDescription',
        defaultMessage: 'Description'
    },
    activityAssignedTo: {
        id: 'e1p.gateway.directives.activity.activityAssignedTo',
        defaultMessage: 'Assigned To'
    },
    activityAssign: {
        id: 'e1p.gateway.directives.activity.activityAssign',
        defaultMessage: 'Assign'
    },
    activityCompletedDate: {
        id: 'e1p.gateway.directives.activity.activityCompletedDate',
        defaultMessage: 'Completed Date'
    },
    selectAnActivity: {
        id: 'e1p.gateway.directives.activity-schedule.Select an activity to assign.',
        defaultMessage: 'Select an activity to assign.',
    }
});
