import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty
} from 'lodash';
import {
    useModal,
} from '@jutro/components';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import {
    ViewModelServiceContext,
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import {
    useAuthentication,
    withAuthenticationContext
} from '@xengage/gw-digital-auth-react';
import { useUWIssueUtil, useLandingPageUtil } from 'e1p-capability-hooks';
import { AutoLossService } from 'e1p-capability-gateway';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import metadata from '../RiskAnalysisPage.metadata.json5';
import messages from '../RiskAnalysisPage.messages';
import requiredMetadata from '../RiskAnalysisPage.requiredness';

const LOB = 'personalAuto_EA';

function ViewRiskAnalysisPage(props) {
    const modalApi = useModal();
    const {
        wizardData: renewalVM,
        updateWizardData,
        isSkipping,
        steps,
        authUserData,
        jumpTo
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
  const [dataForComponent, updateDataForComponent] = useState({});
    const { authHeader } = useAuthentication(); 
    const [visibleFields, updateVisibleFields] = useState([]);
    const renewalSource = _get(renewalVM, 'value.renewalSource', '');
    const renewalReason = _get(renewalVM, 'value.renewalReason', '');
    const policyState = _get(renewalVM, 'baseData.policyAddress.state.value.code');
    const {opCo} = useContext(AmfamOktaTokenContext);


    const {
        hasUWIssues
    } = useUWIssueUtil(
        renewalVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const {
        getDefaultTab
    } = useLandingPageUtil();

         const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(renewalVM, 'lobData.personalAuto_EA.premiumStability.value') !== undefined;

    const defaultTabBasedOnReason = useMemo(
        () => getDefaultTab(LOB, renewalSource, renewalReason),
        [getDefaultTab, renewalSource, renewalReason]
    );

    const saveAndQuote = useCallback(() => renewalVM, [renewalVM]);

    const uwIssuesPresent = useMemo(
        () => hasUWIssues(),
        //   eslint-disable-next-line react-hooks/exhaustive-deps
        [renewalVM?.errorsAndWarnings_Ext?.underwritingIssues?.value]
    );

    const getDefaultActiveTab = useMemo(() => {
        if (uwIssuesPresent) {
            return 'uwIssuesTab';
        }

        return defaultTabBasedOnReason === false ? 'priorCarrierTabID' : defaultTabBasedOnReason;
    }, [uwIssuesPresent, defaultTabBasedOnReason]);

    const createAutoLossVM = useCallback((vmObject, dtoName) => {
        const vmNode = viewModelService.create(
            vmObject,
            'pc',
            dtoName
        );

        return vmNode;
    }, [viewModelService]);

    useEffect(() => {
        const initialVisibleFields = ['declareLossStatementThreeYears', 'declareLossStatementFiveYears']; // Fields to look up by partner/state

        updateVisibleFields(
            isRequired(initialVisibleFields, requiredMetadata, policyState, opCo)
        );
        // User can't able to change policy state on Risk Analysis Page,
        // hence we need to run this useEffect only single time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opCo]);

    useEffect(() => {
        if (!isSkipping) {
            const AutoLossRecordsPromise = AutoLossService.loadAutoLosses(
                _get(renewalVM, 'jobID.value'),
                authHeader
            );

            AutoLossRecordsPromise
                .then((response) => {
                    const autoLossRecords = [];

                    response.autoLossRecords.filter((record)=>record.source.sourceType !== 'Self-Declared').forEach((result) => {
                        const recordVM = createAutoLossVM(
                            result,
                            'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoLossRecordDTO'
                        );
                        
                        autoLossRecords.push(recordVM.value);
                    });

                    const autoViolationRecords = [];

                    response.autoViolationRecords.filter((record)=>record.source.sourceType !== 'Self-Declared').forEach((result) => {
                        const violationVM = createAutoLossVM(
                            result,
                            'amfam.edge.capabilities.policyjob.common.autoincident.dto.AutoViolationRecordDTO'
                        );

                        _set(
                            violationVM,
                            'value.operatorDisplayName',
                            `${violationVM?.value?.assignment?.firstName} ${violationVM?.value?.assignment?.lastName}`
                        );
                        autoViolationRecords.push(violationVM.value);
                    });

                    _set(dataForComponent, 'lobData.personalAuto_EA.autoLossRecords.value', autoLossRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.autoViolationRecords.value', autoViolationRecords);
                    _set(dataForComponent, 'lobData.personalAuto_EA.mvrLicenseStatusRecords.value', response.mvrlicenseStatus);
                    _set(dataForComponent, 'lobData.personalAuto_EA.orderingInfo.value', response.orderingInfo);
                    updateDataForComponent({...dataForComponent});
                })
                .catch(() => {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: messages.reportsErrorTitle,
                        message: messages.reportsErrorMessage
                    });

                });
        }
        //   No array dependencies needed in this hook.
        //   The logic of initializing losses data needs to be executed only once
        //   when landing into losses page.
        //   eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSkipping]);


    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            disabled: true
        },
        tabs: {
            defaultActiveTab: getDefaultActiveTab
        },
        riskAnalysisPageLoadingIndicator: {
            loaded: true
        },
        RiskAnalysisPageContainer: {
            visible: !isSkipping
        },
        EAPriorCarrierComponentId: {
            submissionVM: renewalVM,
            disregardFieldValidation: () => {},
            viewOnlyMode: true
        },
        EALossAndViolationComponentId: {
            lobDataModel: renewalVM.lobData.personalAuto_EA,
            onModelChange: () => {},
            viewModelService,
            disregardFieldValidation: () => {},
            operators: renewalVM.lobData.personalAuto_EA.coverables.drivers?.value?.map((driver) => ({
                    publicId: driver.publicID,
                    displayName: driver.person.displayName
                })),
            viewOnlyMode: true,
            policyState,
            authUserData,
            isVerified: true,
        },
        EARiskAnalysisMiscComponentId: {
            submissionVM: renewalVM,
            viewOnlyMode: true,
            isSkipping,
            onValidate: () => {},
            showHousehold: false,
            shouldShowCedingField: policyState === 'NC'
        },
        EACreditReportsComponentId: {
            submissionVM: renewalVM,
            updateWizardData,
            lineOfBusiness: renewalVM.lobData.personalAuto_EA,
            viewOnlyMode: true,
            lobName: 'personalAuto_EA',
            authUserData,
            id: 'EACreditReportsComponentId',
            onValidate: () => {},
            setOverlappingException: false
        },
        vehicleReportTabID: {
            viewOnlyMode: true
        },
        lossesAndViolationID: {
            visible: !_isEmpty(dataForComponent),
            lossesAndViolations: dataForComponent,
            updateBaseDataComponent: updateDataForComponent,
            viewOnlyMode: true,
            drivers: renewalVM.lobData.personalAuto_EA.coverables.drivers,
            policyState
        },
        EAUWIssuesComponentId: {
            submissionVM: renewalVM,
            updateWizardData,
            authHeader,
            viewOnlyMode: true
        },
        diminishingAvailableAmount: {
            labelPosition: 'left'
        },
        diminishingAvailableAmountLatest: {
            labelPosition: 'left'
        },
        billingMiscellaneousReportComponent: {
            visible: authUserData?.permissions_Ext.includes('canviewbillingreport'),
            data: _get(renewalVM,'value.baseData.cancelActivities_Ext',[])
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(renewalVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: renewalVM,
            updateWizardData,
            onValidate: () => {},
            showErrors: false,
            viewOnlyMode: true
        },
        declareStatementThreeYears: {
            visible: visibleFields.includes('declareLossStatementThreeYears')
        },
        declareStatementFiveYears: {
            visible: visibleFields.includes('declareLossStatementFiveYears')
        },
        noMiscReportResultsMessageId: {
            visible: (() => 
                // If misc report content is empty 
                 !_get(document.getElementById('miscellaneousReportsBodyId'), 'innerText') 
                    && !_get(document.getElementById('billingMiscellaneousReportComponent'), 'innerText')
            )()
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                renewalVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, renewalVM]
    );

    const resolvers = {
        resolveCallbackMap: {
            onValidate: () => {}
        }
    };

    return (
        <WizardPage
            onNext={saveAndQuote}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={() => {}}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewRiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewRiskAnalysisPage);
