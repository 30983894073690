import React, { useCallback, useContext } from 'react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from '../ThirdPartyInterestPage.metadata.json5';

function ThirdPartyInterestPage(props) {
    const {
        wizardData: rewriteVM,
        viewOnly
    } = props;
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    /**
     * Helper callback for handling wizard page navigation after the "Next" button is pressed.
     */
    const onNext = useCallback(() => rewriteVM, [rewriteVM]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            readOnly: viewOnly
        },
        thirdPartyInterestPageLoadingIndicator: {
            loaded: true
        },
        thirdPartyInterestPageContainer: {
            visible: true
        },
        e1ptpiDisplayTableContainer: {
            transactionVM: rewriteVM,
            viewModelService,
            authHeader,
            updateWizardData: () => {},
            setIsSavingTPI: () => { },
            showErrors: false,
            onValidate: () => { },
            disregardFieldValidationParentPage: () => { },
            viewOnlyMode: true
        }
    };

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            addTPIButtonOnClickHandler: undefined,
            handleTPITableOnCell: undefined,
            getTPINameHyperlink: undefined,
            removeTPIButtonOnClickHandler: undefined
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, rewriteVM, id, path, overrideProps),
        [rewriteVM, overrideProps]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ThirdPartyInterestPage.propTypes = wizardProps;
export default ThirdPartyInterestPage;
