/* eslint-disable no-prototype-builtins */
import React, { useCallback, useContext, useState, useEffect } from 'react';
import {
    get as _get,
    includes as _includes,
    noop as _noop,
    pullAt as _pullAt,
    set as _set,
    isEmpty as _isEmpty,
    trim as _trim,
    find as _find,
    isUndefined as _isUndefined
} from 'lodash';
import {
    E1PEHTPISearchDetailComponent
} from 'e1p-capability-policyjob-react';
import { useModal } from '@jutro/components';
import { Grid } from '@jutro/layout';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { TPIUtil, ConsoleHelper } from 'e1p-portals-util-js';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTPIUtil } from 'e1p-capability-hooks';
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import metadata from './E1PEHTPIDisplayTableComponent.metadata.json5';
import messages from './E1PEHTPIDisplayTableComponent.messages';
import styles from './E1PEHTPIDisplayTableComponent.module.scss';

/**
 * This component file models the TPI table which appears on the EH "Third-Party Interest" page.
 *
 * @param {Object} props An object containing the properties passed into this component.
 * @returns {Object} An object containing the data required for rendering this component.
 */
function E1PEHTPIDisplayTableComponent(props) {
    const modalApi = useModal();
    const {
        transactionVM,
        authHeader,
        updateWizardData,
        viewOnlyMode,
        setIsSavingTPI,
        showErrors,
        onValidate,
        disregardFieldValidationParentPage,
        setIsAddingTPI,
        showTitle
    } = props;
    const { handleTPITableOnCell, updateNewlyAddedTPI } = useTPIUtil();
    const [isSearchTPIVisible, updateIsSearchTPIVisible] = useState(false);
    const productCode = _get(transactionVM, 'baseData.productCode.value', _get(transactionVM, 'productCode.value'));
    const [isTPIRecordsAddedRemoved, setIsTPIRecordsAddedRemoved] = useState(false);
    const tpiBasePath = TPIUtil.getTPIBasePath(productCode);
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();

    /**
     * Helper callback for showing the appropriate TPI detail component.
     */
    const showTPIDetailComponent = useCallback(
        async (addlInterestTPIVM) => {
            const componentProps = {
                title: 'Edit TPI',
                viewModelService,
                tpiDetailVM: addlInterestTPIVM,
                isControlledTPI: _get(addlInterestTPIVM, 'tpiid.value') !== undefined,
                isTPIContactTypeCompany: _get(addlInterestTPIVM, 'company.value') !== undefined,
                tpiBasePath,
                isTPITypeMortgagee: _includes(
                    _get(addlInterestTPIVM, 'addlInterestType.value.code'),
                    'MORTGAGEE'
                ),
                isTPITypeTrust:
                    _get(addlInterestTPIVM, 'addlInterestType.value.code') === 'TRUST_Ext',
                isAddingNewTPI: false,
                showPopup: true,
                transactionVM,
                authHeader,
                isEditingTPI: true,
                disregardFieldValidationParentPage
            };
            const result = await modalApi.showModal(
                <E1PEHTPISearchDetailComponent {...componentProps} />
            );

            return result;
        },
        [viewModelService, tpiBasePath, transactionVM, authHeader, disregardFieldValidationParentPage, modalApi]
    );

    /**
     * Helper callback for handling opening/closure of the Third Party Search modal component.
     */
    const openTPISearchModal = useCallback(
        (tpiObj, index) => {
            // show modal popover and pass the tpiVM to edit the tpi
            const addlInterestTPIVM = viewModelService.create(
                tpiObj,
                'pc',
                'amfam.edge.capabilities.policyjob.lob.eh.coverables.dto.EHDwellingAddlInterestDTO'
            );

            try {
                showTPIDetailComponent(addlInterestTPIVM)
                    .then((tpiDetailObj) => {
                        // Now add the tpi detail to the "transactionVM"
                        const allTPIs = _get(transactionVM.value, tpiBasePath);

                        // Remove from the index
                        _pullAt(allTPIs, index);
                        // Add to the TPI array
                        allTPIs.push(tpiDetailObj);
                        _set(transactionVM.value, tpiBasePath, allTPIs);
                        updateWizardData(transactionVM);
                    })
                    .catch();
            } catch {
                ConsoleHelper('user closed the modal');
            }
        },
        [viewModelService, showTPIDetailComponent, transactionVM, tpiBasePath, updateWizardData]
    );

    /**
    * Helper callback for checking whether the input person matches the policy's PNI or SNI.
    */
    const isTPIPersonPNIOrSNI = useCallback(
        (tpi) => {
            const tpiPersonId = tpi.person.publicID;
            const isTPIPersonPNI = tpiPersonId === _get(transactionVM, 'lobData.homeowners_EH.primaryNamedInsured.person.publicID.value', '');
            const isTPIPersonSNI = tpiPersonId === _get(transactionVM, 'lobData.homeowners_EH.secondaryNamedInsured.person.publicID.value', '');

            return isTPIPersonPNI || isTPIPersonSNI;
        },
        [transactionVM]
    );

    const updateNewlyAddedTPIAndUpdateWizardData = useCallback(
        async (tpiDetailObj) => {
            // Now add the tpi detail to the "transactionVM"
            const allTPIs = _get(transactionVM, `${tpiBasePath}.value`, []);

            if (
                tpiDetailObj.person
                && isTPIPersonPNIOrSNI(tpiDetailObj)
            ) {
                return modalApi.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: messages.matchesPNIorSNITitle,
                    message: messages.matchesPNIorSNIMessage
                }).then(() => { });
            }

            allTPIs.push(tpiDetailObj);
            _set(transactionVM, `${tpiBasePath}.value`, allTPIs);
            setIsSavingTPI(true);

            const updateDraftResponse = await updateNewlyAddedTPI(
                transactionVM.value, authHeader
            );

            _set(transactionVM, 'value', updateDraftResponse);
            updateWizardData(transactionVM);
            setIsSavingTPI(false);
            updateIsSearchTPIVisible(false);

            if (setIsAddingTPI) {
                setIsAddingTPI(false);
            }

            return allTPIs;
        },
        [transactionVM, tpiBasePath, isTPIPersonPNIOrSNI, setIsSavingTPI, updateNewlyAddedTPI, authHeader, updateWizardData, setIsAddingTPI, modalApi]
    );

    /**
     * Helper function for generating the TPI name hyperlink element.
     *
     * @param {Object} item An object containing the set of VMNode properties related to the table.
     * @param {Number} index A parameter defined as part of the the Jutro "renderCell" function.
     * @param {Object} property A reference to the TPI VMNode property that was changed.
     * @returns {Object} A link object containing the text to be displayed in the TPI name cell.
     */
    const getTPIName = (item) => {
        let tpiName = '';

        if (item.bankPrimaryName !== undefined) {
            tpiName = item.bankPrimaryName;

            // IAP-1709 : Display full name for searched tpis
            if (!_isEmpty(_trim(item.bankSecondaryName))) {
                tpiName = tpiName.concat(' ', item.bankSecondaryName);
            }

            // IAP-3482 : Display full name for searched tpis
            if (!_isEmpty(_trim(item.bankTertiaryName))) {
                tpiName = tpiName.concat(' ', item.bankTertiaryName);
            }
        } else if (item.company !== undefined) {
            tpiName = item.company.name;
        } else if (item.person !== undefined) {
            tpiName = item.person.firstName.concat(' ', item.person.lastName);
        }

        return tpiName;
    };

    /**
     * Helper callback for removing a TPI record from the TPI table.
     */
    const removeTPIRecord = useCallback(
        (rowToRemove) => {
            const allTPIs = _get(transactionVM, tpiBasePath);

            if (allTPIs.value.length > 0) {
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: e1pCommonMessages.removeTPITitle,
                    message: e1pCommonMessages.removeTPIDescription,
                    confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'THIRD-PARTY INTEREST' }),
                    cancelButtonText: e1pCommonMessages.cancel
                }).then((results) => {
                    if (results !== 'cancel') {
                        _pullAt(allTPIs.value, rowToRemove);
                        _set(transactionVM, `${tpiBasePath}.value`, allTPIs.value);
                        updateWizardData(transactionVM);
                        setIsTPIRecordsAddedRemoved(true);

                        return transactionVM;
                    }

                    return _noop();
                });
            }
        },
        [transactionVM, tpiBasePath, modalApi, translator, updateWizardData]
    );

    /**
     * Helper function for handling when the "Remove Third-Party Interest" button is clicked.
     *
     * @param {*} item An object containing the set of VMNode properties related to the table row.
     * @param {*} rowToRemove A number representing the currently selected table row.
     */
    const removeTPIButtonOnClickHandler = (item, rowToRemove) => {
        removeTPIRecord(rowToRemove);
    };

    const editTPIButtonOnClickHandler = (item, rowToEdit) => {
        openTPISearchModal(item, rowToEdit);
    }

    const renderExpanderContent = useCallback((data) => (
            <Grid
                columns={['1fr', '1fr', '7fr']}
                gap="none"
                className={styles.tpiExpanderGrid}
            >
                <span className={styles.tpiField}>{translator(e1pCommonMessages.billing)}</span>
                <span className={styles.tpiField}>{translator(e1pCommonMessages.preferred)}</span>
                <div />
                <span className={styles.tpiFieldValue}>{data.escrowInd ? 'Yes' : 'No'}</span>
                <span className={styles.tpiFieldValue}>{data.preferredInd ? 'Yes' : 'No'}</span>
            </Grid>
        ), [translator]);

    useEffect(() => {
        /**
         * IAP-1709
         * We don't want to show expandable rows for tpis other than mortgagees
         * There is no direct way to show expandable rows conditionally, so we have to do this
         */
        try {
            setIsTPIRecordsAddedRemoved(false);

            const tpiTable = document.getElementById('tpiTable');
            const expandableRowIconDivs = tpiTable?.getElementsByClassName('rt-td jut__DataTable__tableCell jut__DataTable__expanderCell rt-expandable');
            const additionalInterests = _get(transactionVM, 'lobData.homeowners_EH.coverables.yourHome.additionalInterests.value', []);
            const ADDL_INTEREST_TYPE_TL = viewModelService.productMetadata
                .get('pc')
                .types.getTypelist('AdditionalInterestType');
            const controlledTPIFilterCodes = _find(ADDL_INTEREST_TYPE_TL.filters, {
                name: 'EHControlledTPIFilter_Ext'
            }).codes.map((filteredCodesObj) => filteredCodesObj.code);

            for (const expandableRowIconDivIndex in expandableRowIconDivs) {
                if (expandableRowIconDivs.hasOwnProperty(expandableRowIconDivIndex)) {
                    const additionalInterest = additionalInterests[expandableRowIconDivIndex];

                    expandableRowIconDivs[expandableRowIconDivIndex].setAttribute('style', 'visibility:hidden');

                    if (
                        controlledTPIFilterCodes.includes(additionalInterest.addlInterestType)
                        && !_isUndefined(additionalInterest.tpiid)
                    ) {
                        expandableRowIconDivs[expandableRowIconDivIndex].setAttribute('style', 'visibility:visible');

                    }
                }

            }
        } catch{
            // try block should not throw any exception; its just for additional checking
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTPIRecordsAddedRemoved, isSearchTPIVisible])


    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'top',
            disabled: viewOnlyMode,
            autoComplete: false
        },
        addThirdPartyInterestButton: {
            visible: !viewOnlyMode
        },
        tpiTableActions: {
            visible: !viewOnlyMode
        },
        tpiTable: {
            renderExpanderContent
        },
        noTpiHasBeenAdded: {
            visible: _isEmpty(_get(transactionVM, `${tpiBasePath}.value`, []))
        },
        tpiContainer: {
            visible: !_isEmpty(_get(transactionVM, `${tpiBasePath}.value`, []))
        },
        addTPIComponentContainer: {
            visible: !viewOnlyMode
        },
        thirdPartyInterestTitle: {
            visible: showTitle
        },
        searchTPI: {
            onClick: (() => {
                updateIsSearchTPIVisible(true);

                if (setIsAddingTPI) {
                    setIsAddingTPI(true);
                }
            })
        },
        ehTPIDisplayContainer: {
            visible: !isSearchTPIVisible
        },
        tpiSearchComponent: {
            visible: isSearchTPIVisible,
            productCode,
            tpiBasePath,
            viewModelService,
            authHeader,
            transactionVM,
            isAddingExistingContact: false,
            existingContact: false,
            isPerson: false,
            updateIsSearchTPIVisible,
            saveThirdPartyInterestClickHandler: updateNewlyAddedTPIAndUpdateWizardData,
            showErrors,
            onValidate,
            disregardFieldValidationParentPage,
            setIsAddingTPI
        }
    };

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            getTPIName,
            handleTPITableOnCell,
            removeTPIButtonOnClickHandler,
            editTPIButtonOnClickHandler,
            onRowClick: (data) => {
                _set(data, 'expanded', !data.expanded);
            }
        },
        resolveClassNameMap: styles
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={transactionVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

/**
 * Define expected types for properties to be passed into this Jutro component.
 */
E1PEHTPIDisplayTableComponent.propTypes = {
    transactionVM: PropTypes.shape({
        lobData: PropTypes.shape({}),
        baseData: PropTypes.shape({
            policyAddress: PropTypes.shape({
                state: PropTypes.shape({
                    value: PropTypes.shape({
                        code: PropTypes.string
                    })
                })
            })
        }),
        value: PropTypes.shape({})
    }),
    authHeader: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    showErrors: PropTypes.bool.isRequired,
    setIsSavingTPI: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    disregardFieldValidationParentPage: PropTypes.func.isRequired,
    setIsAddingTPI: PropTypes.func,
    showTitle: PropTypes.bool
};

/**
 * Define default values for properties to be passed into this Jutro component.
 */
E1PEHTPIDisplayTableComponent.defaultProps = {
    transactionVM: {},
    viewOnlyMode: false,
    setIsAddingTPI: undefined,
    showTitle: true
};

export default E1PEHTPIDisplayTableComponent;
