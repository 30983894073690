/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { CurrencyValue } from '@jutro/components';
import { Grid, GridItem } from '@jutro/layout';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import listOfCoveragesReferenceCode from 'e1p-portals-util-js/EHWhatsIncluded-config.json';
import SymbolCurrencyComponent from '../SymbolCurrencyComponent/SymbolCurrencyComponent';

import styles from './EHClauseComponent.module.scss';

import { CheckboxField } from '@jutro/legacy/components';

import { Link } from '@jutro/router';

class ClauseComponent extends Component {
    /**
     * @memberof gw-components-platform-react.ClauseComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.displayName - name of clause to display
     * @prop {bool} propTypes.readOnly - if the clause selection is read only
     * @prop {bool} propTypes.value - is the clause is seleceted
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {bool} propTypes.isLoading - should the clause be loading
     * @prop {string} propTypes.loadingMessage - message to be shown while loading
     * @prop {string} propTypes.containerClassName - clause container class
     */

    static propTypes = {
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        readOnly: PropTypes.bool,
        onValueChange: PropTypes.func.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape({})),
        value: PropTypes.bool,
        path: PropTypes.string,
        isLoading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        checked: PropTypes.bool,
        isEditable: PropTypes.bool,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        containerClassName: PropTypes.string,
        labelPosition: PropTypes.string,
        isDwellingCovA: PropTypes.bool,
        isQuoteStale: PropTypes.bool,
        replacementCost: PropTypes.shape({}),
        amount: PropTypes.shape({}),
        jumpTo: PropTypes.func,
        steps: PropTypes.arrayOf(PropTypes.shape({})),
        policyType: PropTypes.string,
        viewOnlyMode: PropTypes.bool,
        showPremium: PropTypes.bool,
        hasGoldOrPlatinumBoxVisible: PropTypes.bool.isRequired,
        referenceCode: PropTypes.string,
        policyState: PropTypes.string.isRequired,
        isSideBySideQuoteMode: PropTypes.bool
    }

    static defaultProps = {
        readOnly: false,
        path: undefined,
        value: undefined,
        isLoading: false,
        loadingMessage: '',
        checked: false,
        children: undefined,
        isEditable: true,
        description: undefined,
        containerClassName: undefined,
        labelPosition: 'left',
        amount: undefined,
        replacementCost: undefined,
        isDwellingCovA: true,
        isQuoteStale: false,
        jumpTo: () => { },
        steps: [],
        policyType: '',
        viewOnlyMode: false,
        showPremium: true,
        referenceCode: '',
        isSideBySideQuoteMode: false
    }

    handleChange = (value) => {
        const { onValueChange, path } = this.props;

        if (onValueChange) {
            onValueChange(value, path);
        }
    }

    renderEditableValue() {
        const {
            displayName,
            readOnly,
            value,
            id,
            isLoading,
            loadingMessage,
            children,
            path,
            checked,
            description,
            containerClassName,
            labelPosition,
            amount,
            replacementCost,
            isDwellingCovA,
            isQuoteStale,
            jumpTo,
            steps,
            policyType,
            viewOnlyMode,
            showPremium,
            hasGoldOrPlatinumBoxVisible,
            referenceCode,
            policyState,
            isSideBySideQuoteMode
        } = this.props;

        const goToPropertyPage = (event) => {
            // Test circular JS error
            event.stopPropagation();
            event.preventDefault();

            const indexToPropertyPage = _.findIndex(
                steps, (step) => step.path === '/property'
            );

            jumpTo(indexToPropertyPage);
        };

        const hasChildren = !_.isNil(children);
        const clauseContainerStyles = classNames(styles.clause, containerClassName);
        const checkboxControlStyles = classNames({
            [styles.clauseAndTermSpacing]: hasChildren
        });
        const checkboxStyles = classNames({
            [styles.clauseNoPadding]: !hasChildren
        });
        // showing discount icon with each coverage if condition gets satisfied.
        const checkboxLabelStyles = () => {
            if (!hasGoldOrPlatinumBoxVisible && (policyType === 'HO3' || policyType === 'HF9')) {
                const showDiscountIcon = listOfCoveragesReferenceCode[policyState]?.includes(referenceCode);
                const styleClass = classNames({
                    [styles.appendIcon]: showDiscountIcon
                });

                return styleClass;
            }

            return '';
        };

        const premiumGrid = classNames(styles.thirdStyle);
        const gapConfig = isSideBySideQuoteMode ? 'none' : 'medium';
        const columnsConfig = isSideBySideQuoteMode ? ['0.3fr', '2fr', '0.85fr'] : ['1fr', '1fr', '1fr'];

        return (
            <div className={isSideBySideQuoteMode ? '' : clauseContainerStyles}>
                {isLoading
                    ? (
                        <E1PLoader
                            loaded={!isLoading}
                            text={loadingMessage}
                        />
                    ) : (
                        <Grid
                                columns={columnsConfig}
                                gap={gapConfig}
                                id="Grid"
                                rows={[
                                    '1fr',
                                ]}
                                tag="div"
                            >
                                <GridItem
                                    className="jut__Containers__highlightHover"
                                    tag="div"
                                >
                                    <React.Fragment>
                                        <CheckboxField
                                            id={id}
                                            label={isSideBySideQuoteMode ? '' : displayName}
                                            value={value}
                                            readOnly={readOnly}
                                            onValueChange={this.handleChange}
                                            labelClassName={checkboxLabelStyles()}
                                            className={checkboxStyles}
                                            controlClassName={checkboxControlStyles}
                                            path={path}
                                            checked={checked}
                                            labelPosition={labelPosition}
                                            showInlineLabel
                                            tooltip={!_.isUndefined(description) ? { text: description } : null}
                                            layout="full-width"
                                        />
                                        {(isDwellingCovA && (policyType === 'HO3' || policyType === 'HF9'))
                                            ? (
                                                <Link
                                                    iconPosition="left"
                                                    id="button"
                                                    type="outlined"
                                                    className={styles.editCoverages}
                                                    onClick={(event) => goToPropertyPage(event)}
                                                    disabled={viewOnlyMode}
                                                >
                                                    Replacement Cost: &nbsp;

                                                    <SymbolCurrencyComponent
                                                        value={replacementCost}
                                                    />
                                                </Link>
                                            ) : null}

                                    </React.Fragment>
                                </GridItem>
                                <GridItem
                                    className="jut__Containers__highlightHover"
                                    tag="div"
                                >
                                    {value ? children : null}
                                </GridItem>

                                {(!isQuoteStale && showPremium) ? (
                                    <GridItem
                                        className={premiumGrid}
                                        tag="div"
                                    >
                                        <SymbolCurrencyComponent
                                            value={amount}
                                        />
                                    </GridItem>
                                )
                                    : (
                                        <GridItem
                                            className={premiumGrid}
                                            tag="div"
                                        >
                                            <span>-</span>
                                        </GridItem>
                                    )
                                }


                            </Grid>
                    )
                }
            </div>
        );
    }

    renderReadOnlyValue() {
        const {
            displayName, children, amount
        } = this.props;

        return (
            <div className={styles.readOnlyGrid}>
                <span className={styles.readOnlyGridSlotLeft}>
                    {displayName}
                    <span className={styles.readOnlyGridAmount}>
                        {!_.isUndefined(amount) ? (
                            <CurrencyValue
                                amount={amount.amount}
                                currency={amount.currency}
                                showFractions
                            />
                        ) : undefined
                        }
                    </span>
                </span>
                <div className={styles.readOnlyGridSlotRight}>
                    {children}
                </div>
            </div>
        );
    }

    render() {
        const { isEditable } = this.props;

        return isEditable ? this.renderEditableValue() : this.renderReadOnlyValue();
    }
}

export default ClauseComponent;
