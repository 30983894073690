import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import EADriversPage from './pages/Drivers/DriversPage';
import EAVehiclesPage from './pages/Vehicles/VehiclesPage';
import EAQuotePage from './pages/Quote/QuotePage';
import EAAdditionalInformation from './pages/AdditionalInformation/AdditionalInformationPage';
import EAQuoteClauseTable from './components/QuoteClauseTable/QuoteClauseTable';
import EASingleClauseComponentVM from './components/Clauses/SingleClauseComponentVM';
import EAClauseComponent from './components/Clauses/ClauseComponent';
import EAViewDriversPage from './pages/Drivers/ViewOnly/ViewDriversPage';
import EAViewVehiclesPage from './pages/Vehicles/ViewOnly/ViewVehiclesPage';
import EAViewQuotePage from './pages/Quote/ViewOnly/ViewQuotePage';
import EAPaymentDetailsPage from './pages/PaymentDetails/PaymentDetailsPage';
import EAViewPaymentDetailsPage from './pages/PaymentDetails/ViewOnly/ViewPaymentDetailsPage';
import EAThankYouPage from './pages/ThankYou/ThankYouPage';
import EAAdditionalInfoDriver from './components/AdditionalInfoDriver/AdditionalInfoDriver';
import EARiskAnalysisPage from './pages/RiskAnalysis/RiskAnalysisPage';
import EAThirdPartyInterestPage from './pages/ThirdPartyInterest/ThirdPartyInterestPage';
import EAViewThirdPartyInterestPage from './pages/ThirdPartyInterest/ViewOnly/ViewThirdPartyInterestPage';
import QNBViewEARiskAnalysisPage from './pages/RiskAnalysis/viewOnly/ViewEARiskAnalysisPage';
import EaSideBySideContainer from './components/EaSideBySideContainer/EaSideBySideContainer';
import EaSideBySideQuoteDetailsComponent from './components/EaSideBySideQuoteDetailsComponent/EaSideBySideQuoteDetailsComponent';
import EaSideBySidePolicyLevelComponent from './components/EaSideBySidePolicyLevelComponent/EaSideBySidePolicyLevelComponent';
import EaSideBySideVehicleLevelComponent from './components/EaSideBySideVehicleLevelComponent/EaSideBySideVehicleLevelComponent';
import DriverAssignmentPage from './pages/DriverAssignment/DriverAssignmentPage';
import EAViewDriverAssignmentPage from './pages/DriverAssignment/ViewOnly/ViewDriverAssignmentPage';
import EAPolicyLevelCoveragesComponent from './components/EAPolicyLevelCoveragesComponent/EAPolicyLevelCoveragesComponent';
import EAVehicleLevelCoveragesComponent from './components/EAVehicleLevelCoveragesComponent/EAVehicleLevelCoveragesComponent';

setComponentMapOverrides(
    {
        EADriversPage: { component: 'EADriversPage' },
        EAVehiclesPage: { component: 'EAVehiclesPage' },
        EAQuotePage: { component: 'EAQuotePage' },
        EAAdditionalInformation: { component: 'EAAdditionalInformation' },
        EAQuoteClauseTable: { component: 'EAQuoteClauseTable' },
        EASingleClauseComponentVM: { component: 'EASingleClauseComponentVM' },
        EAClauseComponent: { component: 'EAClauseComponent' },
        EAViewDriversPage: { component: 'EAViewDriversPage' },
        EAViewVehiclesPage: { component: 'EAViewVehiclesPage' },
        EAViewQuotePage: { component: 'EAViewQuotePage' },
        EAPaymentDetailsPage: { component: 'EAPaymentDetailsPage' },
        EAViewPaymentDetailsPage: { component: 'EAViewPaymentDetailsPage' },
        EAThankYouPage: { component: 'EAThankYouPage' },
        EAAdditionalInfoDriver: { component: 'EAAdditionalInfoDriver' },
        EARiskAnalysisPage: { component: 'EARiskAnalysisPage' },
        EAThirdPartyInterestPage: { component: 'EAThirdPartyInterestPage' },
        EAViewThirdPartyInterestPage: { component: 'EAViewThirdPartyInterestPage' },
        QNBViewEARiskAnalysisPage: { component: 'QNBViewEARiskAnalysisPage' },
        EaSideBySideContainer: { component: 'EaSideBySideContainer' },
        EaSideBySideQuoteDetailsComponent: { component: 'EaSideBySideQuoteDetailsComponent' },
        EaSideBySidePolicyLevelComponent: { component: 'EaSideBySidePolicyLevelComponent' },
        EaSideBySideVehicleLevelComponent: { component: 'EaSideBySideVehicleLevelComponent' },
        DriverAssignmentPage: { component: 'DriverAssignmentPage' },
        EAViewDriverAssignmentPage: { component: 'EAViewDriverAssignmentPage' },
        EAPolicyLevelCoveragesComponent: { component: 'EAPolicyLevelCoveragesComponent' },
        EAVehicleLevelCoveragesComponent: { component: 'EAVehicleLevelCoveragesComponent' }
    },
    {
        EADriversPage,
        EAVehiclesPage,
        EAQuotePage,
        EAAdditionalInformation,
        EAQuoteClauseTable,
        EASingleClauseComponentVM,
        EAClauseComponent,
        EAViewDriversPage,
        EAViewVehiclesPage,
        EAViewQuotePage,
        EAPaymentDetailsPage,
        EAViewPaymentDetailsPage,
        EAThankYouPage,
        EAAdditionalInfoDriver,
        EARiskAnalysisPage,
        EAThirdPartyInterestPage,
        EAViewThirdPartyInterestPage,
        QNBViewEARiskAnalysisPage,
        EaSideBySideContainer,
        EaSideBySideQuoteDetailsComponent,
        EaSideBySidePolicyLevelComponent,
        EaSideBySideVehicleLevelComponent,
        DriverAssignmentPage,
        EAViewDriverAssignmentPage,
        EAPolicyLevelCoveragesComponent,
        EAVehicleLevelCoveragesComponent
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as EAWizard } from './EAWizard';
