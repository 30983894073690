import { useCallback } from 'react';
import useAttributeConfigUtil from '../useAttributeConfigUtil';
import useAttributeConfigDataOperationUtil from '../useAttributeConfigDataOperationUtil';
import pathConfig from './PathConfigurationForConfigMetadataForEA.json';


/**
 * Util for getting config data for EA fields
 * @param {Object} transactionVM 
 * @returns {*}
 */
function useAttributeConfigUtilForEA(transactionVM) {
    const { getAttributeconfig } = useAttributeConfigUtil(transactionVM);

    /**
     * Helper function to get available values for policy driver role types
     */
    const getAvailableValuesForPolicyDriverRoleTypes = useCallback(async () => {
        const configMetadata = await getAttributeconfig();
        return useAttributeConfigDataOperationUtil
            .getMappedAvailableValuesForGivenFieldAndConfigData(configMetadata, 'policyDriverRoleType', pathConfig);
    }, [getAttributeconfig]);

    /**
     * Helper function to get available values for marital status
     */
    const getAvailableValuesForMaritalStatus = useCallback(async () => {
        const configMetadata = await getAttributeconfig();
        return useAttributeConfigDataOperationUtil
            .getMappedAvailableValuesForGivenFieldAndConfigData(configMetadata, 'maritalStatus', pathConfig);
    }, [getAttributeconfig]);

    /**
     * Helper function to get available values for signature types
     **/
    const getAvailableValuesForSignatureType = useCallback(async () => {
        const configMetadata = await getAttributeconfig();
        return useAttributeConfigDataOperationUtil
            .getMappedAvailableValuesForGivenFieldAndConfigData(configMetadata, 'signatureType', pathConfig);
    }, [getAttributeconfig]);

    /**
     * Helper function to get available values for prior policy update reasons
     **/
    const getAvailableValuesForPriorPolicyUpdateReasons = useCallback(async () => {
        const configMetadata = await getAttributeconfig();
        return useAttributeConfigDataOperationUtil
            .getMappedAvailableValuesForGivenFieldAndConfigData(configMetadata, 'priorPolicyUpdatesReasons', pathConfig);
    }, [getAttributeconfig]);

    /**
     * Helper function to get available values for all fields on driver page
     * Note: create this kind of function if we have multiple fields for which we have to get
     * available values, if we try to use function specific to fields, attributeConfig api can be called multiple times
     * as update to context is async
     */
    const getAvailableValuesForAllFieldsForDriverPage = useCallback(async () => {
        const configMetadata = await getAttributeconfig();
        const allFieldsWhoNeedsAvailableValues = [
            'policyDriverRoleType',
            'maritalStatus'
        ];
        const fieldSpecificAvailableValues = [];
        for (let index = 0; index < allFieldsWhoNeedsAvailableValues.length; index++) {
            const fieldName = allFieldsWhoNeedsAvailableValues[index];
            const availableValues = useAttributeConfigDataOperationUtil
                .getMappedAvailableValuesForGivenFieldAndConfigData(configMetadata, fieldName, pathConfig);
            fieldSpecificAvailableValues[fieldName.concat('AvailableValues')] = availableValues;
        }
        return fieldSpecificAvailableValues;
    }, [getAttributeconfig]);

    /**
     * Helper function to get available values for safety features
     */
    const getAvailableValuesForSafetyFeatures = useCallback(async () => {
        const configMetadata = await getAttributeconfig();
        return useAttributeConfigDataOperationUtil
            .getMappedAvailableValuesForGivenFieldAndConfigData(configMetadata, 'safetyFeatures', pathConfig);
    }, [getAttributeconfig]);

    return {
        getAvailableValuesForAllFieldsForDriverPage,
        getAvailableValuesForPolicyDriverRoleTypes,
        getAvailableValuesForMaritalStatus,
        getAvailableValuesForSignatureType,
        getAvailableValuesForSafetyFeatures,
        getAvailableValuesForPriorPolicyUpdateReasons
    }

}

export default useAttributeConfigUtilForEA;