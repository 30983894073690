import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

/**
 * Invokes the given method on the backend endpoint passing the given data as parameters
 *
 * @param {any} method the method to invoke on the backend endpoint
 * @param {any} data the data to be passed as part of the invokation on the backend
 * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
 * @returns {Promise} the promise from the backend call
 */
function processEndorsement(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(
        getProxiedServiceUrl('gatewayPolicyChange'),
        method,
        data,
        additionalHeaders
    );
}

/**
 * This is the server responsible for dealing with the Quote and Buy backend operations
 *
 * @export
 * @class EndorsementService
 */
export default class EndorsementService {
    /**
   * Updates, saves and performs a quote on an existing submission.
   * Generates quotes for all available product offerings.
   *
   * @param {Object} data tge submission that will be saved
   * @param {Object} [additionalHeaders] additional headers to pass to the backend (e.g. auth)
   * @returns {Promise} the promise from the backend call
   * @memberof EndorsementService
   */
    static loadEndorsement(data, additionalHeaders = {}) {
        return processEndorsement('load', [data], additionalHeaders);
    }

    static loadEndorsementWithEffectiveDate(data, additionalHeaders = {}) {
        return processEndorsement('loadWithEffectiveDate', data, additionalHeaders);
    }

    static saveEndorsement(data, additionalHeaders = {}) {
        return processEndorsement('save', data, additionalHeaders);
    }

    /* Reused from Quote and Buy and Method overrided */
    static saveAndQuoteSubmission(data, additionalHeaders = {}) {
        return processEndorsement('save', data, additionalHeaders);
    }

    static updateCoverages(
        quoteID,
        sessionUUID,
        clausesToUpdate,
        additionalHeaders = {}
    ) {
        return processEndorsement(
            'updateCoverages',
            [quoteID, clausesToUpdate],
            additionalHeaders
        );
    }

    static quoteEndorsement(data, additionalHeaders = {}) {
        return processEndorsement('quote', data, additionalHeaders);
    }

    static bind(data, additionalHeaders = {}) {
        return processEndorsement('bind', data, additionalHeaders);
    }

    static withdrawEndorsement(data, additionalHeaders = {}) {
        return processEndorsement('withdraw', data, additionalHeaders);
    }

    static getAvailablePolicy(data, additionalHeaders = {}) {
        return processEndorsement('getAvailablePolicy', [data], additionalHeaders);
    }

    static getAvailablePolicies(data, additionalHeaders = {}) {
        return processEndorsement('getAvailablePolicies', data, additionalHeaders);
    }

    static getPolicyBillingSummary(data, additionalHeaders = {}) {
        return JsonRPCService.send(
            getProxiedServiceUrl('gatewayBilling'),
            'getPolicyBillingSummary',
            data,
            additionalHeaders
        );
    }

    static checkEffectiveDateIsValid(data, additionalHeaders = {}) {
        return processEndorsement(
            'checkEffectiveDateIsValid',
            data,
            additionalHeaders
        );
    }

    static createWithEffectiveDateSourceAndReason(
        policyNumber,
        effectiveDate,
        reason,
        source,
        additionalHeaders = {}
    ) {
        return processEndorsement(
            'createWithEffectiveDateSourceAndReason',
            [policyNumber, effectiveDate, reason, source],
            additionalHeaders
        );
    }

    static createPolicyChange(
        policyNumber,
        effectiveDate,
        reason,
        source,
        lossAfterEffDate,
        additionalHeaders = {}
    ) {
        return processEndorsement(
            'createPolicyChange',
            [policyNumber, effectiveDate, reason, source, lossAfterEffDate],
            additionalHeaders
        );
    }

    static isQuoted(data, additionalHeaders = {}) {
        return processEndorsement('isQuoted', data, additionalHeaders);
    }

    static loadEndorsementByJobNumber(data, additionalHeaders = {}) {
        return processEndorsement('loadJob', data, additionalHeaders);
    }

    static handleOOSConflicts(data, additionalHeaders = {}) {
        return processEndorsement('handleOOSConflicts', data, additionalHeaders);
    }

    static saveAndQuoteEndorsement(data, additionalHeaders = {}) {
        return processEndorsement('saveAndQuote', data, additionalHeaders);
    }

    /**
     * Returns newly added associated policies for given policy change
     * @param {String} policyChangeNumber
     * @param {Object} additionalHeaders
     * @returns {Promise} the promise from the backend call
     */
    static getNewlyAddedAssociatedPolicies(policyChangeNumber, additionalHeaders = {}) {
        return processEndorsement('getNewlyAddedAssociatedPolicies', [policyChangeNumber], additionalHeaders);
    }

    static bindChange(data, additionalHeaders = {}) {
        return processEndorsement('bindChange', data, additionalHeaders);
    }

    /**
     * update draft change api call with updateOptions
     * updateOptions are used to forcefully updating coverages
     * @param {Object} policyChangeData current policyChangeData
     * @param {Object} updateOptions update options for updating coverages
     * @param {Object} additionalHeaders  additional headers to pass to the backend(e.g. auth)
     * @returns {Promise} the promise from backend call
     */
    static saveWithOptions(policyChangeData, updateOptions, additionalHeaders = {}){
        return processEndorsement('saveWithOptions', [policyChangeData, updateOptions], additionalHeaders);
    }
}
