import { defineMessages } from 'react-intl';

export default defineMessages({
    wizardTitle: {
        id: 'rewrite.eu.directives.templates.page.Start Rewrite EU',
        defaultMessage: 'Start Rewrite EU'
    },
    startRewrite: {
        id: 'rewrite.eu.directives.templates.page.Start Rewrite',
        defaultMessage: 'Start Rewrite'
    },
    insuredDetails: {
        id: 'rewrite.eu.directives.templates.page.Insured Details',
        defaultMessage: 'Insured Details'
    },
    qualification: {
        id: 'rewrite.eu.directives.templates.page.Qualification',
        defaultMessage: 'Qualification'
    },
    ulPolicy: {
        id: 'rewrite.eu.directives.templates.page.Underlying Policy',
        defaultMessage: 'Underlying Policy'
    },
    vehicleOperator: {
        id: 'rewrite.eu.directives.templates.page.Vehicle Operator',
        defaultMessage: 'Vehicle Operator',
    },
    riskAnalysis: {
        id: 'rewrite.eu.directives.templates.page.Risk Analysis',
        defaultMessage: 'Risk Analysis'
    },
    coverages: {
        id: 'rewrite.eu.directives.templates.page.Coverages',
        defaultMessage: 'Coverages'
    },
    thirdPartyInterest: {
        id: 'rewrite.eu.directives.templates.page.Third-Party Interest',
        defaultMessage: 'Third-Party Interest'
    },
    thankYou: {
        id: 'rewrite.eu.directives.templates.page.Thank You',
        defaultMessage: 'Thank You'
    },
    exitTransaction: {
        id: 'rewrite.eu.wizard.steps.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'rewrite.eu.wizard.steps.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'rewrite.eu.wizard.steps.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'rewrite.eu.wizard.steps.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    },
    anErrorOccurred: {
        id: 'rewrite.eu.wizard.error.cancel',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'rewrite.eu.wizard.error.cancel.title',
        defaultMessage: 'Error'
    }
});
