import { defineMessages } from 'react-intl';

export default defineMessages({
    PrefillDriver: {
        id: 'quoteandbind.ea.views.ea-driver-details.Driver',
        defaultMessage: 'Driver'
    },
    PrefillStatus: {
        id: 'quoteandbind.ea.views.ea-driver-details.Add to Quote?',
        defaultMessage: 'Add to Quote?'
    },
    PrefillContinue: {
        id: 'quoteandbind.ea.views.ea-driver-details.Continue',
        defaultMessage: 'Continue'
    },
    PrefillSubTitle: {
        id: 'quoteandbind.ea.views.ea-driver-details.Select resident(s) to add to quote from pre-fill report.',
        defaultMessage: 'Select resident(s) to add to quote from pre-fill report.'
    },
    AutoPrefillTitle: {
        id: 'quoteandbind.ea.views.ea-driver-details.Household Drivers',
        defaultMessage: 'Household Drivers'
    },
    PrefillDriverError: {
        id: 'quoteandbind.ea.views.ea-driver-details.error text',
        defaultMessage: 'Party Pre-Fill report not available. Add people as necessary'
    }
});
