import { defineMessages } from 'react-intl';

// It contains list of Umbrella validation messages. New eu validations should be added here.
export default defineMessages({
    personalLiabilityLimitUwIssueInfoMessage: {
        id: 'e1p.platform.lob.eu.info.Personal liability limits greater than $5 million requires underwriter review and approval. Please contact customer service.',
        defaultMessage: 'Personal liability limits greater than $5 million requires underwriter review and approval. Please contact customer service.'
    },
    mandatoryPolicyType: {
        id: 'e1p.platform.lob.eu.info.Personal Property with primary home is required as Underlying policy.',
        defaultMessage: 'Personal Property with primary home is required as Underlying policy.'
    },
    insuredResidenceAddressWarningText: {
        id: 'e1p.platform.lob.eu.info.If Residence Address has changed, please update Mailing and Billing addresses and the Risk Address of the Underlying Primary Residence. Changes only apply to this policy. Other policies associated to this insured also need to be updated.',
        defaultMessage: 'If Residence Address has changed, please update Mailing and Billing addresses and the Risk Address of the Underlying Primary Residence. Changes only apply to this policy. Other policies associated to this insured also need to be updated.'
    },
    coveragePrioirPolicyBoundMessage: {
        id: 'e1p.platform.lob.eu.info.Before Umbrella policy can be bound, all underlying policies must be bound with effective dates equal to, or prior to Umbrella effective date.',
        defaultMessage: 'Before Umbrella policy can be bound, all underlying policies must be bound with effective dates equal to, or prior to Umbrella effective date.'
    }
    
});
