import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { useModal } from '@jutro/components';
import { Wizard } from 'e1p-portals-wizard-react';
import { EndorsementService } from 'e1p-capability-policychange';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { error as loggerError } from '@jutro/logger';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import { OnPageOperationsUtil } from 'e1p-portals-util-js';
import changeWizardConfig from './config/ea-wizard-config.json5';
import viewWizardConfig from './config/viewOnly/ea-wizard-config.json5';
import wizardStepToFieldMapping from './config/ea-wizard-step-to-field-mapping.json5';
import styles from './EAPolicyChangeWizard.module.scss';

function EAPolicyChangeWizard(props) {
    const modalApi = useModal();
    const [wizardConfig, setWizardConfig] = useState(changeWizardConfig);
    const { steps, title } = wizardConfig;
    const [initialPolicyChange, setinitialPolicyChange] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [viewOnly, setViewOnly] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { location, history, authHeader } = props;
    const { onPageCancel, onPagePrevious } = OnPageOperationsUtil();
    const [
        isPageJumpEnabled,
        updateIsPageJumpEnabled
    ] = useState(false);

    useEffect(() => {
        if (!location.state) {
            history.push('/');

            return;
        }

        if (location.state.viewOnly) {
            setViewOnly(true);
            setWizardConfig(viewWizardConfig);
        }

        const { state: { policyNumber, jobNumber, latestPeriod } } = location;

        if (!policyNumber && !jobNumber) {
            return;
        }

        const errorModalBox = (errorMessage) => {
            modalApi.showConfirm(errorMessage).then(() => {
                setIsLoading(false);

                let path = `/contactAgent/${policyNumber}`;

                if (jobNumber) {
                    path = `/change/${jobNumber}/summary`;
                }

                history.push(path);
            });
        };

        if (jobNumber) {
            EndorsementService.loadEndorsementByJobNumber([jobNumber], authHeader)
                .then((responseData) => {
                    const policyChange = viewModelService.create(
                        responseData,
                        'pc',
                        'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
                    );

                    // Following maps jackson property to viewmodel custom field property
                    policyChange.policyType_Ext.value ??= responseData.policyType;
                    policyChange.policyChangeReason_Ext.value
                        ??= responseData.policyChangeReason;
                    policyChange.policyChangeSource_Ext.value
                        ??= responseData.policyChangeSource;
                    // need to map the producer code over
                    // producer code is assigned from the submission and cannot be changed
                    _.set(policyChange, 'baseData.producerCode_Ext.value', responseData.baseData.producerCode);
                    setinitialPolicyChange(policyChange);
                    updateIsPageJumpEnabled(true);
                    setIsLoading(false);
                }).catch(() => {
                    errorModalBox({
                        title: commonMessages.unableToLoadDraftPolicy,
                        message: commonMessages.somethingWentWrong,
                        status: 'warning',
                        icon: 'mi-error-outline'
                    });
                });
        } else if (policyNumber) {
            const effDate = moment(latestPeriod.effectiveDate);
            const currentDate = moment();
            let loadEffectiveDate = {
                year: effDate.year(), month: effDate.month(), day: effDate.date()
            };

            if (currentDate.isAfter(effDate, 'd')) {
                loadEffectiveDate = {
                    year: currentDate.year(),
                    month: currentDate.month(),
                    day: currentDate.date()
                };
            }

            EndorsementService.loadEndorsementWithEffectiveDate(
                [
                    policyNumber,
                    loadEffectiveDate
                ],
                authHeader
            )
                .then((responseData) => {
                    const policyChange = viewModelService.create(
                        responseData,
                        'pc',
                        'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
                    );

                    policyChange.cancellationDate_Ext = latestPeriod.cancellationDate;
                    policyChange.policyType_Ext.value ??= responseData.policyType;
                    policyChange.policyChangeReason_Ext.value
                        ??= responseData.policyChangeReason;
                    policyChange.policyChangeSource_Ext.value
                        ??= responseData.policyChangeSource;
                    // need to map the producer code over
                    // producer code is assigned from the submission and cannot be changed
                    _.set(policyChange, 'baseData.producerCode_Ext.value', responseData.baseData.producerCode);
                    setinitialPolicyChange(policyChange);
                    setIsLoading(false);
                }).catch(() => {
                    errorModalBox({
                        title: commonMessages.unableToLoadDraftPolicy,
                        message: commonMessages.somethingWentWrong,
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFinish = useCallback((finishParams) => {
        const {
            wizardData: submissionVM,
        } = finishParams;
        const jobID = _.get(submissionVM.value, 'jobID');

        return history.push(`/change/${jobID}/summary`);
    }, [history]);

    const handleOnCancel = useCallback((cancelParams) => {
        const {
            wizardData,
            wizardSnapshot,
            currentStepIndex,
            steps: wizardSteps,
            param = {}
        } = cancelParams;
        const confirmationPageIndex = wizardSteps.length - 1;
        const { search, pathname: nextPath = '' } = param;
        const skipModal = ['knockoutpage', 'logout', 'summary'];
        let lastPathName = '';

        if (nextPath) {
            lastPathName = nextPath.replace(/\/?.*\//g, '');
        }

        const isStatusBound = _.get(wizardSnapshot, 'value.status') === 'Bound';

        if ((!_.isEmpty(nextPath) && nextPath.startsWith('/contactAgent'))
            || currentStepIndex === confirmationPageIndex
            || hasErrorOccurred
            || (isStatusBound && currentStepIndex > 0)
            || skipModal.includes(lastPathName)
            || viewOnly
        ) {
            return true;
        }

        return Promise.resolve(onPageCancel(wizardData.value, wizardSnapshot.value)
            .then((popupResult) => {
                if (!popupResult || popupResult === 'cancel') {
                    return _.noop();
                }

                setIsLoading(true);

                const jobID = _.get(wizardData.value, 'jobID');
                const policyNumber = _.get(wizardData.value, 'policyNumber');

                let redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/change/${jobID}/summary`;

                if (jobID === undefined) {
                    redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/policies/${policyNumber}/summary`;
                }

                if (nextPath?.includes('/quote-')) {
                    return history.push({
                        pathname: nextPath,
                        search
                    });
                }

                return history.push(redirectLobPath);
            }).catch(_.noop()));
    }, [hasErrorOccurred, history, onPageCancel, viewOnly]);

    const handlePrevious = useCallback((previousProps) => {
        const {
            wizardData, wizardSnapshot, updateWizardData, cloneData
        } = previousProps;

        return Promise.resolve(
            onPagePrevious(
                wizardData, wizardSnapshot,
                updateWizardData, cloneData, viewOnly
            )
        );
    }, [onPagePrevious, viewOnly]);

    const handleError = useCallback((error) => {
        const redirectPath = '/contact-for-assistance';
        const pathname = _.get(location, 'pathname');
        const state = {
            pathname: redirectPath,
            state: error,
            policyNumber: _.get(location, 'state.policyNumber')
        };

        loggerError(`MSA-EAWizard-Error: ${error}
            Pathname: ${pathname}`);
        setHasErrorOccurred(true);

        return <Redirect to={state} />;
    }, [location]);

    if (isLoading) {
        return (
            <E1PLoader loaded={!isLoading} />
        );
    }

    if (!initialPolicyChange) {
        return null;
    }

    const indexOfReviewPage = _.findIndex(steps, ({ path }) => path === '/change-summary');
    const { state: { jobNumber } } = location;

    return (
        <div className={styles.hoPolicyChangeWizardContainer}>
            <ErrorBoundary onError={handleError}>
                {/* Hidden field needed for GA on each wizard page */}
                <p id="sourceOfEntry" style={{ display: 'none' }}>
                    {_.get(
                        initialPolicyChange,
                        'baseData.quoteSource_Ext.sourceType.value.code',
                        'directentry'
                    )}
                </p>
                <Wizard
                    viewOnly={viewOnly}
                    initialSteps={steps}
                    wizardStepToFieldMapping={wizardStepToFieldMapping}
                    wizardTitle={title}
                    initialData={initialPolicyChange}
                    onCancel={handleOnCancel}
                    onFinish={handleFinish}
                    onPrevious={handlePrevious}
                    onPreviousModalProps={{
                        title: platformMessages.wantToJump,
                        message: platformMessages.wantToJumpMessage,
                        messageProps: {
                            ok: platformMessages.yes,
                            close: platformMessages.no
                        }
                    }}
                    isPageJumpEnabled={isPageJumpEnabled}
                    isAllPagesSubmittedFlow={!!jobNumber && !viewOnly}
                    furthestVisiblePageIndexOnStart={indexOfReviewPage}
                />
            </ErrorBoundary>
        </div>
    );
}

EAPolicyChangeWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            jobNumber: PropTypes.string,
            viewOnly: PropTypes.bool,
            latestPeriod: PropTypes.shape({
                effectiveDate: PropTypes.string,
                cancellationDate: PropTypes.string
            })
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired
};

export default withAuthenticationContext(EAPolicyChangeWizard);
