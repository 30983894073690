import { defineMessages } from 'react-intl';

export default defineMessages({
    date: {
        id: 'inlineLosses.propertyLosses.Date',
        defaultMessage: 'Date'
    },
    code: {
        id: 'inlineLosses.propertyLosses.Code',
        defaultMessage: 'Code'
    },
    description: {
        id: 'inlineLosses.propertyLosses.Description',
        defaultMessage: 'Description'
    },
    amount: {
        id: 'inlineLosses.propertyLosses.Amount',
        defaultMessage: 'Amount'
    },
    source: {
        id: 'inlineLosses.propertyLosses.Source',
        defaultMessage: 'Source'
    },
    cat: {
        id: 'inlineLosses.propertyLosses.CAT',
        defaultMessage: 'CAT'
    }
});