import { defineMessages } from 'react-intl';

export default defineMessages({
    reorderCreditModalTitle: {
        id: 'common.component.e1pReorderCreditModalComponent.Reorder Credit',
        defaultMessage: 'Reorder Credit'
    },
    reorderCreditModalConformationMessage: {
        id: 'common.component.e1pReorderCreditModalComponent.Credit will be reordered and evaluated for the renewal effective',
        defaultMessage: 'Credit will be reordered and evaluated for the renewal effective'
    },
    reorderCreditModalButtonLabel: {
        id: 'common.component.e1pReorderCreditModalComponent.REORDER CREDIT',
        defaultMessage: 'REORDER CREDIT'
    },
    reorderInProgressLabel: {
        id: 'common.component.e1pReorderCreditModalComponent.Reordering credit report',
        defaultMessage: 'Reordering credit report'
    }
});
