import { useCallback } from 'react';
import {
    get as _get
} from 'lodash';

import { eaCommonMessages } from 'e1p-platform-translations';
import { useTranslator } from '@jutro/locale';

function useMVRLicenseStatusUtil() {
    const translator = useTranslator();
    const checkNoHitStatus = useCallback(
        (mvrLicenseStatusRecords, drivers, setShowNoHitMessage, setNoHitMessage) => {
            const noHitString = (noHitDriversList) => {
                let driversString = '';
                if (noHitDriversList.length > 1) {
                    noHitDriversList.forEach((driver, index) => {
                        const name = translator(
                            eaCommonMessages.middleNameMVRNoHitStatus, { name: driver }
                        );
                        driversString = noHitDriversList.length - 1 !== index
                            ? `${driversString + name}`
                            : driversString;
                    });
                }

                return (
                    translator(eaCommonMessages.firstSectionMVRNoHitStatus)
                    + driversString
                    + translator(eaCommonMessages.lastNameMVRNoHitStatus,
                        { name: noHitDriversList[noHitDriversList.length - 1] })
                );
            };

            const noHitDriversList = [];
            mvrLicenseStatusRecords.forEach(
                (mvrLicenseStatusRecord) => {
                    const isMVRNoHit = mvrLicenseStatusRecord.mvrstatus === 'No-Hit';
                    const driverNode = drivers.find((driver) => {
                        return driver.integrationId === mvrLicenseStatusRecord.integrationId;
                    });
                    const name = _get(driverNode, 'person.displayName', '-');
                    if (isMVRNoHit) {
                        noHitDriversList.push(name);
                        setShowNoHitMessage(true);
                    }
                }
            );
            if (noHitDriversList.length > 0) {
                setNoHitMessage(noHitString(noHitDriversList));
            }
        }, [translator]
    );
    return {
        checkNoHitStatus
    };
}

export default useMVRLicenseStatusUtil;
