import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropdownMenuSeparator, DropdownMenuHeader, DropdownMenuButton } from '@jutro/components';
import { ContactService } from 'e1p-capability-gateway';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { useTranslator } from '@jutro/locale';
import _, { get as _get, set as _set, pullAt as _pullAt } from 'lodash';

import messages from './ChangeNamedInsuredComponent.messages';

import { DropdownMenuLink } from '@jutro/router';

/**
 * E1PAP1PC-9747 & 9755 This component allows a user
 *   to change a person Object on a NI, driver, etc. to
 *   a person contact that belongs to an account. Initially
 *   state is undefined and shows a loader, if call is not
 *   successful, state is set to an empty list and loader goes away.
 *   Succesful call loads list of all contacts.
 *
 *   It is important to notice the difference between using this
 *   menu to create new person VS editing fields on a NI. New person here
 *   will be wiping public ID and therefore making a new contact, whereas
 *   editing the fields on a NI will only be editing an existing contact
 *
 * @param {Object} props see prop types
 * @returns {JSX}
 */
function ChangeNamedInsuredComponent(props) {
    const {
        value: namedInsuredVM,
        path,
        onValueChange,
        accountNumber,
        authHeader,
        allowNewContact,
        excludedContacts,
        drivers,
        householdOccupants
    } = props;
    const translator = useTranslator();
    const [contacts, setContacts] = useState(undefined);
    const [contactsLoaded, setContactsLoaded] = useState(false);

    useEffect(() => {
        let isMounted = true;

        ContactService.getAccountContacts(accountNumber, authHeader)
            .then((contactDtos) => {
                if (!isMounted) {
                    return;
                }

                // returning an empty object when empty for some reason
                if (contactDtos.length > 0) {
                    setContacts(contactDtos);
                } else {
                    // empty list instead of empty object that is returned
                    setContacts([]);
                }
            })
            .catch(() => {
                // Not worried about call failing here
                //   If somehow this were reached, set list to empty
                if (isMounted) {
                    setContacts([]);

                }
            })
            .finally(() => {
                if (isMounted) {
                    setContactsLoaded(true)
                }
            });

        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContactClick = useCallback((value) => {
        // Remove the old NI from the occupants or drivers
        if (!_.isEmpty(householdOccupants)) {
            const niIndex = householdOccupants.findIndex(
                (occupant) => occupant.person.publicID === namedInsuredVM.person.publicID.value
            );

            _pullAt(householdOccupants, niIndex);
            onValueChange(householdOccupants, 'lobData.homeowners_EH.coverables.householdOccupants');
        }

        if (drivers) {
            const niIndex = drivers.findIndex(
                (occupant) => occupant.person.publicID === namedInsuredVM.person.publicID.value
            );

            _pullAt(drivers, niIndex);
            onValueChange(drivers, 'lobData.personalAuto_EA.coverables.drivers');
        }

        // Then change NI
        if (onValueChange) {
            // Remove Integration ID
            onValueChange(undefined, `${path}.integrationId`);
            onValueChange(value, `${path}.person`);
        }

    }, [drivers, householdOccupants, namedInsuredVM, onValueChange, path]);

    let existingContacts;

    if (contacts) {
        existingContacts = contacts
            // filter out company contacts
            .filter((contact) => contact.person)
            // filter out the NI from change to list (not done on BE)
            .filter((contact) => !excludedContacts.includes(contact.person.publicID))
            .map((contact) => (
                    <DropdownMenuLink
                        key={contact.person.publicID}
                        onClick={() => handleContactClick(contact.person)}
                    >
                        {contact.person.displayName}
                    </DropdownMenuLink>
                ));
    }

    return (
        <DropdownMenuButton
            id="dropdownMenuButton"
            buttonText={translator(messages.changeToButton)}
            icon="mi-expand-more"
            iconPosition="right"
        >
            {
                allowNewContact ? (
                    <DropdownMenuHeader
                        title={translator(messages.new)}
                    >
                        <DropdownMenuSeparator />
                        <DropdownMenuLink
                            onClick={() => {
                                // new contact, unset the relationship
                                _set(namedInsuredVM, 'relationshipToNI.value', undefined);
                                handleContactClick(
                                    // Replace person DTO with a new initial person DTO
                                    //   primaryAddress needs to be initialised
                                    //   so that it's not undefined
                                    {
                                        lastName: '',
                                        firstName: '',
                                        dateOfBirth: {
                                            year: undefined,
                                            month: undefined,
                                            day: undefined
                                        },
                                        partnerCode: _get(
                                            namedInsuredVM,
                                            'value.person.partnerCode'
                                        ),
                                        primaryAddress: (() => {
                                            // default new person address to prior pni
                                            const priorPniAddress = _get(
                                                namedInsuredVM,
                                                'value.person.primaryAddress'
                                            );

                                            // Needs new public ID to make it a new adress in the DB
                                            _set(priorPniAddress, 'publicID', undefined);

                                            return priorPniAddress;
                                        })()
                                    }
                                );
                            }}
                        >
                            {translator(messages.newPerson)}
                        </DropdownMenuLink>
                        <DropdownMenuSeparator />
                    </DropdownMenuHeader>
                ) : undefined
            }
            <DropdownMenuHeader
                title={translator(messages.existingPerson)}
            >
                <DropdownMenuSeparator />
                {
                    existingContacts || <E1PLoader loaded={contactsLoaded} />
                }
                <DropdownMenuSeparator />
            </DropdownMenuHeader>
        </DropdownMenuButton>
    );
}

ChangeNamedInsuredComponent.propTypes = {
    value: PropTypes.shape({}).isRequired,
    path: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    accountNumber: PropTypes.string.isRequired,
    allowNewContact: PropTypes.bool,
    excludedContacts: PropTypes.arrayOf(PropTypes.string),
    drivers: PropTypes.arrayOf(PropTypes.shape({})),
    householdOccupants: PropTypes.arrayOf(PropTypes.shape({})),
};

ChangeNamedInsuredComponent.defaultProps = {
    allowNewContact: true,
    excludedContacts: [],
    drivers: undefined,
    householdOccupants: []
};
export default ChangeNamedInsuredComponent;
