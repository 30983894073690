import React, {
    useState,
    useCallback,
    useEffect
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ActivitiesService } from 'e1p-capability-gateway';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import E1PReassignActivity from '../E1PReassignActivityComponent/E1PReassignActivity';
import metadata from './E1PActivityComponent.metadata.json5';
import styles from './E1PActivityComponent.module.scss';


import { Button } from '@jutro/legacy/components';


function E1PActivityComponent(props) {
    const {
        activityVM,
        title,
        authHeader,
        viewModelService,
        activityReassigned,
        isOpen,
        onResolve
    } = props;
    const [activityClonedVM, updateActivityClonedVM] = useState(viewModelService.clone(activityVM));
    const [isLoading, setLoading] = useState(false);
    const {
        onValidate
    } = useValidation('E1PActivityComponent');

    useEffect(() => {
        const activityPublicId = _.get(activityVM, 'value.publicID');

        if (activityPublicId) {
            setLoading(true);
            ActivitiesService.getAssignableUsersForActivity(activityPublicId, authHeader)
                .then((assignabaleUsers) => {
                    _.set(activityVM, 'value.assignableUsers', assignabaleUsers);
                    updateActivityClonedVM(activityVM);
                }).catch(() => {
                    // if due to some reason the API call fails,
                    // assignable users are already coming as empty array in activityVM, so UI wont break
                }).finally(() => {
                    setLoading(false);
                });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAssignedActivity = () => {
        const reAssignActivity = (activity) => {
            _.merge(activityVM.value, activity);
            updateActivityClonedVM(activity);
        };

        return (
            <E1PReassignActivity
                activity={activityVM.value}
                onUpdateActivity={reAssignActivity}
                authHeader={authHeader}
                activityReassigned={activityReassigned}
            />
        );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        reassign: {
            content: getAssignedActivity()
        },
        activityPageLoader: {
            loaded: !isLoading
        },
        activitiesDetailGrid: {
            visible: !isLoading
        },
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            getAssignedActivity
        },
        resolveComponentMap: {
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                activityClonedVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, activityClonedVM]
    );

    return (

        <ModalNext isOpen={isOpen}>
            <ModalHeader title={title} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={activityClonedVM}
                    callbackMap={resolvers.resolveCallbackMap}
                    overrideProps={overrideProps}
                    resolveValue={readValue}
                    classNameMap={resolvers.resolveClassNameMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onResolve}>
                    {'Close'}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

E1PActivityComponent.propTypes = {
    activityVM: PropTypes.shape({
        value: PropTypes.shape({
            notes: PropTypes.arrayOf(PropTypes.shape({}))
        })
    }),
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
        clone: PropTypes.func
    }).isRequired,
    activityReassigned: PropTypes.func.isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    onResolve: PropTypes.func,
    isOpen: PropTypes.bool,
};
E1PActivityComponent.defaultProps = {
    activityVM: {},
    isOpen: undefined,
    onResolve: undefined
};
export default E1PActivityComponent;
