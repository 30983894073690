import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import metadata from './EUAddressComponent.metadata.json5';

function EUAddressComponent(props) {
    const {
        model: addressVM,
        labelPosition,
        showOptional,
        onValidate,
        onModelChange,
        viewOnlyMode,
        showErrors
    } = props;

    useEffect(() => {
        if (_.isUndefined(addressVM.value)) {
            addressVM.value = {};
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleValueChange = useCallback(
        (newVal, path) => {
            // If path is postalCode from AddressComponent, assign it to zipCode as well
            if (path === 'postalCode') {
                _.set(addressVM, 'zipCode.value', newVal);
            }

            _.set(addressVM, `${path}`, newVal);

            // If model value is changed for addressVM from Address standrdized modal,
            // assign it to postalCode to auto fill the new value
            if (path === 'value') {
                _.set(addressVM, 'postalCode', _.get(newVal, 'postalCode'));
            }

            onModelChange();
        },
        [addressVM, onModelChange]
    );


    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            readOnly: viewOnlyMode,
            showRequired: true,
            showErrors,
            autoComplete: false
        },
        euAddressComponent: {
            addressVM,
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate,
            onAddressChange: (value, path) => handleValueChange(value, path),
            viewOnlyMode,
            showErrors
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={addressVM}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            onValueChange={handleValueChange}
        />
    );
}

EUAddressComponent.propTypes = {
    model: PropTypes.shape({}).isRequired,
    labelPosition: PropTypes.string,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    onModelChange: PropTypes.func.isRequired,
    viewOnlyMode: PropTypes.bool,
    showErrors: PropTypes.bool
};
EUAddressComponent.defaultProps = {
    labelPosition: 'top',
    showOptional: false,
    viewOnlyMode: false,
    showErrors: false
};
export default EUAddressComponent;
