import { defineMessages } from 'react-intl';

export default defineMessages({
    ratingYourPolicyMessage: {
        id: 'quoteandbind.eh.ho3.views.underwriting.Please wait while we rate your policy...',
        defaultMessage: 'Please wait while we rate your policy...'
    },
    loadingNextPageMessage: {
        id: 'quoteandbind.eh.ho3.views.underwriting.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    selfDeclaredTitle: {
        id: 'quoteandbind.common.eh-react.underwriting.Self Declared',
        defaultMessage: 'Self Declared'
    },
    autoLossesTitle: {
        id: 'quoteandbind.eh.views.underwriting.Auto Losses',
        defaultMessage: 'Auto Losses'
    },
    propertyLossesTitle: {
        id: 'quoteandbind.eh.views.underwriting.PropertyLosses Losses',
        defaultMessage: 'Property Losses'
    },
    removeLossTitle: {
        id: 'quoteandbind.eh.views.underwriting.Remove Loss?',
        defaultMessage: 'Remove Loss?'
    },
    removeLossDescription: {
        id: 'quoteandbind.eh.views.underwriting.remove-loss.message',
        defaultMessage: 'The selected loss will be removed.'
    },
    confirmOk: {
        id: 'quoteandbind.eh.views.underwriting.Yes',
        defaultMessage: 'Yes'
    },
    cancel: {
        id: 'quoteandbind.eh.views.underwriting.No',
        defaultMessage: 'No'
    },
    noLossAdded: {
        id: 'quoteandbind.eh.views.underwriting.No Losses Added',
        defaultMessage: 'No losses added. Please add one.'
    },
    anyLossToReport: {
        id: 'quoteandbind.common.eh-react.underwriting.Source.Any loss to report in last 5 years?',
        defaultMessage: 'Has the residence had any losses within the last five years that insured will like to report?'
    },
    date: {
        id: 'quoteandbind.common.eh-react.underwriting.Date',
        defaultMessage: 'Date'
    },
    description: {
        id: 'quoteandbind.common.eh-react.underwriting.Description',
        defaultMessage: 'Description'
    },
    amount: {
        id: 'quoteandbind.common.eh-react.underwriting.Amount',
        defaultMessage: 'Amount'
    },
    source: {
        id: 'quoteandbind.common.eh-react.underwriting.Source',
        defaultMessage: 'Source'
    },
    usedInRating: {
        id: 'quoteandbind.common.eh-react.underwriting.UsedInRating',
        defaultMessage: 'Used In Rating'
    },
    autoLosses: {
        id: 'quoteandbind.common.eh-react.underwriting.Auto Comp Losses',
        defaultMessage: 'Auto Comp Losses'
    },
    atFaultAutoLossesTitle: {
        id: 'quoteandbind.common.eh-react.underwriting.At Fault Auto Losses',
        defaultMessage: 'At Fault Auto Losses'
    }
});
