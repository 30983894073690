import { useMemo, useCallback } from 'react';

import { get } from 'lodash';

/**
 * @param {Object} transactionVM
 * @returns {*}
 *
 * This util is being used in EA line to combine all the modifers
 * from different locations into single array.
 * i.e vehicles, drivers and from line
 */
function useModifierUtil (transactionVM) {
    const structuredModifiers = useMemo(() => {
        const vehicleModifiers = [];
        const driverModifiers = [];

        get(
            transactionVM,
            'lobData.personalAuto_EA.coverables.vehicles.children',
            []
        ).forEach((vehicle) => {
            get(vehicle, 'modifiers.value', []).forEach((modifier) => {
                if (
                    modifier.applied &&
                    !vehicleModifiers.some((vehicleModifier) => {
                        return (
                            modifier.patternCode ===
                                vehicleModifier.patternCode && vehicleModifier.applied
                        );
                    })
                ) {
                    vehicleModifiers.push(modifier);
                }
            });
        });

        get(
            transactionVM,
            'lobData.personalAuto_EA.coverables.drivers.children',
            []
        ).forEach((driver) => {
            get(driver, 'modifiers.value', []).forEach((modifier) => {
                if (
                    modifier.applied &&
                    !driverModifiers.some((driverModifier) => {
                        return (
                            modifier.patternCode ===
                                driverModifier.patternCode &&
                            driverModifier.applied
                        );
                    })
                ) {
                    driverModifiers.push(modifier);
                }
            });
        });

        return get(
            transactionVM,
            'lobData.personalAuto_EA.modifiers.value',
            []
        ).concat(vehicleModifiers, driverModifiers);
    }, [transactionVM]);

    const getStateVehicleFees = useCallback(() => {
        const stateFees = {
            IL: ['EA_LawEnforcementFee']
        };
        const vehicleModifiers = [];
        const policyState = get(
            transactionVM,
            'baseData.policyAddress.state.value.code',
            get(transactionVM, 'policyAddress.state.value.code')
        );
        get(
            transactionVM,
            'lobData.personalAuto_EA.coverables.vehicles.children',
            []
        ).forEach((vehicle) => {
            get(vehicle, 'modifiers.value', []).forEach((modifier) => {
                if (
                    modifier.applied &&
                    modifier.modifierType === 'fee' &&
                    stateFees[policyState].includes(modifier.patternCode)
                ) {
                    vehicleModifiers.push({
                        modifier: modifier,
                        vehicle: vehicle.value,
                    });
                }
            });
        });
        return vehicleModifiers;
    }, [transactionVM]);

    /**
     * Function for handling the state wise line level fees 
     * These fees are already totaled for all drivers / vehicles
     * Returns fees associated with policy state
     */
    const getStateCommonFees = useCallback(() => {
        const stateFees = {
            // Financial Filing Fee for NH & VA
            NH: ['EA_FinancialFilingFee'],
            VA: ['EA_FinancialFilingFee']
        };
        const commonModifiers = [];
        // Get Policy State
        const policyState = get(
            transactionVM,
            'baseData.policyAddress.state.value.code',
            get(transactionVM, 'policyAddress.state.value.code')
        );
        get(
            transactionVM,
            'lobData.personalAuto_EA.fees.value',
            []
        ).forEach((fee) => {
            if (stateFees[policyState].includes(fee.name)) {
                commonModifiers.push({
                    modifier: {
                        premium: fee.amountDTO,
                        productSpecificName: fee.productSpecificName,
                        patternCode: fee.name
                    }
                });
            }
        });
        return commonModifiers;
    }, [transactionVM]);

    
    /**
     * Function for handling the state wise line level fees 
     * These fees are already totaled for all drivers / vehicles
     * Returns fees associated with policy state
     */
    const getStatePACSurcharge = useCallback(() => {
        const stateFees = {
            // PAC surcharge for VA
            VA: ['EA_BodilyInjury']
        };
        
        const stateFeeDescription = {
            // PAC surcharge description for VA
            // We have to show only PAC and ignore all others and there is no identifier associated
            VA: ["Bodily Injury Liability - PAC"]
        };
        const commonModifiers = [];
        // Get Policy State
        const policyState = get(
            transactionVM,
            'baseData.policyAddress.state.value.code',
            get(transactionVM, 'policyAddress.state.value.code')
        );
        const selectedOfferings = get(
            transactionVM,
            'lobData.personalAuto_EA.offerings.value',
            []
        ).find((lobOffering) => { 
            return get(transactionVM, 'quoteData.offeredQuotes.value', []).find(
                (quoteOffering) => quoteOffering.selected
            )?.branchCode === lobOffering.branchCode
        });

        get(selectedOfferings, 'coverages.lineCoverages', []).forEach((coverage) => {
            if (stateFees[policyState] && stateFees[policyState].includes(coverage.codeIdentifier)) {
                get(
                    coverage,
                    'subTotalPremiumDTOs',
                    []
                ).forEach((subTotalPremiumDTO) => {
                    if(stateFeeDescription[policyState].includes(subTotalPremiumDTO.description)) {
                        commonModifiers.push({
                            modifier: {
                                premium: subTotalPremiumDTO.subTotalPremiumAmount,
                                productSpecificName: subTotalPremiumDTO.description,
                                patternCode: coverage.codeIdentifier
                            }
                        });
                    }
                });
            }
        });
        return commonModifiers;
    }, [transactionVM]);

    return {
        structuredModifiers,
        getStateVehicleFees,
        getStateCommonFees,
        getStatePACSurcharge,
    };
}

export default useModifierUtil;
