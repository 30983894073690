import { defineMessages } from 'react-intl';

export default defineMessages({
    deliverDocuments: {
        id: 'e1p.components.E1PDeliverDocumentsIndComponent.Deliver Documents',
        defaultMessage: 'Deliver Documents'
    },
    doNotDeliverDocsTitle: {
        id: 'e1p.components.E1PDeliverDocumentsIndComponent.Do Not Deliver Documents?',
        defaultMessage: 'Do Not Deliver Documents?'
    },
    doNotDeliverDocsMessage: {
        id: 'e1p.components.E1PDeliverDocumentsIndComponent.Documents will not be delivered to the agent, insured, or any Third-Party Interest.',
        defaultMessage: 'Documents will not be delivered to the agent, insured, or any Third-Party Interest.'
    }
});
