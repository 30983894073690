import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SearchService } from 'gw-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { withRouter } from 'react-router-dom';

import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import metadata from './SearchResults.metadata.json5';

import styles from './SearchResults.module.scss';
import messages from './SearchResults.messages';

function SearchResults(props) {
    const {
        match: {
            params: { searchText }
        },
        param,
        title,
        noResultsMessage,
        history
    } = props;
    const translator = useTranslator();
    const [searchResult, setSearchResult] = useState({
        accounts: [],
        policies: [],
        jobs: []
    });
    const [loading, setLoading] = useState(true);

    const { authHeader } = useAuthentication();

    const populateAccountResults = useCallback((data) => {
        const accounts = [];

        if (data.accounts.length) {
            data.accounts.forEach((account) => {
                if (account !== null) {
                    const accountInfo = {};

                    accountInfo.accountNumber = account.accountNumber;
                    accountInfo.accountName = account.accountHolder;

                    const {
                        addressLine1, city, state, postalCode
                    } = account.accountHolderAddress;
                    const addressLine2Data = account.accountHolderAddress.addressLine2
                        ? account.accountHolderAddress.addressLine2
                        : '';

                    accountInfo.addressLine = `${addressLine1}, ${addressLine2Data}`;
                    accountInfo.addressOthers = `${city}, ${state}${postalCode}`;
                    accounts.push(accountInfo);
                }
            });
        }

        return accounts;
    }, []);

    const populatePolicyResults = useCallback((data) => {
        const policies = [];

        if (data.policies.length) {
            data.policies.forEach((policy) => {
                if (policy.policyNumber) {
                    const policyInfo = {};

                    policyInfo.policyNumber = policy.policyNumber;
                    policyInfo.accountNumber = policy.accountNumber;

                    if (policy.accountHolder.subtype === 'Company') {
                        policyInfo.accountName = policy.accountHolder.contactName;
                    } else if (policy.accountHolder.subtype === 'Person') {
                        policyInfo.accountName = `${policy.accountHolder.firstName} ${policy.accountHolder.lastName}`;
                    }

                    const {
                        addressLine1,
                        city,
                        state,
                        postalCode
                    } = policy.accountHolder.primaryAddress;
                    const addressLine2Data = policy.accountHolder.primaryAddress.addressLine2
                        ? policy.accountHolder.primaryAddress.addressLine2
                        : '';

                    policyInfo.addressLine = `${addressLine1}, ${addressLine2Data}`;
                    policyInfo.addressOthers = `${city}, ${state}${postalCode}`;
                    policies.push(policyInfo);
                }
            });
        }

        return policies;
    }, []);

    const populateJobResults = useCallback((data) => {
        const jobs = [];

        if (data.jobs_Ext.length) {
            data.jobs_Ext.forEach((job) => {
                if (job.jobNumber) {
                    const jobInfo = {};

                    jobInfo.jobNumber = job.jobNumber;
                    jobInfo.accountNumber = job.accountNumber;
                    jobInfo.accountName = job.accountHolderName;

                    if (job.policyNumber) {
                        jobInfo.policyNumber = job.policyNumber;
                    }

                    jobs.push(jobInfo);
                }
            });
        }

        return jobs;
    }, []);

    const redirectAfterSearch = useCallback((account, policy, job) => {
        if (account.length === 1) {
            return history.push(`/accounts/${account[0].accountNumber}/summary`);
        }

        if (policy.length === 1) {
            return history.push(`/policies/${policy[0].policyNumber}/summary`);
        }

        if (job.length === 1) {
            if (job[0].policyNumber) {
                return history.push(`/policies/${job[0].policyNumber}/summary`);
            }

            return history.push(`/quotes/${job[0].jobNumber}/summary`);
        }

        return false;
    }, [history]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            const searchObj = {
                previousSearchParam: '',
                searchParam: param || searchText
            };
            const data = await SearchService.search(searchObj, authHeader);

            setLoading(false);

            const accountData = populateAccountResults(data);
            const policyData = populatePolicyResults(data);
            const jobData = populateJobResults(data);

            setSearchResult(() => ({
                    accounts: accountData,
                    policies: policyData,
                    jobs: jobData
                }));
            redirectAfterSearch(accountData, policyData, jobData);
        };

        fetchData();
    }, [searchText, populateAccountResults, populatePolicyResults,
        authHeader, param, populateJobResults, redirectAfterSearch]);

    const accountDetailsOverrides = useMemo(() => {
        const overrides = searchResult.accounts.map((account, index) => ({
                [`accountAccountNameLink${index}`]: {
                    to: `/accounts/${account.accountNumber}/summary`
                },
                [`accountNumber${index}`]: {
                    content: `# ${account.accountNumber}`
                },
                [`accountName${index}`]: {
                    content: `${account.accountName}`
                },
                [`accountNumberLink${index}`]: {
                    to: `/accounts/${account.accountNumber}/summary`
                },
                [`accountAddressLine${index}`]: {
                    content: `${account.addressLine}`
                },
                [`accountAddressOthers${index}`]: {
                    content: `${account.addressOthers}`
                },
                [`accountTableSep${index}`]: {
                    visible: !((searchResult.accounts.length - 1) === index)
                },
            }));

        return Object.assign({}, ...overrides);
    }, [searchResult.accounts]);

    const policyDetailsOverrides = useMemo(() => {
        const overrides = searchResult.policies.map((policy, index) => ({
                [`policyNumberLink${index}`]: {
                    to: `/policies/${policy.policyNumber}/summary`
                },
                [`policyNumber${index}`]: {
                    content: `# ${policy.policyNumber}`
                },
                [`accountName${index}`]: {
                    content: `${policy.accountName}`
                },
                [`policyAccountNameLink${index}`]: {
                    to: `/accounts/${policy.accountNumber}/summary`
                },
                [`policyAddressLine${index}`]: {
                    content: `${policy.addressLine}`
                },
                [`policyAddressOthers${index}`]: {
                    content: `${policy.addressOthers}`
                },
                [`policyTableSep${index}`]: {
                    visible: !((searchResult.policies.length - 1) === index)
                },
            }));

        return Object.assign({}, ...overrides);
    }, [searchResult.policies]);

    const jobDetailsOverrides = useMemo(() => {
        const overrides = searchResult.jobs.map((job, index) => ({
                [`jobNumberLink${index}`]: {
                    to: `/quotes/${job.jobNumber}/summary`
                },
                [`jobNumber${index}`]: {
                    content: `# ${job.jobNumber}`
                },
                [`jobAccountName${index}`]: {
                    content: `${job.accountName}`
                },
                [`jobAccountNameLink${index}`]: {
                    to: `/accounts/${job.accountNumber}/summary`
                },
                [`policyAddressLine${index}`]: {
                    content: `${job.addressLine}`
                },
                [`policyAddressOthers${index}`]: {
                    content: `${job.addressOthers}`
                },
                [`jobPolicyNumberLink${index}`]: {
                    visible: !!(job.policyNumber),
                    to: `/policies/${job.policyNumber}/summary`
                },
                [`jobPolicyNumber${index}`]: {
                    content: `# ${job.policyNumber}`
                },
                [`jobTableSep${index}`]: {
                    visible: !((searchResult.jobs.length - 1) === index)
                },
            }));

        return Object.assign({}, ...overrides);
    }, [searchResult.jobs]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        accountResults: {
            visible: searchResult.accounts.length > 1 && !loading
        },
        searchResultsTitle: {
            content: title || translator(messages.searchResults)
        },
        policyResults: {
            visible: searchResult.policies.length > 1 && !loading
        },
        jobResults: {
            visible: searchResult.jobs.length > 1 && !loading
        },
        searchingText: {
            visible: loading
        },
        loadingIndicator: {
            loaded: !loading
        },
        noResultsMessage: {
            visible:
                (!!searchText || param)
                && !loading
                && !searchResult.accounts.length
                && !searchResult.policies.length
                && !searchResult.jobs.length,
            value:
                noResultsMessage
                || translator(messages.noResultsFoundFor, {
                    query: searchText || param
                })
        },
        accountHeader: {
            content: translator(
                messages.accountResults,
                { accountResultsNumber: searchResult.accounts.length }
            )
        },
        policyHeader: {
            content: translator(
                messages.policyResults,
                { policyResultsNumber: searchResult.policies.length }
            )
        },
        jobHeader: {
            content: translator(
                messages.jobResults,
                { jobResultsNumber: searchResult.jobs.length }
            )
        },
        ...accountDetailsOverrides,
        ...policyDetailsOverrides,
        ...jobDetailsOverrides
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, searchResult, id, path, overrideProps),
        [overrideProps, searchResult]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={searchResult}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

SearchResults.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            searchText: PropTypes.string
        })
    }).isRequired,
    param: PropTypes.string,
    title: PropTypes.string,
    noResultsMessage: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

SearchResults.defaultProps = {
    param: '',
    title: '',
    noResultsMessage: ''
};

export default withRouter(SearchResults);
