import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, set, isUndefined, pullAt, isEmpty
} from 'lodash';
import { Grid } from '@jutro/layout';
import { Icon, useModal } from '@jutro/components';
import * as PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { commonMessages } from 'e1p-platform-translations';
import EUAddressComponent from '../EUAddressComponent/EUAddressComponent';
import messages from './EURentalDwellings.messages';
import styles from '../../E1PEUUnderlyingPolicyComponent.module.scss';
import metadata from './EURentalDwellings.metadata.json5';


import { InputField, DropdownSelectField } from '@jutro/legacy/components';


function EURentalDwellings(props) {
    const modalApi = useModal();
    const {
        model: rentalDwellingExposuresVM,
        onModelChange,
        onValidate,
        labelPosition,
        visible,
        viewOnlyMode,
        id,
        showErrors,
        parentIndex
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const [openRentalDwellings, setOpenRentalDwellings] = useState([]);
    const translator = useTranslator();

    const {
        isComponentValid,
        onValidate: setComponentValidation,
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [rentalDwellingExposuresVM, id, onValidate, isComponentValid]);


    useEffect(() => {
        if (isUndefined(rentalDwellingExposuresVM.value)) {
            rentalDwellingExposuresVM.value = [];
        }

        if (!viewOnlyMode) {
            onModelChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const rentalDwellingExposureElement = document.getElementById(`rentalDwellingExposureTab${parentIndex}`);

        if (rentalDwellingExposureElement && rentalDwellingExposuresVM.length > 0) {
            const lastIndex = rentalDwellingExposuresVM.length - 1;
            // const rentalDwellingElement = document.getElementById(`rentalDwelling${lastIndex}`);
            const rentalDwellingElement = rentalDwellingExposureElement.querySelector(`div[id="rentalDwelling${lastIndex}rentalDwellings"]`);
            const toggleNodes = rentalDwellingElement.getElementsByClassName('jut__ToggleField__toggle ');

            if (toggleNodes && toggleNodes[0]
                && isEmpty(get(rentalDwellingExposuresVM, `value[${lastIndex}].isSwimmingPool`))
            ) {
                const toggleNodeAria = toggleNodes[0].attributes.getNamedItem('aria-describedby');

                if (toggleNodeAria?.value
                    && rentalDwellingElement.querySelector(`[aria-describedby="${toggleNodeAria.value}"]`).children[0].children[0].focus !== undefined
                ) {
                    rentalDwellingElement.querySelector(`[aria-describedby="${toggleNodeAria.value}"]`).children[0].children[0].focus();
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rentalDwellingExposuresVM.length]);

    const openAddedDwelling = useCallback(() => {
        setOpenRentalDwellings([`rentalDwelling${rentalDwellingExposuresVM.value.length - 1}rentalDwellings`]);
    }, [rentalDwellingExposuresVM])

    const addDwelling = useCallback(
        () => {
            const { _xCenter, _dtoName } = rentalDwellingExposuresVM;
            const newRentalDwellingVM = viewModelService.create({ isManuallyAddedInd: true }, _xCenter, _dtoName);

            rentalDwellingExposuresVM.pushElement(newRentalDwellingVM);
            onModelChange();
            openAddedDwelling();
        },
        [rentalDwellingExposuresVM, viewModelService, onModelChange, openAddedDwelling]
    );

    const removeDwelling = useCallback(
        (dwellingToRemove, index) => {
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: messages.removeRentalDwelling,
                message: translator(commonMessages.removeItemMessage, { itemToRemove: 'Rental Dwelling' }),
                confirmButtonText: translator(commonMessages.removeItemButtonText, { itemToRemove: 'RENTAL DWELLING' }),
                cancelButtonText: commonMessages.cancel
            }).then(() => {
                pullAt(rentalDwellingExposuresVM.value, index);
                onModelChange();
            }, () => { });
        },
        [modalApi, onModelChange, rentalDwellingExposuresVM.value, translator]
    );

    const renderRentalDwellingHeader = useCallback(
        (rentalDwelling, index) => {
            const lineType = get(rentalDwelling, 'lineType');
            const addressLine1 = `${get(rentalDwelling, 'riskLocationAddress.addressLine1.value', '')}`;
            const addressLine2 = `${get(rentalDwelling, 'riskLocationAddress.addressLine2.value', '')}`;
            const city = `${get(rentalDwelling, 'riskLocationAddress.city.value', '')}`;
            const state = `${get(rentalDwelling, 'riskLocationAddress.state.value.code', '')}`;
            const zipCode = `${get(rentalDwelling, 'riskLocationAddress.zipCode.value', '')}`;
            // Show the address header label only when all relevant address info has been provided
            const dwellingAddress = isEmpty(addressLine1) || isEmpty(city)
                || isEmpty(state) || isEmpty(zipCode) ? '-'
                : `${addressLine1} ${addressLine2}, ${city}, ${state} ${zipCode}`;

            return (isOpen) => (
                <div className={styles.accordionHeaders}>
                    <Grid columns={['1fr', '3fr', '0.5fr', '0.5fr']} gap="small">
                        <DropdownSelectField
                            readOnly
                            id={`lineTypeAccordionHeader${index}`}
                            availableValues={lineType.aspects.availableValues.map(
                                (item) => ({
                                    code: item.code,
                                    name: {
                                        id: item.name,
                                    },
                                })
                            )}
                            value={lineType.value}
                            placeholder=""
                        />
                        <InputField
                            readOnly
                            autoComplete={false}
                            id={`addressAccordionHeader${index}`}
                            value={dwellingAddress}
                        />

                        {(!viewOnlyMode)
                            ? (
                                <React.Fragment key={`editDeleteAccordionHeader${index}`}>
                                    <Icon
                                        id={`editAccordionHeader${index}`}
                                        icon={isOpen ? 'mi-unfold-less' : 'mi-edit'}
                                        size="large"
                                        className="iconAsButton"
                                    />
                                    <Icon
                                        id={`deleteAccordionHeader${index}`}
                                        icon="mi-delete"
                                        size="large"
                                        className="iconAsButton"
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            removeDwelling(rentalDwelling, index);
                                        }}
                                    />
                                </React.Fragment>
                            ) : null
                        }

                    </Grid>
                </div>
            );
        },
        [removeDwelling, viewOnlyMode]
    );

    const generateOverrides = useCallback(
        () => {
            const overrides = [];

            rentalDwellingExposuresVM.children.forEach((rentalDwelling, index) => {
                overrides.push({
                    [`rentalDwelling${index}`]: {
                        renderHeader: renderRentalDwellingHeader(rentalDwelling, index),
                        errorState: !rentalDwelling.aspects.subtreeValid,
                        showErrors
                    },
                    [`riskAddress${index}`]: {
                        model: rentalDwelling.riskLocationAddress,
                        onModelChange,
                        onValidate: setComponentValidation,
                        showErrors,
                        viewOnlyMode
                    }
                });
            });

            return Object.assign({}, ...overrides);
        },
        [
            rentalDwellingExposuresVM.children,
            renderRentalDwellingHeader,
            showErrors,
            onModelChange,
            setComponentValidation,
            viewOnlyMode
        ]
    );

    const overrideProps = {
        '@field': {
            className: 'allFields',
            labelPosition,
            visible,
            showRequired: true,
            readOnly: viewOnlyMode,
            showErrors,
            autoComplete: false
        },
        rentalDwellings: {
            showErrors,
            accordionStates: openRentalDwellings
        },
        emptyRentalDwellings: {
            visible: isEmpty(rentalDwellingExposuresVM.value)
        },
        addEURentalDwelling: {
            visible: !viewOnlyMode
        },
        ...generateOverrides()
    };

    const writeValue = useCallback(
        (newVal, localPath) => {
            set(rentalDwellingExposuresVM, `${localPath}.value`, newVal);
            onModelChange();
        },
        [onModelChange, rentalDwellingExposuresVM]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            addDwelling
        },
        resolveComponentMap: {
            euaddresscomponent: EUAddressComponent
        }
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rentalDwellingExposuresVM}
                overrideProps={overrideProps}
                onValidationChange={setComponentValidation}
                onValueChange={writeValue}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </div>
    );
}

EURentalDwellings.propTypes = {
    model: PropTypes.shape({}).isRequired,
    onModelChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    labelPosition: PropTypes.string,
    visible: PropTypes.bool,
    viewOnlyMode: PropTypes.bool,
    id: PropTypes.string,
    showErrors: PropTypes.bool,
    parentIndex: PropTypes.number.isRequired
};
EURentalDwellings.defaultProps = {
    labelPosition: 'top',
    visible: true,
    viewOnlyMode: false,
    id: '',
    showErrors: false
};
export default EURentalDwellings;
