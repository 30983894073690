import { defineMessages } from 'react-intl';

export default defineMessages({
    selectedValue: {
        id: 'endorsement.eh.caluses.policycommon.The value must be between',
        defaultMessage: 'The value must be between {minValue} and {maxValue}'
    },
    minValue: {
        id: 'endorsement.eh.clauses.The minimum allowed value is',
        defaultMessage: 'The minimum allowed value is {minValue}'
    },
    maxValue: {
        id: 'endorsement.eh.caluses.The maximum allowed value is',
        defaultMessage: 'The maximum allowed value is {maxValue}'
    },
    syncingCoverage: {
        id: 'endorsement.eh.caluses.Syncing coverage dependencies…',
        defaultMessage: 'Syncing coverage dependencies…'
    },
    close: {
        id: 'endorsement.eh.caluses.CLOSE',
        defaultMessage: 'CLOSE'
    },
    editCoverages: {
        id: 'endorsement.eh.caluses.EDIT COVERAGES',
        defaultMessage: 'EDIT COVERAGES'
    },
    editSchedules: {
        id: 'endorsement.eh.caluses.EDIT SCHEDULES',
        defaultMessage: 'EDIT SCHEDULES'
    },
    viewCoverages: {
        id: 'endorsement.eh.caluses.VIEW COVERAGES',
        defaultMessage: 'VIEW COVERAGES'
    },
    viewSchedules: {
        id: 'endorsement.eh.caluses.VIEW SCHEDULES',
        defaultMessage: 'VIEW SCHEDULES'
    }
});
