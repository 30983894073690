/* eslint-disable no-prototype-builtins */
import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AccountSearchUtil } from 'e1p-capability-gateway';
import { useModal } from '@jutro/components';
import {
    AccountsFoundComponent
} from 'e1p-capability-policyjob-react';
import {
    e1pDateUtil
} from 'e1p-capability-hooks';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import appConfig from 'app-config';
import styles from './AccountSearchComponent.module.scss';
import metadata from './AccountSearchComponent.metadata.json5';
import './AccountSearchComponent.messages';

function AccountSearchComponent(props) {
    const modalApi = useModal();
    const {
        value: baseDataVM,
        labelPosition,
        showOptional,
        path,
        onValueChange,
        viewModelService,
        setIsAnExistingAccount,
        setPNI,
        authHeader,
        onMarkAccountSearchCompleted,
        searchButtonErrorFunc,
        showSearchButtonErrors,
        setShowSearchButtonErrors
    } = props;
    const { operatingCompanyConfig } = appConfig;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const currentDate = e1pDateUtil.getFormattedCurrentDate();

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const showAccountsModal = useCallback((vm) => {
        const componentProps = {
            iconClassType: false,
            showCloseBtn: false,
            showCancelBtn: true,
            accountsVM: vm
        };

        return modalApi.showModal(<AccountsFoundComponent {...componentProps} />);
    }, [modalApi]);

    const onSearchAccounts = useCallback(async () => {
        // AccountSearchCriteriaDTO does not have a dob field so currently dob is not used in search
        // firstName, city, and postalCode must be defined
        const connectConfigObject = operatingCompanyConfig[opCo].experiences;
        let partnerCode;

        for (const key in connectConfigObject) {
            if (connectConfigObject.hasOwnProperty(key)) {
                if (connectConfigObject[key].experienceId === baseDataVM?.experienceID_Ext?.value) {
                    partnerCode = key;

                    if (connectConfigObject[key].retired && connectConfigObject[key].mergedPartnerCode) {
                        partnerCode = connectConfigObject[key].mergedPartnerCode;
                    }
                }
            }
        }

        if (baseDataVM.accountHolder?.lastName?.value
            && baseDataVM.accountHolder?.dateOfBirth?.value
            && baseDataVM.accountHolder.primaryAddress?.state?.value?.code
            && baseDataVM.producerCode_Ext?.value) {
            const response = await AccountSearchUtil.lookupAccount(
                baseDataVM.value.accountHolder,
                baseDataVM.value.producerCode_Ext,
                partnerCode,
                authHeader, viewModelService
            );

            onMarkAccountSearchCompleted();

            if (setShowSearchButtonErrors) {setShowSearchButtonErrors(false);}

            showAccountsModal(response).then(({ chosenAccount }) => {
                // If accountHolder is not a property that means they chose to create new
                if (chosenAccount.accountHolder) {
                    baseDataVM.accountHolder.value = chosenAccount.accountHolder.person;
                    baseDataVM.accountNumber.value = chosenAccount.accountNumber;
                    handleValueChange(baseDataVM.accountHolder.value, `${path}.value`);
                    handleValueChange(baseDataVM.accountNumber.value, `${path}.value`);
                    setPNI(baseDataVM.accountHolder.value);
                    setIsAnExistingAccount(true);
                }
            }).catch();
        } else if (searchButtonErrorFunc) {
            searchButtonErrorFunc();
        }
    }, [operatingCompanyConfig, opCo, baseDataVM.accountHolder, baseDataVM.producerCode_Ext?.value, baseDataVM.experienceID_Ext.value, baseDataVM.value.accountHolder, baseDataVM.value.producerCode_Ext, baseDataVM.accountNumber, searchButtonErrorFunc, authHeader, viewModelService, onMarkAccountSearchCompleted, setShowSearchButtonErrors, showAccountsModal, handleValueChange, path, setPNI, setIsAnExistingAccount]);

    const resolvers = {
        resolveClassNameMap: styles
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            showErrors: showSearchButtonErrors,
            showRequired: true,
            autoComplete: false
        },
        searchAccountButton: {
            onClick: () => onSearchAccounts()
        },
        accountSearchDateOfBirth: {
            dateDTO: baseDataVM.accountHolder?.dateOfBirth,
            updateDateDto: () => handleValueChange(baseDataVM.accountHolder?.dateOfBirth, 'dateOfBirth'),
            maxDate: currentDate,
            isRequired: true
        }
    };


    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                baseDataVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [baseDataVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={baseDataVM.accountHolder}
            overrideProps={overrideProps}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

AccountSearchComponent.propTypes = {
    value: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    authHeader: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({}).isRequired,
    setIsAnExistingAccount: PropTypes.func.isRequired,
    setPNI: PropTypes.func.isRequired,
    onMarkAccountSearchCompleted: PropTypes.func.isRequired,
    searchButtonErrorFunc: PropTypes.func,
    setShowSearchButtonErrors: PropTypes.func,
    showSearchButtonErrors: PropTypes.bool
};
AccountSearchComponent.defaultProps = {
    value: {},
    labelPosition: 'top', // I want labels on top by default
    path: undefined,
    showOptional: false,
    searchButtonErrorFunc: undefined,
    setShowSearchButtonErrors: undefined,
    showSearchButtonErrors: false
};
export default AccountSearchComponent;
