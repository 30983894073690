import React, {
    useCallback, useEffect, useMemo, useContext
} from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { TypekeyUtil } from 'e1p-portals-util-js';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import metadata from './UnderlyingPolicyNamedInsured.metadata.json5';
import './UnderlyingPolicyNamedInsured.messages';

function UnderlyingPolicyNamedInsured(props) {
    const {
        model: namedInsuredVM,
        onModelChange,
        onValidate,
        labelPosition,
        visible,
        viewOnlyMode,
        isPNI,
        hideDOB,
        id,
        showErrors,
        index
    } = props;

    const {
        isComponentValid,
        onValidate: setComponentValidation
    } = useValidation(id);
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [namedInsuredVM, id, onValidate, isComponentValid]);

    useEffect(() => {
        if (_.isUndefined(namedInsuredVM.value) && isPNI) {
            namedInsuredVM.value = {};
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        if (_.startsWith(id, 'secondaryNamedInsured') && _.isEmpty(_.get(namedInsuredVM, 'value.firstName'))) {
            const sniContainerElement = document.getElementById(`secondaryNamedInsuredMainContainer${index}`);

            if (sniContainerElement) {
                const firstNameElement = sniContainerElement.querySelector('input[id="firstName"]');

                if (firstNameElement
                    && firstNameElement.focus !== undefined) {
                    firstNameElement.focus();
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (newVal, path) => {
            _.set(namedInsuredVM, `${path}.value`, newVal);
            onModelChange();
        },
        [namedInsuredVM, onModelChange]
    );

    const availableValuesForSuffix = useMemo(() => {
        const typelistWithNoneCode = [{
            code: 'None',
            name: translator(e1pCommonMessages.none)
        }];

        return typelistWithNoneCode.concat(
            TypekeyUtil.getAvailableValuesForTypekey(viewModelService, 'NameSuffix')
        );
    }, [translator, viewModelService]);

    const handleSuffixChange = useCallback((value, suffixPath) => {
        let updatedValue = value;

        if (value === 'None') {
            updatedValue = undefined;
        }

        writeValue(updatedValue, suffixPath);
    }, [writeValue]);

    const overrideProps = {
        '@field': {
            showRequired: true,
            visible,
            labelPosition,
            readOnly: viewOnlyMode,
            isPNI,
            showErrors,
            autoComplete: false
        },
        dateOfBirth: {
            // can't choose a DOB greater than Today
            maxDate: (() => {
                const today = new Date();

                return { day: today.getDate(), month: today.getMonth(), year: today.getFullYear() };
            })(),
            onValidate: setComponentValidation,
            updateDateDto: () => writeValue(namedInsuredVM?.dateOfBirth, 'dateOfBirth'),
            dateDTO: namedInsuredVM?.dateOfBirth,
            isRequired: !hideDOB,
            visible: !hideDOB
        },
        firstName: {
            required: true,
            showErrors
        },
        lastName: {
            required: true,
            showErrors
        },
        suffix: {
            availableValues: availableValuesForSuffix,
            onValueChange: handleSuffixChange
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={namedInsuredVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={writeValue}
        />
    );
}

UnderlyingPolicyNamedInsured.propTypes = {
    model: PropTypes.shape({}).isRequired,
    onModelChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    labelPosition: PropTypes.string,
    visible: PropTypes.bool,
    viewOnlyMode: PropTypes.bool,
    isPNI: PropTypes.bool,
    hideDOB: PropTypes.bool,
    id: PropTypes.string,
    showErrors: PropTypes.bool,
    index: PropTypes.number.isRequired
};
UnderlyingPolicyNamedInsured.defaultProps = {
    labelPosition: 'top',
    visible: true,
    viewOnlyMode: false,
    isPNI: true,
    hideDOB: false,
    id: '',
    showErrors: false
};

export default UnderlyingPolicyNamedInsured;
