import React, {
    useCallback, useContext, useEffect, useState, useMemo
} from 'react';
import {
    get as _get,
    isEmpty as _isEmpty
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useTPIUtil } from 'e1p-capability-hooks';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from './ThirdPartyInterestPage.metadata.json5';

function ThirdPartyInterestPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        isSkipping,
        steps,
        currentStepIndex,
        clearVisitedStepsAfterCurrent,
        e1pGoNext,
        updateWizardSnapshot
    } = props;
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { checkAndShowDialogForPresenceOfTrust } = useTPIUtil();
    const {
        isComponentValid,
        onValidate,
        disregardFieldValidation
    } = useValidation('ThirdPartyInterestPage');
    const [isSavingQuote, setIsSavingQuote] = useState(false);
    const [isSavingTPI, setIsSavingTPI] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const translator = useTranslator();
    const [isAddingTPI, setIsAddingTPI] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const viewOnlyMode = false;

    /**
     * Helper effect for clearing the visited steps after this page. This ensures that the "onNext"
     * callback is executed in each situation where we may have made changes to TPIs. This effect
     * will also skip the TPI Page if the Payment Details Page hasn't been visited yet.
     */
    useEffect(() => {
        if (!isSkipping && steps[currentStepIndex + 1].visited) {
            clearVisitedStepsAfterCurrent();
        } else if (!steps[currentStepIndex + 1].visited) {
            e1pGoNext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    /**
     * Helper callback for saving TPIs if changes were made while visiting this screen.
     */
    const saveTPIChangesIfNecessary = useCallback(async () => {
        // Save and quote again since something is changed on the TPI
        const response = await LoadSaveService.saveAndQuoteSubmission(
            submissionVM.value,
            authHeader
        );

        submissionVM.value = response;

        return submissionVM.value;
    }, [submissionVM, LoadSaveService, authHeader]);

    /**
     * Helper callback for handling wizard page navigation after the "Next" button is pressed.
     */
    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        const popResult = await checkAndShowDialogForPresenceOfTrust(submissionVM, updateWizardData);

        if (!popResult) {
            // user clicked cancel on presence of trust confirmation dialog
            return false;
        }

        setIsSavingQuote(true);
        submissionVM.value = await saveTPIChangesIfNecessary();
        updateWizardData(submissionVM);
        setIsSavingQuote(false);

        return submissionVM;
    }, [
        isComponentValid, checkAndShowDialogForPresenceOfTrust,
        submissionVM, updateWizardData, saveTPIChangesIfNecessary
    ]);

    const onSave = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = _get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = _get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (_isEmpty(fieldIssues) && _isEmpty(exceptions)) {
                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [isComponentValid, onNext, submissionVM, updateWizardSnapshot]
    );

    const getLoadingMessage = useCallback(
        () => {
            let loadingMessage = translator(e1pCommonMessages.loadingNextPage);

            if (isSavingCurrentPageChanges) {
                loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
            } else if (isSavingTPI) {
                loadingMessage = translator(e1pCommonMessages.savingTPI);
            }

            return loadingMessage;
        }, [isSavingTPI, isSavingCurrentPageChanges, translator]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isSavingQuote && !isSkipping && !isSavingTPI && !isSavingCurrentPageChanges,[isSavingCurrentPageChanges, isSavingQuote, isSavingTPI, isSkipping]);
	
    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            readOnly: viewOnlyMode,
            autoComplete: false
        },
        thirdPartyInterestPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: getLoadingMessage()
        },
        thirdPartyInterestPageContainer: {
            visible: isPageLoaded
        },
        e1ptpiDisplayTableContainer: {
            transactionVM: submissionVM,
            viewModelService,
            authHeader,
            updateWizardData,
            setIsSavingTPI,
            showErrors: isPageSubmitted,
            onValidate,
            disregardFieldValidationParentPage: disregardFieldValidation,
            setIsAddingTPI
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps),
        [submissionVM, overrideProps]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            onNext={onNext}
            onSave={onSave}
            showPrevious={false}
            showCancel={!isAddingTPI}
            showNext={!isAddingTPI}
            showOnSave={!isAddingTPI}
            isPageSubmittedWithErrors={
                isPageSubmitted
                && !isComponentValid
            }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                overrideProps={overrideProps}
                resolveValue={readValue}
            />
        </WizardPage>
    );
}

ThirdPartyInterestPage.propTypes = wizardProps;
export default ThirdPartyInterestPage;
