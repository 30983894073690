import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingPrefillDataMessage: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Loading prefill data...',
        defaultMessage: 'Loading prefill data...'
    },
    loadingNextPageMessage: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    eaVehicleInformationTitle: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Vehicle Information',
        defaultMessage: 'Vehicle Information'
    },
    eaVehicleInformationSubHeader: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Complete the required information for each vehicle added from pre-fill. Add additional vehicles by clicking the "ADD VEHICLE" button',
        defaultMessage: 'Complete the required information for each vehicle added from pre-fill. Add additional vehicles by clicking the "ADD VEHICLE" button'
    },
    paVehicleTitle: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Vehicle',
        defaultMessage: 'Vehicle'
    },
    paVehicleTitleNumber: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Vehicle:',
        defaultMessage: 'Vehicle:'
    },
    paAddAnotherVehicleTitle: {
        id: 'quoteandbind.ea.views.ea-driver-details.Add Another Vehicle?',
        defaultMessage: 'Add Another Vehicle?'
    },
    paAddDriverButtonText: {
        id: 'quoteandbind.ea.views.ea-driver-details.Add Vehicle',
        defaultMessage: 'Add Vehicle'
    },
    fillInMsg: {
        id: 'quoteandbind.ea.directives.templates.ea-edit-vehicle.fillInMsg',
        defaultMessage: 'After you enter a VIN and move to the next field, the Make, Model and Year fields will be filled in automatically if possible.'
    },
    additionalInterestsForThisVehicle: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Additional Interests for This Vehicle',
        defaultMessage: 'Additional Interests for This Vehicle'
    },
    paAddAdditionalInterestType: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.Type',
        defaultMessage: 'Type'
    },
    paAddAdditionalInterestFirstName: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.First Name',
        defaultMessage: 'First Name'
    },
    paAddAdditionalInterestLastName: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.Last Name',
        defaultMessage: 'Last Name'
    },
    paAddAdditionalInterestCountry: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.Country',
        defaultMessage: 'Country'
    },
    paAddAdditionalInterestAddress: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.Address',
        defaultMessage: 'Address'
    },
    paAddAdditionalInterestCity: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.City',
        defaultMessage: 'City'
    },
    paAddAdditionalInterestState: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.State',
        defaultMessage: 'State'
    },
    paAddAdditionalInterestZipCode: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.ZIP Code',
        defaultMessage: 'ZIP code'
    },
    paAddAdditionalInterestInterestType: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.Interest Type',
        defaultMessage: 'Interest Type'
    },
    paAddAdditionalInterestAddressType: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.Address Type',
        defaultMessage: 'Address Type'
    },
    paAddAdditionalInterestPhoneNumber: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.Phone Number',
        defaultMessage: 'Phone Number'
    },
    paAddAdditionalInterestEmail: {
        id: 'quoteandbind.views.ea.ho-your-home.additional-interest.Email',
        defaultMessage: 'Email'
    },
    paAddAdditionalInterestForThisVehicle: {
        id: 'quoteandbind.ea.views.ea-driver-details.Additional Interests for This Vehicle',
        defaultMessage: 'Add Additional Interests for This Vehicle'
    },
    additionalInterestCount: {
        id: 'quoteandbind.views.ea.ea-vehicles.additional-interest.Additional Interest:',
        defaultMessage: 'Additional Interest: { count }'
    },
    PrimaryUse: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.PrimaryUse',
        defaultMessage: 'Primary Use'
    },
    VIN: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.Vehicle ID Number (VIN)',
        defaultMessage: 'Vehicle ID Number (VIN)'
    },
    Year: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.Year',
        defaultMessage: 'Year'
    },
    Make: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.Make',
        defaultMessage: 'Make'
    },
    Model: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.Model',
        defaultMessage: 'Model'
    },
    Series: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.Series',
        defaultMessage: 'Series'
    },
    VehicleType: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.VehicleType',
        defaultMessage: 'Vehicle Type'
    },
    VehicleCost: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.VehicleCostNew',
        defaultMessage: 'Cost New'
    },
    Mileage: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.AnnualMileage',
        defaultMessage: 'Annual Mileage'
    },
    CurrentOdometer: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.CurrentOdometerReading',
        defaultMessage: 'Current Odometer Reading'
    },
    AutoSafety: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.AutoSafety',
        defaultMessage: 'Auto Safety Equipment'
    },
    GaragingAddress: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.GaragingAddress',
        defaultMessage: 'Garaging Address'
    },
    eaDeleteVehicle: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Delete Vehicle',
        defaultMessage: 'Delete Vehicle'
    },
    eaAddVehicle: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.ADD VEHICLE',
        defaultMessage: 'ADD VEHICLE'
    },
    thirdPartyTitle: {
        id: 'quoteandbind.ea.views.ea-underwriting-details.Third-Party Interest',
        defaultMessage: 'Third-Party Interest'
    },
    thirdPartyCheck: {
        defaultMessage: 'There is a Third-Party Interest for at least one of the vehicles on this policy ',
        id: 'quoteandbind.ea.views.ea-vehicle-details.There is a Third Party:'
    },
    otherThanNamedInsuredLabel: {
        id: 'quoteandbind.views.ea-vehicle-details.Person(s) other than named insured owns or co-owns this vehicle',
        defaultMessage: 'Person(s) other than named insured owns or co-owns this vehicle'
    },
    antiTheftDevices: {
        id: 'quoteandbind.views.ea-vehicle-details.Anti-Theft Devices',
        defaultMessage: 'Anti-Theft Devices'
    },
    airBagType: {
        id: 'quoteandbind.views.ea-vehicle-details.Airbag Type',
        defaultMessage: 'Airbag Type'
    },
    garagedOutOfState: {
        id: 'quoteandbind.views.ea.ea-vehicles.ea-vehicle-details.Vehicle is garaged out of the State more than 6 months a year',
        defaultMessage: 'Vehicle is garaged out of the State more than 6 months a year'
    },
    vehicleNoPrefillSubheader: {
        id: 'quoteandbind.ea.views.ea-vehicle-details.Vehicle pre-fill report was returned without any vehicle information.',
        defaultMessage: 'Vehicle pre-fill report was returned without any vehicle information.'
    }
});
