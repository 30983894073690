import React, {
    useCallback,
    useEffect, useState,
    useContext
} from 'react';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import metadata from './E1PWindstormMitigationDiscountComponent.metadata.json5';
import messages from './E1PWindstormMitigationDiscountComponent.messages';
import styles from './E1PWindstormMitigationDiscountComponent.module.scss';
import E1PWindstormMitigationDiscountModal from '../E1PWindstormMitigationDiscountModal/E1PWindstormMitigationDiscountModal';
import LossMitigationComponent from '../EHSCLossMitigationComponent/LossMitigationComponent';
import OpeningProtectionComponent from '../EHOpeningProtectionComponent/OpeningProtectionComponent';

function E1PWindstormMitigationDiscountComponent(props) {
    const modalApi = useModal();
    const {
        transactionVM,
        updateWizardData,
        viewOnly,
        setFieldsChangedOnCoveragePage,
        policyState
    } = props;
    const [isWindstormMitigationDiscountPresent, setIsWindstormMitigationDiscountPresent] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);

    useEffect(() => {
        const windstormMitigationFeatures = _.get(
            transactionVM,
            'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
            []
        );

        if (!_.isEmpty(windstormMitigationFeatures)) {
            setIsWindstormMitigationDiscountPresent(true);
        }
    }, [transactionVM]);

    const hasWindstormMitigationFeature = useCallback(() => {
        const windstormMitigationFeatures = _.get(
            transactionVM,
            'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
            []
        );

        return !_.isEmpty(windstormMitigationFeatures);
    }, [transactionVM]);

    const getMitigationTitle = useCallback(() => {
        switch(policyState) {
            case 'SC': 
                return messages.lossMitigation;
            case 'CT':
                return messages.openingProtection;    
            default: 
                return messages.windStormMitigation;
        }
    }, [policyState]);

    const showWindstormMitigationDiscountModal = useCallback(
        () => {
            const componentProps = {
                iconClassType: false,
                showCloseBtn: true,
                showCancelBtn: true,
                transactionVM,
                viewModelService,
                viewOnly,
                title: getMitigationTitle(),
                setFieldsChangedOnCoveragePage
            };

            if (policyState === 'SC') {
                return modalApi.showModal(
                    <LossMitigationComponent {...componentProps} />
                );
            }

            if (policyState === 'CT') {
                return modalApi.showModal(
                    <OpeningProtectionComponent { ...componentProps} />
                );
            }

            return modalApi.showModal(
                <E1PWindstormMitigationDiscountModal {...componentProps} />
            );
        },
        [transactionVM, viewModelService, viewOnly, getMitigationTitle, setFieldsChangedOnCoveragePage, policyState, modalApi]
    );


    const onWindstormMitigationDiscountToggleValueChange = useCallback((value) => {
        if (value) {
            showWindstormMitigationDiscountModal().then((response) => {
                _.set(transactionVM, 'value', response.value);
                updateWizardData(transactionVM);
                setIsWindstormMitigationDiscountPresent(hasWindstormMitigationFeature());
            }).catch(() => {
                setIsWindstormMitigationDiscountPresent(hasWindstormMitigationFeature());
            });
        } else {
            // When toggle is set to 'No' wiping out all data related to windstorm mitigation
            _.set(
                transactionVM,
                'lobData.homeowners_EH.coverables.construction.windstormMitigationFeatures.value',
                []
            );
            _.set(
                transactionVM,
                'lobData.homeowners_EH.coverables.construction.certificationExpirationDate.value',
                undefined
            );
            updateWizardData(transactionVM);
            setIsWindstormMitigationDiscountPresent(value);
        }

        // force recalculate on coverage page
        setFieldsChangedOnCoveragePage(true);
    }, [
        hasWindstormMitigationFeature, setFieldsChangedOnCoveragePage,
        showWindstormMitigationDiscountModal, transactionVM, updateWizardData
    ]);

    const onWindstormMitigationDiscountEditIconClick = () => {
        showWindstormMitigationDiscountModal().then((response) => {
            _.set(transactionVM, 'value', response.value);
            updateWizardData(transactionVM);
            setIsWindstormMitigationDiscountPresent(hasWindstormMitigationFeature());
        }).catch(() => {
            setIsWindstormMitigationDiscountPresent(hasWindstormMitigationFeature());
        });
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    const overrideProps = {
        '@field': {
            showRequired: true,
            autoComplete: false
        },
        windstormMitigationDiscountToggle: {
            value: isWindstormMitigationDiscountPresent,
            onValueChange: onWindstormMitigationDiscountToggleValueChange,
            readOnly: viewOnly,
            label: getMitigationTitle()
        },
        windstormMitigationDiscountActionIcon: {
            onClick: onWindstormMitigationDiscountEditIconClick,
            visible: isWindstormMitigationDiscountPresent,
            icon: viewOnly ? 'mi-visibility' : 'mi-edit'
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            model={transactionVM}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

E1PWindstormMitigationDiscountComponent.propTypes = {
    transactionVM: PropTypes.shape({}).isRequired,
    LOB: PropTypes.string.isRequired,
    updateWizardData: PropTypes.func.isRequired,
    viewOnly: PropTypes.bool.isRequired,
    setFieldsChangedOnCoveragePage: PropTypes.func,
    policyState: PropTypes.string.isRequired, 
};

E1PWindstormMitigationDiscountComponent.defaultProps = {
    setFieldsChangedOnCoveragePage: () => { }
};
export default E1PWindstormMitigationDiscountComponent;
