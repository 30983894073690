import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { CurrencyValue } from '@jutro/components';
import { Grid, GridItem } from '@jutro/layout';
import { SymbolCurrencyComponent, E1PLoader } from 'e1p-capability-policyjob-react';
import styles from './EUClauseComponent.module.scss';

import { CheckboxField } from '@jutro/legacy/components';

class ClauseComponent extends Component {
    /**
     * @memberof gw-components-platform-react.ClauseComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.displayName - name of clause to display
     * @prop {bool} propTypes.readOnly - if the clause selection is read only
     * @prop {bool} propTypes.value - is the clause is seleceted
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {bool} propTypes.isLoading - should the clause be loading
     * @prop {string} propTypes.loadingMessage - message to be shown while loading
     * @prop {string} propTypes.containerClassName - clause container class
     */

    static propTypes = {
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        readOnly: PropTypes.bool,
        onValueChange: PropTypes.func.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape({})),
        value: PropTypes.bool,
        path: PropTypes.string,
        isLoading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        checked: PropTypes.bool,
        isEditable: PropTypes.bool,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        containerClassName: PropTypes.string,
        labelPosition: PropTypes.string,
        amount: PropTypes.shape({}),
        showPremium: PropTypes.bool
    }

    static defaultProps = {
        readOnly: false,
        path: undefined,
        value: undefined,
        isLoading: false,
        loadingMessage: '',
        checked: false,
        children: undefined,
        isEditable: true,
        description: undefined,
        containerClassName: undefined,
        labelPosition: 'left',
        amount: undefined,
        showPremium: true
    }

    handleChange = (value) => {
        const { onValueChange, path } = this.props;

        if (onValueChange) {
            onValueChange(value, path);
        }
    }

    getLabelNameWithAmount = (labelName, labelAmount) => {
        if (!_.isUndefined(labelAmount)) {
            return `${labelName} ${labelAmount.amount}`;
        }

        return labelName;
    }

    renderEditableValue() {
        const {
            displayName,
            readOnly,
            value,
            id,
            isLoading,
            loadingMessage,
            children,
            path,
            checked,
            description,
            containerClassName,
            labelPosition,
            amount,
            showPremium
        } = this.props;

        const hasChildren = !_.isNil(children);
        const clauseContainerStyles = classNames(styles.clause, containerClassName);
        const checkboxControlStyles = classNames({
            [styles.clauseAndTermSpacing]: hasChildren
        });
        const checkboxStyles = classNames({
            [styles.clauseNoPadding]: !hasChildren
        });

        return (
            <div className={clauseContainerStyles}>
                {isLoading
                    ? (
                        <E1PLoader
                            loaded={!isLoading}
                            text={loadingMessage}
                        />
                    ) : (
                        <Grid
                                columns={[
                                    '0.3fr',
                                    '0.5fr',
                                    '0.2fr'
                                ]}
                                gap="medium"
                                id="Grid"
                                rows={[
                                    '1fr',
                                ]}
                                tag="div"
                            >
                                <GridItem
                                    className="jut__Containers__highlightHover"
                                    tag="div"
                                >
                                    <CheckboxField
                                        id={id}
                                        label={displayName}
                                        value={value}
                                        readOnly={readOnly}
                                        onValueChange={this.handleChange}
                                        className={checkboxStyles}
                                        controlClassName={checkboxControlStyles}
                                        path={path}
                                        checked={checked}
                                        labelPosition={labelPosition}
                                        showInlineLabel
                                        tooltip={!_.isUndefined(description) ? { text: description } : null}
                                        layout="full-width"
                                    />
                                </GridItem>
                                <GridItem
                                    className="jut__Containers__highlightHover"
                                    tag="div"
                                >
                                    {value ? children : null}
                                </GridItem>
                                {(showPremium) ? (
                                    <GridItem
                                        className="jut__Containers__highlightHover"
                                        tag="div"
                                    >
                                        <SymbolCurrencyComponent
                                            value={amount}
                                        />
                                    </GridItem>
                                )
                                    : (
                                        <GridItem
                                            className="jut__Containers__highlightHover"
                                            tag="div"
                                        >
                                            <span>-</span>
                                        </GridItem>
                                    )
                                }

                            </Grid>
                    )
                }
            </div>
        );
    }

    renderReadOnlyValue() {
        const {
            displayName, children, amount
        } = this.props;

        return (
            <div className={styles.readOnlyGrid}>
                <span className={styles.readOnlyGridSlotLeft}>
                    {displayName}
                    <span className={styles.readOnlyGridAmount}>
                        {!_.isUndefined(amount) ? (
                            <CurrencyValue
                                amount={amount.amount}
                                currency={amount.currency}
                                showFractions
                            />
                        ) : undefined
                        }
                    </span>
                </span>
                <div className={styles.readOnlyGridSlotRight}>
                    {children}
                </div>
            </div>
        );
    }

    render() {
        const { isEditable } = this.props;

        return isEditable ? this.renderEditableValue() : this.renderReadOnlyValue();
    }
}

export default ClauseComponent;
