import { defineMessages } from 'react-intl';

export default defineMessages({
    paAddressLine1: {
        id: 'quoteandbind.pa.directives.templates.pa-address.line 1',
        defaultMessage: 'Address Line 1',
    },
    paAddressLine2: {
        id: 'quoteandbind.pa.directives.templates.pa-address.Line 2',
        defaultMessage: 'Address Line 2',
    },
    paCity: {
        id: 'quoteandbind.pa.directives.templates.pa-address.City',
        defaultMessage: 'City',
    },
    paState: {
        id: 'quoteandbind.pa.directives.templates.pa-address.State',
        defaultMessage: 'State',
    },
    paZipCode: {
        id: 'quoteandbind.pa.directives.templates.pa-address.ZipCode',
        defaultMessage: 'Zip Code',
    },
    paMostRecent: {
        id: 'quoteandbind.pa.directives.templates.pa-address.MostRecent',
        defaultMessage: 'Most Recent',
    },
    paOptional: {
        id: 'quoteandbind.eh.views.eh-priorAddress-details.optional',
        defaultMessage: 'Optional',
    },
});
