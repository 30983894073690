import { defineMessages } from 'react-intl';

export default defineMessages({
    followingQuotes: {
        id: 'component.quoteandbind.common.views.quote-details.Underwriting issues have been raised for the following quotes:',
        defaultMessage: 'Underwriting issues have been raised.'
    },
    uwIssues: {
        id: 'component.quoteandbind.common.views.quote-details.Underwriting Issues',
        defaultMessage: 'Underwriting Issues'
    },
    weCannotShow: {
        id: 'component.quoteandbind.common.views.quote-details.We cannot show a quote because it has underwriting issues',
        defaultMessage: 'We cannot show a quote because it has underwriting issues'
    },
    youCanDoTheFollowing: {
        id: 'component.quoteandbind.common.views.quote-details.You can do the following:',
        defaultMessage: 'You can do the following:'
    },
    editTheQuote: {
        id: 'component.quoteandbind.common.views.quote-details.Edit the quote if the customer is willing to accept the change',
        defaultMessage: 'Edit the quote if the customer is willing to accept the change'
    },
    approveTheIssue: {
        id: 'component.quoteandbind.common.views.quote-details.Approve the issue(s)',
        defaultMessage: 'Approve the issue(s)'
    },
    referTheQuote: {
        id: 'component.quoteandbind.common.views.quote-details.Refer the quote to the underwriter',
        defaultMessage: 'Refer the quote to the underwriter'
    },
    withdrawQuote: {
        id: 'component.quoteandbind.common.views.quote-details.Withdraw the quote',
        defaultMessage: 'Withdraw the quote'
    },
    approve: {
        id: 'component.quoteandbind.common.views.quote-details.underwriting-issue.action.Approve',
        defaultMessage: 'Approve'
    },
    reject: {
        id: 'component.quoteandbind.common.views.quote-details.underwriting-issue.action.Reject',
        defaultMessage: 'Reject'
    },
    reopen: {
        id: 'component.quoteandbind.common.views.quote-details.underwriting-issue.action.Reopen',
        defaultMessage: 'Reopen'
    },
    viewDetail: {
        id: 'component.quoteandbind.common.views.quote-details.underwriting-issue.action.ViewDetail',
        defaultMessage: 'Actions'
    },
    blocksBind: {
        id: 'component.quoteandbind.directives.MultiQuoteView.Blocks Bind',
        defaultMessage: 'Blocks Bind'
    },
    blocksQuote: {
        id: 'component.quoteandbind.directives.MultiQuoteView.Blocks Quote',
        defaultMessage: 'Blocks Quote'
    },
    blocksQuoteRelease: {
        id: 'component.quoteandbind.directives.MultiQuoteView.Blocks Quote Release',
        defaultMessage: 'Blocks Quote Release'
    },
    nonBlocking: {
        id: 'component.quoteandbind.directives.MultiQuoteView.Informational',
        defaultMessage: 'Informational'
    },
    rejected: {
        id: 'component.quoteandbind.directives.MultiQuoteView.Rejected',
        defaultMessage: 'Rejected'
    },
    alreadyApproved: {
        id: 'component.quoteandbind.directives.MultiQuoteView.Approved',
        defaultMessage: 'Approved'
    },
    blocksIssuance: {
        id: 'component.quoteandbind.directives.MultiQuoteView.Blocks Issuance',
        defaultMessage: 'Blocks Issuance'
    },
    riskApprovalDetails: {
        id: 'component.quoteandbind.common.views.quote-details.Risk Approval Details',
        defaultMessage: 'Risk Approval Details'
    },
    modalError: {
        id: 'component.quoteandbind.common.views.quote-details.Something went wrong',
        defaultMessage: 'Something went wrong'
    },
    uwIssueReopenError: {
        id: 'component.quoteandbind.common.views.quote-details.Something went wrong during reopening Underwriting Issue',
        defaultMessage: 'Something went wrong during reopening Underwriting Issue'
    },
    uwIssueRejectError: {
        id: 'component.quoteandbind.common.views.quote-details.Something went wrong during rejecting Underwriting Issue',
        defaultMessage: 'Something went wrong during rejecting Underwriting Issue'
    },
    uwIssueApproveError: {
        id: 'component.quoteandbind.common.views.quote-details.Something went wrong during approving Underwriting Issue',
        defaultMessage: 'Something went wrong during approving Underwriting Issue'
    },
    loadingUWIssues: {
        id: 'component.quoteandbind.common.views.quote-details.Loading Underwriting issues...',
        defaultMessage: 'Loading Underwriting issues...'
    },
    contactCsrMessage: {
        id: 'component.quoteandbind.common.views.If special circumstances warrant an exception, call Customer Service.',
        defaultMessage: 'If special circumstances warrant an exception, call Customer Service.'
    }
});
