import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '@jutro/layout';
import { get as _get } from 'lodash';
import { CurrencyValue } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import messages from './EhSideBySideQuoteDetailsComponent.messages';
import styles from './EhSideBySideQuoteDetailsComponent.module.scss';

import { IconButton, RadioButtonField, InputField } from '@jutro/legacy/components';

function EhSideBySideQuoteDetailsComponent(props) {
    const {
        lobOfferings,
        quoteDataOfferings,
        updateQdd,
        updateQuoteVersionName,
        setSelectedVersion,
        onWithdrawSideBySideVersion
    } = props;

    const translator = useTranslator();
    const [isLoading, setIsLoading] = useState(false);

    const onSideBySideQuoteNameFieldChange = useCallback(
        (value, offeringIndex) => {
            lobOfferings[offeringIndex - 1].branchName = value;
            updateQdd();
        }, [lobOfferings, updateQdd]
    );

    const onUpdateSideBySideQuoteName = useCallback(
        async (value, beforeValue, branchCode) => {
            if (value === beforeValue) {return;}

            setIsLoading(true);

            const versionId = quoteDataOfferings.find((offering) => offering.branchCode === branchCode).publicID;

            await updateQuoteVersionName(versionId, value);
            setIsLoading(false);
        }, [quoteDataOfferings, updateQuoteVersionName]
    );

    const numberOfColumns = lobOfferings.length + 1;
    const columnConfig = [];

    for (let column = 0; column < numberOfColumns; column++) {
        let columnWidthToPush = '320px';

        if (column === 0) {
            columnWidthToPush = '210px';
        }

        columnConfig.push(columnWidthToPush);
    }

    const rowHeaders = ['Name', 'Premium', 'Discount Subtotal'];
    const numberOfCells = numberOfColumns * rowHeaders.length;
    const tableContent = [];

    for (let x = 0; x < numberOfCells; x++) {
        // There will always be 3 rows based on mock,
        //   but cols differ based on # of versions
        const ROWS = 3;
        const rowNumber = Math.floor(x / numberOfColumns) + 1;

        if (x === 0 || (x % numberOfColumns === 0)) {
            tableContent.push
            (<GridItem
                className={rowNumber === ROWS ? styles.qDetailCellBottomLeft : styles.qDetailCellLeft}
                tag="div" key={`quoteDetailGridItem${x}`}>
                <span key={`quoteDetailCell${x}`}>
                    {rowHeaders.shift()}
                </span>
            </GridItem>)
        } else if (rowNumber === 1) {

            // radio to select version and input to change name
            tableContent.push(
                <Grid className={styles.qDetailCell}
                    columns={['1fr', '6fr', '1fr']} gap="small" key={`row1Grid${x}`}>
                    <GridItem>
                        <RadioButtonField
                            availableValues={[{
                                name: '',
                                code: true
                            }]}
                            id={`version-button-field${x}`}
                            controlClassName={styles.qDetailsRadioPadding}
                            label=""
                            hideLabel
                            value={
                                // using loboffering index as source of truth so will match on quotingdata for isSelected
                                (() => {
                                    const quoteDataOffering = quoteDataOfferings
                                        .find((offering) => lobOfferings[x - 1].branchCode === offering.branchCode);

                                    return quoteDataOffering.selected
                                })()
                            }
                            onValueChange={async () => {
                                setSelectedVersion(
                                    quoteDataOfferings
                                        .find((offering) => lobOfferings[x - 1].branchCode === offering.branchCode).publicID
                                );
                            }}
                        />
                    </GridItem>
                    <GridItem>
                        <InputField
                            defaultValue={lobOfferings[x - 1].branchName}
                            id={`versionName${x - 1}`}
                            label=""
                            noDeprecationWarnings={[
                                'autoComplete'
                            ]}
                            onBlur={(_, { value, beforeValue }) => { onUpdateSideBySideQuoteName(value, beforeValue, lobOfferings[x - 1].branchCode); }}
                            onValueChange={(value) => { onSideBySideQuoteNameFieldChange(value, x); }}
                            value={lobOfferings[x - 1].branchName}
                        />
                    </GridItem>
                    <GridItem>
                        <IconButton
                            id={`deleteVersion${x - 1}`}
                            icon="mi-delete_outline"
                            size="large"
                            onClick={(evt) => {
                                evt.stopPropagation();
                                onWithdrawSideBySideVersion(
                                    quoteDataOfferings
                                        .find((offering) => lobOfferings[x - 1].branchCode === offering.branchCode).publicID
                                )
                            }}
                        />
                    </GridItem>
                </Grid>
            )
        } else if (rowNumber === 2) {
            const quoteOffering = quoteDataOfferings
                .find((offering) => lobOfferings[x - numberOfColumns - 1].branchCode === offering.branchCode)

            tableContent.push(
                <GridItem
                    className={styles.qDetailCell}
                    tag="div" key={`premiumGrid${x - numberOfColumns - 1}`}>
                    <CurrencyValue
                        id={`version${x - numberOfColumns - 1}premium`}
                        showFractions
                        value={{
                            amount: _get(quoteOffering, 'premium.total.amount', undefined),
                            currency: 'USD'
                        }}
                        className={styles.premiumFontLg}
                    />
                </GridItem>
            )
        } else if (rowNumber === 3) {
            const currentQuoteOffering = quoteDataOfferings
                .find((quoteOffering) => quoteOffering.branchCode === lobOfferings[x - (2 * numberOfColumns) - 1].branchCode);
            const isCurrentOfferingNotQuoted = currentQuoteOffering?.status !== 'Quoted';

            tableContent.push(
                isCurrentOfferingNotQuoted ?
                    <GridItem
                        className={rowNumber === ROWS ? styles.qDetailCellBottom : styles.qDetailCell}
                        tag="div" key={`DiscountGridItem${x - 2 * numberOfColumns - 1}`} />
                    :
                    <GridItem
                        className={rowNumber === ROWS ? styles.qDetailCellBottom : styles.qDetailCell}
                        tag="div" key={`DiscountGridItem${x - 2 * numberOfColumns - 1}`}>
                        <CurrencyValue
                            id={`version${x - (2 * numberOfColumns) - 1}discounts`}
                            showFractions
                            value={{
                                amount: (() => {
                                    const premiumSummary = _get(lobOfferings[x - (2 * numberOfColumns) - 1], 'premiumSummary', undefined);

                                    return premiumSummary.find((summary) => summary.premiumSummaryType === 'EHDiscountSavingsSubtotal')?.amount;
                                })(),
                                currency: 'USD'
                            }}
                        />
                    </GridItem>
            )
        }
    }

    return (
        <Grid className={styles.tableSpacingAbove} columns={columnConfig} gap="none">
            {isLoading ?
                (<E1PLoader
                    loaded={!isLoading}
                    text={translator(messages.updatingSideBySideGeneric)}
                />)
                : (tableContent)}
        </Grid>
    );
}

EhSideBySideQuoteDetailsComponent.propTypes = {
    lobOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    quoteDataOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    updateQdd: PropTypes.func.isRequired,
    updateQuoteVersionName: PropTypes.func.isRequired,
    setSelectedVersion: PropTypes.func.isRequired,
    onWithdrawSideBySideVersion: PropTypes.func.isRequired
};

export default EhSideBySideQuoteDetailsComponent;
