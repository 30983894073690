import React, {
    useEffect,
    useState,
    useContext,
    useCallback
} from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Wizard } from 'e1p-portals-wizard-react';
import { PolicyService } from 'gw-capability-gateway';
import { CancellationService } from 'e1p-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useModal } from '@jutro/components';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-capability-policychange-common-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import wizardConfig from './config/cancel-wizard-config.json5';
import messages from './PolicyCancelWizard.messages';
import styles from './PolicyCancelWizard.module.scss';
import wizardStepToFieldMapping from './config/wizard-step-to-field-mapping.json5';

function PolicyCancelWizard(props) {
    const modalApi = useModal();
    const { steps, title } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [cancelSubmission, setCancelSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const viewModelService = useContext(ViewModelServiceContext);
    const { history, authHeader, location } = props;

    const errorModalBox = (errorMessage, policyNumber) => {
        modalApi.showConfirm(errorMessage).then(() => {
            setIsLoading(false);
            history.push(`/policies/${policyNumber}/summary`);
        });
    };

    useEffect(
        () => {
            if (!location.state) {
                history.push('/');

                return;
            }

            const { routeParam: { policyNumber } } = location.state;
            const { routeParam: { jobNumber } } = location.state;

            if (!policyNumber) {
                return;
            }

            if (!jobNumber) {
                const vm = viewModelService.create(
                    {},
                    'pc',
                    'edge.capabilities.gateway.job.cancellation.dto.CancellationDTO'
                );

                setCancelSubmission(vm);
            } else {
                CancellationService.findJobByJobNumber(jobNumber, authHeader)
                    .then((responseData) => {
                        const submission = viewModelService.create(
                            responseData,
                            'pc',
                            'edge.capabilities.gateway.job.cancellation.dto.CancellationDTO'
                        );

                        setCancelSubmission(submission);
                        setIsLoading(false);
                    }).catch(() => {
                        errorModalBox({
                            title: commonMessages.unableToLoadDraftPolicy,
                            message: commonMessages.somethingWentWrong,
                            status: 'warning',
                            icon: 'mi-error-outline'
                        });
                    });
            }

            PolicyService.getPolicy(policyNumber, authHeader)
                .then((responseData) => {
                    const submission = viewModelService.create(
                        responseData,
                        'pc',
                        'edge.capabilities.gateway.policy.dto.PolicyDTO'
                    );

                    setInitialSubmission(submission);

                    if (!jobNumber) {
                        setIsLoading(false);
                    }
                }).catch(() => {
                    errorModalBox({
                        title: commonMessages.unableToLoadDraftPolicy,
                        message: commonMessages.somethingWentWrong,
                    }, policyNumber);
                });
        },
        // Disabled so we don't rerun this function on every rerender
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );


    const handleOnCancel = useCallback((cancelProps) => {
        const {
            param = {}
        } = cancelProps;

        return modalApi.showConfirm({
            title: messages.exitTransaction,
            message: messages.infoWillBeSavedAsDraft,
            confirmButtonText: e1pCommonMessages.exitTransactionLabel,
            cancelButtonText: messages.cancelLabel
        }).then((results) => {
            if (results === 'cancel') {
                return _.noop();
            }

            setIsLoading(true);

            const jobIDFromLocation = _.get(location, 'state.routeParam.policyNumber');
            const redirectPath = `/policies/${jobIDFromLocation}/summary`;
            const { search, pathname: nextPath = '' } = param;

            if (nextPath?.includes('/quote-') || !_.isEmpty(nextPath)) {
                return history.push({
                    pathname: nextPath,
                    search
                });
            }

            return history.push(redirectPath);
        });
    }, [history, location, modalApi]);

    const handleError = useCallback((error) => {
        const isQuotePage = _.includes(_.toLower(_.get(error, 'gwInfo.method')), 'quote');
        const jobIDFromLocation = _.get(location, 'state.policyNumber');
        const redirectPath = `/policies/${jobIDFromLocation}/summary`;
        const state = {
            pathname: redirectPath,
            state: error
        };

        return modalApi.showAlert({
            status: 'error',
            icon: 'mi-error-outline',
            title: isQuotePage ? messages.cancelQuoteError : messages.anErrorOccurredTitle,
            message: isQuotePage ? messages.cancelQuoteErrorMessage : messages.anErrorOccurred,
        }).then(() => <Redirect to={state} />);
    }, [location, modalApi]);

    if (isLoading) {
        return (
            <E1PLoader loaded={!isLoading} />
        );
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <div className={styles.hoPolicyChangeWizardContainer}>
            <ErrorBoundary onError={handleError}>
                <Wizard
                    initialSteps={steps}
                    wizardTitle={title}
                    initialData={{
                        submissionVM: cancelSubmission,
                        policyData: initialSubmission
                    }}
                    wizardStepToFieldMapping={wizardStepToFieldMapping}
                    onCancel={handleOnCancel}
                    onPreviousModalProps={{
                        title: platformMessages.wantToJump,
                        message: platformMessages.wantToJumpMessage,
                        messageProps: {
                            ok: platformMessages.yes,
                            close: platformMessages.no
                        }
                    }}
                />
            </ErrorBoundary>
        </div>
    );
}

PolicyCancelWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
};

export default withAuthenticationContext(PolicyCancelWizard);
