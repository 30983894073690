import React, {
    useCallback, useContext, useState, useEffect
} from 'react';
import {
    get as _get,
    set as _set,
    includes as _includes
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { isRequired } from 'e1p-portals-required-validator-js';
import { RandomNumberUtil } from 'e1p-portals-util-js';
import moment from 'moment';
import {
    useViewPolicyUtil
} from 'e1p-capability-hooks';
import messages from '../InsuredDetailsPage.messages';
import metadata from '../InsuredDetailsPage.metadata.json5';
import requiredMetadata from '../InsuredDetailsPage.requiredness';

const LOB = 'personalAuto_EA';

function ViewInsuredDetailsPage(props) {
    const { wizardData: policyChangeVM, updateWizardData, authUserData } = props;
    const translator = useTranslator();
    const {
        onValidate,
    } = useValidation('ViewInsuredDetailsPage');
    const { authHeader } = useAuthentication();
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const [policyIsBackDated, setPolicyIsBackDated] = useState(false);
    const [setIsStandardizingAddress] = useState(false);
    const [requiredFields, updateRequiredFields] = useState([]);
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const policyState = _get(policyChangeVM, 'baseData.policyAddress.state.value.code');
    const { opCo } = useContext(AmfamOktaTokenContext);
    const {
        getLocationAndProducerCodes,
        getProducerDetails
    } = useViewPolicyUtil();

    const sessionIdPrefixEdit = 'EA - VW - ';
    const sessionHeader = sessionIdPrefixEdit + RandomNumberUtil.randomFixedInteger(10);

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location, authHeader, setLocationAndProducerCodes);

        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(policyChangeVM, 'baseData.producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(policyChangeVM, 'baseData.externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    useEffect(() => {
        if (policyChangeVM.baseData.externalID_Ext?.value) {
            getProducerDetails(
                policyChangeVM.baseData.externalID_Ext.value, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
                setAgencyName(details.agencyName);
                setBookTransferIndicator(details.bookTransferIndicator);
                setServiceCenterIndicator(details.serviceCenterIndicatorValue);
            }).catch((error) => {
                /**
                * E1PAP1PC-15099 :
                * This is rare scenario; will never happen in production
                * When we bound policy using dummy producer code
                * and when other user tries to view this policy we get exception from service
                */
                if (policyChangeVM.baseData.value.exceptions_Ext) {
                    policyChangeVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        policyChangeVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(policyChangeVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyChangeVM.baseData.externalID_Ext.value]);

    const setBackdateInformation = () => {
        const policyEffectiveDate = moment(policyChangeVM.baseData.effectiveDate.value);
        const effectiveDateBeforeToday = moment().isAfter(policyEffectiveDate, 'd');

        setPolicyIsBackDated(effectiveDateBeforeToday);
    };

    useEffect(() => {
        _set(policyChangeVM, 'baseData.producerCode_Ext.value', policyChangeVM.value.baseData.producerCode);

        if (isAgent) {
            getAndSetLocationCodes(_get(policyChangeVM, 'baseData.policyAddress.state.value.code'));
        }

        setBackdateInformation();
        authHeader['afe-session-id'] = sessionHeader;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initialRequiredFields = ['ownsHomeIndicator', 'applicantsResideInSameStateInd', 'isUmbrellaAssociated']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
    }, [policyState]);

    const generateOverrides = useCallback(() => {
        const overrides = {};

        overrides.secondaryNamedInsured = {
            viewOnlyMode: true
        };
        overrides.primaryNamedInsured = {
            viewOnlyMode: true,
            isPNI: true
        };

        return overrides;
    }, []);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        insuredDetailsPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.loadingNextPageMessage)
        },
        insuredDetailsPageContainer: {
            visible: true
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        insuredResidenceAddress: {
            addressVM: _get(policyChangeVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            onValidate,
            viewOnlyMode: true,
            showParentLoader: setIsStandardizingAddress
        },
        mailingAndBillingAddressComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            lob: LOB,
            viewOnlyMode: true
        },
        secondaryNamedInsuredContainer: {
            visible: !!_get(policyChangeVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        changeEffectiveDate: {
            dateDTO: policyChangeVM.baseData.effectiveDate,
            updateDateDto: () => { },
            showErrors: true,
            onValidate
        },
        periodStartDate: {
            dateDTO: policyChangeVM.baseData.effectiveDate,
            updateDateDto: () => { }
        },
        periodEndDate: {
            dateDTO: policyChangeVM.baseData.periodEndDate,
            updateDateDto: () => { }
        },
        coverageRateAsOfDate: {
            dateDTO: policyChangeVM.baseData.periodRateAsOfDate,
            updateDateDto: () => { },
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        policyInformationInfoContainer: {
            columns: authUserData.permissions_Ext.includes('editrateasofdate')
                ? ['0.25fr', '0.25fr', '0.25fr', '0.25fr'] : ['0.25fr', '0.25fr', '0.25fr']
        },
        dateOfBirth: {
            updateDateDto: () => { },
            dateDTO: policyChangeVM.lobData.personalAuto_EA.primaryNamedInsured.person.dateOfBirth,
        },
        ExternalProducerDetailsComponent: {
            viewOnlyMode: true,
            defaultValue: policyChangeVM.baseData.externalID_Ext?.value
        },
        changePniComponent: {
            visible: false
        },
        changeSniComponent: {
            visible: false
        },
        insuredResidenceAddressInfoMessageDiv: {
            visible: false
        },
        lossQuestionEmptyContainer: {
            visible: !policyIsBackDated,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 3 : 2
        },
        lossQuestionContainer: {
            visible: policyIsBackDated,
            colSpan: authUserData.permissions_Ext.includes('editrateasofdate') ? 3 : 2
        },
        lossOccurredBetweenEffectiveDateAndCreateDateInd: {
            defaultValue: policyChangeVM.value.hasLossOnOrAfterEffDate
        },
        addNewPni: {
            visible: false
        },
        excessLiabilityInd: {
            required: true,
            visible: _includes(requiredFields, 'isUmbrellaAssociated'),
        },
        swapPniSni: {
            visible: false
        },
        addSni: {
            visible: false
        },
        removeSni: {
            visible: false
        },
        applicantsResideInSameStateInd: {
            required: true,
            visible: _includes(requiredFields, 'applicantsResideInSameStateInd'),
        },
        agencyInformationMainDiv: {
            visible: opCo === 'MSA'
        },
        partnerInformationId: {
            visible: opCo === 'CONNECT',
            transactionVM: policyChangeVM,
            authHeader,
            LOB: 'personalAuto_EA',
            viewOnlyMode: true
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    const onNext = useCallback(() => policyChangeVM, [policyChangeVM]);

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

ViewInsuredDetailsPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewInsuredDetailsPage);
