import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { ActivitiesService } from 'gw-capability-gateway';
import { useModal } from '@jutro/components';
import metadata from './E1PReassignActivity.metadata.json5';
import styles from './E1PReassignActivity.module.scss';
import messages from './E1PReassignActivity.messages';

const E1PReassignActivity = ({ activity, onUpdateActivity, authHeader, activityReassigned }) => {
    const modalApi = useModal();
    const [selectedValue, setSelectedValue] = useState('');
    const [editable, setIsEditable] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const activityWrapperStyle = classNames(styles.gwReassignActivityWrapper, {
        [styles.gwReassignActivityDisplay]: activity.canReassign
    });
    const enableEditing = useCallback(() => {
        if (activity.canReassign) {
            setIsEditable(true);
        }
    }, [activity]);
    const writeValue = useCallback(
        (value) => {
            setSelectedValue(value);
        },
        [setSelectedValue]
    );
    const getAvailableAssignableUser = useCallback((activities) => (
            activities.assignableUsers
            && activities.assignableUsers.map((availableUser) => ({
                    code: availableUser,
                    name: availableUser
                }))
        ), []);
    const resetAfterReassign = useCallback(() => {
        setLoading(false);
        setSelectedValue('');
        setIsEditable(false);
    }, [setLoading, setSelectedValue, setIsEditable]);
    const assignToSelectedUser = useCallback(async () => {
        setLoading(true);
        ActivitiesService.reassignActivity(selectedValue, activity.publicID, authHeader)
            .then((response) => {
                onUpdateActivity(response);
                resetAfterReassign();
                activityReassigned(response);
            })
            .catch((error) => {
                resetAfterReassign();

                const errorMessage = _.get(error, 'baseError.data.error.message');

                if (errorMessage) {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: messages.unableToReassign,
                        message: error.baseError.data.error.message
                    });
                }
            });
    }, [
        onUpdateActivity,
        resetAfterReassign,
        selectedValue,
        authHeader,
        activity,
        activityReassigned,
        modalApi
    ]);
    const disableEditing = useCallback(() => {
        setSelectedValue('');
        setIsEditable(false);
    }, [setIsEditable, setSelectedValue]);

    const overrideProps = {
        assignableUserList: {
            availableValues: getAvailableAssignableUser(activity),
            value: selectedValue
        },
        reassignActivityWrapper: {
            visible: !editable,
            className: activityWrapperStyle
        },
        selectUserContainer: {
            visible: editable
        },
        assignedName: {
            content: _.get(activity, 'assignedTo.displayName')
        },
        reassignPageLoader: {
            loaded: !isLoading
        },
        activityReassignContainer: {
            visible: !isLoading
        },
        editIcon: {
            onClick: enableEditing
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleValueChange: writeValue,
            enableEditing,
            disableEditing,
            assignToSelectedUser,
            onClickPrevent: (e) => e.stopPropagation()
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
};

E1PReassignActivity.propTypes = {
    activity: PropTypes.shape({}).isRequired,
    onUpdateActivity: PropTypes.func,
    activityReassigned: PropTypes.func
};

export default E1PReassignActivity;
