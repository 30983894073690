/* eslint-disable prefer-regex-literals */
import React, { useMemo, useContext, useState, useEffect } from 'react';
import { get as _get, find as _find, replace as _replace } from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import htmlParser from 'html-react-parser';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import {
    ehValidationAndInfoMessages,
    eaValidationAndInfoMessages,
} from 'e1p-platform-translations';
import metadata from './ThankyouPageMessagesComponent.metadata.json5';
import styles from './ThankyouPageMessagesComponent.module.scss';
import messages from './ThankyouPageMessagesComponent.messages';

const ehLOB = 'homeowners_EH';
const eaLOB = 'personalAuto_EA';

const inspectionCodes = {
    EXTERIOR: 'Exterior',
    INTERIORANDEXTERIOR: 'InteriorAndExterior',
    INTERIOR: 'Interior',
};

function ThankyouPageMessagesComponent(props) {
    const { submissionVM, LOB } = props;

    const translator = useTranslator();
    const { opCo } = useContext(AmfamOktaTokenContext);
    const [displayMessages, setDisplayMessages] = useState([]);
    const [transactionType, setTransactionType] = useState('rewrite');
    const isNewBusiness =
        _get(submissionVM, 'baseData.value.businessTransactionType_Ext', '') ===
        'NewBusiness';

    useEffect(() => {
        if (
            _get(submissionVM, '_dtoName') ===
            'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
        ) {
            setTransactionType('policyChange');
        } else if (
            _get(submissionVM, '_dtoName') ===
            'edge.capabilities.quote.submission.dto.QuoteDataDTO'
        ) {
            setTransactionType('submission');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isMultiPolicyDiscountApplied = useMemo(() => {
        if (
            transactionType === 'submission' ||
            (transactionType === 'rewrite' && isNewBusiness)
        ) {
            const modifiers = _get(
                submissionVM,
                `lobData.${LOB}.modifiers.value`,
                []
            );
            const supportingPolicies = _get(
                submissionVM,
                `lobData.${LOB}.associatedPolicies.value`,
                []
            );
            // check if any Supporting Policy is added Manually as Promise
            const manualPolicyAddedAsPromise = !!supportingPolicies.find(
                policy => (
                        policy.policySource === 'Manual' &&
                        policy.policyStatus === 'Promise'
                    )
            );
            // check if multi product is applied on the policy
            let multiProductDiscount = false;

            if (LOB === ehLOB && manualPolicyAddedAsPromise) {
                // checking if MPD is applied or not
                multiProductDiscount = !!_find(modifiers, {
                    patternCode: 'EH_AffiliateAutoDisc',
                    applied: true,
                });

                if (multiProductDiscount) {
                    // add the message in to display messages
                    const msg = translator(
                        ehValidationAndInfoMessages.ehMultiProductDiscountMessage
                    );

                    setDisplayMessages(oldArray => [...oldArray, msg]);

                    return true;
                }
            } else if (LOB === eaLOB && manualPolicyAddedAsPromise) {
                // checking if MPD is applied or not
                multiProductDiscount = !!_find(modifiers, {
                    patternCode: 'EA_MultiProductDisc',
                    applied: true,
                });

                if (multiProductDiscount) {
                    // add the message in to display messages
                    const msg = translator(
                        messages.eaMultiProductDiscountMessage
                    );

                    setDisplayMessages(oldArray => [...oldArray, msg]);

                    return true;
                }
            }
        }

        return false;
    }, [LOB, isNewBusiness, submissionVM, transactionType, translator]);

    const isEHInspectionExteriorOrInterior = useMemo(() => {
        if (LOB === ehLOB) {
            const inspectionType = _get(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.inspectionDetails.inspectionType.value'
            );

            return [
                inspectionCodes.INTERIORANDEXTERIOR,
                inspectionCodes.INTERIOR,
            ].includes(inspectionType?.code);
        }

        return false;
    }, [LOB, submissionVM]);

    const isEHInspectionExterior = useMemo(() => {
        if (LOB === ehLOB) {
            const inspectionType = _get(
                submissionVM,
                'lobData.homeowners_EH.coverables.yourHome.inspectionDetails.inspectionType.value'
            );

            if (inspectionType?.code === inspectionCodes.EXTERIOR) {
                return true;
            }
        }

        return false;
    }, [LOB, submissionVM]);

    const isPaperlessApplied = useMemo(() => {
        if (
            transactionType === 'policyChange' ||
            transactionType === 'submission'
        ) {
            const paperlessApplied = _get(
                submissionVM,
                `lobData.${LOB}.paperlessInd.value`,
                false
            );
            let msg = paperlessApplied
                ? translator(messages.paperlessIndicatorYes)
                : translator(messages.paperlessIndicatorNo);

            msg = _replace(msg, new RegExp('<br>', 'g'), '\n');
            msg = _replace(msg, new RegExp('&nbsp;', 'g'), ' ');
            setDisplayMessages(oldArray => [...oldArray, msg]);

            return paperlessApplied;
        }

        return false;
    }, [LOB, submissionVM, transactionType, translator]);

    const licenseDiffersFromPolicyState = useMemo(() => {
        if (
            (transactionType === 'policyChange' ||
                transactionType === 'submission') &&
            LOB === eaLOB
        ) {
            // true if all drivers license states match policy rate state
            const policyState = _get(
                submissionVM,
                'baseData.policyAddress.state.value.code'
            );
            const drivers = _get(
                submissionVM,
                'lobData.personalAuto_EA.coverables.drivers.value'
            );

            const licenseDiffers = _find(drivers, driver => driver.person.licenseState !== policyState);

            if (licenseDiffers) {
                // add the message in to display messages
                const msg = translator(messages.driversLicenseInStateMessage);

                setDisplayMessages(oldArray => [...oldArray, msg]);

                return true;
            }
        }

        return false;
    }, [LOB, submissionVM, transactionType, translator]);

    const accidentForgivenessApplied = useMemo(() => {
        if (transactionType === 'submission' && LOB === eaLOB) {
            // checkif accident free is applied on the policy
            const accidentFreeDiscount =
                _get(
                    submissionVM,
                    'lobData.personalAuto_EA.accidentForgivenessStatuses.children.length'
                ) !== 0 &&
                _get(
                    submissionVM,
                    'lobData.personalAuto_EA.accidentForgivenessStatuses.children[0].code.value'
                ) === 'Earned Accident Forgiveness';

            if (accidentFreeDiscount) {
                // add the message in to display messages
                const msg = translator(
                    eaValidationAndInfoMessages.accidentForgivenessMessage
                );

                setDisplayMessages(oldArray => [...oldArray, msg]);

                return true;
            }
        }

        return false;
    }, [LOB, submissionVM, transactionType, translator]);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false,
        },
        messagesMainDiv: {
            visible: displayMessages.length > 0,
        },
        ehMultiProductDiscountMessage: {
            message: htmlParser(
                translator(
                    ehValidationAndInfoMessages.ehMultiProductDiscountMessage
                )?.replace('\n', '<br>')
            ),
        },
        eaMultiProductDiscountMessage: {
            message: htmlParser(
                translator(messages.eaMultiProductDiscountMessage)?.replace(
                    '\n',
                    '<br>'
                )
            ),
        },
        paperlessIndicatorYes: {
            message:
                opCo === 'MSA'
                    ? htmlParser(
                        translator(messages.paperlessIndicatorYes, {
                            partner: 'Main Street America Insurance',
                        }).replace('\n', '<br>')
                    )
                    : htmlParser(
                        translator(messages.paperlessIndicatorYes, {
                            partner: 'CONNECT',
                        }).replace('\n', '<br>')
                    ),
        },
        paperlessIndicatorNo: {
            message:
                opCo === 'MSA'
                    ? htmlParser(
                        translator(messages.paperlessIndicatorNo, {
                            partner: 'Main Street America Insurance',
                        }).replace('\n', '<br>')
                    )
                    : htmlParser(
                        translator(messages.paperlessIndicatorNo, {
                            partner: 'CONNECT',
                        }).replace('\n', '<br>')
                    ),
        },
        inspectionExterior: {
            message: htmlParser(
                translator(ehValidationAndInfoMessages.inspectionExterior)
            ),
        },
        inspectionInteriorExterior: {
            message: htmlParser(
                translator(
                    ehValidationAndInfoMessages.inspectionInteriorExterior
                ).replace('\n', '<br>')
            ),
        },
        driversLicenseInStateMessage: {
            message: htmlParser(
                translator(messages.driversLicenseInStateMessage)
            ),
        },
        accidentForgivenessMessage: {
            message: htmlParser(
                translator(
                    eaValidationAndInfoMessages.accidentForgivenessMessage
                )
            ),
        },
        ehMultiProductDiscountMessageDiv: {
            visible: ehLOB === LOB && isMultiPolicyDiscountApplied
        },
        eaMultiProductDiscountMessageDiv: {
            visible: eaLOB === LOB && isMultiPolicyDiscountApplied
        },
        paperlessIndicatorYesDiv: {
            visible: isPaperlessApplied
        },
        paperlessIndicatorNoDiv: {
            visible: !isPaperlessApplied
        },
        inspectionExteriorDiv: {
            visible: isEHInspectionExterior
        },
        inspectionInteriorExteriorDiv: {
            visible: isEHInspectionExteriorOrInterior
        },
        driversLicenseInStateMessageDiv: {
            visible: licenseDiffersFromPolicyState
        },
        accidentForgivenessMessageDiv: {
            visible: accidentForgivenessApplied
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ThankyouPageMessagesComponent.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
    }).isRequired,
    LOB: PropTypes.string.isRequired,
};

export default ThankyouPageMessagesComponent;
