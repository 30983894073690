import React from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { Grid, GridItem } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import messages from './FeesAndOtherChargesComponent.messages';
import styles from './FeesAndOtherChargesComponent.module.scss';


function FeesAndOtherChargesComponent() {
    const translator = useTranslator();

    return (
        <Grid
                columns={[
                    '1fr'
                ]}
                gap="small"
                id="Grid"
                rows={[
                    '1fr',
                    '1fr',
                    '1fr',
                    '1fr'
                ]}
                tag="div"
                className={styles.modifierTableClass}
            >
                <GridItem
                    className={styles.feesAndChargesTitle}
                    tag="div"
                >
                    {translator(messages.feesAndChargesTitle)}
                </GridItem>
                <GridItem
                    tag="div"
                >
                    {translator(messages.paymentDueDate)}
                </GridItem>
                <GridItem
                    tag="div"
                >
                    {translator(messages.returnPayment)}
                </GridItem>
                <GridItem
                    tag="div"
                >
                    {translator(messages.autoPayments)}
                </GridItem>
            </Grid>
    );
}

FeesAndOtherChargesComponent.propTypes = {
    fromAccountLanding: PropTypes.shape({
        policyNumber: PropTypes.string
    }).isRequired,
    policyDetailsData: PropTypes.shape({
        policyResponse: PropTypes.shape({})
    }).isRequired,
    authHeader: PropTypes.shape({}).isRequired,
    redirectToAnotherTile: PropTypes.func.isRequired
};

export default withAuthenticationContext(FeesAndOtherChargesComponent);
