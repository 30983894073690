import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter } from '@jutro/components';
import htmlParser from 'html-react-parser';
import { commonMessages } from 'e1p-platform-translations';
import metadata from './PersonalInjuryProtectionComponent.metadata.json5';
import styles from './PersonalInjuryProtectionComponent.module.scss';
import messages from './PersonalInjuryProtectionComponent.messages';

import { Button } from '@jutro/legacy/components';

function PersonalInjuryProtectionComponent(props) {
    const {
        isOpen,
        onReject
    } = props;

    const coverageDifferenceArray = useMemo(() => [
            {
                coverageName: 'Medical Expenses (per person)', valueA: '$3,000', valueB: '$5,000', valueC: '$10,000', valueD: '$25,000', valueE: '$100,000'
            },
            {
                coverageName: 'Work Loss (per week, 52 week maximum)', valueA: '85% of gross income up to $250', valueB: '$250', valueC: '$250', valueD: '$250', valueE: '$250'
            },
            {
                coverageName: 'Essential Services Expenses (per day, 365 days maximum)', valueA: '$20', valueB: '$20', valueC: '$20', valueD: '$20', valueE: '$20'
            },
            {
                coverageName: 'Funeral Expenses (per person)', valueA: '$1,500', valueB: '$1,500', valueC: '$1,500', valueD: '$1,500', valueE: '$1,500'
            },
            {
                coverageName: 'Survivors Loss', valueA: '$3,000', valueB: '$3,000', valueC: '$3,000', valueD: '$3,000', valueE: '$3,000'
            }
        ], []);

    const getCell = (items, index, property) => htmlParser(items[property.id]);

    const overrideProps = {
        coverageDifferenceTable: {
            data: coverageDifferenceArray,
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getCell
        }
    };

    const onCancel = useCallback(
        () => {
            onReject();
        }, [onReject]
    );

    return (
        <ModalNext isOpen={isOpen} className={styles.popUp}>
            <ModalHeader title={messages.personalInjuryProtection} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onCancel} type="filled" className="closeButton">
                    {commonMessages.close}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

PersonalInjuryProtectionComponent.propTypes = {
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default PersonalInjuryProtectionComponent;
