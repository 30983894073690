import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextPageMessage: {
        id: 'endorsement.ea.directives.templates.ea-edit-coverage.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    eaCoverages: {
        id: 'endorsement.ea.directives.templates.ea-edit-coverage.Coverages',
        defaultMessage: 'Coverages'
    },
    lineCoverages: {
        id: 'endorsement.ea.directives.templates.ea-edit-coverage.Line Coverages',
        defaultMessage: 'General Coverages (Apply to all vehicles)'
    },
    vehicleCoverages: {
        id: 'endorsement.ea.directives.templates.ea-edit-coverage.Vehicle Coverages',
        defaultMessage: 'Vehicle Coverages'
    },
    saveCoverageError: {
        id: 'endorsement.ea.config.Unable to save coverages change',
        defaultMessage: 'Unable to save coverages change'
    },
    saveCoverageErrorMessage: {
        id: 'endorsement.ea.config.An error occurred while attempting to save coverage changes',
        defaultMessage: 'An error occurred while attempting to save coverage changes'
    },
});
