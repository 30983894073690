import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('contact'), method, data, additionalHeaders);
}

export default class getAccountContacts {
    static getAccountContacts(signatureRequestDTO, additionalHeaders = {}) {
        return processSubmission('getAccountContacts', [signatureRequestDTO], additionalHeaders);
    }

    static searchContacts(contactSearchRequest, additionalHeaders = {}) {
        return processSubmission('searchContacts', [contactSearchRequest], additionalHeaders);
    }
}
