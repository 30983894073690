import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import { WizardPageTemplateWithTitle } from 'e1p-portals-wizard-react';
import E1PPolicyViewEHInsuredPage from './pages/eh/common/PolicyViewInsuredPage/InsuredPage';
import E1PPolicyViewEHHomeownersPropertyPage from './pages/eh/HO3/PolicyViewPropertyPage/PropertyPage';
import E1PPolicyViewEHRentersPropertyPage from './pages/eh/HO4/PolicyViewPropertyPage/PropertyPage';
import E1PPolicyViewEHSeasonalPropertyPage from './pages/eh/HF9/PolicyViewPropertyPage/PropertyPage';
import E1PPolicyViewEHCondominiumPropertyPage from './pages/eh/HO6/PolicyViewPropertyPage/PropertyPage';
import E1PPolicyViewEHCommonQuotePage from './pages/eh/common/PolicyViewQuotePage/QuotePage';
import E1PPolicyViewEHCommonThirdPartyInterestPage from './pages/eh/common/PolicyViewThirdPartyInterestPage/ThirdPartyInterestPage';
import E1PPolicyViewEAInsuredPage from './pages/ea/PolicyViewInsuredPage/InsuredPage';
import E1PPolicyViewEADriverPage from './pages/ea/PolicyViewDriverPage/DriversPage';
import E1PPolicyViewEAVehiclePage from './pages/ea/PolicyViewVehiclePage/VehiclesPage';
import E1PPolicyViewEACoveragePage from './pages/ea/PolicyViewQuotePage/QuotePage';
import E1PPolicyViewEAThirdPartyInterestPage from './pages/ea/PolicyViewThirdPartyInterestPage/ThirdPartyInterestPage';
import E1PPolicyViewEUInsuredPage from './pages/eu/PolicyViewInsuredPage/InsuredPage';
import E1PPolicyViewEUQualificationPage from './pages/eu/PolicyViewQualificationPage/EUPreQualificationPage';
import E1PPolicyViewEUUnderlyingPolicyPage from './pages/eu/PolicyViewUnderlyingPolicyPage/UnderlyingPolicyPage';
import E1PPolicyViewEUVehicleOperatorPage from './pages/eu/PolicyViewVehicleOperatorPage/EUVehicleOperatorPage';
import E1PPolicyViewEUCoveragePage from './pages/eu/PolicyViewQuotePage/QuotePage';
import E1PPolicyViewEUThirdPartyInterestPage from './pages/eu/PolicyViewThirdPartyInterestPage/ThirdPartyInterestPage';
import E1PPolicyViewDriverAssignmentPage from './pages/ea/PolicyViewDriverAssignmentPage/PolicyViewDriverAssignmentPage';

setComponentMapOverrides(
    {
        WizardPageTemplateWithTitle: { component: 'WizardPageTemplateWithTitle' },
        E1PPolicyViewEHInsuredPage: { component: 'E1PPolicyViewEHInsuredPage' },
        E1PPolicyViewEHHomeownersPropertyPage: { component: 'E1PPolicyViewEHHomeownersPropertyPage' },
        E1PPolicyViewEHCommonQuotePage: { component: 'E1PPolicyViewEHCommonQuotePage' },
        E1PPolicyViewEHCommonThirdPartyInterestPage: { component: 'E1PPolicyViewEHCommonThirdPartyInterestPage' },
        E1PPolicyViewEHRentersPropertyPage: { component: 'E1PPolicyViewEHRentersPropertyPage' },
        E1PPolicyViewEHSeasonalPropertyPage: { component: 'E1PPolicyViewEHSeasonalPropertyPage' },
        E1PPolicyViewEHCondominiumPropertyPage: { component: 'E1PPolicyViewEHCondominiumPropertyPage' },
        E1PPolicyViewEAInsuredPage: { component: 'E1PPolicyViewEAInsuredPage' },
        E1PPolicyViewEADriverPage: { component: 'E1PPolicyViewEADriverPage' },
        E1PPolicyViewEAVehiclePage: { component: 'E1PPolicyViewEAVehiclePage' },
        E1PPolicyViewEACoveragePage: { component: 'E1PPolicyViewEACoveragePage' },
        E1PPolicyViewEAThirdPartyInterestPage: { component: 'E1PPolicyViewEAThirdPartyInterestPage' },
        E1PPolicyViewEUInsuredPage: { component: 'E1PPolicyViewEUInsuredPage' },
        E1PPolicyViewEUQualificationPage: { component: 'E1PPolicyViewEUQualificationPage' },
        E1PPolicyViewEUUnderlyingPolicyPage: { component: 'E1PPolicyViewEUUnderlyingPolicyPage' },
        E1PPolicyViewEUVehicleOperatorPage: { component: 'E1PPolicyViewEUVehicleOperatorPage' },
        E1PPolicyViewEUCoveragePage: { component: 'E1PPolicyViewEUCoveragePage' },
        E1PPolicyViewEUThirdPartyInterestPage: { component: 'E1PPolicyViewEUThirdPartyInterestPage' },
        E1PPolicyViewDriverAssignmentPage: {component: 'E1PPolicyViewDriverAssignmentPage'}
    },
    {
        WizardPageTemplateWithTitle,
        E1PPolicyViewEHInsuredPage,
        E1PPolicyViewEHHomeownersPropertyPage,
        E1PPolicyViewEHCommonQuotePage,
        E1PPolicyViewEHCommonThirdPartyInterestPage,
        E1PPolicyViewEHRentersPropertyPage,
        E1PPolicyViewEHSeasonalPropertyPage,
        E1PPolicyViewEHCondominiumPropertyPage,
        E1PPolicyViewEAInsuredPage,
        E1PPolicyViewEADriverPage,
        E1PPolicyViewEAVehiclePage,
        E1PPolicyViewEACoveragePage,
        E1PPolicyViewEAThirdPartyInterestPage,
        E1PPolicyViewEUInsuredPage,
        E1PPolicyViewEUQualificationPage,
        E1PPolicyViewEUUnderlyingPolicyPage,
        E1PPolicyViewEUVehicleOperatorPage,
        E1PPolicyViewEUCoveragePage,
        E1PPolicyViewEUThirdPartyInterestPage,
        E1PPolicyViewDriverAssignmentPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PEPolicyViewCommonWizard } from './PEPolicyViewCommonWizard';
