import { defineMessages } from 'react-intl';

export default defineMessages({
    backToCoverages: {
        id: 'policyjob.SideBySideActionBarComponent.component.Back To Coverages',
        defaultMessage: 'Back To Coverages'
    },
    viewQuoteProposal: {
        id: 'policyjob.SideBySideActionBarComponent.component.View Quote Proposal',
        defaultMessage: 'View Quote Proposal'
    },
    addQuote: {
        id: 'policyjob.SideBySideActionBarComponent.component.Add Quote',
        defaultMessage: 'Add Quote'
    },
    recalculate: {
        id: 'policyjob.SideBySideActionBarComponent.component.Recalculate',
        defaultMessage: 'Recalculate'
    },
    quoteDetailsTitle: {
        id: 'policyjob.SideBySideActionBarComponent.component.Side-by-Side Quote Details',
        defaultMessage: 'Side-by-Side Quote Details'
    }
});
