import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'gw-capability-policychange-common-react';
import PCEHInsuredDetailsPage from './pages/InsuredDetails/InsuredDetailsPage';
import PCEHViewInsuredDetailsPage from './pages/InsuredDetails/viewOnly/ViewInsuredDetailsPage';
import PCEHHomeownersPropertyPage from './pages/HO3/Property/PropertyPage';
import PCEHViewHomeownersPropertyPage from './pages/HO3/Property/viewOnly/ViewPropertyPage';
import PCEHRentersPropertyPage from './pages/HO4/Property/PropertyPage';
import PCEHViewRentersPropertyPage from './pages/HO4/Property/viewOnly/ViewPropertyPage';
import PCEHCondoPropertyPage from './pages/HO6/Property/PropertyPage';
import PCEHViewCondoPropertyPage from './pages/HO6/Property/viewOnly/ViewPropertyPage';
import PCEHSeasonalPropertyPage from './pages/HF9/Property/PropertyPage';
import PCEHViewSeasonalPropertyPage from './pages/HF9/Property/viewOnly/ViewPropertyPage';
import PCEHRiskAnalysisPage from './pages/common/RiskAnalysis/RiskAnalysisPage';
import PCEHViewRiskAnalysisPage from './pages/common/RiskAnalysis/viewOnly/ViewRiskAnalysisPage';
import PCEHCoveragePage from './pages/Coverages/CoveragesPage';
import PCEHViewCoveragePage from './pages/Coverages/viewOnly/ViewCoveragesPage';
import PCEHPolicyChangeDiffPage from './pages/PolicyChangeDiff/PolicyChangeDiffPage';
import PCEHViewPolicyChangeDiffPage from './pages/PolicyChangeDiff/viewOnly/ViewPolicyChangeDiffPage';
import PCEHConfirmationPage from './pages/Confirmation/ConfirmationPage';
import PCEHSingleClauseComponentVM from './components/Clauses/SingleClauseComponentVM';
import PCEHClauseComponent from './components/Clauses/ClauseComponent';
import PCEHClausesComponentVM from './components/Clauses/ClausesComponentVM';
import PCEHOptionalCoveragesPopup from './components/OptionalCoveragesPopup/OptionalCoveragesPopup';
import PCEHStartChangePage from './pages/StartChange/StartChangePage';
import PCEHViewStartChangePage from './pages/StartChange/viewOnly/ViewStartChangePage';
import PCEHThirdPartyInterestPage from './pages/ThirdPartyInterest/ThirdPartyInterestPage';
import PCEHPremiumBoxComponent from './components/PCEHPremiumBoxComponent/PCEHPremiumBoxComponent';
import PCEHViewThirdPartyInterestPage from './pages/ThirdPartyInterest/viewOnly/ViewThirdPartyInterestPage';
import PCEHThankYouPage from './pages/ThankYouPage/ThankYouPage';

setComponentMapOverrides(
    {
        PCEHInsuredDetailsPage: { component: 'PCEHInsuredDetailsPage' },
        PCEHPropertyPage: { component: 'PCEHHomeownersPropertyPage' },
        PCEHRentersPropertyPage: { component: 'PCEHRentersPropertyPage' },
        PCEHSeasonalPropertyPage: { component: 'PCEHSeasonalPropertyPage' },
        PCEHCondoPropertyPage: { component: 'PCEHCondoPropertyPage' },
        PCEHCoveragePage: { component: 'PCEHCoveragePage' },
        PCEHThirdPartyInterestPage: { component: 'PCEHThirdPartyInterestPage' },
        PCEHPolicyChangeDiffPage: { component: 'PCEHPolicyChangeDiffPage' },
        PCEHConfirmationPage: { component: 'PCEHConfirmationPage' },
        PCEHSingleClauseComponentVM: { component: 'PCEHSingleClauseComponentVM' },
        PCEHClauseComponent: { component: 'PCEHClauseComponent' },
        PCEHClausesComponentVM: { component: 'PCEHClausesComponentVM' },
        PCEHOptionalCoveragesPopup: { component: 'PCEHOptionalCoveragesPopup' },
        PCEHStartChangePage: { component: 'PCEHStartChangePage' },
        PCEHRiskAnalysisPage: { component: 'PCEHRiskAnalysisPage' },
        PCEHPremiumBoxComponent: { component: 'PCEHPremiumBoxComponent' },
        PCEHViewStartChangePage: { component: 'PCEHViewStartChangePage' },
        PCEHViewInsuredDetailsPage: { component: 'PCEHViewInsuredDetailsPage' },
        PCEHViewRiskAnalysisPage: { component: 'PCEHViewRiskAnalysisPage' },
        PCEHViewThirdPartyInterestPage: { component: 'PCEHViewThirdPartyInterestPage' },
        PCEHViewPolicyChangeDiffPage: { component: 'PCEHViewPolicyChangeDiffPage' },
        PCEHViewCoveragePage: { component: 'PCEHViewCoveragePage' },
        PCEHViewHomeownersPropertyPage: { component: 'PCEHViewHomeownersPropertyPage' },
        PCEHViewRentersPropertyPage: { component: 'PCEHViewRentersPropertyPage' },
        PCEHViewCondoPropertyPage: { component: 'PCEHViewCondoPropertyPage' },
        PCEHViewSeasonalPropertyPage: { component: 'PCEHViewSeasonalPropertyPage' },
        PCEHThankYouPage: { component: 'PCEHThankYouPage' }
    },
    {
        PCEHInsuredDetailsPage,
        PCEHHomeownersPropertyPage,
        PCEHCoveragePage,
        PCEHThirdPartyInterestPage,
        PCEHRentersPropertyPage,
        PCEHCondoPropertyPage,
        PCEHSeasonalPropertyPage,
        PCEHPolicyChangeDiffPage,
        PCEHConfirmationPage,
        PCEHSingleClauseComponentVM,
        PCEHClauseComponent,
        PCEHClausesComponentVM,
        PCEHOptionalCoveragesPopup,
        PCEHStartChangePage,
        PCEHRiskAnalysisPage,
        PCEHPremiumBoxComponent,
        PCEHViewStartChangePage,
        PCEHViewInsuredDetailsPage,
        PCEHViewRiskAnalysisPage,
        PCEHViewThirdPartyInterestPage,
        PCEHViewPolicyChangeDiffPage,
        PCEHViewCoveragePage,
        PCEHViewHomeownersPropertyPage,
        PCEHViewRentersPropertyPage,
        PCEHViewCondoPropertyPage,
        PCEHViewSeasonalPropertyPage,
        PCEHThankYouPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as EHPolicyChangeWizard } from './EHPolicyChangeWizard';
export {
    PCEHOptionalCoveragesPopup as OptionalCoveragesPopup
};
