import { defineMessages } from 'react-intl';

export default defineMessages({
    addMilitaryDeploymentSubHeader: {
        id: 'military-Deployment.Military personnel returning from active duty',
        defaultMessage: 'Military personnel returning from active duty'
    },
    deploymentDate: {
        id: 'military-Deployment.Deployment Date',
        defaultMessage: 'Deployment Date'
    },
    returnDate: {
        id: 'military-Deployment.Return Date',
        defaultMessage: 'Return Date'
    },
    updateButton: {
        id: 'military-Deployment.Update',
        defaultMessage: 'Update'
    },
    cancelButton: {
        id: 'military-Deployment.Cancel',
        defaultMessage: 'Cancel'
    },
    closeButton: {
        id: 'military-Deployment.Close',
        defaultMessage: 'Close'
    },
    saveButton: {
        id: 'military-Deployment.Save',
        defaultMessage: 'Save'
    }
});