import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get as _get,
    set as _set
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import {
    useNewBusinessUtil, useViewPolicyUtil
} from 'e1p-capability-hooks';
import messages from '../RewriteEHPolicyDetailsPage.messages';
import metadata from '../RewriteEHPolicyDetailsPage.metadata.json5';

const LOB = 'homeowners_EH';

function InsuredPage(props) {
    const {
        wizardData: rewriteVM,
        updateWizardData,
        authUserData,
        steps,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        markFurthestStep,
        viewOnly
    } = props;
    const translator = useTranslator();
    const [lapseInCoverage, updateLapseInCoverage] = useState(undefined);
    const [isThisNewHomePurchase, updateIsThisNewHomePurchase] = useState(undefined);
    const { authHeader } = useAuthentication();
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const viewModelService = useContext(ViewModelServiceContext);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const policyType = _get(rewriteVM, 'lobData.homeowners_EH.policyType.value.code');
    const usageType = translator({ id: _get(rewriteVM, 'lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value.name') });
    const policyForm = _get(rewriteVM, 'lobData.homeowners_EH.policyFormType.value');
    const {
        getLocationAndProducerCodes
    } = useNewBusinessUtil(
        rewriteVM,
        updateWizardData,
        viewModelService,
        LOB,
        true,
        authHeader,
        () => { }
    );

    const {
        getLandingPageIndexForViewOnlyPolicy,
        getProducerDetails
    } = useViewPolicyUtil();

    const getAndSetProducerCodes = async (location) => {
        const foundProducerCodes = await getLocationAndProducerCodes(location);

        // If only one code, set it as selected
        if (foundProducerCodes.length === 1) {
            _set(rewriteVM, 'baseData.producerCode_Ext', foundProducerCodes[0].code);
        }
    };

    useEffect(() => {
        if (rewriteVM.baseData.externalID_Ext?.value) {
            getProducerDetails(
                rewriteVM.baseData.externalID_Ext.value, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
                setAgencyName(details.agencyName);
                setBookTransferIndicator(details.bookTransferIndicator);
                setServiceCenterIndicator(details.serviceCenterIndicatorValue);
            }).catch((error) => {
                /**
                 * E1PAP1PC-15099 :
                 * This is rare scenario; will never happen in production
                 * When we bound policy using dummy producer code
                 * and when other user tries to view this policy we get exception from service
                 */
                if (rewriteVM.baseData.value.exceptions_Ext) {
                    rewriteVM.baseData.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        rewriteVM.value,
                        `baseData.exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(rewriteVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rewriteVM.baseData.externalID_Ext.value]);

    useEffect(() => {
        // in view only flow we want to make each step as visited so that user
        // can navigate to any page by clicking on tabs on chevron
        if (isPageJumpEnabled) {
            updateIsPageJumpEnabled(false);
            jumpTo(getLandingPageIndexForViewOnlyPolicy(LOB, steps, 'Submission'), true);
        }

        markFurthestStep((steps.length - 1));

        if (isAgent) {
            getAndSetProducerCodes(_get(rewriteVM, 'baseData.policyAddress.state.value.code'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            _set(rewriteVM, 'baseData.producerCode_Ext', producerCode);
            _set(rewriteVM, 'baseData.externalID_Ext.value', externalID);
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [rewriteVM],
    );

    useEffect(() => {
        if (
            rewriteVM.lobData.homeowners_EH.priorPolicies.value !== undefined
            && rewriteVM.lobData.homeowners_EH.priorPolicies.length > 0
        ) {
            updateLapseInCoverage(
                !rewriteVM.lobData.homeowners_EH.priorPolicies.value[0].continuousCoverageInd
            );
        }

        if (rewriteVM.lobData.homeowners_EH.coverables.yourHome !== undefined) {
            updateIsThisNewHomePurchase(
                rewriteVM.lobData.homeowners_EH.coverables.yourHome.isThisNewHomePurchase.value
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateOverrides = useCallback(() => {
        const priorAddr = _get(
            rewriteVM,
            'lobData.homeowners_EH.primaryNamedInsured.priorAddresses.value',
            []
        );
        const overrideProps = {};
        const initialRequiredFields = ['pAdressLine1', 'pPostalCode', 'pCity', 'pState'];

        priorAddr.forEach((priorAddress, index) => {
            let priorAddressTitle = translator(messages.ehPriorAddressTitle);

            priorAddressTitle = `${translator(messages.ehPriorAddressTitle)} ${index + 1}`;
            overrideProps[`ehPriorAddressTitle${index}`] = {
                value: priorAddressTitle
            };
            overrideProps[`priorAddress${index}`] = {
                submissionVM: rewriteVM,
                updateWizardData,
                requiredFields: initialRequiredFields,
                viewOnlyMode: true
            };
        });
        overrideProps.secondaryNamedInsured = {
            viewOnlyMode: true
        };
        overrideProps.ehAddPriorAddressButton = {
            visible: false
        };

        return overrideProps;
    }, [rewriteVM, translator, updateWizardData]);


    const onNext = useCallback(() => rewriteVM, [rewriteVM]);

    const resolvers = {
        resolveCallbackMap: {
            onAddPriorAddress: undefined,
            onRemovePriorAddress: undefined,
            onAddSni: undefined,
            onValidate: undefined,
            onRemoveSni: undefined,
            disclosureLinkHandler: undefined,
            disclosureCheckHandler: undefined,
            guidelinesLinkHandler: undefined,
            guidelinesCheckHandler: undefined,
            legalDisclosureCheckHandler: undefined,
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: viewOnly
        },
        policyDetailsPageLoadingIndicator: {
            loaded: true
        },
        effectiveDateValidationMessage: {
            visible: false
        },
        policyDetailsPageContainer: {
            visible: true
        },
        secNamedInsuredContainer: {
            visible: !!_get(rewriteVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        isThisNewHomePurchase: {
            value: isThisNewHomePurchase,
            onValueChange: () => {} 
        },
        addSni: {
            visible: false
        },
        lastPolicyExpirationDate: {
            // HO4 will not have prior policies, so implement check for undefined
            dateDTO: rewriteVM.lobData.homeowners_EH
                .priorPolicies.children[0]?.policyExpirationDate,
        },
        coverageStartDate: {
            dateDTO: rewriteVM.baseData.periodStartDate,
            defaultToToday: true,
            showErrors: true
        },
        coverageEndDate: {
            dateDTO: rewriteVM.baseData.periodEndDate,
            updateDateDto: () => updateWizardData(rewriteVM)
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        insuranceHistoryMainDiv: {
            visible: policyType !== 'HO4'
        },
        coverageLapseAndExpirationDate: {
            visible: policyType !== 'HO4'
        },
        coverageLapse: {
            value: lapseInCoverage
        },
        usageType: {
            value: usageType,
            visible: policyType !== 'HO4'
        },
        mailingAndBillingAddressComponent: {
            transactionVM: rewriteVM,
            viewOnlyMode: true,
            lob: LOB
        },
        e1pAcknowledgementComponent: {
            acknowledgements: _get(rewriteVM, `lobData[${LOB}].acknowledgements`),
            policyState: {
                code: _get(rewriteVM, 'baseData.policyAddress.state.value.code'),
                name: translator({ id: _get(rewriteVM, 'baseData.policyAddress.state.value.name') })
            },
            policyType,
            viewOnly: true,
            lob: LOB
        },
        policyForm: {
            visible: usageType !== undefined || policyType === 'HO4',
            value: policyForm
        },
        insuredResidenceAddress: {
            addressVM: _get(rewriteVM, 'baseData.policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            viewOnlyMode: true
        },
        riskAddressComponent: {
            submissionVM: rewriteVM,
            updateWizardData,
            viewOnlyMode: true,
            viewModelService,
        },
        ehPriorAddressComponent: {
            submissionVM: rewriteVM,
            updateWizardData,
            viewModelService,
            visible: policyType === 'HO3' || policyType === 'HF9',
            viewOnlyMode: true,
            LOB
        },
        primaryNamedInsured: {
            viewOnlyMode: true,
            isPNI: true
        },
        removeSni: {
            visible: false
        },
        ExternalProducerDetailsComponent: {
            viewOnlyMode: true,
            visible: !isAgent,
            defaultValue: rewriteVM.baseData.externalID_Ext?.value
        },
        locationCode: {
            visible: isAgent
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        changePniComponent: {
            visible: false
        },
        changeSniComponent: {
            visible: false
        },
        swapPniSni: {
            visible: false
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, rewriteVM, id, path, overrideProps),
        [overrideProps, rewriteVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
            showPrevious={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

InsuredPage.propTypes = wizardProps;

export default withAuthenticationContext(InsuredPage);
