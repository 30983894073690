import React, {
    useCallback, useState, useEffect, useMemo
} from 'react';
import { get, isEmpty } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import metadata from './EUPreQualificationPage.metadata.json5';

function EUPreQualificationPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        isSkipping,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();
    const { initialValidation, isComponentValid, onValidate, registerInitialComponentValidation } = useValidation(
        'EUPreQualificationPage'
    );
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [submissionVM, isComponentValid, isPageSubmitted]);

    useEffect(()=>{
        // stop user on pre-qualification screen if pre-qualification questions are not answered
        registerInitialComponentValidation(()=> get(submissionVM, 'lobData.personalUmbrella_EU.resRelOwnLiveStockInd.value', undefined) !== undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setIsSavingEndorsement(true);
        submissionVM.value = await LoadSaveService.updateDraftSubmission(
            submissionVM.value,
            authHeader
        );
        setIsSavingEndorsement(false);

        return submissionVM;
    }, [LoadSaveService, authHeader, isComponentValid, submissionVM]);

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(submissionVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(submissionVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(submissionVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, submissionVM, updateWizardSnapshot]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isSavingEndorsement && !isSkipping && !isSavingCurrentPageChanges,[isSavingCurrentPageChanges, isSavingEndorsement, isSkipping]);

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        preQualificationPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(e1pCommonMessages.loadingNextPage)
        },
        preQualificationPageContainer: {
            visible: isPageLoaded
        },
        euPreQualificationComponent: {
            data: submissionVM,
            updateWizardData,
            showErrors: isPageSubmitted
        }
    };

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

EUPreQualificationPage.propTypes = wizardProps;
export default withRouter(EUPreQualificationPage);
