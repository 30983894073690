import { defineMessages } from 'react-intl';

export default defineMessages({
    creatingYourOfferingMessage: {
        id: 'policyview.eu.directives.MultiQuoteView.Please wait while we create your offering...',
        defaultMessage: 'Please wait while we create your offering...'
    },
    policyLevelCoverages: {
        id: 'policyview.eu.directives.MultiQuoteView.Policy Level Coverages',
        defaultMessage: 'Policy Level Coverages'
    }
});
