import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import SingleClauseComponentVM from './SingleClauseComponentVM';
import messages from './ClauseComponentVM.messages';
import styles from './EHClauseComponent.module.scss';

function getPath(path, changedValuePath) {
    // onBlur event returns an object instead of path as a String
    const pathToNormalise = _.isObject(changedValuePath)
        ? changedValuePath.model : changedValuePath;

    const normalisePath = pathToNormalise.replace(/\[/g, '.children[');
    const basePath = path.replace(/\.value/, '');

    return `${basePath}${normalisePath}`;
}

export default class ClausesComponentVM extends Component {
    /**
     * @memberof gw-components-platform-react.ClausesComponentVM
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {array} propTypes.value - is the clause is seleceted
     * @prop {bool} propTypes.splitByClauseCategory - if clauses should be split by coverageCategory
     * @prop {string} propTypes.categoryDisplayName - title if splitByClauseCategory is false
     * @prop {string} propTypes.isEditable - if the clauses should not be editable
     */

    static propTypes = {
        ...SingleClauseComponentVM.propTypes,
        path: PropTypes.string.isRequired,
        value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        splitByClauseCategory: PropTypes.bool,
        categoryDisplayName: PropTypes.string,
        labelPosition: PropTypes.string,
        isEditable: PropTypes.bool,
        loadingClauseMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        uiGroup: PropTypes.string,
        isExclusionOrCondition: PropTypes.bool,
        hasGoldOrPlatinumBoxVisible: PropTypes.bool,
        policyState: PropTypes.string,
        isQuoteStale: PropTypes.bool
    };

    static defaultProps = {
        splitByClauseCategory: false,
        categoryDisplayName: undefined,
        labelPosition: 'left',
        isEditable: true,
        loadingClauseMessage: {
            id: 'policycommon.directives.coverages.Syncing coverage dependencies…',
            defaultMessage: 'Syncing coverage dependencies…'
        },
        uiGroup: '',
        isExclusionOrCondition: false,
        isQuoteStale: false
    };

    static contextType = TranslatorContext;

    toMetadata = (clausesCategory) => {
        const {
            value: clauses,
            path,
            labelPosition,
            loadingClauseMessage,
            isMandatoryCoverages,
            schedulesMap,
            viewOnlyMode,
            policyType,
            replacementCost,
            steps,
            jumpTo,
            hasGoldOrPlatinumBoxVisible,
            policyState,
            uiGroup,
            isQuoteStale
        } = this.props;
        const translator = this.context;

        return clausesCategory.map((clause) => {
            const index = clauses.findIndex((element) => element.publicID === clause.publicID);
            const clausePath = `[${index}]`;

            return {
                id: `clause_${clause.publicID}_[${index}]`,
                type: 'field',
                component: 'PCEHSingleClauseComponentVM',
                componentProps: {
                    ...this.props,
                    labelPosition,
                    loadingClauseMessage: translator(loadingClauseMessage),
                    value: clause,
                    path: getPath(path, clausePath),
                    containerClassName: uiGroup === 'More Optional Coverages' || uiGroup === 'Popular Optional Coverages' ? '' : 'clauseContainer',
                    isMandatoryCoverages,
                    schedulesMap,
                    viewOnlyMode,
                    policyType,
                    replacementCost,
                    steps,
                    jumpTo,
                    hasGoldOrPlatinumBoxVisible,
                    policyState,
                    isQuoteStale
                }
            };
        });
    };

    generateClauseGroup = (clauseCategory, index) => {
        const {
            splitByClauseCategory,
            categoryDisplayName,
            uiGroup,
            isExclusionOrCondition
        } = this.props;
        let clauseCategoryUpdated = [];

        clauseCategoryUpdated = clauseCategory
            .filter((clause) => clause.uigroup === uiGroup && clause.isHiddenInVersion !== true);

        if (isExclusionOrCondition) {
            clauseCategoryUpdated = clauseCategory
                .filter((clause) => clause.isHiddenInVersion !== true);
        }

        // IAP- 73 alphabetize the more optional coverages section
        if (uiGroup === 'More Optional Coverages') {
            const alphabeticallySort = (a, b) => {
                if (a.name < b.name) {return -1;}

                if (a.name > b.name) {return 1;}

                return 0;
            };

            clauseCategoryUpdated.sort(alphabeticallySort);
        }

        const clausesMetadata = this.toMetadata(clauseCategoryUpdated);

        const clauseCategoryName = (splitByClauseCategory || categoryDisplayName) && {
            componentProps: { className: styles.popularOptionalCoveragesLabelStyle },
            id: index ? `clause_category_title_[${index}]` : 'clause_category',
            type: 'element',
            component: 'span',
            // If splitByClauseCategory is false, categoryDisplayName should be supplied
            content: categoryDisplayName || clauseCategoryUpdated[0].coverageCategoryDisplayName
        };

        if (_.isEmpty(clauseCategoryUpdated)) {
            return null;
        }

        // Condition to check optional coverage or not,
        // if not return metadata without popularOptionalCoverageHelpText
        return {
            id: `clause_category_container_[${index}]`,
            type: 'container',
            component: 'div',
            componentProps: { className: 'clauseCategoryContainer' },
            content: clauseCategoryName
                ? [clauseCategoryName, ...clausesMetadata] : clausesMetadata
        };
    };

    generateReadOnlyMetadata = () => {
        const { value: clauses } = this.props;
        const clausesToRender = clauses
            .filter((clause) => clause.selected);

        if (_.isEmpty(clausesToRender)) {
            return [{
                id: 'no_clause',
                type: 'element',
                component: 'h5',
                content: messages.noCoverage,
                componentProps: {
                    className: 'gw-no-coverages'
                }
            }];
        }

        return this.toMetadata(clausesToRender);
    }

    generateEditableMetadata() {
        const { value: clauses, splitByClauseCategory } = this.props;

        if (splitByClauseCategory) {
            // coverageCategoryCode is used in all types of clauses
            const clauseCategory = _.groupBy(clauses, 'coverageCategoryCode');
            const clauseCategoryMetadata = Object.values(clauseCategory)
                .map(this.generateClauseGroup);

            return {
                id: 'clause_container',
                type: 'container',
                component: 'div',
                content: clauseCategoryMetadata
            };
        }

        return this.generateClauseGroup(clauses);
    }

    generateMetadata() {
        const { isEditable } = this.props;

        if (!isEditable) {
            return {
                id: 'clause_container',
                type: 'container',
                component: 'div',
                content: this.generateReadOnlyMetadata()
            };
        }

        return this.generateEditableMetadata();
    }

    render() {
        return <MetadataContent uiProps={this.generateMetadata()} />;
    }
}
