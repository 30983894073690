import { defineMessages } from 'react-intl';

export default defineMessages({
    
    carrierName: {
        id: 'common.eu.Underlying Policy.Insurer',
        defaultMessage: 'Insurer'
    },
    ULPolicyDetails: {
        id: 'common.eu.Underlying Policy.UL Policy Details',
        defaultMessage: 'UL Policy Details'
    },
    delete: {
        id: 'common.eu.Underlying Policy.Delete',
        defaultMessage: 'Delete'
    },
    removeUnderlyingPolicy: {
        id: 'common.eu.Underlying Policy.Remove?',
        defaultMessage: 'Remove Underlying Policy?'
    },
    removeEAVehicleExposure: {
        id: 'common.eu.Underlying Policy.Remove EA Vehicle Exposure?',
        defaultMessage: 'Remove Vehicle Exposure?'
    },
    removeAdditionalPremise: {
        id: 'common.eu.Underlying Policy.Remove Additional Premise',
        defaultMessage: 'Remove Additional Premise?'
    }, 
    removeAddress: {
        id: 'common.eu.Underlying Policy.Remove Address',
        defaultMessage: 'Remove Address?'
    },
    confirmDelete: {
        id: 'common.eu.Underlying Policy.Confirm Delete',
        defaultMessage: 'Please Confirm Delete.'
    },
    cancel: {
        id: 'common.eu.Underlying Policy.Cancel',
        defaultMessage: 'Cancel'
    },
    refreshUnderlyingPolicyInfo: {
        id: 'common.eu.quick.views.REFRESH UNDERLYING POLICY INFO',
        defaultMessage: 'REFRESH UNDERLYING POLICY INFO'
    },
    addULpoliciesButtonLabel: {
        id: 'common.eu.quick.views.ADD UNDERLYING POLICY/EXPOSURE',
        defaultMessage: 'ADD UNDERLYING POLICY/EXPOSURE'
    },
    underlyingPolicyMessage: {
        id: 'common.eu.Underlying Policy.underlyingPolicyMessage',
        defaultMessage: 'Underlying Policy'
    }
});
