import { defineMessages } from 'react-intl';

export default defineMessages({
    tpiPageTitle: {
        id: 'endorsement.eu.wizard.step.Third-Party Interest',
        defaultMessage: 'Third-Party Interest'
    },
    riskAnalysisPage: {
        id: 'endorsement.eu.directives.templates.eu-edit-riskAnalysis.Risk Analysis',
        defaultMessage: 'Risk Analysis'
    },
    exitTransaction: {
        id: 'endorsement.eu.wizard.step.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    recalculateButtonLabel: {
        id: 'endorsement.eu.templates.eh-policy-changes.recalculateButtonLabel',
        defaultMessage: 'Recalculate'
    },
    cancelLabel: {
        id: 'endorsement.eu.wizard.step.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'endorsement.eu.wizard.step.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'endorsement.eu.wizard.step.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    }
});
