import React, {
    useCallback,
    useEffect,
    useState,
    useRef,
    useMemo
} from 'react';
import {
    get, set, isEmpty, findIndex
} from 'lodash';
import { useOOSConflictPageLandingUtil } from 'e1p-capability-hooks';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { E1PEHHO6PropertyDetailsComponent } from 'e1p-capability-policyjob-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { RewriteService } from 'e1p-capability-rewrite';

function PropertyPage(props) {
    const { authHeader } = useAuthentication();
    const {
        wizardData: rewriteVM,
        updateWizardData,
        steps,
        jumpTo,
        currentStepIndex,
        changeNextSteps,
        updateWizardSnapshot
    } = props;

    const stepsRef = useRef(steps);
    const coveragesIndex = findIndex(steps, ({ path }) => path === '/coverage');
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingRewrite, setIsSavingRewrite] = useState(false);
    const [heatingSystemType, updateHeatingSystemType] = useState(undefined);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);

    const {
        isComponentValid,
        initialValidation,
	 onValidate,
        registerInitialComponentValidation
    } = useValidation('PropertyPage');

    useEffect(() => {
        /**
         * Using useRef to access current updated steps.
         * as we are adding new conflicts step and landing user on this newly created step
         * "step" state variable from props does not give us updated value inside useOOSConflictPageLandingUtil
         * it refers initial rendered value only(as we are adding new step and want to land user
         * on new step in)
         */
        stepsRef.current = steps;
    }, [steps]);

    const {
        removeOrAddAndLandOnConflictsPage
    } = useOOSConflictPageLandingUtil(
        stepsRef,
        currentStepIndex,
        changeNextSteps,
        jumpTo
    );

    const isPageValid = useCallback(() => {
        const heatingSystems = get(rewriteVM,
            'lobData.homeowners_EH.coverables.construction.heatingSystems.value', []);

        return heatingSystems.length > 0;
    }, [rewriteVM]);

    useEffect(() => {
        registerInitialComponentValidation(isPageValid);
    }, [registerInitialComponentValidation, isPageValid]);

    // if validation errors are thrown we cant jump to cov page
    const shouldNotGoToCoverages = (apiResponse) => {
        const validationErrors = get(apiResponse, 'errorsAndWarnings.validationIssues.fieldIssues', []);
        const exceptions = get(apiResponse, 'baseData.exceptions_Ext', []);

        return validationErrors.length > 0 || exceptions.length > 0;
    };

    const onNext = useCallback(
        async (_, calledFromOnSave = false) => {
            if (!isComponentValid || heatingSystemType === undefined) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRewrite(true);

            // Store quotedata before quote
            const quoteData = get(rewriteVM, 'quoteData.value');

            const response = await RewriteService
                .saveAndQuote([rewriteVM.value], authHeader);

            const respQuoteData = get(response, 'quoteData');

            if (respQuoteData === undefined) {
                set(response, 'quoteData', quoteData);
            }

            set(rewriteVM, 'value', response);
            updateWizardData(rewriteVM);

            if (!calledFromOnSave) {
                // E1PAP1PC-13853 :
                // If we get conflicts in saveAndQuote, we will add conflicts page if its not present
                // and user will land on conflicts page
                // If we had conflicts and we came back and made changes such that after saveAndQuote
                // if there are no conflicts we will remove conflicts page if its present
                const hasConflicts = !isEmpty(get(rewriteVM, 'value.conflicts', []));

                removeOrAddAndLandOnConflictsPage(hasConflicts);

                if (hasConflicts) {
                    setIsSavingRewrite(false);

                    return false;
                }
            }

            // Check for validation errors and stop page before jumping
            if (shouldNotGoToCoverages(rewriteVM.value)) {
                // stay on page
                updateWizardData(rewriteVM);
                setIsSavingRewrite(false);

                return false;
            }

            setIsSavingRewrite(false);

            if (!calledFromOnSave) {
                jumpTo(coveragesIndex, true);
            }

            // Must return false to deny wizardpageonnext promise
            return false;
        },
        [
            isComponentValid, heatingSystemType, rewriteVM,
            authHeader, updateWizardData, jumpTo, coveragesIndex,
            removeOrAddAndLandOnConflictsPage
        ]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, rewriteVM, updateWizardSnapshot]
    );

    // used to show/hide wholepage loader and bottom navigation buttons as well
    // eslint-disable-next-line arrow-body-style
    const isPageLoaded = useMemo(() => {
        return !isSavingRewrite && !isSavingCurrentPageChanges;
    },[isSavingCurrentPageChanges, isSavingRewrite]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            skipWhen={initialValidation}
            onNext={onNext}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <E1PEHHO6PropertyDetailsComponent
                onValidate={onValidate}
                transactionVM={rewriteVM}
                updateWizardData={updateWizardData}
                isPageSubmitted={isPageSubmitted}
                setShouldSkipAdditionalInfo={() => {}}
                isSavingCurrentPageChanges={isSavingCurrentPageChanges}
                isSavingQuote={isSavingRewrite}
                heatingSystemType={heatingSystemType}
                updateHeatingSystemType={updateHeatingSystemType}
                viewOnlyMode={false}
            />
        </WizardPage>
    );
}

PropertyPage.propTypes = wizardProps;
export default PropertyPage;
