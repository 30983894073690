import { set as _set } from 'lodash';
import AccountService from '../services/AccountService';
/**
 * Using this to create a view model for the dashboard account search component
 * Needed to initialize the page
 * @param {*} viewModelService
 * @returns {ViewModel} Viewmodel to use for search criteria fields
 */
const createAccountSearchVM = (viewModelService) => {
    const accountSearchVM = viewModelService.create({ contactType: 'person', country: 'US' },
        'pc', 'edge.capabilities.gateway.account.search.dto.AccountSearchCriteriaDTO');
    return accountSearchVM;
};

const lookupAccountFromDashboard = async (accountSearchCriteriaDto, authHeader) => {
    try {
        const foundAccounts = await AccountService
            .searchAccounts(accountSearchCriteriaDto, authHeader);
        return foundAccounts;
    } catch (e) {
        throw e.baseError;
    }
};

/**
 *
 * @purpose Used in the new business flows. Takes in accountholder and transforms
 *   into the account search DTO
 * @param {Object} accountHolder job's account holder off of base data
 * @param {String} producerCode producer code for agency
 * @param {String} partnerCode selected partner for the account (MSA is derived)
 * @param {*} authHeader auth token
 * @param {*} viewModelService util
 * @returns {Array} foundAccounts
 */
const lookupAccount = async (accountHolder, producerCode, partnerCode, authHeader, viewModelService) => {
    try {
        const model = {
            contactType: 'person',
            country: 'US'
        };
        // AccountSearchCriteriaDTO does not have a dob field so currently dob is not used in search
        // firstName, city, and postalCode must be defined
        const accountSearchVM = viewModelService.create(model,
            'pc', 'edge.capabilities.gateway.account.search.dto.AccountSearchCriteriaDTO');
        _set(accountSearchVM, 'firstName.value', '');
        _set(accountSearchVM, 'lastName.value', accountHolder.lastName);
        _set(accountSearchVM, 'city.value', '');
        _set(accountSearchVM, 'state.value', accountHolder.primaryAddress.state);
        _set(accountSearchVM, 'postalCode.value', '');
        _set(accountSearchVM, 'dateOfBirth_Ext.value', accountHolder.dateOfBirth);
        _set(accountSearchVM, 'producerCode_Ext.value', producerCode);
        _set(accountSearchVM, 'partnerCode_Ext.value', partnerCode);
        const foundAccounts = await AccountService
            .getPotentialExistingAccounts(accountSearchVM.value, authHeader);
        return foundAccounts;
    } catch (err) {
        throw new Error(err);
    }
};

export default { lookupAccount: lookupAccount, createAccountSearchVM, lookupAccountFromDashboard };