import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    get as _get,
    set as _set
} from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './E1PPremiumAdjustmentRowComponent.metadata.json5';

function E1PPremiumAdjustmentRowComponent(props) {
    const {
        data: premiumAdjustmentVM,
        lob,
        id,
        path,
        onValidate,
        premiumAdjustmentRecords,
        vehicles,
        viewOnlyMode,
        showErrors,
        onValueChange
    } = props;

    const {
        isComponentValid,
        onValidate: setComponentValidation
    } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [premiumAdjustmentVM, onValidate, isComponentValid, id]);

    const writeValue = useCallback(
        (value, changedPath) => {
            _set(premiumAdjustmentVM, `${changedPath}.value`, value);

            const fullPath = `${path}.${changedPath}`;

            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path, premiumAdjustmentVM]
    );

    const getVehicleAvailableValues = useCallback(
        (currentVehicleID) => vehicles.filter((vehicle) => !(premiumAdjustmentRecords.some(
                    (premiumAdjustmentRecord) => premiumAdjustmentRecord
                        .vehicleID?.toString() === vehicle.fixedId?.toString()
                )
                    && currentVehicleID?.toString() !== vehicle.fixedId?.toString()
                )).map((vehicle) => ({
                    code: vehicle.fixedId,
                    name: vehicle.displayName
                })), [premiumAdjustmentRecords, vehicles]
    );


    const getColumns = useCallback(
        () => {
            if (lob !== 'PersonalAuto_EA') {
                return ['1.5fr', '2fr'];
            }

            if (viewOnlyMode) {
                return ['2fr', '1.25fr', '2fr'];
            }

            return ['1.5fr', '1.5fr', '2fr'];
        }, [lob, viewOnlyMode]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            readOnly: viewOnlyMode,
            showErrors,
            autoComplete: false
        },
        premiumAdjustmentGrid: {
            columns: getColumns()
        },
        vehicle: {
            visible: lob === 'PersonalAuto_EA',
            availableValues: getVehicleAvailableValues(
                _get(premiumAdjustmentVM, 'value.vehicleID')
            )
        },
        e1pPremiumAdjustmentAmountComponent: {
            visible: !viewOnlyMode,
            amount: _get(premiumAdjustmentVM, 'value.amount'),
            showErrors
        },
        amount: {
            value: _get(premiumAdjustmentVM, 'value.amount'),
            visible: viewOnlyMode,
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={premiumAdjustmentVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={writeValue}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

E1PPremiumAdjustmentRowComponent.propTypes = {
    data: PropTypes.shape.isRequired,
    lob: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    viewModelService: PropTypes.shape({ create: PropTypes.func.isRequired }).isRequired,
    viewOnlyMode: PropTypes.bool,
    id: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    showErrors: PropTypes.bool,
    vehicles: PropTypes.arrayOf(PropTypes.shape()),
    premiumAdjustmentRecords: PropTypes.arrayOf(PropTypes.shape())
};

E1PPremiumAdjustmentRowComponent.defaultProps = {
    viewOnlyMode: false,
    showErrors: false,
    vehicles: [],
    premiumAdjustmentRecords: []
};

export default E1PPremiumAdjustmentRowComponent;
