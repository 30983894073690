import { defineMessages } from 'react-intl';

export default defineMessages({
    applyingChangesMessage: {
        id: 'quoteandbind.e1p.views.payment-details.Applying changes...',
        defaultMessage: 'Applying changes...'
    },
    completingYourPurchaseMessage: {
        id: 'quoteandbind.e1p.views.payment-details.Completing your purchase...',
        defaultMessage: 'Completing your purchase...'
    },
    paymentOptions: {
        id: 'quoteandbind.e1p.views.payment-details.Payment Options',
        defaultMessage: 'Payment Options'
    },
    averageMonthlyPremium: {
        id: 'quoteandbind.e1p.views.payment-details.Average Monthly Premium',
        defaultMessage: 'Average Monthly Premium:'
    },
    paymentMethod: {
        id: 'quoteandbind.e1p.views.payment-details.Payment Method',
        defaultMessage: 'Payment Method'
    },
    totalPremium: {
        id: 'quoteandbind.e1p.views.payment-details.Total Premium:',
        defaultMessage: 'Total Premium:'
    },
    bankAccount: {
        id: 'quoteandbind.e1p.views.payment-details.Bank Account',
        defaultMessage: 'Bank Account'
    },
    accountType: {
        id: 'quoteandbind.e1p.directives.templates.bank-account-details-quote.Account Type',
        defaultMessage: 'Account Type'
    },
    accountNumber: {
        id: 'quoteandbind.e1p.directives.templates.bank-account-details-quote.Account Number',
        defaultMessage: 'Account Number'
    },
    routingNumber: {
        id: 'quoteandbind.e1p.directives.templates.bank-account-details-quote.Routing (ABA) Number',
        defaultMessage: 'Routing (ABA) Number'
    },
    bankName: {
        id: 'quoteandbind.e1p.directives.templates.bank-account-details-quote.Bank Name',
        defaultMessage: 'Bank Name'
    },
    creditCard: {
        id: 'quoteandbind.e1p.views.payment-details.Credit Card',
        defaultMessage: 'Credit Card'
    },
    cardIssuer: {
        id: 'platform.e1p.paymentdetails.credit-card-auto-details.Card Issuer',
        defaultMessage: 'Card Issuer'
    },
    selectIssuer: {
        id: 'platform.e1p.paymentdetails.credit-card-auto-details.-- Please Select --',
        defaultMessage: '-- Please Select --'
    },
    paymentBuyNow: {
        id: 'quoteandbind.e1p.views.payment-details.Buy Now',
        defaultMessage: 'Buy Now'
    },
    loadingPayments: {
        id: 'quoteandbind.e1p.views.payment-details.Please wait while we communicate with the payments system...',
        defaultMessage: 'Please wait while we communicate with the payments system...'
    },
    verifiedPremium: {
        id: 'quoteandbind.common.views.payment-details.verifiedPremium',
        defaultMessage: 'Verified Premium'
    },
    payOptionsTitle: {
        id: 'quoteandbind.common.views.payment-details.payOptionsTitle',
        defaultMessage: 'Payment Options'
    },
    insuredResAddressTitle: {
        id: 'quoteandbind.common.views.payment-details.insuredResAddressTitle',
        defaultMessage: 'Insured Residence Address'
    },
    pniResAddressTitle: {
        id: 'quoteandbind.common.views.payment-details.pniResAddressTitle',
        defaultMessage: 'Primary Named Insured Residence Address'
    },
    thirdPartyTitle: {
        id: 'quoteandbind.common.views.payment-details.thirdPartyTitle',
        defaultMessage: 'Third-Party Interest'
    },
    backToPolicy: {
        id: 'quoteandbind.common.views.payment-details.Back To Policy',
        defaultMessage: 'Back To Policy'
    },
    duplicateAdditionalDesigneeTitle: {
        id: 'quoteandbind.common.views.payment-ea-details.tpi.duplicateAdditionalDesigneeTitle',
        defaultMessage: 'Duplicate Additional Designee'
    },
    duplicateAdditionalDesigneeMessage: {
        id: 'quoteandbind.common.views.payment-ea-details.tpi.duplicateAdditionalDesigneeMessage',
        defaultMessage: 'Only one TPI with an Interest Type "Additional Designee" is allowed per policy'
    },
    matchesPNIorSNITitle: {
        id: 'quoteandbind.common.views.payment-ea-details.tpi.matchesPNIorSNITitle',
        defaultMessage: 'Interest is Primary Named Insured and/or Secondary Named Insured'
    },
    matchesPNIorSNIMessage: {
        id: 'quoteandbind.common.views.payment-ea-details.tpi.matchesPNIorSNIMessage',
        defaultMessage: 'Interest Type cannot be Primary Named Insured or/and Secondary Named Insured. The interest has been removed.'
    },
    issueLabel: {
        id: 'quoteandbind.e1p.ea.views.payment-details.issueLabel',
        defaultMessage: 'Issue'
    },
    sixMonth: {
        id: 'quoteandbind.common.views.payment-details.6 Months',
        defaultMessage: '6 Months'
    },
    unableToBindTitle: {
        id: 'quoteandbind.common.views.payment-details.Unable to bind',
        defaultMessage: 'Unable to bind'
    }
});
