import { defineMessages } from 'react-intl';

export default defineMessages({
    swimmingPool: {
        id: 'common.eu.views.eu-underlying-policies.Swimming Pool',
        defaultMessage: 'Swimming Pool',
    },
    locationType: {
        id: 'common.eu.views.eu-underlying-policies.Location Type',
        defaultMessage: 'Location Type',
    },
    numberOfUnits: {
        id: 'common.eu.views.eu-underlying-policies.Number of Units',
        defaultMessage: 'Number of Units',
    },
    moreThan6CoOwners: {
        id:
            'common.eu.views.eu-underlying-policies.More than 6 Co Owners',
        defaultMessage: 'More than 6 Co Owners',
    },
    policyType: {
        id: 'common.eu.views.eu-underlying-policies.Policy Type',
        defaultMessage: 'Policy Type',
    },
    name: {
        id: 'common.eu.views.eu-underlying-policies.Name',
        defaultMessage: 'Name',
    },
    removeRentalDwelling: {
        id: 'common.eu.Underlying Policy.Remove Rental Dwelling?',
        defaultMessage: 'Remove Rental Dwelling?'
    },
    addRentalDwelling: {
        id: 'common.eu.views.eu-underlying-policies.Add Rental Dwelling',
        defaultMessage: 'Add Rental Dwelling'
    },
    isRentalDwelling: {
        id: 'common.eu.views.eu-underlying-policies.Building is exclusively a residential rental dwelling (no mixed use)',
        defaultMessage: 'Building is exclusively a residential rental dwelling (no mixed use)'
    },
    noRentalDwellings: {
        id: 'common.eu.views.eu-underlying-policies.No rental dwelling added',
        defaultMessage: 'No rental dwelling added'
    },
    numberOfResidentialUnits: {
        id: 'common.eu.views.eu-underlying-policies.Number of Residential Units',
        defaultMessage: 'Number of Residential Units'
    },
    dwelling: {
        id: 'common.eu.views.eu-underlying-policies.Dwelling',
        defaultMessage: 'Dwelling'
    },
    riskAddress: {
        id: 'common.eu.views.eu-underlying-policies.Risk Address',
        defaultMessage: 'Risk Address'
    },
});
