import React, {
    useCallback, useContext
} from 'react';
import {
    cloneDeep, get, sortBy, uniqBy
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useModifierUtil } from 'e1p-capability-hooks';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import styles from './QuotePage.module.scss';
import messages from './QuotePage.messages';
import metadata from './QuotePage.metadata.json5';

function structureClauseTableData(coverage) {
    // putting ID into an object as the Jutro table component expects an object
    return {
        publicID: coverage.publicID
    };
}

function generateClauseData(columnData, coveragePath, vehicleFixedID) {
    return columnData.map(({ lob, code }) => {
        let completeCoveragePath = `coverages.${coveragePath}`;

        if (vehicleFixedID) {
            const vehicleOfferings = lob.data.coverages.vehicleCoverages;
            const vehicleOfferingIndex = vehicleOfferings.findIndex(
                (vehicleCoverage) => vehicleCoverage.fixedId === vehicleFixedID
            );

            completeCoveragePath = `coverages.${coveragePath}.children[${vehicleOfferingIndex}].coverages`;
        }

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

const getCoveragesUniqueID = (policyViewVM) => {
    const offerings = get(
        policyViewVM,
        'lobData.personalAuto_EA.offerings.value'
    );
    const lineCoverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.lineCoverages.map(structureClauseTableData)
    )), 'publicID');
    const vehicleCoverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.vehicleCoverages.flatMap(({ coverages }) => (
            coverages.map(structureClauseTableData)
        ))
    )), 'publicID');

    return {
        lineCoverages,
        vehicleCoverages
    };
};

const generateColumnData = (policyViewVM) => {
    const lobOfferingPath = 'lobData.personalAuto_EA.offerings';


    const lobOfferings = get(policyViewVM, `${lobOfferingPath}.value`);


    let filteredOfferings = cloneDeep(lobOfferings);

    if (filteredOfferings.length > 1) {
        filteredOfferings = filteredOfferings.filter((offering) => offering.branchCode !== 'CUSTOM');
    }

    const columnData = filteredOfferings
        .map((lobOffering, lobIndex) => ({
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: '',
                    data: {}
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                },
                policyViewVM
            }));

    return sortBy(columnData, ['code']);
};

const generateTableData = (policyViewVM, columnData, translator) => {
    const vehicles = get(
        policyViewVM,
        'lobData.personalAuto_EA.coverables.vehicles.value'
    );
    const uniqueID = getCoveragesUniqueID(policyViewVM);

    const lineCoverages = {
        header: translator(messages.generalCoverages),
        data: uniqueID.lineCoverages,
        tableContent: generateClauseData(columnData, 'lineCoverages'),
        coverageType: 'lineCoverages'
    };

    const vehicleCoverages = vehicles.map((vehicle) => ({
        header: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
        data: uniqueID.vehicleCoverages,
        tableContent: generateClauseData(
            columnData,
            'vehicleCoverages',
            vehicle.fixedId
        ),
        coverageType: 'vehicleCoverages',
        fixedId: vehicle.fixedId
    }));

    return [lineCoverages, ...vehicleCoverages];
};

function ViewQuotePage(props) {
    const {
        wizardData: policyViewVM
    } = props;
    const { opCo } = useContext(AmfamOktaTokenContext);
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader, authUserData } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    const {
        structuredModifiers
    } = useModifierUtil(policyViewVM);
    const modifiers = structuredModifiers;

    const buyNow = useCallback(
        () => policyViewVM, [policyViewVM]
    );

    const checkComparatorFlow = useCallback(() => {
        if (get(policyViewVM, 'quoteSource_Ext.sourceType')) {
            const isSourceTypeCompRater = get(
                policyViewVM, 'quoteSource_Ext.sourceType.value.code'
            ) === 'comprater';
            const quoteType = get(policyViewVM, 'quoteType_Ext.value.code');

            return (isSourceTypeCompRater && quoteType === 'Quick');
        }

        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateOverrides = useCallback(() => {
        const columnData = generateColumnData(policyViewVM);

        const initialOverrides = {
            '@field': {
                // apply to all fields
                labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
            },
            quotePageLoadingIndicator: {
                loaded: true
            },
            quoteProposalLinkId: {
                disabled: false
            },
            quoteTable: {
                columnData,
                tableData: generateTableData(policyViewVM, columnData, translator),
                modifiers,
                submissionVM: policyViewVM,
                viewModelService,
                updateWizardData: () => {},
                authHeader,
                setFieldsChangedOnCoveragePage: () => {},
                fieldsChangedOnCoveragePage: false,
                visible: true,
                underwritingIssues: [],
                onCancel: () => { },
                authUserData,
                isComprater: checkComparatorFlow(),
                viewOnlyMode: true,
                opCo
            },
        };

        return { ...initialOverrides};
        // prevent complaining props and showQuoteStartDate is missing in the dependencies list
        // watch props will cause refresh function ref multiple time,
        // showQuoteStartDate is generated from build time, so won't change in the runtime
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, policyViewVM, translator, opCo]);

    return (
        <WizardPage
            // finish={isQuickQuote}
            shouldLink
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveClassNameMap: styles,
                    resolveCallbackMap: {
                        onBuyNow: () => buyNow().then(onNext),
                        onStaleQuoteBranchCode: () => {},
                        onRecalculate: () => { },
                        onResetQuote: () => { },
                        onChangeSubmissionAndSync: () => { },
                        onChangeSubmission: () => { },
                        onSyncCoverages: () => { },
                        onPrint: () => { },
                        onDisableNextForTable: () => { },
                        updateWizardData: () => {},
                        onCompOnlyChange: () => { }
                    }
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={policyViewVM}
                        overrideProps={generateOverrides()}
                        classNameMap={resolvers.resolveClassNameMap}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                );
            }}
        </WizardPage>
    );
}

ViewQuotePage.propTypes = wizardProps;
export default ViewQuotePage;
