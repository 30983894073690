import { useCallback, useContext } from 'react';
import {
    cloneDeep as _cloneDeep,
    get as _get,
    set as _set,
    unset as _unset,
    isEmpty as _isEmpty
} from 'lodash';
import { AccountService } from 'e1p-capability-gateway';
import { ConsoleHelper } from 'e1p-portals-util-js';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { ClauseService } from 'gw-capability-policycommon';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { ServiceManager } from '@jutro/legacy/services';
import { useEffectiveDateUtil } from 'e1p-capability-hooks';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import appConfig from 'app-config';
import moment from 'moment';

const productCodeToProductNameMap = {
    "PersonalAuto_EA": "Personal Auto",
    "Homeowners_EH": "Homeowners",
    "PersonalUmbrella_EU": "Personal Umbrella"
};

/**
 * @param {*} parsedParameters
 * @param {*} authHeader
 * @param {*} viewModelService
 * @param {*} setInitialSubmission
 * @param {*} location
 * @param {*} setIsLoading
 * @param {*} history
 * @returns {*} object
 */
function useSubmissionCreation(
    parsedParameters,
    authHeader,
    viewModelService,
    setInitialSubmission,
    location,
    setIsLoading,
    history
) {
    const use12MonthPolicyTerm = parsedParameters.productCode !== 'PersonalAuto_EA';
    const { getPeriodEndDateForGivenStartDate } = useEffectiveDateUtil(
        {},
        undefined,
        use12MonthPolicyTerm
    );

    const { operatingCompanyConfig } = appConfig;
    const { opCo } = useContext(AmfamOktaTokenContext);

    /**
     * @param {Object} submissionData base object that will be used to create the
     *   VMNode for the Wizard.
     * @purpose This function is a helper to set the effective dates
     */
    const setDates = (submissionData) => {
        // Determine the period's start and end dates based on the URL parameter, if one exists
        // If no params moment will resolve to today
        const date = moment(parsedParameters.effectiveDate);
        const periodStartDate = {
            year: date.year(),
            month: date.month(),
            day: date.date()
        };
        const periodEndDate = getPeriodEndDateForGivenStartDate(periodStartDate);

        _set(submissionData, 'baseData.periodStartDate', periodStartDate);
        _set(submissionData, 'baseData.periodEndDate', periodEndDate);
    };

    /**
     * @param {Object} submissionData base object that will be used to create the
     *   VMNode for the Wizard.
     * @param {*} policyTypeLOBPath product code
     * @purpose sets common base data. Used by the various conditions.
     */
    const setBaseData = (submissionData, policyTypeLOBPath) => {
        // sets common needed base data
        _set(submissionData, 'baseData.productCode', parsedParameters.productCode);
        _set(submissionData, 'baseData.productName', _get(productCodeToProductNameMap, parsedParameters.productCode, ''));
        _set(submissionData, 'baseData.jobType', 'Submission');

        const localeService = ServiceManager.getService('locale-service');
        const policyAddressCountry = localeService.getDefaultCountryCode();

        _set(submissionData, 'baseData.policyAddress.country', policyAddressCountry);
        _set(
            submissionData,
            `lobData.${policyTypeLOBPath}.policyType`,
            parsedParameters.policyType
        );
    };

    /**
     * Helper callback for finding the account which matches the given account number.
     */
    const findAccount = useCallback(
        async (accountNumber) => {
            const account = await AccountService.getAccountDetails(accountNumber, authHeader);

            if (account.accountHolder) {
                return account;
            }

            return undefined;
        },
        [authHeader]
    );

    /**
     * Helper callback for creating a new submission using the data from the Policy Details Page.
     */
    const initializeSubmission = useCallback(() => {
        const viewModelContext = {
            AccountEmailRequired: false,
            DriverEmailRequired: true,
            AccountDOBRequired: false
        };

        function startSubmissionFromRetrieval(requestData) {
            return LoadSaveService.retrieveSubmission(requestData, authHeader).then((response) => {
                response.persons = [response.baseData.accountHolder];
                ClausesUtil.getDependentClausesFromServer(
                    ClauseService,
                    _get(response, 'quoteID'),
                    authHeader
                );

                const submission = viewModelService.create(
                    response,
                    'pc',
                    'edge.capabilities.quote.submission.dto.QuoteDataDTO',
                    viewModelContext
                );

                _set(submission, 'baseData.producerCode_Ext', response.baseData.producerCode);
                _set(submission, 'convertedFromQuickToFull', false);
                setInitialSubmission(submission);

                return submission;
            });
        }

        const requestData = {};
        const submissionData = { baseData: {}, lobData: {} };

        function getPolicyTypeLOBPath(productCode) {
            switch (productCode) {
                case 'PersonalAuto_EA':
                    return 'personalAuto_EA';
                case 'Homeowners_EH':
                    return 'homeowners_EH';
                case 'PersonalUmbrella_EU':
                    return 'personalUmbrella_EU';
                default:
                    throw new Error(`Encountered an unsupported product code: ${productCode}`);
            }
        }

        let policyTypeLOBPath = null;

        if (parsedParameters.productCode || parsedParameters.jobNumber) {
            if (parsedParameters.productCode) {
                policyTypeLOBPath = getPolicyTypeLOBPath(parsedParameters.productCode);
            }
        } else if (!location.state || !location.state.quoteentry) {
            ConsoleHelper('You have not entered the flow with url params or quoteID', 'ERROR');
            history.push('/');
        }
        
        let initialSubmission = {};

        if (location && location.state) {
            requestData.quoteID = location.state.quoteentry.quoteID;
            initialSubmission = startSubmissionFromRetrieval(requestData);
        } else if (parsedParameters.jobNumber) {
            requestData.quoteID = parsedParameters.jobNumber;
            initialSubmission = startSubmissionFromRetrieval(requestData);
        } else if (parsedParameters.accountNumber) {
            const FindAccount = findAccount(parsedParameters.accountNumber);

            FindAccount.then((resp) => {
                // set basedata defaults
                setBaseData(submissionData, policyTypeLOBPath);
                // set default eff and exp dates
                setDates(submissionData);

                // Set basedata fields from found account
                _set(submissionData, 'baseData.accountHolder', resp.accountHolder.person);
                _set(submissionData, 'baseData.accountNumber', parsedParameters.accountNumber);

                const accountHolderAddress = _cloneDeep(_get(resp, 'accountHolder.person.primaryAddress'));

                if (accountHolderAddress) {
                    _unset(accountHolderAddress, 'publicID');

                    const policyBillingAddress = _cloneDeep(accountHolderAddress);
                    const policyMailingAddress = _cloneDeep(accountHolderAddress);

                    _set(submissionData, 'baseData.policyAddress', accountHolderAddress);
                    _set(submissionData, 'baseData.policyBillingAddress_Ext', policyBillingAddress);
                    _set(submissionData, 'baseData.policyMailingAddress_Ext', policyMailingAddress);
                }

                // E1PAP1PC-12445
                // Defaulting producer code and location code values from account
                if (!_isEmpty(resp.producerCodes)) {
                    const producerCodeDetails = resp.producerCodes[0];

                    _set(submissionData, 'baseData.producerCode_Ext', producerCodeDetails.code);
                    _set(submissionData, 'baseData.externalID_Ext', producerCodeDetails.externalId_Ext);
                }

                // defaulting partner code and experienceId
                const {partnerCode} = resp;

                if (partnerCode) {
                    _set(submissionData, 'baseData.partnerCode_Ext', partnerCode);

                    const opcoConfigObject = operatingCompanyConfig[opCo].experiences;

                    // eslint-disable-next-line no-prototype-builtins
                    if (opcoConfigObject.hasOwnProperty(partnerCode)) {
                        _set(submissionData, 'baseData.experienceID_Ext', opcoConfigObject[partnerCode].experienceId)
                    }
                }

                const submission = viewModelService.create(
                    submissionData,
                    'pc',
                    'edge.capabilities.quote.submission.dto.QuoteDataDTO',
                    viewModelContext
                );

                setInitialSubmission(submission);
                initialSubmission = submission;
            });
        } else {
            // Set common base data
            setBaseData(submissionData, policyTypeLOBPath);
            _set(submissionData, 'baseData.policyAddress.state', parsedParameters.state);
            _set(submissionData, `lobData.${policyTypeLOBPath}.primaryNamedInsured.person.primaryAddress.state`, parsedParameters.state);

            const localeService = ServiceManager.getService('locale-service');
            const policyAddressCountry = localeService.getDefaultCountryCode();

            _set(submissionData, 'baseData.policyBillingAddress_Ext.country', policyAddressCountry);
            _set(submissionData, 'baseData.policyMailingAddress_Ext.country', policyAddressCountry);
            setDates(submissionData);

            // Create the submission VMNode using the properties as configured above
            const submissionVM = viewModelService.create(
                submissionData,
                'pc',
                'edge.capabilities.quote.submission.dto.QuoteDataDTO',
                viewModelContext
            );

            setInitialSubmission(submissionVM);
            initialSubmission = submissionVM;
        }

        setIsLoading(false);

        return initialSubmission;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        parsedParameters,
        authHeader,
        viewModelService,
        setInitialSubmission,
        location,
        findAccount,
        setIsLoading,
        history
    ]);

    return {
        initializeSubmission
    };
}

export default useSubmissionCreation;
