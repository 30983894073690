import React, { useCallback, useState, useEffect } from 'react';
import { get, set, findIndex, isEmpty } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { RenewalService } from 'e1p-capability-renewal';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import wizardMessages from '../../EURenewalWizard.messages';
import metadata from './QualificationPage.metadata.json5';

function QualificationPage(props) {
    const {
        wizardData: renewalVM, updateWizardData, isSkipping,
        onCustom2: handleDiscardChanges,
        jumpTo,
        updateWizardSnapshot,
        steps,
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { initialValidation, isComponentValid, onValidate } = useValidation(
        'QualificationPage'
    );
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isSavingRenewal, setIsSavingRenewal] = useState(false);
    const isDiscardButtonActive = get(renewalVM, 'value.canDiscard_Ext', false);
    const changeSummaryIndex = findIndex(steps, ({ path }) => path === '/change-review');

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [renewalVM, isComponentValid, isPageSubmitted]);

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setIsSavingRenewal(true);

        const saveResponse = await RenewalService.saveRenewal(
            [renewalVM.value],
            authHeader
        );

        set(renewalVM, 'value', saveResponse);
        updateWizardData(renewalVM);
        setIsSavingRenewal(false);

        return renewalVM;
    }, [authHeader, isComponentValid, renewalVM, updateWizardData]);

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(renewalVM, 'value.errorsAndWarnings_Ext.validationIssues.fieldIssues', []);
                const exceptions = get(renewalVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(renewalVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, renewalVM, updateWizardSnapshot]
    );

    const reviewChanges = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingRenewal(true);

            const quoteResponse = await RenewalService.saveAndQuoteRenewal(
                [(renewalVM.value)],
                authHeader
            );

            set(renewalVM, 'value', quoteResponse);
            updateWizardData(renewalVM);
            updateWizardSnapshot(renewalVM);
            jumpTo(changeSummaryIndex, true, quoteResponse);
            setIsSavingRenewal(false);

            return false;
        },
        [
            authHeader, changeSummaryIndex, isComponentValid, jumpTo,
            renewalVM, updateWizardData, updateWizardSnapshot
        ]
    );


    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        preQualificationPageLoadingIndicator: {
            loaded: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(e1pCommonMessages.loadingNextPage)
        },
        preQualificationPageContainer: {
            visible: !isSavingRenewal && !isSkipping && !isSavingCurrentPageChanges
        },
        euPreQualificationComponent: {
            data: renewalVM,
            updateWizardData,
            showErrors: isPageSubmitted
        }
    };

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showCustom
            customLabel={wizardMessages.reviewChangesLabel}
            onCustom={reviewChanges}
            showCustom2={isDiscardButtonActive}
            onCustom2={handleDiscardChanges}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

QualificationPage.propTypes = wizardProps;
export default withRouter(QualificationPage);
