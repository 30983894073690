import { useMemo, useCallback, useRef, useContext } from 'react';
import {
    get as _get,
    some as _some,
    findIndex as _findIndex,
    isEmpty as _isEmpty
} from 'lodash';
import config from 'app-config';
import { VehicleInfoLookupService } from 'e1p-capability-gateway';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { VehicleUtil } from 'e1p-portals-util-js';

/**
 * @param {*} wizardData model for the transaction
 * @returns {*} A set of routines to help determine whether all necessary
 * info has been provided for subject transaction
 */
function useAdditionalInfoUtil(wizardData) {
    const { opCo } = useContext(AmfamOktaTokenContext);
    const { operatingCompanyConfig } = config;
    const lookupServiceFailed = useRef(false);
    const driverHasInvalidLicInfo = useCallback((driver) => {
        const nonOperator = driver.policyDriverRoleType.value?.code === 'nonoperator'
            || driver.policyDriverRoleType.value?.code === 'excludedoperator';
        const msaLicenseNotNeeded = nonOperator && (driver.nonOperatorDescType.value?.code === 'NBL' || driver.nonOperatorDescType.value?.code === 'NCL');
        const licenseNotNeeded = opCo === 'CONNECT' ? nonOperator : msaLicenseNotNeeded;

        return (!licenseNotNeeded
            && (_isEmpty(driver.person.licenseNumber.value)
                || _isEmpty(driver.person.licenseState.value)
                || !driver.person.licenseNumber.aspects.valid
                || !driver.person.licenseState.aspects.valid
            ));
    }, [opCo]);

    const nonOperatorInfoIsMissing = (driver) => (
            driver.policyDriverRoleType.value?.code === 'nonoperator'
            && (_isEmpty(driver.nonOperatorDescType.value)
                || (driver.nonOperatorDescType.value?.code === 'OTH'
                    && _isEmpty(driver.nonOperatorOtherDescText.value))
                || (['NCL', 'SRL'].includes(driver.nonOperatorDescType.value?.code)
                    && _isEmpty(driver.suspRevokePleaseExplain.value)))
        );

    const vehicleHasInvalidVin = (vehicle) => (
            _isEmpty(vehicle.vin.value)
            || !vehicle.vin.aspects.valid
            || vehicle.vin.value?.length !== 17
            || ( // one of these is mapped on quotehandler calls;
                // other is mapped from look up service;
                // only one will be set and should be found or valid
                vehicle.validatedVINStatus.value !== 'VALID'
                && vehicle.vinstatus.value !== 'data_found'
            )
        );

    const vehicleHasEmptyVin = (vehicle) => (
            _isEmpty(vehicle.vin.value)
            || !vehicle.vin.aspects.valid
            || vehicle.vin.value?.length !== 17
        );

    const prefillDriverHasNoStatus = (prefillDriver) => (
            _isEmpty(prefillDriver.driverStatus.value)
            || !prefillDriver.driverStatus.aspects.valid
            || prefillDriver.driverStatus.value.code === 'NotInHousehold'
        );

    const allDrivers = useMemo(() => _get(
            wizardData,
            'lobData.personalAuto_EA.coverables.drivers.children',
            []
        ), [wizardData]);

    const allVehicles = useMemo(() => _get(
            wizardData,
            'lobData.personalAuto_EA.coverables.vehicles.children',
            []
        ), [wizardData]);

    const allPrefillDrivers = useCallback(() => _get(
            wizardData,
            'lobData.personalAuto_EA.prefillDrivers.children',
            []), [wizardData]);

    const vehicleWithoutInvalidVinExists = useCallback(() => (
            _findIndex(allVehicles, (vehicle) => vehicleHasInvalidVin(vehicle)) !== -1
        ), [allVehicles]);

    const vehicleWithoutVinExists = useCallback(() => (
            _findIndex(allVehicles, (vehicle) => vehicleHasEmptyVin(vehicle)) !== -1
        ), [allVehicles]);

    const driverWithoutLicenseInfoExists = useCallback(() => (
            _findIndex(allDrivers, (driver) => driverHasInvalidLicInfo(driver)) !== -1
        ), [allDrivers, driverHasInvalidLicInfo]);

    const nonOperatorWithMissingInfoExists = useCallback(() => _some(allDrivers, (driver) => nonOperatorInfoIsMissing(driver)), [allDrivers]);

    const costcoMembershipIdNeeded = useMemo(() => {
        let costcoPartnerId;

        if (opCo === 'CONNECT') {
            const connectConfigObject = _get(
                operatingCompanyConfig,
                'CONNECT.experiences'
            );

            for (const key in connectConfigObject) {
                if (connectConfigObject.hasOwnProperty(key)) {
                    if (
                        `${connectConfigObject[key].experienceIdDisplayName}` === 'Costco'
                    ) {
                        costcoPartnerId = key;
                    }
                }
            }

            if (_get(wizardData, 'baseData.partnerCode_Ext.value') === costcoPartnerId &&
                _get(wizardData, 'lobData.personalAuto_EA.membership.membershipNumber.value') === undefined
            ) {
                return true;
            }
        }

        return false;
    }, [opCo, operatingCompanyConfig, wizardData]);

    const prefillDriverWithoutStatusExists = useCallback(() => _get(wizardData, 'baseData.quoteSource_Ext.sourceType.value.code', 'directentry') === 'comprater'
            && allPrefillDrivers().some((prefillDriver) => prefillDriverHasNoStatus(prefillDriver)), [allPrefillDrivers, wizardData]);

    const additionalInfoIsProvidedExceptValidatedVehicleVins = useCallback(() => {
        const isThereVehicleWithoutVin = vehicleWithoutVinExists();
        const isThereDriverWithoutLicenseInfo = driverWithoutLicenseInfoExists();
        const isThereNonOperatorWithMissingInfo = nonOperatorWithMissingInfoExists();

        return !(
            isThereVehicleWithoutVin
            || isThereDriverWithoutLicenseInfo
            || isThereNonOperatorWithMissingInfo
        );
    }, [
        driverWithoutLicenseInfoExists,
        nonOperatorWithMissingInfoExists,
        vehicleWithoutVinExists
    ]);

    const doVinLookupsForVehicles = useCallback(
        async (authHeader, updateWizardData) => {
            lookupServiceFailed.current = false;

            const vehiclesWithInvalidVinStatuses = allVehicles
                // one of these is mapped on quotehandler calls;
                // other is mapped from look up service;
                // only one will be set and should be found or valid
                .filter((vehicle) => vehicle.validatedVINStatus.value !== 'VALID' && vehicle.vinstatus.value !== 'data_found');

            const vinValidationsPromises = [];

            for (let index = 0; index < vehiclesWithInvalidVinStatuses.length; index += 1) {
                // Don't make this call if there is no vin yet
                const vehicle = vehiclesWithInvalidVinStatuses[index];

                if (!vehicle.vin.value) break;

                const vehicleLookupDTO = {
                    year: vehicle.year.value,
                    vin: vehicle.vin.value,
                    partnerID: _get(wizardData, 'baseData.partnerCode_Ext.value')
                };

                const lookupCall = VehicleInfoLookupService.lookupVehicleInfoBasedOnVinAndYear(
                    vehicleLookupDTO, authHeader
                ).then((response) => {
                    // valid length for vin; remove message
                    // Service does not return error if not found.
                    //   Have to check this property and then exit function.
                    if (response.vinstatus !== 'data_found') {
                        lookupServiceFailed.current = true;
                    } else {
                        const vehicleToBeUpdated = wizardData.lobData.personalAuto_EA
                            .coverables.vehicles.children.find(
                                (veh) => veh.publicID.value === vehicle.publicID.value
                            );

                        vehicleToBeUpdated.value = VehicleUtil.mapVinAndYearLookupToVehicle(
                            vehicleToBeUpdated.value,
                            response,
                            true
                        );
                        updateWizardData(wizardData);
                    }
                });

                vinValidationsPromises.push(lookupCall);

                if (lookupServiceFailed.current) {
                    break;
                }
            }

            return Promise.all(vinValidationsPromises).then(() => !lookupServiceFailed.current).catch(() => false);
        }, [allVehicles, wizardData]);

    /**
     * Helper function to decide if auto wizard needs to render additional info page
     * @param authHeader required in case any vehicle is not already valid
     * @param updateWizardData required to update the vehicle info, if not already validated
     */
    const additionalInfoIsProvided = useCallback(async (authHeader, updateWizardData) => {
        let isThereNonValidVehicle = false;

        if (authHeader && updateWizardData) {
            // Will lookup and try to validate vehicles, if not yet validated
            // does have a return value but we will check all vehicles again, below
            isThereNonValidVehicle = !(await doVinLookupsForVehicles(authHeader, updateWizardData));
        }

        // this is still needed because we are getting validated vin status to valid on make, model, year vehicles for some reason
        const isThereVehicleWithoutVin = vehicleWithoutInvalidVinExists();
        const isThereDriverWithoutLicenseInfo = driverWithoutLicenseInfoExists();
        const isTherePrefillDriverWithoutStatus = prefillDriverWithoutStatusExists();
        const isThereNonOperatorWithMissingInfo = nonOperatorWithMissingInfoExists();

        return !(
            isThereNonValidVehicle
            || isThereVehicleWithoutVin
            || isThereDriverWithoutLicenseInfo
            || isTherePrefillDriverWithoutStatus
            || isThereNonOperatorWithMissingInfo
            // Check membership id is provided for Costco CONNECT
            || costcoMembershipIdNeeded
        );
    }, [
        driverWithoutLicenseInfoExists,
        nonOperatorWithMissingInfoExists,
        prefillDriverWithoutStatusExists,
        doVinLookupsForVehicles,
        vehicleWithoutInvalidVinExists,
        costcoMembershipIdNeeded
    ]);


    return {
        allDrivers,
        allVehicles,
        allPrefillDrivers,
        driverHasInvalidLicInfo,
        vehicleHasInvalidVin,
        prefillDriverHasNoStatus,
        nonOperatorInfoIsMissing,
        vehicleWithoutInvalidVinExists,
        driverWithoutLicenseInfoExists,
        prefillDriverWithoutStatusExists,
        nonOperatorWithMissingInfoExists,
        additionalInfoIsProvided,
        vehicleWithoutVinExists,
        additionalInfoIsProvidedExceptValidatedVehicleVins
    };
}

export default useAdditionalInfoUtil;
