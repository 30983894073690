import { start } from '@jutro/app';
import {
    BrowserRouter
} from 'react-router-dom';
import { initDefaultGA } from '@jutro/events';
import { LocaleService, G11nstore } from 'gw-portals-i18n-react';


// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import e1pCustomTheme from 'e1p-digital-theme-styles';
import 'regenerator-runtime';

import App from './app/App';

import { configureLegacyComponentMap } from '@jutro/legacy/uiconfig';
configureLegacyComponentMap();

const { trackingConfig = {}, authentication = {}, microAppConfig = {}, policyTransactionMicroAppConfig = {} } = appConfig;

const gaTrackingId = trackingConfig.GA_TRACKING_ID;

const silentRedirectPath = appConfig?.authentication?.servers?.okta?.JUTRO_AUTH_SILENT_REDIRECT_PATH;
const silentLoginPath = appConfig?.authentication?.servers?.okta?.JUTRO_AUTH_SILENT_LOGIN_PATH;

if (window.self === window.top) {
    const theBody = document.getElementsByTagName('body')[0];

    theBody.style.display = 'block';
} else if (
    (!silentLoginPath || !window.self.location.pathname.endsWith(silentLoginPath)) &&
    (!silentRedirectPath || !window.self.location.pathname.endsWith(silentRedirectPath))
) {
    const { host, protocol } = new URL(window.top.location);
    const root = `${protocol}//${host}`;
    const rootPath = process.env.PUBLIC_PATH;

    window.top.location = new URL(rootPath, root);
}

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}

const config = {
    ...authentication.servers.okta,
    ...policyTransactionMicroAppConfig,
    microAppConfig,
    ...appConfig.credentials
};

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    refreshOnLocaleChange: true,
    routerBasename: '/producer-engage',
    config: [config],

    onInit: () => {
        LocaleService.register();
    },

    g11nStore: G11nstore(),
    Router: BrowserRouter,

    appName: {
        id: 'digital.appName',
        defaultMessage: 'ProducerEngage'
    },

    themeConfig: e1pCustomTheme,
    appDescription: 'ProducerEngage',
});