import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingLosses: {
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Loading losses...',
        defaultMessage: 'Loading losses...'
    },
    ratingYourPolicyMessage: {
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Please wait while we rate your policy...',
        defaultMessage: 'Please wait while we rate your policy...'
    },
    loadingNextPageMessage: {
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    selfDeclaredLossesTitle: {
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Self Declared Losses',
        defaultMessage: 'Self Declared Losses'
    },
    AutoTitle: {
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Auto',
        defaultMessage: 'Auto'
    },
    PropertyTitle: {
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Property',
        defaultMessage: 'Property'
    },
    SelfDeclaredTitle: {
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Self Declared',
        defaultMessage: 'Self Declared'
    },
    ThirdPartyReportsTitle: {
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Third Party Reports',
        defaultMessage: 'Third Party Reports'
    },
    SelfDeclaredLossesTitle: {
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Self Declared Losses',
        defaultMessage: 'Self Declared Losses'
    },
    propertyLosses: {
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Have you had any Property losses in the past 5 years?',
        defaultMessage: 'Have you had any Property losses in the past 5 years?'
    },
    thirdPartyReports: {
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Third Party Reports',
        defaultMessage: 'Third Party Reports'
    },
    saveOverridesErrorTitle:{
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Save Override Error',
        defaultMessage: 'Save Override Error'
    },
    saveOverridesErrorMessage:{
        id: 'quoteandbind.eh.verifiedUnderwritingComponent.Save Override Error Message',
        defaultMessage: 'Error in saving the dispute data.'
    }
});
