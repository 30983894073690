import React from 'react';
import PropTypes from 'prop-types';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import _ from 'lodash';
import { e1pDateUtil } from 'e1p-capability-hooks';
import metadata from './TransactionSummaryCardComponent.metadata.json5';
import styles from './TransactionSummaryCardComponent.module.scss';

const TransactionSummaryCardComponent = (props) => {
    const {
        transactionSummaryInfo
    } = props;

    const overrideProps = {
        '@field': {
            readOnly: true,
            autoComplete: false,
            labelPosition: "left"
        },
        transactionPolicyInceptionId: {
            value: e1pDateUtil.convertToUTCForDateWithoutTZ(_.get(transactionSummaryInfo, 'policyInceptionDate'))
        },
        transactionTermEffDateId: {
            value: e1pDateUtil.convertToUTCForDateWithoutTZ(_.get(transactionSummaryInfo, 'termEffectiveDate'))
        },
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <MetadataForm
            uiProps={metadata.pageContent}
            data={transactionSummaryInfo}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap} />
    );
};

TransactionSummaryCardComponent.propTypes = {
    transactionSummaryInfo: PropTypes.shape({
        transactionCreateTime: PropTypes.string.isRequired,
        termEffectiveDate: PropTypes.string.isRequired,
        transactionStatus: PropTypes.string.isRequired,
        policyInceptionDate: PropTypes.string.isRequired
    }).isRequired
};

export default TransactionSummaryCardComponent;
