import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from 'e1p-portals-transport-js';

export default class RewriteService {
    static retrieveReport(data, additionalHeaders) {
        return JsonRPCService.send(
            getProxiedServiceUrl('rvpservice'),
            'retrieveReport',
            [data],
            additionalHeaders
        );
    }

    static approveUWIssue(data, additionalHeaders) {
        return JsonRPCService.send(getProxiedServiceUrl('rvpservice'),
            'approveUWIssue', [data], additionalHeaders);
    }
}
