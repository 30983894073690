
import React from 'react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import PropTypes from 'prop-types';
import metadata from './Contingency.metadata.json5';
import './Contingency.messages';

function Contingency(props) {
    const {
        policyNumber,
        authUserData,
        authHeader,
        policyDetailsData: { contingencies },
        setOutstandingContingenciesPresent
    } = props;

    const canViewAccpetRejectContigencies = authUserData?.permissions_Ext.includes('editcontingency');

    const overrides = {
        contingenciesComponent: {
            canEditContingency: canViewAccpetRejectContigencies,
            contingencies,
            authUserData,
            authHeader,
            policyNumber,
            setOutstandingContingenciesPresent
        }
    };

    const resolvers = {
        resolveComponentMap: {
        }
    };

    return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
}

Contingency.propTypes = {
    policyNumber: PropTypes.string.isRequired,
    authUserData: PropTypes.shape({}),
    authHeader: PropTypes.shape({}),
    policyDetailsData: PropTypes.shape({}).isRequired,
    setOutstandingContingenciesPresent: PropTypes.func.isRequired
};

Contingency.defaultProps = {
    authUserData: undefined,
    authHeader: undefined
}

export default withAuthenticationContext(Contingency);
