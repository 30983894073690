import React, { useMemo, useEffect, useState, useCallback } from 'react';
import {
    get as _get,isNil as _isNil
} from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ContingencyService } from 'e1p-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import metadata from './ThankyouPageRequiredDocumentsComponent.metadata.json5';
import styles from './ThankyouPageRequiredDocumentsComponent.module.scss';

function ThankyouPageRequiredDocumentsComponent(props) {
    const {
        submissionVM
    } = props;

    const { authHeader } = useAuthentication();
    const [allContingencies, setAllContingencies] = useState([]);
    const translator = useTranslator();

    const policyNumber = useMemo(() => {
        if (_get(submissionVM, 'bindData.policyNumber.value')) {
            return _get(submissionVM, 'bindData.policyNumber.value');
        }

        return _get(submissionVM, 'value.policyNumber');
    }, [submissionVM]);

    const retriveAndSavecontingenciesOnPolicy = useCallback(() => {
        ContingencyService.retrieveContingenciesForPolicy(policyNumber, authHeader)
            .then((allContingenciesAtPolicy) => {
                const notAutomatedAndPendingContignecies = allContingenciesAtPolicy
                    .filter((contigencyAtPolicy) => contigencyAtPolicy.isAutomatedContingency === false && 
                        contigencyAtPolicy.contingencyStatus === 'Pending');

                setAllContingencies(notAutomatedAndPendingContignecies);
            });
    }, [authHeader, policyNumber]);

    useEffect(() => {
        retriveAndSavecontingenciesOnPolicy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            autoComplete: false
        },
        linkToDocumentsID: {
            to: `/policies/${policyNumber}/documents`
        },
        requiredDocumentTable: {
            data: allContingencies
        },
        staticRequiredMessage: {
            visible: allContingencies.length > 0
        }
    };
    const getContingencyName = (items, index, property) => {
        let contingencyName = '';
        const propertyValue = items[property.path];

        if (!_isNil(propertyValue)) {
            contingencyName = translator({
                id: `typekey.ContingencyType_Ext.${propertyValue}`,
                defaultMessage: propertyValue
            });
        }

        return <div>{contingencyName}</div>;
    }
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getContingencyName
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

ThankyouPageRequiredDocumentsComponent.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired
};

export default ThankyouPageRequiredDocumentsComponent;
