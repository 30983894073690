import React, {
    useCallback,
    useEffect,
    useContext,
    useState
} from 'react';
import PropTypes from 'prop-types';
import {
    get as _get,
    findIndex as _findIndex,
    some as _some,
    remove as _remove,
    pullAt as _pullAt,
    set as _set,
    isEmpty as _isEmpty,
    isEqual as _isEqual
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { usePriorCarrierUtil, e1pDateUtil } from 'e1p-capability-hooks';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import appConfig from 'app-config';
import metadata from './PriorPolicyManualGridComponent.metadata.json5';
import styles from './PriorPolicyManualGridComponent.module.scss';
import messages from './PriorPolicyManualGridComponent.messages';
/**
 * This component is to view, edit, update manual prior polices,
 * also to view priorPolicyUpdate, not to edit or delete.
 * @param {*} props
 * @returns {object} //component
 */

const initialGridColumnLabels = [
    { id: 'priorCarrierName', name: 'Current/Prior Carrier Name', isRequired: true },
    { id: 'reasonForNoInsurance', name: 'Reason for no Insurance' },
    { id: 'previousPolicy', name: 'Previous Policy #' },
    { id: 'inceptionDate', name: 'Inception Date', isRequired: true },
    { id: 'lastDateOfCoverage', name: 'Last Date of Coverage', isRequired: true },
    { id: 'biLimitPP', name: 'BI Limit(PP)' },
    { id: 'biLimitPO', name: 'BI Limit(PO)' },
    { id: 'biLimitCSL', name: 'BI Limit(CSL)' },
    { id: 'comprehensiveCoverage', name: 'Comprehensive Coverage' },
    { id: 'collisionCoverage', name: 'Collision Coverage' },
    { id: 'coverageLapse', name: 'Coverage Lapse' },
    { id: 'action', name: '' },
];

const initialGridStructure = {
    id: 'PriorPolicyLabelContainer',
    type: 'container',
    component: 'Grid',
    componentProps: {
        columns: [
            '0.5fr',
            '0.5fr',
            '0.5fr',
            '0.5fr',
            '0.5fr',
            '0.5fr',
            '0.5fr',
            '0.5fr',
            '0.5fr',
            '0.3fr',
            '0.3fr',
            '0.2fr'
        ],
        gap: 'small',
        justifyItems: 'left',
        className: ''
    },
    content: []
};

const generateHeadersForPriorPolicy = (gridColumns, gridStructureConfig) => {
    const content = gridColumns.map((label, index) => ({
        id: `${label.id}GridHeaderItem${index}`,
        type: 'container',
        component: 'GridItem',
        componentProps: {
            textAlign: 'left',
            colSpan: 1,
            className: styles.labelItem
        },
        content: [
            {
                id: `${label.id}HeaderLabel${index}`,
                type: 'element',
                component: 'span',
                content: label.name,
                componentProps: {
                    className: label.isRequired ? styles.required : styles.labelItem
                }
            }
        ]

    }));
    const gridStructure = { ...gridStructureConfig };

    gridStructure.id = 'headerGridColumns';
    gridStructure.componentProps.className = styles.gridHeaderBorder;
    gridStructure.content = content;

    return gridStructure;
};

function PriorPolicyManualGridComponent(props) {
    const modalApi = useModal();
    const {
        data: priorPoliciesVMList,
        priorPolicyUpdatesVM,
        namedInsured,
        isSNI,
        labelPosition,
        path,
        id,
        onValidate,
        onValueChange,
        viewOnlyMode,
        policyStartDate,
        setPriorCarrierChanged,
        showErrors,
        priorCarrierNames,
        transactionVM,
        priorPolicyUpdateReasonAvailableValues,
        manualPriorCarrierFuncRef
    } = props;
    const {
        isComponentValid,
        onValidate: setComponentValidation,
        disregardFieldValidation
    } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const policyState = _get(transactionVM, 'baseData.policyAddress.state.value.code', _get(transactionVM, 'policyAddress.state.value.code'));
    const [priorPolicyUpdatesVMWithoutLapseRecords, setPriorPolicyUpdatesVMWithoutLapse] = useState(_get(priorPolicyUpdatesVM, 'value', []));
    const [gridColumnLabels, setGridColumnLabels] = useState(initialGridColumnLabels);
    const [gridStructureConfig, setGridStructureConfig] = useState(initialGridStructure);
    const {
        createPriorPolicyVM
    } = usePriorCarrierUtil(
        priorPoliciesVMList,
        viewModelService,
        onValueChange,
        path,
        disregardFieldValidation,
        messages
    );
    const auto2dot0States = appConfig.auto2dot0States ?? [];
    const isAuto2dot0State = auto2dot0States.includes(policyState);

    const integrationId = _get(namedInsured, 'integrationId.value');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const componentOverrides = {};

    useEffect(() => {
        // if prior policy update records has lapse record(with given reason), we dont want to show it for auto2.0 states
        setPriorPolicyUpdatesVMWithoutLapse(_get(priorPolicyUpdatesVM, 'value', [])
            .filter((priorPolicyUpdate) =>
                priorPolicyUpdate.type !== 'PriorPolicyLapseReason_Ext'
                || !priorPolicyUpdateReasonAvailableValues.some((availableValue) => availableValue.code === priorPolicyUpdate.reason)
                || !isAuto2dot0State
            ))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priorPolicyUpdatesVM?.value?.length, priorPolicyUpdateReasonAvailableValues])


    useEffect(() => {
        const priorPolicyUpdateHasReasonField = priorPolicyUpdatesVMWithoutLapseRecords
            .find((record) => record.reason !== undefined || record.type === 'MilitaryDeployment_Ext');

        // remove reason for no insurance field if we dont have any prior policy  updates with reason field
        if (!priorPolicyUpdateHasReasonField) {
            const updatedGridLables = gridColumnLabels.filter((label) => label.id !== 'reasonForNoInsurance');

            if (!_isEqual(updatedGridLables, gridColumnLabels)) {
                setGridColumnLabels(updatedGridLables);
            }

            const updatedGridStructure = {
                ...gridStructureConfig,
                componentProps: {
                    ...gridStructureConfig.componentProps,
                    columns: ['0.5fr', '0.5fr', '0.5fr', '0.5fr', '0.5fr', '0.5fr', '0.5fr', '0.5fr', '0.3fr', '0.3fr', '0.2fr'
                    ]
                }
            }

            if (!_isEqual(gridStructureConfig, updatedGridStructure)) {
                setGridStructureConfig(updatedGridStructure);
            }
        }
    }, [gridColumnLabels, gridStructureConfig, priorPolicyUpdatesVMWithoutLapseRecords]);

    const getAvailableValuesForNoPriorPolicy = useCallback(() => {
        let values = [];

        values = viewModelService.productMetadata.get('pc').types
            .getTypelist('NoPriorPolicyReason_Ext').codes;

        if (!values || values.length === 0) { return undefined; }

        const availableValues = values.map((code) => ({
            code: code.code,
            name: translator({ id: code.name })
        }));

        return availableValues;
    }, [viewModelService, translator]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;

        if (onValueChange && setPriorCarrierChanged) {
            onValueChange(value, fullPath);
            setPriorCarrierChanged(true);
        }
    }, [onValueChange, path, setPriorCarrierChanged]);

    const removePriorPolicy = useCallback((priorPolicyIndex) => {
        modalApi.showConfirm({
            title: messages.removePriorPolicyTitle,
            message: messages.removePriorPolicyDescription,
            confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'PRIOR CARRIER' }),
            cancelButtonText: e1pCommonMessages.cancel
        }).then((result) => {
            if (result !== 'cancel') {
                const totalPriorPolicies = _get(priorPoliciesVMList, 'value', []).length - 1;
                const pulledObject = _pullAt(priorPoliciesVMList.value, priorPolicyIndex);
                const priorPolPublicID = pulledObject.publicID ? `${priorPolicyIndex}pulledObject.publicID` : `${totalPriorPolicies}newPriorPolicy${totalPriorPolicies}`;

                disregardFieldValidation(`priorCarrierNameinput${priorPolPublicID}`);
                disregardFieldValidation(`previousPolicyinput${priorPolPublicID}`);
                disregardFieldValidation(`inceptionDateE1PDateComponent${priorPolPublicID}`);
                disregardFieldValidation(`lastDateOfCoverageE1PDateComponent${priorPolPublicID}`);
                handleValueChange(priorPoliciesVMList.value, 'value');
            }
        });
    }, [disregardFieldValidation, modalApi, handleValueChange, priorPoliciesVMList, translator]);
    /**
         * Helper function
         * Below function removes all manually entered prior carriers associated with given named insured
         */
    const removeAllPriorPolicies = useCallback(() => {
        modalApi.showConfirm({
            status: 'warning',
            icon: 'mi-error-outline',
            title: messages.removeAllPriorCarriersTitle,
            message: messages.removeAllPriorCarriersMessage,
            confirmButtonText: translator(e1pCommonMessages.removeItemButtonText, { itemToRemove: 'PRIOR CARRIERS' }),
            cancelButtonText: e1pCommonMessages.cancel
        }).then((result) => {
            if (result !== 'cancel') {

                const updatedPriorPoliciesList = [];

                for (let priorPolicyIndex = 0; priorPolicyIndex < _get(priorPoliciesVMList, 'value', []).length; priorPolicyIndex++) {
                    const priorPolicy = _get(priorPoliciesVMList.value, `[${priorPolicyIndex}]`);

                    // removing field validation for prior policy objects which are going to be removed
                    if (priorPolicy.integrationId === integrationId && priorPolicy.policySource === 'ManuallyAdded') {
                        const totalPriorPolicies = _get(priorPoliciesVMList, 'value', []).length - 1;

                        const priorPolPublicID = priorPolicy.publicID
                            ? `${priorPolicyIndex}pulledObject.publicID`
                            : `${totalPriorPolicies}newPriorPolicy${totalPriorPolicies}`;

                        disregardFieldValidation(`priorCarrierNameinput${priorPolPublicID}`);
                        disregardFieldValidation(`previousPolicyinput${priorPolPublicID}`);
                        disregardFieldValidation(`inceptionDateE1PDateComponent${priorPolPublicID}`);
                        disregardFieldValidation(`lastDateOfCoverageE1PDateComponent${priorPolPublicID}`);

                        const prefix = isSNI ? 'sni' : 'pni'

                        setComponentValidation(true, `${prefix}PriorPolicyManualGridID`);
                    }
                    else {
                        updatedPriorPoliciesList.push(priorPolicy);
                    }
                }

                _set(
                    priorPoliciesVMList,
                    'value',
                    updatedPriorPoliciesList
                )
                handleValueChange(priorPoliciesVMList.value, 'value');
                // setRemovingPriorCarriers(false);
            }
        });
    }, [modalApi, translator, priorPoliciesVMList, handleValueChange, integrationId, disregardFieldValidation, isSNI, setComponentValidation]);

    const checkCoverageLapse = useCallback((e, childIndex) => {
        let isCoverageLapse = false;
        const policyStartISODate = _get(policyStartDate, 'value.isodate_Ext');

        if (e && e.value) {
            const tempDate = new Date(e.value.year, e.value.month, e.value.day);

            tempDate.setDate(tempDate.getDate() + 1);

            if (policyStartISODate
                > tempDate.toISOString()) {
                isCoverageLapse = true;
            } else {
                isCoverageLapse = false;
            }
        } else if (e && e.year && e.day && e.month) {
            const tempDate = new Date(e.year, e.month, e.day);

            tempDate.setDate(tempDate.getDate() + 1);

            if (policyStartISODate
                > tempDate.toISOString()) {
                isCoverageLapse = true;
            } else {
                isCoverageLapse = false;
            }
        }

        if (isCoverageLapse) {
            handleValueChange(false, `children[${childIndex}].continuousCoverageInd`);
        } else {
            handleValueChange(true, `children[${childIndex}].continuousCoverageInd`);
        }

        return isCoverageLapse;
    }, [handleValueChange, policyStartDate]);

    const generatePriorPolicyUpdateData = useCallback(() => {
        const allUpdates = priorPolicyUpdatesVMWithoutLapseRecords
            .filter((priorPolUpdate) => priorPolUpdate.integrationId === integrationId)
            .map((priorPolUpdate, priorPolUpdateindex) => {
                // need to retun all the column values for prior policy update
                const rowRecord = [];
                const priorPolPublicID = priorPolUpdate.publicID;

                gridColumnLabels.forEach((label, index) => {
                    switch (label.id) {
                        case 'reasonForNoInsurance':
                            rowRecord.push(
                                {
                                    id: `${label.id}UpdateGridItem${priorPolUpdateindex}${priorPolPublicID}`,
                                    type: 'container',
                                    component: 'GridItem',
                                    componentProps: {
                                        textAlign: 'center',
                                        colSpan: 1
                                    },
                                    content: [
                                        {
                                            id: `${label.id}dropdownselect${priorPolUpdateindex}${priorPolPublicID}`,
                                            type: 'field',
                                            component: 'DropdownSelect',
                                            componentProps: {
                                                availableValues: getAvailableValuesForNoPriorPolicy(),
                                                value: priorPolUpdate.type === 'MilitaryDeployment_Ext' ? 'activeMilitary' : priorPolUpdate.reason,
                                                readOnly: true,
                                                placeholder: ''
                                            }
                                        }
                                    ]
                                }
                            );
                            break;
                        case 'comprehensiveCoverage':
                        case 'collisionCoverage':
                            rowRecord.push(
                                {
                                    id: `${label.id}UpdateGridItem${priorPolUpdateindex}${priorPolPublicID}`,
                                    type: 'container',
                                    component: 'GridItem',
                                    componentProps: {
                                        textAlign: 'center',
                                        colSpan: 1
                                    },
                                    content: [
                                        {
                                            id: `${label.id}CheckboxField${priorPolUpdateindex}${priorPolPublicID}`,
                                            type: 'field',
                                            component: 'Checkbox',
                                            componentProps: {
                                                readOnly: true
                                            }
                                        }
                                    ]
                                }
                            );
                            break;
                        case 'coverageLapse':
                            rowRecord.push(
                                {
                                    id: `${label.id}UpdateGridItem${priorPolUpdateindex}${priorPolPublicID}`,
                                    type: 'container',
                                    component: 'GridItem',
                                    componentProps: {
                                        textAlign: 'center',
                                        colSpan: 1
                                    },
                                    content: [
                                        {
                                            id: `${label.id}CheckboxField${priorPolUpdateindex}${priorPolPublicID}`,
                                            type: 'field',
                                            component: 'Checkbox',
                                            componentProps: {
                                                readOnly: true,
                                                value: priorPolUpdate.type === 'PriorPolicyLapseReason_Ext'
                                            }
                                        }
                                    ]
                                }
                            );
                            break;
                        case 'action':
                            // no action required for priorPolicyUpdate, user cannot add, edit, delete update
                            rowRecord.push(
                                {
                                    id: `${label.id}UpdateGridItem${priorPolUpdateindex}${priorPolPublicID}`,
                                    type: 'container',
                                    component: 'GridItem',
                                    componentProps: {
                                        textAlign: 'center',
                                        colSpan: 1
                                    },
                                    content: [
                                        {
                                            id: `${label.id}span${priorPolUpdateindex}${priorPolPublicID}`,
                                            type: 'element',
                                            component: 'span',
                                            content: ''
                                        }
                                    ]
                                }
                            );
                            break;
                        case 'inceptionDate':
                            rowRecord.push(
                                {
                                    id: `${label.id}UpdateGridItem${priorPolUpdateindex}${priorPolPublicID}`,
                                    type: 'container',
                                    component: 'GridItem',
                                    componentProps: {
                                        textAlign: 'left',
                                        colSpan: 1
                                    },
                                    content: [
                                        {
                                            id: `${label.id}E1PDateComponent${priorPolUpdateindex}${priorPolPublicID}`,
                                            type: 'field',
                                            component: 'E1PDateComponent',
                                            componentProps: {
                                                isRequired: true,
                                                path: `children[${priorPolUpdateindex}].startDate`,
                                                readOnly: true,
                                                dateDTO: e1pDateUtil.convertToUTC(priorPolUpdate.startDate),
                                            }
                                        }
                                    ]
                                }
                            );
                            break;
                        case 'lastDateOfCoverage':
                            rowRecord.push(
                                {
                                    id: `${label.id}UpdateGridItem${priorPolUpdateindex}${priorPolPublicID}`,
                                    type: 'container',
                                    component: 'GridItem',
                                    componentProps: {
                                        textAlign: 'left',
                                        colSpan: 1
                                    },
                                    content: [
                                        {
                                            id: `${label.id}E1PDateComponent${priorPolUpdateindex}${priorPolPublicID}`,
                                            type: 'field',
                                            component: 'E1PDateComponent',
                                            componentProps: {
                                                isRequired: true,
                                                path: `children[${priorPolUpdateindex}].endDate`,
                                                readOnly: true,
                                                dateDTO: e1pDateUtil.convertToUTC(priorPolUpdate.endDate),
                                            }
                                        }
                                    ]
                                }
                            );
                            break;

                        default:
                            // show readonly always, update cannot be modified
                            rowRecord.push(
                                {
                                    id: `${label.id}priorPolicyUpdateGridItem${index}`,
                                    type: 'container',
                                    component: 'GridItem',
                                    componentProps: {
                                        textAlign: 'center',
                                        colSpan: 1
                                    },
                                    content: [
                                        {
                                            id: `${label.id}input${priorPolUpdateindex}${priorPolPublicID}`,
                                            type: 'field',
                                            component: 'Input',
                                            componentProps: {
                                                path: 'carrierName',
                                                readOnly: true
                                            }
                                        }
                                    ]
                                }
                            );
                            break;
                    }
                });

                const gridStructure = { ...gridStructureConfig };

                gridStructure.id = `priorPolicyUpdateGrid${priorPolUpdateindex}${priorPolPublicID}`;
                gridStructure.componentProps.className = styles.contentGrid;
                gridStructure.content = rowRecord;

                return gridStructure;
            });

        return allUpdates.flat();
    }, [
        getAvailableValuesForNoPriorPolicy, gridColumnLabels, gridStructureConfig,
        integrationId, priorPolicyUpdatesVMWithoutLapseRecords
    ]);

    const handleComprehensiveCoverageToggle = useCallback((value, coveragesPath, coverages) => {
        if (value) {
            coverages.push({ coverageCode: 'Comprehensive' });
        } else {
            _remove(coverages, (coverage) => coverage.coverageCode === 'Comprehensive');
        }

        handleValueChange(coverages, coveragesPath);
    }, [handleValueChange]);

    const handleCollisionCoverageToggle = useCallback((value, coveragesPath, coverages) => {
        if (value) {
            coverages.push({ coverageCode: 'Collision' });
        } else {
            _remove(coverages, (coverage) => coverage.coverageCode === 'Collision');
        }

        handleValueChange(coverages, coveragesPath);
    }, [handleValueChange]);

    const addToOverrides = useCallback((objectToAdd) => {
        if (objectToAdd.id && objectToAdd.componentProps) {
            componentOverrides[objectToAdd.id] = { ...objectToAdd.componentProps };
        }
    }, [componentOverrides]);

    const generatePriorPolicyData = useCallback(() => {
        const allPriorPolicyFormatedData = [];

        priorPoliciesVMList.value
            .forEach((priorPolicy, priorPolicyindex) => {
                // doing filter would change the index hence doing filter by  integration id
                if (priorPolicy.integrationId === integrationId && priorPolicy.policySource !== 'Prefill') {
                    // need to retun all the column values for prior policy
                    const rowRecord = [];
                    const priorPolPublicID = priorPolicy.publicID ? priorPolicy.publicID : `newPriorPolicy${priorPolicyindex}`;
                    const BICovIndex = _findIndex(priorPolicy.coverages, (coverage) => coverage.coverageCode === 'BodilyInjury');

                    gridColumnLabels.forEach((label) => {
                        let columnObject = {};

                        switch (label.id) {
                            case 'priorCarrierName':
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'left',
                                            colSpan: 1,
                                            className: styles.fillTypeahead
                                        },
                                        content: [
                                            {
                                                id: `${label.id}input${priorPolicyindex}${priorPolPublicID}`,
                                                type: 'field',
                                                component: 'TypeaheadMultiSelect',
                                                componentProps: {
                                                    path: `children[${priorPolicyindex}].carrierName`,
                                                    required: priorPolicy.policySource !== 'Prefill' && !priorPolicy.carrierName,
                                                    readOnly: priorPolicy.policySource === 'Prefill' || viewOnlyMode,
                                                    allowNew: true,
                                                    singleSelect: true,
                                                    availableValues: priorCarrierNames,
                                                    autoTrim: true,
                                                    stickyIndicator: true
                                                }
                                            }
                                        ]
                                    }
                                );
                                break;
                            case 'reasonForNoInsurance':
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'center',
                                            colSpan: 1
                                        },
                                        content: [
                                            {
                                                id: `${label.id}dropdownselect${priorPolicyindex}${priorPolPublicID}`,
                                                type: 'element',
                                                component: 'span',
                                                content: '-'
                                            }
                                        ]
                                    }
                                );
                                break;
                            case 'previousPolicy':
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'left',
                                            colSpan: 1
                                        },
                                        content: [
                                            {
                                                id: `${label.id}input${priorPolicyindex}${priorPolPublicID}`,
                                                type: 'field',
                                                component: 'Input',
                                                componentProps: {
                                                    path: `children[${priorPolicyindex}].policyNumber`,
                                                    readOnly: priorPolicy.policySource === 'Prefill' || viewOnlyMode
                                                }
                                            }
                                        ]
                                    }
                                );
                                break;
                            case 'inceptionDate':
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'left',
                                            colSpan: 1
                                        },
                                        content: [
                                            {
                                                id: `${label.id}E1PDateComponent${priorPolicyindex}${priorPolPublicID}`,
                                                type: 'field',
                                                component: 'E1PDateComponent',
                                                componentProps: {
                                                    isRequired: true,
                                                    path: `children[${priorPolicyindex}].policyInceptionDate`,
                                                    readOnly: priorPolicy.policySource === 'Prefill' || viewOnlyMode,
                                                    updateDateDto: handleValueChange,
                                                    dateDTO: e1pDateUtil.convertToUTC(priorPolicy.policyInceptionDate),
                                                    showErrors,
                                                    onValidate: setComponentValidation
                                                }
                                            }
                                        ]
                                    }
                                );
                                break;
                            case 'lastDateOfCoverage':
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'left',
                                            colSpan: 1
                                        },
                                        content: [
                                            {
                                                id: `${label.id}E1PDateComponent${priorPolicyindex}${priorPolPublicID}`,
                                                type: 'field',
                                                component: 'E1PDateComponent',
                                                componentProps: {
                                                    isRequired: true,
                                                    path: `children[${priorPolicyindex}].policyHolderExpirationDate`,
                                                    readOnly: priorPolicy.policySource === 'Prefill' || viewOnlyMode,
                                                    updateDateDto: ((value, changedPath) => {
                                                        handleValueChange(value, changedPath);
                                                        checkCoverageLapse(value, priorPolicyindex);
                                                    }),
                                                    dateDTO: e1pDateUtil.convertToUTC(priorPolicy.policyHolderExpirationDate),
                                                    showErrors,
                                                    onValidate: setComponentValidation
                                                }
                                            }
                                        ]
                                    }
                                );
                                break;
                            case 'biLimitPP':
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'left',
                                            colSpan: 1,
                                            className: styles.fillTypeahead
                                        },
                                        content: [
                                            {
                                                id: `${label.id}input${priorPolicyindex}${priorPolPublicID}`,
                                                type: 'field',
                                                component: 'LimitsDropdownComponent',
                                                componentProps: {
                                                    id: `${label.id}input${priorPolicyindex}${priorPolPublicID}`,
                                                    path: `children[${priorPolicyindex}].coverages.children[${BICovIndex}].perPersonLimit`,
                                                    readOnly: priorPolicy.policySource === 'Prefill' || viewOnlyMode,
                                                    onLimitChange: handleValueChange,
                                                    limit: _get(priorPoliciesVMList, `children[${priorPolicyindex}].coverages.children[${BICovIndex}].perPersonLimit.value`)
                                                }
                                            }
                                        ]
                                    }
                                );
                                break;
                            case 'biLimitPO':
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'left',
                                            colSpan: 1,
                                            className: styles.fillTypeahead
                                        },
                                        content: [
                                            {
                                                id: `${label.id}input${priorPolicyindex}${priorPolPublicID}`,
                                                type: 'field',
                                                component: 'LimitsDropdownComponent',
                                                componentProps: {
                                                    id: `${label.id}input${priorPolicyindex}${priorPolPublicID}`,
                                                    path: `children[${priorPolicyindex}].coverages.children[${BICovIndex}].perOccurrenceLimit`,
                                                    readOnly: priorPolicy.policySource === 'Prefill' || viewOnlyMode,
                                                    onLimitChange: handleValueChange,
                                                    limit: _get(priorPoliciesVMList, `children[${priorPolicyindex}].coverages.children[${BICovIndex}].perOccurrenceLimit.value`)
                                                }
                                            }
                                        ]
                                    }
                                );
                                break;
                            case 'biLimitCSL':
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'left',
                                            colSpan: 1,
                                            className: styles.fillTypeahead
                                        },
                                        content: [
                                            {
                                                id: `${label.id}input${priorPolicyindex}${priorPolPublicID}`,
                                                type: 'field',
                                                component: 'LimitsDropdownComponent',
                                                componentProps: {
                                                    id: `${label.id}input${priorPolicyindex}${priorPolPublicID}`,
                                                    path: `children[${priorPolicyindex}].coverages.children[${BICovIndex}].combinedSingleLimit`,
                                                    readOnly: priorPolicy.policySource === 'Prefill' || viewOnlyMode,
                                                    onLimitChange: handleValueChange,
                                                    limit: _get(priorPoliciesVMList, `children[${priorPolicyindex}].coverages.children[${BICovIndex}].combinedSingleLimit.value`)
                                                }
                                            }
                                        ]
                                    }
                                );
                                break;
                            case 'comprehensiveCoverage':
                                columnObject = {
                                    id: `${label.id}CompCheckboxField${priorPolicyindex}${priorPolPublicID}`,
                                    type: 'field',
                                    component: 'Checkbox',
                                    componentProps: {
                                        value: _some(priorPolicy.coverages, (cov) => cov.coverageCode === 'Comprehensive'),
                                        onValueChange: (value) => handleComprehensiveCoverageToggle(value, `children[${priorPolicyindex}].coverages`, priorPolicy.coverages),
                                        readOnly: priorPolicy.policySource === 'Prefill' || viewOnlyMode
                                    }
                                };
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'center',
                                            colSpan: 1
                                        },
                                        content: [
                                            columnObject
                                        ]
                                    }
                                );
                                addToOverrides(columnObject);
                                break;
                            case 'collisionCoverage':
                                columnObject = {
                                    id: `${label.id}CollisionCheckboxField${priorPolicyindex}${priorPolPublicID}`,
                                    type: 'field',
                                    component: 'Checkbox',
                                    componentProps: {
                                        value: _some(priorPolicy.coverages, (cov) => cov.coverageCode === 'Collision'),
                                        onValueChange: (value) => handleCollisionCoverageToggle(value, `children[${priorPolicyindex}].coverages`, priorPolicy.coverages),
                                        readOnly: priorPolicy.policySource === 'Prefill' || viewOnlyMode
                                    }
                                };
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'center',
                                            colSpan: 1
                                        },
                                        content: [
                                            columnObject
                                        ]
                                    }
                                );
                                addToOverrides(columnObject);
                                break;
                            case 'coverageLapse':
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'center',
                                            colSpan: 1
                                        },
                                        content: [
                                            {
                                                id: `${label.id}CheckboxField${priorPolicyindex}${priorPolPublicID}`,
                                                type: 'field',
                                                component: 'Checkbox',
                                                componentProps: {
                                                    readOnly: true,
                                                    value: priorPolicy.policyHolderExpirationDate === undefined ? false : !priorPolicy.continuousCoverageInd
                                                }
                                            }
                                        ]
                                    }
                                );
                                break;
                            case 'action':
                                // delete manually added Prior Policies
                                rowRecord.push(
                                    {
                                        id: `${label.id}priorPolicyGridItem${priorPolicyindex}${priorPolPublicID}`,
                                        type: 'container',
                                        component: 'GridItem',
                                        componentProps: {
                                            textAlign: 'center',
                                            colSpan: 1
                                        },
                                        content: [
                                            {
                                                id: `${label.id}deletePriorPolicy${priorPolicyindex}${priorPolPublicID}`,
                                                type: 'action',
                                                component: 'IconButton',
                                                componentProps: {
                                                    icon: 'mi-delete',
                                                    size: 'small',
                                                    onClick: () => {
                                                        removePriorPolicy(priorPolicyindex);
                                                        setPriorCarrierChanged(true);
                                                    },
                                                    readOnly: viewOnlyMode,
                                                    // IAP-5650 Similar to loss/ violation don't show delete at record 1, if only one record 
                                                    visible: !viewOnlyMode && priorPoliciesVMList.value.filter((priorPol) => priorPol.integrationId === integrationId && priorPol.policySource !== 'Prefill').length > 1
                                                }
                                            }
                                        ]
                                    }
                                );
                                break;
                            default:
                                break;
                        }
                    });

                    const gridStructure = { ...gridStructureConfig };

                    gridStructure.id = `priorPolicyGrid${priorPolicyindex}`;
                    gridStructure.componentProps.className = styles.contentGrid;
                    gridStructure.content = rowRecord;
                    allPriorPolicyFormatedData.push(gridStructure);
                }
            });

        return allPriorPolicyFormatedData.flat();
    }, [
        priorPoliciesVMList, integrationId, gridColumnLabels, gridStructureConfig,
        viewOnlyMode, priorCarrierNames, handleValueChange, showErrors, setComponentValidation,
        addToOverrides, checkCoverageLapse, handleComprehensiveCoverageToggle,
        handleCollisionCoverageToggle, removePriorPolicy, setPriorCarrierChanged
    ]);

    const generateTableDataForPriorPolicy = useCallback(() => {
        const tableData = [];
        // generate Headers

        const headers = generateHeadersForPriorPolicy(gridColumnLabels, gridStructureConfig);

        tableData.push(headers);

        // generate formatted data for priorPolicyUpdateDTO
        const priorUpdates = generatePriorPolicyUpdateData();

        tableData.push(...priorUpdates);

        // generate formatted data for priorPolicyDTO
        const priorPolicies = generatePriorPolicyData();

        tableData.push(...priorPolicies);
        // Set alternate row background color to grey
        tableData.forEach((data, index) => {
            if (index % 2 !== 0) {
                tableData[index].componentProps.className = styles.backgroundGrey;
            }
        });

        return tableData;
    }, [generatePriorPolicyData, generatePriorPolicyUpdateData, gridColumnLabels, gridStructureConfig]);

    const doesManualPoliciesExistForNI = useCallback(
        () =>
            integrationId &&
            _some(
                priorPoliciesVMList.value,
                (priorPolicy) =>
                    priorPolicy.integrationId === integrationId &&
                    priorPolicy.policySource === 'ManuallyAdded'
            ),
        [integrationId, priorPoliciesVMList.value]
    );

    const doesPriorPolicyUpdatesExistForNI = useCallback(
        () =>
            integrationId &&
            _some(
                priorPolicyUpdatesVMWithoutLapseRecords,
                (priorUpdate) => priorUpdate.integrationId === integrationId
            ),
        [integrationId, priorPolicyUpdatesVMWithoutLapseRecords]
    );

    // Add prior policy update record for policy lapse reason
    const addPriorPolicyUpdateForNoInsuranceDropdown = useCallback((value, priorPolicyUpdateList) => {
        if (_isEmpty(priorPolicyUpdateList)) {
            _set(transactionVM, 'lobData.personalAuto_EA.priorPolicyUpdates.value', []);
        }

        const priorPolicyUpdatesObj = {
            startDate: undefined,
            endDate: undefined,
            integrationId,
            policyRole: isSNI ? 'PolicySecNamedInsured' : 'PolicyPriNamedInsured',
            type: 'PriorPolicyLapseReason_Ext',
            reason: value
        };

        const tempPriorPolicyUpdatesVM = viewModelService.create(
            priorPolicyUpdatesObj,
            'pc',
            'amfam.edge.capabilities.policyjob.common.priorpolicy.dto.PriorPolicyUpdateDTO'
        );

        priorPolicyUpdateList.push(tempPriorPolicyUpdatesVM.value);

        if (onValueChange) {
            onValueChange(priorPolicyUpdateList, 'lobData.personalAuto_EA.priorPolicyUpdates');

            if (setPriorCarrierChanged) {
                setPriorCarrierChanged(true);
            }
        }
    }, [integrationId, isSNI, onValueChange, setPriorCarrierChanged, transactionVM, viewModelService]);

    const noInsuranceDropdownValueChange = useCallback((value, _, { beforeValue }) => {
        // removing prior policyUpdate if it was there
        const priorPolicyUpdateList = _get(transactionVM, 'lobData.personalAuto_EA.priorPolicyUpdates.value', []);

        if (beforeValue) {
            const priorPolicyUpdateIndexToBeRemoved = priorPolicyUpdateList
                .findIndex((priorPolicyUpdate) =>
                    priorPolicyUpdate.reason === beforeValue
                    && priorPolicyUpdate.integrationId === integrationId
                    && priorPolicyUpdate.type === 'PriorPolicyLapseReason_Ext');

            if (priorPolicyUpdateIndexToBeRemoved > -1) {
                priorPolicyUpdateList.splice(priorPolicyUpdateIndexToBeRemoved, 1);
            }

            if (onValueChange) {
                onValueChange(priorPolicyUpdateList, 'lobData.personalAuto_EA.priorPolicyUpdates');
            }
        }

        if (value) {
            addPriorPolicyUpdateForNoInsuranceDropdown(value, priorPolicyUpdateList)
        }

    }, [addPriorPolicyUpdateForNoInsuranceDropdown, integrationId, onValueChange, transactionVM]);

    const getNoInsuranceDropdownValue = useCallback(() => {
        const noInsurancePriorPolicyUpdate = _get(transactionVM, 'lobData.personalAuto_EA.priorPolicyUpdates.value', [])
            .find((priorPolicyUpdate) =>
                priorPolicyUpdate.type === 'PriorPolicyLapseReason_Ext'
                && priorPolicyUpdateReasonAvailableValues.some((availableValue) => availableValue.code === priorPolicyUpdate.reason)
            );

        if (noInsurancePriorPolicyUpdate) {
            return noInsurancePriorPolicyUpdate.reason;
        }

        return undefined;
    }, [priorPolicyUpdateReasonAvailableValues, transactionVM]);

    // remove prior policy update whihc was saved when user selected dropdown value for no prior insurance
    const removePriorPolicyUpdatesForNoInsurance = useCallback(() => {
        const priorPolicyUpdateList = _get(transactionVM, 'lobData.personalAuto_EA.priorPolicyUpdates.value', []);
        const priorPolicyUpdateIndexToBeRemoved = priorPolicyUpdateList
            .findIndex((priorPolicyUpdate) =>
                priorPolicyUpdateReasonAvailableValues.some((availableValue) => availableValue.code === priorPolicyUpdate.reason)
                && priorPolicyUpdate.integrationId === integrationId
                && priorPolicyUpdate.type === 'PriorPolicyLapseReason_Ext');

        if (priorPolicyUpdateIndexToBeRemoved > -1) {
            priorPolicyUpdateList.splice(priorPolicyUpdateIndexToBeRemoved, 1);
        }

        if (onValueChange) {
            onValueChange(priorPolicyUpdateList, 'lobData.personalAuto_EA.priorPolicyUpdates');

            if (setPriorCarrierChanged) {
                setPriorCarrierChanged(true);
            }
        }
    }, [integrationId, onValueChange, priorPolicyUpdateReasonAvailableValues, setPriorCarrierChanged, transactionVM]);

    useEffect(() => {
        // add prior policy update as default value if dropdown is visible and value is not already added
        if (
            !doesManualPoliciesExistForNI()
            && !doesPriorPolicyUpdatesExistForNI()
            && isAuto2dot0State
            && getNoInsuranceDropdownValue() === undefined
        ) {
            addPriorPolicyUpdateForNoInsuranceDropdown(
                'ownedVehicleNotCarryInsurance',
                _get(transactionVM, 'lobData.personalAuto_EA.priorPolicyUpdates.value', []
                ));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onToggleValueChange = useCallback((value) => {
        if (value) {
            // only create new priorPolicy object if there is no manuallyAdded prior policies exists 
            if (!doesManualPoliciesExistForNI()) {
                createPriorPolicyVM(integrationId);
            }
            
            setPriorCarrierChanged(true);
            removePriorPolicyUpdatesForNoInsurance();

        } else {
            removeAllPriorPolicies()
        }
    }, [createPriorPolicyVM, doesManualPoliciesExistForNI,
        integrationId, removeAllPriorPolicies,
        removePriorPolicyUpdatesForNoInsurance, setPriorCarrierChanged]);

    useEffect(() => {
        if (manualPriorCarrierFuncRef) {
            manualPriorCarrierFuncRef.current = onToggleValueChange;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition,
            showErrors,
            autoComplete: false,
            showRequired: true
        },
        namedInsuredName: {
            value: _get(namedInsured, 'person.displayName.value'),
            label: isSNI ? translator(messages.sniLabel) : translator(messages.pniLabel),
            labelPosition: 'left'
        },
        insuredHasCoverageInForce: {
            className: isAuto2dot0State ? styles.insuredHasCoverageInForce : null
        },
        noInsuraceDropdown: {
            availableValues: priorPolicyUpdateReasonAvailableValues,
            visible: !doesManualPoliciesExistForNI() && !doesPriorPolicyUpdatesExistForNI() && isAuto2dot0State,
            onValueChange: noInsuranceDropdownValueChange,
            value: getNoInsuranceDropdownValue(),
            readOnly: viewOnlyMode
        },
        priorCarrier: {
            onValueChange: (value) => {
                onToggleValueChange(value);
            },
            required: false,
            value: doesManualPoliciesExistForNI() || doesPriorPolicyUpdatesExistForNI(),
            visible: !doesPriorPolicyUpdatesExistForNI(),
            readOnly: viewOnlyMode
        },
        modifyPriorCarrierContainerID: {
            visible: doesManualPoliciesExistForNI() || doesPriorPolicyUpdatesExistForNI()
        },
        priorPolicyContainerDiv: {
            content: generateTableDataForPriorPolicy(),
            visible: doesManualPoliciesExistForNI() || doesPriorPolicyUpdatesExistForNI()
        },
        priorCarrierComponentWithAddButton: {
            visible: doesManualPoliciesExistForNI() || doesPriorPolicyUpdatesExistForNI()
        },
        addPriorCarrierForNamedInsured: {
            onClick: () => {
                createPriorPolicyVM(integrationId);
                setPriorCarrierChanged(true);
            },
            disabled: viewOnlyMode,
            visible: !viewOnlyMode
        },
        priorCarrierToggleWithNoInsuraceReasonDropdown: {
            // IAP-5650: Don't display one of the prior policies is marked isRemoved (disputed)
            visible: priorPoliciesVMList.value.findIndex((priorPol) => priorPol.isRemoved && priorPol.integrationId === integrationId) === -1
        },
        ...componentOverrides
    };

    const readValue = useCallback(
        (fieldId, fieldPath) =>
            readViewModelValue(
                metadata.pageContent,
                priorPoliciesVMList,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [overrideProps, priorPoliciesVMList]
    );

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onRemovePriorPolicy: removePriorPolicy,
            onValidate: setComponentValidation,
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={priorPoliciesVMList}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

PriorPolicyManualGridComponent.propTypes = {
    data: PropTypes.shape({}),
    priorPolicyUpdatesVM: PropTypes.shape({
        value: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    policyStartDate: PropTypes.shape({
        value: PropTypes.shape({
            isodate_Ext: PropTypes.string,
        })
    }),
    namedInsured: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    isSNI: PropTypes.bool.isRequired,
    setPriorCarrierChanged: PropTypes.func,
    showErrors: PropTypes.bool,
    transactionVM: PropTypes.shape({}).isRequired,
    priorPolicyUpdateReasonAvailableValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
PriorPolicyManualGridComponent.defaultProps = {
    data: {},
    priorPolicyUpdatesVM: {},
    policyStartDate: {},
    namedInsured: {},
    labelPosition: 'top',
    path: undefined,
    id: undefined,
    viewOnlyMode: false,
    showErrors: false,
    setPriorCarrierChanged: undefined
};
export default PriorPolicyManualGridComponent;