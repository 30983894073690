import { defineMessages } from 'react-intl';

export default defineMessages({
    creatingYourOfferingMessage: {
        id: 'quoteandbind.ea.directives.MultiQuoteView.Please wait while we create your offering...',
        defaultMessage: 'Please wait while we create your offering...'
    },
    generalCoverages: {
        id: 'quoteandbind.ea.directives.MultiQuoteView.General Coverages - Apply to all vehicles',
        defaultMessage: 'General Coverages - Apply to all vehicles'
    },
    vehicleSpecificCoverage: {
        id: 'quoteandbind.ea.directives.MultiQuoteView.Vehicle-',
        defaultMessage: 'Vehicle-Specific Coverages - '
    },
    eaUnderwrtingGuidelines: {
        id: 'quoteandbind.ea.quotePage.I verify this does not violate any of the',
        defaultMessage: 'I verify this does not violate any of the'
    },
    eaConsumerReport: {
        id: 'quoteandbind.ea.quotePage.I accept the Consumer Report Disclosure ',
        defaultMessage: 'I accept the Consumer Report Disclosure'
    },
    eaLegalDisclaimer: {
        id: 'quoteandbind.ea.quotePage.I agree that the information collected for this application can be used in my applications for other policies within the program',
        defaultMessage: 'I agree that the information collected for this application can be used in my applications for other policies within the program'
    },
    underliningGuidelineTitle: {
        id: 'quoteandbind.ea.quotePage.underwritingGuidelines',
        defaultMessage: 'Underwriting Guidelines'
    },
    legalDisclaimerTitle: {
        id: 'quoteandbind.ea.quotePage.legalDisclaimer',
        defaultMessage: 'Legal Disclaimer'
    },
    consumerReportTitle: {
        id: 'quoteandbind.ea.quotePage.consumerReport',
        defaultMessage: 'Consumer Report'
    }
});
