import { defineMessages } from 'react-intl';

// It contains list of Homeowners validation messages. New eh validations should be added here.
export default defineMessages({
    recalculateReplacementCostText: {
        id: 'e1p.platform.lob.eh.error.Recalculate Replacement Cost to continue.',
        defaultMessage: 'Recalculate Replacement Cost to continue.'
    },
    discountAvailabilityRatingPlan: {
        id: 'e1p.platform.lob.eh.info.Discount availability is based on the rating plan.',
        defaultMessage: 'Discount availability is based on the rating plan.'
    },
    noChangesRecalculateReplacementCost: {
        id: 'e1p.platform.lob.eh.info.Replacement Cost does not need to be recalculated.',
        defaultMessage: 'Replacement Cost does not need to be recalculated.'
    },
    inspectionInteriorExterior: {
        id: 'e1p.platform.lob.eh.info.An interior inspection of the property is required. The insured will be contacted to set up a convenient time.',
        defaultMessage: 'An interior inspection of the property is required. The insured will be contacted to set up a convenient time.\n'
    },
    inspectionExterior: {
        id: 'e1p.platform.lob.eh.info.An inspection of the property may be required.',
        defaultMessage: 'An inspection of the property may be required.'
    },
    ACVAppliedBasedOnAge: {
        id: 'e1p.platform.lob.eh.info.Actual Cash Value Loss Settlement - Windstorm or Hail Losses to Roof Surfacing has been applied based on the roof age.',
        defaultMessage: 'Actual Cash Value Loss Settlement - Windstorm or Hail Losses to Roof Surfacing has been applied based on the roof age.'
    },
    ACVApplies: {
        id: 'e1p.platform.lob.eh.info.Actual Cash Value Loss Settlement - Windstorm or Hail Losses to Roof Surfacing applies.',
        defaultMessage: 'Actual Cash Value Loss Settlement - Windstorm or Hail Losses to Roof Surfacing applies.'
    },
    yearRoofInfoMessage: {
        id: 'e1p.platform.lob.eh.info.Property prefill reports have been ordered, but the roof age does not meet our guidelines. Please update the Year Roof Installed if applicable, verify the information below, complete the missing fields, and calculate the replacement cost to continue.',
        defaultMessage: 'Property prefill reports have been ordered, but the roof age does not meet our guidelines. Please update the Year Roof Installed if applicable, verify the information below, complete the missing fields, and calculate the replacement cost to continue.'
    },
    propertyInfoRequired: {
        id: 'e1p.platform.lob.eh.info.Property details are required to continue.',
        defaultMessage: 'Property details are required to continue.'
    },
    ehMultiProductDiscountMessage: {
        id: 'e1p.platform.lob.eh.info.The Multi-Product Discount has been granted based on the presence of a companion Auto policy. If the companion policy cannot be found the discount will be removed.',
        defaultMessage: 'The Multi-Product Discount has been granted based on the presence of a companion Auto policy. If the companion policy cannot be found the discount will be removed.'
    },
    phoneNumberRequiredForInteriorInspection: {
        id: 'e1p.platform.lob.eh.info.Home or Mobile Phone required for Interior Inspection.',
        defaultMessage: 'Home or Mobile Phone required for Interior Inspection.'
    },
    partialPrefillMessage: {
        id: 'e1p.platform.lob.eh.info.Property prefill reports have been ordered, but additional information is required to calculate the replacement cost and rate the policy. Please verify the information below, complete the missing fields, and calculate the replacement cost to continue.',
        defaultMessage: 'Property prefill reports have been ordered, but additional information is required to calculate the replacement cost and rate the policy. Please verify the information below, complete the missing fields, and calculate the replacement cost to continue.'
    },
    propertyPrefillUnsuccessfulInfoMessage: {
        id: 'e1p.platform.lob.eh.info.Property prefill reports were not returned. Please provide information about the property and calculate the replacement cost to continue.',
        defaultMessage: 'Property prefill reports were not returned. Please provide information about the property and calculate the replacement cost to continue.'
    },
    covAWillBeUpdatedBasedOnReplacementCostInfoMessage: {
        id: 'e1p.platform.lob.eh.info.Coverage A will be updated based on the Recalculated Replacement Cost. Coverage B, C, and D will be reset based on the updated Coverage A. Please reapply any changes to Coverage C and D amounts if applicable on the Coverages page.',
        defaultMessage: 'Coverage A will be updated based on the Recalculated Replacement Cost. Coverage B, C, and D will be reset based on the updated Coverage A. Please reapply any changes to Coverage C and D amounts if applicable on the Coverages page.'
    },
    insuredResidenceAddressWarningText: {
        id: 'e1p.platform.lob.eh.info.A Cancel/Rewrite transaction is required to make a correction to the Risk Address. Changes can be made to Mailing and Billing Addresses. Changes only apply to this policy. Other policies associated to this insured also need to be updated.',
        defaultMessage: 'A Cancel/Rewrite transaction is required to make a correction to the Risk Address. Changes can be made to Mailing and Billing Addresses. Changes only apply to this policy. Other policies associated to this insured also need to be updated.'
    },
    insuredHO4ResidenceAddressWarningText: {
        id: 'e1p.platform.lob.eh.info.Changes can be made to Risk, Mailing and Billing Addresses. Changes only apply to this policy. Other policies associated to this insured also need to be updated.',
        defaultMessage: 'Changes can be made to Risk, Mailing and Billing Addresses. Changes only apply to this policy. Other policies associated to this insured also need to be updated.'
    },
    windstormMitigationDiscount: {
        id: 'e1p.platform.lob.eh.info.Windstorm Mitigation Discount does not apply when Absolute Windstorm Exclusion applies.',
        defaultMessage: 'Windstorm Mitigation Discount does not apply when Absolute Windstorm Exclusion applies.'
    },
    prefillReportsOrdered: {
        id: 'e1p.platform.lob.eh.info.Property prefill reports have been ordered to determine property details used for rating and replacement cost. Details can be reviewed on the Property page.',
        defaultMessage: 'Property prefill reports have been ordered to determine property details used for rating and replacement cost. Details can be reviewed on the Property page.'
    },
    additionalInfoForVerifiedQuote: { 
        id: 'e1p.platform.lob.eh.info.The information below was used to provide an unverified quote. Please update the information so a verified premium can be calculated.',
        defaultMessage: 'The information below was used to provide an unverified quote. Please update the information so a verified premium can be calculated.'
    },
    missingTrusteeDetailsTPI: { 
        id: 'e1p.platform.lob.eh.error.Please complete the missing Trustee Detail fields.',
        defaultMessage: 'Please complete the missing Trustee Detail fields.'
    },
});
