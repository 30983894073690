import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextWizardStepMessage: {
        id: 'e1p.policyview.wizard.Loading next wizard step...',
        defaultMessage: 'Loading next wizard step...'
    },
    anErrorOccurred: {
        id: 'e1p.policyview.wizard.error.cancel',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'e1p.policyview.wizard.error.cancel.Error',
        defaultMessage: 'Error'
    },
    insured: {
        id: 'e1p.policyview.wizard.step.Insured',
        defaultMessage: 'Insured'
    },
    property: {
        id: 'e1p.policyview.wizard.step.Property',
        defaultMessage: 'Property'
    },
    coverage: {
        id: 'e1p.policyview.wizard.step.Coverage',
        defaultMessage: 'Coverage'
    },
    tpiPageTitle: {
        id: 'e1p.policyview.wizard.step.Third-Party Interest',
        defaultMessage: 'Third-Party Interest'
    }
});
