import { defineMessages } from 'react-intl';

export default defineMessages({
    close: {
        id: 'components.clauseComponent.CLOSE',
        defaultMessage: 'CLOSE'
    },
    editCoverages: {
        id: 'components.clauseComponent.EDIT COVERAGES',
        defaultMessage: 'EDIT COVERAGES'
    },
    editSchedules: {
        id: 'components.clauseComponent.EDIT SCHEDULES',
        defaultMessage: 'EDIT SCHEDULES'
    },
    viewCoverages: {
        id: 'components.clauseComponent.VIEW COVERAGES',
        defaultMessage: 'VIEW COVERAGES'
    },
    viewSchedules: {
        id: 'components.clauseComponent.VIEW SCHEDULES',
        defaultMessage: 'VIEW SCHEDULES'
    },
});
