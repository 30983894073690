import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';

/**
 * Helper function to show error messages for payment page
 * as we show custom UI messages for missing payement and missing signature
 * @param {Object[]} validationErrors 
 * @param {Boolean} shouldShowMissingPaymentError 
 * @param {Boolean} isSignatureMissing 
 * @param {Boolean} isUwIssuePresent 
 * @param {Function} translator 
 * @param {Boolean} removeUnnecessaryErrors 
 * @returns {Boolean}
 */
function getValidationErrorsForPaymentPage(
    validationErrors, shouldShowMissingPaymentError,
    isSignatureMissing, isUwIssuePresent, translator, removeUnnecessaryErrors) {
    const errors = removeUnnecessaryErrors ? validationErrors : [
        ...validationErrors,
        ...(shouldShowMissingPaymentError ? [{
            errorId: 'missingPayment',
            level: 'LEVEL_ERROR',
            description: translator(e1pCommonMessages.missingPayment),
        }] : []),
        ...(isSignatureMissing ? [{
            errorId: 'signatureRequired',
            level: 'LEVEL_ERROR',
            description: translator(e1pCommonMessages.signatureRequiredInfoMessage),
        }] : []),
        ...(isUwIssuePresent ? [{
            errorId: 'uwIssuePresent',
            level: 'LEVEL_ERROR',
            description: translator(e1pCommonMessages.openUw),
        }] : []),
    ];

    const uniqueErrors = errors.reduce((errorAccumulator, currentError) => {
        switch (currentError.errorId) {
            case 'missingPayment':
                if (!shouldShowMissingPaymentError) {
                    // we will remove error if payment is done
                    return errorAccumulator;
                }

                break;
            case 'signatureRequired':
                if (!isSignatureMissing) {
                    // we will remove error if signature is done
                    return errorAccumulator;
                }

                break;
            case 'uwIssuePresent':
                if (!isUwIssuePresent) {
                    // we will remove error if uw issues are not present
                    return errorAccumulator;
                }

                break;
            default:
                break;
        }

        // we are not setting errorId for each error
        // eslint-disable-next-line no-prototype-builtins
        if (!currentError.hasOwnProperty('errorId')) {
            errorAccumulator.result.push(currentError);
            // using hash for performance
            // below code checks for duplicate messages and keeps unique messages only
        } else if (!errorAccumulator.hash[currentError.errorId]) {
            errorAccumulator.hash[currentError.errorId] = true;
            errorAccumulator.result.push(currentError);
        }

        return errorAccumulator;
    }, { result: [], hash: {} }).result;

    return uniqueErrors;
}

export default {
    getValidationErrorsForPaymentPage
};
