import React, {
    useCallback,
    useEffect
} from 'react';
import {
    get as _get
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useViewPolicyUtil } from 'e1p-capability-hooks';
import { useTranslator } from '@jutro/locale';
import metadata from '../StartChangePage.metadata.json5';
import '../StartChangePage.messages';
import styles from '../StartChangePage.module.scss';

const LOB = 'homeowners_EH';

function ViewStartChangePage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        steps,
        jumpTo,
        isPageJumpEnabled,
        updateIsPageJumpEnabled,
        markFurthestStep
    } = props;

    const translator = useTranslator();

    const {
        onValidate
    } = useValidation('ViewStartChangePage');

    const {
        getLandingPageIndexForViewOnlyPolicy
    } = useViewPolicyUtil();

    useEffect(() => {
        markFurthestStep((steps.length - 1));

        if (isPageJumpEnabled) {
            updateIsPageJumpEnabled(false);
            jumpTo(getLandingPageIndexForViewOnlyPolicy(LOB, steps, 'PolicyChange'), true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = useCallback(() => policyChangeVM, [policyChangeVM]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            showRequired: true,
            readOnly: true
        },
        openPolicyChangeInfoMessageDiv: {
            visible: false
        },
        changeEffectiveDate: {
            dateDTO: policyChangeVM.baseData.effectiveDate,
            updateDateDto: () => updateWizardData(policyChangeVM),
            showErrors: true,
            onValidate
        },
        changeDescription: {
            visible: (_get(policyChangeVM, 'policyChangeReason_Ext.value.code') === 'other')
        },
        changeSource: {
            visible: false
        },
        changeReason: {
            visible: false
        },
        changeSourceViewOnly: {
            value: policyChangeVM.value.policyChangeSource !== undefined
                ? translator({
                    id: `typekey.PolicyChangeSource_Ext.${policyChangeVM.value.policyChangeSource}`,
                    defaultMessage: policyChangeVM.value.policyChangeSource
                })
                : ''
        },
        changeReasonViewOnly: {
            value: policyChangeVM.value.policyChangeReason !== undefined
                ? translator({
                    id: `typekey.PolicyChangeReason_Ext.${policyChangeVM.value.policyChangeReason}`,
                    defaultMessage: policyChangeVM.value.policyChangeReason
                })
                : ''
        },
        lossOccurredBetweenEffectiveDateAndCreateDateInd: {
            visible: policyChangeVM.value.hasLossOnOrAfterEffDate_Ext !== undefined 
            || policyChangeVM.value.hasLossOnOrAfterEffDate !== undefined,
            value: policyChangeVM.value.hasLossOnOrAfterEffDate_Ext === undefined
                ? policyChangeVM.value.hasLossOnOrAfterEffDate
                : policyChangeVM.value.hasLossOnOrAfterEffDate_Ext
        },
        startPageLoader: {
            loaded: true
        },
        policyChangeDetailsDiv: {
            visible: true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        }
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
            showPrevious={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

ViewStartChangePage.propTypes = wizardProps;

export default ViewStartChangePage;
