import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withModalContext } from '@jutro/components';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { RewriteService } from 'gw-capability-gateway';
import { DocumentRetrievalService } from 'e1p-capability-gateway';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import appConfig from 'app-config';
import { PageHeaderComponent } from '../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import DocumentsPage from '../Policies/E1PDocuments/Documents';
import CommonRoutes from '../Common/CommonRouting';
import messages from './Summary/Summary.messages';
import metadata from './Rewrite.metadata.json5';
import pageRouting from '../Common/Rewrite-config.json5';

const { capabilitiesConfig } = appConfig;

const DocumentPageService = {
    getDocuments: DocumentRetrievalService.getDocuments,
    generateUploadToken: GatewayDocumentService.generateUploadToken,
    uploadDocument: GatewayDocumentService.uploadDocument,
    removeDocument: GatewayDocumentService.removeDocument,
    downloadDocument: GatewayDocumentService.downloadDocument
};

function getRoutes() {
    const documentRoute = {
        path: '/documents',
        component: DocumentsPage
    };

    if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'document' })) {
        return [...pageRouting, documentRoute];
    }

    return pageRouting;
}

class RewriteWithoutModalContext extends Component {
    static propTypes = {
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }),
        match: PropTypes.shape({
            params: PropTypes.shape({
                jobNumber: PropTypes.string
            }),
            url: PropTypes.string
        }).isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired,
        authUserData: PropTypes.shape({
        })
    };

    static defaultProps = {
        authHeader: undefined,
        authUserData: undefined
    }

    state = {
        currentView: 'summary',
        rewriteSummaryCount: {},
        rewrite: {}
    };

    componentDidMount() {
        // this.loadRewriteSummaryCount();
        this.getJobByJobNumber();
    }

    handleTilesOnClick = (id) => {
        this.setState({ currentView: id });
    };

    loadRewriteSummaryCount = () => {
        // const {
        //     authHeader,
        //     match: {
        //         params: { jobNumber }
        //     },
        //     location: { pathname }
        // } = this.props;

        // const routePath = pathname && (pathname.trim().split('/') || []);
        // const selectedPageFromPath = routePath[routePath.length - 1];
        // const activeTile = selectedPageFromPath || 'summary';

        // RewriteService.getPolicyChangeSummary(jobNumber, authHeader).then((tilesData) => {
        //     this.setState({
        //         currentView: activeTile,
        //         rewriteSummaryCount: tilesData
        //     });
        // });
    };

    updateRewriteSummary = (rewrite) => {
        this.setState({ rewrite });
    };

    canAccessActivities = () => {
        const { authUserData } = this.props;

        return authUserData?.permissions_Ext.includes('actview_ext');
    };

    getJobByJobNumber = async () => {
        const {
            history,
            authHeader,
            match: {
                params: { jobNumber }
            }
        } = this.props;

        try {
            const rewrite = await RewriteService.findJobByJobNumber(
                jobNumber,
                authHeader
            );

            if (!rewrite.statusCode) {
                this.props.modalContext.showAlert({
                    title: messages.error,
                    message: messages.details,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.close
                }).catch(_.noop);
                history.push('/policies');
            }

            this.updateRewriteSummary(rewrite);
        } catch {
            this.props.modalContext.showAlert({
                title: messages.error,
                message: messages.details,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.close
            }).catch(_.noop);
            history.push('/policies');
        }
    };

    render() {
        const { currentView, rewriteSummaryCount, rewrite } = this.state;

        if (_.isEmpty(rewrite)) {
            return null;
        }

        const rewriteVM = {
            rewrite,
            rewriteSummaryCount
        };
        const policyNumber = _.get(rewrite, 'latestPeriod.policyNumber');
        const overrides = {
            [currentView]: {
                active: true
            },
            activities: {
                visible: this.canAccessActivities(),
                active: currentView === 'activities'
            },
        };
        const resolvers = {
            resolveCallbackMap: {
                handleTilesOnClick: this.handleTilesOnClick
            },
            resolveComponentMap: {
                pageheadercomponent: PageHeaderComponent
            }
        };

        const { match: { url, params: { jobNumber } } } = this.props;

        const quoteData = {
            jobNumber,
            policyNumber,
            loadRewriteSummaryCount: this.loadRewriteSummaryCount,
            loadRewriteSummary: rewrite,
            updateJobSummary: this.updateRewriteSummary
        };

        return (
            <DependencyProvider value={{ DocumentPageService }}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={rewriteVM}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
                <CommonRoutes
                    steps={getRoutes()}
                    setDocumentCount={this.loadRewriteSummaryCount}
                    jobNumber={jobNumber}
                    basePath={url}
                    quoteDetailsData={quoteData}
                />
            </DependencyProvider>
        );
    }
}

const Rewrite = withModalContext(RewriteWithoutModalContext);

export default withAuthenticationContext(Rewrite);
