import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { appendMetadataWithIndex, readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import _ from 'lodash';
import { SymbolCurrencyComponent } from 'e1p-capability-policyjob-react';
import { useTranslator } from '@jutro/locale';
import metadata from './ComprehensiveOnlyCheckboxComponent.metadata.json5';
import styles from './ComprehensiveOnlyCheckboxComponent.module.scss';
import messages from './ComprehensiveOnlyCheckboxComponent.messages';

function ComprehensiveOnlyCheckboxComponent(props) {
    const {
        data: vehicleVM,
        index,
        coverageAmount,
        onCompOnlyHandler,
        isLoading,
        viewOnly
    } = props;

    const translator = useTranslator();
    const showCoverageAmount = false;

    const getCoverageAmount = (amount) => (
            <span className={styles.readOnlyGridAmount}>
                {!_.isUndefined(amount) ? (
                    <SymbolCurrencyComponent value={{ amount, currency: 'USD' }} />
                ) : undefined}
            </span>
        );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCompOnlyChangeForVehicle = useCallback((value, path) => {
        if (onCompOnlyHandler) {
            onCompOnlyHandler(value, vehicleVM.publicID);
        }
    }, [onCompOnlyHandler, vehicleVM.publicID]);

    const generateOverrides = useCallback(
        () => {
            const overrides = {};

            overrides[`compOnlyCheckbox${index}`] = {
                onValueChange: handleCompOnlyChangeForVehicle
            };
            overrides[`coverageAmount${index}`] = {
                content: getCoverageAmount(coverageAmount),
                visible: _.get(vehicleVM, 'compOnlyInd', false) && showCoverageAmount
            };
            overrides[`compOnlyLoadingIndicator${index}`] = {
                loaded: !isLoading,
                text: translator(messages.updatingSelection)
            };
            overrides[`compOnlyGridContainer${index}`] = {
                visible: !isLoading
            };

            return overrides;
        },
        [coverageAmount, handleCompOnlyChangeForVehicle, index, isLoading, showCoverageAmount, translator, vehicleVM]
    );
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            disabled: viewOnly,
            autoComplete: false
        },
        ...generateOverrides()
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {}
    };

    const formattedMetadata = useMemo(() => appendMetadataWithIndex(metadata.pageContent, index), [index]);

    const readValue = useCallback(
        (Id, Path) => readViewModelValue(
                formattedMetadata,
                vehicleVM,
                Id,
                Path,
                overrideProps
            ),
        [formattedMetadata, overrideProps, vehicleVM]
    );

    return (
        <ViewModelForm
            model={vehicleVM}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            resolveValue={readValue}
        />
    );
}

ComprehensiveOnlyCheckboxComponent.propTypes = {
    data: PropTypes.shape({}),
    index: PropTypes.number.isRequired,
    coverageAmount: PropTypes.number,
    onCompOnlyHandler: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    viewOnly: PropTypes.bool
};

ComprehensiveOnlyCheckboxComponent.defaultProps = {
    data: {},
    coverageAmount: undefined,
    viewOnly: false
};

export default ComprehensiveOnlyCheckboxComponent;
