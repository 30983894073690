import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get, set, isEqual, isEmpty, findIndex
} from 'lodash';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useGaragingAddressUtil } from 'e1p-capability-hooks';
import { VehicleUtil } from 'e1p-portals-util-js';
import { RewriteService } from 'e1p-capability-rewrite';
import { commonMessages as e1pCommonMessages, eaValidationAndInfoMessages } from 'e1p-platform-translations';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './VehiclesPage.metadata.json5';
import messages from './VehiclesPage.messages';

function VehiclesPage(props) {
    const modalApi = useModal();
    const {
        wizardData: rewriteVM,
        updateWizardData,
        isSkipping,
        updateWizardSnapshot,
        steps,
        jumpTo
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const [pageInitialized, setIsPageInitialized] = useState(false);
    const [isSavingRewrite, setIsSavingRewrite] = useState(false);
    const [vehicleLookupError, setVehicleLookupError] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);
    const [availableMakes, setAvailableMakes] = useState({});
    const [availableModels, setAvailableModels] = useState({});
    const [availableSeries, setAvailableSeries] = useState({});
    const { generateGaragingAddressDisplayName } = useGaragingAddressUtil();
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const { authHeader } = useAuthentication();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('VehiclePage');

    rewriteVM.flowStepIDs_Ext.value = ['vehicle'];
    rewriteVM.entryCompletionStepIDs_Ext.value = ['vehicle'];

    // array of vehicle objects (not vmnodes)
    const vehiclesObjectArray = get(rewriteVM, 'lobData.personalAuto_EA.coverables.vehicles.value', []);
    const atleastOneCarBeingAdded = (() => {
        if (vehiclesObjectArray.length > 1) { return true; }

        const make = get(vehiclesObjectArray[0], 'make');
        const model = get(vehiclesObjectArray[0], 'model');
        const year = get(vehiclesObjectArray[0], 'year');
        const vin = get(vehiclesObjectArray[0], 'vin');

        if (vin && year) { return true; }

        if (make && model && year) { return true; }

        return false;
    })();

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted]);

    const defaultGaragingAddress = get(rewriteVM, 'baseData.policyAddress.value');

    const onVehicleLookupError = useCallback(
        (value) => {
            setVehicleLookupError(value);
        },
        []
    );

    /**
     * Helper callback for creating a new vehicle "VMNode" object and adding it to the list of
     * vehicles on the policy.
     */
    const createAndAddVehicleVM = useCallback(() => {
        const vehicleObj = {
            garagingLocation: defaultGaragingAddress
        };
        const { _xCenter, _dtoName } = rewriteVM.lobData.personalAuto_EA.coverables.vehicles;
        const vehicleVM = viewModelService.create(vehicleObj, _xCenter, _dtoName);

        if (!vehicleVM.safetyFeatures.value) {
            vehicleVM.safetyFeatures.value = [];
        }

        // vehicleVM.callShowAll = false;
        rewriteVM.lobData.personalAuto_EA.coverables.vehicles.pushElement(vehicleVM);
        rewriteVM.lobData.personalAuto_EA.coverables.vehicles.children.forEach((vehicle) => {
            set(vehicle, 'callShowAll', false);
        });
        setCheckScrolling(true);
        setIndexStale(true);

        return updateWizardData(rewriteVM);
    }, [defaultGaragingAddress, rewriteVM, updateWizardData, viewModelService]);

    // this grabs the latest element on the page --
    // this is to make sure this element has been loaded
    const latestVehicleElement = document.getElementById(`vehicleTitleHeader${rewriteVM.lobData.personalAuto_EA.coverables.vehicles.children.length}`);

    useEffect(() => {
        // indexStale set in the add vehicle function
        // once latest element is loaded and a new vehicle is added
        // we check if the button should be visible
        if (latestVehicleElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestVehicleElement]);

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(async (_, calledFromSave = false) => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setIsSavingRewrite(true);

        try {
            const indexOfDriverAssignment = steps.findIndex((step) => step.path === '/assignment');
            const shouldSkipDriverAssignmentPage = VehicleUtil.shouldSkipDriverAssignmentPage(rewriteVM);

            /**
             * If driver assignment is available for given state and 
             * shouldSkipDriverAssignmentPage indicator(given job has one driver and one vehicle and vehicle is not garaged out fo state more than 6 months) is true 
             * then perform driver assignment for given vehicle
             */
            if (indexOfDriverAssignment > 0 && !calledFromSave && shouldSkipDriverAssignmentPage) {
                VehicleUtil.setDriverAssignmentWhenOneDriverOneVehiclePresent(rewriteVM, updateWizardData);
            }

            // Make a updateDraftRewrite call if driver assignment is there in Quote flow else call saveAndQuote
            let serviceCall = RewriteService.saveAndQuote;

            if (indexOfDriverAssignment > 0 && !shouldSkipDriverAssignmentPage) {
                serviceCall = RewriteService.updateDraftRewrite;
            }

            rewriteVM.value = await serviceCall(
                [rewriteVM.value],
                authHeader
            );
            updateWizardData(rewriteVM);

            // Skip driver assignment page
            if (indexOfDriverAssignment > 0 && !calledFromSave && shouldSkipDriverAssignmentPage) {
                const riskAnalysisPageIndex = findIndex(steps, ({ path }) => path === '/risk-analysis');

                updateWizardSnapshot(rewriteVM);
                jumpTo(riskAnalysisPageIndex, true, rewriteVM.value);

                return false;
            }

            return rewriteVM;
        } catch {
            setIsSavingRewrite(false);
            modalApi.showConfirm({
                status: 'warning',
                icon: 'mi-error-outline',
                title: commonMessages.genericError,
                message: commonMessages.genericErrorMessage,
                messageProps: {
                    confirmButtonText: commonMessages.cancelModel
                },
                showCancelBtn: false
            });

            return false;
        }
    }, [isComponentValid, steps, modalApi, rewriteVM, authHeader, updateWizardData, updateWizardSnapshot, jumpTo]);

    const onSave = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsSavingCurrentPageChanges(true);

            try {
                await onNext(undefined, true);

                const fieldIssues = get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    /**
                     * E1PAP1PC-14986 :
                     * wizardData and wizardSnapshot not being equal due to
                     * some defaulting on each page so doing this temp fix
                     */
                    rewriteVM.lobData.personalAuto_EA.coverables
                        .vehicles.children.forEach((vehicle) => {
                            generateGaragingAddressDisplayName(vehicle.garagingLocation);
                        });
                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [generateGaragingAddressDisplayName, isComponentValid, onNext, rewriteVM, updateWizardSnapshot]
    );

    /**
     * Helper effect for handling the component initialization logic.
     */
    useEffect(() => {
        if (!pageInitialized) {
            const vehiclesPath = 'lobData.personalAuto_EA.coverables.vehicles';
            const vehicles = get(rewriteVM, `${vehiclesPath}.value`);

            const newVehicles = vehicles.map((vehicle) => {
                const newVehicle = {
                    ...vehicle,
                    vin: vehicle.vin,
                    costNew: vehicle.costNew
                };

                return newVehicle;
            });

            // Update the submission VM with new list of vehicles
            if (!isEqual(newVehicles, vehicles)) {
                set(rewriteVM, `${vehiclesPath}.value`, newVehicles);
                updateWizardData(rewriteVM);
            }

            setIsPageInitialized(true);
        }

    }, [pageInitialized, rewriteVM, updateWizardData]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        vehiclesPageLoadingIndicator: {
            loaded: !isSavingRewrite && !isSkipping && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(messages.loadingNextPageMessageRewrite)
        },
        vehiclesPageContainer: {
            visible: !isSavingRewrite && !isSkipping && !isSavingCurrentPageChanges
        },
        vehicleLookupErrorNotificationDiv: {
            visible: vehicleLookupError
        },
        oneCarMinimumNotificationDiv: {
            visible: !atleastOneCarBeingAdded && isPageSubmitted
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleGridContainer')
        },
        vehicleInfoChangedMessage: {
            message: translator(eaValidationAndInfoMessages.vehicleHasUpdatedMessage)
        },
        vehicleInfoChangedMessageDiv: {
            visible: (() => {
                const vehicles = get(rewriteVM, 'value.lobData.personalAuto_EA.coverables.vehicles', []);
                const doesVehicleInfoChanged = vehicles.some((vehicle) => !!vehicle.vehicleInfoChanged);

                return doesVehicleInfoChanged;
            })()
        },
        EAVehicleGrid: {
            key: get(rewriteVM, 'lobData.personalAuto_EA.coverables.vehicles', []).length,
            vehicles: get(rewriteVM, 'lobData.personalAuto_EA.coverables.vehicles', []),
            path: 'lobData.personalAuto_EA.coverables.vehicles.children',
            onValidate,
            viewModelService,
            showErrors: isPageSubmitted,
            policyState: get(rewriteVM, 'baseData.policyAddress.state.value.code'),
            vehiclePageDisregardFieldValidation: disregardFieldValidation,
            authHeader,
            onValidationChange: onValidate,
            transactionVM: rewriteVM,
            updateWizardData,
            setCheckScrolling,
            addVehicleVM: createAndAddVehicleVM,
            isSkipping,
            availableMakes,
            setAvailableMakes,
            availableModels,
            setAvailableModels,
            availableSeries,
            setAvailableSeries
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                rewriteVM,
                id,
                path,
                overrideProps
            ),
        [rewriteVM, overrideProps]
    );

    /**
     * Define mappings to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onAddVehicleClick: createAndAddVehicleVM,
            onValidate,
            onPrefillData: undefined,
            onVehicleLookupError
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

VehiclesPage.propTypes = wizardProps;
export default VehiclesPage;