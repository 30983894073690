import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
    set, isEmpty, get, findIndex, includes, uniqBy
} from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { EndorsementService } from 'e1p-capability-policychange';
import { PolicyDiffService, ChangePhraseService } from 'e1p-capability-policyjob';
import { useUWIssueUtil } from 'e1p-capability-hooks';
import { useHistory } from 'react-router-dom';
import { policyChangeMessages, commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import { PolicyChangeUtil, PaymentPageUtil } from 'e1p-portals-util-js';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { WizardSingleErrorComponent } from 'gw-portals-wizard-components-ui';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { isRequired } from 'e1p-portals-required-validator-js';
import moment from 'moment';
import metadata from './PolicyChangeDiffPage.metadata.json5';
import messages from './PolicyChangeDiffPage.messages';
import requiredMetadata from './PolicyChangeDiffPage.requiredness';

function PolicyChangeDiff(props) {
    const modalApi = useModal();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        jumpTo,
        steps,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const { authHeader, authUserData } = useAuthentication();
    const [policyDiffData, setPolicyDiffData] = useState(null);
    const [policyChangePhraseData, setPolicyChangePhraseData] = useState([]);
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [signatureSuccess, setSignatureSuccess] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [requiredFields, updateRequiredFields] = useState([]);
    const policyState = get(policyChangeVM, 'baseData.policyAddress.state.value.code');
    const [isOOSComoponentValid, updateIsOOSComoponentValid] = useState(true);
    const history = useHistory();
    const [producerCodeOptions, setProducerCodeOptions] = useState([]);
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const [isPerformingQuote, setIsPerformingQuote] = useState(false);
    const jobNumber = policyChangeVM?.jobID?.value;
    // It is used to show loader message 'Preparing Required Documents' when gettingSignatureDocument is true
    const [gettingSignatureDocument, setGettingSignatureDocument] = useState(false);
    const [isWithDrawingChangeJob, setIsWithdrawingChangeJob] = useState(false);
    const {
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('PolicyChangeDiff');

    const {
        hasUWIssuesOfType,
        showUnderwritingIssuesPopup,
        hasUWIssues
    } = useUWIssueUtil(
        policyChangeVM,
        updateWizardData,
        jumpTo,
        steps
    );

    // Check if any form requires signature
    const isSignatureRequired = get(
        policyChangeVM, 'lobData.personalAuto_EA.hasSignatureRequiredFormsInCurrentJob.value'
    );

    const conflictsPresent = !isEmpty(get(policyChangeVM, 'value.conflicts', []));
    const [activeTab, setActiveTab] = useState(conflictsPresent ? 'changeConflictsTab' : 'changePhrases');
    
    // when confilcts are resolved, conflicts tab is getting removed,
    // then we need to set change phrases as active tab
    useEffect(() => {
        setActiveTab(conflictsPresent ? 'changeConflictsTab' : 'changePhrases');
    },[conflictsPresent]);

    useEffect(() => {
        registerComponentValidation(
            () => !hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease'])
                    && ((isSignatureRequired && signatureSuccess) || !isSignatureRequired)
                    && !conflictsPresent
        );
    }, [
        hasUWIssuesOfType, isSignatureRequired,
        registerComponentValidation, signatureSuccess, conflictsPresent
    ]);

    // Check if FR44 is not requested
    const isFR44NotRequestedWithViolationDeclared = useCallback(() => {
        if (includes(requiredFields, 'stateViolation')) {
            const drivers = get(policyChangeVM.value, 'lobData.personalAuto_EA.coverables.drivers', []);

            return drivers.some((driver) => {
                // Check if financial repsonsibility filing form is not set to form FR44
                if (get(driver, 'financialResponsibility.filingForm') !== 'form_fr44') {
                    const manualViolationRecords = get(policyChangeVM.value, 'lobData.personalAuto_EA.manualViolationRecords', []);
                    // List of violation type not allowed
                    const violationTypesNotAllowedForVA = ['MSL', 'OWI', 'CWI', 'FSA', 'CMS', 'VUF', 'CUF'];

                    return findIndex(manualViolationRecords, (record) => {
                        // Check if incident date is within past three years & violation type is not allowed
                        const incidentDate = get(record, 'incidentDate');
                        const threeYearPriorDate = moment().subtract(3, 'year');
                        const incidentWithinThreeyear = moment(incidentDate).isAfter(threeYearPriorDate);

                        return incidentWithinThreeyear && violationTypesNotAllowedForVA.includes(get(record, 'violationType')) && get(driver, 'publicID') === get(record, 'assignment.publicId');
                    }) >= 0;
                }

                return false
            });
        }

        return false;
    }, [policyChangeVM.value, requiredFields]);

    useEffect(() => {
        const initialRequiredFields = [
            'stateViolation'
        ];

        // Fields to look up by partner/state
        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
    }, [policyState]);

    const checkuwIssues = useCallback(() => {
        if (hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease'])) {
            showUnderwritingIssuesPopup();
        }
    }, [hasUWIssuesOfType, showUnderwritingIssuesPopup]);

    const isQuoted = get(policyChangeVM, 'status.value.code') === 'Quoted';

    useEffect(() => {
        const checkUwIssuesAndGetChangeSummary = () => {
            // Show UW issues if quote is blocked
            checkuwIssues();

            // Call the policy diff
            PolicyDiffService.getPolicyDiffWithPrevious(
                [policyChangeVM.jobID.value], authHeader
            ).then(setPolicyDiffData);

            ChangePhraseService.getChangePhrase(
                policyChangeVM.jobID.value, authHeader
            ).then((response) => {
                const mappedPhrases = response.map((phrase) => ({
                        name: phrase
                    }));

                setPolicyChangePhraseData(mappedPhrases);
            });
        };
        /**
         * E1PAP1PC-14509 :
         * will perform saveAndQuote if job is not quoted yet and
         * there are not errors, exceptions, conflicts and uwIssues
         */
        const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
        const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

        if (!isQuoted
            && isEmpty(fieldIssues) && isEmpty(exceptions)
            && !conflictsPresent
            && !hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease'])) {
            setIsPerformingQuote(true);
            EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            ).then((quoteResponse) => {
                set(policyChangeVM, 'value', quoteResponse);
                updateWizardData(policyChangeVM);
                setIsPerformingQuote(false);
                checkUwIssuesAndGetChangeSummary();
            }).catch(() => {
                setIsSavingCurrentPageChanges(false);
            });
        } else {
            checkUwIssuesAndGetChangeSummary();
        }

        // It should call when page is render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
    *Checking if valid signature or not
    */
    const isPageValid = useCallback(() => {
        const isSignatureDone = get(policyChangeVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;

        // If signature required and completed and there are no UW issues
        return (!isSignatureRequired || isSignatureDone) && !checkuwIssues();
    }, [policyChangeVM, signatureSuccess, isSignatureRequired, checkuwIssues]);

    useEffect(() => {
        registerComponentValidation(isPageValid);
    }, [isPageValid, registerComponentValidation, signatureSuccess]);

    const writeValue = useCallback(
        (value, path) => {
            set(policyChangeVM, path, value);
            updateWizardData(policyChangeVM);
        }, [policyChangeVM, updateWizardData]
    );

    const setErrors = useCallback((removeUnnecessaryErrors = false) => {
        const isSignatureDone = get(policyChangeVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;
        const isSignatureMissing = isSignatureRequired && !isSignatureDone;
        
        const isUwIssuePresent = hasUWIssues();
        
        // no payments in policyChange
        const shouldShowMissingPaymentError = false;

        const errors = PaymentPageUtil.getValidationErrorsForPaymentPage(
            validationErrors, shouldShowMissingPaymentError, isSignatureMissing, isUwIssuePresent, translator, removeUnnecessaryErrors);

        const uniqueErrors= uniqBy(errors, 'description');

        setValidationErrors(uniqueErrors);

        return uniqueErrors;

    }, [policyChangeVM, signatureSuccess, isSignatureRequired, hasUWIssues, validationErrors, translator]);

    useEffect(() => {
        if (signatureSuccess) {
            setErrors(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatureSuccess])

    const resolvers = {
        resolveComponentMap: {
            WizardSingleErrorComponent
        },
        resolveCallbackMap: {
            sortString: DatatableUtil.sortString
        }
    };

    const loadingTextMessages = useMemo(() => {
        let loadingMessage = translator(messages.completingYourPurchaseMessage);

        if (isSavingCurrentPageChanges) {
            loadingMessage = translator(e1pCommonMessages.savingCurrentPageChanges);
        } else if (gettingSignatureDocument) {
            loadingMessage = translator(e1pCommonMessages.prepareReqDocuments);
        } else if (isPerformingQuote) {
            loadingMessage = translator(e1pCommonMessages.performingQuoteOperation);
        } else if (isWithDrawingChangeJob) {
            loadingMessage = translator(policyChangeMessages.withdrawingPolicychange);
        }

        return loadingMessage;
    }, [
        translator, isSavingCurrentPageChanges, gettingSignatureDocument,
        isPerformingQuote, isWithDrawingChangeJob
    ]);

    // used to show/hide wholepage loader and bottom navigation buttons as well
    const isPageLoaded = useMemo(() => !isSavingEndorsement && !isSkipping && !isWithDrawingChangeJob
            && !isSavingCurrentPageChanges && !isPerformingQuote && !gettingSignatureDocument, [gettingSignatureDocument, isPerformingQuote, isSavingCurrentPageChanges, isSavingEndorsement, isSkipping, isWithDrawingChangeJob]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        policyChangeDiffPageLoadingIndicator: {
            loaded: isPageLoaded,
            text: loadingTextMessages
        },
        policyChangeDiffPageContainer: {
            visible: !isSavingEndorsement && !isSkipping && !isWithDrawingChangeJob
                && !isSavingCurrentPageChanges && !isPerformingQuote,
            // Facing some issues, if i try to hide the container using visibility. 
            // Initially, visibility is false so all the components will be unmount 
            // when the visibility become true then all components will be mount again 
            // it will re-trigger all the react hook components so handled hiding using CSS
            className: gettingSignatureDocument ? 'display-none' : ''
        },
        ehPolicyDiff: {
            quote: policyDiffData,
            visible: !isEmpty(policyDiffData)
        },
        changePhraseDataTable: {
            data: policyChangePhraseData
        },
        policychangeSummaryTabset: {
            defaultActiveTab: 'changePhrases',
            activeTab,
            onTabChange: (tabId) => {
                setActiveTab(tabId);
            }
        },
        changeConflictsTab: {
            visible: conflictsPresent
        },
        PremiumBox: {
            policyChangeVM,
            viewOnlyMode: true,
            authHeader
        },
        changeConflicts: {
            conflicts: get(policyChangeVM, 'value.conflicts', []),
            jobNumber: get(policyChangeVM, 'value.jobID', ''),
            authHeader,
            changeEffectiveDate: get(policyChangeVM.value, 'baseData.effectiveDate'),
            writeValue,
            isPageSubmitted,
            updateIsPageSubmitted,
            updateIsOOSComoponentValid
        },
        signatureTab: {
            visible: isSignatureRequired
        },
        // Should be visible whenever custom validation errors are triggered
        WizardSingleErrorComponent: {
            issuesList: validationErrors
        },
        signatureComponent: {
            submissionVM: policyChangeVM,
            updateWizardData,
            authHeader,
            LOB: 'personalAuto_EA',
            onSignatureSuccess: setSignatureSuccess,
            producerCodeOptions,
            setGettingSignatureDocument,
            setProducerCodeOptions,
            showErrors: isPageSubmitted
        },
        deliverDocumentsIndComponent: {
            visible: authUserData.permissions_Ext.includes('docdeliveryind_ext'),
            deliverDocInd: get(policyChangeVM, 'value.shouldDeliverDocuments_Ext'),
            setDeliverDocInd: ((value) => {
                set(policyChangeVM, 'value.shouldDeliverDocuments_Ext', value);
                updateWizardData(policyChangeVM);
            })
        },
        fr44RelatedViolationMessageDiv: {
            visible: isFR44NotRequestedWithViolationDeclared(),
        }
    };
    const bindPolicyChange = useCallback(
        async () => {
            try {
                setIsSavingEndorsement(true);

                const paymentDetails = {};
                const bindResponse = await EndorsementService.bindChange(
                    [policyChangeVM.value, paymentDetails], authHeader
                );

                if (bindResponse.policyChange?.errorsAndWarnings) {
                    set(policyChangeVM, 'value.errorsAndWarnings', bindResponse.policyChange.errorsAndWarnings);
                    // json alias at backend causing these issues
                    set(policyChangeVM, 'errorsAndWarnings_Ext.value', bindResponse.policyChange.errorsAndWarnings);
                    updateWizardData(policyChangeVM);
                    setIsSavingEndorsement(false);

                    // If there is UW issue which blocks bind, displays underwriting issue popup
                    if (hasUWIssuesOfType(['BlocksBind'])) {
                        showUnderwritingIssuesPopup();
                    }

                    return false;
                }

                policyChangeVM.value = bindResponse.policyChange;
                updateWizardData(policyChangeVM);
                setIsSavingEndorsement(false);
                set(policyChangeVM, 'changesAppliedForward', bindResponse.changesAppliedForward);

                return policyChangeVM;
            } catch {
                setIsSavingEndorsement(false);
                modalApi.showConfirm({
                    status: 'warning',
                    icon: 'mi-error-outline',
                    title: messages.bindError,
                    message: messages.bindErrorMessage,
                    messageProps: {
                        confirmButtonText: commonMessages.cancelModel
                    },
                    showCancelBtn: false
                });

                return policyChangeVM;
            }
        },
        [
            authHeader,
            hasUWIssuesOfType,
            policyChangeVM,
            showUnderwritingIssuesPopup,
            updateWizardData,
            modalApi
        ]
    );

    /**
     * set errors and move to signature tab if signature is required and only signature is pending.
     */
    const setErrorsAndMovetoSignatureTabIfRequired = useCallback(() => {
        const activeErrors = setErrors();

        const isOnlySignatureErrorLeft =
            activeErrors.length === 1 &&
            activeErrors.find(
                (error) =>
                    error.description ===
                    translator(e1pCommonMessages.signatureRequiredInfoMessage)
            );
        const isSignatureDone = get(policyChangeVM, 'baseData.signatureType_ext.value') !== undefined && signatureSuccess;

        if (isSignatureRequired && !isSignatureDone && isOnlySignatureErrorLeft) {
            setActiveTab('signatureTab');
        }
    },[isSignatureRequired, policyChangeVM, setErrors, signatureSuccess, translator]);

    const onNext = useCallback(
        async () => {
            let callBindApi = true;

            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                setErrorsAndMovetoSignatureTabIfRequired();
                window.scrollTo(0, 0);

                return false;
            }

            // E1PAP1PC-13936: we need to show confiramtaion box.
            // if there is bound or unbound renewal present on the policy
            const isBoundRenewalExists = get(policyChangeVM, 'value.hasFutureBoundRenewal_Ext', false);
            const isUnboundRenewalExists = get(policyChangeVM, 'value.hasFutureUnBoundRenewal_Ext', false);

            if (isBoundRenewalExists || isUnboundRenewalExists) {
                const results = await modalApi.showConfirm({
                    title: policyChangeMessages.applyChangesToRenewalTitle,
                    message: policyChangeMessages.applyChangesToRenewalDescription,
                    confirmButtonText: policyChangeMessages.applyChanges
                });

                if (results === 'cancel') {
                    callBindApi = false;
                }
            }

            if (callBindApi) {
                return bindPolicyChange();
            }

            return false;
        },
        [isComponentValid, policyChangeVM, setErrorsAndMovetoSignatureTabIfRequired, modalApi, bindPolicyChange]
    );

    const withdraw = useCallback(() => {
        setIsWithdrawingChangeJob(true);

        return PolicyChangeUtil.withdrawEndorsement(jobNumber, authHeader, history).then(() => {
            setIsWithdrawingChangeJob(false);
        });
    }, [authHeader, history, jobNumber]);

    const saveAndQuote = useCallback(
        async () => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                setErrorsAndMovetoSignatureTabIfRequired();
                window.scrollTo(0, 0);

                return false;
            }

            const quoteResponse = await EndorsementService.saveAndQuoteEndorsement(
                [(policyChangeVM.value)],
                authHeader
            );

            set(policyChangeVM, 'value', quoteResponse);

            return updateWizardData(policyChangeVM);
        },
        [authHeader, isComponentValid, policyChangeVM, setErrorsAndMovetoSignatureTabIfRequired, updateWizardData]
    );

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await saveAndQuote();

                const fieldIssues = get(policyChangeVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(policyChangeVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(policyChangeVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [saveAndQuote, policyChangeVM, updateWizardSnapshot]
    );

    const readValue = useCallback((id, path) => readViewModelValue(metadata.pageContent, policyChangeVM, id, path, overrideProps), [overrideProps, policyChangeVM]);

    return (
        <WizardPage
            isLoadingWholePage={!isPageLoaded}
            onNext={onNext}
            nextLabel={translator(policyChangeMessages.applyChanges)}
            disablePrevious={gettingSignatureDocument}
            disableNext={gettingSignatureDocument}
            showNext={isQuoted}
            showCustom={!isQuoted}
            showWithdraw
            onWithdraw={withdraw}
            onCustom={saveAndQuote}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isOOSComoponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                resolveValue={readValue}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}


PolicyChangeDiff.propTypes = wizardProps;
export default PolicyChangeDiff;
