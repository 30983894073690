import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'e1p-capability-quoteandbind-ea-react';
import E1PQuoteEAPolicyDetailsPage from './pages/PolicyDetails/PolicyDetailsPage';
import E1PQuoteEAViewPolicyDetailsPage from './pages/PolicyDetails/ViewOnly/ViewPolicyDetailsPage';

setComponentMapOverrides(
    {
        E1PQuoteEAPolicyDetailsPage: { component: 'E1PQuoteEAPolicyDetailsPage' },
        E1PQuoteEAViewPolicyDetailsPage: { component: 'E1PQuoteEAViewPolicyDetailsPage' },
    },
    {
        E1PQuoteEAPolicyDetailsPage,
        E1PQuoteEAViewPolicyDetailsPage,
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PEEAWizard } from './PEEAWizard';
