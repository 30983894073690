import { defineMessages } from 'react-intl';

export default defineMessages({
    membershipLevel: {
        id: 'e1p.connect.policyDetails.Membership Level',
        defaultMessage: 'Membership Level'
    },
    membershipID: {
        id: 'e1p.connect.policyDetails.Membership ID',
        defaultMessage: 'Membership ID'
    },
    membershipIdValidation: {
        id: 'e1p.connect.policyDetails.Membership ID should be numeric only with 12 digits',
        defaultMessage: 'Membership ID should be numeric only with 12 digits'
    }
});
