import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withModalContext } from '@jutro/components';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { NoteService } from 'e1p-capability-gateway';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import NotesComponent from '../../Components/NotesComponent/NotesComponent';
import gatewayMessages from '../../gateway.messages';
import metadata from './Notes.metadata.json5';
import messages from './Notes.messages';

class QuoteDetailsNotesWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}),
        authUserData: PropTypes.shape({}),
        fromAccountLanding: PropTypes.shape({
            quoteDetailsData: PropTypes.shape({
                jobNumber: PropTypes.string,
                getQuoteSummaryCount: PropTypes.func,
                loadQuoteSummary: PropTypes.shape({
                    policy: PropTypes.shape({
                        policyNumber: PropTypes.string
                    })
                })
            }),
            basePath: PropTypes.string
        }).isRequired
    };

    static defaultProps = {
        authHeader: undefined,
        authUserData: undefined
    }

    state = {
        notesData: [],
        loaded: true,
        policyNumber: ''
    };

    componentDidMount() {
        this.getNotes();
    }

    handleError = (title, message) => this.props.modalContext.showAlert({
            title,
            message,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.close
        }).catch(_.noop);

    getNotes = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                quoteDetailsData: { jobNumber, getQuoteSummaryCount, loadQuoteSummary }
            }
        } = this.props;
        const {
            policy: {
                policyNumber
            }
        } = loadQuoteSummary;

        this.setState({
            loaded: false,
            policyNumber
        });

        try {
            const getAvailableNotesData = await NoteService.getNotesForJob(
                jobNumber,
                authHeader
            );

            this.setState({
                notesData: getAvailableNotesData,
                loaded: true
            });
            getQuoteSummaryCount();
        } catch (e) {
            this.handleError(gatewayMessages.modalError, gatewayMessages.errorLoadingNotes);
        }
    };

    createNote = async (data) => {
        const {
            authHeader,
            fromAccountLanding: {
                quoteDetailsData: { jobNumber }
            }
        } = this.props;

        this.setState({ loaded: false });

        try {
            const quoteDetailsNoteData = await NoteService.createNoteForJob(
                jobNumber, data,
                authHeader
            );

            if (!_.isEmpty(quoteDetailsNoteData)) {
                this.getNotes();
            }
        } catch (e) {
            this.handleError(gatewayMessages.modalError, gatewayMessages.errorLoadingNotes);
        }
    };

    editNote = async (data) => {
        const {
            authHeader
        } = this.props;

        this.setState({ loaded: false });

        try {
            const policyNoteData = await NoteService.editNote(
                data,
                authHeader
            );

            if (!_.isEmpty(policyNoteData)) {
                this.getNotes();
            }
        } catch (e) {
            this.setState({
                loaded: true
            });
            this.props.modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: messages.errorUpdatingNotes,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.close
            }).catch(_.noop);
        }
    };

    deleteNote = async (data) => {
        const {
            authHeader
        } = this.props;

        this.setState({ loaded: false });

        try {
            const policyNoteData = await NoteService.deleteNote(
                data,
                authHeader
            );

            // delete note api will return empty response if successfull
            if (_.isEmpty(policyNoteData)) {
                this.getNotes();
            }
        } catch (e) {
            this.setState({
                loaded: true
            });
            this.props.modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: messages.errorDeletingNotes,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.close
            }).catch(_.noop);
        }
    };

    render() {
        const { authUserData } = this.props;
        const { notesData, loaded, policyNumber } = this.state;
        const overrides = {
            quoteDetailsNotes: {
                initialNotesData: notesData,
                createNote: this.createNote,
                editNote: this.editNote,
                deleteNote: this.deleteNote,
                authUserData,
                policyNumber,
                visible: loaded
            },
            notesLoader: {
                loaded
            }
        };
        const resolvers = {
            resolveComponentMap: {
                notescomponent: NotesComponent
            }
        };

        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

const QuoteDetailsNotes = withModalContext(QuoteDetailsNotesWithoutModalContext);

export default withAuthenticationContext(QuoteDetailsNotes);
