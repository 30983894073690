/* eslint-disable no-secrets/no-secrets */
import AddressStandardizationComponent from './components/AddressStandardizationComponent/AddressStandardizationComponent';
import EHRiskAnalysisMiscComponent from './components/EHRiskAnalysisMiscComponent/EHRiskAnalysisMiscComponent';
import EHRoofScoreDataComponent from './components/EHRoofScoreComponent/EHRoofScoreComponent';
import EAGaragingAddressComponent from './components/EAGaragingAddressComponent/EAGaragingAddressComponent';
import EAGaragingAddressModal from './components/EAGaragingAddressModal/EAGaragingAddressModal';
import LossTableComponent from './components/LossTableComponent/LossTableComponent';
import PriorAddressComponent from './components/PriorAddressComponent/PriorAddressComponent';
import ProducerAddressComponent from './components/ProducerAddressComponent/ProducerAddressComponent';
import AccountSearchComponent from './components/AccountSearchComponent/AccountSearchComponent';
import AccountsFoundComponent from './components/AccountsFoundComponent/AccountsFoundComponent';
import InsideWallMaterial from './components/InsideWallMaterialComponent/InsideWallMaterialComponent';
import FloorComponent from './components/FloorComponent/FloorComponent';
import EHGarageComponent from './components/EHGarageComponent/EHGarageComponent';
import HeatingSystemComponent from './components/HeatingSystemComponent/HeatingSystemComponent';
import CoolingSystemComponent from './components/CoolingSystemComponent/CoolingSystemComponent';
import FirePlaceComponent from './components/FirePlaceComponent/FirePlaceComponent';
import UnderWritingIssuesComponent from './components/UnderwritingIssues/UnderwritingIssues';
import UWIssueDetail from './components/UWIssueDetail/UWIssueDetail';
import EAVehiclePrefillComponent from './components/EAVehiclePrefillComponent/EAVehiclePrefillComponent';
import SymbolCurrencyComponent from './components/SymbolCurrencyComponent/SymbolCurrencyComponent';
import EADriverPrefillComponent from './components/EADriverPrefillComponent/EADriverPrefillComponent';
import EANotInHouseholdComponent from './components/EANotInHouseholdComponent/EANotInHouseholdComponent';
import PriorPolicyComponent from './components/PriorPolicyComponent/PriorPolicyComponent';
import PniPriorPolicyGridComponent from './components/PniPriorPolicyGridComponent/PniPriorPolicyGridComponent';
import SniPriorPolicyGridComponent from './components/SniPriorPolicyGridComponent/SniPriorPolicyGridComponent';
import EALossReportComponent from './components/EALossReportComponent/EALossReportComponent';
import EAViolationReportComponent from './components/EAViolationReportComponent/EAViolationReportComponent';
import AddressComponent from './components/AddressComponent/AddressComponent';
import MailingAndBillingAddressComponent from './components/MailingAndBillingAddressComponent/MailingAndBillingAddressComponent';
import StandardizationComponent from './components/StandardizationComponent/StandardizationComponent';
import ManualPropertyLossesComponent from './components/ManualPropertyLossesComponent/ManualPropertyLossesComponent';
import ManualPropertyLossComponent from './components/ManualPropertyLossComponent/ManualPropertyLossComponent';
import ManualPropertyLossesInlineComponent from './components/ManualPropertyLossesInlineComponent/ManualPropertyLossesInlineComponent';
import ThirdPartyReportComponent from './components/ThirdPartyReportComponent/ThirdPartyReportComponent';
import ThirdPartyReportsComponent from './components/ThirdPartyReportsComponent/ThirdPartyReportsComponent';
import EHScheduleItemsComponent from './components/EHScheduleItemsComponent/ScheduleItemsComponent';
import EHOptionalCoveragesReadOnlyComponent from './components/EHOptionalCoveragesReadOnlyComponent/OptionalCoveragesReadOnlyComponent';
import E1PLicenseComponent from './components/E1PLicenseComponent/E1PLicenseComponent';
import E1PAcknowledgementComponent from './components/E1PAcknowledgementComponent/E1PAcknowledgementComponent';
import DiscountsListComponent from './components/DiscountsListComponent/DiscountsListComponent';
import SurchargeListComponent from './components/SurchargeListComponent/SurchargeListComponent';
import PaymentOptionsComponent from './components/PaymentOptionsComponent/PaymentOptionsComponent';
import PaperlessDetailPopup from './components/PaperlessDetailPopup/PaperlessDetailPopup';
import HistoryTableComponent from './components/HistoryTableComponent/HistoryTableComponent';
import E1PActivityTableComponent from './components/E1PActivityTableComponent/E1PActivityTableComponent';
import E1PActivityComponent from './components/E1PActivityComponent/E1PActivityComponent';
import E1PNotesComponent from './components/E1PNotesComponent/E1PNotesComponent';
import E1PReassignActivity from './components/E1PReassignActivityComponent/E1PReassignActivity';
import QuoteUnderWritingIssuesComponent from './components/QuoteUnderwritingIssues/QuoteUnderwritingIssues';
import PackageDifferenceComponent from './components/PackageDifferenceComponent/PackageDifferenceComponent';
import EAPriorCarrierComponent from './components/EAPriorCarrierComponent/EAPriorCarrierComponent';
import EARiskAnalysisMiscComponent from './components/EARiskAnalysisMiscComponent/EARiskAnalysisMiscComponent';
import EAUWIssuesComponent from './components/EAUWIssuesComponent/EAUWIssuesComponent';
import CreditReportsComponent from './components/CreditReportsComponent/CreditReportsComponent';
import EASingleUWIssueComponent from './components/EASingleUWIssueComponent/EASingleUWIssueComponent';
import AutoLossesAndViolationsComponent from './components/AutoLossesAndViolationsComponent/AutoLossesAndViolationsComponent';
import ThankyouPageMessagesComponent from './components/ThankyouPageMessagesComponent/ThankyouPageMessagesComponent';
import ThankyouPagePolicyDocumentsComponent from './components/ThankyouPagePolicyDocumentsComponent/ThankyouPagePolicyDocumentsComponent';
import ThankyouPageAddNewLineComponent from './components/ThankyouPageAddNewLineComponent/ThankyouPageAddNewLineComponent';
import ThankyouPageRequiredDocumentsComponent from './components/ThankyouPageRequiredDocumentsComponent/ThankyouPageRequiredDocumentsComponent';
import AddCreditExceptionComponent from './components/AddCreditExceptionComponent/AddCreditExceptionComponent';
import E1PDateComponent from './components/E1PDateComponent/E1PDateComponent';
import AddCreditFixComponent from './components/AddCreditFixComponent/AddCreditFixComponent';
import EHRiskAddressComponent from './components/RiskAddressComponent/RiskAddressComponent';
import CommonPriorAddressComponent from './components/CommonPriorAddressComponent/CommonPriorAddressComponent';
import RiskAnalysisUWIssuesComponent from './components/RiskAnalysisUWIssuesComponent/RiskAnalysisUWIssuesComponent';
import RiskAnalysisPremiumComponent from './components/RiskAnalysisPremiumComponent/PremiumComponent';
import NamedInsuredComponent from './components/NamedInsuredComponent/NamedInsuredComponent';
import InsuredContactComponent from './components/InsuredContactComponent/InsuredContactComponent';
import EUVerifiedLossesAndViolationsComponent from './components/EUVerifiedLossesAndViolationsComponent/EUVerifiedLossesAndViolationsComponent';
import E1PContingenciesComponent from './components/E1PContingenciesComponent/E1PContingenciesComponent';
import ExternalProducerDetailsComponent from './components/ExternalProducerDetailsComponent/ExternalProducerDetailsComponent';
import MonthlyPaymentScheduleComponent from './components/MonthlyPaymentScheduleComponent/MonthlyPaymentScheduleComponent';
import UWIssueDetailPopup from './components/UWIssueDetailPopup/UWIssueDetailPopup';
import PolicyTermComponent from './components/PolicyTermComponent/PolicyTermComponent';
import EHPolicyTermComponent from './components/EHPolicyTermComponent/EHPolicyTermComponent';
import BillingMiscellaneousReportComponent from './components/BillingMiscellaneousReportComponent/BillingMiscellaneousReportComponent';
import E1POOSConflictsComponent from './components/E1POOSConflictsComponent/E1POOSConflictsComponent';
import SupportingPolicyComponent from './components/SupportingPolicyComponent/SupportingPolicyComponent';
import SupportingProductPolicyComponent from './components/SupportingProductPolicyComponent/SupportingProductPolicyComponent';
import SupportingPolicyModal from './components/SupportingPolicyComponent/SupportingPolicyModal/SupportingPolicyModal';
import E1PPropertyPageSectionHeaderComponent from './components/E1PPropertyPageSectionHeaderComponent/E1PPropertyPageSectionHeaderComponent';
import GeoDataComponent from './components/GeoDataComponent/GeoDataComponent';
import RVPSectionComponent from './components/RVPSection/RVPSection';
import E1PSignatureComponent from './components/E1PSignatureComponent/E1PSignatureComponent';
import EAMilitaryDeploymentModal from './components/EAMilitaryDeploymentModal/EAMilitaryDeploymentModal';
import InteractiveLetterTableComponent from './components/InteractiveLetterTableComponent/InteractiveLetterTableComponent';
import E1PPaymentusComponent from './components/E1PPaymentusComponent/E1PPaymentusComponent';
import EHScheduleItemDetailsModal from './components/EHScheduleItemsComponent/EHScheduleItemDetailsModal';
import QNBClauseComponentForOptionalCov from './components/EHClauses/ClauseComponentForOptionalCov';
import QNBEHClauseComponent from './components/EHClauses/ClauseComponent';
import EHQuoteClauseTable from './components/EHQuoteClauseTable/QuoteClauseTable';
import ChangeNamedInsuredComponent from './components/ChangeNamedInsuredComponent/ChangeNamedInsuredComponent';
import E1PPageHeaderCompletionStatusComponent from './components/E1PPageHeaderCompletionStatusComponent/E1PPageHeaderCompletionStatusComponent';
import E1PAddTPIComponent from './components/E1PAddTPIComponent/E1PAddTPIComponent';
import AddDriverComponent from './components/AddDriverComponent/AddDriverComponent';
import E1PProductImageComponent from './components/E1PProductImageComponent/E1PProductImageComponent';
import PrivatePassengerQuestionComponent from './components/PrivatePassengerQuestionComponent/PrivatePassengerQuestionComponent';
import ProtectiveDevices from './components/EHProtectiveDevices/ProtectiveDevicesPage';
import LossMitigationComponent from './components/EHSCLossMitigationComponent/LossMitigationComponent';
import OpeningProtectionComponent from './components/EHOpeningProtectionComponent/OpeningProtectionComponent';
import VehicleHistoryComponent from './components/VehicleHistorySection/VehicleHistory';
import PriorPolicyManualGridComponent from './components/PriorPolicyManualGridComponent/PriorPolicyManualGridComponent';
import E1PPreRenewalDirectionComponent from './components/E1PPreRenewalDirectionComponent/E1PPreRenewalDirectionComponent';
import EHPropertyInspectionComponent from './components/EHPropertyInspectionComponent/EHPropertyInspectionComponent';
import OverflowScrollingComponent from './components/OverflowScrollingComponent/OverflowScrollingComponent';
import OOSConflictsRewritePageComponent from './components/OOSConflictsRewritePageComponent/OOSConflictsRewritePageComponent';
import E1PRenewalNonRenewComponent from './components/E1PRenewalNonRenewComponent/E1PRenewalNonRenewComponent';
import E1PReorderCreditModalComponent from './components/E1PReorderCreditModalComponent/E1PReorderCreditModalComponent';
import E1PCreditReportModalComponent from './components/E1PCreditReportModalComponent/E1PCreditReportModalComponent';
import E1PPremiumAdjustmentTableComponent from './components/E1PPremiumAdjustmentTableComponent/E1PPremiumAdjustmentTableComponent';
import E1PPremiumAdjustmentRowComponent from './components/E1PPremiumAdjustmentRowComponent/E1PPremiumAdjustmentRowComponent';
import E1PPremiumAdjustmentAmountComponent from './components/E1PPremiumAdjustmentAmountComponent/E1PPremiumAdjustmentAmountComponent';
import PackageQualifyingEndorsementsComponent from './components/PackageQualifyingEndorsementsComponent/PackageQualifyingEndorsementsComponent';
import EHProtectiveCheckboxGroupComponent from './components/EHProtectiveCheckboxGroupComponent/ProtectiveCheckboxGroup';
import E1PDeliverDocumentsIndComponent from './components/E1PDeliveryDocumentIndComponent/E1PDeliverDocumentsIndComponent';
import PersonalInjuryProtectionComponent from './components/PersonalInjuryProtectionComponent/PersonalInjuryProtectionComponent';
import EUVehicleOperatorGridComponent from './components/EUVehicleOperatorComponent/EUVehicleOperatorGridComponent';
import ComprehensiveOnlyDescriptionComponent from './components/ComprehensiveOnlyDescriptionComponent/ComprehensiveOnlyDescriptionComponent';
import ComprehensiveOnlyCheckboxComponent from './components/ComprehensiveOnlyCheckboxComponent/ComprehensiveOnlyCheckboxComponent';
import E1PNewQuoteRedirectComponent from './components/E1PNewQuoteRedirectComponent/E1PNewQuoteRedirectComponent';
import EUVehicleExposureGridComponent from './components/EUVehicleExposureGridComponent/EUVehicleExposureGridComponent';
import EUVehicleExposureTableComponent from './components/EUVehicleExposureTableComponent/EUVehicleExposureTableComponent';
import VehiclePackageCovRadioGroupComponent from './components/VehiclePackageCovRadioGroupComponent/VehiclePackageCovRadioGroupComponent';
import RatingDateTestComponent from './components/RatingDateTestComponent/RatingDateTestComponent';
import E1PAssignActivitiesComponent from './components/E1PAssignActivitiesComponent/E1PAssignActivitiesComponent';
import E1PEHTPIDisplayTableComponent from './components/E1PEHTPIDisplayTableComponent/E1PEHTPIDisplayTableComponent';
import E1PTPISearchComponent from './components/E1PTPISearchComponent/E1PTPISearchComponent';
import E1PEHTPISearchDetailComponent from './components/E1PEHTPISearchDetailComponent/E1PEHTPISearchDetailComponent';
import E1PEHTPITrusteeDetailComponent from './components/E1PEHTPITrusteeDetailComponent/E1PEHTPITrusteeDetailComponent';
import E1PContactsFoundComponent from './components/E1PContactsFoundComponent/E1PContactsFoundComponent';
import E1PPreferredTPIExistComponent from './components/E1PPreferredTPIExistComponent/E1PPreferredTPIExistComponent';
import E1PTransactionActionsComponent from './components/E1PTransactionActionsComponent/E1PTransactionActionsComponent';
import E1PEATPIDisplayTableComponent from './components/E1PEATPIDisplayTableComponent/E1PEATPIDisplayTableComponent';
import E1PEATPISearchDetailComponent from './components/E1PEATPISearchDetailComponent/E1PEATPISearchDetailComponent';
import SideBySideActionBarComponent from './components/SideBySideActionBarComponent/SideBySideActionBarComponent';
import E1PRatingPlansComponent from './components/E1PRatingPlansComponent/E1PRatingPlansComponent';
import E1PLoader from './components/E1PLoader/E1PLoader';
import FeesAndOtherChargesComponent from './components/FeesAndOtherChargesComponent/FeesAndOtherChargesComponent';
import DriverAssignmentTable from './components/DriverAssignmentTable/DriverAssignmentTable';
import E1PWindstormMitigationDiscountComponent from './components/E1PWindstormMitigationDiscountComponent/E1PWindstormMitigationDiscountComponent';
import E1PWindstormMitigationDiscountModal from './components/E1PWindstormMitigationDiscountModal/E1PWindstormMitigationDiscountModal';
import EHSingleClauseComponentVM from './components/EHClauses/SingleClauseComponentVM';
import EHOptionalCoveragesComponent from './components/EHOptionalCoveragesAddEdit/OptionalCoverages';
import EHSingleClauseComponentForOptionalCovVM from './components/EHClauses/SingleClauseComponentForOptionalCovVM';
import CopyQuoteComponent from './components/CopyQuoteComponent/CopyQuoteComponent';
import QuoteProposalComponent from './components/QuoteProposalComponent/QuoteProposalComponent';
import E1PEUTPIDisplayTableComponent from './components/E1PEUTPIDisplayTableComponent/E1PEUTPIDisplayTableComponent';
import E1PEUTPISearchDetailComponent from './components/E1PEUTPISearchDetailComponent/E1PEUTPISearchDetailComponent';
import ManualLossHistoryTableComponent from './components/ManualLossHistoryTableComponent/ManualLossHistoryTableComponent';
import E1PSSNComponent from './components/E1PSSNComponent/E1PSSNComponent';
import E1PDropdownSelectComponent from './components/E1PDropdownSelectComponent/E1PDropdownSelectComponent';
import E1PCopyVehicleCoveragesComponent from './components/E1PCopyVehicleCoveragesComponent/E1PCopyVehicleCoveragesComponent';
import E1PCopyVehicleCoveragesModal from './components/E1PCopyVehicleCoveragesComponent/E1PCopyVehicleCoveragesModal/E1PCopyVehicleCoveragesModal';
import EHSpecialLossOptionalCoverageComponent from './components/EHSpecialLossOptionalCoverageComponent/EHSpecialLossOptionalCoverageComponent';
import EADriverGrid from './components/EADriverGrid/EADriverGridComponent';
import EAVehicleGrid from './components/EAVehicleGridComponent/EAVehicleGridComponent';
import EUPreQualificationComponent from './components/EUPreQualificationComponent/EUPreQualificationComponent';
import E1PEUUnderlyingPolicyComponent from './components/E1PEUUnderlyingPolicyComponent/E1PEUUnderlyingPolicyComponent';
import ConnectPartnerInformationComponent from './components/ConnectPartnerInformationComponent/ConnectPartnerInformationComponent';
import LimitsDropdownComponent from './components/LimitsDropdownComponent/LimitsDropdownComponent';
import EHHO4PropertyDetailsComponent from './components/EHHO4PropertyDetailsComponent/EHHO4PropertyDetailsComponent';
import E1PEHHO6PropertyDetailsComponent from './components/E1PEHHO6PropertyDetailsComponent/E1PEHHO6PropertyDetailsComponent';
import E1PEHHO3PropertyDetailsComponent from './components/E1PEHHO3PropertyDetailsComponent/E1PEHHO3PropertyDetailsComponent';
import E1PEHHF9PropertyDetailsComponent from './components/E1PEHHF9PropertyDetailsComponent/E1PEHHF9PropertyDetailsComponent';
import PrintComponent from './components/PrintComponent/PrintComponent';
import MoreMenuComponent from './components/MoreMenuComponent/MoreMenuComponent'
import E1PIframeComponent from './components/E1PIframeComponent/E1PIframeComponent';
import E1PFieldLinkComponent from './components/E1PFieldLinkComponent/E1PFieldLinkComponent';

const e1pPolicyJobComponentMap = {
    AddressStandardizationComponent: {
        component: 'AddressStandardizationComponent'
    },
    eaGaragingAddressComponent: { component: 'EAGaragingAddressComponent' },
    eaGaragingAddressModal: { component: 'EAGaragingAddressModal' },
    EHRiskAnalysisMiscComponent: { component: 'EHRiskAnalysisMiscComponent' },
    EHRoofScoreDataComponent: { component: 'EHRoofScoreDataComponent' },
    LossTableComponent: { component: 'LossTableComponent' },
    prioraddresscomponent: { component: 'PriorAddressComponent' },
    ProducerAddressComponent: { component: 'ProducerAddressComponent' },
    accountsearchcomponent: { component: 'AccountSearchComponent' },
    AccountsFoundComponent: { component: 'AccountsFoundComponent' },
    insideWallMaterial: { component: 'InsideWallMaterial' },
    floorComponent: { component: 'FloorComponent' },
    ehGarageComponent: { component: 'EHGarageComponent' },
    heatingSystemComponent: { component: 'HeatingSystemComponent' },
    coolingSystemComponent: { component: 'CoolingSystemComponent' },
    firePlaceComponent: { component: 'FirePlaceComponent' },
    UnderWritingIssuesComponent: { component: 'UnderWritingIssuesComponent' },
    UWIssueDetail: { component: 'UWIssueDetail' },
    eavehicleprefillcomponent: { component: 'EAVehiclePrefillComponent' },
    priorPolicyComponent: { component: 'PriorPolicyComponent' },
    SymbolCurrencyComponent: { component: 'SymbolCurrencyComponent' },
    EADriverPrefillComponent: { component: 'EADriverPrefillComponent' },
    EANotInHouseholdComponent: { component: 'EANotInHouseholdComponent' },
    eaLossReportComponent: { component: 'EALossReportComponent' },
    eaViolationReportComponent: { component: 'EAViolationReportComponent' },
    AddressComponent: { component: 'AddressComponent' },
    MailingAndBillingAddressComponent: { component: 'MailingAndBillingAddressComponent' },
    StandardizationComponent: { component: 'StandardizationComponent' },
    ManualPropertyLossesComponent: { component: 'ManualPropertyLossesComponent' },
    ManualPropertyLossComponent: { component: 'ManualPropertyLossComponent' },
    ManualPropertyLossesInlineComponent: {
        component: 'ManualPropertyLossesInlineComponent'
    },
    ThirdPartyReportComponent: { component: 'ThirdPartyReportComponent' },
    ThirdPartyReportsComponent: { component: 'ThirdPartyReportsComponent' },
    EHScheduleItemsComponent: { component: 'EHScheduleItemsComponent' },
    EHOptionalCoveragesReadOnlyComponent: {
        component: 'EHOptionalCoveragesReadOnlyComponent'
    },
    E1PLicenseComponent: { component: 'E1PLicenseComponent' },
    E1PAcknowledgementComponent: { component: 'E1PAcknowledgementComponent' },
    DiscountsListComponent: { component: 'DiscountsListComponent' },
    SurchargeListComponent: { component: 'SurchargeListComponent' },
    PaymentOptionsComponent: { component: 'PaymentOptionsComponent' },
    PaperlessDetailPopup: { component: 'PaperlessDetailPopup' },
    PniPriorPolicyGridComponent: { component: 'PniPriorPolicyGridComponent' },
    SniPriorPolicyGridComponent: { component: 'SniPriorPolicyGridComponent' },
    AutoLossesAndViolationsComponent: { component: 'AutoLossesAndViolationsComponent' },
    HistoryTableComponent: { component: 'HistoryTableComponent' },
    E1PActivityTableComponent: { component: 'E1PActivityTableComponent' },
    E1PActivityComponent: { component: 'E1PActivityComponent' },
    E1PNotesComponent: { component: 'E1PNotesComponent' },
    E1PReassignActivity: { component: 'E1PReassignActivity' },
    QuoteUnderWritingIssuesComponent: {
        component: 'QuoteUnderWritingIssuesComponent'
    },
    PackageDifferenceComponent: { component: 'PackageDifferenceComponent' },
    EAPriorCarrierComponent: { component: 'EAPriorCarrierComponent' },
    EARiskAnalysisMiscComponent: { component: 'EARiskAnalysisMiscComponent' },
    EAUWIssuesComponent: { component: 'EAUWIssuesComponent' },
    CreditReportsComponent: { component: 'CreditReportsComponent' },
    EASingleUWIssueComponent: { component: 'EASingleUWIssueComponent' },
    ThankyouPageMessagesComponent: { component: 'ThankyouPageMessagesComponent' },
    ThankyouPagePolicyDocumentsComponent: { component: 'ThankyouPagePolicyDocumentsComponent' },
    ThankyouPageAddNewLineComponent: { component: 'ThankyouPageAddNewLineComponent' },
    ThankyouPageRequiredDocumentsComponent: { component: 'ThankyouPageRequiredDocumentsComponent' },
    AddCreditExceptionComponent: { component: 'AddCreditExceptionComponent' },
    E1PDateComponent: { component: 'E1PDateComponent' },
    AddCreditFixComponent: { component: 'AddCreditFixComponent' },
    EHRiskAddressComponent: { component: 'EHRiskAddressComponent' },
    CommonPriorAddressComponent: { component: 'CommonPriorAddressComponent' },
    RiskAnalysisUWIssuesComponent: { component: 'RiskAnalysisUWIssuesComponent' },
    RiskAnalysisPremiumComponent: { component: 'RiskAnalysisPremiumComponent' },
    NamedInsuredComponent: { component: 'NamedInsuredComponent' },
    InsuredContactComponent: { component: 'InsuredContactComponent' },
    EUVerifiedLossesAndViolationsComponent: { component: 'EUVerifiedLossesAndViolationsComponent' },
    E1PContingenciesComponent: { component: 'E1PContingenciesComponent' },
    ExternalProducerDetailsComponent: { component: 'ExternalProducerDetailsComponent' },
    MonthlyPaymentScheduleComponent: { component: 'MonthlyPaymentScheduleComponent' },
    UWIssueDetailPopup: { component: 'UWIssueDetailPopup' },
    PolicyTermComponent: { component: 'PolicyTermComponent' },
    EHPolicyTermComponent: { component: 'EHPolicyTermComponent' },
    BillingMiscellaneousReportComponent: { component: 'BillingMiscellaneousReportComponent' },
    E1POOSConflictsComponent: { component: 'E1POOSConflictsComponent' },
    SupportingPolicyComponent: { component: 'SupportingPolicyComponent' },
    SupportingProductPolicyComponent: { component: 'SupportingProductPolicyComponent' },
    SupportingPolicyModal: { component: 'SupportingPolicyModal' },
    E1PPropertyPageSectionHeaderComponent: { component: 'E1PPropertyPageSectionHeaderComponent' },
    GeoDataComponent: { component: 'GeoDataComponent' },
    RVPSectionComponent: { component: 'RVPSectionComponent' },
    E1PSignatureComponent: { component: 'E1PSignatureComponent' },
    EAMilitaryDeploymentModal: { component: 'EAMilitaryDeploymentModal' },
    InteractiveLetterTableComponent: { component: 'InteractiveLetterTableComponent' },
    E1PPaymentusComponent: { component: 'E1PPaymentusComponent' },
    EHScheduleItemDetailsModal: { component: 'EHScheduleItemDetailsModal' },
    QNBClauseComponentForOptionalCov: { component: 'QNBClauseComponentForOptionalCov' },
    QNBEHClauseComponent: { component: 'QNBEHClauseComponent' },
    EHQuoteClauseTable: { component: 'EHQuoteClauseTable' },
    ChangeNamedInsuredComponent: { component: 'ChangeNamedInsuredComponent' },
    E1PPageHeaderCompletionStatusComponent: { component: 'E1PPageHeaderCompletionStatusComponent' },
    E1PAddTPIComponent: { component: 'E1PAddTPIComponent' },
    AddDriverComponent: { component: 'AddDriverComponent' },
    E1PProductImageComponent: { component: 'E1PProductImageComponent' },
    PrivatePassengerQuestionComponent: { component: 'PrivatePassengerQuestionComponent' },
    ProtectiveDevices: { component: 'ProtectiveDevices' },
    LossMitigationComponent: { component: 'LossMitigationComponent' },
    OpeningProtectionComponent: { component: 'OpeningProtectionComponent' },
    VehicleHistoryComponent: { component: 'VehicleHistoryComponent' },
    PriorPolicyManualGridComponent: { component: 'PriorPolicyManualGridComponent' },
    E1PPreRenewalDirectionComponent: { component: 'E1PPreRenewalDirectionComponent' },
    EHPropertyInspectionComponent: { component: 'EHPropertyInspectionComponent' },
    OverflowScrollingComponent: { component: 'OverflowScrollingComponent' },
    OOSConflictsRewritePageComponent: { component: 'OOSConflictsRewritePageComponent' },
    E1PRenewalNonRenewComponent: { component: 'E1PRenewalNonRenewComponent' },
    E1PReorderCreditModalComponent: { component: 'E1PReorderCreditModalComponent' },
    E1PCreditReportModalComponent: { component: 'E1PCreditReportModalComponent' },
    E1PPremiumAdjustmentTableComponent: { component: 'E1PPremiumAdjustmentTableComponent' },
    E1PPremiumAdjustmentRowComponent: { component: 'E1PPremiumAdjustmentRowComponent' },
    E1PPremiumAdjustmentAmountComponent: { component: 'E1PPremiumAdjustmentAmountComponent' },
    PackageQualifyingEndorsementsComponent: { component: 'PackageQualifyingEndorsementsComponent' },
    EHProtectiveCheckboxGroupComponent: { component: 'EHProtectiveCheckboxGroupComponent' },
    E1PDeliverDocumentsIndComponent: { component: 'E1PDeliverDocumentsIndComponent' },
    PersonalInjuryProtectionComponent: { component: 'PersonalInjuryProtectionComponent' },
    EUVehicleOperatorGridComponent: { component: 'EUVehicleOperatorGridComponent' },
    ComprehensiveOnlyDescriptionComponent: { component: 'ComprehensiveOnlyDescriptionComponent' },
    ComprehensiveOnlyCheckboxComponent: { component: 'ComprehensiveOnlyCheckboxComponent' },
    E1PNewQuoteRedirectComponent: { component: 'E1PNewQuoteRedirectComponent' },
    EUVehicleExposureGridComponent: { component: 'EUVehicleExposureGridComponent' },
    EUVehicleExposureTableComponent: { component: 'EUVehicleExposureTableComponent' },
    VehiclePackageCovRadioGroupComponent: { component: 'VehiclePackageCovRadioGroupComponent' },
    RatingDateTestComponent: { component: 'RatingDateTestComponent' },
    E1PAssignActivitiesComponent: { component: 'E1PAssignActivitiesComponent' },
    E1PEHTPIDisplayTableComponent: { component: 'E1PEHTPIDisplayTableComponent' },
    E1PTPISearchComponent: { component: 'E1PTPISearchComponent' },
    E1PEHTPISearchDetailComponent: { component: 'E1PEHTPISearchDetailComponent' },
    E1PEHTPITrusteeDetailComponent: { component: 'E1PEHTPITrusteeDetailComponent' },
    E1PContactsFoundComponent: { component: 'E1PContactsFoundComponent' },
    E1PPreferredTPIExistComponent: { component: 'E1PPreferredTPIExistComponent' },
    E1PTransactionActionsComponent: { component: 'E1PTransactionActionsComponent' },
    E1PEATPIDisplayTableComponent: { component: 'E1PEATPIDisplayTableComponent' },
    E1PEATPISearchDetailComponent: { component: 'E1PEATPISearchDetailComponent' },
    SideBySideActionBarComponent: { component: 'SideBySideActionBarComponent' },
    E1PRatingPlansComponent: { component: 'E1PRatingPlansComponent' },
    E1PLoader: { component: 'E1PLoader' },
    FeesAndOtherChargesComponent: { component: 'FeesAndOtherChargesComponent' },
    DriverAssignmentTable: { component: 'DriverAssignmentTable' },
    E1PWindstormMitigationDiscountComponent: { component: 'E1PWindstormMitigationDiscountComponent'},
    E1PWindstormMitigationDiscountModal: { component: 'E1PWindstormMitigationDiscountModal'},
    EHSingleClauseComponentVM: {component : 'EHSingleClauseComponentVM'},
    EHOptionalCoveragesComponent: {component : 'EHOptionalCoveragesComponent'},
    EHSingleClauseComponentForOptionalCovVM: {component : 'EHSingleClauseComponentForOptionalCovVM'},
    CopyQuoteComponent: {component : 'CopyQuoteComponent'},
    QuoteProposalComponent: {component: 'QuoteProposalComponent'},
    E1PEUTPIDisplayTableComponent: {component : 'E1PEUTPIDisplayTableComponent'},
    E1PEUTPISearchDetailComponent: {component : 'E1PEUTPISearchDetailComponent'},
    ManualLossHistoryTableComponent: {component: 'ManualLossHistoryTableComponent'},
    E1PSSNComponent: {component: 'E1PSSNComponent'},
    E1PDropdownSelectComponent: {component: 'E1PDropdownSelectComponent'},
    E1PCopyVehicleCoveragesComponent: {component: 'E1PCopyVehicleCoveragesComponent'},
    E1PCopyVehicleCoveragesModal: {component: 'E1PCopyVehicleCoveragesModal'},
    EHSpecialLossOptionalCoverageComponent: {component: 'EHSpecialLossOptionalCoverageComponent'},
    EADriverGrid: {component: 'EADriverGrid'},
    EAVehicleGrid: {component: 'EAVehicleGrid'},
    EUPreQualificationComponent: {component: 'EUPreQualificationComponent'},
    E1PEUUnderlyingPolicyComponent: {component: 'E1PEUUnderlyingPolicyComponent'},
    ConnectPartnerInformationComponent: {component: 'ConnectPartnerInformationComponent'},
    LimitsDropdownComponent: {component: 'LimitsDropdownComponent'},
    EHHO4PropertyDetailsComponent: {component: 'EHHO4PropertyDetailsComponent'},
    E1PEHHO6PropertyDetailsComponent: {component: 'E1PEHHO6PropertyDetailsComponent'},
    E1PEHHO3PropertyDetailsComponent: {component: 'E1PEHHO3PropertyDetailsComponent'},
    E1PEHHF9PropertyDetailsComponent: {component: 'E1PEHHF9PropertyDetailsComponent'},
    PrintComponent: {component: 'PrintComponent'},
    MoreMenuComponent: {component: 'MoreMenuComponent'},
    E1PIframeComponent: {component: 'E1PIframeComponent'},
    E1PFieldLinkComponent: {component: 'E1PFieldLinkComponent'}
};

const e1pPolicyJobComponents = {
    AddressStandardizationComponent,
    EAGaragingAddressComponent,
    EAGaragingAddressModal,
    EHRiskAnalysisMiscComponent,
    EHRoofScoreDataComponent,
    LossTableComponent,
    PriorAddressComponent,
    ProducerAddressComponent,
    AccountSearchComponent,
    AccountsFoundComponent,
    InsideWallMaterial,
    FloorComponent,
    EHGarageComponent,
    HeatingSystemComponent,
    CoolingSystemComponent,
    FirePlaceComponent,
    UnderWritingIssuesComponent,
    UWIssueDetail,
    EAVehiclePrefillComponent,
    PriorPolicyComponent,
    SymbolCurrencyComponent,
    EADriverPrefillComponent,
    EANotInHouseholdComponent,
    VehicleHistoryComponent,
    RVPSectionComponent,
    EALossReportComponent,
    EAViolationReportComponent,
    AutoLossesAndViolationsComponent,
    AddressComponent,
    MailingAndBillingAddressComponent,
    StandardizationComponent,
    ManualPropertyLossesComponent,
    ManualPropertyLossComponent,
    ManualPropertyLossesInlineComponent,
    ThirdPartyReportComponent,
    ThirdPartyReportsComponent,
    EHScheduleItemsComponent,
    EHOptionalCoveragesReadOnlyComponent,
    E1PLicenseComponent,
    E1PAcknowledgementComponent,
    DiscountsListComponent,
    SurchargeListComponent,
    PaymentOptionsComponent,
    PaperlessDetailPopup,
    PniPriorPolicyGridComponent,
    SniPriorPolicyGridComponent,
    HistoryTableComponent,
    E1PActivityTableComponent,
    E1PActivityComponent,
    E1PReassignActivity,
    E1PNotesComponent,
    QuoteUnderWritingIssuesComponent,
    PackageDifferenceComponent,
    EAPriorCarrierComponent,
    EARiskAnalysisMiscComponent,
    EAUWIssuesComponent,
    CreditReportsComponent,
    EASingleUWIssueComponent,
    ThankyouPageMessagesComponent,
    ThankyouPagePolicyDocumentsComponent,
    ThankyouPageAddNewLineComponent,
    ThankyouPageRequiredDocumentsComponent,
    AddCreditExceptionComponent,
    E1PDateComponent,
    AddCreditFixComponent,
    EHRiskAddressComponent,
    CommonPriorAddressComponent,
    RiskAnalysisUWIssuesComponent,
    RiskAnalysisPremiumComponent,
    NamedInsuredComponent,
    InsuredContactComponent,
    EUVerifiedLossesAndViolationsComponent,
    E1PContingenciesComponent,
    ExternalProducerDetailsComponent,
    MonthlyPaymentScheduleComponent,
    UWIssueDetailPopup,
    PolicyTermComponent,
    EHPolicyTermComponent,
    BillingMiscellaneousReportComponent,
    E1POOSConflictsComponent,
    SupportingPolicyComponent,
    SupportingProductPolicyComponent,
    SupportingPolicyModal,
    E1PPropertyPageSectionHeaderComponent,
    GeoDataComponent,
    E1PSignatureComponent,
    EAMilitaryDeploymentModal,
    InteractiveLetterTableComponent,
    E1PPaymentusComponent,
    EHScheduleItemDetailsModal,
    QNBClauseComponentForOptionalCov,
    QNBEHClauseComponent,
    EHQuoteClauseTable,
    ChangeNamedInsuredComponent,
    E1PPageHeaderCompletionStatusComponent,
    E1PAddTPIComponent,
    AddDriverComponent,
    E1PProductImageComponent,
    PrivatePassengerQuestionComponent,
    ProtectiveDevices,
    LossMitigationComponent,
    OpeningProtectionComponent,
    PriorPolicyManualGridComponent,
    E1PPreRenewalDirectionComponent,
    EHPropertyInspectionComponent,
    OverflowScrollingComponent,
    OOSConflictsRewritePageComponent,
    E1PRenewalNonRenewComponent,
    E1PReorderCreditModalComponent,
    E1PCreditReportModalComponent,
    E1PPremiumAdjustmentTableComponent,
    E1PPremiumAdjustmentRowComponent,
    E1PPremiumAdjustmentAmountComponent,
    PackageQualifyingEndorsementsComponent,
    EHProtectiveCheckboxGroupComponent,
    E1PDeliverDocumentsIndComponent,
    PersonalInjuryProtectionComponent,
    EUVehicleOperatorGridComponent,
    ComprehensiveOnlyDescriptionComponent,
    ComprehensiveOnlyCheckboxComponent,
    E1PNewQuoteRedirectComponent,
    EUVehicleExposureGridComponent,
    EUVehicleExposureTableComponent,
    VehiclePackageCovRadioGroupComponent,
    RatingDateTestComponent,
    E1PAssignActivitiesComponent,
    E1PEHTPIDisplayTableComponent,
    E1PTPISearchComponent,
    E1PEHTPISearchDetailComponent,
    E1PEHTPITrusteeDetailComponent,
    E1PContactsFoundComponent,
    E1PPreferredTPIExistComponent,
    E1PTransactionActionsComponent,
    E1PEATPIDisplayTableComponent,
    E1PEATPISearchDetailComponent,
    SideBySideActionBarComponent,
    E1PRatingPlansComponent,
    E1PLoader,
    FeesAndOtherChargesComponent,
    DriverAssignmentTable,
    E1PWindstormMitigationDiscountComponent,
    E1PWindstormMitigationDiscountModal,
    EHSingleClauseComponentVM,
    EHOptionalCoveragesComponent,
    EHSingleClauseComponentForOptionalCovVM,
    CopyQuoteComponent,
    QuoteProposalComponent,
    E1PEUTPIDisplayTableComponent,
    E1PEUTPISearchDetailComponent,
    ManualLossHistoryTableComponent,
    E1PSSNComponent,
    E1PDropdownSelectComponent,
    E1PCopyVehicleCoveragesComponent,
    E1PCopyVehicleCoveragesModal,
    EHSpecialLossOptionalCoverageComponent,
    EADriverGrid,
    EAVehicleGrid,
    EUPreQualificationComponent,
    E1PEUUnderlyingPolicyComponent,
    ConnectPartnerInformationComponent,
    LimitsDropdownComponent,
    EHHO4PropertyDetailsComponent,
    E1PEHHO6PropertyDetailsComponent,
    E1PEHHO3PropertyDetailsComponent,
    E1PEHHF9PropertyDetailsComponent,
    PrintComponent,
    MoreMenuComponent,
    E1PIframeComponent,
    E1PFieldLinkComponent
}

export {
    AddressStandardizationComponent,
    EAGaragingAddressComponent,
    EAGaragingAddressModal,
    EHRiskAnalysisMiscComponent,
    EHRoofScoreDataComponent,
    LossTableComponent,
    PriorAddressComponent,
    ProducerAddressComponent,
    AccountSearchComponent,
    VehicleHistoryComponent,
    AccountsFoundComponent,
    InsideWallMaterial,
    FloorComponent,
    EHGarageComponent,
    HeatingSystemComponent,
    CoolingSystemComponent,
    FirePlaceComponent,
    UnderWritingIssuesComponent,
    UWIssueDetail,
    EAVehiclePrefillComponent,
    PriorPolicyComponent,
    SymbolCurrencyComponent,
    EADriverPrefillComponent,
    EANotInHouseholdComponent,
    EALossReportComponent,
    EAViolationReportComponent,
    AutoLossesAndViolationsComponent,
    AddressComponent,
    MailingAndBillingAddressComponent,
    StandardizationComponent,
    ManualPropertyLossesComponent,
    ManualPropertyLossComponent,
    ManualPropertyLossesInlineComponent,
    ThirdPartyReportComponent,
    ThirdPartyReportsComponent,
    EHScheduleItemsComponent,
    EHOptionalCoveragesReadOnlyComponent,
    E1PLicenseComponent,
    DiscountsListComponent,
    SurchargeListComponent,
    PaymentOptionsComponent,
    PaperlessDetailPopup,
    PniPriorPolicyGridComponent,
    SniPriorPolicyGridComponent,
    HistoryTableComponent,
    E1PActivityTableComponent,
    E1PActivityComponent,
    E1PNotesComponent,
    E1PReassignActivity,
    QuoteUnderWritingIssuesComponent,
    e1pPolicyJobComponentMap,
    e1pPolicyJobComponents,
    PackageDifferenceComponent,
    EAPriorCarrierComponent,
    EARiskAnalysisMiscComponent,
    EAUWIssuesComponent,
    CreditReportsComponent,
    EASingleUWIssueComponent,
    ThankyouPageMessagesComponent,
    ThankyouPagePolicyDocumentsComponent,
    ThankyouPageAddNewLineComponent,
    ThankyouPageRequiredDocumentsComponent,
    AddCreditExceptionComponent,
    E1PDateComponent,
    AddCreditFixComponent,
    EHRiskAddressComponent,
    CommonPriorAddressComponent,
    RiskAnalysisUWIssuesComponent,
    RiskAnalysisPremiumComponent,
    NamedInsuredComponent,
    EUVerifiedLossesAndViolationsComponent,
    E1PContingenciesComponent,
    ExternalProducerDetailsComponent,
    MonthlyPaymentScheduleComponent,
    UWIssueDetailPopup,
    PolicyTermComponent,
    EHPolicyTermComponent,
    BillingMiscellaneousReportComponent,
    E1POOSConflictsComponent,
    SupportingPolicyComponent,
    SupportingProductPolicyComponent,
    SupportingPolicyModal,
    E1PPropertyPageSectionHeaderComponent,
    GeoDataComponent,
    E1PSignatureComponent,
    EAMilitaryDeploymentModal,
    InteractiveLetterTableComponent,
    E1PPaymentusComponent,
    EHScheduleItemDetailsModal,
    QNBClauseComponentForOptionalCov,
    QNBEHClauseComponent,
    EHQuoteClauseTable,
    ChangeNamedInsuredComponent,
    E1PPageHeaderCompletionStatusComponent,
    E1PAddTPIComponent,
    AddDriverComponent,
    E1PProductImageComponent,
    PrivatePassengerQuestionComponent,
    ProtectiveDevices,
    LossMitigationComponent,
    OpeningProtectionComponent,
    PriorPolicyManualGridComponent,
    E1PPreRenewalDirectionComponent,
    EHPropertyInspectionComponent,
    OverflowScrollingComponent,
    OOSConflictsRewritePageComponent,
    E1PRenewalNonRenewComponent,
    E1PReorderCreditModalComponent,
    E1PCreditReportModalComponent,
    E1PPremiumAdjustmentTableComponent,
    E1PPremiumAdjustmentRowComponent,
    E1PPremiumAdjustmentAmountComponent,
    PackageQualifyingEndorsementsComponent,
    EHProtectiveCheckboxGroupComponent,
    E1PDeliverDocumentsIndComponent,
    PersonalInjuryProtectionComponent,
    EUVehicleOperatorGridComponent,
    ComprehensiveOnlyDescriptionComponent,
    ComprehensiveOnlyCheckboxComponent,
    E1PNewQuoteRedirectComponent,
    EUVehicleExposureGridComponent,
    EUVehicleExposureTableComponent,
    VehiclePackageCovRadioGroupComponent,
    RatingDateTestComponent,
    E1PAssignActivitiesComponent,
    E1PEHTPIDisplayTableComponent,
    E1PTPISearchComponent,
    E1PEHTPISearchDetailComponent,
    E1PEHTPITrusteeDetailComponent,
    E1PContactsFoundComponent,
    E1PPreferredTPIExistComponent,
    E1PTransactionActionsComponent,
    E1PEATPIDisplayTableComponent,
    E1PEATPISearchDetailComponent,
    SideBySideActionBarComponent,
    E1PRatingPlansComponent,
    E1PLoader,
    FeesAndOtherChargesComponent,
    DriverAssignmentTable,
    E1PWindstormMitigationDiscountComponent,
    E1PWindstormMitigationDiscountModal,
    EHSingleClauseComponentVM,
    EHOptionalCoveragesComponent,
    EHSingleClauseComponentForOptionalCovVM,
    CopyQuoteComponent,
    QuoteProposalComponent,
    E1PEUTPIDisplayTableComponent,
    E1PEUTPISearchDetailComponent,
    ManualLossHistoryTableComponent,
    E1PSSNComponent,
    E1PDropdownSelectComponent,
    E1PCopyVehicleCoveragesComponent,
    E1PCopyVehicleCoveragesModal,
    EHSpecialLossOptionalCoverageComponent,
    EADriverGrid,
    EAVehicleGrid,
    EUPreQualificationComponent,
    E1PEUUnderlyingPolicyComponent,
    ConnectPartnerInformationComponent,
    LimitsDropdownComponent,
    EHHO4PropertyDetailsComponent,
    E1PEHHO6PropertyDetailsComponent,
    E1PEHHO3PropertyDetailsComponent,
    E1PEHHF9PropertyDetailsComponent,
    PrintComponent,
    MoreMenuComponent,
    E1PIframeComponent,
    E1PFieldLinkComponent
};
