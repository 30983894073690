import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextWizardStepMessage: {
        id: 'quoteandbind.eh.wizard.Loading next wizard step...',
        defaultMessage: 'Loading next wizard step...'
    },
    anErrorOccurred: {
        id: 'quoteandbind.eh.wizard.error.cancel',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'quoteandbind.eh.wizard.error.cancel.title',
        defaultMessage: 'Error'
    },
    property: {
        id: 'quoteandbind.eh.wizard.step.Property',
        defaultMessage: 'Property'
    },
    saveQuoteError: {
        id: 'quoteandbind.eh.wizard.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'quoteandbind.eh.wizard.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    underwritingPageTitle: {
        id: 'quoteandbind.eh.wizard.step.Underwriting',
        defaultMessage: 'Underwriting'
    },
    riskAnalysisPageTitle: {
        id: 'quoteandbind.eh.wizard.step.RiskAnalysisUW',
        defaultMessage: 'Risk Analysis'
    },
    exitTransaction: {
        id: 'quoteandbind.eh.wizard.step.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'quoteandbind.eh.wizard.step.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'quoteandbind.eh.wizard.step.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'quoteandbind.eh.wizard.step.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    },
    tpiPageTitle: {
        id: 'quoteandbind.eh.wizard.step.Third-Party Interest',
        defaultMessage: 'Third-Party Interest'
    }
});
