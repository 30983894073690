import { defineMessages } from 'react-intl';

export default defineMessages({
    standardizingAddressMessage: {
        id: 'e1p.policyview.eh-policydetails-details.Standardizing address...',
        defaultMessage: 'Standardizing address...'
    },
    loadingNextPageMessage: {
        id: 'e1p.policyview.eh-policydetails-details.Loading next page...',
        defaultMessage: 'Loading next page...'
    },
    creatingYourSubmissionMessage: {
        id: 'e1p.policyview.eh-policydetails-details.Creating your submission...',
        defaultMessage: 'Creating your submission...'
    },
    ehPriorAddressTitle: {
        id: 'e1p.policyview.eh-priorAddress-details.priorAddress',
        defaultMessage: 'Prior Address'
    },
    ehPriorAddressTitleNumber: {
        id: 'e1p.policyview.eh-priorAddress-details.priorAddress Number:',
        defaultMessage: 'Prior Address:'
    },
    ehPriorAddress: {
        id: 'e1p.policyview.Homeowners priorAddress',
        defaultMessage: 'Prior Address'
    },
    removePriorAddressTitle: {
        id: 'e1p.policyview.controllers.EHpriorAddressDetailsCtrl.Remove priorAddress?',
        defaultMessage: 'Remove Prior Address?'
    },
    removePriorAddressDescription: {
        id: 'e1p.policyview.drivers.remove-priorAddress.message',
        defaultMessage: 'Are you sure you want to remove this Prior Address from the list of Prior Addresses?'
    },
    ehAddAnotherPriorAddressTitle: {
        id: 'e1p.policyview.eh-priorAddress-details.Add Another priorAddress?',
        defaultMessage: 'Add Another Prior Address?'
    },
    ehAddPriorAddressButtonText: {
        id: 'e1p.policyview.eh-priorAddress-details.Add priorAddress',
        defaultMessage: 'Add Prior Address'
    },
    ehPriorAddressToggleButton: {
        id: 'e1p.policyview.eh-priorAddress-details.Lived one Year',
        defaultMessage: 'Lived there more than one year?'
    },
    ehAddAnotherPriorAddress: {
        id: 'e1p.policyview.eh-priorAddress-details.Add another address',
        defaultMessage: 'Add Prior Address'
    },
    ehOptional: {
        id: 'e1p.policyview.eh-policydetails-details.optional',
        defaultMessage: 'Optional'
    },
    locationCode: {
        id: 'e1p.policyview.eh-policydetails-details.Location Code',
        defaultMessage: 'Location Code'
    },
    ehAgencyInformation: {
        id: 'e1p.policyview.eh-policydetails-details.Agency Information',
        defaultMessage: 'Agency Information'
    },
    newHomePurchase: {
        id: 'e1p.policyview.policyDetails.New Home Purchase',
        defaultMessage: 'Is this quote for a new home purchase?'
    },
    newHomePurchaseYes: {
        id: 'e1p.policyview.policyDetails.New Home Purchase.Yes',
        defaultMessage: 'Yes'
    },
    newHomePurchaseNo: {
        id: 'e1p.policyview.policyDetails.New Home Purchase.No',
        defaultMessage: 'No'
    },
    currentInsuranceForProperty: {
        id: 'e1p.policyview.policyDetails.Do you Currently have insurance on this Property?',
        defaultMessage: 'Do you Currently have insurance on this Property?'
    },
    currentInsuranceForPropertyYes: {
        id: 'e1p.policyview.policyDetails.Do you Currently have insurance on this Property?.Yes',
        defaultMessage: 'Yes'
    },
    currentInsuranceForPropertyNo: {
        id: 'e1p.policyview.policyDetails.Do you Currently have insurance on this Property?.No',
        defaultMessage: 'No'
    },
    lastPolicyExpiration: {
        id: 'e1p.policyview.policyDetails.When did your last Policy Expire?',
        defaultMessage: 'When does your current policy expire/When did your last policy expire?'
    },
    currentInsuranceExpire: {
        id: 'e1p.policyview.policyDetails.When does your current insurance expire?',
        defaultMessage: 'When does your current insurance expire?'
    },
    insuranceHistory: {
        id: 'e1p.policyview.policyDetails.Insurance History',
        defaultMessage: 'Insurance History'
    },
    losses: {
        id: 'e1p.policyview.policyDetails.Losses',
        defaultMessage: 'Losses'
    },
    propertyLossesLabel: {
        id: 'e1p.policyview.policyDetails.Have you had any Property Losses in the Past 5 year?',
        defaultMessage: 'Have you had any Property Losses in the Past 5 year?'
    },
    propertyLossesYes: {
        id: 'e1p.policyview.policyDetails.Have you had any Property Losses in the Past 5 year?.Yes',
        defaultMessage: 'Yes'
    },
    propertyLossesNo: {
        id: 'e1p.policyview.policyDetails.Have you had any Property Losses in the Past 5 year?.No',
        defaultMessage: 'No'
    },
    manualPropertyLossesAdd: {
        id: 'e1p.policyview.Policy Details.Manual Property Losses.Add Property Losses',
        defaultMessage: 'Add Property Losses'
    },
    accountTypeErrorMessage: {
        id: 'e1p.policyview.policyDetails.Account type must be a Company or a Person',
        defaultMessage: 'Account type must be a Company or a Person'
    },
    newAccountCreateErrorMessage: {
        id: 'e1p.policyview.policyDetails.Could not create the Account',
        defaultMessage: 'Could not create the Account'
    },
    effectiveDate: {
        id: 'e1p.policyview.eh-policy-info.Effective Date',
        defaultMessage: 'Effective Date'
    },
    expirationDate: {
        id: 'e1p.policyview.eh-policy-info.Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    rateAsOfDate: {
        id: 'e1p.policyview.eh-policy-info.Rate as of Date',
        defaultMessage: 'Rate as of Date'
    },
    riskState: {
        id: 'e1p.policyview.eh-policy-info.Risk State',
        defaultMessage: 'Risk State'
    },
    middleName: {
        id: 'e1p.policyview.eh-policy-info.M.I.',
        defaultMessage: 'M.I.'
    },
    zipCode: {
        id: 'e1p.policyview.eh-policy-info.Zip Code',
        defaultMessage: 'Zip Code'
    },
    sourceOfEntry: {
        id: 'e1p.policyview.ea-policydetails-details.Source of Entry',
        defaultMessage: 'Source of Entry'
    },
    policyForm: {
        id: 'e1p.policyview.eh-policy-info.Policy Form',
        defaultMessage: 'Policy Form'
    },
    policyInformation: {
        id: 'e1p.policyview.eh-policy-info.Policy Information',
        defaultMessage: 'Policy Information'
    },
    lastName: {
        id: 'e1p.policyview.eh-policydetails-details.Last Name',
        defaultMessage: 'Last Name'
    },
    email: {
        id: 'e1p.policyview.eh-policydetails-details.Email',
        defaultMessage: 'Email'
    },
    newPurchaseWithin180Days: {
        id: 'e1p.policyview.policyDetails.New purchase within 180 days',
        defaultMessage: 'New purchase within 180 days'
    },
    selfDeclaredPropertyLossesTitle: {
        id: 'e1p.policyview.insuredPage.Self Declared Property Losses',
        defaultMessage: 'Self Declared Property Losses'
    },
    locationCodeHelpText: {
        id: 'policyview.eh.quick.views.mailingAndBillingAddress.Location Code(s) available. Contact MSA at FOSCLicensing@MSAGROUP.COM to request updates.',
        defaultMessage: 'Location Code(s) available. Contact MSA at FOSCLicensing@MSAGROUP.COM to request updates.'
    }
});
