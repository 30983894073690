import { useCallback } from 'react';
import _ from 'lodash';

/**
 * @param {*} garagingAddressVM
 * @returns {*} object
 */
function useGaragingAddressUtil() {
    /**
     * Helper callback for generating the display name of a garaging address. This functionality is
     * necessary because the entity display names are normally generated upon navigating to the
     * next wizard page.
     */
    const generateGaragingAddressDisplayName = useCallback((garagingAddressVM) => {
        const addressLine1 = _.get(garagingAddressVM, 'addressLine1').value;
        let addressLine2 = _.get(garagingAddressVM, 'addressLine2').value;
        addressLine2 = addressLine2 ? `${addressLine2}, ` : '';
        const city = _.get(garagingAddressVM, 'city').value;
        const state = _.get(garagingAddressVM, 'state').value.code;
        const postalCode = _.get(garagingAddressVM, 'postalCode').value;
        const gaDisplayName = `${addressLine1}${addressLine2}, ${city}, ${state} ${postalCode}`;
        _.set(garagingAddressVM, 'displayName', gaDisplayName);
    }, []);

    return {
        generateGaragingAddressDisplayName
    };
}

export default useGaragingAddressUtil;
