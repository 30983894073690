import PropTypes from 'prop-types';

export const wizardStepProps = {
    title: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string
    }), PropTypes.string]),
    path: PropTypes.string,
    component: PropTypes.elementType,
};

const stepInWizard = PropTypes.shape({
    ...wizardStepProps,
    visited: PropTypes.bool,
    submitted: PropTypes.bool,
    disabled: PropTypes.bool
});

export const wizardProps = {
    // wizard details
    wizardTitle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired
    }),
    // step props
    steps: PropTypes.arrayOf(stepInWizard),
    currentStepIndex: PropTypes.number,
    changeNextSteps: PropTypes.func,
    currentStep: stepInWizard,
    disableAllSteps: PropTypes.func,
    // transition props
    isSkipping: PropTypes.bool,
    stopSkipping: PropTypes.func,
    goNext: PropTypes.func,
    goPrevious: PropTypes.func,
    markStepSubmitted: PropTypes.func,
    jumpTo: PropTypes.func,
    cancel: PropTypes.func,
    finish: PropTypes.func,
    lastVisitedRiskAnalysisTab: PropTypes.string,
    setLastVisitedRiskAnalysisTab: PropTypes.func,
    // wizard data props
    wizardData: PropTypes.any,
    wizardSnapshot: PropTypes.any,
    updateWizardData: PropTypes.func,
    updateWizardSnapshot: PropTypes.func,
    // wizard error props
    hasNewErrors: PropTypes.bool,
    errorsForStep: PropTypes.arrayOf(PropTypes.shape({})),
    stepsWithErrors: PropTypes.shape({}),
    underwritingIssues: PropTypes.arrayOf(PropTypes.shape({})),
    acknowledgeError: PropTypes.func,
    reportErrors: PropTypes.func
};

export const pageTemplateProps = {
    wizardData: PropTypes.any,

    onNext: PropTypes.func.isRequired,
    disableNext: PropTypes.bool.isRequired,
    isLoadingNext: PropTypes.bool,
    showNext: PropTypes.bool.isRequired,
    nextLabel: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string
    }), PropTypes.string]).isRequired,

    onPrevious: PropTypes.func.isRequired,
    disablePrevious: PropTypes.bool.isRequired,
    isLoadingPrevious: PropTypes.bool,
    showPrevious: PropTypes.bool.isRequired,
    previousLabel: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string
    }), PropTypes.string]).isRequired,

    onCancel: PropTypes.func.isRequired,
    disableCancel: PropTypes.bool.isRequired,
    isLoadingCancel: PropTypes.bool,
    showCancel: PropTypes.bool.isRequired,
    cancelLabel: PropTypes.oneOfType([PropTypes.shape({
        id: PropTypes.string
    }), PropTypes.string]).isRequired,

    children: PropTypes.node
};
