import { defineMessages } from 'react-intl';

export default defineMessages({
    headerAccountName: {
        id: 'e1p.gateway.dashboard.accounts.Account Name',
        defaultMessage: 'Account Name'
    },
    headerAccountAddress: {
        id: 'e1p.gateway.dashboard.accounts.Address',
        defaultMessage: 'Address'
    },
    headerAcountDateCreated: {
        id: 'e1p.gateway.dashboard.accounts.Date Created',
        defaultMessage: 'Date Created'
    },
});
