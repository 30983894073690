import { defineMessages } from 'react-intl';

export default defineMessages({
    loading: {
        id: 'endorsement.ea.views.riskAnalysis.Loading changes...',
        defaultMessage: 'Loading changes...'
    },
    ratingYourPolicyMessage: {
        id: 'endorsement.ea.views.riskAnalysis.Please wait while we rate your policy...',
        defaultMessage: 'Please wait while we rate your policy...'
    },
    loadingNextPageMessage: {
        id: 'endorsement.ea.views.riskAnalysis.Loading next Page...',
        defaultMessage: 'Loading next page...'
    },
    priorCarrier: {
        id: 'endorsement.ea.views.riskAnalysis.Prior Carrier',
        defaultMessage: 'Prior Carrier'
    },
    lossesAndViolations: {
        id: 'endorsement.ea.views.riskAnalysis.Driver Activity',
        defaultMessage: 'Driver Activity'
    },
    vehicleReport: {
        id: 'endorsement.ea.views.riskAnalysis.Vehicle Report',
        defaultMessage: 'Vehicle Report'
    },
    miscellaneousReports: {
        id: 'endorsement.ea.views.riskAnalysis.Miscellaneous Reports',
        defaultMessage: 'Miscellaneous Reports'
    },
    credit: {
        id: 'endorsement.ea.views.riskAnalysis.Credit',
        defaultMessage: 'Credit'
    },
    reportsErrorTitle: {
        id: 'endorsement.ea.views.riskAnalysis.Error Retrieving Auto Losses',
        defaultMessage: 'Error Retrieving Auto Losses'
    },
    reportsErrorMessage: {
        id: 'endorsement.ea.views.riskAnalysis.An error occurred attempting to retrieving Auto Losses.',
        defaultMessage: 'An error occurred attempting to retrieving Auto Losses.'
    },
    Premium: {
        id: 'endorsement.ea.views.riskAnalysis.Premium',
        defaultMessage: 'Premium'
    }
});
