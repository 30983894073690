import React, { Component } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withModalContext } from '@jutro/components';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { withValidation, validationPropTypes } from '@xengage/gw-portals-validation-react';
import { AccountService } from 'e1p-capability-gateway';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './EditContacts.metadata.json5';
import styles from './EditContacts.module.scss';
import messages from '../Accounts.messages';

class EditContactsWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    state = {
        accountContactVM: {},
        editContactsData: [],
        selectedPhoneType: {
            homeRadioButton: '',
            workRadioButton: '',
            mobileRadioButton: ''
        }
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        accountData: PropTypes.shape({
            accountNumber: PropTypes.string.isRequired,
            accountHolder: PropTypes.shape({
                primaryAddress: PropTypes.shape({}).isRequired,
                emailAddress1: PropTypes.shape({}).isRequired,
                subtype: PropTypes.shape({}).isRequired,
                cellNumber: PropTypes.string.isRequired,
                homeNumber: PropTypes.string.isRequired,
                workNumber: PropTypes.string.isRequired,
                primaryPhoneType: PropTypes.string
            }).isRequired
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        showEditOnCancel: PropTypes.func.isRequired,
        ...validationPropTypes
    };

    componentDidMount() {
        let { accountContactVM } = this.state;
        const model = {};

        accountContactVM = this.createVM(model);
        this.initialAccountData(accountContactVM);
        this.initializeRadioButton(accountContactVM);
    }

    cancelChanges = () => {
        const { showEditOnCancel } = this.props;

        showEditOnCancel();
    };

    initialAccountData = (accountContactVM) => {
        const { accountData } = this.props;

        if (
            !_.isEmpty(accountData)
            && !_.isEmpty(accountData.accountHolder)
            && !_.isEmpty(accountData.accountHolder.primaryAddress)
        ) {
            _.set(accountContactVM, 'accountHolder.value', accountData.accountHolder);
            _.set(accountContactVM, 'homeNumber.value', accountData.accountHolder.homeNumber);
            _.set(accountContactVM, 'workNumber.value', accountData.accountHolder.workNumber);
            _.set(accountContactVM, 'cellNumber.value', accountData.accountHolder.cellNumber);
            _.set(
                accountContactVM,
                'primaryAddress.value',
                accountData.accountHolder.primaryAddress
            );
            _.set(accountContactVM, 'emailAddress1.value', accountData.accountHolder.emailAddress1);
            _.set(
                accountContactVM,
                'primaryPhoneType.value',
                accountData.accountHolder.primaryPhoneType
            );
            this.setState({ accountContactVM });
        }
    };

    createVM = (model) => {
        const { viewModelService } = this.props;

        return viewModelService.create(
            model,
            'pc',
            'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO'
        );
    };

    initializeRadioButton = (accountContactVM) => {
        const { accountData } = this.props;
        const primaryPhone = _.get(accountContactVM, 'primaryPhoneType.value.code');
        let selectedPhone = primaryPhone || 'home';

        if (accountData.accountHolder.subtype === 'Company') {
            selectedPhone = primaryPhone || 'work';
        }

        this.setState({
            selectedPhoneType: {
                workRadioButton: selectedPhone === 'work' ? selectedPhone : '',
                homeRadioButton: selectedPhone === 'home' ? selectedPhone : '',
                mobileRadioButton: selectedPhone === 'mobile' ? selectedPhone : '',
            }
        });
    };

    saveChanges = async () => {
        const { accountData, authHeader, showEditOnCancel } = this.props;
        const { editContactsData } = this.state;
        const requestObject = {
            accountHolder: accountData.accountHolder,
            accountNumber: accountData.accountNumber
        };
        const contacts = await AccountService.updateAccountDetails(requestObject, authHeader);

        if (editContactsData.length === 0) {
            this.setState({
                editContactsData: contacts
            });
        }

        const translator = this.context;

        this.props.modalContext.showAlert({
            title: translator(messages.savedTitle),
            message: translator(messages.savedMessage),
            status: 'success',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.close
        }).catch(_.noop);
        showEditOnCancel(true);
    };

    writeValue = (value, path) => {
        const { accountContactVM } = this.state;
        const phoneTypes = ['homeRadioButton', 'workRadioButton', 'mobileRadioButton'];

        _.set(accountContactVM, 'homeRadioButton', '');
        _.set(accountContactVM, 'workRadioButton', '');
        _.set(accountContactVM, 'mobileRadioButton', '');

        if (phoneTypes.includes(path)) {
            _.set(accountContactVM, path, value);
            _.set(accountContactVM, 'primaryPhoneType.value', value);
            _.set(accountContactVM, 'accountHolder.value.primaryPhoneType', value);
            this.setState({
                accountContactVM,
                selectedPhoneType: {
                    homeRadioButton: path === 'homeRadioButton' ? value : '',
                    workRadioButton: path === 'workRadioButton' ? value : '',
                    mobileRadioButton: path === 'mobileRadioButton' ? value : ''
                }
            });
        } else {
            _.set(accountContactVM, path, value);
            _.set(
                accountContactVM,
                'accountHolder.value.homeNumber',
                accountContactVM.homeNumber.value
            );
            _.set(
                accountContactVM,
                'accountHolder.value.workNumber',
                accountContactVM.workNumber.value
            );
            _.set(
                accountContactVM,
                'accountHolder.value.cellNumber',
                accountContactVM.cellNumber.value
            );
            _.set(
                accountContactVM,
                'accountHolder.value.emailAddress1',
                accountContactVM.emailAddress1.value
            );
            this.setState({ accountContactVM });
        }
    };

    render() {
        const { accountContactVM, selectedPhoneType } = this.state;
        const { accountData, isComponentValid, setComponentValidation } = this.props;
        const translator = this.context;
        const overrideProps = {
            '@field': {
                autoComplete: false
            },
            save: {
                disabled: !isComponentValid
            },
            homeRadioButtonId: {
                value: selectedPhoneType.homeRadioButton ? 'home' : '',
                availableValues: [
                    {
                        code: 'home',
                        name: selectedPhoneType.homeRadioButton
                            ? translator(messages.primaryNumber)
                            : ''
                    }
                ]
            },
            workRadioButtonId: {
                value: selectedPhoneType.workRadioButton ? 'work' : '',
                availableValues: [
                    {
                        code: 'work',
                        name: selectedPhoneType.workRadioButton
                            ? translator(messages.primaryNumber)
                            : ''
                    }
                ]
            },
            mobileRadioButtonId: {
                visible:
                    !accountData.accountHolder.subtype === 'Company'
                    || accountData.accountHolder.subtype === 'Person',
                value: selectedPhoneType.mobileRadioButton ? 'mobile' : '',
                availableValues: [
                    {
                        code: 'mobile',
                        name: selectedPhoneType.mobileRadioButton
                            ? translator(messages.primaryNumber)
                            : ''
                    }
                ]
            },
            mobilePhone: {
                visible:
                    !(_.get(accountData, 'accountHolder.subtype') === 'Company')
                    || (_.get(accountData, 'accountHolder.subtype') === 'Person')
            },
            homePhone: {
                required: _.get(accountData, 'accountHolder.primaryPhoneType.value') === 'home'
            }
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                saveChanges: this.saveChanges,
                cancelChanges: this.cancelChanges
            },
        };

        const readValue = (id, path) => readViewModelValue(
                metadata.pageContent,
                accountContactVM,
                id,
                path,
                overrideProps
            );

        return (
            <div>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={accountContactVM}
                    overrideProps={overrideProps}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                    resolveValue={readValue}
                    onValueChange={this.writeValue}
                    onValidationChange={setComponentValidation}
                />
            </div>
        );
    }
}

const EditContacts = withModalContext(EditContactsWithoutModalContext);

export default withValidation(withViewModelService(withAuthenticationContext(EditContacts)));
