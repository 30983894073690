/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';

import { e1pDateUtil } from 'e1p-capability-hooks';
import { PolicyChangeService } from 'e1p-capability-policychange';
import { SubmissionService } from 'e1p-capability-gateway';
import { RewriteService } from 'e1p-capability-rewrite';
import { RenewalService } from 'e1p-capability-renewal';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useModal } from '@jutro/components';
import { get, isEmpty } from 'lodash';
import MonthlyPaymentSchedule from './Modal/MonthlyPaymentScheduleModal';
import metadata from './MonthlyPaymentScheduleComponent.metadata.json5';
import messages from './MonthlyPaymentScheduleComponent.messages';

const services = {
    PolicyChange: PolicyChangeService,
    Submission: SubmissionService,
    Rewrite: RewriteService,
    Renewal: RenewalService
};
const PAY_MONTHLY = 'af:payMthly3';

function MonthlyPaymentScheduleComponent({
    quoteID,
    authHeader,
    className,
    changeInCost,
    transactionTotalAmount,
    startDate,
    endDate,
    jobTypeCode,
    offerings,
    quoteOfferings
}) {
    const modalApi = useModal();
    const isPayMonthlySelected = () => {
        if (isEmpty(offerings)) {
            return false;
        }

        let selectedOffering = offerings[0];

        if (offerings?.length > 1) {
            const offeredQuotes = quoteOfferings;
            const selectedQuoteBranchCode = offeredQuotes?.find((quote) => quote.selected)?.branchCode;

            if (selectedQuoteBranchCode) {
                selectedOffering = offerings?.find((offering) => offering.branchCode === selectedQuoteBranchCode);
            }
        }

        const paymentPlan = selectedOffering?.paymentPlans?.find((plan) => plan.billingId === PAY_MONTHLY);

        return paymentPlan?.isSelected;
    };

    const showMonthlyPaymentScheduleLinkModal = async () => {
        try {
            if (!services[jobTypeCode]) {
                console.error('Transactionn Type is unknown.');

                return false;
            }

            const result = await services[jobTypeCode].getEstimatedPaymentPreview(
                [quoteID],
                authHeader
            );

            if (result.exceptions_Ext) {
                modalApi.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: messages.anErrorOccurredTitle,
                    message: get(result.exceptions_Ext, ['0', 'errorMessage'])
                });

                return false;
            }

            const modalResult = await modalApi.showModal(
                <MonthlyPaymentSchedule
                    data={result}
                    transactionTotalAmount={transactionTotalAmount}
                    changeInCost={changeInCost}
                    startDate={e1pDateUtil.getFormatedDate(startDate)}
                    endDate={e1pDateUtil.getFormatedDate(endDate)}
                    jobTypeCode={jobTypeCode}
                />
            );

            return modalResult;
        } catch (exception) {
            console.error(exception);
            modalApi.showAlert({
                status: 'error',
                icon: 'mi-error-outline',
                title: messages.anErrorOccurredTitle,
                message: messages.anErrorOccurred
            });

            return false;
        }
    };

    const overrideProps = {
        monthlyPaymentScheduleComponent: {
            onClick: showMonthlyPaymentScheduleLinkModal,
            className,
            visible: !!(isPayMonthlySelected())
        }
    };

    return <ViewModelForm uiProps={metadata.pageContent} overrideProps={overrideProps} />;
}

MonthlyPaymentScheduleComponent.propTypes = {
    className: PropTypes.string,
    quoteID: PropTypes.string,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }),
    transactionTotalAmount: PropTypes.shape({}),
    changeInCost: PropTypes.shape({}),
    startDate: PropTypes.shape({}),
    endDate: PropTypes.shape({}),
    jobTypeCode: PropTypes.string,
    offerings: PropTypes.arrayOf(PropTypes.shape({})),
    quoteOfferings: PropTypes.arrayOf(PropTypes.shape({}))
};

MonthlyPaymentScheduleComponent.defaultProps = {
    className: 'monthlyPaymentScheduleComponent',
    transactionTotalAmount: { currency: 'USD', amount: 0.0 },
    changeInCost: { currency: 'USD', amount: 0.0 },
    offerings: [],
    quoteOfferings: [],
    jobTypeCode: undefined,
    quoteID: undefined,
    startDate: undefined,
    endDate: undefined,
    authHeader: undefined
};

export default MonthlyPaymentScheduleComponent;
