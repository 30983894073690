import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useTranslator } from '@jutro/locale';

import { commonQuoteStyles } from 'gw-capability-quoteandbind-common-react';
import { MockUpUtil } from '@xengage/gw-portals-util-js';

import messages from './HoQuoteInfo.messages';

const PATH_TO_MOCK_DATA = 'quote.ho';

function HoQuoteInfo(props) {
    const { submissionVM } = props;
    const translator = useTranslator();
    const quoteId = _.get(submissionVM, 'quoteID.value');

    const address = _.get(submissionVM, 'baseData.policyAddress.value');
    const { displayName } = address;

    const isAddressDisplayNameMocked = MockUpUtil.isDisplayNameMocked(
        _.get(submissionVM, 'value'),
        PATH_TO_MOCK_DATA,
        'baseData.policyAddress.displayName',
        'baseData.policyAddress.addressLine1',
        'baseData.policyAddress.city'
    );
    const policyAddressToShowInHeader = isAddressDisplayNameMocked ? `${address.state} ${address.postalCode}` : displayName;

    return (
        <div className={commonQuoteStyles.quoteInfoBar}>
            <div className={commonQuoteStyles.infoBlock}>
                <div className={commonQuoteStyles.infoBlockTitle}>
                    {translator(messages.homeOwnersQuote)}
                </div>
                <div id="contextSubmissionId" className={commonQuoteStyles.infoBlockContent}>{quoteId}</div>
            </div>
            <div className={commonQuoteStyles.infoBlock}>
                <div className={commonQuoteStyles.infoBlockTitle}>
                    {translator(messages.location)}
                </div>
                <div id="contextLocation" className={commonQuoteStyles.infoBlockContent}>{policyAddressToShowInHeader}</div>
            </div>
        </div>
    );
}

HoQuoteInfo.propTypes = {
    submissionVM: PropTypes.shape({}).isRequired,
};

export default HoQuoteInfo;
