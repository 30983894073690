import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import {
    get as _get,
    set as _set,
    isEmpty as _isEmpty
} from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAddressStandardization, e1pUSStatesUtil } from 'e1p-capability-hooks';
import { AddressUtil } from 'e1p-portals-util-js';
import messages from './AddressComponent.messages';
import metadata from './AddressComponent.metadata.json5';

/**
 * @param {*} props wizard props
 * @returns {*} none
 * @todo This component needs address standardization built into it. It should be toggled through a
 *       prop. We need to replace everywhere we have entered each individual address field into a
 *       page or component.
 */
function AddressComponent(props) {
    // Define component constants
    const NUM_ROWS_WITH_COUNTRY = 3;
    const NUM_ROWS_WITHOUT_COUNTRY = 2;

    // Define component variables
    const {
        id,
        addressVM,
        labelPosition,
        onValidate,
        onAddressChange,
        showCountry,
        showOptional,
        viewOnlyMode,
        showParentLoader,
        showErrors,
        countryValues,
        isMailingOrBilling,
        updateIsAddressValid,
        shouldStandardizeOnBlur
    } = props;
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const { getStandardizedAddress } = useAddressStandardization(viewModelService, authHeader);
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const [isStandardizingAddress, setIsStandardizingAddress] = useState(false);
    const [isInternationalAddress, setIsInternationalAddress] = useState(false);
    const [isUSTerritory, setIsUSTerritory] = useState(false);
    const [USStates, setUSStates] = useState([]);
    const showLoader = useCallback((value) => {
        setIsStandardizingAddress(value);

        if (showParentLoader !== undefined) {
            showParentLoader(value);
        }
    }, [showParentLoader]);

    useEffect(() => {
        const stateValues = e1pUSStatesUtil.getUSStates(viewModelService);

        setUSStates(stateValues);

        const country = _get(addressVM, 'value.country', undefined);
        /**
         * EU has zip code and not postal code.
         * We use postalCode in the metadata.
         * If value exists on mount of a EU page, we need to set postal from zip.
         * In the future we should refactor to have both in the metadata
         *   and toggle visbility by _dtoName
         */
        const { _dtoName } = addressVM;

        if (_dtoName === 'amfam.edge.capabilities.policyjob.lob.eu.coverables.dto.EUAddressDTO') {
            const zipCode = _get(addressVM, 'value.zipCode', undefined);

            // Set the field that is used in the UI
            // Property doesnt actually exist on the dto so it cannot be set on value
            _set(addressVM, 'postalCode', zipCode);
        }

        // first if condition is to show the address state value when coming from the agentsPortal flow
        //   agents only is sending state but no country
        if (!country && !!(addressVM.state?.value?.code)) {
            setIsInternationalAddress(false);
            _set(addressVM, 'value.country', 'US');
            // EHTrusteeDTO does not have country field
        } else if (country && addressVM.country?.value?.code && addressVM.country?.value?.code !== 'US') {
            // country is provided and it is not the US
            setIsInternationalAddress(true);
            _set(addressVM, 'state', undefined);
            _set(addressVM, 'county', undefined);
        } else {
            setIsInternationalAddress(false);
            _set(addressVM, 'provinceTerritory', undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // If state is selected, check for if selected state is US territory
        setIsUSTerritory(addressVM?.value?.state && USStates.find(
            (element) => element.code === addressVM.value.state
        ) === undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [USStates, addressVM.state?.value]);


    /**
     * Helper callback for running address standardization when the user clicks off the address
     * fields when all address fields have been filled out.
     */
    const standardizeAddressIfNecessary = useCallback(async (_, { value, beforeValue, model }) => {
        // we don't want to standardize on blur(right now we have one scenario for TPI address where we are standardizing address on save button)
        if (!shouldStandardizeOnBlur) {
            return;
        }

        // if country is not United States do not standardize
        if (isInternationalAddress || isUSTerritory) {
            return;
        }
        // checking state since its typelist, so we need to check the stateCode,
        // added second condition, when user tab out without selecting state, this scenario will happen when user is creating new quote from dashboard

        // For AdditionalInterestDTO State is string field
        const beforeValueCode = _get(beforeValue, 'code', beforeValue);
        const valueCode = _get(value, 'code', value);

        if ((model === 'state' && beforeValue !== undefined && value !== undefined && beforeValueCode === valueCode)
            || (model === 'state' && value === undefined)
            || (model !== 'state' && value === beforeValue)) {
            return;
        }

        // need to check all the required fields, otherwise tab out will not work.
        const addressLine1 = _get(addressVM, 'addressLine1.value', _get(addressVM, 'address1.value'));
        const city = _get(addressVM, 'city.value');
        const state = _get(addressVM, 'state.value');
        const postalCode = _get(addressVM, 'postalCode.value');
        // For EU Address we have zipCode instead of postalCode
        const zipCode = _get(addressVM, 'zipCode.value');
        // eslint-disable-next-line prefer-regex-literals, security/detect-unsafe-regex
        const zipCodeValidation = new RegExp('^[0-9]{5}(-[0-9]{4})?$');

        if (!(addressLine1 && city && state && (zipCodeValidation.test(postalCode) || zipCodeValidation.test(zipCode)))) {
            return;
        }

        // check addressLine1 and addressLine2 for special characters
        const isaddressLine1Valid = AddressUtil.validateSpecialCharacters(addressLine1);
        const isaddressLine2Valid = AddressUtil.validateSpecialCharacters(_get(addressVM, 'addressLine2.value', _get(addressVM, 'address2.value')));

        if (!isaddressLine1Valid || !isaddressLine2Valid) {
            return;
        }

        showLoader(true);

        const standardizedAddress = await getStandardizedAddress(addressVM);

        if (standardizedAddress) {
            onAddressChange(standardizedAddress.value, 'value');
        }

        showLoader(false);
    }, [addressVM, getStandardizedAddress, shouldStandardizeOnBlur, isInternationalAddress, isUSTerritory, onAddressChange, showLoader]);

    /**
     * Helper effect for validating the data fields on this component.
     */
    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    useEffect(() => {
        if (updateIsAddressValid) {
            updateIsAddressValid(isComponentValid);
        }
    }, [updateIsAddressValid, isComponentValid]);

    const onCountryChange = (value, path) => {
        onAddressChange(value, path);

        if (addressVM.country.value.code !== 'US') {
            setIsInternationalAddress(true);
            _set(addressVM, 'state', undefined);
            _set(addressVM, 'county', undefined);
        } else {
            setIsInternationalAddress(false);
            _set(addressVM, 'provinceTerritory', undefined);
        }
    };

    const getCountryValues = () => {
        if (!_isEmpty(countryValues)) {
            return countryValues;
        }

        if (_get(addressVM, 'country.aspects.availableValues')) {
            return _get(addressVM, 'country.aspects.availableValues')?.map((code) => ({
                    code: code.code,
                    name: translator({ id: code.name })
                }));
        }

 return [];
    };

    const getStateAvailableValues = useCallback(() => _get(addressVM, 'state.aspects.availableValues').map((code) => ({
                code: code.code,
                name: translator({ id: code.name })
            })), [addressVM, translator]);

    const getStateValues = useCallback(() => {
        if (isMailingOrBilling) {
            return getStateAvailableValues();
        }

        if (!USStates || USStates.length === 0) {
            // view only mode changes : getting error if available values for  state are empty/undefined
            if (viewOnlyMode) {
                return getStateAvailableValues();
            }

            return undefined;
        }

        const states = e1pUSStatesUtil.getStateValues(USStates, translator);

        return states;
    }, [USStates, getStateAvailableValues, isMailingOrBilling, translator, viewOnlyMode]);

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isStandardizingAddress) {
            loadingMessage = translator(messages.standardizingAddressMessage);
        }

        return loadingMessage;
    }, [translator, isStandardizingAddress]);

    /**
     * Helper method to get the validation message to show on UI, 
     * if user inputs any unsupported special characters.
     */
    const getValidationMessageForSpecialCharacters = useCallback((valueToCheck, fieldName) => {
        const isValid = AddressUtil.validateSpecialCharacters(valueToCheck);

        if (!isValid) {
            return [translator(messages.allowedSpecialCharacters, { fieldName })];
        }

        return [];
    }, [translator]);

    /**
     * Define property overrides for this Jutro component.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            readOnly: viewOnlyMode,
            showRequired: true,
            showErrors,
            autoComplete: false
        },
        addressComponentLoadingIndicator: {
            loaded: !isStandardizingAddress,
            text: getLoadingIndicatorMessage
        },
        addressComponentContainer: {
            visible: !isStandardizingAddress
        },
        addressInfoContainer: {
            rows: new Array(showCountry ? NUM_ROWS_WITH_COUNTRY : NUM_ROWS_WITHOUT_COUNTRY).fill(
                '1fr'
            )
        },
        commonAddressLine1: {
            onBlur: standardizeAddressIfNecessary,
            // We have address1 instead of addressLine1 in AdditionalInterestDTO
            value: _get(addressVM, 'addressLine1.value', _get(addressVM, 'address1.value')),
            validationMessages: getValidationMessageForSpecialCharacters(_get(addressVM, 'addressLine1.value', _get(addressVM, 'address1.value')), 'Address Line 1'),
            showErrors: showErrors
                || !!_get(addressVM, 'addressLine1.value', _get(addressVM, 'address1.value'))
        },
        commonAddressLine2: {
            onBlur: standardizeAddressIfNecessary,
            // We have address2 instead of addressLine2 in AdditionalInterestDTO
            value: _get(addressVM, 'addressLine2.value', _get(addressVM, 'address2.value')),
            validationMessages: getValidationMessageForSpecialCharacters(_get(addressVM, 'addressLine2.value', _get(addressVM, 'address2.value')), 'Address Line 2'),
            showErrors: showErrors
                || !!_get(addressVM, 'addressLine2.value', _get(addressVM, 'address2.value'))
        },
        commonCity: {
            onBlur: standardizeAddressIfNecessary
        },
        commonPostalCode: {
            onBlur: standardizeAddressIfNecessary,
            label: isInternationalAddress || isUSTerritory ? messages.commonPostalCode : messages.commonZipCode,
            // /EHTrusteeDTO has zipCode instead of postal code
            value: _get(addressVM, 'postalCode.value', _get(addressVM, 'zipCode.value'))
        },
        commonState: {
            onBlur: standardizeAddressIfNecessary,
            visible: !isInternationalAddress,
            availableValues: getStateValues()
        },
        commonProvinceTerritory: {
            visible: isInternationalAddress
        },
        commonCountry: {
            visible: showCountry,
            onValueChange: onCountryChange,
            availableValues: getCountryValues()
        }
    };

    /**
     * Helper callback for reading values from the view model.
     */
    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.pageContent,
                addressVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [addressVM, overrideProps]
    );

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={addressVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={onAddressChange}
            resolveValue={readValue}
        />
    );
}

/**
 * Define expected property types to be passed into this Jutro component.
 */
AddressComponent.propTypes = {
    id: PropTypes.string,
    addressVM: PropTypes.shape({}).isRequired,
    labelPosition: PropTypes.string,
    onValidate: PropTypes.func,
    onAddressChange: PropTypes.func,
    showCountry: PropTypes.bool,
    showOptional: PropTypes.bool,
    viewOnlyMode: PropTypes.bool,
    showErrors: PropTypes.bool,
    showParentLoader: PropTypes.func,
    countryValues: PropTypes.arrayOf(PropTypes.shape({})),
    isMailingOrBilling: PropTypes.bool,
    updateIsAddressValid: PropTypes.func,
    shouldStandardizeOnBlur: PropTypes.bool
};

/**
 * Define default property values to be passed into this Jutro component.
 */
AddressComponent.defaultProps = {
    id: undefined,
    labelPosition: 'top',
    showCountry: false,
    showOptional: false,
    viewOnlyMode: false,
    showParentLoader: undefined,
    showErrors: false,
    countryValues: [],
    isMailingOrBilling: false,
    updateIsAddressValid: () => { },
    shouldStandardizeOnBlur: true,
    onValidate: undefined,
    onAddressChange: undefined
};

export default AddressComponent;
