import React, {
    useCallback, useContext, useMemo, useRef
} from 'react';
import {
    get, sortBy, uniqBy
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useModifierUtil } from 'e1p-capability-hooks';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import messages from '../CoveragePage.messages';
import metadata from '../CoveragePage.metadata.json5';

function structureClauseTableData(coverage) {
    // putting ID into an object as the Jutro table component expects an object
    return {
        publicID: coverage.publicID
    };
}

function generateClauseData(columnData, coveragePath, vehicleFixedID) {
    return columnData.map(({ lob, code }) => {
        let completeCoveragePath = `coverages.${coveragePath}`;

        if (vehicleFixedID) {
            const vehicleOfferings = lob.data.coverages.vehicleCoverages;
            const vehicleOfferingIndex = vehicleOfferings.findIndex(
                (vehicleCoverage) => vehicleCoverage.fixedId === vehicleFixedID
            );

            completeCoveragePath = `coverages.${coveragePath}.children[${vehicleOfferingIndex}].coverages`;
        }

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

const getCoveragesUniqueID = (rewriteVM) => {
    const offerings = get(
        rewriteVM,
        'lobData.personalAuto_EA.offerings.value'
    );
    const lineCoverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.lineCoverages.map(structureClauseTableData)
    )), 'publicID');
    const vehicleCoverages = uniqBy(offerings.flatMap((offering) => (
        offering.coverages.vehicleCoverages.flatMap(({ coverages }) => (
            coverages.map(structureClauseTableData)
        ))
    )), 'publicID');

    return {
        lineCoverages,
        vehicleCoverages
    };
};

const generateColumnData = (rewriteVM) => {
    const lobOfferingPath = 'lobData.personalAuto_EA.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = get(rewriteVM, `${lobOfferingPath}.value`);
    const quoteOfferings = get(rewriteVM, `${quoteOfferingPath}.value`) || [];

    let columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];

            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                }
            };
        });

    columnData = columnData.length > 1 ? [columnData.find((offering) => offering.code === 'MSA - Good')]
        : columnData;

    return sortBy(columnData, ['code']);
};
const generateTableData = (rewriteVM, columnData, translator) => {
    const vehicles = get(
        rewriteVM,
        'lobData.personalAuto_EA.coverables.vehicles.value'
    );
    const uniqueID = getCoveragesUniqueID(rewriteVM);

    const lineCoverages = {
        header: translator(messages.generalCoverages),
        data: uniqueID.lineCoverages,
        tableContent: generateClauseData(columnData, 'lineCoverages'),
        coverageType: 'lineCoverages'
    };

    const vehicleCoverages = vehicles.map((vehicle) => ({
        header: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
        data: uniqueID.vehicleCoverages,
        tableContent: generateClauseData(
            columnData,
            'vehicleCoverages',
            vehicle.fixedId
        ),
        coverageType: 'vehicleCoverages',
        fixedId: vehicle.fixedId
    }));

    return [lineCoverages, ...vehicleCoverages];
};

function ViewQuotePage(props) {
    const {
        wizardData: rewriteVM,
        viewOnly
    } = props;
    const translator = useTranslator();
    const { opCo } = useContext(AmfamOktaTokenContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader, authUserData } = useAuthentication();
    const isNewBusiness = get(rewriteVM, 'baseData.value.businessTransactionType_Ext') === 'NewBusiness';
   
    const viewModelService = useContext(ViewModelServiceContext);
    const isQuickQuote = useRef(
        get(rewriteVM.value, 'quoteType') === 'Quick'
    ).current;

    const {
        structuredModifiers
    } = useModifierUtil(rewriteVM);
    const modifiers = structuredModifiers;

    const isCompraterFlow = useMemo(() => {
        if (get(rewriteVM, 'baseData.quoteSource_Ext.sourceType')) {
            const isSourceTypeCompRater = get(rewriteVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'comprater';
            const quoteType = get(rewriteVM, 'quoteType_Ext.value.code');

            return (isSourceTypeCompRater && quoteType === 'Quick');
        }

        return false;
    }, [rewriteVM]);

    const buyNow = useCallback(
        async () => rewriteVM, [rewriteVM]
    );

    const accidentForgivenessApplied = () => {
        if (
            rewriteVM.lobData.personalAuto_EA.accidentForgivenessStatuses.children.length !== 0
            && rewriteVM.lobData.personalAuto_EA.accidentForgivenessStatuses.children[0].code.value === 'Earned Accident Forgiveness'
        ) {
            return true;
        }

        return false;
    };

    const generateOverrides = useCallback(() => {
        const columnData = generateColumnData(rewriteVM);
        const { jobID } = rewriteVM;

        const initialOverrides = {
            '@field': {
                // apply to all fields
                readOnly: viewOnly,
                labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
            },
            quotePageLoadingIndicator: {
                loaded: true
            },
            quotePageContainer: {
                visible: true
            },
            quoteProposalLinkContainer: {
                visible: isNewBusiness
            },
            quoteProposalLinkId: {
                disabled: true
            },
            accidentForgivenessMessageDiv: {
                visible: rewriteVM.value.quoteType === 'Full' && accidentForgivenessApplied()
            },
            quoteTable: {
                columnData,
                tableData: generateTableData(rewriteVM, columnData, translator),
                modifiers,
                quoteID: jobID.value,
                submissionVM: rewriteVM,
                viewModelService,
                updateWizardData: () => {},
                authHeader,
                isComprater: isCompraterFlow,
                viewOnlyMode: true,
                authUserData,
                opCo
            },
            paperlessEmailChangedMessageDiv: {
                visible: false
            },
            quoteProposalFailureErrorDiv: {
                visible: false
            },
        };

        return { ...initialOverrides};
        // prevent complaining props and showQuoteStartDate is missing in the dependencies list
        // watch props will cause refresh function ref multiple time,
        // showQuoteStartDate is generated from build time, so won't change in the runtime
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, rewriteVM, translator, opCo]);


    return (
        <WizardPage
            finish={isQuickQuote}
            shouldLink
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveCallbackMap: {
                        onBuyNow: () => buyNow().then(onNext),
                        onStaleQuoteBranchCode: () => { },
                        onRecalculate: () => { },
                        onResetQuote: () => { },
                        onChangeSubmissionAndSync: () => { },
                        onChangeSubmission: () => { },
                        onSyncCoverages: () => { },
                        onPrint: () => { },
                        onDisableNextForTable: () => { },
                        onCompOnlyChange: () => { }
                    }
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={rewriteVM}
                        overrideProps={generateOverrides()}
                        callbackMap={resolvers.resolveCallbackMap}
                        onValidationChange={() => {}}
                    />
                );
            }}
        </WizardPage>
    );
}

ViewQuotePage.propTypes = wizardProps;
export default ViewQuotePage;
