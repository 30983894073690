import { defineMessages } from 'react-intl';

export default defineMessages({
    product: {
        id: 'e1p.policies.dashboard.Product',
        defaultMessage: 'Product',
    },
    policyNumber: {
        id: 'e1p.policies.dashboard.Policy Number',
        defaultMessage: 'Policy Number',
    },
    quoteNumber: {
        id: 'e1p.policies.dashboard.Quote Number',
        defaultMessage: 'Quote Number',
    },
    accountName: {
        id: 'e1p.policies.dashboard.Account Name',
        defaultMessage: 'Account Name',
    },
    address: {
        id: 'e1p.gateway.directives.PolicySummary.Address',
        defaultMessage: 'Address',
    },
    premium: {
        id: 'e1p.policies.dashboard.Premium',
        defaultMessage: 'Premium',
    },
    effectiveDate: {
        id: 'e1p.policies.dashboard.Effective Date',
        defaultMessage: 'Effective Date',
    },
    expirationDate: {
        id: 'e1p.policies.dashboard.Expiration Date',
        defaultMessage: 'Expiration Date',
    },
    dateCreated: {
        id: 'e1p.policies.dashboard.Date Created',
        defaultMessage: 'Date Created',
    },
    status: {
        id: 'e1p.policies.dashboard.Status',
        defaultMessage: 'Status',
    },
    riskState: {
        id: 'e1p.gateway.directives.PolicySummary.Risk State',
        defaultMessage: 'Risk State',
    },
    locationCode: {
        id: 'e1p.gateway.directives.PolicySummary.Location Code',
        defaultMessage: 'Location Code',
    }
});
