import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    get,
    set,
    isEmpty,
    isUndefined
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import metadata from './SupportingProductPolicyComponent.metadata.json5';
import messages from './SupportingProductPolicyComponent.messages';

// Regular expression for Alphanumeric inputs
// eslint-disable-next-line prefer-regex-literals
const regex = new RegExp('^[a-zA-Z0-9]+$');

function SupportingProductPolicyComponent(props) {
    const {
        data: associatedPolicyVM,
        labelPosition,
        showOptional,
        path,
        id,
        onValidate,
        onValueChange,
        policyLineValues,
        viewOnlyMode,
        index,
        currentPolicyType,
        currentProductCode,
        jobType
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const translator = useTranslator();
    const [isMovingInPolicy, setIsMovingInPolicy] = useState(false);
    const isFixedIDAvailable = !!get(associatedPolicyVM, 'value.fixedId');

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [associatedPolicyVM, id, onValidate, isComponentValid]);

    /**
     * E1PAP1PC-13490, we need to set focus on the policy Type element of the supporting policy node
     * when ever the new supporting policy is added.
     * this useEffect will execute only once the component is rendered and based on condition
     * it will focus the policy type element on the UI.
     */
    useEffect(() => {
        const supportingPolicyContainerElement = document.getElementById(`supportingPolicyContainer${index}`);

        if (supportingPolicyContainerElement
            && isEmpty(get(associatedPolicyVM.value, 'policyLineType'))
        ) {
            const policyDropDownElement = supportingPolicyContainerElement.querySelector('input[id="supportingPolicyPolicyLine"]');

            if (policyDropDownElement && policyDropDownElement.focus !== undefined) {
                policyDropDownElement.focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const selectedPolicyLine = get(associatedPolicyVM, 'value.policyLineType');
        const conditionForMovingPolicy = selectedPolicyLine === 'EHLine_Ext'
        && currentProductCode === 'Homeowners_EH'
        && currentPolicyType === 'HO3'
        && isUndefined(get(associatedPolicyVM, 'value.policyLineName'));

        if (conditionForMovingPolicy) {
            set(associatedPolicyVM, 'value.policyStatus', 'In Force L90D');
            setIsMovingInPolicy(true);
        } else {
            setIsMovingInPolicy(false);
        }
    }, [associatedPolicyVM, currentPolicyType, currentProductCode]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;

        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, path]);

    // Only allow empty or alphanumeric values for policy number
    const onPolicyNumberChange = useCallback((value, changedPath) => {
        if (regex.test(value) || value === '') {
            handleValueChange(value, changedPath);
        }
    }, [handleValueChange]);

    const onPolicyStatusChange = useCallback((value, changedPath) => {
        handleValueChange(value, changedPath);
        // need to reset policyNumber every time policy status changed
        handleValueChange(undefined, 'policyNumber');
    }, [handleValueChange]);

    const onPolicyLineTypeChange = useCallback((value, changedPath) => {
        handleValueChange(value, changedPath);

        // for Homeowners(HO3), since we are combining associted policies and movingIn policies,
        // we need to make sure to reset fixedID and publicID
        // if policyLine is changed for already existing record, otherwise PC will through exception
        if (changedPath === 'policyLineType'
        && currentProductCode === 'Homeowners_EH'
        && currentPolicyType === 'HO3'
        && get(associatedPolicyVM, 'value.fixedId')) {
            handleValueChange(undefined, 'fixedId');
            handleValueChange(undefined, 'publicId');
            handleValueChange(undefined, 'policyLineName');
        }
    }, [associatedPolicyVM, currentPolicyType, currentProductCode, handleValueChange]);

    const isSystematicPolicy = get(associatedPolicyVM, 'policySource.value') === 'Systematic';

    const getAvailableValuesForPolicyType = useMemo(() => {
        let values = [];
        const policyLine = associatedPolicyVM.policyLineType?.value?.code;
        let homeValues = ['HO6', 'HO3', 'HO4', 'HF9'];

        switch (currentPolicyType) {
            case 'HO3':
                homeValues = ['HO6', 'HO4'];
                break;
            case 'HF9':
                homeValues = ['HO6', 'HO3', 'HO4'];
                break;
            default:
                homeValues = ['HO6', 'HO3', 'HO4', 'HF9'];
                break;
        }

        switch (policyLine) {
            case 'EHLine_Ext':
                values = get(associatedPolicyVM, 'policyType.aspects.availableValues').filter(
                    (policyTypeValue) => homeValues.includes(policyTypeValue.code)
                );
                break;
            case 'EAAutoLine_Ext':
                values = get(associatedPolicyVM, 'policyType.aspects.availableValues').filter(
                    (policyTypeValue) => policyTypeValue.code === 'EAFamilyCar'
                );
                break;
            case 'EULine_Ext':
                values = get(associatedPolicyVM, 'policyType.aspects.availableValues').filter(
                    (policyTypeValue) => policyTypeValue.code === 'Umbrella'
                );
                break;
            default:
                values = [];
        }

        const availableValues = values.map((code) => ({
                code: code.code,
                name: translator({ id: code.name })
            }));

        return availableValues;
    }, [associatedPolicyVM, currentPolicyType, translator]);

    const getAvailableValuesForPolicyStatus = useCallback(() => {
        let values = [];
        const selectedPolicyLine = get(associatedPolicyVM, 'value.policyLineType');

        switch (true) {
            case currentPolicyType === 'HO3' && selectedPolicyLine === 'EHLine_Ext':
                values = [
                    {
                        code: 'In Force L90D',
                        name: translator(messages.inForceLast90Days)
                    },
                    {
                        code: 'Cancelled',
                        name: translator(messages.cancelled)
                    }
                ];
                break;
            case currentPolicyType === 'HF9' && selectedPolicyLine === 'EHLine_Ext':
                values = [
                    {
                        code: 'In Force',
                        name: translator(messages.inForce)
                    }
                ];
                break;
            default:
                values = [
                    {
                        code: 'In Force',
                        name: translator(messages.inForce)
                    },
                    {
                        code: 'Promise',
                        name: translator(messages.promise)
                    }
                ];

                if(!isFixedIDAvailable && (jobType === 'PolicyChange' || jobType === 'Renewal')) {
                    values = [
                        {
                            code: 'In Force',
                            name: translator(messages.inForce)
                        }
                    ];
                }

                break;
        }

        return values;
    }, [associatedPolicyVM, currentPolicyType, isFixedIDAvailable, jobType, translator]);

    const isPolicyInForcedOrCancelled = ['In Force', 'Cancelled', 'In Force L90D'].includes(get(associatedPolicyVM, 'policyStatus.value'));

    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        if (isSystematicPolicy) {
            overrideProps.supportingPolicyPolicyLine = {
                required: !isSystematicPolicy,
                onValueChange: onPolicyLineTypeChange
            };
            overrideProps.supportingPolicyPolicyType = {
                required: !isSystematicPolicy
            };
            overrideProps.supportingPolicyPolicyStatus = {
                required: !isSystematicPolicy,
                availableValues: getAvailableValuesForPolicyStatus(),
                readOnly: isMovingInPolicy || isSystematicPolicy,
                onValueChange: onPolicyStatusChange
            };
        } else {
            overrideProps.supportingPolicyPolicyLine = {
                required: !isSystematicPolicy,
                availableValues: policyLineValues,
                onValueChange: onPolicyLineTypeChange
            };
            overrideProps.supportingPolicyPolicyType = {
                required: !isSystematicPolicy,
                availableValues: getAvailableValuesForPolicyType
            };
            overrideProps.supportingPolicyPolicyStatus = {
                required: !isSystematicPolicy,
                availableValues: getAvailableValuesForPolicyStatus(),
                readOnly: isMovingInPolicy || isFixedIDAvailable,
                onValueChange: onPolicyStatusChange
            };
        }

        return overrideProps;
    }, [
        getAvailableValuesForPolicyStatus,
        getAvailableValuesForPolicyType,
        isSystematicPolicy,
        onPolicyLineTypeChange,
        policyLineValues,
        isMovingInPolicy,
        isFixedIDAvailable,
        onPolicyStatusChange
    ]);


    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            readOnly: isSystematicPolicy
                || isFixedIDAvailable || viewOnlyMode,
            autoComplete: false
        },
        supportingPolicyPolicySource: {
            required: !isSystematicPolicy,
            readOnly: true
        },
        supportingPolicyPolicyNumber: {
            onValueChange: onPolicyNumberChange,
            required: isPolicyInForcedOrCancelled,
            visible: isPolicyInForcedOrCancelled || isSystematicPolicy
        },
        ...generateOverrides()
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={associatedPolicyVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
        />
    );
}

SupportingProductPolicyComponent.propTypes = {
    data: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    policyLineValues: PropTypes.arrayOf(PropTypes.shape({})),
    index: PropTypes.number.isRequired,
    currentPolicyType: PropTypes.string,
    currentProductCode: PropTypes.string.isRequired,
    jobType: PropTypes.string.isRequired
};
SupportingProductPolicyComponent.defaultProps = {
    data: {},
    labelPosition: 'top',
    path: undefined,
    showOptional: true,
    id: undefined,
    viewOnlyMode: false,
    policyLineValues: [],
    currentPolicyType: undefined
};
export default SupportingProductPolicyComponent;
