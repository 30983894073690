import { defineMessages } from 'react-intl';

export default defineMessages({
    applyChanges: {
        id: 'renewal.common.Apply Changes?',
        defaultMessage: 'Apply Changes?'
    },
    applyChangeMessage: {
        id: 'renewal.common.Changes will be applied to the renewal.',
        defaultMessage: 'Changes will be applied to the renewal.'
    },
    applyChangeConfirmationYes: {
        id: 'renewal.common.APPLY CHANGES',
        defaultMessage: 'APPLY CHANGES'
    },
    totalPremium: {
        id: 'renewal.common.PremiumBox.Total Premium',
        defaultMessage: 'Total Premium'
    },
    changeInPremium: {
        id: 'renewal.common.PremiumBox.Change in Premium',
        defaultMessage: 'Change in Premium'
    },
    exitMessage: {
        id: 'renewal.common.exitMessage',
        defaultMessage: 'Information entered on prior pages will be saved as a draft.\n\nChanges will be discarded if not applied before the renewal is automatically bound.'
    },
    renewalTermEffectiveDate: {
        id: 'renewal.common.Renewal Term Effective Date',
        defaultMessage: 'Renewal Term Effective Date'
    }
});
