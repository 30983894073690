import { defineMessages } from 'react-intl';

// id structure: <transaction/file>.<common/LOB>.<pageName/ComponentName>.<message>
export default defineMessages({
    applyChanges: {
        id: 'policyChange.common.policyDiffPage.Apply Changes',
        defaultMessage: 'Apply Changes'
    },
    applyChangesToRenewalTitle: {
        id: 'policyChange.common.policyChangeDiffPage.applyChangesToRenewalTitle',
        defaultMessage: 'Apply Changes to Renewal Term?'
    },
    applyChangesToRenewalDescription: {
        id: 'policyChange.common.policyChangeDiffPage.applyChangesToRenewalDescription',
        defaultMessage: 'A renewal term already exists for this policy. Changes will also be automatically applied to the renewal term.'
    },
    futureTermErrorMessage: {
        id: 'policyChange.common.ThankyouPage.futureTermErrorMessage',
        defaultMessage: 'An error occurred while automatically applying changes to the renewal term. Customer service has been notified and will apply your changes to the renewal term.'
    },
    totalPremium: {
        id: 'policyChange.common.PremiumBox.Total Premium',
        defaultMessage: 'Total Premium'
    },
    changeInPremium: {
        id: 'policyChange.common.PremiumBox.Change in Premium',
        defaultMessage: 'Change in Premium'
    },
    currentTotalPremium: {
        id: 'policyChange.common.PremiumBox.Current Total Premium',
        defaultMessage: 'Current Total Premium'
    },
    fullTermPremium: {
        id: 'policyChange.common.PremiumBox.Full-Term Premium',
        defaultMessage: 'Full-Term Premium'
    },
    fullTermChangePremium: {
        id: 'policyChange.common.PremiumBox.Full-Term Change in Premium',
        defaultMessage: 'Full-Term Change in Premium'
    },
    premiumAdjustment: {
        id: 'policyChange.common.Premium Adjustment',
        defaultMessage: 'Premium Adjustment'
    },
    withdrawPolicyChange: {
        id: 'policyChange.common.Withdraw Policy Change',
        defaultMessage: 'Withdraw Policy Change'
    },
    areYouSureToWithdrawPolicyChange: {
        id: 'policyChange.common.Are you sure you want to withdraw this policy change?',
        defaultMessage: 'Are you sure you want to withdraw this policy change?'
    },
    withdrawingPolicychange: {
        id: 'policyChange.common.Withdrawing Policy Change...',
        defaultMessage: 'Withdrawing Policy Change...'
    },

});
