import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '@jutro/layout';
import { CurrencyValue, useModal } from '@jutro/components';
import { uniqBy as _uniqBy, get as _get } from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import classNames from 'classnames';
import { useTranslator } from '@jutro/locale';
import { commonMessages as e1pCommonMessages } from 'e1p-platform-translations';
import {
    VehiclePackageCovRadioGroupComponent,
    PackageDifferenceComponent,
    E1PLoader
} from 'e1p-capability-policyjob-react';
import { FeatureUtil } from 'e1p-portals-util-js';
import htmlParser from 'html-react-parser';
import coveragesHelpText from '../QuoteClauseTable/coveragesHelpText.json5';
import EASingleClauseComponentVM from '../Clauses/SingleClauseComponentVM';
import messages from './EaSideBySideVehicleLevelComponent.messages';
import styles from './EaSideBySideVehicleLevelComponent.module.scss';

import { IconButton } from '@jutro/legacy/components';

import { Link } from '@jutro/router';

function getCoveragesHelpText(clause) {
    const coveragesHelpTextMessageDetails = _get(coveragesHelpText, clause.codeIdentifier, undefined);
    const coveragesHelpTextMessage = coveragesHelpTextMessageDetails ? htmlParser(coveragesHelpTextMessageDetails.toString()) : undefined;

    return ({
        message: coveragesHelpTextMessage,
        visible: !!coveragesHelpTextMessage
    });
}

function EaSideBySideVehicleLevelComponent(props) {
    const modalApi = useModal();
    const {
        lobOfferings,
        onChangeSubmissionAndSync,
        onChangeSubmission,
        onSyncCoverages,
        onValidate,
        hasExtendedAttributeEditability,
        policyState,
        vehicles,
        quoteDataOfferings,
        showErrors
    } = props;

    const translator = useTranslator();
    const [isLoading, setIsLoading] = useState(undefined);
    const [isEndorsementPackageChanging, setIsEndorsementPackageChanging] = useState(undefined);
    const id = 'EaSideBySideVehicleLevelComponent';
    const { isComponentValid, onValidate:setIsComponentValid } = useValidation(id);
    const isTravelPackageVisible =  FeatureUtil.isTravelPackageVisibleForState(policyState);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, lobOfferings, onValidate, isComponentValid]);

    /** Callback from main quote page */
    const syncCoverages = useCallback((value, path, lobPath, quotePath, clauseID) => {
        setIsLoading(clauseID);

        if (onSyncCoverages) {
            onSyncCoverages(value, path, lobPath, quotePath).then(() => {
                setIsLoading(undefined);
            });
        }
    }, [onSyncCoverages]);

    /** Callback from main quote page */
    const changeSubmissionAndSync = useCallback(async (value, path, lobPath, quotePath, clauseID) => {
        // "lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages[0].children[0].selected"
        // "lobData.personalAuto_EA.offerings.children[0].coverages.vehicleCoverages.children[0].coverages.children[0].selected"
        setIsLoading(clauseID);

        if (onChangeSubmissionAndSync) {
            return Promise.resolve(onChangeSubmissionAndSync(value, path, lobPath, quotePath).then(() => {
                setIsLoading(undefined);
            }));
        }
    }, [onChangeSubmissionAndSync]);

    const whatsIncludedHandler = useCallback(async () => {
        const componentProps = {
            lob: 'PersonalAuto_EA',
            policyState
        };
        const result = await modalApi.showModal(
            <PackageDifferenceComponent {...componentProps} />
        );

        return result;
    }, [policyState, modalApi]);

    /**
     * Will return unique set of vehicle coverage across the versions 
     * @returns {Array} list of objects - cov public ids
     */
    const getAllVehicleCoverage = useCallback((vehFixedId) => {
        const vehicleCoverages = _uniqBy(lobOfferings.flatMap((offering) => (
            offering.coverages.vehicleCoverages
                .filter((vehicleCoverage) => vehicleCoverage.fixedId === vehFixedId)
                .flatMap(({ coverages }) => (
                    coverages
                ))
                .filter((coverage) => !coverage.isHiddenInVersion)
        )), 'publicID');

        return vehicleCoverages;
    }, [lobOfferings]);

    const getTravelPackageHeaderContent = useCallback((headerClause) => (
            <GridItem
                className={styles.greyBackgroundHeader}
                tag="div" key={`vehicleLevelGridItem${0}`}>
                <span key={`vehicleLevelCell${0}`} className={styles.travelPackagesLabel}>
                    {headerClause.name}
                </span>
                <Link
                    to="/"
                    onClick={(e) => {
                        e.preventDefault();
                        whatsIncludedHandler();
                    }}
                    className={styles.travelPackagesLink}>
                    {translator(e1pCommonMessages.seeWhatsIncluded)}
                </Link>
            </GridItem>
        ), [translator, whatsIncludedHandler]);

    const getTravelPackageClauseContent = useCallback((className, lobPath, quotePath, vehicleIndex, offeringIndex) => (
            <GridItem
                className={className}
                tag="div" key={`travelGridItem${vehicleIndex}${offeringIndex}`}>
                <VehiclePackageCovRadioGroupComponent
                    data={lobOfferings[offeringIndex].coverages.vehicleCoverages[vehicleIndex].coverages}
                    index={vehicleIndex}
                    isLoading={false}
                    onPackageCovChange={
                        (value, termPath) => {
                            setIsEndorsementPackageChanging(true);

                            return changeSubmissionAndSync(value, termPath, lobPath, quotePath).then(() => {
                                setIsEndorsementPackageChanging(false);
                            })
                        }
                    }
                    viewOnly={false}
                    basePath={`lobData.personalAuto_EA.offerings.children[${offeringIndex}].coverages.vehicleCoverages.children[${vehicleIndex}].coverages`}
                />
            </GridItem>
        ), [changeSubmissionAndSync, lobOfferings]);

    const vehicleTables = [];

    vehicles.forEach((vehicle, vehicleIndex) => {

        const COLUMNS = lobOfferings.length + 1;
        const columnConfig = [];

        for (let column = 0; column < COLUMNS; column++) {
            let columnWidthToPush = '320px';

            if (column === 0) {
                columnWidthToPush = '210px';
            }

            columnConfig.push(columnWidthToPush);
        }

        // don't want to manipulate the actual lob data so making a copy
        const allUniqueVehicleCoverage = getAllVehicleCoverage(vehicle.fixedId);
        const travelPackageNodeArray = isTravelPackageVisible ? [{ name: 'Travel Packages', codeIdentifier: 'travelCell' }] : [ ];
        const coverageWithoutPackages = travelPackageNodeArray
            .concat(allUniqueVehicleCoverage)
            .concat({ name: 'Subtotal', codeIdentifier: 'subtotal' })
            // filter out gold and platinum
            .filter((coverage) => coverage.coverageCategoryCode !== 'EA_EndorsementPackage')

        // console.log(coverageWithoutPackages, '\n', travelPackages);
        const rowHeaders = [...coverageWithoutPackages];
        const ROWS = rowHeaders.length + 1;
        const numberOfCells = COLUMNS * ROWS;
        const tableContent = [];

        for (let cell = 0; cell <= numberOfCells - 1; cell++) {
            // There will always be 3 rows based on mock,
            //   but cols differ based on # of versions
            const rowNumber = Math.floor(cell / COLUMNS) + 1;
            const columnNumber = cell % COLUMNS;
            const OFFSET = 1; // array starts at 0 and first element is a header
            const offeringIndex = columnNumber - OFFSET;
            let vehicleIndexFromOfferings = vehicleIndex;

            if (offeringIndex > -1) {
                // IAP-2404 : order of vehicles under coverables is different from order of vehicles under vehicle coverages
                vehicleIndexFromOfferings = lobOfferings[offeringIndex].coverages.vehicleCoverages
                    .findIndex((vehicleCoverage) => vehicleCoverage.integrationID === vehicle.integrationId);
            }

            const path = `lobData.personalAuto_EA.offerings.children[${offeringIndex}].coverages.vehicleCoverages.children[${vehicleIndexFromOfferings}].coverages`;

            // first column, first row - vehicle display name
            if (cell === 0) {
                // Vehicle Name Cell
                tableContent.push
                (<GridItem
                    className={styles.greyBackgroundHeader}
                    tag="div" key={`vehicleNameGridItem${cell}`}>
                    <span key={`vehicleNameCell${cell}`}>
                        {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                    </span>
                </GridItem>)
            }
            // first column - render row headers
            else if ((cell % COLUMNS === 0)) {
                const headerClause = rowHeaders.shift();
                const toolTipDetails = getCoveragesHelpText(headerClause);

                if (headerClause.codeIdentifier === 'travelCell') {
                    // render travel stuff
                    tableContent.push(
                        getTravelPackageHeaderContent(headerClause)
                    )
                } else if (headerClause.codeIdentifier === 'subtotal') {
                    tableContent.push
                    (<GridItem
                        className={styles.subTotalGridItem}
                        tag="div" key={`vehicleLevelGridItem${cell}`}>
                        <span key={`vehicleLevelCell${cell}`}>
                            {headerClause.name}
                        </span>
                    </GridItem>)
                } else {
                    const isBottomLeftHeader = rowNumber === ROWS - 1;
                    const gridItemStyle = classNames(styles.displayInlineGrid, {[styles.qDetailCellBottomLeftHeader] : isBottomLeftHeader, [styles.qDetailCellLeftHeader] : !isBottomLeftHeader});

                    tableContent.push
                    (<GridItem
                        className={gridItemStyle}
                        tag="div" key={`vehicleLevelGridItem${cell}`}>
                        <span key={`vehicleLevelCell${cell}`}>
                            {headerClause.name}
                        </span>
                        {toolTipDetails.visible ? (<IconButton
                            id={`tooltip${cell}`}
                            icon="mi-help-outline"
                            size="medium"
                            onClick={(evt) => {
                                evt.stopPropagation();
                            }}
                            tooltip={{ text: toolTipDetails.message }}
                            className={styles.coveragesHelpTextIconButton}
                            iconClassName={styles.coveragesHelpTextIcon}
                        />) : undefined}
                    </GridItem>)
                }
            } else if (rowNumber === 1) { // first row - quote version name
                tableContent.push(
                    <span key={`quotename${cell}`} className={styles.qDetailCellVersionName}>
                        {lobOfferings[cell - 1].branchName}
                    </span>
                );
            } else {
                const currentQuoteOffering = quoteDataOfferings
                    .find((quoteOffering) => quoteOffering.branchCode === lobOfferings[offeringIndex].branchCode);
                const isCurrentOfferingNotQuoted = currentQuoteOffering?.status !== 'Quoted';

                const covHeader = coverageWithoutPackages[rowNumber - 2];

                if (covHeader.codeIdentifier === 'travelCell') {
                    const className = rowNumber === ROWS - 1 ? styles.qDetailCellBottom : styles.qDetailCell;

                    tableContent.push
                    (
                        getTravelPackageClauseContent(
                            className, `lobData.personalAuto_EA.offerings.children[${offeringIndex}]`,
                            `quoteData.offeredQuotes.children[${offeringIndex}]`,
                            vehicleIndexFromOfferings,
                            offeringIndex
                        )
                    )
                } else if (covHeader.codeIdentifier === 'subtotal') {
                    tableContent.push(
                        isCurrentOfferingNotQuoted ?
                            <GridItem key={`quotename${cell}`} className={styles.subTotalGridItemAmount} />
                            :
                            <GridItem key={`quotename${cell}`} className={styles.subTotalGridItemAmount}>
                                <CurrencyValue
                                    id={`version${offeringIndex}vehicle${vehicleIndexFromOfferings}premium`}
                                    showFractions
                                    value={{
                                        amount: lobOfferings[offeringIndex].coverages.vehicleCoverages[vehicleIndexFromOfferings].premiumSummary.find(
                                            (summary) => summary.premiumSummaryType === 'EAPerVehicleCoverageSubtotal'
                                        )?.amount,
                                        currency: 'USD'
                                    }}
                                />
                            </GridItem>
                    );
                } else {
                    const clause = lobOfferings[offeringIndex].coverages.vehicleCoverages[vehicleIndexFromOfferings].coverages.find(
                        (coverage) => coverage.publicID === covHeader.publicID
                    );
                    const clauseIndex = lobOfferings[offeringIndex].coverages.vehicleCoverages[vehicleIndexFromOfferings].coverages.findIndex(
                        (coverage) => coverage.publicID === covHeader.publicID
                    );

                    if (clause) {
                        tableContent.push
                        (<GridItem
                            tag="div" key={`quotecell${cell}`}
                            className={rowNumber === ROWS - 1 ? styles.qDetailCellBottom : styles.qDetailCell}>
                            <EASingleClauseComponentVM
                                value={clause}
                                path={`${path}.children[${clauseIndex}]`}
                                onChangeClause={onChangeSubmission}
                                onSyncCoverages={(value, termPath) => {
                                    syncCoverages(
                                        value, termPath, `lobData.personalAuto_EA.offerings.children[${offeringIndex}]`,
                                        `quoteData.offeredQuotes.children[${offeringIndex}]`, clause.publicID
                                    )
                                }}
                                onChangeSubmissionAndSync={(value, termPath) => {
                                    changeSubmissionAndSync(
                                        value, termPath, `lobData.personalAuto_EA.offerings.children[${offeringIndex}]`,
                                        `quoteData.offeredQuotes.children[${offeringIndex}]`, clause.publicID
                                    )
                                }}
                                loadingClause={isLoading}
                                idPrefex={clause.publicID}
                                labelTop
                                onValidate={setIsComponentValid}
                                isQuoteStale={isCurrentOfferingNotQuoted}
                                hasExtendedAttributeEditability={hasExtendedAttributeEditability}
                                policyState={policyState}
                                showErrors={showErrors}
                            />
                        </GridItem>)
                    }
                    else {
                        tableContent.push(
                            <GridItem
                                tag="div" key={`quotecell${cell}`}
                                className={rowNumber === ROWS - 1 ? styles.qDetailCellBottom : styles.qDetailCell}>
                                <span className={styles.noCoverageText}>{translator(messages.notApplicable)}</span>
                            </GridItem>
                        )
                    }
                }
            }
        }

        vehicleTables.push(
            <Grid key={`vehicleLevelCoveragesGrid${vehicleIndex}`} className={styles.tableSpacingAbove} columns={columnConfig} gap="none">
                {tableContent}
            </Grid>
        )
    });

    // vehicles.forEach((vehicle, vehicleIndex) => {

    // });
    return (
        <div className={styles.tableSpacingAbove}>
            <h4>{translator(messages.vehicleLevelCoverages)}</h4>
            {!isEndorsementPackageChanging ? (vehicleTables) : (
                <E1PLoader
                    loaded={!isEndorsementPackageChanging}
                    text={translator(messages.updatingSideBySideGeneric)} />)}
        </div>
    );
}

EaSideBySideVehicleLevelComponent.propTypes = {
    lobOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    quoteDataOfferings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    policyState: PropTypes.string.isRequired,
    hasExtendedAttributeEditability: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
    vehicles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    showErrors: PropTypes.bool
};

EaSideBySideVehicleLevelComponent.defaultProps = {
    showErrors:false
}

export default EaSideBySideVehicleLevelComponent;
