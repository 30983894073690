import React, { useCallback, useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { RewriteService } from 'e1p-capability-rewrite';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { commonMessages as e1pCommonMessages  } from 'e1p-platform-translations';
import metadata from './EUPreQualificationPage.metadata.json5';

function EUPreQualificationPage(props) {
    const {
        wizardData: rewriteVM,
        updateWizardData,
        isSkipping,
        updateWizardSnapshot
    } = props;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [isSavingCurrentPageChanges, setIsSavingCurrentPageChanges] = useState(false);
    const { initialValidation, isComponentValid, onValidate } = useValidation(
        'EUPreQualificationPage'
    );
    const [isSavingEndorsement, setIsSavingEndorsement] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);

    useEffect(() => {
        // Take the show errors off once page is fixed
        if (isComponentValid && isPageSubmitted) {
            updateIsPageSubmitted(false);
        }
    }, [rewriteVM, isComponentValid, isPageSubmitted]);

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        setIsSavingEndorsement(true);
        rewriteVM.value = await RewriteService.updateDraftRewrite(
            [rewriteVM.value],
            authHeader
        );
        setIsSavingEndorsement(false);

        return rewriteVM;
    }, [authHeader, isComponentValid, rewriteVM]);

    const onSave = useCallback(
        async () => {
            setIsSavingCurrentPageChanges(true);

            try {
                await onNext();

                const fieldIssues = get(rewriteVM, 'value.errorsAndWarnings.validationIssues.fieldIssues', []);
                const exceptions = get(rewriteVM, 'baseData.exceptions_Ext.value', []);

                if (isEmpty(fieldIssues) && isEmpty(exceptions)) {
                    updateWizardSnapshot(rewriteVM);
                }

                setIsSavingCurrentPageChanges(false);
            } catch {
                setIsSavingCurrentPageChanges(false);
            }
        }, [onNext, rewriteVM, updateWizardSnapshot]
    );

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        preQualificationPageLoadingIndicator: {
            loaded: !isSavingEndorsement && !isSkipping
                && !isSavingCurrentPageChanges,
            text: isSavingCurrentPageChanges
                ? translator(e1pCommonMessages.savingCurrentPageChanges)
                : translator(e1pCommonMessages.loadingNextPage)
        },
        preQualificationPageContainer: {
            visible: !isSavingEndorsement && !isSkipping
                && !isSavingCurrentPageChanges
        },
        euPreQualificationComponent: {
            data: rewriteVM,
            updateWizardData,
            showErrors: isPageSubmitted
        }
    };

    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate
        }
    };

    /**
     * Define rendering behaviors for this Jutro component.
     */
    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            onSave={onSave}
            showOnSave
            isPageSubmittedWithErrors={isPageSubmitted && !isComponentValid}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={rewriteVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

EUPreQualificationPage.propTypes = wizardProps;
export default withRouter(EUPreQualificationPage);
