import {
    useCallback, useContext
} from 'react';

import {
    get as _get,
    set as _set,
    stubFalse as _stubFalse,
    uniqWith as _uniqWith
} from 'lodash';
import moment from 'moment';
import { e1pContactUtil, useSniUtil } from 'e1p-capability-hooks';
import { eaCommonMessages } from 'e1p-platform-translations';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';

const LOB = 'personalAuto_EA';

/**
 * E1PAP1PC-12823:
 * @param {Object} wizardData
 * @param {Function} updateWizardData useState to update wizardData
 * @param {Function} viewModelService
 * @param {Function} setValidationErrors useState to update errors
 * @param {Function} translator translator
 * @returns {*}
 */
function useDriverPageUtil(
    wizardData, updateWizardData, viewModelService,
    setValidationErrors, translator
) {
    const { opCo } = useContext(AmfamOktaTokenContext);
    const { createSecondaryNamedInsuredVM } = useSniUtil(
        wizardData,
        updateWizardData,
        viewModelService,
        LOB,
        () => { }
    );

    // If more than one sni is present on page show validation error
    const checkMoreThanOneSNIRoles = useCallback(
        () => {
            const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
            const drivers = _get(wizardData, driversPath, []);
            const driversWithSNIContactRole = drivers.filter((driver) => driver.role === 'sni');

            if (driversWithSNIContactRole.length > 1) {
                const errors = [{
                    level: 'LEVEL_ERROR',
                    description: translator(eaCommonMessages.invalidNoOfSNIs)
                }];

                setValidationErrors(errors);
                window.scrollTo(0, 0);

                return true;
            }

            return false;
        }, [setValidationErrors, translator, wizardData]
    );

    /**
     * If Spouse is added as driver then we will add spouse as SNI to the policy
     */
    const setSpouseAsSNI = useCallback(
        async (hasNewSNIAdded) => {
            try {
                const sniPath = 'lobData.personalAuto_EA.secondaryNamedInsured.value';
                let sni = _get(wizardData, sniPath);
                const sniRoleOnDriverPage = wizardData.lobData.personalAuto_EA
                    .coverables.drivers.value.find((driver) => driver.role === 'sni');

                if (!sniRoleOnDriverPage) {
                    // There is no sni role on drivers so returning(not setting sni value)
                    return true;
                }

                if (sni && sniRoleOnDriverPage) {
                    // We did not add new sni on driver page so returning(not setting sni value)
                    if (e1pContactUtil.samePerson(sni.person, sniRoleOnDriverPage.person)) {
                        return true;
                    }
                }

                createSecondaryNamedInsuredVM();
                sni = _get(wizardData, sniPath);

                _set(sni, 'person', sniRoleOnDriverPage.person);
                _set(sni, 'relationshipToNI', sniRoleOnDriverPage.relationshipToNI);
                // Remove Integration ID
                _set(sni, 'integrationId', undefined);

                if (hasNewSNIAdded) {
                    hasNewSNIAdded.current = true;
                }

                updateWizardData(wizardData);

                return true;
            } catch {
                // user clicks outside popup, (not setting sni value)
                return _stubFalse();
            }
        }, [createSecondaryNamedInsuredVM, updateWizardData, wizardData]
    );

    /**
     * Updates relationship status for sni
     */
    const setRelationShipStatusForSNI = useCallback(
        async () => {
            const sniPath = 'lobData.personalAuto_EA.secondaryNamedInsured.value';
            const sni = _get(wizardData, sniPath, {});
            const sniRoleOnDriverPage = wizardData.lobData.personalAuto_EA
                .coverables.drivers.value.find(
                    (driver) => e1pContactUtil.samePerson(sni.person, driver.person)
                );

            if (sniRoleOnDriverPage) {
                _set(sni, 'relationshipToNI', sniRoleOnDriverPage.relationshipToNI);
                updateWizardData(wizardData);
            }
        }, [updateWizardData, wizardData]
    );

    const isPNIAndSNIValidForDefensiveDiscount = useCallback(() => {
        const validOperators = _get(wizardData, 'lobData.personalAuto_EA.coverables.drivers.children', [])
            .filter((driver) => {
                const isSNI = _get(driver, 'value.role') === 'sni';
                const isPNI = _get(driver, 'value.role') === 'pni';
                const state = _get(wizardData, 'baseData.policyAddress.state.value.code');

                /**
            * IAP -123 :
            * Defensive Driver discount warning message for "PA" should display only for PNI
            * and SNI.
            */
                if (state === 'PA' && (isSNI || isPNI)) {
                    return true;
                }

                return false;
            });

        if (validOperators.length >= 2) {
            return true;
        }

        return false;
    }, [wizardData]);

    /**
     * Financial Responsibility Form Avaibility
     * @param {*} state
     * @param {*} driverRoleType
     * @param {*} isNewSubmission 
     * @returns {Object} returns indicators of form visibility and editable
     */
    const isFinancialResponsibilityFormAvailableForDriver = (state, driverRoleType,isNewSubmission = false) => {
        let isFinancialResponsibilityFormVisible = false;
        let isFinancialResponsibilityFormEditable = false;

        // availability based on state
        switch (state) {
            case 'NH':
                if(driverRoleType === 'operator'){
                    isFinancialResponsibilityFormVisible = true;
                }
                
                break;
            case 'VA':
                if (!isNewSubmission && driverRoleType === 'operator') {
                    isFinancialResponsibilityFormVisible = true;
                    isFinancialResponsibilityFormEditable = true;
                }
 
                break;
            default:
                isFinancialResponsibilityFormVisible = false;
                isFinancialResponsibilityFormEditable = false;
        }

        return {isFinancialResponsibilityFormVisible, isFinancialResponsibilityFormEditable}
    }

    /**
     * IAP-1318; IAP-123
     * Decides defensive driver discount field visibility
     * @param {Number} driverAge 
     * @param {String} state 
     * @param {Boolean} isPNI 
     * @param {Boolean} isSNI
     * @param {String} driverRoleType
     * @param {Boolean} isActiveNationalGuard
     * @returns {Object} returns indicators of discount availability and tooltip visibility
     */
    const isDefensiveDriverDiscountAvailableForDriver = (driverAge, state, isPNI, isSNI, driverRoleType, isActiveNationalGuard = false) => {
        let isDefensiveDriverDiscountAvailable = false;
        let isDefensiveDriverDiscountToolTipVisible = false;

        if (driverRoleType !== 'operator') {
            return { isDefensiveDriverDiscountAvailable, isDefensiveDriverDiscountToolTipVisible };
        }

        // availability based on state
        switch(state) {
            case 'PA':
                // driver age should be >= 55 and should be PNI or SNI
                if (driverAge >= 55 && (isSNI || isPNI)) {
                    isDefensiveDriverDiscountAvailable = true;
                    isDefensiveDriverDiscountToolTipVisible = true;
                }

                break;
            case 'TN':
            case 'IL':
            case 'VA':
            case 'UT':
            case 'RI':
            case 'ME':
                // driver age should be >= 55
                if (driverAge >= 55) {
                    isDefensiveDriverDiscountAvailable = true;
                }

                break;
            case 'CT':
            // driver age should be >= 60
                if (driverAge >= 60) {
                    isDefensiveDriverDiscountAvailable = true;
                }

                break;
            case 'IN':
            case 'NH':
            case 'NC':   
            case 'WI': 
                // defensive drive option not visible
                isDefensiveDriverDiscountAvailable = false;
                break;
            case 'MT':
                if (driverAge < 55 && isActiveNationalGuard) {
                    isDefensiveDriverDiscountAvailable = true;
                } else if (driverAge >= 55) {
                    isDefensiveDriverDiscountAvailable = true;
                }

                break;
            default:
                // defensive drive option is always visible
                isDefensiveDriverDiscountAvailable = true;
                break;
        }

        return { isDefensiveDriverDiscountAvailable, isDefensiveDriverDiscountToolTipVisible };
    }

    /**
     * IAP-2631
     * Make the DLN required during verified flow. 
     * @param {object} transactionVM 
     * @param {Array} driver 
     * @returns {Boolean}
     */
    const isLicenseNumberRequired = (transactionVM,driver) => {
        const nonOperator = driver.policyDriverRoleType.value?.code === 'nonoperator'
                    || driver.policyDriverRoleType.value?.code === 'excludedoperator';
        const licenseNeededForMSA = !(nonOperator && (driver.nonOperatorDescType.value?.code === 'NBL' || driver.nonOperatorDescType.value?.code === 'NCL'));
        // License is not nededed if the Role type is 'nonoperator' 
        // and it's description is 'Never licensed' and  'Not currently licensed by choice'          
        const licenseIsNeeded = opCo === 'CONNECT' ? !nonOperator : licenseNeededForMSA
        // check the quoteType is Full quote, if the quoteType is available, else return true. 
        // quoteType is available for NewBusiness only. 
        const isQuoteTypeFull = _get(transactionVM, 'value.quoteType') ? _get(transactionVM, 'value.quoteType') === 'Full' : true;
            
        return licenseIsNeeded && isQuoteTypeFull
    }

    /**
     * IAP-2300
     * Returns driver's age.
     * @param {object} driver
     * @returns {integer}
     */
    const getDriverAge = (driver) => {
        const TODAY = moment();
        const BIRTH_DATE = moment(driver.person.dateOfBirth.value);
        const MONTH_DIFF = TODAY.month() - BIRTH_DATE.month();
           
        let driverAge = TODAY.year() - BIRTH_DATE.year();

        if ((MONTH_DIFF < 0) || ((MONTH_DIFF === 0) && (TODAY.date() < BIRTH_DATE.date()))) {
            driverAge += -1;
        }

        return driverAge;
    };

    /**
     *IAP-4716 Check duplicate drivers on the policy
     */
    const checkDuplicateDrivers = useCallback(
        () => {
            const driversPath = 'lobData.personalAuto_EA.coverables.drivers.value';
            const drivers = _get(wizardData, driversPath, []);

            // check for duplicate driver
            const uniqueDrivers = _uniqWith(drivers, (driver1, driver2) => {
                return e1pContactUtil.samePerson(driver1.person, driver2.person);
            });

            if (drivers.length !== uniqueDrivers.length) {
                const errors = [{
                    level: 'LEVEL_ERROR',
                    description: translator(eaCommonMessages.duplicateDriversFound)
                }];

                setValidationErrors(errors);
                window.scrollTo(0, 0);

                return true;
            }

            return false;
        }, [setValidationErrors, translator, wizardData]
    );

    return {
        checkMoreThanOneSNIRoles,
        setSpouseAsSNI,
        setRelationShipStatusForSNI,
        isPNIAndSNIValidForDefensiveDiscount,
        isDefensiveDriverDiscountAvailableForDriver,
        isFinancialResponsibilityFormAvailableForDriver,
        isLicenseNumberRequired,
        getDriverAge,
        checkDuplicateDrivers
    };
}

export default useDriverPageUtil;
