
import React, { useCallback } from 'react';
import {
    get as _get,
    findIndex as _findIndex,
    sortBy as _sortBy
} from 'lodash';
import { UserService, PolicyDetailsService } from 'e1p-capability-gateway';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useHistory } from 'react-router-dom';


function useViewPolicyUtil() {
    // Lands on policy summary page
    const history = useHistory();
    const landOnPolicySummary = useCallback((wizardData) => {
        const policyNumber = _get(wizardData, 'value.policyNumber', '');
        let redirectPath = `/policies/${policyNumber}/summary`;
        const quoteID =  _get(wizardData, 'value.quoteID', '');
        if(!policyNumber && _get(wizardData, 'value.quoteID', '')){
            redirectPath = `/quotes/${quoteID}/summary`;
        }
        history.push(redirectPath);
    }, [history]);

    const getLandingPageIndexForViewOnlyPolicy = (LOB, steps, transaction) => {
        let landingPageIndex = -1;
        let landingPage = '/view-coverage';
        switch (transaction) {
            case 'Submission':
            case 'Renewal':
                landingPage = '/view-coverages';
                break;
            case 'PolicyChange':
                landingPage = '/view-change-review';
                break;
            default:
                return false;
        }
        if (landingPage) {
            landingPageIndex = _findIndex(
                steps, (step) => step.path === landingPage
            );
        }
        return landingPageIndex;
    };

    const getLocationAndProducerCodes = useCallback(
        async (baseState, authHeader, setLocationAndProducerCodes) => {
            let producerCodes = [];
            let locationCodes = [];
            const producerCodeResponse = await UserService.getAvailableProducerCodesBasedOnProducerIdAndState(
                baseState,
                authHeader
            );
            producerCodes = _sortBy(producerCodeResponse, 'code').map((value) => {
                return {
                    code: value.code,
                    name: value.code,
                    displayName: value.displayValue,
                    externalId_Ext: value.externalId_Ext
                };
            });
            locationCodes = _sortBy(producerCodeResponse, 'code').map((value) => {
                return {
                    code: value.externalId_Ext,
                    name: value.externalId_Ext,
                    displayName: value.displayValue,
                    externalId_Ext: value.externalId_Ext
                };
            });
            setLocationAndProducerCodes(
                {
                    producerCodes,
                    locationCodes
                }
            );
            return {
                producerCodes,
                locationCodes
            };
        },
        []
    );

    // get producer address and phone informations
    const getProducerDetails = async (externalId, authHeader) => {
        if (!externalId) return null;
        return (
            PolicyDetailsService.getProducerDetails(
                externalId,
                authHeader
            ).then((policyDetails) => {
                const address = _get(policyDetails, 'contactDetails.addresses', []).find(
                    (add) => add.primaryAddressIndicator
                );
                const phone = _get(policyDetails, 'contactDetails.phones', []).find(
                    (p) => p.phoneNumber
                ).phoneNumber;

                const serviceCenterIndicatorValue = _get(policyDetails, 'disposition', []).find(
                    (disposition) => disposition.key === 'SERVICE_CENTER_INDICATOR'
                )?.value === 'Y';

                return {
                    address,
                    phone,
                    serviceCenterIndicatorValue,
                    agencyName: policyDetails.name,
                    bookTransferIndicator: policyDetails.bookRollIndicator
                };
            }).catch((error) => {
                const errorMessage = error.baseError ? error.baseError : error.message;
                throw new Error(errorMessage);
            })
        );
    };

    return {
        landOnPolicySummary,
        getLandingPageIndexForViewOnlyPolicy,
        getLocationAndProducerCodes,
        getProducerDetails
    };
}

export default useViewPolicyUtil;
