
import React, { useCallback, useMemo } from 'react';
import {
    get as _get
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useLandingPageUtil } from 'e1p-capability-hooks';
import metadata from '../RiskAnalysisPage.metadata.json5';
import messages from '../RiskAnalysisPage.messages';

const LOB = 'personalUmbrella_EU';

function ViewRiskAnalysisPage(props) {
    // eslint-disable-max-len
    const { authHeader } = useAuthentication();
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        isSkipping,
        authUserData
    } = props;
    const policyChangeSource = _get(policyChangeVM, 'value.policyChangeSource', '');
    const policyChangeReason = _get(policyChangeVM, 'value.policyChangeReason', '');
    const translator = useTranslator();
   
    const canViewPremiumAdjustment = authUserData.permissions_Ext.includes('viewpremiumadjustment_ext');
    // Display the tab only for user with Viewpremiumstabilizationdetail permissions and premium stabilization information is not empty
    const isPremiumStabilizationTabVisible = authUserData.permissions_Ext.includes('viewpremiumstabilizationdetail_ext')
        && authUserData.permissions_Ext.includes('viewpremiumstabilizationtab_ext')
        && _get(policyChangeVM, 'lobData.personalUmbrella_EU.premiumStability.value') !== undefined;

    const {
        onValidate
    } = useValidation('ViewRiskAnalysisPage');

    const {
        getDefaultTab
    } = useLandingPageUtil();

    const defaultTabBasedOnReason = useMemo(() =>
        getDefaultTab(LOB, policyChangeSource, policyChangeReason),
        [getDefaultTab, policyChangeSource, policyChangeReason]);

    const saveAndQuote = useCallback(() => policyChangeVM, [policyChangeVM]);

    const getDefaultActiveTab = useMemo(() =>
        defaultTabBasedOnReason === false ? 'lossesAndViolationTab' : defaultTabBasedOnReason,
        [defaultTabBasedOnReason]);


    const resolvers = {
        resolveCallbackMap: {
            onValidate,
        },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top'
        },
        riskAnalysisPropertyPageLoadingIndicator: {
            loaded: true,
            text: translator(messages.doingRateMessage)
        },
        riskAnalysisTabs: {
            visible: true,
            defaultActiveTab: getDefaultActiveTab
        },
        lossesAndViolationComponent: {
            submissionVM: policyChangeVM,
            isSkipping,
            authUserData,
            isVerified: true,
            updateWizardData,
            disregardFieldValidation: ()=>{},
            viewOnlyMode: true
        },
        UWIssuesComponentId: {
            submissionVM: policyChangeVM,
            updateWizardData,
            authHeader,
            viewOnlyMode: true
        },
        premiumTab: {
            visible: (isPremiumStabilizationTabVisible)
                || (canViewPremiumAdjustment
                    && _get(policyChangeVM, 'isPremiumAdjustmentTransactionAllowed_Ext.value'))
        },
        riskAnalysisPremiumComponent: {
            transactionVM: policyChangeVM,
            updateWizardData,
            onValidate,
            showErrors: false,
            viewOnlyMode: true
        },
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(
                metadata.pageContent,
                policyChangeVM,
                id,
                path,
                overrideProps
            ),
        [overrideProps, policyChangeVM]
    );

    return (
        <WizardPage
            onNext={saveAndQuote}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                resolveValue={readValue}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ViewRiskAnalysisPage.propTypes = wizardProps;
export default withAuthenticationContext(ViewRiskAnalysisPage);
