import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { ToggleField, useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { commonMessages } from 'e1p-platform-translations';
import PropTypes from 'prop-types';
import messages from './E1PDeliverDocumentsIndComponent.messages';

function E1PDeliverDocumentsIndComponent(props) {
    const modalApi = useModal();
    const {
        deliverDocInd,
        setDeliverDocInd,
        viewOnlyMode
    } = props;

    const translator = useTranslator();

    useEffect(() => {
        if (deliverDocInd === undefined) {
            if (setDeliverDocInd) {
                setDeliverDocInd(true);
            }
        }
    }, [deliverDocInd, setDeliverDocInd]);

    const onChangeOfDeliveryDocInd = useCallback(
        (value) => {
            if (!value) {
                modalApi.showConfirm({
                    title: translator(messages.doNotDeliverDocsTitle),
                    message: translator(messages.doNotDeliverDocsMessage),
                    confirmButtonText: translator(commonMessages.confirm),
                    cancelButtonText: translator(commonMessages.cancel)

                }).then((result) => {
                    if (result === 'confirm') {
                        setDeliverDocInd(value);
                    }
                }).catch(_.noop);
            } else {
                setDeliverDocInd(value);
            }
        }, [setDeliverDocInd, translator, modalApi]
    );

    return (
        <div style={{ width: '150px' }}>
            <ToggleField
                id="deliverDocIndToggleField"
                value={deliverDocInd}
                dataType="boolean"
                defaultValue
                required
                showRequired
                availableValues={[
                    {
                        displayName: translator(commonMessages.yes),
                        id: true
                    },
                    {
                        displayName: translator(commonMessages.no),
                        id: false
                    }
                ]}
                readOnly={viewOnlyMode}
                onValueChange={onChangeOfDeliveryDocInd}
                label={translator(messages.deliverDocuments)}
                labelPosition="top"
            />
        </div>
    );
}


E1PDeliverDocumentsIndComponent.propTypes = {
    deliverDocInd: PropTypes.bool.isRequired,
    viewOnlyMode: PropTypes.bool,
    setDeliverDocInd: PropTypes.func
};

E1PDeliverDocumentsIndComponent.defaultProps = {
    viewOnlyMode: false,
    setDeliverDocInd: undefined
};

export default E1PDeliverDocumentsIndComponent;
