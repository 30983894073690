/* eslint-disable padding-line-between-statements */

import React, { useCallback } from 'react';
import {
    isNil as _isNil,
    get as _get,
    set as _set,
    findIndex as _findIndex,
    isEmpty as _isEmpty,
    stubFalse as _stubFalse
} from 'lodash';
import { commonMessages as e1pCommonMessages, ehCommonMessages } from 'e1p-platform-translations';
import { LoadSaveService } from 'e1p-capability-quoteandbind';
import { EndorsementService } from 'e1p-capability-policychange';
import { RewriteService } from 'e1p-capability-rewrite';
import { RenewalService } from 'e1p-capability-renewal';
import { TPIUtil } from 'e1p-portals-util-js';
import { useTranslator } from '@jutro/locale';

/**
 * @returns {Object} An object containing the exposed TPI utility hooks.
 */
function useTPIUtil() {
    const translator = useTranslator();

    /**
   * Helper function for handling the "renderCell" actions of TPI-related table cells.
   *
   * @param {Object} items An object containing the set of VMNode properties related to the table.
   * @param {Number} index A parameter defined as part of the the Jutro "renderCell" function.
   * @param {Object} param2 An object containing the name of the VMNode property that was changed.
   * @returns {Object} A span object containing the text to be displayed in the TPI table cell.
   */
    const handleTPITableOnCell = (items, index, { id: property }) => {
        let tpiTableCellText = '';
        const propertyValue = items[property];
        switch (property) {
            case 'address':
                tpiTableCellText = translator({
                    id: `tpi.displayTable.address.${propertyValue}`,
                    defaultMessage: TPIUtil.getFormattedTPIAddress(items)
                });
                break;
            case 'sendBillToTPIInd':
                if (!_isNil(propertyValue)) {
                    tpiTableCellText = 'No';
                    if (propertyValue) {
                        // Note: assignAtRenewalInd is a dropdownSelect field
                        if (JSON.parse(items.assignAtRenewalInd)) {
                            tpiTableCellText = 'Yes - Renewal';
                        } else {
                            tpiTableCellText = 'Yes - Now';
                        }
                    }
                }
                break;
            case 'convertTPIToPayerInd':
                if (!_isNil(propertyValue)) {
                    tpiTableCellText = 'No';
                    if (propertyValue) {
                        tpiTableCellText = 'Yes';
                    }
                }
                break;
            case 'addlInterestType':
                if (!_isNil(propertyValue)) {
                    tpiTableCellText = translator({
                        id: `typekey.AdditionalInterestType.${propertyValue}`,
                        defaultMessage: propertyValue
                    });
                }
                break;
            case 'additionalInsuredType':
                if (!_isNil(propertyValue)) {
                    tpiTableCellText = translator({
                        id: `typekey.EUAdditionalInsuredType_Ext.${propertyValue}`,
                        defaultMessage: propertyValue
                    });
                }
                break;
            case 'relationshipToNI':
                if (!_isNil(propertyValue)) {
                    tpiTableCellText = translator({
                        id: `typekey.Relationship_Ext.${propertyValue}`,
                        defaultMessage: propertyValue
                    });
                }
                break;
            case 'deceased':
                if (!_isNil(propertyValue)) {
                    const deceasedDisplayValue = propertyValue ? 'yes' : 'no';
                    tpiTableCellText = translator({
                        id: `typekey.YesNo_Ext.${deceasedDisplayValue}`,
                        defaultMessage: deceasedDisplayValue
                    });
                }
                break;
            case 'titleholderType':
                if (!_isNil(propertyValue)) {
                    tpiTableCellText = translator({
                        id: `typekey.TitleholderType_Ext.${propertyValue}`,
                        defaultMessage: propertyValue
                    });
                }
                break;
            default:
                if (!_isNil(propertyValue)) {
                    tpiTableCellText = translator({
                        id: `tpi.displayTable.default.${propertyValue}`,
                        defaultMessage: propertyValue
                    });
                }
                break;
        }
        return <div>{tpiTableCellText}</div>;
    };

    const updateNewlyAddedTPI = useCallback(
        async (transactionVMValue, authHeader) => {
            const jobType = _get(transactionVMValue, 'baseData.jobType');
            _set(transactionVMValue, 'flowStepIDs_Ext', ['tpi']);
            let updateDraftResponse;
            switch (jobType) {
                case 'Submission':
                    updateDraftResponse = await LoadSaveService.updateDraftSubmission(
                        transactionVMValue,
                        authHeader
                    );
                    break;
                case 'PolicyChange':
                    updateDraftResponse = await EndorsementService.saveEndorsement(
                        [transactionVMValue],
                        authHeader
                    );
                    break;
                case 'Rewrite':
                    updateDraftResponse = await RewriteService.updateDraftRewrite(
                        [transactionVMValue],
                        authHeader
                    );
                    break;
                case 'Renewal':
                    updateDraftResponse = await RenewalService.saveRenewal(
                        [transactionVMValue],
                        authHeader
                    );
                    break;
                default:
                    return transactionVMValue;
            }
            return updateDraftResponse;
        },
        []
    );

    /**
     * IAP-1278 : NC-Presence of Trust
     * This helper function performs following actions
     * If tpi presence of trust ind is false but tpi of type trust is present,
     * we land user to tpi page and returns true
     * If tpi presence of trust ind is true but tpi of type trust is not present,
     *  we land user to tpi page and returns true
     * @param {Object} transactionVM 
     * @param {Functions} jumpTo 
     * @param {Object} steps 
     * @param {String} pathToGo 
     * @returns {Boolean} 
     */
    const jumptToTPIPageIfTPIOfTrustConditionFails = (
        transactionVM, jumpTo, steps, pathToGo = '/third-party-interest'
    ) => {
        const policyType = _get(
            transactionVM,
            'value.lobData.homeowners_EH.policyType',
            _get(transactionVM, 'value.policyType')
        );
        const policyState = _get(transactionVM, 'baseData.policyAddress.state.value.code');
        const presentOfTrustInd = _get(
            transactionVM,
            'value.lobData.homeowners_EH.presenceOfTrustInd',
            false);
        const trustTPIPresent = !_isEmpty(
            _get(
                transactionVM,
                'value.lobData.homeowners_EH.coverables.yourHome.additionalInterests',
                []
            ).filter((addlInterest) => addlInterest.addlInterestType === 'TRUST_Ext'))

        if (
            policyType === 'HO3'
            && policyState === 'NC'
            && (
                (presentOfTrustInd && !trustTPIPresent)
                || (!presentOfTrustInd && trustTPIPresent)
            )
        ) {
            const indexForTPIPage = _findIndex(steps, ({ path }) => path === pathToGo);
            jumpTo(indexForTPIPage, true);
            return true;
        }
        return false;
    };

    /**
     * IAP-1278 : NC-Presence of Trust
     * This helper function performs following operations
     * If tpi presence of trust ind is false but tpi of type trust is present we show confirmation
     * dialog to set tpi presence of trust ind to true, if user confirms then we set ind to true.
     * IF tpi presence of trust ind is true but tpi of type trust is not present we show confirmation
     * dialog to set tpi presence of trust ind to false, if user confirms then we set ind to false
     * @param {Object} transactionVM 
     * @param {Function} updateWizardData 
     */
    const checkAndShowDialogForPresenceOfTrust = async (transactionVM, updateWizardData) => {
        const policyState = _get(transactionVM, 'baseData.policyAddress.state.value.code');
        const policyType = _get(
            transactionVM,
            'value.lobData.homeowners_EH.policyType',
            _get(transactionVM, 'value.policyType')
        );
        const presentOfTrustInd = _get(
            transactionVM,
            'value.lobData.homeowners_EH.presenceOfTrustInd',
            false);
        const trustTPIPresent = !_isEmpty(
            _get(
                transactionVM,
                'value.lobData.homeowners_EH.coverables.yourHome.additionalInterests',
                []
            ).filter((additionalInterest) => additionalInterest.addlInterestType === 'TRUST_Ext'))
        if (policyState !== 'NC' || policyType !== 'HO3') {
            return true;
        }
        if (presentOfTrustInd && !trustTPIPresent) {
            const { modalApi } = _get(window, '__giamfam.modalApi');
            const popupResult = await modalApi.showConfirm({
                title: ehCommonMessages.thirdPartyInterestOfTrust,
                message: ehCommonMessages.tpiPresenceOfTrustWillBeRemoved,
                confirmButtonText: e1pCommonMessages.confirm,
                cancelButtonText: e1pCommonMessages.cancel
            });
            if (popupResult === 'cancel') {
                // User clicks cancel
                // flow will not progress further will keep user on same page
                return _stubFalse();
            }
            _set(transactionVM, 'value.lobData.homeowners_EH.presenceOfTrustInd', false);
            updateWizardData(transactionVM);
            return true;
        }
        if (!presentOfTrustInd && trustTPIPresent) {
            const { modalApi } = _get(window, '__giamfam.modalApi');
            const popupResult = await modalApi.showConfirm({
                title: ehCommonMessages.thirdPartyInterestOfTrust,
                message: ehCommonMessages.tpiPresenceOfTrustWillBeSelected,
                confirmButtonText: e1pCommonMessages.confirm,
                cancelButtonText: e1pCommonMessages.cancel
            });
            if (popupResult === 'cancel') {
                // User clicks cancel
                // flow will not progress further will keep user on same page
                return _stubFalse();
            }
            _set(transactionVM, 'value.lobData.homeowners_EH.presenceOfTrustInd', true);
            updateWizardData(transactionVM);
            return true;
        }
        return true;
    }

    return {
        handleTPITableOnCell,
        updateNewlyAddedTPI,
        jumptToTPIPageIfTPIOfTrustConditionFails,
        checkAndShowDialogForPresenceOfTrust
    };
}

export default useTPIUtil;
