import React from 'react';
import PropTypes from 'prop-types';
import { MetadataForm } from '@jutro/legacy/uiconfig';
import metadata from './PolicySummaryCardComponent.metadata.json5';
import styles from './PolicySummaryCardComponent.module.scss';

const moneyPropsTypes = PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string
});

const PolicySummaryCardComponent = (props) => {
    const {
        policySummaryInfo
    } = props;

    const overrideProps = {
        '@field': {
            readOnly: true,
            autoComplete: false
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <MetadataForm
            uiProps={metadata.pageContent}
            data={policySummaryInfo}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap} />
    );
};

PolicySummaryCardComponent.propTypes = {
    policySummaryInfo: PropTypes.shape({
        totalPremium: moneyPropsTypes,
        taxesAndFees: moneyPropsTypes,
        totalCost: moneyPropsTypes
    }).isRequired
};

export default PolicySummaryCardComponent;
