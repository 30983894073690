import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    get, set, filter, find, isEmpty, remove
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useModal } from '@jutro/components';
import { DriverLicenseValidationService } from 'e1p-capability-gateway';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { useTranslator } from '@jutro/locale';
import metadata from './E1PLicenseComponent.metadata.json5';
import messages from './E1PLicenseComponent.messages';
import styles from './E1PLicenseComponent.module.scss';

function E1PLicenseComponent(props) {
    const modalApi = useModal();
    const {
        drivers,
        driverVM,
        labelPosition,
        showOptional,
        path,
        id,
        viewModelService,
        authHeader,
        onValidate,
        onValueChange,
        viewOnlyMode,
        hideLabel,
        licenseNumberRequired,
        showErrors,
        driversWithLicenseValidationError,
        setDriversWithLicenseValidationErrors
    } = props;
    const translator = useTranslator();
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const [isLicenseNumberInvalid, setIsLicenseNumberInvalid] = useState(false);
    const [licenseNumberValidationMessages,
        setLicenseNumberValidationMessages] = useState([]);
    const [capturedLicenseNumber, setCapturedLicenseNumber] = useState('');
    const [validatingDriverLicense, setValidatingDriverLicense] = useState(false);
    const licenseState = driverVM.person.licenseState.get();

    const firstUpdate = useRef(true);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, driverVM]);

    const handleValueChange = useCallback((value, changedPath) => {
        const fullPath = `${path}.${changedPath}`;

        if (onValueChange) {
            onValueChange(value, fullPath);
        }
    }, [onValueChange, path]);

    /**
     * Helper function that returns a driver that has the same license #
     *  as the personVM in this component. Undefined if none found.
     */
    const getDriverWithDuplicateLicense = useCallback(() => {
        const driverList = drivers.value;

        const driverMatch = filter(driverList,
            (otherDriver) => otherDriver.person.licenseNumber === get(driverVM.value, 'person.licenseNumber'));

        if (!driverMatch) {
            return undefined;
        }

        if (driverMatch.length === 1) {
            return driverMatch[0] !== driverVM.value ? driverMatch[0] : undefined;
        }

        return find(driverMatch, (d) => d !== driverVM.value);
    }, [driverVM.value, drivers.value]);

    /**
     * Looks to see if two or more drivers have the same license # input.
     * @returns {Boolean} valid Returns true if no duplicates
     *   If not valid we reset license # to undefined and show a error modal
     */
    const checkAndHandleDuplicateLicenseNumbers = useCallback(() => {
        const matchingDriver = getDriverWithDuplicateLicense();
        let valid = true;

        if (matchingDriver) {
            valid = false;

            if (!matchingDriver.person.displayName) {
                const middleName = matchingDriver.person.middleName ? matchingDriver.person.middleName : '';

                matchingDriver.person.displayName = `${matchingDriver.person.firstName} ${middleName} ${matchingDriver.person.lastName}`;
            }

            const driverName = matchingDriver.person.displayName;
            const msg = translator(messages.licenseMatchMessage, {
                driver: driverName
            });

            setIsLicenseNumberInvalid(true);
            setLicenseNumberValidationMessages([msg]);

            const driversWithError = driversWithLicenseValidationError;

            if (driversWithError.find((item) => item === id) === undefined) {
                driversWithError.push(id);
                setDriversWithLicenseValidationErrors(driversWithError);
            }
        }

        return valid;
    }, [driversWithLicenseValidationError, getDriverWithDuplicateLicense, id, setDriversWithLicenseValidationErrors, translator]);

    /**
     * Checks for duplicate license numbers on exit of a drivers license field.
     * If no duplicates, it then calls the DL validation service.
     */
    const handleLicenseBlur = useCallback(async () => {
        // Can't validate until license is entered
        if (!licenseState) {return;}

        // Only execute when DL# has changed
        // if license state for driver is international, we are not calling the validator
        if (driverVM.person.licenseState.value?.code !== 'INT_ext') {
            const licenseValue = get(driverVM.person, 'licenseNumber.value', '');

            if (!(capturedLicenseNumber === licenseValue) && licenseValue !== '') {
                const isValid = checkAndHandleDuplicateLicenseNumbers();

                if (isValid) {
                    const driverLicenseDto = {
                        firstName: get(driverVM, 'person.firstName.value'),
                        lastName: get(driverVM, 'person.lastName.value'),
                        dlstateCode: get(driverVM, 'person.licenseState.value.code', undefined),
                        dlnumber: get(driverVM, 'person.licenseNumber.value')
                    };
                    const driverLicenseVM = viewModelService.create(
                        driverLicenseDto,
                        'pc',
                        'amfam.edge.capabilities.policyjob.common.drivervalidation.dto.DriverLicenseDTO'
                    );

                    try {
                        setValidatingDriverLicense(true);

                        const driverLicenseValidationResult = await DriverLicenseValidationService
                            .validateDriverLicense(driverLicenseVM.value, authHeader);

                        // Adding this condition because mock service is returning an empty object
                        // for a valid (mock valid) license number
                        if (isEmpty(driverLicenseValidationResult)) {
                            setIsLicenseNumberInvalid(false);
                            setLicenseNumberValidationMessages([]);

                            // IAP-2300: real service returning empty object for valid license
                            // Need to remove driver from list if valid for formatting
                            const driversWithError = driversWithLicenseValidationError;

                            if (driversWithError.find((item) => item === id) !== undefined) {
                                remove(driversWithError, (item) => item === id)
                                setDriversWithLicenseValidationErrors(driversWithError);
                            }
                        } else {
                            // Toggle to show error messages
                            setIsLicenseNumberInvalid(!driverLicenseValidationResult.isValid);

                            const driversWithError = driversWithLicenseValidationError;

                            if (driverLicenseValidationResult.isValid) {
                                if (driversWithError.find((item) => item === id) !== undefined) {
                                    remove(driversWithError, (item) => item === id)
                                    setDriversWithLicenseValidationErrors(driversWithError);
                                }
                            } else if (driversWithError.find((item) => item === id) === undefined) {
                                driversWithError.push(id);
                                setDriversWithLicenseValidationErrors(driversWithError);
                            }

                            setLicenseNumberValidationMessages([translator(messages.validLicenseErrorMesage)]);
                        }
                    } catch {
                        modalApi.showConfirm({
                            status: 'warning',
                            icon: 'mi-error-outline',
                            title: messages.licenseValidationError,
                            message: messages.licenseValidationErrorMessage,
                            showCancelBtn: false
                        }).then((result) => {
                            if (result !== 'cancel') {
                                set(driverVM, 'person.licenseNumber', undefined);
                                set(driverVM, 'licenseNumber', undefined);
                                set(driverVM, 'person.licenseState', undefined);
                                set(driverVM, 'licenseState', undefined);
                                handleValueChange(undefined, 'person.licenseNumber');
                                handleValueChange(undefined, 'licenseNumber');
                                handleValueChange(undefined, 'person.licenseState');
                                handleValueChange(undefined, 'licenseState');
                            }
                        });
                    } finally {
                        setValidatingDriverLicense(false);
                    }
                }
            } else if (licenseValue === '') {
                setIsLicenseNumberInvalid(false);

                const driversWithError = driversWithLicenseValidationError;

                if (driversWithError.find((item) => item === id) !== undefined) {
                    remove(driversWithError, (item) => item === id)
                    setDriversWithLicenseValidationErrors(driversWithError);
                }

                setLicenseNumberValidationMessages([]);
            }
        }
    }, [licenseState, driverVM, capturedLicenseNumber, checkAndHandleDuplicateLicenseNumbers,
        viewModelService, authHeader, driversWithLicenseValidationError, id, translator,
        setDriversWithLicenseValidationErrors, handleValueChange, modalApi]);

    /**
     * This callback will capture the license number value on enter of
     * the DL input field. Will be used to see if it has changed on exit
     * of the field
     */
    const handleLicenseFocused = useCallback(
        () => {
            setCapturedLicenseNumber(get(driverVM.person, 'licenseNumber.value', ''));
        }, [driverVM]
    );

    const onLicenseNumberChange = useCallback(
        (value, changedPath) => {
            let licenseNumberValue = value;

            if (isEmpty(licenseNumberValue?.trim())) {
                licenseNumberValue = undefined;
            }

            handleValueChange(licenseNumberValue, changedPath);
            // updating licenseNumber on driver obj
            handleValueChange(licenseNumberValue, 'licenseNumber');
        }, [handleValueChange]
    );

    useEffect(() => {
        if (firstUpdate.current) {
            // Don't validate on mount
            firstUpdate.current = false;

            return;
        }

        // If no license state, we can't run validation on blur
        //   of license field, so we will run this side effect
        //   when license state changes
        handleLicenseBlur();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [licenseState]);

    const overrideProps = {
        '@field': {
            showOptional,
            hideLabel,
            labelPosition,
            readOnly: viewOnlyMode,
            autoComplete: false,
            showRequired: true
        },

        licenseNumber: {
            onBlur: handleLicenseBlur,
            onFocus: handleLicenseFocused,
            showErrors: isLicenseNumberInvalid || showErrors,
            validationMessages: licenseNumberValidationMessages,
            onValueChange: onLicenseNumberChange,
            className: viewOnlyMode ? 'viewOnlyField' : '',
            required: licenseNumberRequired
        },
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <E1PLoader loaded={!validatingDriverLicense} text={translator(messages.validatingDriverLicense)}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={driverVM}
                overrideProps={overrideProps}
                onValidationChange={setComponentValidation}
                onValueChange={handleValueChange}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </E1PLoader>
    );
}

E1PLicenseComponent.propTypes = {
    value: PropTypes.shape({}),
    driverVM: PropTypes.shape({
        value: PropTypes.shape({}),
        person: PropTypes.shape({
            licenseState: PropTypes.shape({
                get: PropTypes.func,
                value: PropTypes.shape({})
            })
        })
    }),
    drivers: PropTypes.shape({}),
    authHeader: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    id: PropTypes.string,
    viewOnlyMode: PropTypes.bool,
    hideLabel: PropTypes.bool,
    licenseNumberRequired: PropTypes.bool,
    showErrors: PropTypes.bool,
    viewModelService: PropTypes.shape({ create: PropTypes.func }).isRequired,
  	driversWithLicenseValidationError: PropTypes.arrayOf(PropTypes.shape({})),
  	setDriversWithLicenseValidationErrors: PropTypes.func
};
E1PLicenseComponent.defaultProps = {
    value: {},
    driverVM: {},
    drivers: [],
    labelPosition: 'top', // I want labels on top by default
    path: undefined,
    showOptional: false,
    id: undefined,
    viewOnlyMode: false,
    hideLabel: true,
    licenseNumberRequired: false,
    showErrors: false,
  	driversWithLicenseValidationError: [],
  	setDriversWithLicenseValidationErrors: () => {},
    authHeader: undefined
};
export default E1PLicenseComponent;
