
import {
    useCallback,
} from 'react';
import _ from 'lodash';

/**
 *
 * @param {*} submissionVM submission dto
 * @param {*} updateWizardData function to update state
 * @param {*} viewModelService create VMs
 * @param {*} lob line of business
 * @param {*} disregardFieldValidation function to remove item from the invalidFields
 * @returns {*} object
 */
function useSniUtil(submissionVM, updateWizardData, viewModelService, lob, disregardFieldValidation) {
    const createSecondaryNamedInsuredVM = useCallback(
        () => {
            const sniExist = _.get(submissionVM, `lobData[${lob}].secondaryNamedInsured.value`);

            if (!sniExist) {
                const secondaryNamedInsuredObj = {
                    person: {
                        lastName: '',
                        firstName: ''
                    }
                };
                const { _dtoName, _xCenter } = submissionVM.lobData[lob].secondaryNamedInsured;
                const secondaryNamedInsuredVM = viewModelService
                    .create(secondaryNamedInsuredObj, _xCenter, _dtoName);

                _.set(submissionVM, `lobData[${lob}].secondaryNamedInsured.value`, secondaryNamedInsuredVM.value);

                // if flowstepid does not contain namedinsured add it 
                if (!_.get(submissionVM, 'flowStepIDs_Ext.value', []).includes('namedinsured')) {
                    _.set(submissionVM, 'flowStepIDs_Ext.value', [..._.get(submissionVM, 'flowStepIDs_Ext.value', []), 'namedinsured']);
                }

                // if entry completionSteps does not contain namedinsured add it
                if (!_.get(submissionVM, 'entryCompletionStepIDs_Ext.value', []).includes('namedinsured')) {
                    _.set(submissionVM, 'entryCompletionStepIDs_Ext.value', [..._.get(submissionVM, 'entryCompletionStepIDs_Ext.value', []),'namedinsured']);
                }

                updateWizardData(submissionVM);
            }
        },
        [lob, submissionVM, updateWizardData, viewModelService]
    );

    const removeSniFromCoverable = useCallback(() => {
        const sni = _.get(submissionVM, `lobData[${lob}].secondaryNamedInsured.value`);

        if (sni && sni.person?.publicID && lob === 'homeowners_EH') {
            const householdOccupants = _.get(
                submissionVM.value,
                `lobData[${lob}].coverables.householdOccupants`,
                []
            );
            const sniIndex = _.findIndex(householdOccupants, (occupant) => occupant.person.publicID === sni.person.publicID);

            if (sniIndex >= 0) {
                _.pullAt(householdOccupants, sniIndex);
                _.set(submissionVM, `lobData[${lob}].coverables.householdOccupants.value`, householdOccupants);
                updateWizardData(submissionVM);
            }
        } else if (sni && sni.person?.publicID && lob === 'personalAuto_EA') {
            const drivers = _.get(
                submissionVM.value,
                `lobData[${lob}].coverables.drivers`,
                []
            );
            const sniIndex = _.findIndex(drivers, (driver) => driver.person.publicID === sni.person.publicID);
            // IAP-5793, remove SNI association from vehicle driver
            const sniDriver = _.find(drivers, (driver) => driver.person.publicID === sni.person.publicID);
            const vehicleListPath = 'value.lobData.personalAuto_EA.coverables.vehicles';
            const vehicles = _.get(submissionVM, vehicleListPath, []);
            const newVehicles = [...vehicles];

            if (sniIndex >= 0) {
                // remove from drivers
                _.pullAt(drivers, sniIndex);
                // remove from vehicle drives if applicable

                if(newVehicles && sniDriver) {
                    newVehicles.forEach((vehicle) => {
                        // remove sni association from vehicle drivers
                        _.remove(vehicle.vehicleDrivers, (vehicleDriver) => vehicleDriver.driverID === sniDriver.fixedId);
                    });
                    // update vehicles
                    _.set(submissionVM, vehicleListPath, newVehicles);
                }
                // update drivers

                _.set(submissionVM, `lobData[${lob}].coverables.drivers.value`, drivers);
                updateWizardData(submissionVM);
            }
        }
    }, [lob, submissionVM, updateWizardData]);

    const removeSni = useCallback(
        () => {
            const sniExist = _.get(submissionVM, `lobData[${lob}].secondaryNamedInsured.value`);

            if (sniExist) {
                removeSniFromCoverable();
                _.set(submissionVM, `lobData[${lob}].secondaryNamedInsured.value`, undefined);
                updateWizardData(submissionVM);
                disregardFieldValidation('secondaryNamedInsured');
            }
        },
        [submissionVM, lob, removeSniFromCoverable, updateWizardData, disregardFieldValidation]
    );

    const createDriverVM = useCallback(
        (person) => {
            const driverObj = {
                person
            };
            const {
                _dtoName,
                _xCenter
            } = _.get(submissionVM, `lobData[${lob}].coverables.drivers`);
            const driverVM = viewModelService.create(driverObj, _xCenter, _dtoName);

            return driverVM;
        },
        [lob, submissionVM, viewModelService]
    );

    const createHousholdVM = useCallback(
        (person) => {
            const householdObj = {
                person
            };
            const {
                _dtoName,
                _xCenter
            } = _.get(submissionVM, `lobData[${lob}].coverables.householdOccupants`);
            const householdVM = viewModelService.create(householdObj, _xCenter, _dtoName);

            return householdVM;
        },
        [lob, submissionVM, viewModelService]
    );

    const addSniInCoverable = useCallback(() => {
        const sni = _.get(submissionVM, `lobData[${lob}].secondaryNamedInsured.value`);

        if (sni && lob === 'homeowners_EH') {
            const householdOccupants = _.get(
                submissionVM.value,
                `lobData[${lob}].coverables.householdOccupants`,
                []
            );
            let householdVM = householdOccupants.find((occupant) => occupant.person.publicID === sni.person?.publicID);

            if (householdVM) {
                _.set(householdVM, 'relationshipToNI', sni.relationshipToNI);
            } else {
                householdVM = createHousholdVM(sni.person);
                _.set(householdVM.value, 'relationshipToNI', sni.relationshipToNI);
                householdOccupants.push(householdVM.value);
            }

            _.set(submissionVM, `lobData[${lob}].coverables.householdOccupants.value`, householdOccupants);
            updateWizardData(submissionVM);
        } else if (sni && lob === 'personalAuto_EA') {
            const drivers = _.get(
                submissionVM.value,
                `lobData[${lob}].coverables.drivers`,
                []
            );
            let sniDriver = drivers.find((driver) => driver.person.publicID === sni.person?.publicID);

            if (sniDriver) {
                _.set(sniDriver, 'relationshipToNI', sni.relationshipToNI);
            } else {
                sniDriver = createDriverVM(sni.person);
                _.set(sniDriver.value, 'relationshipToNI', sni.relationshipToNI);
                
                if(sni.person.licenseNumber) {
                    _.set(sniDriver.value, 'licenseNumber', sni.person.licenseNumber);
                }

                if(sni.person.licenseState) {
                    _.set(sniDriver.value, 'licenseState', sni.person.licenseState);
                }
             
                drivers.push(sniDriver.value);
            }

            _.set(submissionVM, `lobData[${lob}].coverables.drivers.value`, drivers);
            updateWizardData(submissionVM);
        }
    }, [createDriverVM, createHousholdVM, lob, submissionVM, updateWizardData]);


    return {
        createSecondaryNamedInsuredVM,
        removeSni,
        addSniInCoverable
    };
}

export default useSniUtil;
