import { defineMessages } from 'react-intl';

export default defineMessages({
    twelveMonths: {
        id: 'common.component.EHPolicyTermComponent.twelveMonths',
        defaultMessage: '12 Months'
    },
    policyTerm: {
        id: 'common.component.EHPolicyTermComponent.Policy Term',
        defaultMessage: 'Policy Term'
    }
});
