import React, {
    useCallback, useEffect, useState
} from 'react';
import {
    get, isEmpty
} from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import metadata from '../VehicleOperatorPage.metadata.json5';

function ViewVehicleOperatorPage(props) {
    const {
        wizardData: renewalVM
    } = props;

    const [checkScrolling, setCheckScrolling] = useState(false);
    const [indexStale, setIndexStale] = useState(false);

    /**
     * E1PAP1PC-15717: E1P QA Design UX Adherence, as per the story
     * we need to focus on first element of the new node.
     * so that the Tab order should work as expected.
     */
    useEffect(() => {
        const vehicleOperatorElement = document.getElementById(
            `firstName${renewalVM.lobData.personalUmbrella_EU.vehicleOperators.length - 1}`
        );

        if (vehicleOperatorElement
            && isEmpty(vehicleOperatorElement.value)
            && vehicleOperatorElement.focus !== undefined) {
            vehicleOperatorElement.focus();
        }
    }, [renewalVM.lobData.personalUmbrella_EU.vehicleOperators.length]);


    // this grabs the latest element on the page --
    // this is to make sure this element has been loaded
    const latestVehicleOperatorElement = document.getElementById(
        `euVehicleOperatorComponentDiv${renewalVM.lobData
            .personalUmbrella_EU.vehicleOperators.children.length - 1}`
    );

    useEffect(() => {
        // indexStale set in the add vehicle operator function
        // once latest element is loaded and a new vehicle operator is added
        // we check if the button should be visible
        if (latestVehicleOperatorElement && indexStale) {
            setCheckScrolling(true);
            setIndexStale(false);
        }
    }, [indexStale, latestVehicleOperatorElement]);

    /**
     * Define Jutro component properties to be overridden and given dynamic behavior.
     */
    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: false
        },
        vehicleOperatorPageLoadingIndicator: {
            loaded: true
        },
        vehicleOperatorPageContainer: {
            visible: true
        },
        scrollingComponentId: {
            checkScrolling,
            setCheckScrolling,
            scrollableDiv: document.getElementById('vehicleOperatorsContainer')
        },
        EUVehicleOperatorGrid: {
            key: get(renewalVM, 'lobData.personalUmbrella_EU.vehicleOperators', []).length,
            transactionVM: renewalVM,
            drivers: get(renewalVM, 'lobData.personalUmbrella_EU.vehicleOperators', []),
            path: 'lobData.personalUmbrella_EU.vehicleOperators.children',
            onValidate: () => {},
            showErrors: false,
            setCheckScrolling,
            updateWizardData: () => {},
            viewOnlyMode: true
        },
        addVehicleOperator: {
            visible: false
        }
    };


    /**
     * Define resolvers to be used when resolving values for this Jutro component.
     */
    const resolvers = {
        resolveCallbackMap: {
            onValidate: () => {}
        },
        resolveComponentMap: {
        }
    };

    /**
     * Helper callback for handling navigation to the next wizard screen.
     */
    const onNext = useCallback(
        async () => renewalVM,
        [renewalVM]
    );


    return (
        <WizardPage
            onNext={onNext}
            shouldLink
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={() => {}}
                onValidationChange={() => {}}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

ViewVehicleOperatorPage.propTypes = wizardProps;
export default ViewVehicleOperatorPage;
