import { defineMessages } from 'react-intl';

export default defineMessages({
    documents: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.Documents',
        defaultMessage: 'Documents'
    },
    uploadDocuments: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.Upload Documents',
        defaultMessage: 'Upload Documents'
    },
    documentName: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.Name',
        defaultMessage: 'Name'
    },
    searchDocuments: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.Search documents',
        defaultMessage: 'Search Documents'
    },
    dateUploaded: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.Date Modified',
        defaultMessage: 'Date Uploaded'
    },
    dropaFilehere: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.dropafilehere',
        defaultMessage: 'drag &amp; drop a file here'
    },
    fileisUploaded: {
        id: 'e1p.gateway.e1p.directives.gateway-documents.fileisuploaded',
        defaultMessage: 'file is uploaded'
    },
    documentMayFailed: {
        id: 'e1p.gateway.e1p.directives.GatewayDocuments.Document deletion may have failed',
        defaultMessage: 'Document deletion may have failed'
    },
    documentSomethingMayGoneWrong: {
        id: 'e1p.gateway.e1p.directives.GatewayDocuments.Something may have gone wrong trying to delete your document.',
        defaultMessage: 'Something may have gone wrong trying to delete your document.'
    },
    documentType: {
        id: 'e1p.gateway.directives.gateway-documents.Type',
        defaultMessage: 'Type'
    },
    documentCategory: {
        id: 'e1p.gateway.directives.gateway-documents.Category',
        defaultMessage: 'Category'
    },
    transactionType: {
        id: 'e1p.gateway.directives.gateway-documents.Transaction',
        defaultMessage: 'Transaction'
    },
    description: {
        id: 'e1p.gateway.directives.gateway-documents.Description',
        defaultMessage: 'Description'
    },
    deliveryMethod: {
        id: 'e1p.gateway.directives.gateway-documents.Delivery Method',
        defaultMessage: 'Delivery Method'
    },
    refreshDocuments: {
        id: 'e1p.gateway.directives.gateway-documents.Refresh Documents',
        defaultMessage: 'Refresh Documents'
    },
    effectiveDateTitle: {
        id: 'e1p.gateway.directives.gateway-documents.Effective Date',
        defaultMessage: 'Effective Date'
    },
    refineResults: {
        id: 'e1p.gateway.directives.gateway-documents.Refine Results',
        defaultMessage: 'Refine Results'
    },
    agencyName: {
        id: 'e1p.gateway.directives.gateway-documents.Name',
        defaultMessage: 'Name'
    },
    deliveryType: {
        id: 'e1p.gateway.directives.gateway-documents.Delivery Type',
        defaultMessage: 'Delivery Type'
    },
    workOrder: {
        id: 'e1p.gateway.directives.gateway-documents.Work Order',
        defaultMessage: 'Work Order'
    },
    dateRange: {
        id: 'e1p.gateway.directives.gateway-documents.Date Range',
        defaultMessage: 'Date Range'
    },
    fromStartDate: {
        id: 'e1p.gateway.directives.gateway-documents.From',
        defaultMessage: 'From'
    },
    toEndDate: {
        id: 'e1p.gateway.directives.gateway-documents.To',
        defaultMessage: 'To'
    },
    clearButtonId: {
        id: 'e1p.gateway.directives.gateway-documents.Clear',
        defaultMessage: 'Clear'
    },
    documentsHelpText: {
        id: "e1p.gateway.directives.gateway-documents.Some documents may need more time to generate. Please refresh documents to view all available documents.",
        defaultMessage: "Some documents may need more time to generate. Please refresh documents to view all available documents."
    }
});
