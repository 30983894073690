import { defineMessages } from 'react-intl';

export default defineMessages({
    loadingNextWizardStepMessage: {
        id: 'quoteandbind.eu.wizard.Loading next wizard step...',
        defaultMessage: 'Loading next wizard step...'
    },
    insured: {
        id: 'quoteandbind.eu.wizard.step.Insured',
        defaultMessage: 'Insured'
    },
    policyDetailsPageTitle: {
        id: 'quoteandbind.eu.wizard.step.Policy Details',
        defaultMessage: 'Policy Details'
    },
    qualificationPageTitle: {
        id: 'quoteandbind.eu.wizard.step.Qualification',
        defaultMessage: 'Qualification'
    },
    exitTransaction: {
        id: 'quoteandbind.eu.wizard.step.Exit Transaction?',
        defaultMessage: 'Exit Transaction?'
    },
    cancelLabel: {
        id: 'quoteandbind.eu.wizard.step.Cancel',
        defaultMessage: 'Cancel'
    },
    exitLabel: {
        id: 'quoteandbind.eu.wizard.step.Exit',
        defaultMessage: 'Exit'
    },
    infoWillBeSavedAsDraft: {
        id: 'quoteandbind.eu.wizard.step.Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.',
        defaultMessage: 'Information entered on prior pages will be saved as a draft. Any outstanding changes on this page will be lost.'
    },
    underlyingPolicyPageTitle: {
        id: 'quoteandbind.eu.wizard.step.Underlying Policy',
        defaultMessage: 'Underlying Policy'
    },
    underwritingPageTitle: {
        id: 'quoteandbind.eu.wizard.step.Underwriting',
        defaultMessage: 'Underwriting'
    },
    riskAnalysisPageTitle: {
        id: 'quoteandbind.eu.wizard.step.RiskAnalysisUW',
        defaultMessage: 'Risk Analysis'
    },
    anErrorOccurred: {
        id: 'quoteandbind.eu.wizard.error.cancel',
        defaultMessage: 'Something went wrong during this process.'
    },
    anErrorOccurredTitle: {
        id: 'quoteandbind.eu.wizard.error.cancel.title',
        defaultMessage: 'Error'
    },
    saveQuoteError: {
        id: 'quoteandbind.eu.wizard.Unable to quote',
        defaultMessage: 'Unable to quote'
    },
    saveQuoteErrorMessage: {
        id: 'quoteandbind.eu.wizard.An error occurred while attempting to quote.',
        defaultMessage: 'An error occurred while attempting to quote.'
    },
    vehicleOperatorPageTitle: {
        id: 'quoteandbind.eu.wizard.step.Vehicle Operator',
        defaultMessage: 'Vehicle Operator'
    },
    thankYou: {
        id: 'quoteandbind.wizard.step.Thank You',
        defaultMessage: 'Thank You'
    }
});
