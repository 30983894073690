import { defineMessages } from 'react-intl';

export default defineMessages({
    StartRewrite: {
        id: 'gateway-rewrite.directives.start-rewrite.Start Rewrite',
        defaultMessage: 'Start Rewrite'
    },
    RewriteEffectiveDate: {
        id: 'rewrite.common.Rewrite Effective Date',
        defaultMessage: 'Rewrite Effective Date'
    },
    RewriteReason: {
        id: 'rewrite.common.Rewrite Reason',
        defaultMessage: 'Rewrite Reason'
    },
    RewritePolicyNumber: {
        id: 'rewrite.common.Policy Number',
        defaultMessage: 'Rewrite Policy Number'
    }
});
