import React, {
    useCallback,
    useState,
    useEffect,
    useContext
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { AccountSearchUtil, UserService } from 'e1p-capability-gateway';
import { error as loggerError } from '@jutro/logger';
import appConfig from 'app-config';
import { gatewayMessages, AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './AccountSearchCriteriaFilterComponent.metadata.json5';
import styles from './AccountSearchCriteriaFilterComponent.module.scss';

function AccountSearchCriteriaFilterComponent(props) {
    const {
        accountSearchCriteriaVM,
        labelPosition,
        showOptional,
        isCallingApi,
        onRefineAccountsData,
        isAgent
    } = props;
    const [accountSearchVM, setAccountSearchVM] = useState(accountSearchCriteriaVM);
    const [producerCodesForCurrentUser, setProducerCodesForCurrentUser] = useState([]);
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    
    const [isFetchingProducerCodes, setIsFetchingProducerCodes] = useState(false);
    // this is used for Internal users, where they can enter code in text box
    const [externalLocationCode, setExternalLocationCode] = useState(undefined);
    const createAccountSearchVM = useCallback(() => {
        const searchCriteriaVM = AccountSearchUtil.createAccountSearchVM(viewModelService);

        setAccountSearchVM(searchCriteriaVM);
    }, [viewModelService]);
    const { opCo } = useContext(AmfamOktaTokenContext);
    const { operatingCompanyConfig } = appConfig;

    useEffect(() => {
        // this will exceute when context doesnot have search criteria
        if (viewModelService && _.isUndefined(accountSearchVM)) {
            createAccountSearchVM();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService]);

    useEffect(() => {
        // this will executed when we are getting search criteria for context
        if(!_.isUndefined(_.get(accountSearchVM, 'value'))
            && _.get(accountSearchVM, 'value.producerCode_Ext')
            && !isAgent) {
            setExternalLocationCode(_.get(accountSearchVM, 'value.producerCode_Ext'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProducerCodes = useCallback(async () => {
        setIsFetchingProducerCodes(true);

        let formattedProducerCodes = [];

        try{
            const producerCodeResponse = await UserService.getAvailableProducerCodesForCurrentUser(authHeader);

            if (producerCodeResponse && producerCodeResponse.length > 0) {
                formattedProducerCodes = _.sortBy(producerCodeResponse, 'code').map((value) => ({
                        code: value.code,
                        name: value.displayValue
                    }));
            }

            setProducerCodesForCurrentUser(formattedProducerCodes);
        } catch(e) {
            loggerError(e);
            setProducerCodesForCurrentUser(formattedProducerCodes);
        }

        setIsFetchingProducerCodes(false);
    }, [authHeader]);

    useEffect(() => {
        if(isAgent) {
            getProducerCodes();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * The onValueChange for most of the fields
     */
    const handleValueChange = useCallback(
        (value, changedPath) => {
            switch(changedPath) {
                case 'workStartedByCurrentUser_Ext': {
                    const workstartedValue = value === 'true';

                    _.set(accountSearchVM.value, changedPath, workstartedValue);
                    break;
                }
                case 'createdInLastXDays_Ext': {
                    const createdinlastdaysValue = parseInt(value, 10);

                    _.set(accountSearchVM.value, changedPath, createdinlastdaysValue);
                    break;
                }
                default:
                    _.set(accountSearchVM.value, changedPath, value);
                    break 
            }

            const searchCriteriaVMClone = viewModelService.clone(accountSearchVM);

            setAccountSearchVM(searchCriteriaVMClone);
        },
        [accountSearchVM, viewModelService]
    );

    const getConnectPartnerValues = () => {
        const formattedDropdownValues = [];
        const connectConfigObject = _.get(operatingCompanyConfig, 'CONNECT.experiences', {});

        for (const key in connectConfigObject) {
            // eslint-disable-next-line no-prototype-builtins
            if (connectConfigObject.hasOwnProperty(key) && !connectConfigObject[key].retired) {
                formattedDropdownValues.push({
                    id: key,
                    displayName: {
                        id: key,
                        defaultMessage: connectConfigObject[key].partnerDisplayName
                    }
                });
            }
        }

        return formattedDropdownValues;
    };
    
   


    const resolvers = {
        resolveClassNameMap: styles
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            disabled: isCallingApi,
            autoComplete: false
        },
        searchAccountButton: {
            onClick: () => {
                if(onRefineAccountsData) {
                    const searchVmClone = viewModelService.clone(accountSearchVM);

                    if( !isAgent ) {
                        // for UW login, we are setting location code in producerCode field,
                        // so that parent component can call producer api to fetch the producer code from location code
                        const locationValueToSet = externalLocationCode || undefined;

                        _.set(searchVmClone, 'producerCode_Ext.value', locationValueToSet);
                    }

                    onRefineAccountsData(searchVmClone);
                }
            },
            disabled: isCallingApi
        },
        clearFiltersButton: {
            onClick: () => {
                createAccountSearchVM();

                if(!isAgent){
                    setExternalLocationCode(undefined);
                }
            },
            disabled: isCallingApi
        },
        LocationCodeDropdownID: {
            availableValues: [{
                code: 'allLocations',
                name: translator(gatewayMessages.allLocations)
            }].concat(producerCodesForCurrentUser),
            visible: opCo === 'MSA' && isAgent && !isFetchingProducerCodes
        },
        locationCodeInlineLoader: {
            loading: isFetchingProducerCodes,
            visible: isAgent
        },
        ExternalLocationCodeID: {
            visible: opCo === 'MSA' && !isAgent,
            isRequired: false,
            value: externalLocationCode,
            onValueChange:  (code) => {
                setExternalLocationCode(code);
            }
        },
        PartnerCodeDropdownID: {
            availableValues: getConnectPartnerValues(),
            visible: opCo === 'CONNECT'
        }
    };


    const readValue = useCallback(
        (fieldId, fieldPath) => readViewModelValue(
                metadata.componentContent,
                accountSearchVM,
                fieldId,
                fieldPath,
                overrideProps
            ),
        [accountSearchVM, overrideProps]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={accountSearchVM}
            overrideProps={overrideProps}
            onValueChange={handleValueChange}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

AccountSearchCriteriaFilterComponent.propTypes = {
    labelPosition: PropTypes.string,
    showOptional: PropTypes.bool,
    isCallingApi: PropTypes.bool.isRequired,
    onRefineAccountsData: PropTypes.func.isRequired,
    isAgent: PropTypes.bool.isRequired
};
AccountSearchCriteriaFilterComponent.defaultProps = {
    labelPosition: 'top', // I want labels on top by default
    showOptional: false
};
export default AccountSearchCriteriaFilterComponent;