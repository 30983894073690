import React, {
    useContext
} from 'react';
import { AmfamOktaTokenContext } from 'e1p-capability-gateway-react';
import { DropdownSelectField } from '@jutro/legacy/components';


const OpCoSelect = (props) => {
    const { onChangeOperatingCompany } = props;
    const {opCo, changeOpCo} = useContext(AmfamOktaTokenContext);

    return (
        <DropdownSelectField
            availableValues={[
                {
                    code: 'MSA',
                    name: 'MSA'
                },
                {
                    code: 'CONNECT',
                    name: 'Connect'
                }
            ]}
            value={opCo}
            id="opcodropdown"
            hideLabel
            onValueChange={(value) => { changeOpCo(value); onChangeOperatingCompany(value) }}
        />
    );
}

export default OpCoSelect;