import { defineMessages } from 'react-intl';

export default defineMessages({
    driverQuestionLabel: {
        id: 'common.eu.UnderlyingPolicyTable.Do you or any resident relatives ever drive private passenger autos?',
        defaultMessage: 'Do you or any resident relatives ever drive private passenger autos?',
    },
    yes: {
        id: 'common.eu.UnderlyingPolicyTable.Yes',
        defaultMessage: 'Yes',
    },
    no: {
        id: 'common.eu.UnderlyingPolicyTable.No',
        defaultMessage: 'No',
    },
    reason: {
        id: 'common.eu.UnderlyingPolicyTable.Reason',
        defaultMessage: 'Reason(s) for not driving private passenger autos',
    },
    description: {
        id: 'common.eu.UnderlyingPolicyTable.description',
        defaultMessage: 'Description'
    },
    additionalInfoSectionTitle: {
        id: 'common.eu.Underlying Policy.Additional Info',
        defaultMessage: 'Additional Info'
    },
});
