import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import EUPreQualificationPage from './pages/EUPreQualification/EUPreQualificationPage';
import EUUnderlyingPolicyPage from './pages/UnderlyingPolicy/UnderlyingPolicyPage';
import EUVehicleOperatorPage from './pages/VehicleOperator/EUVehicleOperatorPage';
import EUQuotePage from './pages/Quote/QuotePage';
import EUQuoteClauseTable from './components/QuoteClauseTable/QuoteClauseTable';
import EUClauseComponent from './components/Clauses/ClauseComponent';
import EUThirdPartyInterestPage from './pages/ThirdPartyInterest/ThirdPartyInterestPage';
import EUPaymentDetailsPage from './pages/PaymentDetails/PaymentDetailsPage';
import EUThankYouPage from './pages/ThankYou/ThankYouPage';
import EURiskAnalysisPage from './pages/common/RiskAnalysisPage/RiskAnalysisPage';
import QNBViewEUPreQualificationPage from './pages/EUPreQualification/viewOnly/ViewEUPreQualificationPage';
import QNBViewEUUnderlyingPolicyPage from './pages/UnderlyingPolicy/viewOnly/ViewUnderlyingPolicyPage';
import QNBViewEUVehicleOperatorPage from './pages/VehicleOperator/viewOnly/ViewEUVehicleOperatorPage';
import QNBViewEUQuotePage from './pages/Quote/viewOnly/ViewQuotePage';
import QNBViewEUPaymentDetailsPage from './pages/PaymentDetails/ViewOnly/ViewPaymentDetailsPage';
import QNBViewEUThirdPartyInterestPage from './pages/ThirdPartyInterest/ViewOnly/ViewThirdPartyInterestPage';
import QNBViewEURiskAnalysisPage from './pages/common/RiskAnalysisPage/ViewOnly/RiskAnalysisPage';

setComponentMapOverrides(
    {
        EUPreQualificationPage: { component: 'EUPreQualificationPage' },
        EUUnderlyingPolicyPage: { component: 'EUUnderlyingPolicyPage' },
        EUVehicleOperatorPage: { component: 'EUVehicleOperatorPage' },
        EUQuotePage: { component: 'EUQuotePage' },
        EUQuoteClauseTable: { component: 'EUQuoteClauseTable' },
        EUClauseComponent: { component: 'EUClauseComponent' },
        EUThirdPartyInterestPage: { component: 'EUThirdPartyInterestPage' },
        EUPaymentDetailsPage: { component: 'EUPaymentDetailsPage' },
        EUThankYouPage: { component: 'EUThankYouPage' },
        EURiskAnalysisPage: { component: 'EURiskAnalysisPage' },
        QNBViewEUPreQualificationPage: { component: 'QNBViewEUPreQualificationPage' },
        QNBViewEUUnderlyingPolicyPage: { component: 'QNBViewEUUnderlyingPolicyPage' },
        QNBViewEUVehicleOperatorPage: { component: 'QNBViewEUVehicleOperatorPage' },
        QNBViewEUQuotePage: { component: 'QNBViewEUQuotePage' },
        QNBViewEUPaymentDetailsPage: { component: 'QNBViewEUPaymentDetailsPage' },
        QNBViewEUThirdPartyInterestPage: { component: 'QNBViewEUThirdPartyInterestPage' },
        QNBViewEURiskAnalysisPage: { component: 'QNBViewEURiskAnalysisPage' }
    },
    {
        EUPreQualificationPage,
        EUUnderlyingPolicyPage,
        EUVehicleOperatorPage,
        EUQuotePage,
        EUQuoteClauseTable,
        EUClauseComponent,
        EUThirdPartyInterestPage,
        EUPaymentDetailsPage,
        EUThankYouPage,
        EURiskAnalysisPage,
        QNBViewEUPreQualificationPage,
        QNBViewEUUnderlyingPolicyPage,
        QNBViewEUVehicleOperatorPage,
        QNBViewEUQuotePage,
        QNBViewEUPaymentDetailsPage,
        QNBViewEUThirdPartyInterestPage,
        QNBViewEURiskAnalysisPage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as EUWizard } from './EUWizard';
