const overrides = {
    field: {
        consentToRateAcknowledgement: {
            partner: {
                MSA: {
                    states: ['NC']
                }
            }
        }
    }
};
export default overrides;