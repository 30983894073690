import React from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import messages from './EsignConfirmationPage.messages';
import styles from './EsignConfirmationPage.module.scss';

function EsignConfirmationPage() {
    const translator = useTranslator();

    return (
        <div className={styles.confirmationContainer}>
            <h2>{translator(messages.pageHeader)}</h2>
            <hr />
            <p>{translator(messages.userDirections)}</p>
        </div>
    );
}

EsignConfirmationPage.propTypes = {
    authHeader: PropTypes.shape({}).isRequired
};

export default (EsignConfirmationPage);
