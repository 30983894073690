import React, {
    useCallback
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { E1PFieldLinkComponent } from 'e1p-capability-policyjob-react';

import metadata from './AccountsTableComponent.metadata.json5';
import styles from './AccountsTableComponent.module.scss';

/**
 * @param {*} props 
 * IAP-3238 and IAP-3363, as part of these ticket, refactored this component,
 * so that Account APIs, should not be called unless props are changed,
 * 
 * Issue: This component is re-rendering whenever the parent component is re-rendered, even with same props.
 * 
 * Why this change? :
 * we are refactoring this componet  because it is having dataTable with async fetch call,
 * and as soon as this renders async call is getting fired (even though all the Props are same during re-render).
 * So we will control the re-rendering of this component only when the props changes.
 * This change will increase the performance of the component.
 * 
 * you can read more here
 * https://reactjs.org/docs/react-api.html#reactmemo
 * 
 */

const AccountsTableComponent = (props) => {
    const {
        dataTableKey,
        dataTableConfig,
        onFetchData
    } = props;
    
    const getAccountLink = useCallback((item) => (
            <E1PFieldLinkComponent
                id={`account${item.accountNumber}`}
                value={item.accountHolder}
                to={`/accounts/${item.accountNumber}/summary`}
            />
        ), []);
    
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getAccountLink
        }
    };
    const overrideProps = {
        accountsTable: {
            onFetchData,
            key: dataTableKey,
            defaultConfig: dataTableConfig,
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
};

AccountsTableComponent.propTypes = {
    dataTableKey: PropTypes.string.isRequired,
    dataTableConfig: PropTypes.shape({}).isRequired,
    onFetchData: PropTypes.func.isRequired
};

// this function will determine when to render this component.
function areEqual(prevProps, nextProps) {
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
    const arePropsEqual = prevProps.dataTableKey === nextProps.dataTableKey
    && _.isEqual(prevProps.dataTableConfig, nextProps.dataTableConfig);

    return arePropsEqual;
}

// read the component description why we are using React.memo
export default React.memo(AccountsTableComponent, areEqual);
