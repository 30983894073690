import { defineMessages } from 'react-intl';

export default defineMessages({
    noDataText: {
        id: 'components.e1pContingencies.No Contigencies Occured For Policy',
        defaultMessage: 'No Contigencies Occured For Policy'
    },
    title: {
        id: 'components.e1pContingencies.Title',
        defaultMessage: 'Title'
    },
    description: {
        id: 'components.e1pContingencies.Description',
        defaultMessage: 'Description'
    },
    dueDate: {
        id: 'components.e1pContingencies.Due Date',
        defaultMessage: 'Due Date'
    },
    status: {
        id: 'components.e1pContingencies.Status',
        defaultMessage: 'Status'
    },
    potentialAction: {
        id: 'components.e1pContingencies.Potential Action',
        defaultMessage: 'Potential Action'
    },
    addedBy: {
        id: 'components.e1pContingencies.Added By',
        defaultMessage: 'Added By'
    },
    on: {
        id: 'components.e1pContingencies.On',
        defaultMessage: 'On'
    },
    approve: {
        id: 'components.e1pContingencies.Approve',
        defaultMessage: 'Approve'
    },
    reject: {
        id: 'components.e1pContingencies.Reject',
        defaultMessage: 'Reject'
    },
    contingenciesRetrieveErrorMessage: {
        id: 'components.e1pContingencies.An error occurred attempting to update Contingencies.',
        defaultMessage: 'An error occurred attempting to update Contingencies.'
    },
    contingenciesUpateErrorTitle: {
        id: 'components.e1pContingencies.Error Updating Contingencies',
        defaultMessage: 'Error Updating Contingencies'
    },
    updatingContingenciesMessage: {
        id: 'components.e1pContingencies.Updating Contingencies...',
        defaultMessage: 'Updating Contingencies...'
    },
});
