import { defineMessages } from "react-intl";

export const messages = defineMessages(
    { 
        loading: { 
            id: "components.e1pmsaloader.loading",
            defaultMessage: "Loading" 
        } 
    }
);
