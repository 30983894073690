import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    useRef
} from 'react';
import {
    cloneDeep, get, set, sortBy, uniqBy, isEmpty, isUndefined
} from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useModal } from '@jutro/components';
import { PropertyFlowUtil, ClausesUtil as e1pClausesUtil, QuoteProposalUtil } from 'e1p-portals-util-js';
import { RewriteService } from 'e1p-capability-rewrite';
import { useUWIssueUtil, useOOSConflictPageLandingUtil } from 'e1p-capability-hooks';
 
import appConfig from 'app-config';
import messages from './CoveragePage.messages';
import metadata from './CoveragePage.metadata.json5';

function generateClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeCoveragePath = 'coverages.coverages';

        return {
            code,
            path: `${lob.path}.${completeCoveragePath}`,
            clauses: get(lob.data, completeCoveragePath.replace(/\.children/, ''))
        };
    });
}

function generateExclusionClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeExclusionPath = 'exclusions';

        return {
            code,
            path: `${lob.path}.${completeExclusionPath}`,
            clauses: get(lob.data, completeExclusionPath.replace(/\.children/, ''))
        };
    });
}

function generateConditionClauseData(columnData) {
    return columnData.map(({ lob, code }) => {
        const completeConditionPath = 'conditions';

        return {
            code,
            path: `${lob.path}.${completeConditionPath}`,
            clauses: get(lob.data, completeConditionPath.replace(/\.children/, ''))
        };
    });
}

const generateColumnData = (rewriteVM) => {
    const lobOfferingPath = 'lobData.homeowners_EH.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = get(rewriteVM, `${lobOfferingPath}.value`);
    const quoteOfferings = get(rewriteVM, `${quoteOfferingPath}.value`);

    let filteredOfferings = cloneDeep(lobOfferings);

    if (filteredOfferings.length > 1) {
        filteredOfferings = filteredOfferings.filter((offering) => offering.branchCode !== 'CUSTOM');
    }

    const columnData = filteredOfferings.map((lobOffering, lobIndex) => {
        const quoteDataIndex = quoteOfferings.findIndex(
            (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
        );
        const quoteData = quoteOfferings[quoteDataIndex];

        return {
            name: lobOffering.branchName,
            code: lobOffering.branchCode,
            quote: {
                path: `${quoteOfferingPath}.children.${quoteDataIndex}`,
                data: quoteData
            },
            lob: {
                path: `${lobOfferingPath}.children.${lobIndex}`,
                data: lobOffering
            },
            rewriteVM
        };
    });

    // }).filter(({ code }) => code !== 'CUSTOM');
    return sortBy(columnData, ['code']);
};


const getExclusionsUniqueID = (rewriteVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (exclusion) => ({
        publicID: exclusion.publicID,
        coverageCategoryCode: exclusion.exclusionCategoryCode,
        uigroup: exclusion.uigroup
    });

    const offerings = get(rewriteVM, 'lobData.homeowners_EH.offerings.value');
    const exclusions = uniqBy(
        offerings.flatMap((offering) => offering.exclusions.map(structureClauseTableData)),
        'publicID'
    );

    return {
        exclusions
    };
};

const getConditionsUniqueID = (rewriteVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (condition) => ({
        publicID: condition.publicID,
        coverageCategoryCode: condition.conditionCategoryCode,
        uigroup: condition.uigroup
    });

    const offerings = get(rewriteVM, 'lobData.homeowners_EH.offerings.value');
    const conditions = uniqBy(
        offerings.flatMap((offering) => offering.conditions.map(structureClauseTableData)),
        'publicID'
    );

    return {
        conditions
    };
};

const getCoveragesUniqueID = (rewriteVM) => {
    // putting ID into an object as the Jutro table component expects an object
    const structureClauseTableData = (coverage) => ({
        publicID: coverage.publicID,
        coverageCategoryCode: coverage.coverageCategoryCode,
        uigroup: coverage.uigroup
    });
    const offerings = get(rewriteVM, 'lobData.homeowners_EH.offerings.value');
    const offerringsWithCovsNotHidden = offerings.map((offerring) => {
        const offerringFormatted = { ...offerring};

        offerringFormatted.coverages.coverages = offerring.coverages.coverages.filter((coverage) => coverage.isHiddenInVersion !== true);

        return offerringFormatted;
    });
    const baseCoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages.map(structureClauseTableData)),
        'publicID'
    );
    const additionalCoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.schedules.map(structureClauseTableData)),
        'publicID'
    );
    const sectionICoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Section I')
            .map(structureClauseTableData)),
        'publicID'
    );
    const sectionIICoverages = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Section II')
            .map(structureClauseTableData)),
        'publicID'
    );
    const deductibles = uniqBy(
        offerringsWithCovsNotHidden.flatMap((offering) => offering.coverages.coverages
            .filter((coverage) => coverage.uigroup === 'Deductibles')
            .map(structureClauseTableData)),
        'publicID'
    );

    return {
        baseCoverages,
        additionalCoverages,
        sectionICoverages,
        sectionIICoverages,
        deductibles
    };
};


const generateTableData = (rewriteVM, columnData) => {
    const uniqueID = getCoveragesUniqueID(rewriteVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateClauseData(columnData, coverageType)
    }));
};

const generateExclusionTableData = (rewriteVM, columnData) => {
    const uniqueID = getExclusionsUniqueID(rewriteVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateExclusionClauseData(columnData, coverageType)
    }));
};
const generateConditionTableData = (rewriteVM, columnData) => {
    const uniqueID = getConditionsUniqueID(rewriteVM);

    return Object.keys(uniqueID).map((coverageType) => ({
        header: get(messages, coverageType),
        data: get(uniqueID, coverageType),
        tableContent: generateConditionClauseData(columnData, coverageType)
    }));
};

const structureCustomQuote = (rewriteVM, affectedQuote, clauses) => ({
        quote: affectedQuote,
        quoteID: rewriteVM.jobID.value,
        coverages: clauses.homeowners_EH
    });

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = get(vm, `${lobPath}.value`);
    const quoteOffering = get(vm, `${quotePath}.value`);
    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(lobOffering.coverages, lobName, null);
    }

    const updatedExclusions = lobOffering.exclusions.filter((exclusion) => exclusion.updated === true);
    const updatedConditions = lobOffering.conditions.filter((condition) => condition.updated === true);

    clausesToUpdate.homeowners_EH.exclusions = updatedExclusions;
    clausesToUpdate.homeowners_EH.conditions = updatedConditions;

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;

function CoveragePage(props) {
    const modalApi = useModal();
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const [fieldsChangedOnCoveragePage, setFieldsChangedOnCoveragePage] = useState(false);
    const [quoteIsStale, setQuoteIsStale] = useState(false);
    const [isQuoting, setIsQuoting] = useState(false);
    const [isBuying, setIsBuying] = useState(false);
    const [isPageSubmitted, updateIsPageSubmitted] = useState(false);
    const [quoteProposalLink, setQuoteProposalLink] = useState('');
    const [quoteProposalCompleted, setQuoteProposalCompleted] = useState(false);
    const [isPaperlessEmailUpdated, setIsPaperlessEmailUpdated] = useState(false);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { authHeader } = useAuthentication();
    const {
        isComponentValid,
        onValidate,
        disregardFieldValidation,
        initialValidation
    } = useValidation('CoveragePage');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        updateWizardSnapshot,
        wizardData: rewriteVM,
        updateWizardData,
        jumpTo,
        steps,
        cancel: onCancel,
        currentStepIndex,
        changeNextSteps
    } = props;

    const stepsRef = useRef(steps);
    const translator = useTranslator();
    const [isQuoteProposalFailed, setIsQuoteProposalFailed] = useState(false);

    useEffect(() => {
        /**
         * Using useRef to access current updated steps.
         * as we are adding new conflicts step and landing user on this newly created step
         * "step" state variable from props does not give us updated value inside useOOSConflictPageLandingUtil
         * it refers initial rendered value only(as we are adding new step and want to land user
         * on new step in)
         */
        stepsRef.current = steps;
         
    }, [steps]);

    const {
        removeOrAddAndLandOnConflictsPage
    } = useOOSConflictPageLandingUtil(
        stepsRef,
        currentStepIndex,
        changeNextSteps,
        jumpTo
    );

    const {
        hasUWIssuesOfType,
        showUnderwritingIssuesPopup
    } = useUWIssueUtil(
        rewriteVM,
        updateWizardData,
        jumpTo,
        steps
    );

    const checkuwIssues = useCallback((newestSubmission) => {
        if (hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease', 'Rejected'])) {
            setQuoteIsStale(true);
            showUnderwritingIssuesPopup(newestSubmission);
        }
    }, [hasUWIssuesOfType, showUnderwritingIssuesPopup]);

    useEffect(() => {
        // displaying only one offering
        if (rewriteVM.value.lobData.homeowners_EH.offerings.length > 1) {
            rewriteVM.value.lobData.homeowners_EH.offerings = rewriteVM
                .value.lobData.homeowners_EH.offerings
                .filter((offering) => offering.branchCode !== 'CUSTOM');
        }

        checkuwIssues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            set(rewriteVM, path, value);
            updateWizardData(rewriteVM);
        },
        [rewriteVM, updateWizardData]
    );

    const onUpdateCustomQuote = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(
                rewriteVM,
                lobPath,
                quotePath,
                lobName,
                false
            );

            return RewriteService.updateCoverages(
                [rewriteVM.jobID.value, {
                    homeowners_EH: customQuote.coverages
                }],
                authHeader
            ).then((response) => {
                setStaleQuoteBranchCode(undefined);
                setFieldsChangedOnCoveragePage(false);

                const newRewriteVM = viewModelService.clone(rewriteVM);
                const quoteData = get(newRewriteVM, 'quoteData.value');
                const respQuoteData = get(response, 'quoteData');

                if (respQuoteData === undefined) {
                    set(response, 'quoteData', quoteData);
                }

                set(newRewriteVM, 'value', response);
                setQuoteIsStale(true);

                const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                    rewriteVM,
                    newRewriteVM,
                    `${lobPath}.coverages.coverages`
                );
                const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                    rewriteVM,
                    newRewriteVM,
                    `${lobPath}.coverages.schedules`
                );
                const allRemovedFields = [
                    ...removedFieldsFromBaseCoverages,
                    ...removedFieldsFromAdditionalCoverages
                ];

                setQuoteIsStale(true);
                disregardFieldValidation(allRemovedFields);
                updateWizardData(newRewriteVM);
                // state update is async, need to directly pass in
                checkuwIssues(newRewriteVM);

                return newRewriteVM;
            })
                .catch(() => {
                    modalApi.showAlert({
                        status: 'error',
                        icon: 'mi-error-outline',
                        title: 'Something went wrong while updating coverages',
                        message: ''
                    });
                });
        },
        [rewriteVM, authHeader, modalApi, viewModelService, disregardFieldValidation, updateWizardData, checkuwIssues]
    );

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            const lobOfferingPath = 'lobData.homeowners_EH.offerings.children[0]';
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const offering = get(rewriteVM, `${lobOfferingPath}.value`);

            setStaleQuoteBranchCode(offering.branchCode);

            return onUpdateCustomQuote(basePath, lobPath, quotePath);
        },
        [onUpdateCustomQuote, rewriteVM]
    );

    const showPaperlessEmailMessage = useCallback(() => {
        const pniEmail = get(rewriteVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value');
        const paperlessEmailInd = get(rewriteVM, 'lobData.homeowners_EH.paperlessInd.value');
        const paperlessEmail = get(rewriteVM, 'lobData.homeowners_EH.paperlessEmail.value');

        return isPaperlessEmailUpdated && paperlessEmailInd && !!pniEmail && pniEmail !== paperlessEmail;
    }, [rewriteVM, isPaperlessEmailUpdated]);

    const handlePrint = () => {
        window.print();
    };

    const onScheduleChange = useCallback(
        (schedule, path) => {
            const lobOfferingPath = 'lobData.homeowners_EH.offerings.children[0]';
            const quoteOfferingPath = 'quoteData.offeredQuotes.children[0]';

            writeValue(schedule, `${path}.value`);

            const offering = get(rewriteVM, `${lobOfferingPath}.value`);

            setStaleQuoteBranchCode(offering.branchCode);

            return onUpdateCustomQuote({}, lobOfferingPath, quoteOfferingPath);
        },
        [onUpdateCustomQuote, rewriteVM, writeValue]
    );

    const changeRewrite = useCallback(
        (value, changedPath) => {
            setFieldsChangedOnCoveragePage(true);
            updateWizardData(
                e1pClausesUtil.setClauseValue(rewriteVM, value, changedPath)
            );

            return rewriteVM;
        },
        [rewriteVM, updateWizardData]
    );

    const changeRewriteAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            changeRewrite(value, changedPath);

            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeRewrite, syncCoverages]
    );

    const recalculate = useCallback(() => {
        if (!isComponentValid) {
            updateIsPageSubmitted(true);
            window.scrollTo(0, 0);

            return false;
        }

        updateIsPageSubmitted(false);
        setIsQuoting(true);

        // If paperless Email id exist and pni email id is undefiend
        // then update pni email to paperless email
        if (isUndefined(get(rewriteVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value'))
            && !!get(rewriteVM, 'lobData.homeowners_EH.paperlessEmail.value')) {
            set(rewriteVM, 'lobData.homeowners_EH.primaryNamedInsured.person.emailAddress1.value',
                get(rewriteVM, 'lobData.homeowners_EH.paperlessEmail.value'));
        }

        return RewriteService
            .saveAndQuote([rewriteVM.value], authHeader)
            .then((response) => {
                setStaleQuoteBranchCode(undefined);
                setFieldsChangedOnCoveragePage(false);

                const newRewriteVM = viewModelService.clone(rewriteVM);
                const quoteData = get(newRewriteVM, 'quoteData.value');
                const respQuoteData = get(response, 'quoteData');

                if (respQuoteData === undefined) {
                    set(response, 'quoteData', quoteData);
                }

                set(newRewriteVM, 'value', response);
                QuoteProposalUtil.fetchQuoteProposal(
                    newRewriteVM,
                    setQuoteProposalCompleted,
                    setQuoteProposalLink,
                    authHeader,
                    setIsQuoteProposalFailed
                );
                setQuoteIsStale(false);
                updateWizardData(newRewriteVM);
                updateWizardSnapshot(newRewriteVM);
                // state update is async, need to directly pass in
                checkuwIssues(newRewriteVM);

                // E1PAP1PC-13853 :
                // If we get conflicts in saveAndQuote, we will add conflicts page if its not present
                // and user will land on conflicts page
                // If we had conflicts and we came back and made changes such that after saveAndQuote
                // if there are no conflicts we will remove conflicts page if its present
                const hasConflicts = !isEmpty(
                    get(newRewriteVM, 'value.conflicts', [])
                );

                removeOrAddAndLandOnConflictsPage(hasConflicts);

                return response;
            })
            .catch(() => {
                modalApi.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: 'Something went wrong in recalculate operation',
                    message: ''
                });

                const lobOfferingPath = 'lobData.homeowners_EH.offerings.children[0]';
                const offering = get(rewriteVM, `${lobOfferingPath}.value`);

                setStaleQuoteBranchCode(offering.branchCode);
                setFieldsChangedOnCoveragePage(true);

                return rewriteVM.value;
            })
            .finally(() => {
                setIsQuoting(false);
            });
    }, [
        isComponentValid, rewriteVM, authHeader, viewModelService, updateWizardData,
        updateWizardSnapshot, checkuwIssues, modalApi, removeOrAddAndLandOnConflictsPage
    ]);

    const checkComparatorFlow = useCallback(() => {
        if (get(rewriteVM, 'baseData.quoteSource_Ext.sourceType')) {
            set(rewriteVM, 'quoteType_Ext.value', get(rewriteVM.value, 'quoteType'));

            const isSourceTypeCompRater = get(rewriteVM, 'baseData.quoteSource_Ext.sourceType.value.code') === 'comprater';
            const quoteType = get(rewriteVM, 'quoteType_Ext.value.code');

            return (isSourceTypeCompRater && quoteType === 'Quick');
        }

        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const syncCoveragesAndRecalculate = useCallback(
        async (value, changedPath, lobPath, quotePath) => {
            await syncCoverages(value, changedPath, lobPath, quotePath);

            return recalculate(lobPath, quotePath);
        }, [recalculate, syncCoverages]
    );

    const buyNow = useCallback(
        async (lobPath, quotePath) => {
            if (!isComponentValid) {
                updateIsPageSubmitted(true);
                window.scrollTo(0, 0);

                return false;
            }

            setIsBuying(true);

            if (!lobPath) {
                // eslint-disable-next-line no-param-reassign
                lobPath = 'lobData.homeowners_EH.offerings.children.0';
            }

            if (!quotePath) {
                // eslint-disable-next-line no-param-reassign
                quotePath = 'quoteData.offeredQuotes.children.0';
            }

            const quoteOffering = get(rewriteVM, `${quotePath}.value`);

            // For EH we are not forcing recalculate on parperless email change, need to save on next as well
            const retrievedSubmission = await RewriteService.saveAndQuote(
                [rewriteVM.value],
                authHeader
            );

            set(rewriteVM, 'value', retrievedSubmission);

            // set chosen quote
            set(rewriteVM, 'bindData.chosenQuote.value', quoteOffering.publicID);
            updateWizardData(rewriteVM);

            return rewriteVM;
        },
        [isComponentValid, rewriteVM, authHeader, updateWizardData]
    );

    const columnData = generateColumnData(rewriteVM);

    const policyType = get(rewriteVM, 'lobData.homeowners_EH.policyType.value.code');

    /**
     * Helper memo for dynamically generating the loading indicator message.
     */
    const getLoadingIndicatorMessage = useMemo(() => {
        let loadingMessage = '';

        if (isQuoting) {
            loadingMessage = translator(messages.ratingYourPolicyMessage);
        } else if (isBuying) {
            loadingMessage = translator(messages.creatingYourOfferingMessage);
        }

        return loadingMessage;
    }, [translator, isQuoting, isBuying]);


    useEffect(() => {
        // on mount get quote proposal
        QuoteProposalUtil.fetchQuoteProposal(
            rewriteVM,
            setQuoteProposalCompleted,
            setQuoteProposalLink,
            authHeader,
            setIsQuoteProposalFailed
        );
        setQuoteIsStale(hasUWIssuesOfType(['BlocksQuote', 'BlocksQuoteRelease', 'Rejected']));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openDocument = useCallback(
        (e) => {
            e.preventDefault();
            window.open(quoteProposalLink, '_blank');
        }, [quoteProposalLink]
    );

    /**
     * Define property overrides for this Jutro component.
     */
    const overrideProps = {
        '@field': {
            // apply to all fields
            showOptional: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            showErrors: isPageSubmitted,
            autoComplete: false
        },
        quotePageLoadingIndicator: {
            loaded: !isQuoting && !isBuying,
            text: getLoadingIndicatorMessage
        },
        quotePageContainer: {
            visible: !isQuoting && !isBuying
        },
        quoteTableHO3: {
            columnData,
            tableData: generateTableData(rewriteVM, columnData),
            tableDataExclusion: generateExclusionTableData(rewriteVM, columnData),
            tableDataCondition: generateConditionTableData(rewriteVM, columnData),
            underwritingIssues: get(rewriteVM, 'errorsAndWarnings.underwritingIssues.value') === undefined ? [] : get(rewriteVM, 'errorsAndWarnings.underwritingIssues.value'),
            filterUWIssuesInCustomOffering: false,
            submissionVM: rewriteVM,
            modifiers: get(rewriteVM, 'lobData.homeowners_EH.modifiers.value'),
            isComprater: checkComparatorFlow(),
            visible: !isBuying,
            onDisableNextForTable: () => { },
            viewModelService,
            updateWizardData,
            authHeader,
            jumpTo,
            onViewQuoteProposal: openDocument,
            isQuoteProposalReady: quoteProposalCompleted && !fieldsChangedOnCoveragePage && !quoteIsStale,
            steps,
            setFieldsChangedOnCoveragePage,
            fieldsChangedOnCoveragePage,
            policyType,
            quoteIsStale,
            onScheduleChange,
            isPageSubmitted,
            updateIsPageSubmitted,
            onCancel,
            setIsPaperlessEmailUpdated
        },
        startDate: {
            visible: showQuoteStartDate
        },
        printPage: {
            visible: !showQuoteStartDate
        },
        protectiveDeviceRequireInfoMessageBasedOnCovA: {
            message: PropertyFlowUtil.contentBasedOnState(rewriteVM, translator)
        },
        protectiveDeviceRequireInfoMessageBasedOnCovADiv: {
            visible: PropertyFlowUtil.isProtectiveDeviceRequiredMessageVisible(rewriteVM)
        },
        ncrbDiscountAvailabilityMessageDiv: {
            visible: get(rewriteVM, 'baseData.policyAddress.state.value.code') === 'NC'
        },
        windstormMitigationDiscountInfoMessageDiv: {
            visible: PropertyFlowUtil.isWindstormMitigationDiscountInfoMessagePresent(rewriteVM)
        },
        paperlessEmailChangedMessageDiv: {
            visible: showPaperlessEmailMessage()
        },
        quoteProposalFailureErrorDiv: {
            visible: isQuoteProposalFailed
        },
    };

    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);

    return (
        <WizardPage
            showPrevious={false}
            onNext={() => buyNow('', '')}
            showNext={false}
            showCancel={false}
            skipWhen={initialValidation}
            isPageSubmittedWithErrors={isPageSubmitted}
        >
            {({ onNext }) => {
                const resolvers = {
                    resolveCallbackMap: {
                        onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
                        onRecalculate: recalculate,
                        onChangeSubmissionAndSync: changeRewriteAndSync,
                        onChangeSubmission: changeRewrite,
                        onSyncCoverages: syncCoverages,
                        onScheduleChange,
                        onStaleQuoteBranchCode,
                        onPrint: handlePrint,
                        updateWizardData,
                        onSyncCoveragesAndRecalculate: syncCoveragesAndRecalculate
                    }
                };

                return (
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={rewriteVM}
                        overrideProps={overrideProps}
                        onModelChange={updateWizardData}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.resolveCallbackMap}
                    />
                );
            }}
        </WizardPage>
    );
}

CoveragePage.propTypes = wizardProps;
export default CoveragePage;
