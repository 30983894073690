import { defineMessages } from 'react-intl';

export default defineMessages({
    paperlessEmail: {
        id: 'common.component.PaperlessDetailPopup.address.Email Address',
        defaultMessage: 'Email Address'
    },
    paperlessSaveButton: {
        id: 'common.component.PaperlessDetailPopup.ok',
        defaultMessage: 'Save',
    }
});
