import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import 'gw-capability-policychange-common-react'; // Is this needed?
import StartReinstatementPage from './pages/StartReinstatement/StartReinstatementPage';
import QuoteReinstatement from './pages/QuoteReinstatement/QuoteReinstatementPage';


setComponentMapOverrides(
    {
        StartReinstatementPage: { component: 'StartReinstatementPage' },
        QuoteReinstatement: { component: 'QuoteReinstatement' },
    },
    {
        StartReinstatementPage,
        QuoteReinstatement

    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PolicyReinstatementWizard } from './PolicyReinstatementWizard';
