import { defineMessages } from 'react-intl';

export default defineMessages({
    policyChangeEffectiveDate: {
        id: 'endorsement.eh.startChangePage.Policy Change Effective Date',
        defaultMessage: 'Policy Change Effective Date'
    },
    policyChangeReason: {
        id: 'endorsement.eh.startChangePage.Policy Change Reason',
        defaultMessage: 'Policy Change Reason'
    },
    policyChangeSource: {
        id: 'endorsement.eh.startChangePage.Policy Change Source',
        defaultMessage: 'Policy Change Source'
    },
    choosePolicyChangeSource: {
        id: 'endorsement.eh.startChangePage.Policy Change Source.Choose Policy Change Source',
        defaultMessage: 'Please Select'
    },
    choosePolicyChangeReason: {
        id: 'endorsement.eh.startChangePage.Policy Change Source.Choose Policy Change Reason',
        defaultMessage: 'Please Select'
    },
    lossOccuredBetweenDates: {
        id: "endorsement.eh.startChangePage.Has any Liability Loss occurred in the household between the effective date and today's date?",
        defaultMessage: "Has any Liability Loss occurred in the household between the effective date and today's date?"
    },
    policyChangeDescripion: {
        id: 'endorsement.eh.startChangePage.Description',
        defaultMessage: 'Description'
    },
    policyChangeCancelLabel: {
        id: 'endorsement.eh.startChangePage.CancelLabel',
        defaultMessage: 'Cancel'
    },
    policyChangesaveAndExitLabel: {
        id: 'endorsement.eh.startChangePage.SaveAndExitLabel',
        defaultMessage: 'Exit'
    },
    policyChangeEffectiveDateHelpText: {
        id: 'endorsement.eh.startChangePage.Effective Date for change can be modified in the Insured screen',
        defaultMessage: 'Effective Date for change can be modified in the Insured screen'
    }
});
