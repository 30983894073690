import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import {
    get as _get,
    includes as _includes,
    isUndefined as _isUndefined,
    set as _set
} from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'e1p-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { isRequired } from 'e1p-portals-required-validator-js';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import {
    useViewPolicyUtil
} from 'e1p-capability-hooks';
import messages from './InsuredPage.messages';
import metadata from './InsuredPage.metadata.json5';
import requiredMetadata from './InsuredPage.requiredness';

const LOB = 'homeowners_EH';

function PolicyViewInsuredPage(props) {
    const {
        wizardData: policyViewVM,
        updateWizardData,
        authUserData,
        steps,
        markFurthestStep
    } = props;
    const translator = useTranslator();
    const [requiredFields, updateRequiredFields] = useState([]);
    const [lapseInCoverage, updateLapseInCoverage] = useState(undefined);
    const [isThisNewHomePurchase, updateIsThisNewHomePurchase] = useState(undefined);
    const [lastPolicyExpirationDate, updateLastPolicyExpirationDate] = useState({});
    const { authHeader } = useAuthentication();
    const [agencyName, setAgencyName] = useState(undefined);
    const [bookTransferIndicator, setBookTransferIndicator] = useState(undefined);
    const [serviceCenterIndicator, setServiceCenterIndicator] = useState(undefined);
    const isAgent = authUserData.roles_Ext.includes('ext_sales_service');
    const viewModelService = useContext(ViewModelServiceContext);
    const [locationAndProducerCodes, setLocationAndProducerCodes] = useState([]);
    const [producerCodeDetails, setProducerCodeDetails] = useState({});
    const policyState = _get(policyViewVM, 'policyAddress.state.value.code');
    const policyForm = _get(policyViewVM, 'lobData.homeowners_EH.policyFormType.value');
    const usageType = translator({ id: _get(policyViewVM, 'lobData.homeowners_EH.coverables.yourHome.dwellingUsageType.value.name') });
    const policyType = _get(policyViewVM, 'lobData.homeowners_EH.policyType.value.code');
    const {
        getLocationAndProducerCodes,
        getProducerDetails
    } = useViewPolicyUtil();

    const getAndSetLocationCodes = async (location) => {
        const foundLocationCodes = await getLocationAndProducerCodes(location, authHeader, setLocationAndProducerCodes);

        // If only one code, set it as selected
        if (foundLocationCodes && foundLocationCodes.producerCodes.length === 1) {
            _set(policyViewVM, 'producerCode_Ext', foundLocationCodes.producerCodes[0].code);
            _set(policyViewVM, 'externalID_Ext', foundLocationCodes.locationCodes[0].code);
        }
    };

    useEffect(() => {
        // in view only flow we want to make each step as visited so that
        // user can navigate to any page by clicking on tabs on chevron
        markFurthestStep((steps.length - 1));

        if (isAgent) {
            getAndSetLocationCodes(_get(policyViewVM, 'policyAddress.state.value.code'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (locationAndProducerCodes.producerCodes
            && locationAndProducerCodes.producerCodes.length === 1) {
            _set(policyViewVM, 'producerCode_Ext', locationAndProducerCodes.producerCodes[0].code);
            _set(policyViewVM, 'externalID_Ext', locationAndProducerCodes.locationCodes[0].code);
        }
    }, [locationAndProducerCodes, policyViewVM]);

    const handleProducerApiRespone = useCallback(
        (producerDetails) => {
            const {
                producerCode, name, externalID, bookRollIndicator, serviceCenterIndicatorValue
            } = producerDetails;

            setLocationAndProducerCodes(
                [
                    {
                        producerCodes: { code: producerCode, name: producerCode },
                        locationCodes: { code: externalID, name: externalID }
                    }
                ]
            );
            _set(policyViewVM, 'producerCode_Ext', producerCode);
            _set(policyViewVM, 'externalID_Ext.value', externalID);
            setAgencyName(name);
            setBookTransferIndicator(bookRollIndicator);
            setServiceCenterIndicator(serviceCenterIndicatorValue);
        },
        [policyViewVM],
    );

    useEffect(() => {
        if (policyViewVM.value.externalID_Ext) {
            getProducerDetails(
                policyViewVM.value.externalID_Ext, authHeader
            ).then((details) => {
                setProducerCodeDetails(details);
                setAgencyName(details.agencyName);
                setBookTransferIndicator(details.bookTransferIndicator);
                setServiceCenterIndicator(details.serviceCenterIndicatorValue);
            }).catch((error) => {
                /**
                 * E1PAP1PC-15099 :
                 * This is rare scenario; will never happen in production
                 * When we bound policy using dummy producer code
                 * and when other user tries to view this policy we get exception from service
                 */
                if (policyViewVM.value.exceptions_Ext) {
                    policyViewVM.value.exceptions_Ext.push(
                        { errorMessage: error.message }
                    );
                } else {
                    _set(
                        policyViewVM.value,
                        `exceptions_Ext[${0}]`,
                        { errorMessage: error.message }
                    );
                }

                updateWizardData(policyViewVM);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyViewVM.value.externalID_Ext, authHeader]);

    useEffect(() => {
        if (
            policyViewVM.lobData.homeowners_EH.priorPolicies.value !== undefined
            && policyViewVM.lobData.homeowners_EH.priorPolicies.length > 0
        ) {
            if (
                policyViewVM.lobData.homeowners_EH.priorPolicies.value[0].policyExpirationDate
                !== undefined
            ) {
                updateLastPolicyExpirationDate(
                    policyViewVM.lobData.homeowners_EH.priorPolicies.children[0].policyExpirationDate
                );
            }

            updateLapseInCoverage(
                !policyViewVM.lobData.homeowners_EH.priorPolicies.value[0].continuousCoverageInd
            );
        }

        if (policyViewVM.lobData.homeowners_EH.coverables.yourHome !== undefined) {
            updateIsThisNewHomePurchase(
                policyViewVM.lobData.homeowners_EH.coverables.yourHome.isThisNewHomePurchase.value
            );
        }

        if (
            _isUndefined(
                _get(policyViewVM, 'lobData.homeowners_EH.hasManualPropertyAutoLosses.value')
            )
        ) {
            _set(policyViewVM, 'lobData.homeowners_EH.hasManualPropertyAutoLosses.value', false);
        }

        if (
            _isUndefined(
                _get(policyViewVM, 'lobData.homeowners_EH.hasManualPropertyAtFaultAutoLosses.value')
            )
        ) {
            _set(
                policyViewVM,
                'lobData.homeowners_EH.hasManualPropertyAtFaultAutoLosses.value',
                false
            );
        }

        if (
            _isUndefined(_get(policyViewVM, 'lobData.homeowners_EH.hasManualPropertyLosses.value'))
        ) {
            _set(policyViewVM, 'lobData.homeowners_EH.hasManualPropertyLosses.value', false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateOverrides = useCallback(() => {
        const priorAddr = _get(
            policyViewVM,
            'lobData.homeowners_EH.primaryNamedInsured.priorAddresses.value',
            []
        );
        const overrideProps = {};
        const initialRequiredFields = ['pAdressLine1', 'pPostalCode', 'pCity', 'pState'];

        priorAddr.forEach((priorAddress, index) => {
            let priorAddressTitle = translator(messages.ehPriorAddressTitle);

            priorAddressTitle = `${translator(messages.ehPriorAddressTitle)} ${index + 1}`;
            overrideProps[`ehPriorAddressTitle${index}`] = {
                value: priorAddressTitle
            };
            overrideProps[`priorAddress${index}`] = {
                submissionVM: policyViewVM,
                updateWizardData: () => {},
                requiredFields: initialRequiredFields,
                viewOnlyMode: true
            };
        });
        overrideProps.secondaryNamedInsured = {
            viewOnlyMode: true
        };
        overrideProps.ehAddPriorAddressButton = {
            visible: false
        };

        return overrideProps;
    }, [policyViewVM, translator]);

    useEffect(() => {
        const initialRequiredFields = ['isThisNewHomePurchase', 'usageType']; // Fields to look up by partner/state

        updateRequiredFields(
            isRequired(initialRequiredFields, requiredMetadata, policyState, 'MSA')
        );
        // When policystate changes update the required fields
    }, [policyState]);

    const onNext = useCallback(() => policyViewVM, [policyViewVM]);

    const resolvers = {
        resolveCallbackMap: {
            onAddPriorAddress: undefined,
            onRemovePriorAddress: undefined,
            onAddSni: undefined,
            onValidate: undefined,
            onRemoveSni: undefined,
            disclosureLinkHandler: undefined,
            disclosureCheckHandler: undefined,
            guidelinesLinkHandler: undefined,
            guidelinesCheckHandler: undefined,
            legalDisclosureCheckHandler: undefined,
            onHandleProducerApiResponse: handleProducerApiRespone
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        policyDetailsPageLoadingIndicator: {
            loaded: true
        },
        secNamedInsuredContainer: {
            visible: !!_get(policyViewVM, `lobData[${LOB}].secondaryNamedInsured.value`)
        },
        isThisNewHomePurchase: {
            value: isThisNewHomePurchase,
            required:
                _includes(requiredFields, 'isThisNewHomePurchase')
                && (policyType === 'HO3' || policyType === 'HF9'),
            onValueChange: () => {}
        },
        addSni: {
            visible: false
        },
        lastPolicyExpirationDate: {
            // HO4 will not have prior policies, so implement check for undefined
            dateDTO: lastPolicyExpirationDate,
            updateDateDto: () => { }
        },
        coverageStartDate: {
            dateDTO: policyViewVM.periodStartDate,
            defaultToToday: true,
            showErrors: true
        },
        coverageEndDate: {
            dateDTO: policyViewVM.periodEndDate
        },
        dateOfBirth: {
            dateDTO: policyViewVM.accountHolder?.dateOfBirth
        },
        agencyName: {
            value: agencyName
        },
        bookTransferIndicator: {
            value: bookTransferIndicator
        },
        serviceCenterIndicator: {
            value: serviceCenterIndicator
        },
        newHomePurchaseMainDiv: {
            visible: policyType === 'HO3' || policyType === 'HF9'
        },
        insuranceHistoryMainDiv: {
            visible: policyType !== 'HO4'
        },
        coverageLapseAndExpirationDate: {
            visible: policyType !== 'HO4'
        },
        coverageLapse: {
            value: lapseInCoverage
        },
        editPropertyLosses: {
            visible: false
        },
        usageType: {
            value: usageType,
            visible: policyType !== 'HO4'
        },
        atFaultAutoLossesContainer: {
            visible: policyType !== 'HO6' && policyType !== 'HO4' && policyState !== 'IN'
        },
        autoLossesContainer: {
            visible: policyType !== 'HO6' && policyType !== 'HO4' && policyState !== 'IN'
        },
        atFaultAutoLossesMainDiv: {
            visible: policyType !== 'HO6'
        },
        autoLossesMainDiv: {
            visible: policyType !== 'HO6'
        },
        accountSearchComponent: {
            visible: false
        },
        mailingAndBillingAddressComponent: {
            transactionVM: policyViewVM,
            viewOnlyMode: true,
            lob: LOB,
            isPolicyView: true
        },
        policyForm: {
            visible: usageType !== undefined || policyType === 'HO4',
            value: policyForm
        },
        insuredResidenceAddress: {
            addressVM: _get(policyViewVM, 'policyAddress'),
            labelPosition: 'top',
            showCountry: false,
            showOptional: false,
            viewOnlyMode: true
        },
        riskAddressComponent: {
            submissionVM: policyViewVM,
            updateWizardData: () => {},
            viewOnlyMode: true,
            viewModelService,
        },
        ehPriorAddressComponent: {
            submissionVM: policyViewVM,
            updateWizardData: () => {},
            viewModelService,
            visible: policyType === 'HO3' || policyType === 'HF9',
            viewOnlyMode: true,
            LOB
        },
        primaryNamedInsured: {
            viewOnlyMode: true,
            isPNI: true
        },
        removeSni: {
            visible: false
        },
        locationCode: {
            availableValues: locationAndProducerCodes.locationCodes,
            visible: isAgent,
            readOnly: true
        },
        producerAddressComponent: {
            address: producerCodeDetails?.address,
            phone: producerCodeDetails?.phone
        },
        ExternalProducerDetailsComponent: {
            viewOnlyMode: true,
            visible: !isAgent,
            defaultValue: policyViewVM.externalID_Ext?.value
        },
        quoteCreationDate: {
            dateDTO: policyViewVM.quoteCreationDate_Ext,
            updateDateDto: () => {},
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        rateAsOfDate: {
            dateDTO: policyViewVM.periodRateAsOfDate,
            updateDateDto: () => {},
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('editrateasofdate')
        },
        ratingDate: {
            dateDTO: policyViewVM.ratingDate,
            updateDateDto: () => {},
            readOnly: true,
            visible: authUserData.permissions_Ext.includes('viewratefields_ext')
        },
        ...generateOverrides()
    };

    const readValue = useCallback(
        (id, path) => readViewModelValue(metadata.pageContent, policyViewVM, id, path, overrideProps),
        [overrideProps, policyViewVM]
    );

    return (
        <WizardPage
            onNext={onNext}
            shouldLink
            showPrevious={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyViewVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

PolicyViewInsuredPage.propTypes = wizardProps;

export default withAuthenticationContext(PolicyViewInsuredPage);
