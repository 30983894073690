/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import _ from 'lodash';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { Grid, GridItem } from '@jutro/layout';
import PropTypes from 'prop-types';
import { AccountService } from 'e1p-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { JobUtil, DatatableUtil } from '@xengage/gw-portals-util-js';
import {
    E1PLoader,
    E1PProductImageComponent,
    CopyQuoteComponent
} from 'e1p-capability-policyjob-react';
import metadata from './OpenTransactions.metadata.json5';
import styles from './OpenTransactions.module.scss';
import summaryStyles from '../Summary/Summary.module.scss';
import messages from '../Accounts.messages';
import { Link } from '@jutro/router';

const getFormattedCurrency = (premiumAmount, index) => (
        <CurrencyField
            id={`currency${index}`}
            value={premiumAmount}
            readOnly
            hideLabel
            showOptional={false}
        // className={styles.currencyStyle}
        />
    );

class AccountOpenTransactions extends Component {
    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            accountDetailsData: PropTypes.shape({
                accountNumber: PropTypes.string
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        intl: PropTypes.shape({}).isRequired,
        refreshAccountOpenTransactionRef: PropTypes.oneOfType([
            // Either a function
            PropTypes.func, 
            // Or the instance of a DOM native element
            PropTypes.shape({})
        ]),
        updateSummaryOpenTransactionCounts: PropTypes.func.isRequired
    };

    static contextType = TranslatorContext;

    state = {
        openTransactionsResponse: '',
        openTransactionsDataTable: '',
        selectedTransactionType: '',
        searchFilterString: '',
        responseTransactionData: [],
        isLoading: true
    };

    componentDidMount() {
        const { refreshAccountOpenTransactionRef } = this.props;

        this.getResponse();

        if (refreshAccountOpenTransactionRef) {
            refreshAccountOpenTransactionRef.current = this.reloadOpenTransaction;
        }
    };

    reloadOpenTransaction = () => {
        this.getResponse();
    };

    getResponse = async () => {
        const { authHeader, fromAccountLanding, updateSummaryOpenTransactionCounts } = this.props;

        this.setState({
            isLoading: true
        });
        await AccountService.getOpenPolicyTransactionsForAccount(
            fromAccountLanding.accountDetailsData.accountNumber,
            authHeader
        ).then((res) => {
            this.setState(
                {
                    responseTransactionData: res
                },
                () => {
                    this.getData();
                }
            );

            // Get open transaction counts and invoke callback method to update parent
            const openTransactionCounts = {
                renewals: _.filter(res, { type: 'Renewal' }, []).length,
                cancellations: _.filter(res, { type: 'Cancellation' }, []).length,
                changes: _.filter(res, { type: 'PolicyChange' }, []).length,
                submissions: _.filter(res, {type: 'Submission'}).length,
                rewrites: _.filter(res, {type: 'Rewrite'}).length,
            };

            updateSummaryOpenTransactionCounts(openTransactionCounts);
        });
    };

    getData = () => {
        const response = this.getOpenTransactionsModelResponse();
        const translator = this.context;
        const filter = {
            type: null,
            value: null
        };

        this.getOpenTransactionsDataTable(response, filter);
        this.setState({
            openTransactionsResponse: response,
            selectedTransactionType: translator(messages.allTransaction),
            isLoading: false
        });
    };

    getOpenTransactionsModelResponse = () => {
        const { responseTransactionData } = this.state;
        const openTransactionsData = responseTransactionData;

        return {
            openTransactionsData,
            transactionsOptions: this.getTransactionsOptions()
        };
    };

    getFilterValues = (openTransactionsArrayResult, filter) => _.filter(openTransactionsArrayResult, (res) => res.status === filter.value);

    getSearchFilterValues = (openTransactionsArrayResult, filter) => {
        const lowerCaseFilterValue = filter.value.toLocaleLowerCase();

        return _.filter(openTransactionsArrayResult, (res) => Object.keys(res).some(
                (key) =>
                    typeof res[key] === 'string' &&
                    res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            ));
    };

    getOpenTransactionsDataTable = (openTransactionsData, filter) => {
        const { searchFilterString, selectedTransactionType } = this.state;
        const { intl } = this.props;
        let openTransactionsArrayResult =
            openTransactionsData.openTransactionsData;
        const combineFilter = {};

        switch (filter.type) {
            case 'SearchFilter':
                openTransactionsArrayResult = this.getSearchFilterValues(
                    openTransactionsArrayResult,
                    filter
                );

                if (
                    typeof selectedTransactionType === 'string' &&
                    selectedTransactionType !== 'All'
                ) {
                    combineFilter.value = selectedTransactionType;
                    openTransactionsArrayResult = this.getFilterValues(
                        openTransactionsArrayResult,
                        combineFilter
                    );
                }

                break;
            case null:
                if (searchFilterString) {
                    combineFilter.value = searchFilterString;
                    openTransactionsArrayResult = this.getSearchFilterValues(
                        openTransactionsArrayResult,
                        combineFilter
                    );
                }

                break;
            default:
                openTransactionsArrayResult =
                    openTransactionsData.openTransactionsData;
                break;
        }

        openTransactionsArrayResult =
            openTransactionsArrayResult &&
                openTransactionsArrayResult.length > 0
                ? openTransactionsArrayResult.map(
                    (openTransactionsInfo, index) => {
                        const openTransactions = {
                            jobNumber: openTransactionsInfo.jobNumber,
                            status: openTransactionsInfo.status,
                            effectiveDate: intl.formatDate(
                                new Date(
                                    openTransactionsInfo.policyEffectiveDate
                                ),
                                {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                    timeZone: 'UTC'
                                }
                            ),
                            jobExpirationDate_Ext: intl.formatDate(
                                new Date(
                                    openTransactionsInfo.jobExpirationDate_Ext
                                ),
                                {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                    timeZone: 'UTC'
                                }
                            ),
                            policyDisplayStatus: openTransactionsInfo.status,
                            type: openTransactionsInfo.displayType,
                            policyNumber: openTransactionsInfo.policyNumber,
                            canUserView: openTransactionsInfo.canUserView,
                            jobType: openTransactionsInfo.type,
                            productCode:
                                openTransactionsInfo.product.productCode,
                            insuredName:
                                openTransactionsInfo.accountHolderName,
                            premium: getFormattedCurrency(
                                openTransactionsInfo.totalPremium,
                                index
                            )
                        };

                        return openTransactions;
                    }
                )
                : [];
        openTransactionsArrayResult.sort((a, b) => b.jobNumber - a.jobNumber);
        this.setState({
            openTransactionsDataTable: openTransactionsArrayResult
        });
    };

    getTransactionsOptions = () => {
        const translator = this.context;
        const transactionsOptionsArray = [
            translator(messages.allTransaction),
            translator(messages.draftTransaction)
        ];

        return transactionsOptionsArray.map((key) => ({
                code: key,
                name: key
            }));
    };

    handleFilterChange = (value) => {
        const { openTransactionsResponse } = this.state;
        const filter = {
            type: null,
            value: null
        };

        filter.type = 'SearchFilter';
        filter.value = value;
        this.setState(
            {
                searchFilterString: value
            },
            () => {
                this.getOpenTransactionsDataTable(
                    openTransactionsResponse,
                    filter
                );
            }
        );
    };

    getLink = (item, index, property) => {
        const transactionTypeIsViewableInXCenter =
            item.jobType.toLowerCase() !== 'reinstatement';

        if (item.canUserView && transactionTypeIsViewableInXCenter) {
            if (!this.showOpenJobInXCenter(item.jobType)) {
                return (
                    <Link
                        href="/"
                        onClick={() => JobUtil.openJobInXCenter(item.jobNumber)}
                    >
                        {item[property.id]}
                    </Link>
                );
            }

            return (
                <RouterLink
                    to={JobUtil.getJobDetailURLByJobType(
                        item.jobType,
                        item.jobNumber
                    )}
                >
                    {item[property.id]}
                </RouterLink>
            );
        }

        return null;
    };

    showOpenJobInXCenter = (type) => (
            type === 'Submission' ||
            type === 'PolicyChange' ||
            type === 'Cancellation' ||
            type === 'Renewal' ||
            type === 'Rewrite'
        );

    getCell = (items, index, property) => items[property.id] ? items[property.id] : '-';

    getPolicyNumber = (item, index, property) => item[property.id] ? (
            <RouterLink to={`/policies/${item[property.id]}/summary`}>
                {item[property.id]}
            </RouterLink>
        ) : (
            '-'
        );

    getProductImage = (item) => {
        const productDisplayName = {
            Homeowners_EH: 'Homeowners',
            PersonalAuto_EA: 'Auto',
            PersonalUmbrella_EU: 'Umbrella'
        }[item.productCode];

        return (
            <Grid columns={['1fr']} gap="small">
                <GridItem tag="div">
                    <div className={summaryStyles.iconClass}>
                        <E1PProductImageComponent
                            productCode={item.productCode}
                        />
                    </div>
                    <div className={summaryStyles.productLabel}>
                        {productDisplayName}
                    </div>
                </GridItem>
            </Grid>
        );
    };

    getCopyQuote = (item) => {
        if (item.type === 'Submission') {
            const { setCopyQuoteMessage } = this.props;

            return (
                <CopyQuoteComponent
                    quoteNumber={item.jobNumber}
                    setCopyQuoteMessage={setCopyQuoteMessage}
                    refreshOpenTransactions={this.reloadOpenTransaction}
                />
            );
        }

        return <div />;
    };

    render() {
        const {
            openTransactionsDataTable,
            searchFilterString,
            isLoading
        } = this.state;

        if (isLoading) {
            return <E1PLoader loaded={!isLoading} />;
        }

        const overrides = {
            openTransactionDataTable: {
                data: openTransactionsDataTable
            },
            searchFilter: {
                value: searchFilterString
            },
            noDataMessageContainer: {
                visible: openTransactionsDataTable.length === 0
            }
        };
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: this.getCell,
                getLink: this.getLink,
                getPolicyNumber: this.getPolicyNumber,
                handleValueChange: this.handleFilterChange,
                getProductImage: this.getProductImage,
                getCopyQuote: this.getCopyQuote,
                sortDate: DatatableUtil.sortDate,
            }
        };
        const openTransactionPage = <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;

        return (
            <div className={styles.openTransaction}>{openTransactionPage}</div>
        );
    }
}

export const AccountOpenTransactionsComponent = AccountOpenTransactions;
export default withIntl(withAuthenticationContext(AccountOpenTransactions));
